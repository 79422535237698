import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
export const JobsRoot = styled.div ``;
export const StatsContainer = styled.section `
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.43px;
  color: ${colors.GRAY_AA};
  align-items: center;

  ${theme.breakpoints.up('md')} {
    justify-content: flex-start;

    .partsStats {
      padding-left: 24px;
    }
  }

  .statsCount {
    font-weight: bold;
    color: ${colors.GRAY_3F};
  }

  .checkAll {
    padding: 0;
  }
`;
export const VanContainer = styled.section `
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    padding-left: 24px;
  }
`;
export const JobsList = styled.ul `
  padding-left: 0;
  list-style: none;

  ${theme.breakpoints.down('sm')} {
    margin-bottom: 80px;
  }
`;
export const JobsTable = styled.table `
  width: 100%;
  margin-top: 26px;
  border-collapse: collapse;

  .jobCheckbox,
  .checkAll {
    padding: 0;
  }

  th {
    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid ${colors.GRAY_DE};
    padding-bottom: 16px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
    padding-right: 10px;
  }

  th:last-child {
    padding-right: 0;
  }

  td {
    padding: 24px 3px;
    border-bottom: 1px solid ${colors.GRAY_DE};
    width: 1%;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_3F};
  }

  tr:last-child td {
    border-bottom: none;
  }

  label {
    font-size: 14px;
    font-weight: 500;
  }

  .repairsList {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .link {
    text-decoration: none;
    color: ${colors.primary};
  }
`;
