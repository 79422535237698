import React from 'react';
import { CircularProgress, Typography } from 'src/components/mui';
import ConsumerTitle from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/components/ConsumerTitle';
import VehicleCard from './components/VehicleCard';
import AddVehicleButton from './components/AddVehicleButton';
import { CardsContainer, GarageDivider, GridItemWithDivider, LoadingWrapper, } from './components/styledComponents';
import useGarage from './useGarage';
const Garage = ({ consumer }) => {
    const { selectVehicleHandler, buttonHandler, vehicles, isFetching } = useGarage({
        consumerId: consumer.id,
    });
    return (<>
      <ConsumerTitle consumer={consumer}/>

      <GarageDivider />
      <Typography variant="h6">Garage</Typography>
      <CardsContainer container>
        {vehicles.map((vehicle) => {
            return (<GridItemWithDivider item xs={12} md={6} key={vehicle.id}>
              <VehicleCard vehicle={vehicle} onSelectVehicle={selectVehicleHandler}/>
            </GridItemWithDivider>);
        })}
      </CardsContainer>
      {isFetching ? (<LoadingWrapper>
          <CircularProgress size={30} color="primary"/>
        </LoadingWrapper>) : null}
      <AddVehicleButton onClick={buttonHandler}/>
    </>);
};
export default Garage;
