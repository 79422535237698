export var Steps;
(function (Steps) {
    Steps[Steps["INTRO"] = 0] = "INTRO";
    Steps[Steps["CONDITION_POOR_INTRO"] = 1] = "CONDITION_POOR_INTRO";
    Steps[Steps["CONDITION_POOR_FINAL"] = 2] = "CONDITION_POOR_FINAL";
    Steps[Steps["INELIGIBLE_FOR_SERVICE_INTRO"] = 3] = "INELIGIBLE_FOR_SERVICE_INTRO";
    Steps[Steps["INELIGIBLE_FOR_SERVICE_FINAL"] = 4] = "INELIGIBLE_FOR_SERVICE_FINAL";
    Steps[Steps["REQUIRES_ADDITIONAL_REPAIRS_INTRO"] = 5] = "REQUIRES_ADDITIONAL_REPAIRS_INTRO";
    Steps[Steps["REQUIRES_ADDITIONAL_REPAIRS_HEY_TECH_START"] = 6] = "REQUIRES_ADDITIONAL_REPAIRS_HEY_TECH_START";
    Steps[Steps["REQUIRES_ADDITIONAL_REPAIRS_TODAYS_RETURN_VISIT"] = 7] = "REQUIRES_ADDITIONAL_REPAIRS_TODAYS_RETURN_VISIT";
    Steps[Steps["REQUIRES_ADDITIONAL_REPAIRS_ADD_SERVICE_REPAIR"] = 8] = "REQUIRES_ADDITIONAL_REPAIRS_ADD_SERVICE_REPAIR";
    Steps[Steps["REQUIRES_ADDITIONAL_REPAIRS_EDIT_SERVICES"] = 9] = "REQUIRES_ADDITIONAL_REPAIRS_EDIT_SERVICES";
    Steps[Steps["SCREEN_FOR_MEASUREMENTS_REQUIRED"] = 10] = "SCREEN_FOR_MEASUREMENTS_REQUIRED";
})(Steps || (Steps = {}));
export const CANT_FINISH_STEPS = {
    [Steps.INTRO]: {
        title: "Can't Complete",
        next: null,
        back: 'close',
    },
    [Steps.CONDITION_POOR_INTRO]: {
        title: 'Vehicle Condition Poor',
        next: Steps.CONDITION_POOR_FINAL,
        back: Steps.INTRO,
        text: "Looks like we won't be able towork on the customer's vehicle. Let's explain to the customer why.",
        cantFinishOptionChosen: 'VEHICLE_CONDITION_POOR',
    },
    [Steps.CONDITION_POOR_FINAL]: {
        title: 'Vehicle Condition Poor',
        next: 'close',
        back: Steps.CONDITION_POOR_INTRO,
        text: 'Regrettably, your vehicle cannot be serviced due to its condition as determined during our inspection. No charges apply for this assessment. We recommend seeking assistance from a local dealership for suitable solutions, and our customer service team is available for any further questions or support.',
        cantFinishOptionChosen: 'VEHICLE_CONDITION_POOR',
    },
    [Steps.INELIGIBLE_FOR_SERVICE_INTRO]: {
        title: 'Ineligible For Service',
        next: Steps.INELIGIBLE_FOR_SERVICE_FINAL,
        back: Steps.INTRO,
        text: "Since this repair order contains services that are ineligible, let's let the customer know why we can't work on their vehicle.",
        cantFinishOptionChosen: 'INELIGIBLE_FOR_SERVICE',
    },
    [Steps.INELIGIBLE_FOR_SERVICE_FINAL]: {
        title: 'Ineligible For Service',
        next: 'close',
        back: Steps.INELIGIBLE_FOR_SERVICE_INTRO,
        text: "I regret to inform you that after our inspection, your vehicle isn't eligible for service due to &#91;briefly explain the reason&#93;. We'll apply a one-hour labor fee for the assessment and a mobile service charge for my visit. If you have questions or need assistance, reach out to our customer service team. We appreciate your understanding and hope to serve you in the future",
        cantFinishOptionChosen: 'INELIGIBLE_FOR_SERVICE',
    },
    [Steps.REQUIRES_ADDITIONAL_REPAIRS_INTRO]: {
        title: 'Vehicle Requires Addtl Repairs',
        next: Steps.REQUIRES_ADDITIONAL_REPAIRS_HEY_TECH_START,
        back: Steps.INTRO,
        text: 'This should only be used when you cannot complete all of the repairs today due to timing, part availability, etc. If you select "yes" the system will walk you through building the return trip repair order with pricing.',
        cantFinishOptionChosen: 'VEHICLE_REQUIRES_ADDTL_REPAIRS',
        specialBehavior: 'REQUIRES_ADDTL_REPAIR_START',
    },
    [Steps.REQUIRES_ADDITIONAL_REPAIRS_HEY_TECH_START]: {
        title: 'Vehicle Requires Addtl Repairs',
        next: Steps.REQUIRES_ADDITIONAL_REPAIRS_TODAYS_RETURN_VISIT,
        back: Steps.REQUIRES_ADDITIONAL_REPAIRS_INTRO,
        textLongerChargeGrace: "We are going to help build today's repair order as well as the return visit. It appears you were on the jobsite for enough time to warrant a diagnostic fee which will be added to today's service.",
        textNotLongerChargeGrace: "We are going to help build today's repair order as well as the return visit. It appears you were not on the jobsite for long, so we will not charge the customer today.",
        cantFinishOptionChosen: 'VEHICLE_REQUIRES_ADDTL_REPAIRS',
    },
    [Steps.REQUIRES_ADDITIONAL_REPAIRS_TODAYS_RETURN_VISIT]: {
        title: 'Vehicle Requires Addtl Repairs',
        next: null,
        back: Steps.REQUIRES_ADDITIONAL_REPAIRS_INTRO,
        cantFinishOptionChosen: 'VEHICLE_REQUIRES_ADDTL_REPAIRS',
    },
    [Steps.REQUIRES_ADDITIONAL_REPAIRS_ADD_SERVICE_REPAIR]: {
        title: 'Add Services',
        next: null,
        back: Steps.REQUIRES_ADDITIONAL_REPAIRS_TODAYS_RETURN_VISIT,
        cantFinishOptionChosen: 'VEHICLE_REQUIRES_ADDTL_REPAIRS',
    },
    [Steps.REQUIRES_ADDITIONAL_REPAIRS_EDIT_SERVICES]: {
        title: 'Edit Return Visit Builder',
        next: null,
        back: Steps.REQUIRES_ADDITIONAL_REPAIRS_TODAYS_RETURN_VISIT,
        cantFinishOptionChosen: 'VEHICLE_REQUIRES_ADDTL_REPAIRS',
    },
    [Steps.SCREEN_FOR_MEASUREMENTS_REQUIRED]: {
        title: 'Vehicle Requires Addtl Repairs',
        next: null,
        back: Steps.INTRO,
        text: "Some of the repairs you've set up for the return trip require measurements or pricing. Please record those measurements or the pricing in the recommended repairs section and then schedule accordingly.",
        cantFinishOptionChosen: 'VEHICLE_REQUIRES_ADDTL_REPAIRS',
        specialBehavior: 'SCREEN_FOR_MEASUREMENTS_REQUIRED',
    },
};
