import React, { useState } from 'react';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import PartPickupOrderDetails from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails';
import { PartPickupHeyTech } from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupHeyTech';
import PartPickupRescheduleCancel from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupRescheduleCancel';
import PartPickupSomethingMissing from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupSomethingMissing';
import PartPickupTransfer from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupTransfer';
import { PartPickupWhatsNext } from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupWhatsNext';
import { PartPickupChecklist } from '../PartPickupChecklist';
export var PART_PICKUP_DRAWER_TABS;
(function (PART_PICKUP_DRAWER_TABS) {
    PART_PICKUP_DRAWER_TABS[PART_PICKUP_DRAWER_TABS["PART_PICKUP_ORDER"] = 0] = "PART_PICKUP_ORDER";
    PART_PICKUP_DRAWER_TABS[PART_PICKUP_DRAWER_TABS["CHECKLIST"] = 1] = "CHECKLIST";
    PART_PICKUP_DRAWER_TABS[PART_PICKUP_DRAWER_TABS["HEY_TECH"] = 2] = "HEY_TECH";
    PART_PICKUP_DRAWER_TABS[PART_PICKUP_DRAWER_TABS["RESCHEDULE_CANCEL"] = 3] = "RESCHEDULE_CANCEL";
    PART_PICKUP_DRAWER_TABS[PART_PICKUP_DRAWER_TABS["SOMETHING_MISSING"] = 4] = "SOMETHING_MISSING";
    PART_PICKUP_DRAWER_TABS[PART_PICKUP_DRAWER_TABS["WHATS_NEXT"] = 5] = "WHATS_NEXT";
    PART_PICKUP_DRAWER_TABS[PART_PICKUP_DRAWER_TABS["TRANSFER"] = 6] = "TRANSFER";
})(PART_PICKUP_DRAWER_TABS || (PART_PICKUP_DRAWER_TABS = {}));
const PartPickupDrawer = ({ partPickupOrderId, address, open, onClose, startLocal, techName, }) => {
    const routes = [
        {
            name: PART_PICKUP_DRAWER_TABS.PART_PICKUP_ORDER,
            title: 'Part Pickup',
            render: () => (<PartPickupOrderDetails partPickupOrderId={partPickupOrderId} address={address} goToChecklist={() => setTab(PART_PICKUP_DRAWER_TABS.CHECKLIST)} goToRescheduleCancel={() => setTab(PART_PICKUP_DRAWER_TABS.RESCHEDULE_CANCEL)} goToPartPickupTransfer={() => setTab(PART_PICKUP_DRAWER_TABS.TRANSFER)} onClose={onClose}/>),
        },
        {
            name: PART_PICKUP_DRAWER_TABS.CHECKLIST,
            title: 'Purchase Orders',
            render: () => (<PartPickupChecklist goToMissingParts={() => setTab(PART_PICKUP_DRAWER_TABS.SOMETHING_MISSING)} onFinish={() => setTab(PART_PICKUP_DRAWER_TABS.PART_PICKUP_ORDER)}/>),
        },
        {
            name: PART_PICKUP_DRAWER_TABS.HEY_TECH,
            title: `Hey ${techName !== null && techName !== void 0 ? techName : 'Tech'}!`,
            render: () => (<PartPickupHeyTech techName={techName} onFinish={() => {
                    setTab(PART_PICKUP_DRAWER_TABS.WHATS_NEXT);
                }}/>),
        },
        {
            name: PART_PICKUP_DRAWER_TABS.RESCHEDULE_CANCEL,
            title: 'Reschedule/Cancel',
            render: () => (<PartPickupRescheduleCancel startLocal={startLocal} onClose={onClose}/>),
        },
        {
            name: PART_PICKUP_DRAWER_TABS.SOMETHING_MISSING,
            title: "Something's Missing...",
            render: () => (<PartPickupSomethingMissing onFinish={() => {
                    setTab(PART_PICKUP_DRAWER_TABS.HEY_TECH);
                }}/>),
        },
        {
            name: PART_PICKUP_DRAWER_TABS.WHATS_NEXT,
            title: 'What’s Next?',
            render: () => <PartPickupWhatsNext onFinish={onClose}/>,
        },
        {
            name: PART_PICKUP_DRAWER_TABS.TRANSFER,
            title: 'Transfer Pickup',
            render: () => <PartPickupTransfer onClose={onClose}/>,
        },
    ];
    const [tab, setTab] = useState(0);
    const handleClose = () => {
        setTab(PART_PICKUP_DRAWER_TABS.PART_PICKUP_ORDER);
        onClose();
    };
    const getContentComponent = () => {
        var _a;
        return ((_a = routes.find((route) => route.name === tab)) === null || _a === void 0 ? void 0 : _a.render()) || null;
    };
    const navigateBackHandler = () => {
        switch (tab) {
            case PART_PICKUP_DRAWER_TABS.CHECKLIST:
                setTab(PART_PICKUP_DRAWER_TABS.PART_PICKUP_ORDER);
                break;
            case PART_PICKUP_DRAWER_TABS.HEY_TECH:
                setTab(PART_PICKUP_DRAWER_TABS.SOMETHING_MISSING);
                break;
            case PART_PICKUP_DRAWER_TABS.RESCHEDULE_CANCEL:
                setTab(PART_PICKUP_DRAWER_TABS.PART_PICKUP_ORDER);
                break;
            case PART_PICKUP_DRAWER_TABS.SOMETHING_MISSING:
                setTab(PART_PICKUP_DRAWER_TABS.CHECKLIST);
                break;
            case PART_PICKUP_DRAWER_TABS.WHATS_NEXT:
                setTab(PART_PICKUP_DRAWER_TABS.HEY_TECH);
                break;
            case PART_PICKUP_DRAWER_TABS.TRANSFER:
                setTab(PART_PICKUP_DRAWER_TABS.PART_PICKUP_ORDER);
                break;
            default:
                break;
        }
    };
    const getCurrentRouteTitle = () => {
        var _a;
        return (_a = routes.find((route) => route.name === tab)) === null || _a === void 0 ? void 0 : _a.title;
    };
    return (<SimpleDrawer open={open} onClose={handleClose} title={getCurrentRouteTitle()} onBack={navigateBackHandler} arrowBack={tab !== PART_PICKUP_DRAWER_TABS.PART_PICKUP_ORDER} size="half">
      {getContentComponent()}
    </SimpleDrawer>);
};
export default PartPickupDrawer;
