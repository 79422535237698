import styled, { css } from 'styled-components';
import { Grid, Typography } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
export const infoStyles = css `
  font-size: 0.625rem;
  line-height: 1rem;
`;
export const Wrapper = styled.div `
  margin-left: auto;
  display: flex;
  align-items: center;
`;
export const ButtonRightWrapper = styled(Grid) `
  margin-left: 10px !important;
`;
export const ConsumerLink = styled(Grid) `
  display: flex;
  justify-content: space-between;
  background-color: ${colors.whiteSmokeGrey};
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  margin-top: 20px;
  padding: 20px 0px 20px 10px;
`;
export const InfoWrapper = styled.div `
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 8px;
  padding-top: 8px;
`;
export const Info = styled(Typography) `
  ${infoStyles};

  color: ${colors.darkGrey};
  font-weight: 500;
`;
export const InfoText = styled('span') `
  ${infoStyles};

  color: ${colors.middleGrey};
`;
export const StyledStandardButtonRow = styled(SimpleTwoButtonRow) `
  margin-top: 0px;
`;
