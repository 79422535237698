var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, CircularProgress } from 'src/components/mui';
import React from 'react';
import { actionButtonBase, buttonStyle, } from 'src/components/buttons/Buttons.styles';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const StyledFooterActionButton = styled(Button) `
  ${buttonStyle};
  ${actionButtonBase};

  svg {
    color: ${colors.white};
  }
`;
/**
 * Use button inside SimpleDrawerFooter
 */
const FooterActionButton = (_a) => {
    var { children, isSubmitting = false } = _a, rest = __rest(_a, ["children", "isSubmitting"]);
    return (<StyledFooterActionButton variant="contained" {...rest}>
    {isSubmitting ? <CircularProgress size={20}/> : children}
  </StyledFooterActionButton>);
};
export default FooterActionButton;
