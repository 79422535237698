var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { searchRepairs } from 'src/AdminApp/modules/requests/actions';
import { getComponentsByRepairId, getExistingFleetRules, getRepairConciergeRules, } from 'src/PricingApp/modules/repairs/actions';
import { selectRulesByFleetId } from 'src/PricingApp/modules/repairs/selectors';
import { selectFleetPricingRulesEditor } from 'src/modules/auth/selectors';
const usePricingRules = () => {
    const dispatch = useDispatch();
    const { fleetId } = useParams();
    const [isAddRepairOpen, setIsAddRepairOpen] = useState(false);
    const [selectedRepair, setSelectedRepair] = useState(null);
    const [selectedRepairName, setSelectedRepairName] = useState(null);
    const existingRules = useSelector(selectRulesByFleetId(fleetId));
    const isPricingRulesEditor = useSelector(selectFleetPricingRulesEditor);
    const handleSearchRepair = (data, fleetId) => dispatch(searchRepairs(data, fleetId));
    const onRepairSelect = (selectedRepair) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(getComponentsByRepairId(selectedRepair.repairId));
        dispatch(getRepairConciergeRules(selectedRepair.repairId));
        setSelectedRepair(selectedRepair.repairId);
        setSelectedRepairName(selectedRepair.label);
        setIsAddRepairOpen(true);
    });
    const getFleetRules = () => {
        dispatch(getExistingFleetRules(fleetId));
    };
    const handleDrawerClose = () => {
        setIsAddRepairOpen(false);
    };
    useEffect(() => {
        dispatch(getExistingFleetRules(fleetId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        fleetId,
        isAddRepairOpen,
        setIsAddRepairOpen,
        selectedRepair,
        selectedRepairName,
        handleSearchRepair,
        onRepairSelect,
        getFleetRules,
        existingRules,
        isPricingRulesEditor,
        handleDrawerClose,
    };
};
export default usePricingRules;
