import React from 'react';
import PartsHelp from 'src/AdminApp/containers/requests/Quote/PartsHelp';
import { updateSuggestedRepair } from 'src/AdminApp/modules/invoices/actions';
import { formatRepairs } from 'src/AdminApp/components/repairs/statement/utils';
import { shouldDisplayQuote } from 'src/AdminApp/containers/requests/Invoice/Details/utils';
import RenderDiscounts from 'src/AdminApp/components/discounts/RenderDiscounts';
import { Diagnoses, RemovedRepairs, } from 'src/AdminApp/components/repairs/statement';
import { RepairsTableHeader } from 'src/AdminApp/components/repairs/components/RepairDropdown';
import RemoveDiscountModal from 'src/AdminApp/components/discounts/components/RemoveDiscountModal';
import InitialPrice from 'src/AdminApp/components/repairs/statement/InitialPrice';
import CurrencyLabel from 'src/components/CurrencyLabel';
import { responsiblePartyTypeContext } from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/utils';
import PayerDrawer from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/PayerDrawer';
import AuthBtn from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/AuthBtn';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import RepairsDisplay from 'src/AdminApp/components/repairs/statement/Repairs/RepairsDisplay';
import { colors } from 'src/styles/theme';
import ActionsShell from '../ActionsShell';
import useInvoiceDetails from './useInvoiceDetails';
const { Provider } = responsiblePartyTypeContext;
const Details = ({ canEdit, orderId, workOrderId }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { referenceNum, selectorProps } = usePageReferenceNum();
    const { isRemoveDiscountOpen, discountType, payerState, responsiblePartyTypeValue, requestLocked, sublets, invoice, nonPrimaryInvoicesResponsiblePartyAuthorizations, promos, subscriptionDiscounts, credits, otherDiscounts, consumer, cancelFeeCredits, handleClosePayerModal, onAddComponent, onUpdateComponent, onRemoveComponent, onRemoveRepair, onAddCustomComponent, onUpdateCustomComponent, onRemoveCustomComponent, onRemoveCustomRepair, onRemoveDiagnosis, onAddPromoCode, onAddCredit, onAddOtherDiscount, onRemoveDiscount, openRemoveDiscountDialog, closeRemoveDiscountDialog, onRemoveSublet, isShowActionButtonsNonMain, requestId, workOrderItemResponsiblePartyAuthInfo, } = useInvoiceDetails({ referenceNum, workOrderId, selectorProps });
    const { knownRepairs, diagnoses, customRequests } = formatRepairs((_b = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) !== null && _b !== void 0 ? _b : [], (_d = (_c = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _c === void 0 ? void 0 : _c.diagnoses) !== null && _d !== void 0 ? _d : [], (_f = (_e = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _e === void 0 ? void 0 : _e.customRequests) !== null && _f !== void 0 ? _f : []);
    const subs = invoice === null || invoice === void 0 ? void 0 : invoice.sublets.concat(sublets);
    return (<>
      {invoice && shouldDisplayQuote(invoice) && (<>
          <RepairsTableHeader />
          <Provider value={responsiblePartyTypeValue}>
            <RepairsDisplay workOrderId={workOrderId} addComponent={onAddComponent} canEdit={canEdit} referenceNum={referenceNum} removeComponent={onRemoveComponent} removeRepair={onRemoveRepair} removeSublet={onRemoveSublet} renderQuoted={(price) => <InitialPrice price={price}/>} repairs={knownRepairs} sublets={subs} updateComponent={onUpdateComponent} updateSuggestedRepairInvoice={updateSuggestedRepair} invoice={invoice} displayAuditChip={false}/>
          </Provider>
          <Provider value={responsiblePartyTypeValue}>
            <Diagnoses onRemove={onRemoveDiagnosis} invoice={invoice} repairs={diagnoses} canEdit={!requestLocked}/>
          </Provider>
          <Provider value={responsiblePartyTypeValue}>
            <RepairsDisplay workOrderId={workOrderId} addComponent={onAddCustomComponent} showCustom canEdit={canEdit} referenceNum={referenceNum} removeComponent={onRemoveCustomComponent} removeRepair={onRemoveCustomRepair} removeSublet={onRemoveSublet} renderQuoted={(price) => (price === null || price === void 0 ? void 0 : price.quotedTotalCost) ? (<CurrencyLabel>{price === null || price === void 0 ? void 0 : price.quotedTotalCost}</CurrencyLabel>) : ('None')} repairs={customRequests} sublets={subs} updateComponent={onUpdateCustomComponent} updateSuggestedRepairInvoice={updateSuggestedRepair} invoice={invoice} displayAuditChip={false}/>
          </Provider>
        </>)}
      {invoice && <RemovedRepairs diagnosis={invoice}/>}
      {isShowActionButtonsNonMain && (<div style={{ borderBottom: `1px solid ${colors.lightGrey}` }}>
          <PartsHelp isEditable={canEdit} invoice={invoice} workOrderId={workOrderId}/>
        </div>)}
      {workOrderItemResponsiblePartyAuthInfo &&
            Object.keys(workOrderItemResponsiblePartyAuthInfo).map((woirp) => {
                return (<AuthBtn referenceNum={referenceNum} workOrderResponsiblePartyAuthorization={nonPrimaryInvoicesResponsiblePartyAuthorizations === null || nonPrimaryInvoicesResponsiblePartyAuthorizations === void 0 ? void 0 : nonPrimaryInvoicesResponsiblePartyAuthorizations.find((auth) => (auth === null || auth === void 0 ? void 0 : auth.responsiblePartyName) === woirp)} workOrderId={workOrderId} responsiblePartyName={woirp} repairsToAuthorize={Object.values(workOrderItemResponsiblePartyAuthInfo[woirp])} repairRequestId={requestId} invoice={invoice}/>);
            })}
      <RenderDiscounts canEdit={canEdit} credits={credits} otherDiscounts={otherDiscounts} subscriptions={subscriptionDiscounts} promos={promos} remove={openRemoveDiscountDialog}/>
      {!requestLocked && (<ActionsShell orderId={orderId} workOrderId={workOrderId} fleetId={(_g = consumer === null || consumer === void 0 ? void 0 : consumer.fleetId) !== null && _g !== void 0 ? _g : ''} promos={promos} credits={credits} otherDiscounts={otherDiscounts} cancelFeeCredits={cancelFeeCredits} onAddPromoCode={onAddPromoCode} onAddCFCredit={onAddCredit} onAddOtherDiscount={onAddOtherDiscount} disabled={(invoice === null || invoice === void 0 ? void 0 : invoice.cancelFee) !== 0}/>)}
      <RemoveDiscountModal isModalOpen={isRemoveDiscountOpen} label={discountType} closeModal={closeRemoveDiscountDialog} submit={onRemoveDiscount}/>
      {invoice && (<PayerDrawer workOrderId={workOrderId} invoice={invoice} handleClose={handleClosePayerModal} open={payerState.payerModalOpen}/>)}
    </>);
};
export default Details;
