import React, { useState } from 'react';
import { Chip, TextField, Autocomplete } from 'src/components/mui';
const SkillSearchMultiSelect = ({ options, selectedOptions, onUpdate, unremovableOptions, name, label, }) => {
    const [inputValue, setInputValue] = useState('');
    const allAvailableOptions = options.filter((option) => !selectedOptions.find((selectedOption) => selectedOption.id === option.id));
    const onSubmit = (data) => {
        onUpdate(data);
    };
    return (<Autocomplete style={{ width: '100%', minHeight: '25px' }} multiple inputValue={inputValue} options={allAvailableOptions} value={selectedOptions} onInputChange={(event, newInputValue, reason) => reason === 'input' && setInputValue(newInputValue)} onChange={(e, newValue) => {
            if (!newValue || !newValue.length) {
                onSubmit(selectedOptions.filter((v) => unremovableOptions.map((skill) => skill.name).includes(v.name)));
            }
            else {
                onSubmit(newValue);
            }
        }} getOptionLabel={(option) => option.name} renderTags={(tagValue, getTagProps) => {
            // Renders selectedValues as chips
            return tagValue.map((option, index) => (<Chip label={option.name} {...getTagProps({ index })} disabled={unremovableOptions
                    .map((skill) => skill.name)
                    .includes(option.name)}/>));
        }} renderInput={(params) => (<TextField {...params} fullWidth name={name} label={label} type="text" InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default SkillSearchMultiSelect;
