import { colors, FONT_FAMILY, theme } from 'src/styles/theme';
import { CircularProgress, SpeedDial, SpeedDialAction, } from 'src/components/mui';
import styled from 'styled-components';
export const VisitViewWrapper = styled.section `
  padding-bottom: 100px;
`;
export const VisitHeader = styled.section `
  display: flex;
  justify-content: space-between;
`;
export const VisitDate = styled.h1 `
  font-family: ${FONT_FAMILY};
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: -0.5px;
  color: ${colors.GRAY_3F};
  margin: 0;

  ${theme.breakpoints.up('md')} {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.5px;
  }
`;
export const VisitStatusContainer = styled.span `
  display: flex;
  align-items: center;

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`;
export const VisitViewContent = styled.section `
  position: relative;
  left: -16px;
  width: 110%;

  ${theme.breakpoints.up('md')} {
    left: 0;
    width: 100%;
  }
`;
export const LoaderWrapper = styled.div `
  text-align: center;
`;
export const VisitLoader = styled(CircularProgress) `
  margin: 50px auto;
`;
export const NoRequests = styled.section `
  background: ${colors.GRAY_F4};
  font-family: ${FONT_FAMILY};
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.11px;
  text-align: center;
  padding: 40px 0;
  margin-top: 30px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 4px;

  ${theme.breakpoints.up('md')} {
    margin-left: 0;
    margin-right: 0;
  }
`;
export const VisitWorkOrders = styled.section `
  margin-top: 24px;
`;
export const MobileVisits = styled.section `
  margin: 24px 16px;
`;
export const AddRequestSpeedDial = styled(SpeedDial) `
  position: fixed;
  bottom: 32px;
  right: 24px;

  .MuiSpeedDialAction-fab {
    color: ${colors.white};
    background: ${colors.TOOLTIP};
  }

  .MuiSpeedDialAction-staticTooltipLabel {
    color: ${colors.white};
    background: ${colors.TOOLTIP};
    width: 200px;
    padding: 12px 16px;
  }
`;
export const AddRoAction = styled(SpeedDialAction) `
  .MuiSpeedDialAction-fab {
    color: ${colors.white};
    background: ${colors.TOOLTIP};
  }

  .MuiSpeedDialAction-staticTooltipLabel {
    color: ${colors.white};
    background: ${colors.TOOLTIP};
    width: 200px;
    padding: 12px 16px;
  }
`;
