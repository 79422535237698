var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
// Tooltip needs special way for styling
// see https://mui.com/material-ui/react-tooltip/#customization
import { Tooltip as MuiTooltip, ClickAwayListener, muiStyled, tooltipClasses, } from 'src/components/mui';
import useIsMobile from 'src/hooks/useIsMobile';
import { FONT_FAMILY, colors } from 'src/styles/theme';
const MTooltip = muiStyled((_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (<MuiTooltip {...props} arrow classes={{ popper: className }}/>);
})(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: colors.TOOLTIP,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.TOOLTIP,
        padding: '14px',
        fontSize: '14px',
        fontFamily: FONT_FAMILY,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.46',
        letterSpacing: '-0.1px',
        borderRadius: '8px',
    },
}));
const DesktopTooltip = ({ children, title, hideArrow, placement = 'left', style, disabled, }) => {
    return (<MTooltip title={title} arrow={!hideArrow} placement={placement} leaveTouchDelay={60000} style={style} disableHoverListener={disabled} disableTouchListener={disabled} disableFocusListener={disabled} className="desktopTooltip">
      {children}
    </MTooltip>);
};
const MobileTooltip = ({ children, title, hideArrow, placement = 'left', style, disabled, }) => {
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    if (disabled) {
        return <>{children}</>; // eslint-disable-line react/jsx-no-useless-fragment
    }
    return (<ClickAwayListener disableReactTree onClickAway={handleTooltipClose}>
      <MTooltip title={title} arrow={!hideArrow} placement={placement} enterTouchDelay={0} leaveTouchDelay={5000} disableFocusListener disableHoverListener disableTouchListener={disabled} open={open} onClose={handleTooltipClose} PopperProps={{
            disablePortal: true,
        }} style={style} className="mobileTooltip">
        <span role="button" onClick={handleTooltipOpen} onKeyDown={() => { }} tabIndex={0}>
          {children}
        </span>
      </MTooltip>
    </ClickAwayListener>);
};
const Tooltip = ({ children, title, hideArrow, placement = 'left', style, disabled, }) => {
    const isMobile = useIsMobile();
    return isMobile ? (<MobileTooltip title={title} hideArrow={hideArrow} placement={placement} style={style} disabled={disabled}>
      {children}
    </MobileTooltip>) : (<DesktopTooltip title={title} hideArrow={hideArrow} placement={placement} style={style} disabled={disabled}>
      {children}
    </DesktopTooltip>);
};
export default Tooltip;
