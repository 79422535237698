import React from 'react';
import { TextField } from 'src/components/mui';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import useInternalNotes from 'src/PartnersApp/pages/PartnerManagement/PartnerDetailsDialog/InternalNotes/useInternalNotes';
import { InternalNotesStyles } from './InternalNotes.styles';
const InternalNotes = ({ partner, onClose }) => {
    const { handleNotesChange, handleSaveNote, noteInput } = useInternalNotes({
        partner,
        onClose,
    });
    return (<InternalNotesStyles>
      <TextField variant="outlined" name="otherNotes" label="Other Notes" multiline minRows={4} value={noteInput} fullWidth autoFocus onChange={handleNotesChange}/>

      <SimpleTwoButtonRow onSubmit={handleSaveNote} onCancel={onClose}/>
    </InternalNotesStyles>);
};
export default InternalNotes;
