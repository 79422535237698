import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, } from 'src/components/mui';
import { FormatListBulleted } from 'src/components/mui/icons';
import React from 'react';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
import SmallIconButton from 'src/components/icons/SmallIconButton';
import MeasurementModal from './MeasurementModal';
import { MeasurementDrawer } from './MeasurementsDrawer';
import useMeasurementDashboard from './useMeasurementDashboard';
const MeasurementDashboard = () => {
    const { onMeasurementCreate, measurementModalOpen, setMeasurementModalOpen, onCreateMeasurementSubmit, measurementsOpen, handleMeasurementsPanel, onUpdateMeasurement, measurements, onMeasurementSelected, } = useMeasurementDashboard();
    return (<>
      <Box m={1} display="flex" style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: '10px' }} onClick={onMeasurementCreate} variant="contained" color="primary">
          Add New Measurement
        </Button>
      </Box>
      <MeasurementModal open={measurementModalOpen} onClose={() => setMeasurementModalOpen(false)} onSubmit={onCreateMeasurementSubmit}/>
      <MeasurementDrawer open={measurementsOpen} closeHandler={() => handleMeasurementsPanel(false)} onMeasurementSubmit={onUpdateMeasurement}/>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>Name</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Actions</TableCell>
          </TableHead>
          <TableBody>
            {measurements &&
            (measurements === null || measurements === void 0 ? void 0 : measurements.map((measurement) => (<SimpleTableRow data={[
                    measurement === null || measurement === void 0 ? void 0 : measurement.name,
                    measurement === null || measurement === void 0 ? void 0 : measurement.id,
                    measurement === null || measurement === void 0 ? void 0 : measurement.created,
                    <SmallIconButton aria-label="Items" title="Items" onClick={() => onMeasurementSelected(measurement)}>
                      <FormatListBulleted />
                    </SmallIconButton>,
                ]}/>)))}
          </TableBody>
        </Table>
      </TableContainer>
    </>);
};
export default MeasurementDashboard;
