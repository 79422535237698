import React from 'react';
import { TextField } from 'src/AdminApp/components/form';
import { Button, CircularProgress, Grid } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
const s = styled;
const FilterActionButton = s(Button).attrs({
    size: 'small',
    variant: 'outlined',
    fullWidth: true,
}) `
  height: 100%;
  font-size: 13px;
  font-weight: 400;
  color: ${colors.GRAY_76};
`;
const SearchButton = s(Button).attrs({
    size: 'small',
    variant: 'contained',
    fullWidth: true,
    color: 'primary',
    type: 'submit',
}) `
  height: 100%;
  font-size: 13px;
  font-weight: 400;
  color: ${colors.white};
  background: linear-gradient(
    98deg,
    ${colors.celebrationPink} 0%,
    ${colors.brandPink} 100%
  );
`;
const QueryTextField = s(TextField) `
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: ${colors.GRAY_76};
  }

  label {
    color: ${colors.GRAY_76};
    font-size: 16px;
    font-weight: 400;
  }
`;
const FilterButtons = ({ queryLabelText, onReset, onClear, isSubmitting, hideQuery, hideClearResults, children, }) => {
    return (<Grid container spacing={2} style={{ marginBottom: 0, paddingBottom: 8 }}>
      {children}
      {!hideQuery && (<Grid item xs={12} md={hideClearResults ? 8 : 6}>
          <QueryTextField label={queryLabelText} name="query" variant="outlined" autoFocus fullWidth/>
        </Grid>)}
      <Grid item xs={4} md={2}>
        <FilterActionButton onClick={onReset}>Reset Form</FilterActionButton>
      </Grid>
      {!hideClearResults && <Grid item xs={4} md={2}>
        <FilterActionButton onClick={onClear}>Clear Results</FilterActionButton>
      </Grid>}
      <Grid item xs={4} md={2}>
        <SearchButton disabled={isSubmitting}>
          {!isSubmitting ? 'Search' : <CircularProgress size="1.75rem"/>}
        </SearchButton>
      </Grid>
    </Grid>);
};
export default FilterButtons;
