import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { FileCopy as FileCopyIcon } from 'src/components/mui/icons';
import { Button, Typography } from 'src/components/mui';
import { getBreadcrumbs, getDisplayText, isRepairOrderIdentifier, isVisitReferenceNum, } from 'src/utils/nav-utils';
import copyToClipboard from 'src/utils/copyToClipboard';
import VisitLockTooltip from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitLockTooltip';
import moment from 'moment/moment';
import useIsMobile from 'src/hooks/useIsMobile';
import { LOCALIZED_DATE_LONG_FORMAT } from 'src/timeConstants';
import { BackButtonIcon, BreadcrumbList, VisitLock, } from './Breadcrumbs.styles';
const BackButton = ({ handleOnClick }) => {
    return (<Button color="primary" onClick={handleOnClick}>
      <BackButtonIcon />
    </Button>);
};
const Breadcrumbs = ({ visit }) => {
    const isMobile = useIsMobile();
    const history = useHistory();
    const path = useLocation();
    const breadcrumbs = getBreadcrumbs(path);
    const currentTimezone = moment.tz.guess();
    const lockedAtDate = moment(`${visit === null || visit === void 0 ? void 0 : visit.lockedAt}Z`).local();
    const lockedAtDateFormatted = `${lockedAtDate.format(LOCALIZED_DATE_LONG_FORMAT)} ${moment
        .tz(currentTimezone)
        .zoneAbbr()}`;
    const newVisit = Object.assign(Object.assign({}, visit), { lockedAtDate: lockedAtDateFormatted });
    return isMobile ? (<BackButton handleOnClick={() => history.goBack()}/>) : (<Typography component="div">
      <BreadcrumbList>
        {breadcrumbs.map((item) => (<li key={item.url}>
            <Link to={item.url}>{getDisplayText(item.token)}</Link>
            {isRepairOrderIdentifier(item.token) && (<Button startIcon={<FileCopyIcon />} onClick={() => copyToClipboard(`${location.origin}${path.pathname}`)}/>)}
            {isVisitReferenceNum(item.token) && (<VisitLock>
                <VisitLockTooltip visitData={newVisit}/>
              </VisitLock>)}
          </li>))}
      </BreadcrumbList>
    </Typography>);
};
export default Breadcrumbs;
