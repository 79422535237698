import React, { useEffect, useState } from 'react';
import { Grid, MenuItem } from 'src/components/mui';
import { ErrorIconButton } from 'src/styles/styled';
import { AddCircle, DeleteForever } from 'src/components/mui/icons';
import { TextField } from 'src/AdminApp/components/form';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import { StyledDrawerRow } from './EditPartDrawer.styles';
import PartAdditionalInfoOptions from './PartAdditionalInfoOptions';
const EditPartDrawerAdditionalInfo = ({ values, setFieldValue, canEdit = true, }) => {
    const [additionalInfo, setAdditionalInfo] = useState(values);
    useEffect(() => {
        if ((additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.length) === 0) {
            setAdditionalInfo([{ name: '', value: '' }]);
        }
        setFieldValue('additionalInfo', additionalInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalInfo]);
    const addField = () => {
        setAdditionalInfo([...additionalInfo, { name: '', value: '' }]);
    };
    const removeField = (name) => {
        if (name !== undefined && name !== '') {
            setAdditionalInfo(additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.filter((info) => info.name !== name));
        }
    };
    const updateName = (index, name) => {
        setAdditionalInfo(additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.map((field, idx) => {
            if (idx === index) {
                return Object.assign(Object.assign({}, field), { name });
            }
            return field;
        }));
    };
    const updateValue = (index, value) => {
        setAdditionalInfo(additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.map((field, idx) => {
            if (idx === index) {
                return Object.assign(Object.assign({}, field), { value });
            }
            return field;
        }));
    };
    const usedFields = additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.map((info) => info === null || info === void 0 ? void 0 : info.name);
    return (<Grid container spacing={1}>
      {additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.map(({ value, name }, index) => {
            var _a;
            return (<StyledDrawerRow>
          <Grid item xs={5}>
            <TextField variant="outlined" value={name} select name="name" label="Name" onChange={(event) => {
                    updateName(index, event.target.value);
                }} disabled={!canEdit}>
              <MenuItem value="">&nbsp;</MenuItem>
              {(_a = Object.keys(PartAdditionalInfoOptions.values)) === null || _a === void 0 ? void 0 : _a.map((item, index) => (<MenuItem key={index} value={item} disabled={usedFields === null || usedFields === void 0 ? void 0 : usedFields.includes(item)}>
                    {PartAdditionalInfoOptions.display(item)}
                  </MenuItem>))}
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField variant="outlined" type="text" label="Value" placeholder="" value={value} onChange={(event) => {
                    updateValue(index, event.target.value);
                }} disabled={!canEdit} inputProps={{ maxLength: 32 }}/>
          </Grid>
          <ErrorIconButton onClick={() => removeField(name)}>
            <DeleteForever />
          </ErrorIconButton>
        </StyledDrawerRow>);
        })}
      {(additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.length) < 20 && (<SimpleRowButton color="primary" onClick={() => addField()} autoFocus>
          <AddCircle />
          Add New Field
        </SimpleRowButton>)}
    </Grid>);
};
export default EditPartDrawerAdditionalInfo;
