import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
import { Dialog } from 'src/components/mui';
export const Container = styled(Dialog) `
  position: fixed;
  z-index: 1100;

  .caption {
    font-size: 12px;
    font-family: ${FONT_FAMILY};
    font-weight: bold;
    margin: 16px 16px 24px;
    color: ${colors.GRAY_76};
  }

  .dialogHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;
    z-index: 2200;

    background-color: ${colors.YELLOW_E1};
    padding: 18px 16px;
    box-shadow: inset 0 -1px 0 0 ${colors.GRAY_DE};

    & h2 {
      font-family: ${FONT_FAMILY};
      font-size: 16px;
      text-align: center;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: -0.5px;
      color: ${colors.GRAY_3F};
      margin: 0;
      padding: 0;
    }
  }

  .dialogCloseIcon {
    position: absolute;
    top: 4px;
    left: 4px;
    color: ${colors.BLUE};
  }

  .dialogContent {
    margin: 56px 0 100px;
    padding: 0;

    .divider {
      margin-bottom: 32px;
    }
  }

  .editPartActions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    padding: 16px;
    box-shadow: inset 0 1px 0 0 ${colors.GRAY_DE};
    background-image: linear-gradient(
      to bottom,
      ${colors.GRAY_EBED},
      ${colors.GRAY_F2} 75%
    );
  }

  .editButton {
    width: 160px;
    padding: 12px;
    text-transform: capitalize;
    font-family: ${FONT_FAMILY};
    font-weight: 700;
  }

  .editField {
    margin: 0 16px 16px;

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      font-weight: 700;
    }

    &--left {
      margin-right: 8px;
    }

    &--partName {
      width: calc(100% - 32px);
    }
  }

  .editFieldCaption {
    margin: -6px 0 0 30px;
    font-weight: 700;
    background-color: ${colors.white};
  }

  .marginCost {
    padding: 24px 16px;
  }

  .partNameWrapper {
    position: relative;
  }

  .pickPartButton {
    position: absolute;
    right: 24px;
    top: 12px;
    color: ${colors.BLUE};
  }

  .accHeader {
    color: ${colors.GRAY_76};
    font-size: 12px;
    font-weight: 700;
    padding: 18px 16px 18px;
  }

  .noPartsSelected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: solid 1px rgba(222, 222, 222, 0.24);
    background-color: rgba(63, 63, 63, 0.01);
    color: ${colors.GRAY_76};
    font-weight: 500;
    padding: 50px 0;
    margin: 0 0 8px;
  }

  .accordionPart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-top: solid 1px ${colors.GRAY_DE};
  }

  .accordionPartTitle {
    color: ${colors.black};
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 4px;
    padding: 0;
  }

  .accordionPartSubtitle {
    color: ${colors.GRAY_76};
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  .accordionActions {
    display: flex;
    flex-direction: row;

    svg {
      fill: ${colors.GRAY_CC};
      color: ${colors.GRAY_CC};
    }
  }
`;
