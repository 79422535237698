import { createSelector } from 'reselect';
import { selectAllRepairs } from 'src/AdminApp/modules/lookups/selectors';
export const selectConsumers = (state) => state.admin.consumers;
export const selectConsumerById = (consumerId) => createSelector(selectConsumers, (consumers) => consumers.byId[consumerId]);
export const selectConsumerRequestHistory = ({ consumerId, }) => createSelector(selectConsumerById(consumerId), (consumer) => consumer.requestHistory);
export const selectVehiclesByConsumerId = (consumerId) => createSelector(selectConsumers, (consumers) => { var _a, _b, _c; return (_c = (_b = (_a = consumers.byId[consumerId]) === null || _a === void 0 ? void 0 : _a.vehicles) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : []; });
export const selectVehiclesFetchingByConsumerId = (consumerId) => createSelector(selectConsumers, (consumers) => { var _a, _b, _c; return (_c = (_b = (_a = consumers.byId[consumerId]) === null || _a === void 0 ? void 0 : _a.vehicles) === null || _b === void 0 ? void 0 : _b._isFetching) !== null && _c !== void 0 ? _c : false; });
export const selectVehiclesPaginationByConsumerId = (consumerId) => createSelector(selectConsumers, (consumers) => { var _a, _b, _c; return (_c = (_b = (_a = consumers.byId[consumerId]) === null || _a === void 0 ? void 0 : _a.vehicles) === null || _b === void 0 ? void 0 : _b.pagination) !== null && _c !== void 0 ? _c : {}; });
const selectDashboard = createSelector(selectConsumers, (consumers) => { var _a; return (_a = consumers.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardConsumers = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectDashboardPagination = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardParams = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardFetching = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
export const selectCancelFeeCreditsByConsumerId = createSelector(selectConsumers, (_, consumerId) => consumerId, (consumers, consumerId) => { var _a, _b; return (_b = (_a = consumers.byId[consumerId]) === null || _a === void 0 ? void 0 : _a.cancelFeeCredits) !== null && _b !== void 0 ? _b : []; });
export const selectVehicleTokens = (consumerId) => createSelector(selectConsumers, selectAllRepairs, (consumers, allRepairs) => {
    const consumerObj = consumers.byId[consumerId];
    const tokens = (consumerObj === null || consumerObj === void 0 ? void 0 : consumerObj.carTokens) ? consumerObj.carTokens : [];
    return tokens.map((t) => {
        const foundRepair = allRepairs.find((r) => r.id === t.productId);
        const newToken = Object.assign({}, t);
        if (foundRepair) {
            newToken.productName = foundRepair.repairName;
        }
        return newToken;
    });
});
