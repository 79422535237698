var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as gql from 'gql-query-builder';
import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
// FSA ACTIONS
export const ActionCreators = {
    INSTALLATIONS: createDefaultActionCreators('INSTALLATIONS', ['CLEAR']),
    SET_SORT_PARAMS: createDefaultActionCreators('SET_SORT_PARAMS'),
};
export const getInstallations = ({ pageSize, pageIndex, sort = ['installationDate,desc'], }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const query = gql.query({
        operation: 'clientShops',
        fields: [
            {
                type: ['id', 'name', 'rsSmsId'],
            },
            'address1',
            'address2',
            'city',
            'state',
            'zipCode',
            'phone',
            'installedOn',
            'lastInvoiceProcessedAt',
            'customId',
            'ispId',
            'id',
            'name',
            'status',
        ],
    });
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.INSTALLATIONS.FETCH({
                meta: () => ({ pageSize, pageIndex, sort, params: {} }),
            }),
            ActionCreators.INSTALLATIONS.SUCCESS({
                meta: () => ({ pageSize, pageIndex, sort, params: {} }),
            }),
            ActionCreators.INSTALLATIONS.FAILURE({
                meta: () => ({ pageSize, pageIndex, sort, params: {} }),
            }),
        ],
        endpoint: API_URLS.GRAPHQL(),
        method: 'POST',
        body: query,
    }));
    const { errors } = response.payload;
    if (errors && errors[0]) {
        throw new Error(errors[0].message);
    }
    return response;
});
export const setSortParams = (sort = ['installationDate,desc']) => (dispatch) => {
    dispatch({
        type: ActionCreators.SET_SORT_PARAMS.SUCCESS().type,
        meta: {
            sort,
        },
    });
};
