import { Formik } from 'formik';
import React from 'react';
import { Box, Grid, InputAdornment, TextField } from 'src/components/mui';
import { AttachMoneyRounded as AttachMoneyRoundedIcon } from 'src/components/mui/icons';
import { SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import { DrawerFormBorderedSection, DrawerFormRow, } from 'src/components/SimpleDrawer/SimpleDrawer.styles';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import AuthorizedSubtitle from './components/AuthorizedSubtitle';
const EditLaborDrawer = ({ adding, laborRate, laborTime, onClose, onRemove, onSubmit, open, isAuthorized = false, canEdit = true, }) => {
    return (<SimpleDrawer open={open} onClose={onClose} size="oneThird">
      <SimpleDrawerHeader title={adding ? 'Add Labor' : 'Edit Labor'} onClose={onClose}/>
      {isAuthorized && <AuthorizedSubtitle />}
      <Formik initialValues={{
            laborTime: laborTime || '',
            laborRate: laborRate || '',
        }} enableReinitialize onSubmit={(values, { setSubmitting, resetForm }) => {
            onSubmit(Object.assign({}, values)).then(() => {
                resetForm({ values: Object.assign({}, values) });
                setSubmitting(false);
            });
        }}>
        {({ dirty, handleSubmit, isSubmitting, isValid, isValidating, values, setFieldValue, }) => (<>
            <SimpleDrawerContent>
              <Grid container spacing={2}>
                <DrawerFormBorderedSection container spacing={2}>
                  <DrawerFormRow item xs={12}>
                    <TextField variant="outlined" type="number" name="laborTime" label="Labor Time" fullWidth disabled={!canEdit || isAuthorized} value={values.laborTime} onChange={(e) => setFieldValue('laborTime', e.target.value)}/>
                  </DrawerFormRow>
                  <DrawerFormRow item xs={12}>
                    <TextField variant="outlined" type="number" name="laborRate" label="Labor Rate" fullWidth InputProps={{
                endAdornment: (<InputAdornment position="start">/hr</InputAdornment>),
                startAdornment: (<InputAdornment position="start">
                            <AttachMoneyRoundedIcon />
                          </InputAdornment>),
            }} disabled={!canEdit || isAuthorized} value={values.laborRate} onChange={(e) => setFieldValue('laborRate', e.target.value)}/>
                  </DrawerFormRow>
                </DrawerFormBorderedSection>
                <DrawerFormBorderedSection container spacing={2}>
                  <DrawerFormRow item xs={12}>
                    <Box component="div" sx={{
                display: 'flex',
                justifyContent: 'space-between',
                fontWeight: 'bold',
            }}>
                      <Box component="span">Labor Total</Box>
                      <Box component="span">
                        $
                        {(values.laborRate *
                values.laborTime || 0).toFixed(2)}
                      </Box>
                    </Box>
                  </DrawerFormRow>
                </DrawerFormBorderedSection>
              </Grid>
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <SimpleTwoButtonRow disabledSubmit={!isValid || isValidating || isSubmitting || !dirty} disableTopPadding disabledCancelTooltipTitle="This component is part of an authorized repair. To make changes, remove the authorization first." disabledCancel={(adding && !canEdit) || isAuthorized} cancelText="Remove" submitText="Save" onCancel={onRemove} onSubmit={handleSubmit}/>
            </SimpleDrawerFooter>
          </>)}
      </Formik>
    </SimpleDrawer>);
};
export default EditLaborDrawer;
