import { logout, refreshAuth } from 'src/modules/auth/actions';
const APOLLO_UNAUTHENTICATED = 'UNAUTHENTICATED';
const refreshToken = (getState, dispatch) => {
    const state = getState();
    const { refreshToken } = state.auth;
    if (refreshToken) {
        dispatch(refreshAuth(refreshToken));
    }
    else {
        dispatch(logout({}));
    }
};
const graphQLErrorMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    var _a, _b, _c, _d;
    if (action) {
        const graphQLErrors = ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.errors) || ((_c = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.errors);
        if (graphQLErrors &&
            graphQLErrors[0] &&
            ((_d = graphQLErrors[0].extensions) === null || _d === void 0 ? void 0 : _d.code) === APOLLO_UNAUTHENTICATED) {
            refreshToken(getState, dispatch);
        }
    }
    return next(action);
};
export default graphQLErrorMiddleware;
