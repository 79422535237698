import React from 'react';
import styled from 'styled-components';
import { Button, Grid, Icon, DatePicker } from 'src/components/mui';
import moment from 'moment';
import { SaleMatchFilterType } from 'src/AdminApp/models/enums/SaleMatchFilterType';
import WeeklyDataPicker from 'src/components/dataPickers/WeeklyDataPicker';
import { createStartEndWeekFromDates } from 'src/utils/data-utils';
import { DATE_YMD_FORMAT, LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const CenteredDiv = styled.div `
  input {
    text-align: center;
    font-size: 20px;
  }
`;
const StyledDatePicker = styled(DatePicker) `
  input {
    text-align: center;
    font-size: 20px;
  }
`;
const SmallDatePicker = styled(DatePicker) `
  width: 120px;

  input {
    text-align: center;
    font-size: 20px;
  }
`;
const StyledArrowIcon = styled(Icon) `
  vertical-align: middle;
  line-height: 0.9;
`;
const DashboardDateFilters = ({ onSearch, filters, filterType, }) => {
    const onDateRangeChange = (start, end) => {
        const values = {
            start: moment(start).format(DATE_YMD_FORMAT),
            end: moment(end).format(DATE_YMD_FORMAT),
        };
        onSearch(values);
    };
    const onDateRangeChangeWrapper = (start, end, difference, unit) => {
        const dateFrom = start.add(difference, unit);
        const dateTo = end.add(difference, unit);
        if (unit === 'month') {
            dateFrom.startOf(unit);
            dateTo.endOf(unit);
        }
        onDateRangeChange(dateFrom, dateTo);
    };
    const renderDailyDateFilter = (start, end, currentValueIsMax) => {
        const date = start;
        const now = new Date();
        return (<Grid container style={{ alignItems: 'stretch', justifyContent: 'center' }} wrap="nowrap">
        <Button onClick={() => onDateRangeChangeWrapper(date.clone(), date.clone(), -1, 'days')}>
          <StyledArrowIcon>arrow_left</StyledArrowIcon>
        </Button>
        <Button>
          <SmallDatePicker value={start} maxDate={now} onChange={(date) => onDateRangeChange(date, date)} format={LOCALIZED_DATE_FORMAT} slotProps={{
                textField: {
                    variant: 'standard',
                },
            }}/>
        </Button>
        <Button onClick={() => onDateRangeChangeWrapper(date.clone(), date.clone(), +1, 'days')} disabled={currentValueIsMax}>
          <StyledArrowIcon>arrow_right</StyledArrowIcon>
        </Button>
      </Grid>);
    };
    const renderWeeklyDateFilter = (startData, endDate, currentValueIsMax) => {
        const week = createStartEndWeekFromDates(startData || endDate);
        const start = moment(week.start);
        const end = moment(week.end);
        return (<Grid container style={{ justifyContent: 'center' }} wrap="nowrap">
        <Button onClick={() => onDateRangeChangeWrapper(start, end, -1, 'week')}>
          <StyledArrowIcon>arrow_left</StyledArrowIcon>
        </Button>
        <Button>
          <CenteredDiv>
            <WeeklyDataPicker label="" dateFrom={startData.toDate()} onChange={(dateTo, dateFrom) => onDateRangeChange(dateTo, dateFrom)}/>
          </CenteredDiv>
        </Button>
        <Button onClick={() => onDateRangeChangeWrapper(start, end, +1, 'week')} disabled={currentValueIsMax}>
          <StyledArrowIcon>arrow_right</StyledArrowIcon>
        </Button>
      </Grid>);
    };
    const renderCustomDateFilter = (startDate, endDate) => {
        const now = new Date();
        return (<Grid container direction="row" style={{ alignItems: 'center', justifyContent: 'center' }} wrap="nowrap">
        <Button>
          <SmallDatePicker value={startDate} maxDate={endDate || now} onChange={(date) => onDateRangeChange(date, endDate)} format={LOCALIZED_DATE_FORMAT}/>
        </Button>
        <span> - </span>
        <Button>
          <SmallDatePicker value={endDate} maxDate={now} minDate={startDate} onChange={(date) => onDateRangeChange(startDate, date)} format={LOCALIZED_DATE_FORMAT} slotProps={{
                textField: {
                    variant: 'standard',
                },
            }}/>
        </Button>
      </Grid>);
    };
    const renderMonthlyDateFilter = (startDate, endDate, currentValueIsMax) => {
        const start = moment(startDate).startOf('month');
        const end = moment(endDate).endOf('month');
        const now = new Date();
        return (<Grid container style={{ justifyContent: 'center' }} wrap="nowrap">
        <Button onClick={() => onDateRangeChangeWrapper(start, end, -1, 'month')}>
          <StyledArrowIcon>arrow_left</StyledArrowIcon>
        </Button>
        <Button>
          <StyledDatePicker openTo="month" views={['year', 'month']} value={start} maxDate={now} onChange={(date) => {
                const dateFrom = moment(date).startOf('month');
                const dateTo = moment(date).endOf('month');
                onDateRangeChange(dateFrom, dateTo);
            }} slotProps={{
                textField: {
                    variant: 'standard',
                },
            }}/>
        </Button>
        <Button onClick={() => onDateRangeChangeWrapper(start, end, +1, 'month')} disabled={currentValueIsMax}>
          <StyledArrowIcon>arrow_right</StyledArrowIcon>
        </Button>
      </Grid>);
    };
    const { end, start } = filters;
    const now = moment();
    const startMoment = moment(start) || now;
    const endMoment = moment(end) || now;
    const currentValueIsMax = now <= endMoment.clone().endOf('day');
    return (<Grid container style={{ alignContent: 'flex-end' }} spacing={4}>
      <Grid item xs={12}>
        {filterType === SaleMatchFilterType.Daily &&
            renderDailyDateFilter(startMoment, endMoment, currentValueIsMax)}
        {filterType === SaleMatchFilterType.Weekly &&
            renderWeeklyDateFilter(startMoment, endMoment, currentValueIsMax)}
        {filterType === SaleMatchFilterType.Monthly &&
            renderMonthlyDateFilter(startMoment, endMoment, currentValueIsMax)}
        {filterType === SaleMatchFilterType.Custom &&
            renderCustomDateFilter(startMoment, endMoment)}
      </Grid>
    </Grid>);
};
export default DashboardDateFilters;
