import { combineReducers } from 'redux';
import analyticsReducer from './analytics/reducers';
import arrivalTimerReducer from './arrivalTimer/reducers';
import autoselectionReducer from './autoselection/reducers';
import consumersReducer from './consumers/reducers';
import customConfigsReducer from './customConfigs/reducers';
import { deliveryVansReducers } from './deliveryVans/reducers';
import deliveryVanTemplatesReducer from './deliveryVanTemplates/reducers';
import fleetsReducer from './fleets/reducers';
import geoManagementReducers from './geoManagement/reducers';
import installationsReducer from './installations/reducers';
import invoicesReducer from './invoices/reducers';
import locksReducer from './locks/reducers';
import lookupsReducer from './lookups/reducers';
import marketingSourcesReducer from './marketingSources/reducers';
import metrosReducer from './metros/reducers';
import mpiReducer from './mpi/reducers';
import ordersReducer from './orders/reducers';
import paymentDrawerReducer from './paymentDrawer/reducers';
import paymentsReducer from './payments/reducers';
import quickRoReducer from './quickRo/reducers';
import regionsReducer from './regions/reducers';
import requestLockReducer from './requestLock/reducers';
import RequestNotesReducer from './requestNotes/reducers';
import requestsReducers from './requests/reducers';
import responsiblePartiesReducer from './responsibleParties/reducers';
import salesMatchReducer from './salesMatch/reducers';
import serviceAreasReducer from './serviceAreas/reducers';
import shiftsReducer from './shifts/reducers';
import shopsReducer from './shops/reducers';
import subletsReducer from './sublets/reducers';
import subscriptionReducer from './subscription/reducers';
import swimlanesReducer from './swimlanes/reducers';
import territoriesReducers from './territories/reducers';
import userReducer from './users/reducers';
import workflowsReducer from './workflows/reducers';
import invoiceWorkbenchReducer from 'src/AdminApp/modules/invoiceWorkbench/reducers';
import workOrdersReducer from './workOrders/reducers';
import zendeskReducer from './zendesk/reducers';
import invoiceRequestsReducers from './invoiceRequests/reducers';
export default combineReducers({
    analytics: analyticsReducer,
    arrivalTimer: arrivalTimerReducer,
    autoselection: autoselectionReducer,
    consumers: consumersReducer,
    customConfigs: customConfigsReducer,
    deliveryVanTemplates: deliveryVanTemplatesReducer,
    deliveryVans: deliveryVansReducers,
    fleets: fleetsReducer,
    geoManagement: geoManagementReducers,
    installations: installationsReducer,
    invoiceRequests: invoiceRequestsReducers,
    invoices: invoicesReducer,
    invoiceWorkbench: invoiceWorkbenchReducer,
    locks: locksReducer,
    lookups: lookupsReducer,
    marketingSources: marketingSourcesReducer,
    metros: metrosReducer,
    mpi: mpiReducer,
    orders: ordersReducer,
    paymentDrawer: paymentDrawerReducer,
    payments: paymentsReducer,
    quickRo: quickRoReducer,
    regions: regionsReducer,
    requestLock: requestLockReducer,
    requestNotes: RequestNotesReducer,
    requests: requestsReducers,
    responsibleParties: responsiblePartiesReducer,
    salesMatch: salesMatchReducer,
    serviceAreas: serviceAreasReducer,
    shifts: shiftsReducer,
    shops: shopsReducer,
    sublets: subletsReducer,
    subscription: subscriptionReducer,
    swimlanes: swimlanesReducer,
    territories: territoriesReducers,
    users: userReducer,
    workOrders: workOrdersReducer,
    workflows: workflowsReducer,
    zendesk: zendeskReducer,
});
