import { Container } from 'src/components/mui';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPartDistributorEditor } from 'src/modules/auth/selectors';
import NotAuthorized from 'src/components/layout/NotAuthorized';
import DistributorsDashboard from './DistributorsDashboard';
const Distributors = () => {
    const isPartsDistributorEditor = useSelector(selectPartDistributorEditor);
    if (!isPartsDistributorEditor) {
        return <NotAuthorized targetRole="Part_Distributor_Editor"/>;
    }
    return (<Container maxWidth="xl">
      <DistributorsDashboard />
    </Container>);
};
export default Distributors;
