var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectItemById } from 'src/PricingApp/modules/utils/selectors';
import { selectComponents } from 'src/PricingApp/modules/components/selectors';
import { selectAuthorizedPricingAdmin } from 'src/modules/auth/selectors';
import { useEffect } from 'react';
import { getComponent, saveComponent, updateComponent, } from 'src/PricingApp/modules/components/actions';
import { toast } from 'src/components/SimpleToast';
const EMPTY_COMPONENT = {
    id: '',
    name: '',
    type: '',
    unit: '',
    pcdbId: '',
    metadata: {
        fluidType: '',
    },
    catalogComponentDetails: {
        externalId: '',
    },
    automaticallyOrder: false,
    coreChargeExpected: false,
    skynetMinimumMargin: '',
};
const UNIT_TYPES_BY_COMPONENT_TYPE = {
    PART: ['ITEM'],
    FLUID: ['QUART', 'LITER', 'OUNCE', 'GRAM', 'MILLILITER', 'GALLON'],
};
const useAddComponentForm = ({ componentId }) => {
    var _a;
    const dispatch = useDispatch();
    const history = useHistory();
    const component = componentId
        ? (_a = useSelector(selectItemById(componentId, selectComponents))) !== null && _a !== void 0 ? _a : EMPTY_COMPONENT
        : EMPTY_COMPONENT;
    const isPricingAdmin = useSelector(selectAuthorizedPricingAdmin);
    const isFluid = (name) => name === 'FLUID';
    useEffect(() => {
        if (componentId)
            dispatch(getComponent(componentId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSaveOrUpdateComponent = (componentToUpdate) => __awaiter(void 0, void 0, void 0, function* () {
        nullOutEmptyFields(componentToUpdate);
        if (componentToUpdate.id) {
            const updateResponse = dispatch(updateComponent(componentToUpdate.id, componentToUpdate));
            if (!updateResponse.error) {
                toast.success('Component updated successfully');
            }
            return updateResponse;
        }
        const response = yield dispatch(saveComponent(componentToUpdate));
        if (!response.error) {
            toast.success('Component saved successfully');
        }
        return redirectToNewComponentIfSuccess(response);
    });
    const nullOutEmptyFields = (componentToUpdate) => {
        var _a, _b, _c, _d;
        componentToUpdate.id = (_a = componentToUpdate.id) !== null && _a !== void 0 ? _a : undefined;
        componentToUpdate.pcdbId = (_b = componentToUpdate.pcdbId) !== null && _b !== void 0 ? _b : undefined;
        if ((_c = componentToUpdate.catalogComponentDetails) === null || _c === void 0 ? void 0 : _c.externalId) {
            componentToUpdate.catalogComponentDetails.externalId =
                (_d = componentToUpdate.catalogComponentDetails.externalId) !== null && _d !== void 0 ? _d : null;
        }
    };
    const redirectToNewComponentIfSuccess = (response) => {
        var _a;
        if (!response.error) {
            const savedComponentId = (_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.id;
            history.push(`/pricing/components/${savedComponentId}/edit`);
            return;
        }
        return response;
    };
    const getComponentTypes = () => {
        return Object.keys(UNIT_TYPES_BY_COMPONENT_TYPE);
    };
    const onComponentTypeChange = (setFieldValue, newComponentType) => {
        setFieldValue('type', newComponentType);
        setFieldValue('unit', '');
        if (isFluid(newComponentType)) {
            setFieldValue('metadata.fluidType', null);
            setFieldValue('catalogComponentDetails.externalId', null);
        }
        else {
            setFieldValue('metadata', null);
            setFieldValue('catalogComponentDetails', null);
        }
    };
    const getUnitsByComponentType = (values) => {
        const unitType = values.type;
        const unitTypes = UNIT_TYPES_BY_COMPONENT_TYPE[unitType];
        return unitTypes || getAllUnitTypes();
    };
    const getAllUnitTypes = () => {
        return Object.values(UNIT_TYPES_BY_COMPONENT_TYPE).reduce((v1, v2) => [
            ...v1,
            ...v2,
        ]);
    };
    return {
        getComponentTypes,
        onComponentTypeChange,
        getUnitsByComponentType,
        isPricingAdmin,
        component,
        onSaveOrUpdateComponent,
        isFluid,
    };
};
export default useAddComponentForm;
