import { Autocomplete, Paper } from 'src/components/mui';
import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
export const ResponsiblePartyDropdownItemText = styled.span `
  font-size: 14px;
`;
export const DropdownItem = styled.li `
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;

  ${theme.breakpoints.down('md')} {
    white-space: break-spaces;
  }
`;
export const ResponsiblePartyAutocomplete = styled(Autocomplete) `
  .MuiAutocomplete-inputRoot {
    max-height: 124px;
    overflow-y: auto;
    border: solid 1px ${colors.GRAY_DE};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
export const ResponsiblePartyListbox = styled(Paper) `
  .MuiAutocomplete-listbox {
    max-height: 150px;
  }
`;
