var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'src/components/mui';
import { createStatusSelect, ResponsiblePartiesHasAuthorizationNumber, ResponsiblePartiesHasGeneratesRevenue, ResponsiblePartiesHasPaymentRequired, ResponsiblePartiesStatusSelectForm, ResponsiblePartyTaxTypeSelectForm, SelectType, } from 'src/AdminApp/components/form/Statuses';
import { selectPartyTypes } from 'src/AdminApp/modules/responsibleParties/selectors';
import FilterBlock from 'src/components/dashboard/DashboardFilter/FilterBlock';
import FilterButtons from 'src/components/dashboard/DashboardFilter/FilterButtons';
import { toEnum } from 'src/models/enums';
import { INITIAL_VALUES } from './constants';
const Filters = ({ filters = {}, onReset, onSearch }) => {
    const partyTypes = useSelector(selectPartyTypes);
    const partyTypesEnumVals = Object.assign({}, ...partyTypes.map(({ id, name }) => ({ [id]: name })));
    const ResponsiblePartiesTypeSelectForm = createStatusSelect(toEnum(partyTypesEnumVals), SelectType.FormControl);
    const [submitting, setSubmitting] = useState(false);
    const resetFilters = () => {
        onReset();
    };
    const handleSubmit = (_a) => __awaiter(void 0, void 0, void 0, function* () {
        var { query } = _a, rest = __rest(_a, ["query"]);
        const values = Object.assign({ name: query.trim() }, rest);
        setSubmitting(true);
        yield onSearch(values);
        setSubmitting(false);
    });
    return (<Formik enableReinitialize initialValues={INITIAL_VALUES(filters)} onSubmit={handleSubmit}>
      {({ setValues }) => (<Form>
          <FilterBlock>
            <Grid item xs={12} md={6} lg={3}>
              <ResponsiblePartiesStatusSelectForm disabled={submitting} name="status" label="Status" leftAlignLabel/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ResponsiblePartyTaxTypeSelectForm disabled={submitting} name="taxType" label="Tax Type" leftAlignLabel/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ResponsiblePartiesTypeSelectForm disabled={submitting} name="responsiblePartyTypeId" label="Responsible Party Type" leftAlignLabel/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ResponsiblePartiesHasAuthorizationNumber disabled={submitting} name="authorizationNumber" label="Authorization Number" leftAlignLabel/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ResponsiblePartiesHasPaymentRequired disabled={submitting} name="paymentRequired" label="Payment Required" leftAlignLabel/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ResponsiblePartiesHasGeneratesRevenue disabled={submitting} name="generatesRevenue" label="Generates Revenue" leftAlignLabel/>
            </Grid>
          </FilterBlock>
          <FilterButtons onClear={resetFilters} onReset={() => setValues(INITIAL_VALUES())} hideClearResults queryLabelText="Search by Responsible Party Name" isSubmitting={submitting}/>
        </Form>)}
    </Formik>);
};
export default Filters;
