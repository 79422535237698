import React, { Fragment } from 'react';
import { Grid } from 'src/components/mui';
import { formatDate } from 'src/utils/formatter';
import { DisplayMode } from '../models';
import { ValueRenderer } from '../renderers';
const EXCLUDED_KEYS = ['modified', 'version'];
const ChangeLog = ({ data, schema, mode = DisplayMode.PRETTY, }) => {
    function prettifyAction(action) {
        return action.endsWith('E') ? `${action}D` : `${action}ED`;
    }
    const renderTable = (props) => {
        return props && props.changes ? (<table>
        <tbody>
          <Fragment key={props.id}>
            <tr>
              <td colSpan={4}> {`#${props.documentId}`} </td>
            </tr>
            <tr>
              <td colSpan={4}>
                {' '}
                {`${prettifyAction(props.action)} on ${formatDate(props.modified.toString())} UTC by ${props.modifiedBy}`}{' '}
              </td>
            </tr>
            {props.changes
                .filter((change) => !EXCLUDED_KEYS.includes(change.field))
                .filter((change) => change.oldValue !== change.newValue)
                .map((change) => (<tr key={change.field}>
                  <td style={{ paddingLeft: '30px' }}>{change.field}</td>
                  <ValueRenderer field={change.field} value={change.oldValue} schema={schema}/>
                  <td>⇒</td>
                  <ValueRenderer field={change.field} value={change.newValue} schema={schema}/>
                </tr>))}
          </Fragment>
        </tbody>
      </table>) : null;
    };
    const renderRaw = (props) => {
        return <pre>{props && JSON.stringify(props, undefined, 2)}</pre>;
    };
    return data ? (<>
      <Grid container>
        <Grid item>
          <div style={{ fontWeight: 'bold' }}>
            Changelog: {data.table} / {data.id}{' '}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        {data && (<Grid item>
            {DisplayMode.PRETTY === mode && renderTable(data)}
            {DisplayMode.RAW === mode && renderRaw(data)}
          </Grid>)}
      </Grid>
    </>) : null;
};
export default ChangeLog;
