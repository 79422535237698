import React, { useEffect, useState } from 'react';
import { FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { DeleteOutlined } from 'src/components/mui/icons';
import { Table, TableHead, TableBody, TableCell } from 'src/components/mui';
import { selectUnavailabilities } from 'src/AdminApp/modules/deliveryVanTemplates/selectors';
import moment from 'moment';
import { toast } from 'src/components/SimpleToast';
import { getVanUnavailabilities, updateVanUnavailability, } from 'src/AdminApp/modules/deliveryVanTemplates/actions';
import { DATE_YMD_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
import { StyledTableRow } from './DeliveryVanTemplate.styles';
const TemplatesDrawerUnavailabilityTable = ({ model, onEditUnavailability, showInactive, }) => {
    const dispatch = useDispatch();
    const vanUnavailabilities = useSelector(selectUnavailabilities);
    const [submitting, setSubmitting] = useState(false);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    useEffect(() => {
        if (model && !submitting) {
            dispatch(getVanUnavailabilities(model.id, { timezone: timeZone }));
        }
    }, [dispatch, model, timeZone, submitting]);
    const handleSelectUnavailability = (value) => {
        onEditUnavailability(Object.assign({}, value));
    };
    const getDateFormatted = (date, format) => date ? moment(date).format(format) : '';
    return (<Table>
      <TableHead style={{ backgroundColor: '#eee' }}>
        <StyledTableRow>
          <TableCell>Date</TableCell>
          <TableCell>Start Time - End Time</TableCell>
          <TableCell />
        </StyledTableRow>
      </TableHead>
      <TableBody>
        <FieldArray name="templates">
          {() => (vanUnavailabilities === null || vanUnavailabilities === void 0 ? void 0 : vanUnavailabilities.length) < 1
            ? null
            : vanUnavailabilities === null || vanUnavailabilities === void 0 ? void 0 : vanUnavailabilities.map((row, index) => {
                const dateFinish = moment.utc(getDateFormatted(row.finish, DATE_YMD_FORMAT));
                const today = new Date();
                const finish = new Date();
                finish.setDate(dateFinish.date());
                finish.setMonth(dateFinish.month());
                finish.setFullYear(dateFinish.year());
                if (showInactive ||
                    row.finish === null ||
                    today <= finish) {
                    return (<StyledTableRow key={row.id} onClick={() => {
                            handleSelectUnavailability(vanUnavailabilities[index]);
                        }}>
                          <TableCell>
                            <div>
                              {row.start &&
                            getDateFormatted(row.start, DATE_YMD_FORMAT)}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>{`${getDateFormatted(row.start, TIME_12H_FORMAT)} - ${getDateFormatted(row.finish, TIME_12H_FORMAT)}`}</div>
                          </TableCell>
                          <TableCell>
                            <div>
                              <DeleteOutlined onClick={(e) => {
                            e.stopPropagation();
                            setSubmitting(true);
                            dispatch(updateVanUnavailability(Object.assign(Object.assign({}, vanUnavailabilities[index]), { active: false }))).then((response) => {
                                setSubmitting(false);
                                if (response && !response.error) {
                                    toast.success('Unavailability successfully deactivated.');
                                }
                            });
                        }}/>
                            </div>
                          </TableCell>
                        </StyledTableRow>);
                }
                return null;
            })}
        </FieldArray>
      </TableBody>
    </Table>);
};
export default TemplatesDrawerUnavailabilityTable;
