import React from 'react';
import { ActionButton } from 'src/components/buttons';
import useAssets from 'src/PartnersApp/pages/PartnerManagement/PartnerDetailsDialog/Assets/useAssets';
import { AssetsActions, AssetsContainer } from './Assets.styles';
import Image from './components/Image/Image';
import Uploader from './components/Uploader';
const Assets = ({ onClose = () => { }, partner }) => {
    const { handleClose, handleDelete, assets } = useAssets({ onClose, partner });
    return (<>
      <AssetsContainer hasAssets={(assets === null || assets === void 0 ? void 0 : assets.length) > 0}>
        <Uploader partner={partner} hasAssets={(assets === null || assets === void 0 ? void 0 : assets.length) > 0}/>
        {assets === null || assets === void 0 ? void 0 : assets.map((a) => (<Image source={a.fileUrl} alt={a.fileName} onDelete={handleDelete(a.id)}/>))}
      </AssetsContainer>

      <AssetsActions>
        <ActionButton onClick={handleClose}>Close</ActionButton>
      </AssetsActions>
    </>);
};
export default Assets;
