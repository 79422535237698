const RESPONSIBLE_PARTIES_COLUMNS = [
    { title: 'Name', key: 'name', sortable: true },
    { title: 'Status', key: 'status', sortable: true },
    { title: 'Tax type', key: 'taxType', sortable: true },
    {
        title: 'Responsible Party Type',
        key: 'responsiblePartyTypeName',
        sortable: true,
    },
    {
        title: 'Authorization Number',
        key: 'authorizationNumber',
        sortable: true,
    },
    { title: 'Payment required', key: 'paymentRequired', sortable: true },
    { title: 'Generates Revenue', key: 'generatesRevenue', sortable: true },
    { title: '', key: 'edit' },
];
export default RESPONSIBLE_PARTIES_COLUMNS;
