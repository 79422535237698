import React from 'react';
import { Container, FilterItem, ItemsGroup, Item, } from './LoadPlaceholder.styles';
const LoadPlaceholder = ({ itemsCount }) => {
    const i = new Array(itemsCount).fill(1);
    return (<Container>
      <FilterItem variant="rectangular"/>
      <ItemsGroup>
        {i.map((_, idx) => (<Item variant="rectangular" key={idx}/>))}
      </ItemsGroup>
    </Container>);
};
export default LoadPlaceholder;
