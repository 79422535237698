import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, IconButton, Toolbar, Grid } from 'src/components/mui';
import { Menu as MenuIcon, Close as CloseIcon, AccountCircle, } from 'src/components/mui/icons';
import { NavLink } from 'src/containers/utils';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
import Menu from '../Menu/Menu';
import { Gap, CloseButton, SignOutLink, BottomBlock, TopBlock, DrawerHeader, ActionIcon, MobileDrawer, } from './MobileAppBar.styles';
export const TOP_OFFSET = 80;
const Logo = () => (<img style={{ width: 50 }} src={`${STATIC_URL_BASE}anms/favicons/favicon-96x96.png`} alt="RepairSmith"/>);
const MobileAppBar = ({ location }) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const toggleDrawer = (o) => (event) => {
        if (event.type === 'keydown' &&
            (event.key === 'Tab' ||
                event.key === 'Shift')) {
            return;
        }
        setOpen(o);
    };
    useEffect(() => {
        setOpen(false);
    }, [location]);
    return (<>
      <AppBar color="default" position="fixed">
        <Toolbar variant="dense" style={{ backgroundColor: 'black' }}>
          <NavLink to="/">
            <img style={{ width: 50 }} src={`${STATIC_URL_BASE}anms/favicons/favicon-96x96.png`} alt="AutoNation Mobile Service"/>
          </NavLink>
          <Gap />
          <IconButton edge="end" color="primary" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon style={{ color: 'white' }}/>
          </IconButton>
        </Toolbar>
      </AppBar>

      <MobileDrawer anchor="right" open={open} onClose={toggleDrawer(false)} keepMounted>
        <DrawerHeader>
          <NavLink to="/">
            <Logo />
          </NavLink>
          <span>Menu</span>
          <CloseButton color="primary" onClick={toggleDrawer(false)}>
            <CloseIcon />
          </CloseButton>
        </DrawerHeader>

        <TopBlock>
          <Menu />
        </TopBlock>
        <BottomBlock container style={{ justifyContent: 'space-between' }}>
          <Grid item>
            <ActionIcon onClick={() => history.push('/my-profile')}>
              <AccountCircle htmlColor={colors.default}/>
            </ActionIcon>
          </Grid>
          <Grid item>
            <SignOutLink to="/logout">Sign Out</SignOutLink>
          </Grid>
        </BottomBlock>
      </MobileDrawer>
    </>);
};
export default MobileAppBar;
