import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditToggle } from 'src/AdminApp/containers/payments/sharedComponents';
import { priceModifiedSinceTaxCalculation } from 'src/AdminApp/containers/requests/Invoice/InvoiceForm/utils';
import { setPaymentDrawerAmount } from 'src/AdminApp/modules/paymentDrawer/actions';
import { selectPaymentDrawerAmount, selectPaymentDrawerInvoice, selectPaymentDrawerResponsibleParties, selectPaymentDrawerScreen, selectPaymentDrawerTotalInvoicePrice, selectPaymentDrawerWorkOrderPayments, selectPaymentDrawerWorkOrderResponsibleParty, } from 'src/AdminApp/modules/paymentDrawer/selectors';
import { InputAdornment } from 'src/components/mui';
import payerStrings from '../payerStrings';
import { AmountInput } from '../sharedComponents.styles';
import { Amount, BalanceCell, BalanceOwed, BalanceRow, BalanceTracker, PaymentAmount, } from './HomePage.styles';
const HomePage = ({ refund = false }) => {
    var _a, _b, _c;
    const [isEditingAmount, setIsEditingAmount] = useState(false);
    const dispatch = useDispatch();
    const amount = useSelector(selectPaymentDrawerAmount);
    const invoice = useSelector(selectPaymentDrawerInvoice);
    const screen = useSelector(selectPaymentDrawerScreen);
    const responsibleParties = (_a = useSelector(selectPaymentDrawerResponsibleParties)) !== null && _a !== void 0 ? _a : [];
    const responsiblePartyType = screen === 'home' && responsibleParties[0];
    const totalInvoicePrice = useSelector(selectPaymentDrawerTotalInvoicePrice);
    const workOrderPayments = (_b = useSelector(selectPaymentDrawerWorkOrderPayments)) !== null && _b !== void 0 ? _b : [];
    const workOrderResponsibleParty = useSelector(selectPaymentDrawerWorkOrderResponsibleParty);
    const getPaymentsByResponsibleParty = (workOrderResponsiblePartyId) => workOrderPayments.filter((i) => i.workOrderResponsiblePartyId === workOrderResponsiblePartyId);
    const getPaymentsReceivedTotalByResponsibleParty = (workOrderResponsiblePartyId) => {
        const payments = getPaymentsByResponsibleParty(workOrderResponsiblePartyId);
        return payments === null || payments === void 0 ? void 0 : payments.reduce((prev, current) => (current === null || current === void 0 ? void 0 : current.amount) && !(current === null || current === void 0 ? void 0 : current.removed) ? prev + current.amount : prev, 0);
    };
    let balanceOwed = 0;
    let paymentsReceivedTotal = 0;
    if (invoice) {
        balanceOwed = (_c = invoice === null || invoice === void 0 ? void 0 : invoice.balanceOwed) !== null && _c !== void 0 ? _c : 0;
        paymentsReceivedTotal = (invoice === null || invoice === void 0 ? void 0 : invoice.paymentsReceivedTotal)
            ? invoice === null || invoice === void 0 ? void 0 : invoice.paymentsReceivedTotal
            : 0;
    }
    else if (workOrderResponsibleParty) {
        paymentsReceivedTotal = getPaymentsReceivedTotalByResponsibleParty(workOrderResponsibleParty.id);
    }
    const startAdornment = (<InputAdornment position="start">{refund ? '-' : ''}$</InputAdornment>);
    const getBalanceOwedColor = () => {
        const recalculateTax = invoice
            ? priceModifiedSinceTaxCalculation(invoice)
            : null;
        if (balanceOwed === 0) {
            return 'green';
        }
        if (balanceOwed === totalInvoicePrice && !recalculateTax) {
            return 'red';
        }
        return 'darkGold';
    };
    const balanceColor = getBalanceOwedColor();
    const handleChange = (e) => {
        var _a, _b;
        dispatch(setPaymentDrawerAmount((_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null));
    };
    const payerType = payerStrings[`${responsiblePartyType ? responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.name : ''}_PAYMENTS`];
    return (<div>
      <BalanceTracker>
        <BalanceRow>
          <BalanceCell>{payerType} Payments Received:</BalanceCell>
          <BalanceCell>${paymentsReceivedTotal.toFixed(2)}</BalanceCell>
        </BalanceRow>
        <BalanceRow>
          <BalanceCell>{payerType} Balance Owed:</BalanceCell>
          <BalanceCell>
            <BalanceOwed color={balanceColor}>
              ${balanceOwed.toFixed(2)}
            </BalanceOwed>
          </BalanceCell>
        </BalanceRow>
      </BalanceTracker>
      <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
        <PaymentAmount>
          {isEditingAmount ? (<AmountInput type="number" name="amount" value={amount} onChange={handleChange} InputProps={{
                startAdornment,
            }}/>) : (<Amount>
              {refund && '-'}${amount}
            </Amount>)}
        </PaymentAmount>
        <EditToggle style={{ marginLeft: '6px' }} handleClick={setIsEditingAmount} isEditing={isEditingAmount}/>
      </div>
    </div>);
};
export default HomePage;
