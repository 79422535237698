import React from 'react';
import SimpleAutosuggest from 'src/components/SimpleAutosuggest';
const ResponsiblePartySelect = ({ onSelect, disabled, responsibleParties, initialValue, }) => {
    const handleSearch = (value) => {
        var _a;
        const filteredSuggestions = (_a = responsibleParties === null || responsibleParties === void 0 ? void 0 : responsibleParties.filter((el) => { var _a; return (_a = el === null || el === void 0 ? void 0 : el.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(value === null || value === void 0 ? void 0 : value.toLowerCase()); }).map((el) => {
            var _a;
            return Object.assign({ label: `${(_a = el.suffix) !== null && _a !== void 0 ? _a : ''} ${el.name}` }, el);
        })) !== null && _a !== void 0 ? _a : [];
        return filteredSuggestions;
    };
    return (<SimpleAutosuggest onSearch={handleSearch} onSelect={(suggestion) => {
            const rp = responsibleParties === null || responsibleParties === void 0 ? void 0 : responsibleParties.find((rp) => rp.id === suggestion.id);
            if (rp) {
                onSelect(rp);
            }
        }} initialValue={initialValue} fieldName="name" fieldToInput="label" disabled={disabled} autoFocus={false} placeholder="Search by Responsible Party Name" suggestions={responsibleParties === null || responsibleParties === void 0 ? void 0 : responsibleParties.map((rp) => {
            var _a;
            return ({
                id: rp.id,
                name: `${(rp === null || rp === void 0 ? void 0 : rp.suffix) ? `[${rp.suffix}]` : ''} ${rp === null || rp === void 0 ? void 0 : rp.name}`,
                label: `${(rp === null || rp === void 0 ? void 0 : rp.suffix) ? `[${rp.suffix}]` : ''} ${rp === null || rp === void 0 ? void 0 : rp.name}`,
                groupName: (_a = rp === null || rp === void 0 ? void 0 : rp.groupName) !== null && _a !== void 0 ? _a : undefined,
            });
        })} noOptions="No responsible parties found" groupBy={(option) => { var _a; return (_a = option.groupName) !== null && _a !== void 0 ? _a : ''; }}/>);
};
export default ResponsiblePartySelect;
