import { theme } from 'src/styles/theme';
import styled from 'styled-components';
export const RowWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: stretch;
  padding-top: ${({ disableTopPadding }) => disableTopPadding === 'true' ? 0 : '24px'};
  width: 100%;

  > *,
  button {
    width: 100%;
  }

  > :not(:first-child) {
    margin-top: 16px;
  }

  ${theme.breakpoints.up('sm')} {
    flex-direction: row;

    > :not(:first-child) {
      margin-top: 0;
    }

    > :not(:last-child) {
      margin-right: 16px;
    }
  }
`;
