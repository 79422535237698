import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { map, capitalize } from 'lodash';
import { Grid } from 'src/components/mui';
import { RadioButtonChecked as RadioButtonCheckedIcon, RadioButtonUnchecked as RadioButtonUncheckedIcon, } from 'src/components/mui/icons';
import { selectConsumerMethods } from 'src/AdminApp/modules/payments/selectors';
import SimpleChip from 'src/components/SimpleChip';
import { colors } from 'src/styles/theme';
import DropdownOptions from '../PaymentModule/DropdownOptions';
import { cardSvgByType } from './utils/cardSvgByType';
import { formatDateForCard } from './utils/formatDateForCard';
export const CardButton = styled.div `
  border-top: 1px solid ${colors.lightGrey};
  padding: 16px 0;
  &:last-child {
    border-bottom: 1px solid ${colors.lightGrey};
  }
`;
const CardDisplay = styled.div `
  padding: 16px 0;
`;
const CardTypeImg = styled.img `
  height: 40px;
  width: 40px;
  margin-right: 16px;
`;
const AlignedImgWrapper = styled.div `
  display: flex;
  color: ${({ active }) => (active ? colors.primary : colors.darkGrey)};
`;
const Text = styled.div `
  font-weight: bold;
`;
const ExpireDateText = styled.div `
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: ${colors.darkGrey};
  margin-top: 4px;
  display: flex;
  align-items: center;
`;
const DefaultLabelWrapper = styled.span `
  padding-left: ${({ excludeMargin }) => (excludeMargin ? 0 : '8px')};
`;
export const Card = ({ active, canRemove = false, card, onClick, onRemove = () => { }, viewOnly = false, }) => {
    const contents = () => {
        const isAffirm = card.methodType === 'AFFIRM';
        const isCreditCard = card.methodType === 'CREDIT_CARD';
        const cardType = card.cardType ? card.cardType : 'CARD';
        return (<Grid container style={{ alignItems: 'center' }}>
        <Grid item>
          <AlignedImgWrapper>
            <CardTypeImg src={cardSvgByType(isAffirm ? 'affirm' : cardType.toLocaleLowerCase())} alt={cardType}/>
          </AlignedImgWrapper>
        </Grid>
        {isCreditCard && (<Grid item xs>
            <Text>
              {capitalize(cardType)} ending {card.lastFourDigits}
            </Text>
            <ExpireDateText>
              Exp {formatDateForCard(card.monthExpiration, card.yearExpiration)}
              {card && card.defaultMethod && (<DefaultLabelWrapper>
                  <SimpleChip label="DEFAULT" variant="outlined" $textColor={colors.GRAY_76}/>
                </DefaultLabelWrapper>)}
            </ExpireDateText>
          </Grid>)}
        {isAffirm && (<Grid item xs>
            <Text>Affirm Monthly Payments</Text>
            <ExpireDateText>
              {card && card.defaultMethod && (<DefaultLabelWrapper excludeMargin>
                  <SimpleChip label="DEFAULT" variant="outlined" $textColor={colors.GRAY_76}/>
                </DefaultLabelWrapper>)}
            </ExpireDateText>
          </Grid>)}
        {onClick && (<Grid item>
            <AlignedImgWrapper active={active}>
              {active ? (<RadioButtonCheckedIcon />) : (<RadioButtonUncheckedIcon />)}
            </AlignedImgWrapper>
          </Grid>)}
        {canRemove && (<Grid item>
            <DropdownOptions component="payment-method" id={card.id} label="Delete Payment Method" onRemove={() => onRemove(card)}/>
          </Grid>)}
      </Grid>);
    };
    return viewOnly ? (<CardDisplay>{contents()}</CardDisplay>) : (<CardButton onClick={() => {
            if (onClick)
                onClick(card);
        }}>
      {contents()}
    </CardButton>);
};
const SavedCards = ({ onChange }) => {
    const { referenceNum } = useParams();
    const [activeCard, setActiveCard] = useState();
    const params = { match: { params: { referenceNum } } };
    const cards = useSelector((state) => selectConsumerMethods(state, params));
    useEffect(() => {
        const defaultCard = cards.find((card) => card.defaultMethod);
        setActiveCard(defaultCard);
    }, [cards]);
    const onClickCard = (newCard) => {
        if (newCard === activeCard) {
            setActiveCard(undefined);
        }
        else {
            setActiveCard(newCard);
        }
    };
    useEffect(() => {
        if (!activeCard)
            return;
        onChange(activeCard);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCard]);
    return (<div>
      {map(cards, (card) => (<Card card={card} active={card === activeCard} onClick={onClickCard}/>))}
    </div>);
};
export default SavedCards;
