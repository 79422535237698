var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from 'src/components/mui';
import { Clear } from 'src/components/mui/icons';
import { FieldArray, Formik } from 'formik';
import { isNaN, parseInt } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditableInput from 'src/components/form/EditableInput';
import EditableSearchPartDistributorLocations from 'src/components/form/EditableSearchPartDistributorLocations';
import SmallIconButton from 'src/components/icons/SmallIconButton';
import { Tooltip } from 'src/components/tooltips';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { getPartDistributors } from 'src/PopsApp/modules/vendors/actions';
import { selectPartDistributors } from 'src/PopsApp/modules/vendors/selectors';
import styled from 'styled-components';
import * as Yup from 'yup';
const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  td {
    padding: 15px !important;
  }
`;
const validationSchema = Yup.object().shape({
    homeStores: Yup.array()
        .of(Yup.object().shape({
        partDistributorLocationId: Yup.string().nullable().notRequired(),
        priority: Yup.number().positive().integer().nullable().notRequired(),
    }, [['partDistributorLocationId', 'priority']]))
        .test('uniquePriority', 'Priority values must be unique.', (values) => {
        const filtered = values.map((v) => v.priority).filter((v) => !!v);
        return filtered.length === new Set(filtered).size;
    })
        .test('priorityRequired', 'Non hub locations must have a priority', (values) => !values
        .filter((v) => v.name !== 'RepairSmith Hub')
        .some((v) => v.priority === null && v.partDistributorLocationId !== null))
        .test('locationRequired', 'Cannot have a priority value without a location', (values) => !values.some((v) => v.priority !== null && v.partDistributorLocationId === null)),
});
const DeliveryVanHomeStoresPage = ({ model, onSubmit, canEdit, }) => {
    const dispatch = useDispatch();
    const partDistributors = useSelector(selectPartDistributors);
    useEffect(() => {
        if (!partDistributors)
            dispatch(getPartDistributors());
    }, [dispatch, partDistributors]);
    const rows = useMemo(() => {
        return partDistributors === null || partDistributors === void 0 ? void 0 : partDistributors.filter((d) => d.name !== 'Other' &&
            d.name !== 'Customer Provided' &&
            d.status === 'ACTIVE').map((distributor) => {
            var _a;
            const homeStore = (_a = model === null || model === void 0 ? void 0 : model.deliveryVanHomeStores) === null || _a === void 0 ? void 0 : _a.find((store) => store.partDistributorName === distributor.name);
            return {
                partDistributorId: distributor.id,
                name: distributor.name,
                partDistributorLocationId: homeStore === null || homeStore === void 0 ? void 0 : homeStore.partDistributorLocationId,
                address: homeStore === null || homeStore === void 0 ? void 0 : homeStore.partDistributorAddress,
                priority: homeStore === null || homeStore === void 0 ? void 0 : homeStore.priority,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partDistributors]);
    const getValidationTooltip = (errors) => {
        return typeof errors.homeStores === 'string'
            ? errors.homeStores
            : 'Priority must be a positive integer.';
    };
    const getPriority = (homeStores, index) => {
        var _a;
        if (homeStores && ((_a = homeStores[index]) === null || _a === void 0 ? void 0 : _a.name) === 'RepairSmith Hub') {
            return null;
        }
        return homeStores ? homeStores[index].priority : 0;
    };
    return (<Formik initialValues={{
            homeStores: (rows === null || rows === void 0 ? void 0 : rows.length) > 0 &&
                (rows === null || rows === void 0 ? void 0 : rows.sort((a, b) => {
                    if (a.name === 'RepairSmith Hub')
                        return -1;
                    if (b.name === 'RepairSmith Hub')
                        return 1;
                    return a.name.localeCompare(b.name);
                }).map((row) => {
                    var _a, _b, _c, _d, _e;
                    return {
                        name: (_a = row === null || row === void 0 ? void 0 : row.name) !== null && _a !== void 0 ? _a : null,
                        partDistributorId: (_b = row === null || row === void 0 ? void 0 : row.partDistributorId) !== null && _b !== void 0 ? _b : null,
                        address: (_c = row === null || row === void 0 ? void 0 : row.address) !== null && _c !== void 0 ? _c : null,
                        partDistributorLocationId: (_d = row === null || row === void 0 ? void 0 : row.partDistributorLocationId) !== null && _d !== void 0 ? _d : null,
                        priority: row.name === 'RepairSmith Hub'
                            ? null
                            : (_e = row === null || row === void 0 ? void 0 : row.priority) !== null && _e !== void 0 ? _e : null,
                    };
                })),
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield onSubmit(values.homeStores
                    .filter((r) => !!r.partDistributorLocationId)
                    .map((r) => {
                    return {
                        partDistributorLocationId: r.partDistributorLocationId,
                        priority: r.priority,
                    };
                }));
            }
            finally {
                setSubmitting(false);
            }
        })}>
      {({ values, isSubmitting, isValid, errors, setFieldValue, submitForm, }) => (<>
          <SimpleDrawerContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: '#eee' }}>
                  <TableCell>Part Distributor</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell />
                </TableHead>
                <TableBody>
                  <FieldArray name="homeStores">
                    {() => values.homeStores &&
                values.homeStores.length > 0 &&
                values.homeStores.map((row, index) => (<StyledTableRow>
                          <TableCell>
                            <div>{values.homeStores[index].name}</div>
                          </TableCell>
                          <TableCell>
                            <EditableSearchPartDistributorLocations partDistributorId={row.partDistributorId} value={values.homeStores[index].address} onUpdate={(label, value) => {
                        setFieldValue(`homeStores.${index}.address`, label);
                        setFieldValue(`homeStores.${index}.partDistributorLocationId`, value.id);
                    }}/>
                          </TableCell>
                          <TableCell>
                            {values.homeStores[index].name !==
                        'RepairSmith Hub' && (<EditableInput isEditable={canEdit} value={getPriority(values.homeStores, index)} defaultValue="None" onUpdate={(value) => {
                            const priority = !isNaN(parseInt(value))
                                ? parseInt(value)
                                : null;
                            setFieldValue(`homeStores.${index}.priority`, priority);
                        }}/>)}
                          </TableCell>
                          <TableCell>
                            <SmallIconButton aria-label="Clear Home Store" title="Clear" onClick={() => {
                        setFieldValue(`homeStores.${index}`, Object.assign(Object.assign({}, (values.homeStores
                            ? values.homeStores[index]
                            : {})), { priority: null, address: null, partDistributorLocationId: null }));
                    }}>
                              <Clear />
                            </SmallIconButton>
                          </TableCell>
                        </StyledTableRow>))}
                  </FieldArray>
                </TableBody>
              </Table>
            </TableContainer>
          </SimpleDrawerContent>
          <SimpleDrawerFooter>
            <Tooltip title={!isValid
                ? getValidationTooltip(errors)
                : ''}>
              <span>
                <FooterActionButton onClick={submitForm} disabled={!canEdit || isSubmitting || !isValid}>
                  Save Home Stores
                </FooterActionButton>
              </span>
            </Tooltip>
          </SimpleDrawerFooter>
        </>)}
    </Formik>);
};
export default DeliveryVanHomeStoresPage;
