import moment from 'moment';
import { createSelector } from 'reselect';
const filterActive = (now) => ({ effectiveTo }) => !effectiveTo || moment.utc(effectiveTo).isSameOrAfter(now, 'day');
const filterArchived = (now) => ({ effectiveTo }) => effectiveTo && moment.utc(effectiveTo).isBefore(now, 'day');
export const selectGeoManagement = (state) => state.admin.geoManagement;
export const selectSlidePanelOpen = createSelector(selectGeoManagement, (geoManagement) => geoManagement === null || geoManagement === void 0 ? void 0 : geoManagement.slidePanelOpen);
export const selectSlidePanelParams = createSelector(selectGeoManagement, (geoManagement) => geoManagement === null || geoManagement === void 0 ? void 0 : geoManagement.params);
export const selectSlidePanelPage = createSelector(selectGeoManagement, (geoManagement) => { var _a; return (_a = geoManagement === null || geoManagement === void 0 ? void 0 : geoManagement.params) === null || _a === void 0 ? void 0 : _a.selectedPage; });
export const selectLaborRates = createSelector(selectGeoManagement, (geoManagement) => geoManagement === null || geoManagement === void 0 ? void 0 : geoManagement.laborRates);
export const selectSelectedLaborRates = createSelector(selectGeoManagement, (geoManagement) => geoManagement === null || geoManagement === void 0 ? void 0 : geoManagement.selectedLaborRate);
export const selectRegionModel = createSelector(selectSlidePanelParams, (params) => params === null || params === void 0 ? void 0 : params.regionModel);
export const selectRegionLaborRates = createSelector(selectRegionModel, (regionModel) => {
    var _a;
    const now = moment.utc();
    return (_a = regionModel === null || regionModel === void 0 ? void 0 : regionModel.laborRates) === null || _a === void 0 ? void 0 : _a.filter(filterActive(now));
});
export const selectRegionHasLaborRates = createSelector(selectRegionLaborRates, (laborRates) => (laborRates !== null && laborRates !== void 0 ? laborRates : []).length > 0);
export const selectRegionArchivedLaborRates = createSelector(selectRegionModel, (regionModel) => {
    var _a;
    const now = moment.utc();
    return (_a = regionModel === null || regionModel === void 0 ? void 0 : regionModel.laborRates) === null || _a === void 0 ? void 0 : _a.filter(filterArchived(now));
});
export const selectRegionHasArchivedLaborRates = createSelector(selectRegionArchivedLaborRates, (laborRates) => (laborRates !== null && laborRates !== void 0 ? laborRates : []).length > 0);
export const selectMetroModel = createSelector(selectSlidePanelParams, (params) => params === null || params === void 0 ? void 0 : params.metroModel);
export const selectMetroLaborRates = createSelector(selectMetroModel, (metroModel) => {
    var _a;
    const now = moment.utc();
    return (_a = metroModel === null || metroModel === void 0 ? void 0 : metroModel.laborRates) === null || _a === void 0 ? void 0 : _a.filter(filterActive(now));
});
export const selectMetroHasLaborRates = createSelector(selectMetroLaborRates, (laborRates) => (laborRates !== null && laborRates !== void 0 ? laborRates : []).length > 0);
export const selectMetroArchivedLaborRates = createSelector(selectMetroModel, (metroModel) => {
    var _a;
    const now = moment.utc();
    return (_a = metroModel === null || metroModel === void 0 ? void 0 : metroModel.laborRates) === null || _a === void 0 ? void 0 : _a.filter(filterArchived(now));
});
export const selectMetroHasArchivedLaborRates = createSelector(selectMetroArchivedLaborRates, (laborRates) => (laborRates !== null && laborRates !== void 0 ? laborRates : []).length > 0);
export const selectArchivedLaborRates = createSelector(selectGeoManagement, (geoManagement) => geoManagement.selectedArchivedLaborRate);
export const selectHasPendingRegionLaborRates = createSelector(selectRegionLaborRates, (laborRates) => !!(laborRates === null || laborRates === void 0 ? void 0 : laborRates.find(({ effectiveTo }) => !effectiveTo)));
export const selectHasPendingMetroLaborRates = createSelector(selectMetroLaborRates, (laborRates) => !!(laborRates === null || laborRates === void 0 ? void 0 : laborRates.find(({ effectiveTo }) => !effectiveTo)));
