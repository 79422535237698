import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import CONFIG from 'src/config';
const useDatadogRum = () => {
    useEffect(() => {
        if (CONFIG.DD_RUM_ENV !== 'local') {
            datadogRum.init({
                applicationId: CONFIG.DD_RUM_APP_ID,
                clientToken: CONFIG.DD_RUM_CLIENT_TOKEN,
                site: 'datadoghq.com',
                service: 'tools-front-end',
                env: CONFIG.DD_RUM_ENV,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 0,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'allow',
            });
        }
    }, []);
};
export default useDatadogRum;
