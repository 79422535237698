import React from 'react';
import { Tooltip } from 'src/components/tooltips';
const getPriceSourceName = (part) => {
    var _a;
    return ((_a = part === null || part === void 0 ? void 0 : part.sources) === null || _a === void 0 ? void 0 : _a.priceSourceName) || getAlternativeSource(part);
};
const getQuantitySourceName = (part) => {
    var _a;
    return (_a = part === null || part === void 0 ? void 0 : part.sources) === null || _a === void 0 ? void 0 : _a.quantitySourceName;
};
const getAlternativeSource = (part) => {
    return part.price === part.amPrice1 || part.price === part.amPrice2
        ? 'After Market'
        : 'OEM';
};
const PricingSource = ({ part }) => {
    const priceSourceName = getPriceSourceName(part);
    const quantitySourceName = getQuantitySourceName(part);
    const priceSourceText = priceSourceName && `Price Source: ${priceSourceName}`;
    const quantitySourceText = quantitySourceName && `Quantity Source: ${quantitySourceName}`;
    const fullSourceText = [priceSourceText, quantitySourceText]
        .filter(Boolean)
        .join(', ');
    return (<Tooltip placement="right" title={fullSourceText}>
      <span>{priceSourceText}</span>
    </Tooltip>);
};
export default PricingSource;
