import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import createAction from 'src/modules/api/utils/createAction';
export const ActionCreators = {
    GET_WORKFLOW: createDefaultActionCreators('GET_WORKFLOW'),
    INIT_WORKFLOW: createDefaultActionCreators('INIT_WORKFLOW'),
    COMPLETE_WORKFLOW_TASK: createDefaultActionCreators('COMPLETE_WORKFLOW_TASK'),
    GET_WORKFLOW_TASK: createDefaultActionCreators('GET_WORKFLOW_TASK'),
    GET_REPAIR_REQUEST_STOPWATCH: createDefaultActionCreators('GET_REPAIR_REQUEST_STOPWATCH'),
    CLEAR_STOPWATCH: createAction('CLEAR_STOPWATCH'),
    STOP_STOPWATCH: createAction('STOP_STOPWATCH'),
    ADD_EXTRA_TIME_REQUEST: createDefaultActionCreators('ADD_EXTRA_TIME_REQUEST'),
    AUTHORIZATION_STARTED_EVENT: createDefaultActionCreators('AUTHORIZATION_STARTED_EVENT'),
};
export const getTechnicianOnSiteWorkflowByRef = (refNumber) => createAPIAction({
    types: [
        ActionCreators.GET_WORKFLOW.FETCH(),
        ActionCreators.GET_WORKFLOW.SUCCESS({
            meta: () => ({ refNumber }),
        }),
        ActionCreators.GET_WORKFLOW.FAILURE(),
    ],
    endpoint: API_URLS.TECHNICIAN.ONSITE_WORKFLOW_WORK_ORDER(refNumber),
    method: 'GET',
});
export const completeTechnicianWorkflowOnSiteSingleTask = (refNumber, task) => createAPIAction({
    types: [
        ActionCreators.COMPLETE_WORKFLOW_TASK.FETCH(),
        ActionCreators.COMPLETE_WORKFLOW_TASK.SUCCESS(),
        ActionCreators.COMPLETE_WORKFLOW_TASK.FAILURE(),
    ],
    endpoint: API_URLS.TECHNICIAN.ONSITE_WORKFLOW_TASK({
        refNumber,
        taskId: task.id,
    }),
    method: 'POST',
    body: task,
});
export const getRepairRequestStopwatch = (refNumber) => createAPIAction({
    types: [
        ActionCreators.GET_REPAIR_REQUEST_STOPWATCH.FETCH(),
        ActionCreators.GET_REPAIR_REQUEST_STOPWATCH.SUCCESS(),
        ActionCreators.GET_REPAIR_REQUEST_STOPWATCH.FAILURE(),
    ],
    endpoint: API_URLS.TECHNICIAN.STOPWATCH(refNumber),
    method: 'GET',
});
export const clearStopwatch = ActionCreators.CLEAR_STOPWATCH;
export const stopStopwatch = ActionCreators.STOP_STOPWATCH;
export const addExtraTimeRequest = (appointmentId, extraTimeRequest) => createAPIAction({
    types: [
        ActionCreators.ADD_EXTRA_TIME_REQUEST.FETCH(),
        ActionCreators.ADD_EXTRA_TIME_REQUEST.SUCCESS(),
        ActionCreators.ADD_EXTRA_TIME_REQUEST.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ADD_STOPWATCH_ADDITIONAL_TIME(appointmentId),
    method: 'POST',
    body: extraTimeRequest,
});
export const sendAuthorizationStartedEvent = (referenceNumber, consumerId) => createAPIAction({
    types: [
        ActionCreators.AUTHORIZATION_STARTED_EVENT.FETCH(),
        ActionCreators.AUTHORIZATION_STARTED_EVENT.SUCCESS(),
        ActionCreators.AUTHORIZATION_STARTED_EVENT.FAILURE(),
    ],
    endpoint: API_URLS.AUTHORIZATION_EVENT(),
    method: 'POST',
    body: { referenceNumber, consumerId },
});
