import React from 'react';
import styled from 'styled-components';
import { Button, Grid } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { GetApp, Phone, FileCopy } from 'src/components/mui/icons';
import CONFIG from 'src/config';
import FmcWorkAuthorizationType from 'src/AdminApp/models/enums/FmcWorkAuthorizationType';
import { colors } from 'src/styles/theme';
import useIsMobile from 'src/hooks/useIsMobile';
import AdvisorRequested from './AdvisorRequested';
const ActionsFooterStyled = styled.div `
  display: flex;
  justify-content: space-between;
  background-color: ${colors.GRAY_F8};
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  margin-top: 20px;
  padding: 20px 0px 20px 10px;
`;
const AdvisorRequestedWrapper = styled.div `
  display: inline-flex;
  margin: 0 10px;
`;
const Wrapper = styled.div `
  margin-left: auto;
  display: flex;
  align-items: center;
`;
const ButtonRightWrapper = styled(Grid) `
  margin-left: 10px !important;
`;
const ActionsFooter = ({ isRequestNewOrOpen, isProvideQuoteButtonEnabled, onSendQuote, onCopyQuoteLink, onDownloadQuote, onSalesCallRequest, advisorCallRequestedDate, onSendForApproval, isSendForApprovalButtonEnabled, isSendForApprovalButtonVisible, onQuickOrder, isQuickOrderEnabled, isQuickOrderVisible, }) => {
    const isMobile = useIsMobile();
    return (<ActionsFooterStyled>
      <Grid container style={{ alignItems: 'center' }} item>
        <Grid item>
          <Button startIcon={<FileCopy />} onClick={onCopyQuoteLink} color="primary">
            Copy Customer Quote Link
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onDownloadQuote} color="primary" startIcon={<GetApp />}>
            Download Quote
          </Button>
        </Grid>
        {CONFIG.REACT_APP_SHOW_REQUEST_SALES_CALL_CTA === 'true' && (<Grid item>
            <Tooltip disabled={!isRequestNewOrOpen} title="Request a Sales Call">
              <Button startIcon={<Phone />} onClick={onSalesCallRequest} color="primary">
                Request Sales Call
              </Button>
            </Tooltip>
          </Grid>)}

        {advisorCallRequestedDate ? (<Grid item>
            <AdvisorRequestedWrapper>
              <AdvisorRequested requestedDate={advisorCallRequestedDate}/>
            </AdvisorRequestedWrapper>
          </Grid>) : null}
        <Wrapper>
          <Tooltip disabled={isProvideQuoteButtonEnabled} title="You cannot confirm an appointment without filling out values for all repairs in the Total column, filling out their parts/fluids costs & clicking Send Quote." placement={isMobile ? 'bottom' : 'left'}>
            <ButtonRightWrapper>
              <Button onClick={onSendQuote} disabled={!isProvideQuoteButtonEnabled} variant="contained" color="primary">
                Send Quote
              </Button>
            </ButtonRightWrapper>
          </Tooltip>
          {isSendForApprovalButtonVisible && (<Tooltip disabled={isSendForApprovalButtonEnabled} title="You cannot send for approval without the proper settings and completed measurements">
              <ButtonRightWrapper>
                <Button onClick={() => onSendForApproval(FmcWorkAuthorizationType.QUOTE)} disabled={!isSendForApprovalButtonEnabled} variant="contained" color="primary">
                  Send For Approval
                </Button>
              </ButtonRightWrapper>
            </Tooltip>)}
          {isQuickOrderVisible && (<ButtonRightWrapper>
              <Button onClick={() => onQuickOrder()} disabled={!isQuickOrderEnabled} variant="contained" color="primary">
                Quick Order
              </Button>
            </ButtonRightWrapper>)}
        </Wrapper>
      </Grid>
    </ActionsFooterStyled>);
};
export default ActionsFooter;
