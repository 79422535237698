// env variables are set by .env files.
// they are depended by NODE_ENV variable that is set by react-scripts
// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
const config = Object.assign({ CLIENT_API_URL: process.env.REACT_APP_CLIENT_API_URL || '/api', CLIENT_AUTH_URL: process.env.REACT_APP_CLIENT_AUTH_URL || '/auth', CLIENT_GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_SERVER_API_URL || '/graphql', MAPBOX_TOKEN: process.env.MAPBOX_TOKEN ||
        'pk.eyJ1Ijoic2VydnVzIiwiYSI6ImNqaDZxc2RwbDAwMHMzM3B6ZDd6OHllMXUifQ.cuscBMGgiVXwGVEgDiAUxA', GOOGLE_PLACES_API_KEY: process.env.REACT_APP_GOOGLE_PLACES_API_KEY ||
        'AIzaSyDj5qIkkiw9Zfh_EvyI2TJwmZ4EjR1tblg', PROVIDE_QUOTE_FREEZE_DURATION_SECONDS: process.env.PROVIDE_QUOTE_FREEZE_DURATION_SECONDS || '300', ANALYTICS_WRITE_KEY: process.env.REACT_APP_ANALYTICS_WRITE_KEY ||
        'F4IKfqqBTyRetf7A6V1AzzayRrOiAKcv', CONTENTFUL_SPACE: 'dqro86x3e92u', CONTENTFUL_PUBLISHED_TOKEN: '507517dd9308da1b6b60437ca789322a58ae036307d9d7af2bc8fd77c38f1e41', CONTENTFUL_DRAFT_TOKEN: '78082fd1e66daaba9ae9c43cb7ce8966b6c7fda751b65c33455bdbe3b1de61ce', CONTENTFUL_TERMS_ID: 'an73f1ITBYYkoqQsk4022', CONTENTFUL_PRIVACY_ID: '3dtpErUNOEGwo6cWagU602', KAFKA_CONNECT_API_URL: process.env.KAFKA_CONNECT_API_URL || 'http://localhost:8083', REACT_APP_SEND_INVOICE_ENABLED: process.env.REACT_APP_SEND_INVOICE_ENABLED || false, REACT_APP_PAYMENTS_ENABLED: process.env.REACT_APP_PAYMENTS_ENABLED || false, REACT_APP_SPREEDLY_ENV_KEY: '77OzD26DfQ8ma8OhkM4azYakCEY', REACT_APP_POPS_INTEGRATED_ORDERING_ENABLED: process.env.REACT_APP_POPS_INTEGRATED_ORDERING_ENABLED || true, AFFIRM_FINANCIAL_PRODUCT_KEY: process.env.REACT_APP_AFFIRM_FINANCIAL_PRODUCT_KEY || 'HIKZ78HGTOV7AK0M', REACT_APP_PRICING_RULES_UPLOADER_ENABLED: process.env.REACT_APP_PRICING_RULES_UPLOADER_ENABLED || false, REACT_APP_FLEET_VISITS_ENABLED: process.env.REACT_APP_FLEET_VISITS_ENABLED || true, REACT_APP_HOSTNAME: process.env.REACT_APP_HOSTNAME, REACT_APP_POPS_RS_HUBS_ENABLED: process.env.REACT_APP_POPS_RS_HUBS_ENABLED || true, REACT_APP_ZENDESK_CHAT_API_KEY: process.env.REACT_APP_ZENDESK_CHAT_API_KEY ||
        '7a540bb3-1fdb-4062-98a6-4428553e595c', ENABLE_STOPWATCH_DISPLAY: process.env.ENABLE_STOPWATCH_DISPLAY, REACT_APP_CONTENTFUL_SPACE: process.env.REACT_APP_CONTENTFUL_SPACE || 'dqro86x3e92u', REACT_APP_CONTENTFUL_PUBLISHED_TOKEN: process.env.REACT_APP_CONTENTFUL_PUBLISHED_TOKEN ||
        '507517dd9308da1b6b60437ca789322a58ae036307d9d7af2bc8fd77c38f1e41', REACT_APP_CONTENTFUL_DRAFT_TOKEN: process.env.REACT_APP_CONTENTFUL_DRAFT_TOKEN ||
        '78082fd1e66daaba9ae9c43cb7ce8966b6c7fda751b65c33455bdbe3b1de61ce', REACT_APP_CONTENTFUL_ID_UPSELL_SALES_PITCH_TOKENS: process.env.REACT_APP_CONTENTFUL_ID_UPSELL_SALES_PITCH_TOKENS ||
        '13UvOYkNWjQGlUIOuPxIXL', REACT_APP_CONTENTFUL_ID_UPSELL_SALES_PITCH_DISCOUNT: process.env.REACT_APP_CONTENTFUL_ID_UPSELL_SALES_PITCH_DISCOUNT ||
        '5fO30qaNxicjBEaut6hYGD', REACT_APP_CONTENTFUL_ID_UPSELL_SALES_PITCH_DISCOUNT_INTRO: process.env.REACT_APP_CONTENTFUL_ID_UPSELL_SALES_PITCH_DISCOUNT_INTRO ||
        '4b8Gf8GtxHKbtOd9wlT3Gr', REACT_APP_CONTENTFUL_ID_UPSELL_SALES_PITCH_TOKENS_INTRO: process.env.REACT_APP_CONTENTFUL_ID_UPSELL_SALES_PITCH_TOKENS_INTRO ||
        '4uCc0wZ7RefuD87nrQbVl1', REACT_APP_CONTENTFUL_ID_UPSELL_TOKENS_ASK_CUSTOMER: process.env.REACT_APP_CONTENTFUL_ID_UPSELL_TOKENS_ASK_CUSTOMER ||
        '3Yk5ufqBONTujZxKp1dNp4', REACT_APP_CONTENTFUL_ID_UPSELL_DISCOUNTS_ASK_CUSTOMER: process.env.REACT_APP_CONTENTFUL_ID_UPSELL_DISCOUNTS_ASK_CUSTOMER ||
        '1OSbRt0DwrUT2gf4LtzWR5', REACT_APP_CONTENTFUL_ID_UPSELL_AFFIRM_INFO: process.env.REACT_APP_CONTENTFUL_ID_UPSELL_AFFIRM_INFO ||
        '5ByDNGCbvc6arXnlK3d98b', REACT_APP_CHARGEBEE_API_KEY_PUBLISHABLE: process.env.REACT_APP_CHARGEBEE_API_KEY_PUBLISHABLE ||
        'test_5XeQ7cdnGYLGr9oNbklfjbSbeZXcgFuLx', REACT_APP_CHARGEBEE_SITE: process.env.REACT_APP_CHARGEBEE_SITE || 'repairsmith-test', REACT_APP_SHOW_REQUEST_SALES_CALL_CTA: process.env.REACT_APP_SHOW_REQUEST_SALES_CALL_CTA || 'false', REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_HEY_TECH: process.env.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_HEY_TECH ||
        '1FfNQrzEFgltPKw9bBjsO4', REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_TRY_THIS: process.env.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_TRY_THIS ||
        '2C3CbMnrpiRqbq6HjyGtNd', REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_SUCCESS: process.env.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_SUCCESS ||
        '5EfAs6PXkE39N0DR94XdZo', REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_FAILED: process.env.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_FAILED ||
        '4xYZNcaoyC6UmawmDC7iXG', REACT_APP_CONTENTFUL_ID_AUTHORIZATION_PROCESS_PAYMENT_SUBTEXT: process.env.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_PROCESS_PAYMENT_SUBTEXT ||
        '5MYAcJIVUqPcxgdlkHbYGZ', REACT_APP_CONTENTFUL_ID_AUTHORIZATION_CANCEL_SUBMIT_SUBTEXT: process.env.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_CANCEL_SUBMIT_SUBTEXT ||
        '5fSZwP2JI4MOwKVjjZMc0Y', REPAIRSMITH_EMAIL_VALIDATION: process.env.REPAIRSMITH_EMAIL_VALIDATION, REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_SYSTEM_ERROR: process.env.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_SYSTEM_ERROR ||
        '6jUbgPNVTjOq9HljfN0zI1', ENABLE_VISITS_STOPWATCH_DISPLAY: process.env.ENABLE_STOPWATCH_DISPLAY, ENABLE_PART_PICKUP_DISPLAY: process.env.ENABLE_PART_PICKUP_DISPLAY, ENABLE_LOCK_START_TRAVEL_UNTIL_PICKED_UP: process.env.ENABLE_LOCK_START_TRAVEL_UNTIL_PICKED_UP, ENABLE_FORCE_COMPONENT_DB_SELECTION: process.env.ENABLE_FORCE_COMPONENT_DB_SELECTION, LOCKED_DOWN_COMPONENTS: process.env.LOCKED_DOWN_COMPONENTS, MINIMUM_DELIVERY_CHARGE_ID: process.env.MINIMUM_DELIVERY_CHARGE_ID, GENERAL_DIAGNOSIS: process.env.GENERAL_DIAGNOSIS, CUSTOMER_PROVIDED_ID: process.env.CUSTOMER_PROVIDED_ID, CUSTOMER_PROVIDED: process.env.CUSTOMER_PROVIDED, DD_RUM_APP_ID: process.env.DD_RUM_APP_ID || '486a5694-c382-4a00-abf7-d4407c35687c', DD_RUM_CLIENT_TOKEN: process.env.DD_RUM_CLIENT_TOKEN || 'pub9f2d728152488745ba0b094697e5165d', DD_RUM_ENV: process.env.DD_RUM_ENV, POPS_COURIER_DELIVERY_DISTRIBUTORS: 'RepairSmith Hub,AutoNation Dealerships' }, window.RepairSmith);
export default config;
