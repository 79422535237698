import styled from 'styled-components';
import { Grid, Table, TableCell, Typography } from 'src/components/mui';
import { CheckCircle, WarningRounded } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
export const PodWrapper = styled.div `
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;
  padding: 24px 0;
  margin-top: 16px;
  background: white;
`;
export const PodTopRow = styled(Grid) `
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 0 16px 16px;
`;
export const PodBottomRow = styled(Grid) `
  padding: 16px 16px 0;
`;
export const TextRightContent = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 8px;
`;
export const SecondaryDateInfo = styled(Typography) `
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: ${colors.darkGrey};
`;
export const BoldText = styled(Typography) `
  font-weight: bold;
  margin-bottom: 4px;
`;
export const TableStyled = styled(Table) `
  margin-top: 24px;
  margin-bottom: 24px;
  border-top: 1px solid ${colors.lightGrey};
  border-bottom: 1px solid ${colors.lightGrey};
`;
export const PaymentAttribute = styled(Typography) `
  text-decoration: ${({ removed }) => (removed ? 'line-through' : 'none')};
  color: ${({ removed }) => (removed ? colors.GRAY_AA : colors.default)};
`;
export const CollectedBy = styled.span `
  font-weight: normal;
  color: ${colors.GRAY_76};
  font-size: 0.75rem;
  line-height: 1.75;
`;
export const CheckIcon = styled(CheckCircle) `
  margin-right: 4px;
  margin-left: 4px;
  font-size: 18px;
  color: ${colors.success};
`;
export const WarningIcon = styled(WarningRounded) `
  margin-right: 4px;
  margin-left: 4px;
  font-size: 18px;
  color: ${colors.warning};
`;
export const Cell = styled(TableCell) `
  padding-block: 8px;
`;
