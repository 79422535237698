import React from 'react';
import { createStartEndWeekFromDates } from 'src/utils/data-utils';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
const WeeklyDataPicker = (props) => {
    const handleWeekChange = (date) => {
        const week = createStartEndWeekFromDates(date);
        props.onChange(week.start, week.end);
    };
    return (<div>
      <SimpleDatePicker label={props.label} value={props.dateFrom} onChange={handleWeekChange}/>
    </div>);
};
export default WeeklyDataPicker;
