var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotAuthorized from 'src/components/layout/NotAuthorized';
import ProtectedRoute from 'src/containers/utils/ProtectedRoute';
import PageLayout from 'src/containers/layout/PageLayout';
import { NotFound } from 'src/pages';
import { selectAuthorizedTechDashboardViewer, selectAuthorizedTechnician, selectUserId, } from 'src/modules/auth/selectors';
import ErrorBoundary from 'src/components/layout/ErrorBoundary';
import pages from 'src/TechApp/pages';
const DefaultPage = (props) => {
    const isTechnician = useSelector(selectAuthorizedTechnician);
    return isTechnician ? (<Redirect to={{ pathname: '/tech/my-dashboard', state: { from: props.location } }}/>) : (<Redirect to={{ pathname: '/tech/technicians', state: { from: props.location } }}/>);
};
const MyDashboardPage = (props) => {
    const userId = useSelector(selectUserId);
    const isTechnician = useSelector(selectAuthorizedTechnician);
    return isTechnician ? (<Redirect to={{
            pathname: `/tech/technicians/${userId}/dashboard`,
            state: { from: props.location },
        }}/>) : ('Sorry, you are not a technician');
};
const TechNotAuthorized = () => {
    return <NotAuthorized targetRole="Tech_Dashboard_Viewer"/>;
};
const TechApp = (_a) => {
    var props = __rest(_a, []);
    const isTechnician = useSelector(selectAuthorizedTechnician);
    const canViewAdminTech = useSelector(selectAuthorizedTechDashboardViewer);
    const isTechUser = isTechnician || canViewAdminTech;
    return (<ErrorBoundary>
      <PageLayout showRefreshPageButton {...props}>
        {isTechUser ? (<Switch>
            <ProtectedRoute exact path="/tech/" component={DefaultPage}/>
            <ProtectedRoute exact path="/tech/my-dashboard" component={MyDashboardPage}/>
            <ProtectedRoute exact path="/tech/technicians" component={canViewAdminTech ? pages.Technicians : TechNotAuthorized}/>
            <ProtectedRoute exact path="/tech/technicians/:technicianId/dashboard" component={pages.TechDashboard}/>
            <ProtectedRoute exact path="/tech/lunch-breaks" component={pages.LunchBreaks}/>
            <ProtectedRoute exact path="/tech/lunch-breaks/:lunchBreakId" component={pages.LunchBreakDetails}/>
            <ProtectedRoute exact path="/tech/porters/:porterId" component={pages.PorterDashboard}/>
            <Route component={NotFound}/>
          </Switch>) : (<NotAuthorized targetRole="Tech_Dashboard_Viewer or Technician"/>)}
      </PageLayout>
    </ErrorBoundary>);
};
export default TechApp;
