import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPaymentDrawerAmount, selectPaymentDrawerInvoice, selectPaymentDrawerPaymentSubtype, selectPaymentDrawerPaymentType, selectPaymentDrawerScreen, selectPaymentDrawerWorkOrderId, } from 'src/AdminApp/modules/paymentDrawer/selectors';
import { SimplePageSlider, SlidesContainer, } from 'src/components/SimpleDrawerPaged';
import HomePage from '../HomePage';
import PaymentDrawerFooter from '../PaymentDrawerFooter';
import PaymentTypes from '../PaymentTypes';
import RealPaymentDetails from '../RealPaymentDetails';
import SelectPayer from '../SelectPayer';
import usePaymentDrawer from '../usePaymentDrawer';
const RefundFlow = ({ closeDrawer }) => {
    const screen = useSelector(selectPaymentDrawerScreen);
    const amount = useSelector(selectPaymentDrawerAmount);
    const invoice = useSelector(selectPaymentDrawerInvoice);
    const paymentType = useSelector(selectPaymentDrawerPaymentType);
    const paymentSubtype = useSelector(selectPaymentDrawerPaymentSubtype);
    const workOrderId = useSelector(selectPaymentDrawerWorkOrderId);
    const { onAddPayment, calculatePaymentDescription } = usePaymentDrawer({});
    if (!screen)
        return null;
    return (<Formik enableReinitialize initialValues={{
            amount: (invoice === null || invoice === void 0 ? void 0 : invoice.paymentsReceivedTotal)
                ? invoice === null || invoice === void 0 ? void 0 : invoice.paymentsReceivedTotal.toFixed(2)
                : 0,
            paymentSubtype: paymentSubtype !== null && paymentSubtype !== void 0 ? paymentSubtype : '',
        }} onSubmit={(values, { setSubmitting, resetForm }) => {
            var _a;
            const refundAmount = -Math.abs(amount);
            values.description = calculatePaymentDescription(refundAmount);
            onAddPayment({
                payment: Object.assign(Object.assign({}, values), { amount: refundAmount }),
                setSubmitting,
                resetForm,
                closeDrawer,
                paymentType,
                paymentSubtype: (_a = values === null || values === void 0 ? void 0 : values.paymentSubtype) !== null && _a !== void 0 ? _a : paymentSubtype,
                workOrderId,
            });
        }}>
      {({ values, handleSubmit }) => {
            useEffect(() => {
                values.paymentSubtype = '';
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [screen]);
            return (<Form style={{ width: '100%' }}>
            <SlidesContainer $hasTab={screen === 'home'}>
              <SimplePageSlider currentSlide={screen} slides={[
                    {
                        name: 'home',
                        render: () => <HomePage refund/>,
                    },
                    {
                        name: 'paymentType',
                        render: () => <PaymentTypes />,
                    },
                    {
                        name: 'paymentDetail',
                        render: () => <RealPaymentDetails />,
                    },
                    {
                        name: 'selectPayer',
                        render: () => <SelectPayer />,
                    },
                ]}/>
              <PaymentDrawerFooter values={values} handleSubmit={handleSubmit}/>
            </SlidesContainer>
          </Form>);
        }}
    </Formik>);
};
export default RefundFlow;
