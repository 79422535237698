import React from 'react';
import CarForm from 'src/AdminApp/containers/vehicle/CarModule/CarForm';
import { ConfirmationModal } from 'src/components/layout';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import useFleetCarDrawer from './useFleetCarDrawer';
const FleetCarDrawer = ({ open, onClose, onSubmit, vehicle, childrenFleetIds, }) => {
    const { confirmationModalOpen, confirmationModalText, selectedVehicle, setConfirmationModalOpen, handleVehicleUpdated, handleSubmit, isValid, } = useFleetCarDrawer({ onSubmit });
    return (<>
      <ConfirmationModal open={confirmationModalOpen} text={confirmationModalText} action={() => onSubmit(selectedVehicle)} onClose={() => setConfirmationModalOpen(false)}/>
      <SimpleDrawer open={open} onClose={onClose} title="Edit Car">
        <SimpleDrawerContent>
          <CarForm isFleet childrenFleetIds={childrenFleetIds} onChange={(v) => handleVehicleUpdated(v)} vehicle={vehicle} editMode/>
        </SimpleDrawerContent>
        <SimpleDrawerFooter>
          <FooterActionButton onClick={handleSubmit} disabled={!isValid}>
            Save
          </FooterActionButton>
        </SimpleDrawerFooter>
      </SimpleDrawer>
    </>);
};
export default FleetCarDrawer;
