import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPopsComponents, selectPopsMetros, selectPopsTerritories, } from 'src/PopsApp/modules/parts/selectors';
export const DEFAULT_FILTER_STATE = {
    size: 1000,
    regionName: '',
    sort: 'region,ASC',
};
export const vansIdSort = 'sort-popover-mobile';
export const useQuery = () => {
    const l = useLocation();
    let searchArr = l === null || l === void 0 ? void 0 : l.search;
    if (!searchArr.length)
        return {};
    searchArr = searchArr.replace('?', '');
    searchArr = searchArr.split('&');
    const searchObj = {};
    searchArr.forEach((p) => {
        const param = p.split('=');
        const [name, val] = param;
        let value = val;
        if (name === 'skeduloTags') {
            value = val.toUpperCase();
        }
        searchObj[name] = value;
    });
    return searchObj;
};
const useFilter = () => {
    var _a, _b, _c;
    const [filterData, setFilterData] = useState({});
    const [isFilterOpen, setFilterOpen] = useState(false);
    const [sortAnchorEl, setSortAnchorEl] = React.useState(null);
    const query = useQuery();
    const sortDirection = (query === null || query === void 0 ? void 0 : query.sort) ? query.sort.split(',')[1] : 'ASC';
    const metros = (_a = useSelector(selectPopsMetros)) !== null && _a !== void 0 ? _a : [];
    const territories = (_b = useSelector(selectPopsTerritories)) !== null && _b !== void 0 ? _b : [];
    const components = (_c = useSelector(selectPopsComponents)) !== null && _c !== void 0 ? _c : [];
    useEffect(() => {
        changeFilterData('sort', `region,${sortDirection}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortDirection]);
    const setNewFilterData = (filterState) => {
        var _a, _b, _c, _d;
        const newFilter = {};
        if (filterData.sort) {
            newFilter.sort = filterData.sort;
        }
        if ((_a = filterState.vanType) === null || _a === void 0 ? void 0 : _a.length) {
            newFilter.skeduloTags = filterState.vanType.join(',');
        }
        if (filterState.appointmentStart) {
            newFilter.appointmentStart = moment(filterState.appointmentStart)
                .startOf('day')
                .utc()
                .format();
        }
        if (filterState.appointmentEnd) {
            newFilter.appointmentEnd = moment(filterState.appointmentEnd)
                .endOf('day')
                .format();
        }
        if ((_b = filterState.metros) === null || _b === void 0 ? void 0 : _b.length) {
            newFilter.metros = filterState.metros.map((m) => m.id).join(',');
        }
        if ((_c = filterState.territories) === null || _c === void 0 ? void 0 : _c.length) {
            newFilter.territories = filterState.territories
                .map((t) => t.id)
                .join(',');
        }
        if ((_d = filterState.regionName) === null || _d === void 0 ? void 0 : _d.length) {
            newFilter.regionName = filterState.regionName;
        }
        setFilterData(newFilter);
        if (!filterState.appointmentStart) {
            newFilter.appointmentStart = moment().startOf('day').utc().format();
        }
        return newFilter;
    };
    const changeFilterData = (fieldName, value) => {
        const newFilter = Object.assign(Object.assign({}, filterData), { [fieldName]: value });
        setFilterData(newFilter);
        return newFilter;
    };
    const handleSortClose = () => {
        setSortAnchorEl(null);
    };
    const handleSortClick = (event) => {
        setSortAnchorEl(event.currentTarget);
    };
    const isSortOpen = Boolean(sortAnchorEl);
    return {
        changeFilterData,
        setNewFilterData,
        metros,
        territories,
        components,
        query,
        filterData,
        sortDirection,
        handleSortClose,
        sortAnchorEl,
        isSortOpen,
        handleSortClick,
        isFilterOpen,
        setFilterOpen,
    };
};
export default useFilter;
