var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Typography, Grid } from 'src/components/mui';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from 'src/AdminApp/components/form/TextField';
import LaborRates from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/LaborRates';
import { BtnText, LaborRatesContainer, SlidePageContent, SlidePageFieldsContainer, SlidePageFooter, SlidePageFormWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { ActionCreators } from 'src/AdminApp/modules/geoManagement/actions';
import { selectLaborRates, selectMetroHasArchivedLaborRates, selectMetroHasLaborRates, selectMetroLaborRates, } from 'src/AdminApp/modules/geoManagement/selectors';
import { getRegionsAutocomplete } from 'src/AdminApp/modules/regions/actions';
import { selectRegionsAutocomplete } from 'src/AdminApp/modules/regions/selectors';
import { getTerritoriesAutocomplete } from 'src/AdminApp/modules/territories/actions';
import { selectTerritoriesAutocomplete } from 'src/AdminApp/modules/territories/selectors';
import { getManagers } from 'src/AdminApp/modules/users/actions';
import { selectManagers } from 'src/AdminApp/modules/users/selectors';
import { PrimaryButton } from 'src/components/buttons';
import GroupUserSearch from 'src/components/form/GroupUserSearch';
import SearchMultiselect from 'src/components/form/SearchMultiselect';
import TerritorySearch from 'src/components/form/TerritorySearch';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import { toast } from 'src/components/SimpleToast';
import { selectAuthorizedGeoLaborRateEdit, selectAuthorizedGeoMgmtEdit, selectAuthorizedGeoMgmtView, selectAuthorizedHyperionEdit, } from 'src/modules/auth/selectors';
import { ErrorButton } from 'src/styles/styled';
import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    territory: Yup.object().required(),
    hyperionId: Yup.string()
        .matches(/^\d\d\d\d$/, 'Must be exactly 4 digits.')
        .nullable(),
});
const MetroPage = ({ disableSave, model, onClickArchivedLaborRatesList, onClickLaborRates, onRemove, onSubmit, }) => {
    const dispatch = useDispatch();
    const laborRates = useSelector(selectLaborRates);
    const hasMetroLaborRates = useSelector(selectMetroHasLaborRates);
    const metroLaborRates = useSelector(selectMetroLaborRates);
    const hasArchivedLaborRates = useSelector(selectMetroHasArchivedLaborRates);
    const laborRateEditor = useSelector(selectAuthorizedGeoLaborRateEdit);
    const hyperionEditor = useSelector(selectAuthorizedHyperionEdit);
    const geoEditor = useSelector(selectAuthorizedGeoMgmtEdit);
    const geoViewer = useSelector(selectAuthorizedGeoMgmtView);
    const managers = useSelector(selectManagers);
    useEffect(() => {
        if (!managers || managers.length === 0) {
            dispatch(getManagers());
        }
        return () => {
            dispatch(ActionCreators.LABOR_RATES.CLEAR());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onClickArchiveMetro = () => {
        if (onRemove && model) {
            onRemove(model);
        }
    };
    const isGeoViewer = geoEditor || geoViewer;
    const viewLaborRates = laborRateEditor || isGeoViewer;
    const showLaborRates = laborRates.length > 0 || hasMetroLaborRates;
    const needsLaborRates = !model && laborRates.length === 0;
    return (<Formik initialValues={{
            id: model === null || model === void 0 ? void 0 : model.id,
            name: (model === null || model === void 0 ? void 0 : model.name) || '',
            territory: (model === null || model === void 0 ? void 0 : model.territory) || null,
            hyperionId: (model === null || model === void 0 ? void 0 : model.hyperionId) || null,
            regions: (model === null || model === void 0 ? void 0 : model.regions) || [],
            laborRates: (model === null || model === void 0 ? void 0 : model.laborRates) || [],
            generalManagerId: (model === null || model === void 0 ? void 0 : model.generalManagerId) || null,
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            if (needsLaborRates) {
                toast.error('Validation Error: Please add Labor Rates.');
                setSubmitting(false);
                return;
            }
            const submitValue = Object.assign(Object.assign({}, values), { laborRates: laborRates.map((_a) => {
                    var { id } = _a, rest = __rest(_a, ["id"]);
                    return (Object.assign(Object.assign({}, rest), (!(id === null || id === void 0 ? void 0 : id.startsWith('tempLaborRate_')) && { id })));
                }) });
            submitValue.regionIds = (submitValue.addedRegions || []).map((x) => x.id);
            delete submitValue.addedRegions;
            if (!submitValue.hyperionId)
                delete submitValue.hyperionId;
            yield onSubmit(submitValue);
            setSubmitting(false);
        })}>
      {({ values, isSubmitting, isValid, errors, touched, setFieldValue }) => {
            return (<SlidePageFormWrapper>
            <SlidePageContent>
              <SlidePageFieldsContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField fullWidth name="name" label="Metro Name" type="text" variant="outlined"/>
                  </Grid>
                  <Grid item xs={12}>
                    <TerritorySearch value={values.territory} name="territory" label="Territory" onUpdate={(x) => setFieldValue('territory', x)} getTerritories={getTerritoriesAutocomplete} territoriesSelector={selectTerritoriesAutocomplete}/>
                  </Grid>
                  <Grid item xs={12}>
                    <GroupUserSearch value={managers === null || managers === void 0 ? void 0 : managers.filter((m) => m.uid === values.generalManagerId)[0]} selector={selectManagers} onUpdate={(value) => {
                    setFieldValue('generalManagerId', value.uid);
                }} name="generalManager" label="General Manager"/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth name="hyperionId" label={`Hyperion ID${touched.hyperionId && (errors === null || errors === void 0 ? void 0 : errors.hyperionId)
                    ? ` - ${errors.hyperionId}`
                    : ''}`} type="text" variant="outlined" disabled={!hyperionEditor}/>
                  </Grid>
                </Grid>
              </SlidePageFieldsContainer>
              <SlidePageFieldsContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SearchMultiselect fixedValues={values.regions || []} value={values.addedRegions || []} name="regions" label="Regions (optional)" onUpdate={(data) => {
                    setFieldValue('addedRegions', data);
                }} getEntities={getRegionsAutocomplete} selectEntities={selectRegionsAutocomplete}/>
                  </Grid>
                </Grid>
              </SlidePageFieldsContainer>
              {(showLaborRates || viewLaborRates) && (<LaborRatesContainer>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Labor Rates</Typography>
                    </Grid>
                    {showLaborRates ? (<LaborRates metro hasModel={!!model} modelLaborRates={metroLaborRates}/>) : (<Grid item xs={12}>
                        <PrimaryButton disabled={!laborRateEditor} onClick={!laborRateEditor ? () => { } : onClickLaborRates}>
                          Add Labor Rates
                        </PrimaryButton>
                      </Grid>)}
                  </Grid>
                </LaborRatesContainer>)}
              {viewLaborRates && hasArchivedLaborRates && (<Grid item xs={12}>
                  <Button color="primary" onClick={onClickArchivedLaborRatesList}>
                    <BtnText>View Archived Labor Rates</BtnText>
                  </Button>
                </Grid>)}
              {model && !model.archived && (<Grid item xs={12}>
                  <ErrorButton onClick={onClickArchiveMetro}>
                    <BtnText>Archive Metro</BtnText>
                  </ErrorButton>
                </Grid>)}
            </SlidePageContent>
            <SlidePageFooter>
              <FooterActionButton type="submit" disabled={isSubmitting || !isValid || disableSave || needsLaborRates}>
                Save Metro
              </FooterActionButton>
            </SlidePageFooter>
          </SlidePageFormWrapper>);
        }}
    </Formik>);
};
export default MetroPage;
