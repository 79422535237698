import React from 'react';
import pluralize from 'pluralize';
import { getSkeduloUrl } from 'src/AdminApp/utils/url-utils';
import CheckboxOrLabel from 'src/PopsApp/pages/Job/CheckboxOrLabel';
import { Tooltip } from 'src/components/tooltips';
import { SPECIAL_ORDER_TOOLTIP } from 'src/PopsApp/modules/api/constants';
import SpecialOrderIndicator from 'src/PopsApp/components/SpecialOrderIndicator';
import { LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
import moment from 'moment';
import { CarInfo } from '../JobItem/JobItem.styles';
import { SpecialOrderWrapper } from './JobTableRow.styles';
const JobsTableRow = ({ job, selectedJobs, onItemSelected, }) => {
    var _a, _b, _c;
    const { car, skeduloJobId, referenceNum } = job;
    return (<tr>
      <td>
        <CheckboxOrLabel job={job} selectedJobs={selectedJobs} onItemSelected={onItemSelected}/>
      </td>
      <td>
        {referenceNum ? (<a className="link" href={`/admin/repairs/${referenceNum}`} rel="noopener noreferrer" target="_blank">
            {referenceNum}
          </a>) : null}
      </td>
      <td>
        {skeduloJobId ? (<a className="link" href={getSkeduloUrl(`/job/${skeduloJobId}/details`)} rel="noopener noreferrer" target="_blank">
            Skedulo Job
          </a>) : null}
      </td>
      <td>
        {moment(job.scheduleStartLocal).format(LOCALIZED_DATE_FORMAT)}
        {' • '}
        {moment(job.scheduleStartLocal).format(TIME_12H_FORMAT)}
      </td>
      <td>
        {job.repairs && (<ul className="repairsList">
            {job.repairs.map((repair) => (<li key={repair}>{repair}</li>))}
          </ul>)}
      </td>
      <td>
        <CarInfo htmlFor={`job-${job.referenceNum}`} title={((_a = car.engineDescription) === null || _a === void 0 ? void 0 : _a.length) > 30 ? car.engineDescription : ''}>{`${car.carYear} ${car.make} ${car.model} ${car.engineDescription ? '•' : ''} ${car.engineDescription ? car.engineDescription.substring(0, 30) : ''}${((_c = (_b = car === null || car === void 0 ? void 0 : car.engineDescription) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) > 30 ? '...' : ''}`}</CarInfo>
      </td>
      <td>
        {!job.specialOrder && (<>
            {job.componentsNeeded} {pluralize('Part', job.componentsNeeded)}
          </>)}
        {job.specialOrder && (<Tooltip title={SPECIAL_ORDER_TOOLTIP}>
            <SpecialOrderWrapper>
              <span className="neededText">
                {job.componentsNeeded} {pluralize('Part', job.componentsNeeded)}
              </span>
              <SpecialOrderIndicator />
            </SpecialOrderWrapper>
          </Tooltip>)}
      </td>
    </tr>);
};
export default JobsTableRow;
