import React from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import { getNavContext, getDisplayText } from 'src/utils/nav-utils';
const PageTitle = ({ text, slug }) => {
    const location = useLocation();
    const { area, section, tokens } = getNavContext(location);
    let newTitle = text ||
        `AMST | ${getDisplayText(section) || getDisplayText(area) || 'Tools'}`;
    const id = tokens.length > 0 ? tokens.shift() : undefined;
    if (id)
        newTitle = `${newTitle} - ${id}`;
    if (slug)
        newTitle = `${newTitle} - ${slug}`;
    return (<Helmet>
      <title>{newTitle}</title>
    </Helmet>);
};
export default PageTitle;
