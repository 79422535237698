import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { createSimpleDeepHandler } from 'src/modules/api/utils/createSimpleDeepHandler';
import moment from 'moment';
import { ActionCreators, setShift, setEditedShiftWindow, clearShiftWindow, } from './actions';
const DEFAULT_STATE = {
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['status,asc', 'name,asc', 'modified,desc'],
        },
    },
    shift: {
        shiftWindowSet: [],
        editedShiftWindow: {
            windowStart: moment().hour(8).minute(0).second(0),
            windowEnd: moment().hour(9).minute(0).second(0),
        },
    },
};
const searchShiftDashboardSuccess = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize, query } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
                query,
            } }) });
};
const fetchShiftDashboard = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const clearShiftDashboardSuccess = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const getShiftSuccess = (state, action) => {
    const shift = fetched(action.payload);
    return Object.assign(Object.assign({}, state), { shift: Object.assign(Object.assign({}, shift), { editedShiftWindow: DEFAULT_STATE.shift.editedShiftWindow }) });
};
const getShift = (state) => {
    return Object.assign(Object.assign({}, state), { shift: Object.assign(Object.assign({}, state.shift), { _isFetching: true }) });
};
const clearShiftSuccess = (state) => {
    return Object.assign(Object.assign({}, state), { shift: {
            shiftWindowSet: [],
            editedShiftWindow: DEFAULT_STATE.shift.editedShiftWindow,
        } });
};
const clearShiftWindowSuccess = (state) => {
    return Object.assign(Object.assign({}, state), { shift: Object.assign(Object.assign({}, state.shift), { editedShiftWindow: DEFAULT_STATE.shift.editedShiftWindow }) });
};
const reducers = handleActions({
    [ActionCreators.SEARCH_SHIFTS.SUCCESS.type]: searchShiftDashboardSuccess,
    [ActionCreators.SEARCH_SHIFTS.FETCH.type]: fetchShiftDashboard,
    [ActionCreators.SEARCH_SHIFTS.CLEAR.type]: clearShiftDashboardSuccess,
    [ActionCreators.GET_SHIFT.SUCCESS.type]: getShiftSuccess,
    [ActionCreators.GET_SHIFT.FETCH.type]: getShift,
    [ActionCreators.GET_SHIFT.CLEAR.type]: clearShiftSuccess,
    [clearShiftWindow.type]: clearShiftWindowSuccess,
    [setShift.type]: createSimpleDeepHandler('shift'),
    [setEditedShiftWindow.type]: createSimpleDeepHandler('shift.editedShiftWindow'),
}, DEFAULT_STATE);
export default reducers;
