import { createSelector } from 'reselect';
export const selectServiceAreas = (state) => state.admin.serviceAreas;
export const selectDashboard = createSelector(selectServiceAreas, (consumers) => { var _a; return (_a = consumers.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardPagination = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardParams = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectServiceAreasAutocomplete = createSelector(selectServiceAreas, (serviceAreas) => { var _a; return (_a = serviceAreas.autocomplete) !== null && _a !== void 0 ? _a : []; });
export const selectValidateZipCodes = createSelector(selectServiceAreas, (zipCodes) => { var _a; return (_a = zipCodes.zipCodes) !== null && _a !== void 0 ? _a : []; });
export const selectDashboardFetching = createSelector(selectDashboard, (dashboard) => {
    var _a;
    return (_a = dashboard._isFetching) !== null && _a !== void 0 ? _a : false;
});
