import React, { useState } from 'react';
import { Container } from 'src/components/mui';
import AddPartnerAPIDrawer from 'src/PartnersAPIApp/pages/AddPartnersAPIDrawer/AddPartnerAPIDrawer';
import { ActionsBlock } from 'src/containers/layout/ActionsBlock';
import { useDispatch } from 'react-redux';
import { resetAddAPIKey } from 'src/PartnersAPIApp/modules/partnersApi/actions';
import Content from './components/Content';
const PartnersAPI = () => {
    const dispatch = useDispatch();
    const [addPartnerAPIDrawerOpen, setAddPartnerAPIDrawerOpen] = useState(false);
    const handleAddPartnerAPIDrawerClose = () => {
        setAddPartnerAPIDrawerOpen(false);
        dispatch(resetAddAPIKey());
    };
    const addAPIAction = [
        {
            function: () => {
                setAddPartnerAPIDrawerOpen(true);
            },
            disabled: false,
            tooltip: undefined,
            copy: 'Create Partner API Key',
            isCancelAction: false,
            hasBorderTop: false,
        },
    ];
    return (<Container maxWidth="xl">
      <Content />
      <ActionsBlock actions={addAPIAction}/>
      <AddPartnerAPIDrawer open={addPartnerAPIDrawerOpen} onClose={handleAddPartnerAPIDrawerClose}/>
    </Container>);
};
export default PartnersAPI;
