import { handleActions } from 'redux-actions';
import { ActionCreators } from 'src/AdminApp/modules/autoselection/actions';
const DEFAULT_STATE = {
    years: [],
    makes: [],
    models: [],
    selectedYear: undefined,
    selectedModel: undefined,
    selectedTrim: undefined,
    byVin: {},
    byPlate: {},
};
const successYearsReducer = (state, action) => (Object.assign(Object.assign({}, state), { years: action.payload }));
const fetchYearsReducer = (state) => (Object.assign(Object.assign({}, state), { years: [], makes: [], models: [] }));
const successMakesReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { makes: action.payload });
};
const fetchMakesReducer = (state) => (Object.assign(Object.assign({}, state), { makes: [], models: [] }));
const successModelReducer = (state, action) => (Object.assign(Object.assign({}, state), { models: action.payload }));
const fetchModelsReducer = (state) => (Object.assign(Object.assign({}, state), { models: [] }));
const successDescriptionReducer = (state, action) => {
    const { make, model } = action.meta;
    return Object.assign(Object.assign({}, state), { [make]: {
            [model]: action.payload,
        } });
};
const successDescriptionByVinReducer = (state, action) => {
    const { vin } = action.meta;
    return Object.assign(Object.assign({}, state), { byVin: Object.assign(Object.assign({}, state.byVin), { [vin]: action.payload }) });
};
const successDescriptionByPlateReducer = (state, action) => {
    const { plate } = action.meta;
    return Object.assign(Object.assign({}, state), { byPlate: Object.assign(Object.assign({}, state.byPlate), { [plate]: action.payload }) });
};
const reducers = handleActions({
    [ActionCreators.GET_YEARS.FETCH.type]: fetchYearsReducer,
    [ActionCreators.GET_YEARS.SUCCESS.type]: successYearsReducer,
    [ActionCreators.GET_MAKE.FETCH.type]: fetchMakesReducer,
    [ActionCreators.GET_MAKE.SUCCESS.type]: successMakesReducer,
    [ActionCreators.GET_MODEL.FETCH.type]: fetchModelsReducer,
    [ActionCreators.GET_MODEL.SUCCESS.type]: successModelReducer,
    [ActionCreators.GET_DESCRIPTION.SUCCESS.type]: successDescriptionReducer,
    [ActionCreators.GET_DESCRIPTION_BY_VIN.SUCCESS.type]: successDescriptionByVinReducer,
    [ActionCreators.GET_DESCRIPTION_BY_PLATE.SUCCESS.type]: successDescriptionByPlateReducer,
}, DEFAULT_STATE);
export default reducers;
