var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectRepairRequestWithReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { toast } from 'src/components/SimpleToast';
import { getRequestCar } from 'src/AdminApp/modules/requests/actions';
import pluralize from 'pluralize';
import { CircularProgress } from 'src/components/mui';
import { isProduction } from 'src/utils/envUtils';
const SYNC_STATUS = {
    OFFLINE: colors.error,
    ONLINE: colors.success,
    SYNC: colors.blue,
};
const AlertBar = styled.section `
  color: ${colors.white};
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  background: ${({ status }) => SYNC_STATUS[status]};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  z-index: 10000;
  cursor: pointer;
`;
const CircularProgressSyncing = styled(CircularProgress) `
  color: ${colors.white};
  margin-right: 5px;
`;
const OnlineIndicator = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [db, setDb] = useState();
    const [uploads, setUploads] = useState([]);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [syncing, setSyncing] = useState(false);
    const DB_VERSION = 3;
    const DB_NAME = 'workbox-background-sync';
    const REQUEST_OBJECT_STORE_NAME = 'requests';
    const QUEUE_NAME_INDEX = 'queueName';
    const refNums = location.pathname.match(/(\w{2}\d{7})|(?!\/).{2}-.{4}-.{4}/gi);
    const referenceNum = refNums ? refNums[0] : null;
    const repairRequest = useSelector((state) => selectRepairRequestWithReferenceNum(state, referenceNum));
    const getOfflineSyncQueue = () => {
        return new Promise((myResolve, myReject) => {
            try {
                const transaction = db.transaction(['requests']);
                const objectStore = transaction.objectStore('requests');
                const allRequests = objectStore.getAll();
                allRequests.onerror = (event) => {
                    // console.log('Unable to retrieve data from database!', event);
                    myReject(event);
                };
                allRequests.onsuccess = () => {
                    var _a;
                    setUploads(allRequests.result);
                    myResolve(allRequests.result);
                    if (((_a = allRequests.result) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                        setSyncing(false);
                    }
                };
            }
            catch (e) {
                // console.log('Cannot retrieve all requests', e);
                myReject(e);
            }
        });
    };
    const updateOnlineStatus = (e) => {
        setIsOnline(e.type === 'online');
        if (e.type === 'online') {
            toast.success('Tools is back online');
            const request = window.indexedDB.open(DB_NAME, DB_VERSION);
            request.onupgradeneeded = () => {
                const dbHere = request.result;
                const objStore = dbHere.createObjectStore(REQUEST_OBJECT_STORE_NAME, {
                    autoIncrement: true,
                    keyPath: 'id',
                });
                objStore.createIndex(QUEUE_NAME_INDEX, QUEUE_NAME_INDEX, {
                    unique: false,
                });
            };
            request.onerror = () => {
                // console.log('request error: ', e2);
            };
            request.onsuccess = () => {
                setDb(request.result);
            };
        }
    };
    useEffect(() => {
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
        return function cleanup() {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);
    useEffect(() => {
        if (db && isOnline) {
            getOfflineSyncQueue();
        }
    }, [db, isOnline]); // eslint-disable-line react-hooks/exhaustive-deps
    const deleteUpload = (id) => {
        return new Promise((myResolve, myReject) => {
            try {
                const transaction = db.transaction(['requests'], 'readwrite');
                const objectStore = transaction.objectStore('requests');
                const deletedItem = objectStore.delete(id);
                deletedItem.onerror = (e) => {
                    // console.log('Unable to delete data from database!');
                    myReject(e);
                };
                deletedItem.onsuccess = () => {
                    myResolve(true);
                };
            }
            catch (e) {
                // NOTHING TO SEE HERE
                // console.log('Unable to retrieve data from database!', e);
                myReject(e);
            }
        });
    };
    const syncPhotos = () => __awaiter(void 0, void 0, void 0, function* () {
        setSyncing(true);
        const uploadThese = (yield getOfflineSyncQueue());
        try {
            /* eslint-disable no-await-in-loop, no-restricted-syntax */
            for (const upload of uploadThese) {
                yield fetch(new Request(upload.requestData.url, upload.requestData));
                yield deleteUpload(upload.id);
                getOfflineSyncQueue();
            }
            /* eslint-enable */
            if (repairRequest) {
                dispatch(getRequestCar(repairRequest.referenceNum, repairRequest.id));
            }
            yield getOfflineSyncQueue();
            setSyncing(false);
        }
        catch (e) {
            setSyncing(false);
            // console.log('cannot upload', e);
        }
    });
    let status = 'ONLINE';
    if (isOnline && (uploads === null || uploads === void 0 ? void 0 : uploads.length) > 0 && syncing) {
        status = 'SYNC';
    }
    else if (!isOnline) {
        status = 'OFFLINE';
    }
    return (<>
      {!isOnline && <AlertBar status="OFFLINE">Tools is Offline</AlertBar>}

      {isOnline && (uploads === null || uploads === void 0 ? void 0 : uploads.length) > 0 && !syncing && (<AlertBar status={status} onClick={syncPhotos}>
          Tap to Sync offline data{' '}
          {!isProduction() && (<span>{`${uploads.length} ${pluralize('upload', uploads.length)}`}</span>)}
        </AlertBar>)}

      {isOnline && (uploads === null || uploads === void 0 ? void 0 : uploads.length) > 0 && syncing && (<AlertBar status={status} onClick={getOfflineSyncQueue}>
          <CircularProgressSyncing variant="indeterminate" size={10}/> Syncing{' '}
          {!isProduction() && (<span>{`${uploads.length} ${pluralize('upload', uploads.length)}`}</span>)}
        </AlertBar>)}
    </>);
};
export default OnlineIndicator;
