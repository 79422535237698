import React from 'react';
import { Schedule } from 'src/components/mui/icons';
import styled from 'styled-components';
import { Icon, TableCell, TableRow, Checkbox } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import CONFIG from 'src/config';
import { addressObjectToString } from 'src/clients/mapboxClient';
import { colors } from 'src/styles/theme';
import DeliveryTypesDrawer from 'src/PopsApp/pages/Distributors/DeliveryTypes/DeliveryTypesDrawer';
const StyledTableRow = styled(TableRow) `
  cursor: pointer;
  margin-top: 5px;

  td:first-child {
    width: 35%;
  }

  &:hover {
    outline: solid 2px ${colors.BLUE};
  }
`;
const CELL_STYLES = {
    address: { width: '30%', maxWidth: '50%' },
    dealershipName: { width: '15%', maxWidth: '15%' },
    email: { 'width': '20%', 'maxWidth': '20%', 'word-break': 'break-all' },
    status: { width: '10%', maxWidth: '10%' },
    key: { width: '15%', maxWidth: '15%' },
    edit: { width: '5%', maxWidth: '5%' },
};
const LocationDashboardItem = ({ location, onEdit, onSave, onHoursEdit, }) => {
    var _a;
    return (<StyledTableRow>
      <TableCell style={CELL_STYLES.address}>
        {addressObjectToString(Object.assign(Object.assign({}, location), { street: location.street1 }))}
      </TableCell>
      <TableCell style={CELL_STYLES.dealershipName}>
        {location.dealershipName}
      </TableCell>
      <TableCell style={CELL_STYLES.email}>
        {(_a = location.emailAddresses) === null || _a === void 0 ? void 0 : _a.join(', ')}
      </TableCell>
      <TableCell style={CELL_STYLES.status}>{location.status}</TableCell>
      <TableCell style={CELL_STYLES.key}>
        {location.epicorCoverageKey}
      </TableCell>
      <TableCell style={CELL_STYLES.edit} onClick={() => {
            location.notEditable ? null : onEdit();
        }}>
        <Icon color={location.notEditable ? 'disabled' : 'inherit'}>edit</Icon>
      </TableCell>
      <TableCell align="center" style={CELL_STYLES.edit}>
        <Schedule onClick={() => {
            onHoursEdit(location);
        }}/>
      </TableCell>
      <TableCell align="center" style={CELL_STYLES.edit}>
        <DeliveryTypesDrawer model={location} onSave={onSave}/>
      </TableCell>
      {CONFIG.POPS_COURIER_DELIVERY_DISTRIBUTORS.split(',').includes(location === null || location === void 0 ? void 0 : location.partDistributorName) && (<Tooltip title="Can this location receive deliveries from courier services such as Fedex? Storage containers typically cannot.">
          <TableCell align="center" style={CELL_STYLES.edit}>
            <Checkbox checked={location.canReceiveCourierDeliveries} disabled/>
          </TableCell>
        </Tooltip>)}
    </StyledTableRow>);
};
export default LocationDashboardItem;
