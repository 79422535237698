import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectDashboardPagination, selectDashboardParams, } from 'src/AdminApp/modules/consumers/selectors';
import { ActionCreators, getConsumers, } from 'src/AdminApp/modules/consumers/actions';
import { setTab } from 'src/AdminApp/modules/quickRo/actions';
import { CONSUMER_TABS as TABS } from 'src/AdminApp/modules/quickRo/enums';
const useConsumersDashboard = () => {
    const dispatch = useDispatch();
    const [addConsumerModalOpen, setAddConsumerModalOpen] = useState(false);
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    useEffect(() => {
        var _a, _b, _c;
        const props = Object.assign(Object.assign({}, params), { pageSize: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20, pageIndex: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _b !== void 0 ? _b : 0, sort: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _c !== void 0 ? _c : ['id', 'desc'] });
        dispatch(getConsumers(props));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onAddConsumerModalOpen = () => {
        setAddConsumerModalOpen(true);
        dispatch(setTab(TABS.ADD_NEW_CUSTOMER));
    };
    const onAddConsumerModalClose = () => {
        setAddConsumerModalOpen(false);
    };
    const onLoadData = ({ page, size, sort }) => {
        dispatch(getConsumers(Object.assign(Object.assign({}, params), { pageSize: size, pageIndex: page, sort })));
    };
    const onSearch = (filters) => {
        var _a, _b;
        dispatch(getConsumers(Object.assign({ pageIndex: 0, pageSize: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20, sort: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _b !== void 0 ? _b : ['id', 'desc'] }, filters)));
    };
    const onClear = () => {
        dispatch(ActionCreators.CONSUMERS.CLEAR());
    };
    return {
        onAddConsumerModalOpen,
        addConsumerModalOpen,
        onAddConsumerModalClose,
        onSearch,
        onClear,
        pagination,
        onLoadData,
    };
};
export default useConsumersDashboard;
