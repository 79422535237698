var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BarcodeReader } from 'dynamsoft-javascript-barcode';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Grid, Button } from 'src/components/mui';
import styled from 'styled-components';
import VideoDecode from '../components/VideoDecode';
import './VideoProcessor.css';
const s = styled;
const VideoProcessor = () => {
    const [active, setActive] = useState(false);
    const [codes, setCodes] = useState(new Map());
    const results = useMemo(() => [], []);
    const initializeScanner = () => __awaiter(void 0, void 0, void 0, function* () {
        console.info(BarcodeReader.license);
        if (!BarcodeReader.license) {
            BarcodeReader.license =
                'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyMjM2NTI0LVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAyMjM2NTI0Iiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2Rscy5keW5hbXNvZnRvbmxpbmUuY29tIiwiY2hlY2tDb2RlIjotMTU3ODEyNjA3fQ==';
            BarcodeReader.engineResourcePath =
                'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.21/dist/';
        }
        try {
            yield BarcodeReader.loadWasm();
        }
        catch (ex) {
            if (ex.message.indexOf('network connection error')) {
                const customMsg = 'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
                console.error(customMsg);
            }
            throw ex;
        }
    });
    const open = () => {
        setActive(true);
    };
    const stop = () => {
        setActive(false);
    };
    const clear = useCallback(() => {
        results.length = 0;
        codes.clear();
        setCodes(new Map(codes));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCodes, results]);
    const processResult = useCallback((result) => {
        const key = `${result.barcodeFormatString}:${result.barcodeText}`;
        if (codes.has(key)) {
            const scanResult = codes.get(key);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            scanResult.count += 1;
        }
        else {
            codes.set(key, {
                format: result.barcodeFormatString,
                code: result.barcodeText,
                count: 1,
            });
        }
        results.push(result);
        setCodes(new Map(codes));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setCodes]);
    useEffect(() => {
        initializeScanner();
    }, []);
    return (<div className="container">
      <ScanContainer>
        {!active && (<img alt="special" src="img/png/camera.png" style={{ width: '350px', height: '350px' }}/>)}
        {active && <VideoDecode processResultCb={processResult}/>}
      </ScanContainer>
      <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <GridItem item xs={2}>
          <Button type="button" color="primary" onClick={open}>
            Scan
          </Button>
        </GridItem>
        <GridItem item xs={2}>
          <Button type="button" color="primary" onClick={stop}>
            Stop
          </Button>
        </GridItem>
        <GridItem item xs={2}>
          <Button type="button" color="primary" onClick={clear}>
            Clear
          </Button>
        </GridItem>
      </Grid>
      <div>Scanned Items: {results.length}</div>
      <ol>
        {[...codes].map(([key, data]) => (<li key={key}>
            <ScannedItem>
              <Barcode>{data.code}</Barcode>
              <Metadata>
                Format: {data.format}, Qty: {data.count}
              </Metadata>
            </ScannedItem>
          </li>))}
      </ol>
    </div>);
};
export default VideoProcessor;
const ScanContainer = styled.div `
  width: 100%;
  height: 350px;
  text-align: center;
`;
const GridItem = s(Grid).attrs({ item: true }) `
  padding: 5px;
`;
const ScannedItem = s.div `
  margin: 5px 5px 10px;
`;
const Barcode = s.div ``;
const Metadata = s.div `
  font-size: 0.9em;
  color: #666;
  padding-left: 10px;
`;
