import createAction from 'src/modules/api/utils/createAction';
import createDefaultActionCreators, { createActionCreators, } from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/AdminApp/modules/api/urls';
// FSA ACTIONS
export const ActionCreators = {
    DELETE_LABOR_RATE: createDefaultActionCreators('DELETE_LABOR_RATE'),
    LABOR_RATES: createActionCreators(['ADD', 'CLEAR_ARCHIVED', 'CLEAR_EDIT', 'CLEAR', 'UPDATE'], 'LABOR_RATES'),
    SLIDE_PANEL: createActionCreators(['CLOSE', 'OPEN', 'SET_PAGE', 'UPDATE_METRO', 'UPDATE_REGION'], 'SLIDE_PANEL'),
    UPDATE_LABOR_RATE: createDefaultActionCreators('UPDATE_LABOR_RATE'),
    UPDATE_REGION_DEFAULT_HOME_STORES: createDefaultActionCreators('UPDATE_REGION_DEFAULT_HOME_STORES'),
};
export const openSlidePanel = (params) => ({
    type: ActionCreators.SLIDE_PANEL.OPEN.type,
    meta: { params },
});
export const closeSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL.CLOSE.type,
});
export const setSlidePanelPage = (page) => ({
    type: ActionCreators.SLIDE_PANEL.SET_PAGE.type,
    meta: { page },
});
export const updateLaborRate = (id, body) => createAPIAction({
    types: [
        ActionCreators.UPDATE_LABOR_RATE.FETCH(),
        ActionCreators.UPDATE_LABOR_RATE.SUCCESS({ meta: () => ({ id, body }) }),
        ActionCreators.UPDATE_LABOR_RATE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.LABOR_RATE_BY_ID(id),
    method: 'PUT',
    body,
});
export const deleteLaborRate = (id) => createAPIAction({
    types: [
        ActionCreators.DELETE_LABOR_RATE.FETCH(),
        ActionCreators.DELETE_LABOR_RATE.SUCCESS({ meta: () => ({ id }) }),
        ActionCreators.DELETE_LABOR_RATE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.LABOR_RATE_BY_ID(id),
    method: 'DELETE',
});
export const clearLaborRates = () => ActionCreators.LABOR_RATES.CLEAR();
export const updateRegionHomeStores = (id, body) => createAPIAction({
    types: [
        ActionCreators.DELETE_LABOR_RATE.FETCH(),
        ActionCreators.DELETE_LABOR_RATE.SUCCESS({ meta: () => ({ id }) }),
        ActionCreators.DELETE_LABOR_RATE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.UPDATE_REGION_DEFAULT_HOME_STORES(id),
    method: 'PUT',
    body,
});
export const setLaborRatesToEdit = createAction('SET_LABOR_RATES_TO_EDIT');
export const deleteLaborRates = createAction('DELETE_LABOR_RATES');
export const archiveLaborRates = createAction('ARCHIVE_LABOR_RATES');
