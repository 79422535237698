export function createActionCreators(actionMap, namespace) {
    const actionCreators = {};
    actionMap.forEach((_type) => {
        const type = `${namespace}/${_type}`;
        const actionCreator = (action) => (Object.assign(Object.assign({}, action), { type: (action === null || action === void 0 ? void 0 : action.type) ? action.type : type }));
        actionCreator.type = type;
        actionCreators[_type] = actionCreator;
    });
    return actionCreators;
}
const createDefaultActionCreators = (namespace, actionMap = []) => createActionCreators(['FETCH', 'SUCCESS', 'FAILURE', ...actionMap], namespace);
export const createDefaultApiActionCreators = (namespace) => createActionCreators(['FETCH', 'SUCCESS', 'FAILURE'], namespace);
export default createDefaultActionCreators;
