import React from 'react';
import { Grid } from 'src/components/mui';
import DateSelector from './components/DateSelector';
import Section from '../Section/Section';
const MobileDatePicker = () => {
    return (<Section container style={{ alignItems: 'center' }}>
      <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item xs={12}>
          <DateSelector isMobile/>
        </Grid>
      </Grid>
    </Section>);
};
export default MobileDatePicker;
