import React from 'react';
import { Subtext } from 'src/components/layout';
import useAdminUser from 'src/hooks/useAdminUser';
const AdminUser = ({ ispOwnerId, justName = false, subText = true, updatedUser, maxChars, }) => {
    var _a, _b;
    const name = useAdminUser({
        user: updatedUser,
        ispOwnerId,
    });
    if (!updatedUser)
        return null;
    if (justName || !subText)
        return (<span>
        {(_a = name === null || name === void 0 ? void 0 : name.substring(0, maxChars !== null && maxChars !== void 0 ? maxChars : name.length)) !== null && _a !== void 0 ? _a : ''}
        {maxChars ? '...' : ''}
      </span>);
    return (<Subtext>
      {(_b = name === null || name === void 0 ? void 0 : name.substring(0, maxChars !== null && maxChars !== void 0 ? maxChars : name.length)) !== null && _b !== void 0 ? _b : ''} {maxChars ? '...' : ''}
    </Subtext>);
};
export default AdminUser;
