import React from 'react';
import { CheckCircleRounded as CheckCircleRoundedIcon, Directions as DirectionsIcon, Error as ErrorIcon, } from 'src/components/mui/icons';
import PartPickupItemStatus from 'src/AdminApp/models/enums/PartPickupItemStatus';
import { Tooltip } from 'src/components/tooltips';
import { ChecklistAccordionItem, ChecklistAccordionSubItem, IconWrapper, NewIcon, PartPickupOrderItemWrapper, RepairRequestAccordionDetails, RepairRequestLink, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
const PartPickupOrderItem = ({ repairRequestReferenceNum, items, }) => {
    const isAllPending = (states) => states.every((state) => state.status === PartPickupItemStatus.PENDING);
    const getItemClassName = (status) => {
        switch (status) {
            case PartPickupItemStatus.NEW_LOCATION:
                return 'itemStateNewLocation';
            case PartPickupItemStatus.NEW_PART:
                return 'itemStateNewPart';
            default:
                return '';
        }
    };
    const renderIcon = (status) => (<>
      {status === PartPickupItemStatus.PICKED_UP && (<CheckCircleRoundedIcon className="success"/>)}
      {status === PartPickupItemStatus.STANDBY && (<Tooltip placement="left" title={<div style={{
                    padding: '15px 10px',
                    fontSize: '13px',
                    lineHeight: '1.46',
                }}>
              <div style={{
                    marginBottom: '8px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}>
                Standby
              </div>
              The Parts Team is working
              <br />
              on where to get your missing parts.
            </div>}>
          <ErrorIcon className="warning"/>
        </Tooltip>)}
      {status === PartPickupItemStatus.NEW_PART && <NewIcon>NEW</NewIcon>}
      {status === PartPickupItemStatus.NEW_LOCATION && (<DirectionsIcon className="primary"/>)}
    </>);
    const renderItem = (item) => {
        var _a;
        return (<div key={item.id}>
      <ChecklistAccordionItem className={getItemClassName(item.quantity === 1 ? item.states[0].status : '')}>
        <div>
          {(_a = item.name) !== null && _a !== void 0 ? _a : 'Item name'} &middot; Part #: {item.partNumber}
          {item.quantity > 1 ? ` · Qty: ${item.quantity}` : ''}
        </div>
        {item.states.length === 1 &&
                item.states[0].status !== PartPickupItemStatus.PENDING && (<IconWrapper>{renderIcon(item.states[0].status)}</IconWrapper>)}
      </ChecklistAccordionItem>
      {item.quantity > 1 &&
                !isAllPending(item.states) &&
                item.states.map((state, index) => {
                    var _a;
                    return (<ChecklistAccordionSubItem key={index} className={getItemClassName(state.status)}>
            <div>
              {(_a = item.name) !== null && _a !== void 0 ? _a : 'Item name'} &middot; Part #: {item.partNumber}
            </div>
            <IconWrapper>{renderIcon(state.status)}</IconWrapper>
          </ChecklistAccordionSubItem>);
                })}
    </div>);
    };
    return (<PartPickupOrderItemWrapper>
      <RepairRequestLink rel="noreferrer" href={`/admin/repairs/${repairRequestReferenceNum}`} target="_blank">
        {repairRequestReferenceNum}
      </RepairRequestLink>
      <RepairRequestAccordionDetails>
        {items.map((item) => renderItem(item))}
      </RepairRequestAccordionDetails>
    </PartPickupOrderItemWrapper>);
};
export default PartPickupOrderItem;
