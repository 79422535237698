import Slider from 'react-slick';
import styled from 'styled-components';
const SlickSlider = styled(Slider) `
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  -webkit-tap-highlight-color: transparent;

  .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-track,
  .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  &.slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;

    > div {
      height: auto;
    }

    > div > div {
      outline: none;
    }
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  &.slick-initialized .slick-slide {
    display: block;
  }

  &.slick-loading .slick-slide {
    visibility: hidden;
  }

  &.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-dots {
    overflow: auto;
    white-space: nowrap;
    padding: 5px 0;
  }
  .slick-dots li {
    display: inline-block;
    width: 58px;
    opacity: 0.5;
    margin: 0 6px;

    &.slick-active {
      opacity: 1;
    }
  }

  .slick-track {
    display: flex;
  }
`;
export default SlickSlider;
