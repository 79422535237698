var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { selectComponentMarkupEditor } from 'src/modules/auth/selectors';
import { selectFleetById } from 'src/AdminApp/modules/fleets/selectors';
import { getNotTechnicians } from 'src/AdminApp/modules/users/actions';
import { patchUpdateFleet, getFleet, } from 'src/AdminApp/modules/fleets/actions';
import { FleetValidationSchema } from 'src/AdminApp/containers/fleets/utils/validations';
const useAccountManagement = () => {
    const dispatch = useDispatch();
    const { fleetId } = useParams();
    useEffect(() => {
        dispatch(getNotTechnicians());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fleet = fleetId
        ? useSelector(selectFleetById(fleetId))
        : null;
    const canEditStatus = useSelector(selectComponentMarkupEditor);
    const handleUpdateField = (field) => (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (value === fleet[field])
            return;
        try {
            yield FleetValidationSchema.validateAt(field, {
                [field]: value,
            });
            const updateField = `update${field
                .charAt(0)
                .toUpperCase()}${field.slice(1)}`;
            yield dispatch(patchUpdateFleet(fleetId, {
                [field]: value,
                [updateField]: true,
                laborRate: (fleet === null || fleet === void 0 ? void 0 : fleet.laborRate) === '' ? null : fleet === null || fleet === void 0 ? void 0 : fleet.laborRate,
                updateLaborRate: true,
            }));
            if (field === 'removed' && !value) {
                dispatch(getFleet(fleetId));
            }
        }
        catch (e) {
            toast.error(`Validation Error: ${e.message}`);
        }
    });
    return { dispatch, fleetId, fleet, canEditStatus, handleUpdateField };
};
export default useAccountManagement;
