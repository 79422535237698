import React from 'react';
import { ActionsBlock } from 'src/containers/layout/ActionsBlock';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import ShiftsFilters from './ShiftsFilters';
import DashboardListItem from './DashboardListItem';
import EditShiftDrawer from '../EditShiftDrawer';
import useShiftsDashboard from './useShiftsDashboard';
const REQUEST_COLUMNS = [
    { title: 'Name', key: 'name', sortable: true },
    { title: 'Status', key: 'status', sortable: true },
    { title: 'Consumer Default', key: 'isDefault', sortable: true },
    { title: 'Modified', key: 'modified', sortable: true },
    { title: 'Id', key: 'id', sortable: true },
];
const ShiftsDashboard = () => {
    const { shifts, pagination, isFetching, isShiftEditor, editShiftDrawerOpen, setPageSize, currentShiftId, onLoadData, onSearch, onClear, handleListItemClick, handleEditShiftDrawerClose, handleAddShift, } = useShiftsDashboard();
    const visitActions = [
        {
            function: handleAddShift,
            disabled: false,
            tooltip: undefined,
            copy: 'Add Shift',
            isCancelAction: false,
            hasBorderTop: false,
        },
    ];
    return isShiftEditor ? (<>
      <ActionsBlock actions={visitActions}/>
      <EditShiftDrawer open={editShiftDrawerOpen} onClose={handleEditShiftDrawerClose} shiftId={currentShiftId}/>
      <ShiftsFilters onSearch={onSearch} onClear={onClear}/>
      <SimpleTable data={shifts} pagination={pagination} sort={pagination.sort} isFetching={isFetching} columns={REQUEST_COLUMNS} renderItem={(item, index) => (<DashboardListItem {...item} key={`${index}-${item.referenceNum}`} onItemClick={(id) => {
                handleListItemClick(id);
            }}/>)} onLoadData={onLoadData} onRowsPerPageChange={setPageSize}/>
    </>) : (<div>
      ERROR - Not Authorized: You must have the "Shift_Admin" role to view this
      item
    </div>);
};
export default ShiftsDashboard;
