var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel, } from 'src/components/mui';
import styled from 'styled-components';
import { Field } from 'formik';
import { colors } from 'src/styles/theme';
export const Checkbox = styled(MuiCheckbox) `
  padding: 0;
  svg {
    color: ${colors.linkBlue};
  }

  &.Mui-disabled svg {
    color: ${colors.concreteGray};
  }
`;
const ControlLabel = styled(FormControlLabel) `
  margin: 0;

  span:nth-child(2) {
    font-weight: 300;
    margin-left: 4px;
    margin-top: 2px;
  }
`;
export const LabelledCheckbox = (props) => {
    const { checkboxProps } = props, restProps = __rest(props, ["checkboxProps"]);
    return (<ControlLabel {...restProps} control={<Checkbox {...checkboxProps}/>}/>);
};
export const CheckboxField = (_a) => {
    var { name, label = null, style, checked } = _a, rest = __rest(_a, ["name", "label", "style", "checked"]);
    return (<Field name={name}>
    {({ field }) => {
            return (<LabelledCheckbox checkboxProps={Object.assign(Object.assign(Object.assign({}, field), rest), { checked: typeof checked === 'undefined' ? field.value : checked })} label={label} style={style}/>);
        }}
  </Field>);
};
