import React from 'react';
import { RepairOrders, RepairOrderCard, RepairHeader, FleetLabel, VinStatus, DetailsLabel, DetailsValue, StatusContainer, RepairsSection, RefLink, } from 'src/FleetVisitsApp/pages/VisitView/AddExistingRepairOrdersDrawer/RepairOrdersStack/RepairOrdersStack.styles';
import { CardContent } from 'src/components/mui';
import moment from 'moment';
import { GetRepairsForDisplay } from 'src/FleetVisitsApp/utils/GetRepairsForDisplay';
import Services from 'src/FleetVisitsApp/pages/VisitView/Services';
import { RoStatusChip } from 'src/FleetVisitsApp/pages/VisitView/StatusChips';
import { STATIC_URL_BASE } from 'src/styles/theme';
import { LOCALIZED_DATE_LONGER_FORMAT } from 'src/timeConstants';
import { SelectButton } from '../AddRepairOrdersDrawer.styles';
const RepairOrdersStack = ({ repairOrders, addRepairOrder, selectedSet, isRoReadyToAdd, }) => {
    return (<RepairOrders>
      {repairOrders.map((ro) => {
            var _a;
            let action = 'Pending';
            if (ro.totalCost) {
                action = selectedSet.has(ro.workOrderId) ? 'Remove' : 'Add';
            }
            const { repairs, extraRepairsCount, allRepairs } = GetRepairsForDisplay({
                services: ro.diagnosisServices,
            });
            const maxLeadTime = ((_a = ro.diagnosisServices) === null || _a === void 0 ? void 0 : _a.maxLeadTime) || 0;
            const roDate = new Date();
            roDate.setDate(roDate.getDate() + maxLeadTime);
            const isAddable = isRoReadyToAdd(roDate);
            return (<RepairOrderCard variant="outlined" key={ro.repairRequest.id} roaction={action.toUpperCase()}>
            <CardContent>
              <RepairHeader>
                <FleetLabel>
                  <div>{ro.fleetName}</div>
                  <RefLink>
                    Ref #{' '}
                    <a href={`/admin/repairs/${ro.repairRequest.referenceNum}`} target="_blank" rel="noreferrer noopener">
                      {ro.repairRequest.referenceNum}
                    </a>
                  </RefLink>
                </FleetLabel>

                <SelectButton color="primary" variant={action === 'Pending' ? 'contained' : 'outlined'} disabled={action === 'Pending'} roaction={action.toUpperCase()} onClick={() => {
                    addRepairOrder(isAddable, ro, action.toUpperCase());
                }}>
                  {action}
                </SelectButton>
              </RepairHeader>

              <VinStatus>
                <div>
                  <DetailsLabel>VIN</DetailsLabel>
                  <DetailsValue>{ro.vin}</DetailsValue>
                </div>
                <StatusContainer>
                  <DetailsLabel>Status</DetailsLabel>
                  <RoStatusChip repairOrderStatus={ro.repairRequest.status}/>
                </StatusContainer>
              </VinStatus>

              <RepairsSection>
                <DetailsLabel>Repairs</DetailsLabel>

                <Services allRepairs={allRepairs !== null && allRepairs !== void 0 ? allRepairs : []} extraRepairsCount={extraRepairsCount !== null && extraRepairsCount !== void 0 ? extraRepairsCount : 0} repairs={repairs !== null && repairs !== void 0 ? repairs : []}/>
              </RepairsSection>
              <RepairsSection>
                <DetailsLabel>Creation Date</DetailsLabel>
                <DetailsValue>
                  {moment(ro.creationDate).format(LOCALIZED_DATE_LONGER_FORMAT)}
                </DetailsValue>
              </RepairsSection>
              <RepairsSection>
                <DetailsLabel>Earliest Avail Booking</DetailsLabel>
                <DetailsValue>
                  {moment(roDate.toISOString()).format(LOCALIZED_DATE_LONGER_FORMAT)}{' '}
                  {!isAddable && (<img alt="special" src={`${STATIC_URL_BASE}img/svg/special-order-label.svg`}/>)}
                </DetailsValue>
              </RepairsSection>
            </CardContent>
          </RepairOrderCard>);
        })}
    </RepairOrders>);
};
export default RepairOrdersStack;
