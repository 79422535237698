import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Button, FormControl, Grid as MuiGrid, TextField, } from 'src/components/mui';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { DATE_TIME_12H_FORMAT } from 'src/timeConstants';
const Grid = styled(MuiGrid) `
  margin-top: 10px;
`;
const Info = styled.div `
  color: ${colors.GRAY_AA};
  margin: 0 0 20px;
`;
const SnapshotLoader = ({ referenceNum, reload }) => {
    const history = useHistory();
    const [refNum, setRefNum] = useState(referenceNum);
    const [timestamp, setTimestsamp] = useState();
    const onChangeRefNum = (e) => setRefNum(e.target.value);
    const onKeyUp = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            onLoadClicked();
        }
    };
    const onLoadClicked = () => {
        if (refNum !== referenceNum) {
            history.push(`/system/snapshots/requests/${refNum}`);
        }
        else {
            setTimestsamp(Date.now());
            reload(referenceNum);
        }
    };
    useEffect(() => {
        setRefNum(referenceNum);
    }, [referenceNum]);
    return (<Grid container spacing={1} style={{ alignItems: 'flex-start' }}>
      <Grid item xs={12} md={2}>
        <FormControl margin="none">
          <TextField label="Reference Num" name="referenceNum" value={refNum || ''} fullWidth margin="none" onChange={onChangeRefNum} onKeyUp={onKeyUp} variant="standard" InputLabelProps={{
            shrink: true,
        }}/>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={1}>
        <Button variant="contained" onClick={onLoadClicked} color="primary">
          Load
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Info>loaded: {moment(timestamp).format(DATE_TIME_12H_FORMAT)}</Info>
      </Grid>
    </Grid>);
};
export default SnapshotLoader;
