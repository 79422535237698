import React, { useState } from 'react';
import PartsHelpDrawer from 'src/AdminApp/containers/requests/Quote/PartsHelp/PartsHelpDrawer';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import { SettingsRounded } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
const PartsHelp = ({ isEditable, isFmcRo = false, fetchQuoteFmcWorkAuthorizationStatus = () => { }, invoice, workOrderId, }) => {
    const [openPartsHelpDrawer, setOpenPartsHelpDrawer] = useState(false);
    const openDrawer = () => {
        setOpenPartsHelpDrawer(true);
    };
    const closeDrawer = () => {
        setOpenPartsHelpDrawer(false);
    };
    return (<>
      <SimpleRowButton startIcon={<SettingsRounded style={{
                color: colors.white,
                background: colors.primary,
                padding: 3,
                width: 20,
                height: 20,
                borderRadius: '50%',
            }}/>} color="primary" onClick={openDrawer}>
        Parts Help
      </SimpleRowButton>
      <PartsHelpDrawer open={openPartsHelpDrawer} onClose={closeDrawer} isFmcRo={isFmcRo} fetchQuoteFmcWorkAuthorizationStatus={fetchQuoteFmcWorkAuthorizationStatus} isEditable={isEditable} invoice={invoice} workOrderId={workOrderId}/>
    </>);
};
export default PartsHelp;
