import React from 'react';
import { reduce } from 'lodash';
import { Grid } from 'src/components/mui';
import RepairItem from 'src/AdminApp/components/repairs/statement/Repairs/Item';
import { StatementRow, CalculatedTotalCost, CalculatedTotalWrapper, SubItemGridWrapper, } from 'src/AdminApp/components/repairs/statement/styledComponents';
import AuditInfo from 'src/AdminApp/components/users/AuditInfo';
const RemovedRepairsDisplay = ({ repairs = [], partial = false, }) => {
    const titleStyle = {
        fontWeight: partial ? 'normal' : 'bold',
        color: partial ? '#9A9A9A' : 'black',
    };
    return (<>
      {repairs.map((repair) => {
            var _a, _b;
            const { id, name, price } = repair;
            const [firstPrice] = price;
            const allComponentPricesArray = ((_a = firstPrice === null || firstPrice === void 0 ? void 0 : firstPrice.fluids) !== null && _a !== void 0 ? _a : []).concat((_b = firstPrice === null || firstPrice === void 0 ? void 0 : firstPrice.parts) !== null && _b !== void 0 ? _b : []);
            const calculatedTotal = partial
                ? reduce(allComponentPricesArray, (sum, price) => { var _a; return sum + ((_a = price === null || price === void 0 ? void 0 : price.totalPrice) !== null && _a !== void 0 ? _a : 0); }, 0)
                : firstPrice.calculatedTotalCost;
            return (<StatementRow key={`removed_repair_${id}`}>
            <Grid container style={{ justifyContent: 'space-between' }}>
              <div style={titleStyle}>{name}</div>
              <CalculatedTotalWrapper>
                <CalculatedTotalCost values={calculatedTotal}/>
              </CalculatedTotalWrapper>
            </Grid>
            <SubItemGridWrapper item xs={12} style={{ margin: '20px 0 0 20px' }}>
              <RepairItem key={`removed_repair_${id}`} price={firstPrice} partial={partial}/>
            </SubItemGridWrapper>
            <SubItemGridWrapper item xs={12}>
              <AuditInfo auditType="REMOVED" auditedTimestamp={repair.modified} auditedBy={repair.modifiedBy}/>
            </SubItemGridWrapper>
          </StatementRow>);
        })}
    </>);
};
export default RemovedRepairsDisplay;
