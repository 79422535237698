import { Typography } from 'src/components/mui';
import { QuotedPod } from 'src/AdminApp/components/repairs/statement/styledComponents';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const RequestInfoDisplayText = styled(Typography) `
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  color: ${colors.default};
  margin-bottom: 0.75rem;
`;
export const RepairDropdownStyled = styled.div `
  border-bottom: 1px solid ${colors.lightGrey};
`;
export const RepairDropdownSummary = styled.div `
  width: 100%;
  text-align: left;
  display: block;
  padding: 24px 0;
  position: relative;

  ${QuotedPod} {
    border-right: 1px dashed ${colors.GRAY_AA};
    margin-top: -10px;
    margin-bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 23px;
  }
`;
