var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'src/styles/theme';
import { Cloud, CloudDone, CloudOff } from 'src/components/mui/icons';
import { useNetSuiteApiClient } from '../useNetSuiteApiClient';
import { selectAuth } from '../modules/selectors';
import { ActionCreators } from '../modules/actions';
const getStatusText = (auth) => {
    if (auth === null || auth === void 0 ? void 0 : auth._isFetching) {
        return 'Connecting';
    }
    if (auth === null || auth === void 0 ? void 0 : auth.access_token) {
        return 'Connected';
    }
    return 'Not Connected';
};
const StatusIcon = ({ auth }) => {
    switch (getStatusText(auth)) {
        case 'Connected':
            return <CloudDone style={{ color: colors.green }}/>;
        case 'Connecting':
            return <Cloud style={{ color: colors.lightOrange }}/>;
        default:
            return <CloudOff />;
    }
};
const StatusIconToggle = () => {
    const dispatch = useDispatch();
    const netSuiteApiClient = useNetSuiteApiClient();
    const auth = useSelector(selectAuth);
    const status = useMemo(() => getStatusText(auth), [auth]);
    const connect = () => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(ActionCreators.NETSUITE_AUTH.CONNECT());
        const response = yield netSuiteApiClient.authenticate();
        const data = yield response.json();
        dispatch(ActionCreators.NETSUITE_AUTH.CONNECTED({ payload: data }));
    });
    const disconnect = () => {
        dispatch(ActionCreators.NETSUITE_AUTH.DISCONNECT());
    };
    switch (status) {
        case 'Connected':
            return (<span style={{ cursor: 'pointer' }} onClick={disconnect} onKeyDown={() => { }} role="button" tabIndex={0}>
          <StatusIcon auth={auth}/>
        </span>);
        case 'Connecting':
            return (<span>
          <StatusIcon auth={auth}/>
        </span>);
        default:
            return (<span style={{ cursor: 'pointer' }} onClick={connect} tabIndex={0} role="button" onKeyDown={() => { }}>
          <StatusIcon auth={auth}/>
        </span>);
    }
};
export { StatusIcon, StatusIconToggle, getStatusText };
