import React from 'react';
import styled from 'styled-components';
import { Table, Typography } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const AppointmentTable = styled(Table) `
  margin-bottom: 41px;
`;
export const SubTitle = styled.div `
  display: flex;

  & svg {
    color: ${colors.lightGrey};
    margin-right: 8px;
  }
`;
export const IconMargin = styled.div `
  margin-left: 27px;
  margin-bottom: 41px;
`;
export const StatusWrapper = styled.div `
  display: flex;
  align-items: center;
`;
export const CheckListItem = styled.div `
  margin-top: 16px;
`;
export const DrivingTimeWrapper = styled.div `
  margin-top: 24px;
  margin-bottom: 4px;
`;
export const DrivingStepsWrapper = styled.ul `
  list-style-type: decimal;
  padding-left: 10px;
`;
export const Section = ({ icon, title, children }) => (<div>
    <Typography variant="subtitle1" component="span">
      <SubTitle>
        {icon}
        <span>&nbsp;{title}&nbsp;</span>
      </SubTitle>
    </Typography>
    <Typography variant="body1" component="span">
      <IconMargin>{children}</IconMargin>
    </Typography>
  </div>);
