import moment from 'moment';
import { isNull, isNil } from 'lodash';
export function formatInvoice(invoice) {
    if (!invoice)
        return undefined;
    const { laborTaxRate: laborTaxRateProp, partsTaxRate: partsTaxRateProp, laborTaxAmount, partsTaxAmount, } = invoice;
    const formattedInvoice = {
        hazardousMaterialsFee: invoice === null || invoice === void 0 ? void 0 : invoice.hazardousMaterialsFee,
        deliveryFee: invoice === null || invoice === void 0 ? void 0 : invoice.deliveryFee,
        cancelFee: invoice === null || invoice === void 0 ? void 0 : invoice.cancelFee,
        depositAmount: invoice === null || invoice === void 0 ? void 0 : invoice.depositAmount,
        laborTaxRate: !isNull(laborTaxRateProp)
            ? (laborTaxRateProp * 100).toFixed(2)
            : null,
        laborTaxAmount: !isNull(laborTaxAmount)
            ? laborTaxAmount.toFixed(2)
            : null,
        laborTotalPrice: invoice === null || invoice === void 0 ? void 0 : invoice.laborTotalPrice,
        partsTaxRate: !isNull(partsTaxRateProp)
            ? (partsTaxRateProp * 100).toFixed(2)
            : null,
        partsTaxAmount: !isNull(partsTaxAmount)
            ? partsTaxAmount.toFixed(2)
            : null,
        partsTotalPrice: invoice === null || invoice === void 0 ? void 0 : invoice.partsTotalPrice,
        shopSuppliesFee: invoice === null || invoice === void 0 ? void 0 : invoice.shopSuppliesFee,
        totalPrice: invoice === null || invoice === void 0 ? void 0 : invoice.totalPrice,
        totalSublet: invoice === null || invoice === void 0 ? void 0 : invoice.totalSublet,
        totalFees: invoice === null || invoice === void 0 ? void 0 : invoice.totalFees,
        subtotal: invoice === null || invoice === void 0 ? void 0 : invoice.subtotal,
        totalTaxes: invoice === null || invoice === void 0 ? void 0 : invoice.totalTaxes,
    };
    return Object.assign(Object.assign({}, invoice), formattedInvoice);
}
export function getIsEditTaxesDisabled(invoice) {
    return !!invoice.lastTaxCalculatedBy;
}
export const priceModifiedSinceTaxCalculation = (invoice) => {
    const { priceModified, lastTaxCalculated } = invoice;
    return moment(priceModified).isAfter(lastTaxCalculated);
};
export const canTaxesBeRecalculated = (invoice) => {
    return !isNil(invoice.laborTaxRate) && !isNil(invoice.partsTaxRate);
};
export const calculateTaxes = (values, initial) => {
    const taxes = Object.assign({}, values);
    if (values.laborTaxAmount !== initial.laborTaxAmount) {
        taxes.laborTaxRate = getPercentage(values.laborTaxAmount, values.laborTotalPrice);
    }
    if (values.laborTaxRate !== initial.laborTaxRate) {
        taxes.laborTaxAmount = getPercentValue(values.laborTaxRate, values.laborTotalPrice);
    }
    if (values.partsTaxAmount !== initial.partsTaxAmount) {
        taxes.partsTaxRate = getPercentage(values.partsTaxAmount, values.partsTotalPrice);
    }
    if (values.partsTaxRate !== initial.partsTaxRate) {
        taxes.partsTaxAmount = getPercentValue(values.partsTaxRate, values.partsTotalPrice);
    }
    return taxes;
};
export const getPercentValue = (percent, value) => value * (percent / 100);
export const getPercentage = (amount, total) => (amount * 100) / total;
