import React, { useEffect, useState } from 'react';
import { toast } from 'src/components/SimpleToast';
import { Box, Grid, Tab, Tabs, Alert } from 'src/components/mui';
import { TabPanel } from 'src/AdminApp/components/layout/TabPanel';
import { uniqBy } from 'lodash';
import { useSkeduloGraphqlApiClient } from '../api/skeduloGraphqlApiClient';
import { TimezoneFilter, RegionFilter } from '../components';
import { compareStrings, duplicatesFilter } from '../utils';
import Regions from './Regions';
import Resources from './Resources';
import { ResourcesFilters, ResourcesFilter } from './styles';
const SkeduloResources = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const [regions, setRegions] = useState([]);
    const [filteredRegions, setFilteredRegions] = useState([]);
    const [totalRegions, setTotalRegions] = useState(null);
    const [vans, setVans] = useState([]);
    const [filteredVans, setFilteredVans] = useState([]);
    const [totalVans, setTotalVans] = useState(null);
    const [mechanics, setMechanics] = useState([]);
    const [filteredMechanics, setFilteredMechanics] = useState([]);
    const [totalMechanics, setTotalMechanics] = useState(null);
    const [unassigneds, setUnassigneds] = useState([]);
    const [filteredUnassigneds, setFilteredUnassigneds] = useState([]);
    const [totalUnassigneds, setTotalUnassigneds] = useState(null);
    const [timezones, setTimezones] = useState([]);
    const [timezone, setTimezone] = useState('');
    const [regionOptions, setRegionOptions] = useState([]);
    const [region, setRegion] = useState('');
    const skeduloGraphqlApiClient = useSkeduloGraphqlApiClient();
    const onRegionFilterChange = (e) => setRegion(e.target.value);
    const onTimezoneFilterChange = (e) => {
        setTimezone(e.target.value);
        setRegion('');
    };
    const onTabChange = (e, tab) => setCurrentTab(tab);
    const generateTabLabel = (name, items, totalItems) => {
        return `${name} (${totalItems != null ? `${items === null || items === void 0 ? void 0 : items.length}/${totalItems}` : '...'})`;
    };
    const fetchRegions = () => {
        skeduloGraphqlApiClient
            .fetchRegions()
            .then((response) => {
            const regions = response.data.regions.edges.map((edge) => edge.node);
            const allResources = extractResourcesFromRegions(regions);
            const { vans, mechanics, unassigneds } = groupResourcesByType(allResources);
            const sortedRegions = regions === null || regions === void 0 ? void 0 : regions.map((region) => region.Timezone).sort(compareStrings);
            const timezones = [...uniqBy(sortedRegions, 'UID')];
            setTimezones(timezones);
            setRegions(regions);
            setTotalRegions(response.data.regions.totalCount);
            setFilteredRegions(getFilteredRegions(regions));
            setVans(vans);
            setTotalVans(vans.length);
            setFilteredVans(getFilteredResources(vans));
            setMechanics(mechanics);
            setTotalMechanics(mechanics.length);
            setFilteredMechanics(getFilteredResources(mechanics));
            setUnassigneds(unassigneds);
            setTotalUnassigneds(unassigneds.length);
            setFilteredUnassigneds(getFilteredResources(unassigneds));
        })
            .catch((ex) => {
            toast.error(`Could not fetch Regions\n${ex.message}`);
        });
    };
    const onRegionSave = (region) => {
        const newRegions = regions.map((r) => r.UID === region.UID ? region : r);
        setRegions(newRegions);
        setRegionOptions(getRegionsInTimezone(newRegions, timezone));
        setFilteredRegions(getFilteredRegions(newRegions));
        // update the PrimaryRegion of the Resources
        const applyRegionToResources = (resources) => resources.map((resource) => {
            var _a;
            return ((_a = resource.PrimaryRegion) === null || _a === void 0 ? void 0 : _a.UID) === region.UID
                ? Object.assign(Object.assign({}, resource), { PrimaryRegion: region }) : resource;
        });
        const newVans = applyRegionToResources(vans);
        setVans(newVans);
        setFilteredVans(getFilteredResources(newVans));
        const newMechanics = applyRegionToResources(mechanics);
        setMechanics(newMechanics);
        setFilteredMechanics(getFilteredResources(newMechanics));
    };
    const onVanSave = (van) => {
        const newVans = vans.map((v) => v.UID === van.UID ? van : v);
        setVans(newVans);
        setFilteredVans(getFilteredResources(newVans));
    };
    const onMechanicSave = (mechanic) => {
        const newMechanics = mechanics.map((m) => m.UID === mechanic.UID ? mechanic : m);
        setMechanics(newMechanics);
        setFilteredMechanics(getFilteredResources(newMechanics));
    };
    const onUnassignedSave = (unassigned) => {
        const newUnassigneds = unassigneds.map((u) => u.UID === unassigned.UID ? unassigned : u);
        setUnassigneds(newUnassigneds);
        setFilteredUnassigneds(getFilteredResources(newUnassigneds));
    };
    const onResourceCountClick = (tag, timezone, regionID) => {
        if (tag === 'Van') {
            setCurrentTab(1);
        }
        else if (tag === 'Mechanic') {
            setCurrentTab(2);
        }
        else if (tag === 'Unassigned') {
            setCurrentTab(3);
        }
        setTimezone(timezone);
        setRegion(regionID);
    };
    const onRegionClick = (region) => {
        setCurrentTab(0);
        setTimezone(region.Timezone);
        setRegion(region.UID);
    };
    const getRegionsInTimezone = (regions, timezone) => {
        return regions.filter((r) => !timezone || r.Timezone === timezone);
    };
    const getFilteredRegions = (regions) => {
        return regions
            .filter((r) => !timezone || r.Timezone === timezone)
            .filter((r) => !region || r.UID === region);
    };
    const getFilteredResources = (resources) => {
        return resources
            .filter((r) => { var _a; return !timezone || ((_a = r.PrimaryRegion) === null || _a === void 0 ? void 0 : _a.Timezone) === timezone; })
            .filter((r) => { var _a; return !region || ((_a = r.PrimaryRegion) === null || _a === void 0 ? void 0 : _a.UID) === region; });
    };
    const groupResourcesByType = (resources) => {
        const vans = resources.filter((resource) => {
            return (resource.IsActive &&
                resource.ResourceTags.filter((tag) => tag.Tag.Name === 'Van').length > 0);
        });
        const mechanics = resources.filter((resource) => {
            return (resource.IsActive &&
                resource.ResourceTags.filter((tag) => tag.Tag.Name === 'Mechanic')
                    .length > 0);
        });
        const unassigneds = resources.filter((resource) => {
            return (resource.IsActive &&
                !(resource.ResourceTags.filter((tag) => tag.Tag.Name === 'Van').length >
                    0) &&
                !(resource.ResourceTags.filter((tag) => tag.Tag.Name === 'Mechanic')
                    .length > 0));
        });
        return {
            vans,
            mechanics,
            unassigneds,
        };
    };
    const extractResourcesFromRegions = (regions) => {
        const compareResourceName = (a, b) => compareStrings(a.Name, b.Name);
        const allResources = regions
            .map((region) => region.Resources)
            .flat()
            .filter((resource) => resource.IsActive)
            .sort(compareResourceName)
            .filter(duplicatesFilter);
        return allResources;
    };
    useEffect(() => {
        setRegionOptions(getRegionsInTimezone(regions, timezone));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezone]);
    useEffect(() => {
        setFilteredRegions(getFilteredRegions(regions));
        setFilteredVans(getFilteredResources(vans));
        setFilteredMechanics(getFilteredResources(mechanics));
        setFilteredUnassigneds(getFilteredResources(unassigneds));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezone, region]);
    useEffect(() => {
        fetchRegions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<Grid container spacing={2} style={{ alignItems: 'flex-end' }}>
      <Grid item xs={6}>
        <Alert severity="info">
          <Box>Simple UI for managing Resources in Skedulo</Box>
        </Alert>
      </Grid>
      <ResourcesFilters>
        <ResourcesFilter item xs={3}>
          <TimezoneFilter options={timezones} value={timezone} onChange={onTimezoneFilterChange}/>
        </ResourcesFilter>
        <ResourcesFilter item xs={5}>
          <RegionFilter options={regionOptions} value={region} onChange={onRegionFilterChange}/>
        </ResourcesFilter>{' '}
      </ResourcesFilters>
      <Grid item xs={12}>
        <Tabs value={currentTab} onChange={onTabChange} aria-label="Skedulo Resources">
          <Tab label={generateTabLabel('Regions', filteredRegions, totalRegions)}/>
          <Tab label={generateTabLabel('Vans', filteredVans, totalVans)}/>
          <Tab label={generateTabLabel('Mechanics', filteredMechanics, totalMechanics)}/>
          <Tab label={generateTabLabel('Unassigned', filteredUnassigneds, totalUnassigneds)}/>
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          <Regions regions={filteredRegions} onSaveCallback={onRegionSave} onResourceCountClick={onResourceCountClick} groupResourcesByType={groupResourcesByType}/>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Resources resources={filteredVans} onSaveCallback={onVanSave} onRegionClick={onRegionClick}/>
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <Resources resources={filteredMechanics} onSaveCallback={onMechanicSave} onRegionClick={onRegionClick}/>
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <Resources resources={filteredUnassigneds} onSaveCallback={onUnassignedSave} onRegionClick={onRegionClick}/>
        </TabPanel>
      </Grid>
    </Grid>);
};
export default SkeduloResources;
