var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkDashboardUpdates, getWorkbenchDashboard, resetWorkbenchItemsSelected, updateWorkbenchSelectedItems, getInvoiceRequest, } from 'src/AdminApp/modules/invoiceWorkbench/actions';
import { selectFleets, selectInvoices, selectIsFetching, selectItemsSelected, selectPagination, selectResponsibleParties, } from 'src/AdminApp/modules/invoiceWorkbench/selectors';
import { selectWorpDriveAccess } from 'src/modules/auth/selectors';
const getInvoiceId = (item) => {
    if (!item)
        return Math.ceil(Math.random() * 100000).toString();
    return item.referenceNum + '_' + item.responsiblePartyId;
};
const getWorkbenchFilters = () => {
    const s = localStorage.getItem('workbenchFilters');
    return s
        ? JSON.parse(s)
        : {
            mode: 0,
            status: [],
            selectedFilterId: null,
            pageSize: 50,
            pageNumber: 0,
            tableSort: ['appointmentDate,asc'],
        };
};
const setWorkbenchFilters = (filters) => {
    const base = getWorkbenchFilters();
    localStorage.setItem('workbenchFilters', JSON.stringify(Object.assign(Object.assign({}, base), filters)));
};
const savedRefreshTime = localStorage.getItem('workbench.refreshTime');
const useInvoiceWorkbench = () => {
    const dispatch = useDispatch();
    const worpDriveAccess = useSelector(selectWorpDriveAccess);
    const invoices = useSelector(selectInvoices);
    const pagination = useSelector(selectPagination);
    const itemsSelected = useSelector(selectItemsSelected);
    const isFetching = useSelector(selectIsFetching);
    const fleets = useSelector(selectFleets);
    const responsibleParties = useSelector(selectResponsibleParties);
    const [loading, setLoading] = useState(false);
    const [searchedItems, setSearchedItems] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [tableSort, setTableSort] = useState();
    const [selectedFilterId, setSelectedFilterId] = useState();
    const [mode, setMode] = useState(0);
    const [status, setStatus] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [openSendModal, setOpenSendModal] = useState(false);
    const [isRefreshSnackbarOpened, setIsRefreshSnackbarOpened] = useState(false);
    const [openIRDetails, setOpenIRDetails] = useState(false);
    const [invoiceRequest, setInvoiceRequest] = useState();
    const [invoiceRequestLoading, setInvoiceRequestLoading] = useState(false);
    const refreshIntervalRef = useRef();
    useEffect(() => {
        const filters = getWorkbenchFilters();
        setPageSize(filters.pageSize);
        setTableSort(filters.tableSort);
        setSelectedFilterId(filters.selectedFilterId);
        setStatus(['CLOSED']);
        setMode(filters.mode);
        handleRefresh(filters);
    }, [dispatch]);
    useEffect(() => {
        if (mode === 0) {
            setSearchedItems(fleets);
        }
        else {
            setSearchedItems(responsibleParties);
        }
    }, [mode, status]);
    const checkRefreshRequired = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const response = yield dispatch(checkDashboardUpdates(getSearchParams()));
        if (!((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.results)) {
            return;
        }
        if (((_b = response.payload.results.content) === null || _b === void 0 ? void 0 : _b.length) < 1) {
            return;
        }
        const result = response.payload.results;
        let needRefresh = false;
        // totalElements are changed
        if (result.totalElements !== pagination.totalElements) {
            needRefresh = true;
        }
        // first invoice is changed
        if ((invoices === null || invoices === void 0 ? void 0 : invoices.length) > 0 &&
            getInvoiceId(result.content[0]) !== getInvoiceId(invoices[0])) {
            needRefresh = true;
        }
        // last invoice is changed
        if (getInvoiceId(result.content[result.content.length - 1]) !==
            getInvoiceId(invoices[result.content.length - 1])) {
            needRefresh = true;
        }
        if (needRefresh && !isRefreshSnackbarOpened) {
            setIsRefreshSnackbarOpened(true);
        }
    });
    useEffect(() => {
        // use 'workbench.refreshTime' from local storage to set refresh interval in seconds
        const REFRESH_TIME = savedRefreshTime
            ? parseInt(savedRefreshTime, 10) * 1000
            : 3 * 60 * 1000; // 3 minutes
        if (refreshIntervalRef.current) {
            clearInterval(refreshIntervalRef.current);
        }
        refreshIntervalRef.current = setInterval(() => checkRefreshRequired(), REFRESH_TIME);
        return () => {
            if (refreshIntervalRef.current) {
                clearInterval(refreshIntervalRef.current);
            }
        };
    }, [mode, selectedFilterId, status, invoices]);
    const getSearchParams = (filters) => {
        const innerFilters = {
            mode: filters ? filters.mode : mode,
            status: filters ? filters.status : status,
            selectedFilterId: filters ? filters.selectedFilterId : selectedFilterId,
            pageSize: filters ? filters.pageSize : pageSize,
            pageNumber: filters ? filters.pageNumber : pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber,
            tableSort: filters ? filters.tableSort : tableSort,
        };
        const params = {
            customerType: innerFilters.mode === 0 ? 'FLEET' : 'CONSUMER',
            pageNumber: innerFilters.pageNumber,
            pageSize: innerFilters.pageSize,
        };
        if (innerFilters.status) {
            params.roStatus = innerFilters.status;
        }
        params.returnFacets = true;
        if (innerFilters.selectedFilterId) {
            if (innerFilters.mode === 0) {
                params.fleetId = innerFilters === null || innerFilters === void 0 ? void 0 : innerFilters.selectedFilterId;
            }
            else {
                params.responsiblePartyId = innerFilters === null || innerFilters === void 0 ? void 0 : innerFilters.selectedFilterId;
            }
            params.returnFacets = !!filters;
        }
        if (innerFilters === null || innerFilters === void 0 ? void 0 : innerFilters.tableSort) {
            params.sort = innerFilters === null || innerFilters === void 0 ? void 0 : innerFilters.tableSort;
        }
        return params;
    };
    const onLoadData = ({ page, size, sort }) => {
        const params = getSearchParams();
        if (typeof page !== 'undefined') {
            params.pageNumber = page;
            setWorkbenchFilters({ pageNumber: page });
        }
        if (typeof size !== 'undefined') {
            params.pageSize = size;
            setWorkbenchFilters({ pageSize: size });
            setPageSize(size);
        }
        if (typeof sort !== 'undefined') {
            setTableSort(sort);
            params.sort = sort;
        }
        dispatch(getWorkbenchDashboard(params));
    };
    const onRowsPerPageChange = (newPageSize) => {
        setWorkbenchFilters({
            pageSize: newPageSize,
            pageNumber: 0,
        });
        dispatch(getWorkbenchDashboard(Object.assign(Object.assign({}, getSearchParams()), { pageNumber: 0, pageSize: newPageSize })));
    };
    const handleResetSelection = () => {
        dispatch(resetWorkbenchItemsSelected());
    };
    const handleUpdateItemsSelected = (item) => {
        var _a;
        if (((_a = invoices.find((invoice) => getInvoiceId(invoice) === getInvoiceId(item))) === null || _a === void 0 ? void 0 : _a.status) === 'NEEDS_REVIEW') {
            return;
        }
        let updatedItems = [];
        if (itemsSelected) {
            updatedItems = [...itemsSelected];
            const itemIndex = updatedItems.findIndex((updatedItem) => getInvoiceId(updatedItem) === getInvoiceId(item));
            if (itemIndex !== -1) {
                updatedItems.splice(itemIndex, 1);
            }
            else {
                updatedItems.push(item);
            }
        }
        else {
            updatedItems = [item];
        }
        dispatch(updateWorkbenchSelectedItems(updatedItems));
    };
    const handleIRStatusClick = (item) => __awaiter(void 0, void 0, void 0, function* () {
        setOpenIRDetails(true);
        setInvoiceRequestLoading(true);
        const response = yield dispatch(getInvoiceRequest(item.invoiceRequestId));
        setInvoiceRequest(response.payload);
        setInvoiceRequestLoading(false);
    });
    const handleCloseIRDetails = () => {
        setOpenIRDetails(false);
        setInvoiceRequest(null);
    };
    const handleSelectAll = () => {
        const isAllSelected = invoices === null || invoices === void 0 ? void 0 : invoices.every((item) => {
            if (isItemDisabled(item))
                return true;
            const index = itemsSelected === null || itemsSelected === void 0 ? void 0 : itemsSelected.findIndex((selectedItem) => getInvoiceId(selectedItem) === getInvoiceId(item));
            return index !== -1;
        });
        let items = invoices === null || invoices === void 0 ? void 0 : invoices.filter((item) => !isItemDisabled(item));
        if (!isAllSelected) {
            items = items.filter((item) => {
                return ((itemsSelected === null || itemsSelected === void 0 ? void 0 : itemsSelected.findIndex((selectedItem) => getInvoiceId(selectedItem) === getInvoiceId(item))) === -1);
            });
        }
        let updatedItems = [];
        if (itemsSelected) {
            updatedItems = [...itemsSelected];
            items.forEach((item) => {
                const index = updatedItems.findIndex((updatedItem) => getInvoiceId(updatedItem) === getInvoiceId(item));
                if (index !== -1) {
                    updatedItems.splice(index, 1);
                }
                else {
                    updatedItems.push(item);
                }
            });
        }
        else {
            updatedItems = items;
        }
        dispatch(updateWorkbenchSelectedItems(updatedItems));
    };
    const handleCheckAllSelected = () => {
        if (!(invoices === null || invoices === void 0 ? void 0 : invoices.length))
            return false;
        if (invoices === null || invoices === void 0 ? void 0 : invoices.every((item) => isItemDisabled(item))) {
            return false;
        }
        return invoices === null || invoices === void 0 ? void 0 : invoices.every((item) => {
            if (isItemDisabled(item))
                return true;
            const index = itemsSelected === null || itemsSelected === void 0 ? void 0 : itemsSelected.findIndex((selectedItem) => getInvoiceId(selectedItem) === getInvoiceId(item));
            return index !== -1;
        });
    };
    const handleChangeMode = (event, value) => {
        setStatus(['CLOSED']);
        setSelectedFilterId(undefined);
        setMode(value);
        setTableSort(['appointmentDate,asc']);
        setWorkbenchFilters({
            status: ['CLOSED'],
            mode: value,
            selectedFilterId: undefined,
            pageNumber: 0,
            tableSort: ['appointmentDate,asc'],
        });
        const params = Object.assign(Object.assign({}, getSearchParams()), { customerType: value === 0 ? 'FLEET' : 'CONSUMER', pageNumber: 0, roStatus: ['CLOSED'], returnFacets: true, sort: ['appointmentDate,asc'] });
        if (value === 0) {
            delete params.responsiblePartyId;
        }
        else {
            delete params.fleetId;
        }
        dispatch(getWorkbenchDashboard(params));
        dispatch(resetWorkbenchItemsSelected());
    };
    const handleChangeStatus = (status) => {
        setStatus(status);
        setWorkbenchFilters({
            status,
        });
        setSelectedFilterId(undefined);
        const params = Object.assign(Object.assign({}, getSearchParams()), { pageNumber: 0, roStatus: status, returnFacets: true });
        dispatch(getWorkbenchDashboard(params));
        dispatch(resetWorkbenchItemsSelected());
    };
    const handleClearFilters = () => {
        setSelectedFilterId(undefined);
        if (mode === 0) {
            setSearchedItems(fleets);
        }
        else {
            setSearchedItems(responsibleParties);
        }
        setStatus(['CLOSED']);
        setWorkbenchFilters({
            status: [],
            selectedFilterId: undefined,
            pageNumber: 0,
        });
        const params = Object.assign(Object.assign({}, getSearchParams()), { pageNumber: 0, roStatus: [], returnFacets: true });
        delete params.fleetId;
        delete params.responsiblePartyId;
        dispatch(getWorkbenchDashboard(params));
        dispatch(resetWorkbenchItemsSelected());
    };
    const isItemChecked = (item) => {
        if (item.status === 'NEEDS_REVIEW')
            return false;
        const index = itemsSelected === null || itemsSelected === void 0 ? void 0 : itemsSelected.findIndex((selectedItem) => getInvoiceId(selectedItem) === getInvoiceId(item));
        return index !== -1;
    };
    const isItemDisabled = (item) => {
        return (item.status === 'NEEDS_REVIEW' ||
            (item.invoiceRequestStatus &&
                ['PENDING', 'GENERATING_INVOICES', 'SENDING_INVOICES'].includes(item.invoiceRequestStatus)));
    };
    const handleSearch = (search) => {
        let values;
        if (mode === 0) {
            values = fleets;
        }
        else {
            values = responsibleParties;
        }
        if (search === null || search === void 0 ? void 0 : search.length) {
            values = values === null || values === void 0 ? void 0 : values.filter((item) => {
                return item.text.toLowerCase().includes(search.toLowerCase());
            });
        }
        setSearchedItems(values);
    };
    const handleSelectFacet = (id) => {
        setSelectedFilterId(id);
        setWorkbenchFilters({
            selectedFilterId: id,
        });
        const params = Object.assign(Object.assign({}, getSearchParams()), { pageNumber: 0 });
        if (mode === 0) {
            params.fleetId = id;
        }
        else {
            params.responsiblePartyId = id;
        }
        if (!id) {
            delete params.fleetId;
            delete params.responsiblePartyId;
            const hasSuggestions = mode === 0 ? (fleets === null || fleets === void 0 ? void 0 : fleets.length) > 0 : (responsibleParties === null || responsibleParties === void 0 ? void 0 : responsibleParties.length) > 0;
            if (!hasSuggestions) {
                params.returnFacets = true;
            }
        }
        dispatch(getWorkbenchDashboard(params));
        dispatch(resetWorkbenchItemsSelected());
    };
    const handleWorkbenchItemsSubmit = () => {
        if (!itemsSelected)
            return;
        setIsSubmitting(true);
        setOpenSendModal(true);
    };
    const handleCloseSendModal = (clearSelected = true) => {
        setOpenSendModal(false);
        setIsSubmitting(false);
        if (clearSelected) {
            handleResetSelection();
            dispatch(getWorkbenchDashboard(getSearchParams()));
        }
    };
    const handleRefresh = (filters) => {
        setIsRefreshSnackbarOpened(false);
        dispatch(getWorkbenchDashboard(getSearchParams(filters)));
    };
    return {
        worpDriveAccess,
        invoices,
        pagination,
        isFetching,
        fleets,
        responsibleParties,
        openIRDetails,
        invoiceRequest,
        onLoadData,
        onRowsPerPageChange,
        loading,
        setLoading,
        itemsSelected,
        handleResetSelection,
        handleUpdateItemsSelected,
        handleSelectAll,
        handleCheckAllSelected,
        handleChangeMode,
        handleChangeStatus,
        handleClearFilters,
        handleWorkbenchItemsSubmit,
        handleIRStatusClick,
        handleCloseIRDetails,
        isSubmitting,
        openSendModal,
        handleCloseSendModal,
        handleRefresh,
        isItemChecked,
        isItemDisabled,
        mode,
        setMode,
        status,
        setStatus,
        setPageSize,
        selectedFilterId,
        setSelectedFilterId,
        searchedItems,
        handleSearch,
        handleSelectFacet,
        tableSort,
        setTableSort,
        isRefreshSnackbarOpened,
        getSearchParams,
        checkRefreshRequired,
        invoiceRequestLoading,
    };
};
export default useInvoiceWorkbench;
