import { Button, FormControl, FormControlLabel, RadioGroup, Typography, } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
import styled from 'styled-components';
export const IntroContent = styled.section `
  text-align: center;
`;
export const HeyTechWrapper = styled.section `
  text-align: center;
`;
export const RadioButtonWrapper = styled.section `
  text-align: left;
  padding: 0 30px;
`;
export const SelectWrapper = styled.section `
  padding: 0 30px;
  margin-top: 3rem;
`;
export const IntroHeader = styled.h2 `
  margin: 8px 0 0;
`;
export const IntroCopy = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${colors.GRAY_76};
  margin: 16px 0 0;
`;
export const AuthScriptHeader = styled.h2 `
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AuthScriptSubheader = styled.p `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  margin: 6px 0 0;
`;
export const AuthScriptCopy = styled.section `
  margin-top: 32px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
`;
export const DividerXl = styled.hr `
  width: 100%;
  height: 1px;
  margin: 2.5rem 0;
  background: ${colors.GRAY_DE};
  border: 0;
`;
export const RadioLabel = styled.div `
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.1px;
  text-align: left;
  color: ${colors.GRAY_76};
`;
export const Loader = styled.section `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 215px;
`;
export const ErrorIconStyle = {
    color: colors.error,
};
export const SuccessIconStyle = {
    color: colors.success,
};
export const StyledRadioGroup = styled(RadioGroup) `
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  gap: 2.5rem;
  overflow: hidden;
`;
export const CheckboxWrapper = styled.div `
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  text-align: center;
  .checkboxSubtitle {
    font-size: 12px;
  }
`;
export const DrawerContentWrapper = styled.div `
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;
  height: calc(100vh - 130px);
  width: 425px;

  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.5px;

  ${theme.breakpoints.down('sm')} {
    width: 100vw;
  }
`;
export const ViewCCDetailsWrapper = styled.section `
  padding: 40px 0 100px 0;
`;
export const ViewCCDetailsHeader = styled.h2 `
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: -0.5px;
  margin: 0;
`;
export const SectionDetails = styled.section `
  padding: 24px;
  border-radius: 4px;
  border: solid 1px ${colors.GRAY_DE};
  background-color: ${colors.GRAY_FA};
`;
export const SectionHeader = styled.h3 `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  margin-top: 32px;
`;
export const ChangeBtn = styled(Button) `
  padding: 0;
`;
export const AffirmButton = styled(Button) `
  font-size: 12px;
  font-weight: 800;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: 4px;
  }
`;
export const LocationWrapper = styled.section `
  padding: 28px 16px 0;
`;
export const RadioGroupFormControl = styled(FormControl) `
  width: 100%;
`;
export const RadioControlLabel = styled(FormControlLabel) `
  display: flex;
  justify-content: space-between;
  margin-left: 0;
`;
export const RadioGroupForm = styled(RadioGroup) `
  gap: 10px;
`;
export const ButtonLink = styled(Button) `
  color: ${colors.primary};
  &:hover {
    background-color: transparent;
  }
`;
export const AlternativesRadioLabel = styled(Typography) `
  font-weight: bold;
  font-size: 16px;
`;
