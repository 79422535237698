import { useSelector } from 'react-redux';
import { selectAccessToken } from 'src/modules/auth/selectors';
export const useSkeduloGraphqlApiClient = () => {
    const accessToken = useSelector(selectAccessToken);
    const doPost = (body) => {
        return fetch('/skedulo/graphql/graphql', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then((response) => {
            if (!response.ok)
                throw new Error(`${response.status} - ${response.statusText}`);
            return response.json();
        });
    };
    const fetchRegions = () => {
        return doPost({
            query: `query fetchRegions($orderBy: EQLOrderByClauseRegions) {
                regions(orderBy: $orderBy) {
                  totalCount
                  edges {
                    node {
                      UID Name Timezone Description
                      Resources {
                        UID Name IsActive ResourceType
                        PrimaryRegion { UID Name Timezone }
                        ResourceTags {
                          Tag { UID Name Type }
                        }
                        ResourceShifts { UID ActualStart ActualEnd }
                      }
                    }
                  }
                }
              }`,
            variables: {
                orderBy: 'Name',
            },
        });
    };
    const updateRegion = (region) => {
        return doPost({
            query: `mutation updateRegions($region: UpdateRegions!) {
                schema {
                  updateRegions(input: $region)
                }
              }`,
            variables: {
                region: {
                    UID: region.UID,
                    Name: region.Name,
                    Description: region.Description,
                },
            },
        });
    };
    const fetchResources = (type) => {
        return doPost({
            query: `query ($filter: EQLQueryFilterResources, $orderBy: EQLOrderByClauseResources) {
                resources(filter: $filter, orderBy: $orderBy) {
                  totalCount
                  edges {
                    node {
                      UID Name Email ResourceType IsActive
                      PrimaryRegion { UID Name Timezone }
                      ResourceRegions {
                        Region { UID Name }
                      }
                      ResourceTags {
                        Tag { UID Name Type }
                      }
                    }
                  }
                }
              }`,
            variables: {
                filter: `IsActive == true AND UID IN (SELECT ResourceId FROM ResourceTags WHERE TagId IN (SELECT UID FROM Tags WHERE Name == '${type}'))`,
                orderBy: 'Name',
            },
        });
    };
    const fetchVans = () => fetchResources('Van');
    const fetchMechanics = () => fetchResources('Mechanic');
    const updateResource = (resource) => {
        return doPost({
            query: `mutation ($resource: UpdateResources!) {
                schema {
                  updateResources(input: $resource)
                }
              }`,
            variables: {
                resource: {
                    UID: resource.UID,
                    Name: resource.Name,
                },
            },
        });
    };
    return {
        fetchVans,
        fetchMechanics,
        fetchRegions,
        updateResource,
        updateRegion,
    };
};
