/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import { cvcStyle, inputStyle, } from 'src/AdminApp/containers/payments/PaymentDrawer/sharedComponents.styles';
import { TextField } from 'src/components/mui';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import CONFIG from 'src/config';
import { cardSvgByType } from '../utils/cardSvgByType';
import { ExpField, Spreedly } from './SpreedlyForm.styles';
const SpreedlyForm = (props) => {
    const [ready, setReady] = useState(false);
    const preventNav = (event) => {
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = ''; // eslint-disable-line
    };
    const initializeSpreedly = () => {
        const spreedlyReady = Boolean(window.Spreedly);
        const spreedlyCardInputLoaded = document.getElementById('spreedly-number');
        if (spreedlyReady && spreedlyCardInputLoaded) {
            window.Spreedly.init(CONFIG.REACT_APP_SPREEDLY_ENV_KEY, {
                numberEl: 'spreedly-number',
                cvvEl: 'spreedly-cvv',
            });
            window.Spreedly.on('ready', () => {
                window.Spreedly.setFieldType('number', 'text');
                window.Spreedly.setLabel('number', 'Card Number');
                window.Spreedly.setNumberFormat('prettyFormat');
                window.Spreedly.setStyle('number', 'width: 100%;  height:34px;');
                window.Spreedly.setStyle('number', 'font-size: 16px; color: #3f3f3f; font-weight: 400;');
                window.Spreedly.setFieldType('cvv', 'number');
                window.Spreedly.setLabel('cvv', 'CVV');
                window.Spreedly.setStyle('cvv', 'width: 100%; height: 34px;');
                window.Spreedly.setStyle('cvv', 'font-size: 16px; color: #3f3f3f; font-weight: 400;');
            });
            window.Spreedly.on('fieldEvent', (name, type, activeEl, inputProperties) => {
                const numberParent = document.getElementById('spreedly-number');
                const expEl = document.getElementById('exp');
                const hasExpiration = expEl.value;
                const fakeButtonEl = document.getElementById('fake_button');
                const realButtonEl = document.getElementById('real_button');
                const formFilledEl = document.getElementById('form_filled');
                const cvvParentEl = document.getElementById('spreedly-cvv');
                const messageEl = document.getElementById('message');
                if (name === 'number') {
                    if (type === 'focus' && numberParent) {
                        numberParent.className = 'highlighted';
                    }
                    if (type === 'tab') {
                        expEl.focus();
                    }
                    if (type === 'input' && inputProperties['validNumber']) {
                        if (messageEl) {
                            messageEl.style.display = 'none';
                        }
                        if (inputProperties['validCvv']) {
                            formFilledEl.checked = true;
                            if (hasExpiration && fakeButtonEl && realButtonEl) {
                                fakeButtonEl.style.display = 'none';
                                realButtonEl.style.display = 'inherit';
                            }
                        }
                    }
                    if (type === 'input' && inputProperties['cardType']) {
                        const cardSvg = document.createElement('img');
                        const cardType = document.getElementById('spreedly-number');
                        if (!cardType || !cardSvg)
                            return;
                        const iFrames = cardType.getElementsByTagName('iframe');
                        cardSvg.src = cardSvgByType(inputProperties['cardType']);
                        cardSvg.style.width = '20px';
                        cardSvg.style.height = '20px';
                        cardType.style.position = 'relative';
                        cardSvg.style.position = 'absolute';
                        cardSvg.style.top = '21px';
                        if (iFrames[0]) {
                            iFrames[0].style.paddingLeft = '24px';
                        }
                        if (cardType.childNodes.length > 1) {
                            cardType.replaceChild(cardSvg, cardType.childNodes[0]);
                        }
                        else {
                            cardType.insertBefore(cardSvg, cardType.childNodes[0]);
                        }
                    }
                    if (type === 'blur' &&
                        !inputProperties['validNumber'] &&
                        numberParent &&
                        fakeButtonEl &&
                        realButtonEl) {
                        numberParent.className = 'error';
                        fakeButtonEl.style.display = 'inherit';
                        realButtonEl.style.display = 'none';
                        window.Spreedly.validate();
                    }
                }
                if (name === 'cvv') {
                    if (type === 'focus' && cvvParentEl) {
                        cvvParentEl.className = 'highlighted';
                    }
                    if (type === 'input' &&
                        inputProperties['validNumber'] &&
                        inputProperties['validCvv'] &&
                        messageEl) {
                        messageEl.style.display = 'none';
                        formFilledEl.checked = true;
                        if (hasExpiration && fakeButtonEl && realButtonEl) {
                            fakeButtonEl.style.display = 'none';
                            realButtonEl.style.display = 'block';
                        }
                    }
                    if (type === 'input' &&
                        !inputProperties['validCvv'] &&
                        cvvParentEl &&
                        fakeButtonEl &&
                        realButtonEl) {
                        fakeButtonEl.style.display = 'inherit';
                        realButtonEl.style.display = 'none';
                        cvvParentEl.className = 'error';
                        window.Spreedly.validate();
                    }
                }
            });
            window.Spreedly.on('validation', (inputProperties) => {
                const fakeButtonEl = document.getElementById('fake_button');
                const realButtonEl = document.getElementById('real_button');
                if (!inputProperties['validNumber']) {
                    const messageEl = document.getElementById('message');
                    messageEl.innerHTML =
                        'The card number is invalid. Check the card details or use a different card.';
                    messageEl.style.display = 'inherit';
                    fakeButtonEl.style.display = 'inherit';
                    realButtonEl.style.display = 'none';
                    if (props.onFormValid) {
                        props.onFormValid(false);
                    }
                }
                else {
                    fakeButtonEl.style.display = 'none';
                    realButtonEl.style.display = 'inherit';
                    if (props.onFormValid) {
                        props.onFormValid(true);
                    }
                }
            });
            window.Spreedly.on('consoleError', (error) => {
                console.error(`Error from Spreedly iFrame: ${error['msg']}`);
            });
            window.Spreedly.on('errors', (errors) => {
                const messageEl = document.getElementById('message');
                messageEl.innerHTML = '';
                messageEl.style.display = 'inherit';
                for (let i = 0; i < errors.length; i += 1) {
                    messageEl.innerHTML = `${messageEl.innerHTML + errors[i]['message']}<br />`;
                }
            });
            window.Spreedly.on('paymentMethod', (token) => {
                const tokenField = document.getElementById('payment_method_token');
                tokenField.setAttribute('value', token);
            });
            setReady(true);
        }
    };
    useEffect(() => {
        initializeSpreedly();
        window.addEventListener('beforeunload', preventNav);
        return function cleanup() {
            if (window.Spreedly) {
                window.Spreedly.removeHandlers();
            }
            window.removeEventListener('beforeunload', preventNav);
        };
    }, []); // eslint-disable-line
    const onExpirationChange = (e) => {
        const fakeButtonEl = document.getElementById('fake_button');
        const realButtonEl = document.getElementById('real_button');
        const targetValue = e.target.value;
        const expEl = document.getElementById('exp');
        const formFilledEl = document.getElementById('form_filled');
        if (targetValue.length > 5) {
            e.target.value = targetValue.slice(0, 5);
        }
        else if (targetValue.length === 4 && targetValue.indexOf('/') > -1) {
            let month = targetValue.substring(0, 2);
            const year = targetValue.substring(targetValue.indexOf('/') + 1);
            if (month.indexOf('/') > -1) {
                month = `0${month.substring(0, month.length - 1)}`;
            }
            e.target.value = `${month}/${year}`;
        }
        else if (targetValue.length === 4 && targetValue.indexOf('/') === -1) {
            e.target.value = `${targetValue.substring(0, 2)}/${targetValue.substring(2)}`;
        }
        else if (targetValue.length === 5 &&
            expEl &&
            expEl.value &&
            formFilledEl &&
            formFilledEl.checked &&
            fakeButtonEl) {
            fakeButtonEl.style.display = 'none';
            realButtonEl.style.display = 'inherit';
        }
        else if (targetValue.length < 5 && fakeButtonEl) {
            fakeButtonEl.style.display = 'inherit';
            realButtonEl.style.display = 'none';
        }
    };
    const renderSpreedlyIframe = () => {
        return (<form className="Spreedly__form">
        <input type="hidden" name="payment_method_token" id="payment_method_token"/>
        <input type="hidden" name="form_filled" id="form_filled"/>

        <TextField variant="outlined" type="text" id="full_name" label="Full Name" name="full_name" classes={{ root: 'Spreedly__fullName' }} InputLabelProps={{
                shrink: true,
            }} onChange={(e) => {
                e.target.value = e.target.value
                    .split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(' ');
            }}/>
        <div className="Spreedly__ccNumber Spreedly__inputContainer">
          <div className="Spreedly__label">Card Number</div>
          <div id="spreedly-number" style={Object.assign(Object.assign({}, inputStyle), { width: '100%', marginBottom: '20px' })}/>
        </div>

        <div id="expiration">
          <ExpField variant="outlined" type="text" label="MM / YY" id="exp" name="exp" InputLabelProps={{
                shrink: true,
            }} onClick={() => {
                const expEl = document.getElementById('exp');
                expEl.focus();
            }} onChange={onExpirationChange}/>

          <div className="Spreedly__inputContainer Spreedly__inputContainer--cvc">
            <div className="Spreedly__label Spreedly__label--cvc">CVC</div>
            <div id="spreedly-cvv" style={cvcStyle}/>
          </div>
        </div>

        <div id="message"/>
      </form>);
    };
    return (<Spreedly>
      {renderSpreedlyIframe()}
      <div className="Spreedly__actions">
        <div id="fake_button">
          <FooterActionButton id="spreedly-fake-submit-button" disabled>
            Add Card
          </FooterActionButton>
        </div>

        <div id="real_button" style={{ display: 'none' }}>
          <FooterActionButton id="spreedly-submit-button" disabled={!ready} onClick={props.onTokenize}>
            Add Card
          </FooterActionButton>
        </div>
      </div>
    </Spreedly>);
};
export default SpreedlyForm;
