import styled from 'styled-components';
import { SearchRounded as SearchIcon } from 'src/components/mui/icons';
import { TextField, Autocomplete } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
export const FilterContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  padding: 4px;
  border-radius: 24px;
  border: solid 1px ${colors.GRAY_DE};

  ${theme.breakpoints.down('sm')} {
    border-radius: 0;
    border-right: none;
    border-left: none;

    &:before {
      position: absolute;
      content: ' ';
      top: -1px;
      left: -64px;
      width: 64px;
      height: calc(100% + 2px);
      border-top: solid 1px ${colors.GRAY_DE};
      border-bottom: solid 1px ${colors.GRAY_DE};
    }

    &:after {
      position: absolute;
      content: ' ';
      top: -1px;
      right: -64px;
      width: 64px;
      height: calc(100% + 2px);
      border-top: solid 1px ${colors.GRAY_DE};
      border-bottom: solid 1px ${colors.GRAY_DE};
    }
  }

  .VisitFilterInput {
    input {
      font-weight: 700;
    }
    div:before {
      display: none;
    }

    div:after {
      border: none;
    }
  }
`;
export const FilterIcon = styled(SearchIcon) `
  position: absolute;
  left: 8px;
  top: 12px;
  width: 28px;
  height: 28px;
  fill: ${colors.GRAY_DE};

  ${theme.breakpoints.down('sm')} {
    left: 0;
  }
`;
export const FilterText = styled(TextField) `
  padding: 4px 4px 4px 36px;

  ${theme.breakpoints.down('sm')} {
    padding: 4px 4px 4px 28px;
  }
`;
export const FilterAutocomplete = styled(Autocomplete) `
  .MuiAutocomplete-inputRoot {
    border: none;
    padding: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
`;
export const FilterChip = styled.span `
  margin-right: 10px;
  margin-top: 8px;
`;
export const FilterChipWrapper = styled.div `
  margin: 20px 0;
`;
