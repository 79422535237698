import styled from 'styled-components';
import { IconButton } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const FleetVehicles = styled.table `
  border-collapse: collapse;
  width: 100%;

  thead {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    background-color: ${colors.GRAY_FA};
  }

  th {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    padding: 15px 0 14px;
    color: ${colors.darkGrey};
    text-align: left;

    &:first-child {
      padding-left: 25px;
    }
  }

  td {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.3px;
    padding: 27px 0;
    border-bottom: solid 1px ${colors.lightGrey};

    &:first-child {
      padding-left: 25px;
    }
  }
`;
export const CarMakeLogo = styled.img `
  height: 40px;
  width: 40px;
  margin-right: 31px;
`;
export const MoreIconButton = styled(IconButton) `
  color: ${colors.GRAY_E1};
`;
