var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
const useShiftsFilters = ({ onClear, onSearch }) => {
    const [submitting, setSubmitting] = useState(false);
    const [queryString, setQueryString] = useState();
    const clearFilter = () => {
        onClear();
    };
    const handleReset = () => {
        setQueryString('');
    };
    const handleSubmit = (args) => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        const values = Object.assign(Object.assign({}, args), { query: queryString });
        try {
            yield onSearch(values);
        }
        catch (e) {
            window.console.error(e);
        }
        finally {
            setSubmitting(false);
        }
    });
    const handleQueryChange = (event) => setQueryString(event.target.value);
    return {
        submitting,
        setSubmitting,
        queryString,
        setQueryString,
        clearFilter,
        handleReset,
        handleSubmit,
        handleQueryChange,
    };
};
export default useShiftsFilters;
