import React from 'react';
import { Box, Grid } from 'src/components/mui';
import { ExpandMore } from 'src/components/mui/icons';
import cn from 'classnames';
import { NavLinksContainer, NavLinkSection, Accordion, AccordionSummary, AccordionItemsContainer, NavLink, } from './Menu.styles';
import useMenu from './useMenu';
import { getIsSelected, getItemClassName } from './Menu.helpers';
const Item = ({ path, text, accordionItems, isNested, isFirstItem, }) => {
    const isSelected = getIsSelected(path, location.pathname);
    const titleClass = getItemClassName(text);
    const accordionClasses = cn('MainMenuSummary', {
        'MenuItem--nested': isNested,
        'MenuItem--first': isFirstItem,
    });
    return accordionItems ? (<Accordion>
      <AccordionSummary className={accordionClasses} expandIcon={<ExpandMore fontSize="small" className="expand-icon" color="disabled"/>}>
        <Box className={`item ${titleClass} accordion ${isNested ? 'accordion-item' : ''}`}>
          <span>{text}</span>
        </Box>
      </AccordionSummary>

      <AccordionItemsContainer container direction="column">
        {accordionItems === null || accordionItems === void 0 ? void 0 : accordionItems.map((accordionItem, index) => {
            const isSubItemSelected = location.pathname.startsWith(accordionItem.path);
            const subAccordionItems = accordionItem === null || accordionItem === void 0 ? void 0 : accordionItem.accordionItems;
            if (subAccordionItems) {
                return <Item key={index} {...accordionItem} isNested/>;
            }
            const navLinkClasses = cn('item', 'accordion-item', getItemClassName(accordionItem.text), {
                active: isSubItemSelected,
            });
            return (<NavLink key={index} to={accordionItem.path} className={navLinkClasses}>
              <span>{accordionItem.text}</span>
            </NavLink>);
        })}
      </AccordionItemsContainer>
    </Accordion>) : (<NavLink to={path} className={`item ${titleClass} ${isSelected ? 'active' : ''}`}>
      <span>{text}</span>
    </NavLink>);
};
const Menu = () => {
    const { links } = useMenu();
    return (<NavLinksContainer>
      {links.map((item) => {
            return (<NavLinkSection container direction="column" key={item.parent}>
            <Grid item xs>
              <div className="parent-section">
                {item.icon}
                <span>{item.parent}</span>
              </div>
            </Grid>
            <Grid container direction="column" className="sub-section">
              {item.items.map((item, index) => {
                    return (<Item key={index} {...item} isNested={false} isFirstItem={item.isFirstItem}/>);
                })}
            </Grid>
          </NavLinkSection>);
        })}
    </NavLinksContainer>);
};
export default Menu;
