var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import ReactPlacesAutocomplete from 'react-places-autocomplete';
import { debounce, isObject } from 'lodash';
import { Autocomplete, Grid, Typography, TextField, Box, } from 'src/components/mui';
import { LocationOn as LocationOnIcon } from 'src/components/mui/icons';
import { addressObjectToString } from 'src/clients/mapboxClient';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartDistributorsAddressSearchResults } from 'src/PopsApp/modules/vendors/selectors';
import { resetPartDistributorLocationsByAddressSearchResults, searchPartDistributorLocationsByAddress, } from 'src/PopsApp/modules/vendors/actions';
const PlacesAutocompleteWrapper = styled.div `
  position: relative;
  width: 100%;
`;
const PlacesAndPartDistributorLocationsAutoComplete = ({ partDistributorId, address, onSelect, disabled, disabledReason, variant, label, autoFocus, inputProps, inputLabelProps, alignItems, classes, suggestionsContainerClassName, iconCellClassName, searchOptions, textFieldParams, maxPartDistributorLocationResults = 4, isHub = false, }) => {
    const dispatch = useDispatch();
    const [innerAddress, setInnerAddress] = useState('');
    const [distributorLocationsLoading, setDistributorLocationsLoading] = useState(false);
    const partDistributorLocationsSearchResults = useSelector(selectPartDistributorsAddressSearchResults);
    const [partDistributorLocations, setPartDistributorLocations] = useState([]);
    const getAddressFromSuggestion = (suggestion) => {
        const addressLine = suggestion.partDistributorLocation
            ? `${suggestion.street1}${suggestion.street2 ? `, ${suggestion.street2}` : ''}`
            : suggestion.formattedSuggestion.mainText;
        const restAddress = suggestion.partDistributorLocation
            ? `${suggestion.city}, ${suggestion.state} ${suggestion.zip}`
            : suggestion.formattedSuggestion.secondaryText;
        return { addressLine, restAddress };
    };
    const getOptionLabel = (suggestion) => {
        const { addressLine, restAddress } = getAddressFromSuggestion(suggestion);
        return `${addressLine}, ${restAddress}`;
    };
    const clearDistributorResults = () => {
        dispatch(resetPartDistributorLocationsByAddressSearchResults());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(debounce((partialAddress, dealershipName) => {
        if (!partDistributorId)
            return;
        if (!partialAddress && !isHub) {
            clearDistributorResults();
            return;
        }
        setDistributorLocationsLoading(true);
        dispatch(searchPartDistributorLocationsByAddress({
            partialAddress: partialAddress !== null && partialAddress !== void 0 ? partialAddress : '',
            dealershipName,
            partDistributorId,
        })).then(() => {
            setDistributorLocationsLoading(false);
        });
    }, 500), [partDistributorId]);
    useEffect(() => {
        clearDistributorResults();
        debouncedSearch(null, null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partDistributorId]);
    const handleInputChange = (event) => {
        const queryString = event.target.value;
        let queryAddress;
        let queryDealershipName = null;
        if (queryString === null || queryString === void 0 ? void 0 : queryString.includes('&')) {
            const queryStringParams = queryString.split('&');
            const [street, dealershipName] = queryStringParams;
            queryAddress = street.trim();
            queryDealershipName = dealershipName.trim();
        }
        else {
            queryAddress = queryString === null || queryString === void 0 ? void 0 : queryString.trim();
        }
        debouncedSearch(queryAddress, queryDealershipName);
    };
    const handleSelect = (address, placeId, suggestion, bubbleEvent = true) => __awaiter(void 0, void 0, void 0, function* () {
        const addressStr = getOptionLabel(suggestion);
        if (!addressStr || !addressStr.length)
            return;
        setInnerAddress(addressStr);
        if (bubbleEvent) {
            onSelect(Object.assign(Object.assign({}, suggestion), { addressStr }));
        }
    });
    const handleChange = (address) => {
        const addr = address.trimStart();
        setInnerAddress(addr);
        if (!addr || !addr.length) {
            setInnerAddress('');
            onSelect(null);
        }
    };
    useEffect(() => {
        if (partDistributorLocationsSearchResults !== undefined) {
            setPartDistributorLocations(partDistributorLocationsSearchResults
                .slice(0, isHub ? undefined : maxPartDistributorLocationResults)
                .map((pdl) => {
                return Object.assign({ partDistributorLocation: true }, pdl);
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partDistributorLocationsSearchResults]);
    useEffect(() => {
        const addressString = isObject(address)
            ? addressObjectToString(address)
            : address;
        setInnerAddress(addressString || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);
    return (<PlacesAutocompleteWrapper>
      <ReactPlacesAutocomplete value={innerAddress} onChange={handleChange} onSelect={handleSelect} onError={() => { }} searchOptions={searchOptions} debounce={500}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (<Autocomplete style={{ width: '100%' }} getOptionLabel={getOptionLabel} filterOptions={(x) => x} inputValue={innerAddress} options={[
                ...partDistributorLocations,
                ...(isHub ? [] : suggestions),
            ]} loading={loading || distributorLocationsLoading} groupBy={(option) => option.partDistributorLocation
                ? 'Distributor Results'
                : 'Google Results'} openOnFocus autoComplete includeInputInList freeSolo disabled={disabled} clearIcon={null} classes={classes} onBlur={clearDistributorResults} renderInput={(params) => {
                const expandedParams = Object.assign(Object.assign(Object.assign({}, params), getInputProps()), { onChange: (event) => {
                        getInputProps().onChange(event);
                        handleInputChange(event);
                    }, InputProps: Object.assign(Object.assign({}, params.InputProps), inputProps), InputLabelProps: Object.assign(Object.assign({}, params.InputLabelProps), inputLabelProps) });
                return (<TextField {...expandedParams} helperText={disabledReason} autoFocus={autoFocus} label={label} fullWidth variant={variant !== null && variant !== void 0 ? variant : 'standard'} disabled={disabled} placeholder="i.e. My street&My dealer name" {...textFieldParams}/>);
            }} renderOption={(props, suggestion) => {
                const { addressLine, restAddress } = getAddressFromSuggestion(suggestion);
                return (<Box component="li" {...props}>
                  <Grid className={suggestionsContainerClassName} container style={{ alignItems: alignItems !== null && alignItems !== void 0 ? alignItems : 'center' }} {...getSuggestionItemProps(suggestion)}>
                    <Grid item className={iconCellClassName}>
                      <LocationOnIcon />
                    </Grid>
                    <Grid item xs>
                      <span>{addressLine}</span>
                      <Typography variant="body2" color="textSecondary">
                        {restAddress}
                      </Typography>
                      {(suggestion === null || suggestion === void 0 ? void 0 : suggestion.dealershipName) ? (<span>{suggestion === null || suggestion === void 0 ? void 0 : suggestion.dealershipName}</span>) : null}
                    </Grid>
                  </Grid>
                </Box>);
            }}/>)}
      </ReactPlacesAutocomplete>
    </PlacesAutocompleteWrapper>);
};
export default PlacesAndPartDistributorLocationsAutoComplete;
