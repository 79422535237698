import { Formik } from 'formik';
import React from 'react';
import TextField from 'src/AdminApp/components/form/TextField';
import PlacesAutocomplete from 'src/components/form/PlacesAutocomplete';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox';
import { DrawerFormRow, FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    locationAddress: Yup.object()
        .shape({ zip: Yup.string().required() })
        .shape({ timezone: Yup.string().required() })
        .required(),
});
const HubAddEditDrawer = ({ open, onClose, saveHub, selectedHub, }) => {
    var _a, _b, _c, _d;
    return (<SimpleDrawer open={open} onClose={onClose} title={selectedHub ? 'Edit Hub' : 'New Hub'}>
      <Formik id="hub-form" initialValues={{
            name: (_a = selectedHub === null || selectedHub === void 0 ? void 0 : selectedHub.name) !== null && _a !== void 0 ? _a : '',
            acceptDistributorDeliveries: (_b = selectedHub === null || selectedHub === void 0 ? void 0 : selectedHub.acceptDistributorDeliveries) !== null && _b !== void 0 ? _b : false,
            acceptCourierDeliveries: (_c = selectedHub === null || selectedHub === void 0 ? void 0 : selectedHub.acceptCourierDeliveries) !== null && _c !== void 0 ? _c : false,
            locationAddress: (_d = selectedHub === null || selectedHub === void 0 ? void 0 : selectedHub.locationAddress) !== null && _d !== void 0 ? _d : {},
        }} validationSchema={validationSchema} onSubmit={saveHub} validateOnChange validateOnMount>
        {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (<>
            <SimpleDrawerContent>
              <DrawerFormRow>
                <TextField name="name" label="Name" variant="outlined" fullWidth/>
              </DrawerFormRow>

              <DrawerFormRow>
                <PlacesAutocomplete address={values.locationAddress} onSelect={(address) => {
                setFieldValue('locationAddress', address);
            }} textFieldParams={{
                name: 'locationAddress',
                variant: 'outlined',
                label: 'Address',
            }}/>
              </DrawerFormRow>

              <DrawerFormRow>
                <LabelledCheckbox name="acceptCourierDeliveries" label="Accepts Deliveries from couriers?" checked={values.acceptCourierDeliveries} onChange={(_, value) => {
                setFieldValue('acceptCourierDeliveries', value);
            }}/>
              </DrawerFormRow>
              <DrawerFormRow>
                <LabelledCheckbox name="acceptDistributorDeliveries" label="Accepts Deliveries from distributors?" checked={values.acceptDistributorDeliveries} onChange={(_, value) => {
                setFieldValue('acceptDistributorDeliveries', value);
            }}/>
              </DrawerFormRow>
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <FooterActionButton form="hub-form" type="submit" disabled={!isValid} isSubmitting={isSubmitting} onClick={handleSubmit}>
                Save
              </FooterActionButton>
            </SimpleDrawerFooter>
          </>)}
      </Formik>
    </SimpleDrawer>);
};
export default HubAddEditDrawer;
