import React from 'react';
import { Button } from 'src/components/mui';
import AddButton from 'src/AdminApp/components/layout/AddButton';
import { SimpleModal } from 'src/components/layout';
import Indicator from 'src/components/layout/Indicator';
const AddComponent = ({ onClick, children, indicator = null, }) => (<div>
    <Button onClick={onClick} startIcon={<AddButton color="primary"/>} endIcon={indicator ? (<Indicator value={indicator} color="#ff6900" text="#fff"/>) : null}>
      {children}
    </Button>
  </div>);
const AddComponentModal = ({ open, onClose, displayLabor, onAddLabor, onAddPart, onAddFluid, numRecommendedParts = 0, }) => (<SimpleModal title="Add Component" open={open} onClose={onClose} disableEnforceFocus>
    <section style={{ marginLeft: '85px', width: '70%' }}>
      {displayLabor && (<AddComponent onClick={onAddLabor}>Add Labor</AddComponent>)}
      <AddComponent onClick={onAddPart} indicator={numRecommendedParts}>
        Add Part
      </AddComponent>
      <AddComponent onClick={onAddFluid}>Add Fluid</AddComponent>
    </section>
  </SimpleModal>);
export default AddComponentModal;
