import { Link } from 'react-router-dom';
import copyToClipboard from 'src/utils/copyToClipboard';
import { truncate } from 'lodash';
import React, { useState } from 'react';
import { formatPhone } from 'src/utils/formatter';
import { ClickAwayListener, useMediaQuery } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { theme } from 'src/styles/theme';
import { Directions as DirectionsIcon, Help as HelpIcon, FileCopy as FileCopyIcon, } from 'src/components/mui/icons';
import moment from 'moment';
import { AppointmentInfo, AppointmentLabel, RefNumsContainer, ServicesList, } from 'src/TechApp/components/AppointmentTask/AppointmentTask.styles';
import { CopyButton, RefLabel, TimeWindow, } from 'src/TechApp/components/AppointmentList/AppointmentList.styles';
import { FleetVisitInfo } from 'src/TechApp/components/VisitTask/VisitTask.styles';
import { renderEnvironment, openMaps } from 'src/TechApp/utils';
import { TIME_12H_FORMAT } from 'src/timeConstants';
const getPhoneFormatted = (phone) => phone && formatPhone(phone);
export const renderTimeWindow = (start, end, corrupted) => {
    return corrupted ? (<TimeWindow>Time Not Available</TimeWindow>) : (<TimeWindow>
      {moment(start).format(TIME_12H_FORMAT)}&nbsp;-&nbsp;
      {moment(end).format(TIME_12H_FORMAT)}
    </TimeWindow>);
};
export const renderRefNums = ({ referenceNum, environment, corrupted, baseUri = '/admin/repairs', isVisit = false, }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    return (<RefNumsContainer>
      <RefLabel>Ref #: </RefLabel>
      <Link to={`${baseUri}/${referenceNum}`}>
        {referenceNum} {renderEnvironment(environment)}
      </Link>

      <CopyButton variant="text" onClick={() => {
            copyToClipboard(`${window.location.protocol}//${window.location.host}${baseUri}/${referenceNum}`);
        }}>
        <FileCopyIcon />
      </CopyButton>
      {corrupted && !isVisit && (<ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip title="This appointment has bad or invalid data. Please contact an administrator to resolve.">
              <HelpIcon onClick={handleTooltipOpen} style={{ color: 'red' }}/>
            </Tooltip>
          </div>
        </ClickAwayListener>)}
    </RefNumsContainer>);
};
export const renderCustomer = (customer) => (<>
    <AppointmentLabel>Customer</AppointmentLabel>
    {customer && (<>
        <AppointmentInfo>{customer.name}</AppointmentInfo>
        {customer.phone && (<CopyButton onClick={() => {
                copyToClipboard(getPhoneFormatted(customer.phone));
            }}>
            {getPhoneFormatted(customer.phone)}
            <FileCopyIcon />
          </CopyButton>)}
      </>)}
  </>);
export const renderServices = (services) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (<>
      <AppointmentLabel>Services</AppointmentLabel>
      <ServicesList>
        {services === null || services === void 0 ? void 0 : services.slice(0, 3).map((service) => (<li key={service}>
            {truncate(service, {
                length: isMobile ? 45 : 50,
                omission: '...',
            })}
          </li>))}
        {(services === null || services === void 0 ? void 0 : services.length) > 3 && <li> + {services.length - 3} more</li>}
      </ServicesList>
    </>);
};
export const renderServiceAddress = (address, location, title = 'Service Address') => (<>
    <AppointmentLabel>{title}</AppointmentLabel>
    {address && (<FleetVisitInfo className="visitAddress">
        <CopyButton onClick={() => {
            openMaps(address, location);
        }}>
          {address.street}
          <br />
          {address.city}, {address.state} {address.zip}
          <DirectionsIcon />
        </CopyButton>
      </FleetVisitInfo>)}
  </>);
export const renderVisitCustomer = (customer) => (<>
    <AppointmentLabel>Customer</AppointmentLabel>
    <FleetVisitInfo>{customer === null || customer === void 0 ? void 0 : customer.name}</FleetVisitInfo>
  </>);
