import { Grid, TextField, Autocomplete } from 'src/components/mui';
import React from 'react';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox';
import { DrawerFormRow, SimpleDrawer } from 'src/components/SimpleDrawer';
import useMoveReturnedItemDrawer from 'src/PopsApp/pages/Inventory/components/useMoveReturnedItemDrawer';
import { colors, FONT_FAMILY } from 'src/styles/theme';
import styled from 'styled-components';
const Details = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  color: ${colors.darkGrey};
`;
const LinkItem = styled.div `
  padding: 4px 0;

  label span:last-child {
    width: 100%;
  }
`;
const MoveReturnedItemDrawer = ({ open, onClose, returnedItems = [], }) => {
    var _a;
    const { handleClose, moveReturnItem, selectedLocation, locationSearchResults, handleLocationSelected, selectedItems, handleSelectedItem, } = useMoveReturnedItemDrawer({ onClose, returnedItems, open });
    const getLabel = (returnedItem) => (<Grid container key={returnedItem.id} style={{ width: '100%' }}>
      <Grid item xs={10}>
        <Details>
          PO# {returnedItem.poNumber} • Part {returnedItem.partName} • Part#{' '}
          {returnedItem.partNumber}
        </Details>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'right' }}>
        <Details>Qty: {returnedItem.quantity}</Details>
      </Grid>
    </Grid>);
    return (<SimpleDrawer open={open} onClose={() => {
            handleClose();
        }} title="Move" submitText="Move" footerActionButtonProps={{
            onClick: moveReturnItem,
            disabled: !selectedLocation || selectedItems.length === 0,
        }} size="wide" simpleMode>
      <DrawerFormRow>
        {returnedItems.length > 1 &&
            returnedItems.map((returnedItem) => {
                return (<LinkItem key={returnedItem.id}>
                <LabelledCheckbox checked={selectedItems.includes(returnedItem)} style={{ width: '100%' }} label={getLabel(returnedItem)} checkboxProps={{
                        onChange: () => {
                            handleSelectedItem(returnedItem);
                        },
                    }}/>
              </LinkItem>);
            })}

        {returnedItems.length === 1 && getLabel(returnedItems[0])}
      </DrawerFormRow>

      <DrawerFormRow>
        <Autocomplete id="locations-select" clearOnBlur={false} options={(_a = locationSearchResults.filter((result) => {
            if ((returnedItems === null || returnedItems === void 0 ? void 0 : returnedItems.length) > 0) {
                return result.id !== returnedItems[0].locationId;
            }
            return true;
        })) !== null && _a !== void 0 ? _a : []} getOptionLabel={(option) => `${option.name} - ${option.type.replace(/_/g, ' ')}`} onChange={handleLocationSelected} renderInput={(params) => (<TextField {...params} variant="outlined" placeholder="Search by Hub or Van" label="Location Search"/>)}/>
      </DrawerFormRow>
    </SimpleDrawer>);
};
export default MoveReturnedItemDrawer;
