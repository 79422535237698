import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import { createDefaultApiActionCreators } from 'src/utils/createActions';
import createAction from 'src/modules/api/utils/createAction';
export const ActionCreators = {
    GET_PARTNERS_API: createDefaultApiActionCreators('GET_PARTNERS_API'),
    GET_API_KEY_BY_ID: createDefaultApiActionCreators('GET_API_KEY_BY_ID'),
    ADD_PARTNER_API: createDefaultApiActionCreators('ADD_PARTNER_API'),
    UPDATE_PARTNER_API_KEY: createDefaultApiActionCreators('UPDATE_PARTNER_API_KEY'),
    GET_MARKETING_SOURCES: createDefaultApiActionCreators('GET_MARKETING_SOURCES'),
    SEARCH_PARTNERS_BY_NAME: createDefaultApiActionCreators('SEARCH_PARTNERS_BY_NAME'),
};
export const setPartnerAPIKeySort = createAction('SET_PARTNER_API_KEY_SORT');
export const setSelectedPartner = createAction('SET_SELECTED_PARTNERS');
export const setSelectedMarketingSource = createAction('SET_SELECTED_MARKETING_SOURCE');
export const setCheckedDefaultAPIKey = createAction('SET_CHECKED_DEFAULT_API_KEY');
export const setAPIKeyDescription = createAction('SET_API_KEY_DESCRIPTION');
export const setAPIKeyValidFrom = createAction('SET_API_KEY_VALID_FROM');
export const setAPIKeyValidTo = createAction('SET_API_KEY_VALID_TO');
export const setAPIKeyAccessLevel = createAction('SET_API_KEY_ACCESS_LEVEL');
export const resetAddAPIKey = createAction('RESET_ADD_API_KEY');
export const getPartnersAPIDashboard = (params) => {
    const paramsStr = Object.keys(params).reduce((str, key, index) => {
        return `${str}${index ? '&' : '?'}${key}=${params[key]}`;
    }, '');
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_PARTNERS_API),
        endpoint: `/dev-api/admin/api-keys${paramsStr}`,
        method: 'GET',
    });
};
export const getPartnerAPIKeyById = (id) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_API_KEY_BY_ID),
    endpoint: `/dev-api/admin/api-keys/${id}`,
    method: 'GET',
});
export const addAPIKey = ({ body }) => createAPIAction({
    types: fillApiTypes(ActionCreators.ADD_PARTNER_API),
    endpoint: '/dev-api/admin/api-keys',
    body,
    method: 'POST',
});
export const updateAPIKey = ({ id, body }) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_PARTNER_API_KEY),
    endpoint: `/dev-api/admin/api-keys/${id}`,
    body,
    method: 'PUT',
});
