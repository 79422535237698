import React from 'react';
import { Button, Grid } from 'src/components/mui';
import { Info as InfoIcon } from 'src/components/mui/icons';
import CurrencyLabel from 'src/components/CurrencyLabel';
import SimpleChip, { SimpleChips } from 'src/components/SimpleChip';
import { Tooltip } from 'src/components/tooltips';
import useAdminUser from 'src/hooks/useAdminUser';
import { colors } from 'src/styles/theme';
import { formatTimestamp } from 'src/utils/formatter';
import PartFluidFitmentsDisplay from 'src/AdminApp/components/repairs/statement/Elements/PartFluidFitmentsDisplay';
import PartStatusChip, { PartPickupChip, } from 'src/AdminApp/components/repairs/statement/Elements/PartStatusChip';
import { CalculatedTotalCost, Dots, PartSecondaryInfo, StyledEditIcon, Title, } from 'src/AdminApp/components/repairs/statement/styledComponents';
import PartAdditionalInfoOptions from 'src/AdminApp/components/repairs/statement/Repairs/Drawers/EditPartDrawer/PartAdditionalInfoOptions';
import useIsMobile from 'src/hooks/useIsMobile';
import { getVendorInfo, statusesWithoutInfo } from './utils';
const Part = ({ part, onEdit, canEdit, status = '', jobPriority = -1, getJobPriority = () => { }, displayTooltip = false, requestPartPickups, onOpenAdditionalInfoPart, }) => {
    var _a, _b;
    const isMobile = useIsMobile();
    const partFitments = (_a = part.fitments) !== null && _a !== void 0 ? _a : [];
    const isPartOrdered = part.status === 'ORDERED' ||
        part.status === 'PICKED_UP' ||
        part.status === 'IN_STOCK' ||
        part.status === 'PARTIALLY_ORDERED' ||
        part.status === 'SOLD';
    const partPickups = (requestPartPickups || []).filter((item) => item.items.some((component) => component.componentId === part.id));
    const noInfoStatus = statusesWithoutInfo.includes(part.status);
    const userInfo = useAdminUser({
        user: part.orderedBy,
    });
    const vendorInfo = getVendorInfo(part.componentPartDistributor, part.notes);
    const tooltipText = () => {
        if (isPartOrdered) {
            return `Ordered: ${formatTimestamp(part.partOrderedAt)} • ${userInfo} • ${part.orderMethod} ${vendorInfo}`;
        }
        if (displayTooltip &&
            (part.status === 'PENDING' ||
                part.status === 'WRONG_PART' ||
                part.status === 'NOT_PICKED_UP')) {
            return `Queue position: ${jobPriority < 1 || jobPriority > 20 ? '20+' : jobPriority}`;
        }
        return '';
    };
    const additionalInfoTooltip = (part === null || part === void 0 ? void 0 : part.additionalInfo)
        ? Object.entries(part === null || part === void 0 ? void 0 : part.additionalInfo).map((entry) => `${PartAdditionalInfoOptions.display(entry[0])}: ${entry[1]}`)
        : [];
    return (<div>
      <Grid container>
        <Title>
          {part.name}{' '}
          {partFitments.length === 0 && <span>{part.partNumber}</span>}
        </Title>
        {(part === null || part === void 0 ? void 0 : part.additionalInfo) &&
            Object.entries(part === null || part === void 0 ? void 0 : part.additionalInfo).length > 0 && (<Tooltip placement="top" title={<div style={{ whiteSpace: 'pre-line' }}>
                  Additional Info: <br />
                  <br />
                  {additionalInfoTooltip === null || additionalInfoTooltip === void 0 ? void 0 : additionalInfoTooltip.map((field, idx) => idx < 5 && (<>
                          <span>{field}</span>
                          <br />
                        </>))}
                  {(additionalInfoTooltip === null || additionalInfoTooltip === void 0 ? void 0 : additionalInfoTooltip.length) &&
                    (additionalInfoTooltip === null || additionalInfoTooltip === void 0 ? void 0 : additionalInfoTooltip.length) >= 5 &&
                    onOpenAdditionalInfoPart &&
                    canEdit && (<Button variant="text" onClick={() => onOpenAdditionalInfoPart(part)}>
                        See More
                      </Button>)}
                </div>}>
              <InfoIcon style={{
                color: colors.blue,
                width: '20px',
                height: '20px',
                margin: '0 2px',
            }}/>
            </Tooltip>)}
        {onEdit && canEdit && (<StyledEditIcon onClick={() => (onEdit ? onEdit(part) : undefined)}/>)}
        <Dots />
        <CalculatedTotalCost values={part.totalPrice}/>
      </Grid>
      {partFitments.length === 0 && (<PartSecondaryInfo>
          {part.quantity}x @ <CurrencyLabel>{part.price}</CurrencyLabel> each
        </PartSecondaryInfo>)}

      {partFitments.length === 0 && (<Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{(_b = tooltipText()) !== null && _b !== void 0 ? _b : ''}</div>} placement={isMobile ? 'bottom' : 'left'} disabled={noInfoStatus}>
          <div style={{ display: 'inline-block' }} onMouseEnter={isPartOrdered || noInfoStatus ? () => { } : () => getJobPriority()}>
            <PartStatusChip status={status}/>
          </div>
        </Tooltip>)}
      <SimpleChips>
        {partFitments.length === 0 &&
            partPickups.map((ppu) => (<PartPickupChip key={ppu.id} referenceNum={ppu.referenceNum}/>))}
        {partFitments.length > 0 && (<SimpleChip label="MULTIPLE FITMENT" $bgColor={colors.newCarBlue}/>)}
      </SimpleChips>

      <PartFluidFitmentsDisplay fitments={partFitments} onEdit={(fitment) => onEdit ? onEdit(fitment, true) : undefined} canEdit={canEdit} part={part} requestPartPickups={requestPartPickups}/>
    </div>);
};
export default Part;
