var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { selectFleetById } from 'src/AdminApp/modules/fleets/selectors';
import { createFleetRules } from 'src/PricingApp/modules/repairs/actions';
import { selectComponentsByRepairId, selectConciergeRulesByRepairId, selectRulesByFleetId, } from 'src/PricingApp/modules/repairs/selectors';
const useNewRuleDrawer = ({ fleetId, repairId, repairName, onSubmit, onClose, }) => {
    var _a, _b, _c;
    const dispatch = useDispatch();
    const fleetRules = useSelector(selectRulesByFleetId(fleetId));
    const repairRules = (_b = (_a = fleetRules === null || fleetRules === void 0 ? void 0 : fleetRules.products) === null || _a === void 0 ? void 0 : _a.filter((p) => p.id === repairId)[0]) === null || _b === void 0 ? void 0 : _b.rules;
    const components = (_c = useSelector(selectComponentsByRepairId(repairId))) === null || _c === void 0 ? void 0 : _c.filter((component) => component === null || component === void 0 ? void 0 : component.component).map((c) => {
        var _a, _b;
        const matchingExistingRule = repairRules === null || repairRules === void 0 ? void 0 : repairRules.find((r) => r.repairComponentId === c.id);
        if ((matchingExistingRule === null || matchingExistingRule === void 0 ? void 0 : matchingExistingRule.length) > 0) {
            c.quantity = (_a = matchingExistingRule[0]) === null || _a === void 0 ? void 0 : _a.maxQuantity;
            c.price = (_b = matchingExistingRule[0]) === null || _b === void 0 ? void 0 : _b.maxPrice;
        }
        return c;
    });
    const conciergeRules = useSelector(selectConciergeRulesByRepairId(repairId));
    const fleet = useSelector(selectFleetById(fleetId));
    const getTotalCost = (values) => {
        var _a;
        return (_a = values === null || values === void 0 ? void 0 : values.filter((v) => v.price && v.quantity)) === null || _a === void 0 ? void 0 : _a.reduce((acc, val) => acc + val.price * val.quantity, 0);
    };
    const submitNewRule = (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
        var _d;
        const totalCost = getTotalCost(values);
        const individualRules = (_d = values === null || values === void 0 ? void 0 : values.filter((v) => v.quantity && v.price)) === null || _d === void 0 ? void 0 : _d.map((value) => {
            var _a;
            return {
                name: (_a = value === null || value === void 0 ? void 0 : value.component) === null || _a === void 0 ? void 0 : _a.name,
                minPrice: value === null || value === void 0 ? void 0 : value.price,
                maxPrice: value === null || value === void 0 ? void 0 : value.price,
                minQuantity: value === null || value === void 0 ? void 0 : value.quantity,
                maxQuantity: value === null || value === void 0 ? void 0 : value.quantity,
                repairName,
                cost: value.minCost != null && value.maxCost != null
                    ? (value.minCost + value.maxCost) / 2
                    : value.price / 2,
                source: 'Tools',
            };
        });
        const newRule = {
            criteria: {
                fleetId,
                fleetName: fleet.name,
                yearFrom: 1950,
                yearTo: 9999,
            },
            products: [
                {
                    id: values[0].repairId,
                    totalCost,
                    rules: individualRules,
                },
            ],
            ignoreRecycleRule: false,
        };
        yield dispatch(createFleetRules(newRule));
        onSubmit();
        setSubmitting(false);
        onClose();
    });
    return {
        components,
        conciergeRules,
        submitNewRule,
        getTotalCost,
    };
};
export default useNewRuleDrawer;
