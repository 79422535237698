import React from 'react';
import { Button, Grid, useMediaQuery } from 'src/components/mui';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { theme } from 'src/styles/theme';
import { renderRefNums, renderServiceAddress, renderVisitCustomer, } from 'src/TechApp/components/TaskParts';
import { AppointmentLabel } from 'src/TechApp/components/AppointmentTask/AppointmentTask.styles';
import { FleetVisitSection, FleetVisitContainer, FleetVisitInfo, ViewDetailsContainer, FleetAction, } from 'src/TechApp/components/VisitTask/VisitTask.styles';
import { Tag, TimeWindow, } from 'src/TechApp/components/AppointmentList/AppointmentList.styles';
import { DATE_TIME_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
import { renderTimeWindow } from '../TaskParts';
const VisitTask = ({ address, customer, environment, startLocal, endLocal, duration, location, referenceNum, }) => {
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const start = moment(startLocal, DATE_TIME_FORMAT);
    const end = moment(endLocal, DATE_TIME_FORMAT);
    return isMobile ? (<FleetVisitContainer>
      <FleetVisitSection container item>
        <Grid item xs={12}>
          {renderTimeWindow(startLocal, endLocal)}
          <Tag slugType="visit">Visit</Tag>
        </Grid>
        <Grid item>
          {renderRefNums({
            referenceNum,
            environment,
            corrupted: undefined,
            baseUri: '/admin/visits',
            isVisit: true,
        })}
        </Grid>
      </FleetVisitSection>
      <FleetVisitSection container item>
        <Grid item xs={12}>
          {renderServiceAddress(address, location)}
        </Grid>
        <Grid item xs={12}>
          {renderVisitCustomer(customer)}
        </Grid>
      </FleetVisitSection>

      <FleetVisitSection>
        <AppointmentLabel>Duration</AppointmentLabel>
        <FleetVisitInfo>{duration}h</FleetVisitInfo>
      </FleetVisitSection>

      <ViewDetailsContainer>
        <Button variant="contained" color="primary" fullWidth={isMobile} onClick={() => {
            history.push(`/admin/visits/${referenceNum}`);
        }}>
          View Details
        </Button>
      </ViewDetailsContainer>
    </FleetVisitContainer>) : (<tr>
      <td>
        <TimeWindow>
          {start.format(TIME_12H_FORMAT)}&nbsp;-&nbsp;
          {end.format(TIME_12H_FORMAT)}
        </TimeWindow>

        {renderRefNums({
            referenceNum,
            environment,
            corrupted: undefined,
            baseUri: '/admin/visits',
            isVisit: true,
        })}
      </td>
      <td>
        <Tag slugType="visit">Visit</Tag>
        {renderServiceAddress(address, location)}
      </td>
      <td>{renderVisitCustomer(customer)}</td>
      <td className="duration">
        <AppointmentLabel>Duration</AppointmentLabel>
        <FleetVisitInfo>{duration}h</FleetVisitInfo>
      </td>
      <td>&nbsp;</td>
      <td className="taskActions">
        <FleetAction variant="contained" color="primary" fullWidth onClick={() => {
            history.push(`/admin/visits/${referenceNum}`);
        }}>
          View Details
        </FleetAction>
      </td>
    </tr>);
};
export default VisitTask;
