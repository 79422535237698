import { FilterList } from 'src/components/mui/icons';
import React, { useState } from 'react';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import { colors, FONT_FAMILY } from 'src/styles/theme';
import styled from 'styled-components';
const DrawerText = styled.div `
  display: flex;
  align-items: center;
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.26px;
  color: ${colors.primary};
`;
const DEFAULT_TEXT = 'Filter & Sort';
const FiltersDrawer = () => {
    const [open, setOpen] = useState(false);
    return (<>
      <DrawerText onClick={() => setOpen(true)}>
        <FilterList fontSize="small" style={{
            marginRight: '6px',
        }}/>{' '}
        {DEFAULT_TEXT}
      </DrawerText>
      <SimpleDrawer open={open} onClose={() => setOpen(false)} title={DEFAULT_TEXT}/>
    </>);
};
export default FiltersDrawer;
