var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'src/components/SimpleToast';
import { selectItemById } from 'src/PricingApp/modules/utils/selectors';
import { selectAuthorizedPricingAdmin } from 'src/modules/auth/selectors';
import { selectResponsiblePartiesFromType, selectResponsiblePartyTypesNotDefaultPrimary, } from 'src/AdminApp/modules/requests/selectors';
import { selectDefaultResponsiblePartyInfo, selectRepairs, } from 'src/PricingApp/modules/repairs/selectors';
import { clearMeasurements, deleteDefaultResponsiblePartyForProduct, getDefaultResponsiblePartyInfo, getMeasurementsByRepair, getRepair, saveDefaultResponsiblePartyForProduct, saveOrUpdateRepair, searchBaseProducts, updateDefaultResponsiblePartyForProduct, } from 'src/PricingApp/modules/repairs/actions';
import { getSkillsByFilter } from 'src/AdminApp/modules/skills/actions';
import { getResponsiblePartiesByType, getResponsiblePartyTypes, } from 'src/AdminApp/modules/requests/actions';
import { ActionCreators, getProductFleetSearch, } from 'src/AdminApp/modules/fleets/actions';
import { getComponents } from 'src/PricingApp/modules/components/actions';
export const DEFAULT_VALUES = {
    repair: {
        id: '',
        name: '',
        status: '',
        repairType: '',
        baseProduct: {},
        mappedFleets: [],
        skills: [],
        fmcRepairMappings: [],
    },
    productDetails: {
        pricingRuleExempt: '',
        searchLevels: [],
        repairLevel: '',
        defaultReservedTime: '',
        overrideReservedTime: '',
        requiresApproval: false,
        preventLaborEditing: false,
    },
    category: {
        name: '',
    },
    conciergeRules: {
        force: false,
        minPrice: '',
        maxPrice: '',
    },
    catalogDetails: [
        {
            catalogName: 'Motor',
            externalId: '',
        },
    ],
    repairComponents: [],
    dynamicComponentRules: [],
    aftermarketPartDiscount: {
        amDiscountPercent1: '',
        amDiscountPercent2: '',
    },
};
const useAddRepairForm = ({ repairId }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const repair = repairId
        ? useSelector(selectItemById(repairId, selectRepairs))
        : null;
    const isPricingAdmin = useSelector(selectAuthorizedPricingAdmin);
    const responsiblePartyTypes = useSelector(selectResponsiblePartyTypesNotDefaultPrimary);
    const responsibleParties = useSelector(selectResponsiblePartiesFromType);
    const responsiblePartyInfo = useSelector(selectDefaultResponsiblePartyInfo);
    const [isEditRepairComponentOpen, setIsEditRepairComponentOpen] = useState(false);
    const [editableRepairComponent, setEditableRepairComponent] = useState({});
    const [isAllFleetsMapping, setIsAllFleetsMapping] = useState(false);
    const [availableSkills, setAvailableSkills] = useState([]);
    const [showRPT, setShowRPT] = useState(false);
    const [responsiblePartyTypeId, setResponsiblePartyTypeId] = useState('');
    const [responsiblePartyId, setResponsiblePartyId] = useState('');
    const [showRptError, setShowRptError] = useState(false);
    useEffect(() => {
        if (repairId) {
            dispatch(getRepair(repairId));
            dispatch(getMeasurementsByRepair(repairId));
        }
        else {
            dispatch(clearMeasurements());
        }
        dispatch(getSkillsByFilter({})).then((response) => setAvailableSkills(response.payload));
        dispatch(getResponsiblePartyTypes());
        if (repairId) {
            dispatch(getDefaultResponsiblePartyInfo({ productId: +repairId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        var _a, _b;
        const isAllFleets = ((_a = repair === null || repair === void 0 ? void 0 : repair.repair) === null || _a === void 0 ? void 0 : _a.mappedFleets.filter((m) => m.allFleets).length) > 0;
        setIsAllFleetsMapping(isAllFleets);
        dispatch(ActionCreators.GET_PRODUCT_FLEET_SEARCH.CLEAR());
        if (!isAllFleets) {
            (_b = repair === null || repair === void 0 ? void 0 : repair.repair) === null || _b === void 0 ? void 0 : _b.mappedFleets.filter((m) => !m.allFleets && m.active === true).forEach((m) => {
                var _a;
                dispatch(getProductFleetSearch(m.fleetId, (_a = repair === null || repair === void 0 ? void 0 : repair.repair) === null || _a === void 0 ? void 0 : _a.id));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repair]);
    useEffect(() => {
        if (responsiblePartyTypeId) {
            dispatch(getResponsiblePartiesByType({
                responsiblePartyTypeId,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responsiblePartyTypeId]);
    useEffect(() => {
        if (responsiblePartyInfo &&
            responsiblePartyInfo.responsiblePartyType.defaultPrimary === false) {
            setResponsiblePartyTypeId(responsiblePartyInfo.responsiblePartyType.id);
            setShowRPT(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responsiblePartyInfo]);
    useEffect(() => {
        if (responsibleParties &&
            (responsiblePartyInfo === null || responsiblePartyInfo === void 0 ? void 0 : responsiblePartyInfo.responsibleParty) &&
            responsiblePartyTypeId) {
            setResponsiblePartyId(responsiblePartyInfo.responsibleParty.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responsibleParties]);
    const handleEditRepairComponent = (repairComponent) => {
        setEditableRepairComponent(repairComponent);
        setIsEditRepairComponentOpen(true);
    };
    const handleCloseEditRepairComponent = () => {
        setEditableRepairComponent({});
        setIsEditRepairComponentOpen(false);
    };
    const handleUpdateRepairComponent = (values, setFieldValue) => (updatedRepairComponent) => {
        var _a;
        setFieldValue('repairComponents', (_a = values.repairComponents) === null || _a === void 0 ? void 0 : _a.map((rc) => {
            return (rc === null || rc === void 0 ? void 0 : rc.metaId) === (updatedRepairComponent === null || updatedRepairComponent === void 0 ? void 0 : updatedRepairComponent.metaId)
                ? updatedRepairComponent
                : rc;
        }));
    };
    const onSearchRepairComponents = (componentName) => dispatch(getComponents({ name: componentName }));
    const onSearchBaseProducts = (name) => dispatch(searchBaseProducts(name));
    const updateCatalogDetails = (repairToUpdate) => {
        const firstCatalog = repairToUpdate.catalogDetails[0];
        if (firstCatalog === null || firstCatalog === void 0 ? void 0 : firstCatalog.externalId) {
            firstCatalog.catalogName = firstCatalog.catalogName || 'Motor';
        }
        else {
            repairToUpdate.catalogDetails = [];
        }
    };
    const updateAftermarketPartDiscount = (repairToUpdate) => {
        var _a, _b;
        if (!((_a = repairToUpdate.aftermarketPartDiscount) === null || _a === void 0 ? void 0 : _a.amDiscountPercent1) &&
            !((_b = repairToUpdate.aftermarketPartDiscount) === null || _b === void 0 ? void 0 : _b.amDiscountPercent2)) {
            repairToUpdate.aftermarketPartDiscount = {
                amDiscountPercent1: '',
                amDiscountPercent2: '',
            };
        }
    };
    const removeMetaInfo = (repairToUpdate) => {
        if (repairToUpdate.repairComponents) {
            repairToUpdate.repairComponents.forEach((rc) => {
                delete rc.metaId;
            });
        }
    };
    const removeDynamicComponentRules = (repairToUpdate) => {
        if (repairToUpdate.repairComponents) {
            repairToUpdate.repairComponents.forEach((rc) => {
                delete rc.dynamicComponentRules;
            });
        }
    };
    const preprocessData = (repairToUpdate) => {
        updateCatalogDetails(repairToUpdate);
        updateAftermarketPartDiscount(repairToUpdate);
        removeMetaInfo(repairToUpdate);
        removeDynamicComponentRules(repairToUpdate);
    };
    const redirectToNewRepair = (response, repairToUpdate) => {
        var _a, _b;
        const savedRepairId = (_b = (_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.repair) === null || _b === void 0 ? void 0 : _b.id;
        if (repairToUpdate.id !== savedRepairId) {
            history.push(`/pricing/repairs/${savedRepairId}/edit`);
        }
    };
    const onSaveOrUpdateRepair = (repairToUpdate) => __awaiter(void 0, void 0, void 0, function* () {
        if (responsiblePartyTypeId.length > 0 && responsiblePartyId.length === 0) {
            setShowRptError(true);
            return false;
        }
        preprocessData(repairToUpdate);
        const response = (yield dispatch(saveOrUpdateRepair(repairToUpdate)));
        if (!response.error) {
            toast.success('Save repair success. Please reindex Re-Index Elasticsearch');
        }
        if (repairId) {
            // CREATE NEW DEFAULT RESPONSIBLE PARTY
            if (responsiblePartyTypeId.length > 0 &&
                responsiblePartyId.length > 0 &&
                !responsiblePartyInfo) {
                yield dispatch(saveDefaultResponsiblePartyForProduct({
                    productId: +repairId,
                    responsiblePartyTypeId,
                    responsiblePartyId,
                }));
            }
            else if (responsiblePartyTypeId.length > 0 &&
                responsiblePartyId.length > 0 &&
                responsiblePartyInfo.id) {
                // UPDATE DEFAULT RESPONSIBLE PARTY
                yield dispatch(updateDefaultResponsiblePartyForProduct({
                    id: responsiblePartyInfo.id,
                    productId: +repairId,
                    responsiblePartyType: { id: responsiblePartyTypeId },
                    responsibleParty: { id: responsiblePartyId },
                }));
            }
            else if (responsiblePartyTypeId.length === 0 &&
                responsiblePartyId.length === 0 &&
                responsiblePartyInfo.id) {
                // DELETE DEFAULT RESPONSIBLE PARTY
                yield dispatch(deleteDefaultResponsiblePartyForProduct({
                    id: responsiblePartyInfo.id,
                }));
            }
        }
        redirectToNewRepair(response, repairToUpdate);
        if (repairId) {
            dispatch(getRepair(repairId));
        }
        return response;
    });
    const addComponentToRepair = (setFieldValue, values, component) => {
        const newRepairComponent = {
            component,
            type: 'FIXED',
            metaId: values.repairComponents.length,
        };
        setFieldValue('repairComponents', [
            ...values.repairComponents,
            newRepairComponent,
        ]);
    };
    const addDynamicComponentToRepair = (setFieldValue, values, component) => {
        const newRepairComponent = {
            component,
            type: 'DYNAMIC',
            metaId: values.repairComponents.length,
        };
        const hasSameFixedComponent = values.repairComponents.some((rc) => {
            return rc.type === 'FIXED' && rc.component.id === component.id;
        });
        if (hasSameFixedComponent) {
            toast.error('Cannot add dynamic component for repair which has same fixed component');
            return;
        }
        setFieldValue('repairComponents', [
            ...values.repairComponents,
            newRepairComponent,
        ]);
    };
    const handleAddFleetMapping = (value, mappings) => {
        const mapping = {
            active: true,
            allFleets: false,
            fleetId: value.id,
            id: null,
        };
        return [...mappings, mapping];
    };
    const handleRemoveFleetMapping = (value, mappings) => mappings.reduce((prev, m) => {
        if (m.fleetId === value.id) {
            if (m.id)
                prev.push(Object.assign(Object.assign({}, m), { active: false }));
            return prev;
        }
        prev.push(m);
        return prev;
    }, []);
    const handleAllFleetsMappingChange = (checked, mappings) => {
        setIsAllFleetsMapping(checked);
        const allFleetsMap = mappings === null || mappings === void 0 ? void 0 : mappings.filter((m) => m.allFleets);
        if (checked) {
            dispatch(ActionCreators.GET_PRODUCT_FLEET_SEARCH.CLEAR());
            const deletedMappings = mappings === null || mappings === void 0 ? void 0 : mappings.map((m) => {
                if (!m.allFleets && !!m.id) {
                    return Object.assign(Object.assign({}, m), { active: false });
                }
                return undefined;
            }).filter(Boolean);
            if (allFleetsMap.length > 0) {
                return [...deletedMappings, Object.assign(Object.assign({}, allFleetsMap[0]), { active: true })];
            }
            return [
                ...deletedMappings,
                { active: true, allFleets: true, fleetId: null, id: null },
            ];
        }
        if (allFleetsMap.length > 0 && !!allFleetsMap[0].id) {
            return mappings.map((m) => {
                if (m.allFleets) {
                    return Object.assign(Object.assign({}, m), { active: false });
                }
                return m;
            });
        }
        return mappings.filter((m) => !m.allFleets);
    };
    return {
        repair,
        isPricingAdmin,
        responsiblePartyTypes,
        responsibleParties,
        responsiblePartyInfo,
        isEditRepairComponentOpen,
        setIsEditRepairComponentOpen,
        editableRepairComponent,
        setEditableRepairComponent,
        isAllFleetsMapping,
        setIsAllFleetsMapping,
        availableSkills,
        setAvailableSkills,
        showRPT,
        setShowRPT,
        responsiblePartyTypeId,
        setResponsiblePartyTypeId,
        responsiblePartyId,
        setResponsiblePartyId,
        showRptError,
        setShowRptError,
        handleEditRepairComponent,
        handleCloseEditRepairComponent,
        handleUpdateRepairComponent,
        onSearchRepairComponents,
        onSearchBaseProducts,
        updateCatalogDetails,
        updateAftermarketPartDiscount,
        preprocessData,
        redirectToNewRepair,
        onSaveOrUpdateRepair,
        addComponentToRepair,
        addDynamicComponentToRepair,
        handleAddFleetMapping,
        handleRemoveFleetMapping,
        handleAllFleetsMappingChange,
    };
};
export default useAddRepairForm;
