var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGetHubsFromVan from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/useGetHubsFromVan';
import { transferPartPickup } from 'src/TechApp/modules/partpickup/actions';
import { selectPartPickupOrder } from 'src/TechApp/modules/partpickup/selectors';
const usePartPickupTransfer = ({ onClose }) => {
    const dispatch = useDispatch();
    const [selectedLocation, setSelectedLocation] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const partPickupOrder = useSelector(selectPartPickupOrder);
    const van = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.resources.find((resource) => resource.category === 'VAN');
    const { hubs } = useGetHubsFromVan(van === null || van === void 0 ? void 0 : van.resourceId);
    useEffect(() => {
        if ((hubs === null || hubs === void 0 ? void 0 : hubs.length) === 1) {
            setSelectedLocation(Object.assign({}, hubs[0]));
        }
    }, [hubs]);
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if ((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.id) &&
            (partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.scheduledFor) &&
            selectedLocation) {
            setIsLoading(true);
            const result = yield dispatch(transferPartPickup(partPickupOrder.id, {
                locationId: selectedLocation.id,
            }));
            setIsLoading(false);
            if (!result.error) {
                onClose();
                window.location.reload();
            }
        }
    });
    return {
        selectedLocation,
        setSelectedLocation,
        isLoading,
        setIsLoading,
        handleSubmit,
        hubs,
    };
};
export default usePartPickupTransfer;
