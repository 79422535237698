var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CircularProgress, Grid, TextField } from 'src/components/mui';
import { CameraAlt as CameraAltIcon } from 'src/components/mui/icons';
import { Formik } from 'formik';
import { omit } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotesEntityTypes, NotesTypes, NotesUrgency, } from 'src/AdminApp/containers/requests/Notes/constants';
import { Box, BoxPhoto, ImgWrapper, Thumb, } from 'src/AdminApp/containers/vehicle/CarModule/FourCornersDrawer/FourCornersDrawer.styles';
import NotesOriginTypes from 'src/AdminApp/models/enums/NotesOriginTypes';
import { addRequestNotes } from 'src/AdminApp/modules/requestNotes/actions';
import { get_base_64 } from 'src/AdminApp/utils/imageUtils';
import DeleteIcon from 'src/components/icons/DeleteIcon';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerHeader, DrawerFormRow, } from 'src/components/SimpleDrawer';
import { ErrorButton } from 'src/styles/styled';
import { colors, theme } from 'src/styles/theme';
import styled from 'styled-components';
export const WrapperBoxPhoto = styled.label `
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: top;
  width: 50%;
`;
export const DrawerFooter = styled.div `
  height: ${({ planname }) => (planname !== null && planname !== void 0 ? planname : '').length > 0 ? '106px' : '140px'};
  background: ${colors.GRAY_F8};
  padding: 16px;
  width: 100%;
  z-index: 10000;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.down('sm')} {
    max-width: 425px;
  }
`;
const NoShowDrawer = ({ repairRequestId, open, onSubmit, }) => {
    const dispatch = useDispatch();
    const [photos, setPhotos] = useState(null);
    const addPhoto = (file) => {
        get_base_64(file).then((result) => {
            setPhotos(Object.assign(Object.assign({}, photos), { [file.name]: result }));
        });
    };
    const removePhoto = (name) => {
        const updatedPhotos = omit(photos, name);
        setPhotos(updatedPhotos);
    };
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const params = {
            entityId: repairRequestId,
            entityType: NotesEntityTypes.REPAIR_REQUEST,
            origin: NotesOriginTypes.TOOLS,
            content: values.note && values.note !== '' ? values.note : 'NA',
            repairRequestId,
            visibility: NotesTypes.INTERNAL,
            urgency: NotesUrgency.NORMAL,
            base64PhotoStrings: photos,
            type: 'ARRIVAL_NO_SHOW',
        };
        const response = yield dispatch(addRequestNotes(params));
        if (response && !response.error) {
            onSubmit();
        }
    });
    return (<SimpleDrawer open={open} onClose={() => {
            // nothing to see here
        }}>
      <>
        <SimpleDrawerHeader title="Please upload a picture of the location"/>
        <Formik initialValues={{
            component: '',
            note: '',
            type: '',
        }} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            setSubmitting(true);
            yield handleSubmit(values);
            setSubmitting(false);
        })} validateOnChange>
          {({ setFieldValue, handleSubmit, values, isSubmitting }) => {
            var _a;
            return (<>
              <SimpleDrawerContent>
                <Grid container direction="row" spacing={2}>
                  <DrawerFormRow>
                    {photos &&
                    ((_a = Object.keys(photos)) === null || _a === void 0 ? void 0 : _a.map((key) => {
                        return (<Thumb key={key}>
                            <ImgWrapper>
                              <img alt={key} src={photos[key]}/>
                            </ImgWrapper>
                            <ErrorButton disabled={false} onClick={() => removePhoto(key)}>
                              <DeleteIcon />
                              Delete photo
                            </ErrorButton>
                          </Thumb>);
                    }))}
                    <input type="file" style={{ display: 'none' }} id="upload-additional" accept="image/*" onChange={(e) => __awaiter(void 0, void 0, void 0, function* () {
                    const file = e.target.files && e.target.files[0];
                    if (!file)
                        return;
                    addPhoto(file);
                })}/>
                    <WrapperBoxPhoto htmlFor="upload-additional">
                      <BoxPhoto>
                        <Box>
                          <CameraAltIcon />
                        </Box>
                      </BoxPhoto>
                    </WrapperBoxPhoto>
                  </DrawerFormRow>
                  <DrawerFormRow item xs={12}>
                    <TextField variant="outlined" fullWidth multiline minRows={5} label="Notes" name="note" type="text" value={values.note} onChange={(e) => {
                    setFieldValue('note', e.target.value);
                }}/>
                  </DrawerFormRow>
                </Grid>
              </SimpleDrawerContent>

              <DrawerFooter>
                <FooterActionButton onClick={() => handleSubmit()} type="submit" disabled={!photos || Object.keys(photos).length === 0 || isSubmitting}>
                  {!isSubmitting ? ('Submit') : (<CircularProgress size="1.75rem"/>)}
                </FooterActionButton>
              </DrawerFooter>
            </>);
        }}
        </Formik>
      </>
    </SimpleDrawer>);
};
export default NoShowDrawer;
