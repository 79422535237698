import React from 'react';
import { VinPlateContainer, VinPlate, MakeModelContainer, NicknameLabel, } from './CarDisplay.styles';
const CarDisplay = ({ car }) => {
    return (<>
      <MakeModelContainer>{`${car.make} ${car.model}`}</MakeModelContainer>{' '}
      {(car.vin || car.plateNumber) && (<VinPlateContainer>
          {car.vin && <VinPlate>VIN: {car.vin}</VinPlate>}
          {car.plateNumber && <VinPlate>Plate: {car.plateNumber}</VinPlate>}
        </VinPlateContainer>)}
      {car.nickname && (<VinPlateContainer>
          <NicknameLabel>Nickname: {car.nickname}</NicknameLabel>
        </VinPlateContainer>)}
    </>);
};
export default CarDisplay;
