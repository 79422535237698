var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { selectFleetById, selectFleetTotalElements, } from 'src/AdminApp/modules/fleets/selectors';
import { patchUpdateFleet } from 'src/AdminApp/modules/fleets/actions';
import { FleetValidationSchema } from 'src/AdminApp/containers/fleets/utils/validations';
const useTitle = () => {
    const dispatch = useDispatch();
    const { fleetId } = useParams();
    const fleet = useSelector(selectFleetById(fleetId));
    const totalElements = useSelector(selectFleetTotalElements);
    const vehicles = totalElements > 0 ? totalElements : 'No ';
    const handleNameUpdate = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (value === fleet.name)
            return;
        try {
            yield FleetValidationSchema.validateAt('name', { name: value });
            dispatch(patchUpdateFleet(fleetId, {
                name: value,
                updateName: true,
                laborRate: fleet.laborRate === '' ? null : fleet.laborRate,
                updateLaborRate: true,
            }));
        }
        catch (e) {
            toast.error(`Validation Error: ${e.message}`);
        }
    });
    return {
        dispatch,
        fleet,
        totalElements,
        fleetId,
        vehicles,
        handleNameUpdate,
    };
};
export default useTitle;
