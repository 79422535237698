import React, { useState, useEffect } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { Grid } from 'src/components/mui';
const SnapshotDiffViewer = ({ current, previous }) => {
    const [previousData, setPreviousData] = useState();
    const [currentData, setCurrentData] = useState();
    useEffect(() => {
        setPreviousData(JSON.stringify(previous || {}, null, 2));
        setCurrentData(JSON.stringify(current, null, 2));
    }, [current, previous]);
    return (<Grid container>
      <ReactDiffViewer oldValue={previousData} newValue={currentData} splitView compareMethod={DiffMethod.LINES}/>
    </Grid>);
};
export default SnapshotDiffViewer;
