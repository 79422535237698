var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Button, Checkbox, Chip, TextField } from 'src/components/mui';
import { CheckBox, CheckBoxOutlineBlank } from 'src/components/mui/icons';
import { VehiclesDropdownFooterOption, VehiclesDropdownFooter, } from 'src/AdminApp/containers/fleets/FMC/Fmc.styles';
import { DropdownItem, ResponsiblePartyAutocomplete, ResponsiblePartyListbox, } from '../FleetResponsiblePartiesDrawer/components/FleetResponsiblePartyDropdown/FleetResponsiblePartyDropdown.styles';
import useFleetVehicleSearchSelect from './useFleetVehicleSearchSelect';
const invisibleIcon = <div style={{ display: 'none' }}/>;
const FleetVehicleSearchSelect = ({ responsibleParty, onSubmit, onClose, }) => {
    const { inputValue, options, selectedCars, allSelected, setInputValue, onUpdate, toggleSelect, setSelectedCars, } = useFleetVehicleSearchSelect({
        responsibleParty,
    });
    const customPaper = (_a) => {
        var { children } = _a, other = __rest(_a, ["children"]);
        return (<ResponsiblePartyListbox {...other}>
        {children}
        <VehiclesDropdownFooter>
          <VehiclesDropdownFooterOption>
            <Button onMouseDown={(event) => {
                event.preventDefault();
            }} onClick={() => {
                setSelectedCars(allSelected ? [] : options);
            }}>
              Select All
            </Button>
          </VehiclesDropdownFooterOption>
          <VehiclesDropdownFooterOption>
            <Button className="resetVehicles" onMouseDown={(event) => {
                event.preventDefault();
            }} onClick={() => {
                setSelectedCars([]);
                onClose();
            }}>
              Reset
            </Button>
            <Button className="applyVehicles" onMouseDown={(event) => {
                event.preventDefault();
            }} onClick={() => onSubmit(selectedCars)}>
              Apply
            </Button>
          </VehiclesDropdownFooterOption>
        </VehiclesDropdownFooter>
      </ResponsiblePartyListbox>);
    };
    return (<ResponsiblePartyAutocomplete className="fmcVehiclesDropdown" style={{ width: '100%', minHeight: '25px' }} open multiple inputValue={inputValue} value={selectedCars} options={options} disableCloseOnSelect onInputChange={(event, newInputValue, reason) => reason === 'input' && setInputValue(newInputValue)} onChange={(e, newValue) => {
            onUpdate(newValue);
        }} getOptionLabel={(option) => { var _a; return (_a = `${option === null || option === void 0 ? void 0 : option.vin} ${option === null || option === void 0 ? void 0 : option.plateNumber} ${option === null || option === void 0 ? void 0 : option.nickname}`) !== null && _a !== void 0 ? _a : ''; }} renderOption={(props, option) => (<DropdownItem>
          <Checkbox icon={<CheckBoxOutlineBlank fontSize="small"/>} checkedIcon={<CheckBox fontSize="small"/>} color="primary" checked={selectedCars.find((car) => car === option) !== undefined} onChange={(_, value) => toggleSelect(option, value)} style={{ marginRight: 8 }}/>
          VIN: {option === null || option === void 0 ? void 0 : option.vin}, LIC: {option === null || option === void 0 ? void 0 : option.plateNumber}, {option === null || option === void 0 ? void 0 : option.year}{' '}
          {option === null || option === void 0 ? void 0 : option.make} {option === null || option === void 0 ? void 0 : option.model} {option === null || option === void 0 ? void 0 : option.trim}
        </DropdownItem>)} renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (<Chip label={`VIN: ${option === null || option === void 0 ? void 0 : option.vin}, LIC: ${option === null || option === void 0 ? void 0 : option.plateNumber}, ${option === null || option === void 0 ? void 0 : option.year}
            ${option === null || option === void 0 ? void 0 : option.make} ${option === null || option === void 0 ? void 0 : option.model} ${option === null || option === void 0 ? void 0 : option.trim}`} {...getTagProps({ index })} disabled={option.fixed} deleteIcon={option.fixed && invisibleIcon}/>));
        }} renderInput={(params) => (<TextField {...params} type="text" variant="outlined" InputProps={Object.assign(Object.assign({}, params.InputProps), { 
                // eslint-disable-next-line react/jsx-no-useless-fragment
                endAdornment: <>{params.InputProps.endAdornment}</> })}/>)} PaperComponent={customPaper}/>);
};
export default FleetVehicleSearchSelect;
