import React from 'react';
import { Dialog, IconButton, InputAdornment } from 'src/components/mui';
import { ArrowBackRounded as ArrowBackRoundedIcon } from 'src/components/mui/icons';
import PlacesAutocomplete from 'src/components/form/PlacesAutocomplete';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { isNil } from 'lodash';
const AddressDialogRoot = styled.div `
  .closeBtn {
    padding: 0;
  }

  .FormControl {
    &__notchedOutline {
      border: none;
      border-radius: 0;
      border-bottom: 2px solid ${colors.BLUE};
    }

    &__Input--focused fieldset {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
      background: transparent;
    }
  }

  .MuiAutocomplete-popper {
    transform: translate3d(0, 56px, 0) !important;
    bottom: 0;
  }

  .MuiAutocomplete-paper {
    box-shadow: none;
    margin-bottom: 0;
  }

  .MuiAutocomplete-listbox {
    max-height: none;
    background: ${colors.GRAY_FA};
    padding: 0;
    & li:last-child {
      padding-bottom: 0;
    }
  }

  .suggestionsContainer {
    width: 100%;
    box-shadow: inset 0 -1px 0 0 ${colors.GRAY_DE};
    padding-bottom: 16px;
  }

  .iconCell {
    margin-right: 4px;
  }
`;
const AddressDialog = ({ open, onClose, placeholder, }) => {
    const handleSelect = (value) => {
        if (!isNil(value)) {
            onClose(value);
        }
    };
    return (<Dialog open={open} onClose={onClose} fullScreen>
      <AddressDialogRoot>
        <PlacesAutocomplete displayMap={false} address={undefined} onSelect={handleSelect} label="" autoFocus variant="outlined" placeholder={placeholder !== null && placeholder !== void 0 ? placeholder : 'Vendor Address'} searchOptions={{
            componentRestrictions: { country: 'us' },
            types: ['address'],
        }} suggestionsContainerClassName="suggestionsContainer" iconCellClassName="iconCell" inputProps={{
            classes: {
                disabled: 'FormControl__Input--disabled',
                focused: 'FormControl__Input--focused',
                notchedOutline: 'FormControl__notchedOutline',
                input: 'FormControl__input',
            },
            startAdornment: (<InputAdornment position="start">
                <IconButton onClick={onClose} className="closeBtn">
                  <ArrowBackRoundedIcon />
                </IconButton>
              </InputAdornment>),
        }} inputLabelProps={{
            classes: {
                disabled: 'FormControl__Input--disabled',
                focused: 'FormControl__Input--focused',
                notchedOutline: 'FormControl__notchedOutline',
                input: 'FormControl__input',
            },
        }}/>
      </AddressDialogRoot>
    </Dialog>);
};
export default AddressDialog;
