import { createSelector } from 'reselect';
export const selectState = (state) => { var _a; return (_a = state.admin.quickRo) !== null && _a !== void 0 ? _a : {}; };
export const selectTab = createSelector(selectState, (state) => state.tab);
export const selectNextTab = createSelector(selectState, (state) => state.nextTab);
export const selectUser = createSelector(selectState, (state) => state.user);
export const selectCustomUser = createSelector(selectState, (state) => state.customUser);
export const selectFleet = createSelector(selectState, (state) => state.fleet);
export const selectFleetConsumer = createSelector(selectFleet, (fleet) => fleet === null || fleet === void 0 ? void 0 : fleet.consumer);
export const selectCustomFleet = createSelector(selectState, (state) => state.customFleet);
export const selectCustomAddress = createSelector(selectState, (state) => state.customAddress);
export const selectVehicle = createSelector(selectState, (state) => state.vehicle);
export const selectCustomVehicle = createSelector(selectState, (state) => state.customVehicle);
export const selectRepairs = createSelector(selectState, (state) => { var _a; return (_a = state.repairs) !== null && _a !== void 0 ? _a : []; });
export const selectResponsibleParty = createSelector(selectState, (state) => state.setResponsiblePartyId);
export const selectNewFleetVehicleResponsibleParty = createSelector(selectState, (state) => state.newFleetVehicleResponsibleParty);
export const selectPricing = createSelector(selectState, (state) => state.pricing);
export const selectMechanicIds = createSelector(selectState, (state) => { var _a; return (_a = state.mechanicIds) !== null && _a !== void 0 ? _a : []; });
export const selectVanId = createSelector(selectState, (state) => { var _a; return (_a = state.vanId) !== null && _a !== void 0 ? _a : null; });
export const selectDateTime = createSelector(selectState, (state) => { var _a; return (_a = state.dateTime) !== null && _a !== void 0 ? _a : null; });
export const selectFutureAppointmentDateCheck = createSelector(selectState, (state) => { var _a; return (_a = state.futureAppointmentDateCheck) !== null && _a !== void 0 ? _a : null; });
export const selectFutureAppointmentDate = createSelector(selectState, (state) => { var _a; return (_a = state.futureAppointmentDate) !== null && _a !== void 0 ? _a : null; });
export const selectTimeSlot = createSelector(selectState, (state) => { var _a; return (_a = state.timeSlot) !== null && _a !== void 0 ? _a : null; });
export const selectShift = createSelector(selectState, (state) => { var _a; return (_a = state.shift) !== null && _a !== void 0 ? _a : null; });
export const selectRegion = createSelector(selectState, (state) => state.region);
export const selectActiveMechanicsInRegion = createSelector(selectState, (state) => { var _a; return (_a = state.activeMechanics) !== null && _a !== void 0 ? _a : []; });
export const selectActiveMechanicsInRegionData = createSelector(selectActiveMechanicsInRegion, (active) => { var _a; return (_a = active.mechanics) !== null && _a !== void 0 ? _a : []; });
export const selectSchedulingAvailability = createSelector(selectState, (state) => { var _a; return (_a = state.schedulingAvailability) !== null && _a !== void 0 ? _a : {}; });
export const selectAvailabilityLoading = createSelector(selectSchedulingAvailability, (schedulingAvailability) => schedulingAvailability._isFetching);
export const selectMechanicsLoading = createSelector(selectActiveMechanicsInRegion, (mechanics) => mechanics._isFetching);
export const selectAllAvailabilityLoading = createSelector(selectAvailabilityLoading, selectMechanicsLoading, (availabilityLoading, mechanicsLoading) => availabilityLoading || mechanicsLoading);
export const selectIsBackNavDisabled = createSelector(selectState, (state) => state.isBackNavDisabled);
export const selectContinueDisabled = createSelector(selectState, (state) => state.continueDisabled);
export const selectFleetLatestROs = createSelector(selectState, (state) => { var _a; return (_a = state.fleetLatestROs) !== null && _a !== void 0 ? _a : {}; });
export const selectFleetVisits = createSelector(selectState, (state) => { var _a; return (_a = state.fleetVisits) !== null && _a !== void 0 ? _a : []; });
export const selectVisit = createSelector(selectState, (state) => state.visit);
