const template = {
    name: 'Job Allocation Updated',
    url: '/repair-service/skedulo/webhooks/job-allocation-updated',
    type: 'graphql',
    headers: {},
    query: `
  subscription {
    schemaJobAllocations {
      operation
      timestamp
      data {
        UID
        Duration
        JobId
        ResourceId
        ResourceRequirementId
        Status
        TeamLeader
        LastModifiedDate
        LastModifiedById
        Status
        rs_modified_ms
        environment
      }
      previous {
        UID
        Duration
        JobId
        ResourceId
        ResourceRequirementId
        Status
        TeamLeader
        LastModifiedDate
        LastModifiedById
        Status
        rs_modified_ms
        environment
      }
    }
  }`,
};
export default template;
