import React, { useState } from 'react';
import { Tab, Tabs } from 'src/components/mui';
import TabPanel from 'src/AdminApp/containers/fleets/tabs/TabPanel';
import FleetVehicles from 'src/AdminApp/containers/fleets/FleetVehicles';
import FleetRepairs from 'src/AdminApp/containers/fleets/FleetRepairs';
import FleetVisits from 'src/AdminApp/containers/fleets/FleetVisits';
import useIsMobile from 'src/hooks/useIsMobile';
import { TabContent } from './styles';
import FleetInvoices from '../FleetInvoices';
const VehiclesRepairs = () => {
    const isMobile = useIsMobile();
    const [tab, setTab] = useState(0);
    const handleTabChange = (_e, value) => setTab(value);
    const tabsVariant = isMobile ? 'fullWidth' : 'scrollable';
    return (<>
      <Tabs indicatorColor="primary" scrollButtons="auto" textColor="primary" value={tab} variant={tabsVariant} onChange={handleTabChange}>
        <Tab label="Fleet Vehicles"/>
        <Tab label="Repair History"/>
        <Tab label="Invoices"/>
        <Tab label="Visits"/>
      </Tabs>
      <TabContent>
        <TabPanel index={0} value={tab}>
          <FleetVehicles />
        </TabPanel>
        <TabPanel index={1} value={tab}>
          <FleetRepairs />
        </TabPanel>
        <TabPanel index={2} value={tab}>
          <FleetInvoices />
        </TabPanel>
        <TabPanel index={3} value={tab}>
          <FleetVisits />
        </TabPanel>
      </TabContent>
    </>);
};
export default VehiclesRepairs;
