import { DialogContent, DialogTitle, Grid } from 'src/components/mui';
import styled from 'styled-components';
export const Title = styled(DialogTitle) `
  padding: 15px;
  margin: 0;
  font-weight: bold;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;
export const GridContainer = styled(Grid).attrs({
    container: true,
    spacing: 2,
}) ``;
export const GridItem = styled(Grid).attrs({ item: true }) `
  padding: 5px;
`;
export const Content = styled(DialogContent) `
  padding: 10px;
  margin: 0;
`;
export const ContentText = styled.p `
  padding: 0;
  margin: 0;
`;
