import { Tabs } from 'src/components/mui';
import React from 'react';
import { SimpleDrawerPaged, SimplePageSlider, } from 'src/components/SimpleDrawerPaged';
import ChargeFlow from './ChargeFlow/ChargeFlow';
import RefundFlow from './RefundFlow/RefundFlow';
import { StyledTab } from './styledComponents';
import usePaymentDrawer from './usePaymentDrawer';
const PaymentDrawer = ({ open, responsibleParties, closeDrawer, workOrderPayments, workOrderId, workOrderResponsibleParties, totalInvoicePrice, }) => {
    const { navigate, screen, tab, amount, getInitScreen, closePaymentDrawer, canCharge, canRefund, switchTabs, } = usePaymentDrawer({
        responsibleParties,
        open,
        workOrderPayments,
        totalInvoicePrice,
        workOrderId,
        workOrderResponsibleParties,
    });
    const initScreen = getInitScreen();
    const handleCloseDrawer = () => {
        closePaymentDrawer(closeDrawer);
    };
    const header = () => {
        const chargeTab = tab === '0';
        switch (screen) {
            case 'home': {
                if (chargeTab)
                    return <>Record a Payment</>;
                return 'Record a refund';
            }
            case 'selectPayer': {
                return 'Select Payer Type';
            }
            default:
                return `${chargeTab ? '' : '-'}$${amount}`;
        }
    };
    const getTabRoutes = () => {
        const routes = [];
        if (canCharge) {
            routes.push({
                name: '0',
                render: () => {
                    return <ChargeFlow closeDrawer={closeDrawer}/>;
                },
            });
        }
        if (canRefund) {
            routes.push({
                name: '1',
                render: () => <RefundFlow closeDrawer={closeDrawer}/>,
            });
        }
        return routes;
    };
    return (<SimpleDrawerPaged open={open} closeDrawer={handleCloseDrawer} navigateBack={() => {
            navigate(0);
        }} initScreen={initScreen} screen={screen} header={header()} subheader={screen === 'home' && (<Tabs value={+tab} onChange={switchTabs} variant="fullWidth">
            {canCharge ? <StyledTab label="Charge"/> : null}
            {canRefund ? <StyledTab label="Refund"/> : null}
          </Tabs>)} error={!canCharge &&
            !canRefund && (<div>
            ERROR - Not Authorized: You must have the "Payment_Charge" or
            "Payment_Refund" role to view this module
          </div>)}>
      <SimplePageSlider currentSlide={String(tab)} slides={getTabRoutes()}/>
    </SimpleDrawerPaged>);
};
export default PaymentDrawer;
