import styled from 'styled-components';
import { Button } from 'src/components/mui';
import { AddCircle as AddCircleIcon } from 'src/components/mui/icons';
import { theme, colors, FONT_FAMILY } from 'src/styles/theme';
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;

  .ordersActions {
    display: none;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 350px;

    ${theme.breakpoints.up('md')} {
      display: flex;
    }

    .floatingActionsPanel {
      display: block;
      position: relative;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 24px 32px;
      width: 350px;
      background-color: ${colors.white};
    }

    .vendor {
      padding: 16px;
      border-radius: 24px;
      border: solid 1px rgba(222, 222, 222, 0.24);
      background-color: rgba(63, 63, 63, 0.01);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      margin: 0 0 32px;

      &.active-stop {
        border: solid 1px ${colors.BLUE};
      }
    }

    .vendorTitle {
      font-size: 16px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 4px;

      &__main {
        display: flex;
        align-items: center;
      }

      button {
        font-size: 14px;
        font-weight: 700;
        color: ${colors.BLUE};
        cursor: pointer;
        border: none;
        background-color: transparent;
      }
    }

    .vendorSubtitle {
      font-size: 12px;
      font-weight: 500;
      color: ${colors.GRAY_76};
      padding: 0;
      margin: 0;
    }

    .actionsTitle {
      color: ${colors.black};
      font-size: 24px;
      font-weight: 700;
      padding: 0;
      margin: 0 0 24px;
    }

    .accordion {
      border: none;
    }

    .accHeader {
      color: ${colors.GRAY_76};
      font-size: 12px;
      font-weight: 700;
      padding: 0 0 18px;
    }

    .accContent {
      padding: 0;
    }

    .divider {
      margin: 24px 0;
      width: 100%;
    }

    .button {
      width: 100%;
    }
  }

  .ordersMain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 0 80px;
    flex: 1;
    max-height: 100vh;
    overflow-y: auto;
    z-index: 15;

    &.fixed {
      max-height: none;
    }
  }

  .repairJobs {
    font-size: 20px;
    padding: 0;
    margin: 0 0 16px;

    ${theme.breakpoints.up('md')} {
      font-size: 24px;
      padding: 0 0 24px;
      border-bottom: solid 1px ${colors.GRAY_DE};
      margin: 24px 0 0;
    }
  }

  .accordions {
    position: relative;
    margin: 0 0 56px;

    > div {
      border: none;
      border-bottom: solid 1px ${colors.GRAY_DE};
      position: relative;
      left: -16px;
      width: calc(100% + 32px);
    }

    .accHeader {
      color: ${colors.black};
      padding: 20px 16px;
      font-weight: 500;
      font-size: 14px;
    }

    .accContent {
      color: ${colors.black};
    }

    .MuiSvgIcon-root {
      fill: ${colors.GRAY_CC};
    }
    .MuiIconButton-root {
      padding: 8px;
    }

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  }

  .vendorEdit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${colors.GRAY_76};
    font-weight: 500;
    font-size: 12px;

    padding-bottom: 24px;

    button {
      font-size: 14px;
      font-weight: 700;
      color: ${colors.BLUE};
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
  }

  .vendorConfirmation {
    overflow: hidden;
  }

  .accordionPart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-top: solid 1px ${colors.GRAY_DE};
  }

  .accordionPartTitle {
    color: ${colors.black};
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 4px;
    padding: 0;
  }

  .accordionPartSubtitle {
    color: ${colors.GRAY_76};
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  .accordionActions {
    display: flex;
    flex-direction: row;

    svg {
      fill: ${colors.GRAY_CC};
      color: ${colors.GRAY_CC};
    }
  }

  .partNameWrapper {
    position: relative;
  }

  .pickPartButton {
    position: absolute;
    right: 10px;
    top: 12px;
    color: ${colors.BLUE};
  }

  .editField {
    margin: 0 0 16px;
    width: 100%;

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      font-weight: 700;
    }

    &--left {
      margin-right: 8px;
    }

    &--partName input {
      padding-right: 90px;
      text-overflow: ellipsis;
    }
  }

  .editButton {
    flex: 1;
    padding: 16px 24px;
    margin: 8px 0;
    min-width: 128px;
    white-space: nowrap;
    text-transform: capitalize;

    &--left {
      margin-right: 8px;
    }
  }

  .editVendor {
    margin: 0;
    text-transform: capitalize;
  }

  .editFieldCaption {
    margin: -6px 0 0 12px;
    font-weight: 700;
    background-color: ${colors.white};
  }

  .partCostWrap {
    margin: 32px 0 0;
  }

  .noPartsSelected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: solid 1px rgba(222, 222, 222, 0.24);
    background-color: rgba(63, 63, 63, 0.01);
    color: ${colors.GRAY_76};
    font-weight: 500;
    padding: 50px 0;
  }

  .caption {
    font-size: 12px;
    font-family: ${FONT_FAMILY};
    font-weight: bold;
    margin: 24px 0 24px;
    color: ${colors.GRAY_76};
  }
`;
export const ActionPanel = styled.div `
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: solid 1px ${colors.GRAY_DE};
  background-image: linear-gradient(
    to bottom,
    ${colors.GRAY_EBED},
    ${colors.GRAY_F2} 75%
  );
  padding: 16px 40px;
  z-index: 1200;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.up('md')} {
    display: none;
  }

  .partsAdded {
    margin: 0;
    padding: 0 24px;
    color: ${colors.GRAY_76};
    font-weight: 500;
  }
`;
export const ConfirmationNumberMobileWrapper = styled.div `
  margin: 48px 0 80px;

  > div {
    width: 100%;
  }

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`;
export const ConfirmationNumberTitle = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.5px;
  color: ${colors.GRAY_3F};
  margin-bottom: 24px;
`;
export const NewComponentButton = styled(Button) `
  font-family: ${FONT_FAMILY};
  color: ${colors.BLUE};
  font-weight: 700;
  font-size: 14px;
  text-align: left;
`;
export const StyledAddCircleIcon = styled(AddCircleIcon) `
  color: ${colors.BLUE};
  margin-right: 5px;
`;
