import React from 'react';
import styled from 'styled-components';
import { FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, Typography, Alert, } from 'src/components/mui';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import ReindexSearchButton from './ReindexSearchButton';
import SearchIndexType from './SearchIndexType';
const StyledGrid = styled(Grid) `
  padding: 20px;
  border-bottom: 1px solid lightGrey;
`;
const StyledSpan = styled.span `
  font-weight: bold;
  font-style: italic;
`;
const IndexModule = () => {
    const [checkboxes, setCheckboxes] = React.useState({
        immediateRefresh: false,
        deleteExisting: true,
    });
    const [indexType, setIndexType] = React.useState(SearchIndexType.BOTH);
    const handleIndexTypeChange = (event) => {
        setIndexType(event.target.value);
    };
    const handleCheckboxChange = (event) => {
        setCheckboxes(Object.assign(Object.assign({}, checkboxes), { [event.target.name]: event.target.checked }));
    };
    return (<StyledGrid container spacing={2} style={{ alignItems: 'flex-end' }}>
      <Grid item xs={12}>
        <Typography color="textPrimary">Re-Index Elasticsearch</Typography>
      </Grid>
      <Grid item xs={8}>
        <Alert severity="warning">
          Set <StyledSpan>Immediate Refresh</StyledSpan> to ”Checked/True” only
          for testing purposes
        </Alert>
      </Grid>
      <Grid item>
        <FormGroup row>
          <LabelledCheckbox label="Immediate Refresh" checkboxProps={{
            checked: checkboxes.immediateRefresh,
            onChange: handleCheckboxChange,
            name: 'immediateRefresh',
        }}/>
          <LabelledCheckbox label="Delete Existing" checkboxProps={{
            checked: checkboxes.deleteExisting,
            onChange: handleCheckboxChange,
            name: 'deleteExisting',
        }}/>
        </FormGroup>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>Search Index</InputLabel>
          <Select value={indexType} onChange={handleIndexTypeChange} variant="standard">
            {Object.values(SearchIndexType).map((value) => (<MenuItem value={value} key={value}>
                {value}
              </MenuItem>))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <ReindexSearchButton immediateRefresh={checkboxes.immediateRefresh} deleteExisting={checkboxes.deleteExisting} indexType={indexType}/>
      </Grid>
    </StyledGrid>);
};
export default IndexModule;
