var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ActionButton } from 'src/components/buttons';
import styled from 'styled-components';
const Wrapper = styled.div `
  padding-top: 24px;
  width: 100%;
`;
const SimpleSingleButtonRow = (_a) => {
    var { onSubmit, className = '', disabledSubmit, submitText = 'Save', submitButtonStyle, fullWidth = false } = _a, rest = __rest(_a, ["onSubmit", "className", "disabledSubmit", "submitText", "submitButtonStyle", "fullWidth"]);
    return (<Wrapper {...rest}>
    <ActionButton variant="contained" className={className} fullWidth={fullWidth} onClick={onSubmit} type="submit" color="primary" disabled={disabledSubmit} style={Object.assign({}, submitButtonStyle)}>
      {submitText}
    </ActionButton>
  </Wrapper>);
};
export default SimpleSingleButtonRow;
