var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import styled from 'styled-components';
import { Divider, Typography } from 'src/components/mui';
import { selectFleet, selectVehicle, selectRepairs, selectCustomVehicle, } from 'src/AdminApp/modules/quickRo/selectors';
import { ConsumerTitle, FleetVehicleRow, LatestROs, } from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/components';
import { ActionCreators, setResponsiblePartyId, } from 'src/AdminApp/modules/quickRo/actions';
import LaborCapacityBar from 'src/FleetVisitsApp/pages/VisitView/LaborCapacityBar';
import { convertMinutesToHours } from 'src/FleetVisitsApp/pages/VisitView/VisitInfo/utils';
import FleetResponsiblePartySearchSelect from 'src/AdminApp/containers/fleets/FleetInfo/components/FleetResponsiblePartiesDrawer/components/FleetResponsiblePartySearchSelect';
import RepairItem from './components/RepairItem';
import SearchService from './components/SearchService';
import AddVehicleResponsiblePartyConfirmationModal from '../../AddFleetRODrawer/components/AddVehicleResponsiblePartyConfirmationModal';
const QuoteTitle = styled(Typography) `
  margin: 32px 0 18px;
`;
const CapacityTitle = styled(Typography) `
  margin: 32px 0 24px;

  > section {
    margin: 0;
  }
`;
const SmallTitle = styled(Typography) `
  margin: 20px 0 12px;
`;
const FleetAddServices = ({ visit }) => {
    var _a;
    const fleet = useSelector(selectFleet);
    const vehicle = useSelector(selectVehicle);
    const customVehicle = useSelector(selectCustomVehicle);
    const repairs = useSelector(selectRepairs);
    const dispatch = useDispatch();
    const isAutoIntegrateFmc = ((_a = fleet === null || fleet === void 0 ? void 0 : fleet.fleetManagementCompany) === null || _a === void 0 ? void 0 : _a.submissionMethod) === 'AUTO_INTEGRATE';
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [rpId, setRPId] = useState('');
    const handleSelect = (repair) => {
        dispatch(ActionCreators.TAB.ADD_REPAIR({ payload: repair }));
    };
    const clearVechicleRP = () => {
        dispatch(ActionCreators.TAB.CLEAR_CREATE_NEW_FLEET_VEHICLE_RP());
    };
    const handleSelectRP = (responsibleParty) => {
        var _a, _b;
        if ((_a = responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.groupName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('account')) {
            setOpenConfirmation(true);
        }
        setRPId((responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.id) || '');
        dispatch(setResponsiblePartyId((responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.id) || ''));
        if (!responsibleParty ||
            !((_b = responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.groupName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes('account'))) {
            clearVechicleRP();
        }
    };
    const handleSubmitConfirmNewRP = () => __awaiter(void 0, void 0, void 0, function* () {
        if ((fleet === null || fleet === void 0 ? void 0 : fleet.id) && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin)) {
            dispatch(ActionCreators.TAB.SET_CREATE_NEW_FLEET_VEHICLE_RP({
                payload: {
                    responsiblePartyId: rpId,
                    vin: vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin,
                    carId: vehicle.id,
                },
            }));
        }
        setOpenConfirmation(false);
    });
    const handleSubmitCancelNewRP = () => {
        clearVechicleRP();
        setOpenConfirmation(false);
    };
    return (<>
      {fleet && <ConsumerTitle consumer={{ firstName: fleet === null || fleet === void 0 ? void 0 : fleet.name }}/>}
      {(vehicle === null || vehicle === void 0 ? void 0 : vehicle.make) && <FleetVehicleRow vehicle={vehicle}/>}
      <Divider />
      {visit && (<CapacityTitle>
          <LaborCapacityBar totalScheduledHours={convertMinutesToHours(visit.totalScheduledLaborMinutes)} totalAvailableHours={convertMinutesToHours(visit.totalAvailableLaborMinutes)} totalRemainingHours={convertMinutesToHours(visit.totalRemainingLaborMinutes)}/>
        </CapacityTitle>)}
      <QuoteTitle variant="h5">Quote</QuoteTitle>
      <SmallTitle variant="subtitle1">Responsible Party</SmallTitle>
      <FleetResponsiblePartySearchSelect disabled={!fleet} onSelect={(rp) => handleSelectRP(rp)} fleet={fleet} vehicle={Object.assign(Object.assign({}, vehicle), { consumerCarId: vehicle === null || vehicle === void 0 ? void 0 : vehicle.id })} showRpTypeSuffix selectedResponsibleParties={(customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.fleetResponsibleParties) || (vehicle === null || vehicle === void 0 ? void 0 : vehicle.fleetResponsibleParties)} grouped/>
      <SmallTitle variant="subtitle1">Services</SmallTitle>
      <div>
        {map(repairs, (repair) => {
            var _a;
            return (<RepairItem key={repair.id} repair={Object.assign(Object.assign({}, repair), { label: (_a = repair.name) !== null && _a !== void 0 ? _a : repair.label })}/>);
        })}
      </div>
      <SearchService onSelect={handleSelect} isAutoIntegrateFmc={isAutoIntegrateFmc}/>
      <LatestROs carId={vehicle === null || vehicle === void 0 ? void 0 : vehicle.id}/>
      <AddVehicleResponsiblePartyConfirmationModal open={openConfirmation} onClose={handleSubmitCancelNewRP} handleSubmitConfirmNewRP={handleSubmitConfirmNewRP}/>
    </>);
};
export default FleetAddServices;
