import styled from 'styled-components';
import { Tab } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const StyledTab = styled(Tab) `
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.5px;
`;
export const InputLabel = styled.div `
  margin-left: 6px;
  background-color: ${colors.white};
  z-index: 100;
  position: relative;
  width: fit-content;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
`;
export const ExpirationRow = styled.div `
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  justify-content: space-between;
`;
export const Link = styled.div `
  font-weight: bold;
  color: ${colors.primary};
  cursor: pointer;
`;
