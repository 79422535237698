import React, { useState } from 'react';
import { createMenuItems } from 'src/AdminApp/components/form/utils';
import { MobileEditFieldDrawer } from 'src/AdminApp/containers/vehicle/CarModule/MobileEditFieldDrawer';
import { ResidualValue } from 'src/AdminApp/models/enums';
import { Grid, TextField } from 'src/components/mui';
const ResidualFieldEdit = (props) => {
    const [residualValue, setResidualValue] = useState(props.values.residualValue);
    return (<MobileEditFieldDrawer {...props} label="Residual Value" onSave={() => props.onSave({ residualValue })}>
      <Grid item xs={12}>
        <TextField variant="outlined" value={residualValue} onChange={({ target }) => setResidualValue(target.value)} label="Residual Value" type="text" name="residualValue" select fullWidth>
          {createMenuItems(ResidualValue, true)}
        </TextField>
      </Grid>
    </MobileEditFieldDrawer>);
};
export default ResidualFieldEdit;
