import React, { useContext } from 'react';
import styled from 'styled-components';
import { ButtonBase, Grid } from 'src/components/mui';
import { KeyboardArrowRight } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import { SliderContext, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/utils';
import { SlidePageContent } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
const ButtonWithArrow = styled(ButtonBase) `
  padding: 20px 0;
  box-shadow: inset 0 -1px 0 0 ${colors.lightGrey};
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;
const OptionType = ({ children, onClick, disabled }) => {
    return (<ButtonWithArrow disabled={disabled} onClick={onClick}>
      <Grid container style={{ alignItems: 'center' }}>
        <Grid item xs>
          {children}
        </Grid>
        <Grid>
          <KeyboardArrowRight />
        </Grid>
      </Grid>
    </ButtonWithArrow>);
};
const HomePage = ({ disableOptions }) => {
    var _a;
    const { setSlide } = (_a = useContext(SliderContext)) !== null && _a !== void 0 ? _a : {};
    const handlePage = (page) => () => {
        if (setSlide) {
            setSlide(page);
        }
    };
    return (<SlidePageContent>
      <OptionType disabled={!!(disableOptions === null || disableOptions === void 0 ? void 0 : disableOptions.territory)} onClick={handlePage('territory')}>
        Territory
      </OptionType>

      <OptionType disabled={!!(disableOptions === null || disableOptions === void 0 ? void 0 : disableOptions.metro)} onClick={handlePage('metro')}>
        Metro
      </OptionType>

      <OptionType disabled={!!(disableOptions === null || disableOptions === void 0 ? void 0 : disableOptions.region)} onClick={handlePage('region')}>
        Region
      </OptionType>
      <OptionType disabled={!!(disableOptions === null || disableOptions === void 0 ? void 0 : disableOptions.serviceArea)} onClick={handlePage('serviceArea')}>
        Service Area
      </OptionType>
    </SlidePageContent>);
};
export default HomePage;
