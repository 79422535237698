var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectFleetRequests } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { useMediaQuery } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import { addWorkOrdersToVisit, getFleetRequests, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import moment from 'moment/moment';
import { convertCapacityToHours, convertMinutesToHours, getPrecisionHours, } from 'src/FleetVisitsApp/pages/VisitView/VisitInfo/utils';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
import { WARNINGS } from '../../FleetVisits/components/VisitCapacityWarningModal/WarningMessages';
const useAddExistingRepairOrdersDrawer = ({ open, onClose, visit, }) => {
    var _a;
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedRepairOrders, setSelectedRepairOrders] = useState([]);
    const partsOrdered = ((_a = selectedRepairOrders === null || selectedRepairOrders === void 0 ? void 0 : selectedRepairOrders.filter((order) => {
        var _a, _b;
        return ((_a = order === null || order === void 0 ? void 0 : order.repairRequest) === null || _a === void 0 ? void 0 : _a.partsStatus) === 'ORDERED' ||
            ((_b = order === null || order === void 0 ? void 0 : order.repairRequest) === null || _b === void 0 ? void 0 : _b.partsStatus) === 'PARTIALLY_ORDERED';
    })) === null || _a === void 0 ? void 0 : _a.length) > 0;
    const [searchedRepairOrders, setSearchedRepairOrders] = useState(null);
    const [selectedRepairOrdersSet, setSelectedRepairOrdersSet] = useState(new Set());
    const [searchTerm, setSearchTerm] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const repairOrders = useSelector(selectFleetRequests);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const [isPartsResetModalOpen, setIsPartsResetModalOpen] = useState(false);
    const [addToVisitDialog, setAddToVisitDialog] = useState(false);
    const [repairToAdd, setRepairToAdd] = useState();
    const [repairToAddAction, setRepairToAddAction] = useState();
    const [showCapacityWarning, setShowCapacityWarning] = useState(false);
    const [unsavedSelectedRepairOrders, setUnsavedSelectedRepairOrders] = useState(undefined);
    const [warningMessage, setWarningMessage] = useState('');
    const [totalScheduledHours, setTotalScheduledHours] = useState('N/A');
    const [totalRemainingHours, setTotalRemainingHours] = useState('N/A');
    const resetCapacity = () => {
        setTotalScheduledHours(convertMinutesToHours(visit === null || visit === void 0 ? void 0 : visit.totalScheduledLaborMinutes));
        setTotalRemainingHours(convertMinutesToHours(visit === null || visit === void 0 ? void 0 : visit.totalRemainingLaborMinutes));
    };
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (open && visit) {
                setSelectedTab(0);
                setSearchedRepairOrders(null);
                setSearchTerm('');
                resetCapacity();
                setIsLoading(true);
                yield dispatch(getFleetRequests({ refNum: visit.refNum }));
                setIsLoading(false);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, visit]);
    useEffect(() => {
        // ADDED REPAIRS TAB REQUIRES US TO CALCULATE THE NEW CAPACITY WITH THE ADDED REPAIRS
        if (selectedRepairOrders.length === 0) {
            resetCapacity();
            return;
        }
        const newTotalScheduledHours = selectedRepairOrders.reduce((total, order) => {
            var _a;
            const duration = (_a = order.totalLaborDuration) !== null && _a !== void 0 ? _a : 0;
            return total + duration;
        }, 0);
        /*
        const newTotalScheduledHoursPrecision = newTotalScheduledHours.toPrecision(
          getPrecisionHours(newTotalScheduledHours)
        );
    
         */
        const originalTotalScheduledHours = convertMinutesToHours(visit === null || visit === void 0 ? void 0 : visit.totalScheduledLaborMinutes);
        const newTotalScheduledHoursAdded = parseFloat(originalTotalScheduledHours === 'N/A'
            ? '0'
            : originalTotalScheduledHours) + newTotalScheduledHours;
        setTotalScheduledHours(newTotalScheduledHoursAdded.toString().replace(/\.0$/gi, ''));
        const newTotalRemainingHoursPrecision = selectedRepairOrders.reduce((total, order) => {
            var _a;
            const duration = (_a = order.totalLaborDuration) !== null && _a !== void 0 ? _a : 0;
            return total + duration;
        }, 0);
        const originalTotalRemaningHours = convertMinutesToHours(visit === null || visit === void 0 ? void 0 : visit.totalRemainingLaborMinutes);
        const newTotalRemainingHours = parseFloat(originalTotalRemaningHours === 'N/A' ? '0' : originalTotalRemaningHours) - newTotalRemainingHoursPrecision;
        setTotalRemainingHours(newTotalRemainingHours
            .toPrecision(getPrecisionHours(newTotalRemainingHours))
            .replace(/\.0$/gi, ''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRepairOrders]);
    const { totalAvailableHours } = convertCapacityToHours(visit);
    const onTabChange = (e, value) => {
        setSelectedTab(value);
        setSearchedRepairOrders(null);
        setSearchTerm('');
    };
    const shouldShowCapacityWarning = ({ newOrderDurationHours, action, }) => {
        if (!visit || action === 'REMOVE')
            return false;
        let newTotalScheduledHours = parseFloat(totalScheduledHours);
        // ADDING A NEW RO
        if (action === 'ADD') {
            newTotalScheduledHours += newOrderDurationHours;
        }
        else {
            newTotalScheduledHours -= newOrderDurationHours;
        }
        const totalAvailableHours = convertMinutesToHours(visit.totalAvailableLaborMinutes);
        // VALIDATION
        if (parseFloat(totalAvailableHours) < newTotalScheduledHours) {
            setShowCapacityWarning(true);
            setWarningMessage(WARNINGS.ADD_A_SERVICE);
            return true;
        }
        return false;
    };
    const handleCapacityWarningClose = (confirm) => {
        setShowCapacityWarning(false);
        if (confirm && unsavedSelectedRepairOrders) {
            setSelectedRepairOrders(unsavedSelectedRepairOrders);
            setSelectedRepairOrdersSet(new Set(unsavedSelectedRepairOrders.map((order) => order.workOrderId)));
        }
        setUnsavedSelectedRepairOrders(undefined);
    };
    const handleSelectRepairOrder = (repairOrder, action) => {
        let newSelectedOrders = [];
        if (action === 'ADD') {
            newSelectedOrders = [...selectedRepairOrders, Object.assign({}, repairOrder)];
        }
        else {
            newSelectedOrders = selectedRepairOrders.filter((ro) => ro.workOrderId !== repairOrder.workOrderId);
        }
        setUnsavedSelectedRepairOrders(newSelectedOrders);
        if (!shouldShowCapacityWarning({
            newOrderDurationHours: repairOrder.totalLaborDuration,
            action,
        })) {
            setSelectedRepairOrders(newSelectedOrders);
            setSelectedRepairOrdersSet(new Set(newSelectedOrders.map((order) => order.workOrderId)));
        }
    };
    const addRepairOrder = (isAddable, repairOrder, action) => {
        if (isAddable || action === 'REMOVE') {
            handleSelectRepairOrder(repairOrder, action);
        }
        else if (action === 'ADD') {
            setRepairToAdd(repairOrder);
            setRepairToAddAction(action);
            setAddToVisitDialog(true);
        }
    };
    const confirmAddingModalProps = {
        confirmAdding: () => {
            if (!repairToAdd || !repairToAddAction)
                return;
            handleSelectRepairOrder(repairToAdd, repairToAddAction);
            setAddToVisitDialog(false);
        },
        cancelAdding: () => {
            setAddToVisitDialog(false);
        },
    };
    const isRoReadyToAdd = (roDate) => {
        let isAddable = false;
        if (visit === null || visit === void 0 ? void 0 : visit.actualStartLocal) {
            const visitDate = new Date(visit === null || visit === void 0 ? void 0 : visit.actualStartLocal);
            isAddable =
                visitDate === undefined
                    ? true
                    : moment(visitDate).format(DATE_YMD_FORMAT) >=
                        moment(roDate).format(DATE_YMD_FORMAT);
        }
        return isAddable;
    };
    const handleClose = () => {
        setSelectedRepairOrders([]);
        setSelectedRepairOrdersSet(new Set());
        setIsPartsResetModalOpen(false);
        onClose();
    };
    const handleAddROs = () => __awaiter(void 0, void 0, void 0, function* () {
        if (partsOrdered) {
            setIsPartsResetModalOpen(true);
        }
        else {
            handleSubmit(false);
        }
    });
    const handleSubmit = (resetParts) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        const updateResponse = yield dispatch(addWorkOrdersToVisit({
            refNum: visit === null || visit === void 0 ? void 0 : visit.refNum,
            workOrderIds: Array.from(selectedRepairOrdersSet),
            resetParts,
        }));
        setIsSubmitting(false);
        if (!updateResponse.error) {
            handleClose();
        }
    });
    const handleSearchChange = (e) => {
        const q = e.target.value.toLowerCase();
        setSearchTerm(e.target.value);
        if (q.length === 0) {
            setSearchedRepairOrders(null);
            return;
        }
        const source = selectedTab === 0 ? repairOrders : selectedRepairOrders;
        if (source) {
            setSearchedRepairOrders(source.filter((ro) => {
                var _a;
                return (ro.fleetName.toLowerCase().includes(q) ||
                    ((_a = ro.vin) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(q)));
            }));
        }
    };
    return {
        selectedTab,
        searchedRepairOrders,
        repairOrders,
        selectedRepairOrders,
        handleClose,
        onTabChange,
        isLoading,
        handleSearchChange,
        searchTerm,
        isDesktop,
        addRepairOrder,
        selectedRepairOrdersSet,
        isRoReadyToAdd,
        handleAddROs,
        isSubmitting,
        isPartsResetModalOpen,
        setIsPartsResetModalOpen,
        handleSubmit,
        addToVisitDialog,
        confirmAddingModalProps,
        showCapacityWarning,
        handleCapacityWarningClose,
        warningMessage,
        totalAvailableHours,
        totalScheduledHours,
        totalRemainingHours,
    };
};
export default useAddExistingRepairOrdersDrawer;
