import queryString from 'query-string';
import CONFIG from 'src/config';
import { CUSTOM_REPAIR_ID, FMC_CUSTOM_REPAIR_ID, } from 'src/AdminApp/modules/api/constants';
import { PrefixHandler } from 'src/utils/url-utils';
const API_URLS = {
    ADMIN: {
        ADD_CONSUMER: () => '/consumer-service/admin/consumer/profile',
        ADD_CUSTOM_REPAIR: ({ requestId, repairDescription, }) => `/repair-service/repair-requests/${requestId}/repair/${CUSTOM_REPAIR_ID}?customRepairText=${repairDescription}`,
        ADD_FMC_CUSTOM_REPAIR: ({ requestId, repairDescription, fmcCustomRepairId, }) => `/repair-service/repair-requests/${requestId}/repair/${FMC_CUSTOM_REPAIR_ID}?customRepairText=${repairDescription}&fmcCustomRepairId=${fmcCustomRepairId}`,
        ADD_REPAIR: ({ requestId, repairId, }) => `/repair-service/repair-requests/${requestId}/repair/${repairId}`,
        ADD_VEHICLE: ({ consumerId }) => `/consumer-service/admin/consumer/${consumerId}/cars`,
        ADD_WARRANTY: (referenceNum) => `/repair-service/admin/repair-requests/${referenceNum}/warranty-claim`,
        APPOINTMENT_RESOLUTIONS: () => '/repair-service/admin/appointments/resolutions',
        APPOINTMENT_EDIT_DATE: ({ referenceNum }) => `/repair-service/admin/appointments/${referenceNum}/adjust`,
        ADD_STOPWATCH_ADDITIONAL_TIME: (appointmentId) => `/repair-service/admin/appointments/${appointmentId}/additional-time`,
        CARS_SEARCH: () => '/consumer-service/admin/consumer/cars/search',
        CONSUMER_CAR: ({ consumerId, consumerCarId, }) => `/consumer-service/admin/consumer/${consumerId}/car/${consumerCarId}`,
        GET_CONSUMER_CAR: ({ consumerId, consumerCarId, }) => `/consumer-service/admin/consumer/${consumerId}/cars/${consumerCarId}`,
        CONSUMER: ({ consumerId } = {}) => `/consumer-service/admin/consumer${consumerId ? `/${consumerId}` : ''}`,
        CONSUMER_UPDATE_ADDRESS: ({ consumerId } = {}) => `/consumer-service/admin/consumer/${consumerId}/address`,
        CONSUMER_ZENDESK_ID: (consumerId) => `/consumer-service/admin/consumer/${consumerId}/zendesk-id`,
        CONSUMERS_SEARCH: () => '/consumer-service/admin/consumer/dashboard-search',
        CONSUMER_CARS: ({ consumerId, pagination, }) => `/consumer-service/admin/consumer/${consumerId}/cars${pagination ? `?${queryString.stringify(pagination)}` : ''}`,
        CONSUMER_REQUEST_HISTORY: ({ consumerId, pagination, }) => `/repair-service/consumers/${consumerId}/request-history${pagination ? `?${queryString.stringify(pagination)}` : ''}`,
        CONSUMER_PASSWORD_RESET: () => '/consumer-service/password/reset',
        VALIDATE_VIN_PLATE: ({ consumerId, params, }) => `/consumer-service/admin/consumer/${consumerId}/cars/validate-vin-plate?${params}`,
        MARKETING_SOURCES: () => '/repair-service/admin/marketing-sources/by-visibility',
        MARKETING_SOURCES_DASHBOARD: ({ marketingSourceId, }) => {
            return marketingSourceId
                ? `/repair-service/admin/marketing-sources/${marketingSourceId}`
                : '/repair-service/admin/marketing-sources';
        },
        MARKETING_SOURCES_FEES: () => '/repair-service/admin/fees',
        LOGO_BY_BRAND: () => '/isp-service/logos',
        ORDER: ({ orderId }) => `/repair-service/repair-orders/${orderId}`,
        ORDER_APPOINTMENT: ({ orderId }) => `/repair-service/repair-orders/${orderId}/appointment`,
        ORDER_EDIT_DATE: ({ orderId }) => `/repair-service/repair-orders/${orderId}/appointment-info`,
        DELETE_DOCUMENT_BY_ID: ({ workOrderId, document, }) => `/repair-service/admin/work-orders/${workOrderId}/invoice/documents/${document.id}`,
        ORDER_INVOICE: ({ workOrderId }) => `/repair-service/admin/work-orders/${workOrderId}/invoice`,
        ORDER_INVOICES: ({ workOrderId }) => `/repair-service/admin/work-orders/${workOrderId}/invoices`,
        UPLOAD_DOCUMENTS: (workOrderId) => `/repair-service/admin/work-orders/${workOrderId}/upload-documents`,
        CUSTOMER_REVIEW: ({ orderId }) => `/repair-service/repair-orders/${orderId}/customer-review`,
        ORDER_SELECT_SHOP: ({ orderId, ispId, }) => `/repair-service/repair-orders/${orderId}/isp?ispId=${ispId}`,
        PAYMENT_TYPES: () => '/repair-service/payment-types',
        PAYMENT_SUB_TYPES: (paymentTypeId) => `/repair-service/payment-types/${paymentTypeId}`,
        QUOTE_CAR_DIAGNOSIS: ({ diagnosisId }) => `/repair-service/quotes/car-diagnosis/${diagnosisId}`,
        QUOTE_DIAGNOSIS: ({ requestId }) => `/repair-service/repair-requests/${requestId}/diagnosis`,
        QUOTE_SUGGESTED_REPAIR: ({ suggestedRepairId, }) => `/repair-service/quotes/suggested-repair/${suggestedRepairId}`,
        UPDATE_PARTS_STATUS: ({ requestId, status, }) => `/repair-service/repair-requests/${requestId}/parts-status/${status}`,
        // Discounts
        PROMO_CODE_APPLY_TO_QUOTE: ({ workOrderId, promoCode, }) => `/repair-service/admin/promo/work-orders/${workOrderId}/stage/QUOTE/code/${promoCode}`,
        PROMO_CODE_APPLY_TO_INVOICE: ({ workOrderId, promoCode, }) => `/repair-service/admin/promo/work-orders/${workOrderId}/stage/INVOICE/code/${promoCode}`,
        PROMO_CODE_REDEEM: ({ workOrderId }) => `/repair-service/admin/promo/work-orders/${workOrderId}/redeem`,
        RO_CREDITS: ({ consumerId }) => `/repair-service/admin/discounts/credits/${consumerId}`,
        CANCEL_FEE_CREDITS: ({ consumerId }) => `/repair-service/admin/discounts/credits/cancel-fee/${consumerId}`,
        DISCOUNTS_BY_WORK_ORDER: ({ workOrderId }) => `/repair-service/admin/discounts/work-orders/${workOrderId}`,
        REMOVE_DISCOUNT: ({ workOrderId, discountId, }) => `/repair-service/admin/discounts/work-orders/${workOrderId}/${discountId}`,
        ADD_REPAIR_INVOICE: ({ invoiceId, repairId, }) => `/repair-service/invoice/${invoiceId}/repair/${repairId}`,
        INVOICE_SUGGESTED_REPAIR_COMPONENTS_BATCH_ADDITIONAL_REPAIR_COMPONENTS: () => '/repair-service/invoice/suggested-repair/components',
        COMPONENT_INVOICE: (suggestedRepairId) => `/repair-service/invoice/suggested-repair/${suggestedRepairId}/component`,
        INVOICE_DELETE_COMPONENT: ({ repairId, componentType, componentId, removeDynamicRule, }) => `/repair-service/invoice/suggested-repair/${repairId}/component/${componentType}/${componentId}${removeDynamicRule ? '?removeDynamicRule=true' : ''}`,
        CUSTOM_COMPONENT_INVOICE: (customRequestId) => `/repair-service/invoice/custom-request/${customRequestId}/component`,
        INVOICE_UPDATE_SUGGESTED_REPAIR: (suggestedRepairId) => `/repair-service/invoice/suggested-repair/${suggestedRepairId}`,
        ADD_CUSTOM_REPAIR_INVOICE: ({ invoiceId, repairDescription, }) => `/repair-service/invoice/${invoiceId}/repair/${CUSTOM_REPAIR_ID}?customRepairText=${repairDescription}`,
        REMOVE_REPAIR_FROM_INVOICE: (knownRepairId) => `/repair-service/invoice/suggested-repair/${knownRepairId}`,
        REMOVE_DIAGNOSE_FROM_INVOICE: (carDiagnosisId) => `/repair-service/invoice/car-diagnosis/${carDiagnosisId}`,
        REMOVE_CUSTOM_REPAIR_FROM_INVOICE: (customRequestId) => `/repair-service/invoice/custom-request/${customRequestId}`,
        INVOICE_DELETE_CUSTOM_COMPONENT: ({ repairId, componentType, componentId, }) => `/repair-service/invoice/custom-request/${repairId}/component/${componentType}/${componentId}`,
        INVOICE_CREATE: ({ requestId }) => `/repair-service/invoice/create/${requestId}`,
        QUOTE_AUTHORIZATION: ({ quoteId, method, authorizedByName, }) => `/repair-service/quotes/${quoteId}/authorization/${method}?authorizedByName=${authorizedByName}`,
        INVOICE_PROVIDED: ({ invoiceId }) => `/repair-service/invoice/${invoiceId}/invoice-provided`,
        INVOICE_CALCULATE_TAX: ({ invoiceId }) => `/repair-service/invoice/${invoiceId}/taxes`,
        INVOICE_UPDATE_NOTES: ({ invoiceId }) => `/repair-service/invoice/${invoiceId}/notes`,
        PAYMENT_ADD: ({ workOrderId }) => `/repair-service/admin/payments/work-orders/${workOrderId}`,
        PAYMENT_REMOVE: ({ workOrderId, paymentId, }) => `/repair-service/admin/payments/${paymentId}/work-orders/${workOrderId}`,
        PAYMENT_CHARGE: ({ workOrderId }) => `/repair-service/admin/payments/work-orders/${workOrderId}/pay`,
        AFFIRM_PAYMENT_CHARGE: ({ workOrderId }) => `/repair-service/admin/payments/work-orders/${workOrderId}/checkout`,
        PAYMENT_METHODS_BY_CONSUMER: ({ consumerId, referenceNum, }) => `/repair-service/admin/payments/methods/${consumerId}?referenceNum=${referenceNum}`,
        PAYMENT_METHOD_ACTIVATION: ({ consumerId, paymentMethodId, }) => `/repair-service/admin/payments/${consumerId}/method/${paymentMethodId}`,
        WORK_ORDER_PAYMENTS: (workOrderId) => `/repair-service/admin/payments/work-orders/${workOrderId}/payments`,
        PAYMENT_REFUND: ({ workOrderId }) => `/repair-service/admin/payments/work-orders/${workOrderId}/refund`,
        REPAIRS_SEARCH: () => '/search-service/search/',
        FMC_REPAIRS_SEARCH: () => '/pricing-service/admin/fmc/repairs/',
        PUT_WARRANTY_QUESTIONS: ({ referenceNum, warrantyClaimId, }) => `/repair-service/admin/repair-requests/${referenceNum}/warranty-claim/${warrantyClaimId}`,
        REQUESTS: () => '/repair-service/repair-requests',
        REQUESTS_DASHBOARD: (params) => `/repair-service/repair-orders/dashboard-search?${queryString.stringify(Object.assign(Object.assign({}, params.filters), params.pagination))}`,
        REQUEST_BY_ID: ({ requestId }) => `/repair-service/repair-requests/${requestId}`,
        REQUEST_BY_REFERENCE_NUM: ({ referenceNum }) => `/repair-service/repair-requests/?referenceNum=${referenceNum}`,
        REQUEST_CAR: ({ requestId }) => `/repair-service/repair-requests/${requestId}/car`,
        REQUESTS_BY_CAR_STATES: ({ consumerCarId }) => `/repair-service/repair-requests/cars/${consumerCarId}`,
        REQUEST_SUGGESTED_REPAIR_COMPONENTS: ({ suggestedRepairId, isValidateLaborCapacity, }) => `/repair-service/quotes/suggested-repair/${suggestedRepairId}/component?isValidateLaborCapacity=${isValidateLaborCapacity}`,
        QUOTE_SUGGESTED_REPAIR_COMPONENTS_BATCH_ADDITIONAL_REPAIR_COMPONENTS: () => '/repair-service/quotes/suggested-repair/components',
        REQUEST_CUSTOM_REPAIR_COMPONENTS: ({ customRequestId, }) => `/repair-service/quotes/custom-request/${customRequestId}/component`,
        REMOVE_SUGGESTED_REPAIR_COMPONENTS: ({ suggestedRepairId, componentType, componentId, removeDynamicRule, }) => {
            return `/repair-service/quotes/suggested-repair/${suggestedRepairId}/component/${componentType}/${componentId}${removeDynamicRule ? '?removeDynamicRule=true' : ''}`;
        },
        REMOVE_DELIVERY_FEE_FOR_REQUEST: ({ referenceNum, }) => `/repair-service/quotes/${referenceNum}/delivery-fee`,
        PROCESS_INELIGIBLE_FOR_SERVICE: ({ referenceNum, }) => `/repair-service/admin/cant-finish/${referenceNum}/ineligible-for-service`,
        PROCESS_VEHICLE_CONDITION_TOO_POOR: ({ referenceNum, }) => `/repair-service/admin/cant-finish/${referenceNum}/vehicle-condition-poor`,
        COMPLETE_PROCESS_VEHICLE_REQUIRES_ADDTL_REPAIRS: ({ referenceNum, }) => `/repair-service/admin/cant-finish/${referenceNum}/complete-vehicle-requires-addtl-repairs`,
        PROCESS_CANT_FINISH_VEHICLE_IS_LONGER_THAN_NO_CHARGE_GRACE_PERIOD: ({ referenceNum, }) => `/repair-service/admin/cant-finish/${referenceNum}/grace-period`,
        REMOVE_CUSTOM_REPAIR_COMPONENTS: ({ customRequestId, componentType, componentId, }) => `/repair-service/quotes/custom-request/${customRequestId}/component/${componentType}/${componentId}`,
        QUOTE_CALCULATE_TAX: (quoteId) => `/repair-service/quotes/${quoteId}/taxes`,
        REQUEST_APPOINTMENTS: ({ requestId }) => `/repair-service/repair-requests/${requestId}/appointments`,
        REQUEST_ORDERS: ({ requestId }) => `/repair-service/repair-requests/${requestId}/repair-orders`,
        REQUEST_ORDER_CREATE: ({ requestId }) => `/repair-service/repair-requests/${requestId}/repair-orders`,
        REQUEST_CONTACT: ({ requestId }) => `/repair-service/repair-requests/${requestId}/consumer-contact-info`,
        REQUEST_OPEN: ({ requestId }) => `/repair-service/repair-requests/${requestId}/state/open-request`,
        REQUEST_CANCEL: ({ requestId }) => `/repair-service/repair-requests/${requestId}/state/cancel-request`,
        REQUEST_CONFIRM_APPOINTMENT: ({ requestId }) => `/repair-service/repair-requests/${requestId}/state/confirm-appointment`,
        REQUEST_RESCHEDULE_APPOINTMENT: ({ requestId }) => `/repair-service/repair-requests/${requestId}/state/reschedule-appointment`,
        REQUEST_CANCEL_APPOINTMENT: ({ requestId }) => `/repair-service/repair-requests/${requestId}/state/cancel-appointment`,
        REQUEST_CANCEL_VISIT_APPOINTMENT: ({ visitReferenceNum, workOrderId, }) => `/repair-service/admin/visits/${visitReferenceNum}/work-orders/${workOrderId}`,
        REQUEST_REJECT_APPOINTMENT: ({ requestId }) => `/repair-service/repair-requests/${requestId}/state/reject-appointment`,
        REQUEST_START_PROGRESS: ({ requestId }) => `/repair-service/repair-requests/${requestId}/state/start-progress`,
        REQUEST_CLOSE: ({ requestId }) => `/repair-service/repair-requests/${requestId}/state/close-repair`,
        REQUEST_PROVIDE_QUOTE: ({ requestId }) => `/repair-service/repair-requests/${requestId}/quote-provided`,
        REQUEST_RELATED_PARTS: ({ requestId }) => `/repair-service/repair-requests/${requestId}/related-parts`,
        REQUEST_ADDRESS: ({ requestId }) => `/repair-service/repair-requests/${requestId}/address`,
        ALL_REPAIRS: () => '/pricing-service/search/repairs',
        NOTES: () => '/repair-service/admin/notes',
        NOTES_BY_ID: (id) => `/repair-service/admin/notes/${id}`,
        NOTES_BY_ID_TYPE_ORIGIN: ({ entityId, entityType, origin, }) => `/repair-service/admin/notes/${entityId}/${entityType}/${origin}`,
        CONSUMER_CAR_TOKENS: (consumerCarId) => `/repair-service/consumer/tokens/${consumerCarId}`,
        ADD_PAYMENT_METHOD: () => '/repair-service/admin/payments/methods',
        REPAIR_MEASUREMENTS: (referenceNum) => `/repair-service/admin/measurements/repair/${referenceNum}`,
        FLEET_LATEST_ROS: (carId) => `/repair-service/admin/fleet/repair-requests/cars/${carId}`,
        CANCEL_FEE_MAX_HOURS: () => '/repair-service/admin/fees/cancel-fee/max-hours',
        // FMC
        FMC_SEND_FOR_APPROVAL: ({ workOrderId, submissionMethod, authorizationType, recommendedRepairIds, }) => `/repair-service/admin/fmc/${workOrderId}/${submissionMethod}/${authorizationType}${recommendedRepairIds
            ? `?${queryString.stringify({
                recommendedRepairIds,
            })}`
            : ''}`,
        FMC_GET_WORK_AUTHORIZATION: ({ workOrderId, authorizationType, }) => `/repair-service/admin/fmc/work-authorizations/${workOrderId}/${authorizationType}`,
        // ISP Service
        SHOPS: ({ pageSize, pageIndex, sort, }) => `/isp-service/isps?size=${pageSize}&page=${pageIndex}&sort=${sort}`,
        SHOPS_DASHBOARD: () => '/isp-service/isps/dashboard-search',
        SHOPS_SEARCH: () => '/isp-service/isps/search-by-filters',
        SHOPS_BY_ID: (id) => `/isp-service/admin/isps/${id}`,
        SHOP_UPDATE: (id) => `/isp-service/isps/${id}`,
        SHOP_REPAIR_FOCUSES: () => '/isp-service/repairfocuses',
        SHOP_CAR_BRANDS: () => '/isp-service/carbrands',
        SHOP_CAR_TYPES: () => '/isp-service/cartypes',
        SHOP_REGISTER: () => '/isp-service/admin/admin-register-isp',
        SERVICE_AREAS: () => '/isp-service/admin/service-areas',
        SERVICE_AREAS_BY_ID: (id) => `/isp-service/admin/service-areas/${id}`,
        SERVICE_AREAS_ARCHIVE: (id) => `/isp-service/admin/service-areas/${id}/archive`,
        SERVICE_AREAS_AUTOCOMPLETE: (filters) => `/isp-service/admin/service-areas/autocomplete?${queryString.stringify(Object.assign({}, filters))}`,
        SERVICE_AREAS_BY_ZIP_CODE: (zipCode) => `/isp-service/admin/service-areas/zip-codes/${zipCode}`,
        ACTIVE_SERVICE_AREAS_BY_ZIP_CODE: (params) => `/isp-service/service-areas?${queryString.stringify(Object.assign({}, params.filters))}`,
        VALIDATE_ZIP_CODES: () => '/isp-service/admin/service-areas/zip-codes/validate',
        TRANSFER_ZIP_CODES: () => '/isp-service/admin/service-areas/zip-codes/transfer',
        SKEDULO_REGIONS: () => '/isp-service/admin/skedulo/regions',
        REGIONS: () => '/isp-service/admin/regions',
        REGIONS_DASHBOARD: (params) => `/isp-service/admin/regions/dashboard-search?${queryString.stringify(Object.assign(Object.assign({}, params.filters), params.pagination))}`,
        REGION_BY_ID: (id) => `/isp-service/admin/regions/${id}`,
        REGION_ARCHIVE: (id) => `/isp-service/admin/regions/${id}/archive`,
        REGIONS_AUTOCOMPLETE: (filters) => `/isp-service/admin/regions/autocomplete?${queryString.stringify(Object.assign({}, filters))}`,
        METROS: () => '/isp-service/admin/metros',
        METRO_BY_ID: (id) => `/isp-service/admin/metros/${id}`,
        METRO_ARCHIVE: (id) => `/isp-service/admin/metros/${id}/archive`,
        METROS_AUTOCOMPLETE: (filters) => `/isp-service/admin/metros/autocomplete?${queryString.stringify(Object.assign({}, filters))}`,
        TERRITORIES: () => '/isp-service/admin/territories',
        TERRITORIES_DASHBOARD: (params) => `/isp-service/admin/territories/dashboard-search?${queryString.stringify(Object.assign(Object.assign({}, params.filters), params.pagination))}`,
        TERRITORY_BY_ID: (territoryId) => `/isp-service/admin/territories/${territoryId}`,
        TERRITORY_ARCHIVE: (territoryId) => `/isp-service/admin/territories/${territoryId}/archive`,
        TERRITORIES_AUTOCOMPLETE: (filters) => `/isp-service/admin/territories/autocomplete?${queryString.stringify(Object.assign({}, filters))}`,
        DELIVERY_VANS: () => '/isp-service/admin/delivery-vans',
        DELIVERY_VAN_BY_ID: (deliveryVanId) => `/isp-service/admin/delivery-vans/${deliveryVanId}`,
        DELIVERY_VAN_HOME_STORES_BY_ID: (deliveryVanId) => `/isp-service/admin/delivery-vans/${deliveryVanId}/homeStores`,
        DELIVERY_VAN_BY_ISP_ID: (ispId) => `/isp-service/admin/delivery-vans/by-isp-id/${ispId}`,
        DELIVERY_VANS_AUTOCOMPLETE: (filters) => `/isp-service/admin/delivery-vans/autocomplete?${queryString.stringify(Object.assign({}, filters))}`,
        DELIVERY_VAN_TEMPLATES: () => '/isp-service/admin/availability/template',
        DELIVERY_VAN_TEMPLATES_BY_VAN_ID: (deliveryVanId) => `/isp-service/admin/availability/template/${deliveryVanId}`,
        DELIVERY_VAN_UNAVAILABILIES: () => '/isp-service/admin/unavailability',
        DELIVERY_VAN_UNAVAILABILIES_BY_VAN_ID: (deliveryVanId) => `/isp-service/admin/unavailability/${deliveryVanId}`,
        SCHEDULING_AVAILABILITY: (query) => `/isp-service/admin/scheduling/availability?${queryString.stringify(query, { arrayFormat: 'comma' })}`,
        SCHEDULING_MECHANICS: (query) => `/isp-service/admin/scheduling/vans-mechanics?${queryString.stringify(query)}`,
        OPTIMIZATION_RUNS: () => '/isp-service/admin/optimization-run',
        OPTIMIZATION_RUN_BY_ID: (optimizationRunId) => `/isp-service/admin/optimization-run/${optimizationRunId}`,
        ADMIN_USERS: (ispOwnerId) => `/isp-service/admin/users/${ispOwnerId !== null && ispOwnerId !== void 0 ? ispOwnerId : ''}`,
        ADMIN_USER_INFO: (userId) => `/isp-service/admin/users/${userId !== null && userId !== void 0 ? userId : ''}/info`,
        USERS: () => '/isp-service/users',
        USERS_TECHNICIANS: (userId) => `/isp-service/admin/users/technicians/${userId !== null && userId !== void 0 ? userId : ''}`,
        ADMIN_USER_SIGNUP: () => '/isp-service/admin/users/',
        ADMIN_USER_UPDATE: (userId) => `/isp-service/admin/users/${userId}`,
        TECH_USERS: (userId) => `/isp-service/admin/users/technicians/${userId}`,
        USER_PROFILE_PHOTO: (userId) => `/isp-service/admin/users/${userId}/picture`,
        ADMIN_USER_SELF: () => '/isp-service/admin/users/self',
        SKILLS: () => '/isp-service/admin/skills',
        ADD_USER_SKILLS: (userId) => `/isp-service/admin/users/${userId}/skills`,
        DELETE_USER_SKILLS: ({ userId, skillId, }) => `/isp-service/admin/users/${userId}/skills/${skillId}`,
        SKILLS_BY_FILTER: (query) => {
            var _a;
            return `/isp-service/admin/skills?${query.ids && ((_a = query.ids) === null || _a === void 0 ? void 0 : _a.length) === 0
                ? 'ids='
                : queryString.stringify(query)}`;
        },
        ADD_SKILL: () => '/isp-service/admin/skills',
        UPDATE_SKILL: (id) => `/isp-service/admin/skills/${id}`,
        LABOR_RATE_BY_ID: (id) => `/isp-service/admin/geo-labor-rates/${id}`,
        GENERATE_NEW_MFA: () => '/isp-service/admin/mfa/generate',
        SHIFTS: () => '/isp-service/admin/shift',
        SHIFT_BY_ID: (id) => `/isp-service/admin/shift/${id}`,
        // Skedulo
        SKEDULO_CREATE_JOB: (referenceNum) => `/repair-service/skedulo/jobs/${referenceNum}`,
        // Zendesk
        REQUEST_SALES_CALL: (referenceNum) => `/repair-service/admin/zendesk/${referenceNum}/sales-call-needed`,
        REQUEST_SUBLET: (referenceNum) => `/repair-service/admin/zendesk/${referenceNum}/sublet-requested`,
        REQUEST_MPI_QUOTE_NEEDED: (referenceNum) => `/repair-service/admin/zendesk/${referenceNum}/mpi-quote-needed`,
        GET_ZENDESK_TICKETS: ({ referenceNum, ticketType, }) => `/repair-service/admin/zendesk/tickets/${referenceNum}/type/${ticketType}`,
        DISCOUNT_REASON_TYPES: () => '/repair-service/admin/discounts/discount-reason-types',
        // Recommended Repairs
        RECOMMENDED_REPAIRS: (referenceNum) => `/repair-service/repair-requests/${referenceNum}/recommended-repairs`,
        INTERNAL_REPAIRS: (referenceNum) => `/repair-service/repair-requests/${referenceNum}/internal-repairs`,
        REJECT_RECOMMENDED_REPAIR: (suggestedRepairId) => `/repair-service/repair-requests/rejected-repairs/${suggestedRepairId}`,
        ACCEPT_RECOMMENDED_REPAIR: () => '/repair-service/repair-requests/accepted-repairs',
        UNDO_REJECTED_RECOMMENDED_REPAIR: (suggestedRepairId) => `/repair-service/repair-requests/recommended-repairs/${suggestedRepairId}/undo`,
        FLEETS: () => '/consumer-service/admin/fleet',
        STATIONS: () => '/consumer-service/admin/stations',
        FLEET_BY_ID: (fleetId) => `/consumer-service/admin/fleet/${fleetId}`,
        FLEET_CARS_BY_ID: (fleetId) => `/consumer-service/admin/fleet/${fleetId}/cars`,
        ALL_FLEET_CARS_BY_ID: (fleetId) => `/consumer-service/admin/fleet/${fleetId}/all-cars`,
        FLEET_CAR: (fleetId) => `/consumer-service/admin/fleet/${fleetId}/car`,
        FLEET_CAR_BY_ID: ({ fleetId, carId }) => `/consumer-service/admin/fleet/${fleetId}/car/${carId}`,
        FLEET_CAR_STATUS_BY_ID: ({ fleetId, carId, status, }) => `/consumer-service/admin/fleet/${fleetId}/car/${carId}/${status}`,
        FLEET_REPAIR_REQUESTS: (fleetId) => `/repair-service/admin/fleet/${fleetId}/repair-requests`,
        FLEET_INVOICES: (fleetId) => `/repair-service/admin/fleet/${fleetId}/invoices`,
        FMC_BY_ID: (id) => `/consumer-service/admin/fleet/management/${id}`,
        FMCS: () => '/consumer-service/admin/fleet/management',
        FLEET_FMCS: () => '/consumer-service/admin/fleet/fmc',
        FLEET_PROCESS_ELEMENT_PM_CSV: (fleetId) => `/consumer-service/admin/fleet/${fleetId}/process-element-pm-csv`,
        FLEET_PROCESS_LEASE_PLAN_PM_CSV: (fleetId) => `/consumer-service/admin/fleet/${fleetId}/process-lease-plan-pm-csv`,
        FLEET_ACCOUNT_OWNERS: () => '/consumer-service/admin/fleet-account-owner',
        GET_FLEET_ACCOUNT_OWNERS: (fleetId) => `/consumer-service/admin/fleet-account-owner/${fleetId}`,
        PART_DISTRIBUTOR_WITH_LOCATIONS: () => '/pricing-service/admin/part-distributors?withLocation=true',
        FAIR_PRICE: () => '/pricing-service/v2/fair/price',
        SCHEDULE_REPAIR: () => '/repair-service/repair-requests/schedule-repair',
        SCHEDULE_APPOINTMENT: (referenceNum) => `/repair-service/admin/repair-requests/${referenceNum}/schedule-appointment`,
        SCHEDULE_APPOINTMENT_VISIT: (visitRefNum) => `/repair-service/admin/visits/${visitRefNum}/repair-requests`,
        SCHEDULE_APPOINTMENT_VISIT_FROM_RECOMMENDED_REPAIRS: (visitRefNum) => `/repair-service/admin/visits/${visitRefNum}/repair-requests/recommended-repairs`,
        SCHEDULE_FUTURE_APPOINTMENT: (referenceNum) => `/repair-service/admin/repair-requests/${referenceNum}/schedule-future-appointment`,
        SCHEDULE_QUICK_RO: () => '/repair-service/admin/repair-requests/schedule-ro',
        // SUBLETS
        INVOICE_SUBLET: ({ repairId }) => `/repair-service/admin/invoice/suggested-repair/${repairId}/sublet`,
        INVOICE_CUSTOM_SUBLET: ({ customRequestId }) => `/repair-service/admin/invoice/custom-request/${customRequestId}/sublet`,
        SUBLET: () => '/repair-service/admin/sublets',
        SUBLET_BY_ID: ({ subletId }) => `/repair-service/admin/sublets/${subletId}`,
        SUBLET_REPAIR_ITEMS: ({ subletId }) => `/repair-service/admin/sublets/${subletId}/repair-items`,
        // TASKS
        TASK_BY_ID: ({ taskId }) => `/repair-service/valet-tasks/${taskId}`,
        // WORK ORDERS
        WORK_ORDERS_DASHBOARD: () => '/repair-service/admin/work-orders/dashboard-search',
        WORK_ORDERS_NEEDS_ATTENTION: () => '/repair-service/admin/work-orders/needs-attention',
        WORK_ORDERS_DASHBOARD_COUNT: ({ displayedDate, }) => `/repair-service/admin/work-orders/completed-orders-count/${displayedDate}`,
        WORK_ORDERS_DASHBOARD_ASSIGNED_ADVISORS: ({ displayedDate, }) => `/repair-service/admin/work-orders/assigned-admins/${displayedDate}`,
        WORK_ORDER: () => '/repair-service/admin/work-orders',
        WORK_ORDER_BY_ID: ({ workOrderId }) => `/repair-service/admin/work-orders/${workOrderId}`,
        WORK_ORDER_SUBLETS: ({ workOrderId }) => `/repair-service/admin/work-orders/${workOrderId}/sublets`,
        LOAD_REQUEST_SNAPSHOT: ({ referenceNum }) => `/repair-service/repair-requests/${referenceNum}/snapshots`,
        CAPTURE_REQUEST_SNAPSHOT: ({ referenceNum }) => `/repair-service/repair-requests/${referenceNum}/snapshots/capture`,
        RESPONSIBLE_PARTIES_BY_CONSUMER_ID: (consumerId) => `/repair-service/admin/responsible-parties/by-consumer-id/${consumerId}`,
        RESPONSIBLE_PARTIES_BY_FLEET_ID: (fleetId) => `/repair-service/admin/responsible-parties/by-fleet-id/${fleetId}`,
        RESPONSIBLE_PARTY_TYPES: () => '/repair-service/admin/responsible-parties/types?withNonEntityResponsibleParties=true',
        RESPONSIBLE_PARTIES_BY_TYPE: ({ responsiblePartyTypeId, }) => `/repair-service/admin/responsible-parties/by-type?responsiblePartyTypeId=${responsiblePartyTypeId}`,
        WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATIONS: (params) => `/repair-service/admin/work-order-responsible-party-authorizations${params ? `?${queryString.stringify(params)}` : ''}`,
        WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION_BY_ID: (workOrderResponsiblePartyAuthorizationId) => `/repair-service/admin/work-order-responsible-party-authorizations/${workOrderResponsiblePartyAuthorizationId}`,
        WORK_ORDER_ITEM_RESPONSIBLE_PARTIES: (args) => `/repair-service/admin/work-order-item-responsible-parties${args ? `?${queryString.stringify(args)}` : ''}`,
        CAR_PHOTO: ({ carId, type }) => `/repair-service/admin/car-photo?carId=${carId}&type=${type}`,
        EXTRA_TIME_REQUEST_REASONS: (isVisit) => `/repair-service/admin/stopwatch/reasons${isVisit ? '?visits=true' : ''}`,
        VISITS_STOPWATCH: (refNum) => `/repair-service/admin/visits/stopwatch/${refNum}`,
        ADD_VISIT_STOPWATCH_ADDITIONAL_TIME: (refNum) => `/repair-service/admin/visits/${refNum}/additional-time`,
        TOGGLE_VISIT_LOCK: ({ visitRefNum, lock, }) => `/repair-service/admin/visits/${visitRefNum}/${lock ? 'lock' : 'unlock'}`,
        UPDATE_REGION_DEFAULT_HOME_STORES: (regionId) => `/isp-service/admin/regions/${regionId}/homeStores`,
        UPDATE_SERVICE_AREA_HOME_STORES: (serviceAreaId) => `/isp-service/admin/service-areas/${serviceAreaId}/push-default-home-stores`,
        WRONG_PART: () => '/repair-service/admin/wrong-part',
        TIMELINE_JOBS: () => '/schedule-service/admin/repair-jobs/timelines/regions/search',
        TIMELINE_TERRITORIES: () => '/schedule-service/admin/regions/metros/territories',
        UPDATE_TIMELINE_JOB_VAN: () => '/repair-service/repair-requests/assign-new-resource',
        DAILY_OPS_CONTACTS: () => '/repair-service/admin/visits/daily-ops-contact',
        CUSTOM_CONFIG_BY_NAME: () => '/repair-service/admin/config/by-name',
        SWIMLANES_REQUEST_INFO: (referenceNumber) => `/repair-service/repair-requests/${referenceNumber}/swimlane`,
        CONSUMER_CREATE_FIREBASE_USER: () => '/consumer-service/admin/consumer/create-firebase-user',
        GET_VISIT_AUDIT_LOG: ({ id, from, size, }) => `/search-service/audit/db/servus_repair/visit/${id}/changelog?from=${from}&size=${size}`,
        GET_ADDRESS_AUDIT_LOG: ({ id, from, size, }) => `/search-service/audit/db/servus_repair/address/${id}/changelog?from=${from}&size=${size}`,
        GET_FLEET_RESPONSIBLE_PARTIES: (fleetId) => `/consumer-service/admin/fleet/${fleetId}/responsible-parties`,
        GET_FLEET_RESPONSIBLE_PARTIES_BY_CAR: ({ fleetId, carId, }) => `/consumer-service/admin/fleet/${fleetId}/responsible-parties/by-car/${carId}`,
        UPDATE_FLEET_VEHICLE_RESPONSIBLE_PARTIES: (fleetId) => `/consumer-service/admin/fleet/${fleetId}/responsible-parties/car`,
        UPDATE_FLEET_RESPONSIBLE_PARTIES: (fleetId) => `/consumer-service/admin/fleet/${fleetId}/responsible-parties`,
        GET_WORKBENCH_INVOICES: (params) => `/repair-service/admin/workbench/dashboard/search${params ? `?${queryString.stringify(params)}` : ''}`,
        GET_INVOICE_REQUEST: (id) => `/repair-service/admin/invoice-requests/${id}`,
        UPDATE_FLEET_RESPONSIBLE_PARTY_BILLING_CONTACTS: (responsiblePartyId) => `/repair-service/admin/responsible-parties/${responsiblePartyId}/billing-contacts`,
        SUBMIT_INVOICE_REQUESTS: () => '/repair-service/admin/invoice-requests',
        DEFAULT_EMAIL_BODY: () => '/repair-service/admin/invoice-requests/default-email-body',
        INVOICE_REQUESTS: (params) => `/repair-service/admin/invoice-requests${params ? `?${queryString.stringify(params)}` : ''}`,
        ACKNOWLEDGE_INVOICE_REQUEST: (invoiceRequestId) => `/repair-service/admin/invoice-requests/${invoiceRequestId}/acknowledge`,
        CANCEL_INVOICE_REQUEST: (invoiceRequestId) => `/repair-service/admin/invoice-requests/${invoiceRequestId}/cancel`,
        SEARCH_B2B_RESPONSIBLE_PARTIES: (search) => `/repair-service/admin/responsible-parties/suggest-b2bs?input=${search}`,
    },
    AUTOSELECTION: {
        YEARS: () => '/autoselection-service/admin/',
        MAKES: ({ year }) => `/autoselection-service/admin/${year}`,
        MODELS: ({ year, makeId }) => `/autoselection-service/v2/${year}/${makeId}`,
        DESCRIPTION_BY_VIN: ({ vin }) => `/autoselection-service/v2/vin/${vin}`,
        DESCRIPTION_BY_PLATE: ({ plate, state, }) => `/autoselection-service/plate/${state}/${plate}`,
    },
    PRICING: {
        REPAIR_TAGS: (repairIds) => `/pricing-service/products/skills?${repairIds
            .map((x) => `productId=${x}`)
            .join('&')}`,
    },
    SYSTEM: {
        LOAD_REQUEST_HISTORY: (id) => `/search-service/audit/request-history/${id}`,
        RESPONSIBLE_PARTIES: () => {
            return '/repair-service/admin/responsible-parties/pages';
        },
        RESPONSIBLE_PARTIES_CREATE: () => {
            return '/repair-service/admin/responsible-parties';
        },
        RESPONSIBLE_PARTIES_EDIT: (id) => {
            return `/repair-service/admin/responsible-parties/${id}`;
        },
        RESPONSIBLE_PARTY_TYPES: () => {
            return '/repair-service/admin/responsible-parties/all-types';
        },
    },
    TECHNICIAN: {
        ONSITE_WORKFLOW_WORK_ORDER: (refNumber) => `/repair-service/admin/technicians/onsite-workflow/${refNumber}`,
        ONSITE_WORKFLOW_TASK: ({ refNumber, taskId, }) => `/repair-service/admin/technicians/onsite-workflow/${refNumber}/tasks/${taskId}`,
        STOPWATCH: (refNumber) => `/repair-service/admin/technicians/stopwatch/${refNumber}`,
    },
    AUTHORIZATION_EVENT: () => '/repair-service/admin/segment/events/authorization-started',
    REQUEST_LOCK: {
        GET_LOCK: (repairRequestId) => `/repair-service/admin/ro-lock/${repairRequestId}`,
        UPDATE_LOCK: () => '/repair-service/admin/ro-lock',
    },
};
const AUTH_URLS = {
    AUTH: {
        LOGIN: () => '/login',
        REFRESH_TOKEN: () => '/refresh-token',
        VERIFY_MFA: () => '/mfa/verify',
        RESEND_MFA: () => '/mfa/resend',
    },
};
const GRAPHQL_URLS = {
    GRAPHQL: () => '',
};
const API_PROXY = new Proxy(API_URLS, PrefixHandler(CONFIG.CLIENT_API_URL));
const AUTH_PROXY = new Proxy(AUTH_URLS, PrefixHandler(CONFIG.CLIENT_AUTH_URL));
const GRAPHQL_PROXY = new Proxy(GRAPHQL_URLS, PrefixHandler(CONFIG.CLIENT_GRAPHQL_URL));
export default Object.assign(Object.assign(Object.assign({}, API_PROXY), AUTH_PROXY), GRAPHQL_PROXY);
