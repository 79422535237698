import { Typography, Grid } from 'src/components/mui';
import React from 'react';
import { SlidePageFieldsContainer } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { DeleteButton, DestructiveButton } from 'src/components/buttons';
const ZipCodeSection = ({ index, value, onTransfer, onRemove, isLast, }) => (<SlidePageFieldsContainer isLast={isLast} key={index}>
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <DeleteButton onClick={() => onRemove(value.name)}/>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="h6">
          <span>{value.name}</span>
        </Typography>
        <span style={{ fontSize: 'small' }}>
          Current Service Area:{' '}
          {value.activeServiceAreas.map((x) => x.name).join(', ')}
        </span>
      </Grid>
      <Grid item xs={3}>
        <DestructiveButton onClick={() => onTransfer(value)}>
          Transfer
        </DestructiveButton>
      </Grid>
    </Grid>
  </SlidePageFieldsContainer>);
export const ZipCodesTransfer = ({ zipCodes, onTransfer, onRemove, message, }) => {
    return (<SlidePageFieldsContainer>
      <Typography color="error">{message}</Typography>
      {zipCodes.map((x, index) => (<ZipCodeSection key={x.id} index={index} value={x} onTransfer={onTransfer} onRemove={onRemove} isLast={index === zipCodes.length - 1}/>))}
    </SlidePageFieldsContainer>);
};
