import styled from 'styled-components';
export const DeliveryContainer = styled.div `
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
`;
export const DeliveryContainerItem = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
