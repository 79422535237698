import React from 'react';
import { Link } from 'react-router-dom';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
const DashboardListItem = ({ id, name, contactName, station, fleetSize, parentName, }) => {
    return (<SimpleTableRow data={[
            <Link to={`/admin/fleets/${id}`}>
          <div>{name}</div>
        </Link>,
            station,
            contactName,
            fleetSize,
            parentName,
        ]}/>);
};
export default DashboardListItem;
