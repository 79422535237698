import { FleetVisitStatus, MpiStatus } from 'src/FleetVisitsApp/models/enums';
import { colors } from 'src/styles/theme';
export const FLEET_VISIT_STATUS_COLORS = {
    [FleetVisitStatus.NEW]: colors.GREEN,
    [FleetVisitStatus.OPEN]: colors.BLUE,
    [FleetVisitStatus.PENDING]: colors.GRAY_76,
    [FleetVisitStatus.CONFIRMED]: colors.YELLOW_E1,
    [FleetVisitStatus.IN_PROGRESS]: colors.BLUE,
    [FleetVisitStatus.COMPLETED]: colors.GREEN,
    [FleetVisitStatus.CLOSED]: colors.GREEN,
    [FleetVisitStatus.CANCELED]: colors.RED,
};
export const FLEET_VISIT_STATUS_COLORS_TEXT = {
    [FleetVisitStatus.NEW]: colors.white,
    [FleetVisitStatus.OPEN]: colors.white,
    [FleetVisitStatus.PENDING]: colors.white,
    [FleetVisitStatus.CONFIRMED]: colors.GRAY_76,
    [FleetVisitStatus.IN_PROGRESS]: colors.white,
    [FleetVisitStatus.COMPLETED]: colors.white,
    [FleetVisitStatus.CLOSED]: colors.white,
    [FleetVisitStatus.CANCELED]: colors.white,
};
export const MPI_STATUS_COLORS = {
    [MpiStatus.PENDING]: colors.GRAY_76,
    [MpiStatus.PERFORMED]: colors.YELLOW_E1,
    [MpiStatus.COMPLETED]: colors.GREEN,
    [MpiStatus.NOT_PERFORMED]: colors.RED,
};
export const MPI_STATUS_COLORS_TEXT = {
    [MpiStatus.PENDING]: colors.white,
    [MpiStatus.PERFORMED]: colors.GRAY_76,
    [MpiStatus.COMPLETED]: colors.white,
    [MpiStatus.NOT_PERFORMED]: colors.white,
};
