var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartnersAPIData, selectPartnersAPIPagination, selectPartnersAPISort, } from 'src/PartnersAPIApp/modules/partnersApi/selectors';
import { selectMarketingSources } from 'src/AdminApp/modules/lookups/selectors';
import { selectPartnersData } from 'src/PartnersApp/modules/partners/selectors';
import { getPartnersAPIDashboard, resetAddAPIKey, setPartnerAPIKeySort, } from 'src/PartnersAPIApp/modules/partnersApi/actions';
import { PAGE_SIZE } from 'src/PartnersAPIApp/modules/api/constants';
const useContent = () => {
    var _a;
    const dispatch = useDispatch();
    const [editPartner, setEditPartner] = useState({
        open: false,
        apiKey: undefined,
    });
    const [loading, setLoading] = useState(false);
    const apiKeys = useSelector(selectPartnersAPIData);
    const marketingSources = useSelector(selectMarketingSources);
    const partners = useSelector(selectPartnersData);
    const pagination = useSelector(selectPartnersAPIPagination);
    const sort = useSelector(selectPartnersAPISort);
    const [sortName, sortDirection] = (_a = sort.split(',')) !== null && _a !== void 0 ? _a : ['partnerId', 'asc'];
    useEffect(() => {
        requestPartnersAPI({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getRequestParams = ({ newPage, newSize, newSort, }) => {
        var _a, _b;
        const params = {
            page: (_a = newPage !== null && newPage !== void 0 ? newPage : pagination === null || pagination === void 0 ? void 0 : pagination.page) !== null && _a !== void 0 ? _a : 0,
            size: (_b = newSize !== null && newSize !== void 0 ? newSize : pagination === null || pagination === void 0 ? void 0 : pagination.size) !== null && _b !== void 0 ? _b : PAGE_SIZE,
            sort: newSort !== null && newSort !== void 0 ? newSort : sort,
        };
        return params;
    };
    const requestPartnersAPI = (params) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield dispatch(getPartnersAPIDashboard(getRequestParams(params)));
        setLoading(false);
    });
    const handlePageSizeChange = (e) => {
        requestPartnersAPI({ newSize: e.target.value, newPage: 0 });
    };
    const handlePageChange = (e, page) => {
        requestPartnersAPI({ newPage: page });
    };
    const handleSortChange = (item) => {
        let sortItem = null;
        if (sortName === item.field && sortDirection === 'asc') {
            sortItem = [item.field, 'desc'].join(',');
        }
        else {
            sortItem = [item.field, 'asc'].join(',');
        }
        dispatch(setPartnerAPIKeySort(sortItem));
        requestPartnersAPI({ newSort: sortItem });
    };
    const handleOnClosePartnerAPIDrawer = () => {
        setEditPartner({ open: false, apiKey: undefined });
        dispatch(resetAddAPIKey());
    };
    return {
        dispatch,
        editPartner,
        setEditPartner,
        loading,
        setLoading,
        apiKeys,
        marketingSources,
        partners,
        pagination,
        sort,
        sortName,
        sortDirection,
        getRequestParams,
        requestPartnersAPI,
        handlePageSizeChange,
        handlePageChange,
        handleSortChange,
        handleOnClosePartnerAPIDrawer,
    };
};
export default useContent;
