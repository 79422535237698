import React from 'react';
import styled from 'styled-components';
import { TableRow, TableCell } from 'src/components/mui';
import { tableRowMixin } from 'src/components/dashboard/SimpleTable/SimpleTable.style';
import { colors } from 'src/styles/theme';
const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  ${tableRowMixin}
`;
export const Cell = styled(TableCell) `
  font-weight: 300;
  font-size: 15px;
  color: ${colors.GRAY_3F};

  ${({ $strikeThrough }) => $strikeThrough && 'text-decoration: line-through;'}
`;
export const SimpleTableRow = ({ data, cellProps = {}, }) => {
    return (<StyledTableRow>
      {data.map((d, i) => (<Cell key={i} {...cellProps}>
          {d}
        </Cell>))}
    </StyledTableRow>);
};
export default SimpleTableRow;
