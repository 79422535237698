import { Button, Grid } from 'src/components/mui';
import styled from 'styled-components';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from 'src/components/mui/icons';
import { colors, theme } from 'src/styles/theme';
export const DashboardHeader = styled(Grid) `
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  padding-bottom: 20px;
  align-items: stretch;
  flex-wrap: wrap;
`;
export const ButtonContainer = styled(Grid) `
  margin: 16px 0px 8px 0px;
  ${theme.breakpoints.up('sm')} {
    padding-left: 24px;
  }
`;
export const BigButton = styled(Button) `
  font-size: 16px;
  height: 100%;
`;
export const FmcItemHeader = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: Interstate;
  font-weight: normal;
`;
export const FmcItemSubHeader = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-family: Interstate;
  font-weight: normal;
`;
export const RemoveButton = styled(Button) `
  color: ${colors.error};
  font-family: Interstate;
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline !important;
`;
export const FmcDropdown = styled.div `
  border-bottom: 1px solid ${colors.lightGrey};
`;
export const FmcDropdownSummary = styled.div `
  width: 100%;
  text-align: left;
  display: block;
  padding: 12px 8px;
  position: relative;
`;
export const FmcDropdownContent = styled.div `
  padding: 4px 12px 12px;
  ${theme.breakpoints.down('sm')} {
    padding-left: 0;
  }
`;
export const DropdownIcon = styled(KeyboardArrowDownIcon) `
  right: 0;
  top: 24px;
  color: ${colors.iconGrey};
  transform: rotate(${({ $opened }) => ($opened ? '180deg' : '0deg')});
  transition: 0.3s ease transform;
`;
export const FmcContactWrapper = styled.div `
  display: flex;
  align-items: center;
  font-family: Interstate;
  font-size: 14px;
  font-weight: 300;
`;
export const FmcVehicleWrapper = styled.div `
  display: flex;
  align-items: center;
  font-family: Interstate;
  font-size: 16px;
  font-weight: normal;
  padding-bottom: 12px;
  justify-content: space-between;
`;
export const VehiclesDropdownFooter = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid ${colors.lightGrey};
`;
export const VehiclesDropdownFooterOption = styled.div `
  display: flex;
  flex-direction: row;

  .resetVehicles {
    font-weight: 500;
    color: ${colors.GRAY_3F};
  }

  .applyVehicles {
    font-weight: 500;
    color: ${colors.secondary};
  }

  li {
    font-size: 14px;
  }
`;
export const AddVehicleButton = styled(Button) `
  color: ${colors.secondary};

  font-size: 14px;
  font-family: Interstate;
  font-weight: normal;
  svg {
    padding: 2px;
    margin-right: 4px;
  }
`;
export const DeleteVehicleButton = styled(Button) `
  padding: 0px 0px;
  svg {
    padding: 4px;
    color: ${colors.error};
  }
`;
export const ShowVehiclesButton = styled(Button) `
  color: ${colors.secondary};
  font-family: Interstate;
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline !important;
  padding-left: 8px;
`;
