import React from 'react';
import { Button, Grid } from 'src/components/mui';
import { DisplayMode } from '../models';
import { ValueRenderer } from '../renderers';
const Snapshots = ({ data, schema, page = 0, mode = DisplayMode.PRETTY, setPage = () => { }, }) => {
    const renderPretty = (props) => (<SnapshotRenderer data={props} schema={schema}/>);
    const renderRaw = (props) => (<pre>{props && JSON.stringify(props, undefined, 2)}</pre>);
    return data && data.snapshot ? (<>
      <Grid container>
        <Grid item md={12}>
          <div style={{ fontWeight: 'bold' }}>
            Snapshots: {data.table} / {data.id}{' '}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <Pagination data={data} currentPage={page} setPage={setPage}/>
          {DisplayMode.PRETTY === mode && renderPretty(data)}
          {DisplayMode.RAW === mode && renderRaw(data.snapshot)}
        </Grid>
      </Grid>
    </>) : null;
};
export default Snapshots;
const Pagination = ({ data, currentPage, setPage }) => {
    const prevPage = currentPage - 1;
    const nextPage = currentPage + 1;
    return data ? (<>
      <Button onClick={() => setPage(prevPage)} value={prevPage} disabled={prevPage < 0}>
        &lt;&lt;
      </Button>
      <span>Page #{currentPage}</span>
      <Button onClick={() => setPage(nextPage)} value={nextPage} disabled={nextPage > data.size - 1}>
        &gt;&gt;
      </Button>
    </>) : null;
};
const SnapshotRenderer = ({ data, schema }) => {
    var _a;
    const changedProperties = (_a = data.changes) === null || _a === void 0 ? void 0 : _a.map((change) => change.field);
    const keys = Object.keys(data.snapshot).sort();
    return data ? (<table style={{ width: '100%' }}>
      <thead>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {keys.map((key) => (<tr key={key} style={{ color: changedProperties.includes(key) ? 'red' : '' }}>
            <td>{key}</td>
            <ValueRenderer field={key} value={data.snapshot[key]} schema={schema}/>
          </tr>))}
      </tbody>
    </table>) : null;
};
