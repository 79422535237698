var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid, TableBody, TableCell, TableHead, TableRow, Typography, } from 'src/components/mui';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useSelector } from 'react-redux';
import AppointmentPassedModal from 'src/AdminApp/containers/appointment/AppointmentModule/AppointmentPassedModal';
import useAppointmentModule from 'src/AdminApp/containers/appointment/AppointmentModule/useAppointmentModule';
import { formIspTitle, formMechanicTitle, } from 'src/AdminApp/containers/appointment/AppointmentModule/utils';
import CancelAppointmentDrawer from 'src/AdminApp/containers/requests/StateTracker/drawers/CancelAppointmentDrawer';
import { selectRequestIsLocked } from 'src/AdminApp/modules/requestLock/selectors';
import { getTimezoneByMapBox } from 'src/clients/mapboxClient';
import { CollapsibleBlock } from 'src/components/layout';
import { selectAuthorizedRepairRequestStatusEdit } from 'src/modules/auth/selectors';
import { constructAddressObj } from 'src/utils/address-utils';
import AppointmentCard from './AppointmentCard';
import AppointmentRow from './AppointmentRow';
import MapAndDirections from './MapAndDirections';
import RequestedServiceLocation from './RequestedServiceLocation';
import RequestedTimes from './RequestedTimes';
import { AppointmentTable } from './styledComponents';
const TableHeader = ({ appointment }) => {
    const deliveryChannel = appointment === null || appointment === void 0 ? void 0 : appointment.deliveryChannel;
    const ispTitle = formIspTitle(deliveryChannel);
    const mechanicTitle = formMechanicTitle(deliveryChannel);
    return (<TableHead>
      <TableRow>
        <TableCell>Date &amp; Time</TableCell>
        <TableCell>Links</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Parts Status</TableCell>
        <TableCell>{ispTitle}</TableCell>
        <TableCell>{mechanicTitle}</TableCell>
      </TableRow>
    </TableHead>);
};
const Appointment = ({ repairRequest, zip, address, origin, destination, vehicle, referenceNum, orderId, workOrderId, appointments, consumerPreferredTimes, repairsString, deliveryChannel = '', hasInvoice = false, }) => {
    const { isMobile, dateConfirmModalState, updateDate, closeConfirmModal, onPastDateConfirm, onEditDateConfirmClick, onUpdateAppointment, onUpdatePartsStatus, onUpdateAppointmentStatus, onSubmitCancelAppointmentDrawer, } = useAppointmentModule(repairRequest, zip, address, origin, destination, vehicle, referenceNum, workOrderId, appointments, orderId, consumerPreferredTimes, repairsString, deliveryChannel, hasInvoice);
    const [isEditing] = useState(false);
    const [isCancelAppointmentDrawerOpen, setIsCancelAppointmentDrawerOpen] = useState(false);
    const [mapOrigin, setMapOrigin] = useState();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!(origin === null || origin === void 0 ? void 0 : origin.lat) && zip) {
                const parsed = yield geocodeByAddress(zip);
                const addressObj = constructAddressObj(address, parsed);
                addressObj.timezone = yield getTimezoneByMapBox(addressObj.latitude, addressObj.longitude);
                setMapOrigin(Object.assign(Object.assign(Object.assign({}, origin), addressObj), { lat: addressObj === null || addressObj === void 0 ? void 0 : addressObj.latitude, lng: addressObj === null || addressObj === void 0 ? void 0 : addressObj.longitude }));
            }
            else {
                setMapOrigin(origin);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);
    const canEditStatus = useSelector(selectAuthorizedRepairRequestStatusEdit);
    const requestLocked = useSelector(selectRequestIsLocked);
    const sortedAppointments = sortBy(appointments, (a) => a.created).reverse();
    return (<CollapsibleBlock hashName="appointment" header={<Typography variant="h5" gutterBottom>
          Appointment
        </Typography>} defaultOpen>
      {isMobile ? (<div style={{ marginBottom: '24px' }}>
          {sortedAppointments.map((appointment, index) => (<AppointmentCard key={appointment.id} partsStatus={repairRequest.partsStatus} onDateUpdate={updateDate} onAppointmentUpdate={onUpdateAppointment} appointmentIndex={index} {...appointment}/>))}
        </div>) : (<AppointmentTable>
          <TableHeader appointment={appointments[0]}/>
          <TableBody>
            {sortedAppointments.map((appointment) => (<AppointmentRow canEditStatus={canEditStatus} isEditing={isEditing} key={appointment.id} partsStatus={repairRequest.partsStatus} onEditDateConfirmClick={onEditDateConfirmClick} onAppointmentCancel={() => setIsCancelAppointmentDrawerOpen(true)} onAppointmentUpdate={onUpdateAppointment} onAppointmentStatusUpdate={onUpdateAppointmentStatus} onPartsStatusUpdate={onUpdatePartsStatus} {...appointment}/>))}
          </TableBody>
        </AppointmentTable>)}

      <Grid container>
        <Grid item xs={12} md={12} lg={6}>
          <RequestedTimes times={consumerPreferredTimes}/>
          <RequestedServiceLocation isEditable={!requestLocked} address={address || { zip }} workOrder={repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.workOrder}/>
        </Grid>
        <Grid item md={12} xs={12}>
          <MapAndDirections address={address || { zip }} origin={mapOrigin} destination={destination} deliveryChannel={deliveryChannel}/>
        </Grid>
      </Grid>

      <AppointmentPassedModal open={dateConfirmModalState.open} onClose={closeConfirmModal} onSubmit={onPastDateConfirm}/>
      <CancelAppointmentDrawer open={isCancelAppointmentDrawerOpen} onClose={() => setIsCancelAppointmentDrawerOpen(false)} referenceNum={referenceNum} onSubmit={(values) => onSubmitCancelAppointmentDrawer(values)}/>
    </CollapsibleBlock>);
};
export default Appointment;
