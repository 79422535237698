import React, { useEffect, useState } from 'react';
import SimpleChip, { ChipLogo } from 'src/components/SimpleChip';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
import { useHistory } from 'react-router-dom';
const TagChips = ({ tags }) => {
    const history = useHistory();
    const [tagsToShow, setTagsToShow] = useState([]);
    useEffect(() => {
        const newTags = [];
        tags === null || tags === void 0 ? void 0 : tags.forEach((tag) => {
            let newTag = {
                chipColor: colors.grey,
                text: '',
            };
            const matchWarrantyClaim = tag.match(/warranty-claim\/origin:(.+)/);
            if (matchWarrantyClaim) {
                const extractedRefNum = matchWarrantyClaim[1];
                const modifiedTag = tag.replace(`warranty-claim/origin:${extractedRefNum}`, `AMS Warranty - ${extractedRefNum}`);
                newTag = {
                    chipColor: colors.blue,
                    text: modifiedTag,
                    link: `/admin/repairs/${extractedRefNum}`,
                };
                newTags.push(newTag);
            }
        });
        setTagsToShow([...newTags]);
    }, [tags]);
    return !tags || tags.length === 0 ? null : (<div style={{ marginLeft: '5px' }}>
      {tagsToShow.map((tag, index) => (<SimpleChip key={index} $bgColor={tag.chipColor} clickable={!!(tag === null || tag === void 0 ? void 0 : tag.link)} onClick={() => {
                if (tag.link) {
                    history.push(tag.link);
                }
            }} avatar={<ChipLogo className="CarSubscriptionInfo__icon" src={`${STATIC_URL_BASE}img/svg/ms-logo.svg`} alt="AutoNation Mobile Service logo"/>} label={tag.text} $textColor={colors.white}/>))}
    </div>);
};
export default TagChips;
