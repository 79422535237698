import styled from 'styled-components';
import { colors } from 'src/styles/theme';
const Van = styled.div `
  position: relative;
  display: inline-block;

  * {
    margin: 0;
  }
  img {
    width: 99px;
    height: auto;
    position: absolute;
    margin-left: -40px;
    left: 0;
  }

  .description {
    background-color: rgba(222, 222, 222, 0.25);
    height: 75px;
    border-radius: 24px;
    padding: 10px;
    padding-left: 65px;
    border: solid 1px ${colors.lightGrey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    p {
      font-size: 12px;
      color: ${colors.darkGrey};
    }
    h3 {
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    button {
      padding: 0;
      margin-left: -5px;
      color: ${colors.linkBlue};
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.08px;
      text-decoration: underline;
      text-align: left;
    }

    span {
      font-size: 16px;
      line-height: 1.25;
      letter-spacing: -0.3px;
      color: ${colors.linkBlue};
    }
  }
  @media (max-width: 765px) {
    margin-left: 40px;
  }
`;
const DateButton = styled.button `
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  color: ${colors.default};
  width: 130px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  svg {
    margin-right: 5px;
  }
  @media (min-width: 768px) {
    position: relative;
    top: 58px;
    right: -33px;
    background: ${colors.white};
    z-index: 1;
    padding: 10px 15px;
    padding-right: 45px;
  }
`;
const Loader = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: rgba(255, 255, 255, 0.4);
`;
const HubInfoContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 35px 14px 24px;
  border-radius: 24px;
  border: solid 1px ${colors.lightGrey};
  background-color: #dedede40;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;

  .hub-title {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: ${colors.darkGrey};
    margin-bottom: 2px;
  }

  .hub-address {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.3px;
    color: ${colors.secondary};
  }
`;
export { Van, DateButton, Loader, HubInfoContainer };
