import { handleActions } from 'redux-actions';
import { findIndex, filter, map, union } from 'lodash';
import { fetched } from 'src/utils/data-utils';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    dashboard: {
        selectedDate: null,
        params: {
            filters: {
                createdTo: undefined,
                createdFrom: undefined,
                dueDateFrom: undefined,
                dueDateTo: undefined,
                subletAssignedTo: undefined,
                unassigned: undefined,
                valetTaskStatus: undefined,
                displayedDate: undefined,
                geography: {},
            },
        },
        content: [],
        needsAttention: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: [],
        },
        advisors: {},
    },
    byRequestRefNum: {},
    byId: {},
    allWorkOrderIds: [],
};
const setFiltersSuccess = (state, action) => {
    const filters = action.payload;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params: {
                filters,
            } }) });
};
const successAssignedAdvisorsReducer = (state, action) => {
    const { displayedDate } = action.meta;
    const advisors = Object.assign(Object.assign({}, state.dashboard.advisors), { [displayedDate]: action.payload });
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { advisors }) });
};
const searchWorkOrdersSuccess = (state, action) => {
    const results = fetched(action.payload);
    const { pageIndex, pageSize } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { pageSize,
                totalElements,
                totalPages, pageNumber: pageIndex }) }) });
};
const getNewWorkOrdersSuccess = (state, action) => {
    const results = action.payload;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { needsAttention: results }) });
};
const searchWorkOrdersCountSuccess = (state, action) => {
    const completedCount = action.payload;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { completedCount }) }) });
};
const setDashboardDate = (state, action) => {
    const { selectedDate } = action.payload;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { selectedDate }) });
};
const successReducer = (state, action) => {
    const workOrder = action.payload;
    const { referenceNum = null, workOrderId = null } = action.meta;
    const thisWorkOrderId = workOrderId || workOrder.id;
    const allWorkOrderIds = union(state.allWorkOrderIds, thisWorkOrderId);
    const byRequestRefNum = Object.assign(Object.assign({}, state.byRequestRefNum), (!!referenceNum && {
        [referenceNum]: Object.assign(Object.assign({}, state.byRequestRefNum[referenceNum]), { [thisWorkOrderId]: Object.assign(Object.assign({}, state.byRequestRefNum[referenceNum][thisWorkOrderId]), workOrder) }),
    }));
    const byId = Object.assign(Object.assign({}, state.byId), { [thisWorkOrderId]: Object.assign(Object.assign({}, (state.byId[thisWorkOrderId] || {})), workOrder) });
    return Object.assign(Object.assign({}, state), { byRequestRefNum,
        byId,
        allWorkOrderIds });
};
const successWorkOrdersReducer = (state, action) => {
    var _a;
    const workOrders = action.payload.content;
    const { referenceNum } = action.meta;
    let byRequestRefNum = Object.assign(Object.assign({}, state.byRequestRefNum), { [referenceNum]: Object.assign({}, (_a = state.byRequestRefNum) === null || _a === void 0 ? void 0 : _a.referenceNum) });
    let allWorkOrderIds = Object.assign({}, state.allWorkOrderIds);
    let byId = Object.assign({}, state.byId);
    map(workOrders, (workOrder) => {
        var _a, _b, _c;
        const thisWorkOrderId = workOrder.id;
        allWorkOrderIds = union(state.allWorkOrderIds, thisWorkOrderId);
        byRequestRefNum = Object.assign(Object.assign({}, state.byRequestRefNum), { [referenceNum]: Object.assign(Object.assign({}, (_a = state.byRequestRefNum) === null || _a === void 0 ? void 0 : _a.referenceNum), { [thisWorkOrderId]: Object.assign(Object.assign({}, (_c = (_b = state.byRequestRefNum) === null || _b === void 0 ? void 0 : _b.referenceNum) === null || _c === void 0 ? void 0 : _c.thisWorkOrderId), workOrder) }) });
        byId = Object.assign(Object.assign({}, state.byId), { [thisWorkOrderId]: Object.assign(Object.assign({}, state.byId[thisWorkOrderId]), workOrder) });
    });
    return Object.assign(Object.assign({}, state), { byId,
        byRequestRefNum,
        allWorkOrderIds });
};
const successTaskReducer = (state, action) => {
    const updatedTask = action.payload;
    const { referenceNum, workOrderId, taskId } = action.meta;
    const allTasksByWorkOrder = state.byRequestRefNum[referenceNum][workOrderId].valetTasksTimeline;
    const taskIndex = findIndex(allTasksByWorkOrder, { id: taskId });
    allTasksByWorkOrder[taskIndex] = updatedTask;
    const byRequestRefNum = Object.assign(Object.assign({}, state.byRequestRefNum), { [referenceNum]: Object.assign(Object.assign({}, state.byRequestRefNum[referenceNum]), { [workOrderId]: Object.assign(Object.assign({}, state.byRequestRefNum[referenceNum].workOrderId), { valetTasksTimeline: allTasksByWorkOrder }) }) });
    return Object.assign(Object.assign({}, state), { byRequestRefNum });
};
const successSubletsReducer = (state, action) => {
    const sublets = action.payload;
    const { referenceNum, workOrderId } = action.meta;
    const allWorkOrderIds = union(state.allWorkOrderIds, workOrderId);
    const byRequestRefNum = Object.assign(Object.assign({}, state.byRequestRefNum), { [referenceNum]: Object.assign(Object.assign({}, state.byRequestRefNum[referenceNum]), { [workOrderId]: Object.assign(Object.assign({}, state.byRequestRefNum[referenceNum].workOrderId), { sublets }) }) });
    return Object.assign(Object.assign({}, state), { byRequestRefNum,
        allWorkOrderIds });
};
const successWorkOrderNoteReducer = (state, action) => {
    const note = action.payload;
    const { workOrderId } = action.meta;
    const { content, needsAttention } = state.dashboard;
    let woIndex = findIndex(content, { id: workOrderId });
    if (woIndex > -1) {
        content[woIndex].subletNote = note;
    }
    woIndex = findIndex(needsAttention, { id: workOrderId });
    if (woIndex > -1) {
        needsAttention[woIndex].subletNote = note;
    }
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { content: [...content], needsAttention: [...needsAttention] }) });
};
const successDeleteWorkOrderNoteReducer = (state, action) => {
    const { noteId, workOrderId } = action.meta;
    const { content, needsAttention } = state.dashboard;
    let woIndex = findIndex(content, { id: workOrderId });
    if (woIndex > -1) {
        const { subletNote } = content[woIndex];
        if ((subletNote === null || subletNote === void 0 ? void 0 : subletNote.id) === noteId) {
            delete content[woIndex].subletNote;
        }
    }
    woIndex = findIndex(needsAttention, { id: workOrderId });
    if (woIndex > -1) {
        const { subletNote } = needsAttention[woIndex];
        if ((subletNote === null || subletNote === void 0 ? void 0 : subletNote.id) === noteId) {
            delete needsAttention[woIndex].subletNote;
        }
    }
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { content: [...content], needsAttention: [...needsAttention] }) });
};
const successPromoCodeReducer = (state, action) => {
    const { workOrderId } = action.meta;
    const byId = Object.assign(Object.assign({}, state.byId), { [workOrderId]: Object.assign(Object.assign({}, state.byId[workOrderId]), { promos: action.payload }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successDiscountsReducer = (state, action) => {
    const { workOrderId } = action.meta;
    const allDiscounts = action.payload;
    const promos = filter(allDiscounts, { discountType: 'PROMO_CODE' });
    const credits = filter(allDiscounts, { discountType: 'CANCEL_FEE_CREDIT' });
    const otherDiscounts = filter(allDiscounts, {
        discountType: 'CUSTOM_DISCOUNT',
    });
    const byId = Object.assign(Object.assign({}, state.byId), { [workOrderId]: Object.assign(Object.assign({}, state.byId[workOrderId]), { discountDetails: allDiscounts, promos,
            credits,
            otherDiscounts }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const uploadDocumentsSuccessReducer = (state, action) => {
    const uploadedDocuments = action.payload;
    const { workOrderId } = action.meta;
    const workOrder = state.byId[workOrderId];
    const byId = Object.assign(Object.assign({}, state.byId), { [workOrderId]: Object.assign(Object.assign({}, workOrder), { documents: [...(workOrder.documents || []), ...(uploadedDocuments || [])] }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const deleteDocumentSuccessReducer = (state, action) => {
    const { workOrderId, document } = action.meta;
    const workOrder = state.byId[workOrderId];
    const byId = Object.assign(Object.assign({}, state.byId), { [workOrderId]: Object.assign(Object.assign({}, workOrder), { documents: workOrder.documents
                ? workOrder.documents.filter((doc) => doc.id !== document.id)
                : [] }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const reducers = handleActions({
    [ActionCreators.SET_DASHBOARD_DATE.SUCCESS.type]: setDashboardDate,
    [ActionCreators.CREATE_WORK_ORDER.SUCCESS.type]: successReducer,
    [ActionCreators.GET_WORK_ORDER_SUBLETS.SUCCESS.type]: successSubletsReducer,
    [ActionCreators.SEARCH_WORK_ORDERS.SUCCESS.type]: searchWorkOrdersSuccess,
    [ActionCreators.FIND_WORK_ORDERS.SUCCESS.type]: successWorkOrdersReducer,
    [ActionCreators.GET_WORK_ORDERS_NEW.SUCCESS.type]: getNewWorkOrdersSuccess,
    [ActionCreators.GET_WORK_ORDERS_NEEDS_ATTENTION.SUCCESS.type]: getNewWorkOrdersSuccess,
    [ActionCreators.WORK_ORDER_CREATE_NOTES.SUCCESS.type]: successWorkOrderNoteReducer,
    [ActionCreators.WORK_ORDER_UPDATE_NOTES.SUCCESS.type]: successWorkOrderNoteReducer,
    [ActionCreators.WORK_ORDER_DELETE_NOTES.SUCCESS.type]: successDeleteWorkOrderNoteReducer,
    [ActionCreators.GET_WORK_ORDER.SUCCESS.type]: successReducer,
    [ActionCreators.UPDATE_TASK.SUCCESS.type]: successTaskReducer,
    [ActionCreators.GET_WORK_ORDERS_COUNT.SUCCESS.type]: searchWorkOrdersCountSuccess,
    [ActionCreators.SET_FILTERS.SUCCESS.type]: setFiltersSuccess,
    [ActionCreators.GET_ASSIGNED_ADVISORS.SUCCESS.type]: successAssignedAdvisorsReducer,
    [ActionCreators.PROMO_CODE_GET.SUCCESS.type]: successPromoCodeReducer,
    [ActionCreators.PROMO_CODE_REMOVE.SUCCESS.type]: successPromoCodeReducer,
    [ActionCreators.WORK_ORDER_DISCOUNT_GET.SUCCESS.type]: successDiscountsReducer,
    [ActionCreators.APPLY_PROMO_CODE_INVOICE.SUCCESS.type]: successPromoCodeReducer,
    [ActionCreators.UPLOAD_DOCUMENTS.SUCCESS.type]: uploadDocumentsSuccessReducer,
    [ActionCreators.DELETE_DOCUMENT.SUCCESS.type]: deleteDocumentSuccessReducer,
}, DEFAULT_STATE);
export default reducers;
