import React from 'react';
import { CircularProgress, InputAdornment, Typography, } from 'src/components/mui';
import { Search, AddRounded as AddRoundedIcon } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import ConsumerTitle from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/components/ConsumerTitle';
import FleetVehicleCard from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/pages/vehicle/Garage/components/FleetVehicleCard';
import FleetVehiclesTable from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/pages/vehicle/Garage/components/FleetVehiclesTable';
import useFleetGarage from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/pages/vehicle/Garage/useFleetGarage';
import AddVehicleButton from './components/AddVehicleButton';
import { ActionsContainer, AddNewVehicleFab, GarageDivider, GarageSearch, LoadMoreVehicles, VehicleListContainer, } from './components/styledComponents';
const iconStyle = {
    color: colors.iconGrey,
};
const inputProps = {
    startAdornment: (<InputAdornment position="start">
      <Search style={iconStyle}/>
    </InputAdornment>),
};
const FleetGarage = ({ fleet }) => {
    const { isMobile, buttonHandler, search, handleSearch, isDesktop, vehiclesData, selectVehicleHandler, loadMoreRef, loading, loadMoreText, } = useFleetGarage({ fleet });
    return (<>
      <ConsumerTitle consumer={{ firstName: fleet === null || fleet === void 0 ? void 0 : fleet.name }}/>
      <GarageDivider />
      <ActionsContainer>
        <Typography variant="h6">Garage</Typography>
        {!isMobile && <AddVehicleButton onClick={buttonHandler}/>}
      </ActionsContainer>

      <GarageSearch fullWidth InputProps={inputProps} placeholder="Filter by VIN, Plate or Nickname" value={search} variant="outlined" onChange={handleSearch}/>

      <VehicleListContainer>
        {isDesktop && (<FleetVehiclesTable vehicles={vehiclesData} onSelectVehicle={selectVehicleHandler}/>)}

        {!isDesktop &&
            vehiclesData.map((vehicle) => (<FleetVehicleCard key={vehicle.id} vehicle={vehicle} onSelectVehicle={selectVehicleHandler}/>))}
        <LoadMoreVehicles ref={loadMoreRef}>
          {!loading ? (<Typography variant="subtitle2">{loadMoreText}</Typography>) : (<CircularProgress />)}
        </LoadMoreVehicles>
      </VehicleListContainer>

      {isMobile && (<AddNewVehicleFab color="primary" onClick={buttonHandler}>
          <AddRoundedIcon />
        </AddNewVehicleFab>)}
    </>);
};
export default FleetGarage;
