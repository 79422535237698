import React from 'react';
import CONFIG from 'src/config';
import VisitStopWatch from 'src/AdminApp/containers/requests/StateTracker/stopwatch/VisitStopwatch/VisitStopwatch';
import { ProgressLabelWrapper, ProgressTypography, ProgressBarPadding, DotOverlay, IconWrapper, ProgressBarWrapper, ProgressBarFill, LabelWrapper, LevelIcon, } from './ProgressBar.styles';
const icons = ['watch_later', 'event_available', 'build', 'verified_user'];
const ProgressLabel = ({ children }) => {
    return (<ProgressLabelWrapper>
      <ProgressTypography variant="subtitle1">{children}</ProgressTypography>
    </ProgressLabelWrapper>);
};
const mapStepOnType = (value, step, count) => {
    if (value + 1 === count) {
        return 'last';
    }
    if (value < step) {
        return 'future';
    }
    if (value === step) {
        return 'current';
    }
    return 'past';
};
const barProgress = (state) => {
    let progress;
    let step;
    switch (state) {
        case 'PENDING':
            progress = 0;
            step = 0;
            break;
        case 'CONFIRMED':
            progress = 33.4;
            step = 1;
            break;
        case 'IN_PROGRESS':
            progress = 66.67;
            step = 2;
            break;
        case 'COMPLETED':
        default:
            progress = 100;
            step = 3;
            break;
    }
    return { progress, step };
};
const ProgressBar = ({ state, visit, isMobile }) => {
    const progressInfo = barProgress(state);
    const shouldRenderStopwatch = !isMobile &&
        progressInfo.step === 2 &&
        (CONFIG.ENABLE_STOPWATCH_DISPLAY === 'true' ||
            CONFIG.ENABLE_VISITS_STOPWATCH_DISPLAY === 'true');
    return (<ProgressBarPadding>
      <ProgressBarWrapper>
        <DotOverlay>
          {icons.map((icon, index) => (<IconWrapper key={icon} type={mapStepOnType(progressInfo.step, index, icons.length)}>
              {icon === 'build' && shouldRenderStopwatch ? (<VisitStopWatch visit={visit}/>) : (<LevelIcon>{icon}</LevelIcon>)}
            </IconWrapper>))}
        </DotOverlay>
        <ProgressBarFill width={progressInfo.progress} $isCanceled={state === 'CANCELED'}/>
      </ProgressBarWrapper>

      <LabelWrapper container style={{ justifyContent: 'space-between' }}>
        <ProgressLabel>PENDING</ProgressLabel>
        <ProgressLabel>CONFIRMED</ProgressLabel>
        <ProgressLabel>IN PROGRESS</ProgressLabel>
        <ProgressLabel>COMPLETED</ProgressLabel>
      </LabelWrapper>
    </ProgressBarPadding>);
};
export default ProgressBar;
