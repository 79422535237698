import React from 'react';
import { ClickAwayListener } from 'src/components/mui';
import PlacesAutocomplete from './PlacesAutocomplete';
import { Editable } from './components';
const EditableAddressInput = ({ displayValue, onAddressSelect, defaultValue, address, setFieldValue, isEditable = true, }) => {
    const formattedAddress = (<>
      <div>{address.street}</div>
      <div>
        {address.city}
        {address.state ? `, ${address.state} ` : ''}
        {address.zip}
      </div>
    </>);
    return (<Editable isEditable={isEditable} displayValue={(address === null || address === void 0 ? void 0 : address.zip) ? displayValue || formattedAddress : defaultValue} renderForm={({ setIsEditing }) => (<ClickAwayListener disableReactTree onClickAway={(event) => {
                if (event.type !== 'touchend')
                    setIsEditing(false);
            }}>
          <PlacesAutocomplete address={address} onSelect={(value) => {
                var _a, _b, _c, _d, _e, _f;
                if (!value)
                    return;
                if (((_a = address.street) !== null && _a !== void 0 ? _a : '') === ((_b = value === null || value === void 0 ? void 0 : value.street) !== null && _b !== void 0 ? _b : '') &&
                    ((_c = address.city) !== null && _c !== void 0 ? _c : '') === ((_d = value === null || value === void 0 ? void 0 : value.city) !== null && _d !== void 0 ? _d : '') &&
                    ((_e = address.state) !== null && _e !== void 0 ? _e : '') === ((_f = value === null || value === void 0 ? void 0 : value.state) !== null && _f !== void 0 ? _f : '')) {
                    return;
                }
                onAddressSelect(value, setFieldValue);
                setIsEditing(false);
            }} displayMap={false}/>
        </ClickAwayListener>)}/>);
};
export default EditableAddressInput;
