import styled from 'styled-components';
import { TableCell, TableRow } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const VisitRowContainer = styled(TableRow) `
  cursor: pointer;
  margin-top: 5px;

  td:first-child {
    width: 35%;
  }

  &:hover {
    outline: solid 2px ${colors.BLUE};
  }
`;
export const VisitCell = styled(TableCell) `
  padding: 32px 16px;
  white-space: nowrap;
  font-weight: 300;
  font-size: 15px;
  color: ${colors.GRAY_3F};

  span.VisitMore {
    color: ${colors.GRAY_76};
    margin-left: 4px;
    white-space: nowrap;
  }
`;
export const CaptionCell = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  font-weight: 400;
  font-size: 15px;
  color: ${colors.GRAY_3F};

  b {
    margin-right: 4px;
  }
`;
export const TooltipDivider = styled.div `
  border-bottom: solid 1px ${colors.GRAY_76};
  margin: 12px 0 20px;
`;
