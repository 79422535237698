import React from 'react';
import styled from 'styled-components';
import { FONT_FAMILY, theme } from 'src/styles/theme';
const JobsContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  margin: 0 0 72px;

  ${theme.breakpoints.up('md')} {
    flex: 1;

    margin: 0;
  }
`;
const JobCount = styled.div `
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin: 0 16px;
  width: 100%;

  .title {
    font-size: 16px;
    margin: 0;
    padding: 0;
    line-height: 28px;
    width: 100%;
    text-align: end;
    padding: 0 16px;

    ${theme.breakpoints.up('md')} {
      font-size: 18px;
    }
  }

  .counter {
    font-size: 52px;
    margin-right: 8px;
    font-family: ${FONT_FAMILY};
    width: 100%;
    padding: 0 16px;

    ${theme.breakpoints.up('md')} {
      font-size: 60px;
    }
  }
`;
const Jobs = ({ counts }) => {
    return (<JobsContainer>
      {counts && counts.length > 0 ? (<>
          {counts.map((count) => (<JobCount>
              <h3 className="title">{count.category}</h3>
              <div className="counter">{count.jobCount}</div>
            </JobCount>))}
        </>) : (<h3 className="title">No Jobs</h3>)}
    </JobsContainer>);
};
export default Jobs;
