import React from 'react';
import { Collapse, Grid, List, ListItem, ListItemIcon, ListItemText, } from 'src/components/mui';
import { WatchLaterRounded as WatchLaterRoundedIcon, RoomRounded as RoomRoundedIcon, LinkRounded as LinkRoundedIcon, ExpandLessRounded as ExpandLess, ExpandMoreRounded as ExpandMore, PersonRounded as PersonRoundedIcon, BuildRounded as BuildRoundedIcon, DescriptionRounded, InsertDriveFile as InsertDriveFileIcon, } from 'src/components/mui/icons';
import { CopyToClipboardButton } from 'src/components/buttons';
import EditableInput from 'src/components/form/EditableInput';
import ProgressBar from 'src/FleetVisitsApp/pages/VisitView/ProgressBar';
import { convertCapacityToHours } from 'src/FleetVisitsApp/pages/VisitView/VisitInfo/utils';
import moment from 'moment';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
import { AddressDisplay, LaborCapacitySection, ProgressBarWrapper, ResourcesDisplay, ResourcesTitle, SkeduloJobDisplay, TechCapacityWrapper, TimeDisplay, VisitCapacityListMobile, VisitInfoWrapper, VisitList, VisitMobileList, } from './VisitInfo.styles';
import DailyOpsContactsDrawer from '../DailyOpsContactsDrawer/DailyOpsContactsDrawer';
import EditDrawerMobile from '../DailyOpsContactsDrawer/EditDrawerMobile';
import useVisitInfo from './useVisitInfo';
import Contacts from './Contacts';
import AuditLogDrawer from '../AuditLogDrawer/AuditLogDrawer';
const VisitInfo = ({ visit }) => {
    var _a;
    const { isMobile, visitTimeDisplay, techs, instructions, techsExpanded, customersExpanded, fleetInfoExpanded, dailyOpsContacts, dailyOpsContactsExpanded, docMobileExpanded, dailyOpsContactsOpen, selectedDailyOpsContact, handleCopyPhoneClick, handleSelectDOC, handleTechsExpandClick, handleCustomersExpandClick, handleFleetInfoExpandClick, handleUpdateInstructions, handleSaveContact, handleViewContacts, handleCollapseContacts, handleCloseContactsDrawer, handleCloseMobileDrawer, openAuditLog, setOpenAuditLog, } = useVisitInfo({ visit });
    const { totalAvailableHours, totalScheduledHours, totalRemainingHours } = convertCapacityToHours(visit);
    const swimlanesLink = () => {
        var _a;
        const van = (_a = visit === null || visit === void 0 ? void 0 : visit.resources) === null || _a === void 0 ? void 0 : _a.find((v) => v.status === 'ACTIVE' && v.type === 'VAN');
        if (!van)
            return null;
        return (<a rel="noreferrer" href={`/admin/swimlanes/${moment(visit.actualStartLocal).format(DATE_YMD_FORMAT)}?van=${van === null || van === void 0 ? void 0 : van.deliveryVanId}`} target="_blank">
        Swimlane
      </a>);
    };
    return (<VisitInfoWrapper>
      <VisitMobileList>
        <ListItem divider className="visitListItem">
          <ListItemIcon className="visitListIcon">
            <WatchLaterRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: 'visitListItemText' }} primary={visitTimeDisplay}/>
        </ListItem>
        <ListItem divider className="visitListItem">
          <ListItemIcon className="visitListIcon">
            <RoomRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: 'visitListItemText' }} primary={`${visit.address.street}, ${visit.address.city} ${visit.address.zip}`}/>
        </ListItem>
        <ListItem divider className="visitListItem">
          <ListItemIcon className="visitListIcon visitListIconLink">
            <LinkRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: 'visitListItemText' }}>
            {swimlanesLink()}
          </ListItemText>
        </ListItem>
        <ListItem divider={!techsExpanded} className="visitListItem" button onClick={handleTechsExpandClick}>
          <ListItemIcon className="visitListIcon">
            <BuildRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: 'visitListItemTextBold' }} primary="Techs & Labor Capacity"/>
          {techsExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={techsExpanded} timeout="auto" unmountOnExit>
          <TechCapacityWrapper disablePadding>
            <ListItem>
              <ListItemText primary={<span className="primary">Techs:</span>} secondary={<span className="value">{techs}</span>}/>
            </ListItem>{' '}
            <ListItem>
              <ListItemText primary={<span className="primary">Labor Capacity:</span>} secondary={<VisitCapacityListMobile>
                    <li>
                      Total Available Hours:{' '}
                      <span className="value">{totalAvailableHours}</span>
                    </li>
                    <li>
                      Total Scheduled Hours:{' '}
                      <span className="value">{totalScheduledHours}</span>
                    </li>
                    <li>
                      Remaining Hours Available:{' '}
                      <span className="value">{totalRemainingHours}</span>
                    </li>
                  </VisitCapacityListMobile>}/>
            </ListItem>
          </TechCapacityWrapper>
        </Collapse>
        <ListItem divider={!customersExpanded} className="visitListItem" button onClick={handleCustomersExpandClick}>
          <ListItemIcon className="visitListIcon">
            <PersonRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: 'visitListItemTextBold' }} primary={`Customers (${visit.fleets.length})`}/>
          {customersExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={customersExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem divider>
              <ListItemText primary={<Contacts visit={visit} dailyOpsContacts={dailyOpsContacts} dailyOpsContactsExpanded={dailyOpsContactsExpanded} isMobile={isMobile} handleSelectDOC={handleSelectDOC} handleCopyPhoneClick={handleCopyPhoneClick} handleViewContacts={handleViewContacts} handleCollapseContacts={handleCollapseContacts}/>}/>
            </ListItem>
          </List>
        </Collapse>
        <ListItem divider={!fleetInfoExpanded} className="visitListItem" button onClick={handleFleetInfoExpandClick}>
          <ListItemIcon className="visitListIcon">
            <DescriptionRounded />
          </ListItemIcon>
          <ListItemText classes={{ primary: 'visitListItemTextBold' }} primary="Instructions"/>
          {fleetInfoExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={fleetInfoExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem divider>
              <ListItemText primary={instructions}/>
            </ListItem>
          </List>
        </Collapse>
      </VisitMobileList>

      <Grid container>
        <Grid item lg={4}>
          <VisitList>
            <TimeDisplay container style={{ alignItems: 'center' }}>
              <Grid item className="visitListIcon">
                <WatchLaterRoundedIcon />
              </Grid>
              <Grid item className="visitListItemText">
                {visitTimeDisplay}
              </Grid>
            </TimeDisplay>

            <AddressDisplay container style={{ alignItems: 'center' }}>
              <Grid item className="visitListIcon">
                <RoomRoundedIcon />
              </Grid>

              <Grid item className="visitListItemText">
                {`${visit.address.street}, ${visit.address.city} ${visit.address.zip}`}
                <CopyToClipboardButton value={`${visit.address.street}, ${visit.address.city} ${visit.address.zip}`} style={{ marginLeft: '-6px' }}/>
              </Grid>
            </AddressDisplay>

            <SkeduloJobDisplay container style={{ alignItems: 'center' }}>
              <Grid item className="visitListIcon rotate">
                <LinkRoundedIcon />
              </Grid>

              <Grid item className="visitListItemText">
                {swimlanesLink()}
              </Grid>

              <Grid item className="visitListIcon" style={{
            marginLeft: '16px',
        }}>
                <InsertDriveFileIcon />
              </Grid>
              <Grid item className="visitListItemText">
                <span role="button" className="visitListItemTextBtn" onClick={() => setOpenAuditLog(!openAuditLog)} onKeyDown={() => { }} tabIndex={0}>
                  View Audit Log
                </span>
              </Grid>
            </SkeduloJobDisplay>
          </VisitList>
        </Grid>
        {!isMobile && (<Grid item xs={8}>
            <ProgressBarWrapper>
              <ProgressBar state={visit.status} visit={visit} isMobile={isMobile}/>
              <LaborCapacitySection>
                <div className="capacityTitle">Labor Capacity</div>
                <ul>
                  <li>
                    Total Avail Hrs:{' '}
                    <span className="value">{totalAvailableHours}</span>
                  </li>
                  <li>
                    Total Scheduled Hrs:{' '}
                    <span className="value">{totalScheduledHours}</span>
                  </li>
                  <li>
                    Remaining Avail Hrs:{' '}
                    <span className="value">{totalRemainingHours}</span>{' '}
                  </li>
                </ul>
              </LaborCapacitySection>
            </ProgressBarWrapper>
          </Grid>)}
      </Grid>
      <Grid container>
        <ResourcesDisplay container>
          <Grid item xs={3} lg={2}>
            <ResourcesTitle>Techs:</ResourcesTitle>
            <div>{techs}</div>
          </Grid>
          <Grid item xs={3} lg={2}>
            <ResourcesTitle>Customers:</ResourcesTitle>
            <div>
              <Contacts visit={visit} dailyOpsContacts={dailyOpsContacts} dailyOpsContactsExpanded={dailyOpsContactsExpanded} isMobile={isMobile} handleSelectDOC={handleSelectDOC} handleCopyPhoneClick={handleCopyPhoneClick} handleViewContacts={handleViewContacts} handleCollapseContacts={handleCollapseContacts}/>
            </div>
          </Grid>
          <Grid item xs={6} lg={4}>
            <ResourcesTitle>Instructions:</ResourcesTitle>
            <div>
              {(visit === null || visit === void 0 ? void 0 : visit.status) !== 'COMPLETED' ? (<EditableInput inputProps={{
                multiline: true,
                rows: 7,
                variant: 'outlined',
                InputProps: {
                    type: 'text',
                },
            }} breakLines isDrawerOnMobile onUpdate={handleUpdateInstructions} value={instructions !== 'N/A' ? instructions : ''}>
                  {instructions}
                </EditableInput>) : (instructions)}
            </div>
          </Grid>
        </ResourcesDisplay>
      </Grid>
      <DailyOpsContactsDrawer open={dailyOpsContactsOpen} onClose={handleCloseContactsDrawer} contacts={dailyOpsContacts !== null && dailyOpsContacts !== void 0 ? dailyOpsContacts : []} onContactSave={handleSaveContact}/>
      <EditDrawerMobile open={docMobileExpanded} onClose={handleCloseMobileDrawer} contact={selectedDailyOpsContact} onContactSave={handleSaveContact}/>
      <AuditLogDrawer visitId={visit.id} referenceNumber={visit.refNum} open={openAuditLog} onClose={() => setOpenAuditLog(false)} vans={(_a = visit === null || visit === void 0 ? void 0 : visit.resources) === null || _a === void 0 ? void 0 : _a.filter((v) => v.status === 'ACTIVE' && v.type === 'VAN')}/>
    </VisitInfoWrapper>);
};
export default VisitInfo;
