import { createActionCreators } from 'src/utils/createActions';
import createAction from 'src/modules/api/utils/createAction';
export const ActionCreators = {
    EXPIRED_ARRIVAL_TIMER_MODAL: createActionCreators(['OPEN', 'CLOSE'], 'EXPIRED_ARRIVAL_TIMER_MODAL'),
};
export const openExpiredArrivalTimerModal = () => ({
    type: ActionCreators.EXPIRED_ARRIVAL_TIMER_MODAL.OPEN.type,
});
export const closeExpiredArrivalTimerModal = () => ({
    type: ActionCreators.EXPIRED_ARRIVAL_TIMER_MODAL.CLOSE.type,
});
export const setIsArrivalTimerExpired = createAction('SET_ARRIVAL_TIMER_EXPIRED');
export const setArrivalExtraMinutesTimestamp = createAction('SET_ARRIVAL_EXTRA_MINUTES_TIMESTAMP');
