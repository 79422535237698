import React from 'react';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox';
import usePartPickupChecklistItem from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupChecklist/usePartPickupChecklistItem';
import { ChecklistItem, ChecklistItemContent, ChecklistItemLink, ChecklistItemPart, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
import QuantitySelector from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupChecklist/QuantitySelector';
const PartPickupChecklistItem = ({ repairRequestReferenceNum, items, }) => {
    const { countPendingOrNewPart, pickedUpItems, getItemIndex, handleChangedPickedUpItem, handleQuantityChange, } = usePartPickupChecklistItem();
    return (<ChecklistItem>
      <ChecklistItemLink>
        <a rel="noreferrer" href={`/admin/repairs/${repairRequestReferenceNum}`} target="_blank">
          {repairRequestReferenceNum}
        </a>
      </ChecklistItemLink>

      <ChecklistItemContent>
        {items
            .filter((item) => countPendingOrNewPart(item) > 0)
            .map((item, index) => (<ChecklistItemPart key={index}>
              <div>
                <LabelledCheckbox checked={pickedUpItems[getItemIndex(item.id)].checked} onChange={handleChangedPickedUpItem(item)} name="checklistItemCheckbox" label={<>
                      <b>{item.name}</b> &middot; Part #:
                      {item.partNumber}
                      {item.quantity > 1
                    ? ` \u00B7 Qty: ${countPendingOrNewPart(item)}`
                    : ''}
                    </>}/>
              </div>
              {item.quantity > 1 ? (<QuantitySelector value={pickedUpItems[getItemIndex(item.id)].quantity} maxQuantity={countPendingOrNewPart(item)} onQuantityChange={handleQuantityChange(item)}/>) : null}
            </ChecklistItemPart>))}
      </ChecklistItemContent>
    </ChecklistItem>);
};
export default PartPickupChecklistItem;
