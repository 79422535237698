import { createSelector } from 'reselect';
export const selectAdminWorkflowsState = (state) => state.admin.workflows;
export const selectWorkflowByReferenceNumber = (refNum) => createSelector(selectAdminWorkflowsState, (workflows) => { var _a; return (_a = workflows.byRefNum) === null || _a === void 0 ? void 0 : _a[refNum]; });
export const selectWorkflowCurrentTask = (refNum) => createSelector(selectWorkflowByReferenceNumber(refNum), (workflow) => workflow === null || workflow === void 0 ? void 0 : workflow.currentTask);
export const selectFourCornerWorkflow = (refNum) => createSelector(selectWorkflowCurrentTask(refNum), (task) => (task === null || task === void 0 ? void 0 : task.state) === 'FOUR_CORNER' && (task === null || task === void 0 ? void 0 : task.status) === 'PENDING');
export const selectFourCornerWorkflowTaskDone = (refNum) => createSelector(selectWorkflowByReferenceNumber(refNum), (workflow) => {
    var _a;
    const fourCorner = (_a = workflow === null || workflow === void 0 ? void 0 : workflow.workflowTasks) === null || _a === void 0 ? void 0 : _a.find(({ state, status }) => state === 'FOUR_CORNER' && status === 'COMPLETED');
    return !!fourCorner;
});
export const selectStopwatch = () => createSelector(selectAdminWorkflowsState, (workflows) => workflows === null || workflows === void 0 ? void 0 : workflows.stopwatch);
