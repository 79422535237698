import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { TextField } from 'src/components/mui';
import { AccordionContent, AccordionHeader, PartsAccordion, } from 'src/PopsApp/components/PartsAccordion/PartsAccordion';
import PartsButton from 'src/PopsApp/components/PartsButton';
import VendorInfo from 'src/PopsApp/components/VendorInfo/VendorInfo';
import CarInfo from './components/CarInfo';
import EditDialog from './components/EditDialog';
import EditPart from './components/EditPart';
import OrderCompletedDrawer from './components/OrderCompletedDrawer';
import OrderParts from './components/OrderParts';
import PickPartDrawer from './components/PickPartDrawer';
import Delivery from './components/Delivery/Delivery';
import useOrder from './useOrder';
import { ActionPanel, ConfirmationNumberMobileWrapper, ConfirmationNumberTitle, Container, } from './Order.styles';
const Order = () => {
    var _a, _b, _c, _d;
    const { ispId, region, method } = useParams();
    const isQuickOrder = method === 'quick-order';
    const { parts, selectedPart, setSelectedPart, completingOrder, dialogSettings, openCompleteDrawer, setOpenCompleteDrawer, newComponentType, setNewComponentType, setNewComponent, deleteComponent, setIsIntegrated, pickupMethod, setPickupMethod, reloadsJobsByVan, van, history, selectedJobs, distributor, orderParts, vendorOpen, setVendorOpen, partsOpen, setPartsOpen, openPartsDialog, setOpenPartsDialog, openEditDialog, setOpenEditDialog, componentsAvailability, wrongPartNumbers, handleAccordionClick, handleConfirmationNumberChange, handleSaveNextClick, handleSelectPart, mdAndUp, handleChangeEvent, handleCompleteOrder, handlePickPartSelect, handlePickPart, handleEdit, handleBlurEvent, } = useOrder({
        ispId: Number(ispId),
        isPrioritized: false,
        isQuickOrder,
        region,
    });
    return (<>
      <Container className="orderContainer">
        <div className="ordersMain">
          <h1 className="regionsTitle">Order Parts & Fluids</h1>
          {van && (<Helmet>
              <title>{(_a = van.shopName) !== null && _a !== void 0 ? _a : 'N/A'} - Order Parts</title>
            </Helmet>)}

          <div className="accordions">
            <PartsAccordion>
              <AccordionHeader className="accHeader" isOpen={vendorOpen} onClick={handleAccordionClick(vendorOpen, setVendorOpen)}>
                {distributor === null || distributor === void 0 ? void 0 : distributor.partDistributorName}
              </AccordionHeader>
              <AccordionContent className="accContent" isOpen={vendorOpen}>
                <div className="vendorEdit" role="button" tabIndex={0} onKeyDown={() => {
            // do nothing
        }} onClick={() => {
            history.push(`/pops/regions/${region}/vans/${ispId}/jobs/vendors`);
        }}>
                  {distributor === null || distributor === void 0 ? void 0 : distributor.address}
                  <button type="button">Edit</button>
                </div>
              </AccordionContent>
            </PartsAccordion>
            <PartsAccordion>
              <AccordionHeader className="accHeader" isOpen={partsOpen} onClick={handleAccordionClick(partsOpen, setPartsOpen)}>
                Parts Added ({orderParts.length})
              </AccordionHeader>
              <AccordionContent className="accContent" isOpen={partsOpen}>
                <OrderParts selectedPart={selectedPart} selectedJobs={selectedJobs !== null && selectedJobs !== void 0 ? selectedJobs : []} onEdit={(order) => {
            handleEdit(order);
        }}/>
              </AccordionContent>
            </PartsAccordion>
          </div>

          <div>
            <h3 className="repairJobs">Repair Jobs</h3>
            {(_b = selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.map((job, idx) => (<CarInfo key={job.repairOrderId} parts={parts} job={job} jobNumber={idx + 1} jobsCount={selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.length} selectedPart={selectedPart} onSelectPart={handleSelectPart} setNewComponentType={setNewComponentType} deleteComponent={(repairId, componentId, componentType, isCustom, removeDynamicRule) => deleteComponent({
                repairId,
                componentId,
                componentType,
                isCustom,
                removeDynamicRule,
            })} newComponentType={newComponentType} onChangeInput={handleChangeEvent} onSaveNext={handleSaveNextClick} onReload={reloadsJobsByVan}/>))) !== null && _b !== void 0 ? _b : null}

            <ConfirmationNumberMobileWrapper>
              <ConfirmationNumberTitle>
                Add Vendor Confirmation #
              </ConfirmationNumberTitle>
              <TextField variant="outlined" placeholder="Vendor Confirmation # (optional)" onChange={handleConfirmationNumberChange}/>
            </ConfirmationNumberMobileWrapper>
          </div>
          <ActionPanel>
            <p className="partsAdded">
              {' '}
              {orderParts.length} Part{(orderParts === null || orderParts === void 0 ? void 0 : orderParts.length) === 1 ? '' : 's'}{' '}
              Added
            </p>
            <PartsButton disabled={!orderParts || !orderParts.length || completingOrder} className="button" onClick={handleCompleteOrder}>
              Complete Order
            </PartsButton>
          </ActionPanel>
        </div>

        <div className="ordersActions">
          <div className="floatingActionsPanel">
            <VendorInfo distributor={distributor} onClick={() => {
            history.push(`/pops/regions/${region}/vans/${ispId}/jobs/vendors`);
        }}/>

            <OrderCompletedDrawer open={openCompleteDrawer} onClose={() => {
            setOpenCompleteDrawer(false);
        }} partPickupOrderReferenceNum={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.partPickupOrderReferenceNum} integratedSkeduloId={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.integratedSkeduloId} integratedSkeduloJobName={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.integratedSkeduloJobName} skeduloId={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.skeduloId} skeduloJobName={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.skeduloJobName} orderSuccess={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.success} allJobsOrdered={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.allJobsOrdered} editVendor={() => {
            history.push(`/pops/regions/${region}/vans/${ispId}/jobs/vendors`);
        }} processing={completingOrder} integratedFailedOrderJobs={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.integratedFailedOrderjobs} allIntegratedPartsOrdered={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.allIntegratedPartsOrdered}/>

            <h3 className="actionsTitle">Pick-up Method</h3>
            <Delivery ispId={ispId} distributor={distributor} selectedJobs={selectedJobs} pickupMethod={pickupMethod} setPickupMethod={setPickupMethod}/>

            <h3 className="actionsTitle">Cart</h3>

            <PartsAccordion className="accordion">
              <AccordionHeader className="accHeader" isOpen={partsOpen} onClick={handleAccordionClick(partsOpen, setPartsOpen)}>
                Parts Added ({orderParts.length})
              </AccordionHeader>
              <AccordionContent className="accContent" isOpen={partsOpen}>
                <OrderParts selectedPart={selectedPart} selectedJobs={selectedJobs} onEdit={(order) => {
            handleEdit(order);
        }}/>
              </AccordionContent>
            </PartsAccordion>
            {selectedPart ? (<EditPart part={selectedPart} onChangeInput={handleChangeEvent} onBlurInput={handleBlurEvent} onSaveNext={handleSaveNextClick} onPickPart={handlePickPart} isFluid={(_d = (_c = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _c === void 0 ? void 0 : _c.isFluid) !== null && _d !== void 0 ? _d : false} componentsAvailability={componentsAvailability} isDialog={false} setIsIntegrated={(value) => setIsIntegrated(value)} setSelectedPart={setSelectedPart} distributor={distributor} wrongPartNumbers={wrongPartNumbers}/>) : null}
            <div className="divider"/>

            <PartsButton disabled={!orderParts || !orderParts.length || completingOrder} className="button" onClick={handleCompleteOrder}>
              Complete Order
            </PartsButton>
          </div>
        </div>
      </Container>
      <EditDialog part={selectedPart} isOpen={openEditDialog} onClose={() => {
            if (newComponentType) {
                setNewComponentType(undefined);
                setNewComponent({});
            }
            setOpenEditDialog(false);
        }} onChange={handleChangeEvent} onBlurInput={handleBlurEvent} onSaveNext={handleSaveNextClick} handleEdit={handleEdit} handlePickPart={() => {
            setOpenPartsDialog(true);
        }} componentsAvailability={componentsAvailability} setIsIntegrated={(value) => setIsIntegrated(value)} setSelectedPart={setSelectedPart} distributor={distributor}/>
      <PickPartDrawer open={openPartsDialog} onClose={() => {
            setOpenPartsDialog(false);
            if (!mdAndUp) {
                setOpenEditDialog(true);
            }
        }} onSelect={handlePickPartSelect} selectedPart={selectedPart}/>
    </>);
};
export default Order;
