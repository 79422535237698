import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, Button, MenuItem, TextField } from 'src/components/mui';
import * as Yup from 'yup';
import ReactJson from '@microlink/react-json-view';
import CONFIG from 'src/config';
import LocalWebhookInfo from './LocalWebhookInfo';
import { buildWebhook, getDefaultBaseUrl, generateEnvironments, } from './webhookUtils';
const rsEnvironments = generateEnvironments(CONFIG.APP_ENV);
const WebhookCreateSchema = Yup.object().shape({
    baseUrl: Yup.string()
        .url('Incorrect URL (try starting with https://)')
        .required('Required'),
    webhookType: Yup.string().required('Required'),
    environment: Yup.string().required('Required'),
    localMachineName: Yup.string().when('environment', {
        is: (value) => value === 'local',
        then: Yup.string()
            .required('Required')
            .min(2, 'Too Short')
            .max(50, 'Too Long'),
        otherwise: Yup.string().min(2, 'Too Short').max(50, 'Too Long'),
    }),
});
const INITIAL_VALUES = {
    baseUrl: getDefaultBaseUrl(CONFIG.APP_ENV),
    webhookType: '',
    environment: CONFIG.APP_ENV || '',
    localMachineName: CONFIG.APP_ENV === 'local' ? CONFIG.REACT_APP_HOSTNAME : '',
};
const WebhookCreateModule = ({ createWebhook, toggleCreateDrawer, }) => {
    const [webhook, setWebhook] = useState({});
    const setPreview = (values) => {
        setWebhook(buildWebhook(values));
    };
    useEffect(() => {
        setPreview(INITIAL_VALUES);
    }, []);
    return (<Formik initialValues={Object.assign({}, INITIAL_VALUES)} validationSchema={WebhookCreateSchema} validateOnMount onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
                setSubmitting(false);
                const webhook = buildWebhook(values);
                createWebhook(webhook);
                resetForm({});
            }, 1000);
            toggleCreateDrawer();
        }}>
      {({ submitForm, isSubmitting, values, isValid }) => (<Form>
          <Box margin={1}>
            <Field name="environment" label="Environment" select fullWidth onChange={(e) => {
                values.environment = e.target.value;
                values.baseUrl = getDefaultBaseUrl(e.target.value);
                setPreview(values);
            }} render={({ field }) => (<TextField {...field} placeholder="Environment" fullWidth select>
                  {rsEnvironments.map((environment) => (<MenuItem value={environment} key={environment}>
                      {environment}
                    </MenuItem>))}
                </TextField>)}/>
          </Box>
          {values.environment === 'local' && (<Box margin={1}>
              <Field name="localMachineName" label="Local Machine Name" fullWidth onChange={(e) => {
                    values.localMachineName = e.target.value;
                    setPreview(values);
                }} render={({ field }) => (<TextField {...field} placeholder="Local Machine Name" fullWidth/>)}/>
            </Box>)}
          <Box margin={1}>
            <Field name="webhookType" label="Type" select fullWidth onChange={(e) => {
                values.webhookType = e.target.value;
                setPreview(values);
            }} render={({ field }) => (<TextField {...field} placeholder="Type" fullWidth select>
                  <MenuItem value="job">Job Updated</MenuItem>
                  <MenuItem value="job-allocation">
                    Job Allocation Updated
                  </MenuItem>
                </TextField>)}/>
          </Box>
          <Box margin={1}>
            <Field name="baseUrl" label="Base URL" placeholder="https://" onChange={(e) => {
                values.baseUrl = e.target.value;
                setPreview(values);
            }} render={({ field }) => (<TextField {...field} placeholder="Base URL" fullWidth/>)}/>
          </Box>
          {values.environment === 'local' && <LocalWebhookInfo />}
          <Box margin={1}>
            <Button variant="contained" color="primary" disabled={isSubmitting || !isValid} onClick={submitForm}>
              Submit
            </Button>
          </Box>
          <Box>
            <h3>Webhook Preview</h3>
            <ReactJson src={webhook} name={null} indentWidth={2} displayDataTypes={false} theme="mocha" enableClipboard={false} onEdit={false} onAdd={false} onDelete={false} collapseStringsAfterLength={100} style={{ minHeight: '500px', fontSize: '0.875rem' }}/>
          </Box>
        </Form>)}
    </Formik>);
};
export default WebhookCreateModule;
