import React from 'react';
export const AvatarSettingsIcon = () => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path d="M10.304 11.804a3.651 3.651 0 0 0 3.653-3.652A3.651 3.651 0 0 0 10.304 4.5a3.651 3.651 0 0 0-3.652 3.652 3.651 3.651 0 0 0 3.652 3.652zm0 1.826C7.867 13.63 3 14.854 3 17.283v.913c0 .502.41.913.913.913h12.783c.502 0 .913-.411.913-.913v-.913c0-2.43-4.867-3.653-7.305-3.653z" id="t0ix47i3xa"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <use stroke="#3F3F3F" strokeWidth="1.5" fillRule="nonzero" xlinkHref="#t0ix47i3xa"/>
        <circle fill="#FFF" cx="18.522" cy="16.37" r="5.478"/>
        <path d="M22.01 16.817c.019-.146.033-.292.033-.447 0-.156-.014-.302-.033-.448l.99-.753a.226.226 0 0 0 .056-.292l-.938-1.58a.236.236 0 0 0-.207-.114.225.225 0 0 0-.08.014l-1.168.456a3.453 3.453 0 0 0-.793-.447l-.179-1.21a.227.227 0 0 0-.23-.192h-1.877a.227.227 0 0 0-.23.192l-.178 1.21c-.286.114-.55.27-.793.447l-1.169-.456a.273.273 0 0 0-.084-.014c-.08 0-.16.041-.202.114l-.939 1.58a.221.221 0 0 0 .056.292l.99.753a3.523 3.523 0 0 0-.032.448c0 .15.014.3.033.447l-.99.753a.226.226 0 0 0-.057.292l.939 1.58a.236.236 0 0 0 .206.114.225.225 0 0 0 .08-.014l1.169-.456c.244.182.507.333.793.447l.178 1.21c.014.11.113.192.23.192h1.877a.227.227 0 0 0 .23-.192l.179-1.21c.286-.114.549-.27.793-.447l1.169.456c.028.01.056.014.084.014.08 0 .16-.041.202-.114l.938-1.58A.226.226 0 0 0 23 17.57l-.99-.753zm-.93-.78a2.374 2.374 0 0 1 0 .666l-.065.516.418.32.506.383-.328.552-.596-.233-.488-.191-.423.31a2.768 2.768 0 0 1-.586.333l-.498.197-.075.515-.094.617h-.657l-.089-.617-.075-.515-.498-.197a2.682 2.682 0 0 1-.577-.324l-.427-.32-.497.197-.596.233-.329-.553.507-.383.418-.32-.066-.516a3.574 3.574 0 0 1-.024-.337c0-.092.01-.197.024-.334l.066-.516-.418-.32-.507-.383.329-.552.596.233.488.192.422-.31c.202-.147.394-.257.587-.334l.497-.196.075-.516.094-.617h.653l.089.617.075.516.497.196c.202.082.39.187.578.324l.427.32.497-.197.596-.233.329.553-.502.388-.418.32.066.515zm-2.557-1.494c-1.037 0-1.878.818-1.878 1.827 0 1.008.84 1.826 1.878 1.826 1.037 0 1.877-.818 1.877-1.826 0-1.01-.84-1.827-1.877-1.827zm0 2.74c-.516 0-.939-.411-.939-.913 0-.503.423-.913.939-.913s.938.41.938.913c0 .502-.422.913-.938.913z" fill="#3F3F3F" fillRule="nonzero"/>
      </g>
    </svg>);
};
