var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FleetBulkVehicleImportCSVFormat } from 'src/AdminApp/models/enums/FleetBulkVehicleImportCSVFormat';
import moment from 'moment';
import { toast } from 'src/components/SimpleToast';
export const downloadFile = (blob, fileName) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: 'application/pdf' });
    const nav = window.navigator;
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
};
export const downloadInvoicePdf = (repairId, workOrderResponsibleParty, accessToken) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    try {
        const filename = `invoice_${repairId.replaceAll('-', '')}_${(_a = workOrderResponsibleParty === null || workOrderResponsibleParty === void 0 ? void 0 : workOrderResponsibleParty.responsibleParty) === null || _a === void 0 ? void 0 : _a.name}_${new Date().getTime()}.pdf`;
        const pdfResponse = yield window.fetch(`/lambda/generateInvoicePdf?repairRequestRefNum=${repairId}&utcOffset=${moment().utcOffset()}&isAdminRequest=true&uploadToS3=false` +
            `&outputFileName=${encodeURIComponent(filename)}&responsiblePartyId=${(_b = workOrderResponsibleParty === null || workOrderResponsibleParty === void 0 ? void 0 : workOrderResponsibleParty.responsibleParty) === null || _b === void 0 ? void 0 : _b.id}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (pdfResponse && pdfResponse.status === 200) {
            const blob = yield pdfResponse.blob();
            downloadFile(blob, filename);
        }
    }
    catch (e) {
        toast.error('Unable to download report');
    }
});
export const openInvoicePdf = ({ referenceNum, responsiblePartyId, accessToken, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    try {
        const filename = `invoice_${referenceNum.replaceAll('-', '')}_${new Date().getTime()}.pdf`;
        const urlParams = new URLSearchParams({
            repairRequestRefNum: referenceNum,
            utcOffset: moment().utcOffset().toString(),
            isAdminRequest: 'true',
            uploadToS3: 'true',
            outputFileName: encodeURIComponent(filename),
            responsiblePartyId: responsiblePartyId,
        });
        const pdfResponse = yield window.fetch(`/lambda/generateInvoicePdf?${urlParams.toString()}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (+((_c = pdfResponse === null || pdfResponse === void 0 ? void 0 : pdfResponse.status) !== null && _c !== void 0 ? _c : 0) === 200) {
            const json = yield pdfResponse.json();
            window.open(json.url);
        }
        else {
            toast.error('Unable to download report');
        }
    }
    catch (e) {
        toast.error('Unable to download report');
    }
});
export const downloadSalesMatchInvoicePdf = (salesMatchId, invoiceNumber, accessToken) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const fileName = `invoice_${invoiceNumber}_${new Date().getTime()}.pdf`;
        const pdfResponse = yield window.fetch(`/lambda/generateToolsInvoicePdf?salesMatchId=${salesMatchId}` +
            `&outputFileName=${encodeURIComponent(fileName)}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/pdf',
                'Accept-Encoding': 'base64',
            },
        });
        const blob = yield pdfResponse.blob();
        downloadFile(blob, `${fileName}.pdf`);
    }
    catch (e) {
        toast.error('Unable to download report');
    }
});
export const downloadQuotePdf = (repairId, accessToken) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const fileName = `Quote ${repairId} - AutoNation Mobile Service.pdf`;
        const pdfResponse = yield window.fetch(`/lambda/generateQuotePdf?repairRequestRefNum=${repairId}` +
            `&outputFileName=${encodeURIComponent(fileName)}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/pdf',
                'Accept-Encoding': 'base64',
            },
        });
        const blob = yield pdfResponse.blob();
        downloadFile(blob, fileName);
    }
    catch (e) {
        toast.error('Unable to download report');
    }
});
export const downloadNewFleetTemplateCsv = (importCSVFormat, accessToken) => __awaiter(void 0, void 0, void 0, function* () {
    let templateFileName = null;
    switch (importCSVFormat) {
        case FleetBulkVehicleImportCSVFormat.RepairSmithStandard:
            templateFileName = 'anms-standard-template.csv';
            break;
        case FleetBulkVehicleImportCSVFormat.ElementPMDetail:
            templateFileName = 'element-pm-detail-template.csv';
            break;
        default:
        // nothing to see here
    }
    if (templateFileName) {
        const pdfResponse = yield window.fetch(`/documents/${templateFileName}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/pdf',
                'Accept-Encoding': 'base64',
            },
        });
        const blob = yield pdfResponse.blob();
        downloadFile(blob, templateFileName);
    }
});
export const downloadMpiReportPdf = (mpiId, year, make, model, accessToken, fleetId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const pdfResponse = yield window.fetch(`/lambda/generateMpiPdf?inspectionId=${mpiId}&uploadToS3=true` +
            `&outputFileName=${encodeURIComponent(`${year} ${make} ${model} Car Health Report - AutoNation Mobile Service.pdf`)}&fleetId=${fleetId !== null && fleetId !== void 0 ? fleetId : '0'}&isCustomerRequest=false`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (pdfResponse && pdfResponse.status === 200) {
            const json = yield pdfResponse.json();
            window.open(json.url);
        }
    }
    catch (e) {
        toast.error('Unable to download report');
    }
});
export const downloadInvoiceBatch = (requests, accessToken) => __awaiter(void 0, void 0, void 0, function* () {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const options = {
        method: 'POST',
        body: JSON.stringify({
            uploadToS3: true,
            utcOffset: moment().utcOffset(),
            isAdminRequest: true,
            requests,
        }),
        headers,
    };
    const request = new Request(`/lambda/generateBatchInvoicePdfs`, options);
    try {
        const pdfResponse = yield window.fetch(request);
        if (pdfResponse && pdfResponse.status === 200) {
            const blob = yield pdfResponse.blob();
            const objectURL = URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.href = objectURL;
            tempLink.download = 'invoice.zip';
            tempLink.click();
            URL.revokeObjectURL(objectURL);
        }
    }
    catch (e) {
        toast.error('Unable to download report');
    }
});
