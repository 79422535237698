import { useParams } from 'react-router-dom';
import { FormControlLabel, Grid, Radio, RadioGroup } from 'src/components/mui';
import React from 'react';
import useConfirmPartsFitmentDrawer from 'src/AdminApp/containers/repairs/ConfirmPartsFitmentDrawer/useConfirmPartsFitmentDrawer';
import { DrawerFormRow, Instructions, SimpleDrawer, SubHeader, } from 'src/components/SimpleDrawer';
import { usMoneyFormatWithCents } from 'src/modules/api/utils/format';
import { colors } from 'src/styles/theme';
const ConfirmPartsFitmentDrawer = ({ open, onClose, repairFitments, }) => {
    const params = useParams();
    const { currentFitmentIndex, selectedFitment, hasMore, currentPartFluidFitment, handleFitmentChange, handleSubmit, isSubmitting, } = useConfirmPartsFitmentDrawer({
        referenceNum: params.referenceNum,
        repairFitments,
        onClose,
    });
    return !currentPartFluidFitment ? null : (<SimpleDrawer open={open} onClose={onClose} arrowBack={currentFitmentIndex !== 0} title="Confirm Parts Users" simpleMode footerActionButtonProps={{
            disabled: !selectedFitment,
            onClick: handleSubmit,
            isSubmitting,
        }} submitText={hasMore ? 'Next' : 'Submit' // eslint-disable-line no-console
        }>
      <SubHeader>
        <Grid container direction="row" justifyContent="space-between" spacing={2}>
          <Grid item xs={8}>
            <div style={{
            marginBottom: 8,
            color: colors.concreteGray,
            fontSize: 16,
        }}>
              {currentPartFluidFitment.name}
            </div>
            {currentPartFluidFitment.partFluidName} •{' '}
            {currentPartFluidFitment.price
            ? usMoneyFormatWithCents(currentPartFluidFitment.price)
            : ''}
          </Grid>
          {repairFitments.length > 1 && (<Grid item xs={4} style={{ textAlign: 'right' }}>
              {currentFitmentIndex + 1}/{repairFitments.length}
            </Grid>)}
        </Grid>
      </SubHeader>
      <Instructions>Select the part that was used for the repair.</Instructions>
      <DrawerFormRow>
        <RadioGroup aria-labelledby="fitment-group" name="fitment-group" value={selectedFitment} onChange={handleFitmentChange}>
          {currentPartFluidFitment.fitments.map((fitment) => (<FormControlLabel key={fitment.id} value={fitment.id} control={<Radio />} label={<span>
                  {fitment.partNumber} • Cost:{' '}
                  {usMoneyFormatWithCents(fitment.cost)}
                </span>}/>))}
        </RadioGroup>
      </DrawerFormRow>
    </SimpleDrawer>);
};
export default ConfirmPartsFitmentDrawer;
