export default function loadScript(src, position, id, custom) {
    if (!position) {
        return;
    }
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    if (custom) {
        script.setAttribute(custom[0], custom[1]);
    }
    position.appendChild(script);
}
