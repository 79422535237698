import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TableRow, TableCell } from 'src/components/mui';
import { formatDate } from 'src/utils/formatter';
const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  td {
    padding: 15px !important;
  }
`;
const TableCellSecondary = styled(TableCell) `
  & span {
    display: block;
    color: #aaa;
    font-size: 0.85em;
  }
`;
const FleetRepairItem = ({ repair }) => {
    var _a;
    return (<StyledTableRow>
      <TableCell>
        <Link to={`/admin/repairs/${repair.referenceNum}`}>
          <div>{repair.referenceNum}</div>
        </Link>
      </TableCell>
      <TableCell>
        <div>{repair.status}</div>
      </TableCell>
      <TableCellSecondary>
        {repair.carYear} {repair.carMake} {repair.carModel} {repair.carTrim}
        <span> Consumer Car ID: ({repair.consumerCarId})</span>
      </TableCellSecondary>
      <TableCell>
        <div>{repair.companyName}</div>
      </TableCell>
      <TableCell>
        <div>
          {(_a = repair.suggestedRepairs) === null || _a === void 0 ? void 0 : _a.map((item, index) => (<div key={index}>{item}</div>))}
        </div>
      </TableCell>
      <TableCellSecondary>
        <div>{formatDate(repair.created, 'lll z', repair.timezone)}</div>
        <span>{repair.adminName}</span>
      </TableCellSecondary>
      <TableCellSecondary>
        {repair.appointmentScheduleStartUTC
            ? formatDate(repair.appointmentScheduleStartUTC, 'lll z', repair.timezone)
            : '-'}
      </TableCellSecondary>
    </StyledTableRow>);
};
export default FleetRepairItem;
