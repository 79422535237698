var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'src/components/SimpleToast';
import { selectSelectedPlan } from 'src/AdminApp/modules/subscription/selectors';
import { selectRequestContactInfoReferenceNum, selectRequestRequestReferenceNum, } from 'src/AdminApp/modules/requests/selectors';
import { getAffirmCheckoutMagicLink, sendAffirmCheckoutLinkByEmail, } from 'src/AdminApp/modules/subscription/actions';
import { loadPaymentMethodsByConsumer } from 'src/AdminApp/modules/payments/actions';
import { getRequestCar } from 'src/AdminApp/modules/requests/actions';
import { getEntry } from 'src/clients/contentfulClient';
import Config from 'src/config';
export const AFFIRM_ERROR_MESSAGE = 'No Affirm payment method was found. Make sure the customer completes the checkout process, then try again';
const useAffirmCheckout = ({ nextStep, requestAuthorization, onFail, }) => {
    var _a;
    const dispatch = useDispatch();
    const [linkText, setLinkText] = useState('');
    const [infoCopy, setInfoCopy] = useState('After the customer has completed the Affirm checkout on their device, check for payment to move ahead.');
    const [isLoading, setIsLoading] = useState(true);
    const { referenceNum } = useParams();
    const plan = useSelector(selectSelectedPlan);
    const contactInfo = useSelector(selectRequestContactInfoReferenceNum(referenceNum));
    const { consumerId } = contactInfo;
    const planName = (_a = plan === null || plan === void 0 ? void 0 : plan.planName) === null || _a === void 0 ? void 0 : _a.split('-')[1];
    const request = useSelector(selectRequestRequestReferenceNum(referenceNum));
    const getLink = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const linkResponse = yield dispatch(getAffirmCheckoutMagicLink({
                referenceNum,
                consumerId,
            }));
            setLinkText(linkResponse === null || linkResponse === void 0 ? void 0 : linkResponse.payload.loginLink);
        }
        finally {
            setIsLoading(false);
        }
    });
    const getInfoCopy = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield getEntry(Config.REACT_APP_CONTENTFUL_ID_UPSELL_AFFIRM_INFO);
        setInfoCopy(response.fields.pitchCopy);
    });
    const handleSendMail = () => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(sendAffirmCheckoutLinkByEmail({
            affirmUrl: linkText,
            consumerId,
            referenceNum,
        }));
        toast.success('Email sent');
    });
    const handleCheckForPayment = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d;
        let affirmSuccessfulPayment = false;
        if (planName) {
            const carDetails = yield dispatch(getRequestCar(referenceNum, request.id));
            if ((carDetails === null || carDetails === void 0 ? void 0 : carDetails.error) || !((_c = (_b = carDetails === null || carDetails === void 0 ? void 0 : carDetails.payload) === null || _b === void 0 ? void 0 : _b.subscriptions) === null || _c === void 0 ? void 0 : _c.length)) {
                toast.error(AFFIRM_ERROR_MESSAGE);
                return;
            }
            affirmSuccessfulPayment = carDetails.payload.subscriptions.find((subscription) => subscription.status === 'ACTIVE');
        }
        else {
            const paymentMethodsResponse = yield dispatch(loadPaymentMethodsByConsumer(consumerId, referenceNum));
            const foundAffirmPayment = (_d = paymentMethodsResponse === null || paymentMethodsResponse === void 0 ? void 0 : paymentMethodsResponse.payload) === null || _d === void 0 ? void 0 : _d.find((payment) => payment.methodType === 'AFFIRM');
            if (foundAffirmPayment) {
                affirmSuccessfulPayment = true;
            }
        }
        if (!affirmSuccessfulPayment && onFail) {
            onFail();
            return;
        }
        if (!affirmSuccessfulPayment) {
            toast.error(AFFIRM_ERROR_MESSAGE);
            return;
        }
        if (affirmSuccessfulPayment && nextStep) {
            if (!planName && requestAuthorization) {
                yield requestAuthorization(false, null, 'Affirm', '');
            }
            nextStep();
        }
    });
    useEffect(() => {
        getLink();
        getInfoCopy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        linkText,
        infoCopy,
        isLoading,
        setLinkText,
        setInfoCopy,
        setIsLoading,
        referenceNum,
        consumerId,
        planName,
        request,
        getLink,
        getInfoCopy,
        handleSendMail,
        handleCheckForPayment,
    };
};
export default useAffirmCheckout;
