import { Formik } from 'formik';
import { capitalize } from 'lodash';
import React from 'react';
import { Grid, TimePicker, Typography } from 'src/components/mui';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SubHeader, } from 'src/components/SimpleDrawer';
import { Weekdays } from 'src/models/enums';
import { getMomentValueOr8am, getTimeString } from 'src/utils/formatter';
import useLocationHoursOfOperation from 'src/PopsApp/pages/Distributors/LocationHoursOfOperationDrawer/useLocationHoursOfOperation';
import { TIME_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
const WEEKDAYS = Object.keys(Weekdays);
const LocationHoursOfOperationDrawer = ({ open, onClose, location, }) => {
    const { isLoading, getInitialValues, handleFormSubmit } = useLocationHoursOfOperation({ location, onClose });
    return (<SimpleDrawer open={open} onClose={onClose} title="Hours of Operation" size="half" isLoading={isLoading}>
      <Formik initialValues={getInitialValues()} enableReinitialize onSubmit={handleFormSubmit}>
        {({ isSubmitting, isValid, isValidating, setFieldValue, handleSubmit, values, }) => {
            return (<>
              <SimpleDrawerContent>
                <SubHeader>
                  {location === null || location === void 0 ? void 0 : location.partDistributorName} {location === null || location === void 0 ? void 0 : location.address}
                </SubHeader>

                <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                  <Grid item xs={4}>
                    <Typography gutterBottom>Day</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography gutterBottom align="center">
                      Start time
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography gutterBottom align="center">
                      End time
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography gutterBottom align="center">
                      Open
                    </Typography>
                  </Grid>
                </Grid>
                {WEEKDAYS.map((day) => (<Grid container spacing={5} style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                    }} key={day}>
                    <Grid item xs={4}>
                      <Typography>{capitalize(day.toLowerCase())}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <TimePicker value={getMomentValueOr8am(values[day].openTime, TIME_FORMAT)} onChange={(val) => {
                        setFieldValue(day, Object.assign(Object.assign({}, values[day]), { openTime: getTimeString(val, TIME_FORMAT) }));
                    }} minutesStep={1} format={TIME_12H_FORMAT} slotProps={{
                        textField: {
                            variant: 'standard',
                            margin: 'none',
                        },
                    }}/>
                    </Grid>
                    <Grid item xs={3}>
                      <TimePicker value={getMomentValueOr8am(values[day].closeTime, TIME_FORMAT)} onChange={(val) => {
                        setFieldValue(day, Object.assign(Object.assign({}, values[day]), { closeTime: getTimeString(val, TIME_FORMAT) }));
                    }} minutesStep={1} format={TIME_12H_FORMAT} slotProps={{
                        textField: {
                            variant: 'standard',
                            margin: 'none',
                        },
                    }}/>
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                      <LabelledCheckbox label={null} checkboxProps={{
                        name: day,
                        checked: values[day].open,
                        onChange: (e) => {
                            setFieldValue(day, Object.assign(Object.assign({}, values[day]), { open: e.target.checked }));
                        },
                    }}/>
                    </Grid>
                  </Grid>))}
              </SimpleDrawerContent>

              <SimpleDrawerFooter>
                <FooterActionButton disabled={!isValid || isValidating || isSubmitting || isLoading} onClick={handleSubmit} isSubmitting={isSubmitting}>
                  Save
                </FooterActionButton>
              </SimpleDrawerFooter>
            </>);
        }}
      </Formik>
    </SimpleDrawer>);
};
export default LocationHoursOfOperationDrawer;
