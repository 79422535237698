var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFunnelNotes, saveNote, updateNote, } from 'src/PartnersApp/modules/partners/actions';
const useInternalNotes = ({ partner, onClose }) => {
    const dispatch = useDispatch();
    const [noteInput, setNoteInput] = useState();
    const [internalNote, setInternalNote] = useState();
    useEffect(() => {
        var _a;
        if (!partner || partner.funnels === null || ((_a = partner.funnels) === null || _a === void 0 ? void 0 : _a.length) === 0)
            return;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _b;
            const notesResponse = yield dispatch(getFunnelNotes({ funnelId: partner.funnels[0].id }));
            if (!notesResponse.error && ((_b = notesResponse.payload) === null || _b === void 0 ? void 0 : _b.content.length)) {
                setNoteInput(notesResponse.payload.content[0].content);
                setInternalNote(notesResponse.payload.content[0]);
            }
        }))();
    }, [dispatch, partner]);
    const handleNotesChange = (e) => {
        setNoteInput(e.target.value);
    };
    const handleSaveNote = () => __awaiter(void 0, void 0, void 0, function* () {
        let saveResponse = null;
        if (internalNote === null || internalNote === void 0 ? void 0 : internalNote.id) {
            saveResponse = yield dispatch(updateNote(Object.assign(Object.assign({}, internalNote), { content: noteInput !== null && noteInput !== void 0 ? noteInput : '' })));
        }
        else {
            if (!partner.funnels[0].id)
                return;
            saveResponse = yield dispatch(saveNote({
                entityId: partner.funnels[0].id,
                entityType: 'FUNNEL',
                origin: 'TOOLS',
                content: noteInput !== null && noteInput !== void 0 ? noteInput : '',
            }));
        }
        if (!saveResponse.error) {
            onClose();
        }
    });
    return {
        handleNotesChange,
        handleSaveNote,
        noteInput,
    };
};
export default useInternalNotes;
