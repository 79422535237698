import React from 'react';
export const AvatarIcon = () => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z" id="0218b379aa"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <use stroke="#3F3F3F" strokeWidth="1.5" fillRule="nonzero" xlinkHref="#0218b379aa"/>
      </g>
    </svg>);
};
