import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'src/components/mui';
import SimpleChip from 'src/components/SimpleChip';
import { colors } from 'src/styles/theme';
const SubletInfoWrapper = styled.div `
  margin: 24px 0 16px 0;
`;
const SubletInfo = ({ sublet, onRemove }) => {
    if (!sublet)
        return null;
    const isOldSublet = !!sublet.suggestedRepairId;
    return (<SubletInfoWrapper>
      <div>
        <SimpleChip $bgColor={colors.orange} $textColor={colors.black} label="Sublet"/>
      </div>
      <Typography variant="subtitle1">{sublet.shopName}</Typography>
      <Typography variant="subtitle1" style={{ fontWeight: 'normal' }}>
        {sublet.shopAddress}, {sublet.shopCity}, {sublet.shopState}{' '}
        {sublet.shopZip}{' '}
      </Typography>
      <Typography variant="subtitle1" style={{ fontWeight: 'normal' }}>
        Contact: {sublet.shopContactName || 'N/A'} • {sublet.shopPhone || 'N/A'}
      </Typography>
      {isOldSublet && (<Button style={{ color: colors.error }} onClick={onRemove}>
          Remove Sublet
        </Button>)}
    </SubletInfoWrapper>);
};
export default SubletInfo;
