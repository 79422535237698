import React from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreators, clearSchedulingAvailability, clearTimeslot, setContinueDisabled, } from 'src/AdminApp/modules/quickRo/actions';
import { Tab, Tabs } from 'src/components/mui';
import useIsMobile from 'src/hooks/useIsMobile';
import styled from 'styled-components';
const SchedulingTabsStyled = styled(Tabs) `
  padding-top: ${({ isMobile }) => (isMobile === 'true' ? '24px' : 0)};
  margin-right: ${({ isMobile }) => (isMobile === 'true' ? 0 : '24px')};
  button {
    min-width: auto;
  }
`;
const SchedulingTabs = ({ currentTab, setCurrentTab, enableSpecificTime, setManualEntryMechanic, setSelectedDateTime, setSelectedFutureAppointmentDate, setSelectedMinFutureAppointmentDate, setScheduleFutureAppointmentsCheck, }) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const clearMechanicIds = () => {
        dispatch(ActionCreators.TAB.CLEAR_MECHANIC_IDS());
        dispatch(ActionCreators.TAB.CLEAR_VAN_ID());
    };
    const onTabChange = (event, tab) => {
        clearMechanicIds();
        setManualEntryMechanic('');
        dispatch(ActionCreators.TAB.CLEAR_TIME_SLOT());
        dispatch(ActionCreators.TAB.CLEAR_DATE_TIME());
        setSelectedDateTime(undefined);
        setCurrentTab(tab);
        if (enableSpecificTime && tab === 1) {
            dispatch(setContinueDisabled(true));
        }
        else {
            setSelectedFutureAppointmentDate(undefined);
            setSelectedMinFutureAppointmentDate(undefined);
            setScheduleFutureAppointmentsCheck(false);
            dispatch(clearTimeslot());
            dispatch(clearSchedulingAvailability());
            dispatch(setContinueDisabled(false));
        }
    };
    return (<SchedulingTabsStyled isMobile={isMobile.toString()} value={currentTab} onChange={onTabChange} variant="fullWidth" centered scrollButtons="auto" aria-label="Schedule appointment scrollable tabs">
      <Tab label="Open Schedule"/>
      {enableSpecificTime && <Tab label="Manual Entry"/>}
    </SchedulingTabsStyled>);
};
export default SchedulingTabs;
