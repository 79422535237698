var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Formik, Form } from 'formik';
import FilterButtons from 'src/components/dashboard/DashboardFilter/FilterButtons';
const SearchTerritoriesFilters = ({ onSearch, onReset, onClear, }) => {
    const resetFilter = () => {
        onReset();
    };
    const clearFilter = () => {
        resetFilter();
        onClear();
    };
    return (<Formik enableReinitialize initialValues={{
            query: '',
        }} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            const submitValue = Object.assign({}, values);
            submitValue.query = values.query.trim();
            yield onSearch(submitValue);
            setSubmitting(false);
        })}>
      {({ isSubmitting, resetForm }) => (<Form>
          <FilterButtons onClear={() => {
                clearFilter();
                resetForm();
            }} onReset={() => {
                resetFilter();
                resetForm();
            }} queryLabelText="Filter by territory, metro" isSubmitting={isSubmitting}/>
        </Form>)}
    </Formik>);
};
export default SearchTerritoriesFilters;
