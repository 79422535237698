import { forEach, isArray as _isArray } from 'lodash';
import { handleActions } from 'redux-actions';
import { ActionCreators as ConsumerActionCreators } from 'src/AdminApp/modules/consumers/actions';
import { mapAppointment, mapPagination, mapReferenceNumsToIds, mapRepairOrder, mapRepairRequest, mapRepairRequestReferenceNumsToIds, mapRequestCar, mapUpdatedConsumerContactInfo, } from 'src/AdminApp/modules/mappers';
import { ActionCreators as OrderActionCreators } from 'src/AdminApp/modules/orders/actions';
import { ActionCreators, markFourCornerPhotoUploading, setIsMpiAlertShowing, setShowMpiSentToCustomerAlert, unmarkFourCornerPhotoUploading, closeServiceDrawer, openServiceDrawer, } from 'src/AdminApp/modules/requests/actions';
import { defaultServiceDrawerState, } from 'src/AdminApp/modules/requests/repairs.state';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { fetched } from 'src/utils/data-utils';
import { selectDashboardDefaultFilters } from './selectors';
export const DEFAULT_STATE = {
    byId: {},
    dashboard: {
        params: {
            filters: selectDashboardDefaultFilters(),
            pagination: {
                page: 0,
                size: 20,
                sort: ['repairOrder.created,desc'],
            },
        },
        data: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            offset: 0,
            totalElements: 0,
            totalPages: 0,
            sort: [],
        },
        _isDownloading: false,
        _isFetching: false,
    },
    repairs: [],
    snapshots: {},
    referenceNumToIds: {},
    responsiblePartyTypeModal: {
        repairId: undefined,
        customRepairId: undefined,
        diagnosisId: undefined,
        repairName: undefined,
        totalCost: undefined,
        workOrderItemResponsibleParties: [],
    },
    authorizeModalOpen: false,
    paymentDrawerOpen: false,
    warrantyQuestionsDrawerOpen: false,
    fourCornerModal: false,
    fourCornerFromHeader: false,
    mobileCarDetails: false,
    resolutions: [],
    cancelFeeMaxHours: undefined,
    payments: {},
    responsiblePartyTypes: undefined,
    responsiblePartiesFromType: undefined,
    partPickups: undefined,
    serviceDrawer: defaultServiceDrawerState,
};
const successConsumerRequestHistoryReducer = (state, action) => {
    const referenceNumToIds = mapReferenceNumsToIds(state.referenceNumToIds, action.payload.content);
    return Object.assign(Object.assign({}, state), { referenceNumToIds });
};
const successRequestReducer = (state, action) => {
    var _a, _b;
    const requestData = fetched(mapRepairRequest(action.payload));
    const { referenceNum, id } = requestData.request;
    const serviceAdvisorNotes = (_b = (_a = state.byId[referenceNum]) === null || _a === void 0 ? void 0 : _a.request) === null || _b === void 0 ? void 0 : _b.serviceAdvisorNotes;
    const res = Object.assign(Object.assign({}, state.byId[referenceNum]), { request: Object.assign(Object.assign({}, requestData.request), { serviceAdvisorNotes }), contactInfo: requestData.contactInfo });
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: res });
    const referenceNumToIds = Object.assign(Object.assign({}, state.referenceNumToIds), { [referenceNum]: id });
    return Object.assign(Object.assign({}, state), { byId,
        referenceNumToIds });
};
const successLoadRequestHistoryReducer = (state, action) => {
    const requestHistory = action.payload;
    const { referenceNum } = action.meta;
    const res = Object.assign(Object.assign({}, state.byId[referenceNum]), { history: requestHistory });
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: res });
    return Object.assign(Object.assign({}, state), { byId });
};
const successRequestUpdateReducer = (state, action) => {
    const requestStateData = fetched(mapRepairRequest(action.payload));
    const { referenceNum } = action.meta;
    const res = Object.assign(Object.assign({}, state.byId[referenceNum]), { state: requestStateData });
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: res });
    return Object.assign(Object.assign({}, state), { byId });
};
const successCarReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const car = fetched(mapRequestCar(action.payload));
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { car }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successDiagnosisReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const diagnosis = fetched(action.payload);
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { diagnosis }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successOrdersReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const ordersArray = _isArray(action.payload)
        ? action.payload
        : [action.payload];
    const orders = ordersArray.map(mapRepairOrder).map(fetched);
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { orders }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successAppointmentsReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const requestData = state.byId[referenceNum];
    const { activeAppointment } = requestData.request;
    const payload = _isArray(action.payload) ? action.payload : [action.payload];
    const appointments = payload.map(mapAppointment).map(fetched);
    const newActiveAppointment = appointments.find((appointment) => appointment.id === activeAppointment.id);
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, requestData), { request: Object.assign(Object.assign({}, requestData.request), { activeAppointment: newActiveAppointment }), appointments }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successAppointmentReducer = (state, action) => {
    var _a, _b;
    const { referenceNum, orderId } = action.meta;
    const requestData = state.byId[referenceNum];
    const { activeAppointment } = requestData.request;
    const updatedAppointment = (_a = action.payload.appointment) !== null && _a !== void 0 ? _a : action.payload;
    const orders = (_b = requestData === null || requestData === void 0 ? void 0 : requestData.orders) === null || _b === void 0 ? void 0 : _b.map((order) => {
        return order.id === orderId
            ? Object.assign(Object.assign({}, order), { appointment: updatedAppointment }) : order;
    });
    const appointments = requestData.appointments.map((appointment) => {
        return appointment.id === updatedAppointment.id
            ? updatedAppointment
            : appointment;
    });
    const newActiveAppointment = updatedAppointment.id === activeAppointment.id
        ? updatedAppointment
        : activeAppointment;
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, requestData), { request: Object.assign(Object.assign({}, requestData.request), { activeAppointment: newActiveAppointment }), appointments,
            orders }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successRequestDashboardReducer = (state, action) => {
    const response = Object.assign(Object.assign({}, action.payload), { content: action.payload.content });
    const referenceNumToIds = mapRepairRequestReferenceNumsToIds(state.referenceNumToIds, action.payload.content);
    const pagination = mapPagination(response);
    return Object.assign(Object.assign({}, state), { dashboard: fetched(Object.assign(Object.assign({}, state.dashboard), { pagination, data: response.content, _isFetching: false })), referenceNumToIds });
};
const fetchRequestDashboardReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const clearRequestDashboardReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const clearFiltersRequestDashboardReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params: Object.assign(Object.assign({}, state.dashboard.params), { filters: selectDashboardDefaultFilters() }) }) });
};
const successUpdateConsumerContactInfoReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const consumerContactInfo = fetched(mapUpdatedConsumerContactInfo(action.payload));
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { contactInfo: Object.assign(Object.assign({}, state.byId[referenceNum].contactInfo), consumerContactInfo) }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successRequestLogoReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { logo: action.payload }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successSkeduloReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { skedulo: fetched(action.payload) }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successRecommendedRepairsReducer = (state, action) => {
    const { recommendedRepairs, rejectedRepairs, scheduledRepairs, createdRepairs, subTotal, } = action.payload;
    const { referenceNum } = action.meta;
    const res = Object.assign(Object.assign({}, state.byId[referenceNum]), { recommendedRepairs,
        rejectedRepairs,
        scheduledRepairs,
        createdRepairs, recommendedRepairsSubTotal: subTotal });
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: res });
    return Object.assign(Object.assign({}, state), { byId });
};
const fetchSubmitRecommendedRepairsReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { _isSubmitting: true }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successSubmitRecommendedRepairsReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { _isSubmitting: false }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successRelatedPartsReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const relatedPartsBySuggestedRepair = {};
    forEach(action.payload, (repair) => {
        relatedPartsBySuggestedRepair[repair.suggestedRepairId] =
            repair.relatedParts;
    });
    const res = Object.assign(Object.assign({}, state.byId[referenceNum]), { relatedPartsBySuggestedRepair });
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: res });
    return Object.assign(Object.assign({}, state), { byId });
};
const suggestedRepairReducer = (state, action) => {
    const suggestedRepair = fetched(action.payload);
    const { referenceNum } = action.meta;
    const res = Object.assign(Object.assign({}, state.byId[referenceNum]), { updatedSuggestedRepair: suggestedRepair });
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: res });
    return Object.assign(Object.assign({}, state), { byId });
};
const isMpiAlertShowingReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const res = Object.assign(Object.assign({}, state.byId[referenceNum]), { isMpiAlertShowing: action.payload });
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: res });
    return Object.assign(Object.assign({}, state), { byId });
};
const showMpiSentToCustomerAlertReducer = (state, action) => {
    const { referenceNum } = action.meta;
    const res = Object.assign(Object.assign({}, state.byId[referenceNum]), { showMpiSentToCustomerAlert: action.payload });
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: res });
    return Object.assign(Object.assign({}, state), { byId });
};
const successConsumerServiceAdvisorNotesReducer = (state, action) => {
    const serviceAdvisorNotes = action.payload.content;
    const { referenceNum } = action.meta;
    const request = Object.assign(Object.assign({}, state.byId[referenceNum].request), { serviceAdvisorNotes });
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { request }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successRequestsByCarStatesReducer = (state, action) => {
    const openCarRequests = action.payload;
    const { referenceNum } = action.meta;
    const res = Object.assign(Object.assign({}, state.byId[referenceNum]), { openCarRequests });
    const byId = Object.assign(Object.assign({}, state.byId), { [referenceNum]: res });
    return Object.assign(Object.assign({}, state), { byId });
};
const successSnapshot = (state, action) => {
    const { referenceNum } = action.meta;
    const data = action.payload.content;
    const parseData = (snapshot) => (Object.assign(Object.assign({}, snapshot), { dataV1: JSON.parse(snapshot.dataV1), dataV2: JSON.parse(snapshot.dataV2), dataV2Dto: JSON.parse(snapshot.dataV2Dto) }));
    const snapshots = Object.assign(Object.assign({}, state.snapshots.byReferenceNum), { [referenceNum]: data.map(parseData) });
    return Object.assign(Object.assign({}, state), { snapshots });
};
const setResponsiblePartyTypeModalRepair = (state, action) => {
    return Object.assign(Object.assign({}, state), { responsiblePartyTypeModal: Object.assign({}, action.payload) });
};
const clearResponsiblePartyTypeModalRepair = (state) => (Object.assign(Object.assign({}, state), { responsiblePartyTypeModal: Object.assign({}, DEFAULT_STATE.responsiblePartyTypeModal) }));
const createWorkOrderResponsiblePartyAuthorization = (state, { payload, meta }) => {
    const { referenceNum } = meta;
    const result = Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { diagnosis: Object.assign(Object.assign({}, state.byId[referenceNum].diagnosis), { workOrderResponsiblePartyAuthorization: payload }) }) }) });
    return result;
};
const removeWorkOrderResponsiblePartyAuthorization = (state, { meta }) => {
    const { referenceNum } = meta;
    const result = Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { diagnosis: Object.assign(Object.assign({}, state.byId[referenceNum].diagnosis), { workOrderResponsiblePartyAuthorization: null }) }) }) });
    return result;
};
const setOpenAuthorizeModal = (state, action) => {
    return Object.assign(Object.assign({}, state), { authorizeModalOpen: action.payload.isOpen });
};
const setPaymentDrawerOpen = (state, action) => {
    return Object.assign(Object.assign({}, state), { paymentDrawerOpen: action.payload.isOpen });
};
const setWarrantyClaimQuestionstDrawerOpen = (state, action) => {
    return Object.assign(Object.assign({}, state), { warrantyQuestionsDrawerOpen: action.payload.isOpen });
};
const setMeasurementsDrawerOpen = (state, action) => {
    return Object.assign(Object.assign({}, state), { measurementsDrawer: {
            open: action.payload.isOpen,
            nodeId: action.payload.nodeId,
            required: action.payload.required,
        } });
};
const setCopyQuoteToInvoiceModalOpen = (state, action) => {
    return Object.assign(Object.assign({}, state), { copyQuoteToInvoiceModalOpen: action.payload.isOpen });
};
const successGetResponsiblePartyTypes = (state, action) => {
    return Object.assign(Object.assign({}, state), { responsiblePartyTypes: action.payload });
};
const successGetResponsiblePartyByConsumerId = (state, action) => {
    const { referenceNum } = action.meta;
    const reqsById = state.byId;
    const byId = Object.assign(Object.assign({}, reqsById), { [referenceNum]: Object.assign(Object.assign({}, reqsById[referenceNum]), { primaryResponsibleParty: action.payload }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successGetResponsiblePartyByFleetId = (state, action) => {
    const { referenceNum } = action.meta;
    const reqsById = state.byId;
    const byId = Object.assign(Object.assign({}, reqsById), { [referenceNum]: Object.assign(Object.assign({}, reqsById[referenceNum]), { primaryResponsibleParty: action.payload }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successSaveResponsibleParties = (state, action) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const referenceNum = (_a = action.meta) === null || _a === void 0 ? void 0 : _a.referenceNum;
    const customRepairId = (_b = action.meta) === null || _b === void 0 ? void 0 : _b.customRepairId;
    const repairId = (_c = action.meta) === null || _c === void 0 ? void 0 : _c.repairId;
    let workOrderItemResponsibleParties = [];
    if (referenceNum) {
        if (((_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.length) > 0) {
            workOrderItemResponsibleParties = action === null || action === void 0 ? void 0 : action.payload;
        }
        else {
            workOrderItemResponsibleParties = (_e = action.meta) === null || _e === void 0 ? void 0 : _e.data;
        }
        if (repairId) {
            workOrderItemResponsibleParties = workOrderItemResponsibleParties.filter((x) => x.suggestedRepairId === repairId);
        }
        if (workOrderItemResponsibleParties === null || workOrderItemResponsibleParties === void 0 ? void 0 : workOrderItemResponsibleParties.length) {
            const suggestedRepairId = (_f = workOrderItemResponsibleParties[0]) === null || _f === void 0 ? void 0 : _f.suggestedRepairId;
            const carDiagnosisId = (_g = workOrderItemResponsibleParties[0]) === null || _g === void 0 ? void 0 : _g.carDiagnosisId;
            const carDiagnosisIdOrSuggestedRepairId = customRepairId || suggestedRepairId || carDiagnosisId;
            let statePath = [];
            if (customRepairId) {
                statePath = ['diagnosis', 'diagnosisServices', 'customRequests'];
            }
            else if (suggestedRepairId) {
                statePath = ['diagnosis', 'diagnosisServices', 'knownRepairs'];
            }
            else if (carDiagnosisId) {
                statePath = ['diagnosis', 'diagnosisServices', 'diagnoses'];
            }
            if (carDiagnosisIdOrSuggestedRepairId && statePath.length) {
                workOrderItemResponsibleParties.forEach((x, index) => {
                    x.id =
                        x.id ||
                            `workOrderItemResponsibleParty${carDiagnosisIdOrSuggestedRepairId}${index}`;
                });
                const updateDiagnosis = (_h = state.byId[referenceNum].statePath) !== null && _h !== void 0 ? _h : [];
                updateDiagnosis.forEach((x) => {
                    if (x.id === carDiagnosisIdOrSuggestedRepairId) {
                        x.workOrderItemResponsibleParties = workOrderItemResponsibleParties;
                    }
                });
            }
        }
    }
    return Object.assign({}, state);
};
const markFourCorners = (state, action) => {
    var _a, _b, _c, _d;
    const { referenceNum, photoType } = action.payload;
    const reqsById = state.byId;
    // DON'T ADD THE SAME TYPE TWICE
    if ((_b = (_a = reqsById[referenceNum].car) === null || _a === void 0 ? void 0 : _a.photosUploading) === null || _b === void 0 ? void 0 : _b.includes(photoType)) {
        return Object.assign({}, state);
    }
    const byId = Object.assign(Object.assign({}, reqsById), { [referenceNum]: Object.assign(Object.assign({}, reqsById[referenceNum]), { car: Object.assign(Object.assign({}, reqsById[referenceNum].car), { photosUploading: [
                    ...((_d = (_c = reqsById[referenceNum].car) === null || _c === void 0 ? void 0 : _c.photosUploading) !== null && _d !== void 0 ? _d : []),
                    photoType,
                ] }) }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const unmarkFourCorners = (state, action) => {
    var _a, _b, _c, _d, _e;
    const { referenceNum, photoType } = action.payload;
    const reqsById = state.byId;
    // RETURN IF TYPE IS NOT IN STATE
    if (!((_b = (_a = reqsById[referenceNum].car) === null || _a === void 0 ? void 0 : _a.photosUploading) === null || _b === void 0 ? void 0 : _b.includes(photoType))) {
        return Object.assign({}, state);
    }
    const byId = Object.assign(Object.assign({}, reqsById), { [referenceNum]: Object.assign(Object.assign({}, reqsById[referenceNum]), { car: Object.assign(Object.assign({}, reqsById[referenceNum].car), { photosUploading: (_e = (_d = (_c = reqsById[referenceNum]) === null || _c === void 0 ? void 0 : _c.car) === null || _d === void 0 ? void 0 : _d.photosUploading) === null || _e === void 0 ? void 0 : _e.filter((item) => item !== photoType) }) }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successCarPhotoUpdate = (state, action) => {
    var _a, _b, _c, _d;
    const { referenceNum } = action.meta;
    const allPhotosUploaded = ((_c = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.photos) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) >= 5;
    const reqsById = state.byId;
    const byId = Object.assign(Object.assign({}, reqsById), { [referenceNum]: Object.assign(Object.assign({}, reqsById[referenceNum]), { car: Object.assign(Object.assign({}, reqsById[referenceNum].car), { photos: (_d = action.payload) === null || _d === void 0 ? void 0 : _d.photos, allUploaded: allPhotosUploaded }) }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const openFourCornerModal = (state, { payload }) => (Object.assign(Object.assign({}, state), { fourCornerModal: true, fourCornerFromHeader: !!payload }));
const closeFourCornerModal = (state) => (Object.assign(Object.assign({}, state), { fourCornerModal: false, fourCornerFromHeader: false }));
const openMobileCarDetails = (state) => (Object.assign(Object.assign({}, state), { mobileCarDetails: true }));
const closeMobileCarDetails = (state) => (Object.assign(Object.assign({}, state), { mobileCarDetails: false }));
const fetchResolutions = (state, { payload }) => (Object.assign(Object.assign({}, state), { resolutions: payload }));
const fetchCancelFeeMaxHours = (state, { payload }) => (Object.assign(Object.assign({}, state), { cancelFeeMaxHours: payload === null || payload === void 0 ? void 0 : payload.cancelFeeMaxHoursAllowed }));
const getWorkOrderPayments = (state, { meta, payload }) => (Object.assign(Object.assign({}, state), { payments: Object.assign(Object.assign({}, state.payments), { [meta.workOrderId]: payload }) }));
const fetchRepairMeasurementsReducer = (state, { meta }) => {
    const { referenceNum } = meta;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { measurementData: {
                    _isFetching: true,
                } }) }) });
};
const successRepairMeasurementsReducer = (state, { meta, payload }) => {
    const repairMeasurements = payload;
    const { referenceNum } = meta;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { measurementData: {
                    measurements: repairMeasurements,
                    _isFetching: false,
                } }) }) });
};
const successJobQueuePositionReducer = (state, { meta, payload }) => {
    const queuePosition = payload;
    const { referenceNum } = meta;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [referenceNum]: Object.assign(Object.assign({}, state.byId[referenceNum]), { queuePosition }) }) });
};
const reducers = handleActions({
    [ActionCreators.REQUESTS_BY_CAR_STATES.SUCCESS.type]: successRequestsByCarStatesReducer,
    [ActionCreators.REQUEST_BY_ID.SUCCESS.type]: successRequestReducer,
    [ActionCreators.LOAD_REQUEST_HISTORY.SUCCESS.type]: successLoadRequestHistoryReducer,
    [ActionCreators.REQUEST_CREATE.SUCCESS.type]: successRequestReducer,
    [ActionCreators.REQUEST_UPDATE.SUCCESS.type]: successRequestReducer,
    [OrderActionCreators.UPDATE_ORDER.SUCCESS.type]: successOrdersReducer,
    [OrderActionCreators.UPDATE_APPOINTMENT.SUCCESS.type]: successAppointmentReducer,
    [OrderActionCreators.CANCEL_ORDER.SUCCESS.type]: successAppointmentReducer,
    [OrderActionCreators.CONSUMER_CONFIRM_APPOINTMENT.SUCCESS.type]: successAppointmentReducer,
    [ConsumerActionCreators.CONSUMER_REQUEST_HISTORY.SUCCESS.type]: successConsumerRequestHistoryReducer,
    [ActionCreators.REQUEST_BY_REFERENCE_NUM.SUCCESS.type]: successRequestReducer,
    [ActionCreators.REQUEST_UPDATE_STATE.SUCCESS.type]: successRequestUpdateReducer,
    [ActionCreators.REQUEST_CAR.SUCCESS.type]: successCarReducer,
    [ActionCreators.REQUEST_UPDATE_CAR.SUCCESS.type]: successCarReducer,
    [ActionCreators.REQUEST_UPDATE_CONTACT.SUCCESS.type]: successUpdateConsumerContactInfoReducer,
    [ActionCreators.QUOTE_DIAGNOSIS.SUCCESS.type]: successDiagnosisReducer,
    [ActionCreators.REQUEST_RELATED_PARTS.SUCCESS.type]: successRelatedPartsReducer,
    [ActionCreators.REQUEST_APPOINTMENTS.SUCCESS.type]: successAppointmentsReducer,
    [ActionCreators.REQUEST_ORDERS.SUCCESS.type]: successOrdersReducer,
    [ActionCreators.REQUEST_DASHBOARD.SUCCESS.type]: successRequestDashboardReducer,
    [ActionCreators.REQUEST_DASHBOARD.FETCH.type]: fetchRequestDashboardReducer,
    [ActionCreators.REQUEST_DASHBOARD.CLEAR.type]: clearRequestDashboardReducer,
    [ActionCreators.REQUEST_DASHBOARD.CLEAR_FILTERS.type]: clearFiltersRequestDashboardReducer,
    [ActionCreators.REQUEST_PROVIDE_QUOTE.SUCCESS.type]: successRequestReducer,
    [ActionCreators.GET_LOGO_FOR_REQUEST.SUCCESS.type]: successRequestLogoReducer,
    [ActionCreators.SKEDULO_CREATE_JOB.SUCCESS.type]: successSkeduloReducer,
    [ActionCreators.GET_RECOMMENDED_REPAIRS.SUCCESS.type]: successRecommendedRepairsReducer,
    [ActionCreators.GET_CONSUMER_SERVICE_ADVISOR_NOTES.SUCCESS.type]: successConsumerServiceAdvisorNotesReducer,
    [ActionCreators.ADD_RECOMMENDED_REPAIRS.FETCH.type]: fetchSubmitRecommendedRepairsReducer,
    [ActionCreators.ADD_RECOMMENDED_REPAIRS.SUCCESS.type]: successSubmitRecommendedRepairsReducer,
    [ActionCreators.ADD_RECOMMENDED_REPAIRS.FAILURE.type]: successSubmitRecommendedRepairsReducer,
    [ActionCreators.UPDATE_SUGGESTED_REPAIR.SUCCESS.type]: suggestedRepairReducer,
    [ActionCreators.LOAD_REQUEST_SNAPSHOT.SUCCESS.type]: successSnapshot,
    [setIsMpiAlertShowing.type]: isMpiAlertShowingReducer,
    [setShowMpiSentToCustomerAlert.type]: showMpiSentToCustomerAlertReducer,
    AUTHORIZE_MODAL_OPEN: setOpenAuthorizeModal,
    PAYMENT_SECTION_OPEN: setPaymentDrawerOpen,
    WARRANTY_QUESTIONS_SECTION_OPEN: setWarrantyClaimQuestionstDrawerOpen,
    MEASUREMENTS_SECTION_OPEN: setMeasurementsDrawerOpen,
    COPY_QUOTE_TO_INVOICE_MODAL_OPEN: setCopyQuoteToInvoiceModalOpen,
    [ActionCreators.CAR_PHOTO.SUCCESS.type]: successCarPhotoUpdate,
    [ActionCreators.FOUR_CORNER_MODAL.OPEN.type]: openFourCornerModal,
    [ActionCreators.FOUR_CORNER_MODAL.CLOSE.type]: closeFourCornerModal,
    [ActionCreators.MOBILE_CAR_DETAILS.OPEN.type]: openMobileCarDetails,
    [ActionCreators.MOBILE_CAR_DETAILS.CLOSE.type]: closeMobileCarDetails,
    [ActionCreators.PAYER_TYPE_MODAL.SET_REPAIR.type]: setResponsiblePartyTypeModalRepair,
    [ActionCreators.CLOSE_PAYER_TYPE_MODAL.SUCCESS.type]: clearResponsiblePartyTypeModalRepair,
    [ActionCreators.CREATE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.SUCCESS
        .type]: createWorkOrderResponsiblePartyAuthorization,
    [ActionCreators.DELETE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.SUCCESS
        .type]: removeWorkOrderResponsiblePartyAuthorization,
    [ActionCreators.RESPONSIBLE_PARTY_TYPES.SUCCESS.type]: successGetResponsiblePartyTypes,
    [ActionCreators.GET_RESPONSIBLE_PARTIES_BY_TYPE.SUCCESS.type]: createSimpleHandler('responsiblePartiesFromType'),
    [ActionCreators.GET_CONSUMER_RESPONSIBLE_PARTY.SUCCESS.type]: successGetResponsiblePartyByConsumerId,
    [ActionCreators.GET_FLEET_RESPONSIBLE_PARTY.SUCCESS.type]: successGetResponsiblePartyByFleetId,
    [ActionCreators.SAVE_RESPONSIBLE_PARTIES.SUCCESS.type]: successSaveResponsibleParties,
    [markFourCornerPhotoUploading.type]: markFourCorners,
    [unmarkFourCornerPhotoUploading.type]: unmarkFourCorners,
    [ActionCreators.APPOINTMENT_RESOLUTIONS.SUCCESS.type]: fetchResolutions,
    [ActionCreators.CANCEL_FEE_MAX_HOURS.SUCCESS.type]: fetchCancelFeeMaxHours,
    [ActionCreators.WORK_ORDER_PAYMENTS.SUCCESS.type]: getWorkOrderPayments,
    [ActionCreators.REPAIR_MEASUREMENTS.FETCH.type]: fetchRepairMeasurementsReducer,
    [ActionCreators.REPAIR_MEASUREMENTS.SUCCESS.type]: successRepairMeasurementsReducer,
    [ActionCreators.ORDER_ROSTER_JOB_QUEUE_POSITION.SUCCESS.type]: successJobQueuePositionReducer,
    [ActionCreators.REQUEST_PART_PICKUPS.SUCCESS.type]: createSimpleHandler('partPickups'),
    [openServiceDrawer.type]: (state, action) => {
        const { payload } = action;
        return Object.assign(Object.assign({}, state), { serviceDrawer: Object.assign(Object.assign({}, state.serviceDrawer), payload) });
    },
    [closeServiceDrawer.type]: (state) => (Object.assign(Object.assign({}, state), { serviceDrawer: Object.assign({}, DEFAULT_STATE.serviceDrawer) })),
}, DEFAULT_STATE);
export default reducers;
