import { closedStates, RepairRequestStateInShort, } from 'src/AdminApp/models/enums/RepairRequestState';
export const formatResolution = (state) => {
    if (!state) {
        return null;
    }
    return state
        .split('_')
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join(' ');
};
export const formatState = (state) => {
    if (!state) {
        return null;
    }
    return RepairRequestStateInShort.display(state);
};
export const isRepairRequestClosed = (state) => {
    return closedStates.includes(state);
};
export const formatStateWithResolution = (state, resolution) => {
    if (isRepairRequestClosed(state)) {
        return `Closed: ${formatResolution(resolution)}`;
    }
    return formatState(state);
};
