import React from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { isEmpty } from 'lodash';
import { CloudUpload as CloudUploadIcon } from 'src/components/mui/icons';
import { Button, Typography } from 'src/components/mui';
import InvoiceDocumentViewer from 'src/components/InvoiceDocumentViewer';
import { colors } from 'src/styles/theme';
import InvoicesList from './InvoicesList';
import useOrderReceipt from './useOrderReceipt';
const DropZoneCard = styled(Button) `
  height: 5rem;
  display: flex;
  align-items: center;
  max-width: 367px;
  width: 100%;
  justify-content: center;
  margin-top: 24px;
  border-style: dashed;
  border-color: ${colors.primary};
  font-size: 14px;
  text-transform: none;
  &:hover {
    border-style: dashed;
  }
`;
const UploadIcon = styled(CloudUploadIcon) `
  margin-right: 6px;
`;
const OrderReceipt = ({ canEdit, workOrderId }) => {
    const { documents, invoiceDocumentOpen, documentUrl, onInvoiceDocumentOpen, onInvoiceDocumentClose, onDrop, onDelete, } = useOrderReceipt({ workOrderId });
    return (<div>
      <Typography variant="h5" gutterBottom>
        Receipt
      </Typography>
      {isEmpty(documents) ? (<Typography variant="body1">No Documents Uploaded</Typography>) : (<InvoicesList documents={documents} onOpen={onInvoiceDocumentOpen} onDelete={onDelete}/>)}
      {canEdit && (<Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (<>
              <input {...getInputProps()}/>
              <DropZoneCard {...getRootProps()} color="primary" variant="outlined">
                <UploadIcon /> Drag or click to select files
              </DropZoneCard>
            </>)}
        </Dropzone>)}

      <InvoiceDocumentViewer open={invoiceDocumentOpen} onClose={onInvoiceDocumentClose} url={documentUrl}/>
    </div>);
};
export default OrderReceipt;
