import React from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow/SimpleTwoButtonRow';
import SimpleSingleButtonRow from 'src/components/SimpleSingleButtonRow';
import SimpleDialogTitle from 'src/components/SimpleModal/SimpleDialogTitle';
import { ContentChildren, SimpleDialog, SimpleDialogActions, SimpleDialogContent, } from 'src/components/SimpleModal/SimpleModal.styles';
const SimpleModal = ({ children, actionButtonsRow, onClose, open, title = null, showClose = true, simpleTwoButtonRowProps, simpleSingleButtonRowProps, maxWidth, disableEscapeKeyDown, }) => {
    const isMobile = useIsMobile();
    return (<SimpleDialog open={open} onClose={onClose} fullScreen={isMobile} maxWidth={maxWidth !== null && maxWidth !== void 0 ? maxWidth : (actionButtonsRow || simpleTwoButtonRowProps ? 'md' : 'sm')} className="SimpleModal" disableEscapeKeyDown={disableEscapeKeyDown}>
      {title && (<SimpleDialogTitle onClose={onClose} showClose={showClose}>
          {title}
        </SimpleDialogTitle>)}
      <SimpleDialogContent>
        {children && <ContentChildren>{children}</ContentChildren>}

        <SimpleDialogActions>
          {actionButtonsRow}
          {(simpleTwoButtonRowProps === null || simpleTwoButtonRowProps === void 0 ? void 0 : simpleTwoButtonRowProps.onCancel) &&
            simpleTwoButtonRowProps.onSubmit && (<SimpleTwoButtonRow {...simpleTwoButtonRowProps}/>)}
          {(simpleSingleButtonRowProps === null || simpleSingleButtonRowProps === void 0 ? void 0 : simpleSingleButtonRowProps.onSubmit) && (<SimpleSingleButtonRow {...simpleSingleButtonRowProps}/>)}
        </SimpleDialogActions>
      </SimpleDialogContent>
    </SimpleDialog>);
};
export default SimpleModal;
