var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MenuItem, TextField as MuiTextField, Grid } from 'src/components/mui';
import { Field, Formik } from 'formik';
import { find } from 'lodash';
import React from 'react';
import { SlidePageContent, SlidePageWrapper, } from 'src/AdminApp/components/discounts/components/DiscountDrawer/styledComponents';
import TextField from 'src/AdminApp/components/form/TextField';
import { FooterActionButton, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import useIsMobile from 'src/hooks/useIsMobile';
import { formatCurrency, formatDate } from 'src/utils/formatter';
import * as Yup from 'yup';
import { LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
const CreditPage = ({ onDrawerClose, onSubmit, cancelFeeCredits, }) => {
    const isMobile = useIsMobile();
    const validationSchema = Yup.object().shape({
        creditAmount: Yup.number().min(0).required(),
        invoiceId: Yup.number().required(),
    });
    const formatDisplayCancelFeeCredit = (credit) => {
        return `${formatDate(credit.appointmentDate, `${LOCALIZED_DATE_FORMAT} ${TIME_12H_FORMAT}`)} - ${formatCurrency(credit.totalCredit)}`;
    };
    return (<Formik initialValues={{
            invoiceId: '',
            creditAmount: '',
        }} enableReinitialize onSubmit={(values, { setSubmitting, resetForm }) => {
            onSubmit(values).then(() => {
                resetForm({ values: Object.assign({}, values) });
                setSubmitting(false);
                onDrawerClose();
            });
        }} validationSchema={validationSchema}>
      {({ isSubmitting, isValid }) => (<SlidePageWrapper>
          <SlidePageContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field name="invoiceId" render={({ field, form }) => {
                const handleOnChange = (e) => __awaiter(void 0, void 0, void 0, function* () {
                    var _a;
                    const selectedCredit = find(cancelFeeCredits, {
                        invoiceId: e.target.value,
                    });
                    form.setFieldValue(field.name, selectedCredit === null || selectedCredit === void 0 ? void 0 : selectedCredit.invoiceId);
                    const creditAmount = (_a = selectedCredit === null || selectedCredit === void 0 ? void 0 : selectedCredit.totalCredit) !== null && _a !== void 0 ? _a : '';
                    yield form.setFieldValue('creditAmount', creditAmount);
                    form.setFieldTouched('creditAmount', true);
                });
                return (<MuiTextField {...field} select variant="outlined" label="Cancellation Fee Credit" fullWidth onChange={handleOnChange} SelectProps={{ native: isMobile }}>
                        {cancelFeeCredits.map((credit) => isMobile ? (<option value={credit.invoiceId} key={credit.invoiceId}>
                              {formatDisplayCancelFeeCredit(credit)}
                            </option>) : (<MenuItem value={credit.invoiceId} key={credit.invoiceId}>
                              {formatDisplayCancelFeeCredit(credit)}
                            </MenuItem>))}
                      </MuiTextField>);
            }}/>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth name="creditAmount" type="number" inputType="number" label="Credit Amount" variant="outlined"/>
              </Grid>
            </Grid>
          </SlidePageContent>
          <SimpleDrawerFooter>
            <FooterActionButton disabled={isSubmitting || !isValid} type="submit">
              Add
            </FooterActionButton>
          </SimpleDrawerFooter>
        </SlidePageWrapper>)}
    </Formik>);
};
export default CreditPage;
