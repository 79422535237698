import React from 'react';
import { Grid, TableCell, TableRow, IconButton } from 'src/components/mui';
import { Delete as DeleteIcon, WarningRounded as WarningRoundedIcon, } from 'src/components/mui/icons';
import { formatHoursDuration } from 'src/utils/formatter';
const WebhookRow = ({ webhook, deleteWebhook, webhookClickHandler, }) => {
    const { name, url, id, metadata } = webhook;
    const { target, environment, type, localTimestamp, age, isInvalid, isExpired, expiredHours, } = metadata !== null && metadata !== void 0 ? metadata : {};
    const displayTokens = [age];
    if (isExpired && expiredHours !== undefined) {
        displayTokens.push(`expired for ${formatHoursDuration(expiredHours)}`);
    }
    const deleteWebhookClicked = () => deleteWebhook(id, name, false);
    const webhookClicked = () => webhookClickHandler(id);
    return (<TableRow>
      <TableCell>{target}</TableCell>
      <TableCell align="center">{environment}</TableCell>
      <TableCell onClick={webhookClicked} style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
        }}>
        {type}
      </TableCell>
      <TableCell>{url}</TableCell>
      {isInvalid ? (<TableCell size="medium" style={{ color: 'red' }}>
          <Grid container direction="row" wrap="nowrap" style={{ alignItems: 'center' }}>
            <Grid item>
              <WarningRoundedIcon fontSize="small" style={{ color: 'orange', marginRight: '10px' }}/>
            </Grid>
            <Grid item>
              {localTimestamp}
              <br />
              {displayTokens.length > 0 && ` (${displayTokens.join(', ')})`}
            </Grid>
          </Grid>
        </TableCell>) : (<TableCell size="medium">
          {localTimestamp}
          <br />
          {displayTokens.length > 0 && ` (${displayTokens.join(', ')})`}
        </TableCell>)}
      <TableCell align="right" size="small">
        <IconButton onClick={deleteWebhookClicked}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>);
};
export default WebhookRow;
