import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { Grid, Tab, Tabs, Alert } from 'src/components/mui';
import { selectAuthorizedSystemAdmin } from 'src/modules/auth/selectors';
import { isProduction } from 'src/utils/envUtils';
import { TabPanel } from 'src/AdminApp/components/layout/TabPanel';
import { selectSkeduloSynchronizedResources } from 'src/SystemApp/modules/utilities/selectors';
import { matchSkeduloIds, resetSkeduloIds, syncTemplateSkeduloIds, } from 'src/SystemApp/modules/utilities/actions';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import SyncButton from './SyncButton';
import MatchResults from './MatchResults';
const StyledGrid = styled(Grid) `
  padding: 20px;
  border-bottom: 1px solid lightGrey;
`;
const SkeduloMatcher = () => {
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState(0);
    const [dryRun, setDryRun] = useState(true);
    const syncResponse = useSelector(selectSkeduloSynchronizedResources);
    const isSystemAdmin = useSelector(selectAuthorizedSystemAdmin);
    const handleCurrentTabChange = (event, tab) => setCurrentTab(tab);
    const handleDryRunChange = (event) => setDryRun(event.target.checked);
    const resetSkedulo = () => dispatch(resetSkeduloIds());
    const matchSkedulo = () => dispatch(matchSkeduloIds(dryRun));
    const syncTemplates = () => dispatch(syncTemplateSkeduloIds());
    return (!isProduction() && (<>
        <StyledGrid container spacing={2} style={{ alignItems: 'flex-end' }}>
          <Grid item xs={12}>
            <Alert severity="info">
              Click "Reset DB" to null out all Skedulo Resource ID mappings in
              the local database.
            </Alert>
            {!isSystemAdmin && (<Alert severity="warning">
                Not Authorized: You must have the "System_Admin" role to enable
                this module.
              </Alert>)}
          </Grid>
          <Grid item xs={12}>
            <SyncButton disabled={!isSystemAdmin} title="Reset DB" onClick={resetSkedulo}/>
          </Grid>
        </StyledGrid>
        <StyledGrid container spacing={2} style={{ alignItems: 'flex-end' }}>
          <Grid item xs={12}>
            <Alert severity="info">
              Click "Sync Availability Templates" to sync the templates info
              with staging
            </Alert>
            {!isSystemAdmin && (<Alert severity="warning">
                Not Authorized: You must have the "System_Admin" role to enable
                this module.
              </Alert>)}
          </Grid>
          <Grid item xs={12}>
            <SyncButton disabled={!isSystemAdmin} title="Sync Availability Templates" onClick={syncTemplates}/>
          </Grid>
        </StyledGrid>
        <StyledGrid container spacing={2} style={{ alignItems: 'flex-end' }}>
          <Grid item xs={12}>
            <Alert severity="info">
              Click "Match Entities" to match the Skedulo resources in the local
              database with the resources in Skedulo.
            </Alert>
          </Grid>
          <Grid item>
            <LabelledCheckbox label="Dry Run" title="Enable this flag to preview the matched entities without saving to the DB." checkboxProps={{
            name: 'dryRun',
            onChange: handleDryRunChange,
            checked: dryRun,
            disabled: !isSystemAdmin,
        }}/>
          </Grid>
          <Grid item xs={12}>
            <SyncButton disabled={!isSystemAdmin} title="Match Entities" onClick={matchSkedulo}/>
          </Grid>
          <Grid item xs={12}>
            {!isEmpty(syncResponse) && (<>
                <Tabs value={currentTab} onChange={handleCurrentTabChange} aria-label="Skedulo Match Results">
                  <Tab label={`Regions (${syncResponse.regions.total})`}/>
                  <Tab label={`Vans (${syncResponse.vans.total})`}/>
                  <Tab label={`Mechanics (${syncResponse.mechanics.total})`}/>
                </Tabs>
                <TabPanel value={currentTab} index={0}>
                  <MatchResults type="Regions" results={syncResponse.regions}/>
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                  <MatchResults type="Vans" results={syncResponse.vans}/>
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                  <MatchResults type="Mechanics" results={syncResponse.mechanics}/>
                </TabPanel>
              </>)}
          </Grid>
        </StyledGrid>
      </>));
};
export default SkeduloMatcher;
