import { Accordion, AccordionSummary, CircularProgress, Divider, TextField, Typography, Autocomplete, } from 'src/components/mui';
import { AddAPhoto as AddAPhotoIcon, ExpandMore as ExpandMoreIcon, } from 'src/components/mui/icons';
import React from 'react';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { AccordionUserDetails, UploadPictureButton, UserProfileEditFullRow, UserProfileEditRow, UserProfileEditSecWrapper, UserProfileEditWrapper, UserProfilePictureContainer, UserProfilePictureWrapper, WrapperBoxPhoto, } from 'src/components/wrappers/EditProfileInfoDrawer.styles';
import { formatPhone } from 'src/utils/formatter';
import { UserInfoWrapper, UserSecWrapper, } from '../MyProfile/components/Content.styles';
import PasswordUpdate from '../MyProfile/components/PasswordUpdate';
import PhoneNumberUpdate from '../MyProfile/components/PhoneNumberUpdate';
import useEditMyProfileInfoDrawer from './useEditMyProfileInfoDrawer';
const EditMyProfileInfoDrawer = ({ open, user, onClose, }) => {
    var _a;
    const { profilePicture, availableSkills, isSubmitting, handlerFirstNameChange, handlerLastNameChange, handlerPhoneNumberChange, handlerResourceIdChange, onProfilePictureSelected, handleSelected, handleClose, handleSubmit, } = useEditMyProfileInfoDrawer({ user, onClose });
    return (<SimpleDrawer open={open} onClose={handleClose} size="half" title="User Profile Details">
      <SimpleDrawerContent>
        <UserProfileEditWrapper>
          <UserProfileEditRow>
            <TextField id="user-first-name" label="First Name" variant="outlined" value={user.firstName || ''} onChange={handlerFirstNameChange} placeholder="First Name" type="text"/>
            <TextField id="user-last-name" label="Last Name" variant="outlined" value={user.lastName || ''} onChange={handlerLastNameChange} placeholder="Last Name" type="text"/>
          </UserProfileEditRow>
          <UserProfileEditRow>
            <TextField id="user-profile-edit-user-type" label="User Type" variant="outlined" value={user.userType || ''} disabled placeholder="User Type" type="text"/>
            <TextField id="user-email" label="Email" variant="outlined" disabled value={user.email || ''} placeholder="Email" type="email"/>
          </UserProfileEditRow>
          <UserProfileEditRow>
            <TextField id="user-phone-number" label="Phone Number" variant="outlined" value={(user.phoneNumber && formatPhone(user.phoneNumber)) || ''} onChange={handlerPhoneNumberChange} disabled placeholder="Phone number" type="phone"/>
            <TextField id="user-resource-id" label="Resource ID" variant="outlined" value={user.resourceId || ''} onChange={handlerResourceIdChange} placeholder="Resource ID" type="text"/>
          </UserProfileEditRow>
          <UserProfileEditRow>
            <TextField id="user-id" label="ID" variant="outlined" disabled value={user.id} placeholder="ID" type="text"/>
            <TextField id="user-uid" label="UID" variant="outlined" disabled value={user.uid} placeholder="UID" type="text"/>
          </UserProfileEditRow>
          <UserProfileEditFullRow>
            <Autocomplete multiple id="myProfile-user-skills" options={availableSkills} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} value={user.skills} isOptionEqualToValue={(option, value) => option.id === value.id} onChange={(e, value, reason) => handleSelected(e, value, reason)} filterSelectedOptions disableClearable clearOnBlur renderInput={(params) => (<TextField {...params} variant="outlined" label="Skills" placeholder="Add Skill"/>)}/>
          </UserProfileEditFullRow>
          <UserProfileEditRow>
            <LabelledCheckbox label="Enabled" disabled checkboxProps={{
            id: 'user-enabled',
            name: 'user-enabled',
            value: true,
            checked: user.enabled,
            onChange: () => { },
        }}/>
          </UserProfileEditRow>
        </UserProfileEditWrapper>
        <Divider />
        <UserProfileEditSecWrapper>
          <UserProfilePictureWrapper>
            <UserProfilePictureContainer>
              <input accept="image/*" id="myProfile-profile-picture" multiple={false} onChange={onProfilePictureSelected} type="file"/>
              <WrapperBoxPhoto htmlFor="myProfile-profile-picture">
                {profilePicture ? (<img alt="Profile" src={profilePicture}/>) : (<UploadPictureButton component="span" startIcon={<AddAPhotoIcon />}>
                    <span>Add Profile Picture</span>
                  </UploadPictureButton>)}
              </WrapperBoxPhoto>
            </UserProfilePictureContainer>
          </UserProfilePictureWrapper>
          <div style={{ width: '100%' }}>
            <UserInfoWrapper style={{ flexDirection: 'column' }}>
              <Typography align="left" variant="h6">
                Sign In &amp; Security
              </Typography>
              <UserSecWrapper>
                <Accordion square={false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="myProfile-phone-content" id="myProfile-phone-header">
                    <Typography>Phone Number</Typography>
                  </AccordionSummary>
                  <AccordionUserDetails>
                    <PhoneNumberUpdate userId={user.id} userPhoneNumber={(_a = user.phoneNumber) !== null && _a !== void 0 ? _a : ''}/>
                  </AccordionUserDetails>
                </Accordion>
                <Accordion square={false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="myProfile-password-content" id="myProfile-password-header">
                    <Typography>Password</Typography>
                  </AccordionSummary>
                  <AccordionUserDetails>
                    <PasswordUpdate />
                  </AccordionUserDetails>
                </Accordion>
              </UserSecWrapper>
            </UserInfoWrapper>
          </div>
        </UserProfileEditSecWrapper>
      </SimpleDrawerContent>

      <SimpleDrawerFooter>
        <FooterActionButton onClick={handleSubmit} isSubmitting={isSubmitting}>
          {isSubmitting ? <CircularProgress /> : 'Save'}
        </FooterActionButton>
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
export default EditMyProfileInfoDrawer;
