import createDefaultActionCreators, { createDefaultApiActionCreators, } from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import CONFIG from 'src/config';
export const ActionCreators = {
    GET_CUSTOM_FUNNELS: createDefaultActionCreators('GET_CUSTOM_FUNNELS'),
    ADD_CUSTOM_FUNNEL: createDefaultApiActionCreators('ADD_CUSTOM_FUNNEL'),
    ADD_PAGES_TO_FUNNEL: createDefaultApiActionCreators('ADD_PAGES_TO_FUNNEL'),
    REMOVE_PAGES_FROM_FUNNEL: createDefaultApiActionCreators('REMOVE_PAGES_FROM_FUNNEL'),
    GET_FUNNEL_PAGES: createDefaultActionCreators('GET_FUNNEL_PAGES'),
    UPDATE_FUNNEL_PAGE: createDefaultApiActionCreators('UPDATE_FUNNEL_PAGE'),
    PUBLISH_FUNNEL: createDefaultApiActionCreators('PUBLISH_FUNNEL'),
    UNPUBLISH_FUNNEL: createDefaultApiActionCreators('UNPUBLISH_FUNNEL'),
    // PARTNERS
    CREATE_PARTNER: createDefaultApiActionCreators('CREATE_PARTNER'),
    GET_PARTNERS: createDefaultApiActionCreators('GET_PARTNERS'),
    CLONE_PARTNER: createDefaultApiActionCreators('CLONE_PARTNER'),
    ARCHIVE_PARTNER: createDefaultApiActionCreators('ARCHIVE_PARTNER'),
    UPDATE_PARTNER: createDefaultApiActionCreators('UPDATE_PARTNER'),
    GET_PARTNER: createDefaultApiActionCreators('GET_PARTNER'),
    CREATE_FUNNEL_NOTE: createDefaultApiActionCreators('CREATE_FUNNEL_NOTE'),
    GET_FUNNEL_NOTES: createDefaultApiActionCreators('GET_FUNNEL_NOTES'),
    UPDATE_FUNNEL_NOTE: createDefaultApiActionCreators('UPDATE_FUNNEL_NOTE'),
};
export const getPartners = ({ active = true, page = 0, size = 25, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_PARTNERS),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/partner?active=${active}&page=${page}&size=${size}`,
    method: 'GET',
});
export const updateCustomFunnel = ({ funnel }) => createAPIAction({
    types: fillApiTypes(ActionCreators.ADD_CUSTOM_FUNNEL),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/funnel/${funnel.id}`,
    body: funnel,
    method: 'PUT',
});
export const addPagesToFunnel = ({ funnelId, pages, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.ADD_PAGES_TO_FUNNEL),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/funnel/${funnelId}/pages`,
    body: pages,
    method: 'PUT',
});
export const removePagesFromFunnel = ({ funnelId, pages, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.REMOVE_PAGES_FROM_FUNNEL),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/funnel/${funnelId}/pages`,
    body: pages,
    method: 'DELETE',
});
export const updatePageInFunnel = ({ funnelId, page, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_FUNNEL_PAGE),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/funnel/${funnelId}/page/${page.id}`,
    body: page,
    method: 'PUT',
});
export const createPartner = ({ partner }) => createAPIAction({
    types: fillApiTypes(ActionCreators.CREATE_PARTNER),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/partner`,
    body: partner,
    method: 'POST',
});
export const clonePartner = ({ partnerId }) => createAPIAction({
    types: fillApiTypes(ActionCreators.CLONE_PARTNER),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/partner/${partnerId}`,
    method: 'POST',
});
export const archivePartner = ({ partnerId }) => createAPIAction({
    types: fillApiTypes(ActionCreators.ARCHIVE_PARTNER),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/partner/${partnerId}`,
    method: 'DELETE',
});
export const unpublishFunnel = ({ funnelId }) => createAPIAction({
    types: fillApiTypes(ActionCreators.UNPUBLISH_FUNNEL),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/funnel/${funnelId}`,
    method: 'PATCH',
});
export const publishFunnel = ({ funnelId }) => createAPIAction({
    types: fillApiTypes(ActionCreators.PUBLISH_FUNNEL),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/funnel/${funnelId}?publish=true`,
    method: 'PATCH',
});
export const updatePartner = ({ partner }) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_PARTNER),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/partner/${partner.id}`,
    body: partner,
    method: 'PUT',
});
export const getPartner = (partnerId) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_PARTNER),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/partner/${partnerId}`,
    method: 'GET',
});
export const saveNote = (note) => createAPIAction({
    types: fillApiTypes(ActionCreators.CREATE_FUNNEL_NOTE),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/notes`,
    body: note,
    method: 'POST',
});
export const getFunnelNotes = ({ funnelId }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_FUNNEL_NOTES),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/notes/${funnelId}/FUNNEL/TOOLS`,
    method: 'GET',
});
export const updateNote = (note) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_FUNNEL_NOTE),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/notes/${note.id}`,
    body: note,
    method: 'PATCH',
});
