import moment from 'moment';
import filesize from 'filesize';
import momentTimezone from 'moment-timezone';
import numeral from 'numeral';
import { isNumber } from 'lodash';
import { LOCALIZED_DATE_LONG_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
// TODO: make timezone dynamic to shop's location
export function formatDate(date, inputString = LOCALIZED_DATE_LONG_FORMAT, timezone = null) {
    if (date && timezone) {
        return `${moment(date).format(inputString)} ${moment(date)
            .tz(timezone)
            .zoneAbbr()}`;
    }
    return moment(date).format(inputString);
}
export function formatUTCDate(date, tz = 'UTC') {
    return date && momentTimezone(date).tz(tz).format(LOCALIZED_DATE_LONG_FORMAT);
}
export function formatUTCToUserTimezone(date) {
    const utcMoment = moment.utc(date);
    const timezoneMoment = utcMoment.tz(moment.tz.guess());
    return timezoneMoment.format(LOCALIZED_DATE_LONG_FORMAT);
}
export function formatTimestampCustom(date, format) {
    const utc = moment.utc(date);
    return utc.tz(moment.tz.guess()).format(format);
}
export function formatTimestamp(date, pattern = LOCALIZED_DATE_LONG_FORMAT) {
    const utc = moment.utc(date);
    const localDate = moment(utc).local();
    return `${localDate.format(pattern)} ${localDate
        .tz(moment.tz.guess())
        .zoneAbbr()}`;
}
export const formatTimeOnly = (date, pattern = TIME_12H_FORMAT) => {
    const utc = moment.utc(date);
    return moment(utc).format(pattern);
};
export const formatHoursDuration = (hours) => {
    if (hours === undefined)
        return;
    const abs = Math.abs(hours);
    if (abs >= 24) {
        return `${(hours / 24).toFixed(0)} days`;
    }
    if (abs >= 1) {
        return `${hours.toFixed(1)} hours`;
    }
    if (abs < 1) {
        return `${(hours * 60).toFixed(0)} minutes`;
    }
};
export const toFixedIfNumber = (value) => isNumber(value) ? value.toFixed(2) : value;
export function formatCurrency(value) {
    if (value === null || value === undefined) {
        return '';
    }
    return numeral(value).format('$0,0.00');
}
export function formatPercentage(value, fractionDigits = 2) {
    return value === null || value === undefined
        ? ''
        : `${parseFloat(value).toFixed(fractionDigits)}%`;
}
export function formatPhone(value) {
    if (!value) {
        return '';
    }
    const cleaned = `${value}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return '';
}
export const formatPhoneNumberOnType = (value) => {
    if (!value)
        return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4)
        return phoneNumber;
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};
export function formatFileSize(size) {
    return filesize(size);
}
export const getFormattedDate = (rawDate) => {
    const date = new Date(rawDate);
    return date.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
};
export const getFormattedTime = (rawDate) => {
    const date = new Date(rawDate);
    return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
    });
};
export const formatMilitaryToStandardTime = (time) => {
    const [hours, minutes] = time.split(':');
    const isPM = Number(hours) >= 12;
    const formattedHours = Number(hours) % 12 || 12;
    return `${formattedHours}:${minutes} ${isPM ? 'PM' : 'AM'}`;
};
export const getMomentValueOr8am = (value, format) => {
    if (value && format) {
        return moment(value, format);
    }
    return moment().hour(8).minute(0).second(0);
};
export const getMomentValueOrNull = (value, format) => {
    if (value && format) {
        return moment(value, format);
    }
    return null;
};
export const getMomentValueNoUTCOffsetOr8am = (value) => {
    if (value) {
        return value.utcOffset(0);
    }
    return moment().hour(8).minute(0).second(0);
};
export const getTimeString = (value, format) => {
    var _a;
    if (typeof value === 'string') {
        return value;
    }
    return (_a = moment(value).format(format)) === null || _a === void 0 ? void 0 : _a.toUpperCase();
};
export const getMomentValueFromString = (value, format) => {
    if (typeof value === 'string' && value.includes('T') && value.endsWith('Z')) {
        return moment.utc(value);
    }
    if (typeof value === 'string') {
        return moment(value, format);
    }
    return value;
};
export const formatMomentDate = (date, format) => date.format(format);
