import React from 'react';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import { NotesTypes } from '../../constants';
import Notes3CDrawerContent from './Notes3CDrawerContent';
const Notes3CDrawer = ({ open, repairRequestId, onClose, loadNotes = false, completeTask, currentTaskWorkFlow, }) => {
    return (<SimpleDrawer open={open} onClose={onClose} title="Add 3 C's">
      <Notes3CDrawerContent type={NotesTypes.CONCERN_CAUSE_CORRECTION} repairRequestId={repairRequestId} onClose={onClose} loadNotes={loadNotes} completeTask={completeTask} currentTaskWorkFlow={currentTaskWorkFlow}/>
    </SimpleDrawer>);
};
export default Notes3CDrawer;
