import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RepairDropdown, RepairDropdownButtons, } from 'src/AdminApp/components/repairs/components/RepairDropdown';
import { EditFluidDrawer, EditLaborDrawer, EditPartDrawer, } from 'src/AdminApp/components/repairs/statement/Repairs/Drawers';
import { AddComponentModal, RemoveRepairModal, } from 'src/AdminApp/components/repairs/statement/Repairs/Modals';
import useRepairsDisplay from 'src/AdminApp/components/repairs/statement/Repairs/RepairsDisplay/useRepairsDisplay';
import { RemoveRepairButton } from 'src/AdminApp/components/repairs/statement/styledComponents';
import { isAdmin } from 'src/AdminApp/components/users/utils';
import { selectRequestIsLocked } from 'src/AdminApp/modules/requestLock/selectors';
import { ServiceDrawerName } from 'src/AdminApp/modules/requests/repairs.state';
import { selectRepairMeasurements, selectWorkOrderItemResponsiblePartyAuthorizations, } from 'src/AdminApp/modules/requests/selectors';
import { loadCurrentUser } from 'src/AdminApp/modules/users/actions';
import { selectCurrentUserIsEngineering, selectCurrentUserIsPartSpecialistI, selectCurrentUserIsPartSpecialistII, selectCurrentUserIsProduct, } from 'src/AdminApp/modules/users/selectors';
import { CopyToClipboardButton } from 'src/components/buttons';
import { Button, Grid } from 'src/components/mui';
import { AddCircle, DeleteForever } from 'src/components/mui/icons';
import OptionalTooltip from 'src/components/tooltips/OptionalTooltip';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { selectAuthorizedComponentEditor, selectAuthorizedResponsiblePartyEditor, selectRoPriceEdit, selectRoPriceEditSilverRock, } from 'src/modules/auth/selectors';
import Item from '../Item';
import Notes from '../Notes';
import SubletInfo from '../SubletInfo';
import { getRepairPrice, shouldDisplayLabor } from '../utils';
const RepairsDisplay = ({ workOrderId, addComponent, showCustom = false, referenceNum, removeComponent, removeRepair, removeSublet, renderQuoted, repairs = [], sublets = [], updateComponent, invoice, relatedParts = {}, updateSuggestedRepair, updateSuggestedRepairInvoice, displayAuditChip = false, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const { selectorProps } = usePageReferenceNum();
    const dispatch = useDispatch();
    const canEditComponent = useSelector(selectAuthorizedComponentEditor);
    const measurements = useSelector((state) => selectRepairMeasurements(state, selectorProps));
    const requestLocked = useSelector(selectRequestIsLocked);
    const [isEditable, setIsEditable] = useState(true);
    const { updateNote, handleOpenMeasurements, onRemoveComponent, onPartSubmit, onFluidSubmit, onLaborSubmit, onLaborRemove, onRemoveRepair, handleAddPart, serviceDrawerState, handleEditLabor, handleEditPart, handleOpenEditFluid, handleOpenRemoveRepair, handleOpenAddLabor, handleOpenAddFluid, handleOpenAdditionalInfoPart, handleOpenAdditionalInfoFluid, laborModalProps, relatedPartsLength, currentSuggestedRepairId, hasEngine, fixedPartComponents, fixedFluidComponents, isSilverRockRO, handleCloseServiceDrawer, } = useRepairsDisplay({
        referenceNum,
        workOrderId,
        updateSuggestedRepair,
        updateSuggestedRepairInvoice,
        removeComponent,
        updateComponent,
        addComponent,
        removeRepair,
        showCustom,
        repairs,
        relatedParts,
        invoice,
    });
    const authorizations = useSelector(selectWorkOrderItemResponsiblePartyAuthorizations(referenceNum));
    const isAuthorized = !!authorizations.find((auth) => {
        var _a, _b, _c, _d, _e;
        return ((_a = serviceDrawerState === null || serviceDrawerState === void 0 ? void 0 : serviceDrawerState.repair) === null || _a === void 0 ? void 0 : _a.id) &&
            (((_b = auth.suggestedRepairIds) === null || _b === void 0 ? void 0 : _b.includes((_c = serviceDrawerState === null || serviceDrawerState === void 0 ? void 0 : serviceDrawerState.repair) === null || _c === void 0 ? void 0 : _c.id)) ||
                ((_d = auth.suggestedRepairIds) === null || _d === void 0 ? void 0 : _d.includes((_e = serviceDrawerState === null || serviceDrawerState === void 0 ? void 0 : serviceDrawerState.repair) === null || _e === void 0 ? void 0 : _e.suggestedRepairId)));
    });
    const isRoPriceEdit = useSelector(selectRoPriceEdit);
    const isRoPriceEditSilverRock = useSelector(selectRoPriceEditSilverRock);
    const canEditLaborAndPricing = (isRoPriceEdit || (isSilverRockRO && isRoPriceEditSilverRock)) &&
        !isAuthorized;
    useEffect(() => {
        dispatch(loadCurrentUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (requestLocked) {
            setIsEditable(false);
        }
        else {
            setIsEditable(canEditComponent);
        }
    }, [canEditComponent, requestLocked]);
    const canEditResponsibleParty = useSelector(selectAuthorizedResponsiblePartyEditor);
    const currentUserIsEngineering = useSelector(selectCurrentUserIsEngineering);
    const currentUserIsProduct = useSelector(selectCurrentUserIsProduct);
    const currentUserIsPartSpecialistI = useSelector(selectCurrentUserIsPartSpecialistI);
    const currentUserIsPartSpecialistII = useSelector(selectCurrentUserIsPartSpecialistII);
    const canEditDistributor = invoice ||
        currentUserIsEngineering ||
        currentUserIsProduct ||
        currentUserIsPartSpecialistI ||
        currentUserIsPartSpecialistII;
    return (<>
      {repairs.map((repair, index) => {
            var _a;
            const { id, price, suggestedRepairId, nodeId } = repair;
            const [firstPrice] = price;
            const oldSublet = sublets.find((sub) => sub.suggestedRepairId === id ||
                (sub.description && repair.message === sub.description));
            let sublet = undefined;
            if (!oldSublet) {
                sublet = sublets.find(({ repairItems }) => repairItems === null || repairItems === void 0 ? void 0 : repairItems.some(({ suggestedRepairId: repairId }) => repairId === id || repairId === suggestedRepairId));
            }
            const repairName = repair.name;
            const repairId = repair[!showCustom ? 'id' : 'suggestedRepairId'];
            const isCreatedByCustomer = !isAdmin(repair.createdBy);
            const repairMeasurements = measurements.filter((measurement) => measurement.repairs.includes(nodeId));
            const hasAuthorization = !!authorizations.find((auth) => { var _a; return repairId && ((_a = auth.suggestedRepairIds) === null || _a === void 0 ? void 0 : _a.includes(repairId)); });
            return (<div key={index} style={{ position: 'relative' }}>
            {showCustom && (<CopyToClipboardButton value={repairName} style={{ position: 'absolute', left: '-45px', top: '24px' }}/>)}
            <RepairDropdown isCreatedByCustomer={isCreatedByCustomer} name={repairName} originReferenceNum={repair.originReferenceNum} quoted={renderQuoted(firstPrice)} repairId={repair.id} suggestedRepairId={repair.suggestedRepairId} shouldDisplayAuditChip={displayAuditChip} sublet={oldSublet || sublet} totalCost={firstPrice === null || firstPrice === void 0 ? void 0 : firstPrice.totalCost} invoice={invoice} readOnlyChips={!canEditResponsibleParty} fleetFixedPrice={firstPrice === null || firstPrice === void 0 ? void 0 : firstPrice.fleetPricing} created={repair.created} createdBy={repair.createdBy} modified={repair.modified} modifiedBy={repair.modifiedBy} isEditable={isEditable} tags={repair === null || repair === void 0 ? void 0 : repair.tags}>
              <>
                <Item canEdit={canEditComponent} price={firstPrice} onEditLabor={() => handleEditLabor(repair, id)} onEditPart={(part) => {
                    handleEditPart(repair, part);
                }} onEditFluid={(fluid, isFitment) => handleOpenEditFluid(repair, fluid, isFitment)} canEditLabor={canEditLaborAndPricing} onOpenAdditionalInfoPart={(part) => {
                    handleOpenAdditionalInfoPart(repair, part);
                }} onOpenAdditionalInfoFluid={(fluid) => {
                    handleOpenAdditionalInfoFluid(repair, fluid);
                }}/>
                <SubletInfo sublet={oldSublet || sublet} onRemove={() => {
                    if (oldSublet) {
                        const rId = oldSublet === null || oldSublet === void 0 ? void 0 : oldSublet.suggestedRepairId;
                        if (!removeSublet)
                            return;
                        removeSublet({
                            workOrderId,
                            repairId: rId,
                        });
                    }
                }}/>
                <Notes text={(_a = repair.customerFacingNotes) !== null && _a !== void 0 ? _a : ''} modified={repair === null || repair === void 0 ? void 0 : repair.customerFacingNotesModified} modifiedBy={repair === null || repair === void 0 ? void 0 : repair.customerFacingNotesModifiedBy} onUpdateNote={(customerFacingNotes) => updateNote({
                    suggestedRepairId: repairId,
                    customerFacingNotes,
                })}/>
                {true && (<RepairDropdownButtons container style={{ justifyContent: 'flex-start' }} spacing={2}>
                    {repairMeasurements.length > 0 && (<Grid item>
                        <Button color="primary" onClick={() => handleOpenMeasurements(nodeId)} startIcon={<AddCircle />}>
                          Measurements
                        </Button>
                      </Grid>)}
                    <Grid item style={{ textAlign: 'end' }}>
                      <OptionalTooltip title={hasAuthorization
                        ? 'This repair has been authorized. To make changes, remove the authorization first.'
                        : ''}>
                        <RemoveRepairButton onClick={() => handleOpenRemoveRepair(id)} startIcon={<DeleteForever />} disabled={hasAuthorization}>
                          Remove
                        </RemoveRepairButton>
                      </OptionalTooltip>
                    </Grid>
                  </RepairDropdownButtons>)}
              </>
            </RepairDropdown>
          </div>);
        })}
      <EditLaborDrawer open={serviceDrawerState.drawerName === ServiceDrawerName.labor &&
            !serviceDrawerState.isRecommended &&
            serviceDrawerState.isCustom === showCustom &&
            serviceDrawerState.hasInvoice === !!invoice} adding={serviceDrawerState.adding} {...laborModalProps} onClose={handleCloseServiceDrawer} onSubmit={onLaborSubmit} onRemove={onLaborRemove} isAuthorized={isAuthorized} canEdit={isEditable && !isAuthorized}/>
      <EditPartDrawer open={serviceDrawerState.drawerName === ServiceDrawerName.part &&
            !serviceDrawerState.isRecommended &&
            serviceDrawerState.isCustom === showCustom &&
            serviceDrawerState.hasInvoice === !!invoice} {...serviceDrawerState.part} onClose={handleCloseServiceDrawer} onSubmit={onPartSubmit} onRemove={({ removeDynamicRule }) => onRemoveComponent(serviceDrawerState.repair.id, serviceDrawerState.part.id, 'part', removeDynamicRule)} invoice={invoice} repair={serviceDrawerState.repair} suggestedRepairId={currentSuggestedRepairId} canEdit={isEditable} isAuthorized={isAuthorized} totalCost={(_d = (_c = ((_b = (_a = serviceDrawerState.repair) === null || _a === void 0 ? void 0 : _a.price) !== null && _b !== void 0 ? _b : [])[0]) === null || _c === void 0 ? void 0 : _c.totalCost) !== null && _d !== void 0 ? _d : 0} quotedCost={renderQuoted(((_f = (_e = serviceDrawerState.repair) === null || _e === void 0 ? void 0 : _e.price) !== null && _f !== void 0 ? _f : [])[0])} pcdbId={(_l = (_k = (_j = ((_h = (_g = serviceDrawerState.repair) === null || _g === void 0 ? void 0 : _g.price) !== null && _h !== void 0 ? _h : [])[0]) === null || _j === void 0 ? void 0 : _j.parts[0]) === null || _k === void 0 ? void 0 : _k.pcdbId) !== null && _l !== void 0 ? _l : 0} canEditDistributor={canEditDistributor} hasEngine={hasEngine} fixedComponents={fixedPartComponents} canEditPricing={canEditLaborAndPricing} isShowAdditionalInfo={serviceDrawerState.isShowAdditionalInfo}/>
      <EditFluidDrawer open={serviceDrawerState.drawerName === ServiceDrawerName.fluid &&
            !serviceDrawerState.isRecommended &&
            serviceDrawerState.isCustom === showCustom &&
            serviceDrawerState.hasInvoice === !!invoice} adding={serviceDrawerState.adding} {...serviceDrawerState.fluid} onClose={handleCloseServiceDrawer} onSubmit={onFluidSubmit} onRemove={({ removeDynamicRule }) => onRemoveComponent(serviceDrawerState.repair.id, serviceDrawerState.fluid.id, 'fluid', removeDynamicRule)} invoice={invoice} canEdit={isEditable} totalCost={(_q = (_p = ((_o = (_m = serviceDrawerState.repair) === null || _m === void 0 ? void 0 : _m.price) !== null && _o !== void 0 ? _o : [])[0]) === null || _p === void 0 ? void 0 : _p.totalCost) !== null && _q !== void 0 ? _q : 0} quotedCost={renderQuoted(((_s = (_r = serviceDrawerState.repair) === null || _r === void 0 ? void 0 : _r.price) !== null && _s !== void 0 ? _s : [])[0])} canEditDistributor={canEditDistributor} hasEngine={hasEngine} isAuthorized={isAuthorized} fixedComponents={fixedFluidComponents} canEditPricing={canEditLaborAndPricing} isShowAdditionalInfo={serviceDrawerState.isShowAdditionalInfo}/>
      <AddComponentModal open={serviceDrawerState.drawerName === ServiceDrawerName.addComponent &&
            !serviceDrawerState.isRecommended &&
            serviceDrawerState.isCustom === showCustom &&
            serviceDrawerState.hasInvoice === !!invoice} onClose={handleCloseServiceDrawer} displayLabor={serviceDrawerState.repair &&
            !shouldDisplayLabor(getRepairPrice(serviceDrawerState.repair.id, repairs))} onAddLabor={handleOpenAddLabor} numRecommendedParts={relatedPartsLength} onAddPart={handleAddPart} onAddFluid={handleOpenAddFluid}/>
      <RemoveRepairModal open={serviceDrawerState.drawerName === ServiceDrawerName.removeRepair &&
            !serviceDrawerState.isRecommended &&
            serviceDrawerState.isCustom === showCustom &&
            serviceDrawerState.hasInvoice === !!invoice} isCustom={showCustom} onClose={handleCloseServiceDrawer} onSubmit={onRemoveRepair}/>
    </>);
};
export default RepairsDisplay;
