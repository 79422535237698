/* eslint-disable jsx-a11y/anchor-is-valid */
import { TextField } from 'src/components/mui';
import React, { useEffect, useState } from 'react';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import { SliderFooter } from 'src/components/SimpleDrawerPaged';
import CONFIG from 'src/config';
import { colors } from 'src/styles/theme';
import { inputStyle, SubmitButton } from './sharedComponents.styles';
import { ExpirationRow, InputLabel, Link } from './styledComponents';
import { cardSvgByType } from './utils/cardSvgByType';
const SpreedlyForm = ({ onTokenize, altFlow, consumer, useAnotherScreen, }) => {
    var _a;
    const [ready, setReady] = useState(false);
    const initializeSpreedly = () => {
        const spreedlyReady = Boolean(global.Spreedly);
        const spreedlyCardInputLoaded = document.getElementById('spreedly-number');
        if (spreedlyReady && spreedlyCardInputLoaded) {
            global.Spreedly.init(CONFIG.REACT_APP_SPREEDLY_ENV_KEY, {
                numberEl: 'spreedly-number',
                cvvEl: 'spreedly-cvv',
            });
            global.Spreedly.on('ready', () => {
                global.Spreedly.setFieldType('number', 'text');
                global.Spreedly.setLabel('number', 'Card Number');
                global.Spreedly.setNumberFormat('prettyFormat');
                global.Spreedly.setStyle('number', 'width: 100%;  height:34px;');
                global.Spreedly.setStyle('number', 'font-size: 16px; color: #3f3f3f; font-weight: 400;');
                global.Spreedly.setFieldType('cvv', 'number');
                global.Spreedly.setLabel('cvv', 'CVV');
                global.Spreedly.setStyle('cvv', 'width: 100%; height: 34px;');
                global.Spreedly.setStyle('cvv', 'font-size: 16px; color: #3f3f3f; font-weight: 400;');
            });
            global.Spreedly.on('fieldEvent', (name, type, activeEl, inputProperties) => {
                var _a, _b, _c;
                if (name === 'number') {
                    const numberParent = document.getElementById('spreedly-number');
                    const hasMonthAndYear = document.getElementById('month').value &&
                        document.getElementById('year').value;
                    const fakeButton = document.getElementById('fake_button');
                    if (type === 'focus' && numberParent) {
                        numberParent.className = 'highlighted';
                    }
                    if (type === 'tab') {
                        (_a = document.getElementById('month')) === null || _a === void 0 ? void 0 : _a.focus();
                    }
                    if (type === 'input' && inputProperties.validNumber) {
                        const messageEl = document.getElementById('message');
                        if (messageEl) {
                            messageEl.style.display = 'none';
                        }
                        if (inputProperties.validCvv) {
                            document.getElementById('form_filled').checked = true;
                            if (hasMonthAndYear && fakeButton) {
                                fakeButton.style.display = 'none';
                            }
                        }
                    }
                    if (type === 'input' && inputProperties.cardType) {
                        const cardSvg = document.createElement('img');
                        cardSvg.src = cardSvgByType(inputProperties.cardType);
                        const cardType = document.getElementById('spreedly-number');
                        if (((_c = (_b = cardType === null || cardType === void 0 ? void 0 : cardType.childNodes) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) > 1) {
                            cardType === null || cardType === void 0 ? void 0 : cardType.replaceChild(cardSvg, cardType.childNodes[1]);
                        }
                        else {
                            cardType === null || cardType === void 0 ? void 0 : cardType.appendChild(cardSvg);
                        }
                    }
                    if (type === 'blur' && !inputProperties.validNumber) {
                        if (numberParent) {
                            numberParent.className = 'error';
                        }
                        if (fakeButton) {
                            fakeButton.style.display = 'inherit';
                        }
                        global.Spreedly.validate();
                    }
                }
                if (name === 'cvv') {
                    const cvvParent = document.getElementById('spreedly-cvv');
                    const hasMonthAndYear = document.getElementById('month').value &&
                        document.getElementById('year').value;
                    const fakeButton = document.getElementById('fake_button');
                    if (type === 'focus' && cvvParent) {
                        cvvParent.className = 'highlighted';
                    }
                    if (type === 'input' &&
                        inputProperties.validNumber &&
                        inputProperties.validCvv) {
                        const messageEl = document.getElementById('message');
                        if (messageEl) {
                            messageEl.style.display = 'none';
                        }
                        document.getElementById('form_filled').checked = true;
                        if (hasMonthAndYear && fakeButton) {
                            fakeButton.style.display = 'none';
                        }
                    }
                    if (type === 'input' && !inputProperties.validCvv) {
                        if (fakeButton) {
                            fakeButton.style.display = 'inherit';
                        }
                        if (cvvParent) {
                            cvvParent.className = 'error';
                        }
                        global.Spreedly.validate();
                    }
                }
            });
            global.Spreedly.on('validation', (inputProperties) => {
                if (inputProperties.validNumber)
                    return;
                const messageEl = document.getElementById('message');
                if (messageEl) {
                    messageEl.innerHTML = 'Please enter a valid credit card number';
                    messageEl.style.display = 'inherit';
                }
                const fakeButton = document.getElementById('fake_button');
                if (fakeButton) {
                    fakeButton.style.display = 'inherit';
                }
            });
            global.Spreedly.on('consoleError', (error) => {
                console.error(`Error from Spreedly iFrame: ${error.msg}`);
            });
            global.Spreedly.on('errors', (errors) => {
                const messageEl = document.getElementById('message');
                if (messageEl) {
                    messageEl.innerHTML = '';
                    messageEl.style.display = 'inherit';
                    for (let i = 0; i < errors.length; i += 1) {
                        messageEl.innerHTML = `${messageEl.innerHTML + errors[i].message}<br />`;
                    }
                }
            });
            global.Spreedly.on('paymentMethod', (token) => {
                var _a;
                const tokenField = document.getElementById('payment_method_token');
                tokenField === null || tokenField === void 0 ? void 0 : tokenField.setAttribute('value', token);
                (_a = document.getElementById('payment-submit')) === null || _a === void 0 ? void 0 : _a.click();
            });
            setReady(true);
        }
    };
    const preventNav = (event) => {
        if (!this.isEditing)
            return;
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
    };
    useEffect(() => {
        initializeSpreedly();
        global.addEventListener('beforeunload', preventNav);
        return () => {
            if (global.Spreedly) {
                global.Spreedly.removeHandlers();
            }
            global.removeEventListener('beforeunload', preventNav);
        };
    }, []);
    return (<form style={{ justifyContent: 'space-between' }}>
      <input type="hidden" name="payment_method_token" id="payment_method_token"/>
      <input type="hidden" name="form_filled" id="form_filled"/>

      <TextField style={{ width: '100%', margin: '20px 0 16px' }} variant="outlined" type="text" id="full_name" label="Full Name" name="full_name" defaultValue={(_a = consumer === null || consumer === void 0 ? void 0 : consumer.name) !== null && _a !== void 0 ? _a : ''} InputLabelProps={{
            shrink: true,
        }} onChange={(e) => {
            e.target.value = e.target.value
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
        }}/>

      <InputLabel>Credit Card Number</InputLabel>
      <div id="spreedly-number" style={Object.assign(Object.assign({}, inputStyle), { width: '100%', marginBottom: '20px' })}/>

      <ExpirationRow id="expiration">
        <TextField variant="outlined" type="number" label="MM" id="month" name="month" InputLabelProps={{
            shrink: true,
        }} onClick={() => {
            var _a;
            (_a = document.getElementById('month')) === null || _a === void 0 ? void 0 : _a.focus();
        }} style={{ maxWidth: '50%', marginRight: '9px' }} onChange={(e) => {
            const fakeButton = document.getElementById('fake_button');
            if (e.target.value.length > 2)
                e.target.value = e.target.value.slice(0, 2);
            if (e.target.value.length > 0 &&
                document.getElementById('year').value &&
                document.getElementById('form_filled').checked &&
                fakeButton) {
                fakeButton.style.display = 'none';
            }
            if (e.target.value.length < 1 && fakeButton) {
                fakeButton.style.display = 'inherit';
            }
        }}/>

        <TextField variant="outlined" type="number" label="YY" id="year" name="year" InputLabelProps={{
            shrink: true,
        }} style={{ maxWidth: '50%', marginLeft: '9px' }} onClick={() => {
            const yearInput = document.getElementById('year');
            if (!yearInput)
                return;
            yearInput.focus();
        }} onChange={(e) => {
            const fakeButton = document.getElementById('fake_button');
            if (e.target.value.length > 2)
                e.target.value = e.target.value.slice(0, 2);
            else if (e.target.value.length === 2 &&
                document.getElementById('month').value &&
                document.getElementById('form_filled').checked &&
                fakeButton) {
                fakeButton.style.display = 'none';
            }
            else if (e.target.value.length < 2 && fakeButton) {
                fakeButton.style.display = 'inherit';
            }
        }}/>
      </ExpirationRow>

      <InputLabel>CVV</InputLabel>
      <div id="spreedly-cvv" style={inputStyle}/>

      <div>
        <LabelledCheckbox style={{ margin: '25px 0 15px -11px' }} label="Save for future payments" checkboxProps={{
            name: 'savePaymentMethod',
            id: 'savePaymentMethod',
        }}/>
      </div>

      <div style={{
            display: 'none',
            width: '100%',
            objectFit: 'contain',
            borderRadius: '4px',
            backgroundColor: colors.GRAY_F5,
            color: colors.GRAY_76,
            padding: '16px',
            fontSize: '15px',
            borderLeft: `thick solid ${colors.error}`,
        }} id="message"/>

      {useAnotherScreen ? (<Link onClick={useAnotherScreen}>Use Saved Cards</Link>) : null}

      <br />

      <Link onClick={altFlow}>Use a Different Payment Method</Link>

      <br />
      <div id="spacer" style={{
            width: '200px',
            height: '100px',
            float: 'left',
            display: 'inline-block',
        }}/>

      <SliderFooter id="fake_button" style={{ marginLeft: '-16px', position: 'absolute', zIndex: 12000 }}>
        <FooterActionButton id="spreedly-fake-submit-button" disabled>
          Charge Now
        </FooterActionButton>
      </SliderFooter>

      <SliderFooter style={{ marginLeft: '-16px', position: 'absolute' }}>
        <SubmitButton id="spreedly-submit-button" variant="contained" color="primary" disabled={!ready} onClick={onTokenize}>
          Charge Now
        </SubmitButton>
      </SliderFooter>
    </form>);
};
export default SpreedlyForm;
