import styled from 'styled-components';
import { theme, colors } from 'src/styles/theme';
import { Fab } from 'src/components/mui';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
export const Title = styled.h1 `
  margin: 0;
  padding: 0;
  font-size: 24px;

  ${theme.breakpoints.up('md')} {
    margin: 8px 8px;
    font-size: 30px;
  }
`;
export const Content = styled.section `
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0 0;

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 0;
  }
`;
export const Divider = styled.div `
  display: none;

  ${theme.breakpoints.up('md')} {
    display: flex;
    height: 230px;
    width: 1px;
    background-color: ${colors.GRAY_DE};
  }
`;
export const PartPickupButtonsWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  button {
    width: 100%;
  }

  ${theme.breakpoints.up('md')} {
    flex-direction: row;

    button {
      width: auto;
    }
  }
`;
export const ContentInner = styled.div `
  margin-top: 1rem;
`;
export const PartPickupAttachedPhotosPlaceholder = styled.div `
  padding: 30px 113px 30px 100px;
  background-color: #f6f6f6;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  text-align: center;
  color: #3f3f3f7f;
  white-space: nowrap;
`;
export const AttachPhotoFab = styled(Fab) `
  position: fixed;
  bottom: 21px;
  right: 16px;
  height: 70px;
  width: 70px;
  z-index: 100;
  cursor: pointer;

  span {
    &:first-of-type {
      height: 100%;
    }
  }

  svg {
    height: 40px;
    width: 40px;
  }
`;
export const PartPickupAttachmentContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f6;
  padding: 10px 8px 10px 16px;
  border-radius: 2px;

  span {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.26px;
    color: ${colors.secondary};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
