var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FleetValidationSchema } from 'src/AdminApp/containers/fleets/utils/validations';
import { getAllFleetCars, getFleet, getFleetCars, getResponsiblePartyByFleetId, patchUpdateFleet, searchFleetsByName, updateFleetResponsibleParties, updateFleetResponsibleParty, updateFleetResponsiblePartyBillingContacts, } from 'src/AdminApp/modules/fleets/actions';
import { selectFleetById } from 'src/AdminApp/modules/fleets/selectors';
import { loadMarketingSources } from 'src/AdminApp/modules/lookups/actions';
import { selectMarketingSourcesById } from 'src/AdminApp/modules/lookups/selectors';
import { getResponsiblePartiesByType, getResponsiblePartyTypes, } from 'src/AdminApp/modules/requests/actions';
import { toast } from 'src/components/SimpleToast';
import { selectAuthorizedConsumerMarketingResourceEditor } from 'src/modules/auth/selectors';
import { splitAndTrimOn } from 'src/utils/data-utils';
const exemptFields = ['type'];
const defaultFleetCarsParams = {
    pageIndex: 0,
    pageSize: 20,
    sort: ['carMake', 'asc'],
};
const useFleetInfo = (_fleet) => {
    const dispatch = useDispatch();
    const { fleetId: routeFleetId } = useParams();
    const fleet = useSelector(selectFleetById(routeFleetId)) || _fleet;
    const fleetId = routeFleetId || (fleet === null || fleet === void 0 ? void 0 : fleet.id);
    const [addressModalOpen, setAddressModalOpen] = useState(false);
    const [isEditingMarketingSource, setIsEditingMarketingSource] = useState(false);
    const [billingAddressModalOpen, setBillingAddressModalOpen] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState(fleet === null || fleet === void 0 ? void 0 : fleet.parentName);
    const [opsContactSameAsMain, setOpsContactSameAsMain] = useState(false);
    const [fmcModalOpen, setFmcModalOpen] = useState(false);
    const [allResponsibleParties, setAllResponsibleParties] = useState();
    const marketingSourcesById = useSelector(selectMarketingSourcesById);
    const canEditMarketingSource = useSelector(selectAuthorizedConsumerMarketingResourceEditor);
    useEffect(() => {
        dispatch(loadMarketingSources());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        dispatch(getFleet(fleetId));
        // dispatch(getAllFleetCars(fleetId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleetId]);
    useEffect(() => {
        if (fmcModalOpen) {
            dispatch(getFleet(fleetId));
            dispatch(getAllFleetCars(fleetId));
            dispatch(getResponsiblePartyByFleetId(fleetId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fmcModalOpen]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if ((fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) &&
                ((_a = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                !allResponsibleParties) {
                const rpTypes = yield dispatch(getResponsiblePartyTypes());
                const rpTypesData = rpTypes === null || rpTypes === void 0 ? void 0 : rpTypes.payload;
                const promises = rpTypesData.map((type) => {
                    return dispatch(getResponsiblePartiesByType({
                        responsiblePartyTypeId: type.id,
                    }));
                });
                const response = yield Promise.all(promises);
                const responsibleParties = response.reduce((acc, el) => {
                    acc.push(...el.payload);
                    return acc;
                }, []);
                setAllResponsibleParties(responsibleParties);
            }
        }))();
        if ((fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactName) === (fleet === null || fleet === void 0 ? void 0 : fleet.contactName) &&
            (fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactPhone) === (fleet === null || fleet === void 0 ? void 0 : fleet.phone) &&
            (fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactEmail) === (fleet === null || fleet === void 0 ? void 0 : fleet.email)) {
            setOpsContactSameAsMain(true);
        }
        else {
            setOpsContactSameAsMain(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleet]);
    const handleUpdateField = (field) => (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (value === fleet[field])
            return;
        try {
            if (!exemptFields.includes(field)) {
                yield FleetValidationSchema.validateAt(field, {
                    [field]: value,
                });
            }
            const updateField = `update${field.charAt(0).toUpperCase()}${field.slice(1)}`;
            dispatch(patchUpdateFleet(fleetId, {
                [field]: value,
                [updateField]: true,
                laborRate: fleet.laborRate === '' ? null : fleet.laborRate,
                updateLaborRate: true,
            }));
        }
        catch (e) {
            toast.error(`Validation Error: ${e.message}`);
        }
    });
    const handleDailyOpsContactAutofill = (autofill) => {
        setOpsContactSameAsMain(autofill);
        try {
            if (autofill) {
                dispatch(patchUpdateFleet(fleetId, {
                    dailyOpsContactName: fleet.contactName,
                    updateDailyOpsContactName: true,
                    dailyOpsContactPhone: fleet.phone,
                    updateDailyOpsContactPhone: true,
                    dailyOpsContactEmail: fleet.email,
                    updateDailyOpsContactEmail: true,
                }));
            }
            else {
                dispatch(patchUpdateFleet(fleetId, {
                    dailyOpsContactName: null,
                    updateDailyOpsContactName: true,
                    dailyOpsContactPhone: null,
                    updateDailyOpsContactPhone: true,
                    dailyOpsContactEmail: null,
                    updateDailyOpsContactEmail: true,
                }));
            }
        }
        catch (e) {
            toast.error(`Validation Error: ${e.message}`);
        }
    };
    const handleOpenAddressModal = (billing = false) => () => !billing ? setAddressModalOpen(true) : setBillingAddressModalOpen(true);
    const handleCloseAddressModal = (billing = false) => () => !billing ? setAddressModalOpen(false) : setBillingAddressModalOpen(false);
    const handleUpdateAddress = (address) => __awaiter(void 0, void 0, void 0, function* () {
        const { city, country, latitude, longitude, state, street, street2, timezone, zip: zipCode, } = address;
        const fleetAddress = {
            city,
            country: country !== null && country !== void 0 ? country : 'US',
            latitude,
            longitude,
            state,
            street,
            street2,
            timezone: timezone !== null && timezone !== void 0 ? timezone : 'America/Los_Angeles',
            zipCode,
        };
        yield dispatch(patchUpdateFleet(fleetId, {
            consumer: {
                address: fleetAddress,
            },
            updateConsumer: true,
            laborRate: fleet.laborRate === '' ? null : fleet.laborRate,
            updateLaborRate: true,
        }));
        handleCloseAddressModal()();
    });
    const handleUpdateBillingField = (field) => (value) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (value === ((_a = fleet === null || fleet === void 0 ? void 0 : fleet.billingInfo) === null || _a === void 0 ? void 0 : _a[field]))
            return;
        try {
            yield FleetValidationSchema.validateAt('email', {
                email: value,
            });
            dispatch(patchUpdateFleet(fleetId, {
                billingInfo: Object.assign(Object.assign({}, fleet.billingInfo), { [field]: value }),
                updateBillingInfo: true,
                laborRate: fleet.laborRate === '' ? null : fleet.laborRate,
                updateLaborRate: true,
            }));
        }
        catch (e) {
            toast.error(`Validation Error: ${e.message}`);
        }
    });
    const handleUpdateBillingAddress = (address) => __awaiter(void 0, void 0, void 0, function* () {
        const { city, state, street: streetLine1, street2: streetLine2, zip, } = address;
        const billingInfo = Object.assign(Object.assign({}, fleet.billingInfo), { city,
            state,
            streetLine1, streetLine2: streetLine2 || '', zip });
        yield dispatch(patchUpdateFleet(fleetId, {
            billingInfo,
            updateBillingInfo: true,
            laborRate: fleet.laborRate === '' ? null : fleet.laborRate,
            updateLaborRate: true,
        }));
        handleCloseAddressModal(true)();
    });
    const handleUpdateTaxType = (value) => __awaiter(void 0, void 0, void 0, function* () {
        const fleetResponsibleParty = {
            id: fleetId,
            taxType: value,
        };
        const response = yield dispatch(updateFleetResponsibleParty(fleetResponsibleParty, fleetId));
        if (!response.error) {
            toast.success('Tax Type updated successfully');
        }
    });
    const handleBillingContactsSubmit = (values) => {
        if (!fleet.responsibleParty)
            return;
        const response = dispatch(updateFleetResponsiblePartyBillingContacts(fleet.responsibleParty.id, splitAndTrimOn(values.rpBillingContacts, ',')));
        if (response && !response.error) {
            toast.success('Billing Contacts updated successfully');
        }
        return response;
    };
    const handleUpdateFleetResponsibleParties = (responsibleParties) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(updateFleetResponsibleParties(fleetId, responsibleParties));
        if (response && !response.error) {
            toast.success('Responsible Parties updated successfully');
        }
        dispatch(getFleet(fleetId));
        dispatch(getFleetCars(Object.assign(Object.assign({}, defaultFleetCarsParams), { fleetId })));
    });
    const handleSetIsEditingMarketingSource = (state) => {
        setIsEditingMarketingSource(state);
    };
    const handleSearchInput = (value) => {
        setSearchInputValue(value);
    };
    const handleFmcsModalOpen = (state) => () => {
        setFmcModalOpen(state);
    };
    const handleFleetInfoSubmit = (values) => {
        const fields = Object.keys(values);
        const toUpdate = fields.reduce((prev, field) => {
            const updateField = `update${field.charAt(0).toUpperCase()}${field.slice(1)}`;
            if (values[field] === fleet[field])
                return prev;
            return Object.assign(Object.assign({}, prev), { [field]: values[field], [updateField]: true });
        }, {});
        if (Object.keys(toUpdate).length > 0) {
            dispatch(patchUpdateFleet(fleetId, Object.assign({}, toUpdate)));
        }
    };
    const handleSearchFleets = (data) => dispatch(searchFleetsByName({ name: data }));
    const handleConsumerIdChange = (setFieldValue) => (data) => {
        setFieldValue('consumerId', data.target.value);
        setFieldValue('parentName', null);
    };
    const handleSelectFmc = (setFieldValue) => (data) => {
        setFieldValue('consumerId', null);
        setFieldValue('parentId', data.id);
        setFieldValue('parentName', data.id === null ? null : data.label);
    };
    const handleAcceptFmc = ({ handleSubmit, setFieldValue, setIsEditing }) => () => {
        if (searchInputValue === '') {
            setFieldValue('consumerId', null);
            setFieldValue('parentId', null);
            setFieldValue('parentName', null);
            setTimeout(() => {
                handleSubmit();
                setIsEditing(false);
            }, 100);
        }
        else {
            handleSubmit();
            setIsEditing(false);
        }
    };
    const findResponsiblePartyById = (_id) => allResponsibleParties === null || allResponsibleParties === void 0 ? void 0 : allResponsibleParties.find(({ id }) => id === _id);
    return {
        addressModalOpen,
        isEditingMarketingSource,
        billingAddressModalOpen,
        searchInputValue,
        opsContactSameAsMain,
        fmcModalOpen,
        setFmcModalOpen,
        setAddressModalOpen,
        setBillingAddressModalOpen,
        setSearchInputValue,
        handleUpdateField,
        handleDailyOpsContactAutofill,
        handleOpenAddressModal,
        handleCloseAddressModal,
        handleUpdateAddress,
        handleUpdateBillingField,
        handleUpdateBillingAddress,
        handleUpdateTaxType,
        handleUpdateFleetResponsibleParties,
        handleSetIsEditingMarketingSource,
        handleSearchInput,
        handleFmcsModalOpen,
        handleFleetInfoSubmit,
        handleSearchFleets,
        handleConsumerIdChange,
        handleSelectFmc,
        handleAcceptFmc,
        marketingSourcesById,
        canEditMarketingSource,
        fleet,
        fleetId,
        allResponsibleParties,
        findResponsiblePartyById,
        handleBillingContactsSubmit,
    };
};
export default useFleetInfo;
