import { useParams, useLocation } from 'react-router-dom';
const usePageReferenceNum = () => {
    const { referenceNum } = useParams();
    const selectorProps = {
        match: {
            params: { referenceNum },
        },
    };
    return {
        referenceNum,
        selectorProps,
    };
};
export const usePageReferenceNumOutsideRouter = () => {
    const location = useLocation();
    const referenceNum = location.pathname.split('/').pop();
    const selectorProps = {
        match: { params: { referenceNum } },
    };
    const isFleetView = location.pathname.includes('visits');
    return {
        referenceNum,
        selectorProps,
        isFleetView,
    };
};
export default usePageReferenceNum;
