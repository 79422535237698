import React from 'react';
import { TableCell, TableRow as MuiTableRow } from 'src/components/mui';
import { ArrowRightAltOutlined as SelectedIcon } from 'src/components/mui/icons';
import { formatTimestamp } from 'src/utils/formatter';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import SnapshotBrowserContext from './context';
const TableRow = styled(MuiTableRow) `
  ${({ $isSelected }) => $isSelected
    ? `background-color: ${colors.lightCyan};`
    : `cursor: pointer; &:hover {background-color: ${colors.lightYellow};}`}
`;
const Event = styled.div `
  color: ${colors.blue2};
  cursor: pointer;
  text-decoration: underline;
  text-overflow: ellipsis;
`;
const Meta = styled.div `
  font-size: 10px;
  color: ${colors.GRAY_97};
`;
const SnapshotEventLogItem = ({ snapshot, event, isSelected, selectSnapshot, }) => {
    return (<SnapshotBrowserContext.Consumer>
      {({ isExpanded }) => (<TableRow $isSelected={isSelected} onClick={() => selectSnapshot(snapshot.id)} title={isExpanded
                ? `created by: ${snapshot.createdBy}`
                : `${event.type}\n${formatTimestamp(event.created)}\n${event.createdBy}`}>
          <TableCell>{snapshot.index}</TableCell>
          {isExpanded && (<TableCell>
              <Event>{event.type}</Event>
              <Meta>{formatTimestamp(event.created)}</Meta>
            </TableCell>)}
          <TableCell>{isSelected && <SelectedIcon />}</TableCell>
        </TableRow>)}
    </SnapshotBrowserContext.Consumer>);
};
export default SnapshotEventLogItem;
