import { createSelector } from 'reselect';
import { isArray } from 'lodash';
const getFleetVisits = (state) => state.fleetVisitsApp.fleetVisits;
export const selectVisitsData = createSelector(getFleetVisits, (visits) => visits === null || visits === void 0 ? void 0 : visits.data);
export const selectVisitsPagination = createSelector(getFleetVisits, (visits) => visits === null || visits === void 0 ? void 0 : visits.pagination);
export const selectVisitDate = createSelector(getFleetVisits, (visits) => visits === null || visits === void 0 ? void 0 : visits.visitDate);
export const selectVisitSort = createSelector(getFleetVisits, (visits) => visits.visitSort);
export const selectFleetNames = createSelector(getFleetVisits, (visits) => {
    var _a, _b, _c;
    const items = (_a = visits === null || visits === void 0 ? void 0 : visits.fleetNames) !== null && _a !== void 0 ? _a : [];
    if (isArray(items)) {
        return items.map((item) => ({
            id: item.id,
            title: item.name,
            type: 'fleet',
        }));
    }
    const result = [];
    (_b = items.fleetNames) === null || _b === void 0 ? void 0 : _b.forEach((item) => {
        result.push({
            id: item.id,
            title: item.name,
            type: 'fleet',
        });
    });
    (_c = items.stationNames) === null || _c === void 0 ? void 0 : _c.forEach((item) => {
        result.push({
            id: item,
            title: item,
            type: 'station',
        });
    });
    return result.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
        }
        return -1;
    });
});
export const selectVisitFleetIds = createSelector(getFleetVisits, (visits) => visits.visitFleetIds);
export const selectVisitStations = createSelector(getFleetVisits, (visits) => visits.visitStations);
export const selectFleetVisit = createSelector(getFleetVisits, (fleetVisits) => fleetVisits.visit);
export const selectAddVisit = createSelector(getFleetVisits, (fleetVisits) => fleetVisits.addVisit);
export const selectAddVisitStations = createSelector(selectAddVisit, (addVisit) => { var _a; return (_a = addVisit.stations) !== null && _a !== void 0 ? _a : []; });
export const selectAddVisitFleets = createSelector(selectAddVisit, (addVisit) => { var _a; return (_a = addVisit.fleets) !== null && _a !== void 0 ? _a : []; });
export const selectAddVisitFleetsDatasource = createSelector(selectAddVisit, (addVisit) => addVisit.fleetsDatasource);
export const selectAddVisitLocation = createSelector(selectAddVisit, (addVisit) => addVisit.location);
export const selectAddVisitExtLocation = createSelector(selectAddVisit, (addVisit) => addVisit.extLocation);
export const selectAddVisitStartDateTime = createSelector(selectAddVisit, (addVisit) => addVisit.startDateTime);
export const selectAddVisitEndDateTime = createSelector(selectAddVisit, (addVisit) => addVisit.endDateTime);
export const selectAddVisitDurationTime = createSelector(selectAddVisit, (addVisit) => { var _a; return (_a = addVisit.duration) === null || _a === void 0 ? void 0 : _a.time; });
export const selectAddVisitTechniciansSearchResults = createSelector(selectAddVisit, (addVisit) => { var _a; return (_a = addVisit.technicianSearchResults) !== null && _a !== void 0 ? _a : []; });
export const selectAddVisitTechnicians = createSelector(selectAddVisit, (addVisit) => { var _a; return (_a = addVisit.technicians) !== null && _a !== void 0 ? _a : []; });
export const selectAddVisitVans = createSelector(selectAddVisit, (addVisit) => { var _a; return (_a = addVisit.vans) !== null && _a !== void 0 ? _a : []; });
export const selectFleetRequests = createSelector(getFleetVisits, (fleetVisits) => fleetVisits.fleetRequests);
export const selectVisitInstructions = createSelector(getFleetVisits, (fleetVisits) => {
    var _a;
    return ((_a = fleetVisits.visitInstructions) === null || _a === void 0 ? void 0 : _a.content.length)
        ? fleetVisits.visitInstructions.content[0]
        : null;
});
export const selectVisitsByFleet = createSelector(getFleetVisits, (fleetVisits) => fleetVisits.visitsByFleet);
export const selectTechnicianTasksByReferenceNums = createSelector(getFleetVisits, (visits) => visits.technicianTasks);
export const selectAddVisitTimeSlot = createSelector(selectAddVisit, (addVisit) => addVisit.timeSlot);
export const selectAddVisitTechniciansChoice = createSelector(selectAddVisit, (addVisit) => addVisit.techniciansChoice);
export const selectAddVisitVanMechanicParings = createSelector(selectAddVisit, (addVisit) => addVisit.vanMechanicPairings);
export const selectAddVisitNumberOfTechnicians = createSelector(selectAddVisit, (addVisit) => addVisit.numberOfTechnicians);
export const selectVisitsStopwatch = () => createSelector(getFleetVisits, (visits) => visits.stopwatch);
export const selectVisitsAuditLog = createSelector(getFleetVisits, (fleetVisits) => fleetVisits.visitAuditLog);
