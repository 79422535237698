import React from 'react';
import { Card, CardContent, Grid, CircularProgress } from 'src/components/mui';
const SummaryCard = ({ title, value, isFetching }) => (<Card>
    <CardContent>
      <Grid container wrap="nowrap" spacing={2} style={{ justifyContent: 'center' }}>
        <h1>{isFetching ? <CircularProgress /> : value}</h1>
      </Grid>
      <Grid container wrap="nowrap" style={{ justifyContent: 'center' }} spacing={2}>
        <span style={{
        textTransform: 'uppercase',
        lineHeight: '0.9',
        fontSize: 20,
    }}>
          {title}
        </span>
      </Grid>
    </CardContent>
  </Card>);
export default SummaryCard;
