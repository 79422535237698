import React from 'react';
import styled from 'styled-components';
import { TableCell, TableRow, Typography } from 'src/components/mui';
import useRegionListItem from './useRegionListItem';
const Editable = styled.span `
  cursor: pointer;
`;
const RegionListItem = ({ item }) => {
    const { name, metro, serviceAreas = [], archived } = item;
    const { editRegion, renderName, editServiceArea, renderServiceAreaName } = useRegionListItem({ item });
    return (<TableRow>
      <TableCell>
        <Typography variant="subtitle1">Region:</Typography>
        <Editable onClick={() => editRegion()}>
          <Typography variant="h6">{renderName(name, archived)}</Typography>
        </Editable>
        {metro && (<Typography variant="subtitle1">
            {renderName(`Metro: ${metro === null || metro === void 0 ? void 0 : metro.name}`, metro === null || metro === void 0 ? void 0 : metro.archived)}
          </Typography>)}
        {serviceAreas.length > 0 && (<div>
            <Typography variant="subtitle1">Service Area:</Typography>
            {serviceAreas.map((sa) => (<div key={sa.id}>
                <Editable onClick={() => editServiceArea(sa)}>
                  {renderServiceAreaName(sa.name, sa.archiveDate ? new Date(sa.archiveDate) : undefined)}
                </Editable>
              </div>))}
          </div>)}
      </TableCell>
    </TableRow>);
};
export default RegionListItem;
