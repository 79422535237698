/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Container, Header, Content } from './PartsAccordion.styles';
export const PartsAccordion = ({ children, className, }) => {
    return <Container className={className}>{children}</Container>;
};
export const AccordionHeader = ({ isOpen = false, children, onClick, className = '', }) => {
    return (<Header isOpen={isOpen} onClick={onClick} className={className}>
      <div>{children}</div>
      <div className="itemCollapseIcon"/>
    </Header>);
};
export const AccordionContent = ({ isOpen = false, children, className = '', }) => {
    return (<Content isOpen={isOpen} className={className}>
      {children}
    </Content>);
};
export default PartsAccordion;
