var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { selectSelectedJobs } from 'src/PopsApp/modules/parts/selectors';
import { selectAllActivePartDistributors, selectPartDistributorLocationSearchResults, selectVehicleLocation, } from 'src/PopsApp/modules/vendors/selectors';
import { getCatalogComponents, searchPartsDistributorLocations, setVehicleLocation, updateVendorSearch, } from 'src/PopsApp/modules/vendors/actions';
import { createComponentAvailabilityRequests } from 'src/PopsApp/pages/Vendors/utils/componentAvailabilityUtils';
const useVendorSearch = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showAddressDialog, setShowAddressDialog] = useState(false);
    const [vendorArray, setVendorArray] = useState([]);
    const [fetchIndex, setFetchIndex] = useState(-1);
    const [gmapsApiLoaded, setGmapsApiLoaded] = useState(false);
    const [selectedPartDistributorId, setSelectedPartDistributorId] = useState();
    const selectedJobs = useSelector(selectSelectedJobs);
    const partDistributors = useSelector(selectAllActivePartDistributors);
    const searchResults = useSelector(selectPartDistributorLocationSearchResults);
    const vehicleLocation = useSelector(selectVehicleLocation);
    useEffect(() => {
        setTimeout(() => {
            setGmapsApiLoaded(true);
        }, 500);
        return () => setGmapsApiLoaded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        refreshSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleLocation, selectedPartDistributorId]);
    useEffect(() => {
        var _a;
        if (((_a = searchResults === null || searchResults === void 0 ? void 0 : searchResults.length) !== null && _a !== void 0 ? _a : 0) > 0) {
            if (!compareArrays(searchResults, vendorArray)) {
                setVendorArray(searchResults);
            }
            else if (fetchIndex !== -1 &&
                fetchIndex < searchResults.length &&
                searchResults[fetchIndex].loaded) {
                setFetchIndex((state) => state + 1);
            }
        }
        else {
            setVendorArray([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResults]);
    useEffect(() => {
        if (vendorArray && vendorArray.length > 0) {
            setFetchIndex(0);
        }
        else {
            setFetchIndex(-1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorArray]);
    useEffect(() => {
        if (fetchIndex !== -1 && fetchIndex < vendorArray.length) {
            fetchData(vendorArray[fetchIndex]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchIndex]);
    const refreshSearch = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!vehicleLocation)
            return;
        setLoading(true);
        yield dispatch(searchPartsDistributorLocations({
            latitude: vehicleLocation.latitude,
            longitude: vehicleLocation.longitude,
            partDistributorId: selectedPartDistributorId,
        }));
        setLoading(false);
    });
    const compareArrays = (array1, array2) => {
        if (array1.length !== array2.length) {
            return false;
        }
        return array1.every((obj1) => {
            const index = array2.findIndex((obj2) => obj2.id === obj1.id);
            return index !== -1;
        });
    };
    const fetchVendorComponentAvailability = (vendor) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(updateVendorSearch(Object.assign(Object.assign({}, vendor), { loading: true })));
        const promises = [];
        selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.forEach((job) => {
            var _a;
            const availabilityRequestObjects = createComponentAvailabilityRequests((_a = job.services) === null || _a === void 0 ? void 0 : _a.knownRepairs);
            promises.push(dispatch(getCatalogComponents(job === null || job === void 0 ? void 0 : job.car, vendor.id, availabilityRequestObjects)));
        });
        yield Promise.all(promises);
        dispatch(updateVendorSearch(Object.assign(Object.assign({}, vendor), { loading: false, loaded: true })));
    });
    const fetchData = (vendor) => __awaiter(void 0, void 0, void 0, function* () {
        if (vendor.integrated) {
            yield fetchVendorComponentAvailability(vendor);
        }
        else {
            dispatch(updateVendorSearch(Object.assign(Object.assign({}, vendor), { loading: false, loaded: true })));
        }
    });
    const handleShowAddressDialog = () => {
        setShowAddressDialog(true);
    };
    const handleAddressDialogClose = (address) => {
        if (address === null || address === void 0 ? void 0 : address.addressStr) {
            setVendorArray([]);
            dispatch(setVehicleLocation(address));
            handleAddressChange(address);
        }
        else {
            dispatch(setVehicleLocation(undefined));
        }
        setShowAddressDialog(false);
    };
    const handleAddressChange = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (isNil(value))
            return;
        dispatch(setVehicleLocation(value));
    });
    return {
        dispatch,
        loading,
        setLoading,
        showAddressDialog,
        setShowAddressDialog,
        vendorArray,
        setVendorArray,
        fetchIndex,
        setFetchIndex,
        gmapsApiLoaded,
        setGmapsApiLoaded,
        selectedPartDistributorId,
        setSelectedPartDistributorId,
        selectedJobs,
        partDistributors,
        searchResults,
        vehicleLocation,
        refreshSearch,
        compareArrays,
        handleShowAddressDialog,
        handleAddressDialogClose,
        handleAddressChange,
        fetchVendorComponentAvailability,
        fetchData,
    };
};
export default useVendorSearch;
