import styled from 'styled-components';
import { theme, colors, STATIC_URL_BASE } from 'src/styles/theme';
export const Container = styled.div `
  position: relative;
  width: 100%;
  height: 48px;
  margin: 0 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  ${theme.breakpoints.up('md')} {
    margin: 0 0 56px;
  }

  :before {
    content: '';
    width: 40px;
    height: 48px;
    top: 0;
    left: -16px;
    z-index: 10;
    position: absolute;
    background-image: url('${STATIC_URL_BASE}pops/icons/search.svg');
    background-size: 24px 24px;
    background-position: center right;
    background-repeat: no-repeat;
    border-top: solid 1px ${colors.GRAY_DE};
    border-bottom: solid 1px ${colors.GRAY_DE};

    ${theme.breakpoints.up('md')} {
      left: 24px;
      width: 50px;
      height: 24px;
      top: 12px;
      left: 0;
      z-index: 10;
      position: absolute;
      border: none;
      background-image: url('${STATIC_URL_BASE}pops/icons/search.svg');
      background-size: 24px 24px;
      background-position: center right;
    }
  }

  :after {
    content: '';
    border-top: solid 1px ${colors.GRAY_DE};
    border-bottom: solid 1px ${colors.GRAY_DE};
    position: absolute;
    height: 48px;
    right: -16px;
    width: 16px;
    top: 0;

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  }
`;
export const Search = styled.input `
  font-size: 16px;
  font-weight: 500;
  padding: 14px 14px 14px 32px;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  height: 48px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border: none;
  border-top: solid 1px ${colors.GRAY_DE};
  border-bottom: solid 1px ${colors.GRAY_DE};
  width: 100%;
  color: ${colors.black};
  position: relative;
  display: block;

  ${theme.breakpoints.up('md')} {
    border: solid 1px ${colors.GRAY_DE};
    border-radius: 24px;
    padding: 14px 14px 14px 64px;
  }

  ::placeholder {
    color: ${colors.GRAY_CC};
  }
`;
export const Filters = styled.button `
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 117px;
  background: none;
  border: none;
  border-left: solid 1px ${colors.GRAY_DE};
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  padding: 15px 23px;
  color: ${colors.black};

  ${theme.breakpoints.up('md')} {
    display: flex;
  }
`;
export const FilterIcon = styled.img `
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;
