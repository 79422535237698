import { Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { TextField } from 'src/AdminApp/components/form';
import useRecordMeasurementsDrawer from 'src/AdminApp/containers/repairs/RecordMeasurementsDrawer/useRecordMeasurementsDrawer';
import { selectMeasurementsDrawerState } from 'src/AdminApp/modules/requests/selectors';
import { DestructiveButton } from 'src/components/buttons';
import { InputAdornment } from 'src/components/mui';
import SimpleChip from 'src/components/SimpleChip';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { colors } from 'src/styles/theme';
import { FieldWrapper, HorizontalBorder, RepairHeader, RepairName, RepairSubheader, SectionHeader, } from './RecordMeasurementsDrawer.styles';
const RecordMeasurementsDrawer = ({ onClose, }) => {
    const { referenceNum, selectorProps } = usePageReferenceNum();
    const { open, nodeId, required } = useSelector(selectMeasurementsDrawerState);
    const { initialValues, currentRepairIndex, validationSchema, handleSubmit, repairSubheader, compareMeasurements, showSkipButton, repairs, handleSkip, } = useRecordMeasurementsDrawer({
        onClose,
        open,
        nodeId,
        required,
        referenceNum,
        selectorProps,
    });
    return (<SimpleDrawer open={open} onClose={onClose} title="Measurements">
      <Formik initialValues={initialValues.repairs[currentRepairIndex]} enableReinitialize validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, submitForm, resetForm, isSubmitting, touched }) => (<>
            <SimpleDrawerContent>
              {values !== undefined && (<Form>
                  <RepairHeader>
                    <RepairName>
                      {values.repair_name}
                      <RepairSubheader>{repairSubheader}</RepairSubheader>
                    </RepairName>
                    <SimpleChip label={values.source} $textColor={colors.white} $bgColor={colors.BLUE}/>
                  </RepairHeader>
                  <HorizontalBorder />
                  {values.measurements
                    .sort(compareMeasurements)
                    .map((measurement, mIndex) => (<div key={mIndex}>
                        <SectionHeader>
                          {measurement === null || measurement === void 0 ? void 0 : measurement.description}
                        </SectionHeader>
                        {measurement.positions
                        .sort((a, b) => a.displayOrder - b.displayOrder)
                        .map((position, pIndex) => {
                        var _a;
                        return (<FieldWrapper key={`${position.position}-${pIndex}`}>
                              <TextField variant="outlined" label={(_a = position.position) !== null && _a !== void 0 ? _a : measurement.description} name={`measurements.${mIndex}.positions.${pIndex}.value`} type={position.unit ? 'number' : 'text'} 
                        // fullWidth
                        InputProps={{
                                endAdornment: position.unit ? (<InputAdornment position="end">
                                      {position.unit}
                                    </InputAdornment>) : undefined,
                            }}/>
                            </FieldWrapper>);
                    })}
                      </div>))}
                </Form>)}
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              {showSkipButton && (<DestructiveButton disabled={isSubmitting} onClick={() => {
                    handleSkip(resetForm);
                }} fullWidth>
                  Skip All
                </DestructiveButton>)}
              <FooterActionButton type="submit" disabled={isSubmitting || (required && !touched) || (values === null || values === void 0 ? void 0 : values.editDisabled)} onClick={submitForm} fullWidth={showSkipButton}>
                {currentRepairIndex + 1 < repairs.length ? 'Next' : 'Save'}
              </FooterActionButton>
            </SimpleDrawerFooter>
          </>)}
      </Formik>
    </SimpleDrawer>);
};
export default RecordMeasurementsDrawer;
