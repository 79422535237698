import React from 'react';
import styled from 'styled-components';
import { Button as MuiButton } from 'src/components/mui';
import { FileCopy } from 'src/components/mui/icons';
import copyToClipboard from 'src/utils/copyToClipboard';
import { colors } from 'src/styles/theme';
const Button = styled(MuiButton) `
  padding: 0px;
  color: ${colors.linkBlue};
`;
const Icon = styled(FileCopy) `
  width: ${({ width }) => `${width}px` || 'auto'};
  padding: 0px;
`;
const CopyToClipboardButton = ({ value, children, width, style, }) => {
    const handleClick = (e) => {
        e.stopPropagation();
        copyToClipboard(value);
    };
    return (<Button startIcon={<Icon width={width}/>} onClick={handleClick} style={style}>
      {children}
    </Button>);
};
export default CopyToClipboardButton;
