import styled from 'styled-components';
import { theme, colors } from 'src/styles/theme';
export const Widget = styled.button `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 23px;
  background-color: ${colors.white};
  border-radius: 4px;
  border: solid 1px ${colors.GRAY_AA};
  cursor: pointer;
  position: relative;

  :hover {
    box-shadow: inset 0 0 0 2px ${colors.BLUE};
    border: solid 1px ${colors.BLUE};
  }

  ${theme.breakpoints.up('md')} {
    min-width: 288px;
    max-width: 288px;
  }

  .itemName {
    font-size: 16px;
    margin: 0 0 4px;
    padding: 0;

    ${theme.breakpoints.up('md')} {
      font-size: 18px;
    }
  }

  .itemCount {
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: ${colors.GRAY_76};

    ${theme.breakpoints.up('md')} {
      color: ${colors.GRAY_AA};
    }
  }
`;
export const SpecialOrderWrapper = styled.div `
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
`;
