import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { searchComponents } from 'src/PricingApp/modules/components/actions';
import { selectComponents } from 'src/PricingApp/modules/components/selectors';
import { selectDashboardContent, selectDashboardPagination, selectDashboardParams, selectDashboardFetching, } from 'src/PricingApp/modules/utils/selectors';
import PricingComponentsDashboardListItem from './PricingComponentsDashboardListItem';
import PricingComponentsSearch from './PricingComponentsSearch';
const REQUEST_COLUMNS = [
    { title: 'Id', key: 'id', sortable: true },
    { title: 'Component Name', key: 'name', sortable: true },
    { title: 'Type', key: 'type', sortable: false },
    { title: 'Unit', key: 'unit', sortable: false },
    { title: 'PCDB id', key: 'pcdbId', sortable: true },
    { title: 'Created Date', key: 'created', sortable: true },
    { title: 'Last Modified By', key: 'modifiedBy', sortable: false },
];
const PricingComponentsDashboard = () => {
    const dispatch = useDispatch();
    const components = useSelector(selectDashboardContent(selectComponents));
    const componentsPagination = useSelector(selectDashboardPagination(selectComponents));
    const isFetching = useSelector(selectDashboardFetching(selectComponents));
    const componentsParams = useSelector(selectDashboardParams(selectComponents));
    const onLoadData = ({ page, size, sort }) => dispatch(searchComponents(Object.assign(Object.assign({}, componentsParams), { pagination: {
            size,
            page,
            sort,
        } })));
    useEffect(() => {
        var _a, _b, _c;
        onLoadData({
            page: (_a = componentsPagination.pageNumber) !== null && _a !== void 0 ? _a : 0,
            size: (_b = componentsPagination.pageSize) !== null && _b !== void 0 ? _b : 20,
            sort: (_c = componentsPagination.sort) !== null && _c !== void 0 ? _c : ['id,asc'],
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const onSearch = (filters) => dispatch(searchComponents({
        filters,
        pagination: {
            size: componentsPagination.size,
            page: 0,
            sort: componentsPagination.sort,
        },
    }));
    return (<>
      <PricingComponentsSearch filters={componentsParams.filters} onSearch={onSearch}/>
      <SimpleTable data={components} pagination={componentsPagination} sort={componentsPagination.sort} isFetching={isFetching} columns={REQUEST_COLUMNS} renderItem={(item) => (<PricingComponentsDashboardListItem item={item} key={item.id}/>)} onLoadData={onLoadData} onRowsPerPageChange={() => {
            // This is intentional
        }}/>
    </>);
};
export default PricingComponentsDashboard;
