export const shouldDisplayQuote = (invoice) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return [
        ...((_b = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) !== null && _b !== void 0 ? _b : []),
        ...((_d = (_c = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _c === void 0 ? void 0 : _c.customRequests) !== null && _d !== void 0 ? _d : []),
        ...((_f = (_e = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _e === void 0 ? void 0 : _e.removedRepairs) !== null && _f !== void 0 ? _f : []),
        ...((_h = (_g = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _g === void 0 ? void 0 : _g.diagnoses) !== null && _h !== void 0 ? _h : []),
        ...((_k = (_j = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _j === void 0 ? void 0 : _j.removedDiagnoses) !== null && _k !== void 0 ? _k : []),
        ...((_m = (_l = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _l === void 0 ? void 0 : _l.removedCustomRequests) !== null && _m !== void 0 ? _m : []),
    ].length > 0;
};
