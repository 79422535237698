import React from 'react';
import { STATIC_URL_BASE } from 'src/styles/theme';
import { SpecialLabelIcon, SpecialLabelText, } from './SpecialOrderIndicator.styles';
const SpecialOrderIndicator = () => {
    return (<>
      <SpecialLabelIcon src={`${STATIC_URL_BASE}img/svg/special-order-icon.svg`} alt="Special Order Icon"/>{' '}
      <SpecialLabelText>SPECIAL</SpecialLabelText>
    </>);
};
export default SpecialOrderIndicator;
