import { createSelector } from 'reselect';
export const selectVendors = (state) => {
    var _a;
    return (_a = state.pops) === null || _a === void 0 ? void 0 : _a.vendors;
};
export const selectHomeStores = createSelector(selectVendors, (vendors) => vendors === null || vendors === void 0 ? void 0 : vendors.homeStores);
export const selectPartPickups = createSelector(selectVendors, (vendors) => vendors === null || vendors === void 0 ? void 0 : vendors.partPickups);
export const selectPartDistributorLocationSearchResults = createSelector(selectVendors, (vendors) => { var _a; return (_a = vendors === null || vendors === void 0 ? void 0 : vendors.searchResults) === null || _a === void 0 ? void 0 : _a.data; });
export const selectVehicleLocation = createSelector(selectVendors, (vendors) => vendors === null || vendors === void 0 ? void 0 : vendors.vehicleLocation);
export const selectComponentsAvailability = createSelector(selectVendors, (vendors) => vendors === null || vendors === void 0 ? void 0 : vendors.componentsAvailability);
export const selectPartsCatalog = createSelector(selectVendors, (vendors) => vendors === null || vendors === void 0 ? void 0 : vendors.catalogParts);
export const selectCatalogPartsLookup = createSelector(selectVendors, (vendors) => vendors === null || vendors === void 0 ? void 0 : vendors.catalogPartsLookup);
export const selectFluidsCatalog = createSelector(selectVendors, (vendors) => vendors === null || vendors === void 0 ? void 0 : vendors.catalogFluids);
export const selectPartDistributors = createSelector(selectVendors, (vendors) => vendors === null || vendors === void 0 ? void 0 : vendors.partDistributors);
export const selectPartDistributorSearchResults = createSelector(selectVendors, (vendors) => vendors === null || vendors === void 0 ? void 0 : vendors.partDistributorSearchResults);
export const selectAllActivePartDistributors = createSelector(selectVendors, (vendors) => {
    var _a, _b;
    return (_b = (_a = vendors === null || vendors === void 0 ? void 0 : vendors.partDistributors) === null || _a === void 0 ? void 0 : _a.filter((distributor) => distributor.status === 'ACTIVE')) === null || _b === void 0 ? void 0 : _b.sort((d1, d2) => d1.name > d2.name ? 1 : -1);
});
export const selectPartDistributorsAddressSearchResults = createSelector(selectVendors, (vendors) => vendors === null || vendors === void 0 ? void 0 : vendors.addressSearchResults);
