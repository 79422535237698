import { toEnum } from 'src/models/enums';
export const TechnicianWorkflowState = toEnum({
    TRAVEL_STARTED: 'Start Travel',
    JOB_STARTED: 'Start Job',
    ARRIVED: 'Arrived',
    INVOICE_CREATED: 'Complete Job',
    REPAIRS_AUTHORIZED: 'Authorize',
    JOB_COMPLETED: 'Complete Job',
    MPI_PERFORMED: 'Perform MPI',
    PAYMENT_COLLECTED: 'Collect Payment',
    WORK_ORDER_COMPLETED: 'Complete',
    FOUR_CORNER: 'Add 4 Corner',
    FOUR_CORNER_CONTINUE: 'Continue 4 Corner',
    FOUR_CORNER_VIEW: 'View 4 Corner',
    GET_MEASUREMENTS: 'Measurements',
    GET_MEASUREMENTS_SKIPPABLE: 'Measurements',
    CONCERN_CAUSE_CORRECTION: 'Add 3Cs',
    FITMENT_PICKER: 'Fitment Picker',
});
export default TechnicianWorkflowState;
