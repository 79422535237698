import { SettingsRounded as Settings, CheckCircleRounded as CheckCircle, EventNoteRounded as EventNote, VpnKeyRounded as VpnKey, ErrorRounded as Error, BlockRounded as Block, PhoneMissedRounded as PhoneMissed, BuildRounded, } from 'src/components/mui/icons';
import { toEnum } from 'src/models/enums';
import Note3CsIcon from './common/Note3CsIcon';
export const MAX_NOTE_LENGTH = 200;
export const NotesTypes = toEnum({
    INTERNAL: 'Internal',
    CUSTOMER_FACING: 'Customer Facing',
    FMC_FACING: 'FMC Facing',
    CONCERN_CAUSE_CORRECTION: 'Concern, Cause, Correction',
});
export const NotesOrigin = toEnum({
    QUOTE: 'Quote',
    RECOMMENDED_REPAIRS: 'Recommended Repair',
});
export const NotesUrgency = toEnum({
    LOW: 'LOW',
    NORMAL: 'NORMAL',
    WARNING: 'WARNING',
    URGENT: 'URGENT',
});
export const NotesEntityTypes = toEnum({
    REPAIR_REQUEST: 'REPAIR_REQUEST',
    SUGGESTED_REPAIR: 'SUGGESTED_REPAIR',
    PARTS_PRICES: 'PARTS_PRICES',
    FLUID_PRICES: 'FLUID_PRICES',
    LABOR: 'LABOR',
});
export const TYPE_ICONS = {
    CONSUMER: EventNote,
    FLEET: EventNote,
    APPT_CHECKLIST: CheckCircle,
    APPOINTMENT_CHECKLIST: CheckCircle,
    ARRIVAL_INSTRUCTIONS: VpnKey,
    FLEET_INSTRUCTIONS: Settings,
    FMC: Error,
    WRONG_PART: Block,
    CONCERN_CAUSE_CORRECTION: Note3CsIcon,
    ARRIVAL_NO_SHOW: PhoneMissed,
    PART_UPDATE: BuildRounded,
};
export const TYPE_DESCRIPTION = {
    CONSUMER: 'Notes to the Technician, from the customer',
    FLEET: 'Notes to the Technician, from the fleet account',
    APPT_CHECKLIST: 'Pre-appointment checklist, from the customer',
    APPOINTMENT_CHECKLIST: 'Pre-appointment checklist, from the customer',
    ARRIVAL_INSTRUCTIONS: 'Arrival Instructions',
    FLEET_INSTRUCTIONS: 'Fleet Instructions',
    FMC: 'A note from the Fleet Management Company',
    WRONG_PART: 'Wrong part',
    CONCERN_CAUSE_CORRECTION: 'Concern, Cause, and Correction',
    ARRIVAL_NO_SHOW: 'No Show',
    PART_UPDATE: 'Part Update',
};
export const SUB_TYPE_DESCRIPTION = {
    ADDITIONAL_COMPONENTS_REQUIRED: 'ADDITIONAL_COMPONENTS_REQUIRED',
    ADDITIONAL_REPAIRS_REQUIRED: 'ADDITIONAL_REPAIRS_REQUIRED',
    CUSTOMER_PROVIDED_PARTS: 'CUSTOMER_PROVIDED_PARTS',
    FAULTY_PART_RECEIVED: 'FAULTY_PART_RECEIVED',
    PRICING_QUOTE: 'PRICING_QUOTE',
    URGENT_PENDING_PARTS: 'URGENT_PENDING_PARTS',
    WRONG_PART: 'WRONG_PART',
};
export const sortOptions = [
    {
        label: 'Author (A-Z)',
        value: ['author,asc'],
    },
    {
        label: 'Author (Z-A)',
        value: ['author,desc'],
    },
    {
        label: 'Origin (A-Z)',
        value: ['origin,asc'],
    },
    {
        label: 'Origin (Z-A)',
        value: ['origin,desc'],
    },
    {
        label: 'Date Created (Newest)',
        value: ['created,desc'],
    },
    {
        label: 'Date Created (Oldest)',
        value: ['created,asc'],
    },
];
export const typesOptions = [
    {
        label: 'Entire Repair Order',
        value: 'ORDER',
    },
    {
        label: 'Repair',
        value: 'REPAIR',
    },
    {
        label: 'Component',
        value: 'COMPONENT',
    },
];
