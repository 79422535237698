import styled from 'styled-components';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
export const Container = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 340px;
  margin: 0 20px 20px 0;
  border-radius: 5px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px ${colors.GRAY_DE};
  padding: 30px;
`;
export const Picture = styled.img `
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const DeleteButton = styled.button `
  border: none;
  background-color: transparent;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 12px;
  right: 12px;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url(${STATIC_URL_BASE}tools/menu-icons/delete.svg);
`;
export const DownloadButton = styled.button `
  border: none;
  width: 30px;
  height: 30px;
  background-color: transparent;
  position: absolute;
  top: 12px;
  right: 40px;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url(${STATIC_URL_BASE}tools/menu-icons/download.svg);
`;
