import React from 'react';
import { Grid } from 'src/components/mui';
import PageTitle from 'src/containers/layout/PageTitle';
import AddRepairForm from '../components/repairs/AddRepairForm';
const AddRepair = () => (<>
    <PageTitle slug="Add Repair"/>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AddRepairForm />
      </Grid>
    </Grid>
  </>);
export default AddRepair;
