var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid } from 'src/components/mui';
import { LocalShipping } from 'src/components/mui/icons';
import { Formik } from 'formik';
import React, { useState } from 'react';
import TextField from 'src/AdminApp/components/form/TextField';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
    hubDeliveryRadius: Yup.string().when('isHubDelivery', {
        is: true,
        then: Yup.string().required('Hub Radius is required'),
        otherwise: Yup.string().nullable(),
    }),
    hotshotDeliveryRadius: Yup.string().when('isHotshotDelivery', {
        is: true,
        then: Yup.string().required('Hotshot Radius is required'),
        otherwise: Yup.string().nullable(),
    }),
});
const DeliveryTypesDrawer = ({ model, onSave }) => {
    const [open, setOpen] = useState(false);
    const closeHandler = () => setOpen(false);
    const openHandler = () => setOpen(true);
    return (<>
      <LocalShipping onClick={openHandler}/>
      <SimpleDrawer open={open} onClose={closeHandler} title="Delivery Type" size="half">
        <Formik initialValues={{
            isHubDelivery: model.hubDelivery,
            hubDeliveryRadius: model.hubDeliveryRadius,
            isHotshotDelivery: model.hotshotDelivery,
            hotshotDeliveryRadius: model.hotshotDeliveryRadius,
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield onSave(Object.assign(Object.assign({}, model), { hubDelivery: values.isHubDelivery, hubDeliveryRadius: values.isHubDelivery
                        ? values.hubDeliveryRadius
                        : null, hotshotDelivery: values.isHotshotDelivery, hotshotDeliveryRadius: values.isHotshotDelivery
                        ? values.hotshotDeliveryRadius
                        : null, createdBy: null, created: null, modifiedBy: null, modified: null }));
            }
            finally {
                setSubmitting(false);
                closeHandler();
            }
        })}>
          {({ values, isSubmitting, isValid, setFieldValue, submitForm }) => (<>
              <SimpleDrawerContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h3>Delivery Type</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <LabelledCheckbox label="Hub Delivery" checkboxProps={{
                checked: values.isHubDelivery,
                onChange: (_, value) => {
                    setFieldValue('isHubDelivery', !!value);
                },
                name: 'isHubDelivery',
            }}/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth inputType="text" name="hubDeliveryRadius" label="Hub Delivery Radius (Miles):" value={values.hubDeliveryRadius} type="number" variant="outlined" disabled={!values.isHubDelivery}/>
                  </Grid>
                  <Grid item xs={12}>
                    <LabelledCheckbox label="Hotshot Delivery" checkboxProps={{
                checked: values.isHotshotDelivery,
                onChange: (_, value) => {
                    setFieldValue('isHotshotDelivery', !!value);
                },
                name: 'isHotshotDelivery',
            }}/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth inputType="text" name="hotshotDeliveryRadius" label="Hotshot Delivery Radius (Miles):" value={values.hotshotDeliveryRadius} type="number" variant="outlined" disabled={!values.isHotshotDelivery}/>
                  </Grid>
                </Grid>
              </SimpleDrawerContent>
              <SimpleDrawerFooter>
                <FooterActionButton onClick={submitForm} disabled={isSubmitting || !isValid}>
                  Save
                </FooterActionButton>
              </SimpleDrawerFooter>
            </>)}
        </Formik>
      </SimpleDrawer>
    </>);
};
export default DeliveryTypesDrawer;
