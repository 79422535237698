var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { TableRow, TableCell } from 'src/components/mui';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
import { Checkbox } from 'src/components/SimpleCheckbox';
import { openInvoicePdf } from 'src/utils/downloadUtils';
import { selectAccessToken } from 'src/modules/auth/selectors';
import { colors } from 'src/styles/theme';
const ListItem = ({ data }) => {
    const [expanded, setExpanded] = React.useState(false);
    if ((data === null || data === void 0 ? void 0 : data.length) <= 3) {
        return (<ul style={{ listStyle: 'none', padding: 0 }}>
        {data.map((item) => (<li key={item}>{item}</li>))}
      </ul>);
    }
    const [first, second, ...rest] = data || [];
    return (<ul style={{ listStyle: 'none', padding: 0 }}>
      <li>{first}</li>
      <li>{second}</li>
      {expanded && rest.map((item) => <li key={item}>{item}</li>)}
      {rest.length > 0 && (<li onClick={() => setExpanded(!expanded)} style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: colors.darkBlue,
            }}>
          {expanded ? 'Show less' : `+ ${rest.length} more`}
        </li>)}
    </ul>);
};
const InvoiceItem = ({ data, checked, onSelect, setLoading, checkDisabled, }) => {
    const { referenceNum, responsibleParty, responsiblePartyId, invoiceNum, created, lastInvoiceSentAt, recipients, appointmentDate, carNickname, carMake, carModel, carYear, carVin, repairs, } = data || {};
    const accessToken = useSelector(selectAccessToken);
    const handleDownloadInvoice = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (checkDisabled)
            return;
        if (setLoading) {
            setLoading(true);
        }
        yield openInvoicePdf({
            referenceNum,
            responsiblePartyId,
            responsiblePartyName: responsibleParty,
            accessToken,
            invoiceNumber: invoiceNum,
        });
        if (setLoading) {
            setLoading(false);
        }
    });
    return (<TableRow>
      <TableCell>
        {<Checkbox checked={checked} disabled={checkDisabled} onClick={() => {
                onSelect(data);
            }}/>}
      </TableCell>
      <TableCell>
        <a href={`/admin/repairs/${referenceNum}`} target="_blank" rel="noreferrer">
          {referenceNum}
        </a>{' '}
      </TableCell>
      <TableCell>
        <a href="#" onClick={handleDownloadInvoice} style={{
            cursor: checkDisabled ? 'not-allowed' : 'pointer',
        }}>
          {invoiceNum}
        </a>
      </TableCell>
      <TableCell>{moment(created).format(LOCALIZED_DATE_FORMAT)}</TableCell>
      <TableCell>
        {lastInvoiceSentAt
            ? moment(lastInvoiceSentAt).format(LOCALIZED_DATE_FORMAT)
            : ''}
      </TableCell>
      <TableCell>
        <ListItem data={recipients}/>
      </TableCell>
      <TableCell>{responsibleParty}</TableCell>
      <TableCell>
        {appointmentDate
            ? moment(appointmentDate).format(LOCALIZED_DATE_FORMAT)
            : ''}
      </TableCell>
      <TableCell>
        <div>
          <div style={{ whiteSpace: 'nowrap' }}>
            {carYear} {carMake} {carModel}
          </div>
          {carVin && (<div style={{ color: colors.GRAY_76, fontSize: 12 }}>{carVin}</div>)}
          {carNickname && (<div style={{ color: colors.GRAY_76, fontSize: 12 }}>
              {carNickname}
            </div>)}
        </div>
      </TableCell>
      <TableCell>
        <ListItem data={repairs}/>
      </TableCell>
    </TableRow>);
};
export default InvoiceItem;
