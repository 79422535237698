import React, { Fragment, useState } from 'react';
import { Slide } from 'src/components/mui';
import styled from 'styled-components';
import { usePrevious } from 'src/components/SimpleDrawerPaged/utils';
import usePaginationScroll from 'src/AdminApp/hooks/usePaginationScroll';
const SlideHelper = styled.div `
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: ${({ $isActive }) => ($isActive ? 2 : 1)};
  display: flex;
  flex-direction: column;
  margin: auto;

  > div {
    height: 100%;
    overflow: auto;
  }
`;
const Slider = ({ currentSlide, slides, callback }) => {
    const prevScreen = usePrevious(currentSlide);
    const prevIndex = slides.findIndex((route) => route.name === prevScreen);
    const currentIndex = slides.findIndex((route) => route.name === currentSlide);
    const direction = prevIndex < currentIndex ? 'left' : 'right';
    const [ref, setRef] = useState();
    if (callback) {
        usePaginationScroll({
            callback,
            ref,
        });
    }
    return (<Fragment key="slider">
      {slides === null || slides === void 0 ? void 0 : slides.map((route) => (<SlideHelper $isActive={route.name === currentSlide} key={route.name}>
          <Slide direction={direction} in={route.name === currentSlide} mountOnEnter unmountOnExit ref={(el) => setRef(el)}>
            <div>{route.render()}</div>
          </Slide>
        </SlideHelper>))}
    </Fragment>);
};
export default Slider;
