import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
export const SlidesContainer = styled.div `
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${({ $hasTab }) => $hasTab ? 'calc(100vh - 104px)' : 'calc(100vh - 56px)'};
`;
export const HeaderCta = styled.div `
  position: absolute;
  top: 16px;
  cursor: pointer;
  z-index: 10;
  width: 24px;
  height: 24px;
  color: ${colors.concrete};
  background-color: ${colors.lightSilver};
  border-radius: 50%;
  svg {
    padding: 5px;
  }
`;
export const ContentWrapper = styled.div `
  width: 100%;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.5px;
`;
export const SliderFooter = styled.div `
  height: 80px;
  background: ${colors.white};
  padding: 16px 20px 20px 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  text-align: right;

  ${theme.breakpoints.down('sm')} {
    max-width: 425px;
  }
`;
