import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Button } from '@mui/material';
import React from 'react';
import { SubText } from 'src/components/SimpleDrawer';
import { HelpList } from './PartsHelpDrawer.styles';
const SelectComponentType = ({ repair, onTypeSelect, }) => {
    var _a, _b, _c;
    if (!repair)
        return null;
    const addTypes = ['Parts', 'Fluids', 'Labor'];
    // REMOVE LABOR FROM LIST IF REPAIR has laborCost
    const firstPrice = repair.price[0];
    if (((_a = firstPrice.laborCost) !== null && _a !== void 0 ? _a : 0) > 0 ||
        ((_b = firstPrice.laborRate) !== null && _b !== void 0 ? _b : 0) > 0 ||
        ((_c = firstPrice.laborTime) !== null && _c !== void 0 ? _c : 0) > 0) {
        addTypes.splice(addTypes.indexOf('Labor'), 1);
    }
    return (<>
      <h2>Select Component Type</h2>
      <SubText>Which repair requires additional components?</SubText>
      <HelpList>
        {addTypes.map((type) => (<li key={type}>
            <Button variant="text" endIcon={<ArrowForwardIosRoundedIcon />} onClick={() => {
                onTypeSelect(type);
            }}>
              {type}
            </Button>
          </li>))}
      </HelpList>
    </>);
};
export default SelectComponentType;
