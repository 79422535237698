import { useState } from 'react';
import { VIN_LENGTH } from 'src/AdminApp/modules/api/constants';
import { parseStringNumber } from 'src/utils/data-utils';
const useCarDrawer = ({ onSubmit, isMilesOutConfirm = false, }) => {
    const [selectedVehicle, setSelectedVehicle] = useState();
    const [isValid, setIsValid] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState('');
    const handleSubmit = () => {
        validateMileage();
    };
    const validateMileage = () => {
        var _a, _b;
        const parsedMileageIn = parseStringNumber((_a = selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.mileage) !== null && _a !== void 0 ? _a : 0);
        const parsedMileageOut = parseStringNumber((_b = selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.mileageOut) !== null && _b !== void 0 ? _b : 0);
        if (!parsedMileageIn && !parsedMileageOut) {
            setConfirmationModalOpen(false);
            submitSelectedVehicle();
        }
        else {
            let confirmationModalText = '';
            if (parsedMileageIn <= 10) {
                confirmationModalText = ' low mileage in,';
            }
            else if (parsedMileageIn >= 300000) {
                confirmationModalText = ' high mileage in,';
            }
            if (parsedMileageOut <= 10) {
                confirmationModalText += ' low mileage out,';
            }
            else if (parsedMileageOut >= 300000) {
                confirmationModalText += ' high mileage out,';
            }
            if (!confirmationModalText) {
                submitSelectedVehicle();
            }
            else {
                setConfirmationModalOpen(true);
                setConfirmationModalText(`You’ve entered in a${confirmationModalText} are you sure?`);
            }
        }
    };
    const submitSelectedVehicle = () => {
        onSubmit(selectedVehicle);
    };
    const isValidMileage = (mileageIn, mileageOut) => {
        if (mileageIn && mileageOut) {
            return mileageIn <= mileageOut;
        }
        if (mileageOut) {
            return false;
        }
        return true;
    };
    const handleVehicleUpdated = (v) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const isLicensePlateValid = !!v.plateNumber === !!v.plateNumberState;
        if (isMilesOutConfirm) {
            setIsValid(isValidMileage((_a = v.mileage) !== null && _a !== void 0 ? _a : 0, (_b = v.mileageOut) !== null && _b !== void 0 ? _b : 0) &&
                (((_c = v.vin) === null || _c === void 0 ? void 0 : _c.length) === 0 || ((_d = v.vin) === null || _d === void 0 ? void 0 : _d.length) === VIN_LENGTH) &&
                isLicensePlateValid &&
                !!v.mileageOut);
        }
        else {
            setIsValid(isValidMileage((_e = v.mileage) !== null && _e !== void 0 ? _e : 0, (_f = v.mileageOut) !== null && _f !== void 0 ? _f : 0) &&
                (((_g = v.vin) === null || _g === void 0 ? void 0 : _g.length) === 0 || ((_h = v.vin) === null || _h === void 0 ? void 0 : _h.length) === VIN_LENGTH) &&
                isLicensePlateValid);
        }
        setSelectedVehicle(v);
    };
    const handleIsSelectedCarChange = (isSelected) => {
        if (isSelected)
            setIsValid(true);
    };
    return {
        isValid,
        confirmationModalOpen,
        confirmationModalText,
        submitSelectedVehicle,
        handleSubmit,
        handleVehicleUpdated,
        handleIsSelectedCarChange,
        setConfirmationModalOpen,
    };
};
export default useCarDrawer;
