var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectResponsiblePartyTypesNotDefaultPrimary, selectResponsiblePartiesFromType, } from 'src/AdminApp/modules/requests/selectors';
import { getResponsiblePartyTypes, getResponsiblePartiesByType, } from 'src/AdminApp/modules/requests/actions';
const IGNORE_SUBMIT_FIELDS = [
    'name',
    'entityType',
    'entityId',
    'taxType',
    'responsiblePartyTypeId',
    'responsiblePartyType',
    'generatesRevenue',
    'highlight',
    'label',
];
const useFleetResponsiblePartiesDrawer = ({ open, fleetId, onClose, }) => {
    const dispatch = useDispatch();
    const [removeResponsiblePartyModalOpen, setRemoveResponsiblePartyModalOpen] = useState(false);
    const [vehicleSearchOpen, setVehicleSearchOpen] = useState(false);
    const [removeResponsiblePartyId, setRemoveResponsiblePartyId] = useState();
    const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);
    const responsiblePartyTypes = useSelector(selectResponsiblePartyTypesNotDefaultPrimary);
    const responsiblePartiesFromType = useSelector(selectResponsiblePartiesFromType);
    useEffect(() => {
        if (open) {
            dispatch(getResponsiblePartyTypes());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    const fetchResponsiblePartiesByType = (type) => {
        dispatch(getResponsiblePartiesByType({
            responsiblePartyTypeId: type,
        }));
    };
    const onResponsiblePartyAdd = (responsibleParty) => {
        const newFleetResponsibleParty = Object.assign(Object.assign({}, responsibleParty), { fleetId, status: 'ACTIVE', cars: [], autoSubmit: false });
        return newFleetResponsibleParty;
    };
    const onUpdateVehicles = (id, vehicles, responsibleParties, setFieldValue) => {
        const newData = responsibleParties.map((rp) => {
            if (rp.id === id) {
                return Object.assign(Object.assign({}, rp), { cars: vehicles });
            }
            return rp;
        });
        setFieldValue('responsibleParties', newData);
    };
    const onAutoSubmitUpdate = (id, autoSubmit, responsibleParties, setFieldValue) => {
        const newData = responsibleParties === null || responsibleParties === void 0 ? void 0 : responsibleParties.map((rp) => {
            if (rp.id === id) {
                return Object.assign(Object.assign({}, rp), { autoSubmit });
            }
            return rp;
        });
        setFieldValue('responsibleParties', newData);
    };
    const closeDrawer = () => onClose();
    const handleClose = (valuesHaveChanged) => {
        if (valuesHaveChanged) {
            setShowCloseConfirmationModal(true);
            return;
        }
        closeDrawer();
    };
    const handleFormSubmit = (responsibleParties, onSubmit, resetForm, onClose) => __awaiter(void 0, void 0, void 0, function* () {
        const transformedData = responsibleParties.map((rp) => {
            const responsibleParty = Object.assign(Object.assign({}, rp), { responsiblePartyId: !rp.created ? rp.id : rp.responsiblePartyId, id: !rp.created ? null : rp.id });
            IGNORE_SUBMIT_FIELDS.forEach((field) => delete responsibleParty[field]);
            return Object.assign({}, responsibleParty);
        });
        yield onSubmit(transformedData);
        resetForm();
        onClose();
    });
    return {
        removeResponsiblePartyModalOpen,
        vehicleSearchOpen,
        removeResponsiblePartyId,
        responsiblePartyTypes,
        responsiblePartiesFromType,
        showCloseConfirmationModal,
        onResponsiblePartyAdd,
        onUpdateVehicles,
        onAutoSubmitUpdate,
        closeDrawer,
        handleClose,
        setVehicleSearchOpen,
        setRemoveResponsiblePartyId,
        setRemoveResponsiblePartyModalOpen,
        fetchResponsiblePartiesByType,
        setShowCloseConfirmationModal,
        handleFormSubmit,
    };
};
export default useFleetResponsiblePartiesDrawer;
