import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { CircularProgress, Grid } from 'src/components/mui';
import ShopAddForm from 'src/AdminApp/containers/shops/ShopAddForm';
import PageTitle from 'src/containers/layout/PageTitle';
import { loadShop } from 'src/AdminApp/modules/shops/actions';
import { selectShopFormValues } from 'src/AdminApp/modules/shops/selectors';
// This is a temporary fix for the shop add form
// TODO: Fix this
// ShopAddForm should be rewritten to functional component. Currently it is a
// class component and is not compatible with latest typescript
const ShopAddFormUntyped = ShopAddForm;
const ShopEditPage = () => {
    const dispatch = useDispatch();
    const initialValuesSelector = useSelector(selectShopFormValues);
    const { shopId } = useParams();
    const initialValues = shopId ? initialValuesSelector(+shopId) : {};
    useEffect(() => {
        if (!shopId)
            return;
        dispatch(loadShop(+shopId));
    }, []); // eslint-disable-line
    return (<div>
      <PageTitle slug="Edit Shop"/>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!isEmpty(initialValues) ? (<ShopAddFormUntyped isEditing initialValues={initialValues}/>) : (<CircularProgress />)}
        </Grid>
      </Grid>
    </div>);
};
export default ShopEditPage;
