import React from 'react';
import styled from 'styled-components';
import { ArrowDropDown as ArrowDropDownIcon, Check as CheckIcon, } from 'src/components/mui/icons';
import { Popover } from 'src/components/mui';
import { theme, colors } from 'src/styles/theme';
const Widget = styled.div `
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-bottom: solid 1px ${colors.GRAY_DE};
  margin-bottom: 16px;

  ${theme.breakpoints.up('md')} {
    display: flex;
  }
`;
const SortButton = styled.button `
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: ${colors.black};
  cursor: pointer;
`;
const Arrow = styled(ArrowDropDownIcon) `
  color: ${colors.GRAY_CC};
  width: 17px;
`;
const Check = styled(CheckIcon) `
  color: ${colors.black};
  width: 18px;
  margin-right: 12px;
  opacity: ${(props) => (props.checked ? 1 : 0)};
`;
const PopoverContent = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 12px 24px;
  background-color: ${colors.GRAY_FA};
`;
const PopoverItem = styled.div `
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const SortPopover = ({ pid, isOpen, anchor, onClose, value, onItemClick, }) => (<Popover id={pid} open={isOpen} anchorEl={anchor} onClose={onClose} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
    }} transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
    }}>
    <PopoverContent>
      <PopoverItem onClick={() => {
        onItemClick('ASC');
    }}>
        <Check checked={value === 'ASC'}/>
        A-Z
      </PopoverItem>
      <PopoverItem onClick={() => {
        onItemClick('DESC');
    }}>
        <Check checked={value !== 'ASC'}/>
        Z-A
      </PopoverItem>
    </PopoverContent>
  </Popover>);
const SortWidget = ({ onItemClick, value = 'ASC', }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'sort-popover' : undefined;
    const popoverItemClick = (value) => {
        handleClose();
        onItemClick(value);
    };
    return (<Widget>
      <SortButton aria-describedby={id} onClick={handleClick}>
        Sort by: {value === 'ASC' ? 'A-Z' : 'Z-A'}
        <Arrow />
      </SortButton>

      <SortPopover pid={id} isOpen={open} anchor={anchorEl} onClose={handleClose} value={value} onItemClick={popoverItemClick}/>
    </Widget>);
};
export default SortWidget;
