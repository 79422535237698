var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Grid, RadioGroup } from 'src/components/mui';
import { Formik } from 'formik';
import { map, values as _values } from 'lodash';
import React from 'react';
import { TextField } from 'src/AdminApp/components/form';
import { RadioButtonField } from 'src/AdminApp/components/form/RadioButton';
import { RepairRequestResolution } from 'src/AdminApp/models/enums';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import { enumToArray } from 'src/models/enums';
import useCancelRequestDrawer from './useCancelRequestDrawer';
const cancelOptions = enumToArray(RepairRequestResolution).filter((v) => v.id !== 'UNKNOWN');
const invalidRequestOptions = [
    'No Response',
    'Wrong Number',
    'Price Shopper',
    'Distance Too Far',
    'Repair Type Not Supported',
    'Got Repair Elsewhere',
    'Hang-Up',
    'Delivery Request Not in Delivery Area',
    'Seeking Something Other than Repair',
    'Looking for Dealer',
    'Looking for Part Only',
    'Not in Service Area',
    'Replied STOP to Texts',
];
const CancelRequestDrawer = ({ open, onClose }) => {
    const { showCancelRequestForm, handleSubmit, backButtonHandler, nextButtonHandler, } = useCancelRequestDrawer();
    return (<SimpleDrawer open={open} onClose={onClose} disableEnforceFocus>
      <SimpleDrawerHeader title="Select Cancel Reason" onClose={showCancelRequestForm ? onClose : backButtonHandler} arrowBack={!showCancelRequestForm}/>

      <Formik initialValues={{}} enableReinitialize onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting, onClose)}>
        {({ setFieldValue, values, isSubmitting, handleSubmit }) => {
            const { cancelResolution, otherResolution } = values, invalidRequestRes = __rest(values, ["cancelResolution", "otherResolution"]);
            const showInvalidRequestCta = showCancelRequestForm &&
                cancelResolution === RepairRequestResolution.INVALID_REQUEST;
            const otherCancelSelectedButNoReason = cancelResolution === RepairRequestResolution.OTHER &&
                !otherResolution;
            const otherInvalidSelectedButNoReason = values.other && !values.otherReason;
            const invalidSelectedButNoReason = cancelResolution === RepairRequestResolution.INVALID_REQUEST &&
                !showCancelRequestForm &&
                !_values(invalidRequestRes).includes(true);
            const disableSubmit = typeof values.cancelResolution === 'undefined' ||
                otherCancelSelectedButNoReason ||
                otherInvalidSelectedButNoReason ||
                invalidSelectedButNoReason;
            return (<>
              <SimpleDrawerContent>
                <div style={{
                    display: showCancelRequestForm ? 'block' : 'none',
                }}>
                  <RadioGroup id="cancelResolution" value={values.resolution}>
                    {map(cancelOptions, (option) => (<RadioButtonField key={option.id} name="cancelResolution" id={option.id} label={option.label} onChange={(e) => {
                        if (e.target.value !== 'OTHER') {
                            setFieldValue('otherResolution', undefined);
                        }
                    }} helperField={values.cancelResolution === 'OTHER' &&
                        option.id === 'OTHER' ? (<TextField fullWidth name="otherResolution" type="text" label="Reason" variant="outlined"/>) : null}/>))}
                  </RadioGroup>
                </div>
                <div style={{
                    display: showCancelRequestForm ? 'none' : 'block',
                }}>
                  <Grid container spacing={2}>
                    {map(invalidRequestOptions, (option) => (<Grid item xs={6}>
                        <CheckboxField label={option} name={option}/>
                      </Grid>))}
                    <Grid item xs={6}>
                      <CheckboxField name="other" label="Other, Please Specify"/>
                      {values.other && <TextField name="otherReason"/>}
                    </Grid>
                  </Grid>
                </div>
              </SimpleDrawerContent>
              <SimpleDrawerFooter>
                <FooterActionButton onClick={showInvalidRequestCta
                    ? nextButtonHandler
                    : () => handleSubmit()} disabled={isSubmitting || disableSubmit}>
                  {showInvalidRequestCta ? 'Next' : 'Save'}
                </FooterActionButton>
              </SimpleDrawerFooter>
            </>);
        }}
      </Formik>
    </SimpleDrawer>);
};
export default CancelRequestDrawer;
