import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, } from 'src/components/mui';
import React, { useState } from 'react';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import styled from 'styled-components';
const RejectFormControl = styled(FormControl) `
  width: 100%;
`;
const RejectControlLabel = styled(FormControlLabel) `
  display: flex;
  justify-content: space-between;
  margin-left: 0;
`;
const RejectRecommendedRepairSlider = ({ open, onClose, onSubmit, }) => {
    const [rejectedReason, setRejectedReason] = useState('');
    const [otherReason, setOtherReason] = useState();
    const handleSubmit = () => {
        onSubmit({
            rejectedReason,
            otherReason: rejectedReason === 'OTHER' && otherReason,
        });
        setOtherReason(undefined);
        setRejectedReason('');
    };
    return (<SimpleDrawer open={open} onClose={onClose} title="Reason for Rejection">
      <SimpleDrawerContent>
        <RejectFormControl>
          <RadioGroup aria-label="rejectedReason" name="rejectedReason" value={rejectedReason} onChange={(event) => setRejectedReason(event.target.value)}>
            <RejectControlLabel value="REPAIR_COST" control={<Radio color="primary"/>} label={<Typography style={{ fontWeight: 'bold' }}>
                  Repair cost
                </Typography>} labelPlacement="start"/>
            <Divider />
            <RejectControlLabel value="FUTURE_APPOINTMENT" control={<Radio color="primary"/>} label={<Typography style={{ fontWeight: 'bold' }}>
                  Will schedule future appointment
                </Typography>} labelPlacement="start"/>
            <Divider />
            <RejectControlLabel value="OTHER" control={<Radio color="primary"/>} label={<Typography style={{ fontWeight: 'bold' }}>Other</Typography>} labelPlacement="start"/>
            <Divider />
          </RadioGroup>
        </RejectFormControl>
        <Grid item xs={12}>
          {rejectedReason === 'OTHER' && (<TextField variant="outlined" value={otherReason} onChange={(event) => setOtherReason(event.target.value)} fullWidth multiline/>)}
        </Grid>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={handleSubmit} disabled={!rejectedReason}>
          Save
        </FooterActionButton>
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
export default RejectRecommendedRepairSlider;
