import React from 'react';
import { getSquareMakeLogo } from 'src/AdminApp/containers/vehicle/utils';
import { ActionsSection, CarDetails, CarDetailsLabel, CarDetailsValue, CarMain, CarMakeLogo, CarName, FleetVehicleCarCard, FleetVehicleCarCardContent, PlateContainer, SelectCarButton, } from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/pages/vehicle/Garage/components/FleetVehicleCard/FleetVehicleCard.styles';
const FleetVehicleCard = ({ onSelectVehicle, vehicle, }) => {
    var _a, _b;
    return (<FleetVehicleCarCard variant="outlined">
      <FleetVehicleCarCardContent>
        <CarMain>
          <CarMakeLogo alt={vehicle.make} src={getSquareMakeLogo(vehicle.make, '@3x')}/>

          <CarName>
            {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          </CarName>
        </CarMain>

        <CarDetails>
          <div>
            <CarDetailsLabel>VIN</CarDetailsLabel>
            <CarDetailsValue>{(_a = vehicle.vin) !== null && _a !== void 0 ? _a : 'Not Specified'}</CarDetailsValue>
          </div>
          <PlateContainer>
            <CarDetailsLabel>Plate</CarDetailsLabel>
            <CarDetailsValue>
              {(_b = vehicle.plateNumber) !== null && _b !== void 0 ? _b : 'Not Specified'}
            </CarDetailsValue>
          </PlateContainer>
        </CarDetails>

        <ActionsSection>
          <SelectCarButton variant="text" onClick={() => {
            onSelectVehicle(vehicle);
        }}>
            Select Car
          </SelectCarButton>
        </ActionsSection>
      </FleetVehicleCarCardContent>
    </FleetVehicleCarCard>);
};
export default FleetVehicleCard;
