import React, { useEffect, useState } from 'react';
import SimpleAutosuggest from 'src/components/SimpleAutosuggest';
import useFleetResponsiblePartySearchSelect from './useFleetResponsiblePartySearchSelect';
const FleetResponsiblePartySearchSelect = ({ onSelect, disabled, responsibleParties, initialValue, selectedResponsibleParties, fleet, vehicle, grouped, showRpTypeSuffix, }) => {
    var _a;
    const [processedInitialValue, setProcessedInitialValue] = useState();
    const firstRP = (selectedResponsibleParties === null || selectedResponsibleParties === void 0 ? void 0 : selectedResponsibleParties.length) && selectedResponsibleParties.length <= 1
        ? selectedResponsibleParties === null || selectedResponsibleParties === void 0 ? void 0 : selectedResponsibleParties[0]
        : { id: '' };
    const assignedRP = (firstRP === null || firstRP === void 0 ? void 0 : firstRP.id) || '';
    const rp = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) === null || _a === void 0 ? void 0 : _a.find(({ id }) => id === assignedRP);
    const { availableFleetResponsibleParties, getInitialValue } = useFleetResponsiblePartySearchSelect({
        fleet,
        vehicle,
        grouped,
        showRpTypeSuffix,
        responsibleParties,
        onSelect,
        initialValue,
    });
    useEffect(() => {
        var _a, _b, _c;
        const defaultRp = availableFleetResponsibleParties === null || availableFleetResponsibleParties === void 0 ? void 0 : availableFleetResponsibleParties.find(({ id }) => id === (rp === null || rp === void 0 ? void 0 : rp.responsiblePartyId));
        const initValue = (_c = (_b = (_a = initialValue === null || initialValue === void 0 ? void 0 : initialValue.name) !== null && _a !== void 0 ? _a : getNameWithSuffix(getInitialValue)) !== null && _b !== void 0 ? _b : getNameWithSuffix(defaultRp)) !== null && _c !== void 0 ? _c : '';
        if (initValue) {
            setProcessedInitialValue(initValue);
        }
    }, [initialValue, getInitialValue, availableFleetResponsibleParties]);
    const handleSearch = (value) => {
        var _a, _b;
        const filteredSuggestions = (_b = (_a = availableFleetResponsibleParties === null || availableFleetResponsibleParties === void 0 ? void 0 : availableFleetResponsibleParties.filter((el) => { var _a; return (_a = el === null || el === void 0 ? void 0 : el.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(value === null || value === void 0 ? void 0 : value.toLowerCase()); })) === null || _a === void 0 ? void 0 : _a.map((el) => {
            var _a;
            return Object.assign({ label: `${(_a = el.suffix) !== null && _a !== void 0 ? _a : ''} ${el.name}` }, el);
        })) !== null && _b !== void 0 ? _b : [];
        return filteredSuggestions;
    };
    const getNameWithSuffix = (getInitialValue) => {
        if (!getInitialValue)
            return null;
        return `${getInitialValue.suffix ? `[${getInitialValue.suffix}]` : ''} ${getInitialValue.name}`;
    };
    return (<SimpleAutosuggest onSearch={handleSearch} onChange={(suggestion) => {
            if (!suggestion) {
                onSelect();
            }
            if (processedInitialValue) {
                setProcessedInitialValue(undefined);
            }
        }} onSelect={(suggestion) => {
            onSelect(suggestion);
        }} initialValue={processedInitialValue} fieldName="name" fieldToInput="label" disabled={disabled} autoFocus={false} placeholder="Search by Responsible Party Name" suggestions={availableFleetResponsibleParties === null || availableFleetResponsibleParties === void 0 ? void 0 : availableFleetResponsibleParties.map((f) => {
            var _a;
            return ({
                id: f.id,
                name: getNameWithSuffix(f),
                label: getNameWithSuffix(f),
                groupName: (_a = f.groupName) !== null && _a !== void 0 ? _a : undefined,
            });
        })} noOptions="No responsible parties found" groupBy={(option) => { var _a; return (_a = option.groupName) !== null && _a !== void 0 ? _a : ''; }} filterOptions={(options, state) => {
            const { inputValue } = state;
            const filteredOptions = options === null || options === void 0 ? void 0 : options.filter((opt) => {
                var _a;
                return processedInitialValue
                    ? (opt === null || opt === void 0 ? void 0 : opt.name) !== processedInitialValue
                    : (_a = opt === null || opt === void 0 ? void 0 : opt.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(inputValue.toLowerCase());
            });
            if (!!filteredOptions && !!processedInitialValue) {
                // Ensure the initialValueOption is included in the options
                filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.unshift(options.find((opt) => (opt === null || opt === void 0 ? void 0 : opt.name) === processedInitialValue));
            }
            return filteredOptions;
        }}/>);
};
export default FleetResponsiblePartySearchSelect;
