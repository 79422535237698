import React, { useContext } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Checkbox, CircularProgress, Typography } from 'src/components/mui';
import { Tooltip, TooltipInfo } from 'src/components/tooltips';
import { colors, theme } from 'src/styles/theme';
import { scheduleContext } from './util';
const MechsContainer = styled('div') `
  min-height: 50px;
  overflow: auto;
  width: 100%;

  ${theme.breakpoints.up('md')} {
    flex-wrap: wrap;
    margin-bottom: 40px;
    margin-right: 0;
    margin-top: 16px;
    padding: 0;
  }
`;
const MechanicItem = styled.div `
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};
  display: flex;
  padding: 24px 0px;
  width: 100%;
`;
const Mechanics = ({ loading, mechanicIds, selectedVanId, mechanics, requiredSkills, onSelectMechanic, }) => {
    useContext(scheduleContext);
    const requiredSkillIds = requiredSkills.map((s) => s.id);
    if (loading)
        return (<MechsContainer>
        <CircularProgress />
      </MechsContainer>);
    if (isEmpty(mechanics))
        return (<MechsContainer>
        <Typography variant="subtitle2">No Technicians Available</Typography>
      </MechsContainer>);
    return (<MechsContainer>
      {mechanics === null || mechanics === void 0 ? void 0 : mechanics.map(({ deliveryVanSkeduloResourceId, mechanicFirstName, mechanicLastName, technicianId, deliveryVanName, tags, vanSkills, }) => {
            const isCurrent = mechanicIds.includes(technicianId) &&
                selectedVanId === deliveryVanSkeduloResourceId;
            const title = `Tools ID: ${technicianId}\n\nTech Skills: ${(tags && tags.join(', ')) || 'None'}`;
            const vanSkillIds = vanSkills === null || vanSkills === void 0 ? void 0 : vanSkills.map((s) => s.id);
            const hasAllRequiredSkills = requiredSkillIds.length === 0 ||
                (requiredSkillIds === null || requiredSkillIds === void 0 ? void 0 : requiredSkillIds.every((s) => vanSkillIds === null || vanSkillIds === void 0 ? void 0 : vanSkillIds.includes(s)));
            return (<MechanicItem key={technicianId}>
              <Tooltip disabled={hasAllRequiredSkills} title={"This van doesn't have all the required skills"}>
                <div>
                  <Checkbox checked={isCurrent} color="primary" onChange={() => onSelectMechanic(deliveryVanSkeduloResourceId, technicianId)} disabled={!hasAllRequiredSkills}/>
                </div>
              </Tooltip>
              <Tooltip title={title}>
                <div>
                  {`${mechanicFirstName} ${mechanicLastName} (${deliveryVanName})`}
                </div>
              </Tooltip>

              <TooltipInfo>
                {vanSkills && vanSkills.length > 0
                    ? vanSkills.map((s) => s.name).join(', ')
                    : 'No Skills found for this Van'}
              </TooltipInfo>
            </MechanicItem>);
        })}
    </MechsContainer>);
};
export default Mechanics;
