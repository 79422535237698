var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateFleetVehicleResponsibleParties } from 'src/AdminApp/modules/fleets/actions';
import { selectDateTime, selectFleet, selectMechanicIds, selectNewFleetVehicleResponsibleParty, selectRepairs, selectResponsibleParty, selectShift, selectTimeSlot, selectUser, selectVehicle, } from 'src/AdminApp/modules/quickRo/selectors';
import { createRO, createROFromVisitByRecommendedRepairs, getWorkOrderPayments, rescheduleRO, scheduleRO, } from 'src/AdminApp/modules/requests/actions';
import { selectRepairRequestWithReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { calculateTimezone, normalizeAddress, } from 'src/AdminApp/modules/utils';
import { toast } from 'src/components/SimpleToast';
import { addWorkOrdersToVisit } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { DATE_YMD_LONG_TIME_FORMAT } from 'src/timeConstants';
import compact from 'src/utils/compact';
const useSubmitQuickRO = ({ referenceNum }) => {
    const dispatch = useDispatch();
    const consumer = useSelector(selectUser);
    const fleet = useSelector(selectFleet);
    const vehicle = useSelector(selectVehicle);
    const repairs = useSelector(selectRepairs);
    const responsiblePartyId = useSelector(selectResponsibleParty);
    const fleetVehicleResponsiblePartyRequest = useSelector(selectNewFleetVehicleResponsibleParty);
    const timeSlot = useSelector(selectTimeSlot);
    const mechanicIds = useSelector(selectMechanicIds);
    const dateTime = useSelector(selectDateTime);
    let request = useSelector((state) => selectRepairRequestWithReferenceNum(state, referenceNum !== null && referenceNum !== void 0 ? referenceNum : ''));
    const shift = useSelector(selectShift);
    return ({ isFleet, isReschedule, isSchedule, resetParts = false, appointmentResolutionId, otherResolution, visitRefNum, processCancelFee = false, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const isSpecificTime = Boolean(dateTime);
        const address = isFleet ? (_a = fleet === null || fleet === void 0 ? void 0 : fleet.consumer) === null || _a === void 0 ? void 0 : _a.address : consumer === null || consumer === void 0 ? void 0 : consumer.address;
        if (!address) {
            toast.error('Validation Error: Fleet address is not set. Please fill it to submit the RO.');
            return { error: true };
        }
        const normalizedAddress = address ? normalizeAddress(address) : null;
        const normalizedAddressTZ = normalizedAddress
            ? yield calculateTimezone(normalizedAddress)
            : null;
        const consumerId = isFleet ? fleet === null || fleet === void 0 ? void 0 : fleet.consumerId : consumer === null || consumer === void 0 ? void 0 : consumer.id;
        const workItems = repairs.map((repair) => compact({
            id: repair.id,
            repairId: repair.repairId,
            repair: repair.label,
            customRepairText: repair.customRepairText,
            fmcCustomRepairId: repair.fmcCustomRepairId,
            internalApprovalProvidedById: repair.internalApprovalProvidedById,
        }));
        const newRepairs = workItems.filter((item) => !item.id);
        const recommendedRepairs = workItems.filter((item) => !!item.id);
        const internalApprovalProvidedById = workItems
            .map((item) => item.internalApprovalProvidedById)
            .find((id) => !!id);
        const createROParams = {
            address: normalizedAddressTZ !== null && normalizedAddressTZ !== void 0 ? normalizedAddressTZ : {},
            consumerCarId: (_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.id) !== null && _b !== void 0 ? _b : 0,
            consumerId: consumerId,
            mileage: (_d = (_c = vehicle === null || vehicle === void 0 ? void 0 : vehicle.mileage) !== null && _c !== void 0 ? _c : vehicle === null || vehicle === void 0 ? void 0 : vehicle.customMileage) !== null && _d !== void 0 ? _d : 0,
            repairs: isFleet && !newRepairs.length
                ? recommendedRepairs
                : newRepairs,
            internalApprovalProvidedById,
            responsiblePartyId,
        };
        if ((fleetVehicleResponsiblePartyRequest === null || fleetVehicleResponsiblePartyRequest === void 0 ? void 0 : fleetVehicleResponsiblePartyRequest.vin) &&
            (fleetVehicleResponsiblePartyRequest === null || fleetVehicleResponsiblePartyRequest === void 0 ? void 0 : fleetVehicleResponsiblePartyRequest.responsiblePartyId) &&
            (fleet === null || fleet === void 0 ? void 0 : fleet.id)) {
            const response = yield dispatch(updateFleetVehicleResponsibleParties(fleet.id, fleetVehicleResponsiblePartyRequest));
            if (response && !response.error) {
                toast.success('Responsible Parties updated successfully');
            }
        }
        let scheduleROParams = null;
        if (timeSlot || isSpecificTime) {
            scheduleROParams = {
                appointmentTime: isSpecificTime
                    ? moment(dateTime).add(moment().utcOffset(), 'm').utc().toISOString()
                    : moment
                        .utc(`${timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot.date} ${timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot.time}`, DATE_YMD_LONG_TIME_FORMAT)
                        .add((timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot.slot.offset) || 0, 'm')
                        .toISOString(),
                vanId: (_g = (_e = timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot.slot.vanId) !== null && _e !== void 0 ? _e : (_f = timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot.slot.van) === null || _f === void 0 ? void 0 : _f.deliveryVanId) !== null && _g !== void 0 ? _g : '',
                mechanicIds: isSpecificTime
                    ? mechanicIds
                    : (_j = (_h = timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot.slot) === null || _h === void 0 ? void 0 : _h.mechanics) === null || _j === void 0 ? void 0 : _j.map((mechanic) => mechanic.technicianId),
                shift,
            };
        }
        const cancellationParams = {
            appointmentResolutionId,
            otherResolution,
            processCancelFee,
        };
        if (isSchedule && referenceNum) {
            let result;
            if (!visitRefNum) {
                result = yield dispatch(scheduleRO(Object.assign(Object.assign(Object.assign({}, scheduleROParams), { consumerId, address: normalizedAddressTZ }), cancellationParams), referenceNum, isSpecificTime, resetParts));
            }
            else if (request) {
                yield dispatch(addWorkOrdersToVisit({
                    refNum: visitRefNum,
                    workOrderIds: [request.workOrder.id],
                    resetParts,
                }));
            }
            if (request === null || request === void 0 ? void 0 : request.workOrder)
                dispatch(getWorkOrderPayments(request.workOrder.id));
            return result;
        }
        if (isReschedule && referenceNum) {
            let result;
            if (!visitRefNum) {
                result = yield dispatch(rescheduleRO(Object.assign(Object.assign(Object.assign(Object.assign({}, scheduleROParams), createROParams), { suggestedRepairIds: [] }), cancellationParams), referenceNum, isSpecificTime, resetParts));
            }
            else if (request) {
                yield dispatch(addWorkOrdersToVisit({
                    refNum: visitRefNum,
                    workOrderIds: [request.workOrder.id],
                    resetParts,
                }));
            }
            if (request === null || request === void 0 ? void 0 : request.workOrder) {
                dispatch(getWorkOrderPayments(request.workOrder.id));
            }
            return result;
        }
        if (visitRefNum && (recommendedRepairs === null || recommendedRepairs === void 0 ? void 0 : recommendedRepairs.length) > 0) {
            const createROParamsForVisit = {
                address: createROParams.address,
                consumerCarId: createROParams.consumerCarId,
                consumerId: consumerId,
                mileage: createROParams.mileage,
                repairs: createROParams.repairs,
                internalApprovalProvidedById,
            };
            return dispatch(createROFromVisitByRecommendedRepairs(Object.assign(Object.assign(Object.assign({}, scheduleROParams), createROParamsForVisit), { recommendedRepairIds: recommendedRepairs.map((item) => item.id), referenceNum }), isSpecificTime, visitRefNum));
        }
        return dispatch(createRO(Object.assign(Object.assign(Object.assign({}, scheduleROParams), createROParams), { recommendedRepairIds: recommendedRepairs.map((item) => item.id), referenceNum: (recommendedRepairs === null || recommendedRepairs === void 0 ? void 0 : recommendedRepairs.length) > 0 ? referenceNum : undefined }), isSpecificTime, visitRefNum));
    });
};
export default useSubmitQuickRO;
