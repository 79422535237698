import React from 'react';
import { Paginator } from './StyledTablePagination.styles';
const StyledTablePagination = ({ paginationData, page, onPageChange, rowsPerPage, onRowsPerPageChange, }) => {
    return (<Paginator classes={{
            root: 'tablePagination',
            select: 'tablePaginationSelect',
            menuItem: 'paginationMenuItem',
            actions: 'tablePaginationCaptionActions',
            selectIcon: 'tablePaginationSelectIcon',
        }} count={paginationData.totalElements} page={page} onPageChange={onPageChange} rowsPerPage={rowsPerPage} onRowsPerPageChange={onRowsPerPageChange}/>);
};
export default StyledTablePagination;
