import React, { useState } from 'react';
import { Tab, Tabs } from 'src/components/mui';
import TabPanel from 'src/AdminApp/containers/fleets/tabs/TabPanel';
import { TabContent } from 'src/AdminApp/containers/fleets/tabs/styles';
import useIsMobile from 'src/hooks/useIsMobile';
import FleetInfo from './FleetInfo';
import Settings from './FleetInfo/Settings/Settings';
import AccountManagement from './AccountManagement';
import PricingRules from '../pricing/PricingRules';
const Info = () => {
    const isMobile = useIsMobile();
    const [tab, setTab] = useState(0);
    const handleTabChange = (_e, value) => setTab(value);
    const tabsVariant = isMobile ? 'fullWidth' : 'scrollable';
    return (<>
      <Tabs indicatorColor="primary" scrollButtons="auto" textColor="primary" value={tab} variant={tabsVariant} onChange={handleTabChange}>
        <Tab label="Fleet Info"/>
        <Tab label="Settings"/>
        <Tab label="Pricing"/>
        <Tab label="Account Management"/>
      </Tabs>
      <TabContent>
        <TabPanel index={0} value={tab}>
          <FleetInfo />
        </TabPanel>
        <TabPanel index={1} value={tab}>
          <Settings />
        </TabPanel>
        <TabPanel index={2} value={tab}>
          <PricingRules />
        </TabPanel>
        <TabPanel index={3} value={tab}>
          <AccountManagement />
        </TabPanel>
      </TabContent>
    </>);
};
export default Info;
