import React from 'react';
import styled from 'styled-components';
import { Button, TableCell, TableRow } from 'src/components/mui';
import { formatTimestamp } from 'src/utils/formatter';
const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  td {
    padding: 15px !important;
    font-weight: 300;
  }
`;
const DashboardListItem = ({ item, onRecoverUpdate, onIgnoredUpdate, }) => {
    return (<StyledTableRow>
      <TableCell>{item.regionName}</TableCell>
      <TableCell>{item.date}</TableCell>
      <TableCell>{item.status}</TableCell>
      <TableCell>{item.created ? formatTimestamp(item.created) : ''}</TableCell>
      <TableCell>{item.errorDetails}</TableCell>
      <TableCell>
        {item.status === 'ERROR' && (<>
            <Button className="editButton" color="primary" variant="text" onClick={onRecoverUpdate}>
              Recover
            </Button>
            <Button className="editButton" color="primary" variant="text" onClick={onIgnoredUpdate}>
              Ignore
            </Button>
          </>)}
      </TableCell>
    </StyledTableRow>);
};
export default DashboardListItem;
