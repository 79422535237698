import React from 'react';
import { InputAdornment, TextField } from 'src/components/mui';
import { DrawerFormRow } from 'src/components/SimpleDrawer';
const MilesIn = ({ stateVehicle, onFieldChange, isFleet }) => {
    return (<DrawerFormRow item xs={12}>
      <TextField variant="outlined" value={(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.mileage) || ''} onChange={onFieldChange('mileage')} label={isFleet ? 'Mileage' : 'Miles In'} type="text" name="mileage" fullWidth InputProps={{
            endAdornment: <InputAdornment position="end">miles</InputAdornment>,
        }}/>
    </DrawerFormRow>);
};
export default MilesIn;
