import React, { useCallback, useEffect, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { FormControl, TextField, Autocomplete } from 'src/components/mui';
const BaseProductIdSelect = ({ baseProduct, currentRepairId, setBaseProduct, onSearchBaseProducts, }) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleSearchProducts = (value = '') => {
        onSearchBaseProducts(value).then((response) => {
            setProducts(response === null || response === void 0 ? void 0 : response.payload);
            setLoading(false);
        });
    };
    useEffect(() => {
        if (isEmpty(products))
            handleSearchProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearchBaseProducts = useCallback(debounce(handleSearchProducts, 500), []);
    const handleInputChange = (_, value) => {
        setLoading(true);
        debouncedSearchBaseProducts(value);
    };
    return (<FormControl fullWidth>
      <Autocomplete loading={loading} options={[
            ...products.filter((p) => (p === null || p === void 0 ? void 0 : p.id) !== (baseProduct === null || baseProduct === void 0 ? void 0 : baseProduct.id)),
            baseProduct,
        ]} getOptionLabel={(option) => (option === null || option === void 0 ? void 0 : option.name) || ''} isOptionEqualToValue={(option, value) => (option === null || option === void 0 ? void 0 : option.id) === (value === null || value === void 0 ? void 0 : value.id)} getOptionDisabled={(option) => (option === null || option === void 0 ? void 0 : option.id) === currentRepairId || (option === null || option === void 0 ? void 0 : option.baseProductId) !== null} value={baseProduct} onChange={(_, newValue) => setBaseProduct(newValue)} onInputChange={handleInputChange} renderInput={(params) => (<TextField {...params} fullWidth variant="standard"/>)}/>
    </FormControl>);
};
export default BaseProductIdSelect;
