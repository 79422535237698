import { isIOS } from 'src/utils/crossPlatformUtils';
export const openMaps = (address, location) => {
    let url = '';
    if (address) {
        const addressFormatted = `${address.street} ${address.city}, ${address.state} ${address.zip}`.replace(/null/g, '');
        if (isIOS)
            url = `https://maps.apple.com/?daddr=${addressFormatted}&dirflg=d&t=m`;
        else if (location && location.latitude && location.longitude) {
            url = `https://www.google.com/maps/dir/?api=1&origin=${location === null || location === void 0 ? void 0 : location.latitude},${location === null || location === void 0 ? void 0 : location.longitude}&destination=${addressFormatted}`;
        }
        else {
            url = `https://www.google.com/maps/dir/?api=1&destination=${addressFormatted}`;
        }
    }
    window.open(url, 'location=yes');
};
export const renderEnvironment = (environment) => {
    if (environment && !environment.toLowerCase().includes('prod'))
        return `(${environment})`;
};
export const groupByRepairRequest = (items) => {
    return items.reduce((acc, item) => {
        const { repairRequestReferenceNum } = item;
        const group = acc.find((g) => g.repairRequestReferenceNum === repairRequestReferenceNum);
        if (group) {
            group.items.push(item);
        }
        else {
            acc.push({ repairRequestReferenceNum, items: [item] });
        }
        return acc;
    }, []);
};
