import { toEnum } from 'src/models/enums';
export const AppointmentDeliveryChannel = toEnum({
    REFERRAL: 'Referral',
    DELIVERY: 'Delivery',
    DIRECT: 'Direct',
    HAZMAT: 'Hazmat',
    SUBLET: 'Sublet',
    NONE: 'None'
});
export default AppointmentDeliveryChannel;
