import CONFIG from 'src/config';
import { PrefixHandler } from 'src/utils/url-utils';
const API_URLS = {
    SYSTEM: {
        REINDEX_CONSUMER_SEARCH: () => '/search-service/repair-search-terms/index',
        REINDEX_FLEET_SEARCH: () => '/search-service/repair-search-terms/fleet-index',
        RESET_SKEDULO_IDS: () => '/isp-service/admin/skedulo/reset',
        MATCH_SKEDULO_IDS: () => '/isp-service/admin/skedulo/match',
        LOAD_AUDIT_CHANGELOG: ({ schema, table, id, page, }) => `/search-service/audit/db/${schema}/${table}/${id}/changelog?from=${page}&size=20`,
        APPOINTMENT_RESOLUTIONS: () => '/repair-service/admin/appointments/resolutions',
        APPOINTMENT_RESOLUTIONS_OPTIONS: () => '/repair-service/admin/appointments/resolutions/options',
        SYNC_TEMPLATES: () => '/isp-service/admin/availability/template/skedulo/sync',
        CUSTOM_CONFIGS: () => '/repair-service/admin/config',
    },
};
const API_PROXY = new Proxy(API_URLS, PrefixHandler(CONFIG.CLIENT_API_URL));
export default Object.assign({}, API_PROXY);
