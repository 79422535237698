import { startCase, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Popover, Select } from 'src/components/mui';
import { Checkbox } from 'src/components/SimpleCheckbox';
import useIsMobile from 'src/hooks/useIsMobile';
import { ActionButton, Actions, Item, } from '../InvoiceRequestStatusSelect/InvoiceRequestStatusSelect.styles';
const InvoiceRequestTypeSelect = ({ type = [], onChange, disabled, }) => {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [innerType, setInnerType] = useState([]);
    useEffect(() => {
        setInnerType(type);
    }, [type]);
    const handleChange = (value) => {
        setInnerType(state => state.includes(value) ? state.filter(s => s !== value) : [...state, value]);
    };
    const handleClear = () => {
        setInnerType([]);
        setOpen(false);
        setAnchorEl(null);
        onChange([]);
    };
    const INVOICE_REQUEST_TYPES = [
        'GENERATE_INVOICE',
        'SEND_INVOICE'
    ];
    return (<>
      <Select labelId="type-select-label" id="type-select" value={type} style={{
            minWidth: 160,
            maxWidth: isMobile ? 0 : 400,
            width: isMobile ? '100%' : 'auto',
        }} label="Type" disabled={disabled} MenuProps={{
            style: {
                opacity: 0,
            },
        }} open={open} onClick={(event) => {
            if (disabled)
                return;
            setOpen(!open);
            setAnchorEl(event === null || event === void 0 ? void 0 : event.currentTarget);
        }} renderValue={() => {
            return (innerType !== null && innerType !== void 0 ? innerType : [])
                .map((s) => startCase(toLower(s).replaceAll('_', ' ')))
                .join(', ');
        }}></Select>
      <Popover open={open} anchorEl={anchorEl} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }} transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }} onClose={() => {
            setOpen(false);
            setAnchorEl(null);
            setInnerType(type);
        }} style={{ minWidth: 260 }}>
        {INVOICE_REQUEST_TYPES.map((s) => (<Item key={s} onClick={() => handleChange(s)}>
            <Checkbox checked={innerType.includes(s)}/>
            {startCase(toLower(s).replaceAll('_', ' '))}
          </Item>))}

        <Actions>
          <ActionButton onClick={handleClear}>Reset</ActionButton>
          <ActionButton onClick={() => {
            setOpen(false);
            setAnchorEl(null);
            onChange(innerType);
        }} isMain>
            Apply
          </ActionButton>
        </Actions>
      </Popover>
    </>);
};
export default InvoiceRequestTypeSelect;
