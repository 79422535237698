import styled from 'styled-components';
import { Button, TableHead, TablePagination } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const JumpButton = styled(Button) `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  font-weight: 400;

  svg {
    width: 18px;
    height: 18px;
    fill: ${colors.GRAY_76};
    color: ${colors.GRAY_76};
  }
`;
export const VisitsTableHead = styled(TableHead) `
  width: 100%;
  background-color: ${colors.GRAY_FA};
  border-top: solid 1px ${colors.GRAY_DE};
  border-bottom: solid 1px ${colors.GRAY_DE};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.14);

  th {
    font-weight: 400;
  }
`;
export const VisitsContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  .VisitsNoData {
    margin: 8px 0 0;
  }
`;
export const VisitsActions = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const VisitsPagination = styled(TablePagination) `
  font-size: 12px;

  .VisitsPaginationInput {
    font-size: 12px;
    color: ${colors.black};
  }

  .VisitsPaginationToolbar {
    font-size: 12px;
    color: ${colors.GRAY_76};
  }
`;
