var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, } from 'src/components/mui';
import { Visibility, VisibilityOff } from 'src/components/mui/icons';
import { logout, resetForgottenPassword } from 'src/modules/auth/actions';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import { toast } from 'src/components/SimpleToast';
const PasswordUpdate = ({ userId, editLoggedUser = true, }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentPassword, setCurrentPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
    const [isPasswordSubmitting, setIsPasswordSubmitting] = useState(false);
    const validatePassword = () => {
        const validPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/i;
        return !newPassword || validPassword.test(newPassword);
    };
    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    };
    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };
    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };
    const handleClickShowPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };
    const handleClickNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleClickConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const clearAllInputs = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };
    const handlePasswordSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsPasswordSubmitting(true);
        if (!newPassword || !confirmPassword || !currentPassword) {
            return;
        }
        const updatePasswordPayload = {
            password: newPassword,
            confirmPassword,
            userId,
        };
        const updatePasswordResponse = yield dispatch(resetForgottenPassword(updatePasswordPayload));
        if (!updatePasswordResponse.error) {
            analyticsTrackEvent('Update Password sent', {
                'Password': updatePasswordPayload.password,
                'Confirm Password': updatePasswordPayload.confirmPassword,
            });
            toast.success('Update password successfully');
            if (editLoggedUser) {
                yield dispatch(logout({ history }));
            }
            else {
                clearAllInputs();
            }
        }
        else {
            toast.error(updatePasswordResponse.payload.response.message);
        }
        setIsPasswordSubmitting(false);
    });
    useEffect(() => {
        setIsValidPassword(!validatePassword());
        setIsValidConfirmPassword(newPassword !== confirmPassword);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmPassword, newPassword]);
    return (<>
      <FormControl variant="outlined">
        <InputLabel htmlFor="myProfile-current-password-input">
          Current Password
        </InputLabel>
        <OutlinedInput id="myProfile-current-password-input" type={showCurrentPassword ? 'text' : 'password'} value={currentPassword} onChange={handleCurrentPasswordChange} endAdornment={<InputAdornment position="end">
              <IconButton aria-label="toggle current password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>}/>
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel htmlFor="myProfile-new-password-input">
          New Password
        </InputLabel>
        <OutlinedInput id="myProfile-new-password-input" type={showNewPassword ? 'text' : 'password'} value={newPassword} onChange={handleNewPasswordChange} endAdornment={<InputAdornment position="end">
              <IconButton aria-label="toggle current password visibility" onClick={handleClickNewPassword} onMouseDown={handleMouseDownPassword} edge="end">
                {showNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>}/>
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel htmlFor="myProfile-new-password-confirm-input">
          Confirm Password
        </InputLabel>
        <OutlinedInput id="myProfile-new-password-confirm-input" type={showConfirmPassword ? 'text' : 'password'} value={confirmPassword} onChange={handleConfirmPasswordChange} endAdornment={<InputAdornment position="end">
              <IconButton aria-label="toggle current password visibility" onClick={handleClickConfirmPassword} onMouseDown={handleMouseDownPassword} edge="end">
                {showNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>}/>
      </FormControl>
      <Button variant="contained" color="primary" size="large" disabled={!currentPassword ||
            isValidPassword ||
            isValidConfirmPassword ||
            isPasswordSubmitting} onClick={handlePasswordSubmit}>
        {isPasswordSubmitting ? <CircularProgress /> : 'Reset Password'}
      </Button>
    </>);
};
export default PasswordUpdate;
