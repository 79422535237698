import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from 'src/components/mui';
import { AccountCircle } from 'src/components/mui/icons';
import { NavLink } from 'src/containers/utils';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
import { SimpleModal } from 'src/components/layout';
import Menu from '../Menu/Menu';
import { LogoutIcon } from '../Menu/icons';
import { AppBarContainer, LogoColumn, NavLinksColumn, ActionIcon, } from './AppBar.styles';
export const DRAWER_WIDTH = 200;
const Logo = () => (<img style={{ width: 60 }} src={`${STATIC_URL_BASE}anms/favicons/favicon-96x96.png`} alt="AutoNation Mobile Service"/>);
const AppBar = () => {
    const history = useHistory();
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    return (<AppBarContainer>
      <LogoColumn>
        <NavLink to="/">
          <Logo />
        </NavLink>
      </LogoColumn>
      <NavLinksColumn container direction="column" style={{ flex: '1' }}>
        <Grid item xs>
          <Menu />
        </Grid>
      </NavLinksColumn>
      <NavLinksColumn container direction="row" style={{
            padding: '50px 34px 24px 34px',
            justifyContent: 'space-between',
        }}>
        <ActionIcon onClick={() => history.push('/my-profile')}>
          <AccountCircle htmlColor={colors.default}/>
        </ActionIcon>

        <ActionIcon onClick={() => setIsLogoutModalOpen(true)}>
          <LogoutIcon />
        </ActionIcon>
      </NavLinksColumn>
      <SimpleModal open={isLogoutModalOpen} onClose={() => setIsLogoutModalOpen(false)} title="Are you sure you want to log out?" simpleTwoButtonRowProps={{
            cancelText: 'No',
            submitText: 'Yes',
            onCancel: () => setIsLogoutModalOpen(false),
            onSubmit: () => history.push('/logout'),
        }}/>
    </AppBarContainer>);
};
export default AppBar;
