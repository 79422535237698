import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const StyledChevronRightIcon = styled(ChevronRightIcon) `
  margin-left: auto;
  color: ${colors.iconGrey};
`;
export const MobileButtonWrapper = styled.div `
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  box-shadow: inset 0 -1px 0 0 ${colors.lightGrey};
`;
export const IssuesFound = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.error};
  margin-top: 9px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;

  svg {
    padding-right: 4px;
  }
`;
export const NoIssuesFound = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.success};
  margin-top: 9px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;

  svg {
    padding-right: 4px;
  }
`;
export const HealthReportWrapper = styled.div `
  margin-top: 48px;

  h6 {
    margin-bottom: 0;
  }

  .downloadMpiButton {
    padding: 0;
    margin: 0;
    position: relative;
    top: 1px;
  }
`;
export const HealthReportStatsWrapper = styled.div `
  margin-top: 16px;
`;
export const AdvisorNameWrapper = styled.div `
  display: flex;

  > span {
    padding-right: 5px;
  }
`;
export const PerformedStatusWrapper = styled.section `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_3F};
  margin-top: 16px;
`;
export const PerformedStatusMetadata = styled.div `
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.darkGrey};
  margin-top: 4px;
`;
export const HealthReportButtonWrapper = styled.div `
  margin-top: 25px;

  .mainMPIButton {
    margin-right: 16px;
  }
`;
export const WarrantyButtonWrapper = styled.div `
  margin-top: 50px;

  .mainMPIButton {
    margin-right: 20px;
  }
`;
