import { createContext, useState } from 'react';
const DEFAULT_AUTOREFRESH_INTERVAL_SECONDS = 30;
export const useSwimlanesDashboardContext = () => {
    const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(true);
    const [autoRefreshIntervalSeconds, setAutoRefreshIntervalSeconds] = useState(DEFAULT_AUTOREFRESH_INTERVAL_SECONDS);
    const [lastActivity, setLastActivity] = useState(Date.now());
    const toggleAutoRefresh = () => setAutoRefreshEnabled(!autoRefreshEnabled);
    const trackLastActivity = () => setLastActivity(Date.now());
    return {
        autoRefreshEnabled,
        autoRefreshIntervalSeconds,
        setAutoRefreshIntervalSeconds,
        lastActivity,
        trackLastActivity,
        toggleAutoRefresh,
    };
};
const SwimlanesDashboardContext = createContext({
    autoRefreshEnabled: true,
    autoRefreshIntervalSeconds: DEFAULT_AUTOREFRESH_INTERVAL_SECONDS,
    setAutoRefreshIntervalSeconds: () => { },
    lastActivity: Date.now(),
    trackLastActivity: () => { },
    toggleAutoRefresh: () => { },
});
export default SwimlanesDashboardContext;
