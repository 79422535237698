var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, TextField, Autocomplete } from 'src/components/mui';
import useDebounce from 'src/modules/api/utils/useDebounce';
const invisibleIcon = <div style={{ display: 'none' }}/>;
const SearchMultiselect = ({ value, fixedValues, getEntities, selectEntities, onUpdate, loadEntitiesByFocus = false, name, label, }) => {
    const dispatch = useDispatch();
    const entities = useSelector(selectEntities);
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, 500);
    useEffect(() => {
        (() => {
            dispatch(getEntities(debouncedInputValue));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedInputValue]);
    const parsedFixedValues = (fixedValues || []).map((i) => {
        return Object.assign(Object.assign({}, i), { fixed: true });
    });
    const values = [...parsedFixedValues, ...value];
    const options = entities.filter((i) => !values.find((option) => option.id === i.id));
    const onSubmit = (data) => {
        const filteredData = data.filter((i) => !i.fixed);
        onUpdate(filteredData);
    };
    const onFocus = () => __awaiter(void 0, void 0, void 0, function* () {
        if (loadEntitiesByFocus) {
            yield dispatch(getEntities(undefined));
        }
    });
    return (<Autocomplete multiple inputValue={inputValue} value={values} options={options} onInputChange={(event, newInputValue, reason) => reason === 'input' && setInputValue(newInputValue)} onChange={(e, newValue) => {
            onSubmit(newValue);
        }} onFocus={onFocus} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (<Chip label={option.name} {...getTagProps({ index })} disabled={option.fixed} deleteIcon={option.fixed && invisibleIcon}/>));
        }} renderInput={(params) => (<TextField {...params} name={name} label={label} type="text" variant="standard" InputProps={Object.assign(Object.assign({}, params.InputProps), { 
                // eslint-disable-next-line react/jsx-no-useless-fragment
                endAdornment: <>{params.InputProps.endAdornment}</> })}/>)}/>);
};
export default SearchMultiselect;
