import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    invoiceRequests: [],
    pagination: {},
    filters: {
        status: [],
        responsiblePartyId: undefined,
        sort: undefined,
    },
    isFetching: false,
    responsibleParties: [],
};
const reducers = handleActions({
    [ActionCreators.GET_INVOICE_REQUESTS.FETCH.type]: (state) => {
        return Object.assign(Object.assign({}, state), { isFetching: true });
    },
    [ActionCreators.GET_INVOICE_REQUESTS.FAILURE.type]: (state) => {
        return Object.assign(Object.assign({}, state), { isFetching: false });
    },
    [ActionCreators.GET_INVOICE_REQUESTS.SUCCESS.type]: (state, action) => {
        var _a, _b, _c, _d, _e;
        return Object.assign(Object.assign({}, state), { isFetching: false, invoiceRequests: (_a = action.payload) === null || _a === void 0 ? void 0 : _a.content, pagination: {
                pageNumber: (_b = action.payload) === null || _b === void 0 ? void 0 : _b.number,
                pageSize: (_c = action.payload) === null || _c === void 0 ? void 0 : _c.size,
                totalElements: (_d = action.payload) === null || _d === void 0 ? void 0 : _d.totalElements,
                totalPages: (_e = action.payload) === null || _e === void 0 ? void 0 : _e.totalPages,
            } });
    },
    [ActionCreators.GET_INVOICE_REQUESTS.CLEAR.type]: (state) => {
        return Object.assign(Object.assign({}, state), { invoiceRequests: [], pagination: {} });
    },
}, DEFAULT_STATE);
export default reducers;
