import { useState } from 'react';
import { parseStringNumber } from 'src/utils/data-utils';
import { VIN_LENGTH } from 'src/AdminApp/modules/api/constants';
const useFleetCarDrawer = ({ onSubmit }) => {
    const [selectedVehicle, setSelectedVehicle] = useState();
    const [isValid, setIsValid] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState('');
    const handleSubmit = () => {
        if (selectedVehicle) {
            const parsedMileage = parseStringNumber(selectedVehicle.mileage);
            if (!parsedMileage || parsedMileage === 0) {
                setConfirmationModalOpen(false);
            }
            else {
                if (parsedMileage <= 10) {
                    setConfirmationModalOpen(true);
                    setConfirmationModalText('You’ve entered in a low mileage, are you sure?');
                    return;
                }
                if (parsedMileage >= 300000) {
                    setConfirmationModalOpen(true);
                    setConfirmationModalText('You’ve entered in a high mileage, are you sure?');
                    return;
                }
            }
        }
        onSubmit(selectedVehicle);
    };
    const handleVehicleUpdated = (v) => {
        var _a, _b;
        const isLicensePlateValid = !!v.plateNumber === !!v.plateNumberState;
        setIsValid(isLicensePlateValid &&
            (((_a = v.vin) === null || _a === void 0 ? void 0 : _a.length) === 0 || ((_b = v.vin) === null || _b === void 0 ? void 0 : _b.length) === VIN_LENGTH));
        setSelectedVehicle(v);
    };
    return {
        confirmationModalOpen,
        confirmationModalText,
        selectedVehicle,
        setConfirmationModalOpen,
        handleVehicleUpdated,
        handleSubmit,
        isValid,
    };
};
export default useFleetCarDrawer;
