var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { SimpleModal } from 'src/components/layout';
import AddCustomRepairForm from 'src/AdminApp/containers/repairs/AddCustomRepairForm';
import SelectInternalApprovalProviderFormModal from 'src/AdminApp/containers/repairs/SelectInternalApprovalProviderFormModal';
import SimpleAutosuggest from '../SimpleAutosuggest';
import { toast } from 'src/components/SimpleToast';
const RepairSearchSelect = ({ onSelect, onSearch, onCustomRepairSubmit, autoFocus, fleetId, InputProps = {}, showCustomSuggestions = true, handleCustomRepairDialog, showCustomInputActionButtons = true, clearCustom = true, }) => {
    const [isOpenCustomRepairDialog, setOpenCustomRepairDialog] = useState(false);
    const [isOpenApprovalDialog, setOpenApprovalDialog] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    const openCustomRepairDialog = () => handleCustomRepairDialog
        ? handleCustomRepairDialog()
        : setOpenCustomRepairDialog(true);
    const closeCustomRepairDialog = () => {
        setOpenCustomRepairDialog(false);
    };
    const closeApprovalDialog = () => {
        setOpenApprovalDialog(false);
        setSelectedSuggestion(null);
    };
    const handleCustomRepairSubmit = (values) => {
        if (onCustomRepairSubmit)
            onCustomRepairSubmit(values.repairDescription);
    };
    const handleInternalApprovalProviderSubmit = (internalApprovalProvidedById) => {
        var _a;
        onSelect(selectedSuggestion, internalApprovalProvidedById);
        setOpenApprovalDialog(false);
        (_a = document.getElementById('autoSuggestInput')) === null || _a === void 0 ? void 0 : _a.blur();
    };
    const handleSuggestionSelected = (suggestion) => {
        setSelectedSuggestion(suggestion);
        if (suggestion.customEvent) {
            suggestion.customEvent();
        }
        else if (suggestion.requiresApproval) {
            setOpenApprovalDialog(true);
        }
        else {
            onSelect(suggestion);
        }
    };
    return (<>
      <SimpleAutosuggest label="" placeholder="Search services..." fieldName="name" groupBy={(option) => { var _a; return (_a = option.groupName) !== null && _a !== void 0 ? _a : 'Custom repairs'; }} inputId="autoSuggestInput" InputProps={InputProps} autoFocus={autoFocus} clearOnSelect={clearCustom} onSearch={(v) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            const response = yield onSearch(v, fleetId);
            if (response === null || response === void 0 ? void 0 : response.error) {
                toast.error((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.message);
                return [];
            }
            const items = (_c = (_b = response === null || response === void 0 ? void 0 : response.payload) === null || _b === void 0 ? void 0 : _b.results.map((r) => ({
                name: r.source.name,
                repairId: r.source.repairId[0],
                requiresApproval: r.source.requiresApproval,
                groupName: 'Common repairs',
            }))) !== null && _c !== void 0 ? _c : [];
            return items;
        })} onSelect={handleSuggestionSelected} filterOptions={(options) => {
            if (!showCustomSuggestions) {
                return options;
            }
            return [
                ...options,
                {
                    name: 'Add a custom repair',
                    groupName: 'Custom repairs',
                    customEvent: openCustomRepairDialog,
                },
            ];
        }}/>
      <SimpleModal open={isOpenCustomRepairDialog} onClose={closeCustomRepairDialog} title="Add a custom repair" maxWidth="md">
        <AddCustomRepairForm onSubmit={handleCustomRepairSubmit} onClose={closeCustomRepairDialog} showActionButtons={showCustomInputActionButtons}/>
      </SimpleModal>
      <SelectInternalApprovalProviderFormModal onSubmit={handleInternalApprovalProviderSubmit} onClose={closeApprovalDialog} open={isOpenApprovalDialog}/>
    </>);
};
export default RepairSearchSelect;
