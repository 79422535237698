var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid } from 'src/components/mui';
import { alignSelfText, ChipGrid, ChipText, TotalPod, } from 'src/AdminApp/components/repairs/statement/styledComponents';
import PayerChip from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/PayerChip';
const gridStyle = {
    '--margin-bottom': '32px',
    '--margin-top': 0,
};
const PayerChipsList = (_a) => {
    var { inTitle = false, responsibleParties = [], authorization } = _a, rest = __rest(_a, ["inTitle", "responsibleParties", "authorization"]);
    if (responsibleParties.length < 1)
        return null;
    const chipStyle = inTitle ? {} : gridStyle;
    return (<>
      {responsibleParties.map(({ amount, id, responsibleParty }) => {
            var _a;
            return (<ChipGrid key={id} container style={chipStyle}>
          <Grid item xs>
            <PayerChip name={responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.name} responsiblePartyType={responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.responsiblePartyType} {...rest} authorizationNumber={authorization &&
                    (authorization === null || authorization === void 0 ? void 0 : authorization.responsiblePartyId) === (responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.id)
                    ? authorization === null || authorization === void 0 ? void 0 : authorization.number
                    : undefined}/>
          </Grid>
          {amount != null && (<TotalPod item style={alignSelfText} xs={3}>
              <ChipText>${(_a = amount === null || amount === void 0 ? void 0 : amount.toFixed) === null || _a === void 0 ? void 0 : _a.call(amount, 2)}</ChipText>
            </TotalPod>)}
        </ChipGrid>);
        })}
    </>);
};
export default PayerChipsList;
