import React from 'react';
import { SlideWrapper } from 'src/AdminApp/components/drawers/styledComponents';
import HeyTech from 'src/components/layout/HeyTech';
import { FooterActionButton, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import AddRepairOrderDrawer from '../AddRepairOrderDrawer';
import Slider from '../AddRepairOrderDrawer/Slider';
import useWarrantyDecisionsDrawer from './useWarrantyDecisionsDrawer';
import WarrantyDecisionsQuestions from './WarrantyDecisionsQuestions';
import WarrantyDecisionsRecommendedRepairs from './WarrantyDecisionsRecommendedRepairs';
const WarrantyDecisionsDrawer = ({ open, referenceNum, currentTaskWorkFlowState, warrantyClaims, }) => {
    const { tab, closeDrawerHandler, getOnClickNext, handleSetTab, isEligible, isDefect, reason, approvedInspectionIds, setIsDefect, setIsEligible, setReason, setApprovedInspectionIds, drawerOpened, getIsContinueDisabled, TABS, getOnNextCta, } = useWarrantyDecisionsDrawer(open, referenceNum, currentTaskWorkFlowState, warrantyClaims);
    const questionsComponent = () => warrantyClaims && (<WarrantyDecisionsQuestions isDefect={isDefect} isEligible={isEligible} reason={reason} setIsDefect={setIsDefect} setIsEligible={setIsEligible} setReason={setReason}/>);
    const renderTechScript = () => {
        let scriptCopy = 'Looks like we need to select a recommended repair to resolve this warranty claim. Select a recommended repair to finalize the inspection.';
        if (!isDefect) {
            scriptCopy =
                'The following request is not covered by our AutoNation Mobile Service Limited Warranty. 1 hour of labor, plus applicable taxes and fees, will be charged for this service call.';
        }
        return (<SlideWrapper>
        <HeyTech copy={scriptCopy}/>
      </SlideWrapper>);
    };
    const renderRecomendedRepairs = () => (<WarrantyDecisionsRecommendedRepairs referenceNumber={referenceNum} setApprovedInspectionIds={setApprovedInspectionIds} approvedInspectionIds={approvedInspectionIds}/>);
    const routes = [
        {
            name: TABS.QUESTION_SECTION,
            render: questionsComponent,
        },
        {
            name: TABS.MESSAGE_SECTION,
            render: renderTechScript,
        },
        {
            name: TABS.RECOMMENDED_REPAIR_SECTION,
            render: renderRecomendedRepairs,
        },
    ];
    const navigateBackHandler = () => {
        if (tab === TABS.MESSAGE_SECTION) {
            handleSetTab(TABS.QUESTION_SECTION);
        }
        else if (tab === TABS.RECOMMENDED_REPAIR_SECTION) {
            handleSetTab(TABS.QUESTION_SECTION);
        }
        else {
            closeDrawerHandler();
        }
    };
    return (<AddRepairOrderDrawer open={drawerOpened} onClose={closeDrawerHandler} onNavigateBack={navigateBackHandler} header="AutoNation Mobile Service Warranty" subheader={null} footer={<SimpleDrawerFooter>
          <FooterActionButton disabled={getIsContinueDisabled()} onClick={getOnClickNext()}>
            {getOnNextCta()}
          </FooterActionButton>
        </SimpleDrawerFooter>}>
      <Slider currentSlide={tab} slides={routes}/>
    </AddRepairOrderDrawer>);
};
export default WarrantyDecisionsDrawer;
