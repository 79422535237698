var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectWorkflowByReferenceNumber } from 'src/AdminApp/modules/workflows/selectors';
import { selectConsumerMethodsByConsumerId } from 'src/AdminApp/modules/payments/selectors';
import { completeTechnicianWorkflowOnSiteSingleTask } from 'src/AdminApp/modules/workflows/actions';
import { getWorkOrderPayments } from 'src/AdminApp/modules/requests/actions';
import { loadPaymentMethodsByConsumer } from 'src/AdminApp/modules/payments/actions';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
var Steps;
(function (Steps) {
    Steps[Steps["LOADING_SCREEN"] = 0] = "LOADING_SCREEN";
    Steps[Steps["HEY_TECH"] = 1] = "HEY_TECH";
    Steps[Steps["AUTH_SCRIPT"] = 2] = "AUTH_SCRIPT";
    Steps[Steps["AUTH_MODAL"] = 3] = "AUTH_MODAL";
    Steps[Steps["AUTH_SUCCESS"] = 4] = "AUTH_SUCCESS";
    Steps[Steps["AUTH_FAIL"] = 5] = "AUTH_FAIL";
    Steps[Steps["VIEW_CC"] = 6] = "VIEW_CC";
    Steps[Steps["AUTH_ALTERNATIVE"] = 7] = "AUTH_ALTERNATIVE";
    Steps[Steps["AFFIRM_CHECKOUT"] = 8] = "AFFIRM_CHECKOUT";
    Steps[Steps["CANCEL_CONFIRM"] = 9] = "CANCEL_CONFIRM";
    Steps[Steps["SERVICE_ERROR"] = 10] = "SERVICE_ERROR";
    Steps[Steps["AFFIRM_CHECKOUT_FAIL"] = 11] = "AFFIRM_CHECKOUT_FAIL";
})(Steps || (Steps = {}));
const AUTHORIZATION_STEPS = {
    [Steps.LOADING_SCREEN]: {
        back: 'close',
    },
    [Steps.HEY_TECH]: {
        next: Steps.AUTH_SCRIPT,
        back: 'close',
    },
    [Steps.AUTH_SCRIPT]: {
        next: Steps.AUTH_MODAL,
        back: Steps.HEY_TECH,
    },
    [Steps.AUTH_MODAL]: {
        next: Steps.AUTH_SUCCESS,
        back: Steps.AUTH_SCRIPT,
    },
    [Steps.AUTH_ALTERNATIVE]: {
        back: Steps.AUTH_FAIL,
    },
    [Steps.AUTH_SUCCESS]: {
        back: 'close',
    },
    [Steps.AUTH_FAIL]: {
        back: 'close',
    },
    [Steps.SERVICE_ERROR]: {
        back: 'close',
    },
    [Steps.VIEW_CC]: {
        back: Steps.AUTH_FAIL,
    },
    [Steps.AFFIRM_CHECKOUT]: {
        back: Steps.VIEW_CC,
    },
    [Steps.CANCEL_CONFIRM]: {},
    [Steps.AFFIRM_CHECKOUT_FAIL]: {
        back: Steps.AFFIRM_CHECKOUT,
    },
};
const useAuthorizationScript = ({ open, state, consumerId, onClose, completeTask, onCancelReschedule, currentTask, preAuthorizationRequested, workOrderId, }) => {
    var _a;
    const dispatch = useDispatch();
    const { referenceNum } = useParams();
    const workflow = useSelector(selectWorkflowByReferenceNumber(referenceNum));
    const paymentMethods = useSelector(selectConsumerMethodsByConsumerId(consumerId));
    const [currentStep, setCurrentStep] = useState();
    const [finalStep, setFinalStep] = useState();
    const [prevStep, setPrevStep] = useState();
    const [loading, setLoading] = useState(true);
    const authorizationTask = (_a = workflow === null || workflow === void 0 ? void 0 : workflow.workflowTasks) === null || _a === void 0 ? void 0 : _a.filter((t) => t.state === 'REPAIRS_AUTHORIZED' && t.status === 'PENDING')[0];
    const [paymentMethodFetched, setPaymentMethodFetched] = useState(false);
    const [paymentData, setPaymentData] = useState({
        id: null,
        type: null,
    });
    const [authMethod, setAuthMethod] = useState('');
    const [authorizedBy, setAuthorizedBy] = useState('');
    const [paymentMethodId, setPaymentMethodId] = useState();
    const requestAuthorization = (preAuthorizationRequested, paymentMethodId, skipReason, skipSpecificReason, userSkip) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            return yield dispatch(completeTechnicianWorkflowOnSiteSingleTask(referenceNum, Object.assign(Object.assign({}, authorizationTask), { paymentMethodId,
                preAuthorizationRequested, paymentMethodAuthorized: false, skipReason,
                skipSpecificReason, toolsUserSkip: userSkip !== null && userSkip !== void 0 ? userSkip : false })));
        }
        catch (error) {
            return error;
        }
    });
    const currentState = state;
    const changeStep = (stepName) => {
        setCurrentStep(AUTHORIZATION_STEPS[stepName]);
    };
    const handleNextStep = () => {
        changeStep(currentStep.next);
    };
    const goToAuthModal = () => {
        changeStep(Steps.AUTH_MODAL);
    };
    const goToSuccess = () => {
        setFinalStep(Steps.AUTH_SUCCESS);
    };
    const goToAuthAlternative = () => {
        changeStep(Steps.AUTH_ALTERNATIVE);
    };
    const goToFail = (userSkip) => {
        dispatch(getWorkOrderPayments(workOrderId));
        if (!userSkip) {
            changeStep(Steps.AUTH_FAIL);
        }
        else {
            changeStep(Steps.AUTH_ALTERNATIVE);
        }
    };
    const goToServiceError = (paymentMethodId) => {
        setPaymentMethodId(paymentMethodId);
        changeStep(Steps.SERVICE_ERROR);
    };
    const handleClose = () => {
        setCurrentStep(null);
        setFinalStep(null);
        onClose();
    };
    const handleFail = (method, authorizedBy, userSkip) => {
        setAuthMethod(method);
        setAuthorizedBy(authorizedBy);
        goToFail(userSkip);
    };
    const handleViewCCFail = () => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(loadPaymentMethodsByConsumer(consumerId, referenceNum));
        goToFail();
    });
    const handleSkip = (method, authorizedBy, userSkip) => {
        if (userSkip) {
            setAuthMethod(method);
            setAuthorizedBy(authorizedBy);
            goToAuthAlternative();
        }
        else {
            completeTask(method, authorizedBy);
            handleClose();
        }
    };
    const handleCancelReschedule = () => {
        analyticsTrackEvent('Authorize Cancel/Reschedule Appointment', {
            Task: currentTask,
        });
        onCancelReschedule();
    };
    const handleConfirmCancelReschedule = () => {
        setPrevStep(currentStep);
        changeStep(Steps.CANCEL_CONFIRM);
    };
    const goToViewCC = () => {
        changeStep(Steps.VIEW_CC);
    };
    const handleCompleteTaskAlternative = () => {
        completeTask(authMethod, authorizedBy);
        handleClose();
    };
    const handleSuccessReAuthorization = () => {
        completeTask(authMethod, authorizedBy);
        goToSuccess();
    };
    const handleSuccess = (method, authorizedBy) => {
        completeTask(method, authorizedBy);
        goToSuccess();
    };
    const handleBackStep = () => {
        if (prevStep) {
            setCurrentStep(prevStep);
            setPrevStep(undefined);
            return;
        }
        if (AUTHORIZATION_STEPS[currentStep.back]) {
            setCurrentStep(AUTHORIZATION_STEPS[currentStep.back]);
        }
        else {
            setCurrentStep(Steps.HEY_TECH);
        }
    };
    useEffect(() => {
        if (!paymentMethods || paymentMethods.length === 0) {
            const getPaymentMethods = () => __awaiter(void 0, void 0, void 0, function* () {
                yield dispatch(loadPaymentMethodsByConsumer(consumerId, referenceNum));
                setPaymentMethodFetched(true);
            });
            getPaymentMethods();
        }
        else {
            setPaymentMethodFetched(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!open) {
            return;
        }
        if (paymentData.id !== null && paymentData.type !== null) {
            setLoading(false);
        }
    }, [paymentData, open]);
    useEffect(() => {
        if (!open) {
            return;
        }
        if (paymentMethodFetched) {
            if (paymentMethods && paymentMethods.length > 0) {
                const affirmIndex = paymentMethods.findIndex((m) => m.methodType === 'AFFIRM');
                if (affirmIndex === -1) {
                    const defaultMethod = paymentMethods.filter((m) => m.defaultMethod);
                    if (defaultMethod.length > 0) {
                        setPaymentData({
                            id: defaultMethod[0].id,
                            type: defaultMethod[0].methodType,
                        });
                    }
                    else {
                        setPaymentData({
                            id: paymentMethods[0].id,
                            type: paymentMethods[0].methodType,
                        });
                    }
                }
                else {
                    setPaymentData({
                        id: paymentMethods[affirmIndex].id,
                        type: 'AFFIRM',
                    });
                }
            }
            else {
                setPaymentData({ id: '', type: '' });
            }
        }
    }, [paymentMethods, paymentMethodFetched, open]);
    useEffect(() => {
        if (!open) {
            return;
        }
        if (loading) {
            changeStep(Steps.LOADING_SCREEN);
        }
        else if (finalStep) {
            changeStep(finalStep);
        }
        else if (!preAuthorizationRequested ||
            currentState === 'REPAIR_IN_PROGRESS' ||
            paymentData.type === 'AFFIRM') {
            changeStep(Steps.AUTH_MODAL);
        }
        else {
            changeStep(Steps.HEY_TECH);
        }
    }, [
        open,
        preAuthorizationRequested,
        currentState,
        loading,
        paymentData.type,
        finalStep,
    ]);
    return {
        referenceNum,
        currentStep,
        setCurrentStep,
        finalStep,
        setFinalStep,
        prevStep,
        setPrevStep,
        loading,
        setLoading,
        authorizationTask,
        paymentMethodFetched,
        setPaymentMethodFetched,
        paymentData,
        setPaymentData,
        authMethod,
        setAuthMethod,
        authorizedBy,
        setAuthorizedBy,
        paymentMethodId,
        setPaymentMethodId,
        workflow,
        requestAuthorization,
        currentState,
        paymentMethods,
        changeStep,
        handleNextStep,
        goToAuthModal,
        goToSuccess,
        goToAuthAlternative,
        goToFail,
        goToServiceError,
        handleClose,
        handleFail,
        handleViewCCFail,
        handleSkip,
        handleCancelReschedule,
        handleConfirmCancelReschedule,
        goToViewCC,
        handleCompleteTaskAlternative,
        handleSuccessReAuthorization,
        handleSuccess,
        handleBackStep,
        AUTHORIZATION_STEPS,
    };
};
export default useAuthorizationScript;
