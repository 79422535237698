import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableCell, TableBody, TableRow, } from 'src/components/mui';
import EditIcon from 'src/components/icons/EditIcon';
import DeleteIcon from 'src/components/icons/DeleteIcon';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
const SkillsDashboard = ({ skills, onEdit, onDelete, }) => {
    return (<TableContainer component={Paper}>
      <Table>
        <TableHead style={{ backgroundColor: '#eee' }}>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Applies To</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {skills.map(({ id, name, appliesTo, skeduloTagId }) => (<SimpleTableRow data={[
                id,
                name,
                appliesTo.join(', '),
                <>
                  <EditIcon onClick={() => onEdit({ id, name, appliesTo, skeduloTagId })}/>
                  <DeleteIcon onClick={() => onDelete(id)}/>
                </>,
            ]}/>))}
        </TableBody>
      </Table>
    </TableContainer>);
};
export default SkillsDashboard;
