var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { calculateLatLong, getTimezoneByMapBox, } from 'src/clients/mapboxClient';
export const normalizeAddress = (address) => {
    if (address) {
        const street = address.streetLine1 || address.street;
        const street2 = address.streetLine2 || address.street2;
        const { city } = address;
        const { state } = address;
        const zip = address.zip || address.zipCode;
        const { timezone } = address;
        const { regionId } = address;
        let { latitude } = address;
        let { longitude } = address;
        if (address.coordinates) {
            latitude = address.coordinates.latitude;
            longitude = address.coordinates.longitude;
        }
        const normalized = {
            street,
            street2,
            latitude,
            longitude,
            city,
            state,
            zip,
            timezone,
            regionId,
        };
        return normalized;
    }
    return null;
};
export const calculateTimezone = (address) => __awaiter(void 0, void 0, void 0, function* () {
    if (address.timezone)
        return address;
    const geocode = yield calculateLatLong({
        zip: address.zip,
        address,
    });
    const timezone = yield getTimezoneByMapBox(geocode.latitude, geocode.longitude);
    address.latitude = geocode.latitude;
    address.longitude = geocode.longitude;
    address.timezone = timezone;
    return address;
});
export const getJobDurationHours = ({ totalJobDurationMinutes, totalLaborDuration, }) => {
    let totalJobDurationHours;
    if (totalJobDurationMinutes) {
        totalJobDurationHours = (totalJobDurationMinutes || 60) / 60;
    }
    else {
        totalJobDurationHours = totalLaborDuration;
    }
    return totalJobDurationHours;
};
