import CONFIG from 'src/config';
import { PrefixHandler } from 'src/utils/url-utils';
const API_URLS = {
    ADMIN: {
        DISTRIBUTORS: () => '/pricing-service/admin/part-distributors',
        DISTRIBUTOR_BY_ID: (id) => `/pricing-service/admin/part-distributors/${id}`,
        LOCATIONS_BY_DISTRIBUTOR: (id) => `/pricing-service/admin/part-distributors/${id}/locations`,
        DISTRIBUTOR_LOCATIONS: () => '/pricing-service/admin/part-distributor-locations',
        DISTRIBUTOR_LOCATIONS_BY_ID: (id) => `/pricing-service/admin/part-distributor-locations/${id}`,
        DISTRIBUTOR_HOURS_OF_OPERATION: (id) => `/pricing-service/admin/hours-of-operation/distributor/${id}`,
        DISTRIBUTOR_LOCATION_HOURS_OF_OPERATION: (locationId) => `/pricing-service/admin/hours-of-operation/location/${locationId}`,
    },
    INVENTORY: {
        RETURN_ITEMS: () => '/repair-service/admin/inventory/return-items',
        RETURN_ITEM_BY_ID: (id) => `/repair-service/admin/inventory/return-items/${id}`,
        RETURN_ITEM_LOCATIONS: (inventoryReturnItemId) => `/repair-service/admin/inventory/return-items/fetch-location-hierarchy/${inventoryReturnItemId}`,
        MULTIPLE_RETURN_ITEMS: () => '/repair-service/admin/inventory/multiple-return-items',
    },
};
const API_PROXY = new Proxy(API_URLS, PrefixHandler(CONFIG.CLIENT_API_URL));
export default Object.assign({}, API_PROXY);
