import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Grid, MenuItem, Typography } from 'src/components/mui';
import FleetSearchSelect from 'src/components/form/FleetSearchSelect';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { TextField, SwitchField } from 'src/AdminApp/components/form';
import FleetSearchByName from 'src/AdminApp/containers/fleets/FleetInfo/components/FleetSearchByName';
import AddressFormModal from 'src/AdminApp/containers/appointment/AddressFormModal';
import { MarketingSourceVisibility } from 'src/AdminApp/models/enums/MarketingSourceVisibility';
import MarketingSourceSelect from 'src/AdminApp/containers/requests/MarketingSourceSelect';
import { FleetValidationSchema } from '../utils/validations';
import useAddFleetDrawer from 'src/AdminApp/containers/fleets/AddFleetDrawer/useAddFleetDrawer';
const CheckboxRow = styled.div `
  padding: 10px 33px;
  font-weight: bold;
`;
const AddFleetDrawer = ({ open, onClose, onSubmit, parent, parentType, searchFleets, }) => {
    const { editAddressOpen, isSubmitting, openEditAddress, onAddressClose, onAddressSubmit, getAddressString, submitHandler, handleSelectFleetByName, handleSearchFleetByName, handleFleetSearchSelect, } = useAddFleetDrawer({ onSubmit });
    return (<SimpleDrawer title="Add Fleet" open={open} onClose={onClose} size="oneThird">
      <Formik initialValues={{
            hasFMC: false,
            fleetManagementCompany: {
                name: '',
                phone: '',
                email: '',
            },
            consumer: {
                address: '',
            },
            type: parentType === 'Amazon' ? 'Amazon' : undefined,
            removed: false,
        }} validationSchema={FleetValidationSchema} enableReinitialize validateOnMount onSubmit={submitHandler}>
        {({ handleSubmit, values, setFieldValue, isValid }) => (<>
            <SimpleDrawerContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {parent ? (<Typography>{parent}</Typography>) : (<FleetSearchSelect onSelect={handleFleetSearchSelect({ setFieldValue })} onSearch={searchFleets} label="Parent"/>)}
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Type" type="text" name="type" select value={values.type}>
                    <MenuItem value="Amazon">Amazon</MenuItem>
                    <MenuItem value="Commercial">Commercial</MenuItem>
                    <MenuItem value="Rental">Rental</MenuItem>
                    <MenuItem value="Dealer">Dealer</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="name" fullWidth label="Fleet Name"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="dspName" fullWidth label="DSP Name"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="station" fullWidth label="Station"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="contactName" fullWidth label="Main Contact Name"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="phone" fullWidth label="Main Contact Phone"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="email" fullWidth label="Main Contact Email"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="dailyOpsContactName" fullWidth label="Daily Ops Contact Name"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="dailyOpsContactPhone" fullWidth label="Daily Ops Contact Phone"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="dailyOpsContactEmail" fullWidth label="Daily Ops Contact Email"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField type="text" name="consumer.address" label="Service Address" value={getAddressString(values.consumer.address)} onChange={() => { }} onClick={openEditAddress}/>

                  <AddressFormModal title="Edit Service Address" open={editAddressOpen} address={getAddressString(values.consumer.address)} onSubmit={(value) => onAddressSubmit(value, setFieldValue)} onClose={onAddressClose}/>
                </Grid>

                <Grid item xs={12}>
                  <MarketingSourceSelect onChange={(v) => setFieldValue('marketingSourceId', v.target.value)} name="marketingSourceId" label="Marketing Source" visibility={MarketingSourceVisibility.ADMIN} fullWidth formField={false}/>
                </Grid>

                <CheckboxRow>
                  Fleet Management Company
                  <SwitchField name="hasFMC"/>
                </CheckboxRow>

                <Grid item xs={12}>
                  <FleetSearchByName autoFocus={false} disabled={!values.hasFMC} name="fleetManagementCompany.name" label="FMC Name" onSelect={handleSelectFleetByName({ setFieldValue })} onSearch={handleSearchFleetByName} initialValue=""/>
                </Grid>

                <Grid item xs={12}>
                  <TextField disabled={!values.hasFMC} name="fleetManagementCompany.contactName" label="FMC Contact" fullWidth/>
                </Grid>

                <Grid item xs={12}>
                  <TextField disabled={!values.hasFMC} name="fleetManagementCompany.phone" label="Phone" fullWidth/>
                </Grid>

                <Grid item xs={12}>
                  <TextField disabled={!values.hasFMC} name="fleetManagementCompany.email" label="Email" fullWidth/>
                </Grid>
              </Grid>
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <FooterActionButton onClick={handleSubmit} disabled={!isValid || isSubmitting} isSubmitting={isSubmitting}>
                Save
              </FooterActionButton>
            </SimpleDrawerFooter>
          </>)}
      </Formik>
    </SimpleDrawer>);
};
export default AddFleetDrawer;
