import React from 'react';
import CONFIG from 'src/config';
import { canceledStates } from 'src/AdminApp/models/enums/RepairRequestState';
import { ProgressBarPadding, DotOverlay, ProgressBarWrapper, ProgressBarFill, ProgressTypography, ProgressLabelWrapper, LabelWrapper, IconWrapper, LevelIcon, } from './ProgressBar.styles';
import VisitStopwatch from '../stopwatch/VisitStopwatch/VisitStopwatch';
import NonVisitStopwatch from '../stopwatch/NonVisitStopwatch/NonVisitStopwatch';
const icons = ['star', 'event_available', 'build', 'verified_user'];
const ProgressLabel = ({ children }) => {
    return (<ProgressLabelWrapper>
      <ProgressTypography variant="subtitle1">{children}</ProgressTypography>
    </ProgressLabelWrapper>);
};
const mapStepOnType = (value, step, count) => {
    if (value + 1 === count) {
        return 'last';
    }
    if (value < step) {
        return 'future';
    }
    if (value === step) {
        return 'current';
    }
    return 'past';
};
const barProgress = (state) => {
    let progress;
    let step;
    switch (state) {
        case 'NEW_REQUEST':
        case 'OPEN_REQUEST':
            progress = 0;
            step = 0;
            break;
        case 'CONFIRMED_APPOINTMENT':
            progress = 33.4;
            step = 1;
            break;
        case 'REPAIR_IN_PROGRESS':
            progress = 66.67;
            step = 2;
            break;
        case 'CLOSED_REPAIR':
        case 'CLOSED_REPAIR_COMPLETION_UNKNOWN':
        case 'CANCELED_REQUEST':
        case 'CANCELED_APPOINTMENT_CUSTOMER':
        case 'CANCELED_APPOINTMENT_NO_CONFIRMED_ARRIVAL':
        case 'INVALID':
        default:
            progress = 100;
            step = 3;
            break;
    }
    return { progress, step };
};
const ProgressBar = ({ state, visit, isMobile, activeAppointment, currentTask, referenceNum, }) => {
    const progressInfo = barProgress(state);
    const shouldRenderStopwatch = !isMobile &&
        progressInfo.step === 2 &&
        (CONFIG.ENABLE_STOPWATCH_DISPLAY === 'true' ||
            CONFIG.ENABLE_VISITS_STOPWATCH_DISPLAY === 'true');
    const renderProgressBarIcons = (isVisit, icon) => {
        if (icon !== 'build' || (icon === 'build' && !shouldRenderStopwatch)) {
            return <LevelIcon>{icon}</LevelIcon>;
        }
        return isVisit ? (<VisitStopwatch visit={visit}/>) : (<NonVisitStopwatch activeAppointment={activeAppointment} currentTask={currentTask} referenceNum={referenceNum}/>);
    };
    return (<ProgressBarPadding>
      <ProgressBarWrapper>
        <DotOverlay>
          {icons.map((icon, index) => (<IconWrapper key={icon} type={mapStepOnType(progressInfo.step, index, icons.length)}>
              {renderProgressBarIcons(!!visit, icon)}
            </IconWrapper>))}
        </DotOverlay>
        <ProgressBarFill width={progressInfo.progress} $isCanceled={canceledStates.includes(state)}/>
      </ProgressBarWrapper>

      <LabelWrapper container style={{ justifyContent: 'space-between' }}>
        <ProgressLabel>OPEN</ProgressLabel>
        <ProgressLabel>CONFIRMED</ProgressLabel>
        <ProgressLabel>IN PROGRESS</ProgressLabel>
        <ProgressLabel>CLOSED</ProgressLabel>
      </LabelWrapper>
    </ProgressBarPadding>);
};
export default ProgressBar;
