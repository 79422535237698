/* eslint-disable react/no-unused-prop-types */
import { MenuItem } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import { TextField } from 'src/AdminApp/components/form';
import { SimpleModal } from 'src/components/layout';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { CheckboxRow, Row } from './AdditionalCancelationReasons.styles';
import NoShowDrawer from './NoShowDrawer';
import { cancellationReasonsSchema, initValues, strValues } from './utils';
import useAdditionalCancelationReasons from './useAdditionalCancelationReasons';
const noPaymentMethods = 'There are no valid payment methods tied to this customer';
const AdditionalCancelationReasons = ({ consumerId, isFleet = false, onClose, open, submit, workOrderId, visitReferenceNum, repairRequestId, expiredTimer = false, reassignVanFunction, onlyCancellation, referenceNum, }) => {
    const { formRef, openRescheduleModal, setOpenRescheduleModal, openNoShowDrawer, isSubmitting, resolutions, isProductEngViewer, isWithinMaxCancelHours, isAppointmentConfirmed, hasPaymentMethods, checkedCancellationFee, handleTopReasonChange, handleReasonChange, handleContinue, handleNoShowSubmit, handleReschedule, topReasonVisible, getReasonId, } = useAdditionalCancelationReasons({
        repairRequestId,
        referenceNum,
        open,
        consumerId,
        isFleet,
        reassignVanFunction,
        workOrderId,
        visitReferenceNum,
        onClose,
        onlyCancellation,
        submit,
    });
    const CheckboxWrap = !hasPaymentMethods ? Tooltip : Fragment;
    return (<>
      <SimpleDrawer open={open} onClose={onClose} disableEnforceFocus title={visitReferenceNum ? 'Remove RO' : 'Cancel Appointment'}>
        <Formik initialValues={Object.assign(Object.assign({}, initValues), { id: expiredTimer ? getReasonId() : '', topReason: expiredTimer ? 'CUSTOMER' : '', reason: expiredTimer ? 'Cancelled on arrival / No Show' : '', cancellationFee: checkedCancellationFee })} innerRef={formRef} validationSchema={cancellationReasonsSchema} onSubmit={handleContinue} enableReinitialize>
          {({ isValid, isValidating, submitForm, values }) => {
            var _a;
            return (<>
                <SimpleDrawerContent>
                  <Row>
                    <TextField select label={visitReferenceNum
                    ? 'Removal Reason'
                    : 'Cancelation Issue'} name="topReason" variant="outlined" onChange={handleTopReasonChange}>
                      <MenuItem disabled value="">
                        {visitReferenceNum
                    ? 'Removal Reason'
                    : 'Cancelation Issue'}
                      </MenuItem>
                      {resolutions &&
                    Array.from(resolutions.keys()).map((key) => (<MenuItem key={key} value={key} style={{ whiteSpace: 'normal' }}>
                            {strValues[key]}
                          </MenuItem>))}
                    </TextField>
                  </Row>
                  {(values === null || values === void 0 ? void 0 : values.topReason) !== '' && (<Row>
                      <TextField select label="Reason" name="reason" variant="outlined" onChange={handleReasonChange}>
                        <MenuItem disabled value="">
                          Reason
                        </MenuItem>
                        {resolutions &&
                        ((_a = resolutions.get(values.topReason)) === null || _a === void 0 ? void 0 : _a.keys()) &&
                        Array.from(resolutions.get(values.topReason).keys()).map((key) => {
                            return topReasonVisible(values.topReason, key) ? (<MenuItem key={key} value={key}>
                                {key}
                              </MenuItem>) : ('');
                        })}
                      </TextField>
                    </Row>)}
                  {(values === null || values === void 0 ? void 0 : values.hasSubReasons) && (<Row>
                      <TextField select label="Sub-reason" name="subReason" variant="outlined">
                        <MenuItem disabled value="">
                          Sub-reason
                        </MenuItem>
                        {resolutions === null || resolutions === void 0 ? void 0 : resolutions.get(values.topReason).get(values.reason).filter((res) => res.visibility.includes('CANCEL') &&
                        ((isProductEngViewer &&
                            res.visibility.includes('PROD-ENG')) ||
                            !res.visibility.includes('PROD-ENG'))).map(({ id, specificReason, }) => (<MenuItem key={id} value={reassignVanFunction ? specificReason : id}>
                                {specificReason}
                              </MenuItem>))}
                      </TextField>
                    </Row>)}
                  {(values === null || values === void 0 ? void 0 : values.hasDescription) && (<Row>
                      <TextField label="Description" name="description" variant="outlined"/>
                    </Row>)}
                  {isAppointmentConfirmed &&
                    isWithinMaxCancelHours &&
                    !isFleet &&
                    values.topReason.toLowerCase() === 'customer' && (<Row>
                        <CheckboxWrap placement="bottom" title={noPaymentMethods}>
                          <CheckboxRow>
                            <CheckboxField color="primary" disabled={!hasPaymentMethods} label="Charge Cancel Fee" name="cancellationFee"/>
                          </CheckboxRow>
                        </CheckboxWrap>
                      </Row>)}
                </SimpleDrawerContent>
                <SimpleDrawerFooter>
                  <FooterActionButton disabled={!isValid || isValidating || isSubmitting} onClick={submitForm} isSubmitting={isSubmitting}>
                    Continue
                  </FooterActionButton>
                </SimpleDrawerFooter>
                <SimpleModal open={openRescheduleModal} onClose={() => {
                    setOpenRescheduleModal(false);
                }} title="Would you like to Reschedule this appointment?" simpleTwoButtonRowProps={{
                    onSubmit: handleReschedule,
                    onCancel: onClose,
                    submitText: 'Yes',
                    cancelText: 'No',
                }}/>
              </>);
        }}
        </Formik>
      </SimpleDrawer>
      <NoShowDrawer repairRequestId={repairRequestId} open={openNoShowDrawer} onSubmit={handleNoShowSubmit}/>
    </>);
};
export default AdditionalCancelationReasons;
