import { Formik } from 'formik';
import { capitalize } from 'lodash';
import React from 'react';
import { SimpleModal } from 'src/components/layout';
import { Grid, TimePicker, Typography } from 'src/components/mui';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SubHeader, } from 'src/components/SimpleDrawer';
import useDistributorHoursOfOperationDrawer, { WEEKDAYS, } from 'src/PopsApp/pages/Distributors/DistributorHoursOfOperationDrawer/useDistributorHoursOfOperationDrawer';
import { getMomentValueOr8am, getTimeString } from 'src/utils/formatter';
import { TIME_12H_FORMAT, TIME_FORMAT } from 'src/timeConstants';
const DistributorHoursOfOperationDrawer = ({ open, distributor, onClose, }) => {
    const { getInitialValues, openConfirmationDialog, isLoading, onHandleSubmit, setOpenConfirmationDialog, } = useDistributorHoursOfOperationDrawer({ distributor, onClose });
    return (<SimpleDrawer open={open} onClose={onClose} title="Hours of Operation" size="half" isLoading={isLoading}>
      <Formik initialValues={getInitialValues()} enableReinitialize onSubmit={onHandleSubmit}>
        {({ handleSubmit, isSubmitting, isValid, isValidating, setFieldValue, values, }) => (<>
            <SimpleDrawerContent>
              <SubHeader>{distributor === null || distributor === void 0 ? void 0 : distributor.name}</SubHeader>

              <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                <Grid item xs={4}>
                  <Typography gutterBottom>Day</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom align="center">
                    Start time
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom align="center">
                    End time
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography gutterBottom align="center">
                    Open
                  </Typography>
                </Grid>
              </Grid>
              {WEEKDAYS.map((day) => (<Grid container spacing={5} style={{ alignItems: 'center', justifyContent: 'center' }} key={day}>
                  <Grid item xs={4}>
                    <Typography>{capitalize(day.toLowerCase())}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TimePicker value={getMomentValueOr8am(values[day].openTime, TIME_FORMAT)} onChange={(val) => {
                    setFieldValue(day, Object.assign(Object.assign({}, values[day]), { openTime: getTimeString(val, TIME_FORMAT) }));
                }} minutesStep={1} format={TIME_12H_FORMAT} slotProps={{
                    textField: {
                        variant: 'standard',
                        margin: 'none',
                    },
                }}/>
                  </Grid>
                  <Grid item xs={3}>
                    <TimePicker value={getMomentValueOr8am(values[day].closeTime, TIME_FORMAT)} onChange={(val) => {
                    setFieldValue(day, Object.assign(Object.assign({}, values[day]), { closeTime: getTimeString(val, TIME_FORMAT) }));
                }} minutesStep={1} format={TIME_12H_FORMAT} slotProps={{
                    textField: {
                        variant: 'standard',
                        margin: 'none',
                    },
                }}/>
                  </Grid>
                  <Grid item xs={2} textAlign="center">
                    <LabelledCheckbox label={null} checkboxProps={{
                    name: day,
                    checked: values[day].open,
                    onChange: (e) => {
                        setFieldValue(day, Object.assign(Object.assign({}, values[day]), { open: e.target.checked }));
                    },
                }}/>
                  </Grid>
                </Grid>))}
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <FooterActionButton disabled={!isValid || isValidating || isSubmitting || isLoading} onClick={() => setOpenConfirmationDialog(true)} isSubmitting={isSubmitting}>
                Save
              </FooterActionButton>
            </SimpleDrawerFooter>
            <SimpleModal open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)} title="Are you sure you want to change the hours of operations for all of
              this distributors locations?" simpleTwoButtonRowProps={{
                onCancel: () => setOpenConfirmationDialog(false),
                onSubmit: () => {
                    setOpenConfirmationDialog(false);
                    handleSubmit();
                },
                submitText: 'Yes',
            }}/>
          </>)}
      </Formik>
    </SimpleDrawer>);
};
export default DistributorHoursOfOperationDrawer;
