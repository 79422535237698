var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { ConfirmationModal } from 'src/components/layout';
import { Container, DeleteButton, DownloadButton, Picture, } from './Image.styles';
const Image = ({ alt, source, onDelete }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const changeDialogOpen = (state) => () => {
        setOpenDialog(state);
    };
    const handleDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        setOpenDialog(false);
        yield onDelete();
        setSubmitting(false);
    });
    return (<>
      <Container>
        <Picture alt={alt} src={source}/>
        <DeleteButton onClick={changeDialogOpen(true)}/>
        <a href={source} download>
          <DownloadButton />
        </a>
      </Container>
      <ConfirmationModal open={openDialog} text="Would you like to delete the asset?" onClose={changeDialogOpen(false)} action={handleDelete} disabledSubmit={submitting}/>
    </>);
};
export default Image;
