import { createContext } from 'react';
import PriceInput from 'src/AdminApp/components/form/PriceInput';
import { boldInputProps } from 'src/styles/styled';
const responsiblePartyType = {
    autModalOpen: false,
    handleCloseAuthModal: undefined,
    handleClosePayerModal: undefined,
    handleOpenAuthModal: undefined,
    handleOpenPayerModal: undefined,
    payerModalOpen: false,
};
const responsiblePartyTypeContext = createContext(responsiblePartyType);
const responsiblePartyTypeReducer = (state, { payload, type }) => {
    switch (type) {
        case 'CLOSE_AUTH_MODAL':
        case 'OPEN_AUTH_MODAL': {
            return Object.assign(Object.assign({}, state), { autModalOpen: payload });
        }
        case 'CLOSE_PAYER_MODAL':
        case 'OPEN_PAYER_MODAL': {
            return Object.assign(Object.assign({}, state), { payerModalOpen: payload });
        }
        default:
            throw new Error('Invalid dispatch');
    }
};
const priceInputProps = {
    InputProps: {
        inputComponent: PriceInput,
        inputProps: Object.assign(Object.assign({}, boldInputProps), { prefix: '$' }),
    },
    inputProps: boldInputProps,
};
export var ResponsiblePartyType;
(function (ResponsiblePartyType) {
    ResponsiblePartyType["CUSTOMER"] = "Customer Pay";
    ResponsiblePartyType["INTERNAL"] = "Internal Pay";
    ResponsiblePartyType["THIRD_PARTY"] = "3rd Party Pay";
    ResponsiblePartyType["AUTONATION"] = "AutoNation Pay";
})(ResponsiblePartyType || (ResponsiblePartyType = {}));
export { priceInputProps, responsiblePartyTypeContext, responsiblePartyTypeReducer, responsiblePartyType, };
