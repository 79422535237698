import { useState, useEffect } from 'react';
const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const listenScrollEvent = () => {
        setScrollPosition(window.scrollY);
    };
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () => window.removeEventListener('scroll', listenScrollEvent);
    }, []);
    return [scrollPosition];
};
export default useScrollPosition;
