import React from 'react';
import { RepairOrders, RoLabel, RoValue, } from 'src/FleetVisitsApp/pages/VisitView/AddExistingRepairOrdersDrawer/RepairOrdersTable/RepairOrdersTable.styles';
import classNames from 'classnames';
import { GetRepairsForDisplay } from 'src/FleetVisitsApp/utils/GetRepairsForDisplay';
import Services from 'src/FleetVisitsApp/pages/VisitView/Services';
import { RoStatusChip } from 'src/FleetVisitsApp/pages/VisitView/StatusChips';
import moment from 'moment';
import { STATIC_URL_BASE } from 'src/styles/theme';
import { LOCALIZED_DATE_LONGER_FORMAT } from 'src/timeConstants';
import { SelectButton } from '../AddRepairOrdersDrawer.styles';
const RepairOrdersTable = ({ repairOrders, addRepairOrder, selectedSet, isRoReadyToAdd, }) => {
    return (<RepairOrders>
      <tbody>
        {repairOrders.map((ro) => {
            var _a;
            let action = 'Pending';
            if (ro.totalCost) {
                action = selectedSet.has(ro.workOrderId) ? 'Remove' : 'Add';
            }
            const { repairs, extraRepairsCount, allRepairs } = GetRepairsForDisplay({
                services: ro.diagnosisServices,
            });
            const maxLeadTime = ((_a = ro.diagnosisServices) === null || _a === void 0 ? void 0 : _a.maxLeadTime) || 0;
            const roDate = new Date();
            roDate.setDate(roDate.getDate() + maxLeadTime);
            const isAddable = isRoReadyToAdd(roDate);
            return (<tr key={ro.repairRequest.id} className={classNames({
                    added: action.toUpperCase() === 'REMOVE',
                })}>
              <td>
                <RoLabel>Name</RoLabel>
                <RoValue>{ro.fleetName}</RoValue>
              </td>
              <td>
                <RoLabel>Ref #</RoLabel>
                <RoValue>
                  <a href={`/admin/repairs/${ro.repairRequest.referenceNum}`} target="_blank" rel="noreferrer noopener">
                    {ro.repairRequest.referenceNum}
                  </a>
                </RoValue>
              </td>
              <td>
                <RoLabel>Creation Date</RoLabel>
                <RoValue>
                  {moment(ro.creationDate).format(LOCALIZED_DATE_LONGER_FORMAT)}
                </RoValue>
              </td>
              <td>
                <RoLabel>Status</RoLabel>
                <RoStatusChip repairOrderStatus={ro.repairRequest.status}/>
              </td>
              <td>
                <RoLabel>VIN</RoLabel>
                <RoValue>{ro.vin}</RoValue>
              </td>
              <td>
                <RoLabel>Repairs</RoLabel>
                <Services allRepairs={allRepairs !== null && allRepairs !== void 0 ? allRepairs : []} extraRepairsCount={extraRepairsCount !== null && extraRepairsCount !== void 0 ? extraRepairsCount : 0} repairs={repairs !== null && repairs !== void 0 ? repairs : []}/>
              </td>
              <td>
                <RoLabel>Earliest Avail Booking</RoLabel>
                <RoValue>
                  {moment(roDate.toISOString()).format(LOCALIZED_DATE_LONGER_FORMAT)}{' '}
                  {!isAddable && (<img alt="special" src={`${STATIC_URL_BASE}img/svg/special-order-label.svg`}/>)}
                </RoValue>
              </td>
              <td>
                <SelectButton color="primary" variant={action === 'Pending' ? 'contained' : 'outlined'} disabled={action === 'Pending'} roaction={action.toUpperCase()} onClick={() => {
                    addRepairOrder(isAddable, ro, action.toUpperCase());
                }}>
                  {action}
                </SelectButton>
              </td>
            </tr>);
        })}
      </tbody>
    </RepairOrders>);
};
export default RepairOrdersTable;
