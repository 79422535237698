import React from 'react';
import { Grid, Typography } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { PaymentDescription, PaymentStatus } from 'src/AdminApp/models/enums';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import { formatTimestamp } from 'src/utils/formatter';
import CurrencyLabel from 'src/components/CurrencyLabel';
import PaymentDescriptionChip from 'src/AdminApp/containers/payments/PaymentModule/PaymentDescriptionChip';
import DropdownOptions from 'src/AdminApp/containers/payments/PaymentModule/DropdownOptions';
import { BoldText, PaymentAttribute, PodBottomRow, PodTopRow, PodWrapper, SecondaryDateInfo, TextRightContent, } from 'src/AdminApp/containers/payments/PaymentModule/PaymentsTable/styledComponents';
import { useSelector } from 'react-redux';
import { selectPaymentRefundSubmitter } from 'src/modules/auth/selectors';
import { InfoOutlined as InfoOutlinedIcon } from 'src/components/mui/icons';
const splitTimestamp = (timestamp) => {
    const date = timestamp.split(' ').slice(0, 3).join(' ');
    const time = timestamp.split(' ').slice(3, 6).join(' ');
    return { date, time };
};
const PaymentPod = ({ payment, onRemovePayment }) => {
    var _a;
    const { id, paymentMethodId, amount, collectedBy, status, description, referenceNumber = null, type, subType, created, removed, refundReason, errorMessage, } = payment;
    const charged = [
        PaymentDescription.PARTIAL_PAYMENT_BY_SPREEDLY,
        PaymentDescription.FULL_PAYMENT_BY_SPREEDLY,
        PaymentDescription.DECLINED,
        PaymentDescription.FAILED,
    ].includes(description);
    const { date, time } = splitTimestamp(formatTimestamp(created));
    const isFailedStatus = PaymentStatus.FAILED === status;
    const isRefundable = Boolean(paymentMethodId) && type.id === 2;
    const isRefundDescription = description === null || description === void 0 ? void 0 : description.includes('REFUND');
    const isRefundedStatus = PaymentStatus.REFUNDED === status || isRefundDescription;
    const isPreAuthorization = description === null || description === void 0 ? void 0 : description.includes('PRE_AUTHORIZATION');
    const handleRemovePayment = (paymentId) => {
        onRemovePayment(paymentId, amount, isRefundable);
    };
    const isPaymentRefundSubmitter = useSelector(selectPaymentRefundSubmitter);
    const canRefund = isPaymentRefundSubmitter &&
        !isRefundedStatus &&
        !removed &&
        isRefundable &&
        !charged &&
        !isFailedStatus &&
        !isPreAuthorization;
    const canDelete = !removed &&
        !isRefundedStatus &&
        !isFailedStatus &&
        !isRefundable &&
        !charged &&
        !isPreAuthorization;
    const isVoided = (_a = payment.transactions) === null || _a === void 0 ? void 0 : _a.find((transaction) => transaction.type === 'VOID' && transaction.state === 'SUCCEEDED');
    return (<PodWrapper>
      <PodTopRow container direction="row">
        <Grid item xs={6}>
          <PaymentAttribute removed={removed}>
            <BoldText>{date}</BoldText>
          </PaymentAttribute>
          <PaymentAttribute removed={removed}>
            <SecondaryDateInfo>{time}</SecondaryDateInfo>
          </PaymentAttribute>
          <PaymentAttribute removed={removed}>
            <SecondaryDateInfo>
              {isRefundDescription ? 'Refunded' : 'Collected'} By:{' '}
              <AdminUser subText={false} updatedUser={collectedBy}/>
            </SecondaryDateInfo>
          </PaymentAttribute>
        </Grid>
        <Grid item xs={6}>
          <TextRightContent>
            <TextRightContent>
              <PaymentAttribute removed={removed}>
                <BoldText>
                  <CurrencyLabel>{amount}</CurrencyLabel>
                </BoldText>
              </PaymentAttribute>
            </TextRightContent>
            <TextRightContent>
              <PaymentDescriptionChip removed={removed} status={status} description={isVoided && !description.includes('PRE_AUTHORIZATION')
            ? 'VOID'
            : description}/>
              {refundReason && (<Tooltip title={refundReason} style={{ fontSize: '20px' }} placement="left">
                  <InfoOutlinedIcon style={{
                verticalAlign: 'top',
                fontSize: '1.2rem',
                cursor: 'pointer',
            }}/>
                </Tooltip>)}
              {errorMessage && (<div style={{
                display: 'flex',
                verticalAlign: 'center',
                marginTop: '8px',
            }}>
                  <PaymentAttribute removed={removed} align="right">
                    {errorMessage}
                  </PaymentAttribute>
                </div>)}
            </TextRightContent>
          </TextRightContent>
        </Grid>
      </PodTopRow>

      <PodBottomRow container direction="row">
        <Grid item xs={6}>
          <Typography variant="subtitle1">Method</Typography>
          <PaymentAttribute removed={removed} variant="body1">
            {type &&
            `${type.displayName}${subType ? ` - ${subType.displayName}` : ''}`}
          </PaymentAttribute>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">Ref #</Typography>
          <PaymentAttribute removed={removed} variant="body1">
            {referenceNumber}
          </PaymentAttribute>
        </Grid>
        <Grid item xs={3}>
          <TextRightContent>
            {canRefund && (<DropdownOptions component="payment" id={id} label="Refund Payment" onRemove={handleRemovePayment}/>)}
            {canDelete && (<DropdownOptions component="payment" id={id} label="Delete Payment" onRemove={handleRemovePayment}/>)}
          </TextRightContent>
        </Grid>
      </PodBottomRow>
    </PodWrapper>);
};
export default PaymentPod;
