var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseInt } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInvoiceByWorkOrderId } from 'src/AdminApp/modules/invoices/selectors';
import { updateRepairMeasurements } from 'src/AdminApp/modules/requests/actions';
import { selectCreatedRepairs, selectRecommendedRepairs, selectRepairMeasurements, selectRepairMeasurementsData, selectRequest, selectRequestDiagnosis, selectScheduledRepairs, } from 'src/AdminApp/modules/requests/selectors';
import { completeTechnicianWorkflowOnSiteSingleTask, getTechnicianOnSiteWorkflowByRef, } from 'src/AdminApp/modules/workflows/actions';
import { selectWorkflowByReferenceNumber } from 'src/AdminApp/modules/workflows/selectors';
import { MeasurementUnit } from 'src/PricingApp/models/enums/MeasurementUnit';
import * as Yup from 'yup';
const useRecordMeasurementsDrawer = ({ onClose, open, nodeId, required, referenceNum, selectorProps, }) => {
    var _a, _b;
    const dispatch = useDispatch();
    const repairMeasurements = useSelector((state) => selectRepairMeasurements(state, selectorProps));
    const repairMeasurementsData = useSelector((state) => selectRepairMeasurementsData(state, selectorProps));
    const workflow = useSelector(selectWorkflowByReferenceNumber(referenceNum));
    const skippableMeasurementsTask = (_a = workflow === null || workflow === void 0 ? void 0 : workflow.workflowTasks) === null || _a === void 0 ? void 0 : _a.filter((t) => t.state === 'GET_MEASUREMENTS_SKIPPABLE' && t.status === 'PENDING')[0];
    const measurementsTask = (_b = workflow === null || workflow === void 0 ? void 0 : workflow.workflowTasks) === null || _b === void 0 ? void 0 : _b.find((t) => t.state === 'GET_MEASUREMENTS' && t.status === 'PENDING');
    const [repairs, setRepairs] = useState([]);
    const [currentRepairIndex, setCurrentRepairIndex] = useState(0);
    const [showSkipButton, setShowSkipButton] = useState(false);
    const diagnosis = useSelector((state) => selectRequestDiagnosis(state, selectorProps));
    const recommendedRepairs = useSelector(selectRecommendedRepairs(referenceNum));
    const scheduledRepairs = useSelector(selectScheduledRepairs(referenceNum));
    const createdRepairs = useSelector(selectCreatedRepairs(referenceNum));
    const repairRequest = useSelector((state) => selectRequest(state, selectorProps));
    const invoice = useSelector((state) => {
        var _a;
        return selectInvoiceByWorkOrderId(state, {
            workOrderId: (_a = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.workOrder) === null || _a === void 0 ? void 0 : _a.id,
        });
    });
    useEffect(() => {
        if (open) {
            setCurrentRepairIndex(0);
            setShowSkipButton(!nodeId && !required);
            const refreshedRepairs = getRepairs();
            setRepairs(refreshedRepairs);
            if (!refreshedRepairs.length) {
                onClose();
            }
            if (!nodeId &&
                !refreshedRepairs.length &&
                repairMeasurementsData._isFetching === false) {
                completeMeasurementTasks();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, nodeId, required]);
    const completeMeasurementTasks = (skippableOnly = false) => __awaiter(void 0, void 0, void 0, function* () {
        if (skippableMeasurementsTask) {
            yield dispatch(completeTechnicianWorkflowOnSiteSingleTask(referenceNum, skippableMeasurementsTask));
        }
        if (!skippableOnly && measurementsTask) {
            yield dispatch(completeTechnicianWorkflowOnSiteSingleTask(referenceNum, measurementsTask));
        }
        dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
    });
    const getRepairs = () => {
        var _a, _b;
        const knownRepairs = ((_a = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) || [];
        const recommended = recommendedRepairs || [];
        const scheduled = scheduledRepairs || [];
        const created = createdRepairs || [];
        const invoiceRepairs = ((_b = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _b === void 0 ? void 0 : _b.knownRepairs) || [];
        const allRepairs = [
            ...invoiceRepairs.map((repair) => (Object.assign(Object.assign({}, repair), { source: 'INVOICE' }))),
            ...knownRepairs.map((repair) => (Object.assign(Object.assign({}, repair), { source: 'QUOTE' }))),
            ...recommended.map((repair) => (Object.assign(Object.assign({}, repair), { source: 'RECOMMENDED REPAIR', name: repair.repair, nodeId: parseInt(repair.repairId) }))),
            ...[...scheduled, ...created].map((repair) => (Object.assign(Object.assign({}, repair), { source: 'SCHEDULED REPAIR', name: repair.repair, nodeId: parseInt(repair.repairId) }))),
        ];
        return allRepairs
            .filter((repair) => !nodeId || repair.nodeId === nodeId)
            .filter((repair) => {
            const measurements = repairMeasurements.filter((measurement) => measurement.repairs.includes(repair.nodeId));
            if (!nodeId) {
                return (measurements.find((measurement) => !measurement.optional && measurement.value === null) !== undefined);
            }
            return measurements.length > 0;
        })
            .map((repair) => ({
            nodeId: repair.nodeId,
            name: repair.name,
            source: repair.source,
            editDisabled: (nodeId &&
                repair.source === 'SCHEDULED REPAIR'),
        }))
            .filter((repair, index, mappedRepairs) => index === mappedRepairs.findIndex((r) => r.nodeId === repair.nodeId));
    };
    useEffect(() => {
        if (currentRepairIndex >= repairs.length) {
            setCurrentRepairIndex(repairs.length - 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRepairIndex, repairs]);
    const valueValidationWhen = (fieldOptional, unit) => {
        const type = unit ? Yup.number() : Yup.string();
        return !fieldOptional && required ? type.required() : type;
    };
    const groupMeasurements = (nodeId) => repairMeasurements
        .filter((measurement) => !nodeId || measurement.repairs.includes(nodeId))
        .reduce((map, measurement) => {
        if (map[measurement.description]) {
            map[measurement.description].push(measurement);
        }
        else {
            map[measurement.description] = [measurement];
        }
        return map;
    }, {});
    const initialValues = useMemo(() => {
        return {
            repairs: repairs.map((repair) => {
                const measurementMap = groupMeasurements(repair.nodeId);
                return {
                    repair_name: repair.name,
                    source: repair.source,
                    editDisabled: repair.editDisabled,
                    measurements: Object.keys(measurementMap).map((description) => {
                        return {
                            description,
                            positions: measurementMap[description].map((measurement) => {
                                var _a;
                                return {
                                    id: measurement.id,
                                    position: measurement.position,
                                    optional: measurement.optional,
                                    unit: MeasurementUnit.display(measurement.unit),
                                    value: (_a = measurement.value) !== null && _a !== void 0 ? _a : '',
                                    measurementId: measurement.measurementId,
                                    displayOrder: measurement.displayOrder,
                                };
                            }),
                        };
                    }),
                };
            }),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repairMeasurements, repairs]);
    const compareMeasurements = (a, b) => {
        const measurementComparison = a.positions[0].measurementId
            ? a.positions[0].measurementId.localeCompare(b.positions[0].measurementId)
            : 0;
        if (measurementComparison !== 0) {
            return measurementComparison;
        }
        return a.positions[0].displayOrder - b.positions[0].displayOrder;
    };
    const repairSubheader = useMemo(() => {
        if (nodeId || initialValues.repairs.length === 1) {
            return undefined;
        }
        return `Measurements (${currentRepairIndex + 1} of ${initialValues.repairs.length})`;
    }, [nodeId, initialValues, currentRepairIndex]);
    const validationSchema = Yup.object().shape({
        repair_name: Yup.string(),
        measurements: Yup.array().of(Yup.object().shape({
            description: Yup.string(),
            positions: Yup.array().of(Yup.object().shape({
                id: Yup.string(),
                position: Yup.string().nullable(),
                value: Yup.string().when(['optional', 'unit'], valueValidationWhen),
                unit: Yup.string(),
                optional: Yup.boolean(),
            })),
        })),
    });
    const handleSubmit = (values, { resetForm, setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
        let modified = false;
        const flatValues = values.measurements.flatMap((measurement) => measurement.positions);
        const updatedMeasurements = repairMeasurements.map((measurement) => {
            const formMeasurement = flatValues.find((value) => value.id === measurement.id);
            let value;
            if (formMeasurement) {
                value = formMeasurement.value === '' ? null : formMeasurement.value;
                modified = modified || measurement.value !== value;
            }
            else {
                value = measurement.value;
            }
            return Object.assign(Object.assign({}, measurement), { value });
        });
        const outstandingMeasurements = updatedMeasurements.filter((measurement) => !measurement.optional && !measurement.value);
        if (modified) {
            yield dispatch(updateRepairMeasurements(referenceNum, updatedMeasurements));
            if (outstandingMeasurements.length === 0) {
                completeMeasurementTasks();
                onClose();
                resetForm();
                return;
            }
            setTimeout(() => dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum)), 250);
        }
        const isNext = currentRepairIndex + 1 < repairs.length;
        if (isNext) {
            setCurrentRepairIndex(currentRepairIndex + 1);
            setSubmitting(false);
        }
        else {
            onClose();
            resetForm();
        }
    });
    const handleSkip = (resetForm) => __awaiter(void 0, void 0, void 0, function* () {
        completeMeasurementTasks(true);
        resetForm();
        onClose();
    });
    return {
        initialValues,
        currentRepairIndex,
        validationSchema,
        handleSubmit,
        repairSubheader,
        compareMeasurements,
        showSkipButton,
        repairs,
        handleSkip,
    };
};
export default useRecordMeasurementsDrawer;
