import React from 'react';
import { theme } from 'src/styles/theme';
import AppointmentTask from 'src/TechApp/components/AppointmentTask';
import LunchTask from 'src/TechApp/components/LunchTask';
import VisitTask from 'src/TechApp/components/VisitTask';
import { useMediaQuery } from 'src/components/mui';
import { AppointmentsListContainer, AppointmentsTable, NoAppointments, } from 'src/TechApp/components/AppointmentList/AppointmentList.styles';
import PartPickupTask from '../PartPickupTask';
const getAddress = (item) => {
    if (item.pickupAtAddress) {
        return item.pickupAtAddress;
    }
    if (item.partDistributorLocation) {
        const { city, state, street1: street, zip } = item.partDistributorLocation;
        return { city, state, street, zip };
    }
    return null;
};
const PorterTask = ({ item, techName, }) => {
    var _a;
    return (<PartPickupTask key={item.skeduloJobId} {...item} distributorName={(_a = item === null || item === void 0 ? void 0 : item.partDistributorLocation) === null || _a === void 0 ? void 0 : _a.partDistributorName} address={getAddress(item)} startLocal={item.scheduledFor} endLocal={item.scheduledEndTime} location={null} partPickupOrderId={item.id} status={item.status} techName={techName} isPorter resources={item.resources}/>);
};
const AppointmentList = ({ dashboardAppointments, techName, isPorter, }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const renderTask = (item) => {
        if (isPorter) {
            return (<PorterTask item={item} techName={techName}/>);
        }
        if (item.type.toLowerCase().indexOf('lunch') > -1) {
            return <LunchTask key={item === null || item === void 0 ? void 0 : item.skeduloJobId} {...item}/>;
        }
        if (item.type.toLowerCase() === 'repair request') {
            return (<AppointmentTask key={item.skeduloJobId} {...item} location={location}/>);
        }
        if (item.type.toLowerCase() === 'part pickup') {
            return (<PartPickupTask key={item.skeduloJobId} {...item} location={location} techName={techName}/>);
        }
        if (item.type.toLowerCase() === 'visit') {
            return (<VisitTask key={item.skeduloJobId} {...item} location={location}/>);
        }
    };
    return (<AppointmentsListContainer>
      {isMobile ? (dashboardAppointments.map(renderTask)) : (<AppointmentsTable>
          <tbody>{dashboardAppointments.map(renderTask)}</tbody>
        </AppointmentsTable>)}

      {dashboardAppointments.length === 0 && (<NoAppointments>No scheduled appointments</NoAppointments>)}
    </AppointmentsListContainer>);
};
export default AppointmentList;
