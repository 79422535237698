var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'src/components/SimpleToast';
import { getNotTechnicians } from 'src/AdminApp/modules/users/actions';
import { selectNonTechnicians } from 'src/AdminApp/modules/users/selectors';
import { selectFleetById } from 'src/AdminApp/modules/fleets/selectors';
import { createFleetAccountOwners, getFleet, updateFleetAccountOwners, } from 'src/AdminApp/modules/fleets/actions';
const useAccountOwner = () => {
    var _a, _b;
    const dispatch = useDispatch();
    const { fleetId } = useParams();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [dateOverlappedModalOpen, setDateOverlappedModalOpen] = useState(false);
    const [formDefaults, setFormDefaults] = useState({
        start: null,
        end: null,
        owner: null,
        id: null,
    });
    const [showHistory, setShowHistory] = useState(false);
    const [savedUpdates, setSavedUpdates] = useState();
    const users = useSelector(selectNonTechnicians);
    const fleet = fleetId
        ? useSelector(selectFleetById(fleetId))
        : null;
    useEffect(() => {
        dispatch(getNotTechnicians());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const allAccountOwners = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.fleetAccountOwners) !== null && _a !== void 0 ? _a : [];
    let accountOwners = ((_b = fleet === null || fleet === void 0 ? void 0 : fleet.fleetAccountOwners) !== null && _b !== void 0 ? _b : []).sort((a, b) => {
        var _a, _b;
        const aStart = moment(a.startDate);
        const bStart = moment(b.startDate);
        const aEnd = moment((_a = a.endDate) !== null && _a !== void 0 ? _a : undefined);
        const bEnd = moment((_b = b.endDate) !== null && _b !== void 0 ? _b : undefined);
        if (aEnd < bEnd)
            return -1;
        if (aEnd > bEnd)
            return 1;
        if (aEnd === bEnd) {
            if (aStart < bStart)
                return -1;
            if (aStart > bStart)
                return 1;
        }
        return 0;
    });
    if (!showHistory) {
        accountOwners = accountOwners.slice(0, 2);
    }
    const saveUpdates = (values) => __awaiter(void 0, void 0, void 0, function* () {
        if (!(accountOwners === null || accountOwners === void 0 ? void 0 : accountOwners.length)) {
            const result = yield dispatch(createFleetAccountOwners([
                {
                    fleetId,
                    startDate: moment.utc(values.start),
                    endDate: moment.utc(values.end),
                    systemUserId: values.owner.uid,
                },
            ]));
            if (!(result === null || result === void 0 ? void 0 : result.error)) {
                toast.success('New account owner successfully applied.');
            }
        }
        else if (!formDefaults.owner) {
            const item = {
                fleetId,
                startDate: moment.utc(values.start),
                endDate: moment.utc(values.end),
                systemUserId: values.owner.uid,
            };
            const result = yield dispatch(createFleetAccountOwners([item]));
            if (!(result === null || result === void 0 ? void 0 : result.error)) {
                toast.success('Account owner successfully updated.');
            }
        }
        else if (formDefaults.owner) {
            const item = accountOwners.find((accountOwner) => accountOwner.id === formDefaults.id);
            item.startDate = moment.utc(values.start);
            item.endDate = moment.utc(values.end);
            item.systemUserId = values.owner.uid;
            const result = yield dispatch(updateFleetAccountOwners(accountOwners));
            if (!(result === null || result === void 0 ? void 0 : result.error)) {
                toast.success('Account owner successfully updated.');
            }
        }
        yield dispatch(getFleet(fleetId));
    });
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        if (moment(values.start) > moment(values.end)) {
            toast.error('“From" date cannot be later than the "End" date. Enter a valid date range.');
            return;
        }
        let owners = (_c = fleet === null || fleet === void 0 ? void 0 : fleet.fleetAccountOwners) !== null && _c !== void 0 ? _c : [];
        if (formDefaults.id) {
            owners = owners.filter((owner) => owner.id !== formDefaults.id);
        }
        let isOverlapped = false;
        owners.forEach((owner) => {
            var _a, _b, _c, _d;
            const ownerStart = moment((_a = owner.startDate) !== null && _a !== void 0 ? _a : undefined);
            const ownerEnd = moment((_b = owner.endDate) !== null && _b !== void 0 ? _b : undefined);
            const start = moment((_c = values.start) !== null && _c !== void 0 ? _c : undefined);
            const end = moment((_d = values.end) !== null && _d !== void 0 ? _d : undefined);
            if (start.isBetween(ownerStart, ownerEnd) ||
                end.isBetween(ownerStart, ownerEnd) ||
                ownerStart.isBetween(start, end) ||
                ownerEnd.isBetween(start, end)) {
                isOverlapped = true;
            }
        });
        if (isOverlapped) {
            setSavedUpdates(values);
            setDateOverlappedModalOpen(true);
            return;
        }
        yield saveUpdates(values);
        setOpenDrawer(false);
    });
    const handleClose = () => {
        setOpenDrawer(false);
        setFormDefaults({
            start: null,
            end: null,
            owner: null,
        });
    };
    const handleOwnerEdit = (item) => () => {
        setFormDefaults({
            start: moment(item.startDate),
            end: moment(item.endDate),
            owner: users.find((user) => user.uid === item.systemUserId),
            id: item.id,
        });
        setOpenDrawer(true);
    };
    const handleSubmitModal = () => __awaiter(void 0, void 0, void 0, function* () {
        yield saveUpdates(savedUpdates);
        setSavedUpdates(null);
        setDateOverlappedModalOpen(false);
        setOpenDrawer(false);
    });
    return {
        openDrawer,
        setOpenDrawer,
        dateOverlappedModalOpen,
        setDateOverlappedModalOpen,
        formDefaults,
        setFormDefaults,
        showHistory,
        setShowHistory,
        savedUpdates,
        setSavedUpdates,
        users,
        fleet,
        fleetId,
        accountOwners,
        allAccountOwners,
        saveUpdates,
        handleSubmit,
        handleClose,
        handleOwnerEdit,
        handleSubmitModal,
    };
};
export default useAccountOwner;
