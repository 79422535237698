import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Autocomplete } from 'src/components/mui';
import { selectPartDistributors } from 'src/PopsApp/modules/vendors/selectors';
import { getPartDistributors } from 'src/PopsApp/modules/vendors/actions';
import { orderBy } from 'lodash';
const PartDistributorAutocomplete = ({ value, onSelect, name, label, disabled = false, isAuthorized = false, }) => {
    const dispatch = useDispatch();
    const allPartDistributors = useSelector(selectPartDistributors);
    const [options, setOptions] = useState([]);
    useEffect(() => {
        if (allPartDistributors === undefined) {
            dispatch(getPartDistributors());
        }
        else {
            setOptions(allPartDistributors.filter((distributor) => distributor.status === 'ACTIVE'));
        }
    }, [allPartDistributors]); // eslint-disable-line react-hooks/exhaustive-deps
    const filterOptions = (options, state) => {
        if (!state.inputValue) {
            return options;
        }
        return options.filter((distributor) => distributor.name
            .toLowerCase()
            .replace(/[^0-9a-z ]/g, '')
            .indexOf(state.inputValue.toLowerCase()) > -1);
    };
    return (<Autocomplete value={value} style={{ width: '100%', minHeight: '25px' }} disabled={disabled} isOptionEqualToValue={(option, value) => (option === null || option === void 0 ? void 0 : option.id) === (value === null || value === void 0 ? void 0 : value.id)} options={orderBy(options, ['name'])} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} onChange={(e, value) => {
            onSelect(value);
        }} getOptionDisabled={(option) => {
            var _a, _b;
            return isAuthorized &&
                ((_b = (_a = option === null || option === void 0 ? void 0 : option.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.includes('customer provided'));
        }} filterOptions={filterOptions} renderInput={(params) => (<TextField {...params} name={name} label={label} type="text" fullWidth variant="outlined" InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default PartDistributorAutocomplete;
