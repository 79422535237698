import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedJobs } from 'src/PopsApp/modules/parts/selectors';
import InfoTag from 'src/components/layout/InfoTag';
import { StoresList } from './QuickLocations.styles';
import QuickLocationItem from './QuickLocationItem';
import { ListSectionWrapper, ListTitle } from '../Vendors.styles';
const QuickLocations = ({ title, homeStores, partPickups, handleVendorSubmit, showDeliveryTag = false, }) => {
    const locations = homeStores || partPickups || [];
    const selectedJobs = useSelector(selectSelectedJobs);
    return (<ListSectionWrapper>
      <div style={{ display: 'flex' }}>
        <ListTitle> {title} </ListTitle>
        {showDeliveryTag && <InfoTag text="Deliveries to hub"/>}
      </div>
      <StoresList>
        {selectedJobs &&
            locations
                .filter((l) => l.partDistributorName.toLowerCase() !== 'repairsmith hub')
                .map((location, index) => (<QuickLocationItem location={location} handleVendorSubmit={handleVendorSubmit} key={`${location.partDistributorLocationId} ${index}`}/>))}
      </StoresList>
    </ListSectionWrapper>);
};
export default QuickLocations;
