import React, { useRef, useState } from 'react';
import moment from 'moment';
import { Group as GroupIcon, ChevronLeft, ChevronRight, } from 'src/components/mui/icons';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import { Button } from 'src/components/mui';
import SimpleChip from 'src/components/SimpleChip';
import { SimpleModal, SlickSlider } from 'src/components/layout';
import { CarouselContent, CarouselWrapper, LeftArrow, RightArrow, Slide, } from 'src/components/carousel.styles';
import { colors } from 'src/styles/theme';
import { DATE_TIME_12H_FORMAT } from 'src/timeConstants';
import NoteTooltip from './common/NoteTooltip';
import NoteLogo from './common/NoteLogo';
import NoteContent from './common/NoteContent';
import { chipColors, Note, NoteContainer, NoteContainerInfo, NoteDetails, NoteDetailsItem, NoteInfo, NoteItemRoot, NoteLabel, NoteValue, TypeIcon, } from './Notes.styles';
import { renderNoteContent } from './utils';
const repairSmithOrigins = ['TOOLS', 'MPI'];
const NotesItem = ({ note, notesAppliesTo }) => {
    var _a, _b;
    const entityOriginName = (_a = notesAppliesTo.find((item) => item.entityId === note.appliesTo[0].entityOriginId)) === null || _a === void 0 ? void 0 : _a.entityName;
    const [carouselOpen, setCarouselOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState();
    const photoNames = (note === null || note === void 0 ? void 0 : note.base64PhotoStrings)
        ? Object.keys(note === null || note === void 0 ? void 0 : note.base64PhotoStrings)
        : [];
    const sliderRef = useRef(null);
    const handleViewPhotos = () => {
        handleCarouselChange(null, 0);
        setCarouselOpen(true);
    };
    const handleCarouselChange = (_last, index) => {
        const img = photoNames[index];
        setCurrentImage(img);
    };
    const handleCarouselClose = () => {
        setCarouselOpen(false);
        setCurrentImage(undefined);
    };
    const sliderSettings = {
        adaptiveHeight: true,
        arrows: false,
        beforeChange: handleCarouselChange,
        dots: false,
        infinite: true,
        initialSlide: 0,
        slidesToScroll: 1,
        slidesToShow: 1,
    };
    const handlePrevImage = () => { var _a, _b; return ((_a = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _a === void 0 ? void 0 : _a.slickPrev) && ((_b = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _b === void 0 ? void 0 : _b.slickPrev()); };
    const handleNextImage = () => { var _a, _b; return ((_a = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _a === void 0 ? void 0 : _a.slickNext) && ((_b = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _b === void 0 ? void 0 : _b.slickNext()); };
    return (<NoteItemRoot key={note.id}>
      <NoteContainer>
        <NoteContainerInfo>
          {entityOriginName && `${entityOriginName} - `}
          {entityOriginName ? (<strong>{note.appliesTo[0].entityName}</strong>) : (note.appliesTo[0].entityName)}
        </NoteContainerInfo>
        <NoteTooltip placement="left" type={note.type} subType={note.subType}/>
      </NoteContainer>

      <Note>
        <NoteLabel>Note:</NoteLabel>
        <NoteValue>
          <NoteContent note={renderNoteContent(note)}/>
          {(note === null || note === void 0 ? void 0 : note.base64PhotoStrings) && (<Button type="button" color="primary" variant="text" onClick={handleViewPhotos}>
              Attached images({photoNames.length})
            </Button>)}
        </NoteValue>
      </Note>
      <NoteDetails>
        <NoteDetailsItem>
          <NoteInfo>
            <div>
              {repairSmithOrigins.includes(note.origin) ? (<NoteLogo width="20px" marginRight="8px"/>) : (<TypeIcon style={{
                marginRight: '8px',
            }} component={GroupIcon}/>)}
            </div>
            <div>
              Created by:{' '}
              <AdminUser subText={false} updatedUser={note.author} maxChars={30}/>{' '}
              <br />{' '}
              {moment
            .tz(moment.utc(note.created), 'America/Los_Angeles')
            .format(DATE_TIME_12H_FORMAT)}
            </div>
          </NoteInfo>
          <SimpleChip $bgColor={chipColors[note.visibility]} label={note.visibility} $textColor={colors.white}/>
        </NoteDetailsItem>
      </NoteDetails>
      <SimpleModal maxWidth="lg" open={carouselOpen} onClose={handleCarouselClose} title={currentImage}>
        {(note === null || note === void 0 ? void 0 : note.base64PhotoStrings) && (<CarouselWrapper>
            <CarouselContent>
              <LeftArrow onClick={handlePrevImage}>
                <ChevronLeft />
              </LeftArrow>
              <SlickSlider {...sliderSettings} ref={sliderRef}>
                {(_b = (photoNames !== null && photoNames !== void 0 ? photoNames : [])) === null || _b === void 0 ? void 0 : _b.map((name) => {
                return (<div key={name}>
                      <Slide>
                        <img alt={name} src={note.base64PhotoStrings[name]}/>
                      </Slide>
                    </div>);
            })}
              </SlickSlider>
              <RightArrow onClick={handleNextImage}>
                <ChevronRight />
              </RightArrow>
            </CarouselContent>
          </CarouselWrapper>)}
      </SimpleModal>
    </NoteItemRoot>);
};
export default NotesItem;
