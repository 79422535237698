var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { passwordReset } from 'src/AdminApp/modules/consumers/actions';
import { toast } from 'src/components/SimpleToast';
const useSendResetPassword = ({ isFleet, email, }) => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const toggleModal = () => {
        setOpenModal(!openModal);
    };
    const onResetPasswordClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const consumerEmail = email;
        const result = yield dispatch(passwordReset(consumerEmail));
        if (!((result === null || result === void 0 ? void 0 : result.payload) instanceof Error)) {
            toast.success(`${isFleet ? 'Fleet user' : 'Consumer'} password reset request sent.`);
        }
        toggleModal();
    });
    return {
        toggleModal,
        openModal,
        onResetPasswordClick,
    };
};
export default useSendResetPassword;
