import { createTheme } from 'src/components/mui';
export const STATIC_URL_BASE = 'https://assets.repairsmith.com/web/';
export const FONT_FAMILY = 'Interstate, sans-serif';
export const colors = {
    brandPink: '#e61464',
    default: '#000000',
    primary: '#e61464',
    secondary: '#2e64b5',
    lightGrey: '#dedede',
    middleGrey: '#aaa',
    blueGrey: '#9b9fb5',
    darkGrey: '#767676',
    iconGrey: '#cccccc',
    backgroundIconGrey: '#d8d8d8',
    transparentDarkGrey: 'rgba(118, 118, 118, 0.16)',
    transparentLightGrey: 'rgba(118, 118, 118, 0.08)',
    success: '#00b988',
    error: '#d0021b',
    warning: '#ff6900',
    lightRed: '#ff7070',
    blue: '#0b8ed5',
    blue2: '#4a90e2',
    blue3F: '#3f51b5',
    blue33: '#3356dd',
    blue13: '#1383CB',
    orange: '#d99800',
    orangeSelected: '#ff6900',
    darkGrayish: '#9da6b0',
    darkGrayishBlue: '#6f767d',
    deepDarkGrey: '#565b61',
    border: '#e0e0e0',
    pink: '#ffbaba',
    celebrationPink: '#ad104c',
    carbonFiber: '#5c5c5c',
    newCarBlue: '#2e64b5',
    midnightBlue: '#0a3453',
    concreteGray: '#949494',
    lightSilver: '#eaeaea',
    linkBlue: '#0f5485',
    linkBlueHover: '#0d4771',
    white: '#ffffff',
    black: '#191919',
    lightCyan: 'lightcyan',
    lightYellow: 'lightyellow',
    autocompleteBorder: '#007991',
    redF5: '#f50057',
    redAB: '#a6160a',
    statusRed: '#d92d20',
    ORANGE_FF: '#ffb300',
    GRAY_71: '#717171',
    GRAY_67: '#676767',
    GRAY_76: '#767676',
    GRAY_FA: '#FAFAFA',
    GRAY_FC: '#fcfcfc',
    GRAY_AA: '#AAAAAA',
    GRAY_CC: '#CCCCCC',
    GRAY_DE: '#DEDEDE',
    GRAY_D4: '#D4D4D4',
    GRAY_E1: '#e1e1e1',
    GRAY_EB: '#ebebeb',
    GRAY_EBED: '#EBEDf0',
    GRAY_EE: '#EEEEEE',
    GRAY_F0: '#F0F0F0',
    GRAY_F1: '#F1F1F1',
    GRAY_F2: '#F2F2F2',
    GRAY_F5: '#f5f5f5',
    GRAY_F6: '#f6f6f6',
    GRAY_F8: '#f8f8fa',
    GRAY_3F: '#3F3F3F',
    GRAY_37: '#373737',
    GRAY_97: '#979797',
    BLUE: '#2c56dd',
    BLUE_25: '#2596be',
    TOOLTIP: '#0A1D29',
    BLUE_MUTED: '#8E9DCB',
    GRAY_F4: '#F4F4F4',
    GRAY_D1: '#D1D1D1',
    GRAY_3E: '#3E484E',
    GRAY_9C: '#9C9C9C',
    BLACK_001: '#001D2C',
    YELLOW_E1: '#FFE100',
    GREEN: '#00B988',
    ORANGE: '#FF6900',
    RED: '#FF0000',
    RED_DARK: '#D50000',
    BLACK_ICON: '#0a1d29',
    YELLOW: '#FFFF00',
    LIGHT_GRAY: '#3f3f3f03',
    transparentError: 'rgba(213, 0, 0, .04)',
    // OLD COLORS
    lightBlue: '#0B8ED2',
    darkBlue: '#3F51B7',
    red: 'indianred',
    darkRed: '#ff3043',
    lightGreen: '#00b988',
    green: '#00b988',
    darkGreen: '#00815f',
    brightRed: '#d5182f',
    grey_57: '#575757',
    yellow: '#ffffba',
    darkGold: '#ffb300',
    lightOrange: '#ffca4b',
    grey: 'rgba(0, 0, 0, 0.26)',
    whiteSmokeGrey: '#f8f8f8',
};
export const theme = createTheme({
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.secondary,
        },
        success: {
            main: colors.success,
        },
        error: {
            main: colors.error,
        },
        warning: {
            main: colors.warning,
        },
        text: {
            primary: colors.default,
            secondary: colors.default,
        },
    },
    typography: {
        fontFamily: FONT_FAMILY,
        subtitle1: {
            fontSize: '0.75rem',
            fontWeight: 'bold',
            color: colors.darkGrey,
        },
        subtitle2: {
            fontSize: '1rem',
            fontWeight: 'bold',
        },
    },
    breakpoints: {
        values: {
            xs: 375,
            sm: 768,
            md: 1280,
            lg: 1920,
            xl: 1920,
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h5: {
                    fontWeight: 'bold',
                },
                h6: {
                    fontWeight: 'bold',
                },
                body2: {
                    fontSize: '1rem',
                },
                body1: {
                    fontSize: '0.875rem',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    'fontSize': '14px',
                    '&:first-child': {
                        paddingLeft: '10px',
                    },
                    '&:last-child': {
                        paddingRight: '0',
                    },
                },
                head: {
                    fontWeight: 'bold',
                    fontSize: '12px',
                    color: colors.darkGrey,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${colors.lightGrey}`,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeSmall: {
                    fontSize: '1.15rem',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    fontWeight: 500,
                    color: colors.default,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    color: colors.darkGrey,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: '8px',
                },
            },
        },
    },
});
export const visitUIVanChipColors = [
    '#00B988',
    '#9881F6',
    '#E9A505',
    '#4A90E2',
];
