import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { SimpleDrawer, SimpleDrawerContent } from 'src/components/SimpleDrawer';
import InitialPrice from 'src/AdminApp/components/repairs/statement/InitialPrice';
import styled from 'styled-components';
import { NewComponentButton, StyledAddCircleIcon } from '../../Order.styles';
import AddComponentDrawerForm from './AddComponentDrawerForm';
const DrawerStyledSubHeader = styled.div `
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
`;
const DrawerStyledSubHeaderInfo = styled.span `
  display: flex;
  font-size: 13px;
  padding: 8px 0px;
`;
const AddComponentDrawer = ({ type, setNewComponentType, repairId, newComponentType, onChangeInput, onSaveNext, referenceNum, price, isCustomRepair, hasEngine, fixedComponents, }) => {
    const [open, setOpen] = useState(false);
    const componentType = capitalize(type);
    const handleOpenDrawer = () => {
        setNewComponentType({
            type,
            repairId,
        });
        setOpen(true);
    };
    const handleCloseDrawer = () => {
        setNewComponentType(undefined);
        setOpen(false);
    };
    return (<>
      <NewComponentButton onClick={handleOpenDrawer}>
        <StyledAddCircleIcon />
        Add {pluralize(componentType)}
      </NewComponentButton>
      <SimpleDrawer open={open} onClose={handleCloseDrawer} title={`Add ${componentType}`}>
        <DrawerStyledSubHeader>
          <DrawerStyledSubHeaderInfo>
            {componentType} Details
          </DrawerStyledSubHeaderInfo>
          <DrawerStyledSubHeaderInfo>
            Ref # {referenceNum} | Quoted Price: <InitialPrice price={price}/>
          </DrawerStyledSubHeaderInfo>
        </DrawerStyledSubHeader>
        <SimpleDrawerContent>
          <AddComponentDrawerForm newComponentType={newComponentType} onChangeInput={onChangeInput} onSaveNext={onSaveNext} onClose={handleCloseDrawer} isCustomRepair={isCustomRepair} hasEngine={hasEngine} fixedComponents={fixedComponents}/>
        </SimpleDrawerContent>
      </SimpleDrawer>
    </>);
};
export default AddComponentDrawer;
