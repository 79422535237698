import { Formik } from 'formik';
import { isNull } from 'lodash';
import React from 'react';
import { SimpleModal } from 'src/components/layout';
import { Tab, Tabs } from 'src/components/mui';
import { SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import useEditPartDrawer from 'src/AdminApp/components/repairs/statement/Repairs/Drawers/EditPartDrawer/useEditPartDrawer';
import EditPartDrawerAdditional from './EditPartDrawerAdditional';
import EditPartDrawerGeneral from './EditPartDrawerGeneral';
import EditPartDrawerAdditionalInfo from './EditPartDrawerAdditionalInfo';
import AuthorizedSubtitle from '../components/AuthorizedSubtitle';
import { Spinner, StyledBackdrop } from './EditPartDrawer.styles';
const EditPartDrawer = ({ adding = false, cost, id, name, notes, onClose, onRemove, onSubmit, open, partNumber = null, componentPartDistributor, price, quantity, specialOrder = false, leadTime = null, canEdit = true, partMetadata, totalCost, quotedCost, status, invoice, pcdbId, canEditDistributor = false, componentId = null, pricingData, hasEngine, fixedComponents, coreChargeExpected, coreChargeCost, canEditPricing = false, isFitment = false, additionalInfo, isAuthorized = false, isShowAdditionalInfo = false, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const { isTireComponent, tab, handleTabChange, getPartDistributor, PartSchema, onAddressSelect, setIsConfirmQtyModalOpen, isConfirmQtyModalOpen, setHasMetadataInfo, validMetadata, isSystemAdmin, listOfLockedDownComponents, handleSubmitWithDrawer, isAddPartToPickupDialogOpen, setIsAddPartToPickupDialogOpen, onAddPartToPickupConfirm, onConfirmDynamicCreation, dynamicCreationConfirm, onConfirmDataCorrectness, idComponentDataCorrect, handleCloseDialogs, confirmRemoveDynamicRule, setConfirmRemoveDynamicRule, forceComponentSelection, serviceDrawerState, setIsTireSelected, isTireSelected, } = useEditPartDrawer({
        open,
        name,
        componentPartDistributor,
        onSubmit,
        adding,
        invoice,
        status,
        isShowAdditionalInfo,
    });
    const isDynamicComponent = (pricingData === null || pricingData === void 0 ? void 0 : pricingData.componentType) === 'DYNAMIC';
    const isFixedComponent = (pricingData === null || pricingData === void 0 ? void 0 : pricingData.componentType) === 'FIXED';
    let title = serviceDrawerState.adding ? 'Add Parts' : 'Edit Parts';
    if (isFitment) {
        title = 'Edit Multi Fitment Item';
    }
    const additionalInfoMap = additionalInfo
        ? Object.keys(additionalInfo).map((key) => {
            return { name: key, value: additionalInfo[key] };
        })
        : [];
    return (<SimpleDrawer open={open} onClose={onClose} title={title}>
      <Tabs variant="fullWidth" indicatorColor="primary" scrollButtons="auto" textColor="primary" value={tab} onChange={handleTabChange}>
        <Tab value="general" label="General Info" style={{
            fontWeight: 'bold',
        }}/>
        {(!isTireSelected || (!adding && isTireComponent)) && (<Tab value="additional" label="Additional" style={{
                fontWeight: 'bold',
            }}/>)}
      </Tabs>

      {isAuthorized && <AuthorizedSubtitle />}

      <Formik initialValues={{
            name: name || '',
            id: id || '',
            quantity: quantity || '',
            cost: isNull(cost) ? '' : cost,
            price: isNull(price) ? '' : price,
            partNumber: partNumber || '',
            partDistributorId: (_a = componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.partDistributorId) !== null && _a !== void 0 ? _a : '',
            partDistributor: getPartDistributor(),
            partDistributorName: (_b = componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.partDistributorName) !== null && _b !== void 0 ? _b : '',
            partDistributorLocationId: (_c = componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.partDistributorLocationId) !== null && _c !== void 0 ? _c : '',
            address: (_d = componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.address) !== null && _d !== void 0 ? _d : '',
            notes: notes || '',
            specialOrder,
            leadTime,
            pcdbId,
            componentId,
            addAsDynamicComponent: isDynamicComponent,
            partMetadata: !isTireComponent
                ? null
                : {
                    manufacturer: (_e = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.manufacturer) !== null && _e !== void 0 ? _e : '',
                    model: (_f = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.model) !== null && _f !== void 0 ? _f : '',
                    aspectRatio: (_g = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.aspectRatio) !== null && _g !== void 0 ? _g : '',
                    rimDiameter: (_h = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.rimDiameter) !== null && _h !== void 0 ? _h : '',
                    loadRange: (_j = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.loadRange) !== null && _j !== void 0 ? _j : '',
                    speedRating: (_k = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.speedRating) !== null && _k !== void 0 ? _k : '',
                    size: (_l = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.size) !== null && _l !== void 0 ? _l : '',
                    position: (_m = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.position) !== null && _m !== void 0 ? _m : '',
                    width: (_o = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.width) !== null && _o !== void 0 ? _o : '',
                },
            coreChargeExpected,
            coreChargeCost,
            isFitment,
            additionalInfo: additionalInfoMap,
            partSpecialistLevel: null,
            automaticallyOrder: null,
            skynetMinimumMargin: null,
        }} validationSchema={PartSchema} validateOnChange validateOnBlur enableReinitialize onSubmit={() => { }}>
        {({ isSubmitting, isValid, isValidating, setFieldValue, setValues, values, dirty, resetForm, setSubmitting, }) => {
            var _a;
            return (<>
              <SimpleDrawerContent>
                {tab === 'general' && (<EditPartDrawerGeneral values={values} setValues={setValues} setFieldValue={setFieldValue} onAddressSelect={onAddressSelect} canEdit={canEdit} totalCost={totalCost} quotedCost={quotedCost} setIsConfirmQtyModalOpen={setIsConfirmQtyModalOpen} isConfirmQtyModalOpen={isConfirmQtyModalOpen} isTireComponent={isTireComponent} canEditDistributor={canEditDistributor} isFixedComponent={isFixedComponent} hasEngine={hasEngine} fixedComponents={fixedComponents} canEditPricing={(adding || canEditPricing) && !isAuthorized} isAuthorized={isAuthorized} isFitment={isFitment} forceComponentSelection={forceComponentSelection} setIsTireSelected={setIsTireSelected}/>)}
                {tab === 'additional' && !adding && isTireComponent && (<EditPartDrawerAdditional values={values} setFieldValue={setFieldValue} canEdit={canEdit} setHasMetadataInfo={setHasMetadataInfo}/>)}
                {tab === 'additional' &&
                    !isTireComponent &&
                    !isTireSelected && (<EditPartDrawerAdditionalInfo values={values.additionalInfo} setFieldValue={setFieldValue} canEdit={canEdit}/>)}
              </SimpleDrawerContent>
              <SimpleDrawerFooter>
                <SimpleTwoButtonRow disabledSubmit={!isValid ||
                    isValidating ||
                    isSubmitting ||
                    !canEdit ||
                    !dirty ||
                    !validMetadata((_a = values === null || values === void 0 ? void 0 : values.partMetadata) !== null && _a !== void 0 ? _a : {}) ||
                    (forceComponentSelection &&
                        ((adding && !(values === null || values === void 0 ? void 0 : values.componentId)) ||
                            (!adding &&
                                name !== (values === null || values === void 0 ? void 0 : values.name) &&
                                !(values === null || values === void 0 ? void 0 : values.componentId))))} disabledCancel={adding ||
                    !canEdit ||
                    isAuthorized ||
                    (!isSystemAdmin &&
                        listOfLockedDownComponents.includes(values === null || values === void 0 ? void 0 : values.pcdbId)) ||
                    isFitment} submitText="Save" cancelText="Remove" disabledCancelTooltipTitle="This component is part of an authorized repair. To make changes, remove the authorization first." onCancel={() => {
                    if ((values === null || values === void 0 ? void 0 : values.componentId) && isDynamicComponent) {
                        setConfirmRemoveDynamicRule(true);
                        return;
                    }
                    onRemove({ componentId: id });
                }} onSubmit={() => {
                    var _a;
                    if (values.addAsDynamicComponent && (values === null || values === void 0 ? void 0 : values.componentId)) {
                        onConfirmDynamicCreation();
                        return;
                    }
                    if (isFitment) {
                        values.partDistributor = {
                            address: values.address,
                            id: values.partDistributorId,
                            partDistributorId: values.partDistributorId,
                            partDistributorName: values.partDistributorName,
                            partDistributorLocationId: values.partDistributorLocationId,
                        };
                    }
                    handleSubmitWithDrawer(Object.assign(Object.assign({}, values), { coreChargeCost: (values === null || values === void 0 ? void 0 : values.coreChargeExpected)
                            ? values === null || values === void 0 ? void 0 : values.coreChargeCost
                            : null, coreChargeExpected: (_a = values === null || values === void 0 ? void 0 : values.coreChargeExpected) !== null && _a !== void 0 ? _a : false }), resetForm, setSubmitting);
                }}/>
              </SimpleDrawerFooter>

              <StyledBackdrop open={isSubmitting}>
                <Spinner />
              </StyledBackdrop>

              <SimpleModal open={isConfirmQtyModalOpen} onClose={() => {
                    setValues(Object.assign(Object.assign({}, values), { quantity: quantity !== null && quantity !== void 0 ? quantity : '' }));
                    setIsConfirmQtyModalOpen(!isConfirmQtyModalOpen);
                }} title="Houston, we have a problem. You have entered in a quantity greater than 25, are you sure?" simpleTwoButtonRowProps={{
                    onCancel: () => {
                        setValues(Object.assign(Object.assign({}, values), { quantity: quantity !== null && quantity !== void 0 ? quantity : '' }));
                        setIsConfirmQtyModalOpen(!isConfirmQtyModalOpen);
                    },
                    onSubmit: () => {
                        setIsConfirmQtyModalOpen(!isConfirmQtyModalOpen);
                    },
                    cancelText: 'No',
                    submitText: 'Yes',
                }}/>
              <SimpleModal open={isAddPartToPickupDialogOpen} onClose={() => setIsAddPartToPickupDialogOpen(false)} title="Would you like to have the system create/merge with a part pickup?" simpleTwoButtonRowProps={{
                    onCancel: () => onAddPartToPickupConfirm(false),
                    onSubmit: () => onAddPartToPickupConfirm(true),
                    cancelText: 'No',
                    submitText: 'Yes',
                }}>
                Reminder that part pickups created manually in Skedulo will not
                show on the technicians dashboard.
              </SimpleModal>
              <SimpleModal open={dynamicCreationConfirm} onClose={handleCloseDialogs} title="Warning" simpleTwoButtonRowProps={{
                    onCancel: handleCloseDialogs,
                    onSubmit: onConfirmDataCorrectness,
                    cancelText: 'Cancel',
                    submitText: 'Confirm',
                }}>
                Please confirm this part is always needed when doing this repair
                on this specific vehicle. Doing so will make sure this part
                always appears when we perform this repair on this specific
                vehicle.
              </SimpleModal>
              <SimpleModal open={idComponentDataCorrect} onClose={handleCloseDialogs} title="Are you sure?" simpleTwoButtonRowProps={{
                    onCancel: handleCloseDialogs,
                    onSubmit: () => {
                        handleSubmitWithDrawer(values, resetForm, setSubmitting);
                    },
                    cancelText: 'Cancel',
                    submitText: 'Confirm',
                }}>
                Please verify the part information is correct as entered.
              </SimpleModal>
              <SimpleModal open={confirmRemoveDynamicRule} onClose={() => setConfirmRemoveDynamicRule(false)} title="Removal Warning" simpleTwoButtonRowProps={{
                    onCancel: () => {
                        setConfirmRemoveDynamicRule(false);
                    },
                    onSubmit: () => {
                        setConfirmRemoveDynamicRule(false);
                        onRemove({ componentId: id, removeDynamicRule: true });
                    },
                    cancelText: 'Cancel',
                    submitText: 'Confirm',
                }}>
                Please confirm that this part is not needed to complete this
                repair. Doing so will make sure this part no longer appears when
                we perform this repair on this specific vehicle.
              </SimpleModal>
            </>);
        }}
      </Formik>
    </SimpleDrawer>);
};
export default EditPartDrawer;
