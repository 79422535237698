import React from 'react';
import useCarDrawer from 'src/AdminApp/containers/vehicle/CarModule/useCarDrawer';
import { ConfirmationModal } from 'src/components/layout';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import CarForm from './CarForm';
const CarDrawer = ({ open, onClose, onSubmit, vehicle, isMilesOutConfirm = false, }) => {
    const { isValid, confirmationModalOpen, confirmationModalText, submitSelectedVehicle, handleSubmit, handleVehicleUpdated, handleIsSelectedCarChange, setConfirmationModalOpen, } = useCarDrawer({ onSubmit, isMilesOutConfirm });
    return (<>
      <ConfirmationModal open={confirmationModalOpen} text={confirmationModalText} action={submitSelectedVehicle} onClose={() => setConfirmationModalOpen(false)}/>
      <SimpleDrawer open={open} onClose={onClose} title={isMilesOutConfirm ? 'Set/Confirm Miles Out' : 'Edit Car'} disableEnforceFocus>
        <SimpleDrawerContent>
          <CarForm onChange={(v) => handleVehicleUpdated(v)} setIsSelectedCarVin={handleIsSelectedCarChange} vehicle={vehicle} editMode focusMilesOut={isMilesOutConfirm}/>
        </SimpleDrawerContent>
        <SimpleDrawerFooter>
          <FooterActionButton onClick={handleSubmit} disabled={!isValid}>
            Save
          </FooterActionButton>
        </SimpleDrawerFooter>
      </SimpleDrawer>
    </>);
};
export default CarDrawer;
