import React from 'react';
import { InputAdornment, Typography } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
import LaborRates from './LaborRates';
const PricingForm = (props) => {
    var _a, _b;
    return (<div className="app-form">
      <LaborRates laborRates={(_b = (_a = props.values) === null || _a === void 0 ? void 0 : _a.pricingDetails) === null || _b === void 0 ? void 0 : _b.laborRates}/>

      <Typography variant="subtitle2">Taxes</Typography>

      <TextField name="pricingDetails.taxRate" placeholder="Sales Tax" type="number" InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
        }} required/>
    </div>);
};
export default PricingForm;
