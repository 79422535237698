import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdminUserInfoById } from 'src/AdminApp/modules/users/selectors';
import { getIspOwnerId, isAdmin } from 'src/AdminApp/components/users/utils';
import { loadAdminUserInfo } from 'src/AdminApp/modules/users/actions';
function useAdminUser({ user, ispOwnerId = null }) {
    const dispatch = useDispatch();
    const isUserAdmin = isAdmin(user) || !!ispOwnerId;
    const adminUserId = !!user && isUserAdmin ? getIspOwnerId(user) : null;
    const currentUser = useSelector(selectAdminUserInfoById(adminUserId));
    const { firstName, lastName } = currentUser;
    const adminName = !!firstName && !!lastName ? `${firstName} ${lastName}` : currentUser === null || currentUser === void 0 ? void 0 : currentUser.email;
    const name = isUserAdmin ? adminName : user;
    useEffect(() => {
        if (!adminUserId)
            return;
        if (isUserAdmin) {
            dispatch(loadAdminUserInfo(adminUserId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    return name;
}
export default useAdminUser;
