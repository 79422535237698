import { useEffect } from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
const useStickyTabs = (tabRef) => {
    const isMobile = useIsMobile();
    useEffect(() => {
        if (isMobile) {
            const handleScroll = () => {
                var _a, _b, _c;
                const tabsElement = tabRef.current;
                const header = document.querySelector('.mobile-header');
                const tabsContainer = document.querySelector('.tabs-content');
                const stopwatchBar = document.querySelector('.stopwatch-bar');
                const stopwatchHasContent = ((_a = stopwatchBar === null || stopwatchBar === void 0 ? void 0 : stopwatchBar.childElementCount) !== null && _a !== void 0 ? _a : 0) > 0;
                if (tabsElement && header && tabsContainer && stopwatchBar) {
                    const fixedDivRect = tabsElement.getBoundingClientRect();
                    const headerRect = header.getBoundingClientRect();
                    const tabsContainerRect = tabsContainer.getBoundingClientRect();
                    const stopwatchBarRect = stopwatchBar.getBoundingClientRect();
                    const hasTouchedHeader = headerRect.bottom >= fixedDivRect.top &&
                        fixedDivRect.bottom >= tabsContainerRect.top;
                    const hasTouchedStopwatch = stopwatchHasContent &&
                        stopwatchBarRect.bottom >= fixedDivRect.top &&
                        fixedDivRect.bottom >= stopwatchBarRect.top;
                    if (hasTouchedHeader || hasTouchedStopwatch) {
                        tabsElement.style.position = 'sticky';
                        tabsElement.style.top = `${headerRect.height +
                            (stopwatchHasContent
                                ? (_c = (_b = stopwatchBar === null || stopwatchBar === void 0 ? void 0 : stopwatchBar.firstElementChild) === null || _b === void 0 ? void 0 : _b.clientHeight) !== null && _c !== void 0 ? _c : 0
                                : 0)}px`;
                        tabsElement.style.zIndex = '19';
                        tabsElement.style.background = 'white';
                    }
                }
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isMobile, tabRef]);
};
export default useStickyTabs;
