var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import CONFIG from 'src/config';
import { FormControl, Grid, InputAdornment, InputLabel, Select, TextField, } from 'src/components/mui';
import { createMenuItems } from 'src/AdminApp/components/form/utils';
import ComponentAutocomplete from 'src/AdminApp/components/repairs/components/ComponentAutocomplete';
import { FluidCapacityUnit, FluidType } from 'src/AdminApp/models/enums';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { AttachMoneyRounded as AttachMoneyRoundedIcon, AddCircle, } from 'src/components/mui/icons';
import { DrawerFormRow, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import ToolTipInfo from 'src/FleetVisitsApp/pages/FleetVisits/components/ToolTipInfo';
import { formatAdditionalInfo } from 'src/AdminApp/components/repairs/statement/utils';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import AddComponentAdditionalInfo from './AddComponentAdditionalInfo';
const AddComponentDrawerForm = ({ newComponentType, onChangeInput, onSaveNext, onClose, isCustomRepair, hasEngine, fixedComponents, }) => {
    const isFluid = (newComponentType === null || newComponentType === void 0 ? void 0 : newComponentType.type) === 'Fluid';
    const itemName = isFluid ? 'Fluid' : 'Part';
    const FormSchema = Yup.object().shape({
        name: Yup.string().min(1).required(),
        componentId: Yup.string().notRequired().nullable(),
        quantity: isFluid
            ? Yup.number().notRequired().nullable()
            : Yup.number().min(1).required(),
        capacityUnit: isFluid
            ? Yup.string().required()
            : Yup.string().notRequired().nullable(),
        capacity: isFluid
            ? Yup.number().required()
            : Yup.number().notRequired().nullable(),
        type: isFluid
            ? Yup.string().required()
            : Yup.string().notRequired().nullable(),
        cost: Yup.number().min(0).required(),
        price: Yup.number().min(0).required(),
        partNumber: Yup.string().required(),
        addAsDynamicComponent: Yup.boolean().nullable().notRequired(),
        coreChargeExpected: Yup.boolean().nullable(),
        coreChargeCost: Yup.number().when('coreChargeExpected', {
            is: true,
            then: Yup.number().min(1).required(),
            otherwise: Yup.number().nullable().notRequired(),
        }),
    });
    const forceComponentSelection = CONFIG.ENABLE_FORCE_COMPONENT_DB_SELECTION === 'true';
    const handleSubmit = (values, setSubmitting, resetForm) => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        const formattedValues = formatAdditionalInfo(values);
        yield onSaveNext(isCustomRepair, formattedValues, newComponentType);
        setSubmitting(false);
        resetForm({ values: Object.assign({}, values) });
        onClose();
    });
    const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false);
    return (<Formik initialValues={{
            name: '',
            componentId: null,
            addAsDynamicComponent: false,
            quantity: '',
            capacityUnit: '',
            capacity: '',
            type: '',
            cost: '',
            price: '',
            partNumber: '',
            coreChargeExpected: null,
            coreChargeCost: null,
            pcdbId: null,
            additionalInfo: null,
        }} validationSchema={FormSchema} validateOnMount onSubmit={(values, { setSubmitting, resetForm }) => __awaiter(void 0, void 0, void 0, function* () {
            handleSubmit(values, setSubmitting, resetForm);
        })}>
      {({ isSubmitting, isValid, isValidating, setFieldValue, handleSubmit, values, }) => {
            var _a, _b;
            return (<>
            <SimpleDrawerContent>
              <Grid container spacing={1}>
                <DrawerFormRow item xs={12}>
                  <ComponentAutocomplete value={values.name} componentId={values.componentId} type={itemName.toUpperCase()} onChange={({ componentId, name, partSpecialistLevel, automaticallyOrder, skynetMinimumMargin, coreChargeExpected, pcdbId, }) => {
                    setFieldValue('componentId', componentId);
                    setFieldValue('name', name);
                    setFieldValue('partSpecialistLevel', partSpecialistLevel);
                    setFieldValue('automaticallyOrder', automaticallyOrder);
                    setFieldValue('skynetMinimumMargin', skynetMinimumMargin);
                    setFieldValue('coreChargeExpected', coreChargeExpected);
                    setFieldValue('pcdbId', pcdbId);
                    onChangeInput('componentId', false)({ target: { value: componentId } });
                    onChangeInput('name', false)({ target: { value: name } });
                    if (!componentId) {
                        setFieldValue('addAsDynamicComponent', false);
                        onChangeInput('name', false)({ target: { value: false } });
                    }
                }} forceComponentSelection={forceComponentSelection}/>
                </DrawerFormRow>
                {!isFluid && (<DrawerFormRow item xs={12}>
                    <TextField variant="outlined" fullWidth name="quantity" label={`${itemName} Quantity`} type="number" placeholder={`${itemName} Quantity`} onChange={(e) => {
                        onChangeInput('quantity', true)(e);
                        setFieldValue('quantity', e.target.value);
                    }}/>
                  </DrawerFormRow>)}
                {isFluid && (<DrawerFormRow item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="fluid-capacity-unit" className="editFieldCaption">
                        Fluid Capacity Unit
                      </InputLabel>
                      <Select variant="outlined" name="capacityUnit" label="Fluid Capacity Unit" onChange={(e) => {
                        onChangeInput('capacityUnit', false)(e);
                        setFieldValue('capacityUnit', e.target.value);
                    }}>
                        {createMenuItems(FluidCapacityUnit)}
                      </Select>
                    </FormControl>
                  </DrawerFormRow>)}
                {isFluid && (<DrawerFormRow item xs={12}>
                    <TextField className="editField" variant="outlined" fullWidth name="capacity" label="Fluid Capacity" type="number" placeholder="Fluid Capacity" onChange={(e) => {
                        onChangeInput('capacity', true, true)(e);
                        setFieldValue('capacity', e.target.value);
                    }}/>
                  </DrawerFormRow>)}
                {isFluid && (<DrawerFormRow item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="fluid-capacity-type" className="editFieldCaption">
                        Fluid Capacity Type
                      </InputLabel>
                      <Select labelId="fluid-capacity-type" className="editField" variant="outlined" name="type" label="Fluid Capacity Type" onChange={(e) => {
                        onChangeInput('type', false)(e);
                        setFieldValue('type', e.target.value);
                    }}>
                        {createMenuItems(FluidType)}
                      </Select>
                    </FormControl>
                  </DrawerFormRow>)}
                <DrawerFormRow item xs={6}>
                  <TextField variant="outlined" fullWidth name="cost" label={`${itemName} Cost`} type="number" placeholder={`${itemName} Cost`} onChange={(e) => {
                    onChangeInput('cost', true, true)(e);
                    setFieldValue('cost', e.target.value);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow item xs={6}>
                  <TextField variant="outlined" fullWidth name="price" label={`${itemName} Price`} type="number" placeholder={`${itemName} Price`} onChange={(e) => {
                    onChangeInput('price', true, true)(e);
                    setFieldValue('price', e.target.value);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow item xs={12}>
                  <TextField variant="outlined" fullWidth name="partNumber" label="Part Number" placeholder="Part Number" onChange={(e) => {
                    onChangeInput('partNumber', false)(e);
                    setFieldValue('partNumber', e.target.value);
                }}/>
                </DrawerFormRow>
                {values.coreChargeExpected && (<DrawerFormRow item xs={12}>
                    <TextField variant="outlined" type="number" name="coreChargeCost" label="Core Charge Cost" fullWidth InputProps={{
                        startAdornment: (<InputAdornment position="start">
                            <AttachMoneyRoundedIcon />
                          </InputAdornment>),
                    }} onChange={(e) => {
                        onChangeInput('coreChargeCost', true)(e);
                        setFieldValue('coreChargeCost', e.target.value);
                    }}/>
                  </DrawerFormRow>)}
                <DrawerFormRow item xs={12}>
                  <label htmlFor="pops-add-as-dynamic-component">
                    Additional Required Component
                  </label>{' '}
                  {!hasEngine && (<ToolTipInfo>
                      This repair order is missing Vehicle Information.
                      Additional Required Components cannot be selected without
                      full vehicle information.
                    </ToolTipInfo>)}
                  <CheckboxField id="pops-add-as-dynamic-component" name="addAsDynamicComponent" color="primary" disabled={!(values === null || values === void 0 ? void 0 : values.componentId) ||
                    !hasEngine ||
                    (fixedComponents || []).includes(values === null || values === void 0 ? void 0 : values.componentId)}/>
                </DrawerFormRow>
                {((_a = values === null || values === void 0 ? void 0 : values.name) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) !== 'tire' && (<SimpleRowButton color="primary" onClick={() => {
                        setAdditionalInfoOpen(true);
                    }}>
                    <AddCircle />
                    Additional Info
                  </SimpleRowButton>)}
              </Grid>
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <SimpleTwoButtonRow disabledSubmit={!isValid ||
                    isValidating ||
                    isSubmitting ||
                    (forceComponentSelection && !(values === null || values === void 0 ? void 0 : values.componentId))} disabledCancel={isSubmitting} submitText="Add To Cart" cancelText="Cancel" onCancel={() => onClose()} onSubmit={handleSubmit}/>
            </SimpleDrawerFooter>
            <AddComponentAdditionalInfo open={additionalInfoOpen} onClose={() => setAdditionalInfoOpen(false)} isFluid={isFluid} setFieldValue={setFieldValue} values={(_b = values === null || values === void 0 ? void 0 : values.additionalInfo) !== null && _b !== void 0 ? _b : []}/>
          </>);
        }}
    </Formik>);
};
export default AddComponentDrawerForm;
