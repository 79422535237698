var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { PrimaryButton } from 'src/components/buttons';
import { Grid } from 'src/components/mui/';
import { AddCircle as AddCircleIcon } from 'src/components/mui/icons';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, } from 'src/components/SimpleDrawer';
import { toast } from 'src/components/SimpleToast';
import DraweredSetup from './DraweredSetup';
import DraweredTemplate from './DraweredTemplate';
import DraweredWebhook from './DraweredWebhook';
import ExtraWebhookInfoModule from './ExtraWebhookInfoModule';
import { useSkeduloApiClient } from './skeduloApiClient';
import WebhookTemplates from './templates';
import WebhookCreateModule from './WebhookCreateModule';
import Webhooks from './Webhooks';
import { compareWebhooks, extractMetadata } from './webhookUtils';
const SkeduloWebhooks = () => {
    const [webhooks, setWebhooks] = useState([]);
    const [webhookDrawerOpened, setWebhookDrawerOpened] = useState(false);
    const [webhook, setWebhook] = useState();
    const [templateDrawerOpened, setTemplateDrawerOpened] = useState(false);
    const [template, setTemplate] = useState(null);
    const [createDrawerOpened, setCreateDrawerOpened] = useState(false);
    const [setupDrawerOpened, setSetupDrawerOpened] = useState(false);
    const skeduloApiClient = useSkeduloApiClient();
    const toggleTemplateDrawer = () => {
        setTemplateDrawerOpened(!templateDrawerOpened);
    };
    const templateClickHandler = (templateName) => {
        const displayedTemplate = WebhookTemplates[templateName];
        setTemplate(displayedTemplate);
        toggleTemplateDrawer();
    };
    const toggleWebhookDrawer = () => {
        setWebhookDrawerOpened(!webhookDrawerOpened);
    };
    const webhookClickHandler = (id) => {
        const displayedWebhook = webhooks.find((specificWebhook) => specificWebhook.id === id);
        toggleWebhookDrawer();
        setWebhook(displayedWebhook);
    };
    const toggleCreateDrawer = () => {
        setCreateDrawerOpened(!createDrawerOpened);
    };
    const toggleSetupDrawer = () => {
        setSetupDrawerOpened(!setupDrawerOpened);
    };
    const createWebhookHandler = (body) => __awaiter(void 0, void 0, void 0, function* () {
        yield skeduloApiClient
            .createWebhook(body)
            .then(fetchAllWebhooks)
            .catch((error) => toast.error(`Something went wrong: \n${error.message}`));
    });
    const deleteWebhookHandler = (id, name, wizard) => __awaiter(void 0, void 0, void 0, function* () {
        skeduloApiClient.deleteWebhook(id, name, wizard).then(fetchAllWebhooks);
    });
    const fetchAllWebhooks = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield skeduloApiClient.fetchWebhooks();
        const webhooks = response.result.map((webhook) => (Object.assign(Object.assign({}, webhook), { metadata: extractMetadata(webhook) })));
        webhooks.sort(compareWebhooks);
        setWebhooks(webhooks);
    });
    useEffect(() => {
        fetchAllWebhooks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<Grid container spacing={1}>
      <SimpleDrawer open={webhookDrawerOpened} onClose={toggleWebhookDrawer}>
        <DraweredWebhook webhook={webhook}/>
      </SimpleDrawer>
      <SimpleDrawer open={templateDrawerOpened} onClose={toggleTemplateDrawer}>
        <DraweredTemplate template={template}/>
      </SimpleDrawer>
      <SimpleDrawer title="Add New Webhook" onClose={toggleCreateDrawer} open={createDrawerOpened}>
        <SimpleDrawerContent>
          <WebhookCreateModule createWebhook={createWebhookHandler} toggleCreateDrawer={toggleCreateDrawer}/>
        </SimpleDrawerContent>
      </SimpleDrawer>
      <SimpleDrawer title="Setup Environment" onClose={toggleSetupDrawer} open={setupDrawerOpened}>
        <DraweredSetup createWebhook={createWebhookHandler} deleteWebhook={skeduloApiClient.deleteWebhook} toggleDrawer={toggleSetupDrawer} webhooks={webhooks}/>
      </SimpleDrawer>
      <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}>
        <Grid item xs={8} md={8}>
          <ExtraWebhookInfoModule templateClickHandler={templateClickHandler}/>
        </Grid>
        <Grid container item xs={2} direction="column" style={{
            minWidth: '100px',
            margin: '0 16px',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
        }}>
          <PrimaryButton onClick={toggleCreateDrawer} startIcon={<AddCircleIcon />} variant="outlined">
            Add Webhook
          </PrimaryButton>
        </Grid>
        <Grid container item xs={2} direction="column" style={{
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
        }}>
          <FooterActionButton onClick={toggleSetupDrawer}>
            Setup Environment
          </FooterActionButton>
        </Grid>
      </div>

      <Grid item xs={12}>
        <Webhooks webhooks={webhooks} deleteWebhook={deleteWebhookHandler} webhookClickHandler={webhookClickHandler} fetchWebhooks={fetchAllWebhooks}/>
      </Grid>
    </Grid>);
};
export default SkeduloWebhooks;
