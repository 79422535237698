import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
import { IconButton } from 'src/components/mui';
export const WorkOrdersTableEl = styled.table `
  width: calc(100% + 64px);
  border-collapse: collapse;
  position: relative;
  margin-left: -32px;

  thead tr {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    background-color: ${colors.GRAY_FA};
    z-index: 1;
    position: relative;
  }

  th {
    text-align: left;
    font-family: ${FONT_FAMILY};
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
    padding: 14px 0;

    &:first-child {
      padding-left: 41px;
    }
  }
`;
export const WorkOrderRow = styled.tr `
  td {
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_3F};
    border-bottom: solid 1px ${colors.GRAY_DE};
    padding: 32px 0;
    text-decoration: ${({ removed }) => (removed ? 'line-through' : 'none')};

    &:first-child {
      padding-left: 41px;
    }
  }

  li {
    text-decoration: ${({ removed }) => (removed ? 'line-through' : 'none')};
  }
`;
export const VinPlate = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.white};
`;
export const NicknameLabel = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.white};
`;
export const MoreIconButton = styled(IconButton) `
  padding: 5px 10px 5px 5px;
`;
