var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboardPagination, selectDashboardParams, } from 'src/AdminApp/modules/locks/selectors';
import { useEffect, useState } from 'react';
import { clearOptimizationRunsResults, getOptimizationRuns, resetOptimizationRunsFilters, updateOptimizationRun, } from 'src/AdminApp/modules/locks/actions';
import { toast } from 'src/components/SimpleToast';
const useLocksDashboard = () => {
    var _a;
    const dispatch = useDispatch();
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    const [currentFilter, setCurrentFilters] = useState();
    const [currentSort, setCurrentSort] = useState();
    const [pageSize, setPageSize] = useState((_a = pagination.pageSize) !== null && _a !== void 0 ? _a : 20);
    useEffect(() => {
        var _a, _b, _c;
        dispatch(getOptimizationRuns(Object.assign(Object.assign({}, params), { pageSize: (_a = pagination.pageSize) !== null && _a !== void 0 ? _a : 20, pageIndex: (_b = pagination.pageNumber) !== null && _b !== void 0 ? _b : 0, sort: (_c = pagination.sort) !== null && _c !== void 0 ? _c : ['created,desc', 'regionName,desc'] })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLoadData = ({ page, size, sort }) => {
        dispatch(getOptimizationRuns(Object.assign(Object.assign({}, params), { pageIndex: page, pageSize: size, sort, status: currentFilter })));
        setCurrentSort(sort);
    };
    const resetFilters = () => {
        dispatch(resetOptimizationRunsFilters());
        setCurrentFilters('ERROR');
    };
    const onClear = () => {
        dispatch(clearOptimizationRunsResults());
        setPageSize(20);
        setCurrentSort(['created']);
    };
    const onSearch = (status) => {
        dispatch(getOptimizationRuns({ pageIndex: 0, pageSize, sort: currentSort, status }));
        setCurrentFilters(status);
    };
    const handleUpdate = (item, status) => () => __awaiter(void 0, void 0, void 0, function* () {
        const lock = Object.assign(Object.assign({}, item), { status });
        const response = yield dispatch(updateOptimizationRun(lock));
        if (response && !response.error) {
            toast.success('Lock updated successfully');
        }
        onSearch(currentFilter);
    });
    return {
        onSearch,
        onClear,
        resetFilters,
        pagination,
        handleUpdate,
        onLoadData,
        setPageSize,
    };
};
export default useLocksDashboard;
