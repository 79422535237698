import moment from 'moment';
import pluralize from 'pluralize';
import React from 'react';
import CheckboxOrLabel from 'src/PopsApp/pages/Job/CheckboxOrLabel';
import SpecialOrderIndicator from 'src/PopsApp/components/SpecialOrderIndicator';
import { LOCALIZED_DATE_LONG_FORMAT } from 'src/timeConstants';
import { CarContainer, CarInfo, DetailsLabel, DetailsValue, Repairs, RequestDetails, RequestDetailsItem, JobItemRoot, SpecialOrderWrapper, } from './JobItem.styles';
const JobItem = ({ job, selectedJobs, onItemSelected, }) => {
    const { car } = job;
    return (<JobItemRoot key={job.referenceNum}>
      <CarContainer>
        <CarInfo htmlFor={`job-${job.referenceNum}`}>{`${car.carYear} ${car.make} ${car.model} • ${car.engineDescription}`}</CarInfo>
        <CheckboxOrLabel job={job} selectedJobs={selectedJobs} onItemSelected={onItemSelected}/>
      </CarContainer>
      <RequestDetails>
        <RequestDetailsItem>
          <DetailsLabel>Date</DetailsLabel>
          <DetailsValue>
            {moment(job.scheduleStartLocal).format(LOCALIZED_DATE_LONG_FORMAT)}
          </DetailsValue>
        </RequestDetailsItem>

        <RequestDetailsItem>
          <DetailsLabel>
            {pluralize('Part', job.componentsNeeded)} Needed
          </DetailsLabel>
          <DetailsValue>
            <span>
              {job.componentsNeeded} {pluralize('Part', job.componentsNeeded)}
            </span>
            {job.specialOrder && (<SpecialOrderWrapper>
                <SpecialOrderIndicator />
              </SpecialOrderWrapper>)}
          </DetailsValue>
        </RequestDetailsItem>
      </RequestDetails>

      <Repairs>
        <DetailsLabel>Repairs</DetailsLabel>
        <DetailsValue>{job.repairs.join(', ')}</DetailsValue>
      </Repairs>
      <div className="repairRefNum" style={{ display: 'none' }}>
        {job.referenceNum}
      </div>
    </JobItemRoot>);
};
export default JobItem;
