import { sortBy } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { RepairsTableHeader } from 'src/AdminApp/components/repairs/components/RepairDropdown';
import InitialPrice from 'src/AdminApp/components/repairs/statement/InitialPrice';
import { EditFluidDrawer, EditLaborDrawer, EditPartDrawer, } from 'src/AdminApp/components/repairs/statement/Repairs/Drawers';
import { AddComponentModal } from 'src/AdminApp/components/repairs/statement/Repairs/Modals';
import AddConsumerRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddConsumerRODrawer/AddConsumerRODrawer';
import AddFleetRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddFleetRODrawer';
import SelectInternalApprovalProviderFormModal from 'src/AdminApp/containers/repairs/SelectInternalApprovalProviderFormModal';
import Total from 'src/AdminApp/containers/requests/Quote/Total';
import useRecommendedRepairs from 'src/AdminApp/containers/requests/RecommendedRepairs/useRecommendedRepairs';
import AlertsAndDialogs from 'src/AdminApp/containers/requests/RecommendedRepairs/WorkflowComponents/AlertsAndDialogs';
import FmcWorkAuthorizationType from 'src/AdminApp/models/enums/FmcWorkAuthorizationType';
import { selectMpiInspectionByRepairRequestId } from 'src/AdminApp/modules/mpi/selectors';
import { ServiceDrawerName } from 'src/AdminApp/modules/requests/repairs.state';
import { selectCurrentUserIsEngineering, selectCurrentUserIsPartSpecialistI, selectCurrentUserIsPartSpecialistII, selectCurrentUserIsProduct, } from 'src/AdminApp/modules/users/selectors';
import { CopyToClipboardButton } from 'src/components/buttons';
import CollapsibleBlock from 'src/components/layout/CollapsibleBlock';
import { Button, Divider, Grid, Typography, useMediaQuery, } from 'src/components/mui';
import SimpleChip from 'src/components/SimpleChip';
import { Tooltip } from 'src/components/tooltips';
import VisitCapacityWarningModal from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { selectRoPriceEditRecRepairs } from 'src/modules/auth/selectors';
import { colors, theme } from 'src/styles/theme';
import { getConsumerUrl } from 'src/utils/nav-utils';
import styled from 'styled-components';
import RecommendedRepairItem from './RecommendedRepairItem';
import RejectRecommendedRepairSlider from './RejectRecommendedRepairSlider';
import { ActionItemsWrapper, CopyToClipboardWrapper, Subtotal, } from './styledComponents';
const Title = styled(Typography) `
  margin: 32px 0 12px;
`;
const ButtonRightWrapper = styled(Grid) `
  margin-left: 10px !important;
`;
const renderQuoted = (price) => {
    return <InitialPrice price={price}/>;
};
const RecommendedRepairs = ({ repairRequestId, vehicle, workOrderId, canEdit, hasInvoice, onSendForApproval, isSendForApprovalButtonEnabled, fmcWorkAuthorizationStatus, isSendForApprovalButtonVisible, fetchMPIFmcWorkAuthorizationStatus, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { referenceNum, selectorProps } = usePageReferenceNum();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const currentUserIsEngineering = useSelector(selectCurrentUserIsEngineering);
    const currentUserIsProduct = useSelector(selectCurrentUserIsProduct);
    const currentUserIsPartSpecialistI = useSelector(selectCurrentUserIsPartSpecialistI);
    const currentUserIsPartSpecialistII = useSelector(selectCurrentUserIsPartSpecialistII);
    const mpi = useSelector(selectMpiInspectionByRepairRequestId(repairRequestId));
    const canEditDistributor = hasInvoice ||
        currentUserIsEngineering ||
        currentUserIsProduct ||
        currentUserIsPartSpecialistI ||
        currentUserIsPartSpecialistII;
    const { setRecommendedRepairsDirty, recommendedRepairsDirty, hasRepairs, recommendedRepairs, scheduledRepairs, rejectedRepairs, createdRepairs, onRejectRecommendedRepair, onAddComponentToRecommendedRepair, onEditComponentToRecommendedRepair, onSelectRepair, selectedRepairs, onUndoRejectedRecommendedRepair, subTotal, isAbleToAddToCurrent, onAcceptRecommendedRepairs, areAllSelectedRepairsValid, areMeasurementsCompleteForSelectedRepairs, onScheduleAppointmentClick, selectedRecommendedRepairIds, shouldHaveAdditionalDetails, isRejectModalOpen, setIsRejectModalOpen, onRejectRecommendedRepairSubmit, isFleet, showScheduling, setShowScheduling, serviceDrawerState, onLaborSubmit, onLaborRemove, adding, onPartSubmit, onRemoveComponent, onFluidSubmit, isScheduleAppointmentApprovalOpen, isAddToCurrentApprovalOpen, closeApprovalDialog, submitInternalApprovalProvider, handleOpenAddFluid, handleOpenAddLabor, handleOpenAddPart, showCapacityWarning, handleCapacityWarningClose, capacityWarningMessage, requestData, fixedPartComponents, fixedFluidComponents, handleCloseServiceDrawer, } = useRecommendedRepairs({
        referenceNum,
        selectorProps,
        repairRequestId,
        fmcWorkAuthorizationStatus,
        workOrderId,
        fetchMPIFmcWorkAuthorizationStatus,
        hasInvoice,
    });
    const hasEngine = ((_a = requestData.car) === null || _a === void 0 ? void 0 : _a.engineId) && ((_b = requestData.car) === null || _b === void 0 ? void 0 : _b.engineId) !== 'i_dont_know';
    const canEditLaborAndPricing = useSelector(selectRoPriceEditRecRepairs);
    const title = (<Title variant="h5">
      Recommended Repairs
      {fmcWorkAuthorizationStatus && (<span style={{ paddingLeft: '10px' }}>
          <SimpleChip $bgColor={colors.orange} $textColor={colors.white} label={fmcWorkAuthorizationStatus}/>
        </span>)}
    </Title>);
    return (<>
      <AlertsAndDialogs repairRequestId={repairRequestId} onComplete={() => {
            setRecommendedRepairsDirty(false);
        }} recommendedRepairsDirty={recommendedRepairsDirty} vehicle={vehicle} hasRecommendedRepairs={hasRepairs(recommendedRepairs)}/>
      <CollapsibleBlock hashName="recommendedRepair" header={title} defaultOpen>
        {!hasRepairs(recommendedRepairs) &&
            !hasRepairs(scheduledRepairs) &&
            !hasRepairs(rejectedRepairs) &&
            !hasRepairs(createdRepairs) ? ('No Recommended Repairs') : (<>
            <RepairsTableHeader isRecommendedHeader/>
            <Divider />
            {recommendedRepairs &&
                sortBy(recommendedRepairs, ['created', 'name']).map((repair, i) => (<RecommendedRepairItem key={`${repair.repair}-${i}`} repair={repair} onRejectRecommendedRepair={onRejectRecommendedRepair} onAddComponentToRecommendedRepair={onAddComponentToRecommendedRepair} onEditComponentToRecommendedRepair={onEditComponentToRecommendedRepair} onSelectRepair={onSelectRepair} isSelected={selectedRepairs.indexOf(repair.id) !== -1} canEdit={canEdit} canEditLabor={canEditLaborAndPricing}/>))}
            {scheduledRepairs &&
                sortBy(scheduledRepairs, ['created', 'name']).map((repair) => (<RecommendedRepairItem repair={repair} key={repair.repair} canEdit={canEdit} canEditLabor={canEditLaborAndPricing}/>))}
            {createdRepairs &&
                sortBy(createdRepairs, ['created', 'name']).map((repair) => (<RecommendedRepairItem repair={repair} key={repair.repair} canEdit={canEdit} canEditLabor={canEditLaborAndPricing}/>))}
            {rejectedRepairs &&
                sortBy(rejectedRepairs, ['created', 'name']).map((repair) => (<RecommendedRepairItem key={repair.repair} onUndoRejectedRecommendedRepair={onUndoRejectedRecommendedRepair} repair={repair} canEdit={canEdit} canEditLabor={canEditLaborAndPricing}/>))}
            <Subtotal>
              <Grid item xs={7}/>
              <Grid item xs={5}>
                <Total recommendedRepairSubtotal={subTotal}/>
              </Grid>
            </Subtotal>
          </>)}
        <ActionItemsWrapper isMobile={isMobile}>
          {(mpi === null || mpi === void 0 ? void 0 : mpi.id) && (recommendedRepairs === null || recommendedRepairs === void 0 ? void 0 : recommendedRepairs.length) > 0 && (<CopyToClipboardWrapper>
              <CopyToClipboardButton value={getConsumerUrl(`my-repairs/car-health-reports/${mpi.id}`)}>
                Copy Customer Health Report Link
              </CopyToClipboardButton>
            </CopyToClipboardWrapper>)}
          <Button variant="contained" color="primary" disabled={selectedRepairs.length === 0 ||
            !canEdit ||
            hasInvoice ||
            !isAbleToAddToCurrent} onClick={() => onAcceptRecommendedRepairs({
            isValidateLaborCapacity: !!requestData.request.visit,
        })}>
            Add to Current
          </Button>
          <Button variant="contained" color="primary" disabled={selectedRepairs.length === 0 ||
            !areAllSelectedRepairsValid ||
            !areMeasurementsCompleteForSelectedRepairs ||
            !canEdit} onClick={() => onScheduleAppointmentClick()}>
            Schedule Appointment
          </Button>
          {isSendForApprovalButtonVisible && (<Tooltip disabled={!(!isSendForApprovalButtonEnabled ||
                !areMeasurementsCompleteForSelectedRepairs ||
                selectedRepairs.length === 0)} title="You cannot send for approval without the proper settings, completed measurements, and selected recommended repairs">
              <ButtonRightWrapper>
                <Button onClick={() => onSendForApproval(FmcWorkAuthorizationType.MPI, selectedRecommendedRepairIds)} disabled={!isSendForApprovalButtonEnabled ||
                !areMeasurementsCompleteForSelectedRepairs ||
                selectedRepairs.length === 0 ||
                shouldHaveAdditionalDetails} variant="contained" color="primary">
                  Send For Approval
                </Button>
              </ButtonRightWrapper>
            </Tooltip>)}
        </ActionItemsWrapper>
      </CollapsibleBlock>

      <RejectRecommendedRepairSlider open={!!isRejectModalOpen} onClose={() => setIsRejectModalOpen(false)} onSubmit={onRejectRecommendedRepairSubmit}/>

      {isFleet ? (<AddFleetRODrawer open={showScheduling} onClose={() => setShowScheduling(false)} referenceNum={referenceNum} isFollowUp/>) : (<AddConsumerRODrawer open={showScheduling} onClose={() => setShowScheduling(false)} referenceNum={referenceNum} context="Repair Request / Recommended Repairs" isFollowup isRecommendedRepair/>)}

      <EditLaborDrawer open={serviceDrawerState.drawerName === ServiceDrawerName.labor &&
            serviceDrawerState.isRecommended &&
            !serviceDrawerState.isCustom} onClose={handleCloseServiceDrawer} onSubmit={onLaborSubmit} onRemove={onLaborRemove} adding={adding} {...((_c = serviceDrawerState.repair) !== null && _c !== void 0 ? _c : {})}/>
      <EditPartDrawer open={serviceDrawerState.drawerName === ServiceDrawerName.part &&
            serviceDrawerState.isRecommended &&
            !serviceDrawerState.isCustom} {...serviceDrawerState.part} onClose={handleCloseServiceDrawer} onSubmit={onPartSubmit} adding={adding} onRemove={({ removeDynamicRule }) => onRemoveComponent(serviceDrawerState.repair.id, serviceDrawerState.part.id, 'part', removeDynamicRule)} totalCost={(_e = (_d = serviceDrawerState.repair) === null || _d === void 0 ? void 0 : _d.totalCost) !== null && _e !== void 0 ? _e : 0} quotedCost={renderQuoted(serviceDrawerState.repair)} canEditDistributor={canEditDistributor} isRecommendedRepair hasEngine={hasEngine} fixedComponents={fixedPartComponents} canEditPricing={canEditLaborAndPricing}/>
      <EditFluidDrawer open={serviceDrawerState.drawerName === ServiceDrawerName.fluid &&
            serviceDrawerState.isRecommended &&
            !serviceDrawerState.isCustom} {...serviceDrawerState.fluid} onClose={handleCloseServiceDrawer} onSubmit={onFluidSubmit} adding={adding} onRemove={({ removeDynamicRule }) => onRemoveComponent(serviceDrawerState.repair.id, serviceDrawerState.fluid.id, 'fluid', removeDynamicRule)} totalCost={(_g = (_f = serviceDrawerState.repair) === null || _f === void 0 ? void 0 : _f.totalCost) !== null && _g !== void 0 ? _g : 0} quotedCost={renderQuoted(serviceDrawerState.repair)} canEditDistributor={canEditDistributor} isRecommendedRepair hasEngine={hasEngine} fixedComponents={fixedFluidComponents} canEditPricing={canEditLaborAndPricing}/>
      <AddComponentModal open={serviceDrawerState.drawerName === ServiceDrawerName.addComponent &&
            serviceDrawerState.isRecommended &&
            !serviceDrawerState.isCustom} onClose={handleCloseServiceDrawer} displayLabor={serviceDrawerState.repair &&
            (!serviceDrawerState.repair.laborCost ||
                !serviceDrawerState.repair.laborTime)} onAddLabor={handleOpenAddLabor} onAddPart={handleOpenAddPart} onAddFluid={handleOpenAddFluid}/>

      <SelectInternalApprovalProviderFormModal open={isScheduleAppointmentApprovalOpen || isAddToCurrentApprovalOpen} onSubmit={submitInternalApprovalProvider} onClose={closeApprovalDialog} multipleRepairs={selectedRepairs.length > 1}/>
      <VisitCapacityWarningModal open={showCapacityWarning} onClose={handleCapacityWarningClose} warningMessage={capacityWarningMessage}/>
    </>);
};
export default RecommendedRepairs;
