var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Field, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Grid, TextField as MTextField, MenuItem } from 'src/components/mui';
import { SimpleModal } from 'src/components/layout';
import { TextField } from 'src/AdminApp/components/form';
import { LabeledGridField } from 'src/AdminApp/components/repairs/statement/styledComponents';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
const configTypes = ['NUMBER', 'TEXT'];
const ConfigModal = ({ open, onClose, onSubmit, id, name, value, type, }) => {
    const ConfigSchema = Yup.object().shape({
        id: Yup.string().min(1).max(255).required(),
        name: Yup.string().min(1).max(255).required(),
        value: Yup.string().min(1).max(255).required(),
        type: Yup.string().min(1).max(255).required(),
    });
    return (<SimpleModal title="Edit Custom Config" open={open} onClose={onClose} maxWidth="md">
      <Formik initialValues={{
            id,
            name: name || '',
            value: value || '',
            type: type || '',
        }} validationSchema={ConfigSchema} onSubmit={(values, { setSubmitting, resetForm }) => {
            onSubmit(Object.assign({}, values)).then(() => {
                resetForm({ values: Object.assign({}, values) });
                setSubmitting(false);
            });
        }}>
        {({ errors, handleSubmit, isValid, setFieldValue, touched }) => (<Grid>
            <LabeledGridField label="Name">
              <TextField type="string" name="name" fullWidth variant="standard"/>
            </LabeledGridField>
            <LabeledGridField label="Type">
              <Field name="type">
                {({ field }) => (<MTextField select value={field.value || ''} onChange={(event) => __awaiter(void 0, void 0, void 0, function* () {
                    var _a, _b;
                    const item = (_b = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
                    yield setFieldValue(field.name, item);
                })} helperText={touched.type ? errors.type : ''} error={touched.type && Boolean(errors.type)} variant="standard">
                    {configTypes.map((v) => (<MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>))}
                  </MTextField>)}
              </Field>
            </LabeledGridField>
            <LabeledGridField label="Value">
              <TextField type={type === 'NUMBER' ? 'number' : 'string'} name="value" fullWidth variant="standard"/>
            </LabeledGridField>
            <Grid item xs={12}>
              <SimpleTwoButtonRow disabledSubmit={!isValid} submitText="Save" onCancel={onClose} onSubmit={handleSubmit}/>
            </Grid>
          </Grid>)}
      </Formik>
    </SimpleModal>);
};
export default ConfigModal;
