import styled from 'styled-components';
import { TablePagination, Table, TableSortLabel } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
export const StyledTablePagination = styled(TablePagination) `
  color: ${colors.GRAY_76};
  .MuiTablePagination-toolbar {
    height: 100px;
    flex-wrap: wrap;
    justify-content: center;
  }

  ${theme.breakpoints.up('md')} {
    .MuiTablePagination-toolbar {
      height: 56px;
      min-height: 56px;
      justify-content: flex-end;
    }
  }
`;
export const PageNumber = styled.span `
  padding-right: 4px;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 'bold;' : 'normal;')};
  font-size: 16px;
  color: ${colors.GRAY_76};
`;
export const TableContainer = styled(Table) `
  position: relative;

  ${theme.breakpoints.down('lg')} {
    &.scrollable {
      display: block;
      overflow: auto;
    }
  }
`;
export const TableLoader = styled.div `
  position: absolute;
  top: 72px;
  left: calc(50% - 44px);
  background-color: ${colors.white};
  padding: 24px;
  border-radius: 50%;
  z-index: 100;
`;
export const TableSortTitle = styled(TableSortLabel) `
  color: ${colors.GRAY_76} !important;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? 700 : 400)};

  svg {
    fill: ${colors.GRAY_76};
  }
`;
export const tableRowMixin = `
  td {
    font-weight: 300;
    font-size: 15px;
    color: ${colors.GRAY_3F};
  }
  & > td  a {
    color: ${colors.linkBlue};

    &:visited {
      color: ${colors.celebrationPink};
    }
  }

  & > td  a {
    color: ${colors.linkBlue};

    &:visited {
      color: ${colors.celebrationPink};
    }
  }
`;
export const EmptyTable = styled.div `
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
