import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
export const ConfirmationWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 40px;

  h4 {
    font-family: ${FONT_FAMILY};
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.5px;
    text-align: center;
    color: ${colors.GRAY_3F};
    margin-top: 16px;
  }

  .CompletedOrder {
    &__partPickup {
      font-family: ${FONT_FAMILY};
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.1px;
      text-align: center;
      color: ${colors.GRAY_76};
      margin-top: 24px;
    }
  }
`;
export const ConfirmationIcon = styled.div `
  svg {
    height: 48px;
    width: 48px;
  }

  .iconSuccess {
    color: ${colors.success};
  }

  .iconError {
    color: ${colors.error};
  }
`;
export const ConfirmationBottom = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${colors.GRAY_76};
  padding: 32px 40px;
`;
export const ProgressWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px;
`;
export const JobsWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
`;
export const Job = styled.div `
  padding: 32px 0 0;

  .jobTitle {
    font-size: 14px;
    color: ${colors.GRAY_76};
  }
`;
export const JobPart = styled.div `
  font-size: 16px;
  padding: 26px 0;
  border-bottom: solid 1px ${colors.GRAY_DE};
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .iconError {
    fill: ${colors.RED};
  }
`;
export const OrderCompleteSubtitleText = styled.span `
  font-size: 14px;
  color: ${colors.GRAY_76};
  text-align: center;
  margin-top: 32px;
`;
export const JobInfoHeader = styled.div `
  padding: 0 0 32px;
  border-bottom: 1px solid ${colors.GRAY_DE};
  width: 100%;
`;
