import { toast } from 'src/components/SimpleToast';
import { forceReAuthentication, ActionCreators as AuthActionCreators, } from 'src/modules/auth/actions';
import { ActionCreators as ConsumerActionCreators } from 'src/AdminApp/modules/consumers/actions';
import { ActionCreators as InvoiceActionCreators } from 'src/AdminApp/modules/invoices/actions';
import { ActionCreators as MpiActionCreators } from 'src/AdminApp/modules/mpi/actions';
import { ActionCreators as RequestsActionCreators } from 'src/AdminApp/modules/requests/actions';
import { ActionCreators as RegionActionCreators } from 'src/AdminApp/modules/regions/actions';
import { ActionCreators as PopsVendorsActionCreators } from 'src/PopsApp/modules/vendors/actions';
import { ActionCreators as PricingRepairsActionCreators } from 'src/PricingApp/modules/repairs/actions';
const HTTP_STATUS_UNAUTHORIZED = 401;
const HTTP_STATUS_FORBIDDEN = 403;
const USER_ABORT_REQUEST_MSG = 'The user aborted a request.';
const NOOP_ACTION_HANDLER = () => { };
const CUSTOM_ACTION_HANDLERS = {
    [InvoiceActionCreators.INVOICE.FAILURE.type]: NOOP_ACTION_HANDLER,
    [MpiActionCreators.GET_INSPECTION_PERFORMED_STATUS.FAILURE.type]: NOOP_ACTION_HANDLER,
    [ConsumerActionCreators.CONSUMER_GET_NOTES.FAILURE.type]: NOOP_ACTION_HANDLER,
    [RequestsActionCreators.CAR_PHOTO.FAILURE.type]: (action) => {
        var _a, _b;
        const status = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.status;
        if (status === 400) {
            toast.error((_b = action.payload.response) === null || _b === void 0 ? void 0 : _b.message);
        }
        else if (Navigator.onLine) {
            toast.error('Photo Upload Failed');
        }
        else {
            toast.error("Photo upload saved for later. Please sync data when you're back online");
        }
        return NOOP_ACTION_HANDLER;
    },
    [ConsumerActionCreators.CONSUMERS.FAILURE.type]: (action) => {
        var _a;
        if (USER_ABORT_REQUEST_MSG !== ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.message)) {
            handleActionError(action);
        }
    },
    [RegionActionCreators.SERVICE_AREA_BY_ZIP_CODE.FAILURE.type]: NOOP_ACTION_HANDLER,
    [RequestsActionCreators.REQUEST_BY_REFERENCE_NUM.FAILURE.type]: NOOP_ACTION_HANDLER,
    [RequestsActionCreators.UPDATE_REPAIR_COMPONENTS.FAILURE.type]: NOOP_ACTION_HANDLER,
    [RequestsActionCreators.ACCEPT_RECOMMENDED_REPAIR.FAILURE.type]: NOOP_ACTION_HANDLER,
    [RequestsActionCreators.ADD_REPAIR.FAILURE.type]: NOOP_ACTION_HANDLER,
    [PopsVendorsActionCreators.POPS_GET_CATALOG_PARTS.FAILURE.type]: NOOP_ACTION_HANDLER,
    [PopsVendorsActionCreators.POPS_GET_CATALOG_FLUIDS.FAILURE.type]: NOOP_ACTION_HANDLER,
    [PricingRepairsActionCreators.GET_DEFAULT_RESPONSIBLE_PARTY_INFO.FAILURE
        .type]: NOOP_ACTION_HANDLER,
};
const handleActionError = ({ type, payload, meta }) => {
    var _a, _b, _c, _d, _e;
    const messages = [payload === null || payload === void 0 ? void 0 : payload.message];
    if (type === AuthActionCreators.REFRESH_TOKEN.FAILURE.type) {
        const refreshTokenFailureMessage = ((_a = payload === null || payload === void 0 ? void 0 : payload.response) === null || _a === void 0 ? void 0 : _a.message) === 'Refresh access token request failed.'
            ? 'You have been logged out due to inactivity.'
            : (_b = payload === null || payload === void 0 ? void 0 : payload.response) === null || _b === void 0 ? void 0 : _b.message;
        messages.push(refreshTokenFailureMessage);
    }
    else {
        messages.push((_c = payload === null || payload === void 0 ? void 0 : payload.response) === null || _c === void 0 ? void 0 : _c.message);
        messages.push(`\nURL: ${(meta === null || meta === void 0 ? void 0 : meta.url) || ((_d = payload.response) === null || _d === void 0 ? void 0 : _d.path)}\n`);
    }
    if ((_e = payload === null || payload === void 0 ? void 0 : payload.response) === null || _e === void 0 ? void 0 : _e.fieldErrors) {
        const fieldErrors = payload.response.fieldErrors
            .map((fieldError) => `${fieldError.field}: ${fieldError.message}`)
            .join(', ');
        messages.push(fieldErrors);
    }
    const lines = messages.filter(Boolean);
    const errorMessage = lines.length > 0 ? lines.join('\n') : 'API error';
    toast.error(errorMessage);
};
const apiErrorMiddleware = ({ dispatch }) => (next) => (action) => {
    var _a;
    if (action && action.error) {
        if ([HTTP_STATUS_UNAUTHORIZED, HTTP_STATUS_FORBIDDEN].includes((_a = action.payload) === null || _a === void 0 ? void 0 : _a.status)) {
            handleActionError(action);
            dispatch(forceReAuthentication());
        }
        else if (CUSTOM_ACTION_HANDLERS[action.type]) {
            CUSTOM_ACTION_HANDLERS[action.type](action);
        }
        else {
            handleActionError(action);
        }
    }
    return next(action);
};
export default apiErrorMiddleware;
