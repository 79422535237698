import React from 'react';
import { Form, Formik } from 'formik';
import { Button, ButtonGroup, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Typography, } from 'src/components/mui';
import compact from 'src/utils/compact';
import NotAuthorized from 'src/components/layout/NotAuthorized';
import SkillSearchMultiSelect from 'src/components/form/SkillSearchMultiSelect';
import FormDivider from 'src/PricingApp/components/forms/FormDivider';
import FormInput from 'src/PricingApp/components/forms/FormInput';
import FormSelect from 'src/PricingApp/components/forms/FormSelect';
import ReindexSearchButton from 'src/SystemApp/pages/elasticsearch/ElasticsearchIndexer/ReindexSearchButton';
import SearchIndexType from 'src/SystemApp/pages/elasticsearch/ElasticsearchIndexer/SearchIndexType';
import { Toggle } from 'src/AdminApp/containers/fleets/tabs/styles';
import { colors } from 'src/styles/theme';
import EditRepairComponentDrawer from './repair-components/EditRepairComponentForm/EditRepairComponentDrawer';
import RepairComponentSearch from './repair-components/RepairComponentSearch';
import RepairComponentTable from './repair-components/RepairComponentTable';
import SearchLevelSelect from './SearchLevelSelect';
import BaseProductIdSelect from './BaseProductIdSelect';
import { addRepairContextValidation } from './validations';
import FleetsAutocomplete from './FleetsAutocomplete';
import MeasurementsAutocomplete from './MeasurementsAutocomplete/MeasurementsAutocomplete';
import useAddRepairForm, { DEFAULT_VALUES } from './useAddRepairForm';
const AddRepairForm = ({ repairId }) => {
    const { repair, isPricingAdmin, responsiblePartyTypes, responsibleParties, isEditRepairComponentOpen, editableRepairComponent, isAllFleetsMapping, availableSkills, showRPT, setShowRPT, responsiblePartyTypeId, setResponsiblePartyTypeId, responsiblePartyId, setResponsiblePartyId, showRptError, setShowRptError, handleEditRepairComponent, handleCloseEditRepairComponent, handleUpdateRepairComponent, onSearchRepairComponents, onSearchBaseProducts, onSaveOrUpdateRepair, addComponentToRepair, addDynamicComponentToRepair, handleAddFleetMapping, handleRemoveFleetMapping, handleAllFleetsMappingChange, } = useAddRepairForm({ repairId });
    const renderRepairInfo = (values, setFieldValue) => {
        var _a, _b, _c, _d, _e;
        return (<>
      <Grid item>
        <Typography variant="h6">Repair Data</Typography>
      </Grid>
      <FormInput placeholder="autogenerated" label="Repair ID" valueName="repair.id" disabled variant="standard"/>
      <FormInput label="Base Product ID">
        <BaseProductIdSelect onSearchBaseProducts={onSearchBaseProducts} currentRepairId={(_a = values.repair) === null || _a === void 0 ? void 0 : _a.id} baseProduct={(_b = values.repair) === null || _b === void 0 ? void 0 : _b.baseProduct} setBaseProduct={(newBaseProductId) => setFieldValue('repair.baseProduct', newBaseProductId)}/>
      </FormInput>
      <FormInput placeholder="example: Synthetic Oil Change" label="Repair Name" valueName="repair.name" required variant="standard"/>
      <FormInput label="Repair Type">
        <FormSelect required options={['REPAIR', 'INSPECTION', 'PREVENTATIVE_MAINTENANCE']} value={(_c = values.repair) === null || _c === void 0 ? void 0 : _c.repairType} setValue={(newValue) => setFieldValue('repair.repairType', newValue)}/>
      </FormInput>
      <FormInput label="Repair Category">
        <FormSelect required options={['GENERAL', 'MAINTENANCE', 'SPECIAL', 'DIAGNOSIS']} value={(_d = values.category) === null || _d === void 0 ? void 0 : _d.name} setValue={(newValue) => setFieldValue('category.name', newValue)}/>
      </FormInput>
      <FormInput tooltip="This value is used in Zendesk" label="Repair Level">
        <FormSelect required options={[1, 2, 3]} value={(_e = values.productDetails) === null || _e === void 0 ? void 0 : _e.repairLevel} setValue={(newValue) => setFieldValue('productDetails.repairLevel', newValue)}/>
      </FormInput>
      <FormDivider />
    </>);
    };
    const renderVisibility = (values, setFieldValue) => {
        var _a, _b;
        return (<>
      <FormInput label="Repair Status">
        <FormSelect required options={['ACTIVE', 'INACTIVE']} value={(_a = values.repair) === null || _a === void 0 ? void 0 : _a.status} setValue={(newValue) => setFieldValue('repair.status', newValue)}/>
      </FormInput>
      <FormInput label="Search Levels">
        <SearchLevelSelect activeLevels={(_b = values.productDetails) === null || _b === void 0 ? void 0 : _b.searchLevels} setActiveLevels={(newSearchLevels) => setFieldValue('productDetails.searchLevels', newSearchLevels)}/>
      </FormInput>
      <FormDivider />
    </>);
    };
    const renderFleetMapping = (values, setFieldValue) => {
        var _a;
        return (<>
      <FormInput label="All Fleets">
        <Checkbox checked={isAllFleetsMapping} onChange={(event) => {
                const newMapping = handleAllFleetsMappingChange(event.target.checked, values.repair.mappedFleets);
                setFieldValue('repair.mappedFleets', newMapping);
            }}/>
      </FormInput>
      <FormInput label="Fleet Mapping">
        <FleetsAutocomplete repairId={(_a = repair === null || repair === void 0 ? void 0 : repair.repair) === null || _a === void 0 ? void 0 : _a.id} editable={!isAllFleetsMapping} handleAddMapping={(value) => {
                const newMapping = handleAddFleetMapping(value, values.repair.mappedFleets);
                setFieldValue('repair.mappedFleets', newMapping);
            }} handleRemoveMapping={(value) => {
                const newMapping = handleRemoveFleetMapping(value, values.repair.mappedFleets);
                setFieldValue('repair.mappedFleets', newMapping);
            }}/>
      </FormInput>
      <FormDivider />
    </>);
    };
    const renderConciergeRules = (values, setFieldValue) => {
        var _a, _b;
        return (<>
      <FormInput label="Force Concierge">
        <Checkbox value={(_a = values.conciergeRules) === null || _a === void 0 ? void 0 : _a.force} checked={(_b = values.conciergeRules) === null || _b === void 0 ? void 0 : _b.force} onChange={(event) => setFieldValue('conciergeRules.force', event.target.checked)}/>
      </FormInput>
      <FormInput placeholder="example: 0" label="Concierge Min Price" valueName="conciergeRules.minPrice" type="number" required variant="standard"/>
      <FormInput placeholder="example: 5000" label="Concierge Max Price" valueName="conciergeRules.maxPrice" type="number" required variant="standard"/>
      <FormDivider />
    </>);
    };
    const renderPricingDetails = (values, setFieldValue, errors = {}) => {
        var _a, _b, _c, _d;
        return (<>
      <FormInput label="Exempt from recycling">
        <Checkbox value={(_a = values.productDetails) === null || _a === void 0 ? void 0 : _a.pricingRuleExempt} checked={(_b = values.productDetails) === null || _b === void 0 ? void 0 : _b.pricingRuleExempt} onChange={(event) => setFieldValue('productDetails.pricingRuleExempt', event.target.checked)}/>
      </FormInput>
      <FormInput label="Prevent labor editing">
        <Checkbox value={(_c = values.productDetails) === null || _c === void 0 ? void 0 : _c.preventLaborEditing} checked={(_d = values.productDetails) === null || _d === void 0 ? void 0 : _d.preventLaborEditing} onChange={(event) => setFieldValue('productDetails.preventLaborEditing', event.target.checked)}/>
      </FormInput>
      <FormInput placeholder="example: 1.5 (hours)" label="Default Reserved Time" valueName="productDetails.defaultReservedTime" type="number" inputProps={{ step: 0.1 }} required helperText={(errors === null || errors === void 0 ? void 0 : errors.defaultReservedTime) ? errors === null || errors === void 0 ? void 0 : errors.defaultReservedTime : ''} error={errors === null || errors === void 0 ? void 0 : errors.defaultReservedTime} variant="standard"/>
      <FormInput placeholder="example: 1.5 (hours)" label="Override Reserved Time" valueName="productDetails.overrideReservedTime" type="number" inputProps={{ step: 0.1 }} variant="standard"/>
      <FormInput tooltip="The discount applies to OEM parts for vehicles with MEDIUM residual value" placeholder="example: 0.10" label="Aftermarket Parts Discount 1" valueName="aftermarketPartDiscount.amDiscountPercent1" type="number" inputProps={{ step: 0.01 }} variant="standard"/>
      <FormInput tooltip="The discount applies to OEM parts for vehicles with LOW residual value" placeholder="example: 0.18" label="Aftermarket Parts Discount 2" valueName="aftermarketPartDiscount.amDiscountPercent2" type="number" inputProps={{ step: 0.01 }} variant="standard"/>
      <FormDivider />
    </>);
    };
    const renderCatalogs = () => (<>
      <FormInput placeholder="example: 11303" label="'MOTOR' catalog external id" valueName="catalogDetails[0].externalId" type="number" variant="standard"/>
      <FormDivider />
    </>);
    const renderAutointegrateServiceCode = (values, setFieldValue) => {
        var _a;
        const { fmcRepairMappings } = values.repair;
        const currentFmcRepairIndex = fmcRepairMappings
            ? fmcRepairMappings.findIndex((fmcRepairMapping) => fmcRepairMapping.fmcType === 'AUTO_INTEGRATE')
            : -1;
        const currentFmcRepair = currentFmcRepairIndex !== -1
            ? fmcRepairMappings[currentFmcRepairIndex]
            : { fmcType: 'AUTO_INTEGRATE', fmcId: '' };
        const isNewRepair = !((_a = repair === null || repair === void 0 ? void 0 : repair.repair) === null || _a === void 0 ? void 0 : _a.id);
        return (<>
        <FormInput disabled={isNewRepair} placeholder={isNewRepair ? 'Only available when editing' : 'example: 166'} label="Autointegrate service code" value={currentFmcRepair.fmcId} type="number" variant="standard" onChange={(event) => {
                currentFmcRepair.fmcId = event.target.value;
                if (fmcRepairMappings.length === 0 && currentFmcRepair.fmcId) {
                    setFieldValue('repair.fmcRepairMappings', [currentFmcRepair]);
                }
                else {
                    fmcRepairMappings[currentFmcRepairIndex] = currentFmcRepair;
                    setFieldValue('repair.fmcRepairMappings', fmcRepairMappings);
                }
            }}/>
        <FormDivider />
      </>);
    };
    const renderTagsMultiSelect = (values, setFieldValue) => {
        const selectedSkills = availableSkills.filter((availableSkill) => values.repair.skills.includes(availableSkill.id));
        return (<>
        <SkillSearchMultiSelect options={availableSkills} selectedOptions={selectedSkills || []} name="tags" label="Skills" onUpdate={(updatedSkills) => setFieldValue('repair.skills', updatedSkills.map((skill) => skill.id))} unremovableOptions={[]}/>
        <Grid item container>
          <div style={{ marginTop: 10 }}/>
        </Grid>
      </>);
    };
    const renderComponents = (values, setFieldValue) => (<>
      <Grid item container xs={12}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Typography variant="h6" paragraph>
            Repair components
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RepairComponentSearch onSearchRepairComponents={onSearchRepairComponents} onAddToRepair={(component) => addComponentToRepair(setFieldValue, values, component)}/>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <RepairComponentTable values={values} setFieldValue={setFieldValue} handleEditRepairComponent={handleEditRepairComponent} componentType="FIXED"/>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Typography variant="h6" paragraph>
            Dynamic components
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RepairComponentSearch onSearchRepairComponents={onSearchRepairComponents} onAddToRepair={(component) => addDynamicComponentToRepair(setFieldValue, values, component)}/>
        </Grid>
        <Grid item xs={12}>
          <RepairComponentTable values={values} setFieldValue={setFieldValue} handleEditRepairComponent={handleEditRepairComponent} componentType="DYNAMIC"/>
        </Grid>
      </Grid>
      <FormDivider />
    </>);
    const renderFormButtons = (resetForm) => (<Grid item container xs={12} style={{ marginTop: 5, justifyContent: 'space-between' }}>
      <Grid item>
        <ReindexSearchButton indexType={SearchIndexType.BOTH} color="primary" size="large"/>
      </Grid>
      <Grid item>
        <ButtonGroup variant="contained" size="large" style={{ margin: '8px 0' }}>
          <Button color="primary" onClick={resetForm} style={{ marginRight: 8 }}>
            Reset Form
          </Button>
          <Button color="primary" type="submit">
            Save Repair
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>);
    const renderMeasurementRequirements = () => (<>
      <MeasurementsAutocomplete repairId={repairId}/>
      <FormDivider />
    </>);
    const renderRequiresApprovalToggle = (values, setFieldValue) => (<>
      <FormInput label="Requires Approval">
        <Toggle checked={values.productDetails.requiresApproval} color="primary" name="requiresApproval" onChange={(e) => setFieldValue('productDetails.requiresApproval', e.target.checked)}/>
      </FormInput>
      <FormDivider />
    </>);
    return isPricingAdmin ? (<Formik enableReinitialize initialValues={Object.assign(Object.assign({}, DEFAULT_VALUES), compact(repair))} validate={addRepairContextValidation} onSubmit={onSaveOrUpdateRepair}>
      {({ resetForm, values, setFieldValue, errors, handleSubmit }) => {
            var _a;
            return (<Form onSubmit={handleSubmit}>
            <Grid container style={{ marginTop: 5, maxWidth: 1300 }}>
              <Grid item container xs={12} lg={8} spacing={2}>
                {renderRepairInfo(values, setFieldValue)}
                {renderVisibility(values, setFieldValue)}
                {renderFleetMapping(values, setFieldValue)}
                {renderConciergeRules(values, setFieldValue)}
                {renderPricingDetails(values, setFieldValue, {
                    defaultReservedTime: (_a = errors === null || errors === void 0 ? void 0 : errors.productDetails) === null || _a === void 0 ? void 0 : _a.defaultReservedTime,
                })}
                {renderCatalogs()}
                {renderAutointegrateServiceCode(values, setFieldValue)}
                {renderTagsMultiSelect(values, setFieldValue)}
                {renderMeasurementRequirements()}
                {renderRequiresApprovalToggle(values, setFieldValue)}
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  {!showRPT && (<Button onClick={() => {
                        setShowRPT(true);
                    }} variant="text" style={{
                        padding: '16px 0',
                        marginTop: 16,
                        color: colors.primary,
                    }}>
                      Add Responsible Party Type
                    </Button>)}

                  {showRPT && (<>
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <FormControl fullWidth>
                          <InputLabel id="rpt-select-label" variant="outlined">
                            Responsible Party Type
                          </InputLabel>
                          {responsiblePartyTypes && (<Select label="Responsible Party Type" labelId="rpt-select-label" id="rpt-simple-select" variant="outlined" onChange={(event) => {
                            setResponsiblePartyTypeId(event.target.value);
                            setResponsiblePartyId('');
                        }} value={responsiblePartyTypeId}>
                              <MenuItem disabled value="">
                                Responsible Party Type
                              </MenuItem>
                              {responsiblePartyTypes.map((rpt) => (<MenuItem key={rpt.id} value={rpt.id}>
                                    {rpt.name}
                                  </MenuItem>))}
                            </Select>)}
                        </FormControl>
                      </Grid>
                      {responsiblePartyTypeId && responsibleParties && (<Grid item xs={12} style={{ marginTop: 20 }}>
                          <FormControl fullWidth>
                            <InputLabel id="rp-select-label" variant="outlined">
                              Responsible Party
                            </InputLabel>
                            {responsibleParties && (<Select label="Responsible Party" labelId="rp-select-label" id="rp-simple-select" variant="outlined" onChange={(event) => {
                                setResponsiblePartyId(event.target.value);
                                setShowRptError(false);
                            }} value={responsiblePartyId}>
                                <MenuItem disabled value="">
                                  Responsible Party
                                </MenuItem>
                                {responsibleParties.map((rp) => (<MenuItem key={rp.id} value={rp.id}>
                                    {rp.name}
                                  </MenuItem>))}
                                {responsibleParties.length === 0 && (<MenuItem value="">
                                    No Responsible Parties
                                  </MenuItem>)}
                              </Select>)}
                          </FormControl>
                        </Grid>)}
                      <Grid item xs={12} style={{ marginTop: 10 }}>
                        <Button onClick={() => {
                        setResponsiblePartyTypeId('');
                        setResponsiblePartyId('');
                        setShowRPT(false);
                        setShowRptError(false);
                    }} variant="text" style={{
                        padding: '0',
                        marginTop: 16,
                        color: colors.error,
                    }}>
                          Remove
                        </Button>
                      </Grid>
                      {showRptError && (<Grid item xs={12} style={{
                            color: colors.error,
                            marginTop: 10,
                            fontSize: 15,
                            textAlign: 'right',
                        }}>
                          Choose responsible party
                        </Grid>)}
                    </>)}
                </Grid>
                <FormDivider />

                {renderComponents(values, setFieldValue)}
                {renderFormButtons(resetForm)}
              </Grid>
            </Grid>
            <EditRepairComponentDrawer open={isEditRepairComponentOpen} onClose={handleCloseEditRepairComponent} onSubmit={handleUpdateRepairComponent(values, setFieldValue)} repairComponent={editableRepairComponent} repair={repair === null || repair === void 0 ? void 0 : repair.repair}/>
          </Form>);
        }}
    </Formik>) : (<NotAuthorized targetRole="Pricing_Admin"/>);
};
export default AddRepairForm;
