import React from 'react';
import moment from 'moment';
import { Grid, TimePicker, StaticDatePicker } from 'src/components/mui';
import { FieldWrapperTop } from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/AddFleetVisitDrawer.styles';
import { useDispatch, useSelector } from 'react-redux';
import { getTechnicians, setAddVisitEndDateTime, setAddVisitStartDateTime, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { selectAddVisitDurationTime, selectAddVisitLocation, selectAddVisitStartDateTime, } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import useIsMobile from 'src/hooks/useIsMobile';
import { DATE_TIME_FORMAT, DATE_YMD_FORMAT } from 'src/timeConstants';
import { VisitCalendar } from './VisitDateTimePicker.styles';
const VisitDateTimePicker = ({ disabled }) => {
    var _a;
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const selectedStartDateTime = useSelector(selectAddVisitStartDateTime);
    const addVisitDurationTime = (_a = useSelector(selectAddVisitDurationTime)) !== null && _a !== void 0 ? _a : '';
    const selectedLocation = useSelector(selectAddVisitLocation);
    const handleDateTimeChange = (value) => {
        if (!(value === null || value === void 0 ? void 0 : value.isValid())) {
            return;
        }
        dispatch(setAddVisitStartDateTime(value));
        // RECALCULATE END DATE BASED ON CURRENT DURATION SELECTED
        const durationParts = addVisitDurationTime.split(':');
        const hours = durationParts[0];
        const minutes = durationParts[1];
        dispatch(setAddVisitEndDateTime(value.clone().add(hours, 'hours').add(minutes, 'minutes')));
        if (selectedLocation) {
            dispatch(getTechnicians({
                startDate: value.format(DATE_YMD_FORMAT),
                zip: selectedLocation.zip,
                timezone: selectedLocation.timezone,
                startWithTime: moment(value)
                    .tz(selectedLocation.timezone)
                    .format(DATE_TIME_FORMAT),
            }));
        }
    };
    const noop = (date, isFinish) => { }; // eslint-disable-line @typescript-eslint/no-unused-vars
    return (<Grid container style={{ justifyContent: 'space-between' }}>
      <Grid item xs={12}>
        <VisitCalendar isMobile={isMobile}>
          <StaticDatePicker value={selectedStartDateTime} disableFuture={disabled} disablePast onChange={disabled ? noop : handleDateTimeChange} slots={{
            toolbar: () => null,
            actionBar: () => null,
        }}/>
        </VisitCalendar>
      </Grid>
      <Grid item xs={12}>
        <FieldWrapperTop>
          <TimePicker label="Enter Start Time" value={selectedStartDateTime} onChange={handleDateTimeChange} disabled={disabled} minutesStep={15} slotProps={{
            textField: {
                variant: 'outlined',
                margin: 'normal',
                id: 'time-picker',
                style: {
                    width: isMobile ? '100%' : 448,
                },
            },
        }}/>
        </FieldWrapperTop>
      </Grid>
    </Grid>);
};
export default VisitDateTimePicker;
