import { AttachMoneyRounded as AttachMoneyRoundedIcon } from '@mui/icons-material';
import { Grid, InputAdornment, MenuItem } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { SlidePageContent, SlidePageWrapper, } from 'src/AdminApp/components/discounts/components/DiscountDrawer/styledComponents';
import TextField from 'src/AdminApp/components/form/TextField';
import { selectDiscountReasonOtherType, selectDiscountReasonTypes, } from 'src/AdminApp/modules/lookups/selectors';
import { FooterActionButton, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import useIsMobile from 'src/hooks/useIsMobile';
import * as Yup from 'yup';
const CustomDiscountPage = ({ onDrawerClose, onSubmit, }) => {
    const isMobile = useIsMobile();
    const discountReasonTypes = useSelector(selectDiscountReasonTypes);
    const otherReason = useSelector(selectDiscountReasonOtherType);
    const validationSchema = Yup.object().shape({
        reasonDescription: Yup.string().when('reasonId', {
            is: otherReason === null || otherReason === void 0 ? void 0 : otherReason.id,
            then: Yup.string().required('Description is required.'),
            otherwise: Yup.string().nullable(),
        }),
        reasonId: Yup.number().required(),
        discountedAmount: Yup.number().min(0).required(),
    }, [['reasonDescription', 'reasonId']]);
    return (<Formik initialValues={{
            reasonId: '',
            discountedAmount: null || '',
            reasonDescription: null || '',
        }} onSubmit={({ reasonId, discountedAmount, reasonDescription }, { setSubmitting }) => {
            const discountReasonType = discountReasonTypes.find((r) => Number(reasonId) === r.id);
            onSubmit({
                discountReasonType,
                discountedAmount,
                reasonDescription,
            });
            setSubmitting(false);
            onDrawerClose();
        }} validationSchema={validationSchema}>
      {({ isSubmitting, values, isValid }) => (<SlidePageWrapper>
          <SlidePageContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField type="text" select fullWidth name="reasonId" label="Discount Reason" variant="outlined" SelectProps={{ native: isMobile }}>
                  {discountReasonTypes.map(({ id, internalReason }) => isMobile ? (<option value={id} key={id}>
                        {internalReason}
                      </option>) : (<MenuItem value={id} key={id}>
                        {internalReason}
                      </MenuItem>))}
                </TextField>
              </Grid>
              {Number(values.reasonId) === (otherReason === null || otherReason === void 0 ? void 0 : otherReason.id) && (<Grid item xs={12}>
                  <TextField fullWidth name="reasonDescription" type="text" label="Discount Reason Description" variant="outlined"/>
                </Grid>)}
              <Grid item xs={12}>
                <TextField fullWidth name="discountedAmount" type="number" inputType="number" label="Discount Amount" variant="outlined" InputProps={{
                startAdornment: (<InputAdornment position="start">
                        <AttachMoneyRoundedIcon />
                      </InputAdornment>),
            }}/>
              </Grid>
            </Grid>
          </SlidePageContent>
          <SimpleDrawerFooter>
            <FooterActionButton disabled={isSubmitting || !isValid} type="submit">
              Add
            </FooterActionButton>
          </SimpleDrawerFooter>
        </SlidePageWrapper>)}
    </Formik>);
};
export default CustomDiscountPage;
