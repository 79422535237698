import React, { forwardRef, useCallback, useEffect, useMemo, useState, } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useDoubleTap } from 'use-double-tap';
import { debounce } from 'lodash';
import { ArrowBack, Cancel as CancelIcon } from 'src/components/mui/icons';
import { Dialog, Divider, IconButton, InputBase, List, ListItem, ListItemText, Paper, Slide, TextField, Autocomplete, } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import { selectPartDistributorsAddressSearchResults } from 'src/PopsApp/modules/vendors/selectors';
import { searchPartDistributorLocationsByAddress } from 'src/PopsApp/modules/vendors/actions';
import useIsMobile from 'src/hooks/useIsMobile';
import { FieldWrapper } from './components';
const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props}/>;
});
const StyledPaper = styled(Paper) `
  display: flex;
  border-radius: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16),
    inset 0 -2px 0 0 ${colors.primary};
`;
const getLocationLabel = (location) => {
    if (!location)
        return '';
    return `${location.street1}, ${location.street2 ? `${location.street2}, ` : ''}${location.city}, ${location.state} ${location.zip}`;
};
const EditableSearchPartDistributorLocations = ({ partDistributorId, value, defaultValue = 'None', onUpdate, style = null, }) => {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const dispatch = useDispatch();
    const locations = useSelector(selectPartDistributorsAddressSearchResults);
    const [loading, setLoading] = useState(false);
    const onDoubleClick = () => setOpen(true);
    const onDoubleTap = useDoubleTap(onDoubleClick);
    const options = useMemo(() => {
        if (!inputValue.length)
            return [];
        return locations !== null && locations !== void 0 ? locations : [];
    }, [inputValue, locations]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(debounce((partialAddress) => {
        if (!partialAddress.length)
            return;
        setLoading(true);
        dispatch(searchPartDistributorLocationsByAddress({
            partialAddress,
            dealershipName: null,
            partDistributorId,
        })).then(() => {
            setLoading(false);
        });
    }, 500), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => debouncedSearch(inputValue), [inputValue]);
    const onSubmit = (location) => {
        onUpdate(getLocationLabel(location), location);
        setInputValue('');
    };
    return (<>
      {open && !isMobile && (<Autocomplete style={{ width: '100%', minHeight: '25px' }} open={open} onOpen={() => {
                setOpen(true);
            }} onClose={(e, reason) => {
                if (reason === 'toggleInput')
                    return;
                setOpen(false);
            }} options={options} getOptionLabel={getLocationLabel} loading={loading} onInputChange={(event, newValue) => setInputValue(newValue)} onChange={(e, location) => {
                onSubmit(location);
            }} renderInput={(params) => (<TextField {...params} InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })} autoFocus/>)}/>)}
      {isMobile && (<Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
          <StyledPaper component="form">
            <IconButton onClick={() => setOpen(false)}>
              <ArrowBack />
            </IconButton>
            <InputBase fullWidth placeholder="Search" value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
            <IconButton>
              <CancelIcon onClick={() => inputValue === '' ? setOpen(false) : setInputValue('')}/>
            </IconButton>
          </StyledPaper>

          <List>
            {options.map((option) => (<div key={`div-${option.id.toString()}`}>
                <ListItem key={`li-${option.id.toString()}`} button onClick={() => {
                    onSubmit(option);
                    setOpen(false);
                }}>
                  <ListItemText primary={option.street1}/>
                </ListItem>
                <Divider key={`divider-${option.id.toString()}`}/>
              </div>))}
          </List>
        </Dialog>)}
      {!open && (<FieldWrapper style={style} isEditable onDoubleClick={onDoubleClick} {...onDoubleTap}>
          {value !== null && value !== void 0 ? value : defaultValue}
        </FieldWrapper>)}
    </>);
};
export default EditableSearchPartDistributorLocations;
