import React from 'react';
import { PaymentDescription, PaymentStatus } from 'src/AdminApp/models/enums';
import { formatTimestamp } from 'src/utils/formatter';
import { TableCell, TableRow } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import PaymentDescriptionChip from 'src/AdminApp/containers/payments/PaymentModule/PaymentDescriptionChip';
import CurrencyLabel from 'src/components/CurrencyLabel';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import { PaymentAttribute, CollectedBy, } from 'src/AdminApp/containers/payments/PaymentModule/PaymentsTable/styledComponents';
import { InfoOutlined as InfoOutlinedIcon } from 'src/components/mui/icons';
import { selectPaymentRefundSubmitter } from 'src/modules/auth/selectors';
import { useSelector } from 'react-redux';
import DropdownOptions from '../DropdownOptions';
const PaymentRow = ({ payment, onRemovePayment }) => {
    var _a;
    const { id, amount, paymentMethodId, collectedBy, status, description, referenceNumber = null, type, subType, created, removed, refundReason, errorMessage, } = payment;
    const charged = [
        PaymentDescription.PARTIAL_PAYMENT_BY_SPREEDLY,
        PaymentDescription.FULL_PAYMENT_BY_SPREEDLY,
        PaymentDescription.DECLINED,
        PaymentDescription.FAILED,
    ].includes(description);
    const isFailedStatus = PaymentStatus.FAILED === status;
    const isRefundable = Boolean(paymentMethodId) && type.id === 2;
    const isRefundDescription = description === null || description === void 0 ? void 0 : description.includes('REFUND');
    const isRefundedStatus = PaymentStatus.REFUNDED === status || isRefundDescription;
    const isPreAuthorization = description === null || description === void 0 ? void 0 : description.includes('PRE_AUTHORIZATION');
    const handleRemovePayment = (paymentId) => {
        onRemovePayment(paymentId, amount, isRefundable);
    };
    const isPaymentRefundSubmitter = useSelector(selectPaymentRefundSubmitter);
    const canRefund = isPaymentRefundSubmitter &&
        !isRefundedStatus &&
        !removed &&
        isRefundable &&
        !charged &&
        !isFailedStatus &&
        !isPreAuthorization;
    const canDelete = !removed &&
        !isRefundedStatus &&
        !isFailedStatus &&
        !isRefundable &&
        !charged &&
        !isPreAuthorization;
    const isVoided = (_a = payment.transactions) === null || _a === void 0 ? void 0 : _a.find((transaction) => transaction.type === 'VOID' && transaction.state === 'SUCCEEDED');
    return (<TableRow>
      <TableCell>
        <PaymentAttribute removed={removed}>
          {formatTimestamp(created)}
        </PaymentAttribute>
        <PaymentAttribute removed={removed}>
          <CollectedBy>
            {isRefundDescription ? 'Refunded' : 'Collected'} By:{' '}
            <AdminUser subText={false} updatedUser={collectedBy}/>
          </CollectedBy>
        </PaymentAttribute>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex' }}>
          <PaymentDescriptionChip removed={removed} status={status} description={isVoided && !description.includes('PRE_AUTHORIZATION')
            ? 'VOID'
            : description}/>
          {refundReason && (<Tooltip title={refundReason} style={{ fontSize: '20px' }} placement="top">
              <InfoOutlinedIcon style={{
                cursor: 'pointer',
                paddingLeft: 8,
            }}/>
            </Tooltip>)}
        </div>
      </TableCell>
      <TableCell>{errorMessage}</TableCell>
      <TableCell>
        <PaymentAttribute removed={removed}>
          {type &&
            `${type.displayName}${subType ? ` • ${subType.displayName}` : ''}`}
        </PaymentAttribute>
      </TableCell>
      <TableCell>
        <PaymentAttribute removed={removed}>{referenceNumber}</PaymentAttribute>
      </TableCell>
      <TableCell align="right">
        <PaymentAttribute removed={removed}>
          <CurrencyLabel>{amount}</CurrencyLabel>
        </PaymentAttribute>
      </TableCell>
      <TableCell align="right">
        {canRefund && (<DropdownOptions component="payment" id={id} label="Refund Payment" onRemove={handleRemovePayment}/>)}
        {canDelete && (<DropdownOptions component="payment" id={id} label="Delete Payment" onRemove={handleRemovePayment}/>)}
      </TableCell>
    </TableRow>);
};
export default PaymentRow;
