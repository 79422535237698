import { capitalize } from 'lodash';
import React from 'react';
import { getSkeduloUrl } from 'src/AdminApp/utils/url-utils';
import { addressObjectToString } from 'src/clients/mapboxClient';
import { Typography } from 'src/components/mui';
import { CalendarToday as CalendarTodayIcon, ExpandMore as ExpandMoreIcon, LocationOn as LocationOnIcon, Phone as PhoneIcon, WatchLater as WatchLaterIcon, } from 'src/components/mui/icons';
import { SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleLoader from 'src/components/SimpleLoader';
import { colors } from 'src/styles/theme';
import { AppointmentInfoSection, PartPickupAccordion, PartPickupField, PartPickupSection, PartPickupSectionAccordionDetails, PartPickupSectionContent, PhoneLink, PurchaseOrdersTitle, RefNumberSpan, StyledAccordionSummary, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
import usePartPickupOrderDetails from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/usePartPickupOrderDetails';
import PartPickupOrderItem from 'src/TechApp/components/AppointmentList/PartPickupOrderItem';
import PartPickupCTAContainer from 'src/TechApp/components/PartPickupTask/PartPickupCTAContainer';
import { groupByRepairRequest } from 'src/TechApp/utils';
import { formatMilitaryToStandardTime, getFormattedDate, getFormattedTime, } from 'src/utils/formatter';
const PartPickupOrderDetails = ({ partPickupOrderId, address, goToChecklist, goToRescheduleCancel, goToPartPickupTransfer, onClose, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { partPickupOrder, isLoadingHubs, isPickupAtHub, vendorName, phone, currentLocation, completeCurrentTask, hasHubs, } = usePartPickupOrderDetails({ partPickupOrderId });
    return (<>
      {!partPickupOrder && !isLoadingHubs ? (<SimpleLoader />) : (<SimpleDrawerContent>
          <>
            <h2>
              {isPickupAtHub
                ? 'RepairSmith Hub'
                : (_a = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _a === void 0 ? void 0 : _a.partDistributorName}
            </h2>
            {vendorName && (<h4 style={{ padding: '0 16px', marginTop: 0 }}>{vendorName}</h4>)}
            <PartPickupSection>
              <RefNumberSpan>No</RefNumberSpan>
              <PartPickupSectionContent>
                {partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum}
              </PartPickupSectionContent>
            </PartPickupSection>
            <PartPickupSection>
              <LocationOnIcon htmlColor={colors.lightGrey}/>
              <PartPickupSectionContent>
                {addressObjectToString(address)}
              </PartPickupSectionContent>
            </PartPickupSection>
            {phone && (<PartPickupSection>
                <PhoneLink href={phone ? `tel:${phone}` : undefined}>
                  <PhoneIcon htmlColor={colors.lightGrey}/>
                  <PartPickupSectionContent>{phone}</PartPickupSectionContent>
                </PhoneLink>
              </PartPickupSection>)}
            <PartPickupAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <WatchLaterIcon htmlColor={colors.lightGrey}/>
                <Typography style={{ marginLeft: '8px' }}>
                  Hours of Operation
                </Typography>
              </StyledAccordionSummary>
              <PartPickupSectionAccordionDetails>
                {(_c = (_b = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _b === void 0 ? void 0 : _b.hoursOfOperation) === null || _c === void 0 ? void 0 : _c.map((hours, index) => (<AppointmentInfoSection key={index}>
                      <PartPickupField>
                        {capitalize(hours.weekday)}
                      </PartPickupField>
                      <span>
                        {hours.open
                    ? `${formatMilitaryToStandardTime(hours.openTime)} - ${formatMilitaryToStandardTime(hours.closeTime)}`
                    : 'Closed'}
                      </span>
                    </AppointmentInfoSection>))}
              </PartPickupSectionAccordionDetails>
            </PartPickupAccordion>
            <PartPickupAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <CalendarTodayIcon htmlColor={colors.lightGrey}/>
                <Typography style={{ marginLeft: '8px' }}>
                  Appointment Info
                </Typography>
              </StyledAccordionSummary>
              <PartPickupSectionAccordionDetails>
                <AppointmentInfoSection>
                  <PartPickupField>Date</PartPickupField>
                  <span>
                    {getFormattedDate((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.scheduledFor) || '')}
                  </span>
                </AppointmentInfoSection>
                <AppointmentInfoSection>
                  <PartPickupField>Time</PartPickupField>
                  <span>
                    {getFormattedTime((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.scheduledFor) || '')}
                  </span>
                  &nbsp; - &nbsp;
                  <span>
                    {getFormattedTime((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.scheduledEndTime) || '')}
                  </span>
                </AppointmentInfoSection>
                <AppointmentInfoSection>
                  <PartPickupField>Techs</PartPickupField>
                  <span>
                    {(_e = (_d = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.resources) === null || _d === void 0 ? void 0 : _d.filter((resource) => resource.category === 'MECHANIC')) === null || _e === void 0 ? void 0 : _e.map((resource) => resource.name).join(', ')}
                  </span>
                </AppointmentInfoSection>
                <AppointmentInfoSection>
                  <PartPickupField>Vans</PartPickupField>
                  <span>
                    {(_g = (_f = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.resources) === null || _f === void 0 ? void 0 : _f.filter((resource) => resource.category === 'VAN')) === null || _g === void 0 ? void 0 : _g.map((resource) => resource.name).join(', ')}
                  </span>
                </AppointmentInfoSection>
                <AppointmentInfoSection>
                  <PartPickupField>Skedulo</PartPickupField>
                  <span>
                    <a rel="noreferrer" href={getSkeduloUrl(`/job/${partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.skeduloJobId}`)} target="_blank">
                      {partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.skeduloJobId}
                    </a>
                  </span>
                </AppointmentInfoSection>
              </PartPickupSectionAccordionDetails>
            </PartPickupAccordion>
            <PartPickupAccordion data-last="true">
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <PurchaseOrdersTitle>Purchase Orders</PurchaseOrdersTitle>
              </StyledAccordionSummary>
              <PartPickupSectionAccordionDetails style={{ margin: 0, padding: 0 }}>
                {groupByRepairRequest((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.items) || []).map((group) => (<PartPickupOrderItem key={group.repairRequestReferenceNum} repairRequestReferenceNum={group.repairRequestReferenceNum} items={group.items}/>))}
              </PartPickupSectionAccordionDetails>
            </PartPickupAccordion>
          </>
        </SimpleDrawerContent>)}

      <SimpleDrawerFooter className="CTA-render-tech-dashboard">
        <PartPickupCTAContainer address={address} currentLocation={currentLocation} completeCurrentTask={completeCurrentTask} goToChecklistPage={goToChecklist} goToRescheduleCancel={goToRescheduleCancel} goToPartPickupTransfer={goToPartPickupTransfer} onClose={onClose} hasHubs={hasHubs}/>
      </SimpleDrawerFooter>
    </>);
};
export default PartPickupOrderDetails;
