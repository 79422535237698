import { updateInspectionPerformedStatus } from 'src/AdminApp/modules/mpi/actions';
import { setShowMpiSentToCustomerAlert } from 'src/AdminApp/modules/requests/actions';
export const updateInspectionStatus = ({ dispatch, inspectionPerformedStatus, currentUserIsSA, currentUser, currentUserId, sentToCustomer = false, referenceNum, forceUpdateSA = false, currentUserIsSAEditor = false, updateSA = false, updateSAEdit = false, }) => {
    const updatedDate = new Date();
    const payload = {
        lastSent: null,
        lastSentByName: null,
        lastSentByUserId: null,
        serviceAdvisorLastSaved: null,
        serviceAdvisorName: null,
        serviceAdvisorUserId: null,
    };
    if (updateSAEdit) {
        payload.serviceAdvisorLastSaved = updatedDate;
    }
    // ADD SERVICE ADVISOR NAME AND ID IF NOT PRESENT
    if ((!inspectionPerformedStatus.serviceAdvisorUserId ||
        (forceUpdateSA && currentUserIsSAEditor)) &&
        (currentUserIsSA || currentUserIsSAEditor) &&
        updateSA) {
        payload.serviceAdvisorName = `${currentUser.firstName} ${currentUser.lastName}`;
        payload.serviceAdvisorUserId = currentUserId;
        payload.serviceAdvisorLastSaved = updatedDate;
    }
    if (sentToCustomer) {
        payload.lastSent = updatedDate;
        payload.lastSentByName = `${currentUser.firstName} ${currentUser.lastName}`;
        payload.lastSentByUserId = currentUserId;
    }
    dispatch(updateInspectionPerformedStatus({
        repairRefNum: referenceNum,
        body: payload,
    }));
    // SHOW ALERT NOW
    if (sentToCustomer) {
        dispatch(setShowMpiSentToCustomerAlert(true, { referenceNum }));
    }
};
