var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Yup from 'yup';
import { validateYupSchema, yupToFormErrors } from 'formik';
const requiredMessage = (name) => `${name} is required`;
const minLengthMessage = (name, min) => `${name} must be at least ${min} characters`;
const maxLengthMessage = (name, max) => `${name} must be at most ${max} characters`;
const positiveIntegerMessage = (name) => `${name} must be a positive integer`;
export const positiveInteger = (name) => {
    return nullableNumber()
        .required(requiredMessage(name))
        .positive(positiveIntegerMessage(name))
        .integer(positiveIntegerMessage(name));
};
export const requiredString = (name) => {
    return Yup.string().nullable().trim().required(requiredMessage(name));
};
export const limitedString = (name, min, max) => {
    return requiredString(name)
        .min(min, minLengthMessage(name, min))
        .max(max, maxLengthMessage(name, max));
};
/**
 Formik requires optional inputs to be '' instead of null, even for number fields.
 This functions helps validate such inputs
 */
export const nullableNumber = () => {
    return Yup.number()
        .nullable()
        .transform((v, o) => (o === '' ? null : v));
};
export const addContextValidation = (values, schema) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield validateYupSchema(values, schema, true, values);
    }
    catch (err) {
        return yupToFormErrors(err); // for rendering validation errors
    }
    return {};
});
