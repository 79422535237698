import React from 'react';
import PlacesAutocomplete from 'src/components/form/PlacesAutocomplete';
import { MenuItem, Skeleton, TextField } from 'src/components/mui';
import useIsMobile from 'src/hooks/useIsMobile';
import AddressDialog from 'src/PopsApp/pages/Vendors/AddressDialog';
import VendorSearchResults from 'src/PopsApp/pages/Vendors/VendorSearchResults';
import { LoadingWrapper, VehicleLocation, VendorSearchHeadline, VendorSearchWrapper, } from './VendorSearch.styles';
import useVendorSearch from './useVendorSearch';
const VendorSearch = ({ onVendorSubmit }) => {
    var _a;
    const { loading, showAddressDialog, gmapsApiLoaded, selectedPartDistributorId, setSelectedPartDistributorId, selectedJobs, partDistributors, searchResults, vehicleLocation, handleAddressDialogClose, fetchVendorComponentAvailability, handleAddressChange, handleShowAddressDialog, } = useVendorSearch();
    const isMobile = useIsMobile();
    return (<VendorSearchWrapper fullScreen={isMobile}>
      <VendorSearchHeadline>
        Input van location to search nearby stores.
      </VendorSearchHeadline>

      <VehicleLocation>
        {!isMobile && gmapsApiLoaded && (<>
            <PlacesAutocomplete displayMap={false} variant="outlined" alignItems="flex-start" address={vehicleLocation !== null && vehicleLocation !== void 0 ? vehicleLocation : ''} label="Search Vehicle Location" searchOptions={{
                componentRestrictions: { country: 'us' },
                types: ['address'],
            }} onSelect={handleAddressChange}/>
            {partDistributors && (<TextField select label="Part Distributor" variant="outlined" className="mobileVehicleLocationInput" onChange={(e) => {
                    e.preventDefault();
                    setSelectedPartDistributorId(e.target.value);
                }} value={selectedPartDistributorId}>
                <MenuItem value={undefined}>Show All</MenuItem>
                {partDistributors === null || partDistributors === void 0 ? void 0 : partDistributors.map((pd) => (<MenuItem key={pd.id} value={pd.id}>
                    {pd.name}
                  </MenuItem>))}
              </TextField>)}
          </>)}
        {isMobile && (<TextField label="Search Vehicle Location" variant="outlined" className="mobileVehicleLocationInput" onClick={handleShowAddressDialog} value={(_a = vehicleLocation === null || vehicleLocation === void 0 ? void 0 : vehicleLocation.addressStr) !== null && _a !== void 0 ? _a : ''}/>)}
      </VehicleLocation>

      {searchResults && !loading && (<VendorSearchResults onVendorSubmit={onVendorSubmit} onVendorFetchAvailability={fetchVendorComponentAvailability} selectedJobs={selectedJobs !== null && selectedJobs !== void 0 ? selectedJobs : []} searchResults={searchResults}/>)}

      {loading && (<LoadingWrapper>
          <Skeleton variant="rectangular" width="100%" height={100}/>
          <Skeleton variant="rectangular" width="100%" height={100}/>
          <Skeleton variant="rectangular" width="100%" height={100}/>
          <Skeleton variant="rectangular" width="100%" height={100}/>
        </LoadingWrapper>)}

      <AddressDialog open={showAddressDialog} onClose={handleAddressDialogClose} placeholder="Search Vehicle Location"/>
    </VendorSearchWrapper>);
};
export default VendorSearch;
