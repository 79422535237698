import styled from 'styled-components';
import { Tab, Tabs, Grid } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const SlideWrapper = styled.div `
  height: var(--full-height, auto);
  padding-top: 16px;

  @media screen and (max-height: 500px) {
    padding-bottom: 150px;
  }
`;
export const UserFieldsWrapper = styled.div `
  margin-top: 40px;
`;
export const ContactInfoWrapper = styled.div `
  margin: 16px 0 24px;
  display: flex;
  align-items: center;

  & svg {
    color: ${colors.iconGrey};
    margin-right: 8px;

    &:nth-child(2n) {
      margin-left: 8px;
    }
  }
`;
export const MobileContactInfoWrapper = styled.div `
  & svg {
    color: ${colors.iconGrey};
    margin-right: 8px;
  }
`;
export const UserItem = styled.div `
  padding: 19px 0;
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
`;
export const AddressFieldsWrapper = styled.div `
  margin: 24px 0px;
  position: relative;
  display: flex;
  align-items: center;
`;
export const LocationIconWrapper = styled.div `
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  background-color: ${colors.backgroundIconGrey};
  align-items: center;
  justify-content: center;
  position: absolute;
`;
export const AddressTypography = styled.div `
  font-size: 17px;
  color: ${colors.darkGrey};
`;
export const SelectAddressButton = styled.div `
  color: ${colors.primary};
  margin-top: 16px;
  font-weight: 500;
  cursor: pointer;
`;
export const SearchAddressTitle = styled.div `
  color: ${colors.default};
  font-size: 14px;
  margin-bottom: 24px;
`;
export const Header = styled.div `
  width: 100%;
  height: 56px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${colors.midnightBlue};
  padding: 16px;
  position: relative;
  align-items: center;
`;
export const SlidesContainer = styled.div `
  position: relative;
  overflow-y: auto;
  width: 100%;
  flex: 1 1 auto;
`;
export const ContentWrapper = styled.div `
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.5px;
  display: flex;
  flex-flow: column;
`;
export const FormGridItemWrapper = styled(Grid) `
  ${({ $vertical }) => $vertical
    ? 'margin-bottom: 16px;'
    : `
      padding-right: 16px;
      margin-bottom: 16px;

      &:nth-child(2n) {
        padding-right: 0px;
      }

      @media (max-width: 960px) {
        padding-right: 0px;
      }
    `}
`;
export const SubHeaderWrapper = styled.div `
  padding: 24px 0 0 24px;
`;
export const UserId = styled.span `
  color: ${colors.middleGrey};
`;
export const SubHeader = styled(Tabs) `
  height: 48px;
  background: ${(props) => props.color || colors.secondary};

  & .MuiTabs-indicator {
    background-color: ${colors.darkBlue};
  }
`;
export const StyledTab = styled(Tab) `
  text-transform: none;
  width: 50%;
  ${({ $freeWidth }) => $freeWidth && 'width: auto;'}
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${({ $isActive }) => ($isActive ? colors.primary : colors.default)};
`;
