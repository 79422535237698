import React, { useEffect } from 'react';
import { Grid, MenuItem } from 'src/components/mui';
import { DrawerFormRow } from 'src/components/SimpleDrawer';
import { TextField } from 'src/AdminApp/components/form';
const positionOptions = [
    'FL',
    'FR',
    'RL',
    'RR',
    'RLI',
    'RRI',
    'RFLI',
    'RFL',
    'RFRI',
    'RFR',
    'S',
    'U',
];
const EditPartDrawerAdditional = ({ values, setFieldValue, canEdit = true, setHasMetadataInfo, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    useEffect(() => {
        var _a;
        const hasMetadata = Object.keys((_a = values === null || values === void 0 ? void 0 : values.partMetadata) !== null && _a !== void 0 ? _a : {}).some((item) => (values === null || values === void 0 ? void 0 : values.partMetadata[item]) !== '');
        setHasMetadataInfo(hasMetadata);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);
    return (<Grid container spacing={1}>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_a = values.partMetadata) === null || _a === void 0 ? void 0 : _a.manufacturer} label="Manufacturer e.g. GOODYEAR" type="text" name="manufacturer" fullWidth onChange={(event) => setFieldValue('partMetadata.manufacturer', event.target.value)} disabled={!canEdit}/>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_b = values.partMetadata) === null || _b === void 0 ? void 0 : _b.model} label="Model e.g. G647 RSS" type="text" name="model" fullWidth onChange={(event) => setFieldValue('partMetadata.model', event.target.value)} disabled={!canEdit}/>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_c = values.partMetadata) === null || _c === void 0 ? void 0 : _c.width} type="number" name="width" label="Width e.g. 205" fullWidth onChange={(event) => setFieldValue('partMetadata.width', isNaN(event.target.valueAsNumber)
            ? ''
            : event.target.valueAsNumber)} disabled={!canEdit}/>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_d = values.partMetadata) === null || _d === void 0 ? void 0 : _d.aspectRatio} type="number" name="aspectRatio" label="Aspect Ratio e.g. 70" fullWidth onChange={(event) => setFieldValue('partMetadata.aspectRatio', isNaN(event.target.valueAsNumber)
            ? ''
            : event.target.valueAsNumber)} disabled={!canEdit}/>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_e = values.partMetadata) === null || _e === void 0 ? void 0 : _e.rimDiameter} type="number" name="rimDiameter" label="Rim Diameter e.g. 19.5" fullWidth onChange={(event) => setFieldValue('partMetadata.rimDiameter', isNaN(event.target.valueAsNumber)
            ? ''
            : event.target.valueAsNumber)} disabled={!canEdit}/>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_f = values.partMetadata) === null || _f === void 0 ? void 0 : _f.loadRange} type="text" name="loadRange" label="Load Range e.g. G" fullWidth onChange={(event) => setFieldValue('partMetadata.loadRange', event.target.value)} disabled={!canEdit}/>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_g = values.partMetadata) === null || _g === void 0 ? void 0 : _g.speedRating} type="text" name="speedRating" label="Speed Rating e.g. S" fullWidth onChange={(event) => setFieldValue('partMetadata.speedRating', event.target.value)} disabled={!canEdit}/>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_h = values.partMetadata) === null || _h === void 0 ? void 0 : _h.size} type="text" name="size" label="Size e.g. 225/70R19.5" fullWidth onChange={(event) => setFieldValue('partMetadata.size', event.target.value)} disabled={!canEdit}/>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_j = values.partMetadata) === null || _j === void 0 ? void 0 : _j.position} select name="position" label="Position" fullWidth onChange={(event) => setFieldValue('partMetadata.position', event.target.value)} disabled={!canEdit}>
          <MenuItem value="">&nbsp;</MenuItem>
          {positionOptions.map((item, index) => (<MenuItem key={index} value={item}>
              {item}
            </MenuItem>))}
        </TextField>
      </DrawerFormRow>
    </Grid>);
};
export default EditPartDrawerAdditional;
