/* eslint-disable react/no-unstable-nested-components */
import { RadioGroup } from 'src/components/mui';
import { Formik } from 'formik';
import { map } from 'lodash';
import React from 'react';
import { RadioButtonField } from 'src/AdminApp/components/form/RadioButton';
import TextField from 'src/AdminApp/components/form/TextField';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const ValidationSchema = Yup.object().shape({
    otherResolution: Yup.string().when('resolution', {
        is: 'OTHER',
        then: Yup.string().required('Reason is required.'),
        otherwise: Yup.string().nullable(),
    }),
    resolution: Yup.string().required(),
}, [['resolution', 'otherResolution']]);
const StateRadioForm = ({ submitForm = () => Promise.resolve(), paramName = 'resolution', params = {}, onClose = () => { }, options = [], }) => {
    return (<Formik enableReinitialize initialValues={{
            resolution: null,
            otherResolution: null,
        }} validateOnMount validationSchema={ValidationSchema} onSubmit={(values, { setSubmitting }) => {
            const { resolution, otherResolution } = values;
            const submit = Object.assign(Object.assign({ [paramName]: resolution }, params), (resolution === 'OTHER' && { otherResolution }));
            submitForm(submit);
            onClose();
            setSubmitting(false);
        }}>
      {({ isValid, handleSubmit, values, isSubmitting, setFieldValue }) => (<>
          <SimpleDrawerContent>
            <RadioGroup id="resolution" value={values.resolution}>
              {map(options, (option) => (<RadioButtonField name="resolution" id={option.id} label={option.label} onChange={(e) => {
                    if (e.target.value !== 'OTHER') {
                        setFieldValue('otherResolution', undefined);
                    }
                }} helperField={values.resolution === 'OTHER' && option.id === 'OTHER' ? (<TextField fullWidth name="otherResolution" type="text" label="Reason" variant="outlined"/>) : null}/>))}
            </RadioGroup>
          </SimpleDrawerContent>

          <SimpleDrawerFooter>
            <FooterActionButton onClick={handleSubmit} disabled={!isValid || isSubmitting}>
              Save
            </FooterActionButton>
          </SimpleDrawerFooter>
        </>)}
    </Formik>);
};
export default StateRadioForm;
