import React from 'react';
import GridContainer from './Grid.styles';
const Grid = ({ noItemsText, hasItems, children }) => {
    return (<GridContainer>
      <div className="gridContent">
        {!hasItems ? children : <div>{noItemsText !== null && noItemsText !== void 0 ? noItemsText : 'No items'}</div>}
      </div>
    </GridContainer>);
};
export default Grid;
