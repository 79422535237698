import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, } from 'src/components/mui';
const cleanString = (value) => {
    if (typeof value !== 'string') {
        return value;
    }
    return value.replace(/_/g, ' ');
};
const FormSelect = ({ value = '', setValue, options, required = false, label = null, }) => {
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (<FormControl fullWidth>
      {label && <InputLabel>{label}</InputLabel>}
      <Select variant="standard" required={required} value={value} onChange={handleChange}>
        {options.map((option) => (<MenuItem key={option} value={option}>
            {cleanString(option)}
          </MenuItem>))}
      </Select>
    </FormControl>);
};
export default FormSelect;
