import { Chip, Grid, Autocomplete } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import { TextField } from 'src/AdminApp/components/form';
import AppliesTo from 'src/AdminApp/models/enums/AppliesTo';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const SkillsDrawer = ({ open, editSkill, editMode, onClose, onUpdateSkill, onAddSkill, }) => {
    const appliesToOptions = Object.values(AppliesTo.values);
    const validationSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(255, 'Too Long!').required(),
    });
    const onSubmit = (skill) => {
        if (editSkill) {
            onUpdateSkill({
                id: skill.id || '',
                name: skill.name || '',
                appliesTo: skill.appliesTo || [],
                skeduloTagId: skill.skeduloTagId || '',
            });
        }
        else {
            onAddSkill(skill);
        }
    };
    return (<SimpleDrawer open={open} onClose={onClose} title={`${editMode ? 'Edit' : 'Add New'} Skill`}>
      <Formik validateOnMount validationSchema={validationSchema} initialValues={{
            id: editSkill === null || editSkill === void 0 ? void 0 : editSkill.id,
            name: (editSkill === null || editSkill === void 0 ? void 0 : editSkill.name) || '',
            appliesTo: (editSkill === null || editSkill === void 0 ? void 0 : editSkill.appliesTo) || [],
            skeduloTagId: (editSkill === null || editSkill === void 0 ? void 0 : editSkill.skeduloTagId) || '',
        }} onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onSubmit(values);
        }}>
        {({ isSubmitting, values, setFieldValue, isValid }) => (<>
            <SimpleDrawerContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField id="add-edit-skill-name" name="name" label="Name" variant="outlined" placeholder="Name" type="string"/>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete multiple value={values.appliesTo} options={appliesToOptions.filter((appliesToOption) => !values.appliesTo.includes(appliesToOption))} onChange={(e, newValue) => setFieldValue('appliesTo', newValue)} getOptionLabel={(option) => option} renderTags={(selectedOption, selectedOptionProps) => {
                return selectedOption.map((option, index) => (<Chip label={option} {...selectedOptionProps({ index })}/>));
            }} renderInput={(params) => (<TextField name="applies-to-field" {...params} variant="outlined" label="Applies to" InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>
                </Grid>
                {editMode && (<Grid item xs={12}>
                    <TextField id="edit-skedulo-id" name="skeduloTagId" label="Skedulo Tag Id" variant="outlined" placeholder="Skedulo Tag Id" type="string" disabled/>
                  </Grid>)}
              </Grid>
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <FooterActionButton onClick={() => onSubmit(values)} disabled={!isValid} isSubmitting={isSubmitting}>
                Save
              </FooterActionButton>
            </SimpleDrawerFooter>
          </>)}
      </Formik>
    </SimpleDrawer>);
};
export default SkillsDrawer;
