import { createDefaultApiActionCreators } from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import CONFIG from 'src/config';
import createAction from 'src/modules/api/utils/createAction';
export const ActionCreators = {
    GET_PARTNER_ASSETS: createDefaultApiActionCreators('GET_PARTNER_ASSETS'),
    GET_PARTNER_ASSETS_BY_ID: createDefaultApiActionCreators('GET_PARTNER_ASSETS_BY_ID'),
    UPLOAD_PARTNER_ASSETS: createDefaultApiActionCreators('UPLOAD_PARTNER_ASSETS'),
    DELETE_PARTNER_ASSET: createDefaultApiActionCreators('DELETE_PARTNER_ASSET'),
};
export const getPartnerAssets = ({ partnerId }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_PARTNER_ASSETS),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/partners/${partnerId}/assets`,
    method: 'GET',
});
export const getPartnerAssetById = ({ partnerId, assetId, }) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_PARTNER_ASSETS_BY_ID),
        endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/partners/${partnerId}/assets/${assetId}`,
        method: 'GET',
    });
};
export const uploadPartnerAssets = ({ partnerId, assets }) => (dispatch) => {
    const formData = new window.FormData();
    assets.forEach((file) => {
        formData.append('assets', file, file.name);
    });
    return dispatch(createAPIAction({
        types: fillApiTypes(ActionCreators.UPLOAD_PARTNER_ASSETS),
        endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/partners/${partnerId}/assets`,
        method: 'POST',
        formData,
    }));
};
export const deletePartnerAsset = ({ partnerId, assetsIds, }) => {
    let assetsIdsStr;
    if (assetsIds.length > 1) {
        assetsIdsStr = `?assetIds=${assetsIds.join(',')}`;
    }
    else {
        assetsIdsStr = `/${assetsIds[0]}`;
    }
    return createAPIAction({
        types: fillApiTypes(ActionCreators.DELETE_PARTNER_ASSET),
        endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/partners/${partnerId}/assets${assetsIdsStr}`,
        method: 'DELETE',
    });
};
export const clearPartnerAssets = createAction('CLEAR_PARTNER_ASSETS');
