import React from 'react';
import { Grid } from 'src/components/mui';
import SummaryCard from './SummaryCard';
const SummaryContainer = ({ data, isFetching }) => {
    const shopsValue = data.length;
    const systemsValue = data
        .map((x) => x.systemsIntegratedId)
        .filter((value, index, self) => self.indexOf(value) === index).length;
    return (<Grid container style={{ justifyContent: 'center' }} spacing={5}>
      <Grid item xs={5}>
        <SummaryCard title="Shops" value={shopsValue} isFetching={isFetching}/>
      </Grid>
      <Grid item xs={5}>
        <SummaryCard title="Systems" value={systemsValue} isFetching={isFetching}/>
      </Grid>
    </Grid>);
};
export default SummaryContainer;
