import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from 'src/components/mui';
import CurrencyLabel from 'src/components/CurrencyLabel';
import { getOwnedStatus, getPaidStatus, } from 'src/AdminApp/containers/payments/PaymentModule/utils';
import { CheckIcon, WarningIcon } from './PaymentsTable/styledComponents';
import { ColoredMoneyLabel } from './Payment/Payment.styles';
const PaymentsRow = styled(Grid) `
  font-weight: bold;
  margin-top: 8px;

  &:first-of-type {
    margin-top: 24px;
  }
`;
const PaymentSummary = ({ invoice, recalculateTax }) => {
    const owedStatus = getOwnedStatus(invoice.balanceOwed, invoice.totalPrice, recalculateTax);
    const paidStatus = getPaidStatus(invoice.balanceOwed);
    const { balanceOwed, paymentsReceivedTotal, workOrderResponsibleParty } = invoice;
    const { responsibleParty } = workOrderResponsibleParty;
    const { responsiblePartyType } = responsibleParty !== null && responsibleParty !== void 0 ? responsibleParty : {};
    return (<Typography variant="subtitle2">
      <PaymentsRow container style={{ justifyContent: 'space-between' }}>
        <Grid xs={12} item>
          <span>
            {responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.name}{' '}
            {responsibleParty ? ` - ${responsibleParty.name}` : null}
          </span>
        </Grid>
      </PaymentsRow>

      <PaymentsRow container style={{ justifyContent: 'space-between' }}>
        <Grid item>Balance Owed</Grid>
        <Grid item>
          <ColoredMoneyLabel status={owedStatus}>
            {balanceOwed === null || recalculateTax ? ('Not Calculated') : (<>
                {balanceOwed === 0 ? null : <WarningIcon />}
                <CurrencyLabel>{balanceOwed || 0}</CurrencyLabel>
              </>)}
          </ColoredMoneyLabel>
        </Grid>
      </PaymentsRow>

      <PaymentsRow container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <span>Payments Received</span>
        </Grid>

        <Grid item>
          <ColoredMoneyLabel status={paidStatus}>
            {balanceOwed === 0 ? <CheckIcon /> : null}
            <CurrencyLabel>{paymentsReceivedTotal || 0}</CurrencyLabel>
          </ColoredMoneyLabel>
        </Grid>
      </PaymentsRow>
    </Typography>);
};
export default PaymentSummary;
