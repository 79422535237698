var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { toast } from 'src/components/SimpleToast';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createDefaultActionCreators, { createDefaultApiActionCreators, } from 'src/utils/createActions';
import API_URLS from 'src/SystemApp/modules/api/urls';
export const ActionCreators = {
    REINDEX_SEARCH: createDefaultApiActionCreators('REINDEX_SEARCH'),
    RESET_SKEDULO_IDS: createDefaultActionCreators('RESET_SKEDULO_IDS'),
    SYNC_TEMPLATES_SKEDULO_IDS: createDefaultActionCreators('SYNC_TEMPLATES_SKEDULO_IDS'),
    MATCH_SKEDULO_IDS: createDefaultActionCreators('MATCH_SKEDULO_IDS'),
    LOAD_AUDIT_CHANGELOG: createDefaultActionCreators('LOAD_AUDIT_CHANGELOG'),
    APPOINTMENT_RESOLUTIONS: createDefaultApiActionCreators('APPOINTMENT_RESOLUTIONS'),
    APPOINTMENT_RESOLUTIONS_OPTIONS: createDefaultActionCreators('APPOINTMENT_RESOLUTIONS_OPTIONS'),
    CUSTOM_CONFIGS: createDefaultActionCreators('CUSTOM_CONFIGS'),
};
export const reindexConsumerSearch = (...args) => reindexSearch(API_URLS.SYSTEM.REINDEX_CONSUMER_SEARCH, ...args);
export const reindexFleetSearch = (...args) => reindexSearch(API_URLS.SYSTEM.REINDEX_FLEET_SEARCH, ...args);
export const reindexSearch = (endpoint, immediateRefresh = false, deleteExisting = true) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: fillApiTypes(ActionCreators.REINDEX_SEARCH),
        params: {
            immediateRefresh,
            deleteExisting,
        },
        endpoint: endpoint(),
        method: 'GET',
    }));
    if (!response.error) {
        toast.success('Search Successfully Reindexed');
    }
    return response;
});
export const resetSkeduloIds = () => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: fillApiTypes(ActionCreators.RESET_SKEDULO_IDS),
        endpoint: API_URLS.SYSTEM.RESET_SKEDULO_IDS(),
        method: 'POST',
    }));
    if (!response.error) {
        toast.success('Skedulo reset success');
    }
    return response;
});
export const matchSkeduloIds = (dryRun, useExact) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: fillApiTypes(ActionCreators.MATCH_SKEDULO_IDS),
        params: { dryRun, useExact },
        endpoint: API_URLS.SYSTEM.MATCH_SKEDULO_IDS(),
        method: 'POST',
    }));
    if (!response.error) {
        toast.success('Skedulo match success');
    }
    return response;
});
export const syncTemplateSkeduloIds = () => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: fillApiTypes(ActionCreators.SYNC_TEMPLATES_SKEDULO_IDS),
        endpoint: API_URLS.SYSTEM.SYNC_TEMPLATES(),
        method: 'POST',
    }));
    if (!response.error) {
        toast.success('Templates Sync success');
    }
    return response;
});
export const loadAuditEntity = (params) => createAPIAction({
    types: fillApiTypes(ActionCreators.LOAD_AUDIT_CHANGELOG),
    endpoint: API_URLS.SYSTEM.LOAD_AUDIT_CHANGELOG(params),
    method: 'GET',
});
export const getAppointmentResolutions = () => (dispatch) => dispatch(createAPIAction({
    types: fillApiTypes(ActionCreators.APPOINTMENT_RESOLUTIONS),
    endpoint: API_URLS.SYSTEM.APPOINTMENT_RESOLUTIONS(),
    method: 'GET',
}));
export const createAppointmentResolution = (resolution) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: fillApiTypes(ActionCreators.APPOINTMENT_RESOLUTIONS),
        body: Object.assign({}, resolution),
        endpoint: API_URLS.SYSTEM.APPOINTMENT_RESOLUTIONS(),
        method: 'POST',
    }));
    if (!response.error) {
        toast.success('Appointment Resolution successfully created');
    }
    return dispatch(getAppointmentResolutions());
});
export const updateAppointmentResolution = (resolution) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: fillApiTypes(ActionCreators.APPOINTMENT_RESOLUTIONS),
        body: Object.assign({}, resolution),
        endpoint: API_URLS.SYSTEM.APPOINTMENT_RESOLUTIONS(),
        method: 'PUT',
    }));
    if (!response.error) {
        toast.success('Appointment Resolution successfully updated');
    }
    return dispatch(getAppointmentResolutions());
});
export const getAppointmentResolutionOptions = () => (dispatch) => dispatch(createAPIAction({
    types: fillApiTypes(ActionCreators.APPOINTMENT_RESOLUTIONS_OPTIONS),
    endpoint: API_URLS.SYSTEM.APPOINTMENT_RESOLUTIONS_OPTIONS(),
    method: 'GET',
}));
export const getCustomConfigs = () => (dispatch) => dispatch(createAPIAction({
    types: fillApiTypes(ActionCreators.CUSTOM_CONFIGS),
    endpoint: API_URLS.SYSTEM.CUSTOM_CONFIGS(),
    method: 'GET',
}));
export const updateCustomConfig = (config) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: fillApiTypes(ActionCreators.CUSTOM_CONFIGS),
        body: Object.assign({}, config),
        endpoint: API_URLS.SYSTEM.CUSTOM_CONFIGS(),
        method: 'PUT',
    }));
    if (!response.error) {
        toast.success('Custom Config successfully updated');
    }
    return dispatch(getCustomConfigs());
});
