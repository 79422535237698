var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import SimpleAutosuggest from 'src/components/SimpleAutosuggest';
const FleetSearchByName = ({ onSelect, onSearch, autoFocus, initialValue = null, disabled, name, label = 'Name', }) => {
    return (<SimpleAutosuggest label={label} placeholder="Search fleets..." fieldName="name" name={name} autoFocus={autoFocus} initialValue={initialValue || ''} onSearch={(value) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const response = yield onSearch(value);
            return (_b = (_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : [];
        })} onSelect={onSelect} disabled={disabled}/>);
};
export default FleetSearchByName;
