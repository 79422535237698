var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestNotes, getRequestNotesAppliesTo, getRequestNotesAppliesToRecommendedRepairs, addRequestNotes, } from 'src/AdminApp/modules/requestNotes/actions';
import { selectNotes, selectNotesAppliesTo, selectNotesAppliesToRecommendedRepairs, selectNotesPagination, } from 'src/AdminApp/modules/requestNotes/selectors';
const useNotes = (repairRequestId) => {
    const dispatch = useDispatch();
    const [notesLoading, setNotesLoading] = useState(false);
    const [notesAppliesToLoading, setNotesAppliesToLoading] = useState(false);
    const [notesAppliesToRecommendedRepairsLoading, setNotesAppliesToRecommendedRepairsLoading,] = useState(false);
    const [saveNotesLoading, setSaveNotesLoading] = useState(false);
    const notes = useSelector(selectNotes);
    const notesAppliesTo = useSelector(selectNotesAppliesTo);
    const notesAppliesToRecommendedRepairs = useSelector(selectNotesAppliesToRecommendedRepairs);
    const pagination = useSelector(selectNotesPagination);
    const onLoadData = ({ page, size, sort, }) => __awaiter(void 0, void 0, void 0, function* () {
        setNotesLoading(true);
        const response = yield dispatch(getRequestNotes(repairRequestId, sort, size, page));
        setNotesLoading(false);
        return response;
    });
    const fetchNotesAppliesTo = () => __awaiter(void 0, void 0, void 0, function* () {
        setNotesAppliesToLoading(true);
        const response = yield dispatch(getRequestNotesAppliesTo(repairRequestId));
        setNotesAppliesToLoading(false);
        return response;
    });
    const fetchNotesAppliesToRecommendedRepairs = () => __awaiter(void 0, void 0, void 0, function* () {
        setNotesAppliesToRecommendedRepairsLoading(true);
        const response = yield dispatch(getRequestNotesAppliesToRecommendedRepairs(repairRequestId));
        setNotesAppliesToRecommendedRepairsLoading(false);
        return response;
    });
    const saveNotes = (params) => __awaiter(void 0, void 0, void 0, function* () {
        setSaveNotesLoading(true);
        const response = yield dispatch(addRequestNotes(params));
        setSaveNotesLoading(false);
        return response;
    });
    return {
        fetchNotesAppliesTo,
        fetchNotesAppliesToRecommendedRepairs,
        saveNotes,
        onLoadData,
        notes,
        notesAppliesTo,
        notesAppliesToRecommendedRepairs,
        notesLoading,
        notesAppliesToLoading,
        notesAppliesToRecommendedRepairsLoading,
        saveNotesLoading,
        pagination,
    };
};
export default useNotes;
