var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { pickBy } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createParty, editParty, getParties, getPartyTypes, setFilters, setPartiesSort, } from 'src/AdminApp/modules/responsibleParties/actions';
import { selectPartiesContent, selectPartiesFilters, selectPartiesPagination, selectPartiesSort, selectPartyTypes, } from 'src/AdminApp/modules/responsibleParties/selectors';
import { toast } from 'src/components/SimpleToast';
import { splitAndTrimOn } from 'src/utils/data-utils';
const useResponsibleParties = () => {
    const pagination = useSelector(selectPartiesPagination);
    const partiesData = useSelector(selectPartiesContent);
    const partyTypes = useSelector(selectPartyTypes);
    const partiesSort = useSelector(selectPartiesSort);
    const partiesFilters = useSelector(selectPartiesFilters);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const onLoadData = ({ page, size, sort, }) => {
        dispatch(setPartiesSort(sort));
        dispatch(getParties(Object.assign({ page,
            size, sort: sort, adminManageable: true }, partiesFilters)));
    };
    const refreshParties = () => {
        dispatch(getParties({
            page: pagination.pageNumber,
            size: pagination.pageSize,
            sort: partiesSort,
            adminManageable: true,
        }));
    };
    const getData = () => {
        dispatch(getParties({
            page: 0,
            size: 20,
            sort: partiesSort,
            adminManageable: true,
        }));
        dispatch(getPartyTypes({ params: { adminManageable: true } }));
    };
    const searchData = ({ filters }) => {
        var _a;
        dispatch(setFilters(filters));
        dispatch(getParties(Object.assign({ page: 0, size: (_a = pagination.pageSize) !== null && _a !== void 0 ? _a : 20, sort: partiesSort, adminManageable: true }, pickBy(filters))));
    };
    const clearFilters = () => {
        dispatch(setFilters({}));
    };
    const normalizeEditData = (data) => {
        const normalized = Object.assign(Object.assign({}, data), { billingContacts: splitAndTrimOn(data.billingContactsString, ',') });
        if (Object.hasOwn(data, 'billingContactsString')) {
            delete normalized.billingContactsString;
        }
        return normalized;
    };
    const createResponsibleParty = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(createParty(normalizeEditData(data)));
        if (!response.error) {
            toast.success('Responsible Party created successfully');
        }
        return response;
    });
    const editResponsibleParty = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(editParty(normalizeEditData(data)));
        if (!response.error) {
            toast.success('Responsible Party updated successfully');
        }
        return response;
    });
    return {
        pagination,
        tableSort: partiesSort,
        partiesData,
        onLoadData,
        partyTypes,
        partiesFilters,
        clearFilters,
        getData,
        searchData,
        createResponsibleParty,
        editResponsibleParty,
        refreshParties,
        isDialogOpen,
        setDialogOpen,
    };
};
export default useResponsibleParties;
