import { toEnum } from 'src/models/enums';
export const FmcWorkAuthorizationStatus = toEnum({
    NEEDS_SUBMISSION: 'NEEDS SUBMISSION',
    SUBMITTED: 'SUBMITTED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    CANCELED: 'CANCELED',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
});
export default FmcWorkAuthorizationStatus;
