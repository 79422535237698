var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NotFound } from 'src/pages';
import ProtectedRoute from 'src/containers/utils/ProtectedRoute';
import PageLayout from 'src/containers/layout/PageLayout';
import PocV1 from './pages/PocV1';
import PocV2 from './pages/PocV2';
import NetSuiteAuth from './pages/NetSuiteAuth';
import NetSuiteApiTester from './pages/NetSuiteApiTester';
import PoList from './pages/V3/PoList';
import PocV3 from './pages/V3/PocV3';
import EditPO from './pages/V3/PoNew';
const DefaultPage = (props) => (<Redirect to={{ pathname: '/scan-poc/auth', state: { from: props.location } }}/>);
const ScanPoc = (_a) => {
    var props = __rest(_a, []);
    return (<PageLayout {...props}>
      <Switch>
        <ProtectedRoute exact path="/scan-poc/auth" component={NetSuiteAuth}/>
        <ProtectedRoute exact path="/scan-poc/v1" component={PocV1}/>
        <ProtectedRoute exact path="/scan-poc/v2" component={PocV2}/>
        <ProtectedRoute exact path="/scan-poc/api-tester" component={NetSuiteApiTester}/>
        <ProtectedRoute exact path="/scan-poc/" component={DefaultPage}/>
        <ProtectedRoute exact path="/scan-poc/v2" component={PocV2}/>
        <ProtectedRoute exact path="/scan-poc/v3" component={PoList}/>
        <ProtectedRoute exact path="/scan-poc/v3/PO/edit/:id" component={EditPO}/>
        <ProtectedRoute exact path="/scan-poc/v3/PO/receive/:id" component={PocV3}/>
        <Route component={NotFound}/>
      </Switch>
    </PageLayout>);
};
export default ScanPoc;
