import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { Button } from 'src/components/mui';
export const InternalNotesStyles = styled.section `
  padding: 20px;
`;
export const NotesActions = styled.section `
  border-top: solid 1px ${colors.GRAY_DE};
  text-align: right;
  padding-top: 15px;
  margin-top: 29px;
`;
export const NotesButton = styled(Button) `
  padding: 16px 41px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;

  &:first-child {
    margin-right: 20px;
  }
`;
