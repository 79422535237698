import { toEnum } from 'src/models/enums';
export const PartsStatus = toEnum({
    PENDING: 'Pending',
    NOT_PICKED_UP: 'Not Picked Up',
    ORDERED: 'Ordered',
    IN_STOCK: 'In Stock',
    SOLD: 'Sold',
    NOT_NEEDED: 'Not Needed',
    PICKED_UP: 'Picked Up',
    PARTIALLY_ORDERED: 'Partially Ordered',
    WRONG_PART: 'Wrong Part',
});
export default PartsStatus;
