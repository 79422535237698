var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'formik';
import { MenuItem, Select, TextField } from 'src/components/mui';
import LabeledField from 'src/components/layout/LabeledField';
import { loadMarketingSources } from 'src/AdminApp/modules/lookups/actions';
import { selectMarketingSources } from 'src/AdminApp/modules/lookups/selectors';
const MarketingSourceSelect = (_a) => {
    var { label, labeled, name, formField = true, visibility } = _a, rest = __rest(_a, ["label", "labeled", "name", "formField", "visibility"]);
    const dispatch = useDispatch();
    const marketingSourcesList = useSelector(selectMarketingSources);
    useEffect(() => {
        dispatch(loadMarketingSources(visibility));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const formOptions = () => [
        { value: null, label: '' },
        ...marketingSourcesList.map((i) => ({ value: i.id, label: i.displayName })),
    ];
    const renderOptions = () => {
        const options = formOptions();
        return options.map(({ value, label }) => (<MenuItem value={value !== null && value !== void 0 ? value : ''} key={value}>
        {label}
      </MenuItem>));
    };
    const renderLabeledField = ({ field, form, }) => {
        return (<LabeledField label={label !== null && label !== void 0 ? label : ''}>
        <Select {...field} {...rest} label={label} name={name} labeled={labeled} onChange={form.handleChange} inputProps={{
                id: name,
                name,
            }}>
          {renderOptions()}
        </Select>
      </LabeledField>);
    };
    const renderTextField = (formikForm) => {
        const { field = {} } = formikForm || {};
        return (<TextField select {...rest} label={label} labeled={labeled} name={name} {...field} inputProps={{
                id: name,
                name,
            }} InputLabelProps={{
                shrink: true,
            }}>
        {renderOptions()}
      </TextField>);
    };
    return formField ? (<Field name={name}>{labeled ? renderLabeledField : renderTextField}</Field>) : (renderTextField(null));
};
export default MarketingSourceSelect;
