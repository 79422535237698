import { toEnum } from 'src/models/enums';
export var DeliveryVanStatus;
(function (DeliveryVanStatus) {
    DeliveryVanStatus["PENDING"] = "Pending";
    DeliveryVanStatus["INACTIVE"] = "Inactive";
    DeliveryVanStatus["ACTIVE"] = "Active";
})(DeliveryVanStatus || (DeliveryVanStatus = {}));
export const DeliveryVanStatusValues = toEnum({
    PENDING: 'Pending',
    INACTIVE: 'Inactive',
    ACTIVE: 'Active',
});
