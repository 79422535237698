import React, { useState, useEffect } from 'react';
import ReactPlacesAutocomplete from 'react-places-autocomplete';
import { Autocomplete, Box, Grid, TextField, Typography, } from 'src/components/mui';
import { LocationOn as LocationOnIcon } from 'src/components/mui/icons';
const SubletAddressSelect = ({ initialAddress, onSelectNetwork, onSelectAddress, onChange, textFieldProps, }) => {
    const [address, setAddress] = useState(initialAddress);
    useEffect(() => {
        setAddress(initialAddress);
    }, [initialAddress]);
    const handleChange = (a) => {
        setAddress(a);
        onChange(a);
    };
    const handleSelect = (a) => {
        setAddress(a);
        onSelectAddress(a);
    };
    return (<ReactPlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect} searchOptions={{
            componentRestrictions: { country: 'us' },
        }} debounce={500}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (<Autocomplete style={{ width: '100%' }} filterOptions={(x) => x} inputValue={address} options={[{ custom: true, description: 't' }, ...suggestions]} autoComplete includeInputInList freeSolo clearIcon={null} getOptionLabel={(x) => { var _a; return (_a = x.description) !== null && _a !== void 0 ? _a : ''; }} renderInput={(params) => {
                return (<TextField {...params} {...getInputProps()} label="Add a location" fullWidth inputProps={Object.assign(Object.assign({}, params.inputProps), { value: address })} {...textFieldProps}/>);
            }} renderOption={(props, suggestion) => {
                var _a, _b;
                if (suggestion.custom) {
                    return (<Grid container style={{ alignItems: 'center' }} onClick={() => {
                            onSelectNetwork();
                        }}>
                  <Grid item xs={12}>
                    Search Network...
                  </Grid>
                </Grid>);
                }
                const [addressLine, ...restAddress] = (_b = (_a = suggestion.description) === null || _a === void 0 ? void 0 : _a.split(', ')) !== null && _b !== void 0 ? _b : [];
                return (<Box component="li" {...props}>
                <Grid container style={{ alignItems: 'center' }} {...getSuggestionItemProps(suggestion)}>
                  <Grid item>
                    <LocationOnIcon />
                  </Grid>
                  <Grid item xs>
                    <span>{addressLine}</span>
                    <Typography variant="subtitle1">
                      {restAddress.join(', ')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>);
            }}/>)}
    </ReactPlacesAutocomplete>);
};
export default SubletAddressSelect;
