import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getConsumerUrl } from 'src/utils/nav-utils';
import styled from 'styled-components';
import { TextField, Select, InputLabel, FormControl, MenuItem, Checkbox, FormControlLabel, } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import { selectMarketingSources } from 'src/AdminApp/modules/lookups/selectors';
const FunnelInput = styled(TextField) `
  margin-top: 24px;
`;
const FunnelSelect = styled(Select) ``;
const FunnelSelectLabel = styled(InputLabel) `
  margin-left: 16px;
  margin-top: -4px;
`;
const FunnelFormControl = styled(FormControl) `
  margin-top: 24px;
`;
const UrlIdentifierContainer = styled.section `
  border-radius: 4px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08);
  background: ${colors.GRAY_3F};
  color: ${colors.white};
  padding: 17px 18px;
  margin-top: 23px;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;
const PartnerDetails = ({ formik, initialUrlIdentifier, disabled, }) => {
    var _a;
    const [urlIdentifier, setUrlIdentifier] = useState();
    const marketingSources = useSelector(selectMarketingSources);
    useEffect(() => {
        if (initialUrlIdentifier) {
            setUrlIdentifier(initialUrlIdentifier);
        }
    }, [initialUrlIdentifier]);
    const cleanPartnerName = (name) => name
        .toLowerCase()
        .replace(/\s/g, '-')
        .replace(/[^\w-]/g, '');
    return (<>
      <UrlIdentifierContainer>
        {(_a = getConsumerUrl(`location?partner=${urlIdentifier}`)) !== null && _a !== void 0 ? _a : ''}
      </UrlIdentifierContainer>

      <FunnelInput variant="outlined" name="name" autoComplete="off" label="Partner Name" fullWidth value={formik.values.name} onChange={(e) => {
            const newValue = cleanPartnerName(e.target.value);
            setUrlIdentifier(newValue);
            formik.setFieldValue('urlParameterIdentifier', newValue);
            formik.handleChange(e);
        }} error={formik.touched.name && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} onBlur={formik.handleBlur} disabled={disabled}/>

      <FunnelInput variant="outlined" name="urlParameterIdentifier" label="Custom Parameter Name" fullWidth value={formik.values.urlParameterIdentifier} onChange={(e) => {
            const newValue = cleanPartnerName(e.target.value);
            setUrlIdentifier(newValue);
            e.target.value = newValue;
            formik.handleChange(e);
        }} error={formik.touched.urlParameterIdentifier &&
            Boolean(formik.errors.urlParameterIdentifier)} helperText={formik.touched.urlParameterIdentifier &&
            formik.errors.urlParameterIdentifier} onBlur={formik.handleBlur} disabled={disabled}/>

      <FunnelFormControl fullWidth>
        <FunnelSelectLabel id="funnel-marketing-source-select-label">
          Marketing Source
        </FunnelSelectLabel>
        <FunnelSelect label="Marketing Source" labelId="funnel-marketing-source-select-label" id="funnel-marketing-source-select" name="marketingSourceId" value={formik.values.marketingSourceId} fullWidth variant="outlined" onChange={(e) => {
            const value = e.target.value === 'null' ? null : e.target.value;
            formik.setFieldValue('marketingSourceId', value);
            formik.handleChange(value);
        }}>
          <MenuItem value="null">Not Selected</MenuItem>
          {marketingSources === null || marketingSources === void 0 ? void 0 : marketingSources.map((m) => (<MenuItem key={m.id} value={m.id}>
              {m.displayName}
            </MenuItem>))}
        </FunnelSelect>
      </FunnelFormControl>
      <FunnelFormControl fullWidth>
        <FormControlLabel control={<Checkbox checked={formik.values.startAtPlate} onChange={() => {
                formik.setFieldValue('startAtPlate', !formik.values.startAtPlate);
            }}/>} label="Require VIN to select car"/>
      </FunnelFormControl>
    </>);
};
export default PartnerDetails;
