import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isProduction } from 'src/utils/envUtils';
import { TIME_12H_FORMAT } from 'src/timeConstants';
import { selectSelectedJobs, selectSelectedDate, selectUpdatingJobId, selectJobCancellationStatus, selectSelectedJobAnchorEl, selectIsJobInfoLoading, selectLockDrag, } from 'src/AdminApp/modules/swimlanes/selectors';
import { setSelectedJobs, setUpdatingJobId, setOnDragEnd, setSelectedJobAnchorEl, setIsJobInfoLoading, } from 'src/AdminApp/modules/swimlanes/actions';
import { getJobSize, getPosition, getOverlappingJobs, getHourFromPosition, getTravelTimeTailSize, } from '../utils';
import { DraggableStatuses } from '../../constants';
const useChartJob = ({ job, region, van, setDragLoading }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dragCurrentTime, setDragCurrentTime] = useState(null);
    const [shouldPerformClickEvent, setShouldPerformClickEvent] = useState(true);
    const selectedJobs = useSelector(selectSelectedJobs);
    const selectedDate = useSelector(selectSelectedDate);
    const updatingJobId = useSelector(selectUpdatingJobId);
    const jobCancellationStatus = useSelector(selectJobCancellationStatus);
    const selectedJobAnchorEl = useSelector(selectSelectedJobAnchorEl);
    const isJobInfoLoading = useSelector(selectIsJobInfoLoading);
    const lockDrag = useSelector(selectLockDrag);
    const jobUpdatingTest = useRef(null);
    const jobPositionX = getPosition(job.repairJob.startDate, selectedDate, region.timezone);
    const jobSizePixels = getJobSize(job.repairJob.startDate, job.repairJob.endDate, region.timezone);
    const travelTimeTailPixels = getTravelTimeTailSize(job.drivingTime);
    const overlappingJobs = getOverlappingJobs(job, van.timelines);
    const isCurrentJobSelected = !!(selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.find((selected) => selected.id === job.id));
    const jobSelectedForUpdate = updatingJobId === job.id;
    const jobCanBeDragged = !['LUNCH', 'PART_PICKUP'].includes(job.repairJob.type) &&
        DraggableStatuses.includes(job.repairJob.jobStatus);
    const jobCanOpenDetails = !['LUNCH'].includes(job.repairJob.type);
    const jobName = isProduction()
        ? job.repairJob.referenceNum
        : `${job.repairJob.referenceNum} (${job.repairJob.environment})`;
    const defaultOptions = {
        disabled: false,
        axis: 'both',
        bounds: '.chart-row-scrollable',
        grid: [7.5, 65],
        defaultPosition: { x: 0, y: 0 },
        position: { x: 0, y: 0 },
        cancel: '.cancel',
        onDrag: (data) => handleDrag(data),
        onDragEnd: (data) => {
            setDragCurrentTime(null);
            handleEndDrag(data);
        },
    };
    const [options, setOptions] = useState(Object.assign(Object.assign({}, defaultOptions), { disabled: (!!updatingJobId && !jobSelectedForUpdate) || !jobCanBeDragged, defaultPosition: { x: jobPositionX, y: 0 }, position: jobUpdatingTest.current !== null ? undefined : { x: jobPositionX, y: 0 } }));
    const handleDrag = (data) => {
        setDragCurrentTime(getHourFromPosition(data.offsetX, TIME_12H_FORMAT));
        setDragLoading(true);
        dispatch(setUpdatingJobId(job.id));
    };
    const handleEndDrag = (data) => {
        dispatch(setOnDragEnd({
            data,
            jobId: job.id,
            van,
            region,
        }));
    };
    const draggableRef = useRef(null);
    useEffect(() => {
        if (anchorEl !== selectedJobAnchorEl) {
            setAnchorEl(null);
        }
    }, [anchorEl, selectedJobAnchorEl]);
    useEffect(() => {
        setOptions(Object.assign(Object.assign({}, defaultOptions), { disabled: (!!updatingJobId && !jobSelectedForUpdate) || !jobCanBeDragged, defaultPosition: { x: jobPositionX, y: 0 }, position: updatingJobId ? undefined : { x: jobPositionX, y: 0 } }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobCancellationStatus, updatingJobId, jobPositionX]);
    useEffect(() => {
        var _a;
        if (updatingJobId) {
            jobUpdatingTest.current = updatingJobId !== null && updatingJobId !== void 0 ? updatingJobId : null;
            dispatch(setSelectedJobs([
                ...((_a = selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.filter((job) => job.id === updatingJobId)) !== null && _a !== void 0 ? _a : []),
            ]));
        }
        else {
            jobUpdatingTest.current = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatingJobId]);
    const handleJobSelect = (event, isOverlapping) => {
        event.stopPropagation();
        if (updatingJobId || !jobCanOpenDetails || !shouldPerformClickEvent) {
            return;
        }
        if (!isOverlapping) {
            dispatch(setSelectedJobs([job]));
        }
        setAnchorEl(event.currentTarget);
        dispatch(setSelectedJobAnchorEl(event.currentTarget));
        dispatch(setIsJobInfoLoading(true));
    };
    const onOverlappingJobsClick = (event, job, overlappingJobs) => {
        event.stopPropagation();
        dispatch(setSelectedJobs([job, ...overlappingJobs]));
        handleJobSelect(event, true);
    };
    const getZIndex = useCallback(() => {
        if (lockDrag)
            return 2;
        if (jobSelectedForUpdate)
            return 4;
        if (overlappingJobs)
            return 3;
        return 2;
    }, [lockDrag, jobSelectedForUpdate, overlappingJobs]);
    const extractCompanyName = (text) => {
        const match = text.match(/Visit for (.+?)\n/);
        return match ? match[1] : '';
    };
    const handleClickAway = () => {
        if (!jobCancellationStatus || jobCancellationStatus.status === 'CLOSED') {
            dispatch(setSelectedJobs(undefined));
            dispatch(setSelectedJobAnchorEl(undefined));
            setAnchorEl(null);
        }
    };
    return {
        anchorEl,
        setAnchorEl,
        dragCurrentTime,
        setDragCurrentTime,
        shouldPerformClickEvent,
        setShouldPerformClickEvent,
        selectedJobs,
        selectedDate,
        updatingJobId,
        jobCancellationStatus,
        selectedJobAnchorEl,
        isJobInfoLoading,
        lockDrag,
        jobUpdatingTest,
        jobPositionX,
        jobSizePixels,
        travelTimeTailPixels,
        overlappingJobs,
        isCurrentJobSelected,
        jobSelectedForUpdate,
        jobCanBeDragged,
        jobCanOpenDetails,
        jobName,
        options,
        setOptions,
        handleDrag,
        handleEndDrag,
        draggableRef,
        handleJobSelect,
        onOverlappingJobsClick,
        getZIndex,
        extractCompanyName,
        handleClickAway,
    };
};
export default useChartJob;
