var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import { toast } from 'src/components/SimpleToast';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Button, Grid, CircularProgress } from 'src/components/mui';
import { getInstallations, setSortParams, } from 'src/AdminApp/modules/installations/actions';
import { selectDashboardPagination, selectDashboardInstallations, selectDashboardParams, selectDashboardIsFetching, } from 'src/AdminApp/modules/installations/selectors';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import DashboardListItem from './DashboardListItem';
import SummaryContainer from './SummaryContainer';
const REQUEST_COLUMNS = [
    { title: 'Shop Name', key: 'shopName', sortable: true },
    { title: 'Custom Id', key: 'customId', sortable: true },
    { title: 'Shop Zip', key: 'shopZip', sortable: true },
    { title: 'Shop Phone', key: 'shopPhone', sortable: true },
    { title: 'Systems Integrated', key: 'systemsIntegrated', sortable: true },
    { title: 'Installation Date', key: 'installationDate', sortable: true },
    {
        title: 'Last Invoice Date/Time',
        key: 'lastInvoiceDateTime',
        sortable: true,
    },
    { title: 'Status', key: 'status', sortable: true },
    { title: 'Actions', key: 'other', sortable: false },
];
const InstallationsDashboard = () => {
    var _a;
    const dispatch = useDispatch();
    const installations = useSelector(selectDashboardInstallations);
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    const isFetching = useSelector(selectDashboardIsFetching);
    const loadInstallations = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d;
        try {
            yield dispatch(getInstallations(Object.assign(Object.assign({}, params), { pageSize: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _b !== void 0 ? _b : 100, pageIndex: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _c !== void 0 ? _c : 0, sort: (_d = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _d !== void 0 ? _d : ['installationDate', 'desc'] })));
        }
        catch (e) {
            toast.error('Installations were not loaded');
        }
    });
    const onLoadData = ({ sort }) => {
        if (sort) {
            dispatch(setSortParams(sort));
        }
    };
    useEffect(() => {
        loadInstallations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getCsvName = () => `installations_${new Date().toLocaleDateString()}.csv`;
    const isExportDisabled = !(installations && installations.length);
    return (<>
      <SummaryContainer data={installations} isFetching={isFetching}/>
      <p />
      <Grid container direction="row">
        <Grid container style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
          {!isExportDisabled && (<CSVLink data={installations || []} filename={getCsvName()} target="_blank" style={{ textDecoration: 'none' }}>
              <Button size="small" variant="contained" fullWidth color="primary" disabled={isExportDisabled}>
                Export CSV
              </Button>
            </CSVLink>)}
        </Grid>
      </Grid>
      <p />
      {isFetching ? (<CircularProgress />) : (<SimpleTable data={installations} pagination={pagination} sort={(_a = pagination.sort) !== null && _a !== void 0 ? _a : []} columns={REQUEST_COLUMNS} isFetching={isFetching} renderItem={(item, index) => (<DashboardListItem {...item} key={`${index}-${item.customId}`}/>)} onLoadData={onLoadData} hidePagination/>)}
    </>);
};
export default InstallationsDashboard;
