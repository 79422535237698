import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: [],
        },
    },
    fees: [],
};
const reducers = handleActions({
    [ActionCreators.GET_ALL_MARKETING_SOURCES.SUCCESS.type]: (state, action) => {
        return Object.assign(Object.assign({}, state), { dashboard: action.payload });
    },
    [ActionCreators.GET_FEES.SUCCESS.type]: (state, action) => {
        return Object.assign(Object.assign({}, state), { fees: action.payload });
    },
}, DEFAULT_STATE);
export default reducers;
