// all props and playground is here https://fkhadra.github.io/react-toastify/introduction
const toastProps = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
};
export default toastProps;
