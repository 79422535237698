import styled from 'styled-components';
import { theme } from 'src/styles/theme';
export const Wrapper = styled.div `
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0;

  ${theme.breakpoints.up('lg')} {
    justify-content: flex-end;
    padding-left: 4px;
  }
`;
