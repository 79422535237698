import styled from 'styled-components';
import { flexCenter } from 'src/styles/styled';
import { colors, theme } from 'src/styles/theme';
import { Button, Typography } from 'src/components/mui';
export const PayerContainer = styled.div `
  ${flexCenter};

  flex-direction: column;
  justify-content: flex-start;
  max-width: 550px;
  padding: 40px 0 24px;
  padding-inline: 16px;

  ${theme.breakpoints.up('md')} {
    padding-inline: 24px;
  }
`;
export const PayerBtnContainer = styled('div') `
  border-bottom: 1px solid ${colors.GRAY_DE};
  display: inherit;
  width: inherit;

  &:first-of-type {
    border-top: 1px solid ${colors.GRAY_DE};
  }
`;
export const PayerBtn = styled(Button) `
  display: inherit;
  height: 56px;
  justify-content: space-between;
  padding: 0;
`;
export const PayerBtnText = styled(Typography) `
  color: ${({ $isOwed, $isColored }) => $isOwed && $isColored ? colors.error : colors.success};
`;
export const PayerBtnRightSide = styled('div') `
  display: inherit;

  svg {
    color: ${colors.GRAY_CC};
    margin-left: 8px;
  }
`;
