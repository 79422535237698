export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
    })
        .join(''));
    return JSON.parse(jsonPayload);
};
export const isTokenExpired = (token) => {
    const { exp } = parseJwt(token);
    if (Date.now() >= exp * 1000) {
        return true;
    }
    return false;
};
export const getCookieDomainAttrs = () => {
    let domain = null;
    if (location.origin.endsWith('.com'))
        domain = '.autonationmobileservice.com';
    else if (location.origin.endsWith('.io'))
        domain = '.autonationmobileservice.io';
    else if (location.origin.endsWith('.local'))
        domain = '.repairsmith.local';
    const attrs = Object.assign({}, (!!domain && { domain }));
    return attrs;
};
const LS_REDIRECT_URL_KEY = 'redirectUrl';
export const storeRedirectUrl = (url) => localStorage.setItem(LS_REDIRECT_URL_KEY, url);
export const clearRedirectUrl = () => localStorage.removeItem(LS_REDIRECT_URL_KEY);
const getRedirectUrl = () => localStorage.getItem(LS_REDIRECT_URL_KEY);
export const resolveRedirectUrl = () => {
    let redirectUrl = getRedirectUrl();
    if (!redirectUrl ||
        redirectUrl === '/' ||
        redirectUrl === '/login' ||
        redirectUrl === 'null') {
        redirectUrl = '/my-home';
    }
    return redirectUrl;
};
export const INTERNAL_ACCESS_TOKEN = 'rs-internal-accessToken';
export const INTERNAL_REFRESH_TOKEN = 'rs-internal-refreshToken';
