import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRepairMeasurements } from 'src/AdminApp/modules/requests/selectors';
import { selectAuthorizedComponentEditor } from 'src/modules/auth/selectors';
import { openMeasurementsSection } from 'src/AdminApp/modules/requests/actions';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { isUrl } from 'src/utils/data-utils';
import { colors } from 'src/styles/theme';
const REASONS = {
    REPAIR_COST: 'Repair cost',
    FUTURE_APPOINTMENT: 'Will schedule future appointment',
    OTHER: 'Other',
};
const useRecommendedRepairItem = ({ repair, }) => {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const { selectorProps } = usePageReferenceNum();
    const [displayImages, setDisplayImages] = useState(false);
    const measurements = useSelector((state) => selectRepairMeasurements(state, selectorProps));
    const canEditComponent = useSelector(selectAuthorizedComponentEditor);
    const showMeasurements = useMemo(() => {
        return (measurements.find((m) => m.repairs.includes(parseInt(repair === null || repair === void 0 ? void 0 : repair.repairId, 10))) !== undefined);
    }, [measurements, repair]);
    const isRecommended = (repair === null || repair === void 0 ? void 0 : repair.status) === 'RECOMMENDED' &&
        !(repair === null || repair === void 0 ? void 0 : repair.scheduledRepairRequestReferenceNum);
    const isRejected = (repair === null || repair === void 0 ? void 0 : repair.status) === 'REJECTED';
    const isAddedToFutureRequest = (repair === null || repair === void 0 ? void 0 : repair.status) === 'RECOMMENDED' &&
        (repair === null || repair === void 0 ? void 0 : repair.scheduledRepairRequestReferenceNum);
    const rawImageUrls = (_b = (_a = repair === null || repair === void 0 ? void 0 : repair.inspectionDetails) === null || _a === void 0 ? void 0 : _a.imageUrls) !== null && _b !== void 0 ? _b : [];
    const imageUrls = rawImageUrls.filter((imgUrl) => isUrl(imgUrl));
    const sortedPartPrices = (_c = repair === null || repair === void 0 ? void 0 : repair.partsPrices) === null || _c === void 0 ? void 0 : _c.filter((i) => !i.removed).sort((a, b) => {
        return a.name < b.name ? -1 : 1;
    });
    const sortedFluidPrices = (_d = repair === null || repair === void 0 ? void 0 : repair.fluidPrices) === null || _d === void 0 ? void 0 : _d.filter((i) => !i.removed).sort((a, b) => {
        return a.name < b.name ? -1 : 1;
    });
    const getRejectionReason = (repairItem) => {
        const reason = REASONS[repairItem.rejectedReason];
        const otherReason = repairItem.rejectedReason === 'OTHER' ? repairItem.otherReason : null;
        return {
            reason,
            otherReason,
        };
    };
    const getStatusSuggestedRepair = (repairItem) => {
        if (repairItem.calculatedTotalCost === null &&
            repairItem.laborTime === null) {
            return 'Please finalize the pricing and labor duration to schedule this repair';
        }
        if (repairItem.calculatedTotalCost === null) {
            return 'Please finalize the pricing to schedule this repair';
        }
        if (repairItem.laborTime === null) {
            return 'Please finalize the labor duration to schedule this repair';
        }
        if (repairItem.totalCost === null) {
            return 'Please finalize the pricing to schedule this repair';
        }
    };
    const getSeverity = (severity) => {
        let severityColor;
        let severityText;
        if (severity === 'now') {
            severityColor = colors.error;
            severityText = 'Severity: now';
        }
        else if (severity === '30_days') {
            severityColor = colors.warning;
            severityText = 'Severity: 30 days';
        }
        else if (severity === '60_days') {
            severityColor = colors.success;
            severityText = 'Severity: 60 days';
        }
        else if (severity === '6_months') {
            severityColor = colors.success;
            severityText = 'Severity: 6 months';
        }
        else {
            severityColor = undefined;
            severityText = undefined;
        }
        return {
            severityColor,
            severityText,
        };
    };
    const handleOpenMeasurements = () => {
        dispatch(openMeasurementsSection(true, parseInt(repair === null || repair === void 0 ? void 0 : repair.repairId, 10), false));
    };
    return {
        displayImages,
        setDisplayImages,
        isRecommended,
        isRejected,
        isAddedToFutureRequest,
        getRejectionReason,
        measurements,
        showMeasurements,
        canEditComponent,
        imageUrls,
        getStatusSuggestedRepair,
        getSeverity,
        handleOpenMeasurements,
        sortedPartPrices,
        sortedFluidPrices,
    };
};
export default useRecommendedRepairItem;
