import { FormControlLabel, Radio, RadioGroup } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
const RadioButton = styled(FormControlLabel) `
  padding: 4px 0;
  border-bottom: 1px solid ${colors.GRAY_DE};
`;
const ExtraTimeReasonModal = ({ isOpen, setIsOpen, extraTimeReasons, onValueChange, disableSubmit, onSubmit, }) => {
    return (<SimpleDrawer open={isOpen} onClose={() => { }}>
      <SimpleDrawerHeader title="Select Reason" onClose={() => {
            setIsOpen(false);
        }}/>

      <SimpleDrawerContent>
        <Formik initialValues={{}} onSubmit={() => { }}>
          <RadioGroup onChange={({ target }) => onValueChange(target.value)}>
            {extraTimeReasons.map((extraTimeReason) => (<RadioButton key={extraTimeReason} name="Extra time reason" value={extraTimeReason} label={extraTimeReason} control={<Radio color="primary"/>}/>))}
          </RadioGroup>
        </Formik>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={() => onSubmit()} disabled={disableSubmit}>
          Next
        </FooterActionButton>
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
export default ExtraTimeReasonModal;
