import { ArrowBack, Close } from 'src/components/mui/icons';
import React, { useMemo, useState } from 'react';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import { SimplePageSlider } from 'src/components/SimpleDrawerPaged';
import { DrawerBackButton, DrawerCloseButton, DrawerStyledHeader, DrawerStyledHeaderTitle, } from 'src/components/SimpleDrawer/SimpleDrawerHeader/SimpleDrawerHeader.styles';
import History from './pages/History';
import HistoryItem from './pages/HistoryItem';
import HistoryItemDetails from './pages/HistoryItemDetails';
import { SliderContext } from './sliderContext';
const RequestHistoryDrawer = ({ open, closeHandler, history, }) => {
    const [slide, setSlide] = useState('history');
    const [currentHistoryItem, setCurrentHistoryItem] = useState([]);
    const [currentHistoryItemDetails, setCurrentHistoryItemDetails] = useState();
    const providerContext = useMemo(() => ({
        slide,
        setSlide,
    }), [slide]);
    const closeDrawerHandler = () => {
        setSlide('history');
        closeHandler();
    };
    const routes = [
        {
            name: 'history',
            title: 'History',
            render: () => (<History history={history} onHistoryItemClick={setCurrentHistoryItem}/>),
        },
        {
            name: 'historyItem',
            title: 'History Item',
            render: () => (<HistoryItem changelogs={currentHistoryItem} onHistoryItemDetailsClick={setCurrentHistoryItemDetails}/>),
        },
        {
            name: 'historyItemDetails',
            title: 'History Item Details',
            render: () => currentHistoryItemDetails ? (<HistoryItemDetails changelog={currentHistoryItemDetails}/>) : null,
        },
    ];
    const drawerTitle = (s) => {
        var _a, _b;
        return (_b = (_a = routes.find((r) => r.name === s)) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '';
    };
    const renderHeader = () => {
        switch (slide) {
            case 'historyItem':
                return (<DrawerBackButton onClick={() => setSlide('history')}>
            <ArrowBack />
          </DrawerBackButton>);
            case 'historyItemDetails':
                return (<DrawerBackButton onClick={() => setSlide('historyItem')}>
            <ArrowBack />
          </DrawerBackButton>);
            default:
                return (<DrawerCloseButton onClick={() => closeHandler()}>
            <Close />
          </DrawerCloseButton>);
        }
    };
    return (<SimpleDrawer open={open} onClose={closeDrawerHandler}>
      <DrawerStyledHeader>
        {renderHeader()}
        <DrawerStyledHeaderTitle>{drawerTitle(slide)}</DrawerStyledHeaderTitle>
      </DrawerStyledHeader>
      <SliderContext.Provider value={providerContext}>
        <SimplePageSlider currentSlide={slide} slides={routes}/>
      </SliderContext.Provider>
    </SimpleDrawer>);
};
export default RequestHistoryDrawer;
