var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import { getEntry } from 'src/clients/contentfulClient';
import { ActionButton, DestructiveButton } from 'src/components/buttons';
import HeyTech from 'src/components/layout/HeyTech';
import { SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import Config from 'src/config';
const AuthorizationHeyTech = ({ nextStep, skipGuide, refNum, }) => {
    const [headerCopy, setHeaderCopy] = useState();
    const [scriptCopy, setScriptCopy] = useState();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield getEntry(Config.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_HEY_TECH);
            setHeaderCopy(response.fields.header);
            setScriptCopy(response.fields.script);
        }))();
    }, []);
    const handleNextStep = () => {
        sendAnalyticEvent('Open Authorization Script');
        nextStep();
    };
    const handleSkipGuide = () => {
        sendAnalyticEvent('Skip Authorization Script');
        skipGuide();
    };
    const sendAnalyticEvent = (eventName) => __awaiter(void 0, void 0, void 0, function* () {
        analyticsTrackEvent(eventName, {
            refNum,
        });
    });
    return (<>
      <SimpleDrawerContent>
        <HeyTech header={headerCopy} copy={scriptCopy !== null && scriptCopy !== void 0 ? scriptCopy : ''}/>
      </SimpleDrawerContent>

      <SimpleDrawerFooter>
        <DestructiveButton onClick={handleSkipGuide}>
          Skip Guide
        </DestructiveButton>
        <ActionButton onClick={handleNextStep}>Next</ActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default AuthorizationHeyTech;
