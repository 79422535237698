import styled from 'styled-components';
import { colors, FONT_FAMILY, theme } from 'src/styles/theme';
import { CheckCircleRounded as CheckCircleRoundedIcon, RemoveCircleRounded as RemoveCircleRoundedIcon, } from 'src/components/mui/icons';
import { Alert, Button, Slider } from 'src/components/mui';
const s = styled;
export const SwitcherMenu = styled(Button) `
  position: absolute;
  top: 10px;
  right: 50px;
`;
export const AddFleetContent = styled.section `
  display: flex;
  flex-direction: column-reverse;

  ${theme.breakpoints.up('md')} {
    justify-content: space-between;
    align-content: stretch;
    flex-direction: row;
  }
`;
export const RightSide = styled.section `
  width: 100%;

  ${theme.breakpoints.up('md')} {
    max-width: 500px;
    flex-grow: 1;
    margin-left: 56px;
    margin-top: -23px;
  }
`;
export const FleetsAndLocation = styled.section `
  margin-top: 25px;

  .fleets-no-options {
    color: red;
  }
`;
export const LocationGoodIcon = styled(CheckCircleRoundedIcon) `
  color: ${colors.GREEN};
`;
export const LocationBadIcon = styled(RemoveCircleRoundedIcon) `
  color: ${colors.RED};
`;
export const MainHeader = styled.h2 `
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -0.5px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;
export const SectionHeader = styled.h2 `
  font-family: ${FONT_FAMILY};
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.38px;
  color: ${colors.GRAY_3F};
  margin: 0;
`;
export const FieldWrapperTop = styled.div `
  margin-top: 18px;
  margin-bottom: 100px;

  ${theme.breakpoints.up('md')} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
export const FieldWrapper = styled.div `
  margin-top: 18px;
`;
export const DurationWrapper = styled.section `
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 32px;

  ${theme.breakpoints.up('md')} {
    margin-top: 0;
  }
`;
export const DurationLabel = styled.div `
  margin-right: 32px;
  white-space: nowrap;
  font-family: ${FONT_FAMILY};
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  width: 200px;
`;
export const DurationSlider = styled(Slider) `
  & .durationThumb {
    height: 29px;
    width: 29px;
  }

  & .bar {
    height: 9px;
    width: 1px;
    background-color: ${colors.white};
    margin-left: 1px;
    margin-right: 1px;
  }

  .MuiSlider-rail {
    height: 2px;
  }

  .MuiSlider-track {
    height: 1px;
  }
`;
export const SchedulingWrapper = styled.section `
  margin-bottom: 24px;
  margin-top: 80px;

  ${theme.breakpoints.up('md')} {
    flex-grow: 1;
    margin-top: 0;
  }
`;
export const TechniciansWrapper = styled.section `
  margin-top: 24px;
`;
export const RevenueWrapper = styled.section `
  margin-top: 24px;

  ${theme.breakpoints.up('md')} {
    margin-top: 32px;
    padding-bottom: 53px;
  }
`;
export const AddVisitFooter = styled.section `
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding: 16px 25px;
  text-align: right;
  box-shadow: inset 0 1px 0 0 ${colors.GRAY_DE};
  background-image: linear-gradient(to bottom, #ebedf0, #f2f2f2 75%);
`;
export const AddVisitAc = styled.div `
  margin-top: 24px;

  .chip {
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: white;
    background-color: ${(props) => props.color === 'gray' ? colors.GRAY_3F : colors.BLUE};
  }

  .chip-delete-icon {
    color: ${(props) => props.color === 'gray' ? colors.GRAY_97 : colors.BLUE_MUTED};
  }
`;
export const AddVisitInfo = s(Alert).attrs({
    severity: 'info',
}) `
  margin: 16px 0;
`;
