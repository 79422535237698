import React from 'react';
import { useSelector } from 'react-redux';
import { ActionsBlock } from 'src/containers/layout/ActionsBlock';
import GeoManagementDrawer from 'src/AdminApp/components/geoManagement/GeoManagementDrawer';
import { selectSlidePanelOpen, selectSlidePanelParams, } from 'src/AdminApp/modules/geoManagement/selectors';
import { selectAuthorizedGeoMgmtEdit } from 'src/modules/auth/selectors';
import useGeoManagementActions from './useGeoManagementActions';
const GeoManagementActions = () => {
    var _a;
    const { getActions, closeHandler, onDeleteRegionLaborRates, onDeleteMetroLaborRates, } = useGeoManagementActions();
    const canEdit = useSelector(selectAuthorizedGeoMgmtEdit);
    const open = useSelector(selectSlidePanelOpen);
    const slidePanelParams = useSelector(selectSlidePanelParams);
    return (<>
      <ActionsBlock actions={getActions()}/>
      <GeoManagementDrawer open={open} closeHandler={closeHandler} onTerritorySubmit={slidePanelParams.onSaveTerritory} onTerritoryRemove={slidePanelParams.onRemoveTerritory} onMetroSubmit={slidePanelParams.onSaveMetro} onMetroRemove={slidePanelParams.onRemoveMetro} onRegionSubmit={(_a = slidePanelParams.onSaveRegion) !== null && _a !== void 0 ? _a : (() => { })} onRegionRemove={slidePanelParams.onRemoveRegion} onServiceAreaRemove={slidePanelParams.onRemoveServiceArea} disableOptions={slidePanelParams.disableOptions} territoryModel={slidePanelParams.territoryModel} metroModel={slidePanelParams.metroModel} regionModel={slidePanelParams.regionModel} serviceAreaModel={slidePanelParams.serviceAreaModel} canEdit={canEdit} onDeleteRegionLaborRates={onDeleteRegionLaborRates} onDeleteMetroLaborRates={onDeleteMetroLaborRates}/>
    </>);
};
export default GeoManagementActions;
