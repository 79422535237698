import { Grid, FormControlLabel, Radio, RadioGroup, Divider, } from 'src/components/mui';
import React from 'react';
import { SlideWrapper } from 'src/AdminApp/components/drawers/styledComponents';
import { FONT_FAMILY } from 'src/styles/theme';
import styled from 'styled-components';
import { RequestInfoDisplayText } from './commonStyles';
const InputText = styled('textarea') `
  margin: 10px 0;
  height: 5rem;
  width: 100%;
  padding: 0.5rem;
  display: block;
  align-items: center;
  justify-content: center;
  resize: none;
`;
const InformationDiv = styled('div') `
  margin: 10px 0;
  padding: 0.5rem;
  width: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  text-align: end;
  font-size: 0.875rem;
  font-family: ${FONT_FAMILY};
  font-weight: 400;
  line-height: 1.5;
`;
const LabelDiv = styled('div') `
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;
const getBoolean = (value) => {
    if (value === undefined)
        return value;
    return value === 'true';
};
const WarrantyDecisionsQuestions = ({ isEligible, isDefect, reason, setIsEligible, setIsDefect, setReason, }) => {
    return (<SlideWrapper>
      <Grid item xs={12}>
        <Grid item xs>
          <RequestInfoDisplayText>Confirm Details</RequestInfoDisplayText>
        </Grid>
        <LabelDiv>
          1. Is this repair covered by our AutoNation Mobile Service limited
          warranty?
        </LabelDiv>
        <Divider />
        <RadioGroup style={{ display: 'block', marginRight: '1rem' }} aria-labelledby="is-eligible" name="is-eligible" value={`${isEligible}`} onChange={({ target }) => {
            setIsEligible(getBoolean(target.value));
            if (target.value === 'false') {
                setIsDefect(undefined);
                setReason('');
            }
        }}>
          <FormControlLabel value="true" control={<Radio color="primary"/>} label="Yes, repair is covered"/>
          <Divider />
          <FormControlLabel value="false" control={<Radio color="primary"/>} label="No, repair is not covered"/>
          <Divider />
        </RadioGroup>

        {isEligible && (<Grid item xs={12}>
            <LabelDiv>
              2. Have you ever verified the alleged defect exists as a result of
              materials or workmanship?
            </LabelDiv>
            <Divider />
            <RadioGroup style={{ display: 'block', marginRight: '1rem' }} aria-labelledby="is-defect" name="is-defect" value={`${isDefect}`} onChange={({ target }) => {
                setIsDefect(getBoolean(target.value));
                if (target.value === 'false') {
                    setReason('');
                }
            }}>
              <FormControlLabel value="true" control={<Radio color="primary"/>} label="Yes, it's been verified"/>
              <Divider />
              <FormControlLabel value="false" control={<Radio color="primary"/>} label="No, it's never been verified"/>
              <Divider />
            </RadioGroup>
          </Grid>)}
        {isDefect && (<Grid item xs={12}>
            <LabelDiv>
              3. Please describe your reason for verifying the warranty claim:
            </LabelDiv>
            <InformationDiv>*Required</InformationDiv>
            <InputText id="reason" value={reason} onChange={(e) => setReason(e.target.value)} title="Reason" placeholder="Type here..." minLength={1} maxLength={500}/>
            <InformationDiv>{reason === null || reason === void 0 ? void 0 : reason.length}/500</InformationDiv>
          </Grid>)}
      </Grid>
    </SlideWrapper>);
};
export default WarrantyDecisionsQuestions;
