import { colors, theme } from 'src/styles/theme';
import styled from 'styled-components';
export const SearchBar = styled.div `
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    ${theme.breakpoints.up('md')} {
      margin-bottom: 0;
    }
  }

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
  }

  .searchWrapper {
    flex: 1;
  }

  button {
    min-width: 150px;
  }
`;
export const FilterWrapper = styled.div `
  margin: 16px 0;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  ${theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
  }
`;
export const FilterSection = styled.div `
  :not(:last-child) {
    margin-right: 32px;
  }
`;
export const FilterTitle = styled.div `
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  margin-bottom: 16px;
`;
export const FilterFieldset = styled.div `
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  align-items: center;

  > div:not(:last-child) {
    margin-right: 16px;
  }
`;
export const FilterActions = styled.div `
  text-align: right;
  padding: 24px 16px;
  border-top: 1px solid ${colors.GRAY_DE};

  button {
    padding: 16px;
    min-width: 150px;
  }
`;
export const NoResults = styled.div `
  background-color: ${colors.GRAY_F5};
  display: flex;
  height: 200px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
