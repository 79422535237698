import moment from 'moment';
import React, { useState } from 'react';
import { SimpleTableRow } from 'src/components/dashboard/SimpleTable';
import { Icon, Menu, MenuItem, Tooltip } from 'src/components/mui';
import { MoreVertRounded } from 'src/components/mui/icons';
import SimpleChip from 'src/components/SimpleChip';
import { MoreIconButton } from 'src/FleetVisitsApp/pages/VisitView/WorkOrdersTable/WorkOrdersTable.styles';
import { colors } from 'src/styles/theme';
import { DATE_TIME_12H_FORMAT } from 'src/timeConstants';
export const statusColors = {
    PENDING: colors.orange,
    IN_PROGRESS: colors.newCarBlue,
    GENERATING_INVOICES: colors.ORANGE_FF,
    SENDING_INVOICES: colors.lightOrange,
    COMPLETED: colors.success,
    COMPLETED_WITH_ERRORS: colors.error,
    CANCELED: colors.GRAY_76,
};
const InvoiceRequestItem = ({ item, onClickViewDetails, handleAcknowledge, handleCancelInvoiceRequest, }) => {
    var _a, _b, _c, _d;
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const menuOpen = Boolean(menuAnchorEl);
    const isAcknowledged = !!item.acknowledgedAt;
    const status = item.status.replace(/_/g, ' ');
    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };
    return (<SimpleTableRow data={[
            (_a = item.type) === null || _a === void 0 ? void 0 : _a.replace(/_/g, ' '),
            <>
          {(_b = item.responsibleParty) === null || _b === void 0 ? void 0 : _b.name} <wbr />(
          {(_c = item.responsibleParty) === null || _c === void 0 ? void 0 : _c.responsiblePartyTypeName})
        </>,
            <div style={{ textAlign: 'center' }}>
          <SimpleChip label={<Tooltip open={isAcknowledged ? undefined : false} title="Completed with errors, but acknowledged">
                <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 4,
                    }}>
                  {status}
                  <Icon style={{
                        fontSize: 14,
                        display: isAcknowledged ? 'inline-block' : 'none',
                    }}>
                    warning
                  </Icon>
                </div>
              </Tooltip>} $bgColor={statusColors[isAcknowledged ? 'COMPLETED_WITH_ERRORS' : item.status]} onClick={() => onClickViewDetails(item)}/>
        </div>,
            <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {item.created
                    ? moment.utc(item.created).local().format(DATE_TIME_12H_FORMAT)
                    : 'N/A'}
        </div>,
            <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {item.startedAt
                    ? moment.utc(item.startedAt).local().format(DATE_TIME_12H_FORMAT)
                    : 'N/A'}
        </div>,
            <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {item.completedAt
                    ? moment.utc(item.completedAt).local().format(DATE_TIME_12H_FORMAT)
                    : 'N/A'}
        </div>,
            <div style={{ textAlign: 'center' }}>{(_d = item.recipients) === null || _d === void 0 ? void 0 : _d.length}</div>,
            <div style={{ textAlign: 'center' }}>{item.numTotal}</div>,
            <div style={{ textAlign: 'center' }}>{item.numCompleted}</div>,
            <div style={{ textAlign: 'center', color: `${!!item.numErrors ? statusColors['COMPLETED_WITH_ERRORS'] : 'none'}` }}>{item.numErrors}</div>,
            <>
          <MoreIconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuClick}>
            <MoreVertRounded />
          </MoreIconButton>
          <Menu anchorEl={menuAnchorEl} keepMounted open={menuOpen} onClose={handleMenuClose}>
            <MenuItem onClick={() => {
                    handleMenuClose();
                    onClickViewDetails(item);
                }}>
              View Details
            </MenuItem>
            <MenuItem disabled={(item.status === 'COMPLETED_WITH_ERRORS' &&
                    !!item.acknowledgedAt) ||
                    item.status !== 'COMPLETED_WITH_ERRORS'} onClick={() => {
                    handleMenuClose();
                    handleAcknowledge(item.id);
                }}>
              Acknowledge
            </MenuItem>
            <MenuItem disabled={item.status !== 'PENDING'} onClick={() => {
                    handleMenuClose();
                    handleCancelInvoiceRequest(item.id);
                }}>
              Cancel
            </MenuItem>
          </Menu>
        </>,
        ]}/>);
};
export default InvoiceRequestItem;
