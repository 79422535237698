import { TextField, Autocomplete } from 'src/components/mui';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/modules/api/utils/useDebounce';
import { searchLocationsHubs } from 'src/SystemApp/modules/locations/actions';
import { selectLocationSearchResults } from 'src/SystemApp/modules/locations/selectors';
const HubSearch = ({ setSelectedLocations, value }) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const locationSearchResults = useSelector(selectLocationSearchResults);
    const searchLocationsDebounce = useDebounce(search, 500);
    let options = locationSearchResults;
    if (value) {
        // add value to search results
        if (isArray(value)) {
            options = [...locationSearchResults, ...value];
        }
        else {
            options = [...locationSearchResults, value];
        }
    }
    useEffect(() => {
        if ((searchLocationsDebounce === null || searchLocationsDebounce === void 0 ? void 0 : searchLocationsDebounce.length) > 1) {
            dispatch(searchLocationsHubs({ name: searchLocationsDebounce }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchLocationsDebounce]);
    const handleLocationsSelected = (e, newLocations) => {
        if (newLocations) {
            setSelectedLocations(newLocations);
        }
    };
    return (<Autocomplete multiple clearOnBlur={false} inputValue={search} options={options} getOptionLabel={(option) => option.name} filterSelectedOptions filterOptions={(x) => x} onChange={handleLocationsSelected} onInputChange={(event, newInputValue, reason) => {
            if (reason === 'input') {
                setSearch(newInputValue);
            }
        }} isOptionEqualToValue={(option, value) => (option === null || option === void 0 ? void 0 : option.id) === (value === null || value === void 0 ? void 0 : value.id)} ChipProps={{
            color: 'secondary',
        }} value={value} renderInput={(params) => (<TextField {...params} variant="standard" placeholder="Search by name" label="Hub Search" name="locationsSelect"/>)}/>);
};
export default HubSearch;
