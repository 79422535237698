var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { isEmpty } from 'lodash';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { toast } from 'src/components/SimpleToast';
import { getMetroById } from 'src/AdminApp/modules/metros/actions';
import { metroById } from 'src/AdminApp/modules/metros/selectors';
import { getRegionById } from 'src/AdminApp/modules/regions/actions';
import { regionById } from 'src/AdminApp/modules/regions/selectors';
import { getRequest } from 'src/AdminApp/modules/requests/actions';
import { getTerritoryById } from 'src/AdminApp/modules/territories/actions';
import { selectTerritoryById } from 'src/AdminApp/modules/territories/selectors';
import createDefaultActionCreators from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import NotesEntityType from 'src/AdminApp/models/enums/NotesEntityTypes';
import NotesOriginType from 'src/AdminApp/models/enums/NotesOriginTypes';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { lookupRequestIdByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
// FSA ACTIONS
export const ActionCreators = {
    SET_DASHBOARD_DATE: createDefaultActionCreators('SET_DASHBOARD_DATE'),
    SET_FILTERS: createDefaultActionCreators('SET_FILTERS'),
    GET_WORK_ORDER_SUBLETS: createDefaultActionCreators('GET_WORK_ORDER_SUBLETS'),
    SEARCH_WORK_ORDERS: createDefaultActionCreators('SEARCH_WORK_ORDERS'),
    FIND_WORK_ORDERS: createDefaultActionCreators('FIND_WORK_ORDERS'),
    GET_WORK_ORDERS_COUNT: createDefaultActionCreators('GET_WORK_ORDERS_COUNT'),
    GET_WORK_ORDER: createDefaultActionCreators('GET_WORK_ORDER'),
    GET_WORK_ORDERS_NEW: createDefaultActionCreators('GET_WORK_ORDERS_NEW'),
    GET_WORK_ORDERS_NEEDS_ATTENTION: createDefaultActionCreators('GET_WORK_ORDERS_NEEDS_ATTENTION'),
    CREATE_WORK_ORDER: createDefaultActionCreators('CREATE_WORK_ORDER'),
    UPDATE_WORK_ORDER: createDefaultActionCreators('UPDATE_WORK_ORDER'),
    UPDATE_TASK: createDefaultActionCreators('UPDATE_TASK'),
    WORK_ORDER_CREATE_NOTES: createDefaultActionCreators('WORK_ORDER_CREATE_NOTES'),
    WORK_ORDER_UPDATE_NOTES: createDefaultActionCreators('WORK_ORDER_UPDATE_NOTES'),
    WORK_ORDER_DELETE_NOTES: createDefaultActionCreators('WORK_ORDER_DELETE_NOTES'),
    GET_ASSIGNED_ADVISORS: createDefaultActionCreators('GET_ASSIGNED_ADVISORS'),
    PROMO_CODE_APPLY: createDefaultActionCreators('PROMO_CODE_APPLY'),
    PROMO_CODE_GET: createDefaultActionCreators('PROMO_CODE_GET'),
    PROMO_CODE_REMOVE: createDefaultActionCreators('PROMO_CODE_REMOVE'),
    PROMO_CODE_REDEEM: createDefaultActionCreators('PROMO_CODE_REDEEM'),
    QUOTE_DISCOUNT_APPLY: createDefaultActionCreators('QUOTE_DISCOUNT_APPLY'),
    APPLY_PROMO_CODE_INVOICE: createDefaultActionCreators('APPLY_PROMO_CODE_INVOICE'),
    QUOTE_DISCOUNT_REMOVE: createDefaultActionCreators('QUOTE_DISCOUNT_REMOVE'),
    DELETE_DOCUMENT: createDefaultActionCreators('DELETE_DOCUMENT'),
    UPLOAD_DOCUMENTS: createDefaultActionCreators('UPLOAD_DOCUMENTS'),
    INVOICE_DISCOUNT_APPLY: createDefaultActionCreators('INVOICE_DISCOUNT_APPLY'),
    INVOICE_DISCOUNT_REMOVE: createDefaultActionCreators('INVOICE_DISCOUNT_REMOVE'),
    WORK_ORDER_DISCOUNT_GET: createDefaultActionCreators('WORK_ORDER_DISCOUNT_GET'),
    QUOTE_CALCULATE_TAX: createDefaultActionCreators('QUOTE_CALCULATE_TAX'),
    FMC_SEND_FOR_APPROVAL: createDefaultActionCreators('FMC_SEND_FOR_APPROVAL'),
    FMC_WORK_AUTHORIZATION: createDefaultActionCreators('FMC_WORK_AUTHORIZATION'),
};
export const getWorkOrderSublets = ({ workOrderId, referenceNum, }) => (dispatch) => {
    return dispatch(createAPIAction({
        types: [
            ActionCreators.GET_WORK_ORDER_SUBLETS.FETCH(),
            ActionCreators.GET_WORK_ORDER_SUBLETS.SUCCESS({
                meta: () => ({ workOrderId, referenceNum }),
            }),
            ActionCreators.GET_WORK_ORDER_SUBLETS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.WORK_ORDER_SUBLETS({ workOrderId }),
        method: 'GET',
    }));
};
const getAssignedAdvisors = ({ displayedDate }) => createAPIAction({
    types: [
        ActionCreators.GET_ASSIGNED_ADVISORS.FETCH(),
        ActionCreators.GET_ASSIGNED_ADVISORS.SUCCESS({
            meta: () => ({ displayedDate }),
        }),
        ActionCreators.GET_ASSIGNED_ADVISORS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.WORK_ORDERS_DASHBOARD_ASSIGNED_ADVISORS({
        displayedDate,
    }),
    method: 'GET',
});
export const processGeographyFilters = (geography, dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, e_1, _b, _c, _d, e_2, _e, _f, _g, e_3, _h, _j, _k, e_4, _l, _m, _o, e_5, _p, _q, _r, e_6, _s, _t;
    let ids = [];
    try {
        /* eslint-disable no-await-in-loop, no-restricted-syntax */
        for (var _u = true, _v = __asyncValues(Object.entries(geography)), _w; _w = yield _v.next(), _a = _w.done, !_a;) {
            _c = _w.value;
            _u = false;
            try {
                const [tId, territoryValue] = _c;
                yield dispatch(getTerritoryById(tId));
                const territory = selectTerritoryById(+tId)(getState());
                if (territoryValue === true) {
                    try {
                        for (var _x = true, _y = (e_2 = void 0, __asyncValues(territory.metros)), _z; _z = yield _y.next(), _d = _z.done, !_d;) {
                            _f = _z.value;
                            _x = false;
                            try {
                                const m = _f;
                                yield dispatch(getMetroById(m.id));
                                const metro = metroById(m.id)(getState());
                                try {
                                    for (var _0 = true, _1 = (e_3 = void 0, __asyncValues(metro.regions)), _2; _2 = yield _1.next(), _g = _2.done, !_g;) {
                                        _j = _2.value;
                                        _0 = false;
                                        try {
                                            const r = _j;
                                            yield dispatch(getRegionById(r.id));
                                            const region = regionById(r.id)(getState());
                                            const serviceAreasIds = region.serviceAreas
                                                ? region.serviceAreas.map((s) => s.id)
                                                : [];
                                            ids = [...ids, ...serviceAreasIds];
                                        }
                                        finally {
                                            _0 = true;
                                        }
                                    }
                                }
                                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                                finally {
                                    try {
                                        if (!_0 && !_g && (_h = _1.return)) yield _h.call(_1);
                                    }
                                    finally { if (e_3) throw e_3.error; }
                                }
                            }
                            finally {
                                _x = true;
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (!_x && !_d && (_e = _y.return)) yield _e.call(_y);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
                else if (!isEmpty(territoryValue)) {
                    try {
                        for (var _3 = true, _4 = (e_4 = void 0, __asyncValues(Object.entries(territoryValue))), _5; _5 = yield _4.next(), _k = _5.done, !_k;) {
                            _m = _5.value;
                            _3 = false;
                            try {
                                const [mId, metroValue] = _m;
                                yield dispatch(getMetroById(mId));
                                const metro = metroById(mId)(getState());
                                if (metroValue === true) {
                                    try {
                                        for (var _6 = true, _7 = (e_5 = void 0, __asyncValues(metro.regions)), _8; _8 = yield _7.next(), _o = _8.done, !_o;) {
                                            _q = _8.value;
                                            _6 = false;
                                            try {
                                                const r = _q;
                                                yield dispatch(getRegionById(r.id));
                                                const region = regionById(r.id)(getState());
                                                const serviceAreasIds = region.serviceAreas
                                                    ? region.serviceAreas.map((s) => s.id)
                                                    : [];
                                                ids = [...ids, ...serviceAreasIds];
                                            }
                                            finally {
                                                _6 = true;
                                            }
                                        }
                                    }
                                    catch (e_5_1) { e_5 = { error: e_5_1 }; }
                                    finally {
                                        try {
                                            if (!_6 && !_o && (_p = _7.return)) yield _p.call(_7);
                                        }
                                        finally { if (e_5) throw e_5.error; }
                                    }
                                }
                                else if (!isEmpty(metroValue)) {
                                    try {
                                        for (var _9 = true, _10 = (e_6 = void 0, __asyncValues(Object.entries(metroValue))), _11; _11 = yield _10.next(), _r = _11.done, !_r;) {
                                            _t = _11.value;
                                            _9 = false;
                                            try {
                                                const [rId, regionValue] = _t;
                                                yield dispatch(getRegionById(rId));
                                                const region = regionById(rId)(getState());
                                                if (regionValue === true) {
                                                    const serviceAreasIds = region.serviceAreas
                                                        ? region.serviceAreas.map((s) => s.id)
                                                        : [];
                                                    ids = [...ids, ...serviceAreasIds];
                                                }
                                                else if (!isEmpty(regionValue)) {
                                                    const serviceAreasIds = Object.entries(regionValue).map((r) => r[0]);
                                                    ids = [...ids, ...serviceAreasIds];
                                                }
                                            }
                                            finally {
                                                _9 = true;
                                            }
                                        }
                                    }
                                    catch (e_6_1) { e_6 = { error: e_6_1 }; }
                                    finally {
                                        try {
                                            if (!_9 && !_r && (_s = _10.return)) yield _s.call(_10);
                                        }
                                        finally { if (e_6) throw e_6.error; }
                                    }
                                }
                            }
                            finally {
                                _3 = true;
                            }
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (!_3 && !_k && (_l = _4.return)) yield _l.call(_4);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
            }
            finally {
                _u = true;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (!_u && !_a && (_b = _v.return)) yield _b.call(_v);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return ids;
});
export const searchWorkOrders = ({ pageSize = 20, pageIndex = 0, filters, displayedDate, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const serviceAreaIds = !isEmpty(filters.geography) &&
        (yield processGeographyFilters(filters.geography, dispatch, getState));
    const params = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!filters.createdTo && {
        createdTo: filters.createdTo.format(DATE_YMD_FORMAT),
    })), (!!filters.createdFrom && {
        createdFrom: filters.createdFrom.format(DATE_YMD_FORMAT),
    })), (!!filters.dueDateTo && {
        dueDateTo: filters.dueDateTo.format(DATE_YMD_FORMAT),
    })), (!!filters.dueDateFrom && {
        dueDateFrom: filters.dueDateFrom.format(DATE_YMD_FORMAT),
    })), (!!filters.subletAssignedTo && {
        subletAssignedTo: filters.subletAssignedTo,
    })), (!!filters.unassigned && { unassigned: filters.unassigned })), (!!serviceAreaIds && { serviceAreaIds })), (!!filters.valetTaskStatus && {
        valetTaskStatus: filters.valetTaskStatus,
    }));
    const getCompletedCount = () => dispatch(createAPIAction({
        types: [
            ActionCreators.GET_WORK_ORDERS_COUNT.FETCH({
                meta: () => ({}),
            }),
            ActionCreators.GET_WORK_ORDERS_COUNT.SUCCESS({
                meta: () => ({}),
            }),
            ActionCreators.GET_WORK_ORDERS_COUNT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.WORK_ORDERS_DASHBOARD_COUNT({
            displayedDate,
        }),
        method: 'GET',
    }));
    getCompletedCount();
    dispatch(getAssignedAdvisors({ displayedDate }));
    return dispatch(createAPIAction({
        types: [
            ActionCreators.SEARCH_WORK_ORDERS.FETCH({
                meta: () => ({ pageSize, pageIndex, params }),
            }),
            ActionCreators.SEARCH_WORK_ORDERS.SUCCESS({
                meta: () => ({
                    pageSize,
                    pageIndex,
                    params,
                    needsAttention: false,
                }),
            }),
            ActionCreators.SEARCH_WORK_ORDERS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.WORK_ORDERS_DASHBOARD(),
        params: Object.assign({ displayedDate }, params),
        method: 'GET',
    }));
});
export const findWorkOrder = ({ referenceNum, workOrderIds, }) => (dispatch, getState) => {
    const repairRequestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    return dispatch(createAPIAction({
        types: [
            ActionCreators.FIND_WORK_ORDERS.FETCH(),
            ActionCreators.FIND_WORK_ORDERS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.FIND_WORK_ORDERS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.WORK_ORDER(),
        method: 'GET',
        params: {
            repairRequestId,
            workOrderIds,
        },
    }));
};
export const getWorkOrdersNeedsAttention = () => createAPIAction({
    types: [
        ActionCreators.GET_WORK_ORDERS_NEEDS_ATTENTION.FETCH(),
        ActionCreators.GET_WORK_ORDERS_NEEDS_ATTENTION.SUCCESS({
            meta: () => ({ needsAttention: true }),
        }),
        ActionCreators.GET_WORK_ORDERS_NEEDS_ATTENTION.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.WORK_ORDERS_NEEDS_ATTENTION(),
    method: 'GET',
});
export const createWorkOrder = ({ orderId, referenceNum }) => (dispatch, getState) => {
    const repairRequestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    return dispatch(createAPIAction({
        types: [
            ActionCreators.CREATE_WORK_ORDER.FETCH(),
            ActionCreators.CREATE_WORK_ORDER.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.CREATE_WORK_ORDER.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.WORK_ORDER(),
        method: 'POST',
        body: {
            repairRequestId,
            repairOrderId: orderId,
            tow: false,
        },
    }));
};
export const updateWorkOrder = ({ subletAssignedTo = undefined, subletAssignedToName = undefined, valetTasksTimeline = undefined, paidBy = undefined, workOrderId, }) => (dispatch) => {
    const body = Object.assign(Object.assign(Object.assign(Object.assign({}, (subletAssignedTo && { subletAssignedTo })), (subletAssignedToName && { subletAssignedToName })), (valetTasksTimeline && { valetTasksTimeline })), (paidBy && { paidBy }));
    return dispatch(createAPIAction({
        types: [
            ActionCreators.UPDATE_WORK_ORDER.FETCH(),
            ActionCreators.UPDATE_WORK_ORDER.SUCCESS({
                meta: () => ({}),
            }),
            ActionCreators.UPDATE_WORK_ORDER.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.WORK_ORDER_BY_ID({ workOrderId }),
        method: 'PATCH',
        body,
    }));
};
export const getWorkOrder = ({ workOrderId, referenceNum = null, }) => createAPIAction({
    types: [
        ActionCreators.GET_WORK_ORDER.FETCH(),
        ActionCreators.GET_WORK_ORDER.SUCCESS({
            meta: () => ({ workOrderId, referenceNum }),
        }),
        ActionCreators.GET_WORK_ORDER.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.WORK_ORDER_BY_ID({ workOrderId }),
    method: 'GET',
});
export const updateTask = ({ referenceNum, taskId, workOrderId, updatedTask, }) => createAPIAction({
    types: [
        ActionCreators.UPDATE_TASK.FETCH(),
        ActionCreators.UPDATE_TASK.SUCCESS({
            meta: () => ({ workOrderId, referenceNum, taskId }),
        }),
        ActionCreators.UPDATE_TASK.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.TASK_BY_ID({ taskId }),
    method: 'PATCH',
    body: updatedTask,
});
export const createWorkOrderNotes = ({ note, workOrderId, }) => createAPIAction({
    types: [
        ActionCreators.WORK_ORDER_CREATE_NOTES.FETCH(),
        ActionCreators.WORK_ORDER_CREATE_NOTES.SUCCESS({
            meta: () => ({ workOrderId }),
        }),
        ActionCreators.WORK_ORDER_CREATE_NOTES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.NOTES(),
    method: 'POST',
    body: {
        content: note,
        origin: NotesOriginType.TOOLS,
        entityType: NotesEntityType.WORK_ORDER,
        entityId: workOrderId,
    },
});
export const updateWorkOrderNotes = ({ note, noteId, workOrderId, }) => createAPIAction({
    types: [
        ActionCreators.WORK_ORDER_UPDATE_NOTES.FETCH(),
        ActionCreators.WORK_ORDER_UPDATE_NOTES.SUCCESS({
            meta: () => ({ workOrderId }),
        }),
        ActionCreators.WORK_ORDER_UPDATE_NOTES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.NOTES_BY_ID(noteId),
    method: 'PATCH',
    body: note,
});
export const deleteWorkOrderNotes = ({ noteId, workOrderId, }) => createAPIAction({
    types: [
        ActionCreators.WORK_ORDER_DELETE_NOTES.FETCH(),
        ActionCreators.WORK_ORDER_DELETE_NOTES.SUCCESS({
            meta: () => ({ noteId, workOrderId }),
        }),
        ActionCreators.WORK_ORDER_DELETE_NOTES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.NOTES_BY_ID(noteId),
    method: 'DELETE',
});
export const getDiscounts = ({ workOrderId }) => createAPIAction({
    types: [
        ActionCreators.WORK_ORDER_DISCOUNT_GET.FETCH(),
        ActionCreators.WORK_ORDER_DISCOUNT_GET.SUCCESS({
            meta: () => ({ workOrderId }),
        }),
        ActionCreators.WORK_ORDER_DISCOUNT_GET.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DISCOUNTS_BY_WORK_ORDER({ workOrderId }),
    method: 'GET',
});
export const applyQuotePromoCode = ({ referenceNum, workOrderId, promoCode, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const applyPromoCodeAction = createAPIAction({
        types: [
            ActionCreators.PROMO_CODE_APPLY.FETCH(),
            ActionCreators.PROMO_CODE_APPLY.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.PROMO_CODE_APPLY.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PROMO_CODE_APPLY_TO_QUOTE({
            workOrderId,
            promoCode,
        }),
        method: 'POST',
    });
    const response = yield dispatch(applyPromoCodeAction);
    dispatch(getRequest(referenceNum, requestId));
    return response;
});
export const redeemPromoCode = ({ workOrderId }) => createAPIAction({
    types: [
        ActionCreators.PROMO_CODE_REDEEM.FETCH(),
        ActionCreators.PROMO_CODE_REDEEM.SUCCESS({
            meta: () => ({ workOrderId }),
        }),
        ActionCreators.PROMO_CODE_REDEEM.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.PROMO_CODE_REDEEM({ workOrderId }),
    method: 'POST',
});
export const applyQuoteCancelFeeCredit = ({ referenceNum, workOrderId, originalInvoiceId, creditAmount, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const applyPromoCodeAction = createAPIAction({
        types: [
            ActionCreators.QUOTE_DISCOUNT_APPLY.FETCH(),
            ActionCreators.QUOTE_DISCOUNT_APPLY.SUCCESS({
                meta: () => ({ referenceNum, workOrderId }),
            }),
            ActionCreators.QUOTE_DISCOUNT_APPLY.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISCOUNTS_BY_WORK_ORDER({ workOrderId }),
        body: {
            originalInvoiceId,
            discountedAmount: creditAmount,
            discountType: 'CANCEL_FEE_CREDIT',
            workOrderStage: 'QUOTE',
        },
        method: 'POST',
    });
    const response = yield dispatch(applyPromoCodeAction);
    dispatch(getRequest(referenceNum, requestId));
    return response;
});
export const applyQuoteOtherDiscount = ({ referenceNum, workOrderId, discountReasonType, discountedAmount, reasonDescription, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const applyPromoCodeAction = createAPIAction({
        types: [
            ActionCreators.QUOTE_DISCOUNT_APPLY.FETCH(),
            ActionCreators.QUOTE_DISCOUNT_APPLY.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.QUOTE_DISCOUNT_APPLY.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISCOUNTS_BY_WORK_ORDER({ workOrderId }),
        body: {
            discountReasonType,
            reasonDescription,
            discountedAmount,
            discountType: 'CUSTOM_DISCOUNT',
            workOrderStage: 'QUOTE',
        },
        method: 'POST',
    });
    const response = yield dispatch(applyPromoCodeAction);
    dispatch(getRequest(referenceNum, requestId));
    return response;
});
export const applyInvoicePromoCode = ({ workOrderId, promoCode }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.APPLY_PROMO_CODE_INVOICE.FETCH(),
            ActionCreators.APPLY_PROMO_CODE_INVOICE.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.APPLY_PROMO_CODE_INVOICE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PROMO_CODE_APPLY_TO_INVOICE({
            workOrderId,
            promoCode,
        }),
        method: 'POST',
    }));
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const applyInvoiceCancelFeeCredit = ({ workOrderId, originalInvoiceId, creditAmount, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const applyPromoCodeAction = createAPIAction({
        types: [
            ActionCreators.INVOICE_DISCOUNT_APPLY.FETCH(),
            ActionCreators.INVOICE_DISCOUNT_APPLY.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.INVOICE_DISCOUNT_APPLY.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISCOUNTS_BY_WORK_ORDER({ workOrderId }),
        body: {
            originalInvoiceId,
            discountedAmount: creditAmount,
            discountType: 'CANCEL_FEE_CREDIT',
            workOrderStage: 'INVOICE',
        },
        method: 'POST',
    });
    const response = yield dispatch(applyPromoCodeAction);
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const applyInvoiceOtherDiscount = ({ workOrderId, discountedAmount, discountReasonType, reasonDescription, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const applyPromoCodeAction = createAPIAction({
        types: [
            ActionCreators.INVOICE_DISCOUNT_APPLY.FETCH(),
            ActionCreators.INVOICE_DISCOUNT_APPLY.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.INVOICE_DISCOUNT_APPLY.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISCOUNTS_BY_WORK_ORDER({ workOrderId }),
        body: {
            reasonDescription,
            discountedAmount,
            discountReasonType,
            discountType: 'CUSTOM_DISCOUNT',
            workOrderStage: 'INVOICE',
        },
        method: 'POST',
    });
    const response = yield dispatch(applyPromoCodeAction);
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const removeDiscount = ({ referenceNum, workOrderId, discountId, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const removeDiscountAction = createAPIAction({
        types: [
            ActionCreators.QUOTE_DISCOUNT_REMOVE.FETCH(),
            ActionCreators.QUOTE_DISCOUNT_REMOVE.SUCCESS({
                meta: () => ({ referenceNum, workOrderId }),
            }),
            ActionCreators.QUOTE_DISCOUNT_REMOVE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REMOVE_DISCOUNT({
            workOrderId,
            discountId,
        }),
        method: 'DELETE',
    });
    const response = yield dispatch(removeDiscountAction);
    dispatch(getRequest(referenceNum, requestId));
    return response;
});
export const removeInvoiceDiscount = ({ workOrderId, discountId }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const removeDiscountAction = createAPIAction({
        types: [
            ActionCreators.INVOICE_DISCOUNT_REMOVE.FETCH(),
            ActionCreators.INVOICE_DISCOUNT_REMOVE.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.INVOICE_DISCOUNT_REMOVE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REMOVE_DISCOUNT({
            workOrderId,
            discountId,
        }),
        method: 'DELETE',
    });
    const response = yield dispatch(removeDiscountAction);
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const uploadDocuments = (workOrderId, documents) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const formData = new FormData();
    documents.forEach((file) => {
        formData.append('documents', file);
    });
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.UPLOAD_DOCUMENTS.FETCH(),
            ActionCreators.UPLOAD_DOCUMENTS.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.UPLOAD_DOCUMENTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.UPLOAD_DOCUMENTS(workOrderId),
        method: 'POST',
        formData,
    }));
    if (!response.error) {
        response.payload.forEach((document) => toast.success(`Successfully uploaded ${document.name}`));
    }
    return response;
});
export const deleteDocument = (workOrderId, document) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.DELETE_DOCUMENT.FETCH(),
            ActionCreators.DELETE_DOCUMENT.SUCCESS({
                meta: () => ({ workOrderId, document }),
            }),
            ActionCreators.DELETE_DOCUMENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DELETE_DOCUMENT_BY_ID({
            workOrderId,
            document,
        }),
        method: 'DELETE',
    }));
    if (!response.error) {
        toast.success(`Document successfully deleted: ${document.name}`);
    }
    return response;
});
export const calculateQuoteTax = (quoteId, workOrderId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.QUOTE_CALCULATE_TAX.FETCH(),
            ActionCreators.QUOTE_CALCULATE_TAX.SUCCESS(),
            ActionCreators.QUOTE_CALCULATE_TAX.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.QUOTE_CALCULATE_TAX(quoteId),
        method: 'POST',
    }));
    dispatch(getWorkOrder({ workOrderId }));
    return response;
});
export const fmcSendForApproval = (workOrderId, submissionMethod, authorizationType, recommendedRepairIds) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _12, _13;
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.FMC_SEND_FOR_APPROVAL.FETCH(),
            ActionCreators.FMC_SEND_FOR_APPROVAL.SUCCESS({
                meta: () => ({ workOrderId, submissionMethod, authorizationType }),
            }),
            ActionCreators.FMC_SEND_FOR_APPROVAL.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FMC_SEND_FOR_APPROVAL({
            workOrderId,
            submissionMethod,
            authorizationType,
            recommendedRepairIds,
        }),
        method: 'POST',
    }));
    const { errors, error } = response;
    if ((!errors || errors.length === 0) && !error) {
        toast.success(`Order successfully sent for Approval ${(_12 = response === null || response === void 0 ? void 0 : response.payload) === null || _12 === void 0 ? void 0 : _12.referenceNum}`);
    }
    else if (!error && errors && errors.length > 0) {
        const errorsText = (_13 = errors
            .map((error) => error.errorMessage)) === null || _13 === void 0 ? void 0 : _13.join(', ');
        toast.error(`There were some errors while trying to send for approval: ${errorsText}`);
    }
    return response;
});
export const getFmcWorkAuthorization = (workOrderId, authorizationType) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    return dispatch(createAPIAction({
        types: [
            ActionCreators.FMC_WORK_AUTHORIZATION.FETCH(),
            ActionCreators.FMC_WORK_AUTHORIZATION.SUCCESS({
                meta: () => ({ workOrderId, authorizationType }),
            }),
            ActionCreators.FMC_WORK_AUTHORIZATION.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FMC_GET_WORK_AUTHORIZATION({
            workOrderId,
            authorizationType,
        }),
        method: 'GET',
    }));
});
