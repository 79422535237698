import React from 'react';
import FmcRepairSearchSelect from 'src/components/form/FmcRepairSearchSelect';
import { SubText } from 'src/components/SimpleDrawer';
const FmcRepairSearch = ({ customRepairText, onSelect, }) => {
    return (<>
      <SubText>
        You will need to select the matching repair in AutoIntegrates catalog
        for {customRepairText}
      </SubText>
      <FmcRepairSearchSelect onSelect={onSelect}/>
    </>);
};
export default FmcRepairSearch;
