var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import numeral from 'numeral';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConsumerById } from 'src/AdminApp/modules/consumers/selectors';
import { selectFleetById } from 'src/AdminApp/modules/fleets/selectors';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { clearResponsiblePartyTypeModalRepair, getWorkOrderPayments, loadRequest, removeWorkOrderResponsiblePartyAuthorization, saveResponsibleParties, } from 'src/AdminApp/modules/requests/actions';
import { updateFleetVehicleResponsibleParties, getFleet, } from 'src/AdminApp/modules/fleets/actions';
import { selectContactInfo, selectPrimaryResponsibleParty, selectResponsiblePartyTypeModal, selectResponsiblePartyTypes, selectWorkOrderItemResponsibleParties, selectWorkOrderItemResponsiblePartiesOfCustomRepair, selectWorkOrderItemResponsiblePartiesOfRepair, selectWorkOrderItemResponsiblePartyAuthorizations, } from 'src/AdminApp/modules/requests/selectors';
import { getWorkOrder } from 'src/AdminApp/modules/workOrders/actions';
import { toast } from 'src/components/SimpleToast';
import useConfirmModal from 'src/hooks/useConfirmModal';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { removeStyles } from './PayerDrawer.styles';
import { isEqual } from 'lodash';
import useResponsiblePartySelect from './ResponsiblePartySelect/useResponsiblePartySelect';
const allowedNonPrimaryResponsiblePartyTypeNames = [
    '3rd Party Pay',
    'AutoNation Pay',
    'Dealer Pay',
    'Partner Pay',
];
const thirdPartyModalTitle = `Removing this 3rd Party Payer will remove any existing Authorization #.
Are you sure you want to proceed?
`;
const amountErrMessage = 'Payer amounts sum must be equal to total repair cost.';
const amountSplitErrorMessage = 'Payer amount should be 0% or 100% of total repair cost.';
const samePayerErrMessage = 'No duplicate payers allowed.';
const initVals = {
    amount1: undefined,
    amount2: undefined,
    has2ResponsibleParties: false,
    responsibleParty1: {},
    responsibleParty2: {},
};
const usePayerDrawer = ({ invoice, workOrderId, handleClose, formRef, open, car, }) => {
    var _a, _b, _c, _d, _e, _f;
    const { referenceNum, selectorProps } = usePageReferenceNum();
    const dispatch = useDispatch();
    const isDone = useRef(false);
    const [initValues, setInitValues] = useState(initVals);
    const [payerUpdate, setPayerUpdate] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [openAssignRpToVinModal, setOpenAssignRpToVinModal] = useState();
    const info = useSelector(selectResponsiblePartyTypeModal);
    const primaryResponsibleParty = useSelector((state) => selectPrimaryResponsibleParty(state, selectorProps));
    const workOrderItemResponsiblePartiesByReferenceNum = useSelector(selectWorkOrderItemResponsibleParties(referenceNum));
    const authorizations = useSelector(selectWorkOrderItemResponsiblePartyAuthorizations(referenceNum));
    const workOrderIsAuthorized = !!authorizations.find((auth) => {
        var _a, _b;
        return (info === null || info === void 0 ? void 0 : info.repairId) &&
            (((_a = auth.suggestedRepairIds) === null || _a === void 0 ? void 0 : _a.includes(info === null || info === void 0 ? void 0 : info.repairId)) ||
                ((_b = auth.suggestedRepairIds) === null || _b === void 0 ? void 0 : _b.includes(info === null || info === void 0 ? void 0 : info.suggestedRepairId)));
    });
    const contactInfo = useSelector((state) => selectContactInfo(state, selectorProps));
    const consumerId = contactInfo ? +contactInfo.consumerId : 0;
    const consumer = useSelector(selectConsumerById(consumerId));
    const fleetId = consumer === null || consumer === void 0 ? void 0 : consumer.fleetId;
    const fleet = useSelector(selectFleetById(fleetId !== null && fleetId !== void 0 ? fleetId : ''));
    let workOrderItemResponsibleParties = [];
    const { fetchAllResponsiblePartiesByType, availableFleetResponsibleParties: availableResponsibleParties, } = useResponsiblePartySelect({
        fleet,
        vehicle: car,
        showRpTypeSuffix: true,
    });
    const workOrderItemResponsiblePartiesOfRepair = invoice
        ? (_c = (_b = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) === null || _b === void 0 ? void 0 : _b.find((x) => x.id === info.repairId)) === null || _c === void 0 ? void 0 : _c.workOrderItemResponsibleParties
        : useSelector(selectWorkOrderItemResponsiblePartiesOfRepair(referenceNum, info.repairId));
    const workOrderItemResponsiblePartiesOfCustomRepair = invoice
        ? (_f = (_e = (_d = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _d === void 0 ? void 0 : _d.customRequests) === null || _e === void 0 ? void 0 : _e.find((x) => x.id === info.customRepairId)) === null || _f === void 0 ? void 0 : _f.workOrderItemResponsibleParties
        : useSelector(selectWorkOrderItemResponsiblePartiesOfCustomRepair(referenceNum, info.customRepairId));
    workOrderItemResponsibleParties =
        workOrderItemResponsiblePartiesOfRepair ||
            workOrderItemResponsiblePartiesOfCustomRepair ||
            [];
    const responsiblePartyTypes = useSelector(selectResponsiblePartyTypes);
    useEffect(() => {
        if (open) {
            (() => __awaiter(void 0, void 0, void 0, function* () {
                yield fetchAllResponsiblePartiesByType();
                setIsSubmitting(false);
            }))();
        }
    }, [open]);
    useEffect(() => {
        if (!isDone.current && (info === null || info === void 0 ? void 0 : info.repairId) && (info === null || info === void 0 ? void 0 : info.repairName)) {
            const values = workOrderItemResponsibleParties === null || workOrderItemResponsibleParties === void 0 ? void 0 : workOrderItemResponsibleParties.reduce((prev, current, index) => {
                const num = index + 1;
                const rpType = responsiblePartyTypes.find((rpt) => rpt.id === current.responsibleParty.responsiblePartyTypeId);
                return Object.assign(Object.assign({}, prev), { [`amount${num}`]: current.amount || 0, [`responsibleParty${num}`]: Object.assign(Object.assign({}, current.responsibleParty), { responsiblePartyType: rpType, suffix: rpType === null || rpType === void 0 ? void 0 : rpType.name }), has2ResponsibleParties: index > 0 });
            }, {});
            setInitValues((prev) => (Object.assign(Object.assign({}, prev), values)));
            isDone.current = true;
        }
        if (!(info === null || info === void 0 ? void 0 : info.repairId)) {
            setInitValues(initVals);
        }
        return () => {
            isDone.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info === null || info === void 0 ? void 0 : info.repairId, info === null || info === void 0 ? void 0 : info.repairName, initVals, responsiblePartyTypes]);
    const handleCloseCleanup = useCallback(() => {
        dispatch(clearResponsiblePartyTypeModalRepair());
        setPayerUpdate(undefined);
        dispatch(getWorkOrderPayments(workOrderId));
        dispatch(loadRequest(referenceNum, true));
        setOpenAssignRpToVinModal(undefined);
        handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initValues.has2ResponsibleParties]);
    const onClose = useCallback(() => {
        if (!(formRef === null || formRef === void 0 ? void 0 : formRef.current))
            return true;
        const { dirty, isValid } = formRef === null || formRef === void 0 ? void 0 : formRef.current;
        if (!dirty && isValid && !isSubmitting) {
            handleCloseCleanup();
            return false;
        }
        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef === null || formRef === void 0 ? void 0 : formRef.current]);
    const handleSubmitRemoveAuth = useCallback(() => {
        dispatch(removeWorkOrderResponsiblePartyAuthorization(referenceNum, ''));
        handleCloseCleanup();
        setIsSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleCloseCleanup, payerUpdate, referenceNum]);
    const { handleOpenModal, modal } = useConfirmModal({
        closeDiscardCb: handleCloseCleanup,
        openCb: onClose,
    });
    const { modal: thirdPartyModal } = useConfirmModal({
        closeDiscardCb: handleSubmitRemoveAuth,
        confirmBtn: 'No',
        discardBtn: 'Yes',
        dismiss: true,
        title: thirdPartyModalTitle,
    });
    const handleAddPayer = () => {
        if (!(formRef === null || formRef === void 0 ? void 0 : formRef.current))
            return;
        formRef.current.setFieldValue('amount1', 0);
        formRef.current.setFieldValue('amount2', info.totalCost);
        formRef.current.setFieldValue('has2ResponsibleParties', true);
    };
    const handleRemovePayer = (e) => {
        if (!(formRef === null || formRef === void 0 ? void 0 : formRef.current))
            return;
        const { currentTarget: { name }, } = e;
        const { setFieldValue, validateForm, values } = formRef.current;
        if (name === '1') {
            setFieldValue('responsibleParty1', values.responsibleParty2);
        }
        setFieldValue('amount1', info.totalCost);
        setFieldValue('amount2', undefined);
        setFieldValue('responsibleParty2', {});
        setFieldValue('has2ResponsibleParties', false);
        setTimeout(() => {
            validateForm();
        }, 0);
    };
    const getAmountFixed = (amount) => {
        return amount === null || amount === undefined || amount === 0;
    };
    const handleFormSubmit = (values, helpers) => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h, _j, _k;
        if (isSubmitting)
            return;
        const { amount1, amount2, responsibleParty1, responsibleParty2 } = values;
        setIsSubmitting(true);
        // if responsible parry is empty for consumer/fleet we should get/create it using consumerId
        const originalCustomerParty = workOrderItemResponsiblePartiesByReferenceNum.find((x) => { var _a; return ((_a = x.responsibleParty.responsiblePartyType) === null || _a === void 0 ? void 0 : _a.name) === 'Customer Pay'; });
        const workOrderItemResponsiblePartiesSaveData = [];
        if (!responsibleParty1 || !responsibleParty1.id) {
            toast.error('Choose responsible party');
            return null;
        }
        const workOrderItemResponsibleParty1 = {
            id: '',
            amount: parseFloat(amount1),
            amountFixed: getAmountFixed(amount1),
            responsiblePartyId: responsibleParty1.id,
            suggestedRepairId: (_g = info.repairId) === null || _g === void 0 ? void 0 : _g.toString(),
            responsibleParty: responsibleParty1,
        };
        if (((_h = workOrderItemResponsibleParty1.responsibleParty.responsiblePartyType) === null || _h === void 0 ? void 0 : _h.name) === 'Customer Pay' &&
            originalCustomerParty) {
            workOrderItemResponsibleParty1.id = originalCustomerParty === null || originalCustomerParty === void 0 ? void 0 : originalCustomerParty.id;
        }
        workOrderItemResponsiblePartiesSaveData.push(workOrderItemResponsibleParty1);
        const workOrderItemResponsiblePartiesMap = {
            [info.repairId]: [workOrderItemResponsibleParty1],
        };
        if (responsibleParty2 && Object.keys(responsibleParty2).length > 0) {
            const total = numeral((info === null || info === void 0 ? void 0 : info.totalCost) || 0)
                .subtract(amount1 || 0)
                .subtract(amount2 || 0);
            if (total.value() !== 0) {
                helpers.setFieldError('amount1', amountErrMessage);
                helpers.setFieldError('amount2', amountErrMessage);
                setIsSubmitting(false);
                return;
            }
            if (total.value() === 0 &&
                numeral(amount1 || 0).value() !== 0 &&
                numeral(amount2 || 0).value() !== 0) {
                helpers.setFieldError('amount1', amountSplitErrorMessage);
                helpers.setFieldError('amount2', amountSplitErrorMessage);
                setIsSubmitting(false);
                return;
            }
            if (isEqual(responsibleParty2, responsibleParty1)) {
                helpers.setFieldError('responsibleParty1', samePayerErrMessage);
                helpers.setFieldError('responsibleParty2', samePayerErrMessage);
                setIsSubmitting(false);
                return;
            }
            if (!responsibleParty2 || !responsibleParty2.id) {
                toast.error('Choose responsible party');
                return null;
            }
            const workOrderItemResponsibleParty2 = {
                id: '',
                amount: parseFloat(amount2),
                amountFixed: getAmountFixed(amount2),
                responsiblePartyId: responsibleParty2.id,
                suggestedRepairId: (_j = info.repairId) === null || _j === void 0 ? void 0 : _j.toString(),
                responsibleParty: responsibleParty2,
            };
            if (((_k = workOrderItemResponsibleParty2.responsibleParty.responsiblePartyType) === null || _k === void 0 ? void 0 : _k.name) === 'Customer Pay' &&
                originalCustomerParty) {
                workOrderItemResponsibleParty2.id = originalCustomerParty === null || originalCustomerParty === void 0 ? void 0 : originalCustomerParty.id;
            }
            workOrderItemResponsiblePartiesSaveData.push(workOrderItemResponsibleParty2);
            workOrderItemResponsiblePartiesMap[info.repairId].push(workOrderItemResponsibleParty2);
        }
        dispatch(saveResponsibleParties(referenceNum, info.customRepairId, info.repairId, workOrderItemResponsiblePartiesSaveData))
            .then(() => {
            if (workOrderId) {
                dispatch(getWorkOrder({ workOrderId, referenceNum }));
                if (invoice) {
                    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
                }
                setPayerUpdate(workOrderItemResponsiblePartiesMap);
                if (fleet) {
                    handleOpenAddResponsiblePartyToVinModal(values);
                }
                else {
                    handleCloseCleanup();
                }
            }
        })
            .catch(() => {
            setIsSubmitting(false);
        });
    });
    const handleOpenAddResponsiblePartyToVinModal = (values) => {
        var _a, _b;
        const { responsibleParty1, responsibleParty2 } = values;
        const isRp1FromAccount = (_a = responsibleParty1 === null || responsibleParty1 === void 0 ? void 0 : responsibleParty1.groupName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('account');
        const isRp2FromAccount = (_b = responsibleParty2 === null || responsibleParty2 === void 0 ? void 0 : responsibleParty2.groupName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes('account');
        if (isRp1FromAccount) {
            setOpenAssignRpToVinModal(responsibleParty1);
            return;
        }
        if (isRp2FromAccount) {
            setOpenAssignRpToVinModal(responsibleParty2);
            return;
        }
        handleCloseCleanup();
    };
    const handleAddResponsiblePartyToVin = () => __awaiter(void 0, void 0, void 0, function* () {
        if (fleet && (car === null || car === void 0 ? void 0 : car.vin)) {
            const response = yield dispatch(updateFleetVehicleResponsibleParties(fleet === null || fleet === void 0 ? void 0 : fleet.id, {
                responsiblePartyId: openAssignRpToVinModal === null || openAssignRpToVinModal === void 0 ? void 0 : openAssignRpToVinModal.id,
                vin: car === null || car === void 0 ? void 0 : car.vin,
                carId: car === null || car === void 0 ? void 0 : car.consumerCarId,
            }));
            if (response && !response.error) {
                toast.success(`${openAssignRpToVinModal === null || openAssignRpToVinModal === void 0 ? void 0 : openAssignRpToVinModal.name} has been successfully assigned to this vehicle.`);
                if (fleetId) {
                    dispatch(getFleet(fleetId));
                }
            }
        }
        handleCloseCleanup();
    });
    const removeStyle = (has2ResponsibleParties) => {
        var _a, _b;
        return has2ResponsibleParties || ((_b = (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.has2ResponsibleParties)
            ? {}
            : removeStyles;
    };
    return {
        initValues,
        setInitValues,
        payerUpdate,
        setPayerUpdate,
        responsiblePartyTypes,
        primaryResponsibleParty,
        handleCloseCleanup,
        onClose,
        handleSubmitRemoveAuth,
        handleAddPayer,
        handleOpenModal,
        modal,
        handleRemovePayer,
        getAmountFixed,
        handleFormSubmit,
        removeStyle,
        workOrderIsAuthorized,
        workOrderItemResponsibleParties,
        workOrderItemResponsiblePartiesByReferenceNum,
        thirdPartyModal,
        info,
        isSubmitting,
        availableResponsibleParties,
        openAssignRpToVinModal,
        setOpenAssignRpToVinModal,
        handleAddResponsiblePartyToVin,
    };
};
export default usePayerDrawer;
