var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { InputAdornment, Menu, MenuItem, Tab, Tabs, TextField, SliderThumb, } from 'src/components/mui';
import StationsAutocomplete from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/StationsAutocomplete';
import FleetsAutocomplete from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/FleetsAutocomplete';
import TechniciansAutocomplete from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/TechniciansAutocomplete';
import LocationAutocomplete from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/LocationAutocomplete';
import VisitDateTimePicker from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/VisitDateTimePicker';
import { useParams } from 'react-router';
import ShiftToggle from 'src/AdminApp/containers/scheduling/ShiftToggle';
import AvailabilityList from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/AvailabilityList';
import AvailableTechnicians from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/AvailableTechnicians';
import { useSelector } from 'react-redux';
import { selectFleetVisit } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import ResetParts from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/components/ResetPartsConfirmation';
import { SlideWrapper } from 'src/AdminApp/components/drawers/styledComponents';
import VisitCapacityWarningModal from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal/VisitCapacityWarningModal';
import useIsMobile from 'src/hooks/useIsMobile';
import { SimpleDrawer, SimpleDrawerHeader, SimpleDrawerContent, } from 'src/components/SimpleDrawer';
import { WEEK_DAY_SHORT_FORMAT, LOCALIZED_DATE_LONG_FORMAT, } from 'src/timeConstants';
import { AddFleetContent, AddVisitAc, AddVisitInfo, DurationLabel, DurationSlider, DurationWrapper, FieldWrapper, FleetsAndLocation, MainHeader, RevenueWrapper, RightSide, SchedulingWrapper, SectionHeader, SwitcherMenu, TechniciansWrapper, } from './AddFleetVisitDrawer.styles';
import useAddFleetVisitDrawer from './useAddFleetVisitDrawer';
const DurationThumbComponent = (props) => {
    const { children } = props, other = __rest(props, ["children"]);
    return (<SliderThumb {...other}>
      {children}
      <span className="bar"/>
      <span className="bar"/>
      <span className="bar"/>
    </SliderThumb>);
};
export var TabNames;
(function (TabNames) {
    TabNames[TabNames["Assisted"] = 0] = "Assisted";
    TabNames[TabNames["Manual"] = 1] = "Manual";
})(TabNames || (TabNames = {}));
const AddFleetVisitDrawer = ({ open, onClose, fleet, newStartTime, reassignVanFunction, }) => {
    const isMobile = useIsMobile();
    const { refNum } = useParams();
    const { handleSubmit, handleClose, isSubmitting, selectedFleets, localDuration, setExpectedRevenue, setTabValue, tabValue, selectedTechnicians, selectedTimeSlot, selectedLocation, expectedRevenue, setShift, shift, shifts, selectedEndDateTime, selectedDurationString, isMoreThanADay, handleDurationChange, initialTechnicians, selectedStartDateChanged, showCapacityWarning, onCapacityWarningClose, shouldShowCapacityWarning, setUnsavedNumberOfTechs, setUnsavedTechniciansChoice, setUnsavedVanMechanicPairings, warningMessage, } = useAddFleetVisitDrawer({
        open,
        onClose,
        refNum,
        openedFromFleet: !!fleet,
        newStartTime,
        reassignVanFunction,
    });
    const [anchorEl, setAnchorEl] = useState();
    const [openResetParts, setOpenResetParts] = useState(false);
    const visit = useSelector(selectFleetVisit);
    const hasAmazonFleet = selectedFleets.some((f) => f.type === 'Amazon');
    const handleTabOptionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleTabOptionsClose = () => {
        setAnchorEl(undefined);
    };
    const onExpectedRevenueChange = (e) => {
        const targetValue = e.target.value;
        if (targetValue.toString().length === 0) {
            return;
        }
        setExpectedRevenue(targetValue.match('.') ? parseInt(targetValue, 10) : targetValue);
    };
    const changeTab = (event, newValue) => {
        setTabValue(newValue);
    };
    const changeTabAndCloseMenu = (newValue) => () => {
        setTabValue(newValue);
        handleTabOptionsClose();
    };
    const hasResources = (tabValue === TabNames.Manual && selectedTechnicians.length > 0) ||
        (tabValue === TabNames.Assisted && selectedTimeSlot);
    const isValid = selectedFleets.length > 0 &&
        selectedLocation &&
        localDuration > 0 &&
        hasResources &&
        (hasAmazonFleet || (expectedRevenue !== null && expectedRevenue !== void 0 ? expectedRevenue : 0) > 0);
    const isFormDisabled = tabValue === TabNames.Assisted
        ? !!selectedTimeSlot
        : selectedTechnicians.length > 0 || !!selectedTimeSlot;
    const drawerHeaderCopy = (visit === null || visit === void 0 ? void 0 : visit.status) ? 'Edit Visit' : 'Add a New Visit';
    let visitInfoCopy = ['IN_PROGRESS'].includes(visit === null || visit === void 0 ? void 0 : visit.status)
        ? 'New Techs can be added when the Visit is in progress'
        : 'Remove all Techs to edit Visit’s details';
    if (tabValue === TabNames.Assisted && !visit) {
        visitInfoCopy = 'Unselect time slot to edit Visit’s details';
    }
    const handleOpenPartResetDashboard = () => {
        if (selectedStartDateChanged && visit) {
            setOpenResetParts(true);
        }
        else {
            setOpenResetParts(false);
            return handleSubmit(false);
        }
    };
    const handleSendFromPartResetDashboard = (resetParts) => {
        setOpenResetParts(false);
        return handleSubmit(resetParts);
    };
    return (<SimpleDrawer size="threeFourths" open={open} onClose={handleClose} disableEnforceFocus footerActionButtonProps={{
            onClick: handleOpenPartResetDashboard,
            disabled: !isValid || isSubmitting,
        }} submitText={`${visit ? 'Update' : 'Build'} Visit`}>
      {!openResetParts || !visit ? (<>
          <SimpleDrawerHeader onClose={onClose} title={drawerHeaderCopy} extraCta={!isMobile && (<>
                  <SwitcherMenu variant="text" color="primary" onClick={handleTabOptionsClick}>
                    {tabValue === TabNames.Assisted
                    ? 'Assisted Scheduling'
                    : 'Manual Entry'}
                  </SwitcherMenu>
                  <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleTabOptionsClose}>
                    {tabValue === TabNames.Assisted && (<MenuItem onClick={changeTabAndCloseMenu(TabNames.Manual)}>
                        Manual Entry
                      </MenuItem>)}
                    {tabValue === TabNames.Manual && (<MenuItem onClick={changeTabAndCloseMenu(TabNames.Assisted)}>
                        Assisted Scheduling
                      </MenuItem>)}
                  </Menu>
                </>)}/>

          {isMobile && (<Tabs value={tabValue} onChange={changeTab} indicatorColor="primary" textColor="primary" variant="fullWidth">
              <Tab label="Assisted Scheduling"/>
              <Tab label="Manual Entry"/>
            </Tabs>)}
          <SimpleDrawerContent>
            <AddFleetContent>
              {tabValue === TabNames.Assisted && (<SchedulingWrapper>
                  <MainHeader>
                    <span>Available Time Slots</span>
                    <ShiftToggle setShift={setShift} selectedShiftName={shift} shifts={shifts}/>
                  </MainHeader>

                  <AvailabilityList />
                </SchedulingWrapper>)}

              {tabValue === TabNames.Manual && (<SchedulingWrapper>
                  <MainHeader>Schedule a Time</MainHeader>

                  <VisitDateTimePicker disabled={isFormDisabled}/>
                </SchedulingWrapper>)}

              <RightSide>
                {isMobile && <MainHeader>Visit Details</MainHeader>}
                {isFormDisabled && <AddVisitInfo>{visitInfoCopy}</AddVisitInfo>}
                <FleetsAndLocation>
                  <AddVisitAc color="blue">
                    <StationsAutocomplete workOrders={visit === null || visit === void 0 ? void 0 : visit.workOrders} disabled={isFormDisabled}/>
                  </AddVisitAc>

                  <AddVisitAc color="gray">
                    <FleetsAutocomplete workOrders={visit === null || visit === void 0 ? void 0 : visit.workOrders} disabled={isFormDisabled} fleet={fleet}/>
                  </AddVisitAc>

                  <FieldWrapper>
                    <LocationAutocomplete disabled={isFormDisabled} tabValue={tabValue}/>
                  </FieldWrapper>
                </FleetsAndLocation>

                <RevenueWrapper>
                  <TextField variant="outlined" label="Expected Revenue" fullWidth disabled={['CANCELED', 'COMPLETED'].includes(visit === null || visit === void 0 ? void 0 : visit.status)} type="number" value={expectedRevenue !== null && expectedRevenue !== void 0 ? expectedRevenue : ''} onChange={onExpectedRevenueChange} InputProps={{
                startAdornment: (<InputAdornment position="start">$</InputAdornment>),
            }}/>
                </RevenueWrapper>

                <DurationWrapper>
                  <DurationLabel id="discrete-slider">
                    Duration:{' '}
                    <span title={`Ends ${selectedEndDateTime.format(`${WEEK_DAY_SHORT_FORMAT}, ${LOCALIZED_DATE_LONG_FORMAT}`)}`}>
                      {selectedDurationString}
                    </span>{' '}
                    {isMoreThanADay && <div>(Ends Next Day)</div>}
                  </DurationLabel>
                  <DurationSlider classes={{ thumb: 'durationThumb' }} defaultValue={8} aria-labelledby="discrete-slider" valueLabelDisplay="off" step={0.25} min={0.5} max={12} onChange={(e, value) => handleDurationChange(value)} value={localDuration} disabled={isFormDisabled} slots={{ thumb: DurationThumbComponent }}/>
                </DurationWrapper>

                {tabValue === TabNames.Assisted && (<AvailableTechnicians disabled={isFormDisabled} isEditing={!!visit} shouldShowCapacityWarning={shouldShowCapacityWarning} setUnsavedNumberOfTechs={setUnsavedNumberOfTechs} setUnsavedTechniciansChoice={setUnsavedTechniciansChoice} setUnsavedVanMechanicPairings={setUnsavedVanMechanicPairings}/>)}

                {tabValue === TabNames.Manual && (<TechniciansWrapper>
                    <SectionHeader>Add Resources</SectionHeader>

                    <FieldWrapper>
                      <AddVisitAc color="gray">
                        <TechniciansAutocomplete initialTechnicians={initialTechnicians} visitStatus={visit === null || visit === void 0 ? void 0 : visit.status}/>
                      </AddVisitAc>
                    </FieldWrapper>
                  </TechniciansWrapper>)}
              </RightSide>
            </AddFleetContent>
          </SimpleDrawerContent>
        </>) : (<>
          <SimpleDrawerHeader onClose={() => setOpenResetParts(false)} title={drawerHeaderCopy}/>
          <SlideWrapper>
            <ResetParts onConfirmResetParts={() => handleSendFromPartResetDashboard(true)} onCancelResetParts={() => handleSendFromPartResetDashboard(false)}/>
          </SlideWrapper>
        </>)}

      <VisitCapacityWarningModal open={showCapacityWarning} onClose={onCapacityWarningClose} warningMessage={warningMessage}/>
    </SimpleDrawer>);
};
export default AddFleetVisitDrawer;
