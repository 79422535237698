import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { Typography, Grid, Button, IconButton, InputAdornment, } from 'src/components/mui';
import { Cancel } from 'src/components/mui/icons';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { TextField } from 'src/AdminApp/components/form';
import LaborRatesType from './LaborRateType';
const RemoveButton = styled(IconButton) `
  position: absolute;
  right: 0;
  top: -4px;
`;
const LaborRateItem = styled.div `
  margin-top: 32px;
  margin-bottom: 32px;
  position: relative;
`;
const LaborRateField = ({ name, label, index }) => {
    return (<Grid item xs={12} md={6}>
      <LaborRatesType index={index}>{label}</LaborRatesType>
      <TextField type="number" name={name} InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }} placeholder="Labor Rate / Hour" required/>
    </Grid>);
};
const LaborRates = ({ laborRates = [] }) => {
    const generalName = 'pricingDetails.laborRates';
    return (<div>
      <Typography variant="subtitle2">Labor Rates</Typography>

      <FieldArray name={generalName}>
        {(arrayHelpers) => laborRates.map((laborRate, index) => (<LaborRateItem key={laborRate.brandType}>
              <Grid key={laborRate.brandType} container spacing={2}>
                <LaborRateField index={index} name={`${generalName}[${index}].maintenancePrice`} label={<>
                      Maintenance Repairs
                      {index !== 0 && (<RemoveButton onClick={() => arrayHelpers.remove(index)}>
                          <Cancel style={{ fontSize: 18 }}/>
                        </RemoveButton>)}
                    </>}/>

                <LaborRateField index={index} name={`${generalName}[${index}].generalPrice`} label="General Repair"/>

                <LaborRateField index={index} name={`${generalName}[${index}].specialPrice`} label="Specialty Repairs"/>

                <LaborRateField index={index} name={`${generalName}[${index}].diagnosisPrice`} label="Diagnosis"/>

                <Grid item xs={12}>
                  <CheckboxField name={`${generalName}[${index}].diagnosisReimbursable`} label="Shop will reimburse"/>
                </Grid>
              </Grid>

              <div>
                {laborRates.length <= 1 && (<Button onClick={() => arrayHelpers.push({
                    brandType: 'EU',
                })} color="primary">
                    + Add separate rates for European Cars
                  </Button>)}
              </div>
            </LaborRateItem>))}
      </FieldArray>
    </div>);
};
export default LaborRates;
