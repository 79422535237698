import React from 'react';
import styled from 'styled-components';
import { Alert, AlertTitle } from 'src/components/mui';
import { KAFKA_CONNECT_API_URL } from './connectApiClient';
const StyledAlert = styled(Alert) `
  margin: 5px;
`;
const ConnectApiInfo = () => (<StyledAlert severity="info" elevation={1}>
    <AlertTitle>Kafka Connect API</AlertTitle>
    {KAFKA_CONNECT_API_URL}
  </StyledAlert>);
export default ConnectApiInfo;
