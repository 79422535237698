var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import MapboxClient from 'mapbox';
import CONFIG from 'src/config';
import { toast } from 'src/components/SimpleToast';
import { compact } from 'lodash';
let instance = null;
export const createInstance = () => {
    return new MapboxClient(CONFIG.MAPBOX_TOKEN);
};
export const MbClient = () => {
    if (!instance) {
        instance = createInstance();
    }
    return instance;
};
export const addressObjectToString = (address) => {
    var _a, _b, _c, _d, _e;
    let addressArray = [
        (_a = address === null || address === void 0 ? void 0 : address.street) !== null && _a !== void 0 ? _a : null,
        (_b = address === null || address === void 0 ? void 0 : address.street2) !== null && _b !== void 0 ? _b : null,
        (_c = address === null || address === void 0 ? void 0 : address.city) !== null && _c !== void 0 ? _c : null,
        (_d = address === null || address === void 0 ? void 0 : address.state) !== null && _d !== void 0 ? _d : null,
        (_e = address === null || address === void 0 ? void 0 : address.zip) !== null && _e !== void 0 ? _e : null,
    ];
    addressArray = compact(addressArray);
    const hasAddress = addressArray.length > 0;
    return hasAddress ? addressArray.join(', ') : null;
};
export const calculateLatLong = ({ zip, address = null, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e;
    const latLong = {
        latitude: null,
        longitude: null,
    };
    const addressString = address ? addressObjectToString(address) : null;
    const apiInput = addressString || (zip ? zip.toString() : null);
    if (addressString || zip) {
        const geodata = yield MbClient().geocodeForward(apiInput, {
            country: 'us',
        });
        const error = (geodata === null || geodata === void 0 ? void 0 : geodata.status) !== 200;
        if (error) {
            toast.error((_b = (_a = geodata === null || geodata === void 0 ? void 0 : geodata.entity) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Oops! Something went wrong.');
        }
        else {
            // MapBox BoundingBox: [minLongitude, minLatitude, maxLongitude, maxLatitude]
            [latLong.longitude, latLong.latitude] = (_e = (_d = (_c = geodata === null || geodata === void 0 ? void 0 : geodata.entity) === null || _c === void 0 ? void 0 : _c.features[0]) === null || _d === void 0 ? void 0 : _d.center) !== null && _e !== void 0 ? _e : [null, null];
        }
    }
    return latLong;
});
export function getTimezoneByMapBox(lat, lng) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const result = yield fetch(`https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${lng},${lat}.json?access_token=${CONFIG.MAPBOX_TOKEN}`);
        const respBody = yield result.json();
        return (_b = (_a = respBody === null || respBody === void 0 ? void 0 : respBody.features[0]) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.TZID;
    });
}
