export var SalesMatchDateFilterType;
(function (SalesMatchDateFilterType) {
    SalesMatchDateFilterType[SalesMatchDateFilterType["Invoice"] = 0] = "Invoice";
    SalesMatchDateFilterType[SalesMatchDateFilterType["Matched"] = 1] = "Matched";
})(SalesMatchDateFilterType || (SalesMatchDateFilterType = {}));
export var SalesMatchShowFilterType;
(function (SalesMatchShowFilterType) {
    SalesMatchShowFilterType[SalesMatchShowFilterType["Matches"] = 0] = "Matches";
    SalesMatchShowFilterType[SalesMatchShowFilterType["All"] = 1] = "All";
})(SalesMatchShowFilterType || (SalesMatchShowFilterType = {}));
