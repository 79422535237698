import { ArrowBack as ArrowBackIcon, Close as CloseIcon, } from 'src/components/mui/icons';
import React from 'react';
import { DrawerBackButton, DrawerCloseButton, DrawerStyledHeader, DrawerStyledHeaderTitle, } from 'src/components/SimpleDrawer/SimpleDrawerHeader/SimpleDrawerHeader.styles';
export const SimpleDrawerHeader = ({ onClose, title, extraCta, arrowBack, onBack, }) => {
    return (<DrawerStyledHeader>
      {onClose && arrowBack && (<DrawerBackButton onClick={onBack !== null && onBack !== void 0 ? onBack : onClose}>
          <ArrowBackIcon />
        </DrawerBackButton>)}

      {onClose && !arrowBack && (<DrawerCloseButton onClick={onClose}>
          <CloseIcon />
        </DrawerCloseButton>)}

      <DrawerStyledHeaderTitle>
        {title} {extraCta}
      </DrawerStyledHeaderTitle>
    </DrawerStyledHeader>);
};
export default SimpleDrawerHeader;
