// datetime
export const DATE_TIME_12H_FORMAT = 'MM/DD/YYYY h:mm A'; // 01/24/2019 8:00 AM
export const DATE_TIME_12H_FORMAT_WITH_SECONDS = 'MM/DD/YYYY h:mm:ss A'; // 01/24/2019 8:00:00 AM
export const LOCALIZED_DATE_LONG_FORMAT = 'lll'; // Jan 24, 2019 8:00 AM
export const DATE_YMD_LONG_TIME_FORMAT = 'YYYY-MM-DD LT'; // 2019-01-01 18:00 AM
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'; // 2019-01-01T18:00
export const DATE_TIME_FORMAT_WITH_SECONDS = 'YYYY-MM-DDTHH:mm:ss'; // 2019-01-01T18:00:00
export const TIMESTAMP_WITH_OFFSET_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'; // 2019-01-01T18:00:00-07:00
// date
export const LOCALIZED_DATE_FORMAT = 'MM/DD/YYYY'; // 01/24/2019
export const LOCALIZED_DATE_LONGER_FORMAT = 'll'; // Jan 24, 2019
export const DATE_YMD_FORMAT = 'YYYY-MM-DD'; // 2019-01-01
// time
export const TIME_12H_FORMAT = 'h:mm A'; // 8:00 AM
export const TIME_12H_FORMAT_WITH_SECONDS = 'h:mm:ss a'; // 8:00:00 am
export const TIME_FORMAT = 'HH:mm:ss'; // 18:00:00
// misc
export const WEEK_DAY_SHORT_FORMAT = 'ddd'; // Mon
export const WEEK_DAY_FORMAT = 'dddd'; // Monday
export const MONTH_AND_DAY_FORMAT = 'MMM D'; // Jan 4
export const MONTH_AND_YEAR_FORMAT = 'MMM YYYY'; // Jan 2019
export const CREDIT_CARD_EXPIRY_FORMAT = 'MM/YY'; // 01/23
export const TIME_ZONE_FORMAT = 'z'; // EST
export const HOUR_12H_FORMAT = 'h A'; // 8 AM
