import React from 'react';
import { CircularProgress } from 'src/components/mui';
import styled from 'styled-components';
const Wrapper = styled.div `
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
const SimpleLoader = () => (<Wrapper>
    <CircularProgress />
  </Wrapper>);
export default SimpleLoader;
