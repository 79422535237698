import React from 'react';
import { TableCell, Table, TableHead, TableContainer, TableBody, Paper, } from 'src/components/mui';
import { Edit as EditIcon } from 'src/components/mui/icons';
import SmallIconButton from 'src/components/icons/SmallIconButton';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
const Configs = ({ configs, onEdit }) => {
    return (<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableCell>Name</TableCell>
          <TableCell>Value</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Actions</TableCell>
        </TableHead>
        <TableBody>
          {configs.length > 0 &&
            (configs === null || configs === void 0 ? void 0 : configs.map((config) => (<SimpleTableRow key={config === null || config === void 0 ? void 0 : config.id} data={[
                    config === null || config === void 0 ? void 0 : config.name,
                    config === null || config === void 0 ? void 0 : config.value,
                    config === null || config === void 0 ? void 0 : config.type,
                    <SmallIconButton aria-label="Edit Resolution" title="Edit" onClick={() => onEdit(config)}>
                    <EditIcon />
                  </SmallIconButton>,
                ]}/>)))}
        </TableBody>
      </Table>
    </TableContainer>);
};
export default Configs;
