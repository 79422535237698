var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Formik } from 'formik';
import React from 'react';
import { TextField } from 'src/AdminApp/components/form';
import { Divider, Grid, MenuItem, Typography } from 'src/components/mui';
import { DrawerFormRow, FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { emailListValidation } from 'src/utils/email-list-validation';
import * as Yup from 'yup';
import useResponsibleParties from './useResponsibleParties';
const DEFAULT_ERROR_MESSAGE = 'The field must be filled';
const DEFAULT_SCHEMA = {
    name: Yup.string().min(1).max(255).required(DEFAULT_ERROR_MESSAGE),
    status: Yup.string()
        .oneOf(['ACTIVE', 'INACTIVE'])
        .required(DEFAULT_ERROR_MESSAGE),
    taxType: Yup.string()
        .oneOf(['PRICE', 'EXEMPT', 'COST'])
        .required(DEFAULT_ERROR_MESSAGE),
    generatesRevenue: Yup.string().required(DEFAULT_ERROR_MESSAGE),
    contactName: Yup.string().nullable(),
    phone: Yup.string()
        .matches(/^$|[2-9]{1}\d{2}[2-9]{1}\d{6}/, 'Phone Number is not valid')
        .nullable(),
    email: Yup.string().nullable().email('Must be a valid email'),
    submissionMethod: Yup.string().nullable(),
    invoiceAutosendSchedule: Yup.string()
        .oneOf(['DAILY', 'WEEKLY', 'MONTHLY', null])
        .nullable(),
    billingContactsString: Yup.string().when('invoiceAutosendSchedule', {
        is: (v) => !!(v === null || v === void 0 ? void 0 : v.length),
        then: Yup.string().required('Billing Contacts are required when auto-send is enabled'),
        otherwise: Yup.string().nullable(),
    }),
};
const ResponsiblePartiesSchema = Yup.object().shape(Object.assign(Object.assign({}, DEFAULT_SCHEMA), { responsiblePartyTypeId: Yup.string().required(DEFAULT_ERROR_MESSAGE) }));
const ResponsiblePartiesSchemaEdit = Yup.object().shape(DEFAULT_SCHEMA);
const PartiesEditDrawer = ({ isOpen, onClose, editMode, party, }) => {
    var _a, _b, _c;
    const { partyTypes, createResponsibleParty, editResponsibleParty, refreshParties, } = useResponsibleParties();
    const responsiblePartyType = partyTypes === null || partyTypes === void 0 ? void 0 : partyTypes.find((type) => type.id === (party === null || party === void 0 ? void 0 : party.responsiblePartyTypeId));
    const canEdit = !editMode || ((_a = responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'active';
    const canNotChangeStatus = ((_b = responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.status) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'inactive' &&
        (responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.name) === '3rd Party Pay' &&
        ((_c = party === null || party === void 0 ? void 0 : party.status) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === 'inactive';
    const getInitialValues = () => {
        var _a, _b;
        return party
            ? Object.assign(Object.assign({}, party), { billingContactsString: (_b = (_a = party.billingContacts) === null || _a === void 0 ? void 0 : _a.join(', ')) !== null && _b !== void 0 ? _b : '' }) : {
            id: undefined,
            name: '',
            status: 'ACTIVE',
            taxType: '',
            responsiblePartyTypeId: '',
            generatesRevenue: '',
            contactName: '',
            phone: '',
            email: '',
            submissionMethod: '',
            invoiceAutosendSchedule: undefined,
            billingContactsString: '',
        };
    };
    const handleSubmit = (values, setSubmitting) => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        if (editMode) {
            yield editResponsibleParty(Object.assign(Object.assign({}, values), { id: party === null || party === void 0 ? void 0 : party.id }));
        }
        else {
            yield createResponsibleParty(values);
        }
        yield refreshParties();
        setSubmitting(false);
        onClose();
    });
    return (<SimpleDrawer open={isOpen} onClose={onClose} title={editMode ? 'Edit Responsible Party' : 'Add New Responsible Party'}>
      <Formik enableReinitialize validateOnMount validationSchema={editMode ? ResponsiblePartiesSchemaEdit : ResponsiblePartiesSchema} initialValues={getInitialValues()} onSubmit={handleSubmit}>
        {({ values, isValid, isSubmitting, setSubmitting, errors, touched, setFieldValue, }) => {
            var _a, _b, _c, _d;
            const showFMCBlock = values.responsiblePartyTypeId &&
                partyTypes.find((pt) => pt.id === values.responsiblePartyTypeId &&
                    pt.name.toLowerCase() === 'fmc pay');
            const showAutoSendSchedule = !showFMCBlock ||
                !(values === null || values === void 0 ? void 0 : values.submissionMethod) ||
                (values === null || values === void 0 ? void 0 : values.submissionMethod.toLocaleLowerCase()) === 'phone';
            if (!showAutoSendSchedule && (values === null || values === void 0 ? void 0 : values.invoiceAutosendSchedule)) {
                setFieldValue('invoiceAutosendSchedule', undefined);
            }
            return (<>
              <SimpleDrawerContent>
                <Grid container>
                  <DrawerFormRow item xs={12}>
                    <TextField value={values.status} select id="add-edit-responsible-party-status" name="status" label="Status" variant="outlined" disabled={canNotChangeStatus}>
                      <MenuItem value="ACTIVE">Active</MenuItem>
                      <MenuItem value="INACTIVE">Inactive</MenuItem>
                    </TextField>
                  </DrawerFormRow>
                  <DrawerFormRow item xs={12}>
                    <TextField value={values.responsiblePartyTypeId} select id="add-edit-responsible-party-type" name="responsiblePartyTypeId" label="Responsible Party Type" variant="outlined" disabled={editMode}>
                      {partyTypes
                    .filter((p) => { var _a; return ((_a = p.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'active' || editMode; })
                    .map((p) => (<MenuItem key={p.id} value={p.id}>
                            {p.name}
                          </MenuItem>))}
                    </TextField>
                  </DrawerFormRow>
                  <DrawerFormRow item xs={12}>
                    <TextField value={values.name} id="add-edit-responsible-party-name" name="name" label="Name" variant="outlined" placeholder="Name" type="string" disabled={!canEdit}/>
                  </DrawerFormRow>
                  <DrawerFormRow item xs={12}>
                    <TextField value={values.taxType} select id="add-edit-responsible-party-tax-type" name="taxType" label="Tax type" variant="outlined" disabled={!canEdit}>
                      <MenuItem value="PRICE">Price</MenuItem>
                      <MenuItem value="COST">Cost</MenuItem>
                      <MenuItem value="EXEMPT">Exempt</MenuItem>
                    </TextField>
                  </DrawerFormRow>
                  <DrawerFormRow item xs={12}>
                    <TextField value={(_a = values.generatesRevenue) !== null && _a !== void 0 ? _a : ''} select id="add-edit-responsible-party-generates-revenue" name="generatesRevenue" label="Generates Revenue?" variant="outlined" disabled={(editMode && ((_b = party === null || party === void 0 ? void 0 : party.generatesRevenue) !== null && _b !== void 0 ? _b : false)) ||
                    !canEdit}>
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </TextField>
                  </DrawerFormRow>
                  {showAutoSendSchedule && (<DrawerFormRow item xs={12}>
                      <TextField value={(_c = values.invoiceAutosendSchedule) !== null && _c !== void 0 ? _c : ''} select id="add-edit-responsible-party-autosend-schedule" name="invoiceAutosendSchedule" label="Invoice Auto-Send Schedule" variant="outlined" disabled={!canEdit}>
                        <MenuItem value={undefined}>None</MenuItem>
                        <MenuItem value="DAILY">Daily</MenuItem>
                        <MenuItem value="WEEKLY">Weekly</MenuItem>
                        <MenuItem value="MONTHLY">Monthly</MenuItem>
                      </TextField>
                    </DrawerFormRow>)}

                  <DrawerFormRow item xs={12}>
                    <TextField value={(_d = values.billingContactsString) !== null && _d !== void 0 ? _d : ''} id="add-edit-responsible-party-billing-contacts" name="billingContactsString" label="Billing Contacts Emails" variant="outlined" disabled={canNotChangeStatus} rows={3} helperText={touched['billingContactsString'] ? (<>{errors.billingContactsString}</>) : null} validate={emailListValidation} multiline/>
                    <Typography variant="subtitle1" style={{ marginTop: '8px' }}>
                      Input emails separated by a comma
                      {values.invoiceAutosendSchedule &&
                    '. Required when Invoice Auto-Send Schedule is set.'}
                    </Typography>
                  </DrawerFormRow>
                  {showFMCBlock && (<>
                      <DrawerFormRow item xs={12}>
                        <Divider style={{
                        marginBottom: '15px',
                    }}/>
                        <Typography variant="h6">
                          Additional FMC Info
                        </Typography>
                      </DrawerFormRow>
                      <DrawerFormRow item xs={12}>
                        <TextField value={values.contactName} id="add-edit-responsible-party-fmc-contact" name="contactName" label="FMC Contact" variant="outlined" placeholder="FMC Contact" type="string"/>
                      </DrawerFormRow>
                      <DrawerFormRow item xs={12}>
                        <TextField value={values.phone} id="add-edit-responsible-party-fmc-phone" name="phone" label="Phone" variant="outlined" placeholder="Phone" type="string"/>
                      </DrawerFormRow>
                      <DrawerFormRow item xs={12}>
                        <TextField value={values.email} id="add-edit-responsible-party-fmc-email" name="email" label="Email" variant="outlined" placeholder="Email" type="string"/>
                      </DrawerFormRow>
                      <DrawerFormRow item xs={12}>
                        <TextField value={values.submissionMethod} select id="add-edit-responsible-party-submissionMethod" name="submissionMethod" label="Portal" variant="outlined" fullWidth>
                          <MenuItem value={undefined} disabled>
                            &nbsp;
                          </MenuItem>
                          <MenuItem value="AUTO_INTEGRATE">
                            AutoIntegrate
                          </MenuItem>
                          <MenuItem value="LEASE_PLAN">LeasePlan</MenuItem>
                          <MenuItem value="ARI">ARI</MenuItem>
                          <MenuItem value="PHONE">Phone</MenuItem>
                        </TextField>
                      </DrawerFormRow>
                    </>)}
                </Grid>
              </SimpleDrawerContent>
              <SimpleDrawerFooter>
                <FooterActionButton disabled={!isValid || isSubmitting || canNotChangeStatus} onClick={() => handleSubmit(values, setSubmitting)} type="submit">
                  Save
                </FooterActionButton>
              </SimpleDrawerFooter>
            </>);
        }}
      </Formik>
    </SimpleDrawer>);
};
export default PartiesEditDrawer;
