import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'src/components/mui';
import useTechnicians from 'src/TechApp/hooks/useTechnicians';
const Technicians = () => {
    const { technicians } = useTechnicians();
    return (<>
      <h1>Technicians ({technicians.length})</h1>
      <Grid container>
        <ol>
          {technicians.map((technician) => (<li key={technician.id}>
              <Link to={`/tech/technicians/${technician.id}/dashboard`}>
                {technician.fullName} ({technician.id})
              </Link>
            </li>))}
        </ol>
      </Grid>
    </>);
};
export default Technicians;
