import React, { useCallback, useState } from 'react';
import { SimpleModal } from 'src/components/layout';
const useConfirmModal = ({ closeConfirmCb, closeDiscardCb, confirmBtn = 'Keep Editing', discardBtn = 'Discard Changes', dismiss = false, openCb, title = 'Discard unsaved changes?', }) => {
    const [open, setOpen] = useState(false);
    const handleOpenModal = useCallback(() => {
        if (openCb && !openCb())
            return;
        setOpen(true);
    }, [openCb]);
    const handleDismiss = () => setOpen(false);
    const handleCloseConfirm = () => {
        if (closeConfirmCb)
            closeConfirmCb();
        handleDismiss();
    };
    const handleCloseDiscard = () => {
        if (closeDiscardCb)
            closeDiscardCb();
        handleDismiss();
    };
    const modal = (<SimpleModal open={open} onClose={!dismiss ? handleCloseConfirm : handleDismiss} showClose={false} title={title} simpleTwoButtonRowProps={{
            onCancel: handleCloseDiscard,
            onSubmit: handleCloseConfirm,
            submitText: confirmBtn,
            cancelText: discardBtn,
            submitIsDestructive: true,
        }}/>);
    return {
        handleOpenModal,
        modal,
    };
};
export default useConfirmModal;
