import { useEffect } from 'react';
const usePaginationScroll = ({ ref, callback }) => {
    useEffect(() => {
        if (!ref)
            return;
        const handleScroll = () => {
            const bottomReached = ref.scrollHeight - ref.scrollTop <= ref.clientHeight;
            if (bottomReached) {
                callback();
            }
        };
        ref.addEventListener('scroll', handleScroll);
        return () => {
            if (ref) {
                ref.removeEventListener('scroll', handleScroll);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, callback]);
};
export default usePaginationScroll;
