var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectFleetPricingRulesEditor } from 'src/modules/auth/selectors';
import { deactivateFleetRules, getExistingFleetRules, } from 'src/PricingApp/modules/repairs/actions';
const usePricingRule = ({ rule, onEdit, }) => {
    const dispatch = useDispatch();
    const { fleetId } = useParams();
    const [deactivateConfirmModalOpen, setDeactivateConfirmModalOpen] = useState(false);
    const isPricingRulesEditor = useSelector(selectFleetPricingRulesEditor);
    const deactivateRules = () => __awaiter(void 0, void 0, void 0, function* () {
        const ruleIds = rule.rules.map((r) => r.id);
        yield dispatch(deactivateFleetRules(ruleIds, 'DEACTIVATED_BY_ADMIN'));
        dispatch(getExistingFleetRules(fleetId));
        setDeactivateConfirmModalOpen(false);
    });
    const handleEdit = () => {
        var _a;
        onEdit({
            repairId: rule === null || rule === void 0 ? void 0 : rule.id,
            label: (_a = rule === null || rule === void 0 ? void 0 : rule.rules[0]) === null || _a === void 0 ? void 0 : _a.repairName,
        });
    };
    const handleDeactivateModal = (state) => () => {
        setDeactivateConfirmModalOpen(state);
    };
    return {
        dispatch,
        fleetId,
        deactivateRules,
        isPricingRulesEditor,
        setDeactivateConfirmModalOpen,
        deactivateConfirmModalOpen,
        handleEdit,
        handleDeactivateModal,
    };
};
export default usePricingRule;
