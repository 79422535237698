var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryRegions, getDeliveryMetros, getDeliveryTerritories, getRepairComponents, } from 'src/PopsApp/modules/parts/actions';
import { selectPopsRegions } from 'src/PopsApp/modules/parts/selectors';
// eslint-disable-next-line import/no-named-default
import useVansFilter, { DEFAULT_FILTER_STATE, } from 'src/PopsApp/modules/hooks/useFilter';
// FilterState
export const getQueryString = (query) => {
    const queryParams = [];
    Object.keys(query).forEach((p) => {
        queryParams.push(`${p}=${query[p]}`);
    });
    return queryParams.join('&');
};
const useFilter = () => {
    var _a;
    const [regions, setRegions] = useState([]);
    const [dataProcessing, setDataProcessing] = useState(false);
    const { changeFilterData, setNewFilterData, metros, territories, components, query, sortDirection, handleSortClose, sortAnchorEl, isSortOpen, handleSortClick, isFilterOpen, setFilterOpen, } = useVansFilter();
    const history = useHistory();
    const dispatch = useDispatch();
    const queryLength = Object.keys(query).filter((k) => k.length).length;
    // TODO update apiRegions to use them without internal state
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const apiRegions = (_a = useSelector(selectPopsRegions)) !== null && _a !== void 0 ? _a : [];
    const updateFilter = (fieldName, value) => {
        const q = changeFilterData(fieldName, value);
        history.replace(`regions?${getQueryString(q)}`);
        dispatch(getDeliveryRegions(q));
    };
    const batchFilter = (filterState) => {
        const newFilter = setNewFilterData(filterState);
        history.replace(`regions?${getQueryString(newFilter)}`);
        dispatch(getDeliveryRegions(newFilter));
    };
    const getData = () => __awaiter(void 0, void 0, void 0, function* () {
        setDataProcessing(true);
        yield Promise.all([
            dispatch(getDeliveryRegions(queryLength ? query : DEFAULT_FILTER_STATE)),
            dispatch(getDeliveryMetros()),
            dispatch(getDeliveryTerritories()),
            dispatch(getRepairComponents()),
        ]);
        setDataProcessing(false);
    });
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setRegions(apiRegions);
    }, [apiRegions]);
    return {
        regions,
        metros,
        territories,
        components,
        dataProcessing,
        updateFilter,
        batchFilter,
        sortDirection,
        handleSortClose,
        sortAnchorEl,
        isSortOpen,
        handleSortClick,
        isFilterOpen,
        setFilterOpen,
        query: queryLength ? query : DEFAULT_FILTER_STATE,
    };
};
export default useFilter;
