var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'src/components/mui';
import SimpleChip from 'src/components/SimpleChip';
import { fetchPartPickupOrderByReferenceNum } from 'src/TechApp/modules/partpickup/actions';
import { selectPartPickupOrder } from 'src/TechApp/modules/partpickup/selectors';
import { colors } from 'src/styles/theme';
import { setSelectedJobs, setIsJobInfoLoading, } from 'src/AdminApp/modules/swimlanes/actions';
import { CopyToClipboardButton } from 'src/components/buttons';
import { TIME_12H_FORMAT } from 'src/timeConstants';
import { convertToTimezone } from '../../../utils';
import { ApptMain, ApptMainActions, ApptMainInfo, JobInfoContainer, JobInfoSection, JobInfoSubsection, } from '../JobInfo.styles';
import Loader from '../Loader';
const PartPickupJobInfo = ({ selectedJobs, referenceNum, jobIdx, }) => {
    var _a;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const partPickup = useSelector(selectPartPickupOrder);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const job = selectedJobs ? selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0] : undefined;
            if ((job && ((_a = job === null || job === void 0 ? void 0 : job.repairJob) === null || _a === void 0 ? void 0 : _a.type) === 'PART_PICKUP') || referenceNum) {
                try {
                    const response = yield dispatch(fetchPartPickupOrderByReferenceNum(referenceNum !== null && referenceNum !== void 0 ? referenceNum : selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.referenceNum));
                    if (response.error) {
                        throw new Error(`Part Pickup Not Found: ${referenceNum !== null && referenceNum !== void 0 ? referenceNum : job === null || job === void 0 ? void 0 : job.repairJob.referenceNum}`);
                    }
                }
                catch (e) {
                    const remainingJobs = selectedJobs.filter((el) => el.id !== (job === null || job === void 0 ? void 0 : job.id));
                    const newJobs = remainingJobs.length === 0 ? undefined : [...remainingJobs];
                    dispatch(setSelectedJobs(newJobs));
                    // eslint-disable-next-line no-alert
                }
                setLoading(false);
            }
        }))();
        return () => setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedJobs, jobIdx, referenceNum]);
    useEffect(() => {
        if (!loading) {
            dispatch(setIsJobInfoLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    if (loading || !partPickup || !selectedJobs || (selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.length) === 0) {
        return <Loader />;
    }
    const pickupAtAddress = () => {
        if (partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickupAtAddress) {
            return `${partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickupAtAddress.street} ${partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickupAtAddress.city}, ${partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickupAtAddress.state} ${partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickupAtAddress.zip}`;
        }
        if (partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickupAtDistributor) {
            return `${partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickupAtDistributor.street1}\n ${partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickupAtDistributor.city}, ${partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickupAtDistributor.state} ${partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickupAtDistributor.zip}`;
        }
        return null;
    };
    return (<JobInfoContainer>
      <JobInfoSection>
        <ApptMain className="hour">
          <ApptMainInfo>
            {convertToTimezone(selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.startDate, selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.timezone, TIME_12H_FORMAT)}{' '}
            -{' '}
            {convertToTimezone(selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.endDate, selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.timezone, TIME_12H_FORMAT)}
            <div style={{ marginLeft: '5px' }}>
              <SimpleChip label="PART PICKUP" $bgColor={colors.BLUE} $textColor={colors.white}/>
            </div>
          </ApptMainInfo>
          <ApptMainActions>
            <div>
              <span className="ref">Ref #: </span>
              <Link to={`/pops/part-pickups/${partPickup.referenceNum}`} style={{
            textDecoration: 'none',
        }}>
                {partPickup.referenceNum}
              </Link>
            </div>
            <CopyToClipboardButton value={partPickup.referenceNum} width={18} style={{
            color: colors.primary,
            minWidth: 'auto',
            marginLeft: '10px',
        }}/>
          </ApptMainActions>
        </ApptMain>
      </JobInfoSection>
      <Divider />
      <JobInfoSection>
        {partPickup.partDistributorLocation && (<JobInfoSubsection>
            <span className="label">Distributor</span>
            <span className="value primary" style={{
                whiteSpace: 'pre',
            }}>
              {`${partPickup.partDistributorLocation.partDistributorName}\n${partPickup.partDistributorLocation.address}`}
              <CopyToClipboardButton value={`${partPickup.partDistributorLocation.partDistributorName}\n${partPickup.partDistributorLocation.address}`} width={18} style={{
                color: colors.primary,
                minWidth: 'auto',
                marginLeft: '10px',
            }}/>
            </span>
          </JobInfoSubsection>)}
      </JobInfoSection>
      {pickupAtAddress() && (<>
          <Divider />
          <JobInfoSection>
            <JobInfoSubsection>
              <span className="label">Pickup At location</span>
              <span className="value primary" style={{
                whiteSpace: 'pre',
            }}>
                {pickupAtAddress()}
                <CopyToClipboardButton value={(_a = pickupAtAddress()) !== null && _a !== void 0 ? _a : ''} width={18} style={{
                color: colors.primary,
                minWidth: 'auto',
                marginLeft: '10px',
            }}/>
              </span>
            </JobInfoSubsection>
          </JobInfoSection>
        </>)}
    </JobInfoContainer>);
};
export default PartPickupJobInfo;
