import React from 'react';
import { Grid } from 'src/components/mui';
import Calendar from './components/Calendar';
import DateSelector from './components/DateSelector';
import { ClockIcon } from '../icons';
import { JumpButton, DateActions } from './DatePicker.styles';
import Section from '../Section/Section';
import useDatePicker from './hooks/useDatePicker';
const DatePickerComponent = () => {
    const { goToToday } = useDatePicker();
    return (<Section container style={{ alignItems: 'center' }}>
      <Grid item xs={2}>
        <span>Van</span>
      </Grid>

      <Grid item xs={8}>
        <DateSelector />
      </Grid>
      <Grid item xs={2}>
        <DateActions>
          <JumpButton startIcon={<ClockIcon />} onClick={goToToday}>
            Today
          </JumpButton>
          <Calendar />
        </DateActions>
      </Grid>
    </Section>);
};
export default DatePickerComponent;
