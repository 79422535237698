import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { getAdminUsers, } from 'src/AdminApp/modules/users/actions';
import { selectAdminUsersDashboardFetching, selectAdminUsersDashboardPagination, selectAdminUsersDashboardParams, } from 'src/AdminApp/modules/users/selectors';
import DashboardListItem from './DashboardListItem';
import DashboardFilters from './DashboardFilters';
const REQUEST_COLUMNS = [
    { title: 'ID', key: 'id' },
    { title: 'First Name', key: 'firstName' },
    { title: 'Last Name', key: 'lastName' },
    { title: 'Email', key: 'email' },
    { title: 'Phone Number', key: 'phoneNumber' },
    { title: 'Type', key: 'userType' },
    { title: '', key: 'edit' },
];
const AdminSignupDashboard = ({ onEdit, onSearch, adminUsers, }) => {
    var _a;
    const dispatch = useDispatch();
    const params = useSelector(selectAdminUsersDashboardParams);
    const pagination = useSelector(selectAdminUsersDashboardPagination);
    const isFetching = useSelector(selectAdminUsersDashboardFetching);
    useEffect(() => {
        dispatch(getAdminUsers(Object.assign({}, params)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    const onLoadData = ({ page, size, sort }) => {
        dispatch(getAdminUsers(Object.assign(Object.assign({}, params), { pageSize: size, pageIndex: page, sort })));
    };
    const onClear = () => {
        onSearch({ query: '' });
    };
    return (<>
      <DashboardFilters onSearch={onSearch} onClear={onClear}/>
      <SimpleTable data={adminUsers} pagination={pagination} sort={(_a = pagination.sort) !== null && _a !== void 0 ? _a : []} columns={REQUEST_COLUMNS} isFetching={isFetching} renderItem={({ id, firstName, lastName, email, phoneNumber, userType, }) => (<DashboardListItem id={id} firstName={firstName} lastName={lastName} email={email} phoneNumber={phoneNumber} userType={userType} key={id} onEdit={onEdit}/>)} onLoadData={onLoadData}/>
    </>);
};
export default AdminSignupDashboard;
