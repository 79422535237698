import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'src/components/mui';
import { formatTimestamp } from 'src/utils/formatter';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import { colors } from 'src/styles/theme';
const infoStyles = css `
  font-size: 0.625rem;
  line-height: 1rem;
`;
const Info = styled(Typography) `
  ${infoStyles};

  color: ${colors.deepDarkGrey};
  font-weight: 500;
`;
const InfoText = styled('span') `
  ${infoStyles};

  color: ${colors.darkGrey};
`;
export var AUDITING_TYPES;
(function (AUDITING_TYPES) {
    AUDITING_TYPES["ADDED"] = "Added";
    AUDITING_TYPES["CREATED"] = "Created";
    AUDITING_TYPES["MODIFIED"] = "Modified";
    AUDITING_TYPES["REMOVED"] = "Removed";
    AUDITING_TYPES["PROVIDED"] = "Last Provided";
    AUDITING_TYPES["INVOICE_SENT"] = "Invoice Last Sent";
    AUDITING_TYPES["AUTHORIZED"] = "Last Authorized";
    AUDITING_TYPES["ORDERED_BY"] = "Ordered";
    AUDITING_TYPES["IN_PROGRESS"] = "Order In Progress";
})(AUDITING_TYPES || (AUDITING_TYPES = {}));
const AuditInfo = ({ auditType, auditedTimestamp, auditedBy, extraInfo, }) => (<Info>
    {AUDITING_TYPES[auditType]}:
    <InfoText>
      {` ${formatTimestamp(auditedTimestamp)} • `}
      <AdminUser subText={false} updatedUser={auditedBy}/>
      {extraInfo && ` • ${extraInfo}`}
    </InfoText>
  </Info>);
export default AuditInfo;
