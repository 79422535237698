import styled, { css } from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
const mobileStyles = (styles) => function customMobileStyles({ fullScreen }) {
    return fullScreen ? styles : null;
};
export const VendorSearchWrapper = styled.div `
  padding: 32px 0 0 32px;
  flex-grow: 3;

  ${mobileStyles(css `
    padding: 24px 16px 64px 16px;
  `)};
`;
export const IntegratedSwitchWrapper = styled.div `
  text-align: right;
  margin-top: 24px;

  ${mobileStyles(css `
    margin-bottom: 48px;
  `)};
`;
export const VendorSearchHeadline = styled.h4 `
  font-family: ${FONT_FAMILY};
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  margin: 0;
`;
export const VehicleLocation = styled.div `
  margin-top: 16px;

  .mobileVehicleLocationInput {
    width: 100%;
  }

  & > * {
    margin-bottom: 20px;
  }
`;
export const LoadingWrapper = styled.section `
  display: flex;
  flex-direction: column;
  margin-top: 48px;

  > span {
    margin-bottom: 10px;
  }
`;
