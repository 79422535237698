import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'formik';
import { MenuItem, TextField } from 'src/components/mui';
import { selectPaymentSubTypes } from 'src/AdminApp/modules/lookups/selectors';
import { selectEscalationPayments } from 'src/modules/auth/selectors';
const PaymentSubTypeSelect = ({ name, label = 'Payment Subtype', value, fullWidth, }) => {
    const [open, setOpen] = useState(false);
    const paymentSubTypes = useSelector(selectPaymentSubTypes);
    const areEscalationPaymentsPermitted = useSelector(selectEscalationPayments);
    const formOptions = () => [
        ...paymentSubTypes
            .filter((i) => {
            const escalationPayments = [
                'MARKETING',
                'GOODWILL',
                'BAD_DEBT',
                'CHARITY_PR',
                'FLEET_MANAGEMENT_FEE',
                'PARTNERSHIPS',
            ];
            return areEscalationPaymentsPermitted
                ? i
                : !escalationPayments.includes(i.name);
        })
            .map((i) => ({
            value: i.id,
            label: i.displayName,
        })),
    ];
    const renderTextField = ({ field }) => {
        const { name } = field;
        const options = formOptions();
        return (<TextField variant="outlined" select {...{ name, value, fullWidth, label }} {...field} inputProps={{
                id: name,
                open,
                onOpen: () => {
                    setOpen(true);
                },
                onClose: () => {
                    setOpen(false);
                },
            }}>
        {options.map(({ value, label }) => (<MenuItem value={value} key={value}>
            {label}
          </MenuItem>))}
      </TextField>);
    };
    return <Field name={name} render={renderTextField}/>;
};
export default PaymentSubTypeSelect;
