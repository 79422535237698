import React from 'react';
import { useSelector } from 'react-redux';
import { Divider, ListItem, ListItemButton } from 'src/components/mui';
import { selectAuthorizedInternalPaymentCharge, selectEscalationPayments, } from 'src/modules/auth/selectors';
import { STATIC_URL_BASE } from 'src/styles/theme';
import usePaymentDrawer from '../usePaymentDrawer';
import { IconRow, IconTile, Icon, IconLabel, RectangleIconTile, TypeList, TypeLabel, RightIcon, } from './PaymentTypes.styles';
const PaymentTypes = () => {
    const canViewInternalPaymentCharge = useSelector(selectAuthorizedInternalPaymentCharge);
    const areEscalationPaymentsPermitted = useSelector(selectEscalationPayments);
    const { selectPaymentType } = usePaymentDrawer({});
    return (<>
      <IconRow style={{ justifyContent: 'space-evenly' }}>
        {areEscalationPaymentsPermitted && (<IconTile onClick={() => selectPaymentType('Card')}>
            <Icon src={`${STATIC_URL_BASE}tools/svg/payment/card.svg`}/>
            <IconLabel>Card</IconLabel>
          </IconTile>)}
        <IconTile onClick={() => selectPaymentType('Check')}>
          <Icon src={`${STATIC_URL_BASE}tools/svg/payment/check.svg`}/>
          <IconLabel>Check</IconLabel>
        </IconTile>
      </IconRow>
      <Divider />
      <IconRow style={{ justifyContent: 'center' }}>
        <RectangleIconTile onClick={() => selectPaymentType('Financing', 'Affirm', true)}>
          <Icon style={{ width: '70px' }} src={`${STATIC_URL_BASE}tools/svg/payment/affirm.svg`}/>
        </RectangleIconTile>
      </IconRow>
      <TypeList>
        <Divider />
        {canViewInternalPaymentCharge && (<>
            <ListItemButton divider onClick={() => selectPaymentType('Internal')}>
              <img src={`${STATIC_URL_BASE}tools/svg/payment/internal.svg`} alt="internal"/>
              <TypeLabel primary="Internal"/>
              <RightIcon src={`${STATIC_URL_BASE}tools/svg/nav/right.svg`}/>
            </ListItemButton>
            <Divider />
          </>)}
        <ListItem button divider onClick={() => selectPaymentType('ACH Payment')}>
          <img src={`${STATIC_URL_BASE}tools/svg/payment/ach.svg`} alt="ach"/>
          <TypeLabel primary="ACH Payment"/>
          <RightIcon src={`${STATIC_URL_BASE}tools/svg/nav/right.svg`}/>
        </ListItem>
      </TypeList>
    </>);
};
export default PaymentTypes;
