import { Button, Card, CardContent, Divider, IconButton, useMediaQuery, } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { Cached as CachedIcon } from 'src/components/mui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import SimpleChip from 'src/components/SimpleChip';
import { selectFluidsCatalog, selectPartsCatalog, } from 'src/PopsApp/modules/vendors/selectors';
import { colors, theme } from 'src/styles/theme';
import { calculateComponentsAvailability, } from '../utils/componentAvailabilityUtils';
import VendorPartsAvailability from '../VendorPartsAvailability';
import { getChipColor } from '../Vendors.styles';
import { NoNearbyVendors, SearchResultsRow, VendorList, VendorSearchResultsList, VendorSearchResultsTable, } from './VendorSearchResults.styles';
const VendorSearchResults = ({ onVendorSubmit, onVendorFetchAvailability, searchResults, selectedJobs, }) => {
    const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
    const partsCatalog = useSelector(selectPartsCatalog);
    const fluidsCatalog = useSelector(selectFluidsCatalog);
    const handleRefreshAvailabilityClick = (vendor) => (event) => {
        event.stopPropagation();
        return onVendorFetchAvailability(vendor);
    };
    const renderAvailability = (vendor, componentsAvailability) => {
        var _a, _b;
        return vendor.integrated ? (<>
        <Tooltip title="Refresh Vendor Parts Availability">
          <IconButton edge="start" onClick={handleRefreshAvailabilityClick(vendor)}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        <VendorPartsAvailability componentsAvailability={componentsAvailability} loading={(_a = vendor.loading) !== null && _a !== void 0 ? _a : false} loaded={(_b = vendor.loaded) !== null && _b !== void 0 ? _b : false}/>
      </>) : (<SimpleChip className="QuickLocation__badge" $bgColor={getChipColor({
                integrated: false,
            })} $textColor={colors.white} label="Not Integrated"/>);
    };
    const handleSearchResultClick = (selectedVendorSearchResult, componentsAvailability) => {
        let vendorAddress = `${selectedVendorSearchResult.street1}`;
        if (selectedVendorSearchResult.street2) {
            vendorAddress += `, ${selectedVendorSearchResult.street2}`;
        }
        vendorAddress += `, ${selectedVendorSearchResult.city}, ${selectedVendorSearchResult.state} ${selectedVendorSearchResult.zip}`;
        onVendorSubmit({
            partDistributorName: selectedVendorSearchResult.partDistributor.name,
            partDistributorId: selectedVendorSearchResult.partDistributor.id,
            partDistributorLocationId: selectedVendorSearchResult.id,
            address: vendorAddress,
            dealershipName: selectedVendorSearchResult.dealershipName,
        }, !selectedVendorSearchResult.integrated, componentsAvailability);
    };
    if (searchResults.length === 0) {
        return <NoNearbyVendors>No nearby vendors found</NoNearbyVendors>;
    }
    return mdAndUp ? (<VendorSearchResultsTable>
      <thead>
        <tr>
          <th className="searchResultsHeader">Vendor</th>
          <th className="searchResultsHeader">Dealership Name</th>
          <th className="searchResultsHeader">Address</th>
          <th className="searchResultsHeader">Distance</th>
          <th className="searchResultsHeader integrationHeader">
            Parts Availability
          </th>
        </tr>
      </thead>
      <tbody>
        {searchResults.map((vendor) => {
            var _a;
            const componentsAvailability = calculateComponentsAvailability(vendor.id, selectedJobs, partsCatalog[vendor.id], fluidsCatalog[vendor.id]);
            const disable = (_a = (!vendor.loaded || vendor.loading)) !== null && _a !== void 0 ? _a : true;
            return (<SearchResultsRow disablePointerEvents={disable && vendor.integrated} key={vendor.id} onClick={() => {
                    handleSearchResultClick(vendor, componentsAvailability);
                }}>
              <td className="searchResultsItem vendorItem">
                {vendor.partDistributor.name}
              </td>
              <td className="searchResultsItem">{vendor.dealershipName}</td>
              <td className="searchResultsItem">
                <div>{vendor.street1}</div>
                <div>{vendor.street2}</div>
                <div>{`${vendor.city}, ${vendor.state} ${vendor.zip}`}</div>
              </td>
              <td className="searchResultsItem">{`${vendor.distance.value} ${vendor.distance.metric === 'MILES' ? 'mi' : ''}`}</td>
              <td className="searchResultsItem" style={{ padding: 0 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {renderAvailability(vendor, componentsAvailability)}
                </div>
              </td>
            </SearchResultsRow>);
        })}
      </tbody>
    </VendorSearchResultsTable>) : (<VendorSearchResultsList>
      <h5 className="nearbyVendors">Select from nearby vendors</h5>

      <VendorList>
        {searchResults.map((vendor) => {
            const componentsAvailability = calculateComponentsAvailability(vendor.id, selectedJobs, partsCatalog[vendor.id], fluidsCatalog[vendor.id]);
            return (<Card variant="outlined" className="vendorCard" key={vendor.id}>
              <CardContent className="vendorCardContent">
                <Button variant="text" classes={{ root: 'vendorBtnRoot' }} onClick={() => {
                    if (!vendor.loaded || vendor.loading) {
                        return;
                    }
                    handleSearchResultClick(vendor, componentsAvailability);
                }}>
                  <div className="itemTopSection">
                    <div className="vendorNameContainer">
                      <div className="vendorName">
                        {vendor.partDistributor.name}
                      </div>
                      <span className="vendorLabel">Vendor</span>
                    </div>

                    <div className="integratedContainer">
                      <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                        {renderAvailability(vendor, componentsAvailability)}
                      </div>
                    </div>
                  </div>

                  <Divider className="resultDivider"/>

                  <div className="itemBottomSection">
                    <div className="addressContainer">
                      <div className="addressLabel">Address</div>
                      <div className="vendorAddress">
                        <div>{vendor.street1}</div>
                        <div>{vendor.street2}</div>
                        <div>{`${vendor.city}, ${vendor.state} ${vendor.zip} ${vendor.dealershipName
                    ? `- ${vendor.dealershipName}`
                    : ''}`}</div>
                      </div>
                    </div>

                    <div className="distanceContainer">
                      <div className="distanceLabel">Distance</div>
                      <div className="vendorDistance">{`${vendor.distance.value} ${vendor.distance.metric === 'MILES' ? 'mi' : ''}`}</div>
                    </div>
                  </div>
                </Button>
              </CardContent>
            </Card>);
        })}
      </VendorList>
    </VendorSearchResultsList>);
};
export default VendorSearchResults;
