import React from 'react';
import RadioFormTemplate from 'src/AdminApp/containers/requests/StateTracker/RadioFormTemplate';
import { AppointmentResolution } from 'src/AdminApp/models/enums';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
const consumerCancelOptions = [
    AppointmentResolution.CUSTOMER_CANCELED,
    AppointmentResolution.CUSTOMER_NO_SHOW,
    AppointmentResolution.OTHER,
];
const fleetCancelOptions = [
    AppointmentResolution.CUSTOMER_CANCELED,
    AppointmentResolution.CUSTOMER_NO_SHOW,
    AppointmentResolution.TECHNICIAN_ISSUE,
    AppointmentResolution.VEHICLE_ACCESS,
    AppointmentResolution.ENVIRONMENTAL,
    AppointmentResolution.OTHER,
];
const CancelAppointmentDrawer = ({ open, onClose, onSubmit, referenceNum, isFleetRequest = false, }) => {
    const cancelOptions = (isFleetRequest ? fleetCancelOptions : consumerCancelOptions).map((option) => ({
        id: option,
        label: AppointmentResolution.display(option),
    }));
    return (<SimpleDrawer open={open} onClose={onClose} title="Reason for Cancellation">
      <RadioFormTemplate paramName="appointmentResolution" params={{ referenceNum }} onClose={onClose} submitForm={onSubmit} options={cancelOptions}/>
    </SimpleDrawer>);
};
export default CancelAppointmentDrawer;
