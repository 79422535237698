var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { delayFor } from 'src/utils/delay';
import { selectAppointmentIsWithinMaxCancelHours, selectIsActiveAppointmentConfirmed, selectResolutionsOptions, } from 'src/AdminApp/modules/requests/selectors';
import { selectProductEngCancelViewer } from 'src/modules/auth/selectors';
import { selectMpiInspectionByRepairRequestId } from 'src/AdminApp/modules/mpi/selectors';
import { selectHasConsumerMethods } from 'src/AdminApp/modules/payments/selectors';
import { cancelRequestAppointment, fetchAppointmentResolutions, fetchCancelFeeMaxHours, reloadRequest, } from 'src/AdminApp/modules/requests/actions';
import { archiveInspection, getInspectionByRepairRequestId, getInspectionPerformedStatus, } from 'src/AdminApp/modules/mpi/actions';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { loadPaymentMethodsByConsumer } from 'src/AdminApp/modules/payments/actions';
import { initValues, OTHER } from './utils';
const useAdditionalCancelationReasons = ({ repairRequestId, referenceNum, open, consumerId, isFleet, reassignVanFunction, workOrderId, visitReferenceNum, onClose, onlyCancellation, submit, }) => {
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const [openNoShowDrawer, setOpenNoShowDrawer] = useState(false);
    const [cancelFeeChecked, setCancelFeeChecked] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const resolutions = useSelector(selectResolutionsOptions);
    const isProductEngViewer = useSelector(selectProductEngCancelViewer);
    const mpi = useSelector(selectMpiInspectionByRepairRequestId(repairRequestId));
    const matchProps = {
        match: {
            params: { referenceNum },
        },
    };
    const isWithinMaxCancelHours = useSelector((state) => selectAppointmentIsWithinMaxCancelHours(state, matchProps));
    const isAppointmentConfirmed = useSelector((state) => selectIsActiveAppointmentConfirmed(state, matchProps));
    const hasPaymentMethods = useSelector((state) => selectHasConsumerMethods(state, matchProps));
    useEffect(() => {
        if (!open)
            return;
        dispatch(fetchAppointmentResolutions());
        dispatch(fetchCancelFeeMaxHours());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    useEffect(() => {
        if (consumerId && referenceNum && open) {
            dispatch(loadPaymentMethodsByConsumer(consumerId, referenceNum));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consumerId, referenceNum, open]);
    const checkedCancellationFee = !isFleet &&
        isAppointmentConfirmed &&
        isWithinMaxCancelHours &&
        hasPaymentMethods;
    const handleTopReasonChange = useCallback((e) => {
        const { handleChange, setValues, validateForm } = formRef.current;
        setValues(Object.assign(Object.assign({}, initValues), { cancellationFee: e.target.value.toLowerCase() !== 'customer'
                ? false
                : checkedCancellationFee }), true);
        handleChange(e);
        setTimeout(() => {
            validateForm();
        }, 0);
    }, [checkedCancellationFee]);
    const handleReasonChange = useCallback((e) => {
        var _a;
        const { value } = e.target;
        const { handleChange, setFieldValue, validateForm, values } = formRef.current;
        const reasons = resolutions === null || resolutions === void 0 ? void 0 : resolutions.get(values.topReason);
        const subReasons = reasons.get(value);
        const hasDescription = value.toLowerCase() === OTHER;
        const hasSubReasons = subReasons.length > 1 || !!((_a = subReasons[0]) === null || _a === void 0 ? void 0 : _a.specificReason);
        setFieldValue('description', '');
        setFieldValue('subReason', '');
        setFieldValue('hasDescription', hasDescription);
        setFieldValue('hasSubReasons', hasSubReasons);
        setFieldValue('id', !hasSubReasons ? subReasons[0].id : 'none');
        handleChange(e);
        setTimeout(() => {
            validateForm();
        }, 0);
    }, [resolutions]);
    const handleContinue = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        if (reassignVanFunction && resolutions) {
            const specificReason = values.hasSubReasons
                ? values.subReason
                : values.reason;
            yield reassignVanFunction(specificReason);
            setTimeout(() => {
                setSubmitting(false);
            }, 4000);
            return;
        }
        if ((values === null || values === void 0 ? void 0 : values.reason) === 'Cancelled on arrival / No Show') {
            setCancelFeeChecked(values.cancellationFee);
            setOpenNoShowDrawer(true);
        }
        else {
            try {
                yield cancelAppointment(values);
            }
            catch (e) {
                toast.error(e);
            }
        }
        setSubmitting(false);
    });
    const cancelAppointment = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const result = yield dispatch(cancelRequestAppointment({
            appointmentResolution: values.subReason !== '' ? values.subReason : values.id,
            otherResolution: values.description.length > 0 ? values.description : null,
            referenceNum,
            workOrderId: workOrderId !== null && workOrderId !== void 0 ? workOrderId : '',
            visitReferenceNum: visitReferenceNum !== null && visitReferenceNum !== void 0 ? visitReferenceNum : '',
            processCancelFee: values.cancellationFee,
        }));
        const appointmentStatus = (_a = result === null || result === void 0 ? void 0 : result.activeAppointment) === null || _a === void 0 ? void 0 : _a.status;
        if (appointmentStatus === 'CLOSED') {
            yield dispatch(getInvoicesByWorkOrderId({ workOrderId: workOrderId }));
        }
        if (['CANCELED', 'CLOSED'].includes(appointmentStatus) ||
            visitReferenceNum) {
            if (((_b = values === null || values === void 0 ? void 0 : values.reason) === null || _b === void 0 ? void 0 : _b.toUpperCase()) === 'SYSTEM') {
                toast.success('Appointment successfully canceled.');
            }
            else {
                toast.success('We have sent a notification with an explanation to the customer.');
            }
            yield dispatch(reloadRequest(referenceNum));
            if (visitReferenceNum) {
                if (mpi) {
                    yield dispatch(archiveInspection({ referenceNum }));
                    dispatch(getInspectionPerformedStatus(referenceNum));
                    dispatch(getInspectionByRepairRequestId(repairRequestId));
                }
                onClose();
                return;
            }
            if (!onlyCancellation) {
                setOpenRescheduleModal(true);
            }
            else {
                onClose();
            }
        }
    });
    const handleNoShowSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        setSubmitting(true);
        const noShowReasonId = (_d = (_c = resolutions === null || resolutions === void 0 ? void 0 : resolutions.get('CUSTOMER')) === null || _c === void 0 ? void 0 : _c.get('Cancelled on arrival / No Show')[0]) === null || _d === void 0 ? void 0 : _d.id;
        setOpenNoShowDrawer(false);
        try {
            yield cancelAppointment({
                cancellationFee: cancelFeeChecked,
                description: '',
                reason: '',
                subReason: '',
                topReason: '',
                id: noShowReasonId,
            });
        }
        catch (e) {
            toast.error(e);
        }
        setSubmitting(false);
    });
    const handleReschedule = () => __awaiter(void 0, void 0, void 0, function* () {
        yield delayFor(200);
        if (submit)
            submit();
        return Promise.resolve();
    });
    const topReasonVisible = (issuer, key) => {
        var _a, _b;
        return (((_b = (_a = resolutions === null || resolutions === void 0 ? void 0 : resolutions.get(issuer)) === null || _a === void 0 ? void 0 : _a.get(key)) === null || _b === void 0 ? void 0 : _b.filter((res) => {
            var _a, _b, _c;
            return ((_a = res === null || res === void 0 ? void 0 : res.visibility) === null || _a === void 0 ? void 0 : _a.includes('CANCEL')) &&
                ((isProductEngViewer && ((_b = res === null || res === void 0 ? void 0 : res.visibility) === null || _b === void 0 ? void 0 : _b.includes('PROD-ENG'))) ||
                    !((_c = res === null || res === void 0 ? void 0 : res.visibility) === null || _c === void 0 ? void 0 : _c.includes('PROD-ENG')));
        }).length) > 0);
    };
    const getReasonId = () => {
        var _a;
        const reason = 'Cancelled on arrival / No Show';
        const reasons = resolutions === null || resolutions === void 0 ? void 0 : resolutions.get('CUSTOMER');
        const subReasons = reasons === null || reasons === void 0 ? void 0 : reasons.get(reason);
        if (!reasons)
            return '';
        const hasSubReasons = subReasons.length > 1 || !!((_a = subReasons[0]) === null || _a === void 0 ? void 0 : _a.specificReason);
        const id = !hasSubReasons ? subReasons[0].id : 'none';
        return id;
    };
    return {
        dispatch,
        formRef,
        openRescheduleModal,
        setOpenRescheduleModal,
        openNoShowDrawer,
        setOpenNoShowDrawer,
        cancelFeeChecked,
        setCancelFeeChecked,
        isSubmitting,
        setSubmitting,
        resolutions,
        isProductEngViewer,
        mpi,
        isWithinMaxCancelHours,
        isAppointmentConfirmed,
        hasPaymentMethods,
        checkedCancellationFee,
        handleTopReasonChange,
        handleReasonChange,
        handleContinue,
        cancelAppointment,
        handleNoShowSubmit,
        handleReschedule,
        topReasonVisible,
        getReasonId,
    };
};
export default useAdditionalCancelationReasons;
