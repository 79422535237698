/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/label-has-associated-control */
import { List, ListItem, ListItemIcon, ListItemText, Radio, } from 'src/components/mui';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { cardSvgByType } from 'src/AdminApp/containers/payments/PaymentDrawer/utils/cardSvgByType';
import { loadPaymentMethodsByConsumer } from 'src/AdminApp/modules/payments/actions';
import { selectConsumerMethodsByConsumerId } from 'src/AdminApp/modules/payments/selectors';
import { selectRequestContactInfoReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import SimpleChip from 'src/components/SimpleChip';
import { MONTH_AND_YEAR_FORMAT } from 'src/timeConstants';
import PaymentForm from '../PaymentForm';
import { PaymentChooserContainer } from './PaymentChooser.styles';
const PaymentChooser = ({ onMethodChosen, triggerCCSubmit, onCCSubmit, onFormValid, onFormError, errorMode, path, }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { referenceNum } = params;
    const contactInfo = useSelector(selectRequestContactInfoReferenceNum(referenceNum));
    const { consumerId } = contactInfo;
    const [methodChosen, setMethodChosen] = useState();
    const [userCreditCard, setUserCreditCard] = useState();
    const paymentMethods = useSelector(selectConsumerMethodsByConsumerId(parseInt(consumerId, 10)));
    const methodChange = (method, card) => {
        setMethodChosen(method);
        const updatedCard = card !== null && card !== void 0 ? card : userCreditCard;
        onMethodChosen(method, method === 'EXISTING' ? updatedCard : undefined);
    };
    const handleMethodChange = (e) => {
        if (e.target.value === 'NEW') {
            setUserCreditCard(undefined);
            methodChange('NEW');
        }
        else {
            const existingPayment = paymentMethods.find((method) => method.id === e.target.value);
            setUserCreditCard(existingPayment);
            methodChange('EXISTING', existingPayment);
        }
    };
    useEffect(() => {
        if (consumerId) {
            dispatch(loadPaymentMethodsByConsumer(consumerId, referenceNum));
        }
    }, []); // eslint-disable-line
    useEffect(() => {
        if (paymentMethods.length > 0) {
            const foundCreditCard = paymentMethods.find((p) => p.methodType === 'CREDIT_CARD');
            if (foundCreditCard) {
                setUserCreditCard(foundCreditCard);
                methodChange('EXISTING', foundCreditCard);
            }
            else {
                methodChange('NEW');
            }
        }
        else {
            methodChange('NEW');
        }
    }, [paymentMethods]); // eslint-disable-line
    const onFinishedAddPaymentMethod = (newPayment) => {
        if (onCCSubmit) {
            onCCSubmit(newPayment);
        }
    };
    return (<PaymentChooserContainer>
      <div className="PaymentChooser">
        <List className="PaymentChooser__list">
          {paymentMethods.map((method) => {
            var _a, _b, _c;
            const expDate = moment()
                .utc()
                .month((method === null || method === void 0 ? void 0 : method.monthExpiration) ? ((_a = method === null || method === void 0 ? void 0 : method.monthExpiration) !== null && _a !== void 0 ? _a : 0) - 1 : 0) // js months start from 0
                .year((_b = method === null || method === void 0 ? void 0 : method.yearExpiration) !== null && _b !== void 0 ? _b : 0)
                .format(MONTH_AND_YEAR_FORMAT);
            return (<ListItem key={method.id} divider>
                <ListItemIcon>
                  <Radio value={method.id} name={`creditCardExisting-${method.id}`} id={`creditCardExisting-${method.id}`} color="primary" checked={(userCreditCard === null || userCreditCard === void 0 ? void 0 : userCreditCard.id) === method.id} onChange={handleMethodChange} className={classNames({
                    'PaymentChooser__radio--error': errorMode,
                })}/>
                </ListItemIcon>
                <ListItemText>
                  <label htmlFor={`creditCardExisting-${method.id}`} className="PaymentChooser__ccDetails PaymentChooser__itemLabel">
                    <span className="PaymentChooser__ccDetailsMain">
                      <img className="PaymentChooser__ccLogo" src={cardSvgByType((_c = method === null || method === void 0 ? void 0 : method.cardType) === null || _c === void 0 ? void 0 : _c.toLowerCase())} alt={`${method === null || method === void 0 ? void 0 : method.cardType} logo`}/>
                      <span className="PaymentChooser__last4">••••</span>
                      <span>{method === null || method === void 0 ? void 0 : method.lastFourDigits}</span>
                      <span className="PaymentChooser__ccBullet">•</span>
                      <span>{expDate}</span>
                      {method.defaultMethod && (<SimpleChip label="DEFAULT" variant="outlined"/>)}
                    </span>
                  </label>
                </ListItemText>
              </ListItem>);
        })}

          <ListItem>
            <ListItemIcon>
              <Radio value="NEW" name="creditCardNew" id="creditCardNew" color="primary" checked={methodChosen === 'NEW'} onChange={handleMethodChange} className={classNames({
            'PaymentChooser__radio--error': errorMode,
        })}/>
            </ListItemIcon>
            <ListItemText>
              <label htmlFor="creditCardNew" className="PaymentChooser__ccLabel PaymentChooser__itemLabel">
                Add a new debit / credit card
              </label>
            </ListItemText>
          </ListItem>
          {methodChosen === 'NEW' && (<ListItem className="PaymentChooser__addCC">
              <PaymentForm onFinishedAddPaymentMethod={onFinishedAddPaymentMethod} path={path} triggerCCSubmit={triggerCCSubmit} onFormValid={onFormValid} onFormError={onFormError} alwaysDefault consumerId={+consumerId}/>
            </ListItem>)}
        </List>
      </div>
    </PaymentChooserContainer>);
};
export default PaymentChooser;
