import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { mapServiceArea } from 'src/AdminApp/modules/mappers';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byId: {},
    autocomplete: [],
    zipCodes: [],
    transferredZipCode: null,
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['id,asc'],
        },
    },
};
const serviceAreaSuccessReducer = (state, action) => {
    const model = mapServiceArea(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [model.id]: fetched(model) }), dashboard: Object.assign(Object.assign({}, state.dashboard), { _isFetching: false }) });
};
const serviceAreasSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const serviceAreasFetchReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const serviceAreaFetchReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { _isFetching: true }) });
};
const serviceAreasClearReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const serviceAreasUpdateSuccessReducer = (state, action) => {
    const { id } = action.meta;
    const model = mapServiceArea(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [model.id]: Object.assign(Object.assign({}, state.byId[id]), model) }) });
};
const serviceAreasAddSuccessReducer = (state, action) => {
    const model = mapServiceArea(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [model.id]: Object.assign(Object.assign({}, state.byId[model.id]), model) }) });
};
const serviceAreasDeleteSuccessReducer = (state, action) => {
    var _a, _b;
    const { id } = action.meta;
    const content = (_b = (_a = state === null || state === void 0 ? void 0 : state.dashboard) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.filter((x) => x.id !== id);
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { dashboard: {
                content,
            }, pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { totalElements: state.dashboard.pagination.totalElements - 1 }) }) });
};
const serviceAreasAutocompleteSuccessReducer = (state, action) => {
    const results = action === null || action === void 0 ? void 0 : action.payload;
    return Object.assign(Object.assign({}, state), { autocomplete: results });
};
const validateZipCodesSuccessReducer = (state, action) => {
    const results = action === null || action === void 0 ? void 0 : action.payload;
    return Object.assign(Object.assign({}, state), { zipCodes: results });
};
const removeZipCode = (state, action) => {
    const { zipCode } = action.meta;
    const zipCodes = state.zipCodes.filter((x) => x.name !== zipCode);
    return Object.assign(Object.assign({}, state), { zipCodes });
};
const clearZipCode = (state) => {
    return Object.assign(Object.assign({}, state), { zipCodes: [], transferredZipCode: [] });
};
const transferZipCode = (state, action) => {
    const results = action === null || action === void 0 ? void 0 : action.payload;
    return Object.assign(Object.assign({}, state), { transferredZipCode: results });
};
const reducers = handleActions({
    [ActionCreators.SERVICE_AREAS.SUCCESS.type]: serviceAreasSuccessReducer,
    [ActionCreators.SERVICE_AREAS.FETCH.type]: serviceAreasFetchReducer,
    [ActionCreators.SERVICE_AREAS.CLEAR.type]: serviceAreasClearReducer,
    [ActionCreators.SERVICE_AREA.SUCCESS.type]: serviceAreaSuccessReducer,
    [ActionCreators.SERVICE_AREA.FETCH.type]: serviceAreaFetchReducer,
    [ActionCreators.ADD_SERVICE_AREA.SUCCESS.type]: serviceAreasAddSuccessReducer,
    [ActionCreators.UPDATE_SERVICE_AREA.SUCCESS.type]: serviceAreasUpdateSuccessReducer,
    [ActionCreators.DELETE_SERVICE_AREA.SUCCESS.type]: serviceAreasDeleteSuccessReducer,
    [ActionCreators.ARCHIVE_SERVICE_AREA.SUCCESS.type]: serviceAreasDeleteSuccessReducer,
    [ActionCreators.SERVICE_AREAS_AUTOCOMPLETE.SUCCESS.type]: serviceAreasAutocompleteSuccessReducer,
    [ActionCreators.VALIDATE_ZIP_CODES.SUCCESS.type]: validateZipCodesSuccessReducer,
    [ActionCreators.REMOVE_ZIP_CODE.SUCCESS.type]: removeZipCode,
    [ActionCreators.CLEAR_ZIP_CODES.SUCCESS.type]: clearZipCode,
    [ActionCreators.TRANSFER_ZIP_CODE.SUCCESS.type]: transferZipCode,
}, DEFAULT_STATE);
export default reducers;
