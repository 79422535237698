import { Typography } from 'src/components/mui';
import { Formik } from 'formik';
import numeral from 'numeral';
import React, { useRef } from 'react';
import { TextField } from 'src/AdminApp/components/form';
import { priceInputProps } from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/utils';
import { ConfirmationModal } from 'src/components/layout';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import ResponsiblePartySelect from './ResponsiblePartySelect/ResponsiblePartySelect';
import { AddPayerBtn, AddPayerContainer, Block, blockMarginStyle, Divider, FieldWrapper, RemainingContainer, RemoveBtn, RemoveContainer, } from './PayerDrawer.styles';
import usePayerDrawer from './usePayerDrawer';
const PayerDrawer = ({ handleClose, open = false, invoice, workOrderId, car, }) => {
    var _a;
    const formRef = useRef(null);
    const { handleFormSubmit, initValues, info, handleRemovePayer, handleAddPayer, handleOpenModal, modal, thirdPartyModal, removeStyle, workOrderIsAuthorized, isSubmitting, availableResponsibleParties, openAssignRpToVinModal, handleAddResponsiblePartyToVin, handleCloseCleanup, } = usePayerDrawer({
        invoice,
        workOrderId,
        handleClose,
        formRef,
        open,
        car,
    });
    if (!initValues)
        return null;
    const title = (_a = info === null || info === void 0 ? void 0 : info.repairName) === null || _a === void 0 ? void 0 : _a.split(' ').slice(1).join(' ');
    return (<>
      <SimpleDrawer size="oneThird" open={open} onClose={handleOpenModal} title={title}>
        <Formik initialValues={initValues} innerRef={formRef} onSubmit={handleFormSubmit} enableReinitialize>
          {({ dirty, errors, isValid, submitForm, touched, values, setFieldValue, }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            const total = numeral((info === null || info === void 0 ? void 0 : info.totalCost) || 0)
                .subtract(values.amount1 || 0)
                .subtract(values.amount2 || 0)
                .format('0,0.00');
            const rp1HasCustomerPay = ((_b = (_a = values.responsibleParty1) === null || _a === void 0 ? void 0 : _a.responsiblePartyType) === null || _b === void 0 ? void 0 : _b.name) ===
                'Customer Pay';
            const rp2HasCustomerPay = ((_d = (_c = values.responsibleParty2) === null || _c === void 0 ? void 0 : _c.responsiblePartyType) === null || _d === void 0 ? void 0 : _d.name) ===
                'Customer Pay';
            const IsCtaDisabled = !dirty ||
                !isValid ||
                isSubmitting ||
                (!values.responsibleParty2 && !!values.amount2);
            return (<>
                <SimpleDrawerContent>
                  <Block>
                    <FieldWrapper>
                      <ResponsiblePartySelect responsibleParties={availableResponsibleParties} onSelect={(suggestion) => setFieldValue('responsibleParty1', suggestion)} disabled={rp1HasCustomerPay ||
                    (values.has2ResponsibleParties &&
                        workOrderIsAuthorized)} initialValue={{
                    id: values.responsibleParty1.id,
                    name: `${((_e = values.responsibleParty1) === null || _e === void 0 ? void 0 : _e.suffix) ? `[${values.responsibleParty1.suffix}]` : ''} ${(_f = values.responsibleParty1) === null || _f === void 0 ? void 0 : _f.name}`,
                    label: `${((_g = values.responsibleParty1) === null || _g === void 0 ? void 0 : _g.suffix) ? `[${values.responsibleParty1.suffix}]` : ''} ${(_h = values.responsibleParty1) === null || _h === void 0 ? void 0 : _h.name}`,
                }}/>
                    </FieldWrapper>

                    {values.has2ResponsibleParties && (<FieldWrapper>
                        <TextField helperText={touched.amount1 && errors.amount1} label="Responsible Party Type 01 Amount" name="amount1" variant="outlined" {...priceInputProps}/>
                      </FieldWrapper>)}
                    <RemoveContainer style={removeStyle(!!(values === null || values === void 0 ? void 0 : values.responsibleParty2))}>
                      <RemoveBtn name="1" onClick={handleRemovePayer} disabled={rp1HasCustomerPay ||
                    (values.has2ResponsibleParties &&
                        workOrderIsAuthorized)}>
                        Remove
                      </RemoveBtn>
                    </RemoveContainer>
                  </Block>
                  <Divider />
                  {!values.has2ResponsibleParties ? (<AddPayerContainer>
                      <AddPayerBtn color="primary" onClick={handleAddPayer}>
                        <Typography variant="subtitle2">
                          Add Responsible Party
                        </Typography>
                      </AddPayerBtn>
                    </AddPayerContainer>) : (<>
                      <Block style={blockMarginStyle}>
                        <FieldWrapper>
                          <ResponsiblePartySelect responsibleParties={availableResponsibleParties} onSelect={(suggestion) => setFieldValue('responsibleParty2', suggestion)} disabled={rp2HasCustomerPay ||
                        (values.has2ResponsibleParties &&
                            workOrderIsAuthorized)} initialValue={Object.keys((_j = values === null || values === void 0 ? void 0 : values.responsibleParty2) !== null && _j !== void 0 ? _j : {})
                        .length > 0
                        ? {
                            id: (_k = values.responsibleParty2) === null || _k === void 0 ? void 0 : _k.id,
                            name: `${((_l = values.responsibleParty2) === null || _l === void 0 ? void 0 : _l.suffix) ? `[${values.responsibleParty2.suffix}]` : ''} ${(_m = values.responsibleParty2) === null || _m === void 0 ? void 0 : _m.name}`,
                            label: `${((_o = values.responsibleParty2) === null || _o === void 0 ? void 0 : _o.suffix) ? `[${values.responsibleParty2.suffix}]` : ''} ${(_p = values.responsibleParty2) === null || _p === void 0 ? void 0 : _p.name}`,
                        }
                        : undefined}/>
                        </FieldWrapper>

                        {values.has2ResponsibleParties && (<FieldWrapper>
                            <TextField helperText={touched.amount2 && errors.amount2} label="Responsible Party 02 Amount" name="amount2" variant="outlined" {...priceInputProps}/>
                          </FieldWrapper>)}
                        <RemoveContainer style={removeStyle(!!(values === null || values === void 0 ? void 0 : values.responsibleParty2))}>
                          <RemoveBtn name="2" onClick={handleRemovePayer} disabled={rp2HasCustomerPay ||
                        (values.has2ResponsibleParties &&
                            workOrderIsAuthorized)}>
                            Remove
                          </RemoveBtn>
                        </RemoveContainer>
                        <Divider />
                      </Block>
                      <RemainingContainer>
                        <Typography variant="subtitle2">
                          Remaining Total:
                        </Typography>
                        <Typography variant="subtitle2">${total}</Typography>
                      </RemainingContainer>
                    </>)}
                </SimpleDrawerContent>
                <SimpleDrawerFooter>
                  <FooterActionButton disabled={IsCtaDisabled} onClick={submitForm}>
                    Save
                  </FooterActionButton>
                </SimpleDrawerFooter>
                <ConfirmationModal open={!!openAssignRpToVinModal} title="Would you like to assign them to this VIN now?" text="The selected responsible party is not currently associated with this VIN." onClose={handleCloseCleanup} action={handleAddResponsiblePartyToVin}/>
              </>);
        }}
        </Formik>
      </SimpleDrawer>
      {modal}
      {thirdPartyModal}
    </>);
};
export default PayerDrawer;
