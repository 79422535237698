import styled from 'styled-components';
import { Grid } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
export const AppointmentStyled = styled(Grid) `
  padding: 20px 24px;
  border: solid 1px ${colors.lightGrey};
  border-left: 6px solid ${colors.primary};
  margin-top: 15px;
  border-radius: 4px;
`;
export const AppointmentSection = styled(Grid) `
  border-bottom: solid 1px ${colors.lightGrey};
  padding: ${({ nobottompadding }) => nobottompadding === 'true' ? 'none' : '0 0 16px 0'};

  &:not(:first-child) {
    padding-top: 16px;
  }
`;
export const RefNumsContainer = styled.div `
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  margin-top: 6px;

  button {
    display: inline-block;
    min-width: auto;
  }
`;
export const SkeduloJob = styled.span `
  display: inline;

  ${theme.breakpoints.up('md')} {
    display: block;
  }
`;
export const ServicesList = styled.ul `
  list-style: none;
  padding-left: 0;
  margin: 0 0 16px;

  li {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;

    &:not(:first-child) {
      margin-top: 6px;
    }
  }
`;
export const CompletedLabel = styled.div `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 6px;

  svg {
    color: ${colors.success};
    margin-left: 4px;
    height: 20px;
    width: 20px;
  }
`;
export const AppointmentTaskAction = styled(Grid) `
  padding-top: 16px;

  ${theme.breakpoints.up('md')} {
    padding-top: 0;
  }

  button {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;

    ${theme.breakpoints.up('md')} {
      width: 100%;
    }
  }
`;
export const AppointmentLabel = styled.p `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  margin: 0 0 4px 0;
`;
export const AppointmentInfo = styled.p `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  margin: 0;

  &:last-child {
    margin-bottom: 16px;
  }
`;
