var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { find, uniqBy } from 'lodash';
import CONSTANTS from 'src/constants';
import { getAvailableTrimIdsByEngineDesc, getEnginesByDescription, getLowestEngineId, } from 'src/AdminApp/containers/vehicle/utils';
import { getAutoselectionMakes, getAutoselectionModels, } from 'src/AdminApp/modules/autoselection/actions';
export const ADDITIONAL_TYPE = 'ADDITIONAL';
export const getInitialVehicleState = (vehicle, dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const { year, make: makeProp, model: modelProp, trim: trimProp, engineId: engineIdProp, vin, mileage, mileageOut, nickname, plateNumber, plateNumberState, residualValue, } = vehicle || {};
    let newStateVehicle = {};
    return dispatch(getAutoselectionMakes({ year: year }))
        .then(({ payload: makes }) => {
        const make = find(makes, (i) => i.value === makeProp);
        newStateVehicle = Object.assign(Object.assign({}, vehicle), { year, makeId: make === null || make === void 0 ? void 0 : make.id, vin,
            mileage,
            mileageOut,
            nickname,
            plateNumber,
            plateNumberState,
            residualValue });
        return dispatch(getAutoselectionModels({ year: year, makeId: make.id }));
    })
        .then(({ payload: models }) => {
        const model = find(models, (i) => i.value === modelProp);
        const { engines, trims } = model;
        const isIDontKnowTrim = trimProp === CONSTANTS.TRIM_DONT_KNOW;
        const isIDontKnowEngine = engineIdProp === CONSTANTS.ENGINE_DONT_KNOW_ID;
        const engine = isIDontKnowEngine
            ? {
                id: CONSTANTS.ENGINE_DONT_KNOW_ID,
                value: CONSTANTS.ENGINE_DONT_KNOW,
            }
            : engines.find((e) => e.id === parseInt(engineIdProp !== null && engineIdProp !== void 0 ? engineIdProp : '-1', 10));
        const engineDesc = engineIdProp ? engine.value : '';
        const trim = isIDontKnowTrim
            ? {
                id: CONSTANTS.TRIM_DONT_KNOW_ID,
                value: CONSTANTS.TRIM_DONT_KNOW,
            }
            : trims.find((i) => i.value === trimProp);
        const engineDescOfTrim = !!trimProp &&
            !isIDontKnowTrim &&
            engines
                .filter((e) => trim.availableEngineIds.includes(e.id))
                .map((e) => e.description);
        const enginesOfTrim = engineDescOfTrim
            ? engines.map((e) => ({
                description: e.description,
                disabled: !engineDescOfTrim.includes(e.description),
            }))
            : engines;
        const engineOptions = uniqBy(enginesOfTrim, 'description');
        const allTrimIdsOfEngines = getAvailableTrimIdsByEngineDesc(engineDesc, engines);
        const trimOptions = !engineIdProp || isIDontKnowEngine
            ? trims
            : trims.map((trim) => (Object.assign(Object.assign({}, trim), { disabled: !(allTrimIdsOfEngines === null || allTrimIdsOfEngines === void 0 ? void 0 : allTrimIdsOfEngines.includes(trim.id)) })));
        return Object.assign(Object.assign({}, newStateVehicle), { engineDesc, engineIsRequired: !!engineIdProp, engineOptions, modelId: model.id, model, trimId: trimProp ? trim.id : '', trimIsRequired: !!trimProp, trimOptions });
    });
});
export const normalizeVehicle = (vehicle, makes) => {
    var _a, _b, _c, _d;
    const { year, makeId, model, trimId, engineDesc, plate, plateNumber, fleetFmcId, } = vehicle !== null && vehicle !== void 0 ? vehicle : {};
    const modelTrims = (_a = model === null || model === void 0 ? void 0 : model.trims) !== null && _a !== void 0 ? _a : [];
    const modelEngines = (_b = model === null || model === void 0 ? void 0 : model.engines) !== null && _b !== void 0 ? _b : [];
    const make = makes.find((i) => i.id === makeId);
    const trim = trimId === CONSTANTS.TRIM_DONT_KNOW_ID
        ? { value: CONSTANTS.TRIM_DONT_KNOW }
        : modelTrims.find((i) => i.id === trimId);
    const engineIdsOfTrim = (_c = trim === null || trim === void 0 ? void 0 : trim.availableEngineIds) !== null && _c !== void 0 ? _c : null;
    const selectedEngines = getEnginesByDescription(engineDesc, modelEngines);
    const lowestSelectedEngineId = selectedEngines
        ? getLowestEngineId(selectedEngines, engineIdsOfTrim)
        : '';
    const engine = engineDesc === CONSTANTS.ENGINE_DONT_KNOW
        ? {
            id: CONSTANTS.ENGINE_DONT_KNOW_ID,
            value: CONSTANTS.ENGINE_DONT_KNOW,
        }
        : modelEngines.find((i) => i.id === lowestSelectedEngineId);
    const carPlate = plateNumber || plate;
    const normalizedVehicle = {
        year,
        make: make === null || make === void 0 ? void 0 : make.value,
        model: (_d = model === null || model === void 0 ? void 0 : model.value) !== null && _d !== void 0 ? _d : null,
        trim: trimId && trim ? trim.value : null,
        engineId: engineDesc && engine ? engine.id : null,
        engineDescription: engineDesc || '',
        plate: carPlate,
        fleetFmcId,
    };
    return normalizedVehicle;
};
export const selectInspectorName = (mpiInspector) => {
    let inspectorName = 'User Name Unavailable';
    if ((mpiInspector === null || mpiInspector === void 0 ? void 0 : mpiInspector.firstName) || (mpiInspector === null || mpiInspector === void 0 ? void 0 : mpiInspector.lastName)) {
        inspectorName = `${(mpiInspector === null || mpiInspector === void 0 ? void 0 : mpiInspector.firstName) ? `${mpiInspector.firstName} ` : ''}${(mpiInspector === null || mpiInspector === void 0 ? void 0 : mpiInspector.lastName) ? mpiInspector === null || mpiInspector === void 0 ? void 0 : mpiInspector.lastName : ''}`;
    }
    return inspectorName;
};
export const onDecodedVehicleSelect = (thisVehicle, dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const { make, makeId, vin, year: yearString, engineId, style: trimName, } = thisVehicle;
    const year = parseInt(yearString, 10);
    yield dispatch(getAutoselectionMakes({ year }));
    const { payload: autoselectionModels } = yield dispatch(getAutoselectionModels({
        year,
        makeId,
    }));
    const model = autoselectionModels.find((i) => i.value === thisVehicle.model);
    const { engines, trims } = model;
    let engineObject = null;
    if (engineId && engines.length > 1) {
        engineObject = engines.find((i) => i.id === engineId);
    }
    else if (engines.length === 1) {
        [engineObject] = engines;
    }
    const isIDontKnowTrim = thisVehicle.style === CONSTANTS.TRIM_DONT_KNOW;
    const isIDontKnowEngine = `${engineId}` === CONSTANTS.ENGINE_DONT_KNOW_ID || !engineObject;
    const engine = isIDontKnowEngine
        ? {
            id: CONSTANTS.ENGINE_DONT_KNOW_ID,
            value: CONSTANTS.ENGINE_DONT_KNOW,
        }
        : engineObject;
    const engineDesc = engine ? engine === null || engine === void 0 ? void 0 : engine.value : CONSTANTS.ENGINE_DONT_KNOW;
    const trim = isIDontKnowTrim
        ? {
            availableEngineIds: null,
            id: CONSTANTS.TRIM_DONT_KNOW_ID,
            value: CONSTANTS.TRIM_DONT_KNOW,
        }
        : trims.find((i) => i.value.localeCompare(trimName) === 0);
    const engineDescOfTrim = !!trimName &&
        !isIDontKnowTrim &&
        engines
            .filter((e) => { var _a; return (_a = trim === null || trim === void 0 ? void 0 : trim.availableEngineIds) === null || _a === void 0 ? void 0 : _a.includes(e.id); })
            .map((e) => e.description);
    const enginesOfTrim = engineDescOfTrim
        ? engines.map((e) => (Object.assign(Object.assign({}, e), { disabled: !engineDescOfTrim.includes(e.description) })))
        : engines;
    const engineOptions = uniqBy(enginesOfTrim, 'description');
    const allTrimIdsOfEngines = getAvailableTrimIdsByEngineDesc(engineDesc, engines);
    const trimOptions = !engineId || isIDontKnowEngine
        ? trims
        : trims.map((t) => (Object.assign(Object.assign({}, t), { disabled: !(allTrimIdsOfEngines === null || allTrimIdsOfEngines === void 0 ? void 0 : allTrimIdsOfEngines.includes(t.id)) })));
    return {
        engineDesc,
        engineIsRequired: true,
        engineOptions,
        make,
        makeId,
        model,
        modelId: model.id,
        trimId: trimName ? trim.id : '',
        trimIsRequired: !!trimName,
        trimOptions,
        vin,
        year,
    };
});
