import React, { useMemo } from 'react';
import { useIsWidthDown } from 'src/hooks/useIsWidthDown';
import CurrencyLabel from 'src/components/CurrencyLabel';
import QuotedPriceMode from 'src/AdminApp/models/enums/QuotedPriceMode';
export const InitialPrice = ({ disableMobileView = false, price, }) => {
    const isRanged = useMemo(() => {
        if ((price === null || price === void 0 ? void 0 : price.status) === 'RECOMMENDED') {
            return ((price === null || price === void 0 ? void 0 : price.quotedPriceMode) !== QuotedPriceMode.SINGLE &&
                (price === null || price === void 0 ? void 0 : price.calculatedMaxTotalCost) > (price === null || price === void 0 ? void 0 : price.calculatedMinTotalCost));
        }
        return (price === null || price === void 0 ? void 0 : price.quotedPriceMode) === QuotedPriceMode.RANGED;
    }, [
        price === null || price === void 0 ? void 0 : price.status,
        price === null || price === void 0 ? void 0 : price.calculatedMaxTotalCost,
        price === null || price === void 0 ? void 0 : price.calculatedMinTotalCost,
        price === null || price === void 0 ? void 0 : price.quotedPriceMode,
    ]);
    const isWidthDownSm = useIsWidthDown('sm');
    const isMobile = !disableMobileView && isWidthDownSm;
    if (!(price === null || price === void 0 ? void 0 : price.initialTotalCost)) {
        return <span>None</span>;
    }
    if (isRanged) {
        return (<div style={{
                fontSize: isMobile ? '12px' : 'inherit',
                display: 'inline-flex',
                justifyContent: 'flex-start',
                flexDirection: isMobile ? 'column' : 'row',
            }}>
        <span>
          <CurrencyLabel>{price.calculatedMinTotalCost}</CurrencyLabel>
          &nbsp;-&nbsp;
        </span>
        <span>
          <CurrencyLabel>{price.calculatedMaxTotalCost}</CurrencyLabel>
        </span>
      </div>);
    }
    if ((price === null || price === void 0 ? void 0 : price.quotedPriceMode) === QuotedPriceMode.SINGLE) {
        return <CurrencyLabel>{price.initialTotalCost}</CurrencyLabel>;
    }
    return <span>None</span>;
};
export default InitialPrice;
