import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    skedulo: {
        synchronizedResources: {},
    },
    appointment: {
        resolutions: {},
        options: {},
    },
    configuration: {
        customConfigs: {},
    },
};
const syncSkeduloIdsSuccess = (state, action) => {
    return Object.assign(Object.assign({}, state), { skedulo: Object.assign(Object.assign({}, state.skedulo), { synchronizedResources: action.payload }) });
};
const getResolutions = (state, action) => {
    return Object.assign(Object.assign({}, state), { appointment: Object.assign(Object.assign({}, state.appointment), { resolutions: action.payload }) });
};
const getCustomConfigs = (state, action) => {
    return Object.assign(Object.assign({}, state), { configuration: Object.assign(Object.assign({}, state.configuration), { customConfigs: action.payload }) });
};
const getResolutionOptions = (state, action) => {
    return Object.assign(Object.assign({}, state), { appointment: Object.assign(Object.assign({}, state.appointment), { options: action.payload }) });
};
const reducers = handleActions({
    [ActionCreators.MATCH_SKEDULO_IDS.SUCCESS.type]: syncSkeduloIdsSuccess,
    [ActionCreators.APPOINTMENT_RESOLUTIONS.SUCCESS.type]: getResolutions,
    [ActionCreators.CUSTOM_CONFIGS.SUCCESS.type]: getCustomConfigs,
    [ActionCreators.APPOINTMENT_RESOLUTIONS_OPTIONS.SUCCESS.type]: getResolutionOptions,
}, DEFAULT_STATE);
export default reducers;
