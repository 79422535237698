import React from 'react';
import styled from 'styled-components';
import { DatePicker } from 'src/components/mui';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const FullWidthDatePicker = styled(DatePicker) `
  width: 100%;
`;
const DashboardDatePicker = ({ label, value, onChange, }) => {
    return (<FullWidthDatePicker label={label} value={value} onChange={onChange} format={LOCALIZED_DATE_FORMAT} slotProps={{
            textField: {
                fullWidth: true,
                variant: 'standard',
            },
        }}/>);
};
export default DashboardDatePicker;
