import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Autocomplete } from 'src/components/mui';
import useAutocompleteInput from 'src/hooks/useAutocompleteInput';
const RegionSearch = ({ value, getRegions, regionsSelector, onUpdate, name, label, }) => {
    const { onInputChange } = useAutocompleteInput(value, false, getRegions);
    const serviceAreas = useSelector(regionsSelector);
    const options = serviceAreas;
    const onSubmit = (value) => {
        onUpdate(value);
    };
    return (<Autocomplete value={value} style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option, value) => option.id === value.id} fullWidth options={options} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} onInputChange={onInputChange} onChange={(e, value) => {
            onSubmit(value);
        }} renderInput={(params) => (<TextField {...params} name={name} label={label} type="text" fullWidth variant="outlined" InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default RegionSearch;
