var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDiscountReasonTypes } from 'src/AdminApp/modules/lookups/selectors';
import { getDiscountReasonTypes } from 'src/AdminApp/modules/lookups/actions';
const useAddDiscountDrawer = ({ onAddPromoCode, onAddCFCredit, onAddOtherDiscount, }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const closeHandler = () => setOpen(false);
    const openHandler = () => setOpen(true);
    const discountReasonTypes = useSelector(selectDiscountReasonTypes);
    useEffect(() => {
        if (!discountReasonTypes.length && open) {
            dispatch(getDiscountReasonTypes());
        }
    }, [open, discountReasonTypes, dispatch]);
    const onAddPromo = ({ promoCode }) => __awaiter(void 0, void 0, void 0, function* () {
        yield onAddPromoCode({ promoCode });
        closeHandler();
    });
    const onAddCredit = ({ invoiceId, creditAmount, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield onAddCFCredit({
            originalInvoiceId: invoiceId,
            creditAmount,
        });
        closeHandler();
    });
    const onAddOther = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield onAddOtherDiscount(values);
        closeHandler();
    });
    return {
        open,
        openHandler,
        closeHandler,
        discountReasonTypes,
        onAddPromo,
        onAddCredit,
        onAddOther,
    };
};
export default useAddDiscountDrawer;
