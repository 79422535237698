var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { selectMpiInspectionDetailByRepairRequestId } from 'src/AdminApp/modules/mpi/selectors';
import { setFleet, setRepairs, setTab, saveUser, setVehicle, } from 'src/AdminApp/modules/quickRo/actions';
import { CONSUMER_TABS as TABS, FLEET_TABS, } from 'src/AdminApp/modules/quickRo/enums';
import { acceptRecommendedRepair, addRecommendedRepairs, addRepairComponents, closeServiceDrawer, getRecommendedRepairs, loadRequest, openServiceDrawer, rejectRecommendedRepair, removeRepairComponents, undoRejectedRecommendedRepair, updateRepairComponents, } from 'src/AdminApp/modules/requests/actions';
import { ServiceDrawerName } from 'src/AdminApp/modules/requests/repairs.state';
import { selectCreatedRepairs, selectRecommendedRepairs, selectRecommendedRepairsIsSubmitting, selectRecommendedSubTotal, selectRejectedRepairs, selectRepairMeasurements, selectRequestDataByReferenceNum, selectScheduledRepairs, selectServiceDrawer, selectSuggestedRepairsNodeIdsArrayByRefNum, } from 'src/AdminApp/modules/requests/selectors';
import { getRepairRequestStopwatch, getTechnicianOnSiteWorkflowByRef, } from 'src/AdminApp/modules/workflows/actions';
import { toast } from 'src/components/SimpleToast';
import { WARNINGS } from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal/WarningMessages';
import { delayFor } from 'src/utils/delay';
const useRecommendedRepairs = ({ referenceNum, selectorProps, repairRequestId, fmcWorkAuthorizationStatus, workOrderId, fetchMPIFmcWorkAuthorizationStatus, hasInvoice, }) => {
    var _a, _b;
    const dispatch = useDispatch();
    const [selectedRepairs, setSelectedRepairs] = useState([]);
    const [selectedRecommendedRepairIds, setSelectedRecommendedRepairIds] = useState([]);
    const [isAddToCurrentApprovalOpen, setIsAddToCurrentApprovalOpen] = useState(false);
    const [isScheduleAppointmentApprovalOpen, setIsScheduleAppointmentApprovalOpen,] = useState(false);
    const [showScheduling, setShowScheduling] = useState(false);
    const [adding, setIsAdding] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [areAllSelectedRepairsValid, setAreAllSelectedRepairsValid] = useState(false);
    const [recommendedRepairsDirty, setRecommendedRepairsDirty] = useState(false);
    const [showCapacityWarning, setShowCapacityWarning] = useState(false);
    const [unsavedInternalApprovalProvidedById, setUnsavedInternalApprovalProvidedById,] = useState();
    const [capacityWarningMessage, setCapacityWarningMessage] = useState();
    const measurements = useSelector((state) => selectRepairMeasurements(state, selectorProps));
    const inspectionDetail = useSelector(selectMpiInspectionDetailByRepairRequestId(repairRequestId));
    const isSubmitting = useSelector(selectRecommendedRepairsIsSubmitting(referenceNum));
    const recommendedRepairs = useSelector(selectRecommendedRepairs(referenceNum));
    const suggestedRepairsNodeIds = useSelector(selectSuggestedRepairsNodeIdsArrayByRefNum(referenceNum));
    const rejectedRepairs = useSelector(selectRejectedRepairs(referenceNum));
    const scheduledRepairs = useSelector(selectScheduledRepairs(referenceNum));
    const createdRepairs = useSelector(selectCreatedRepairs(referenceNum));
    const subTotal = useSelector(selectRecommendedSubTotal(referenceNum));
    const repairRequest = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const isFleet = !!((_a = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _a === void 0 ? void 0 : _a.fleetId);
    const requestData = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const serviceDrawerState = useSelector(selectServiceDrawer);
    const hasRepairs = (repairs) => (repairs === null || repairs === void 0 ? void 0 : repairs.length) > 0;
    const isAbleToAddToCurrent = fmcWorkAuthorizationStatus !== 'SUBMITTED' &&
        fmcWorkAuthorizationStatus !== 'APPROVED';
    const shouldHaveAdditionalDetails = ((_b = [
        ...(recommendedRepairs !== null && recommendedRepairs !== void 0 ? recommendedRepairs : []),
        ...(scheduledRepairs !== null && scheduledRepairs !== void 0 ? scheduledRepairs : []),
        ...(createdRepairs !== null && createdRepairs !== void 0 ? createdRepairs : []),
    ].filter((repair) => {
        return repair.partsPrices.some((part) => { var _a; return ((_a = part === null || part === void 0 ? void 0 : part.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'tire' && !(part === null || part === void 0 ? void 0 : part.partMetadata); });
    })) === null || _b === void 0 ? void 0 : _b.length) > 0;
    useEffect(() => {
        var _a;
        if (!inspectionDetail)
            return;
        const inspectionResults = (_a = inspectionDetail === null || inspectionDetail === void 0 ? void 0 : inspectionDetail.inspectionResults) !== null && _a !== void 0 ? _a : [];
        const newRecommendedRepairs = inspectionResults.filter((r) => { var _a; return suggestedRepairsNodeIds.includes((_a = r === null || r === void 0 ? void 0 : r.inspectionRsRepair) === null || _a === void 0 ? void 0 : _a.repairId); });
        if (newRecommendedRepairs.length > 0 &&
            !isSubmitting &&
            !hasRepairs(recommendedRepairs) &&
            !hasRepairs(rejectedRepairs) &&
            !hasRepairs(scheduledRepairs) &&
            !hasRepairs(createdRepairs)) {
            dispatch(addRecommendedRepairs({
                referenceNum,
                inspectionResults,
                origin: 'FIELDOPS',
            })).then(() => dispatch(getRecommendedRepairs(referenceNum)));
        }
    }, [inspectionDetail]); // eslint-disable-line
    useEffect(() => {
        let validSelectedRepairs = true;
        // eslint-disable-next-line
        for (const repairId of selectedRepairs) {
            const selectedRepair = recommendedRepairs.find((r) => r.id === repairId);
            validSelectedRepairs =
                validSelectedRepairs &&
                    (selectedRepair === null || selectedRepair === void 0 ? void 0 : selectedRepair.calculatedTotalCost) !== null &&
                    (selectedRepair === null || selectedRepair === void 0 ? void 0 : selectedRepair.laborTime) !== null &&
                    (selectedRepair === null || selectedRepair === void 0 ? void 0 : selectedRepair.totalCost) !== null;
        }
        setAreAllSelectedRepairsValid(validSelectedRepairs);
    }, [selectedRepairs.length]); // eslint-disable-line
    useEffect(() => {
        setSelectedRepairs([]);
        setSelectedRecommendedRepairIds([]);
    }, [recommendedRepairs === null || recommendedRepairs === void 0 ? void 0 : recommendedRepairs.length]);
    const areMeasurementsCompleteForSelectedRepairs = useMemo(() => {
        var _a, _b;
        if (!recommendedRepairs || !selectedRepairs || !measurements) {
            return true;
        }
        const selectedRepairIds = (_a = recommendedRepairs
            .filter((repair) => selectedRepairs.includes(repair.id))
            .map((repair) => repair.repairId)) !== null && _a !== void 0 ? _a : [];
        const selectedRepairMeasurements = (_b = measurements.filter((measurement) => measurement.repairs.find((repairId) => selectedRepairIds.includes(repairId)) !== undefined)) !== null && _b !== void 0 ? _b : [];
        return (selectedRepairMeasurements.find((measurement) => !measurement.optional && !measurement.value) === undefined);
    }, [selectedRepairs, recommendedRepairs, measurements]);
    const onUndoRejectedRecommendedRepair = (id) => {
        dispatch(undoRejectedRecommendedRepair({ id })).then(() => dispatch(getRecommendedRepairs(referenceNum)));
    };
    const onRejectRecommendedRepair = (id) => {
        setIsRejectModalOpen(id);
    };
    const onRejectRecommendedRepairSubmit = ({ rejectedReason, otherReason, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(rejectRecommendedRepair({
            id: isRejectModalOpen,
            rejectedReason,
            otherReason,
        }));
        dispatch(getRecommendedRepairs(referenceNum));
        setIsRejectModalOpen(false);
    });
    const submitInternalApprovalProvider = (internalApprovalProvidedById) => {
        if (isAddToCurrentApprovalOpen) {
            onAcceptRecommendedRepairs({
                internalApprovalProvidedById,
                isValidateLaborCapacity: !!requestData.request.visit,
            });
        }
        else if (isScheduleAppointmentApprovalOpen) {
            onScheduleAppointmentClick(internalApprovalProvidedById);
        }
    };
    const closeApprovalDialog = () => {
        setIsAddToCurrentApprovalOpen(false);
        setIsScheduleAppointmentApprovalOpen(false);
    };
    const selectedRepairsRequireApproval = () => {
        return selectedRepairs.some((id) => {
            const repair = recommendedRepairs.find((r) => r.id === id);
            return !!repair && repair.requiresApproval;
        });
    };
    const onAcceptRecommendedRepairs = ({ internalApprovalProvidedById, isValidateLaborCapacity = false, }) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedRepairsRequireApproval() && !internalApprovalProvidedById) {
            setIsAddToCurrentApprovalOpen(true);
            return;
        }
        const response = yield dispatch(acceptRecommendedRepair({
            suggestedRepairIds: selectedRepairs,
            internalApprovalProvidedById,
            isValidateLaborCapacity,
        }));
        if (response.error &&
            response.payload.response.status === 400 &&
            response.payload.response.message.toLowerCase() === 'visit over capacity') {
            setUnsavedInternalApprovalProvidedById(internalApprovalProvidedById);
            setShowCapacityWarning(true);
            setCapacityWarningMessage(WARNINGS.MPI_ADD_TO_CURRENT);
        }
        else if (response.error) {
            toast.error(response.payload.response.message);
        }
        else {
            setIsAddToCurrentApprovalOpen(false);
            dispatch(getRecommendedRepairs(referenceNum));
            dispatch(loadRequest(referenceNum));
            if (hasInvoice) {
                dispatch(getInvoicesByWorkOrderId({ workOrderId }));
            }
            dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
            dispatch(getRepairRequestStopwatch(referenceNum));
            yield delayFor(5000);
            fetchMPIFmcWorkAuthorizationStatus();
            setSelectedRepairs([]);
            setSelectedRecommendedRepairIds([]);
        }
    });
    const handleCapacityWarningClose = (confirm) => {
        if (confirm) {
            onAcceptRecommendedRepairs({
                internalApprovalProvidedById: unsavedInternalApprovalProvidedById,
                isValidateLaborCapacity: false,
            });
        }
        setShowCapacityWarning(false);
        setUnsavedInternalApprovalProvidedById(undefined);
    };
    const onScheduleAppointmentClick = (internalApprovalProvidedById) => {
        var _a, _b, _c, _d, _e, _f;
        if (selectedRepairsRequireApproval() && !internalApprovalProvidedById) {
            setIsScheduleAppointmentApprovalOpen(true);
            return;
        }
        setIsScheduleAppointmentApprovalOpen(false);
        const user = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.contactInfo;
        const vehicle = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.car;
        const address = (_a = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _a === void 0 ? void 0 : _a.address;
        const repairs = selectedRepairs.map((id) => {
            const repair = recommendedRepairs.find((r) => r.id === id);
            return {
                id,
                label: repair === null || repair === void 0 ? void 0 : repair.repair,
                repairId: repair === null || repair === void 0 ? void 0 : repair.repairId,
                laborTime: repair === null || repair === void 0 ? void 0 : repair.laborTime,
                internalApprovalProvidedById,
            };
        });
        if (!address) {
            toast.error('You must add an address to this request before you can schedule another appointment');
            return;
        }
        dispatch(saveUser(Object.assign(Object.assign({}, user), { id: user.consumerId, address: Object.assign(Object.assign({}, address), { streetLine1: address.street, coordinates: {
                    latitude: address.latitude,
                    longitude: address.longitude,
                } }) })));
        dispatch(setVehicle(Object.assign(Object.assign({}, vehicle), { id: vehicle.consumerCarId })));
        dispatch(setRepairs(repairs));
        dispatch(setFleet({
            consumerId: (_b = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.contactInfo) === null || _b === void 0 ? void 0 : _b.consumerId,
            consumer: {
                address: (_e = (_d = (_c = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _c === void 0 ? void 0 : _c.visit) === null || _d === void 0 ? void 0 : _d.address) !== null && _e !== void 0 ? _e : (_f = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _f === void 0 ? void 0 : _f.address,
            },
        }));
        if (isFleet) {
            dispatch(setTab(FLEET_TABS.SELECT_VISIT));
        }
        else {
            dispatch(setTab(TABS.SELECT_APPOINTMENT));
        }
        setShowScheduling(true);
    };
    const onAddComponentToRecommendedRepair = (repair) => {
        setIsAdding(true);
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.addComponent,
            repair,
            isRecommended: true,
        }));
    };
    const onEditComponentToRecommendedRepair = (type, repair, component) => {
        setIsAdding(false);
        if (type === 'part') {
            dispatch(openServiceDrawer({
                drawerName: ServiceDrawerName.part,
                repair,
                part: component,
                isRecommended: true,
            }));
        }
        else if (type === 'fluid') {
            dispatch(openServiceDrawer({
                drawerName: ServiceDrawerName.fluid,
                repair,
                fluid: component,
                isRecommended: true,
            }));
        }
        else if (type === 'labor') {
            dispatch(openServiceDrawer({
                drawerName: ServiceDrawerName.labor,
                repair,
                isRecommended: true,
            }));
        }
    };
    const onSelectRepair = (id) => {
        const index = selectedRepairs.indexOf(id);
        if (index !== -1) {
            const newSelectedRepairs = [...selectedRepairs];
            newSelectedRepairs.splice(index, 1);
            setSelectedRepairs(newSelectedRepairs);
            const newSelectedRecommendedRepairIds = [...selectedRecommendedRepairIds];
            newSelectedRecommendedRepairIds.splice(index, 1);
            setSelectedRecommendedRepairIds(newSelectedRecommendedRepairIds);
        }
        else {
            setSelectedRepairs([...selectedRepairs, id]);
            setSelectedRecommendedRepairIds([...selectedRecommendedRepairIds, id]);
        }
    };
    const addComponent = ({ repairId, body, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(addRepairComponents({ referenceNum, repairId, body }));
        setRecommendedRepairsDirty(true);
        dispatch(closeServiceDrawer());
        yield delayFor(5000);
        fetchMPIFmcWorkAuthorizationStatus();
        dispatch(getRecommendedRepairs(referenceNum));
    });
    const updateComponent = ({ repairId, body, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(updateRepairComponents({ referenceNum, repairId, body }));
        setRecommendedRepairsDirty(true);
        dispatch(closeServiceDrawer());
        yield delayFor(5000);
        fetchMPIFmcWorkAuthorizationStatus();
        return dispatch(getRecommendedRepairs(referenceNum));
    });
    const removeComponent = ({ repairId, componentId, componentType, removeDynamicRule = false, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(removeRepairComponents({
            referenceNum,
            repairId,
            componentId,
            componentType,
            removeDynamicRule,
        }));
        dispatch(closeServiceDrawer());
        yield delayFor(5000);
        fetchMPIFmcWorkAuthorizationStatus();
        setRecommendedRepairsDirty(true);
        return dispatch(getRecommendedRepairs(referenceNum));
    });
    const onRemoveComponent = (repairId, componentId, componentType, removeDynamicRule) => {
        dispatch(closeServiceDrawer());
        return removeComponent({
            repairId,
            componentId,
            componentType,
            removeDynamicRule,
        });
    };
    const onPartSubmit = (values) => {
        var _a;
        const repairPrice = Object.assign({}, serviceDrawerState.repair);
        delete repairPrice.fluidPrices;
        const repairId = (_a = serviceDrawerState.repair) === null || _a === void 0 ? void 0 : _a.id;
        return values.id
            ? updateComponent({
                repairId,
                body: Object.assign(Object.assign({}, repairPrice), { parts: [Object.assign(Object.assign({}, repairPrice.parts), values)] }),
            })
            : addComponent({
                repairId,
                body: {
                    parts: [Object.assign({}, values)],
                },
            });
    };
    const onFluidSubmit = (values) => {
        const repairPrice = Object.assign({}, serviceDrawerState.repair);
        delete repairPrice.parts;
        const repairId = serviceDrawerState.repair.id;
        return serviceDrawerState.fluid
            ? updateComponent({
                repairId,
                body: Object.assign(Object.assign({}, repairPrice), { fluids: [Object.assign(Object.assign({}, serviceDrawerState.fluid), values)] }),
            })
            : addComponent({
                repairId,
                body: {
                    fluids: [Object.assign({}, values)],
                },
            });
    };
    const onLaborSubmit = (values) => {
        const repairId = serviceDrawerState.repair.id;
        return updateComponent({
            repairId,
            body: values,
        });
    };
    const onLaborRemove = () => {
        const repairId = serviceDrawerState.repair.id;
        return updateComponent({
            repairId,
            body: {
                laborRate: null,
                laborTime: null,
            },
        });
    };
    const handleOpenAddFluid = () => dispatch(openServiceDrawer({
        drawerName: ServiceDrawerName.fluid,
        adding: true,
        repair: serviceDrawerState.repair,
        fluid: null,
        isRecommended: true,
    }));
    const handleOpenAddLabor = () => dispatch(openServiceDrawer({
        drawerName: ServiceDrawerName.labor,
        adding: true,
        repair: serviceDrawerState.repair,
        isRecommended: true,
    }));
    const handleOpenAddPart = () => dispatch(openServiceDrawer({
        drawerName: ServiceDrawerName.part,
        adding: true,
        repair: serviceDrawerState.repair,
        part: null,
        isRecommended: true,
    }));
    const fixedPartComponents = useMemo(() => {
        var _a, _b, _c, _d;
        const { repair } = serviceDrawerState;
        if (!repair)
            return false;
        const fixedFluids = (_b = (_a = repair.fluidPrices) === null || _a === void 0 ? void 0 : _a.filter((f) => { var _a; return ((_a = f === null || f === void 0 ? void 0 : f.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'FIXED'; }).map((f) => f.componentId)) !== null && _b !== void 0 ? _b : [];
        const fixedParts = (_d = (_c = repair.partsPrices) === null || _c === void 0 ? void 0 : _c.filter((p) => { var _a; return ((_a = p.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'FIXED'; }).map((p) => p.componentId)) !== null && _d !== void 0 ? _d : [];
        return [...fixedFluids, ...fixedParts];
    }, [serviceDrawerState]);
    const fixedFluidComponents = useMemo(() => {
        var _a, _b, _c, _d;
        const { repair } = serviceDrawerState;
        if (!repair)
            return false;
        const fixedFluids = (_b = (_a = repair.fluidPrices) === null || _a === void 0 ? void 0 : _a.filter((f) => { var _a; return ((_a = f === null || f === void 0 ? void 0 : f.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'FIXED'; }).map((f) => f.componentId)) !== null && _b !== void 0 ? _b : [];
        const fixedParts = (_d = (_c = repair.partsPrices) === null || _c === void 0 ? void 0 : _c.filter((p) => { var _a; return ((_a = p.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'FIXED'; }).map((p) => p.componentId)) !== null && _d !== void 0 ? _d : [];
        return [...fixedFluids, ...fixedParts];
    }, [serviceDrawerState]);
    const handleCloseServiceDrawer = () => {
        dispatch(closeServiceDrawer());
    };
    return {
        setRecommendedRepairsDirty,
        recommendedRepairsDirty,
        hasRepairs,
        recommendedRepairs,
        scheduledRepairs,
        rejectedRepairs,
        createdRepairs,
        onRejectRecommendedRepair,
        onAddComponentToRecommendedRepair,
        onEditComponentToRecommendedRepair,
        onSelectRepair,
        selectedRepairs,
        onUndoRejectedRecommendedRepair,
        subTotal,
        isAbleToAddToCurrent,
        onAcceptRecommendedRepairs,
        areAllSelectedRepairsValid,
        areMeasurementsCompleteForSelectedRepairs,
        onScheduleAppointmentClick,
        selectedRecommendedRepairIds,
        shouldHaveAdditionalDetails,
        isRejectModalOpen,
        setIsRejectModalOpen,
        onRejectRecommendedRepairSubmit,
        isFleet,
        showScheduling,
        setShowScheduling,
        serviceDrawerState,
        onLaborSubmit,
        onLaborRemove,
        adding,
        onPartSubmit,
        onRemoveComponent,
        onFluidSubmit,
        isScheduleAppointmentApprovalOpen,
        isAddToCurrentApprovalOpen,
        closeApprovalDialog,
        submitInternalApprovalProvider,
        handleOpenAddFluid,
        handleOpenAddLabor,
        handleOpenAddPart,
        showCapacityWarning,
        handleCapacityWarningClose,
        capacityWarningMessage,
        requestData,
        fixedPartComponents,
        fixedFluidComponents,
        handleCloseServiceDrawer,
    };
};
export default useRecommendedRepairs;
