import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const SpecialOrderWrapper = styled.span `
  display: flex;
  align-items: center;

  .neededText {
    color: ${colors.GRAY_3F};
    margin-right: 16px;
  }
`;
