import React from 'react';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { Typography, Grid } from 'src/components/mui';
import { formatPhone } from 'src/utils/formatter';
import EditableSelect from 'src/components/form/EditableSelect';
import EditableInput from 'src/components/form/EditableInput';
import { CopyToClipboardButton } from 'src/components/buttons';
import RepairHeader from 'src/AdminApp/containers/requests/RepairHeader';
import ZendeskInfo from 'src/AdminApp/zendesk/ZendeskInfo';
import { MarketLabel, HelperIcon, Row, InfoWrapper, EmailWrapper, UserWrapper, HealthReportWrapper, } from 'src/AdminApp/containers/requests/ContactInfo/ContactInfo.styles';
import useContactInfo from 'src/AdminApp/containers/requests/ContactInfo/useContactInfo';
import { selectInspectionPerformedStatus } from 'src/AdminApp/modules/mpi/selectors';
import { selectMarketingSources } from 'src/AdminApp/modules/lookups/selectors';
import MobileContactInfo from './MobileContactInfo';
const ContactInfo = ({ referenceNum, repairRequestId, name, phone, email, vehicle, consumerContactInfo, fleet, fleetContactInfo, logo, marketingSource, id, canEdit = true, tags, }) => {
    var _a;
    const initialValues = {
        name,
        phone,
        email,
    };
    const { isMobile, onUpdateContact, onUpdateCar, updateMarketingSource } = useContactInfo(referenceNum, repairRequestId, name, phone, email, vehicle, consumerContactInfo, fleet, logo, marketingSource, id);
    const inspectionPerformedStatus = useSelector(selectInspectionPerformedStatus);
    const marketingSources = useSelector(selectMarketingSources);
    const options = marketingSources.map((item) => ({
        label: item.displayName,
        value: item.id,
    }));
    let handleHealthStatus = '';
    if (inspectionPerformedStatus) {
        handleHealthStatus = startCase(inspectionPerformedStatus.status.toLowerCase());
    }
    const renderUniqueInfo = () => {
        var _a, _b, _c, _d, _e, _f;
        if (isMobile) {
            return (<MobileContactInfo email={email} initialValues={initialValues} canEdit={canEdit} onUpdateContact={onUpdateContact} fleet={fleet} fleetContactInfo={fleetContactInfo}/>);
        }
        if (fleet) {
            return (<Grid container style={{
                    marginTop: '15px',
                }} spacing={2}>
          <Grid item xs={12}>
            <Row>
              <HelperIcon>person</HelperIcon>
              <UserWrapper>
                {fleetContactInfo
                    ? fleetContactInfo.dailyOpsContactName
                    : fleet.contactName}
              </UserWrapper>
              <CopyToClipboardButton value={fleetContactInfo
                    ? (_a = fleetContactInfo.dailyOpsContactName) !== null && _a !== void 0 ? _a : ''
                    : fleet.contactName} style={{ marginLeft: '-6px' }}/>
            </Row>
          </Grid>
          <Grid item xs={6}>
            <Row>
              <HelperIcon>phone</HelperIcon>
              <div>
                {formatPhone(fleetContactInfo
                    ? (_b = fleetContactInfo.dailyOpsContactPhone) !== null && _b !== void 0 ? _b : ''
                    : fleet.phone)}
              </div>
              <CopyToClipboardButton value={fleetContactInfo
                    ? (_c = fleetContactInfo.dailyOpsContactPhone) !== null && _c !== void 0 ? _c : ''
                    : fleet.phone} style={{ marginLeft: '-6px' }}/>
            </Row>
          </Grid>
          <Grid item xs={6}>
            <Row>
              <MarketLabel>Marketing Source</MarketLabel>
              <div>
                <EditableSelect onUpdate={(value) => updateMarketingSource(value)} value={(_d = marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.id) !== null && _d !== void 0 ? _d : 'unknown'} options={options} unsetOption={!(marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.id)} unsetOptionText="unknown" isEditable={canEdit}/>
              </div>
            </Row>
          </Grid>
          <Grid item xs={6}>
            <Row>
              <HelperIcon>alternate_email</HelperIcon>
              <EmailWrapper>
                {fleetContactInfo
                    ? fleetContactInfo.dailyOpsContactEmail
                    : fleet.email}
              </EmailWrapper>
              <CopyToClipboardButton value={fleetContactInfo
                    ? (_e = fleetContactInfo.dailyOpsContactEmail) !== null && _e !== void 0 ? _e : ''
                    : fleet.email} style={{ marginLeft: '-6px' }}/>
            </Row>
          </Grid>
          <Grid item xs={6}>
            <Row>
              <MarketLabel>Zendesk ID</MarketLabel>
              <ZendeskInfo consumerId={id}/>
            </Row>
          </Grid>
        </Grid>);
        }
        return (<Grid container direction={isMobile ? 'column' : 'row'} style={{
                marginTop: '15px',
            }} spacing={1}>
        <Grid item xs={6}>
          <Row>
            <HelperIcon>phone</HelperIcon>
            <EditableInput value={initialValues.phone} displayValue={formatPhone(initialValues.phone)} defaultValue="-" onUpdate={onUpdateContact('phone')} isEditable={canEdit}/>
            <CopyToClipboardButton value={initialValues.phone} style={{ marginLeft: '-6px' }}/>
          </Row>
        </Grid>
        <Grid item xs={6}>
          <Row>
            <MarketLabel>Marketing Source</MarketLabel>
            <div>
              <EditableSelect onUpdate={(value) => updateMarketingSource(value)} value={(_f = marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.id) !== null && _f !== void 0 ? _f : 'unknown'} options={options} unsetOption={!(marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.id)} unsetOptionText="unknown" isEditable={canEdit}/>
            </div>
          </Row>
        </Grid>

        <Grid item xs={6}>
          <Row>
            <HelperIcon>alternate_email</HelperIcon>
            <EmailWrapper>{email}</EmailWrapper>
            <CopyToClipboardButton value={email} style={{ marginLeft: '-6px' }}/>
          </Row>
        </Grid>
        <Grid item xs={6}>
          <Row>
            <MarketLabel>Zendesk ID</MarketLabel>
            <ZendeskInfo consumerId={id}/>
          </Row>
        </Grid>
      </Grid>);
    };
    return (<Typography component="span">
      <RepairHeader vehicle={vehicle} consumer={consumerContactInfo} fleet={fleet} logo={logo} onUpdateContact={onUpdateContact('name')} onUpdateCar={onUpdateCar} referenceNum={referenceNum} repairRequestId={repairRequestId} canEdit={canEdit} tags={tags}/>
      {inspectionPerformedStatus && isMobile && (<HealthReportWrapper>
          Health Report:
          {handleHealthStatus}
        </HealthReportWrapper>)}

      <InfoWrapper>
        {renderUniqueInfo()}{' '}
        {isMobile && (<>
            <Row>
              <MarketLabel>Marketing Source</MarketLabel>
              <div style={{ marginTop: '8px' }}>
                <EditableSelect onUpdate={(value) => updateMarketingSource(value)} value={(_a = marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.id) !== null && _a !== void 0 ? _a : 'unknown'} options={options} unsetOption={!(marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.id)} unsetOptionText="unknown" isEditable={canEdit}/>
              </div>
            </Row>
            <Row>
              {' '}
              <MarketLabel>Zendesk ID</MarketLabel>
              <ZendeskInfo consumerId={id}/>
            </Row>
          </>)}
      </InfoWrapper>
    </Typography>);
};
export default ContactInfo;
