import moment from 'moment';
import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { DATE_TIME_FORMAT, DATE_YMD_FORMAT } from 'src/timeConstants';
// FSA ACTIONS
export const ActionCreators = {
    SERVICE_AREAS: createDefaultActionCreators('SERVICE_AREAS', ['CLEAR']),
    SERVICE_AREA: createDefaultActionCreators('SERVICE_AREA'),
    SERVICE_AREA_BY_ZIP: createDefaultActionCreators('SERVICE_AREA_BY_ZIP'),
    SERVICE_AREAS_AUTOCOMPLETE: createDefaultActionCreators('SERVICE_AREAS_AUTOCOMPLETE'),
    ADD_SERVICE_AREA: createDefaultActionCreators('ADD_SERVICE_AREA'),
    UPDATE_SERVICE_AREA: createDefaultActionCreators('UPDATE_SERVICE_AREA'),
    DELETE_SERVICE_AREA: createDefaultActionCreators('DELETE_SERVICE_AREA'),
    ARCHIVE_SERVICE_AREA: createDefaultActionCreators('ARCHIVE_SERVICE_AREA'),
    VALIDATE_ZIP_CODES: createDefaultActionCreators('VALIDATE_ZIP_CODES'),
    REMOVE_ZIP_CODE: createDefaultActionCreators('REMOVE_ZIP_CODE'),
    CLEAR_ZIP_CODES: createDefaultActionCreators('CLEAR_ZIP_CODES'),
    TRANSFER_ZIP_CODE: createDefaultActionCreators('TRANSFER_ZIP_CODE'),
};
export const getServiceAreasAutocomplete = (name, territoryIds, metroIds, regionIds) => {
    const filters = {
        name: name !== null && name !== void 0 ? name : '',
        territoryIds: territoryIds !== null && territoryIds !== void 0 ? territoryIds : [],
        metroIds: metroIds !== null && metroIds !== void 0 ? metroIds : [],
        regionIds: regionIds !== null && regionIds !== void 0 ? regionIds : [],
    };
    return createAPIAction({
        types: [
            ActionCreators.SERVICE_AREAS_AUTOCOMPLETE.FETCH(),
            ActionCreators.SERVICE_AREAS_AUTOCOMPLETE.SUCCESS(),
            ActionCreators.SERVICE_AREAS_AUTOCOMPLETE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SERVICE_AREAS_AUTOCOMPLETE(filters),
        method: 'GET',
    });
};
export const getServiceAreaById = (id) => createAPIAction({
    types: [
        ActionCreators.SERVICE_AREA.FETCH(),
        ActionCreators.SERVICE_AREA.SUCCESS(),
        ActionCreators.SERVICE_AREA.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.SERVICE_AREAS_BY_ID(id),
    method: 'GET',
});
export const getServiceAreaByZipCode = (zipCode) => createAPIAction({
    types: [
        ActionCreators.SERVICE_AREA_BY_ZIP.FETCH(),
        ActionCreators.SERVICE_AREA_BY_ZIP.SUCCESS(),
        ActionCreators.SERVICE_AREA_BY_ZIP.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.SERVICE_AREAS_BY_ZIP_CODE(zipCode),
    method: 'GET',
});
export const addServiceArea = (value) => {
    var _a;
    return createAPIAction({
        types: [
            ActionCreators.ADD_SERVICE_AREA.FETCH(),
            ActionCreators.ADD_SERVICE_AREA.SUCCESS(),
            ActionCreators.ADD_SERVICE_AREA.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SERVICE_AREAS(),
        body: {
            name: value === null || value === void 0 ? void 0 : value.name,
            regionId: (_a = value === null || value === void 0 ? void 0 : value.region) === null || _a === void 0 ? void 0 : _a.id,
            zipCodes: value === null || value === void 0 ? void 0 : value.zipCodes,
            deliveryVanServiceAreas: value === null || value === void 0 ? void 0 : value.deliveryVanServiceAreas,
            marketingLaunchDate: value && value.marketingLaunchDate
                ? moment(value.marketingLaunchDate)
                    .startOf('day')
                    .format(DATE_TIME_FORMAT)
                : undefined,
            bookingEffectiveDate: value && value.bookingEffectiveDate
                ? moment(value.bookingEffectiveDate)
                    .startOf('day')
                    .format(DATE_TIME_FORMAT)
                : undefined,
        },
        method: 'POST',
    });
};
export const updateServiceArea = (value) => {
    var _a, _b, _c;
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_SERVICE_AREA.FETCH(),
            ActionCreators.UPDATE_SERVICE_AREA.SUCCESS({
                meta: () => ({ id: value.id }),
            }),
            ActionCreators.UPDATE_SERVICE_AREA.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SERVICE_AREAS_BY_ID(value.id),
        method: 'PUT',
        body: {
            name: value === null || value === void 0 ? void 0 : value.name,
            regionId: (_a = value === null || value === void 0 ? void 0 : value.region) === null || _a === void 0 ? void 0 : _a.id,
            zipCodes: (_b = value === null || value === void 0 ? void 0 : value.zipCodes) === null || _b === void 0 ? void 0 : _b.map((zip) => {
                return {
                    name: zip.name,
                    b2bExclusive: zip.b2bExclusive,
                };
            }),
            deliveryVanServiceAreas: (_c = value === null || value === void 0 ? void 0 : value.deliveryVanServiceAreas) === null || _c === void 0 ? void 0 : _c.map((dvsa) => {
                return {
                    id: dvsa.id,
                    serviceAreaId: dvsa.serviceAreaId,
                    van: {
                        id: dvsa.van.id,
                        name: dvsa.van.id,
                    },
                    effectiveFrom: dvsa.effectiveFrom,
                    effectiveTo: dvsa.effectiveTo,
                };
            }),
            marketingLaunchDate: value && value.marketingLaunchDate
                ? moment(value.marketingLaunchDate)
                    .startOf('day')
                    .format(DATE_TIME_FORMAT)
                : undefined,
            bookingEffectiveDate: value && value.bookingEffectiveDate
                ? moment(value.bookingEffectiveDate)
                    .startOf('day')
                    .format(DATE_TIME_FORMAT)
                : undefined,
        },
    });
};
export const archiveServiceArea = ({ id, archiveDate, }) => createAPIAction({
    types: [
        ActionCreators.ARCHIVE_SERVICE_AREA.FETCH(),
        ActionCreators.ARCHIVE_SERVICE_AREA.SUCCESS({ meta: () => ({ id }) }),
        ActionCreators.ARCHIVE_SERVICE_AREA.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.SERVICE_AREAS_ARCHIVE(id),
    method: 'PUT',
    body: {
        archiveDate: moment(archiveDate).startOf('day').format(DATE_YMD_FORMAT),
    },
});
export const validateZipCodes = (zipCodes, serviceAreaId) => createAPIAction({
    types: [
        ActionCreators.VALIDATE_ZIP_CODES.FETCH(),
        ActionCreators.VALIDATE_ZIP_CODES.SUCCESS(),
        ActionCreators.VALIDATE_ZIP_CODES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.VALIDATE_ZIP_CODES(),
    body: {
        zipCodes,
        serviceAreaId,
    },
    method: 'POST',
});
export const removeZipCode = (zipCode) => ({
    type: ActionCreators.REMOVE_ZIP_CODE.SUCCESS.type,
    meta: { zipCode },
});
export const clearZipCodes = () => ({
    type: ActionCreators.CLEAR_ZIP_CODES.SUCCESS.type,
});
export const transferZipCode = (zipCode, newServiceAreaId) => createAPIAction({
    types: [
        ActionCreators.TRANSFER_ZIP_CODE.FETCH(),
        ActionCreators.TRANSFER_ZIP_CODE.SUCCESS(),
        ActionCreators.TRANSFER_ZIP_CODE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.TRANSFER_ZIP_CODES(),
    method: 'POST',
    body: {
        zipCode,
        newServiceAreaId,
    },
});
