import React, { useState } from 'react';
import styled from 'styled-components';
import { TableRow, TableCell, Menu, MenuItem } from 'src/components/mui';
import moment from 'moment-timezone';
import { colors } from 'src/styles/theme';
import MoreIcon from 'src/components/icons/MoreIcon';
import { LOCALIZED_DATE_LONG_FORMAT, TIME_ZONE_FORMAT, } from 'src/timeConstants';
const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  background-color: ${({ $removed }) => $removed ? colors.lightGrey : 'initial'};
  td {
    padding: 15px !important;
  }
`;
const FleetVehicleListItem = ({ id, year, make, model, engineDescription, vin, plateNumber, mileage, nickname, lastServiceDate, fleetResponsibleParties, lastServiceDateTimezone, removed, onAddRequest, onEditVehicle, onRemoveVehicle, fleet, allResponsibleParties, }) => {
    var _a, _b;
    const [showVehicleMenu, setShowVehicleMenu] = useState(null);
    return (<StyledTableRow $removed={removed}>
      <TableCell>
        <div>{year}</div>
      </TableCell>
      <TableCell>{make}</TableCell>
      <TableCell>{model}</TableCell>
      <TableCell>{engineDescription}</TableCell>
      <TableCell>{vin}</TableCell>
      <TableCell>{plateNumber}</TableCell>
      <TableCell>{mileage}</TableCell>
      <TableCell>{nickname}</TableCell>
      <TableCell>
        {lastServiceDate && ((_b = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.consumer) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.timezone)
            ? moment(lastServiceDate)
                .tz(lastServiceDateTimezone !== null && lastServiceDateTimezone !== void 0 ? lastServiceDateTimezone : fleet.consumer.address.timezone)
                .format(`${LOCALIZED_DATE_LONG_FORMAT} ${TIME_ZONE_FORMAT}`)
            : '-'}
      </TableCell>
      <TableCell>
        {(fleetResponsibleParties === null || fleetResponsibleParties === void 0 ? void 0 : fleetResponsibleParties.length) &&
            (fleetResponsibleParties === null || fleetResponsibleParties === void 0 ? void 0 : fleetResponsibleParties.flatMap((item) => {
                var _a, _b;
                return (_b = (_a = allResponsibleParties === null || allResponsibleParties === void 0 ? void 0 : allResponsibleParties.find((rp) => rp.id === item.responsiblePartyId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : [];
            }).join(', '))}
      </TableCell>
      <TableCell>
        <>
          <span role="button" tabIndex={0} onKeyDown={() => { }} onClick={(event) => setShowVehicleMenu({ anchor: event.currentTarget })}>
            <MoreIcon />
          </span>
          <Menu id={`menu-${id}`} anchorEl={showVehicleMenu && showVehicleMenu.anchor} open={showVehicleMenu !== null} onClose={() => setShowVehicleMenu(null)}>
            <MenuItem disabled={removed} onClick={() => {
            setShowVehicleMenu(null);
            onAddRequest(id);
        }}>
              Add Request
            </MenuItem>
            <MenuItem disabled={removed} onClick={() => {
            setShowVehicleMenu(null);
            onEditVehicle(id);
        }}>
              Edit Vehicle
            </MenuItem>
            <MenuItem disabled={removed} onClick={() => {
            setShowVehicleMenu(null);
            onRemoveVehicle(id);
        }}>
              Archive Vehicle
            </MenuItem>
          </Menu>
        </>
      </TableCell>
    </StyledTableRow>);
};
export default FleetVehicleListItem;
