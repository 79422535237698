import styled from 'styled-components';
import { IconButton, Snackbar as MuiSnackbar } from 'src/components/mui';
import { Refresh } from 'src/components/mui/icons';
import { colors, theme } from 'src/styles/theme';
export const Snackbar = styled(MuiSnackbar) `
  ${theme.breakpoints.up('sm')} {
    top: ${({ isScrollOnTop }) => (isScrollOnTop ? '30px' : '10px')};
  }
`;
export const SnackbarMessage = styled.div `
  padding: 0 16px;
`;
export const ActionButton = styled(IconButton) `
  color: ${colors.white};
`;
export const ActionText = styled.div `
  font-size: 12px;
`;
export const RefreshIcon = styled(Refresh) `
  color: ${colors.white};
  height: 13px;
`;
