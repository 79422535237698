import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Autocomplete } from 'src/components/mui';
import useDebounce from 'src/modules/api/utils/useDebounce';
const SkeduloRegionsSearch = ({ value, getSkeduloRegions, skeduloRegionsSelector, onUpdate, disabled, name, label, }) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState(value);
    const debouncedInputValue = useDebounce(inputValue, 500);
    const options = useSelector(skeduloRegionsSelector);
    useEffect(() => {
        dispatch(getSkeduloRegions({ name: debouncedInputValue }));
    }, [debouncedInputValue]); // eslint-disable-line react-hooks/exhaustive-deps
    const onSubmit = (value) => {
        onUpdate(value);
    };
    return (<Autocomplete value={value} style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option) => option.uid === value.uid} options={options} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }} onChange={(e, value) => {
            onSubmit(value);
        }} disabled={disabled} renderInput={(params) => (<TextField {...params} name={name} label={label} type="text" variant="standard" fullWidth InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default SkeduloRegionsSearch;
