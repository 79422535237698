import styled from 'styled-components';
import { Button, Typography } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
export const Container = styled.div `
  padding: 16px;

  ${theme.breakpoints.up('md')} {
    padding: 32px;
  }
`;
export const Header = styled(Typography) `
  margin-bottom: 8px;
`;
export const Subheader = styled(Typography) `
  margin-bottom: 24px;
`;
export const VisitItem = styled.div `
  border-top: solid 1px ${colors.GRAY_DE};
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const LoaderWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px;
  width: 100%;
`;
export const SelectButton = styled(Button) `
  padding: 8px 16px;
`;
export const LaborCapacityWrapper = styled.section `
  > section {
    margin: 17px 0 0;
    border: 0;
    background: transparent;
    padding: 0;
  }

  .capacityHeader {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.12px;
    color: ${colors.GRAY_3F};
  }

  ul {
    margin-top: 8px;

    li {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: -0.12px;
      color: ${colors.GRAY_76};
      float: none;

      &:not(:first-child) {
        margin-top: 4px;
      }
    }
  }

  ${theme.breakpoints.up('md')} {
    .capacityHeader {
      border-right: 0;

      &::after {
        content: ' • ';
        position: relative;
        left: 4px;
      }
    }
    ul {
      margin-top: 0;

      li {
        float: left;

        &:not(:first-child) {
          margin-top: 0;
          margin-left: 24px;
        }
      }
    }
  }
`;
