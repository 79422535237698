import React from 'react';
import classNames from 'classnames';
import { CreateRounded as CreateRoundedIcon, MoreVert as MoreVertIcon, FileCopyOutlined as FileCopyOutlinedIcon, ArrowRightAltRounded as ArrowRightAltRoundedIcon, DeleteForeverRounded as DeleteForeverRoundedIcon, } from 'src/components/mui/icons';
import { IconButton, MenuItem, ListItemIcon, ListItemText, Button, } from 'src/components/mui';
import ArchiveConfirmationDialog from 'src/PartnersApp/pages/PartnerManagement/PartnersTable/ArchiveConfirmationDialog';
import moment from 'moment';
import { capitalize } from 'lodash';
import { DATE_TIME_12H_FORMAT } from 'src/timeConstants';
import { EditButton, PartnersTD, PartnersTR, PublishButton, RowMenu, } from '../PartnersTableRow.styles';
import usePartnersTableRow from './usePartnersTableRow';
const PartnersTableRow = ({ partner, onFunnelEdit, onPartnerEdit, switchActive, }) => {
    var _a, _b, _c;
    const { open, anchorEl, firstFunnel, showEditFunnelButton, lastModifiedDate, showArchiveConfirmation, setShowArchiveConfirmation, handleCloseMenu, handleClickMenuOpen, handleClone, handleUnpublish, handleArchive, handleArchiveAction, handleView, } = usePartnersTableRow({
        partner,
        onFunnelEdit,
        switchActive,
    });
    return (<>
      <PartnersTR>
        <PartnersTD>
          <Button variant="text" onClick={handleView} className="funnelName">
            {partner.name}
          </Button>
        </PartnersTD>
        <PartnersTD>
          {(firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.original) ? 'System' : firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.createdByName}
        </PartnersTD>
        <PartnersTD>
          {(firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.original)
            ? 'System'
            : moment(lastModifiedDate).format(DATE_TIME_12H_FORMAT)}
        </PartnersTD>
        <PartnersTD align="center">
          {showEditFunnelButton && (<EditButton variant="text" startIcon={<CreateRoundedIcon />} onClick={() => onFunnelEdit(partner)}>
              <span className="text">Edit Funnel</span>
            </EditButton>)}
        </PartnersTD>
        <PartnersTD>
          <span className={classNames('funnelStatus', {
            'funnelStatus--draft': ((_a = partner.funnels[0]) === null || _a === void 0 ? void 0 : _a.status) === 'DRAFT',
        })}>
            {capitalize((_b = partner.funnels[0]) === null || _b === void 0 ? void 0 : _b.status)}
          </span>
        </PartnersTD>
        <PartnersTD align="center">
          {partner.allowPublish && (<PublishButton variant="contained" color="primary" onClick={() => onFunnelEdit(partner)}>
              Publish
            </PublishButton>)}
        </PartnersTD>
        <PartnersTD align="center">
          {!((_c = partner.funnels[0]) === null || _c === void 0 ? void 0 : _c.original) && (<EditButton variant="text" startIcon={<CreateRoundedIcon />} onClick={() => {
                onPartnerEdit(partner);
            }}>
              <span className="text">Edit Details</span>
            </EditButton>)}
        </PartnersTD>
        <PartnersTD align="center">
          {!(firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.original) && (<>
              <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClickMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <RowMenu open={open} onClose={handleCloseMenu} anchorEl={anchorEl} keepMounted>
                {(firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.status) === 'PUBLISHED' && (<MenuItem onClick={handleUnpublish} className="RowMenu">
                    <ListItemIcon>
                      <ArrowRightAltRoundedIcon className="unpublish"/>
                    </ListItemIcon>
                    <ListItemText primary="Unpublish"/>
                  </MenuItem>)}
                <MenuItem onClick={handleClone}>
                  <ListItemIcon>
                    <FileCopyOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Clone Partner"/>
                </MenuItem>
                {(firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.status) !== 'PUBLISHED' &&
                (firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.status) !== 'ARCHIVED' && (<MenuItem onClick={handleArchive}>
                      <ListItemIcon>
                        <DeleteForeverRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Archive Partner"/>
                    </MenuItem>)}
              </RowMenu>
            </>)}
        </PartnersTD>
      </PartnersTR>

      <ArchiveConfirmationDialog open={showArchiveConfirmation} onClose={() => {
            setShowArchiveConfirmation(false);
        }} onArchiveAction={handleArchiveAction}/>
    </>);
};
export default PartnersTableRow;
