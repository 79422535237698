import React from 'react';
import { Grid } from 'src/components/mui';
import ShopAddForm from 'src/AdminApp/containers/shops/ShopAddForm';
import PageTitle from 'src/containers/layout/PageTitle';
// This is a temporary fix for the shop add form
// TODO: Fix this
// ShopAddForm should be rewritten to functional component. Currently it is a
// class component and is not compatible with latest typescript
const ShopAddFormUntyped = ShopAddForm;
const ShopsAddPage = () => {
    return (<div>
      <PageTitle slug="Add Shop"/>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ShopAddFormUntyped />
        </Grid>
      </Grid>
    </div>);
};
export default ShopsAddPage;
