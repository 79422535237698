import CONFIG from 'src/config';
import { useSelector } from 'react-redux';
import { selectAccessToken } from 'src/modules/auth/selectors';
export const { KAFKA_CONNECT_API_URL } = CONFIG;
const CONNECTORS_URL = '/kafka/connectors';
export const useConnectApiClient = () => {
    const accessToken = useSelector(selectAccessToken);
    const doGet = (path) => {
        return fetch(CONNECTORS_URL + path, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (!response.ok)
                throw new Error(`${response.status} - ${response.statusText}`);
            return response.json();
        });
    };
    const doPost = (path, body) => {
        return fetch(CONNECTORS_URL + path, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
    };
    const doPut = (path, body) => {
        return fetch(CONNECTORS_URL + path, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
    };
    const doDelete = (path) => {
        return fetch(CONNECTORS_URL + path, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    };
    const fetchConnectors = () => doGet('/');
    const fetchConnectorInfo = (connector) => doGet(`/${connector}`);
    const fetchConnectorConfig = (connector) => doGet(`/${connector}/config`);
    const fetchConnectorStatus = (connector) => doGet(`/${connector}/status`);
    const pauseConnector = (connector) => doPut(`/${connector}/pause`);
    const resumeConnector = (connector) => doPut(`/${connector}/resume`);
    const restartConnector = (connector) => doPost(`/${connector}/restart`);
    const restartConnectorTask = (connector, taskId) => doPost(`/${connector}/tasks/${taskId}/restart`);
    const patchConnector = (connector, config) => doPut(`/${connector}/config`, config);
    const deleteConnector = (connector) => doDelete(`/${connector}`);
    return {
        fetchConnectors,
        fetchConnectorInfo,
        fetchConnectorConfig,
        fetchConnectorStatus,
        pauseConnector,
        resumeConnector,
        restartConnector,
        restartConnectorTask,
        patchConnector,
        deleteConnector,
    };
};
