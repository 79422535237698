import createAPIAction from 'src/modules/api';
import createDefaultActionCreators, { createActionCreators, } from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
// FSA ACTIONS
export const ActionCreators = {
    DELIVERY_VANS: createDefaultActionCreators('DELIVERY_VANS', ['CLEAR']),
    DELIVERY_VAN: createDefaultActionCreators('DELIVERY_VAN'),
    DELIVERY_VAN_BY_ISP: createDefaultActionCreators('DELIVERY_VAN_BY_ISP'),
    DELIVERY_VANS_AUTOCOMPLETE: createDefaultActionCreators('DELIVERY_VANS_AUTOCOMPLETE'),
    ADD_DELIVERY_VAN: createDefaultActionCreators('ADD_DELIVERY_VAN'),
    UPDATE_DELIVERY_VAN: createDefaultActionCreators('UPDATE_DELIVERY_VAN'),
    UPDATE_DELIVERY_VAN_HOME_STORES: createDefaultActionCreators('UPDATE_DELIVERY_VAN_HOME_STORES'),
    UPDATE_SERVICE_AREA_HOME_STORES: createDefaultActionCreators('UPDATE_SERVICE_AREA_HOME_STORES'),
    SLIDE_PANEL_DELIVERY_VAN: createActionCreators(['OPEN', 'CLOSE'], 'SLIDE_PANEL_DELIVERY_VAN'),
    SLIDE_PANEL_DELIVERY_VAN_HOME_STORES: createActionCreators(['OPEN', 'CLOSE'], 'SLIDE_PANEL_DELIVERY_VAN_HOME_STORES'),
};
export const getDeliveryVanById = (deliveryVanId) => createAPIAction({
    types: [
        ActionCreators.DELIVERY_VAN_BY_ISP.FETCH(),
        ActionCreators.DELIVERY_VAN_BY_ISP.SUCCESS(),
        ActionCreators.DELIVERY_VAN_BY_ISP.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VAN_BY_ID(deliveryVanId),
    method: 'GET',
});
export const getDeliveryVanByIspId = (ispId) => createAPIAction({
    types: [
        ActionCreators.DELIVERY_VAN_BY_ISP.FETCH(),
        ActionCreators.DELIVERY_VAN_BY_ISP.SUCCESS(),
        ActionCreators.DELIVERY_VAN_BY_ISP.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VAN_BY_ISP_ID(ispId),
    method: 'GET',
});
export const getDeliveryVansAutocomplete = (name, territoryIds, metroIds, regionIds, serviceAreas, zipCodes) => {
    const filters = {
        name: name !== null && name !== void 0 ? name : '',
        territoryIds: territoryIds !== null && territoryIds !== void 0 ? territoryIds : [],
        metroIds: metroIds !== null && metroIds !== void 0 ? metroIds : [],
        regionIds: regionIds !== null && regionIds !== void 0 ? regionIds : [],
        serviceAreas: serviceAreas !== null && serviceAreas !== void 0 ? serviceAreas : [],
        zipCodes: zipCodes !== null && zipCodes !== void 0 ? zipCodes : [],
    };
    return createAPIAction({
        types: [
            ActionCreators.DELIVERY_VANS_AUTOCOMPLETE.FETCH(),
            ActionCreators.DELIVERY_VANS_AUTOCOMPLETE.SUCCESS(),
            ActionCreators.DELIVERY_VANS_AUTOCOMPLETE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DELIVERY_VANS_AUTOCOMPLETE(filters),
        method: 'GET',
    });
};
export const addDeliveryVan = (value) => createAPIAction({
    types: [
        ActionCreators.ADD_DELIVERY_VAN.FETCH(),
        ActionCreators.ADD_DELIVERY_VAN.SUCCESS(),
        ActionCreators.ADD_DELIVERY_VAN.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VANS(),
    body: {
        regionId: value.regionId || null,
        name: value.name || null,
        type: value.type || null,
        ispId: value.ispId || null,
        skeduloResourceId: value.skeduloResourceId || null,
        confirmedRepairRequests: value.confirmedRepairRequests || null,
        namedAfter: value.namedAfter || null,
        vehicleNumber: value.vehicleNumber || null,
        vin: value.vin || null,
        launchDate: value.launchDate || null,
        skills: value.skills,
        deliveryVanServiceAreas: value.deliveryVanServiceAreas,
    },
    method: 'POST',
});
export const updateDeliveryVan = (value) => createAPIAction({
    types: [
        ActionCreators.UPDATE_DELIVERY_VAN.FETCH(),
        ActionCreators.UPDATE_DELIVERY_VAN.SUCCESS({
            meta: () => ({ id: value.id }),
        }),
        ActionCreators.UPDATE_DELIVERY_VAN.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VAN_BY_ID(value.id),
    method: 'PUT',
    body: {
        regionId: value.regionId || null,
        name: value.name || null,
        type: value.type || null,
        ispId: value.ispId || null,
        skeduloResourceId: value.skeduloResourceId || null,
        confirmedRepairRequests: value.confirmedRepairRequests,
        namedAfter: value.namedAfter,
        vehicleNumber: value.vehicleNumber || null,
        vin: value.vin || null,
        launchDate: value.launchDate || null,
        skills: value.skills,
        deliveryVanServiceAreas: value.deliveryVanServiceAreas,
    },
});
export const updateDeliveryVanHomeStores = (deliveryVanId, homeStores) => createAPIAction({
    types: [
        ActionCreators.UPDATE_DELIVERY_VAN_HOME_STORES.FETCH(),
        ActionCreators.UPDATE_DELIVERY_VAN_HOME_STORES.SUCCESS({
            meta: () => ({ id: deliveryVanId }),
        }),
        ActionCreators.UPDATE_DELIVERY_VAN_HOME_STORES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VAN_HOME_STORES_BY_ID(deliveryVanId),
    method: 'PUT',
    body: homeStores,
});
export const updateDeliveryVanServiceAreaHomestores = (serviceAreaId, deliveryVanId) => createAPIAction({
    types: [
        ActionCreators.UPDATE_SERVICE_AREA_HOME_STORES.FETCH(),
        ActionCreators.UPDATE_SERVICE_AREA_HOME_STORES.SUCCESS({
            meta: () => ({ id: serviceAreaId }),
        }),
        ActionCreators.UPDATE_SERVICE_AREA_HOME_STORES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.UPDATE_SERVICE_AREA_HOME_STORES(serviceAreaId),
    method: 'PUT',
    body: Array.isArray(deliveryVanId) ? deliveryVanId : [deliveryVanId],
});
export const openSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN.OPEN.type,
});
export const closeSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN.CLOSE.type,
});
export const openHomeStoresSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN_HOME_STORES.OPEN.type,
});
export const closeHomeStoresSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN_HOME_STORES.CLOSE.type,
});
