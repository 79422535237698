import moment from 'moment';
import { DATE_YMD_FORMAT, DATE_TIME_FORMAT } from 'src/timeConstants';
export const fetching = (data) => (Object.assign(Object.assign({}, data), { _isFetching: true }));
export const fetched = (data) => (Object.assign(Object.assign({}, data), { _fetched: new Date(), _isFetching: false }));
export const isExpired = (data, ttlMs = 60000) => {
    if (!data || !data._fetched)
        return true;
    const diffTime = new Date().getTime() - data._fetched.getTime();
    return diffTime > ttlMs;
};
export const dateToUTC = (date) => moment(date).utc().format(DATE_YMD_FORMAT);
export function makeJSDateObject(date) {
    if (moment.isMoment(date)) {
        return date.clone().toDate();
    }
    if (date instanceof Date) {
        return new Date(date.getTime());
    }
    throw new Error('Cannot properly parse argument passed to cloneCrossUtils');
}
export const createStartEndWeekFromDates = (date) => {
    const jsDate = makeJSDateObject(date);
    const start = moment(jsDate).startOf('week');
    const end = moment(jsDate).endOf('week');
    return { start, end };
};
export const isUrl = (testStr) => {
    if (!testStr)
        return false;
    const expression = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&/=]*)?/gi;
    const regex = new RegExp(expression);
    return testStr.match(regex);
};
// check if the value is a long timestamp and greater than 'Sat Jan 01 2000 00:00:00 GMT+0000'
export const isDate = (value) => !!value && !isNaN(value) && value > 946684800000;
export const isFullAddress = (address) => {
    var _a;
    if (!address.zip || !address.state || !address.city) {
        return false;
    }
    const streetAddRegEx = /(\d{1,}) [a-zA-Z0-9\s]+(\.)?(,)?/;
    return (streetAddRegEx.test(address.street) ||
        streetAddRegEx.test((_a = address.streetLine1) !== null && _a !== void 0 ? _a : ''));
};
export const parseStringNumber = (value) => {
    return typeof value === 'string'
        ? parseInt(value.replace(/[.,\s]/g, ''), 10)
        : value;
};
export const splitAtFirstComma = (input) => {
    const commaIndex = input.indexOf(',');
    return commaIndex === -1
        ? [input, '']
        : [input.substring(0, commaIndex), input.substring(commaIndex + 1)];
};
export const splitAndTrimOn = (string, delimiter) => {
    return (string !== null && string !== void 0 ? string : '').split(delimiter).reduce((acc, cur) => {
        const trimmed = cur.trim();
        if (trimmed) {
            acc.push(trimmed);
        }
        return acc;
    }, []);
};
export const formatTime = (time) => {
    if (!time) {
        return undefined;
    }
    const date = moment().format(DATE_YMD_FORMAT);
    const dateTime = `${date}T${time}`;
    const formattedDateTime = moment(dateTime).format(DATE_TIME_FORMAT);
    return formattedDateTime;
};
