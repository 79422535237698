import React from 'react';
import { useParams } from 'react-router';
import { Typography, Button, Grid } from 'src/components/mui';
import PaymentDrawer from 'src/AdminApp/containers/payments/PaymentDrawer';
import ResponsiblePartyPayment from 'src/AdminApp/containers/payments/PaymentModule/ResponsiblePartyPayment';
import { CheckIcon, WarningIcon, } from 'src/AdminApp/containers/payments/PaymentModule/PaymentsTable/styledComponents';
import PaymentsTable from 'src/AdminApp/containers/payments/PaymentModule/PaymentsTable';
import CurrencyLabel from 'src/components/CurrencyLabel';
import RemovePaymentModal from '../RemovePaymentModal';
import SavedPaymentMethods from '../SavedPaymentMethods';
import { AddButtonsWrapper } from '../styledComponents.styles';
import { TotalSection, PaymentsRow, ColoredMoneyLabel } from './Payment.styles';
import usePayment from './usePayment';
const Payment = ({ canEdit = false, consumerContactInfo, workOrderId, }) => {
    const { referenceNum } = useParams();
    const { primaryInvoice, invoices, responsibleParties, responsiblePartiesOfInvoice, workOrderResponsibleParties, payments, workOrderPayments, addPaymentOpen, removePaymentOpen, isRefund, removePaymentAmount, removePaymentMethodOpen, removePaymentMethodName, refundReason, setRefundReason, paymentsReceivedTotal, paymentsBalanceOwed, totalPrice, resetAddPayment, onRemovePayment, hasResponsibleParties, handleRemovePaymentMethod, handleRemovePayment, onRemovePaymentMethod, getPaidStatus, owedStatus, recalculateTax, handleAddPaymentOpen, handleRemovePaymentOpen, handleRemovePaymentMethodOpen, } = usePayment({ referenceNum, workOrderId, consumerContactInfo });
    const removePaymentModalTitle = isRefund
        ? 'Refund Payment'
        : 'Delete Payment';
    return (<>
      <Typography variant="h5" style={{ marginBottom: 24 }}>
        Payment
      </Typography>

      {invoices.length > 1 &&
            invoices.map((invoice) => (<ResponsiblePartyPayment key={invoice.id} invoice={invoice} onRemovePayment={handleRemovePayment} recalculateTax={recalculateTax}/>))}

      <TotalSection invoicesCount={invoices.length}>
        <PaymentsRow container style={{ justifyContent: 'space-between' }}>
          <Grid item>Total Balance Owed:</Grid>
          <Grid item>
            <ColoredMoneyLabel status={owedStatus}>
              {paymentsBalanceOwed === null || recalculateTax ? ('Not Calculated') : (<>
                  {paymentsBalanceOwed === 0 ? null : <WarningIcon />}
                  <CurrencyLabel>{paymentsBalanceOwed || 0}</CurrencyLabel>
                </>)}
            </ColoredMoneyLabel>
          </Grid>
        </PaymentsRow>
        <PaymentsRow container style={{ justifyContent: 'space-between' }}>
          <Grid item>Total Payments Received:</Grid>
          <Grid item>
            <ColoredMoneyLabel status={getPaidStatus(paymentsBalanceOwed)}>
              {paymentsBalanceOwed === 0 ? <CheckIcon /> : null}
              <CurrencyLabel>{paymentsReceivedTotal || 0}</CurrencyLabel>
            </ColoredMoneyLabel>
          </Grid>
        </PaymentsRow>
      </TotalSection>

      {invoices.length < 2 && (<PaymentsTable payments={payments} onRemovePayment={handleRemovePayment}/>)}

      {canEdit && (<AddButtonsWrapper>
          <Button color="primary" disabled={!!(primaryInvoice === null || primaryInvoice === void 0 ? void 0 : primaryInvoice.totalPriceOverride) || !hasResponsibleParties} variant="contained" onClick={handleAddPaymentOpen(true)}>
            Add Payment
          </Button>
        </AddButtonsWrapper>)}

      <SavedPaymentMethods onRemovePaymentMethod={handleRemovePaymentMethod}/>
      <PaymentDrawer open={addPaymentOpen} closeDrawer={resetAddPayment} totalInvoicePrice={totalPrice} responsibleParties={responsibleParties.length > 0
            ? responsibleParties
            : responsiblePartiesOfInvoice} workOrderId={workOrderId} workOrderPayments={workOrderPayments} workOrderResponsibleParties={workOrderResponsibleParties}/>
      <RemovePaymentModal acceptBtnLabel={removePaymentModalTitle} content={isRefund
            ? 'This will trigger a refund to the original payment method'
            : 'This will not impact any in-process transactions or issue a refund.'} cancelBtnLabel="Keep" onClose={handleRemovePaymentOpen(false)} onSubmit={onRemovePayment} open={removePaymentOpen} title={`${removePaymentModalTitle} for ${removePaymentAmount}?`} isRefund={isRefund} refundReason={refundReason} onRefundReason={setRefundReason}/>
      <RemovePaymentModal acceptBtnLabel="Delete Payment Method" cancelBtnLabel="Keep" onClose={handleRemovePaymentMethodOpen(false)} onSubmit={onRemovePaymentMethod} open={removePaymentMethodOpen} title={`Delete payment method "${removePaymentMethodName}"?`}/>
    </>);
};
export default Payment;
