var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { selectDashboardPagination } from 'src/AdminApp/modules/shops/selectors';
import { selectAuthorizedShopEdit } from 'src/modules/auth/selectors';
import { getShops as getShopsAction, updateShop as updateShopAction, } from 'src/AdminApp/modules/shops/actions';
const useShopsListItem = ({ id, shopScore }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [editShopScore, setEditShopScore] = useState(false);
    const pagination = useSelector(selectDashboardPagination);
    const canEdit = useSelector(selectAuthorizedShopEdit);
    const showMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };
    const updateAndReloadShop = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { pageSize, pageNumber, sort } = pagination;
        const result = yield dispatch(updateShopAction(Object.assign({ id }, values)));
        closeMenu();
        if (!result.error) {
            toast.success('Shop successfully updated.');
            dispatch(getShopsAction({
                pageSize,
                pageIndex: pageNumber,
                sort: sort,
            }));
        }
        return result;
    });
    const submitUpdate = (values) => {
        updateAndReloadShop(values);
    };
    const internalEditShopScore = () => {
        setEditShopScore(true);
        closeMenu();
    };
    const handleSubmitScore = (values, { setSubmitting, resetForm }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const submittedScore = (_a = values === null || values === void 0 ? void 0 : values.shopScore) !== null && _a !== void 0 ? _a : 0;
        const splitByDecimals = submittedScore.toString().split('.');
        let numberOfDecimals = 0;
        if (splitByDecimals.length > 1) {
            numberOfDecimals = splitByDecimals[1].length;
        }
        if (!!submittedScore &&
            (submittedScore < 1 || submittedScore > 5 || numberOfDecimals > 1)) {
            toast.error('Sorry, score must be between 1 - 5 with a maximum of one decimal place!');
            setSubmitting(false);
        }
        else {
            yield updateAndReloadShop({ id, shopScore: values.shopScore });
            resetForm({ values: { shopScore } });
            setSubmitting(false);
            setEditShopScore(false);
        }
    });
    return {
        dispatch,
        anchorEl,
        setAnchorEl,
        editShopScore,
        setEditShopScore,
        pagination,
        canEdit,
        showMenu,
        closeMenu,
        updateAndReloadShop,
        submitUpdate,
        internalEditShopScore,
        handleSubmitScore,
    };
};
export default useShopsListItem;
