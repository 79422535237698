var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Error as ErrorIcon } from 'src/components/mui/icons';
import React, { useEffect, useState } from 'react';
import { getEntry } from 'src/clients/contentfulClient';
import { SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import Config from 'src/config';
import { ErrorIconStyle, IntroContent, IntroCopy, IntroHeader, } from '../AuthorizationScript.styles';
const AuthorizationConfirmCancel = ({ backStep, onCancelReschedule, }) => {
    const [subtext, setSubtext] = useState('');
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const response = yield getEntry(Config.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_CANCEL_SUBMIT_SUBTEXT);
            setSubtext((_a = response === null || response === void 0 ? void 0 : response.fields) === null || _a === void 0 ? void 0 : _a.script);
        }))();
    }, []);
    return (<>
      <SimpleDrawerContent>
        <IntroContent>
          <ErrorIcon style={ErrorIconStyle} fontSize="large"/>
          <IntroHeader>
            Are you sure the customer wants to cancel their appointment today?{' '}
          </IntroHeader>
        </IntroContent>

        <IntroCopy>{subtext}</IntroCopy>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <SimpleTwoButtonRow submitIsDestructive submitText="Cancel/Reschedule Appointment" onSubmit={onCancelReschedule} onCancel={backStep} cancelText="Back to Payment Authorization"/>
      </SimpleDrawerFooter>
    </>);
};
export default AuthorizationConfirmCancel;
