import React from 'react';
import { Grid, Table, TableHead, TableCell, TableBody, TableRow, } from 'src/components/mui';
import WindowShiftTableItem from './WindowShiftTableItem';
const WindowShiftTable = ({ data, onRemove, onSave, disabled = false, shiftWindowHasOverlap, }) => {
    return (<Grid item xs={12}>
      <Table>
        <TableHead>
          <TableCell>Window Start</TableCell>
          <TableCell>Window End</TableCell>
          {disabled ? null : (<>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </>)}
        </TableHead>
        <TableBody>
          {data === null || data === void 0 ? void 0 : data.map((row, index) => (<TableRow key={index}>
              <WindowShiftTableItem item={row} onRowRemove={onRemove} onRowSave={onSave} shiftWindowHasOverlap={shiftWindowHasOverlap}/>
            </TableRow>))}
        </TableBody>
      </Table>
    </Grid>);
};
export default WindowShiftTable;
