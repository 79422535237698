import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from '../api/urls';
export const ActionCreators = {
    UPLOAD_PRICING_RULES_CSV: createDefaultActionCreators('UPLOAD_PRICING_RULES_CSV'),
    UPLOAD_DYNAMIC_COMPONENT_RULES: createDefaultActionCreators('UPLOAD_DYNAMIC_COMPONENT_RULES'),
};
const uploadPricingRulesCsv = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return createAPIAction({
        types: fillApiTypes(ActionCreators.UPLOAD_PRICING_RULES_CSV),
        endpoint: API_URLS.UTILS.PRICING_RULES_CSV(),
        method: 'POST',
        formData,
    });
};
const uploadDynamicComponentRulesCsv = (data) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.UPLOAD_DYNAMIC_COMPONENT_RULES),
        endpoint: API_URLS.UTILS.DYNAMIC_COMPONENT_RULES_CSV(),
        method: 'POST',
        body: data,
    });
};
export { uploadPricingRulesCsv, uploadDynamicComponentRulesCsv };
