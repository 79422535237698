import React from 'react';
import { CircularProgress } from 'src/components/mui';
import { JobInfoContainer } from './JobInfo.styles';
const Loader = () => (<JobInfoContainer>
    <div style={{
        display: 'flex',
        justifyContent: 'center',
    }}>
      <CircularProgress size={30} color="primary"/>
    </div>
  </JobInfoContainer>);
export default Loader;
