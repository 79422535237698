import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowDropDown, ArrowDropUp } from 'src/components/mui/icons';
import { selectTerritories, selectSelectedRegions, } from 'src/AdminApp/modules/swimlanes/selectors';
import { without } from 'lodash';
import TerritoryItem from './TerritoryItem';
import RegionSelectorActions from './RegionSelectorActions';
import { RoundedButton, StyledPopover } from './RegionSelector.styles';
const RegionSelector = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [regionIds, setRegionIds] = useState([]);
    const allTerritories = useSelector(selectTerritories);
    const selectedRegions = useSelector(selectSelectedRegions);
    const open = Boolean(anchorEl);
    useEffect(() => {
        setRegionIds([...selectedRegions]);
    }, [selectedRegions]);
    const territories = useMemo(() => allTerritories.filter((t) => t.name !== 'Other'), [allTerritories]);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const getRegionsFromTerritory = (territoryId) => {
        const territory = territories.find((t) => t.id === territoryId);
        if (territory) {
            const regionIds = territory.metros.reduce((result, metro) => {
                const metroRegionIds = metro.regions.map((region) => region.id);
                result.push(...metroRegionIds);
                return result;
            }, []);
            return regionIds;
        }
        return [];
    };
    const getRegionsFromMetro = (territoryId, metroId) => {
        const territory = territories.find((t) => t.id === territoryId);
        if (territory) {
            const metro = territory.metros.find((metro) => metro.id === metroId);
            if (metro) {
                const regionIds = metro.regions.map((region) => region.id);
                return regionIds;
            }
        }
        return [];
    };
    const getRegions = (checked, territoryId, metroId, regionId) => {
        const prevRegions = [...regionIds];
        let regions = [];
        if (regionId) {
            regions = [regionId];
        }
        else if (metroId) {
            regions = getRegionsFromMetro(territoryId, metroId);
        }
        else {
            regions = getRegionsFromTerritory(territoryId);
        }
        if (checked) {
            setRegionIds([...prevRegions, ...regions]);
        }
        else {
            setRegionIds([...without(prevRegions, ...regions)]);
        }
    };
    const checkAllRegionsFromMetroSelected = (metro) => {
        const ids = metro.regions.map((region) => region.id);
        return ids.every((value) => regionIds.includes(value));
    };
    const checkAllMetrosFromTerritorySelected = (territory) => territory.metros.every((metro) => checkAllRegionsFromMetroSelected(metro));
    return (<>
      <RoundedButton variant="outlined" onClick={handleClick} disabled={(territories === null || territories === void 0 ? void 0 : territories.length) === 0}>
        Region {`(${regionIds === null || regionIds === void 0 ? void 0 : regionIds.length})`}{' '}
        {!open ? (<ArrowDropDown htmlColor="#cccccc"/>) : (<ArrowDropUp htmlColor="#cccccc"/>)}
      </RoundedButton>
      <StyledPopover className="region-selector" open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }} transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}>
        <div style={{
            maxHeight: '600px',
            overflowY: 'auto',
        }}>
          {territories.length > 0 &&
            territories.map((t, index) => (<TerritoryItem key={index} territory={t} selectedRegions={regionIds} getRegions={getRegions} checkAllRegionsFromMetroSelected={checkAllRegionsFromMetroSelected} checkAllMetrosFromTerritorySelected={checkAllMetrosFromTerritorySelected}/>))}
        </div>
        <RegionSelectorActions handleClose={handleClose} regionIds={regionIds}/>
      </StyledPopover>
    </>);
};
export default RegionSelector;
