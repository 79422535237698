var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
// TODO: make this more common for components/geoManagement and components/discounts
import { SlidePageContent, SlidePageFooter, SlidePageFormWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const ArchiveServiceAreaPage = ({ model, onArchive, }) => {
    var _a;
    const validationSchema = Yup.object().shape({
        archiveDate: Yup.string().required(),
    });
    return model ? (<Formik initialValues={{
            id: (_a = model.id) !== null && _a !== void 0 ? _a : '',
            archiveDate: null,
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            var _b;
            if (onArchive) {
                yield onArchive({
                    id: model.id,
                    archiveDate: (_b = values.archiveDate) !== null && _b !== void 0 ? _b : '',
                });
            }
            setSubmitting(false);
        })}>
      {({ values, isSubmitting, isValid, setFieldValue }) => (<SlidePageFormWrapper>
          <SlidePageContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>Archive {model.name} ?</h2>
              </Grid>
              <Grid item xs={12}>
                <SimpleDatePicker fullWidth name="archiveDate" label="Archive Date" format={LOCALIZED_DATE_FORMAT} value={values.archiveDate} onChange={(value) => {
                setFieldValue('archiveDate', value);
            }} slotProps={{
                textField: {
                    variant: 'outlined',
                },
            }}/>
              </Grid>
            </Grid>
          </SlidePageContent>
          <SlidePageFooter>
            <FooterActionButton type="submit" disabled={isSubmitting || !isValid}>
              Archive Service Area
            </FooterActionButton>
          </SlidePageFooter>
        </SlidePageFormWrapper>)}
    </Formik>) : null;
};
export default ArchiveServiceAreaPage;
