import React from 'react';
import { CapacitySection } from './LaborCapacityBar.styles';
const LaborCapacityBar = ({ totalAvailableHours, totalScheduledHours, totalRemainingHours, }) => {
    return (<CapacitySection>
      <p className="capacityHeader">Labor Capacity</p>
      <ul>
        <li>
          Total Avail Hrs: <strong>{totalAvailableHours}</strong>
        </li>
        <li>
          Total Scheduled Hrs: <strong>{totalScheduledHours}</strong>
        </li>
        <li>
          Total Remaining Hrs: <strong>{totalRemainingHours}</strong>
        </li>
      </ul>
    </CapacitySection>);
};
export default LaborCapacityBar;
