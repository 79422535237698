var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { Button, CircularProgress, Grid, TextField } from 'src/components/mui';
const BigButton = styled(Button) `
  height: 100%;
`;
const LocationFilters = ({ onSearch, onAdd }) => {
    const [submitting, setSubmitting] = useState(false);
    const [queryString, setQueryString] = useState();
    const handleReset = () => {
        setQueryString('');
    };
    const handleSubmit = (args) => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        let values = {};
        if (queryString === null || queryString === void 0 ? void 0 : queryString.includes('&')) {
            const queryStringParams = queryString.split('&');
            const [street, dealershipName] = queryStringParams;
            values = Object.assign(Object.assign({}, args), { street: street.trim(), dealershipName: dealershipName.trim() });
        }
        else {
            values = Object.assign(Object.assign({}, args), { street: queryString === null || queryString === void 0 ? void 0 : queryString.trim() });
        }
        try {
            yield onSearch(values);
        }
        catch (e) {
            window.console.error(e);
        }
        finally {
            setSubmitting(false);
        }
    });
    return (<Formik enableReinitialize initialValues={{ query: '' }} onSubmit={handleSubmit}>
      {() => (<Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField autoFocus fullWidth disabled={submitting} label="Search Query" name="query" placeholder="i.e. My street&My dealer name" variant="outlined" value={queryString} onChange={(event) => setQueryString(event.target.value)}/>
            </Grid>
            <Grid item xs={4} md={2}>
              <BigButton fullWidth disabled={submitting} size="small" variant="outlined" onClick={handleReset}>
                Reset Form
              </BigButton>
            </Grid>
            <Grid item xs={4} md={2}>
              <BigButton fullWidth color="primary" disabled={submitting} size="small" type="submit" variant="contained">
                {!submitting ? 'Search' : <CircularProgress size="1.75rem"/>}
              </BigButton>
            </Grid>
            <Grid item xs={4} md={2}>
              <BigButton fullWidth color="primary" size="small" type="submit" variant="contained" onClick={onAdd}>
                Add New
              </BigButton>
            </Grid>
          </Grid>
        </Form>)}
    </Formik>);
};
export default LocationFilters;
