var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Grid } from 'src/components/mui';
import UnlockReasons from 'src/AdminApp/models/enums/UnlockReasons';
import { DrawerFormRow, FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
const UNLOCK_REASONS = Object.keys(UnlockReasons);
const RequestUnlockReasons = ({ open, onSubmit, onCancel, }) => {
    const [loading, setLoading] = useState(false);
    const [selectedReasons, setSelectedReasons] = useState([]);
    useEffect(() => {
        if (!open)
            return;
        setSelectedReasons([]);
    }, [open]);
    const isSelected = (reasonKey) => {
        return selectedReasons.includes(getReason(reasonKey));
    };
    const handleSelect = (reasonKey, checked) => {
        const reason = getReason(reasonKey);
        const reasonIndex = selectedReasons.findIndex((_reason) => _reason === getReason(reasonKey));
        if (checked && reasonIndex === -1) {
            selectedReasons.push(reason);
        }
        else {
            selectedReasons.splice(reasonIndex, 1);
        }
        setSelectedReasons([...selectedReasons]);
    };
    const getReason = (reasonKey) => {
        return UnlockReasons[reasonKey];
    };
    const isValid = () => {
        return selectedReasons.length > 0;
    };
    if (!open) {
        return null;
    }
    return (<SimpleDrawer open={open} title="Re-Opening Reasons" onClose={onCancel} isLoading={loading}>
      <Formik onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            if (!isValid) {
                setSubmitting(false);
                return;
            }
            setLoading(true);
            setSubmitting(true);
            yield onSubmit(selectedReasons);
            setSubmitting(false);
            setLoading(false);
            setSelectedReasons([]);
        })} initialValues={() => { }}>
        {({ handleSubmit, isSubmitting }) => (<>
            <SimpleDrawerContent>
              <Grid container>
                {UNLOCK_REASONS.map((reason) => (<DrawerFormRow key={reason} item xs={12}>
                    <LabelledCheckbox label={UnlockReasons[reason]} checkboxProps={{
                    checked: isSelected(reason),
                    onChange: (e) => {
                        handleSelect(reason, e.target.checked);
                    },
                    name: reason,
                }}/>
                  </DrawerFormRow>))}
              </Grid>
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <FooterActionButton disabled={!isValid()} onClick={handleSubmit} isSubmitting={isSubmitting}>
                Re-open RO
              </FooterActionButton>
            </SimpleDrawerFooter>
          </>)}
      </Formik>
    </SimpleDrawer>);
};
export default RequestUnlockReasons;
