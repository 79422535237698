var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { selectUserId } from 'src/modules/auth/selectors';
import { selectCurrentUserIsPorter } from 'src/AdminApp/modules/users/selectors';
import { selectPorterDashboard, selectPorterById, } from 'src/TechApp/modules/dashboard/selectors';
import { getPorterDashboardById, getPorterById, } from 'src/TechApp/modules/dashboard/actions';
import { formatMomentDate } from 'src/utils/formatter';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
const usePorterDashboard = ({ porterId }) => {
    const dispatch = useDispatch();
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const queryParams = new URLSearchParams(window.location.search);
    const dateParam = queryParams.has('date')
        ? moment(queryParams.get('date'))
        : moment();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentDateSlider, setCurrentDateSlider] = useState(dateParam);
    const [formattedDate, setFormattedDate] = useState();
    const [calendarDate, setCalendarDate] = useState(dateParam);
    const userId = useSelector(selectUserId);
    const porter = useSelector(selectPorterById(porterId));
    const userIsPorter = useSelector(selectCurrentUserIsPorter);
    const dashboard = useSelector(selectPorterDashboard);
    useEffect(() => {
        setIsLoading(true);
        if (currentDateSlider !== null) {
            const selectedDate = formatMomentDate(currentDateSlider, DATE_YMD_FORMAT);
            setCalendarDate(currentDateSlider);
            setFormattedDate(selectedDate);
            (() => __awaiter(void 0, void 0, void 0, function* () {
                const porterInfoResult = yield dispatch(getPorterById(porterId, selectedDate));
                if (!porterInfoResult.error &&
                    porterInfoResult.payload &&
                    porterInfoResult.payload.regionIds) {
                    yield dispatch(getPorterDashboardById(porterId, {
                        date: selectedDate,
                        timezone: timeZone,
                        regionIds: porterInfoResult.payload.regionIds,
                    }));
                }
                setIsLoading(false);
            }))();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDateSlider, porterId]);
    return {
        isLoading,
        setIsLoading,
        open,
        setOpen,
        formattedDate,
        calendarDate,
        setCalendarDate,
        userId,
        porter,
        dashboard,
        currentDateSlider,
        setCurrentDateSlider,
        userIsPorter,
    };
};
export default usePorterDashboard;
