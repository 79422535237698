import React from 'react';
import { Slide } from 'src/components/mui';
import styled from 'styled-components';
import { usePrevious } from 'src/components/SimpleDrawerPaged/utils';
const SlideHelper = styled.div `
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: ${({ $isActive }) => ($isActive ? 2 : 1)};
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  margin: auto;

  > div {
    height: var(--helper-height, auto);
  }
`;
const Slider = ({ currentSlide, keepMounted = [], slides }) => {
    const prevScreen = usePrevious(currentSlide);
    const prevIndex = slides.findIndex((route) => route.name === prevScreen);
    const currentIndex = slides.findIndex((route) => route.name === currentSlide);
    const direction = prevIndex < currentIndex ? 'left' : 'right';
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {slides === null || slides === void 0 ? void 0 : slides.map((route) => (<SlideHelper key={route.name} style={route.style} $isActive={route.name === currentSlide}>
          <Slide mountOnEnter direction={direction} in={route.name === currentSlide} unmountOnExit={!keepMounted.includes(route.name)}>
            <div>{route.render()}</div>
          </Slide>
        </SlideHelper>))}
    </>);
};
export default Slider;
