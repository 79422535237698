import createDefaultActionCreators, { createActionCreators, } from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/TechApp/modules/api/urls';
export const ActionCreators = {
    GET_PART_PICKUP_ORDER: createDefaultActionCreators('GET_PART_PICKUP_ORDER'),
    COMPLETE_PART_PICKUP_TASK: createDefaultActionCreators('COMPLETE_PART_PICKUP_TASK'),
    GET_PART_PICKUP_TASK_WORKFLOW: createDefaultActionCreators('GET_PART_PICKUP_TASK_WORKFLOW'),
    UPDATE_PART_PICKUP_TASK: createDefaultActionCreators('UPDATE_PART_PICKUP_TASK'),
    GET_PART_PICKUP_TASK: createDefaultActionCreators('GET_PART_PICKUP_TASK'),
    GET_PART_PICKUP_VAN: createDefaultActionCreators('GET_PART_PICKUP_VAN'),
    SUBMIT_PICKUP_PARTS: createDefaultActionCreators('SUBMIT_PICKUP_PARTS'),
    CANCEL_PART_PICKUP: createDefaultActionCreators('CANCEL_PART_PICKUP'),
    RESCHEDULE_PART_PICKUP: createDefaultActionCreators('RESCHEDULE_PART_PICKUP'),
    TRANSFER_PART_PICKUP: createDefaultActionCreators('TRANSFER_PART_PICKUP'),
    SUBMIT_PART_PICKUP_PHOTO: createDefaultActionCreators('SUBMIT_PART_PICKUP_PHOTO'),
    DELETE_PART_PICKUP_PHOTO: createDefaultActionCreators('DELETE_PART_PICKUP_PHOTO'),
    PART_PICKUP_MAIN_DRAWER: createActionCreators(['OPEN', 'CLOSE'], 'PART_PICKUP_MAIN_DRAWER'),
    PART_PICKUP_CHECKLIST_DRAWER: createActionCreators(['OPEN', 'CLOSE'], 'PART_PICKUP_CHECKLIST_DRAWER'),
    PART_PICKUP_MISSING_PARTS_DRAWER: createActionCreators(['OPEN', 'CLOSE'], 'PART_PICKUP_MISSING_PARTS_DRAWER'),
    PART_PICKUP_HEY_TECH_DRAWER: createActionCreators(['OPEN', 'CLOSE'], 'PART_PICKUP_HEY_TECH_DRAWER'),
    PART_PICKUP_WHATS_NEXT_DRAWER: createActionCreators(['OPEN', 'CLOSE'], 'PART_PICKUP_WHATS_NEXT_DRAWER'),
    PART_PICKUP_RESCHEDULE_DRAWER: createActionCreators(['OPEN', 'CLOSE'], 'PART_PICKUP_RESCHEDULE_DRAWER'),
    UPDATE_PICKEDUP_QUANTITY: createActionCreators(['SUCCESS'], 'UPDATE_PICKEDUP_QUANTITY'),
    CHECK_PICKEDUP_ITEM: createActionCreators(['SUCCESS'], 'CHECK_PICKEDUP_ITEM'),
    PART_PICKUP_TRANSFER_DRAWER: createActionCreators(['OPEN', 'CLOSE'], 'PART_PICKUP_TRANSFER_DRAWER'),
};
export const fetchPartPickupOrder = (partPickupOrderId) => createAPIAction({
    types: [
        ActionCreators.GET_PART_PICKUP_ORDER.FETCH(),
        ActionCreators.GET_PART_PICKUP_ORDER.SUCCESS(),
        ActionCreators.GET_PART_PICKUP_ORDER.FAILURE(),
    ],
    endpoint: API_URLS.PART_PICKUP.PART_PICKUP_ORDER_BY_ID(partPickupOrderId),
    method: 'GET',
});
export const fetchPartPickupOrderByReferenceNum = (referenceNum) => createAPIAction({
    types: [
        ActionCreators.GET_PART_PICKUP_ORDER.FETCH(),
        ActionCreators.GET_PART_PICKUP_ORDER.SUCCESS(),
        ActionCreators.GET_PART_PICKUP_ORDER.FAILURE(),
    ],
    endpoint: API_URLS.PART_PICKUP.PART_PICKUP_ORDER_BY_REFERENCE_NUM(referenceNum),
    method: 'GET',
});
export const getPartPickupTaskWorkflow = (referenceNum) => createAPIAction({
    types: [
        ActionCreators.GET_PART_PICKUP_TASK_WORKFLOW.FETCH(),
        ActionCreators.GET_PART_PICKUP_TASK_WORKFLOW.SUCCESS(),
        ActionCreators.GET_PART_PICKUP_TASK_WORKFLOW.FAILURE(),
    ],
    endpoint: API_URLS.PART_PICKUP.GET_PART_PICKUP_WORKFLOW(referenceNum),
    method: 'GET',
});
export const completePartPickupTask = (referenceNum, taskId, requestBody) => createAPIAction({
    types: [
        ActionCreators.COMPLETE_PART_PICKUP_TASK.FETCH(),
        ActionCreators.COMPLETE_PART_PICKUP_TASK.SUCCESS(),
        ActionCreators.COMPLETE_PART_PICKUP_TASK.FAILURE(),
    ],
    endpoint: API_URLS.PART_PICKUP.PART_PICKUP_TASK({ referenceNum, taskId }),
    method: 'POST',
    body: requestBody,
});
export const updatePickedUpQuantity = ({ id, quantity, }) => ({
    type: ActionCreators.UPDATE_PICKEDUP_QUANTITY.SUCCESS.type,
    payload: { id, quantity },
});
export const checkPickedUpItem = ({ id, checked, }) => ({
    type: ActionCreators.CHECK_PICKEDUP_ITEM.SUCCESS.type,
    payload: { id, checked },
});
export const submitPickedUpParts = (partPickupOrderId, pickedUpParts) => createAPIAction({
    types: [
        ActionCreators.SUBMIT_PICKUP_PARTS.FETCH(),
        ActionCreators.SUBMIT_PICKUP_PARTS.SUCCESS(),
        ActionCreators.SUBMIT_PICKUP_PARTS.FAILURE(),
    ],
    endpoint: API_URLS.PART_PICKUP.PICKUP_PARTS(partPickupOrderId),
    method: 'POST',
    body: { pickedUpParts },
});
export const cancelPartPickupOrder = (partPickupOrderId) => createAPIAction({
    types: [
        ActionCreators.CANCEL_PART_PICKUP.FETCH(),
        ActionCreators.CANCEL_PART_PICKUP.SUCCESS(),
        ActionCreators.CANCEL_PART_PICKUP.FAILURE(),
    ],
    endpoint: API_URLS.PART_PICKUP.CANCEL_PART_PICKUP(partPickupOrderId),
    method: 'POST',
});
export const getPartPickupVan = (partPickupOrderId) => createAPIAction({
    types: [
        ActionCreators.GET_PART_PICKUP_VAN.FETCH(),
        ActionCreators.GET_PART_PICKUP_VAN.SUCCESS(),
        ActionCreators.GET_PART_PICKUP_VAN.FAILURE(),
    ],
    endpoint: API_URLS.PART_PICKUP.GET_PART_PICKUP_VAN(partPickupOrderId),
    method: 'GET',
});
export const reschedulePartPickup = (partPickupOrderId, data) => createAPIAction({
    types: [
        ActionCreators.RESCHEDULE_PART_PICKUP.FETCH(),
        ActionCreators.RESCHEDULE_PART_PICKUP.SUCCESS(),
        ActionCreators.RESCHEDULE_PART_PICKUP.FAILURE(),
    ],
    endpoint: API_URLS.PART_PICKUP.RESCHEDULE_PART_PICKUP(partPickupOrderId),
    method: 'POST',
    body: data,
});
export const transferPartPickup = (partPickupOrderId, data) => createAPIAction({
    types: [
        ActionCreators.TRANSFER_PART_PICKUP.FETCH(),
        ActionCreators.TRANSFER_PART_PICKUP.SUCCESS(),
        ActionCreators.TRANSFER_PART_PICKUP.FAILURE(),
    ],
    endpoint: API_URLS.PART_PICKUP.TRANSFER_PART_PICKUP(partPickupOrderId),
    method: 'POST',
    body: data,
});
export const submitPartPickupPhoto = (partPickupOrderId, body) => {
    return createAPIAction({
        types: [
            ActionCreators.SUBMIT_PART_PICKUP_PHOTO.FETCH(),
            ActionCreators.SUBMIT_PART_PICKUP_PHOTO.SUCCESS(),
            ActionCreators.SUBMIT_PART_PICKUP_PHOTO.FAILURE(),
        ],
        endpoint: API_URLS.PART_PICKUP.PART_PICKUP_PHOTO(partPickupOrderId),
        method: 'POST',
        formData: body,
    });
};
export const deletePartPickupPhoto = (partPickupOrderId, attachmentId) => createAPIAction({
    types: [
        ActionCreators.DELETE_PART_PICKUP_PHOTO.FETCH(),
        ActionCreators.DELETE_PART_PICKUP_PHOTO.SUCCESS(),
        ActionCreators.DELETE_PART_PICKUP_PHOTO.FAILURE(),
    ],
    endpoint: API_URLS.PART_PICKUP.DELETE_PART_PICKUP_PHOTO({
        partPickupOrderId,
        attachmentId,
    }),
    method: 'DELETE',
});
