import React from 'react';
const AMSLogo = ({ width = 270, height = 31, alt = 'AMS Tools', }) => {
    return (<svg viewBox="0 0 270.566948 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height}>
      <title>{alt}</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="270.566956" height="32"/>
      </defs>
      <g id="Mobile-Service-Website---Oct.-2023" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Homepage" transform="translate(-1533.005188, -847.000000)">
          <g id="Frame-1171275952" transform="translate(500.000000, 500.000000)">
            <g id="Frame-1171275091" transform="translate(805.005188, 0.000000)">
              <g id="Frame-1171275386" transform="translate(0.000000, 324.000000)">
                <g id="Frame-16" transform="translate(180.000000, 0.000000)">
                  <g id="Frame-17" transform="translate(48.000000, 19.000000)">
                    <g transform="translate(0.000000, 4.000000)" id="Mobile-Service-Logo--Inline_FULL-COLOR-1">
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"/>
                      </mask>
                      <g id="Clip" fillRule="nonzero"/>
                      <path d="M173.490329,16.8400202 C173.290283,16.8400202 173.199358,16.7781886 173.199358,16.6545241 C173.199358,16.5781431 173.213908,16.5163111 173.246643,16.4217441 L175.003401,12.5263125 L168.922019,16.4217441 C168.474644,16.7127194 168.092737,16.8363836 167.783576,16.8363836 L166.892471,16.8363836 C166.58331,16.8363836 166.27778,16.7127194 166.045,16.4217441 L162.920655,12.5263125 L161.178448,16.4217441 C161.054784,16.6981706 160.85473,16.8363836 160.578303,16.8363836 L159.145249,16.8363836 C158.945204,16.8363836 158.854279,16.7745506 158.854279,16.6508861 C158.854279,16.5745051 158.868832,16.5126731 158.916116,16.4181062 L163.226177,6.73226917 C163.349842,6.47039147 163.549896,6.33217812 163.826323,6.33217812 L165.044772,6.33217812 C165.368482,6.33217812 165.659462,6.47039147 165.844959,6.73226917 L170.155031,13.261025 L180.779261,6.73226917 C181.226636,6.47039147 181.67401,6.33217812 182.041367,6.33217812 L183.27438,6.33217812 C183.459876,6.33217812 183.550798,6.39401007 183.550798,6.5322233 C183.550798,6.59405553 183.550798,6.65588818 183.503513,6.73226917 L179.175259,16.4181062 C179.051594,16.6945326 178.866103,16.8327456 178.589677,16.8327456 L173.493969,16.8327456 L173.490329,16.8400202 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M185.438513,16.8400269 C183.299845,16.8400269 182.143219,15.4069741 182.143219,13.5156353 C182.143219,9.91117999 186.024109,6.33945942 190.119586,6.33945942 L197.663115,6.33945942 C199.834518,6.33945942 200.991131,7.725229 200.991131,9.62020506 C200.991131,13.2392091 197.001152,16.8400269 192.985694,16.8400269 L185.442153,16.8400269 L185.438513,16.8400269 Z M194.706077,10.544051 C195.044336,9.82025015 195.215271,9.29649519 195.215271,8.9255018 C195.215271,8.33991419 194.782448,8.1398681 193.967717,8.1398681 L191.872714,8.1398681 C190.672441,8.1398681 189.934086,8.67817242 189.115718,10.5404137 L188.377357,12.2207951 C187.977266,13.1119067 187.791775,13.7447785 187.791775,14.1921529 C187.791775,14.7922893 188.144575,15.0250698 188.806543,15.0250698 L190.916131,15.0250698 C192.178236,15.0250698 193.040252,14.3158174 193.964099,12.2244331 L194.702437,10.544051 L194.706077,10.544051 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M199.558085,16.840024 C199.372588,16.840024 199.281677,16.7781924 199.281677,16.6545279 C199.281677,16.5781469 199.296228,16.5163149 199.328962,16.421748 L203.657206,6.7359103 C203.78087,6.4740326 203.966362,6.33581924 204.242788,6.33581924 L217.605845,6.33581924 C219.067995,6.33581924 219.777237,6.96869059 219.777237,7.98346662 C219.777237,9.90754017 218.144139,10.6313411 217.391241,10.955051 C217.776783,11.1078129 218.147783,11.7261352 218.147783,12.5881492 C218.147783,14.4503905 216.023661,16.8363874 212.666534,16.8363874 L199.565367,16.8363874 L199.558085,16.840024 Z M208.596517,12.0207471 C208.41102,12.0207471 208.334628,11.9589155 208.334628,11.835251 C208.334628,11.7734188 208.349178,11.6970377 208.381913,11.6206567 L208.829302,10.6204295 C208.952967,10.3585518 209.138458,10.2203384 209.414885,10.2203384 L211.909992,10.2203384 C212.942954,10.2203384 213.848621,9.38742205 213.848621,8.7727369 C213.848621,8.40174351 213.57218,8.13986521 212.83383,8.13986521 L208.967517,8.13986521 L205.904987,15.0214289 L209.509455,15.0214289 C211.695407,15.0214289 212.033666,13.4028789 212.033666,12.8827608 C212.033666,12.3299079 211.648121,12.0207471 210.847939,12.0207471 L208.600158,12.0207471 L208.596517,12.0207471 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M218.355104,16.840024 C218.169608,16.840024 218.078674,16.7781924 218.078674,16.6545279 C218.078674,16.5781469 218.093225,16.5163149 218.12596,16.421748 L222.454203,6.7359103 C222.577867,6.4740326 222.777907,6.33581924 223.039785,6.33581924 L228.335555,6.33581924 C228.535601,6.33581924 228.630167,6.3976512 228.630167,6.53586442 C228.630167,6.59769666 228.615602,6.65952931 228.568319,6.7359103 L224.258257,16.421748 C224.134593,16.6981744 223.934553,16.8363874 223.672675,16.8363874 L218.358723,16.8363874 L218.355104,16.840024 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M230.16503,16.8400269 C228.026362,16.8400269 227.055237,15.9634638 227.055237,14.5595085 C227.055237,13.9593721 227.240734,13.2501207 227.578993,12.4972224 L230.150489,6.73955047 C230.274154,6.47767277 230.459645,6.33945942 230.736072,6.33945942 L236.631965,6.33945942 C236.832011,6.33945942 236.922936,6.40129138 236.922936,6.5395046 C236.922936,6.60133683 236.908371,6.66316948 236.861088,6.73955047 L234.18048,12.7591005 C233.904054,13.3737857 233.765857,13.8357083 233.765857,14.1921529 C233.765857,14.7631918 234.151391,15.0250698 234.984307,15.0250698 L241.036597,15.0250698 C241.236643,15.0250698 241.313027,15.1014505 241.313027,15.2251149 C241.313027,15.2723984 241.298463,15.3633282 241.251179,15.4397092 L240.821993,16.4253874 C240.698329,16.7018139 240.498289,16.8400269 240.236411,16.8400269 L230.16503,16.8400269 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M245.593978,16.8400269 C243.498957,16.8400269 242.313248,15.4542576 242.313248,13.5156353 C242.313248,9.92936601 246.208662,6.33945942 250.275041,6.33945942 L261.037497,6.33945942 C261.222993,6.33945942 261.313927,6.40129138 261.313927,6.5395046 C261.313927,6.60133683 261.299376,6.66316948 261.266641,6.73955047 L260.819274,7.72522865 C260.69561,8.0016551 260.495555,8.1398681 260.219129,8.1398681 L253.0575,8.1398681 C251.733563,8.1398681 251.009772,8.64907493 250.180492,10.5404137 L249.442132,12.2207951 C249.05659,13.0828092 248.85655,13.6974947 248.85655,14.144869 C248.85655,14.7777401 249.256642,15.0214318 249.994992,15.0214318 L257.218447,15.0214318 C257.403943,15.0214318 257.494877,15.0978125 257.494877,15.221477 C257.494877,15.2687605 257.480334,15.3596903 257.433051,15.4360713 L257.000224,16.4217508 C256.87656,16.6981773 256.676505,16.8363903 256.400079,16.8363903 L245.590338,16.8363903 L245.593978,16.8400269 Z M251.031582,12.02075 C250.846086,12.02075 250.755152,11.9589184 250.755152,11.8352539 C250.755152,11.7734217 250.769717,11.6970406 250.817001,11.6206596 L251.246186,10.6204323 C251.369851,10.3585546 251.569883,10.2203413 251.846309,10.2203413 L259.189798,10.2203413 C259.404392,10.2203413 259.513513,10.2676248 259.513513,10.3731033 C259.513513,10.4022008 259.498958,10.4494843 259.484409,10.4967678 L258.913368,11.7443241 C258.836987,11.9298208 258.651489,12.02075 258.389611,12.02075 L251.027942,12.02075 L251.031582,12.02075 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M152.75107,29.4792614 C152.565573,29.4792614 152.47464,29.4174284 152.47464,29.2937639 C152.47464,29.217383 152.48919,29.155551 152.521925,29.060984 L152.969304,28.0753059 C153.092968,27.7988795 153.293011,27.6606665 153.569438,27.6606665 L162.360531,27.6606665 L156.650138,24.3653708 C155.740841,23.8416154 155.44987,23.1941971 155.44987,22.579512 C155.44987,20.8409353 157.359394,18.9750557 159.916339,18.9750557 L171.264366,18.9750557 C171.464411,18.9750557 171.555347,19.036889 171.555347,19.1751022 C171.555347,19.2369345 171.540794,19.2987664 171.49351,19.3751474 L171.064325,20.3608255 C170.94066,20.6372519 170.726068,20.7754649 170.46419,20.7754649 L161.934975,20.7754649 L167.645368,24.0707593 C168.583763,24.6090634 168.845636,25.2710308 168.845636,25.8711671 C168.845636,27.6097438 166.968847,29.4756234 164.379167,29.4756234 L152.754711,29.4756234 L152.75107,29.4792614 Z" id="Vector" fill="#E31566" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M173.395758,29.4792643 C171.300736,29.4792643 170.115005,28.093495 170.115005,26.1548726 C170.115005,22.5686031 174.010441,18.9786949 178.07682,18.9786949 L188.839264,18.9786949 C189.024761,18.9786949 189.115694,19.0405269 189.115694,19.1787401 C189.115694,19.2405723 189.101144,19.3024043 189.068409,19.3787853 L188.621042,20.3644649 C188.497377,20.6408914 188.297323,20.7791044 188.020896,20.7791044 L180.859268,20.7791044 C179.535331,20.7791044 178.811525,21.2883105 177.978609,23.1796494 L177.24026,24.8600309 C176.854717,25.722045 176.654677,26.3367305 176.654677,26.7841049 C176.654677,27.416976 177.05477,27.6606691 177.79312,27.6606691 L185.012944,27.6606691 C185.198441,27.6606691 185.289363,27.7370484 185.289363,27.8607129 C185.289363,27.9079964 185.27482,27.9989276 185.227537,28.0753086 L184.79834,29.0609868 C184.674676,29.3374133 184.474632,29.4756263 184.198206,29.4756263 L173.388477,29.4756263 L173.395758,29.4792643 Z M178.829721,24.6599871 C178.644224,24.6599871 178.553291,24.5981541 178.553291,24.4744897 C178.553291,24.4126574 178.567833,24.336275 178.615117,24.259894 L179.047943,23.259668 C179.171608,22.9977903 179.371662,22.8595763 179.648089,22.8595763 L186.991565,22.8595763 C187.206159,22.8595763 187.315281,22.9068605 187.315281,23.012339 C187.315281,23.0414365 187.300726,23.08872 187.286177,23.1360035 L186.715135,24.3835599 C186.638754,24.5690566 186.453268,24.6599871 186.19139,24.6599871 L178.829721,24.6599871 Z" id="Vector" fill="#E31566" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M199.096182,29.4792635 C198.696091,29.4792635 198.401472,29.3555993 198.24871,29.0646241 L195.677214,24.183513 C195.615382,24.0925832 195.568103,24.0125667 195.568103,23.8925394 C195.568103,23.8307072 195.5972,23.7397767 195.659032,23.6161122 L195.82997,23.2633043 C195.953634,23.0014266 196.139148,22.863214 196.415574,22.863214 L198.510599,22.863214 C199.849086,22.863214 200.543777,22.2157936 200.543777,21.4774441 C200.543777,21.0446184 200.190973,20.7863788 199.434438,20.7863788 L196.000929,20.7863788 L192.305531,29.0682593 C192.181867,29.3446857 191.996375,29.4828987 191.719949,29.4828987 L186.438763,29.4828987 C186.253267,29.4828987 186.147781,29.4210685 186.147781,29.297404 C186.147781,29.221023 186.162324,29.159191 186.209607,29.0646241 L190.537862,19.3787853 C190.661526,19.1169076 190.843381,18.9786949 191.123444,18.9786949 L204.581049,18.9786949 C206.014102,18.9786949 206.890678,19.7315929 206.890678,20.8409358 C206.890678,22.8595764 204.813848,24.3217266 202.304187,24.6272506 L204.628334,28.9846055 C204.642883,29.013703 204.657438,29.0755342 204.657438,29.1228177 C204.657438,29.2937656 204.486485,29.4756256 204.242793,29.4756256 L199.099823,29.4756256 L199.096182,29.4792635 Z" id="Vector" fill="#E31566" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M215.554459,29.1737429 C215.278033,29.3737883 215.016143,29.4829035 214.768814,29.4829035 L213.754046,29.4829035 C213.477619,29.4829035 213.324864,29.3737883 213.215749,29.1737429 L208.010931,19.5060923 C207.981827,19.4588088 207.981827,19.3824274 207.981827,19.3351439 C207.981827,19.1350985 208.13458,18.982336 208.472839,18.982336 L214.801558,18.982336 C215.139817,18.982336 215.354414,19.0732666 215.46353,19.291498 L218.664253,25.4674453 L227.640835,19.291498 C227.93181,19.0914526 228.226429,18.982336 228.579236,18.982336 L229.579464,18.982336 C229.717677,18.982336 229.779505,19.0441687 229.779505,19.1532844 C229.779505,19.2624001 229.688578,19.3678787 229.532179,19.4915432 L215.550819,29.1773808 L215.554459,29.1737429 Z" id="Vector" fill="#E31566" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M226.564204,29.4792614 C226.378707,29.4792614 226.287796,29.4174284 226.287796,29.2937639 C226.287796,29.217383 226.302347,29.155551 226.335081,29.060984 L230.663325,19.3751474 C230.786989,19.1132697 230.987029,18.9750557 231.248907,18.9750557 L236.544655,18.9750557 C236.744701,18.9750557 236.839267,19.036889 236.839267,19.1751022 C236.839267,19.2369345 236.824724,19.2987664 236.777441,19.3751474 L232.467379,29.060984 C232.343715,29.3374104 232.143674,29.4756234 231.881797,29.4756234 L226.567845,29.4756234 L226.564204,29.4792614 Z" id="Vector" fill="#E31566" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M238.774246,29.4792643 C236.679224,29.4792643 235.493515,28.093495 235.493515,26.1548726 C235.493515,22.5686031 239.388929,18.9786949 243.455308,18.9786949 L253.941334,18.9786949 C254.126831,18.9786949 254.217764,19.0405269 254.217764,19.1787401 C254.217764,19.2405723 254.217764,19.3024043 254.170479,19.3787853 L253.723111,20.3644649 C253.599447,20.6408914 253.399393,20.7791044 253.122966,20.7791044 L246.241408,20.7791044 C244.917471,20.7791044 244.193657,21.2883105 243.364378,23.1796494 L242.62604,24.8600309 C242.240498,25.722045 242.040458,26.3367305 242.040458,26.7841049 C242.040458,27.416976 242.44055,27.6606691 243.1789,27.6606691 L250.122284,27.6606691 C250.322329,27.6606691 250.398714,27.7370484 250.398714,27.8607129 C250.398714,27.9079964 250.384171,27.9989276 250.336888,28.0753086 L249.907702,29.0609868 C249.784038,29.3374133 249.583975,29.4756263 249.322098,29.4756263 L238.774246,29.4756263 L238.774246,29.4792643 Z" id="Vector" fill="#E31566" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M254.847,29.4792643 C252.751978,29.4792643 251.566269,28.093495 251.566269,26.1548726 C251.566269,22.5686031 255.461683,18.9786949 259.528062,18.9786949 L270.290518,18.9786949 C270.476015,18.9786949 270.566948,19.0405269 270.566948,19.1787401 C270.566948,19.2405723 270.552397,19.3024043 270.519663,19.3787853 L270.072295,20.3644649 C269.948631,20.6408914 269.748577,20.7791044 269.47215,20.7791044 L262.310521,20.7791044 C260.986584,20.7791044 260.262793,21.2883105 259.433513,23.1796494 L258.695153,24.8600309 C258.309611,25.722045 258.109571,26.3367305 258.109571,26.7841049 C258.109571,27.416976 258.509664,27.6606691 259.248013,27.6606691 L266.471468,27.6606691 C266.656965,27.6606691 266.747898,27.7370484 266.747898,27.8607129 C266.747898,27.9079964 266.733333,27.9989276 266.68605,28.0753086 L266.253245,29.0609868 C266.129581,29.3374133 265.929527,29.4756263 265.6531,29.4756263 L254.843359,29.4756263 L254.847,29.4792643 Z M260.284604,24.6599871 C260.099107,24.6599871 260.008174,24.5981541 260.008174,24.4744897 C260.008174,24.4126574 260.022738,24.336275 260.070022,24.259894 L260.499208,23.259668 C260.622872,22.9977903 260.822904,22.8595763 261.099331,22.8595763 L268.442819,22.8595763 C268.657413,22.8595763 268.766534,22.9068605 268.766534,23.012339 C268.766534,23.0414365 268.751979,23.08872 268.737431,23.1360035 L268.166389,24.3835599 C268.090008,24.5690566 267.90451,24.6599871 267.642633,24.6599871 L260.280963,24.6599871 L260.284604,24.6599871 Z" id="Vector" fill="#E31566" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M14.0904758,23.0560417 L12.5883158,18.7968924 L5.59763597,18.7968924 L4.09547634,23.0560417 L3.84498782e-14,23.0560417 L7.31802715,3.94260788 L11.0315981,3.94260788 L18.2950668,23.0560417 L14.0904758,23.0560417 Z M10.0495564,11.6970971 C9.66765148,10.5768426 9.28574678,9.43112758 9.0929757,8.72187548 C8.90020463,9.43112758 8.51829992,10.5768426 8.13639496,11.6970971 L6.90702426,15.1669768 L11.2789271,15.1669768 L10.0495564,11.6970971 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M27.6935578,23.0560027 L27.6935578,22.0994213 C27.0388635,22.7832131 25.8385923,23.3578901 24.3364327,23.3578901 C20.6774192,23.3578901 19.0406818,20.8445916 19.0406818,16.8036736 L19.0406818,8.72183704 L22.8088112,8.72183704 L22.8088112,16.476326 C22.8088112,19.0441821 23.6817369,19.8079919 25.2384544,19.8079919 C26.7951719,19.8079919 27.6935578,19.0441819 27.6935578,16.5599812 L27.6935578,8.72183704 L31.4616871,8.72183704 L31.4616871,23.0560027 L27.6935578,23.0560027 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M40.9038355,22.4849432 C40.2746016,22.9759639 39.1543474,23.3578694 37.9831721,23.3578694 C35.6626447,23.3578694 34.1604843,21.5538229 34.1604843,19.0441618 L34.1604843,5.03734218 L37.9286137,3.15327764 L37.9286137,8.72545266 L41.3693954,8.72545266 L41.3693954,12.2753502 L37.9286137,12.2753502 L37.9286137,18.0366586 C37.9286137,19.4006049 38.4196351,19.7570507 39.3216583,19.7570507 C40.0054501,19.7570507 40.5219313,19.4588011 41.453052,18.6658937 L40.9074735,22.4885812 L40.9038355,22.4849432 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M48.7674404,23.3578615 C45.0247718,23.3578615 42.2968788,20.2989854 42.2968788,15.9016225 C42.2968788,11.5042596 45.0284091,8.41992188 48.7674404,8.41992188 C52.5064718,8.41992188 55.2670975,11.5078969 55.2670975,15.9016225 C55.2670975,20.2953481 52.5101091,23.3578615 48.7674404,23.3578615 Z M48.7674404,12.0534747 C46.9670314,12.0534747 46.1195664,13.6938475 46.1195664,15.9052591 C46.1195664,18.1166706 46.9670314,19.7279467 48.7674404,19.7279467 C50.5678495,19.7279467 51.4407719,17.8984393 51.4407719,15.9052591 C51.4407719,13.7193077 50.5678495,12.0534747 48.7674404,12.0534747 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M69.521262,23.0560417 L62.5051247,12.6245803 C62.0432015,11.9153282 61.4139647,10.9587478 61.1666358,10.4677272 C61.1666358,11.1769793 61.221197,13.5811618 61.221197,14.6468585 L61.221197,23.0560417 L57.4530678,23.0560417 L57.4530678,3.94260788 L61.1120802,3.94260788 L67.8808912,14.0467216 C68.3464516,14.7559737 68.9756828,15.7125548 69.2193745,16.2035755 C69.2193745,15.4943234 69.1648189,13.0901415 69.1648189,12.0244448 L69.1648189,3.94260788 L72.9329481,3.94260788 L72.9329481,23.0560417 L69.521262,23.0560417 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M83.7499408,23.0559788 L83.7499408,22.0993974 C83.066149,22.8086495 81.8658759,23.3578663 80.5273898,23.3578663 C77.8540549,23.3578663 75.1770859,21.7174931 75.1770859,18.251251 C75.1770859,15.0832583 77.7449386,13.5010801 80.9929498,13.5010801 C82.3023383,13.5010801 83.1498043,13.7738701 83.7499408,14.0757568 L83.7499408,13.3665045 C83.7499408,12.3553656 83.0661527,11.5369976 81.7022063,11.5369976 C79.9818153,11.5369976 79.0797929,11.7843262 77.7704044,12.4935783 L76.3227986,9.87116452 C77.9886318,8.91458347 79.3816788,8.423563 81.7022063,8.423563 C85.1684487,8.423563 87.5180702,10.2276093 87.5180702,13.4501597 L87.5180702,23.0632534 L83.7499408,23.0632534 L83.7499408,23.0559788 Z M83.7499408,17.0473405 C83.0916093,16.636338 82.4660157,16.447205 81.1275296,16.447205 C79.7090253,16.447205 78.9415746,17.1018981 78.9415746,18.247613 C78.9415746,19.2587519 79.5162543,20.1607758 81.1275296,20.1607758 C82.3569,20.1607758 83.3389382,19.5315425 83.7499408,18.8513879 L83.7499408,17.0473405 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M96.822021,22.4849432 C96.192787,22.9759639 95.0725328,23.3578694 93.9013574,23.3578694 C91.5808299,23.3578694 90.0786667,21.5538229 90.0786667,19.0441618 L90.0786667,5.03734218 L93.8467907,3.15327764 L93.8467907,8.72545266 L97.2875809,8.72545266 L97.2875809,12.2753502 L93.8467907,12.2753502 L93.8467907,18.0366586 C93.8467907,19.4006049 94.3378176,19.7570507 95.2398409,19.7570507 C95.9236327,19.7570507 96.4401084,19.4588011 97.3712292,18.6658937 L96.8256506,22.4885812 L96.822021,22.4849432 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <polygon id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)" points="99.6590118 23.0560026 99.6590118 8.72183704 103.427136 8.72183704 103.427136 23.0560026"/>
                      <path d="M111.592642,23.3578615 C107.849974,23.3578615 105.122078,20.2989854 105.122078,15.9016225 C105.122078,11.5042596 107.853611,8.41992188 111.592642,8.41992188 C115.331674,8.41992188 118.092299,11.5078969 118.092299,15.9016225 C118.092299,20.2953481 115.335311,23.3578615 111.592642,23.3578615 Z M111.592642,12.0534747 C109.792233,12.0534747 108.944768,13.6938475 108.944768,15.9052591 C108.944768,18.1166706 109.792233,19.7279467 111.592642,19.7279467 C113.393051,19.7279467 114.265979,17.8984393 114.265979,15.9052591 C114.265979,13.7193077 113.393051,12.0534747 111.592642,12.0534747 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M128.305514,23.0559734 L128.305514,15.3014852 C128.305514,12.7336292 127.43258,11.9698193 125.875863,11.9698193 C124.319145,11.9698193 123.417129,12.7336287 123.417129,15.2214666 L123.417129,23.0596113 L119.648994,23.0596113 L119.648994,8.72180861 L123.417129,8.72180861 L123.417129,9.67838995 C124.071824,8.99459825 125.272089,8.41992188 126.774249,8.41992188 C130.433263,8.41992188 132.073638,10.9332197 132.073638,14.9741376 L132.073638,23.0559734 L128.305514,23.0559734 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M133.684937,23.0305609 C133.215739,23.0305609 132.859299,22.6741161 132.859299,22.2049186 C132.859299,21.7357211 133.215739,21.3792763 133.684937,21.3792763 C134.154134,21.3792763 134.510586,21.7357211 134.510586,22.2049186 C134.510586,22.6741161 134.154134,23.0305609 133.684937,23.0305609 Z M133.684937,21.5102153 C133.29212,21.5102153 132.990243,21.8084648 132.990243,22.2049186 C132.990243,22.6013723 133.288483,22.8996218 133.684937,22.8996218 C134.081391,22.8996218 134.379642,22.6013723 134.379642,22.2049186 C134.379642,21.8084648 134.081391,21.5102153 133.684937,21.5102153 Z M133.870437,22.2740261 L134.055937,22.6377451 L133.874078,22.6377451 L133.699489,22.2885738 L133.5249,22.2885738 L133.5249,22.6377451 L133.372144,22.6377451 L133.372144,21.7357207 L133.768596,21.7357207 C133.928633,21.7357207 134.063208,21.8193761 134.063208,22.00851 C134.063208,22.1503604 133.986827,22.2412913 133.870437,22.2740261 Z M133.761326,21.8848455 L133.5249,21.8848455 L133.5249,22.139449 L133.761326,22.139449 C133.844981,22.139449 133.906811,22.0994405 133.906811,22.0121479 C133.906811,21.9284926 133.844981,21.8848455 133.761326,21.8848455 Z" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M115.611727,25.9330482 C111.603544,25.9330482 107.595361,25.973056 103.587178,26.0567114 C97.9749934,26.1767386 92.3628153,26.3440493 86.7542683,26.6277501 C81.2984832,26.9041765 75.8426961,27.198789 70.3905483,27.6025171 C66.1277615,27.9153154 61.8686123,28.2644848 57.6094627,28.6245666 C57.72949,28.6172922 57.8495193,28.6100187 57.9695465,28.6027443 C62.5560431,28.348141 67.1389033,28.1153623 71.7290371,27.9080425 C76.9920512,27.6716251 82.2587015,27.5625087 87.5217156,27.4497558 C90.7697264,27.3806492 94.0213696,27.3551881 97.2693804,27.3551881 C99.4407828,27.3551881 101.608553,27.3661003 103.776319,27.3842862 C108.999323,27.4242953 114.21869,27.5406863 119.438058,27.726183 C121.824055,27.8098383 124.206416,27.9153151 126.588775,28.0426167 C127.538082,28.0935374 128.48738,28.1444597 129.433049,28.2062919 C129.942256,28.2390266 130.451467,28.2717601 130.960674,28.3044948 C131.102524,28.3154064 131.2953,28.3481421 131.477159,28.3481421 C131.768135,28.3481421 132.040915,28.2644869 132.059101,27.8680332 C132.07365,27.5734208 132.088208,27.2824435 132.102757,26.9878311 C132.102757,26.9514592 132.102757,26.9150897 132.102757,26.882355 C132.102757,26.5113617 131.989998,26.2494813 131.597182,26.2203838 C131.127984,26.1876491 130.658788,26.1840141 130.189591,26.1658282 C129.160266,26.1294563 128.127304,26.09672 127.097979,26.0676225 C124.664699,26.0057903 122.235057,25.9621433 119.801777,25.9475946 C118.401459,25.936683 117.004775,25.9330482 115.604457,25.9330482" id="Vector" fill="currentColor" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M101.0557,29.1301479 C99.8408782,29.1301479 98.6224199,29.1337862 97.4075984,29.148335 C93.2539272,29.1883441 89.1002581,29.2429014 84.9465869,29.3665658 C81.6985759,29.46477 78.4505657,29.5847989 75.206192,29.7121005 C75.2971218,29.7121005 75.3916867,29.7120998 75.4826165,29.7084626 C78.9743192,29.6611791 82.4660226,29.6248084 85.9577253,29.6102597 C86.4378344,29.6102597 86.9143052,29.6102597 87.3944143,29.6102597 C90.9224887,29.6102597 94.4505621,29.6684546 97.9749992,29.7266496 C102.095936,29.7921191 106.216876,29.9412429 110.334175,30.1121909 C114.30235,30.2758644 118.266884,30.4940946 122.231421,30.7559723 C124.042742,30.8759995 125.850423,31.0105778 127.661744,31.1560654 C128.381907,31.2142604 129.102072,31.2760906 129.822236,31.3415601 C130.207778,31.377932 130.593321,31.4143042 130.9825,31.4470389 C131.102527,31.4579505 131.262565,31.4906862 131.415327,31.4906862 C131.622646,31.4906862 131.804511,31.4324897 131.829972,31.1851608 C131.848158,30.9887525 131.866341,30.7923461 131.884527,30.5959378 C131.884527,30.5595659 131.888157,30.5268289 131.888157,30.4940941 C131.888157,30.261314 131.804506,30.0976411 131.517167,30.0685436 C131.160723,30.0321717 130.804277,30.017624 130.447833,29.9958009 C129.665837,29.9448802 128.883844,29.893959 128.098211,29.8503128 C126.250518,29.7448343 124.402829,29.6502673 122.555137,29.5775235 C118.445112,29.4102128 114.335086,29.2865471 110.221423,29.2210777 C107.169821,29.170157 104.114573,29.1374239 101.06297,29.1374239" id="Vector" fill="#E31566" fillRule="nonzero" mask="url(#mask-2)"/>
                      <path d="M138.81337,31.9998621 C137.991365,31.9998621 137.580368,31.7270723 137.580368,31.1778566 C137.580368,30.8359607 137.649474,30.5595336 137.78405,30.1485311 L150.430556,1.78208534 C150.979772,0.614547286 151.870881,-0.000137871146 153.031145,-0.000137871146 L155.442604,-0.000137871146 C156.333716,-0.000137871146 156.744724,0.272651391 156.744724,0.890973734 C156.744724,1.163763 156.675614,1.43655228 156.468294,1.78208534 L143.890884,30.1521691 C143.341668,31.3851766 142.450559,32.0034975 141.290295,32.0034975 L138.81337,32.0034975 L138.81337,31.9998621 Z" id="Vector" fill="#E31566" fillRule="nonzero" mask="url(#mask-2)"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>);
};
export { AMSLogo };
