/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useReducer, useEffect } from 'react';
import { useMediaQuery } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import moment from 'moment';
import { FilterContainer } from './FilterDialog.styles';
import FilterItem, { Value } from './FilterItem';
import AppointmentDates from './AppointmentDates';
const initialState = {
    vanType: [],
    regionName: '',
    appointmentStart: null,
    appointmentEnd: null,
    territories: [],
    metros: [],
    query: undefined,
    componentIds: [],
};
const filterReducer = (state, action) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { type, payload, meta } = action;
    if (type === 'RESET') {
        return Object.assign({}, initialState);
    }
    if (type === 'RESET_VAN_TYPE') {
        return Object.assign(Object.assign({}, state), { vanType: [] });
    }
    if (type === 'SET_VAN_TYPE') {
        const vanType = (_a = state.vanType) !== null && _a !== void 0 ? _a : [];
        return Object.assign(Object.assign({}, state), { vanType: (vanType === null || vanType === void 0 ? void 0 : vanType.includes(payload))
                ? vanType.filter((f) => f !== payload)
                : [...vanType, payload] });
    }
    if (type === 'RESET_REGION_NAME') {
        return Object.assign(Object.assign({}, state), { regionName: '' });
    }
    if (type === 'SET_REGION_NAME') {
        return Object.assign(Object.assign({}, state), { regionName: payload });
    }
    if (type === 'RESET_APPOINTMENT_DATE') {
        return Object.assign(Object.assign({}, state), { appointmentStart: null, appointmentEnd: null });
    }
    if (type === 'SET_APPOINTMENT_START') {
        return Object.assign(Object.assign({}, state), { appointmentStart: payload });
    }
    if (type === 'SET_APPOINTMENT_END') {
        return Object.assign(Object.assign({}, state), { appointmentEnd: payload });
    }
    if (type === 'RESET_TERRITORIES') {
        return Object.assign(Object.assign({}, state), { territories: [] });
    }
    if (type === 'SET_TERRITORY') {
        return Object.assign(Object.assign({}, state), { territories: payload });
    }
    if (type === 'RESET_METROS') {
        return Object.assign(Object.assign({}, state), { metros: [] });
    }
    if (type === 'SET_METRO') {
        return Object.assign(Object.assign({}, state), { metros: payload });
    }
    if (type === 'RESET_COMPONENTS') {
        return Object.assign(Object.assign({}, state), { componentIds: [] });
    }
    if (type === 'SET_COMPONENT') {
        return Object.assign(Object.assign({}, state), { componentIds: payload });
    }
    if (type === 'POPULATE') {
        const q = {};
        if (payload.skeduloTags) {
            q.vanType = payload.skeduloTags.split(',');
        }
        q.appointmentStart = payload.appointmentStart
            ? moment(payload.appointmentStart)
            : null;
        q.appointmentEnd = payload.appointmentEnd
            ? moment(payload.appointmentEnd)
            : null;
        if (payload.metros) {
            const m = payload.metros.split(',');
            q.metros =
                (_c = (_b = meta === null || meta === void 0 ? void 0 : meta.metros) === null || _b === void 0 ? void 0 : _b.filter((i) => m.includes(i.id))) !== null && _c !== void 0 ? _c : [];
        }
        if (payload.territories) {
            const t = payload.territories.split(',');
            q.territories =
                (_e = (_d = meta === null || meta === void 0 ? void 0 : meta.territories) === null || _d === void 0 ? void 0 : _d.filter((i) => t.includes(i.id))) !== null && _e !== void 0 ? _e : [];
        }
        if (payload.components) {
            const c = payload.components.split(',');
            q.componentIds =
                (_g = (_f = meta === null || meta === void 0 ? void 0 : meta.components) === null || _f === void 0 ? void 0 : _f.filter((i) => c.includes(i.id))) !== null && _g !== void 0 ? _g : [];
        }
        if (payload.regionName) {
            q.regionName = payload.regionName;
        }
        if (Object.keys(q).length < 1) {
            return Object.assign({}, state);
        }
        return Object.assign({}, q);
    }
    return state;
};
const FilterDialog = ({ isOpen, title, onClose, renderLocationFilter, metros = [], territories = [], onApplyChanges, query, }) => {
    var _a, _b, _c, _d;
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [filterState, dispatch] = useReducer(filterReducer, initialState);
    const createAction = (type, payload, meta) => dispatch({ type, payload, meta });
    useEffect(() => {
        if (!isOpen) {
            return;
        }
        if (query) {
            createAction('POPULATE', query, { territories, metros });
        }
    }, [territories, metros, isOpen, query]);
    const onCloseClick = () => {
        onClose();
    };
    const onResetClick = () => {
        onClose();
        createAction('RESET');
        if (onApplyChanges) {
            onApplyChanges(initialState);
        }
    };
    const onChangeFilter = () => {
        if (onApplyChanges) {
            onApplyChanges(filterState);
        }
        onClose();
    };
    const isConsumerChecked = (_a = filterState === null || filterState === void 0 ? void 0 : filterState.vanType) === null || _a === void 0 ? void 0 : _a.includes('CONSUMER');
    const isFleetChecked = (_b = filterState === null || filterState === void 0 ? void 0 : filterState.vanType) === null || _b === void 0 ? void 0 : _b.includes('FLEET');
    const appointments = (<AppointmentDates dateStart={(_c = filterState.appointmentStart) !== null && _c !== void 0 ? _c : null} dateEnd={(_d = filterState.appointmentEnd) !== null && _d !== void 0 ? _d : null} onDateStartChange={(date) => {
            createAction('SET_APPOINTMENT_START', date);
        }} onDateEndChange={(date) => {
            createAction('SET_APPOINTMENT_END', date);
        }}/>);
    return isMobile ? (<FilterContainer open={isOpen} onClose={onClose} fullScreen>
      <div className="filterHeader">
        <button className="filterClose" type="button" onClick={onCloseClick}/>
        <h3 className="filterTitle">{title}</h3>
        <button className="filterReset" type="button" onClick={onResetClick}>
          Reset
        </button>
      </div>
      <div className="filterContent">
        <FilterItem title="Van Type" onShowAll={() => {
            createAction('RESET_VAN_TYPE');
        }}>
          <Value title="Consumer" checked={isConsumerChecked !== null && isConsumerChecked !== void 0 ? isConsumerChecked : false} onClick={() => {
            createAction('SET_VAN_TYPE', 'CONSUMER');
        }}/>
          <Value title="Fleet" checked={isFleetChecked !== null && isFleetChecked !== void 0 ? isFleetChecked : false} onClick={() => {
            createAction('SET_VAN_TYPE', 'FLEET');
        }}/>
        </FilterItem>
        <FilterItem title="Appointment Date" onShowAll={() => {
            createAction('RESET_APPOINTMENT_DATE');
        }}>
          {appointments}
        </FilterItem>
        {renderLocationFilter && (<>
            <FilterItem title="Territory" onShowAll={() => {
                createAction('RESET_TERRITORIES');
            }}>
              {territories.map((t) => {
                var _a, _b, _c;
                return (<Value key={t.id} title={t.name} checked={(_b = (_a = filterState === null || filterState === void 0 ? void 0 : filterState.territories) === null || _a === void 0 ? void 0 : _a.some((ter) => t.id === ter.id)) !== null && _b !== void 0 ? _b : false} disabled={!!((_c = filterState === null || filterState === void 0 ? void 0 : filterState.metros) === null || _c === void 0 ? void 0 : _c.length)} onClick={() => {
                        var _a;
                        const territoriesList = (_a = filterState === null || filterState === void 0 ? void 0 : filterState.territories) !== null && _a !== void 0 ? _a : [];
                        const values = territoriesList.some((ter) => t.id === ter.id)
                            ? territoriesList.filter((ter) => ter.id !== t.id)
                            : [...territoriesList, t];
                        createAction('SET_TERRITORY', values);
                    }}/>);
            })}
            </FilterItem>
            <FilterItem title="Metro" onShowAll={() => {
                createAction('RESET_METROS');
            }}>
              {metros.map((m) => {
                var _a, _b, _c;
                return (<Value key={m.id} title={m.name} checked={(_b = (_a = filterState === null || filterState === void 0 ? void 0 : filterState.metros) === null || _a === void 0 ? void 0 : _a.some((mtr) => m.id === mtr.id)) !== null && _b !== void 0 ? _b : false} disabled={!!((_c = filterState === null || filterState === void 0 ? void 0 : filterState.territories) === null || _c === void 0 ? void 0 : _c.length)} onClick={() => {
                        var _a;
                        const metrosList = (_a = filterState === null || filterState === void 0 ? void 0 : filterState.metros) !== null && _a !== void 0 ? _a : [];
                        const values = metrosList.some((mtr) => m.id === mtr.id)
                            ? metrosList.filter((mtr) => mtr.id !== m.id)
                            : [...metrosList, m];
                        createAction('SET_METRO', values);
                    }}/>);
            })}
            </FilterItem>
          </>)}
      </div>
      <div className="filterActions">
        <button className="filterAction" type="button" onClick={onChangeFilter}>
          Show Results
        </button>
      </div>
    </FilterContainer>) : null;
};
export default FilterDialog;
