import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { isNull } from 'lodash';
import React from 'react';
import PartDistributorAutocomplete from 'src/components/form/PartDistributorAutocomplete';
import PlacesAndPartDistributorLocationsAutoComplete from 'src/components/form/PlacesAndPartDistributorLocationsAutoComplete';
import { SimpleModal } from 'src/components/layout';
import { FormControl, Grid, InputAdornment, InputLabel, Select, Tab, Tabs, } from 'src/components/mui';
import { AttachMoneyRounded as AttachMoneyRoundedIcon } from 'src/components/mui/icons';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { DrawerFormBorderedSection } from 'src/components/SimpleDrawer/SimpleDrawer.styles';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { TooltipInfo } from 'src/components/tooltips';
import { colors } from 'src/styles/theme';
import { TextField } from 'src/AdminApp/components/form';
import { createMenuItems } from 'src/AdminApp/components/form/utils';
import ComponentAutocomplete from 'src/AdminApp/components/repairs/components/ComponentAutocomplete';
import PriceMargin from 'src/AdminApp/components/repairs/statement/PriceMargin';
import { FluidCapacityUnit, FluidType } from 'src/AdminApp/models/enums';
import useEditFluidDrawer from './useEditFluidDrawer';
import EditFluidDrawerAdditionalInfo from './EditFluidDrawerAdditionalInfo';
import { RowCompact, Spinner, StyledBackdrop, } from '../EditPartDrawer/EditPartDrawer.styles';
import AuthorizedSubtitle from '../components/AuthorizedSubtitle';
const EditFluidDrawer = ({ adding, capacity, capacityUnit, cost, id, name, notes, onClose, onRemove, onSubmit, open, partNumber, componentPartDistributor, price, specialOrder = false, type, leadTime = null, canEdit = true, totalCost, quotedCost, status, invoice, canEditDistributor = false, componentId = null, pricingData, hasEngine, isFitment = false, fixedComponents, canEditPricing = false, additionalInfo, isShowAdditionalInfo = false, isAuthorized = false, }) => {
    var _a, _b, _c, _d;
    const FluidSchema = Yup.object().shape({
        name: Yup.string().min(1).max(255).required(),
        capacityUnit: Yup.string().required(),
        capacity: Yup.number().positive().required(),
        price: adding && invoice === undefined
            ? Yup.number().min(0).nullable()
            : Yup.number().min(0).required(),
        cost: Yup.number().min(0).required(),
        type: Yup.string().required(),
        notes: Yup.string().min(1).max(255).nullable(),
        partNumber: Yup.string().min(1).max(255).nullable(),
        specialOrder: Yup.boolean().nullable(),
        componentId: Yup.string().nullable().notRequired(),
        leadTime: Yup.number().when('specialOrder', (specialOrder, PartSchema) => {
            return specialOrder
                ? PartSchema.min(0).required()
                : PartSchema.nullable();
        }),
        partDistributorId: Yup.string().nullable(),
        partDistributorLocationId: Yup.string().nullable(),
        partDistributor: Yup.string().min(1).max(255).nullable(),
        partDistributorName: Yup.string()
            .min(1)
            .max(255)
            .when('partDistributor', (partDistributor, PartSchema) => {
            return partDistributor === 'Other'
                ? PartSchema.required('Other vendor name required')
                : PartSchema.nullable().notRequired();
        }),
        address: Yup.string().min(1).max(255).nullable(),
        addAsDynamicComponent: Yup.boolean().nullable().notRequired(),
        partSpecialistLevel: Yup.number().min(0).nullable(),
        automaticallyOrder: Yup.boolean().nullable(),
        skynetMinimumMargin: Yup.number().min(0).nullable(),
        pcdbId: Yup.number().min(0).nullable(),
    });
    const { tab, handleTabChange, isConfirmCapModalOpen, setIsConfirmCapModalOpen, isAddPartToPickupDialogOpen, setIsAddPartToPickupDialogOpen, getPartDistributor, onAddressSelect, onAddPartToPickupConfirm, handleSubmitWithDrawer, handleSubmit, onConfirmDataCorrectness, onConfirmDynamicCreation, dynamicCreationConfirm, idComponentDataCorrect, handleCloseDialogs, confirmRemoveDynamicRule, setConfirmRemoveDynamicRule, forceComponentSelection, } = useEditFluidDrawer({
        componentPartDistributor,
        open,
        onSubmit,
        invoice,
        status,
        adding,
        isShowAdditionalInfo,
    });
    const isDynamicComponent = (pricingData === null || pricingData === void 0 ? void 0 : pricingData.componentType) === 'DYNAMIC';
    const isFixedComponent = (pricingData === null || pricingData === void 0 ? void 0 : pricingData.componentType) === 'FIXED';
    const additionalInfoMap = additionalInfo
        ? Object.keys(additionalInfo).map((key) => {
            return { name: key, value: additionalInfo[key] };
        })
        : [];
    return (<SimpleDrawer open={open} onClose={onClose} title={`${adding ? 'Add Fluid' : 'Edit Fluid'}`}>
      <Tabs variant="fullWidth" indicatorColor="primary" scrollButtons="auto" textColor="primary" value={tab} onChange={handleTabChange}>
        <Tab value="general" label="General Info" style={{
            fontWeight: 'bold',
        }}/>
        <Tab value="additional" label="Additional" style={{
            fontWeight: 'bold',
        }}/>
      </Tabs>

      {isAuthorized && <AuthorizedSubtitle />}

      <Formik initialValues={{
            id: id || '',
            name: name || '',
            capacityUnit: capacityUnit || '',
            capacity: capacity || '',
            type: type || '',
            price: isNull(price) ? '' : price,
            cost: isNull(cost) ? '' : cost,
            partDistributorId: (_a = componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.partDistributorId) !== null && _a !== void 0 ? _a : '',
            partDistributor: getPartDistributor(),
            partDistributorName: (_b = componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.partDistributorName) !== null && _b !== void 0 ? _b : '',
            partDistributorLocationId: (_c = componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.partDistributorLocationId) !== null && _c !== void 0 ? _c : '',
            address: (_d = componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.address) !== null && _d !== void 0 ? _d : '',
            notes: notes || '',
            partNumber: partNumber || '',
            specialOrder,
            leadTime,
            componentId,
            addAsDynamicComponent: isDynamicComponent,
            isFitment,
            additionalInfo: additionalInfoMap,
            partSpecialistLevel: null,
            automaticallyOrder: null,
            skynetMinimumMargin: null,
            pcdbId: null,
        }} validationSchema={FluidSchema} enableReinitialize onSubmit={() => { }}>
        {({ isSubmitting, isValid, isValidating, setFieldValue, values, dirty, resetForm, setSubmitting, }) => {
            var _a;
            return (<>
              <SimpleDrawerContent>
                {tab === 'general' && (<Grid container spacing={2}>
                    <DrawerFormBorderedSection container spacing={2} xs={12}>
                      <RowCompact item xs={12}>
                        <ComponentAutocomplete value={values.name} componentId={values.componentId} type="FLUID" onChange={({ componentId, name, partSpecialistLevel, automaticallyOrder, skynetMinimumMargin, coreChargeExpected, pcdbId, }) => {
                        setFieldValue('componentId', componentId);
                        setFieldValue('name', name);
                        setFieldValue('partSpecialistLevel', partSpecialistLevel);
                        setFieldValue('automaticallyOrder', automaticallyOrder);
                        setFieldValue('skynetMinimumMargin', skynetMinimumMargin);
                        setFieldValue('coreChargeExpected', coreChargeExpected);
                        setFieldValue('pcdbId', pcdbId);
                        if (!componentId) {
                            setFieldValue('addAsDynamicComponent', false);
                        }
                    }} disabled={!canEdit || isFitment} forceComponentSelection={forceComponentSelection}/>
                      </RowCompact>
                      <RowCompact item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="fluid-capacity-unit" className="editFieldCaption">
                            Fluid Capacity Unit
                          </InputLabel>
                          <Select variant="outlined" name="capacityUnit" label="Fluid Capacity Unit" disabled={!canEdit || isFitment} value={values.capacityUnit} onChange={(e) => setFieldValue('capacityUnit', e.target.value)}>
                            {createMenuItems(FluidCapacityUnit)}
                          </Select>
                        </FormControl>
                      </RowCompact>
                      <RowCompact item xs={12}>
                        <TextField variant="outlined" type="number" name="capacity" label="Fluid Capacity" onChange={(e) => {
                        const capacityInputVal = parseFloat(e.target.value);
                        setFieldValue('capacity', capacityInputVal);
                        if (capacityInputVal > 25.0) {
                            setIsConfirmCapModalOpen(!isConfirmCapModalOpen);
                        }
                    }} fullWidth disabled={(!adding && !canEditPricing) || isFitment}/>
                      </RowCompact>
                      <RowCompact item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="fluid-capacity-type" className="editFieldCaption">
                            Fluid Capacity Type
                          </InputLabel>
                          <Select variant="outlined" name="type" label="Fluid Capacity Type" disabled={!canEdit || isFitment} value={values.type} onChange={(e) => setFieldValue('type', e.target.value)}>
                            {createMenuItems(FluidType)}
                          </Select>
                        </FormControl>
                      </RowCompact>
                    </DrawerFormBorderedSection>
                    <DrawerFormBorderedSection container spacing={2} xs={12}>
                      <RowCompact item xs={6} style={{
                        fontSize: '13px',
                        fontWeight: 'bold',
                    }}>
                        System Quoted: {quotedCost}
                      </RowCompact>
                      <RowCompact item xs={6} style={{
                        textAlign: 'end',
                        fontSize: '13px',
                        fontWeight: 'bold',
                    }}>
                        Current Total: ${totalCost}
                      </RowCompact>
                    </DrawerFormBorderedSection>
                    <DrawerFormBorderedSection container spacing={2} xs={12}>
                      <RowCompact item xs={6}>
                        <TextField variant="outlined" type="number" name="cost" label="Fluid Cost" fullWidth disabled={!canEdit} InputProps={{
                        startAdornment: (<InputAdornment position="start">
                                <AttachMoneyRoundedIcon />
                              </InputAdornment>),
                    }}/>
                      </RowCompact>
                      <RowCompact item xs={6}>
                        <TextField variant="outlined" type="number" name="price" label="Fluid Price" fullWidth disabled={(!adding && !canEditPricing) || isFitment} InputProps={{
                        startAdornment: (<InputAdornment position="start">
                                <AttachMoneyRoundedIcon />
                              </InputAdornment>),
                    }}/>
                      </RowCompact>
                      <RowCompact item xs={12} style={{
                        textAlign: 'end',
                        padding: '0',
                    }}>
                        <PriceMargin price={values.price} cost={values.cost}/>
                      </RowCompact>
                    </DrawerFormBorderedSection>

                    <DrawerFormBorderedSection container spacing={2} xs={12}>
                      <RowCompact item xs={12}>
                        <TextField variant="outlined" type="string" name="partNumber" label="Part Number" fullWidth disabled={!canEdit}/>
                      </RowCompact>
                      <RowCompact item xs={12}>
                        <Field name="partDistributor">
                          {({ field }) => (<PartDistributorAutocomplete value={field.value} name="partDistributor" label="Part Distributor" isAuthorized={isAuthorized} onSelect={(partDistributor) => {
                            setFieldValue('partDistributor', partDistributor);
                            if ((partDistributor === null || partDistributor === void 0 ? void 0 : partDistributor.name) === 'Other') {
                                setFieldValue('partDistributorName', '');
                            }
                            else {
                                setFieldValue('partDistributorName', partDistributor === null || partDistributor === void 0 ? void 0 : partDistributor.name);
                            }
                            if ((partDistributor === null || partDistributor === void 0 ? void 0 : partDistributor.name) === 'Customer Provided') {
                                setFieldValue('price', 0);
                                setFieldValue('cost', 0);
                            }
                            setFieldValue('partDistributorId', partDistributor === null || partDistributor === void 0 ? void 0 : partDistributor.id);
                            setFieldValue('partDistributorId', partDistributor === null || partDistributor === void 0 ? void 0 : partDistributor.id);
                            setFieldValue('partDistributorLocationId', '');
                            setFieldValue('address', '');
                            setFieldValue('notes', '');
                        }}/>)}
                        </Field>
                      </RowCompact>
                      {((_a = values.partDistributor) === null || _a === void 0 ? void 0 : _a.name) === 'Other' && (<RowCompact item xs={12}>
                          <TextField variant="outlined" type="string" name="partDistributorName" label="Part Distributor Name" fullWidth disabled={!canEdit}/>
                        </RowCompact>)}
                      <RowCompact item xs={12}>
                        <PlacesAndPartDistributorLocationsAutoComplete variant="outlined" label="Distributor Address" address={values.address} onSelect={(value) => {
                        onAddressSelect(value, setFieldValue);
                    }} disabled={!values.partDistributorId ||
                        !canEdit ||
                        !canEditDistributor} partDistributorId={values.partDistributorId}/>
                      </RowCompact>
                    </DrawerFormBorderedSection>

                    {values.address && (<RowCompact item xs={12}>
                        <TextField variant="outlined" name="notes" fullWidth type="string" label="Vendor Notes" disabled={!canEdit}/>
                      </RowCompact>)}
                    {!isFitment && (<>
                        <RowCompact item xs={12}>
                          Special Order{' '}
                          <CheckboxField name="specialOrder" color="primary" disabled={!canEdit}/>
                        </RowCompact>
                        {!isFixedComponent && (<RowCompact item xs={12}>
                            <label htmlFor="fluid-add-as-dynamic-component">
                              Additional Required Component
                              {!hasEngine && (<TooltipInfo>
                                  This repair order is missing Vehicle
                                  Information. Additional Required Components
                                  cannot be selected without full vehicle
                                  information.
                                </TooltipInfo>)}
                              <CheckboxField id="fluid-add-as-dynamic-component" name="addAsDynamicComponent" color="primary" disabled={!canEdit ||
                                !(values === null || values === void 0 ? void 0 : values.componentId) ||
                                !hasEngine ||
                                (fixedComponents || []).includes(values === null || values === void 0 ? void 0 : values.componentId)}/>
                            </label>
                          </RowCompact>)}
                        {values.specialOrder && (<RowCompact item xs={12}>
                            <TextField variant="outlined" type="number" name="leadTime" label="Lead Time (Days)" fullWidth disabled={!canEdit}/>
                          </RowCompact>)}
                        {isDynamicComponent && (<RowCompact item xs={12}>
                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                              Dynamic Component
                            </span>
                            <TooltipInfo infoColor={colors.blue13} contentWidth="700px">
                              This component was added automatically as it has
                              been marked as necessary by our system when
                              performing this repair on this vehicle.
                            </TooltipInfo>
                          </RowCompact>)}
                      </>)}
                  </Grid>)}
                {tab === 'additional' && (<EditFluidDrawerAdditionalInfo values={values.additionalInfo} setFieldValue={setFieldValue} canEdit={canEdit}/>)}
              </SimpleDrawerContent>
              <SimpleDrawerFooter>
                <SimpleTwoButtonRow disabledSubmit={!isValid ||
                    isValidating ||
                    isSubmitting ||
                    !canEdit ||
                    !dirty ||
                    (forceComponentSelection &&
                        ((adding && !(values === null || values === void 0 ? void 0 : values.componentId)) ||
                            (!adding &&
                                name !== (values === null || values === void 0 ? void 0 : values.name) &&
                                !(values === null || values === void 0 ? void 0 : values.componentId))))} disableTopPadding disabledCancel={adding || !canEdit} submitText="Save" cancelText="Remove" onCancel={() => {
                    if ((values === null || values === void 0 ? void 0 : values.componentId) && isDynamicComponent) {
                        setConfirmRemoveDynamicRule(true);
                        return;
                    }
                    onRemove({ componentId: id });
                }} onSubmit={() => {
                    if (values.addAsDynamicComponent && (values === null || values === void 0 ? void 0 : values.componentId)) {
                        onConfirmDynamicCreation();
                        return;
                    }
                    handleSubmitWithDrawer(values, resetForm, setSubmitting);
                }}/>
              </SimpleDrawerFooter>

              <StyledBackdrop open={isSubmitting}>
                <Spinner />
              </StyledBackdrop>

              <SimpleModal open={isConfirmCapModalOpen} onClose={() => {
                    setFieldValue('capacity', capacity);
                    setIsConfirmCapModalOpen(!isConfirmCapModalOpen);
                }} title="I'll make you an offer you can't refuse. You have entered in a fluid capacity greater than 25, are you sure?" simpleTwoButtonRowProps={{
                    onCancel: () => {
                        setFieldValue('capacity', capacity);
                        setIsConfirmCapModalOpen(!isConfirmCapModalOpen);
                    },
                    onSubmit: () => {
                        setIsConfirmCapModalOpen(!isConfirmCapModalOpen);
                    },
                    cancelText: 'No',
                    submitText: 'Yes',
                }}/>
              <SimpleModal open={isAddPartToPickupDialogOpen} onClose={() => setIsAddPartToPickupDialogOpen(false)} title="Would you like to have the system create/merge with a part pickup?" simpleTwoButtonRowProps={{
                    onCancel: () => setIsAddPartToPickupDialogOpen(false),
                    onSubmit: () => {
                        onAddPartToPickupConfirm(true);
                    },
                    cancelText: 'No',
                    submitText: 'Yes',
                }}>
                Reminder that part pickups created manually in Skedulo will not
                show on the technicians dashboard.
              </SimpleModal>
              <SimpleModal open={dynamicCreationConfirm} onClose={handleCloseDialogs} title="Warning" simpleTwoButtonRowProps={{
                    onCancel: handleCloseDialogs,
                    onSubmit: onConfirmDataCorrectness,
                    cancelText: 'Cancel',
                    submitText: 'Confirm',
                }}>
                Please confirm this fluid is always needed when doing this
                repair on this specific vehicle. Doing so will make sure this
                fluid always appears when we perform this repair on this
                specific vehicle.
              </SimpleModal>
              <SimpleModal open={idComponentDataCorrect} onClose={handleCloseDialogs} title="Are you sure?" simpleTwoButtonRowProps={{
                    onCancel: handleCloseDialogs,
                    onSubmit: () => {
                        handleSubmit(values, resetForm, setSubmitting);
                    },
                    cancelText: 'Cancel',
                    submitText: 'Confirm',
                }}>
                Please verify the part information is correct as entered.
              </SimpleModal>
              <SimpleModal open={confirmRemoveDynamicRule} onClose={handleCloseDialogs} title="Removal Warning" simpleTwoButtonRowProps={{
                    onCancel: () => {
                        handleCloseDialogs();
                    },
                    onSubmit: () => {
                        handleCloseDialogs();
                        onRemove({ componentId: id, removeDynamicRule: true });
                    },
                    cancelText: 'Cancel',
                    submitText: 'Confirm',
                }}>
                Please confirm that this fluid is not needed to complete this
                repair. Doing so will make sure this fluid no longer appears
                when we perform this repair on this specific vehicle.
              </SimpleModal>
            </>);
        }}
      </Formik>
    </SimpleDrawer>);
};
export default EditFluidDrawer;
