import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { PersonAdd as PersonAddIcon, Close } from 'src/components/mui/icons';
import { selectAuthorizedCustomerEdit } from 'src/modules/auth/selectors';
import { resolveZendeskUserId } from 'src/AdminApp/modules/consumers/actions';
import { getZendeskUrl } from 'src/AdminApp/utils/url-utils';
import SmallIconButton from 'src/components/icons/SmallIconButton';
import styled from 'styled-components';
import { Tooltip } from 'src/components/tooltips';
import { colors } from 'src/styles/theme';
const CloseIcon = styled(Close) `
  color: ${colors.error};
`;
const PersonAddIconSmall = styled(PersonAddIcon) `
  font-size: 15px;
`;
const ZendeskUserLink = ({ consumerId, zendeskUserId, linkStyles, }) => {
    const dispatch = useDispatch();
    const canEdit = useSelector(selectAuthorizedCustomerEdit);
    const onResolveZendeskUserClick = () => {
        dispatch(resolveZendeskUserId(consumerId)).then((result) => !result.error &&
            toast.success("Successfully resolved this consumer's Zendesk User ID."));
    };
    if (zendeskUserId) {
        return (<a href={getZendeskUrl(`/agent/users/${zendeskUserId}`)} target="_blank" rel="noreferrer" style={linkStyles}>
        {zendeskUserId}
      </a>);
    }
    if (canEdit) {
        return (<SmallIconButton color="primary" aria-label="Find or Create Zendesk User ID" title="Click to find or create the Zendesk User ID" onClick={onResolveZendeskUserClick}>
        <PersonAddIconSmall />
      </SmallIconButton>);
    }
    return (<Tooltip title={'ERROR - Not Authorized: You must have the "Customer_Editor" role to view this item'}>
      <div>
        <CloseIcon />
      </div>
    </Tooltip>);
};
export default ZendeskUserLink;
