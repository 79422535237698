import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Grid } from 'src/components/mui';
import ConsumerInternalNotes from 'src/AdminApp/containers/consumers/ConsumerInternalNotes';
import AddConsumerRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddConsumerRODrawer/AddConsumerRODrawer';
import { saveUser, setTab, setNextTab, setVehicle, } from 'src/AdminApp/modules/quickRo/actions';
import { CONSUMER_TABS as TABS } from 'src/AdminApp/modules/quickRo/enums';
import { loadConsumer, getConsumerNotes, } from 'src/AdminApp/modules/consumers/actions';
import { selectConsumerById, selectVehiclesByConsumerId, } from 'src/AdminApp/modules/consumers/selectors';
import { selectAuthorizedCustomerView, selectAuthorizedCustomerEdit, selectAuthorizedRepairEdit, selectAuthorizedConsumerMarketingResourceEditor, } from 'src/modules/auth/selectors';
import ConsumerInfo from 'src/AdminApp/containers/consumers/ConsumerInfo';
import VehicleList from 'src/AdminApp/containers/vehicle/VehicleList';
import RepairHistory from 'src/AdminApp/containers/requests/RepairHistory';
import PageTitle from 'src/containers/layout/PageTitle';
import { useParams } from 'react-router';
import Loader from 'src/AdminApp/containers/requests/StateTracker/Loader';
import TokensSubscriptionsDrawer from 'src/AdminApp/components/drawers/TokensSubscriptionsDrawer';
const ConsumerDetailsPage = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [addVehicleModalOpen, setAddVehicleModalOpen] = useState(false);
    const [tokensDrawerOpen, setTokensDrawerOpen] = useState(false);
    const consumer = useSelector(selectConsumerById(+params.consumerId));
    const vehicles = useSelector(selectVehiclesByConsumerId(+params.consumerId));
    const canView = useSelector(selectAuthorizedCustomerView);
    const canEdit = useSelector(selectAuthorizedCustomerEdit);
    const canEditMarketingSource = useSelector(selectAuthorizedConsumerMarketingResourceEditor);
    const canAddRequest = useSelector(selectAuthorizedRepairEdit);
    useEffect(() => {
        const { consumerId } = params;
        dispatch(loadConsumer(+consumerId));
        dispatch(getConsumerNotes(+consumerId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const { consumerId } = params;
        dispatch(loadConsumer(+consumerId));
        dispatch(getConsumerNotes(+consumerId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onAddVehicleOpen = () => {
        dispatch(saveUser(Object.assign(Object.assign({}, consumer), { address: consumer.fullAddress })));
        if (!isEmpty(consumer.address)) {
            dispatch(setTab(TABS.ADD_NEW_VEHICLE));
        }
        else {
            dispatch(setNextTab(TABS.ADD_NEW_VEHICLE));
        }
        setAddVehicleModalOpen(true);
    };
    const onAddVehicleClose = () => setAddVehicleModalOpen(false);
    const onAddRequest = (addRequestVehicle) => {
        dispatch(saveUser(Object.assign(Object.assign({}, consumer), { address: consumer.fullAddress })));
        dispatch(setVehicle(addRequestVehicle));
        if (!isEmpty(consumer.address)) {
            dispatch(setTab(TABS.ADD_SERVICES));
        }
        else {
            dispatch(setNextTab(TABS.ADD_SERVICES));
        }
        setAddVehicleModalOpen(true);
    };
    const onViewTokens = (vehicle) => {
        dispatch(setVehicle(vehicle));
        setTokensDrawerOpen(true);
    };
    if (isEmpty(consumer) || !consumer.requestHistory) {
        return <Loader />;
    }
    return canView ? (<div>
      <PageTitle slug={consumer.name}/>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <ConsumerInfo {...consumer} canEdit={canEdit} canEditMarketingSource={canEditMarketingSource}/>
        </Grid>
        <Grid item md={8} xs={12}>
          <VehicleList vehicles={vehicles} onAddVehicleOpen={onAddVehicleOpen} onAddRequestOpen={onAddRequest} onViewTokensOpen={onViewTokens} addRequest={!!canAddRequest} canEdit={canEdit} consumerId={+params.consumerId}/>
          <AddConsumerRODrawer open={addVehicleModalOpen} onClose={onAddVehicleClose} context="Consumer / Vehicle"/>

          <ConsumerInternalNotes canEdit={canEdit} consumerId={parseInt(params.consumerId, 10)}/>
        </Grid>
        {consumer.id && (<Grid item xs={12}>
            <RepairHistory consumerId={consumer.id}/>
          </Grid>)}
      </Grid>

      <TokensSubscriptionsDrawer open={tokensDrawerOpen} onClose={() => {
            setTokensDrawerOpen(false);
        }}/>
    </div>) : (<div>
      ERROR - Not Authorized: You must have the &ldquo;Customer_Viewer&rdquo;
      role to view this item
    </div>);
};
export default ConsumerDetailsPage;
