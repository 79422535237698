import { Button, Grid } from 'src/components/mui';
import React from 'react';
import PartFluidFitmentsDisplay from 'src/AdminApp/components/repairs/statement/Elements/PartFluidFitmentsDisplay';
import { Tooltip } from 'src/components/tooltips';
import { Info as InfoIcon } from 'src/components/mui/icons';
import { Dots, PartSecondaryInfo, StyledEditIcon, Title, } from 'src/AdminApp/components/repairs/statement/styledComponents';
import SimpleChip, { SimpleChips } from 'src/components/SimpleChip';
import CurrencyLabel from 'src/components/CurrencyLabel';
import useAdminUser from 'src/hooks/useAdminUser';
import useIsMobile from 'src/hooks/useIsMobile';
import { colors } from 'src/styles/theme';
import { formatTimestamp } from 'src/utils/formatter';
import { getCapacityUnitAbbreviation } from 'src/utils/getCapacityUnitAbbreviation';
import PartStatusChip, { PartPickupChip, } from 'src/AdminApp/components/repairs/statement/Elements/PartStatusChip';
import { getVendorInfo, statusesWithoutInfo } from './utils';
import FluidAdditionalInfoOptions from '../Repairs/Drawers/EditFluidDrawer/FluidAdditionalInfoOptions';
const Fluid = ({ fluid, canEdit, onEdit, status = '', jobPriority = -1, getJobPriority = () => { }, displayTooltip = false, requestPartPickups, onOpenAdditionalInfoFluid, }) => {
    var _a, _b;
    const isMobile = useIsMobile();
    const fluidFitments = (_a = fluid.fitments) !== null && _a !== void 0 ? _a : [];
    const isFluidOrdered = fluid.status === 'ORDERED' ||
        fluid.status === 'PICKED_UP' ||
        fluid.status === 'IN_STOCK' ||
        fluid.status === 'PARTIALLY_ORDERED' ||
        fluid.status === 'SOLD';
    const partPickups = (requestPartPickups || []).filter((item) => item.items.some((component) => component.componentId === fluid.id));
    const noInfoStatus = statusesWithoutInfo.includes(fluid.status);
    const userInfo = useAdminUser({
        user: fluid.orderedBy,
    });
    const vendorInfo = getVendorInfo(fluid.componentPartDistributor, fluid.notes);
    const tooltipText = () => {
        if (isFluidOrdered) {
            return `Ordered: ${formatTimestamp(fluid.partOrderedAt)} • ${userInfo} • ${fluid.orderMethod} ${vendorInfo}`;
        }
        if (displayTooltip &&
            (fluid.status === 'PENDING' ||
                fluid.status === 'WRONG_PART' ||
                fluid.status === 'NOT_PICKED_UP')) {
            return `Queue position: ${jobPriority < 1 || jobPriority > 20 ? '20+' : jobPriority}`;
        }
        return '';
    };
    const additionalInfoTooltip = (fluid === null || fluid === void 0 ? void 0 : fluid.additionalInfo) && Object.entries(fluid === null || fluid === void 0 ? void 0 : fluid.additionalInfo).length > 0
        ? Object.entries(fluid === null || fluid === void 0 ? void 0 : fluid.additionalInfo).map((entry) => `${FluidAdditionalInfoOptions.display(entry[0])}: ${entry[1]}`)
        : [];
    return (<div>
      <Grid container>
        <Title>
          {fluid.name} {fluid.partNumber}
        </Title>
        {(fluid === null || fluid === void 0 ? void 0 : fluid.additionalInfo) &&
            Object.entries(fluid === null || fluid === void 0 ? void 0 : fluid.additionalInfo).length > 0 && (<Tooltip placement="top" title={<div style={{ whiteSpace: 'pre-line' }}>
                  Additional Info: <br />
                  <br />
                  {additionalInfoTooltip === null || additionalInfoTooltip === void 0 ? void 0 : additionalInfoTooltip.map((field, idx) => idx < 5 && (<>
                          <span>{field}</span>
                          <br />
                        </>))}
                  {(additionalInfoTooltip === null || additionalInfoTooltip === void 0 ? void 0 : additionalInfoTooltip.length) &&
                    (additionalInfoTooltip === null || additionalInfoTooltip === void 0 ? void 0 : additionalInfoTooltip.length) >= 5 &&
                    onOpenAdditionalInfoFluid &&
                    canEdit && (<Button variant="text" onClick={() => onOpenAdditionalInfoFluid(fluid)}>
                        See More
                      </Button>)}
                </div>}>
              <InfoIcon style={{
                color: colors.blue,
                width: '20px',
                height: '20px',
                margin: '0 2px',
            }}/>
            </Tooltip>)}
        {onEdit && canEdit && <StyledEditIcon onClick={() => onEdit(fluid)}/>}
        <Dots />
        {fluid.capacity === null ? (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label>Pending</label>) : (<CurrencyLabel>
            {fluid.price && fluid.price * fluid.capacity}
          </CurrencyLabel>)}
      </Grid>
      <PartSecondaryInfo>
        {fluid.type} • {fluid.capacity}
        {getCapacityUnitAbbreviation(fluid.capacityUnit)} @{' '}
        <CurrencyLabel>{fluid.price}</CurrencyLabel>
        {fluid.capacityUnit &&
            `/${getCapacityUnitAbbreviation(fluid.capacityUnit)}`}
        <br />
      </PartSecondaryInfo>

      {fluidFitments.length === 0 && (<Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{(_b = tooltipText()) !== null && _b !== void 0 ? _b : ''}</div>} placement={isMobile ? 'bottom' : 'left'} disabled={noInfoStatus}>
          <div style={{ display: 'inline-block' }} onMouseEnter={isFluidOrdered || noInfoStatus ? () => { } : () => getJobPriority()}>
            <PartStatusChip status={status}/>
          </div>
        </Tooltip>)}

      <SimpleChips>
        {fluidFitments.length === 0 &&
            partPickups.map((ppu) => (<PartPickupChip key={ppu.id} referenceNum={ppu.referenceNum}/>))}
        {fluidFitments.length > 0 && (<SimpleChip label="MULTIPLE FITMENT" $bgColor={colors.newCarBlue}/>)}
      </SimpleChips>

      <PartFluidFitmentsDisplay fitments={fluidFitments} onEdit={(fitment) => onEdit ? onEdit(fitment, true) : undefined} canEdit={canEdit} fluid={fluid} requestPartPickups={requestPartPickups}/>
    </div>);
};
export default Fluid;
