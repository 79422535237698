import React from 'react';
import { Grid } from 'src/components/mui';
import styled from 'styled-components';
import { FONT_FAMILY, colors } from 'src/styles/theme';
import JobCard from './JobCard';
import FiltersDrawer from './FiltersDrawer';
const RegionName = styled.span `
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.12px;
  color: ${colors.GRAY_3F};
`;
const Jobs = ({ regionName, timelines }) => {
    return (<Grid container direction="column" spacing={2}>
      <Grid container item xs={12} style={{
            marginTop: '30px',
            justifyContent: 'space-between',
        }}>
        <Grid container item xs={6}>
          <RegionName>{regionName}</RegionName>
        </Grid>
        <Grid container item xs={6} style={{ justifyContent: 'flex-end' }}>
          <FiltersDrawer />
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="column">
        {timelines
            .filter((job) => job.repairJob.type !== 'LUNCH')
            .map((item) => (<JobCard key={item.id} item={item}/>))}
      </Grid>
    </Grid>);
};
export default Jobs;
