export var ServiceDrawerName;
(function (ServiceDrawerName) {
    ServiceDrawerName[ServiceDrawerName["removeRepair"] = 0] = "removeRepair";
    ServiceDrawerName[ServiceDrawerName["labor"] = 1] = "labor";
    ServiceDrawerName[ServiceDrawerName["addComponent"] = 2] = "addComponent";
    ServiceDrawerName[ServiceDrawerName["addSublet"] = 3] = "addSublet";
    ServiceDrawerName[ServiceDrawerName["part"] = 4] = "part";
    ServiceDrawerName[ServiceDrawerName["fluid"] = 5] = "fluid";
})(ServiceDrawerName || (ServiceDrawerName = {}));
export const defaultServiceDrawerState = {
    drawerName: undefined,
    adding: false,
    repair: undefined,
    repairId: undefined,
    part: undefined,
    fluid: undefined,
    isFitment: false,
    isRecommended: false,
    isCustom: false,
    hasInvoice: false,
};
