import { Button } from 'src/components/mui';
import React from 'react';
import { buttonStyleBase } from 'src/components/buttons/Buttons.styles';
import { colors } from 'src/styles/theme';
import styled, { css } from 'styled-components';
const DestructiveBaseStyle = css `
  background-color: ${colors.white};
  color: ${colors.error};
  border-color: ${colors.error};

  &:hover {
    background-color: ${colors.error};
    color: ${colors.white};
  }
`;
const StyledDestructiveButton = styled(Button) `
  ${buttonStyleBase};
  ${DestructiveBaseStyle};
`;
/**
 * Used for delete or destructive actions within SimpleModal. Meant to be used next to another button.
 */
const DestructiveButton = (props) => (<StyledDestructiveButton variant="outlined" {...props}/>);
export default DestructiveButton;
