var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CheckCircle as CheckIcon } from 'src/components/mui/icons';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { IntroContent, IntroCopy, IntroHeader, SuccessIconStyle, } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/AuthorizationScript.styles';
import { getEntry } from 'src/clients/contentfulClient';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import Config from 'src/config';
const AuthorizationSuccess = ({ onClose }) => {
    const [headerCopy, setHeaderCopy] = useState();
    const [scriptCopy, setScriptCopy] = useState();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield getEntry(Config.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_SUCCESS);
            setHeaderCopy(response.fields.header);
            setScriptCopy(response.fields.script);
        }))();
    }, []);
    return (<>
      <SimpleDrawerContent>
        <IntroContent>
          <CheckIcon style={SuccessIconStyle} fontSize="large"/>
          <IntroHeader>{headerCopy}</IntroHeader>

          <IntroCopy>
            <ReactMarkdown>{scriptCopy}</ReactMarkdown>
          </IntroCopy>
        </IntroContent>
      </SimpleDrawerContent>

      <SimpleDrawerFooter>
        <FooterActionButton onClick={onClose}>Continue</FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default AuthorizationSuccess;
