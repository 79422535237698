import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
import { AccessAlarm, AddCircle } from 'src/components/mui/icons';
import React from 'react';
import ExtraTimeReasonModal from 'src/AdminApp/containers/requests/StopwatchBar/ExtraTimeReasonModal';
import ExtraMinutesModal from 'src/AdminApp/containers/requests/StopwatchBar/ExtraMinutesModal';
import { Icon } from 'src/components/mui';
import useStopwatchBar from './useStopwatchBar';
const StopwatchContainer = styled.div `
  background-color: ${colors.GRAY_DE};
  color: ${colors.black};
  height: 34px;
  width: 100%;
  position: fixed;
  top: 56px;
  z-index: 20;
  left: 0;
  display: flex;
  justify-content: flex-end;
  font-family: ${FONT_FAMILY};
  font-weight: bold;
  line-height: 1px;
`;
const StopwatchBarSection = styled.div `
  flex-grow: 0.3333;
  display: flex;
  align-items: center;
  width: 0;
`;
/*
  This component will only render under /admin/repairs/${referenceNum} url
 */
const StopwatchBar = () => {
    const { stopwatch, setIsExtraMinutesModalOpened, setIsExtraTimeReasonModalOpened, extraMinutes, extraTimeReason, timeLeft, isAddTimeDisabled, setExtraTimeReason, isExtraTimeReasonModalOpened, extraTimeReasonOptions, isExtraMinutesModalOpened, setExtraMinutes, onExtraTimeRequestSubmit, arrivalTimerMinutesRemaining, displayArrivalGracePeriod, formatMinutesForDisplay, isMobile, repairRequest, } = useStopwatchBar();
    const isFleetVisitRequest = !!(repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.visit);
    if (isFleetVisitRequest)
        return null;
    if (displayArrivalGracePeriod) {
        return (<StopwatchContainer>
        <StopwatchBarSection style={{
                justifyContent: 'center',
                width: '100%',
            }}>
          <Icon style={{ marginRight: '4px' }}>handshake</Icon>
          {formatMinutesForDisplay(arrivalTimerMinutesRemaining)}
        </StopwatchBarSection>
      </StopwatchContainer>);
    }
    else if (isMobile && stopwatch !== undefined && (stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning)) {
        return (<>
        <StopwatchContainer>
          <StopwatchBarSection style={{
                justifyContent: 'center',
            }}>
            <AccessAlarm fontSize="small"/>
            {timeLeft}
          </StopwatchBarSection>
          <StopwatchBarSection style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '16px',
            }} onClick={() => {
                if (isAddTimeDisabled ||
                    stopwatch === undefined ||
                    !(stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning)) {
                    return;
                }
                setExtraTimeReason(undefined);
                setIsExtraTimeReasonModalOpened(true);
            }}>
            <AddCircle fontSize="small"/>
            Add Time
          </StopwatchBarSection>
        </StopwatchContainer>
        <ExtraTimeReasonModal isOpen={isExtraTimeReasonModalOpened} setIsOpen={setIsExtraTimeReasonModalOpened} extraTimeReasons={extraTimeReasonOptions} onValueChange={setExtraTimeReason} disableSubmit={extraTimeReason === undefined} onSubmit={() => setIsExtraMinutesModalOpened(true)}/>
        <ExtraMinutesModal isOpen={isExtraMinutesModalOpened} setIsOpen={setIsExtraMinutesModalOpened} onValueChange={setExtraMinutes} disableSubmit={extraMinutes <= 0} onSubmit={onExtraTimeRequestSubmit} addingTime/>
      </>);
    }
    else {
        return null;
    }
};
export default StopwatchBar;
