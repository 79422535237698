var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthorizedPricingAdmin } from 'src/modules/auth/selectors';
import { removeCatalogManufacturer, saveCatalogManufacturer, saveManufacturer, updateCatalogManufacturer, updateManufacturer, } from 'src/PricingApp/modules/manufacturers/actions';
export var CatalogManufacturerDrawerMode;
(function (CatalogManufacturerDrawerMode) {
    CatalogManufacturerDrawerMode[CatalogManufacturerDrawerMode["CLOSED"] = 0] = "CLOSED";
    CatalogManufacturerDrawerMode[CatalogManufacturerDrawerMode["EDIT_CATALOG"] = 1] = "EDIT_CATALOG";
    CatalogManufacturerDrawerMode[CatalogManufacturerDrawerMode["ADD_CATALOG"] = 2] = "ADD_CATALOG";
    CatalogManufacturerDrawerMode[CatalogManufacturerDrawerMode["EDIT_MANUFACTURER"] = 3] = "EDIT_MANUFACTURER";
    CatalogManufacturerDrawerMode[CatalogManufacturerDrawerMode["ADD_MANUFACTURER"] = 4] = "ADD_MANUFACTURER";
})(CatalogManufacturerDrawerMode || (CatalogManufacturerDrawerMode = {}));
const useCatalogManufacturerDrawer = ({ catalogManufacturerDrawerMode, manufacturer, catalogManufacturer, onClose, }) => {
    const dispatch = useDispatch();
    const isPricingAdmin = useSelector(selectAuthorizedPricingAdmin);
    const getCatalogIdOrDefault = () => { var _a; return (_a = catalogManufacturer === null || catalogManufacturer === void 0 ? void 0 : catalogManufacturer.catalogId) !== null && _a !== void 0 ? _a : 2; };
    const getPriorityOrDefault = () => { var _a; return (_a = catalogManufacturer === null || catalogManufacturer === void 0 ? void 0 : catalogManufacturer.priority) !== null && _a !== void 0 ? _a : undefined; };
    const getManufacturerStatusOrDefault = () => { var _a; return (_a = manufacturer === null || manufacturer === void 0 ? void 0 : manufacturer.status) !== null && _a !== void 0 ? _a : 'ACTIVE'; };
    const getManufacturerNameOrDefault = () => { var _a; return (_a = manufacturer === null || manufacturer === void 0 ? void 0 : manufacturer.name) !== null && _a !== void 0 ? _a : ''; };
    const [catalogId, setCatalogId] = useState(getCatalogIdOrDefault());
    const [priority, setPriority] = useState(getPriorityOrDefault());
    const [isPriorityDirty, setPriorityDirty] = useState(false);
    const [manufacturerName, setManufacturerName] = useState(getManufacturerNameOrDefault());
    const [isManufacturerNameDirty, setManufacturerNameDirty] = useState(false);
    const [manufacturerStatus, setManufacturerStatus] = useState(getManufacturerStatusOrDefault());
    const [removeCatalogModalIsOpen, setRemoveCatalogModalIsOpen] = useState(false);
    useEffect(() => {
        setCatalogId(getCatalogIdOrDefault());
        setPriority(getPriorityOrDefault());
        setManufacturerName(getManufacturerNameOrDefault());
        setManufacturerStatus(getManufacturerStatusOrDefault());
        setManufacturerNameDirty(false);
        setPriorityDirty(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogManufacturer, manufacturer]);
    const handleCatalogIdChange = (event) => setCatalogId(parseInt(event.target.value, 10));
    const handlePriorityChange = (event) => setPriority(+event.target.value);
    const handleManufacturerNameChange = (event) => setManufacturerName(event.target.value);
    const handleManufacturerStatusChange = (event) => setManufacturerStatus(event.target.value);
    const isManufacturerDisabled = () => catalogManufacturerDrawerMode === CatalogManufacturerDrawerMode.ADD_CATALOG;
    const isInvalidPriority = () => !priority || priority < 0;
    const isInvalidManufacturer = () => (manufacturerName === null || manufacturerName === void 0 ? void 0 : manufacturerName.length) === 0;
    const hasErrors = () => catalogManufacturerDrawerMode ===
        CatalogManufacturerDrawerMode.EDIT_MANUFACTURER
        ? isInvalidManufacturer()
        : isInvalidPriority() || isInvalidManufacturer();
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        switch (catalogManufacturerDrawerMode) {
            case CatalogManufacturerDrawerMode.ADD_CATALOG:
                if (manufacturer === null || manufacturer === void 0 ? void 0 : manufacturer.id) {
                    return dispatch(saveCatalogManufacturer({
                        manufacturerId: manufacturer.id,
                        catalogId,
                        priority,
                    }));
                }
                break;
            case CatalogManufacturerDrawerMode.EDIT_CATALOG:
                if (priority && (manufacturer === null || manufacturer === void 0 ? void 0 : manufacturer.id) && catalogManufacturer) {
                    dispatch(updateCatalogManufacturer({
                        id: catalogManufacturer.id,
                        manufacturerId: manufacturer.id,
                        catalogId,
                        priority,
                    }));
                    return dispatch(updateManufacturer({
                        id: manufacturer.id,
                        name: manufacturerName,
                        status: manufacturerStatus,
                    }));
                }
                break;
            case CatalogManufacturerDrawerMode.ADD_MANUFACTURER: {
                const response = yield dispatch(saveManufacturer({
                    name: manufacturerName,
                    status: manufacturerStatus,
                }));
                if (!(response === null || response === void 0 ? void 0 : response.error)) {
                    dispatch(saveCatalogManufacturer({
                        manufacturerId: (_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.id,
                        catalogId,
                        priority,
                    }));
                }
                return response;
            }
            case CatalogManufacturerDrawerMode.EDIT_MANUFACTURER:
                if (manufacturer === null || manufacturer === void 0 ? void 0 : manufacturer.id) {
                    return dispatch(updateManufacturer({
                        id: manufacturer.id,
                        name: manufacturerName,
                        status: manufacturerStatus,
                    }));
                }
                break;
            default:
        }
    });
    const handleSubmit = () => {
        handleSave().then((response) => !(response === null || response === void 0 ? void 0 : response.error) && setManufacturerName(''));
        onClose();
    };
    const handleRemoveCatalog = () => {
        if (!(manufacturer === null || manufacturer === void 0 ? void 0 : manufacturer.id) || !catalogManufacturer)
            return;
        dispatch(removeCatalogManufacturer(Object.assign(Object.assign({}, catalogManufacturer), { manufacturerId: manufacturer.id })));
        setRemoveCatalogModalIsOpen(false);
        onClose();
    };
    return {
        isPricingAdmin,
        getCatalogIdOrDefault,
        getPriorityOrDefault,
        getManufacturerStatusOrDefault,
        getManufacturerNameOrDefault,
        catalogId,
        setCatalogId,
        priority,
        setPriority,
        isPriorityDirty,
        setPriorityDirty,
        manufacturerName,
        setManufacturerName,
        isManufacturerNameDirty,
        setManufacturerNameDirty,
        manufacturerStatus,
        setManufacturerStatus,
        removeCatalogModalIsOpen,
        setRemoveCatalogModalIsOpen,
        handleCatalogIdChange,
        handlePriorityChange,
        handleManufacturerNameChange,
        handleManufacturerStatusChange,
        isManufacturerDisabled,
        isInvalidPriority,
        isInvalidManufacturer,
        hasErrors,
        handleSubmit,
        handleRemoveCatalog,
        handleSave,
    };
};
export default useCatalogManufacturerDrawer;
