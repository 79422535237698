import { Button } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
import styled, { css } from 'styled-components';
export const buttonStyleBase = css `
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.28px;
  border-radius: 8px;
  min-width: 150px;
  padding: 10px 16px;

  &:hover {
    background-color: ${colors.linkBlueHover};
    color: ${colors.white};
  }
`;
export const buttonStyle = css `
  ${buttonStyleBase};

  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;
export const actionButtonBase = css `
  ${buttonStyleBase};
  background-color: ${colors.linkBlue};
  color: ${colors.white};

  &:hover {
    background: ${colors.linkBlueHover} !important;
  }

  &.Mui-disabled {
    background: ${colors.lightGrey};
    color: rgba(118, 118, 118, 0.48) !important;
    box-shadow: none;
  }

  &.MuiButton-contained {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
  }

  .MuiButton-outlined {
    &.Mui-disabled {
      color: rgba(118, 118, 118, 0.24);
      border: 1px solid rgba(118, 118, 118, 0.24);
    }
  }
`;
export const ActionButton = styled(Button) `
  ${actionButtonBase};

  ${({ variant = 'contained' }) => {
    if (variant === 'outlined') {
        return css `
        background-color: ${colors.white};
        color: ${colors.linkBlue};
      `;
    }
}}
`;
