import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import { getInspectionByRepairRequestIdQuery, archiveInspectionsQuery, } from './queries';
// FSA ACTIONS
export const ActionCreators = {
    ARCHIVE_INSPECTION: createDefaultActionCreators('ARCHIVE_INSPECTION'),
    GET_INSPECTION_BY_REPAIR_REQUEST_ID: createDefaultActionCreators('GET_INSPECTION_BY_REPAIR_REQUEST_ID'),
    GET_INSPECTION_PERFORMED_STATUS: createDefaultActionCreators('GET_INSPECTION_PERFORMED_STATUS'),
    UPDATE_INSPECTION_PERFORMED_STATUS: createDefaultActionCreators('UPDATE_INSPECTION_PERFORMED_STATUS'),
};
export const getInspectionByRepairRequestId = (repairRequestId) => createAPIAction({
    types: [
        ActionCreators.GET_INSPECTION_BY_REPAIR_REQUEST_ID.FETCH(),
        ActionCreators.GET_INSPECTION_BY_REPAIR_REQUEST_ID.SUCCESS({
            meta: () => ({ repairRequestId }),
        }),
        ActionCreators.GET_INSPECTION_BY_REPAIR_REQUEST_ID.FAILURE(),
    ],
    endpoint: '/api/mpi-service/graphql',
    method: 'POST',
    body: getInspectionByRepairRequestIdQuery({ repairRequestId }),
});
export const archiveInspection = ({ referenceNum }) => createAPIAction({
    types: [
        ActionCreators.ARCHIVE_INSPECTION.FETCH(),
        ActionCreators.ARCHIVE_INSPECTION.SUCCESS(),
        ActionCreators.ARCHIVE_INSPECTION.FAILURE(),
    ],
    endpoint: '/api/mpi-service/graphql',
    method: 'POST',
    body: archiveInspectionsQuery({ referenceNum }),
});
export const getInspectionPerformedStatus = (repairRefNum) => createAPIAction({
    types: [
        ActionCreators.GET_INSPECTION_PERFORMED_STATUS.FETCH(),
        ActionCreators.GET_INSPECTION_PERFORMED_STATUS.SUCCESS(),
        ActionCreators.GET_INSPECTION_PERFORMED_STATUS.FAILURE(),
    ],
    endpoint: `/api/repair-service/admin/mpi/repair-requests/${repairRefNum}`,
    method: 'GET',
});
export const updateInspectionPerformedStatus = ({ repairRefNum, body, }) => createAPIAction({
    types: [
        ActionCreators.UPDATE_INSPECTION_PERFORMED_STATUS.FETCH(),
        ActionCreators.UPDATE_INSPECTION_PERFORMED_STATUS.SUCCESS(),
        ActionCreators.UPDATE_INSPECTION_PERFORMED_STATUS.FAILURE(),
    ],
    endpoint: `/api/repair-service/admin/mpi/repair-requests/${repairRefNum}`,
    method: 'PATCH',
    body,
});
