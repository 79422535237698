import styled from 'styled-components';
import { Button, Dialog, DialogActions, DialogContent, Alert, } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const FunnelDialog = styled(Dialog) ``;
export const FunnelDetailsDialogContent = styled(DialogContent) `
  display: flex;
  padding: 0;

  :first-child {
    padding-top: 0;
  }
`;
export const PartnerDetailsContainer = styled.section `
  width: 35%;
  border-right: solid 1px ${colors.GRAY_DE};
  padding: 32px 30px 0 20px;
`;
export const FunnelDetails = styled.section `
  width: 65%;
  padding: 32px 0 0 20px;
  background: ${colors.GRAY_FA};
  height: 755px;
  overflow-y: auto;
`;
export const FunnelSectionTitle = styled.h2 `
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .closeFunnelEditBtn {
    padding: 0;
  }
`;
export const FunnelPagesContainer = styled.section `
  margin-top: 25px;
`;
export const FunnelPagesTitle = styled.h3 `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.4px;
  color: ${colors.GRAY_3F};
`;
export const PagesList = styled.ol `
  padding-left: 0;
  list-style: none;
`;
export const FunnelPages = styled.section `
  margin-right: 20px;
`;
export const FunnelActions = styled(DialogActions) `
  padding: 14px 20px;
  border-top: solid 1px ${colors.GRAY_DE};
`;
export const FunnelAction = styled(Button) `
  width: 200px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
`;
export const PublishAlert = styled(Alert) `
  margin-top: 20px;
  margin-right: 20px;
`;
