import React from 'react';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import RepairsDashboardListItem from './RepairsDashboardListItem';
import RepairsDashboardSearch from './RepairsDashboardSearch';
import useRepairDashboard from './useRepairDashboard';
const REQUEST_COLUMNS = [
    { title: 'Id', key: 'id', sortable: true },
    { title: 'Repair Name', key: 'name', sortable: true },
    { title: 'Search Levels', key: 'searchLevel', sortable: false },
    { title: 'Repair Level', key: 'repairLevel', sortable: false },
    { title: 'Status', key: 'status', sortable: true },
    { title: 'Concierge', key: 'concierge', sortable: false },
    { title: 'Created Date', key: 'created', sortable: true },
];
const RepairsDashboard = () => {
    const { repairs, pagination, params, isFetching, onLoadData, onSearch } = useRepairDashboard();
    return (<>
      <RepairsDashboardSearch filters={params.filters} onSearch={onSearch}/>
      <SimpleTable data={repairs} pagination={pagination} sort={pagination.sort} isFetching={isFetching} columns={REQUEST_COLUMNS} renderItem={(item) => (<RepairsDashboardListItem item={item} key={item.id}/>)} onLoadData={onLoadData} onRowsPerPageChange={() => {
            // This is intentional
        }}/>
    </>);
};
export default RepairsDashboard;
