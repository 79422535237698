import styled, { css } from 'styled-components';
import { flexCenter } from 'src/styles/styled';
import { colors, theme } from 'src/styles/theme';
import { Button, Typography } from 'src/components/mui';
export const arrowStyles = css `
  position: absolute;
  top: 50%;
  z-index: 9;
`;
export const WrapperBoxPhoto = styled.label `
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: top;
  width: 50%;
`;
export const BoxPhoto = styled.section `
  align-items: center;
  border-radius: 4px;
  border: solid 3px #3e8ccf;
  display: flex;
  height: 100px;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 120px;

  svg {
    color: #3e8ccf;
    font-size: 35px;
  }
`;
export const Box = styled('div') `
  ${flexCenter};

  cursor: pointer;
`;
export const ProgressContainer = styled('div') `
  ${flexCenter};

  background-color: ${colors.white};
  border: inherit;
  height: inherit;
  position: absolute;
  width: inherit;
`;
export const Thumb = styled.div `
  display: inline-block;
  margin-bottom: 10px;
  width: 50%;

  img {
    height: 100px;
    object-fit: cover;
    width: 120px;
  }

  ${theme.breakpoints.up('md')} {
    &.additional {
      display: inline-flex;

      > button {
        align-self: flex-end;
      }
    }
  }
`;
export const Container = styled('div') `
  margin-top: 16px;
`;
export const Btn = styled(Button) `
  margin-top: 16px;
  padding: 16px 20px;
`;
export const DrawerWrapper = styled('div') `
  ${flexCenter};

  align-items: stretch;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  width: 600px;

  ${theme.breakpoints.down('md')} {
    width: 100vw;
  }
`;
export const DrawerContent = styled('div') `
  align-items: inherit;
  display: inherit;
  flex-direction: inherit;
  font-size: 16px;
  justify-content: inherit;
  letter-spacing: 0.5px;
  line-height: 1.25;
  overflow: hidden auto;
  padding: 26px 24px;

  ${theme.breakpoints.down('sm')} {
    padding: 16px 24px;
  }
`;
export const Footer = styled('div') `
  ${flexCenter};

  background-color: ${colors.GRAY_F8};
  height: 106px;

  ${theme.breakpoints.down('sm')} {
    height: 160px;
  }
`;
export const ImgContainer = styled('div') `
  ${flexCenter};

  justify-content: flex-start;

  button {
    align-self: flex-end;
  }

  ${theme.breakpoints.down('md')} {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    button {
      align-self: flex-start;
    }
  }
`;
export const ImgWrapper = styled(Button) `
  border-radius: 0;
  padding: 0;
`;
export const ModalContent = styled('div') `
  ${flexCenter};

  align-items: stretch;
  flex-direction: column;
  height: 120px;
  justify-content: space-between;
  padding: 16px 24px;
  position: relative;
  width: 360px;

  ${theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
`;
export const ModalHeader = styled(Typography) `
  font-size: 1.25rem;

  ${theme.breakpoints.down('sm')} {
    font-size: 1.125rem;
  }
`;
export const ModalBtns = styled('div') `
  align-self: flex-end;

  > button {
    font-size: 1rem;
    padding: 8px;
    width: 80px;

    :first-of-type {
      margin-right: 8px;
    }
  }
`;
export const SlideTitle = styled(Typography) `
  align-self: flex-start;
  padding-left: 16px;
  padding-top: 4px;
`;
export const ModalLoadingContainer = styled('div') `
  ${flexCenter};

  background-color: ${colors.white}99;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 9;
`;
