import React from 'react';
import { Button, Typography } from 'src/components/mui';
import { Warning } from 'src/components/mui/icons';
import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
const ResetTitle = styled(Typography) `
  color: ${colors.black};
  font-weight: bold;
  font-size: 2rem;
  margin-top: 10px;
  text-align: center;

  ${theme.breakpoints.up('md')} {
    display: block;
  }
`;
const CheckIcon = styled(Warning) `
  font-size: 3rem;
  color: ${colors.orangeSelected};
`;
const TitleContainer = styled('div') `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};
  padding-bottom: 30px;
`;
const ButtonContainer = styled('div') `
  padding-top: 30px;
  display: flex;
  justify-content: center;
`;
const ActionButton = styled(Button) `
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 2rem;
  margin-left: 20px;
  margin-right: 20px;
`;
const ResetParts = ({ onConfirmResetParts, onCancelResetParts, }) => {
    return (<div>
      <TitleContainer>
        <CheckIcon />
        <ResetTitle>Your appointment will be rescheduled!</ResetTitle>
        <p>
          You are changing the date of your appointment. Will we need to
          re-order the parts?
        </p>
      </TitleContainer>

      <ButtonContainer>
        <ActionButton onClick={onConfirmResetParts} variant="contained" color="primary" size="large">
          Yes
        </ActionButton>
        <ActionButton onClick={onCancelResetParts} variant="contained" color="primary" size="large">
          No
        </ActionButton>
      </ButtonContainer>
    </div>);
};
export default ResetParts;
