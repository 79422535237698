import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const SectionMain = styled.section `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding: 40px 0 32px;
  border-bottom: solid 1px rgba(118, 118, 118, 0.24);
`;
export const SectionInfo = styled.section `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 40px 38px;
  border-bottom: solid 1px rgba(118, 118, 118, 0.24);
`;
export const SectionText = styled.p `
  font-size: 12px;
  font-weight: 800;
  color: ${colors.GRAY_76};
  letter-spacing: -0.1px;
  padding: 0;
`;
export const SectionLink = styled.button `
  font-size: 12px;
  font-weight: 800;
  color: ${colors.blue3F};
  border: none;
  background: transparent;
  cursor: pointer;
`;
export const SectionInfoText = styled.p `
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${colors.GRAY_76};
`;
export const QRWrapper = styled.div `
  margin: 16px 0;
`;
