import styled from 'styled-components';
import { Colors } from 'src/PopsApp/modules/globalStyle';
export const TechsVanContainer = styled.div `
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${Colors.GRAY_CC};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;

  &.vanInfo {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px 0px 15px;
    border-right: 1px solid ${Colors.GRAY_CC};
    font-size: 10px;

    .info {
      display: flex;
      flex-direction: column;
      width: 140px;
    }

    .main-tech {
      white-space: nowrap;
      font-size: 12px;
    }

    .unavailability {
      position: relative;
    }

    .van {
      display: flex;
      align-items: center;
      margin-top: 8px;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
`;
export const TechsInfo = styled.div `
  display: flex;
  justify-content: start;

  .techs-more {
    color: ${Colors.GRAY_76};
    font-size: 12px;
    margin-left: 5px;
    white-space: nowrap;
  }
`;
export const Tooltiptitle = styled.div `
  white-space: pre;
`;
