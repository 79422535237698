import React from 'react';
import { Box, Button } from 'src/components/mui';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import DistributorModal from 'src/PopsApp/pages/Distributors/AddDistributorModal';
import DistributorHoursOfOperationDrawer from 'src/PopsApp/pages/Distributors/DistributorHoursOfOperationDrawer';
import DashboardListItem from './DashboardListItem';
import useDistributorsDashboard from './useDistributorsDashboard';
const REQUEST_COLUMNS = [
    { title: 'Name', key: 'name', sortable: true },
    { title: 'Status', key: 'status', sortable: true },
    { title: 'Modified', key: 'modified', sortable: true },
    { title: 'Edit', key: 'edit', sortable: false, textAlign: 'center' },
    {
        title: 'Manage Locations',
        key: 'locations',
        sortable: false,
        textAlign: 'center',
    },
    {
        title: 'Hours of Operation',
        key: 'hours_of_operation',
        sortable: false,
        textAlign: 'center',
    },
    {
        title: 'Delivery options',
        key: 'delivery_options',
        sortable: false,
        textAlign: 'center',
    },
];
const DistributorsDashboard = () => {
    const { distributors, pagination, isFetching, addDistributorDrawerOpen, setAddDistributorDrawerOpen, onLoadData, onEditDistributor, onSaveDistributor, onHoursEdit, selectedDistributor, hoursDrawerOpen, closeHoursOfOperationDrawer, } = useDistributorsDashboard();
    return (<>
      <Box m={1} display="flex" style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: '10px' }} onClick={() => setAddDistributorDrawerOpen(true)} variant="contained" color="primary">
          Add New Parts Distributor
        </Button>
      </Box>
      <DistributorModal open={addDistributorDrawerOpen} onClose={() => setAddDistributorDrawerOpen(false)} onSubmit={onSaveDistributor}/>
      <SimpleTable data={distributors} pagination={pagination} sort={pagination.sort} isFetching={isFetching} columns={REQUEST_COLUMNS} renderItem={(item, index) => (<DashboardListItem distributor={item} onRowSave={onEditDistributor} key={index} onHoursEdit={onHoursEdit}/>)} onLoadData={onLoadData}/>

      <DistributorHoursOfOperationDrawer distributor={selectedDistributor} open={hoursDrawerOpen} onClose={closeHoursOfOperationDrawer}/>
    </>);
};
export default DistributorsDashboard;
