import React from 'react';
import moment from 'moment';
import { Grid, CircularProgress, MobileDatePicker, Button, } from 'src/components/mui';
import { CalendarToday as CalendarTodayIcon } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import ContactSupport from 'src/AdminApp/components/contactSupport';
import MoveReturnedItemDrawer from 'src/PopsApp/pages/Inventory/components/MoveReturnedItemDrawer';
import AppointmentList from 'src/TechApp/components/AppointmentList';
import ScrollDatePicker from 'src/TechApp/components/ScrollDatePicker/Slider';
import useTechDashboard from 'src/TechApp/pages/useTechDashboard';
import { DateButton, Loader, Van } from './TechApp.styles';
const TechDashboard = ({ match }) => {
    var _a, _b, _c, _d;
    const { formattedDate, userId, technicianId, canViewAdminTech, technician, setOpen, open, setCalendarDate, calendarDate, setCurrentDateSlider, dashboard, isLoading, coresCount, returnItems, openMoveDrawer, handleMoveDrawerClose, handleMoveDrawerOpen, } = useTechDashboard({ match });
    if (!formattedDate)
        return null;
    return (<div id="tech-app">
      {userId === technicianId || canViewAdminTech ? (<>
          <Grid container direction="row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={12} md={6}>
              <h1>Hello, {(_a = technician[formattedDate]) === null || _a === void 0 ? void 0 : _a.firstName}!</h1>
            </Grid>
            {((_b = technician[formattedDate]) === null || _b === void 0 ? void 0 : _b.vanName) && (<Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
                <Van>
                  <img src="img/png/van.png" alt="van"/>
                  <div className="description">
                    <p>Your Van</p>
                    <h3>{(_c = technician[formattedDate]) === null || _c === void 0 ? void 0 : _c.vanName}</h3>
                    <Button variant="text" onClick={coresCount > 0 ? handleMoveDrawerOpen : () => { }}>
                      Cores ({coresCount})
                    </Button>
                  </div>
                </Van>
              </Grid>)}
          </Grid>
          <ContactSupport />

          <Grid container direction="row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <h2>Your Appointments</h2>

            <DateButton onClick={() => {
                setOpen(true);
            }}>
              <CalendarTodayIcon style={{ fontSize: '18px', color: colors.darkGrey }}/>
              Jump to Date
            </DateButton>
            <MobileDatePicker open={open} onChange={(date) => {
                setCalendarDate(moment(date));
                setOpen(false);
            }} onAccept={() => setOpen(false)} value={calendarDate} slotProps={{
                textField: {
                    component: () => null,
                },
                actionBar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
            }}/>
          </Grid>
          <ScrollDatePicker currentDate={calendarDate} setCurrentDate={setCurrentDateSlider}/>
          <AppointmentList dashboardAppointments={dashboard.dashboard} techName={(_d = technician[formattedDate]) === null || _d === void 0 ? void 0 : _d.firstName}/>
          {isLoading && (<Loader>
              <CircularProgress />
            </Loader>)}
        </>) : (<div>
          ERROR - Not Authorized: You must have the Tech_Dashboard_Viewer role
          to access this item or be a Technician in their own dashboard.
        </div>)}

      <MoveReturnedItemDrawer open={openMoveDrawer} onClose={handleMoveDrawerClose} returnedItems={returnItems}/>
    </div>);
};
export default TechDashboard;
