import React from 'react';
import { Grid } from 'src/components/mui';
import { formatRepairs } from 'src/AdminApp/components/repairs/statement/utils';
import Diagnoses from 'src/AdminApp/components/repairs/statement/Diagnoses';
import Accordion from './Accordion';
import RemovedRepairsDisplay from './RemovedRepairsDisplay';
const RemovedRepairs = ({ diagnosis }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { knownRepairs: removedRepairs, diagnoses: removedDiagnoses, customRequests: removedCustomRepairs, removedComponents, removedCustomComponents, } = formatRepairs((_b = (_a = diagnosis.diagnosisServices) === null || _a === void 0 ? void 0 : _a.removedRepairs) !== null && _b !== void 0 ? _b : [], (_d = (_c = diagnosis.diagnosisServices) === null || _c === void 0 ? void 0 : _c.removedDiagnoses) !== null && _d !== void 0 ? _d : [], (_f = (_e = diagnosis.diagnosisServices) === null || _e === void 0 ? void 0 : _e.removedCustomRequests) !== null && _f !== void 0 ? _f : [], (_h = (_g = diagnosis.diagnosisServices) === null || _g === void 0 ? void 0 : _g.removedComponents) !== null && _h !== void 0 ? _h : [], (_k = (_j = diagnosis.diagnosisServices) === null || _j === void 0 ? void 0 : _j.removedCustomComponents) !== null && _k !== void 0 ? _k : []);
    const removedArr = [
        ...removedRepairs,
        ...removedCustomRepairs,
        ...removedDiagnoses,
        ...removedComponents,
        ...removedCustomComponents,
    ].filter((removed) => removed.modifiedBy !==
        'MIGRATION|PL-4197|2024_07_18-PL-4197-update-any-ACTIVE-or-REMOVED-repairs-associated-with-those-car_diagnosis-records-to-be-REMOVED.sql');
    return removedArr.length > 0 ? (<Grid container>
      <Accordion>
        <RemovedRepairsDisplay repairs={removedRepairs}/>
        <Diagnoses repairs={removedDiagnoses}/>
        <RemovedRepairsDisplay repairs={removedCustomRepairs}/>
        <RemovedRepairsDisplay repairs={removedComponents} partial/>
        <RemovedRepairsDisplay repairs={removedCustomComponents} partial/>
      </Accordion>
    </Grid>) : null;
};
export default RemovedRepairs;
