import styled from 'styled-components';
import { MenuItem, Button } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const Item = styled(MenuItem) `
  font-size: 16px;
  padding: 8px 16px;

  &:first-of-type {
    margin-top: 12px;
  }

  &:last-of-type {
    margin-bottom: 8px;
  }

  span {
    margin-right: 8px;
  }
`;
export const Actions = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  min-width: 230px;

  border-top: solid 1px ${colors.GRAY_CC};
  padding: 8px 4px;
`;
export const ActionButton = styled(Button) `
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
  color: ${({ isMain }) => (isMain ? colors.blueButton : colors.black)};
`;
