import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    locked: false,
    unlockReasons: [],
};
const updateRequestLockState = (state, action) => {
    return Object.assign(Object.assign({}, state), action.payload);
};
const reducers = handleActions({
    [ActionCreators.GET_REQUEST_LOCK.SUCCESS.type]: updateRequestLockState,
    [ActionCreators.UPDATE_REQUEST_LOCK.SUCCESS.type]: updateRequestLockState,
}, DEFAULT_STATE);
export default reducers;
