import styled from 'styled-components';
import { Button } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const RemoveRoButton = styled(Button) `
  color: ${colors.RED_DARK};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: right;
  padding: 0;
`;
