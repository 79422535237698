import React from 'react';
import { Grid, TextField } from 'src/components/mui';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { ArrowRightAlt } from 'src/components/mui/icons';
const FunnelPageCardContainer = styled.section `
  padding: 16px 21px 26px 19px;
  border-radius: 4px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08);
  background-color: ${colors.white};
  position: relative;

  :first-child {
    margin-top: 23px;
  }

  :not(:first-child) {
    margin-top: 40px;
  }

  :last-child {
    margin-bottom: 75px;

    .arrowConnector {
      display: none;
    }
  }
`;
const ArrowConnector = styled(ArrowRightAlt) `
  position: absolute;
  transform: rotate(90deg) scale(2);
  bottom: -32px;
  left: 48%;
  color: ${colors.black_001};
`;
const PageName = styled.h4 `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.4px;
  color: ${colors.GRAY_3F};
  margin: 0;
`;
const FunnelInput = styled(TextField) `
  margin-top: 24px;
`;
const FunnelPageCard = ({ page, idx, formik, disabled, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    return (<FunnelPageCardContainer>
      <PageName>{page.name}</PageName>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FunnelInput variant="outlined" name={`pages[${idx}].header`} label="Main Header" fullWidth value={(_a = formik.values.pages[idx].header) !== null && _a !== void 0 ? _a : ''} onChange={formik.handleChange} error={formik.touched.pages &&
            formik.errors.pages &&
            ((_b = formik.touched.pages[idx]) === null || _b === void 0 ? void 0 : _b.header) &&
            Boolean((_c = formik.errors.pages[idx]) === null || _c === void 0 ? void 0 : _c.header)} helperText={formik.touched.pages &&
            formik.errors.pages &&
            ((_d = formik.touched.pages[idx]) === null || _d === void 0 ? void 0 : _d.header) &&
            ((_e = formik.errors.pages[idx]) === null || _e === void 0 ? void 0 : _e.header)} onBlur={formik.handleBlur} disabled={disabled}/>
        </Grid>

        {formik.values.pages[idx].path === '/car-info/details' &&
            !formik.values.startAtPlate && (<Grid item xs={12}>
              <FunnelInput variant="outlined" name={`pages[${idx}].subheader`} label="Sub Header" fullWidth value={(_f = formik.values.pages[idx].subheader) !== null && _f !== void 0 ? _f : ''} onChange={formik.handleChange} error={formik.touched.pages &&
                formik.errors.pages &&
                ((_g = formik.touched.pages[idx]) === null || _g === void 0 ? void 0 : _g.subheader) &&
                Boolean((_h = formik.errors.pages[idx]) === null || _h === void 0 ? void 0 : _h.subheader)} helperText={formik.touched.pages &&
                formik.errors.pages &&
                ((_j = formik.touched.pages[idx]) === null || _j === void 0 ? void 0 : _j.subheader) &&
                ((_k = formik.errors.pages[idx]) === null || _k === void 0 ? void 0 : _k.subheader)} onBlur={formik.handleBlur} disabled={disabled}/>
            </Grid>)}

        {formik.values.pages[idx].hasCta && (<Grid item xs={12}>
            <FunnelInput variant="outlined" name={`pages[${idx}].callToAction`} label="Call to Action" fullWidth value={(_l = formik.values.pages[idx].callToAction) !== null && _l !== void 0 ? _l : ''} onChange={formik.handleChange} error={formik.touched.pages &&
                formik.errors.pages &&
                ((_m = formik.touched.pages[idx]) === null || _m === void 0 ? void 0 : _m.callToAction) &&
                Boolean((_o = formik.errors.pages[idx]) === null || _o === void 0 ? void 0 : _o.callToAction)} helperText={formik.touched.pages &&
                formik.errors.pages &&
                ((_p = formik.touched.pages[idx]) === null || _p === void 0 ? void 0 : _p.callToAction) &&
                ((_q = formik.errors.pages[idx]) === null || _q === void 0 ? void 0 : _q.callToAction)} onBlur={formik.handleBlur} disabled={disabled}/>
          </Grid>)}
      </Grid>

      <ArrowConnector className="arrowConnector"/>
    </FunnelPageCardContainer>);
};
export default FunnelPageCard;
