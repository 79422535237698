import styled from 'styled-components';
import { Typography } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import { rowStyles } from 'src/components/layout/SimpleRowButton';
import { flexCenter } from 'src/styles/styled';
export const Row = styled('div') `
  ${rowStyles};
  justify-content: space-between;
`;
export const AuthText = styled(Typography) `
  color: ${colors.warning};
`;
export const AmountText = styled(Typography) `
  color: ${colors.warning};
  font-weight: 500;
`;
export const AmountContainer = styled('div') `
  ${flexCenter};
  width: auto;
  ${AmountText} {
    margin-right: 4px;
  }
`;
export const RepairAuthorizationSection = styled.div `
  border-top: 1px solid ${colors.lightGrey};
  padding: 18px 0;
`;
export const RepairAuthorizationTitle = styled.div `
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`;
export const AuthorizationRepair = styled.div `
  width: 100%;
  display: flex;
  flex-flow: row;
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: space-between;
  padding: 8px 0;
  align-items: center;
`;
