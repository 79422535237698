import React from 'react';
import Van from './Van';
const TechsVanInfo = ({ region, vans, openUnavailabilityDrawer, }) => {
    return (<div className="region-container">
      <div className="region-name">
        <span>{region.regionName.toUpperCase()}</span>
        <div className="separator">&nbsp;</div>
      </div>
      <div className="region-content-wrapper">
        {vans.map((v) => {
            const techs = v.assignedTechResources
                .filter((data) => data.active)
                .map((tech) => tech.name);
            return (<Van van={v} techs={techs} openUnavailabilityDrawer={openUnavailabilityDrawer}/>);
        })}
      </div>
    </div>);
};
export default TechsVanInfo;
