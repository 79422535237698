import styled from 'styled-components';
import { theme, colors } from 'src/styles/theme';
const Container = styled.button `
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
  background-color: ${colors.BLUE};
  font-size: 16px;
  color: ${colors.white};
  font-weight: 700;
  padding: 16px 0;
  width: 163px;
  border: none;
  cursor: pointer;

  ${theme.breakpoints.up('md')} {
    width: 144px;
  }

  :disabled {
    background-color: ${colors.GRAY_DE};
    color: ${colors.GRAY_76};
    box-shadow: none;
  }
`;
export default Container;
