import React from 'react';
import { useSelector } from 'react-redux';
import { selectFees } from 'src/AdminApp/modules/marketingSources/selectors';
import { SimpleDrawer, SimpleDrawerContent, SimpleDrawerHeader, SimpleDrawerFooter, FooterActionButton, } from 'src/components/SimpleDrawer';
import { Grid, TextField, MenuItem, Select, ListItemText, OutlinedInput, InputLabel, FormControl, } from 'src/components/mui';
import { Checkbox } from 'src/components/SimpleCheckbox';
import useMarketingSourcesDrawer from './useMarketingSourcesDrawer';
const MarketingSourcesDrawer = ({ open, onClose, marketingSourceId, }) => {
    var _a, _b;
    const drawerHeader = (marketingSourceId === null || marketingSourceId === void 0 ? void 0 : marketingSourceId.id)
        ? 'Edit Marketing Source'
        : 'Add Marketing Source';
    const fees = useSelector(selectFees);
    const { handleChange, handleSubmit, marketingSource, loading, isSubmitting } = useMarketingSourcesDrawer({
        marketingSourceId,
        onClose,
        open,
    });
    const isDisabled = isSubmitting || !(marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.displayName);
    const values = ['', 'Public', 'Admin'];
    return (<SimpleDrawer open={open} onClose={onClose} size="oneThird" isLoading={loading}>
      <SimpleDrawerHeader onClose={onClose} title={drawerHeader}/>
      <SimpleDrawerContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField id="displayName" name="displayName" label="Display Name" variant="outlined" fullWidth InputLabelProps={{
            shrink: true,
        }} onChange={(e) => handleChange('displayName', e.target.value)} value={marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.displayName}/>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="ms-visibility-label">Visibility</InputLabel>
              <Select labelId="ms-visibility-label" id="visibility" name="visibility" fullWidth multiple input={<OutlinedInput label="Visibility" placeholder="None"/>} onChange={(e) => handleChange('visibility', e.target.value)} value={((_a = marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.visibility) !== null && _a !== void 0 ? _a : [])} renderValue={(selected) => {
            const s = typeof selected === 'string'
                ? selected.split(',')
                : selected;
            return s.map((value) => values[Number(value)]).join(', ');
        }}>
                {values.map((value, index) => {
            var _a;
            if (index < 1)
                return null;
            return (<MenuItem key={value} value={index}>
                      <Checkbox checked={(_a = marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.visibility) === null || _a === void 0 ? void 0 : _a.includes(index)}/>
                      <ListItemText primary={value}/>
                    </MenuItem>);
        })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="preventFeeCodes-label">
                Prevent Fee Codes
              </InputLabel>
              <Select id="preventFeeCodes" labelId="preventFeeCodes-label" name="preventFeeCodes" variant="outlined" fullWidth multiple input={<OutlinedInput label="Prevent Fee Codes"/>} onChange={(e) => handleChange('preventFeeCodes', e.target.value)} value={((_b = marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.preventFeeCodes) !== null && _b !== void 0 ? _b : [])} renderValue={(selected) => {
            return selected
                .map((value) => { var _a; return (_a = fees.find((f) => f.code === value)) === null || _a === void 0 ? void 0 : _a.name; })
                .join(', ');
        }}>
                {fees.map((fee) => {
            var _a;
            return (<MenuItem value={fee.code} key={fee.id}>
                    <Checkbox checked={(_a = marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.preventFeeCodes) === null || _a === void 0 ? void 0 : _a.includes(fee.code)}/>
                    <ListItemText primary={fee.name}/>
                  </MenuItem>);
        })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={handleSubmit} disabled={isDisabled}>
          Save
        </FooterActionButton>
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
export default MarketingSourcesDrawer;
