import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byId: {},
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            offset: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['id,asc'],
        },
        _isFetching: false,
    },
};
const successComponentByIdReducer = (state, action) => {
    const componentData = fetched(action.payload);
    const id = componentData === null || componentData === void 0 ? void 0 : componentData.id;
    return Object.assign(Object.assign({}, state), { byId: {
            [id]: componentData,
        } });
};
const fetchSearchComponentsReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params: {
                filters: params.filters,
            }, _isFetching: true }) });
};
const successSearchComponentsReducer = (state, action) => {
    var _a, _b, _c;
    const { content, totalPages, totalElements } = fetched(action.payload);
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { content, _isFetching: false, params: {
                filters: params.filters,
            }, pagination: {
                pageSize: (_a = params.pagination) === null || _a === void 0 ? void 0 : _a.size,
                totalElements,
                totalPages,
                pageNumber: (_b = params.pagination) === null || _b === void 0 ? void 0 : _b.page,
                sort: (_c = params.pagination) === null || _c === void 0 ? void 0 : _c.sort,
            } }) });
};
const reducers = handleActions({
    [ActionCreators.GET_COMPONENT_BY_ID.SUCCESS.type]: successComponentByIdReducer,
    [ActionCreators.SEARCH_COMPONENTS.FETCH.type]: fetchSearchComponentsReducer,
    [ActionCreators.SEARCH_COMPONENTS.SUCCESS.type]: successSearchComponentsReducer,
    [ActionCreators.SAVE_COMPONENT.SUCCESS.type]: successComponentByIdReducer,
    [ActionCreators.UPDATE_COMPONENT.SUCCESS.type]: successComponentByIdReducer,
}, DEFAULT_STATE);
export default reducers;
