import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const RepairOrders = styled.table `
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 28px 0;
    border-bottom: 1px solid ${colors.GRAY_DE};

    &:first-child {
      padding-left: 26px;
    }

    &:last-child {
      text-align: center;
    }
  }

  tr.added td {
    background: ${colors.GRAY_FA};
  }
`;
export const RoLabel = styled.div `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
`;
export const RoValue = styled.div `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.3px;
  margin-top: 4px;
`;
