import { Divider } from 'src/components/mui';
import { Directions as DirectionsIcon, Error as ErrorIcon, } from 'src/components/mui/icons';
import React from 'react';
import { STATIC_URL_BASE } from 'src/styles/theme';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { ContentInner, ContentText, ContentWrapper, IconWrapper, InfoItem, NewIcon, WhatsNextHeader, WhatsNextImg, WhatsNextSubheader, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
export const PartPickupWhatsNext = ({ onFinish }) => {
    return (<>
      <SimpleDrawerContent>
        <ContentWrapper>
          <WhatsNextHeader>
            <WhatsNextSubheader>
              Each of the missing parts will have a status icon next to it.
            </WhatsNextSubheader>
            <WhatsNextImg src={`${STATIC_URL_BASE}tools/speech-bubble.svg`} alt="speech bubble"/>
          </WhatsNextHeader>
        </ContentWrapper>
        <Divider />
        <ContentWrapper className="fullSpace">
          <ContentInner>
            <InfoItem>
              <IconWrapper>
                <ErrorIcon className="warning"/>
              </IconWrapper>
              <ContentText className="textWithIcon">
                <b>Standby:</b> A part specialist is working on it for you.
              </ContentText>
            </InfoItem>
            <InfoItem>
              <NewIcon>NEW</NewIcon>
              <ContentText className="textWithIcon">
                <b>New Part #:</b> At the same location ask counter for new part
                number.
              </ContentText>
            </InfoItem>
            <InfoItem>
              <IconWrapper>
                <DirectionsIcon className="primary"/>
              </IconWrapper>
              <ContentText className="textWithIcon">
                <b>Different Location:</b> You’re done here, we’re sending you
                to another location.
              </ContentText>
            </InfoItem>
          </ContentInner>
        </ContentWrapper>
        <Divider />
        <ContentWrapper>
          <ContentText style={{ padding: '0 1rem' }}>
            At any time you can tap any of the icons and there will be an
            explanation of each status.
          </ContentText>
        </ContentWrapper>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={onFinish}>Got it</FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
