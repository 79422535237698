import styled from 'styled-components';
import { Grid, Typography } from 'src/components/mui';
import { Link } from 'react-router-dom';
import { colors } from 'src/styles/theme';
import { ChevronRight as ChevronRightIcon } from 'src/components/mui/icons';
export const Logo = styled.img `
  max-height: 32px;
  max-width: 32px;
  margin-right: 8px;
`;
export const Wrapper = styled(Typography) `
  border-bottom: 1px solid ${colors.lightGrey};
  padding-bottom: 24px;
`;
export const ConsumerId = styled(Link) `
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  color: ${colors.middleGrey};
  margin-left: 8px;
  text-decoration: none;
`;
export const RequestInfoDisplayText = styled(Typography) `
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  color: ${colors.default};
`;
export const RequestInfoDisplayLink = styled(Link) `
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  color: ${colors.default};
  text-decoration: none;
`;
export const VehicleInfo = styled(Typography) `
  font-weight: bold;
`;
export const RequestInfo = styled(Grid) `
  margin-bottom: 8px;
`;
export const StyledChevronRightIcon = styled(ChevronRightIcon) `
  margin-left: auto;
  color: ${colors.iconGrey};
`;
export const StyledConsumerTag = styled(Typography) `
  text-transform: uppercase;
  color: ${colors.white};
  background-color: ${colors.secondary};
  padding: 2px 15px;
`;
