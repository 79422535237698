import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { Grid } from 'src/components/mui';
import { CalculatedTotalCost, Dots, StyledEditIcon, Title, PartSecondaryInfo, } from 'src/AdminApp/components/repairs/statement/styledComponents';
import CurrencyLabel from 'src/components/CurrencyLabel';
import { useSelector } from 'react-redux';
import { selectAuthorizedPricingAdmin } from 'src/modules/auth/selectors';
const Labor = ({ price, onEdit, canEdit }) => {
    var _a, _b, _c;
    const laborCost = (_a = price === null || price === void 0 ? void 0 : price.laborCost) !== null && _a !== void 0 ? _a : null;
    const laborRate = (_b = price === null || price === void 0 ? void 0 : price.laborRate) !== null && _b !== void 0 ? _b : null;
    const laborTime = (_c = price === null || price === void 0 ? void 0 : price.laborTime) !== null && _c !== void 0 ? _c : null;
    const isPricingAdmin = useSelector(selectAuthorizedPricingAdmin);
    return (<Grid container>
      <Grid container>
        <Title>Labor Cost</Title>
        {onEdit &&
            canEdit &&
            (!price.preventLaborEditing || isPricingAdmin) && (<StyledEditIcon onClick={onEdit}/>)}
        {!isNull(laborCost) && <Dots />}
        {!isNull(laborCost) && <CalculatedTotalCost values={laborCost}/>}
      </Grid>
      {!isNull(laborRate) && (<PartSecondaryInfo>
          {`${!(isNull(laborTime) || isUndefined(laborTime))
                ? laborTime
                : price.minLaborTime} h`}{' '}
          @ <CurrencyLabel>{laborRate}</CurrencyLabel>/h
        </PartSecondaryInfo>)}
    </Grid>);
};
export default Labor;
