import { HeyTechCopy, HeyTechWrapper } from 'src/components/layout/HeyTech';
import { Error as ErrorIcon } from 'src/components/mui/icons';
import React from 'react';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { ErrorIconStyle, MissingPartsDivider, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
import usePartPickupSomethingMissing from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupSomethingMissing/usePartPickupSomethingMissing';
export const PartPickupSomethingMissing = ({ onFinish, }) => {
    const { isSubmitting, handleOk } = usePartPickupSomethingMissing({
        onFinish,
    });
    return (<>
      <SimpleDrawerContent>
        <HeyTechWrapper>
          <ErrorIcon style={ErrorIconStyle}/>
          <HeyTechCopy>
            It looks like there’s some parts you didn’t get. If so they will be
            re-submitted to the parts team queue at a high priority
          </HeyTechCopy>
        </HeyTechWrapper>
        <MissingPartsDivider />
      </SimpleDrawerContent>

      <SimpleDrawerFooter>
        <FooterActionButton onClick={handleOk} isSubmitting={isSubmitting} disabled={isSubmitting}>
          Okay
        </FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default PartPickupSomethingMissing;
