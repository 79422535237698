import React from 'react';
import styled from 'styled-components';
import { formatTimestamp } from 'src/utils/formatter';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
const InfoWrapper = styled.div `
  border-top: 1px solid lightgray;
  text-align: right;
`;
const InfoStatus = ({ provided, providedBy, }) => (<InfoWrapper>
    {provided && providedBy && (<>
        Last Provided: {formatTimestamp(provided)} by{' '}
        <AdminUser subText={false} updatedUser={providedBy}/>
      </>)}
  </InfoWrapper>);
export default InfoStatus;
