import { MobileTimePicker } from 'src/components/mui';
import { TooltipInfo } from 'src/components/tooltips';
import moment from 'moment';
import React from 'react';
import { Block, BlockNotes, BlockTitle, editInputStyle, FieldLabel, FieldLabelNotes, FieldRow, FieldRowNotes, FieldValue, FieldValueNotes, InternalNotesContainer, labelStyle, requireMultiStoreStyle, Toggle, valueStyle, variantMap, } from 'src/AdminApp/containers/fleets/tabs/styles';
import { ActionButton } from 'src/components/buttons';
import { Editable } from 'src/components/form/components';
import EditableInput from 'src/components/form/EditableInput';
import EditableSelect from 'src/components/form/EditableSelect';
import { SimpleModal } from 'src/components/layout';
import { RowWrapper } from 'src/components/SimpleTwoButtonRow';
import { TIME_12H_FORMAT } from 'src/timeConstants';
import useSettings from './useSettings';
const selectStyle = {
    width: '100%',
};
const dialogContent = 'This fleet has children associated with it. Would you like to save these settings to those fleets?';
const Settings = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { fleet, isConfirmFieldModalOpen, setIsConfirmFieldModalOpen, setPendingField, setPendingFieldValue, dialogTitle, setDialogTitle, showCapacityStationWarning, openPicker, tempRequireMultiStore, enableRequireMultiStore, isComponentMarkupEditor, partyTypes, getPartDistributors, handleUpdateField, handleUpdatePartDistributor, handleUpdateInternalNotes, handleToggle, handleSubmitVisitPadding, handleVisitPaddingUpdate, closeCapacityWarning, handleSaveField, handleClosePicker, handleOpenPicker, handleTimeChange, handleUpdateComponentMarketField, handleUpdateEngineOilLimit, handleUpdateAdditionalQuartPrice, handleUpdateInvoiceAutoSend, } = useSettings();
    const autoIntegrateSettingEditable = ((_a = fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) === null || _a === void 0 ? void 0 : _a.find((fmc) => { var _a; return ((_a = fmc === null || fmc === void 0 ? void 0 : fmc.fmcDto) === null || _a === void 0 ? void 0 : _a.submissionMethod) === 'AUTO_INTEGRATE'; })) !== null;
    if (!fleet)
        return null;
    const laborRate = fleet.laborRate ? `$ ${fleet.laborRate}/hr` : 'Default';
    const additionalQuartPrice = (fleet === null || fleet === void 0 ? void 0 : fleet.additionalQuartPrice)
        ? `$ ${fleet.additionalQuartPrice}/qt`
        : '';
    const engineOilLimit = (fleet === null || fleet === void 0 ? void 0 : fleet.engineOilLimit)
        ? `${fleet.engineOilLimit} qts`
        : '';
    const start = moment((_b = fleet.schedulingWindow) === null || _b === void 0 ? void 0 : _b.start, TIME_12H_FORMAT);
    const end = moment((_c = fleet.schedulingWindow) === null || _c === void 0 ? void 0 : _c.end, TIME_12H_FORMAT);
    const autoIntegrateId = (fleet === null || fleet === void 0 ? void 0 : fleet.autoIntegrateId) ? fleet === null || fleet === void 0 ? void 0 : fleet.autoIntegrateId : '';
    const isFMCPay = fleet.responsibleParty &&
        fleet.responsibleParty.responsiblePartyTypeId &&
        (partyTypes === null || partyTypes === void 0 ? void 0 : partyTypes.find((type) => {
            var _a;
            return type.id === ((_a = fleet.responsibleParty) === null || _a === void 0 ? void 0 : _a.responsiblePartyTypeId) &&
                type.name.toLocaleLowerCase() === 'fmc pay';
        }));
    const showAutoSendSchedule = !!fleet.responsibleParty &&
        (!fleet.responsibleParty.submissionMethod ||
            ((_d = fleet.responsibleParty.submissionMethod) === null || _d === void 0 ? void 0 : _d.toLocaleLowerCase()) ===
                'phone' ||
            !isFMCPay);
    return (<InternalNotesContainer>
      <Block>
        <BlockTitle variant="subtitle2">Settings</BlockTitle>
        <FieldRow>
          <FieldLabel style={labelStyle} variant="subtitle1">
            Labor Rate
          </FieldLabel>
          <FieldValue style={valueStyle} variantMapping={variantMap} variant="body1">
            <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.laborRate} onUpdate={handleUpdateField('laborRate')}>
              {laborRate}
            </EditableInput>
          </FieldValue>
        </FieldRow>
        {isComponentMarkupEditor && (<FieldRow>
            <FieldLabel style={labelStyle} variant="subtitle1">
              Fixed Component Markup
            </FieldLabel>
            <FieldValue style={valueStyle} variantMapping={variantMap} variant="body1">
              <EditableInput style={editInputStyle} value={fleet.componentMarkup} onUpdate={handleUpdateComponentMarketField} inputProps={{ type: 'number' }} defaultValue="Default"/>
            </FieldValue>
          </FieldRow>)}

        <FieldRow>
          <FieldLabel style={labelStyle} variant="subtitle1">
            Part Pricing Source
          </FieldLabel>
          <FieldValue variant="body1" variantMapping={variantMap} style={valueStyle}>
            <EditableSelect options={getPartDistributors()} selectStyle={selectStyle} style={editInputStyle} unsetOption unsetOptionText="Default" value={(_e = fleet.pricingPartDistributorId) !== null && _e !== void 0 ? _e : 'Default'} onUpdate={handleUpdatePartDistributor}>
              {(_f = fleet === null || fleet === void 0 ? void 0 : fleet.pricingPartDistributorId) !== null && _f !== void 0 ? _f : 'Default'}
            </EditableSelect>
          </FieldValue>
        </FieldRow>

        <FieldRow>
          <FieldLabel style={labelStyle} variant="subtitle1">
            Visit Padding Amount{' '}
            <TooltipInfo>Max padding length is 120 mins/2hrs</TooltipInfo>
          </FieldLabel>
          <FieldValue style={valueStyle} variantMapping={variantMap} variant="body1">
            <EditableSelect options={[
            { label: <>00:15</>, value: '15' },
            { label: <>00:30</>, value: '30' },
            { label: <>00:45</>, value: '45' },
            { label: <>01:00</>, value: '60' },
            { label: <>01:15</>, value: '75' },
            { label: <>01:30</>, value: '90' },
            { label: <>01:45</>, value: '105' },
            { label: <>02:00</>, value: '120' },
        ]} selectStyle={selectStyle} style={editInputStyle} unsetOption unsetOptionText="None" value={(_h = (_g = fleet.visitPaddingMinutes) === null || _g === void 0 ? void 0 : _g.toString()) !== null && _h !== void 0 ? _h : 'None'} onUpdate={handleVisitPaddingUpdate}>
              {(_j = fleet === null || fleet === void 0 ? void 0 : fleet.visitPaddingMinutes) !== null && _j !== void 0 ? _j : 'None'}
            </EditableSelect>
          </FieldValue>
        </FieldRow>

        <FieldRow>
          <FieldLabel style={labelStyle} variant="subtitle1">
            Scheduling Window Start
          </FieldLabel>
          <FieldValue style={valueStyle} variantMapping={variantMap} variant="body1">
            <Editable isEditable style={editInputStyle} onClick={handleOpenPicker('start')}>
              {(_k = fleet.schedulingWindow) === null || _k === void 0 ? void 0 : _k.start}
            </Editable>
          </FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel style={labelStyle} variant="subtitle1">
            Scheduling Window End
          </FieldLabel>
          <FieldValue style={valueStyle} variantMapping={variantMap} variant="body1">
            <Editable isEditable style={editInputStyle} onClick={handleOpenPicker('end')}>
              {(_l = fleet.schedulingWindow) === null || _l === void 0 ? void 0 : _l.end}
            </Editable>
          </FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel style={labelStyle} variant="subtitle1">
            Auto Integrate Id
          </FieldLabel>
          <FieldValue style={valueStyle} variantMapping={variantMap} variant="body1">
            <EditableInput style={editInputStyle} value={autoIntegrateId} onUpdate={handleUpdateField('autoIntegrateId', null, 'number')} isEditable={autoIntegrateSettingEditable}>
              {autoIntegrateId}
            </EditableInput>
          </FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel style={labelStyle} variant="subtitle1">
            Require Multi-Store
          </FieldLabel>
          <FieldValue style={requireMultiStoreStyle} variantMapping={variantMap} variant="body1">
            <Toggle checked={tempRequireMultiStore} color="primary" name="requireMultiStore" onChange={handleToggle} disabled={!enableRequireMultiStore}/>
          </FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel style={labelStyle} variant="subtitle1">
            Engine Oil Limit
          </FieldLabel>
          <FieldValue style={valueStyle} variantMapping={variantMap} variant="body1">
            <EditableInput style={editInputStyle} value={fleet.engineOilLimit ? String(fleet.engineOilLimit) : ''} onUpdate={handleUpdateEngineOilLimit} inputProps={{ type: 'number' }} defaultValue="">
              {engineOilLimit}
            </EditableInput>
          </FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel style={labelStyle} variant="subtitle1">
            Additional Quart Price
          </FieldLabel>
          <FieldValue style={valueStyle} variantMapping={variantMap} variant="body1">
            <EditableInput style={editInputStyle} value={fleet.additionalQuartPrice
            ? String(fleet.additionalQuartPrice)
            : ''} onUpdate={handleUpdateAdditionalQuartPrice} inputProps={{ type: 'number' }}>
              {additionalQuartPrice}
            </EditableInput>
          </FieldValue>
        </FieldRow>
        {showAutoSendSchedule && (<FieldRow>
            <FieldLabel style={labelStyle} variant="subtitle1">
              Invoice Auto-Send Schedule
            </FieldLabel>
            <FieldValue style={valueStyle} variantMapping={variantMap} variant="body1">
              <EditableSelect options={[
                { label: <>Daily</>, value: 'DAILY' },
                { label: <>Weekly</>, value: 'WEEKLY' },
                { label: <>Monthly</>, value: 'MONTHLY' },
            ]} selectStyle={selectStyle} style={editInputStyle} value={(_o = (_m = fleet.responsibleParty) === null || _m === void 0 ? void 0 : _m.invoiceAutosendSchedule) !== null && _o !== void 0 ? _o : ''} unsetOptionText="None" onUpdate={(v) => {
                handleUpdateInvoiceAutoSend(v);
            }}>
                {(_p = fleet === null || fleet === void 0 ? void 0 : fleet.visitPaddingMinutes) !== null && _p !== void 0 ? _p : 'None'}
              </EditableSelect>
            </FieldValue>
          </FieldRow>)}
      </Block>
      <BlockNotes>
        <FieldRowNotes>
          <FieldLabelNotes variant="subtitle1">
            Fleet Instructions
          </FieldLabelNotes>
          <FieldValueNotes variantMapping={variantMap} variant="body1">
            <EditableInput inputProps={{
            multiline: true,
            rows: 7,
            variant: 'outlined',
            InputProps: {
                type: 'text',
            },
        }} breakLines isDrawerOnMobile drawerTitle="Internal Notes" style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.internalNotes} onUpdate={handleUpdateInternalNotes}>
              {fleet === null || fleet === void 0 ? void 0 : fleet.internalNotes}
            </EditableInput>
          </FieldValueNotes>
        </FieldRowNotes>
      </BlockNotes>
      <MobileTimePicker open={openPicker.start} value={start} onAccept={(time) => handleTimeChange('start')(time)} onClose={handleClosePicker('start')} slotProps={{
            textField: {
                component: () => null,
            },
        }}/>
      <MobileTimePicker open={openPicker.end} value={end} onAccept={(time) => handleTimeChange('end')(time)} onClose={handleClosePicker('end')} slotProps={{
            textField: {
                component: () => null,
            },
        }}/>

      <SimpleModal open={isConfirmFieldModalOpen} title={dialogTitle} onClose={() => {
            setDialogTitle(undefined);
            setPendingField(undefined);
            setPendingFieldValue(undefined);
            setIsConfirmFieldModalOpen(false);
        }} actionButtonsRow={<RowWrapper>
            <ActionButton onClick={handleSaveField(false)}>
              Save to this fleet
            </ActionButton>
            <ActionButton onClick={handleSaveField(true)}>
              Save to all fleets
            </ActionButton>
          </RowWrapper>}>
        {dialogContent}
      </SimpleModal>

      <SimpleModal open={showCapacityStationWarning} onClose={closeCapacityWarning} title={`Saving this will change the value for all DSPs under the station: ${fleet.station}`} simpleTwoButtonRowProps={{
            submitText: 'Ok',
            onSubmit: handleSubmitVisitPadding,
            onCancel: closeCapacityWarning,
        }}/>
    </InternalNotesContainer>);
};
export default Settings;
