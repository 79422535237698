import styled from 'styled-components';
import { flexCenter } from 'src/styles/styled';
export const Row = styled.div `
  ${flexCenter};

  height: auto;
  justify-content: stretch;
  min-height: 56px;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;
export const CheckboxRow = styled.div `
  > label {
    margin-left: -4px;
  }
`;
