import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
export const ActionCreators = {
    GET_REQUEST_LOCK: createDefaultActionCreators('GET_REQUEST_LOCK'),
    UPDATE_REQUEST_LOCK: createDefaultActionCreators('UPDATE_REQUEST_LOCK'),
};
export const getRequestLock = (repairRequestId) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_REQUEST_LOCK),
    endpoint: API_URLS.REQUEST_LOCK.GET_LOCK(repairRequestId),
    method: 'GET',
});
export const updateRequestLock = (requestBody) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_REQUEST_LOCK),
    endpoint: API_URLS.REQUEST_LOCK.UPDATE_LOCK(),
    method: 'POST',
    body: requestBody,
});
