import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'src/AdminApp/modules/users/selectors';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
import styled from 'styled-components';
export const HeyTechWrapper = styled.section `
  text-align: center;
  padding: 0 40px;
`;
export const HeyTechHeader = styled.h2 `
  margin: 8px 0 0;
`;
export const HeyTechCopy = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${colors.GRAY_76};
  margin: 16px 0 0;
`;
export const HeyTechImg = styled.img `
  height: 200px;
  width: 200px;
  margin-top: 60px;
  position: relative;
  left: 20px;
`;
const HeyTech = ({ header, copy }) => {
    var _a;
    const currentUser = useSelector(selectCurrentUser);
    return (<HeyTechWrapper>
      <HeyTechImg src={`${STATIC_URL_BASE}tools/guide-illustration.svg`} alt="person with speech bubble"/>
      <HeyTechHeader>
        {header !== null && header !== void 0 ? header : `Hey ${(_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName) !== null && _a !== void 0 ? _a : 'Tech'}!`}
      </HeyTechHeader>
      <HeyTechCopy>{copy}</HeyTechCopy>
    </HeyTechWrapper>);
};
export default HeyTech;
