import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { Button, Grid, TableRow } from 'src/components/mui';
export const ButtonRow = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const MeasurementItemContainer = styled(Grid) `
  border: 1px solid ${colors.middleGrey};
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 5px;
  padding: 30px 15px 30px 15px;
`;
export const AddButtonContainer = styled(Button) `
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #2c56dd;
  font-weight: bold;
  justify-content: flex-start;
`;
export const RemoveButton = styled(Button) `
  height: 100%;
`;
export const ActionButtonContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
`;
export const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  td {
    padding: 15px !important;
  }
`;
