var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { submitSendInvoiceRequests } from 'src/AdminApp/modules/invoiceWorkbench/actions';
import { selectItemsSelected } from 'src/AdminApp/modules/invoiceWorkbench/selectors';
import { selectAccessToken } from 'src/modules/auth/selectors';
import { splitAndTrimOn } from 'src/utils/data-utils';
const THIRD_PARTY_SUB_PORTALS = ['ARI', 'AUTO_INTEGRATE', 'LEASE_PLAN'];
const useInvoiceSendModal = ({ open, onClose, }) => {
    var _a, _b;
    const dispatch = useDispatch();
    const [currentResponsiblePartyIndex, setCurrentResponsiblePartyIndex] = useState(0);
    const [groupedItems, setGroupedItems] = useState({});
    const [responsibleParties, setResponsibleParties] = useState({});
    const [currentResponsibleParty, setCurrentResponsibleParty] = useState();
    const [showFinalScreen, setShowFinalScreen] = useState(false);
    const [currentGroup, setCurrentGroup] = useState();
    const [showEmail, setShowEmail] = useState(false);
    const [isExternalSubmissionPortal, setIsExternalSubmissionPortal] = useState(false);
    const [defaultEmailBody, setDefaultEmailBody] = useState();
    const itemsSelected = useSelector(selectItemsSelected);
    const accessToken = useSelector(selectAccessToken);
    useEffect(() => {
        var _a;
        if (!open || !itemsSelected)
            return;
        const rps = {};
        const grouped = itemsSelected.reduce((acc, item) => {
            var _a, _b;
            if (!acc[item.responsiblePartyId]) {
                acc[item.responsiblePartyId] = [];
                rps[item.responsiblePartyId] = {
                    id: item.responsiblePartyId,
                    name: item.responsibleParty,
                    submissionPortal: (_a = item.submissionPortal) !== null && _a !== void 0 ? _a : '',
                    billingContacts: (_b = item.billingContacts) !== null && _b !== void 0 ? _b : [],
                };
            }
            acc[item.responsiblePartyId].push(item);
            return acc;
        }, {});
        const currentGrp = Object.values(grouped)[currentResponsiblePartyIndex];
        const currentRP = (currentGrp === null || currentGrp === void 0 ? void 0 : currentGrp.length) > 0 ? rps[currentGrp[0].responsiblePartyId] : null;
        setIsExternalSubmissionPortal(THIRD_PARTY_SUB_PORTALS.includes((_a = currentRP.submissionPortal) !== null && _a !== void 0 ? _a : ''));
        setCurrentGroup(currentGrp);
        setGroupedItems(grouped);
        setResponsibleParties(rps);
        setCurrentResponsibleParty(currentRP);
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield fetch(API_URLS.ADMIN.DEFAULT_EMAIL_BODY(), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                setDefaultEmailBody(yield response.text());
            }
        }))();
    }, [open]);
    useEffect(() => {
        var _a;
        const currentGrp = Object.values(groupedItems)[currentResponsiblePartyIndex];
        const currentRP = (currentGrp === null || currentGrp === void 0 ? void 0 : currentGrp.length) > 0
            ? responsibleParties[currentGrp[0].responsiblePartyId]
            : null;
        if (currentRP) {
            setIsExternalSubmissionPortal(THIRD_PARTY_SUB_PORTALS.includes((_a = currentRP.submissionPortal) !== null && _a !== void 0 ? _a : ''));
            setCurrentGroup(currentGrp);
            setCurrentResponsibleParty(currentRP);
        }
    }, [currentResponsiblePartyIndex]);
    const sendAndNext = (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        const response = yield dispatch(submitSendInvoiceRequests({
            invoiceIds: currentGroup.map((item) => item.invoiceNum),
            referenceNums: currentGroup.map((item) => item.referenceNum),
            responsiblePartyId: currentResponsibleParty.id,
            recipients: showEmail || !isExternalSubmissionPortal
                ? splitAndTrimOn(values.billingContactsString, ',')
                : [],
            emailBody: !isExternalSubmissionPortal ? values.comments : null,
        }));
        if (response.error) {
            setSubmitting(false);
            return;
        }
        setSubmitting(false);
        // ARE WE AT THE END OF THE GROUPINGS?
        if (currentResponsiblePartyIndex === Object.keys(groupedItems).length - 1) {
            setShowFinalScreen(true);
            return;
        }
        setCurrentResponsiblePartyIndex((prev) => prev + 1);
    });
    const toggleShowEmail = (setFieldValue, resetForm) => {
        var _a, _b;
        const newShowEmail = !showEmail;
        if (newShowEmail) {
            setFieldValue('billingContactsString', (_b = (_a = currentResponsibleParty.billingContacts) === null || _a === void 0 ? void 0 : _a.join(', ')) !== null && _b !== void 0 ? _b : '');
        }
        else {
            resetForm();
        }
        setShowEmail(newShowEmail);
    };
    const handleFinalScreenClose = (clearSelected = true) => {
        setShowFinalScreen(false);
        setCurrentResponsiblePartyIndex(0);
        setCurrentGroup(undefined);
        setResponsibleParties({});
        setCurrentResponsibleParty(undefined);
        setShowEmail(false);
        onClose(clearSelected);
    };
    return {
        currentResponsibleParty,
        sendAndNext,
        showFinalScreen,
        handleFinalScreenClose,
        itemsSelectedLength: (_a = itemsSelected === null || itemsSelected === void 0 ? void 0 : itemsSelected.length) !== null && _a !== void 0 ? _a : 0,
        currentResponsiblePartyIndex,
        responsiblePartiesLength: Object.keys(responsibleParties).length,
        currentGroupLength: (_b = currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.length) !== null && _b !== void 0 ? _b : 0,
        toggleShowEmail,
        showEmail,
        isExternalSubmissionPortal,
        defaultEmailBody,
    };
};
export default useInvoiceSendModal;
