import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byId: {},
    byReferenceNum: {},
    allIds: [],
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['shopName,asc'],
        },
    },
    registration: {
        repairFocuses: { data: [] },
        carBrands: { data: [] },
        carTypes: { data: [] },
    },
    newShop: null,
};
const successShops = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const fetchShopsDashboardReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const clearShopsDashboardReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const successShopsByReferenceNumReducer = (state, action) => {
    const shops = action.payload;
    const { referenceNum } = action.meta;
    return Object.assign(Object.assign({}, state), { byReferenceNum: {
            [referenceNum]: shops,
        } });
};
const successShopById = (state, action) => {
    const shop = fetched(action.payload);
    const { id } = action.meta;
    return Object.assign(Object.assign({}, state), { byId: {
            [id]: shop,
        } });
};
const successClearSearchedShopsReducer = (state, action) => {
    const { referenceNum } = action;
    return Object.assign(Object.assign({}, state), { byReferenceNum: {
            [referenceNum]: null,
        } });
};
const successRepairFocuses = (state, action) => {
    const repairFocuses = fetched({ data: action.payload });
    return Object.assign(Object.assign({}, state), { registration: Object.assign(Object.assign({}, state.registration), { repairFocuses: repairFocuses }) });
};
const successCarBrands = (state, action) => {
    const carBrands = fetched({ data: action.payload });
    return Object.assign(Object.assign({}, state), { registration: Object.assign(Object.assign({}, state.registration), { carBrands: carBrands }) });
};
const successCarTypes = (state, action) => {
    const carTypes = fetched({ data: action.payload });
    return Object.assign(Object.assign({}, state), { registration: Object.assign(Object.assign({}, state.registration), { carTypes: carTypes }) });
};
const successShopRegister = (state, action) => {
    const newShop = fetched(action.payload);
    return Object.assign(Object.assign({}, state), { newShop });
};
const reducers = handleActions({
    [ActionCreators.SHOPS_SEARCH.SUCCESS.type]: successShopsByReferenceNumReducer,
    [ActionCreators.SHOPS.SUCCESS.type]: successShops,
    [ActionCreators.SHOPS.FETCH.type]: fetchShopsDashboardReducer,
    [ActionCreators.SHOPS.CLEAR.type]: clearShopsDashboardReducer,
    [ActionCreators.SHOP.SUCCESS.type]: successShopById,
    [ActionCreators.SHOP_UPDATE.SUCCESS.type]: successShopById,
    [ActionCreators.SHOP_UPDATE_BY_ID.SUCCESS.type]: successShopById,
    [ActionCreators.CLEAR_SEARCHED_SHOPS.SUCCESS.type]: successClearSearchedShopsReducer,
    [ActionCreators.REPAIR_FOCUSES.SUCCESS.type]: successRepairFocuses,
    [ActionCreators.CAR_BRANDS.SUCCESS.type]: successCarBrands,
    [ActionCreators.CAR_TYPES.SUCCESS.type]: successCarTypes,
    [ActionCreators.SHOP_REGISTER.SUCCESS.type]: successShopRegister,
}, DEFAULT_STATE);
export default reducers;
