import React from 'react';
import { MoreVert as MoreVertIcon } from 'src/components/mui/icons';
import { ClickAwayListener } from 'src/components/mui';
import { EllipsisButton, FloatingMenu, StyledActionButton, } from './EllipsisMenu.styles';
const EllipsisMenu = ({ open, setOpen, actions }) => {
    return (<>
      <EllipsisButton aria-controls={open ? 'job-actions-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={() => setOpen(true)}>
        <MoreVertIcon />
      </EllipsisButton>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setOpen(false)}>
        <FloatingMenu open={open}>
          {actions.map(({ label, action, disabled }, index) => (<StyledActionButton key={index} disabled={disabled} onClick={action}>
              {label}
            </StyledActionButton>))}
        </FloatingMenu>
      </ClickAwayListener>
    </>);
};
export default EllipsisMenu;
