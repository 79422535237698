import styled from 'styled-components';
import { colors, FONT_FAMILY, theme } from 'src/styles/theme';
export const PartnersAPICalendar = styled.div `
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  background: ${colors.GRAY_FA};
  margin-top: 16px;
  margin-bottom: 100px;

  ${theme.breakpoints.up('md')} {
    width: 350px;
    padding-bottom: 15px;
    overflow-x: hidden;
  }

  .nav-button {
    color: ${colors.BLUE};
    background: transparent;
  }

  button[class*='MuiPickersDay-day'] {
    border-radius: 5px;
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.35px;
    text-align: center;
  }

  div[class*='MuiPickersCalendarHeader'] p {
    font-family: ${FONT_FAMILY};
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: ${colors.GRAY_3F};
  }

  div[class*='MuiPickersCalendarHeader-daysHeader'] span {
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.35px;
    text-align: center;
  }
`;
