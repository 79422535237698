import React from 'react';
import classNames from 'classnames';
import { Accordion } from 'src/components/mui';
import useWorkOrderItem from 'src/FleetVisitsApp/pages/VisitView/useWorkOrderItem';
import { ExpandMoreRounded as ExpandMoreIcon } from 'src/components/mui/icons';
import { MpiStatusChip, RoStatusChip, } from 'src/FleetVisitsApp/pages/VisitView/StatusChips';
import ActionButtons from 'src/FleetVisitsApp/pages/VisitView/AddRemoveActionButtons';
import { GetRepairsForDisplay } from 'src/FleetVisitsApp/utils/GetRepairsForDisplay';
import { Details, WorkOrderSummary, WorkOrderDetails, DetailsLabel, DetailsValue, AddRemoveActions, CancelationReason, RemovedLabel, } from './WorkOrderItemMobile.styles';
import CarDisplay from '../CarDisplay';
import Services from '../Services';
const WorkOrderItemMobile = ({ workOrder, onWorkOrderRemove, }) => {
    var _a, _b, _c;
    const { itemExpanded, handleAccordionOnChange, mechanicDisplayName, allowRemove, cancelationReason, } = useWorkOrderItem({ workOrder });
    const { repairs, extraRepairsCount, allRepairs } = GetRepairsForDisplay({
        services: workOrder.diagnosis.diagnosisServices,
        showServicesCount: 2,
    });
    return (<Accordion variant="outlined" expanded={itemExpanded} onChange={handleAccordionOnChange}>
      <WorkOrderSummary expandIcon={<ExpandMoreIcon />} expanded={itemExpanded.toString()} classes={{ content: 'summary-content' }} removed={workOrder.status === 'REMOVED'}>
        <span>Ref #:&nbsp;</span>
        <a href={`/admin/repairs/${workOrder.repairRequestRefNum}`} target="_blank" rel="noreferrer">
          <strong>{workOrder.repairRequestRefNum}</strong>
        </a>
        {workOrder.status === 'REMOVED' && (<RemovedLabel>(Removed)</RemovedLabel>)}
      </WorkOrderSummary>

      <WorkOrderDetails removed={workOrder.status === 'REMOVED'}>
        <Details>
          <tbody>
            <tr>
              <td colSpan={2}>
                <DetailsLabel>Customer</DetailsLabel>
                <a href={`/admin/fleets/${(_a = workOrder.fleet) === null || _a === void 0 ? void 0 : _a.id}`} target="_blank" rel="noreferrer">
                  <DetailsValue>{(_b = workOrder.fleet) === null || _b === void 0 ? void 0 : _b.name}</DetailsValue>
                </a>
              </td>
            </tr>
            <tr>
              <td className={classNames({
            removedCar: workOrder.status === 'REMOVED',
        })}>
                {workOrder.car && <CarDisplay car={workOrder.car}/>}
              </td>
              <td>
                {workOrder.status !== 'REMOVED' && (<>
                    <DetailsLabel>RO Status</DetailsLabel>
                    <RoStatusChip repairOrderStatus={workOrder.repairOrderStatus}/>
                  </>)}
              </td>
            </tr>
            <tr>
              <td className={classNames({
            removed: workOrder.status === 'REMOVED',
        })}>
                <DetailsLabel>Tech</DetailsLabel>
                <DetailsValue>{mechanicDisplayName}</DetailsValue>
              </td>
              <td>
                {workOrder.status !== 'REMOVED' && (<>
                    <DetailsLabel>MPI</DetailsLabel>
                    <MpiStatusChip mpiStatus={(_c = workOrder.mpiStatus) !== null && _c !== void 0 ? _c : ''}/>
                  </>)}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={classNames({
            removed: workOrder.status === 'REMOVED',
        })}>
                <DetailsLabel>Services</DetailsLabel>

                <Services allRepairs={allRepairs !== null && allRepairs !== void 0 ? allRepairs : []} extraRepairsCount={extraRepairsCount !== null && extraRepairsCount !== void 0 ? extraRepairsCount : 0} repairs={repairs !== null && repairs !== void 0 ? repairs : []}/>
              </td>
            </tr>
          </tbody>
        </Details>

        <AddRemoveActions>
          {workOrder.status !== 'REMOVED' && (<ActionButtons disabled={!allowRemove} onWorkOrderRemove={() => {
                onWorkOrderRemove(workOrder);
            }}/>)}
          {workOrder.status === 'REMOVED' && (<CancelationReason>{cancelationReason}</CancelationReason>)}
        </AddRemoveActions>
      </WorkOrderDetails>
    </Accordion>);
};
export default WorkOrderItemMobile;
