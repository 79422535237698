import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from 'src/components/mui';
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px;
`;
const Loader = () => {
    return (<Wrapper>
      <CircularProgress />
    </Wrapper>);
};
export default Loader;
