import React from 'react';
import { Radio, Checkbox } from 'src/components/mui';
import { AvailableTechniciansWrapper, TechHeader, TechChoices, TechControlLabel, NumberOfTechs, TechChoice, TechnicianLabel, TechsList, } from './AvailableTechnicians.styles';
import useAvailableTechnicians from './useAvailableTechnicians';
const AvailableTechnicians = ({ disabled, isEditing, shouldShowCapacityWarning, setUnsavedNumberOfTechs, setUnsavedTechniciansChoice, setUnsavedVanMechanicPairings, }) => {
    const { techniciansChoice, numberOfTechs, technicians, changeTechniciansChoice, changeNumberOfTechs, handleTechSelect, isTechVanChecked, } = useAvailableTechnicians({
        isEditing,
        setUnsavedTechniciansChoice,
        setUnsavedVanMechanicPairings,
        setUnsavedNumberOfTechs,
        shouldShowCapacityWarning,
    });
    return (<AvailableTechniciansWrapper>
      <TechHeader>Available Technicians</TechHeader>

      <TechChoices value={techniciansChoice !== null && techniciansChoice !== void 0 ? techniciansChoice : ''} onChange={changeTechniciansChoice}>
        <TechChoice>
          <TechControlLabel value="byNumber" control={<Radio color="primary" disabled={technicians.length === 0 || disabled} checked={techniciansChoice === 'byNumber'}/>} label="By Number of Technicians"/>

          {techniciansChoice === 'byNumber' && (<NumberOfTechs fullWidth label="Number of Techs" variant="outlined" type="number" InputLabelProps={{
                shrink: true,
            }} inputProps={{
                min: 1,
                max: 20,
            }} value={numberOfTechs} onChange={changeNumberOfTechs} disabled={disabled}/>)}
        </TechChoice>

        <TechChoice>
          <TechControlLabel value="specific" control={<Radio color="primary" disabled={technicians.length === 0 || disabled} checked={techniciansChoice === 'specific'}/>} label="By Specific Technicians"/>

          {techniciansChoice === 'specific' && (<TechsList>
              {technicians.map((tech) => (<TechnicianLabel key={`${tech.technicianId}-${tech.deliveryVanId}`} control={<Checkbox color="primary" onChange={(e) => {
                        handleTechSelect(e.target.checked, tech);
                    }} disabled={disabled} checked={isTechVanChecked(tech)}/>} label={`${tech.mechanicFirstName} ${tech.mechanicLastName} - ${tech.deliveryVanName}`}/>))}
            </TechsList>)}
        </TechChoice>
      </TechChoices>
    </AvailableTechniciansWrapper>);
};
export default AvailableTechnicians;
