import React from 'react';
import { SlidesContainer } from 'src/AdminApp/components/drawers/styledComponents';
import { SimpleDrawer, SimpleDrawerContent } from 'src/components/SimpleDrawer';
const AddRepairOrderDrawer = ({ open = false, onClose, header, children, subheader = null, error = null, onNavigateBack, isExpanded = false, isSmall = false, arrowClose = false, footer = null, isLoading = false, }) => {
    let size = isExpanded ? '80vw' : 'half';
    if (isSmall) {
        size = 'oneThird';
    }
    return (<SimpleDrawer open={open} onClose={onClose} disableEnforceFocus title={header} onBack={onNavigateBack} size={size} arrowBack={!arrowClose} isLoading={isLoading}>
      <SimpleDrawerContent $displayFlex>
        {!!subheader && subheader}
        <SlidesContainer>{children}</SlidesContainer>
        {!!error && error}
      </SimpleDrawerContent>
      {footer}
    </SimpleDrawer>);
};
export default AddRepairOrderDrawer;
