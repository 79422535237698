var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { archivePartner, unpublishFunnel, getPartners, clonePartner, } from 'src/PartnersApp/modules/partners/actions';
import { getConsumerUrl } from 'src/utils/nav-utils';
const usePartnersTableRow = ({ partner, onFunnelEdit, switchActive, }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [lastModifiedDate, setLastModifiedDate] = useState();
    const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
    const open = Boolean(anchorEl);
    const firstFunnel = (partner === null || partner === void 0 ? void 0 : partner.funnels.length) ? partner.funnels[0] : undefined;
    const showEditFunnelButton = !!firstFunnel &&
        (firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.status) !== 'ARCHIVED' &&
        !(firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.original);
    useEffect(() => {
        var _a, _b, _c;
        if (!firstFunnel)
            return;
        const pageModified = (_c = (((_a = firstFunnel.pages) === null || _a === void 0 ? void 0 : _a.length) && ((_b = firstFunnel.pages[0]) === null || _b === void 0 ? void 0 : _b.modified))) !== null && _c !== void 0 ? _c : undefined;
        // FIGURE OUT WHICH LEVEL HAS THE MOST RECENT LAST MODIFIED: PARTNER/FUNNEL/PAGE
        if (!firstFunnel.modified || !firstFunnel.pages || !pageModified)
            return;
        // PARTNER
        let lastModified = partner.modified;
        // FUNNEL
        if (partner.funnels[0] &&
            new Date(firstFunnel.modified) > new Date(partner.modified)) {
            lastModified = firstFunnel.modified;
        }
        // PAGE
        firstFunnel.pages.sort((a, b) => {
            if (new Date(a.ordinal) < new Date(b.ordinal)) {
                return -1;
            }
            if (new Date(a.ordinal) > new Date(b.ordinal)) {
                return 1;
            }
            return 0;
        });
        if (new Date(lastModified) < new Date(pageModified)) {
            lastModified = pageModified;
        }
        setLastModifiedDate(lastModified);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partner]);
    const handleClickMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleClone = () => __awaiter(void 0, void 0, void 0, function* () {
        const newPartner = yield dispatch(clonePartner({ partnerId: partner.id }));
        handleCloseMenu();
        if (!newPartner.error) {
            dispatch(getPartners({}));
            onFunnelEdit(newPartner.payload);
            switchActive(null, true);
        }
    });
    const handleUnpublish = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.id))
            return;
        yield dispatch(unpublishFunnel({ funnelId: firstFunnel.id }));
        setShowArchiveConfirmation(false);
        handleCloseMenu();
        dispatch(getPartners({}));
    });
    const handleArchive = () => {
        handleCloseMenu();
        setShowArchiveConfirmation(true);
    };
    const handleArchiveAction = () => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(archivePartner({ partnerId: partner.id }));
        setShowArchiveConfirmation(false);
        handleCloseMenu();
        dispatch(getPartners({}));
    });
    const handleView = () => {
        if ((firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.status) === 'ARCHIVED') {
            onFunnelEdit(partner);
        }
        else {
            window.open(getConsumerUrl(`location?partner=${firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.urlParameterIdentifier}${(firstFunnel === null || firstFunnel === void 0 ? void 0 : firstFunnel.status) !== 'PUBLISHED' ? '&rspreview=true' : ''}`));
        }
    };
    return {
        open,
        anchorEl,
        firstFunnel,
        showEditFunnelButton,
        lastModifiedDate,
        showArchiveConfirmation,
        setShowArchiveConfirmation,
        handleClickMenuOpen,
        handleCloseMenu,
        handleClone,
        handleUnpublish,
        handleArchive,
        handleArchiveAction,
        handleView,
        setLastModifiedDate,
    };
};
export default usePartnersTableRow;
