import React from 'react';
import { Grid } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import styled from 'styled-components';
import { CatalogManufacturerDrawerMode } from 'src/PricingApp/components/manufacturers/catalog-manufacturers/useCatalogManufacturerDrawer';
import { Catalog } from 'src/PricingApp/models/enums/Catalog';
import { colors } from 'src/styles/theme';
const PointerGrid = styled(Grid) `
  cursor: pointer;

  font-weight: 300;
  font-size: 15px;
  color: ${colors.GRAY_3F};
`;
const CatalogManufacturer = ({ manufacturer, catalogManufacturer, setEditableManufacturer, setEditableCatalogManufacturer, setCatalogManufacturerDrawerMode, }) => {
    const handleEditCatalogManufacturer = () => {
        setEditableManufacturer(manufacturer);
        setEditableCatalogManufacturer(catalogManufacturer);
        setCatalogManufacturerDrawerMode(CatalogManufacturerDrawerMode.EDIT_CATALOG);
    };
    return (<PointerGrid container spacing={4} style={{ alignItems: 'center', justifyContent: 'center' }} onDoubleClick={handleEditCatalogManufacturer}>
      <Grid item xs={6}>
        {Catalog.display(`${catalogManufacturer.catalogId}`)}
      </Grid>
      <Tooltip title="Priority: 0 - Blacklisted, 1+ Prioritized">
        <Grid item xs={6}>
          {catalogManufacturer.priority}
        </Grid>
      </Tooltip>
    </PointerGrid>);
};
export default CatalogManufacturer;
