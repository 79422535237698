import React from 'react';
import Loader from 'src/AdminApp/containers/requests/StateTracker/Loader';
import { Grid, TimePicker } from 'src/components/mui';
import { WatchLater as WatchLaterIcon } from 'src/components/mui/icons';
import SimpleModal from 'src/components/SimpleModal';
import { colors } from 'src/styles/theme';
import { renderTimeWindow } from 'src/TechApp/components/TaskParts';
import LunchBreakActions from 'src/TechApp/pages/LunchBreaksRoot/LunchBreakDetails/LunchBreakActions';
import { TIME_12H_FORMAT, TIME_FORMAT } from 'src/timeConstants';
import { getMomentValueOrNull, getTimeString } from 'src/utils/formatter';
import { ButtonsWrapper, Container, Content, ContentInner, ContentWrapper, FormRow, LunchInfo, LunchSubheader, LunchSubheaderWrapper, LunchTitle, Title, } from './LunchBreakDetails.styles';
import useLunchBreakDetails from './useLunchBreakDetails';
const LunchBreakDetails = () => {
    const { goToDashBoardHandler, lunchBreak, isCanceled, isMobile, isLoading, updateLunchBreakStatus, endTime, setIsModalOpen, startTime, setStartTime, setEndTime, isModalOpen, goBackHandler, onCancelLunchBreak, } = useLunchBreakDetails();
    return (<Container>
      <Title>Lunch Break</Title>
      <Content>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <ContentWrapper style={{ marginTop: '8px' }}>
              <LunchTitle>Break Log</LunchTitle>
              <LunchSubheaderWrapper>
                <WatchLaterIcon htmlColor={colors.lightGrey}/>
                <LunchSubheader>
                  Scheduled Time{' '}
                  {renderTimeWindow(lunchBreak === null || lunchBreak === void 0 ? void 0 : lunchBreak.scheduledStartTime, lunchBreak === null || lunchBreak === void 0 ? void 0 : lunchBreak.scheduledEndTime)}
                </LunchSubheader>
              </LunchSubheaderWrapper>
              <FormRow>
                <LunchSubheader>Actual Time</LunchSubheader>
              </FormRow>
              <FormRow>
                <TimePicker 
    /* fullWidth */
    label="Start Time" value={getMomentValueOrNull(startTime, TIME_FORMAT)} onChange={(val) => {
            setStartTime(getTimeString(val, TIME_FORMAT));
        }} minutesStep={1} format={TIME_12H_FORMAT} disabled slotProps={{
            textField: {
                fullWidth: true,
                variant: 'outlined',
                margin: 'none',
            },
        }}/>
              </FormRow>

              <FormRow style={{ marginBottom: '16px' }}>
                <TimePicker label="End Time" value={getMomentValueOrNull(endTime, TIME_FORMAT)} onChange={(val) => {
            setEndTime(getTimeString(val, TIME_FORMAT));
        }} minutesStep={1} format={TIME_12H_FORMAT} disabled slotProps={{
            textField: {
                fullWidth: true,
                variant: 'outlined',
                margin: 'none',
            },
        }}/>
              </FormRow>
              <LunchInfo>
                *Cancelling will remove lunch from schedule.
              </LunchInfo>
            </ContentWrapper>
          </Grid>
          <Grid item xs={12} md={12}>
            <ContentInner className="center">
              {isLoading ? (<Loader />) : (<ButtonsWrapper>
                  <LunchBreakActions lunchBreak={lunchBreak} isCanceled={isCanceled} isLoading={isLoading} endTime={endTime} isMobile={isMobile} setIsModalOpen={setIsModalOpen} updateLunchBreakStatus={updateLunchBreakStatus} goToDashBoardHandler={goToDashBoardHandler}/>
                </ButtonsWrapper>)}
            </ContentInner>
          </Grid>
        </Grid>

        <SimpleModal open={isModalOpen} onClose={() => {
            setIsModalOpen(!isModalOpen);
        }} simpleTwoButtonRowProps={{
            onCancel: goBackHandler,
            cancelText: 'Go Back',
            onSubmit: onCancelLunchBreak,
            submitText: 'Okay',
        }} title="Cancel Lunch?">
          If you remove this lunch, it will be removed from your schedule.
        </SimpleModal>
      </Content>
    </Container>);
};
export default LunchBreakDetails;
