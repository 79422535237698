import React from 'react';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
const StyledTag = styled.div `
  display: flex;
  background-color: ${colors.GRAY_F1};
  color: ${colors.GRAY_AA};
  border-radius: 15px;
  font-weight: bold;
  justify-content: center;
  margin: auto;
  padding: 0px 20px;
  height: 34px;
  line-height: 34px;
`;
const InfoTag = ({ text }) => {
    return <StyledTag>{text}</StyledTag>;
};
export default InfoTag;
