import { startCase, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Popover, Select } from 'src/components/mui';
import { Checkbox } from 'src/components/SimpleCheckbox';
import useIsMobile from 'src/hooks/useIsMobile';
import { ActionButton, Actions, Item, } from './InvoiceRequestStatusSelect.styles';
const InvoiceRequestStatusSelect = ({ status = [], onChange, disabled, }) => {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [innerStatus, setInnerStatus] = useState([]);
    useEffect(() => {
        setInnerStatus(status);
    }, [status]);
    const handleChange = (value) => {
        setInnerStatus(state => state.includes(value) ? state.filter(s => s !== value) : [...state, value]);
    };
    const handleClear = () => {
        setInnerStatus([]);
        setOpen(false);
        setAnchorEl(null);
        onChange([]);
    };
    const INVOICE_REQUEST_STATUSES = [
        'PENDING',
        'GENERATING_INVOICES',
        'SENDING_INVOICES',
        'COMPLETED_WITH_ERRORS',
        'COMPLETED',
        'CANCELED'
    ];
    return (<>
      <Select labelId="status-select-label" id="status-select" value={status} style={{
            minWidth: 160,
            maxWidth: isMobile ? 0 : 400,
            width: isMobile ? '100%' : 'auto',
        }} label="Age" disabled={disabled} MenuProps={{
            style: {
                opacity: 0,
            },
        }} open={open} onClick={(event) => {
            if (disabled)
                return;
            setOpen(!open);
            setAnchorEl(event === null || event === void 0 ? void 0 : event.currentTarget);
        }} renderValue={() => {
            return (innerStatus !== null && innerStatus !== void 0 ? innerStatus : [])
                .map((s) => startCase(toLower(s).replaceAll('_', ' ')))
                .join(', ');
        }}></Select>
      <Popover open={open} anchorEl={anchorEl} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }} transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }} onClose={() => {
            setOpen(false);
            setAnchorEl(null);
            setInnerStatus(status);
        }} style={{ minWidth: 260 }}>
        {INVOICE_REQUEST_STATUSES.map((s) => (<Item key={s} onClick={() => handleChange(s)}>
            <Checkbox checked={innerStatus.includes(s)}/>
            {startCase(toLower(s).replaceAll('_', ' '))}
          </Item>))}

        <Actions>
          <ActionButton onClick={handleClear}>Reset</ActionButton>
          <ActionButton onClick={() => {
            setOpen(false);
            setAnchorEl(null);
            onChange(innerStatus);
        }} isMain>
            Apply
          </ActionButton>
        </Actions>
      </Popover>
    </>);
};
export default InvoiceRequestStatusSelect;
