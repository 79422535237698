var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRegionsByFilters } from 'src/AdminApp/modules/regions/actions';
import { getPartDistributors } from 'src/PopsApp/modules/vendors/actions';
import { updateRegionHomeStores } from 'src/AdminApp/modules/geoManagement/actions';
import { selectPartDistributors } from 'src/PopsApp/modules/vendors/selectors';
import { selectDashboardPagination as selectRegionsDashboardPagination, selectDashboardParams as selectRegionsDashboardParams, } from 'src/AdminApp/modules/regions/selectors';
import { isNaN, parseInt } from 'lodash';
import { toast } from 'src/components/SimpleToast';
const useHomeStoresDrawerPage = ({ model, onClose, }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const partDistributors = useSelector(selectPartDistributors);
    const regionsPagination = useSelector(selectRegionsDashboardPagination);
    const regionsParams = useSelector(selectRegionsDashboardParams);
    useEffect(() => {
        if (!partDistributors)
            dispatch(getPartDistributors());
    }, [dispatch, partDistributors]);
    const rows = useMemo(() => {
        return partDistributors === null || partDistributors === void 0 ? void 0 : partDistributors.filter((d) => d.name !== 'Other' &&
            d.name !== 'Customer Provided' &&
            d.status === 'ACTIVE').map((distributor) => {
            var _a;
            const homeStore = (_a = model.regionDefaultHomeStores) === null || _a === void 0 ? void 0 : _a.find((store) => store.partDistributorName === distributor.name);
            return {
                partDistributorId: distributor.id,
                name: distributor.name,
                partDistributorLocationId: homeStore === null || homeStore === void 0 ? void 0 : homeStore.partDistributorLocationId,
                address: homeStore === null || homeStore === void 0 ? void 0 : homeStore.partDistributorAddress,
                priority: homeStore === null || homeStore === void 0 ? void 0 : homeStore.priority,
            };
        }).sort((a, b) => {
            if (a.name === 'RepairSmith Hub')
                return -1;
            if (b.name === 'RepairSmith Hub')
                return 1;
            return a.name.localeCompare(b.name);
        }).map((row) => {
            var _a, _b, _c, _d, _e;
            return {
                name: (_a = row === null || row === void 0 ? void 0 : row.name) !== null && _a !== void 0 ? _a : null,
                partDistributorId: (_b = row === null || row === void 0 ? void 0 : row.partDistributorId) !== null && _b !== void 0 ? _b : null,
                address: (_c = row === null || row === void 0 ? void 0 : row.address) !== null && _c !== void 0 ? _c : null,
                partDistributorLocationId: (_d = row === null || row === void 0 ? void 0 : row.partDistributorLocationId) !== null && _d !== void 0 ? _d : null,
                priority: row.name === 'RepairSmith Hub' ? null : (_e = row === null || row === void 0 ? void 0 : row.priority) !== null && _e !== void 0 ? _e : null,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partDistributors]);
    const getValidationTooltip = (errors) => {
        return typeof errors.homeStores === 'string'
            ? errors.homeStores
            : 'Priority must be a positive integer.';
    };
    const getPriority = (homeStores, index) => {
        var _a;
        if (homeStores && ((_a = homeStores[index]) === null || _a === void 0 ? void 0 : _a.name) === 'RepairSmith Hub') {
            return null;
        }
        return homeStores ? homeStores[index].priority : 0;
    };
    const onSubmitHomeStores = (homeStores) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(updateRegionHomeStores(model.id, homeStores));
        if (!response || response.error) {
            toast.error('Something went wrong, try again later.');
            return;
        }
        return response;
    });
    const handleSubmit = (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!model) {
            toast.error('Something went wrong, try again later.');
            return;
        }
        try {
            yield onSubmitHomeStores(values.homeStores
                .filter((r) => !!r.partDistributorLocationId)
                .map((r) => {
                return {
                    partDistributorLocationId: r.partDistributorLocationId,
                    priority: r.priority,
                };
            }));
            refreshRegions();
        }
        catch (e) {
            toast.error('Something went wrong, try again later.');
        }
        finally {
            toast.success('Region home stores updated successfully.');
        }
        setIsDialogOpen(false);
        setSubmitting(false);
        onClose();
    });
    const refreshRegions = () => {
        var _a, _b, _c;
        return dispatch(getRegionsByFilters(Object.assign(Object.assign({}, regionsParams), { pagination: {
                size: (_a = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                page: (_b = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.pageNumber) !== null && _b !== void 0 ? _b : 0,
                sort: (_c = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.sort) !== null && _c !== void 0 ? _c : ['name', 'asc'],
            } })));
    };
    const handleUpdatePriority = (setFieldValue, index) => (value) => {
        const priority = !isNaN(parseInt(value)) ? parseInt(value) : null;
        setFieldValue(`homeStores.${index}.priority`, priority);
    };
    const handleUpdateLocation = (setFieldValue, index) => (label, value) => {
        setFieldValue(`homeStores.${index}.address`, label);
        setFieldValue(`homeStores.${index}.partDistributorLocationId`, value.id);
    };
    const handleClear = (setFieldValue, index, values) => () => {
        setFieldValue(`homeStores.${index}`, Object.assign(Object.assign({}, (values.homeStores ? values.homeStores[index] : {})), { priority: null, address: null, partDistributorLocationId: null }));
    };
    return {
        isDialogOpen,
        setIsDialogOpen,
        dispatch,
        partDistributors,
        regionsPagination,
        regionsParams,
        rows,
        getValidationTooltip,
        getPriority,
        onSubmitHomeStores,
        refreshRegions,
        handleSubmit,
        handleUpdatePriority,
        handleUpdateLocation,
        handleClear,
    };
};
export default useHomeStoresDrawerPage;
