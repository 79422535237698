import { CircularProgress, Grid, TimePicker } from 'src/components/mui';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { Formik } from 'formik';
import { capitalize } from 'lodash';
import React from 'react';
import { TextField } from 'src/AdminApp/components/form';
import { Text } from 'src/AdminApp/containers/requests/styledComponents';
import { TemplateWeekday } from 'src/AdminApp/models/enums';
import { selectTechniciansForAutocomplete } from 'src/AdminApp/modules/users/selectors';
import EditableSearchUsers from 'src/components/form/EditableSearchUsers';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, DrawerFormRow, } from 'src/components/SimpleDrawer';
import { getMomentValueOr8am, getTimeString } from 'src/utils/formatter';
import * as Yup from 'yup';
import { DATE_YMD_FORMAT, LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT, TIME_FORMAT, } from 'src/timeConstants';
import { LoaderWrapper, searchInputStyle } from '../DeliveryVanTemplate.styles';
import useEditAvailabilityTemplate from './useEditAvailabilityTemplate';
const WEEKDAYS = Object.keys(TemplateWeekday);
const EditAvailabilityTemplateDrawerPage = ({ vanModel, onCancel, }) => {
    const { loading, manualEntryMechanic, setManualEntryMechanic, getInitialValues, handleManualMechanicSearchUpdate, handleFormSubmit, } = useEditAvailabilityTemplate({
        vanModel,
        onCancel,
    });
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Template name is required').min(2).max(50),
        start: Yup.string().required(),
        finish: Yup.string().notRequired().nullable(),
        technicianId: Yup.string().required(),
    });
    return (<Formik initialValues={getInitialValues()} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
      {({ dirty, isSubmitting, isValid, isValidating, setFieldValue, handleSubmit, values, }) => (<>
          <SimpleDrawerContent>
            {loading ? (<LoaderWrapper>
                <CircularProgress size={40}/>
              </LoaderWrapper>) : (<>
                <Grid container spacing={2}>
                  <DrawerFormRow item xs={12}>
                    <TextField variant="outlined" value={values.name} label="Template name" type="text" name="name" fullWidth/>
                  </DrawerFormRow>
                  <Grid item xs={6}>
                    <DrawerFormRow>
                      <TextField variant="outlined" value={vanModel === null || vanModel === void 0 ? void 0 : vanModel.name} label="Van" type="text" name="vanName" fullWidth disabled/>
                    </DrawerFormRow>
                  </Grid>
                  <Grid item xs={6}>
                    <EditableSearchUsers value={manualEntryMechanic} label="Technician" defaultValue="Technician" usersSelector={selectTechniciansForAutocomplete} onChange={handleManualMechanicSearchUpdate} onUpdate={(value) => {
                    setFieldValue('technicianId', value.serviceAdvisorUid);
                    setManualEntryMechanic(value.serviceAdvisorName);
                }} requireId doubleClickToOpen={false} style={searchInputStyle(!manualEntryMechanic)}/>
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleDatePicker fullWidth name="startDate" label="Start date" format={LOCALIZED_DATE_FORMAT} value={values.start} onChange={(value) => {
                    setFieldValue('start', value === null || value === void 0 ? void 0 : value.local().format(DATE_YMD_FORMAT));
                }} slotProps={{
                    textField: {
                        variant: 'outlined',
                    },
                    field: {
                        clearable: true,
                    },
                }}/>
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleDatePicker fullWidth name="endDate" label="End date" format={LOCALIZED_DATE_FORMAT} value={values.finish} onChange={(value) => {
                    setFieldValue('finish', value ? value.local().format(DATE_YMD_FORMAT) : null);
                }} slotProps={{
                    textField: {
                        variant: 'outlined',
                    },
                    field: {
                        clearable: true,
                    },
                }}/>
                  </Grid>
                  <Grid item xs={4}>
                    <Text>Select day/s</Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text>Start time</Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text>End time</Text>
                  </Grid>
                </Grid>

                {WEEKDAYS.map((weekday) => (<Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }} key={weekday}>
                    <Grid item xs={4}>
                      <LabelledCheckbox label={capitalize(weekday.toLowerCase())} checkboxProps={{
                        checked: values[weekday].checked,
                        onChange: (e) => {
                            setFieldValue(weekday, Object.assign(Object.assign({}, values[weekday]), { checked: e.target.checked }));
                        },
                        name: weekday,
                    }}/>
                    </Grid>
                    <Grid item xs={4}>
                      <TimePicker slotProps={{
                        textField: {
                            margin: 'none',
                            variant: 'standard',
                        },
                    }} value={getMomentValueOr8am(values[weekday].startTime, TIME_FORMAT)} onChange={(val) => {
                        setFieldValue(weekday, Object.assign(Object.assign({}, values[weekday]), { startTime: getTimeString(val, TIME_FORMAT) }));
                    }} minutesStep={1} format={TIME_12H_FORMAT}/>
                    </Grid>

                    <Grid item xs={4}>
                      <TimePicker slotProps={{
                        textField: {
                            margin: 'none',
                            variant: 'standard',
                        },
                    }} value={getMomentValueOr8am(values[weekday].endTime, TIME_FORMAT)} onChange={(val) => {
                        setFieldValue(weekday, Object.assign(Object.assign({}, values[weekday]), { endTime: getTimeString(val, TIME_FORMAT) }));
                    }} minutesStep={1} format={TIME_12H_FORMAT}/>
                    </Grid>
                  </Grid>))}
              </>)}
          </SimpleDrawerContent>

          <SimpleDrawerFooter>
            <FooterActionButton disabled={!isValid || isValidating || isSubmitting || !dirty || loading} onClick={handleSubmit}>
              Save
            </FooterActionButton>
          </SimpleDrawerFooter>
        </>)}
    </Formik>);
};
export default EditAvailabilityTemplateDrawerPage;
