import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatRepairs } from 'src/AdminApp/components/repairs/statement/utils';
import { selectRequestDiagnosis } from 'src/AdminApp/modules/requests/selectors';
import CONFIG from 'src/config';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
const PART_DISTRIBUTOR_ID = CONFIG.CUSTOMER_PROVIDED_ID;
const PART_DISTRIBUTOR_NAME = CONFIG.CUSTOMER_PROVIDED;
const PART_DISTRIBUTOR_LOCATION_ID = '';
const ADDRESS = '';
const useCustomerProvidedParts = ({ setIsCustomerProvidedPartsValid, selectedCustomerParts, isCheckedAllCustomerParts, setSelectedCustomerParts, setIsCheckedAllCustomerParts, additionalRepairComponents, setAdditionalRepairComponents, invoice, }) => {
    const { selectorProps } = usePageReferenceNum();
    const [filteredRepairs, setFilteredRepairs] = useState([]);
    let diagnosis = useSelector((state) => selectRequestDiagnosis(state, selectorProps));
    if (invoice) {
        diagnosis = invoice;
    }
    const filterRepairs = (repairs) => {
        return repairs.filter((repair) => {
            var _a;
            return (_a = repair.price) === null || _a === void 0 ? void 0 : _a.some((price) => price.fluids.length > 0 || price.parts.length > 0);
        });
    };
    useEffect(() => {
        var _a, _b, _c;
        const { knownRepairs } = formatRepairs(((_a = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) || [], ((_b = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _b === void 0 ? void 0 : _b.diagnoses) || [], ((_c = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _c === void 0 ? void 0 : _c.customRequests) || []);
        setFilteredRepairs(filterRepairs([...knownRepairs]));
    }, [diagnosis]);
    useEffect(() => {
        const components = filteredRepairs
            .map((repair) => {
            var _a, _b;
            const batchAdditionalRepairComponentsInt = {
                suggestedRepairId: repair === null || repair === void 0 ? void 0 : repair.id,
                additionalRepairComponent: {
                    laborTime: repair.price[0].laborTime,
                    laborRate: repair.price[0].laborRate,
                    totalCost: repair.price[0].totalCost,
                    name: repair.name,
                    addToPartPickup: false,
                },
            };
            if (((_a = repair.price[0].fluids) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                const fluids = repair.price[0].fluids
                    .filter((fluid) => fluid.status !== 'NOT_NEEDED')
                    .map((currentFluid) => {
                    const fluid = {};
                    fluid.id = currentFluid.id;
                    fluid.componentId = currentFluid.componentId;
                    fluid.name = currentFluid.name;
                    fluid.price = 0;
                    fluid.cost = 0;
                    fluid.capacityUnit = currentFluid.capacityUnit;
                    fluid.capacityUnitAbbreviation =
                        currentFluid.capacityUnitAbbreviation;
                    fluid.capacity = currentFluid.capacity;
                    fluid.type = currentFluid.type;
                    fluid.notes = currentFluid.notes;
                    fluid.partNumber = currentFluid.partNumber;
                    fluid.specialOrder = currentFluid.specialOrder;
                    fluid.leadTime = currentFluid.leadTime;
                    fluid.partDistributorId = PART_DISTRIBUTOR_ID;
                    fluid.partDistributorName = PART_DISTRIBUTOR_NAME;
                    fluid.partDistributorLocationId = PART_DISTRIBUTOR_LOCATION_ID;
                    fluid.address = ADDRESS;
                    fluid.addAsDynamicComponent = false;
                    fluid.addToPartPickup = false;
                    fluid.selected = false;
                    fluid.coreChargeExpected = false;
                    return fluid;
                });
                batchAdditionalRepairComponentsInt.additionalRepairComponent.fluids =
                    fluids;
            }
            if (((_b = repair.price[0].parts) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                const parts = repair.price[0].parts
                    .filter((part) => part.status !== 'NOT_NEEDED')
                    .map((currentPart) => {
                    return {
                        id: currentPart.id,
                        componentId: currentPart.componentId,
                        name: currentPart.name,
                        cost: 0,
                        price: 0,
                        partManufacturer: currentPart.partManufacturer,
                        catalogPartNumber: currentPart.catalogPartNumber,
                        catalogPartName: currentPart.catalogPartName,
                        catalogSource: currentPart.catalogSource,
                        partNumber: currentPart.partNumber,
                        quantity: currentPart.quantity,
                        notes: currentPart.notes,
                        specialOrder: currentPart.specialOrder,
                        leadTime: currentPart.leadTime,
                        partMetadata: currentPart.partMetadata,
                        coreChargeCost: currentPart.coreChargeCost,
                        partDistributorId: PART_DISTRIBUTOR_ID,
                        partDistributorName: PART_DISTRIBUTOR_NAME,
                        partDistributorLocationId: PART_DISTRIBUTOR_LOCATION_ID,
                        address: ADDRESS,
                        addAsDynamicComponent: false,
                        addToPartPickup: false,
                        pcdbId: currentPart.pcdbId,
                        coreChargeExpected: false,
                        selected: false,
                    };
                });
                batchAdditionalRepairComponentsInt.additionalRepairComponent.parts =
                    parts;
            }
            return batchAdditionalRepairComponentsInt;
        })
            .filter((repairComponent) => {
            var _a, _b, _c, _d;
            return ((_b = (_a = repairComponent.additionalRepairComponent.parts) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0 ||
                ((_d = (_c = repairComponent.additionalRepairComponent.fluids) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) > 0;
        });
        setAdditionalRepairComponents(components);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredRepairs]);
    useEffect(() => {
        let enabled = isCheckedAllCustomerParts;
        Object.values(selectedCustomerParts).forEach((component) => {
            var _a, _b;
            if (((_a = component.part) === null || _a === void 0 ? void 0 : _a.length) > 0 || ((_b = component.fluid) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                enabled = true;
            }
        });
        if (setIsCustomerProvidedPartsValid) {
            setIsCustomerProvidedPartsValid(enabled && additionalRepairComponents.length > 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomerParts, isCheckedAllCustomerParts]);
    const handleCheckboxChange = (event, batchAdditionalRepairComponent, id, partType) => {
        const isChecked = event.target.checked;
        const repairId = batchAdditionalRepairComponent.suggestedRepairId;
        const updatedSelected = Object.assign({}, selectedCustomerParts);
        if (!updatedSelected[repairId]) {
            updatedSelected[repairId] = { part: [], fluid: [] };
        }
        if (isChecked) {
            if (!updatedSelected[repairId][partType].includes(id)) {
                updatedSelected[repairId][partType].push(id);
            }
        }
        else {
            updatedSelected[repairId][partType] = updatedSelected[repairId][partType].filter((itemId) => itemId !== id);
        }
        if (!isChecked) {
            setIsCheckedAllCustomerParts(false);
        }
        setSelectedCustomerParts(updatedSelected);
    };
    const handleSelectAllChange = (event, components) => {
        setIsCheckedAllCustomerParts(event.target.checked);
        if (event.target.checked) {
            const updatedSelected = Object.assign({}, selectedCustomerParts);
            components.forEach((component) => {
                var _a, _b;
                const repairId = component.suggestedRepairId;
                if (!updatedSelected[repairId]) {
                    updatedSelected[repairId] = { part: [], fluid: [] };
                }
                (_a = component.additionalRepairComponent.parts) === null || _a === void 0 ? void 0 : _a.forEach((part) => {
                    if (!updatedSelected[repairId].part.includes(part.id)) {
                        updatedSelected[repairId].part.push(part.id);
                    }
                });
                (_b = component.additionalRepairComponent.fluids) === null || _b === void 0 ? void 0 : _b.forEach((fluid) => {
                    if (!updatedSelected[repairId].fluid.includes(fluid.id)) {
                        updatedSelected[repairId].fluid.push(fluid.id);
                    }
                });
            });
            setSelectedCustomerParts(updatedSelected);
        }
        else {
            setSelectedCustomerParts({});
        }
    };
    return {
        handleCheckboxChange,
        handleSelectAllChange,
    };
};
export default useCustomerProvidedParts;
