import React, { useState } from 'react';
import { Grid, IconButton } from 'src/components/mui';
import { ChevronLeft, ChevronRight } from 'src/components/mui/icons';
import { SimpleModal } from 'src/components/layout';
import { ImagePreview, ChevronGrid } from './styledComponents';
const ImagesModal = ({ open, onClose, urls }) => {
    const [currentImage, setCurrentImage] = useState(1);
    return (<SimpleModal title="Attached Images" open={open} onClose={onClose} simpleTwoButtonRowProps={{
            onSubmit: onClose,
            onCancel: onClose,
            submitText: 'Done',
        }}>
      <Grid container>
        <ChevronGrid item xs={1}>
          <IconButton disabled={currentImage === 1} onClick={() => setCurrentImage(currentImage - 1)}>
            <ChevronLeft />
          </IconButton>
        </ChevronGrid>
        <Grid item xs={10}>
          <ImagePreview src={urls[currentImage - 1]}/>
        </Grid>
        <ChevronGrid item xs={1}>
          <IconButton disabled={currentImage === urls.length} onClick={() => setCurrentImage(currentImage + 1)}>
            <ChevronRight />
          </IconButton>
        </ChevronGrid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          ({currentImage}/{urls.length})
        </Grid>
      </Grid>
    </SimpleModal>);
};
export default ImagesModal;
