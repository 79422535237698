var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVisitsAuditLog } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { getDeliveryVanById } from 'src/AdminApp/modules/deliveryVans/actions';
import { getVisitAuditLog, getAddressAuditLog, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import useMultipleAdminUsers from 'src/hooks/useMultipleAdminUsers';
import useIsMobile from 'src/hooks/useIsMobile';
import { uniq } from 'lodash';
import { transformAuditLogData, transformAddressData, getUniqueUsers, getAddressString, getValue, } from './constants';
const useAuditDrawer = ({ referenceNumber, visitId, }) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const [loading, setLoading] = useState(false);
    const [usersIds, setUsersIds] = useState([]);
    const [assignedVans, setAssignedVans] = useState({});
    const [addresses, setAddresses] = useState({});
    const auditLogData = useSelector(selectVisitsAuditLog);
    const data = useMemo(() => { var _a; return transformAuditLogData((_a = auditLogData === null || auditLogData === void 0 ? void 0 : auditLogData[referenceNumber]) !== null && _a !== void 0 ? _a : undefined); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auditLogData]);
    const { usersInfo } = useMultipleAdminUsers({ userIds: usersIds !== null && usersIds !== void 0 ? usersIds : [] });
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!data)
                return;
            setLoading(true);
            if (usersIds.length === 0) {
                const users = getUniqueUsers(data);
                setUsersIds([...users]);
            }
            const changesVans = data.changes.default_delivery_van_id.map((el) => el.newValue);
            const changesAddresses = data.changes.address_id.map((el) => el.newValue);
            const vans = [data.snapshot.default_delivery_van_id, ...changesVans];
            const addresses = [data.snapshot.address_id, ...changesAddresses];
            const filteredVans = uniq(vans);
            const filteredAddresses = uniq(addresses);
            const vansPromises = filteredVans.map((v) => {
                return dispatch(getDeliveryVanById(v));
            });
            const addressesPromises = filteredAddresses.map((v) => {
                return dispatch(getAddressAuditLog({ id: v }));
            });
            const vansResponse = yield Promise.all(vansPromises);
            const addressesResponse = yield Promise.all(addressesPromises);
            const transformedVans = vansResponse.reduce((acc, el) => {
                var _a;
                if ((_a = el === null || el === void 0 ? void 0 : el.payload) === null || _a === void 0 ? void 0 : _a.id) {
                    acc[el.payload.id] = el.payload.name;
                }
                return acc;
            }, {});
            const transformedAddresses = transformAddressData(addressesResponse);
            setAddresses(transformedAddresses);
            setAssignedVans(transformedVans);
            setLoading(false);
        }))();
        return () => {
            setUsersIds([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    const getAuditLogData = (visitId, referenceNumber) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield dispatch(getVisitAuditLog({ id: visitId, referenceNumber }));
        setLoading(false);
    });
    useEffect(() => {
        getAuditLogData(visitId, referenceNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referenceNumber]);
    const getValueByField = (field, value, index, current, vans) => {
        var _a;
        if (field === 'default_delivery_van_id') {
            if (current && (vans !== null && vans !== void 0 ? vans : []).length > 0) {
                const data = vans === null || vans === void 0 ? void 0 : vans.map((el) => {
                    var _a, _b;
                    return ((_a = el === null || el === void 0 ? void 0 : el.deliveryVanName) !== null && _a !== void 0 ? _a : '')
                        .substring(((_b = el.deliveryVanName) !== null && _b !== void 0 ? _b : '').indexOf('-') + 1)
                        .trim();
                }).join(', ');
                return data;
            }
            else if (assignedVans && assignedVans[value]) {
                return assignedVans[value]
                    .substring(assignedVans[value].indexOf('-') + 1)
                    .trim();
            }
            else {
                return 'N/A';
            }
        }
        if (field === 'address_id' &&
            typeof index !== 'undefined' &&
            Object.keys(addresses !== null && addresses !== void 0 ? addresses : {}).length > 0) {
            return getAddressString((_a = addresses[value][index]) === null || _a === void 0 ? void 0 : _a.snapshot);
        }
        return getValue(field, value, usersInfo);
    };
    const getAddressesArray = (id) => Object.keys(addresses[id])
        .map((el) => {
        return Object.assign({}, addresses[id][el]);
    })
        .filter((x) => !!x)
        .sort((a, b) => new Date(b.created) - new Date(a.created));
    return {
        loading,
        assignedVans,
        data,
        usersInfo,
        isMobile,
        addresses,
        getValueByField,
        getAddressesArray,
    };
};
export default useAuditDrawer;
