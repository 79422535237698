var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, TextField } from 'src/components/mui';
import { Warning as WarningIcon, Info as InfoIcon, Error as ErrorIcon, } from 'src/components/mui/icons';
import { updateUserPhoneNumber } from 'src/AdminApp/modules/users/actions';
import { formatPhoneNumberOnType } from 'src/utils/formatter';
import { UpdateMessageTypography } from '../EmailUpdate/EmailUpdate.styles';
const PhoneNumberUpdate = ({ userId, userPhoneNumber }) => {
    const dispatch = useDispatch();
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState(userPhoneNumber);
    const [message, setMessage] = useState({
        icon: null,
        text: '',
    });
    const [isPhoneNumberSubmitting, setIsPhoneNumberSubmitting] = useState(false);
    const validatePhoneNumber = () => {
        const validPhoneNumber = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i;
        return !currentPhoneNumber || validPhoneNumber.test(currentPhoneNumber);
    };
    const handleUserPhoneNumberChange = (e) => {
        const { value } = e.target;
        const formattedInputValue = formatPhoneNumberOnType(value);
        setCurrentPhoneNumber(formattedInputValue);
    };
    const handlePhoneNumberSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        setIsPhoneNumberSubmitting(true);
        const cleanPhoneNumber = userPhoneNumber.replace(/[^\d]/g, '');
        const cleanCurrentPhoneNumber = currentPhoneNumber && currentPhoneNumber.replace(/[^\d]/g, '');
        if (cleanPhoneNumber === cleanCurrentPhoneNumber) {
            setMessage({
                icon: <WarningIcon />,
                text: 'The phone number is the same as the original',
            });
        }
        const phoneNumberPayload = {
            phoneNumber: cleanCurrentPhoneNumber,
        };
        const updatePhoneNumberResponse = yield dispatch(updateUserPhoneNumber({ id: userId, body: phoneNumberPayload }));
        if (!updatePhoneNumberResponse.error) {
            setMessage({
                icon: <InfoIcon />,
                text: 'Phone number updated successfully',
            });
        }
        else {
            setMessage({
                icon: <ErrorIcon />,
                text: updatePhoneNumberResponse.payload.response.message,
            });
        }
        setIsPhoneNumberSubmitting(false);
    });
    useEffect(() => {
        setPhoneNumberError(!validatePhoneNumber());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPhoneNumber]);
    return (<>
      <TextField id="myProfile-user-phone-number-input" label="Phone Number" variant="outlined" type="tel" value={(currentPhoneNumber && formatPhoneNumberOnType(currentPhoneNumber)) ||
            ''} onChange={handleUserPhoneNumberChange}/>
      {message.text && (<UpdateMessageTypography>
          {message.icon} {message.text}
        </UpdateMessageTypography>)}
      <Button variant="contained" color="primary" size="large" disabled={!currentPhoneNumber || phoneNumberError || isPhoneNumberSubmitting} onClick={handlePhoneNumberSubmit}>
        {isPhoneNumberSubmitting ? (<CircularProgress />) : ('Send phone number update')}
      </Button>
    </>);
};
export default PhoneNumberUpdate;
