import React from 'react';
import { Container } from 'src/components/mui';
import { SaleMatchFilterType } from 'src/AdminApp/models/enums/SaleMatchFilterType';
import SalesMatchDashboard from 'src/AdminApp/containers/salesMatch/SalesMatchDashboard';
const SalesMatch = ({ filterType = SaleMatchFilterType.Daily, }) => {
    return (<Container maxWidth="xl">
      <SalesMatchDashboard filterType={filterType}/>
    </Container>);
};
export default SalesMatch;
