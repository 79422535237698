import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketingSources, getFees, } from 'src/AdminApp/modules/marketingSources/actions';
import { selectDashboardPagination, selectDashboardParams, selectMarketingSources, selectDashboardFetching, } from 'src/AdminApp/modules/marketingSources/selectors';
const useMarketingSourcesDashboard = () => {
    var _a;
    const dispatch = useDispatch();
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    const marketingSources = useSelector(selectMarketingSources);
    const isFetching = useSelector(selectDashboardFetching);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [pageSize, setPageSize] = useState((_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20);
    const [currentId, setCurrentId] = useState();
    const [visibility, setVisibility] = useState('-1');
    useEffect(() => {
        var _a, _b;
        dispatch(getMarketingSources(Object.assign(Object.assign({}, params), { pageSize: (_a = pagination.pageSize) !== null && _a !== void 0 ? _a : 20, pageIndex: (_b = pagination.pageNumber) !== null && _b !== void 0 ? _b : 0 })));
        dispatch(getFees());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLoadData = ({ page, size, vis }) => {
        const newVisibility = vis === '-1' ? undefined : vis;
        const existingVisibility = visibility === '-1' ? undefined : visibility;
        dispatch(getMarketingSources(Object.assign(Object.assign({}, params), { pageIndex: page, pageSize: size, visibility: vis ? newVisibility : existingVisibility })));
    };
    const handleListItemClick = (id) => {
        setEditDrawerOpen(true);
        setCurrentId(id);
    };
    const handleEditorClose = () => {
        setEditDrawerOpen(false);
        setCurrentId(undefined);
        onLoadData({ page: pagination.pageNumber, size: pagination.pageSize });
    };
    const handleSourceAdd = () => {
        setCurrentId(undefined);
        setEditDrawerOpen(true);
    };
    const handleVisibilityChange = (vis) => {
        setVisibility(vis);
        onLoadData({
            size: pagination.pageSize,
            vis,
        });
    };
    return {
        editDrawerOpen,
        setEditDrawerOpen,
        pageSize,
        setPageSize,
        visibility,
        marketingSources,
        onLoadData,
        pagination,
        handleListItemClick,
        isFetching,
        handleEditorClose,
        handleSourceAdd,
        handleVisibilityChange,
        currentId,
    };
};
export default useMarketingSourcesDashboard;
