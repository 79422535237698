var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartPickupOrder, selectPartPickupWorkflow, selectShouldAutocompleteSelectPart, } from 'src/TechApp/modules/partpickup/selectors';
import { completePartPickupTask, fetchPartPickupOrderByReferenceNum, getPartPickupTaskWorkflow, submitPartPickupPhoto, deletePartPickupPhoto, } from 'src/TechApp/modules/partpickup/actions';
import { toast } from 'src/components/SimpleToast';
import useGetHubsFromVan from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/useGetHubsFromVan';
const usePartPickupDetails = ({ referenceNum }) => {
    const dispatch = useDispatch();
    const partPickupOrder = useSelector(selectPartPickupOrder);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [deletePhotoId, setDeletePhotoId] = useState(null);
    const [currentLocation, setCurrentLocation] = useState();
    const [isPartPickupTransferDrawerOpen, setIsPartPickupTransferDrawerOpen] = useState(false);
    const isPickupAtDistributor = !!(partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.pickupAtDistributor);
    const isPickupAtLocation = !!(partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.pickupAtLocation);
    const partPickupWorkflow = useSelector(selectPartPickupWorkflow);
    const shouldAutocompleteSelectPart = useSelector(selectShouldAutocompleteSelectPart);
    const van = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.resources.find((resource) => resource.category === 'VAN');
    const { hubs, hasHubs } = useGetHubsFromVan(van === null || van === void 0 ? void 0 : van.resourceId);
    useEffect(() => {
        let unmountedComponent = false;
        if (referenceNum) {
            dispatch(fetchPartPickupOrderByReferenceNum(referenceNum));
            navigator.geolocation.getCurrentPosition((position) => {
                if (unmountedComponent)
                    return;
                setCurrentLocation(position.coords);
            });
        }
        return () => {
            unmountedComponent = true;
        };
    }, [dispatch, referenceNum]);
    useEffect(() => {
        if (referenceNum) {
            dispatch(getPartPickupTaskWorkflow(referenceNum));
        }
    }, [dispatch, partPickupOrder, referenceNum]);
    useEffect(() => {
        let intervalId = null;
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            yield dispatch(fetchPartPickupOrderByReferenceNum(referenceNum));
        });
        if (referenceNum) {
            fetchData();
            intervalId = setInterval(fetchData, 10000);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [dispatch, referenceNum]);
    useEffect(() => {
        if (shouldAutocompleteSelectPart) {
            completeCurrentTask();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldAutocompleteSelectPart]);
    const completeCurrentTask = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if ((partPickupWorkflow === null || partPickupWorkflow === void 0 ? void 0 : partPickupWorkflow.currentTask) && (partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum)) {
            setIsLoading(true);
            const result = yield dispatch(completePartPickupTask(partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum, partPickupWorkflow.currentTask.id, Object.assign(Object.assign({}, partPickupWorkflow.currentTask), { coordinates: {
                    latitude: (_a = currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.latitude) !== null && _a !== void 0 ? _a : 0.0,
                    longitude: (_b = currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.longitude) !== null && _b !== void 0 ? _b : 0.0,
                } })));
            if (((_d = (_c = result === null || result === void 0 ? void 0 : result.payload) === null || _c === void 0 ? void 0 : _c.currentTask) === null || _d === void 0 ? void 0 : _d.status) === 'COMPLETED' &&
                ((_f = (_e = result === null || result === void 0 ? void 0 : result.payload) === null || _e === void 0 ? void 0 : _e.currentTask) === null || _f === void 0 ? void 0 : _f.state) !== 'SELECT_PARTS' &&
                ((_h = (_g = result === null || result === void 0 ? void 0 : result.payload) === null || _g === void 0 ? void 0 : _g.currentTask) === null || _h === void 0 ? void 0 : _h.state) !== 'COMPLETE') {
                toast.success(`The ${(_k = (_j = result === null || result === void 0 ? void 0 : result.payload) === null || _j === void 0 ? void 0 : _j.currentTask) === null || _k === void 0 ? void 0 : _k.state} task was successfully completed`);
            }
            dispatch(getPartPickupTaskWorkflow(partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum));
        }
    });
    const handleAddPhoto = (e) => __awaiter(void 0, void 0, void 0, function* () {
        var _l, _m, _o;
        const file = ((_l = e === null || e === void 0 ? void 0 : e.target) === null || _l === void 0 ? void 0 : _l.files) && ((_m = e === null || e === void 0 ? void 0 : e.target) === null || _m === void 0 ? void 0 : _m.files[0]);
        if (!file)
            return;
        const formData = new FormData();
        formData.append('file', (_o = e === null || e === void 0 ? void 0 : e.target) === null || _o === void 0 ? void 0 : _o.files[0]);
        try {
            const response = yield dispatch(submitPartPickupPhoto(partPickupOrder.id, formData));
            if (!response || (response === null || response === void 0 ? void 0 : response.error)) {
                throw new Error('');
            }
        }
        catch (e) {
            // nothing to do
        }
    });
    const handleOpenRemovePhotoModal = (e, attachmentId) => {
        e.stopPropagation();
        setDeletePhotoId(attachmentId);
    };
    const handleRemovePhoto = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!deletePhotoId)
            return;
        yield dispatch(deletePartPickupPhoto(partPickupOrder.id, deletePhotoId));
        yield dispatch(fetchPartPickupOrderByReferenceNum(referenceNum));
        setDeletePhotoId(null);
    });
    return {
        partPickupOrder,
        isPickupAtDistributor,
        isPickupAtLocation,
        van,
        isLoading,
        selectedPhoto,
        setSelectedPhoto,
        deletePhotoId,
        setDeletePhotoId,
        currentLocation,
        hubs,
        hasHubs,
        completeCurrentTask,
        handleAddPhoto,
        handleOpenRemovePhotoModal,
        handleRemovePhoto,
        isPartPickupTransferDrawerOpen,
        setIsPartPickupTransferDrawerOpen,
    };
};
export default usePartPickupDetails;
