import React from 'react';
import { startCase, truncate } from 'lodash';
import { usMoneyBaseFormat } from 'src/modules/api/utils/format';
import moment from 'moment';
import { FLEET_NAME_LENGTH, TECH_NAME_LENGTH, } from 'src/FleetVisitsApp/modules/api/constants';
import { useHistory } from 'react-router-dom';
import VisitLockTooltip from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitLockTooltip';
import { convertMinutesToHours } from 'src/FleetVisitsApp/pages/VisitView/VisitInfo/utils';
import { TooltipInfo } from 'src/components/tooltips';
import { TIME_12H_FORMAT, DATE_TIME_FORMAT_WITH_SECONDS, LOCALIZED_DATE_LONG_FORMAT, } from 'src/timeConstants';
import { VisitCell, CaptionCell, VisitRowContainer, TooltipDivider, } from './VisitRow.styles';
export const getVisitData = (item) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const fleetNameLength = FLEET_NAME_LENGTH - (((_a = item === null || item === void 0 ? void 0 : item.stationName) === null || _a === void 0 ? void 0 : _a.length) || 0);
    const showFleets = ((_b = item === null || item === void 0 ? void 0 : item.fleetNames) === null || _b === void 0 ? void 0 : _b.length) && fleetNameLength > 0;
    const startDate = moment(item.startLocal, DATE_TIME_FORMAT_WITH_SECONDS);
    const endDate = moment(item.endLocal, DATE_TIME_FORMAT_WITH_SECONDS);
    const startDateFormatted = startDate.format(TIME_12H_FORMAT);
    const endDateFormatted = endDate.format(TIME_12H_FORMAT);
    const isNextDate = startDate.format('DD') !== endDate.format('DD');
    const showFleetColon = ((_c = item === null || item === void 0 ? void 0 : item.stationName) === null || _c === void 0 ? void 0 : _c.length) < FLEET_NAME_LENGTH;
    const technicians = !((_d = item === null || item === void 0 ? void 0 : item.technicianNames) === null || _d === void 0 ? void 0 : _d.length)
        ? []
        : (_e = item === null || item === void 0 ? void 0 : item.technicianNames) === null || _e === void 0 ? void 0 : _e.sort((a, b) => {
            if (a > b) {
                return 1;
            }
            return -1;
        });
    const fleetName = ((_f = item === null || item === void 0 ? void 0 : item.fleetNames) === null || _f === void 0 ? void 0 : _f.length)
        ? truncate(item === null || item === void 0 ? void 0 : item.fleetNames[0], {
            omission: '...',
            length: fleetNameLength,
        }).trimEnd()
        : 'N/A';
    const stationName = truncate(item === null || item === void 0 ? void 0 : item.stationName, {
        length: FLEET_NAME_LENGTH,
        omission: '...',
    });
    const currentTimezone = moment.tz.guess();
    const lockedAtDate = moment(`${item.lockedAt}Z`).local();
    const lockedAtDateFormatted = `${lockedAtDate.format(LOCALIZED_DATE_LONG_FORMAT)} ${moment
        .tz(currentTimezone)
        .zoneAbbr()}`;
    const totalScheduledHours = convertMinutesToHours(item.totalScheduledLaborMinutes);
    const totalRemainingHours = convertMinutesToHours(item.totalRemainingLaborMinutes);
    return {
        refNum: item.refNum,
        locked: item.locked,
        lockedBy: item.lockedBy,
        lockedAtDate: lockedAtDateFormatted,
        stationName,
        fleetName,
        showFleetColon,
        fleetsCount: (_h = (_g = item === null || item === void 0 ? void 0 : item.fleetNames) === null || _g === void 0 ? void 0 : _g.length) !== null && _h !== void 0 ? _h : 0,
        fleets: (_j = item === null || item === void 0 ? void 0 : item.fleetNames) !== null && _j !== void 0 ? _j : [],
        showFleets,
        techsCount: (_l = (_k = item === null || item === void 0 ? void 0 : item.technicianNames) === null || _k === void 0 ? void 0 : _k.length) !== null && _l !== void 0 ? _l : 0,
        techName: ((_m = item === null || item === void 0 ? void 0 : item.technicianNames) === null || _m === void 0 ? void 0 : _m.length)
            ? truncate(technicians[0], {
                length: TECH_NAME_LENGTH,
                omission: '',
            })
            : 'N/A',
        techs: technicians,
        startDateFormatted,
        endDateFormatted,
        isNextDate,
        closedOrders: (_o = item === null || item === void 0 ? void 0 : item.ordersCount) === null || _o === void 0 ? void 0 : _o.closed,
        totalOrders: (_p = item === null || item === void 0 ? void 0 : item.ordersCount) === null || _p === void 0 ? void 0 : _p.total,
        subtotal: usMoneyBaseFormat(item === null || item === void 0 ? void 0 : item.subtotal, '0,0.00'),
        status: startCase(item === null || item === void 0 ? void 0 : item.status.replace(/_/g, ' ').toLowerCase()),
        address: (<>
        {item.address.street}
        <br />
        {`${item.address.city}, ${item.address.state} ${item.address.zip}`}
      </>),
        totalScheduledHours,
        totalRemainingHours,
    };
};
const VisitRow = ({ item }) => {
    var _a;
    const history = useHistory();
    const itemData = getVisitData(item);
    return (<VisitRowContainer onClick={() => {
            history.push(`/admin/visits/${itemData.refNum}`);
        }}>
      <VisitCell>
        <CaptionCell>
          <VisitLockTooltip visitData={itemData}/>

          {itemData.stationName ? (<b>
              {itemData.stationName}
              {itemData.showFleetColon && <>: </>}
            </b>) : null}
          {itemData.showFleets ? <span> {itemData.fleetName}</span> : ''}
          {itemData.fleetsCount > 1 && (<span className="VisitMore"> +{itemData.fleetsCount - 1} more</span>)}
          <TooltipInfo outermargin="0 10px 2px">
            {itemData.stationName ? <b>{itemData.stationName}</b> : null}
            {itemData.stationName ? <TooltipDivider /> : null}
            {itemData.fleets.join(' • ')}
            <TooltipDivider />
            {itemData.address}
          </TooltipInfo>
        </CaptionCell>
      </VisitCell>
      <VisitCell>
        {itemData.techsCount ? <span> {itemData.techName}</span> : 'N/A'}
        {itemData.techsCount > 1 && (<span className="VisitMore"> +{itemData.techsCount - 1} more</span>)}
        <TooltipInfo outermargin="0 10px -4px">
          <div className="tooltipTitle">
            <b>Technicians</b>
          </div>
          {(_a = itemData.techs) === null || _a === void 0 ? void 0 : _a.join(' • ')}
        </TooltipInfo>
      </VisitCell>
      <VisitCell>
        {itemData.startDateFormatted} - {itemData.endDateFormatted}
        {!!itemData.isNextDate && ' (Next Day)'}
        <br />
        Scheduled Hrs: {itemData.totalScheduledHours}
      </VisitCell>
      <VisitCell>
        Repair Orders: {itemData.totalOrders}
        <br />
        Remaining Hrs: {itemData.totalRemainingHours}
      </VisitCell>
      <VisitCell>{itemData.subtotal}</VisitCell>
      <VisitCell>{itemData.status}</VisitCell>
    </VisitRowContainer>);
};
export default VisitRow;
