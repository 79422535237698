import React, { useState } from 'react';
import { Value } from 'src/AdminApp/components/layout/Module';
import { MobileButtonWrapper, StyledChevronRightIcon, } from 'src/AdminApp/containers/vehicle/CarModule/CarFields.styles';
import { Grid } from 'src/components/mui';
const MobileFieldButton = ({ label, displayValue, values, onSave, FieldEdit, }) => {
    const [open, setOpen] = useState(false);
    return (<Grid item xs={12}>
      <MobileButtonWrapper onClick={() => setOpen(true)}>
        <div>
          <Value>{label}</Value>
          <div>{displayValue}</div>
        </div>
        <StyledChevronRightIcon />
      </MobileButtonWrapper>
      <FieldEdit open={open} onClose={() => setOpen(false)} onSave={(values) => {
            onSave(values);
            setOpen(false);
        }} values={values}/>
    </Grid>);
};
export default MobileFieldButton;
