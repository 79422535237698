import styled from 'styled-components';
import { colors, FONT_FAMILY, theme } from 'src/styles/theme';
import { TextField } from 'src/components/mui';
export const Spreedly = styled.section `
  .Spreedly {
    &__form {
      justify-content: space-between;
      padding: 23px 34px 43px;

      ${theme.breakpoints.up('md')} {
        padding-bottom: 40px;
      }

      #message {
        display: none;
        width: 100%;
        object-fit: contain;
        border-radius: 4px;
        background-color: ${colors.GRAY_F0};
        padding: 16px 24px;
        font-size: 14px;
        border-left: thick solid ${colors.error};
        margin-top: 24px;
        font-family: ${FONT_FAMILY};
        font-weight: bold;
        font-style: normal;
        line-height: 18px;
        letter-spacing: -0.3px;
        color: ${colors.GRAY_3F};
      }
    }

    &__fullName {
      width: 100%;
      margin: 20px 0 16px;

      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23) !important;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
        border-width: 1px;
      }

      label {
        font-family: ${FONT_FAMILY};
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.3px;
        color: ${colors.GRAY_76};

        &.MuiFormLabel-root.Mui-focused {
          color: ${colors.GRAY_76};
        }
      }
    }

    &__inputContainer {
      position: relative;
      margin-top: 5px;

      &--cvc {
        margin-top: 6px;
      }
    }

    &__label {
      position: absolute;
      top: -11px;
      left: 0;
      background: ${colors.white};
      z-index: 800;
      margin-left: 10px;
      padding: 0 4px;
      font-family: ${FONT_FAMILY};
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.3px;
      color: ${colors.GRAY_76};
      white-space: nowrap;

      &--cvc {
        top: -11px;
      }
    }

    &__makeDefaultInput {
      margin: 20px 0 0 -11px;
    }

    &__makeDefaultLabel {
      font-family: ${FONT_FAMILY};
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.46;
      letter-spacing: -0.1px;
      color: ${colors.GRAY_3F};
    }

    &__actions {
      flex-grow: 10;
      min-height: 100px;

      #fake_button {
        z-index: 2;
      }

      > div {
        z-index: 1;
      }

      ${theme.breakpoints.down('sm')} {
        position: relative;
        background-image: linear-gradient(
          to bottom,
          ${colors.GRAY_EBED},
          ${colors.GRAY_F2} 150%
        );

        > div {
          position: absolute;
          top: 24px;
          left: 32px;
          right: 32px;
        }
      }

      ${theme.breakpoints.up('md')} {
        position: relative;
        height: 70px;

        > div {
          position: absolute;
          top: 0;
          left: 24px;
          right: 24px;
          bottom: 24px;
        }
      }
    }
  }
`;
export const ExpField = styled(TextField) `
  [class*='MuiOutlinedInput-root'] {
    border-radius: 4px 0 0 4px;
  }

  [class*='MuiOutlinedInput-notchedOutline'] {
    border-color: rgba(0, 0, 0, 0.23) !important;
    border-right: none;
  }

  .Mui-focused [class*='MuiOutlinedInput-notchedOutline'] {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
  }

  label {
    font-family: ${FONT_FAMILY};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.3px;
    color: ${colors.GRAY_76};

    &.Mui-focused {
      color: ${colors.GRAY_76};
    }
  }
`;
