import styled from 'styled-components';
import { theme, colors } from 'src/styles/theme';
export const JobsFooterContainer = styled.section `
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 1px 0 0 ${colors.GRAY_DE};
  background-image: linear-gradient(
    to bottom,
    ${colors.GRAY_EBED},
    ${colors.GRAY_F2} 75%
  );
  padding: 22px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.breakpoints.up('md')} {
    background-image: none;
    position: static;
    box-shadow: inset 0 1px 0 0 ${colors.GRAY_DE};
    background-color: ${colors.white};

    .jobsStats {
      display: flex;
      align-items: center;

      & > div:not(:first-child) {
        margin-left: 16px;
        margin-top: 0;
      }
    }
  }

  .orderPartsBtn {
    padding: 16px 40px;
  }
`;
export const FooterItem = styled.div `
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  text-align: right;
  color: ${colors.GRAY_76};

  &:last-child {
    margin-top: 4px;
  }
`;
