export const DEFAULT_TAB = 0;
export const CONSUMER_TABS = {
    EXISTING_CUSTOMER: 0,
    ADD_NEW_CUSTOMER: 1,
    SEARCH_ADDRESS: 2,
    ADD_NEW_VEHICLE: 3,
    GARAGE: 4,
    ADD_SERVICES: 5,
    SELECT_APPOINTMENT: 6,
    RESET_PARTS: 7,
};
export const FLEET_TABS = {
    ADD_FLEET_CUSTOMER: 0,
    SEARCH_ADDRESS: 1,
    ADD_NEW_VEHICLE: 2,
    GARAGE: 3,
    ADD_SERVICES: 4,
    SELECT_APPOINTMENT: 5,
    RESET_PARTS: 6,
    SELECT_VISIT: 7,
};
export const FLEET_QUICK_RO_TABS = () => {
    return [{ label: 'Existing Fleet', value: FLEET_TABS.ADD_FLEET_CUSTOMER }];
};
