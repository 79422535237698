import { createSelector } from 'reselect';
import { selectCurrentUserId } from 'src/modules/auth/selectors';
import { AuthorizationGroups } from 'src/AdminApp/models/enums';
export const selectUsers = (state) => {
    return state.admin.users;
};
export const selectCurrentUser = createSelector(selectUsers, selectCurrentUserId, (users, currentUserId) => { var _a; return currentUserId ? (_a = users.byId[currentUserId]) !== null && _a !== void 0 ? _a : {} : null; });
export const selectAdminUsers = createSelector(selectUsers, (users) => { var _a, _b; return (_b = (_a = users.dashboard) === null || _a === void 0 ? void 0 : _a.users) !== null && _b !== void 0 ? _b : {}; });
export const selectAdminUsersDashboardParams = createSelector(selectUsers, (users) => { var _a, _b; return (_b = (_a = users.dashboard) === null || _a === void 0 ? void 0 : _a.params) !== null && _b !== void 0 ? _b : {}; });
export const selectAdminUsersDashboardPagination = createSelector(selectUsers, (users) => { var _a, _b; return (_b = (_a = users.dashboard) === null || _a === void 0 ? void 0 : _a.pagination) !== null && _b !== void 0 ? _b : {}; });
export const selectAdminUsersDashboardFetching = createSelector(selectUsers, (users) => { var _a, _b; return (_b = (_a = users.dashboard) === null || _a === void 0 ? void 0 : _a._isFetching) !== null && _b !== void 0 ? _b : false; });
export const selectCurrentAdminUserName = createSelector(selectUsers, selectCurrentUserId, (users, currentId) => {
    var _a, _b, _c;
    const user = users.byId[currentId !== null && currentId !== void 0 ? currentId : -1];
    if (!user)
        return null;
    const firstName = (_a = user.firstName) !== null && _a !== void 0 ? _a : null;
    const lastName = (_b = user.lastName) !== null && _b !== void 0 ? _b : null;
    const adminName = !!firstName && !!lastName
        ? `${firstName} ${lastName}`
        : (_c = user.email) !== null && _c !== void 0 ? _c : null;
    return adminName;
});
export const selectUsersByAuthGroup = (group) => createSelector(selectUsers, (users) => { var _a; return (_a = users.byAuthGroup[group]) !== null && _a !== void 0 ? _a : []; });
export const selectUsersByNotInAuthGroup = (group) => createSelector(selectUsers, (users) => { var _a; return (_a = users.byNotInAuthGroup[group]) !== null && _a !== void 0 ? _a : []; });
export const selectServiceAdvisors = createSelector(selectUsersByAuthGroup('serviceAdvisors'), (users) => users);
export const selectTerritoryManagers = createSelector(selectUsersByAuthGroup('territoryManagers'), (users) => users);
export const selectManagers = createSelector(selectUsersByAuthGroup('managers'), (users) => users.sort((t1, t2) => {
    if (t1.email > t2.email) {
        return 1;
    }
    if (t1.email < t2.email) {
        return -1;
    }
    return 0;
}));
export const selectPorters = createSelector(selectUsersByAuthGroup('porters'), (users) => users.sort((t1, t2) => {
    if (t1.fullName > t2.fullName) {
        return 1;
    }
    if (t1.fullName < t2.fullName) {
        return -1;
    }
    return 0;
}));
export const selectEngineers = createSelector(selectUsersByAuthGroup('engineers'), (users) => users);
export const selectTechnicians = createSelector(selectUsersByAuthGroup('technicians'), (users) => users.sort((t1, t2) => {
    if (t1.fullName > t2.fullName) {
        return 1;
    }
    if (t1.fullName < t2.fullName) {
        return -1;
    }
    return 0;
}));
export const selectTechniciansForAutocomplete = createSelector(selectUsers, (users) => {
    var _a;
    return ((_a = users.techniciansForAutocomplete) !== null && _a !== void 0 ? _a : []).sort((t1, t2) => {
        if (t1.fullName > t2.fullName) {
            return 1;
        }
        if (t1.fullName < t2.fullName) {
            return -1;
        }
        return 0;
    });
});
export const selectNonTechnicians = createSelector(selectUsersByNotInAuthGroup('technicians'), (users) => users);
export const selectAdminUserInfoById = (userId) => createSelector(selectUsers, (users) => { var _a; return (_a = users.userInfoById[userId]) !== null && _a !== void 0 ? _a : {}; });
export const selectCurrentUserIsEngineering = createSelector(selectCurrentUser, (user) => {
    var _a;
    return !user
        ? false
        : ((_a = user.authGroups) !== null && _a !== void 0 ? _a : []).includes(AuthorizationGroups.ENGINEERING);
});
export const selectCurrentUserIsProduct = createSelector(selectCurrentUser, (user) => {
    var _a;
    return !user
        ? false
        : ((_a = user.authGroups) !== null && _a !== void 0 ? _a : []).includes(AuthorizationGroups.PRODUCT);
});
export const selectCurrentUserIsPartSpecialistI = createSelector(selectCurrentUser, (user) => {
    var _a;
    return !user
        ? false
        : ((_a = user.authGroups) !== null && _a !== void 0 ? _a : []).includes(AuthorizationGroups.PART_SPECIALIST_I);
});
export const selectCurrentUserIsPartSpecialistII = createSelector(selectCurrentUser, (user) => {
    var _a;
    return !user
        ? false
        : ((_a = user.authGroups) !== null && _a !== void 0 ? _a : []).includes(AuthorizationGroups.PART_SPECIALIST_II);
});
export const selectCurrentUserIsTechnician = createSelector(selectCurrentUser, (user) => {
    var _a;
    return !user
        ? false
        : ((_a = user.authGroups) !== null && _a !== void 0 ? _a : []).includes(AuthorizationGroups.TECHNICIANS);
});
export const selectCurrentUserIsPorter = createSelector(selectCurrentUser, (user) => { var _a; return ((_a = user === null || user === void 0 ? void 0 : user.authGroups) !== null && _a !== void 0 ? _a : []).includes(AuthorizationGroups.PORTER); });
