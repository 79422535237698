import React from 'react';
import Loader from 'src/AdminApp/containers/requests/StateTracker/Loader';
import { getDeliveryVansAutocomplete } from 'src/AdminApp/modules/deliveryVans/actions';
import { selectDeliveryVansAutocomplete } from 'src/AdminApp/modules/deliveryVans/selectors';
import DeliveryVanSearch from 'src/components/form/DeliveryVanSearch';
import { HubIcon } from 'src/components/icons/HubIcon';
import { ConfirmationModal } from 'src/components/layout';
import { Grid, TextField, TimePicker } from 'src/components/mui';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { Content, Title, } from 'src/PopsApp/pages/PartPickup/PartPickupMissingParts/PartPickupMissingParts.styles';
import usePartPickupReschedule from 'src/PopsApp/pages/PartPickup/Reschedule/usePartPickupReschedule';
import { FormRow, MenuItemContent, MenuItemIcon, MenuItemInner, MenuItemWrapper, PartsPickupButton, Subheader, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
import { DATE_YMD_FORMAT, LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT, TIME_FORMAT, } from 'src/timeConstants';
import { getMomentValueOr8am, getTimeString } from 'src/utils/formatter';
import { Container, ContentInner, } from '../Checklist/PartPickupChecklist.styles';
import { PartPickupButtonsWrapper } from '../Details/PartPickupDetails.styles';
const PartPickupReschedule = () => {
    const { isMobile, selectedDistributorLocationId, setSelectedDistributorLocationId, distributors, splitAtFirstComma, apptDate, setApptDate, apptTime, setApptTime, selectedVan, setSelectedVan, isLoading, submitRescheduleHandler, isValid, cancelPartPickupHandler, isConfirmModalOpen, submitCancel, goBackHandler, } = usePartPickupReschedule();
    return (<Container>
      <Title>Edit</Title>
      <Content>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Subheader style={{ marginBottom: '5px' }}>
              Appointment Details
            </Subheader>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormRow>
              <TextField select label="Pickup Location" name="topReason" variant="outlined" value={selectedDistributorLocationId} onChange={(event) => {
            setSelectedDistributorLocationId(event.target.value);
        }}>
                {distributors &&
            distributors.map((distributor) => (<MenuItemWrapper key={distributor.distributorId} value={distributor.distributorId}>
                      <MenuItemInner>
                        <MenuItemIcon>
                          {distributor.isHub ? <HubIcon /> : null}
                        </MenuItemIcon>

                        <MenuItemContent>
                          <span>
                            {splitAtFirstComma(distributor.address)[0]}
                          </span>
                          <br />
                          {splitAtFirstComma(distributor.address)[1]}
                        </MenuItemContent>
                      </MenuItemInner>
                    </MenuItemWrapper>))}
              </TextField>
            </FormRow>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormRow>
              <SimpleDatePicker fullWidth name="apptDate" label="Date" format={LOCALIZED_DATE_FORMAT} value={apptDate} onChange={(value) => {
            if (value) {
                setApptDate(value.local().format(DATE_YMD_FORMAT));
            }
        }} slotProps={{
            textField: {
                variant: 'outlined',
            },
            field: {
                clearable: true,
            },
        }}/>
            </FormRow>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormRow>
              <TimePicker 
    /* fullWidth */
    label="Time" value={getMomentValueOr8am(apptTime, TIME_FORMAT)} onChange={(val) => {
            setApptTime(getTimeString(val, TIME_FORMAT));
        }} minutesStep={1} format={TIME_12H_FORMAT} slotProps={{
            textField: {
                margin: 'none',
                variant: 'outlined',
            },
        }}/>
            </FormRow>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormRow>
              {selectedVan !== undefined && (<DeliveryVanSearch value={selectedVan} name="van" label="Van" onUpdate={(van) => {
                setSelectedVan(van);
            }} getDeliveryVans={getDeliveryVansAutocomplete} deliveryVansSelector={selectDeliveryVansAutocomplete} disabled={false}/>)}
            </FormRow>
          </Grid>
          <Grid item xs={12} md={12}>
            <ContentInner className="center">
              {isLoading ? (<Loader />) : (<PartPickupButtonsWrapper>
                  <PartsPickupButton color="primary" variant="contained" onClick={submitRescheduleHandler} fullWidth={isMobile} disabled={!isValid || isLoading}>
                    Submit
                  </PartsPickupButton>
                  <PartsPickupButton color="primary" variant="contained" onClick={cancelPartPickupHandler} fullWidth={isMobile} disabled={isLoading}>
                    Cancel Part Pickup
                  </PartsPickupButton>
                </PartPickupButtonsWrapper>)}
            </ContentInner>
          </Grid>
        </Grid>
      </Content>
      <ConfirmationModal open={isConfirmModalOpen} onClose={goBackHandler} text="Are you sure you want to cancel part pickup?" action={submitCancel}/>
    </Container>);
};
export default PartPickupReschedule;
