import { handleActions } from 'redux-actions';
import { createAPIPaginationHandler2 } from 'src/modules/api/utils/createAPIPaginationHandler2';
import moment from 'moment';
import { createSimpleDeepHandler } from 'src/modules/api/utils/createSimpleDeepHandler';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { ActionCreators, resetAddAPIKey, setAPIKeyAccessLevel, setAPIKeyDescription, setAPIKeyValidFrom, setAPIKeyValidTo, setCheckedDefaultAPIKey, setPartnerAPIKeySort, setSelectedMarketingSource, setSelectedPartner, } from './actions';
import { PAGE_SIZE } from '../api/constants';
const addPartnerAPIKeyDefault = {
    publicKey: undefined,
    partners: undefined,
    marketingSource: undefined,
    isDefault: undefined,
    description: undefined,
    validFrom: moment(),
    validTo: moment(),
};
const defaultState = {
    apiKeys: undefined,
    addPartnerAPIKey: addPartnerAPIKeyDefault,
    pageable: {
        sort: {
            sorted: false,
            unsorted: true,
            empty: true,
        },
        pageNumber: 0,
        pageSize: PAGE_SIZE,
        offset: 0,
        paged: true,
        unpaged: false,
    },
    totalElements: 1,
    last: true,
    totalPages: 1,
    number: 0,
    sort: {
        sorted: false,
        unsorted: true,
        empty: true,
    },
    apiKeySort: 'partnerId,asc',
    first: true,
    numberOfElements: 1,
    size: PAGE_SIZE,
    empty: false,
};
const reducers = handleActions({
    [ActionCreators.GET_PARTNERS_API.SUCCESS.type]: createAPIPaginationHandler2('content'),
    [ActionCreators.GET_API_KEY_BY_ID.SUCCESS.type]: createSimpleHandler('apiKeyData'),
    [setPartnerAPIKeySort.type]: createSimpleHandler('apiKeySort'),
    [setSelectedMarketingSource.type]: createSimpleDeepHandler('addPartnerAPIKey.marketingSource'),
    [setSelectedPartner.type]: createSimpleDeepHandler('addPartnerAPIKey.partners'),
    [setAPIKeyValidFrom.type]: createSimpleDeepHandler('addPartnerAPIKey.validFrom'),
    [setAPIKeyValidTo.type]: createSimpleDeepHandler('addPartnerAPIKey.validTo'),
    [setCheckedDefaultAPIKey.type]: createSimpleDeepHandler('addPartnerAPIKey.isDefault'),
    [setAPIKeyDescription.type]: createSimpleDeepHandler('addPartnerAPIKey.description'),
    [setAPIKeyAccessLevel.type]: createSimpleDeepHandler('addPartnerAPIKey.accessLevel'),
    [resetAddAPIKey.type]: (state) => (Object.assign(Object.assign({}, state), { addPartnerAPIKey: Object.assign({}, addPartnerAPIKeyDefault) })),
}, defaultState);
export default reducers;
