var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import TextField from 'src/AdminApp/components/form/TextField';
import { SlidePageContent, SlidePageFieldsContainer, SlidePageFooter, SlidePageFormWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { getMetrosAutocomplete } from 'src/AdminApp/modules/metros/actions';
import { selectMetrosAutocomplete } from 'src/AdminApp/modules/metros/selectors';
import { getTerritoryManagers } from 'src/AdminApp/modules/users/actions';
import { selectTerritoryManagers } from 'src/AdminApp/modules/users/selectors';
import { DestructiveButton } from 'src/components/buttons';
import IspOwnerSearch from 'src/components/form/IspOwnerSearch';
import SearchMultiselect from 'src/components/form/SearchMultiselect';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const TerritoryPage = ({ model, onSubmit, onRemove = () => { }, disableSave, }) => {
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
    });
    return (<Formik initialValues={{
            id: model === null || model === void 0 ? void 0 : model.id,
            name: (model === null || model === void 0 ? void 0 : model.name) || '',
            manager: (model === null || model === void 0 ? void 0 : model.manager) || null,
            metros: (model === null || model === void 0 ? void 0 : model.metros) || null,
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            const submitValue = Object.assign({}, values);
            submitValue.metroIds = (submitValue.addedMetros || []).map((x) => x.id);
            delete submitValue.addedMetros;
            if (onSubmit) {
                yield onSubmit(submitValue);
            }
            setSubmitting(false);
        })}>
      {({ values, isSubmitting, isValid, setFieldValue }) => (<SlidePageFormWrapper>
          <SlidePageContent>
            <SlidePageFieldsContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name="name" label="Territory Name" type="text" variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                  <IspOwnerSearch value={values.manager} name="manager" label="Territory Manager (Optional)" onUpdate={(ispOwner) => setFieldValue('manager', ispOwner)} getUsers={getTerritoryManagers} usersSelector={selectTerritoryManagers}/>
                </Grid>
              </Grid>
            </SlidePageFieldsContainer>
            <SlidePageFieldsContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SearchMultiselect fixedValues={values.metros || []} value={values.addedMetros || []} name="metros" label="Metros (optional)" onUpdate={(data) => setFieldValue('addedMetros', data)} getEntities={getMetrosAutocomplete} selectEntities={selectMetrosAutocomplete}/>
                </Grid>
              </Grid>
            </SlidePageFieldsContainer>
            {model && !model.archived && (<DestructiveButton onClick={() => onRemove(model)}>
                Archive Territory
              </DestructiveButton>)}
          </SlidePageContent>

          {/* TODO: need to implement the rest of fields */}

          <SlidePageFooter>
            <FooterActionButton type="submit" disabled={isSubmitting || !isValid || disableSave}>
              Save Territory
            </FooterActionButton>
          </SlidePageFooter>
        </SlidePageFormWrapper>)}
    </Formik>);
};
export default TerritoryPage;
