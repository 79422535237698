import React from 'react';
import { useSelector } from 'react-redux';
import AddButton from 'src/AdminApp/components/layout/AddButton';
import useConsumersDashboard from 'src/AdminApp/containers/consumers/useConsumersDashboard';
import AddConsumerRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddConsumerRODrawer/AddConsumerRODrawer';
import { selectDashboardConsumers, selectDashboardFetching, } from 'src/AdminApp/modules/consumers/selectors';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { selectAuthorizedCustomerEdit, selectAuthorizedCustomerSearch, selectAuthorizedCustomerView, } from 'src/modules/auth/selectors';
import ConsumerDashboardFilters from './ConsumerDashboardFilters';
import DashboardListItem from './DashboardListItem';
const REQUEST_COLUMNS = [
    { title: 'ID', key: 'id' },
    { title: 'Zendesk ID', key: 'zendeskUserId' },
    { title: 'Consumer Name', key: 'firstName' },
    { title: 'Contact', key: 'email' },
];
const ConsumersDashboard = () => {
    var _a;
    const consumers = useSelector(selectDashboardConsumers);
    const canView = useSelector(selectAuthorizedCustomerView);
    const canSearch = useSelector(selectAuthorizedCustomerSearch);
    const canEdit = useSelector(selectAuthorizedCustomerEdit);
    const isFetching = useSelector(selectDashboardFetching);
    const { onSearch, onClear, pagination, onLoadData, addConsumerModalOpen, onAddConsumerModalClose, onAddConsumerModalOpen, } = useConsumersDashboard();
    return (<>
      {' '}
      {canEdit ? (<div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: '10px',
            }}>
          <AddButton text="Add Consumer" onClick={onAddConsumerModalOpen} color="primary"/>

          <AddConsumerRODrawer open={addConsumerModalOpen} onClose={onAddConsumerModalClose} context="Consumer Dashboard / Add Consumer"/>
        </div>) : (<div>
          ERROR - Not Authorized: You must have the "Customer_Editor" role to
          view this item
        </div>)}
      {canSearch ? (<ConsumerDashboardFilters onSearch={onSearch} onClear={onClear}/>) : (<div>
          ERROR - Not Authorized: You must have the "Customer_Search" role to
          view this item
        </div>)}
      {canView ? (<SimpleTable data={consumers} pagination={pagination} sort={(_a = pagination.sort) !== null && _a !== void 0 ? _a : []} columns={REQUEST_COLUMNS} isFetching={isFetching} renderItem={(item, index) => (<DashboardListItem {...item} key={`${index}`}/>)} onLoadData={onLoadData}/>) : (<div>
          ERROR - Not Authorized: You must have the "Customer_Viewer" role to
          view this item
        </div>)}
    </>);
};
export default ConsumersDashboard;
