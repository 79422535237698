import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Grid, Icon } from 'src/components/mui';
import Module from 'src/components/layout/Module';
import VehicleItem from 'src/AdminApp/containers/vehicle/CarModule/VehicleItem';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { useDispatch, useSelector } from 'react-redux';
import { selectVehiclesFetchingByConsumerId, selectVehiclesPaginationByConsumerId, } from 'src/AdminApp/modules/consumers/selectors';
import { getVehiclesByConsumerId } from 'src/AdminApp/modules/consumers/actions';
const ScrollGrid = styled(Grid) `
  overflow-x: scroll;
`;
const REQUEST_COLUMNS = [
    { title: 'ID', key: 'id' },
    { title: 'Vehicle', key: 'make' },
    { title: 'Subscription', key: 'subscriptions' },
    { title: 'VIN', key: 'vin' },
    { title: 'Mileage', key: 'mileage' },
    { title: '', key: 'edit' },
];
const VehicleList = ({ consumerId, vehicles, canEdit, addRequest, onAddVehicleOpen, onAddRequestOpen, onViewTokensOpen, }) => {
    var _a, _b, _c, _d, _e;
    const dispatch = useDispatch();
    const vehiclesPagination = useSelector(selectVehiclesPaginationByConsumerId(consumerId));
    const isFetching = useSelector(selectVehiclesFetchingByConsumerId(consumerId));
    useEffect(() => {
        dispatch(getVehiclesByConsumerId(consumerId, 0, 20));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    const onLoadData = ({ page, size }) => {
        dispatch(getVehiclesByConsumerId(consumerId, page, size));
    };
    return (<Module title="Vehicle Info" actionButton={canEdit ? (<Button onClick={onAddVehicleOpen}>
            <Icon>add_circle</Icon> Add Vehicle{' '}
          </Button>) : null}>
      <ScrollGrid xs={12} item>
        <SimpleTable data={vehicles} pagination={Object.assign(Object.assign({}, vehiclesPagination), { pageNumber: (_a = vehiclesPagination === null || vehiclesPagination === void 0 ? void 0 : vehiclesPagination.pageNumber) !== null && _a !== void 0 ? _a : 0, pageSize: (_b = vehiclesPagination === null || vehiclesPagination === void 0 ? void 0 : vehiclesPagination.pageSize) !== null && _b !== void 0 ? _b : 20, totalElements: (_c = vehiclesPagination === null || vehiclesPagination === void 0 ? void 0 : vehiclesPagination.totalElements) !== null && _c !== void 0 ? _c : 0, totalPages: (_d = vehiclesPagination === null || vehiclesPagination === void 0 ? void 0 : vehiclesPagination.totalPages) !== null && _d !== void 0 ? _d : 0 })} sort={(_e = vehiclesPagination.sort) !== null && _e !== void 0 ? _e : []} columns={REQUEST_COLUMNS} isFetching={isFetching} renderItem={(vehicle) => (<VehicleItem key={vehicle.id} vehicle={vehicle} addRequest={addRequest} onViewTokensOpen={onViewTokensOpen} onAddRequestOpen={onAddRequestOpen}/>)} onLoadData={onLoadData}/>
      </ScrollGrid>
    </Module>);
};
export default VehicleList;
