/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { Switch } from 'src/components/mui';
import SwimlanesDashboardContext from './SwimlanesDashboardContext';
const AutoRefreshToggle = () => {
    const { autoRefreshIntervalSeconds, autoRefreshEnabled, toggleAutoRefresh } = useContext(SwimlanesDashboardContext);
    return (<div title={`Automatically reload the page data in the background after ${autoRefreshIntervalSeconds} seconds of inactivity`}>
      <label>Auto-Refresh</label>
      <Switch checked={autoRefreshEnabled} onChange={toggleAutoRefresh}/>
    </div>);
};
export default AutoRefreshToggle;
