var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import { loadCurrentUser } from 'src/AdminApp/modules/users/actions';
import { getPartDistributorWithLocations, getResponsiblePartyByFleetId, patchUpdateFleet, } from 'src/AdminApp/modules/fleets/actions';
import { getPartyTypes, patchParty, } from 'src/AdminApp/modules/responsibleParties/actions';
import { partDistributorsSelector, selectFleetById, } from 'src/AdminApp/modules/fleets/selectors';
import { toast } from 'src/components/SimpleToast';
import { selectComponentMarkupEditor } from 'src/modules/auth/selectors';
import { selectPartyTypes } from 'src/AdminApp/modules/responsibleParties/selectors';
import { TIME_12H_FORMAT } from 'src/timeConstants';
const dialogTitles = {
    componentMarkup: 'Save Fixed Component Markup',
    internalNotes: 'Save Fleet Instructions',
};
const useSettings = () => {
    const dispatch = useDispatch();
    const { fleetId } = useParams();
    const [isConfirmFieldModalOpen, setIsConfirmFieldModalOpen] = useState(false);
    const [pendingField, setPendingField] = useState();
    const [pendingFieldValue, setPendingFieldValue] = useState();
    const [dialogTitle, setDialogTitle] = useState();
    const [showCapacityStationWarning, setShowCapacityStationWarning] = useState(false);
    const [unsavedCapacity, setUnsavedCapacity] = useState();
    const [tempRequireMultiStore, setTempRequireMultiStore] = useState(false);
    const [enableRequireMultiStore, setEnableRequireMultiStore] = useState(false);
    const [openPicker, setOpenPicker] = useState({
        start: false,
        end: false,
    });
    const fleet = fleetId
        ? useSelector(selectFleetById(fleetId))
        : null;
    const isComponentMarkupEditor = useSelector(selectComponentMarkupEditor);
    const partDistributors = useSelector(partDistributorsSelector);
    const partyTypes = useSelector(selectPartyTypes);
    useEffect(() => {
        dispatch(loadCurrentUser());
        dispatch(getPartDistributorWithLocations());
        dispatch(getPartyTypes({}));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        var _a, _b, _c;
        const fmcActive = (fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) !== undefined && ((_a = fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) === null || _a === void 0 ? void 0 : _a.length) > 0;
        const isAutoIntegrate = ((_b = fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) === null || _b === void 0 ? void 0 : _b.find((fmc) => { var _a; return ((_a = fmc === null || fmc === void 0 ? void 0 : fmc.fmcDto) === null || _a === void 0 ? void 0 : _a.submissionMethod) === 'AUTO_INTEGRATE'; })) !== null;
        setEnableRequireMultiStore(fmcActive && isAutoIntegrate);
        setTempRequireMultiStore((_c = fleet === null || fleet === void 0 ? void 0 : fleet.autoIntegrateRequiresMultiStore) !== null && _c !== void 0 ? _c : false);
    }, [fleet]); // eslint-disable-line react-hooks/exhaustive-deps
    const openSaveChildDialog = (field, value, forceSave) => {
        var _a, _b;
        if (((_b = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.childrenIds) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0 && !forceSave) {
            setPendingField(field);
            setPendingFieldValue(value);
            setIsConfirmFieldModalOpen(true);
            setDialogTitle(dialogTitles[field]);
            return true;
        }
        setPendingField(undefined);
        setPendingFieldValue(undefined);
        setIsConfirmFieldModalOpen(false);
        setDialogTitle(undefined);
        return false;
    };
    const getPartDistributors = () => {
        return (partDistributors && [
            ...partDistributors
                .filter((dist) => dist.status === 'ACTIVE')
                .map((item) => ({
                label: item.name,
                value: item.id,
            }))
                .sort((a, b) => a.label.localeCompare(b.label))
                .filter((a) => a.label !== ''),
        ]);
    };
    const handlePatchUpdateFleet = (fleetId, values) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(patchUpdateFleet(fleetId, values));
        if (!(response === null || response === void 0 ? void 0 : response.error) &&
            (values.initParent || values.oldParentId) &&
            values.oldParentId !== values.parentId) {
            toast.success('Parent fleet has been changed. Please re-index the search to complete the update.');
        }
    });
    const handleUpdateField = (field, maxLength, type) => (value) => {
        let newValue = value;
        if (value === fleet[field])
            return;
        if (maxLength && newValue.length > maxLength) {
            newValue = value.slice(0, maxLength);
        }
        let convertToNumber = false;
        if (type && type === 'number') {
            convertToNumber = true;
        }
        const updateField = `update${field.charAt(0).toUpperCase()}${field.slice(1)}`;
        const updateFleetBody = {
            [field]: convertToNumber ? Number(newValue) : newValue,
            [updateField]: true,
        };
        if (field !== 'laborRate') {
            updateFleetBody.laborRate = (fleet === null || fleet === void 0 ? void 0 : fleet.laborRate) || '';
            updateFleetBody.updateLaborRate = true;
        }
        handlePatchUpdateFleet(fleetId, updateFleetBody);
    };
    const handleUpdatePartDistributor = (value) => {
        if (value === (fleet === null || fleet === void 0 ? void 0 : fleet.pricingPartDistributorId))
            return;
        const updateFleetBody = {
            pricingPartDistributorId: value,
            updatePricingPartDistributorId: true,
        };
        handlePatchUpdateFleet(fleetId, updateFleetBody);
    };
    const handleUpdateInternalNotes = (value, forceSave = false, isApplyToChildren = false) => __awaiter(void 0, void 0, void 0, function* () {
        const maxLength = 1000;
        let newValue = value;
        if (value === (fleet === null || fleet === void 0 ? void 0 : fleet.internalNotes))
            return;
        if (maxLength && newValue.length > maxLength) {
            newValue = value.slice(0, maxLength);
        }
        const isDialogOpened = openSaveChildDialog('internalNotes', newValue, forceSave);
        if (!isDialogOpened) {
            yield handlePatchUpdateFleet(fleetId, {
                internalNotes: newValue,
                updateInternalNotes: true,
                applyToChildren: isApplyToChildren,
                laborRate: (fleet === null || fleet === void 0 ? void 0 : fleet.laborRate) === '' ? null : fleet === null || fleet === void 0 ? void 0 : fleet.laborRate,
                updateLaborRate: true,
            });
            analyticsTrackEvent('Fleet Instructions Saved', {
                'Fleet Name': fleet === null || fleet === void 0 ? void 0 : fleet.name,
                'Instructions': newValue,
            });
        }
    });
    const handleToggle = (_e, checked) => __awaiter(void 0, void 0, void 0, function* () {
        setTempRequireMultiStore(checked);
        yield handlePatchUpdateFleet(fleetId, {
            updateAutoIntegrateRequiresMultiStore: true,
            autoIntegrateRequiresMultiStore: checked,
        });
    });
    const handleSubmitVisitPadding = () => {
        if (unsavedCapacity !== undefined) {
            handleUpdateField('visitPaddingMinutes', null, 'number')(unsavedCapacity);
            setUnsavedCapacity(undefined);
            setShowCapacityStationWarning(false);
        }
    };
    const handleVisitPaddingUpdate = (value) => {
        if (value && parseInt(value, 10) > 120) {
            return false;
        }
        let newValue = value;
        if (!newValue) {
            newValue = '0';
        }
        if (fleet === null || fleet === void 0 ? void 0 : fleet.station) {
            setShowCapacityStationWarning(true);
            setUnsavedCapacity(newValue);
            return;
        }
        handleUpdateField('visitPaddingMinutes', null, 'number')(newValue);
    };
    const closeCapacityWarning = () => {
        setShowCapacityStationWarning(false);
        setUnsavedCapacity(undefined);
    };
    const handleSaveField = (isApplyToChildren) => () => {
        switch (pendingField) {
            case 'componentMarkup':
                handleUpdateComponentMarketField(pendingFieldValue || '', true, isApplyToChildren);
                break;
            case 'internalNotes':
                handleUpdateInternalNotes(pendingFieldValue || '', true, isApplyToChildren);
                break;
            default:
            // nothing to see here
        }
    };
    const handleClosePicker = (field) => () => setOpenPicker((prev) => (Object.assign(Object.assign({}, prev), { [field]: false })));
    const handleOpenPicker = (field) => () => setOpenPicker((prev) => (Object.assign(Object.assign({}, prev), { [field]: true })));
    const handleTimeChange = (field) => (time) => {
        var _a;
        const value = time.format(TIME_12H_FORMAT);
        if (value === ((_a = fleet === null || fleet === void 0 ? void 0 : fleet.schedulingWindow) === null || _a === void 0 ? void 0 : _a[field]))
            return;
        handlePatchUpdateFleet(fleetId, {
            schedulingWindow: Object.assign(Object.assign({}, fleet === null || fleet === void 0 ? void 0 : fleet.schedulingWindow), { [field]: value }),
            updateSchedulingWindow: true,
            laborRate: (fleet === null || fleet === void 0 ? void 0 : fleet.laborRate) === '' ? null : fleet === null || fleet === void 0 ? void 0 : fleet.laborRate,
            updateLaborRate: true,
        });
    };
    const handleUpdateComponentMarketField = (value, forceSave = false, isApplyToChildren = false) => __awaiter(void 0, void 0, void 0, function* () {
        if (value === (fleet === null || fleet === void 0 ? void 0 : fleet.componentMarkup))
            return;
        const isDialogOpened = openSaveChildDialog('componentMarkup', value, forceSave);
        if (!isDialogOpened) {
            yield handlePatchUpdateFleet(fleetId, {
                componentMarkup: +value > 0 ? value : null,
                updateComponentMarkup: true,
                applyToChildren: isApplyToChildren,
                laborRate: (fleet === null || fleet === void 0 ? void 0 : fleet.laborRate) === '' ? null : fleet === null || fleet === void 0 ? void 0 : fleet.laborRate,
                updateLaborRate: true,
            });
            analyticsTrackEvent('B2B Fixed Markup Saved', {
                'Fleet Name': fleet === null || fleet === void 0 ? void 0 : fleet.name,
            });
        }
    });
    const handleUpdateEngineOilLimit = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (value === (fleet === null || fleet === void 0 ? void 0 : fleet.engineOilLimit))
            return;
        handlePatchUpdateFleet(fleetId, {
            engineOilLimit: +value > 0 ? value : null,
            updateEngineOilLimit: true,
        });
    });
    const handleUpdateAdditionalQuartPrice = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (value === (fleet === null || fleet === void 0 ? void 0 : fleet.additionalQuartPrice))
            return;
        handlePatchUpdateFleet(fleetId, {
            additionalQuartPrice: +value > 0 ? value : null,
            updateAdditionalQuartPrice: true,
        });
    });
    const handleUpdateInvoiceAutoSend = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (!(fleet === null || fleet === void 0 ? void 0 : fleet.responsibleParty))
            return;
        const rp = {
            id: fleet.responsibleParty.id,
        };
        if (value === '') {
            rp.clearInvoiceAutosendSchedule = true;
        }
        else {
            rp.invoiceAutosendSchedule = value;
        }
        yield dispatch(patchParty(rp));
        toast.success(`Invoice auto send schedule for fleet ${fleet.name} has been updated.`);
        dispatch(getResponsiblePartyByFleetId(fleet.id));
    });
    return {
        fleetId,
        fleet,
        isConfirmFieldModalOpen,
        setIsConfirmFieldModalOpen,
        pendingField,
        setPendingField,
        pendingFieldValue,
        setPendingFieldValue,
        dialogTitle,
        setDialogTitle,
        showCapacityStationWarning,
        setShowCapacityStationWarning,
        unsavedCapacity,
        setUnsavedCapacity,
        openPicker,
        tempRequireMultiStore,
        setTempRequireMultiStore,
        enableRequireMultiStore,
        setEnableRequireMultiStore,
        setOpenPicker,
        dispatch,
        isComponentMarkupEditor,
        partDistributors,
        partyTypes,
        getPartDistributors,
        handleUpdateField,
        handleUpdatePartDistributor,
        handleUpdateInternalNotes,
        openSaveChildDialog,
        handleToggle,
        handleSubmitVisitPadding,
        handleVisitPaddingUpdate,
        closeCapacityWarning,
        handleSaveField,
        handleClosePicker,
        handleOpenPicker,
        handleTimeChange,
        handleUpdateComponentMarketField,
        handleUpdateEngineOilLimit,
        handleUpdateAdditionalQuartPrice,
        handleUpdateInvoiceAutoSend,
    };
};
export default useSettings;
