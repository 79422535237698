import React from 'react';
import { Link } from 'react-router-dom';
import { formatTimestamp } from 'src/utils/formatter';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
const RepairsDashboardListItem = ({ item }) => {
    const { id, name, searchLevels, repairLevel, status, concierge, createdDate, } = item;
    return (<SimpleTableRow data={[
            <Link to={`/pricing/repairs/${id}/edit`}>
          <div>{id}</div>
        </Link>,
            <Link to={`/pricing/repairs/${id}/edit`}>
          <div>{name}</div>
        </Link>,
            searchLevels === null || searchLevels === void 0 ? void 0 : searchLevels.join(', '),
            repairLevel,
            status,
            concierge,
            formatTimestamp(createdDate),
        ]}/>);
};
export default RepairsDashboardListItem;
