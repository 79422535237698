import React from 'react';
import { Tooltip } from 'src/components/tooltips';
import { usMoneyFormatWithCents } from 'src/modules/api/utils/format';
import PartStatus from 'src/PopsApp/components/PartStatus';
import SpecialOrderIndicator from 'src/PopsApp/components/SpecialOrderIndicator';
import { DeleteForever, ExpandMoreRounded as ExpandMoreIcon, } from 'src/components/mui/icons';
import SimpleChip from 'src/components/SimpleChip';
import { SPECIAL_ORDER_TOOLTIP } from 'src/PopsApp/modules/api/constants';
import { colors } from 'src/styles/theme';
import ConfirmationModal from 'src/components/layout/ConfirmationModal';
import { SpecialOrderWrapper, AccordionWrapper, AddFitment, PartCaption, PartDetails, Dots, CaptionWrapper, FitmentCaption, FitmentItem, CaptionWrapperMobile, FitmentActionsMobile, AccordionDetailsWrapperMobile, RightSideWrapperMobile, AccordionMainMobile, } from './Part.styles';
import usePart from './usePart';
const PartMobile = ({ part, onClick, selected, onDelete, onReload, }) => {
    var _a, _b, _c;
    if (!part)
        return null;
    const { expanded, setExpanded, fitmentIdToDelete, setFitmentIdToDelete, handleRemoveComponent, handleRemoveFitment, handleCaptionClick, handleFitmentClick, fitments, isFitment, status, partNumber, quantity, price, } = usePart({ part, onClick, onDelete, onReload });
    const hideAddFitment = part.specialOrder ||
        ['ORDERED', 'ADDED'].includes(status) ||
        ((_a = part === null || part === void 0 ? void 0 : part.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'tire';
    return (<>
      <div style={{ position: 'relative' }}>
        <AccordionWrapper expanded={expanded} selected={selected}>
          <AccordionMainMobile className="accordion-main-mobile" expandIcon={isFitment ? (<ExpandMoreIcon onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
            }}/>) : null} onClick={handleCaptionClick} aria-controls={`panel-content-${part.id}`} id={`panel-header-${part.id}`}>
            <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
            flex: 1,
            maxWidth: '260px',
        }}>
              <div style={{ width: '100%' }}>
                {part.specialOrder ? (<CaptionWrapperMobile>
                    <Tooltip title={SPECIAL_ORDER_TOOLTIP} placement="bottom">
                      <SpecialOrderWrapper>
                        <PartCaption>
                          {(_b = part.name) === null || _b === void 0 ? void 0 : _b.substring(0, 17)}{' '}
                        </PartCaption>
                        <SpecialOrderIndicator />
                      </SpecialOrderWrapper>
                    </Tooltip>
                  </CaptionWrapperMobile>) : (<CaptionWrapperMobile>
                    <PartCaption>{part.name}</PartCaption>
                    <Dots />
                  </CaptionWrapperMobile>)}
                {isFitment && (<div>
                    <SimpleChip className="multipleFitmentsChip" label="MULTIPLE FITMENT" $bgColor={colors.BLUE}/>
                    {!hideAddFitment && (<AddFitment $isFitment variant="text" type="button" onClick={handleFitmentClick}>
                        +Fitment
                      </AddFitment>)}
                  </div>)}
              </div>
              {!isFitment && (<>
                  <PartDetails>
                    Cost: {quantity}x @ {price} • {partNumber}
                  </PartDetails>
                  {!part.specialOrder && !hideAddFitment && (<AddFitment variant="text" type="button" onClick={handleFitmentClick}>
                      +Fitment
                    </AddFitment>)}
                </>)}
              <div />
            </div>
            <RightSideWrapperMobile>
              {isFitment ? (<span style={{ margin: '0 24px' }}>${(_c = part.price) !== null && _c !== void 0 ? _c : 0}</span>) : (<>
                  <PartStatus status={status}/>
                  <DeleteForever color="error" onClick={handleRemoveComponent}/>
                </>)}
            </RightSideWrapperMobile>
          </AccordionMainMobile>

          {isFitment && (<AccordionDetailsWrapperMobile>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column',
                flex: 1,
            }}>
                {fitments.map((fitment) => (<FitmentItem key={fitment.id} selected={fitment.id === part.fitmentId && selected} onClick={() => {
                    onClick(fitment.id);
                }}>
                    <div style={{ flex: 1 }}>
                      <CaptionWrapper>
                        <FitmentCaption>{fitment.partName}</FitmentCaption>
                      </CaptionWrapper>

                      <PartDetails>
                        Cost: {part.quantity}x @{' '}
                        {usMoneyFormatWithCents(fitment.cost)} •{' '}
                        {fitment.partNumber}
                      </PartDetails>
                      <PartStatus status={fitment.status}/>
                    </div>
                    <FitmentActionsMobile>
                      <DeleteForever color="error" onClick={(e) => {
                    e.stopPropagation();
                    setFitmentIdToDelete(fitment.id);
                }} style={{ margin: '0 8px', cursor: 'pointer' }}/>
                    </FitmentActionsMobile>
                  </FitmentItem>))}
              </div>
            </AccordionDetailsWrapperMobile>)}
        </AccordionWrapper>
      </div>

      <ConfirmationModal open={!!fitmentIdToDelete} onClose={() => setFitmentIdToDelete(undefined)} text="Are you sure you want to remove this fitment?" action={() => {
            handleRemoveFitment(fitmentIdToDelete);
        }}/>
    </>);
};
export default PartMobile;
