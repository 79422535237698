var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedRegions } from 'src/AdminApp/modules/swimlanes/actions';
import styled from 'styled-components';
import { Colors } from 'src/PopsApp/modules/globalStyle';
import { Button } from 'src/components/mui';
const ActionsContainer = styled.div `
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${Colors.GRAY_DE};
  width: calc(100% + 31px);
  margin-left: -16px;
`;
const RegionSelectorActions = ({ handleClose, regionIds, }) => {
    const dispatch = useDispatch();
    const handleReset = () => {
        dispatch(setSelectedRegions([]));
        handleApply(true);
    };
    const handleApply = (reset = false) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(setSelectedRegions(reset ? [] : [...regionIds]));
        handleClose();
    });
    return (<ActionsContainer>
      <Button onClick={handleReset}>Reset</Button>
      <Button onClick={() => handleApply()} color="primary">
        Apply
      </Button>
    </ActionsContainer>);
};
export default RegionSelectorActions;
