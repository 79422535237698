import React from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash';
import { useDispatch } from 'react-redux';
import { Button } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { calculateTax } from 'src/AdminApp/modules/invoices/actions';
import { formatTimestamp } from 'src/utils/formatter';
import { canTaxesBeRecalculated } from 'src/AdminApp/containers/requests/Invoice/InvoiceForm/utils';
export const CalculateTaxCTAWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;
const CalculateTaxCTA = ({ invoice, workOrderId, }) => {
    const dispatch = useDispatch();
    const isFormReady = !isNil(invoice.laborTotalPrice) && !isNil(invoice.partsTotalPrice);
    const calculatedDate = formatTimestamp(invoice.lastTaxCalculated, 'llll');
    const title = invoice.lastTaxCalculated
        ? `Tax was calculated: ${calculatedDate}`
        : 'Tax not yet calculated';
    const recalculateTax = canTaxesBeRecalculated(invoice);
    return (<CalculateTaxCTAWrapper>
      <Tooltip title={title}>
        <span>
          <Button disabled={!isFormReady} onClick={() => dispatch(calculateTax({ invoiceId: invoice.id, workOrderId }))} color="primary">
            {recalculateTax ? 'Re-calculate Tax' : 'Calculate Tax'}
          </Button>
        </span>
      </Tooltip>
    </CalculateTaxCTAWrapper>);
};
export default CalculateTaxCTA;
