import React from 'react';
import styled from 'styled-components';
import { Grid, Accordion, AccordionSummary, AccordionDetails, } from 'src/components/mui';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
export const FieldTitle = styled.div `
  font-weight: 600;
  margin-top: 16px;
`;
export const FormSection = ({ title, xs = 12, md = 12, children, }) => (<Grid container item xs={xs} md={md}>
    <Grid item xs={xs} md={md}>
      <FieldTitle>{title}</FieldTitle>
    </Grid>
    {children}
  </Grid>);
export const FormPanel = ({ children, ariaName, title }) => (<Accordion defaultExpanded>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${ariaName}-content`} id={`${ariaName}-header`}>
      <b>{title}</b>
    </AccordionSummary>
    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
      {children}
    </AccordionDetails>
  </Accordion>);
