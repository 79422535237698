var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PartPickupWorkflowState from 'src/TechApp/models/enums/PartPickupWorkflowState';
import { TechActionsBlock } from 'src/TechApp/layout/TechActionsBlock';
import { selectPartPickupOrder, selectPartPickupWorkflow, selectShouldEnableSelectPartCta, } from 'src/TechApp/modules/partpickup/selectors';
import { selectCurrentUserIsPorter } from 'src/AdminApp/modules/users/selectors';
import { selectCurrentUserId } from 'src/modules/auth/selectors';
import { openMaps } from 'src/TechApp/utils';
const PartPickupCTAContainer = ({ address, currentLocation, completeCurrentTask, isRootPage = false, goToChecklistPage, goToRescheduleCancel, isSubmitting = false, hasHubs = false, goToPartPickupTransfer, onClose, }) => {
    var _a;
    const history = useHistory();
    useState(false);
    const partPickupWorkflow = useSelector(selectPartPickupWorkflow);
    const partPickupOrder = useSelector(selectPartPickupOrder);
    const userIsPorter = useSelector(selectCurrentUserIsPorter);
    const userId = useSelector(selectCurrentUserId);
    const shouldEnableSelectPartCta = useSelector(selectShouldEnableSelectPartCta);
    const getLabelCurrentTaskWorkFlow = () => {
        var _a, _b;
        return ((_a = partPickupWorkflow === null || partPickupWorkflow === void 0 ? void 0 : partPickupWorkflow.currentTask) === null || _a === void 0 ? void 0 : _a.state)
            ? PartPickupWorkflowState.display((_b = partPickupWorkflow === null || partPickupWorkflow === void 0 ? void 0 : partPickupWorkflow.currentTask) === null || _b === void 0 ? void 0 : _b.state)
            : 'Start Travel';
    };
    const goToCheckList = () => {
        if (isRootPage) {
            history.push(`/pops/part-pickups/${partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum}/select-parts`);
        }
        else if (goToChecklistPage) {
            goToChecklistPage();
        }
    };
    const goToReschedule = () => {
        if (isRootPage) {
            history.push(`/pops/part-pickups/${partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum}/reschedule`);
        }
        else if (goToRescheduleCancel) {
            goToRescheduleCancel();
        }
    };
    const getPartPickupActions = (currentTask) => {
        const actions = [];
        switch (currentTask) {
            case 'START_TRAVEL':
                actions.push({
                    function: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield completeCurrentTask();
                        openMaps(address, currentLocation);
                    }),
                    copy: getLabelCurrentTaskWorkFlow(),
                    variant: 'contained',
                    disabled: isSubmitting,
                    fullWidth: true,
                });
                actions.push({
                    function: () => __awaiter(void 0, void 0, void 0, function* () {
                        goToReschedule();
                    }),
                    button: 'destructive',
                    copy: 'Reschedule/Cancel',
                    variant: 'outlined',
                    disabled: isSubmitting,
                    fullWidth: true,
                    disableElevation: true,
                });
                actions.push({
                    function: () => {
                        if (goToPartPickupTransfer) {
                            goToPartPickupTransfer();
                        }
                    },
                    variant: 'contained',
                    copy: 'Transfer Pickup',
                    disabled: !hasHubs,
                    fullWidth: true,
                    style: {
                        margin: '10px 0px',
                    },
                });
                break;
            case 'ARRIVED':
                actions.push({
                    function: () => __awaiter(void 0, void 0, void 0, function* () {
                        goToReschedule();
                    }),
                    button: 'destructive',
                    variant: 'outlined',
                    copy: 'Reschedule/Cancel',
                    disabled: isSubmitting,
                    fullWidth: true,
                    disableElevation: true,
                });
                actions.push({
                    function: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield completeCurrentTask();
                    }),
                    button: 'action',
                    copy: getLabelCurrentTaskWorkFlow(),
                    variant: 'contained',
                    disabled: isSubmitting,
                    fullWidth: true,
                });
                break;
            case 'SELECT_PARTS':
                actions.push({
                    function: () => __awaiter(void 0, void 0, void 0, function* () {
                        goToCheckList();
                    }),
                    button: 'action',
                    copy: getLabelCurrentTaskWorkFlow(),
                    variant: 'contained',
                    disabled: !shouldEnableSelectPartCta,
                    fullWidth: true,
                });
                break;
            case 'COMPLETED':
                actions.push({
                    function: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield completeCurrentTask();
                        if (!isRootPage && onClose) {
                            onClose();
                        }
                        if (userIsPorter) {
                            history.push(`/tech/porters/${userId}`);
                        }
                        else {
                            history.push('/tech/my-dashboard');
                        }
                    }),
                    button: 'action',
                    copy: getLabelCurrentTaskWorkFlow(),
                    variant: 'contained',
                    disabled: isSubmitting,
                    fullWidth: true,
                });
                break;
            default:
                break;
        }
        return actions;
    };
    const actions = useMemo(() => {
        var _a, _b;
        return getPartPickupActions((_b = (_a = partPickupWorkflow === null || partPickupWorkflow === void 0 ? void 0 : partPickupWorkflow.currentTask) === null || _a === void 0 ? void 0 : _a.state) !== null && _b !== void 0 ? _b : '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        partPickupWorkflow,
        partPickupWorkflow === null || partPickupWorkflow === void 0 ? void 0 : partPickupWorkflow.currentTask,
        (_a = partPickupWorkflow === null || partPickupWorkflow === void 0 ? void 0 : partPickupWorkflow.currentTask) === null || _a === void 0 ? void 0 : _a.state,
        partPickupOrder,
        hasHubs,
    ]);
    return <TechActionsBlock actions={actions}/>;
};
export default PartPickupCTAContainer;
