import React, { Fragment, useState } from 'react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Button, Collapse, Divider, Grid, Typography, } from 'src/components/mui';
import { formatRepairs } from 'src/AdminApp/components/repairs/statement/utils';
import { StyledGrid } from 'src/AdminApp/containers/requests/PriceDetailsItem';
import { PriceField } from 'src/AdminApp/containers/requests/styledComponents';
import { selectWorkOrderSubletsByRefNum } from 'src/AdminApp/modules/workOrders/selectors';
import SimpleChip from 'src/components/SimpleChip';
import { Arrow } from 'src/components/layout/CollapsibleBlock';
import { colors } from 'src/styles/theme';
const SubletsWrapper = styled.div `
  margin-top: 27px;
`;
const DividerWrapper = styled.div `
  margin: 12px 0;
`;
const priceStyle = { textAlign: 'right' };
const fontNormal = { fontWeight: 'normal' };
const TotalSublet = ({ invoice, onSubletRemove }) => {
    var _a, _b, _c, _d;
    const [opened, setOpened] = useState(false);
    const { referenceNum } = useParams();
    const oldSublets = invoice.sublets;
    const sublets = useSelector(selectWorkOrderSubletsByRefNum(referenceNum));
    const subs = oldSublets.concat(...sublets);
    if (subs.length === 0) {
        return null;
    }
    const { customRequests, knownRepairs } = formatRepairs((_b = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) !== null && _b !== void 0 ? _b : [], [], (_d = (_c = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _c === void 0 ? void 0 : _c.customRequests) !== null && _d !== void 0 ? _d : []);
    const getItems = ({ cost = false, sublet, }) => {
        const items = knownRepairs.reduce((prev, { id, name }) => {
            var _a;
            const item = (_a = sublet === null || sublet === void 0 ? void 0 : sublet.repairItems) === null || _a === void 0 ? void 0 : _a.find(({ suggestedRepairId }) => suggestedRepairId === id);
            if (item) {
                prev.push(cost ? item.subletCost : name);
            }
            return prev;
        }, []);
        const customItems = customRequests.reduce((prev, { suggestedRepairId, name }) => {
            var _a;
            const item = (_a = sublet === null || sublet === void 0 ? void 0 : sublet.repairItems) === null || _a === void 0 ? void 0 : _a.find(({ suggestedRepairId: repairId }) => repairId === suggestedRepairId);
            if (item) {
                prev.push(cost ? item === null || item === void 0 ? void 0 : item.subletCost : name);
            }
            return prev;
        }, []);
        const allItems = [...items, ...customItems];
        const PriceComponent = cost ? PriceField : Fragment;
        return allItems.map((item, index) => (<Typography variant="subtitle1" key={index}>
        <PriceComponent>{item}</PriceComponent>
      </Typography>));
    };
    const getSubletDescription = (sublet) => {
        var _a;
        const { description, suggestedRepairId, repairItems } = sublet;
        // old sublet
        if (!!suggestedRepairId || !!description) {
            let repair = knownRepairs.find((r) => r.id === suggestedRepairId);
            if (!repair) {
                repair = customRequests.find(({ message }) => message === description);
            }
            if (repair) {
                return <Typography variant="subtitle1">{repair.name}</Typography>;
            }
        }
        // new sublet
        if (((_a = repairItems === null || repairItems === void 0 ? void 0 : repairItems.length) !== null && _a !== void 0 ? _a : 0) > 0)
            return getItems({ sublet });
        return <Typography variant="subtitle1">No Description</Typography>;
    };
    const getSubletPrices = (sublet) => {
        var _a;
        const { cost, description, repairItems, suggestedRepairId } = sublet;
        if (!!suggestedRepairId || !!description) {
            return (<Typography variant="subtitle1">
          <PriceField>{cost}</PriceField>
        </Typography>);
        }
        if (((_a = repairItems === null || repairItems === void 0 ? void 0 : repairItems.length) !== null && _a !== void 0 ? _a : 0) > 0) {
            return getItems({
                cost: true,
                sublet,
            });
        }
    };
    return (<span>
      <StyledGrid container>
        <Grid item md={6}>
          <SimpleChip $bgColor={colors.orange} $textColor={colors.black} label="Total Sublet"/>
          {'  '}
          <Button size="small" color="primary" onClick={() => setOpened(!opened)}>
            View Sublet Info &nbsp; &nbsp; &nbsp; &nbsp;
            <Arrow $opened={opened} $color={colors.primary}/>
          </Button>
        </Grid>
        <Grid item md={6}>
          <PriceField>{invoice.totalSublet}</PriceField>
        </Grid>
      </StyledGrid>
      <Collapse in={opened}>
        <SubletsWrapper>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Shop Info</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1">Service</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" style={priceStyle}>
                Cost
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DividerWrapper>
                <Divider />
              </DividerWrapper>
            </Grid>
            {map(subs, (sublet) => {
            var _a, _b;
            return (<Fragment key={sublet.id}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{sublet.shopName}</Typography>
                  <Typography variant="subtitle1" style={fontNormal}>
                    {`${sublet.shopAddress}, ${sublet.shopCity}, ${sublet.shopState} ${sublet.shopZip}`}
                  </Typography>
                  <Typography variant="subtitle1" style={fontNormal}>
                    {`Contact: ${(_a = sublet === null || sublet === void 0 ? void 0 : sublet.shopContactName) !== null && _a !== void 0 ? _a : 'N/A'} • ${(_b = sublet === null || sublet === void 0 ? void 0 : sublet.shopPhone) !== null && _b !== void 0 ? _b : 'N/A'}`}
                  </Typography>
                  <Button style={{ color: colors.error }} onClick={() => onSubletRemove({
                    repairId: sublet === null || sublet === void 0 ? void 0 : sublet.suggestedRepairId,
                    subletId: sublet === null || sublet === void 0 ? void 0 : sublet.id,
                })}>
                    Remove Sublet
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  {getSubletDescription(sublet)}
                </Grid>
                <Grid item xs={3}>
                  {getSubletPrices(sublet)}
                </Grid>
              </Fragment>);
        })}
          </Grid>
        </SubletsWrapper>
      </Collapse>
    </span>);
};
export default TotalSublet;
