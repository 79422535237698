import { Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid } from 'src/components/mui';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
import PayerSummary from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/PayerSummary';
import { selectTheOnlyOneResponsiblePartyIsPrimary } from 'src/AdminApp/modules/requests/selectors';
import { PriceBreakdownInputField, PriceBreakdownLaborField, PriceBreakdownPriceField, PriceBreakdownTaxInputField, } from 'src/AdminApp/containers/requests/fieldComponents';
import { FieldDivider } from 'src/AdminApp/containers/requests/styledComponents';
import useIsMobile from 'src/hooks/useIsMobile';
export const QuoteDetailsWrapper = styled.div `
  position: relative;
  border-top: 1px dashed ${colors.lightGrey};
`;
export const Total = styled.div `
  display: flex;
  flex-direction: column;
  color: ${colors.darkGrey};
  margin-top: 15px;

  & > div:nth-of-type(odd) {
    background-color: rgba(63, 63, 63, 0.04);
  }
`;
const QuoteSchema = Yup.object().shape({
    hazardousMaterialsFee: Yup.number().min(0).nullable(),
    deliveryFee: Yup.number().min(0).nullable(),
    laborTaxRate: Yup.number().max(99.99).min(0).nullable(),
    partsTaxRate: Yup.number().max(99.99).min(0).nullable(),
    shopSuppliesFee: Yup.number().min(0).nullable(),
    adjustments: Yup.number().negative().max(0).nullable(),
    totalPrice: Yup.number().typeError('Price must include a number').nullable(),
    totalFees: Yup.number().min(0).nullable(),
    subtotal: Yup.number().min(0).nullable(),
    totalTaxes: Yup.number().min(0).nullable(),
    discounts: Yup.number().nullable(),
});
const QuoteDetails = ({ quote, totalLaborDuration, totalJobDurationMinutes, workOrderResponsiblePartyTypeTotals, }) => {
    var _a;
    const isMobile = useIsMobile();
    const { referenceNum } = usePageReferenceNum();
    const [currentEditField] = useState();
    const hasOnlyOneResponsiblePartyType = useSelector(selectTheOnlyOneResponsiblePartyIsPrimary(referenceNum));
    const totalJobDuration = totalJobDurationMinutes
        ? Math.round((totalJobDurationMinutes / 60) * 100) / 100
        : 0.0;
    const showMultiplyResponsiblePartyTypeTotals = ((_a = workOrderResponsiblePartyTypeTotals === null || workOrderResponsiblePartyTypeTotals === void 0 ? void 0 : workOrderResponsiblePartyTypeTotals.length) !== null && _a !== void 0 ? _a : 0) > 1 &&
        !hasOnlyOneResponsiblePartyType;
    return (<Formik initialValues={quote} enableReinitialize validationSchema={QuoteSchema} onSubmit={() => {
            // Quote price details are not editable yet
        }}>
      {({ values }) => {
            var _a;
            const cancelHandler = () => {
                // Quote price details are not editable yet
            };
            const submitHandler = () => {
                // Quote price details are not editable yet
            };
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const editHandler = (fieldName) => () => {
                // Quote price details are not editable yet
            };
            return (<Grid container>
            <Grid item xs={12}>
              <Total>
                <PriceBreakdownLaborField leftPart={isMobile
                    ? `${totalJobDuration}h`
                    : `repairs: ${totalLaborDuration || 0.0}h - job: ${totalJobDuration}h`} label="Labors">
                  {quote === null || quote === void 0 ? void 0 : quote.laborTotalPrice}
                </PriceBreakdownLaborField>
                <PriceBreakdownPriceField label="Parts">
                  {quote === null || quote === void 0 ? void 0 : quote.partsTotalPrice}
                </PriceBreakdownPriceField>
                <PriceBreakdownInputField edit={currentEditField === 'shopSuppliesFee'} onEdit={editHandler('shopSuppliesFee')} onSubmit={submitHandler} onCancel={cancelHandler} label="Shop Supplies Fee" name="shopSuppliesFee" initialValue={quote === null || quote === void 0 ? void 0 : quote.shopSuppliesFee} editable={false}/>
                <PriceBreakdownInputField edit={currentEditField === 'hazardousMaterialsFee'} onEdit={editHandler('hazardousMaterialsFee')} onSubmit={submitHandler} onCancel={cancelHandler} label="Environmental Fee" name="hazardousMaterialsFee" initialValue={quote === null || quote === void 0 ? void 0 : quote.hazardousMaterialsFee} editable={false}/>
                <PriceBreakdownInputField edit={currentEditField === 'deliveryFee'} onEdit={editHandler('deliveryFee')} onSubmit={submitHandler} onCancel={cancelHandler} label="Mobile Service Charge" name="deliveryFee" initialValue={quote === null || quote === void 0 ? void 0 : quote.deliveryFee} editable={false}/>
                <PriceBreakdownPriceField label="Total Fees">
                  {quote === null || quote === void 0 ? void 0 : quote.totalFees}
                </PriceBreakdownPriceField>
                <FieldDivider />
                <PriceBreakdownPriceField label="Subtotal">
                  {quote === null || quote === void 0 ? void 0 : quote.subtotal}
                </PriceBreakdownPriceField>
                <PriceBreakdownPriceField label="Discounts">
                  {-((_a = quote === null || quote === void 0 ? void 0 : quote.discounts) !== null && _a !== void 0 ? _a : 0)}
                </PriceBreakdownPriceField>
                <PriceBreakdownInputField edit={currentEditField === 'adjustments'} onEdit={editHandler('adjustments')} onSubmit={submitHandler} onCancel={cancelHandler} label="Adjustments" name="adjustments" initialValue={quote === null || quote === void 0 ? void 0 : quote.adjustments} editable={false}/>
                <PriceBreakdownTaxInputField edit={currentEditField === 'partsTax'} onEdit={editHandler('partsTax')} onSubmit={submitHandler} onCancel={cancelHandler} label="Parts Taxes" name="partsTax" initial={quote} values={values} editable={false} formatTax/>
                <PriceBreakdownTaxInputField edit={currentEditField === 'laborTax'} onEdit={editHandler('laborTax')} onSubmit={submitHandler} onCancel={cancelHandler} label="Labor Taxes" name="laborTax" initial={quote} values={values} editable={false} formatTax/>
                <FieldDivider />
                <PriceBreakdownPriceField label="Total Taxes">
                  {quote === null || quote === void 0 ? void 0 : quote.totalTaxes}
                </PriceBreakdownPriceField>
                <FieldDivider />

                {showMultiplyResponsiblePartyTypeTotals &&
                    (workOrderResponsiblePartyTypeTotals === null || workOrderResponsiblePartyTypeTotals === void 0 ? void 0 : workOrderResponsiblePartyTypeTotals.map((worpt) => (<PayerSummary key={`${worpt.name}`} totalText={`${worpt.name} Total:`} totalPrice={worpt.isPrimary
                            ? quote === null || quote === void 0 ? void 0 : quote.totalPrice
                            : worpt.totalAmount}/>)))}
                {!showMultiplyResponsiblePartyTypeTotals &&
                    !hasOnlyOneResponsiblePartyType && (<PayerSummary totalPrice={quote === null || quote === void 0 ? void 0 : quote.totalPrice}/>)}
                {!showMultiplyResponsiblePartyTypeTotals &&
                    hasOnlyOneResponsiblePartyType && (<PriceBreakdownPriceField large label="Total">
                      {quote === null || quote === void 0 ? void 0 : quote.totalPrice}
                    </PriceBreakdownPriceField>)}
              </Total>
            </Grid>
          </Grid>);
        }}
    </Formik>);
};
export default QuoteDetails;
