import { Divider, Typography } from 'src/components/mui';
import { LocationOn as LocationOnIcon } from 'src/components/mui/icons';
import React, { useEffect } from 'react';
import { AddressFieldsWrapper, AddressTypography, LocationIconWrapper, SearchAddressTitle, SelectAddressButton, SlideWrapper, } from 'src/AdminApp/components/drawers/styledComponents';
import ContactInfo from 'src/AdminApp/containers/requests/ContactInfo';
import { States } from 'src/AdminApp/models/enums';
import { setContinueDisabled } from 'src/AdminApp/modules/quickRo/actions';
import { CONSUMER_TABS as TABS } from 'src/AdminApp/modules/quickRo/enums';
import PlacesACWithServiceAreas from 'src/components/form/PlacesACWithServiceAreas';
import { ConfirmationModal, SimpleModal } from 'src/components/layout';
import { FooterActionButton, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import AddRepairOrderDrawer from '../AddRepairOrderDrawer';
import Slider from '../AddRepairOrderDrawer/Slider';
import SubHeader from '../AddRepairOrderDrawer/SubHeader';
import useAddWarrantyClaim from './useAddWarrantyClaim';
const isFirstScreen = (tab) => {
    return (tab === TABS.ADD_NEW_CUSTOMER ||
        tab === TABS.EXISTING_CUSTOMER ||
        tab === TABS.SEARCH_ADDRESS);
};
const AddressFields = ({ street, city, state, zip }) => {
    const stateShort = States.findByValue(state) || '';
    return (<AddressFieldsWrapper>
      <LocationIconWrapper>
        <LocationOnIcon />
      </LocationIconWrapper>
      <div style={{ paddingLeft: '80px' }}>
        <Typography variant="h5">{street}</Typography>
        <AddressTypography>
          {city}, {stateShort} {zip}
        </AddressTypography>
      </div>
    </AddressFieldsWrapper>);
};
const AddWarrantyClaimDrawer = ({ open, onClose, referenceNum, isFollowup, isRescheduleConfirmed, isRescheduleCancelled, isSchedule, selectedUser, vehicle, logo, marketingSource, }) => {
    const { isLoading, isDuplicateCarModalOpen, setIsDuplicateCarModalOpen, confirmationModalOpen, setConfirmationModalOpen, tab, user, customConsumerAddress, customVehicle, closeDrawerHandler, getOnClickNext, handleSelectCustomerAddress, handleSetTab, handleSetUser, onAddNewWarranty, } = useAddWarrantyClaim(open, onClose, referenceNum);
    useEffect(() => {
        if (selectedUser && !user) {
            selectedUser.id = selectedUser.consumerId;
            handleSetUser(selectedUser);
        }
    }, [selectedUser, user, handleSetUser]);
    const showArrowClose = isSchedule || isRescheduleConfirmed || isRescheduleCancelled || isFollowup;
    const getIsContinueDisabled = () => {
        if (tab === TABS.EXISTING_CUSTOMER) {
            return !((user === null || user === void 0 ? void 0 : user.address) && (user === null || user === void 0 ? void 0 : user.id));
        }
        return false;
    };
    const getOnNextCta = () => tab === TABS.EXISTING_CUSTOMER ? 'Create Warranty' : 'Continue';
    const renderExistingCustomerTab = () => {
        var _a, _b, _c, _d, _e;
        return (<SlideWrapper>
        {selectedUser && (<>
            <ContactInfo {...selectedUser} id={selectedUser.consumerId} name={selectedUser.name} referenceNum={referenceNum} vehicle={vehicle} consumerContactInfo={selectedUser} logo={logo} canEdit={false} marketingSource={marketingSource}/>
            <Divider />
            {user && ((_a = user.address) === null || _a === void 0 ? void 0 : _a.zip) && (<>
                <AddressFields street={(_b = user.address) === null || _b === void 0 ? void 0 : _b.streetLine1} city={(_c = user.address) === null || _c === void 0 ? void 0 : _c.city} state={(_d = user.address) === null || _d === void 0 ? void 0 : _d.state} zip={(_e = user.address) === null || _e === void 0 ? void 0 : _e.zip}/>
                <Divider />
              </>)}
            <SelectAddressButton onClick={() => {
                    handleSetTab(TABS.SEARCH_ADDRESS);
                }}>
              Select a different address
            </SelectAddressButton>
          </>)}
      </SlideWrapper>);
    };
    const renderSearchAddress = () => {
        return (<SlideWrapper>
        <SearchAddressTitle>Search Address</SearchAddressTitle>
        <PlacesACWithServiceAreas address={customConsumerAddress} onSelect={(value) => handleSelectCustomerAddress(value)} textFieldParams={{
                variant: 'outlined',
                label: 'Address',
            }}/>
      </SlideWrapper>);
    };
    const routes = [
        {
            name: TABS.EXISTING_CUSTOMER,
            render: renderExistingCustomerTab,
        },
        { name: TABS.SEARCH_ADDRESS, render: renderSearchAddress },
    ];
    const navigateBackHandler = () => {
        if (tab === TABS.ADD_SERVICES) {
            handleSetTab(TABS.EXISTING_CUSTOMER);
        }
        else {
            closeDrawerHandler();
        }
    };
    const handleConfirmDuplicateCar = () => {
        setIsDuplicateCarModalOpen(false);
        setContinueDisabled(false);
    };
    return (<>
      <ConfirmationModal open={confirmationModalOpen} text="" action={onAddNewWarranty} onClose={() => setConfirmationModalOpen(false)}/>
      <AddRepairOrderDrawer open={open} onClose={closeDrawerHandler} onNavigateBack={navigateBackHandler} header="AutoNation Mobile Service Warranty Claim" isExpanded={tab ? tab === TABS.SELECT_APPOINTMENT : false} isSmall={tab ? tab === TABS.RESET_PARTS : false} arrowClose={showArrowClose} isLoading={isLoading} subheader={isFirstScreen(tab) && (<SubHeader tabs={[
                { label: 'Existing Customer', value: TABS.EXISTING_CUSTOMER },
            ]} toOmit={[TABS.SEARCH_ADDRESS]} tab={tab} onChange={(event, tab) => handleSetTab(tab)}/>)} footer={<SimpleDrawerFooter>
            <FooterActionButton disabled={getIsContinueDisabled()} onClick={getOnClickNext()}>
              {getOnNextCta()}
            </FooterActionButton>
          </SimpleDrawerFooter>}>
        <Slider currentSlide={tab} slides={routes}/>
      </AddRepairOrderDrawer>
      <SimpleModal open={isDuplicateCarModalOpen} onClose={() => setIsDuplicateCarModalOpen(false)} title="Car already exists" simpleSingleButtonRowProps={{
            submitText: 'Okay',
            onSubmit: handleConfirmDuplicateCar,
        }}>
        You already have a {customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.year} {customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.model}{' '}
        {customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.make}. Are you certain you want to create a duplicate?
      </SimpleModal>
    </>);
};
export default AddWarrantyClaimDrawer;
