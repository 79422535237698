import { Button } from 'src/components/mui';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import SavedCards from 'src/AdminApp/containers/payments/PaymentDrawer/SavedCards';
import { selectConsumerMethods } from 'src/AdminApp/modules/payments/selectors';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import { SliderFooter } from 'src/components/SimpleDrawerPaged';
function validateCard(value) {
    let error;
    if (!value) {
        error = 'Card is required.';
    }
    return error;
}
const SavedCardsForm = ({ onSubmit, altFlow, useAnotherScreen, }) => {
    const { referenceNum } = useParams();
    const params = { match: { params: { referenceNum } } };
    const cards = useSelector((state) => selectConsumerMethods(state, params));
    const defaultPaymentMethod = cards.find((card) => card.defaultMethod);
    return (<div>
      <Formik initialValues={{
            activeCard: defaultPaymentMethod,
        }} validateOnMount enableReinitialize onSubmit={(values, { setSubmitting, resetForm }) => {
            var _a, _b, _c;
            const { activeCard } = values;
            if (!activeCard)
                return;
            onSubmit(setSubmitting, resetForm, (_a = values.activeCard) === null || _a === void 0 ? void 0 : _a.token, (_b = values.activeCard) === null || _b === void 0 ? void 0 : _b.methodType, (_c = values.activeCard) === null || _c === void 0 ? void 0 : _c.id);
        }}>
        {({ isSubmitting, handleSubmit, isValid }) => (<Form>
            <Field name="activeCard" validate={validateCard} render={({ field, form }) => {
                const handleOnChange = (card) => {
                    form.setFieldValue(field.name, card);
                };
                return <SavedCards onChange={handleOnChange}/>;
            }}/>
            <br />
            <Button variant="text" color="primary" onClick={useAnotherScreen}>
              Add Another Card
            </Button>
            <br />
            <Button variant="text" color="primary" onClick={altFlow}>
              Use a Different Payment Method
            </Button>

            <SliderFooter style={{ marginLeft: '-16px', position: 'absolute' }}>
              <FooterActionButton id="spreedly-submit-button" disabled={isSubmitting || !isValid} onClick={() => handleSubmit()}>
                Charge Now
              </FooterActionButton>
            </SliderFooter>
          </Form>)}
      </Formik>
    </div>);
};
export default SavedCardsForm;
