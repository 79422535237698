import { useEffect } from 'react';
const useChartScroll = (jobsContainerRef) => {
    const scrollSnap = (direction) => {
        const scrollAmount = direction === 'right' ? 90 : -90;
        if (jobsContainerRef) {
            jobsContainerRef.scrollBy({
                left: scrollAmount,
                behavior: 'smooth',
            });
        }
    };
    useEffect(() => {
        if (jobsContainerRef) {
            jobsContainerRef.scrollBy({
                left: 630,
                behavior: 'smooth',
            });
        }
    }, [jobsContainerRef]);
    return {
        scrollSnap,
    };
};
export default useChartScroll;
