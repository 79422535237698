var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Autocomplete, createFilterOptions, Box, } from 'src/components/mui';
import { getConsumers } from 'src/AdminApp/modules/consumers/actions';
import { selectDashboardConsumers } from 'src/AdminApp/modules/consumers/selectors';
import { formatPhone } from 'src/utils/formatter';
import useDebounce from 'src/modules/api/utils/useDebounce';
const SearchUsersField = ({ label, onSelect, }) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [abortController, setAbortController] = useState();
    const debouncedSearch = useDebounce(inputValue, 500);
    const getUsers = (query, signal) => {
        dispatch(getConsumers({
            query,
            pageSize: 10,
            pageIndex: 0,
            sort: ['id', 'desc'],
            signal,
        }));
    };
    const users = useSelector(selectDashboardConsumers);
    useEffect(() => {
        if (abortController && abortController.abort) {
            abortController.abort();
        }
        const newAbortCtrl = window.AbortController && new window.AbortController();
        const signal = newAbortCtrl && newAbortCtrl.signal;
        setAbortController(newAbortCtrl);
        (() => __awaiter(void 0, void 0, void 0, function* () {
            getUsers(debouncedSearch, signal);
        }))();
    }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps
    const options = [...users];
    const onChange = (u) => {
        onSelect(u);
    };
    const filterOptions = createFilterOptions({
        stringify: (option) => `${option.firstName} ${option.lastName} ${option.email} ${option.phone || ''}`,
    });
    const renderOptionsLabel = (props, option) => {
        return (<Box component="li" {...props}>
        {option.firstName} {option.lastName} <i>{option.email}</i>{' '}
        {option.phone ? formatPhone(option.phone) : ''}
      </Box>);
    };
    return (<Autocomplete style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option, value) => option.fullName === value.fullName} clearOnBlur={false} options={options} getOptionLabel={(option) => `${option.firstName} ${option.lastName} ${option.email} ${option.phone ? formatPhone(option.phone) : ''}`} renderOption={renderOptionsLabel} onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }} onChange={(e, value) => {
            onChange(value);
        }} filterOptions={filterOptions} renderInput={(params) => (<TextField variant="outlined" label={label} {...params} InputProps={Object.assign({}, params.InputProps)}/>)}/>);
};
export default SearchUsersField;
