var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid, MenuItem, TextField } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import { DrawerFormRow, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { delayFor } from 'src/utils/delay';
import * as Yup from 'yup';
const positionOptions = [
    'FL',
    'FR',
    'RL',
    'RR',
    'RLI',
    'RRI',
    'RFLI',
    'RFL',
    'RFRI',
    'RFR',
    'S',
    'U',
];
const AdditionalDetailsDrawerForm = ({ part, handleClose, setSelectedPart, }) => {
    const FormSchema = Yup.object().shape({
        manufacturer: Yup.string().required(),
        model: Yup.string().min(1).max(100).required(),
        aspectRatio: Yup.number().min(0).integer().required(),
        rimDiameter: Yup.number()
            .test('maxDigitsAfterDecimal', 'rimDiameter field must have 1 digits after decimal or less', (number) => /^\d{0,3}(?:\.\d)?$/.test(number))
            .required(),
        loadRange: Yup.string().max(3).required(),
        speedRating: Yup.string().max(3).required(),
        size: Yup.string().min(6).max(40).required(),
        position: Yup.string().required(),
        width: Yup.number().integer().required(),
    });
    const handleSave = (values) => {
        setSelectedPart(Object.assign(Object.assign({}, part), { partMetadata: values }));
    };
    return (<Formik initialValues={Object.assign({}, part === null || part === void 0 ? void 0 : part.partMetadata)} validationSchema={FormSchema} validateOnMount onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            setSubmitting(true);
            handleSave(values);
            yield delayFor(1000);
            setSubmitting(false);
            handleClose();
        })}>
      {({ handleSubmit, isSubmitting, isValid, isValidating, setFieldValue, dirty, values, }) => {
            return (<>
            <SimpleDrawerContent>
              <Grid container spacing={1}>
                <DrawerFormRow item xs={12}>
                  <TextField variant="outlined" value={values === null || values === void 0 ? void 0 : values.manufacturer} label="Manufacturer e.g. GOODYEAR" type="text" name="manufacturer" fullWidth onChange={(event) => {
                    setFieldValue('manufacturer', event.target.value);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow item xs={12}>
                  <TextField variant="outlined" value={values === null || values === void 0 ? void 0 : values.model} label="Model e.g. G647 RSS" type="text" name="model" fullWidth onChange={(event) => {
                    setFieldValue('model', event.target.value);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow item xs={12}>
                  <TextField variant="outlined" value={values === null || values === void 0 ? void 0 : values.width} type="number" name="width" label="Width e.g. 205" fullWidth onChange={(event) => {
                    setFieldValue('width', event.target.valueAsNumber);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow item xs={12}>
                  <TextField variant="outlined" value={values === null || values === void 0 ? void 0 : values.aspectRatio} type="number" name="aspectRatio" label="Aspect Ratio e.g. 70" fullWidth onChange={(event) => {
                    setFieldValue('aspectRatio', event.target.valueAsNumber);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow item xs={12}>
                  <TextField variant="outlined" value={values === null || values === void 0 ? void 0 : values.rimDiameter} type="number" name="rimDiameter" label="Rim Diameter e.g. 19.5" fullWidth onChange={(event) => {
                    setFieldValue('rimDiameter', event.target.valueAsNumber);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow item xs={12}>
                  <TextField variant="outlined" value={values === null || values === void 0 ? void 0 : values.loadRange} type="text" name="loadRange" label="Load Range e.g. G" fullWidth onChange={(event) => {
                    setFieldValue('loadRange', event.target.value);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow item xs={12}>
                  <TextField variant="outlined" value={values === null || values === void 0 ? void 0 : values.speedRating} type="text" name="speedRating" label="Speed Rating e.g. S" fullWidth onChange={(event) => {
                    setFieldValue('speedRating', event.target.value);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow item xs={12}>
                  <TextField variant="outlined" value={values === null || values === void 0 ? void 0 : values.size} type="text" name="size" label="Size e.g. 225/70R19.5" fullWidth onChange={(event) => {
                    setFieldValue('size', event.target.value);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow item xs={12}>
                  <TextField variant="outlined" value={values === null || values === void 0 ? void 0 : values.position} select name="position" label="Position" fullWidth onChange={(event) => {
                    setFieldValue('position', event.target.value);
                }}>
                    {positionOptions.map((item, index) => (<MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>))}
                  </TextField>
                </DrawerFormRow>
              </Grid>
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <SimpleTwoButtonRow disabledSubmit={!isValid || isValidating || isSubmitting || !dirty} submitText="Save" cancelText="Cancel" onCancel={() => handleClose()} onSubmit={handleSubmit}/>
            </SimpleDrawerFooter>
          </>);
        }}
    </Formik>);
};
export default AdditionalDetailsDrawerForm;
