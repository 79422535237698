import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'src/components/mui';
import { loadRequestHistory } from 'src/AdminApp/modules/requests/actions';
import RequestHistoryDrawer from './RequestHistoryDrawer';
const StyledList = styled.ul `
  list-style-type: none;
  padding: 0;
  margin: 24px 0 0 0;
`;
const StyledButton = styled(Button) `
  padding: 0px;
`;
const StyledLink = styled(Link) `
  text-decoration: none;
`;
const RequestHistoryModal = ({ history, repairRequest, }) => {
    const [open, setOpen] = useState(false);
    const closeHandler = () => setOpen(false);
    const dispatch = useDispatch();
    const openDrawer = () => {
        dispatch(loadRequestHistory({
            id: repairRequest.id,
            referenceNum: repairRequest.referenceNum,
        }));
        setOpen(true);
    };
    return (<>
      <StyledList>
        <li>
          <StyledButton color="primary" onClick={openDrawer}>
            View History
          </StyledButton>
        </li>
        {repairRequest && (<li>
            <StyledButton>
              <StyledLink to={`/system/snapshots/requests/${repairRequest.referenceNum}`} title="Snapshot Browser" target="_blank">
                View Snapshots
              </StyledLink>
            </StyledButton>
          </li>)}
      </StyledList>
      <RequestHistoryDrawer open={open} closeHandler={closeHandler} history={history}/>
    </>);
};
export default RequestHistoryModal;
