import CONFIG from 'src/config';
import { PrefixHandler } from 'src/utils/url-utils';
const API_URLS = {
    REPAIRS: {
        REPAIR_BY_ID: (id) => `/pricing-service/admin/repairs/${id}`,
        REPAIRS: () => '/pricing-service/admin/repairs/',
        SEARCH_REPAIRS: () => '/pricing-service/admin/repairs/dashboard',
        SEARCH_BASE_PRODUCTS: () => '/pricing-service/admin/products/search/base',
        REPAIR_MEASUREMENTS: () => '/pricing-service/admin/repairs/measurement',
        UPDATE_DEFAULT_RESPONSIBLE_PARTY: () => '/repair-service/admin/default-product-responsible-party',
        DEFAULT_RESPONSIBLE_PARTY: ({ id }) => `/repair-service/admin/default-product-responsible-party/${id}`,
        GET_DEFAULT_RESPONSIBLE_PARTY_INFO: ({ productId, }) => `/repair-service/admin/default-product-responsible-party/product/${productId}/customerType/CONSUMER`,
    },
    COMPONENTS: {
        COMPONENT_BY_ID: (id) => `/pricing-service/admin/components/${id}`,
        SEARCH_COMPONENTS: () => '/pricing-service/admin/components/dashboard',
        COMPONENTS: () => '/pricing-service/admin/components',
        COMPONENTS_BY_REPAIR_ID: (repairId) => `/pricing-service/admin/components/repair/${repairId}`,
    },
    MANUFACTURERS: {
        MANUFACTURERS: () => '/pricing-service/admin/manufacturers',
        CATALOG_MANUFACTURER: () => '/pricing-service/admin/catalog-manufacturers',
        CATALOG_MANUFACTURER_BY_ID: (id) => `/pricing-service/admin/catalog-manufacturers/${id}`,
        SEARCH_MANUFACTURERS: () => '/pricing-service/admin/manufacturers',
    },
    MEASUREMENTS: {
        MEASUREMENTS: () => '/pricing-service/admin/repair/measurements',
        ITEMS_BY_MEASUREMENT_ID: (measurementId) => `/pricing-service/admin/repair/measurements/items/${measurementId}`,
        MEASUREMENTS_BY_REPAIR_ID: (repairId) => `/pricing-service/admin/repair/measurements/${repairId}`,
        MEASUREMENTS_BY_ID: (id) => `/pricing-service/admin/repair/measurements/${id}`,
    },
    UTILS: {
        PRICING_RULES_CSV: () => '/pricing-service/admin/component-pricing-rules/upload',
        DYNAMIC_COMPONENT_RULES_CSV: () => '/pricing-service/admin/dynamic-component-rules/upload',
    },
    RULES: {
        RULES_BY_FLEET_ID: (fleetId) => `/pricing-service/admin/component-pricing-rules/fleet/${fleetId}`,
        FLEET_PRICING_RULES: () => '/pricing-service/admin/fleet/pricing-rules',
        REPAIR_CONCIERGE_RULES_BY_REPAIR_ID: (repairId) => `/pricing-service/admin/fleet/pricing-rules/concierge/rules/${repairId}`,
    },
};
const API_PROXY = new Proxy(API_URLS, PrefixHandler(CONFIG.CLIENT_API_URL));
export default Object.assign({}, API_PROXY);
