import styled from 'styled-components';
import { Button, Dialog } from 'src/components/mui';
import { theme, colors, STATIC_URL_BASE } from 'src/styles/theme';
export const DialogContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;

  .filterDialogMultiOption {
    min-width: 250px;
    margin-right: 16px;
  }
`;
export const DialogTitle = styled.h2 `
  font-size: 30px;
  font-weight: bold;
  color: ${colors.black};
  margin: 0 0 24px;
  padding: 0;
`;
export const Appointments = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .apptText {
    font-size: 12px;
    color: ${colors.GRAY_76};
    padding: 32px 8px;
  }

  ${theme.breakpoints.down('sm')} {
    margin: 16px 0;
  }
`;
export const Section = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0 0 16px;

  .sectionTitle {
    font-size: 12px;
    color: ${colors.GRAY_76};
    font-weight: bold;
    margin: 0 0 8px;

    &--location {
      margin: 0;
    }
  }

  .sectionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const ChipButton = styled.div `
  margin-right: 8px;
  display: inline-block;
`;
export const ActionsSection = styled.div `
  padding: 24px 16px;
  border-top: solid 1px ${colors.GRAY_DE};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
export const ActionButton = styled(Button) `
  font-size: 16px;
  padding: 16px;
`;
export const FilterContainer = styled(Dialog) `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: ${colors.white};
  transition: transform 0.5s ease, opacity 0.3s 0.1s ease;

  ${theme.breakpoints.up('md')} {
    display: none;
  }

  .filterHeader {
    height: 56px;
    background-color: ${colors.YELLOW_E1};
    padding: 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .filterTitle {
    font-size: 16px;
    font-weight: 700;
    flex: 1;
    text-align: center;
  }

  .filterReset {
    font-size: 16px;
    font-weight: 700;
    height: 24px;
    border: none;
    background-color: transparent;
    color: ${colors.BLUE};
  }

  .filterClose {
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    background-image: url(${STATIC_URL_BASE}pops/icons/close.svg);
  }

  .filterContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 16px;
    overflow-y: auto;
  }

  .filterActions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 16px;
    background-image: linear-gradient(
      to bottom,
      ${colors.GRAY_EBED},
      ${colors.GRAY_F2} 75%
    );
  }

  .filterAction {
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
    background-color: ${colors.BLUE};
    font-size: 16px;
    padding: 16px;
    color: ${colors.white};
    font-weight: 700;
    border: none;
    cursor: pointer;
  }
`;
