import React from 'react';
import { TextField, Typography } from 'src/components/mui';
import styled from 'styled-components';
import InfoStatus from 'src/AdminApp/components/repairs/InfoStatus';
import EditIcon from 'src/components/icons/EditIcon';
import { Module } from 'src/components/layout';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import useConsumerInternalNotes from './useConsumerInternalNotes';
const Wrapper = styled.div `
  padding: 0 22px 34px 18px;
`;
const ConsumerInternalNotes = ({ canEdit = true, consumerId, }) => {
    const { internalNote, isEditing, newNotes, handleEditIconClick, handleCancelClick, handleSubmitClick, handleNotesChange, } = useConsumerInternalNotes({ consumerId });
    return (<Module title="Notes" actionButton={canEdit ? <EditIcon onClick={handleEditIconClick}/> : null}>
      <Wrapper>
        {isEditing ? (<>
            <TextField fullWidth multiline rows={3} value={newNotes} onChange={handleNotesChange} variant="outlined"/>
            <SimpleTwoButtonRow cancelText="No" submitText="Yes" onCancel={handleCancelClick} onSubmit={handleSubmitClick}/>
          </>) : (newNotes || (<Typography variant="subtitle1">
              {(internalNote === null || internalNote === void 0 ? void 0 : internalNote.content) || 'Add Internal Notes...'}
            </Typography>))}
      </Wrapper>
      <InfoStatus provided={(internalNote === null || internalNote === void 0 ? void 0 : internalNote.modified) || (internalNote === null || internalNote === void 0 ? void 0 : internalNote.created)} providedBy={(internalNote === null || internalNote === void 0 ? void 0 : internalNote.modifiedBy) || (internalNote === null || internalNote === void 0 ? void 0 : internalNote.createdBy)}/>
    </Module>);
};
export default ConsumerInternalNotes;
