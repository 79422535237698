var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Autocomplete } from 'src/components/mui';
import useAutocompleteInput from 'src/hooks/useAutocompleteInput';
const ServiceAreaSearch = (_a) => {
    var { value, getServiceAreas, serviceAreaSelector, onUpdate, disabled } = _a, rest = __rest(_a, ["value", "getServiceAreas", "serviceAreaSelector", "onUpdate", "disabled"]);
    const { onInputChange } = useAutocompleteInput(value, disabled, getServiceAreas);
    const options = useSelector(serviceAreaSelector);
    const onSubmit = (value) => {
        onUpdate(value);
    };
    return (<Autocomplete value={value} style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option, value) => option.id === value.id} options={options} disabled={disabled} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} onInputChange={onInputChange} onChange={(e, value) => {
            onSubmit(value);
        }} renderInput={(params) => (<TextField {...params} {...rest} InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default ServiceAreaSearch;
