var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openEditTemplateSlidePanel, openEditUnavailabilitySlidePanel, setDeliveryVanTemplateType, setSelectedTemplate, setSelectedUnavailability, } from 'src/AdminApp/modules/deliveryVanTemplates/actions';
import { selectTemplateType } from 'src/AdminApp/modules/deliveryVanTemplates/selectors';
const useTemplatesDrawerPage = () => {
    const [showInactiveOut, setShowInactiveOut] = useState(false);
    const templateType = useSelector(selectTemplateType);
    const dispatch = useDispatch();
    const onEditTemplate = (template) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(setSelectedTemplate(template));
        yield dispatch(openEditTemplateSlidePanel());
    });
    const onEditUnavailability = (template) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(setSelectedUnavailability(template));
        yield dispatch(openEditUnavailabilitySlidePanel());
    });
    const handleTabChange = (_e, value) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(setDeliveryVanTemplateType(value.toUpperCase()));
    });
    const handleCreateNewTemplate = () => {
        switch (templateType) {
            case 'availability':
                onEditTemplate();
                break;
            case 'unavailability':
                onEditUnavailability();
                break;
            default:
                onEditTemplate();
        }
    };
    return {
        showInactiveOut,
        setShowInactiveOut,
        dispatch,
        templateType,
        onEditTemplate,
        onEditUnavailability,
        handleTabChange,
        handleCreateNewTemplate,
    };
};
export default useTemplatesDrawerPage;
