import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createConsumerNotes, updateConsumerNotes, } from 'src/AdminApp/modules/consumers/actions';
import { selectConsumerById } from 'src/AdminApp/modules/consumers/selectors';
const useConsumerInternalNotes = ({ consumerId }) => {
    const dispatch = useDispatch();
    const consumer = useSelector(selectConsumerById(consumerId));
    const internalNote = consumer === null || consumer === void 0 ? void 0 : consumer.internalNote;
    const [isEditing, setIsEditing] = useState(false);
    const [newNotes, setNewNotes] = useState(internalNote === null || internalNote === void 0 ? void 0 : internalNote.content);
    useEffect(() => {
        setNewNotes(internalNote === null || internalNote === void 0 ? void 0 : internalNote.content);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalNote]);
    const onUpdate = () => {
        if (internalNote === null || internalNote === void 0 ? void 0 : internalNote.id) {
            return dispatch(updateConsumerNotes(internalNote.id, newNotes));
        }
        if (consumer === null || consumer === void 0 ? void 0 : consumer.id) {
            return dispatch(createConsumerNotes(consumer === null || consumer === void 0 ? void 0 : consumer.id, newNotes));
        }
        return false;
    };
    const handleEditIconClick = () => {
        setIsEditing(true);
    };
    const handleCancelClick = () => {
        setNewNotes((internalNote === null || internalNote === void 0 ? void 0 : internalNote.content) || '');
        setIsEditing(false);
    };
    const handleSubmitClick = () => {
        onUpdate();
        setIsEditing(false);
    };
    const handleNotesChange = (e) => {
        setNewNotes(e.target.value);
    };
    return {
        internalNote,
        isEditing,
        setIsEditing,
        newNotes,
        setNewNotes,
        onUpdate,
        handleEditIconClick,
        handleCancelClick,
        handleSubmitClick,
        handleNotesChange,
    };
};
export default useConsumerInternalNotes;
