const constants = {
    TRIM_DONT_KNOW_ID: 'i_dont_know',
    TRIM_DONT_KNOW: "I don't know",
    ENGINE_DONT_KNOW_ID: 'i_dont_know',
    ENGINE_DONT_KNOW: "I don't know",
};
export const SNAPSHOT_LOAD_TIMEOUT = 2000;
export const FOUR_CORNER_PHOTO_MINIMUM = 4;
export const FOUR_CORNER_PHOTO_MINIMUM_WITH_MILEAGE = 5;
export default constants;
