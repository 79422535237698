import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Autocomplete } from 'src/components/mui';
import useDebounce from 'src/modules/api/utils/useDebounce';
const IspOwnerSearch = ({ value, getUsers, usersSelector, onUpdate, name, label, }) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState(value);
    const debouncedInputValue = useDebounce(inputValue, 500);
    const ispOwners = useSelector(usersSelector);
    const options = ispOwners.map((x) => ({ id: x === null || x === void 0 ? void 0 : x.id, name: x === null || x === void 0 ? void 0 : x.fullName }));
    useEffect(() => {
        dispatch(getUsers(debouncedInputValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedInputValue]); // eslint-disable-line react-hooks/exhaustive-deps
    const onSubmit = (submittedValue) => {
        onUpdate({
            id: submittedValue === null || submittedValue === void 0 ? void 0 : submittedValue.id,
            name: submittedValue === null || submittedValue === void 0 ? void 0 : submittedValue.name,
        });
    };
    return (<Autocomplete value={value
            ? {
                id: value.id,
                name: value.name ||
                    (value.firstName || value.lastName
                        ? `${value.firstName} ${value.lastName}`
                        : undefined),
            }
            : null} style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option, value1) => option.name === value1.name} options={options} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }} onChange={(e, changedValue) => {
            onSubmit(changedValue);
        }} renderInput={(params) => (<TextField {...params} name={name} label={label} variant="outlined" type="text" fullWidth InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default IspOwnerSearch;
