import { capitalize } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import AdditionalCancelationReasons from 'src/AdminApp/containers/requests/StateTracker/drawers/AdditionalCancelationReasons';
import useRepairRequestJobInfo from 'src/AdminApp/containers/swimlanes/Chart/ChartJob/JobInfoPopover/JobInfoByType/useRepairRequestJobInfo';
import { CopyToClipboardButton } from 'src/components/buttons';
import { Button, Divider } from 'src/components/mui';
import SimpleChip from 'src/components/SimpleChip';
import OrderNotesDrawer from 'src/PopsApp/pages/Order/components/OrderNotesDrawer/OrderNotesDrawer';
import { colors } from 'src/styles/theme';
import { TIME_12H_FORMAT } from 'src/timeConstants';
import { convertToTimezone } from '../../../utils';
import { ApptMain, ApptMainActions, ApptMainInfo, JobInfoContainer, JobInfoSection, JobInfoSubsection, } from '../JobInfo.styles';
import JobInfoMenu from '../JobInfoMenu/JobInfoMenu';
import Loader from '../Loader';
const RepairRequestJobInfo = ({ selectedJobs, referenceNum, showMoveButton, jobIdx, onJobUpdate, }) => {
    var _a, _b, _c;
    const { loading, requestInfo, handleClick, openMenu, setOpenMenu, jobCanBeCanceled, handleJobCancelation, request, contactInfo, openCancelationDrawer, handleCloseCancelation, jobCancelationStatus, } = useRepairRequestJobInfo({
        selectedJobs,
        referenceNumber: referenceNum,
        jobIdx,
    });
    if (loading || !requestInfo) {
        return <Loader />;
    }
    return (<>
      <JobInfoContainer onClick={handleClick}>
        <JobInfoSection onClick={handleClick}>
          <ApptMain className="hour" onClick={handleClick}>
            <ApptMainInfo onClick={handleClick}>
              {convertToTimezone(selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.startDate, selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.timezone, TIME_12H_FORMAT)}{' '}
              -{' '}
              {convertToTimezone(selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.endDate, selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.timezone, TIME_12H_FORMAT)}
              <div style={{ marginLeft: '5px' }}>
                <SimpleChip label="APPOINTMENT" $textColor={colors.white} $bgColor={colors.BLUE}/>
              </div>
              <JobInfoMenu open={openMenu} setOpen={setOpenMenu} cancelDisabled={!jobCanBeCanceled} onCancel={handleJobCancelation}/>
            </ApptMainInfo>
            <ApptMainActions>
              <div>
                <span className="ref">Ref #: </span>
                <Link to={`/admin/repairs/${requestInfo.referenceNum}`} style={{
            textDecoration: 'none',
        }}>
                  {requestInfo.referenceNum}
                </Link>
              </div>
              <CopyToClipboardButton value={requestInfo.referenceNum} width={18} style={{
            color: colors.primary,
            minWidth: 'auto',
            marginLeft: '10px',
        }}/>
              <div className="divider">&nbsp;</div>

              <OrderNotesDrawer repairRequestId={requestInfo.repairRequestId} referenceNum={requestInfo.referenceNum} showIcon={false} notesQuantity={requestInfo.notesQuantity} overrideStyles={{
            fontSize: '12px',
            padding: 0,
        }} swimlanes/>
            </ApptMainActions>
          </ApptMain>
        </JobInfoSection>
        <Divider />
        <JobInfoSection>
          <JobInfoSubsection>
            <span className="label">Parts Status:</span>
            <span className="value">
              {capitalize(requestInfo.partsStatus.replace('_', ' '))}
            </span>
          </JobInfoSubsection>
          {requestInfo.address && (<JobInfoSubsection>
              <span className="label">Service Address</span>
              <span className="value primary">
                {requestInfo.address}
                <CopyToClipboardButton value={requestInfo.address} width={18} style={{
                color: colors.primary,
                minWidth: 'auto',
                marginLeft: '10px',
            }}/>
              </span>
            </JobInfoSubsection>)}
          {(requestInfo === null || requestInfo === void 0 ? void 0 : requestInfo.customerName) && (<JobInfoSubsection>
              <span className="label">Customer</span>
              <span className="value">{requestInfo === null || requestInfo === void 0 ? void 0 : requestInfo.customerName}</span>
              <span className="value primary">
                {requestInfo === null || requestInfo === void 0 ? void 0 : requestInfo.customerPhone}
              </span>
            </JobInfoSubsection>)}
        </JobInfoSection>
        <Divider />

        <JobInfoSection>
          <JobInfoSubsection>
            <span className="label">Vehicle Info</span>
            <span className="value">
              {`${requestInfo === null || requestInfo === void 0 ? void 0 : requestInfo.carYear} ${requestInfo === null || requestInfo === void 0 ? void 0 : requestInfo.make} ${requestInfo === null || requestInfo === void 0 ? void 0 : requestInfo.model}`}
            </span>
          </JobInfoSubsection>
        </JobInfoSection>

        <Divider />

        <JobInfoSection>
          <JobInfoSubsection>
            <span className="label">Services</span>
            {((_a = requestInfo === null || requestInfo === void 0 ? void 0 : requestInfo.repairs) !== null && _a !== void 0 ? _a : []).map((repair, index) => (<span key={index} className="value">
                  {repair}
                </span>))}
          </JobInfoSubsection>
        </JobInfoSection>

        <Divider />

        <JobInfoSection>
          {(requestInfo === null || requestInfo === void 0 ? void 0 : requestInfo.technicianNames) && (<JobInfoSubsection>
              <span className="label">Techs</span>
              <span className="value">
                {requestInfo.technicianNames.join(', ')}
              </span>
            </JobInfoSubsection>)}
          {(requestInfo === null || requestInfo === void 0 ? void 0 : requestInfo.subTotal) && (<div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
              <JobInfoSubsection style={{
                marginBottom: '0',
            }}>
                <span className="label">Subtotal</span>
                <span className="value">${requestInfo.subTotal}</span>
              </JobInfoSubsection>
              {showMoveButton && (<Button color="primary" variant="contained" onClick={() => onJobUpdate ? onJobUpdate(selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].id) : {}}>
                  Move
                </Button>)}
            </div>)}
        </JobInfoSection>
      </JobInfoContainer>

      {request && (<AdditionalCancelationReasons consumerId={contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.consumerId} isFleet={!!(request === null || request === void 0 ? void 0 : request.fleetId)} open={openCancelationDrawer} referenceNum={requestInfo.referenceNum} repairRequestId={requestInfo.repairRequestId} visitReferenceNum={(_b = request.visit) === null || _b === void 0 ? void 0 : _b.refNum} workOrderId={(_c = request.workOrder) === null || _c === void 0 ? void 0 : _c.id} onClose={handleCloseCancelation} reassignVanFunction={jobCancelationStatus === null || jobCancelationStatus === void 0 ? void 0 : jobCancelationStatus.reassignVanFunction} onlyCancellation/>)}
    </>);
};
export default RepairRequestJobInfo;
