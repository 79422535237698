import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Autocomplete } from 'src/components/mui';
import useAutocompleteInput from 'src/hooks/useAutocompleteInput';
const TerritorySearch = ({ value = null, getTerritories, territoriesSelector, onUpdate, name, label, }) => {
    const options = useSelector(territoriesSelector);
    const { onInputChange } = useAutocompleteInput(value, false, getTerritories);
    const onSubmit = (value) => {
        onUpdate(value);
    };
    return (<Autocomplete fullWidth value={value} style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option, value) => option.name === value.name} options={options} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} onInputChange={onInputChange} onChange={(e, value) => {
            onSubmit(value);
        }} renderInput={(params) => (<TextField {...params} label={label} type="text" name={name} variant="outlined" InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default TerritorySearch;
