import React, { useState } from 'react';
import useWorkOrderItem from 'src/FleetVisitsApp/pages/VisitView/useWorkOrderItem';
import { GetRepairsForDisplay } from 'src/FleetVisitsApp/utils/GetRepairsForDisplay';
import { MoreIconButton, NicknameLabel, VinPlate, WorkOrderRow, } from 'src/FleetVisitsApp/pages/VisitView/WorkOrdersTable/WorkOrdersTable.styles';
import { Grid, Menu, MenuItem } from 'src/components/mui';
import { TooltipInfo } from 'src/components/tooltips';
import { CancelationReasonTooltip, TooltipContainer, } from 'src/FleetVisitsApp/pages/VisitView/RepairsTooltip/RepairsTooltip.styles';
import { MakeModelContainer, VinPlateContainer, } from 'src/FleetVisitsApp/pages/VisitView/CarDisplay/CarDisplay.styles';
import Services from 'src/FleetVisitsApp/pages/VisitView/Services';
import { MpiStatusChip, RoStatusChip, } from 'src/FleetVisitsApp/pages/VisitView/StatusChips';
import { MoreVertRounded } from 'src/components/mui/icons';
const WorkOrdersTableRow = ({ workOrder, technicianTasks, onWorkOrderRemove, }) => {
    var _a, _b, _c;
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    if (!workOrder)
        return null;
    const { mechanicDisplayName, allowRemove, cancelationReason } = useWorkOrderItem({
        workOrder,
    });
    let activeRemoveButton = allowRemove;
    if ((technicianTasks === null || technicianTasks === void 0 ? void 0 : technicianTasks.tasks) &&
        (technicianTasks === null || technicianTasks === void 0 ? void 0 : technicianTasks.tasks[workOrder.repairRequestRefNum])) {
        const tasks = technicianTasks.tasks[workOrder.repairRequestRefNum];
        const mpiCompleted = tasks === null || tasks === void 0 ? void 0 : tasks.some((task) => (task === null || task === void 0 ? void 0 : task.state) === 'MPI_PERFORMED' && (task === null || task === void 0 ? void 0 : task.status) === 'COMPLETED');
        if (mpiCompleted) {
            activeRemoveButton = false;
        }
    }
    const { repairs, extraRepairsCount, allRepairs } = GetRepairsForDisplay({
        services: (_a = workOrder === null || workOrder === void 0 ? void 0 : workOrder.diagnosis) === null || _a === void 0 ? void 0 : _a.diagnosisServices,
    });
    const menuOpen = Boolean(menuAnchorEl);
    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };
    const workOrderCar = workOrder.car;
    return (<WorkOrderRow removed={workOrder.status === 'REMOVED'}>
      <td>
        <a href={`/admin/repairs/${workOrder.repairRequestRefNum}`} target="_blank" rel="noreferrer">
          <strong>{workOrder.repairRequestRefNum}</strong>
          {workOrder.status === 'REMOVED' && (<TooltipInfo>
              <TooltipContainer>
                <CancelationReasonTooltip>
                  {cancelationReason}
                </CancelationReasonTooltip>
              </TooltipContainer>
            </TooltipInfo>)}
        </a>
      </td>
      <td>
        <a href={`/admin/fleets/${(_b = workOrder.fleet) === null || _b === void 0 ? void 0 : _b.id}`} target="_blank" rel="noreferrer">
          {(_c = workOrder.fleet) === null || _c === void 0 ? void 0 : _c.name}
        </a>
      </td>
      <td>
        <Grid container style={{ alignItems: 'center' }}>
          {!!workOrderCar && (<>
              <Grid item>
                {`${workOrderCar.make} ${workOrderCar.model}`}
                {workOrderCar.vin && (<>
                    <br />
                    {workOrderCar.vin}
                  </>)}
              </Grid>
              <Grid item>
                {(workOrderCar.vin || workOrderCar.plateNumber) && (<TooltipInfo>
                    <TooltipContainer>
                      <MakeModelContainer>{`${workOrderCar.make} ${workOrderCar.model}`}</MakeModelContainer>
                      <VinPlateContainer>
                        {workOrderCar.vin && (<VinPlate>VIN: {workOrderCar.vin}</VinPlate>)}
                        {workOrderCar.plateNumber && (<VinPlate>Plate: {workOrderCar.plateNumber}</VinPlate>)}
                        {workOrderCar.nickname && (<NicknameLabel>
                            Nickname: {workOrderCar.nickname}
                          </NicknameLabel>)}
                      </VinPlateContainer>
                    </TooltipContainer>
                  </TooltipInfo>)}
              </Grid>
            </>)}
        </Grid>
      </td>
      <td>{mechanicDisplayName}</td>
      <td>
        <Services allRepairs={allRepairs !== null && allRepairs !== void 0 ? allRepairs : []} extraRepairsCount={extraRepairsCount !== null && extraRepairsCount !== void 0 ? extraRepairsCount : 0} repairs={repairs !== null && repairs !== void 0 ? repairs : []}/>
      </td>
      <td>
        {workOrder.status !== 'REMOVED' && (<RoStatusChip repairOrderStatus={workOrder.repairOrderStatus}/>)}
      </td>
      <td>
        {workOrder.mpiStatus && workOrder.status !== 'REMOVED' && (<MpiStatusChip mpiStatus={workOrder.mpiStatus}/>)}
      </td>
      <td>
        <MoreIconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <MoreVertRounded />
        </MoreIconButton>

        <Menu anchorEl={menuAnchorEl} keepMounted open={menuOpen} onClose={handleMenuClose}>
          <MenuItem disabled={!activeRemoveButton} onClick={() => {
            handleMenuClose();
            onWorkOrderRemove(workOrder);
        }}>
            Remove
          </MenuItem>
        </Menu>
      </td>
    </WorkOrderRow>);
};
export default WorkOrdersTableRow;
