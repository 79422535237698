import React, { useEffect, useState } from 'react';
import { Alert, Button, Snackbar as MuiSnackbar } from 'src/components/mui';
import { ErrorRounded as ErrorRoundedIcon } from 'src/components/mui/icons';
import { Tooltip } from 'src/components/tooltips';
import useScrollPosition from 'src/AdminApp/hooks/useScrollPosition';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
const Snackbar = styled(MuiSnackbar) `
  ${theme.breakpoints.up('sm')} {
    top: ${({ isScrollOnTop }) => (isScrollOnTop ? '90px' : '0')};
  }
`;
const SaMpiQuotePendingAlert = ({ open, recommendedRepairsDirty, onNotifyCustomer, onManualClose, }) => {
    const [scrollPosition] = useScrollPosition();
    const [isScrollOnTop, setIsScrollOnTop] = useState(true);
    useEffect(() => {
        if (scrollPosition === 0)
            setIsScrollOnTop(true);
        else
            setIsScrollOnTop(false);
    }, [scrollPosition]);
    return (<Snackbar className="snackBarRoot" open={open} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }} isScrollOnTop={isScrollOnTop}>
      <Alert classes={{ root: 'userAlert', message: 'userAlertMessage' }} severity="error" icon={<ErrorRoundedIcon fontSize="inherit"/>} action={<>
            {!recommendedRepairsDirty && (<Tooltip title="Please make an adjustment to the recommended repairs in order to send the customer their MPI.">
                <span>
                  <Button color="primary" size="small" disabled>
                    Notify Customer
                  </Button>
                  <Button color="primary" size="small" onClick={() => onManualClose('setShowQuotePendingAlert')}>
                    Dismiss
                  </Button>
                </span>
              </Tooltip>)}
            {recommendedRepairsDirty && (<>
                <Button color="primary" size="small" onClick={onNotifyCustomer}>
                  Notify Customer
                </Button>
                <Button color="primary" size="small" onClick={() => onManualClose('setShowQuotePendingAlert')}>
                  Dismiss
                </Button>
              </>)}
          </>}>
        SA MPI Quote Pending
      </Alert>
    </Snackbar>);
};
export default SaMpiQuotePendingAlert;
