import React from 'react';
import { useDispatch } from 'react-redux';
import { MechanicsHeader, MechanicsTitle, Warning, } from 'src/AdminApp/containers/scheduling/index.styles';
import Location from 'src/AdminApp/containers/scheduling/Location';
import Mechanics from 'src/AdminApp/containers/scheduling/Mechanics';
import { saveMechanicIds, saveVanId, } from 'src/AdminApp/modules/quickRo/actions';
import { Typography } from 'src/components/mui';
const LocationAndMechanicSelection = ({ address, errMsg, currentTab, activeMechanics, mechanicIds, selectedVanId, skills, handleLocationChange, }) => {
    var _a;
    const dispatch = useDispatch();
    const handleSelectMechanic = (vanId, techId) => {
        dispatch(saveMechanicIds(techId));
        dispatch(saveVanId(vanId));
    };
    return (<>
      <Location address={address} onChange={handleLocationChange}/>

      {errMsg && (<Warning>
          <Typography variant="subtitle2">{errMsg}</Typography>
        </Warning>)}
      {currentTab !== 2 && (<>
          <MechanicsHeader>
            <MechanicsTitle variant="subtitle2">
              Available Technicians (Van)
            </MechanicsTitle>
          </MechanicsHeader>
          <Mechanics loading={!!(activeMechanics === null || activeMechanics === void 0 ? void 0 : activeMechanics._isFetching)} mechanicIds={mechanicIds} selectedVanId={selectedVanId} mechanics={(_a = activeMechanics === null || activeMechanics === void 0 ? void 0 : activeMechanics.mechanics) !== null && _a !== void 0 ? _a : []} requiredSkills={skills} onSelectMechanic={handleSelectMechanic}/>
        </>)}
    </>);
};
export default LocationAndMechanicSelection;
