import styled from 'styled-components';
import { Grid, Button } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const FleetVisitContainer = styled(Grid) `
  padding: 20px 24px;
  border: solid 1px ${colors.lightGrey};
  border-left: 6px solid ${colors.black};
  margin-top: 15px;
  border-radius: 4px;
`;
export const FleetVisitSection = styled(Grid) `
  border-bottom: solid 1px ${colors.lightGrey};
  padding: 0 0 16px 0;

  &:not(:first-child) {
    padding-top: 16px;
  }
`;
export const FleetVisitInfo = styled.p `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  margin: 0;

  &.visitAddress {
    margin-bottom: 16px;
  }
`;
export const ViewDetailsContainer = styled.div `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 6px;
`;
export const FleetAction = styled(Button) `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
  padding: 16px 0;
`;
