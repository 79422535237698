import React, { useState } from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { Button, ButtonBase, Collapse, Divider, Grid, Typography, } from 'src/components/mui';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from 'src/components/mui/icons';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
import { getSquareMakeLogo } from 'src/AdminApp/containers/vehicle/utils';
import useIsMobile from 'src/hooks/useIsMobile';
const Card = styled.div `
  border-radius: 4px;
  border: solid 1px ${colors.lightGrey};
  overflow: hidden;
  background: white;
  width: 100%;
`;
const ButtonBaseStyled = styled(ButtonBase) `
  display: block;
  width: 100%;
`;
const Logo = styled.img `
  max-height: 40px;
  max-width: 40px;
`;
const TopSection = styled.div `
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;
const CarName = styled.div `
  margin-left: 8px;
  font-size: 16px;
`;
const OpenedIcon = styled(KeyboardArrowDownIcon) `
  margin-left: auto;
  transition: 0.3s ease transform;
  transform: rotate(${({ $open }) => ($open ? 180 : 0)}deg);
  color: ${colors.iconGrey};
`;
const CarSpecsSection = styled(Grid) `
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  color: ${colors.default};
`;
const CarSelectButton = styled(Button) `
  border-radius: 0;
  background-color: rgba(118, 118, 118, 0.08);
`;
const AdditionalInfo = styled.div `
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.75px;
  color: ${colors.darkGrey};
  display: flex;
  align-items: center;
  padding: 16px;
`;
const AdditionalInfoImg = styled.img `
  margin-right: 4px;
  &:nth-of-type(2n) {
    margin-left: 20px;
  }
`;
const DesktopLabels = styled(Grid) `
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  color: ${colors.darkGrey};
`;
const DesktopValues = styled(Grid) `
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  color: ${colors.default};
  max-width: 100%;
  overflow-wrap: break-word;
`;
const DesktopTopSection = styled.div `
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
const DesktopButton = styled(Button) `
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
  height: 48px;
  width: 192px;
  max-width: 100%;
  margin-top: 24px;
`;
const DesktopContainer = styled(Grid) `
  min-width: 228px;
  margin-bottom: 24px;
`;
const DesktopRow = ({ label, value, }) => (<Grid container spacing={2}>
    <DesktopLabels item xs={4}>
      {label}
    </DesktopLabels>
    <DesktopValues item xs={8} zeroMinWidth>
      {value}
    </DesktopValues>
  </Grid>);
const DesktopVehicleCard = ({ onSelectVehicle, vehicle, }) => {
    const valuesAndLabels = [
        {
            label: 'ID',
            value: vehicle.id || '-',
        },
        {
            label: 'Engine',
            value: vehicle.engineDescription || '-',
        },
        {
            label: 'Trim',
            value: vehicle.trim || '-',
        },
        {
            label: 'License Plate',
            value: vehicle.plateNumber ? (<>
          {vehicle.plateNumber} ({vehicle.plateNumberState})
        </>) : ('-'),
        },
        {
            label: 'VIN',
            value: vehicle.vin || '-',
        },
        {
            label: 'Mileage',
            value: vehicle.mileage || '-',
        },
    ];
    return (<DesktopContainer container spacing={2} direction="row">
      <Grid item xs={2} md={2}>
        <Logo src={getSquareMakeLogo(vehicle.make, '@3x')} alt=""/>
      </Grid>
      <Grid item xs={10} md={10}>
        <DesktopTopSection>
          <div>
            <b>
              {vehicle.year} {vehicle.make}
            </b>{' '}
            {vehicle.model}
          </div>
        </DesktopTopSection>
        <Grid container spacing={2} style={{ padding: '16px' }}>
          {map(valuesAndLabels, (item) => (<DesktopRow key={item.label} {...item}/>))}
        </Grid>
        <DesktopButton color="primary" variant="contained" onClick={onSelectVehicle}>
          Select Vehicle
        </DesktopButton>
      </Grid>
    </DesktopContainer>);
};
const VehicleCard = ({ vehicle, onSelectVehicle, }) => {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const carSelectHandler = () => onSelectVehicle(vehicle);
    return isMobile ? (<Card>
      <ButtonBaseStyled onClick={() => setOpen((v) => !v)}>
        <TopSection>
          <Logo src={getSquareMakeLogo(vehicle.make, '@3x')} alt=""/>
          <CarName>
            <b>
              {vehicle.year} {vehicle.make}
            </b>{' '}
            {vehicle.model}
          </CarName>
          <OpenedIcon $open={open}/>
        </TopSection>
      </ButtonBaseStyled>
      <Collapse in={open}>
        <Divider />
        <CarSpecsSection container>
          <Grid item xs={6}>
            <Typography variant="subtitle1">ID</Typography>
            <div>{vehicle.id}</div>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Engine</Typography>
            <div>{vehicle.engineDescription}</div>
          </Grid>
        </CarSpecsSection>
        <Divider />
        <CarSpecsSection container>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Trim</Typography>
            <div>{vehicle.trim}</div>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Mileage</Typography>
            <div>{vehicle.mileage}</div>
          </Grid>
        </CarSpecsSection>
      </Collapse>
      {vehicle.plateNumber || vehicle.vin ? (<>
          <Divider />
          <AdditionalInfo>
            {vehicle.plateNumber && (<>
                <AdditionalInfoImg src={`${STATIC_URL_BASE}img/license-plate-text.svg`} alt=""/>{' '}
                {vehicle.plateNumber} ({vehicle.plateNumberState}){' '}
              </>)}

            {vehicle.vin && (<>
                <AdditionalInfoImg src={`${STATIC_URL_BASE}img/vin-text.svg`} alt=""/>{' '}
                {vehicle.vin}
              </>)}
          </AdditionalInfo>
        </>) : null}

      <Divider />
      <CarSelectButton size="large" color="primary" fullWidth onClick={carSelectHandler}>
        Select Car
      </CarSelectButton>
    </Card>) : (<DesktopVehicleCard vehicle={vehicle} onSelectVehicle={carSelectHandler}/>);
};
export default VehicleCard;
