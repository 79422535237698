import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'src/components/mui';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { getTerritoriesByFilters } from 'src/AdminApp/modules/territories/actions';
import { selectDashboardPagination, selectDashboardTerritories, selectDashboardParams, selectDashboardFetching, } from 'src/AdminApp/modules/territories/selectors';
import { DashboardListItem } from './DashboardListItem';
import SearchTerritories from './SearchTerritories';
const TopBlock = styled(Grid) `
  margin-bottom: 64px;
`;
const REQUEST_COLUMNS = [
    { title: 'Territory', key: 'name', sortable: true },
    { title: 'Metro', key: 'metros', sortable: false },
];
const Dashboard = () => {
    var _a;
    const dispatch = useDispatch();
    const territories = useSelector(selectDashboardTerritories);
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    const isFetching = useSelector(selectDashboardFetching);
    const onLoadData = ({ page, size, sort }) => dispatch(getTerritoriesByFilters(Object.assign(Object.assign({}, params), { pagination: Object.assign(Object.assign({}, pagination), { size,
            page,
            sort }) })));
    return (<>
      <h3>Territories</h3>
      <TopBlock container spacing={4}>
        <Grid item lg={12} md={12} xs={12}>
          <SearchTerritories />
        </Grid>
      </TopBlock>
      <Grid container>
        <Grid item md={12} xs={12}>
          <SimpleTable data={territories} pagination={pagination} sort={(_a = pagination.sort) !== null && _a !== void 0 ? _a : []} isFetching={isFetching} columns={REQUEST_COLUMNS} renderItem={(item, index) => (<DashboardListItem item={item} key={`${index}-${item.id}`}/>)} onLoadData={(params) => onLoadData(params)}/>
        </Grid>
      </Grid>
    </>);
};
export default Dashboard;
