var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectJobs, selectTopPriorityOrder, } from 'src/PopsApp/modules/parts/selectors';
import { selectUserId } from 'src/modules/auth/selectors';
import { ActionCreators, getJobsByVan, getShopById, getTopPriorityOrder, setOrderParts, setSelectedDistributor, setSelectedJobsForOrdering, updateOrderRoster, } from 'src/PopsApp/modules/parts/actions';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'src/components/SimpleToast';
import { VALID_SHIFTS } from 'src/PopsApp/modules/api/constants';
const useSmartOrder = ({ ispId, region, editVendor, }) => {
    const dispatch = useDispatch();
    const { shift } = useParams();
    const history = useHistory();
    const jobs = useSelector(selectJobs);
    const topPriorityOrder = useSelector(selectTopPriorityOrder);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isSmartOrder, setIsSmartOrder] = useState(false);
    const [selectedJobs, setSelectedJobs] = useState({});
    const [partsSelectedCount, setPartsSelectedCount] = useState(0);
    const userId = useSelector(selectUserId);
    const [orderLoaded, setOrderLoaded] = useState(false);
    useEffect(() => {
        if (!ispId && !region) {
            setIsSmartOrder(true);
            if (!editVendor) {
                dispatch(ActionCreators.CLEAR_JOBS.CLEAR());
            }
        }
        return () => {
            setIsSmartOrder(false);
            setOrderLoaded(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ispId, region]);
    useEffect(() => {
        if (!editVendor && isSmartOrder) {
            handleResetJobsSelected();
            dispatch(setSelectedJobsForOrdering(undefined));
        }
        if (VALID_SHIFTS.includes(shift === null || shift === void 0 ? void 0 : shift.toLowerCase()) && isSmartOrder) {
            if (!editVendor) {
                dispatch(getTopPriorityOrder(shift)).then((response) => {
                    if (!response.error) {
                        setOrderLoaded(true);
                    }
                    return response;
                });
            }
        }
        else if (isSmartOrder) {
            setError(true);
            history.push('/pops');
        }
        dispatch(setOrderParts([]));
        dispatch(setSelectedDistributor(undefined));
        return () => {
            handleResetJobsSelected();
            setLoading(false);
            setError(false);
            setOrderLoaded(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmartOrder, shift]);
    useEffect(() => {
        var _a, _b;
        if (orderLoaded &&
            topPriorityOrder &&
            ((_a = topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.shift) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === shift) {
            dispatch(getJobsByVan({
                ispId: topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.ispId,
                date: topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.scheduleStartLocal,
                shift: (_b = topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.shift) === null || _b === void 0 ? void 0 : _b.toUpperCase(),
                timezone: topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.timezone,
                page: 0,
                size: 100,
            }));
            dispatch(getShopById(topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.ispId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderLoaded, topPriorityOrder]);
    useEffect(() => {
        if (!isSmartOrder || !jobs || jobs.length === 0) {
            return;
        }
        // IS EVERYTHING SELECTED ON THIS PAGE?
        let foundAll = true;
        jobs.forEach((job) => {
            if (!selectedJobs[job.referenceNum]) {
                foundAll = false;
            }
        });
        if (!foundAll) {
            handleCheckAll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs, isSmartOrder]);
    const areAllJobsInProgress = (jobs) => jobs.every((job) => job.partsStatus === 'ORDER_IN_PROGRESS');
    const handleCheckAll = () => {
        const allSelectedJobs = {};
        let partsCount = partsSelectedCount;
        jobs.forEach((job) => {
            if (job.partsStatus === 'PENDING' ||
                job.partsStatus === 'NOT_PICKED_UP' ||
                job.partsStatus === 'ORDER_IN_PROGRESS' ||
                job.partsStatus === 'WRONG_PART' ||
                job.partsStatus === 'ORDER_FAILED' ||
                job.partsStatus === 'ORDERED' ||
                job.partsStatus === 'PARTIALLY_ORDERED') {
                allSelectedJobs[job.referenceNum] = job;
                partsCount += job.componentsNeeded;
            }
        });
        setSelectedJobs(allSelectedJobs);
        setPartsSelectedCount(partsCount);
        const jobsArray = Object.keys(allSelectedJobs);
        if (jobsArray.length > 0 && !areAllJobsInProgress(jobsArray)) {
            dispatch(setSelectedJobsForOrdering(allSelectedJobs));
        }
        else {
            toast.success('All orders in queue for this van are in progress or currently skipped.');
            dispatch(getTopPriorityOrder(shift));
        }
    };
    const handleOrderParts = (jobs, ispId = topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.ispId, shift = topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.shift, date = topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.scheduleStartLocal, timezone = topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.timezone, quickOrder = false) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(updateOrderRoster({
            ispId,
            shift,
            date,
            timezone,
            body: {
                status: 'IN_PROGRESS',
                userId,
                jobs: jobs.map((job) => ({
                    referenceNum: job.referenceNum,
                    scheduleStartLocal: job.scheduleStartLocal,
                    orderStartedAt: new Date(),
                    userId,
                })),
                quickOrder,
            },
        }));
        if (!response || response.error) {
            setLoading(false);
            setError(true);
            setSelectedJobs({});
            dispatch(setSelectedJobsForOrdering(undefined));
            dispatch(getTopPriorityOrder(shift));
        }
        return response;
    });
    const handleResetJobsSelected = () => {
        setSelectedJobs({});
    };
    const handleSkipOrder = (skippedHours) => __awaiter(void 0, void 0, void 0, function* () {
        const currentTime = new Date();
        const response = yield dispatch(updateOrderRoster({
            ispId: topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.ispId,
            shift: topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.shift,
            date: topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.scheduleStartLocal,
            timezone: topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.timezone,
            body: {
                skippedFrom: currentTime,
                skippedTo: new Date(currentTime.getTime() + skippedHours * 60 * 60 * 1000),
                jobs: Object.keys(selectedJobs).map((job) => ({
                    referenceNum: job,
                    scheduleStartLocal: selectedJobs[job].scheduleStartLocal,
                    skippedFrom: currentTime,
                    skippedTo: new Date(currentTime.getTime() + skippedHours * 60 * 60 * 1000),
                })),
            },
        }));
        if (response && !response.error) {
            dispatch(setSelectedJobsForOrdering(undefined));
            dispatch(setOrderParts([]));
            dispatch(setSelectedDistributor(undefined));
            dispatch(getTopPriorityOrder(shift));
            handleResetJobsSelected();
        }
        return response;
    });
    return {
        loading,
        error,
        smartIspId: topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.ispId,
        handleSkipOrder,
        orderLoaded,
        handleOrderParts,
    };
};
export default useSmartOrder;
