import React from 'react';
import { DialogTitle, Grid, IconButton } from 'src/components/mui';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { Close } from 'src/components/mui/icons';
const MuiDialogTitle = styled(DialogTitle) `
  font-size: 20px;
  font-style: 'normal';
  font-weight: 700;
  line-height: '150%' /* 30px */;
  letter-spacing: '0.35px';
  color: ${colors.midnightBlue};
  padding: 0;
  margin: 0;
`;
const StyledIconGrid = styled(Grid) `
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const ModalCloseButton = styled(IconButton) `
  position: relative;
  width: 24px;
  height: 24px;
  color: ${colors.concrete};
  background-color: ${colors.lightSilver};
  border-radius: 50%;
  svg {
    font-size: 1em;
    padding: 5px;
  }
`;
const SimpleDialogTitle = ({ children, onClose, showClose, }) => {
    return (<MuiDialogTitle>
      <Grid container style={{ alignItems: 'flex-start' }}>
        <Grid item xs={onClose && showClose ? 10 : 12}>
          {children}
        </Grid>
        {showClose && onClose && (<StyledIconGrid item xs={2}>
            <ModalCloseButton onClick={onClose}>
              <Close />
            </ModalCloseButton>
          </StyledIconGrid>)}
      </Grid>
    </MuiDialogTitle>);
};
export default SimpleDialogTitle;
