import React from 'react';
import AddButton from 'src/AdminApp/components/layout/AddButton';
import { Link as RouterLink } from 'react-router-dom';
import RepairsDashboard from 'src/PricingApp/components/repairs/RepairsDashboard';
const Repairs = () => (<>
    <RouterLink style={{
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '10px',
    }} to="/pricing/repairs/add">
      <AddButton text="Add New Repair" color="primary"/>
    </RouterLink>

    <RepairsDashboard />
  </>);
export default Repairs;
