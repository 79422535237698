import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { createSimpleDeepHandler } from 'src/modules/api/utils/createSimpleDeepHandler';
import moment from 'moment';
import { createAPIPaginationHandler2 } from 'src/modules/api/utils/createAPIPaginationHandler2';
import { ActionCreators as QuickRoActionCreators } from 'src/AdminApp/modules/requests/actions';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
import { ActionCreators, setVisitFleetIds, setVisitFleetStations, setSelectedStations, setSelectedFleets, setFleetsDatasource, setAddVisitLocation, setAddVisitEndDateTime, setAddVisitTechnicians, setAddVisitStartDateTime, setAddVisitExtLocation, resetAddVisit, setAddVisitDuration, setVisitDate, setVisitSort, setAddVisitVans, setAddVisitDurationTime, saveAddVisitTimeSlot, saveAddVisitTechniciansChoice, saveAddVisitNumberOfTechnicians, saveAddVisitTechnicianIds, clearVisitsStopwatch, } from './actions';
import { PAGE_SIZE } from '../api/constants';
const addVisitDefault = {
    stations: undefined,
    fleets: undefined,
    fleetsDatasource: 'SEARCH',
    location: undefined,
    extLocation: undefined,
    startDateTime: moment(),
    endDateTime: moment(),
    technicianSearchResults: undefined,
    technicians: undefined,
    duration: {
        value: undefined,
        time: undefined,
    },
    vans: undefined,
    visitsByFleet: undefined,
    timeSlot: undefined,
    techniciansChoice: undefined,
    vanMechanicPairings: new Set(),
    numberOfTechnicians: 1,
};
const defaultState = {
    visit: undefined,
    addVisit: addVisitDefault,
    visits: {
        content: undefined,
        pagination: {
            page: 0,
            size: PAGE_SIZE,
        },
    },
    visitDate: moment().format(DATE_YMD_FORMAT),
    visitSort: 'startLocal,asc',
    fleetRequests: undefined,
    sharedTechnicians: [],
    sharedVans: [],
    visitInstructions: null,
    visitsByFleet: [],
    fleetNames: undefined,
    stopwatch: {
        availableTime: -1,
        isRunning: false,
    },
    visitAuditLog: {},
};
const addNewROHandler = (state, action) => {
    if (action.meta.visitRefNum && state.visit) {
        return Object.assign(Object.assign({}, state), { visit: Object.assign(Object.assign({}, state.visit), { workOrders: [Object.assign({}, action.payload), ...state.visit.workOrders] }) });
    }
    return Object.assign({}, state);
};
const getStopwatchSuccess = (state, action) => {
    return Object.assign(Object.assign({}, state), { stopwatch: action.payload });
};
const clearVisitsStopwatchReducer = (state) => {
    return Object.assign(Object.assign({}, state), { stopwatch: undefined });
};
const stopVisitsStopwatchReducer = (state) => {
    return Object.assign(Object.assign({}, state), { stopwatch: Object.assign(Object.assign({}, state.stopwatch), { isRunning: false }) });
};
const visitLockReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { visit: Object.assign(Object.assign({}, state.visit), { locked: action.payload.locked, lockedAt: action.payload.lockedAt, lockedBy: action.payload.lockedBy }) });
};
const visitAuditLogReducer = (state, action) => {
    var _a, _b;
    if (action.meta.visitRefNum) {
        const data = [...action.payload];
        const previousRefNumData = (_b = (_a = state.visitAuditLog) === null || _a === void 0 ? void 0 : _a[action.meta.visitRefNum]) !== null && _b !== void 0 ? _b : undefined;
        if (previousRefNumData) {
            data.push(...previousRefNumData);
        }
        return Object.assign(Object.assign({}, state), { visitAuditLog: {
                [action.meta.visitRefNum]: data,
            } });
    }
};
const clearVisitAuditLogReducer = (state) => {
    return Object.assign(Object.assign({}, state), { visitAuditLog: {} });
};
const reducers = handleActions({
    [ActionCreators.GET_FLEET_VISITS.SUCCESS.type]: createAPIPaginationHandler2('content'),
    [ActionCreators.GET_FLEET_NAMES.SUCCESS.type]: createSimpleHandler('fleetNames'),
    [setVisitDate.type]: createSimpleHandler('visitDate'),
    [setVisitSort.type]: createSimpleHandler('visitSort'),
    [setVisitFleetIds.type]: createSimpleHandler('visitFleetIds'),
    [setVisitFleetStations.type]: createSimpleHandler('visitStations'),
    [ActionCreators.GET_VISIT.SUCCESS.type]: createSimpleHandler('visit'),
    [ActionCreators.GET_VISIT.CLEAR.type]: (state) => {
        return Object.assign(Object.assign({}, state), { visit: undefined });
    },
    [ActionCreators.GET_TECHNICIANS_SEARCH.SUCCESS.type]: createSimpleDeepHandler('addVisit.technicianSearchResults'),
    [setSelectedStations.type]: createSimpleDeepHandler('addVisit.stations'),
    [setSelectedFleets.type]: createSimpleDeepHandler('addVisit.fleets'),
    [setFleetsDatasource.type]: createSimpleDeepHandler('addVisit.fleetsDatasource'),
    [setAddVisitLocation.type]: createSimpleDeepHandler('addVisit.location'),
    [setAddVisitExtLocation.type]: createSimpleDeepHandler('addVisit.extLocation'),
    [setAddVisitStartDateTime.type]: createSimpleDeepHandler('addVisit.startDateTime'),
    [setAddVisitEndDateTime.type]: createSimpleDeepHandler('addVisit.endDateTime'),
    [setAddVisitTechnicians.type]: createSimpleDeepHandler('addVisit.technicians'),
    [setAddVisitVans.type]: createSimpleDeepHandler('addVisit.vans'),
    [setAddVisitDuration.type]: createSimpleDeepHandler('addVisit.duration.value'),
    [setAddVisitDurationTime.type]: createSimpleDeepHandler('addVisit.duration.time'),
    [ActionCreators.GET_FLEET_REQUESTS.SUCCESS.type]: createSimpleHandler('fleetRequests'),
    [resetAddVisit.type]: (state) => (Object.assign(Object.assign({}, state), { addVisit: Object.assign({}, addVisitDefault) })),
    [ActionCreators.GET_MECHANICS_SHARED_AVAILABILITY.SUCCESS.type]: createSimpleHandler('sharedTechnicians'),
    [ActionCreators.GET_VANS_SHARED_AVAILABILITY.SUCCESS.type]: createSimpleHandler('sharedVans'),
    [ActionCreators.GET_VISITS_BY_FLEET.SUCCESS.type]: createSimpleHandler('visitsByFleet'),
    [ActionCreators.UPDATE_FLEET_VISIT_STATUS.SUCCESS.type]: (state, action) => (Object.assign(Object.assign({}, state), { visit: Object.assign(Object.assign({}, state.visit), { status: action.payload.status }) })),
    [ActionCreators.GET_VISIT_INSTRUCTIONS.SUCCESS.type]: createSimpleHandler('visitInstructions'),
    [QuickRoActionCreators.REQUEST_CREATE.SUCCESS.type]: addNewROHandler,
    [QuickRoActionCreators.SCHEDULE_REPAIR.SUCCESS.type]: addNewROHandler,
    [ActionCreators.GET_TECHNICIAN_TASKS_BY_REFERENCE_NUMS.SUCCESS.type]: createSimpleHandler('technicianTasks'),
    [saveAddVisitTimeSlot.type]: createSimpleDeepHandler('addVisit.timeSlot'),
    [saveAddVisitTechniciansChoice.type]: createSimpleDeepHandler('addVisit.techniciansChoice'),
    [saveAddVisitTechnicianIds.type]: createSimpleDeepHandler('addVisit.vanMechanicPairings'),
    [saveAddVisitNumberOfTechnicians.type]: createSimpleDeepHandler('addVisit.numberOfTechnicians'),
    [ActionCreators.GET_VISIT_STOPWATCH.SUCCESS.type]: getStopwatchSuccess,
    [clearVisitsStopwatch.type]: clearVisitsStopwatchReducer,
    [ActionCreators.STOP_VISITS_STOPWATCH.type]: stopVisitsStopwatchReducer,
    [ActionCreators.TOGGLE_VISIT_LOCK.SUCCESS.type]: visitLockReducer,
    [ActionCreators.GET_VISIT_AUDIT_LOG.SUCCESS.type]: visitAuditLogReducer,
    [ActionCreators.CLEAR_VISIT_AUDIT_LOG.type]: clearVisitAuditLogReducer,
}, defaultState);
export default reducers;
