import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadCurrentUser } from 'src/AdminApp/modules/users/actions';
import { selectCurrentUser } from 'src/AdminApp/modules/users/selectors';
import { AvatarIcon } from 'src/components/icons/AvatarIcon';
import { AvatarSettingsIcon } from 'src/components/icons/AvatarSettingsIcon';
import { BuildIcon } from 'src/components/icons/BuildIcon';
import { DeveloperIcon } from 'src/components/icons/DeveloperIcon';
import { PartsIcon } from 'src/components/icons/PartsIcon';
import CONFIG from 'src/config';
import { selectAuthorizedSubletValet, selectAuthorizedSystemUser, selectAuthorizedTechDashboardViewer, selectAuthorizedTechnician, selectCancellationReasonEditor, selectLockEditor, selectPartnerAPIKeyEditor, selectShiftEditor, selectWorpDriveAccess, } from 'src/modules/auth/selectors';
import { isProduction } from 'src/utils/envUtils';
const useMenu = () => {
    const dispatch = useDispatch();
    const [links, setLinks] = useState([]);
    const hasSubletValetBeta = useSelector(selectAuthorizedSubletValet);
    const isPartnerAPIKeyEditor = useSelector(selectPartnerAPIKeyEditor);
    const isShiftEditor = useSelector(selectShiftEditor);
    const isLockEditor = useSelector(selectLockEditor);
    const isTechnician = useSelector(selectAuthorizedTechnician);
    const canViewAdminTech = useSelector(selectAuthorizedTechDashboardViewer);
    const isSystemUser = useSelector(selectAuthorizedSystemUser);
    const isCancellationReasonEditor = useSelector(selectCancellationReasonEditor);
    const isWorpDriveAccess = useSelector(selectWorpDriveAccess);
    const user = useSelector(selectCurrentUser);
    useEffect(() => {
        dispatch(loadCurrentUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const navLinks = [
            {
                parent: 'Customers',
                items: [
                    {
                        path: '/admin/consumers',
                        text: 'Consumers',
                    },
                    {
                        path: '/admin/fleets',
                        text: 'Fleets',
                    },
                    {
                        path: '/admin/repairs',
                        text: 'Repairs',
                    },
                    CONFIG.REACT_APP_FLEET_VISITS_ENABLED === 'true'
                        ? {
                            path: '/admin/visits',
                            text: 'Visits',
                        }
                        : undefined,
                ].filter((x) => !!x),
                icon: <AvatarIcon />,
            },
            {
                parent: 'Parts',
                icon: <PartsIcon />,
                items: [
                    {
                        path: '/pops/distributors',
                        text: 'Distributors',
                    },
                    {
                        path: '/pops',
                        text: 'POPS',
                    },
                ],
            },
            {
                parent: 'Operations',
                icon: <AvatarSettingsIcon />,
                items: [
                    {
                        path: '/admin/geoManagement',
                        text: 'Geo Mgmt',
                    },
                    {
                        path: '/pops/inventory',
                        text: 'Inventory',
                    },
                    isWorpDriveAccess
                        ? {
                            path: '/admin/invoice-workbench',
                            text: 'Invoice Workbench',
                        }
                        : undefined,
                    canViewAdminTech
                        ? {
                            path: '/tech/lunch-breaks',
                            text: 'Lunch Breaks',
                        }
                        : undefined,
                    {
                        path: '/pops/part-pickups',
                        text: 'Part Pickups',
                    },
                    user
                        ? {
                            path: `/tech/porters/${user.id}`,
                            text: 'Porters',
                        }
                        : undefined,
                    {
                        path: '/admin/responsible-parties',
                        text: 'Responsible Parties',
                    },
                    {
                        path: '/admin/swimlanes',
                        text: 'Swimlanes',
                    },
                    canViewAdminTech
                        ? {
                            path: '/tech/technicians',
                            text: 'Technicians',
                        }
                        : undefined,
                    {
                        path: '/admin/user/signup',
                        text: 'User Mgmt',
                    },
                    {
                        path: '/admin/shops',
                        text: 'Vans',
                    },
                ].filter((x) => !!x),
            },
            isTechnician
                ? {
                    parent: 'Technicians',
                    icon: <BuildIcon />,
                    items: [
                        {
                            path: '/tech/my-dashboard',
                            text: 'My Dashboard',
                        },
                    ],
                }
                : undefined,
            isSystemUser
                ? {
                    parent: 'Developer',
                    icon: <DeveloperIcon />,
                    items: [
                        {
                            path: '',
                            text: 'Engineering',
                            isFirstItem: true,
                            accordionItems: [
                                {
                                    path: '/system/address-sanitizer',
                                    text: 'Address Sanitizer',
                                },
                                {
                                    path: '/system/audit/entity-explorer',
                                    text: 'Audit',
                                },
                                {
                                    path: '/system/elasticsearch-indexer',
                                    text: 'Elasticsearch Indexer',
                                },
                                isWorpDriveAccess
                                    ? {
                                        path: '/admin/invoice-requests',
                                        text: 'Invoice Requests',
                                    }
                                    : undefined,
                                {
                                    path: '/system/kafka/kafka-connect',
                                    text: 'Kafka',
                                },
                                !isProduction()
                                    ? {
                                        path: '/system/skedulo/matcher',
                                        text: 'Skedulo Matcher',
                                    }
                                    : undefined,
                                {
                                    path: '/system/skedulo/webhooks',
                                    text: 'Skedulo Webhooks',
                                },
                                {
                                    path: '/system/skedulo/resources',
                                    text: 'Skedulo Resources',
                                },
                                {
                                    path: '/system/snapshots/requests',
                                    text: 'Snapshots',
                                },
                                {
                                    path: '/system/services',
                                    text: 'Services',
                                },
                                {
                                    path: '',
                                    text: 'Scan POC',
                                    accordionItems: [
                                        {
                                            path: '/scan-poc/auth',
                                            text: 'Auth',
                                        },
                                        {
                                            path: '/scan-poc/api-tester',
                                            text: 'API Tester',
                                        },
                                        {
                                            path: '/scan-poc/v1',
                                            text: 'V1',
                                        },
                                        {
                                            path: '/scan-poc/v2',
                                            text: 'V2',
                                        },
                                        {
                                            path: '/scan-poc/v3',
                                            text: 'V3',
                                        },
                                    ],
                                },
                            ].filter((x) => !!x),
                        },
                        {
                            path: '',
                            text: 'Pricing',
                            accordionItems: [
                                {
                                    path: '/pricing/components',
                                    text: 'Components',
                                },
                                {
                                    path: '/pricing/csv-uploader',
                                    text: 'CSV Uploader',
                                },
                                {
                                    path: '/pricing/manufacturers',
                                    text: 'Manufacturers',
                                },
                                {
                                    path: '/pricing/measurements',
                                    text: 'Measurements',
                                },
                                {
                                    path: '/pricing/repairs',
                                    text: 'Repairs',
                                },
                                {
                                    path: '/pricing/packages',
                                    text: 'Repair Packages',
                                },
                                {
                                    path: '/pricing/utils',
                                    text: 'Utils',
                                },
                            ],
                        },
                        {
                            path: '',
                            text: 'Product',
                            accordionItems: [
                                {
                                    path: '/system/configs',
                                    text: 'Custom Configs',
                                },
                                {
                                    path: '/system/hubs',
                                    text: 'Hubs',
                                },
                                {
                                    path: '/admin/locks',
                                    text: 'Locks',
                                },
                                {
                                    path: '/admin/marketing-sources',
                                    text: 'Marketing Sources',
                                },
                                {
                                    path: '/partners',
                                    text: 'Partners',
                                },
                                isPartnerAPIKeyEditor
                                    ? {
                                        path: '/admin/partner-api-keys',
                                        text: 'Partner API',
                                    }
                                    : undefined,
                                isCancellationReasonEditor
                                    ? {
                                        path: '/system/resolutions',
                                        text: 'Resolutions',
                                    }
                                    : undefined,
                                isShiftEditor
                                    ? {
                                        path: '/admin/shifts',
                                        text: 'Shifts',
                                    }
                                    : undefined,
                                {
                                    path: '/system/skills',
                                    text: 'Skills',
                                },
                            ].filter((x) => !!x),
                        },
                    ],
                }
                : undefined,
        ].filter((x) => !!x);
        setLinks([...navLinks]);
    }, [
        hasSubletValetBeta,
        isPartnerAPIKeyEditor,
        isShiftEditor,
        isLockEditor,
        isTechnician,
        canViewAdminTech,
        isSystemUser,
        isCancellationReasonEditor,
        user,
    ]);
    return { links };
};
export default useMenu;
