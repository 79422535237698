import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, MenuItem, } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import styled, { css } from 'styled-components';
export const PhoneLink = styled.a `
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
  ${({ href }) => !href &&
    css `
      cursor: default;
      pointer-events: none;
    `}
`;
export const PartPickupSectionContent = styled.span `
  margin-left: 8px;
`;
export const PartPickupSection = styled.div `
  padding: 16px 16px;
  border-bottom: ${colors.lightGrey} 1px solid;
  display: flex;
  align-items: flex-start;
`;
export const PartPickupAccordion = styled(Accordion) `
  padding: 4px 16px;
  border-bottom: ${colors.lightGrey} 1px solid;
  border-radius: 0;
  box-shadow: none;
  margin: 0;

  &::before {
    display: none;
  }

  &[data-last='true'].Mui-expanded {
    border-bottom: 0;
  }
`;
export const PartPickupSectionAccordionDetails = styled(AccordionDetails) `
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 15px;
`;
export const PartPickupField = styled.div `
  min-width: 80px;
  margin-right: 8px;
`;
export const AppointmentInfoSection = styled.div `
  margin-bottom: 8px;
  display: flex;
`;
export const StyledAccordionSummary = styled(AccordionSummary) `
  padding: 0;
  margin: 0;
`;
export const RepairRequestAccordionDetails = styled(AccordionDetails) `
  display: flex;
  flex-direction: column;
  padding: 0 0 1rem 0;
`;
export const ContentWrapper = styled(Box) `
  padding-top: 25px;

  h2 {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.1;
  }
  .subheaderChecklist {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
    margin: 8px 0 0;
  }
  &.fullSpace {
    padding: 25px;
  }
`;
export const ListHeadings = styled.div `
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.lightGrey};
  padding-bottom: 8px;
`;
export const ChecklistItem = styled.div `
  border-bottom: 1px solid ${colors.lightGrey};
  padding-bottom: 25px;
  margin-bottom: 25px;
`;
export const ChecklistItemLink = styled.div `
  margin-bottom: 1rem;
  font-weight: bold;

  a {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25;
    color: #2c56dd;
  }
`;
export const ChecklistItemContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const ChecklistItemPart = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;
export const QuantityButton = styled(Button) `
  border-radius: 50%;
  min-width: 25px;
  background-color: ${colors.linkBlue};
  line-height: 25px;
  font-size: 18px;
  color: ${colors.white};
  padding: 0;

  &:disabled {
    background-color: ${colors.lightGrey};
    color: ${colors.white};
  }
  span {
    font-size: 30px;
    position: relative;
    top: 3px;
  }
  &:hover {
    background-color: ${colors.blue3F};
  }
`;
export const QuantityLabel = styled.span `
  font-size: 1.25rem;
  line-height: normal;
  min-width: 30px;
  text-align: center;
  font-weight: 500;
`;
export const ErrorIconStyle = {
    color: colors.error,
    fontSize: '40px',
};
export const MissingPartsDivider = styled(Divider) `
  margin: 40px 0;
`;
export const PartsPickupButton = styled(Button) `
  min-width: 144px;
  height: 48px;
  font-size: 1rem;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
`;
export const WhatsNextHeader = styled.div `
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.5px;
  display: flex;
  justify-content: space-between;
  padding: 25px 25px 25px 16px;
  position: relative;
`;
export const ContentInner = styled.div `
  padding: 7px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
export const WhatsNextImg = styled.img `
  position: absolute;
  right: 20px;
  top: -8px;
`;
export const ContentText = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  position: relative;

  &.textWithIcon {
    padding-left: 20px;
    &:before {
      content: '—';
      position: absolute;
      left: 0;
    }
  }
`;
export const InfoItem = styled.div `
  display: flex;
  gap: 15px;
`;
export const ChecklistAccordionItem = styled.div `
  margin-bottom: 0.875rem;
  display: flex;
  justify-content: space-between;

  &.itemStateNewLocation {
    text-decoration: line-through;
  }
  &.itemStateNewPart {
    font-weight: 600;
  }
`;
export const ChecklistAccordionSubItem = styled.div `
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;

  &.itemStateNewLocation {
    text-decoration: line-through;
  }
  &.itemStateNewPart {
    font-weight: 600;
  }
`;
export const IconWrapper = styled.div `
  height: 22px;

  svg {
    width: 22px;
    height: 22px;
    &.success {
      color: ${colors.success};
    }
    &.warning {
      color: ${colors.ORANGE_FF};
    }
    &.primary {
      color: ${colors.primary};
    }
  }
`;
export const NewIcon = styled.span `
  color: ${colors.primary};
  font-weight: 900;
  font-size: 12px;
  display: block;
  width: 22px;

  .itemStateNewPart & {
    width: 30px;
  }
`;
export const PurchaseOrdersTitle = styled.h3 `
  font-size: 1.25rem;
  line-height: 1.2;
  margin: 0;
`;
export const PartPickupOrderItemWrapper = styled.div `
  border-bottom: 1px solid rgba(118, 118, 118, 0.24);
  margin-bottom: 1.5rem;
`;
export const RepairRequestLink = styled.a `
  display: block;
  margin-bottom: 1rem;
  color: #2c56dd;
  font-size: 1rem;
  font-weight: bold;
`;
export const ChecklistSubheader = styled.p `
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  margin: 6px 0 0;
`;
export const WhatsNextSubheader = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.1px;
  margin-top: 8px;
  max-width: 15rem;
  color: ${colors.GRAY_76};
`;
export const RefNumberSpan = styled.span `
  color: ${colors.lightGrey};
  font-weight: 900;
  font-size: 15px;
  margin-left: 5px;
`;
export const FormRow = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.563rem;
`;
export const Subheader = styled.div `
  font-size: 16px;
  color: ${colors.GRAY_3F};
  margin-bottom: 24px;
`;
export const MenuItemContent = styled.div `
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: -0.3px;
  color: ${colors.GRAY_76};

  li[class*='MuiButtonBase-root'] & {
    padding: 1rem 0;
  }

  span {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    color: ${colors.GRAY_3F};
  }
`;
export const MenuItemWrapper = styled(MenuItem) `
  white-space: normal;

  &:not(:last-child) {
    border-bottom: 1px solid #dedede;
  }
`;
export const MenuItemInner = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const MenuItemIcon = styled.div `
  width: 38px;
  height: 32px;

  div[class*='MuiInputBase-root'] & {
    display: none;
  }
`;
