import React from 'react';
import { Snackbar, Alert } from 'src/components/mui';
import { ErrorRounded as ErrorRoundedIcon } from 'src/components/mui/icons';
import { Alerts } from 'src/AdminApp/containers/requests/RecommendedRepairs/WorkflowComponents/AlertsAndDialogs.styles';
const DecodePlateAlert = ({ open, onClick, autoHideDuration, }) => {
    return (<Alerts isMobile>
      <Snackbar classes={{ root: 'snackBarRoot' }} open={open} onClose={onClick} autoHideDuration={autoHideDuration} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}>
        <Alert classes={{ root: 'userAlert', message: 'userAlertMessage' }} severity="error" icon={<ErrorRoundedIcon fontSize="inherit"/>}>
          Please input a valid Licence Plate and select a License Plate State
        </Alert>
      </Snackbar>
    </Alerts>);
};
export default DecodePlateAlert;
