import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import SimpleChip from 'src/components/SimpleChip';
import { colors } from 'src/styles/theme';
import { MAX_NOTE_LENGTH } from '../constants';
import { NoteSeeMoreContent } from '../Notes.styles';
// For some reason the ReactMarkdown component was trowing a "process is not defined" error, added this as
// a temporary solution.
window.process = Object.assign(Object.assign({}, window.process), { cwd: () => '' });
const NoteContent = ({ note }) => {
    const [open, setOpen] = useState(false);
    return (<NoteSeeMoreContent>
      <ReactMarkdown className="line-break">
        {note.substring(0, !open ? MAX_NOTE_LENGTH : note.length)}
      </ReactMarkdown>
      {note.length > MAX_NOTE_LENGTH && (<SimpleChip $bgColor={colors.secondary} $textColor={colors.white} label={open ? 'See Less' : 'See More'} onClick={() => setOpen(!open)}/>)}
    </NoteSeeMoreContent>);
};
export default NoteContent;
