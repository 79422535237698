import { Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography, } from 'src/components/mui';
import { CameraAlt as CameraAltIcon, ChevronLeft, ChevronRight, Close as CloseIcon, Delete as DeleteIcon, ScheduleRounded as ScheduleRoundedIcon, } from 'src/components/mui/icons';
import React from 'react';
import { createMenuItems } from 'src/AdminApp/components/form/utils';
import { States } from 'src/AdminApp/models/enums';
import { CAR_MSG } from 'src/AdminApp/modules/api/constants';
import { PrimaryButton } from 'src/components/buttons';
import { CarouselContent, CarouselHeader, CarouselWrapper, LeftArrow, RightArrow, Slide, } from 'src/components/carousel.styles';
import { ConfirmationModal, SimpleModal, SlickSlider, } from 'src/components/layout';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { DrawerFormRow, FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { ErrorButton } from 'src/styles/styled';
import CarCard from '../CarCard';
import DecodePlateAlert from '../DecodePlateAlert';
import { Box, BoxPhoto, Container, ImgContainer, ImgWrapper, ModalLoadingContainer, ProgressContainer, SlideTitle, Thumb, WrapperBoxPhoto, } from './FourCornersDrawer.styles';
import useFourCornersDrawer from './useFourCornersDrawer';
const FourCornersDrawer = ({ car, handleUpdateCar, isCurrentTask, isDone, referenceNum, }) => {
    var _a, _b, _c;
    const { saveBtnText, carPhotos, loading, carState, vinError, plateError, isValid, carouselOpen, carouselIndex, currentImage, showDecodePlateAlert, isLoading4CornerPhoto, failed4CornerPhoto, isLoadingAdditional, deleteModalOpen, deletePhotoLoading, isLicensePlateOnFocus, confirmationModalOpen, updateConfirmationModal, confirmationModalText, isVehicleChangingOldCarRequest, confirmationNewVehicleText, isVinCompleted, decodedVehicle, requestLocked, openFourCorner, isShowActionButtonsNonMain, photos, additionalCarPhotos, orderedPhotos, sliderRef, handleOpen, handleClose, validateMileageAndSubmit, handleSubmit, handleCarouselChange, handleCarouselClose, handleClickImage, handlePrevImage, handleNextImage, handleDeleteModalOpen, handleDeleteModalClose, handleDeletePhoto, ctaText, handleOnChangeVin, handleCancelOverrideCar, handleOverrideCar, noop, handleChangeVehiclePhoto, handleChangeAdditionalCarPhoto, updateIsLicensePlate, isPlateComplete, handleDecodeClick, handlePlateChange, handlePlateNumberChange, handleMileageAvailabilityChange, handleMileageChange, handleDecodePlateAlertUpdate, } = useFourCornersDrawer({
        car,
        handleUpdateCar,
        referenceNum,
        isCurrentTask,
        isDone,
    });
    const isVisible = isDone || isShowActionButtonsNonMain;
    const sliderSettings = {
        adaptiveHeight: true,
        arrows: false,
        beforeChange: handleCarouselChange,
        dots: false,
        infinite: true,
        initialSlide: (_a = (orderedPhotos !== null && orderedPhotos !== void 0 ? orderedPhotos : []).findIndex(({ description }) => carouselIndex === description)) !== null && _a !== void 0 ? _a : 0,
        slidesToScroll: 1,
        slidesToShow: 1,
    };
    return (<>
      <ConfirmationModal open={confirmationModalOpen} text={confirmationModalText} action={handleSubmit} onClose={updateConfirmationModal(false)}/>
      {isVisible && (<Container>
          <Typography variant="subtitle2">4 Corner Walkaround</Typography>
          <PrimaryButton className="mainMPIButton" style={{ marginTop: 16 }} onClick={handleOpen}>
            {ctaText()}
          </PrimaryButton>
        </Container>)}
      <SimpleDrawer open={openFourCorner} onClose={handleClose} disableEnforceFocus size="wide" title="4 Corner Walkaround">
        <SimpleDrawerContent>
          <div className="four-corner-form-fields">
            <Grid container style={{ paddingTop: 16 }}>
              <DrawerFormRow item xs={12}>
                <TextField disabled={requestLocked} variant="outlined" value={(carState === null || carState === void 0 ? void 0 : carState.vin) || ''} onChange={handleOnChangeVin} label="VIN" type="text" name="vin" helperText={vinError ||
            (!isVinCompleted && CAR_MSG.VIN_CHARACTER_LENGTH)} fullWidth error={!!vinError}/>
              </DrawerFormRow>
              <DrawerFormRow container item spacing={2}>
                <Grid item xs={7}>
                  <TextField disabled={requestLocked} onFocus={updateIsLicensePlate(true)} onBlur={updateIsLicensePlate(false)} fullWidth error={!!plateError} helperText={plateError ||
            (!isPlateComplete && CAR_MSG.PLATE_CHARACTER_LENGTH)} InputProps={{
            endAdornment: !!carState.plateNumber && (<InputAdornment position="end">
                          <Button color={!isPlateComplete || !carState.plateNumberState
                    ? 'secondary'
                    : 'primary'} onClick={handleDecodeClick}>
                            Decode
                          </Button>
                        </InputAdornment>),
        }} label="License Plate" name="plateNumber" type="text" value={carState.plateNumber} variant="outlined" onChange={handlePlateChange}/>
                </Grid>
                <Grid item xs={5}>
                  <TextField disabled={requestLocked} fullWidth select label="State" name="plateNumberState" type="text" value={carState.plateNumberState} variant="outlined" onChange={handlePlateNumberChange} error={!(carState === null || carState === void 0 ? void 0 : carState.plateNumberState) &&
            !!(carState === null || carState === void 0 ? void 0 : carState.plateNumber) &&
            !isLicensePlateOnFocus}>
                    {createMenuItems(States)}
                  </TextField>
                </Grid>
              </DrawerFormRow>
              <DrawerFormRow item xs={12}>
                <LabelledCheckbox label="Mileage not available" checkboxProps={{
            disabled: requestLocked,
            checked: carState.mileageNotAvailable,
            onChange: handleMileageAvailabilityChange,
        }}/>
              </DrawerFormRow>
              <DrawerFormRow item xs={12}>
                <TextField fullWidth InputProps={{
            endAdornment: (<InputAdornment position="end">miles</InputAdornment>),
        }} label="Miles In" name="mileage" type="text" value={carState.mileage} variant="outlined" onChange={handleMileageChange} disabled={carState.mileageNotAvailable || requestLocked}/>
              </DrawerFormRow>
              {decodedVehicle && (<DrawerFormRow item xs={12}>
                  <Typography variant="subtitle1">
                    VIN/Plate Decoded Vehicle Details
                  </Typography>
                  <CarCard car={decodedVehicle}/>
                </DrawerFormRow>)}
            </Grid>
          </div>
          <div className="photos">
            {carPhotos.map((photo) => {
            const vehiclePhoto = photos === null || photos === void 0 ? void 0 : photos.find(({ description }) => description === photo.type);
            if (!(vehiclePhoto === null || vehiclePhoto === void 0 ? void 0 : vehiclePhoto.url)) {
                return (<span key={photo.type}>
                    <input disabled={requestLocked} type="file" id={`upload-${photo.type}`} accept="image/*" multiple={false} onChange={handleChangeVehiclePhoto(photo)} style={{ display: 'none' }}/>
                    <WrapperBoxPhoto htmlFor={`upload-${photo.type}`}>
                      <p>{photo.name}</p>
                      <BoxPhoto>
                        {isLoading4CornerPhoto[photo.type] && (<ProgressContainer>
                            <CircularProgress size="1.75rem"/>
                          </ProgressContainer>)}
                        <Box>
                          {failed4CornerPhoto[photo.type] ? (<ScheduleRoundedIcon />) : (<CameraAltIcon />)}
                        </Box>
                      </BoxPhoto>
                    </WrapperBoxPhoto>
                  </span>);
            }
            return (<Thumb key={vehiclePhoto.key}>
                  <p>{photo.name}</p>
                  <>
                    <ImgWrapper onClick={handleClickImage(photo.type)}>
                      <img alt={photo.name} src={vehiclePhoto.url}/>
                    </ImgWrapper>
                    <ErrorButton disabled={requestLocked} onClick={handleDeleteModalOpen(photo.type)}>
                      <DeleteIcon />
                      Delete photo
                    </ErrorButton>
                  </>
                </Thumb>);
        })}
            <p>Other (Max 10)</p>
            {additionalCarPhotos === null || additionalCarPhotos === void 0 ? void 0 : additionalCarPhotos.map((photo) => (<Thumb key={photo.key}>
                <ImgContainer>
                  <ImgWrapper onClick={handleClickImage(photo.description)}>
                    <img alt={photo.description} src={photo.url}/>
                  </ImgWrapper>
                  <ErrorButton disabled={requestLocked} onClick={handleDeleteModalOpen(photo.description)}>
                    <DeleteIcon />
                    Delete photo
                  </ErrorButton>
                </ImgContainer>
              </Thumb>))}
            {((_b = additionalCarPhotos === null || additionalCarPhotos === void 0 ? void 0 : additionalCarPhotos.length) !== null && _b !== void 0 ? _b : 0) < 10 && (<div>
                <input disabled={requestLocked} type="file" style={{ display: 'none' }} id="upload-additional" accept="image/*" onChange={handleChangeAdditionalCarPhoto}/>
                <WrapperBoxPhoto htmlFor="upload-additional">
                  <BoxPhoto>
                    {isLoadingAdditional && (<ProgressContainer>
                        <CircularProgress size="1.75rem"/>
                      </ProgressContainer>)}
                    <Box>
                      <CameraAltIcon />
                    </Box>
                  </BoxPhoto>
                </WrapperBoxPhoto>
              </div>)}
          </div>
        </SimpleDrawerContent>
        <SimpleDrawerFooter>
          <FooterActionButton disabled={loading || !isValid || requestLocked} onClick={validateMileageAndSubmit}>
            {!loading ? saveBtnText : <CircularProgress size="1.75rem"/>}
          </FooterActionButton>
        </SimpleDrawerFooter>
        <DecodePlateAlert open={showDecodePlateAlert} onClick={handleDecodePlateAlertUpdate(false)} autoHideDuration={3000}/>
      </SimpleDrawer>
      <SimpleModal maxWidth="lg" open={carouselOpen} onClose={handleCarouselClose}>
        <CarouselWrapper>
          <CarouselHeader>
            <SlideTitle variant="h5">{currentImage}</SlideTitle>
            <IconButton onClick={handleCarouselClose}>
              <CloseIcon />
            </IconButton>
          </CarouselHeader>
          <CarouselContent>
            <LeftArrow onClick={handlePrevImage}>
              <ChevronLeft />
            </LeftArrow>
            <SlickSlider {...sliderSettings} ref={sliderRef}>
              {(_c = (orderedPhotos !== null && orderedPhotos !== void 0 ? orderedPhotos : [])) === null || _c === void 0 ? void 0 : _c.map(({ description, key, url }) => (<div key={key}>
                  <Slide>
                    <img alt={description} src={url}/>
                  </Slide>
                </div>))}
            </SlickSlider>
            <RightArrow onClick={handleNextImage}>
              <ChevronRight />
            </RightArrow>
          </CarouselContent>
        </CarouselWrapper>
      </SimpleModal>
      <SimpleModal open={deleteModalOpen} onClose={!deletePhotoLoading ? handleDeleteModalClose : noop} showClose={false} title="Delete this photo?" simpleTwoButtonRowProps={{
            onCancel: handleDeleteModalClose,
            onSubmit: handleDeletePhoto,
            submitText: 'Yes',
            cancelText: 'Cancel',
            disabledSubmit: requestLocked,
            disabledCancel: requestLocked,
            submitIsDestructive: true,
        }}>
        {deletePhotoLoading && (<ModalLoadingContainer>
            <CircularProgress size="1.75rem"/>
          </ModalLoadingContainer>)}
      </SimpleModal>
      <SimpleModal open={isVehicleChangingOldCarRequest} title="Some fields are not matching the old car information: " onClose={handleCancelOverrideCar} simpleTwoButtonRowProps={{
            submitText: 'Override Car',
            onCancel: handleCancelOverrideCar,
            onSubmit: handleOverrideCar,
            disabledCancel: requestLocked,
            disabledSubmit: requestLocked,
        }}>
        {confirmationNewVehicleText.map((error) => (<p>{error}</p>))}
      </SimpleModal>
    </>);
};
export default FourCornersDrawer;
