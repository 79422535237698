const template = {
    name: 'Job Updated',
    url: '/repair-service/skedulo/webhooks/job-updated',
    type: 'graphql',
    headers: {},
    query: `
  subscription {
    schemaJobs {
      operation
      timestamp
      data {
        UID
        AbortReason
        Address
        arrival_instructions
        CreatedDate
        CreatedById
        customer_facing_notes
        Description
        Duration
        GeoLatitude
        GeoLongitude
        JobStatus
        LastModifiedDate
        LastModifiedById
        Name
        NotesComments
        Start
        End
        Timezone
        Type
        reference_num
        service_advisor_notes
        shopboss_ro
        ymmte
        vin
        plate_state
        plate_number
        miles_in
        miles_out
        environment
        promo_code
        reference_link
        rs_modified_ms
        parts_ordered
      }
      previous {
        UID
        AbortReason
        Address
        arrival_instructions
        CreatedDate
        CreatedById
        customer_facing_notes
        Description
        Duration
        GeoLatitude
        GeoLongitude
        JobStatus
        LastModifiedDate
        LastModifiedById
        Name
        NotesComments
        Start
        End
        Timezone
        Type
        reference_num
        service_advisor_notes
        shopboss_ro
        ymmte
        vin
        plate_state
        plate_number
        miles_in
        miles_out
        environment
        promo_code
        reference_link
        rs_modified_ms
        parts_ordered
      }
    }
  }`,
};
export default template;
