import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HelpList } from 'src/AdminApp/containers/requests/Quote/PartsHelp/PartsHelpDrawer.styles';
import { selectRequestDiagnosis } from 'src/AdminApp/modules/requests/selectors';
import { Button } from 'src/components/mui';
import OptionalTooltip from 'src/components/tooltips/OptionalTooltip';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
const RepairsList = ({ onRepairSelect, disabled, invoice, }) => {
    var _a;
    const pageReferenceNum = usePageReferenceNum();
    const [internalDiag, setInternalDiag] = useState();
    const diagnosis = useSelector((state) => selectRequestDiagnosis(state, pageReferenceNum.selectorProps));
    useEffect(() => {
        setInternalDiag(invoice ? invoice : diagnosis);
    }, [invoice, diagnosis]);
    return (<HelpList>
      {((_a = internalDiag === null || internalDiag === void 0 ? void 0 : internalDiag.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) ? ([
            ...internalDiag.diagnosisServices.knownRepairs,
            ...internalDiag.diagnosisServices.customRequests,
        ].map((repair) => {
            var _a, _b;
            const hasAuthorization = (_a = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.workOrderResponsiblePartyAuthorizations) === null || _a === void 0 ? void 0 : _a.find((r) => {
                var _a;
                return r.suggestedRepairIds.includes(repair === null || repair === void 0 ? void 0 : repair.id) ||
                    ((_a = r.suggestedRepairIds) === null || _a === void 0 ? void 0 : _a.includes((repair === null || repair === void 0 ? void 0 : repair.suggestedRepairId) || 0));
            });
            return (<li key={repair.id}>
              <OptionalTooltip title={hasAuthorization &&
                    'This component is part of an authorized repair. To make changes, remove the authorization first.'}>
                <Button variant="text" onClick={() => {
                    onRepairSelect(repair);
                }} endIcon={<ArrowForwardIosRoundedIcon />} disabled={disabled ? disabled(repair) : !!hasAuthorization}>
                  {(_b = repair.name) !== null && _b !== void 0 ? _b : repair.message}
                </Button>
              </OptionalTooltip>
            </li>);
        })) : (<Button disabled>No repairs found</Button>)}
    </HelpList>);
};
export default RepairsList;
