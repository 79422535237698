import React from 'react';
import { useSelector } from 'react-redux';
import { Cell } from 'src/components/dashboard/SimpleTable/SimpleTableRow';
import EditIcon from 'src/components/icons/EditIcon';
import { TableRow } from 'src/components/mui';
import RoleTooltip from 'src/components/tooltips/RoleTooltip';
import { selectHasFinanceAdmin } from 'src/modules/auth/selectors';
import RESPONSIBLE_PARTIES_COLUMNS from './PartiesColumns';
const PartiesItem = ({ party, onEdit }) => {
    const isFinanceAdmin = useSelector(selectHasFinanceAdmin);
    const handleEdit = () => {
        if (onEdit) {
            onEdit({
                id: party.id,
                name: party.name,
                responsiblePartyTypeId: party.responsiblePartyTypeId,
                taxType: party.taxType,
                status: party.status,
                generatesRevenue: party.generatesRevenue,
                contactName: party.contactName,
                email: party.email,
                phone: party.phone,
                submissionMethod: party.submissionMethod,
                billingContacts: party.billingContacts,
                invoiceAutosendSchedule: party.invoiceAutosendSchedule,
            });
        }
    };
    return (<TableRow>
      {RESPONSIBLE_PARTIES_COLUMNS.map((column) => {
            const p = party;
            return (<Cell key={column.key}>
            {column.key === 'edit' ? (<RoleTooltip title="Only authorized users can edit responsible parties" hasRole={isFinanceAdmin}>
                <EditIcon disabled={!isFinanceAdmin} onClick={handleEdit}/>
              </RoleTooltip>) : (p[column.key])}
          </Cell>);
        })}
    </TableRow>);
};
export default PartiesItem;
