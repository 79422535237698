import * as Yup from 'yup';
import { addContextValidation, limitedString, positiveInteger, requiredString, } from 'src/PricingApp/utils/validations';
export const addComponentContextValidation = (values) => {
    return addContextValidation(values, AddComponentValidationSchema(values.type === 'FLUID'));
};
const AddComponentValidationSchema = (isFluid) => Yup.object().shape({
    name: requiredString('Component Name'),
    pcdbId: positiveInteger('PCDB id'),
    type: requiredString('Component type'),
    unit: requiredString('Unit type'),
    partSpecialistLevel: positiveInteger('Part Specialist Level'),
    metadata: isFluid
        ? Yup.object().shape({
            fluidType: limitedString('Fluid Type', 1, 100),
        })
        : Yup.object().nullable(),
    catalogComponentDetails: isFluid
        ? Yup.object().shape({
            externalId: positiveInteger('Motor taxonomy id'),
        })
        : Yup.object().nullable(),
});
