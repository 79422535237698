var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ModuleFormContainer } from 'src/AdminApp/components/form';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import FormDivider from 'src/PricingApp/components/forms/FormDivider';
import FormInput from 'src/PricingApp/components/forms/FormInput';
import ExcludeCatalogsSelect from './ExcludeCatalogsSelect';
import PositionSelect from './PositionSelect';
import { editRepairComponentValidationSchema } from './validations';
const EditRepairComponentForm = ({ repairComponent, repair, onDrawerClose, onSubmit, }) => {
    const [initialValues, setInitialValues] = useState({});
    const [disableSpecialOrder, setDisableSpecialOrder] = useState(false);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        setInitialValues(Object.assign(Object.assign({}, repairComponent), { defaultMinQuantity: (_a = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.defaultMinQuantity) !== null && _a !== void 0 ? _a : null, defaultMaxQuantity: (_b = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.defaultMaxQuantity) !== null && _b !== void 0 ? _b : null, defaultMinUnitPrice: (_c = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.defaultMinUnitPrice) !== null && _c !== void 0 ? _c : null, defaultMaxUnitPrice: (_d = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.defaultMaxUnitPrice) !== null && _d !== void 0 ? _d : null, overrideSpecialOrder: (_e = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.overrideSpecialOrder) !== null && _e !== void 0 ? _e : false, overrideLeadTime: (_f = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.overrideLeadTime) !== null && _f !== void 0 ? _f : null, overrideQuantity: (_g = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.overrideQuantity) !== null && _g !== void 0 ? _g : null, overrideUnitPrice: (_h = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.overrideUnitPrice) !== null && _h !== void 0 ? _h : null, repairName: repair === null || repair === void 0 ? void 0 : repair.name }));
        setDisableSpecialOrder(!(repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.overrideSpecialOrder));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repairComponent]);
    const handleSubmit = (values) => {
        onSubmit(values);
        onDrawerClose();
    };
    const isLabor = () => {
        var _a;
        return ((_a = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.component) === null || _a === void 0 ? void 0 : _a.type) === 'LABOR';
    };
    const handleEditSpecialOrder = (checked, setSpecialOrder, setOverrideLeadTime) => {
        setSpecialOrder();
        setOverrideLeadTime();
        setDisableSpecialOrder(!checked);
    };
    const handleReset = (resetForm, validateForm) => () => __awaiter(void 0, void 0, void 0, function* () {
        yield resetForm();
        validateForm();
    });
    return repairComponent ? (<Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit} validateOnMount validate={editRepairComponentValidationSchema}>
      {({ values, setFieldValue, errors, isSubmitting, resetForm, validateForm, submitForm, }) => {
            var _a, _b;
            return (<>
          <SimpleDrawerContent>
            <ModuleFormContainer>
              <FormInput placeholder="" label="Repair Name" valueName="repairName" disabled/>
              <FormInput placeholder="" label="Component Name" valueName="component.name" disabled/>
              <FormInput placeholder="" label="Component Type" valueName="component.type" disabled/>
              <FormDivider style={{ margin: '20px 0px 10px 0px' }}/>
              {((_a = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.component) === null || _a === void 0 ? void 0 : _a.type) !== 'FLUID' ? (<FormInput placeholder="" label="Position">
                  <PositionSelect positionName={(_b = values === null || values === void 0 ? void 0 : values.position) === null || _b === void 0 ? void 0 : _b.name} error={errors === null || errors === void 0 ? void 0 : errors.position} setPosition={(newPosition) => setFieldValue('position', newPosition)}/>
                </FormInput>) : null}
              <FormInput placeholder="" label="Default Min Quantity" valueName="defaultMinQuantity" type="number"/>
              <FormInput placeholder="" label="Default Max Quantity" valueName="defaultMaxQuantity" type="number"/>
              <FormInput placeholder="" label="Default Min Unit Price" valueName="defaultMinUnitPrice" type="number"/>
              <FormInput placeholder="" label="Default Max Unit Price" valueName="defaultMaxUnitPrice" type="number"/>
              <FormInput placeholder="" label="Override Quantity" valueName="overrideQuantity" type="number"/>
              <FormInput placeholder="" label="Override Unit Price" valueName="overrideUnitPrice" type="number"/>
              {!isLabor() && (<>
                  <FormInput placeholder="" label="Special Order" valueName="overrideSpecialOrder">
                    <CheckboxField name="overrideSpecialOrder" checked={!disableSpecialOrder} onChange={(e) => {
                        handleEditSpecialOrder(e.target.checked, () => setFieldValue('overrideSpecialOrder', e.target.checked), () => setFieldValue('overrideLeadTime', ''));
                    }}/>
                  </FormInput>
                  <FormInput placeholder="" label="Override Lead Time" valueName="overrideLeadTime" type="number" disabled={disableSpecialOrder}/>
                </>)}
              <FormInput placeholder="" label="Exclude Catalog IDS">
                <ExcludeCatalogsSelect currentIds={values === null || values === void 0 ? void 0 : values.excludedCatalogIds} setIds={(newExcludeCatalogIds) => setFieldValue('excludedCatalogIds', newExcludeCatalogIds)}/>
              </FormInput>
            </ModuleFormContainer>
          </SimpleDrawerContent>
          <SimpleDrawerFooter>
            <SimpleTwoButtonRow submitText="Submit" cancelText="Reset" onSubmit={submitForm} disabledCancel={isSubmitting} disabledSubmit={isSubmitting || !isEmpty(errors)} onCancel={handleReset(resetForm, validateForm)}/>
          </SimpleDrawerFooter>
        </>);
        }}
    </Formik>) : null;
};
export default EditRepairComponentForm;
