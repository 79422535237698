import React from 'react';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
import { Card, CardActions, CardContent, Divider, Grid, } from 'src/components/mui';
import { formVehicleDisplayText, formLogoUrl, } from 'src/AdminApp/containers/vehicle/utils';
import { Label, Value } from 'src/AdminApp/components/layout/Module';
const Logo = styled.img `
  max-height: 32px;
  max-width: 32px;
  margin-right: 8px;
`;
const CardHeader = styled.div `
  display: flex;
  padding: 16px;
  align-items: center;
`;
const StyledCardActions = styled(CardActions) `
  background-color: ${colors.transparentLightGrey};
  justify-content: center;
`;
const CarCard = ({ car, actions }) => {
    const { vin, engineDescription, style, model } = car;
    return (<Card variant="outlined">
      <CardHeader>
        <Logo src={formLogoUrl(car)}/> <b>{formVehicleDisplayText(car)}</b>
        <span>&nbsp;{model}</span>
      </CardHeader>
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <div>
              <Label bold>Engine</Label>
            </div>
            <div>
              <Value>{engineDescription}</Value>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Label bold>Trim</Label>
            </div>
            <div>
              <Value>{style}</Value>
            </div>
          </Grid>
          {!!vin && (<Grid item xs={6}>
              <div>
                <Label bold>Vin</Label>
              </div>
              <div>
                <Value>{vin}</Value>
              </div>
            </Grid>)}
        </Grid>
      </CardContent>
      <Divider />
      {actions && <StyledCardActions>{actions}</StyledCardActions>}
    </Card>);
};
export default CarCard;
