import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
// FSA ACTIONS
export const ActionCreators = {
    METROS: createDefaultActionCreators('METROS', ['CLEAR']),
    METRO: createDefaultActionCreators('METRO'),
    METROS_AUTOCOMPLETE: createDefaultActionCreators('METROS_AUTOCOMPLETE'),
    ADD_METRO: createDefaultActionCreators('ADD_METRO'),
    UPDATE_METRO: createDefaultActionCreators('UPDATE_METRO'),
    DELETE_METRO: createDefaultActionCreators('DELETE_METRO'),
    ARCHIVE_METRO: createDefaultActionCreators('ARCHIVE_METRO'),
};
export const getMetrosAutocomplete = (name, territoryIds) => {
    const filters = {
        name: name !== null && name !== void 0 ? name : '',
        territoryIds: territoryIds !== null && territoryIds !== void 0 ? territoryIds : [],
    };
    return createAPIAction({
        types: [
            ActionCreators.METROS_AUTOCOMPLETE.FETCH(),
            ActionCreators.METROS_AUTOCOMPLETE.SUCCESS(),
            ActionCreators.METROS_AUTOCOMPLETE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.METROS_AUTOCOMPLETE(filters),
        method: 'GET',
    });
};
export const getMetroById = (id) => createAPIAction({
    types: [
        ActionCreators.METRO.FETCH(),
        ActionCreators.METRO.SUCCESS({ meta: () => ({ metroId: id }) }),
        ActionCreators.METRO.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.METRO_BY_ID(id),
    method: 'GET',
});
export const addMetro = (metro) => {
    var _a;
    return createAPIAction({
        types: [
            ActionCreators.ADD_METRO.FETCH(),
            ActionCreators.ADD_METRO.SUCCESS(),
            ActionCreators.ADD_METRO.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.METROS(),
        method: 'POST',
        body: {
            name: metro.name,
            territoryId: (_a = metro.territory) === null || _a === void 0 ? void 0 : _a.id,
            regionIds: metro.regionIds,
            laborRates: metro === null || metro === void 0 ? void 0 : metro.laborRates,
            generalManagerId: metro === null || metro === void 0 ? void 0 : metro.generalManagerId,
            hyperionId: metro.hyperionId,
        },
    });
};
export const updateMetro = (metro, inPlace = false) => {
    var _a;
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_METRO.FETCH(),
            ActionCreators.UPDATE_METRO.SUCCESS({
                meta: () => ({
                    id: metro.id,
                    inPlace,
                }),
            }),
            ActionCreators.UPDATE_METRO.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.METRO_BY_ID(metro.id),
        method: 'PUT',
        body: {
            name: metro.name,
            territoryId: (_a = metro.territory) === null || _a === void 0 ? void 0 : _a.id,
            regionIds: metro.regionIds,
            laborRates: metro === null || metro === void 0 ? void 0 : metro.laborRates,
            generalManagerId: metro === null || metro === void 0 ? void 0 : metro.generalManagerId,
            hyperionId: metro.hyperionId,
        },
    });
};
export const archiveMetro = ({ id, transferMetroId, techniciansTransferMetroId, }) => createAPIAction({
    types: [
        ActionCreators.ARCHIVE_METRO.FETCH(),
        ActionCreators.ARCHIVE_METRO.SUCCESS({ meta: () => ({ id }) }),
        ActionCreators.ARCHIVE_METRO.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.METRO_ARCHIVE(id),
    method: 'PUT',
    body: { transferMetroId, techniciansTransferMetroId },
});
