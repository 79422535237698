import React from 'react';
import { ServicesList, MoreServiceLabel, DetailsValue, } from 'src/FleetVisitsApp/pages/VisitView/Services/services.styles';
import RepairsTooltip from 'src/FleetVisitsApp/pages/VisitView/RepairsTooltip';
const getServiceName = (name) => name.length > 24 ? `${name.substring(0, 24)}...` : name;
const Services = ({ repairs, extraRepairsCount, allRepairs, }) => {
    return (<ServicesList>
      {repairs === null || repairs === void 0 ? void 0 : repairs.map((item, idx) => (<li key={`${item.name}-${idx}`}>
          <DetailsValue>{getServiceName(item.name)}</DetailsValue>
          {idx === repairs.length - 1 && !!extraRepairsCount && (<MoreServiceLabel>
              <span>{!!extraRepairsCount && `+${extraRepairsCount} more`}</span>
              <RepairsTooltip allRepairs={allRepairs}/>
            </MoreServiceLabel>)}
        </li>))}
    </ServicesList>);
};
export default Services;
