import API_URLS from 'src/AdminApp/modules/api/urls';
import CONFIG from 'src/config';
import createAPIAction from 'src/modules/api';
import createAction from 'src/modules/api/utils/createAction';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createDefaultActionCreators, { createActionCreators, createDefaultApiActionCreators, } from 'src/utils/createActions';
export const ActionCreators = {
    TAB: createActionCreators([
        'SAVE_TAB',
        'SAVE_NEXT_TAB',
        'SAVE_DISABLE_BACK_NAV',
        'SAVE_CONTINUE_DISABLED',
        'SAVE_USER',
        'SAVE_CUSTOM_USER',
        'SAVE_FLEET',
        'SAVE_CUSTOM_FLEET',
        'SAVE_CUSTOM_ADDRESS',
        'SAVE_VEHICLE',
        'SAVE_PRICING',
        'CLEAR_VEHICLE',
        'SAVE_VEHICLE_MILEAGE',
        'SAVE_CUSTOM_VEHICLE',
        'RESET_VEHICLE',
        'RESET_CUSTOM_VEHICLE',
        'SET_RESPONSIBLE_PARTY',
        'SET_CREATE_NEW_FLEET_VEHICLE_RP',
        'CLEAR_CREATE_NEW_FLEET_VEHICLE_RP',
        'ADD_REPAIR',
        'REMOVE_REPAIR',
        'SAVE_REPAIRS',
        'RESET_REPAIRS',
        'SAVE_MECHANIC_IDS',
        'CLEAR_MECHANIC_IDS',
        'SAVE_VAN_ID',
        'CLEAR_VAN_ID',
        'SAVE_DATE_TIME',
        'CLEAR_DATE_TIME',
        'SAVE_FUTURE_APPOINTMENT_DATE_CHECK',
        'SAVE_FUTURE_APPOINTMENT_DATE',
        'SAVE_SHIFT',
        'CLEAR_MECHANICS',
        'SAVE_TIME_SLOT',
        'CLEAR_TIME_SLOT',
        'SAVE_CONTINUE_DISABLED',
        'RESET_SCHEDULING',
        'RESET_ALL',
        'SAVE_VISIT',
    ], 'QUICK_RO'),
    FETCH_PRICING: createDefaultActionCreators('FETCH_PRICING'),
    SCHEDULING_AVAILABILITY: createDefaultActionCreators('SCHEDULING_AVAILABILITY', ['CLEAR']),
    ACTIVE_MECHANICS: createDefaultActionCreators('ACTIVE_MECHANICS', ['CLEAR']),
    FETCH_FLEET_LATESTS_ROS: createDefaultActionCreators('FETCH_FLEET_LATESTS_ROS', ['CLEAR']),
    FETCH_FLEET_VISITS: createDefaultApiActionCreators('FETCH_FLEET_VISITS'),
};
export const fetchPricing = ({ repairIds, year, make, makeId, model, modelId, engineId, subModel, subModelId, vin, zip, }) => {
    if (!repairIds || repairIds.length === 0)
        return;
    return createAPIAction({
        types: [
            ActionCreators.FETCH_PRICING.FETCH(),
            ActionCreators.FETCH_PRICING.SUCCESS(),
            ActionCreators.FETCH_PRICING.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FAIR_PRICE(),
        method: 'GET',
        params: {
            repairIds,
            year,
            make,
            makeId,
            model,
            modelId,
            engineId,
            subModel,
            subModelId,
            vin,
            zip,
        },
    });
};
const DEFAULT_DAYS_AHEAD = 21;
export const fetchSchedulingAvailability = (args) => {
    const query = Object.assign({ days: DEFAULT_DAYS_AHEAD }, args);
    return createAPIAction({
        types: [
            ActionCreators.SCHEDULING_AVAILABILITY.FETCH(),
            ActionCreators.SCHEDULING_AVAILABILITY.SUCCESS(),
            ActionCreators.SCHEDULING_AVAILABILITY.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SCHEDULING_AVAILABILITY(query),
        method: 'GET',
    });
};
export const fetchActiveMechanicsInRegion = (args) => {
    const query = Object.assign(Object.assign({}, args), { days: DEFAULT_DAYS_AHEAD });
    return createAPIAction({
        types: [
            ActionCreators.ACTIVE_MECHANICS.FETCH(),
            ActionCreators.ACTIVE_MECHANICS.SUCCESS(),
            ActionCreators.ACTIVE_MECHANICS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SCHEDULING_MECHANICS(query),
        method: 'GET',
    });
};
export const fetchFleetLatestROs = (carId) => (dispatch) => dispatch(createAPIAction({
    types: [
        ActionCreators.FETCH_FLEET_LATESTS_ROS.FETCH(),
        ActionCreators.FETCH_FLEET_LATESTS_ROS.SUCCESS(),
        ActionCreators.FETCH_FLEET_LATESTS_ROS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.FLEET_LATEST_ROS(carId),
    method: 'GET',
}));
export const fetchFleetVisits = (fleetId, maxLeadTime) => (dispatch) => {
    dispatch(createAPIAction({
        types: fillApiTypes(ActionCreators.FETCH_FLEET_VISITS),
        endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/visits/fleets/${fleetId}`,
        method: 'GET',
        params: {
            maxLeadTime,
        },
    }));
};
export const setTab = (tab) => ActionCreators.TAB.SAVE_TAB({ payload: tab });
export const setNextTab = (nextTab) => ActionCreators.TAB.SAVE_NEXT_TAB({ payload: nextTab });
export const clearSchedulingAvailability = ActionCreators.SCHEDULING_AVAILABILITY.CLEAR;
export const saveUser = (user) => ActionCreators.TAB.SAVE_USER({ payload: user });
export const setVehicle = (v) => ActionCreators.TAB.SAVE_VEHICLE({ payload: v });
export const setFleet = (fleet) => ActionCreators.TAB.SAVE_FLEET({ payload: fleet });
export const setCustomFleet = (fleet) => ActionCreators.TAB.SAVE_CUSTOM_FLEET({ payload: fleet });
export const setCustomUser = (user) => ActionCreators.TAB.SAVE_CUSTOM_USER({
    payload: user,
});
export const setCustomConsumerAddress = (address) => ActionCreators.TAB.SAVE_CUSTOM_ADDRESS({
    payload: address,
});
export const setPricing = (p) => ActionCreators.TAB.SAVE_PRICING({
    payload: p,
});
export const setRepairs = (r) => ActionCreators.TAB.SAVE_REPAIRS({
    payload: r,
});
export const setIsBackNavDisabled = (disabled) => ActionCreators.TAB.SAVE_DISABLE_BACK_NAV({
    payload: disabled,
});
export const setContinueDisabled = (disabled) => ActionCreators.TAB.SAVE_CONTINUE_DISABLED({ payload: disabled });
export const setVisit = ({ visit }) => ActionCreators.TAB.SAVE_VISIT({ payload: { visit } });
export const saveShift = (shift) => ActionCreators.TAB.SAVE_SHIFT({
    payload: shift,
});
export const clearTimeslot = () => ActionCreators.TAB.CLEAR_TIME_SLOT();
export const clearMechanicIds = () => ActionCreators.TAB.CLEAR_MECHANIC_IDS();
export const clearActiveMechanics = () => ActionCreators.ACTIVE_MECHANICS.CLEAR();
export const saveFutureAppointmentDate = (selectedFutureAppointmentDate) => ActionCreators.TAB.SAVE_FUTURE_APPOINTMENT_DATE({
    payload: selectedFutureAppointmentDate,
});
export const saveFutureAppointmentDateCheck = (check) => ActionCreators.TAB.SAVE_FUTURE_APPOINTMENT_DATE_CHECK({
    payload: check,
});
export const saveTimeSlot = (data) => ActionCreators.TAB.SAVE_TIME_SLOT({
    payload: data,
});
export const saveDateTime = (dateTime) => ActionCreators.TAB.SAVE_DATE_TIME({
    payload: dateTime,
});
export const clearVanId = () => ActionCreators.TAB.CLEAR_VAN_ID();
export const saveMechanicIds = (id) => ActionCreators.TAB.SAVE_MECHANIC_IDS({
    payload: id,
});
export const saveVanId = (id) => ActionCreators.TAB.SAVE_VAN_ID({
    payload: id,
});
export const setResponsiblePartyId = createAction('SET_RESPONSIBLE_PARTY_ID');
