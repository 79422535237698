import moment from 'moment';
import { createSelector } from 'reselect';
export const selectMarketingSourcesState = (state) => state.admin.marketingSources;
export const selectRawDashboard = createSelector(selectMarketingSourcesState, (m) => { var _a; return (_a = m === null || m === void 0 ? void 0 : m.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectMarketingSources = createSelector(selectMarketingSourcesState, (m) => {
    var _a, _b;
    return (_b = (_a = m === null || m === void 0 ? void 0 : m.dashboard) === null || _a === void 0 ? void 0 : _a.content.map((s) => {
        var _a, _b;
        return ({
            id: s.id,
            displayName: s.displayName,
            marketingName: s.marketingName,
            visibility: (_a = s.visibility) !== null && _a !== void 0 ? _a : [],
            preventFeeCodes: (_b = s.preventFeeCodes) !== null && _b !== void 0 ? _b : [],
            created: moment(s.created).format('lll'),
            createdBy: s.createdBy,
            modified: moment(s.modified).format('lll'),
            modifiedBy: s.modifiedBy,
        });
    })) !== null && _b !== void 0 ? _b : [];
});
export const selectFees = createSelector(selectMarketingSourcesState, (m) => m === null || m === void 0 ? void 0 : m.fees);
export const selectDashboardPagination = createSelector(selectRawDashboard, (dashboard) => {
    var _a, _b, _c, _d, _e, _f;
    return {
        pageSize: (_b = (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.pageable) === null || _a === void 0 ? void 0 : _a.pageSize) !== null && _b !== void 0 ? _b : 20,
        pageNumber: (_d = (_c = dashboard === null || dashboard === void 0 ? void 0 : dashboard.pageable) === null || _c === void 0 ? void 0 : _c.pageNumber) !== null && _d !== void 0 ? _d : 0,
        totalElements: (_e = dashboard === null || dashboard === void 0 ? void 0 : dashboard.totalElements) !== null && _e !== void 0 ? _e : 0,
        totalPages: (_f = dashboard === null || dashboard === void 0 ? void 0 : dashboard.totalPages) !== null && _f !== void 0 ? _f : 1,
    };
});
export const selectDashboardParams = createSelector(selectRawDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardFetching = createSelector(selectRawDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
