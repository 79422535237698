var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { AppBar, Tabs, Tab } from 'src/components/mui';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdatingJobId, setIsJobInfoLoading } from 'src/AdminApp/modules/swimlanes/actions';
import { selectSelectedJobs } from 'src/AdminApp/modules/swimlanes/selectors';
import JobInfoContent from './JobInfoContent';
const TabPanel = (props) => {
    const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
    return (<div role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
      {value === index && children}
    </div>);
};
const JobInfo = ({ jobType }) => {
    const dispatch = useDispatch();
    const selectedJobs = useSelector(selectSelectedJobs);
    const [tabIndex, setTabIndex] = useState(0);
    const sortedSelectedJobs = [...selectedJobs].sort((a, b) => {
        const startDateA = new Date(a.repairJob.startDate);
        const startDateB = new Date(b.repairJob.startDate);
        return startDateA.getTime() - startDateB.getTime();
    });
    return (sortedSelectedJobs === null || sortedSelectedJobs === void 0 ? void 0 : sortedSelectedJobs.length) > 1 ? (<>
      <AppBar position="static" color="transparent" style={{
            boxShadow: 'none',
            maxWidth: '375px',
        }}>
        <Tabs value={tabIndex} onChange={(e, newValue) => {
            setTabIndex(newValue);
            dispatch(setIsJobInfoLoading(true));
        }} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
          {sortedSelectedJobs.map((job, index) => (<Tab key={job.id} label={`Job ${index + 1}`}/>))}
        </Tabs>
      </AppBar>
      {sortedSelectedJobs.map((job, index) => tabIndex === index && (<TabPanel key={job.id} value={tabIndex} index={index}>
              <JobInfoContent selectedJobs={sortedSelectedJobs} showMoveButton jobType={jobType} jobIdx={index} onJobUpdate={(id) => dispatch(setUpdatingJobId(id))}/>
            </TabPanel>))}
    </>) : (<JobInfoContent selectedJobs={selectedJobs} jobType={jobType}/>);
};
export default JobInfo;
