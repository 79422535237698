var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { isArray } from 'lodash';
import { Button, Grid, Typography } from 'src/components/mui';
import { Edit as EditIcon, Check as CheckIcon } from 'src/components/mui/icons';
import LabeledField from 'src/components/layout/LabeledField';
import CurrencyLabel from 'src/components/CurrencyLabel';
import { colors } from 'src/styles/theme';
import { formatTimeOnly } from 'src/utils/formatter';
export const AddButtonsWrapper = styled(Grid) `
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding-right: 20px;
`;
export const RepairTitle = styled.div `
  font-weight: bold;
  flex: 2;
`;
export const HeaderTitle = styled.div `
  color: ${(props) => props.removed === 'true' ? colors.grey : colors.darkGrey};
  flex: 1;
  font-weight: bold;
  text-align: left;
`;
export const Title = styled.div `
  font-weight: bold;
`;
const StatementRowGrid = styled(Grid) `
  border-bottom: 2px solid ${colors.lightGrey};
  display: flex;
  padding: 10px 50px 10px 20px;

  &:nth-child(even) {
    border-right: none;
  }

  &:last-child {
    border-right: none;
  }

  @media (max-width: 960px) {
    padding: 10px 50px 10px 10px;
  }
`;
export const StatementRow = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (<StatementRowGrid item container style={{ justifyContent: 'space-between' }} {...rest}>
    {children}
  </StatementRowGrid>);
};
export const QuotedTotal = styled.div `
  color: rgba(0, 0, 0, 0.26);
  font-weight: bold;
  width: 150px;
`;
export const addButtonStyles = `
  font-weight: bold;
  flex: 1;
  cursor: pointer;
`;
export const AddComponentButton = styled.div `
  ${addButtonStyles};
  color: ${colors.lightBlue};
  margin-left: 10px;
`;
export const SubletLabel = styled.div `
  width: 150px;
  background-color: ${colors.lightOrange};
  font-weight: bold;
  text-align: center;
  margin-right: 10px;
  height: fit-content;
`;
export const QuoteGridItem = styled(Grid) `
  border-bottom: 2px solid ${colors.lightGrey};
  border-right: 2px dashed ${colors.lightGrey};
  display: flex;
  padding: 10px;

  &:nth-child(even) {
    border-right: none;
  }
  &:last-child {
    border-right: none;
  }
`;
export const SubItemGridWrapper = styled(Grid) `
  color: colors.grey;
  font-size: 0.9rem;
`;
export const SubItem = styled.div `
  margin-bottom: 16px;
`;
export const CalculatedTotalWrapper = styled.div `
  color: ${(props) => (props.removed ? colors.grey : colors.darkGrey)};
  flex: 1;
  font-weight: bold;
  text-align: right;
  font-weight: ${(props) => (props.removed ? 'normal' : 'bold')};
`;
export const Dots = styled.div `
  border-bottom: 2px dotted ${colors.grey};
  flex-grow: 1;
  margin: 5px;
  opacity: 0.5;
`;
export const PartSecondaryInfo = styled.div `
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.33;
  color: ${colors.darkGrey};
  margin-top: 3px;
`;
export const StyledEditIcon = styled(EditIcon) `
  font-size: 18px;
  color: ${colors.GRAY_CC};
  margin-left: 4px;
`;
export const CheckmarkIcon = styled(CheckIcon) `
  position: absolute;
  color: ${colors.success};
  margin-left: -30px;
  margin-top: 20px;
`;
export const RemoveRepairButton = styled(Button) `
  color: ${colors.error};
`;
export const LabeledGridField = (_a) => {
    var { children, label } = _a, rest = __rest(_a, ["children", "label"]);
    return (<Grid item xs={12}>
    <LabeledField label={label} {...rest}>
      {children}
    </LabeledField>
  </Grid>);
};
export const CalculatedTotalCost = ({ values, }) => {
    return isArray(values) ? (<>
      {values.map((value, idx) => (<>
          <CurrencyLabel>{value}</CurrencyLabel>
          {idx !== values.length - 1 ? ' - ' : ' '}
        </>))}
    </>) : (<CurrencyLabel>{values}</CurrencyLabel>);
};
export const ChipText = styled(Typography) `
  font-weight: 500;
  text-transform: capitalize;
`;
export const alignSelfText = {
    alignSelf: 'center',
};
export const TotalPod = styled(Grid) `
  display: flex;
  justify-content: flex-end;
  padding-right: 32px;
  text-align: right;
`;
export const QuotedPod = styled(Grid) `
  color: ${colors.middleGrey};
  padding-right: 24px;
  text-align: right;
`;
export const ChipGrid = styled(Grid) `
  margin-bottom: var(--margin-bottom, 0);
  margin-top: var(--margin-top, 16px);
`;
const NoWrap = styled.span `
  white-space: nowrap;
`;
export const ArrivalWindow = ({ start = null, end = null, style = {}, }) => (<div style={Object.assign({ fontSize: '0.8 rem' }, style)}>
    <i>
      {'Arrival: '}
      {!start && !end ? ('N/A') : (<>
          <NoWrap>{formatTimeOnly(start)}</NoWrap>
          {!!start || !!end ? ' - ' : ''}
          <NoWrap>{formatTimeOnly(end)}</NoWrap>
        </>)}
    </i>
  </div>);
export const RepairAuditInfoWrapper = styled.div `
  margin-top: 24px;
`;
export const Bullet = styled.span `
  padding: 0 5px;
`;
