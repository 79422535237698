import React from 'react';
import { Link } from 'react-router-dom';
import { ApptMain, ApptMainActions, ApptMainInfo, JobInfoContainer, JobInfoSection, JobInfoSubsection, } from 'src/AdminApp/containers/swimlanes/Chart/ChartJob/JobInfoPopover/JobInfo.styles';
import useVisitJobInfo from 'src/AdminApp/containers/swimlanes/Chart/ChartJob/JobInfoPopover/JobInfoByType/VisitJobInfo/useVisitJobInfo';
import Loader from 'src/AdminApp/containers/swimlanes/Chart/ChartJob/JobInfoPopover/Loader';
import { convertToTimezone } from 'src/AdminApp/containers/swimlanes/Chart/utils';
import { CopyToClipboardButton } from 'src/components/buttons';
import { Button, Divider } from 'src/components/mui';
import SimpleChip from 'src/components/SimpleChip';
import AddFleetVisitDrawer from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer';
import { colors } from 'src/styles/theme';
import { TIME_12H_FORMAT } from 'src/timeConstants';
const VisitJobInfo = ({ selectedJobs, referenceNum, showMoveButton, jobIdx, onJobUpdate, }) => {
    const { loading, visit, openVisitDrawer, handleClose, jobCancellationStatus, } = useVisitJobInfo({ selectedJobs, referenceNum, jobIdx });
    if (loading || !visit || !selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0]) {
        return <Loader />;
    }
    return (<>
      <JobInfoContainer>
        <JobInfoSection>
          <ApptMain className="hour">
            <ApptMainInfo>
              {convertToTimezone(selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.startDate, selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.timezone, TIME_12H_FORMAT)}{' '}
              -{' '}
              {convertToTimezone(selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.endDate, selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.timezone, TIME_12H_FORMAT)}
              <div style={{ marginLeft: '5px' }}>
                <SimpleChip $bgColor={colors.BLUE} $textColor={colors.white} label="VISIT"/>
              </div>
            </ApptMainInfo>
            <ApptMainActions>
              <div>
                <span className="ref">Ref #: </span>
                <Link to={`/admin/visits/${visit.refNum}`} style={{
            textDecoration: 'none',
        }}>
                  {visit.refNum}
                </Link>
              </div>
              <CopyToClipboardButton value={visit.refNum} width={18} style={{
            color: colors.primary,
            minWidth: 'auto',
            marginLeft: '10px',
        }}/>
            </ApptMainActions>
          </ApptMain>
        </JobInfoSection>
        <Divider />
        <JobInfoSection>
          {visit.address && (<JobInfoSubsection>
              <span className="label">Visit Address</span>
              <span className="value primary" style={{
                whiteSpace: 'pre',
            }}>
                {`${visit.address.street}\n${visit.address.city}, ${visit.address.state} ${visit.address.zip}`}
                <CopyToClipboardButton value={`${visit.address.street}\n ${visit.address.city}, ${visit.address.state} ${visit.address.zip}`} width={18} style={{
                color: colors.primary,
                minWidth: 'auto',
                marginLeft: '10px',
            }}/>
              </span>
            </JobInfoSubsection>)}
        </JobInfoSection>

        <Divider />

        <JobInfoSection>
          {(visit === null || visit === void 0 ? void 0 : visit.resources) && (<div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
              <JobInfoSubsection style={{
                marginBottom: '0',
            }}>
                <span className="label">Techs</span>
                <span className="value">
                  {visit.resources
                .filter((tech) => tech.type === 'MECHANIC' && tech.status !== 'DELETED')
                .map((mech) => mech.technicianName)
                .join(', ')}
                </span>
              </JobInfoSubsection>
              {showMoveButton && (<Button color="primary" variant="contained" onClick={() => onJobUpdate ? onJobUpdate(selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].id) : {}}>
                  Move
                </Button>)}
            </div>)}
        </JobInfoSection>
      </JobInfoContainer>

      <AddFleetVisitDrawer open={openVisitDrawer} onClose={handleClose} newStartTime={jobCancellationStatus === null || jobCancellationStatus === void 0 ? void 0 : jobCancellationStatus.newStartTime} reassignVanFunction={jobCancellationStatus === null || jobCancellationStatus === void 0 ? void 0 : jobCancellationStatus.reassignVanFunction}/>
    </>);
};
export default VisitJobInfo;
