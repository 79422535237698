import React from 'react';
import { useSelector } from 'react-redux';
import { selectConsumerById } from 'src/AdminApp/modules/consumers/selectors';
import { selectContactInfo } from 'src/AdminApp/modules/requests/selectors';
import RepairSearchSelect from 'src/components/form/RepairSearchSelect';
import { SubText } from 'src/components/SimpleDrawer';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
const AdditionalRepairsRequired = ({ onRepairSelect, onCustomRepairSelected, onSearchRepairs, title, instructions, }) => {
    const { selectorProps } = usePageReferenceNum();
    const contactInfo = useSelector((state) => selectContactInfo(state, selectorProps));
    const consumerId = contactInfo ? +contactInfo.consumerId : 0;
    const consumer = useSelector(selectConsumerById(consumerId));
    return (<>
      <h2>{title}</h2>
      {instructions && <SubText>{instructions}</SubText>}
      <br />
      <RepairSearchSelect onSelect={onRepairSelect} onSearch={onSearchRepairs} autoFocus clearCustom={false} fleetId={consumer.fleetId} handleCustomRepairDialog={onCustomRepairSelected} showCustomInputActionButtons={false}/>
    </>);
};
export default AdditionalRepairsRequired;
