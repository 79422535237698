import React from 'react';
import { PrimaryButton } from 'src/components/buttons';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, DatePicker, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField, Typography, } from 'src/components/mui';
import { AttachMoneyRounded as AttachMoneyRoundedIcon, ExpandMore as ExpandMoreIcon, SearchRounded as SearchIcon, } from 'src/components/mui/icons';
import { FilterActions, FilterFieldset, FilterSection, FilterTitle, FilterWrapper, SearchBar, } from 'src/PopsApp/pages/Inventory/Inventory.styles';
const InventorySearchBar = ({ searchPoNumberFilter, onSearchChange, handleSearch, locationSearchResults, handleLocationSelected, searchLocations, minPrice, maxPrice, setPrice, statusFilter, setStatusFilter, resetSearchFilters, selectedLocation, setCreatedFrom, createdFrom, setCreatedTo, createdTo, }) => {
    const showMinMaxError = minPrice && maxPrice && minPrice >= maxPrice;
    return (<div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '100%' }}>
            <FilterWrapper>
              <FilterSection style={{ width: '100%' }}>
                <FilterFieldset>
                  <Autocomplete fullWidth id="locations-select" clearOnBlur={false} options={locationSearchResults !== null && locationSearchResults !== void 0 ? locationSearchResults : []} getOptionLabel={(option) => `${option.name} - ${option.type.replace(/_/g, ' ')}`} onChange={handleLocationSelected} onInputChange={searchLocations} value={selectedLocation !== null && selectedLocation !== void 0 ? selectedLocation : null} renderInput={(params) => (<TextField {...params} variant="outlined" placeholder="Search by Hub, Van or Region" label="Location Search"/>)}/>
                </FilterFieldset>
              </FilterSection>
            </FilterWrapper>

            <FilterWrapper>
              <FilterSection>
                <FilterTitle>Core Cost Range</FilterTitle>
                <FilterFieldset>
                  <TextField variant="outlined" value={minPrice} onChange={(e) => setPrice(Number(e.target.value), true)} type="number" name="minPrice" label="Min Cost" InputProps={{
            startAdornment: (<InputAdornment position="start">
                          <AttachMoneyRoundedIcon />
                        </InputAdornment>),
        }}/>
                  <div>to</div>
                  <TextField variant="outlined" value={maxPrice} onChange={(e) => setPrice(Number(e.target.value), false)} type="number" name="maxPrice" label="Max Cost" InputProps={{
            startAdornment: (<InputAdornment position="start">
                          <AttachMoneyRoundedIcon />
                        </InputAdornment>),
        }}/>
                </FilterFieldset>
              </FilterSection>

              <FilterSection>
                <FilterTitle>Created Date</FilterTitle>

                <FilterFieldset>
                  <DatePicker onChange={(date) => {
            setCreatedFrom(date);
            setCreatedTo(null);
        }} value={createdFrom} slotProps={{
            field: {
                clearable: true,
            },
        }}/>

                  <div>to</div>
                  <DatePicker onChange={(date) => setCreatedTo(date)} value={createdTo} minDate={createdFrom === null || createdFrom === void 0 ? void 0 : createdFrom.clone().add(1, 'day')} slotProps={{
            field: {
                clearable: true,
            },
        }} disabled={!createdFrom}/>
                </FilterFieldset>
              </FilterSection>
            </FilterWrapper>

            <FilterWrapper>
              <FilterSection>
                <FilterTitle>Status</FilterTitle>
                <RadioGroup row aria-label="statusFilter" name="statusFilter" value={statusFilter} onChange={(e, value) => {
            setStatusFilter(value);
        }}>
                  <FormControlLabel value="PENDING" control={<Radio />} label="Pending"/>
                  <FormControlLabel value="RETURNED" control={<Radio />} label="Returned"/>
                </RadioGroup>
              </FilterSection>
            </FilterWrapper>

            {showMinMaxError && (<Typography color="error">
                ** Core Charge Max should be higher than the Min
              </Typography>)}
          </div>
        </AccordionDetails>
      </Accordion>

      <SearchBar>
        <div className="searchWrapper">
          <TextField type="search" label="Search by PO#" variant="outlined" fullWidth name="searchFilter" onKeyDown={(e) => {
            if (e.key === 'Enter') {
                handleSearch();
            }
        }} onChange={onSearchChange} value={searchPoNumberFilter} InputProps={{
            startAdornment: (<InputAdornment position="start">
                  <SearchIcon color="disabled"/>
                </InputAdornment>),
        }}/>
        </div>
      </SearchBar>

      <FilterActions>
        <Button variant="text" onClick={resetSearchFilters}>
          Reset
        </Button>
        <PrimaryButton type="submit" onClick={handleSearch}>
          Search
        </PrimaryButton>
      </FilterActions>
    </div>);
};
export default InventorySearchBar;
