import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { colors } from 'src/styles/theme';
export const IntroContentTodayReturn = styled.section `
  text-align: left;
  overflow-y: scroll;
`;
export const SubtitleText = styled.h2 `
  margin: 8px 0 0;
  font-size: 1rem;
`;
export const IntroTodayReturnCopy = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  text-align: left;
  color: ${colors.GRAY_76};
  margin: 16px 0 0;
`;
export const ReactMarkdownStyled = styled(ReactMarkdown) `
  font-size: 12px;
  font-style: italic;
`;
