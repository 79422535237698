import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const UserProfileInfoWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  min-width: 50%;

  p {
    text-align: left;
    margin: 3px 0;
  }
`;
export const UserContactInfo = styled.div `
  display: flex;
  margin-bottom: 50px;
`;
export const UserPhoneInfo = styled.div `
  display: flex;
  justify-content: between;
  margin-right: 50px;

  svg {
    color: ${colors.GRAY_76};
    margin-right: 10px;
  }
`;
export const UserEmailInfo = styled.div `
  display: flex;
  justify-content: between;

  svg {
    color: ${colors.GRAY_76};
    margin-right: 10px;
  }
`;
export const UserDetailsInfo = styled.div `
  display: flex;
  flex-direction: column;
`;
export const UserDetailsRow = styled.div `
  display: flex;
  margin-bottom: 5px;

  & > p:first-child {
    color: ${colors.GRAY_76};
    font-weight: bold;
    margin-right: 10px;
    width: 85px;
  }
`;
