var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'src/AdminApp/components/form';
import { boldSelectProps } from 'src/styles/styled';
import { Button, Grid, IconButton, Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Paper, Autocomplete, } from 'src/components/mui';
import Loader from 'src/FleetVisitsApp/pages/FleetVisits/components/Loader';
import styled from 'styled-components';
import { Delete } from 'src/components/mui/icons';
import { Item } from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/PayerDrawer/PayerDrawer.styles';
import { toast } from 'src/components/SimpleToast';
import { useNetSuiteApiClient } from '../../useNetSuiteApiClient';
const GridItem = styled(Grid) `
  padding: 5px;
`;
const Cell = styled(TableCell) `
  font-size: 14px;
`;
const PoNew = () => {
    const [initialValues, setInitialValues] = useState({
        entity: { id: '' },
        location: { id: '' },
        item: {
            items: [
                {
                    item: {
                        refName: '',
                        id: '',
                    },
                    class: {
                        refName: '',
                        id: '',
                    },
                    quantity: 0,
                    rate: 0,
                    amount: 0,
                    description: '',
                },
            ],
        },
    });
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [po, setPO] = useState();
    const [vendors, setVendors] = useState([]);
    const [locations, setLocations] = useState([]);
    const [classifications, setClassifications] = useState([]);
    const [items, setItems] = useState([]);
    const netSuiteApiClient = useNetSuiteApiClient();
    const { id } = useParams();
    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e;
            setIsLoading(true);
            try {
                const vendors = yield netSuiteApiClient.fetchActiveVendors();
                const locations = yield netSuiteApiClient.fetchActiveLocations();
                const classifications = yield netSuiteApiClient.fetchActiveClasses();
                const items = yield netSuiteApiClient.fetchActiveInvItems();
                setVendors((_a = vendors === null || vendors === void 0 ? void 0 : vendors.items) !== null && _a !== void 0 ? _a : []);
                setLocations((_b = locations === null || locations === void 0 ? void 0 : locations.items) !== null && _b !== void 0 ? _b : []);
                setClassifications((_c = classifications === null || classifications === void 0 ? void 0 : classifications.items) !== null && _c !== void 0 ? _c : []);
                setItems((_d = items === null || items === void 0 ? void 0 : items.items) !== null && _d !== void 0 ? _d : []);
                if (id !== 'new') {
                    const res = yield netSuiteApiClient.fetchPurchaseOrdersById(id, true);
                    setPO(res);
                    if (((_e = res.status) === null || _e === void 0 ? void 0 : _e.id) !== 'Pending Receipt')
                        setDisabled(true);
                    setInitialValues({
                        entity: res.entity,
                        item: res.item,
                        location: res.location,
                    });
                }
            }
            catch (ex) {
                // Handle error
            }
            setIsLoading(false);
        });
        // Simulate an API call (replace with your actual API endpoint)
        fetchData().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const handleCreatePO = (values) => __awaiter(void 0, void 0, void 0, function* () {
        // Make your API call here with the searchTerm
        // Update searchResults based on the API response
        setIsLoading(true);
        try {
            let message = 'PO CREATED';
            if (po) {
                yield netSuiteApiClient.updatePo(values, id);
                message = 'PO UPDATED';
            }
            else {
                yield netSuiteApiClient.createPO(values);
            }
            toast.error(message);
            history.push('/scan-poc/v3');
        }
        catch (ex) {
            toast.error(`ERROR ${ex.message}`);
        }
        setIsLoading(false);
    });
    function calculateAmount(items, index) {
        var _a, _b;
        const item = items[index];
        return ((_a = item === null || item === void 0 ? void 0 : item.quantity) !== null && _a !== void 0 ? _a : 0) * ((_b = item === null || item === void 0 ? void 0 : item.rate) !== null && _b !== void 0 ? _b : 0);
    }
    return (<div>
      {isLoading ? (<Loader />) : (<>
          <h1>{!po ? 'New' : ''} Purchase Order</h1>
          {po ? (<span>
              {' '}
              Po Number: {po.tranId} <hr />
              <br />
            </span>) : null}
          <Formik initialValues={initialValues} onSubmit={handleCreatePO} enableReinitialize>
            {({ setFieldValue, values }) => (<Form>
                <Grid container spacing={2}>
                  {/* Select Input 1 */}
                  <GridItem xs={12} sm={6} item>
                    <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 300,
                }}>
                      <TextField select required label="Vendor" name="entity.id" SelectProps={boldSelectProps} variant="outlined" disabled={disabled}>
                        <Item disabled value="">
                          Select Vendor
                        </Item>
                        {vendors.map(({ id, refname }) => (<Item key={id} value={id}>
                            {refname}
                          </Item>))}
                      </TextField>
                    </div>
                  </GridItem>

                  {/* Select Input 2 */}
                  <GridItem xs={12} sm={6} item>
                    <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 300,
                }}>
                      <TextField select required label="Location" name="location.id" SelectProps={boldSelectProps} variant="outlined" disabled={disabled}>
                        <Item disabled value="">
                          Select Location
                        </Item>
                        {locations.map(({ id, refname }) => (<Item key={id} value={id}>
                            {refname}
                          </Item>))}
                      </TextField>
                    </div>
                  </GridItem>
                </Grid>
                <h3>Items</h3>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Class</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <FieldArray name="values">
                      {() => (<TableBody>
                          {values.item.items.map((item, index) => (<TableRow key={index}>
                              <Cell>
                                {po && item.item.id ? (<Field disabled id={`item.items.${index}.id`} name={`item.items.${index}.id`} as={TextField} value={item.item.id}/>) : (<Field name={`item.items.${index}.item.id`}>
                                    {({ field }) => (<Autocomplete id={`item.items.${index}.item.id`} options={items} getOptionLabel={(option) => option.fullname} onChange={(_, value) => setFieldValue(field.name, value.id)} renderInput={(params) => (<TextField {...params} name={`item.items.${index}.item.id`} fullWidth/>)}/>)}
                                  </Field>)}
                              </Cell>
                              <Cell>
                                <Field required id={`item.items.${index}.description`} name={`item.items.${index}.description`} as={TextField} disabled={disabled}/>
                              </Cell>
                              <Cell>
                                <TextField required select name={`item.items.${index}.class.id`} SelectProps={boldSelectProps} defaultValue="" disabled={disabled}>
                                  {classifications.map(({ id, refname }) => (<Item key={id} value={id}>
                                      {refname}
                                    </Item>))}
                                </TextField>
                              </Cell>
                              <Cell>
                                <Field required id={`item.items.${index}.quantity`} name={`item.items.${index}.quantity`} as={TextField} type="number" disabled={disabled}/>
                              </Cell>
                              <Cell>
                                <Field required id={`item.items.${index}.rate`} name={`item.items.${index}.rate`} as={TextField} type="number" disabled={disabled}/>
                              </Cell>
                              <Cell>
                                {calculateAmount(values.item.items, index)}
                              </Cell>
                              <Cell>
                                <IconButton style={{ color: 'red' }} onClick={() => {
                            const updatedItems = initialValues.item.items.filter((_, i) => index !== i);
                            const item = Object.assign({}, initialValues.item);
                            item.items = updatedItems;
                            setInitialValues(Object.assign(Object.assign({}, initialValues), { item }));
                        }}>
                                  <Delete />
                                </IconButton>
                              </Cell>
                            </TableRow>))}
                        </TableBody>)}
                    </FieldArray>
                  </Table>
                </TableContainer>
                <Grid container style={{
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                }}>
                  <GridItem xs={12} sm={12} item>
                    <Grid container spacing={2} direction="row" style={{ justifyContent: 'flex-end' }}>
                      <GridItem item>
                        <Button onClick={() => {
                    const item = Object.assign({}, initialValues.item);
                    item.items.push({
                        item: {
                            refName: '',
                            id: '',
                        },
                        class: {
                            refName: '',
                            id: '',
                        },
                        quantity: 0,
                        rate: 0,
                        amount: 0,
                        description: '',
                    });
                    setInitialValues(Object.assign(Object.assign({}, initialValues), { item }));
                }} variant="contained" color="primary" disabled={disabled}>
                          Add New Item
                        </Button>
                      </GridItem>
                      <GridItem item>
                        <Button type="submit" variant="contained" color="primary" disabled={disabled}>
                          {po ? 'Update PO' : 'Create PO'}
                        </Button>
                      </GridItem>
                    </Grid>
                  </GridItem>
                </Grid>
              </Form>)}
          </Formik>
        </>)}
    </div>);
};
export default PoNew;
