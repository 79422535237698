import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { selectSelectedDate } from 'src/AdminApp/modules/swimlanes/selectors';
import { setSelectedDate } from 'src/AdminApp/modules/swimlanes/actions';
import moment from 'moment';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
const useDatePicker = () => {
    var _a;
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const selectedDate = useSelector(selectSelectedDate);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const today = moment().format(DATE_YMD_FORMAT);
    const currentDate = (_a = params.date) !== null && _a !== void 0 ? _a : today;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const goToDate = (date) => {
        if (date !== selectedDate) {
            dispatch(setSelectedDate(date));
            history.push(`/admin/swimlanes/${date}`);
        }
    };
    const goToToday = () => {
        goToDate(today);
    };
    const handleSetPrevDate = () => {
        goToDate(moment(selectedDate).subtract(1, 'day').format(DATE_YMD_FORMAT));
    };
    const handleSetNextDate = () => {
        goToDate(moment(selectedDate).add(1, 'day').format(DATE_YMD_FORMAT));
    };
    const handleDateChange = (date) => {
        goToDate(date === null || date === void 0 ? void 0 : date.format(DATE_YMD_FORMAT));
        setAnchorEl(null);
    };
    useEffect(() => {
        goToDate(currentDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        anchorEl,
        open,
        goToToday,
        handleClick,
        handleClose,
        handleSetPrevDate,
        handleSetNextDate,
        selectedDate,
        handleDateChange,
    };
};
export default useDatePicker;
