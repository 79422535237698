import moment from 'moment';
import React from 'react';
import { SimpleTableRow } from 'src/components/dashboard/SimpleTable';
import { Button } from 'src/components/mui';
import { DATE_TIME_12H_FORMAT } from 'src/timeConstants';
const InvoicePdfItem = ({ invoice, onViewDetailsClick, onDownloadInvoiceClick, isInvoiceDownloading, }) => {
    var _a, _b, _c;
    const handleViewDetailsClick = () => {
        if (!invoice.invoiceSentHistory || invoice.invoiceSentHistory.length === 0)
            return;
        onViewDetailsClick(invoice.invoiceSentHistory);
    };
    return (<SimpleTableRow cellProps={{ style: { fontSize: '12px', whiteSpace: 'nowrap' } }} data={[
            <Button variant="text" onClick={() => {
                    if (invoice.s3Url) {
                        window.open(invoice.s3Url, '_blank');
                    }
                    else {
                        onDownloadInvoiceClick(invoice);
                    }
                }} disabled={isInvoiceDownloading} title="Download Invoice">
          {invoice.id}
        </Button>,
            <>
          {(_a = invoice.workOrderResponsibleParty.responsibleParty) === null || _a === void 0 ? void 0 : _a.name} <wbr />(
          {(_c = (_b = invoice.workOrderResponsibleParty.responsibleParty) === null || _b === void 0 ? void 0 : _b.responsiblePartyType) === null || _c === void 0 ? void 0 : _c.name}
          )
        </>,
            moment.utc(invoice.created).local().format(DATE_TIME_12H_FORMAT),
            invoice.lastMailSent
                ? moment
                    .utc(invoice.lastMailSent)
                    .local()
                    .format(DATE_TIME_12H_FORMAT)
                : '',
            <div style={{ textAlign: 'center' }}>
          {invoice.lastMailSent ? invoice.lastMailSentTo.length : ''}
        </div>,
            <Button onClick={handleViewDetailsClick} disabled={!invoice.invoiceSentHistory ||
                    invoice.invoiceSentHistory.length === 0}>
          View Details
        </Button>,
        ]}/>);
};
export default InvoicePdfItem;
