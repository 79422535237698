import React from 'react';
import { Input as MuiInput, TableCell as MuiTableCell, } from 'src/components/mui';
import styled from 'styled-components';
const Input = styled(MuiInput) `
  height: 40px;
`;
const TableCell = styled(MuiTableCell) `
  height: 40px;
`;
const EditableTableCell = ({ row, name, inputStyle, onChange, children, }) => {
    const { isEditMode } = row;
    return (<TableCell align="left">
      {isEditMode ? (<Input value={row[name]} name={name} onChange={(e) => onChange(e, row)} style={inputStyle}/>) : (children || row[name])}
    </TableCell>);
};
export default EditableTableCell;
