var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import ReactPlacesAutocomplete, { geocodeByAddress, } from 'react-places-autocomplete';
import { isObject } from 'lodash';
import { Autocomplete, Grid, Typography, TextField, Box, } from 'src/components/mui';
import { LocationOn as LocationOnIcon } from 'src/components/mui/icons';
import CONFIG from 'src/config';
import { constructAddressObj } from 'src/utils/address-utils';
import { addressObjectToString, getTimezoneByMapBox, } from 'src/clients/mapboxClient';
import { colors } from 'src/styles/theme';
const DEFAULT_ZOOM = 13;
const DEFAULT_SEARCH_OPTIONS = {
    componentRestrictions: { country: 'us' },
    types: ['address'],
};
const PlacesAutocompleteWrapper = styled.div `
  position: relative;
  width: 100%;
`;
const GoogleMapWrapper = styled.div `
  height: 200px;
  width: 100%;
  margin-bottom: 10px;
`;
const Marker = styled.div `
  position: absolute;
  width: 20px;
  height: 20px;
  top: -10px;
  left: -10px;
  border-radius: 50% 50% 50% 0%;
  border: 4px solid ${colors.autocompleteBorder};
  transform: rotate(-45deg);

  &:after {
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -3px;
    margin-top: -2px;
    background-color: ${colors.autocompleteBorder};
  }
`;
const PlacesAutocomplete = (props) => {
    const [innerAddress, setInnerAddress] = useState('');
    const [addressObj, setAddressObj] = useState(null);
    const handleSelect = (...params) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_1, bubbleEvent = true, suggestion] = params;
        const address = (_a = suggestion === null || suggestion === void 0 ? void 0 : suggestion.description) !== null && _a !== void 0 ? _a : suggestion === null || suggestion === void 0 ? void 0 : suggestion.key;
        if (!address || !address.length)
            return;
        setInnerAddress(address);
        const parsed = yield geocodeByAddress(address);
        const newAddressObj = constructAddressObj(address, parsed);
        newAddressObj.timezone = yield getTimezoneByMapBox(newAddressObj.latitude, newAddressObj.longitude);
        setAddressObj(newAddressObj);
        if (bubbleEvent) {
            props.onSelect(newAddressObj);
        }
    });
    const handleChange = (address) => {
        const addr = address.trimStart();
        setInnerAddress(addr);
        if (!addr || !addr.length) {
            setInnerAddress('');
            props.onSelect(null);
        }
        if (props.onChange) {
            props.onChange(addr);
        }
    };
    const getCenter = () => {
        if (!addressObj) {
            return [33.955968, -118.180686];
        }
        return [addressObj.latitude, addressObj.longitude];
    };
    useEffect(() => {
        let addressString = null;
        if (props.extAddress) {
            addressString = isObject(props.extAddress)
                ? addressObjectToString(props.extAddress)
                : props.extAddress;
        }
        else if (props.address) {
            addressString = isObject(props.address)
                ? addressObjectToString(props.address)
                : props.address;
        }
        if ((addressString && !innerAddress) || innerAddress.length <= 0) {
            handleSelect(undefined, false, { description: addressString !== null && addressString !== void 0 ? addressString : '' }, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.extAddress, props.address]);
    const { displayMap = false, disabled, disabledReason, variant, placeholder, label, autoFocus, inputProps, inputLabelProps, alignItems, classes, suggestionsContainerClassName, iconCellClassName, searchOptions, textFieldParams, } = props;
    const [lat, lng] = getCenter();
    return (<PlacesAutocompleteWrapper>
      {displayMap && (<GoogleMapWrapper>
          <GoogleMapReact key={`${lat}-${lng}`} bootstrapURLKeys={{ key: CONFIG.GOOGLE_PLACES_API_KEY }} defaultCenter={[lat, lng]} defaultZoom={DEFAULT_ZOOM}>
            <Marker lat={lat} lng={lng}/>
          </GoogleMapReact>
        </GoogleMapWrapper>)}
      <ReactPlacesAutocomplete value={innerAddress} onChange={handleChange} onSelect={handleSelect} onError={() => { }} searchOptions={searchOptions !== null && searchOptions !== void 0 ? searchOptions : DEFAULT_SEARCH_OPTIONS} debounce={500}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (<Autocomplete style={{ width: '100%' }} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.description) !== null && _a !== void 0 ? _a : ''; }} filterOptions={(x) => x} inputValue={innerAddress} options={suggestions} openOnFocus autoComplete includeInputInList freeSolo disabled={disabled} clearIcon={null} classes={classes} renderInput={(params) => {
                const expandedParams = Object.assign(Object.assign({}, params), { InputProps: Object.assign(Object.assign({}, params.InputProps), inputProps), InputLabelProps: Object.assign(Object.assign({}, params.InputLabelProps), inputLabelProps) });
                return (<TextField {...expandedParams} label={label !== null && label !== void 0 ? label : 'Add a location'} helperText={disabledReason} autoFocus={autoFocus} fullWidth variant={variant !== null && variant !== void 0 ? variant : 'standard'} {...getInputProps()} disabled={disabled} placeholder={placeholder} {...textFieldParams}/>);
            }} renderOption={(props, suggestion) => {
                var _a, _b;
                const [addressLine, ...restAddress] = (_b = (_a = suggestion.description) === null || _a === void 0 ? void 0 : _a.split(', ')) !== null && _b !== void 0 ? _b : [];
                return (<Box component="li" {...props}>
                  <Grid className={suggestionsContainerClassName} container style={{ alignItems: alignItems !== null && alignItems !== void 0 ? alignItems : 'center' }} {...getSuggestionItemProps(suggestion)}>
                    <Grid item className={iconCellClassName}>
                      <LocationOnIcon />
                    </Grid>
                    <Grid item xs>
                      <span>{addressLine}</span>
                      <Typography variant="body2" color="textSecondary">
                        {restAddress.join(', ')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>);
            }}/>)}
      </ReactPlacesAutocomplete>
    </PlacesAutocompleteWrapper>);
};
export default PlacesAutocomplete;
