import React from 'react';
import pluralize from 'pluralize';
import { JobsList, JobsRoot, JobsTable, StatsContainer, VanContainer,
// eslint-disable-next-line import/extensions
 } from 'src/PopsApp/pages/Job/Job.styles';
import { useMediaQuery } from 'src/components/mui';
import { Checkbox } from 'src/components/SimpleCheckbox/Checkbox';
import { theme } from 'src/styles/theme';
import JobsFooter from 'src/PopsApp/pages/Job/JobsFooter';
import JobsTableRow from 'src/PopsApp/pages/Job/JobTableRow';
import { Helmet } from 'react-helmet';
import StyledTablePagination from 'src/components/StyledTablePagination';
import DeliveryVanAdditionalInfo from 'src/PopsApp/components/Breadcrumbs/DeliveryVanAdditionalInfo/DeliveryVanAdditionalInfo';
import JobItem from './JobItem';
import useJob from './useJob';
const Job = () => {
    var _a;
    const { allChecked, selectedJobs, partsSelectedCount, page, rowsPerPage, jobs, jobsPagination, vanShop, totalComponents, handleCheckAll, handleItemSelected, handleChangePage, handleChangeRowsPerPage, hasDeliveryToHubSkill, repairSmithHubHomeStore, } = useJob();
    const lgAndUp = useMediaQuery(theme.breakpoints.up('md'));
    return !jobs ? null : (<JobsRoot>
      {vanShop && (<>
          <VanContainer>
            <h2>{vanShop.shopName}</h2>
            <span>{vanShop.metroName}</span>
            <DeliveryVanAdditionalInfo hasDeliveryToHubSkill={hasDeliveryToHubSkill} hasRepairSmithHubHomeStore={!!repairSmithHubHomeStore()} address={(_a = repairSmithHubHomeStore()) !== null && _a !== void 0 ? _a : ''}/>
          </VanContainer>
          <Helmet>
            <title>{vanShop.shopName}</title>
          </Helmet>
        </>)}

      {jobsPagination && totalComponents != null && (<StatsContainer>
          <span>
            <span className="statsCount">{jobsPagination.totalElements}</span>{' '}
            <span className="statsLabel">Jobs Scheduled</span>
          </span>
          <span className="partsStats">
            <span className="statsCount">{totalComponents}</span>{' '}
            <span className="statsLabel">
              {pluralize('Part', totalComponents)} Needed
            </span>
          </span>
          {!lgAndUp && (<Checkbox className="checkAll" checked={allChecked} color="primary" onClick={handleCheckAll}/>)}
        </StatsContainer>)}
      {!lgAndUp && (<JobsList>
          {jobs.map((job) => (<JobItem job={job} key={job.referenceNum} selectedJobs={selectedJobs} onItemSelected={handleItemSelected}/>))}
        </JobsList>)}

      {lgAndUp && (<JobsTable>
          <thead>
            <tr>
              <th className="checkBoxCell">
                <Checkbox className="checkAll" checked={allChecked} color="primary" onClick={handleCheckAll}/>
              </th>
              <th>Reference #</th>
              <th>Skedulo Job</th>
              <th>Date</th>
              <th>Repairs</th>
              <th>YMME</th>
              <th className="partsNeededHeader">Parts Needed</th>
            </tr>
          </thead>

          <tbody>
            {jobs.map((job) => (<JobsTableRow job={job} key={job.referenceNum} selectedJobs={selectedJobs} onItemSelected={handleItemSelected}/>))}
          </tbody>
        </JobsTable>)}

      {jobsPagination != null && (<StyledTablePagination paginationData={jobsPagination} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}/>)}

      <JobsFooter selectedJobs={selectedJobs} partsSelectedCount={partsSelectedCount}/>
    </JobsRoot>);
};
export default Job;
