export var DeliveryVanType;
(function (DeliveryVanType) {
    DeliveryVanType["METRIS"] = "Metris";
    DeliveryVanType["METRIS_WITH_OIL"] = "Metris w/ Oil";
    DeliveryVanType["SPRINTER"] = "Sprinter";
    DeliveryVanType["SPRINTER_WITH_OIL"] = "Sprinter w/ Oil";
    DeliveryVanType["SPRINTER_WITH_OIL_AND_TIRES"] = "Sprinter w/ Oil & Tires";
    DeliveryVanType["SPRINTER_144"] = "Sprinter 144";
    DeliveryVanType["EXTENDED_SPRINTER_WITH_OIL_AND_TIRES"] = "Extended Sprinter w/ Oil & Tires";
    DeliveryVanType["BOXVAN"] = "Box Van";
    DeliveryVanType["BOXVAN_WITH_OIL_AND_TIRES"] = "Box Van w/ Oil & Tires";
    DeliveryVanType["FREIGHTLINER"] = "Freightliner";
})(DeliveryVanType || (DeliveryVanType = {}));
export default DeliveryVanType;
