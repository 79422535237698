import React from 'react';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import SimpleTablePagination from 'src/components/dashboard/SimpleTable/SimpleTablePagination';
import { ConfirmationModal } from 'src/components/layout';
import useIsMobile from 'src/hooks/useIsMobile';
import InventorySearchBar from 'src/PopsApp/pages/Inventory/components/InventorySearchBar';
import MoveReturnedItemDrawer from 'src/PopsApp/pages/Inventory/components/MoveReturnedItemDrawer';
import ReturnDrawer from 'src/PopsApp/pages/Inventory/components/ReturnDrawer';
import { NoResults } from 'src/PopsApp/pages/Inventory/Inventory.styles';
import ReturnedItemCard from 'src/PopsApp/pages/Inventory/ReturnedItemCard';
import ReturnedItemRow from 'src/PopsApp/pages/Inventory/ReturnedItemRow';
import useReturnedInventory from 'src/PopsApp/pages/Inventory/useReturnedInventory';
const INVENTORY_COLUMNS = [
    { title: 'Location Type', key: 'location' },
    { title: 'Name', key: 'name' },
    { title: 'Distributor', key: 'distributor' },
    { title: 'Part Details', key: 'partDetails' },
    { title: 'Address', key: 'address' },
    { title: 'Created Date', key: 'created' },
    { title: 'Core Cost', key: 'coreCost' },
    { title: 'Status', key: 'status', textAlign: 'center' },
    { title: '', key: 'actions', textAlign: 'center' },
];
const Inventory = () => {
    var _a;
    const isMobile = useIsMobile();
    const { returnItems, isLoading, isSubmitting, pagination, onPaginationChange, handleDeleteItem, openConfirmDelete, closeDeleteModal, deleteItem, handleReturn, openReturnDrawer, handleReturnDrawerClose, saveReturnedItem, confirmationNumber, setConfirmationNumber, selectedItem, handleMove, openMoveDrawer, handleMoveDrawerClose, statusFilter, setStatusFilter, handleSearch, searchPoNumberFilter, onSearchChange, minPrice, maxPrice, setPrice, handleLocationSelected, locationSearchResults, searchLocations, resetSearchFilters, selectedLocation, setCreatedFrom, createdFrom, setCreatedTo, createdTo, } = useReturnedInventory();
    return (<section className="Inventory">
      <InventorySearchBar statusFilter={statusFilter} setStatusFilter={setStatusFilter} handleSearch={handleSearch} searchPoNumberFilter={searchPoNumberFilter} onSearchChange={onSearchChange} minPrice={minPrice} maxPrice={maxPrice} setPrice={setPrice} handleLocationSelected={handleLocationSelected} locationSearchResults={locationSearchResults} searchLocations={searchLocations} resetSearchFilters={resetSearchFilters} selectedLocation={selectedLocation} setCreatedFrom={setCreatedFrom} createdFrom={createdFrom} setCreatedTo={setCreatedTo} createdTo={createdTo}/>

      {(returnItems === null || returnItems === void 0 ? void 0 : returnItems.length) === 0 && <NoResults>0 Searches Found</NoResults>}

      {returnItems && returnItems.length > 0 && (<span>
          {!isMobile ? (<SimpleTable data={returnItems} style={{ minHeight: 200 }} pagination={pagination} sort={pagination.sort} columns={INVENTORY_COLUMNS} isFetching={isLoading} hideTopPagination renderItem={(item, index) => (<ReturnedItemRow item={item} key={`${index}`} onDeleteItem={handleDeleteItem} onReturn={handleReturn} onMove={handleMove}/>)} onLoadData={(params) => {
                    onPaginationChange(Object.assign({}, params));
                }}/>) : (<>
              <section>
                {returnItems.map((item, index) => (<ReturnedItemCard key={`${index}`} item={item} onDeleteItem={handleDeleteItem} onReturn={handleReturn} onMove={handleMove}/>))}
              </section>
              <SimpleTablePagination pagination={pagination} rowsCount={returnItems.length} onPageChange={(event, newPage) => {
                    var _a;
                    onPaginationChange({
                        page: newPage,
                        size: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                        sort: pagination.sort,
                        query: pagination === null || pagination === void 0 ? void 0 : pagination.query,
                    });
                }} onRowsPerPageChange={(event) => {
                    const size = +event.target.value;
                    onPaginationChange({
                        page: 0,
                        size,
                        sort: pagination.sort,
                        query: pagination.query,
                    });
                }}/>
            </>)}
        </span>)}

      <ConfirmationModal open={openConfirmDelete} text="Are you sure you want to delete this return item?" onClose={closeDeleteModal} action={deleteItem}/>
      <ReturnDrawer open={openReturnDrawer} onClose={handleReturnDrawerClose} saveReturnedItem={saveReturnedItem} confirmationNumber={confirmationNumber} isSubmitting={isSubmitting} setConfirmationNumber={setConfirmationNumber} status={(_a = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.status) !== null && _a !== void 0 ? _a : 'PENDING'}/>
      <MoveReturnedItemDrawer open={openMoveDrawer} onClose={handleMoveDrawerClose} returnedItems={selectedItem ? [selectedItem] : undefined}/>
    </section>);
};
export default Inventory;
