var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable dot-notation */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addPaymentMethod } from 'src/AdminApp/modules/consumers/actions';
import { toast } from 'src/components/SimpleToast';
const usePaymentForm = ({ onFinishedAddPaymentMethod, path, triggerCCSubmit, onFormError, alwaysDefault, consumerId, }) => {
    const dispatch = useDispatch();
    const handleAddPaymentMethod = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!consumerId)
            return null;
        const requiredFields = {};
        // COLLECT THE FORM INFO FOR TOKENIZATION
        if (document !== null) {
            const fullName = document.getElementById('full_name');
            if (fullName) {
                requiredFields['full_name'] = fullName.value;
            }
            const exp = document.getElementById('exp');
            if (exp) {
                const month = exp.value.substring(0, 2);
                const year = exp.value.substring(3);
                requiredFields['month'] = +month;
                requiredFields['year'] = 2000 + +year;
            }
            const makeDefaultPaymentMethod = document.getElementById('makeDefaultPaymentMethod');
            if (makeDefaultPaymentMethod) {
                requiredFields['makeDefaultPaymentMethod'] =
                    makeDefaultPaymentMethod.checked;
            }
        }
        // TOKENIZE FROM SPREEDLY
        const spreedlyReady = Boolean(window.Spreedly);
        let token = '';
        if (spreedlyReady) {
            // FILLS OUT A HIDDEN INPUT 'payment_method_token'
            yield window.Spreedly.tokenizeCreditCard(requiredFields);
            window.setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                if (document) {
                    const tokenEl = document.getElementById('payment_method_token');
                    if (tokenEl) {
                        token = tokenEl.value;
                    }
                }
                if (token) {
                    const addPaymentResponse = yield dispatch(addPaymentMethod({
                        consumerId,
                        defaultMethod: !!requiredFields.makeDefaultPaymentMethod || alwaysDefault,
                        token,
                        active: true,
                        methodType: 'CREDIT_CARD',
                        path,
                    }));
                    if (!addPaymentResponse.error) {
                        toast.success('Your card has been added');
                        onFinishedAddPaymentMethod(addPaymentResponse.payload);
                    }
                    else {
                        const messageEl = document.getElementById('message');
                        messageEl.innerHTML = '';
                        messageEl.style.display = 'inherit';
                        messageEl.innerHTML = addPaymentResponse.payload.response.message;
                        if (onFormError) {
                            onFormError();
                        }
                    }
                }
                else if (onFormError) {
                    onFormError();
                }
            }), 1000);
        }
    });
    useEffect(() => {
        if (triggerCCSubmit) {
            handleAddPaymentMethod();
        }
    }, [triggerCCSubmit]); // eslint-disable-line
    return {
        handleAddPaymentMethod,
    };
};
export default usePaymentForm;
