import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
export const ListWrapper = styled.section `
  max-height: 500px;
  overflow-y: auto;
  margin-top: 44px;

  ${theme.breakpoints.up('md')} {
    padding-right: 21px;
    max-height: 1000px;
  }
`;
export const DaySection = styled.section `
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: ${colors.darkGrey};
  margin-bottom: 57px;

  .date {
    font-weight: bold;
    color: ${colors.GRAY_3F};
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
`;
export const DayLabel = styled.div `
  padding-bottom: 16px;
`;
export const NoSlots = styled.section `
  margin-top: 75px;
  padding-top: 31px;
  border-top: 1px solid ${colors.border};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.11px;
  text-align: center;
  color: ${colors.GRAY_3F};
`;
