import styled from 'styled-components';
import { Typography } from 'src/components/mui';
import CurrencyLabel from 'src/components/CurrencyLabel';
import { colors } from 'src/styles/theme';
export const InvoiceFormWrapper = styled.div `
  position: relative;
  border-top: 1px dashed ${colors.lightGrey};
`;
export const Description = styled.div `
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
export const LabelWrapper = styled.div `
  font-weight: bold;
  display: flex;
  align-items: center;
`;
export const Text = styled(Typography) `
  padding-top: 8px;
`;
export const Total = styled.div `
  display: flex;
  flex-direction: column;
  color: ${colors.darkGrey};
  margin-top: 15px;

  & > div:nth-of-type(odd) {
    background-color: rgba(63, 63, 63, 0.04);
  }
`;
export const PriceField = styled(CurrencyLabel) `
  display: block;
  width: 100%;
  text-align: right;
  flex-grow: 0;
  flex-basis: 50%;
  align-items: center;
`;
export const FieldDivider = styled.div `
  border-top: dashed 1px ${colors.lightGrey};
  margin-top: 15px;
  margin-bottom: 15px;
`;
