var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid } from 'src/components/mui';
import styled from 'styled-components';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { Formik } from 'formik';
import { MuiChipsInput } from 'mui-chips-input';
import moment from 'moment';
import React from 'react';
import TextField from 'src/AdminApp/components/form/TextField';
import { SlidePageContent, SlidePageFieldsContainer, SlidePageFooter, SlidePageFormWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { getRegionsAutocomplete } from 'src/AdminApp/modules/regions/actions';
import { selectRegionsAutocomplete } from 'src/AdminApp/modules/regions/selectors';
import { DestructiveButton } from 'src/components/buttons';
import RegionSearch from 'src/components/form/RegionSearch';
import { SimpleModal } from 'src/components/layout';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import { colors } from 'src/styles/theme';
import { DeliveryVansSelector } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/DeliveryVansSelector';
import { ZipCodesTransfer } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/ZipCodesTransfer';
import useServiceAreaPage from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/pages/ServiceAreaPage/useServiceAreaPage';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
export const ChipInput = styled(MuiChipsInput) `
  .MuiChipsInput-Chip {
    background-color: ${({ model }) => (model ? colors.black : colors.primary)};
    height: 20px;
    span {
      color: ${colors.white};
      font-size: 11px;
      font-weight: 700;
    }
    svg {
      fill: ${colors.white};
      width: 18px;
    }
    ${({ model }) => {
    var _a, _b;
    const zipIds = (_b = (_a = model === null || model === void 0 ? void 0 : model.zipCodes) === null || _a === void 0 ? void 0 : _a.map((item) => item.name)) !== null && _b !== void 0 ? _b : [];
    let result = '';
    zipIds.forEach((id) => {
        result += `
          &[title='${id}'] {
            background-color: ${colors.primary};
          }
        `;
    });
    return result;
}}
  }
`;
const ServiceAreaPage = ({ model, onRemove, disableSave, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { validationSchema, handleSubmit, handleZipCodeBeforeAdd, getZipCodeAdd, removeZipCodeFromArray, zipCodesInUse, transferServiceAreaZipCode, removeServiceAreaZipCode, serviceAreasDialog, setServiceAreasDialog, closeHandler, onUpdateDeliveryVanServiceAreas, refreshRegions, } = useServiceAreaPage({ model });
    return (<Formik initialValues={{
            id: model === null || model === void 0 ? void 0 : model.id,
            name: (_a = model === null || model === void 0 ? void 0 : model.name) !== null && _a !== void 0 ? _a : '',
            region: (_b = model === null || model === void 0 ? void 0 : model.region) !== null && _b !== void 0 ? _b : '',
            zipCodes: (_d = (_c = model === null || model === void 0 ? void 0 : model.zipCodes) === null || _c === void 0 ? void 0 : _c.filter((item) => !item.b2bExclusive).map((item) => item.name)) !== null && _d !== void 0 ? _d : [],
            b2bZipCodes: (_f = (_e = model === null || model === void 0 ? void 0 : model.zipCodes) === null || _e === void 0 ? void 0 : _e.filter((item) => item.b2bExclusive).map((item) => item.name)) !== null && _f !== void 0 ? _f : [],
            deliveryVanServiceAreas: (_g = model === null || model === void 0 ? void 0 : model.deliveryVanServiceAreas) !== null && _g !== void 0 ? _g : [],
            marketingLaunchDate: (_h = model === null || model === void 0 ? void 0 : model.marketingLaunchDate) !== null && _h !== void 0 ? _h : null,
            bookingEffectiveDate: (_j = model === null || model === void 0 ? void 0 : model.bookingEffectiveDate) !== null && _j !== void 0 ? _j : null,
        }} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, isSubmitting, isValid, setFieldValue }) => {
            var _a, _b, _c;
            return (<>
          <SlidePageFormWrapper>
            <SlidePageContent>
              <SlidePageFieldsContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField fullWidth name="name" label="Service Area Name" type="text" variant="outlined"/>
                  </Grid>
                  <Grid item xs={12}>
                    <RegionSearch value={values.region} name="region" label="Region" onUpdate={(region) => {
                    if (region) {
                        setFieldValue('region', region);
                    }
                }} getRegions={getRegionsAutocomplete} regionsSelector={selectRegionsAutocomplete}/>
                  </Grid>
                </Grid>
              </SlidePageFieldsContainer>
              <SlidePageFieldsContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ChipInput fullWidth label="B2C & B2B Zip Codes" variant="outlined" value={values.zipCodes} validate={(value) => handleZipCodeBeforeAdd(value)} onAddChip={(value) => {
                    const zipCodes = getZipCodeAdd(value);
                    setFieldValue('zipCodes', [
                        ...values.zipCodes,
                        ...zipCodes,
                    ]);
                    setFieldValue('b2bZipCodes', removeZipCodeFromArray(values.b2bZipCodes, zipCodes));
                }} onDeleteChip={(value, index) => {
                    const zipCodes = [...values.zipCodes];
                    zipCodes.splice(index, 1);
                    setFieldValue('zipCodes', zipCodes);
                }} model={model}/>
                  </Grid>
                </Grid>
              </SlidePageFieldsContainer>
              <SlidePageFieldsContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ChipInput fullWidth label="B2B Exclusive Zip Codes" variant="outlined" value={values.b2bZipCodes} validate={(value) => handleZipCodeBeforeAdd(value)} onAddChip={(value) => {
                    const zipCodes = getZipCodeAdd(value);
                    setFieldValue('b2bZipCodes', [
                        ...values.b2bZipCodes,
                        ...zipCodes,
                    ]);
                    setFieldValue('zipCodes', removeZipCodeFromArray(values.zipCodes, zipCodes));
                }} onDeleteChip={(value, index) => {
                    const zipCodes = [...values.b2bZipCodes];
                    zipCodes.splice(index, 1);
                    setFieldValue('b2bZipCodes', zipCodes);
                }} model={model}/>
                  </Grid>
                </Grid>
              </SlidePageFieldsContainer>
              {zipCodesInUse && zipCodesInUse.length > 0 && (<ZipCodesTransfer zipCodes={zipCodesInUse} onTransfer={(zipCode) => __awaiter(void 0, void 0, void 0, function* () {
                        yield transferServiceAreaZipCode(zipCode.name, values.id);
                        yield removeServiceAreaZipCode(zipCode.name);
                    })} onRemove={(zipCodeName) => __awaiter(void 0, void 0, void 0, function* () {
                        yield removeServiceAreaZipCode(zipCodeName);
                        const zipCodes = [...values.zipCodes];
                        const index = zipCodes.findIndex((x) => x === zipCodeName);
                        zipCodes.splice(index, 1);
                        setFieldValue('zipCodes', zipCodes);
                    })} message="The following zip codes are already in use in a different service area. Omit or transfer zip codes to this service area."/>)}
              <SlidePageFieldsContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SimpleDatePicker fullWidth name="bookingEffectiveDate" label="Booking Effective Date (Optional)" format={LOCALIZED_DATE_FORMAT} minDate={values.marketingLaunchDate
                    ? moment(values.marketingLaunchDate)
                    : undefined} value={values.bookingEffectiveDate
                    ? moment(values.bookingEffectiveDate)
                    : null} onChange={(value) => {
                    setFieldValue('bookingEffectiveDate', value);
                }} slotProps={{
                    textField: {
                        variant: 'outlined',
                        helperText: 'Must be greater than or equal to Marketing Launch date',
                    },
                    field: {
                        clearable: true,
                    },
                }}/>
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleDatePicker fullWidth name="marketingLaunchDate" label="Marketing Launch Date (Optional)" format={LOCALIZED_DATE_FORMAT} maxDate={values.bookingEffectiveDate
                    ? moment(values.bookingEffectiveDate)
                    : undefined} value={values.marketingLaunchDate
                    ? moment(values.marketingLaunchDate)
                    : null} onChange={(value) => {
                    setFieldValue('marketingLaunchDate', value);
                }} slotProps={{
                    textField: {
                        variant: 'outlined',
                        helperText: 'Must be greater than or equal to Marketing Launch date',
                    },
                    field: {
                        clearable: true,
                    },
                }}/>
                  </Grid>
                </Grid>
              </SlidePageFieldsContainer>
              <DeliveryVansSelector selectedVans={values.deliveryVanServiceAreas} onUpdate={(deliveryVans) => setFieldValue('deliveryVanServiceAreas', deliveryVans)}/>
              {!(model === null || model === void 0 ? void 0 : model.archiveDate) ||
                    new Date(model.archiveDate) > new Date() ? (<Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DestructiveButton onClick={() => {
                        if (onRemove && model) {
                            onRemove(model);
                        }
                    }}>
                      Archive Service Area
                    </DestructiveButton>
                  </Grid>
                </Grid>) : null}
            </SlidePageContent>
            <SlidePageFooter>
              <FooterActionButton type="submit" disabled={isSubmitting ||
                    !isValid ||
                    disableSave ||
                    ((_a = values === null || values === void 0 ? void 0 : values.deliveryVanServiceAreas) !== null && _a !== void 0 ? _a : []).some((item) => !(item === null || item === void 0 ? void 0 : item.effectiveFrom) ||
                        (item === null || item === void 0 ? void 0 : item.effectiveFrom.toLowerCase()) === 'invalid date')}>
                Save Service Area
              </FooterActionButton>
            </SlidePageFooter>
          </SlidePageFormWrapper>
          <SimpleModal title={`Do you want all of the vans [${(_c = (_b = serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.model) === null || _b === void 0 ? void 0 : _b.deliveryVanServiceAreas.filter((item) => !item.id && !item.alreadyInactive).map((area) => area.van.name)) === null || _c === void 0 ? void 0 : _c.join(', ')}] you added to inherit the
              homestores of the region?`} onClose={() => setServiceAreasDialog(undefined)} open={!!(serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.open)} simpleTwoButtonRowProps={{
                    onCancel: () => {
                        setServiceAreasDialog(undefined);
                        refreshRegions();
                        closeHandler();
                    },
                    submitText: 'Proceed',
                    onSubmit: onUpdateDeliveryVanServiceAreas,
                }}/>
        </>);
        }}
    </Formik>);
};
export default ServiceAreaPage;
