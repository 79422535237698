import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import AddButton from 'src/AdminApp/components/layout/AddButton';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { CatalogManufacturerDrawerMode } from 'src/PricingApp/components/manufacturers/catalog-manufacturers/useCatalogManufacturerDrawer';
import CatalogManufacturerDrawer from 'src/PricingApp/components/manufacturers/catalog-manufacturers/CatalogManufacturerDrawer';
import { searchManufacturers } from 'src/PricingApp/modules/manufacturers/actions';
import { selectManufacturers } from 'src/PricingApp/modules/manufacturers/selectors';
import { selectDashboardContent, selectDashboardPagination, selectDashboardParams, selectDashboardFetching, } from 'src/PricingApp/modules/utils/selectors';
import ManufacturersSearch from './ManufacturersSearch';
import ManufacturerListItem from './ManufacturerListItem';
const FlexEndButton = styled.div `
  display: flex;
  justify-content: flex-end;
  margin: 10px;
`;
const REQUEST_COLUMNS = [
    { title: 'Manufacturer Name', key: 'name', sortable: true },
    { title: 'Prioritization', key: 'status', sortable: true },
    { title: 'Catalog', key: 'catalog', sortable: false },
    { title: 'Priority', key: 'priority', sortable: false },
    { title: '', key: 'add-manufacturer-catalog', sortable: false },
];
const ManufacturersDashboard = () => {
    const dispatch = useDispatch();
    const manufacturers = useSelector(selectDashboardContent(selectManufacturers));
    const pagination = useSelector(selectDashboardPagination(selectManufacturers));
    const params = useSelector(selectDashboardParams(selectManufacturers));
    const isFetching = useSelector(selectDashboardFetching(selectManufacturers));
    const onLoadData = ({ page, size, sort }) => dispatch(searchManufacturers(Object.assign(Object.assign({}, params), { pagination: {
            size,
            page,
            sort,
        } })));
    useEffect(() => {
        var _a, _b, _c;
        onLoadData({
            page: (_a = pagination.pageNumber) !== null && _a !== void 0 ? _a : 0,
            size: (_b = pagination.pageSize) !== null && _b !== void 0 ? _b : 20,
            sort: (_c = pagination.sort) !== null && _c !== void 0 ? _c : ['id,asc'],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSearch = (filters) => {
        dispatch(searchManufacturers({
            filters,
            pagination: {
                size: pagination.size,
                page: 0,
                sort: pagination.sort,
            },
        }));
    };
    const [catalogManufacturerDrawerMode, setCatalogManufacturerDrawerMode] = useState(CatalogManufacturerDrawerMode.CLOSED);
    const [editableManufacturer, setEditableManufacturer] = useState();
    const [editableCatalogManufacturer, setEditableCatalogManufacturer] = useState(null);
    const handleAddManufacturer = () => {
        setEditableCatalogManufacturer(null);
        setEditableManufacturer({ name: '' });
        setCatalogManufacturerDrawerMode(CatalogManufacturerDrawerMode.ADD_MANUFACTURER);
    };
    return (<>
      <FlexEndButton>
        <AddButton text="Add New Manufacturer" color="primary" onClick={handleAddManufacturer}/>
      </FlexEndButton>
      <ManufacturersSearch filters={params.filters} onSearch={onSearch}/>
      {manufacturers && (<>
          <SimpleTable data={manufacturers} pagination={pagination} sort={pagination.sort} isFetching={isFetching} columns={REQUEST_COLUMNS} onLoadData={onLoadData} onRowsPerPageChange={() => {
                // This is intentional
            }} renderItem={(manufacturer) => (<ManufacturerListItem manufacturer={manufacturer} setEditableManufacturer={setEditableManufacturer} setEditableCatalogManufacturer={setEditableCatalogManufacturer} setCatalogManufacturerDrawerMode={setCatalogManufacturerDrawerMode} key={manufacturer.id}/>)}/>
          <CatalogManufacturerDrawer catalogManufacturerDrawerMode={catalogManufacturerDrawerMode} onClose={() => setCatalogManufacturerDrawerMode(CatalogManufacturerDrawerMode.CLOSED)} manufacturer={editableManufacturer} catalogManufacturer={editableCatalogManufacturer}/>
        </>)}
    </>);
};
export default ManufacturersDashboard;
