var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearLaborRates } from 'src/AdminApp/modules/geoManagement/actions';
import { selectLaborRates, selectRegionHasArchivedLaborRates, selectRegionHasLaborRates, selectRegionLaborRates, } from 'src/AdminApp/modules/geoManagement/selectors';
import { getManagers, getPorters, loadCurrentUser, } from 'src/AdminApp/modules/users/actions';
import { selectCurrentUserIsEngineering, selectCurrentUserIsProduct, selectManagers, selectPorters, } from 'src/AdminApp/modules/users/selectors';
import { selectAuthorizedGeoLaborRateEdit, selectAuthorizedGeoMgmtEdit, selectAuthorizedGeoMgmtView, } from 'src/modules/auth/selectors';
import { clearLocationsHubs, getLocationsHubs, } from 'src/SystemApp/modules/locations/actions';
import { selectLocationHubs } from 'src/SystemApp/modules/locations/selectors';
const useRegionPage = ({ model, onRemove, onSubmit }) => {
    const dispatch = useDispatch();
    const laborRates = useSelector(selectLaborRates);
    const hasRegionLaborRates = useSelector(selectRegionHasLaborRates);
    const regionLaborRates = useSelector(selectRegionLaborRates);
    const hasArchivedLaborRates = useSelector(selectRegionHasArchivedLaborRates);
    const laborRateEditor = useSelector(selectAuthorizedGeoLaborRateEdit);
    const geoEditor = useSelector(selectAuthorizedGeoMgmtEdit);
    const geoViewer = useSelector(selectAuthorizedGeoMgmtView);
    const managers = useSelector(selectManagers);
    const porters = useSelector(selectPorters);
    const isEngineering = useSelector(selectCurrentUserIsEngineering);
    const isProduct = useSelector(selectCurrentUserIsProduct);
    const hubs = useSelector(selectLocationHubs);
    const [createNewSkeduloRegion, setCreateNewSkeduloRegion] = useState(false);
    const [openHomeStoresDrawer, setOpenHomeStoresDrawer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            setIsLoading(true);
            dispatch(loadCurrentUser());
            if (!managers || managers.length === 0) {
                dispatch(getManagers());
            }
            if (!porters || porters.length === 0) {
                dispatch(getPorters());
            }
            if (model === null || model === void 0 ? void 0 : model.id) {
                yield dispatch(getLocationsHubs({ parentId: model.id }));
            }
            else {
                dispatch(clearLocationsHubs());
            }
            setIsLoading(false);
        }))();
        return () => {
            dispatch(clearLaborRates());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onClickArchiveRegion = () => {
        if (model) {
            onRemove(model);
        }
    };
    const handleSubmit = (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const submitParams = {
            name: values === null || values === void 0 ? void 0 : values.name,
            metro: values === null || values === void 0 ? void 0 : values.metro,
            serviceAreaIds: ((values === null || values === void 0 ? void 0 : values.addedServiceAreas) || []).map((x) => x.id),
            laborRates: laborRates.map((_a) => {
                var { id } = _a, rest = __rest(_a, ["id"]);
                return (Object.assign(Object.assign({}, rest), (!(id === null || id === void 0 ? void 0 : id.startsWith('tempLaborRate_')) && { id })));
            }),
            opsManagerId: values === null || values === void 0 ? void 0 : values.opsManagerId,
            porterId: values === null || values === void 0 ? void 0 : values.porterId,
            futureAppointmentMaxCapacity: (values === null || values === void 0 ? void 0 : values.futureAppointmentMaxCapacity) || 0,
            futureAppointmentMinDays: (values === null || values === void 0 ? void 0 : values.futureAppointmentMinDays) || 0,
            skynetDisabledForB2C: values.skynetDisabledForB2C,
            skynetDisabledForB2B: values.skynetDisabledForB2B,
            hubs: values.hubs,
        };
        if (createNewSkeduloRegion) {
            yield onSubmit(Object.assign(Object.assign({}, submitParams), { skeduloRegionTimezone: values === null || values === void 0 ? void 0 : values.skeduloRegionTimezone, skeduloRegionDescription: values === null || values === void 0 ? void 0 : values.skeduloRegionDescription }), hubs);
        }
        else {
            yield onSubmit(Object.assign(Object.assign({}, submitParams), { id: model === null || model === void 0 ? void 0 : model.id, skeduloRegionTimezone: model === null || model === void 0 ? void 0 : model.skeduloRegionTimezone, skeduloRegionId: (_a = values.skeduloRegion) === null || _a === void 0 ? void 0 : _a.uid }), hubs);
        }
        setSubmitting(false);
    });
    return {
        geoEditor,
        geoViewer,
        laborRateEditor,
        laborRates,
        hasRegionLaborRates,
        createNewSkeduloRegion,
        setCreateNewSkeduloRegion,
        managers,
        regionLaborRates,
        isProduct,
        isEngineering,
        hasArchivedLaborRates,
        onClickArchiveRegion,
        setOpenHomeStoresDrawer,
        openHomeStoresDrawer,
        handleSubmit,
        hubs,
        isLoading,
        porters,
    };
};
export default useRegionPage;
