import { clearShift, clearShiftWindow, getShift, saveShift, setEditedShiftWindow, setShift, updateShift, } from 'src/AdminApp/modules/shifts/actions';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'src/components/SimpleToast';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditedShiftWindow, selectShift, } from 'src/AdminApp/modules/shifts/selectors';
import { getMomentValueFromString, getTimeString } from 'src/utils/formatter';
import { TIME_12H_FORMAT } from 'src/timeConstants';
const useEditShiftDrawer = ({ shiftId, onClose, open, }) => {
    const dispatch = useDispatch();
    const [allowEdit, setAllowEdit] = useState(true);
    const [isValid, setValid] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const shift = useSelector(selectShift);
    const editedShiftWindow = useSelector(selectEditedShiftWindow);
    const handleClose = (refreshDashboard = false) => {
        dispatch(clearShift());
        onClose(refreshDashboard);
    };
    const updateShiftWindowSet = (shiftWindowSet, shiftWindow) => shiftWindowSet === null || shiftWindowSet === void 0 ? void 0 : shiftWindowSet.map((item) => {
        const shouldUpdate = (item.id && item.id === shiftWindow.id) ||
            (item.fictionalId && item.fictionalId === shiftWindow.fictionalId);
        if (shouldUpdate) {
            return Object.assign(Object.assign(Object.assign({}, item), (shiftWindow.fictionalId
                ? { fictionalId: shiftWindow.fictionalId }
                : { id: shiftWindow.id })), { windowStart: shiftWindow.windowStart, windowEnd: shiftWindow.windowEnd });
        }
        return item;
    });
    const editShiftWindow = (shiftWindow) => {
        const { shiftWindowSet } = shift;
        if (shiftWindowHasOverlap(shiftWindow, true)) {
            toast.error('Cannot create an overlap');
            return shiftWindowSet;
        }
        return updateShiftWindowSet(shiftWindowSet, shiftWindow);
    };
    const handleWindowSave = (row) => {
        dispatch(setShift(Object.assign(Object.assign({}, shift), { shiftWindowSet: editShiftWindow(row) })));
    };
    const handleShiftChange = (field, value) => {
        switch (field) {
            case 'name': {
                dispatch(setShift(Object.assign(Object.assign({}, shift), { name: value })));
                break;
            }
            case 'status': {
                dispatch(setShift(Object.assign(Object.assign({}, shift), { status: value })));
                break;
            }
            case 'isDefault': {
                dispatch(setShift(Object.assign(Object.assign({}, shift), { isDefault: value })));
                break;
            }
            default: {
                break;
            }
        }
    };
    const handleWindowRemove = (row) => {
        const { shiftWindowSet } = shift;
        dispatch(setShift(Object.assign(Object.assign({}, shift), { shiftWindowSet: shiftWindowSet.filter((r) => r.id !== row.id || r.fictionalId !== row.fictionalId) })));
    };
    const addNewShiftWindow = (shiftWindow) => {
        const { shiftWindowSet } = shift;
        if (shiftWindowHasOverlap(shiftWindow)) {
            toast.error('Cannot create an overlap');
            return shiftWindowSet;
        }
        shiftWindowSet.push({
            fictionalId: uuidv4(),
            windowStart: getTimeString(shiftWindow.windowStart, TIME_12H_FORMAT),
            windowEnd: getTimeString(shiftWindow.windowEnd, TIME_12H_FORMAT),
        });
        return shiftWindowSet;
    };
    const handleAddClick = () => {
        dispatch(setShift(Object.assign(Object.assign({}, shift), { shiftWindowSet: addNewShiftWindow(editedShiftWindow) })));
        dispatch(clearShiftWindow());
    };
    const handleEditShiftWindowStartChange = (value) => {
        dispatch(setEditedShiftWindow(Object.assign(Object.assign({}, editedShiftWindow), { windowStart: getTimeString(value, TIME_12H_FORMAT) })));
    };
    const handleEditShiftWindowEndChange = (value) => {
        dispatch(setEditedShiftWindow(Object.assign(Object.assign({}, editedShiftWindow), { windowEnd: getTimeString(value, TIME_12H_FORMAT) })));
    };
    const handleSubmit = () => {
        setIsSubmitting(true);
        if (!allowEdit) {
            dispatch(updateShift({ shift })).then((response) => {
                if (response && !response.error) {
                    toast.success('Shift successfully updated.');
                    handleClose(true);
                }
                setIsSubmitting(false);
            });
        }
        else {
            dispatch(saveShift({ shift })).then((response) => {
                if (response && !response.error) {
                    toast.success('Shift successfully saved.');
                    handleClose(true);
                }
                setIsSubmitting(false);
            });
        }
    };
    useEffect(() => {
        if (shiftId && open) {
            dispatch(getShift(shiftId));
            setAllowEdit(false);
        }
        else {
            dispatch(clearShift());
            setAllowEdit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shiftId, open]);
    useEffect(() => {
        const isValid = !isEmpty(shift.name) &&
            !isEmpty(shift.status) &&
            shift.isDefault !== undefined &&
            !isEmpty(`${shift.isDefault}`) &&
            !isEmpty(shift === null || shift === void 0 ? void 0 : shift.shiftWindowSet);
        setValid(isValid);
    }, [shift]);
    const shiftWindowHasOverlap = (newShiftWindow, removeShiftWindow = false) => {
        const { shiftWindowSet } = shift;
        const tempArray = removeShiftWindow
            ? shiftWindowSet.filter((item) => item.id !== newShiftWindow.id ||
                item.fictionalId !== newShiftWindow.fictionalId)
            : shiftWindowSet;
        const newWindowStart = getMomentValueFromString(newShiftWindow.windowStart, TIME_12H_FORMAT);
        const newWindowEnd = getMomentValueFromString(newShiftWindow.windowEnd, TIME_12H_FORMAT);
        if (newWindowStart === null || newWindowStart === void 0 ? void 0 : newWindowStart.isSameOrAfter(newWindowEnd)) {
            return true;
        }
        if (newWindowStart.format(TIME_12H_FORMAT) ===
            newWindowEnd.format(TIME_12H_FORMAT)) {
            return true;
        }
        const hasOverlap = tempArray.some((shiftWindow) => {
            const currentWindowStart = getMomentValueFromString(shiftWindow.windowStart, TIME_12H_FORMAT);
            const currentWindowEnd = getMomentValueFromString(shiftWindow.windowEnd, TIME_12H_FORMAT);
            return ((newWindowStart === null || newWindowStart === void 0 ? void 0 : newWindowStart.isBetween(currentWindowStart, currentWindowEnd)) ||
                (newWindowEnd === null || newWindowEnd === void 0 ? void 0 : newWindowEnd.isBetween(currentWindowStart, currentWindowEnd)) ||
                currentWindowStart.isBetween(newWindowStart, newWindowEnd) ||
                currentWindowEnd.isBetween(newWindowStart, newWindowEnd) ||
                newWindowStart.isSame(currentWindowStart) ||
                newWindowEnd.isSame(currentWindowEnd));
        });
        return hasOverlap;
    };
    return {
        handleClose,
        shift,
        allowEdit,
        handleShiftChange,
        isValid,
        isSubmitting,
        handleSubmit,
        handleWindowSave,
        handleWindowRemove,
        handleAddClick,
        editedShiftWindow,
        handleEditShiftWindowEndChange,
        handleEditShiftWindowStartChange,
        shiftWindowHasOverlap,
    };
};
export default useEditShiftDrawer;
