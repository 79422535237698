import React from 'react';
import styled from 'styled-components';
import { Grid, Divider, Typography, Button } from 'src/components/mui';
import { AddCircle, DeleteForever, Error as ErrorIcon, CachedRounded, } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import ImagesModal from 'src/AdminApp/containers/requests/RecommendedRepairs/ImagesModal';
import { RemoveRepairButton, SubItem, } from 'src/AdminApp/components/repairs/statement/styledComponents';
import { Fluid, Labor, Part, } from 'src/AdminApp/components/repairs/statement/Elements';
import { RepairDropdown, RecommendedRepairDropdownButtons, } from 'src/AdminApp/components/repairs/components/RepairDropdown';
import InitialPrice from 'src/AdminApp/components/repairs/statement/InitialPrice';
import AuditInfo from 'src/AdminApp/components/users/AuditInfo';
import { TextButton } from '../styledComponents';
import useRecommendedRepairItem from './useRecommendedRepairItem';
const FooterItem = styled.div `
  color: ${colors.darkGrayish};
  margin-bottom: 24px;
`;
const SymptomList = styled.ul `
  margin: 0 0 24px 0;
  padding: 0 16px;
`;
const RecommendedRepairItem = ({ onRejectRecommendedRepair, onAddComponentToRecommendedRepair, onEditComponentToRecommendedRepair, onUndoRejectedRecommendedRepair, onSelectRepair, isSelected = false, repair, canEdit, canEditLabor = false, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { displayImages, setDisplayImages, isRecommended, isRejected, isAddedToFutureRequest, getRejectionReason, showMeasurements, canEditComponent, imageUrls, getStatusSuggestedRepair, getSeverity, handleOpenMeasurements, sortedPartPrices, sortedFluidPrices, } = useRecommendedRepairItem({ repair });
    const suggestedRepairStatus = getStatusSuggestedRepair(repair);
    const renderQuoted = (repairItem) => {
        return <InitialPrice price={repairItem}/>;
    };
    const { reason, otherReason } = getRejectionReason(repair);
    const { severityColor, severityText } = getSeverity((_a = repair === null || repair === void 0 ? void 0 : repair.inspectionDetails) === null || _a === void 0 ? void 0 : _a.severity);
    return (<RepairDropdown name={repair.repair} quoted={renderQuoted(repair)} totalCost={repair.totalCost} shouldDisplayAuditChip={false} isCreatedByCustomer={false} isRecommendedRepair={isRecommended} isRejectedRecommendedRepair={isRejected} isScheduledRecommendedRepair={!!isAddedToFutureRequest} suggestedRepairStatus={suggestedRepairStatus} isRecommendedRepairSelected={isSelected} onSelectRecommendedRepair={onSelectRepair} recommendedRepairId={repair.id} recommendedRepairScheduledRefNumber={repair.scheduledRepairRequestReferenceNum} created={repair.created} createdBy={repair.createdBy} modified={repair.modified} modifiedBy={repair.modifiedBy} tags={repair === null || repair === void 0 ? void 0 : repair.tags}>
      <>
        {sortedPartPrices === null || sortedPartPrices === void 0 ? void 0 : sortedPartPrices.map((part, index) => {
            var _a;
            return (<SubItem key={part.name}>
            <Part onEdit={(part) => {
                    if (onEditComponentToRecommendedRepair) {
                        onEditComponentToRecommendedRepair('part', repair, part);
                    }
                }} key={index} part={Object.assign(Object.assign({}, part), { totalPrice: (_a = (part.price && part.quantity * part.price)) !== null && _a !== void 0 ? _a : 0 })} canEdit={canEditComponent && !(repair === null || repair === void 0 ? void 0 : repair.scheduledRepairRequestReferenceNum)}/>
          </SubItem>);
        })}
        {sortedFluidPrices === null || sortedFluidPrices === void 0 ? void 0 : sortedFluidPrices.map((fluid, index) => (<SubItem key={fluid.name}>
            <Fluid onEdit={(fluid) => {
                if (onEditComponentToRecommendedRepair) {
                    onEditComponentToRecommendedRepair('fluid', repair, fluid);
                }
            }} key={index} fluid={fluid} canEdit={canEditComponent && !(repair === null || repair === void 0 ? void 0 : repair.scheduledRepairRequestReferenceNum)}/>
          </SubItem>))}
      </>
      <SubItem>
        <Labor canEdit={canEditLabor && !(repair === null || repair === void 0 ? void 0 : repair.scheduledRepairRequestReferenceNum)} price={repair} onEdit={() => {
            if (onEditComponentToRecommendedRepair) {
                onEditComponentToRecommendedRepair('labor', repair);
            }
        }}/>
      </SubItem>
      <RecommendedRepairDropdownButtons container style={{ justifyContent: 'space-between' }} spacing={2}>
        {isRecommended && (<>
            <Grid item style={{
                paddingLeft: '0px',
                display: 'flex',
                flexDirection: 'row',
            }}>
              <Button color="primary" onClick={() => {
                if (onAddComponentToRecommendedRepair) {
                    onAddComponentToRecommendedRepair(repair);
                }
            }} startIcon={<AddCircle />} disabled={!canEdit}>
                Component
              </Button>
              {showMeasurements && (<Button color="primary" onClick={handleOpenMeasurements} startIcon={<AddCircle />} style={{ marginLeft: '10px' }}>
                  Measurements
                </Button>)}
            </Grid>
            <Grid item style={{ textAlign: 'end' }}>
              <RemoveRepairButton onClick={() => {
                if (onRejectRecommendedRepair) {
                    onRejectRecommendedRepair(repair.id);
                }
            }} startIcon={<DeleteForever />} disabled={!canEdit}>
                Reject Repair
              </RemoveRepairButton>
            </Grid>
          </>)}
        {isRejected && (<>
            <Grid item style={{ paddingLeft: '0px' }}>
              <>
                <Typography variant="subtitle1">
                  Reason for Rejection
                </Typography>
                <span>
                  {reason}
                  {otherReason}
                </span>
              </>
            </Grid>
            <Grid item style={{ textAlign: 'end' }}>
              <Button color="primary" onClick={() => {
                if (onUndoRejectedRecommendedRepair) {
                    onUndoRejectedRecommendedRepair(repair.id);
                }
            }} startIcon={<CachedRounded />} disabled={!canEdit}>
                Undo Rejection
              </Button>
            </Grid>
          </>)}
        {isAddedToFutureRequest && showMeasurements && (<Grid item style={{
                paddingLeft: '0px',
                display: 'flex',
                flexDirection: 'row',
            }}>
            <Button color="primary" onClick={handleOpenMeasurements} startIcon={<AddCircle />} style={{ marginLeft: '10px' }}>
              Measurements
            </Button>
          </Grid>)}
      </RecommendedRepairDropdownButtons>
      <br />
      <Typography variant="subtitle1">Reason</Typography>
      <Divider />

      {!!severityText && (<div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: severityColor,
            }}>
          <ErrorIcon />
          <h3> {severityText}</h3>
        </div>)}

      <SymptomList>
        {(_c = (_b = repair.inspectionDetails) === null || _b === void 0 ? void 0 : _b.symptoms) === null || _c === void 0 ? void 0 : _c.map((symptom) => (<li key={symptom}>{symptom}</li>))}
      </SymptomList>
      {((_d = repair === null || repair === void 0 ? void 0 : repair.inspectionDetails) === null || _d === void 0 ? void 0 : _d.additionalInfo) && (<FooterItem style={{ color: colors.darkGrey }}>
          <Typography variant="subtitle1">Notes from the Technician</Typography>
          {(_e = repair.inspectionDetails.additionalInfo) !== null && _e !== void 0 ? _e : 'N/A'}
        </FooterItem>)}
      {imageUrls.length > 0 && (<FooterItem>
          <Typography variant="subtitle1">Images</Typography>
          <TextButton onClick={() => setDisplayImages(true)}>
            View Attached Images ({imageUrls.length})
          </TextButton>
        </FooterItem>)}
      <AuditInfo auditType="ADDED" auditedTimestamp={(_f = repair.created) !== null && _f !== void 0 ? _f : ''} auditedBy={(_g = repair.createdBy) !== null && _g !== void 0 ? _g : ''}/>
      <AuditInfo auditType="MODIFIED" auditedTimestamp={(_h = repair.modified) !== null && _h !== void 0 ? _h : ''} auditedBy={(_j = repair.modifiedBy) !== null && _j !== void 0 ? _j : ''}/>
      <ImagesModal open={displayImages} onClose={() => setDisplayImages(false)} urls={imageUrls}/>
    </RepairDropdown>);
};
export default RecommendedRepairItem;
