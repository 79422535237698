import React from 'react';
import { Grid } from 'src/components/mui';
import DynamicAutocompleteSelector from 'src/AdminApp/components/salesMatch/DynamicAutocompleteSelector';
const DashboardFilters = ({ selectedShopId, selectedRepairRequestState, shops, repairRequestStatuses, selectedRepairRequestStatus, repairRequestStates, onSearch, }) => {
    const onShopIdChanged = (value) => {
        onSearch({ shopId: value });
    };
    const onRepairRequestStatusChanged = (value) => {
        onSearch({ repairRequestStatus: value });
    };
    const onRepairRequestStateChanged = (value) => {
        onSearch({ repairRequestState: value });
    };
    const selectedShop = shops.find((x) => x.id === selectedShopId) ||
        { name: '' };
    const selectedRepairRequestStatusItem = repairRequestStatuses.find((x) => x.id === selectedRepairRequestStatus) ||
        { name: '' };
    const selectedRepairRequestStateItem = repairRequestStates.find((x) => x.id === selectedRepairRequestState) ||
        { name: '' };
    return (<Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <DynamicAutocompleteSelector id="shopId" label="Shop Name" items={shops} onChange={onShopIdChanged} selectedItem={selectedShop}/>
      </Grid>
      <Grid item xs={12} md={3}>
        <DynamicAutocompleteSelector id="repairRequestStatus" label="Request Status" items={repairRequestStatuses} onChange={onRepairRequestStatusChanged} selectedItem={selectedRepairRequestStatusItem}/>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <DynamicAutocompleteSelector id="repairRequestState" label="Request State" items={repairRequestStates} onChange={onRepairRequestStateChanged} selectedItem={selectedRepairRequestStateItem}/>
      </Grid>
    </Grid>);
};
export default DashboardFilters;
