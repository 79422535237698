import styled from 'styled-components';
import { Button } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const VisitCardContainer = styled.div `
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  border-radius: 4px;
  border: solid 1px ${colors.GRAY_DE};
  padding: 20px 20px 64px;
  margin: 0 0 16px;
`;
export const VisitCardRow = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
export const VisitCaption = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
export const VisitItem = styled.div `
  margin: 0 0 8px;
  flex: ${(props) => {
    var _a;
    return (_a = props.flex) !== null && _a !== void 0 ? _a : 1;
}};

  span.VisitMore {
    color: ${colors.GRAY_76};
    white-space: nowrap;
  }
`;
export const Divider = styled.div `
  border-bottom: solid 1px ${colors.GRAY_DE};
  margin: ${({ hastopmargin }) => (hastopmargin ? '32px' : '12px')} 0 20px;
`;
export const ViewButton = styled(Button) `
  color: ${colors.BLUE};
  background-color: ${colors.GRAY_FA};
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
export const VisitItemTitle = styled.div `
  color: ${colors.GRAY_76};
  font-size: 12px;
  font-weight: 500;
`;
