var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export const compareStrings = (s1, s2) => {
    const a = s1 && s1.toUpperCase(); // ignore upper and lowercase
    const b = s2 && s2.toUpperCase(); // ignore upper and lowercase
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};
export const toEditableRow = (object) => {
    return Object.assign(Object.assign({}, object), { isEditMode: false });
};
export const fromEditableRow = (editableObject) => {
    const object = __rest(editableObject, []);
    return object;
};
export const duplicatesFilter = (object, index, self) => {
    return index === self.findIndex((o) => o.UID === object.UID);
};
