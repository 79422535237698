var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { getSkeduloUrl } from 'src/AdminApp/utils/url-utils';
import { CircularProgress, Divider } from 'src/components/mui';
import { CheckCircleRounded as CheckCircleRoundedIcon, ErrorRounded as ErrorRoundedIcon, } from 'src/components/mui/icons';
import { FooterActionButton, SimpleDrawer, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import useIsMobile from 'src/hooks/useIsMobile';
import { setSelectedJobsForOrdering } from 'src/PopsApp/modules/parts/actions';
import { ConfirmationBottom, ConfirmationIcon, ConfirmationWrapper, Job, JobInfoHeader, JobPart, JobsWrapper, OrderCompleteSubtitleText, ProgressWrapper, } from 'src/PopsApp/pages/Order/components/OrderCompletedDrawer/OrderCompletedDrawer.styles';
const getCopy = ({ success, allJobsOrdered, allIntegratedPartsOrdered, }) => {
    let status = 'None';
    if (success && !allIntegratedPartsOrdered) {
        status = 'PartialSuccess';
    }
    else if (success && !allJobsOrdered) {
        status = 'SuccessMoreParts';
    }
    else if (success && allIntegratedPartsOrdered) {
        status = 'Success';
    }
    else if (!success) {
        status = 'Fail';
    }
    const statuses = {
        SuccessMoreParts: {
            title: 'You’ve successfully completed your order!',
            subtitle: 'Your order has been added to the following part pickup job: ',
            bottomText: '',
            buttonText: 'New Order',
        },
        Success: {
            title: 'You’ve successfully completed your order!',
            subtitle: 'Your order has been added to the following part pickup job: ',
            bottomText: 'All parts have been ordered!',
            buttonText: 'New Order',
        },
        Fail: {
            title: 'Something went wrong and your order was not placed.',
            subtitle: 'Select a new Vendor to try again.',
            bottomText: '',
            buttonText: 'Edit Vendor',
        },
        PartialSuccess: {
            title: 'Something went wrong and some parts were not ordered.',
            subtitle: 'The ordered parts were added to the following part pickup job: ',
            buttonText: 'Move to Regular Order',
        },
        None: {},
    };
    return Object.assign(Object.assign({}, statuses[status]), { status });
};
const OrderCompletedDrawerPrioritized = ({ open, onClose, integratedSkeduloId, integratedSkeduloJobName, skeduloId, skeduloJobName, allJobsOrdered, orderSuccess, editVendor, processing, integratedFailedOrderJobs, allIntegratedPartsOrdered, }) => {
    var _a;
    const history = useHistory();
    const { shift } = useParams();
    const dispatch = useDispatch();
    const { title, subtitle, bottomText, buttonText, status } = getCopy({
        success: orderSuccess,
        allJobsOrdered,
        allIntegratedPartsOrdered: allIntegratedPartsOrdered !== null && allIntegratedPartsOrdered !== void 0 ? allIntegratedPartsOrdered : false,
    });
    const handleNext = () => __awaiter(void 0, void 0, void 0, function* () {
        if (orderSuccess) {
            dispatch(setSelectedJobsForOrdering(undefined));
            setTimeout(() => {
                onClose();
                history.push(`/pops/${shift}/jobs`);
            }, 1000);
        }
        else {
            onClose();
            editVendor();
        }
    });
    const handleClose = () => {
        onClose();
    };
    const isMobile = useIsMobile();
    const desktopPartialOrderWithSkeduloId = status === 'PartialSuccess' && isMobile && integratedSkeduloId;
    const getPartPickUpJob = () => {
        let innerSkeduloId = '';
        let innerSkeduloName = '';
        if (status === 'Fail' || (!skeduloId && !integratedSkeduloId)) {
            return <>N/A</>;
        }
        if (skeduloId) {
            innerSkeduloId = skeduloId;
            innerSkeduloName = skeduloJobName;
        }
        else if (integratedSkeduloId) {
            innerSkeduloId = integratedSkeduloId;
            innerSkeduloName = integratedSkeduloJobName;
        }
        return (<a href={getSkeduloUrl(`/job/${innerSkeduloId}/details`)} className="titleSkedulo" target="_blank" rel="noopener noreferrer">
        {innerSkeduloName !== null && innerSkeduloName !== void 0 ? innerSkeduloName : innerSkeduloId}
      </a>);
    };
    return (<SimpleDrawer open={open || processing} onClose={handleClose} size="half" title="Complete Order">
      {processing && (<ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>)}
      {!processing && (<>
          <ConfirmationWrapper>
            {desktopPartialOrderWithSkeduloId ? (<>
                <OrderCompleteSubtitleText style={{ marginTop: 0 }}>
                  The ordered parts were added to the following part pickup job:{' '}
                  <a href={getSkeduloUrl(`/job/${integratedSkeduloId}/details`)} className="titleSkedulo" target="_blank" rel="noopener noreferrer">
                    Skedulo ID:{' '}
                    {(_a = integratedSkeduloJobName !== null && integratedSkeduloJobName !== void 0 ? integratedSkeduloJobName : integratedSkeduloId) !== null && _a !== void 0 ? _a : 'N/A'}
                  </a>
                </OrderCompleteSubtitleText>

                <JobInfoHeader />
              </>) : (<>
                <ConfirmationIcon>
                  {['SuccessMoreParts', 'Success', 'PartialSuccess'].includes(status) ? (<CheckCircleRoundedIcon className="iconSuccess"/>) : (<ErrorRoundedIcon className="iconError"/>)}
                </ConfirmationIcon>
                <h4>{title}</h4>
                <p className="CompletedOrder__partPickup">
                  {subtitle}
                  {getPartPickUpJob()}
                </p>
              </>)}

            {status === 'PartialSuccess' && (<>
                <OrderCompleteSubtitleText>
                  The following integrated parts were not ordered:
                </OrderCompleteSubtitleText>

                <JobsWrapper>
                  {integratedFailedOrderJobs === null || integratedFailedOrderJobs === void 0 ? void 0 : integratedFailedOrderJobs.map((j) => (<Job key={j.jobNumber}>
                        <span className="jobTitle">Job {j.jobNumber}</span>

                        {j.parts.map((p) => (<JobPart key={p.partName}>
                            {p} <ErrorRoundedIcon className="iconError"/>
                          </JobPart>))}
                      </Job>))}
                </JobsWrapper>

                <OrderCompleteSubtitleText>
                  Please order the remaining parts on the Vendor site and
                  complete a Regular Order
                </OrderCompleteSubtitleText>
              </>)}
          </ConfirmationWrapper>
          {bottomText && (<>
              <Divider />
              <ConfirmationBottom>{bottomText}</ConfirmationBottom>
            </>)}
        </>)}
      <SimpleDrawerFooter>
        {!processing && orderSuccess && !allJobsOrdered && (<FooterActionButton onClick={handleClose}>Resume</FooterActionButton>)}
        {processing ? null : (<FooterActionButton onClick={handleNext}>
            {buttonText}
          </FooterActionButton>)}
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
export default OrderCompletedDrawerPrioritized;
