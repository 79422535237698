import React, { useState } from 'react';
import { Checkbox } from 'src/components/SimpleCheckbox';
import { FilterItem, FilterValue } from './FilterItem.styles';
export const Value = ({ title, checked, onClick, disabled }) => {
    return (<FilterValue isChecked={checked} onClick={onClick}>
      <span>{title}</span>
      <Checkbox checked={checked} disabled={disabled}/>
    </FilterValue>);
};
const Item = ({ children, title, onShowAll }) => {
    const [isExpanded, setExpanded] = useState(false);
    const updateExpanded = (state) => () => {
        setExpanded(state);
    };
    return (<FilterItem isExpanded={isExpanded}>
      <div tabIndex={-1} className="itemHeader" role="button" onKeyDown={() => { }} onClick={updateExpanded(!isExpanded)}>
        <p className="itemTitle">{title}</p>
        <div className="itemCollapseWrap">
          <button className="showingAll" type="button" onClick={(e) => {
            e.stopPropagation();
            onShowAll();
        }}>
            Show All
          </button>
          <div className="itemCollapseIcon"/>
        </div>
      </div>
      <div className="itemCollapsible">{children}</div>
    </FilterItem>);
};
export default Item;
