import { IconButton } from 'src/components/mui';
import React from 'react';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
import { CreateRounded as CreateRoundedIcon, ArchiveRounded as ArchiveRoundedIcon, } from 'src/components/mui/icons';
import { HubName } from 'src/SystemApp/pages/hubs/Hubs.styles';
const HubItem = ({ hub, onEditHub, onArchiveHub }) => (<SimpleTableRow data={[
        <HubName archived={hub.archived.toString()}>{hub.name}</HubName>,
        hub.locationAddress.fullAddress,
        hub.archived ? 'INACTIVE' : 'ACTIVE',
        <div style={{ textAlign: 'center' }}>
        {hub.acceptCourierDeliveries ? 'TRUE' : 'FALSE'}
      </div>,
        <div style={{ textAlign: 'center' }}>
        {hub.acceptDistributorDeliveries ? 'TRUE' : 'FALSE'}
      </div>,
        <IconButton disabled={hub.archived} onClick={() => {
                onEditHub(hub);
            }}>
        <CreateRoundedIcon />
      </IconButton>,
        <IconButton disabled={hub.archived} onClick={() => {
                onArchiveHub(hub.id);
            }} title="Archive">
        <ArchiveRoundedIcon />
      </IconButton>,
    ]}/>);
export default HubItem;
