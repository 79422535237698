import React from 'react';
import { Tooltip } from 'src/components/tooltips';
import { LockIconStyle, LockText, LockTitle, TooltipContainer, } from 'src/FleetVisitsApp/pages/VisitView/RepairsTooltip/RepairsTooltip.styles';
import { LockIcon } from 'src/components/icons/LockIcon';
import useAdminUser from 'src/hooks/useAdminUser';
const VisitLockTooltip = ({ visitData }) => {
    if (!(visitData === null || visitData === void 0 ? void 0 : visitData.locked))
        return null;
    const createdByName = visitData.lockedBy.indexOf('|') > -1
        ? useAdminUser({ user: visitData.lockedBy })
        : visitData.lockedBy;
    return (<Tooltip title={<TooltipContainer>
          <LockTitle>Unavailable for self-scheduling</LockTitle>
          <LockText>
            Locked by: {createdByName} at {visitData.lockedAtDate}
          </LockText>
        </TooltipContainer>}>
      <LockIconStyle>
        <LockIcon />
      </LockIconStyle>
    </Tooltip>);
};
export default VisitLockTooltip;
