import { useState, useEffect } from 'react';
const useFleetResponsiblePartyDropdown = ({ responsibleParty, onVehiclesUpdate, onVehicleSearchOpen, }) => {
    const [opened, setOpened] = useState(true);
    const [vehiclesOpened, setVehiclesOpened] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [vehiclesSearchOpened, setVehiclesSearchOpened] = useState(false);
    const onAddVehicles = (vehicles) => {
        onVehiclesUpdate(responsibleParty.id, [
            ...responsibleParty.cars,
            ...vehicles,
        ]);
    };
    const onRemoveVehicle = (vehicle) => {
        var _a;
        const vehicles = (_a = responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.cars) === null || _a === void 0 ? void 0 : _a.filter((v) => v.id !== vehicle.id);
        onVehiclesUpdate(responsibleParty.id, vehicles);
    };
    useEffect(() => {
        onVehicleSearchOpen(vehiclesSearchOpened);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehiclesSearchOpened]);
    return {
        opened,
        vehiclesOpened,
        vehiclesSearchOpened,
        showAll,
        setOpened,
        setVehiclesOpened,
        setShowAll,
        setVehiclesSearchOpened,
        onAddVehicles,
        onRemoveVehicle,
    };
};
export default useFleetResponsiblePartyDropdown;
