import React from 'react';
import { Avatar as MuiAvatar, Popper, Typography, CircularProgress, ClickAwayListener, } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { Person } from 'src/components/mui/icons';
import { useDraggable } from '@neodrag/react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import { OverlappingApptIcon, LunchIcon, PartPickupIcon, VisitIcon, } from '../../icons';
import JobInfo from './JobInfoPopover/JobInfo';
import { DraggableJob, ChartJobContainer, ChartJobInfoContainer, ChartJobInfo, ChartJobInfoReferenceNum, ChartJobInfoAddress, OverlappingApptButton, JobPopperContent, JobPlaceholder, } from './ChartJob.styles';
import useChartJob from './useChartJob';
const Avatar = styled(MuiAvatar) `
  background-color: transparent;
  border: 2px solid ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
  width: 32px;
  height: 32px;
  & svg {
    font-size: 25px;
  }
`;
const icons = {
    REPAIR_REQUEST: Person,
    LUNCH: LunchIcon,
    PART_PICKUP: PartPickupIcon,
    VISIT: VisitIcon,
};
const ChartJob = ({ job, van, region, idx, dragLoading, setDragLoading, }) => {
    var _a;
    const Icon = (_a = icons[job.repairJob.type]) !== null && _a !== void 0 ? _a : Person;
    const { anchorEl, dragCurrentTime, setShouldPerformClickEvent, selectedJobs, updatingJobId, jobCancellationStatus, isJobInfoLoading, jobPositionX, jobSizePixels, travelTimeTailPixels, overlappingJobs, isCurrentJobSelected, jobSelectedForUpdate, jobName, options, draggableRef, handleJobSelect, onOverlappingJobsClick, getZIndex, extractCompanyName, handleClickAway, } = useChartJob({
        job,
        region,
        van,
        setDragLoading,
    });
    useDraggable(draggableRef, options);
    return (<>
      {jobSelectedForUpdate && (<JobPlaceholder position={jobPositionX} width={jobSizePixels} jobStatus={job.repairJob.jobStatus}/>)}
      <Tooltip title={<Typography>{dragCurrentTime !== null && dragCurrentTime !== void 0 ? dragCurrentTime : ''}</Typography>} disabled={!dragCurrentTime} placement="left" style={{
            fontSize: '30px',
        }}>
        <DraggableJob ref={draggableRef} width={jobSizePixels} travelTimeTailWidth={jobSelectedForUpdate ? 0 : travelTimeTailPixels} jobStatus={job.repairJob.jobStatus} selected={isCurrentJobSelected} isJobBeingUpdated={jobSelectedForUpdate} gridRow={idx} zIndex={getZIndex()} onMouseDown={() => setShouldPerformClickEvent(false)} onMouseUp={() => setShouldPerformClickEvent(true)}>
          <ChartJobContainer onClick={(e) => (dragCurrentTime ? {} : handleJobSelect(e, false))}>
            <ChartJobInfoContainer>
              <ChartJobInfo>
                {dragLoading && jobSelectedForUpdate ? (<CircularProgress />) : (<Avatar>
                    <Icon />
                  </Avatar>)}
              </ChartJobInfo>

              <ChartJobInfo>
                <ChartJobInfoReferenceNum>{jobName}</ChartJobInfoReferenceNum>

                {job.repairJob.type === 'VISIT' && (<ChartJobInfoAddress>
                    {extractCompanyName(job.repairJob.description)}
                  </ChartJobInfoAddress>)}

                {job.repairJob.type !== 'VISIT' && job.repairJob.address && (<ChartJobInfoAddress>
                    {job.repairJob.address}
                  </ChartJobInfoAddress>)}
              </ChartJobInfo>

              {overlappingJobs &&
            !jobSelectedForUpdate &&
            (!selectedJobs.find((item) => item.id !== job.id) ||
                isCurrentJobSelected) && (<OverlappingApptButton className="cancel" onClick={(e) => onOverlappingJobsClick(e, job, overlappingJobs)}>
                    <OverlappingApptIcon />
                  </OverlappingApptButton>)}
            </ChartJobInfoContainer>
          </ChartJobContainer>
        </DraggableJob>
      </Tooltip>

      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
        <Popper style={{
            zIndex: 4,
            display: (jobCancellationStatus === null || jobCancellationStatus === void 0 ? void 0 : jobCancellationStatus.status) === 'IN_PROGRESS' || updatingJobId
                ? 'none'
                : 'block',
        }} open={(!!anchorEl && isCurrentJobSelected) ||
            (jobCancellationStatus === null || jobCancellationStatus === void 0 ? void 0 : jobCancellationStatus.status) === 'IN_PROGRESS'} anchorEl={anchorEl} placement="left-start" disablePortal={false} modifiers={[
            { name: 'flip', enabled: !isJobInfoLoading },
            {
                name: 'preventOverflow',
                enabled: true,
                options: { boundariesElement: 'viewport' },
            },
            { name: 'eventsEnabled', enabled: true },
        ]}>
          <JobPopperContent>
            <JobInfo jobType={job.repairJob.type}/>
          </JobPopperContent>
        </Popper>
      </ClickAwayListener>
    </>);
};
export default ChartJob;
