import { IconButton, Menu, MenuItem } from 'src/components/mui';
import { MoreVertRounded } from 'src/components/mui/icons';
import { capitalize, truncate } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUserIsEngineering, selectCurrentUserIsProduct, } from 'src/AdminApp/modules/users/selectors';
import { SimpleTableRow } from 'src/components/dashboard/SimpleTable';
import SimpleChip from 'src/components/SimpleChip';
import { usMoneyFormatWithCents } from 'src/modules/api/utils/format';
import { colors } from 'src/styles/theme';
import moment from 'moment';
const ReturnedItemRow = ({ item, onDeleteItem, onReturn, onMove, }) => {
    var _a, _b, _c;
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const userIsEngineer = useSelector(selectCurrentUserIsEngineering);
    const userIsProduct = useSelector(selectCurrentUserIsProduct);
    const menuOpen = Boolean(menuAnchorEl);
    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };
    const allowDelete = userIsEngineer || userIsProduct;
    const handleReturn = () => {
        handleMenuClose();
        onReturn(item);
    };
    const handleMove = () => {
        handleMenuClose();
        onMove(item);
    };
    const { locationAddress } = item;
    return (<SimpleTableRow data={[
            <div style={{ whiteSpace: 'nowrap' }}>
          {capitalize((_a = item.locationType) === null || _a === void 0 ? void 0 : _a.replace('_', ' '))}
        </div>,
            item.locationName,
            item.partDistributorName,
            <>
          <div>
            PO#{' '}
            <a href={`/admin/repairs/${item.poNumber}`} target="_blank" rel="noopener noreferrer">
              {item.poNumber}
            </a>
          </div>
          <div style={{ whiteSpace: 'nowrap' }}>
            {item.partName} • {capitalize(item.type)}
          </div>
          <div style={{ whiteSpace: 'nowrap' }}>
            Part#{' '}
            <span title={((_b = item.partNumber) !== null && _b !== void 0 ? _b : '').length > 10
                    ? item.partNumber
                    : undefined}>
              {truncate(item.partNumber, { length: 10 })}
            </span>{' '}
            • Qty {item.quantity}
          </div>
        </>,
            locationAddress && item.locationType !== 'DELIVERY_VAN' ? (<>
            <div>{locationAddress.street1}</div>
            <div>
              {locationAddress.city}, {locationAddress.state}{' '}
              {locationAddress.zip}
            </div>
          </>) : null,
            <div style={{ whiteSpace: 'nowrap' }}>
          {moment(item.created).format('lll')}
        </div>,
            usMoneyFormatWithCents((_c = item.unitCost) !== null && _c !== void 0 ? _c : ''),
            <div style={{ textAlign: 'center' }}>
          <SimpleChip label={item.status} $textColor={colors.white} $bgColor={item.status === 'PENDING'
                    ? colors.orangeSelected
                    : colors.linkBlue}/>
        </div>,
            <>
          <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuClick}>
            <MoreVertRounded />
          </IconButton>
          <Menu anchorEl={menuAnchorEl} keepMounted open={menuOpen} onClose={handleMenuClose}>
            {item.status === 'PENDING' && (<MenuItem onClick={handleReturn}>Return</MenuItem>)}
            {item.status === 'RETURNED' && (<MenuItem onClick={handleReturn}>
                Add Confirmation Number
              </MenuItem>)}
            {item.status === 'PENDING' && (<MenuItem onClick={handleMove}>Move</MenuItem>)}
            {item.status === 'PENDING' && allowDelete && (<MenuItem style={{ color: colors.error }} onClick={() => {
                        handleMenuClose();
                        onDeleteItem(item);
                    }}>
                Delete
              </MenuItem>)}
          </Menu>
        </>,
        ]}/>);
};
export default ReturnedItemRow;
