var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid, Typography } from 'src/components/mui';
import { KeyboardArrowRight, Sort } from 'src/components/mui/icons';
import React, { useState } from 'react';
import { SimpleDrawer, SimpleDrawerContent } from 'src/components/SimpleDrawer';
import { NotesDrawerBtn, SortButtonContainer, } from './AddNotesDrawer/AddNotesDrawer.styles';
import { sortOptions } from './constants';
import useNotes from './useNotes';
const SortNotesDrawer = ({ repairRequestId }) => {
    var _a;
    const { onLoadData, pagination } = useNotes(repairRequestId);
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleSubmit = (sort) => __awaiter(void 0, void 0, void 0, function* () {
        yield onLoadData({
            page: (pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) || 0,
            size: (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || 10,
            sort,
        });
        setOpenDrawer(false);
    });
    return (<>
      <SortButtonContainer onClick={() => setOpenDrawer(true)}>
        <Sort fontSize="small"/>
        Notes Sort
      </SortButtonContainer>
      <SimpleDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} title="Sort">
        <SimpleDrawerContent>
          <Grid container>
            <Grid container direction="row" style={{ justifyContent: 'space-between' }}>
              <Grid item spacing={3}>
                <Typography variant="h6">Sort</Typography>
              </Grid>
              <Grid item spacing={3}>
                <Typography variant="subtitle1">
                  {(_a = sortOptions.find((option) => option.value === pagination.sort)) === null || _a === void 0 ? void 0 : _a.label}
                </Typography>
              </Grid>
            </Grid>

            {sortOptions.map((item, index) => (<NotesDrawerBtn key={index} onClick={() => handleSubmit(item.value)}>
                <Grid container>
                  <Grid item xs>
                    {item.label}
                  </Grid>
                  <Grid>
                    <KeyboardArrowRight />
                  </Grid>
                </Grid>
              </NotesDrawerBtn>))}
          </Grid>
        </SimpleDrawerContent>
      </SimpleDrawer>
    </>);
};
export default SortNotesDrawer;
