var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addExtraTimeRequest, clearStopwatch, getRepairRequestStopwatch, stopStopwatch, } from 'src/AdminApp/modules/workflows/actions';
import { AppointmentStatus } from 'src/AdminApp/models/enums';
import { toast } from 'src/components/SimpleToast';
import { getExtraTimeRequestReasons } from 'src/AdminApp/modules/extraTimeRequestReasons/actions';
import { reloadRequestAppointments } from 'src/AdminApp/modules/requests/actions';
import { selectStopwatch } from 'src/AdminApp/modules/workflows/selectors';
import useStopwatchUpdates from 'src/hooks/useStopwatchUpdates';
const useNonVisitStopwatch = ({ activeAppointment, currentTask, referenceNum, }) => {
    const dispatch = useDispatch();
    const stopwatch = useSelector(selectStopwatch());
    const [secondsLeft, setSecondsLeft] = useState(0);
    const [timeLeft, setTimeLeft] = useState('00:00');
    const [stopwatchInterval, setStopwatchInterval] = useState();
    const [isAddTimeDisabled, setIsAddTimeDisabled] = useState(false);
    const [isExtraTimeReasonModalOpened, setIsExtraTimeReasonModalOpened] = useState(false);
    const [isExtraMinutesModalOpened, setIsExtraMinutesModalOpened] = useState(false);
    const [extraTimeReason, setExtraTimeReason] = useState();
    const [extraMinutes, setExtraMinutes] = useState(0);
    const [extraTimeReasonOptions, setExtraTimeReasonOptions] = useState([]);
    const stopwatchIntervalRef = useRef(stopwatchInterval);
    stopwatchIntervalRef.current = stopwatchInterval;
    useStopwatchUpdates(secondsLeft, stopwatchInterval, setTimeLeft);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const { payload } = yield dispatch(getExtraTimeRequestReasons());
            setExtraTimeReasonOptions(payload);
        }))();
        return () => {
            clearInterval(stopwatchIntervalRef.current);
            dispatch(clearStopwatch());
        };
    }, [dispatch]);
    useEffect(() => {
        if ((stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.availableTime) === undefined) {
            return;
        }
        clearInterval(stopwatchInterval);
        setSecondsLeft(stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.availableTime);
        const interval = setInterval(() => {
            setSecondsLeft((prevState) => prevState - 1);
        }, 1000);
        setStopwatchInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.availableTime]);
    const requestStopwatch = useCallback(() => {
        const sleep = (ms) => {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        };
        // Retry mechanism is necessary since the endpoint may respond with
        // an invalid value due to a race condition
        const fetchAndRetry = () => __awaiter(void 0, void 0, void 0, function* () {
            let availableTime;
            for (let i = 0; i <= 2; i += 1) {
                // eslint-disable-next-line no-await-in-loop
                const { payload } = yield dispatch(getRepairRequestStopwatch(referenceNum));
                availableTime = payload.availableTime;
                if (availableTime !== 0) {
                    return;
                }
                // eslint-disable-next-line no-await-in-loop
                yield sleep(3000);
            }
        });
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield fetchAndRetry();
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, referenceNum]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            // Skips initial tasks for normal ROs and visits
            const isCurrentTaskValid = currentTask &&
                currentTask.state !== 'TRAVEL_STARTED' &&
                currentTask.state !== 'ARRIVED' &&
                currentTask.status === 'PENDING' &&
                currentTask.state !== 'JOB_STARTED' &&
                currentTask.status === 'PENDING';
            const isStopwatchValid = (stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning) === undefined;
            if (isCurrentTaskValid && isStopwatchValid) {
                requestStopwatch();
            }
        }))();
    }, [
        dispatch,
        requestStopwatch,
        stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning,
        currentTask,
        currentTask === null || currentTask === void 0 ? void 0 : currentTask.state,
        currentTask === null || currentTask === void 0 ? void 0 : currentTask.status,
    ]);
    useEffect(() => {
        const verifyAndStopStopwatch = () => {
            const isAppointmentInvalid = activeAppointment &&
                (activeAppointment.status === AppointmentStatus.CANCELED ||
                    activeAppointment.status === AppointmentStatus.CLOSED);
            const isJobCompleted = currentTask &&
                currentTask.state === 'PAYMENT_COLLECTED' &&
                currentTask.status === 'PENDING';
            if (isAppointmentInvalid || isJobCompleted) {
                setIsAddTimeDisabled(true);
                clearInterval(stopwatchInterval);
                dispatch(stopStopwatch());
            }
        };
        verifyAndStopStopwatch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeAppointment, currentTask, dispatch]);
    const onExtraTimeRequestSubmit = useCallback(() => {
        setIsExtraMinutesModalOpened(false);
        setIsExtraTimeReasonModalOpened(false);
        const extraTimeRequest = {
            secondsRequested: Number(extraMinutes) * 60,
            reason: extraTimeReason,
        };
        dispatch(addExtraTimeRequest(activeAppointment.id, extraTimeRequest)).then(({ error }) => {
            if (!error) {
                dispatch(reloadRequestAppointments(referenceNum));
                toast.success('Extra time successfully requested.');
                requestStopwatch();
            }
        });
    }, [
        extraMinutes,
        dispatch,
        extraTimeReason,
        activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.id,
        referenceNum,
        requestStopwatch,
    ]);
    return {
        stopwatch,
        secondsLeft,
        setSecondsLeft,
        timeLeft,
        setTimeLeft,
        isAddTimeDisabled,
        setIsAddTimeDisabled,
        isExtraTimeReasonModalOpened,
        setIsExtraTimeReasonModalOpened,
        isExtraMinutesModalOpened,
        setIsExtraMinutesModalOpened,
        extraTimeReason,
        setExtraTimeReason,
        extraMinutes,
        setExtraMinutes,
        extraTimeReasonOptions,
        setExtraTimeReasonOptions,
        onExtraTimeRequestSubmit,
        stopwatchInterval,
        setStopwatchInterval,
    };
};
export default useNonVisitStopwatch;
