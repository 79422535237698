import { startCase } from 'lodash';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { VerifiedUser } from '@mui/icons-material';
import { setResponsiblePartyTypeModalRepair } from 'src/AdminApp/modules/requests/actions';
import SimpleChip from 'src/components/SimpleChip';
import { Tooltip } from 'src/components/tooltips';
import { flexCenter } from 'src/styles/styled';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
import { responsiblePartyTypeContext } from './utils';
const Container = styled.div `
  ${flexCenter};

  height: auto;
  justify-content: flex-start;
  width: auto;
`;
const PayerChip = ({ readOnly = false, name, repairId, diagnosisId, customRepairId, repairName, totalCost, responsiblePartyType, authorizationNumber, }) => {
    const dispatch = useDispatch();
    const { handleOpenPayerModal } = useContext(responsiblePartyTypeContext);
    const handleClick = (e) => {
        e.stopPropagation();
        dispatch(setResponsiblePartyTypeModalRepair({
            repairId,
            customRepairId,
            diagnosisId,
            repairName,
            totalCost,
        }));
        if (handleOpenPayerModal) {
            handleOpenPayerModal();
        }
    };
    const text = () => {
        switch (responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.name) {
            case 'Customer Pay':
                return responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.name;
            default:
                return `${responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.name} - ${name}`;
        }
    };
    const onClick = !readOnly ? handleClick : undefined;
    return (<Container>
      <SimpleChip variant="outlined" onClick={onClick} label={startCase(text())} size="medium" $textColor={colors.black}/>
      {authorizationNumber && !(responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.defaultPrimary) && (<Tooltip title={`Authorization # ${authorizationNumber}`} style={{ fontSize: '20px' }} placement="top">
          <VerifiedUser style={{
                cursor: 'pointer',
                paddingLeft: 8,
                color: colors.green,
            }}/>
        </Tooltip>)}
    </Container>);
};
export default PayerChip;
