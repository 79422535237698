import { toEnum } from 'src/models/enums';
export const PartAdditionalInfoOptions = toEnum({
    amperage: 'Amperage',
    bank: 'Bank',
    brandOrManufacturer: 'Brand/Manufacturer',
    color: 'Color',
    condition: 'Condition',
    diameter: 'Diameter',
    finish: 'Finish',
    length: 'Length',
    material: 'Material',
    position: 'Position',
    powerConsumption: 'Power Consumption',
    temperature: 'Temperature',
    voltage: 'Voltage',
    weight: 'Weight',
    width: 'Width',
    other: 'Other',
});
export default PartAdditionalInfoOptions;
