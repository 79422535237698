import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { setSelectedDate, setSelectedJobs, setUpdatingJobId, setSelectedRegions, setWorkingVansOnly, setJobCancellationStatus, setOnDragEnd, setSelectedJobAnchorEl, setIsJobInfoLoading, ActionCreators, setLockDrag, } from './actions';
const DEFAULT_STATE = {
    jobsByRegion: [],
    selectedJobs: [],
    selectedDate: '',
    territories: [],
    updatingJobId: undefined,
    workingVansOnly: false,
    selectedRegions: [],
    jobCancellationStatus: undefined,
    onDragEnd: undefined,
    selectedJobAnchorEl: undefined,
    isJobInfoLoading: false,
    lockDrag: false,
};
const successGetTimelineJobsReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { jobsByRegion: action.payload.content });
};
const clearJobsByRegions = (state) => {
    return Object.assign(Object.assign({}, state), { jobsByRegion: [] });
};
const reducers = handleActions({
    [setSelectedJobs.type]: createSimpleHandler('selectedJobs'),
    [setSelectedDate.type]: createSimpleHandler('selectedDate'),
    [setUpdatingJobId.type]: createSimpleHandler('updatingJobId'),
    [setWorkingVansOnly.type]: createSimpleHandler('workingVansOnly'),
    [setSelectedRegions.type]: createSimpleHandler('selectedRegions'),
    [setJobCancellationStatus.type]: createSimpleHandler('jobCancellationStatus'),
    [setOnDragEnd.type]: createSimpleHandler('onDragEnd'),
    [setSelectedJobAnchorEl.type]: createSimpleHandler('selectedJobAnchorEl'),
    [setIsJobInfoLoading.type]: createSimpleHandler('isJobInfoLoading'),
    [ActionCreators.GET_JOBS_BY_REGIONS.SUCCESS.type]: successGetTimelineJobsReducer,
    [ActionCreators.CLEAR_JOBS_BY_REGIONS.type]: clearJobsByRegions,
    [ActionCreators.GET_TERRITORIES.SUCCESS.type]: createSimpleHandler('territories'),
    [setLockDrag.type]: createSimpleHandler('lockDrag'),
}, DEFAULT_STATE);
export default reducers;
