var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import { SlidePageContent, SlidePageFooter, SlidePageFormWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { getTerritoriesAutocomplete } from 'src/AdminApp/modules/territories/actions';
import { selectTerritoriesAutocomplete } from 'src/AdminApp/modules/territories/selectors';
import TerritorySearch from 'src/components/form/TerritorySearch';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const ArchiveTerritoryPage = ({ model, onArchive, }) => {
    var _a;
    const validationSchema = Yup.object().shape({
        transferTerritory: Yup.string().required(),
    });
    return model ? (<Formik initialValues={{
            id: (_a = model.id) !== null && _a !== void 0 ? _a : '',
            transferTerritory: null,
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            var _b;
            if (onArchive) {
                yield onArchive({
                    id: values.id,
                    transferTerritoryId: (_b = values.transferTerritory.id) !== null && _b !== void 0 ? _b : '',
                });
                setSubmitting(false);
            }
        })}>
      {({ values, isSubmitting, isValid, setFieldValue }) => (<SlidePageFormWrapper>
          <SlidePageContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>{`Move the following "${model.name}" metros to a different territory`}</h2>
              </Grid>
              <Grid item xs={12}>
                <h4>
                  {model.metros
                .filter((x) => !x.archived)
                .map((x) => {
                return <p>{x.name}</p>;
            })}
                </h4>
              </Grid>
              <Grid item xs={12}>
                <TerritorySearch value={values.transferTerritory} name="territory" label="Territory" onUpdate={(x) => setFieldValue('transferTerritory', x)} getTerritories={getTerritoriesAutocomplete} territoriesSelector={selectTerritoriesAutocomplete}/>
              </Grid>
            </Grid>
          </SlidePageContent>
          <SlidePageFooter>
            <FooterActionButton type="submit" disabled={isSubmitting || !isValid}>
              Archive Territory
            </FooterActionButton>
          </SlidePageFooter>
        </SlidePageFormWrapper>)}
    </Formik>) : null;
};
export default ArchiveTerritoryPage;
