import * as gql from 'gql-query-builder';
const INSPECTION_FIELDS = [
    'id',
    'repairRequestId',
    'repairRefNum',
    'consumerId',
    'carId',
    'createdAt',
    'completedAt',
    'performedById',
    'modifiedBy',
    'updatedAt',
    {
        checklistAnswers: ['id', 'inspectionId', 'checklistQuestionId', 'answer'],
    },
    {
        inspectionType: [
            'id',
            'name',
            'type',
            {
                checklistAnswerLabels: ['needsWorkLabel', 'okLabel'],
            },
            {
                inspectionTypeCategories: [
                    {
                        category: ['id', 'name'],
                    },
                    {
                        components: [
                            'id',
                            'name',
                            {
                                inspectionRsRepairs: ['id', 'repairId', 'name'],
                            },
                            {
                                inspectionSymptoms: ['id', 'name'],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        inspectionCategoryResults: [
            'id',
            {
                inspectionCategory: ['id'],
            },
            {
                payload: [
                    'status',
                    {
                        tireTreadMeasurements: [
                            'frontLeft',
                            'frontRight',
                            'backLeft',
                            'backRight',
                        ],
                    },
                    {
                        brakePadMeasurements: [
                            'frontLeft',
                            'frontRight',
                            'backLeft',
                            'backRight',
                        ],
                    },
                    {
                        batteryReadings: ['coldCrankAmps'],
                    },
                ],
            },
        ],
    },
    {
        inspectionResults: [
            'id',
            'status',
            'images',
            'suggestedRepairId',
            {
                payload: ['additionalInformation', 'customServiceRequest'],
            },
            {
                inspectionSymptoms: ['id', 'name'],
            },
            {
                inspectionComponent: [
                    'id',
                    'name',
                    {
                        inspectionCategories: ['id', 'name'],
                    },
                    {
                        inspectionRsRepairs: ['id', 'name', 'repairId'],
                    },
                    {
                        inspectionSymptoms: ['id', 'name'],
                    },
                ],
            },
            {
                inspectionRsRepair: ['id', 'name', 'repairId'],
            },
            {
                inspectionCategory: ['id', 'name'],
            },
        ],
    },
];
export const archiveInspectionsQuery = ({ referenceNum, }) => gql.mutation({
    operation: 'archiveInspection',
    variables: { refNum: { value: referenceNum, required: true } },
    fields: ['id'],
});
export const getInspectionByRepairRequestIdQuery = ({ repairRequestId, }) => gql.query({
    operation: 'findByRepairRequestId',
    variables: { repairRequestId: { value: repairRequestId, required: true } },
    fields: INSPECTION_FIELDS,
});
