import styled from 'styled-components';
import { colors, FONT_FAMILY, theme } from 'src/styles/theme';
export const PaymentChooserContainer = styled.section `
  margin-top: 20px;
  margin-bottom: 31px;

  .PaymentChooser {
    &__list {
      border-radius: 5px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    }

    &__itemLabel {
      font-weight: 500;
    }

    &__radio {
      &--error {
        color: $red;
      }
    }

    .affirm-as-low-as {
      font-family: $font-family;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.69;
      letter-spacing: -0.33px;
      color: $snack_bg;
    }

    &__affirmLogo {
      width: 100%;
      position: relative;
      left: -20px;

      ${theme.breakpoints.up('md')} {
        left: -25px;
      }
    }

    .Spreedly {
      &__form {
        padding: 0;

        #expiration {
          display: flex;

          input {
            padding: 19px 16px 21px;
          }
        }
      }
    }

    &__ccLabel {
      display: flex;
      align-items: center;
    }

    &__ccDetailsMain {
      display: flex;
      align-items: center;
    }

    &__ccLogo {
      width: 26px;
    }

    &__ccDetails {
      font-family: ${FONT_FAMILY};
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: -0.38px;
      color: ${colors.GRAY_3F};
      display: flex;
      justify-content: space-between;
    }

    &__changeCCLink {
      font-family: ${FONT_FAMILY};
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: -0.38px;
      text-align: right;
      color: ${colors.blue};
      text-transform: none;
    }

    &__last4 {
      letter-spacing: 2px;
      padding-left: 10px;
    }

    &__ccBullet {
      padding-left: 8px;
      padding-right: 8px;
    }

    &__addCC {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
    }

    .PaymentForm {
      margin-top: 0;

      .Spreedly__actions {
        display: none;
      }
    }

    &__addAffirm,
    &__cashOrCheck {
      padding-top: 0;
      margin-top: 0;

      .PaymentChooser {
        &__noCharge {
          margin-top: 0;
        }

        &__noChargeSubheader {
          margin-top: 0;
          margin-left: 55px;
          margin-bottom: 16px;
        }
      }
    }

    &__noCharge {
      margin-top: 16px;
    }

    &__noChargeHeader {
      font-size: 15px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.28px;
      white-space: nowrap;
      margin: 0;
    }

    &__noChargeSubheader {
      font-family: ${FONT_FAMILY};
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      color: ${colors.GRAY_76};
      margin: 8px 0 21px 0;
    }
  }
`;
