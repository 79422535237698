import { Button, DialogActions, DialogContent, DialogTitle, IconButton, } from 'src/components/mui';
import styled from 'styled-components';
import { ErrorRounded } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
export const ModalTitle = styled(DialogTitle) `
  padding: 32px 32px 0 32px;
`;
export const ErrorIcon = styled(ErrorRounded) `
  color: ${colors.RED_DARK};
  margin-left: 4px;
  display: block;
`;
export const CloseButton = styled(IconButton) `
  position: absolute;
  top: 12px;
  right: 32px;
`;
export const ModalContent = styled(DialogContent) `
  padding: 16px 32px 0 32px;
`;
export const ModalActions = styled(DialogActions) `
  padding: 32px;
`;
export const CancelButton = styled(Button) `
  margin-right: 16px;
  padding: 16px;
  font-size: 16px;
  line-height: 16px;
`;
export const ConfirmButton = styled(Button) `
  padding: 16px;
  font-size: 16px;
  line-height: 16px;
`;
