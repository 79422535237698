import React from 'react';
import { SimpleDrawerContent } from 'src/components/SimpleDrawer';
import SimpleLoader from 'src/components/SimpleLoader';
import { Typography, Grid } from 'src/components/mui';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
import { DrawerHeader, DrawerContent, Accordion, AccordionSectionHeader, AccordionDetailsInfo, AccordionSummary, AccordionDetails, AccordionSummaryContent, MobileAccordionSummaryContent, } from './AuditLogDrawer.styles';
import { getValue, getModifiedValue, DRAWER_FIELDS, LIST_ORDER, } from './constants';
import useAuditDrawer from './useAuditDrawer';
const UserInfo = ({ ispOwnerId, created, userId, users, textOnly, }) => {
    var _a, _b;
    const user = (_b = (_a = users.find((el) => el.ispOwnerId === ispOwnerId)) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : userId;
    if (textOnly)
        return user;
    if (created) {
        if (!user) {
            return (<div className="created">
          <span>Created: </span> {getValue('created', created)}
        </div>);
        }
        return (<div className="created">
        <span>Created: </span>
        {`${getValue('created', created)} by ${user}`}
      </div>);
    }
    return null;
};
const AuditLogDrawerPage = ({ visitId, referenceNumber, vans, }) => {
    var _a;
    const { loading, assignedVans, data, usersInfo, isMobile, addresses, getValueByField, getAddressesArray, } = useAuditDrawer({
        referenceNumber,
        visitId,
    });
    if (loading)
        return <SimpleLoader />;
    if (!(data === null || data === void 0 ? void 0 : data.snapshot) || !addresses || !assignedVans) {
        return null;
    }
    return (<SimpleDrawerContent $noSidePadding={isMobile}>
      <DrawerHeader>
        <UserInfo ispOwnerId={(_a = data === null || data === void 0 ? void 0 : data.snapshot) === null || _a === void 0 ? void 0 : _a.ispOwnerId} created={data.snapshot.created} userId={data.snapshot.created_by} users={usersInfo}/>
        <div className="refNum">
          <span>Reference #: </span>
          {referenceNumber}
        </div>
      </DrawerHeader>
      <DrawerContent>
        <AccordionSectionHeader container>
          <Grid xs={6} item>
            Field
          </Grid>
          <Grid xs={6} item>
            Current Value
          </Grid>
        </AccordionSectionHeader>
        {Object.keys(data.snapshot)
            .filter((snap) => !!DRAWER_FIELDS[snap])
            .sort((a, b) => LIST_ORDER.indexOf(a) - LIST_ORDER.indexOf(b))
            .map((el, index) => {
            var _a, _b, _c;
            return (<Accordion key={index}>
              <AccordionSummary expandIcon={((_a = data === null || data === void 0 ? void 0 : data.changes) === null || _a === void 0 ? void 0 : _a[el]) && <ExpandMoreIcon />} aria-controls={`panel${index + 1}-content`} id={`panel${index + 1}-header`} disabled={!((_b = data === null || data === void 0 ? void 0 : data.changes) === null || _b === void 0 ? void 0 : _b[el])}>
                <MobileAccordionSummaryContent>
                  <Typography>
                    {DRAWER_FIELDS[el]}:{' '}
                    {getValue(el, data.snapshot[el], usersInfo)}
                  </Typography>
                </MobileAccordionSummaryContent>
                <AccordionSummaryContent container>
                  <Grid item xs={6}>
                    <Typography>{DRAWER_FIELDS[el]}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {getValueByField(el, data.snapshot[el], 0, true, vans)}
                    </Typography>
                  </Grid>
                </AccordionSummaryContent>
              </AccordionSummary>
              {((_c = data === null || data === void 0 ? void 0 : data.changes) === null || _c === void 0 ? void 0 : _c[el]) && (<AccordionDetails>
                  <AccordionDetailsInfo>
                    {data.changes[el].map((change, changeIndex) => (<>
                          {el === 'address_id' &&
                            Object.keys(addresses !== null && addresses !== void 0 ? addresses : {}).length > 0 &&
                            getAddressesArray(change.newValue).map((address, addressIndex) => {
                                return (<Grid key={addressIndex} container className="row">
                                    <Grid item xs={12} md={6}>
                                      <b>
                                        {addressIndex ===
                                        getAddressesArray(change.newValue)
                                            .length -
                                            1
                                        ? 'Created: '
                                        : 'Modified: '}
                                      </b>{' '}
                                      {getModifiedValue(address.created)} by{' '}
                                      <UserInfo ispOwnerId={address === null || address === void 0 ? void 0 : address.ispOwnerId} userId={address === null || address === void 0 ? void 0 : address.modifiedBy} users={usersInfo} textOnly/>
                                    </Grid>
                                    <Grid item xs={12} md={6} className="previous-value">
                                      <b>Value: </b>{' '}
                                      {getValueByField('address_id', address.id, addressIndex)}
                                    </Grid>
                                  </Grid>);
                            })}
                          {el !== 'address_id' && (<Grid key={changeIndex} container className="row">
                              <Grid item xs={12} md={6}>
                                <b>
                                  {changeIndex === data.changes[el].length - 1
                                ? 'Created: '
                                : 'Modified: '}{' '}
                                </b>{' '}
                                {getModifiedValue(change.date)} by{' '}
                                <UserInfo ispOwnerId={change === null || change === void 0 ? void 0 : change.ispOwnerId} userId={change === null || change === void 0 ? void 0 : change.modifiedBy} users={usersInfo} textOnly/>
                              </Grid>
                              <Grid item xs={12} md={6} className="previous-value">
                                <b>Value: </b>{' '}
                                {getValueByField(el, change.newValue, changeIndex, false, vans)}
                              </Grid>
                            </Grid>)}
                        </>))}
                  </AccordionDetailsInfo>
                </AccordionDetails>)}
            </Accordion>);
        })}
      </DrawerContent>
    </SimpleDrawerContent>);
};
export default AuditLogDrawerPage;
