import { createSelector } from 'reselect';
export const selectMetros = (state) => state.admin.metros;
export const metroById = (id) => createSelector(selectMetros, (value) => (value.byId[id] || {}));
export const selectDashboard = createSelector(selectMetros, (metros) => { var _a; return (_a = metros.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardPagination = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardParams = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectMetrosAutocomplete = createSelector(selectMetros, (metros) => { var _a; return (_a = metros.autocomplete) !== null && _a !== void 0 ? _a : []; });
export const selectDashboardFetching = createSelector(selectDashboard, (dashboard) => {
    var _a;
    return (_a = dashboard._isFetching) !== null && _a !== void 0 ? _a : false;
});
