import React from 'react';
import InvoiceSendModal from 'src/AdminApp/pages/invoiceWorkbench/components/InvoiceSendModal';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { Button, Container, FormControl, InputLabel } from 'src/components/mui';
import SimpleAutosuggest from 'src/components/SimpleAutosuggest';
import InvoiceRequestDetailsDrawer from 'src/AdminApp/pages/invoiceRequests/InvoiceRequestDetailsDrawer';
import LoadingModal from './components/LoadingModal';
import ModeSwitcher from './components/ModeSwitcher';
import NullState from './components/NullState';
import RefreshSnackbar from './components/RefreshSnackbar';
import StatusSelect from './components/StatusSelect';
import WorkbenchItem from './components/WorkbenchItem';
import useInvoiceWorkbench from 'src/AdminApp/pages/invoiceWorkbench/useInvoiceWorkbench';
import { BottomContainer, ClearFiltersButton, FiltersContainer, SelectedInfo, SubmitButton, } from './InvoiceWorkbench.styles';
import useIsMobile from 'src/hooks/useIsMobile';
const InvoiceWorkbench = () => {
    var _a, _b;
    const { worpDriveAccess, invoices, pagination, onLoadData, onRowsPerPageChange, loading, fleets, responsibleParties, openIRDetails, invoiceRequest, setLoading, handleUpdateItemsSelected, handleIRStatusClick, handleCloseIRDetails, itemsSelected, handleSelectAll, handleCheckAllSelected, isItemChecked, isItemDisabled, mode, status, selectedFilterId, isFetching, tableSort, isRefreshSnackbarOpened, invoiceRequestLoading, handleChangeMode, handleChangeStatus, handleClearFilters, handleWorkbenchItemsSubmit, isSubmitting, openSendModal, handleCloseSendModal, handleSearch, handleSelectFacet, handleRefresh, } = useInvoiceWorkbench();
    const isMobile = useIsMobile();
    const WORKBENCH_COLUMNS = [
        {
            title: '',
            key: 'checked',
            sortable: false,
            type: 'checkbox',
            action: handleSelectAll,
            checked: handleCheckAllSelected,
        },
        {
            title: mode === 0 ? 'Fleet' : 'Customer',
            key: mode === 0 ? 'fleet' : 'consumer',
            sortable: true,
        },
        { title: 'Ref #', key: 'refNumber', sortable: true },
        { title: 'Appt Date', key: 'appointmentDate', sortable: true },
        { title: 'RO Status', key: 'roStatus', sortable: false },
        { title: 'Invoice #', key: 'invoice', sortable: false },
        { title: 'Invoice Status', key: 'invoiceStatus', sortable: false },
        { title: 'Auth #', key: 'auth', sortable: false },
        { title: 'Resp Party', key: 'party', sortable: false },
        { title: 'Submission Portal', key: 'portal', sortable: false },
        { title: 'Total Price', key: 'price', sortable: false },
        { title: 'VIN', key: 'vin', sortable: false },
        { title: 'IR Status', key: 'irStatus', sortable: false },
    ];
    let title = 'No Results';
    if ((invoices === null || invoices === void 0 ? void 0 : invoices.length) > 0) {
        if (mode === 0) {
            title = invoices[0].fleet;
        }
        else if (mode === 1) {
            title = invoices[0].responsibleParty;
        }
    }
    return worpDriveAccess ? (<>
      <Container maxWidth="xl">
        <h3>{title}</h3>
        <FiltersContainer>
          <ModeSwitcher mode={mode} onChange={(value) => handleChangeMode({}, value)} disabled={isFetching}/>
          <FormControl>
            <InputLabel id="workbench-select-label" style={{
            backgroundColor: 'white',
            padding: '0 5px',
        }}>
              RO Status
            </InputLabel>
            <StatusSelect disabled={isFetching} status={status} onChange={handleChangeStatus}/>
          </FormControl>

          <SimpleAutosuggest style={{ minWidth: 350, marginLeft: isMobile ? 0 : '8px' }} label={mode === 0 ? 'Fleet' : 'Responsible Party'} suggestions={mode === 0 ? fleets : responsibleParties} autoFocus={false} fieldName="text" fieldToInput="text" clearOnEmpty={selectedFilterId} clearOnChange={mode} disabled={isFetching} initialValue={mode === 0
            ? fleets === null || fleets === void 0 ? void 0 : fleets.find((f) => f.value === selectedFilterId)
            : responsibleParties === null || responsibleParties === void 0 ? void 0 : responsibleParties.find((f) => f.value === selectedFilterId)} inputValue={selectedFilterId} onSearch={(value) => {
            handleSearch(value.text);
        }} onSelect={(suggestion) => {
            handleSelectFacet(suggestion.value);
        }} onClear={() => {
            handleSelectFacet(undefined);
        }}/>

          <ClearFiltersButton variant="text" onClick={handleClearFilters}>
            Clear Filters
          </ClearFiltersButton>

          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flex: 1,
        }}>
            <Button variant="contained" color="primary" onClick={() => handleRefresh()} disabled={isFetching || (invoices === null || invoices === void 0 ? void 0 : invoices.length) === 0}>
              Refresh
            </Button>
          </div>
        </FiltersContainer>
        <SimpleTable isFetching={isFetching} columns={WORKBENCH_COLUMNS} data={invoices} pagination={pagination} sort={tableSort} hideTopPagination renderItem={(item, index) => (<WorkbenchItem key={index} item={item} mode={mode} checked={isItemChecked(item)} checkDisabled={isItemDisabled(item)} setLoading={setLoading} onCheckboxClick={handleUpdateItemsSelected} onIRStatusClick={handleIRStatusClick}/>)} hidePagination={invoices.length < 1} onLoadData={onLoadData} onRowsPerPageChange={onRowsPerPageChange} showNullState={!selectedFilterId} NullState={<NullState text={mode === 0
                ? 'Please select a Fleet to see results'
                : 'Please select a Responsible Party to see results'}/>}/>
        <BottomContainer>
          <SelectedInfo>{(_a = itemsSelected === null || itemsSelected === void 0 ? void 0 : itemsSelected.length) !== null && _a !== void 0 ? _a : 0} Selected</SelectedInfo>
          <SubmitButton variant="contained" disabled={((_b = itemsSelected === null || itemsSelected === void 0 ? void 0 : itemsSelected.length) !== null && _b !== void 0 ? _b : 0) < 1 || isSubmitting} onClick={handleWorkbenchItemsSubmit}>
            Submit
          </SubmitButton>
        </BottomContainer>
        <LoadingModal open={loading} title={'Downloading Invoice'}/>
      </Container>
      <RefreshSnackbar open={isRefreshSnackbarOpened} onClick={() => handleRefresh()}/>
      <InvoiceSendModal open={openSendModal} onClose={handleCloseSendModal}/>
      <InvoiceRequestDetailsDrawer open={openIRDetails} onClose={handleCloseIRDetails} loading={invoiceRequestLoading} invoiceRequests={invoiceRequest ? [invoiceRequest] : []}/>
    </>) : (<div>
      ERROR - Not Authorized: You must have the "WORP Drive Access" role to view
      this item
    </div>);
};
export default InvoiceWorkbench;
