import styled from 'styled-components';
import { Button } from 'src/components/mui';
import { PrimaryButton } from 'src/components/buttons';
import { colors, theme } from 'src/styles/theme';
export const FiltersContainer = styled.div `
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      margin-bottom: 12px;
    }
  }
`;
export const TabsContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-right: 20px;
`;
export const ClearFiltersButton = styled(Button) `
  margin-left: 20px;
  text-decoration: underline;
  font-size: 16px;
`;
export const BottomContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding: 20px 0;
`;
export const SubmitButton = styled(PrimaryButton) `
  margin-left: 20px;
  padding: 16px 70px;
  font-size: 16px;
`;
export const SelectedInfo = styled.p `
  font-size: 12px;
  color: ${colors.GRAY_76};
  padding: 0;
  margin: 0;
`;
