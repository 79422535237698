var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearPartnerAssets, deletePartnerAsset, getPartnerAssets, } from 'src/PartnersApp/modules/assets/actions';
import { selectPartnersAssetsList } from 'src/PartnersApp/modules/assets/selectors';
const useAssets = ({ onClose, partner }) => {
    const dispatch = useDispatch();
    const assets = useSelector(selectPartnersAssetsList);
    const getAssets = () => {
        dispatch(getPartnerAssets({ partnerId: partner.id }));
    };
    const onLeaveTab = () => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(clearPartnerAssets());
    });
    const handleClose = () => {
        dispatch(clearPartnerAssets());
        if (onClose) {
            onClose();
        }
    };
    const handleDelete = (id) => () => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(deletePartnerAsset({ partnerId: partner.id, assetsIds: [id] }));
        yield dispatch(getPartnerAssets({ partnerId: partner.id }));
    });
    useEffect(() => {
        getAssets();
        return () => {
            onLeaveTab();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        handleClose,
        handleDelete,
        assets,
    };
};
export default useAssets;
