import React, { useEffect, useState } from 'react';
import { toast } from 'src/components/SimpleToast';
import { IconButton, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from 'src/components/mui';
import { CheckCircleOutlined as ActiveIcon, CheckOutlined as DoneIcon, EditOutlined as EditIcon, CancelOutlined as CancelIcon, } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import { EditableTableCell } from '../components';
import { useSkeduloGraphqlApiClient } from '../api/skeduloGraphqlApiClient';
import { toEditableRow, fromEditableRow } from '../utils';
import { ResourcesTable, ResourcesColumnHeader, ResourcesCell, ResourcesMeta, ResourcesClickableText, } from './styles';
const Resources = ({ resources, onSaveCallback, onRegionClick, }) => {
    const [rows, setRows] = useState([]);
    const [previous, setPrevious] = useState({});
    const skeduloGraphqlApiClient = useSkeduloGraphqlApiClient();
    const onToggleEditMode = (uid) => {
        setRows(() => rows.map((row) => row.UID === uid ? Object.assign(Object.assign({}, row), { isEditMode: !row.isEditMode }) : row));
    };
    const onSave = (uid) => {
        onToggleEditMode(uid);
        const resource = rows.find((row) => row.UID === uid);
        skeduloGraphqlApiClient
            .updateResource({
            UID: resource === null || resource === void 0 ? void 0 : resource.UID,
            Name: resource === null || resource === void 0 ? void 0 : resource.Name,
        })
            .then((response) => {
            if (response.errors) {
                const errors = response.errors.map((error) => `${error.statusCode}: ${error.mutation_errors
                    .map((me) => me.message)
                    .join(',')}`);
                toast.error(`Error updating Resource '${resource.Name}':\n\n${errors.join('\\n')}`);
            }
            else {
                toast.success(`Successfully updated Resource '${resource === null || resource === void 0 ? void 0 : resource.Name}' (ID: ${resource.UID})'`);
                onSaveCallback(fromEditableRow(resource));
            }
        })
            .catch((error) => {
            toast.error(`Error updating Resource '${resource === null || resource === void 0 ? void 0 : resource.Name}' (ID: ${resource.UID}): ${error}`);
        });
    };
    const onChange = (e, row) => {
        if (!previous[row.UID]) {
            setPrevious((state) => (Object.assign(Object.assign({}, state), { [row.UID]: row })));
        }
        const { value, name } = e.target;
        const { UID } = row;
        const newRows = rows.map((row) => row.UID === UID ? Object.assign(Object.assign({}, row), { [name]: value }) : row);
        setRows(newRows);
    };
    const onRevert = (uid) => {
        const newRows = rows.map((row) => row.UID === uid ? Object.assign(Object.assign({}, (previous[uid] || row)), { isEditMode: false }) : row);
        setRows(newRows);
        setPrevious((state) => {
            delete state[uid];
            return state;
        });
    };
    useEffect(() => {
        if (resources) {
            setRows(resources.map(toEditableRow));
        }
    }, [resources]);
    return (<TableContainer component={Paper}>
      <ResourcesTable size="small">
        <TableHead>
          <ResourcesColumnHeader>
            <ResourcesCell $minWidth="350px">Resource Name</ResourcesCell>
            <ResourcesCell $minWidth="200px">Primary Region</ResourcesCell>
            <ResourcesCell>Tags</ResourcesCell>
            <ResourcesCell>Active</ResourcesCell>
            <ResourcesCell $minWidth="150px"/>
          </ResourcesColumnHeader>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            var _a, _b;
            return (<TableRow key={row.UID}>
              <EditableTableCell row={row} name="Name" onChange={onChange} inputStyle={{ width: '300px' }}>
                <div>{row.Name}</div>
                <ResourcesMeta>{row.UID}</ResourcesMeta>
              </EditableTableCell>
              <TableCell onClick={() => {
                    onRegionClick(row.PrimaryRegion);
                }}>
                <ResourcesClickableText>
                  {(_a = row.PrimaryRegion) === null || _a === void 0 ? void 0 : _a.Name}
                </ResourcesClickableText>
                <ResourcesMeta>{(_b = row.PrimaryRegion) === null || _b === void 0 ? void 0 : _b.Timezone}</ResourcesMeta>
              </TableCell>
              <TableCell>
                {row.ResourceTags.map((rt) => rt.Tag.Name).join(', ')}
              </TableCell>
              <TableCell>
                {row.IsActive && <ActiveIcon style={{ color: colors.green }}/>}
              </TableCell>
              <TableCell>
                {row.isEditMode ? (<>
                    <IconButton aria-label="done" onClick={() => onSave(row.UID)}>
                      <DoneIcon />
                    </IconButton>
                    <IconButton aria-label="revert" onClick={() => onRevert(row.UID)}>
                      <CancelIcon />
                    </IconButton>
                  </>) : (<IconButton aria-label="delete" onClick={() => onToggleEditMode(row.UID)}>
                    <EditIcon />
                  </IconButton>)}
              </TableCell>
            </TableRow>);
        })}
        </TableBody>
      </ResourcesTable>
    </TableContainer>);
};
export default Resources;
