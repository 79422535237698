var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectDashboardPagination, selectDashboardParams, } from 'src/AdminApp/modules/fleets/selectors';
import { ActionCreators, createFleet, searchFleets, searchFleetsByName, } from 'src/AdminApp/modules/fleets/actions';
const useFleetsDashboard = () => {
    var _a;
    const dispatch = useDispatch();
    const [addFleetIsOpen, setAddFleetIsOpen] = useState(false);
    const [currentFilters, setCurrentFilters] = useState();
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    const [pageSize, setPageSize] = useState((_a = pagination.pageSize) !== null && _a !== void 0 ? _a : 20);
    useEffect(() => {
        var _a, _b, _c;
        dispatch(searchFleets(Object.assign(Object.assign({}, params), { pageSize: (_a = pagination.pageSize) !== null && _a !== void 0 ? _a : 20, pageIndex: (_b = pagination.pageNumber) !== null && _b !== void 0 ? _b : 0, sort: (_c = pagination.sort) !== null && _c !== void 0 ? _c : ['shopName', 'asc'] })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLoadData = ({ page, size, sort }) => {
        dispatch(searchFleets(Object.assign(Object.assign(Object.assign({}, params), { pageSize: size, pageIndex: page, sort }), currentFilters)));
    };
    const onSearch = (filters) => {
        dispatch(searchFleets(Object.assign(Object.assign({ pageIndex: 0 }, filters), { pageSize })));
        setCurrentFilters(filters);
    };
    const onReset = () => {
        setCurrentFilters(undefined);
        setPageSize(20);
    };
    const onClear = () => {
        dispatch(ActionCreators.SEARCH_FLEETS.CLEAR());
        onReset();
    };
    const onAddFleet = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(createFleet(values));
        setAddFleetIsOpen(false);
        if (!response.error) {
            dispatch(searchFleets({ pageIndex: 0, pageSize: 20 }));
        }
    });
    const handleSearchFleets = (data) => dispatch(searchFleetsByName({ name: data }));
    return {
        setAddFleetIsOpen,
        addFleetIsOpen,
        onReset,
        onClear,
        onSearch,
        handleSearchFleets,
        pagination,
        onLoadData,
        setPageSize,
        onAddFleet,
    };
};
export default useFleetsDashboard;
