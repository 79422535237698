import React from 'react';
import styled from 'styled-components';
import { Typography } from 'src/components/mui';
import CurrencyLabel from 'src/components/CurrencyLabel';
import { colors } from 'src/styles/theme';
const TotalWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Remark = styled.div `
  font-size: 12px;
  font-style: italic;
  line-height: 1.33;
  color: ${colors.darkGrey};
  margin-top: 4px;
`;
const Total = ({ total, workOrderResponsiblePartyTypeTotals, recommendedRepairSubtotal, }) => {
    let subtotalValue;
    if (recommendedRepairSubtotal) {
        subtotalValue = <CurrencyLabel>{recommendedRepairSubtotal}</CurrencyLabel>;
    }
    else if (total) {
        subtotalValue = `$${Number(total).toFixed(2)}`;
    }
    else {
        subtotalValue = '0.00';
    }
    return (<>
      {workOrderResponsiblePartyTypeTotals &&
            workOrderResponsiblePartyTypeTotals.length > 1 &&
            workOrderResponsiblePartyTypeTotals.map((worpt) => (<TotalWrapper>
            <Typography variant="subtitle2">{worpt.name} Total: </Typography>
            <Typography variant="subtitle2">
              {worpt.totalAmount
                    ? `$${Number(worpt.totalAmount).toFixed(2)}`
                    : '0.00'}
            </Typography>
          </TotalWrapper>))}
      <TotalWrapper>
        <Typography variant="subtitle2">Subtotal: </Typography>
        <Typography variant="subtitle2">{subtotalValue}</Typography>
      </TotalWrapper>
      <Remark>Excludes tax where applicable</Remark>
    </>);
};
export default Total;
