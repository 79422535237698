import React from 'react';
import { TextField, Autocomplete } from 'src/components/mui';
const DynamicAutocompleteSelector = (props) => {
    const onChange = (element, value) => {
        var _a;
        props.onChange((_a = value === null || value === void 0 ? void 0 : value.id) !== null && _a !== void 0 ? _a : '');
    };
    return (<Autocomplete id={props.id} options={props.items} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} onChange={onChange} value={props.selectedItem} renderInput={(params) => (<TextField {...params} label={props.label} fullWidth/>)}/>);
};
export default DynamicAutocompleteSelector;
