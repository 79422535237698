export var ZendeskTicketType;
(function (ZendeskTicketType) {
    ZendeskTicketType[ZendeskTicketType["QUOTE_NEEDED"] = 0] = "QUOTE_NEEDED";
    ZendeskTicketType[ZendeskTicketType["MPI_QUOTE_NEEDED"] = 1] = "MPI_QUOTE_NEEDED";
    ZendeskTicketType[ZendeskTicketType["DELIVERY_REQUESTED"] = 2] = "DELIVERY_REQUESTED";
    ZendeskTicketType[ZendeskTicketType["DELIVERY_BOOKED"] = 3] = "DELIVERY_BOOKED";
    ZendeskTicketType[ZendeskTicketType["SALES_CALL_NEEDED"] = 4] = "SALES_CALL_NEEDED";
    ZendeskTicketType[ZendeskTicketType["ISP_CONFIRMATION_NEEDED"] = 5] = "ISP_CONFIRMATION_NEEDED";
    ZendeskTicketType[ZendeskTicketType["DROP_OFF_INVOICE"] = 6] = "DROP_OFF_INVOICE";
    ZendeskTicketType[ZendeskTicketType["SMS_REPLY"] = 7] = "SMS_REPLY";
    ZendeskTicketType[ZendeskTicketType["SERVICE_ADVISOR_CALL_REQUESTED"] = 8] = "SERVICE_ADVISOR_CALL_REQUESTED";
    ZendeskTicketType[ZendeskTicketType["APPOINTMENT_CANCELED"] = 9] = "APPOINTMENT_CANCELED";
    ZendeskTicketType[ZendeskTicketType["APPOINTMENT_RESCHEDULED"] = 10] = "APPOINTMENT_RESCHEDULED";
    ZendeskTicketType[ZendeskTicketType["CHECK_QUOTE"] = 11] = "CHECK_QUOTE";
})(ZendeskTicketType || (ZendeskTicketType = {}));
