var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import { toast } from 'src/components/SimpleToast';
import { TableBody, Divider, CircularProgress } from 'src/components/mui';
import SimpleTablePagination from 'src/components/dashboard/SimpleTable/SimpleTablePagination';
import SimpleTableHeader from 'src/components/dashboard/SimpleTable/SimpleTableHeader';
import { TableContainer, TableLoader, EmptyTable, } from 'src/components/dashboard/SimpleTable/SimpleTable.style';
const SimpleTable = ({ pagination, sort, columns, data, renderItem, onLoadData, onRowsPerPageChange, hidePagination, hideTopPagination, isFetching = false, style, NullState, hideTopDivider = false, showNullState, }) => {
    const [innerData, setInnerData] = useState(data);
    const [innerPagination, setInnerPagination] = useState();
    const [isWaitingForData, setIsWaitingForData] = useState(false);
    useEffect(() => {
        if (isFetching)
            return;
        // when sort is changing we can wait for data without clearing rows
        if (!isWaitingForData) {
            setInnerData(data);
            setInnerPagination(pagination);
        }
        if (isWaitingForData && data.length) {
            setInnerData(data);
            setInnerPagination(pagination);
        }
    }, [data, isWaitingForData, isFetching, pagination]);
    const handleChangePage = (event, newPage) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!onLoadData)
            return;
        if (isFetching || isWaitingForData)
            return;
        setIsWaitingForData(true);
        try {
            yield onLoadData({
                page: newPage,
                size: (_a = innerPagination === null || innerPagination === void 0 ? void 0 : innerPagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                sort,
                query: innerPagination === null || innerPagination === void 0 ? void 0 : innerPagination.query,
            });
        }
        finally {
            setIsWaitingForData(false);
        }
    });
    const handleChangeRowsPerPage = (event) => __awaiter(void 0, void 0, void 0, function* () {
        if (!onLoadData)
            return;
        if (isFetching || isWaitingForData)
            return;
        setIsWaitingForData(true);
        try {
            const size = +event.target.value;
            if (onRowsPerPageChange) {
                onRowsPerPageChange(size);
            }
            else {
                yield onLoadData({
                    page: 0,
                    size,
                    sort,
                    query: innerPagination === null || innerPagination === void 0 ? void 0 : innerPagination.query,
                });
            }
        }
        finally {
            setIsWaitingForData(false);
        }
    });
    const handleSort = (column) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e;
        if (!onLoadData)
            return;
        if (isFetching || isWaitingForData)
            return;
        setIsWaitingForData(true);
        try {
            const [currentSortBy, currentSortDir] = ((sort && sort[0]) || '').split(',');
            const newSortBy = column.key;
            const newSortDir = newSortBy === currentSortBy && currentSortDir === 'desc'
                ? 'asc'
                : 'desc';
            // @TODO: Add support for multisorting
            const newSort = [`${newSortBy},${newSortDir}`];
            if ((_b = column === null || column === void 0 ? void 0 : column.sortable) !== null && _b !== void 0 ? _b : true) {
                yield onLoadData({
                    page: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _c !== void 0 ? _c : 0,
                    size: (_d = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _d !== void 0 ? _d : 20,
                    sort: newSort,
                    query: pagination === null || pagination === void 0 ? void 0 : pagination.query,
                });
            }
            else {
                toast.error(`Sorry! Cannot sort on "${(_e = column === null || column === void 0 ? void 0 : column.title) === null || _e === void 0 ? void 0 : _e.newSortBy}"`);
            }
        }
        finally {
            setIsWaitingForData(false);
        }
    });
    return (<>
      {!hideTopDivider && <Divider />}
      {!hideTopPagination && (<SimpleTablePagination hidePagination={hidePagination} pagination={innerPagination} rowsCount={innerData.length} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}/>)}
      <div style={Object.assign({ width: '100%', overflow: 'auto', position: 'relative' }, style)}>
        {(isWaitingForData || isFetching) && (<TableLoader>
            <CircularProgress />
          </TableLoader>)}

        <TableContainer>
          <SimpleTableHeader columns={columns} sort={sort !== null && sort !== void 0 ? sort : []} onSort={handleSort}/>
          <TableBody>
            {map(innerData, renderItem)}
            {innerData.length < 1 && !showNullState && <EmptyTable />}
          </TableBody>
        </TableContainer>
      </div>
      {showNullState && (innerData === null || innerData === void 0 ? void 0 : innerData.length) < 1 && NullState}
      {<SimpleTablePagination hidePagination={hidePagination} pagination={innerPagination} rowsCount={innerData.length} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}/>}
    </>);
};
export default SimpleTable;
