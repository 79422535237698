var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDispatch } from 'react-redux';
import { requestMPIQuoteNeededCall } from 'src/AdminApp/modules/zendesk/actions';
import { SimpleModal } from 'src/components/layout';
const MpiNotifySADialog = ({ referenceNum, open, onClose, updateInspectionStatus, }) => {
    const dispatch = useDispatch();
    return (<SimpleModal open={open} onClose={onClose} title="Would you like to notify a service advisor about your MPI?" simpleTwoButtonRowProps={{
            onCancel: onClose,
            onSubmit: () => __awaiter(void 0, void 0, void 0, function* () {
                dispatch(requestMPIQuoteNeededCall(referenceNum));
                updateInspectionStatus();
            }),
            submitText: 'Send',
        }}/>);
};
export default MpiNotifySADialog;
