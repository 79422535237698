import React from 'react';
import { Grid, Grow, Paper, ClickAwayListener, MenuList, } from 'src/components/mui';
import { ArrowDropDown as ArrowDropDownIcon } from 'src/components/mui/icons';
import { VisitActionButtonsWrapper, AddRequestButton, AddRoPopper, AddRoMenuItem, } from './VisitActionButtons.styles';
const VisitActionButtons = ({ visit, onAddRepairOrders, }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleSelectExistingRos = (e) => {
        onAddRepairOrders('EXISTING');
        handleClose(e);
    };
    const handleCreateNewRo = (e) => {
        onAddRepairOrders('NEW');
        handleClose(e);
    };
    return (<VisitActionButtonsWrapper>
      <Grid container style={{ justifyContent: 'flex-end' }}>
        <Grid item>
          <AddRequestButton variant="contained" color="primary" onClick={handleToggle} ref={anchorRef} endIcon={<ArrowDropDownIcon />} disabled={visit.status === 'COMPLETED'}>
            Add Repair Order
          </AddRequestButton>
          <AddRoPopper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (<Grow {...TransitionProps} style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      <AddRoMenuItem key={0} onClick={handleSelectExistingRos}>
                        Select Existing ROs
                      </AddRoMenuItem>
                      <AddRoMenuItem key={1} onClick={handleCreateNewRo}>
                        Create New RO
                      </AddRoMenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>)}
          </AddRoPopper>
        </Grid>
      </Grid>
    </VisitActionButtonsWrapper>);
};
export default VisitActionButtons;
