var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEqual } from 'lodash';
import { toast } from 'src/components/SimpleToast';
import { selectDistributorLocationHoursOfOperation } from 'src/PopsApp/modules/distributors/selectors';
import { fetchDistributorLocationHoursOfOperation, patchUpdateLocationHoursOfOperation, } from 'src/PopsApp/modules/distributors/actions';
import { TIME_FORMAT } from 'src/timeConstants';
const INITIAL_TIME_ENTRY = {
    open: false,
    openTime: '08:00:00',
    closeTime: '08:00:00',
};
const useLocationHoursOfOperation = ({ location, onClose, }) => {
    const dispatch = useDispatch();
    const locationHoursOfOperation = useSelector(selectDistributorLocationHoursOfOperation);
    const [isLoading, setIsLoading] = useState(false);
    const getData = (location) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield dispatch(fetchDistributorLocationHoursOfOperation(location === null || location === void 0 ? void 0 : location.id));
        setIsLoading(false);
    });
    useEffect(() => {
        if (!location)
            return;
        getData(location);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    const getInitialValues = () => {
        const initialValues = {
            MONDAY: INITIAL_TIME_ENTRY,
            TUESDAY: INITIAL_TIME_ENTRY,
            WEDNESDAY: INITIAL_TIME_ENTRY,
            THURSDAY: INITIAL_TIME_ENTRY,
            FRIDAY: INITIAL_TIME_ENTRY,
            SATURDAY: INITIAL_TIME_ENTRY,
            SUNDAY: INITIAL_TIME_ENTRY,
        };
        if (!locationHoursOfOperation)
            return initialValues;
        locationHoursOfOperation.forEach((entry) => {
            initialValues[entry.weekday] = {
                open: entry.open,
                openTime: entry.openTime,
                closeTime: entry.closeTime,
                id: entry.id,
            };
        });
        return initialValues;
    };
    const invalidHoursErrorMessage = (values) => {
        const sameHours = values.filter((item) => moment
            .utc(item.closeTime, TIME_FORMAT)
            .isSame(moment.utc(item.openTime, TIME_FORMAT))).length > 0;
        if (sameHours) {
            return 'Validation Error: Close time must be different from open time';
        }
        const closeBeforeOpen = values.filter((item) => moment
            .utc(item.closeTime, TIME_FORMAT)
            .isBefore(moment.utc(item.openTime, TIME_FORMAT))).length > 0;
        if (closeBeforeOpen) {
            return 'Validation Error: Close time must be after open time';
        }
        return null;
    };
    const handleFormSubmit = (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        const initialValues = getInitialValues();
        const payload = Object.keys(initialValues)
            .filter((item) => !isEqual(initialValues[item], values[item]))
            .map((el) => {
            return Object.assign({ weekday: el }, values[el]);
        });
        if (payload.length > 0 && (location === null || location === void 0 ? void 0 : location.id)) {
            const validation = invalidHoursErrorMessage(payload);
            if (validation) {
                toast.error(validation);
                setSubmitting(false);
                return;
            }
            const response = yield dispatch(patchUpdateLocationHoursOfOperation(location === null || location === void 0 ? void 0 : location.id, payload));
            if (response && !response.error) {
                toast.success('Distributor location successfully updated.');
            }
            onClose();
        }
        setSubmitting(false);
    });
    return {
        dispatch,
        locationHoursOfOperation,
        isLoading,
        setLoading: setIsLoading,
        getInitialValues,
        invalidHoursErrorMessage,
        handleFormSubmit,
    };
};
export default useLocationHoursOfOperation;
