import createDefaultActionCreators from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/AdminApp/modules/api/urls';
export const ActionCreators = {
    GET_SKILLS_BY_FILTER: createDefaultActionCreators('GET_SKILLS_BY_FILTER'),
    ADD_SKILL: createDefaultActionCreators('ADD_SKILL'),
    UPDATE_SKILL: createDefaultActionCreators('UPDATE_SKILL'),
    DELETE_SKILL: createDefaultActionCreators('DELETE_SKILL'),
};
const getSkillsByFilter = (query) => createAPIAction({
    types: [
        ActionCreators.GET_SKILLS_BY_FILTER.FETCH(),
        ActionCreators.GET_SKILLS_BY_FILTER.SUCCESS(),
        ActionCreators.GET_SKILLS_BY_FILTER.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.SKILLS_BY_FILTER(query),
    method: 'GET',
});
const addSkill = (skill) => createAPIAction({
    types: [
        ActionCreators.ADD_SKILL.FETCH(),
        ActionCreators.ADD_SKILL.SUCCESS(),
        ActionCreators.ADD_SKILL.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ADD_SKILL(),
    method: 'POST',
    body: skill,
});
const updateSkill = (skill) => createAPIAction({
    types: [
        ActionCreators.UPDATE_SKILL.FETCH(),
        ActionCreators.UPDATE_SKILL.SUCCESS(),
        ActionCreators.UPDATE_SKILL.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.UPDATE_SKILL(skill.id),
    method: 'PUT',
    body: skill,
});
const deleteSkill = (skillId) => createAPIAction({
    types: [
        ActionCreators.DELETE_SKILL.FETCH(),
        ActionCreators.DELETE_SKILL.SUCCESS(),
        ActionCreators.DELETE_SKILL.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.UPDATE_SKILL(skillId),
    method: 'DELETE',
});
export { getSkillsByFilter, addSkill, updateSkill, deleteSkill };
