import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Autocomplete } from 'src/components/mui';
import useAutocompleteInput from 'src/hooks/useAutocompleteInput';
const DeliveryVanSearch = ({ value, getDeliveryVans, deliveryVansSelector, onUpdate, disabled, name, label, }) => {
    const { onInputChange } = useAutocompleteInput(value, disabled, getDeliveryVans);
    const options = useSelector(deliveryVansSelector);
    const onSubmit = (value) => {
        onUpdate(value);
    };
    return (<Autocomplete value={value} style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option, value) => option.id === value.id} options={options} disabled={disabled} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} onInputChange={onInputChange} onChange={(e, value) => onSubmit(value)} renderInput={(params) => (<TextField {...params} name={name} label={label} fullWidth variant="outlined" type="text" InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default DeliveryVanSearch;
