import { Grid, Typography } from 'src/components/mui';
import React from 'react';
import HubSearchSingleValue from 'src/components/form/HubSearchSingleValue';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import usePartPickupTransfer from './usePartPickupTransfer';
const PartPickupTransfer = ({ onClose }) => {
    var _a;
    const { selectedLocation, setSelectedLocation, isLoading, handleSubmit, hubs } = usePartPickupTransfer({
        onClose,
    });
    return (<>
      <SimpleDrawerContent>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <Typography variant="subtitle2">Confirm Selection</Typography>
            <Typography variant="subtitle1">
              Select the hub that you’d like to transfer parts to.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <HubSearchSingleValue setSelectedLocation={(location) => setSelectedLocation(Object.assign({}, location))} value={selectedLocation} options={hubs}/>
          </Grid>
        </Grid>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={handleSubmit} disabled={!selectedLocation ||
            ((_a = Object.keys(selectedLocation)) === null || _a === void 0 ? void 0 : _a.length) === 0 ||
            isLoading} isSubmitting={isLoading}>
          Submit
        </FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default PartPickupTransfer;
