import React, { useCallback, useEffect } from 'react';
import { FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectTechNames, selectTemplates, } from 'src/AdminApp/modules/deliveryVanTemplates/selectors';
import { getTemplateTechName, getVanTemplates, } from 'src/AdminApp/modules/deliveryVanTemplates/actions';
import { Table, TableBody, TableCell, TableHead } from 'src/components/mui';
import moment from 'moment';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
import { StyledTableRow } from './DeliveryVanTemplate.styles';
const TemplatesDrawerAvailabilityTable = ({ model, onEditTemplate, showInactive, }) => {
    const dispatch = useDispatch();
    const techNames = useSelector(selectTechNames);
    const vanTemplates = useSelector(selectTemplates);
    useEffect(() => {
        if (model) {
            dispatch(getVanTemplates(model.id));
        }
    }, [dispatch, model]);
    const getTechName = useCallback((techId) => {
        return techNames === null || techNames === void 0 ? void 0 : techNames.find((tech) => tech.id === techId);
    }, [techNames]);
    const getDateFormatted = (date) => date ? moment(date).format(LOCALIZED_DATE_FORMAT) : '';
    const handleSelectTemplate = (value) => {
        var _a, _b;
        onEditTemplate(Object.assign(Object.assign({}, value), { technicianName: `${(_a = getTechName(value.technicianId)) === null || _a === void 0 ? void 0 : _a.name}  ${!((_b = getTechName(value.technicianId)) === null || _b === void 0 ? void 0 : _b.enabled) ? ' (DEACTIVATED)' : ''}` }));
    };
    useEffect(() => {
        if (vanTemplates.length > 0 && techNames.length === 0) {
            vanTemplates.forEach((element) => {
                if (element.technicianId) {
                    dispatch(getTemplateTechName(element.technicianId));
                }
            });
        }
    }, [vanTemplates, dispatch, techNames]);
    return (<Table>
      <TableHead style={{ backgroundColor: '#eee' }}>
        <StyledTableRow>
          <TableCell>Technician</TableCell>
          <TableCell>Start</TableCell>
          <TableCell>End</TableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        <FieldArray name="templates">
          {() => vanTemplates === null || vanTemplates === void 0 ? void 0 : vanTemplates.map((row, index) => {
            var _a, _b;
            const dateFinish = moment(getDateFormatted(row.finish));
            const today = new Date();
            const finish = new Date();
            finish.setDate(dateFinish.date());
            finish.setMonth(dateFinish.month());
            finish.setFullYear(dateFinish.year());
            if (showInactive || row.finish === null || today <= finish) {
                return (<StyledTableRow key={row.id} onClick={() => {
                        handleSelectTemplate(vanTemplates[index]);
                    }}>
                    <TableCell>
                      <div>
                        {(_a = getTechName(row.technicianId)) === null || _a === void 0 ? void 0 : _a.name}{' '}
                        {!((_b = getTechName(row.technicianId)) === null || _b === void 0 ? void 0 : _b.enabled)
                        ? ' (DEACTIVATED)'
                        : ''}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{getDateFormatted(row.start)}</div>
                    </TableCell>
                    <TableCell>
                      <div>{getDateFormatted(row.finish)}</div>
                    </TableCell>
                  </StyledTableRow>);
            }
            return null;
        })}
        </FieldArray>
      </TableBody>
    </Table>);
};
export default TemplatesDrawerAvailabilityTable;
