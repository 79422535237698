import React from 'react';
import { SimpleModal } from 'src/components/layout/index';
const ConfirmationModal = ({ open, title, text, onClose, action, disabledSubmit, model, }) => {
    const onYesButtonClick = () => {
        onClose();
        action(model);
    };
    return (<SimpleModal aria-labelledby="confirmation-dialog-title" open={open} onClose={onClose} title={title || 'Confirm'} simpleTwoButtonRowProps={{
            onSubmit: onYesButtonClick,
            submitText: 'Yes',
            cancelText: 'No',
            onCancel: onClose,
            submitIsDestructive: true,
            disabledSubmit,
            disabledCancel: disabledSubmit,
        }}>
      {text}
    </SimpleModal>);
};
export default ConfirmationModal;
