import { intersection, overSome, isNil, isEmpty, reject } from 'lodash';
export function constructStreetLine(streetNumber, route) {
    const arr = reject([streetNumber, route], overSome([isNil, isEmpty]));
    return arr.length ? arr.join(' ') : '';
}
export function constructAddressObj(addressStr, googleMapsResponse) {
    var _a, _b;
    const essentialNames = [
        'street_number',
        'route',
        'locality',
        'neighborhood',
        'administrative_area_level_1',
        'administrative_area_level_2',
        'postal_code',
        'country',
    ];
    const { address_components, geometry } = googleMapsResponse[0];
    const parsed = address_components.reduce((acc, comp) => {
        const [name] = intersection(comp.types, essentialNames);
        return name ? Object.assign(Object.assign({}, acc), { [name]: comp.long_name }) : acc;
    }, {});
    return {
        addressStr,
        street: constructStreetLine(parsed.street_number, parsed.route),
        city: (_b = (_a = parsed.locality) !== null && _a !== void 0 ? _a : parsed.neighborhood) !== null && _b !== void 0 ? _b : parsed.administrative_area_level_2,
        state: parsed.administrative_area_level_1,
        zip: parsed.postal_code,
        latitude: geometry.location.lat(),
        longitude: geometry.location.lng(),
        streetNumber: parsed.street_number,
        country: parsed.country,
    };
}
