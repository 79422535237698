import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useVansFilter from 'src/PopsApp/modules/hooks/useFilter';
import { useDispatch } from 'react-redux';
import { getDeliveryRegions } from 'src/PopsApp/modules/parts/actions';
const getQueryString = (query) => {
    const queryParams = [];
    Object.keys(query).forEach((p) => {
        queryParams.push(`${p}=${query[p]}`);
    });
    return queryParams.join('&');
};
function useFilter({ values, searchField, defaultSort }) {
    const [filtered, setFiltered] = useState([]);
    const [searchStr, setSearchStr] = useState('');
    const [sortValue, setSortValue] = useState(defaultSort);
    const history = useHistory();
    const { changeFilterData, setNewFilterData, metros, territories, components, query, filterData, sortDirection, handleSortClose, isSortOpen, sortAnchorEl, handleSortClick, isFilterOpen, setFilterOpen, } = useVansFilter();
    const dispatch = useDispatch();
    useEffect(() => {
        const f = values
            .filter((v) => {
            return (!searchStr.length ||
                v[searchField]
                    .toLowerCase()
                    .includes(searchStr.toLowerCase()));
        })
            .sort((a, b) => {
            if (!defaultSort) {
                if (a[searchField] > b[searchField]) {
                    return 1;
                }
                return -1;
            }
            const fld = sortValue.field;
            const val = sortValue.value === 'asc' ? 1 : -1;
            if (a[fld] > b[fld]) {
                return val;
            }
            return val * -1;
        });
        if (!f.every((item) => filtered.includes(item)) ||
            !filtered.every((item) => f.includes(item))) {
            setFiltered(f);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, searchStr]);
    const batchFilter = (filterState) => {
        const newFilter = setNewFilterData(filterState);
        history.replace(`vans?${getQueryString(newFilter)}`);
    };
    const updateFilter = (fieldName, value) => {
        const q = changeFilterData(fieldName, value);
        history.replace(`vans?${getQueryString(q)}`);
        dispatch(getDeliveryRegions(q));
    };
    return {
        searchStr,
        setSearchStr,
        filtered,
        sortValue,
        setSortValue,
        updateFilter,
        batchFilter,
        metros,
        territories,
        components,
        query,
        filterData,
        sortDirection,
        handleSortClose,
        isSortOpen,
        sortAnchorEl,
        handleSortClick,
        isFilterOpen,
        setFilterOpen,
    };
}
export default useFilter;
