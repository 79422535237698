import { handleActions } from 'redux-actions';
import { ActionCreators } from 'src/AdminApp/modules/analytics/actions';
const DEFAULT_STATE = {
    track: null,
    identify: null,
};
const successAnalyticsTrackReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { track: action.payload });
};
const successAnalyticsIdentifyReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { identify: action.payload });
};
const reducers = handleActions({
    [ActionCreators.ANALYTICS_TRACK_EVENT.SUCCESS.type]: successAnalyticsTrackReducer,
    [ActionCreators.ANALYTICS_IDENTIFY_EVENT.SUCCESS.type]: successAnalyticsIdentifyReducer,
}, DEFAULT_STATE);
export default reducers;
