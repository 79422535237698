import { Typography } from 'src/components/mui';
import styled from 'styled-components';
export const UpdateMessageTypography = styled(Typography) `
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    margin-right: 6px;
  }
`;
