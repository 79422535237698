import styled from 'styled-components';
import { Button, Menu } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const PartnersTD = styled.td `
  padding: 26px 0;
  border-bottom: solid 1px ${colors.GRAY_DE};
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  text-align: ${(props) => { var _a; return (_a = props.align) !== null && _a !== void 0 ? _a : 'left'; }};

  :first-child {
    padding-left: 20px;
  }

  .funnelName {
    font-weight: 400;
    letter-spacing: -0.3px;
    color: ${colors.linkBlue};
    text-decoration: underline;
  }

  .funnelStatus {
    text-transform: capitalize;

    &--draft {
      color: ${colors.RED};
    }
  }
`;
export const RowMenu = styled(Menu) `
  .unpublish {
    transform: rotate(180deg);
  }
`;
export const EditButton = styled(Button) `
  font-weight: normal;

  .text {
    color: ${colors.GRAY_76};
    font-weight: bold;
  }

  svg {
    fill: ${colors.GRAY_76};
  }

  button {
    color: ${colors.linkBlue};
  }
`;
export const PublishButton = styled(Button) `
  padding: 16px 20px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
`;
export const PartnersTR = styled.tr `
  cursor: pointer;

  :hover {
    background: ${colors.GRAY_FA};
  }
`;
