import React from 'react';
import styled from 'styled-components';
import { DeleteForever } from 'src/components/mui/icons';
import SimpleChip from 'src/components/SimpleChip';
import { colors } from 'src/styles/theme';
const DeleteForeverIcon = styled(DeleteForever) `
  color: ${colors.error};
  cursor: pointer;
  margin-left: 4px;
`;
const StyledTableRow = styled.div `
  vertical-align: top !important;
  font-weight: bold;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.lightGrey};
`;
const RightInfoBlock = styled.div `
  display: flex;
  align-items: center;
`;
const DiscountStatementItem = ({ id, label, amount, discountType, remove = null, redeemed = false, isClosedAndUnredeemed = false, canEdit = false, }) => {
    const style = {
        color: isClosedAndUnredeemed ? colors.red : colors.green,
    };
    return (<StyledTableRow key="promo-code">
      <div style={style}>
        {label}
        {redeemed && (<span style={{ marginLeft: '5px' }}>
            <SimpleChip $bgColor={colors.green} $textColor={colors.white} label="REDEEMED"/>
          </span>)}
      </div>
      <RightInfoBlock>
        <span style={style}>- {amount}</span>
        {!redeemed && canEdit && (<DeleteForeverIcon onClick={() => remove && remove({ discountId: id, discountType })}/>)}
      </RightInfoBlock>
    </StyledTableRow>);
};
export default DiscountStatementItem;
