import { theme } from 'src/styles/theme';
import styled from 'styled-components';
import { Switch, Typography } from 'src/components/mui';
import { flexCenter } from 'src/styles/styled';
const TitleContainer = styled('div') `
  ${flexCenter};

  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 8px;
`;
const TitleText = styled(Typography) `
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 8px;
`;
const TabContent = styled('div') `
  margin-top: 24px;
  min-height: 285px;
`;
const Container = styled('div') `
  ${theme.breakpoints.up('md')} {
    ${flexCenter};

    align-items: flex-start;
    justify-content: flex-start;
  }
`;
const BlockTitle = styled(Typography) `
  font-size: 0.875rem;
  margin-bottom: 16px;
`;
const FieldRow = styled('div') `
  ${flexCenter};

  justify-content: flex-start;
  min-height: 24px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;
const FieldLabel = styled(Typography) `
  flex: 1;
  max-width: 180px;
`;
const FieldValue = styled(Typography) `
  ${flexCenter};

  flex: 2;
  max-width: 360px;
  min-height: inherit;
`;
const Toggle = styled(Switch) `
  transform: translateX(-12px);
`;
const Block = styled('div') `
  flex: 1;
`;
const InternalNotesContainer = styled(Container) `
  flex-direction: row;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;
const FieldLabelNotes = styled(FieldLabel) `
  max-width: 100px;
`;
const FieldRowNotes = styled(FieldRow) `
  align-items: flex-start;
  margin-top: 16px;

  @media (min-width: 1280px) {
    margin-top: 40px;
  }
`;
const BlockNotes = styled(Block) `
  flex-grow: 2;
  align-items: flex-start;
  width: 100%;
`;
const FieldValueNotes = styled(FieldValue) `
  max-width: 550px;
  flex: 3;
`;
const variantMap = {
    body1: 'span',
};
const editInputStyle = {
    minHeight: 'inherit',
    width: 'inherit',
};
const addressInputStyle = Object.assign(Object.assign({}, editInputStyle), { display: 'flex', flexDirection: 'column' });
const labelStyle = {
    maxWidth: 180,
};
const valueStyle = {
    maxWidth: 250,
};
const requireMultiStoreStyle = {
    justifyContent: 'left',
    marginLeft: '5px',
};
export { addressInputStyle, BlockTitle, Container, editInputStyle, FieldLabel, FieldRow, FieldValue, labelStyle, TabContent, TitleContainer, TitleText, Toggle, Block, InternalNotesContainer, FieldLabelNotes, FieldRowNotes, BlockNotes, FieldValueNotes, valueStyle, variantMap, requireMultiStoreStyle, };
