import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { Menu, MenuItem, Input } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { Check } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import { RowFormContainer } from 'src/AdminApp/components/form';
import { DeliveryVanStatusValues, SalesStatus, ShopStatus, } from 'src/AdminApp/models/enums';
import { getShopDashboardUrl } from 'src/AdminApp/utils/url-utils';
import { SimpleTableRow } from 'src/components/dashboard/SimpleTable';
import MoreIcon from 'src/components/icons/MoreIcon';
import Subtext from 'src/components/layout/Subtext';
import { formatPhone } from 'src/utils/formatter';
import { SmallCancelButton, SmallSubmitButton, } from '../ShopAddForm/components/SmallButtons';
import useShopsListItem from './useShopsListItem';
const ShopScore = styled.div `
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 14px;
  color: ${colors.white};
  line-height: 30px;
  text-align: center;
  font-weight: 800;
`;
const StyledCheck = styled(Check) `
  font-size: 2rem;
  color: ${colors.lightBlue};
`;
const Score = ({ score }) => {
    let background = colors.backgroundIconGrey;
    let color = 'white';
    switch (Math.floor(score)) {
        case 1:
            background = '#FC5050';
            break;
        case 2:
            background = '#FF7C30';
            break;
        case 3:
            background = '#FFC721';
            break;
        case 4:
            background = '#FFF600';
            color = 'gray';
            break;
        case 5:
            background = '#3AC140';
            break;
        default:
            background = 'black';
    }
    return <ShopScore style={{ background, color }}>{score || 'n/a'}</ShopScore>;
};
const smallButtonStyles = {
    marginLeft: '4px',
    padding: '0px',
    minWidth: '35px',
    height: '35px',
};
const statusActionMap = {
    PENDING: {
        label: 'Activate',
        newStatus: 'ACTIVE',
    },
    SUSPENDED: {
        label: 'Re-instate',
        newStatus: 'ACTIVE',
    },
    ACTIVE: {
        label: 'Suspend',
        newStatus: 'SUSPENDED',
    },
};
const ShopsListItem = ({ id, firstName, lastName, shopName, integrated, address, city, state, zip, shopScore, phone, ispOwner, salesStatus, deliveryChannels, deliveryVanStatus, activeServiceAreasCount, activeServiceAreasNames, status, }) => {
    var _a;
    const { anchorEl, editShopScore, setEditShopScore, canEdit, showMenu, closeMenu, submitUpdate, internalEditShopScore, handleSubmitScore, } = useShopsListItem({ id, shopScore });
    const renderShopScoreForm = () => {
        return (<Formik initialValues={{ shopScore }} enableReinitialize onSubmit={handleSubmitScore}>
        {({ setFieldValue, isSubmitting, resetForm }) => (<RowFormContainer>
            <Field name="shopScore">
              {({ field }) => (<Input {...field} style={{ width: '40px' }} onChange={({ target }) => {
                        setFieldValue('shopScore', target.value);
                    }} inputProps={{
                        maxLength: 1,
                        step: 1,
                        min: 1,
                        max: 5,
                        pattern: '[1-5]{1}',
                        type: 'number',
                    }}/>)}
            </Field>
            <SmallCancelButton onClick={() => {
                    resetForm({ values: { shopScore } });
                    setEditShopScore(false);
                }} disabled={isSubmitting} style={smallButtonStyles}/>
            <SmallSubmitButton type="submit" disabled={isSubmitting} style={smallButtonStyles}/>
          </RowFormContainer>)}
      </Formik>);
    };
    return (<SimpleTableRow data={[
            editShopScore ? renderShopScoreForm() : <Score score={shopScore}/>,
            integrated && <StyledCheck />,
            deliveryChannels === null || deliveryChannels === void 0 ? void 0 : deliveryChannels.map((deliveryChannel, index) => (<div key={index}>{deliveryChannel}</div>)),
            <Link to={`/admin/shops/${id}/edit`}>{shopName}</Link>,
            <>
          <div>{address}</div>
          <div>
            {city}, {state} {zip}
          </div>
        </>,
            <>
          <div>
            {firstName} {lastName}
          </div>
          <Subtext>{formatPhone(phone)}</Subtext>
          <Subtext>{ispOwner === null || ispOwner === void 0 ? void 0 : ispOwner.email}</Subtext>
        </>,
            <>
          {DeliveryVanStatusValues.display(deliveryVanStatus)}
          {activeServiceAreasCount ? (<Subtext>
              {activeServiceAreasCount === 1 ? (activeServiceAreasNames) : (<Tooltip title={activeServiceAreasNames}>
                  <span>{`${activeServiceAreasCount} service areas`}</span>
                </Tooltip>)}
            </Subtext>) : null}
        </>,
            SalesStatus.display(salesStatus),
            ShopStatus.display(status),
            canEdit && (<>
            <span onClick={showMenu} onKeyDown={() => { }} role="button" tabIndex={0}>
              <MoreIcon />
            </span>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeMenu}>
              <MenuItem onClick={() => submitUpdate({
                    id,
                    status: statusActionMap[status].newStatus,
                })}>
                {(_a = statusActionMap[status]) === null || _a === void 0 ? void 0 : _a.label} Shop{' '}
              </MenuItem>
              <MenuItem onClick={() => submitUpdate({ id, integrated: !integrated })}>
                {integrated ? 'Set to Non-Integrated' : 'Set to Integrated'}{' '}
              </MenuItem>
              {!editShopScore && (<MenuItem onClick={() => internalEditShopScore()}>
                  Edit Shop Score
                </MenuItem>)}
              <MenuItem onClick={() => {
                    window.open(getShopDashboardUrl(id), '_blank');
                }}>
                ISP Site
              </MenuItem>
            </Menu>
          </>),
        ]}/>);
};
export default ShopsListItem;
