import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { ActionCreators } from 'src/PopsApp/modules/inventory/actions';
import { fetched } from 'src/utils/data-utils';
const DEFAULT_STATE = {
    dashboard: {
        params: {
            filters: {},
        },
        content: undefined,
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: undefined,
        },
    },
    moveLocations: undefined,
};
const returnItemsSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const reducers = handleActions({
    [ActionCreators.GET_RETURN_INVENTORY.SUCCESS.type]: returnItemsSuccessReducer,
    [ActionCreators.FETCH_LOCATION_HIERARCHY.SUCCESS.type]: createSimpleHandler('moveLocations'),
}, DEFAULT_STATE);
export default reducers;
