import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddVisitNumberOfTechnicians, selectAddVisitVanMechanicParings, selectAddVisitTechniciansChoice, } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { selectActiveMechanicsInRegionData } from 'src/AdminApp/modules/quickRo/selectors';
import { saveAddVisitNumberOfTechnicians, saveAddVisitTechnicianIds, saveAddVisitTechniciansChoice, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { WARNINGS } from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal/WarningMessages';
const useAvailableTechnicians = ({ isEditing, setUnsavedTechniciansChoice, setUnsavedVanMechanicPairings, setUnsavedNumberOfTechs, shouldShowCapacityWarning, }) => {
    const dispatch = useDispatch();
    const techniciansChoice = useSelector(selectAddVisitTechniciansChoice);
    const numberOfTechs = useSelector(selectAddVisitNumberOfTechnicians);
    const technicians = useSelector(selectActiveMechanicsInRegionData);
    const selectedVanMechanicPairings = useSelector(selectAddVisitVanMechanicParings);
    useEffect(() => {
        if (technicians.length > 0 && !isEditing) {
            dispatch(saveAddVisitTechniciansChoice('byNumber'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [technicians, isEditing]);
    const changeTechniciansChoice = (e) => {
        setUnsavedTechniciansChoice(e.target.value);
        dispatch(saveAddVisitTechniciansChoice(e.target.value));
    };
    const changeNumberOfTechs = (e) => {
        const techCount = parseInt(e.target.value, 10);
        // WE DON'T CARE IF YOU INCREASE THE NUMBER OF TECHS
        if (techCount > numberOfTechs) {
            dispatch(saveAddVisitNumberOfTechnicians(techCount > 20 ? 1 : techCount));
            return;
        }
        setUnsavedNumberOfTechs(techCount);
        if (!shouldShowCapacityWarning({
            newNumberOfTechs: techCount,
            message: WARNINGS.DECREASE_TECHNICIAN_COUNT,
        })) {
            dispatch(saveAddVisitNumberOfTechnicians(techCount > 20 ? 1 : techCount));
        }
    };
    const handleTechSelect = (checked, vanMechanicPairing) => {
        let newSelectedTechs = new Set(selectedVanMechanicPairings);
        if (checked) {
            newSelectedTechs.add(vanMechanicPairing);
        }
        else {
            const newNewSelectedTechs = new Set();
            newSelectedTechs.forEach((tech) => {
                if (tech.technicianId !== vanMechanicPairing.technicianId &&
                    tech.deliveryVanSkeduloResourceId !==
                        vanMechanicPairing.deliveryVanSkeduloResourceId) {
                    newNewSelectedTechs.add(tech);
                }
            });
            newSelectedTechs = newNewSelectedTechs;
        }
        if (selectedVanMechanicPairings.size > newSelectedTechs.size) {
            dispatch(saveAddVisitTechnicianIds(newSelectedTechs));
            return;
        }
        setUnsavedVanMechanicPairings(newSelectedTechs);
        if (!shouldShowCapacityWarning({
            newNumberOfTechs: newSelectedTechs.size,
            message: WARNINGS.DECREASE_TECHNICIAN_COUNT,
        })) {
            dispatch(saveAddVisitTechnicianIds(newSelectedTechs));
        }
    };
    const isTechVanChecked = (vanMechanicPairing) => {
        let isChecked = false;
        selectedVanMechanicPairings.forEach((tech) => {
            if (tech.technicianId === vanMechanicPairing.technicianId &&
                tech.deliveryVanSkeduloResourceId ===
                    vanMechanicPairing.deliveryVanSkeduloResourceId &&
                !isChecked) {
                isChecked = true;
            }
        });
        return isChecked;
    };
    return {
        techniciansChoice,
        numberOfTechs,
        technicians,
        changeTechniciansChoice,
        changeNumberOfTechs,
        handleTechSelect,
        isTechVanChecked,
    };
};
export default useAvailableTechnicians;
