import { createSelector } from 'reselect';
const getPartners = (state) => state.partners.partners;
export const selectPartnersData = createSelector(getPartners, (partners) => partners === null || partners === void 0 ? void 0 : partners.data);
export const selectPartnersPagination = createSelector(getPartners, (partners) => partners === null || partners === void 0 ? void 0 : partners.pagination);
export const selectPartnerFunnels = createSelector(selectPartnersData, (partners) => {
    if (partners) {
        return partners.reduce((agg, curr) => {
            if (curr.funnels.length) {
                return [...agg, curr.funnels[0]];
            }
            return agg;
        }, []);
    }
    return null;
});
export const selectPartnerById = (partnerId) => createSelector(getPartners, (partners) => { var _a; return partnerId != null ? (_a = partners === null || partners === void 0 ? void 0 : partners.partnersById) === null || _a === void 0 ? void 0 : _a[partnerId] : null; });
