import React from 'react';
import { ActionButton } from 'src/components/buttons';
import { RowWrapper } from 'src/components/SimpleTwoButtonRow';
import useIsMobile from 'src/hooks/useIsMobile';
import { SimpleModal } from 'src/components/layout';
import { GridContainer, GridItem, Title } from './AddedToVisitDialog.styles';
const CONFIG = {
    LEAD_TIME: {
        copy: 'A new request has been created but not added to your visit due to a lead time in order to have parts in time. Please add this request to a new visit.',
        showSpecial: true,
    },
    PENDING_PRICING: {
        copy: 'This RO contains pending pricing and cannot be scheduled for this visit. Please complete the pricing and try again.',
        showSpecial: false,
    },
    OVER_CAPACITY: {
        copy: "Unable to add repair order to visit. Selected services exceed the visit's labor capacity. Please assign to a different visit.",
        showSpecial: false,
    },
};
const AddedToVisitDialog = ({ open, onClose, errorCode = 'LEAD_TIME', }) => {
    const isMobile = useIsMobile();
    const { copy, showSpecial } = errorCode
        ? CONFIG[errorCode]
        : { copy: '', showSpecial: false };
    const textWidth = showSpecial ? 10 : 12;
    return (<SimpleModal open={open} onClose={onClose} title={<Title isMobile={isMobile}>
          <GridContainer direction={isMobile ? 'column-reverse' : 'row'}>
            <GridItem xs={textWidth}>Request Not Added To Visit</GridItem>
            {showSpecial && (<GridItem xs={2}>
                <img alt="special" src="img/png/special.png"/>
              </GridItem>)}
          </GridContainer>
        </Title>}>
      {copy}
      <RowWrapper>
        <ActionButton onClick={onClose}>Okay</ActionButton>
      </RowWrapper>
    </SimpleModal>);
};
export default AddedToVisitDialog;
