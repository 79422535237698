import React from 'react';
import { Skeleton } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
const LoaderItemContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  border-bottom: solid 1px ${colors.GRAY_DE};
  padding: 24px 8px;
`;
const LoaderItemImage = styled(Skeleton) `
  width: 56px;
  height: 56px;
  margin-right: 8px;
  border-radius: 3px;
`;
const LoaderItemContent = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 247px;
`;
const LoaderItemRow = styled(Skeleton) `
  border-radius: 3px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-bottom: ${(props) => props.offset}px;
`;
const SkeletonItem = () => {
    return (<LoaderItemContainer className="LoaderItem__Container">
      <LoaderItemImage variant="rectangular" className="LoaderItem__Image"/>

      <LoaderItemContent>
        <LoaderItemRow variant="rectangular" width={117} height={20} offset={8} className="LoaderItem__Row"/>
        <LoaderItemRow variant="rectangular" width={188} height={12} offset={4} className="LoaderItem__Row"/>
        <LoaderItemRow variant="rectangular" width={188} height={12} offset={16} className="LoaderItem__Row"/>

        <LoaderItemRow variant="rectangular" width={38} height={12} offset={8} className="LoaderItem__Row"/>
        <LoaderItemRow variant="rectangular" width={247} height={12} offset={4} className="LoaderItem__Row"/>
        <LoaderItemRow variant="rectangular" width={71} height={12} offset={0} className="LoaderItem__Row"/>
      </LoaderItemContent>
    </LoaderItemContainer>);
};
export default SkeletonItem;
