import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchShifts, ActionCreators, } from 'src/AdminApp/modules/shifts/actions';
import { selectShiftDashboard, selectDashboardPagination, selectDashboardParams, selectDashboardFetching, } from 'src/AdminApp/modules/shifts/selectors';
import { selectShiftEditor } from 'src/modules/auth/selectors';
const useShiftsDashboard = () => {
    var _a;
    const dispatch = useDispatch();
    const shifts = useSelector(selectShiftDashboard);
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    const isFetching = useSelector(selectDashboardFetching);
    const isShiftEditor = useSelector(selectShiftEditor);
    const [currentFilters, setCurrentFilters] = useState();
    const [editShiftDrawerOpen, setEditShiftDrawerOpen] = useState(false);
    const [pageSize, setPageSize] = useState((_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20);
    const [currentShiftId, setCurrentShiftId] = useState();
    useEffect(() => {
        var _a, _b, _c;
        dispatch(searchShifts(Object.assign(Object.assign({}, params), { pageSize: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20, pageIndex: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _b !== void 0 ? _b : 0, sort: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _c !== void 0 ? _c : ['status,asc', 'name,asc', 'modified,desc'] })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLoadData = ({ page, size, sort, }) => {
        dispatch(searchShifts(Object.assign(Object.assign(Object.assign({}, params), { pageSize: size, pageIndex: page, sort }), currentFilters)));
    };
    const onSearch = (filters) => {
        dispatch(searchShifts({ pageIndex: 0, pageSize, name: filters.query }));
        setCurrentFilters(filters);
    };
    const onClear = () => {
        dispatch(ActionCreators.SEARCH_SHIFTS.CLEAR());
        setPageSize(20);
    };
    const handleListItemClick = (id) => {
        setCurrentShiftId(id);
        setEditShiftDrawerOpen(true);
    };
    const handleEditShiftDrawerClose = (refresh) => {
        var _a, _b;
        setEditShiftDrawerOpen(false);
        if (!refresh)
            return;
        dispatch(searchShifts({
            pageSize,
            pageIndex: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _a !== void 0 ? _a : 0,
            name: currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.query,
            sort: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _b !== void 0 ? _b : ['status,asc', 'name,asc', 'modified,desc'],
        }));
    };
    const handleAddShift = () => {
        setCurrentShiftId(undefined);
        setEditShiftDrawerOpen(true);
    };
    return {
        shifts,
        pagination,
        params,
        isFetching,
        isShiftEditor,
        currentFilters,
        setCurrentFilters,
        editShiftDrawerOpen,
        setEditShiftDrawerOpen,
        pageSize,
        setPageSize,
        currentShiftId,
        setCurrentShiftId,
        onLoadData,
        onSearch,
        onClear,
        handleListItemClick,
        handleEditShiftDrawerClose,
        handleAddShift,
    };
};
export default useShiftsDashboard;
