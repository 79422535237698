import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'src/components/mui';
import { getTerritories, setWorkingVansOnly, } from 'src/AdminApp/modules/swimlanes/actions';
import { selectWorkingVansOnly } from 'src/AdminApp/modules/swimlanes/selectors';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import Section from '../Section/Section';
import RegionSelector from '../RegionSelector/RegionSelector';
import { StatusesContainer, StatusItem, StatusPill } from './Statuses.styles';
import { statuses } from '../constants';
import AutoRefreshToggle from '../AutoRefreshToggle';
const Statuses = () => {
    const dispatch = useDispatch();
    const workingVansOnly = useSelector(selectWorkingVansOnly);
    useEffect(() => {
        dispatch(getTerritories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<Section container style={{ alignItems: 'center' }}>
      <Grid item xs={6}>
        <StatusesContainer justifyContent="flex-start">
          {statuses.map((status, index) => (<StatusItem key={index}>
              <StatusPill color={status.color}/> {status.label}
            </StatusItem>))}
        </StatusesContainer>
      </Grid>
      <Grid item xs={6}>
        <StatusesContainer justifyContent="flex-end">
          <AutoRefreshToggle />
          <LabelledCheckbox label="Working Vans" labelPlacement="start" onChange={() => dispatch(setWorkingVansOnly(!workingVansOnly))} style={{
            fontSize: '12px',
            color: '#767676',
        }} checkboxProps={{
            checked: workingVansOnly,
            name: 'workingVans',
        }}/>
          <RegionSelector />
        </StatusesContainer>
      </Grid>
    </Section>);
};
export default Statuses;
