import React from 'react';
export const BuildIcon = () => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path d="M12.002 4.228c-1-1-2.75-1.389-5.252-1.168a.5.5 0 0 0-.31.848l2.341 2.34c.33.33.33.862 0 1.192L7.437 8.783a.827.827 0 0 1-1.192 0L3.911 6.45a.502.502 0 0 0-.854.31c-.215 2.492.177 4.236 1.176 5.235a5.503 5.503 0 0 0 5.825 1.25l6.73 6.728c.87.87 2.274.87 3.136 0 .87-.87.87-2.274 0-3.136l-6.696-6.701a5.498 5.498 0 0 0-1.226-5.908z" id="6rn00e0woa"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <use stroke="#3F3F3F" strokeWidth="1.5" strokeLinejoin="round" fillRule="nonzero" transform="matrix(-1 0 0 1 23.577 0)" xlinkHref="#6rn00e0woa"/>
      </g>
    </svg>);
};
