import React from 'react';
import SimpleChip from 'src/components/SimpleChip';
import { colors } from 'src/styles/theme';
import { Wrapper } from './PartStatus.styles';
const getChipColor = ({ status }) => {
    switch (status) {
        case 'PENDING':
        case 'NOT_PICKED_UP':
        case 'WRONG_PART':
            return colors.ORANGE;
        case 'ADDED':
        case 'ORDER_IN_PROGRESS':
            return colors.BLUE;
        case 'ORDERED':
        case 'PARTIALLY_ORDERED':
        case 'PICKED_UP':
            return colors.GREEN;
        case 'ORDER_FAILED':
            return colors.RED;
        default:
            return colors.black;
    }
};
const PartStatus = ({ status, className = '' }) => {
    const statusName = status === 'PENDING' ? 'NEEDED' : status.split('_').join(' ');
    return (<Wrapper>
      <SimpleChip $bgColor={getChipColor({ status })} $textColor={colors.white} className={className} label={statusName}/>
    </Wrapper>);
};
export default PartStatus;
