import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
import { Button } from 'src/components/mui';
export const SectionHeader = styled.h2 `
  font-family: ${FONT_FAMILY};
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.38px;
  color: ${colors.GRAY_3F};
  margin: 0;
  margin-top: 20px;
`;
export const RepairHeader = styled.div `
  display: flex;
  flex-direction: row;
`;
export const RepairName = styled.div `
  flex-grow: 1;
  font-size: 20px;
  font-weight: 750;
`;
export const RepairSubheader = styled.div `
  font-size: 12px;
  font-weight: 300;
  color: ${colors.GRAY_9C};
`;
export const HorizontalBorder = styled.div `
  width: 100%;
  height: 1px;
  opacity: 0.29;
  border: solid 0.5px ${colors.GRAY_97};
  margin-top: 20px;
`;
export const FieldWrapper = styled.div `
  margin-top: 18px;
`;
export const FooterButton = styled(Button) `
  margin-top: 5px;
  margin-bottom: 5px;
`;
