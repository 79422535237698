import moment from 'moment';
import CONFIG from 'src/config';
import { LOCALIZED_DATE_LONG_FORMAT, TIME_ZONE_FORMAT, } from 'src/timeConstants';
import templates from './templates';
const TIMESTAMP_FORMAT = 'YYYYMMDD-HHmm';
const DISPLAY_FORMAT = `${LOCALIZED_DATE_LONG_FORMAT} ${TIME_ZONE_FORMAT}`;
const DEV_ENVIRONMENTS = ['dev'];
const QA_ENVIRONMENTS = [
    'test',
    'test1',
    'test2',
    'test3',
    'test4',
    'test5',
    'staging',
];
const DOMAIN_TTLS_HOURS = {
    'ngrok.io': 2,
    'ngrok-free.app': 24 * 30, // static NGROK domains don't expire, but we'll flag as expired after 30 days to encourage cleanup of unused webhooks
};
export const generateTargets = () => {
    return CONFIG.APP_ENV === 'prod' ? ['prod'] : ['local', 'dev', 'qa'];
};
export const generateEnvironments = (appEnv) => {
    return appEnv === 'prod'
        ? ['prod']
        : ['local', ...DEV_ENVIRONMENTS, ...QA_ENVIRONMENTS];
};
export const extractMetadata = (webhook) => {
    const { name, url } = webhook;
    const matcher = name.match(/(.*):\s(.*)\s(\((.*)\))?/);
    const tokens = (matcher && matcher.slice(1)) || [];
    const environment = (tokens.length >= 1 && tokens[0]) || undefined;
    const type = (tokens.length >= 2 && tokens[1]) || undefined;
    const timestamp = (tokens.length >= 4 && tokens[3]) || undefined;
    const target = getTarget(environment);
    const localTimestamp = formatLocalTimestamp(timestamp);
    const expiredResults = checkExpiration(url, timestamp);
    const isInvalid = expiredResults.isExpired ||
        localTimestamp === 'Invalid date' ||
        localTimestamp === 'Unknown';
    return {
        target,
        environment,
        type,
        timestamp,
        localTimestamp,
        isInvalid,
        isExpired: expiredResults.isExpired,
        expiredHours: expiredResults.expiredHours,
        age: expiredResults.age,
    };
};
export const compareWebhooks = (webhook1, webhook2) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (((_b = (_a = webhook1.metadata) === null || _a === void 0 ? void 0 : _a.target) !== null && _b !== void 0 ? _b : 0) < ((_d = (_c = webhook2.metadata) === null || _c === void 0 ? void 0 : _c.target) !== null && _d !== void 0 ? _d : 0)) {
        return -1;
    }
    if (((_f = (_e = webhook1.metadata) === null || _e === void 0 ? void 0 : _e.target) !== null && _f !== void 0 ? _f : 0) > ((_h = (_g = webhook2.metadata) === null || _g === void 0 ? void 0 : _g.target) !== null && _h !== void 0 ? _h : 0)) {
        return 1;
    }
    return 0;
};
export const buildWebhook = ({ baseUrl, webhookType, environment, localMachineName, }) => {
    if (!webhookType || !templates[webhookType])
        return {};
    const template = templates[webhookType];
    const webhookEnv = environment === 'local' ? localMachineName : environment;
    const timestamp = formatCurrentTimestamp();
    return Object.assign(Object.assign({}, template), { name: formatName(webhookEnv, template.name, timestamp), url: `${baseUrl}${template.url}`, headers: {
            Authorization: 'Basic c2tlZHVsbzozMmE1ZGQ4YS03MWIzLTRmOTMtOWUzNy1iYWI1M2EwYzIxNzI=',
        } });
};
export const buildEnvironment = ({ baseUrl, environment, localMachineName, }) => {
    const webhookTypes = Object.keys(templates);
    const webhooksToCreate = [];
    webhookTypes.forEach((webhookType) => {
        const webhookToCreate = buildWebhook({
            baseUrl,
            webhookType,
            environment,
            localMachineName,
        });
        webhooksToCreate.push(webhookToCreate);
    });
    return webhooksToCreate;
};
export const getTarget = (environment) => {
    if (environment === 'prod') {
        return 'prod';
    }
    if (QA_ENVIRONMENTS.includes(environment)) {
        return 'qa';
    }
    if (DEV_ENVIRONMENTS.includes(environment)) {
        return 'dev';
    }
    return 'local';
};
export const getDefaultBaseUrl = (environment) => {
    const target = getTarget(environment);
    switch (target) {
        case 'prod':
            return 'https://api.autonationmobileservice.com';
        case 'qa':
        case 'dev':
            return `https://${environment}-api.autonationmobileservice.io`;
        default:
            return '';
    }
};
const checkExpiration = (url, timestamp) => {
    if (!timestamp)
        return { isExpired: false };
    const now = moment.utc();
    const created = moment.utc(timestamp, TIMESTAMP_FORMAT);
    const ageHours = moment.duration(now.diff(created)).asHours();
    const ttlDomain = Object.keys(DOMAIN_TTLS_HOURS).find((key) => url.includes(key));
    const expiredHours = ttlDomain
        ? ageHours - DOMAIN_TTLS_HOURS[ttlDomain]
        : undefined;
    const isExpired = (expiredHours && expiredHours > 0) || false;
    return {
        isExpired,
        expiredHours,
        age: created.fromNow(),
    };
};
const formatCurrentTimestamp = () => {
    return moment.utc().format(TIMESTAMP_FORMAT);
};
const formatLocalTimestamp = (timestamp, format = DISPLAY_FORMAT) => {
    if (!timestamp)
        return 'Unknown';
    return moment
        .utc(timestamp, TIMESTAMP_FORMAT)
        .tz(moment.tz.guess())
        .format(format);
};
const formatName = (environment, type, timestamp) => {
    return `${environment}: ${type} (${timestamp})`;
};
