var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cancelVisit, getVisit, toggleVisitLock, updateFleetVisitStatus, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { toast } from 'src/components/SimpleToast';
import { selectCancelApptsVisits, selectComponentMarkupEditor, } from 'src/modules/auth/selectors';
export const useVisitActions = ({ visit }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [visitActions, setVisitActions] = useState([]);
    const [openVisitDrawer, setOpenVisitDrawer] = useState(false);
    const [openVisitCancelConfirm, setOpenVisitCancelConfirm] = useState(false);
    const [openVisitStartConfirm, setOpenVisitStartConfirm] = useState(false);
    const canEditLock = useSelector(selectComponentMarkupEditor);
    const canCancelVisit = useSelector(selectCancelApptsVisits);
    useEffect(() => {
        if (!visit)
            return;
        if (visit.status === 'CANCELED') {
            setVisitActions([]);
            return;
        }
        const baseAction = {
            isCancelAction: false,
            hasBorderTop: false,
            disabled: false,
            function: () => {
                // nothing to see here
            },
            copy: '',
        };
        let mainAction;
        const notEditable = ['COMPLETED'].includes(visit.status);
        const notCancelable = ['IN_PROGRESS', 'COMPLETED'].includes(visit.status);
        switch (visit.status) {
            case 'PENDING':
                mainAction = Object.assign(Object.assign({}, baseAction), { function: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield dispatch(updateFleetVisitStatus({ refNum: visit.refNum, step: 'confirm' }));
                        yield dispatch(getVisit({ refNum: visit.refNum }));
                    }), copy: 'Confirm Visit', key: 'confirm_visit' });
                break;
            case 'CONFIRMED':
                mainAction = Object.assign(Object.assign({}, baseAction), { function: () => setOpenVisitStartConfirm(true), copy: 'Start Visit', key: 'start_visit' });
                break;
            case 'IN_PROGRESS':
                {
                    const notClosedRequests = visit.workOrders.filter((wo) => wo.repairOrderStatus !== 'CLOSED' && wo.status === 'ACTIVE');
                    mainAction = Object.assign(Object.assign({}, baseAction), { function: () => __awaiter(void 0, void 0, void 0, function* () {
                            yield dispatch(updateFleetVisitStatus({
                                refNum: visit.refNum,
                                step: 'complete',
                            }));
                            yield dispatch(getVisit({ refNum: visit.refNum }));
                        }), copy: 'Complete Visit', key: 'complete_visit', tooltip: notClosedRequests.length > 0
                            ? 'Complete or remove open ROs to complete the visit'
                            : '', disabled: notClosedRequests.length > 0 });
                }
                break;
            case 'COMPLETED':
                mainAction = Object.assign(Object.assign({}, baseAction), { function: () => {
                        // nothing to see here
                    }, copy: 'Completed', key: 'completed', disabled: true });
                break;
            default:
                mainAction = Object.assign({}, baseAction);
                break;
        }
        const secondaryAction = Object.assign(Object.assign({}, baseAction), { key: 'menuActions', button: 'menu', showInfoLabel: notCancelable, infoLabelText: 'Visits that are In Progress or Completed cannot be canceled', actions: [
                {
                    key: 'editVisit',
                    copy: 'Edit Visit',
                    disabled: notEditable,
                    action: () => {
                        setOpenVisitDrawer(true);
                    },
                },
            ] });
        if (!secondaryAction.actions)
            secondaryAction.actions = [];
        if (canEditLock) {
            if (!visit.locked) {
                secondaryAction.actions.push(Object.assign(Object.assign({}, baseAction), { key: 'lockVisit', copy: 'Lock Visit', disabled: notEditable, action: () => __awaiter(void 0, void 0, void 0, function* () {
                        const response = yield dispatch(toggleVisitLock({
                            visitRefNum: visit.refNum,
                            lock: true,
                        }));
                        if (!response.error) {
                            toast.success('Visit locked successfully');
                        }
                    }) }));
            }
            else {
                secondaryAction.actions.push({
                    key: 'unlockVisit',
                    copy: 'Unlock Visit',
                    disabled: notEditable,
                    action: () => __awaiter(void 0, void 0, void 0, function* () {
                        const response = yield dispatch(toggleVisitLock({
                            visitRefNum: visit.refNum,
                            lock: false,
                        }));
                        if (!response.error) {
                            toast.success('Visit unlocked successfully');
                        }
                    }),
                });
            }
        }
        if (canCancelVisit) {
            secondaryAction.actions.push({
                key: 'cancelVisit',
                copy: 'Cancel Visit',
                disabled: notCancelable,
                action: () => {
                    setOpenVisitCancelConfirm(true);
                },
            });
        }
        setVisitActions([mainAction, secondaryAction]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visit]);
    const handleStartVisit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!visit) {
            handleVisitStartConfirmClose();
            return;
        }
        yield dispatch(updateFleetVisitStatus({ refNum: visit.refNum, step: 'start' }));
        yield dispatch(getVisit({ refNum: visit.refNum }));
        handleVisitStartConfirmClose();
    });
    const handleVisitDrawerClose = () => {
        if (!visit)
            return;
        setOpenVisitDrawer(false);
        dispatch(getVisit({ refNum: visit.refNum }));
    };
    const handleVisitCancelConfirmClose = () => {
        setOpenVisitCancelConfirm(false);
    };
    const handleVisitStartConfirmClose = () => {
        setOpenVisitStartConfirm(false);
    };
    const handleCancelVisit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!visit)
            return;
        yield dispatch(cancelVisit({ refNum: visit.refNum }));
        toast.success('Visit canceled successfully');
        handleVisitCancelConfirmClose();
        history.push('/admin/visits');
    });
    return {
        visitActions,
        openVisitDrawer,
        handleVisitDrawerClose,
        openVisitCancelConfirm,
        handleVisitCancelConfirmClose,
        handleCancelVisit,
        setOpenVisitDrawer,
        handleVisitStartConfirmClose,
        handleStartVisit,
        openVisitStartConfirm,
        setOpenVisitStartConfirm
    };
};
