import createDefaultActionCreators from 'src/utils/createActions';
import createAction from 'src/modules/api/utils/createAction';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/AdminApp/modules/api/urls';
// FSA ACTIONS
export const ActionCreators = {
    SEARCH_SHIFTS: createDefaultActionCreators('SEARCH_SHIFTS', ['CLEAR']),
    GET_SHIFT: createDefaultActionCreators('GET_SHIFT', ['CLEAR']),
    SAVE_SHIFT: createDefaultActionCreators('SAVE_SHIFT'),
    UPDATE_SHIFT: createDefaultActionCreators('UPDATE_SHIFT'),
};
export const setShift = createAction('SET_SHIFT');
export const setEditedShiftWindow = createAction('SET_EDIT_SHIFT_WINDOW');
export const clearShiftWindow = createAction('CLEAR_SHIFT_WINDOW');
export const searchShifts = ({ pageSize, pageIndex, name = null, status = null, sort = ['name,asc'], }) => (dispatch) => {
    const params = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort })), (!!name && { name })), (!!status && { status }));
    return dispatch(createAPIAction({
        types: [
            ActionCreators.SEARCH_SHIFTS.FETCH({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.SEARCH_SHIFTS.SUCCESS({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.SEARCH_SHIFTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SHIFTS(),
        params,
        method: 'GET',
    }));
};
export const getShift = (id) => (dispatch) => {
    return dispatch(createAPIAction({
        types: [
            ActionCreators.GET_SHIFT.FETCH({
                meta: () => ({ id }),
            }),
            ActionCreators.GET_SHIFT.SUCCESS({
                meta: () => ({ id }),
            }),
            ActionCreators.SEARCH_SHIFTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SHIFT_BY_ID(id),
        method: 'GET',
    }));
};
export const saveShift = ({ shift }) => {
    return createAPIAction({
        types: [
            ActionCreators.SAVE_SHIFT.FETCH(),
            ActionCreators.SAVE_SHIFT.SUCCESS({ meta: () => ({ shift }) }),
            ActionCreators.SAVE_SHIFT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SHIFTS(),
        method: 'POST',
        body: Object.assign({}, shift),
    });
};
export const updateShift = ({ shift }) => {
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_SHIFT.FETCH(),
            ActionCreators.UPDATE_SHIFT.SUCCESS({ meta: () => ({ shift }) }),
            ActionCreators.UPDATE_SHIFT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SHIFT_BY_ID(shift.id),
        method: 'PATCH',
        body: Object.assign({}, shift),
    });
};
export const clearShift = ActionCreators.GET_SHIFT.CLEAR;
