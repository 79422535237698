var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CircularProgress, Divider } from 'src/components/mui';
import { Error as ErrorIcon } from 'src/components/mui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddNotesDrawerForm from 'src/AdminApp/containers/requests/Notes/AddNotesDrawer/AddNotesDrawerForm';
import { NotesTypes } from 'src/AdminApp/containers/requests/Notes/constants';
import { LoaderWrapper as NotesLoaderWrapper, NotesList, } from 'src/AdminApp/containers/requests/Notes/Notes.styles';
import NotesItem from 'src/AdminApp/containers/requests/Notes/NotesItem';
import NotesTable from 'src/AdminApp/containers/requests/Notes/NotesTable/NotesTable';
import useNotes from 'src/AdminApp/containers/requests/Notes/useNotes';
import { getFleet } from 'src/AdminApp/modules/fleets/actions';
import { getRequest } from 'src/AdminApp/modules/requests/actions';
import { selectFleet, selectVehicleByRefNum, } from 'src/AdminApp/modules/requests/selectors';
import { SimpleDrawer, SimpleDrawerContent } from 'src/components/SimpleDrawer';
import useIsMobile from 'src/hooks/useIsMobile';
import { NewComponentButton, StyledAddCircleIcon, } from 'src/PopsApp/pages/Order/Order.styles';
import { colors } from 'src/styles/theme';
const ErrorIconStyle = {
    color: colors.error,
    fontSize: '30px',
};
const OrderNotesDrawer = ({ repairRequestId, referenceNum, highlightNotes = false, showIcon = true, notesQuantity = undefined, overrideStyles = {}, swimlanes = false, }) => {
    var _a, _b, _c;
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const [fleetId, setFleetId] = useState();
    const fleet = useSelector(selectFleet({ fleetId: fleetId !== null && fleetId !== void 0 ? fleetId : '' }));
    const { notes, notesAppliesTo, notesAppliesToRecommendedRepairs, fetchNotesAppliesTo, fetchNotesAppliesToRecommendedRepairs, onLoadData, pagination, notesLoading, } = useNotes(repairRequestId);
    const notesAppliesToCombined = [
        ...notesAppliesTo,
        ...notesAppliesToRecommendedRepairs,
    ];
    useEffect(() => {
        if (fleetId) {
            (() => __awaiter(void 0, void 0, void 0, function* () {
                yield dispatch(getFleet(fleetId));
            }))();
        }
        return () => setFleetId(undefined);
    }, [fleetId]); // eslint-disable-line
    useEffect(() => {
        if (repairRequestId && open) {
            (() => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c, _d, _e, _f;
                if (swimlanes && !open) {
                    yield Promise.all([
                        fetchNotesAppliesTo(),
                        onLoadData({
                            page: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _a !== void 0 ? _a : 0,
                            size: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _b !== void 0 ? _b : 10,
                            sort: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _c !== void 0 ? _c : ['created,desc'],
                        }),
                    ]);
                }
                else {
                    const data = yield dispatch(getRequest(referenceNum, repairRequestId));
                    const { payload, error } = data !== null && data !== void 0 ? data : {};
                    if (payload && !error && (payload === null || payload === void 0 ? void 0 : payload.fleetId)) {
                        setFleetId(payload === null || payload === void 0 ? void 0 : payload.fleetId);
                    }
                    yield Promise.all([
                        fetchNotesAppliesTo(),
                        fetchNotesAppliesToRecommendedRepairs(),
                        onLoadData({
                            page: (_d = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _d !== void 0 ? _d : 0,
                            size: (_e = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _e !== void 0 ? _e : 10,
                            sort: (_f = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _f !== void 0 ? _f : ['created,desc'],
                        }),
                    ]);
                }
            }))();
        }
    }, [repairRequestId, open]); // eslint-disable-line
    const car = useSelector(selectVehicleByRefNum(referenceNum));
    const fmc = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) === null || _a === void 0 ? void 0 : _a.filter((fmc) => { var _a; return (_a = fmc === null || fmc === void 0 ? void 0 : fmc.cars) === null || _a === void 0 ? void 0 : _a.find((fmcCar) => fmcCar.id === car.consumerCarId); });
    const isAutoIntegrateFMC = (fmc === null || fmc === void 0 ? void 0 : fmc.length) === 1 && ((_c = (_b = fmc[0]) === null || _b === void 0 ? void 0 : _b.fmcDto) === null || _c === void 0 ? void 0 : _c.submissionMethod) === 'AUTO_INTEGRATE';
    if (!repairRequestId)
        return null;
    return (<>
      <NewComponentButton style={Object.assign({}, overrideStyles)} onClick={() => setOpen(true)}>
        {showIcon && <StyledAddCircleIcon />}
        Notes ({notesQuantity !== null && notesQuantity !== void 0 ? notesQuantity : 0})
        {highlightNotes && (<ErrorIcon style={ErrorIconStyle} fontSize="large"/>)}
      </NewComponentButton>
      <SimpleDrawer open={open} onClose={() => setOpen(false)} size="twoThirds" title="Notes">
        <SimpleDrawerContent>
          {notesLoading && (<NotesLoaderWrapper>
              <CircularProgress />
            </NotesLoaderWrapper>)}
          {!notesLoading && isMobile ? (<NotesList>
              {notes.map((note) => (<NotesItem key={note.id} note={note} notesAppliesTo={notesAppliesToCombined}/>))}
            </NotesList>) : (<NotesTable repairRequestId={repairRequestId}/>)}
          <Divider />
          <AddNotesDrawerForm type={NotesTypes.INTERNAL} repairRequestId={repairRequestId} onClose={() => setOpen(false)} isAutoIntegrateFMC={isAutoIntegrateFMC}/>
        </SimpleDrawerContent>
      </SimpleDrawer>
    </>);
};
export default OrderNotesDrawer;
