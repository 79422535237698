var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable class-methods-use-this */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Grid, Typography } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
import { Toggle } from 'src/AdminApp/containers/fleets/tabs/styles';
import FilterButtons from 'src/components/dashboard/DashboardFilter/FilterButtons';
import DashboardDatePicker from 'src/components/dashboard/DashboardFilter/DatePicker';
import FilterBlock from 'src/components/dashboard/DashboardFilter/FilterBlock';
import { colors } from 'src/styles/theme';
const ToggleTitle = styled(Typography) `
  display: inline-block;
  align-self: flex-end;
  margin-right: 10px;
  font-weight: 500;
  font-size: 0.8rem;
  color: ${colors.GRAY_76};
`;
const initialState = {
    createdTo: undefined,
    createdFrom: undefined,
    id: '',
    query: '',
    disabled: false,
};
const DashboardFilters = ({ onSearch, onClear }) => {
    const [formValues, setFormValues] = useState(Object.assign({}, initialState));
    const onDateChange = (field) => (date) => {
        setFormValues(Object.assign(Object.assign({}, formValues), { [field]: date.local() }));
    };
    const resetFilter = () => {
        setFormValues(Object.assign({}, initialState));
    };
    const clearFilter = () => {
        resetFilter();
        onClear();
    };
    const { createdFrom, createdTo } = formValues;
    const [disabled, setDisabled] = useState(false);
    const handleToggle = (_e, checked) => __awaiter(void 0, void 0, void 0, function* () {
        setDisabled(checked);
    });
    const handleSubmit = (query, rest) => {
        var _a;
        const values = Object.assign(Object.assign({ query: query.trim() }, rest), { createdTo: createdTo !== null && createdTo !== void 0 ? createdTo : undefined, createdFrom: createdFrom !== null && createdFrom !== void 0 ? createdFrom : undefined, id: (_a = rest.id) !== null && _a !== void 0 ? _a : undefined, disabled });
        onSearch(values);
    };
    return (<Formik initialValues={initialState} onSubmit={(_a, _b) => {
            var { query } = _a, rest = __rest(_a, ["query"]);
            var setSubmitting = _b.setSubmitting;
            handleSubmit(query, rest);
            setSubmitting(false);
        }}>
      {({ isSubmitting, resetForm }) => (<Form>
          <FilterBlock>
            <Grid item xs={12} md={4}>
              <DashboardDatePicker label="Created Date From" value={createdFrom} onChange={onDateChange('createdFrom')}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <DashboardDatePicker label="Created Date To" value={createdTo} onChange={onDateChange('createdTo')}/>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField label="ID" name="id" autoFocus fullWidth type="number" variant="standard"/>
            </Grid>
            <Grid item xs={12} md={4}>
              <ToggleTitle variant="body1">Include Disabled Users</ToggleTitle>
              <Toggle color="primary" name="disabled" onChange={handleToggle}/>
            </Grid>
          </FilterBlock>

          <FilterButtons onClear={clearFilter} onReset={() => {
                resetFilter();
                resetForm();
            }} queryLabelText="Search Query (by name, email, phone number)" isSubmitting={isSubmitting}/>
        </Form>)}
    </Formik>);
};
export default DashboardFilters;
