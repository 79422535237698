var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from 'react-redux';
import { createMarketingSource, updateMarketingSource, getMarketingSource, } from 'src/AdminApp/modules/marketingSources/actions';
import { useEffect, useState } from 'react';
import { toast } from 'src/components/SimpleToast';
const useMarketingSourcesDrawer = ({ marketingSourceId, onClose, open, }) => {
    const dispatch = useDispatch();
    const [marketingSource, setMarketingSource] = useState();
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const populateMarketingSource = (marketingSourceId) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const response = yield dispatch(getMarketingSource(marketingSourceId));
        const result = response.payload;
        setMarketingSource(result);
        setLoading(false);
    });
    useEffect(() => {
        if (!open)
            return;
        setMarketingSource(undefined);
        if (marketingSourceId) {
            populateMarketingSource(marketingSourceId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketingSourceId, open]);
    const handleChange = (key, value) => {
        setMarketingSource(Object.assign(Object.assign({}, marketingSource), { [key]: value }));
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsSubmitting(true);
        const result = Object.assign({}, marketingSource);
        result.marketingName = ((_a = result.displayName) !== null && _a !== void 0 ? _a : '')
            .toLowerCase()
            .replace(/\s/g, '_')
            .replace(/\W/g, '_');
        if (marketingSourceId) {
            yield dispatch(updateMarketingSource(result));
        }
        else {
            yield dispatch(createMarketingSource(result));
        }
        toast.success('Marketing source successfully saved.');
        setMarketingSource(undefined);
        onClose();
        setIsSubmitting(false);
    });
    return {
        handleChange,
        handleSubmit,
        marketingSource,
        loading,
        setMarketingSource,
        isSubmitting,
    };
};
export default useMarketingSourcesDrawer;
