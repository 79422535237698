import React from 'react';
import { HelperIcon, Row, EmailWrapper, UserWrapper, } from 'src/AdminApp/containers/requests/ContactInfo/ContactInfo.styles';
import { formatPhone } from 'src/utils/formatter';
import { CopyToClipboardButton } from 'src/components/buttons';
import EditableInput from 'src/components/form/EditableInput';
const MobileContactInfo = ({ email, initialValues, canEdit, onUpdateContact, fleet, fleetContactInfo, }) => {
    var _a, _b, _c, _d;
    if (fleet) {
        return (<>
        <Row>
          <HelperIcon>person</HelperIcon>
          <UserWrapper>
            {fleetContactInfo
                ? fleetContactInfo.dailyOpsContactName
                : fleet.contactName}
          </UserWrapper>
          <CopyToClipboardButton value={fleetContactInfo
                ? (_a = fleetContactInfo.dailyOpsContactName) !== null && _a !== void 0 ? _a : ''
                : fleet.contactName} style={{ marginLeft: '-6px' }}/>
        </Row>
        <Row>
          <HelperIcon>phone</HelperIcon>
          <div>
            {formatPhone(fleetContactInfo
                ? (_b = fleetContactInfo.dailyOpsContactPhone) !== null && _b !== void 0 ? _b : ''
                : fleet.phone)}
          </div>
          <CopyToClipboardButton value={fleetContactInfo
                ? (_c = fleetContactInfo.dailyOpsContactPhone) !== null && _c !== void 0 ? _c : ''
                : fleet.phone} style={{ marginLeft: '-6px' }}/>
        </Row>
        <Row>
          <HelperIcon>alternate_email</HelperIcon>
          <EmailWrapper>
            {fleetContactInfo
                ? fleetContactInfo.dailyOpsContactEmail
                : fleet.email}
          </EmailWrapper>
          <CopyToClipboardButton value={fleetContactInfo
                ? (_d = fleetContactInfo.dailyOpsContactEmail) !== null && _d !== void 0 ? _d : ''
                : fleet.email} style={{ marginLeft: '-6px' }}/>
        </Row>
      </>);
    }
    return (<>
      <Row>
        <HelperIcon>alternate_email</HelperIcon>
        <EmailWrapper>{email}</EmailWrapper>
        <CopyToClipboardButton value={email} style={{ marginLeft: '-6px' }}/>
      </Row>
      <Row>
        <HelperIcon>phone</HelperIcon>
        <EditableInput value={initialValues.phone} displayValue={formatPhone(initialValues.phone)} defaultValue="-" onUpdate={onUpdateContact('phone')} isEditable={canEdit}/>
        <CopyToClipboardButton value={initialValues.phone} style={{ marginLeft: '-6px' }}/>
      </Row>
    </>);
};
export default MobileContactInfo;
