import React from 'react';
import PartStatusChip, { PartPickupChip, } from 'src/AdminApp/components/repairs/statement/Elements/PartStatusChip';
import { Bullet, PartSecondaryInfo, StyledEditIcon, Title, } from 'src/AdminApp/components/repairs/statement/styledComponents';
import CurrencyLabel from 'src/components/CurrencyLabel';
import { SimpleChips } from 'src/components/SimpleChip';
import styled from 'styled-components';
export const FitmentsList = styled.ul `
  margin-top: 16px;
  list-style: none;
  padding-left: 32px;

  li {
    margin-top: 8px;
  }
`;
const PartFluidFitmentsDisplay = ({ part, fluid, fitments, onEdit = () => { }, canEdit = false, requestPartPickups, }) => {
    return (fitments === null || fitments === void 0 ? void 0 : fitments.length) === 0 ? null : (<FitmentsList>
      {fitments === null || fitments === void 0 ? void 0 : fitments.map((fitment) => {
            const partPickups = (requestPartPickups || []).filter((item) => item.items.some((component) => component.fitmentId === fitment.id));
            const item = {
                id: fitment.id,
                name: fitment.partName,
                cost: fitment.cost,
                partNumber: fitment.partNumber,
                capacity: fluid === null || fluid === void 0 ? void 0 : fluid.capacity,
                capacityUnit: fluid === null || fluid === void 0 ? void 0 : fluid.capacityUnit,
                capacityUnitAbbreviation: fluid === null || fluid === void 0 ? void 0 : fluid.capacityUnitAbbreviation,
                type: fluid === null || fluid === void 0 ? void 0 : fluid.type,
                quantity: 1,
                status: fitment.status,
                isFitment: true,
                componentPartDistributor: Object.assign({}, fitment.partDistributor),
                additionalInfo: fitment.additionalInfo,
            };
            return (<li key={fitment.id}>
            <Title>
              {fitment.partName}{' '}
              {onEdit && canEdit && (<StyledEditIcon onClick={() => {
                        if (part) {
                            onEdit(Object.assign(Object.assign({}, item), { price: part.price }));
                        }
                        else {
                            onEdit(Object.assign(Object.assign({}, item), { price: fluid === null || fluid === void 0 ? void 0 : fluid.price }));
                        }
                    }}/>)}
            </Title>

            <PartSecondaryInfo>
              Cost: <CurrencyLabel>{fitment.cost}</CurrencyLabel>
              <Bullet> • </Bullet> {fitment.partNumber}
            </PartSecondaryInfo>
            <SimpleChips>
              {partPickups.map((ppu) => (<PartPickupChip key={ppu.id} referenceNum={ppu.referenceNum}/>))}
              <PartStatusChip status={fitment.status}/>
            </SimpleChips>
          </li>);
        })}
    </FitmentsList>);
};
export default PartFluidFitmentsDisplay;
