import styled from 'styled-components';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
import { Button } from 'src/components/mui';
export const PartDrawerContainer = styled.div `
  padding: 24px;
  position: relative;
  flex: 1;
  cursor: pointer;
`;
export const PartDrawerActionBox = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.GRAY_F2};
  border-top: 1px solid ${colors.GRAY_DE};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
`;
export const PartDrawerActionButton = styled(Button) `
  width: 192px;
  background-color: ${colors.BLUE};
  color: ${colors.white};
`;
export const PickPartItemContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 24px 16px;
  border-bottom: solid 1px ${colors.GRAY_DE};
`;
export const PartImage = styled.img `
  width: 64px;
  height: 64px;
  margin-right: 8px;
`;
export const PartContent = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const PartTitle = styled.div `
  color: ${colors.black};
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 4px;
`;
export const PartDetails = styled.p `
  font-size: 12px;
  color: ${colors.GRAY_76};
  line-height: 1.33;
  margin: 0;
  padding: 0;
`;
export const NotesTitle = styled.h6 `
  font-size: 12px;
  font-weight: 700;
  color: ${colors.GRAY_76};
  padding: 0;
  margin: 16px 0 4px;
`;
export const PartNotes = styled.p `
  font-size: 12px;
  color: ${colors.GRAY_76};
  line-height: 1.33;
  padding: 0;
  margin: 0;
`;
export const MoreButton = styled.button `
  background: none;
  border: none;
  color: ${colors.GRAY_76};
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
`;
export const ShowAllPartsContainer = styled.div `
  display: flex;
  justify-content: flex-end;
`;
export const ShowAllPartsButton = styled.button `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: none;
  border: none;
  color: ${colors.GRAY_76};
  cursor: pointer;
  margin-top: 18px;
  font-size: 12px;
  font-weight: 700;

  .itemCollapseIcon {
    margin-top: -6px;
    width: 24px;
    height: 24px;
    background-image: url(${STATIC_URL_BASE}pops/icons/chevron-down.svg);
    font-size: 12px;
  }
`;
