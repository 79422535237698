var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { colors } from 'src/styles/theme';
import { getFleetInstructions, getTechnicianTasksByReferenceNums, getVisit, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { getFleet } from 'src/AdminApp/modules/fleets/actions';
import { loadRequest, cancelROVisitAppointment, } from 'src/AdminApp/modules/requests/actions';
import { getInspectionByRepairRequestId } from 'src/AdminApp/modules/mpi/actions';
import { selectFleetVisit, selectTechnicianTasksByReferenceNums, } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { selectRepairRequestWithReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { LOCALIZED_DATE_LONG_FORMAT } from 'src/timeConstants';
export const useVisitView = () => {
    const dispatch = useDispatch();
    const { refNum } = useParams();
    const [speedDialOpen, setSpeedDialOpen] = useState(false);
    const [addExistingDrawerOpen, setAddExistingDrawerOpen] = useState(false);
    const [isCancelDrawerOpen, setIsCancelDrawerOpen] = useState(false);
    const [workOrderToRemove, setWorkOrderToRemove] = useState();
    const [isAddROOpen, setIsAddROOpen] = useState(false);
    const [openROCancelConfirm, setOpenROCancelConfirm] = useState(false);
    const visit = useSelector(selectFleetVisit);
    const repairRequest = useSelector((state) => {
        var _a;
        return selectRepairRequestWithReferenceNum(state, (_a = workOrderToRemove === null || workOrderToRemove === void 0 ? void 0 : workOrderToRemove.repairRequestRefNum) !== null && _a !== void 0 ? _a : '');
    });
    const technicianTasksByReferenceNums = useSelector(selectTechnicianTasksByReferenceNums);
    useEffect(() => {
        dispatch(getVisit({ refNum }));
        dispatch(getFleetInstructions({ entityId: refNum }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        var _a, _b;
        if (!visit)
            return;
        const orders = (visit === null || visit === void 0 ? void 0 : visit.workOrders.filter((item) => item.repairOrderId !== null)) || null;
        if (orders) {
            visit.workOrders = orders;
        }
        if (((_a = visit === null || visit === void 0 ? void 0 : visit.workOrders) !== null && _a !== void 0 ? _a : []).length > 0) {
            const repairRequestReferenceNums = visit.workOrders.map((workOrder) => workOrder.repairRequestRefNum);
            dispatch(getTechnicianTasksByReferenceNums({
                repairRequestReferenceNums: repairRequestReferenceNums.join(','),
            }));
        }
        if (((_b = visit === null || visit === void 0 ? void 0 : visit.fleets) === null || _b === void 0 ? void 0 : _b.length) !== 1)
            return;
        dispatch(getFleet(visit.fleets[0].fleetId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visit]);
    const handleSpeedDialOpen = () => {
        setSpeedDialOpen(true);
    };
    const handleSpeedDialClose = () => {
        setSpeedDialOpen(false);
        setOpenROCancelConfirm(false);
    };
    const handleAddRepairOrder = (type) => {
        if (type === 'EXISTING') {
            setAddExistingDrawerOpen(true);
        }
        else {
            setIsAddROOpen(true);
        }
        handleSpeedDialClose();
    };
    const handleWorkOrderRemove = (workOrder) => __awaiter(void 0, void 0, void 0, function* () {
        if (!visit)
            return;
        const requestResponse = yield dispatch(loadRequest(workOrder.repairRequestRefNum));
        yield dispatch(getInspectionByRepairRequestId(requestResponse === null || requestResponse === void 0 ? void 0 : requestResponse.id));
        setWorkOrderToRemove(workOrder);
        if (visit.status === 'PENDING' || visit.status === 'IN_PROGRESS') {
            setOpenROCancelConfirm(true);
        }
        else {
            setIsCancelDrawerOpen(true);
        }
    });
    const handleROCancelConfirmClose = () => {
        setOpenROCancelConfirm(false);
    };
    const handleRemoveROConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!visit)
            return;
        if (visit.status === 'IN_PROGRESS') {
            handleROCancelConfirmClose();
            setIsCancelDrawerOpen(true);
        }
        else if (workOrderToRemove) {
            yield dispatch(cancelROVisitAppointment(workOrderToRemove === null || workOrderToRemove === void 0 ? void 0 : workOrderToRemove.repairRequestRefNum, workOrderToRemove === null || workOrderToRemove === void 0 ? void 0 : workOrderToRemove.id, refNum, null));
            handleROCancelConfirmClose();
            dispatch(getVisit({ refNum }));
        }
    });
    const handleCloseDrawers = () => {
        setAddExistingDrawerOpen(false);
        setIsCancelDrawerOpen(false);
        setIsAddROOpen(false);
        setOpenROCancelConfirm(false);
        setWorkOrderToRemove(undefined);
        dispatch(getVisit({ refNum }));
    };
    let fabBgColor = speedDialOpen ? colors.TOOLTIP : colors.BLUE;
    if ((visit === null || visit === void 0 ? void 0 : visit.status) === 'COMPLETED') {
        fabBgColor = colors.GRAY_AA;
    }
    const filterWorkOrder = (visit === null || visit === void 0 ? void 0 : visit.workOrders.filter((item) => item.repairOrderId !== null)) || [];
    if (visit) {
        visit.workOrders = filterWorkOrder;
    }
    const currentTimezone = moment.tz.guess();
    const lockedAtDate = moment(`${visit === null || visit === void 0 ? void 0 : visit.lockedAt}Z`).local();
    const lockedAtDateFormatted = `${lockedAtDate.format(LOCALIZED_DATE_LONG_FORMAT)} ${moment
        .tz(currentTimezone)
        .zoneAbbr()}`;
    return {
        dispatch,
        refNum,
        speedDialOpen,
        setSpeedDialOpen,
        addExistingDrawerOpen,
        setAddExistingDrawerOpen,
        isCancelDrawerOpen,
        setIsCancelDrawerOpen,
        workOrderToRemove,
        setWorkOrderToRemove,
        isAddROOpen,
        setIsAddROOpen,
        openROCancelConfirm,
        setOpenROCancelConfirm,
        visit,
        repairRequest,
        technicianTasksByReferenceNums,
        handleSpeedDialOpen,
        handleSpeedDialClose,
        handleAddRepairOrder,
        handleWorkOrderRemove,
        handleROCancelConfirmClose,
        handleRemoveROConfirm,
        handleCloseDrawers,
        fabBgColor,
        lockedAtDateFormatted,
    };
};
export default useVisitView;
