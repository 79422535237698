import { colors, FONT_FAMILY } from 'src/styles/theme';
import styled from 'styled-components';
export const AppointmentList = styled.ul `
  margin-top: 24px;
  padding-left: 0;

  .Appointment {
    list-style: none;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid ${colors.GRAY_EE};
    width: 100%;

    &__main {
      display: flex;
      width: 100%;

      &__time {
        font-family: ${FONT_FAMILY};
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -0.3px;
        color: ${colors.GRAY_3F};
      }
    }

    &__address {
      margin-top: 4px;
      font-family: ${FONT_FAMILY};
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.1px;
      color: ${colors.GRAY_76};
    }

    &__repairInfo {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      overflow: none;

      &__car {
        font-family: ${FONT_FAMILY};
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.1px;
        margin-bottom: 4px;
      }

      &__repairs {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.1px;
        color: ${colors.GRAY_76};
      }
    }
  }
`;
