import { Formik } from 'formik';
import pluralize from 'pluralize';
import React from 'react';
import { TextField } from 'src/AdminApp/components/form';
import useInvoiceSendModal from 'src/AdminApp/pages/invoiceWorkbench/components/InvoiceSendModal/useInvoiceSendModal';
import { ActionButton, SecondaryButton } from 'src/components/buttons';
import { Grid, Typography } from 'src/components/mui';
import { DrawerFormRow, FooterActionButton, SubHeader, SubText, } from 'src/components/SimpleDrawer';
import SimpleModal from 'src/components/SimpleModal';
import { SimpleDialogActions } from 'src/components/SimpleModal/SimpleModal.styles';
import { colors } from 'src/styles/theme';
import { emailListValidation } from 'src/utils/email-list-validation';
import styled from 'styled-components';
import * as Yup from 'yup';
const MoreInfo = styled.div `
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.09px;
  color: ${colors.darkGrey};
  margin-top: 16px;
  margin-bottom: 32px;
`;
const FormLabel = styled(Grid) `
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.11px;
  color: ${colors.darkGrey};
`;
const FormValue = styled(Grid) `
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: ${colors.deepDarkGrey};
  margin-left: 16px;
`;
const EXTERNAL_SUB_PORTAL_SCHEMA = Yup.object().shape({
    billingContactsString: Yup.string().nullable(),
});
const EMAIL_SCHEMA = Yup.object().shape({
    billingContactsString: Yup.string().required('At least one email is required'),
    comments: Yup.string().trim().max(500).required('Comments are required'),
});
const InvoiceSendModal = ({ open, onClose }) => {
    var _a, _b;
    const { currentResponsibleParty, sendAndNext, showFinalScreen, handleFinalScreenClose, itemsSelectedLength, currentResponsiblePartyIndex, responsiblePartiesLength, currentGroupLength, toggleShowEmail, showEmail, isExternalSubmissionPortal, defaultEmailBody, } = useInvoiceSendModal({
        open,
        onClose,
    });
    if (!currentResponsibleParty)
        return null;
    console.info('currentResponsibleParty', currentResponsibleParty);
    let invoiceBatchText = `Invoice Batch (${currentResponsiblePartyIndex + 1} of ${responsiblePartiesLength})`;
    if (responsiblePartiesLength === 1) {
        invoiceBatchText = 'Invoice Batch';
    }
    return (<SimpleModal open={open} onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
                handleFinalScreenClose(false);
            }
        }} title={showFinalScreen ? 'Your invoices are in process' : invoiceBatchText} disableEscapeKeyDown maxWidth="md">
      {showFinalScreen && (<section>
          You've submitted {itemsSelectedLength}{' '}
          {pluralize('item', itemsSelectedLength)} successfully for processing
          <div style={{ textAlign: 'right', marginTop: '32px' }}>
            <ActionButton onClick={() => handleFinalScreenClose()}>
              OK
            </ActionButton>
          </div>
        </section>)}

      {!showFinalScreen && (<>
          <SubHeader>{currentResponsibleParty.name}</SubHeader>
          <SubText>
            {currentGroupLength} {pluralize('item', currentGroupLength)}{' '}
            selected
          </SubText>

          <Formik enableReinitialize validateOnMount validationSchema={isExternalSubmissionPortal
                ? EXTERNAL_SUB_PORTAL_SCHEMA
                : EMAIL_SCHEMA} initialValues={{
                billingContactsString: (_b = (_a = currentResponsibleParty === null || currentResponsibleParty === void 0 ? void 0 : currentResponsibleParty.billingContacts) === null || _a === void 0 ? void 0 : _a.join(', ')) !== null && _b !== void 0 ? _b : '',
                comments: defaultEmailBody !== null && defaultEmailBody !== void 0 ? defaultEmailBody : '',
            }} onSubmit={sendAndNext}>
            {({ values, isValid, isSubmitting, errors, touched, handleSubmit, resetForm, setFieldValue, }) => {
                return (<>
                  {isExternalSubmissionPortal && (<>
                      <MoreInfo>
                        You've selected an account that uses a 3rd party
                        platform for invoice submission. Press Submit to confirm
                        you've submitted these invoices on that website and it
                        will update the sent date here. Press Email if you would
                        like to also send an email.
                      </MoreInfo>

                      <DrawerFormRow container>
                        <FormLabel item>To:</FormLabel>
                        <FormValue>
                          [EXTERNAL] {currentResponsibleParty.submissionPortal}
                        </FormValue>
                      </DrawerFormRow>
                    </>)}
                  {(showEmail || !isExternalSubmissionPortal) && (<>
                      <MoreInfo>
                        Complete the following form to send the invoices.
                      </MoreInfo>
                      <DrawerFormRow container style={{ marginTop: '18px' }}>
                        <FormLabel item>
                          Email
                          {isExternalSubmissionPortal ? ' (optional)' : null}
                        </FormLabel>
                      </DrawerFormRow>

                      <DrawerFormRow container>
                        <TextField id="email-billing-contacts" name="billingContactsString" label="" placeholder="john.doe@autonation.com" variant="outlined" rows={3} validate={emailListValidation} helperText={touched['billingContactsString'] ? (<>{errors.billingContactsString}</>) : null} multiline/>
                        <Typography variant="subtitle1" style={{
                            marginTop: '8px',
                            textAlign: 'right',
                            width: '100%',
                        }}>
                          Input emails separated by a comma
                        </Typography>
                      </DrawerFormRow>
                      <DrawerFormRow container style={{ marginTop: '18px' }}>
                        <FormLabel item>Comments</FormLabel>
                      </DrawerFormRow>

                      <DrawerFormRow container>
                        <TextField id="email-comments" name="comments" placeholder="Enter text here…" variant="outlined" rows={3} inputProps={{ maxLength: 500 }} multiline InputLabelProps={{ shrink: true }}/>
                        <Typography variant="subtitle1" style={{
                            marginTop: '8px',
                            textAlign: 'right',
                            width: '100%',
                        }}>
                          Characters ({values.comments.length} / 500)
                        </Typography>
                      </DrawerFormRow>
                    </>)}
                  <SimpleDialogActions style={{ textAlign: 'right', marginTop: '32px' }}>
                    {isExternalSubmissionPortal && (<SecondaryButton onClick={() => {
                            toggleShowEmail(setFieldValue, resetForm);
                        }} style={{ width: 'auto', marginRight: '12px' }}>
                        Email
                      </SecondaryButton>)}
                    <FooterActionButton onClick={handleSubmit} disabled={!isValid || isSubmitting}>
                      {showFinalScreen ? 'Ok' : 'Submit'}
                    </FooterActionButton>
                  </SimpleDialogActions>
                </>);
            }}
          </Formik>
        </>)}
    </SimpleModal>);
};
export default InvoiceSendModal;
