import { TextField } from 'src/components/mui';
import React from 'react';
import { FooterActionButton, Instructions, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
const ReturnDrawer = ({ open, onClose, saveReturnedItem, confirmationNumber, isSubmitting, setConfirmationNumber, status, }) => {
    const disableSubmit = (confirmationNumber !== null && confirmationNumber !== void 0 ? confirmationNumber : '').length === 0 || isSubmitting;
    return (<SimpleDrawer open={open} onClose={onClose} title={status === 'RETURNED' ? 'Confirmation Number' : 'Return'}>
      <SimpleDrawerContent>
        <Instructions>
          Enter confirmation number to complete return.
        </Instructions>
        <TextField variant="outlined" label="Confirmation Number" placeholder="Confirmation Number" fullWidth value={confirmationNumber !== null && confirmationNumber !== void 0 ? confirmationNumber : ''} onChange={(e) => {
            setConfirmationNumber(e.target.value);
        }}/>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        {status === 'PENDING' && (<SimpleTwoButtonRow submitText="Confirm" onSubmit={() => {
                saveReturnedItem(confirmationNumber);
            }} disabledSubmit={disableSubmit} cancelText="Skip" onCancel={() => {
                saveReturnedItem();
            }}/>)}

        {status === 'RETURNED' && (<FooterActionButton onClick={() => {
                saveReturnedItem(confirmationNumber);
            }} isSubmitting={isSubmitting} disabled={disableSubmit}>
            Confirm
          </FooterActionButton>)}
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
export default ReturnDrawer;
