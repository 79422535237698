import React from 'react';
import moment from 'moment';
import { TextField, useMediaQuery, Checkbox, Autocomplete, } from 'src/components/mui';
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon, } from 'src/components/mui/icons';
import { colors, theme } from 'src/styles/theme';
import { SkeduloResourceTag } from 'src/AdminApp/models/enums';
import SimpleChip from 'src/components/SimpleChip';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { TIMESTAMP_WITH_OFFSET_FORMAT } from 'src/timeConstants';
import AppointmentDates from './AppointmentDates';
import { DialogContainer, Section, ChipButton, ActionsSection, ActionButton, } from './FilterDialog.styles';
const DesktopFilter = ({ query, territories, metros, components, onResetClick, onUpdate, renderLocationField, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const resourceTag = SkeduloResourceTag;
    const consumerTag = Object.keys(resourceTag)
        .map((x) => {
        return { id: x, value: resourceTag[x] };
    })
        .filter((x) => x.value === resourceTag.CONSUMER)[0];
    const fleetTag = Object.keys(resourceTag)
        .map((x) => {
        return { id: x, value: resourceTag[x] };
    })
        .filter((x) => x.value === resourceTag.FLEET)[0];
    const isConsumerChecked = (_a = query === null || query === void 0 ? void 0 : query.skeduloTags) === null || _a === void 0 ? void 0 : _a.includes(consumerTag.id);
    const isFleetChecked = (_b = query === null || query === void 0 ? void 0 : query.skeduloTags) === null || _b === void 0 ? void 0 : _b.includes(fleetTag.id);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;
    const queryTerritories = (_d = (_c = query === null || query === void 0 ? void 0 : query.territories) === null || _c === void 0 ? void 0 : _c.split(',')) !== null && _d !== void 0 ? _d : [];
    const queryMetros = (_f = (_e = query === null || query === void 0 ? void 0 : query.metros) === null || _e === void 0 ? void 0 : _e.split(',')) !== null && _f !== void 0 ? _f : [];
    const queryComponents = (_h = (_g = query === null || query === void 0 ? void 0 : query.componentIds) === null || _g === void 0 ? void 0 : _g.split(',')) !== null && _h !== void 0 ? _h : [];
    const queryCustomComponents = (query === null || query === void 0 ? void 0 : query.hasCustomComponent) === 'true';
    const terValues = territories === null || territories === void 0 ? void 0 : territories.filter((t) => queryTerritories.includes(t.id));
    const metValues = metros === null || metros === void 0 ? void 0 : metros.filter((m) => queryMetros.includes(m.id));
    const compValues = components === null || components === void 0 ? void 0 : components.filter((p) => queryComponents === null || queryComponents === void 0 ? void 0 : queryComponents.some((c) => p === null || p === void 0 ? void 0 : p.repairComponentIds.includes(Number(c))));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    if (!isDesktop)
        return null;
    const onReset = () => {
        onResetClick();
    };
    return (<div>
      <DialogContainer>
        <Section>
          <h4 className="sectionTitle">Van Type</h4>
          <div className="sectionContent">
            <ChipButton>
              <SimpleChip label="Consumer" $bgColor={isConsumerChecked ? colors.BLUE : colors.GRAY_EBED} $textColor={isConsumerChecked ? colors.white : colors.GRAY_76} onClick={() => {
            var _a, _b;
            const tags = (_b = (_a = query === null || query === void 0 ? void 0 : query.skeduloTags) === null || _a === void 0 ? void 0 : _a.split(',').filter((i) => i.length)) !== null && _b !== void 0 ? _b : [];
            const value = tags.includes(consumerTag.id)
                ? tags
                    .filter((i) => i !== consumerTag.id && i.length)
                    .join(',')
                : [...tags, consumerTag.id].join(',');
            onUpdate('skeduloTags', value);
        }}/>
            </ChipButton>
            <ChipButton>
              <SimpleChip label="Fleet" $bgColor={isFleetChecked ? colors.BLUE : colors.GRAY_EBED} $textColor={isFleetChecked ? colors.white : colors.GRAY_76} onClick={() => {
            var _a, _b;
            const tags = (_b = (_a = query === null || query === void 0 ? void 0 : query.skeduloTags) === null || _a === void 0 ? void 0 : _a.split(',').filter((i) => i.length)) !== null && _b !== void 0 ? _b : [];
            const value = tags.includes(fleetTag.id)
                ? tags
                    .filter((i) => i !== fleetTag.id && i.length)
                    .join(',')
                : [...tags, fleetTag.id].join(',');
            onUpdate('skeduloTags', value);
        }}/>
            </ChipButton>
          </div>
        </Section>

        <Section>
          <h4 className="sectionTitle">Appointment</h4>
          <AppointmentDates dateStart={(_j = query === null || query === void 0 ? void 0 : query.appointmentStart) !== null && _j !== void 0 ? _j : null} dateEnd={(_k = query === null || query === void 0 ? void 0 : query.appointmentEnd) !== null && _k !== void 0 ? _k : null} onDateStartChange={(date) => {
            const momentDate = moment(date).startOf('day').utc();
            if (momentDate.isValid()) {
                onUpdate('appointmentStart', momentDate.format(TIMESTAMP_WITH_OFFSET_FORMAT));
            }
        }} onDateEndChange={(date) => {
            const momentDate = moment(date).endOf('day').utc();
            if (momentDate.isValid()) {
                onUpdate('appointmentEnd', momentDate.format(TIMESTAMP_WITH_OFFSET_FORMAT));
            }
        }}/>
        </Section>
        <Section>
          <h4 className="sectionTitle sectionTitle--location">Parts</h4>
        </Section>
        <Section>
          <Autocomplete style={{ width: '50%' }} className="filterDialogMultiOption" multiple disableCloseOnSelect options={components !== null && components !== void 0 ? components : []} value={compValues} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} renderOption={(props, option, { selected }) => (<li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected}/>
                {option.name}
              </li>)} defaultValue={[]} renderInput={(params) => (<TextField {...params} variant="outlined" label="Components"/>)} onChange={(e, value) => {
            const p = value
                .map((i) => i.repairComponentIds ? i.repairComponentIds : [])
                .reduce((accumulator, value) => accumulator.concat(value), []);
            onUpdate('componentIds', p);
        }}/>
          <br />
          <LabelledCheckbox label="Custom components" checkboxProps={{
            icon,
            checkedIcon,
            style: { marginRight: 8 },
            checked: queryCustomComponents,
            onChange: (_, value) => {
                onUpdate('hasCustomComponent', value);
            },
        }}/>
        </Section>

        {renderLocationField && (<>
            <Section>
              <h4 className="sectionTitle sectionTitle--location">Location</h4>
            </Section>
            <Section>
              <Autocomplete className="filterDialogMultiOption" multiple disableCloseOnSelect options={territories !== null && territories !== void 0 ? territories : []} value={terValues} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} disabled={((_l = metValues === null || metValues === void 0 ? void 0 : metValues.length) !== null && _l !== void 0 ? _l : 0) > 0} renderOption={(props, option, { selected }) => (<li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected}/>
                    {option.name}
                  </li>)} defaultValue={[]} renderInput={(params) => (<TextField {...params} variant="outlined" label="Territory"/>)} onChange={(e, value) => {
                const t = value.map((i) => (i.id ? i.id : i));
                onUpdate('territories', t);
            }}/>
            </Section>
            <Section>
              <div className="sectionContent">
                <Autocomplete className="filterDialogMultiOption" multiple disableCloseOnSelect options={metros !== null && metros !== void 0 ? metros : []} value={metValues} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} disabled={((_m = terValues === null || terValues === void 0 ? void 0 : terValues.length) !== null && _m !== void 0 ? _m : 0) > 0} renderOption={(props, option, { selected }) => (<li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected}/>
                      {option.name}
                    </li>)} defaultValue={[]} renderInput={(params) => (<TextField {...params} variant="outlined" label="Metro"/>)} onChange={(e, value) => {
                const m = value.map((i) => (i.id ? i.id : i));
                onUpdate('metros', m);
            }}/>
              </div>
            </Section>
          </>)}
      </DialogContainer>

      <ActionsSection>
        <ActionButton variant="text" onClick={onReset}>
          Reset
        </ActionButton>
      </ActionsSection>
    </div>);
};
export default DesktopFilter;
