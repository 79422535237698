import { min, uniq } from 'lodash';
import CONSTANTS from 'src/constants';
import { STATIC_URL_BASE } from 'src/styles/theme';
export const getEnginesByDescription = (engineDesc, engines) => {
    return engineDesc === CONSTANTS.ENGINE_DONT_KNOW
        ? null
        : engines.filter((e) => e.description === engineDesc);
};
export const getAvailableTrimIdsByEngineDesc = (engineDesc, engines) => {
    const selectedEngines = engineDesc === CONSTANTS.ENGINE_DONT_KNOW
        ? null
        : engines.filter((e) => e.description === engineDesc);
    const trimIdsOfEngines = selectedEngines
        ? uniq(selectedEngines
            .map((e) => e.availableSubModelIds)
            .reduce((allSubmodelIds, submodelIds) => allSubmodelIds.concat(submodelIds), []))
        : null;
    return trimIdsOfEngines;
};
export const getLowestEngineId = (engines, filterIds) => {
    const ids = filterIds
        ? engines.filter((e) => filterIds.includes(e.id)).map((e) => e.id)
        : engines.map((e) => e.id);
    return min(ids);
};
export const formVehicleDisplayText = (vehicle) => `${vehicle === null || vehicle === void 0 ? void 0 : vehicle.year} ${vehicle === null || vehicle === void 0 ? void 0 : vehicle.make}`;
export const formLogoUrl = (vehicle) => { var _a, _b; return ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.baseUrl) !== null && _a !== void 0 ? _a : '') + ((_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.smallLogoUrl) !== null && _b !== void 0 ? _b : ''); };
export const getSquareMakeLogo = (make, factor = '') => {
    return `${STATIC_URL_BASE}img/makes-logos/${make
        .toLowerCase()
        .replace(/\s/g, '-')}${factor}.png`;
};
