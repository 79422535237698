import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
export const ActionCreators = {
    GENERATE_MFA_CODE: createDefaultActionCreators('GENERATE_MFA_CODE'),
};
export const generateNewMfaCode = (email) => createAPIAction({
    types: [
        ActionCreators.GENERATE_MFA_CODE.FETCH(),
        ActionCreators.GENERATE_MFA_CODE.SUCCESS(),
        ActionCreators.GENERATE_MFA_CODE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.GENERATE_NEW_MFA(),
    body: {
        email
    },
    method: 'POST',
});
