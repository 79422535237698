import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byId: {},
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['created,desc', 'regionName,desc'],
        },
    },
};
const lockSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize, status } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
                status,
            } }) });
};
const locksSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize, status } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
                status,
            } }) });
};
const locksFetchReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const locksClearReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const locksClearFiltersReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params: Object.assign(Object.assign({}, state.dashboard.params), { filters: DEFAULT_STATE.dashboard.params.filters }) }) });
};
const reducers = handleActions({
    [ActionCreators.OPTIMIZATION_RUNS.SUCCESS.type]: locksSuccessReducer,
    [ActionCreators.OPTIMIZATION_RUNS.FETCH.type]: locksFetchReducer,
    [ActionCreators.OPTIMIZATION_RUNS.CLEAR.type]: locksClearReducer,
    [ActionCreators.OPTIMIZATION_RUNS.CLEAR_FILTERS.type]: locksClearFiltersReducer,
    [ActionCreators.OPTIMIZATION_RUN.SUCCESS.type]: lockSuccessReducer,
}, DEFAULT_STATE);
export default reducers;
