import styled from 'styled-components';
import { theme, colors } from 'src/styles/theme';
export const JobItemRoot = styled.li `
  border-radius: 4px;
  border: solid 1px ${colors.GRAY_DE};
  padding: 26px 16px 24px;
  margin-top: 16px;
`;
export const CarContainer = styled.section `
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${theme.breakpoints.up('md')} {
    align-items: center;
  }

  .jobCheckbox {
    padding: 0;
    margin-left: 8px;
  }
`;
export const CarInfo = styled.label.attrs((props) => ({
    htmlFor: props.htmlFor,
})) `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: ${colors.black};
`;
export const RequestDetails = styled.section `
  display: flex;
  justify-content: stretch;
  padding: 14px 0;
  margin: 14px 0;
  border-top: 1px solid ${colors.GRAY_DE};
  border-bottom: 1px solid ${colors.GRAY_DE};
`;
export const DetailsLabel = styled.div `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
`;
export const RequestDetailsItem = styled.div `
  flex-grow: 1;
`;
export const DetailsValue = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.3px;
  color: ${colors.black};
  margin-top: 6px;
  display: flex;
`;
export const Repairs = styled.section `
  padding-top: 14px;
`;
export const SpecialOrderWrapper = styled.span `
  display: flex;
  align-items: center;
  margin-left: 8px;
`;
