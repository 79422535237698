var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PartPickupItemStatus from 'src/AdminApp/models/enums/PartPickupItemStatus';
import { toast } from 'src/components/SimpleToast';
import { submitPickedUpParts } from 'src/TechApp/modules/partpickup/actions';
import { selectPartPickupOrder, selectPickedUpItems, selectPickupOrderMissingParts, } from 'src/TechApp/modules/partpickup/selectors';
const usePartPickupChecklist = ({ goToMissingParts, onFinish, }) => {
    const dispatch = useDispatch();
    const partPickupOrder = useSelector(selectPartPickupOrder);
    const pickedUpItems = useSelector(selectPickedUpItems);
    const isMissingParts = useSelector(selectPickupOrderMissingParts);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const groupByRepairRequest = (items) => {
        return items.reduce((acc, item) => {
            const { repairRequestReferenceNum } = item;
            const group = acc.find((g) => g.repairRequestReferenceNum === repairRequestReferenceNum);
            if (group) {
                group.items.push(item);
            }
            else {
                acc.push({ repairRequestReferenceNum, items: [item] });
            }
            return acc;
        }, []);
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isMissingParts) {
            goToMissingParts();
        }
        else {
            submitParts();
        }
    });
    const countPendingOrNewPartGroup = (items) => {
        let pendingOrNewPart = 0;
        if (!items) {
            return 0;
        }
        items.forEach((item) => {
            pendingOrNewPart += countPendingOrNewPart(item);
        });
        return pendingOrNewPart;
    };
    const countPendingOrNewPart = (item) => {
        let pendingOrNewPart = 0;
        if (!item || !item.states) {
            return 0;
        }
        item.states.forEach((state) => {
            if (state.status === PartPickupItemStatus.NEW_PART ||
                state.status === PartPickupItemStatus.PENDING) {
                pendingOrNewPart += 1;
            }
        });
        return pendingOrNewPart;
    };
    const submitParts = () => __awaiter(void 0, void 0, void 0, function* () {
        if (pickedUpItems && pickedUpItems.length) {
            const mappedItems = pickedUpItems.map((item) => (Object.assign(Object.assign({}, item), { quantity: item.checked ? item.quantity : 0 })));
            setIsSubmitting(true);
            const response = yield dispatch(submitPickedUpParts(partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.id, mappedItems));
            setIsSubmitting(false);
            if (!response.error) {
                toast.success('The selected parts were submitted successfully');
                onFinish();
            }
        }
    });
    return {
        groupByRepairRequest,
        partPickupOrder,
        countPendingOrNewPartGroup,
        handleSubmit,
        isSubmitting,
    };
};
export default usePartPickupChecklist;
