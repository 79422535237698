var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'src/modules/api/utils/useDebounce';
import { difference } from 'lodash';
import { toast } from 'src/components/SimpleToast';
import { selectFleetsSearch } from 'src/AdminApp/modules/fleets/selectors';
import { selectAddVisitFleets, selectAddVisitFleetsDatasource, selectAddVisitStations, } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { clearFleetsSearchResults, searchFleetsByName, searchFleetsByStation, } from 'src/AdminApp/modules/fleets/actions';
import { setAddVisitExtLocation, setAddVisitLocation, setFleetsDatasource, setSelectedFleets, setSelectedStations, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
const useFleetsAutocomplete = ({ fleet, workOrders, }) => {
    const dispatch = useDispatch();
    const [searchFleet, setSearchFleet] = useState('');
    const searchFleetsDebounce = useDebounce(searchFleet, 500);
    const [fleetSearchResults, setFleetSearchResults] = useState([]);
    const [selected, setSelected] = useState([]);
    const selectedFleets = useSelector(selectAddVisitFleets);
    const selectedStation = useSelector(selectAddVisitStations);
    const fleetSearchResultsRaw = useSelector(selectFleetsSearch);
    const dataSource = useSelector(selectAddVisitFleetsDatasource);
    useEffect(() => {
        if (fleet) {
            handleFleetSelected(null, [fleet]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleet]);
    useEffect(() => {
        if ((searchFleetsDebounce === null || searchFleetsDebounce === void 0 ? void 0 : searchFleetsDebounce.length) > 0) {
            dispatch(searchFleetsByName({ name: searchFleetsDebounce }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFleetsDebounce]);
    useEffect(() => {
        // FILTER SEARCH RESULTS IF THERE ARE ANY SELECTED ALREADY
        if (fleetSearchResultsRaw && (selectedFleets === null || selectedFleets === void 0 ? void 0 : selectedFleets.length) > 0) {
            filterRawSearchResults();
        }
        else if (fleetSearchResultsRaw) {
            setFleetSearchResults(fleetSearchResultsRaw);
        }
    }, [fleetSearchResultsRaw, dataSource]); // eslint-disable-line
    useEffect(() => {
        const selectedCountOld = selected.length;
        setSelected(selectedFleets);
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            // ADD REMOVED FLEET IF NOT IN LOCAL SEARCH RESULTS
            if (selectedCountOld > selectedFleets.length) {
                filterRawSearchResults();
            }
            // ADD A STATION IF NONE SELECTED AND A SELECTED FLEET HAS ONE
            if (selectedFleets.length > 0 &&
                selectedStation.length === 0 &&
                dataSource === 'SEARCH') {
                const fleetWithStation = selectedFleets.find((f) => f.station !== null);
                if (fleetWithStation) {
                    dispatch(setSelectedStations([fleetWithStation.station]));
                    const stationFleetsResponse = yield dispatch(searchFleetsByStation({
                        station: fleetWithStation.station,
                        size: 100,
                    }));
                    // NARROW DOWN FLEETS TO ONLY THIS STATION NOW
                    if (!stationFleetsResponse.error) {
                        dispatch(setFleetsDatasource('STATION'));
                        setFleetSearchResults(stationFleetsResponse.payload.content);
                    }
                    // KEEP SELECTED FLEETS THAT ARE IN THIS STATION
                    const filteredFleets = selectedFleets.filter((f) => f.station === fleetWithStation.station);
                    dispatch(setSelectedFleets(filteredFleets));
                }
            }
            // ADDRESS POPULATE
            if (selectedFleets.length > 0) {
                const firstFleet = selectedFleets[0];
                if ((_a = firstFleet.consumer) === null || _a === void 0 ? void 0 : _a.address) {
                    const firstFleetAddress = firstFleet.consumer.address;
                    const firstAddress = Object.assign(Object.assign({}, firstFleetAddress), { zip: firstFleetAddress.zipCode, created: '', createdBy: '', modified: '', modifiedBy: '' });
                    dispatch(setAddVisitLocation(firstAddress));
                    dispatch(setAddVisitExtLocation(firstAddress));
                }
            }
            else {
                dispatch(setAddVisitLocation(null));
                dispatch(setAddVisitExtLocation(null));
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFleets]);
    const handleFleetSelected = (e, value) => {
        if (workOrders) {
            const deletedFleets = difference(selectedFleets.map((f) => f.id), value.map((v) => v.id));
            // STOP THE USER FROM REMOVING A STATION THAT IS BEING USED IN A WORK ORDER
            let foundFleet = false;
            deletedFleets.forEach((deletedFleet) => {
                const foundWoFleet = workOrders.find((wo) => { var _a; return ((_a = wo.fleet) === null || _a === void 0 ? void 0 : _a.id) === deletedFleet; });
                if (foundWoFleet && !foundFleet) {
                    foundFleet = true;
                }
            });
            if (foundFleet) {
                toast.error('This fleet cannot be removed as long as its ROs are in the visit');
                return;
            }
        }
        dispatch(setSelectedFleets(value));
        // CLEAR THE STATION IF THERE ARE NO MORE FLEETS LEFT
        if (value.length === 0) {
            dispatch(setSelectedStations([]));
            dispatch(clearFleetsSearchResults());
            dispatch(setFleetsDatasource('SEARCH'));
            dispatch(setAddVisitLocation(null));
            dispatch(setAddVisitExtLocation(null));
        }
    };
    const filterRawSearchResults = () => {
        const filteredResults = fleetSearchResultsRaw.filter((result) => {
            const findExisting = selectedFleets.find((fleet) => fleet.id === result.id);
            return !findExisting;
        });
        setFleetSearchResults(filteredResults);
    };
    const handleSearchFleets = (e, value) => {
        if (dataSource === 'SEARCH') {
            setSearchFleet(value);
        }
    };
    return {
        dispatch,
        searchFleet,
        setSearchFleet,
        searchFleetsDebounce,
        fleetSearchResults,
        setFleetSearchResults,
        selected,
        setSelected,
        selectedFleets,
        selectedStation,
        fleetSearchResultsRaw,
        dataSource,
        handleFleetSelected,
        filterRawSearchResults,
        handleSearchFleets,
    };
};
export default useFleetsAutocomplete;
