var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Divider } from 'src/components/mui';
import { Error as ErrorIcon } from 'src/components/mui/icons';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ButtonLink, ErrorIconStyle, IntroContent, IntroCopy, IntroHeader, } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/AuthorizationScript.styles';
import { getEntry } from 'src/clients/contentfulClient';
import { SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import Config from 'src/config';
const ServiceError = ({ onSkipPayment, onCancelReschedule, paymentMethodId, requestAuthorization, onSuccess, onFail, }) => {
    const [introCopy, setIntroCopy] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield getEntry(Config.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_SYSTEM_ERROR);
            setIntroCopy(response.fields.script);
        }))();
    }, []);
    const handleTryAgain = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        setIsLoading(true);
        const response = yield requestAuthorization(true, paymentMethodId);
        setIsLoading(false);
        if (response.error) {
            return;
        }
        if ((_b = (_a = response.payload) === null || _a === void 0 ? void 0 : _a.currentTask) === null || _b === void 0 ? void 0 : _b.paymentMethodAuthorized) {
            onSuccess();
        }
        else {
            onFail();
        }
    });
    return (<>
      <SimpleDrawerContent>
        <IntroContent>
          <ErrorIcon style={ErrorIconStyle} fontSize="large"/>
          <IntroHeader>Service Error</IntroHeader>
          <IntroCopy>
            <ReactMarkdown>{introCopy}</ReactMarkdown>
          </IntroCopy>
        </IntroContent>
        <Divider />
        <IntroCopy>
          Customer using alternative payment method?
          <ButtonLink onClick={onSkipPayment}>
            Skip Payment Authorization
          </ButtonLink>
        </IntroCopy>
      </SimpleDrawerContent>

      <SimpleDrawerFooter>
        <SimpleTwoButtonRow submitText="Try Again" onSubmit={handleTryAgain} onCancel={onCancelReschedule} cancelText="Cancel/Reschedule" disabledSubmit={isLoading} disabledCancel={isLoading}/>
      </SimpleDrawerFooter>
    </>);
};
export default ServiceError;
