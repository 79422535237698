import { Grid } from 'src/components/mui';
import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
export const PartPickup = styled(Grid) `
  padding: 20px 24px;
  border: solid 1px ${colors.lightGrey};
  border-left: 6px solid ${colors.ORANGE_FF};
  margin-top: 15px;
  border-radius: 4px;
`;
export const PickupSection = styled(Grid) `
  border-bottom: solid 1px ${colors.lightGrey};
  padding: 0 0 16px 0;

  &:not(:first-child) {
    padding-top: 16px;
  }

  .visitAddress {
    margin-bottom: 0;
  }
`;
export const PickupDesc = styled.span `
  display: inline;

  ${theme.breakpoints.up('md')} {
    display: block;
  }
`;
export const CompletedLabel = styled.div `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 6px;

  svg {
    color: ${colors.success};
    margin-left: 4px;
    height: 20px;
    width: 20px;
  }
`;
export const PartPickupTaskAction = styled(Grid) `
  padding-top: 16px;

  ${theme.breakpoints.up('md')} {
    padding-top: 0;
  }

  button {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;

    ${theme.breakpoints.up('md')} {
      width: 100%;
    }
  }
`;
