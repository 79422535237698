import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddButton from 'src/AdminApp/components/layout/AddButton';
import DashboardFilters from 'src/AdminApp/containers/shops/DashboardFilters';
import useShopsDashboard from 'src/AdminApp/containers/shops/useShopsDashboard';
import { selectDashboardFetching, selectDashboardShops, } from 'src/AdminApp/modules/shops/selectors';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { selectAuthorizedShopEdit, selectAuthorizedShopSearch, selectAuthorizedShopView, } from 'src/modules/auth/selectors';
import ShopsListItem from './ShopsListItem';
const REQUEST_COLUMNS = [
    { title: 'Shop Score', key: 'shopScore', sortable: false },
    { title: 'Integrated', key: 'integrated', sortable: false },
    { title: 'Delivery Channel', key: 'delivery', sortable: false },
    { title: 'Shop Name', key: 'shopName', sortable: true },
    { title: 'Address', key: 'address', sortable: true },
    { title: 'Contact Info', key: 'contactInfo', sortable: false },
    { title: 'Delivery Van Status', key: 'deliveryVanStatus', sortable: true },
    { title: 'Sales Status', key: 'salesStatus', sortable: true },
    { title: 'Status', key: 'status', sortable: true },
    { title: '', key: 'other' },
];
const ShopsDashboard = () => {
    var _a;
    const history = useHistory();
    const shops = useSelector(selectDashboardShops);
    const canEdit = useSelector(selectAuthorizedShopEdit);
    const canSearch = useSelector(selectAuthorizedShopSearch);
    const canView = useSelector(selectAuthorizedShopView);
    const isFetching = useSelector(selectDashboardFetching);
    const { onLoadData, onSearch, onClear, params, pagination } = useShopsDashboard();
    return (<>
      {canEdit ? (<div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                position: 'relative',
                zIndex: 2,
            }}>
          <AddButton text="Add Shop" color="primary" onClick={() => {
                history.push('/admin/shops/add');
            }}/>
        </div>) : (<div>
          ERROR - Not Authorized: You must have the "Shop_Editor" role to view
          this item
        </div>)}
      {canSearch ? (<DashboardFilters filters={params.filters} onSearch={onSearch} onClear={onClear}/>) : (<div>
          ERROR - Not Authorized: You must have the "Shop_Search" role to view
          this item
        </div>)}
      {canView ? (<SimpleTable data={shops} pagination={pagination} sort={(_a = pagination.sort) !== null && _a !== void 0 ? _a : []} columns={REQUEST_COLUMNS} isFetching={isFetching} renderItem={(item, index) => (<ShopsListItem {...item} key={`${index}-${item.referenceNum}`}/>)} onLoadData={onLoadData}/>) : (<div>
          ERROR - Not Authorized: You must have the "Shop_Viewer" role to view
          this item
        </div>)}
    </>);
};
export default ShopsDashboard;
