import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { theme } from 'src/styles/theme';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline, ThemeProvider } from 'src/components/mui';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CONFIG from './config';
import Loading from './pages/Loading';
import loadScript from './utils/loadScript';
import { welcomeText } from './utils/welcome';
import registerServiceWorker from './serviceWorkerRegistration';
import App from './App';
import configureStore from './configureStore';
console.info(welcomeText);
const { store, persistor } = configureStore();
loadScript(`https://maps.googleapis.com/maps/api/js?key=${CONFIG.GOOGLE_PLACES_API_KEY}&libraries=places`, document.querySelector('head'), 'google-maps');
window.zESettings = {
    webWidget: {
        chat: {
            suppress: true,
        },
        contactForm: {
            suppress: true,
        },
        helpCenter: {
            suppress: true,
        },
        talk: {
            suppress: true,
        },
        answerBot: {
            suppress: true,
        },
        offset: {
            vertical: '60px',
            mobile: {
                vertical: '60px',
            },
        },
    },
};
if (CONFIG.REACT_APP_ZENDESK_CHAT_ENABLED === 'true') {
    loadScript(`https://static.zdassets.com/ekr/snippet.js?key=${CONFIG.REACT_APP_ZENDESK_CHAT_API_KEY}`, document.querySelector('head'), 'ze-snippet');
}
loadScript('https://js.chargebee.com/v2/chargebee.js', document.querySelector('head'), 'cb', ['data-cb-site', CONFIG.REACT_APP_CHARGEBEE_SITE]);
const renderDom = () => {
    const container = document.getElementById('react-root');
    const root = createRoot(container);
    root.render(<Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Router>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <App />
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </Router>

      </PersistGate>
    </Provider>);
};
renderDom();
registerServiceWorker();
