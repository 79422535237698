import React from 'react';
import { Grid } from 'src/components/mui';
import PageTitle from 'src/containers/layout/PageTitle';
import AddRepairForm from '../components/repairs/AddRepairForm';
const EditRepair = (props) => {
    var _a, _b;
    return (<>
    <PageTitle slug="Edit Repair"/>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AddRepairForm repairId={(_b = (_a = props === null || props === void 0 ? void 0 : props.match) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.repairId}/>
      </Grid>
    </Grid>
  </>);
};
export default EditRepair;
