import React from 'react';
import ReactJson from '@microlink/react-json-view';
import { Grid } from 'src/components/mui';
const SnapshotJsonViewer = ({ json, root }) => {
    return (json && (<Grid container>
        <Grid item md={12}>
          <ReactJson src={json} name={root || 'root'} collapsed={false} indentWidth={2} displayDataTypes={false} theme="bright:inverted" enableClipboard={false} onEdit={false} onAdd={false} onDelete={false} collapseStringsAfterLength={100} style={{ minHeight: '600px' }}/>
        </Grid>
      </Grid>));
};
export default SnapshotJsonViewer;
