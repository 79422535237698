import { useState } from 'react';
const useInvoiceRequestDetailsDrawer = ({ invoiceRequests, handleAcknowledge, onClose, }) => {
    const [fullError, setFullError] = useState();
    const hasMultipleRequests = invoiceRequests.length > 1;
    const firstRequest = invoiceRequests[0];
    const submitAcknowledge = () => {
        if (invoiceRequests.length === 0)
            return;
        if (firstRequest.status === 'COMPLETED_WITH_ERRORS' &&
            !firstRequest.acknowledgedAt &&
            handleAcknowledge) {
            handleAcknowledge(firstRequest.id);
            onClose(true);
            return;
        }
        onClose();
    };
    const showErrorStack = (errorObj) => {
        setFullError(errorObj);
    };
    const hideErrorStack = () => {
        setFullError(undefined);
    };
    return {
        hasMultipleRequests,
        firstRequest,
        submitAcknowledge,
        showErrorStack,
        hideErrorStack,
        fullError,
    };
};
export default useInvoiceRequestDetailsDrawer;
