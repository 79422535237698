var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPartners } from 'src/PartnersApp/modules/partners/actions';
import { selectPartnersPagination } from 'src/PartnersApp/modules/partners/selectors';
import PartnersTable from 'src/PartnersApp/pages/PartnerManagement/PartnersTable';
import { Button, InputAdornment, TextField, Alert } from 'src/components/mui';
import { SearchRounded as SearchRoundedIcon, AddCircleRounded as AddCircleRoundedIcon, } from 'src/components/mui/icons';
import FunnelDetailsDialog from 'src/PartnersApp/pages/PartnerManagement/FunnelDetailsDialog';
import StyledTablePagination from 'src/components/StyledTablePagination';
import usePartners from 'src/PartnersApp/pages/PartnerManagement/usePartners';
import useCustomFunnels from 'src/PartnersApp/pages/PartnerManagement/useCustomFunnels';
import PartnerDetailsDialog from 'src/PartnersApp/pages/PartnerManagement/PartnerDetailsDialog';
import { FunnelSearchContainer, FunnelPaginationContainer, AlertContainer, } from './PartnerManagement.styles';
const PartnerManagement = () => {
    var _a;
    const dispatch = useDispatch();
    const partnersPagination = useSelector(selectPartnersPagination);
    const [showFunnelDetails, setShowFunnelDetails] = useState(false);
    const [showPartnerDetails, setShowPartnerDetails] = useState(false);
    const [editingPartner, setEditingPartner] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const { filterPartners, needsPublished, filter, partnersFiltered, partnersView, clearFilteredResults, setShowActive, } = usePartners(rowsPerPage);
    const { allPages } = useCustomFunnels();
    const handleAddNewPartner = () => {
        const newPartner = {
            name: '',
            funnels: [
                {
                    urlParameterIdentifier: '',
                    pages: [...(allPages !== null && allPages !== void 0 ? allPages : [])],
                    allowPublish: false,
                },
            ],
        };
        setEditingPartner(newPartner);
        setShowFunnelDetails(true);
    };
    const handleActiveSwitch = (showActive) => {
        clearFilteredResults();
        setPage(0);
        setShowActive(showActive);
        if (rowsPerPage !== 25) {
            // API gets called when changing rowsPerPage, so don't call it twice
            setRowsPerPage(25);
        }
        else {
            dispatch(getPartners({ active: showActive, size: 25, page: 0 }));
        }
    };
    const handleChangePage = (event, newPage) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(getPartners({ page: newPage, size: rowsPerPage }));
        setPage(newPage);
    });
    const handleChangeRowsPerPage = (event) => __awaiter(void 0, void 0, void 0, function* () {
        const newRows = parseInt(event.target.value, 10);
        yield dispatch(getPartners({ page: 0, size: newRows }));
        setRowsPerPage(newRows);
        setPage(0);
    });
    const showPagination = partnersPagination != null &&
        ((_a = partnersView === null || partnersView === void 0 ? void 0 : partnersView.length) !== null && _a !== void 0 ? _a : 0) > 0 &&
        ((filter === null || filter === void 0 ? void 0 : filter.length) === 0 || filter === undefined);
    return !partnersView ? null : (<>
      {needsPublished && (<AlertContainer>
          <Alert severity="info" variant="filled">
            There are custom funnels ready for publishing
          </Alert>
        </AlertContainer>)}

      <FunnelSearchContainer>
        <TextField classes={{ root: 'searchField' }} variant="outlined" placeholder="Filter by Partner Name or Admin" onChange={(e) => {
            filterPartners(e, null, null);
        }} value={filter} InputProps={{
            startAdornment: (<InputAdornment position="start">
                <SearchRoundedIcon />
              </InputAdornment>),
        }}/>
        <Button variant="contained" color="primary" className="addFunnelBtn" startIcon={<AddCircleRoundedIcon />} onClick={handleAddNewPartner}>
          Add a New Partner
        </Button>
      </FunnelSearchContainer>

      <PartnersTable partners={partnersFiltered !== null && partnersFiltered !== void 0 ? partnersFiltered : partnersView} onFunnelEdit={(partner) => {
            setEditingPartner(partner);
            setShowFunnelDetails(true);
        }} onPartnerEdit={(partner) => {
            setEditingPartner(partner);
            setShowPartnerDetails(true);
        }} onActiveSwitch={handleActiveSwitch}/>

      {showPagination && (<FunnelPaginationContainer>
          <StyledTablePagination paginationData={partnersPagination} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}/>
        </FunnelPaginationContainer>)}

      {!!editingPartner && !!allPages && (<FunnelDetailsDialog open={showFunnelDetails} onClose={(refresh = false) => {
                if (refresh) {
                    dispatch(getPartners({ page, size: rowsPerPage }));
                }
                setShowFunnelDetails(false);
                setEditingPartner(undefined);
            }} partner={editingPartner} allPages={allPages}/>)}

      <PartnerDetailsDialog partner={editingPartner} open={showPartnerDetails} onClose={() => {
            setShowPartnerDetails(false);
        }}/>
    </>);
};
export default PartnerManagement;
