import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/mui';
import { ChevronRight } from 'src/components/mui/icons';
import { BtnText, SlidePageContent, SlidePageWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { effectiveDate, SliderContext, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/utils';
import { colors } from 'src/styles/theme';
import useArchivedLaborRatesListPage from './useArchivedLaborRatesListPage';
const iconStyle = {
    color: colors.iconGrey,
};
const ListContainer = styled('div') `
  padding: 8px 0;
`;
const ListItem = styled(Button) `
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0;
`;
const ArchivedLaborRatesListPage = ({ metro = false, }) => {
    var _a;
    const { setSlide } = (_a = useContext(SliderContext)) !== null && _a !== void 0 ? _a : {};
    const { laborRates, handleArchiveClick } = useArchivedLaborRatesListPage({
        metro,
        setSlide,
    });
    return (<SlidePageWrapper>
      <SlidePageContent>
        <ListContainer>
          {laborRates === null || laborRates === void 0 ? void 0 : laborRates.map(({ effectiveFrom, effectiveTo, id }) => (<ListItem fullWidth key={id} onClick={handleArchiveClick(id)}>
              <BtnText>
                {`${effectiveDate(effectiveFrom)} - ${effectiveDate(effectiveTo)}`}
              </BtnText>
              <ChevronRight style={iconStyle}/>
            </ListItem>))}
        </ListContainer>
      </SlidePageContent>
    </SlidePageWrapper>);
};
export default ArchivedLaborRatesListPage;
