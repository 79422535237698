import React, { useEffect, useState, } from 'react';
import { TextField } from 'src/components/mui';
import { Editable, InputButtons, InputWrapper } from './components';
const EditableInput = ({ children, breakLines, defaultValue, displayValue, drawerTitle, inputProps = {}, isDrawerOnMobile = false, isEditable = true, isInline = false, onClose = () => { }, onUpdate = () => { }, style, value, }) => {
    const [input, setInput] = useState();
    useEffect(() => {
        setInput(value !== null && value !== void 0 ? value : '');
    }, [value]);
    const handleUpdate = () => {
        if (onUpdate) {
            onUpdate(input !== null && input !== void 0 ? input : '');
        }
    };
    const val = typeof input !== 'string' ? value : input;
    return (<Editable isEditable={isEditable} isDrawerOnMobile={isDrawerOnMobile} displayValue={children || displayValue || value || defaultValue} breakLines={breakLines} style={style} renderForm={({ setIsEditing }) => (<InputWrapper inline={isInline}>
          <TextField fullWidth value={val} onChange={(e) => {
                setInput(e.target.value);
            }} onKeyPress={(e) => {
                if (!breakLines) {
                    if (e.key === 'Enter') {
                        setIsEditing(false);
                        handleUpdate();
                    }
                }
            }} {...inputProps}/>
          <InputButtons onClose={() => {
                setIsEditing(false);
                setInput(val !== null && val !== void 0 ? val : '');
                if (onClose)
                    onClose();
            }} onAccept={() => {
                setIsEditing(false);
                handleUpdate();
            }}/>
        </InputWrapper>)} DrawerProps={{
            title: drawerTitle !== null && drawerTitle !== void 0 ? drawerTitle : '',
            onSubmit: () => handleUpdate(),
            renderInput: () => (<>
            {/* disable the auto-zoom on input selection on iPhone */}
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
            <TextField fullWidth value={val} onChange={(e) => {
                    setInput(e.target.value);
                }} {...inputProps}/>
          </>),
        }}/>);
};
export default EditableInput;
