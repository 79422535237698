import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { colors } from 'src/styles/theme';
const pageWrapper = css `
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
`;
export const SlidePageWrapper = styled('div') `
  ${pageWrapper}
`;
export const SlidePageFormWrapper = styled(Form) `
  ${pageWrapper}
`;
export const SlidePageContent = styled.div `
  padding: 24px;
  flex: 1;
  overflow-y: scroll;
`;
export const SlidePageFooter = styled.div `
  padding: 20px;
  text-align: right;
  background: ${colors.white};
`;
export const SlidePageFieldsContainer = styled.div `
  border-bottom: ${(props) => props.isLast ? '0' : '1px'}
    solid ${colors.lightGrey};
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
`;
export const LaborRatesContainer = styled('div') `
  padding: 32px 0;
`;
export const BtnText = styled('span') `
  font-size: 1rem;
`;
export const DialogContent = styled.div `
  font-weight: bold;
  font-size: 18px;
`;
export const ButtonWrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 18px;
`;
