import { handleActions } from 'redux-actions';
import { createSimpleDeepHandler } from 'src/modules/api/utils/createSimpleDeepHandler';
import { fetched } from 'src/utils/data-utils';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { createClearHandler } from 'src/modules/api/utils/createClearHandler';
import { ActionCreators, setSelectedProductFleets } from './actions';
const DEFAULT_STATE = {
    byId: {},
    pricing: {},
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['id,asc'],
        },
    },
    componentsByRepair: [],
    rules: [],
    conciergeRules: [],
    measurementsSlidePanelOpen: false,
    measurements: [],
    selectedMeasurement: [],
    repairMeasurements: [],
    defaultResponsiblePartyInfo: undefined,
};
const successRepairByIdReducer = (state, action) => {
    var _a;
    const repairData = fetched(action.payload);
    const id = (_a = repairData.repair) === null || _a === void 0 ? void 0 : _a.id;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [id]: repairData }) });
};
const successComponentsByRepairId = (state, action) => {
    const components = fetched(action.payload);
    const { repairId } = action.meta;
    return Object.assign(Object.assign({}, state), { componentsByRepair: Object.assign(Object.assign({}, state.componentsByRepair), { [repairId]: components }) });
};
const successConciergeRulesByRepairId = (state, action) => {
    const conciergeRule = fetched(action.payload);
    const { repairId } = action.meta;
    return Object.assign(Object.assign({}, state), { conciergeRules: Object.assign(Object.assign({}, state.conciergeRules), { [repairId]: conciergeRule }) });
};
const successRulesByFleetId = (state, action) => {
    const pricingRules = fetched(action.payload);
    const { fleetId } = action.meta;
    return Object.assign(Object.assign({}, state), { rules: Object.assign(Object.assign({}, state.rules), { [fleetId]: pricingRules }) });
};
const successSearchRepairsReducer = (state, action) => {
    var _a, _b, _c;
    const { content, totalPages, totalElements } = fetched(action.payload);
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { content, _isFetching: false, params: {
                filters: params.filters,
            }, pagination: {
                pageSize: (_a = params.pagination) === null || _a === void 0 ? void 0 : _a.size,
                totalElements,
                totalPages,
                pageNumber: (_b = params.pagination) === null || _b === void 0 ? void 0 : _b.page,
                sort: (_c = params.pagination) === null || _c === void 0 ? void 0 : _c.sort,
            } }) });
};
const fetchSearchRepairsReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params: {
                filters: params.filters,
            }, _isFetching: true }) });
};
const openMeasurementsSlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { measurementsSlidePanelOpen: true });
};
const closeMeasurementsSlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { measurementsSlidePanelOpen: false });
};
const getMeasurementRequirementsSuccess = (state, action) => {
    return Object.assign(Object.assign({}, state), { measurements: action.payload });
};
const getMeasurementByRepairSuccess = (state, action) => {
    return Object.assign(Object.assign({}, state), { repairMeasurements: action.payload });
};
const clearMeasurements = (state) => {
    return Object.assign(Object.assign({}, state), { repairMeasurements: [], selectedMeasurement: [] });
};
const getSelectedMeasurementSuccess = (state, action) => {
    return Object.assign(Object.assign({}, state), { selectedMeasurement: action.payload });
};
const noAction = (state) => {
    return Object.assign({}, state);
};
const reducers = handleActions({
    [ActionCreators.GET_REPAIR_BY_ID.SUCCESS.type]: successRepairByIdReducer,
    [ActionCreators.GET_COMPONENTS_BY_REPAIR_ID.SUCCESS.type]: successComponentsByRepairId,
    [ActionCreators.GET_REPAIR_CONCIERGE_RULES.SUCCESS.type]: successConciergeRulesByRepairId,
    [ActionCreators.GET_EXISTING_FLEET_RULES.SUCCESS.type]: successRulesByFleetId,
    [ActionCreators.UPDATE_REPAIR.SUCCESS.type]: successRepairByIdReducer,
    [ActionCreators.SEARCH_REPAIRS.SUCCESS.type]: successSearchRepairsReducer,
    [ActionCreators.SEARCH_REPAIRS.FETCH.type]: fetchSearchRepairsReducer,
    [setSelectedProductFleets.type]: createSimpleDeepHandler('pricing.fleets'),
    [ActionCreators.ADD_REPAIR_MEASUREMENT.SUCCESS.type]: noAction,
    [ActionCreators.REMOVE_REPAIR_MEASUREMENT.SUCCESS.type]: noAction,
    [ActionCreators.SLIDE_PANEL_MEASUREMENT_REQUIREMENTS.OPEN.type]: openMeasurementsSlidePanel,
    [ActionCreators.SLIDE_PANEL_MEASUREMENT_REQUIREMENTS.CLOSE.type]: closeMeasurementsSlidePanel,
    [ActionCreators.GET_MEASUREMENTS.SUCCESS.type]: getMeasurementRequirementsSuccess,
    [ActionCreators.GET_MEASUREMENTS_BY_REPAIR.SUCCESS.type]: getMeasurementByRepairSuccess,
    [ActionCreators.CLEAR_MEASUREMENTS.CLEAR.type]: clearMeasurements,
    [ActionCreators.GET_MEASUREMENT.SUCCESS.type]: getSelectedMeasurementSuccess,
    [ActionCreators.UPDATE_MEASUREMENT_ITEMS.SUCCESS.type]: getSelectedMeasurementSuccess,
    [ActionCreators.CREATE_MEASUREMENT.SUCCESS.type]: noAction,
    [ActionCreators.GET_DEFAULT_RESPONSIBLE_PARTY_INFO.SUCCESS.type]: createSimpleHandler('defaultResponsiblePartyInfo'),
    [ActionCreators.DELETE_PRODUCT_DEFAULT_RESPONSIBLE_PARTY.SUCCESS.type]: createClearHandler('defaultResponsiblePartyInfo'),
}, DEFAULT_STATE);
export default reducers;
