import React from 'react';
import HeyTech from 'src/components/layout/HeyTech';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
export const PartPickupHeyTech = ({ techName, onFinish, }) => {
    return (<>
      <SimpleDrawerContent>
        <HeyTech header={`Hey ${techName !== null && techName !== void 0 ? techName : 'Tech'}!`} copy="We’ve sent the parts that you didn’t pick up to the parts team. Next, they will look for alternatives at the store you’re already at or they will order them to a new store."/>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={onFinish}>Next</FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
