var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import SimpleAutosuggest from '../SimpleAutosuggest';
const FleetSearchSelect = ({ onSelect, onSearch, onChange = () => { }, excludedId = '', autoFocus = false, initialValue = null, inputValue, fleetSuggestions, label = 'Fleet', }) => {
    const handleSearch = (value) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const response = yield onSearch(value);
        const suggestions = ((_b = (_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : [])
            .map((fleet) => ({
            label: fleet.name,
            id: fleet.id,
            fleet,
        }))
            .filter((fleet) => fleet.id !== excludedId);
        suggestions.unshift({
            label: ' ',
            id: null,
            fleet: null,
        });
        return suggestions;
    });
    return (<SimpleAutosuggest label={label} placeholder="Search fleets..." fieldName="label" autoFocus={autoFocus} initialValue={inputValue !== null && inputValue !== void 0 ? inputValue : initialValue} fieldToInput="fleet.name" suggestions={fleetSuggestions} onChange={(newValue) => {
            onChange(newValue);
        }} onSearch={handleSearch} onSelect={(suggestion) => {
            onSelect(suggestion);
        }}/>);
};
export default FleetSearchSelect;
