import React from 'react';
import { Grid } from 'src/components/mui';
import SnapshotV2 from 'src/AdminApp/containers/requests/Snapshot/SnapshotV2';
const SnapshotComponentViewer = ({ referenceNum, snapshot, }) => {
    return ((snapshot && (<Grid container>
        <Grid item md={12}>
          {snapshot.type === 'QUOTE' && (<SnapshotV2 isMobile={false} referenceNum={referenceNum} quoteSnapshot={snapshot}/>)}
          {snapshot.type === 'INVOICE' && (<SnapshotV2 isMobile={false} referenceNum={referenceNum} invoiceSnapshot={snapshot}/>)}
        </Grid>
      </Grid>)) ||
        null);
};
export default SnapshotComponentViewer;
