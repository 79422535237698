import React from 'react';
import { Link } from 'react-router-dom';
import { AttachFile as AttachFileIcon } from 'src/components/mui/icons';
import { ArrivalWindow } from 'src/AdminApp/components/repairs/statement/styledComponents';
import { AppointmentStatus, DeliveryChannel, PartsStatus, RepairOrderStatus, } from 'src/AdminApp/models/enums';
import { getShopDashboardUrl, getSkeduloUrl, } from 'src/AdminApp/utils/url-utils';
import { SimpleTableRow } from 'src/components/dashboard/SimpleTable';
import SimpleChip from 'src/components/SimpleChip';
import { Subtext } from 'src/components/layout';
import { colors } from 'src/styles/theme';
import { formatCurrency, formatDate, formatTimestamp, } from 'src/utils/formatter';
import styled from 'styled-components';
const HasInvoiceIcon = styled(AttachFileIcon) `
  font-size: 1rem;
`;
const deliveryChannelColor = (deliveryChannel) => {
    let color;
    switch (deliveryChannel) {
        case 'REFERRAL':
            color = colors.red;
            break;
        case 'DELIVERY':
            color = colors.blue;
            break;
        case 'DIRECT':
            color = colors.green;
            break;
        case 'NONE':
        default:
            color = colors.lightgray;
    }
    return color;
};
const RepairListItem = ({ appointmentStatus, appointmentTime, car, consumerId = null, consumerInfo, created, deliveryChannel, repairOrderStatus, referenceNum, shopInfo, hasInvoice = false, arrivalWindowStart, arrivalWindowEnd, partsStatus, timezone, skeduloJobId = null, balanceOwed, paymentsReceivedTotal, }) => {
    var _a, _b, _c, _d, _e, _f;
    const appointmentIsCanceled = AppointmentStatus.CANCELED === appointmentStatus;
    return (<SimpleTableRow data={[
            <Link to={`/admin/repairs/${referenceNum}`}>
          {hasInvoice && <HasInvoiceIcon fontSize="small"/>}
          {referenceNum}
        </Link>,
            <>
          <div>
            {`${(_b = (_a = car === null || car === void 0 ? void 0 : car.carYear) !== null && _a !== void 0 ? _a : car === null || car === void 0 ? void 0 : car.year) !== null && _b !== void 0 ? _b : ''}
          ${(_c = car === null || car === void 0 ? void 0 : car.make) !== null && _c !== void 0 ? _c : ''} ${(_d = car === null || car === void 0 ? void 0 : car.model) !== null && _d !== void 0 ? _d : ''}
          ${(_e = car === null || car === void 0 ? void 0 : car.engineDescription) !== null && _e !== void 0 ? _e : ''}`}
          </div>
          <Subtext>{(_f = car === null || car === void 0 ? void 0 : car.vin) !== null && _f !== void 0 ? _f : ''}</Subtext>
        </>,
            formatTimestamp(created),
            PartsStatus.display(partsStatus),
            <>
          <div>{formatDate(appointmentTime, 'lll z', timezone)}</div>
          <Subtext>
            <ArrivalWindow start={arrivalWindowStart} end={arrivalWindowEnd}/>
          </Subtext>
        </>,
            <>
          <div>{AppointmentStatus.display(appointmentStatus)}</div>
          {skeduloJobId && (<div>
              <a href={getSkeduloUrl(`/job/${skeduloJobId}/details`)} rel="noopener noreferrer" target="_blank">
                Skedulo Job
              </a>
            </div>)}
        </>,
            RepairOrderStatus.display(repairOrderStatus),
            (!!balanceOwed || !!paymentsReceivedTotal) && (<>
            {balanceOwed ? (<div>
                <i>Owed:</i>&nbsp;{formatCurrency(balanceOwed)}
              </div>) : null}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {paymentsReceivedTotal && !balanceOwed ? (<img style={{ marginRight: '5px' }} src="/img/svg/payment/fully_paid.svg" alt=""/>) : null}
              <i>Paid:</i>&nbsp;{formatCurrency(paymentsReceivedTotal)}
            </div>
          </>),
            <>
          <Link to={`/admin/consumers/${consumerId}`}>
            {consumerInfo === null || consumerInfo === void 0 ? void 0 : consumerInfo.name}
          </Link>
          <Subtext>{consumerInfo === null || consumerInfo === void 0 ? void 0 : consumerInfo.email}</Subtext>
          <Subtext>{consumerInfo === null || consumerInfo === void 0 ? void 0 : consumerInfo.phone}</Subtext>
        </>,
            <SimpleChip $bgColor={deliveryChannelColor(deliveryChannel || DeliveryChannel.NONE)} $textColor={colors.white} label={DeliveryChannel.display(deliveryChannel || DeliveryChannel.NONE)}/>,
            shopInfo && (<>
            <a href={shopInfo.ispId ? getShopDashboardUrl(shopInfo.ispId) : ''} rel="noopener noreferrer" target="_blank">
              {shopInfo.shopName}
            </a>
            <Subtext>{shopInfo.email}</Subtext>
            <Subtext>{shopInfo.phone}</Subtext>
          </>),
        ]} cellProps={{
            $strikeThrough: appointmentIsCanceled,
        }}/>);
};
export default RepairListItem;
