var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-alert */
import { Grid } from 'src/components/mui';
import { BarcodeScanner } from 'dynamsoft-javascript-barcode';
import React, { useEffect, useRef } from 'react';
import './VideoDecode.css';
const VideoDecode = ({ processResultCb }) => {
    const elRef = useRef(null);
    const pScanner = useRef(null);
    const setupBarcodeScanner = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const scanner = yield BarcodeScanner.createInstance();
            pScanner.current = scanner;
            if (scanner.isContextDestroyed())
                return;
            if (elRef.current) {
                yield scanner.setUIElement(elRef.current);
            }
            scanner.onUniqueRead = (txt, result) => {
                processResultCb(result);
            };
            yield scanner.open();
        }
        catch (ex) {
            if (ex.message.indexOf('network connection error')) {
                const customMsg = 'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
                console.error(customMsg);
                alert(customMsg);
            }
            throw ex;
        }
    });
    useEffect(() => {
        setupBarcodeScanner();
        return () => {
            if (pScanner.current) {
                pScanner.current.destroyContext();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<Grid container style={{ justifyContent: 'center', alignItems: 'center' }}>
      <div ref={elRef} className="component-barcode-scanner">
        <div className="dce-video-container" style={{ position: 'relative', width: '350px', height: '350px' }}/>
      </div>
    </Grid>);
};
export default VideoDecode;
