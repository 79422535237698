import styled from 'styled-components';
import { Divider, Fab, Grid, TextField } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
import { flexCenter } from 'src/styles/styled';
export const CardsContainer = styled(Grid) `
  margin-top: 16px;
  margin-bottom: 16px;
`;
export const GarageDivider = styled(Divider) `
  margin-top: 16px;
  margin-bottom: 24px;
`;
export const GridItemWithDivider = styled(Grid) `
  ${theme.breakpoints.up('md')} {
    &:nth-of-type(2n + 1) {
      position: relative;
      &:after {
        content: '';
        border-left: 1px solid ${colors.lightGrey};
        height: 270px;
        width: 0;
        position: absolute;
        top: 16px;
        right: 0px;
      }
    }
  }
`;
export const ActionsContainer = styled('div') `
  ${flexCenter}

  height: auto;
  justify-content: space-between;
  margin-bottom: 32px;

  > h6 {
    flex: 1;
  }

  > div {
    flex: 2;
  }
`;
export const VehicleListContainer = styled('div') `
  height: calc(90% - 16px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;
export const LoadMoreVehicles = styled('div') `
  ${flexCenter}

  border-top: 1px solid ${colors.lightGrey};
  height: 90px;
  padding: 8px;
`;
export const GarageSearch = styled(TextField) `
  margin-bottom: 33px;

  .MuiTextField-root {
    & > div {
      border-radius: 40px;
    }
  }
`;
export const AddNewVehicleFab = styled(Fab) `
  position: absolute;
  bottom: 36px;
  right: 25px;
`;
export const LoadingWrapper = styled('div') `
  display: flex;
  justify-content: center;
`;
