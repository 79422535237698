import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, } from 'src/components/mui';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
import { isNull, max } from 'lodash';
import { getServiceAreasAutocomplete } from 'src/AdminApp/modules/serviceAreas/actions';
import { selectServiceAreasAutocomplete } from 'src/AdminApp/modules/serviceAreas/selectors';
import { SlidePageFieldsContainer } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import ServiceAreaSearch from 'src/components/form/ServiceAreaSearch';
import moment from 'moment';
import { DATE_TIME_FORMAT, LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const ServiceArea = ({ index, value, onSetValue, onRemove, minDate, isLast, }) => {
    var _a, _b;
    return (<SlidePageFieldsContainer isLast={isLast} key={index}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ServiceAreaSearch fullWidth key={`sa_${index}`} value={value.serviceArea} type="text" name={`sa_${index}`} label={`Service Area ${(index !== null && index !== void 0 ? index : 0) + 1}`} variant="outlined" disabled={!!value.id} onUpdate={(serviceArea) => {
            if (serviceArea) {
                onSetValue(index, Object.assign(Object.assign({}, value), { serviceArea }));
            }
            else {
                onRemove(index);
            }
        }} getServiceAreas={getServiceAreasAutocomplete} serviceAreaSelector={selectServiceAreasAutocomplete}/>
      </Grid>
      {value.serviceArea && (<>
          <Grid item xs={6}>
            <SimpleDatePicker fullWidth name={`effectiveDateFrom_${index}`} label="Effective from" slotProps={{
                textField: {
                    variant: 'outlined',
                    helperText: 'Must be greater than or equal to Marketing Launch, Vehicle Launch date"',
                },
            }} format={LOCALIZED_DATE_FORMAT} disabled={value.active} minDate={moment(max([minDate, (_a = value.serviceArea) === null || _a === void 0 ? void 0 : _a.marketingLaunchDate]))} value={!isNull(value.effectiveFrom) && value.id !== undefined
                ? moment(value.effectiveFrom)
                : null} onChange={(date) => {
                onSetValue(index, Object.assign(Object.assign({}, value), { effectiveFrom: !isNull(date)
                        ? moment(date).startOf('day').format(DATE_TIME_FORMAT)
                        : null }));
            }}/>
          </Grid>
          <Grid item xs={6}>
            <SimpleDatePicker fullWidth name={`effectiveDateTo_${index}`} label="Effective to (opt.)" format={LOCALIZED_DATE_FORMAT} disabled={value.alreadyInactive} minDate={moment(max([
                value.effectiveFrom,
                minDate,
                (_b = value.serviceArea) === null || _b === void 0 ? void 0 : _b.marketingLaunchDate,
            ]))} slotProps={{
                textField: {
                    variant: 'outlined',
                    helperText: 'Must be greater than or equal to Effective To, Vehicle Launch, Marketing Launch date',
                },
                field: {
                    clearable: true,
                },
            }} value={!isNull(value.effectiveTo) && value.id !== undefined
                ? moment(value.effectiveTo) : null} onChange={(date) => {
                onSetValue(index, Object.assign(Object.assign({}, value), { effectiveTo: !isNull(date)
                        ? moment(date).startOf('day').format(DATE_TIME_FORMAT)
                        : null }));
            }}/>
          </Grid>
        </>)}
    </Grid>
  </SlidePageFieldsContainer>);
};
export const ServiceAreaSelector = ({ selectedServiceAreas, onUpdate, minDate, }) => {
    const [activeServiceAreas, setActiveServiceAreas] = useState([]);
    const [inactiveServiceAreas, setInactiveServiceAreas] = useState([]);
    useEffect(() => {
        setActiveServiceAreas(selectedServiceAreas.filter((x) => !x.alreadyInactive));
        setInactiveServiceAreas(selectedServiceAreas.filter((x) => x.alreadyInactive));
    }, [selectedServiceAreas]);
    const updateElement = (index, value) => {
        if (index === activeServiceAreas.length) {
            return onUpdate([...activeServiceAreas, value, ...inactiveServiceAreas]);
        }
        const updatedActiveServiceAreas = [...activeServiceAreas];
        updatedActiveServiceAreas[index] = value;
        onUpdate([...updatedActiveServiceAreas, ...inactiveServiceAreas]);
    };
    const removeElement = (index) => {
        const updatedActiveServiceAreas = [...activeServiceAreas];
        updatedActiveServiceAreas.splice(index, 1);
        onUpdate([...updatedActiveServiceAreas, ...inactiveServiceAreas]);
    };
    return (<>
      <SlidePageFieldsContainer>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Typography>Active Service Areas</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            {activeServiceAreas.map((x, index) => (<ServiceArea index={index} value={x} onSetValue={updateElement} onRemove={removeElement} minDate={minDate}/>))}
            <ServiceArea index={activeServiceAreas.length} value={{
            id: undefined,
            van: undefined,
            serviceArea: undefined,
            effectiveFrom: undefined,
            effectiveTo: undefined,
        }} onSetValue={updateElement} onRemove={removeElement} minDate={minDate} isLast/>
          </AccordionDetails>
        </Accordion>
      </SlidePageFieldsContainer>
      {inactiveServiceAreas.length ? (<SlidePageFieldsContainer>
          <Accordion defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Typography>Inactive Service Areas</Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
              {inactiveServiceAreas.map((x, index) => (<ServiceArea index={index} value={x} onSetValue={updateElement} onRemove={removeElement} minDate={minDate} isLast={index === inactiveServiceAreas.length - 1}/>))}
            </AccordionDetails>
          </Accordion>
        </SlidePageFieldsContainer>) : null}
    </>);
};
