import { createSelector } from 'reselect';
export const selectDeliveryVanTemplates = (state) => state.admin.deliveryVanTemplates;
export const selectTemplates = createSelector(selectDeliveryVanTemplates, (deliveryVanTemplates) => deliveryVanTemplates === null || deliveryVanTemplates === void 0 ? void 0 : deliveryVanTemplates.templates);
export const selectTechNames = createSelector(selectDeliveryVanTemplates, (deliveryVanTemplates) => deliveryVanTemplates === null || deliveryVanTemplates === void 0 ? void 0 : deliveryVanTemplates.techNames);
export const selectSelectedTemplate = createSelector(selectDeliveryVanTemplates, (deliveryVanTemplates) => deliveryVanTemplates === null || deliveryVanTemplates === void 0 ? void 0 : deliveryVanTemplates.selectedTemplate);
export const selectTemplatesSlidePanelOpen = createSelector(selectDeliveryVanTemplates, (deliveryVanTemplates) => deliveryVanTemplates === null || deliveryVanTemplates === void 0 ? void 0 : deliveryVanTemplates.templatesSlidePanelOpen);
export const selectEditTemplateSlidePanelOpen = createSelector(selectDeliveryVanTemplates, (deliveryVanTemplates) => deliveryVanTemplates === null || deliveryVanTemplates === void 0 ? void 0 : deliveryVanTemplates.editTemplateSlidePanelOpen);
export const selectTemplateType = createSelector(selectDeliveryVanTemplates, (deliveryVanTemplates) => deliveryVanTemplates === null || deliveryVanTemplates === void 0 ? void 0 : deliveryVanTemplates.deliveryVanTemplateType);
export const selectUnavailabilities = createSelector(selectDeliveryVanTemplates, (deliveryVanTemplates) => deliveryVanTemplates === null || deliveryVanTemplates === void 0 ? void 0 : deliveryVanTemplates.unavailabilities);
export const selectSelectedUnavailability = createSelector(selectDeliveryVanTemplates, (deliveryVanTemplates) => deliveryVanTemplates === null || deliveryVanTemplates === void 0 ? void 0 : deliveryVanTemplates.selectedUnavailability);
export const selectUnavailabilitiesSlidePanelOpen = createSelector(selectDeliveryVanTemplates, (deliveryVanTemplates) => deliveryVanTemplates === null || deliveryVanTemplates === void 0 ? void 0 : deliveryVanTemplates.unavailabilitiesSlidePanelOpen);
export const selectEditUnavailabilitySlidePanelOpen = createSelector(selectDeliveryVanTemplates, (deliveryVanTemplates) => deliveryVanTemplates === null || deliveryVanTemplates === void 0 ? void 0 : deliveryVanTemplates.editUnavailabilitiesSlidePanelOpen);
