import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    dashboard: [],
    byId: {},
    porterDashboard: [],
    porterById: {},
};
const dashboardSuccessReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { dashboard: [...action.payload] });
};
const technicianSuccessReducer = (state, action) => {
    const technician = action.payload;
    const { technicianId, date } = action.meta;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [technicianId]: Object.assign(Object.assign({}, state.byId[technicianId]), { [date]: Object.assign({}, technician) }) }) });
};
const porterDashboardSuccessReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { porterDashboard: [...action.payload] });
};
const porterSuccessReducer = (state, action) => {
    const porter = action.payload;
    const { porterId, date } = action.meta;
    return Object.assign(Object.assign({}, state), { porterById: Object.assign(Object.assign({}, state.byId), { [porterId]: Object.assign(Object.assign({}, state.porterById[porterId]), { [date]: Object.assign({}, porter) }) }) });
};
const reducers = handleActions({
    [ActionCreators.GET_DASHBOARD.SUCCESS.type]: dashboardSuccessReducer,
    [ActionCreators.GET_TECHNICIAN_DATA.SUCCESS.type]: technicianSuccessReducer,
    [ActionCreators.GET_PORTER_DASHBOARD.SUCCESS.type]: porterDashboardSuccessReducer,
    [ActionCreators.GET_PORTER_DATA.SUCCESS.type]: porterSuccessReducer,
}, DEFAULT_STATE);
export default reducers;
