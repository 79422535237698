var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleDrawer, SimpleDrawerContent } from 'src/components/SimpleDrawer';
import { selectPartDistributorLocationId } from 'src/PopsApp/modules/parts/selectors';
import { getCatalogComponents } from 'src/PopsApp/modules/vendors/actions';
import { selectFluidsCatalog, selectPartsCatalog, } from 'src/PopsApp/modules/vendors/selectors';
import { PartDrawerContainer, ShowAllPartsButton, ShowAllPartsContainer, } from './PickPartDrawer.styles';
import PickPartItem from './PickPartItem';
import Skeleton from './Skeleton';
const PickPartDrawer = ({ open, onClose, onSelect, selectedPart, }) => {
    var _a, _b, _c;
    const partDistributorLocationId = useSelector(selectPartDistributorLocationId);
    const partsCatalog = useSelector(selectPartsCatalog);
    const fluidsCatalog = useSelector(selectFluidsCatalog);
    const [showAll, setShowAll] = useState(false);
    const [processing, setProcessing] = useState(false);
    const { isFluid } = (_a = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) !== null && _a !== void 0 ? _a : { isFluid: false };
    const { car } = (_c = (_b = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _b === void 0 ? void 0 : _b.job) !== null && _c !== void 0 ? _c : {};
    const items = useMemo(() => {
        var _a, _b;
        if (!car || !car.id || !selectedPart.nodeId)
            return [];
        const vendorCatalog = (isFluid ? fluidsCatalog : partsCatalog)[partDistributorLocationId];
        if (!vendorCatalog)
            return [];
        const { components } = (_b = ((_a = vendorCatalog[car.id]) !== null && _a !== void 0 ? _a : {})[selectedPart.nodeId]) !== null && _b !== void 0 ? _b : {};
        if (!components)
            return [];
        return components
            .sort((c1, c2) => (c2.autoNationPart ? 1 : 0) - (c1.autoNationPart ? 1 : 0))
            .filter((component) => {
            var _a, _b;
            return !((_a = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.pricingData) === null || _a === void 0 ? void 0 : _a.repairComponentId) ||
                (component === null || component === void 0 ? void 0 : component.repairComponentId) ===
                    ((_b = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.pricingData) === null || _b === void 0 ? void 0 : _b.repairComponentId);
        });
    }, [
        isFluid,
        fluidsCatalog,
        partsCatalog,
        partDistributorLocationId,
        car,
        selectedPart,
    ]);
    const autoNationItems = useMemo(() => {
        const filteredItems = items.filter((item) => item.autoNationPart);
        if (!filteredItems.length || filteredItems.length === items.length) {
            setShowAll(true);
        }
        else {
            setShowAll(false);
        }
        return filteredItems;
    }, [items]);
    const dispatch = useDispatch();
    const getData = () => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        const { car } = (_e = (_d = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _d === void 0 ? void 0 : _d.job) !== null && _e !== void 0 ? _e : {};
        if (!open)
            return;
        if (!car)
            return;
        if (!(items === null || items === void 0 ? void 0 : items.length)) {
            setProcessing(true);
            yield dispatch(getCatalogComponents(car, partDistributorLocationId, [
                {
                    productId: selectedPart.nodeId,
                    part: !isFluid,
                    fluid: isFluid,
                },
            ]));
            setProcessing(false);
        }
    });
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPart, open]);
    const handleSelect = (item) => {
        onSelect(item);
        onClose();
    };
    return (<SimpleDrawer open={open} onClose={onClose} title="Select Part">
      <SimpleDrawerContent>
        {processing && <Skeleton />}
        {!processing && (<PartDrawerContainer className="PartDrawer__Container">
            {!showAll ? (<>
                {autoNationItems.map((i) => (<PickPartItem key={i.id} item={i} onSelect={handleSelect}/>))}

                <ShowAllPartsContainer>
                  <ShowAllPartsButton onClick={() => setShowAll(true)}>
                    Show More
                    <div className="itemCollapseIcon"/>
                  </ShowAllPartsButton>
                </ShowAllPartsContainer>
              </>) : (items.map((i) => (<PickPartItem key={i.id} item={i} onSelect={handleSelect}/>)))}

            {!items.length && (<p>No parts found. Please select a new vendor.</p>)}
          </PartDrawerContainer>)}
      </SimpleDrawerContent>
    </SimpleDrawer>);
};
export default PickPartDrawer;
