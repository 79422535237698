import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { diff } from 'deep-diff';
import { FormControl, Grid, TextField } from 'src/components/mui';
import { InfoText } from 'src/components/layout';
import { colors } from 'src/styles/theme';
const DiffTable = styled.table `
  width: 100%;

  & tr {
    vertical-align: top;
  }

  & th {
    background-color: ${colors.GRAY_EE};
    padding: 2px 5px;
  }

  .data {
    min-width: 200px;
    max-width: 200px;
    & pre {
      margin-top: 0;
      display: block;
    }
  }

  .old {
    &::before {
      content: '-';
      margin-right: 6px;
      float: left;
    }
  }

  .new {
    &::before {
      content: '+';
      margin-right: 6px;
      float: left;
    }
  }

  .edited {
    &.new {
      color: green;
    }
    &.old {
      color: red;
    }
  }

  .deleted {
    color: palevioletred;
  }

  .added {
    color: dodgerblue;
  }
`;
const getClassNames = (classes, kind, column = '') => {
    const classNames = ['data', column];
    const action = getAction(kind).toLowerCase();
    classNames.push(classes[action]);
    if (column === 'old' && action !== 'added')
        classNames.push(classes[column]);
    if (column === 'new' && action !== 'deleted')
        classNames.push(classes[column]);
    return classNames.join(' ');
};
const getAction = (kind) => {
    switch (kind) {
        case 'E':
            return 'Edited';
        case 'D':
            return 'Deleted';
        case 'N':
            return 'Added';
        default:
            return '';
    }
};
const renderValue = (o) => {
    return typeof o === 'object' ? <pre>{JSON.stringify(o, null, 2)}</pre> : o;
};
const formatPath = (path) => {
    return path
        .map((p) => (typeof p === 'number' ? `[${p}]` : p))
        .join('.')
        .replaceAll('.[', '[');
};
const renderPath = (path, filter) => {
    const formattedPath = formatPath(path);
    if (!filter)
        return formattedPath;
    const tokens = formattedPath.split(filter);
    const finalTokens = [];
    tokens === null || tokens === void 0 ? void 0 : tokens.forEach((t, i) => {
        finalTokens.push(<span>{t}</span>);
        if (i < tokens.length - 1)
            finalTokens.push(<span style={{ backgroundColor: 'yellow' }}>{filter}</span>);
    });
    return finalTokens;
};
const renderSnapshotDiffRow = ({ key, diffItem, filter, }) => {
    const { kind, path, lhs, rhs, item, index } = diffItem;
    if (kind === 'A') {
        return renderSnapshotDiffRow({
            key,
            diffItem: Object.assign(Object.assign({}, item), { path: [...path, index] }),
            filter,
        });
    }
    return (<tr key={key} title={getAction(kind)}>
      <td>{renderPath(path, filter)}</td>
      <td className={getClassNames(kind, 'old')}>
        {renderValue(lhs || (item === null || item === void 0 ? void 0 : item.lhs))}
      </td>
      <td className={getClassNames(kind, 'new')}>
        {renderValue(rhs || (item === null || item === void 0 ? void 0 : item.rhs))}
      </td>
    </tr>);
};
const SnapshotDiffChangelog = ({ current, previous, }) => {
    const [filter, setFilter] = useState();
    const [diffs, setDiffs] = useState();
    const [filteredDiffs, setFilteredDiffs] = useState();
    const onChangeFilter = (e) => {
        setFilter(e.target.value);
    };
    useEffect(() => {
        setDiffs(current ? diff(previous || {}, current) : []);
    }, [current, previous]);
    useEffect(() => {
        if (!diffs)
            return;
        if (!filter) {
            setFilteredDiffs([...diffs]);
            return;
        }
        const filtered = diffs.filter((d) => {
            const path = formatPath(d.path);
            return path.indexOf(filter) >= 0;
        });
        setFilteredDiffs(filtered);
    }, [diffs, filter]);
    return (<Grid container>
      <Grid item xs={12}>
        <FormControl margin="none">
          <TextField label="Filter" name="filter" value={filter !== null && filter !== void 0 ? filter : ''} fullWidth margin="none" onChange={onChangeFilter}/>
        </FormControl>
      </Grid>{' '}
      <Grid item xs={12}>
        <InfoText>
          {filteredDiffs === null || filteredDiffs === void 0 ? void 0 : filteredDiffs.length} of {diffs === null || diffs === void 0 ? void 0 : diffs.length} changes
        </InfoText>
      </Grid>
      {(filteredDiffs !== null && filteredDiffs !== void 0 ? filteredDiffs : []).length > 0 && (<Grid item md={12}>
          <DiffTable>
            <thead>
              <tr style={{ textAlign: 'left' }}>
                <th>Path</th>
                <th>Old Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>
              {filteredDiffs === null || filteredDiffs === void 0 ? void 0 : filteredDiffs.map((d, index) => renderSnapshotDiffRow({
                key: index,
                filter: filter !== null && filter !== void 0 ? filter : '',
                diffItem: d,
            }))}
            </tbody>
          </DiffTable>
        </Grid>)}
    </Grid>);
};
export default SnapshotDiffChangelog;
