import { Accordion, AccordionSummary, Button, CircularProgress, Divider, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField as MTextField, Typography, Autocomplete, } from 'src/components/mui';
import { Visibility, AddAPhoto as AddAPhotoIcon, ExpandMore as ExpandMoreIcon, VisibilityOff, } from 'src/components/mui/icons';
import { Form, Formik } from 'formik';
import React from 'react';
import { CheckboxGroup, TextField } from 'src/AdminApp/components/form';
import AddButton from 'src/AdminApp/components/layout/AddButton';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import { AccordionUserDetails, CheckboxWrapper, EditContent, EditFooter, UploadPictureButton, UserProfileEditFullRow, UserProfileEditRow, UserProfileEditSecWrapper, UserProfileEditWrapper, UserProfilePictureContainer, UserProfilePictureWrapper, WrapperBoxPhoto, } from 'src/components/wrappers/EditProfileInfoDrawer.styles';
import { UserInfoWrapper, UserSecWrapper, } from 'src/MyProfileApp/pages/MyProfile/components/Content.styles';
import PhoneNumberUpdate from 'src/MyProfileApp/pages/MyProfile/components/PhoneNumberUpdate';
import useAddAndEditUser from './useAddAndEditUser';
const AddAndEditUser = ({ editMode, editUser, openModal, setOpenModal, handleCloseModal, refreshList, }) => {
    const { skillOptions, metroOptions, showPassword, setShowPassword, checkAllAuthGroups, clearAllAuthGroups, onAuthorizationGroupCheck, onProfilePictureSelected, getValidationSchema, handleClose, hasUserEditorRole, handleSubmit, authorizationGroupsListItems, } = useAddAndEditUser({ editUser, editMode, handleCloseModal, refreshList });
    const { id, firstName, lastName, email, skeduloResourceId, phoneNumber, skills, authGroups, profilePicture, metro, personalPromoCode, enabled, } = editUser || {};
    return (<>
      <AddButton text="Add New User" onClick={() => setOpenModal(true)} color="primary"/>
      <SimpleDrawer open={openModal} onClose={handleClose} size="halfPlus" title={`${editMode ? 'Edit' : 'Add New'} User`}>
        <Formik validateOnMount validationSchema={getValidationSchema()} initialValues={{
            firstName: firstName || '',
            lastName: lastName || '',
            email: email || '',
            skeduloResourceId: skeduloResourceId || '',
            personalPromoCode: personalPromoCode || null,
            password: null,
            phoneNumber: phoneNumber || '',
            skills: skills || [],
            authGroups: authGroups || [],
            profilePicture: profilePicture || '',
            metro,
            enabled: enabled || true,
        }} onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, setSubmitting, resetForm);
        }}>
          {({ values, isSubmitting, isValid, setFieldValue, submitForm }) => {
            var _a, _b;
            return (<Form>
              <EditContent>
                <UserProfileEditWrapper>
                  <UserProfileEditRow>
                    <TextField id="add-edit-user-first-name" name="firstName" label="First Name" variant="outlined" placeholder="First Name" type="text" required/>
                    <TextField id="add-edit-user-last-name" name="lastName" label="Last Name" variant="outlined" placeholder="Last Name" type="text" required/>
                  </UserProfileEditRow>
                  <UserProfileEditRow>
                    <TextField id="add-edit-user-email" name="email" label="Email" variant="outlined" placeholder="Email" type="email" required/>
                    <TextField id="add-edit-user-promo-code" name="personalPromoCode" label="Personal Promo Code" variant="outlined" placeholder="Personal Promo Code" type="text" disabled={!values.authGroups.includes('TECHNICIANS')} required={values.authGroups.includes('TECHNICIANS')}/>
                  </UserProfileEditRow>
                  <UserProfileEditFullRow>
                    <Autocomplete multiple id="add-edit-user-skills" options={skillOptions} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} value={values.skills} isOptionEqualToValue={(option, value) => option.id === value.id} onChange={(_e, value) => setFieldValue('skills', value)} filterSelectedOptions disableClearable clearOnBlur renderInput={(params) => (<MTextField {...params} variant="outlined" label="Skills" placeholder="Add Skill"/>)}/>
                  </UserProfileEditFullRow>
                </UserProfileEditWrapper>
                <Divider />
                <UserProfileEditSecWrapper>
                  {editMode && (<UserProfilePictureWrapper>
                      <UserProfilePictureContainer>
                        <WrapperBoxPhoto htmlFor="upload-user-profile-picture">
                          <input accept="image/*" id="upload-user-profile-picture" multiple={false} type="file" onChange={(e) => {
                        onProfilePictureSelected(e, setFieldValue);
                    }}/>
                          {values.profilePicture ? (<img alt="Profile" src={values.profilePicture}/>) : (<UploadPictureButton variant="contained" color="primary" component="span" startIcon={<AddAPhotoIcon />}>
                              <span>Add Profile Picture</span>
                            </UploadPictureButton>)}
                        </WrapperBoxPhoto>
                      </UserProfilePictureContainer>
                    </UserProfilePictureWrapper>)}
                  <div style={{ width: '100%' }}>
                    <UserInfoWrapper style={{ flexDirection: 'column' }}>
                      <Typography align="left" variant="h6">
                        Sign In &amp; Security
                      </Typography>
                      <UserSecWrapper>
                        {editMode && id ? (<>
                            <Accordion square={false}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="edit-user-phone-content" id="add-edit-user-phone-header">
                                <Typography>Phone Number</Typography>
                              </AccordionSummary>
                              <AccordionUserDetails>
                                <PhoneNumberUpdate userId={id} userPhoneNumber={phoneNumber || ''}/>
                              </AccordionUserDetails>
                            </Accordion>
                            {hasUserEditorRole && (<CheckboxWrapper>
                                <CheckboxField color="primary" disabled={false} label="Active User" name="enabled"/>
                              </CheckboxWrapper>)}
                          </>) : (<>
                            <TextField id="add-user-phone-number" name="phoneNumber" label="Phone Number" variant="outlined" placeholder="Phone number" type="number" required/>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="user-password">
                                Password
                              </InputLabel>
                              <OutlinedInput id="add-edit-user-password" label="Password" placeholder="Password" type={showPassword ? 'text' : 'password'} value={(_a = values.password) !== null && _a !== void 0 ? _a : ''} style={{ height: '56px' }} onChange={(e) => setFieldValue('password', e.target.value, true)} endAdornment={<InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} onMouseDown={(e) => e.preventDefault()} edge="end">
                                      {showPassword ? (<VisibilityOff />) : (<Visibility />)}
                                    </IconButton>
                                  </InputAdornment>}/>
                            </FormControl>
                          </>)}
                      </UserSecWrapper>
                    </UserInfoWrapper>
                  </div>
                </UserProfileEditSecWrapper>
                <Divider />
                <CheckboxGroup checkboxItems={authorizationGroupsListItems} checkboxState={values.authGroups} onCheckboxChange={(group, checked) => onAuthorizationGroupCheck(group, values.authGroups, checked, setFieldValue)} checkAll={() => checkAllAuthGroups(setFieldValue)} clearAll={() => clearAllAuthGroups(setFieldValue)} title="Groups"/>
                <Divider />
                <Grid xs={12} sm={4} item>
                  <FormControl fullWidth disabled={!values.authGroups.includes('TECHNICIANS')} required={values.authGroups.includes('TECHNICIANS')}>
                    <InputLabel id="metro-select-label">Metro</InputLabel>
                    <Select labelId="metro-select-label" id="metro-select" value={((_b = values.metro) === null || _b === void 0 ? void 0 : _b.id) || ''} label="Metro" onChange={({ target }) => {
                    const foundMetro = metroOptions.find((metro) => metro.id === target.value);
                    setFieldValue('metro', foundMetro);
                }}>
                      {metroOptions.map((metro) => {
                    return (<MenuItem key={metro.id} value={metro.id}>
                            {metro.name}
                          </MenuItem>);
                })}
                    </Select>
                    <FormHelperText>
                      Choose a Metro if the user is a technician
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </EditContent>

              <EditFooter>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" color="primary" size="large" disabled={isSubmitting || !isValid} onClick={submitForm}>
                  {isSubmitting ? <CircularProgress /> : 'Save'}
                </Button>
              </EditFooter>
            </Form>);
        }}
        </Formik>
      </SimpleDrawer>
    </>);
};
export default AddAndEditUser;
