var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Divider } from 'src/components/mui';
import { Error as ErrorIcon } from 'src/components/mui/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonLink, ErrorIconStyle, IntroContent, IntroCopy, IntroHeader, } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/AuthorizationScript.styles';
import { loadPaymentMethodsByConsumer } from 'src/AdminApp/modules/payments/actions';
import { selectRequestContactInfoReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { AFFIRM_ERROR_MESSAGE } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/Screens/AffirmCheckout/AffirmCheckout';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { toast } from 'src/components/SimpleToast';
const AffirmFail = ({ onViewCC, onSkipPayment, onCancelReschedule, onSuccess, }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { referenceNum } = params;
    const contactInfo = useSelector(selectRequestContactInfoReferenceNum(referenceNum));
    const { consumerId } = contactInfo;
    const [isLoading, setIsLoading] = useState(false);
    const handleTryAgain = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsLoading(true);
        const paymentMethodsResponse = yield dispatch(loadPaymentMethodsByConsumer(consumerId, referenceNum));
        const affirmSuccessfulPayment = false;
        const foundAffirmPayment = (_a = paymentMethodsResponse === null || paymentMethodsResponse === void 0 ? void 0 : paymentMethodsResponse.payload) === null || _a === void 0 ? void 0 : _a.find((payment) => payment.methodType === 'AFFIRM');
        if (foundAffirmPayment) {
            onSuccess();
            return;
        }
        if (!affirmSuccessfulPayment) {
            toast.error(AFFIRM_ERROR_MESSAGE);
        }
        setIsLoading(false);
    });
    return (<>
      <SimpleDrawerContent>
        <IntroContent>
          <ErrorIcon style={ErrorIconStyle} fontSize="large"/>
          <IntroHeader>Affirm Not Available</IntroHeader>
          <IntroCopy>
            We were unable to authorize Affirm payment. Would the customer like
            to:
          </IntroCopy>
        </IntroContent>
        <Divider />
        <IntroCopy>
          Customer using alternative payment method?
          <ButtonLink onClick={onSkipPayment}>
            Skip Payment Authorization
          </ButtonLink>
        </IntroCopy>
      </SimpleDrawerContent>

      <SimpleDrawerFooter>
        <FooterActionButton onClick={handleTryAgain} disabled={isLoading}>
          Try Again
        </FooterActionButton>

        <FooterActionButton onClick={onViewCC}>
          View CC Details
        </FooterActionButton>

        <FooterActionButton onClick={onCancelReschedule}>
          Cancel/Reschedule
        </FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default AffirmFail;
