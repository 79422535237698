var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import XLSX from 'xlsx';
import { fetchAddressDetails } from 'src/clients/googleGeocoderClient';
import { Button, Card, Grid, Typography } from 'src/components/mui';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import OutTable from './OutTable';
const DropZoneCard = styled(Card) `
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.GRAY_F1} !important;
`;
const StyledGrid = styled(Grid) `
  padding: 20px;
`;
const ProgressText = styled(Typography) `
  display: inline;
  margin: 10px;
`;
const AddressSanitizerModule = () => {
    const [data, setData] = React.useState([]);
    const [fileName, setFileName] = React.useState('default.xlsx');
    const [progress, setProgress] = React.useState(0);
    const sanitizeData = (rows) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const headers = [
            ...rows[0],
            'sanitized data=>',
            'street_1',
            'street_2',
            'city',
            'state',
            'zip',
            'country',
            'longitude',
            'latitude',
        ];
        rows[0] = headers;
        const length = (rows === null || rows === void 0 ? void 0 : rows.length) || 0;
        for (let index = 1; index < length; index += 1) {
            const row = rows[index];
            const inputState = row[headers.indexOf('State')];
            const inputCity = row[headers.indexOf('City')];
            const inputAddress1 = row[headers.indexOf('Address')];
            const inputAddress2 = row[headers.indexOf('Address 2')];
            if (inputCity && inputAddress1) {
                // eslint-disable-next-line no-await-in-loop
                const details = yield fetchAddressDetails(`${inputState}, ${inputCity}, ${inputAddress1}, ${inputAddress2 || ''}`);
                if (details && details.status === 'OK') {
                    const { street1, street2, subpremise, city, state, country, zip, latitude, longitude, } = (_a = details.data) !== null && _a !== void 0 ? _a : {};
                    rows[index] = [
                        ...row,
                        '|',
                        `${street2} ${street1}`,
                        subpremise,
                        city,
                        state,
                        zip,
                        country,
                        longitude,
                        latitude,
                    ];
                }
                else {
                    rows[index] = [...row, 'N/A'];
                }
            }
            else {
                rows[index] = [...row, 'N/A'];
            }
            setData(rows);
            setProgress(((index + 1) / length) * 100);
        }
    });
    const onDrop = (files) => __awaiter(void 0, void 0, void 0, function* () {
        if (files === null || files === void 0 ? void 0 : files.length) {
            const file = files[0];
            setFileName(file.name);
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = (e) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const rows = XLSX.utils.sheet_to_json(ws, { header: 1 });
                /* Update state */
                sanitizeData(rows);
            };
            if (rABS)
                reader.readAsBinaryString(file);
            else
                reader.readAsArrayBuffer(file);
        }
    });
    const exportFile = () => {
        /* convert state to workbook */
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'sanitized data');
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fileName);
    };
    return (<StyledGrid>
      <Typography variant="h6">Address Sanitizer</Typography>
      <div>Minimum requirements for documents:</div>
      <div>
        1st row is a header row with at least <b>Address</b>, <b>City</b> and{' '}
        <b>State</b> columns
      </div>
      <div>2nd+ rows are address data</div>
      <div>
        NOTE: Google API has a limit on the number of requests per minute, so it
        will take a long time to process a document of 100+ lines
      </div>
      <div>
        It's better to convert multiple smaller documents than a single large
        one
      </div>
      <Dropzone onDrop={onDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => (<DropZoneCard {...getRootProps()}>
            <input {...getInputProps()} accept=".xlsx"/>
            <Button>
              Drag 'n' drop .xlsx file here, or click to select file
            </Button>
          </DropZoneCard>)}
      </Dropzone>
      <Button disabled={!(data === null || data === void 0 ? void 0 : data.length)} className="btn btn-success" variant="contained" color="primary" onClick={exportFile}>
        Export to file
      </Button>
      {progress !== 0 && (<ProgressText>
          <b>Progress: {`${progress.toFixed(0)}/100`}%</b>
        </ProgressText>)}
      <OutTable data={data}/>
    </StyledGrid>);
};
export default AddressSanitizerModule;
