import React from 'react';
import { Grid, Checkbox, CircularProgress } from 'src/components/mui';
import { SlideWrapper } from 'src/AdminApp/components/drawers/styledComponents';
import { RequestInfoDisplayText, RepairDropdownStyled, RepairDropdownSummary, } from './commonStyles';
import useWarrantyDecisionsRecommendedRepairs from './useWarrantyDecisionsRecommendedRepairs';
const WarrantyDecisionsRecommendedRepairs = ({ referenceNumber, setApprovedInspectionIds, approvedInspectionIds, }) => {
    const { selectedRepairs, handleCheckboxChange, isLoading } = useWarrantyDecisionsRecommendedRepairs(setApprovedInspectionIds, approvedInspectionIds, referenceNumber);
    return (<SlideWrapper>
      <Grid item xs={12}>
        <RepairDropdownStyled>
          <RepairDropdownSummary>
            <Grid container>
              <Grid item xs>
                <RequestInfoDisplayText>Repairs List</RequestInfoDisplayText>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <span>
                  Please select the repair needed to resolve the Warranty Claim.
                </span>
              </Grid>
            </Grid>
          </RepairDropdownSummary>
        </RepairDropdownStyled>
        {isLoading ? (<CircularProgress />) : (selectedRepairs === null || selectedRepairs === void 0 ? void 0 : selectedRepairs.map((repair, index) => (<RepairDropdownStyled key={repair.id}>
              <RepairDropdownSummary>
                <Grid container>
                  <Grid item xs>
                    <Checkbox color="primary" checked={repair.checked || false} name={repair.id} onChange={handleCheckboxChange(index)}/>
                    <span>{repair.repair}</span>
                  </Grid>
                </Grid>
              </RepairDropdownSummary>
            </RepairDropdownStyled>)))}
      </Grid>
    </SlideWrapper>);
};
export default WarrantyDecisionsRecommendedRepairs;
