var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Field } from 'formik';
import { FormControl, Select, MenuItem, InputLabel } from 'src/components/mui';
const hours = [
    { display: '7:00 AM', value: '07:00:00' },
    { display: '7:30 AM', value: '07:30:00' },
    { display: '8:00 AM', value: '08:00:00' },
    { display: '8:30 AM', value: '08:30:00' },
    { display: '9:00 AM', value: '09:00:00' },
    { display: '9:30 AM', value: '09:30:00' },
    { display: '10:00 AM', value: '10:00:00' },
    { display: '10:30 AM', value: '10:30:00' },
    { display: '11:00 AM', value: '11:00:00' },
    { display: '11:30 AM', value: '11:30:00' },
    { display: '12:00 PM (noon)', value: '12:00:00' },
    { display: '12:30 PM', value: '12:30:00' },
    { display: '1:00 PM', value: '13:00:00' },
    { display: '1:30 PM', value: '13:30:00' },
    { display: '2:00 PM', value: '14:00:00' },
    { display: '2:30 PM', value: '14:30:00' },
    { display: '3:00 PM', value: '15:00:00' },
    { display: '3:30 PM', value: '15:30:00' },
    { display: '4:00 PM', value: '16:00:00' },
    { display: '4:30 PM', value: '16:30:00' },
    { display: '5:00 PM', value: '17:00:00' },
    { display: '5:30 PM', value: '17:30:00' },
    { display: '6:00 PM', value: '18:00:00' },
    { display: '6:30 PM', value: '18:30:00' },
    { display: '7:00 PM', value: '19:00:00' },
    { display: '7:30 PM', value: '19:30:00' },
    { display: '8:00 PM', value: '20:00:00' },
    { display: '8:30 PM', value: '20:30:00' },
    { display: '9:00 PM', value: '21:00:00' },
    { display: '9:30 PM', value: '21:30:00' },
    { display: '10:00 PM', value: '22:00:00' },
    { display: '10:30 PM', value: '22:30:00' },
    { display: '11:00 PM', value: '23:00:00' },
    { display: '11:30 PM', value: '23:30:00' },
    { display: '12:00 AM', value: '00:00:00' },
    { display: '12:30 AM', value: '00:30:00' },
    { display: '1:00 AM', value: '01:00:00' },
    { display: '1:30 AM', value: '01:30:00' },
    { display: '2:00 AM', value: '02:00:00' },
    { display: '2:30 AM', value: '02:30:00' },
    { display: '3:00 AM', value: '03:00:00' },
    { display: '3:30 AM', value: '03:30:00' },
    { display: '4:00 AM', value: '04:00:00' },
    { display: '4:30 AM', value: '04:30:00' },
    { display: '5:00 AM', value: '05:00:00' },
    { display: '5:30 AM', value: '05:30:00' },
    { display: '6:00 AM', value: '06:00:00' },
    { display: '6:30 AM', value: '06:30:00' },
];
const createHoursMenuItems = () => {
    const menuItems = [
        <MenuItem value={undefined} key="unset">
      &nbsp;
    </MenuItem>,
    ];
    for (let i = 0; i < hours.length; i += 1) {
        menuItems.push(<MenuItem value={hours[i].value} key={hours[i].value}>
        {hours[i].display}
      </MenuItem>);
    }
    return menuItems;
};
const HoursSelect = (_a) => {
    var { label = '', name = '' } = _a, rest = __rest(_a, ["label", "name"]);
    const inputLabel = React.useRef(null);
    return (<Field name={name}>
      {({ field }) => {
            return (<FormControl fullWidth>
            <InputLabel ref={inputLabel}>{label}</InputLabel>
            <Select {...field} {...rest} inputProps={{ name, id: name }}>
              {createHoursMenuItems()}
            </Select>
          </FormControl>);
        }}
    </Field>);
};
export default HoursSelect;
