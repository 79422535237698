import React from 'react';
import { Divider, Typography, useMediaQuery } from 'src/components/mui';
import { AlternateEmail as AlternateEmailIcon, LocationOn as LocationOnIcon, Phone as PhoneIcon, } from 'src/components/mui/icons';
import { AddressFieldsWrapper, AddressTypography, ContactInfoWrapper, LocationIconWrapper, MobileContactInfoWrapper, UserFieldsWrapper, UserItem, } from 'src/AdminApp/components/drawers/styledComponents';
import { formatPhone } from 'src/utils/formatter';
import { States } from 'src/AdminApp/models/enums';
import { normalizeAddress } from 'src/AdminApp/modules/utils';
import ConsumerTitle from './ConsumerTitle';
const AddressFields = ({ street, city, state, zip }) => {
    const stateShort = States.findByValue(state) || '';
    return (<AddressFieldsWrapper>
      <LocationIconWrapper>
        <LocationOnIcon />
      </LocationIconWrapper>
      <div style={{ paddingLeft: '80px' }}>
        <Typography variant="h5">{street}</Typography>
        <AddressTypography>
          {city}, {stateShort} {zip}
        </AddressTypography>
      </div>
    </AddressFieldsWrapper>);
};
const ConsumerInfo = ({ user }) => {
    const isMobile = useMediaQuery('(max-width: 1280px)');
    if (!user) {
        return null;
    }
    const { phone, email } = user;
    const address = normalizeAddress(user.address);
    return (<UserFieldsWrapper>
      <ConsumerTitle consumer={user}/>

      {!isMobile && (<>
          <ContactInfoWrapper>
            {phone && (<>
                <PhoneIcon /> {formatPhone(phone)}
              </>)}
            {email && (<>
                <AlternateEmailIcon /> {email}
              </>)}
          </ContactInfoWrapper>
          <Divider />
        </>)}
      {isMobile && (<MobileContactInfoWrapper>
          <UserItem>
            {phone && (<>
                <PhoneIcon /> {formatPhone(phone)}
              </>)}
          </UserItem>
          <Divider />
          <UserItem>
            {email && (<>
                <AlternateEmailIcon /> {email}
              </>)}
          </UserItem>
          <Divider />
        </MobileContactInfoWrapper>)}
      {(address === null || address === void 0 ? void 0 : address.zip) && (<>
          <AddressFields street={address === null || address === void 0 ? void 0 : address.street} city={address === null || address === void 0 ? void 0 : address.city} state={address === null || address === void 0 ? void 0 : address.state} zip={address === null || address === void 0 ? void 0 : address.zip}/>
          <Divider />
        </>)}
    </UserFieldsWrapper>);
};
export default ConsumerInfo;
