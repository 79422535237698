var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { getExtraTimeRequestReasons } from 'src/AdminApp/modules/extraTimeRequestReasons/actions';
import { selectVisitsStopwatch } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { addVisitExtraTimeRequest, getVisitStopwatch, clearVisitsStopwatch, stopVisitsStopwatch, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
const useVisitStopwatch = (visit) => {
    const dispatch = useDispatch();
    const stopwatch = useSelector(selectVisitsStopwatch());
    const [addingTime, setAddingTime] = useState(false);
    const [isDeduceTimeDisabled, setIsDeduceTimeDisabled] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(0);
    const [timeLeft, setTimeLeft] = useState('00:00');
    const [stopwatchInterval, setStopwatchInterval] = useState();
    const [constantUpdatesInterval, setConstantUpdatesInterval] = useState();
    const [isAddTimeDisabled, setIsAddTimeDisabled] = useState(false);
    const [isExtraTimeReasonModalOpened, setIsExtraTimeReasonModalOpened] = useState(false);
    const [isExtraMinutesModalOpened, setIsExtraMinutesModalOpened] = useState(false);
    const [extraTimeReason, setExtraTimeReason] = useState();
    const [extraMinutes, setExtraMinutes] = useState(0);
    const [extraTimeReasonOptions, setExtraTimeReasonOptions] = useState([]);
    const useComputedAvailableTime = secondsLeft > 900;
    const stopwatchIntervalRef = useRef(stopwatchInterval);
    stopwatchIntervalRef.current = stopwatchInterval;
    const constantUpdatesIntervalRef = useRef(constantUpdatesInterval);
    constantUpdatesIntervalRef.current = constantUpdatesInterval;
    const secondsLeftRef = useRef(secondsLeft);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const { payload } = yield dispatch(getExtraTimeRequestReasons(true));
            setExtraTimeReasonOptions(payload);
            dispatch(clearVisitsStopwatch());
        }))();
        return () => {
            clearInterval(stopwatchIntervalRef.current);
            clearInterval(constantUpdatesIntervalRef.current);
        };
    }, [dispatch]);
    useEffect(() => {
        if ((stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.availableTime) === undefined && !useComputedAvailableTime) {
            return;
        }
        clearInterval(stopwatchInterval);
        if (secondsLeft < 900) {
            setSecondsLeft(stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.availableTime);
            secondsLeftRef.current = stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.availableTime;
        }
        const interval = setInterval(() => {
            setSecondsLeft((prevState) => prevState - 1);
            secondsLeftRef.current -= 1;
        }, 1000);
        setStopwatchInterval(interval);
        clearInterval(constantUpdatesInterval);
        const updatesInterval = setInterval(() => {
            requestStopwatch();
        }, 30000);
        setConstantUpdatesInterval(updatesInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.availableTime]);
    const requestStopwatch = useCallback(() => {
        if ((visit === null || visit === void 0 ? void 0 : visit.status) !== 'CANCELED' && (visit === null || visit === void 0 ? void 0 : visit.status) !== 'COMPLETED') {
            const sleep = (ms) => {
                return new Promise((resolve) => {
                    setTimeout(resolve, ms);
                });
            };
            // If time left is more than 15 minutes the time will keep being calculated here instead of calling the stopwatch endpoint.
            if (secondsLeftRef.current > 900) {
                return;
            }
            // Retry mechanism is necessary since the endpoint may respond with
            // an invalid value due to a race condition
            const fetchAndRetry = () => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                let availableTime;
                for (let i = 0; i <= 2; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { payload } = yield dispatch(getVisitStopwatch((_a = visit === null || visit === void 0 ? void 0 : visit.refNum) !== null && _a !== void 0 ? _a : ''));
                    availableTime = payload.availableTime;
                    if (availableTime !== 0) {
                        return;
                    }
                    // eslint-disable-next-line no-await-in-loop
                    yield sleep(3000);
                }
            });
            (() => __awaiter(void 0, void 0, void 0, function* () {
                yield fetchAndRetry();
            }))();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, visit === null || visit === void 0 ? void 0 : visit.refNum, visit === null || visit === void 0 ? void 0 : visit.status]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const isVisitInProgress = (visit === null || visit === void 0 ? void 0 : visit.status) === 'IN_PROGRESS';
            const isStopwatchValid = (stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning) === undefined;
            if (isVisitInProgress && isStopwatchValid) {
                requestStopwatch();
            }
        }))();
    }, [dispatch, requestStopwatch, stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning, visit === null || visit === void 0 ? void 0 : visit.status]);
    useEffect(() => {
        const verifyAndStopStopwatch = () => {
            if ((visit === null || visit === void 0 ? void 0 : visit.status) === 'CANCELED' || (visit === null || visit === void 0 ? void 0 : visit.status) === 'COMPLETED') {
                setIsDeduceTimeDisabled(true);
                setIsAddTimeDisabled(true);
                clearInterval(stopwatchInterval);
                clearInterval(constantUpdatesInterval);
                dispatch(stopVisitsStopwatch());
            }
        };
        verifyAndStopStopwatch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visit === null || visit === void 0 ? void 0 : visit.status, dispatch]);
    const onExtraTimeRequestSubmit = useCallback(() => {
        setIsExtraMinutesModalOpened(false);
        setIsExtraTimeReasonModalOpened(false);
        let extraTimeRequest;
        if (addingTime) {
            extraTimeRequest = {
                secondsRequested: Number(extraMinutes) * 60,
                reason: extraTimeReason,
            };
        }
        else {
            extraTimeRequest = {
                secondsRequested: Number(extraMinutes) * -60,
                reason: 'Time deduction',
            };
        }
        if (visit) {
            dispatch(addVisitExtraTimeRequest(visit === null || visit === void 0 ? void 0 : visit.refNum, extraTimeRequest)).then(({ error }) => {
                if (!error) {
                    if (addingTime) {
                        toast.success('Extra time successfully requested.');
                    }
                    else {
                        toast.success('Time successfully deduced.');
                    }
                    secondsLeftRef.current = extraTimeRequest.secondsRequested;
                    requestStopwatch();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        addingTime,
        extraMinutes,
        dispatch,
        extraTimeReason,
        visit === null || visit === void 0 ? void 0 : visit.refNum,
        requestStopwatch,
    ]);
    return {
        stopwatch,
        addingTime,
        setAddingTime,
        isDeduceTimeDisabled,
        setIsDeduceTimeDisabled,
        secondsLeft,
        setSecondsLeft,
        timeLeft,
        setTimeLeft,
        isAddTimeDisabled,
        setIsAddTimeDisabled,
        isExtraTimeReasonModalOpened,
        setIsExtraTimeReasonModalOpened,
        isExtraMinutesModalOpened,
        setIsExtraMinutesModalOpened,
        extraTimeReason,
        setExtraTimeReason,
        extraMinutes,
        setExtraMinutes,
        extraTimeReasonOptions,
        setExtraTimeReasonOptions,
        onExtraTimeRequestSubmit,
        stopwatchInterval,
        setStopwatchInterval,
        useComputedAvailableTime,
    };
};
export default useVisitStopwatch;
