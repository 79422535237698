import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'src/components/tooltips';
import { HelpOutline as HelpOutlineIcon } from 'src/components/mui/icons';
import { PrimaryButton, ActionButton, DestructiveButton, } from 'src/components/buttons';
import { camelCase } from 'lodash';
const CtaRow = styled.div `
  display: flex;
  justify-content: ${({ isCantFinish, variant = 'text' }) => {
    if (isCantFinish) {
        return 'center';
    }
    if (variant === 'contained') {
        return 'flex-end';
    }
    return 'flex-start';
}};
  align-items: center;
  width: 100%;
`;
export const ActionsBlockMobile = ({ actions }) => {
    const isCantFinish = actions.some((element) => {
        var _a;
        return (_a = element === null || element === void 0 ? void 0 : element.copy) === null || _a === void 0 ? void 0 : _a.includes("Can't Complete");
    });
    if (isCantFinish) {
        const indexCantFinish = actions.findIndex((element) => {
            var _a;
            return (_a = element === null || element === void 0 ? void 0 : element.copy) === null || _a === void 0 ? void 0 : _a.includes("Can't Complete");
        });
        const cantFinish = actions[indexCantFinish];
        actions.splice(indexCantFinish, 1);
        actions.splice(0, 0, cantFinish);
    }
    function getActionStyle(isCantFinish, actions, currentAction) {
        if (isCantFinish) {
            if (actions.length === 3) {
                return {
                    fontSize: 12,
                    width: 115,
                    height: 50,
                    padding: 5,
                    fontWeight: 650,
                };
            }
            if (actions.length >= 4) {
                return {
                    fontSize: 10.1,
                    width: 87,
                    height: 35,
                    padding: 5,
                    fontWeight: 650,
                };
            }
        }
        return currentAction.style;
    }
    return (<>
      {actions.map((action) => {
            var _a, _b, _c, _d, _e;
            if (action.button === 'menu') {
                return null;
            }
            let TheButton;
            switch (action.button) {
                case 'action':
                    TheButton = ActionButton;
                    break;
                case 'destructive':
                    TheButton = DestructiveButton;
                    break;
                default:
                    TheButton = PrimaryButton;
                    break;
            }
            const actionVariant = (_a = action.mobileVariant) !== null && _a !== void 0 ? _a : 'contained';
            const button = (<CtaRow key={(_b = action.copy) !== null && _b !== void 0 ? _b : 'action'} variant={actionVariant} isCantFinish={isCantFinish}>
            {action.tooltipIcon && (<div>
                <Tooltip title={action.tooltipIcon}>
                  <HelpOutlineIcon style={{
                        color: 'red',
                        marginRight: '5px',
                    }}/>
                </Tooltip>
              </div>)}
            <TheButton variant={actionVariant} disabled={action.disabled} onClick={action.function} key={(_c = action.copy) !== null && _c !== void 0 ? _c : 'action'} fullWidth={action.fullWidth} disableElevation={action.disableElevation} style={getActionStyle(isCantFinish, actions, action)} className={`${camelCase(((_d = action.copy) !== null && _d !== void 0 ? _d : 'action').replace(/\s|\//g, ''))}-cta`}>
              {action.copy}
            </TheButton>
          </CtaRow>);
            return action.tooltip ? (<Tooltip title={action.tooltip} key={(_e = action.copy) !== null && _e !== void 0 ? _e : 'action'}>
            <div>{button}</div>
          </Tooltip>) : (button);
        })}
    </>);
};
