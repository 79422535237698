import React from 'react';
import { useSelector } from 'react-redux';
import { selectPaymentSubTypes } from 'src/AdminApp/modules/lookups/selectors';
import { selectPaymentDrawerPaymentType, selectPaymentDrawerPaymentSubtype, } from 'src/AdminApp/modules/paymentDrawer/selectors';
import { isEmpty } from 'lodash';
import { TextField } from 'src/AdminApp/components/form';
import PaymentSubTypeSelect from '../PaymentSubTypeSelect';
import { FlowWindow, PaymentField, Warning } from './RealPaymentDetails.styles';
const RealPaymentDetails = () => {
    const paymentType = useSelector(selectPaymentDrawerPaymentType);
    const paymentSubtype = useSelector(selectPaymentDrawerPaymentSubtype);
    const paymentSubTypes = useSelector(selectPaymentSubTypes);
    const formatPaymentType = (paymentType) => {
        if (!paymentType)
            return '';
        switch (paymentType) {
            case 'ACH Payment':
                return ' an ACH Payment';
            default:
                return ` a ${paymentType.toLowerCase()}`;
        }
    };
    return (<FlowWindow>
      {!isEmpty(paymentSubTypes) && !paymentSubtype && (<PaymentField>
          <PaymentSubTypeSelect value={paymentSubtype} fullWidth label="Payment Subtype" name="paymentSubtype"/>
        </PaymentField>)}
      <PaymentField>
        <TextField variant="outlined" type="text" label="Reference Number" name="referenceNumber"/>
      </PaymentField>
      <Warning>
        Warning - Only for logging transactions, not to be used for processing
        {formatPaymentType(paymentType)} transaction. Please process there and
        log here afterward.
      </Warning>
    </FlowWindow>);
};
export default RealPaymentDetails;
