import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleChip from 'src/components/SimpleChip';
import { getFleetNames, setVisitFleetIds, setVisitFleetStations, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { selectFleetNames } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import useDebounce from 'src/modules/api/utils/useDebounce';
import { colors } from 'src/styles/theme';
import { FilterContainer, FilterIcon, FilterText, FilterAutocomplete, FilterChip, FilterChipWrapper, } from './VisitFilter.styles';
const OPTIONS_LIMIT = 20;
const VisitFilter = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, 500);
    const dispatch = useDispatch();
    const fleetNames = useSelector(selectFleetNames);
    useEffect(() => {
        const storedFilters = localStorage.getItem('fleetVisitsFilter');
        if (storedFilters) {
            setSelectedItems(JSON.parse(storedFilters));
        }
    }, []);
    const items = fleetNames
        .filter((i) => {
        return !selectedItems.find((j) => j.id === i.id);
    })
        .slice(0, OPTIONS_LIMIT);
    const getOptionItem = (i) => { var _a; return (_a = i === null || i === void 0 ? void 0 : i.title) !== null && _a !== void 0 ? _a : ''; };
    const requestFleetNames = () => {
        const filteredValue = inputValue
            .split(' ')
            .map((chunk) => chunk.replace(/\W/g, '-'))
            .join(' ');
        dispatch(getFleetNames({ text: filteredValue }));
    };
    const handleAddItem = (event, value) => {
        if (!value)
            return;
        let newItem;
        if (typeof value === 'string') {
            const textInItems = typeof value === 'string' &&
                items.find((item) => item.title.toLowerCase() === value.toLowerCase());
            if (!textInItems)
                return;
            newItem = textInItems;
        }
        else {
            newItem = value;
        }
        const newChips = [...selectedItems, newItem];
        localStorage.setItem('fleetVisitsFilter', JSON.stringify(newChips));
        setSelectedItems(newChips);
        setTimeout(() => {
            setInputValue('');
        }, 200);
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };
    useEffect(() => {
        requestFleetNames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedInputValue]);
    const handleDeleteItem = (item) => () => {
        const newChips = selectedItems.filter((j) => j.id !== item.id);
        localStorage.setItem('fleetVisitsFilter', JSON.stringify(newChips));
        setSelectedItems(newChips);
    };
    const renderTextInput = (params) => (<FilterText {...params} className={`${params.className} VisitFilterInput`} placeholder="Filter by Fleet or Station"/>);
    useEffect(() => {
        const fleets = selectedItems
            .filter((item) => item.type === 'fleet')
            .map((item) => item.id)
            .join(',');
        const stations = selectedItems
            .filter((item) => item.type === 'station')
            .map((item) => item.title)
            .join(',');
        dispatch(setVisitFleetIds(fleets));
        dispatch(setVisitFleetStations(stations));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);
    return (<>
      <FilterContainer>
        <FilterIcon />
        <FilterAutocomplete options={items} getOptionLabel={getOptionItem} freeSolo onChange={handleAddItem} renderInput={renderTextInput} inputValue={inputValue} onInputChange={handleInputChange}/>
      </FilterContainer>
      <FilterChipWrapper>
        {selectedItems.map((item) => (<FilterChip key={item.id}>
            <SimpleChip label={item.title} $textColor={colors.white} onDelete={handleDeleteItem(item)} $bgColor={item.type === 'fleet' ? colors.black : colors.BLUE} $deleteIconColor={colors.lightGrey}/>
          </FilterChip>))}
      </FilterChipWrapper>
    </>);
};
export default VisitFilter;
