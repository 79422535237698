import React from 'react';
import { getSquareMakeLogo } from 'src/AdminApp/containers/vehicle/utils';
import { ChevronRightRounded as ChevronRightRoundedIcon } from 'src/components/mui/icons';
import { CarMakeLogo, FleetVehicles, MoreIconButton, } from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/pages/vehicle/Garage/components/FleetVehiclesTable/FleetVehiclesTable.styles';
const FleetVehiclesTable = ({ vehicles, onSelectVehicle, }) => {
    return (<FleetVehicles>
      <thead>
        <tr>
          <th>Make</th>
          <th>Vehicle</th>
          <th>VIN</th>
          <th>Plate</th>
          <th>Mileage</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {vehicles.map((vehicle) => {
            var _a, _b;
            return (<tr key={vehicle.id} onClick={() => {
                    onSelectVehicle(vehicle);
                }}>
            <td>
              <CarMakeLogo alt={vehicle.make} src={getSquareMakeLogo(vehicle.make, '@3x')}/>
            </td>
            <td> {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</td>
            <td>{(_a = vehicle.vin) !== null && _a !== void 0 ? _a : 'Not Specified'}</td>
            <td>{(_b = vehicle.plateNumber) !== null && _b !== void 0 ? _b : 'Not Specified'}</td>
            <td>{vehicle.mileage} miles</td>
            <td>
              <MoreIconButton onClick={() => {
                    onSelectVehicle(vehicle);
                }}>
                <ChevronRightRoundedIcon />
              </MoreIconButton>
            </td>
          </tr>);
        })}
      </tbody>
    </FleetVehicles>);
};
export default FleetVehiclesTable;
