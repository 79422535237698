import { handleActions } from 'redux-actions';
import { fetching, fetched } from 'src/utils/data-utils';
import { ActionCreators } from './actions';
import { mapConsumer, mapConsumerCar, mapPagination, mapRepairRequest, } from '../mappers';
const DEFAULT_STATE = {
    byId: {},
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['id,asc'],
        },
    },
};
const consumerSuccessReducer = (state, action) => {
    const consumerInfo = mapConsumer(action.payload);
    const consumerInfoId = consumerInfo.id;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerInfoId]: Object.assign(Object.assign({}, state.byId[consumerInfoId]), fetched(consumerInfo)) }) });
};
const consumersSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const fetchConsumerDashboardReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const clearConsumerDashboardReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const consumerUpdateSuccessReducer = (state, action) => {
    const { consumerId } = action.meta;
    const consumerInfo = mapConsumer(action.payload);
    const consumerInfoId = consumerInfo.id;
    const thisConsumerState = state === null || state === void 0 ? void 0 : state.byId[consumerId];
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerInfoId]: fetched(Object.assign(Object.assign({}, thisConsumerState), consumerInfo)) }) });
};
const resolveZendeskUserIdSuccessReducer = (state, action) => {
    const { consumerId } = action.meta;
    const zendeskUserId = action.payload;
    const thisConsumerState = state === null || state === void 0 ? void 0 : state.byId[consumerId];
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerId]: fetched(Object.assign(Object.assign({}, thisConsumerState), { zendeskUserId })) }) });
};
const successRoCreditsReducer = (state, action) => {
    const { consumerId } = action.meta;
    const roCredits = action.payload;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerId]: fetched(Object.assign(Object.assign({}, state.byId[consumerId]), { roCredits })) }) });
};
const successCancelFeeCreditsReducer = (state, action) => {
    const { consumerId } = action.meta;
    const cancelFeeCredits = action.payload;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerId]: fetched(Object.assign(Object.assign({}, state.byId[consumerId]), { cancelFeeCredits })) }) });
};
const vehiclesSuccessReducer = (state, action) => {
    var _a, _b, _c, _d, _e;
    const { consumerId, isDrawer } = action.meta;
    const vehicles = (_b = (_a = action.payload.content) === null || _a === void 0 ? void 0 : _a.map(mapConsumerCar)) !== null && _b !== void 0 ? _b : [];
    const currentVehicles = (_e = (_d = (_c = state.byId[consumerId]) === null || _c === void 0 ? void 0 : _c.vehicles) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : [];
    const mergedUniqueMap = new Map([...vehicles, ...currentVehicles].map((item) => [item.id, item]));
    const mergedUnique = isDrawer
        ? Array.from(mergedUniqueMap.values())
        : [...vehicles];
    mergedUnique.sort((a, b) => a.id - b.id);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerId]: Object.assign(Object.assign({}, state.byId[consumerId]), { vehicles: fetched({
                    params: {
                        pagination: action.meta.pagination,
                    },
                    pagination: mapPagination(action.payload),
                    data: mergedUnique,
                }) }) }) });
};
const addVehicleSuccessReducer = (state, action) => {
    const { consumerId } = action.meta;
    const { vehicles } = state.byId[consumerId];
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerId]: fetched(Object.assign(Object.assign({}, state.byId[consumerId]), { vehicles: Object.assign(Object.assign({}, vehicles), { data: [...vehicles.data, action.payload] }) })) }) });
};
const fetchConsumerVehicleReducer = (state, action) => {
    const { consumerId } = action.meta;
    const consumer = state.byId[consumerId] || {};
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerId]: Object.assign(Object.assign({}, consumer), { vehicles: fetching(consumer.vehicles || {}) }) }) });
};
const fetchConsumerRequestHistoryReducer = (state, action) => {
    const { consumerId } = action.meta;
    const consumer = state.byId[consumerId] || {};
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerId]: Object.assign(Object.assign({}, consumer), { requestHistory: fetching(consumer.requestHistory || {}) }) }) });
};
const successConsumerRequestHistoryReducer = (state, action) => {
    const { consumerId } = action.meta;
    const consumer = state.byId[consumerId] || {};
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerId]: Object.assign(Object.assign({}, consumer), { requestHistory: fetched({
                    params: {
                        pagination: action.meta.pagination,
                    },
                    pagination: mapPagination(action.payload),
                    data: action.payload.content,
                }) }) }) });
};
const updateVehicleMileageById = (state, consumerId, consumerCarId, mileage) => {
    var _a, _b, _c;
    return [
        ...((_c = (_b = (_a = state.byId[consumerId]) === null || _a === void 0 ? void 0 : _a.vehicles) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : []).map((vehicle) => vehicle.id === consumerCarId ? Object.assign(Object.assign({}, vehicle), { mileage }) : vehicle),
    ];
};
const successAddRepairRequestReducer = (state, action) => {
    const { consumerId, consumerCarId, mileage } = action.meta;
    const request = mapRepairRequest(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [consumerId]: Object.assign(Object.assign({}, state.byId[consumerId]), { vehicles: updateVehicleMileageById(state, consumerId, consumerCarId, mileage), request }) }) });
};
const consumerGetNotesSuccessReducer = (state, action) => {
    var _a;
    const content = ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.content) || [];
    const internalNote = content[0];
    return internalNote
        ? Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [internalNote.entityId]: Object.assign(Object.assign({}, state.byId[internalNote.entityId]), { internalNote }) }) }) : Object.assign({}, state);
};
const consumerUpdateNotesSuccessReducer = (state, action) => {
    const internalNote = action.payload;
    return internalNote
        ? Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [internalNote.entityId]: Object.assign(Object.assign({}, state.byId[internalNote.entityId]), { internalNote }) }) }) : Object.assign({}, state);
};
const consumerCarTokensSuccessReducer = (state, action) => {
    const tokens = action.payload;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [action.meta.consumerId]: Object.assign(Object.assign({}, state.byId[action.meta.consumerId]), { carTokens: tokens }) }) });
};
const reducers = handleActions({
    [ActionCreators.CONSUMER.SUCCESS.type]: consumerSuccessReducer,
    [ActionCreators.CONSUMERS.SUCCESS.type]: consumersSuccessReducer,
    [ActionCreators.CONSUMERS.FETCH.type]: fetchConsumerDashboardReducer,
    [ActionCreators.CONSUMERS.CLEAR.type]: clearConsumerDashboardReducer,
    [ActionCreators.CONSUMER_UPDATE.SUCCESS.type]: consumerUpdateSuccessReducer,
    [ActionCreators.CONSUMER_VEHICLES.SUCCESS.type]: vehiclesSuccessReducer,
    [ActionCreators.CONSUMER_VEHICLES.FETCH.type]: fetchConsumerVehicleReducer,
    [ActionCreators.ADD_VEHICLE.SUCCESS.type]: addVehicleSuccessReducer,
    [ActionCreators.CONSUMER_REQUEST_HISTORY.FETCH.type]: fetchConsumerRequestHistoryReducer,
    [ActionCreators.CONSUMER_REQUEST_HISTORY.SUCCESS.type]: successConsumerRequestHistoryReducer,
    [ActionCreators.RESOLVE_ZENDESK_USER_ID.SUCCESS.type]: resolveZendeskUserIdSuccessReducer,
    [ActionCreators.ADD_REQUEST.SUCCESS.type]: successAddRepairRequestReducer,
    [ActionCreators.RO_CREDITS.SUCCESS.type]: successRoCreditsReducer,
    [ActionCreators.CANCEL_FEE_CREDITS.SUCCESS.type]: successCancelFeeCreditsReducer,
    [ActionCreators.CONSUMER_GET_NOTES.SUCCESS.type]: consumerGetNotesSuccessReducer,
    [ActionCreators.CONSUMER_CREATE_NOTES.SUCCESS.type]: consumerUpdateNotesSuccessReducer,
    [ActionCreators.CONSUMER_UPDATE_NOTES.SUCCESS.type]: consumerUpdateNotesSuccessReducer,
    [ActionCreators.CONSUMER_CAR_GET_TOKENS.SUCCESS.type]: consumerCarTokensSuccessReducer,
}, DEFAULT_STATE);
export default reducers;
