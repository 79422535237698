var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CheckCircle, RemoveCircle, Warning } from 'src/components/mui/icons';
import { CircularProgress, InputAdornment } from 'src/components/mui';
import styled from 'styled-components';
import { validateServiceAreasByZipCode, clearRegionsData, clearRegionFilters, } from 'src/AdminApp/modules/regions/actions';
import { Tooltip } from 'src/components/tooltips';
import { colors } from 'src/styles/theme';
import PlacesAutocomplete from './PlacesAutocomplete';
const PointerSpan = styled.span `
  cursor: pointer;
`;
const ValidServiceAreaIcon = styled(CheckCircle) `
  color: ${colors.success};
  font-size: 20px;
`;
const OutOfServiceAreaIcon = styled(RemoveCircle) `
  color: ${colors.redAB};
  font-size: 22px;
`;
const NoZipCodeIcon = styled(Warning) `
  color: ${colors.warning};
  font-size: 20px;
`;
const PlacesACWithServiceAreas = ({ address, onSelect, classes, displayMap, inputLabelProps, inputProps, textFieldParams, setIsZipCodeValid, }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [isNoZipCode, setIsNoZipCode] = useState(false);
    const [isOutOfServiceArea, setIsOutOfServiceArea] = useState(false);
    const [isInSA, setIsInSA] = useState(false);
    const dispatch = useDispatch();
    const _setIsZipCodeValid = (val) => {
        if (setIsZipCodeValid) {
            setIsZipCodeValid(val);
        }
    };
    const resetState = () => {
        setIsFetching(false);
        setIsNoZipCode(false);
        setIsOutOfServiceArea(false);
        setIsInSA(false);
        _setIsZipCodeValid(false);
    };
    const setNoZipCode = () => {
        setIsFetching(false);
        setIsNoZipCode(true);
    };
    const setOutOfServiceArea = () => {
        setIsFetching(false);
        setIsOutOfServiceArea(true);
    };
    const setServiceAreas = (names) => {
        setIsFetching(false);
        setIsInSA(names);
        _setIsZipCodeValid(true);
    };
    const loadServiceAreas = (zip) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const result = yield dispatch(validateServiceAreasByZipCode({
            filters: {
                zipCode: zip,
            },
        }));
        const serviceAreasAreValid = ((_a = result === null || result === void 0 ? void 0 : result.payload) === null || _a === void 0 ? void 0 : _a.status) !== 404;
        dispatch(clearRegionsData());
        dispatch(clearRegionFilters());
        return serviceAreasAreValid;
    });
    const onPlacesSelect = (place) => __awaiter(void 0, void 0, void 0, function* () {
        onSelect(place);
        if (!place)
            return resetState();
        setIsFetching(true);
        setIsNoZipCode(false);
        setIsOutOfServiceArea(false);
        setIsInSA(false);
        _setIsZipCodeValid(false);
        const { zip } = place;
        if (!zip)
            return setNoZipCode();
        const serviceAreas = yield loadServiceAreas(zip);
        if (!serviceAreas)
            return setOutOfServiceArea();
        setServiceAreas('ok');
    });
    const getEndAdornment = () => {
        if (isFetching) {
            return <CircularProgress size={20}/>;
        }
        if (isNoZipCode) {
            return (<Tooltip title="No Zip Code">
          <PointerSpan>
            <NoZipCodeIcon />
          </PointerSpan>
        </Tooltip>);
        }
        if (isOutOfServiceArea) {
            return (<Tooltip title="Out of Service Area">
          <PointerSpan>
            <OutOfServiceAreaIcon />
          </PointerSpan>
        </Tooltip>);
        }
        if (isInSA) {
            return (<Tooltip title={isInSA.toString()}>
          <PointerSpan>
            <ValidServiceAreaIcon />
          </PointerSpan>
        </Tooltip>);
        }
        return <div>Enter location</div>;
    };
    return (<PlacesAutocomplete address={address} onSelect={onPlacesSelect} classes={classes} displayMap={displayMap !== null && displayMap !== void 0 ? displayMap : false} inputLabelProps={inputLabelProps} inputProps={Object.assign({ endAdornment: (<InputAdornment position="end">{getEndAdornment()}</InputAdornment>) }, inputProps)} textFieldParams={Object.assign({}, textFieldParams)}/>);
};
export default PlacesACWithServiceAreas;
