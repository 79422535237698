import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { TextField, useMediaQuery } from 'src/components/mui';
import { Helmet } from 'react-helmet';
import { theme } from 'src/styles/theme';
import { getComponentsMarkUp, getWrongPartNumbersByComponent, } from 'src/PopsApp/modules/parts/actions';
import { QuotedPriceMode } from 'src/AdminApp/models/enums';
import PartsButton from 'src/PopsApp/components/PartsButton';
import { AccordionContent, AccordionHeader, PartsAccordion, } from 'src/PopsApp/components/PartsAccordion/PartsAccordion';
import { selectDistributor, selectOrderParts, selectSelectedJobs, selectTopPriorityOrder, selectVan, selectWrongPartNumbers, } from 'src/PopsApp/modules/parts/selectors';
import PickPartDrawer from 'src/PopsApp/pages/Order/components/PickPartDrawer';
import VendorInfo from 'src/PopsApp/components/VendorInfo/VendorInfo';
import { selectComponentsAvailability } from '../../modules/vendors/selectors';
import CarInfo from '../Order/components/CarInfo';
import EditPart from '../Order/components/EditPart';
import EditDialog from '../Order/components/EditDialog';
import OrderParts from '../Order/components/OrderParts';
import OrderCompletedDrawer from './components/OrderCompletedDrawerPrioritized';
import Delivery from '../Order/components/Delivery/Delivery';
import { ActionPanel, ConfirmationNumberMobileWrapper, ConfirmationNumberTitle, Container, } from '../Order/Order.styles';
import useOrder from '../Order/useOrder';
const Order = () => {
    var _a, _b, _c, _d, _e;
    const topPriorityOrder = useSelector(selectTopPriorityOrder);
    const van = useSelector(selectVan);
    const selectedJobs = useSelector(selectSelectedJobs);
    const distributor = useSelector(selectDistributor);
    const orderParts = useSelector(selectOrderParts);
    const componentsAvailability = (_a = useSelector(selectComponentsAvailability)) !== null && _a !== void 0 ? _a : {};
    const wrongPartNumbers = useSelector(selectWrongPartNumbers);
    const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
    const [partsOpen, setPartsOpen] = useState(orderParts.length > 0);
    const { parts, selectedPart, setSelectedPart, completingOrder, dialogSettings, openCompleteDrawer, setOpenCompleteDrawer, newComponentType, setNewComponentType, setNewComponent, deleteComponent, setIsIntegrated, pickupMethod, setPickupMethod, reloadsJobsByVan, handleSelectPart, handleSaveNextClick, handleConfirmationNumberChange, handleAccordionClick, handleChangeEvent, handleBlurEvent, handleEdit, handlePickPart, handlePickPartSelect, handleCompleteOrder, openPartsDialog, setOpenPartsDialog, openEditDialog, setOpenEditDialog, vendorOpen, setVendorOpen, markupsMap, setMarkups, } = useOrder({ ispId: topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.ispId, isPrioritized: true });
    const dispatch = useDispatch();
    const { shift } = useParams();
    const history = useHistory();
    useEffect(() => {
        if (!van && !selectedJobs) {
            history.push(`/pops/${shift}/jobs`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedJobs, van]);
    useEffect(() => {
        if (!markupsMap && (parts === null || parts === void 0 ? void 0 : parts.length) > 0) {
            const repairComponentIds = parts
                .filter((p) => { var _a, _b; return (_b = !((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.isFluid)) !== null && _b !== void 0 ? _b : false; })
                .filter((p) => { var _a; return QuotedPriceMode.RANGED === ((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.price.quotedPriceMode); })
                .map((p) => p.pricingData.repairComponentId);
            if ((repairComponentIds === null || repairComponentIds === void 0 ? void 0 : repairComponentIds.length) > 0) {
                dispatch(getComponentsMarkUp(repairComponentIds)).then((result) => {
                    setMarkups(result.payload);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parts]);
    useEffect(() => {
        var _a, _b, _c;
        if (selectedPart &&
            selectedPart.status === 'WRONG_PART' &&
            selectedPart.id &&
            !wrongPartNumbers[selectedPart.id]) {
            dispatch(getWrongPartNumbersByComponent((_b = (_a = selectedPart.meta) === null || _a === void 0 ? void 0 : _a.repair) === null || _b === void 0 ? void 0 : _b.id, selectedPart.id, ((_c = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _c === void 0 ? void 0 : _c.isFluid) ? 'FLUID' : 'PART'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPart]);
    return (<>
      <Container className="orderContainer">
        <div className="ordersMain">
          <h1 className="regionsTitle">Order Parts & Fluids</h1>
          {van && (<Helmet>
              <title>{(_b = van.shopName) !== null && _b !== void 0 ? _b : 'N/A'} - Order Parts</title>
            </Helmet>)}

          <div className="accordions">
            <PartsAccordion>
              <AccordionHeader className="accHeader" isOpen={vendorOpen} onClick={handleAccordionClick(vendorOpen, setVendorOpen)}>
                {distributor === null || distributor === void 0 ? void 0 : distributor.partDistributorName}
              </AccordionHeader>
              <AccordionContent className="accContent" isOpen={vendorOpen}>
                <div className="vendorEdit" role="button" tabIndex={0} onKeyDown={() => {
            // do nothing
        }} onClick={() => {
            history.push(`/pops/${shift}/jobs`);
        }}>
                  {distributor === null || distributor === void 0 ? void 0 : distributor.address}
                  <button type="button">Edit</button>
                </div>
              </AccordionContent>
            </PartsAccordion>
            <PartsAccordion>
              <AccordionHeader className="accHeader" isOpen={partsOpen} onClick={handleAccordionClick(partsOpen, setPartsOpen)}>
                Parts ({orderParts.length})
              </AccordionHeader>
              <AccordionContent className="accContent" isOpen={partsOpen}>
                <OrderParts selectedPart={selectedPart} selectedJobs={selectedJobs !== null && selectedJobs !== void 0 ? selectedJobs : []} onEdit={(order) => {
            handleEdit(order);
        }}/>
              </AccordionContent>
            </PartsAccordion>
          </div>

          <div>
            <h3 className="repairJobs">Repair Jobs</h3>
            {(_c = selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.map((job, idx) => (<CarInfo key={job.repairOrderId} parts={parts} job={job} jobNumber={idx + 1} jobsCount={selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.length} selectedPart={selectedPart} onSelectPart={handleSelectPart} setNewComponentType={setNewComponentType} onReload={reloadsJobsByVan} deleteComponent={(repairId, componentId, componentType, isCustom, removeDynamicRule) => deleteComponent({
                repairId,
                componentId,
                componentType,
                isCustom,
                removeDynamicRule,
            })} newComponentType={newComponentType} onChangeInput={handleChangeEvent} onSaveNext={handleSaveNextClick}/>))) !== null && _c !== void 0 ? _c : null}

            <ConfirmationNumberMobileWrapper>
              <ConfirmationNumberTitle>
                Add Vendor Confirmation #
              </ConfirmationNumberTitle>
              <TextField variant="outlined" placeholder="Vendor Confirmation # (optional)" onChange={handleConfirmationNumberChange}/>
            </ConfirmationNumberMobileWrapper>
          </div>
          <ActionPanel>
            <p className="partsAdded">
              {' '}
              {orderParts.length} Part{(orderParts === null || orderParts === void 0 ? void 0 : orderParts.length) === 1 ? '' : 's'}{' '}
              Added
            </p>
            <PartsButton disabled={!orderParts || !orderParts.length || completingOrder} className="button" onClick={handleCompleteOrder}>
              Complete Order
            </PartsButton>
          </ActionPanel>
        </div>

        <div className="ordersActions">
          <div className="floatingActionsPanel">
            <VendorInfo distributor={distributor} onClick={() => {
            history.push(`/pops/${shift}/jobs?editVendor=true`);
        }}/>

            <OrderCompletedDrawer open={openCompleteDrawer} onClose={() => {
            setOpenCompleteDrawer(false);
        }} integratedSkeduloId={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.integratedSkeduloId} integratedSkeduloJobName={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.integratedSkeduloJobName} skeduloId={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.skeduloId} skeduloJobName={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.skeduloJobName} orderSuccess={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.success} allJobsOrdered={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.allJobsOrdered} editVendor={() => {
            history.push(`/pops/${shift}/jobs?editVendor=true`);
        }} processing={completingOrder} integratedFailedOrderJobs={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.integratedFailedOrderjobs} allIntegratedPartsOrdered={dialogSettings === null || dialogSettings === void 0 ? void 0 : dialogSettings.allIntegratedPartsOrdered}/>

            <h3 className="actionsTitle">Pick-up Method</h3>
            <Delivery ispId={topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.ispId} distributor={distributor} selectedJobs={selectedJobs} pickupMethod={pickupMethod} setPickupMethod={setPickupMethod}/>

            <h3 className="actionsTitle">Cart</h3>

            <PartsAccordion className="accordion">
              <AccordionHeader className="accHeader" isOpen={partsOpen} onClick={handleAccordionClick(partsOpen, setPartsOpen)}>
                Parts ({orderParts.length})
              </AccordionHeader>
              <AccordionContent className="accContent" isOpen={partsOpen}>
                <OrderParts selectedPart={selectedPart} selectedJobs={selectedJobs} onEdit={(order) => {
            handleEdit(order);
        }}/>
              </AccordionContent>
            </PartsAccordion>
            {selectedPart ? (<EditPart part={selectedPart} onChangeInput={handleChangeEvent} onBlurInput={handleBlurEvent} onSaveNext={handleSaveNextClick} isFluid={(_e = (_d = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _d === void 0 ? void 0 : _d.isFluid) !== null && _e !== void 0 ? _e : false} onPickPart={handlePickPart} componentsAvailability={componentsAvailability} isDialog={false} setIsIntegrated={(value) => setIsIntegrated(value)} setSelectedPart={setSelectedPart} distributor={distributor} wrongPartNumbers={wrongPartNumbers}/>) : null}

            <div className="divider"/>

            <PartsButton disabled={!orderParts || !orderParts.length || completingOrder} className="button" onClick={handleCompleteOrder}>
              Complete Order
            </PartsButton>
          </div>
        </div>
      </Container>
      <EditDialog part={selectedPart} isOpen={openEditDialog} onClose={() => {
            if (newComponentType) {
                setNewComponentType(undefined);
                setNewComponent({});
            }
            setOpenEditDialog(false);
        }} onChange={handleChangeEvent} onBlurInput={handleBlurEvent} onSaveNext={handleSaveNextClick} handleEdit={handleEdit} handlePickPart={() => {
            setOpenPartsDialog(true);
        }} componentsAvailability={componentsAvailability} setIsIntegrated={(value) => setIsIntegrated(value)} setSelectedPart={setSelectedPart} distributor={distributor}/>
      <PickPartDrawer open={openPartsDialog} onClose={() => {
            setOpenPartsDialog(false);
            if (!mdAndUp) {
                setOpenEditDialog(true);
            }
        }} onSelect={handlePickPartSelect} selectedPart={selectedPart}/>
    </>);
};
export default Order;
