import React from 'react';
import { sortBy } from 'lodash';
import { TableBody, TableCell, TableHead, TableRow, useMediaQuery, } from 'src/components/mui';
import { TableWrapper } from 'src/AdminApp/containers/payments/PaymentModule/styledComponents.styles';
import { theme } from 'src/styles/theme';
import PaymentRow from './PaymentRow';
import { TableStyled } from './styledComponents';
import PaymentPod from './PaymentPod';
const PaymentsTable = ({ payments = [], onRemovePayment, }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    if (!payments)
        return null;
    if (isMobile) {
        return (<TableWrapper>
        {sortBy(payments, (p) => p.created).map((payment) => (<PaymentPod key={payment.id} payment={payment} onRemovePayment={onRemovePayment}/>))}
      </TableWrapper>);
    }
    return (<TableStyled>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Error</TableCell>
          <TableCell>Method</TableCell>
          <TableCell>Ref #</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell align="right"/>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortBy(payments, (p) => p.created).map((payment) => (<PaymentRow key={payment.id} payment={payment} onRemovePayment={onRemovePayment}/>))}
      </TableBody>
    </TableStyled>);
};
export default PaymentsTable;
