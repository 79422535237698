var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOM_REPAIR_ID, FMC_CUSTOM_REPAIR_ID, } from 'src/AdminApp/modules/api/constants';
import { selectVehicle } from 'src/AdminApp/modules/quickRo/selectors';
import { searchRepairs } from 'src/AdminApp/modules/requests/actions';
import FmcRepairSearchSelect from 'src/components/form/FmcRepairSearchSelect';
import RepairSearchSelect from 'src/components/form/RepairSearchSelect';
import { SimpleModal } from 'src/components/layout';
import { Grid, useMediaQuery } from 'src/components/mui';
const SearchService = ({ onSelect, isAutoIntegrateFmc = false, }) => {
    const dispatch = useDispatch();
    const isTablet = useMediaQuery('(max-width: 768px)');
    const vehicle = useSelector(selectVehicle);
    const [isFmcDialogOpen, setFmcDialogOpen] = useState(false);
    const [customRepairText, setCustomRepairText] = useState('');
    const [fmcRepairId, setFmcRepairId] = useState(null);
    const closeFmcDialog = () => {
        setFmcDialogOpen(false);
    };
    const onFmcRepairSelect = (selectedFmcRepair) => {
        setFmcRepairId(selectedFmcRepair.fmcId);
    };
    const handleCustomRepair = (repairDescription) => {
        if (isAutoIntegrateFmc) {
            setCustomRepairText(repairDescription);
            setFmcDialogOpen(true);
        }
        else {
            handleCustomRepairSubmit(repairDescription, false, null);
        }
    };
    const handleCustomFmcRepairSubmit = () => {
        closeFmcDialog();
        handleCustomRepairSubmit(customRepairText, true, fmcRepairId);
    };
    const handleCustomRepairSubmit = (repairDescription, isAIFmc, fmcRepairId) => __awaiter(void 0, void 0, void 0, function* () {
        onSelect({
            repairId: isAIFmc ? FMC_CUSTOM_REPAIR_ID : CUSTOM_REPAIR_ID,
            customRepairText: repairDescription,
            highlight: repairDescription,
            label: repairDescription,
            fmcCustomRepairId: fmcRepairId,
        });
    });
    return (<Grid container direction={isTablet ? 'column' : 'row'}>
      <Grid item xs={12}>
        <RepairSearchSelect onSearch={(data) => {
            return dispatch(searchRepairs(data, vehicle === null || vehicle === void 0 ? void 0 : vehicle.fleetId));
        }} onSelect={(repair, internalApprovalProvidedById) => {
            return onSelect(Object.assign(Object.assign({}, repair), { internalApprovalProvidedById }));
        }} onCustomRepairSubmit={handleCustomRepair} InputProps={{ variant: 'outlined', label: undefined }} autoFocus/>
      </Grid>
      <SimpleModal fullWidth open={isFmcDialogOpen} onClose={closeFmcDialog} title={`You will need to select the matching repair in AutoIntegrates catalog for
        ${customRepairText}`} simpleTwoButtonRowProps={{
            onSubmit: handleCustomFmcRepairSubmit,
            onCancel: closeFmcDialog,
            disabledSubmit: !fmcRepairId,
        }}>
        <FmcRepairSearchSelect onSelect={onFmcRepairSelect}/>
      </SimpleModal>
    </Grid>);
};
export default SearchService;
