import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const ServicesList = styled.ul `
  padding-left: 0;
  list-style: none;
  margin: 5px 0 0;
  display: inline-block;
`;
export const DetailsValue = styled.span `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_3F};
`;
export const MoreServiceLabel = styled.span `
  color: ${colors.GRAY_76};
  padding-left: 5px;
  display: inline;
  position: relative;
  white-space: nowrap;
`;
