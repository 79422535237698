import { createSelector } from 'reselect';
import PartPickupItemStatus from 'src/AdminApp/models/enums/PartPickupItemStatus';
export const selectTechApp = (state) => state.techApp;
export const selectTechPartPickup = createSelector(selectTechApp, (selectTechApp) => { var _a; return (_a = selectTechApp.partPickup) !== null && _a !== void 0 ? _a : []; });
export const selectPartPickupOrder = createSelector(selectTechPartPickup, (partPickup) => partPickup === null || partPickup === void 0 ? void 0 : partPickup.partPickupOrder);
export const selectPickedUpItems = createSelector(selectTechPartPickup, (partPickup) => partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickedUpItems);
export const selectPartPickupVans = createSelector(selectTechPartPickup, (partPickup) => partPickup === null || partPickup === void 0 ? void 0 : partPickup.vans);
export const selectPartPickupWorkflow = createSelector(selectTechPartPickup, (partPickup) => partPickup === null || partPickup === void 0 ? void 0 : partPickup.workflow);
const countPendingAndNewPartStates = (item) => {
    let total = 0;
    if (!item || !item.states) {
        return 0;
    }
    item.states.forEach((state) => {
        if (state.status === PartPickupItemStatus.PENDING ||
            state.status === PartPickupItemStatus.NEW_PART) {
            total += 1;
        }
    });
    return total;
};
export const selectPickupOrderMissingParts = createSelector(selectTechPartPickup, (partPickup) => {
    var _a;
    let missingParts = false;
    const pickupOrder = partPickup === null || partPickup === void 0 ? void 0 : partPickup.partPickupOrder;
    const pickedUpItems = (_a = partPickup === null || partPickup === void 0 ? void 0 : partPickup.pickedUpItems) !== null && _a !== void 0 ? _a : [];
    pickedUpItems.forEach((pickedUpItem) => {
        const matchingItem = pickupOrder === null || pickupOrder === void 0 ? void 0 : pickupOrder.items.find((item) => item.id === pickedUpItem.id);
        const pendingOrNewPart = countPendingAndNewPartStates(matchingItem);
        if (pendingOrNewPart > 0 &&
            (pickedUpItem.quantity < pendingOrNewPart || !pickedUpItem.checked)) {
            missingParts = true;
        }
    });
    return missingParts;
});
export const selectShouldEnableSelectPartCta = createSelector(selectTechPartPickup, (partPickup) => {
    var _a, _b;
    const items = (_b = (_a = partPickup === null || partPickup === void 0 ? void 0 : partPickup.partPickupOrder) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
    if (areAllItemsInPendingStatus(items)) {
        return true;
    }
    return shouldEnableCtaBasedOnStandbyAndNewPartStatus(items);
});
export const selectShouldAutocompleteSelectPart = createSelector(selectTechPartPickup, (partPickup) => {
    var _a, _b, _c, _d;
    const items = (_b = (_a = partPickup === null || partPickup === void 0 ? void 0 : partPickup.partPickupOrder) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
    return (areAllItemsInPickedUpOrNewLocationStatus(items) &&
        ((_d = (_c = partPickup === null || partPickup === void 0 ? void 0 : partPickup.workflow) === null || _c === void 0 ? void 0 : _c.currentTask) === null || _d === void 0 ? void 0 : _d.state) === 'SELECT_PARTS');
});
const areAllItemsInPickedUpOrNewLocationStatus = (items) => {
    return items.every((item) => item.states.every((state) => state.status === PartPickupItemStatus.PICKED_UP ||
        state.status === PartPickupItemStatus.NEW_LOCATION));
};
const areAllItemsInPendingStatus = (items) => {
    return items.every((item) => item.states.every((state) => state.status === PartPickupItemStatus.PENDING));
};
const shouldEnableCtaBasedOnStandbyAndNewPartStatus = (items) => {
    let enableCta = false;
    const hasStandby = items.some((item) => item.states.some((state) => {
        if (state.status === PartPickupItemStatus.STANDBY) {
            return true;
        }
        if (state.status === PartPickupItemStatus.NEW_PART ||
            state.status === PartPickupItemStatus.PENDING) {
            enableCta = true;
        }
        return false;
    }));
    return hasStandby ? false : enableCta;
};
