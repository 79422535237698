var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFleetById } from 'src/AdminApp/modules/fleets/selectors';
import { useMediaQuery } from 'src/components/mui';
import { createFleetInstructions, deleteFleetInstructions, getFleetInstructions, getVisit, updateDailyOpsContact, updateFleetInstructions, clearVisitAuditLog, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { selectVisitInstructions } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { theme } from 'src/styles/theme';
import copyToClipboard from 'src/utils/copyToClipboard';
import { TIME_12H_FORMAT } from 'src/timeConstants';
const useVisitInfo = ({ visit }) => {
    var _a;
    const dispatch = useDispatch();
    const referenceNum = visit === null || visit === void 0 ? void 0 : visit.refNum;
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [visitTimeDisplay, setVisitTimeDisplay] = useState();
    const [techs, setTechs] = useState();
    const [instructions, setInstructions] = useState();
    const [techsExpanded, setTechsExpanded] = useState(false);
    const [customersExpanded, setCustomersExpanded] = useState(false);
    const [fleetInfoExpanded, setFleetInfoExpanded] = useState(false);
    const [dailyOpsContacts, setDailyOpsContacts] = useState(null);
    const [dailyOpsContactsExpanded, setDailyOpsContactsExpanded] = useState(false);
    const [docMobileExpanded, setDocMobileExpanded] = useState(false);
    const [dailyOpsContactsOpen, setDailyOpsContactsOpen] = useState(false);
    const [selectedDailyOpsContact, setSelectedDailyOpsContact] = useState();
    const [openAuditLog, setOpenAuditLog] = useState(false);
    const fleetInfo = useSelector(selectFleetById((_a = visit === null || visit === void 0 ? void 0 : visit.fleets[0]) === null || _a === void 0 ? void 0 : _a.fleetId));
    const visitInstructions = useSelector(selectVisitInstructions);
    const getEndDate = (date) => {
        const newDate = new Date(date);
        if ((visit === null || visit === void 0 ? void 0 : visit.status) !== 'COMPLETED') {
            const additionalSeconds = visit === null || visit === void 0 ? void 0 : visit.additionalTime.map((additionalTime) => additionalTime.secondsRequested).reduce((prev, current) => prev + current, 0);
            newDate.setSeconds(newDate.getSeconds() + additionalSeconds);
        }
        return newDate;
    };
    const handleCopyPhoneClick = (phone) => copyToClipboard(phone);
    const handleSelectDOC = (contact) => {
        setSelectedDailyOpsContact(contact);
        setDocMobileExpanded(true);
    };
    const handleTechsExpandClick = () => {
        setTechsExpanded(!techsExpanded);
    };
    const handleCustomersExpandClick = () => {
        setCustomersExpanded(!customersExpanded);
        if (!customersExpanded) {
            setDailyOpsContactsExpanded(false);
        }
    };
    const handleFleetInfoExpandClick = () => {
        setFleetInfoExpanded(!fleetInfoExpanded);
    };
    const handleUpdateInstructions = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (!value.length && visitInstructions) {
            yield dispatch(deleteFleetInstructions({ noteId: visitInstructions.id }));
        }
        if (!visitInstructions && value.length) {
            yield dispatch(createFleetInstructions({ note: value, entityId: referenceNum }));
        }
        if (visitInstructions && value.length) {
            yield dispatch(updateFleetInstructions({ noteId: visitInstructions.id, note: value }));
        }
        dispatch(getFleetInstructions({ entityId: referenceNum }));
    });
    const handleSaveContact = (contact) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(updateDailyOpsContact({
            contact,
        }));
        yield dispatch(getVisit({ refNum: referenceNum }));
    });
    const handleViewContacts = () => {
        if (isMobile) {
            setDailyOpsContactsExpanded(true);
        }
        else {
            setDailyOpsContactsOpen(true);
        }
    };
    const handleCollapseContacts = () => {
        setDailyOpsContactsExpanded(false);
    };
    const handleCloseContactsDrawer = () => {
        setDailyOpsContactsOpen(false);
    };
    const handleCloseMobileDrawer = () => {
        setDocMobileExpanded(false);
    };
    useEffect(() => {
        if (!openAuditLog) {
            dispatch(clearVisitAuditLog());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAuditLog]);
    useEffect(() => {
        var _a;
        if (!visit)
            return;
        const startEndTime = `${moment(visit.actualStartLocal).format(TIME_12H_FORMAT)} - ${moment(getEndDate(visit.actualEndLocal)).format(TIME_12H_FORMAT)}`;
        const duration = moment.duration(moment(getEndDate(visit.actualEndLocal)).diff(moment(visit.actualStartLocal)));
        setVisitTimeDisplay(<>
        {startEndTime} <span className="durationBullet">•</span> Duration:{' '}
        {duration === null || duration === void 0 ? void 0 : duration.hours()}h {duration === null || duration === void 0 ? void 0 : duration.minutes()}m
      </>);
        // TECHNICIAN RESOURCES
        const techResources = visit.resources.filter((r) => r.type === 'MECHANIC');
        setTechs(techResources
            .map((tr) => tr.technicianName)
            .sort((a, b) => {
            if (a && b && a > b) {
                return 1;
            }
            return -1;
        })
            .join(', '));
        const contacts = (_a = visit === null || visit === void 0 ? void 0 : visit.dailyOpsContacts) === null || _a === void 0 ? void 0 : _a.map((c) => {
            var _a, _b;
            return Object.assign(Object.assign({}, c), { fleetName: (_b = (_a = visit === null || visit === void 0 ? void 0 : visit.fleets) === null || _a === void 0 ? void 0 : _a.filter((f) => f.fleetId === c.fleetId)[0]) === null || _b === void 0 ? void 0 : _b.fleetName });
        }).sort((a, b) => ((a === null || a === void 0 ? void 0 : a.fleetName) > (b === null || b === void 0 ? void 0 : b.fleetName) ? 1 : -1));
        setDailyOpsContacts(contacts !== null && contacts !== void 0 ? contacts : null);
    }, [visit]); // eslint-disable-line
    useEffect(() => {
        var _a;
        let content = 'N/A';
        if (fleetInfo === null || fleetInfo === void 0 ? void 0 : fleetInfo.internalNotes) {
            content = fleetInfo === null || fleetInfo === void 0 ? void 0 : fleetInfo.internalNotes;
        }
        if ((_a = visitInstructions === null || visitInstructions === void 0 ? void 0 : visitInstructions.content) === null || _a === void 0 ? void 0 : _a.length) {
            content = visitInstructions.content;
        }
        setInstructions(content !== null && content !== void 0 ? content : '');
    }, [fleetInfo, visitInstructions]);
    return {
        dispatch,
        referenceNum,
        isMobile,
        visitTimeDisplay,
        setVisitTimeDisplay,
        techs,
        setTechs,
        instructions,
        setInstructions,
        techsExpanded,
        setTechsExpanded,
        customersExpanded,
        setCustomersExpanded,
        fleetInfoExpanded,
        setFleetInfoExpanded,
        dailyOpsContacts,
        setDailyOpsContacts,
        dailyOpsContactsExpanded,
        setDailyOpsContactsExpanded,
        docMobileExpanded,
        setDocMobileExpanded,
        dailyOpsContactsOpen,
        setDailyOpsContactsOpen,
        selectedDailyOpsContact,
        setSelectedDailyOpsContact,
        fleetInfo,
        visitInstructions,
        getEndDate,
        handleCopyPhoneClick,
        handleSelectDOC,
        handleTechsExpandClick,
        handleCustomersExpandClick,
        handleFleetInfoExpandClick,
        handleUpdateInstructions,
        handleSaveContact,
        handleViewContacts,
        handleCollapseContacts,
        handleCloseContactsDrawer,
        handleCloseMobileDrawer,
        openAuditLog,
        setOpenAuditLog,
    };
};
export default useVisitInfo;
