import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { createClearHandler } from 'src/modules/api/utils/createClearHandler';
import { ActionCreators, clearPartnerAssets } from './actions';
const defaultState = {
    assets: undefined,
};
const reducers = handleActions({
    [ActionCreators.GET_PARTNER_ASSETS.SUCCESS.type]: createSimpleHandler('assets'),
    [clearPartnerAssets.type]: createClearHandler('assets', undefined),
}, defaultState);
export default reducers;
