import { handleActions } from 'redux-actions';
import { resetWorkbenchItemsSelected, updateWorkbenchSelectedItems, changeFilters, ActionCreators, } from './actions';
const DEFAULT_STATE = {
    invoices: [],
    pagination: {},
    itemsSelected: [],
    filters: {
        mode: 0,
        status: [],
        fleet: undefined,
        responsibleParty: undefined,
    },
    isFetching: false,
    fleets: [],
    responsibleParties: [],
};
const statuses = {
    NEEDS_REVIEW: 'NEEDS_REVIEW',
    REPAIR_COMPLETED_WITH_INVOICE: 'CLOSED',
    REPAIR_COMPLETED_NO_INVOICE: 'READY',
};
const sortItems = (a, b) => {
    if (a.text < b.text) {
        return -1;
    }
    if (a.text > b.text) {
        return 1;
    }
    return 0;
};
const reducers = handleActions({
    [ActionCreators.GET_WORKBENCH_DASHBOARD.FETCH.type]: (state) => {
        return Object.assign(Object.assign({}, state), { isFetching: true });
    },
    [ActionCreators.GET_WORKBENCH_DASHBOARD.FAILURE.type]: (state) => {
        return Object.assign(Object.assign({}, state), { isFetching: false });
    },
    [ActionCreators.GET_WORKBENCH_DASHBOARD.SUCCESS.type]: (state, action) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        const invoices = ((_c = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.results) === null || _b === void 0 ? void 0 : _b.content) !== null && _c !== void 0 ? _c : []).map((invoice) => (Object.assign(Object.assign({}, invoice), { roStatus: statuses[invoice.roStatus] })));
        const fleets = (_f = (_e = (_d = action.payload) === null || _d === void 0 ? void 0 : _d.facets) === null || _e === void 0 ? void 0 : _e.fleet) === null || _f === void 0 ? void 0 : _f.filter((f) => f.text && f.value).sort(sortItems);
        return Object.assign(Object.assign({}, state), { isFetching: false, invoices, pagination: {
                pageNumber: (_h = (_g = action.payload) === null || _g === void 0 ? void 0 : _g.results) === null || _h === void 0 ? void 0 : _h.number,
                pageSize: (_k = (_j = action.payload) === null || _j === void 0 ? void 0 : _j.results) === null || _k === void 0 ? void 0 : _k.size,
                totalElements: (_m = (_l = action.payload) === null || _l === void 0 ? void 0 : _l.results) === null || _m === void 0 ? void 0 : _m.totalElements,
                totalPages: (_p = (_o = action.payload) === null || _o === void 0 ? void 0 : _o.results) === null || _p === void 0 ? void 0 : _p.totalPages,
            }, fleets, responsibleParties: (_r = (_q = action.payload) === null || _q === void 0 ? void 0 : _q.facets) === null || _r === void 0 ? void 0 : _r.responsibleParty });
    },
    [resetWorkbenchItemsSelected.type]: (state) => {
        return Object.assign(Object.assign({}, state), { itemsSelected: [] });
    },
    [updateWorkbenchSelectedItems.type]: (state, action) => {
        return Object.assign(Object.assign({}, state), { itemsSelected: action.payload });
    },
    [changeFilters.type]: (state, action) => {
        const res = Object.assign(Object.assign({}, state), { filters: Object.assign({}, state.filters) });
        if (typeof action.payload.mode !== 'undefined') {
            res.filters.mode = action.payload.mode;
        }
        if (action.payload.status) {
            res.filters.status = action.payload.status;
        }
        if (action.payload.fleet) {
            res.filters.fleet = action.payload.fleet;
        }
        return res;
    },
}, DEFAULT_STATE);
export default reducers;
