import React from 'react';
export const HubIcon = () => {
    return (<svg width="38" height="32" viewBox="0 0 38 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path id="2b60bd5xla" d="M0 0h26.719v22.5H0z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h38v32H0z"/>
        <g transform="translate(1.188 1)">
          <ellipse fill="#EBEBEB" cx="17.813" cy="15" rx="17.813" ry="15"/>
          <g transform="translate(4.453 3.75)">
            <mask id="e4163gq50b" fill="#fff">
              <use xlinkHref="#2b60bd5xla"/>
            </mask>
            <g mask="url(#e4163gq50b)" fill="#3F3F3F" fillRule="nonzero">
              <path d="M7.435 3.75a.578.578 0 0 0-.373.13.409.409 0 0 0-.153.313v12.791h3.773v-3.171c0-.245.235-.444.526-.443h4.305c.29-.001.527.198.526.443v3.171h3.77V4.193a.408.408 0 0 0-.151-.313.577.577 0 0 0-.37-.13H7.435zm2.289 1.89h6.909c.29-.001.527.197.525.442 0 .244-.236.44-.525.44h-6.91c-.287-.001-.52-.197-.521-.44-.001-.236.218-.43.495-.442h.027zm6.909 2.676c.289-.001.525.196.526.44-.001.244-.237.44-.526.44h-6.91c-.287-.001-.52-.198-.521-.44 0-.242.234-.44.522-.44h6.909zm0 2.673c.289 0 .525.197.526.44 0 .246-.235.444-.526.443h-6.91c-.289 0-.522-.199-.521-.442 0-.243.234-.44.522-.44h6.909zm-4.902 3.263v2.732h3.26v-2.732h-3.26zM4.98 17.868a.578.578 0 0 0-.372.129.409.409 0 0 0-.154.314.41.41 0 0 0 .155.311.579.579 0 0 0 .371.128h16.765c.138 0 .27-.047.368-.13a.41.41 0 0 0 .154-.31.409.409 0 0 0-.152-.312.577.577 0 0 0-.37-.13H4.979z"/>
            </g>
          </g>
        </g>
      </g>
    </svg>);
};
