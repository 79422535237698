import moment from 'moment';
import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { DATE_TIME_FORMAT } from 'src/timeConstants';
// FSA ACTIONS
export const ActionCreators = {
    SKEDULO_REGIONS: createDefaultActionCreators('SKEDULO_REGIONS', ['CLEAR']),
    REGIONS: createDefaultActionCreators('REGIONS', ['CLEAR', 'CLEAR_FILTERS']),
    REGIONS_AUTOCOMPLETE: createDefaultActionCreators('REGIONS_AUTOCOMPLETE'),
    REGION: createDefaultActionCreators('REGION'),
    SERVICE_AREA_BY_ZIP_CODE: createDefaultActionCreators('SERVICE_AREA_BY_ZIP_CODE'),
    ADD_REGION: createDefaultActionCreators('ADD_REGION'),
    UPDATE_REGION: createDefaultActionCreators('UPDATE_REGION'),
    DELETE_REGION: createDefaultActionCreators('DELETE_REGION'),
    ARCHIVE_REGION: createDefaultActionCreators('ARCHIVE_REGION'),
};
export const getRegionsByFilters = (params) => {
    var _a, _b, _c;
    const { filters } = params;
    const patchedDates = {};
    if (filters === null || filters === void 0 ? void 0 : filters.bookingEffectiveDayFrom) {
        patchedDates.bookingEffectiveDayFrom = moment(filters.bookingEffectiveDayFrom)
            .startOf('day')
            .format(DATE_TIME_FORMAT);
    }
    if (filters === null || filters === void 0 ? void 0 : filters.bookingEffectiveDayTo) {
        patchedDates.bookingEffectiveDayTo = moment(filters.bookingEffectiveDayTo)
            .startOf('day')
            .add(1, 'days')
            .format(DATE_TIME_FORMAT);
    }
    if (filters === null || filters === void 0 ? void 0 : filters.marketingLaunchDayFrom) {
        patchedDates.marketingLaunchDayFrom = moment(filters.marketingLaunchDayFrom)
            .startOf('day')
            .format(DATE_TIME_FORMAT);
    }
    if (filters === null || filters === void 0 ? void 0 : filters.marketingLaunchDayTo) {
        patchedDates.marketingLaunchDayTo = moment(filters.marketingLaunchDayTo)
            .startOf('day')
            .add(1, 'days')
            .format(DATE_TIME_FORMAT);
    }
    const pagination = {
        pageSize: (_a = params.pagination) === null || _a === void 0 ? void 0 : _a.size,
        pageIndex: (_b = params.pagination) === null || _b === void 0 ? void 0 : _b.page,
        sort: (_c = params.pagination) === null || _c === void 0 ? void 0 : _c.sort,
    };
    return createAPIAction({
        types: [
            ActionCreators.REGIONS.FETCH({
                meta: () => ({ params, pagination }),
            }),
            ActionCreators.REGIONS.SUCCESS({
                meta: () => ({ params, pagination }),
            }),
            ActionCreators.REGIONS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REGIONS_DASHBOARD(Object.assign(Object.assign({}, params), { filters: Object.assign(Object.assign({}, params.filters), patchedDates) })),
        method: 'GET',
    });
};
export const validateServiceAreasByZipCode = (params) => {
    return createAPIAction({
        types: [
            ActionCreators.SERVICE_AREA_BY_ZIP_CODE.FETCH({
                meta: () => ({ params }),
            }),
            ActionCreators.SERVICE_AREA_BY_ZIP_CODE.SUCCESS({
                meta: () => ({ params }),
            }),
            ActionCreators.SERVICE_AREA_BY_ZIP_CODE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.ACTIVE_SERVICE_AREAS_BY_ZIP_CODE(Object.assign({}, params)),
        method: 'HEAD',
    });
};
export const getRegionsAutocomplete = (name, territoryIds, metroIds) => {
    const filters = {
        name: name !== null && name !== void 0 ? name : '',
        territoryIds,
        metroIds,
    };
    return createAPIAction({
        types: [
            ActionCreators.REGIONS_AUTOCOMPLETE.FETCH(),
            ActionCreators.REGIONS_AUTOCOMPLETE.SUCCESS(),
            ActionCreators.REGIONS_AUTOCOMPLETE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REGIONS_AUTOCOMPLETE(filters),
        method: 'GET',
    });
};
export const getRegionById = (id) => createAPIAction({
    types: [
        ActionCreators.REGION.FETCH(),
        ActionCreators.REGION.SUCCESS(),
        ActionCreators.REGION.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.REGION_BY_ID(id),
    method: 'GET',
});
export const getSkeduloRegions = () => createAPIAction({
    types: [
        ActionCreators.SKEDULO_REGIONS.FETCH(),
        ActionCreators.SKEDULO_REGIONS.SUCCESS(),
        ActionCreators.SKEDULO_REGIONS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.SKEDULO_REGIONS(),
    method: 'GET',
});
export const addRegion = (region) => {
    var _a, _b, _c;
    return createAPIAction({
        types: [
            ActionCreators.ADD_REGION.FETCH(),
            ActionCreators.ADD_REGION.SUCCESS(),
            ActionCreators.ADD_REGION.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REGIONS(),
        method: 'POST',
        body: {
            name: region === null || region === void 0 ? void 0 : region.name,
            metroId: (_a = region === null || region === void 0 ? void 0 : region.metro) === null || _a === void 0 ? void 0 : _a.id,
            serviceAreaIds: region === null || region === void 0 ? void 0 : region.serviceAreaIds,
            skeduloRegionId: region === null || region === void 0 ? void 0 : region.skeduloRegionId,
            skeduloRegionTimezone: region === null || region === void 0 ? void 0 : region.skeduloRegionTimezone,
            skeduloRegionDescription: region === null || region === void 0 ? void 0 : region.skeduloRegionDescription,
            laborRates: region === null || region === void 0 ? void 0 : region.laborRates,
            opsManagerId: region === null || region === void 0 ? void 0 : region.opsManagerId,
            futureAppointmentMaxCapacity: region === null || region === void 0 ? void 0 : region.futureAppointmentMaxCapacity,
            futureAppointmentMinDays: region === null || region === void 0 ? void 0 : region.futureAppointmentMinDays,
            skynetDisabledForB2C: (_b = region === null || region === void 0 ? void 0 : region.skynetDisabledForB2C) !== null && _b !== void 0 ? _b : false,
            skynetDisabledForB2B: (_c = region === null || region === void 0 ? void 0 : region.skynetDisabledForB2B) !== null && _c !== void 0 ? _c : false,
        },
    });
};
export const updateRegion = (region, inPlace = false) => {
    var _a, _b, _c;
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_REGION.FETCH(),
            ActionCreators.UPDATE_REGION.SUCCESS({
                meta: () => ({
                    id: region.id,
                    inPlace,
                }),
            }),
            ActionCreators.UPDATE_REGION.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REGION_BY_ID(region.id),
        method: 'PUT',
        body: {
            name: region === null || region === void 0 ? void 0 : region.name,
            metroId: (_a = region === null || region === void 0 ? void 0 : region.metro) === null || _a === void 0 ? void 0 : _a.id,
            serviceAreaIds: region === null || region === void 0 ? void 0 : region.serviceAreaIds,
            skeduloRegionId: region === null || region === void 0 ? void 0 : region.skeduloRegionId,
            skeduloRegionTimezone: region === null || region === void 0 ? void 0 : region.skeduloRegionTimezone,
            skeduloRegionDescription: region === null || region === void 0 ? void 0 : region.skeduloRegionDescription,
            laborRates: region === null || region === void 0 ? void 0 : region.laborRates,
            opsManagerId: region === null || region === void 0 ? void 0 : region.opsManagerId,
            porterId: region === null || region === void 0 ? void 0 : region.porterId,
            futureAppointmentMaxCapacity: region === null || region === void 0 ? void 0 : region.futureAppointmentMaxCapacity,
            futureAppointmentMinDays: region === null || region === void 0 ? void 0 : region.futureAppointmentMinDays,
            skynetDisabledForB2C: (_b = region === null || region === void 0 ? void 0 : region.skynetDisabledForB2C) !== null && _b !== void 0 ? _b : false,
            skynetDisabledForB2B: (_c = region === null || region === void 0 ? void 0 : region.skynetDisabledForB2B) !== null && _c !== void 0 ? _c : false,
        },
    });
};
export const archiveRegion = ({ id, transferRegionId, }) => createAPIAction({
    types: [
        ActionCreators.ARCHIVE_REGION.FETCH(),
        ActionCreators.ARCHIVE_REGION.SUCCESS({ meta: () => ({ id }) }),
        ActionCreators.ARCHIVE_REGION.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.REGION_ARCHIVE(id),
    method: 'PUT',
    body: { transferRegionId },
});
export const clearRegionsData = () => ActionCreators.REGIONS.CLEAR();
export const clearRegionFilters = () => ActionCreators.REGIONS.CLEAR_FILTERS();
