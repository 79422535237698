import CONFIG from 'src/config';
import { PrefixHandler } from 'src/utils/url-utils';
const API_URLS = {
    DASHBOARD: {
        GET_DASHBOARD: (id) => `/repair-service/admin/technicians/${id}/dashboard`,
        GET_TECHNICIAN_DATA: (id) => `/repair-service/admin/technicians/${id}`,
        COMPLETE_TASK: ({ refNumber, taskId }) => `/repair-service/admin/technicians/onsite-workflow/${refNumber}/tasks/${taskId}`,
        GET_PORTER_DATA: (id) => `/repair-service/admin/porters/${id}`,
        GET_PORTER_DASHBOARD: (id) => `/repair-service/admin/porters/${id}/dashboard`,
    },
    PART_PICKUP: {
        PART_PICKUP_ORDER_BY_ID: (partPickupOrderId) => `/repair-service/admin/part-pickup-order/${partPickupOrderId}`,
        GET_PART_PICKUP_WORKFLOW: (referenceNum) => `/repair-service/admin/part-pickup-task/workflow/${referenceNum}`,
        PART_PICKUP_TASK: ({ referenceNum, taskId, }) => `/repair-service/admin/part-pickup-task/workflow/${referenceNum}/tasks/${taskId}`,
        PICKUP_PARTS: (partPickupOrderId) => `/repair-service/admin/part-pickup-order/${partPickupOrderId}/pickup-parts`,
        PART_PICKUP_ORDER_BY_REFERENCE_NUM: (referenceNum) => `/repair-service/admin/part-pickup-order/orders/${referenceNum}`,
        CANCEL_PART_PICKUP: (partPickupOrderId) => `/repair-service/admin/part-pickup-order/${partPickupOrderId}/cancel`,
        GET_PART_PICKUP_VAN: (partPickupOrderId) => `/repair-service/admin/part-pickup-order/${partPickupOrderId}/assigned-van`,
        RESCHEDULE_PART_PICKUP: (partPickupOrderId) => `/repair-service/admin/part-pickup-order/${partPickupOrderId}/reschedule`,
        TRANSFER_PART_PICKUP: (partPickupOrderId) => `/repair-service/admin/part-pickup-order/${partPickupOrderId}/transfer`,
        PART_PICKUP_PHOTO: (partPickupOrderId) => `/repair-service/admin/part-pickup-order/${partPickupOrderId}/attach-picture`,
        DELETE_PART_PICKUP_PHOTO: ({ partPickupOrderId, attachmentId, }) => `/repair-service/admin/part-pickup-order/${partPickupOrderId}/attach-picture/${attachmentId}`,
    },
    LUNCH_BREAK: (lunchBreakId) => `/repair-service/admin/lunch-break/${lunchBreakId}`,
};
const API_PROXY = new Proxy(API_URLS, PrefixHandler(CONFIG.CLIENT_API_URL));
export default Object.assign({}, API_PROXY);
