var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Button, Grid } from 'src/components/mui';
import SimpleAutosuggest from 'src/components/SimpleAutosuggest';
const RepairComponentSearch = ({ onSearchRepairComponents, onAddToRepair, }) => {
    const [selectedComponent, setSelectedComponent] = useState();
    const handleAddToRepair = () => {
        if (selectedComponent) {
            onAddToRepair(selectedComponent);
        }
        setSelectedComponent(undefined);
    };
    const handleSearch = (value) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const searchResults = yield onSearchRepairComponents(value);
        return (_a = searchResults === null || searchResults === void 0 ? void 0 : searchResults.payload) === null || _a === void 0 ? void 0 : _a.map((c) => (Object.assign(Object.assign({}, c), { label: `${c.name} (${c.type})` })));
    });
    return (<Grid container>
      <Grid item xs={8}>
        <SimpleAutosuggest placeholder="Search components" fieldName="label" groupBy={(option) => option.groupName} onSearch={handleSearch} onSelect={(suggestion) => {
            setSelectedComponent(suggestion);
        }} clearOnEmpty={selectedComponent}/>
      </Grid>
      <Grid item xs={1}/>
      <Grid item xs={3}>
        <Button variant="contained" color="primary" fullWidth onClick={handleAddToRepair} disabled={!selectedComponent}>
          Add to Repair
        </Button>
      </Grid>
    </Grid>);
};
export default RepairComponentSearch;
