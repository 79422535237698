import { Typography, Grid } from 'src/components/mui';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useRef } from 'react';
import PriceInput from 'src/AdminApp/components/form/PriceInput';
import TextField from 'src/AdminApp/components/form/TextField';
import { SlidePageContent, SlidePageFooter, SlidePageFormWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { SliderContext } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/utils';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
import * as Yup from 'yup';
import useLaborRatesPage from './useLaborRatesPage';
const priceInputProps = {
    inputComponent: PriceInput,
};
const euMakesWhen = {
    name: 'euActive',
    builder(value, schema) {
        return value ? schema.required() : schema;
    },
};
const laborRatesSchema = Yup.object().shape({
    diagnosis: Yup.number().positive().required(),
    effectiveFrom: Yup.string().required(),
    effectiveTo: Yup.string().test('is-date-after', 'Effective To date cannot be before Effective From', 
    // eslint-disable-next-line func-names
    function (effectiveTo) {
        if (!effectiveTo)
            return true;
        const from = moment(this.parent.effectiveFrom);
        const to = moment(effectiveTo);
        return to.isAfter(from, 'day');
    }),
    euActive: Yup.bool().default(false),
    euDiagnosis: Yup.number()
        .positive()
        .when(euMakesWhen.name, euMakesWhen.builder),
    euGeneralRepair: Yup.number()
        .positive()
        .when(euMakesWhen.name, euMakesWhen.builder),
    euMaintenanceRepair: Yup.number()
        .positive()
        .when(euMakesWhen.name, euMakesWhen.builder),
    euSpecialtyRepair: Yup.number()
        .positive()
        .when(euMakesWhen.name, euMakesWhen.builder),
    generalRepair: Yup.number().positive().required(),
    maintenanceRepair: Yup.number().positive().required(),
    specialtyRepair: Yup.number().positive().required(),
});
const Container = styled('div') `
  margin-bottom: 40px;
`;
const ChkContainer = styled(Grid) `
  margin-top: 8px;

  span[class*='MuiFormControlLabel-label-'] {
    color: ${colors.darkGrey};
    font-weight: 500;
  }
`;
const LaborRatesPage = ({ metro = false }) => {
    var _a, _b, _c, _d, _e;
    const formRef = useRef(null);
    const { previousPage, setSlide } = useContext(SliderContext);
    const { laborRate, laborRateEditor, handleEuChange, handleDateChange, onSubmit, disabledEffectiveFrom, disabledEffectiveTo, } = useLaborRatesPage({
        metro,
        formRef,
        previousPage,
        setSlide,
        laborRatesSchema,
    });
    const initValues = laborRate
        ? Object.assign(Object.assign({}, laborRate), { effectiveTo: (_a = laborRate === null || laborRate === void 0 ? void 0 : laborRate.effectiveTo) !== null && _a !== void 0 ? _a : undefined, euActive: !!(laborRate === null || laborRate === void 0 ? void 0 : laborRate.euGeneralRepair), euDiagnosis: (_b = laborRate === null || laborRate === void 0 ? void 0 : laborRate.euDiagnosis) !== null && _b !== void 0 ? _b : undefined, euGeneralRepair: (_c = laborRate === null || laborRate === void 0 ? void 0 : laborRate.euGeneralRepair) !== null && _c !== void 0 ? _c : undefined, euMaintenanceRepair: (_d = laborRate === null || laborRate === void 0 ? void 0 : laborRate.euMaintenanceRepair) !== null && _d !== void 0 ? _d : undefined, euSpecialtyRepair: (_e = laborRate === null || laborRate === void 0 ? void 0 : laborRate.euSpecialtyRepair) !== null && _e !== void 0 ? _e : undefined })
        : null;
    return (<Formik initialValues={laborRatesSchema.cast(initValues || laborRatesSchema.default())} innerRef={(instance) => {
            formRef.current = instance;
        }} validationSchema={laborRatesSchema} onSubmit={onSubmit}>
      {({ errors, dirty, isSubmitting, isValid, values }) => {
            var _a, _b;
            return (<SlidePageFormWrapper>
          <SlidePageContent>
            <Container>
              <Grid>
                <Typography variant="h5">Labor Rates</Typography>
              </Grid>
            </Container>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Pricing Effective</Typography>
                </Grid>
                <Grid item xs={6}>
                  <SimpleDatePicker fullWidth disabled={disabledEffectiveFrom} label="Effective from" 
            /* labelFunc={labelFn(values.effectiveFrom) as any} */
            name="effectiveFrom" value={values.effectiveFrom} onChange={handleDateChange('effectiveFrom')} slotProps={{
                    textField: {
                        variant: 'outlined',
                        helperText: (_a = errors === null || errors === void 0 ? void 0 : errors.effectiveFrom) !== null && _a !== void 0 ? _a : '',
                        type: 'text',
                    },
                }}/>
                </Grid>
                <Grid item xs={6}>
                  <SimpleDatePicker fullWidth disabled={disabledEffectiveTo} label="Effective to" 
            /* labelFunc={labelFn(values.effectiveTo) as any} */
            name="effectiveTo" value={values.effectiveTo} onChange={handleDateChange('effectiveTo')} slotProps={{
                    textField: {
                        variant: 'outlined',
                        helperText: (_b = errors === null || errors === void 0 ? void 0 : errors.effectiveTo) !== null && _b !== void 0 ? _b : '',
                        type: 'text',
                    },
                    field: {
                        clearable: true,
                    },
                }}/>
                </Grid>
              </Grid>
            </Container>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Labor Rates</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth disabled={disabledEffectiveFrom} InputProps={priceInputProps} label="General Repairs" name="generalRepair" type="text" variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth disabled={disabledEffectiveFrom} InputProps={priceInputProps} label="Maintenance Repairs" name="maintenanceRepair" type="text" variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth disabled={disabledEffectiveFrom} InputProps={priceInputProps} label="Specialty Repairs" name="specialtyRepair" type="text" variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth disabled={disabledEffectiveFrom} InputProps={priceInputProps} label="Diagnosis" name="diagnosis" type="text" variant="outlined"/>
                </Grid>
                <ChkContainer item xs={12}>
                  <LabelledCheckbox label="Separate Labor Rates for European Makes" disabled={disabledEffectiveFrom} checkboxProps={{
                    checked: values.euActive,
                    onChange: handleEuChange,
                }}/>
                </ChkContainer>
              </Grid>
            </Container>
            {values.euActive && (<Container>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Labor Rates (European Makes)
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth disabled={disabledEffectiveFrom} InputProps={priceInputProps} label="General Repairs (EU)" name="euGeneralRepair" type="text" variant="outlined"/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth disabled={disabledEffectiveFrom} InputProps={priceInputProps} label="Maintenance Repairs (EU)" name="euMaintenanceRepair" type="text" variant="outlined"/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth disabled={disabledEffectiveFrom} InputProps={priceInputProps} label="Specialty Repairs (EU)" name="euSpecialtyRepair" type="text" variant="outlined"/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth disabled={disabledEffectiveFrom} InputProps={priceInputProps} label="Diagnosis (EU)" name="euDiagnosis" type="text" variant="outlined"/>
                  </Grid>
                </Grid>
              </Container>)}
          </SlidePageContent>
          <SlidePageFooter>
            <FooterActionButton disabled={!laborRateEditor || isSubmitting || !isValid || !dirty} type="submit">
              Save Labor Rates
            </FooterActionButton>
          </SlidePageFooter>
        </SlidePageFormWrapper>);
        }}
    </Formik>);
};
export default LaborRatesPage;
