import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Route, Redirect, Switch } from 'react-router-dom';
import { selectIsAuthenticated } from 'src/modules/auth/selectors';
import PartnerManagement from 'src/PartnersApp/pages/PartnerManagement';
import { NotFound } from 'src/pages';
import Paths from './paths';
const routes = [
    {
        path: '/partners',
        exact: true,
        component: PartnerManagement,
    },
    {
        path: '*',
        component: NotFound,
    },
];
const Routes = () => {
    const isAuth = useSelector(selectIsAuthenticated);
    const location = useLocation();
    return isAuth ? (<Switch>
      {routes.map((r) => (<Route key={r.path} path={r.path} exact={r.exact} component={r.component}/>))}
    </Switch>) : (<Redirect to={{ pathname: Paths.Login, state: { from: location } }}/>);
};
export default Routes;
