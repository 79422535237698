var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import { useCallback, useEffect, useState } from 'react';
import { selectLunchBreak, selectLunchBreakIsLoading, } from 'src/TechApp/modules/lunchBreak/selectors';
import moment from 'moment/moment';
import { getLunchBreak, patchLunchBreak, } from 'src/TechApp/modules/lunchBreak/actions';
import { formatTime } from 'src/utils/data-utils';
import { toast } from 'src/components/SimpleToast';
import { TIME_FORMAT } from 'src/timeConstants';
const useLunchTaskDrawer = ({ id, isOpen, onClose, }) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isLoading = useSelector(selectLunchBreakIsLoading);
    const lunchBreak = useSelector(selectLunchBreak);
    const [startTime, setStartTime] = useState(moment().format(TIME_FORMAT));
    const [endTime, setEndTime] = useState();
    useEffect(() => {
        if (id && isOpen) {
            dispatch(getLunchBreak(id));
        }
    }, [dispatch, id, isOpen]);
    const submitLunchBreak = useCallback((status) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const result = yield dispatch(patchLunchBreak(id, {
            actualStartTime: (_a = lunchBreak === null || lunchBreak === void 0 ? void 0 : lunchBreak.actualStartTime) !== null && _a !== void 0 ? _a : formatTime(startTime),
            actualEndTime: (_b = lunchBreak === null || lunchBreak === void 0 ? void 0 : lunchBreak.actualEndTime) !== null && _b !== void 0 ? _b : formatTime(endTime),
            status,
        }));
        if (!result.error) {
            toast.success('Lunch Break Updated Successfully');
        }
    }), [dispatch, endTime, id, lunchBreak, startTime]);
    const goBackHandler = () => {
        setIsModalOpen(false);
    };
    const okayHandler = () => {
        setIsModalOpen(false);
        submitLunchBreak('CANCELLED');
        onClose();
        window.location.reload();
    };
    useEffect(() => {
        if (lunchBreak) {
            setStartTime(lunchBreak.actualStartTime
                ? moment(lunchBreak.actualStartTime).format(TIME_FORMAT)
                : moment().format(TIME_FORMAT));
            if (lunchBreak.actualEndTime) {
                setEndTime(moment(lunchBreak.actualEndTime).format(TIME_FORMAT));
            }
            else if (lunchBreak.actualStartTime) {
                setEndTime(moment().format(TIME_FORMAT));
            }
        }
    }, [lunchBreak, isOpen]);
    const goToDashBoardHandler = () => {
        onClose();
        window.location.reload();
    };
    return {
        lunchBreak,
        goToDashBoardHandler,
        isMobile,
        isLoading,
        submitLunchBreak,
        endTime,
        setIsModalOpen,
        setStartTime,
        startTime,
        setEndTime,
        isModalOpen,
        goBackHandler,
        okayHandler,
    };
};
export default useLunchTaskDrawer;
