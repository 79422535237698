import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import { flexCenter } from 'src/styles/styled';
import { IconButton } from 'src/components/mui';
import { arrowStyles } from 'src/AdminApp/containers/vehicle/CarModule/FourCornersDrawer/FourCornersDrawer.styles';
export const CarouselWrapper = styled('div') `
  min-height: 300px;
  min-width: 600px;
  overflow: hidden;

  ${theme.breakpoints.down('sm')} {
    min-height: auto;
    min-width: auto;
  }
`;
export const CarouselHeader = styled('div') `
  ${flexCenter};

  height: auto;
  justify-content: space-between;
  padding: 4px;
`;
export const CarouselContent = styled('div') `
  overflow: hidden;
  padding: 16px;
`;
export const LeftArrow = styled(IconButton) `
  ${arrowStyles};

  left: 8px;
`;
export const RightArrow = styled(IconButton) `
  ${arrowStyles};

  right: 8px;
`;
export const Slide = styled('div') `
  ${flexCenter};

  height: 55vh;

  img {
    max-height: 100%;
  }

  ${theme.breakpoints.down('sm')} {
    height: 60vh;

    img {
      max-width: 60vw;
    }
  }
`;
