import styled from 'styled-components';
import { Card } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const RepairOrders = styled.section `
  margin: 0 25px 20px 25px;
`;
export const RepairOrderCard = styled(Card) `
  margin-bottom: 15px;

  background-color: ${({ roaction }) => roaction === 'REMOVE' ? colors.GRAY_FA : colors.white};
`;
export const RepairHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FleetLabel = styled.h3 `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  margin: 0;
`;
export const RefLink = styled.div `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
`;
export const VinStatus = styled.section `
  display: flex;
  margin-top: 23px;
`;
export const DetailsLabel = styled.div `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
`;
export const DetailsValue = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  margin-top: 5px;
`;
export const StatusContainer = styled.div `
  margin-left: 30px;
`;
export const RepairsSection = styled.section `
  margin-top: 20px;
`;
