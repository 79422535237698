var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { omit } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createWrongPart, fetchRequestPartPickups, getQuoteDiagnosis, } from 'src/AdminApp/modules/requests/actions';
import { selectRequest } from 'src/AdminApp/modules/requests/selectors';
import { get_base_64 } from 'src/AdminApp/utils/imageUtils';
import { toast } from 'src/components/SimpleToast';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
export var WrongPartDrawerTabs;
(function (WrongPartDrawerTabs) {
    WrongPartDrawerTabs[WrongPartDrawerTabs["NoteInput"] = 0] = "NoteInput";
    WrongPartDrawerTabs[WrongPartDrawerTabs["PhotoUpload"] = 1] = "PhotoUpload";
})(WrongPartDrawerTabs || (WrongPartDrawerTabs = {}));
const useWrongPartDrawer = ({ repair, referenceNum, onClose, selectedComponent, }) => {
    const dispatch = useDispatch();
    const { selectorProps } = usePageReferenceNum();
    const [tab, setTab] = useState(0);
    const [note, setNote] = useState('');
    const [photos, setPhotos] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const repairRequest = useSelector((state) => selectRequest(state, selectorProps));
    const addPhoto = (file) => {
        get_base_64(file).then((result) => {
            setPhotos(Object.assign(Object.assign({}, photos), { [file.name]: result }));
        });
    };
    const removePhoto = (name) => {
        const updatedPhotos = omit(photos, name);
        setPhotos(updatedPhotos);
    };
    const onWrongPartSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (isSubmitting)
            return;
        setIsSubmitting(true);
        const wrongPart = {
            repairRequestId: repairRequest.id,
            suggestedRepairId: repair === null || repair === void 0 ? void 0 : repair.id,
            componentId: selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.id,
            componentType: (selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.isPart) ? 'PART' : 'FLUID',
            componentName: selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.name,
            partNumber: selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.partNumber,
            orderedBy: selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.orderedBy,
            orderedAt: selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.partOrderedAt,
            orderedFromDistributorLocationId: (_a = selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.componentPartDistributor) === null || _a === void 0 ? void 0 : _a.partDistributorLocationId,
            note,
            base64PhotoStrings: Object.keys(photos).length > 0 ? photos : null,
        };
        const response = yield dispatch(createWrongPart(wrongPart));
        if (response && !response.error) {
            toast.success('Wrong Part successfully added');
            dispatch(getQuoteDiagnosis(referenceNum, repairRequest.id));
            dispatch(fetchRequestPartPickups(referenceNum));
        }
        setIsSubmitting(false);
        handleClose();
    });
    const handleClose = () => {
        setTab(0);
        onClose();
    };
    return {
        setTab,
        tab,
        onWrongPartSubmit,
        note,
        setNote,
        photos,
        addPhoto,
        removePhoto,
        handleClose,
        isSubmitting,
    };
};
export default useWrongPartDrawer;
