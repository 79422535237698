import { handleActions } from 'redux-actions';
import { createAPIPaginationHandler2 } from 'src/modules/api/utils/createAPIPaginationHandler2';
import { ActionCreators } from './actions';
const defaultState = {
    partnersById: {},
};
const getPartnerById = (state, action) => {
    const partner = action.payload;
    return Object.assign(Object.assign({}, state), { partnersById: Object.assign(Object.assign({}, state.partnersById), { [partner.id]: Object.assign({}, partner) }) });
};
const reducers = handleActions({
    [ActionCreators.GET_PARTNERS.SUCCESS.type]: createAPIPaginationHandler2('content'),
    [ActionCreators.GET_PARTNER.SUCCESS.type]: getPartnerById,
}, defaultState);
export default reducers;
