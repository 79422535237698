import React from 'react';
import { Link } from 'react-router-dom';
import { formatUTCDate } from 'src/utils/formatter';
import { isDate } from 'src/utils/data-utils';
import { TABLES } from './EntityLoader';
const isLink = (field, value) => !!value &&
    TABLES.some((availableTableName) => field.endsWith(`${availableTableName}_id`));
const parseTableName = (field) => field.replace('active_', '').replace('_id', '');
export const ValueRenderer = ({ field, value, schema, }) => {
    if (isLink(field, value))
        return (<td>
        <Link to={`/system/audit/entity-explorer/${schema}/${parseTableName(field)}/${value}`}>{`${value}`}</Link>
      </td>);
    if (isDate(value)) {
        return <td>{`${value && formatUTCDate(value / 1000)}`}</td>;
    }
    return <td>{`${value}`}</td>;
};
