var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Link } from 'react-router-dom';
import { Btn } from './PrimaryButton.styles';
/**
 * Used for most primary page actions. Do not use in SimpleDrawer footer or SimpleModal footer
 */
const PrimaryButton = (_a) => {
    var { color = 'primary', variant = 'contained', href } = _a, props = __rest(_a, ["color", "variant", "href"]);
    const el = <Btn href={href} color={color} variant={variant} {...props}/>;
    // use next/link as wrapper when we have href, href prop should be duplicated
    return href && !href.startsWith('tel:') && !href.startsWith('mailto:') ? (<Link to={href}>{el}</Link>) : (el);
};
export default PrimaryButton;
