import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import API_URLS from 'src/PopsApp/modules/api/urls';
import { createDefaultApiActionCreators } from 'src/utils/createActions';
export const ActionCreators = {
    GET_RETURN_INVENTORY: createDefaultApiActionCreators('GET_INVENTORY'),
    DELETE_RETURN_ITEM: createDefaultApiActionCreators('DELETE_RETURN_ITEM'),
    UPDATE_RETURNED_ITEM: createDefaultApiActionCreators('UPDATE_RETURNED_ITEM'),
    MOVE_RETURNED_ITEMS: createDefaultApiActionCreators('MOVE_RETURNED_ITEMS'),
    FETCH_LOCATION_HIERARCHY: createDefaultApiActionCreators('FETCH_LOCATION_HIERARCHY'),
    SAVE_CORES_RETURNED_ITEMS: createDefaultApiActionCreators('SAVE_CORES_RETURNED_ITEMS'),
};
export const getInventoryReturnItems = ({ pageSize, pageIndex, sort = ['locationName,asc'], status, poNumber, minPrice, maxPrice, locationId, locationType, createdFrom, createdTo, }) => {
    const params = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort })), (!!status && { status })), (!!poNumber && { poNumber })), (!!minPrice && { minPrice })), (!!maxPrice && { maxPrice })), (!!locationId && { locationId })), (!!locationType && { locationType })), (!!createdFrom && { createdFrom })), (!!createdTo && { createdTo }));
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_RETURN_INVENTORY, {
            success: {
                meta: () => ({ pageSize, pageIndex, sort }),
            },
        }),
        endpoint: API_URLS.INVENTORY.RETURN_ITEMS(),
        method: 'GET',
        params,
    });
};
export const deleteReturnItem = (id) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.DELETE_RETURN_ITEM),
        endpoint: API_URLS.INVENTORY.RETURN_ITEM_BY_ID(id),
        method: 'DELETE',
    });
};
export const updateReturnedItem = ({ id, status, confirmationNumber, }) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.UPDATE_RETURNED_ITEM),
        endpoint: API_URLS.INVENTORY.RETURN_ITEM_BY_ID(id),
        method: 'PATCH',
        body: { confirmationNumber, status },
    });
};
export const moveReturnedItems = (returnItems) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.MOVE_RETURNED_ITEMS),
        endpoint: API_URLS.INVENTORY.RETURN_ITEMS(),
        method: 'PUT',
        body: { returnItems },
    });
};
export const fetchLocationHierarchy = (inventoryReturnItemId, locationTypes = ['HUB', 'DELIVERY_VAN']) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.FETCH_LOCATION_HIERARCHY),
        endpoint: API_URLS.INVENTORY.RETURN_ITEM_LOCATIONS(inventoryReturnItemId),
        method: 'GET',
        params: { locationTypes },
    });
};
export const saveCoresReturnedItems = (returnItems) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.SAVE_CORES_RETURNED_ITEMS),
        endpoint: API_URLS.INVENTORY.MULTIPLE_RETURN_ITEMS(),
        method: 'POST',
        body: { returnItems },
    });
};
