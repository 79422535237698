import React from 'react';
import { TableSortTitle } from 'src/components/dashboard/SimpleTable/SimpleTable.style';
import { TableCell } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
const SimpleTableHeaderCell = ({ column, sortBy, sortDir, onSort, textAlign = 'left', }) => {
    return (<TableCell sortDirection={sortDir}>
      {column.sortable ? (<Tooltip title="Sort" placement="bottom">
          <TableSortTitle active={sortBy === column.key} direction={sortDir} onClick={() => onSort(column)}>
            {column.title}
          </TableSortTitle>
        </Tooltip>) : (<div style={{
                fontSize: '14px',
                fontWeight: sortBy === column.key ? 'bold' : 'normal',
                textAlign,
            }}>
          {column.title}
        </div>)}
    </TableCell>);
};
export default SimpleTableHeaderCell;
