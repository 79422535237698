import React, { Fragment } from 'react';
import { map } from 'lodash';
import { FieldArray } from 'formik';
import { Grid } from 'src/components/mui';
import { FormSection, FieldTitle, HoursSelect, } from 'src/AdminApp/components/form';
import { CheckboxField } from 'src/components/SimpleCheckbox';
export const deliveryChannels = {
    REFERRAL: { id: 'REFERRAL', name: 'Referral', checked: false },
    DELIVERY: { id: 'DELIVERY', name: 'Delivery', checked: false },
    DIRECT: { id: 'DIRECT', name: 'Direct', checked: false },
    HAZMAT: { id: 'HAZMAT', name: 'Hazmat', checked: false },
    SUBLET: { id: 'SUBLET', name: 'Sublet', checked: false },
};
export const deliveryChannelArray = [
    deliveryChannels.REFERRAL,
    deliveryChannels.DELIVERY,
    deliveryChannels.DIRECT,
    deliveryChannels.HAZMAT,
    deliveryChannels.SUBLET,
];
const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];
export const renderHourSelects = (setHours, businessHours = []) => map(businessHours, (day, index) => {
    const { dayOfWeek, closed, openingTime, closingTime } = day;
    const handleCheckboxChange = (e) => {
        const otherValues = {};
        if (e.target.checked) {
            otherValues.openingTime = '';
            otherValues.closingTime = '';
        }
        setHours(Object.assign({ dayOfWeek, closed: e.target.checked }, otherValues));
    };
    return (<Fragment key={index}>
        <FormSection title={days[dayOfWeek - 1]} xs={10} md={10}/>
        <Grid item xs={2} md={2}>
          <CheckboxField name={`closed-${day}`} label="Closed" style={{ float: 'right' }} checked={closed} onChange={handleCheckboxChange}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <HoursSelect name={`opensAt-${day.label}`} label="Opens At" value={openingTime !== null && openingTime !== void 0 ? openingTime : ''} onChange={(e) => setHours({ dayOfWeek, openingTime: e.target.value })} disabled={closed}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <HoursSelect name={`closesAt-${day.label}`} label="Closes At" value={closingTime !== null && closingTime !== void 0 ? closingTime : ''} onChange={(e) => setHours({ dayOfWeek, closingTime: e.target.value })} disabled={closed}/>
        </Grid>
      </Fragment>);
});
export const renderFieldArray = (type, options, setFieldValue, values, stateType, check) => (<FieldArray name={type} render={(arrayHelpers) => (<Grid container spacing={2}>
        {map(options, ({ name, id }) => {
            var _a, _b;
            const checked = (_b = (_a = stateType[id]) === null || _a === void 0 ? void 0 : _a.checked) !== null && _b !== void 0 ? _b : false;
            return (<Grid item xs={6} md={3} key={id}>
              <CheckboxField name={id.toString()} label={name} checked={checked} onChange={(e) => {
                    check(type, id, e.target.checked);
                    if (e.target.checked) {
                        arrayHelpers.push(id);
                    }
                    else {
                        const idx = values[type].indexOf(id);
                        arrayHelpers.remove(idx);
                    }
                }}/>
            </Grid>);
        })}
      </Grid>)}/>);
export const renderGroupedCheckboxForm = (type, groups, setFieldValue, values, stateType, check) => map(groups, (groupItem, index) => {
    var _a;
    return (<Fragment key={index}>
      <FieldTitle style={{ marginBottom: '8px' }}>{groupItem === null || groupItem === void 0 ? void 0 : groupItem.name}</FieldTitle>
      {renderFieldArray(type, (_a = groupItem[type]) !== null && _a !== void 0 ? _a : [], setFieldValue, values, stateType, check)}
    </Fragment>);
});
