import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Typography } from 'src/components/mui';
import { formatTimestamp } from 'src/utils/formatter';
import { colors } from 'src/styles/theme';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import CollapsibleBlock from 'src/components/layout/CollapsibleBlock';
import Form from './Form';
const Timestamp = styled.div `
  margin-top: 12px;
  font-family: Interstate;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.darkGrey};
`;
const CustomerReviewModule = ({ onSubmit, review, }) => {
    var _a, _b, _c;
    const updatedStamp = (<Timestamp>
      {((_a = review.modified) !== null && _a !== void 0 ? _a : null) ? 'Updated: ' : 'Added: '}{' '}
      {formatTimestamp((_b = review.modified) !== null && _b !== void 0 ? _b : null)} •{' '}
      <AdminUser subText={false} updatedUser={(_c = review.modifiedBy) !== null && _c !== void 0 ? _c : null}/>
    </Timestamp>);
    return (<CollapsibleBlock hashName="review" header={<Typography variant="h5" gutterBottom>
          Customer Review
        </Typography>} defaultOpen={!isEmpty(review)}>
      <Form {...review} onUpdate={(values) => {
            onSubmit(values);
        }}/>
      {!isEmpty(review) && updatedStamp}
    </CollapsibleBlock>);
};
export default CustomerReviewModule;
