import styled from 'styled-components';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
const getRotateAngle = ({ isExpanded }) => isExpanded ? -180 : 0;
export const FilterItem = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: solid 1px ${colors.GRAY_DE};
  padding: 16px 0 0;
  background-color: ${colors.white};

  .itemHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    outline: none;
    z-index: 100;
    background-color: ${colors.white};
  }

  .showingAll {
    border: none;
    cursor: pointer;
    background: none;
  }

  .itemTitle {
    font-weight: 700;
    font-size: 16px;
    margin: 0;
  }

  .itemValue {
    font-weight: 500;
  }

  .itemCollapseIcon {
    width: 24px;
    height: 24px;
    background-image: url(${STATIC_URL_BASE}pops/icons/chevron-down.svg);
    transform: rotate(${getRotateAngle}deg);
    transition: transform 0.3s ease;
    margin-left: 8px;
    font-size: 13px;
  }

  .itemCollapseWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
  }

  .itemCollapsible {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 0 16px;
    overflow: hidden;
    ${(props) => (props.isExpanded ? '' : 'height: 0;')}
    opacity: ${(props) => (props.isExpanded ? 1 : 0)};
    transition: transform 0.2s ease-in, opacity 0.1s ease-in;
    transform: translateY(${(props) => (props.isExpanded ? 0 : -60)}px);
  }
`;
const getRadioState = (props) => {
    return props.isChecked
        ? `${STATIC_URL_BASE}pops/icons/checked-round.svg`
        : `${STATIC_URL_BASE}pops/icons/empty-round.svg`;
};
export const FilterValue = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  font-weight: 500;

  .filterRadio {
    width: 24px;
    height: 24px;
    background-image: url(${getRadioState});
  }
`;
