import moment from 'moment';
import { formatUTCDate, formatUTCToUserTimezone } from 'src/utils/formatter';
import { getIspOwnerId, isAdmin } from 'src/AdminApp/components/users/utils';
export const DATE_FIELDS = [
    'last_notified_start_time',
    'modified',
    'scheduled_end_local',
    'scheduled_start_local',
    'created',
    'actual_start_local',
    'actual_end_local',
    'created',
];
export const USER_FIELDS = [
    'created_by',
    'createdBy',
    'modified_by',
    'modifiedBy',
];
export const LIST_ORDER = [
    'description',
    'status',
    'address_id',
    'default_delivery_van_id',
    'expected_revenue',
    'scheduled_duration_minutes',
    'actual_duration_minutes',
    'scheduled_start_local',
    'scheduled_end_local',
    'actual_start_local',
    'actual_end_local',
    'total_scheduled_labor_minutes',
    'total_remaining_labor_minutes',
];
export const DRAWER_FIELDS = {
    description: 'Description',
    status: 'Status',
    address_id: 'Address',
    default_delivery_van_id: 'Vans Assigned',
    expected_revenue: 'Expected Revenue',
    scheduled_duration_minutes: 'Scheduled Duration Minutes',
    actual_duration_minutes: 'Actual Duration Minutes',
    scheduled_start_local: 'Scheduled Start Local',
    scheduled_end_local: 'Scheduled End Local',
    actual_start_local: 'Actual Start Local',
    actual_end_local: 'Actual End  Local',
    total_scheduled_labor_minutes: 'Total Scheduled Labor Minutes',
    total_remaining_labor_minutes: 'Total Remaining Labor Minutes',
};
export const transformAuditLogData = (auditLogData) => {
    var _a;
    if (!auditLogData || (auditLogData === null || auditLogData === void 0 ? void 0 : auditLogData.length) === 0)
        return null;
    const snapshot = (_a = auditLogData.sort((a, b) => new Date(b.modified) - new Date(a.modified))[0]) === null || _a === void 0 ? void 0 : _a.snapshot;
    const filteredSnapshot = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in snapshot) {
        if (DRAWER_FIELDS[key]) {
            filteredSnapshot[key] = snapshot[key];
        }
    }
    const changes = {};
    for (let i = 0; i < auditLogData.length; i += 1) {
        const item = auditLogData[i];
        const data = item.changes.filter((el) => !!DRAWER_FIELDS[el.field]);
        for (let j = 0; j < data.length; j += 1) {
            const user = isAdmin(item.modifiedBy)
                ? getIspOwnerId(item.modifiedBy)
                : item.modifiedBy;
            if (changes[data[j].field]) {
                changes[data[j].field].push(Object.assign(Object.assign({}, data[j]), { date: item.modified, ispOwnerId: user, modifiedBy: item.modifiedBy }));
            }
            else {
                changes[data[j].field] = [
                    Object.assign(Object.assign({}, data[j]), { date: item.modified, ispOwnerId: user, modifiedBy: item.modifiedBy }),
                ];
            }
        }
    }
    return {
        snapshot: Object.assign(Object.assign({}, filteredSnapshot), { ispOwnerId: isAdmin(snapshot.created_by)
                ? getIspOwnerId(snapshot.created_by)
                : snapshot.created_by, created_by: snapshot.created_by, created: snapshot.created }),
        changes,
    };
};
export const transformAddressData = (addresses) => {
    return addresses
        .filter((item) => item === null || item === void 0 ? void 0 : item.payload)
        .reduce((acc, el) => {
        const snapshots = el.payload.sort((a, b) => new Date(b.modified) - new Date(a.modified));
        for (let i = 0; i < snapshots.length; i += 1) {
            const item = Object.assign(Object.assign({}, snapshots[i]), { ispOwnerId: isAdmin(snapshots[i].modifiedBy)
                    ? getIspOwnerId(snapshots[i].modifiedBy)
                    : snapshots[i].modifiedBy, created_by: snapshots[i].modifiedBy, created: snapshots[i].modified });
            if (acc[snapshots[i].id]) {
                acc[snapshots[i].id] = Object.assign(Object.assign({}, acc[snapshots[i].id]), { [i]: Object.assign({}, item) });
            }
            else {
                acc[snapshots[i].id] = {
                    0: Object.assign({}, item),
                };
            }
        }
        return acc;
    }, {});
};
export const getValue = (field, value, users) => {
    var _a, _b;
    if (DATE_FIELDS.includes(field) && typeof value === 'number') {
        return formatUTCDate(value / 1000, moment.tz.guess());
    }
    if (USER_FIELDS.includes(field) && users) {
        return (_b = (_a = users.find((el) => el.createdBy === value)) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : value;
    }
    return value;
};
export const getModifiedValue = (value) => {
    return formatUTCToUserTimezone(value);
};
export const getUniqueUsers = (data) => {
    const changes = Object.keys(data === null || data === void 0 ? void 0 : data.changes);
    if (!changes || (changes === null || changes === void 0 ? void 0 : changes.length) === 0) {
        return [
            {
                ispOwnerId: data.snapshot.ispOwnerId,
                createdBy: data.snapshot.created_by,
            },
        ];
    }
    return changes.reduce((acc, el) => {
        const changes = data.changes[el];
        for (let i = 0; i < changes.length; i += 1) {
            const item = changes[i];
            const itemExists = acc.find((el) => el.ispOwnerId === item.ispOwnerId || el.modifiedBy === item.modifiedBy);
            if (!itemExists) {
                acc.push({
                    ispOwnerId: item.ispOwnerId,
                    createdBy: item.modifiedBy,
                });
            }
        }
        const snapshotCreatedBy = acc.find((el) => el.ispOwnerId === data.snapshot.ispOwnerId ||
            el.modifiedBy === data.snapshot.created_by);
        if (!snapshotCreatedBy) {
            acc.push({
                ispOwnerId: data.snapshot.ispOwnerId,
                createdBy: data.snapshot.created_by,
            });
        }
        return acc;
    }, []);
};
export const getAddressString = (address) => {
    if (!address)
        return '';
    const location = [address.street, address.city, address.state, address.zip];
    return location.filter(Boolean).join(', ');
};
