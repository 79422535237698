import styled from 'styled-components';
import { colors, FONT_FAMILY, theme } from 'src/styles/theme';
import { Chip, Button } from 'src/components/mui';
export const EditShiftContent = styled.section `
  ${theme.breakpoints.down('sm')} {
    padding-bottom: 180px;
  }
`;
export const ShiftContent = styled.section `
  margin-top: 25px;

  .fleets-no-options {
    ${colors.RED}
  }
`;
export const SectionHeader = styled.h2 `
  font-family: ${FONT_FAMILY};
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.38px;
  color: ${colors.GRAY_3F};
  margin: 0;
  margin-top: 20px;
`;
export const FieldWrapperTop = styled.div `
  margin-top: 18px;

  ${theme.breakpoints.up('md')} {
    margin-top: 0;
  }
`;
export const FieldWrapper = styled.div `
  margin-top: 18px;
`;
export const AddNewWindowButton = styled(Chip) `
  cursor: pointer;
`;
export const HorizontalBorder = styled.div `
  width: 100%;
  height: 1px;
  opacity: 0.29;
  border: solid 0.5px #979797;
  margin-top: 20px;
`;
export const SaveButton = styled(Button) `
  height: 55px;
`;
