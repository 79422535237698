import React from 'react';
import { pickBy } from 'lodash';
import { createMenuItems } from 'src/AdminApp/components/form/utils';
import CarFields from 'src/AdminApp/containers/vehicle/CarModule/CarFields';
import ExtraCarFields from 'src/AdminApp/containers/vehicle/CarModule/ExtraCarFields';
import MilesIn from 'src/AdminApp/containers/vehicle/MilesIn';
import { States } from 'src/AdminApp/models/enums';
import { CAR_MSG } from 'src/AdminApp/modules/api/constants';
import { Button, Divider, Grid, InputAdornment, TextField, Typography, } from 'src/components/mui';
import { DrawerFormRow } from 'src/components/SimpleDrawer/SimpleDrawer.styles';
import SimpleModal from 'src/components/SimpleModal';
import styled from 'styled-components';
import CarCard from './CarCard';
import DecodePlateAlert from './DecodePlateAlert';
import useCarForm from './useCarForm';
const FormDivider = styled(Divider) `
  margin: 24px 0 40px 0;
`;
const CarForm = ({ editMode = false, isFleet = false, onChange, vehicle, vinPlateFieldsFirst = false, setIsSelectedCarVin, focusMilesOut = false, handleVinExists, childrenFleetIds, }) => {
    var _a, _b;
    const { stateVehicle, updateStateVehicle, isVinFull, isPlateFull, showDecodePlateAlert, setShowDecodePlateAlert, vinError, plateError, mileageOutError, shouldUpdateCarFieldsVehicle, setShouldUpdateCarFieldsVehicle, isLicensePlateOnFocus, setIsLicensePlateOnFocus, decodedVehicle, isVehicleChangingOldCarRequest, confirmationNewVehicleText, onDecodePlate, onFieldChange, handleCancelOverrideCar, handleOverrideCar, fleet, } = useCarForm({
        vehicle,
        onChange,
        editMode,
        setIsSelectedCarVin: setIsSelectedCarVin,
        isFleet,
        handleVinExists,
        childrenFleetIds,
    });
    return (<>
      <Grid container>
        <DrawerFormRow item xs={12}>
          <TextField variant="outlined" value={(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.vin) || ''} onChange={onFieldChange('vin')} label="VIN" type="text" helperText={vinError || (!isVinFull && CAR_MSG.VIN_CHARACTER_LENGTH)} name="vin" fullWidth error={!!vinError} required/>
        </DrawerFormRow>
        <DrawerFormRow item xs={8}>
          <TextField onFocus={() => setIsLicensePlateOnFocus(true)} onBlur={() => setIsLicensePlateOnFocus(false)} variant="outlined" value={(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumber) || ''} onChange={onFieldChange('plateNumber')} label="License Plate" type="text" name="plateNumber" fullWidth InputProps={{
            endAdornment: !!stateVehicle.plateNumber && (<InputAdornment position="end">
                  <Button onClick={isPlateFull && !!stateVehicle.plateNumberState
                    ? onDecodePlate
                    : () => setShowDecodePlateAlert(true)} color={isPlateFull && !!stateVehicle.plateNumberState
                    ? 'primary'
                    : 'secondary'}>
                    Decode
                  </Button>
                </InputAdornment>),
        }} helperText={plateError || (!isPlateFull && CAR_MSG.PLATE_CHARACTER_LENGTH)} error={!!plateError}/>
        </DrawerFormRow>
        <DrawerFormRow item xs={4}>
          <TextField variant="outlined" value={(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumberState) || ''} onChange={onFieldChange('plateNumberState')} label="State" type="text" name="plateNumberState" select fullWidth error={!(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumberState) &&
            !!(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumber) &&
            !isLicensePlateOnFocus}>
            {createMenuItems(States, true)}
          </TextField>
        </DrawerFormRow>
        {decodedVehicle && (<DrawerFormRow item xs={12}>
            <Typography variant="subtitle1">
              VIN/Plate Decoded Vehicle Details
            </Typography>
            <CarCard car={decodedVehicle}/>
          </DrawerFormRow>)}
      </Grid>
      <FormDivider />
      {vinPlateFieldsFirst ? (<ExtraCarFields stateVehicle={stateVehicle} onFieldChange={onFieldChange} isFleet={isFleet} editMode={editMode} fleet={fleet}/>) : (<CarFields vehicle={stateVehicle} onUpdate={(values) => {
                updateStateVehicle(Object.assign(Object.assign({}, pickBy(values)), { mileage: stateVehicle.mileage, vin: stateVehicle.vin, notFoundVin: stateVehicle.notFoundVin, plateNumber: stateVehicle.plateNumber, plateNumberState: stateVehicle.plateNumberState }));
            }} updateAllFields={shouldUpdateCarFieldsVehicle} setUpdateComplete={() => setShouldUpdateCarFieldsVehicle(false)}/>)}
      <FormDivider />
      {vinPlateFieldsFirst ? (<CarFields vehicle={stateVehicle} onUpdate={(values) => {
                updateStateVehicle(Object.assign(Object.assign({}, pickBy(values)), { mileage: stateVehicle.mileage, vin: stateVehicle.vin, notFoundVin: stateVehicle.notFoundVin, plateNumber: stateVehicle.plateNumber, plateNumberState: stateVehicle.plateNumberState }));
            }} updateAllFields={shouldUpdateCarFieldsVehicle} setUpdateComplete={() => setShouldUpdateCarFieldsVehicle(false)}/>) : (<ExtraCarFields stateVehicle={stateVehicle} onFieldChange={onFieldChange} isFleet={isFleet} editMode={editMode} fleet={fleet}/>)}
      {!isFleet && editMode && (<>
          <FormDivider />
          <Grid container>
            <MilesIn stateVehicle={stateVehicle} onFieldChange={onFieldChange} isFleet={isFleet}/>
            <DrawerFormRow item xs={12}>
              <TextField variant="outlined" value={(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.mileageOut) || ''} onChange={onFieldChange('mileageOut')} label="Miles Out" type="text" name="mileageOut" helperText={mileageOutError} fullWidth InputProps={{
                endAdornment: (<InputAdornment position="end">miles</InputAdornment>),
            }} autoFocus={focusMilesOut} error={focusMilesOut &&
                ((_a = stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.mileageOut) !== null && _a !== void 0 ? _a : 0) < ((_b = stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.mileage) !== null && _b !== void 0 ? _b : 0)}/>
            </DrawerFormRow>
          </Grid>
        </>)}
      <DecodePlateAlert open={showDecodePlateAlert} onClick={() => setShowDecodePlateAlert(false)} autoHideDuration={3000}/>

      <SimpleModal open={isVehicleChangingOldCarRequest} title="Some fields are not matching the old car information: " onClose={handleCancelOverrideCar} simpleTwoButtonRowProps={{
            onCancel: handleCancelOverrideCar,
            onSubmit: handleOverrideCar,
            submitText: 'Override Car',
        }}>
        {confirmationNewVehicleText.map((error) => (<p key={error}>{error}</p>))}
      </SimpleModal>
    </>);
};
export default CarForm;
