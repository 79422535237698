import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { STATIC_URL_BASE } from 'src/styles/theme';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { GuidePersonImg, IntroContent, IntroCopy, IntroHeader, } from '../CantFinishDrawer.styles';
const CantFinishConditionsDrawerStart = ({ nextStep, introCopyText, specialBehavior, onClose, onCallIneligibleOrConditionPoorOrAddtlRepairNoOption, referenceNum, cantFinishOptionChosen, }) => {
    const [introCopy, setIntroCopy] = useState();
    useEffect(() => {
        setIntroCopy(introCopyText);
    }, [introCopyText]);
    const getContentComponent = () => {
        if (specialBehavior === 'REQUIRES_ADDTL_REPAIR_START') {
            return (<>
          <SimpleDrawerContent>
            <IntroContent style={{ marginTop: 60 }}>
              <IntroHeader>Are you sure?</IntroHeader>
              <IntroCopy>
                <ReactMarkdown>{introCopy}</ReactMarkdown>
              </IntroCopy>
              <IntroCopy style={{ marginTop: 30 }}>
                <ReactMarkdown>
                  Has the customer agreed to the additional repairs?
                </ReactMarkdown>
              </IntroCopy>
            </IntroContent>
          </SimpleDrawerContent>
          <SimpleDrawerFooter>
            <SimpleTwoButtonRow submitText="Yes" onSubmit={nextStep} cancelText="No" onCancel={() => {
                    onClose();
                    onCallIneligibleOrConditionPoorOrAddtlRepairNoOption(referenceNum, cantFinishOptionChosen);
                }}/>
          </SimpleDrawerFooter>
        </>);
        }
        if (specialBehavior === 'SCREEN_FOR_MEASUREMENTS_REQUIRED') {
            return (<>
          <SimpleDrawerContent>
            <IntroContent>
              <GuidePersonImg src={`${STATIC_URL_BASE}tools/guide-illustration.svg`} alt="person with speech bubble"/>
              <IntroHeader>Hey Tech!</IntroHeader>
              <IntroCopy>
                <ReactMarkdown>{introCopy}</ReactMarkdown>
              </IntroCopy>
            </IntroContent>
          </SimpleDrawerContent>
          <SimpleDrawerFooter>
            <FooterActionButton onClick={() => {
                    window.location.reload();
                }}>
              OK
            </FooterActionButton>
          </SimpleDrawerFooter>
        </>);
        }
        return (<>
        <SimpleDrawerContent>
          <IntroCopy>
            <GuidePersonImg src={`${STATIC_URL_BASE}tools/guide-illustration.svg`} alt="person with speech bubble"/>
            <IntroHeader>Hey Tech!</IntroHeader>

            <ReactMarkdown>{introCopy}</ReactMarkdown>
          </IntroCopy>
        </SimpleDrawerContent>
        <SimpleDrawerFooter>
          <FooterActionButton onClick={nextStep}>Next</FooterActionButton>
        </SimpleDrawerFooter>
      </>);
    };
    return getContentComponent();
};
export default CantFinishConditionsDrawerStart;
