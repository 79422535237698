import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'src/components/tooltips';
import { colors } from 'src/styles/theme';
import { startCase } from 'lodash';
import SimpleChip from 'src/components/SimpleChip';
import useIsMobile from 'src/hooks/useIsMobile';
import { formatResolution, formatStateWithResolution } from './utils';
const LabelColored = styled.div `
  color: ${colors.primary};
`;
const ClosedStatesLabel = ({ closedRequest }) => {
    var _a;
    const isMobile = useIsMobile();
    const resolutionStatus = (closedRequest === null || closedRequest === void 0 ? void 0 : closedRequest.repairOrder.resolution) || (closedRequest === null || closedRequest === void 0 ? void 0 : closedRequest.resolution);
    const resolutionText = (closedRequest === null || closedRequest === void 0 ? void 0 : closedRequest.repairOrder.otherResolution) ||
        (closedRequest === null || closedRequest === void 0 ? void 0 : closedRequest.otherResolution);
    const chip = !isMobile ? (<SimpleChip $bgColor={colors.black} $textColor={colors.white} label={startCase((_a = formatResolution(resolutionStatus)) !== null && _a !== void 0 ? _a : '')}/>) : (<LabelColored>
      {formatStateWithResolution(closedRequest.state, resolutionStatus)}
    </LabelColored>);
    return resolutionText ? (<Tooltip title={resolutionText}>{chip}</Tooltip>) : (chip);
};
export default ClosedStatesLabel;
