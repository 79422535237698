import React from 'react';
import styled from 'styled-components';
import { Accordion as MAccordion, AccordionDetails, AccordionSummary, Grid, } from 'src/components/mui';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
const StyledAccordion = styled(MAccordion) `
  box-shadow: none;
  border: 0px;
  width: 100%;
  background-color: ${colors.whiteSmokeGrey};
  padding: 0 10px;
`;
const StyledAccordionSummary = styled(AccordionSummary) `
  padding: 0px;
  border-radius: 0 !important;
`;
const StyledAccordionDetails = styled(AccordionDetails) `
  display: flex;
  flex-direction: column;
  padding: 0px;
`;
const Accordion = ({ children }) => {
    return (<StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <b>Removed Repairs</b>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Grid container>{children}</Grid>
      </StyledAccordionDetails>
    </StyledAccordion>);
};
export default Accordion;
