var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { selectMarketingSourcesById } from 'src/AdminApp/modules/lookups/selectors';
import { loadMarketingSources } from 'src/AdminApp/modules/lookups/actions';
import { updateConsumer } from 'src/AdminApp/modules/consumers/actions';
import { selectAuthorizedSystemAdmin } from 'src/modules/auth/selectors';
const useConsumerInfo = ({ id }) => {
    const [editable, setEditable] = useState(false);
    const dispatch = useDispatch();
    const marketingSourcesById = useSelector(selectMarketingSourcesById);
    const isSystemAdmin = useSelector(selectAuthorizedSystemAdmin);
    useEffect(() => {
        if (!marketingSourcesById) {
            dispatch(loadMarketingSources());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketingSourcesById]);
    const handleSubmit = (values, { resetForm }) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield dispatch(updateConsumer(Object.assign(Object.assign({}, values), { id })));
        if (!(result.payload instanceof Error)) {
            toast.success('Consumer information successfully updated.');
        }
        setEditable(false);
        resetForm();
    });
    return {
        editable,
        setEditable,
        marketingSourcesById,
        handleSubmit,
        isSystemAdmin,
    };
};
export default useConsumerInfo;
