import createAPIAction from 'src/modules/api';
import createDefaultActionCreators, { createActionCreators, } from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
export const ActionCreators = {
    GET_REQUEST_NOTES: createDefaultActionCreators('GET_REQUEST_NOTES'),
    GET_REQUEST_NOTES_APPLIES_TO: createDefaultActionCreators('GET_REQUEST_NOTES_APPLIES_TO'),
    GET_REQUEST_NOTES_APPLIES_TO_RECOMMENDED_REPAIRS: createDefaultActionCreators('GET_REQUEST_NOTES_APPLIES_TO_RECOMMENDED_REPAIRS'),
    GET_MISSING_3C_NOTES: createDefaultActionCreators('GET_MISSING_3C_NOTES'),
    SAVE_REQUEST_NOTES: createDefaultActionCreators('SAVE_REQUEST_NOTES'),
    NOTES_DRAWER: createActionCreators(['OPEN', 'CLOSE'], 'NOTES_DRAWER'),
};
export const getRequestNotes = (repairRequestId, sort, pageSize, pageIndex) => {
    const params = Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort }));
    return createAPIAction({
        types: [
            ActionCreators.GET_REQUEST_NOTES.FETCH({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.GET_REQUEST_NOTES.SUCCESS({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.GET_REQUEST_NOTES.FAILURE({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
        ],
        endpoint: `/api/repair-service/admin/notes/requests/${repairRequestId}`,
        params: Object.assign({}, params),
        method: 'GET',
    });
};
export const getRequestNotesAppliesTo = (repairRequestId) => createAPIAction({
    types: [
        ActionCreators.GET_REQUEST_NOTES_APPLIES_TO.FETCH(),
        ActionCreators.GET_REQUEST_NOTES_APPLIES_TO.SUCCESS(),
        ActionCreators.GET_REQUEST_NOTES_APPLIES_TO.FAILURE(),
    ],
    endpoint: `/api/repair-service/admin/notes/requests/${repairRequestId}/appliesTo`,
    method: 'GET',
});
export const getRequestNotesAppliesToRecommendedRepairs = (repairRequestId) => createAPIAction({
    types: [
        ActionCreators.GET_REQUEST_NOTES_APPLIES_TO_RECOMMENDED_REPAIRS.FETCH(),
        ActionCreators.GET_REQUEST_NOTES_APPLIES_TO_RECOMMENDED_REPAIRS.SUCCESS(),
        ActionCreators.GET_REQUEST_NOTES_APPLIES_TO_RECOMMENDED_REPAIRS.FAILURE(),
    ],
    endpoint: `/api/repair-service/admin/notes/requests/${repairRequestId}/appliesTo`,
    params: {
        isRecommendedRepairs: true,
    },
    method: 'GET',
});
export const addRequestNotes = (params) => createAPIAction({
    types: [
        ActionCreators.SAVE_REQUEST_NOTES.FETCH(),
        ActionCreators.SAVE_REQUEST_NOTES.SUCCESS(),
        ActionCreators.SAVE_REQUEST_NOTES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.NOTES(),
    method: 'POST',
    body: params,
});
export const getMissing3CRequestNotes = (repairRequestId) => createAPIAction({
    types: [
        ActionCreators.GET_MISSING_3C_NOTES.FETCH(),
        ActionCreators.GET_MISSING_3C_NOTES.SUCCESS(),
        ActionCreators.GET_MISSING_3C_NOTES.FAILURE(),
    ],
    endpoint: `/api/repair-service/admin/notes/requests/${repairRequestId}/3cs`,
    method: 'GET',
});
export const openNotesDrawer = () => ({
    type: ActionCreators.NOTES_DRAWER.OPEN.type,
});
export const closeNotesDrawer = () => ({
    type: ActionCreators.NOTES_DRAWER.CLOSE.type,
});
