import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRepairs } from 'src/PricingApp/modules/repairs/selectors';
import { selectDashboardContent, selectDashboardPagination, selectDashboardParams, selectDashboardFetching, } from 'src/PricingApp/modules/utils/selectors';
import { searchRepairs } from 'src/PricingApp/modules/repairs/actions';
const useRepairDashboard = () => {
    const dispatch = useDispatch();
    const repairs = useSelector(selectDashboardContent(selectRepairs));
    const pagination = useSelector(selectDashboardPagination(selectRepairs));
    const params = useSelector(selectDashboardParams(selectRepairs));
    const isFetching = useSelector(selectDashboardFetching(selectRepairs));
    const onLoadData = ({ page, size, sort }) => dispatch(searchRepairs(Object.assign(Object.assign({}, params), { pagination: {
            size,
            page,
            sort,
        } })));
    const onSearch = (filters) => {
        dispatch(searchRepairs({
            filters,
            pagination: {
                size: pagination.size,
                page: 0,
                sort: pagination.sort,
            },
        }));
    };
    useEffect(() => {
        var _a, _b, _c;
        onLoadData({
            page: (_a = pagination.pageNumber) !== null && _a !== void 0 ? _a : 0,
            size: (_b = pagination.pageSize) !== null && _b !== void 0 ? _b : 20,
            sort: (_c = pagination.sort) !== null && _c !== void 0 ? _c : ['id,asc'],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        repairs,
        pagination,
        params,
        isFetching,
        onLoadData,
        onSearch,
    };
};
export default useRepairDashboard;
