import { toEnum } from 'src/models/enums';
export const RepairRequestState = toEnum({
    NEW_REQUEST: 'New Request',
    OPEN_REQUEST: 'Open Request',
    CONFIRMED_APPOINTMENT: 'Confirmed Appointment',
    REPAIR_IN_PROGRESS: 'Repair in Progress',
    CLOSED_REPAIR: 'Closed Repair',
    CLOSED_REPAIR_COMPLETION_UNKNOWN: 'Closed Repair - Completion Unknown',
    CANCELED_REQUEST: 'Canceled Request',
    CANCELED_APPOINTMENT_CUSTOMER: 'Canceled Appointment by Customer',
    CANCELED_APPOINTMENT_NO_CONFIRMED_ARRIVAL: 'Canceled Appointment - No Confirmed Arrival',
    INVALID: 'Invalid',
});
export const RepairRequestStateInShort = toEnum({
    NEW_REQUEST: 'New',
    OPEN_REQUEST: 'Open',
    CONFIRMED_APPOINTMENT: 'Confirmed',
    REPAIR_IN_PROGRESS: 'In Progress',
    CLOSED_REPAIR: 'Closed',
    CLOSED_REPAIR_COMPLETION_UNKNOWN: 'Closed',
    CANCELED_REQUEST: 'Canceled',
    CANCELED_APPOINTMENT_CUSTOMER: 'Canceled',
    CANCELED_APPOINTMENT_NO_CONFIRMED_ARRIVAL: 'Canceled',
    INVALID: 'Invalid',
});
export const closedStates = [
    'CLOSED_REPAIR',
    'CLOSED_REPAIR_COMPLETION_UNKNOWN',
    'CANCELED_REQUEST',
    'CANCELED_APPOINTMENT_CUSTOMER',
    'CANCELED_APPOINTMENT_NO_CONFIRMED_ARRIVAL',
    'INVALID',
];
export const canceledStates = [
    'CANCELED_REQUEST',
    'CANCELED_APPOINTMENT_CUSTOMER',
    'CANCELED_APPOINTMENT_NO_CONFIRMED_ARRIVAL',
];
export default RepairRequestState;
