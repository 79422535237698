import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Grid } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Container, Title, Content } from './Details/PartPickupDetails.styles';
const BigButton = styled(Button) `
  height: 100%;
`;
const PartPickup = () => {
    const history = useHistory();
    return (<Container>
      <Title>Part Pickup</Title>
      <Content>
        <Formik initialValues={{
            referenceNum: '',
        }} onSubmit={(data) => {
            history.replace(`part-pickups/${data.referenceNum}`);
        }}>
          {({ isSubmitting }) => (<Form>
              <Grid container spacing={2}>
                <Grid item xs={10} md={10}>
                  <TextField placeholder="Part Pickup Reference Number" label="Search Query" name="referenceNum" variant="outlined" autoFocus fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <BigButton size="small" variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting}>
                    Search
                  </BigButton>
                </Grid>
              </Grid>
            </Form>)}
        </Formik>
      </Content>
    </Container>);
};
export default PartPickup;
