import { createSelector } from 'reselect';
export const selectSalesMatch = (state) => state.admin.salesMatch;
export const selectDashboard = createSelector(selectSalesMatch, (salesMatch) => { var _a; return (_a = salesMatch.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectFiltersPanelState = createSelector(selectSalesMatch, (salesMatch) => salesMatch.filtersPanel || {});
export const selectDashboardSalesMatch = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.data) !== null && _a !== void 0 ? _a : []; });
export const selectDashboardPagination = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardParams = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardFetching = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
export const selectDashboardIsFetching = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
export const selectDashboardSummaryInfo = createSelector(selectSalesMatch, (salesMatch) => { var _a; return (_a = salesMatch.summaryInfo) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardIsDownloading = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard._isDownloading) !== null && _a !== void 0 ? _a : false; });
