import React from 'react';
import AddDiscountDrawer from 'src/AdminApp/components/discounts/components';
import RemoveDiscountModal from 'src/AdminApp/components/discounts/components/RemoveDiscountModal';
import RenderDiscounts from 'src/AdminApp/components/discounts/RenderDiscounts';
import { RepairsTableHeader } from 'src/AdminApp/components/repairs/components/RepairDropdown';
import InfoStatus from 'src/AdminApp/components/repairs/InfoStatus';
import { Diagnoses, RemovedRepairs, } from 'src/AdminApp/components/repairs/statement';
import InitialPrice from 'src/AdminApp/components/repairs/statement/InitialPrice';
import RepairsDisplay from 'src/AdminApp/components/repairs/statement/Repairs/RepairsDisplay';
import AuthBtn from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/AuthBtn';
import PayerDrawer from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/PayerDrawer';
import { responsiblePartyTypeContext } from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/utils';
import PartsHelp from 'src/AdminApp/containers/requests/Quote/PartsHelp';
import useQuote from 'src/AdminApp/containers/requests/Quote/useQuote';
import { updateSuggestedRepair } from 'src/AdminApp/modules/requests/actions';
import { SimpleModal } from 'src/components/layout';
import CollapsibleBlock from 'src/components/layout/CollapsibleBlock';
import { Button, Grid, Typography } from 'src/components/mui';
import { FileCopy, GetApp } from 'src/components/mui/icons';
import SimpleChip from 'src/components/SimpleChip';
import VisitCapacityWarningModal from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
import ActionsFooter from './ActionsFooter';
import QuoteDetails from './QuoteDetails';
const { Provider } = responsiblePartyTypeContext;
const QuoteFooter = styled(Grid) `
  border-top: 1px dashed ${colors.lightGrey};
  padding-bottom: 50px;
  padding-top: 24px;
`;
const Quote = ({ canEdit, hasInvoice, onSendForApproval }) => {
    var _a, _b, _c, _d;
    const { referenceNum, selectorProps } = usePageReferenceNum();
    const { quoteFmcWorkAuthorizationStatus, responsiblePartyTypeValue, knownRepairs, diagnosis, isQuoteEditable, relatedParts, addComponent, updateComponent, removeComponent, removeRepair, diagnoses, removeCustomRepair, customRequests, addCustomComponent, updateCustomComponent, removeCustomComponent, credits, otherDiscounts, subscriptionDiscounts, promos, openRemoveDiscountDialog, workOrderItemResponsiblePartyAuthInfo, isAutoIntegrateFmc, fetchQuoteFmcWorkAuthorizationStatus, canAccessDiscount, onAddPromoCode, onAddCancelCreditCredit, onAddOtherDiscount, cancelFeeCredits, workOrder, totalJobDurationMinutes, totalLaborDuration, workOrderResponsiblePartyTypeTotals, isProvideQuoteButtonEnabled, isRequestNewOrOpen, setIsSendEmailOpen, onDownloadQuote, onCopyQuoteLink, advisorCallRequestedDate, handleSendForApproval, isSendForApprovalButtonEnabled, isSendForApprovalButtonVisible, isQuickOrderEnabled, canUseQuickOrder, handleQuickOrder, isSendEmailConfirmDialogOpen, onSendEmailConfirm, isRemoveDiscountOpen, discountType, closeRemoveDiscountDialog, onRemoveDiscount, workOrderId, handleClosePayerModal, payerState, handleSalesCallRequest, showCapacityWarning, handleCapacityWarningClose, capacityWarningMessage, repairRequestId, car, } = useQuote({
        referenceNum,
        selectorProps,
        canEdit,
        hasInvoice,
        onSendForApproval,
    });
    return (<CollapsibleBlock hashName="quote" header={<Typography variant="h5" gutterBottom>
          Quote{' '}
          {quoteFmcWorkAuthorizationStatus && (<span style={{ paddingLeft: '10px' }}>
              <SimpleChip $bgColor={colors.orange} $textColor={colors.white} label={quoteFmcWorkAuthorizationStatus}/>
            </span>)}
        </Typography>} defaultOpen={!hasInvoice}>
      <div>
        <RepairsTableHeader />
        <Provider value={responsiblePartyTypeValue}>
          <RepairsDisplay repairs={knownRepairs} orderStartedAt={(_a = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _a === void 0 ? void 0 : _a.orderStartedAt} orderStartedBy={(_b = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _b === void 0 ? void 0 : _b.orderStartedBy} canEdit={isQuoteEditable} renderQuoted={(price) => <InitialPrice price={price}/>} renderTotal={(price) => price.totalCost} relatedParts={relatedParts} addComponent={addComponent} updateComponent={updateComponent} removeComponent={removeComponent} removeRepair={removeRepair} updateSuggestedRepair={updateSuggestedRepair} referenceNum={referenceNum} displayAuditChip workOrderId=""/>

          <Diagnoses repairs={diagnoses} onRemove={removeCustomRepair} displayAuditChip/>
          <RepairsDisplay repairs={customRequests} orderStartedAt={(_c = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _c === void 0 ? void 0 : _c.orderStartedAt} orderStartedBy={(_d = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _d === void 0 ? void 0 : _d.orderStartedBy} showCustom canEdit={isQuoteEditable} renderQuoted={(price) => <InitialPrice price={price}/>} renderTotal={(price) => price === null || price === void 0 ? void 0 : price.totalCost} addComponent={addCustomComponent} updateComponent={updateCustomComponent} removeComponent={removeCustomComponent} removeRepair={removeCustomRepair} updateSuggestedRepair={updateSuggestedRepair} referenceNum={referenceNum} displayAuditChip workOrderId="" relatedParts={undefined}/>
        </Provider>
      </div>

      <RemovedRepairs diagnosis={diagnosis}/>
      <RenderDiscounts canEdit={isQuoteEditable} credits={credits} otherDiscounts={otherDiscounts} subscriptions={subscriptionDiscounts} promos={promos} remove={openRemoveDiscountDialog}/>
      {diagnosis &&
            workOrderItemResponsiblePartyAuthInfo &&
            Object.keys(workOrderItemResponsiblePartyAuthInfo).map((woirp) => {
                var _a;
                return (<AuthBtn referenceNum={referenceNum} workOrderResponsiblePartyAuthorization={(_a = diagnosis.workOrderResponsiblePartyAuthorizations) === null || _a === void 0 ? void 0 : _a.find((auth) => auth.responsiblePartyName === woirp)} workOrderId={workOrderId} responsiblePartyName={woirp} repairsToAuthorize={Object.values(workOrderItemResponsiblePartyAuthInfo[woirp])} repairRequestId={repairRequestId}/>);
            })}
      <PartsHelp isFmcRo={isAutoIntegrateFmc} fetchQuoteFmcWorkAuthorizationStatus={fetchQuoteFmcWorkAuthorizationStatus} isEditable={isQuoteEditable}/>
      {canAccessDiscount && isQuoteEditable && (<AddDiscountDrawer promos={promos} credits={credits} others={otherDiscounts} onAddPromoCode={onAddPromoCode} onAddCFCredit={onAddCancelCreditCredit} onAddOtherDiscount={onAddOtherDiscount} cancelFeeCredits={cancelFeeCredits}/>)}
      <QuoteFooter container>
        <QuoteDetails quote={workOrder === null || workOrder === void 0 ? void 0 : workOrder.quote} totalJobDurationMinutes={totalJobDurationMinutes} totalLaborDuration={totalLaborDuration} workOrderResponsiblePartyTypeTotals={workOrderResponsiblePartyTypeTotals}/>
      </QuoteFooter>
      {isQuoteEditable && (<ActionsFooter isProvideQuoteButtonEnabled={isProvideQuoteButtonEnabled} isRequestNewOrOpen={isRequestNewOrOpen} onSendQuote={() => setIsSendEmailOpen(true)} onDownloadQuote={onDownloadQuote} onCopyQuoteLink={onCopyQuoteLink} onSalesCallRequest={handleSalesCallRequest} advisorCallRequestedDate={advisorCallRequestedDate} onSendForApproval={handleSendForApproval} isSendForApprovalButtonEnabled={isSendForApprovalButtonEnabled} isSendForApprovalButtonVisible={isSendForApprovalButtonVisible} isQuickOrderEnabled={isQuickOrderEnabled} isQuickOrderVisible={canUseQuickOrder} onQuickOrder={handleQuickOrder}/>)}

      {canEdit && hasInvoice && (<Grid item xs={12}>
          <Button startIcon={<FileCopy />} onClick={onCopyQuoteLink} color="primary">
            Copy Customer Quote Link
          </Button>

          <Button onClick={onDownloadQuote} color="primary" startIcon={<GetApp />}>
            Download Quote
          </Button>
        </Grid>)}

      {(workOrder === null || workOrder === void 0 ? void 0 : workOrder.quote.activeWorkAuthorization) && (<InfoStatus provided={workOrder.quote.activeWorkAuthorization.providedAt} providedBy={workOrder.quote.activeWorkAuthorization.providedBy}/>)}
      <SimpleModal open={isSendEmailConfirmDialogOpen} onClose={() => setIsSendEmailOpen(false)} title={'You have saved the quote.\nWould you like to Email Quote to Consumer?'} simpleTwoButtonRowProps={{
            cancelText: 'No',
            submitText: 'Yes',
            onCancel: () => onSendEmailConfirm(false),
            onSubmit: () => onSendEmailConfirm(true),
        }}/>
      <RemoveDiscountModal isModalOpen={isRemoveDiscountOpen} label={discountType} closeModal={closeRemoveDiscountDialog} submit={onRemoveDiscount}/>
      {workOrderId && (<PayerDrawer workOrderId={workOrderId} handleClose={handleClosePayerModal} open={payerState.payerModalOpen} car={car}/>)}

      <VisitCapacityWarningModal open={showCapacityWarning} onClose={handleCapacityWarningClose} warningMessage={capacityWarningMessage}/>
    </CollapsibleBlock>);
};
export default Quote;
