import React from 'react';
import { map } from 'lodash';
import { Divider, Table, TableBody, TableCell, TableHead, TableRow, } from 'src/components/mui';
import { DeliveryChannel } from 'src/AdminApp/models/enums';
import Subtext from 'src/components/layout/Subtext';
import styled from 'styled-components';
import useIsMobile from 'src/hooks/useIsMobile';
import { theme } from 'src/styles/theme';
import ShopCard from './ShopCard';
const Wrapper = styled.div `
  margin: 10px 0;
  overflow: scroll;
  width: 100%;

  ${theme.breakpoints.up('md')} {
    height: 100%;
    width: 100%;
  }
`;
const StyledTableRow = styled(TableRow) `
  cursor: pointer;
`;
const List = ({ shops, selectShop, hoveredListId, onShopHover, onShopLeave, }) => {
    const isMobile = useIsMobile();
    if (isMobile) {
        return (<div>
        <Divider />
        {map(shops, (shop) => (<ShopCard shop={shop} onClick={() => selectShop(shop)}/>))}
      </div>);
    }
    return (<Wrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Match Score</TableCell>
            <TableCell>Shop Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Shop Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(shops, (shop, index) => {
            var _a, _b, _c, _d, _e, _f;
            return (<StyledTableRow key={index} onClick={() => selectShop(shop)} selected={shop.id === hoveredListId} onMouseOver={(e) => onShopHover(e, shop)} onMouseLeave={onShopLeave} hover>
              <TableCell>{(_a = shop.matchScore) !== null && _a !== void 0 ? _a : ''}</TableCell>
              <TableCell>
                {(_b = shop.shopName) !== null && _b !== void 0 ? _b : ''}
                <Subtext title="Active Status">{(_c = shop.status) !== null && _c !== void 0 ? _c : ''}</Subtext>
              </TableCell>
              <TableCell>
                <div>{(_d = shop.address) !== null && _d !== void 0 ? _d : ''}</div>
                <div>
                  {(_e = shop.city) !== null && _e !== void 0 ? _e : ''}, {shop.state} {shop.zip}
                </div>
              </TableCell>
              <TableCell>
                <div>{shop.phone}</div>
                <div>
                  {shop.firstName} {shop.lastName}
                </div>
              </TableCell>
              <TableCell>
                {map((_f = shop.deliveryChannels) !== null && _f !== void 0 ? _f : [], (channel) => DeliveryChannel.display(channel)).join(', ')}
              </TableCell>
            </StyledTableRow>);
        })}
        </TableBody>
      </Table>
    </Wrapper>);
};
export default List;
