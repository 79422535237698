import { forIn } from 'lodash';
const getDisplayText = (values, value) => {
    return (value && (values[value] || values[value.toUpperCase()] || `${value} (?)`));
};
export const toEnum = (values) => {
    return Object.keys(values).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: key.toUpperCase() })), {
        values,
        display: (value) => getDisplayText(values, value),
    });
};
export const enumToArray = (_enum) => {
    const result = [];
    forIn(_enum.values, (value, key) => {
        result.push({
            id: key,
            label: value,
        });
    });
    return result;
};
export const AUTH_ROLES = toEnum({
    ACCOUNT_MANAGER: 'Account_Manager',
    AFFIRM_PAYMENT_CHARGE: 'Affirm_Payment_Charge',
    APPOINTMENT_DATE_EDITOR: 'Appointment_Date_Editor',
    B2B_FIXED_MARKUP_EDITOR: 'B2B_Fixed_Markup_Editor',
    CANCEL_APPTS_VISITS: 'Cancel_Appts_Visits',
    CANCELLATION_REASON_EDITOR: 'Cancellation_Reason_Editor',
    COMPONENT_EDITOR: 'Component_Editor',
    CUSTOMER_EDITOR: 'Customer_Editor',
    CUSTOMER_SEARCH: 'Customer_Search',
    CUSTOMER_VIEWER: 'Customer_Viewer',
    DELIVERY_VAN_EDITOR: 'Delivery_Van_Editor',
    DELIVERY_VAN_VIEWER: 'Delivery_Van_Viewer',
    ESCALATION_PAYMENTS: 'Show_Esc_Pay',
    FINANCE_ADMIN: 'Finance_Admin',
    FLEET_EDITOR: 'Fleet_Editor',
    FMC_ADMIN: 'FMC_Admin',
    FPR_EDITOR: 'FPR_Editor',
    GEO_EDITOR: 'Geo_Editor',
    GEO_LABOR_RATE_EDITOR: 'Geo_Labor_Rate_Editor',
    HYPERION_EDIT: 'Hyperion_Edit',
    GEO_SEARCH: 'Geo_Search',
    GEO_VIEWER: 'Geo_Viewer',
    HIDE_ADD_DISCOUNT: 'Hide_Add_Discount',
    INTERNAL_PAYMENT_CHARGE: 'Internal_Payment_Charge',
    ISP_ADMIN: 'ISP_ADMIN',
    LOCK_ADMIN: 'Lock_Admin',
    LOCKED_RO_EDITOR: 'Locked_RO_Editor',
    MARKETING_SOURCE_EDITOR: 'Marketing_Source_Editor',
    OFFLINE_FOUR_CORNERS: 'Offline_Four_Corners',
    PART_DISTRIBUTOR_EDITOR: 'Part_Distributor_Editor',
    PARTS_EDITOR: 'Parts_Editor',
    PARTS_INTEGRATED_ORDERING: 'Parts_Integrated_Ordering',
    PARTS_VIEWER: 'Parts_Viewer',
    PARTNER_API_KEY_EDITOR: 'Partner_API_Key_Editor',
    PARTNERS_EDITOR: 'Partners_Editor',
    PAYMENT_CHARGE: 'Payment_Charge',
    PAYMENT_CHARGE_CARD: 'Payment_Charge_Card',
    PAYMENT_PREAUTH: 'Payment_Preauth',
    PAYMENT_REFUND: 'Payment_Refund',
    PAYMENT_REFUND_SUBMITTER: 'Payment_Refund_Submitter',
    POPS_MANUAL_ORDER_VIEWER: 'POPS_Manual_Order_Viewer',
    PRICING_ADMIN: 'Pricing_Admin',
    PRICING_USER: 'Pricing_User',
    PRODUCT_ENG_CANCEL_VIEWER: 'PE_Cancel_View',
    QUICK_ORDER: 'Quick_Order',
    REPAIR_EDITOR: 'Repair_Editor',
    REPAIR_SEARCH: 'Repair_Search',
    REPAIR_VIEWER: 'Repair_Viewer',
    REPAIR_REQUEST_STATUS_EDITOR: 'Repair_Request_Status_Editor',
    REQUEST_HISTORY_VIEWER: 'Request_History_Viewer',
    REQUEST_SERVICE_ADVISOR_EDITOR: 'Request_Service_Advisor_Editor',
    RESPONSIBLE_PARTY_EDITOR: 'Responsible_Party_Editor',
    RO_PRICE_EDIT: 'Ro_Price_Edit',
    RO_PRICE_EDIT_REC_REPAIRS: 'Ro_Price_Edit_Rec_Repairs',
    RO_PRICE_EDIT_SILVER_ROCK: 'Ro_Price_Edit_Silverrock',
    SALES_MATCH_EDITOR: 'Sales_Match_Editor',
    SALES_MATCH_VIEWER: 'Sales_Match_Viewer',
    SHIFT_ADMIN: 'Shift_Admin',
    SHOP_EDITOR: 'Shop_Editor',
    SHOP_SEARCH: 'Shop_Search',
    SHOP_VIEWER: 'Shop_Viewer',
    SHOW_ACTION_BUTTONS_NOT_MAIN: 'Show_Action_Buttons_Non_Main',
    SKIP_FOUR_CORNER_MPI: 'Skip_Four_Corner_MPI',
    SNAPSHOT_VIEWER: 'Snapshot_Viewer',
    SUBLET_VALET_BETA: 'Sublet_Valet_Beta',
    SYSTEM_ADMIN: 'System_Admin',
    SYSTEM_USER: 'System_User',
    TECH_DASHBOARD_REDIRECT: 'Tech_Dashboard_Redirect',
    TECH_DASHBOARD_VIEWER: 'Tech_Dashboard_Viewer',
    TECHNICIAN: 'Technician',
    TEMPLATE_HSTORE_MGR: 'Template_HStore_Mngr',
    USER_EDITOR: 'User_Editor',
    WARRANTY_ADMIN: 'Warranty_Admin',
    WORP_DRIVE_ACCESS: 'WORP Drive Access',
    ZENDESK_CHAT: 'Zendesk_Chat',
});
export var Weekdays;
(function (Weekdays) {
    Weekdays["MONDAY"] = "MONDAY";
    Weekdays["TUESDAY"] = "TUESDAY";
    Weekdays["WEDNESDAY"] = "WEDNESDAY";
    Weekdays["THURSDAY"] = "THURSDAY";
    Weekdays["FRIDAY"] = "FRIDAY";
    Weekdays["SATURDAY"] = "SATURDAY";
    Weekdays["SUNDAY"] = "SUNDAY";
})(Weekdays || (Weekdays = {}));
