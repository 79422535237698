var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import AppliesTo from 'src/AdminApp/models/enums/AppliesTo';
import { getSkillsByFilter } from 'src/AdminApp/modules/skills/actions';
import { getTechnicianUser, signupAdminUser, updateAdminUser, uploadUserProfilePicture, } from 'src/AdminApp/modules/users/actions';
import { getDeliveryMetros } from 'src/PopsApp/modules/parts/actions';
import { selectAuthorizedUserEdit } from 'src/modules/auth/selectors';
import { UPDATE_PROFILE_PICTURE_ERROR, UPDATE_PROFILE_PICTURE_SUCCESS, USER_CREATE_ERROR, USER_CREATED_SUCCESSFULLY, USER_UPDATE_ERROR, USER_UPDATED_SUCCESSFULLY, } from 'src/AdminApp/constants/userMessages';
import { toast } from 'src/components/SimpleToast';
import * as Yup from 'yup';
import CONFIG from 'src/config';
import { AuthorizationGroups } from 'src/AdminApp/models/enums';
const useAddAndEditUser = ({ editUser, editMode, handleCloseModal, refreshList, }) => {
    const dispatch = useDispatch();
    const [resourceId, setResourceId] = useState('');
    const [skillOptions, setSkillOptions] = useState([]);
    const [metroOptions, setMetroOptions] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const hasUserEditorRole = useSelector(selectAuthorizedUserEdit);
    const authorizationGroupsKeys = Object.keys(AuthorizationGroups.values);
    const authorizationGroupsListItems = authorizationGroupsKeys.map((key) => ({
        id: key,
        value: AuthorizationGroups.values[key],
    }));
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const skillsResponse = yield dispatch(getSkillsByFilter({ applies_to: AppliesTo.TECHNICIAN }));
            setSkillOptions(skillsResponse.payload);
            const metrosResponse = yield dispatch(getDeliveryMetros(false));
            setMetroOptions(metrosResponse.payload);
        }))();
    }, [dispatch]);
    useEffect(() => {
        const fetchSkeduloResourceId = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if (((_a = editUser === null || editUser === void 0 ? void 0 : editUser.authGroups) === null || _a === void 0 ? void 0 : _a.includes('TECHNICIANS')) && (editUser === null || editUser === void 0 ? void 0 : editUser.id)) {
                const tech = yield dispatch(getTechnicianUser(editUser === null || editUser === void 0 ? void 0 : editUser.id));
                setResourceId((_b = tech === null || tech === void 0 ? void 0 : tech.payload) === null || _b === void 0 ? void 0 : _b.skeduloResourceId);
            }
        });
        fetchSkeduloResourceId();
    }, [dispatch, editUser]);
    const checkAllAuthGroups = (setFieldValue) => {
        setFieldValue('authGroups', [...authorizationGroupsKeys]);
    };
    const clearAllAuthGroups = (setFieldValue) => {
        setFieldValue('authGroups', []);
    };
    const onAuthorizationGroupCheck = (updatedGroup, authGroups, checked, setFieldValue) => {
        const updatedAuthGroups = new Set([...authGroups]);
        if (checked) {
            updatedAuthGroups.add(updatedGroup);
        }
        else if (!checked) {
            updatedAuthGroups.delete(updatedGroup);
            if (updatedGroup === 'TECHNICIANS') {
                setFieldValue('metro', null);
            }
        }
        setFieldValue('authGroups', Array.from(updatedAuthGroups));
    };
    const onProfilePictureSelected = (e, setFieldValue) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        e.preventDefault();
        const file = (_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0];
        const userId = editUser === null || editUser === void 0 ? void 0 : editUser.id;
        if (!userId || !file) {
            return;
        }
        const profilePicturePayload = {
            file,
            userId,
        };
        const profilePictureUpdateResponse = yield dispatch(uploadUserProfilePicture(profilePicturePayload));
        const { error, payload: { profilePicture }, } = profilePictureUpdateResponse;
        if (!error) {
            toast.success(UPDATE_PROFILE_PICTURE_SUCCESS);
        }
        else {
            toast.error(UPDATE_PROFILE_PICTURE_ERROR);
        }
        setFieldValue('profilePicture', profilePicture);
    });
    const getValidationSchema = () => {
        let emailValidation = Yup.string().email().required();
        if (CONFIG.REPAIRSMITH_EMAIL_VALIDATION === 'true') {
            emailValidation = Yup.string()
                .matches(/^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(repairsmith|autonation)\.com$/, 'Only @repairsmith.com or @autonation.com emails allowed')
                .required();
        }
        return Yup.object().shape({
            firstName: Yup.string()
                .min(2, 'Too Short!')
                .max(255, 'Too Long!')
                .required(),
            lastName: Yup.string()
                .min(2, 'Too Short!')
                .max(255, 'Too Long!')
                .required(),
            email: emailValidation,
            authGroups: Yup.array().min(1),
            metro: Yup.object().when('authGroups', (authGroups, schema) => {
                const isTechnicianSelected = authGroups === null || authGroups === void 0 ? void 0 : authGroups.includes('TECHNICIANS');
                return isTechnicianSelected
                    ? schema.shape({
                        name: Yup.string().required().min(2, 'Metro must be valid'),
                    })
                    : schema.shape({}).nullable();
            }),
        });
    };
    const handleClose = () => {
        handleCloseModal();
        setResourceId('');
    };
    const handleSubmit = (values, setSubmitting, resetForm) => __awaiter(void 0, void 0, void 0, function* () {
        const { firstName, lastName, email, skeduloResourceId, phoneNumber, password, skills, authGroups, metro, personalPromoCode, enabled, } = values;
        const techniciansChecked = authGroups.includes('TECHNICIANS');
        const requestBody = {
            firstName,
            lastName,
            email,
            authGroups,
            skeduloResourceId,
            skills,
            metro,
            personalPromoCode,
            enabled,
        };
        const requestResponse = (yield dispatch(editMode && (editUser === null || editUser === void 0 ? void 0 : editUser.id)
            ? updateAdminUser(Object.assign(Object.assign({}, requestBody), { id: editUser.id }), techniciansChecked)
            : signupAdminUser(Object.assign(Object.assign({}, requestBody), { phoneNumber, password: !password ? null : password }), techniciansChecked)));
        setSubmitting(false);
        if (!requestResponse.error) {
            toast.success(editMode ? USER_UPDATED_SUCCESSFULLY : USER_CREATED_SUCCESSFULLY);
            refreshList();
            resetForm();
            handleClose();
        }
        else {
            toast.error(editMode ? USER_UPDATE_ERROR : USER_CREATE_ERROR);
        }
    });
    return {
        dispatch,
        resourceId,
        setResourceId,
        skillOptions,
        setSkillOptions,
        metroOptions,
        setMetroOptions,
        showPassword,
        setShowPassword,
        checkAllAuthGroups,
        clearAllAuthGroups,
        onAuthorizationGroupCheck,
        onProfilePictureSelected,
        getValidationSchema,
        handleClose,
        hasUserEditorRole,
        handleSubmit,
        authorizationGroupsKeys,
        authorizationGroupsListItems,
    };
};
export default useAddAndEditUser;
