import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
import { Typography, FormControl, FormControlLabel, RadioGroup, Button, Grid, List, } from 'src/components/mui';
export const DrawerSubtitle = styled(Typography) `
  color: ${colors.darkGrey};
  font-size: 14px;
  font-weight: normal;
  margin-top: 10px;
`;
export const FooterWrapper = styled.div `
  div[class*='StandardButton__Wrapper'],
  button {
    width: 100%;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
  padding: 0 16px;

  ${theme.breakpoints.down('xs')} {
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;

    div[class*='StandardButton__Wrapper'],
    button {
      width: 100%;
    }
  }
`;
export const RadioGroupFormControl = styled(FormControl) `
  width: 100%;
`;
export const RadioGroupForm = styled(RadioGroup) `
  gap: 0px;
`;
export const RadioControlLabel = styled(FormControlLabel) `
  justify-content: space-between;
  margin-left: 0;
  padding: 10px;

  border-bottom: 1px solid lightGrey;
  width: 100%;
`;
export const AlternativesRadioLabel = styled(Typography) `
  font-weight: bold;
  font-size: 16px;
`;
export const IntroContent = styled.section `
  text-align: center;
`;
export const GuidePersonImg = styled.img `
  height: 200px;
  width: 200px;
  margin-top: 60px;
  position: relative;
  left: 20px;
`;
export const IntroHeader = styled.h2 `
  margin: 8px 0 0;
`;
export const IntroCopy = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${colors.GRAY_76};
  margin: 16px 0 0;
`;
export const ButtonList = styled.div `
  display: flex;
  flex-direction: column;

  button:not(:first-child) {
    margin-top: 16px;
  }
`;
export const ButtonCTA = styled(Button) `
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  width: 100%;

  div {
    margin-left: 8px;
  }
`;
export const CantFinishWrapperHeader = styled.h2 `
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Subheader = styled.p `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  margin: 6px 0 0;
`;
export const CantFinishCopy = styled.section `
  margin-top: 32px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
`;
export const ButtonLink = styled(Button) `
  color: ${colors.primary};
  &:hover {
    background-color: transparent;
  }
`;
export const IntroContentTodayReturn = styled.section `
  text-align: left;
  overflowy: scroll;
`;
export const IntroTodayReturnHeader = styled.h2 `
  margin: 8px 0 0;
  font-size: 1rem;
`;
export const IntroTodayReturnCopy = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  text-align: left;
  color: ${colors.GRAY_76};
  margin: 16px 0 0;
`;
export const RepairItemStyled = styled(Grid) `
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
`;
export const ListEditReturn = styled(List) `
  border: 1px solid ${colors.lightGrey};
  width: 100%;
  border-radius: 5px;
`;
