import React from 'react';
import { useDispatch } from 'react-redux';
import { searchFMCRepairs } from 'src/AdminApp/modules/requests/actions';
import SimpleAutosuggest from '../SimpleAutosuggest';
const FmcRepairSearchSelect = ({ onSelect }) => {
    const dispatch = useDispatch();
    const handleSearch = (value) => {
        var _a, _b;
        const response = dispatch(searchFMCRepairs(value, 'AUTO_INTEGRATE'));
        const items = ((_b = (_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : []).map((repair) => ({
            label: repair.fmcDisplayName,
            fmcId: repair.fmcId,
        }));
        return items;
    };
    return (<SimpleAutosuggest label="AutoIntegrate Repairs" placeholder="Search AI Repairs..." fieldName="label" onSearch={handleSearch} onSelect={(suggestion) => {
            onSelect(suggestion);
        }}/>);
};
export default FmcRepairSearchSelect;
