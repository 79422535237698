import React from 'react';
import styled from 'styled-components';
import { AddCircle as AddCircleIcon } from 'src/components/mui/icons';
import { Tooltip } from 'src/components/tooltips';
import { colors } from 'src/styles/theme';
const StyledText = styled.span `
  font-size: 1.25rem;
  font-weight: 500;
  color: ${(props) => { var _a; return colors[(_a = props.color) !== null && _a !== void 0 ? _a : '']; }};
  pointer-events: none;
`;
const Wrapper = styled.div `
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledAddCircleIcon = styled(AddCircleIcon) `
  color: ${(props) => { var _a; return colors[(_a = props.color) !== null && _a !== void 0 ? _a : '']; }};
  margin-right: 5px;
  pointer-events: none;
`;
const AddButton = ({ text, color, onClick, disabled = false, disabledTooltip, style, }) => {
    const ctaColor = disabled ? 'grey' : color;
    const content = (<Wrapper disabled={disabled} onClick={disabled ? () => { } : onClick} style={style}>
      <StyledAddCircleIcon color={ctaColor}/>
      <StyledText color={ctaColor}>{text}</StyledText>
    </Wrapper>);
    return disabled ? (<Tooltip title={disabledTooltip !== null && disabledTooltip !== void 0 ? disabledTooltip : ''} style={style}>
      {content}
    </Tooltip>) : (content);
};
export default AddButton;
