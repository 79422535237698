import React from 'react';
import { Box } from 'src/components/mui';
import { PrimaryButton } from 'src/components/buttons';
import useAppointmentResolutions from 'src/SystemApp/pages/appointmentResolutions/useAppointmentResolutions';
import ResolutionModal from './ResolutionModal';
import Resolutions from './Resolutions';
const AppointmentResolutions = () => {
    const { onResolutionCreate, resolutionModalOpen, isNewResolution, setResolutionModalOpen, onCreateResolutionSubmit, onUpdateResolutionSubmit, currentResolution, resolutions, onResolutionEdit, } = useAppointmentResolutions();
    return (<>
      <Box m={1} display="flex" style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <PrimaryButton style={{ marginRight: '10px' }} onClick={onResolutionCreate}>
          Add New Appointment Resolution
        </PrimaryButton>
      </Box>
      <ResolutionModal open={resolutionModalOpen} adding={isNewResolution} onClose={() => setResolutionModalOpen(false)} onSubmit={isNewResolution ? onCreateResolutionSubmit : onUpdateResolutionSubmit} {...currentResolution}/>

      <Resolutions resolutions={resolutions} onEdit={onResolutionEdit}/>
    </>);
};
export default AppointmentResolutions;
