import { useDispatch, useSelector } from 'react-redux';
import { archiveLaborRates, } from 'src/AdminApp/modules/geoManagement/actions';
import { selectMetroArchivedLaborRates, selectRegionArchivedLaborRates, } from 'src/AdminApp/modules/geoManagement/selectors';
const useArchivedLaborRatesListPage = ({ metro, setSlide, }) => {
    const dispatch = useDispatch();
    const regionRates = useSelector(selectRegionArchivedLaborRates);
    const metroRates = useSelector(selectMetroArchivedLaborRates);
    const laborRates = !metro ? regionRates : metroRates;
    const handleArchiveClick = (id) => () => {
        dispatch(archiveLaborRates({
            id,
            metro,
        }));
        if (setSlide) {
            setSlide(!metro ? 'archivedLaborRates' : 'archivedMetroLaborRates');
        }
    };
    return {
        laborRates,
        handleArchiveClick,
    };
};
export default useArchivedLaborRatesListPage;
