import React from 'react';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import NotAuthorized from 'src/components/layout/NotAuthorized';
import CONFIG from 'src/config';
import LocationHoursOfOperationDrawer from 'src/PopsApp/pages/Distributors/LocationHoursOfOperationDrawer';
import useLocationsDashboard from 'src/PopsApp/pages/Distributors/LocationsDashboard/useLocationsDashboard';
import AddEditModal from './AddEditModal';
import LocationFilters from './Filters';
import LocationDashboardItem from './LocationDashboardItem';
const BASE_REQUEST_COLUMNS = [
    { title: 'Address', key: 'street1', sortable: true },
    { title: 'Dealership Name', key: 'dealershipName', sortable: true },
    { title: 'Email', key: 'email', sortable: true },
    { title: 'Status', key: 'status', sortable: true },
    { title: 'Epicor Coverage Key', key: 'epicorCoverageKey', sortable: false },
    { title: 'Edit', key: 'edit', sortable: false },
    { title: 'Hours of Operation', key: 'hours_of_operation', sortable: false },
    { title: 'Delivery options', key: 'delivery_options', sortable: false },
];
const COURIER_DELIVERY_DISTRIBUTOR_COLUMNS = [
    {
        title: 'Courier Deliveries?',
        key: 'canReceiveCourierDeliveries',
        sortable: true,
    },
];
const LocationsDashboard = () => {
    const { isPartsDistributorEditor, distributorName, onSearch, onLocationAdd, isEditing, addEditModalOpen, setAddEditModalOpen, params, selectedLocation, onLocationUpdate, onLocationSave, locations, pagination, onLocationEdit, onHoursEdit, onLoadData, hoursDrawerOpen, closeHoursDrawer, } = useLocationsDashboard();
    if (!isPartsDistributorEditor) {
        return <NotAuthorized targetRole="Part_Distributor_Editor"/>;
    }
    return (<>
      <h2>{distributorName}</h2>
      <LocationFilters onSearch={onSearch} onAdd={onLocationAdd}/>
      <br />
      <AddEditModal isEdit={isEditing} open={addEditModalOpen} onClose={() => setAddEditModalOpen(false)} partDistributorId={params === null || params === void 0 ? void 0 : params.distributorId} location={selectedLocation} partDistributorName={distributorName} onSubmit={isEditing ? onLocationUpdate : onLocationSave}/>
      <SimpleTable data={locations} pagination={pagination} sort={pagination.sort} columns={[
            ...BASE_REQUEST_COLUMNS,
            ...(CONFIG.POPS_COURIER_DELIVERY_DISTRIBUTORS.split(',').includes(distributorName)
                ? COURIER_DELIVERY_DISTRIBUTOR_COLUMNS
                : []),
        ]} renderItem={(item, index) => (<LocationDashboardItem location={item} key={index} onEdit={() => {
                onLocationEdit(item);
            }} onSave={onLocationUpdate} onHoursEdit={onHoursEdit}/>)} onLoadData={onLoadData}/>
      <LocationHoursOfOperationDrawer open={hoursDrawerOpen} onClose={closeHoursDrawer} location={selectedLocation}/>
    </>);
};
export default LocationsDashboard;
