import { CircularProgress } from 'src/components/mui';
import { QRCodeCanvas } from 'qrcode.react';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { QRWrapper, SectionInfo, SectionInfoText, SectionLink, SectionMain, SectionText, } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/Screens/AffirmCheckout/AffirmCheckout.styles';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import useAffirmCheckout from './useAffirmCheckout';
export const AFFIRM_ERROR_MESSAGE = 'No Affirm payment method was found. Make sure the customer completes the checkout process, then try again';
const AffirmCheckout = ({ nextStep, goToViewCC, requestAuthorization, onFail, }) => {
    const { linkText, infoCopy, isLoading, planName, handleSendMail, handleCheckForPayment, } = useAffirmCheckout({ nextStep, requestAuthorization, onFail });
    return (<>
      <SimpleDrawerContent>
        <SectionMain>
          {isLoading ? (<CircularProgress />) : (<>
              <SectionText>Scan QR Code for Affirm checkout</SectionText>
              <QRWrapper>
                <QRCodeCanvas value={linkText} size={170}/>
              </QRWrapper>
              <SectionText>OR</SectionText>
              <SectionLink type="button" onClick={handleSendMail}>
                Send email to customer
              </SectionLink>
            </>)}
        </SectionMain>

        <SectionInfo>
          <SectionInfoText>
            <ReactMarkdown>{infoCopy}</ReactMarkdown>
          </SectionInfoText>
        </SectionInfo>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={handleCheckForPayment}>
          Check for payment
        </FooterActionButton>

        {!planName && (<FooterActionButton onClick={goToViewCC}>
            Back to Alternative Payment
          </FooterActionButton>)}
      </SimpleDrawerFooter>
    </>);
};
export default AffirmCheckout;
