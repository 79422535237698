/* eslint-disable func-names */
import * as Yup from 'yup';
import { addContextValidation } from 'src/PricingApp/utils/validations';
export const editRepairComponentValidationSchema = (values) => {
    return addContextValidation(values, EditRepairComponentValidationSchema);
};
const EditRepairComponentValidationSchema = Yup.object().shape({
    position: Yup.object()
        .nullable()
        .test('required for PART component', 'required for PART component', function (position) {
        var _a, _b;
        const isPart = ((_b = (_a = this.options.context) === null || _a === void 0 ? void 0 : _a.component) === null || _b === void 0 ? void 0 : _b.type) === 'PART';
        return !isPart || (position === null || position === void 0 ? void 0 : position.id);
    }),
    defaultMinUnitPrice: Yup.number()
        .nullable()
        .min(0, 'must be >= 0')
        .test('min & max must come in pair', 'min & max must come in pair', function () {
        return ((this.parent.defaultMinUnitPrice != null) ===
            (this.parent.defaultMaxUnitPrice != null));
    }),
    defaultMaxUnitPrice: Yup.number()
        .nullable()
        .min(0, 'must be >= 0')
        .test('min & max must come in pair', 'min & max must come in pair', function () {
        return ((this.parent.defaultMinUnitPrice != null) ===
            (this.parent.defaultMaxUnitPrice != null));
    })
        .test('max<min unit price', 'max must be >= min unit price', function () {
        return ((this.parent.defaultMinUnitPrice == null &&
            this.parent.defaultMaxUnitPrice == null) ||
            this.parent.defaultMinUnitPrice <= this.parent.defaultMaxUnitPrice);
    }),
    defaultMinQuantity: Yup.number()
        .nullable()
        .min(0, 'must be >= 0')
        .test('min & max must come in pair', 'min & max must come in pair', function () {
        return ((this.parent.defaultMinQuantity != null) ===
            (this.parent.defaultMaxQuantity != null));
    }),
    defaultMaxQuantity: Yup.number()
        .nullable()
        .min(0, 'must be >= 0')
        .test('min & max must come in pair', 'min & max must come in pair', function () {
        return ((this.parent.defaultMinQuantity != null) ===
            (this.parent.defaultMaxQuantity != null));
    })
        .test('max<min quantity', 'max must be >= min quantity', function () {
        return ((this.parent.defaultMinQuantity == null &&
            this.parent.defaultMaxQuantity == null) ||
            this.parent.defaultMinQuantity <= this.parent.defaultMaxQuantity);
    }),
    overrideUnitPrice: Yup.number().nullable().min(0, 'must be >= 0'),
    overrideQuantity: Yup.number().nullable().min(0, 'must be >= 0'),
});
