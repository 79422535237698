import React from 'react';
import { PaymentDescription, PaymentStatus } from 'src/AdminApp/models/enums';
import SimpleChip from 'src/components/SimpleChip';
import { colors } from 'src/styles/theme';
const descriptionColor = (type) => {
    switch (PaymentDescription[type]) {
        case PaymentDescription.FULL_PAYMENT:
        case PaymentDescription.FULL_PAYMENT_BY_SPREEDLY:
            return colors.success;
        case PaymentDescription.PARTIAL_PAYMENT:
        case PaymentDescription.PARTIAL_PAYMENT_BY_SPREEDLY:
        case PaymentDescription.CANCELLATION_FEE:
        case PaymentDescription.PRE_AUTHORIZATION:
        case PaymentDescription.DEPOSIT:
            return colors.linkBlue;
        case PaymentDescription.PARTIAL_REFUND:
            return colors.brandPink;
        case PaymentDescription.FULL_REFUND:
            return colors.brandPink;
        case 'VOID':
        default:
            return colors.darkGrey;
    }
};
const PaymentDescriptionChip = ({ removed, description, status, }) => {
    let text = PaymentDescription.values[description];
    let color = descriptionColor(description);
    if (removed || (description === null || description === void 0 ? void 0 : description.includes('VOID'))) {
        text = 'VOID';
        color = colors.darkGrey;
    }
    if ([
        PaymentStatus.FAILED,
        PaymentStatus.DECLINED,
        PaymentStatus.UNKNOWN,
    ].includes(status) &&
        !(description === null || description === void 0 ? void 0 : description.includes('PRE_AUTHORIZATION'))) {
        text = PaymentStatus.values[status];
        color = colors.error;
    }
    if (PaymentStatus.REFUNDED === status) {
        text = PaymentStatus.values[status];
        color = colors.darkGrey;
    }
    if (!description ||
        ((description === null || description === void 0 ? void 0 : description.includes('PRE_AUTHORIZATION')) &&
            status === PaymentStatus.FAILED)) {
        color = colors.error;
    }
    if ((description === null || description === void 0 ? void 0 : description.includes('PRE_AUTHORIZATION')) &&
        status === PaymentStatus.SUCCEEDED) {
        color = colors.success;
    }
    return (<SimpleChip $textColor={colors.white} $bgColor={color} label={text} className="PaymentDescriptionChip"/>);
};
export default PaymentDescriptionChip;
