import React from 'react';
import { sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { selectQueuePosition, selectRequestPartPickups, } from 'src/AdminApp/modules/requests/selectors';
import { fetchOrderRosterJobQueuePosition } from 'src/AdminApp/modules/requests/actions';
import { SubItem } from 'src/AdminApp/components/repairs/statement/styledComponents';
import { Part, Fluid, Labor, } from 'src/AdminApp/components/repairs/statement/Elements';
import { shouldDisplayLabor } from './utils';
const Item = ({ price, onEditLabor, onEditPart, onEditFluid, canEdit = false, partial = false, canEditLabor = false, onOpenAdditionalInfoPart, onOpenAdditionalInfoFluid, }) => {
    const dispatch = useDispatch();
    const { selectorProps, referenceNum } = usePageReferenceNum();
    const jobPriority = useSelector((state) => selectQueuePosition(state, selectorProps));
    const requestPartPickups = useSelector(selectRequestPartPickups);
    const getJobPriority = () => {
        if (referenceNum) {
            dispatch(fetchOrderRosterJobQueuePosition(referenceNum));
        }
    };
    return (<div>
      {price &&
            price.parts &&
            price.parts.length > 0 &&
            sortBy(price.parts, 'name').map((part, index) => {
                return (<SubItem key={index}>
              <Part jobPriority={jobPriority} canEdit={canEdit} onEdit={onEditPart} key={index} status={part.status} {...{ part, partial }} getJobPriority={getJobPriority} displayTooltip requestPartPickups={requestPartPickups} onOpenAdditionalInfoPart={onOpenAdditionalInfoPart}/>
            </SubItem>);
            })}
      {price &&
            price.fluids &&
            price.fluids.length > 0 &&
            sortBy(price.fluids, 'name').map((fluid, index) => {
                return (<SubItem key={index}>
              <Fluid jobPriority={jobPriority} canEdit={canEdit} onEdit={onEditFluid !== null && onEditFluid !== void 0 ? onEditFluid : (() => { })} key={index} status={fluid.status} {...{ fluid, partial }} getJobPriority={getJobPriority} displayTooltip requestPartPickups={requestPartPickups} onOpenAdditionalInfoFluid={onOpenAdditionalInfoFluid}/>
            </SubItem>);
            })}
      {!partial && shouldDisplayLabor(price) && (<SubItem>
          <Labor canEdit={canEditLabor} price={price} onEdit={onEditLabor}/>
        </SubItem>)}
    </div>);
};
export default Item;
