import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/mui';
import { DeleteForever } from 'src/components/mui/icons';
import { SimpleModal } from 'src/components/layout';
import AuditInfo from 'src/AdminApp/components/users/AuditInfo';
import { isAdmin } from 'src/AdminApp/components/users/utils';
import { colors } from 'src/styles/theme';
import { RepairDropdown } from '../../components/RepairDropdown';
import DiagnosisItem from './Item';
const InfoWrapper = styled.div `
  padding: 20px 0;
`;
const RemoveButton = styled(Button) `
  color: ${colors.error};
  margin-top: 8px;
`;
const Diagnoses = ({ repairs, onRemove, invoice, displayAuditChip = false, canEdit = true, }) => {
    const [removeDialogState, setRemoveDialogState] = useState({
        open: false,
        repairId: null,
    });
    return (<>
      {repairs &&
            repairs.map((repair) => {
                const { id, inspectionFee, name, price } = repair;
                const isCreatedByCustomer = !isAdmin(repair.createdBy);
                return (<div key={`diagnoses-${id}`}>
              <RepairDropdown name={name} quoted="" invoice={invoice} diagnosisId={id} shouldDisplayAuditChip={displayAuditChip} totalCost={inspectionFee} isCreatedByCustomer={isCreatedByCustomer} created={repair.created} createdBy={repair.createdBy} modified={repair.modified} modifiedBy={repair.modifiedBy} isEditable={canEdit}>
                <DiagnosisItem price={price}/>

                <InfoWrapper>
                  <AuditInfo auditType="ADDED" auditedTimestamp={repair.created} auditedBy={repair.createdBy}/>
                  <AuditInfo auditType="MODIFIED" auditedTimestamp={repair.modified} auditedBy={repair.modifiedBy}/>
                </InfoWrapper>

                {onRemove && (<RemoveButton disabled={!canEdit} startIcon={<DeleteForever />} onClick={() => setRemoveDialogState({ open: true, repairId: id })}>
                    Remove
                  </RemoveButton>)}
              </RepairDropdown>
            </div>);
            })}
      <SimpleModal title="Remove Diagnose" open={removeDialogState.open} onClose={() => setRemoveDialogState({ open: false, repairId: null })} simpleTwoButtonRowProps={{
            onCancel: () => setRemoveDialogState({ open: false, repairId: null }),
            onSubmit: () => {
                if (onRemove) {
                    onRemove({ repairId: removeDialogState.repairId });
                }
                setRemoveDialogState({ open: false, repairId: null });
            },
            submitText: 'Confirm',
        }}>
        Are you sure you want to remove diagnose?
      </SimpleModal>
    </>);
};
export default Diagnoses;
