var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import createDefaultActionCreators from 'src/utils/createActions';
export const ActionCreators = {
    ANALYTICS_IDENTIFY_EVENT: createDefaultActionCreators('ANALYTICS_IDENTIFY_EVENT'),
    ANALYTICS_TRACK_EVENT: createDefaultActionCreators('ANALYTICS_TRACK_EVENT'),
};
export const analyticsTrackEvent = (eventName, payload = null) => {
    const analyticsReady = Boolean(window.analytics);
    if (analyticsReady) {
        window.analytics.track(eventName, payload);
    }
    return {
        type: ActionCreators.ANALYTICS_TRACK_EVENT.SUCCESS.type,
        data: {
            analyticsReady,
            eventName,
            payload,
        },
    };
};
export const analyticsIdentifyEvent = (userId, payload = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const analyticsReady = Boolean(window.analytics);
    if (analyticsReady) {
        const result = yield window.analytics.identify(userId, Object.assign({}, payload), {
            integrations: {
                Salesforce: true,
            },
        });
        return {
            type: ActionCreators.ANALYTICS_IDENTIFY_EVENT.SUCCESS.type,
            data: {
                analyticsReady,
                payload: Object.assign(Object.assign({ userId }, payload), { result }),
            },
        };
    }
    return {
        type: ActionCreators.ANALYTICS_IDENTIFY_EVENT.SUCCESS.type,
        data: {
            analyticsReady,
            payload,
        },
    };
});
