import { STATIC_URL_BASE } from 'src/styles/theme';
export const cardSvgByType = (cardType) => {
    switch (cardType) {
        case 'american_express':
            return `${STATIC_URL_BASE}payment/Amex.svg`;
        case 'diners_club':
            return `${STATIC_URL_BASE}payment/Diners Club.svg`;
        case 'discover':
            return `${STATIC_URL_BASE}payment/Discover.svg`;
        case 'jcb':
            return `${STATIC_URL_BASE}payment/JCB.svg`;
        case 'mastercard':
        case 'master':
            return `${STATIC_URL_BASE}payment/Mastercard.svg`;
        case 'visa':
            return `${STATIC_URL_BASE}payment/Visa.svg`;
        default:
            return `${STATIC_URL_BASE}payment/card.svg`;
    }
};
