/* eslint-disable react/no-unused-prop-types */
import { AttachMoneyRounded as AttachMoneyRoundedIcon } from '@mui/icons-material';
import { Formik } from 'formik';
import React from 'react';
import { HorizontalBorder, RepairName, SectionHeader, } from 'src/AdminApp/containers/fleets/tabs/pricing/NewRuleDrawer/NewRuleDrawer.styles';
import { Grid, InputAdornment, TextField, Typography, } from 'src/components/mui';
import { DrawerFormRow, FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
import useNewRuleDrawer from './useNewRuleDrawer';
const validationSchema = Yup.array().of(Yup.object().shape({
    quantity: Yup.number()
        .moreThan(0, 'Quantity must be greater than 0')
        .max(25, 'Quantity must be equal or less than 25')
        .nullable(),
    price: Yup.number()
        .moreThan(0, 'Price must be greater than 0')
        .max(1000, 'Price must be equal or less than 1000')
        .nullable(),
}));
const NewRuleDrawer = ({ open, onSubmit, onClose, repairId, repairName, fleetId, }) => {
    const { components, conciergeRules, submitNewRule, getTotalCost } = useNewRuleDrawer({
        fleetId,
        repairId,
        repairName,
        onSubmit,
        onClose,
    });
    return (<SimpleDrawer open={open} onClose={onClose} title="Add a Repair">
      <Formik enableReinitialize validationSchema={validationSchema} onSubmit={submitNewRule} initialValues={components}>
        {({ values, submitForm, isSubmitting, setFieldValue, errors, isValid, touched, }) => {
            var _a, _b, _c, _d;
            return (<>
            <SimpleDrawerContent>
              {components && (<Grid container>
                  <DrawerFormRow item xs={12}>
                    <RepairName>
                      <div>{repairName}</div>
                      <div>Running Total: ${getTotalCost(values)}</div>
                    </RepairName>
                    <RepairName>
                      <Typography variant="subtitle1">
                        Max Price: $ {(_a = conciergeRules === null || conciergeRules === void 0 ? void 0 : conciergeRules.maxPrice) !== null && _a !== void 0 ? _a : 10000}
                      </Typography>
                      <Typography variant="subtitle1">
                        Min Price: $ {(_b = conciergeRules === null || conciergeRules === void 0 ? void 0 : conciergeRules.minPrice) !== null && _b !== void 0 ? _b : 0}
                      </Typography>
                    </RepairName>
                  </DrawerFormRow>
                  <HorizontalBorder />
                  {(components === null || components === void 0 ? void 0 : components.length) > 0 ? (values.map((component, index) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        return (<section style={{ width: '100%' }} key={index}>
                          <SectionHeader>
                            {(_a = component === null || component === void 0 ? void 0 : component.component) === null || _a === void 0 ? void 0 : _a.name}
                          </SectionHeader>
                          <DrawerFormRow item xs={12}>
                            <TextField variant="outlined" fullWidth label="Quantity" name={`[${index}].quantity`} type="number" value={(_b = values[index].quantity) !== null && _b !== void 0 ? _b : ''} onChange={(event) => setFieldValue(`[${index}].quantity`, isNaN(event.target.valueAsNumber)
                                ? null
                                : event.target.valueAsNumber)} helperText={((_c = errors[index]) === null || _c === void 0 ? void 0 : _c.quantity) &&
                                errors[index].quantity} error={Boolean((_d = errors[index]) === null || _d === void 0 ? void 0 : _d.quantity)}/>
                          </DrawerFormRow>
                          <DrawerFormRow item xs={12}>
                            <TextField variant="outlined" fullWidth label="Price" name={`${index}.price`} type="number" value={(_e = values[index].price) !== null && _e !== void 0 ? _e : ''} onChange={(event) => setFieldValue(`[${index}].price`, isNaN(event.target.valueAsNumber)
                                ? null
                                : event.target.valueAsNumber)} helperText={((_f = errors[index]) === null || _f === void 0 ? void 0 : _f.price) && ((_g = errors[index]) === null || _g === void 0 ? void 0 : _g.price)} error={Boolean((_h = errors[index]) === null || _h === void 0 ? void 0 : _h.price)} InputProps={{
                                startAdornment: (<InputAdornment position="start">
                                    <AttachMoneyRoundedIcon />
                                  </InputAdornment>),
                            }}/>
                          </DrawerFormRow>
                        </section>);
                    })) : (<span>Sorry! This repair doesn't have any components.</span>)}
                </Grid>)}
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <FooterActionButton type="submit" isSubmitting={isSubmitting} disabled={!isValid ||
                    isSubmitting ||
                    !touched ||
                    (components === null || components === void 0 ? void 0 : components.length) === 0 ||
                    ((_c = getTotalCost(values) > (conciergeRules === null || conciergeRules === void 0 ? void 0 : conciergeRules.maxPrice)) !== null && _c !== void 0 ? _c : 10000) ||
                    ((_d = getTotalCost(values) < (conciergeRules === null || conciergeRules === void 0 ? void 0 : conciergeRules.minPrice)) !== null && _d !== void 0 ? _d : 0) ||
                    (values === null || values === void 0 ? void 0 : values.filter((v) => (v.quantity && !v.price) || (!v.quantity && v.price)).length) > 0} onClick={submitForm}>
                Submit
              </FooterActionButton>
            </SimpleDrawerFooter>
          </>);
        }}
      </Formik>
    </SimpleDrawer>);
};
export default NewRuleDrawer;
