import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { ArrowBack } from 'src/components/mui/icons';
export const BreadcrumbList = styled.ul `
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: ${colors.GRAY_3F};
  font-weight: 500;

  a,
  a:visited {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    font-weight: 500;
    color: ${colors.primary};
  }

  li {
    list-style: none;
    display: inline;
  }

  li + li:before {
    content: '/';
    margin: 0 8px;
  }
`;
export const BackButtonIcon = styled(ArrowBack) `
  color: ${colors.black};
`;
export const VisitLock = styled.div `
  display: inline;

  > div {
    display: inline;
    position: relative;
    top: 6px;
    margin-left: 10px;
  }
`;
