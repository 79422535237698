import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Grid } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Container, Content, Title } from './LunchBreaksRoot.styles';
const BigButton = styled(Button) `
  height: 100%;
`;
const LunchBreaksRoot = () => {
    const history = useHistory();
    return (<Container>
      <Title>Lunch Break</Title>
      <Content>
        <Formik initialValues={{
            lunchBreakId: '',
        }} onSubmit={(data) => {
            history.replace(`lunch-breaks/${data.lunchBreakId}`);
        }}>
          {({ isSubmitting }) => (<Form>
              <Grid container spacing={2}>
                <Grid item xs={10} md={10}>
                  <TextField placeholder="Lunch Break Id" label="Search Query" name="lunchBreakId" variant="outlined" autoFocus fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <BigButton size="small" variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting}>
                    Search
                  </BigButton>
                </Grid>
              </Grid>
            </Form>)}
        </Formik>
      </Content>
    </Container>);
};
export default LunchBreaksRoot;
