var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Button, Grid } from 'src/components/mui';
import { TextField, FormPanel } from 'src/AdminApp/components/form';
import { generateNewMfaCode } from 'src/AdminApp/modules/mfa/actions';
const GenerateMfaForm = () => {
    const dispatch = useDispatch();
    const defaultMessage = 'Your generated code will be displayed here';
    const [mfaCode, setMfaCode] = useState(defaultMessage);
    const fetchNewMfaCode = (email) => __awaiter(void 0, void 0, void 0, function* () {
        const fetchResult = yield dispatch(generateNewMfaCode(email));
        const mfaCode = fetchResult.payload.code
            ? fetchResult.payload.code
            : defaultMessage;
        setMfaCode(mfaCode);
    });
    const handleDisableCopyPaste = (e) => {
        e.preventDefault();
    };
    return (<Formik initialValues={{
            email: '',
            mfaCode: '',
        }} onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            fetchNewMfaCode(values.email);
        }}>
      {({ isSubmitting }) => (<FormPanel ariaName="generate-mfa-code-form" title="Generate New MFA Verification Code">
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField id="mfa-email" name="email" type="email" label="Email" variant="standard"/>
              </Grid>
              <Grid item xs={12}>
                <TextField name="mfaCode" type="mfaCode" label="" value={mfaCode} disabled variant="standard" onCut={handleDisableCopyPaste} onCopy={handleDisableCopyPaste} onPaste={handleDisableCopyPaste}/>
              </Grid>
              <Grid item xs={12} container style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Generate
                </Button>
              </Grid>
            </Grid>
          </Form>
        </FormPanel>)}
    </Formik>);
};
export default GenerateMfaForm;
