import { createSelector } from 'reselect';
const selectSwimlane = (state) => state.admin.swimlanes;
export const selectJobsByRegion = createSelector(selectSwimlane, (swimlanes) => { var _a; return (_a = swimlanes.jobsByRegion) !== null && _a !== void 0 ? _a : []; });
export const selectSelectedJobs = createSelector(selectSwimlane, (swimlanes) => { var _a; return (_a = swimlanes.selectedJobs) !== null && _a !== void 0 ? _a : []; });
export const selectSelectedDate = createSelector(selectSwimlane, (swimlanes) => swimlanes.selectedDate);
export const selectTerritories = createSelector(selectSwimlane, (swimlanes) => { var _a; return (_a = swimlanes.territories) !== null && _a !== void 0 ? _a : []; });
export const selectUpdatingJobId = createSelector(selectSwimlane, (swimlanes) => swimlanes.updatingJobId);
export const selectWorkingVansOnly = createSelector(selectSwimlane, (swimlanes) => swimlanes.workingVansOnly);
export const selectSelectedRegions = createSelector(selectSwimlane, (swimlanes) => { var _a; return (_a = swimlanes.selectedRegions) !== null && _a !== void 0 ? _a : []; });
export const selectJobCancellationStatus = createSelector(selectSwimlane, (swimlanes) => swimlanes.jobCancellationStatus);
export const selectOnDragEnd = createSelector(selectSwimlane, (swimlanes) => swimlanes.onDragEnd);
export const selectSelectedJobAnchorEl = createSelector(selectSwimlane, (swimlanes) => swimlanes.selectedJobAnchorEl);
export const selectIsJobInfoLoading = createSelector(selectSwimlane, (swimlanes) => swimlanes.isJobInfoLoading);
export const selectLockDrag = createSelector(selectSwimlane, (swimlanes) => swimlanes.lockDrag);
