import React from 'react';
const OutTable = ({ data }) => {
    return (data === null || data === void 0 ? void 0 : data.length) ? (<div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th key="index">Row#</th>
            {data[0].map((column, index) => (<th key={column + index}>{column}</th>))}
          </tr>
        </thead>
        <tbody>
          {data.slice(1).map((row, rowIndex) => (<tr key={row + rowIndex}>
              <td key={row + rowIndex}>{rowIndex + 1}</td>
              {data[0].map((column, columnIndex) => (<td style={{ color: row.includes('N/A') ? 'red' : 'initial' }} key={row + column + columnIndex}>
                  {row[columnIndex]}
                </td>))}
            </tr>))}
        </tbody>
      </table>
    </div>) : null;
};
export default OutTable;
