var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import Module from 'src/components/layout/Module';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { Grid } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
import * as Yup from 'yup';
import InvoiceItem from './InvoiceItem';
import useFleetInvoices, { MAX_DOWNLOAD_ITEMS } from './useFleetInvoices';
import { Wrapper, GridTitle, BigButton } from './FleetInvoices.styles';
import LoadingModal from 'src/AdminApp/pages/invoiceWorkbench/components/LoadingModal';
const validationSchema = Yup.object().shape({
    query: Yup.number().required('Number required'),
});
const FleetInvoices = () => {
    const { invoices, pagination, fetching, params, onLoadData, selectedItems, isBatchDownloading, isInvoiceDownloading, setIsInvoiceDownloading, handleSelect, batchDownloadInvoices, handleCheckAllSelected, handleSelectAll, handleClearSelected, } = useFleetInvoices();
    const FLEET_INVOICES_COLUMNS = [
        {
            title: '',
            key: 'checked',
            sortable: false,
            type: 'checkbox',
            action: handleSelectAll,
            checked: handleCheckAllSelected,
            checkDisabled: fetching || isBatchDownloading,
        },
        { title: 'Reference Number', key: 'referenceNum', sortable: true },
        { title: 'Invoice Number', key: 'invoiceNum', sortable: true },
        { title: 'Invoice Created Date', key: 'created', sortable: true },
        { title: 'Invoice Sent Date', key: 'lastInvoiceSentAt', sortable: true },
        { title: 'Recipients', key: 'recipients', sortable: false },
        { title: 'Responsible Party', key: 'responsibleParty', sortable: false },
        { title: 'Appointment Date', key: 'appointmentDate', sortable: true },
        { title: 'Vehicle', key: 'vehicle', sortable: false },
        { title: 'Services', key: 'services', sortable: false },
    ];
    return (<Module title="Invoices" actionButton={<GridTitle container>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {selectedItems.length} Selected{' '}
              {selectedItems.length >= MAX_DOWNLOAD_ITEMS ? ' (Max)' : ''}
            </div>
            <BigButton variant="contained" disabled={!selectedItems.length || isBatchDownloading} onClick={batchDownloadInvoices} style={{ marginLeft: 10, marginRight: 24, width: 180 }}>
              Download Selected
            </BigButton>
          </Grid>
          <Formik enableReinitialize initialValues={{ query: '' }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
                yield onLoadData(Object.assign(Object.assign({}, params), { query: values.query, page: 0 }));
                handleClearSelected();
                setSubmitting(false);
            })}>
            {({ values, isSubmitting, setFieldValue, setSubmitting, isValid, }) => {
                return (<Form>
                  <Grid container>
                    <Grid item>
                      <TextField name="query" placeholder="Invoice Number" label="Invoice Number" value={values.query} variant="outlined" autoFocus fullWidth/>
                    </Grid>
                    <Grid item>
                      <BigButton size="small" variant="contained" fullWidth disabled={isSubmitting || fetching} onClick={() => __awaiter(void 0, void 0, void 0, function* () {
                        setFieldValue('query', '');
                        yield onLoadData(Object.assign(Object.assign({}, params), { query: '' }));
                        setSubmitting(false);
                    })}>
                        Reset
                      </BigButton>
                      <BigButton size="small" variant="contained" color="primary" fullWidth type="submit" disabled={!isValid ||
                        isSubmitting ||
                        isEmpty(values.query) ||
                        fetching}>
                        Search
                      </BigButton>
                    </Grid>
                  </Grid>
                </Form>);
            }}
          </Formik>
        </GridTitle>}>
      {(invoices === null || invoices === void 0 ? void 0 : invoices.length) || fetching ? (<SimpleTable columns={FLEET_INVOICES_COLUMNS} isFetching={fetching} data={invoices} pagination={pagination} sort={(pagination === null || pagination === void 0 ? void 0 : pagination.sort) || ['appointmentDate', 'desc']} hideTopPagination renderItem={(item) => (<InvoiceItem data={item} checked={!!selectedItems.find((s) => s.invoiceNum === item.invoiceNum)} checkDisabled={isBatchDownloading || isInvoiceDownloading || fetching} onSelect={handleSelect} setLoading={setIsInvoiceDownloading}/>)} onLoadData={onLoadData}/>) : (<Wrapper>No Invoices</Wrapper>)}

      {(isBatchDownloading || isInvoiceDownloading) && (<LoadingModal open title={`Downloading Invoice${isBatchDownloading ? 's' : ''}...`}/>)}
    </Module>);
};
export default FleetInvoices;
