var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'src/components/mui';
import { reindexConsumerSearch, reindexFleetSearch, } from 'src/SystemApp/modules/utilities/actions';
import SearchIndexType from './SearchIndexType';
const ReindexSearchButton = ({ immediateRefresh, deleteExisting, indexType, color = 'primary', size, }) => {
    const dispatch = useDispatch();
    const [reindexDisabled, setReindexDisabled] = useState(false);
    const reindexSearch = (...args) => __awaiter(void 0, void 0, void 0, function* () {
        if ([SearchIndexType.BOTH, SearchIndexType.CONSUMER].includes(indexType)) {
            dispatch(reindexConsumerSearch(...args));
        }
        if ([SearchIndexType.BOTH, SearchIndexType.FLEET].includes(indexType)) {
            dispatch(reindexFleetSearch(...args));
        }
    });
    const onReindexSearch = () => __awaiter(void 0, void 0, void 0, function* () {
        setReindexDisabled(true);
        yield reindexSearch(immediateRefresh, deleteExisting);
        setReindexDisabled(false);
    });
    return (<Button onClick={onReindexSearch} disabled={reindexDisabled} variant="contained" color={color} size={size}>
      Re-Index Search
    </Button>);
};
export default ReindexSearchButton;
