import { Button, Typography } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import TextField from 'src/AdminApp/components/form/TextField';
import LaborRates from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/LaborRates';
import FutureAppointmentPage from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/pages/FutureAppointmentPage';
import useRegionPage from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/pages/RegionPage/useRegionPage';
// TODO: make this come common for components/geoManagement and components/discounts
import { BtnText, LaborRatesContainer, SlidePageContent, SlidePageFieldsContainer, SlidePageFooter, SlidePageFormWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import HomeStoresDrawer from 'src/AdminApp/containers/geoManagement/HomeStoresDrawer/HomeStoresDrawer';
import { getMetrosAutocomplete } from 'src/AdminApp/modules/metros/actions';
import { selectMetrosAutocomplete } from 'src/AdminApp/modules/metros/selectors';
import { getSkeduloRegions } from 'src/AdminApp/modules/regions/actions';
import { selectSkeduloRegions } from 'src/AdminApp/modules/regions/selectors';
import { getServiceAreasAutocomplete } from 'src/AdminApp/modules/serviceAreas/actions';
import { selectServiceAreasAutocomplete } from 'src/AdminApp/modules/serviceAreas/selectors';
import { selectManagers, selectPorters, } from 'src/AdminApp/modules/users/selectors';
import { DestructiveButton, PrimaryButton } from 'src/components/buttons';
import HubSearch from 'src/components/form/HubSearch';
import GroupUserSearch from 'src/components/form/GroupUserSearch';
import MetroSearch from 'src/components/form/MetroSearch';
import SearchMultiselect from 'src/components/form/SearchMultiselect';
import SkeduloRegionsSearch from 'src/components/form/SkeduloRegionsSearch';
import TimezonesSearch from 'src/components/form/TimezonesSearch';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { DrawerFormRow, FooterActionButton } from 'src/components/SimpleDrawer';
import SimpleLoader from 'src/components/SimpleLoader';
import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    metro: Yup.object().required(),
});
const RegionPage = ({ disableSave, model, onClickArchivedLaborRatesList, onClickLaborRates, onRemove, onSubmit, closeGeoManagementDrawer, }) => {
    var _a, _b;
    const { geoEditor, geoViewer, laborRateEditor, laborRates, hasRegionLaborRates, createNewSkeduloRegion, setCreateNewSkeduloRegion, managers, regionLaborRates, isProduct, isEngineering, hasArchivedLaborRates, onClickArchiveRegion, setOpenHomeStoresDrawer, openHomeStoresDrawer, handleSubmit, hubs, isLoading, porters, } = useRegionPage({ model, onRemove, onSubmit });
    const isGeoViewer = geoEditor || geoViewer;
    const viewLaborRates = laborRateEditor || isGeoViewer;
    const showLaborRates = laborRates.length > 0 || hasRegionLaborRates;
    return isLoading ? (<SimpleLoader />) : (<>
      <Formik initialValues={{
            name: model ? model.name : '',
            metro: model ? model.metro : '',
            serviceAreas: model ? model.serviceAreas : null,
            serviceAreaIds: [],
            skeduloRegion: model
                ? { uid: model.skeduloRegionId, name: model.name }
                : '',
            skeduloRegionTimezone: '',
            skeduloRegionDescription: '',
            laborRates: model ? model.laborRates : [],
            futureAppointmentMaxCapacity: (model === null || model === void 0 ? void 0 : model.futureAppointmentMaxCapacity) || 0,
            futureAppointmentMinDays: (model === null || model === void 0 ? void 0 : model.futureAppointmentMinDays) !== null &&
                (model === null || model === void 0 ? void 0 : model.futureAppointmentMinDays) !== undefined
                ? model === null || model === void 0 ? void 0 : model.futureAppointmentMinDays
                : 21,
            addedServiceAreas: [],
            opsManagerId: (model === null || model === void 0 ? void 0 : model.opsManagerId) || null,
            porterId: (model === null || model === void 0 ? void 0 : model.porterId) || null,
            skynetDisabledForB2B: (_a = model === null || model === void 0 ? void 0 : model.skynetDisabledForB2B) !== null && _a !== void 0 ? _a : false,
            skynetDisabledForB2C: (_b = model === null || model === void 0 ? void 0 : model.skynetDisabledForB2C) !== null && _b !== void 0 ? _b : false,
            hubs,
        }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, isSubmitting, isValid, setFieldValue }) => (<SlidePageFormWrapper>
            <SlidePageContent>
              <SlidePageFieldsContainer>
                <section>
                  <DrawerFormRow>
                    <TextField fullWidth name="name" label="Region Name" type="text" variant="standard"/>
                  </DrawerFormRow>
                  <DrawerFormRow>
                    <SkeduloRegionsSearch value={values.skeduloRegion} name="skeduloRegionId" label="Skedulo Region" disabled={createNewSkeduloRegion} onUpdate={(skeduloRegion) => {
                setFieldValue('skeduloRegion', skeduloRegion);
                setCreateNewSkeduloRegion(false);
            }} getSkeduloRegions={getSkeduloRegions} skeduloRegionsSelector={selectSkeduloRegions}/>
                  </DrawerFormRow>
                  <DrawerFormRow>
                    <LabelledCheckbox label="Create New Skedulo Region" checkboxProps={{
                checked: createNewSkeduloRegion,
                onChange: (_, value) => {
                    if (value)
                        setFieldValue('skeduloRegion', '');
                    setCreateNewSkeduloRegion(value);
                },
                name: 'createNewSkeduloRegion',
            }}/>
                  </DrawerFormRow>
                  {createNewSkeduloRegion && (<>
                      <DrawerFormRow>
                        <TimezonesSearch name="skeduloRegionTimezone" label="Skedulo Region Timezone" onUpdate={(timezone) => {
                    setFieldValue('skeduloRegionTimezone', timezone);
                }}/>
                      </DrawerFormRow>
                      <DrawerFormRow>
                        <TextField fullWidth name="skeduloRegionDescription" label="Skedulo Region Description" type="text" variant="outlined"/>
                      </DrawerFormRow>
                    </>)}
                </section>
              </SlidePageFieldsContainer>
              <SlidePageFieldsContainer>
                <DrawerFormRow>
                  <HubSearch setSelectedLocations={(locations) => {
                setFieldValue('hubs', locations);
            }} value={values.hubs}/>
                </DrawerFormRow>
                <DrawerFormRow>
                  <MetroSearch value={values.metro} name="metro" label="Metro" onUpdate={(metro) => setFieldValue('metro', metro)} getMetros={getMetrosAutocomplete} metrosSelector={selectMetrosAutocomplete}/>
                </DrawerFormRow>
                <DrawerFormRow>
                  <SearchMultiselect fixedValues={values.serviceAreas || []} value={values.addedServiceAreas || []} name="serviceAreas" label="Service Areas (optional)" onUpdate={(data) => setFieldValue('addedServiceAreas', data)} getEntities={getServiceAreasAutocomplete} selectEntities={selectServiceAreasAutocomplete}/>
                </DrawerFormRow>
                <DrawerFormRow>
                  <GroupUserSearch value={managers === null || managers === void 0 ? void 0 : managers.filter((m) => m.uid === values.opsManagerId)[0]} selector={selectManagers} onUpdate={(value) => {
                setFieldValue('opsManagerId', value.uid);
            }} name="opsManager" label="Ops Manager"/>
                </DrawerFormRow>
                <DrawerFormRow>
                  <GroupUserSearch value={porters === null || porters === void 0 ? void 0 : porters.filter((m) => m.uid === values.porterId)[0]} selector={selectPorters} onUpdate={(value) => {
                setFieldValue('porterId', value.uid);
            }} name="porter" label="Porter"/>
                </DrawerFormRow>
              </SlidePageFieldsContainer>
              {(showLaborRates || viewLaborRates) && (<LaborRatesContainer>
                  <DrawerFormRow>
                    <Typography variant="h5">Labor Rates</Typography>
                  </DrawerFormRow>
                  {showLaborRates ? (<LaborRates hasModel={!!model} modelLaborRates={regionLaborRates}/>) : (<DrawerFormRow>
                      <PrimaryButton disabled={!laborRateEditor} onClick={!laborRateEditor ? () => { } : onClickLaborRates}>
                        Add Labor Rates
                      </PrimaryButton>
                    </DrawerFormRow>)}
                </LaborRatesContainer>)}
              <div>
                <FutureAppointmentPage setFieldValue={setFieldValue}/>
              </div>
              {(isProduct || isEngineering) && (<SlidePageFieldsContainer>
                  <DrawerFormRow>
                    <Typography variant="h5">Skynet settings</Typography>
                  </DrawerFormRow>
                  <DrawerFormRow>
                    <LabelledCheckbox label="Disabled for B2B" checkboxProps={{
                    checked: values.skynetDisabledForB2B,
                    name: 'skynetDisabledForB2B',
                    onChange: () => {
                        setFieldValue('skynetDisabledForB2B', !values.skynetDisabledForB2B);
                    },
                }}/>
                  </DrawerFormRow>
                  <DrawerFormRow>
                    <LabelledCheckbox label="Disabled for B2C" checkboxProps={{
                    checked: values.skynetDisabledForB2C,
                    name: 'skynetDisabledForB2C',
                    onChange: () => {
                        setFieldValue('skynetDisabledForB2C', !values.skynetDisabledForB2C);
                    },
                }}/>
                  </DrawerFormRow>
                </SlidePageFieldsContainer>)}
              {model && (<DrawerFormRow>
                  <Button color="primary" onClick={() => setOpenHomeStoresDrawer(true)}>
                    <BtnText>Home Stores</BtnText>
                  </Button>
                </DrawerFormRow>)}
              {viewLaborRates && hasArchivedLaborRates && (<DrawerFormRow>
                  <Button color="primary" onClick={onClickArchivedLaborRatesList}>
                    <BtnText>View Archived Labor Rates</BtnText>
                  </Button>
                </DrawerFormRow>)}
              {model && !model.archived && (<DrawerFormRow>
                  <DestructiveButton onClick={onClickArchiveRegion}>
                    <BtnText>Archive Region</BtnText>
                  </DestructiveButton>
                </DrawerFormRow>)}
            </SlidePageContent>
            <SlidePageFooter>
              <FooterActionButton type="submit" disabled={isSubmitting || !isValid || disableSave}>
                Save Region
              </FooterActionButton>
            </SlidePageFooter>
          </SlidePageFormWrapper>)}
      </Formik>
      <HomeStoresDrawer open={openHomeStoresDrawer} onClose={() => {
            closeGeoManagementDrawer();
            setOpenHomeStoresDrawer(false);
        }} model={model} canEdit/>
    </>);
};
export default RegionPage;
