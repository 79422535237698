import { createSelector } from 'reselect';
export const selectShiftsState = (state) => state.admin.shifts;
export const selectEditedShiftWindow = (state) => state.admin.shifts.shift.editedShiftWindow;
export const selectRawDashboard = createSelector(selectShiftsState, (shifts) => { var _a; return (_a = shifts === null || shifts === void 0 ? void 0 : shifts.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectShift = createSelector(selectShiftsState, (shifts) => { var _a; return (_a = shifts === null || shifts === void 0 ? void 0 : shifts.shift) !== null && _a !== void 0 ? _a : {}; });
export const selectShifts = createSelector(selectShiftsState, (shifts) => { var _a, _b; return (_b = (_a = shifts === null || shifts === void 0 ? void 0 : shifts.dashboard) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : []; });
export const selectShiftDashboard = createSelector([selectRawDashboard], (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectDashboardPagination = createSelector(selectRawDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardParams = createSelector(selectRawDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardFetching = createSelector(selectRawDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
