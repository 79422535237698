var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { differenceBy } from 'lodash';
import { Autocomplete, TextField } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import { selectAddVisitTechnicians, selectAddVisitTechniciansSearchResults, } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { setAddVisitTechnicians } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import SimpleChip from 'src/components/SimpleChip';
const TechniciansAutocomplete = ({ initialTechnicians, visitStatus, }) => {
    const dispatch = useDispatch();
    const technicians = useSelector(selectAddVisitTechniciansSearchResults);
    const selectedTechnicians = useSelector(selectAddVisitTechnicians);
    const handleSelected = (e, value) => __awaiter(void 0, void 0, void 0, function* () {
        const [delTech] = differenceBy(selectedTechnicians, value, 'technicianId');
        if (delTech &&
            (initialTechnicians === null || initialTechnicians === void 0 ? void 0 : initialTechnicians.includes(delTech.technicianId)) &&
            ['IN_PROGRESS'].includes(visitStatus !== null && visitStatus !== void 0 ? visitStatus : '')) {
            return;
        }
        dispatch(setAddVisitTechnicians(value));
    });
    return (<Autocomplete multiple id="technicians-select" options={technicians} getOptionLabel={(option) => `${option.mechanicFirstName}  ${option.mechanicLastName} (${option.deliveryVanName})`} filterSelectedOptions clearOnBlur disabled={technicians.length === 0} noOptionsText="There are no more available techs/vans" onChange={handleSelected} value={selectedTechnicians} disableClearable={['IN_PROGRESS'].includes(visitStatus !== null && visitStatus !== void 0 ? visitStatus : '')} isOptionEqualToValue={(option, value) => option.deliveryVanId === value.deliveryVanId &&
            option.technicianId === value.technicianId} renderInput={(params) => (<TextField {...params} variant="outlined" placeholder="Search for a technician/van pair" label="Resources"/>)} renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
                var _a;
                const tagProps = getTagProps({ index });
                return (<SimpleChip {...tagProps} label={(_a = option.name) !== null && _a !== void 0 ? _a : `${option.mechanicFirstName}  ${option.mechanicLastName} (${option.deliveryVanName})`} $bgColor={colors.GRAY_97} $iconColor="white" $textColor="white"/>);
            });
        }}/>);
};
export default TechniciansAutocomplete;
