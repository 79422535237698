var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVehiclesByConsumerId } from 'src/AdminApp/modules/consumers/actions';
import { selectVehiclesByConsumerId, selectVehiclesFetchingByConsumerId, } from 'src/AdminApp/modules/consumers/selectors';
import { setTab, setVehicle } from 'src/AdminApp/modules/quickRo/actions';
import { CONSUMER_TABS as TABS } from 'src/AdminApp/modules/quickRo/enums';
const useGarage = ({ consumerId }) => {
    const dispatch = useDispatch();
    const vehicles = useSelector(selectVehiclesByConsumerId(consumerId));
    const isFetching = useSelector(selectVehiclesFetchingByConsumerId(consumerId));
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            dispatch(getVehiclesByConsumerId(consumerId, 0, 20, true));
        });
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const selectVehicleHandler = (vehicle) => {
        dispatch(setVehicle(vehicle));
        dispatch(setTab(TABS.ADD_SERVICES));
    };
    const buttonHandler = () => {
        dispatch(setTab(TABS.ADD_NEW_VEHICLE));
    };
    return {
        selectVehicleHandler,
        buttonHandler,
        vehicles,
        isFetching,
    };
};
export default useGarage;
