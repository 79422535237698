import styled from 'styled-components';
import { theme, colors, FONT_FAMILY } from 'src/styles/theme';
export const VendorDrawerRoot = styled.div `
  display: flex;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;
export const getChipColor = ({ integrated }) => integrated ? colors.success : colors.error;
export const ListSectionWrapper = styled.div `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 24px;
  padding: 32px;

  ${theme.breakpoints.down('md')} {
    margin-top: 8px;
    margin-left: 0;
    padding: 16px;
  }

  ${theme.breakpoints.up('lg')} {
    padding-left: 124px;
    padding-right: 124px;
  }
`;
export const ListTitle = styled.h4 `
  font-family: ${FONT_FAMILY};
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 34px;
  letter-spacing: -0.3px;
  color: ${colors.GRAY_3F};
  margin: 0;

  ${theme.breakpoints.down('md')} {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
  }
`;
export const VendorHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
