var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchFMCByName } from 'src/AdminApp/modules/fleets/actions';
const useAddFleetDrawer = ({ onSubmit }) => {
    const [selectedFMC, setSelectedFMC] = useState(null);
    const [editAddressOpen, setEditAddressOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();
    const openEditAddress = () => {
        setEditAddressOpen(true);
    };
    const onAddressClose = () => {
        setEditAddressOpen(false);
    };
    const onAddressSelect = (address, setFieldValue) => {
        if (address) {
            setFieldValue('consumer.address.street', address.street);
            setFieldValue('consumer.address.city', address.city);
            setFieldValue('consumer.address.state', address.state);
            setFieldValue('consumer.address.zipCode', address.zip);
            setFieldValue('consumer.address.latitude', address.latitude);
            setFieldValue('consumer.address.longitude', address.longitude);
            setFieldValue('consumer.address.timezone', address.timezone);
        }
    };
    const onAddressSubmit = (value, setFieldValue) => {
        onAddressSelect(value, setFieldValue);
        onAddressClose();
    };
    function getAddressString(address) {
        if (!address)
            return '';
        const location = [
            address.street,
            address.city,
            address.state,
            address.zipCode,
        ];
        return location.filter(Boolean).join(', ');
    }
    const submitHandler = (values, { setSubmitting, resetForm }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const fleetManagement = selectedFMC || {
            name: (_a = values === null || values === void 0 ? void 0 : values.fleetManagementCompany) === null || _a === void 0 ? void 0 : _a.name,
            email: (_b = values === null || values === void 0 ? void 0 : values.fleetManagementCompany) === null || _b === void 0 ? void 0 : _b.email,
            contactName: (_c = values === null || values === void 0 ? void 0 : values.fleetManagementCompany) === null || _c === void 0 ? void 0 : _c.contactName,
            phone: (_d = values === null || values === void 0 ? void 0 : values.fleetManagementCompany) === null || _d === void 0 ? void 0 : _d.phone,
        };
        const valuesToSubmit = Object.assign(Object.assign({}, values), { fleetManagementCompany: (values === null || values === void 0 ? void 0 : values.hasFMC) ? fleetManagement : undefined });
        setIsSubmitting(true);
        yield onSubmit(valuesToSubmit);
        resetForm({ values: Object.assign({}, values) });
        setSubmitting(false);
        setIsSubmitting(false);
    });
    const handleSelectFleetByName = ({ setFieldValue }) => (data) => {
        setFieldValue('fleetManagementCompany.name', data.name);
        setFieldValue('fleetManagementCompany.email', data.email);
        setFieldValue('fleetManagementCompany.phone', data.phone);
        setFieldValue('fleetManagementCompany.contactName', data.contactName);
        setSelectedFMC(data);
    };
    const handleSearchFleetByName = (data) => {
        dispatch(searchFMCByName({ name: data }));
    };
    const handleFleetSearchSelect = ({ setFieldValue }) => (data) => {
        setFieldValue('consumerId', null);
        setFieldValue('parentId', data.id);
    };
    return {
        editAddressOpen,
        setEditAddressOpen,
        isSubmitting,
        setIsSubmitting,
        dispatch,
        openEditAddress,
        onAddressClose,
        onAddressSelect,
        onAddressSubmit,
        getAddressString,
        submitHandler,
        selectedFMC,
        setSelectedFMC,
        handleSelectFleetByName,
        handleSearchFleetByName,
        handleFleetSearchSelect,
    };
};
export default useAddFleetDrawer;
