var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { debounce } from 'lodash';
import { toast } from 'src/components/SimpleToast';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useIsMobile from 'src/hooks/useIsMobile';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { isFlagEnabled } from 'src/utils/url-utils';
import { selectAuthorizeModalOpen, selectFleet, selectPaymentDrawerOpen, selectRepairsStringByRefNum, selectRequest, selectRequestActiveAppointment, selectRequestAppointmentInfo, selectRequestCar, selectRequestContactInfo, selectRequestDiagnosis, selectRequestHistory, selectRequestLogoUrl, selectRequestSnapshots, selectVehicleByRefNum, selectWarrantyQuestionsDrawerOpen, } from 'src/AdminApp/modules/requests/selectors';
import { selectAuthorizedFmcAdmin, selectAuthorizedRepairEdit, selectAuthorizedRepairView, selectAuthorizedRequestHistoryView, selectAuthorizedSnapshotViewer, selectWarrantyAdmin, } from 'src/modules/auth/selectors';
import { selectMainInvoiceByWorkOrderId } from 'src/AdminApp/modules/invoices/selectors';
import { selectCustomerReview } from 'src/AdminApp/modules/orders/selectors';
import { selectRequestLock } from 'src/AdminApp/modules/requestLock/selectors';
import { selectWorkflowCurrentTask } from 'src/AdminApp/modules/workflows/selectors';
import { loadRequest, loadRequestSnapshot, } from 'src/AdminApp/modules/requests/actions';
import { getCustomerReviewByOrderId, updateCustomerReviewByOrderId, } from 'src/AdminApp/modules/orders/actions';
import { getFleet } from 'src/AdminApp/modules/fleets/actions';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { fmcSendForApproval, getFmcWorkAuthorization, } from 'src/AdminApp/modules/workOrders/actions';
import { delayFor } from 'src/utils/delay';
import FmcWorkAuthorizationStatus from 'src/AdminApp/models/enums/FmcWorkAuthorizationStatus';
import { RepairOrderResolution } from '../../models/enums';
import { getRequestLock } from '../../modules/requestLock/actions';
import { loadCurrentUser } from '../../modules/users/actions';
const useRequest = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const history = useHistory();
    const { referenceNum, selectorProps } = usePageReferenceNum();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    let showPayment = useSelector(selectPaymentDrawerOpen);
    const showWarrantyQuestions = useSelector(selectWarrantyQuestionsDrawerOpen);
    const showAuthorizeWorkOrder = useSelector(selectAuthorizeModalOpen);
    const showPaymentFlag = isFlagEnabled('payment');
    showPayment = showPayment || showPaymentFlag;
    const canViewSnapshotRole = useSelector(selectAuthorizedSnapshotViewer);
    const canViewWarrantyClaimRole = useSelector(selectWarrantyAdmin);
    const repairRequest = useSelector((state) => selectRequest(state, selectorProps));
    const requestSnapshots = useSelector((state) => selectRequestSnapshots(state, selectorProps));
    const repairRequestHistory = useSelector((state) => selectRequestHistory(state, selectorProps));
    const consumerContactInfo = useSelector((state) => selectRequestContactInfo(state, selectorProps));
    const vehicle = useSelector((state) => selectRequestCar(state, selectorProps));
    const diagnosis = useSelector((state) => selectRequestDiagnosis(state, selectorProps));
    const appointmentInfo = useSelector((state) => selectRequestAppointmentInfo(state, selectorProps));
    const activeAppointment = useSelector((state) => selectRequestActiveAppointment(state, selectorProps));
    const fleet = useSelector(selectFleet(repairRequest !== null && repairRequest !== void 0 ? repairRequest : { fleetId: null }));
    const logo = useSelector((state) => selectRequestLogoUrl(state, selectorProps));
    const canView = useSelector(selectAuthorizedRepairView);
    const canViewSnapshot = canViewSnapshotRole &&
        (requestSnapshots === null || requestSnapshots === void 0 ? void 0 : requestSnapshots.find((snapshot) => snapshot.type === 'QUOTE')) != null;
    const canViewRequestHistory = useSelector(selectAuthorizedRequestHistoryView);
    const canEdit = useSelector(selectAuthorizedRepairEdit);
    const canSubmitForApproval = useSelector(selectAuthorizedFmcAdmin);
    const orderId = (_a = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.repairOrder) === null || _a === void 0 ? void 0 : _a.id;
    const workOrderId = (_b = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.workOrder) === null || _b === void 0 ? void 0 : _b.id;
    const requestId = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.id;
    const isFleetVisitRequest = !!(repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.visit);
    const requestAddress = (_c = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.address) !== null && _c !== void 0 ? _c : null;
    const requestZip = (_d = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.zip) !== null && _d !== void 0 ? _d : null;
    const mainInvoice = useSelector((state) => selectMainInvoiceByWorkOrderId(state, { workOrderId }));
    const hasInvoice = !!(mainInvoice && orderId);
    const customerReview = useSelector((state) => selectCustomerReview(state, orderId));
    const repairsString = useSelector((state) => selectRepairsStringByRefNum(referenceNum)(state));
    const requestLock = useSelector(selectRequestLock);
    const currentTask = useSelector(selectWorkflowCurrentTask(referenceNum));
    const car = useSelector(selectVehicleByRefNum(referenceNum));
    const [currentTab, setCurrentTab] = useState(showPayment ? 2 : 1);
    const [openAddedToVisitDialog, setOpenVisitModal] = useState(false);
    const [isEditable, setIsEditable] = useState(true);
    const [isWarrantyCreationEligeble, setIsWarrantyCreationEligeble] = useState(false);
    const [isSendForApprovalButtonEnabled, setIsSendForApprovalButtonEnabled] = useState(false);
    const [isSendForApprovalButtonVisible, setIsSendForApprovalButtonVisible] = useState(false);
    const [mpiFmcWorkAuthorizationStatus, setMpiFmcWorkAuthorizationStatus] = useState('');
    const tabsRef = useRef(null);
    let isFleetRequest = false;
    let typeLabel = 'CONSUMER';
    if (repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.visit) {
        typeLabel = 'FLEET_VISIT';
        isFleetRequest = true;
    }
    else if (repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.fleetId) {
        typeLabel = 'FLEET';
        isFleetRequest = true;
    }
    const fleetContactInfo = (repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.visit)
        ? (_f = (_e = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.visit) === null || _e === void 0 ? void 0 : _e.dailyOpsContacts) === null || _f === void 0 ? void 0 : _f.filter((contact) => contact.fleetId === repairRequest.fleetId)[0]
        : (fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactName) &&
            (fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactPhone) &&
            fleet.dailyOpsContactEmail
            ? {
                fleetId: (_g = fleet === null || fleet === void 0 ? void 0 : fleet.id) !== null && _g !== void 0 ? _g : '',
                dailyOpsContactName: fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactName,
                dailyOpsContactPhone: fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactPhone,
                dailyOpsContactEmail: fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactEmail,
                visitId: null,
            }
            : null;
    const activeShop = activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.ispContactInfo;
    const origin = requestAddress || requestZip
        ? {
            lat: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.latitude,
            lng: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.longitude,
            name: 'Customer Address',
            state: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.state,
            street: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.street,
            zip: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.zip,
            originZip: requestZip,
        }
        : null;
    const destination = activeAppointment
        ? {
            lat: activeShop === null || activeShop === void 0 ? void 0 : activeShop.latitude,
            lng: activeShop === null || activeShop === void 0 ? void 0 : activeShop.longitude,
            name: activeShop === null || activeShop === void 0 ? void 0 : activeShop.shopName,
            address: activeShop === null || activeShop === void 0 ? void 0 : activeShop.address,
            zip: activeShop === null || activeShop === void 0 ? void 0 : activeShop.zip,
        }
        : null;
    const fmc = (_h = fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) === null || _h === void 0 ? void 0 : _h.filter((fmc) => { var _a; return (_a = fmc === null || fmc === void 0 ? void 0 : fmc.cars) === null || _a === void 0 ? void 0 : _a.find((fmcCar) => fmcCar.id === car.consumerCarId); });
    const submissionMethod = (fmc === null || fmc === void 0 ? void 0 : fmc.length) === 1 ? (_k = (_j = fmc[0]) === null || _j === void 0 ? void 0 : _j.fmcDto) === null || _k === void 0 ? void 0 : _k.submissionMethod : '';
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const skipAddToVisit = urlParams.get('skipAddToVisit');
    const fetchMPIFmcWorkAuthorizationStatus = () => __awaiter(void 0, void 0, void 0, function* () {
        var _m;
        if (fleet &&
            fleet.autoIntegrateId !== null &&
            submissionMethod !== null &&
            workOrderId) {
            const response = yield dispatch(getFmcWorkAuthorization(workOrderId, 'MPI'));
            setMpiFmcWorkAuthorizationStatus(((_m = response === null || response === void 0 ? void 0 : response.payload) === null || _m === void 0 ? void 0 : _m.status)
                ? FmcWorkAuthorizationStatus.display(response.payload.status)
                : 'N/A');
        }
    });
    useEffect(() => {
        if (showPayment) {
            setCurrentTab(canViewSnapshot ? 3 : 2);
        }
        if (showAuthorizeWorkOrder) {
            setCurrentTab(1);
        }
    }, [showPayment, showAuthorizeWorkOrder]); // eslint-disable-line
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const requestResponse = yield dispatch(loadRequest(referenceNum));
            if (requestResponse.error) {
                window.alert(`Repair Request Not Found: ${referenceNum}`); // eslint-disable-line no-alert
                history.push('/admin/repairs');
            }
        }))();
        if (canViewSnapshotRole) {
            dispatch(loadRequestSnapshot({ referenceNum }));
        }
    }, [referenceNum]); // eslint-disable-line
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (activeAppointment &&
                activeAppointment.status === 'CONFIRMED' &&
                repairRequest.state !== 'CONFIRMED_APPOINTMENT' &&
                ((_a = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.repairOrder) === null || _a === void 0 ? void 0 : _a.status) !== 'IN_PROGRESS') {
                yield dispatch(loadRequest(referenceNum));
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeAppointment]);
    useEffect(() => {
        if (requestLock.locked) {
            setIsEditable(false);
        }
        else {
            setIsEditable(canEdit);
        }
    }, [canEdit, requestLock.locked]);
    useEffect(() => {
        if (orderId) {
            if (repairRequest.hasInvoice) {
                dispatch(getInvoicesByWorkOrderId({ workOrderId }));
            }
            dispatch(getCustomerReviewByOrderId(orderId));
        }
        if (isFleetRequest) {
            dispatch(getFleet(repairRequest.fleetId));
        }
    }, [orderId, isFleetRequest]); // eslint-disable-line
    useEffect(() => {
        var _a;
        if (((_a = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.repairOrder) === null || _a === void 0 ? void 0 : _a.resolution) ===
            RepairOrderResolution.REPAIR_COMPLETED_WITH_INVOICE &&
            (requestLock.id === undefined ||
                requestLock.repairRequestId !== repairRequest.id)) {
            dispatch(getRequestLock(repairRequest.id));
        }
        else if (repairRequest &&
            requestLock.repairRequestId !== repairRequest.id) {
            dispatch(getRequestLock(repairRequest.id));
        }
    }, [dispatch, repairRequest, requestLock.repairRequestId, requestLock.id]);
    useEffect(() => {
        var _a;
        const warrantyEligeble = canViewWarrantyClaimRole &&
            !isFleetRequest &&
            ((_a = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.repairOrder) === null || _a === void 0 ? void 0 : _a.status) === 'CLOSED' &&
            (repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.hasNewUnapprovedWarrantyRepairs);
        setIsWarrantyCreationEligeble(!!warrantyEligeble);
    }, [
        repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.hasNewUnapprovedWarrantyRepairs,
        canViewWarrantyClaimRole,
        isFleetRequest,
        (_l = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.repairOrder) === null || _l === void 0 ? void 0 : _l.status,
    ]);
    useEffect(() => {
        if (mpiFmcWorkAuthorizationStatus === '') {
            fetchMPIFmcWorkAuthorizationStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleet, workOrderId]);
    useEffect(() => {
        if (skipAddToVisit !== null) {
            setOpenVisitModal(true);
        }
        dispatch(loadCurrentUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        var _a, _b;
        if (((_b = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0) {
            if (submissionMethod === 'AUTO_INTEGRATE') {
                setIsSendForApprovalButtonVisible(true);
            }
            if ((fleet === null || fleet === void 0 ? void 0 : fleet.autoIntegrateId) !== null && submissionMethod !== null) {
                const isPendingStatus = mpiFmcWorkAuthorizationStatus !== 'COMPLETED';
                setIsSendForApprovalButtonEnabled(canSubmitForApproval && isPendingStatus);
            }
        }
    }, [
        canSubmitForApproval,
        fleet,
        mpiFmcWorkAuthorizationStatus,
        submissionMethod,
    ]);
    const sendApproval = (authorizationType, recommendedRepairIds) => __awaiter(void 0, void 0, void 0, function* () {
        var _o, _p, _q, _r, _s;
        const payload = yield dispatch(fmcSendForApproval(workOrderId, submissionMethod, authorizationType, recommendedRepairIds));
        if (![400, 500].includes(payload === null || payload === void 0 ? void 0 : payload.status))
            return;
        if (((_q = (_p = (_o = payload === null || payload === void 0 ? void 0 : payload.response) === null || _o === void 0 ? void 0 : _o.errors) === null || _p === void 0 ? void 0 : _p.length) !== null && _q !== void 0 ? _q : 0) < 1)
            return;
        const errorArray = payload.response.errors;
        const errorCode = (_r = errorArray.find((error) => error.errorCode !== undefined)) === null || _r === void 0 ? void 0 : _r.errorCode;
        const errorMessage = (_s = errorArray.find((error) => error.errorMessage !== undefined)) === null || _s === void 0 ? void 0 : _s.errorMessage;
        if (errorCode === 'InvalidVinNo') {
            toast.error('The VIN does not have a valid maintenance request. Please contact the client and have them add it and try again.', undefined, {
                autoClose: 10000,
            });
        }
        else {
            toast.error(`There were some errors while trying to send for approval: ${errorMessage}`);
        }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSendForApproval = useCallback(debounce(sendApproval, 500), [workOrderId, submissionMethod] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const handleMPISendForApproval = (authorizationType, recommendedRepairIds) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSendForApprovalButtonEnabled(false);
        yield onSendForApproval(authorizationType, recommendedRepairIds);
        yield delayFor(5000);
        fetchMPIFmcWorkAuthorizationStatus();
    });
    const handleSubmitCustomReview = (values) => dispatch(updateCustomerReviewByOrderId({ orderId, values }));
    return {
        history,
        referenceNum,
        dispatch,
        isMobile,
        showPayment,
        showWarrantyQuestions,
        showAuthorizeWorkOrder,
        currentTab,
        setCurrentTab,
        tabsRef,
        canViewSnapshotRole,
        canViewWarrantyClaimRole,
        repairRequest,
        requestSnapshots,
        repairRequestHistory,
        consumerContactInfo,
        vehicle,
        diagnosis,
        appointmentInfo,
        activeAppointment,
        fleet,
        logo,
        canView,
        canViewSnapshot,
        canViewRequestHistory,
        canEdit,
        canSubmitForApproval,
        orderId,
        workOrderId,
        requestId,
        mainInvoice,
        customerReview,
        repairsString,
        requestLock,
        openAddedToVisitDialog,
        setOpenVisitModal,
        isEditable,
        setIsEditable,
        isFleetRequest,
        isFleetVisitRequest,
        currentTask,
        typeLabel,
        isWarrantyCreationEligeble,
        setIsWarrantyCreationEligeble,
        isSendForApprovalButtonEnabled,
        setIsSendForApprovalButtonEnabled,
        isSendForApprovalButtonVisible,
        setIsSendForApprovalButtonVisible,
        mpiFmcWorkAuthorizationStatus,
        setMpiFmcWorkAuthorizationStatus,
        fmc,
        submissionMethod,
        car,
        skipAddToVisit,
        onSendForApproval,
        handleMPISendForApproval,
        fetchMPIFmcWorkAuthorizationStatus,
        requestAddress,
        requestZip,
        hasInvoice,
        handleSubmitCustomReview,
        fleetContactInfo,
        activeShop,
        origin,
        destination,
        sendApproval,
    };
};
export default useRequest;
