var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuoteDiagnosis } from 'src/AdminApp/modules/requests/actions';
import { selectRequestDataByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { completeTechnicianWorkflowOnSiteSingleTask, getTechnicianOnSiteWorkflowByRef, } from 'src/AdminApp/modules/workflows/actions';
import { selectWorkflowCurrentTask } from 'src/AdminApp/modules/workflows/selectors';
import { toast } from 'src/components/SimpleToast';
import { saveSelectedFitment } from 'src/PopsApp/modules/parts/actions';
const useConfirmPartsFitmentDrawer = ({ referenceNum, repairFitments, onClose, }) => {
    const dispatch = useDispatch();
    const [currentFitmentIndex, setCurrentFitmentIndex] = useState(0);
    const [selectedFitment, setSelectedFitment] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const currentTask = useSelector(selectWorkflowCurrentTask(referenceNum));
    const requestData = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const currentPartFluidFitment = repairFitments.length > 0 ? repairFitments[currentFitmentIndex] : undefined;
    const hasMore = currentFitmentIndex < repairFitments.length - 1;
    const handleFitmentChange = (e, value) => {
        setSelectedFitment(value);
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedFitment || !currentPartFluidFitment || isSubmitting)
            return;
        setIsSubmitting(true);
        if (hasMore) {
            const response = yield dispatch(saveSelectedFitment({
                parentType: currentPartFluidFitment.partOrFluid,
                parentId: currentPartFluidFitment.id,
                fitmentId: selectedFitment,
            }));
            setIsSubmitting(false);
            if (response.error)
                return false;
            setCurrentFitmentIndex(currentFitmentIndex + 1);
            return;
        }
        const response = yield dispatch(saveSelectedFitment({
            parentType: currentPartFluidFitment.partOrFluid,
            parentId: currentPartFluidFitment.id,
            fitmentId: selectedFitment,
        }));
        setIsSubmitting(false);
        if (response.error)
            return false;
        dispatch(getQuoteDiagnosis(referenceNum, requestData === null || requestData === void 0 ? void 0 : requestData.request.id));
        const completeTaskResponse = yield dispatch(completeTechnicianWorkflowOnSiteSingleTask(referenceNum, currentTask));
        if (!completeTaskResponse.error) {
            toast.success('Parts confirmed');
            dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
            onClose();
        }
        setIsSubmitting(false);
    });
    return {
        currentFitmentIndex,
        selectedFitment,
        hasMore,
        currentPartFluidFitment,
        handleFitmentChange,
        handleSubmit,
        isSubmitting,
    };
};
export default useConfirmPartsFitmentDrawer;
