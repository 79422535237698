import React, { useState } from 'react';
import { Tooltip } from 'src/components/tooltips';
import { Error } from 'src/components/mui/icons';
import { Colors } from 'src/PopsApp/modules/globalStyle';
import { TechsVanContainer, TechsInfo, Tooltiptitle, } from './TechsVanInfo.styles';
import VanSkills from '../VanSkills';
import EllipsisMenu from '../EllipsisMenu/EllipsisMenu';
const Techs = ({ techs }) => {
    if (techs.length === 1)
        return (<span className="main-tech" style={{
                whiteSpace: 'nowrap',
            }}>
        {techs[0]}
      </span>);
    return (<TechsInfo>
      <span className="main-tech">{techs[0]}</span>
      <Tooltip placement="top" title={<Tooltiptitle>{techs.slice(1).join('\n')}</Tooltiptitle>}>
        <span className="techs-more">+{techs.length - 1} more</span>
      </Tooltip>
    </TechsInfo>);
};
const Van = ({ van, techs, openUnavailabilityDrawer }) => {
    const [openUnavailabilityMenu, setOpenUnavailabilityMenu] = useState(false);
    return (<TechsVanContainer className="vanInfo">
      <div className="info">
        {techs.length > 0 && <Techs techs={techs}/>}
        <div className="van">
          <span>{van.name.substring(van.name.indexOf('-') + 1).trim()}</span>
          <Tooltip placement="top" title={<VanSkills vanSkills={van.resourceSkills}/>}>
            <Error htmlColor={Colors.GRAY_76} fontSize="small" style={{
            marginLeft: '4px',
        }}/>
          </Tooltip>
        </div>
      </div>
      <div className="unavailability">
        <EllipsisMenu open={openUnavailabilityMenu} setOpen={setOpenUnavailabilityMenu} actions={[
            {
                label: 'Unavailability',
                action: () => {
                    setOpenUnavailabilityMenu(false);
                    openUnavailabilityDrawer(van);
                },
            },
        ]}/>
      </div>
    </TechsVanContainer>);
};
export default Van;
