import styled from 'styled-components';
import { Grid, List } from 'src/components/mui';
import { colors, FONT_FAMILY, theme } from 'src/styles/theme';
export const VisitInfoWrapper = styled.section `
  .visitListItem {
    padding-top: 19px;
    padding-bottom: 19px;
  }

  .visitListIcon {
    color: ${colors.GRAY_CC};
    width: 24px;
    height: 24px;
    min-width: 32px;
  }

  .durationBullet {
    margin: 0 10px;
  }

  .visitListIconLink {
    transform: rotate(-45deg);
    position: relative;
    top: -2px;
  }

  .visitListItemText {
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.3px;
    color: ${colors.GRAY_3F};

    .visitListItemTextBtn {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: -0.3px;
      color: #0000EE;
      cursor: pointer;
      text-decoration: underline;
    }

    ${theme.breakpoints.up('md')} {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: -0.3px;
    }
  }

  .visitListItemTextBold {
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.3px;
    color: ${colors.GRAY_3F};
  }
`;
export const VisitMobileList = styled(List) `
  ${theme.breakpoints.up('md')} {
    display: none;
  }
`;
export const VisitList = styled.section `
  display: none;

  ${theme.breakpoints.up('md')} {
    display: block;
  }
`;
export const TimeDisplay = styled(Grid) `
  margin-top: 24px;
`;
export const AddressDisplay = styled(Grid) `
  margin-top: 7px;
`;
export const SkeduloJobDisplay = styled(Grid) `
  margin-top: 7px;

  .visitListIcon {
    &.rotate {
      transform: rotate(-45deg);
      position: relative;
      top: -2px;
    }
  }
`;
export const ResourcesDisplay = styled(Grid) `
  display: none;

  ${theme.breakpoints.up('md')} {
    display: flex;
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_3F};
    margin-top: 17px;
  }
`;
export const ResourcesTitle = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  margin-bottom: 2px;
`;
export const ResourcesSubTitle = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  margin-bottom: 4px;
`;
export const ProgressBarWrapper = styled.aside `
  border-radius: 24px;
  border: solid 1px rgba(222, 222, 222, 0.24);
  background-color: rgba(63, 63, 63, 0.01);
  display: flex;
`;
export const TechCapacityWrapper = styled(List) `
  .primary {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
  }

  .secondary {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
  }

  .value {
    font-weight: bold;
    color: ${colors.GRAY_3F};
  }
`;
export const VisitCapacityListMobile = styled.ul `
  list-style: none;
  padding-left: 0;
`;
export const LaborCapacitySection = styled.section `
  padding: 16px;
  border-left: solid 1px rgba(222, 222, 222, 0.24);

  .capacityTitle {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_3F};
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.08px;
      color: ${colors.GRAY_76};

      .value {
        font-weight: bold;
        color: ${colors.GRAY_3F};
      }
    }
  }
`;
