import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const PartnersTableContainer = styled.table `
  width: 100%;
  border-collapse: collapse;
`;
export const PartnersTH = styled.th `
  text-align: ${(props) => { var _a; return (_a = props.align) !== null && _a !== void 0 ? _a : 'left'; }};
  padding: 16px 0;
  border-bottom: solid 1px ${colors.GRAY_DE};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};

  :first-child {
    padding-left: 20px;
  }

  :last-child {
    text-align: right;
  }
`;
