import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { Button, FormControl, Grid, InputLabel, NativeSelect, TextField, } from 'src/components/mui';
import { InfoText } from 'src/components/layout';
import { DATE_TIME_12H_FORMAT } from 'src/timeConstants';
export const SCHEMAS = ['servus_repair'];
export const TABLES = [
    'repair_request',
    'appointment',
    'quote',
    'work_authorization',
    'car_diagnosis',
    'suggested_repair',
    'parts_prices_for_suggested_repair',
    'fluid_prices',
    'repair_order',
    'symptom',
    'invoice',
    'payment',
    'payment_method',
    'payment_transaction',
    'visit',
    'address',
];
const EntityLoader = ({ timestamp, load, }) => {
    const history = useHistory();
    const { table: tableFromParams, id: idFromParams } = useParams();
    const [schema, setSchema] = useState(SCHEMAS[0]);
    const [table, setTable] = useState(TABLES[0]);
    const [id, setId] = useState();
    const onChangeSchema = (e) => setSchema(e.target.value);
    const onChangeTable = (e) => setTable(e.target.value);
    const onChangeId = (e) => setId(e.target.value);
    const onLoadClicked = () => {
        history.push(`/system/audit/entity-explorer/${schema}/${table}/${id}`);
        load({ schema, table, id });
    };
    useEffect(() => {
        if (tableFromParams && tableFromParams !== table)
            setTable(tableFromParams);
        if (idFromParams && idFromParams !== id)
            setId(idFromParams);
    }, [tableFromParams, idFromParams]);
    return (<Grid container spacing={1} style={{ alignItems: 'flex-start' }}>
      <Grid item xs={12} md={2}>
        <FormControl margin="none">
          <InputLabel>Schema</InputLabel>
          <NativeSelect name="schema" variant="standard" value={schema || ''} onChange={onChangeSchema}>
            {SCHEMAS.map((name) => (<option key={name}>{name}</option>))}
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl margin="none">
          <InputLabel>Table</InputLabel>
          <NativeSelect name="table" variant="standard" value={table || ''} onChange={onChangeTable}>
            {TABLES.map((name) => (<option key={name}>{name}</option>))}
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField label="Id" name="id" value={id || ''} fullWidth margin="none" onChange={onChangeId} variant="standard"/>
      </Grid>
      <Grid item xs={12} md={2}>
        <Button variant="contained" onClick={onLoadClicked} color="primary">
          Load
        </Button>
        <InfoText>{`${moment(timestamp).format(DATE_TIME_12H_FORMAT)}`}</InfoText>
      </Grid>
    </Grid>);
};
export default EntityLoader;
