import React from 'react';
import { Button, Grid, useMediaQuery } from 'src/components/mui';
import { Directions as DirectionsIcon, CheckCircleRounded as CheckCircleRoundedIcon, } from 'src/components/mui/icons';
import { theme } from 'src/styles/theme';
import { formatCurrency } from 'src/utils/formatter';
import { renderRefNums, renderCustomer, renderServices, renderTimeWindow, } from 'src/TechApp/components/TaskParts';
import { CopyButton, Tag, } from 'src/TechApp/components/AppointmentList/AppointmentList.styles';
import { openMaps } from 'src/TechApp/utils';
import { useHistory } from 'react-router-dom';
import { AppointmentStyled, AppointmentSection, AppointmentTaskAction, CompletedLabel, AppointmentLabel, AppointmentInfo, } from './AppointmentTask.styles';
const AppointmentTask = ({ referenceNum, address, customer, services, duration, subTotal, startLocal, endLocal, environment, currentTask, car, location, status, corrupted, }) => {
    var _a;
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const redirectHandler = () => {
        history.push(`/admin/repairs/${referenceNum}`);
    };
    const renderActions = () => {
        if (currentTask)
            return (<AppointmentTaskAction item>
          <Button variant="contained" color="primary" fullWidth={isMobile} onClick={redirectHandler}>
            View Details
          </Button>
        </AppointmentTaskAction>);
        if (!currentTask && status === 'CLOSED')
            return (<CompletedLabel>
          <span>Completed</span> <CheckCircleRoundedIcon />
        </CompletedLabel>);
    };
    return isMobile ? (<AppointmentStyled container direction="column" wrap="nowrap">
      <AppointmentSection container item>
        <Grid item xs={12}>
          {renderTimeWindow(startLocal, endLocal, corrupted)}
          <Tag slugType="appointment">Appointment</Tag>
        </Grid>
        <Grid item>
          {renderRefNums({ referenceNum, environment, corrupted })}
        </Grid>
      </AppointmentSection>
      <AppointmentSection container item>
        <Grid item xs={12}>
          <AppointmentLabel>Service Address</AppointmentLabel>
          {address && (<AppointmentInfo>
              <CopyButton onClick={() => {
                openMaps(address, location);
            }}>
                {address.street}
                <br />
                {address.city}, {address.state} {address.zip}
                <DirectionsIcon />
              </CopyButton>
            </AppointmentInfo>)}
        </Grid>
        <Grid item xs={12}>
          {renderCustomer(customer)}
        </Grid>
      </AppointmentSection>
      <AppointmentSection container item nobottompadding="true">
        <Grid item xs={12}>
          <AppointmentLabel>Vehicle Info</AppointmentLabel>
          {car && (<AppointmentInfo>
              {(_a = car.carYear) !== null && _a !== void 0 ? _a : car.year} {car.make} {car.model}{' '}
              {car.plateNumber && `(${car.plateNumber})`}
            </AppointmentInfo>)}
        </Grid>
      </AppointmentSection>
      <AppointmentSection>
        <Grid item xs={12}>
          {renderServices(services)}
        </Grid>
        <Grid item xs={6}>
          <AppointmentLabel>Duration</AppointmentLabel>
          <AppointmentInfo>{duration && `${duration}h`}</AppointmentInfo>
        </Grid>
        <Grid item xs={6}>
          <AppointmentLabel>Subtotal</AppointmentLabel>
          <AppointmentInfo>{formatCurrency(subTotal)}</AppointmentInfo>
        </Grid>
      </AppointmentSection>
      {renderActions()}
    </AppointmentStyled>) : (<tr>
      <td>
        {renderTimeWindow(startLocal, endLocal, corrupted)}
        {renderRefNums({ referenceNum, environment, corrupted })}
      </td>
      <td>
        <Tag slugType="appointment">Appointment</Tag>
        {renderCustomer(customer)}
      </td>
      <td className="servicesList">{renderServices(services)}</td>
      <td className="duration">
        <AppointmentLabel>Duration</AppointmentLabel>
        <AppointmentInfo>{duration && `${duration}h`}</AppointmentInfo>
      </td>
      <td className="subtotal">
        <AppointmentLabel>Subtotal</AppointmentLabel>
        <AppointmentInfo>{formatCurrency(subTotal)}</AppointmentInfo>
      </td>
      <td className="taskActions">{renderActions()}</td>
    </tr>);
};
export default AppointmentTask;
