import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
export const ActionCreators = {
    REQUEST_SALES_CALL: createDefaultActionCreators('REQUEST_SALES_CALL'),
    REQUEST_SUBLET: createDefaultActionCreators('REQUEST_SUBLET'),
    REQUEST_MPI_QUOTE_NEEDED: createDefaultActionCreators('REQUEST_MPI_QUOTE_NEEDED'),
    GET_ZENDESK_TICKETS: createDefaultActionCreators('GET_ZENDESK_TICKETS'),
};
export const requestSalesCall = (referenceNum) => createAPIAction({
    types: [
        ActionCreators.REQUEST_SALES_CALL.FETCH(),
        ActionCreators.REQUEST_SALES_CALL.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.REQUEST_SALES_CALL.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.REQUEST_SALES_CALL(referenceNum),
    method: 'POST',
});
export const requestSublet = (referenceNum, notes) => {
    return createAPIAction({
        types: [
            ActionCreators.REQUEST_SUBLET.FETCH(),
            ActionCreators.REQUEST_SUBLET.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.REQUEST_SUBLET.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_SUBLET(referenceNum),
        body: notes,
        method: 'POST',
    });
};
export const requestMPIQuoteNeededCall = (referenceNum) => createAPIAction({
    types: [
        ActionCreators.REQUEST_MPI_QUOTE_NEEDED.FETCH(),
        ActionCreators.REQUEST_MPI_QUOTE_NEEDED.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.REQUEST_MPI_QUOTE_NEEDED.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.REQUEST_MPI_QUOTE_NEEDED(referenceNum),
    method: 'POST',
});
export const getZendeskTickets = (referenceNum, ticketType) => (dispatch) => dispatch(createAPIAction({
    types: [
        ActionCreators.GET_ZENDESK_TICKETS.FETCH(),
        ActionCreators.GET_ZENDESK_TICKETS.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.GET_ZENDESK_TICKETS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.GET_ZENDESK_TICKETS({
        referenceNum,
        ticketType,
    }),
    method: 'GET',
}));
