import styled from 'styled-components';
import { Card } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const TokensDisplayWrapper = styled.section `
  margin-top: 32px;
  margin-left: 24px;
  margin-right: 24px;
`;
export const Loading = styled.section `
  margin-top: 50px;
  text-align: center;
`;
export const TokenCard = styled(Card) `
  margin-bottom: 16px;
`;
export const NoTokens = styled.section `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  text-align: center;
  margin-top: 48px;
`;
export const TokenCardTitle = styled.section `
  padding: 16px;
  border-bottom: 1px solid ${colors.GRAY_DE};
  display: flex;
  align-items: center;
`;
export const TokenInfoTable = styled.table `
  width: 100%;
  margin: 16px 16px 0;

  td {
    width: 50%;
  }
`;
export const TokenInfoLabel = styled.p `
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.darkGrey};
`;
export const TokenInfoValue = styled.p `
  margin: 5px 0 24px 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
`;
