import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
export const StoresList = styled.ul `
  margin-top: 24px;
  padding-left: 0;

  .QuickLocation {
    list-style: none;
    background-color: rgba(63, 63, 63, 0.01);

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &__buttonRoot {
      padding: 16px;
      border-radius: 24px;
      border: solid 1px ${colors.GRAY_F2};
      background-color: ${colors.LIGHT_GRAY};
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &:hover {
        box-shadow: inset 0 0 0 2px ${colors.BLUE};
      }

      &:active {
        background-color: rgba(44, 86, 221, 0.05);
      }

      &.standby {
        border: solid 1px ${colors.BLUE};
      }
    }

    &__label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }

    &__name {
      font-family: ${FONT_FAMILY};
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: -0.3px;
      color: ${colors.GRAY_3F};
      display: flex;
      align-items: center;
    }

    &__badge {
      margin-left: 10px;
    }

    &__availability {
      text-align: right;
      flex: 1;
    }

    &__address {
      margin-top: 4px;
      font-family: ${FONT_FAMILY};
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.1px;
      color: ${colors.GRAY_76};
    }
  }
`;
