import styled from 'styled-components';
import { Button, Menu } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
export const DateClicker = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  min-width: 215px;

  button {
    padding: 12px 4px;
  }
`;
export const DateClickerCaption = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
export const JumpButton = styled(Button) `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;

  svg {
    width: 18px;
    height: 18px;
    fill: ${colors.GRAY_DE};
    color: ${colors.GRAY_DE};
  }
`;
export const DatePickerRow = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 8px;

  ${theme.breakpoints.down('sm')} {
    margin: 0 0 32px;
    border-bottom: solid 1px ${colors.GRAY_DE};
  }
`;
export const DatePickerMenu = styled(Menu) ``;
