import { useDispatch, useSelector } from 'react-redux';
import { deleteLaborRates, setLaborRatesToEdit, } from 'src/AdminApp/modules/geoManagement/actions';
import { selectArchivedLaborRates, selectHasPendingMetroLaborRates, selectHasPendingRegionLaborRates, selectLaborRates, } from 'src/AdminApp/modules/geoManagement/selectors';
import { selectAuthorizedGeoLaborRateEdit } from 'src/modules/auth/selectors';
const useLaborRates = ({ metro, setSlide, onDeleteMetroLaborRates, onDeleteRegionLaborRates, }) => {
    const dispatch = useDispatch();
    const tempLaborRates = useSelector(selectLaborRates);
    const archivedLaborRates = useSelector(selectArchivedLaborRates);
    const laborRateEditor = useSelector(selectAuthorizedGeoLaborRateEdit);
    const hasPendingRegionLaborRates = useSelector(selectHasPendingRegionLaborRates);
    const hasPendingMetroLaborRates = useSelector(selectHasPendingMetroLaborRates);
    const onDelete = !metro ? onDeleteRegionLaborRates : onDeleteMetroLaborRates;
    const handleClickNewRule = () => setSlide(!metro ? 'laborRates' : 'metroLaborRates');
    const handleClickEditRule = (id, toDelete = false, isTemp = true) => () => {
        if (!id)
            return;
        if (toDelete) {
            if (isTemp) {
                dispatch(deleteLaborRates(id));
                return;
            }
            onDelete(id);
            return;
        }
        dispatch(setLaborRatesToEdit({
            id,
            metro,
        }));
        setSlide(!metro ? 'laborRates' : 'metroLaborRates');
    };
    return {
        laborRateEditor,
        tempLaborRates,
        archivedLaborRates,
        hasPendingRegionLaborRates,
        hasPendingMetroLaborRates,
        handleClickNewRule,
        handleClickEditRule,
    };
};
export default useLaborRates;
