import React from 'react';
import { SimpleModal } from 'src/components/layout';
const SendResetPasswordModal = ({ openModal, toggleModal, isFleet, onResetPasswordClick, }) => {
    return (<SimpleModal open={openModal} onClose={toggleModal} title={`Send this ${isFleet ? 'fleet user' : 'customer'} a password reset request?`} simpleTwoButtonRowProps={{
            submitText: 'Send',
            onSubmit: onResetPasswordClick,
            onCancel: toggleModal,
            cancelText: 'Cancel',
        }}/>);
};
export default SendResetPasswordModal;
