import { useEffect, useState } from 'react';
import { getPartners } from 'src/PartnersApp/modules/partners/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartnersData } from 'src/PartnersApp/modules/partners/selectors';
import { selectCurrentUserId } from 'src/modules/auth/selectors';
const usePartners = (rowsPerPage) => {
    const dispatch = useDispatch();
    const partners = useSelector(selectPartnersData);
    const serviceAdvisorUserId = useSelector(selectCurrentUserId);
    const [partnersFiltered, setPartnersFiltered] = useState();
    const [filter, setFilter] = useState();
    const [partnersView, setPartnersView] = useState();
    const [needsPublished, setNeedsPublished] = useState(false);
    const [showActive, setShowActive] = useState(true);
    useEffect(() => {
        dispatch(getPartners({ page: 0, size: rowsPerPage, active: showActive }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filterPartners = (e, otherFilter, funnelVw) => {
        const filterTextFixed = e
            ? e.target.value.trim().toLowerCase()
            : otherFilter;
        setFilter(filterTextFixed);
        const filtered = (funnelVw !== null && funnelVw !== void 0 ? funnelVw : partnersView).filter((f) => {
            var _a;
            return f.name.trim().toLowerCase().indexOf(filterTextFixed) > -1 ||
                ((_a = f.createdByName) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf(filterTextFixed)) > -1;
        });
        setPartnersFiltered(filtered);
    };
    const clearFilteredResults = () => {
        setFilter('');
        setPartnersFiltered(undefined);
    };
    useEffect(() => {
        if (!partners)
            return;
        let needsToBePublished = false;
        const funnelVw = partners.map((f) => {
            var _a, _b;
            const createdBySplit = (_a = f.createdBy) === null || _a === void 0 ? void 0 : _a.split('|');
            const allowPublish = !serviceAdvisorUserId
                ? false
                : ((_b = f.funnels[0]) === null || _b === void 0 ? void 0 : _b.status) === 'DRAFT' &&
                    f.createdBy &&
                    createdBySplit.length === 3 &&
                    serviceAdvisorUserId !== +createdBySplit[2];
            if (!needsToBePublished && allowPublish) {
                needsToBePublished = true;
            }
            return Object.assign(Object.assign({}, f), { allowPublish });
        });
        setPartnersView(funnelVw);
        setNeedsPublished(needsToBePublished);
        // REDO FILTER
        if (filter === null || filter === void 0 ? void 0 : filter.length) {
            filterPartners(null, filter, funnelVw);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, partners, serviceAdvisorUserId]);
    return {
        setShowActive,
        filterPartners,
        needsPublished,
        filter,
        partnersFiltered,
        partnersView,
        clearFilteredResults,
    };
};
export default usePartners;
