import React, { useState, useEffect } from 'react';
import { Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Paper, IconButton, } from 'src/components/mui/';
import { Refresh as RefreshIcon } from 'src/components/mui/icons';
import styled from 'styled-components';
import ModuleWrapper from './ModuleWrapper';
import WebhookRow from './WebhookRow';
import TargetsFilter from './TargetsFilter';
import { generateTargets } from './webhookUtils';
const SkeduloTable = styled(Table) `
  min-width: 650px;
`;
const Webhooks = ({ webhooks, deleteWebhook, webhookClickHandler, fetchWebhooks, }) => {
    const [targets, setTargets] = useState(generateTargets());
    const [filteredWebhooks, setFilteredWebhooks] = useState([]);
    useEffect(() => {
        const filtered = targets.length > 0
            ? webhooks.filter((webhook) => { var _a; return targets.includes((_a = webhook.metadata) === null || _a === void 0 ? void 0 : _a.target); })
            : webhooks;
        setFilteredWebhooks(filtered);
    }, [targets, webhooks]);
    return (<ModuleWrapper header={`Webhooks (${(filteredWebhooks === null || filteredWebhooks === void 0 ? void 0 : filteredWebhooks.length) || 0} of ${(webhooks === null || webhooks === void 0 ? void 0 : webhooks.length) || 0})`} filter={<TargetsFilter setTargets={setTargets}/>}>
      <TableContainer component={Paper}>
        <SkeduloTable>
          <TableHead>
            <TableRow>
              <TableCell>Target</TableCell>
              <TableCell align="center">Environment</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">URL</TableCell>
              <TableCell align="left">Created</TableCell>
              <TableCell align="right" size="small">
                <IconButton onClick={fetchWebhooks}>
                  <RefreshIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredWebhooks ? (filteredWebhooks.map((webhook) => (<WebhookRow key={webhook.id} webhook={webhook} deleteWebhook={deleteWebhook} webhookClickHandler={webhookClickHandler}/>))) : (<p>Webhooks not fetched</p>)}
          </TableBody>
        </SkeduloTable>
      </TableContainer>
    </ModuleWrapper>);
};
export default Webhooks;
