import React from 'react';
import { AddCircle, DeleteForever } from 'src/components/mui/icons';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import useAuthBtn from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/AuthBtn/useAuthBtn';
import { Row, AuthText, AmountText, AmountContainer } from './AuthBtn.styles';
import AddAuthDrawer from './AddAuthDrawer';
import { ErrorIconButton } from 'src/styles/styled';
import { IconButton } from 'src/components/mui';
import EditIcon from 'src/components/icons/EditIcon';
const AuthBtn = ({ workOrderResponsiblePartyAuthorization, workOrderId, referenceNum, invoice, responsiblePartyName, repairsToAuthorize, repairRequestId, }) => {
    var _a, _b;
    const { handleOpenAuthModal, handleCloseAuthModal, openAuthModal, modal, handleOpenModal, } = useAuthBtn({
        workOrderResponsiblePartyAuthorization,
        referenceNum,
        workOrderId,
        invoice,
        repairRequestId,
    });
    return (<>
      {workOrderResponsiblePartyAuthorization ? (<>
          <Row>
            <AuthText variant="subtitle2">
              {responsiblePartyName} Authorization #:{' '}
              {workOrderResponsiblePartyAuthorization.number}
            </AuthText>
            <AmountContainer>
              <AmountText variant="body2">
                Claim Amount: $
                {(_b = (_a = workOrderResponsiblePartyAuthorization.claimAmount) === null || _a === void 0 ? void 0 : _a.toFixed) === null || _b === void 0 ? void 0 : _b.call(_a, 2)}
              </AmountText>
              <IconButton onClick={handleOpenAuthModal}>
                <EditIcon />
              </IconButton>
              <ErrorIconButton onClick={handleOpenModal}>
                <DeleteForever />
              </ErrorIconButton>
            </AmountContainer>
          </Row>
          {modal}
        </>) : (<>
          <SimpleRowButton color="warning" onClick={handleOpenAuthModal} startIcon={<AddCircle />}>
            {responsiblePartyName} Authorization
          </SimpleRowButton>
        </>)}
      <AddAuthDrawer handleClose={handleCloseAuthModal} open={openAuthModal} referenceNum={referenceNum !== null && referenceNum !== void 0 ? referenceNum : ''} workOrderId={workOrderId} invoice={invoice} responsiblePartyName={responsiblePartyName} repairsToAuthorize={repairsToAuthorize} workOrderResponsiblePartyAuthorization={workOrderResponsiblePartyAuthorization} repairRequestId={repairRequestId}/>
    </>);
};
export default AuthBtn;
