var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { ChevronLeft, ChevronRight } from 'src/components/mui/icons';
import { CircularProgress, useMediaQuery } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import { Tooltip } from 'src/components/tooltips';
import { selectSelectedRegions, selectLockDrag, } from 'src/AdminApp/modules/swimlanes/selectors';
import { useSelector } from 'react-redux';
import EditUnavailabilityTemplateDrawer from 'src/AdminApp/components/deliveryVan/DeliveryVanTemplatesDrawer/EditUnavailabilityTemplateDrawer';
import { DATE_YMD_FORMAT, HOUR_12H_FORMAT } from 'src/timeConstants';
import ChartJob from './ChartJob/ChartJob';
import Unavailability from './ChartJob/Unavailability';
import TechsVanInfo from '../components/TechsVanInfo/TechsVanInfo';
import { ChartRow, ChartRowContent, ChartRowTimeContainer, ChartRegion, ChartRowItems, ArrowIcon, RegionTimezoneDivider, ArrivalWindowOverlay, ScreenLockOverlay, } from './Chart.styles';
import { SectionContainer } from '../Section/Section.styles';
import { getPositionFromTimezone, getTimezoneCurrentTime, getDayAvailabilityTemplate, getColorPercentages, isRegionTimezoneSameDay, getAvailabilityColorPercentages, } from './utils';
import useChartScroll from '../hooks/useChartScroll';
import useChart from '../hooks/useChart';
import SwimlanesDashboardContext from '../SwimlanesDashboardContext';
const Chart = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [jobsRef, setJobsRef] = useState();
    const [unavailabilityDrawer, setUnavailabilityDrawer] = useState();
    const selectedRegions = useSelector(selectSelectedRegions);
    const lockDrag = useSelector(selectLockDrag);
    const context = useContext(SwimlanesDashboardContext);
    const { scrollSnap } = useChartScroll(jobsRef);
    const { jobsByRegions, loading, selectedDate, dragLoading, setDragLoading, updatingJobId, getJobs, jobCancellationStatus, selectedJobs, } = useChart(context);
    const handleOpenUnavailabilityDrawer = (van) => {
        setUnavailabilityDrawer({
            van,
            open: true,
        });
    };
    useEffect(() => {
        if (context.autoRefreshEnabled) {
            const shouldReloadJobs = !updatingJobId &&
                !jobCancellationStatus &&
                (!selectedJobs || selectedJobs.length === 0);
            const autoRefreshId = setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                if (shouldReloadJobs)
                    yield getJobs(false);
            }), context.autoRefreshIntervalSeconds * 1000);
            console.info(`[auto-refresh] scheduled next auto-refresh in ${context.autoRefreshIntervalSeconds} seconds (id=${autoRefreshId})`);
            return () => {
                console.info(`[auto-refresh] clearing out scheduled auto-refresh (id=${autoRefreshId})`);
                clearTimeout(autoRefreshId);
            };
        }
    }, [
        context.autoRefreshEnabled,
        context.autoRefreshIntervalSeconds,
        context.lastActivity,
        getJobs,
        updatingJobId,
        jobCancellationStatus,
        selectedJobs,
    ]);
    if (loading) {
        return (<div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
        <CircularProgress size={50} color="primary"/>
      </div>);
    }
    if (jobsByRegions.length === 0 || (selectedRegions === null || selectedRegions === void 0 ? void 0 : selectedRegions.length) === 0) {
        return (<div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
        {(selectedRegions === null || selectedRegions === void 0 ? void 0 : selectedRegions.length) === 0
                ? 'Select a Region to get started'
                : 'No Results Found'}
      </div>);
    }
    const jobsHeight = jobsByRegions.reduce((acc, el) => {
        acc += el.vanResources.length;
        return acc;
    }, 0) * 65;
    // eslint-disable-next-line react/no-unstable-nested-components
    const ArrivalWindow = () => {
        var _a, _b, _c, _d, _e;
        const vans = jobsByRegions.reduce((acc, el) => {
            acc.push(...el.vanResources);
            return acc;
        }, []);
        const timelines = vans.reduce((acc, el) => {
            acc.push(...el.timelines);
            return acc;
        }, []);
        const job = timelines === null || timelines === void 0 ? void 0 : timelines.find((t) => t.id === updatingJobId && t.repairJob.type !== 'VISIT');
        // eslint-disable-next-line react/jsx-no-useless-fragment
        if (!job)
            return <></>;
        const arrivalWindow = (_b = (_a = job === null || job === void 0 ? void 0 : job.repairJob) === null || _a === void 0 ? void 0 : _a.timeConstraint) !== null && _b !== void 0 ? _b : null;
        const arrivalWindowPercentages = !arrivalWindow
            ? null
            : getColorPercentages(arrivalWindow.startAfter, arrivalWindow.startBefore, true, job.repairJob.timezone, (_c = job === null || job === void 0 ? void 0 : job.duration) !== null && _c !== void 0 ? _c : 0);
        // eslint-disable-next-line react/jsx-no-useless-fragment
        if (!arrivalWindow || !arrivalWindowPercentages)
            return <></>;
        return (<ArrivalWindowOverlay startPercentage={(_d = arrivalWindowPercentages === null || arrivalWindowPercentages === void 0 ? void 0 : arrivalWindowPercentages.startPercentage) !== null && _d !== void 0 ? _d : 0} endPercentage={(_e = arrivalWindowPercentages === null || arrivalWindowPercentages === void 0 ? void 0 : arrivalWindowPercentages.endPercentage) !== null && _e !== void 0 ? _e : 0}/>);
    };
    return (<>
      <SectionContainer bordered={false} onClick={context.trackLastActivity}>
        {!isMobile ? (<ChartRow>
            <ArrowIcon direction="left" onClick={() => scrollSnap('left')}>
              <ChevronLeft />
            </ArrowIcon>

            <ChartRowItems>
              <div className="placeholder"/>
              {jobsByRegions &&
                jobsByRegions.map((region, index) => {
                    const vans = region.vanResources;
                    return (<TechsVanInfo key={index} region={region} vans={vans} openUnavailabilityDrawer={(van) => handleOpenUnavailabilityDrawer(van)}/>);
                })}
            </ChartRowItems>
            <ChartRowItems className="chart-row-scrollable" ref={(el) => setJobsRef(el)}>
              {updatingJobId && <ArrivalWindow />}
              {lockDrag && <ScreenLockOverlay />}
              <ChartRowContent className="timeline">
                <ChartRowTimeContainer dividerHeight={jobsHeight > 0 ? jobsHeight + 70 : 0}>
                  {Array.from(Array(24).keys()).map((i) => (<div className="hour" key={i}>
                      <span>{moment(i, 'HH').format(HOUR_12H_FORMAT)}</span>
                    </div>))}
                </ChartRowTimeContainer>
              </ChartRowContent>
              {jobsByRegions &&
                jobsByRegions.map((region, regionIndex) => {
                    const vans = region.vanResources;
                    const isRegionTimeInSameDay = isRegionTimezoneSameDay(getTimezoneCurrentTime(region.timezone, true));
                    return (<ChartRegion key={regionIndex}>
                        {isRegionTimeInSameDay &&
                            selectedDate === moment().format(DATE_YMD_FORMAT) && (<Tooltip placement="left" title={getTimezoneCurrentTime(region.timezone)}>
                              <RegionTimezoneDivider position={getPositionFromTimezone(region.timezone, selectedDate)}/>
                            </Tooltip>)}
                        {vans &&
                            vans.map((van, index) => {
                                var _a;
                                const availabilityTemplates = getDayAvailabilityTemplate((_a = van === null || van === void 0 ? void 0 : van.availabilityTemplates) !== null && _a !== void 0 ? _a : [], selectedDate);
                                const availabilityPercentages = !availabilityTemplates
                                    ? null
                                    : getAvailabilityColorPercentages(availabilityTemplates);
                                return (<ChartRowContent data-van={van.id} key={van.id} className="jobs" percentagesArray={!availabilityPercentages
                                        ? []
                                        : availabilityPercentages}>
                                  {van.timelines
                                        .filter((j) => j.repairJob)
                                        .map((job) => (<ChartJob key={job.id} job={job} van={van} region={region} idx={index + 1} dragLoading={dragLoading} setDragLoading={setDragLoading}/>))}
                                  {van.unavailabilities &&
                                        van.unavailabilities.length > 0 &&
                                        van.unavailabilities.map((template) => (<Unavailability key={template.id} template={template} timezone={region.timezone} selectedDate={selectedDate}/>))}
                                </ChartRowContent>);
                            })}
                      </ChartRegion>);
                })}
            </ChartRowItems>
            <ArrowIcon direction="right" onClick={() => scrollSnap('right')}>
              <ChevronRight />
            </ArrowIcon>
          </ChartRow>) : (<div />)}
      </SectionContainer>
      <EditUnavailabilityTemplateDrawer open={!!(unavailabilityDrawer === null || unavailabilityDrawer === void 0 ? void 0 : unavailabilityDrawer.open)} closeHandler={() => setUnavailabilityDrawer(undefined)} van={unavailabilityDrawer === null || unavailabilityDrawer === void 0 ? void 0 : unavailabilityDrawer.van} swimlanes/>
    </>);
};
export default Chart;
