import { createSelector } from 'reselect';
export const selectDistributors = (state) => {
    var _a;
    return (_a = state.pops) === null || _a === void 0 ? void 0 : _a.distributors;
};
export const selectRawDashboard = createSelector(selectDistributors, (distributors) => { var _a; return (_a = distributors === null || distributors === void 0 ? void 0 : distributors.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectDistributorDashboard = createSelector([selectRawDashboard], (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectDashboardPagination = createSelector(selectRawDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardParams = createSelector(selectRawDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardFetching = createSelector(selectRawDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
export const selectRawLocationsDashboard = (distributorId) => createSelector(selectDistributors, (distributors) => { var _a, _b, _c; return (_c = (_b = (_a = distributors === null || distributors === void 0 ? void 0 : distributors.byId) === null || _a === void 0 ? void 0 : _a[distributorId]) === null || _b === void 0 ? void 0 : _b.dashboard) !== null && _c !== void 0 ? _c : {}; });
export const selectDistributorLocationDashboard = (distributorId) => createSelector([selectRawLocationsDashboard(distributorId)], (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectLocationPagination = (distributorId) => createSelector(selectRawLocationsDashboard(distributorId), (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectLocationParams = (distributorId) => createSelector(selectRawLocationsDashboard(distributorId), (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectDistributorNameById = (distributorId) => createSelector(selectDistributorDashboard, (distributors) => {
    var _a;
    return (_a = distributors === null || distributors === void 0 ? void 0 : distributors.find((distributor) => distributor.id === distributorId)) === null || _a === void 0 ? void 0 : _a.name;
});
export const selectDistributorHoursOfOperation = createSelector(selectDistributors, (distributors) => { var _a; return (_a = distributors === null || distributors === void 0 ? void 0 : distributors.distributorHoursOfOperation) !== null && _a !== void 0 ? _a : []; });
export const selectDistributorLocationHoursOfOperation = createSelector(selectDistributors, (distributors) => { var _a; return (_a = distributors === null || distributors === void 0 ? void 0 : distributors.distributorLocationHoursOfOperation) !== null && _a !== void 0 ? _a : []; });
