var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentDescription } from 'src/AdminApp/models/enums';
import { selectInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/selectors';
import { loadPaymentSubTypes, loadPaymentTypes, } from 'src/AdminApp/modules/lookups/actions';
import { selectPaymentSubTypesById, selectPaymentTypes, selectPaymentTypesByName, } from 'src/AdminApp/modules/lookups/selectors';
import { setPaymentDrawerAmount, setPaymentDrawerHasResponsibleParties, setPaymentDrawerInvoice, setPaymentDrawerPaymentSubtype, setPaymentDrawerPaymentType, setPaymentDrawerResponsibleParties, setPaymentDrawerScreen, setPaymentDrawerTab, setPaymentDrawerTotalInvoicePrice, setPaymentDrawerWorkOrderId, setPaymentDrawerWorkOrderPayments, setPaymentDrawerWorkOrderResponsibleParties, setPaymentDrawerWorkOrderResponsibleParty, } from 'src/AdminApp/modules/paymentDrawer/actions';
import { selectPaymentDrawerAmount, selectPaymentDrawerBalanceOwed, selectPaymentDrawerInvoice, selectPaymentDrawerPaymentsReceivedTotal, selectPaymentDrawerScreen, selectPaymentDrawerTab, selectPaymentDrawerWorkOrderResponsibleParty, } from 'src/AdminApp/modules/paymentDrawer/selectors';
import { addPayment, chargeAffirmPayment, chargePayment, loadPaymentMethodsByConsumer, spreedlyTokenize, } from 'src/AdminApp/modules/payments/actions';
import { openPaymentSection } from 'src/AdminApp/modules/requests/actions';
import { selectRequestContactInfoByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { loadCurrentUser } from 'src/AdminApp/modules/users/actions';
import { selectCurrentAdminUserName } from 'src/AdminApp/modules/users/selectors';
import { getTechnicianOnSiteWorkflowByRef } from 'src/AdminApp/modules/workflows/actions';
import { toast } from 'src/components/SimpleToast';
import CONFIG from 'src/config';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { selectAuthorizedPaymentCharge, selectAuthorizedPaymentChargeCard, selectAuthorizedPaymentRefund, } from 'src/modules/auth/selectors';
import { delayFor } from 'src/utils/delay';
const screenFlow = {
    chargeCard: {
        selectPayer: {
            back: null,
            next: 'home',
        },
        home: {
            back: 'selectPayer',
            next: 'charge',
        },
        charge: {
            back: 'home',
            next: null,
        },
        paymentType: {
            back: 'charge',
            next: 'paymentDetail',
        },
        paymentDetail: {
            back: 'paymentType',
            next: null,
        },
    },
    refund: {
        selectPayer: {
            back: null,
            next: 'home',
        },
        home: {
            back: 'selectPayer',
            next: 'paymentType',
        },
        charge: {
            back: 'home',
            next: null,
        },
        paymentType: {
            back: 'home',
            next: 'paymentDetail',
        },
        paymentDetail: {
            back: 'paymentType',
            next: null,
        },
    },
    charge: {
        selectPayer: {
            back: null,
            next: 'home',
        },
        home: {
            back: 'selectPayer',
            next: 'paymentType',
        },
        paymentType: {
            back: 'home',
            next: 'paymentDetail',
        },
        paymentDetail: {
            back: 'paymentType',
            next: null,
        },
    },
};
const usePaymentDrawer = ({ responsibleParties = [], open = false, workOrderPayments, totalInvoicePrice, workOrderId, workOrderResponsibleParties = [], }) => {
    const { referenceNum } = usePageReferenceNum();
    const dispatch = useDispatch();
    const invoice = useSelector(selectPaymentDrawerInvoice);
    const screen = useSelector(selectPaymentDrawerScreen);
    const tab = useSelector(selectPaymentDrawerTab);
    const amount = useSelector(selectPaymentDrawerAmount);
    const balanceOwed = useSelector(selectPaymentDrawerBalanceOwed);
    const canChargeCard = useSelector(selectAuthorizedPaymentChargeCard);
    const paymentTypesByName = useSelector(selectPaymentTypesByName);
    const paymentSubTypesById = useSelector(selectPaymentSubTypesById);
    const paymentsReceivedTotal = useSelector(selectPaymentDrawerPaymentsReceivedTotal);
    const workOrderResponsibleParty = useSelector(selectPaymentDrawerWorkOrderResponsibleParty);
    const contactInfoSelector = useSelector(selectRequestContactInfoByReferenceNum);
    const contactInfo = contactInfoSelector(referenceNum);
    const currentUserName = useSelector(selectCurrentAdminUserName);
    const hasResponsibleParties = responsibleParties.length > 0;
    const invoices = useSelector((state) => selectInvoicesByWorkOrderId(state, { workOrderId })) || {};
    const paymentTypes = useSelector(selectPaymentTypes);
    const canCharge = useSelector(selectAuthorizedPaymentCharge);
    const canRefund = useSelector(selectAuthorizedPaymentRefund);
    const getInitScreen = () => hasResponsibleParties && responsibleParties.length > 1
        ? 'selectPayer'
        : 'home';
    const getInitInvoice = () => invoices.filter((i) => {
        var _a, _b;
        return !responsibleParties.length ||
            ((_b = (_a = i.workOrderResponsibleParty) === null || _a === void 0 ? void 0 : _a.responsibleParty) === null || _b === void 0 ? void 0 : _b.id) ===
                responsibleParties[0].id;
    })[0];
    useEffect(() => {
        var _a;
        if (!open)
            return;
        const initScreen = getInitScreen();
        const initInvoice = getInitInvoice();
        // put props to state to get access from nested components
        dispatch(setPaymentDrawerScreen(initScreen));
        dispatch(setPaymentDrawerInvoice(initInvoice));
        dispatch(setPaymentDrawerAmount((_a = initInvoice === null || initInvoice === void 0 ? void 0 : initInvoice.balanceOwed) !== null && _a !== void 0 ? _a : 0));
        dispatch(setPaymentDrawerWorkOrderPayments(workOrderPayments));
        dispatch(setPaymentDrawerTotalInvoicePrice(totalInvoicePrice));
        dispatch(setPaymentDrawerResponsibleParties(responsibleParties));
        dispatch(setPaymentDrawerWorkOrderResponsibleParty());
        dispatch(setPaymentDrawerWorkOrderId(workOrderId));
        dispatch(setPaymentDrawerHasResponsibleParties(hasResponsibleParties));
        dispatch(setPaymentDrawerWorkOrderResponsibleParties(workOrderResponsibleParties));
        const quoteWorkOrderResponsibleParty = workOrderResponsibleParties.filter((w) => w.primary)[0];
        const workOrderResponsibleParty = invoice
            ? invoice === null || invoice === void 0 ? void 0 : invoice.workOrderResponsibleParty
            : quoteWorkOrderResponsibleParty;
        dispatch(setPaymentDrawerWorkOrderResponsibleParty(workOrderResponsibleParty));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    useEffect(() => {
        if (isEmpty(paymentTypes)) {
            dispatch(loadPaymentTypes());
        }
    }, [dispatch, paymentTypes]);
    useEffect(() => {
        if (!currentUserName) {
            dispatch(loadCurrentUser());
        }
    }, [dispatch, currentUserName]);
    useEffect(() => {
        if (!canCharge && canRefund) {
            dispatch(setPaymentDrawerTab('1'));
        }
        dispatch(loadPaymentMethodsByConsumer(contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.consumerId, referenceNum));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactInfo]);
    useEffect(() => {
        var _a;
        if (!invoice)
            return;
        if (amount > 0.001)
            return;
        const balanceOwed = (_a = invoice.balanceOwed) !== null && _a !== void 0 ? _a : 0;
        dispatch(setPaymentDrawerAmount(Number(balanceOwed.toFixed(2))));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice]);
    const navigate = (direction) => {
        const flow = tab === '0' ? 'charge' : 'refund';
        if (!screen)
            return;
        if (canChargeCard && flow === 'charge') {
            if (direction === 1) {
                dispatch(setPaymentDrawerScreen(screenFlow.chargeCard[screen].next));
            }
            else {
                dispatch(setPaymentDrawerScreen(screenFlow.chargeCard[screen].back));
            }
        }
        else if (direction === 1) {
            dispatch(setPaymentDrawerScreen(screenFlow[flow][screen].next));
        }
        else {
            dispatch(setPaymentDrawerScreen(screenFlow[flow][screen].back));
        }
    };
    const calculatePaymentDescription = (paymentAmount) => {
        if (!invoice)
            return PaymentDescription.PARTIAL_PAYMENT;
        const correctPrice = (invoice === null || invoice === void 0 ? void 0 : invoice.totalPrice)
            ? +invoice.totalPrice.toFixed(2)
            : 0;
        const amount = typeof paymentAmount === 'string'
            ? parseFloat(paymentAmount)
            : paymentAmount;
        if (amount === correctPrice) {
            return PaymentDescription.FULL_PAYMENT;
        }
        if (amount > 0 && amount < correctPrice) {
            return PaymentDescription.PARTIAL_PAYMENT;
        }
        if (amount === -correctPrice) {
            return PaymentDescription.FULL_REFUND;
        }
        if (amount < 0 && amount > -correctPrice) {
            return PaymentDescription.PARTIAL_REFUND;
        }
    };
    const resetState = () => {
        dispatch(setPaymentDrawerScreen(getInitScreen()));
        dispatch(setPaymentDrawerTab('0'));
        dispatch(setPaymentDrawerAmount(balanceOwed ? Number(balanceOwed.toFixed(2)) : 0));
        dispatch(setPaymentDrawerPaymentType(null));
        dispatch(setPaymentDrawerPaymentSubtype(null));
    };
    const closePaymentDrawer = (closeDrawer) => {
        dispatch(setPaymentDrawerScreen(getInitScreen()));
        if (closeDrawer) {
            closeDrawer();
        }
    };
    const onSubmitAffirmPayment = ({ setSubmitting, resetForm, options, // : { token: string; id: string },
    closeDrawer, paymentType, paymentSubtype, workOrderId, }) => __awaiter(void 0, void 0, void 0, function* () {
        const type = paymentTypesByName[paymentType];
        const subType = paymentSubTypesById[paymentSubtype];
        const description = calculatePaymentDescription(amount);
        const newPayment = {
            paymentMethodId: options === null || options === void 0 ? void 0 : options.id,
            amount,
            description,
            paymentMethodToken: options === null || options === void 0 ? void 0 : options.token,
            token: options === null || options === void 0 ? void 0 : options.token,
            referenceNumber: referenceNum,
            financingProgram: CONFIG.AFFIRM_FINANCIAL_PRODUCT_KEY,
            subType,
            type,
            transactionType: 'CREDIT',
            workOrderResponsiblePartyId: workOrderResponsibleParty === null || workOrderResponsibleParty === void 0 ? void 0 : workOrderResponsibleParty.id,
        };
        yield dispatch(chargeAffirmPayment(workOrderId, newPayment, invoice === null || invoice === void 0 ? void 0 : invoice.id));
        yield dispatch(loadPaymentMethodsByConsumer(contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.consumerId, referenceNum));
        delayFor(1500);
        resetState();
        dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
        resetForm();
        closePaymentDrawer(closeDrawer);
        setSubmitting(false);
        setTimeout(() => {
            location.reload();
        }, 3000);
    });
    const onSubmitPayment = ({ setSubmitting, resetForm, options = { isSavedCard: false }, // : { isSavedCard: boolean; token?: string }
    closeDrawer, paymentType, paymentSubtype, workOrderId, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        const type = paymentTypesByName[paymentType];
        const subType = paymentSubTypesById[paymentSubtype];
        const description = calculatePaymentDescription(amount);
        const payment = {
            type,
            subType,
            amount,
            description,
            balanceOwed,
            workOrderResponsiblePartyId: workOrderResponsibleParty === null || workOrderResponsibleParty === void 0 ? void 0 : workOrderResponsibleParty.id,
            paymentsReceived: paymentsReceivedTotal
                ? paymentsReceivedTotal.toFixed(2)
                : 0,
            transactionUser: currentUserName,
        };
        if (document && !options.isSavedCard) {
            payment.savePaymentMethod = document.getElementById('savePaymentMethod').checked;
            payment.paymentMethodToken = document.getElementById('payment_method_token').value;
            payment.customerEmail = (_a = contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.email) !== null && _a !== void 0 ? _a : null;
        }
        else {
            payment.paymentMethodToken = options.token;
            payment.savePaymentMethod = true;
        }
        const result = yield dispatch(chargePayment(workOrderId, payment, invoice === null || invoice === void 0 ? void 0 : invoice.id));
        delayFor(1500);
        yield dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
        const chargeFailed = (_b = result === null || result === void 0 ? void 0 : result.state) === null || _b === void 0 ? void 0 : _b.includes('GATEWAY_PROCESSING_FAILED');
        if (chargeFailed) {
            const messageEl = document.getElementById('message');
            if (messageEl) {
                messageEl.innerHTML =
                    (_c = result === null || result === void 0 ? void 0 : result.errorMessage) !== null && _c !== void 0 ? _c : 'Payment authorization has failed.';
                messageEl.style.display = 'inherit';
            }
        }
        else {
            yield dispatch(loadPaymentMethodsByConsumer(contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.consumerId, referenceNum));
            resetState();
            dispatch(openPaymentSection(false));
            getTechnicianOnSiteWorkflowByRef(referenceNum);
            resetForm();
            closePaymentDrawer(closeDrawer);
        }
        setSubmitting(false);
        setTimeout(() => {
            location.reload();
        }, 3000);
    });
    const onAddPayment = ({ payment, setSubmitting, resetForm, closeDrawer, paymentType, paymentSubtype, workOrderId, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _d;
        const subTypeId = (_d = payment === null || payment === void 0 ? void 0 : payment.paymentSubtype) !== null && _d !== void 0 ? _d : paymentSubtype;
        if (payment.description === PaymentDescription.PARTIAL_REFUND ||
            payment.description === PaymentDescription.FULL_REFUND) {
            if (Math.sign(payment.amount) !== -1) {
                return toast.error('Refund amount should be negative.');
            }
            if (Math.abs(payment.amount) > (paymentsReceivedTotal !== null && paymentsReceivedTotal !== void 0 ? paymentsReceivedTotal : 0)) {
                return toast.error('Refund amount is greater than Payment Received.');
            }
        }
        const type = paymentTypesByName[paymentType];
        const subType = paymentSubTypesById[subTypeId];
        yield dispatch(addPayment(workOrderId, Object.assign(Object.assign({}, payment), { type,
            subType,
            balanceOwed, paymentsReceived: paymentsReceivedTotal
                ? paymentsReceivedTotal.toFixed(2)
                : 0, transactionUser: currentUserName, workOrderResponsiblePartyId: workOrderResponsibleParty === null || workOrderResponsibleParty === void 0 ? void 0 : workOrderResponsibleParty.id }), invoice === null || invoice === void 0 ? void 0 : invoice.id));
        delayFor(1500);
        setSubmitting(false);
        closePaymentDrawer(closeDrawer);
        resetState();
        dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
        resetForm();
        setTimeout(() => {
            location.reload();
        }, 3000);
    });
    const onPaymentExistingCard = ({ setSubmitting, resetForm, token, methodType, id, closeDrawer, workOrderId, }) => __awaiter(void 0, void 0, void 0, function* () {
        if (methodType === 'AFFIRM') {
            const { paymentType, paymentSubtype } = yield selectPaymentType('Financing', 'Affirm');
            onSubmitAffirmPayment({
                setSubmitting,
                resetForm,
                options: {
                    token,
                    id,
                },
                closeDrawer,
                paymentType,
                paymentSubtype,
                workOrderId,
            });
        }
        else {
            const { paymentType, paymentSubtype } = yield selectPaymentType('Card', 'Stripe');
            onSubmitPayment({
                setSubmitting,
                resetForm,
                options: {
                    isSavedCard: true,
                    token,
                },
                closeDrawer,
                paymentType,
                paymentSubtype,
                workOrderId,
            });
        }
    });
    const selectPaymentType = (type, subType = null, alt = false) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(loadPaymentSubTypes(type));
        const paymentSubTypes = response.payload;
        const paymentSubTypeFound = paymentSubTypes.find((st) => st.displayName === subType);
        let newPaymentSubType;
        if (paymentSubTypeFound) {
            newPaymentSubType = paymentSubTypeFound.id;
        }
        dispatch(setPaymentDrawerPaymentType(type));
        let needNavigate = true;
        if (type === 'Financing' && subType === 'Affirm' && !alt) {
            dispatch(setPaymentDrawerPaymentSubtype(newPaymentSubType));
            needNavigate = false;
        }
        if (type === 'Card' && subType === 'Stripe') {
            dispatch(setPaymentDrawerPaymentSubtype(newPaymentSubType));
            needNavigate = false;
        }
        if (needNavigate) {
            navigate(1);
        }
        return {
            paymentType: type,
            paymentSubtype: newPaymentSubType,
        };
    });
    const onTokenize = () => __awaiter(void 0, void 0, void 0, function* () {
        const fakeButton = document.getElementById('fake_button');
        if (fakeButton) {
            fakeButton.style.display = 'inherit';
            setTimeout(() => {
                fakeButton.style.display = 'none';
            }, 3000);
        }
        const requiredFields = {};
        if (document) {
            requiredFields.full_name = document.getElementById('full_name').value;
            requiredFields.month = +document.getElementById('month').value;
            requiredFields.year =
                2000 + +document.getElementById('year').value;
            requiredFields.savePaymentMethod = document.getElementById('savePaymentMethod').checked;
        }
        yield selectPaymentType('Card', 'Stripe');
        dispatch(spreedlyTokenize(invoice === null || invoice === void 0 ? void 0 : invoice.id, requiredFields, false));
    });
    const getInvoiceByResponsibleParty = (responsibleParty) => invoices.filter((i) => {
        var _a, _b;
        return ((_b = (_a = i.workOrderResponsibleParty) === null || _a === void 0 ? void 0 : _a.responsibleParty) === null || _b === void 0 ? void 0 : _b.id) ===
            responsibleParty.id;
    })[0];
    const switchTabs = (event, newTab) => {
        var _a, _b;
        let newAmount = null;
        if (tab === '0') {
            newAmount = (_a = balanceOwed === null || balanceOwed === void 0 ? void 0 : balanceOwed.toFixed(2)) !== null && _a !== void 0 ? _a : 0;
        }
        else {
            newAmount = (_b = paymentsReceivedTotal === null || paymentsReceivedTotal === void 0 ? void 0 : paymentsReceivedTotal.toFixed(2)) !== null && _b !== void 0 ? _b : 0;
        }
        dispatch(setPaymentDrawerTab(newTab));
        dispatch(setPaymentDrawerAmount(newAmount));
    };
    return {
        selectPaymentType,
        onTokenize,
        onPaymentExistingCard,
        onSubmitPayment,
        onAddPayment,
        navigate,
        calculatePaymentDescription,
        getInvoiceByResponsibleParty,
        getInitScreen,
        getInitInvoice,
        invoice,
        screen,
        tab,
        amount,
        balanceOwed,
        paymentsReceivedTotal,
        canCharge,
        canRefund,
        closePaymentDrawer,
        switchTabs,
    };
};
export default usePaymentDrawer;
