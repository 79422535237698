import React from 'react';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
const MarginContainer = styled.div `
  font-size: 13px;
  font-weight: bold;
`;
const MarginBox = styled.span `
  color: ${(props) => colors[props.color]};
  font-size: 14px;
`;
const PriceMargin = ({ price, cost }) => {
    if (!price || !cost)
        return null;
    const margin = Math.round(((price - cost) / price) * 100);
    const gain = margin > 0;
    return (<MarginContainer>
      {`${gain ? 'Margin' : 'Loss'}: `}
      <MarginBox color={gain ? 'green' : 'red'}>{margin}%</MarginBox>
    </MarginContainer>);
};
export default PriceMargin;
