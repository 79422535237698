import { createDefaultApiActionCreators } from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/AdminApp/modules/api/urls';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createAction from 'src/modules/api/utils/createAction';
export const ActionCreators = {
    GET_JOBS_BY_REGIONS: createDefaultApiActionCreators('GET_JOBS_BY_REGIONS'),
    CLEAR_JOBS_BY_REGIONS: createAction('CLEAR_JOBS_BY_REGIONS'),
    GET_TERRITORIES: createDefaultApiActionCreators('GET_TERRITORIES'),
    UPDATE_TIMELINE_JOB_VAN: createDefaultApiActionCreators('UPDATE_TIMELINE_JOB_VAN'),
    GET_SWIMLANES_REQUEST_INFO: createDefaultApiActionCreators('GET_SWIMLANES_REQUEST_INFO'),
};
export const setSelectedDate = createAction('SET_SELECTED_DATE');
export const setSelectedJobs = createAction('SET_SELECTED_JOBS');
export const setUpdatingJobId = createAction('SET_UPDATING_JOB_ID');
export const setWorkingVansOnly = createAction('SET_WORKING_VANS_ONLY');
export const setSelectedRegions = createAction('SET_SELECTED_REGIONS');
export const setJobCancellationStatus = createAction('SET_JOB_CANCELLATION_STATUS');
export const setOnDragEnd = createAction('SET_ON_DRAG_END');
export const setSelectedJobAnchorEl = createAction('SET_SELECTED_JOB_ANCHOR_EL');
export const setIsJobInfoLoading = createAction('SET_IS_INFO_LOADING');
export const setLockDrag = createAction('SET_LOCK_DRAG');
export const getJobsByRegions = (date, workingVans, regionIds) => {
    const params = { date, workingVans, regionIds };
    if (regionIds.length === 0) {
        return ActionCreators.CLEAR_JOBS_BY_REGIONS();
    }
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_JOBS_BY_REGIONS),
        endpoint: API_URLS.ADMIN.TIMELINE_JOBS(),
        method: 'POST',
        body: params,
    });
};
export const getTerritories = () => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_TERRITORIES),
    endpoint: API_URLS.ADMIN.TIMELINE_TERRITORIES(),
    method: 'GET',
});
export const updateJobVan = (jobId, appointmentTime, timezone, vanId, rescheduleReason) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_TIMELINE_JOB_VAN),
    endpoint: API_URLS.ADMIN.UPDATE_TIMELINE_JOB_VAN(),
    method: 'POST',
    body: { jobId, appointmentTime, timezone, vanId, rescheduleReason },
});
export const getSwimlanesRequestInfo = (referenceNumber) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_SWIMLANES_REQUEST_INFO),
    endpoint: API_URLS.ADMIN.SWIMLANES_REQUEST_INFO(referenceNumber),
    method: 'GET',
});
