var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getInspectionByRepairRequestId, getInspectionPerformedStatus, } from 'src/AdminApp/modules/mpi/actions';
import { loadAdminUserInfo } from 'src/AdminApp/modules/users/actions';
import { updateInspectionStatus } from 'src/AdminApp/containers/vehicle/CarModule/helpers/updateInspectionStatus';
import { selectInspectionPerformedStatus, selectMpiInspectionByRepairRequestId, } from 'src/AdminApp/modules/mpi/selectors';
import { selectAccessToken, selectAuthorizedEditServiceAdvisor, } from 'src/modules/auth/selectors';
import { selectCurrentUser } from 'src/AdminApp/modules/users/selectors';
import { getFieldOpsUrl } from 'src/AdminApp/utils/url-utils';
import { downloadMpiReportPdf } from 'src/utils/downloadUtils';
import { selectRequestDataByReferenceNum, selectVehicleByRefNum, } from 'src/AdminApp/modules/requests/selectors';
import { getPartner } from 'src/PartnersApp/modules/partners/actions';
import { selectPartnerById } from 'src/PartnersApp/modules/partners/selectors';
const useHealthReport = ({ repairRequestId, referenceNum, state, }) => {
    var _a, _b;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [issuesCount, setIssuesCount] = useState(0);
    const [mpiEditable, setMpiEditable] = useState();
    const inspectionPerformedStatus = useSelector(selectInspectionPerformedStatus);
    const currentUser = useSelector(selectCurrentUser);
    const currentUserIsSAEditor = useSelector(selectAuthorizedEditServiceAdvisor);
    const currentAuthGroups = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.authGroups) !== null && _a !== void 0 ? _a : [];
    const currentUserIsSA = currentAuthGroups.includes('Service Advisors');
    const mpi = useSelector(selectMpiInspectionByRepairRequestId(repairRequestId));
    const accessToken = useSelector(selectAccessToken);
    const car = useSelector(selectVehicleByRefNum(referenceNum));
    const repairRequest = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const partnerInfo = useSelector(selectPartnerById((_b = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _b === void 0 ? void 0 : _b.partnerId));
    const suppressNotifications = partnerInfo === null || partnerInfo === void 0 ? void 0 : partnerInfo.disableNonAppointmentReminderCommunications;
    const getData = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e, _f, _g;
        setLoading(true);
        dispatch(getInspectionPerformedStatus(referenceNum));
        if ((_c = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _c === void 0 ? void 0 : _c.partnerId) {
            dispatch(getPartner((_d = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _d === void 0 ? void 0 : _d.partnerId));
        }
        const inspectionResponse = yield dispatch(getInspectionByRepairRequestId(repairRequestId));
        if (inspectionResponse) {
            const ins = inspectionResponse.payload.data.findByRepairRequestId;
            if (ins && ins.completedAt && ins.performedById) {
                yield dispatch(loadAdminUserInfo(ins.performedById));
            }
        }
        const localMpi = (_f = (_e = inspectionResponse === null || inspectionResponse === void 0 ? void 0 : inspectionResponse.payload) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.findByRepairRequestId;
        let tmpIssuesCount = 0;
        if (localMpi && ((_g = localMpi.inspectionType) === null || _g === void 0 ? void 0 : _g.type)) {
            if (localMpi.inspectionType.type === 'inspection') {
                tmpIssuesCount = localMpi.inspectionResults.length;
            }
            else {
                localMpi.checklistAnswers.forEach((a) => {
                    if (a.answer ===
                        localMpi.inspectionType.checklistAnswerLabels.needsWorkLabel) {
                        tmpIssuesCount += 1;
                    }
                });
            }
            setIssuesCount(tmpIssuesCount);
        }
        setLoading(false);
    });
    useEffect(() => {
        const editable = ![
            'CLOSED_REPAIR',
            'CLOSED_REPAIR_COMPLETION_UNKNOWN',
            'CANCELED_REQUEST',
            'CANCELED_APPOINTMENT_CUSTOMER',
            'CANCELED_APPOINTMENT_NO_CONFIRMED_ARRIVAL',
            'INVALID',
        ].includes(state);
        setMpiEditable(editable);
    }, [state]);
    const updateServiceAdvisor = (values) => {
        var _a, _b, _c;
        const fullName = values.serviceAdvisorName.split(' ');
        updateInspectionStatus({
            dispatch,
            inspectionPerformedStatus,
            currentUserIsSA,
            currentUser: {
                firstName: (_a = fullName[0]) !== null && _a !== void 0 ? _a : '',
                lastName: (_b = fullName[1]) !== null && _b !== void 0 ? _b : '',
            },
            currentUserId: (_c = values.serviceAdvisorUserId) !== null && _c !== void 0 ? _c : null,
            referenceNum,
            forceUpdateSA: true,
            currentUserIsSAEditor,
            updateSA: true,
        });
    };
    const handleMPIMainAction = () => {
        if (!mpi) {
            window.location = getFieldOpsUrl(`mpi/create?repairRequestId=${referenceNum}`);
        }
        else {
            window.location = getFieldOpsUrl(`mpi/${mpi.id}`);
        }
    };
    const mpiMainButtonText = () => {
        if (!mpi) {
            return 'Add MPI';
        }
        const { inspectionType } = mpi !== null && mpi !== void 0 ? mpi : {};
        if (mpi.completedAt) {
            return `Edit ${inspectionType.name}${inspectionType.name.toLowerCase().indexOf('mpi') === -1 ? ' MPI' : ''}`;
        }
        return `Continue ${inspectionType.name}${inspectionType.name.toLowerCase().indexOf('mpi') === -1 ? ' MPI' : ''}`;
    };
    const downloadMpiPdf = () => __awaiter(void 0, void 0, void 0, function* () {
        var _h, _j, _k;
        setLoading(true);
        yield downloadMpiReportPdf(mpi === null || mpi === void 0 ? void 0 : mpi.id, (_h = car === null || car === void 0 ? void 0 : car.year) !== null && _h !== void 0 ? _h : 0, car === null || car === void 0 ? void 0 : car.make, car === null || car === void 0 ? void 0 : car.model, accessToken, (_k = (_j = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _j === void 0 ? void 0 : _j.fleetId) !== null && _k !== void 0 ? _k : '0');
        setLoading(false);
    });
    return {
        getData,
        updateServiceAdvisor,
        loading,
        inspectionPerformedStatus,
        currentUserIsSAEditor,
        mpi,
        handleMPIMainAction,
        mpiMainButtonText,
        downloadMpiPdf,
        issuesCount,
        mpiEditable,
        suppressNotifications,
    };
};
export default useHealthReport;
