import { FormControl, MenuItem, Select } from 'src/components/mui';
import React, { useEffect, useState } from 'react';
import { ContentInner, TechInputLabel, VanLabel, } from 'src/AdminApp/containers/requests/StateTracker/drawers/VisitSelectTechnicianDrawer/VisitSelectTechnicianDrawer.styles';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import useTechnicians from 'src/TechApp/hooks/useTechnicians';
const VisitSelectTechnicianDrawer = ({ open, onClose, visitResources, }) => {
    const [technicianUid, setTechnicianUid] = useState('');
    const [techs, setTechs] = useState([]);
    const [van, setVan] = useState();
    const { selectTechnicianByUid } = useTechnicians();
    const handleChange = (event) => {
        setTechnicianUid(event.target.value);
        const selectedTech = techs.find((tech) => tech.technicianId === event.target.value);
        if (selectedTech) {
            const foundVan = visitResources === null || visitResources === void 0 ? void 0 : visitResources.find((r) => r.type === 'VAN' && r.deliveryVanId === selectedTech.deliveryVanId);
            setVan(foundVan);
        }
        else {
            setVan(undefined);
        }
    };
    useEffect(() => {
        if (visitResources) {
            const justTechs = visitResources.filter((resource) => resource.type === 'MECHANIC');
            setTechs(justTechs);
            if (justTechs.length === 1 && justTechs[0].technicianId) {
                setTechnicianUid(justTechs[0].technicianId);
                const foundVan = visitResources.find((r) => r.type === 'VAN' && r.deliveryVanId === justTechs[0].deliveryVanId);
                setVan(foundVan);
            }
        }
    }, [visitResources]);
    const handleSubmit = () => {
        const selectedTechFull = selectTechnicianByUid(technicianUid);
        onClose(selectedTechFull === null || selectedTechFull === void 0 ? void 0 : selectedTechFull.id);
    };
    return (<SimpleDrawer open={open} onClose={onClose} disableEnforceFocus>
      <SimpleDrawerHeader title="Assign Resources" onClose={onClose}/>

      <SimpleDrawerContent>
        <ContentInner>
          <p>Select technician and van to assign to this RO.</p>

          <FormControl fullWidth>
            <TechInputLabel id="select-tech-label">Technician</TechInputLabel>
            <Select id="select-technician-visit" variant="outlined" labelId="select-tech-label" value={technicianUid} onChange={handleChange}>
              {techs.map((resource) => {
            var _a;
            return (<MenuItem key={resource.technicianId} value={(_a = resource.technicianId) !== null && _a !== void 0 ? _a : -1}>
                  {resource.technicianName}
                </MenuItem>);
        })}
            </Select>
          </FormControl>

          {van && (<VanLabel>
              Van: <strong>{van.deliveryVanName}</strong>
            </VanLabel>)}
        </ContentInner>
      </SimpleDrawerContent>

      <SimpleDrawerFooter>
        <FooterActionButton disabled={!technicianUid} onClick={handleSubmit}>
          Submit
        </FooterActionButton>
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
export default VisitSelectTechnicianDrawer;
