import React, { useEffect, useState } from 'react';
import { toast } from 'src/components/SimpleToast';
import { IconButton, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from 'src/components/mui';
import { CheckOutlined as DoneIcon, EditOutlined as EditIcon, CancelOutlined as CancelIcon, } from 'src/components/mui/icons';
import { EditableTableCell } from '../components';
import { useSkeduloGraphqlApiClient } from '../api/skeduloGraphqlApiClient';
import { toEditableRow, fromEditableRow } from '../utils';
import { ResourcesTable, ResourcesColumnHeader, ResourcesCell, ResourcesMeta, ResourcesClickableCell, } from './styles';
const Regions = ({ regions, onSaveCallback, onResourceCountClick, groupResourcesByType, }) => {
    const [rows, setRows] = useState([]);
    const [previous, setPrevious] = useState({});
    const skeduloGraphqlApiClient = useSkeduloGraphqlApiClient();
    const onToggleEditMode = (uid) => {
        setRows(() => rows.map((row) => row.UID === uid ? Object.assign(Object.assign({}, row), { isEditMode: !row.isEditMode }) : row));
    };
    const onSave = (uid) => {
        onToggleEditMode(uid);
        const region = rows.find((row) => row.UID === uid);
        skeduloGraphqlApiClient
            .updateRegion({
            UID: region.UID,
            Name: region.Name,
            Description: region.Description,
        })
            .then((response) => {
            if (response.errors) {
                const errors = response.errors.map((error) => `${error.statusCode}: ${error.mutation_errors
                    .map((me) => me.message)
                    .join(',')}`);
                toast.error(`Error updating Region '${region === null || region === void 0 ? void 0 : region.Name}':\n\n${errors.join('\\n')}`);
            }
            else {
                toast.success(`Successfully updated Region '${region === null || region === void 0 ? void 0 : region.Name}' (ID: ${region === null || region === void 0 ? void 0 : region.UID})'`);
                onSaveCallback(fromEditableRow(region));
            }
        })
            .catch((error) => {
            toast.error(`Error updating Region '${region === null || region === void 0 ? void 0 : region.Name}' (ID: ${region === null || region === void 0 ? void 0 : region.UID}): ${error}`);
        });
    };
    const onChange = (e, row) => {
        if (!previous[row.UID]) {
            setPrevious((state) => (Object.assign(Object.assign({}, state), { [row.UID]: row })));
        }
        const { value, name } = e.target;
        const { UID } = row;
        const newRows = rows.map((row) => row.UID === UID ? Object.assign(Object.assign({}, row), { [name]: value }) : row);
        setRows(newRows);
    };
    const onRevert = (uid) => {
        const newRows = rows.map((row) => row.UID === uid ? Object.assign(Object.assign({}, (previous[uid] || row)), { isEditMode: false }) : row);
        setRows(newRows);
        setPrevious((state) => {
            delete state[uid];
            return state;
        });
    };
    useEffect(() => {
        if (regions) {
            setRows(regions.map(toEditableRow));
        }
    }, [regions]);
    return (<TableContainer component={Paper}>
      <ResourcesTable size="small">
        <TableHead>
          <ResourcesColumnHeader>
            <ResourcesCell $minWidth="350px">Region Name</ResourcesCell>
            <ResourcesCell>Timezone</ResourcesCell>
            <ResourcesCell>Description</ResourcesCell>
            <ResourcesCell>Vans</ResourcesCell>
            <ResourcesCell>Mechanics</ResourcesCell>
            <ResourcesCell>Unassigned</ResourcesCell>
            <ResourcesCell $minWidth="150px"/>
          </ResourcesColumnHeader>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const { vans, mechanics, unassigneds } = groupResourcesByType(row.Resources);
            return (<TableRow key={row.UID}>
                <EditableTableCell row={row} name="Name" onChange={onChange} inputStyle={{ width: '300px' }}>
                  <div>{row.Name}</div>
                  <ResourcesMeta>{row.UID}</ResourcesMeta>
                </EditableTableCell>
                <TableCell>{row.Timezone}</TableCell>
                <EditableTableCell row={row} name="Description" onChange={onChange}/>
                <ResourcesClickableCell align="center" onClick={() => onResourceCountClick('Van', row.Timezone, row.UID)}>
                  {vans.length}
                </ResourcesClickableCell>
                <ResourcesClickableCell align="center" onClick={() => onResourceCountClick('Mechanic', row.Timezone, row.UID)}>
                  {mechanics.length}
                </ResourcesClickableCell>
                <ResourcesClickableCell align="center" onClick={() => onResourceCountClick('Unassigned', row.Timezone, row.UID)}>
                  {unassigneds.length}
                </ResourcesClickableCell>
                <TableCell>
                  {row.isEditMode ? (<>
                      <IconButton aria-label="done" onClick={() => onSave(row.UID)}>
                        <DoneIcon />
                      </IconButton>
                      <IconButton aria-label="revert" onClick={() => onRevert(row.UID)}>
                        <CancelIcon />
                      </IconButton>
                    </>) : (<IconButton aria-label="delete" onClick={() => onToggleEditMode(row.UID)}>
                      <EditIcon />
                    </IconButton>)}
                </TableCell>
              </TableRow>);
        })}
        </TableBody>
      </ResourcesTable>
    </TableContainer>);
};
export default Regions;
