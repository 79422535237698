import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { Tabs } from 'src/components/mui';
import TabPanel from 'src/AdminApp/containers/fleets/tabs/TabPanel';
export const VehicleInfo = styled.section `
  padding: 24px 26px 16px;
  border-bottom: 1px solid ${colors.lightGrey};
`;
export const YearMakeModelInfo = styled.h3 `
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -0.5px;
  margin: 0;
  padding: 0;

  img {
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }
`;
export const VehicleDetails = styled.p `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.29px;
  color: ${colors.middleGrey};
  margin: 0;
`;
export const Bullet = styled.span `
  padding: 0 5px;
`;
export const TokensTabs = styled(Tabs) `
  margin-top: 16px;
`;
export const TokensTabPanel = styled(TabPanel) `
  padding: 0;
`;
