var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useMediaQuery } from 'src/components/mui';
import { toast } from 'src/components/SimpleToast';
import { theme } from 'src/styles/theme';
import { getLunchBreak, patchLunchBreak, } from 'src/TechApp/modules/lunchBreak/actions';
import { selectLunchBreak, selectLunchBreakIsLoading, } from 'src/TechApp/modules/lunchBreak/selectors';
import { TIME_FORMAT } from 'src/timeConstants';
import { formatTime } from 'src/utils/data-utils';
const useLunchBreakDetails = () => {
    const dispatch = useDispatch();
    const { lunchBreakId } = useParams();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isLoading = useSelector(selectLunchBreakIsLoading);
    const lunchBreak = useSelector(selectLunchBreak);
    const [isCanceled, setIsCanceled] = useState(false);
    const history = useHistory();
    const [startTime, setStartTime] = useState(moment().format(TIME_FORMAT));
    const [endTime, setEndTime] = useState();
    useEffect(() => {
        if (!lunchBreakId) {
            return;
        }
        dispatch(getLunchBreak(lunchBreakId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lunchBreakId]);
    const updateLunchBreakStatus = (status) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const actualStartTime = (_a = lunchBreak === null || lunchBreak === void 0 ? void 0 : lunchBreak.actualStartTime) !== null && _a !== void 0 ? _a : formatTime(startTime);
        const actualEndTime = (_b = lunchBreak === null || lunchBreak === void 0 ? void 0 : lunchBreak.actualEndTime) !== null && _b !== void 0 ? _b : formatTime(endTime);
        const response = yield dispatch(patchLunchBreak(lunchBreakId, {
            actualStartTime: status !== 'CANCELLED' ? actualStartTime : null,
            actualEndTime,
            status,
        }));
        if (!response.error) {
            toast.success('Lunch Break Updated Successfully');
        }
    });
    const goBackHandler = () => {
        setIsModalOpen(false);
    };
    const onCancelLunchBreak = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsModalOpen(false);
        yield updateLunchBreakStatus('CANCELLED');
        history.replace('/tech/lunch-breaks');
    });
    useEffect(() => {
        if (!lunchBreak) {
            return;
        }
        // START TIME
        setStartTime(lunchBreak.actualStartTime
            ? moment(lunchBreak.actualStartTime).format(TIME_FORMAT)
            : moment().format(TIME_FORMAT));
        // END TIME
        if (lunchBreak.actualEndTime) {
            setEndTime(moment(lunchBreak.actualEndTime).format(TIME_FORMAT));
        }
        else if (lunchBreak.actualStartTime) {
            setEndTime(moment().format(TIME_FORMAT));
        }
        else {
            setEndTime(undefined);
        }
        // STATUS
        if (lunchBreak.status === 'CANCELLED') {
            setIsCanceled(true);
        }
    }, [lunchBreak]);
    const goToDashBoardHandler = () => {
        history.replace('/tech/lunch-breaks');
    };
    return {
        goToDashBoardHandler,
        lunchBreak,
        isCanceled,
        isMobile,
        isLoading,
        updateLunchBreakStatus,
        endTime,
        setIsModalOpen,
        startTime,
        TIME_FORMAT,
        setStartTime,
        setEndTime,
        isModalOpen,
        goBackHandler,
        onCancelLunchBreak,
    };
};
export default useLunchBreakDetails;
