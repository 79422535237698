import React from 'react';
import styled from 'styled-components';
import { Check } from 'src/components/mui/icons';
import { formatTimestampCustom } from 'src/utils/formatter';
import { colors } from 'src/styles/theme';
import { LOCALIZED_DATE_LONG_FORMAT, TIME_ZONE_FORMAT, } from 'src/timeConstants';
const DATE_FORMAT = `${LOCALIZED_DATE_LONG_FORMAT} ${TIME_ZONE_FORMAT}`;
const TextSpan = styled.span `
  font-weight: bold;
  color: ${colors.darkGrey};
`;
const DateSpan = styled.span `
  margin-left: 26px;
  color: ${colors.darkGrey};
`;
const CheckIconStyle = {
    fontSize: '20px',
    marginRight: '6px',
    color: colors.darkGrey,
    verticalAlign: 'text-bottom',
};
const ComponentDiv = styled.div `
  font-size: 0.8rem;
`;
const AdvisorRequested = ({ requestedDate, }) => {
    return (<ComponentDiv>
      <div>
        <Check style={CheckIconStyle}/>
        <TextSpan>Call Requested</TextSpan>
      </div>
      <div>
        <DateSpan>{formatTimestampCustom(requestedDate, DATE_FORMAT)}</DateSpan>
      </div>
    </ComponentDiv>);
};
export default AdvisorRequested;
