import React from 'react';
import { CircularProgress } from 'src/components/mui';
import { Loader } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/AuthorizationScript.styles';
import AffirmFail from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/Screens/AffirmFail';
import ServiceError from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/Screens/ServiceError';
import AffirmCheckout from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/Screens/AffirmCheckout';
import { SimpleDrawer, SimpleDrawerContent } from 'src/components/SimpleDrawer';
import AuthorizationAlternative from './Screens/AuthorizationAlternative';
import AuthorizationAuthScript from './Screens/AuthorizationAuthScript';
import AuthorizationCancel from './Screens/AuthorizationConfirmCancel';
import AuthorizationFail from './Screens/AuthorizationFail';
import AuthorizationForm from './Screens/AuthorizationForm';
import AuthorizationHeyTech from './Screens/AuthorizationHeyTech';
import AuthorizationSuccess from './Screens/AuthorizationSuccess';
import AuthorizationViewCC from './Screens/AuthorizationViewCC';
import useAuthorizationScript from './useAuthorizationScript';
var Steps;
(function (Steps) {
    Steps[Steps["LOADING_SCREEN"] = 0] = "LOADING_SCREEN";
    Steps[Steps["HEY_TECH"] = 1] = "HEY_TECH";
    Steps[Steps["AUTH_SCRIPT"] = 2] = "AUTH_SCRIPT";
    Steps[Steps["AUTH_MODAL"] = 3] = "AUTH_MODAL";
    Steps[Steps["AUTH_SUCCESS"] = 4] = "AUTH_SUCCESS";
    Steps[Steps["AUTH_FAIL"] = 5] = "AUTH_FAIL";
    Steps[Steps["VIEW_CC"] = 6] = "VIEW_CC";
    Steps[Steps["AUTH_ALTERNATIVE"] = 7] = "AUTH_ALTERNATIVE";
    Steps[Steps["AFFIRM_CHECKOUT"] = 8] = "AFFIRM_CHECKOUT";
    Steps[Steps["CANCEL_CONFIRM"] = 9] = "CANCEL_CONFIRM";
    Steps[Steps["SERVICE_ERROR"] = 10] = "SERVICE_ERROR";
    Steps[Steps["AFFIRM_CHECKOUT_FAIL"] = 11] = "AFFIRM_CHECKOUT_FAIL";
})(Steps || (Steps = {}));
const AuthorizationScript = ({ open, onClose, currentTask, onCancelReschedule, consumerName, completeTask, preAuthorizationRequested, consumerId, state, workOrderId, }) => {
    const { referenceNum, currentStep, paymentData, paymentMethodId, requestAuthorization, currentState, changeStep, handleNextStep, goToAuthModal, goToAuthAlternative, goToFail, goToServiceError, handleClose, handleFail, handleViewCCFail, handleSkip, handleCancelReschedule, handleConfirmCancelReschedule, goToViewCC, handleCompleteTaskAlternative, handleSuccessReAuthorization, handleSuccess, handleBackStep, AUTHORIZATION_STEPS, } = useAuthorizationScript({
        open,
        state,
        consumerId,
        onClose,
        completeTask,
        onCancelReschedule,
        currentTask,
        preAuthorizationRequested,
        workOrderId,
    });
    const getContentComponent = () => {
        let currentStepName = 0;
        Object.entries(AUTHORIZATION_STEPS).forEach(([key, value]) => {
            if (value === currentStep) {
                currentStepName = parseInt(key, 10);
            }
        });
        switch (currentStepName) {
            case Steps.LOADING_SCREEN:
                return (<SimpleDrawerContent>
            <Loader>
              <CircularProgress size={40}/>
            </Loader>
          </SimpleDrawerContent>);
            case Steps.HEY_TECH:
                return (<AuthorizationHeyTech nextStep={handleNextStep} skipGuide={goToAuthModal} refNum={referenceNum}/>);
            case Steps.AUTH_SCRIPT:
                return (<AuthorizationAuthScript nextStep={handleNextStep} onCancelReschedule={handleConfirmCancelReschedule}/>);
            case Steps.AUTH_MODAL:
                return (<AuthorizationForm consumerName={consumerName} paymentMethodId={paymentData.id} paymentMethodType={paymentData.type} onSuccess={handleSuccess} onFail={handleFail} onSkip={handleSkip} preAuthorizationRequested={preAuthorizationRequested} currentState={currentState} requestAuthorization={requestAuthorization} onServiceError={goToServiceError}/>);
            case Steps.AUTH_SUCCESS:
                return <AuthorizationSuccess onClose={handleClose}/>;
            case Steps.AUTH_FAIL:
                return (<AuthorizationFail onViewCC={goToViewCC} onSkipPayment={goToAuthAlternative} onCancelReschedule={handleConfirmCancelReschedule}/>);
            case Steps.AUTH_ALTERNATIVE:
                return (<AuthorizationAlternative requestAuthorization={requestAuthorization} completeTask={handleCompleteTaskAlternative}/>);
            case Steps.SERVICE_ERROR:
                return (paymentMethodId && (<ServiceError requestAuthorization={requestAuthorization} paymentMethodId={paymentMethodId} onSkipPayment={goToAuthAlternative} onCancelReschedule={handleConfirmCancelReschedule} onSuccess={handleSuccessReAuthorization} onFail={goToFail}/>));
            case Steps.VIEW_CC:
                return (<AuthorizationViewCC requestAuthorization={requestAuthorization} onSuccess={handleSuccessReAuthorization} onFail={handleViewCCFail} onGoToAffirmCheckout={() => {
                        changeStep(Steps.AFFIRM_CHECKOUT);
                    }} onServiceError={goToServiceError}/>);
            case Steps.AFFIRM_CHECKOUT:
                return (<AffirmCheckout requestAuthorization={requestAuthorization} nextStep={handleSuccessReAuthorization} goToViewCC={goToViewCC} onFail={() => {
                        changeStep(Steps.AFFIRM_CHECKOUT_FAIL);
                    }}/>);
            case Steps.AFFIRM_CHECKOUT_FAIL:
                return (<AffirmFail onViewCC={goToViewCC} onSkipPayment={goToAuthAlternative} onCancelReschedule={handleConfirmCancelReschedule} onSuccess={handleSuccessReAuthorization}/>);
            case Steps.CANCEL_CONFIRM:
                return (<AuthorizationCancel backStep={handleBackStep} onCancelReschedule={handleCancelReschedule}/>);
            default:
                return <div>Wrong step buddy {currentStepName}</div>;
        }
    };
    if (!open || !currentStep) {
        return null;
    }
    return (<div>
      <SimpleDrawer open={open} onClose={handleClose} onBack={handleBackStep} disableEnforceFocus title="Authorization" arrowBack={currentStep.back !== 'close' || preAuthorizationRequested}>
        {getContentComponent()}
      </SimpleDrawer>
    </div>);
};
export default AuthorizationScript;
