import React from 'react';
import { useSelector } from 'react-redux';
import useLocksDashboard from 'src/AdminApp/containers/locks/useLocksDashboard';
import { selectDashboardFetching, selectDashboardOptimizationRuns, } from 'src/AdminApp/modules/locks/selectors';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { toast } from 'src/components/SimpleToast';
import { selectLockEditor } from 'src/modules/auth/selectors';
import DashboardFilters from './DashboardFilters';
import DashboardListItem from './DashboardListItem';
const REQUEST_COLUMNS = [
    { title: 'Region', key: 'regionName', sortable: true },
    { title: 'Date', key: 'date', sortable: true },
    { title: 'Status', key: 'status', sortable: true },
    { title: 'Created time', key: 'created', sortable: true },
    { title: 'Error', key: 'errorDetails', sortable: true },
    { title: 'Change Status', key: 'status === "ERROR"', sortable: false },
];
const LocksDashboard = () => {
    const isLockEditor = useSelector(selectLockEditor);
    const locks = useSelector(selectDashboardOptimizationRuns);
    const isFetching = useSelector(selectDashboardFetching);
    const { onSearch, onClear, resetFilters, pagination, handleUpdate, onLoadData, setPageSize, } = useLocksDashboard();
    if (!isLockEditor) {
        toast.error('ERROR - Not Authorized: You must have the "Lock_Admin" tole to view this item');
        return null;
    }
    return (<>
      <DashboardFilters onSearch={onSearch} onClear={onClear} onReset={resetFilters}/>
      <SimpleTable data={locks} pagination={pagination} sort={pagination.sort} isFetching={isFetching} columns={REQUEST_COLUMNS} renderItem={(item, index) => (<DashboardListItem item={item} key={`${index}-${item.id}`} onRecoverUpdate={handleUpdate(item, 'RECOVERED')} onIgnoredUpdate={handleUpdate(item, 'IGNORED')}/>)} onLoadData={onLoadData} onRowsPerPageChange={setPageSize}/>
    </>);
};
export default LocksDashboard;
