import React from 'react';
import styled from 'styled-components';
import { IconButton, TableCell, TableRow } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { AddCircle } from 'src/components/mui/icons';
import CatalogManufacturer from 'src/PricingApp/components/manufacturers/catalog-manufacturers/CatalogManufacturer';
import { CatalogManufacturerDrawerMode } from 'src/PricingApp/components/manufacturers/catalog-manufacturers/useCatalogManufacturerDrawer';
import { ManufacturerStatus } from 'src/PricingApp/models/enums/ManufacturerStatus';
import { colors } from 'src/styles/theme';
const PointerTableCell = styled(TableCell) `
  cursor: pointer;

  font-weight: 300;
  font-size: 15px;
  color: ${colors.GRAY_3F};
`;
const SwitchableTableCell = styled(TableCell) `
  background: ${(props) => props.disabled ? colors.GRAY_EBED : 'none'};
`;
const EditableCell = ({ children, onDoubleClick, }) => (<PointerTableCell onDoubleClick={onDoubleClick}>{children}</PointerTableCell>);
const ManufacturerListItem = ({ manufacturer, setEditableManufacturer, setEditableCatalogManufacturer, setCatalogManufacturerDrawerMode, }) => {
    var _a;
    const isDisabledManufacturer = () => manufacturer.status === 'INACTIVE';
    const handleAddCatalog = () => {
        setEditableManufacturer(manufacturer);
        setEditableCatalogManufacturer(null);
        setCatalogManufacturerDrawerMode(CatalogManufacturerDrawerMode.ADD_CATALOG);
    };
    const handleEditManufacturer = () => {
        setEditableManufacturer(manufacturer);
        setEditableCatalogManufacturer(null);
        setCatalogManufacturerDrawerMode(CatalogManufacturerDrawerMode.EDIT_MANUFACTURER);
    };
    return (<TableRow>
      <EditableCell onDoubleClick={handleEditManufacturer}>
        <b>{manufacturer.name}</b>
      </EditableCell>
      <EditableCell onDoubleClick={handleEditManufacturer}>
        {ManufacturerStatus.display(`${manufacturer.status}`)}
      </EditableCell>
      <SwitchableTableCell colSpan={2} disabled={isDisabledManufacturer()}>
        {(_a = manufacturer.catalogs) === null || _a === void 0 ? void 0 : _a.sort((a, b) => a.catalogId - b.catalogId).map((catalogManufacturer) => (<CatalogManufacturer key={catalogManufacturer.id} setEditableManufacturer={setEditableManufacturer} setEditableCatalogManufacturer={setEditableCatalogManufacturer} setCatalogManufacturerDrawerMode={setCatalogManufacturerDrawerMode} manufacturer={manufacturer} catalogManufacturer={catalogManufacturer}/>))}
      </SwitchableTableCell>
      <SwitchableTableCell colSpan={2} disabled={isDisabledManufacturer()}>
        <IconButton color="primary" edge="end" onClick={handleAddCatalog}>
          <Tooltip title="Add Catalog">
            <AddCircle />
          </Tooltip>
        </IconButton>
      </SwitchableTableCell>
    </TableRow>);
};
export default ManufacturerListItem;
