import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDoubleTap } from 'use-double-tap';
import { updateCar } from 'src/AdminApp/modules/requests/actions';
const useCarModule = ({ canEdit, consumerId, referenceNum, repairRequestId, vehicle, }) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const onDoubleClick = () => canEdit && setIsEditing(true);
    const onDoubleTap = useDoubleTap(onDoubleClick);
    const onUpdateCar = (car, updateVin = false) => {
        var _a;
        setIsEditing(false);
        return dispatch(updateCar({
            consumerCarId: (_a = vehicle.consumerCarId) !== null && _a !== void 0 ? _a : 0,
            consumerId,
            updateVin,
            referenceNum,
            vehicle: car,
            repairRequestId,
        }));
    };
    return {
        isEditing,
        setIsEditing,
        onUpdateCar,
        onDoubleClick,
        onDoubleTap,
    };
};
export default useCarModule;
