import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { getSkeduloUrl } from 'src/AdminApp/utils/url-utils';
import { CircularProgress, Divider } from 'src/components/mui';
import { CheckCircleRounded as CheckCircleRoundedIcon, ErrorRounded as ErrorRoundedIcon, } from 'src/components/mui/icons';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import useIsMobile from 'src/hooks/useIsMobile';
import { setOrderParts } from 'src/PopsApp/modules/parts/actions';
import { ConfirmationBottom, ConfirmationIcon, ConfirmationWrapper, Job, JobInfoHeader, JobPart, JobsWrapper, OrderCompleteSubtitleText, ProgressWrapper, } from './OrderCompletedDrawer.styles';
const getCopy = ({ success, allJobsOrdered, allIntegratedPartsOrdered, }) => {
    let status = 'None';
    if (success && !allIntegratedPartsOrdered) {
        status = 'PartialSuccess';
    }
    else if (success && !allJobsOrdered) {
        status = 'SuccessMoreParts';
    }
    else if (success && allIntegratedPartsOrdered) {
        status = 'Success';
    }
    else if (!success) {
        status = 'Fail';
    }
    const statuses = {
        SuccessMoreParts: {
            title: 'You’ve successfully completed your order!',
            subtitle: 'Your order has been added to the following part pickup job: ',
            bottomText: 'Some parts still need to be ordered. Click Next to select a new Vendor.',
            buttonText: 'Next',
        },
        Success: {
            title: 'You’ve successfully completed your order!',
            subtitle: 'Your order has been added to the following part pickup job: ',
            bottomText: 'All parts have been ordered!',
            buttonText: 'Next',
        },
        Fail: {
            title: 'Something went wrong and your order was not placed.',
            subtitle: 'Select a new Vendor to try again.',
            bottomText: '',
            buttonText: 'Edit Vendor',
        },
        PartialSuccess: {
            title: 'Something went wrong and some parts were not ordered.',
            subtitle: 'The ordered parts were added to the following part pickup job: ',
            buttonText: 'Move to Regular Order',
        },
        None: {},
    };
    return Object.assign(Object.assign({}, statuses[status]), { status });
};
const OrderCompletedDrawer = ({ open, onClose, integratedSkeduloId, integratedSkeduloJobName, skeduloId, skeduloJobName, allJobsOrdered, orderSuccess, editVendor, processing, integratedFailedOrderJobs, allIntegratedPartsOrdered, partPickupOrderReferenceNum, }) => {
    var _a;
    const history = useHistory();
    const { ispId, region } = useParams();
    const dispatch = useDispatch();
    const { title, subtitle, bottomText, buttonText, status } = getCopy({
        success: orderSuccess,
        allJobsOrdered,
        allIntegratedPartsOrdered: allIntegratedPartsOrdered !== null && allIntegratedPartsOrdered !== void 0 ? allIntegratedPartsOrdered : false,
    });
    const handleNext = () => {
        if (orderSuccess) {
            onClose();
            if (allJobsOrdered) {
                history.push(`/pops/regions/${region}/vans/${ispId}/jobs`);
            }
            else {
                dispatch(setOrderParts([]));
                editVendor();
            }
        }
        else {
            onClose();
            editVendor();
        }
    };
    const handleClose = () => {
        onClose();
    };
    const getPartPickUpJob = () => {
        let innerSkeduloId = '';
        let innerSkeduloName = '';
        if (status === 'Fail' || (!skeduloId && !integratedSkeduloId)) {
            return <>N/A</>;
        }
        if (partPickupOrderReferenceNum) {
            return (<a href={`${location.origin}/pops/part-pickups/${partPickupOrderReferenceNum}`} className="titlePartPickup" target="_blank" rel="noopener noreferrer">
          {partPickupOrderReferenceNum}
        </a>);
        }
        if (skeduloId) {
            innerSkeduloId = skeduloId;
            innerSkeduloName = skeduloJobName;
        }
        else if (integratedSkeduloId) {
            innerSkeduloId = integratedSkeduloId;
            innerSkeduloName = integratedSkeduloJobName;
        }
        return (<a href={getSkeduloUrl(`/job/${innerSkeduloId}/details`)} className="titleSkedulo" target="_blank" rel="noopener noreferrer">
        {innerSkeduloName !== null && innerSkeduloName !== void 0 ? innerSkeduloName : innerSkeduloId}
      </a>);
    };
    const isMobile = useIsMobile();
    const desktopPartialOrderWithSkeduloId = status === 'PartialSuccess' && isMobile && integratedSkeduloId;
    return (<SimpleDrawer open={open || processing} onClose={handleClose} title="Complete Order">
      <SimpleDrawerContent>
        {processing && (<ProgressWrapper>
            <CircularProgress />
          </ProgressWrapper>)}
        {!processing && (<>
            <ConfirmationWrapper>
              {desktopPartialOrderWithSkeduloId ? (<>
                  <OrderCompleteSubtitleText style={{ marginTop: 0 }}>
                    The ordered parts were added to the following part pickup
                    job:{' '}
                    <a href={getSkeduloUrl(`/job/${integratedSkeduloId}/details`)} className="titleSkedulo" target="_blank" rel="noopener noreferrer">
                      Skedulo ID:{' '}
                      {(_a = integratedSkeduloJobName !== null && integratedSkeduloJobName !== void 0 ? integratedSkeduloJobName : integratedSkeduloId) !== null && _a !== void 0 ? _a : 'N/A'}
                    </a>
                  </OrderCompleteSubtitleText>

                  <JobInfoHeader />
                </>) : (<>
                  <ConfirmationIcon>
                    {['SuccessMoreParts', 'Success', 'PartialSuccess'].includes(status) ? (<CheckCircleRoundedIcon className="iconSuccess"/>) : (<ErrorRoundedIcon className="iconError"/>)}
                  </ConfirmationIcon>
                  <h4>{title}</h4>
                  <p className="CompletedOrder__partPickup">
                    {subtitle}
                    {getPartPickUpJob()}
                  </p>
                </>)}

              {status === 'PartialSuccess' && (<>
                  <OrderCompleteSubtitleText>
                    The following integrated parts were not ordered:
                  </OrderCompleteSubtitleText>

                  <JobsWrapper>
                    {integratedFailedOrderJobs === null || integratedFailedOrderJobs === void 0 ? void 0 : integratedFailedOrderJobs.map((j) => (<Job key={j.jobNumber}>
                          <span className="jobTitle">Job {j.jobNumber}</span>

                          {j.parts.map((p) => (<JobPart key={p.partName}>
                              {p} <ErrorRoundedIcon className="iconError"/>
                            </JobPart>))}
                        </Job>))}
                  </JobsWrapper>

                  <OrderCompleteSubtitleText>
                    Please order the remaining parts on the Vendor site and
                    complete a Regular Order
                  </OrderCompleteSubtitleText>
                </>)}
            </ConfirmationWrapper>
            {bottomText && (<>
                <Divider />
                <ConfirmationBottom>{bottomText}</ConfirmationBottom>
              </>)}
          </>)}
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        {!processing && orderSuccess && !allJobsOrdered && (<FooterActionButton onClick={handleClose}>Resume</FooterActionButton>)}
        {processing ? null : (<FooterActionButton onClick={handleNext}>
            {buttonText}
          </FooterActionButton>)}
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
export default OrderCompletedDrawer;
