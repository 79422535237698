import React, { useRef, useState } from 'react';
import moment from 'moment';
import { ChevronLeft, ChevronRight, Group } from 'src/components/mui/icons';
import { Button } from 'src/components/mui';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import { CarouselContent, CarouselWrapper, LeftArrow, RightArrow, Slide, } from 'src/components/carousel.styles';
import SimpleChip from 'src/components/SimpleChip';
import { SimpleModal, SlickSlider } from 'src/components/layout';
import { colors } from 'src/styles/theme';
import { DATE_TIME_12H_FORMAT } from 'src/timeConstants';
import NoteContent from '../common/NoteContent';
import NoteLogo from '../common/NoteLogo';
import NoteTooltip from '../common/NoteTooltip';
import { NotesTypes } from '../constants';
import { chipColors, TypeIcon } from '../Notes.styles';
import { renderNoteContent } from '../utils';
import { NotesTableCellItem, NotesTableRowItem } from './NotesTable.styles';
const repairSmithOrigins = ['TOOLS', 'MPI'];
const NotesTableRow = ({ note, notesAppliesTo }) => {
    var _a, _b, _c;
    const entityOriginName = (_a = notesAppliesTo.find((item) => item.entityId === note.appliesTo[0].entityOriginId)) === null || _a === void 0 ? void 0 : _a.entityName;
    const [carouselOpen, setCarouselOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState();
    const photoNames = (note === null || note === void 0 ? void 0 : note.base64PhotoStrings)
        ? Object.keys(note === null || note === void 0 ? void 0 : note.base64PhotoStrings)
        : [];
    const sliderRef = useRef(null);
    const handleViewPhotos = () => {
        handleCarouselChange(null, 0);
        setCarouselOpen(true);
    };
    const handleCarouselChange = (_last, index) => {
        const img = photoNames[index];
        setCurrentImage(img);
    };
    const handleCarouselClose = () => {
        setCarouselOpen(false);
        setCurrentImage(undefined);
    };
    const sliderSettings = {
        adaptiveHeight: true,
        arrows: false,
        beforeChange: handleCarouselChange,
        dots: false,
        infinite: true,
        initialSlide: 0,
        slidesToScroll: 1,
        slidesToShow: 1,
    };
    const handlePrevImage = () => { var _a, _b; return ((_a = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _a === void 0 ? void 0 : _a.slickPrev) && ((_b = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _b === void 0 ? void 0 : _b.slickPrev()); };
    const handleNextImage = () => { var _a, _b; return ((_a = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _a === void 0 ? void 0 : _a.slickNext) && ((_b = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _b === void 0 ? void 0 : _b.slickNext()); };
    const renderAppliesTo = () => {
        var _a;
        const prefix = entityOriginName ? `${entityOriginName} - ` : '';
        const { entityName } = (_a = note === null || note === void 0 ? void 0 : note.appliesTo[0]) !== null && _a !== void 0 ? _a : '';
        const appliesToContent = entityOriginName ? (<>
        {prefix}
        <strong>{entityName}</strong>
      </>) : (`${prefix}${entityName}`);
        if ((note === null || note === void 0 ? void 0 : note.type) === NotesTypes.CONCERN_CAUSE_CORRECTION) {
            return (<>
          <div style={{ height: '13px' }}/>
          <span>Concern:</span>
          <br />
          <strong>{appliesToContent}</strong>
        </>);
        }
        return appliesToContent;
    };
    const hasPhotos = ((_b = photoNames === null || photoNames === void 0 ? void 0 : photoNames.length) !== null && _b !== void 0 ? _b : 0) > 1;
    return (<NotesTableRowItem>
      <NotesTableCellItem>
        <NoteTooltip placement="top" type={note.type} subType={note.subType}/>
      </NotesTableCellItem>
      <NotesTableCellItem style={{
            verticalAlign: (note === null || note === void 0 ? void 0 : note.type) === NotesTypes.CONCERN_CAUSE_CORRECTION
                ? 'top'
                : 'inherit',
        }}>
        {renderAppliesTo()}
      </NotesTableCellItem>
      <NotesTableCellItem className="content">
        <NoteContent note={renderNoteContent(note)}/>
        {(note === null || note === void 0 ? void 0 : note.base64PhotoStrings) && (<Button type="button" color="primary" variant="text" onClick={handleViewPhotos}>
            Attached images({photoNames.length})
          </Button>)}
      </NotesTableCellItem>
      <NotesTableCellItem>
        {repairSmithOrigins.includes(note.origin) ? (<NoteLogo width="20px"/>) : (<TypeIcon component={Group}/>)}
      </NotesTableCellItem>
      <NotesTableCellItem>
        <AdminUser subText={false} updatedUser={note.author}/>
      </NotesTableCellItem>
      <NotesTableCellItem>
        {moment
            .tz(moment.utc(note.created), 'America/Los_Angeles')
            .format(DATE_TIME_12H_FORMAT)}
      </NotesTableCellItem>

      <NotesTableCellItem>
        <SimpleChip $bgColor={chipColors[note.visibility]} label={note.visibility} $textColor={colors.white}/>
      </NotesTableCellItem>
      <SimpleModal maxWidth="lg" open={carouselOpen} onClose={handleCarouselClose} title={currentImage}>
        {(note === null || note === void 0 ? void 0 : note.base64PhotoStrings) && (<CarouselWrapper>
            <CarouselContent>
              <LeftArrow onClick={handlePrevImage}>
                <ChevronLeft />
              </LeftArrow>
              <SlickSlider {...{ sliderSettings, infinite: hasPhotos, arrows: hasPhotos }} ref={sliderRef}>
                {(_c = (photoNames !== null && photoNames !== void 0 ? photoNames : [])) === null || _c === void 0 ? void 0 : _c.map((name) => {
                return (<div key={name}>
                      <Slide>
                        <img alt={name} src={note.base64PhotoStrings[name]}/>
                      </Slide>
                    </div>);
            })}
              </SlickSlider>
              <RightArrow onClick={handleNextImage}>
                <ChevronRight />
              </RightArrow>
            </CarouselContent>
          </CarouselWrapper>)}
      </SimpleModal>
    </NotesTableRowItem>);
};
export default NotesTableRow;
