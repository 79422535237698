import React from 'react';
import styled from 'styled-components';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
const AccordionTitle = styled(Typography) `
  font-size: 16px;
  color: ${colors.GRAY_76};
`;
const FilterBlock = ({ children }) => {
    return (<Grid container style={{ alignContent: 'flex-end', marginBottom: 12 }} spacing={4}>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <AccordionTitle>Filters</AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {children}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>);
};
export default FilterBlock;
