import React from 'react';
export const PartsIcon = () => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path id="iahyqi4hxa" d="M0 0h24v24H0z"/>
        <path d="M2.562.456a.867.867 0 0 0-.33-.2.405.405 0 0 0-.42.67c.076.076.076.2 0 .277l-.314.312a.193.193 0 0 1-.278 0 .405.405 0 0 0-.667.425.86.86 0 0 0 .198.322c.366.365.903.462 1.358.29l1.569 1.565a.516.516 0 1 0 .73-.73L2.849 1.83A1.276 1.276 0 0 0 2.562.456z" id="xd0ywm5kyc"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="s6e6pg5zmb" fill="#fff">
          <use xlinkHref="#iahyqi4hxa"/>
        </mask>
        <g mask="url(#s6e6pg5zmb)">
          <path d="M10.672 21.57V9.471l2.387-1.061 5.23-2.325 1.299-.577v6.606a.44.44 0 0 0 .878 0V4.548L10.233 0 0 4.548v13.629l10.055 4.469.178.08.179-.08 6.329-2.851-.827-.555-5.242 2.33zM10.232.96l8.714 3.872-1.232.548-8.713-3.873 1.232-.547zM7.922 1.99l8.712 3.872-3.347 1.488-8.713-3.873L7.92 1.99zm1.873 19.58L.878 17.607V5.509l8.916 3.963v12.097zm.439-12.863L1.52 4.834l2.022-.899-.017.038 8.68 3.857-1.972.876z" stroke="#3F3F3F" strokeWidth=".3" fill="#3F3F3F" fillRule="nonzero"/>
          <path d="M18.756 11.878a4.249 4.249 0 0 0-4.244 4.244 4.249 4.249 0 0 0 4.244 4.244A4.249 4.249 0 0 0 23 16.122a4.249 4.249 0 0 0-4.244-4.244zm0 7.317a3.076 3.076 0 0 1-3.073-3.073 3.076 3.076 0 0 1 3.073-3.073 3.076 3.076 0 0 1 3.073 3.073 3.076 3.076 0 0 1-3.073 3.073z" stroke="#FFF" strokeWidth=".3" fill="#3F3F3F" fillRule="nonzero"/>
          <g transform="translate(16.39 13.756)">
            <path d="M0 0h7.024v7.024H0z"/>
            <use fill="#3F3F3F" fillRule="nonzero" xlinkHref="#xd0ywm5kyc"/>
          </g>
        </g>
      </g>
    </svg>);
};
