var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable eqeqeq */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { BarcodeReader } from 'dynamsoft-javascript-barcode';
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Button } from 'src/components/mui';
import styled from 'styled-components';
import './VideoProcessor.css';
import { TextField } from 'src/AdminApp/components/form';
import { Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import Loader from 'src/FleetVisitsApp/pages/FleetVisits/components/Loader';
import { getQuoteDiagnosis, loadRequest, } from 'src/AdminApp/modules/requests/actions';
import VideoDecode from '../components/VideoDecode';
const s = styled;
const VideoProcessor = () => {
    const [roFound, setRoFound] = useState(false);
    const [active, setActive] = useState(false);
    const [codes, setCodes] = useState(new Map());
    const [repairRequest, setRepairRequest] = useState({});
    const [relatedParts, setRelatedParts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [counts, setCounts] = useState({ scanned: 0, matched: 0 });
    const dispatch = useDispatch();
    const referenceNum = 'AA0893832';
    const initialValues = {
        referenceNumber: '',
    };
    const initializeScanner = () => __awaiter(void 0, void 0, void 0, function* () {
        console.info(BarcodeReader.license);
        if (!BarcodeReader.license) {
            BarcodeReader.license =
                'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyMjM2NTI0LVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAyMjM2NTI0Iiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2Rscy5keW5hbXNvZnRvbmxpbmUuY29tIiwiY2hlY2tDb2RlIjotMTU3ODEyNjA3fQ==';
            BarcodeReader.engineResourcePath =
                'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.21/dist/';
        }
        try {
            yield BarcodeReader.loadWasm();
        }
        catch (ex) {
            if (ex.message.indexOf('network connection error')) {
                const customMsg = 'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
                console.error(customMsg);
            }
            throw ex;
        }
    });
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        // Handle form submission here
        if (!values.referenceNumber)
            return;
        setIsLoading(true);
        const requestResponse = yield dispatch(loadRequest(values.referenceNumber));
        if (requestResponse.error) {
            window.alert(`Repair Request Not Found: ${values.referenceNum}`); // eslint-disable-line no-alert
        }
        else {
            const diagnosis = yield dispatch(getQuoteDiagnosis(referenceNum, requestResponse.id));
            const relParts = [];
            (_c = (_b = (_a = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.payload) === null || _a === void 0 ? void 0 : _a.diagnosisServices) === null || _b === void 0 ? void 0 : _b.knownRepairs) === null || _c === void 0 ? void 0 : _c.forEach((kp) => {
                var _a;
                const row = {};
                row.repairName = kp.name;
                row.relatedParts = [];
                (_a = kp.price) === null || _a === void 0 ? void 0 : _a.forEach((p) => {
                    var _a, _b;
                    (_a = p.fluids) === null || _a === void 0 ? void 0 : _a.forEach((fluid) => {
                        var _a;
                        row.relatedParts.push({
                            partNumber: fluid.partNumber,
                            name: fluid.name,
                            quantity: (_a = fluid.quantity) !== null && _a !== void 0 ? _a : 1,
                            scanned: 0,
                        });
                    });
                    (_b = p.parts) === null || _b === void 0 ? void 0 : _b.forEach((part) => {
                        var _a;
                        row.relatedParts.push({
                            partNumber: part.partNumber,
                            name: part.name,
                            quantity: (_a = part.quantity) !== null && _a !== void 0 ? _a : 1,
                            scanned: 0,
                        });
                    });
                });
                relParts.push(row);
            });
            setRepairRequest(requestResponse);
            setRelatedParts(relParts !== null && relParts !== void 0 ? relParts : []);
            setRoFound(true);
            setActive(true);
        }
        setIsLoading(false);
    });
    const cancel = (e) => {
        console.warn(e);
        setRoFound(false);
        setRepairRequest({});
        setRelatedParts([new Map()]);
        setActive(false);
        setCodes(new Map());
        setCounts({ scanned: 0, matched: 0 });
    };
    const processResult = useCallback((result) => {
        setCounts((prevCounts) => (Object.assign(Object.assign({}, prevCounts), { scanned: prevCounts.scanned + 1 })));
        const key = `${result.barcodeFormatString}:${result.barcodeText}`;
        if (codes.has(key)) {
            const scanResult = codes.get(key);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            scanResult.count += 1;
        }
        else {
            codes.set(key, {
                format: result.barcodeFormatString,
                code: result.barcodeText,
                count: 1,
            });
        }
        const relatedPartsInmutable = [...relatedParts];
        relatedPartsInmutable === null || relatedPartsInmutable === void 0 ? void 0 : relatedPartsInmutable.every((it) => {
            var _a;
            let found = false;
            (_a = it.relatedParts) === null || _a === void 0 ? void 0 : _a.every((part) => {
                if (part.partNumber == result.barcodeText) {
                    if (part.scanned == part.quantity) {
                        window.alert(`${part.partNumber} quantity reached`);
                        return false;
                    }
                    part.scanned += 1;
                    part.ok = part.scanned == part.quantity;
                    found = true;
                    setCounts((prevCounts) => (Object.assign(Object.assign({}, prevCounts), { matched: prevCounts.matched + 1 })));
                    return false;
                }
                return true;
            });
            if (!found)
                window.alert(`${result.barcodeText} not found`);
            return !found;
        });
        setCodes(new Map(codes));
        setRelatedParts(relatedPartsInmutable);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setCodes, setRelatedParts, relatedParts, setCounts]);
    useEffect(() => {
        initializeScanner();
    }, []);
    return (<div className="container">
      <ScanContainer>
        {!active && (<img alt="special" src="img/png/camera.png" style={{ width: '350px', height: '350px' }}/>)}
        {active && <VideoDecode processResultCb={processResult}/>}
      </ScanContainer>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          {!roFound ? (<Grid container style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <GridItem item xl>
                <Field fullWidth id="referenceNumber" name="referenceNumber" label="Reference Number" as={TextField} variant="standard"/>
              </GridItem>
              <GridItem item xs={4}>
                <Button type="submit" variant="contained" color="primary">
                  Scan Parts
                </Button>
              </GridItem>
            </Grid>) : (<Grid container style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <GridItem item xl={12}>
                <Button type="submit" variant="contained" color="primary">
                  Done
                </Button>
                <Button type="reset" variant="contained" onClick={cancel}>
                  Cancel
                </Button>
              </GridItem>
            </Grid>)}
        </Form>
      </Formik>
      {isLoading && <Loader />}
      {roFound && (<article>
          <h2>{repairRequest.referenceNum}</h2>
          <Grid container style={{ justifyContent: 'flex-end' }}>
            <div>Scanned Items: {counts.scanned}</div>
          </Grid>
          <Grid container style={{ justifyContent: 'flex-end' }}>
            <div>Matched {counts.matched}</div>
          </Grid>
          {relatedParts === null || relatedParts === void 0 ? void 0 : relatedParts.map((row, index) => {
                var _a;
                return (<div key={index}>
              <div>
                <h3>
                  <label>{index + 1}. </label> {row.repairName}
                </h3>
              </div>
              <div>
                {(_a = row.relatedParts) === null || _a === void 0 ? void 0 : _a.map((rp, ind) => {
                        var _a, _b;
                        return (<Grid container style={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }} key={`${ind} ${index}`}>
                    <GridItem item xl>
                      {rp.name} - {(_a = rp.partNumber) !== null && _a !== void 0 ? _a : ''}
                    </GridItem>
                    <GridItem item xs={2}>
                      <label style={{
                                fontWeight: 'bold',
                                color: rp.ok ? '#28B421' : '#B12A27',
                            }}>
                        {(_b = rp.scanned) !== null && _b !== void 0 ? _b : 0} of {rp.quantity}
                      </label>
                    </GridItem>
                  </Grid>);
                    })}
              </div>
            </div>);
            })}
        </article>)}
      <br />
      <div>POC V2</div>
    </div>);
};
export default VideoProcessor;
const ScanContainer = styled('div') `
  width: 100%;
  height: 350px;
  text-align: center;
`;
const GridItem = s(Grid).attrs({ item: true }) `
  padding: 5px;
`;
