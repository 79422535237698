import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadCurrentUser } from 'src/AdminApp/modules/users/actions';
import { selectCurrentUserIsEngineering, selectCurrentUserIsProduct, } from 'src/AdminApp/modules/users/selectors';
import SimpleChip from 'src/components/SimpleChip';
import { selectPopsManualOrderViewer } from 'src/modules/auth/selectors';
import { ChipButton } from 'src/PopsApp/components/VansFilter/FilterDialog.styles';
import { colors, FONT_FAMILY, theme } from 'src/styles/theme';
import styled from 'styled-components';
const Widget = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${theme.breakpoints.up('md')} {
    flex: 1;
  }

  .title {
    font-size: 12px;
    line-height: 2.33;
    margin: 0 0 8px;
    padding: 0;

    ${theme.breakpoints.up('md')} {
      font-size: 14px;
    }
  }

  .counter {
    font-family: ${FONT_FAMILY};
    font-size: 36px;
    margin: 0 0 24px;

    ${theme.breakpoints.up('md')} {
      font-size: 40px;
    }
  }

  .button {
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
    background-color: ${colors.BLUE};
    font-size: 16px;
    color: ${colors.white};
    font-weight: 700;
    padding: 16px 0;
    width: 163px;
    border: none;
    cursor: pointer;

    ${theme.breakpoints.up('md')} {
      width: 144px;
    }
  }

  .shift-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 163px;

    div {
      margin-right: 0;
    }

    ${theme.breakpoints.up('md')} {
      width: 144px;
    }
  }

  .old-view-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    div {
      margin-right: 10px;
    }

    ${theme.breakpoints.up('md')} {
      width: 144px;
    }
  }
`;
const PartWidget = ({ actionTitle, mapUrl }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isDayShift, setIsDayShift] = useState(true);
    const onAction = () => {
        const shift = isDayShift ? 'day' : 'night';
        const url = mapUrl(shift);
        history.push(url);
    };
    const canViewManualOrder = useSelector(selectPopsManualOrderViewer);
    const currentUserIsEngineering = useSelector(selectCurrentUserIsEngineering);
    const currentUserIsProduct = useSelector(selectCurrentUserIsProduct);
    useEffect(() => {
        dispatch(loadCurrentUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<Widget>
      <button className="button" type="button" onClick={onAction}>
        {actionTitle}
      </button>
      <div className="shift-container">
        <ChipButton>
          <SimpleChip label="Day" className={isDayShift ? 'chipSelected' : ''} onClick={() => setIsDayShift(true)} size="medium" $textColor={isDayShift ? colors.white : colors.darkGrey} $bgColor={isDayShift ? colors.BLUE : colors.lightGrey}/>
        </ChipButton>
        <ChipButton>
          <SimpleChip label="Night" className={!isDayShift ? 'chipSelected' : ''} onClick={() => setIsDayShift(false)} size="medium" $textColor={!isDayShift ? colors.white : colors.darkGrey} $bgColor={!isDayShift ? colors.BLUE : colors.lightGrey}/>
        </ChipButton>
      </div>
      <div className="old-view-container">
        {canViewManualOrder && (<ChipButton>
            <SimpleChip label="Manual Order" onClick={() => history.push('/pops/regions')} $textColor={colors.darkGrey} size="medium"/>
          </ChipButton>)}
        {(currentUserIsEngineering || currentUserIsProduct) && (<ChipButton>
            <SimpleChip label="Order Roster" onClick={() => history.push('/pops/order-roster/day')} $textColor={colors.darkGrey} size="medium"/>
          </ChipButton>)}
      </div>
    </Widget>);
};
export default PartWidget;
