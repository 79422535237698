var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { IconButton } from '@mui/material';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import PayerSummary from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/PayerSummary';
import { InvoiceName, PriceBreakdownInputField, PriceBreakdownLaborField, PriceBreakdownPriceField, PriceBreakdownTaxInputField, } from 'src/AdminApp/containers/requests/fieldComponents';
import useInvoiceForm from 'src/AdminApp/containers/requests/Invoice/InvoiceForm/useInvoiceForm';
import { FieldDivider, InvoiceFormWrapper, Total, } from 'src/AdminApp/containers/requests/styledComponents';
import { Grid } from 'src/components/mui';
import { GetApp } from 'src/components/mui/icons';
import * as Yup from 'yup';
import CalculateTaxCTA from './CalculateTaxCTA';
import TotalSublet from './TotalSublet';
import { calculateTaxes } from './utils';
const InvoiceSchema = Yup.object().shape({
    hazardousMaterialsFee: Yup.number().min(0).nullable(),
    deliveryFee: Yup.number().min(0).nullable(),
    cancelFee: Yup.number().min(0).nullable(),
    laborTaxRate: Yup.number().max(99.99).min(0).nullable(),
    laborTotalPrice: Yup.number().min(0).nullable(),
    partsTaxRate: Yup.number().max(99.99).min(0).nullable(),
    partsTotalPrice: Yup.number().min(0).nullable(),
    shopSuppliesFee: Yup.number().min(0).nullable(),
    adjustments: Yup.number().negative().max(0).nullable(),
    totalPrice: Yup.number().typeError('Price must include a number').nullable(),
    totalSublet: Yup.number().min(0).nullable(),
    totalFees: Yup.number().min(0).nullable(),
    subtotal: Yup.number().min(0).nullable(),
    totalTaxes: Yup.number().min(0).nullable(),
    discounts: Yup.number().nullable(),
});
const hasNoServices = (invoice) => {
    var _a, _b, _c;
    return isEmpty((_a = invoice.diagnosisServices) === null || _a === void 0 ? void 0 : _a.customRequests) &&
        isEmpty((_b = invoice.diagnosisServices) === null || _b === void 0 ? void 0 : _b.diagnoses) &&
        isEmpty((_c = invoice.diagnosisServices) === null || _c === void 0 ? void 0 : _c.knownRepairs);
};
const hasTotalPriceEqualsCancelFee = (invoice) => !invoice.totalPrice || invoice.totalPrice === invoice.cancelFee;
const hasNoFees = (invoice) => !invoice.deliveryFee &&
    !invoice.shopSuppliesFee &&
    !invoice.hazardousMaterialsFee;
const hasNoTaxes = (invoice) => !invoice.adjustments &&
    (!invoice.laborTaxAmount || invoice.laborTaxAmount === '0.00') &&
    (!invoice.laborTaxRate || invoice.laborTaxRate === '0.00') &&
    (!invoice.partsTaxAmount || invoice.partsTaxAmount === '0.00') &&
    (!invoice.partsTaxRate || invoice.partsTaxRate === '0.00');
const isCancelFeeApplicable = (invoice) => hasNoServices(invoice) &&
    hasTotalPriceEqualsCancelFee(invoice) &&
    hasNoFees(invoice) &&
    hasNoTaxes(invoice);
const hasMultipleResponsibleParty = (invoice) => {
    var _a, _b, _c, _d, _e, _f;
    return ((_b = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) === null || _b === void 0 ? void 0 : _b.some((k) => k === null || k === void 0 ? void 0 : k.hasMoreThanOneResponsibleParty)) ||
        ((_d = (_c = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _c === void 0 ? void 0 : _c.diagnoses) === null || _d === void 0 ? void 0 : _d.some((d) => d === null || d === void 0 ? void 0 : d.hasMoreThanOneResponsibleParty)) ||
        ((_f = (_e = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _e === void 0 ? void 0 : _e.customRequests) === null || _f === void 0 ? void 0 : _f.some((c) => c === null || c === void 0 ? void 0 : c.hasMoreThanOneResponsibleParty));
};
const InvoiceForm = ({ canEdit, orderId, setIsEditing, workOrderId, locked = false, }) => {
    const { mainInvoice, handleInvoiceSubmit, setCurrentEditField, totalJobDurationMinutes, totalLaborDuration, currentEditField, handleRemoveSublet, onDownloadInvoiceClick, isInvoiceDownloading, notMainInvoices, totalPriceOverrideEnabled, } = useInvoiceForm({
        workOrderId,
        orderId,
        setIsEditing,
    });
    return (<InvoiceFormWrapper>
      <Formik initialValues={mainInvoice} enableReinitialize validationSchema={InvoiceSchema} onSubmit={(values, { setSubmitting, resetForm }) => __awaiter(void 0, void 0, void 0, function* () {
            if (!mainInvoice)
                return;
            yield handleInvoiceSubmit(calculateTaxes(values, mainInvoice));
            resetForm({ values: Object.assign({}, values) });
            setSubmitting(false);
        })}>
        {({ handleSubmit, values, resetForm }) => {
            var _a, _b, _c, _d;
            const cancelHandler = () => {
                setCurrentEditField(undefined);
                resetForm();
            };
            const submitHandler = () => {
                handleSubmit();
                setCurrentEditField(undefined);
            };
            const editHandler = (field) => () => {
                resetForm();
                setCurrentEditField(field);
            };
            const isEditable = (mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.cancelFee) === 0 && !locked;
            const totalJobDuration = totalJobDurationMinutes
                ? Math.round((totalJobDurationMinutes / 60) * 100) / 100
                : 0.0;
            return (mainInvoice && (<Grid container>
                <Grid item xs={12}>
                  <Total>
                    <PriceBreakdownLaborField leftPart={`repairs: ${totalLaborDuration || 0.0}h - job: ${totalJobDuration}h`} label="Labors">
                      {mainInvoice.laborTotalPrice}
                    </PriceBreakdownLaborField>
                    <PriceBreakdownPriceField label="Parts">
                      {mainInvoice.partsTotalPrice}
                    </PriceBreakdownPriceField>
                    <PriceBreakdownInputField edit={currentEditField === 'shopSuppliesFee'} onEdit={editHandler('shopSuppliesFee')} onSubmit={submitHandler} onCancel={cancelHandler} label="Shop Supplies Fee" name="shopSuppliesFee" initialValue={mainInvoice.shopSuppliesFee} editable={isEditable}/>
                    <PriceBreakdownInputField edit={currentEditField === 'hazardousMaterialsFee'} onEdit={editHandler('hazardousMaterialsFee')} onSubmit={submitHandler} onCancel={cancelHandler} label="Environmental Fee" name="hazardousMaterialsFee" initialValue={mainInvoice.hazardousMaterialsFee} editable={isEditable}/>
                    <PriceBreakdownInputField edit={currentEditField === 'deliveryFee'} onEdit={editHandler('deliveryFee')} onSubmit={submitHandler} onCancel={cancelHandler} label="Mobile Service Charge" name="deliveryFee" initialValue={mainInvoice.deliveryFee} editable={isEditable}/>
                    <PriceBreakdownInputField edit={currentEditField === 'cancelFee'} onEdit={editHandler('cancelFee')} onSubmit={submitHandler} onCancel={cancelHandler} label="Cancel Fee" name="cancelFee" initialValue={mainInvoice.cancelFee} editable={isCancelFeeApplicable(mainInvoice) && !locked}/>
                    <PriceBreakdownPriceField label="Total Fees">
                      {mainInvoice.totalFees}
                    </PriceBreakdownPriceField>
                    {(mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.depositAmount) && (<PriceBreakdownPriceField label="Booking Deposit">
                        {mainInvoice.depositAmount}
                      </PriceBreakdownPriceField>)}
                    <FieldDivider />
                    <PriceBreakdownPriceField label="Subtotal">
                      {mainInvoice.subtotal}
                    </PriceBreakdownPriceField>
                    <PriceBreakdownPriceField label="Discounts">
                      {-((_a = mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.discounts) !== null && _a !== void 0 ? _a : 0)}
                    </PriceBreakdownPriceField>
                    <PriceBreakdownInputField edit={currentEditField === 'adjustments'} onEdit={editHandler('adjustments')} onSubmit={submitHandler} onCancel={cancelHandler} label="Adjustments" name="adjustments" initialValue={mainInvoice.adjustments} editable={false}/>
                    <PriceBreakdownTaxInputField edit={currentEditField === 'partsTax'} onEdit={editHandler('partsTax')} onSubmit={submitHandler} onCancel={cancelHandler} label="Parts Taxes" name="partsTax" initial={mainInvoice} values={values} editable={isEditable}/>
                    <PriceBreakdownTaxInputField edit={currentEditField === 'laborTax'} onEdit={editHandler('laborTax')} onSubmit={submitHandler} onCancel={cancelHandler} label="Labor Taxes" name="laborTax" initial={mainInvoice} values={values} editable={isEditable}/>
                    {canEdit && (<CalculateTaxCTA invoice={mainInvoice} workOrderId={workOrderId}/>)}
                    <FieldDivider />

                    <PriceBreakdownPriceField label="Total Taxes">
                      {mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.totalTaxes}
                    </PriceBreakdownPriceField>

                    <TotalSublet invoice={mainInvoice} onSubletRemove={handleRemoveSublet}/>

                    <PriceBreakdownPriceField large label="Total">
                      {mainInvoice.totalPrice}
                    </PriceBreakdownPriceField>

                    <FieldDivider />
                    <>
                      {mainInvoice && (<PayerSummary totalText={<>
                              <IconButton onClick={() => {
                            if (mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.s3Url) {
                                window.open(mainInvoice.s3Url, '_blank');
                            }
                            else {
                                onDownloadInvoiceClick(mainInvoice);
                            }
                        }} disabled={isInvoiceDownloading} title="Click to Download Invoice">
                                <GetApp color={isInvoiceDownloading
                            ? 'disabled'
                            : 'primary'}/>
                              </IconButton>
                              <span>
                                #{mainInvoice.id}{' '}
                                {(_d = (_c = (_b = mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.workOrderResponsibleParty) === null || _b === void 0 ? void 0 : _b.responsibleParty) === null || _c === void 0 ? void 0 : _c.responsiblePartyType) === null || _d === void 0 ? void 0 : _d.name}
                              </span>
                            </>} totalPrice={mainInvoice.totalPrice}/>)}

                      {hasMultipleResponsibleParty(mainInvoice) &&
                    notMainInvoices &&
                    (notMainInvoices === null || notMainInvoices === void 0 ? void 0 : notMainInvoices.map((invoice) => {
                        var _a, _b, _c, _d, _e;
                        return (<PayerSummary totalText={<InvoiceName>
                                <IconButton onClick={() => {
                                    if (invoice === null || invoice === void 0 ? void 0 : invoice.s3Url) {
                                        window.open(invoice.s3Url, '_blank');
                                    }
                                    else {
                                        onDownloadInvoiceClick(invoice);
                                    }
                                }} disabled={isInvoiceDownloading} title="Click to Download Invoice">
                                  <GetApp color={isInvoiceDownloading
                                    ? 'disabled'
                                    : 'primary'}/>
                                </IconButton>
                                <span>#{invoice.id}</span>&nbsp;
                                <span>
                                  [
                                  {(_c = (_b = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.workOrderResponsibleParty) === null || _a === void 0 ? void 0 : _a.responsibleParty) === null || _b === void 0 ? void 0 : _b.responsiblePartyType) === null || _c === void 0 ? void 0 : _c.name}
                                  ]{' '}
                                  {(_e = (_d = invoice === null || invoice === void 0 ? void 0 : invoice.workOrderResponsibleParty) === null || _d === void 0 ? void 0 : _d.responsibleParty) === null || _e === void 0 ? void 0 : _e.name}
                                </span>
                              </InvoiceName>} totalPrice={invoice.totalPrice}/>);
                    }))}
                    </>
                    {totalPriceOverrideEnabled && (<PriceBreakdownInputField large edit={currentEditField === 'totalPriceOverride'} onEdit={editHandler('totalPriceOverride')} onSubmit={submitHandler} onCancel={cancelHandler} initialValue={mainInvoice.totalPriceOverride} label="Invoice Override" name="totalPriceOverride" editable={isEditable}/>)}
                  </Total>
                </Grid>
              </Grid>));
        }}
      </Formik>
    </InvoiceFormWrapper>);
};
export default InvoiceForm;
