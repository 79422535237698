export var UnlockReasons;
(function (UnlockReasons) {
    UnlockReasons["PRICING_CORRECTION"] = "Pricing Correction";
    UnlockReasons["COST_CORRECTION"] = "Cost Correction";
    UnlockReasons["REPAIR_CORRECTION"] = "Repair Correction";
    UnlockReasons["PART_DETAIL_ADJUSTMENT"] = "Part Detail Adjustment";
    UnlockReasons["LABOR_CORRECTION"] = "Labor Correction";
    UnlockReasons["FMC_FLEET_ISSUE"] = "FMC/Fleet Issue";
    UnlockReasons["PARTNERSHIP_ISSUE"] = "Partnership Issue";
    UnlockReasons["UPDATE_INTERNAL_NOTES"] = "Update Internal Notes";
    UnlockReasons["UPDATE_VEHICLE_INFORMATION"] = "Update Vehicle Information";
})(UnlockReasons || (UnlockReasons = {}));
export default UnlockReasons;
