import React from 'react';
import { formatPhone } from 'src/utils/formatter';
import { Edit as EditIcon } from 'src/components/mui/icons';
import { SimpleTableRow } from 'src/components/dashboard/SimpleTable';
const DashboardListItem = ({ id, firstName, lastName, email, phoneNumber, userType, onEdit, }) => (<SimpleTableRow data={[
        id,
        firstName,
        lastName,
        email,
        formatPhone(phoneNumber),
        userType,
        <EditIcon onClick={() => onEdit(id)} style={{ cursor: 'pointer' }}/>,
    ]}/>);
export default DashboardListItem;
