import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import { formatTimestamp } from 'src/utils/formatter';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
const PricingComponentsDashboardListItem = ({ item }) => {
    const { id, name, type, unit, pcdbId, created, modifiedBy } = item;
    return (<SimpleTableRow data={[
            type === 'LABOR' ? (<div>{id}</div>) : (<Link to={`/pricing/components/${id}/edit`}>{id}</Link>),
            type === 'LABOR' ? (<div>{name}</div>) : (<Link to={`/pricing/components/${id}/edit`}>{name}</Link>),
            capitalize(type),
            unit,
            pcdbId,
            formatTimestamp(created),
            <AdminUser subText={false} updatedUser={modifiedBy}/>,
        ]}/>);
};
export default PricingComponentsDashboardListItem;
