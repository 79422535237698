import createAction from 'src/modules/api/utils/createAction';
export const ActionCreators = {
    SET_PAYMENT_DRAWER_INVOICE: createAction('SET_PAYMENT_DRAWER_INVOICE'),
    SET_PAYMENT_DRAWER_SCREEN: createAction('SET_PAYMENT_DRAWER_SCREEN'),
    SET_PAYMENT_DRAWER_TAB: createAction('SET_PAYMENT_DRAWER_TAB'),
    SET_PAYMENT_DRAWER_AMOUNT: createAction('SET_PAYMENT_DRAWER_AMOUNT'),
    SET_PAYMENT_DRAWER_BALANCE_OWED: createAction('SET_PAYMENT_DRAWER_BALANCE_OWED'),
    SET_PAYMENT_DRAWER_PAYMENTS_RECEIVED_TOTAL: createAction('SET_PAYMENT_DRAWER_PAYMENTS_RECEIVED_TOTAL'),
    SET_PAYMENT_DRAWER_PAYMENT_TYPE: createAction('SET_PAYMENT_DRAWER_PAYMENT_TYPE'),
    SET_PAYMENT_DRAWER_PAYMENT_SUBTYPE: createAction('SET_PAYMENT_DRAWER_PAYMENT_SUBTYPE'),
    SET_PAYMENT_DRAWER_WORK_ORDER_RESPONSIBLE_PARTY: createAction('SET_PAYMENT_DRAWER_WORK_ORDER_RESPONSIBLE_PARTY'),
    SET_PAYMENT_DRAWER_CLASSES: createAction('SET_PAYMENT_DRAWER_CLASSES'),
    SET_PAYMENT_DRAWER_WORK_ORDER_PAYMENTS: createAction('SET_PAYMENT_DRAWER_WORK_ORDER_PAYMENTS'),
    SET_PAYMENT_DRAWER_WORK_ORDER_ID: createAction('SET_PAYMENT_DRAWER_WORK_ORDER_ID'),
    SET_PAYMENT_DRAWER_RESPONSIBLE_PARTIES: createAction('SET_PAYMENT_DRAWER_RESPONSIBLE_PARTIES'),
    SET_PAYMENT_DRAWER_TOTAL_INVOICE_PRICE: createAction('SET_PAYMENT_DRAWER_TOTAL_INVOICE_PRICE'),
    SET_PAYMENT_DRAWER_INVOICES: createAction('SET_PAYMENT_DRAWER_INVOICES'),
    SET_PAYMENT_DRAWER_HAS_RESPONSIBLE_PARTIES: createAction('SET_PAYMENT_DRAWER_HAS_RESPONSIBLE_PARTIES'),
    SET_PAYMENT_DRAWER_WORK_ORDER_RESPONSIBLE_PARTIES: createAction('SET_PAYMENT_DRAWER_WORK_ORDER_RESPONSIBLE_PARTIES'),
};
export const setPaymentDrawerInvoice = ActionCreators.SET_PAYMENT_DRAWER_INVOICE;
export const setPaymentDrawerScreen = ActionCreators.SET_PAYMENT_DRAWER_SCREEN;
export const setPaymentDrawerTab = ActionCreators.SET_PAYMENT_DRAWER_TAB;
export const setPaymentDrawerAmount = ActionCreators.SET_PAYMENT_DRAWER_AMOUNT;
export const setPaymentDrawerBalanceOwed = ActionCreators.SET_PAYMENT_DRAWER_BALANCE_OWED;
export const setPaymentDrawerPaymentsReceivedTotal = ActionCreators.SET_PAYMENT_DRAWER_PAYMENTS_RECEIVED_TOTAL;
export const setPaymentDrawerPaymentType = ActionCreators.SET_PAYMENT_DRAWER_PAYMENT_TYPE;
export const setPaymentDrawerPaymentSubtype = ActionCreators.SET_PAYMENT_DRAWER_PAYMENT_SUBTYPE;
export const setPaymentDrawerWorkOrderResponsibleParty = ActionCreators.SET_PAYMENT_DRAWER_WORK_ORDER_RESPONSIBLE_PARTY;
export const setPaymentDrawerWorkOrderPayments = ActionCreators.SET_PAYMENT_DRAWER_WORK_ORDER_PAYMENTS;
export const setPaymentDrawerWorkOrderId = ActionCreators.SET_PAYMENT_DRAWER_WORK_ORDER_ID;
export const setPaymentDrawerResponsibleParties = ActionCreators.SET_PAYMENT_DRAWER_RESPONSIBLE_PARTIES;
export const setPaymentDrawerTotalInvoicePrice = ActionCreators.SET_PAYMENT_DRAWER_TOTAL_INVOICE_PRICE;
export const setPaymentDrawerHasResponsibleParties = ActionCreators.SET_PAYMENT_DRAWER_HAS_RESPONSIBLE_PARTIES;
// TODO: setPaymentDrawerWorkOrderResponsibleParties
export const setPaymentDrawerWorkOrderResponsibleParties = ActionCreators.SET_PAYMENT_DRAWER_WORK_ORDER_RESPONSIBLE_PARTIES;
