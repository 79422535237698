import { colors } from 'src/styles/theme';
import styled, { css } from 'styled-components';
const transitionProps = css `
  transition:
    0.3s color ease-in-out,
    0.3s background-color ease-in-out;
  will-change: color, background-color;
`;
export const ModeSwitcherContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-right: 20px;
  border-radius: 4px;
  background-color: ${colors.white};
  border: 1px solid ${colors.GRAY_CC};
  ${transitionProps}

  &:hover {
    border-color: ${colors.primary};
  }
`;
const getButtonBackgroundColor = ({ active, disabled, }) => {
    const variant = 0 + (active ? 1 : 0) + (disabled ? 2 : 0);
    const cl = [colors.white, colors.primary, colors.GRAY_F2, colors.GRAY_76];
    return cl[variant];
};
export const ModeOption = styled.div `
  padding: 15px 20px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ active, disabled }) => getButtonBackgroundColor({ active, disabled })};
  color: ${({ active }) => (active ? colors.white : colors.GRAY_3F)};
  ${transitionProps}
`;
