var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/AdminApp/modules/api/urls';
import createDefaultActionCreators from 'src/utils/createActions';
import { MarketingSourceVisibility } from 'src/AdminApp/models/enums/MarketingSourceVisibility';
import { selectPaymentTypeIdByName } from './selectors';
export const ActionCreators = {
    GET_DISCOUNT_REASON_TYPES: createDefaultActionCreators('GET_DISCOUNT_REASON_TYPES'),
    LOAD_MARKETING_SOURCES: createDefaultActionCreators('LOAD_MARKETING_SOURCES'),
    LOAD_PAYMENT_TYPES: createDefaultActionCreators('LOAD_PAYMENT_TYPES'),
    LOAD_PAYMENT_SUB_TYPES: createDefaultActionCreators('LOAD_PAYMENT_SUB_TYPES'),
    GET_VENDOR_ADDRESS_AUTOCOMPLETE: createDefaultActionCreators('GET_VENDOR_ADDRESS_AUTOCOMPLETE'),
    GET_ALL_REPAIRS: createDefaultActionCreators('GET_ALL_REPAIRS'),
};
export const loadMarketingSources = (visibilityLevel = MarketingSourceVisibility.ALL) => createAPIAction({
    types: [
        ActionCreators.LOAD_MARKETING_SOURCES.FETCH(),
        ActionCreators.LOAD_MARKETING_SOURCES.SUCCESS(),
        ActionCreators.LOAD_MARKETING_SOURCES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.MARKETING_SOURCES(),
    params: visibilityLevel ? { visibilityLevel } : {},
    method: 'GET',
});
export const loadPaymentTypes = () => createAPIAction({
    types: [
        ActionCreators.LOAD_PAYMENT_TYPES.FETCH(),
        ActionCreators.LOAD_PAYMENT_TYPES.SUCCESS(),
        ActionCreators.LOAD_PAYMENT_TYPES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.PAYMENT_TYPES(),
    method: 'GET',
});
export const loadPaymentSubTypes = (paymentType) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const paymentTypeId = selectPaymentTypeIdByName(paymentType)(getState());
    return dispatch(createAPIAction({
        types: [
            ActionCreators.LOAD_PAYMENT_SUB_TYPES.FETCH(),
            ActionCreators.LOAD_PAYMENT_SUB_TYPES.SUCCESS(),
            ActionCreators.LOAD_PAYMENT_SUB_TYPES.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PAYMENT_SUB_TYPES(paymentTypeId),
        method: 'GET',
    }));
});
export const getDiscountReasonTypes = () => createAPIAction({
    types: [
        ActionCreators.GET_DISCOUNT_REASON_TYPES.FETCH(),
        ActionCreators.GET_DISCOUNT_REASON_TYPES.SUCCESS(),
        ActionCreators.GET_DISCOUNT_REASON_TYPES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DISCOUNT_REASON_TYPES(),
    method: 'GET',
});
export const getAllRepairs = () => createAPIAction({
    types: [
        ActionCreators.GET_ALL_REPAIRS.FETCH(),
        ActionCreators.GET_ALL_REPAIRS.SUCCESS(),
        ActionCreators.GET_ALL_REPAIRS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ALL_REPAIRS(),
    method: 'GET',
});
