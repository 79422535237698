import React from 'react';
import { Button } from 'src/components/mui';
import { VendorContainer } from './VendorInfo.styles';
import ActiveStopTooltip from '../tooltips/ActiveStopTooltip';
const VendorInfo = ({ distributor, onClick }) => {
    var _a;
    const haveNotPickedUpOrders = (_a = distributor === null || distributor === void 0 ? void 0 : distributor.order) === null || _a === void 0 ? void 0 : _a.items.some((o) => { var _a; return ((_a = o === null || o === void 0 ? void 0 : o.states[0]) === null || _a === void 0 ? void 0 : _a.status) === 'STANDBY'; });
    return !distributor ? null : (<VendorContainer className={`${haveNotPickedUpOrders ? 'active-stop' : ''}`}>
      <div className="vendorTitle">
        <div className="vendorTitle__main">
          {distributor === null || distributor === void 0 ? void 0 : distributor.partDistributorName}{' '}
          {haveNotPickedUpOrders && <ActiveStopTooltip />}
        </div>

        <Button className="editVendor" type="button" onClick={onClick}>
          Edit
        </Button>
      </div>
      <p className="vendorSubtitle">{distributor === null || distributor === void 0 ? void 0 : distributor.address}</p>
    </VendorContainer>);
};
export default VendorInfo;
