import React from 'react';
import styled from 'styled-components';
import { TableRow, TableCell } from 'src/components/mui';
import { getShopDashboardUrl } from 'src/AdminApp/utils/url-utils';
import { formatDate, formatPhone } from 'src/utils/formatter';
import { LOCALIZED_DATE_FORMAT, LOCALIZED_DATE_LONG_FORMAT, } from 'src/timeConstants';
const rowColor = (shopStatus) => {
    let color = '';
    if (shopStatus && shopStatus !== 'ACTIVE') {
        color = '#ff00004f';
    }
    return color;
};
const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  td {
    padding: 15px !important;
  }
`;
const DashboardListItem = ({ customId, ispId, shopName, shopZip, shopPhone, systemsIntegrated, installationDate, lastInvoiceDateTime, status, }) => {
    return (<StyledTableRow style={{ backgroundColor: rowColor(status) }}>
      <TableCell>
        {ispId && (<a href={getShopDashboardUrl(ispId)} target="_blank" rel="noreferrer">
            {shopName}
          </a>)}
        {!ispId && <span>{shopName}</span>}
      </TableCell>
      <TableCell>{customId}</TableCell>
      <TableCell>{shopZip}</TableCell>
      <TableCell>{shopPhone ? formatPhone(shopPhone) : 'N/A'}</TableCell>
      <TableCell>{systemsIntegrated}</TableCell>
      <TableCell>
        {installationDate
            ? formatDate(installationDate, LOCALIZED_DATE_FORMAT)
            : 'N/A'}
      </TableCell>
      <TableCell>
        {lastInvoiceDateTime
            ? formatDate(lastInvoiceDateTime, LOCALIZED_DATE_LONG_FORMAT)
            : 'N/A'}
      </TableCell>
      <TableCell>{status}</TableCell>
      <TableCell />
    </StyledTableRow>);
};
export default DashboardListItem;
