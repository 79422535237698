var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from 'src/components/mui';
import useDebounce from 'src/modules/api/utils/useDebounce';
import { useDispatch } from 'react-redux';
import { getComponents } from 'src/PricingApp/modules/components/actions';
const ComponentAutocomplete = ({ type, value, componentId, onChange, disabled, forceComponentSelection = false, }) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [searchComponent, setSearchComponent] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const searchComponentDebounce = useDebounce(searchComponent, 500);
    const [errorMessage, setErrorMessage] = useState(null);
    const onSearch = (searchTerm, autoPopulate) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(getComponents({ name: searchTerm }));
        const results = response.payload
            .filter((c) => c.type === type)
            .map((c) => ({
            id: c.id,
            name: c.name,
            partSpecialistLevel: c.partSpecialistLevel,
            automaticallyOrder: c.automaticallyOrder,
            skynetMinimumMargin: c.skynetMinimumMargin,
            coreChargeExpected: c.coreChargeExpected,
            pcdbId: c.pcdbId,
        }));
        if (!results.length) {
            if (forceComponentSelection) {
                setErrorMessage('Please select a valid component');
            }
            onChange({ componentId: null, name: searchTerm });
            setSelected({ id: -1, name: searchTerm });
        }
        if (results.length > 1 && autoPopulate && searchTerm.length > 0) {
            let component = results[0];
            results.forEach((c) => {
                if (c.name.length < (component === null || component === void 0 ? void 0 : component.name.length)) {
                    component = c;
                }
            });
            setSelected(component);
        }
        else if (results.length === 1 && results[0].name === searchTerm) {
            setSelected(results[0]);
        }
        else if (componentId) {
            const component = results.find((c) => c.id === componentId);
            if (component) {
                setSelected(component);
            }
        }
        setSearchResults(results);
    });
    useEffect(() => {
        if (value === inputValue)
            return;
        if (value) {
            setInputValue(value);
        }
        onSearch(value, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    useEffect(() => {
        if (searchComponentDebounce.length > 0) {
            onSearch(searchComponentDebounce);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchComponentDebounce]);
    const handleSearchComponents = (event, value) => {
        setSearchComponent(value);
    };
    const handleItemSelect = (event, value) => {
        if ((value === null || value === void 0 ? void 0 : value.id) === -1) {
            onChange({ componentId: null, name: inputValue });
            setSelected({ id: null, name: inputValue });
            return;
        }
        onChange({
            componentId: value.id,
            name: value.name,
            automaticallyOrder: value.automaticallyOrder,
            skynetMinimumMargin: value.skynetMinimumMargin,
            partSpecialistLevel: value.partSpecialistLevel,
            coreChargeExpected: value.coreChargeExpected,
            pcdbId: value.pcdbId,
        });
        setSelected(value);
        setErrorMessage(null);
    };
    return (<Autocomplete id="components-select" fullWidth clearOnBlur={!forceComponentSelection} freeSolo={!forceComponentSelection} disabled={disabled} options={searchResults} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} value={selected} onInputChange={handleSearchComponents} onChange={handleItemSelect} isOptionEqualToValue={(option, value) => option.id === value.id} renderInput={(params) => {
            const { inputProps } = params;
            if (inputProps.value !== inputValue) {
                setInputValue(inputProps.value);
            }
            return (<TextField {...params} variant="outlined" placeholder={`Search for a ${type === 'PART' ? 'part' : 'fluid'} `} label={`${type === 'PART' ? 'Part' : 'Fluid'} Name`} error={!!errorMessage} helperText={!!errorMessage && errorMessage}/>);
        }}/>);
};
export default ComponentAutocomplete;
