var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'src/components/mui';
import { Edit } from 'src/components/mui/icons';
import FloatControlButtons from 'src/components/form/FloatControlButtons';
import { colors } from 'src/styles/theme';
export const StyledGrid = styled(Grid) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${({ $large }) => ($large ? '50px' : '26px')};
  font-size: ${({ $large }) => ($large ? '1rem' : '0.875rem')};
  color: ${({ $large }) => ($large ? colors.default : colors.darkGrey)};
  ${({ $large }) => $large
    ? `
    background: transparent!important;
    border-bottom: 1px solid ${colors.lightGrey};
    font-weight: bold;
  `
    : ''};
`;
export const LabelWrapper = styled.div `
  font-weight: bold;
  display: flex;
  align-items: center;
`;
const EditIcon = styled(Edit) `
  font-size: 18px;
  margin-left: 7px;
  color: ${colors.GRAY_CC};
`;
const ControlsWrapper = styled.div `
  position: relative;
`;
const PriceDetailsItem = (_a) => {
    var { label, children, editable = false, large = false, edit, onEdit, onSubmit = () => { }, onCancel = () => { } } = _a, rest = __rest(_a, ["label", "children", "editable", "large", "edit", "onEdit", "onSubmit", "onCancel"]);
    return (<StyledGrid container $large={large} {...rest}>
      <Grid item xs={6}>
        <LabelWrapper>
          {label}:{editable && !edit ? <EditIcon onClick={onEdit}/> : null}
        </LabelWrapper>
      </Grid>
      <Grid item xs={6}>
        <ControlsWrapper>
          {children}
          {edit && (<FloatControlButtons onApprove={onSubmit} onClose={onCancel}/>)}
        </ControlsWrapper>
      </Grid>
    </StyledGrid>);
};
export default PriceDetailsItem;
