import { createSelector } from 'reselect';
export const selectInvoices = (state) => state.admin.invoices;
export const selectInvoiceByWorkOrderId = createSelector(selectInvoices, (_, props) => props, (invoices, { workOrderId, workOrderResponsiblePartyId }) => {
    const invoiceMap = invoices === null || invoices === void 0 ? void 0 : invoices.byWorkOrderId[workOrderId];
    if (!invoiceMap) {
        return;
    }
    if (workOrderResponsiblePartyId) {
        return invoiceMap[workOrderResponsiblePartyId];
    }
    return Object.values(invoiceMap).filter((x) => { var _a; return (_a = x === null || x === void 0 ? void 0 : x.workOrderResponsibleParty) === null || _a === void 0 ? void 0 : _a.primary; })[0];
});
export const selectInvoicesByWorkOrderId = createSelector(selectInvoices, (_, props) => props, (invoices, { workOrderId }) => {
    var _a;
    const invoiceMap = (_a = invoices === null || invoices === void 0 ? void 0 : invoices.byWorkOrderId) === null || _a === void 0 ? void 0 : _a[workOrderId];
    return invoiceMap ? Object.values(invoiceMap) : [];
});
export const selectMainInvoiceByWorkOrderId = createSelector(selectInvoicesByWorkOrderId, (invoices) => {
    if (!invoices || !invoices.length)
        return null;
    return invoices.filter((x) => { var _a; return (_a = x.workOrderResponsibleParty) === null || _a === void 0 ? void 0 : _a.primary; })[0];
});
export const selectNotMainInvoicesByWorkOrderId = createSelector(selectInvoicesByWorkOrderId, (invoices) => {
    if (!invoices || !invoices.length)
        return null;
    return invoices.filter((x) => { var _a; return !((_a = x.workOrderResponsibleParty) === null || _a === void 0 ? void 0 : _a.primary); });
});
export const selectInvoiceWithWorkOrderId = createSelector(selectInvoices, (_, workOrderId) => workOrderId, (invoices, workOrderId) => {
    var _a, _b;
    const invoiceMap = (_a = invoices === null || invoices === void 0 ? void 0 : invoices.byWorkOrderId) === null || _a === void 0 ? void 0 : _a[workOrderId];
    return invoiceMap
        ? (_b = Object.values(invoiceMap).filter((x) => x.workOrderResponsibleParty.primary)) === null || _b === void 0 ? void 0 : _b[0]
        : null;
});
export const selectNonPrimaryInvoiceWithWorkOrderId = createSelector(selectInvoices, (_, workOrderId) => workOrderId, (invoices, workOrderId) => {
    var _a;
    const invoiceMap = (_a = invoices === null || invoices === void 0 ? void 0 : invoices.byWorkOrderId) === null || _a === void 0 ? void 0 : _a[workOrderId];
    return invoiceMap
        ? Object.values(invoiceMap)
            .filter((x) => !x.workOrderResponsibleParty.primary)
            .flatMap((invoice) => invoice.workOrderResponsiblePartyAuthorizations)
        : [];
});
