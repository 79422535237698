import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, Icon, InputAdornment, TextField, Typography, } from 'src/components/mui';
import FloatControlButtons from 'src/components/form/FloatControlButtons';
import { colors } from 'src/styles/theme';
import { flexCenter } from 'src/styles/styled';
const containerStyle = {
    '--bottom-margin': '24px',
    '--flex-style': 'space-between',
};
const EditIcon = styled(Icon) `
  color: ${colors.GRAY_CC};
  font-size: 1.125rem;
  margin-left: 8px;
`;
const TotalWrapper = styled.div `
  position: relative;
  width: inherit;
`;
const Container = styled(Grid) `
  margin-bottom: var(--bottom-margin, 0);
  justify-content: var(--flex-style, flex-start);
`;
const DurationContainer = styled('div') `
  ${flexCenter}

  width: auto;
`;
const Form = styled('form') `
  position: relative;
`;
const TotalLaborDuration = ({ canEdit, onEdit, totalLaborDuration, withPayerInfo = false, }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [updatedTotalLaborDuration, setUpdatedTotalLaborDuration] = useState(totalLaborDuration);
    useEffect(() => {
        setUpdatedTotalLaborDuration(totalLaborDuration);
    }, [totalLaborDuration]);
    const onEditSubmit = () => {
        setIsEditing(false);
        if (onEdit)
            onEdit(updatedTotalLaborDuration);
    };
    const style = !withPayerInfo ? {} : containerStyle;
    return (<TotalWrapper>
      <Container container style={style} wrap="nowrap">
        <Grid item>
          <Typography variant="subtitle2">
            Total Labor Duration:&nbsp;
          </Typography>
        </Grid>
        <Grid item>
          {isEditing ? (<Form onSubmit={onEditSubmit}>
              <Grid container wrap="nowrap">
                <Grid item xs>
                  <TextField fullWidth InputProps={{
                endAdornment: (<InputAdornment position="end">h</InputAdornment>),
            }} value={updatedTotalLaborDuration} onChange={(e) => setUpdatedTotalLaborDuration(+e.target.value)} onBlur={onEditSubmit}/>
                </Grid>
              </Grid>
              <FloatControlButtons onClose={() => setIsEditing(false)} onApprove={() => {
                if (onEdit)
                    onEdit();
                setIsEditing(false);
            }}/>
            </Form>) : (<DurationContainer>
              {totalLaborDuration && (<Typography variant="body2">{totalLaborDuration}h</Typography>)}
              {onEdit && canEdit && (<EditIcon onClick={() => setIsEditing(true)}>edit</EditIcon>)}
            </DurationContainer>)}
        </Grid>
      </Container>
    </TotalWrapper>);
};
export default TotalLaborDuration;
