import styled from 'styled-components';
import { IconButton } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import { Colors } from 'src/PopsApp/modules/globalStyle';
const generateGradientString = (percentagesArray) => {
    if (!percentagesArray || percentagesArray.length === 0) {
        return 'linear-gradient(to right,#f0f0f0 0%,#f0f0f0 0%,#fff 0%,#fff 0,#f0f0f0 0%,#f0f0f0 100%)';
    }
    let gradientString = 'linear-gradient(to right, ';
    percentagesArray.forEach((percentage, index) => {
        gradientString += `#f0f0f0 ${percentage.startPercentage}%`;
        gradientString += `, #fff ${percentage.startPercentage}%`;
        gradientString += `, #fff ${percentage.endPercentage}%`;
        gradientString += `, #f0f0f0 ${percentage.endPercentage}%`;
        if (index < percentagesArray.length - 1) {
            gradientString += ', ';
        }
    });
    gradientString += ')';
    return gradientString;
};
export const ChartRow = styled.div `
  display: grid;
  grid-template-columns: 0.3fr 1.36fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid ${Colors.GRAY_CC};
`;
export const ChartRowItems = styled.div `
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;

  .placeholder {
    height: 70px;
  }

  &.chart-row-scrollable {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .region-container {
    display: flex;
    position: relative;
    margin-left: 20px;
    width: calc(100% - 20px);

    .region-content-wrapper {
      width: 100%;
    }

    .region-name {
      position: absolute;
      top: 1px;
      left: -24px;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      white-space: nowrap;
      height: 100%;
      text-align: end;
      overflow: hidden;
      text-overflow: ellipsis;

      .separator {
        height: 100%;
        width: 1px;
        background-color: ${Colors.GRAY_CC};
        position: relative;

        &::before {
          position: absolute;
          content: '';
          height: 1px;
          width: 10px;
          top: 0%;
          left: 5%;
          bottom: 0%;
          background-color: ${Colors.GRAY_CC};
        }

        &::after {
        }
      }

      span {
        color: ${Colors.GRAY_97};
        font-size: 10px;
      }
    }
  }
`;
export const ChartRegion = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;
export const ChartRowTitle = styled.div `
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${Colors.GRAY_CC};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;

  &.vanInfo {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px 0px 15px;
    border-right: 1px solid ${Colors.GRAY_CC};
    font-size: 12px;

    .info {
      display: flex;
      flex-direction: column;
    }
  }
`;
export const ChartRowContent = styled.div `
  height: 65px;
  display: flex;
  position: relative;
  &.timeline {
    height: 70px;
    position: relative;
  }

  &.jobs {
    border-top: 1px solid ${Colors.GRAY_CC};
    border-right: 1px solid ${Colors.GRAY_CC};
    display: grid;
    grid-template-columns: repeat(24, minmax(90px, 90px));
    grid-gap: 0px;
    background: ${(props) => { var _a; return generateGradientString((_a = props.percentagesArray) !== null && _a !== void 0 ? _a : []); }};
  }
`;
export const ChartRowTimeContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(24, minmax(90px, 90px));
  height: 70px;
  align-items: center;

  .hour {
    position: relative;

    span {
      color: ${Colors.GRAY_76};
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.08px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 175%;
      left: 0%;
      transform: translateX(-50%);
      height: ${(props) => props.dividerHeight}px;
      width: 1px;
      background-color: ${Colors.GRAY_CC};
      z-index: 1;
    }
  }
`;
export const ArrowIcon = styled(IconButton) `
  background: white;
  position: absolute;
  left: ${(props) => (props.direction === 'left' ? '200px' : 'auto')};
  right: ${(props) => (props.direction === 'right' ? '0' : 'auto')};
  top: 17px;
  z-index: 1;

  &:hover {
    background: ${colors.border};
  }

  svg {
    color: ${Colors.GRAY_CC};
  }
`;
export const RegionTimezoneDivider = styled.div `
  position: absolute;
  background-color: #ff00b9;
  height: 100%;
  width: 2px;
  transform: translateX(${(props) => props.position}px);
  z-index: 5;

  &::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ff00b9;
    left: 50%;
    transform: translateX(-50%);
  }
`;
export const ArrivalWindowOverlay = styled.div `
  position: absolute;
  top: 70px;
  width: 2160px;
  height: 100%;
  opacity: 0.15;
  z-index: 1;
  background: linear-gradient(
    to right,
    #ff8080 0%,
    #ff8080 ${(props) => props.startPercentage}%,
    #fff ${(props) => props.startPercentage}%,
    #fff ${(props) => props.endPercentage}%,
    #ff8080 ${(props) => props.endPercentage}%,
    #ff8080 100%
  );
`;
export const ScreenLockOverlay = styled.div `
  position: absolute;
  top: 70px;
  width: 2160px;
  height: 100%;
  opacity: 0.5;
  z-index: 3;
  background: transparent;
`;
