var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { differenceWith, isNull } from 'lodash';
import moment from 'moment/moment';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { responsiblePartyType, responsiblePartyTypeReducer, } from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/utils';
import { formatRepairs, isQuoteCostsHasNull, } from 'src/AdminApp/components/repairs/statement/utils';
import FmcWorkAuthorizationStatus from 'src/AdminApp/models/enums/FmcWorkAuthorizationStatus';
import RepairRequestState from 'src/AdminApp/models/enums/RepairRequestState';
import { selectCancelFeeCreditsByConsumerId, selectConsumerById, } from 'src/AdminApp/modules/consumers/selectors';
import { selectFleetById } from 'src/AdminApp/modules/fleets/selectors';
import { downloadQuote } from 'src/AdminApp/modules/invoices/actions';
import { addCustomRepairComponents, addRepairComponents, closeServiceDrawer, fetchRequestPartPickups, getRecommendedRepairs, removeCarDiagnosis, removeCustomRepairComponents, removeRepairComponents, removeSuggestedRepair, updateCustomRepairComponents, updateQuoteProvided, updateRepairComponents, } from 'src/AdminApp/modules/requests/actions';
import { selectAdvisorCallRequestedDate, selectContactInfo, selectRelatedParts, selectRepairMeasurements, selectRequest, selectRequestDiagnosis, selectVehicleByRefNum, } from 'src/AdminApp/modules/requests/selectors';
import { getRepairRequestStopwatch, getTechnicianOnSiteWorkflowByRef, } from 'src/AdminApp/modules/workflows/actions';
import { applyQuoteCancelFeeCredit, applyQuoteOtherDiscount, applyQuotePromoCode, getFmcWorkAuthorization, removeDiscount, } from 'src/AdminApp/modules/workOrders/actions';
import { selectFirstWorkOrderByRequestRefNum, selectQuoteCredits, selectQuoteOtherDiscounts, selectQuotePromos, selectQuoteSubscriptions, selectWorkOrderResponsiblePartyTypeTotals, } from 'src/AdminApp/modules/workOrders/selectors';
import { requestSalesCall } from 'src/AdminApp/modules/zendesk/actions';
import { getConsumerUrl } from 'src/AdminApp/utils/url-utils';
import { toast } from 'src/components/SimpleToast';
import CONFIG from 'src/config';
import { WARNINGS } from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal/WarningMessages';
import { selectAuthorizedFmcAdmin, selectAuthorizedShowRODiscount, selectQuickOrder, } from 'src/modules/auth/selectors';
import { getOrderRosterJobRequestByReferenceNum, getShopById, setSelectedJobsForOrdering, } from 'src/PopsApp/modules/parts/actions';
import { selectOrderRosterJobByReferenceNum } from 'src/PopsApp/modules/parts/selectors';
import copyToClipboard from 'src/utils/copyToClipboard';
import { delayFor } from 'src/utils/delay';
const useQuote = ({ referenceNum, selectorProps, canEdit, hasInvoice, onSendForApproval, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const history = useHistory();
    const [payerState, payerDispatch] = useReducer(responsiblePartyTypeReducer, responsiblePartyType);
    const dispatch = useDispatch();
    const handleClosePayerModal = useCallback(() => payerDispatch({
        payload: false,
        type: 'CLOSE_PAYER_MODAL',
    }), []);
    const handleCloseAuthModal = useCallback(() => payerDispatch({
        payload: false,
        type: 'CLOSE_AUTH_MODAL',
    }), []);
    const handleOpenPayerModal = useCallback(() => payerDispatch({
        payload: true,
        type: 'OPEN_PAYER_MODAL',
    }), []);
    const handleOpenAuthModal = useCallback(() => payerDispatch({
        payload: true,
        type: 'OPEN_AUTH_MODAL',
    }), []);
    const request = useSelector((state) => selectRequest(state, selectorProps));
    const repairRequestId = request === null || request === void 0 ? void 0 : request.id;
    const workOrder = useSelector(selectFirstWorkOrderByRequestRefNum(referenceNum));
    const workOrderId = workOrder === null || workOrder === void 0 ? void 0 : workOrder.id;
    const car = useSelector(selectVehicleByRefNum(referenceNum));
    const promos = useSelector(selectQuotePromos(workOrderId));
    const subscriptionDiscounts = useSelector(selectQuoteSubscriptions(workOrderId));
    const credits = useSelector(selectQuoteCredits(workOrderId));
    const otherDiscounts = useSelector(selectQuoteOtherDiscounts(workOrderId));
    const contactInfo = useSelector((state) => selectContactInfo(state, selectorProps));
    const consumerId = contactInfo ? contactInfo.consumerId : 0;
    const consumer = consumerId
        ? useSelector(selectConsumerById(+consumerId))
        : null;
    const allCancelFeeCredits = !consumerId
        ? null
        : useSelector((state) => selectCancelFeeCreditsByConsumerId(state, +consumerId));
    const workOrderResponsiblePartyTypeTotals = useSelector(selectWorkOrderResponsiblePartyTypeTotals(workOrderId));
    const cancelFeeCredits = differenceWith(allCancelFeeCredits, credits, (allCredit, otherCredit) => allCredit.invoiceId === otherCredit.originalInvoiceId);
    const diagnosis = useSelector((state) => selectRequestDiagnosis(state, selectorProps));
    const relatedParts = useSelector((state) => selectRelatedParts(state, selectorProps));
    const advisorCallRequestedDate = useSelector((state) => selectAdvisorCallRequestedDate(state, selectorProps));
    const canAccessDiscount = useSelector(selectAuthorizedShowRODiscount);
    const canUseQuickOrder = useSelector(selectQuickOrder);
    const { knownRepairs, diagnoses, customRequests } = formatRepairs(((_a = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) || [], ((_b = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _b === void 0 ? void 0 : _b.diagnoses) || [], ((_c = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _c === void 0 ? void 0 : _c.customRequests) || []);
    const [removingDiscountId, setRemovingDiscountId] = useState('');
    const [discountType, setDiscountType] = useState('');
    const [isRemoveDiscountOpen, setIsRemoveDiscountOpen] = useState(false);
    const [isSendEmailConfirmDialogOpen, setIsSendEmailOpen] = useState(false);
    const [isSendForApprovalButtonEnabled, setIsSendForApprovalButtonEnabled] = useState(false);
    const [isSendForApprovalButtonVisible, setIsSendForApprovalButtonVisible] = useState(false);
    const [isAutoIntegrateFmc, setIsAutoIntegrateFmc] = useState(false);
    const [showCapacityWarning, setShowCapacityWarning] = useState(false);
    const [unsavedLabor, setUnsavedLabor] = useState();
    const [capacityWarningMessage, setCapacityWarningMessage] = useState();
    const canSubmitForApproval = useSelector(selectAuthorizedFmcAdmin);
    const measurements = useSelector((state) => selectRepairMeasurements(state, selectorProps));
    const orderRosterJob = useSelector(selectOrderRosterJobByReferenceNum(referenceNum));
    const [quoteFmcWorkAuthorizationStatus, setQuoteFmcWorkAuthorizationStatus] = useState('');
    const fleetId = consumer === null || consumer === void 0 ? void 0 : consumer.fleetId;
    const getFleet = (fleetId) => {
        if (fleetId) {
            return useSelector(selectFleetById(fleetId));
        }
    };
    const fleet = fleetId ? getFleet(fleetId) : null;
    const fmc = (_d = fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) === null || _d === void 0 ? void 0 : _d.filter((fmc) => { var _a; return (_a = fmc === null || fmc === void 0 ? void 0 : fmc.cars) === null || _a === void 0 ? void 0 : _a.find((fmcCar) => fmcCar.id === car.consumerCarId); });
    const submissionMethod = (fmc === null || fmc === void 0 ? void 0 : fmc.length) === 1 ? (_f = (_e = fmc[0]) === null || _e === void 0 ? void 0 : _e.fmcDto) === null || _f === void 0 ? void 0 : _f.submissionMethod : null;
    const fetchQuoteFmcWorkAuthorizationStatus = () => __awaiter(void 0, void 0, void 0, function* () {
        var _p, _q;
        if (fleet &&
            fleet.autoIntegrateId !== null &&
            submissionMethod !== null &&
            workOrderId) {
            const response = yield dispatch(getFmcWorkAuthorization(workOrderId, 'QUOTE'));
            setQuoteFmcWorkAuthorizationStatus(((_p = response === null || response === void 0 ? void 0 : response.payload) === null || _p === void 0 ? void 0 : _p.status)
                ? FmcWorkAuthorizationStatus.display((_q = response === null || response === void 0 ? void 0 : response.payload) === null || _q === void 0 ? void 0 : _q.status)
                : 'N/A');
        }
    });
    useEffect(() => {
        dispatch(fetchRequestPartPickups(referenceNum));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referenceNum]);
    useEffect(() => {
        var _a;
        fetchQuoteFmcWorkAuthorizationStatus();
        if (canUseQuickOrder && ((_a = request === null || request === void 0 ? void 0 : request.activeAppointment) === null || _a === void 0 ? void 0 : _a.scheduleStartLocal)) {
            dispatch(getOrderRosterJobRequestByReferenceNum(referenceNum));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagnosis]);
    useEffect(() => {
        if (quoteFmcWorkAuthorizationStatus === '') {
            fetchQuoteFmcWorkAuthorizationStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleet, workOrderId]);
    const areMeasurementsCompleteForKnownRepairs = useMemo(() => {
        var _a;
        if (!knownRepairs || !measurements) {
            return true;
        }
        const knownRepairIds = knownRepairs.map((repair) => repair.nodeId);
        const knownRepairMeasurements = (_a = measurements.filter((measurement) => measurement.repairs.find((repairId) => knownRepairIds.includes(repairId)) !== undefined)) !== null && _a !== void 0 ? _a : [];
        return (knownRepairMeasurements.find((measurement) => !measurement.optional && !measurement.value) === undefined);
    }, [knownRepairs, measurements]);
    const shouldHaveAdditionalDetails = [...knownRepairs, ...diagnoses, ...customRequests].filter((repair) => {
        return repair.price[0].parts.some((part) => { var _a; return ((_a = part === null || part === void 0 ? void 0 : part.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'tire' && !(part === null || part === void 0 ? void 0 : part.partMetadata); });
    }).length > 0;
    useEffect(() => {
        var _a;
        if (fleet && (fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) && ((_a = fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            if (submissionMethod === 'AUTO_INTEGRATE') {
                setIsSendForApprovalButtonVisible(true);
                setIsAutoIntegrateFmc(true);
            }
            if (fleet.autoIntegrateId !== null && submissionMethod !== null) {
                const isPendingStatus = quoteFmcWorkAuthorizationStatus !== 'COMPLETED';
                setIsSendForApprovalButtonEnabled(canSubmitForApproval &&
                    isPendingStatus &&
                    areMeasurementsCompleteForKnownRepairs &&
                    !shouldHaveAdditionalDetails);
            }
        }
    }, [
        fleet,
        quoteFmcWorkAuthorizationStatus,
        canSubmitForApproval,
        areMeasurementsCompleteForKnownRepairs,
        shouldHaveAdditionalDetails,
        submissionMethod,
    ]);
    const componentRequiresOrder = (component) => ['PENDING', 'ORDER_FAILED', 'NOT_PICKED_UP', 'WRONG_PART'].includes(component === null || component === void 0 ? void 0 : component.status);
    const repairsRequireOrdering = useCallback(() => {
        return ([...knownRepairs, ...customRequests]
            .flatMap((repair) => repair.price)
            .findIndex((price) => price.fluids.findIndex(componentRequiresOrder) > -1 ||
            price.parts.findIndex(componentRequiresOrder) > -1) > -1);
    }, [knownRepairs, customRequests]);
    const handleSendForApproval = (authorizationType) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSendForApprovalButtonEnabled(false);
        yield onSendForApproval(authorizationType);
        yield delayFor(5000).then(() => {
            fetchQuoteFmcWorkAuthorizationStatus();
        });
    });
    const handleQuickOrder = () => __awaiter(void 0, void 0, void 0, function* () {
        var _r, _s;
        const ispId = orderRosterJob === null || orderRosterJob === void 0 ? void 0 : orderRosterJob.ispId;
        if (ispId) {
            dispatch(getShopById(ispId.toString()));
        }
        const regionId = orderRosterJob === null || orderRosterJob === void 0 ? void 0 : orderRosterJob.regionId;
        const repairs = (_s = (_r = orderRosterJob === null || orderRosterJob === void 0 ? void 0 : orderRosterJob.services) === null || _r === void 0 ? void 0 : _r.knownRepairs) === null || _s === void 0 ? void 0 : _s.map((r) => {
            return r.name;
        });
        const selectedJobs = {};
        selectedJobs[referenceNum] = Object.assign(Object.assign({}, orderRosterJob), { repairs });
        yield dispatch(setSelectedJobsForOrdering(selectedJobs));
        history.push(`/pops/regions/${regionId}/vans/${ispId}/jobs/vendors/quick-order`);
    });
    const authorizableRepairs = [...knownRepairs, ...customRequests];
    const authorizableResponsibleParties = (_h = (_g = authorizableRepairs === null || authorizableRepairs === void 0 ? void 0 : authorizableRepairs.flatMap((rep) => rep === null || rep === void 0 ? void 0 : rep.workOrderItemResponsibleParties)) === null || _g === void 0 ? void 0 : _g.filter((rep) => rep !== undefined)) === null || _h === void 0 ? void 0 : _h.filter((woirp) => { var _a, _b; return (_b = (_a = woirp === null || woirp === void 0 ? void 0 : woirp.responsibleParty) === null || _a === void 0 ? void 0 : _a.responsiblePartyType) === null || _b === void 0 ? void 0 : _b.authorizationNumber; });
    const getRepairToAuthorize = (woirp, rep) => {
        var _a;
        return {
            repairId: (rep === null || rep === void 0 ? void 0 : rep.suggestedRepairId) ? rep === null || rep === void 0 ? void 0 : rep.suggestedRepairId : rep === null || rep === void 0 ? void 0 : rep.id,
            repairName: rep === null || rep === void 0 ? void 0 : rep.name.substring(3),
            woirpId: woirp === null || woirp === void 0 ? void 0 : woirp.id,
            totalPrice: (_a = rep === null || rep === void 0 ? void 0 : rep.price[0]) === null || _a === void 0 ? void 0 : _a.totalCost,
            responsiblePartyId: woirp === null || woirp === void 0 ? void 0 : woirp.responsiblePartyId,
        };
    };
    const workOrderItemResponsiblePartyAuthInfo = authorizableRepairs === null || authorizableRepairs === void 0 ? void 0 : authorizableRepairs.reduce((acc, rep) => {
        authorizableResponsibleParties === null || authorizableResponsibleParties === void 0 ? void 0 : authorizableResponsibleParties.map((woirp) => {
            var _a, _b;
            if (rep.id === +woirp.suggestedRepairId ||
                (rep === null || rep === void 0 ? void 0 : rep.suggestedRepairId) === +woirp.suggestedRepairId) {
                const responsiblePartyName = (_a = woirp === null || woirp === void 0 ? void 0 : woirp.responsibleParty) === null || _a === void 0 ? void 0 : _a.name;
                if (acc[responsiblePartyName]) {
                    const index = (_b = Object.keys(acc[responsiblePartyName])) === null || _b === void 0 ? void 0 : _b.length;
                    acc[responsiblePartyName] = Object.assign(Object.assign({}, acc[responsiblePartyName]), { [index]: Object.assign({}, getRepairToAuthorize(woirp, rep)) });
                }
                else {
                    acc[responsiblePartyName] = {
                        [0]: Object.assign({}, getRepairToAuthorize(woirp, rep)),
                    };
                }
            }
        });
        return acc;
    }, {});
    const openRemoveDiscountDialog = ({ discountId, discountType, }) => {
        setIsRemoveDiscountOpen(true);
        setDiscountType(discountType);
        setRemovingDiscountId(discountId);
    };
    const closeRemoveDiscountDialog = () => {
        setIsRemoveDiscountOpen(false);
        setDiscountType('');
        setRemovingDiscountId('');
    };
    const onAddPromoCode = ({ promoCode }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(applyQuotePromoCode({ referenceNum, workOrderId, promoCode }));
        dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
    });
    const onAddCancelCreditCredit = ({ originalInvoiceId, creditAmount, }) => dispatch(applyQuoteCancelFeeCredit({
        referenceNum,
        workOrderId,
        originalInvoiceId,
        creditAmount,
    }));
    const onAddOtherDiscount = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(applyQuoteOtherDiscount(Object.assign({ referenceNum, workOrderId }, values)));
        dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
    });
    const onRemoveDiscount = () => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(removeDiscount({
            referenceNum,
            workOrderId,
            discountId: removingDiscountId,
        }));
        closeRemoveDiscountDialog();
        dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
    });
    const addComponent = ({ repairId, body, }) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(addRepairComponents({ referenceNum, repairId, body }));
        if (!response.error) {
            dispatch(closeServiceDrawer());
            yield delayFor(5000);
            fetchQuoteFmcWorkAuthorizationStatus();
            yield dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
            dispatch(fetchRequestPartPickups(referenceNum));
        }
    });
    const updateComponent = ({ repairId, body, isValidateLaborCapacity = false, }) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(updateRepairComponents({
            referenceNum,
            repairId,
            body,
            isValidateLaborCapacity,
        }));
        if (response.error &&
            response.payload.response.status === 400 &&
            response.payload.response.message.toLowerCase() === 'visit over capacity') {
            setUnsavedLabor({ repairId, body });
            setShowCapacityWarning(true);
            setCapacityWarningMessage(WARNINGS.LABOR_DURATION_ADJUSTMENT);
        }
        else if (response.error) {
            toast.error(response.payload.response.message);
        }
        else {
            yield delayFor(5000);
            fetchQuoteFmcWorkAuthorizationStatus();
            dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
            dispatch(fetchRequestPartPickups(referenceNum));
            dispatch(closeServiceDrawer());
        }
    });
    const handleCapacityWarningClose = (confirm) => {
        setShowCapacityWarning(false);
        if (confirm) {
            updateComponent(Object.assign(Object.assign({}, unsavedLabor), { isValidateLaborCapacity: false }));
            setUnsavedLabor(undefined);
        }
    };
    const removeComponent = ({ repairId, componentId, componentType, removeDynamicRule, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(removeRepairComponents({
            referenceNum,
            repairId,
            componentId,
            componentType,
            removeDynamicRule,
        }));
        dispatch(closeServiceDrawer());
        yield delayFor(5000);
        fetchQuoteFmcWorkAuthorizationStatus();
        dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
    });
    const removeRepair = ({ repairId }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(removeSuggestedRepair({ referenceNum, suggestedRepairId: repairId }));
        dispatch(closeServiceDrawer());
        yield delayFor(5000);
        fetchQuoteFmcWorkAuthorizationStatus();
        dispatch(getRepairRequestStopwatch(referenceNum));
        dispatch(getRecommendedRepairs(referenceNum));
        dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
    });
    const addCustomComponent = ({ repairId, body, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(addCustomRepairComponents({
            referenceNum,
            customRequestId: repairId,
            body,
        }));
        dispatch(closeServiceDrawer());
        yield delayFor(5000);
        fetchQuoteFmcWorkAuthorizationStatus();
        dispatch(fetchRequestPartPickups(referenceNum));
    });
    const updateCustomComponent = ({ repairId, body, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(updateCustomRepairComponents({
            referenceNum,
            customRequestId: repairId,
            body,
        }));
        dispatch(closeServiceDrawer());
        yield delayFor(5000);
        fetchQuoteFmcWorkAuthorizationStatus();
        dispatch(fetchRequestPartPickups(referenceNum));
    });
    const removeCustomComponent = ({ repairId, componentId, componentType, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(removeCustomRepairComponents({
            referenceNum,
            customRequestId: repairId,
            componentId,
            componentType,
        }));
        dispatch(closeServiceDrawer());
        yield delayFor(5000);
        fetchQuoteFmcWorkAuthorizationStatus();
    });
    const removeCustomRepair = ({ repairId }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(removeCarDiagnosis({ referenceNum, diagnosisId: repairId }));
        dispatch(closeServiceDrawer());
        yield delayFor(5000);
        fetchQuoteFmcWorkAuthorizationStatus();
    });
    const appointmentAdditionalTime = (_j = request === null || request === void 0 ? void 0 : request.activeAppointment.additionalTime) !== null && _j !== void 0 ? _j : [];
    const additionalMinutes = appointmentAdditionalTime
        .map((time) => time.secondsRequested / 60)
        .reduce((prev, current) => prev + current, 0);
    const totalLaborDuration = ((_k = request === null || request === void 0 ? void 0 : request.totalLaborDuration) !== null && _k !== void 0 ? _k : 0) +
        Math.round((additionalMinutes / 60) * 100) / 100;
    const totalJobDurationMinutes = ((_l = request === null || request === void 0 ? void 0 : request.totalJobDurationMinutes) !== null && _l !== void 0 ? _l : 0) + additionalMinutes;
    const { totalPrice } = (_m = workOrder === null || workOrder === void 0 ? void 0 : workOrder.quote) !== null && _m !== void 0 ? _m : {};
    const isRequestNewOrOpen = [
        RepairRequestState.NEW_REQUEST,
        RepairRequestState.OPEN_REQUEST,
    ].includes(request === null || request === void 0 ? void 0 : request.state);
    const isQuoteProvideTimeReady = () => {
        var _a;
        const quoteProvided = (_a = workOrder === null || workOrder === void 0 ? void 0 : workOrder.quote.activeWorkAuthorization) === null || _a === void 0 ? void 0 : _a.providedAt;
        if (!quoteProvided)
            return true;
        const allowedTime = moment(`${quoteProvided}+00:00`).add(CONFIG.PROVIDE_QUOTE_FREEZE_DURATION_SECONDS, 'seconds');
        return moment().isAfter(allowedTime);
    };
    const isProvideQuoteButtonEnabled = !isNull(totalPrice) &&
        isQuoteProvideTimeReady() &&
        !isQuoteCostsHasNull(knownRepairs, customRequests);
    const onSendEmailConfirm = (sendEmail) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(updateQuoteProvided({
            referenceNum,
            sendEmail,
        }));
        setIsSendEmailOpen(false);
    });
    const onDownloadQuote = () => dispatch(downloadQuote(referenceNum));
    const onCopyQuoteLink = () => {
        const link = getConsumerUrl(`my-repairs/${referenceNum}`);
        copyToClipboard(link);
    };
    const handleSalesCallRequest = () => {
        dispatch(requestSalesCall(referenceNum));
    };
    const responsiblePartyTypeValue = Object.assign(Object.assign({}, payerState), { handleCloseAuthModal,
        handleClosePayerModal,
        handleOpenAuthModal,
        handleOpenPayerModal });
    const isQuoteEditable = canEdit && !hasInvoice;
    const isQuickOrderEnabled = repairsRequireOrdering() &&
        !!((_o = request === null || request === void 0 ? void 0 : request.activeAppointment) === null || _o === void 0 ? void 0 : _o.scheduleStartLocal) &&
        !!(orderRosterJob === null || orderRosterJob === void 0 ? void 0 : orderRosterJob.repairRequestId);
    return {
        quoteFmcWorkAuthorizationStatus,
        responsiblePartyTypeValue,
        knownRepairs,
        diagnosis,
        isQuoteEditable,
        relatedParts,
        addComponent,
        updateComponent,
        removeComponent,
        removeRepair,
        request,
        diagnoses,
        removeCustomRepair,
        customRequests,
        addCustomComponent,
        updateCustomComponent,
        removeCustomComponent,
        credits,
        otherDiscounts,
        subscriptionDiscounts,
        promos,
        openRemoveDiscountDialog,
        workOrderItemResponsiblePartyAuthInfo,
        consumer,
        isAutoIntegrateFmc,
        fetchQuoteFmcWorkAuthorizationStatus,
        canAccessDiscount,
        onAddPromoCode,
        onAddCancelCreditCredit,
        onAddOtherDiscount,
        cancelFeeCredits,
        workOrder,
        totalJobDurationMinutes,
        totalLaborDuration,
        workOrderResponsiblePartyTypeTotals,
        isProvideQuoteButtonEnabled,
        isRequestNewOrOpen,
        setIsSendEmailOpen,
        onDownloadQuote,
        onCopyQuoteLink,
        advisorCallRequestedDate,
        handleSendForApproval,
        isSendForApprovalButtonEnabled,
        isSendForApprovalButtonVisible,
        isQuickOrderEnabled,
        canUseQuickOrder,
        handleQuickOrder,
        isSendEmailConfirmDialogOpen,
        onSendEmailConfirm,
        isRemoveDiscountOpen,
        discountType,
        closeRemoveDiscountDialog,
        onRemoveDiscount,
        workOrderId,
        handleClosePayerModal,
        payerState,
        handleSalesCallRequest,
        showCapacityWarning,
        handleCapacityWarningClose,
        capacityWarningMessage,
        repairRequestId,
        car,
    };
};
export default useQuote;
