var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from 'src/containers/utils/ProtectedRoute';
import PageLayout from 'src/containers/layout/PageLayout';
import { selectAuthorizedSystemUser, selectCancellationReasonEditor, } from 'src/modules/auth/selectors';
import NotAuthorized from 'src/components/layout/NotAuthorized';
import * as pages from './pages';
const DefaultPage = (props) => (<Redirect to={{
        pathname: '/system/elasticsearch-indexer',
        state: { from: props.location },
    }}/>);
const SystemApp = (_a) => {
    var props = __rest(_a, []);
    const isSystemUser = useSelector(selectAuthorizedSystemUser);
    const isCancellationReasonEditor = useSelector(selectCancellationReasonEditor);
    return (<PageLayout {...props}>
      {isSystemUser ? (<Switch>
          <ProtectedRoute exact path="/system/" component={DefaultPage}/>
          <ProtectedRoute exact path="/system/address-sanitizer" component={pages.UtilitiesDashboard}/>
          <ProtectedRoute exact path="/system/audit/" component={pages.AuditHome}/>
          <ProtectedRoute exact path="/system/audit/entity-explorer/:schema?/:table?/:id?" component={pages.EntityExplorer}/>
          <ProtectedRoute exact path="/system/elasticsearch-indexer" component={pages.ElasticsearchIndexer}/>
          <ProtectedRoute exact path="/system/kafka/" component={pages.KafkaHome}/>
          <ProtectedRoute exact path="/system/kafka/kafka-connect" component={pages.KafkaConnect}/>
          <ProtectedRoute exact path="/system/skedulo/" component={pages.SkeduloHome}/>
          <ProtectedRoute exact path="/system/skedulo/matcher" component={pages.SkeduloMatcher}/>
          <ProtectedRoute exact path="/system/skedulo/webhooks" component={pages.SkeduloWebhooks}/>
          <ProtectedRoute exact path="/system/skedulo/resources" component={pages.SkeduloResources}/>
          <ProtectedRoute exact path="/system/snapshots" component={pages.SnapshotsHome}/>
          <ProtectedRoute exact path="/system/snapshots/requests/:referenceNum?" component={pages.RequestSnapshots}/>
          <ProtectedRoute exact path="/system/services" component={pages.ServicesDashboard}/>
          {isCancellationReasonEditor && (<ProtectedRoute exact path="/system/resolutions" component={pages.AppointmentResolutions}/>)}
          <ProtectedRoute exact path="/system/skills" component={pages.Skills}/>
          <ProtectedRoute exact path="/system/configs" component={pages.CustomConfigs}/>
          <ProtectedRoute exact path="/system/hubs" component={pages.Hubs}/>
        </Switch>) : (<NotAuthorized targetRole="System_User or System_Admin"/>)}
    </PageLayout>);
};
export default SystemApp;
