/* eslint-disable jsx-a11y/label-has-associated-control */
import { Grid, InputAdornment } from 'src/components/mui';
import { AttachMoneyRounded as AttachMoneyRoundedIcon } from 'src/components/mui/icons';
import { Field } from 'formik';
import React from 'react';
import PartDistributorAutocomplete from 'src/components/form/PartDistributorAutocomplete';
import PlacesAndPartDistributorLocationsAutoComplete from 'src/components/form/PlacesAndPartDistributorLocationsAutoComplete';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { DrawerFormBorderedSection } from 'src/components/SimpleDrawer/SimpleDrawer.styles';
import { TooltipInfo } from 'src/components/tooltips';
import { colors } from 'src/styles/theme';
import { TextField } from 'src/AdminApp/components/form';
import ComponentAutocomplete from 'src/AdminApp/components/repairs/components/ComponentAutocomplete';
import PriceMargin from 'src/AdminApp/components/repairs/statement/PriceMargin';
import ToolTipInfo from 'src/FleetVisitsApp/pages/FleetVisits/components/ToolTipInfo';
import { RowCompact } from './EditPartDrawer.styles';
const EditPartDrawerGeneral = ({ values, setValues, setFieldValue, onAddressSelect, canEdit = true, totalCost, quotedCost, setIsConfirmQtyModalOpen, isConfirmQtyModalOpen, isTireComponent, canEditDistributor, isFixedComponent, hasEngine, fixedComponents, canEditPricing, isFitment = false, isAuthorized = false, forceComponentSelection = false, setIsTireSelected, }) => {
    var _a;
    const isAddAsDynamicComponentDisabled = !canEdit ||
        !(values === null || values === void 0 ? void 0 : values.componentId) ||
        !hasEngine ||
        (fixedComponents || []).includes(values === null || values === void 0 ? void 0 : values.componentId);
    const isDistributorAutocompleteDisabled = !values.partDistributorId || !canEdit || !canEditDistributor;
    return (<Grid container spacing={1}>
      <DrawerFormBorderedSection container spacing={2}>
        <RowCompact item xs={12}>
          <ComponentAutocomplete value={values.name} componentId={values === null || values === void 0 ? void 0 : values.componentId} type="PART" onChange={({ componentId, name, partSpecialistLevel, automaticallyOrder, skynetMinimumMargin, coreChargeExpected, pcdbId, }) => {
            setFieldValue('componentId', componentId);
            setFieldValue('name', name);
            setFieldValue('partSpecialistLevel', partSpecialistLevel);
            setFieldValue('automaticallyOrder', automaticallyOrder);
            setFieldValue('skynetMinimumMargin', skynetMinimumMargin);
            setFieldValue('coreChargeExpected', coreChargeExpected);
            setFieldValue('pcdbId', pcdbId);
            if (!componentId) {
                setFieldValue('addAsDynamicComponent', false);
            }
            setIsTireSelected((name === null || name === void 0 ? void 0 : name.toLowerCase()) === 'tire');
        }} disabled={!canEdit || isFitment} forceComponentSelection={forceComponentSelection}/>
        </RowCompact>
        <RowCompact item xs={12}>
          <TextField variant="outlined" value={isTireComponent ? 1 : values.quantity} type="number" name="quantity" label="Part Quantity" onChange={(e) => {
            const qtyInputVal = parseInt(e.target.value, 10);
            setValues(Object.assign(Object.assign({}, values), { quantity: qtyInputVal }));
            if (qtyInputVal > 25) {
                setIsConfirmQtyModalOpen(!isConfirmQtyModalOpen);
            }
        }} fullWidth disabled={!canEditPricing || isTireComponent || isFitment}/>
        </RowCompact>
      </DrawerFormBorderedSection>
      <DrawerFormBorderedSection container spacing={1}>
        <RowCompact item xs={6} style={{
            fontSize: '13px',
            fontWeight: 'bold',
        }}>
          System Quoted: {quotedCost}
        </RowCompact>
        <RowCompact item xs={6} style={{
            textAlign: 'end',
            fontSize: '13px',
            fontWeight: 'bold',
        }}>
          Current Total: ${totalCost}
        </RowCompact>
      </DrawerFormBorderedSection>
      <DrawerFormBorderedSection container spacing={1}>
        <RowCompact item xs={6}>
          <TextField variant="outlined" value={values.cost} type="number" name="cost" label="Part Cost" fullWidth disabled={!canEdit} InputProps={{
            startAdornment: (<InputAdornment position="start">
                  <AttachMoneyRoundedIcon />
                </InputAdornment>),
        }}/>
        </RowCompact>
        <RowCompact item xs={6}>
          <TextField variant="outlined" value={values.price} type="number" name="price" label="Part Price" fullWidth disabled={!canEditPricing || isFitment} InputProps={{
            startAdornment: (<InputAdornment position="start">
                  <AttachMoneyRoundedIcon />
                </InputAdornment>),
        }}/>
        </RowCompact>
        <RowCompact item xs={12} style={{
            textAlign: 'end',
            padding: '0',
        }}>
          <PriceMargin price={values.price} cost={values.cost}/>
        </RowCompact>
      </DrawerFormBorderedSection>

      <DrawerFormBorderedSection container spacing={1}>
        <RowCompact item xs={12}>
          <TextField variant="outlined" value={values.partNumber} type="string" name="partNumber" label="Part Number" fullWidth disabled={!canEdit}/>
        </RowCompact>
        {values.coreChargeExpected && (<RowCompact item xs={12}>
            <TextField variant="outlined" value={values.coreChargeCost} type="number" name="coreChargeCost" label="Core Charge Cost" fullWidth disabled={!canEdit} InputProps={{
                startAdornment: (<InputAdornment position="start">
                    <AttachMoneyRoundedIcon />
                  </InputAdornment>),
            }}/>
          </RowCompact>)}
        <RowCompact item xs={12}>
          <Field name="partDistributor">
            {({ field }) => (<PartDistributorAutocomplete value={field.value} isAuthorized={isAuthorized} name="partDistributor" label="Part Distributor" onSelect={(partDistributor) => {
                setFieldValue('partDistributor', partDistributor);
                if ((partDistributor === null || partDistributor === void 0 ? void 0 : partDistributor.name) === 'Other') {
                    setFieldValue('partDistributorName', '');
                }
                else {
                    setFieldValue('partDistributorName', partDistributor === null || partDistributor === void 0 ? void 0 : partDistributor.name);
                }
                if ((partDistributor === null || partDistributor === void 0 ? void 0 : partDistributor.name) === 'Customer Provided') {
                    setFieldValue('price', 0);
                    setFieldValue('cost', 0);
                }
                setFieldValue('partDistributorId', partDistributor === null || partDistributor === void 0 ? void 0 : partDistributor.id);
                setFieldValue('address', '');
                setFieldValue('notes', '');
                setFieldValue('partDistributorLocationId', '');
            }}/>)}
          </Field>
        </RowCompact>
        {((_a = values.partDistributor) === null || _a === void 0 ? void 0 : _a.name) === 'Other' && (<RowCompact item xs={12}>
            <TextField variant="outlined" value={values.partDistributorName} type="string" name="partDistributorName" label="Part Distributor Name" fullWidth disabled={!canEdit}/>
          </RowCompact>)}
        <RowCompact item xs={12}>
          <PlacesAndPartDistributorLocationsAutoComplete variant="outlined" label="Distributor Address" address={values.address} onSelect={(value) => {
            onAddressSelect(value, setFieldValue);
        }} searchOptions={{
            componentRestrictions: { country: 'us' },
            types: ['address'],
        }} disabled={isDistributorAutocompleteDisabled} partDistributorId={values.partDistributorId} isHub={values.partDistributorName === 'RepairSmith Hub'}/>
        </RowCompact>
      </DrawerFormBorderedSection>

      {values.address && (<RowCompact item xs={12}>
          <TextField variant="outlined" value={values.notes} type="string" name="notes" label="Vendor Notes" fullWidth disabled={!canEdit}/>
        </RowCompact>)}
      {!isFitment && (<>
          <RowCompact item xs={12}>
            Special Order{' '}
            <CheckboxField name="specialOrder" color="primary" disabled={!canEdit}/>
          </RowCompact>
          {!isFixedComponent && (<RowCompact item xs={12}>
              <label htmlFor="part-add-as-dynamic-component">
                Additional Required Component
              </label>{' '}
              {!hasEngine && (<ToolTipInfo>
                  This repair order is missing Vehicle Information. Additional
                  Required Components cannot be selected without full vehicle
                  information.
                </ToolTipInfo>)}
              <CheckboxField id="part-add-as-dynamic-component" name="addAsDynamicComponent" color="primary" disabled={isAddAsDynamicComponentDisabled}/>
            </RowCompact>)}
        </>)}
      {values.specialOrder && (<RowCompact item xs={12}>
          <TextField variant="outlined" value={values.leadTime} type="number" name="leadTime" label="Lead Time (Days)" fullWidth disabled={!canEdit}/>
        </RowCompact>)}
      {values.addAsDynamicComponent && (<RowCompact item xs={12}>
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
            Dynamic Component
          </span>
          <TooltipInfo infoColor={colors.blue13} contentWidth="700px">
            This component was added automatically as it has been marked as
            necessary by our system when performing this repair on this vehicle.
          </TooltipInfo>
        </RowCompact>)}
    </Grid>);
};
export default EditPartDrawerGeneral;
