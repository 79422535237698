import { createSelector } from 'reselect';
export const selectMpi = (state) => state.admin.mpi;
export const selectMpiInspectionDetailByRepairId = createSelector(selectMpi, (mpiData) => mpiData.inspectionDetailByRepairId);
export const selectMpiInspectionsByRepairRequestId = createSelector(selectMpi, (mpiData) => { var _a; return (_a = mpiData.inspectionByRepairId) !== null && _a !== void 0 ? _a : {}; });
export const selectMpiInspectionByRepairRequestId = (repairRequestId) => createSelector(selectMpiInspectionsByRepairRequestId, (inspectionByRepairId) => inspectionByRepairId ? inspectionByRepairId[repairRequestId] : null);
export const selectMpiPerformedByIdByRepairRequestId = (repairRequestId) => createSelector(selectMpiInspectionsByRepairRequestId, (inspectionByRepairId) => {
    var _a;
    return inspectionByRepairId
        ? (_a = inspectionByRepairId[repairRequestId]) === null || _a === void 0 ? void 0 : _a.performedById
        : undefined;
});
export const selectMpiConsumerIdByRepairRequestId = (repairRequestId) => createSelector(selectMpiInspectionsByRepairRequestId, (inspectionByRepairId) => {
    var _a;
    return inspectionByRepairId
        ? (_a = inspectionByRepairId[repairRequestId]) === null || _a === void 0 ? void 0 : _a.consumerId
        : undefined;
});
export const selectMpiInspectionDetailByRepairRequestId = (repairRequestId) => createSelector(selectMpiInspectionDetailByRepairId, (inspectionByRepairId) => inspectionByRepairId ? inspectionByRepairId[repairRequestId] : null);
export const selectInspectionPerformedStatus = createSelector(selectMpi, (mpiData) => mpiData.inspectionPerformedStatus);
