import React, { useEffect, useState } from 'react';
import { Alert, Button, Snackbar as MuiSnackbar } from 'src/components/mui';
import { ErrorRounded as ErrorRoundedIcon } from 'src/components/mui/icons';
import useScrollPosition from 'src/AdminApp/hooks/useScrollPosition';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
const Snackbar = styled(MuiSnackbar) `
  ${theme.breakpoints.up('sm')} {
    top: ${({ isScrollOnTop }) => (isScrollOnTop ? '90px' : '0')};
  }
`;
const MpiPendingAlert = ({ open, onNotifyCustomer, onManualClose, }) => {
    const [scrollPosition] = useScrollPosition();
    const [isScrollOnTop, setIsScrollOnTop] = useState(true);
    useEffect(() => {
        if (scrollPosition === 0)
            setIsScrollOnTop(true);
        else
            setIsScrollOnTop(false);
    }, [scrollPosition]);
    return (<Snackbar className="snackBarRoot" open={open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} isScrollOnTop={isScrollOnTop}>
      <Alert classes={{ root: 'userAlert', message: 'userAlertMessage' }} severity="error" icon={<ErrorRoundedIcon fontSize="inherit"/>} action={<>
            <Button color="primary" size="small" onClick={onNotifyCustomer}>
              Notify Customer
            </Button>
            <Button color="primary" size="small" onClick={() => onManualClose('setShowMpiPendingAlert')}>
              Dismiss
            </Button>
          </>}>
        MPI Pending
      </Alert>
    </Snackbar>);
};
export default MpiPendingAlert;
