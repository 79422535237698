import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const BalanceTracker = styled.div `
  margin: 20px 16px 40px;
  border-radius: 4px;
  border: solid 1px ${colors.lightGrey};
  background-color: ${colors.GRAY_FA};
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
`;
export const BalanceRow = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const BalanceCell = styled.div `
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${colors.GRAY_3F};
`;
export const BalanceOwed = styled.div `
  color: ${({ color }) => colors[color]};
  margin-left: 10px;
`;
export const PaymentAmount = styled.div `
  min-width: 155px;
  max-width: 300px;
`;
export const Amount = styled.div `
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  color: ${colors.GRAY_3F};
`;
