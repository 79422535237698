import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import React from 'react';
import { SimpleModal } from 'src/components/layout';
import NotAuthorized from 'src/components/layout/NotAuthorized';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import { Catalog } from 'src/PricingApp/models/enums/Catalog';
import { ManufacturerStatus } from 'src/PricingApp/models/enums/ManufacturerStatus';
import useCatalogManufacturerDrawer, { CatalogManufacturerDrawerMode, } from './useCatalogManufacturerDrawer';
const CatalogManufacturerDrawer = ({ catalogManufacturerDrawerMode, onClose, manufacturer, catalogManufacturer, }) => {
    const { isPricingAdmin, catalogId, priority, isPriorityDirty, setPriorityDirty, manufacturerName, isManufacturerNameDirty, setManufacturerNameDirty, manufacturerStatus, removeCatalogModalIsOpen, setRemoveCatalogModalIsOpen, handleCatalogIdChange, handlePriorityChange, handleManufacturerNameChange, handleManufacturerStatusChange, isManufacturerDisabled, isInvalidPriority, isInvalidManufacturer, hasErrors, handleSubmit, handleRemoveCatalog, } = useCatalogManufacturerDrawer({
        catalogManufacturerDrawerMode,
        catalogManufacturer,
        manufacturer,
        onClose,
    });
    const renderTitle = () => CatalogManufacturerDrawerMode[catalogManufacturerDrawerMode].replace('_', ' ');
    const renderManufacturer = () => (<>
      <Typography>
        <b>Manufacturer</b>
      </Typography>
      <Grid item xs={12}>
        <TextField label="Manufacturer Name" required fullWidth variant="outlined" value={manufacturerName} onChange={handleManufacturerNameChange} onChangeCapture={() => setManufacturerNameDirty(true)} disabled={isManufacturerDisabled()} error={isManufacturerNameDirty && isInvalidManufacturer()} helperText={isManufacturerNameDirty &&
            isInvalidManufacturer() &&
            'Manufacturer Name is required'}/>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined" disabled={isManufacturerDisabled()}>
          <InputLabel>Prioritization</InputLabel>
          <Select label="Prioritization" value={`${manufacturerStatus}`} onChange={handleManufacturerStatusChange}>
            {Object.entries(ManufacturerStatus.values).map(([value, displayName]) => (<MenuItem key={value} value={value}>
                  {displayName}
                </MenuItem>))}
          </Select>
        </FormControl>
      </Grid>
    </>);
    const renderCatalog = () => catalogManufacturerDrawerMode !==
        CatalogManufacturerDrawerMode.EDIT_MANUFACTURER ? (<>
        <Typography>
          <b>Manufacturer Catalog</b>
        </Typography>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Catalog</InputLabel>
            <Select label="Catalog" value={catalogId} onChange={handleCatalogIdChange}>
              {Object.entries(Catalog.values).map(([value, displayName]) => (<MenuItem key={value} value={value}>
                  {displayName}
                </MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
        <Tooltip title="Priority: 0 - Blacklisted, 1+ Prioritized">
          <Grid item xs={12}>
            <TextField label="Priority" required fullWidth variant="outlined" type="number" id="priority" value={priority} onChange={handlePriorityChange} onChangeCapture={() => setPriorityDirty(true)} error={isPriorityDirty && isInvalidPriority()} helperText={isPriorityDirty &&
            isInvalidPriority() &&
            'Priority is required and must be >= 0'}/>
          </Grid>
        </Tooltip>
      </>) : null;
    const renderRemoveCatalog = () => catalogManufacturerDrawerMode ===
        CatalogManufacturerDrawerMode.EDIT_CATALOG ? (<Grid item xs={12}>
        <Button onClick={() => setRemoveCatalogModalIsOpen(true)} variant="contained" color="secondary" size="large" fullWidth>
          Remove Catalog
        </Button>
      </Grid>) : null;
    return (<>
      <SimpleDrawer open={catalogManufacturerDrawerMode !== CatalogManufacturerDrawerMode.CLOSED} onClose={onClose}>
        <SimpleDrawerHeader title={renderTitle()} onClose={onClose}/>

        <SimpleDrawerContent>
          {isPricingAdmin ? (<Grid container spacing={2} style={{ justifyContent: 'center', alignItems: 'center' }}>
              {renderManufacturer()}
              {renderCatalog()}
              {renderRemoveCatalog()}
            </Grid>) : (<NotAuthorized targetRole="Pricing_Admin"/>)}
        </SimpleDrawerContent>
        <SimpleDrawerFooter>
          <FooterActionButton onClick={handleSubmit} disabled={!isPricingAdmin || hasErrors()}>
            Save
          </FooterActionButton>
        </SimpleDrawerFooter>
      </SimpleDrawer>
      <SimpleModal title="Confirm Remove Catalog" open={removeCatalogModalIsOpen} onClose={() => setRemoveCatalogModalIsOpen(false)} simpleTwoButtonRowProps={{
            onCancel: () => setRemoveCatalogModalIsOpen(false),
            onSubmit: handleRemoveCatalog,
            submitText: 'Remove Catalog',
            submitIsDestructive: true,
        }}>
        This is a terminal operation. Are you sure you want to remove this
        Catalog?
      </SimpleModal>
    </>);
};
export default CatalogManufacturerDrawer;
