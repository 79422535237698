import React from 'react';
import { VerifiedUser } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { colors } from 'src/styles/theme';
const AuthorizedSubtitle = () => {
    return (<Typography sx={{ display: 'flex', padding: '8px' }} align="center" justifyContent="center" alignItems="center" variant="subtitle1">
      AUTHORIZED{' '}
      <VerifiedUser style={{
            paddingLeft: 8,
            color: colors.green,
        }}/>
    </Typography>);
};
export default AuthorizedSubtitle;
