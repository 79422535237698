import { handleActions } from 'redux-actions';
import { isNull, union as _union } from 'lodash';
import { ActionCreators as PaymentsActions } from 'src/AdminApp/modules/payments/actions';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byWorkOrderId: {},
    allIds: [],
};
const mapInvoice = (invoice) => {
    return !invoice
        ? null
        : Object.assign({}, invoice);
};
const successReducer = (state, action) => {
    var _a, _b;
    const invoices = (_b = (_a = Array.from(action.payload)) === null || _a === void 0 ? void 0 : _a.filter((x) => Boolean(x)).map((x) => mapInvoice(x))) !== null && _b !== void 0 ? _b : [];
    if (!invoices.length)
        return state;
    if (isNull(invoices[0]))
        return state;
    const workOrderId = action.meta.workOrderId || invoices[0].workOrderId;
    const allIds = _union(state.allIds, workOrderId);
    const byWorkOrderId = Object.assign(Object.assign({}, state.byWorkOrderId), { [workOrderId]: {} });
    invoices.forEach((invoice) => {
        var _a;
        const id = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.workOrderResponsibleParty) === null || _a === void 0 ? void 0 : _a.id;
        if (!id)
            return;
        byWorkOrderId[workOrderId][id] = invoice;
    });
    return Object.assign(Object.assign({}, state), { byWorkOrderId,
        allIds });
};
const addPaymentSuccessReducer = (state, action) => {
    var _a;
    const invoice = action === null || action === void 0 ? void 0 : action.payload;
    const workOrderId = ((_a = action === null || action === void 0 ? void 0 : action.meta) === null || _a === void 0 ? void 0 : _a.workOrderId) || (invoice === null || invoice === void 0 ? void 0 : invoice.workOrderId);
    const allIds = _union(state.allIds, workOrderId);
    const byWorkOrderId = Object.assign({}, state.byWorkOrderId);
    if (invoice) {
        byWorkOrderId[workOrderId] = Object.assign(Object.assign({}, state.byWorkOrderId[workOrderId]), { [invoice.workOrderResponsibleParty.id]: invoice });
    }
    return Object.assign(Object.assign({}, state), { byWorkOrderId,
        allIds });
};
const invoicesSuccessReducer = (state, action) => {
    const invoiceMap = {};
    const invoices = action.payload;
    // eslint-disable-next-line no-return-assign
    invoices.forEach((x) => (invoiceMap[x.workOrderResponsibleParty.id] = x));
    const { workOrderId } = action.meta;
    const allIds = _union(state.allIds, workOrderId);
    const byWorkOrderId = Object.assign(Object.assign({}, state.byWorkOrderId), { [workOrderId]: invoiceMap });
    return Object.assign(Object.assign({}, state), { byWorkOrderId,
        allIds });
};
const successSuggestedRepairReducer = (state, action) => {
    const { workOrderId } = action.meta;
    const invoice = state.byWorkOrderId[workOrderId];
    const response = action.payload;
    const byWorkOrderId = Object.assign(Object.assign({}, state.byWorkOrderId), { [workOrderId]: Object.assign(Object.assign({}, state.byWorkOrderId[workOrderId]), { [invoice.workOrderResponsibleParty.id]: Object.assign(Object.assign({}, invoice), { modifiedSuggestedRepair: response }) }) });
    return Object.assign(Object.assign({}, state), { byWorkOrderId });
};
const reducers = handleActions({
    [ActionCreators.INVOICE.SUCCESS.type]: successReducer,
    [ActionCreators.INVOICES.SUCCESS.type]: invoicesSuccessReducer,
    [ActionCreators.CREATE_INVOICE.SUCCESS.type]: successReducer,
    [ActionCreators.SEND_INVOICE.SUCCESS.type]: successReducer,
    [ActionCreators.AUTHORIZE_INVOICE.SUCCESS.type]: successReducer,
    [ActionCreators.REMOVE_SUBLET.SUCCESS.type]: successSuggestedRepairReducer,
    [ActionCreators.UPDATE_SUGGESTED_REPAIR.SUCCESS.type]: successSuggestedRepairReducer,
    [PaymentsActions.REMOVE_PAYMENT.SUCCESS.type]: successReducer,
    [PaymentsActions.ADD_PAYMENT.SUCCESS.type]: addPaymentSuccessReducer,
}, DEFAULT_STATE);
export default reducers;
