import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTimeSlot } from 'src/AdminApp/modules/quickRo/selectors';
import { scheduleContext } from '../util';
import { TIME_12H_FORMAT } from 'src/timeConstants';
import { saveTimeSlot } from 'src/AdminApp/modules/quickRo/actions';
import moment from 'moment';
const useTimeSlot = ({ date, slot, time, mechanicFilterEnabled, }) => {
    const dispatch = useDispatch();
    const timeSlot = useSelector(selectTimeSlot);
    const { isSelectBtnDisabled } = useContext(scheduleContext);
    const { mechanics } = slot;
    const { vans } = slot;
    const van = vans ? vans[0] : undefined;
    const vanId = (van === null || van === void 0 ? void 0 : van.skeduloResourceId) || slot.vanId;
    const isSelected = (timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot.date) === date && (timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot.time) === time;
    const startTime = moment(time, TIME_12H_FORMAT);
    const slotEnd = moment(slot.windowEnd, TIME_12H_FORMAT);
    const appointmentTime = moment(startTime).add(slot.offset, 'm');
    const btnText = isSelected ? 'Change' : 'Select';
    const mechanicsWithoutId = mechanics === null || mechanics === void 0 ? void 0 : mechanics.filter((mechanic) => !(mechanic === null || mechanic === void 0 ? void 0 : mechanic.technicianId));
    const enableTimeSlot = isSelected ||
        (!timeSlot &&
            !isSelectBtnDisabled &&
            (!mechanicFilterEnabled ||
                ((mechanicsWithoutId === null || mechanicsWithoutId === void 0 ? void 0 : mechanicsWithoutId.length) === 0 && !!(van === null || van === void 0 ? void 0 : van.deliveryVanId))));
    const handleClick = () => {
        if (isSelected) {
            dispatch(saveTimeSlot(null));
            return;
        }
        const current = Array.isArray(slot) ? slot[0] : slot;
        dispatch(saveTimeSlot({
            date,
            slot: current,
            time,
        }));
    };
    return {
        enableTimeSlot,
        handleClick,
        appointmentTime,
        vanId,
        startTime,
        slotEnd,
        btnText,
        mechanics,
        van,
    };
};
export default useTimeSlot;
