import React from 'react';
import { ActionButton } from 'src/components/buttons';
import SendResetPasswordModal from 'src/AdminApp/containers/consumers/SendResetPassword/SendResetPasswordModal';
import useSendResetPassword from 'src/AdminApp/containers/consumers/SendResetPassword/useSendResetPassword';
const SendResetPassword = ({ email, isFleet = false, }) => {
    const { toggleModal, openModal, onResetPasswordClick } = useSendResetPassword({ isFleet, email });
    return (<div>
      <ActionButton onClick={toggleModal}>Send Password Reset</ActionButton>
      <SendResetPasswordModal openModal={openModal} toggleModal={toggleModal} isFleet={isFleet} onResetPasswordClick={onResetPasswordClick}/>
    </div>);
};
export default SendResetPassword;
