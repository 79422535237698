import React from 'react';
import { Grid, Container } from 'src/components/mui';
import ShopsDashboard from 'src/AdminApp/containers/shops/ShopsDashboard';
const Shops = () => {
    const optional = '';
    return (<Container maxWidth="xl">
      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item md={12}>
          {optional}
        </Grid>
      </Grid>
      <ShopsDashboard />
    </Container>);
};
export default Shops;
