import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarToday, ChevronLeft, ChevronRight, } from 'src/components/mui/icons';
import { IconButton, StaticDatePicker } from 'src/components/mui';
import moment from 'moment';
import { selectVisitDate } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { setVisitDate } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { DATE_YMD_FORMAT, MONTH_AND_DAY_FORMAT } from 'src/timeConstants';
import { JumpButton, DateClicker, DateClickerCaption, DatePickerRow, DatePickerMenu, } from './DatePicker.styles';
const DatePickerComponent = ({ onChange, }) => {
    const dispatch = useDispatch();
    const visitDate = useSelector(selectVisitDate);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleSetPrevDate = () => {
        const newDate = moment(visitDate)
            .subtract(1, 'day')
            .format(DATE_YMD_FORMAT);
        dispatch(setVisitDate(newDate));
        onChange(newDate);
    };
    const handleSetNextDate = () => {
        const newDate = moment(visitDate).add(1, 'day').format(DATE_YMD_FORMAT);
        dispatch(setVisitDate(newDate));
        onChange(newDate);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDateChange = (date) => {
        dispatch(setVisitDate(date));
        setAnchorEl(null);
        onChange(moment(date, DATE_YMD_FORMAT).format(DATE_YMD_FORMAT));
    };
    return (<DatePickerRow>
      <DateClicker>
        <IconButton onClick={handleSetPrevDate}>
          <ChevronLeft />
        </IconButton>
        <DateClickerCaption>
          <span>{moment(visitDate).format(MONTH_AND_DAY_FORMAT)}</span>
        </DateClickerCaption>

        <IconButton onClick={handleSetNextDate}>
          <ChevronRight />
        </IconButton>
      </DateClicker>

      <JumpButton startIcon={<CalendarToday />} aria-controls="basic-menu" aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        Jump to date
      </JumpButton>
      <DatePickerMenu className="VisitDateMenu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <StaticDatePicker value={moment(visitDate)} onChange={handleDateChange} slotProps={{
            actionBar: {
                hidden: true,
            },
        }}/>
      </DatePickerMenu>
    </DatePickerRow>);
};
export default DatePickerComponent;
