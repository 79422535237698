var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useMemo, useEffect, useRef, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { selectRequestIsLocked } from 'src/AdminApp/modules/requestLock/selectors';
import { selectFourCornerFromHeader, selectFourCornerHasPhotos, selectFourCornerModal, } from 'src/AdminApp/modules/requests/selectors';
import { selectMakes, selectVehiclesByPlate, selectVehiclesByVin, } from 'src/AdminApp/modules/autoselection/selectors';
import { selectWorkflowCurrentTask } from 'src/AdminApp/modules/workflows/selectors';
import { selectShowActionButtonsNonMain } from 'src/modules/auth/selectors';
import { closeFourCornerModal, closeMobileCarDetails, deleteFourCornerPhoto, markFourCornerPhotoUploading, openFourCornerModal, unmarkFourCornerPhotoUploading, uploadFourCornerPhotos, } from 'src/AdminApp/modules/requests/actions';
import { getVehicleDescriptionByPlate, getVehicleDescriptionByVin, } from 'src/AdminApp/modules/autoselection/actions';
import { completeTechnicianWorkflowOnSiteSingleTask, getTechnicianOnSiteWorkflowByRef, } from 'src/AdminApp/modules/workflows/actions';
import { CAR_MSG, VIN_LENGTH, PLATE_NUMBER_LENGTH, } from 'src/AdminApp/modules/api/constants';
import { FOUR_CORNER_PHOTO_MINIMUM, FOUR_CORNER_PHOTO_MINIMUM_WITH_MILEAGE, } from 'src/constants';
import TechnicianWorkflowState from 'src/AdminApp/models/enums/TechnicianWorkflowState';
import { normalizeVehicle, onDecodedVehicleSelect } from '../utils';
const ADDITIONAL_TYPE = 'ADDITIONAL';
export const initCarState = {
    mileage: undefined,
    plateNumber: undefined,
    plateNumberState: undefined,
    vin: undefined,
    mileageNotAvailable: undefined,
};
const initLoadingState = {
    DASHBOARD: false,
    FRONT_LEFT: false,
    FRONT_RIGHT: false,
    REAR_LEFT: false,
    REAR_RIGHT: false,
    VIN: false,
};
const photosOrder = [
    'FRONT_LEFT',
    'FRONT_RIGHT',
    'REAR_LEFT',
    'REAR_RIGHT',
    'DASHBOARD',
    'VIN',
];
const useFourCornersDrawer = ({ car, handleUpdateCar, referenceNum, isCurrentTask, isDone, }) => {
    var _a, _b;
    const dispatch = useDispatch();
    const saveBtnText = !isCurrentTask ? 'Save & Close' : 'Continue';
    const carPhotos = [
        {
            name: 'Driver - Front',
            image: null,
            preview: '',
            type: 'FRONT_LEFT',
            status: 'ACTIVE',
        },
        {
            name: 'Passenger - Front',
            image: null,
            preview: '',
            type: 'FRONT_RIGHT',
            status: 'ACTIVE',
        },
        {
            name: 'Driver - Rear',
            image: null,
            preview: '',
            type: 'REAR_LEFT',
            status: 'ACTIVE',
        },
        {
            name: 'Passenger - Rear',
            image: null,
            preview: '',
            type: 'REAR_RIGHT',
            status: 'ACTIVE',
        },
        {
            name: 'Dash',
            image: null,
            preview: '',
            type: 'DASHBOARD',
            status: 'ACTIVE',
        },
        {
            name: 'VIN',
            image: null,
            preview: '',
            type: 'VIN',
            status: 'ACTIVE',
        },
    ];
    // STATES
    const [loading, setLoading] = useState(false);
    const [carState, setCarState] = useState({
        plateNumber: car.plateNumber,
        plateNumberState: car.plateNumberState,
        vin: car.vin,
        mileageNotAvailable: car.mileageNotAvailable,
    });
    const isPlateComplete = carState.plateNumberState &&
        ((_a = carState.plateNumber) !== null && _a !== void 0 ? _a : '').length >= PLATE_NUMBER_LENGTH;
    const [vehicle, setVehicle] = useState();
    const [vinOrPlate, setVinOrPlate] = useState();
    const [vinError, setVinError] = useState();
    const [plateError, setPlateError] = useState();
    const [isValid, setIsValid] = useState(false);
    const [carouselOpen, setCarouselOpen] = useState(false);
    const [carouselIndex, setCarouselIndex] = useState();
    const [currentImage, setCurrentImage] = useState();
    const [showDecodePlateAlert, setShowDecodePlateAlert] = useState(false);
    const [isLoading4CornerPhoto, setIsLoading4CornerPhoto] = useState(initLoadingState);
    const [failed4CornerPhoto, setFailed4CornerPhoto] = useState(initLoadingState);
    const [isLoadingAdditional, setIsLoadingAdditional] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePhotoType, setDeletePhotoType] = useState();
    const [deletePhotoLoading, setDeletePhotoLoading] = useState(false);
    const [canLoadVinInformation, setCanLoadVinInformation] = useState(false);
    const [isLicensePlateOnFocus, setIsLicensePlateOnFocus] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState('');
    const [isVehicleChangingOldCarRequest, setIsVehicleChangingOldCarRequest] = useState(false);
    const [confirmationNewVehicleText, setConfirmationNewVehicleText] = useState([]);
    const [isDecodedCarSelected, setIsDecodedCarSelected] = useState(false);
    const [isVinCompleted, setIsVinComplete] = useState(((_b = carState === null || carState === void 0 ? void 0 : carState.vin) === null || _b === void 0 ? void 0 : _b.length) === VIN_LENGTH);
    const [decodedVehicle, setDecodedVehicle] = useState();
    // SELECTORS
    const requestLocked = useSelector(selectRequestIsLocked);
    const openFourCorner = useSelector(selectFourCornerModal);
    const hasPhotos = useSelector(selectFourCornerHasPhotos(referenceNum));
    const fromHeader = useSelector(selectFourCornerFromHeader);
    const currentTask = useSelector(selectWorkflowCurrentTask(referenceNum));
    const byVin = useSelector(selectVehiclesByVin);
    const byPlate = useSelector(selectVehiclesByPlate);
    const makes = useSelector(selectMakes);
    const isShowActionButtonsNonMain = useSelector(selectShowActionButtonsNonMain);
    // REFS
    const formUpdate = useRef(false);
    const makesRef = useRef(makes);
    const sliderRef = useRef(null);
    // MEMOS
    const photos = useMemo(() => {
        var _a;
        return (_a = car.photos) === null || _a === void 0 ? void 0 : _a.filter(({ description }) => !description.startsWith(ADDITIONAL_TYPE));
    }, [car === null || car === void 0 ? void 0 : car.photos]);
    const additionalCarPhotos = useMemo(() => {
        var _a;
        return (_a = car.photos) === null || _a === void 0 ? void 0 : _a.filter(({ description }) => description.startsWith(ADDITIONAL_TYPE));
    }, [car === null || car === void 0 ? void 0 : car.photos]);
    const orderedPhotos = useMemo(() => photosOrder
        .map((type) => photos === null || photos === void 0 ? void 0 : photos.find(({ description }) => description === type))
        .filter(Boolean)
        .concat(additionalCarPhotos), [additionalCarPhotos, photos]);
    // EFFECTS
    useEffect(() => {
        if (openFourCorner && formUpdate.current) {
            setCarState({
                mileage: car.mileage,
                plateNumber: car.plateNumber,
                plateNumberState: car.plateNumberState,
                vin: car.vin,
                mileageNotAvailable: car.mileageNotAvailable,
            });
            formUpdate.current = false;
        }
    }, [
        car.mileage,
        car.plateNumber,
        car.plateNumberState,
        car.vin,
        openFourCorner,
        car.mileageNotAvailable,
    ]);
    useEffect(() => {
        makesRef.current = makes;
    }, [makes]);
    useEffect(() => {
        var _a, _b;
        const { mileage, vin, mileageNotAvailable } = carState;
        const thesePhotos = ((_a = car.photosUploading) !== null && _a !== void 0 ? _a : []).filter((photo) => !photo.startsWith(ADDITIONAL_TYPE) &&
            photo !== 'VIN' &&
            mileageNotAvailable
            ? photo !== 'DASHBOARD'
            : true);
        const uploadedMainPhotos = ((_b = car.photos) !== null && _b !== void 0 ? _b : []).filter((photo) => !photo.description.startsWith(ADDITIONAL_TYPE) &&
            photo.description !== 'VIN' &&
            mileageNotAvailable
            ? photo.description !== 'DASHBOARD'
            : true);
        const isMileageValid = !mileageNotAvailable === !!mileage;
        const photoRequiredLength = mileageNotAvailable
            ? FOUR_CORNER_PHOTO_MINIMUM
            : FOUR_CORNER_PHOTO_MINIMUM_WITH_MILEAGE;
        setIsValid(((vin === null || vin === void 0 ? void 0 : vin.length) === VIN_LENGTH ||
            (!!(carState === null || carState === void 0 ? void 0 : carState.plateNumber) && !!(carState === null || carState === void 0 ? void 0 : carState.plateNumberState))) &&
            isMileageValid &&
            (thesePhotos.length >= photoRequiredLength ||
                uploadedMainPhotos.length >= photoRequiredLength) &&
            confirmationNewVehicleText.length === 0);
    }, [
        car.photos,
        car.photosUploading,
        carState,
        car.allUploaded,
        confirmationNewVehicleText,
        isVinCompleted,
        openFourCorner,
    ]);
    useEffect(() => {
        var _a;
        const vinComplete = ((_a = carState === null || carState === void 0 ? void 0 : carState.vin) === null || _a === void 0 ? void 0 : _a.length) === VIN_LENGTH;
        if (vinComplete &&
            isDecodedCarSelected &&
            decodedVehicle &&
            openFourCorner &&
            vehicle) {
            toast.success(`Vehicle selected. Click '${saveBtnText}' to save vehicle.`);
        }
    }, [carState === null || carState === void 0 ? void 0 : carState.vin, isVinCompleted, openFourCorner]); // eslint-disable-line
    useEffect(() => {
        var _a;
        const vinComplete = ((_a = carState === null || carState === void 0 ? void 0 : carState.vin) === null || _a === void 0 ? void 0 : _a.length) === VIN_LENGTH;
        if (canLoadVinInformation) {
            if (vinComplete &&
                (!isDecodedCarSelected || !decodedVehicle) &&
                openFourCorner) {
                onDecodeVin();
            }
        }
        else if (carState === null || carState === void 0 ? void 0 : carState.vin) {
            setCanLoadVinInformation(true);
        }
    }, [carState === null || carState === void 0 ? void 0 : carState.vin, isDecodedCarSelected, decodedVehicle]); // eslint-disable-line
    // METHODS
    const handleOpen = () => {
        dispatch(openFourCornerModal());
    };
    const handleClose = () => {
        dispatch(closeFourCornerModal());
        setCarState(initCarState);
        setDecodedVehicle(undefined);
        setIsDecodedCarSelected(false);
        setIsValid(false);
        setLoading(false);
        setVehicle(undefined);
        setVinError(undefined);
        setVinOrPlate(undefined);
        setDeletePhotoType(undefined);
        setDeletePhotoLoading(false);
        setConfirmationNewVehicleText([]);
        formUpdate.current = true;
        if (fromHeader) {
            setTimeout(() => {
                dispatch(closeMobileCarDetails());
            }, 50);
        }
        setCanLoadVinInformation(false);
    };
    const handleSelectDecodedVehicle = (selectedVehicle) => __awaiter(void 0, void 0, void 0, function* () {
        if (!(carState === null || carState === void 0 ? void 0 : carState.vin) && !(carState === null || carState === void 0 ? void 0 : carState.plateNumber))
            return;
        if (!selectedVehicle && (carState === null || carState === void 0 ? void 0 : carState.vin)) {
            yield dispatch(getVehicleDescriptionByVin({ vin: carState.vin })).then((result) => {
                selectedVehicle = result.payload;
            });
        }
        if (vinOrPlate === 'plate') {
            setCarState((prev) => (Object.assign(Object.assign({}, prev), { vin: selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.vin })));
        }
        if (selectedVehicle) {
            setDecodedVehicle(selectedVehicle);
            validateSelectedVehicle(selectedVehicle);
        }
    });
    const selectCar = (selectedVehicle) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        if (!(carState === null || carState === void 0 ? void 0 : carState.vin))
            return;
        if (!selectedVehicle) {
            selectedVehicle =
                decodedVehicle ||
                    (vinOrPlate === 'plate'
                        ? byPlate[(_c = carState.plateNumber) !== null && _c !== void 0 ? _c : '']
                        : byVin[carState.vin]);
        }
        const result = yield onDecodedVehicleSelect(selectedVehicle, dispatch);
        setVehicle(result);
        setDecodedVehicle(selectedVehicle);
        toast.success(`Vehicle selected. Click '${saveBtnText}' to save vehicle.`);
    });
    const validateSelectedVehicle = (selectedVehicle) => __awaiter(void 0, void 0, void 0, function* () {
        const errors = [];
        if ((car === null || car === void 0 ? void 0 : car.year) && (car === null || car === void 0 ? void 0 : car.year) !== Number(selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.year)) {
            errors.push(`New year is: ${selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.year}`);
        }
        if ((car === null || car === void 0 ? void 0 : car.make) && (car === null || car === void 0 ? void 0 : car.make) !== (selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.make)) {
            errors.push(`New make is: ${selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.make}`);
        }
        if ((car === null || car === void 0 ? void 0 : car.model) && (car === null || car === void 0 ? void 0 : car.model) !== (selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.model)) {
            errors.push(`New model is: ${selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.model}`);
        }
        if ((car === null || car === void 0 ? void 0 : car.engineDescription) &&
            (car === null || car === void 0 ? void 0 : car.engineDescription) !== (selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.engineDescription)) {
            errors.push(`New engine is: ${selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.engineDescription}`);
        }
        if ((car === null || car === void 0 ? void 0 : car.trim) && (car === null || car === void 0 ? void 0 : car.trim) !== (selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.style)) {
            errors.push(`New trim is: ${selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.style}`);
        }
        if (errors.length > 0) {
            setConfirmationNewVehicleText(errors);
            setIsVehicleChangingOldCarRequest(true);
        }
        if (errors && errors.length === 0) {
            selectCar(selectedVehicle);
        }
    });
    const onDecodeVin = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(carState === null || carState === void 0 ? void 0 : carState.vin))
            return;
        let car = byVin[carState === null || carState === void 0 ? void 0 : carState.vin];
        if (!car) {
            const result = yield dispatch(getVehicleDescriptionByVin({ vin: carState.vin }));
            if (isSelectedVehicleInvalid(result)) {
                setVinError(CAR_MSG.VIN_NOT_EXIST);
                setVinOrPlate(undefined);
                return;
            }
            setVinError(undefined);
            car = result.payload;
        }
        if (!car) {
            setVinError(CAR_MSG.VIN_NOT_EXIST);
            return;
        }
        setDecodedVehicle(car);
        setVinOrPlate('vin');
        setIsDecodedCarSelected(true);
        handleSelectDecodedVehicle(car);
    });
    const isSelectedVehicleInvalid = (result) => {
        var _a, _b;
        return ((result === null || result === void 0 ? void 0 : result.error) ||
            ((_a = result === null || result === void 0 ? void 0 : result.payload) === null || _a === void 0 ? void 0 : _a.status) === 404 ||
            ((_b = result === null || result === void 0 ? void 0 : result.payload) === null || _b === void 0 ? void 0 : _b.vin) == null);
    };
    const onDecodePlate = () => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        let selectedVehicle = byPlate[(_d = carState === null || carState === void 0 ? void 0 : carState.plateNumber) !== null && _d !== void 0 ? _d : ''];
        if ((carState === null || carState === void 0 ? void 0 : carState.plateNumber) && !selectedVehicle) {
            const result = yield dispatch(getVehicleDescriptionByPlate({
                plate: carState.plateNumber,
                state: carState.plateNumberState,
            }));
            if ((result === null || result === void 0 ? void 0 : result.error) || ((_e = result === null || result === void 0 ? void 0 : result.payload) === null || _e === void 0 ? void 0 : _e.status) === 404) {
                setPlateError(CAR_MSG.PLATE_NOT_EXIST);
                setVinOrPlate(undefined);
                return;
            }
            selectedVehicle = result.payload;
        }
        if (!selectedVehicle) {
            setPlateError(CAR_MSG.PLATE_NOT_EXIST);
            return;
        }
        setDecodedVehicle(selectedVehicle);
        setVinOrPlate('plate');
        setIsDecodedCarSelected(true);
        handleSelectDecodedVehicle(selectedVehicle);
        setCarState((prev) => (Object.assign(Object.assign({}, prev), { vin: selectedVehicle.vin })));
    });
    const validateMileageAndSubmit = () => {
        const { mileage } = carState;
        if (!mileage || mileage === 0) {
            setConfirmationModalOpen(false);
        }
        else {
            if (mileage <= 10) {
                setConfirmationModalOpen(true);
                setConfirmationModalText('You’ve entered in a low mileage, are you sure?');
                return;
            }
            if (mileage >= 300000) {
                setConfirmationModalOpen(true);
                setConfirmationModalText('You’ve entered in a high mileage, are you sure?');
                return;
            }
        }
        handleSubmit();
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!isValid)
            return;
        setLoading(true);
        try {
            const { mileage, plateNumber, plateNumberState, vin, mileageNotAvailable, } = carState;
            let normalizedCar = car;
            if (vehicle) {
                normalizedCar = normalizeVehicle(vehicle, makesRef.current);
            }
            yield handleUpdateCar(Object.assign(Object.assign({}, normalizedCar), { mileageOut: car.mileageOut, mileage,
                plateNumber,
                plateNumberState,
                vin,
                mileageNotAvailable }), true);
            if (!isDone && isCurrentTask) {
                yield dispatch(completeTechnicianWorkflowOnSiteSingleTask(referenceNum, currentTask));
                yield dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
            }
            handleClose();
        }
        catch (e) {
            setLoading(false);
        }
    });
    const handleCarouselChange = (_last, index) => {
        var _a, _b, _c;
        const current = (_a = orderedPhotos[index]) === null || _a === void 0 ? void 0 : _a.description;
        const img = ((_b = carPhotos.find(({ type }) => type === current)) === null || _b === void 0 ? void 0 : _b.name) ||
            `Other ${index + 1 - ((_c = photos === null || photos === void 0 ? void 0 : photos.length) !== null && _c !== void 0 ? _c : 0)}`;
        setCurrentImage(img);
    };
    const handleCarouselClose = () => {
        setCarouselOpen(false);
        setCarouselIndex(undefined);
        setCurrentImage(undefined);
    };
    const handleClickImage = (type) => () => {
        handleCarouselChange(null, orderedPhotos === null || orderedPhotos === void 0 ? void 0 : orderedPhotos.findIndex(({ description }) => type === description));
        setCarouselIndex(type);
        setCarouselOpen(true);
    };
    const handlePrevImage = () => { var _a, _b; return ((_a = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _a === void 0 ? void 0 : _a.slickPrev) && ((_b = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _b === void 0 ? void 0 : _b.slickPrev()); };
    const handleNextImage = () => { var _a, _b; return ((_a = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _a === void 0 ? void 0 : _a.slickNext) && ((_b = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _b === void 0 ? void 0 : _b.slickNext()); };
    const handleDeleteModalOpen = (type) => () => {
        setDeletePhotoType(type);
        setDeleteModalOpen(true);
    };
    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
        setDeletePhotoType(undefined);
        setDeletePhotoLoading(false);
    };
    const handleDeletePhoto = () => __awaiter(void 0, void 0, void 0, function* () {
        var _f;
        setDeletePhotoLoading(true);
        dispatch(unmarkFourCornerPhotoUploading({
            photoType: deletePhotoType,
            referenceNum,
        }));
        yield dispatch(deleteFourCornerPhoto({
            carId: (_f = car.id) !== null && _f !== void 0 ? _f : 0,
            referenceNum,
            type: deletePhotoType,
        }));
        handleDeleteModalClose();
    });
    const ctaText = () => {
        if (!isDone) {
            if (!hasPhotos)
                return TechnicianWorkflowState.values.FOUR_CORNER;
            return TechnicianWorkflowState.values.FOUR_CORNER_CONTINUE;
        }
        return TechnicianWorkflowState.values.FOUR_CORNER_VIEW;
    };
    const updateCarState = (key, value) => setCarState((prev) => (Object.assign(Object.assign({}, prev), { [key]: value })));
    const handleOnChangeVin = ({ target }) => {
        if (!target)
            return;
        const { value } = target;
        setDecodedVehicle(undefined);
        setVinOrPlate(undefined);
        setVinError(undefined);
        updateCarState('vin', value);
        setIsVinComplete(value.length === VIN_LENGTH);
        setIsDecodedCarSelected(false);
    };
    const handleCancelOverrideCar = () => {
        setIsVehicleChangingOldCarRequest(false);
        setDecodedVehicle(undefined);
        setIsValid(false);
        setDecodedVehicle(undefined);
        setVinError(undefined);
        setConfirmationNewVehicleText([]);
        setCarState(Object.assign(Object.assign({}, carState), { vin: '', plateNumber: '', plateNumberState: '' }));
    };
    const handleOverrideCar = () => {
        setConfirmationNewVehicleText([]);
        setIsVehicleChangingOldCarRequest(false);
        selectCar(decodedVehicle);
        setVinError(undefined);
    };
    const lastIndex = (photos) => {
        const lastOne = photos[photos.length - 1];
        const [, index] = lastOne.description.split('_');
        return index ? parseInt(index, 10) : 1;
    };
    const noop = () => { };
    const handleChangeVehiclePhoto = (photo) => (e) => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h, _j;
        const file = ((_g = e === null || e === void 0 ? void 0 : e.target) === null || _g === void 0 ? void 0 : _g.files) && ((_h = e === null || e === void 0 ? void 0 : e.target) === null || _h === void 0 ? void 0 : _h.files[0]);
        if (!file)
            return;
        setIsLoading4CornerPhoto((prev) => (Object.assign(Object.assign({}, prev), { [photo.type]: true })));
        dispatch(markFourCornerPhotoUploading({
            photoType: photo.type,
            referenceNum,
        }));
        const uploadResponse = yield dispatch(uploadFourCornerPhotos({
            carId: (_j = car.id) !== null && _j !== void 0 ? _j : 0,
            file,
            referenceNum,
            type: photo.type,
        }));
        setFailed4CornerPhoto((prev) => (Object.assign(Object.assign({}, prev), { [photo.type]: uploadResponse.error && !navigator.onLine })));
        setIsLoading4CornerPhoto((prev) => (Object.assign(Object.assign({}, prev), { [photo.type]: false })));
    });
    const handleChangeAdditionalCarPhoto = (e) => __awaiter(void 0, void 0, void 0, function* () {
        var _k;
        const file = e.target.files && e.target.files[0];
        if (!file)
            return;
        setIsLoadingAdditional(true);
        const type = (additionalCarPhotos === null || additionalCarPhotos === void 0 ? void 0 : additionalCarPhotos.length) === 0
            ? ADDITIONAL_TYPE
            : `${ADDITIONAL_TYPE}_${lastIndex(additionalCarPhotos !== null && additionalCarPhotos !== void 0 ? additionalCarPhotos : []) + 1}`;
        dispatch(markFourCornerPhotoUploading({
            photoType: type,
            referenceNum,
        }));
        yield dispatch(uploadFourCornerPhotos({
            carId: (_k = car.id) !== null && _k !== void 0 ? _k : 0,
            file,
            referenceNum,
            type,
        }));
        setIsLoadingAdditional(false);
    });
    const updateIsLicensePlate = (state) => () => {
        setIsLicensePlateOnFocus(state);
    };
    const handleDecodeClick = isPlateComplete && !!carState.plateNumberState
        ? onDecodePlate
        : () => setShowDecodePlateAlert(true);
    const handlePlateChange = (event) => {
        setVinOrPlate(undefined);
        setPlateError(undefined);
        updateCarState('plateNumber', event.target.value);
    };
    const handlePlateNumberChange = (event) => {
        updateCarState('plateNumberState', event.target.value);
    };
    const handleMileageAvailabilityChange = (event) => {
        updateCarState('mileage', '');
        updateCarState('mileageNotAvailable', event.target.checked);
    };
    const handleMileageChange = (event) => {
        updateCarState('mileage', event.target.value);
    };
    const handleDecodePlateAlertUpdate = (state) => () => {
        setShowDecodePlateAlert(state);
    };
    const updateConfirmationModal = (state) => () => {
        setConfirmationModalOpen(state);
    };
    return {
        saveBtnText,
        carPhotos,
        loading,
        setLoading,
        carState,
        setCarState,
        vehicle,
        setVehicle,
        vinOrPlate,
        setVinOrPlate,
        vinError,
        setVinError,
        plateError,
        setPlateError,
        isValid,
        setIsValid,
        carouselOpen,
        setCarouselOpen,
        carouselIndex,
        setCarouselIndex,
        currentImage,
        setCurrentImage,
        showDecodePlateAlert,
        setShowDecodePlateAlert,
        isLoading4CornerPhoto,
        setIsLoading4CornerPhoto,
        failed4CornerPhoto,
        setFailed4CornerPhoto,
        isLoadingAdditional,
        setIsLoadingAdditional,
        deleteModalOpen,
        setDeleteModalOpen,
        deletePhotoType,
        setDeletePhotoType,
        deletePhotoLoading,
        setDeletePhotoLoading,
        canLoadVinInformation,
        setCanLoadVinInformation,
        isLicensePlateOnFocus,
        setIsLicensePlateOnFocus,
        confirmationModalOpen,
        updateConfirmationModal,
        confirmationModalText,
        setConfirmationModalText,
        isVehicleChangingOldCarRequest,
        setIsVehicleChangingOldCarRequest,
        confirmationNewVehicleText,
        setConfirmationNewVehicleText,
        isDecodedCarSelected,
        setIsDecodedCarSelected,
        isVinCompleted,
        setIsVinComplete,
        decodedVehicle,
        setDecodedVehicle,
        requestLocked,
        openFourCorner,
        hasPhotos,
        fromHeader,
        currentTask,
        byVin,
        byPlate,
        makes,
        isShowActionButtonsNonMain,
        photos,
        additionalCarPhotos,
        orderedPhotos,
        makesRef,
        sliderRef,
        formUpdate,
        handleOpen,
        handleClose,
        handleSelectDecodedVehicle,
        selectCar,
        validateSelectedVehicle,
        onDecodeVin,
        isSelectedVehicleInvalid,
        onDecodePlate,
        validateMileageAndSubmit,
        handleSubmit,
        handleCarouselChange,
        handleCarouselClose,
        handleClickImage,
        handlePrevImage,
        handleNextImage,
        handleDeleteModalOpen,
        handleDeleteModalClose,
        handleDeletePhoto,
        ctaText,
        updateCarState,
        handleOnChangeVin,
        handleCancelOverrideCar,
        handleOverrideCar,
        lastIndex,
        noop,
        handleChangeVehiclePhoto,
        handleChangeAdditionalCarPhoto,
        updateIsLicensePlate,
        isPlateComplete,
        handleDecodeClick,
        handlePlateChange,
        handlePlateNumberChange,
        handleMileageAvailabilityChange,
        handleMileageChange,
        handleDecodePlateAlertUpdate,
    };
};
export default useFourCornersDrawer;
