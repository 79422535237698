var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { toast } from 'src/components/SimpleToast';
import { createWorkOrderItemResponsiblePartyAuthorization, getQuoteDiagnosis, updateWorkOrderItemResponsiblePartyAuthorization, } from 'src/AdminApp/modules/requests/actions';
const useAddAuthDrawer = ({ referenceNum, workOrderId, invoice, handleClose, repairsToAuthorize, workOrderResponsiblePartyAuthorization, repairRequestId, }) => {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const adding = !workOrderResponsiblePartyAuthorization;
    const [addedRepairs, setAddedRepairs] = useState([]);
    const [pendingRepairs, setPendingRepairs] = useState([]);
    const [isConfirmSaveOpen, setIsConfirmSaveOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const authorizedRepairsSum = (_b = addedRepairs === null || addedRepairs === void 0 ? void 0 : (_a = addedRepairs.reduce((acc, cur) => acc + cur.totalPrice, 0)).toFixed) === null || _b === void 0 ? void 0 : _b.call(_a, 2);
    const unauthorizedRepairsSum = (_d = pendingRepairs === null || pendingRepairs === void 0 ? void 0 : (_c = pendingRepairs.reduce((acc, cur) => acc + cur.totalPrice, 0)).toFixed) === null || _d === void 0 ? void 0 : _d.call(_c, 2);
    const addRepair = (repair) => {
        setAddedRepairs([...addedRepairs, repair]);
    };
    const removeRepair = (repair) => {
        setAddedRepairs(addedRepairs.filter((r) => r.repairName !== repair.repairName));
    };
    const originalRepairs = workOrderResponsiblePartyAuthorization
        ? repairsToAuthorize.filter((r) => {
            var _a;
            return (_a = workOrderResponsiblePartyAuthorization === null || workOrderResponsiblePartyAuthorization === void 0 ? void 0 : workOrderResponsiblePartyAuthorization.suggestedRepairIds) === null || _a === void 0 ? void 0 : _a.some((repairId) => repairId === r.repairId);
        })
        : [];
    const addedRepairsChanged = (originalRepairs === null || originalRepairs === void 0 ? void 0 : originalRepairs.length) !== (addedRepairs === null || addedRepairs === void 0 ? void 0 : addedRepairs.length) ||
        JSON.stringify(originalRepairs) !== JSON.stringify(addedRepairs);
    const resetAddedRepairs = () => {
        if (!workOrderResponsiblePartyAuthorization) {
            setAddedRepairs([]);
        }
        else {
            setAddedRepairs(repairsToAuthorize.filter((r) => {
                var _a;
                return (_a = workOrderResponsiblePartyAuthorization === null || workOrderResponsiblePartyAuthorization === void 0 ? void 0 : workOrderResponsiblePartyAuthorization.suggestedRepairIds) === null || _a === void 0 ? void 0 : _a.some((repairId) => repairId === r.repairId);
            }));
        }
    };
    useEffect(() => {
        resetAddedRepairs();
    }, [repairsToAuthorize]);
    useEffect(() => {
        if (addedRepairs.length === 0) {
            setPendingRepairs(repairsToAuthorize);
        }
        else {
            setPendingRepairs(repairsToAuthorize.filter((r) => !addedRepairs.some((a) => a.repairId === r.repairId)));
        }
    }, [addedRepairs]);
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _e;
        if (!adding) {
            setIsConfirmSaveOpen(true);
        }
        else {
            setLoading(true);
            yield dispatch(createWorkOrderItemResponsiblePartyAuthorization(referenceNum, {
                number: values.number,
                claimAmount: values.claimAmount,
                workOrderItemResponsiblePartyIds: addedRepairs === null || addedRepairs === void 0 ? void 0 : addedRepairs.map((r) => r.woirpId),
                responsiblePartyId: repairsToAuthorize && ((_e = repairsToAuthorize[0]) === null || _e === void 0 ? void 0 : _e.responsiblePartyId),
            }));
            if (invoice) {
                yield dispatch(getInvoicesByWorkOrderId({ workOrderId }));
            }
            yield dispatch(getQuoteDiagnosis(referenceNum, repairRequestId));
            setLoading(false);
            handleClose();
        }
    });
    const onUpdate = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setIsConfirmSaveOpen(false);
        const body = {
            number: values.number,
            claimAmount: values.claimAmount,
            workOrderItemResponsiblePartyIds: addedRepairs === null || addedRepairs === void 0 ? void 0 : addedRepairs.map((r) => r.woirpId),
            responsiblePartyId: repairsToAuthorize && repairsToAuthorize[0].responsiblePartyId,
        };
        const response = yield dispatch(updateWorkOrderItemResponsiblePartyAuthorization(referenceNum, Object.assign(Object.assign({}, body), { id: workOrderResponsiblePartyAuthorization === null || workOrderResponsiblePartyAuthorization === void 0 ? void 0 : workOrderResponsiblePartyAuthorization.id })));
        if (invoice) {
            yield dispatch(getInvoicesByWorkOrderId({ workOrderId }));
        }
        yield dispatch(getQuoteDiagnosis(referenceNum, repairRequestId));
        setLoading(false);
        handleClose();
        if (response && !response.error) {
            toast.success('Reauthorization successful.');
        }
    });
    return {
        onSubmit,
        addedRepairs,
        pendingRepairs,
        addRepair,
        removeRepair,
        authorizedRepairsSum,
        unauthorizedRepairsSum,
        isConfirmSaveOpen,
        setIsConfirmSaveOpen,
        loading,
        onUpdate,
        resetAddedRepairs,
        addedRepairsChanged,
    };
};
export default useAddAuthDrawer;
