var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { selectDeliveryVanByIspId, selectHomeStoresSlidePanelOpen, selectSlidePanelOpen, } from 'src/AdminApp/modules/deliveryVans/selectors';
import { selectEditTemplateSlidePanelOpen, selectEditUnavailabilitySlidePanelOpen, selectTemplatesSlidePanelOpen, } from 'src/AdminApp/modules/deliveryVanTemplates/selectors';
import { selectAuthorizedDeliveryVanEdit, selectAuthorizedDeliveryVanView, selectTemplateHStoreManager, } from 'src/modules/auth/selectors';
import { getPartDistributors } from 'src/PopsApp/modules/vendors/actions';
import { addDeliveryVan, closeHomeStoresSlidePanel, closeSlidePanel, getDeliveryVanByIspId, openHomeStoresSlidePanel, openSlidePanel, updateDeliveryVan, updateDeliveryVanHomeStores, updateDeliveryVanServiceAreaHomestores, } from 'src/AdminApp/modules/deliveryVans/actions';
import { closeEditTemplateSlidePanel, closeEditUnavailabilitySlidePanel, closeTemplatesSlidePanel, openTemplatesSlidePanel, } from 'src/AdminApp/modules/deliveryVanTemplates/actions';
import { loadShop } from 'src/AdminApp/modules/shops/actions';
const useDeliveryVanActions = ({ ispId, isDeliveryVan, onSubmit, }) => {
    const dispatch = useDispatch();
    const canView = useSelector(selectAuthorizedDeliveryVanView);
    const canEdit = useSelector(selectAuthorizedDeliveryVanEdit);
    const canEditTemplates = useSelector(selectTemplateHStoreManager);
    const open = useSelector(selectSlidePanelOpen);
    const homeStoresOpen = useSelector(selectHomeStoresSlidePanelOpen);
    const templatesDrawerOpen = useSelector(selectTemplatesSlidePanelOpen);
    const editTemplateDrawerOpen = useSelector(selectEditTemplateSlidePanelOpen);
    const editAvailabilityDrawerOpen = useSelector(selectEditUnavailabilitySlidePanelOpen);
    const deliveryVan = useSelector(selectDeliveryVanByIspId(ispId));
    if (deliveryVan && deliveryVan.deliveryVanServiceAreas) {
        deliveryVan.deliveryVanServiceAreas.forEach((x) => {
            x.alreadyInactive = !!(x.effectiveTo && new Date(x.effectiveTo) < new Date());
            x.active = !!(x.effectiveFrom && new Date(x.effectiveFrom) < new Date());
        });
    }
    const [serviceAreasDialog, setServiceAreasDialog] = useState();
    useEffect(() => {
        if (isDeliveryVan) {
            dispatch(getPartDistributors());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const closeHandler = () => dispatch(closeSlidePanel());
    const openHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isDeliveryVan && ispId) {
            yield dispatch(getDeliveryVanByIspId(ispId));
        }
        yield dispatch(openSlidePanel());
    });
    const closeHomeStoresHandler = () => dispatch(closeHomeStoresSlidePanel());
    const openHomeStoresHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isDeliveryVan && ispId) {
            yield dispatch(getDeliveryVanByIspId(ispId));
        }
        yield dispatch(openHomeStoresSlidePanel());
    });
    const openTemplatesHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isDeliveryVan && ispId) {
            yield dispatch(getDeliveryVanByIspId(ispId));
        }
        yield dispatch(openTemplatesSlidePanel());
    });
    const closeTemplatesHandler = () => dispatch(closeTemplatesSlidePanel());
    const closeEditTemplateHandler = () => dispatch(closeEditTemplateSlidePanel());
    const closeEditUnavailabilityHandler = () => dispatch(closeEditUnavailabilitySlidePanel());
    const saveDeliveryVan = (model, haveNewServiceAreasAdded) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        let response;
        if (model && !model.id) {
            response = yield dispatch(addDeliveryVan(model));
        }
        else if (model && model.id) {
            response = yield dispatch(updateDeliveryVan(model));
        }
        if (haveNewServiceAreasAdded && response && !response.error) {
            setServiceAreasDialog({
                open: true,
                model: Object.assign(Object.assign({}, model), { id: (_a = model === null || model === void 0 ? void 0 : model.id) !== null && _a !== void 0 ? _a : response.payload.id }),
            });
        }
        return response.payload;
    });
    const onSubmitDeliveryVan = (model) => __awaiter(void 0, void 0, void 0, function* () {
        const haveNewServiceAreasAdded = model.deliveryVanServiceAreas.some((area) => !area.id);
        const response = yield saveDeliveryVan(model, haveNewServiceAreasAdded);
        if (response && onSubmit) {
            onSubmit(response);
        }
        if (!haveNewServiceAreasAdded) {
            yield dispatch(loadShop(model.ispId));
        }
    });
    const onSubmitDeliveryVanHomeStores = (homeStores) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(updateDeliveryVanHomeStores(deliveryVan.id, homeStores));
        if (onSubmit && response.payload) {
            onSubmit(response.payload);
        }
        closeHomeStoresHandler();
    });
    const onUpdateDeliveryVanServiceAreas = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e;
        if ((serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.model) && ((_b = serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.model) === null || _b === void 0 ? void 0 : _b.id)) {
            if (serviceAreasDialog.selectedServiceArea) {
                const response = yield dispatch(updateDeliveryVanServiceAreaHomestores(serviceAreasDialog.selectedServiceArea, (_d = (_c = serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.model) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : ''));
                if (response && !response.error) {
                    toast.success('Homestores updated successfully.');
                }
            }
            else {
                const serviceAreas = (_e = serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.model) === null || _e === void 0 ? void 0 : _e.deliveryVanServiceAreas.filter((item) => !item.id && !item.alreadyInactive).map((area) => area.serviceArea.id);
                const promises = [];
                (serviceAreas !== null && serviceAreas !== void 0 ? serviceAreas : []).forEach((area) => {
                    var _a, _b;
                    promises.push(dispatch(updateDeliveryVanServiceAreaHomestores(area, (_b = (_a = serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.model) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '')));
                });
                try {
                    yield Promise.all(promises);
                    toast.success('Homestores updated successfully.');
                }
                catch (e) {
                    // nothing to do here.
                }
            }
            yield dispatch(loadShop(serviceAreasDialog.model.ispId));
        }
        setServiceAreasDialog(undefined);
    });
    return {
        dispatch,
        canView,
        canEdit,
        canEditTemplates,
        open,
        homeStoresOpen,
        templatesDrawerOpen,
        editTemplateDrawerOpen,
        editAvailabilityDrawerOpen,
        deliveryVan,
        serviceAreasDialog,
        setServiceAreasDialog,
        closeHandler,
        openHandler,
        closeHomeStoresHandler,
        openHomeStoresHandler,
        openTemplatesHandler,
        closeTemplatesHandler,
        closeEditTemplateHandler,
        closeEditUnavailabilityHandler,
        saveDeliveryVan,
        onSubmitDeliveryVan,
        onSubmitDeliveryVanHomeStores,
        onUpdateDeliveryVanServiceAreas,
    };
};
export default useDeliveryVanActions;
