import { TextField } from 'src/AdminApp/components/form';
import { Button } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const SubmitButton = styled(Button) `
  .MuiButton-root {
    background-color: ${colors.primary};
    color: ${colors.white};
    border-radius: 3px;
    border: 0;
    color: ${colors.white};
    height: 48px;
    padding: 0 30px;
    text-transform: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.75px;
    width: 100%;
  }
`;
export const AmountInput = styled(TextField) `
  .MuiInput-input {
    font-size: 40px;
    min-width: 150px;
    font-weight: 500;
    text-align: center;
    color: ${colors.GRAY_3F};
  }

  .MuiInputAdornment-positionStart {
    color: ${colors.GRAY_3F};
    margin-right: 2px;
  }
`;
export const inputStyle = {
    border: '0',
    padding: '6px 12px 7px 8px',
    animationName: 'mui-auto-fill-cancel',
    animationDuration: '10ms',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontWeight: '400',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    height: '50px',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    marginTop: '-6px',
};
export const cvcStyle = {
    border: '0',
    padding: '7px 16px 21px 14px',
    animationName: 'mui-auto-fill-cancel',
    animationDuration: '10ms',
    color: `${colors.GRAY_3F}`,
    cursor: 'text',
    height: '63px',
    marginTop: '-6px',
    borderRadius: '0 4px 4px 0',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderLeft: '0',
};
