import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    notes: {
        content: [],
        pagination: {
            pageSize: 10,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['created,desc'],
        },
    },
    notesAppliesTo: [],
    notesAppliesToRecommendedRepairs: [],
    missing3cNotes: undefined,
    drawerOpen: false,
};
const getNotes = (state, action) => {
    var _a;
    const notes = fetched(action.payload);
    const { sort, pageIndex, pageSize, query } = (_a = action.meta) !== null && _a !== void 0 ? _a : {};
    const { totalPages, totalElements } = notes;
    return Object.assign(Object.assign({}, state), { notes: Object.assign(Object.assign(Object.assign({}, state.notes), notes), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
                query,
            } }) });
};
const getNotesAppliesTo = (state, action) => {
    return Object.assign(Object.assign({}, state), { notesAppliesTo: action.payload });
};
const getNotesAppliesToRecommendedRepairs = (state, action) => {
    return Object.assign(Object.assign({}, state), { notesAppliesToRecommendedRepairs: action.payload });
};
const getMissing3Cnotes = (state, action) => {
    return Object.assign(Object.assign({}, state), { missing3cNotes: action.payload });
};
const openNotesDrawer = (state) => {
    return Object.assign(Object.assign({}, state), { openDrawer: true });
};
const closeNotesDrawer = (state) => {
    return Object.assign(Object.assign({}, state), { openDrawer: false });
};
const reducers = handleActions({
    [ActionCreators.GET_REQUEST_NOTES.SUCCESS.type]: getNotes,
    [ActionCreators.GET_REQUEST_NOTES_APPLIES_TO.SUCCESS.type]: getNotesAppliesTo,
    [ActionCreators.GET_REQUEST_NOTES_APPLIES_TO_RECOMMENDED_REPAIRS.SUCCESS
        .type]: getNotesAppliesToRecommendedRepairs,
    [ActionCreators.GET_MISSING_3C_NOTES.SUCCESS.type]: getMissing3Cnotes,
    [ActionCreators.NOTES_DRAWER.OPEN.type]: openNotesDrawer,
    [ActionCreators.NOTES_DRAWER.CLOSE.type]: closeNotesDrawer,
}, DEFAULT_STATE);
export default reducers;
