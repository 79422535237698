import styled from 'styled-components';
import { Grid, IconButton } from 'src/components/mui';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { colors, theme } from 'src/styles/theme';
export const TOP_OFFSET = 20;
export const AppBarContainer = styled.div `
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 100;

  ${theme.breakpoints.down('sm')} {
    right: 0;
    width: 100%;
  }
`;
export const LogoColumn = styled.div `
  background: ${colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 62px;

  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;
export const NavLinksColumn = styled(Grid) `
  box-shadow: inset -5px 0 20px 0 rgba(239, 239, 239, 0.94);
  background-color: #fafafa;
  width: 200px;
  padding-top: ${TOP_OFFSET}px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  overflow: auto;

  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;
export const ActionIcon = styled(IconButton) `
  padding: 0;
`;
export const StyledStandardButtonRow = styled(SimpleTwoButtonRow) `
  margin-top: 0;
`;
