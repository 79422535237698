var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLocationById, getLocationsHubs, } from 'src/SystemApp/modules/locations/actions';
const useGetHubsFromVan = (vanId) => {
    const dispatch = useDispatch();
    const [isLoadingHubs, setIsLoadingHubs] = useState(false);
    const [hubs, setHubs] = useState([]);
    useEffect(() => {
        if (!vanId)
            return;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            setIsLoadingHubs(true);
            const vanLocationResponse = yield dispatch(getLocationById(vanId));
            if (!vanLocationResponse.error && vanLocationResponse.payload) {
                const vanLocation = vanLocationResponse.payload;
                const promises = vanLocation.parentIds.map((item) => {
                    return dispatch(getLocationsHubs({ parentId: item }));
                });
                const vanParentHubsResponse = yield Promise.all(promises);
                const hubs = vanParentHubsResponse.reduce((acc, el) => {
                    if (!el.error) {
                        acc.push(...el.payload.content);
                    }
                    return acc;
                }, []);
                setHubs([...hubs]);
            }
            setIsLoadingHubs(false);
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vanId]);
    return {
        hasHubs: hubs && (hubs === null || hubs === void 0 ? void 0 : hubs.length) > 0,
        hubs,
        isLoadingHubs,
    };
};
export default useGetHubsFromVan;
