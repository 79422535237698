import styled from 'styled-components';
import { Button } from 'src/components/mui';
import { theme, colors } from 'src/styles/theme';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
export const Title = styled.h1 `
  margin: 0;
  padding: 0;
  font-size: 24px;

  ${theme.breakpoints.up('md')} {
    margin: 8px 8px;
    font-size: 30px;
  }
`;
export const Content = styled.section `
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0 0;

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 0;
  }
`;
export const Divider = styled.div `
  display: none;

  ${theme.breakpoints.up('md')} {
    display: flex;
    height: 230px;
    width: 1px;
    background-color: ${colors.GRAY_DE};
  }
`;
export const ButtonsWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  button {
    width: 100%;
  }

  ${theme.breakpoints.up('md')} {
    flex-direction: row;

    button {
      width: auto;
    }
  }
`;
export const ContentInner = styled.div `
  margin-top: 1rem;
`;
export const StyledButton = styled(Button) `
  min-width: 144px;
  height: 48px;
  font-size: 1rem;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
`;
