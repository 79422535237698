import moment from 'moment';
import React from 'react';
import AddFleetRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddFleetRODrawer';
import AdditionalCancelationReasons from 'src/AdminApp/containers/requests/StateTracker/drawers/AdditionalCancelationReasons';
import { ConfirmationModal } from 'src/components/layout';
import { SpeedDialAction, SpeedDialIcon, useMediaQuery, } from 'src/components/mui';
import { AddCircleOutlineRounded, ViewListRounded, } from 'src/components/mui/icons';
import SimpleChip from 'src/components/SimpleChip';
import { ActionsBlock } from 'src/containers/layout/ActionsBlock';
import { FLEET_VISIT_STATUS_COLORS, FLEET_VISIT_STATUS_COLORS_TEXT, } from 'src/FleetVisitsApp/constants';
import AddFleetVisitDrawer from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer';
import { useVisitActions } from 'src/FleetVisitsApp/pages/VisitView/useVisitActions';
import VisitInfo from 'src/FleetVisitsApp/pages/VisitView/VisitInfo';
import WorkOrderItemMobile from 'src/FleetVisitsApp/pages/VisitView/WorkOrderItemMobile';
import { colors, theme } from 'src/styles/theme';
import { LOCALIZED_DATE_LONGER_FORMAT } from 'src/timeConstants';
import VisitLockTooltip from '../FleetVisits/components/VisitLockTooltip';
import AddExistingRepairOrdersDrawer from './AddExistingRepairOrdersDrawer';
import useVisitView from './useVisitView';
import VisitActionButtons from './VisitActionButtons';
import { AddRequestSpeedDial, LoaderWrapper, MobileVisits, NoRequests, VisitDate, VisitHeader, VisitLoader, VisitStatusContainer, VisitViewContent, VisitViewWrapper, VisitWorkOrders, } from './VisitView.styles';
import WorkOrdersTable from './WorkOrdersTable';
const VisitView = () => {
    const { speedDialOpen, addExistingDrawerOpen, isCancelDrawerOpen, workOrderToRemove, isAddROOpen, openROCancelConfirm, visit, repairRequest, technicianTasksByReferenceNums, handleSpeedDialOpen, handleSpeedDialClose, handleAddRepairOrder, handleWorkOrderRemove, handleRemoveROConfirm, handleCloseDrawers, fabBgColor, lockedAtDateFormatted, } = useVisitView();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { visitActions, openVisitDrawer, handleVisitDrawerClose, openVisitCancelConfirm, handleVisitCancelConfirmClose, handleCancelVisit, handleVisitStartConfirmClose, handleStartVisit, openVisitStartConfirm, } = useVisitActions({ visit });
    const newVisit = Object.assign(Object.assign({}, visit), { lockedAtDate: lockedAtDateFormatted });
    return visit ? (<VisitViewWrapper>
      <ActionsBlock actions={visitActions}/>
      <VisitHeader>
        <VisitDate>
          {moment(visit.actualStartLocal).format(LOCALIZED_DATE_LONGER_FORMAT)}
        </VisitDate>

        <VisitStatusContainer>
          <VisitLockTooltip visitData={newVisit}/>

          <SimpleChip label={visit.status} $textColor={FLEET_VISIT_STATUS_COLORS_TEXT[visit.status]} $bgColor={FLEET_VISIT_STATUS_COLORS[visit.status]}/>
        </VisitStatusContainer>
      </VisitHeader>
      <VisitViewContent>
        <VisitInfo visit={visit}/>

        {!isMobile && (<VisitActionButtons visit={visit} onAddRepairOrders={handleAddRepairOrder}/>)}

        {visit.workOrders.length === 0 && (<NoRequests>0 Repair Orders</NoRequests>)}

        {visit.workOrders.length > 0 && (<VisitWorkOrders>
            {!isMobile && (<WorkOrdersTable workOrders={visit.workOrders} technicianTasks={technicianTasksByReferenceNums} onWorkOrderRemove={handleWorkOrderRemove}/>)}

            {isMobile && (<MobileVisits>
                {visit.workOrders.map((workOrder) => (<WorkOrderItemMobile key={workOrder.id} workOrder={workOrder} onWorkOrderRemove={handleWorkOrderRemove}/>))}
              </MobileVisits>)}
          </VisitWorkOrders>)}
      </VisitViewContent>
      {isMobile && (<AddRequestSpeedDial ariaLabel="Add Repair Order" icon={<SpeedDialIcon />} FabProps={{
                style: {
                    backgroundColor: fabBgColor,
                    color: colors.white,
                },
                disabled: visit.status === 'COMPLETED',
            }} title="Add Repair Order" onClose={handleSpeedDialClose} onOpen={handleSpeedDialOpen} open={speedDialOpen}>
          <SpeedDialAction key={0} tooltipTitle="Select Existing ROs" tooltipOpen onClick={() => {
                handleAddRepairOrder('EXISTING');
            }} icon={<ViewListRounded />}/>
          <SpeedDialAction key={1} tooltipTitle="Create New RO" tooltipOpen onClick={() => {
                handleAddRepairOrder('NEW');
            }} icon={<AddCircleOutlineRounded />}/>
        </AddRequestSpeedDial>)}
      <AddExistingRepairOrdersDrawer visit={visit} open={addExistingDrawerOpen} onClose={handleCloseDrawers}/>
      <AddFleetRODrawer open={isAddROOpen} onClose={handleCloseDrawers} visit={visit}/>
      {workOrderToRemove && repairRequest && (<AdditionalCancelationReasons isFleet open={isCancelDrawerOpen} referenceNum={workOrderToRemove.repairRequestRefNum} workOrderId={workOrderToRemove.id} visitReferenceNum={visit.refNum} repairRequestId={repairRequest.id} onClose={handleCloseDrawers}/>)}
      <AddFleetVisitDrawer open={openVisitDrawer} onClose={handleVisitDrawerClose}/>

      <ConfirmationModal open={openVisitCancelConfirm} text="Are you sure you want to cancel this visit?" onClose={handleVisitCancelConfirmClose} action={handleCancelVisit}/>
      <ConfirmationModal open={openROCancelConfirm} title="Are you sure you want to remove this RO?" text="Its status will reset and progress will be lost." action={handleRemoveROConfirm} onClose={handleRemoveROConfirm}/>
      <ConfirmationModal open={openVisitStartConfirm} text="Are you sure you want to start this Visit?" action={handleStartVisit} onClose={handleVisitStartConfirmClose}/>
    </VisitViewWrapper>) : (<LoaderWrapper>
      <VisitLoader size={50}/>
    </LoaderWrapper>);
};
export default VisitView;
