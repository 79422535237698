import React from 'react';
import FleetSearchSelect from 'src/components/form/FleetSearchSelect';
import { SelectAddressButton, SlideWrapper, } from 'src/AdminApp/components/drawers/styledComponents';
import FleetInfo from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/pages/FleetInfo';
const SelectFleetTab = ({ fleet, fleetSuggestions, onSelectedFleet, onSearchFleets, handleSelectChange, handleSelectAddressButtonClick, }) => (<SlideWrapper>
    <FleetSearchSelect onSelect={onSelectedFleet} onChange={handleSelectChange} onSearch={onSearchFleets} fleetSuggestions={fleetSuggestions}/>

    {!!fleet && (<>
        <FleetInfo fleet={fleet}/>
        <SelectAddressButton onClick={handleSelectAddressButtonClick}>
          Select a different address
        </SelectAddressButton>
      </>)}
  </SlideWrapper>);
export default SelectFleetTab;
