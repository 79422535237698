var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartPickupOrder, selectPickedUpItems, selectPickupOrderMissingParts, } from 'src/TechApp/modules/partpickup/selectors';
import { ChecklistSubheader, ContentWrapper, ListHeadings, PartsPickupButton, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
import Loader from 'src/AdminApp/containers/requests/StateTracker/Loader';
import { Grid } from 'src/components/mui';
import { toast } from 'src/components/SimpleToast';
import { fetchPartPickupOrderByReferenceNum, submitPickedUpParts, } from 'src/TechApp/modules/partpickup/actions';
import PartPickupChecklistItem from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupChecklist/PartPickupChecklistItem';
import PartPickupItemStatus from 'src/AdminApp/models/enums/PartPickupItemStatus';
import useIsMobile from 'src/hooks/useIsMobile';
import { Container, Content, ContentInner, Title, } from './PartPickupChecklist.styles';
import { PartPickupButtonsWrapper } from '../Details/PartPickupDetails.styles';
const PartPickupChecklist = () => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const history = useHistory();
    const partPickupOrder = useSelector(selectPartPickupOrder);
    const pickedUpItems = useSelector(selectPickedUpItems);
    const isMissingParts = useSelector(selectPickupOrderMissingParts);
    const { referenceNum } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        if (referenceNum && !partPickupOrder) {
            dispatch(fetchPartPickupOrderByReferenceNum(referenceNum));
        }
    }, [dispatch, partPickupOrder, referenceNum]);
    const groupByRepairRequest = (items) => {
        return items.reduce((acc, item) => {
            const { repairRequestReferenceNum } = item;
            const group = acc.find((g) => g.repairRequestReferenceNum === repairRequestReferenceNum);
            if (group) {
                group.items.push(item);
            }
            else {
                acc.push({ repairRequestReferenceNum, items: [item] });
            }
            return acc;
        }, []);
    };
    const goBackHandler = () => {
        history.push(`/pops/part-pickups/${partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum}`);
    };
    const submitHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isMissingParts) {
            history.push(`/pops/part-pickups/${partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum}/missing-parts`);
        }
        else {
            submitParts();
        }
    });
    const countPendingOrNewPartGroup = (items) => {
        let pendingOrNewPart = 0;
        if (!items) {
            return 0;
        }
        items.forEach((item) => {
            pendingOrNewPart += countPendingOrNewPart(item);
        });
        return pendingOrNewPart;
    };
    const countPendingOrNewPart = (item) => {
        let pendingOrNewPart = 0;
        if (!item || !item.states) {
            return 0;
        }
        item.states.forEach((state) => {
            if (state.status === PartPickupItemStatus.NEW_PART ||
                state.status === PartPickupItemStatus.PENDING) {
                pendingOrNewPart += 1;
            }
        });
        return pendingOrNewPart;
    };
    const submitParts = () => __awaiter(void 0, void 0, void 0, function* () {
        if (pickedUpItems && pickedUpItems.length) {
            const mappedItems = pickedUpItems.map((item) => (Object.assign(Object.assign({}, item), { quantity: item.checked ? item.quantity : 0 })));
            setIsSubmitting(true);
            const response = yield dispatch(submitPickedUpParts(partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.id, mappedItems));
            if (!response.error) {
                toast.success('The selected parts were submitted successfully');
            }
            history.push(`/pops/part-pickups/${partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum}`);
        }
    });
    return (<Container>
      <Title>Checklist</Title>
      <Content>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <ContentWrapper style={{ marginTop: '5px' }}>
              <h2>Purchase Orders</h2>
              <p className="subheaderChecklist">
                Select Parts that were picked up.
              </p>
            </ContentWrapper>
            <ContentWrapper>
              <ListHeadings>
                <ChecklistSubheader>PO#</ChecklistSubheader>
                <ChecklistSubheader>Multiple Qty</ChecklistSubheader>
              </ListHeadings>
            </ContentWrapper>
            <ContentWrapper>
              {groupByRepairRequest((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.items) || [])
            .filter((group) => countPendingOrNewPartGroup(group.items) > 0)
            .map((group, index) => (<PartPickupChecklistItem key={index} repairRequestReferenceNum={group.repairRequestReferenceNum} items={group.items}/>))}
            </ContentWrapper>
          </Grid>
          <Grid item xs={12} md={12}>
            <ContentInner className="center">
              {isSubmitting ? (<Loader />) : (<PartPickupButtonsWrapper>
                  <PartsPickupButton color="primary" variant="contained" onClick={goBackHandler} fullWidth={isMobile} disabled={isSubmitting}>
                    Go Back
                  </PartsPickupButton>
                  <PartsPickupButton color="primary" variant="contained" onClick={submitHandler} fullWidth={isMobile} disabled={isSubmitting}>
                    Submit
                  </PartsPickupButton>
                </PartPickupButtonsWrapper>)}
            </ContentInner>
          </Grid>
        </Grid>
      </Content>
    </Container>);
};
export default PartPickupChecklist;
