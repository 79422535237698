import React from 'react';
import { FormGridItemWrapper, SlideWrapper, } from 'src/AdminApp/components/drawers/styledComponents';
import MarketingSourceSelect from 'src/AdminApp/containers/requests/MarketingSourceSelect';
import { MarketingSourceVisibility } from 'src/AdminApp/models/enums/MarketingSourceVisibility';
import PlacesACWithServiceAreas from 'src/components/form/PlacesACWithServiceAreas';
import { Grid } from 'src/components/mui';
import { GridFormField } from './GridFormField';
import useAddNewConsumerTab from './useAddNewConsumerTab';
const AddNewConsumerTab = ({ setIsZipCodeValid }) => {
    const { customUser, setField, handleAddressChange, handleMarketingSourceChange, } = useAddNewConsumerTab();
    return (<SlideWrapper>
      <Grid container>
        <GridFormField label="First Name" name="firstName" customUser={customUser} setField={setField}/>
        <GridFormField label="Last Name" name="lastName" customUser={customUser} setField={setField}/>
        <GridFormField label="Phone" name="phone" customUser={customUser} setField={setField}/>
        <GridFormField label="Email" name="email" customUser={customUser} setField={setField}/>
        <FormGridItemWrapper $vertical item xs={12}>
          <PlacesACWithServiceAreas address={customUser === null || customUser === void 0 ? void 0 : customUser.address} onSelect={handleAddressChange} textFieldParams={{
            variant: 'outlined',
            label: 'Address',
        }} setIsZipCodeValid={setIsZipCodeValid}/>
        </FormGridItemWrapper>
        <Grid item xs={12}>
          <MarketingSourceSelect onChange={handleMarketingSourceChange} name="marketingSourceId" label="Marketing Source" visibility={MarketingSourceVisibility.ADMIN} fullWidth variant="outlined" formField={false}/>
        </Grid>
      </Grid>
    </SlideWrapper>);
};
export default AddNewConsumerTab;
