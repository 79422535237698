var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'src/components/SimpleToast';
import { selectPartDistributorEditor } from 'src/modules/auth/selectors';
import { fetchDistributorLocations, saveDistributorLocation, updateDistributorLocation, } from 'src/PopsApp/modules/distributors/actions';
import { selectDistributorLocationDashboard, selectDistributorNameById, selectLocationPagination, selectLocationParams, } from 'src/PopsApp/modules/distributors/selectors';
const useLocationsDashboard = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const locations = useSelector(selectDistributorLocationDashboard(params.distributorId));
    const pagination = useSelector(selectLocationPagination(params.distributorId));
    const dashboardParams = useSelector(selectLocationParams(params.distributorId));
    const selectedDistributorName = useSelector(selectDistributorNameById(params.distributorId));
    const isPartsDistributorEditor = useSelector(selectPartDistributorEditor);
    const [distributorName, setDistributorName] = useState(selectedDistributorName);
    const [currentFilters, setCurrentFilters] = useState(null);
    const pageSize = (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || 20;
    const [addEditModalOpen, setAddEditModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState();
    const [hoursDrawerOpen, setHoursDrawerOpen] = useState(false);
    const isEditing = Boolean(selectedLocation);
    useEffect(() => {
        onLoadData({
            page: 0,
            size: pageSize,
            sort: ['status,asc', 'street1,asc'],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!distributorName && (locations === null || locations === void 0 ? void 0 : locations.length) > 0) {
            setDistributorName(locations[0].partDistributorName);
        }
    }, [distributorName, locations]);
    const onLoadData = ({ page, size, sort, }) => {
        const payload = Object.assign(Object.assign(Object.assign({}, dashboardParams), { pageSize: size, pageIndex: page, sort, distributorId: params === null || params === void 0 ? void 0 : params.distributorId }), (currentFilters !== null && currentFilters !== void 0 ? currentFilters : {}));
        dispatch(fetchDistributorLocations(payload));
    };
    const onSearch = (filters) => {
        dispatch(fetchDistributorLocations({
            pageSize,
            pageIndex: 0,
            street: filters.street,
            dealershipName: filters.dealershipName,
            distributorId: params === null || params === void 0 ? void 0 : params.distributorId,
        }));
        setCurrentFilters(filters);
    };
    const onLocationSave = (distributorLocation) => __awaiter(void 0, void 0, void 0, function* () {
        setAddEditModalOpen(false);
        const result = yield dispatch(saveDistributorLocation(distributorLocation));
        if (!result.error) {
            toast.success('Location successfully saved');
            onLoadData({
                page: 0,
                size: pageSize,
                sort: ['status,asc', 'street1,asc'],
            });
        }
    });
    const onLocationUpdate = (distributorLocation) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield dispatch(updateDistributorLocation(distributorLocation));
        if (!result.error) {
            toast.success('Location successfully updated');
            setAddEditModalOpen(false);
            onLoadData({
                page: 0,
                size: pageSize,
                sort: ['status,asc', 'street1,asc'],
            });
        }
    });
    const onHoursEdit = (location) => {
        setSelectedLocation(location);
        setHoursDrawerOpen(true);
    };
    const onLocationAdd = () => {
        setSelectedLocation(undefined);
        setAddEditModalOpen(true);
    };
    const onLocationEdit = (item) => {
        setSelectedLocation(item);
        setAddEditModalOpen(true);
    };
    const closeHoursDrawer = () => {
        setSelectedLocation(undefined);
        setHoursDrawerOpen(false);
    };
    return {
        isPartsDistributorEditor,
        distributorName,
        onSearch,
        onLocationAdd,
        isEditing,
        addEditModalOpen,
        setAddEditModalOpen,
        params,
        selectedLocation,
        onLocationUpdate,
        onLocationSave,
        locations,
        pagination,
        onLocationEdit,
        onHoursEdit,
        onLoadData,
        hoursDrawerOpen,
        closeHoursDrawer,
    };
};
export default useLocationsDashboard;
