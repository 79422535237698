import React from 'react';
import { Divider, Typography } from 'src/components/mui';
import { AlternateEmail as AlternateEmailIcon, LocationOn as LocationOnIcon, Person as PersonIcon, Phone as PhoneIcon, } from 'src/components/mui/icons';
import { formatPhone } from 'src/utils/formatter';
import { States } from 'src/AdminApp/models/enums';
import { AddressFieldsWrapper, AddressTypography, ContactInfoWrapper, LocationIconWrapper, MobileContactInfoWrapper, UserFieldsWrapper, UserItem, } from 'src/AdminApp/components/drawers/styledComponents';
import { normalizeAddress } from 'src/AdminApp/modules/utils';
import useIsMobile from 'src/hooks/useIsMobile';
const AddressFields = ({ street, city, state, zip }) => {
    const stateShort = States.findByValue(state) || '';
    return (<AddressFieldsWrapper>
      <LocationIconWrapper>
        <LocationOnIcon />
      </LocationIconWrapper>
      <div style={{ paddingLeft: '80px' }}>
        <Typography variant="h5">{street}</Typography>
        <AddressTypography>
          {city}, {stateShort} {zip}
        </AddressTypography>
      </div>
    </AddressFieldsWrapper>);
};
const FleetInfo = ({ fleet }) => {
    const isMobile = useIsMobile();
    if (!fleet) {
        return null;
    }
    const { phone, email, consumer, name, contactName } = fleet;
    const address = normalizeAddress(consumer === null || consumer === void 0 ? void 0 : consumer.address);
    return (<UserFieldsWrapper>
      <Typography variant="h5">{name}</Typography>

      {!isMobile && (<>
          <ContactInfoWrapper>
            {contactName && (<>
                <PersonIcon /> {contactName}
              </>)}
            {phone && (<>
                <PhoneIcon /> {formatPhone(phone)} &nbsp;
              </>)}
            {email && (<>
                <AlternateEmailIcon /> {email}
              </>)}
          </ContactInfoWrapper>
          <Divider />
        </>)}
      {isMobile && (<MobileContactInfoWrapper>
          <UserItem>
            {contactName && (<>
                <PersonIcon /> Main Contact: &nbsp; <b>{contactName}</b>
              </>)}
          </UserItem>
          <Divider />
          <UserItem>
            {phone && (<>
                <PhoneIcon /> Phone #: &nbsp; <b>{formatPhone(phone)}</b>
              </>)}
          </UserItem>
          <Divider />
          <UserItem>
            {email && (<>
                <AlternateEmailIcon /> Email: &nbsp; <b>{email}</b>
              </>)}
          </UserItem>
          <Divider />
        </MobileContactInfoWrapper>)}
      {address && address.zip && (<>
          <AddressFields street={address.street} city={address.city} state={address.state} zip={address.zip}/>
          <Divider />
        </>)}
    </UserFieldsWrapper>);
};
export default FleetInfo;
