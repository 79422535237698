import React from 'react';
import { TYPE_ICONS, TYPE_DESCRIPTION, SUB_TYPE_DESCRIPTION, } from '../constants';
import { TypeTooltip, TypeIcon } from '../Notes.styles';
const getTooltipTitle = (type, subType) => {
    let title = TYPE_DESCRIPTION[type];
    if (subType) {
        title += ` - ${SUB_TYPE_DESCRIPTION[subType]}`;
    }
    return title;
};
const IconWithTooltip = ({ type, subType, placement = 'top', }) => {
    if (!TYPE_ICONS[type])
        return null;
    return (<TypeTooltip title={getTooltipTitle(type, subType)} placement={placement}>
      <TypeIcon color={type === 'FMC' ? 'error' : undefined} component={TYPE_ICONS[type]}/>
    </TypeTooltip>);
};
export default IconWithTooltip;
