import createDefaultActionCreators from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import CONFIG from 'src/config';
import createAction from 'src/modules/api/utils/createAction';
import queryString from 'query-string';
import NotesEntityType from 'src/AdminApp/models/enums/NotesEntityTypes';
import NotesOriginType from 'src/AdminApp/models/enums/NotesOriginTypes';
import API_URLS from 'src/AdminApp/modules/api/urls';
export const clearVisitsStopwatch = createAction('CLEAR_VISITS_STOPWATCH');
export const ActionCreators = {
    GET_VISIT: createDefaultActionCreators('GET_VISIT', ['CLEAR']),
    GET_TECHNICIANS_SEARCH: createDefaultActionCreators('GET_TECHNICIANS_SEARCH'),
    ADD_VISIT: createDefaultActionCreators('ADD_VISIT'),
    CANCEL_VISIT: createDefaultActionCreators('CANCEL_VISIT'),
    UPDATE_FLEET_VISIT_STATUS: createDefaultActionCreators('UPDATE_FLEET_VISIT_STATUS'),
    GET_FLEET_VISITS: createDefaultActionCreators('GET_FLEET_VISITS'),
    UPDATE_VISIT: createDefaultActionCreators('UPDATE_VISIT'),
    GET_FLEET_NAMES: createDefaultActionCreators('GET_FLEET_NAMES'),
    GET_FLEET_REQUESTS: createDefaultActionCreators('GET_FLEET_REQUESTS'),
    ADD_WORK_ORDERS_TO_VISIT: createDefaultActionCreators('ADD_WORK_ORDERS_TO_VISIT'),
    GET_MECHANICS_SHARED_AVAILABILITY: createDefaultActionCreators('GET_MECHANICS_SHARED_AVAILABILITY'),
    GET_VANS_SHARED_AVAILABILITY: createDefaultActionCreators('GET_VANS_SHARED_AVAILABILITY'),
    GET_VISIT_INSTRUCTIONS: createDefaultActionCreators('GET_VISIT_INSTRUCTIONS'),
    GET_VISIT_INSTRUCTIONS_BY_ID: createDefaultActionCreators('GET_VISIT_INSTRUCTIONS_BY_ID'),
    CREATE_VISIT_INSTRUCTIONS: createDefaultActionCreators('CREATE_VISIT_INSTRUCTIONS'),
    UPDATE_VISIT_INSTRUCTIONS: createDefaultActionCreators('UPDATE_VISIT_INSTRUCTIONS'),
    DELETE_VISIT_INSTRUCTIONS: createDefaultActionCreators('DELETE_VISIT_INSTRUCTIONS'),
    GET_VISITS_BY_FLEET: createDefaultActionCreators('GET_VISITS_BY_FLEET'),
    GET_TECHNICIAN_TASKS_BY_REFERENCE_NUMS: createDefaultActionCreators('GET_TECHNICIAN_TASKS_BY_REFERENCE_NUMS'),
    GET_VISIT_STOPWATCH: createDefaultActionCreators('GET_VISIT_STOPWATCH'),
    ADD_VISIT_EXTRA_TIME_REQUEST: createDefaultActionCreators('ADD_VISIT_EXTRA_TIME_REQUEST'),
    CLEAR_VISITS_STOPWATCH: clearVisitsStopwatch,
    STOP_VISITS_STOPWATCH: createAction('STOP_VISITS_STOPWATCH'),
    TOGGLE_VISIT_LOCK: createDefaultActionCreators('TOGGLE_VISIT_LOCK'),
    UPDATE_DAILY_OPS_CONTACT: createDefaultActionCreators('UPDATE_DAILY_OPS_CONTACT'),
    GET_VISIT_AUDIT_LOG: createDefaultActionCreators('GET_VISIT_AUDIT_LOG'),
    CLEAR_VISIT_AUDIT_LOG: createAction('CLEAR_VISIT_AUDIT_LOG'),
    GET_ADDRESS_AUDIT_LOG: createDefaultActionCreators('GET_ADDRESS_AUDIT_LOG'),
};
export const setVisitDate = createAction('SET_FLEET_VISIT_DATE');
export const setVisitSort = createAction('SET_FLEET_VISIT_SORT');
export const setVisitFleetIds = createAction('SET_FLEET_VISIT_FLEET_IDS');
export const setVisitFleetStations = createAction('SET_FLEET_VISIT_STATIONS');
export const stopVisitsStopwatch = ActionCreators.STOP_VISITS_STOPWATCH;
export const clearVisitAuditLog = ActionCreators.CLEAR_VISIT_AUDIT_LOG;
export const getVisitsDashboard = (params) => {
    const paramsStr = Object.keys(params).reduce((str, key, index) => {
        return `${str}${index ? '&' : '?'}${key}=${params[key]}`;
    }, '');
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_FLEET_VISITS),
        endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/visits/dashboard${paramsStr}`,
        method: 'GET',
    });
};
export const getFleetNames = ({ text = '' }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_FLEET_NAMES),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/fleet/names?query=${text}`,
    method: 'GET',
});
export const getVisit = ({ refNum }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_VISIT),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/visits/${refNum}`,
    method: 'GET',
});
export const getTechnicians = ({ startDate, zip, timezone, startWithTime, }) => {
    const params = {
        days: 1,
        start: startDate,
        zipCode: zip,
        fleet: true,
        startWithTime,
        repairOrderPartStatus: 'PENDING',
    };
    if (timezone) {
        params.timezone = timezone;
    }
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_TECHNICIANS_SEARCH),
        endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/scheduling/vans-mechanics?${queryString.stringify(params)}`,
        method: 'GET',
    });
};
export const addVisit = ({ body }) => createAPIAction({
    types: fillApiTypes(ActionCreators.ADD_VISIT),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/visits`,
    body,
    method: 'POST',
});
export const updateVisit = ({ refNum, body, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.ADD_VISIT),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/visits/${refNum}`,
    body,
    method: 'PUT',
});
export const cancelVisit = ({ refNum }) => createAPIAction({
    types: fillApiTypes(ActionCreators.CANCEL_VISIT),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/visits/${refNum}/workflow/cancel`,
    method: 'POST',
});
export const setSelectedStations = createAction('SET_SELECTED_STATIONS');
export const setSelectedFleets = createAction('SET_SELECTED_FLEETS');
export const setFleetsDatasource = createAction('SET_FLEETS_DATASOURCE');
export const setAddVisitLocation = createAction('SET_ADD_VISIT_LOCATION');
export const setAddVisitExtLocation = createAction('SET_ADD_VISIT_EXT_LOCATION');
export const setAddVisitStartDateTime = createAction('SET_ADD_VISIT_START_DATE_TIME');
export const setAddVisitEndDateTime = createAction('SET_ADD_VISIT_END_DATE_TIME');
export const setAddVisitDuration = createAction('SET_ADD_VISIT_DURATION');
export const setAddVisitDurationTime = createAction('SET_ADD_VISIT_DURATION_TIME');
export const setAddVisitTechnicians = createAction('SET_ADD_VISIT_TECHNICIANS');
export const setAddVisitVans = createAction('SET_ADD_VISIT_VANS');
export const resetAddVisit = createAction('RESET_ADD_VISIT');
export const getFleetRequests = ({ refNum, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_FLEET_REQUESTS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/visits/${refNum}/fleets/unassigned-repair-orders`,
    method: 'GET',
});
export const addWorkOrdersToVisit = ({ refNum, workOrderIds, resetParts, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.ADD_WORK_ORDERS_TO_VISIT),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/visits/${refNum}/work-orders`,
    method: 'PUT',
    body: workOrderIds,
    params: {
        resetParts,
    },
});
export const updateFleetVisitStatus = ({ refNum, step, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_FLEET_VISIT_STATUS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/visits/${refNum}/workflow/${step}`,
    method: 'POST',
});
export const getMechanicsWithSharedAvailability = ({ startDateTime, zip, timezone, skeduloResourceId, duration, }) => {
    const params = {
        hours: duration,
        start: startDateTime,
        zipCode: zip,
        fleet: true,
    };
    if (timezone) {
        params.timezone = timezone;
    }
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_MECHANICS_SHARED_AVAILABILITY),
        endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/scheduling/resources/${skeduloResourceId}/shared-availability/mechanics?${queryString.stringify(params)}`,
        method: 'GET',
    });
};
export const getVansWithSharedAvailability = ({ startDateTime, zip, timezone, skeduloResourceId, duration, }) => {
    const params = {
        hours: duration,
        start: startDateTime,
        zipCode: zip,
        fleet: true,
    };
    if (timezone) {
        params.timezone = timezone;
    }
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_VANS_SHARED_AVAILABILITY),
        endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/scheduling/resources/${skeduloResourceId}/shared-availability/vans`,
        method: 'GET',
        params,
    });
};
export const getFleetInstructions = ({ entityId }) => {
    const entityType = NotesEntityType.FLEET_VISIT;
    const origin = NotesOriginType.TOOLS;
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_VISIT_INSTRUCTIONS),
        endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/notes/${entityId}/${entityType}/${origin}`,
        method: 'GET',
    });
};
export const createFleetInstructions = ({ note, entityId, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_VISIT_INSTRUCTIONS_BY_ID),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/notes`,
    method: 'POST',
    body: {
        content: note,
        origin: NotesOriginType.TOOLS,
        entityType: NotesEntityType.FLEET_VISIT,
        entityId,
    },
});
export const updateFleetInstructions = ({ noteId, note, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_VISIT_INSTRUCTIONS_BY_ID),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/notes/${noteId}`,
    method: 'PATCH',
    body: note,
});
export const deleteFleetInstructions = ({ noteId }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_VISIT_INSTRUCTIONS_BY_ID),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/notes/${noteId}`,
    method: 'DELETE',
});
export const getVisitsByFleet = (params) => {
    const paramsStr = Object.keys(params).reduce((str, key, index) => {
        return `${str}${index ? '&' : '?'}${key}=${params[key]}`;
    }, '');
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_VISITS_BY_FLEET),
        endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/visits/dashboard${paramsStr}`,
        method: 'GET',
    });
};
export const getTechnicianTasksByReferenceNums = ({ repairRequestReferenceNums, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_TECHNICIAN_TASKS_BY_REFERENCE_NUMS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/technicians/technician-tasks?repairRequestReferenceNums=${repairRequestReferenceNums}`,
    method: 'GET',
});
export const saveAddVisitTimeSlot = createAction('SAVE_ADD_VISIT_TIME_SLOT');
export const saveAddVisitTechniciansChoice = createAction('SAVE_ADD_VISIT_TECHNICIANS_CHOICE');
export const saveAddVisitNumberOfTechnicians = createAction('SAVE_ADD_VISIT_NUMBER_OF_TECHNICIANS');
export const saveAddVisitTechnicianIds = createAction('SAVE_ADD_VISIT_TECHNICIAN_IDS');
export const getVisitStopwatch = (refNum) => createAPIAction({
    types: [
        ActionCreators.GET_VISIT_STOPWATCH.FETCH(),
        ActionCreators.GET_VISIT_STOPWATCH.SUCCESS(),
        ActionCreators.GET_VISIT_STOPWATCH.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.VISITS_STOPWATCH(refNum),
    method: 'GET',
});
export const addVisitExtraTimeRequest = (refNum, extraTimeRequest) => createAPIAction({
    types: [
        ActionCreators.ADD_VISIT_EXTRA_TIME_REQUEST.FETCH(),
        ActionCreators.ADD_VISIT_EXTRA_TIME_REQUEST.SUCCESS(),
        ActionCreators.ADD_VISIT_EXTRA_TIME_REQUEST.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ADD_VISIT_STOPWATCH_ADDITIONAL_TIME(refNum),
    method: 'POST',
    body: extraTimeRequest,
});
export const toggleVisitLock = ({ visitRefNum, lock, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.TOGGLE_VISIT_LOCK, {
        success: {
            meta: () => ({ lock }),
        },
    }),
    endpoint: API_URLS.ADMIN.TOGGLE_VISIT_LOCK({ visitRefNum, lock }),
    method: 'PUT',
});
export const updateDailyOpsContact = ({ contact, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_DAILY_OPS_CONTACT),
    endpoint: API_URLS.ADMIN.DAILY_OPS_CONTACTS(),
    method: 'PUT',
    body: contact,
});
export const clearFleetVisit = ActionCreators.GET_VISIT.CLEAR;
export const getVisitAuditLog = ({ id, referenceNumber, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_VISIT_AUDIT_LOG, {
        success: {
            meta: () => ({ visitRefNum: referenceNumber }),
        },
    }),
    endpoint: API_URLS.ADMIN.GET_VISIT_AUDIT_LOG({ id, from: 0, size: 20 }),
    method: 'GET',
});
export const getAddressAuditLog = ({ id }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_ADDRESS_AUDIT_LOG),
    endpoint: API_URLS.ADMIN.GET_ADDRESS_AUDIT_LOG({ id, from: 0, size: 20 }),
    method: 'GET',
});
