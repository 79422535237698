export var MeasurementPosition;
(function (MeasurementPosition) {
    MeasurementPosition[MeasurementPosition["Rotor_FL"] = 0] = "Rotor_FL";
    MeasurementPosition[MeasurementPosition["Rotor_FR"] = 1] = "Rotor_FR";
    MeasurementPosition[MeasurementPosition["Rotor_FRL"] = 2] = "Rotor_FRL";
    MeasurementPosition[MeasurementPosition["Rotor_FRR"] = 3] = "Rotor_FRR";
    MeasurementPosition[MeasurementPosition["Rotor_RLI"] = 4] = "Rotor_RLI";
    MeasurementPosition[MeasurementPosition["Rotor_RRI"] = 5] = "Rotor_RRI";
    MeasurementPosition[MeasurementPosition["BrakePad_FL"] = 6] = "BrakePad_FL";
    MeasurementPosition[MeasurementPosition["BrakePad_FR"] = 7] = "BrakePad_FR";
    MeasurementPosition[MeasurementPosition["BrakePad_RL"] = 8] = "BrakePad_RL";
    MeasurementPosition[MeasurementPosition["BrakePad_RR"] = 9] = "BrakePad_RR";
    MeasurementPosition[MeasurementPosition["BrakePad_RLI"] = 10] = "BrakePad_RLI";
    MeasurementPosition[MeasurementPosition["BrakePad_RRI"] = 11] = "BrakePad_RRI";
    MeasurementPosition[MeasurementPosition["DrumBrake_FL"] = 12] = "DrumBrake_FL";
    MeasurementPosition[MeasurementPosition["DrumBrake_FR"] = 13] = "DrumBrake_FR";
    MeasurementPosition[MeasurementPosition["DrumBrake_RL"] = 14] = "DrumBrake_RL";
    MeasurementPosition[MeasurementPosition["DrumBrake_RR"] = 15] = "DrumBrake_RR";
    MeasurementPosition[MeasurementPosition["DrumBrake_RLI"] = 16] = "DrumBrake_RLI";
    MeasurementPosition[MeasurementPosition["DrumBrake_RRI"] = 17] = "DrumBrake_RRI";
    MeasurementPosition[MeasurementPosition["Tire_FL_Out"] = 18] = "Tire_FL_Out";
    MeasurementPosition[MeasurementPosition["Tire_FL_Mid"] = 19] = "Tire_FL_Mid";
    MeasurementPosition[MeasurementPosition["Tire_FL_In"] = 20] = "Tire_FL_In";
    MeasurementPosition[MeasurementPosition["Tire_FR_Out"] = 21] = "Tire_FR_Out";
    MeasurementPosition[MeasurementPosition["Tire_FR_Mid"] = 22] = "Tire_FR_Mid";
    MeasurementPosition[MeasurementPosition["Tire_FR_In"] = 23] = "Tire_FR_In";
    MeasurementPosition[MeasurementPosition["Tire_RL_Out"] = 24] = "Tire_RL_Out";
    MeasurementPosition[MeasurementPosition["Tire_RL_Mid"] = 25] = "Tire_RL_Mid";
    MeasurementPosition[MeasurementPosition["Tire_RL_In"] = 26] = "Tire_RL_In";
    MeasurementPosition[MeasurementPosition["Tire_RR_Out"] = 27] = "Tire_RR_Out";
    MeasurementPosition[MeasurementPosition["Tire_RR_Mid"] = 28] = "Tire_RR_Mid";
    MeasurementPosition[MeasurementPosition["Tire_RR_In"] = 29] = "Tire_RR_In";
    MeasurementPosition[MeasurementPosition["Tire_RLI_Out"] = 30] = "Tire_RLI_Out";
    MeasurementPosition[MeasurementPosition["Tire_RLI_Mid"] = 31] = "Tire_RLI_Mid";
    MeasurementPosition[MeasurementPosition["Tire_RLI_In"] = 32] = "Tire_RLI_In";
    MeasurementPosition[MeasurementPosition["Tire_RRI_Out"] = 33] = "Tire_RRI_Out";
    MeasurementPosition[MeasurementPosition["Tire_RRI_Mid"] = 34] = "Tire_RRI_Mid";
    MeasurementPosition[MeasurementPosition["Tire_RRI_In"] = 35] = "Tire_RRI_In";
    MeasurementPosition[MeasurementPosition["Tire_RFLI_Out"] = 36] = "Tire_RFLI_Out";
    MeasurementPosition[MeasurementPosition["Tire_RFLI_Mid"] = 37] = "Tire_RFLI_Mid";
    MeasurementPosition[MeasurementPosition["Tire_RFLI_In"] = 38] = "Tire_RFLI_In";
    MeasurementPosition[MeasurementPosition["Tire_RFL_Out"] = 39] = "Tire_RFL_Out";
    MeasurementPosition[MeasurementPosition["Tire_RFL_Mid"] = 40] = "Tire_RFL_Mid";
    MeasurementPosition[MeasurementPosition["Tire_RFL_In"] = 41] = "Tire_RFL_In";
    MeasurementPosition[MeasurementPosition["Tire_RFRI_Out"] = 42] = "Tire_RFRI_Out";
    MeasurementPosition[MeasurementPosition["Tire_RFRI_Mid"] = 43] = "Tire_RFRI_Mid";
    MeasurementPosition[MeasurementPosition["Tire_RFRI_In"] = 44] = "Tire_RFRI_In";
    MeasurementPosition[MeasurementPosition["Tire_RFR_Out"] = 45] = "Tire_RFR_Out";
    MeasurementPosition[MeasurementPosition["Tire_RFR_Mid"] = 46] = "Tire_RFR_Mid";
    MeasurementPosition[MeasurementPosition["Tire_RFR_In"] = 47] = "Tire_RFR_In";
    MeasurementPosition[MeasurementPosition["Tire_S_Out"] = 48] = "Tire_S_Out";
    MeasurementPosition[MeasurementPosition["Tire_S_Mid"] = 49] = "Tire_S_Mid";
    MeasurementPosition[MeasurementPosition["Tire_S_In"] = 50] = "Tire_S_In";
    MeasurementPosition[MeasurementPosition["Battery_CCA_Rated"] = 51] = "Battery_CCA_Rated";
    MeasurementPosition[MeasurementPosition["Battery_CCA_Tested"] = 52] = "Battery_CCA_Tested";
    MeasurementPosition[MeasurementPosition["Battery_Voltage_Tested"] = 53] = "Battery_Voltage_Tested";
})(MeasurementPosition || (MeasurementPosition = {}));
