var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { every } from 'lodash';
import moment from 'moment/moment';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { scheduleContext } from 'src/AdminApp/containers/scheduling/util';
import useCurrentRepairSkills from 'src/AdminApp/hooks/useCurrentRepairSkills';
import { clearActiveMechanics, clearMechanicIds, clearSchedulingAvailability, clearTimeslot, fetchActiveMechanicsInRegion, fetchPricing, fetchSchedulingAvailability, saveDateTime, saveShift, saveTimeSlot, setContinueDisabled, saveFutureAppointmentDate, saveFutureAppointmentDateCheck, saveUser, clearVanId, saveMechanicIds, } from 'src/AdminApp/modules/quickRo/actions';
import { selectActiveMechanicsInRegion, selectMechanicIds, selectPricing, selectRepairs, selectSchedulingAvailability, selectTimeSlot, selectUser, selectVanId, selectVehicle, } from 'src/AdminApp/modules/quickRo/selectors';
import { selectRecommendedRepairs, selectRequestDiagnosis, selectRequestRequestReferenceNum, } from 'src/AdminApp/modules/requests/selectors';
import { getServiceAreaByZipCode } from 'src/AdminApp/modules/serviceAreas/actions';
import { searchShifts } from 'src/AdminApp/modules/shifts/actions';
import { selectShifts } from 'src/AdminApp/modules/shifts/selectors';
import { toast } from 'src/components/SimpleToast';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
import { isFullAddress } from 'src/utils/data-utils';
const useScheduling = ({ userAddress, context, preSelectedMechanics, }) => {
    var _a, _b, _c;
    const dispatch = useDispatch();
    const history = useHistory();
    const pageReferenceNum = usePageReferenceNum();
    const { setIsSelectBtnDisabled } = useContext(scheduleContext);
    const [address, setAddress] = useState(userAddress !== null && userAddress !== void 0 ? userAddress : {});
    const [isAddressFull, setIsAddressFull] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [initialized, setInitialized] = useState(false);
    const [isDateTimePickerOpen, setIsDateTimePickerOpen] = useState(false);
    const [isMechanicPreselected, setIsMechanicPreselected] = useState(false);
    const [isSpecificTimeDisabled, setIsSpecificTimeDisabled] = useState(false);
    const [selectedDateTime, setSelectedDateTime] = useState();
    const [selectedFutureAppointmentDate, setSelectedFutureAppointmentDate] = useState();
    const [selectedMinFutureAppointmentDate, setSelectedMinFutureAppointmentDate,] = useState();
    const [currentTab, setCurrentTab] = useState(0);
    const [manualEntryMechanic, setManualEntryMechanic] = useState('');
    const [shift, setShift] = useState();
    const [enableSwitch, setEnableSwitch] = useState(false);
    const [scheduleFutureAppointmentsCheck, setScheduleFutureAppointmentsCheck] = useState(false);
    const [regionFutureAppointment, setRegionFutureAppointment] = useState();
    const mechanicIds = useSelector(selectMechanicIds);
    const [disableDateTimePicker, setDisableDateTimePicker] = useState((!scheduleFutureAppointmentsCheck || mechanicIds.length === 0) &&
        (isSpecificTimeDisabled || mechanicIds.length > 2));
    const activeMechanics = useSelector(selectActiveMechanicsInRegion);
    const schedulingAvailability = useSelector(selectSchedulingAvailability);
    const selectedVanId = useSelector(selectVanId);
    const pricing = useSelector(selectPricing);
    const repairs = useSelector(selectRepairs);
    const selectedTimeSlot = useSelector(selectTimeSlot);
    const vehicle = useSelector(selectVehicle);
    const user = useSelector(selectUser);
    const diagnosis = useSelector((state) => selectRequestDiagnosis(state, pageReferenceNum.selectorProps));
    const recommendedRepairs = useSelector(selectRecommendedRepairs(pageReferenceNum.referenceNum));
    const selectedRecommendedRepairs = useSelector(selectRepairs);
    const shifts = useSelector(selectShifts);
    const skills = (_a = useCurrentRepairSkills()) !== null && _a !== void 0 ? _a : [];
    const request = useSelector(selectRequestRequestReferenceNum(pageReferenceNum.referenceNum));
    const handleClearSchedulingAvailability = () => {
        dispatch(clearTimeslot());
        dispatch(clearSchedulingAvailability());
    };
    const [leadTimeDays, setLeadTimeDays] = useState(0);
    const [start, setStart] = useState((_b = moment()
        .tz(address.timezone)) === null || _b === void 0 ? void 0 : _b.format(DATE_YMD_FORMAT));
    // initialization of shift options
    useEffect(() => {
        dispatch(searchShifts({
            pageSize: 5,
            pageIndex: 0,
            status: 'ACTIVE',
        }));
        const initTimer = setTimeout(() => {
            setInitialized(true);
        }, 100);
        if (repairIds.length === 0) {
            toast.error(`Cannot fetch pricing - No repairs are selected (context: ${context})`);
        }
        if (!(pricing === null || pricing === void 0 ? void 0 : pricing.reFetchDisabled) && (address === null || address === void 0 ? void 0 : address.zip)) {
            dispatch(fetchPricing(Object.assign(Object.assign({ repairIds }, vehicle), { zip: address === null || address === void 0 ? void 0 : address.zip })));
        }
        return () => {
            clearTimeout(initTimer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setDisableDateTimePicker(!scheduleFutureAppointmentsCheck &&
            (isSpecificTimeDisabled ||
                mechanicIds.length > 2 ||
                mechanicIds.length === 0));
    }, [mechanicIds, scheduleFutureAppointmentsCheck, isSpecificTimeDisabled]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (address === null || address === void 0 ? void 0 : address.zip) {
                const serviceArea = yield dispatch(getServiceAreaByZipCode(address === null || address === void 0 ? void 0 : address.zip));
                if (serviceArea.error)
                    return;
                const returnedRegion = serviceArea.payload.region;
                address.regionId = returnedRegion === null || returnedRegion === void 0 ? void 0 : returnedRegion.id;
                const regionHasMaxCapacity = (returnedRegion === null || returnedRegion === void 0 ? void 0 : returnedRegion.futureAppointmentMaxCapacity) > 0;
                setEnableSwitch(regionHasMaxCapacity);
                setRegionFutureAppointment(returnedRegion);
                if (!regionHasMaxCapacity && scheduleFutureAppointmentsCheck) {
                    handleSwitchChange();
                }
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);
    // set shift to the default when shifts are loaded
    useEffect(() => {
        var _a;
        if (!shift && shifts.length) {
            const newShift = (_a = shifts.find((shift) => shift.isDefault)) === null || _a === void 0 ? void 0 : _a.name;
            setShift(newShift);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shifts]);
    useEffect(() => {
        dispatch(saveShift(shift));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shift]);
    useEffect(() => {
        var _a;
        setLeadTimeDays(calculateMaxLeadTime());
        setStart((_a = moment()
            .tz(address.timezone)) === null || _a === void 0 ? void 0 : _a.format(DATE_YMD_FORMAT));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddressFull, address, diagnosis, selectedRecommendedRepairs]);
    useEffect(() => {
        if (isAddressFull &&
            !!address &&
            (!!selectedFutureAppointmentDate || !!selectedMinFutureAppointmentDate) &&
            !!manualEntryMechanic) {
            dispatch(setContinueDisabled(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isAddressFull,
        address,
        selectedFutureAppointmentDate,
        selectedMinFutureAppointmentDate,
        manualEntryMechanic,
    ]);
    // fetch active mechanics in region for filtering
    useEffect(() => {
        if (!(address === null || address === void 0 ? void 0 : address.zip)) {
            dispatch(clearMechanicIds());
            dispatch(clearActiveMechanics());
            handleClearSchedulingAvailability();
            setErrMsg('Cannot fetch active mechanics in region without a zip code');
            return;
        }
        if (start) {
            dispatch(fetchActiveMechanicsInRegion({
                start,
                timezone: address.timezone,
                zipCode: address.zip,
                skillNames: skills.map((skill) => skill.name),
            }));
        }
        const isAddrFull = isFullAddress(address);
        setIsAddressFull(isAddrFull);
        setIsSelectBtnDisabled(!isAddrFull);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, start, skills]);
    // disable schedule now button when time slot is selected
    useEffect(() => {
        setIsSpecificTimeDisabled(!!(selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.date) && !!(selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.time));
    }, [selectedTimeSlot]);
    useEffect(() => {
        dispatch(saveFutureAppointmentDate(selectedFutureAppointmentDate));
        dispatch(setContinueDisabled(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFutureAppointmentDate]);
    useEffect(() => {
        dispatch(saveFutureAppointmentDateCheck(scheduleFutureAppointmentsCheck));
        dispatch(setContinueDisabled(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleFutureAppointmentsCheck]);
    // fetch available time slots
    useEffect(() => {
        var _a, _b, _c;
        if (!initialized ||
            (pricing === null || pricing === void 0 ? void 0 : pricing._isFetching) ||
            (activeMechanics === null || activeMechanics === void 0 ? void 0 : activeMechanics._isFetching) ||
            !start ||
            currentTab === 2) {
            return;
        }
        if (!(pricing === null || pricing === void 0 ? void 0 : pricing.jobDuration)) {
            handleClearSchedulingAvailability();
            setErrMsg(`Cannot fetch time slots - Job duration is unknown (context: ${context})`);
            return;
        }
        if (!isAddressFull) {
            handleClearSchedulingAvailability();
            setErrMsg('Please enter a full address to select a time slot or create a Skedulo job.');
            return;
        }
        dispatch(fetchSchedulingAvailability({
            duration: pricing === null || pricing === void 0 ? void 0 : pricing.jobDuration,
            deliveryAddressZip: address.zip,
            latitude: (_a = address.coordinates) === null || _a === void 0 ? void 0 : _a.latitude,
            longitude: (_b = address.coordinates) === null || _b === void 0 ? void 0 : _b.longitude,
            mechanicsIds: mechanicIds,
            skeduloVanIds: selectedVanId ? [selectedVanId] : undefined,
            skills: skills.map((skill) => skill.id),
            shift,
            repairIds,
            maxLeadTime: (_c = calculateMaxLeadTime()) !== null && _c !== void 0 ? _c : 0,
            repairOrderPartStatus: request === null || request === void 0 ? void 0 : request.partsStatus,
            automaticOrderEnabled: automaticOrderingEnabled,
            referenceNum: pageReferenceNum.referenceNum,
            requestOrigin: 'TOOLS',
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        initialized,
        isAddressFull,
        pricing,
        activeMechanics,
        mechanicIds,
        start,
        shift,
    ]);
    // selected time slot management
    useEffect(() => {
        var _a, _b;
        if (!(schedulingAvailability === null || schedulingAvailability === void 0 ? void 0 : schedulingAvailability._isFetching)) {
            if ((selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.date) && (selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.time)) {
                const tempSlot = (_b = (_a = schedulingAvailability === null || schedulingAvailability === void 0 ? void 0 : schedulingAvailability.slots) === null || _a === void 0 ? void 0 : _a[selectedTimeSlot.date]) === null || _b === void 0 ? void 0 : _b[selectedTimeSlot.time];
                if (Object.keys((schedulingAvailability === null || schedulingAvailability === void 0 ? void 0 : schedulingAvailability.slots) || {}).length === 0) {
                    // no time slots available. clear out selected time slot
                    dispatch(clearTimeslot());
                }
                else if (Object.keys(tempSlot || {}).length === 0) {
                    // selected time slot no longer available. clear it out
                    dispatch(clearTimeslot());
                }
                else if (selectedTimeSlot.slot.vans !== (tempSlot === null || tempSlot === void 0 ? void 0 : tempSlot.vans) ||
                    selectedTimeSlot.slot.mechanics !== (tempSlot === null || tempSlot === void 0 ? void 0 : tempSlot.mechanics)) {
                    // selected time slot has an updated Van or Mechanic
                    dispatch(saveTimeSlot({
                        date: selectedTimeSlot.date,
                        time: selectedTimeSlot.time,
                        slot: tempSlot,
                    }));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedulingAvailability]);
    useEffect(() => {
        var _a, _b, _c;
        if (((_b = (_a = activeMechanics.mechanics) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : -1) >= 0 &&
            ((_c = preSelectedMechanics === null || preSelectedMechanics === void 0 ? void 0 : preSelectedMechanics.length) !== null && _c !== void 0 ? _c : -1) >= 0 &&
            !isMechanicPreselected) {
            setIsMechanicPreselected(true);
        }
        return () => setIsMechanicPreselected(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMechanics, preSelectedMechanics]);
    useEffect(() => {
        dispatch(saveDateTime(selectedDateTime));
        if (selectedDateTime && !!manualEntryMechanic) {
            dispatch(setContinueDisabled(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDateTime, manualEntryMechanic]);
    const calculateMaxLeadTime = () => {
        var _a, _b;
        const existRecommendedRepair = ((_a = recommendedRepairs === null || recommendedRepairs === void 0 ? void 0 : recommendedRepairs.filter(findSelectedRecommendedRepairs).flatMap((num) => num)) === null || _a === void 0 ? void 0 : _a.length) > 0;
        if (existRecommendedRepair) {
            return getRecommendedRepairsMaxLeadTime();
        }
        return ((_b = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _b === void 0 ? void 0 : _b.maxLeadTime) || 0;
    };
    const findSelectedRecommendedRepairs = (rp) => selectedRecommendedRepairs.some((srr) => rp.id === srr.id);
    const getRecommendedRepairsMaxLeadTime = () => {
        const listMaxLeadTime = recommendedRepairs
            .filter(findSelectedRecommendedRepairs)
            .map((item) => item.maxLeadTime)
            .filter((item) => !!item);
        if (listMaxLeadTime.length === 0) {
            return 0;
        }
        return listMaxLeadTime.reduce((prev, curr) => Math.max(prev, curr));
    };
    const automaticOrderingEnabled = every(((_c = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _c === void 0 ? void 0 : _c.knownRepairs) || [], ({ price: prices }) => {
        return every(prices || [], (price) => {
            var _a, _b, _c, _d;
            const fluids = ((_b = (_a = price.fluids) === null || _a === void 0 ? void 0 : _a.filter((au) => (au === null || au === void 0 ? void 0 : au.automaticOrderEnabled) === null ||
                (au === null || au === void 0 ? void 0 : au.automaticOrderEnabled) === false).flatMap((num) => num)) === null || _b === void 0 ? void 0 : _b.length) > 0;
            const parts = ((_d = (_c = price.parts) === null || _c === void 0 ? void 0 : _c.filter((au) => (au === null || au === void 0 ? void 0 : au.automaticOrderEnabled) === null ||
                (au === null || au === void 0 ? void 0 : au.automaticOrderEnabled) === false).flatMap((num) => num)) === null || _d === void 0 ? void 0 : _d.length) > 0;
            return !(fluids || parts);
        });
    });
    const repairIds = repairs.map((r) => r.repairId || r.id);
    const handleLocationChange = (locationAddress) => {
        const newAddress = {
            city: locationAddress.city,
            coordinates: {
                latitude: locationAddress.latitude,
                longitude: locationAddress.longitude,
            },
            state: locationAddress.state,
            streetLine1: locationAddress.street,
            timezone: locationAddress.timezone,
            zip: locationAddress.zip,
        };
        setAddress(newAddress);
        dispatch(saveUser(Object.assign(Object.assign({}, user), { address: newAddress })));
        setErrMsg(undefined);
    };
    const handleManualMechanicSelection = (id) => {
        dispatch(clearMechanicIds());
        dispatch(clearVanId());
        if (id) {
            dispatch(saveMechanicIds(id));
        }
    };
    const handleSwitchChange = () => {
        var _a;
        const value = !scheduleFutureAppointmentsCheck;
        setScheduleFutureAppointmentsCheck(value);
        dispatch(saveFutureAppointmentDateCheck(value));
        if (value) {
            handleManualMechanicSelection('');
            setManualEntryMechanic('');
            dispatch(setContinueDisabled(false));
            let currentDate = new Date();
            const timeZone = regionFutureAppointment === null || regionFutureAppointment === void 0 ? void 0 : regionFutureAppointment.skeduloRegionTimezone;
            if (timeZone) {
                const formattedDateString = currentDate.toLocaleString('en-US', {
                    timeZone,
                    hour12: false,
                });
                currentDate = new Date(formattedDateString);
            }
            const newDate = moment(currentDate)
                .add((_a = regionFutureAppointment === null || regionFutureAppointment === void 0 ? void 0 : regionFutureAppointment.futureAppointmentMinDays) !== null && _a !== void 0 ? _a : 0, 'days')
                .toDate();
            setSelectedFutureAppointmentDate(newDate);
            setSelectedMinFutureAppointmentDate(newDate);
        }
    };
    const goToVisits = () => {
        history.push('/admin/visits');
    };
    const isLoading = !!(pricing === null || pricing === void 0 ? void 0 : pricing._isFetching) ||
        !!(activeMechanics === null || activeMechanics === void 0 ? void 0 : activeMechanics._isFetching) ||
        !!(schedulingAvailability === null || schedulingAvailability === void 0 ? void 0 : schedulingAvailability._isFetching);
    return {
        currentTab,
        setCurrentTab,
        setManualEntryMechanic,
        setSelectedDateTime,
        setSelectedFutureAppointmentDate,
        setSelectedMinFutureAppointmentDate,
        setScheduleFutureAppointmentsCheck,
        isLoading,
        goToVisits,
        shift,
        setShift,
        shifts,
        pricing,
        leadTimeDays,
        skills,
        schedulingAvailability,
        mechanicIds,
        address,
        manualEntryMechanic,
        handleManualMechanicSelection,
        selectedDateTime,
        scheduleFutureAppointmentsCheck,
        handleSwitchChange,
        isDateTimePickerOpen,
        setIsDateTimePickerOpen,
        selectedFutureAppointmentDate,
        selectedMinFutureAppointmentDate,
        disableDateTimePicker,
        enableSwitch,
        errMsg,
        activeMechanics,
        selectedVanId,
        handleLocationChange,
    };
};
export default useScheduling;
