import { ButtonGroup, MenuItem, useMediaQuery } from 'src/components/mui';
import { Add, AddCircleOutline, ArrowDropDown } from 'src/components/mui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeNotesDrawer, getMissing3CRequestNotes, } from 'src/AdminApp/modules/requestNotes/actions';
import { select3CNotesDrawerIsOpen, selectRemaining3CNotes, } from 'src/AdminApp/modules/requestNotes/selectors';
import Indicator from 'src/components/layout/Indicator';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import { colors, theme } from 'src/styles/theme';
import { NotesTypes } from '../constants';
import { AddNotesBtn, AddNotesFab, AddNotesMenu, } from './AddNotesDrawer.styles';
import AddNotesDrawerForm from './AddNotesDrawerForm';
import AddNotesOptionsDrawer from './AddNotesOptionsDrawer';
import Notes3CDrawerContent from './Notes3C/Notes3CDrawerContent';
const AddNotesDrawer = ({ repairRequestId, isAutoIntegrateFMC, completeTask, currentTaskWorkFlow, }) => {
    const [options, setOptions] = useState();
    const [type, setType] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openMobileOptionsDrawer, setOpenMobileOptionsDrawer] = useState(false);
    const open = Boolean(options);
    const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const is3CDrawerOpen = useSelector(select3CNotesDrawerIsOpen);
    useEffect(() => {
        dispatch(getMissing3CRequestNotes(repairRequestId));
    }, [dispatch, repairRequestId]);
    const remaining3CNotes = useSelector(selectRemaining3CNotes);
    const handleOpenDrawer = (type) => {
        setType(type);
        setOpenDrawer(true);
        setOpenMobileOptionsDrawer(false);
        setOptions(undefined);
    };
    const handleCloseDrawer = () => {
        if (!openMobileOptionsDrawer) {
            setType('');
        }
        setOpenMobileOptionsDrawer(false);
        setOpenDrawer(false);
        dispatch(closeNotesDrawer());
    };
    const renderTitle = () => {
        if (type === NotesTypes.CONCERN_CAUSE_CORRECTION) {
            return "Add 3 C's";
        }
        if (!mdAndUp && openMobileOptionsDrawer) {
            return 'Select note type';
        }
        return `${NotesTypes.values[type]} Note`;
    };
    const renderContent = () => {
        if (type === NotesTypes.CONCERN_CAUSE_CORRECTION || is3CDrawerOpen) {
            return (<Notes3CDrawerContent type={NotesTypes.CONCERN_CAUSE_CORRECTION} repairRequestId={repairRequestId} onClose={() => handleCloseDrawer()} completeTask={completeTask} currentTaskWorkFlow={currentTaskWorkFlow}/>);
        }
        if (!mdAndUp && openMobileOptionsDrawer) {
            return (<AddNotesOptionsDrawer onSelect={(value) => handleOpenDrawer(value)} isAutoIntegrateFMC={isAutoIntegrateFMC} remaining3Notes={remaining3CNotes}/>);
        }
        return (<AddNotesDrawerForm type={type} repairRequestId={repairRequestId} onClose={() => handleCloseDrawer()} isAutoIntegrateFMC={isAutoIntegrateFMC}/>);
    };
    return (<>
      {mdAndUp ? (<>
          <ButtonGroup variant="contained">
            <AddNotesBtn color="primary" startIcon={<AddCircleOutline />} variant="contained" onClick={(e) => setOptions(e.currentTarget)}>
              Add Note
            </AddNotesBtn>
            <AddNotesBtn size="small" color="primary" aria-controls={open ? 'notes-options' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={(e) => setOptions(e.currentTarget)}>
              <ArrowDropDown />
            </AddNotesBtn>
          </ButtonGroup>
          <AddNotesMenu id="notes-options" anchorEl={options} open={open} onClose={() => setOptions(undefined)}>
            {!isAutoIntegrateFMC && (<MenuItem component="button" onClick={() => handleOpenDrawer(NotesTypes.CUSTOMER_FACING)}>
                Customer Facing
              </MenuItem>)}
            {isAutoIntegrateFMC && (<MenuItem component="button" onClick={() => handleOpenDrawer(NotesTypes.FMC_FACING)}>
                FMC Facing
              </MenuItem>)}
            <MenuItem component="button" onClick={() => handleOpenDrawer(NotesTypes.INTERNAL)}>
              Internal
            </MenuItem>
            <MenuItem component="button" onClick={() => handleOpenDrawer(NotesTypes.CONCERN_CAUSE_CORRECTION)}>
              Concern, Cause, Correction
              {remaining3CNotes > 0 && (<span style={{ paddingLeft: '5px' }}>
                  <Indicator value={remaining3CNotes.toString()} color={colors.primary} text={colors.white}/>
                </span>)}
            </MenuItem>
          </AddNotesMenu>
        </>) : (<AddNotesFab variant="circular" size="large" color="primary" onClick={() => setOpenMobileOptionsDrawer(true)}>
          <Add />
        </AddNotesFab>)}

      <SimpleDrawer open={openDrawer || (!mdAndUp && openMobileOptionsDrawer)} onClose={handleCloseDrawer} size="oneThird" title={renderTitle()}>
        {renderContent()}
      </SimpleDrawer>
    </>);
};
export default AddNotesDrawer;
