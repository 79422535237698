import { createSelector } from 'reselect';
export const selectPricing = (state) => state.pricing;
export const selectRepairs = createSelector(selectPricing, (pricing) => pricing === null || pricing === void 0 ? void 0 : pricing.repairs);
export const selectAddProductFleets = createSelector(selectPricing, (pricing) => { var _a, _b, _c; return (_c = (_b = (_a = pricing === null || pricing === void 0 ? void 0 : pricing.repairs) === null || _a === void 0 ? void 0 : _a.pricing) === null || _b === void 0 ? void 0 : _b.fleets) !== null && _c !== void 0 ? _c : []; });
export const selectMeasurementsSlidePanelOpen = createSelector(selectPricing, (pricing) => { var _a; return (_a = pricing === null || pricing === void 0 ? void 0 : pricing.repairs) === null || _a === void 0 ? void 0 : _a.measurementsSlidePanelOpen; });
export const selectMeasurements = createSelector(selectPricing, (pricing) => { var _a; return (_a = pricing === null || pricing === void 0 ? void 0 : pricing.repairs) === null || _a === void 0 ? void 0 : _a.measurements; });
export const selectSelectedMeasurement = createSelector(selectPricing, (pricing) => { var _a; return (_a = pricing === null || pricing === void 0 ? void 0 : pricing.repairs) === null || _a === void 0 ? void 0 : _a.selectedMeasurement; });
export const selectRepairMeasurements = createSelector(selectPricing, (pricing) => { var _a; return (_a = pricing === null || pricing === void 0 ? void 0 : pricing.repairs) === null || _a === void 0 ? void 0 : _a.repairMeasurements; });
export const selectDefaultResponsiblePartyInfo = createSelector(selectPricing, (pricing) => { var _a; return (_a = pricing === null || pricing === void 0 ? void 0 : pricing.repairs) === null || _a === void 0 ? void 0 : _a.defaultResponsiblePartyInfo; });
export const selectComponentsByRepairId = (repairId) => createSelector(selectPricing, (pricing) => repairId &&
    pricing.repairs.componentsByRepair[repairId] &&
    Object.values(pricing.repairs.componentsByRepair[repairId]));
export const selectRulesByFleetId = (fleetId) => createSelector(selectPricing, (pricing) => fleetId && pricing.repairs.rules[fleetId]);
export const selectConciergeRulesByRepairId = (repairId) => createSelector(selectPricing, (pricing) => repairId && pricing.repairs.conciergeRules[repairId]);
