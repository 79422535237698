var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TextField, Autocomplete } from 'src/components/mui';
import { DeliveryVanType } from 'src/AdminApp/models/enums';
const VehicleTypeSearch = (_a) => {
    var _b;
    var { value, onUpdate } = _a, rest = __rest(_a, ["value", "onUpdate"]);
    const vehicleTypes = Object.keys(DeliveryVanType).map((x) => {
        return { id: x, name: DeliveryVanType[x] };
    });
    const selectedValue = {
        id: value,
        name: (_b = vehicleTypes.find((x) => x.id === value)) === null || _b === void 0 ? void 0 : _b.name,
    };
    const onSubmit = (submittedValue) => {
        onUpdate(submittedValue);
    };
    return (<Autocomplete value={selectedValue} style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option, value) => option.id === value.id} options={vehicleTypes} getOptionLabel={(option) => { var _a; return (_a = option.name) !== null && _a !== void 0 ? _a : ''; }} onChange={(e, value) => {
            onSubmit(value === null || value === void 0 ? void 0 : value.id);
        }} renderInput={(params) => (<TextField {...params} {...rest} InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default VehicleTypeSearch;
