import { toEnum } from 'src/models/enums';
export const MeasurementUnit = toEnum({
    INCHES: 'INCHES',
    MILLIMETERS: 'MILLIMETERS',
    AMPS: 'AMPS',
    VOLTS: 'VOLTS',
    LITERS: 'LITERS',
    GRAMS: 'GRAMS',
    QUARTS: 'QUARTS',
    PSI: 'PSI',
    THIRTY_SECONDS_OF_AN_INCH: '/32 INCHES',
});
