import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const FlowWindow = styled.div `
  padding: 16px;
`;
export const PaymentField = styled.div `
  margin-bottom: 16px;
`;
export const Warning = styled.div `
  width: 100%;
  object-fit: contain;
  border-radius: 4px;
  background-color: ${colors.GRAY_F5};
  color: ${colors.GRAY_76};
  padding: 16px;
  font-size: 14px;
  border-left: thick solid ${colors.warning};
  margin-top: 24px;
`;
