import { Grid } from 'src/components/mui';
import styled from 'styled-components';
import { Backdrop, CircularProgress } from '@mui/material';
export const RowCompact = styled(Grid) `
  margin-bottom: 5px;
`;
export const StyledDrawerRow = styled.div `
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
`;
export const StyledBackdrop = styled(Backdrop) `
  color: #fff;
  z-index: ${({ theme }) => theme.zIndex.drawer + 10000};
`;
export const Spinner = styled(CircularProgress) `
  color: inherit;
`;
