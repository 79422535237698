import React from 'react';
import { Grid, Typography } from 'src/components/mui';
import CarResidualValue from 'src/AdminApp/models/enums/CarResidualValue';
import HealthReport from 'src/AdminApp/containers/vehicle/CarModule/HealthReport';
import { GridLabelItem, Label, Value, } from 'src/AdminApp/components/layout/Module';
import { FieldWrapper } from 'src/components/form/components';
import { CopyToClipboardButton } from 'src/components/buttons';
import FourCornersDrawer from 'src/AdminApp/containers/vehicle/CarModule/FourCornersDrawer';
import { useSelector } from 'react-redux';
import { selectFourCornerWorkflow, selectFourCornerWorkflowTaskDone, selectWorkflowByReferenceNumber, } from 'src/AdminApp/modules/workflows/selectors';
import useCarModule from './useCarModule';
import CarDrawer from './CarDrawer';
import WarrantyInspection from './Warranty/WarrantyInspection';
const CarModule = ({ canEdit, consumerId, referenceNum, repairRequestId, state, vehicle, }) => {
    var _a;
    const isFourCornerCurrentTask = useSelector(selectFourCornerWorkflow(referenceNum));
    const isFourCornerDone = useSelector(selectFourCornerWorkflowTaskDone(referenceNum));
    const workflow = useSelector(selectWorkflowByReferenceNumber(referenceNum));
    const completedWarrantyTaskExists = workflow === null || workflow === void 0 ? void 0 : workflow.workflowTasks.filter((task) => task.state === 'RS_WARRANTY_CLAIM_INSPECTION_PERFORMED').some((task) => task.status === 'COMPLETED');
    const { isEditing, setIsEditing, onUpdateCar, onDoubleClick, onDoubleTap } = useCarModule({
        canEdit,
        consumerId,
        referenceNum,
        repairRequestId,
        vehicle,
    });
    return (<>
      <Typography variant="h5" gutterBottom>
        Car
      </Typography>
      <FieldWrapper isEditable={canEdit} onDoubleClick={onDoubleClick} {...onDoubleTap}>
        <Grid container>
          <GridLabelItem item xs={12}>
            <Label>Year</Label>
            <Value>{vehicle.year}</Value>
          </GridLabelItem>
          <GridLabelItem item xs={12}>
            <Label>Make</Label>
            <Value>{vehicle.make}</Value>
          </GridLabelItem>
          <GridLabelItem item xs={12}>
            <Label>Model</Label>
            <Value>{vehicle.model}</Value>
          </GridLabelItem>
          <GridLabelItem item xs={12}>
            <Label>Engine</Label>
            <Value>{vehicle.engineDescription}</Value>
          </GridLabelItem>
          <GridLabelItem item xs={12}>
            <Label>Trim</Label>
            <Value>{vehicle.trim}</Value>
          </GridLabelItem>
          <GridLabelItem item xs={12}>
            <Label>License Plate</Label>
            <Value>
              {vehicle.plateNumber}{' '}
              {vehicle.plateNumberState && `(${vehicle.plateNumberState})`}
              {vehicle.plateNumber && (<CopyToClipboardButton value={vehicle.plateNumber} style={{ marginLeft: '-6px' }}/>)}
            </Value>
          </GridLabelItem>
          <GridLabelItem item xs={12}>
            <Label>VIN</Label>
            <Value>
              {vehicle.vin}
              {vehicle.vin && (<CopyToClipboardButton value={vehicle.vin} style={{ marginLeft: '-6px' }}/>)}
            </Value>
          </GridLabelItem>
          <GridLabelItem item xs={12}>
            <Label>Miles In / Out</Label>
            {vehicle.mileage ? (<>
                <Value>{vehicle.mileage}</Value>
                {' / '}
                {vehicle.mileageOut ? (<Value>{vehicle.mileageOut}</Value>) : (<Value grey> Add...</Value>)}
              </>) : (<Value grey> Add...</Value>)}
          </GridLabelItem>
          <GridLabelItem item xs={12}>
            <Label>Residual Value</Label>
            {vehicle.residualValue ? (<Value>
                {CarResidualValue.display((_a = vehicle.residualValue) !== null && _a !== void 0 ? _a : '')}
              </Value>) : (<Value grey> Add...</Value>)}
          </GridLabelItem>
        </Grid>
      </FieldWrapper>

      <FourCornersDrawer car={vehicle} handleUpdateCar={onUpdateCar} isCurrentTask={isFourCornerCurrentTask} isDone={isFourCornerDone} referenceNum={referenceNum}/>
      <HealthReport referenceNum={referenceNum} repairRequestId={repairRequestId} vehicle={vehicle} state={state}/>
      {completedWarrantyTaskExists && <WarrantyInspection />}

      <CarDrawer open={isEditing} onClose={() => setIsEditing(false)} onSubmit={onUpdateCar} vehicle={vehicle}/>
    </>);
};
export default CarModule;
