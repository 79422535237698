import { List, ListItem, ListItemSecondaryAction, ListItemText, Typography, } from 'src/components/mui';
import { KeyboardArrowRight } from 'src/components/mui/icons';
import React, { useContext } from 'react';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import { SimpleDrawerContent } from 'src/components/SimpleDrawer';
import { formatTimestamp } from 'src/utils/formatter';
import styled from 'styled-components';
import { TIME_12H_FORMAT_WITH_SECONDS } from 'src/timeConstants';
import { EXCLUDED_KEYS } from '../pages/History';
import { SliderContext } from '../sliderContext';
const StyledHistoryItemHeader = styled(Typography) `
  color: grey;
  padding-top: 10px;
`;
const StyledChangelogEntry = styled(Typography) `
  color: grey;
`;
const ChangelogEntry = ({ changelog, actionName, children, onClick, }) => {
    return (<ListItem key={changelog.table + changelog.modified} divider button onClick={() => onClick(changelog)}>
      <ListItemText primary={<StyledChangelogEntry display="inline">
            {formatTimestamp(changelog.modified.toString(), TIME_12H_FORMAT_WITH_SECONDS)}
            <span>: </span>
            <AdminUser justName updatedUser={changelog.modifiedBy}/>
          </StyledChangelogEntry>} secondary={<>
            <b> {changelog.table} </b>
            {actionName}
            {children}
          </>}/>
      <ListItemSecondaryAction>
        <KeyboardArrowRight />
      </ListItemSecondaryAction>
    </ListItem>);
};
const HistoryItem = ({ changelogs, onHistoryItemDetailsClick, }) => {
    const { setSlide } = useContext(SliderContext);
    const handleHistoryItemDetailsClick = (changelog) => {
        onHistoryItemDetailsClick(changelog);
        setSlide('historyItemDetails');
    };
    return changelogs && changelogs.length > 0 ? (<SimpleDrawerContent>
      <StyledHistoryItemHeader align="center" variant="subtitle2">
        {changelogs[0].modified
            ? formatTimestamp(changelogs[0].modified.toString())
            : ''}
      </StyledHistoryItemHeader>
      <List dense disablePadding>
        {changelogs === null || changelogs === void 0 ? void 0 : changelogs.map((changelog) => {
            if (changelog.action === 'UPDATE')
                return (<ChangelogEntry changelog={changelog} actionName="changed" onClick={handleHistoryItemDetailsClick}>
                <Typography component="span" display="block">
                  {changelog.changes
                        .filter((change) => !EXCLUDED_KEYS.find((key) => change.field.endsWith(key)))
                        .map((change) => change.field)
                        .join(', ')}
                </Typography>
              </ChangelogEntry>);
            if (changelog.action === 'INSERT')
                return (<ChangelogEntry changelog={changelog} actionName="added" onClick={handleHistoryItemDetailsClick}/>);
            return null;
        })}
      </List>
    </SimpleDrawerContent>) : (<SimpleDrawerContent>LOADING...</SimpleDrawerContent>);
};
export default HistoryItem;
