import { Grid, Radio, Typography } from 'src/components/mui';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { DrawerFormRow, FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import FormDivider from 'src/PricingApp/components/forms/FormDivider';
import { TooltipInfo } from 'src/components/tooltips';
import { AlternativesRadioLabel, DrawerSubtitle, RadioControlLabel, RadioGroupForm, RadioGroupFormControl, } from '../CantFinishDrawer.styles';
import { Steps } from '../CantFinishMenuController';
import { CantFinishReasonTooltipList, CantFinishReasonTooltipTitle, TooltipContainer, } from '../CantFinishTooltip.styles';
const CantFinishDrawerContent = ({ nextStep, }) => {
    const INITIAL_VALUES = {
        cantFinish: '',
    };
    const [showInactiveOut, setShowInactiveOut] = useState(true);
    const [selectedCondition, setSelectedCondition] = useState();
    const StepsString = new Map([
        ['ineligibleForService', Steps.INELIGIBLE_FOR_SERVICE_INTRO],
        ['vehicleConditionTooPoor', Steps.CONDITION_POOR_INTRO],
        [
            'vehicleRequiresAdditionalRepairs',
            Steps.REQUIRES_ADDITIONAL_REPAIRS_INTRO,
        ],
    ]);
    return (<Formik initialValues={INITIAL_VALUES} onSubmit={() => { }} validateOnChange>
      {({ setFieldValue }) => (<>
          <SimpleDrawerContent>
            <Grid container direction="row">
              <Grid item>
                <Typography variant="h6">Tell us why</Typography>
                <DrawerSubtitle paragraph>
                  Why are we not able to complete the requested repairs today?
                </DrawerSubtitle>
              </Grid>

              <DrawerFormRow item xs={12}>
                <FormDivider />

                <RadioGroupFormControl>
                  <RadioGroupForm aria-label="demo-radio-buttons-group-label" name="cantFinishReason" onChange={(event) => {
                setFieldValue('cantFinishReason', event.target.value);
                setShowInactiveOut(false);
                setSelectedCondition(StepsString.get(event.target.value));
            }}>
                    <RadioControlLabel value="ineligibleForService" control={<Radio color="primary"/>} label={<AlternativesRadioLabel>
                          Ineligible for service
                          <TooltipInfo>
                            <TooltipContainer>
                              <CantFinishReasonTooltipTitle>
                                What qualifies as ineligible:
                              </CantFinishReasonTooltipTitle>
                              <CantFinishReasonTooltipList>
                                • Damaged and require a body shop to repair
                              </CantFinishReasonTooltipList>
                              <CantFinishReasonTooltipList>
                                • Disassembled by customer or another shop
                              </CantFinishReasonTooltipList>
                              <CantFinishReasonTooltipList>
                                • Modified from the factory
                              </CantFinishReasonTooltipList>
                              <CantFinishReasonTooltipList>
                                • 1995 model year or older
                              </CantFinishReasonTooltipList>
                              <CantFinishReasonTooltipList>
                                • Failed an emissions test or need an emissions
                                test
                              </CantFinishReasonTooltipList>
                              <CantFinishReasonTooltipList>
                                • Customer attempting to apply their own parts
                              </CantFinishReasonTooltipList>
                            </TooltipContainer>
                          </TooltipInfo>
                        </AlternativesRadioLabel>} labelPlacement="start"/>

                    <RadioControlLabel value="vehicleConditionTooPoor" control={<Radio color="primary"/>} label={<AlternativesRadioLabel>
                          Vehicle condition too poor
                        </AlternativesRadioLabel>} labelPlacement="start"/>

                    <RadioControlLabel value="vehicleRequiresAdditionalRepairs" control={<Radio color="primary"/>} label={<AlternativesRadioLabel>
                          Vehicle requires additional repairs
                        </AlternativesRadioLabel>} labelPlacement="start"/>
                  </RadioGroupForm>
                </RadioGroupFormControl>
              </DrawerFormRow>
            </Grid>
          </SimpleDrawerContent>
          <SimpleDrawerFooter>
            <FooterActionButton disabled={showInactiveOut} onClick={() => nextStep(selectedCondition)}>
              Submit
            </FooterActionButton>
          </SimpleDrawerFooter>
        </>)}
    </Formik>);
};
export default CantFinishDrawerContent;
