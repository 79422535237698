import React, { useState } from 'react';
import { sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import InvoicePdfList from 'src/AdminApp/containers/requests/Snapshot/InvoicePdfList';
import { FileCopy, GetApp } from 'src/components/mui/icons';
import { Button, Grid, Tab, Tabs, Typography, } from 'src/components/mui';
import DiagnosisItem from 'src/AdminApp/components/repairs/statement/Diagnoses/Item';
import styled from 'styled-components';
import InvoiceFooter from 'src/AdminApp/containers/requests/Invoice/InvoiceFooter';
import { colors } from 'src/styles/theme';
import copyToClipboard from 'src/utils/copyToClipboard';
import { isAdmin } from 'src/AdminApp/components/users/utils';
import { getConsumerUrl } from 'src/AdminApp/utils/url-utils';
import QuotedPriceMode from 'src/AdminApp/models/enums/QuotedPriceMode';
import { downloadQuote } from 'src/AdminApp/modules/invoices/actions';
import InfoStatus from 'src/AdminApp/components/repairs/InfoStatus';
import SubletInfo from 'src/AdminApp/components/repairs/statement/Repairs/SubletInfo';
import AuditInfo from 'src/AdminApp/components/users/AuditInfo';
import Total from 'src/AdminApp/containers/requests/Quote/Total';
import CollapsibleBlock from 'src/components/layout/CollapsibleBlock';
import InitialPrice from 'src/AdminApp/components/repairs/statement/InitialPrice';
import { SubItem } from 'src/AdminApp/components/repairs/statement/styledComponents';
import { RepairDropdown, RepairsTableHeader, } from 'src/AdminApp/components/repairs/components/RepairDropdown';
import TotalLaborDuration from 'src/AdminApp/containers/requests/Quote/TotalLaborDuration';
import { TabPanel } from 'src/AdminApp/components/layout/TabPanel';
import { Part, Fluid, Labor, InvoiceForm, Discounts } from './components';
import QuoteDetails from '../Quote/QuoteDetails';
const QuoteFooter = styled(Grid) `
  padding-top: 24px;
  padding-bottom: 50px;
  border-top: 1px dashed ${colors.lightGrey};
`;
const InfoWrapper = styled.div `
  padding: 20px 0;
`;
const COMPONENT_TYPE = {
    PART: 'PART',
    FLUID: 'FLUID',
    LABOR: 'LABOR',
};
const SnapshotV2 = ({ referenceNum, isMobile, workOrderId, quoteSnapshot, invoiceSnapshot, originalInvoice, hasOnlyOneResponsiblePartyType, workOrderResponsiblePartyTypes = [], suggestedRepairsArrayByRefNum = [], }) => {
    var _a, _b, _c, _d, _f, _g;
    const reduxDispatch = useDispatch();
    const [invoiceSnapshotTab, setInvoiceSnapshotTab] = useState(0);
    const [quoteSnapshotTab, setQuoteSnapshotTab] = useState(0);
    const invoice = ((_a = invoiceSnapshot === null || invoiceSnapshot === void 0 ? void 0 : invoiceSnapshot.dataV2Dto) === null || _a === void 0 ? void 0 : _a.data) || null;
    const quote = ((_b = quoteSnapshot === null || quoteSnapshot === void 0 ? void 0 : quoteSnapshot.dataV2Dto) === null || _b === void 0 ? void 0 : _b.data) || null;
    if (!quote && !invoice)
        return <span>No Snapshots</span>;
    const onCopyQuoteLink = () => {
        const link = getConsumerUrl(`my-repairs/${referenceNum}`);
        copyToClipboard(link);
    };
    const onDownloadQuote = () => reduxDispatch(downloadQuote(referenceNum));
    const isCreatedByCustomer = (createdBy) => !isAdmin(createdBy);
    const renderDiagnosis = ({ hideChips, index, isInvoice = false, repair, }) => {
        var _a;
        return (<RepairDropdown key={index} name={`${index + 1}. ${repair.name}`} isOpen readOnlyChips hideChips={hideChips} invoice={invoice} isCreatedByCustomer={isCreatedByCustomer(repair.createdBy)} quoted="" diagnosisId={(_a = repair === null || repair === void 0 ? void 0 : repair.metadata) === null || _a === void 0 ? void 0 : _a.oldId} workOrderItemResponsibleParties={repair.workOrderItemResponsibleParties} shouldDisplayAuditChip={!isInvoice} totalCost={repair.totalCost} created={repair.created} createdBy={repair.createdBy} modified={repair.modified} modifiedBy={repair.modifiedBy}>
      <DiagnosisItem price={repair.subItems}/>

      <InfoWrapper>
        <AuditInfo auditType="ADDED" auditedTimestamp={repair.created} auditedBy={repair.createdBy}/>
        <AuditInfo auditType="MODIFIED" auditedTimestamp={repair.modified} auditedBy={repair.modifiedBy}/>
      </InfoWrapper>
    </RepairDropdown>);
    };
    const renderKnownRepairs = ({ hideChips, index, isInvoice = false, repair, sublet, }) => {
        var _a;
        return (<RepairDropdown key={index} isOpen readOnlyChips hideChips={hideChips} name={`${index + 1}. ${repair.name}`} repairId={(_a = repair === null || repair === void 0 ? void 0 : repair.metadata) === null || _a === void 0 ? void 0 : _a.oldId} invoice={invoice} workOrderItemResponsibleParties={repair.workOrderItemResponsibleParties} quoted={<InitialPrice price={{
                    initialTotalCost: repair.priceObject.initialTotalCost,
                    calculatedMinTotalCost: repair.priceObject.calculatedMinTotalCost,
                    calculatedMaxTotalCost: repair.priceObject.calculatedMaxTotalCost,
                    quotedPriceMode: repair.priceObject.quotedPriceMode,
                }}/>} totalCost={repair.priceObject.componentTotalPrice
                ? repair.priceObject.componentTotalPrice
                : repair.componentTotalPrice} shouldDisplayAuditChip={!isInvoice} isCreatedByCustomer={isCreatedByCustomer(repair.createdBy)} sublet={sublet} created={repair.created} createdBy={repair.createdBy} modified={repair.modified} modifiedBy={repair.modifiedBy}>
      {repair.components
                .filter((c) => c.type === COMPONENT_TYPE.PART && c.status === 'QUOTED')
                .map((component, cIndex) => (<SubItem key={cIndex}>
            <Part key={cIndex} {...component}/>
          </SubItem>))}

      {repair.components
                .filter((c) => c.type === COMPONENT_TYPE.FLUID && c.status === 'QUOTED')
                .map((component, cIndex) => (<SubItem key={cIndex}>
            <Fluid key={cIndex} {...component}/>
          </SubItem>))}

      {repair.components
                .filter((c) => c.type === COMPONENT_TYPE.LABOR)
                .map((component, index) => (<SubItem key={index}>
            <Labor canEdit={false} {...component}/>
          </SubItem>))}

      <AuditInfo auditType="ADDED" auditedTimestamp={repair.created} auditedBy={repair.createdBy}/>
      <AuditInfo auditType="MODIFIED" auditedTimestamp={repair.modified} auditedBy={repair.modifiedBy}/>

      {sublet && <SubletInfo sublet={sublet}/>}
    </RepairDropdown>);
    };
    const renderQuoteRepairs = (data) => {
        if (data.repair.type === 'DIAGNOSIS') {
            return renderDiagnosis(data);
        }
        return renderKnownRepairs(data);
    };
    const renderInvoiceRepairs = ({ hideChips, repair, index, }) => {
        const isSublet = invoice.sublets.find((sublet) => { var _a; return ((_a = sublet.suggestedRepairIds) === null || _a === void 0 ? void 0 : _a.indexOf(repair.metadata.oldId)) !== -1; });
        if (repair.type === 'DIAGNOSIS') {
            return renderDiagnosis({
                hideChips,
                index,
                isInvoice: true,
                repair,
            });
        }
        return renderKnownRepairs({
            hideChips,
            index,
            isInvoice: true,
            repair,
            sublet: isSublet,
        });
    };
    const renderRepairItems = (repairItems = [], isInvoice = false, hideChips = false, responsiblePartyType = null) => {
        var _a;
        const sortFields = ['sortIndex', 'created', 'name', 'description'];
        const knownRepairs = sortBy(repairItems.filter((r) => r.type === 'REPAIR'), sortFields);
        const diagnoses = sortBy(repairItems.filter((r) => r.type === 'DIAGNOSIS'), sortFields);
        const customRequests = sortBy(repairItems.filter((r) => r.type === 'CUSTOM'), sortFields);
        // TODO: support other repair item types if any
        // const others = repairItems.filter(r => r.type !== 'REPAIR' && r.type !== 'DIAGNOSIS' && r.type !== 'CUSTOM');
        let repairs = [...knownRepairs, ...diagnoses, ...customRequests];
        if (quote)
            quote.totalPrice = (_a = quote.workAuthorization) === null || _a === void 0 ? void 0 : _a.totalPrice;
        repairs.forEach((repair) => {
            var _a, _b, _c, _d, _f, _g, _h, _j;
            repair.priceObject = Object.assign(Object.assign({ initialTotalCost: isInvoice && (repair === null || repair === void 0 ? void 0 : repair.type) === 'CUSTOM'
                    ? (_a = repair === null || repair === void 0 ? void 0 : repair.biData) === null || _a === void 0 ? void 0 : _a.quotedTotalCost
                    : (_b = repair === null || repair === void 0 ? void 0 : repair.biData) === null || _b === void 0 ? void 0 : _b.initialTotalCost, calculatedMinTotalCost: ((_c = repair === null || repair === void 0 ? void 0 : repair.biData) === null || _c === void 0 ? void 0 : _c.quotedPriceMode) === 'RANGED' &&
                    ((_d = repair === null || repair === void 0 ? void 0 : repair.biData) === null || _d === void 0 ? void 0 : _d.calculatedTotalCost.min), calculatedMaxTotalCost: ((_f = repair === null || repair === void 0 ? void 0 : repair.biData) === null || _f === void 0 ? void 0 : _f.quotedPriceMode) === 'RANGED' &&
                    ((_g = repair === null || repair === void 0 ? void 0 : repair.biData) === null || _g === void 0 ? void 0 : _g.calculatedTotalCost.max) }, repair === null || repair === void 0 ? void 0 : repair.biData), { quotedPriceMode: (_j = (_h = repair === null || repair === void 0 ? void 0 : repair.biData) === null || _h === void 0 ? void 0 : _h.quotedPriceMode) !== null && _j !== void 0 ? _j : QuotedPriceMode.SINGLE, componentTotalPrice: repair === null || repair === void 0 ? void 0 : repair.componentTotalPrice });
        });
        if (responsiblePartyType) {
            const filteredSuggestedRepairs = suggestedRepairsArrayByRefNum.filter((suggestedRepair) => suggestedRepair === null || suggestedRepair === void 0 ? void 0 : suggestedRepair.workOrderItemResponsibleParties.some((workOrderItemResponsibleParty) => {
                var _a;
                return ((_a = workOrderItemResponsibleParty === null || workOrderItemResponsibleParty === void 0 ? void 0 : workOrderItemResponsibleParty.responsibleParty) === null || _a === void 0 ? void 0 : _a.responsiblePartyTypeId) === (responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.id);
            }));
            const resultRepairs = repairs
                .filter((repair) => filteredSuggestedRepairs.some((suggestedRepair) => { var _a; return suggestedRepair.id === ((_a = repair === null || repair === void 0 ? void 0 : repair.metadata) === null || _a === void 0 ? void 0 : _a.oldId); }))
                .map((repair) => {
                var _a;
                const filteredSuggestedRepair = filteredSuggestedRepairs
                    .filter((suggestedRepair) => { var _a; return suggestedRepair.id === ((_a = repair === null || repair === void 0 ? void 0 : repair.metadata) === null || _a === void 0 ? void 0 : _a.oldId); })
                    .shift();
                const workOrderItemResponsibleParty = filteredSuggestedRepair.workOrderItemResponsibleParties
                    .filter((workOrderItemResponsibleParty) => {
                    var _a;
                    return ((_a = workOrderItemResponsibleParty === null || workOrderItemResponsibleParty === void 0 ? void 0 : workOrderItemResponsibleParty.responsibleParty) === null || _a === void 0 ? void 0 : _a.responsiblePartyTypeId) === (responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.id);
                })
                    .shift();
                repair.priceObject = Object.assign(Object.assign({}, repair.biData), { initialTotalCost: workOrderItemResponsibleParty.amount, calculatedMinTotalCost: workOrderItemResponsibleParty.amount, calculatedMaxTotalCost: workOrderItemResponsibleParty.amount, quotedPriceMode: (_a = repair.biData.quotedPriceMode) !== null && _a !== void 0 ? _a : QuotedPriceMode.SINGLE, workOrderItemResponsiblePartyAmount: workOrderItemResponsibleParty.amount, componentTotalPrice: workOrderItemResponsibleParty.amount });
                return repair;
            });
            repairs = resultRepairs;
            if (quote) {
                quote.totalPrice = repairs.reduce((prev, repair) => {
                    return prev + repair.priceObject.componentTotalPrice;
                }, 0);
            }
        }
        return repairs.map((repair, index) => isInvoice
            ? renderInvoiceRepairs({
                hideChips,
                index,
                repair,
            })
            : renderQuoteRepairs({
                hideChips,
                repair,
                index,
            }));
    };
    const handleChangeInvoiceTab = (_e, value) => setInvoiceSnapshotTab(value);
    const handleChangeQuoteTab = (_e, value) => setQuoteSnapshotTab(value);
    return (<>
      {invoice && (<>
          <Tabs aria-label="scrollable auto tabs example" indicatorColor="primary" scrollButtons="auto" textColor="primary" value={invoiceSnapshotTab} variant={isMobile ? 'fullWidth' : 'scrollable'} onChange={handleChangeInvoiceTab}>
            <Tab label="Admin"/>
            <Tab label="PDFs"/>
          </Tabs>
          <TabPanel index={0} value={invoiceSnapshotTab}>
            <CollapsibleBlock hashName="invoice" header={<Typography variant="h5" gutterBottom>
                  Invoice{`# ${(_c = invoice === null || invoice === void 0 ? void 0 : invoice.invoiceId) !== null && _c !== void 0 ? _c : originalInvoice === null || originalInvoice === void 0 ? void 0 : originalInvoice.id}`} v
                  {invoiceSnapshot === null || invoiceSnapshot === void 0 ? void 0 : invoiceSnapshot.index}
                </Typography>} defaultOpen>
              <div>
                <RepairsTableHeader />
                {renderRepairItems(invoice === null || invoice === void 0 ? void 0 : invoice.repairItems, true)}
              </div>
              <Discounts discounts={invoice === null || invoice === void 0 ? void 0 : invoice.discountDetails}/>
              <InvoiceForm {...invoice === null || invoice === void 0 ? void 0 : invoice.aggregates} {...invoice === null || invoice === void 0 ? void 0 : invoice.fees} {...invoice === null || invoice === void 0 ? void 0 : invoice.taxes} refNum={referenceNum} repairItems={invoice === null || invoice === void 0 ? void 0 : invoice.repairItems} showBreakDown={!hasOnlyOneResponsiblePartyType} sublets={invoice === null || invoice === void 0 ? void 0 : invoice.sublets}/>
              {workOrderId && (<InvoiceFooter canEdit={false} workOrderId={workOrderId}/>)}
            </CollapsibleBlock>
          </TabPanel>
          <TabPanel index={1} value={invoiceSnapshotTab} removePadding>
            <InvoicePdfList workOrderId={workOrderId !== null && workOrderId !== void 0 ? workOrderId : ''}/>
          </TabPanel>
          {workOrderResponsiblePartyTypes.map((workOrderResponsiblePartyType, index) => {
                var _a;
                return (<TabPanel key={`invoice-${index + 1}`} index={2} value={invoiceSnapshotTab}>
                <CollapsibleBlock hashName="invoice" header={<Typography variant="h5" gutterBottom>
                      Invoice{`# ${(_a = invoice === null || invoice === void 0 ? void 0 : invoice.invoiceId) !== null && _a !== void 0 ? _a : originalInvoice === null || originalInvoice === void 0 ? void 0 : originalInvoice.id}`}{' '}
                      v{invoiceSnapshot === null || invoiceSnapshot === void 0 ? void 0 : invoiceSnapshot.index}
                    </Typography>} defaultOpen>
                  <div>
                    <RepairsTableHeader />
                    {renderRepairItems(invoice === null || invoice === void 0 ? void 0 : invoice.repairItems, true, true)}
                  </div>
                  <Discounts discounts={invoice === null || invoice === void 0 ? void 0 : invoice.discountDetails}/>
                  <InvoiceForm {...invoice === null || invoice === void 0 ? void 0 : invoice.aggregates} {...invoice === null || invoice === void 0 ? void 0 : invoice.fees} {...invoice === null || invoice === void 0 ? void 0 : invoice.taxes} sublets={invoice === null || invoice === void 0 ? void 0 : invoice.sublets} repairItems={invoice === null || invoice === void 0 ? void 0 : invoice.repairItems}/>
                  {workOrderId && (<InvoiceFooter canEdit={false} workOrderId={workOrderId}/>)}
                </CollapsibleBlock>
              </TabPanel>);
            })}
          <br />
          <br />
        </>)}

      {quote && (<>
          <Tabs aria-label="scrollable auto tabs example" indicatorColor="primary" scrollButtons="auto" textColor="primary" value={quoteSnapshotTab} variant={isMobile ? 'fullWidth' : 'scrollable'} onChange={handleChangeQuoteTab}>
            <Tab label="Admin"/>
          </Tabs>
          <TabPanel index={0} value={quoteSnapshotTab}>
            <CollapsibleBlock hashName="quote" header={<Typography variant="h5" gutterBottom>
                  {`Quote v${quoteSnapshot === null || quoteSnapshot === void 0 ? void 0 : quoteSnapshot.index}`}
                </Typography>} defaultOpen>
              <div>
                <RepairsTableHeader />
                {renderRepairItems(quote.repairItems, false)}
                <Discounts discounts={quote.discountDetails}/>
              </div>
              <QuoteDetails quote={quote} totalJobDurationMinutes={quote.totalJobDurationMinutes} totalLaborDuration={quote.totalLaborDuration}/>
              <QuoteFooter container>
                <Grid item xs={5}>
                  {!hasOnlyOneResponsiblePartyType ? null : (<TotalLaborDuration canEdit={false} totalLaborDuration={quote.totalLaborDuration}/>)}
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={5}>
                  <Total total={(_d = quote.workAuthorization) === null || _d === void 0 ? void 0 : _d.totalPrice}/>
                </Grid>
              </QuoteFooter>

              <Grid item xs={12}>
                <Button startIcon={<FileCopy />} onClick={onCopyQuoteLink} color="primary">
                  Copy Customer Quote Link
                </Button>

                <Button onClick={onDownloadQuote} color="primary" startIcon={<GetApp />}>
                  Download Quote
                </Button>
              </Grid>

              <InfoStatus provided={(_f = quote === null || quote === void 0 ? void 0 : quote.workAuthorization) === null || _f === void 0 ? void 0 : _f.providedAt} providedBy={(_g = quote === null || quote === void 0 ? void 0 : quote.workAuthorization) === null || _g === void 0 ? void 0 : _g.providedBy}/>
            </CollapsibleBlock>
          </TabPanel>
          {workOrderResponsiblePartyTypes.map((workOrderResponsiblePartyType, index) => {
                var _a, _b;
                return (<TabPanel key={`quote-${index + 1}`} index={index + 1} value={quoteSnapshotTab}>
                <CollapsibleBlock hashName="quote" header={<Typography variant="h5" gutterBottom>
                      Quote
                    </Typography>} defaultOpen>
                  <div>
                    <RepairsTableHeader />
                    {renderRepairItems(quote.repairItems, false, true, workOrderResponsiblePartyType)}
                    <Discounts discounts={quote.discountDetails}/>
                  </div>

                  <QuoteFooter container>
                    <Grid item xs={5}>
                      <TotalLaborDuration canEdit={false} totalLaborDuration={quote.totalLaborDuration}/>
                    </Grid>
                    <Grid item xs={2}/>
                    <Grid item xs={5}>
                      <Total total={quote.totalPrice}/>
                    </Grid>
                  </QuoteFooter>

                  <Grid item xs={12}>
                    <Button startIcon={<FileCopy />} onClick={onCopyQuoteLink} color="primary">
                      Copy Customer Quote Link
                    </Button>

                    <Button onClick={onDownloadQuote} color="primary" startIcon={<GetApp />}>
                      Download Quote
                    </Button>
                  </Grid>

                  <InfoStatus provided={(_a = quote === null || quote === void 0 ? void 0 : quote.workAuthorization) === null || _a === void 0 ? void 0 : _a.providedAt} providedBy={(_b = quote === null || quote === void 0 ? void 0 : quote.workAuthorization) === null || _b === void 0 ? void 0 : _b.providedBy}/>
                </CollapsibleBlock>
              </TabPanel>);
            })}
        </>)}
    </>);
};
export default SnapshotV2;
