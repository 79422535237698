import createAPIAction from 'src/modules/api';
import createDefaultActionCreators, { createActionCreators, } from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import createAction from 'src/modules/api/utils/createAction';
export const ActionCreators = {
    ADD_DELIVERY_VAN_TEMPLATE: createDefaultActionCreators('ADD_DELIVERY_VAN_TEMPLATE'),
    UPDATE_DELIVERY_VAN_TEMPLATE: createDefaultActionCreators('UPDATE_DELIVERY_VAN_TEMPLATE'),
    DELIVERY_VAN_TEMPLATES: createDefaultActionCreators('DELIVERY_VAN_TEMPLATES'),
    DELIVERY_VAN_TEMPLATES_TECH_NAME: createDefaultActionCreators('DELIVERY_VAN_TEMPLATES_TECH_NAME'),
    DELIVERY_VAN_TEMPLATES_SELECT: createActionCreators(['CLEAR'], 'DELIVERY_VAN_TEMPLATES_SELECT'),
    SLIDE_PANEL_DELIVERY_VAN_TEMPLATES: createActionCreators(['OPEN', 'CLOSE'], 'SLIDE_PANEL_DELIVERY_VAN_TEMPLATES'),
    SLIDE_PANEL_DELIVERY_VAN_EDIT_TEMPLATE: createActionCreators(['OPEN', 'CLOSE'], 'SLIDE_PANEL_DELIVERY_VAN_EDIT_TEMPLATE'),
    DELIVERY_VAN_UNAVAILABILITIES: createDefaultActionCreators('DELIVERY_VAN_UNAVAILABILITIES'),
    ADD_DELIVERY_VAN_UNAVAILABILITY: createDefaultActionCreators('ADD_DELIVERY_VAN_UNAVAILABILITY'),
    UPDATE_DELIVERY_VAN_UNAVAILABILITY: createDefaultActionCreators('UPDATE_DELIVERY_VAN_TEMPLATE'),
    DELIVERY_VAN_UNAVAILABILITY_SELECT: createActionCreators(['CLEAR'], 'DELIVERY_VAN_UNAVAILABILITY_SELECT'),
    SLIDE_PANEL_DELIVERY_VAN_UNAVAILABILITIES: createActionCreators(['OPEN', 'CLOSE'], 'SLIDE_PANEL_DELIVERY_VAN_UNAVAILABILITIES'),
    SLIDE_PANEL_DELIVERY_VAN_EDIT_UNAVAILABILITY: createActionCreators(['OPEN', 'CLOSE'], 'SLIDE_PANEL_DELIVERY_VAN_EDIT_UNAVAILABILITY'),
    DELIVERY_VAN_TEMPLATE_TYPE: createActionCreators(['AVAILABILITY', 'UNAVAILABILITY'], 'DELIVERY_VAN_TEMPLATE_TYPE'),
};
export const getVanTemplates = (vanId) => createAPIAction({
    types: [
        ActionCreators.DELIVERY_VAN_TEMPLATES.FETCH(),
        ActionCreators.DELIVERY_VAN_TEMPLATES.SUCCESS(),
        ActionCreators.DELIVERY_VAN_TEMPLATES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VAN_TEMPLATES_BY_VAN_ID(vanId),
    method: 'GET',
});
export const addVanTemplate = (value) => createAPIAction({
    types: [
        ActionCreators.ADD_DELIVERY_VAN_TEMPLATE.FETCH(),
        ActionCreators.ADD_DELIVERY_VAN_TEMPLATE.SUCCESS(),
        ActionCreators.ADD_DELIVERY_VAN_TEMPLATE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VAN_TEMPLATES(),
    body: Object.assign({}, value),
    method: 'POST',
});
export const updateVanTemplate = (value) => createAPIAction({
    types: [
        ActionCreators.UPDATE_DELIVERY_VAN_TEMPLATE.FETCH(),
        ActionCreators.UPDATE_DELIVERY_VAN_TEMPLATE.SUCCESS(),
        ActionCreators.UPDATE_DELIVERY_VAN_TEMPLATE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VAN_TEMPLATES(),
    body: Object.assign({}, value),
    method: 'PUT',
});
export const getTemplateTechName = (techUid) => (dispatch) => {
    return dispatch(createAPIAction({
        types: [
            ActionCreators.DELIVERY_VAN_TEMPLATES_TECH_NAME.FETCH(),
            ActionCreators.DELIVERY_VAN_TEMPLATES_TECH_NAME.SUCCESS(),
            ActionCreators.DELIVERY_VAN_TEMPLATES_TECH_NAME.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.USERS_TECHNICIANS(),
        method: 'GET',
        params: { uuid: techUid, size: 1, includeDisabled: true },
    }));
};
export const getVanUnavailabilities = (vanId, params) => createAPIAction({
    types: [
        ActionCreators.DELIVERY_VAN_UNAVAILABILITIES.FETCH(),
        ActionCreators.DELIVERY_VAN_UNAVAILABILITIES.SUCCESS(),
        ActionCreators.DELIVERY_VAN_UNAVAILABILITIES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VAN_UNAVAILABILIES_BY_VAN_ID(vanId),
    method: 'GET',
    params,
});
export const addVanUnavailability = (value) => createAPIAction({
    types: [
        ActionCreators.ADD_DELIVERY_VAN_UNAVAILABILITY.FETCH(),
        ActionCreators.ADD_DELIVERY_VAN_UNAVAILABILITY.SUCCESS(),
        ActionCreators.ADD_DELIVERY_VAN_UNAVAILABILITY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VAN_UNAVAILABILIES(),
    body: Object.assign({}, value),
    method: 'POST',
});
export const updateVanUnavailability = (value) => createAPIAction({
    types: [
        ActionCreators.UPDATE_DELIVERY_VAN_UNAVAILABILITY.FETCH(),
        ActionCreators.UPDATE_DELIVERY_VAN_UNAVAILABILITY.SUCCESS(),
        ActionCreators.UPDATE_DELIVERY_VAN_UNAVAILABILITY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.DELIVERY_VAN_UNAVAILABILIES(),
    body: Object.assign({}, value),
    method: 'PUT',
});
export const setSelectedTemplate = createAction('SET_SELECTED_TEMPLATE');
export const setSelectedUnavailability = createAction('SET_SELECTED_UNAVAILABILITY');
export const clearVanTemplateSelection = () => ({
    type: ActionCreators.DELIVERY_VAN_TEMPLATES_SELECT.CLEAR.type,
});
export const openTemplatesSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN_TEMPLATES.OPEN.type,
});
export const closeTemplatesSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN_TEMPLATES.CLOSE.type,
});
export const openEditTemplateSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN_EDIT_TEMPLATE.OPEN.type,
});
export const closeEditTemplateSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN_EDIT_TEMPLATE.CLOSE.type,
});
export const clearVanAvailabilitySelection = () => ({
    type: ActionCreators.DELIVERY_VAN_UNAVAILABILITY_SELECT.CLEAR.type,
});
export const openUnavailabilitiesSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN_UNAVAILABILITIES.OPEN.type,
});
export const closeUnavailabilitiesSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN_UNAVAILABILITIES.CLOSE.type,
});
export const openEditUnavailabilitySlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN_EDIT_UNAVAILABILITY.OPEN.type,
});
export const closeEditUnavailabilitySlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_DELIVERY_VAN_EDIT_UNAVAILABILITY.CLOSE.type,
});
export const setDeliveryVanTemplateType = (type) => ({
    type: ActionCreators.DELIVERY_VAN_TEMPLATE_TYPE[type].type,
});
