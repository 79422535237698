import { FormGroup, TextField } from 'src/components/mui';
import React from 'react';
import { DrawerFormRow, SimpleDrawer } from 'src/components/SimpleDrawer';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import FormSelect from 'src/PricingApp/components/forms/FormSelect';
import { AddPartnersAPIAc, SectionHeader } from './AddPartnerAPIDrawer.styles';
import AddPartnersAPIDateTimePickerFrom from './AddPartnersAPIDateTimePickerFrom';
import AddPartnersAPIDateTimePickerTo from './AddPartnersAPIDateTimePickerTo';
import MarketingSourceAutocomplete from './MarketingSourceAutocomplete';
import PartnersAPIAutocomplete from './PartnersAPIAutocomplete';
import useAddPartnerAPIDrawer from './useAddPartnerAPIDrawer';
const AddPartnerAPIDrawer = ({ open, onClose, apiKey, }) => {
    const { notExpire, partnerAPIDescription, partnerAPIIsDefault, partnerAPIValidTo, partnerAPIAccessLevel, isSubmitting, isSubmittingEnabled, setIsSubmitting, isMobile, handleDescriptionChange, handleAccessLevelChange, handleClose, handleIsDefaultAPIKey, handleNotExpire, handleSubmit, } = useAddPartnerAPIDrawer({ open, apiKey, onClose });
    return (<SimpleDrawer open={open} onClose={handleClose} title={`${apiKey ? 'Update ' : 'Add a new '}Partner API`} submitText={`${apiKey ? 'Update' : 'Save'} Partner API Key`} footerActionButtonProps={{
            disabled: isSubmitting || !isSubmittingEnabled,
            onClick: handleSubmit,
            variant: 'contained',
            color: 'primary',
            size: 'large',
            fullWidth: isMobile,
        }} simpleMode size="half">
      <>
        <DrawerFormRow>
          <AddPartnersAPIAc color="blue">
            <PartnersAPIAutocomplete partnerId={apiKey ? apiKey.partnerId : null}/>
          </AddPartnersAPIAc>
        </DrawerFormRow>
        <AddPartnersAPIAc color="blue">
          <MarketingSourceAutocomplete marketingSourceId={apiKey ? apiKey.marketingSourceId : undefined}/>
        </AddPartnersAPIAc>

        <DrawerFormRow>
          <FormGroup>
            <LabelledCheckbox label="Default API Key" checkboxProps={{
            checked: partnerAPIIsDefault,
            onChange: handleIsDefaultAPIKey,
            value: partnerAPIIsDefault,
        }}/>
          </FormGroup>
        </DrawerFormRow>

        <DrawerFormRow>
          <FormGroup>
            <TextField id="partner-api-key-descr" label="Description" placeholder="Description" multiline variant="outlined" value={partnerAPIDescription !== null && partnerAPIDescription !== void 0 ? partnerAPIDescription : null} onChange={handleDescriptionChange} maxRows={4} minRows={0}/>
          </FormGroup>
        </DrawerFormRow>

        <DrawerFormRow>
          <FormGroup>
            <FormSelect title="Access Level" label="Access Level" required defaultValue={partnerAPIAccessLevel} options={['LIMITED', 'PLATFORM']} value={partnerAPIAccessLevel} setValue={(e) => handleAccessLevelChange(e)}/>
          </FormGroup>
        </DrawerFormRow>

        <DrawerFormRow>
          <SectionHeader>Valid From</SectionHeader>

          <AddPartnersAPIDateTimePickerFrom editDate={apiKey ? apiKey.validFrom : undefined}/>
        </DrawerFormRow>

        <DrawerFormRow>
          <LabelledCheckbox label="This does not expire" checkboxProps={{
            checked: notExpire,
            onChange: (e) => handleNotExpire(e.target.checked),
            value: !partnerAPIValidTo,
        }}/>
        </DrawerFormRow>

        {!notExpire && (<DrawerFormRow>
            <SectionHeader>Valid To</SectionHeader>

            <AddPartnersAPIDateTimePickerTo editDate={!!notExpire && apiKey ? apiKey.validTo : undefined} onChange={() => setIsSubmitting(false)}/>
          </DrawerFormRow>)}
      </>
    </SimpleDrawer>);
};
export default AddPartnerAPIDrawer;
