import React from 'react';
import { MoreVert as MoreVertIcon } from 'src/components/mui/icons';
import { EllipsisButton, FloatingMenu, StyledCancelButton, } from './JobInfoMenu.styles';
const JobInfoMenu = ({ open, setOpen, onCancel, cancelDisabled, }) => {
    const handleClick = () => {
        setOpen(!open);
    };
    return (<>
      <EllipsisButton aria-controls={open ? 'job-actions-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        <MoreVertIcon />
      </EllipsisButton>
      <FloatingMenu open={open}>
        <StyledCancelButton disabled={cancelDisabled} onClick={onCancel}>
          Cancel
        </StyledCancelButton>
      </FloatingMenu>
    </>);
};
export default JobInfoMenu;
