import { createGlobalStyle } from 'styled-components';
export const STATIC_URL_BASE = 'https://assets.repairsmith.com/web/';
export const MOBILE_BREAKPOINT = 768;
export const TABLET_RESOLUTION = 1280;
export var Colors;
(function (Colors) {
    Colors["BLACK"] = "#3f3f3f";
    Colors["WHITE"] = "#ffffff";
    Colors["BLUE"] = "#2c56dd";
    Colors["GRAY_76"] = "#767676";
    Colors["GRAY_FA"] = "#FAFAFA";
    Colors["GRAY_AA"] = "#AAAAAA";
    Colors["GRAY_CC"] = "#CCCCCC";
    Colors["GRAY_DE"] = "#DEDEDE";
    Colors["GRAY_EE"] = "#EEEEEE";
    Colors["GRAY_EBED"] = "#EBEDf0";
    Colors["GRAY_F2"] = "#F2F2F2";
    Colors["GRAY_3F"] = "#3F3F3F";
    Colors["GRAY_37"] = "#373737";
    Colors["GRAY_97"] = "#979797";
    Colors["YELLOW"] = "#FFFF00";
    Colors["YELLOW_E1"] = "#FFE100";
    Colors["GREEN"] = "#00B988";
    Colors["ORANGE"] = "#FF6900";
    Colors["RED"] = "#FF0000";
    Colors["BLACK_ICON"] = "#0a1d29";
    Colors["LIGHT_GRAY"] = "#3f3f3f03";
})(Colors || (Colors = {}));
export const FONT_FAMILY = 'AcherusGrotesque';
const addFont = ({ name, weight }) => {
    return `
  @font-face {
    font-family: 'AcherusGrotesque';
    font-style: normal;
    font-display: swap;
    font-weight: ${weight};
    src: url('${STATIC_URL_BASE}fonts/acherus-grotesque-${name}/acherus-grotesque-${name}.eot?#iefix') format('embedded-opentype'),
      url('${STATIC_URL_BASE}fonts/acherus-grotesque-${name}/acherus-grotesque-${name}.woff2') format('woff2'),
      url('${STATIC_URL_BASE}fonts/acherus-grotesque-${name}/acherus-grotesque-${name}.woff') format('woff'),
      url('${STATIC_URL_BASE}fonts/acherus-grotesque-${name}/acherus-grotesque-${name}.ttf') format('truetype'),
      url('${STATIC_URL_BASE}fonts/acherus-grotesque-${name}/acherus-grotesque-${name}.svg#youworkforthem') format('svg')
    ;
    unicode-range:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  }
  `;
};
export const GlobalStyle = createGlobalStyle `
  ${addFont({ name: 'regular', weight: 400 })}
  ${addFont({ name: 'medium', weight: 500 })}
  ${addFont({ name: 'bold', weight: 700 })}
  ${addFont({ name: 'extrabold', weight: 800 })}
  ${addFont({ name: 'black', weight: 900 })}

  #pops-app {
    font-family: 'AcherusGrotesque', 'serif';
    height: 100%;
    color: ${Colors.BLACK}
  }

`;
