var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, InputAdornment } from 'src/components/mui';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { TextField } from 'src/AdminApp/components/form';
import PriceDetailsItem from './PriceDetailsItem';
import { PriceField } from './styledComponents';
export const PriceBreakdownPriceField = ({ label, children, large = false, }) => (<PriceDetailsItem large={large} label={label}>
    <PriceField>{children}</PriceField>
  </PriceDetailsItem>);
const LeftPartGrid = styled(Grid) `
  text-align: right;
`;
export const PriceBreakdownLaborField = ({ label, leftPart, children, large = false, }) => (<PriceDetailsItem label={label} large={large}>
    <Grid container>
      <LeftPartGrid item xs={6}>
        {leftPart}
      </LeftPartGrid>
      <Grid item xs={6}>
        <PriceField>{children}</PriceField>
      </Grid>
    </Grid>
  </PriceDetailsItem>);
const nativeInputProps = {
    style: {
        textAlign: 'right',
        paddingTop: 0,
        paddingBottom: 4,
        fontSize: 14,
    },
};
const AdornmentStyled = styled.span `
  color: ${colors.lightGrey};
  font-size: 0.875rem;
`;
export const InvoiceName = styled.div `
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
`;
const inputFieldProps = (adornmentChar) => ({
    startAdornment: (<InputAdornment position="start">
      <AdornmentStyled>{adornmentChar}</AdornmentStyled>
    </InputAdornment>),
});
export const PriceBreakdownInputField = (_a) => {
    var { editable = true, edit, label, name, onEdit, onCancel, onSubmit, initialValue, large = false } = _a, rest = __rest(_a, ["editable", "edit", "label", "name", "onEdit", "onCancel", "onSubmit", "initialValue", "large"]);
    return (<PriceDetailsItem editable={editable} edit={edit} label={label} onSubmit={onSubmit} onEdit={onEdit} onCancel={onCancel} large={large}>
    {edit ? (<Grid container spacing={2}>
        <Grid item xs={6}/>
        <Grid item xs={6}>
          <TextField inputProps={nativeInputProps} // inputProps aren't apply current nativeInputProps
         InputProps={inputFieldProps('$')} type="text" name={name} {...rest}/>
        </Grid>
      </Grid>) : (<PriceField>{initialValue}</PriceField>)}
  </PriceDetailsItem>);
};
const Text = styled('span') `
  display: block;
  text-align: right;
  width: 100%;
`;
export const PriceBreakdownTaxInputField = (_a) => {
    var _b;
    var { editable = true, label, name, initial, values, edit, onEdit, onCancel, onSubmit, large = false, formatTax = false } = _a, rest = __rest(_a, ["editable", "label", "name", "initial", "values", "edit", "onEdit", "onCancel", "onSubmit", "large", "formatTax"]);
    const taxRateName = `${name}Rate`;
    const taxAmountName = `${name}Amount`;
    const formattedTax = (((_b = initial === null || initial === void 0 ? void 0 : initial[taxRateName]) !== null && _b !== void 0 ? _b : 0) * 100).toFixed(2);
    return (<PriceDetailsItem editable={!!onEdit && editable} edit={edit} large={large} label={label} onEdit={onEdit} onCancel={onCancel} onSubmit={onSubmit}>
      <Grid container spacing={edit ? 2 : 0}>
        <Grid item xs={6}>
          {edit ? (<TextField inputProps={nativeInputProps} InputProps={inputFieldProps('%')} type="text" name={taxRateName} disabled={initial[taxAmountName] !== values[taxAmountName]} {...rest}/>) : (<Text>
              {formatTax ? `${formattedTax}%` : `${initial === null || initial === void 0 ? void 0 : initial[taxRateName]}%`}
            </Text>)}
        </Grid>
        <Grid item xs={6}>
          {edit ? (<TextField inputProps={nativeInputProps} InputProps={inputFieldProps('$')} type="text" name={taxAmountName} disabled={initial[taxRateName] !== values[taxRateName]} {...rest}/>) : (<PriceField>{initial === null || initial === void 0 ? void 0 : initial[taxAmountName]}</PriceField>)}
        </Grid>
      </Grid>
    </PriceDetailsItem>);
};
