var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { toast } from 'src/components/SimpleToast';
import { Button, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, DatePicker, } from 'src/components/mui';
import { ChipInput } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/pages/ServiceAreaPage';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
import { TextField } from 'src/AdminApp/components/form';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import SearchMultiselect from 'src/components/form/SearchMultiselect';
import { selectServiceAreasAutocomplete } from 'src/AdminApp/modules/serviceAreas/selectors';
import { selectRegionsAutocomplete } from 'src/AdminApp/modules/regions/selectors';
import { selectMetrosAutocomplete } from 'src/AdminApp/modules/metros/selectors';
import { selectTerritoriesAutocomplete } from 'src/AdminApp/modules/territories/selectors';
import { selectDeliveryVansAutocomplete } from 'src/AdminApp/modules/deliveryVans/selectors';
import { getServiceAreasAutocomplete } from 'src/AdminApp/modules/serviceAreas/actions';
import { getRegionsAutocomplete } from 'src/AdminApp/modules/regions/actions';
import { getMetrosAutocomplete } from 'src/AdminApp/modules/metros/actions';
import { getTerritoriesAutocomplete } from 'src/AdminApp/modules/territories/actions';
import { getDeliveryVansAutocomplete } from 'src/AdminApp/modules/deliveryVans/actions';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const FullWidthDatePicker = styled(DatePicker) `
  width: 100%;

  &::placeholder {
    font-weight: 700;
  }
`;
const BigButton = styled(Button) `
  height: 100%;
`;
const SearchRegionFilters = ({ onSearch, onReset, onClear, filters, }) => {
    var _a, _b, _c, _d;
    const [bookingEffectiveDayFrom, setBookingEffectiveDayFrom] = useState((_a = filters.bookingEffectiveDayFrom) !== null && _a !== void 0 ? _a : null);
    const [bookingEffectiveDayTo, setBookingEffectiveDayTo] = useState((_b = filters.bookingEffectiveDayTo) !== null && _b !== void 0 ? _b : null);
    const [marketingLaunchDayFrom, setMarketingLaunchDayFrom] = useState((_c = filters.marketingLaunchDayFrom) !== null && _c !== void 0 ? _c : null);
    const [marketingLaunchDayTo, setMarketingLaunchDayTo] = useState((_d = filters.marketingLaunchDayTo) !== null && _d !== void 0 ? _d : null);
    const resetFilter = () => {
        setBookingEffectiveDayFrom(null);
        setBookingEffectiveDayTo(null);
        setMarketingLaunchDayFrom(null);
        setMarketingLaunchDayTo(null);
        onReset();
    };
    const clearFilter = () => {
        resetFilter();
        onClear();
    };
    const getZipCodeAdd = (value) => {
        return value.split(',').map((x) => x.trim());
    };
    const handleZipCodeBeforeAdd = (value) => {
        if (value === '')
            return false;
        const zipCodes = getZipCodeAdd(value);
        let result = true;
        zipCodes.forEach((zipCode) => {
            result = !isNaN(Number(zipCode)) && zipCode.toString().length === 5;
            if (!result) {
                toast.error(`Validation Error: '${zipCode}' is not a zip code`);
            }
        });
        return result;
    };
    const renderDatePicker = ({ label, value, onChange, }) => (<FullWidthDatePicker label={label} value={value} onChange={onChange} format={LOCALIZED_DATE_FORMAT} slotProps={{
            textField: {
                fullWidth: true,
                variant: 'standard',
            },
        }}/>);
    return (<Formik enableReinitialize initialValues={{
            query: '',
            zipCodes: [],
            regionIds: [],
            territoryIds: [],
            metroIds: [],
            serviceAreaIds: [],
            deliveryVanIds: [],
            archived: false,
        }} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            const submitValue = Object.assign(Object.assign({}, values), { bookingEffectiveDayFrom,
                bookingEffectiveDayTo,
                marketingLaunchDayFrom,
                marketingLaunchDayTo });
            submitValue.query = values.query.trim();
            submitValue.regionIds = (values.regionIds || []).map((x) => x.id);
            submitValue.territoryIds = (values.territoryIds || []).map((x) => x.id);
            submitValue.metroIds = (values.metroIds || []).map((x) => x.id);
            submitValue.serviceAreaIds = (values.serviceAreaIds || []).map((x) => x.id);
            submitValue.deliveryVanIds = (values.deliveryVanIds || []).map((x) => x.id);
            yield onSearch(submitValue);
            setSubmitting(false);
        })}>
      {({ values, isSubmitting, resetForm, setFieldValue }) => {
            var _a, _b, _c, _d;
            return (<Form>
          <Grid container style={{ alignContent: 'flex-end', marginBottom: 12 }} spacing={4}>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      {renderDatePicker({
                    label: 'Booking Effective Date From',
                    value: bookingEffectiveDayFrom,
                    onChange: setBookingEffectiveDayFrom,
                })}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {renderDatePicker({
                    label: 'Booking Effective Date To',
                    value: bookingEffectiveDayTo,
                    onChange: setBookingEffectiveDayTo,
                })}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {renderDatePicker({
                    label: 'Marketing Launch Date From',
                    value: marketingLaunchDayFrom,
                    onChange: setMarketingLaunchDayFrom,
                })}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {renderDatePicker({
                    label: 'Marketing Launch Date To',
                    value: marketingLaunchDayTo,
                    onChange: setMarketingLaunchDayTo,
                })}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SearchMultiselect value={(_a = values.territoryIds) !== null && _a !== void 0 ? _a : []} name="territories" label="Territory" onUpdate={(data) => setFieldValue('territoryIds', data)} getEntities={getTerritoriesAutocomplete} selectEntities={selectTerritoriesAutocomplete}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SearchMultiselect value={values.metroIds || []} name="metros" label="Metro" onUpdate={(data) => setFieldValue('metroIds', data)} getEntities={(searchBy) => {
                    var _a;
                    return getMetrosAutocomplete(searchBy !== null && searchBy !== void 0 ? searchBy : '', ((_a = values.territoryIds) !== null && _a !== void 0 ? _a : []).map((x) => x.id));
                }} selectEntities={selectMetrosAutocomplete} loadEntitiesByFocus/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SearchMultiselect value={(_b = values.regionIds) !== null && _b !== void 0 ? _b : []} name="regions" label="Region" onUpdate={(data) => {
                    setFieldValue('regionIds', data);
                }} getEntities={(searchBy) => getRegionsAutocomplete(searchBy !== null && searchBy !== void 0 ? searchBy : '', (values.territoryIds || []).map((x) => x.id), (values.metroIds || []).map((x) => x.id))} selectEntities={selectRegionsAutocomplete} loadEntitiesByFocus/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SearchMultiselect value={(_c = values.serviceAreaIds) !== null && _c !== void 0 ? _c : []} name="serviceAreas" label="Service Area" onUpdate={(data) => setFieldValue('serviceAreaIds', data)} getEntities={(searchBy) => {
                    var _a, _b, _c;
                    return getServiceAreasAutocomplete(searchBy !== null && searchBy !== void 0 ? searchBy : '', ((_a = values.territoryIds) !== null && _a !== void 0 ? _a : []).map((x) => x.id), ((_b = values.metroIds) !== null && _b !== void 0 ? _b : []).map((x) => x.id), ((_c = values.regionIds) !== null && _c !== void 0 ? _c : []).map((x) => x.id));
                }} selectEntities={selectServiceAreasAutocomplete} loadEntitiesByFocus/>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <ChipInput fullWidth label="Zip Code" value={values.zipCodes} validate={(value) => handleZipCodeBeforeAdd(value)} onAddChip={(value) => {
                    const zipCodes = getZipCodeAdd(value);
                    setFieldValue('zipCodes', [
                        ...values.zipCodes,
                        ...zipCodes,
                    ]);
                }} onDeleteChip={(value, index) => {
                    const zipCodes = [...values.zipCodes];
                    zipCodes.splice(index, 1);
                    setFieldValue('zipCodes', zipCodes);
                }}/>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <SearchMultiselect value={(_d = values.deliveryVanIds) !== null && _d !== void 0 ? _d : []} name="deliveryVans" label="Delivery Van" onUpdate={(data) => setFieldValue('deliveryVanIds', data)} getEntities={(searchBy) => {
                    var _a, _b, _c, _d, _e;
                    return getDeliveryVansAutocomplete(searchBy !== null && searchBy !== void 0 ? searchBy : '', ((_a = values.territoryIds) !== null && _a !== void 0 ? _a : []).map((x) => x.id), ((_b = values.metroIds) !== null && _b !== void 0 ? _b : []).map((x) => x.id), ((_c = values.regionIds) !== null && _c !== void 0 ? _c : []).map((x) => x.id), ((_d = values.serviceAreaIds) !== null && _d !== void 0 ? _d : []).map((x) => x.id), (_e = values.zipCodes) !== null && _e !== void 0 ? _e : []);
                }} selectEntities={selectDeliveryVansAutocomplete} loadEntitiesByFocus/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <CheckboxField name="archived" label="Is Archived" checked={values.archived} onChange={(e) => {
                    setFieldValue('archived', e.target.checked);
                }}/>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField placeholder="Filter by territory, metro, region, service area, van or zip code" label="Search Query" name="query" variant="standard" autoFocus fullWidth style={{ minHeight: 50 }}/>
            </Grid>
            <Grid item xs={4} md={2}>
              <BigButton size="small" variant="outlined" fullWidth onClick={() => {
                    resetFilter();
                    resetForm();
                }}>
                Reset Form
              </BigButton>
            </Grid>
            <Grid item xs={4} md={2}>
              <BigButton size="small" variant="outlined" fullWidth onClick={() => {
                    clearFilter();
                    resetForm();
                }}>
                Clear Results
              </BigButton>
            </Grid>
            <Grid item xs={4} md={2}>
              <BigButton size="small" variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting}>
                Search
              </BigButton>
            </Grid>
          </Grid>
        </Form>);
        }}
    </Formik>);
};
export default SearchRegionFilters;
