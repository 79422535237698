import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOrderParts } from 'src/PopsApp/modules/parts/actions';
import { selectOrderParts } from 'src/PopsApp/modules/parts/selectors';
import { IconButton, Divider } from 'src/components/mui';
import { Edit, Cancel } from 'src/components/mui/icons';
const OrderParts = ({ selectedJobs, onEdit, selectedPart, }) => {
    const orderParts = useSelector(selectOrderParts);
    const dispatch = useDispatch();
    const handleDeleteClick = (order) => () => {
        const newOrder = orderParts.filter((o) => {
            return !(o.referenceNum === order.referenceNum && o.part.id === order.part.id);
        });
        dispatch(setOrderParts([...newOrder]));
    };
    const handleEditClick = (order) => () => {
        onEdit(order);
    };
    return (<>
      {orderParts.map((order) => {
            var _a;
            return (<div key={`${order.referenceNum}-${order.part.id}`} className="accordionPart">
          <div>
            <h6 className="accordionPartTitle">
              {(_a = order.part.partName) !== null && _a !== void 0 ? _a : order.part.name}
              {order.isFitment ? ` • ${order.part.partNumber}` : ''}
            </h6>
            <p className="accordionPartSubtitle">
              Job {order.jobNumber} of {selectedJobs.length}
            </p>
          </div>
          <div className="accordionActions">
            <IconButton onClick={handleEditClick(order)}>
              <Edit />
            </IconButton>
            <IconButton onClick={handleDeleteClick(order)}>
              <Cancel />
            </IconButton>
          </div>
        </div>);
        })}

      {!orderParts.length ? (<div className="noPartsSelected">0 parts selected</div>) : (!!selectedPart && <Divider />)}
    </>);
};
export default OrderParts;
