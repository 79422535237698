import { Button } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const Btn = styled(Button) `
  padding: 10px 16px;
  text-transform: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.28px;
  border-radius: 8px;
  background: ${({ variant }) => variant === 'contained'
    ? `linear-gradient(98deg, ${colors.celebrationPink} 0%, ${colors.brandPink} 100%)`
    : 'transparent'};
  border: ${({ variant }) => variant === 'contained' || variant === 'text'
    ? 'none'
    : `1px solid ${colors.brandPink}`};

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      linear-gradient(
        90deg,
        ${colors.celebrationPink} 0%,
        ${colors.brandPink} 100%
      );
    color: ${colors.white};
  }

  &:active {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(
        90deg,
        ${colors.celebrationPink} 0%,
        ${colors.brandPink} 100%
      );
  }

  &.Mui-disabled {
    background: #dedede;
    color: rgba(118, 118, 118, 0.48) !important;
    box-shadow: none;
  }

  &.MuiButton-contained {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
  }

  .MuiButton-outlined {
    &.Mui-disabled {
      color: rgba(118, 118, 118, 0.24);
      border: 1px solid rgba(118, 118, 118, 0.24);
    }
  }
`;
