import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SlidePageContent, SlidePageWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import LaborRates from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/LaborRates';
import { ActionCreators } from 'src/AdminApp/modules/geoManagement/actions';
const Container = styled('div') `
  padding: 8px 0;
`;
const ArchivedLaborRatesPage = () => {
    const dispatch = useDispatch();
    useEffect(() => () => {
        dispatch(ActionCreators.LABOR_RATES.CLEAR_ARCHIVED());
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return (<SlidePageWrapper>
      <SlidePageContent>
        <Container>
          <LaborRates />
        </Container>
      </SlidePageContent>
    </SlidePageWrapper>);
};
export default ArchivedLaborRatesPage;
