export const getOwnedStatus = (balanceOwed, totalInvoicePrice, recalculateTax) => {
    if (balanceOwed === 0) {
        return 'default';
    }
    if (balanceOwed === totalInvoicePrice && !recalculateTax) {
        return 'error';
    }
    return 'warning';
};
export const getPaidStatus = (balanceOwed) => {
    if (balanceOwed === 0) {
        return 'success';
    }
    return 'default';
};
