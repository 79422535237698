import styled from 'styled-components';
import { theme } from 'src/styles/theme';
export const PaymentFormContainer = styled.section `
  display: flex;
  flex-direction: column;
  padding: 0;

  ${theme.breakpoints.down('sm')} {
    margin-top: 16px;
  }
`;
