import React from 'react';
import SimpleChip from 'src/components/SimpleChip';
import { FLEET_VISIT_STATUS_COLORS, FLEET_VISIT_STATUS_COLORS_TEXT, MPI_STATUS_COLORS, MPI_STATUS_COLORS_TEXT, } from 'src/FleetVisitsApp/constants';
export const MpiStatusChip = ({ mpiStatus }) => {
    const status = mpiStatus !== null && mpiStatus !== void 0 ? mpiStatus : 'PENDING';
    return (<SimpleChip $bgColor={MPI_STATUS_COLORS[status]} $textColor={MPI_STATUS_COLORS_TEXT[status]} label={status.replace(/_/gi, ' ')}/>);
};
export const RoStatusChip = ({ repairOrderStatus, }) => {
    return (<SimpleChip $bgColor={FLEET_VISIT_STATUS_COLORS[repairOrderStatus]} $textColor={FLEET_VISIT_STATUS_COLORS_TEXT[repairOrderStatus]} label={repairOrderStatus.replace(/_/gi, ' ')}/>);
};
