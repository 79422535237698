import React from 'react';
import RepairRequestJobInfo from './JobInfoByType/RepairRequestJobInfo';
import VisitJobInfo from './JobInfoByType/VisitJobInfo';
import PartPickupJobInfo from './JobInfoByType/PartPickupJobInfo';
const JobInfoContent = ({ selectedJobs, jobType, referenceNum, showMoveButton, jobIdx, onJobUpdate, }) => {
    var _a;
    const type = jobIdx ? selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.type : jobType;
    const render = {
        REPAIR_REQUEST: (<RepairRequestJobInfo selectedJobs={selectedJobs} showMoveButton={showMoveButton} jobIdx={jobIdx} onJobUpdate={onJobUpdate ? (id) => onJobUpdate(id) : undefined} referenceNum={referenceNum}/>),
        VISIT: (<VisitJobInfo selectedJobs={selectedJobs} referenceNum={referenceNum} showMoveButton={showMoveButton} jobIdx={jobIdx} onJobUpdate={onJobUpdate ? (id) => onJobUpdate(id) : undefined}/>),
        PART_PICKUP: (<PartPickupJobInfo selectedJobs={selectedJobs} referenceNum={referenceNum} jobIdx={jobIdx}/>),
    };
    return (_a = render[type !== null && type !== void 0 ? type : '']) !== null && _a !== void 0 ? _a : <div />;
};
export default JobInfoContent;
