import { isNil } from 'lodash';
export const getPrecisionHours = (value) => {
    return value % 60 === 0 ? 1 : 2;
};
export const convertMinutesToHours = (minutes) => {
    if (isNil(minutes))
        return 'N/A';
    const hours = minutes / 60;
    const finalNumberString = hours % 1 === 0
        ? hours.toString()
        : hours.toPrecision(getPrecisionHours(minutes));
    return finalNumberString.replace(/\.0$/gi, '');
};
export const convertCapacityToHours = (visit) => {
    if (!visit)
        return {
            totalAvailableHours: 'N/A',
            totalScheduledHours: 'N/A',
            totalRemainingHours: 'N/A',
        };
    const totalAvailableHours = convertMinutesToHours(visit.totalAvailableLaborMinutes);
    const totalScheduledHours = convertMinutesToHours(visit.totalScheduledLaborMinutes);
    const totalRemainingHours = convertMinutesToHours(visit.totalRemainingLaborMinutes);
    return { totalAvailableHours, totalScheduledHours, totalRemainingHours };
};
