import { WatchLater as WatchLaterIcon } from 'src/components/mui/icons';
import { TimePicker } from 'src/components/mui';
import React, { useCallback } from 'react';
import { ActionButton, DestructiveButton } from 'src/components/buttons';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleModal from 'src/components/SimpleModal';
import { RowWrapper } from 'src/components/SimpleTwoButtonRow';
import useLunchTaskDrawer from 'src/TechApp/components/LunchTask/LunchTaskDrawer/useLunchTaskDrawer';
import { colors } from 'src/styles/theme';
import { getMomentValueOrNull, getTimeString } from 'src/utils/formatter';
import { TIME_12H_FORMAT, TIME_FORMAT } from 'src/timeConstants';
import { renderTimeWindow } from '../../TaskParts';
import { FormRow, LunchInfo, LunchSubheader, LunchSubheaderWrapper, LunchTitle, } from './LunchTaskDrawer.styles';
const LunchTaskDrawer = ({ id, isOpen, onClose }) => {
    const { lunchBreak, goToDashBoardHandler, isMobile, isLoading, submitLunchBreak, endTime, setIsModalOpen, setStartTime, startTime, setEndTime, isModalOpen, goBackHandler, okayHandler, } = useLunchTaskDrawer({
        id,
        isOpen,
        onClose,
    });
    const getButtons = useCallback(() => {
        if (lunchBreak && lunchBreak.actualStartTime && lunchBreak.actualEndTime) {
            return (<FooterActionButton onClick={goToDashBoardHandler} disabled={isLoading}>
          Back To Tech Dashboard
        </FooterActionButton>);
        }
        if (lunchBreak && lunchBreak.actualStartTime) {
            return (<FooterActionButton onClick={() => {
                    submitLunchBreak('COMPLETED');
                }} disabled={isLoading || !endTime}>
          End Lunch
        </FooterActionButton>);
        }
        return (<>
        <DestructiveButton onClick={() => {
                setIsModalOpen(true);
            }} disabled={isLoading}>
          Cancel Lunch
        </DestructiveButton>
        <ActionButton onClick={() => {
                submitLunchBreak('PENDING');
            }} disabled={isLoading}>
          Start Lunch
        </ActionButton>
      </>);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endTime, isLoading, isMobile, lunchBreak]);
    return (<SimpleDrawer open={isOpen} onClose={onClose} title="Lunch">
      <SimpleDrawerContent>
        <LunchTitle>Break Log</LunchTitle>
        <LunchSubheaderWrapper>
          <WatchLaterIcon htmlColor={colors.lightGrey}/>
          <LunchSubheader>
            Scheduled Time{' '}
            {renderTimeWindow(lunchBreak === null || lunchBreak === void 0 ? void 0 : lunchBreak.scheduledStartTime, lunchBreak === null || lunchBreak === void 0 ? void 0 : lunchBreak.scheduledEndTime)}
          </LunchSubheader>
        </LunchSubheaderWrapper>
        <LunchSubheader style={{ marginBottom: '16px' }}>
          Actual Time
        </LunchSubheader>
        <FormRow>
          <TimePicker label="Start Time" value={getMomentValueOrNull(startTime, TIME_FORMAT)} onChange={(val) => {
            setStartTime(getTimeString(val, TIME_FORMAT));
        }} minutesStep={1} format={TIME_12H_FORMAT} disabled slotProps={{
            textField: {
                fullWidth: true,
                variant: 'outlined',
                margin: 'none',
            },
        }}/>
        </FormRow>

        <FormRow style={{ marginBottom: '16px' }}>
          <TimePicker label="End Time" value={getMomentValueOrNull(endTime, TIME_FORMAT)} onChange={(val) => {
            setEndTime(getTimeString(val, TIME_FORMAT));
        }} minutesStep={1} format={TIME_12H_FORMAT} disabled slotProps={{
            textField: {
                fullWidth: true,
                variant: 'outlined',
                margin: 'none',
            },
        }}/>
        </FormRow>
        <LunchInfo>*Cancelling will remove lunch from schedule.</LunchInfo>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <RowWrapper>{getButtons()}</RowWrapper>
      </SimpleDrawerFooter>
      <SimpleModal open={isModalOpen} onClose={() => {
            setIsModalOpen(!isModalOpen);
        }} title="Cancel Lunch?" simpleTwoButtonRowProps={{
            onCancel: goBackHandler,
            cancelText: 'Go Back',
            onSubmit: okayHandler,
            submitText: 'Okay',
        }}>
        If you remove this lunch, it will be removed from your schedule.
      </SimpleModal>
    </SimpleDrawer>);
};
export default LunchTaskDrawer;
