var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { SubText } from 'src/components/SimpleDrawer';
import { Fab, IconButton } from 'src/components/mui';
import { AddAPhotoRounded, DeleteForeverRounded, } from 'src/components/mui/icons';
import { Tooltip } from 'src/components/tooltips';
import useIsMobile from 'src/hooks/useIsMobile';
import { colors, theme } from 'src/styles/theme';
import styled from 'styled-components';
const GalleryWrapper = styled.section `
  padding: 18px 0 32px;
  margin-top: 16px;
  border-bottom: 1px solid ${colors.lightGrey};
  border-top: 1px solid ${colors.lightGrey};
`;
const PhotoGallery = styled.section `
  margin-top: 16px;
`;
const NoPhotos = styled.div `
  padding: 30px 0;
  text-align: center;
  background: ${colors.lightSilver};
  color: ${colors.darkGrey};
  border-radius: 4px;
  margin-top: 27px;
`;
const PhotoCount = styled.div `
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.3px;
  color: ${colors.GRAY_3F};
  display: flex;
  align-items: center;

  svg {
    color: ${colors.GRAY_D4};
    margin-right: 4px;
  }

  span {
    position: relative;
    top: 3px;
  }
`;
const UploadPhotoFab = styled(Fab) `
  position: absolute;
  bottom: 16px;
  right: 0;
  height: 69px;
  width: 69px;

  label {
    padding: 0;
    line-height: 1;
  }
`;
const PhotoItem = styled.div `
  background: ${colors.GRAY_EB};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 8px;
  color: ${colors.linkBlue};
  text-decoration: underline;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;
const Thumb = styled.img `
  width: 100px;

  ${theme.breakpoints.up('md')} {
    width: 100%;
  }
`;
const UploadLink = styled.label `
  margin-top: 24px;
  display: block;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.secondary};
  text-align: left;
  text-decoration: underline;
`;
const WrongPartPhotoUpload = ({ photos, addPhoto, removePhoto, }) => {
    var _a;
    const isMobile = useIsMobile();
    const photosLength = Object.keys(photos).length;
    return (<>
      <h2>Take a Picture</h2>
      <SubText>Add any additional pictures.</SubText>

      <GalleryWrapper>
        <PhotoCount>
          <AddAPhotoRounded />
          <span>Attached Photos ({photosLength})</span>
        </PhotoCount>
        {photosLength === 0 && <NoPhotos>No Attached Photos</NoPhotos>}

        {photosLength > 0 && (<PhotoGallery>
            {(_a = Object.keys(photos)) === null || _a === void 0 ? void 0 : _a.map((key) => {
                return (<PhotoItem key={key}>
                  <Tooltip title={<div>
                        <Thumb alt={key} src={photos[key]}/>
                      </div>}>
                    <span>{key}</span>
                  </Tooltip>
                  <IconButton onClick={() => removePhoto(key)}>
                    <DeleteForeverRounded />
                  </IconButton>
                </PhotoItem>);
            })}
          </PhotoGallery>)}
      </GalleryWrapper>

      {!isMobile && (<UploadLink htmlFor="upload-additional2">
          <div>Upload Photos</div>
          <input type="file" style={{ display: 'none' }} id="upload-additional2" accept="image/*" onChange={(e) => __awaiter(void 0, void 0, void 0, function* () {
                const file = e.target.files && e.target.files[0];
                if (!file)
                    return;
                addPhoto(file);
            })}/>
        </UploadLink>)}

      {isMobile && (<UploadPhotoFab color="secondary" aria-label="Upload Photo" title="Upload Photo" variant="circular" size="large">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="upload-additional">
            <AddAPhotoRounded fontSize="large"/>

            <input type="file" style={{ display: 'none' }} id="upload-additional" accept="image/*" onChange={(e) => __awaiter(void 0, void 0, void 0, function* () {
                const file = e.target.files && e.target.files[0];
                if (!file)
                    return;
                addPhoto(file);
            })}/>
          </label>
        </UploadPhotoFab>)}
    </>);
};
export default WrongPartPhotoUpload;
