import React, { useState } from 'react';
import PartDistributorAutocomplete from 'src/components/form/PartDistributorAutocomplete';
import PlacesAndPartDistributorLocationsAutoComplete from 'src/components/form/PlacesAndPartDistributorLocationsAutoComplete';
import { Button, FormControl, TextField } from 'src/components/mui';
import useIsMobile from 'src/hooks/useIsMobile';
import { VendorSelect } from './VendorManualInput.styles';
const VendorManualInput = ({ onVendorSubmit }) => {
    const isMobile = useIsMobile();
    const [distributorLocationId, setDistributorLocationId] = useState();
    const [distributorName, setDistributorName] = useState();
    const [distributor, setDistributor] = useState();
    const [distributorAddress, setDistributorAddress] = useState();
    const [dealershipName, setDealershipName] = useState();
    const handleDistributorChange = (value) => {
        setDistributor(value);
        if (!value || !value.name || value.name.toLowerCase() === 'other') {
            setDistributorName(undefined);
        }
        else {
            setDistributorName(value.name);
        }
        if (distributorLocationId) {
            setDistributorAddress(undefined);
        }
        setDistributorLocationId(undefined);
    };
    const handleDistributorNameChange = (e) => {
        setDistributorName(e.target.value);
    };
    const handleAddressChange = (address) => {
        if (!address) {
            setDistributorAddress(undefined);
            setDistributorLocationId(undefined);
            return;
        }
        setDistributorAddress(address.addressStr);
        if (address.partDistributorLocation) {
            setDistributorLocationId(address.id);
        }
        else {
            setDistributorLocationId(undefined);
        }
        setDealershipName(address === null || address === void 0 ? void 0 : address.dealershipName);
    };
    const handleVendorSubmit = () => {
        var _a;
        onVendorSubmit({
            partDistributorName: distributorName,
            partDistributorId: (_a = distributor === null || distributor === void 0 ? void 0 : distributor.id) !== null && _a !== void 0 ? _a : '',
            partDistributorLocationId: distributorLocationId,
            address: distributorAddress,
            dealershipName,
        }, true, {});
    };
    return (<VendorSelect fullScreen={isMobile}>
      <div className="dialogRoot">
        <div className="vendorDialogText">
          Select the vendor you’d like to order parts from.
        </div>
        <div>
          <FormControl variant="outlined" className="selectVendor">
            <PartDistributorAutocomplete value={distributor} name="vendor" label="Vendor" onSelect={(vendor) => {
            handleDistributorChange(vendor);
        }}/>
          </FormControl>
          {(distributor === null || distributor === void 0 ? void 0 : distributor.name.toLowerCase()) === 'other' && (<FormControl variant="outlined" className="selectVendor">
              <TextField variant="outlined" label="Vendor - Other" value={distributorName} onChange={handleDistributorNameChange} inputProps={{
                id: 'outlined-vendor-other',
            }}/>
            </FormControl>)}
          <FormControl variant="outlined" className="selectAddress">
            <PlacesAndPartDistributorLocationsAutoComplete variant="outlined" alignItems="flex-start" address={distributorAddress !== null && distributorAddress !== void 0 ? distributorAddress : ''} label="Address" searchOptions={{
            componentRestrictions: { country: 'us' },
            types: ['address'],
        }} disabled={!(distributor === null || distributor === void 0 ? void 0 : distributor.id)} onSelect={handleAddressChange} partDistributorId={distributor === null || distributor === void 0 ? void 0 : distributor.id} isHub={(distributor === null || distributor === void 0 ? void 0 : distributor.name) === 'RepairSmith Hub'}/>
          </FormControl>
        </div>
      </div>
      <div className="dialogActions">
        <Button className="vendorDialogButton" onClick={handleVendorSubmit} color="primary" variant="contained" disabled={!distributor || !distributorName || !distributorAddress}>
          Submit
        </Button>
      </div>
    </VendorSelect>);
};
export default VendorManualInput;
