import React from 'react';
import Loader from 'src/AdminApp/containers/requests/StateTracker/Loader';
import { HeyTechCopy, HeyTechHeader, HeyTechWrapper, } from 'src/components/layout/HeyTech';
import { Grid } from 'src/components/mui';
import { Error as ErrorIcon } from 'src/components/mui/icons';
import usePartPickupMissingParts from 'src/PopsApp/pages/PartPickup/PartPickupMissingParts/usePartPickupMissingParts';
import { ErrorIconStyle, MissingPartsDivider, PartsPickupButton, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
import { PartPickupButtonsWrapper } from '../Details/PartPickupDetails.styles';
import { Container, Content, Title } from './PartPickupMissingParts.styles';
const PartPickupMissingParts = () => {
    const { isLoading, handleBack, handleOk } = usePartPickupMissingParts();
    return (<Container>
      <Title>Missing Parts</Title>
      <Content>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <HeyTechWrapper>
              <ErrorIcon style={ErrorIconStyle}/>
              <HeyTechHeader>Something's Missing...</HeyTechHeader>
              <HeyTechCopy>
                It looks like there’s some parts you didn’t get. If so they will
                be re-submitted to the parts team queue at a high priority
              </HeyTechCopy>
            </HeyTechWrapper>
            <MissingPartsDivider />
          </Grid>
          <Grid item xs={12} md={12}>
            {isLoading && <Loader />}
            <PartPickupButtonsWrapper>
              <PartsPickupButton color="primary" variant="contained" onClick={handleBack} fullWidth={false} disabled={isLoading}>
                Go Back
              </PartsPickupButton>
              <PartsPickupButton color="primary" variant="contained" onClick={handleOk} fullWidth={false} disabled={isLoading}>
                Okay
              </PartsPickupButton>
            </PartPickupButtonsWrapper>
          </Grid>
        </Grid>
      </Content>
    </Container>);
};
export default PartPickupMissingParts;
