var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSubmitQuickRO from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/useSubmitQuickRO';
import { toast } from 'src/components/SimpleToast';
import { parseStringNumber } from 'src/utils/data-utils';
import { selectContinueDisabled, selectCustomAddress, selectCustomVehicle, selectFleet, selectIsBackNavDisabled, selectNextTab, selectRepairs, selectTab, selectTimeSlot, selectVehicle, selectVisit, } from 'src/AdminApp/modules/quickRo/selectors';
import { updateConsumerAddress } from 'src/AdminApp/modules/consumers/actions';
import { addCarToFleet, getFleet, searchFleetsByName, } from 'src/AdminApp/modules/fleets/actions';
import { ActionCreators, setContinueDisabled, setCustomConsumerAddress, setCustomFleet, setFleet, setNextTab, setRepairs, setTab, setVehicle, } from 'src/AdminApp/modules/quickRo/actions';
import { loadRequest } from 'src/AdminApp/modules/requests/actions';
import { getTechnicianOnSiteWorkflowByRef } from 'src/AdminApp/modules/workflows/actions';
import { FLEET_TABS as TABS } from 'src/AdminApp/modules/quickRo/enums';
import AddNewVehicle from './components/AddNewVehicle';
import AddServices from './components/AddServices';
import GarageWrapper from './components/GarageWrapper';
import ResetPartsWrapper from './components/ResetPartsWrapper';
import SearchAddress from './components/SearchAddress';
import SelectAppointment from './components/SelectAppointment';
import SelectFleetTab from './components/SelectFleetTab';
import SelectVisit from './components/SelectVisit';
const isFirstScreen = (tab) => {
    return tab === TABS.ADD_FLEET_CUSTOMER;
};
const isTimeSelectionScreen = (tab) => {
    return tab === TABS.SELECT_APPOINTMENT;
};
const isGarageScreen = (tab) => {
    return tab === TABS.GARAGE;
};
const helperStyle = {
    '--helper-height': '100%',
};
const heightStyle = {
    '--full-height': '100%',
};
const useAddFleetRODrawer = ({ visit, referenceNum, onClose, cancellationValues, isRescheduleCancelled, isRescheduleConfirmed, isSchedule, previousVanId, previousDate, isPartOrdered, isFollowUp, open, }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const submitQuickRO = useSubmitQuickRO({
        referenceNum,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSelectBtnDisabled, setIsSelectBtnDisabled] = useState(false);
    const [fleetSuggestions, setFleetSuggestions] = useState();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState('');
    const [vinExists, setVinExists] = useState(false);
    const tab = useSelector(selectTab);
    const nextTab = useSelector(selectNextTab);
    const fleet = useSelector(selectFleet);
    const continueDisabled = useSelector(selectContinueDisabled);
    const isBackNavDisabled = useSelector(selectIsBackNavDisabled);
    const customAddress = useSelector(selectCustomAddress);
    const vehicle = useSelector(selectVehicle);
    const customVehicle = useSelector(selectCustomVehicle);
    const repairs = useSelector(selectRepairs);
    const selectedVisit = useSelector(selectVisit);
    const timeSlot = useSelector(selectTimeSlot);
    useEffect(() => {
        if (!visit || !open)
            return;
        onVisitOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visit === null || visit === void 0 ? void 0 : visit.id, open]);
    useEffect(() => {
        var _a, _b, _c;
        if (!(tab === TABS.ADD_FLEET_CUSTOMER))
            return;
        if (!fleet)
            return;
        if (!((_c = (_b = (_a = fleet.consumer) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.street) === null || _c === void 0 ? void 0 : _c.length) && !customAddress) {
            toast.error('Select an address to continue');
        }
    }, [tab, fleet, customAddress]);
    const contextValue = {
        isDisabled: continueDisabled,
        isSelectBtnDisabled,
        setIsSelectBtnDisabled,
    };
    const isReschedule = isRescheduleCancelled || isRescheduleConfirmed;
    const onSearchFleets = (name) => {
        var _a;
        if ((_a = visit === null || visit === void 0 ? void 0 : visit.fleets) === null || _a === void 0 ? void 0 : _a.length) {
            const filteredFleets = visit.fleets
                .filter((f) => !name.length ||
                f.fleetName.toLowerCase().includes(name.toLowerCase()))
                .map((f) => ({
                id: f.fleetId,
                name: f.fleetName,
                label: f.fleetName,
            }));
            setFleetSuggestions(filteredFleets);
            return;
        }
        return dispatch(searchFleetsByName({ name }));
    };
    const closeDrawerHandler = () => {
        dispatch(setFleet(null));
        dispatch(setCustomFleet({
            name: '',
            phone: '',
            contactName: '',
            email: '',
            parentId: '',
            consumerId: '',
            mainContactName: '',
            type: '',
        }));
        dispatch(setCustomConsumerAddress(null));
        dispatch(setRepairs([]));
        dispatch(setTab(TABS.ADD_FLEET_CUSTOMER));
        dispatch(ActionCreators.TAB.RESET_ALL());
        onClose();
    };
    const onCreateRequest = (resetParts) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e;
        dispatch(setContinueDisabled(true));
        setIsLoading(true);
        const result = yield submitQuickRO({
            appointmentResolutionId: cancellationValues === null || cancellationValues === void 0 ? void 0 : cancellationValues.resolutionId,
            isFleet: true,
            isReschedule: isRescheduleCancelled || isRescheduleConfirmed,
            isSchedule,
            otherResolution: cancellationValues === null || cancellationValues === void 0 ? void 0 : cancellationValues.description,
            resetParts,
            visitRefNum: visit === null || visit === void 0 ? void 0 : visit.refNum,
        });
        if (result === null || result === void 0 ? void 0 : result.error) {
            setIsLoading(false);
            dispatch(setContinueDisabled(false));
            return;
        }
        if (((_a = result === null || result === void 0 ? void 0 : result.payload) === null || _a === void 0 ? void 0 : _a.addedToVisit) !== undefined &&
            !((_b = result === null || result === void 0 ? void 0 : result.payload) === null || _b === void 0 ? void 0 : _b.addedToVisit)) {
            const url_redirect = `/admin/repairs/${(_c = result === null || result === void 0 ? void 0 : result.payload) === null || _c === void 0 ? void 0 : _c.repairRequestRefNum}?skipAddToVisit=${(_d = result === null || result === void 0 ? void 0 : result.payload) === null || _d === void 0 ? void 0 : _d.visitWorkOrderErrorType}`;
            window.open(url_redirect);
            closeDrawerHandler();
        }
        if (visit) {
            setIsLoading(false);
            closeDrawerHandler();
        }
        else if (isRescheduleCancelled || isRescheduleConfirmed || isSchedule) {
            toast.success('We have sent a notification with the appointment details to the customer');
            setTimeout(() => {
                setIsLoading(false);
                closeDrawerHandler();
                location.reload();
            }, 8500);
        }
        else {
            closeDrawerHandler();
            history.push(`/admin/repairs/${(_e = result === null || result === void 0 ? void 0 : result.payload) === null || _e === void 0 ? void 0 : _e.referenceNum}`);
        }
    });
    const handleCreateRequest = (state) => () => onCreateRequest(state);
    const updateUserAddress = (consumerId, address) => {
        const streetLine1 = address.street;
        const coordinates = {
            latitude: address.latitude,
            longitude: address.longitude,
        };
        return dispatch(updateConsumerAddress({
            consumerId,
            address: Object.assign(Object.assign({}, address), { active: true, coordinates,
                streetLine1 }),
        })).then((res) => {
            const newAddress = res.payload;
            if (newAddress) {
                dispatch(setFleet(Object.assign(Object.assign({}, fleet), { consumer: Object.assign(Object.assign({}, fleet.consumer), { address: newAddress }) })));
            }
        });
    };
    const onSelectFleet = () => {
        dispatch(setTab(TABS.GARAGE));
    };
    const onSelectCustomAddress = () => {
        updateUserAddress(fleet.consumerId, customAddress);
        dispatch(setTab(TABS.ADD_FLEET_CUSTOMER));
    };
    const addNewVehicle = () => {
        dispatch(addCarToFleet({
            fleetId: fleet.id,
            car: Object.assign(Object.assign({}, customVehicle), { fleetFmcId: (customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.fleetFmcId) === 'None'
                    ? null
                    : customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.fleetFmcId, fleetResponsibleParties: customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.fleetResponsibleParties }),
        })).then((res) => {
            dispatch(setVehicle(res.payload));
            dispatch(setTab(TABS.ADD_SERVICES));
        });
    };
    const addNewVehicleHandler = () => {
        var _a;
        const parsedMileage = parseStringNumber((_a = customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.mileage) !== null && _a !== void 0 ? _a : 0);
        if (!parsedMileage || parsedMileage === 0) {
            setConfirmationModalOpen(false);
        }
        else {
            if (parsedMileage <= 10) {
                setConfirmationModalOpen(true);
                setConfirmationModalText('You’ve entered in a low mileage, are you sure?');
                return;
            }
            if (parsedMileage >= 300000) {
                setConfirmationModalOpen(true);
                setConfirmationModalText('You’ve entered in a high mileage, are you sure?');
                return;
            }
        }
        addNewVehicle();
    };
    const onAddNewVehicle = () => {
        addNewVehicleHandler();
    };
    const getOnClickNext = () => {
        if (nextTab) {
            return () => {
                if (tab === TABS.SEARCH_ADDRESS) {
                    updateUserAddress(fleet.consumerId, customAddress);
                }
                dispatch(setNextTab(undefined));
                dispatch(setTab(nextTab));
            };
        }
        switch (tab) {
            case TABS.SEARCH_ADDRESS:
                return onSelectCustomAddress;
            case TABS.ADD_FLEET_CUSTOMER:
                return onSelectFleet;
            case TABS.ADD_NEW_VEHICLE:
                return onAddNewVehicle;
            case TABS.ADD_SERVICES:
                return handleCreateRequest(false);
            case TABS.SELECT_APPOINTMENT:
                return determineResetParts;
            case TABS.SELECT_VISIT:
                return onAddROByFleet;
            default:
                return () => {
                    console.error('AddFleetRO Tab Unexpected Case');
                };
        }
    };
    const onVisitOpen = () => __awaiter(void 0, void 0, void 0, function* () {
        var _f, _g;
        if (!visit)
            return;
        // VISIT WITH A SINGLE FLEET AND WE CAN SET THIS DIRECTLY
        if (visit.fleets.length === 1) {
            dispatch(setFleet({ id: visit.fleets[0].fleetId }));
            // CONTINUE TO THE NEXT STEP IF THERE IS A VISIT
            getOnClickNext()();
            const fleetResponse = yield dispatch(getFleet(visit.fleets[0].fleetId));
            dispatch(setFleet(fleetResponse.payload));
        }
        // MORE THAN 1 FLEET WITH NO STATION
        // VISIT WITH A SINGLE FLEET SETTING SEARCH RESULTS
        if (((_g = (_f = visit === null || visit === void 0 ? void 0 : visit.fleets) === null || _f === void 0 ? void 0 : _f.length) !== null && _g !== void 0 ? _g : 0) > 1) {
            setFleetSuggestions(visit === null || visit === void 0 ? void 0 : visit.fleets.map((f) => ({
                id: f.fleetId,
                name: f.fleetName,
                label: f.fleetName,
            })));
        }
    });
    const determineResetParts = () => {
        var _a;
        if (!timeSlot) {
            return onCreateRequest(false);
        }
        const newVanId = timeSlot.slot.vanId || ((_a = timeSlot.slot.van) === null || _a === void 0 ? void 0 : _a.skeduloResourceId);
        const isSameVan = newVanId === previousVanId;
        const isSameDate = previousDate === timeSlot.date;
        const resetParts = (!isSameVan && isPartOrdered && isReschedule) ||
            (isPartOrdered && !isSameDate && isReschedule);
        if (resetParts) {
            return dispatch(setTab(TABS.RESET_PARTS));
        }
        return onCreateRequest(false);
    };
    const getIsContinueDisabled = () => {
        var _a, _b, _c, _d;
        if (continueDisabled || isLoading) {
            return true;
        }
        if (tab === TABS.SEARCH_ADDRESS) {
            return !customAddress;
        }
        if (tab === TABS.ADD_FLEET_CUSTOMER) {
            return (!(fleet && ((_c = (_b = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.consumer) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.street) === null || _c === void 0 ? void 0 : _c.length)) && !customAddress);
        }
        if (tab === TABS.GARAGE) {
            return true;
        }
        if (tab === TABS.ADD_NEW_VEHICLE) {
            const check = !(((_d = customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.vin) === null || _d === void 0 ? void 0 : _d.length) === 17 &&
                (customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.year) &&
                (customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.makeId) &&
                (customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.modelId) &&
                (customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.engineId) &&
                (customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.trimId) &&
                !!customVehicle.plateNumber === !!customVehicle.plateNumberState);
            return vinExists || check;
        }
        if (tab === TABS.ADD_SERVICES) {
            return (isEmpty(repairs) ||
                (isNil(vehicle === null || vehicle === void 0 ? void 0 : vehicle.mileage) && isNil(vehicle === null || vehicle === void 0 ? void 0 : vehicle.customMileage)));
        }
        if (tab === TABS.SELECT_APPOINTMENT) {
            return !isFollowUp;
        }
        return false;
    };
    const onAddROByFleet = () => __awaiter(void 0, void 0, void 0, function* () {
        var _h;
        if (!selectedVisit)
            return;
        setContinueDisabled(true);
        setIsLoading(true);
        const result = yield submitQuickRO({
            appointmentResolutionId: cancellationValues === null || cancellationValues === void 0 ? void 0 : cancellationValues.resolutionId,
            isFleet: true,
            isReschedule: isRescheduleCancelled || isRescheduleConfirmed,
            isSchedule,
            otherResolution: cancellationValues === null || cancellationValues === void 0 ? void 0 : cancellationValues.description,
            resetParts: false,
            visitRefNum: selectedVisit.refNum,
        });
        if (result === null || result === void 0 ? void 0 : result.error) {
            setIsLoading(false);
            setContinueDisabled(false);
            return;
        }
        setIsLoading(false);
        closeDrawerHandler();
        if (isSchedule || isReschedule) {
            dispatch(loadRequest(referenceNum));
            dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
            return;
        }
        closeDrawerHandler();
        window.location.href = `/admin/repairs/${(_h = result === null || result === void 0 ? void 0 : result.payload) === null || _h === void 0 ? void 0 : _h.repairRequestRefNum}`;
    });
    const onSelectedFleet = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _j, _k;
        // GET THE FULL FLEET DATA WHEN NEEDED
        if (!((_j = data.fleet) === null || _j === void 0 ? void 0 : _j.created)) {
            const fleetDataResponse = yield dispatch(getFleet(data.id));
            if (!fleetDataResponse.error) {
                dispatch(setFleet(fleetDataResponse.payload));
            }
        }
        else {
            dispatch(setFleet((_k = data.fleet) !== null && _k !== void 0 ? _k : data));
        }
        // CONTINUE TO THE NEXT STEP IF THERE IS A VISIT
        if (visit) {
            getOnClickNext()();
        }
    });
    const navigateBackHandler = () => {
        var _a, _b;
        if (isFirstScreen(tab) ||
            isTimeSelectionScreen(tab) ||
            isBackNavDisabled ||
            ((visit === null || visit === void 0 ? void 0 : visit.fleets.length) === 1 && isGarageScreen(tab))) {
            closeDrawerHandler();
            return;
        }
        const tabIndex = routes.findIndex((route) => tab === route.name);
        if (isFirstScreen((_a = routes[tabIndex - 1]) === null || _a === void 0 ? void 0 : _a.name)) {
            dispatch(setTab(TABS.ADD_FLEET_CUSTOMER));
        }
        else if (tab === TABS.ADD_SERVICES) {
            dispatch(setTab(TABS.GARAGE));
        }
        else if (isGarageScreen(tab)) {
            dispatch(setTab(TABS.ADD_FLEET_CUSTOMER));
        }
        else {
            dispatch(setTab((_b = routes[tabIndex - 1]) === null || _b === void 0 ? void 0 : _b.name));
        }
    };
    const handleAddressSelect = (value) => {
        if (value === null && !(customAddress === null || customAddress === void 0 ? void 0 : customAddress.addressStr))
            return;
        dispatch(setCustomConsumerAddress(value));
    };
    const handleSelectChange = () => {
        dispatch(setFleet(null));
    };
    const handleSelectAddressButtonClick = () => {
        dispatch(setTab(TABS.SEARCH_ADDRESS));
    };
    const handleDrawerHeaderChange = (event, tab) => {
        dispatch(setTab(tab));
    };
    const updateConfirmationModalOpen = (state) => () => {
        setConfirmationModalOpen(state);
    };
    const routes = [
        {
            name: TABS.ADD_FLEET_CUSTOMER,
            render: () => (<SelectFleetTab fleet={fleet} fleetSuggestions={fleetSuggestions} onSearchFleets={onSearchFleets} onSelectedFleet={onSelectedFleet} handleSelectChange={handleSelectChange} handleSelectAddressButtonClick={handleSelectAddressButtonClick}/>),
        },
        {
            name: TABS.SEARCH_ADDRESS,
            render: () => (<SearchAddress customAddress={customAddress} handleAddressSelect={handleAddressSelect}/>),
        },
        {
            name: TABS.GARAGE,
            render: () => <GarageWrapper heightStyle={heightStyle} fleet={fleet}/>,
            style: helperStyle,
        },
        {
            name: TABS.ADD_NEW_VEHICLE,
            render: () => <AddNewVehicle setVinExists={setVinExists}/>,
        },
        { name: TABS.ADD_SERVICES, render: () => <AddServices visit={visit}/> },
        {
            name: TABS.SELECT_APPOINTMENT,
            render: () => (<SelectAppointment contextValue={contextValue} isLoading={isLoading}/>),
        },
        {
            name: TABS.RESET_PARTS,
            render: () => (<ResetPartsWrapper isLoading={isLoading} handleCreateRequest={handleCreateRequest}/>),
        },
        {
            name: TABS.SELECT_VISIT,
            render: () => (<SelectVisit contextValue={contextValue} isLoading={isLoading}/>),
        },
    ];
    return {
        isLoading,
        setIsLoading,
        isSelectBtnDisabled,
        setIsSelectBtnDisabled,
        fleetSuggestions,
        setFleetSuggestions,
        confirmationModalOpen,
        setConfirmationModalOpen,
        confirmationModalText,
        setConfirmationModalText,
        vinExists,
        setVinExists,
        tab,
        nextTab,
        fleet,
        continueDisabled,
        isBackNavDisabled,
        customAddress,
        vehicle,
        customVehicle,
        repairs,
        selectedVisit,
        timeSlot,
        onSearchFleets,
        onVisitOpen,
        closeDrawerHandler,
        onCreateRequest,
        updateUserAddress,
        onSelectFleet,
        onSelectCustomAddress,
        onAddNewVehicle,
        addNewVehicle,
        addNewVehicleHandler,
        determineResetParts,
        getIsContinueDisabled,
        onAddROByFleet,
        onSelectedFleet,
        getOnClickNext,
        navigateBackHandler,
        routes,
        handleCreateRequest,
        handleSelectAddressButtonClick,
        handleDrawerHeaderChange,
        updateConfirmationModalOpen,
        handleAddressSelect,
        handleSelectChange,
    };
};
export default useAddFleetRODrawer;
