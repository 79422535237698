var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, Typography } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
import { Tooltip } from 'src/components/tooltips';
const FormInput = (_a) => {
    var { label, placeholder = 'type here', valueName = '', tooltip = '', children = null, inputProps, helperText } = _a, otherProps = __rest(_a, ["label", "placeholder", "valueName", "tooltip", "children", "inputProps", "helperText"]);
    return (<Grid item container xs={12}>
    <Grid item xs={6} style={{ position: 'relative', display: 'flex' }}>
      <Tooltip title={tooltip}>
        <Typography variant="body1" style={{ display: 'inline-block', alignSelf: 'flex-end' }}>
          {label}
        </Typography>
      </Tooltip>
    </Grid>
    <Grid item xs={6}>
      {children || (<TextField {...otherProps} id={valueName} name={valueName} placeholder={placeholder} inputProps={inputProps} helperText={helperText}/>)}
    </Grid>
  </Grid>);
};
export default FormInput;
