/* eslint-disable class-methods-use-this */
import React from 'react';
import styled from 'styled-components';
import { PageNumber } from 'src/components/dashboard/SimpleTable/SimpleTable.style';
const Wrapper = styled.div `
  display: flex;
  font-size: 1.3em;
  justify-content: center;
  align-items: center;
`;
const minifyPageSizeNumbers = (_current, _last) => {
    const current = _current;
    const last = _last;
    const delta = 5;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    let l;
    for (let i = 1; i <= last; i += 1) {
        if (i === 1 || i === last || (i >= left && i < right)) {
            range.push(i);
        }
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            }
            else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }
    return rangeWithDots;
};
const SimplePaginationControls = ({ count, page, rowsPerPage, onPageSelect, }) => {
    const pages = Math.ceil(count / rowsPerPage);
    const minPageNumbers = minifyPageSizeNumbers(page, pages);
    const adjustedPage = page + 1;
    const Numbers = minPageNumbers.map((item, index) => (<PageNumber key={+item + index} active={item === adjustedPage} onClick={() => onPageSelect(undefined, +item - 1)}>
      {item}
    </PageNumber>));
    return <Wrapper>{Numbers}</Wrapper>;
};
export default SimplePaginationControls;
