import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled, { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from 'src/styles/theme';
import useIsMobile from 'src/hooks/useIsMobile';
import GlobalStyle from './styles/global';
import useDatadogRum from './hooks/useDatadogRum';
import AdminApp from './AdminApp/AdminAppLayout';
import SystemApp from './SystemApp';
import PopsApp from './PopsApp';
import PricingApp from './PricingApp';
import TechApp from './TechApp';
import PartnersApp from './PartnersApp';
import MyProfileApp from './MyProfileApp';
import ScanPocApp from './ScanPocApp';
import PageTitle from './containers/layout/PageTitle';
import ProtectedRoute from './containers/utils/ProtectedRoute';
import * as pages from './pages';
import AppBar from './containers/layout/AppBar/AppBar';
const Wrapper = styled.div `
  display: flex;
  font-family: 'Interstate', sans-serif;
`;
const Main = styled.main `
  height: 100vh;
  flex-grow: 1;
`;
const StyledToastContainer = styled(ToastContainer) `
  white-space: pre-line;
`;
const App = () => {
    useDatadogRum();
    const isMobile = useIsMobile();
    const location = useLocation();
    const isInHideList = (location) => {
        if ([
            '/login',
            '/reset-password',
            '/reset-forgotten-password',
            '/forgot-password',
            '/my-home',
            '/login/mfa',
        ].includes(location.pathname)) {
            return true;
        }
    };
    return (<Wrapper>
      <PageTitle />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <div style={{ display: 'flex', height: '100vh', flexGrow: 1 }}>
          {!isMobile && !isInHideList(location) && (<div>
              <AppBar />
            </div>)}
          <Main>
            <Switch>
              <ProtectedRoute exact path="/" component={pages.Home}/>
              <Route exact path="/reset-password" component={pages.ResetPassword}/>
              <Route exact path="/reset-forgotten-password" component={pages.ResetForgottenPassword}/>
              <ProtectedRoute exact path="/my-home" component={pages.MyHome}/>
              <ProtectedRoute exact path="/my-profile" component={MyProfileApp}/>
              <ProtectedRoute path="/admin" component={AdminApp}/>
              <ProtectedRoute path="/partners" component={PartnersApp}/>
              <ProtectedRoute path="/pops" component={PopsApp}/>
              <ProtectedRoute path="/pricing" component={PricingApp}/>
              <ProtectedRoute path="/system" component={SystemApp}/>
              <ProtectedRoute path="/tech" component={TechApp}/>
              <ProtectedRoute path="/scan-poc" component={ScanPocApp}/>
              <Route exact path="/login/mfa" component={pages.MfaAuth}/>
              <Route exact path="/login" component={pages.Login}/>
              <Route exact path="/logout" component={pages.Logout}/>
              <Route component={pages.NotFound}/>
            </Switch>
            <StyledToastContainer />
          </Main>
        </div>
      </ThemeProvider>
    </Wrapper>);
};
export default App;
