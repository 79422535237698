import React from 'react';
import { find } from 'lodash';
import { TableHead, TableRow, TableCell } from 'src/components/mui';
import SimpleTableHeaderCell from 'src/components/dashboard/SimpleTable/SimpleTableHeaderCell';
import { Checkbox } from 'src/components/SimpleCheckbox';
const SimpleTableHeader = ({ columns, sort, onSort, }) => {
    const columnCells = columns.map((column) => {
        var _a;
        const sortInfo = find(sort, (item) => item.split(',')[0] === column.key);
        const [sortBy, sortDir] = (_a = sortInfo === null || sortInfo === void 0 ? void 0 : sortInfo.split(',')) !== null && _a !== void 0 ? _a : [
            undefined,
            undefined,
        ];
        if (column.type === 'checkbox') {
            if (!column.checked || !column.action) {
                throw new Error('checkbox type must have a checked and action props');
            }
            return (<TableCell>
          <Checkbox key={column.key} checked={typeof column.checked === 'function'
                    ? column.checked()
                    : column.checked} disabled={column.checkDisabled} onClick={column.action}/>
        </TableCell>);
        }
        return (<SimpleTableHeaderCell column={column} sortBy={sortBy} sortDir={sortDir} onSort={onSort} key={column.key} textAlign={column.textAlign}/>);
    });
    return (<TableHead>
      <TableRow>{columnCells}</TableRow>
    </TableHead>);
};
export default SimpleTableHeader;
