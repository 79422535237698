var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Grid, NativeSelect } from 'src/components/mui';
import { loadAuditEntity } from 'src/SystemApp/modules/utilities/actions';
import { DisplayMode } from './models';
import EntityLoader from './EntityLoader';
import ChangeLog from './ChangeLog';
import Snapshots from './Snapshots';
const EntityExplorer = (props) => {
    var _a;
    const [state, setState] = useState({
        schema: '',
        table: '',
        id: '',
        page: 0,
        mode: DisplayMode.PRETTY,
        timestamp: undefined,
        data: undefined,
    });
    const dispatch = useDispatch();
    const load = ({ schema, table, id, page, }) => __awaiter(void 0, void 0, void 0, function* () {
        const targetPage = page !== undefined ? page : state.page;
        const response = yield dispatch(loadAuditEntity({
            schema,
            table,
            id,
            page: targetPage,
        }));
        setState(Object.assign(Object.assign({}, state), { schema: schema || '', table: table || '', id: id || '', page: page || 0, timestamp: Date.now(), data: response.payload[0] }));
    });
    const onChangeMode = (e) => {
        setState(Object.assign(Object.assign({}, state), { mode: e.target.value }));
    };
    const setPage = (page) => {
        const { schema, table, id } = state;
        load({ schema, table, id, page });
    };
    useEffect(() => {
        var _a;
        const params = (_a = props.match) === null || _a === void 0 ? void 0 : _a.params;
        if (params.schema && params.table && params.id) {
            load(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        var _a;
        const params = (_a = props.match) === null || _a === void 0 ? void 0 : _a.params;
        load(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_a = props.match) === null || _a === void 0 ? void 0 : _a.params]);
    const { schema, table, id, data, page, mode, timestamp } = state;
    return (<>
      <EntityLoader timestamp={timestamp} load={load}/>
      <Divider style={{ margin: '10px 0' }}/>
      {schema && table && id && (<>
          <Grid container style={{ margin: '10px 0' }}>
            <Grid item md={12}>
              <NativeSelect name="mode" onChange={onChangeMode}>
                {Object.values(DisplayMode).map((dm) => (<option key={dm}>{dm}</option>))}
              </NativeSelect>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item md={7}>
              <Snapshots data={data} schema={schema} page={page} setPage={setPage} mode={mode}/>
            </Grid>
            <Grid item md={5}>
              <ChangeLog data={data} schema={schema} mode={mode}/>
            </Grid>
          </Grid>
        </>)}
    </>);
};
export default EntityExplorer;
