import React, { useState, useEffect } from 'react';
const ServiceModule = ({ host, service, endpoint, timestamp, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const fetchData = () => {
        setIsLoading(true);
        fetch(`${host}/${service}/actuator/${endpoint}`)
            .then((response) => response.json())
            .then((data) => {
            setIsLoading(false);
            setData(data);
        })
            .catch((error) => {
            setIsLoading(false);
            setData({ error: error.toString() });
        });
    };
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [host, service, endpoint, timestamp]);
    return (<div>
      <div style={{ fontWeight: 'bold' }}>
        {service}
        {isLoading ? ' (loading...)' : ''}
      </div>
      <pre>{data && JSON.stringify(data, undefined, 2)}</pre>
    </div>);
};
export default ServiceModule;
