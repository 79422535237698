import { toEnum } from 'src/models/enums';
import { toStatusWithResolutionEnum } from './StatusWithResolutionEnum';
export const RepairOrderStatus = toStatusWithResolutionEnum({
    PENDING: 'Pending',
    IN_PROGRESS: 'In Progress',
    CLOSED: 'Closed',
}, ['CLOSED']);
export const RepairOrderResolution = toEnum({
    CUSTOMER_NO_SHOW: 'Customer No Show',
    CUSTOMER_DECLINED_REPAIR: 'Customer Declined Repair',
    CUSTOMER_RESCHEDULE: 'Customer Reschedule',
    SHOP_DECLINED_REPAIR: 'Shop Declined Repair',
    COMPLETION_UNKNOWN: 'Completion Unknown',
    REPAIR_COMPLETED_NO_INVOICE: 'Repair Completed - No Invoice',
    REPAIR_COMPLETED_WITH_INVOICE: 'Repair Completed - With Invoice',
    UNKNOWN: 'Unknown',
    OTHER: 'Other',
});
export default RepairOrderStatus;
