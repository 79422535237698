var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { isNil } from 'lodash';
import { Button, Card, CardContent, CircularProgress, Grid, } from 'src/components/mui';
import { formatCurrency } from 'src/utils/formatter';
import { SaleMatchFilterType } from 'src/AdminApp/models/enums/SaleMatchFilterType';
import { getSalesMatch, deleteSalesMatch, verifySalesMatchInvoice, getSalesMatchSummaryInfo, exportSalesMatchCsv, } from 'src/AdminApp/modules/salesMatch/actions';
import { selectDashboardPagination, selectDashboardSalesMatch, selectDashboardParams, selectDashboardIsFetching, selectDashboardSummaryInfo, selectDashboardIsDownloading, } from 'src/AdminApp/modules/salesMatch/selectors';
import { selectAuthorizedSalesMatchView } from 'src/modules/auth/selectors';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { ConfirmationModal } from 'src/components/layout';
import { useLocation } from 'react-router-dom';
import DashboardListItem from './DashboardListItem';
import DashboardDateFilters from './DashboardDateFilters';
import DashboardFiltersPanel from './DashboardFiltersPanel';
import DashboardFilters from './DashboardFilters';
const REQUEST_COLUMNS = [
    { title: 'Shop Name', key: 'shopName', sortable: true },
    { title: '%', key: 'percentageMatch', sortable: true },
    { title: 'Invoice #', key: 'invoiceNumber', sortable: true },
    { title: 'Date ', key: 'dateRepairCompleted', sortable: true },
    { title: 'Customer', key: 'nameOfCustomer', sortable: true },
    { title: 'Vehicle', key: 'vehicleYearMakeModel', sortable: true },
    { title: 'Subtotal', key: 'subtotal', sortable: true },
    { title: 'Tax', key: 'salesTax', sortable: true },
    { title: 'Hazmat', key: 'hazmatExpense', sortable: true },
    { title: 'Tires', key: 'saleOfTires', sortable: true },
    { title: 'Total', key: 'totalAmount', sortable: true },
    { title: 'Match Status', key: 'validationStatus', sortable: true },
    { title: 'RepReq Status', key: 'repairRequestStatus', sortable: true },
    { title: 'RepReq State', key: 'repairRequestState', sortable: true },
    { title: 'Repair Request', key: 'repairRequestId', sortable: true },
    { title: 'Actions', key: 'other', sortable: false },
];
const SalesMatchDashboard = ({ filterType }) => {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [deleteConfirmationModalAction, setDeleteConfirmationModalAction] = useState();
    const [verifyInvoiceConfirmationModalAction, setVerifyInvoiceConfirmationModalAction,] = useState();
    const salesMatch = useSelector(selectDashboardSalesMatch);
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    const summaryInfo = useSelector(selectDashboardSummaryInfo);
    const canView = useSelector(selectAuthorizedSalesMatchView);
    const _isFetching = useSelector(selectDashboardIsFetching);
    const _isDownloading = useSelector(selectDashboardIsDownloading);
    useEffect(() => {
        var _a;
        const stateFilters = params.filters;
        const queryParams = getUrlQueryParams();
        const filters = Object.assign(Object.assign({}, getInitialFilters()), queryParams);
        const paramsFilters = (_a = params.filters) !== null && _a !== void 0 ? _a : {};
        if (stateFilters.start !== filters.start ||
            stateFilters.end !== filters.end ||
            paramsFilters.dateFilterType !== filters.dateFilterType ||
            paramsFilters.showFilterType !== filters.showFilterType ||
            paramsFilters.shopId !== filters.shopId ||
            paramsFilters.repairRequestStatus !== filters.repairRequestStatus ||
            paramsFilters.repairRequestState !== filters.repairRequestState) {
            handleGetSalesMatch(Object.assign(Object.assign({}, params), { pageSize: pagination === null || pagination === void 0 ? void 0 : pagination.pageSize, pageIndex: pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber, sort: pagination === null || pagination === void 0 ? void 0 : pagination.sort, filters: Object.assign(Object.assign({}, paramsFilters), filters) }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleGetSalesMatch = (params) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield dispatch(getSalesMatch(params));
        }
        catch (err) {
            toast.error('Sales matches were not loaded');
        }
    });
    const handleGetSalesMatchSummaryInfo = () => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(getSalesMatchSummaryInfo({ filters: params.filters }));
    });
    const onLoadData = ({ page, size, sort }) => __awaiter(void 0, void 0, void 0, function* () {
        yield handleGetSalesMatch(Object.assign(Object.assign({}, params), { pageSize: size, pageIndex: page, sort: sort || null }));
    });
    const onSearch = (filters) => setUrlQueryParams(filters);
    const onFilterByShopAndStatusAndState = (filters) => __awaiter(void 0, void 0, void 0, function* () {
        handleGetSalesMatch(Object.assign(Object.assign({}, params), { filters: Object.assign(Object.assign({}, params.filters), filters), pageSize: pagination.pageSize, pageIndex: 0, sort: pagination.sort }));
        setUrlQueryParams(params.filters);
    });
    const setUrlQueryParams = (filters) => {
        const filter = Object.assign(Object.assign({}, params.filters), filters);
        delete filter.expanded;
        const queryParamsString = queryString.stringify(filter);
        history.push(`${location.pathname}?${queryParamsString}`);
    };
    const getUrlQueryParams = () => {
        const queryParams = queryString.parse(location.search);
        const result = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (queryParams.start && { start: queryParams.start })), (queryParams.end && { end: queryParams.end })), (queryParams.shopId && { shopId: queryParams.shopId })), (queryParams.repairRequestStatus && {
            repairRequestStatus: queryParams.repairRequestStatus,
        })), (queryParams.repairRequestState && {
            repairRequestState: queryParams.repairRequestState,
        })), (!isNil(queryParams.dateFilterType) && {
            dateFilterType: Number(queryParams.dateFilterType),
        })), (!isNil(queryParams.showFilterType) && {
            showFilterType: Number(queryParams.showFilterType),
        }));
        return result;
    };
    const getInitialFilters = () => {
        const now = new Date();
        let start;
        let end;
        switch (filterType) {
            case SaleMatchFilterType.Weekly:
                start = moment(now).startOf('week');
                end = moment(now).endOf('week');
                break;
            case SaleMatchFilterType.Monthly:
                start = moment(now).startOf('month');
                end = moment(now).endOf('month');
                break;
            default:
                start = moment(now);
                end = moment(now);
        }
        return Object.assign(Object.assign({}, params.filters), { start, end });
    };
    const getDateRangeString = () => {
        var _a, _b, _c, _d;
        const start = (_b = (_a = params === null || params === void 0 ? void 0 : params.filters) === null || _a === void 0 ? void 0 : _a.start) !== null && _b !== void 0 ? _b : null;
        const end = (_d = (_c = params === null || params === void 0 ? void 0 : params.filters) === null || _c === void 0 ? void 0 : _c.end) !== null && _d !== void 0 ? _d : null;
        if (start && end) {
            return `${start}-${end}`;
        }
    };
    const getCsvName = () => {
        const dateRange = getDateRangeString();
        let type = '';
        switch (filterType) {
            case SaleMatchFilterType.Daily:
            case SaleMatchFilterType.Weekly:
            case SaleMatchFilterType.Monthly:
                type = filterType.toString();
                break;
            default:
        }
        return `sales_match_${type}_${dateRange}`;
    };
    const handleExportSalesMatchCsv = () => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(exportSalesMatchCsv({ filters: params.filters, sort: '' }, getCsvName()));
    });
    const handleVerifySalesMatchInvoice = (salesMatchId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield dispatch(verifySalesMatchInvoice(salesMatchId));
            yield handleGetSalesMatchSummaryInfo();
            toast.success('invoice successfully marked as valid');
        }
        catch (err) {
            toast.error("invoice wasn't marked as valid");
        }
    });
    const handleDeleteSalesMatch = (salesMatchId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield dispatch(deleteSalesMatch(salesMatchId));
            yield handleGetSalesMatchSummaryInfo();
            toast.success('Sales match is successfully removed');
        }
        catch (err) {
            toast.error("Sales match wasn't removed");
        }
    });
    const openDeleteConfirmationModal = (salesMatchId) => setDeleteConfirmationModalAction(() => handleDeleteSalesMatch(salesMatchId));
    const onDeleteConfirmationModalClose = () => setDeleteConfirmationModalAction(undefined);
    const openVerifyInvoiceConfirmationModal = (salesMatchId) => setVerifyInvoiceConfirmationModalAction(() => handleVerifySalesMatchInvoice(salesMatchId));
    const onVerifyInvoiceConfirmationModalClose = () => setVerifyInvoiceConfirmationModalAction(undefined);
    const renderSummaryContainer = (summaryInfo) => {
        const { totalShops, discovered, revenue } = summaryInfo;
        return (<Grid container style={{ justifyContent: 'center' }} spacing={5}>
        <Grid item xs={4} sm={3}>
          {renderSummaryCard('Discovered', discovered, _isFetching !== null && _isFetching !== void 0 ? _isFetching : false)}
        </Grid>
        <Grid item xs={4} sm={3}>
          {renderSummaryCard('Shops', totalShops, _isFetching !== null && _isFetching !== void 0 ? _isFetching : false)}
        </Grid>
        <Grid item xs={4} sm={3}>
          {renderSummaryCard('Revenue', formatCurrency(revenue), _isFetching !== null && _isFetching !== void 0 ? _isFetching : false)}
        </Grid>
      </Grid>);
    };
    // eslint-disable-next-line class-methods-use-this
    const renderSummaryCard = (title, value, isFetching) => {
        return (<Card>
        <CardContent>
          <Grid container wrap="nowrap" style={{ justifyContent: 'center' }} spacing={2}>
            <h1>{isFetching ? <CircularProgress /> : value}</h1>
          </Grid>
          <Grid container wrap="nowrap" style={{ justifyContent: 'center' }} spacing={2}>
            <span style={{
                textTransform: 'uppercase',
                lineHeight: '0.9',
                fontSize: 20,
            }}>
              {title}
            </span>
          </Grid>
        </CardContent>
      </Card>);
    };
    const isExportDisabled = !(salesMatch && salesMatch.length);
    const shopId = (_a = params === null || params === void 0 ? void 0 : params.filters) === null || _a === void 0 ? void 0 : _a.shopId;
    const repairRequestStatus = (_b = params === null || params === void 0 ? void 0 : params.filters) === null || _b === void 0 ? void 0 : _b.repairRequestStatus;
    const repairRequestState = (_c = params === null || params === void 0 ? void 0 : params.filters) === null || _c === void 0 ? void 0 : _c.repairRequestState;
    return canView ? (<>
      <Grid container style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }} spacing={2}>
        <Grid item xs={12} md={4}>
          <DashboardDateFilters filters={params.filters} onSearch={onSearch} filterType={filterType}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardFiltersPanel onSearch={onSearch}/>
        </Grid>
      </Grid>
      {renderSummaryContainer(summaryInfo)}
      <p />
      <Grid container direction="row">
        <Grid container item style={{ alignItems: 'center', justifyContent: 'flex-start' }} xs={9}>
          <DashboardFilters shops={(summaryInfo.shops || []).map((x) => {
            var _a;
            return ({
                id: x.id,
                name: (_a = x.name) !== null && _a !== void 0 ? _a : '',
            });
        })} selectedShopId={shopId} repairRequestStatuses={(summaryInfo.repairRequestStatuses || []).map((x) => ({ id: x, name: x }))} selectedRepairRequestStatus={repairRequestStatus} repairRequestStates={(summaryInfo.repairRequestStates || []).map((x) => ({ id: x, name: x }))} selectedRepairRequestState={repairRequestState} onSearch={onFilterByShopAndStatusAndState}/>
        </Grid>
        <Grid container item style={{ alignItems: 'center' }} xs={1}>
          {_isDownloading && (<h4>
              Downloading... <CircularProgress size="1rem"/>
            </h4>)}
        </Grid>
        <Grid container item style={{ alignItems: 'center', justifyContent: 'flex-end' }} xs={2}>
          {!isExportDisabled && (<Button size="small" variant="contained" onClick={handleExportSalesMatchCsv} color="primary" disabled={isExportDisabled}>
              Export CSV
            </Button>)}
        </Grid>
      </Grid>
      <Grid container direction="row"/>
      <p />
      {_isFetching ? (<CircularProgress />) : (<SimpleTable data={salesMatch} pagination={pagination} sort={(_d = pagination.sort) !== null && _d !== void 0 ? _d : []} columns={REQUEST_COLUMNS} isFetching={_isFetching} renderItem={(item, index) => (<DashboardListItem {...item} deleteAction={() => openDeleteConfirmationModal(item.id)} verifyInvoiceAction={() => openVerifyInvoiceConfirmationModal(item.id)} key={`${index}-${item.id}`}/>)} onLoadData={onLoadData}/>)}

      <ConfirmationModal open={!!deleteConfirmationModalAction} text="Are you sure you want to mark this sale match as invalid?" onClose={onDeleteConfirmationModalClose} action={deleteConfirmationModalAction}/>

      <ConfirmationModal open={!!verifyInvoiceConfirmationModalAction} text="Are you sure you want to mark invoice as valid?" onClose={onVerifyInvoiceConfirmationModalClose} action={verifyInvoiceConfirmationModalAction}/>
    </>) : (<div>
      ERROR - Not Authorized: You must have the "Sales_Match_Viewer" role to
      view this page
    </div>);
};
export default SalesMatchDashboard;
