import Cookies from 'js-cookie';
import { handleActions } from 'redux-actions';
import { ActionCreators, setAccessToken } from './actions';
import { parseJwt, getCookieDomainAttrs, INTERNAL_ACCESS_TOKEN, INTERNAL_REFRESH_TOKEN, } from './utils';
const DEFAULT_STATE = {
    accessToken: null,
    refreshToken: null,
    isLoading: null,
    error: null,
    userId: null,
    mfaRequired: null,
    userEmail: null,
    roles: [],
    groups: [],
};
const setAuthCookies = (accessToken, refreshToken, jwt) => {
    const cookieAccessToken = Cookies.get(INTERNAL_ACCESS_TOKEN);
    const cookieRefreshToken = Cookies.get(INTERNAL_REFRESH_TOKEN);
    const domain = getCookieDomainAttrs();
    const expires = getAuthCookieExpiration(jwt);
    if (accessToken) {
        if (cookieAccessToken)
            Cookies.remove(INTERNAL_ACCESS_TOKEN, Object.assign({}, domain));
        Cookies.set(INTERNAL_ACCESS_TOKEN, accessToken, Object.assign({ expires }, domain));
        if (accessToken.length > 4096) {
            // 4kb
            console.warn(`[auth] accessToken (${accessToken === null || accessToken === void 0 ? void 0 : accessToken.length} bytes) has exceeded max size of 4096 bytes. The ${INTERNAL_ACCESS_TOKEN} cookie may not have been saved successfully.`);
        }
    }
    if (refreshToken) {
        if (cookieRefreshToken)
            Cookies.remove(INTERNAL_REFRESH_TOKEN, Object.assign({}, domain));
        Cookies.set(INTERNAL_REFRESH_TOKEN, refreshToken, Object.assign({ expires }, domain));
    }
};
const getAuthCookieExpiration = (jwt) => {
    if (jwt === null || jwt === void 0 ? void 0 : jwt.exp) {
        return new Date(jwt.exp * 1000);
    }
    const defaultExpiration = new Date();
    defaultExpiration.setDate(defaultExpiration.getDate() + 5);
    return defaultExpiration;
};
const loginSuccessReducer = (state, action) => {
    const { accessToken = null, refreshToken = null, mfaRequired = false, } = action.payload;
    const email = action.meta ? action.meta.email : state.userEmail;
    let roles = null;
    let groups = null;
    let userId = -1;
    let tokens = {};
    if (!mfaRequired) {
        const jwt = accessToken && parseJwt(accessToken);
        if (jwt) {
            roles = jwt['https://servus.com/roles'];
            groups = jwt['https://servus.com/groups'] || [];
            const userIdStringValue = jwt['https://servus.com/systemUserId'] ||
                jwt['https://servus.com/ispOwnerId'];
            userId = parseInt(userIdStringValue, 10);
        }
        setAuthCookies(accessToken, refreshToken, jwt);
        tokens = Object.assign(Object.assign({}, (!!accessToken && { accessToken })), (!!refreshToken && { refreshToken }));
    }
    return Object.assign(Object.assign(Object.assign({}, DEFAULT_STATE), tokens), { roles,
        groups,
        userId, userEmail: mfaRequired ? email : null, mfaRequired });
};
const loginFailureReducer = (state, action) => (Object.assign(Object.assign({}, DEFAULT_STATE), { error: action.payload.error }));
const loginRequestReducer = () => (Object.assign(Object.assign({}, DEFAULT_STATE), { isLoading: true }));
const mfaRequestReducer = (state) => (Object.assign(Object.assign({}, DEFAULT_STATE), { userEmail: state.userEmail, isLoading: true }));
const mfaFailureReducer = (state, action) => (Object.assign(Object.assign({}, DEFAULT_STATE), { userEmail: state.userEmail, error: action.payload.error }));
const userEmail = (state, action) => (Object.assign(Object.assign({}, state), { userEmail: action.payload.email, userId: action.payload.id }));
const reducers = handleActions({
    [ActionCreators.AUTH.SUCCESS.type]: loginSuccessReducer,
    [ActionCreators.AUTH.FAILURE.type]: loginFailureReducer,
    [ActionCreators.AUTH.FETCH.type]: loginRequestReducer,
    [ActionCreators.REFRESH_TOKEN.SUCCESS.type]: loginSuccessReducer,
    [ActionCreators.VERIFY_MFA.FETCH.type]: mfaRequestReducer,
    [ActionCreators.VERIFY_MFA.SUCCESS.type]: loginSuccessReducer,
    [ActionCreators.VERIFY_MFA.FAILURE.type]: mfaFailureReducer,
    [ActionCreators.RESEND_MFA.FAILURE.type]: mfaFailureReducer,
    [ActionCreators.RESEND_MFA.FETCH.type]: mfaRequestReducer,
    [ActionCreators.GET_SELF.SUCCESS.type]: userEmail,
    [setAccessToken.type]: loginSuccessReducer,
}, DEFAULT_STATE);
export default reducers;
