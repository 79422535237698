import styled from 'styled-components';
import { Card, CardContent, Button } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const FleetVehicleCarCard = styled(Card) `
  margin-bottom: 19px;
`;
export const FleetVehicleCarCardContent = styled(CardContent) `
  padding: 0;

  &:last-child {
    padding-bottom: 0;
  }
`;
export const CarMain = styled.section `
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.GRAY_DE};
  padding: 16px;
`;
export const CarMakeLogo = styled.img `
  height: 40px;
  width: 40px;
  margin-right: 18px;
`;
export const CarName = styled.div `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
`;
export const CarDetails = styled.section `
  padding: 16px;
  display: flex;
`;
export const CarDetailsLabel = styled.div `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
`;
export const CarDetailsValue = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.3px;
`;
export const PlateContainer = styled.div `
  margin-left: 16px;
`;
export const ActionsSection = styled.section `
  box-shadow: inset 0 0.5px 0 0 ${colors.GRAY_DE};
  background-color: rgba(118, 118, 118, 0.08);
  text-align: center;
`;
export const SelectCarButton = styled(Button) `
  width: 100%;
  padding: 16px 0;
  color: ${colors.primary};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
`;
