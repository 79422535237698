import { handleActions } from 'redux-actions';
import PartPickupItemStatus from 'src/AdminApp/models/enums/PartPickupItemStatus';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    partPickupOrder: undefined,
    workflow: undefined,
    pickedUpItems: [],
    van: undefined,
};
const getPartPickupOrderSuccessReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { partPickupOrder: action.payload, pickedUpItems: [...getInitialPickedUpItems(action.payload.items)] });
};
const getPartPickupVanSuccessReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { vans: action.payload });
};
const getPartPickupOrderWorkflowSuccessReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { workflow: action.payload });
};
const updatePickedUpQuantity = (state, action) => {
    const currentPickedUpItems = state.pickedUpItems;
    const itemIndex = currentPickedUpItems.findIndex((item) => item.id === action.payload.id);
    if (itemIndex >= 0) {
        currentPickedUpItems[itemIndex].quantity = action.payload.quantity;
    }
    return Object.assign(Object.assign({}, state), { pickedUpItems: [...currentPickedUpItems] });
};
const checkPickedUpItem = (state, action) => {
    const currentPickedUpItems = state.pickedUpItems;
    const itemIndex = currentPickedUpItems.findIndex((item) => item.id === action.payload.id);
    if (itemIndex >= 0) {
        currentPickedUpItems[itemIndex].checked = action.payload.checked;
    }
    return Object.assign(Object.assign({}, state), { pickedUpItems: [...currentPickedUpItems] });
};
const countPendingOrNewPart = (item) => {
    let pendingOrNewPart = 0;
    item.states.forEach((state) => {
        if (state.status === PartPickupItemStatus.NEW_PART ||
            state.status === PartPickupItemStatus.PENDING) {
            pendingOrNewPart += 1;
        }
    });
    return pendingOrNewPart;
};
const getInitialPickedUpItems = (items) => {
    const initialPickedUpItems = [];
    if (items && items.length) {
        items.forEach((item) => {
            initialPickedUpItems.push({
                id: item.id,
                quantity: countPendingOrNewPart(item),
                totalQuantity: countPendingOrNewPart(item),
                checked: false,
            });
        });
    }
    return initialPickedUpItems;
};
const partPickupReducers = handleActions({
    [ActionCreators.GET_PART_PICKUP_ORDER.SUCCESS.type]: getPartPickupOrderSuccessReducer,
    [ActionCreators.GET_PART_PICKUP_VAN.SUCCESS.type]: getPartPickupVanSuccessReducer,
    [ActionCreators.UPDATE_PICKEDUP_QUANTITY.SUCCESS.type]: updatePickedUpQuantity,
    [ActionCreators.CHECK_PICKEDUP_ITEM.SUCCESS.type]: checkPickedUpItem,
    [ActionCreators.GET_PART_PICKUP_TASK_WORKFLOW.SUCCESS.type]: getPartPickupOrderWorkflowSuccessReducer,
}, DEFAULT_STATE);
export default partPickupReducers;
