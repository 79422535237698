var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomConfigs, updateCustomConfig, } from 'src/SystemApp/modules/utilities/actions';
import { selectCustomConfigurations } from 'src/SystemApp/modules/utilities/selectors';
import Configs from './Configs';
import ConfigModal from './ConfigModal';
const CustomConfigs = () => {
    const dispatch = useDispatch();
    const configs = useSelector(selectCustomConfigurations);
    const [currentConfig, setCurrentConfig] = useState();
    const [configModalOpen, setConfigModalOpen] = useState(false);
    const onUpdateConfigSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(updateCustomConfig(Object.assign({}, values)));
        setConfigModalOpen(false);
    });
    const onConfigEdit = (values) => {
        setCurrentConfig(values);
        setConfigModalOpen(true);
    };
    useEffect(() => {
        dispatch(getCustomConfigs());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
      <ConfigModal open={configModalOpen} onClose={() => setConfigModalOpen(false)} onSubmit={onUpdateConfigSubmit} {...currentConfig}/>
      <Configs configs={configs} onEdit={onConfigEdit}/>
    </>);
};
export default CustomConfigs;
