import { Grid } from 'src/components/mui';
import React from 'react';
import { SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
const EditFieldDrawer = ({ open, onClose, onSubmit, title, renderInput, }) => {
    const submitHandler = () => {
        onSubmit();
        onClose();
    };
    return (<SimpleDrawer open={open} onClose={onClose} title={title}>
      <SimpleDrawerContent>
        <Grid container spacing={2}>
          {renderInput()}
        </Grid>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <SimpleTwoButtonRow onCancel={onClose} onSubmit={submitHandler} submitText="Save"/>
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
export default EditFieldDrawer;
