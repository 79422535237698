var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { TableContainer } from 'src/components/dashboard/SimpleTable/SimpleTable.style';
import SimpleTableHeader from 'src/components/dashboard/SimpleTable/SimpleTableHeader';
import SimpleTablePagination from 'src/components/dashboard/SimpleTable/SimpleTablePagination';
import useNotes from '../useNotes';
import NotesTableRow from './NotesTableRow';
const REQUEST_COLUMNS = [
    { title: 'Type', key: 'type', sortable: false },
    { title: 'Applies To', key: 'appliesTo', sortable: false },
    { title: 'Note', key: 'note', sortable: false },
    { title: 'Origin', key: 'origin', sortable: true },
    { title: 'Author', key: 'author', sortable: true },
    { title: 'Date Created', key: 'created', sortable: true },
    { title: 'Visibility', key: 'visibility', sortable: false },
];
const NotesTable = ({ repairRequestId }) => {
    const { notes, onLoadData, pagination, notesAppliesTo, notesAppliesToRecommendedRepairs, } = useNotes(repairRequestId);
    const notesAppliesToCombined = [
        ...notesAppliesTo,
        ...notesAppliesToRecommendedRepairs,
    ];
    const handleSort = (column) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        if ((_a = column === null || column === void 0 ? void 0 : column.sortable) !== null && _a !== void 0 ? _a : true) {
            const [currentSortBy, currentSortDir] = (((pagination === null || pagination === void 0 ? void 0 : pagination.sort) && pagination.sort[0]) ||
                '').split(',');
            const newSortBy = column.key;
            const newSortDir = newSortBy === currentSortBy && currentSortDir === 'desc'
                ? 'asc'
                : 'desc';
            // @TODO: Add support for multisorting
            const newSort = [`${newSortBy},${newSortDir}`];
            yield onLoadData({
                page: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _b !== void 0 ? _b : 0,
                size: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _c !== void 0 ? _c : 10,
                sort: newSort,
            });
        }
    });
    const handleChangePage = (event, newPage) => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        yield onLoadData({
            page: newPage,
            size: (_d = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _d !== void 0 ? _d : 10,
            sort: (_e = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _e !== void 0 ? _e : ['created, desc'],
        });
    });
    const handleChangeRowsPerPage = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _f, _g;
        const newRows = parseInt(event.target.value, 10);
        yield onLoadData({
            page: (_f = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _f !== void 0 ? _f : 0,
            size: newRows,
            sort: (_g = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _g !== void 0 ? _g : ['created, desc'],
        });
    });
    return (<>
      <TableContainer className="scrollable">
        <SimpleTableHeader columns={REQUEST_COLUMNS} sort={pagination === null || pagination === void 0 ? void 0 : pagination.sort} onSort={handleSort}/>
        {notes.map((item) => (<NotesTableRow key={item.id} note={item} notesAppliesTo={notesAppliesToCombined}/>))}
      </TableContainer>
      <SimpleTablePagination pagination={pagination} hidePagination={false} rowsCount={notes.length} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}/>
    </>);
};
export default NotesTable;
