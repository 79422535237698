const getPriceItems = (price) => {
    var _a, _b;
    if (!price[0])
        return [];
    const items = [];
    (_a = price[0].parts) === null || _a === void 0 ? void 0 : _a.forEach((p) => {
        items.push({
            type: 'part',
            name: p.name,
            quantity: p.quantity,
            totalPrice: p.totalPrice,
            price: p.price,
        });
    });
    (_b = price[0].fluids) === null || _b === void 0 ? void 0 : _b.forEach((f) => {
        items.push({
            type: 'fluid',
            name: f.name,
            totalPrice: f.totalPrice,
            cost: f.cost,
            unit: f.capacityUnitAbbreviation,
            capacity: f.capacity,
        });
    });
    if (price[0].laborCost) {
        items.push({
            type: 'labor',
            name: 'Labor Cost',
            cost: price.laborCost,
        });
    }
};
export const GetRepairsForDisplay = ({ services, showServicesCount = 1, }) => {
    let repairs = null;
    let extraRepairsCount = null;
    let allRepairs = null;
    if (!services) {
        return {
            repairs,
            extraRepairsCount,
            allRepairs,
        };
    }
    const allRepairsTmp = services === null || services === void 0 ? void 0 : services.knownRepairs.map((r) => ({
        name: r.name,
        price: r.price.reduce((acc, item) => acc + item.totalCost, 0),
        items: getPriceItems(r.price),
    }));
    services.customRequests.forEach((c) => {
        allRepairsTmp.push({
            name: c.message,
            price: c.price.reduce((acc, item) => acc + item.totalCost),
            items: getPriceItems(c.price),
        });
    });
    const outputRepairs = allRepairsTmp.length <= showServicesCount
        ? allRepairsTmp
        : allRepairsTmp.slice(0, showServicesCount);
    repairs = outputRepairs;
    extraRepairsCount =
        allRepairsTmp.length <= showServicesCount
            ? 0
            : allRepairsTmp.length - showServicesCount;
    allRepairs = allRepairsTmp;
    return {
        repairs,
        extraRepairsCount,
        allRepairs,
    };
};
