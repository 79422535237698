export const getIsSelected = (path, pathname) => {
    if (!path.length)
        return false;
    if (['/pops/distributors', '/pops/part-pickups', '/pops/inventory'].includes(pathname) &&
        path === '/pops') {
        return false;
    }
    if (path === '/tech/technicians' &&
        pathname.includes('technicians') &&
        pathname.includes('dashboard')) {
        return false;
    }
    if (path === '/tech/my-dashboard' &&
        pathname.includes('technicians') &&
        pathname.includes('dashboard'))
        return true;
    return location.pathname.startsWith(path);
};
export const getItemClassName = (text) => {
    return `item-${text.toLowerCase().replaceAll(' ', '-')}`;
};
