import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReactJson from '@microlink/react-json-view';
import { Grid } from 'src/components/mui';
import styled from 'styled-components';
import { selectAuth } from '../modules/selectors';
import { StatusIconToggle, getStatusText, } from '../components/NetSuiteStatusIcon';
const NetSuiteAuth = () => {
    const auth = useSelector(selectAuth);
    const status = useMemo(() => getStatusText(auth), [auth]);
    return (<Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Grid container direction="row" style={{ alignItems: 'center' }}>
        <StatusIconToggle />
        <StatusText>{status}</StatusText>
      </Grid>
      <Grid item>
        <ReactJson src={auth} name="auth" collapsed={false} indentWidth={4} displayDataTypes={false} theme="bright:inverted" enableClipboard onEdit={false} onAdd={false} onDelete={false} collapseStringsAfterLength={50}/>
      </Grid>
    </Grid>);
};
export default NetSuiteAuth;
const StatusText = styled('div') `
  font-size: 0.9em;
  color: #666;
  padding-left: 10px;
`;
