var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { find, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, MenuItem, TextField } from 'src/components/mui';
import { getInitialVehicleState } from 'src/AdminApp/containers/vehicle/CarModule/utils';
import { getAvailableTrimIdsByEngineDesc } from 'src/AdminApp/containers/vehicle/utils';
import { getAutoselectionMakes, getAutoselectionModels, getAutoselectionYears, } from 'src/AdminApp/modules/autoselection/actions';
import { selectMakes, selectModels, selectYears, } from 'src/AdminApp/modules/autoselection/selectors';
import { DrawerFormRow } from 'src/components/SimpleDrawer';
import CONSTANTS from 'src/constants';
const CarFields = ({ vehicle, onUpdate, updateAllFields = false, setUpdateComplete = () => { }, }) => {
    var _a, _b, _c, _d, _e, _f;
    const dispatch = useDispatch();
    const years = useSelector(selectYears);
    const models = useSelector(selectModels);
    const makes = useSelector(selectMakes);
    const defaultState = {
        year: '',
        makeId: '',
        modelId: '',
        model: null,
        trimId: '',
        trimOptions: [],
        engineDesc: '',
        engineOptions: [],
    };
    const [stateVehicle, setStateVehicle] = useState(Object.assign(Object.assign({}, defaultState), vehicle));
    const updateStateVehicle = (values) => {
        setStateVehicle((prevState) => (Object.assign(Object.assign({}, prevState), values)));
        onUpdate(values);
    };
    useEffect(() => {
        if (updateAllFields) {
            updateStateVehicle(vehicle);
            setUpdateComplete();
        }
    }, [updateAllFields]); // eslint-disable-line
    const initializeVehicle = () => __awaiter(void 0, void 0, void 0, function* () {
        const newStateVehicle = yield getInitialVehicleState(stateVehicle, dispatch);
        updateStateVehicle(newStateVehicle);
    });
    const onYearChange = ({ target }) => {
        const year = parseInt(target.value, 10);
        dispatch(getAutoselectionMakes({ year }));
        updateStateVehicle({
            makeId: '',
            year,
        });
    };
    const onMakeChange = ({ target }) => {
        const makeId = parseInt(target.value, 10);
        const { year } = stateVehicle;
        dispatch(getAutoselectionModels({ year, makeId }));
        updateStateVehicle({
            makeId,
        });
    };
    const onModelChange = ({ target }) => {
        const modelId = target.value;
        const model = find(models, (i) => i.id === +modelId);
        const engineOptions = model ? uniqBy(model.engines, 'description') : null;
        updateStateVehicle({
            modelId,
            model,
            trimOptions: model === null || model === void 0 ? void 0 : model.trims,
            trimIsRequired: true,
            engineOptions,
            engineIsRequired: true,
        });
    };
    const onTrimChange = ({ target }) => {
        var _a;
        const { engines: allEngines, trims } = stateVehicle.model;
        const trimId = target.value;
        const trim = trimId === CONSTANTS.TRIM_DONT_KNOW_ID
            ? null
            : trims.find((i) => i.id === +trimId);
        const engineIdsOfTrim = (_a = trim === null || trim === void 0 ? void 0 : trim.availableEngineIds) !== null && _a !== void 0 ? _a : null;
        const engineDescOfTrim = !!engineIdsOfTrim &&
            allEngines
                .filter((e) => engineIdsOfTrim.includes(e.id))
                .map((e) => e.description);
        const enginesOfTrim = engineDescOfTrim
            ? allEngines.map((e) => ({
                description: e.description,
                disabled: !engineDescOfTrim.includes(e.description),
            }))
            : allEngines;
        const engineOptions = uniqBy(enginesOfTrim, 'description');
        updateStateVehicle({
            trimId,
            engineOptions,
        });
    };
    const onEngineChange = ({ target }) => {
        var _a, _b;
        const engineDesc = target.value;
        const allEngines = (_a = stateVehicle.model.engines) !== null && _a !== void 0 ? _a : null;
        const allTrims = (_b = stateVehicle.model.trims) !== null && _b !== void 0 ? _b : null;
        const trimIdsOfEngines = getAvailableTrimIdsByEngineDesc(engineDesc, allEngines);
        const trimOptions = trimIdsOfEngines
            ? allTrims.map((trim) => (Object.assign(Object.assign({}, trim), { disabled: !trimIdsOfEngines.includes(trim.id) })))
            : allTrims;
        updateStateVehicle({
            engineDesc,
            trimOptions,
        });
    };
    useEffect(() => {
        var _a;
        const year = (_a = vehicle.year) !== null && _a !== void 0 ? _a : null;
        dispatch(getAutoselectionYears());
        if (year) {
            initializeVehicle();
        }
    }, []); // eslint-disable-line
    const trimId = !(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.trimId) ? '' : `${stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.trimId}`;
    return (<Grid container>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" type="text" label="Year" value={stateVehicle.year} onChange={onYearChange} name="year" id="year" select fullWidth helperText="* Required">
          {years.map((year) => (<MenuItem key={year} value={year}>
              {year}
            </MenuItem>))}
        </TextField>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_a = stateVehicle.makeId) !== null && _a !== void 0 ? _a : ''} onChange={onMakeChange} disabled={!stateVehicle.year} label="Make" type="text" name="make" id="make" select fullWidth helperText="* Required">
          {makes.map((make) => (<MenuItem key={make.id} value={make.id}>
              {make.value}
            </MenuItem>))}
        </TextField>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={(_b = stateVehicle.modelId) !== null && _b !== void 0 ? _b : ''} onChange={onModelChange} disabled={!stateVehicle.makeId} label="Model" type="text" name="model" id="model" select fullWidth helperText="* Required">
          {models.map((model) => (<MenuItem key={model.id} value={model.id}>
              {model.value}
            </MenuItem>))}
        </TextField>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={stateVehicle.engineDesc} onChange={onEngineChange} disabled={!stateVehicle.modelId} label="Engine" type="text" name="engine" id="engine" select fullWidth helperText="* Required">
          {((_c = stateVehicle.engineOptions) !== null && _c !== void 0 ? _c : []).map((engine) => (<MenuItem key={engine.description} value={engine.description} disabled={engine.disabled}>
              {engine.description}
            </MenuItem>))}
          {((_d = stateVehicle.engineOptions) !== null && _d !== void 0 ? _d : []).length >= 1 && (<MenuItem key={CONSTANTS.ENGINE_DONT_KNOW_ID} value={CONSTANTS.ENGINE_DONT_KNOW}>
              {CONSTANTS.ENGINE_DONT_KNOW}
            </MenuItem>)}
        </TextField>
      </DrawerFormRow>
      <DrawerFormRow item xs={12}>
        <TextField variant="outlined" value={trimId} onChange={onTrimChange} disabled={!stateVehicle.modelId} label="Trim" type="text" name="trim" id="trim" select fullWidth helperText="* Required">
          {((_e = stateVehicle.trimOptions) !== null && _e !== void 0 ? _e : []).map((trim) => (<MenuItem key={trim.id} value={trim.id} disabled={trim.disabled}>
              {trim.value}
            </MenuItem>))}
          {((_f = stateVehicle.trimOptions) !== null && _f !== void 0 ? _f : []).length >= 1 && (<MenuItem key={CONSTANTS.TRIM_DONT_KNOW_ID} value={CONSTANTS.TRIM_DONT_KNOW_ID}>
              {CONSTANTS.TRIM_DONT_KNOW}
            </MenuItem>)}
        </TextField>
      </DrawerFormRow>
    </Grid>);
};
export default CarFields;
