var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { toast } from 'src/components/SimpleToast';
import { useSelector } from 'react-redux';
import { selectAccessToken } from 'src/modules/auth/selectors';
export const useSkeduloApiClient = () => {
    const accessToken = useSelector(selectAccessToken);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${accessToken}`);
    myHeaders.append('Content-Type', 'application/json');
    const requestOptions = (method, body) => ({
        method,
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow',
    });
    const fetchErrorHandler = (status) => {
        toast.error(`Error fetching webhooks, Code: ${status}`);
        return { result: [] };
    };
    const fetchWebhooks = () => __awaiter(void 0, void 0, void 0, function* () {
        let response;
        try {
            response = yield fetch('/skedulo/webhooks', requestOptions('GET'));
            if (response.status === 200) {
                return response.json();
            }
            fetchErrorHandler(response.status);
        }
        catch (error) {
            toast.error(`Could not fetch Webhooks\n${error.message}`);
        }
        return response;
    });
    const createWebhook = (body) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield fetch('/skedulo/webhooks', requestOptions('POST', body));
            if (response.status === 200) {
                toast.success(`Successfully Created Webhook ${body.name}`);
            }
            else {
                toast.error(`Failed creating webhook : Error ${response.status}`);
            }
        }
        catch (error) {
            toast.error(`Failed to create webhook\n${error.message}`);
        }
    });
    const deleteWebhook = (id, name, setupWizard) => __awaiter(void 0, void 0, void 0, function* () {
        if (!setupWizard &&
            // eslint-disable-next-line no-alert
            !confirm(`Are you sure you want to delete webhook? ${name}`)) {
            return;
        }
        try {
            const response = yield fetch(`/skedulo/webhooks/${id}`, requestOptions('DELETE'));
            if (response.status === 200) {
                toast.success(`Successfully Deleted Webhook ${name}`);
            }
            else {
                toast.error(`Something went wrong : Error ${response.status}`);
            }
        }
        catch (error) {
            toast.error(`Could not delete Webhook\n${error.message}`);
        }
    });
    return {
        fetchWebhooks,
        createWebhook,
        deleteWebhook,
        fetchErrorHandler,
    };
};
