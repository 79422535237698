import { isNull } from 'lodash';
export const shouldDisplayLabor = (price) => price && (!isNull(price.laborCost) || !isNull(price.laborRate));
export function getRepairPrice(repairId, repairs) {
    var _a;
    if (repairs && repairId) {
        const repair = repairs.find((r) => r.id === repairId);
        return (_a = repair === null || repair === void 0 ? void 0 : repair.price[0]) !== null && _a !== void 0 ? _a : {};
    }
    return null;
}
