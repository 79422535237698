import { Formik } from 'formik';
import React from 'react';
import { TextField } from 'src/AdminApp/components/form';
import AddressFormModal from 'src/AdminApp/containers/appointment/AddressFormModal';
import SendResetPassword from 'src/AdminApp/containers/consumers/SendResetPassword';
import FleetResponsiblePartiesDrawer from 'src/AdminApp/containers/fleets/FleetInfo/components/FleetResponsiblePartiesDrawer';
import { addressInputStyle, BlockTitle, Container, editInputStyle, FieldLabel, FieldRow, FieldValue, variantMap, } from 'src/AdminApp/containers/fleets/tabs/styles';
import { FleetTaxTypes, FleetTypes, } from 'src/AdminApp/containers/fleets/utils/typeEnum';
import MarketingSourceSelect from 'src/AdminApp/containers/requests/MarketingSourceSelect';
import { MarketingSourceVisibility } from 'src/AdminApp/models/enums/MarketingSourceVisibility';
import { ActionButton } from 'src/components/buttons';
import { Editable, InputButtons, InputWrapper, } from 'src/components/form/components';
import EditableInput from 'src/components/form/EditableInput';
import EditableSelect from 'src/components/form/EditableSelect';
import FleetSearchSelect from 'src/components/form/FleetSearchSelect';
import { Button, Switch, Typography } from 'src/components/mui';
import { AddCircle } from 'src/components/mui/icons';
import useIsMobile from 'src/hooks/useIsMobile';
import { theme } from 'src/styles/theme';
import { getAddressString, getLocationString } from 'src/utils/addressStrings';
import { emailListValidation } from 'src/utils/email-list-validation';
import styled from 'styled-components';
import * as Yup from 'yup';
import useFleetInfo from './useFleetInfo';
const selectStyle = {
    width: '100%',
};
const alignStartStyle = {
    justifyContent: 'flex-start',
};
const Block = styled('div') `
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  ${theme.breakpoints.up('md')} {
    margin-bottom: 0;
    padding-right: 16px;
  }

  flex: 1;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    flex: 2;
    padding-right: 0;
  }
`;
const DEFAULT_SCHEMA = Yup.object().shape({
    rpBillingContacts: Yup.string().nullable(),
});
const FleetInfo = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    const { addressModalOpen, billingAddressModalOpen, isEditingMarketingSource, opsContactSameAsMain, fmcModalOpen, handleOpenAddressModal, handleCloseAddressModal, handleUpdateField, handleDailyOpsContactAutofill, handleUpdateAddress, handleUpdateBillingField, handleUpdateBillingAddress, handleUpdateTaxType, handleUpdateFleetResponsibleParties, handleSetIsEditingMarketingSource, handleSearchInput, handleFmcsModalOpen, handleFleetInfoSubmit, handleSearchFleets, handleSelectFmc, handleAcceptFmc, marketingSourcesById, canEditMarketingSource, fleet, fleetId, allResponsibleParties, handleBillingContactsSubmit, } = useFleetInfo();
    const isMobile = useIsMobile();
    if (!fleet)
        return null;
    const serviceAddress = ((_a = fleet === null || fleet === void 0 ? void 0 : fleet.consumer) === null || _a === void 0 ? void 0 : _a.address) && {
        address: getAddressString((_b = fleet.consumer) === null || _b === void 0 ? void 0 : _b.address),
        location: getLocationString((_c = fleet.consumer) === null || _c === void 0 ? void 0 : _c.address),
    };
    const billingAddress = () => {
        if (fleet === null || fleet === void 0 ? void 0 : fleet.billingInfo) {
            const address = {
                city: fleet.billingInfo.city,
                state: fleet.billingInfo.state,
                street: fleet.billingInfo.streetLine1,
                street2: fleet.billingInfo.streetLine2,
                zipCode: fleet.billingInfo.zip,
            };
            return {
                address: getAddressString(address),
                location: getLocationString(address),
            };
        }
    };
    const getMarketingDisplayName = (marketingSourceId) => {
        const marketingSource = marketingSourceId &&
            marketingSourcesById &&
            marketingSourcesById[marketingSourceId];
        return (<FieldValue style={alignStartStyle} variant="body1" variantMapping={variantMap} onClick={canEditMarketingSource
                ? () => handleSetIsEditingMarketingSource(true)
                : () => { }}>
        {(marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.displayName) || 'None'}
      </FieldValue>);
    };
    const renderParentForm = ({ setIsEditing }) => {
        const initValues = {
            consumerId: fleet.consumerId,
            parentId: fleet.parentId,
            oldParentId: fleet.parentId,
            initParent: true,
            parentName: fleet.parentName,
        };
        return (<Formik initialValues={initValues} onSubmit={handleFleetInfoSubmit}>
        {({ handleSubmit, setFieldValue }) => (<InputWrapper>
            <FleetSearchSelect autoFocus={false} excludedId={fleet.id} initialValue={fleet.parentName} onSearch={handleSearchFleets} onSelect={handleSelectFmc(setFieldValue)} onChange={(value) => {
                    handleSearchInput(value);
                }} fleetSuggestions={[]} label="Parent"/>
            <InputButtons onClose={() => {
                    setIsEditing(false);
                }} onAccept={handleAcceptFmc({
                    handleSubmit,
                    setFieldValue,
                    setIsEditing,
                })}/>
          </InputWrapper>)}
      </Formik>);
    };
    return (<>
      <Container>
        <Block>
          <BlockTitle variant="subtitle2">Fleet Info</BlockTitle>
          <FieldRow>
            <FieldLabel variant="subtitle1">Parent Name</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <Editable isEditable renderForm={renderParentForm} style={editInputStyle}>
                {(fleet === null || fleet === void 0 ? void 0 : fleet.parentName) || 'N/A'}
              </Editable>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Type</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableSelect enumeration={FleetTypes} selectStyle={selectStyle} style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.type} onUpdate={handleUpdateField('type')}>
                {fleet === null || fleet === void 0 ? void 0 : fleet.type}
              </EditableSelect>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">DSP Name</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.dspName} onUpdate={handleUpdateField('dspName')}>
                {fleet === null || fleet === void 0 ? void 0 : fleet.dspName}
              </EditableInput>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Station</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.station} onUpdate={handleUpdateField('station')}>
                {fleet === null || fleet === void 0 ? void 0 : fleet.station}
              </EditableInput>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Service Address</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <Editable isEditable style={addressInputStyle} onClick={handleOpenAddressModal()}>
                <span>{(_e = (_d = fleet === null || fleet === void 0 ? void 0 : fleet.consumer) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.street}</span>
                <span>{serviceAddress === null || serviceAddress === void 0 ? void 0 : serviceAddress.location}</span>
              </Editable>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">PM CSV Import Key</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.pmCsvImportKey} onUpdate={handleUpdateField('pmCsvImportKey')}>
                {fleet === null || fleet === void 0 ? void 0 : fleet.pmCsvImportKey}
              </EditableInput>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Fleet Size</FieldLabel>
            <FieldValue style={alignStartStyle} variant="body1" variantMapping={variantMap}>
              {fleet === null || fleet === void 0 ? void 0 : fleet.fleetSize}
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Marketing Source</FieldLabel>
            {isEditingMarketingSource ? (<FieldValue variant="body1">
                <MarketingSourceSelect onChange={(v) => {
                handleUpdateField('marketingSourceId')(v.target.value);
                handleSetIsEditingMarketingSource(false);
            }} name="marketingSourceId" visibility={MarketingSourceVisibility.ADMIN} fullWidth formField={false}/>
              </FieldValue>) : (getMarketingDisplayName(fleet === null || fleet === void 0 ? void 0 : fleet.marketingSourceId))}
          </FieldRow>
        </Block>
        <Block>
          <BlockTitle variant="subtitle2">Main Contact Info</BlockTitle>
          <FieldRow>
            <FieldLabel variant="subtitle1">Name</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.contactName} onUpdate={handleUpdateField('contactName')}>
                {fleet === null || fleet === void 0 ? void 0 : fleet.contactName}
              </EditableInput>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Phone</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.phone} onUpdate={handleUpdateField('phone')}>
                {fleet === null || fleet === void 0 ? void 0 : fleet.phone}
              </EditableInput>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Email</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.email} onUpdate={handleUpdateField('email')}>
                {fleet === null || fleet === void 0 ? void 0 : fleet.email}
              </EditableInput>
            </FieldValue>
          </FieldRow>
          {!isMobile && (<FieldRow>
              <SendResetPassword email={fleet.email} isFleet/>
            </FieldRow>)}
          <BlockTitle variant="subtitle2">Daily Ops Contact Info</BlockTitle>
          <FieldRow>
            <Typography variant="caption">Same as Main Contact</Typography>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <Switch checked={opsContactSameAsMain !== null && opsContactSameAsMain !== void 0 ? opsContactSameAsMain : false} onChange={(event) => {
            handleDailyOpsContactAutofill(event.target.checked);
        }} color="primary" name="Active"/>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Name</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactName} onUpdate={handleUpdateField('dailyOpsContactName')} isEditable={!opsContactSameAsMain}>
                {fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactName}
              </EditableInput>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Phone</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactPhone} onUpdate={handleUpdateField('dailyOpsContactPhone')} isEditable={!opsContactSameAsMain}>
                {fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactPhone}
              </EditableInput>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Email</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactEmail} onUpdate={handleUpdateField('dailyOpsContactEmail')} isEditable={!opsContactSameAsMain}>
                {fleet === null || fleet === void 0 ? void 0 : fleet.dailyOpsContactEmail}
              </EditableInput>
            </FieldValue>
          </FieldRow>
        </Block>
        <Block>
          <BlockTitle variant="subtitle2">Billing Info</BlockTitle>
          <FieldRow>
            <FieldLabel variant="subtitle1">Billing Address</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <Editable isEditable style={addressInputStyle} onClick={handleOpenAddressModal(true)}>
                <span>{(_f = fleet === null || fleet === void 0 ? void 0 : fleet.billingInfo) === null || _f === void 0 ? void 0 : _f.streetLine1}</span>
                {((_g = fleet === null || fleet === void 0 ? void 0 : fleet.billingInfo) === null || _g === void 0 ? void 0 : _g.streetLine2) && (<span>{fleet.billingInfo.streetLine2}</span>)}
                <span>{(_h = billingAddress()) === null || _h === void 0 ? void 0 : _h.location}</span>
              </Editable>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Billing Email</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableInput style={editInputStyle} value={(_j = fleet === null || fleet === void 0 ? void 0 : fleet.billingInfo) === null || _j === void 0 ? void 0 : _j.billingEmail} onUpdate={handleUpdateBillingField('billingEmail')}>
                {(_k = fleet === null || fleet === void 0 ? void 0 : fleet.billingInfo) === null || _k === void 0 ? void 0 : _k.billingEmail}
              </EditableInput>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Tax type</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableSelect enumeration={FleetTaxTypes} selectStyle={selectStyle} style={editInputStyle} value={((_l = fleet === null || fleet === void 0 ? void 0 : fleet.responsibleParty) === null || _l === void 0 ? void 0 : _l.taxType) || ''} onUpdate={handleUpdateTaxType}>
                {FleetTaxTypes.display(((_m = fleet === null || fleet === void 0 ? void 0 : fleet.responsibleParty) === null || _m === void 0 ? void 0 : _m.taxType) || '')}
              </EditableSelect>
            </FieldValue>
          </FieldRow>

          <BlockTitle variant="subtitle2">
            Associated Responsible Parties{' '}
            <Typography variant="subtitle1">
              {(_q = (_p = (_o = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) === null || _o === void 0 ? void 0 : _o.filter((rp) => {
            var _a;
            return rp.name ||
                ((_a = allResponsibleParties === null || allResponsibleParties === void 0 ? void 0 : allResponsibleParties.find((el) => el.id === rp.responsiblePartyId)) === null || _a === void 0 ? void 0 : _a.name);
        })) === null || _p === void 0 ? void 0 : _p.length) !== null && _q !== void 0 ? _q : 0}{' '}
              selected
            </Typography>
          </BlockTitle>
          {((_s = (_r = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) === null || _r === void 0 ? void 0 : _r.length) !== null && _s !== void 0 ? _s : 0) > 0 ? (<>
              {(_t = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) === null || _t === void 0 ? void 0 : _t.filter((rp) => {
                var _a;
                return rp.name ||
                    ((_a = allResponsibleParties === null || allResponsibleParties === void 0 ? void 0 : allResponsibleParties.find((el) => el.id === rp.responsiblePartyId)) === null || _a === void 0 ? void 0 : _a.name);
            }).map((rp) => {
                var _a, _b;
                return (<FieldRow>
                    <FieldLabel variant="subtitle1">Name</FieldLabel>
                    <FieldValue variant="body1" variantMapping={variantMap}>
                      <InputWrapper>
                        {(rp === null || rp === void 0 ? void 0 : rp.name) ||
                        ((_b = (_a = allResponsibleParties === null || allResponsibleParties === void 0 ? void 0 : allResponsibleParties.find((el) => el.id === rp.responsiblePartyId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '')}
                      </InputWrapper>
                    </FieldValue>
                  </FieldRow>);
            })}
              <FieldRow>
                <Button onClick={handleFmcsModalOpen(true)} variant="contained" color="primary">
                  Manage Responsible Parties
                </Button>
              </FieldRow>
            </>) : (<FieldRow>
              <Button color="primary" onClick={handleFmcsModalOpen(true)} startIcon={<AddCircle />} style={{
                display: 'flex',
                justifyContent: 'flex-start',
            }}>
                Add Responsible Party
              </Button>
            </FieldRow>)}
        </Block>
        {fleet.responsibleParty && (<Block>
            <BlockTitle variant="subtitle2">Billing Contacts</BlockTitle>
            <Formik enableReinitialize validateOnMount validationSchema={DEFAULT_SCHEMA} initialValues={{
                rpBillingContacts: ((_v = (_u = fleet === null || fleet === void 0 ? void 0 : fleet.responsibleParty) === null || _u === void 0 ? void 0 : _u.billingContacts) === null || _v === void 0 ? void 0 : _v.join(', ')) || '',
            }} onSubmit={handleBillingContactsSubmit}>
              {({ values, isValid, isSubmitting, errors, touched, handleSubmit, }) => {
                return (<>
                    <FieldRow>
                      <TextField value={values.rpBillingContacts} id="fleet-responsible-party-billing-contacts" name="rpBillingContacts" label="Contacts Emails" variant="outlined" rows={3} multiline helperText={touched['rpBillingContacts']
                        ? errors.rpBillingContacts
                        : undefined} validate={emailListValidation}/>
                    </FieldRow>
                    <Typography variant="subtitle1" style={{ marginTop: '8px' }}>
                      Input emails separated by a comma
                    </Typography>

                    <FieldRow style={{ marginTop: '12px' }}>
                      <ActionButton onClick={handleSubmit} disabled={!isValid || isSubmitting}>
                        Save
                      </ActionButton>
                    </FieldRow>
                  </>);
            }}
            </Formik>
          </Block>)}
      </Container>
      <AddressFormModal open={addressModalOpen} address={serviceAddress === null || serviceAddress === void 0 ? void 0 : serviceAddress.address} onClose={handleCloseAddressModal()} onSubmit={handleUpdateAddress} title="Edit Service Address"/>

      <AddressFormModal open={billingAddressModalOpen} secondLine={((_w = fleet === null || fleet === void 0 ? void 0 : fleet.billingInfo) === null || _w === void 0 ? void 0 : _w.streetLine2) || ''} address={((_x = billingAddress()) === null || _x === void 0 ? void 0 : _x.address) || ''} onClose={handleCloseAddressModal(true)} onSubmit={handleUpdateBillingAddress} title="Edit Billing Address"/>
      <FleetResponsiblePartiesDrawer open={fmcModalOpen} fleetResponsibleParties={fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties} onClose={handleFmcsModalOpen(false)} fleetId={fleetId} onSubmit={handleUpdateFleetResponsibleParties} allResponsibleParties={allResponsibleParties}/>
    </>);
};
export default FleetInfo;
