var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators, closeSlidePanel, deleteLaborRate, openSlidePanel, } from 'src/AdminApp/modules/geoManagement/actions';
import { selectSlidePanelParams } from 'src/AdminApp/modules/geoManagement/selectors';
import { addMetro, getMetroById } from 'src/AdminApp/modules/metros/actions';
import { addRegion, getRegionById, getRegionsByFilters, } from 'src/AdminApp/modules/regions/actions';
import { selectDashboardPagination as selectRegionsDashboardPagination, selectDashboardParams as selectRegionsDashboardParams, } from 'src/AdminApp/modules/regions/selectors';
import { addServiceArea } from 'src/AdminApp/modules/serviceAreas/actions';
import { addTerritory, getTerritoriesByFilters, } from 'src/AdminApp/modules/territories/actions';
import { selectDashboardPagination as selectTerritoriesDashboardPagination, selectDashboardParams as selectTerritoriesDashboardParams, } from 'src/AdminApp/modules/territories/selectors';
import { selectAuthorizedGeoMgmtEdit } from 'src/modules/auth/selectors';
import { addLocationParent } from 'src/SystemApp/modules/locations/actions';
const useGeoManagementActions = () => {
    const dispatch = useDispatch();
    const canEdit = useSelector(selectAuthorizedGeoMgmtEdit);
    const regionsPagination = useSelector(selectRegionsDashboardPagination);
    const regionsParams = useSelector(selectRegionsDashboardParams);
    const slidePanelParams = useSelector(selectSlidePanelParams);
    const territoriesPagination = useSelector(selectTerritoriesDashboardPagination);
    const territoriesParams = useSelector(selectTerritoriesDashboardParams);
    const refreshRegions = () => {
        var _a, _b, _c;
        return dispatch(getRegionsByFilters(Object.assign(Object.assign({}, regionsParams), { pagination: {
                size: (_a = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                page: (_b = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.pageNumber) !== null && _b !== void 0 ? _b : 0,
                sort: (_c = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.sort) !== null && _c !== void 0 ? _c : ['name', 'asc'],
            } })));
    };
    const refreshTerritories = () => {
        var _a, _b, _c;
        return dispatch(getTerritoriesByFilters(Object.assign(Object.assign({}, territoriesParams), { pagination: {
                size: (_a = territoriesPagination === null || territoriesPagination === void 0 ? void 0 : territoriesPagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                page: (_b = territoriesPagination === null || territoriesPagination === void 0 ? void 0 : territoriesPagination.pageNumber) !== null && _b !== void 0 ? _b : 0,
                sort: (_c = territoriesPagination === null || territoriesPagination === void 0 ? void 0 : territoriesPagination.sort) !== null && _c !== void 0 ? _c : ['name', 'asc'],
            } })));
    };
    const closeHandler = () => dispatch(closeSlidePanel());
    const onAddServiceArea = (model) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(addServiceArea(model));
        refreshRegions();
        return response;
    });
    const onAddRegion = (model) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const response = yield dispatch(addRegion(model));
        if (response.error)
            return;
        // ADD NEW HUBS
        const updatePromises = [];
        (_a = model.hubs) === null || _a === void 0 ? void 0 : _a.forEach((hub) => __awaiter(void 0, void 0, void 0, function* () {
            updatePromises.push(dispatch(addLocationParent(hub.id, response.payload.id)));
        }));
        yield Promise.all(updatePromises);
        refreshRegions();
    });
    const onAddMetro = (model) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(addMetro(model));
        refreshTerritories();
        refreshRegions();
    });
    const onAddTerritory = (model) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(addTerritory(model));
        refreshTerritories();
    });
    const openHandler = () => dispatch(openSlidePanel({
        onSaveRegion: onAddRegion,
        onSaveMetro: onAddMetro,
        onSaveTerritory: onAddTerritory,
        disableOptions: {
            serviceArea: false,
            region: false,
            metro: false,
            territory: false,
        },
    }));
    const getActions = () => {
        const actions = [];
        if (canEdit) {
            actions.push({
                function: openHandler,
                copy: 'Create New',
                actions: [],
            });
        }
        return actions;
    };
    const onDeleteRegionLaborRates = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(deleteLaborRate(id));
        if (slidePanelParams.regionModel) {
            const result = yield dispatch(getRegionById(slidePanelParams.regionModel.id));
            dispatch(ActionCreators.SLIDE_PANEL.UPDATE_REGION({ payload: result.payload }));
            refreshRegions();
        }
    });
    const onDeleteMetroLaborRates = (id) => __awaiter(void 0, void 0, void 0, function* () {
        if (!slidePanelParams.metroModel)
            return;
        yield dispatch(deleteLaborRate(id));
        const result = yield dispatch(getMetroById(slidePanelParams.metroModel.id));
        dispatch(ActionCreators.SLIDE_PANEL.UPDATE_METRO({ payload: result.payload }));
        refreshTerritories();
        refreshRegions();
    });
    return {
        refreshRegions,
        refreshTerritories,
        closeHandler,
        onAddServiceArea,
        onAddRegion,
        onAddMetro,
        onAddTerritory,
        getActions,
        onDeleteRegionLaborRates,
        onDeleteMetroLaborRates,
    };
};
export default useGeoManagementActions;
