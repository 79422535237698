import { Button, FormControlLabel, List, ListItem, Radio, RadioGroup, } from 'src/components/mui';
import React from 'react';
import DeliveryVanHomeStoresDrawer from 'src/AdminApp/components/deliveryVan/DeliveryVanHomeStoresDrawer';
import DeliveryVanDrawer from 'src/AdminApp/components/deliveryVan/DeliveryVanManagementDrawer';
import EditAvailabilityTemplateDrawer from 'src/AdminApp/components/deliveryVan/DeliveryVanTemplatesDrawer/EditAvailabilityTemplateDrawer';
import EditUnavailabilityTemplateDrawer from 'src/AdminApp/components/deliveryVan/DeliveryVanTemplatesDrawer/EditUnavailabilityTemplateDrawer';
import TemplatesDrawer from 'src/AdminApp/components/deliveryVan/DeliveryVanTemplatesDrawer/TemplatesDrawer';
import { SimpleModal } from 'src/components/layout';
import useDeliveryVanActions from './useDeliveryVanActions';
const DeliveryVanActions = ({ ispId, isDeliveryVan, onSubmit, }) => {
    var _a, _b;
    const { canView, canEdit, canEditTemplates, open, homeStoresOpen, templatesDrawerOpen, editTemplateDrawerOpen, editAvailabilityDrawerOpen, deliveryVan, serviceAreasDialog, setServiceAreasDialog, closeHandler, openHandler, closeHomeStoresHandler, openHomeStoresHandler, openTemplatesHandler, closeTemplatesHandler, closeEditTemplateHandler, closeEditUnavailabilityHandler, onSubmitDeliveryVan, onSubmitDeliveryVanHomeStores, onUpdateDeliveryVanServiceAreas, } = useDeliveryVanActions({ ispId, isDeliveryVan, onSubmit });
    return canView ? (<>
      {isDeliveryVan && (<>
          <Button type="button" color="primary" variant="contained" onClick={openHomeStoresHandler} style={{ marginRight: '15px' }}>
            Home Stores
          </Button>
          {canEditTemplates && (<Button type="button" color="primary" variant="contained" onClick={openTemplatesHandler} style={{ marginRight: '15px' }}>
              Templates
            </Button>)}
        </>)}

      <Button type="button" color="primary" variant="contained" onClick={openHandler}>
        Delivery Van
      </Button>
      <DeliveryVanDrawer open={open} closeHandler={closeHandler} onDeliveryVanSubmit={onSubmitDeliveryVan} deliveryVanModel={(deliveryVan === null || deliveryVan === void 0 ? void 0 : deliveryVan.id) ? deliveryVan : { ispId, skills: [] }} canEdit={canEdit}/>
      <DeliveryVanHomeStoresDrawer open={homeStoresOpen} closeHandler={closeHomeStoresHandler} onDeliveryVanHomeStoresSubmit={onSubmitDeliveryVanHomeStores} deliveryVanModel={deliveryVan} canEdit={canEdit}/>
      {canEditTemplates && (<>
          <TemplatesDrawer open={templatesDrawerOpen} closeHandler={closeTemplatesHandler} deliveryVanModel={deliveryVan}/>
          <EditAvailabilityTemplateDrawer open={editTemplateDrawerOpen} closeHandler={closeEditTemplateHandler} van={deliveryVan}/>
          <EditUnavailabilityTemplateDrawer open={editAvailabilityDrawerOpen} closeHandler={closeEditUnavailabilityHandler} van={deliveryVan}/>
        </>)}
      <SimpleModal title="Do you want this van to inherit the homestores of the region?" onClose={() => setServiceAreasDialog(undefined)} open={(_a = serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.open) !== null && _a !== void 0 ? _a : false} simpleTwoButtonRowProps={{
            onCancel: () => setServiceAreasDialog(undefined),
            onSubmit: () => onUpdateDeliveryVanServiceAreas(),
            submitText: 'Proceed',
        }}>
        <RadioGroup value={serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.selectedServiceArea} onChange={({ target }) => {
            setServiceAreasDialog((prevState) => (Object.assign(Object.assign({}, prevState), { selectedServiceArea: target.value })));
        }}>
          <List>
            {(_b = serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.model) === null || _b === void 0 ? void 0 : _b.deliveryVanServiceAreas.filter((item) => !item.id && !item.alreadyInactive).map((area) => (<ListItem key={area.serviceArea.id}>
                  <FormControlLabel value={area.serviceArea.id} control={<Radio color="primary"/>} label={area.serviceArea.name}/>
                </ListItem>))}
          </List>
        </RadioGroup>
      </SimpleModal>
    </>) : null;
};
export default DeliveryVanActions;
