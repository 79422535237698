var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { Container } from 'src/components/mui';
import PageLayout from 'src/containers/layout/PageLayout';
import useTechnicians from 'src/TechApp/hooks/useTechnicians';
import { theme, STATIC_URL_BASE } from 'src/styles/theme';
const Wrapper = styled.div `
  width: 100%;
  text-align: center;
  font-size: 6px;

  ${theme.breakpoints.up('md')} {
    font-size: 1rem;
  }
`;
const Home = (_a) => {
    var props = __rest(_a, []);
    useTechnicians();
    return (<PageLayout location={undefined} disableBreadcrumbs {...props}>
      <Container>
        <Wrapper>
          <img style={{ width: '100%', marginTop: 175 }} src={`${STATIC_URL_BASE}anms/logo/ms-tools-logo.svg`} alt="AMS Tools logo"/>
        </Wrapper>
      </Container>
    </PageLayout>);
};
export default Home;
