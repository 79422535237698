import React, { useState } from 'react';
import { Box, Button, Grid } from 'src/components/mui';
import { CheckCircleRounded as CheckCircleRoundedIcon } from 'src/components/mui/icons';
import useIsMobile from 'src/hooks/useIsMobile';
import { Tag } from '../AppointmentList/AppointmentList.styles';
import { CompletedLabel } from '../PartPickupTask/PartPickupTask.styles';
import { renderTimeWindow } from '../TaskParts';
import { LunchSection, LunchTaskAction, LunchTaskStyled, } from './LunchTask.styles';
import LunchTaskDrawer from './LunchTaskDrawer/LunchTaskDrawer';
const LunchTask = ({ startLocal, endLocal, lunchBreakId, status, }) => {
    const isMobile = useIsMobile();
    const [isLunchBreakDrawerOpen, setIsLunchBreakDrawerOpen] = useState(false);
    const closeDrawerHandler = () => {
        setIsLunchBreakDrawerOpen(false);
    };
    const openDrawerHandler = () => {
        setIsLunchBreakDrawerOpen(true);
    };
    const getActionButton = () => {
        if (status === 'COMPLETED') {
            return (<CompletedLabel>
          <span>Completed</span> <CheckCircleRoundedIcon />
        </CompletedLabel>);
        }
        return (<LunchTaskAction item>
        <Button variant="contained" color="primary" fullWidth={isMobile} onClick={openDrawerHandler}>
          View Details
        </Button>
      </LunchTaskAction>);
    };
    return isMobile ? (<>
      <LunchTaskStyled container direction="column" wrap="nowrap">
        <LunchSection container item>
          <Grid item xs={12}>
            {renderTimeWindow(startLocal, endLocal)}
            <Tag slugType="lunch">LUNCH</Tag>
          </Grid>
        </LunchSection>
        {getActionButton()}
      </LunchTaskStyled>
      <LunchTaskDrawer id={lunchBreakId !== null && lunchBreakId !== void 0 ? lunchBreakId : ''} isOpen={isLunchBreakDrawerOpen} onClose={closeDrawerHandler}/>
    </>) : (<>
      <tr>
        <td>{renderTimeWindow(startLocal, endLocal)}</td>
        <td>
          <Box textAlign="left">
            <Tag slugType="lunch">LUNCH</Tag>
          </Box>
        </td>
        <td colSpan={3}/>
        <td className="taskActions">{getActionButton()}</td>
      </tr>
      {lunchBreakId && (<LunchTaskDrawer id={lunchBreakId} isOpen={isLunchBreakDrawerOpen} onClose={closeDrawerHandler}/>)}
    </>);
};
export default LunchTask;
