import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDoubleTap } from 'use-double-tap';
import { Grid } from 'src/components/mui';
import EditableInput from 'src/components/form/EditableInput';
import { CopyToClipboardButton } from 'src/components/buttons';
import MobileCarDrawer from 'src/AdminApp/containers/vehicle/CarModule/MobileCarDrawer';
import { selectMobileCarDetails } from 'src/AdminApp/modules/requests/selectors';
import { closeMobileCarDetails } from 'src/AdminApp/modules/requests/actions';
import TokensSubscriptionsDrawer from 'src/AdminApp/components/drawers/TokensSubscriptionsDrawer';
import TagChips from 'src/AdminApp/containers/requests/TagChips';
import SubscriptionChip from 'src/AdminApp/containers/requests/SubscriptionChip';
import useIsMobile from 'src/hooks/useIsMobile';
import { ConsumerId, Logo, RequestInfo, RequestInfoDisplayLink, RequestInfoDisplayText, StyledChevronRightIcon, StyledConsumerTag, VehicleInfo, Wrapper, } from './RepairHeader.styles';
const RepairHeader = ({ consumer, vehicle, logo, onUpdateContact, onUpdateCar, referenceNum, repairRequestId, fleet, canEdit = true, tags, }) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const [tokensDrawerOpen, setTokensDrawerOpen] = useState(false);
    const isOpenMobileCarDetails = useSelector(selectMobileCarDetails);
    const consumerDisplayText = consumer
        ? consumer.name || 'Consumer'
        : 'loading consumer...';
    const fleetDisplayText = fleet ? fleet.name || 'Fleet' : 'loading fleet...';
    const vehicleDisplayText = vehicle
        ? `${vehicle.year} ${vehicle.make}`
        : 'loading vehicle...';
    const [displayCarDrawer, setDisplayCarDrawer] = useState(false);
    const onDoubleClick = () => isMobile && setDisplayCarDrawer(true);
    const onDoubleTap = useDoubleTap(onDoubleClick);
    const handleClose = () => {
        if (isOpenMobileCarDetails) {
            dispatch(closeMobileCarDetails());
        }
        setDisplayCarDrawer(false);
    };
    const open = displayCarDrawer || isOpenMobileCarDetails;
    const renderInfo = () => {
        if (fleet) {
            return (<RequestInfo container style={{ alignItems: 'baseline' }}>
          <RequestInfoDisplayLink to={`/admin/fleets/${fleet.id}`}>
            {fleetDisplayText}
          </RequestInfoDisplayLink>
        </RequestInfo>);
        }
        return (<RequestInfo container style={{ alignItems: 'baseline' }}>
        <EditableInput value={consumer.name} displayValue={<RequestInfoDisplayText>
              {consumerDisplayText}
            </RequestInfoDisplayText>} defaultValue="-" onUpdate={onUpdateContact} isEditable={canEdit}/>
        <ConsumerId to={`/admin/consumers/${consumer.consumerId}`}>
          {consumer.consumerId}
        </ConsumerId>
        <CopyToClipboardButton value={consumer.name}/>
        {isMobile && <StyledConsumerTag>Consumer</StyledConsumerTag>}
      </RequestInfo>);
    };
    return (<Wrapper component="span">
      {renderInfo()}
      <Grid onDoubleClick={onDoubleClick} {...onDoubleTap} container style={{ alignItems: 'center' }}>
        <Logo src={logo}/>
        <VehicleInfo variant="body2">{vehicleDisplayText}</VehicleInfo>
        &nbsp;{`${vehicle === null || vehicle === void 0 ? void 0 : vehicle.model} ${vehicle === null || vehicle === void 0 ? void 0 : vehicle.trim}`}
        <SubscriptionChip vehicle={vehicle} setTokensDrawerOpen={setTokensDrawerOpen}/>
        {tags && <TagChips tags={tags}/>}
        {isMobile && <StyledChevronRightIcon />}
      </Grid>
      {isMobile && (<MobileCarDrawer open={open} onClose={handleClose} onUpdate={onUpdateCar} vehicle={vehicle} logo={logo} referenceNum={referenceNum} repairRequestId={repairRequestId}/>)}

      <TokensSubscriptionsDrawer open={tokensDrawerOpen} extVehicle={vehicle} onClose={() => {
            setTokensDrawerOpen(false);
        }}/>
    </Wrapper>);
};
export default RepairHeader;
