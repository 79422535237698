import React from 'react';
import { ChevronRight } from 'src/components/mui/icons';
import payerStrings from '../payerStrings';
import { PayerBtn, PayerBtnContainer, PayerBtnRightSide, PayerBtnText, PayerContainer, } from './SelectPayer.styles';
import useSelectPayer from './useSelectPayer';
const SelectPayer = () => {
    const { workOrderResponsibleParties, handleSelectPayer, getInvoiceByResponsibleParty, } = useSelectPayer();
    return (<PayerContainer>
      {workOrderResponsibleParties.map((workOrderResponsibleParty) => {
            var _a;
            const responsibleParty = workOrderResponsibleParty === null || workOrderResponsibleParty === void 0 ? void 0 : workOrderResponsibleParty.responsibleParty;
            if (!responsibleParty)
                return null;
            const invoice = getInvoiceByResponsibleParty(responsibleParty);
            const balanceOwed = (invoice === null || invoice === void 0 ? void 0 : invoice.balanceOwed) ? invoice === null || invoice === void 0 ? void 0 : invoice.balanceOwed : 0;
            const responsiblePartyName = (_a = responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.responsiblePartyType) === null || _a === void 0 ? void 0 : _a.name;
            return (<PayerBtnContainer key={responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.id}>
            <PayerBtn fullWidth onClick={handleSelectPayer(workOrderResponsibleParty)}>
              <PayerBtnText variant="subtitle2">
                {responsiblePartyName
                    ? payerStrings[responsiblePartyName]
                    : ''}
              </PayerBtnText>
              <PayerBtnRightSide>
                <PayerBtnText $isColored $isOwed={balanceOwed > 0} variant="subtitle2">
                  ${balanceOwed === null || balanceOwed === void 0 ? void 0 : balanceOwed.toFixed(2)}
                </PayerBtnText>
                <ChevronRight />
              </PayerBtnRightSide>
            </PayerBtn>
          </PayerBtnContainer>);
        })}
    </PayerContainer>);
};
export default SelectPayer;
