var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'src/components/SimpleToast';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { submitPickedUpParts } from 'src/TechApp/modules/partpickup/actions';
import { selectPartPickupOrder, selectPickedUpItems, } from 'src/TechApp/modules/partpickup/selectors';
const usePartPickupMissingParts = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const partPickupOrder = useSelector(selectPartPickupOrder);
    const pickedUpItems = useSelector(selectPickedUpItems);
    const { referenceNum } = usePageReferenceNum();
    const history = useHistory();
    const handleBack = () => {
        history.push(`/pops/part-pickups/${referenceNum}/select-parts`);
    };
    const handleOk = () => __awaiter(void 0, void 0, void 0, function* () {
        if (pickedUpItems && pickedUpItems.length) {
            setIsLoading(true);
            const mappedItems = pickedUpItems.map((item) => (Object.assign(Object.assign({}, item), { quantity: item.checked ? item.quantity : 0 })));
            const response = yield dispatch(submitPickedUpParts(partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.id, mappedItems));
            setIsLoading(false);
            if (!response.error) {
                toast.success('The selected parts were submitted successfully');
                history.push(`/pops/part-pickups/${referenceNum}`);
            }
        }
    });
    return {
        isLoading,
        handleBack,
        handleOk,
    };
};
export default usePartPickupMissingParts;
