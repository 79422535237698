import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails, Grid, } from 'src/components/mui';
export const DrawerHeader = styled.div `
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.11px;
  color: ${colors.GRAY_3F};

  .refNum {
    span {
      font-weight: bold;
    }
  }

  .created {
    margin-bottom: 4px;
    span {
      font-weight: bold;
    }
  }

  ${theme.breakpoints.up('md')} {
    padding: 0;
    flex-direction: row;
    font-size: 14px;
    font-weight: normal;

    .refNum {
      span {
        font-weight: normal;
      }
    }

    .created {
      margin-bottom: 0px;
      span {
        font-weight: normal;
      }
    }
  }
`;
export const DrawerContent = styled.div `
  margin-top: 24px;

  ${theme.breakpoints.up('md')} {
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  }
`;
export const AccordionSectionHeader = styled(Grid) `
  display: none;

  ${theme.breakpoints.up('md')} {
    display: flex;
    padding: 14px 24px 15px 24px;
    background-color: #fafafa;

    & > * {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.1px;
      color: ${colors.darkGrey};
    }
  }
`;
export const AccordionDetailsInfo = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;

  .row {
    flex-direction: column-reverse;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.23px;
    color: ${colors.GRAY_3F};
    margin-bottom: 16px;

    .previous-value {
      margin-bottom: 8px;
    }
  }

  ${theme.breakpoints.up('md')} {
    .row {
      flex-direction: row;

      .previous-value {
        margin-bottom: 0px;
      }
    }
  }
`;
export const Accordion = styled(MuiAccordion) `
  &.Mui-expanded {
    margin: 0;

    &::before {
      opacity: 1;
    }
  }
`;
export const AccordionSummary = styled(MuiAccordionSummary) `
  position: relative;

  ${theme.breakpoints.up('md')} {
    padding: 14px 24px 15px 24px;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    right: 0;
    margin-right: 25px;
  }

  ${theme.breakpoints.up('md')} {
    &.Mui-expanded {
      min-height: 0px;
    }
  }
`;
export const AccordionSummaryContent = styled(Grid) `
  display: none;

  ${theme.breakpoints.up('md')} {
    display: flex;
  }
`;
export const MobileAccordionSummaryContent = styled.div `
  display: block;
  justify-content: space-between;
  padding: 19px 0px;

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`;
export const AccordionDetails = styled(MuiAccordionDetails) `
  ${theme.breakpoints.up('md')} {
    padding: 14px 24px 15px 24px;
  }
`;
