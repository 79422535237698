import { Divider } from 'src/components/mui';
import { Error as ErrorIcon } from 'src/components/mui/icons';
import React from 'react';
import { ButtonLink, ErrorIconStyle, IntroContent, IntroCopy, IntroHeader, } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/AuthorizationScript.styles';
import { SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
const AuthorizationFail = ({ onViewCC, onSkipPayment, onCancelReschedule, }) => {
    return (<>
      <SimpleDrawerContent>
        <IntroContent>
          <ErrorIcon style={ErrorIconStyle} fontSize="large"/>
          <IntroHeader>Authorization Failed</IntroHeader>
          <IntroCopy>
            We were unable to authorize. Would the customer like to:
          </IntroCopy>
        </IntroContent>
        <Divider />
        <IntroCopy>
          Customer using alternative payment method?
          <ButtonLink onClick={onSkipPayment}>
            Skip Payment Authorization
          </ButtonLink>
        </IntroCopy>
      </SimpleDrawerContent>

      <SimpleDrawerFooter>
        <SimpleTwoButtonRow submitText="View CC Details" onSubmit={onViewCC} onCancel={onCancelReschedule} cancelText="Cancel/Reschedule"/>
      </SimpleDrawerFooter>
    </>);
};
export default AuthorizationFail;
