import React, { useState } from 'react';
import { PickPartItemContainer, PartContent, PartTitle, PartDetails, NotesTitle, PartNotes, MoreButton, } from './PickPartDrawer.styles';
const PickPartItem = ({ item, onSelect }) => {
    var _a, _b, _c;
    const NOTES_LENGTH = 80;
    const truncatedNotes = (_b = (_a = item === null || item === void 0 ? void 0 : item.notes) === null || _a === void 0 ? void 0 : _a.substring(0, NOTES_LENGTH)) !== null && _b !== void 0 ? _b : '';
    const hideNotes = !(item === null || item === void 0 ? void 0 : item.notes) || (item === null || item === void 0 ? void 0 : item.notes) === '';
    const [notesExtended, setNotesExtended] = useState(!(item === null || item === void 0 ? void 0 : item.notes) || ((_c = item === null || item === void 0 ? void 0 : item.notes) === null || _c === void 0 ? void 0 : _c.length) < NOTES_LENGTH);
    const handleMoreButton = (e) => {
        e.stopPropagation();
        setNotesExtended(true);
    };
    const handleSelect = () => {
        if (!onSelect)
            return;
        onSelect(item);
    };
    return (<PickPartItemContainer onClick={handleSelect} className="PickPartItem__Container">
      <PartContent className="PickPartItem__Content">
        <PartTitle className="PickPartItem__Title">
          {item.longDescription}
        </PartTitle>
        <PartDetails className="PickPartItem__Details">
          {item.manufacturerName} • Part #: {item.partNumber}
          <br />
          Cost: ${item.unitCost} • Availability: {item.availableQuantity}
          <br />
          {item.coreCost ? `Core Cost: $${item.coreCost}` : ''}
        </PartDetails>
        {!hideNotes && (<>
            <NotesTitle className="PickPartItem__NotesTitle">Notes</NotesTitle>
            <PartNotes className="PickPartItem__Notes">
              {notesExtended ? item.notes : truncatedNotes}{' '}
              {!notesExtended && (<MoreButton onClick={handleMoreButton}>See More</MoreButton>)}
            </PartNotes>
          </>)}
      </PartContent>
    </PickPartItemContainer>);
};
export default PickPartItem;
