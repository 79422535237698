import styled from 'styled-components';
import { Accordion, AccordionSummary, Button, AccordionDetails, } from 'src/components/mui';
import { colors, FONT_FAMILY } from 'src/styles/theme';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 0 4px -8px;
  padding: 8px;
  background-color: ${(props) => props.selected ? colors.transparentDarkGrey : 'transparent'};
  will-change: padding;
  transition: padding 0.3s ease;
  border-radius: 4px;
  width: calc(100% + 16px);
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  :hover {
    background-color: ${(props) => props.disabled ? 'transparent' : colors.transparentDarkGrey};
  }

  .partHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .partCaption {
    font-family: ${FONT_FAMILY};
    color: ${colors.BLUE};
    font-weight: 700;
    font-size: 14px;
    text-align: left;

    &--special {
      margin-right: ${(props) => (props.isMobile ? '4px' : '8px')};
    }
  }

  .partDivider {
    flex: 1;
    border-bottom: dotted 1px ${colors.GRAY_DE};
    height: 17px;
  }

  .partCost {
    font-weight: 500;
    color: ${colors.black};
    font-size: 14px;
    margin-right: 10px;
  }

  .partQuoted {
    font-weight: 500;
    color: ${colors.GRAY_AA};
    font-size: 14px;
  }

  .partCostRow {
    justify-content: flex-start;
  }

  .partDetails {
    font-weight: 500;
    font-size: 12px;
    color: ${colors.GRAY_76};

    @media (min-width: 600px) {
      position: absolute;
      left: 0;
      top: 100%;
    }
  }
`;
export const SpecialOrderWrapper = styled.span `
  display: flex;
  align-items: center;

  .neededText {
    color: ${colors.GRAY_3F};
  }
`;
export const AccordionWrapper = styled(Accordion) `
  border: none;
  box-shadow: none;

  &:before {
    background-color: transparent;
  }
`;
export const AccordionMain = styled(AccordionSummary) `
  .MuiAccordionSummary-content {
    margin: 4px 0 4px;
    &.Mui-expanded {
      margin: 4px 0 4px;
    }
  }
  &.Mui-expanded {
    margin: 4px 0 4px;
  }

  .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    right: 0;
    top: -6px;
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }
`;
export const AccordionMainMobile = styled(AccordionSummary) `
  .MuiAccordionSummary-content {
    margin: 4px 0 4px;
    &.Mui-expanded {
      margin: 4px 0 4px;
    }
  }
  &.Mui-expanded {
    margin: 4px 0 4px;
  }

  .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    right: 16px;
    top: 1px;
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }
`;
export const AccordionDetailsWrapper = styled(AccordionDetails) `
  &.MuiAccordionDetails-root {
    padding: 0 0 0 32px;
  }
`;
export const AccordionDetailsWrapperMobile = styled(AccordionDetails) `
  &.MuiAccordionDetails-root {
    padding: 0 16px;
  }
`;
export const RightSideWrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  flex-basis: 148px;
`;
export const RightSideWrapperMobile = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const AddFitment = styled(Button) `
  font-family: ${FONT_FAMILY};
  font-weight: 400;
  font-size: 12px;
  color: ${colors.BLUE};
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  position: relative;
  left: -6px;
  margin: ${({ $isFitment }) => ($isFitment ? '0 8px' : ' 0')};
`;
export const PartCaption = styled.span `
  color: ${colors.BLUE};
  font-weight: 700;
  font-size: 14px;
  text-align: left;
`;
export const FitmentCaption = styled.span `
  color: ${colors.black};
  font-weight: 700;
  font-size: 14px;
  text-align: left;
`;
export const PartDetails = styled.div `
  font-weight: 500;
  font-size: 12px;
  color: ${colors.GRAY_76};
`;
export const VerticalDivider = styled.div `
  position: absolute;
  top: 0;
  right: 160px;
  height: 100%;
  border-left: 1px dashed ${colors.GRAY_AA};
`;
export const Dots = styled.div `
  flex: 1;
  border-bottom: dotted 1px ${colors.GRAY_DE};
  margin: 0 8px 6px 4px;
`;
export const CaptionWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  flex: 1;

  .multipleFitmentsChip {
    margin: 0 3px;
  }
`;
export const CaptionWrapperMobile = styled.div `
  display: flex;
  flex-direction: ${({ $isFitment }) => ($isFitment ? 'column' : 'row')};
  justify-content: flex-start;
  width: 100%;

  .multipleFitmentsChip {
    margin: 0 3px;
  }
`;
export const FitmentActions = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 150px;
  top: -2px;
  position: relative;
`;
export const FitmentActionsMobile = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
`;
export const FitmentItem = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding: 4px 4px 8px;
  background-color: ${({ selected }) => selected ? colors.transparentDarkGrey : 'transparent'};
  border-radius: 4px;
  width: 100%;

  :hover {
    background-color: ${({ disabled }) => disabled ? 'transparent' : colors.transparentDarkGrey};
  }
`;
export const AccordionTop = styled.div `
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ selected }) => selected ? colors.transparentDarkGrey : 'transparent'};
  border-radius: 4px;
  padding: 4px;

  :hover {
    background-color: ${({ disabled }) => disabled ? 'transparent' : colors.transparentDarkGrey};
  }
`;
