import React from 'react';
import { Accordion, AccordionSummary, Button, TableBody, TableCell, TableHead, TableRow, } from 'src/components/mui';
import { ExpandMore as ExpandMoreIcon, Edit } from 'src/components/mui/icons';
import { ConfirmationModal } from 'src/components/layout';
import { AccordionContainer, HeaderContainer, RuleAccordionDetails, RuleName, RuleTable, TableFooter, } from 'src/AdminApp/containers/fleets/tabs/pricing/NewRuleDrawer/NewRuleDrawer.styles';
import usePricingRule from './usePricingRule';
const PricingRule = ({ rule, onEdit }) => {
    var _a, _b;
    const { deactivateRules, isPricingRulesEditor, deactivateConfirmModalOpen, handleEdit, handleDeactivateModal, } = usePricingRule({ rule, onEdit });
    return (<>
      <AccordionContainer>
        <Accordion style={{ width: '55%' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="disabled"/>} aria-controls="panel1a-content" id="panel1a-header">
            <HeaderContainer>
              <RuleName style={{ width: '70%' }}>
                {(_a = rule === null || rule === void 0 ? void 0 : rule.rules[0]) === null || _a === void 0 ? void 0 : _a.repairName}
              </RuleName>
              <RuleName>Total Price: ${rule === null || rule === void 0 ? void 0 : rule.totalCost}</RuleName>
              {isPricingRulesEditor && (<Edit color="disabled" onClick={handleEdit}/>)}
            </HeaderContainer>
          </AccordionSummary>
          <RuleAccordionDetails>
            <RuleTable>
              <TableHead>
                <TableCell>Component Name</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Price</TableCell>
              </TableHead>
              <TableBody>
                {(_b = rule === null || rule === void 0 ? void 0 : rule.rules) === null || _b === void 0 ? void 0 : _b.map((row) => (<TableRow>
                    <TableCell style={{ width: '50%' }}>
                      <div>{row.name}</div>
                    </TableCell>
                    <TableCell>
                      <div>{row.maxQuantity}</div>
                    </TableCell>
                    <TableCell>
                      <div>${row.maxPrice}</div>
                    </TableCell>
                  </TableRow>))}
              </TableBody>
            </RuleTable>
            {isPricingRulesEditor && (<TableFooter>
                <Button variant="outlined" onClick={handleDeactivateModal(true)}>
                  Deactivate
                </Button>
              </TableFooter>)}
          </RuleAccordionDetails>
        </Accordion>
      </AccordionContainer>
      <ConfirmationModal open={deactivateConfirmModalOpen} onClose={handleDeactivateModal(false)} text="Are you sure you want to deactivate this repair?" action={deactivateRules}/>
    </>);
};
export default PricingRule;
