import { createClient } from 'contentful';
import CONFIG from 'src/config';
const config = {
    space: CONFIG.REACT_APP_CONTENTFUL_SPACE,
    accessToken: CONFIG.REACT_APP_CONTENTFUL_PUBLISHED_TOKEN,
};
const client = createClient(config);
const draftClient = createClient(Object.assign(Object.assign({}, config), { accessToken: CONFIG.REACT_APP_CONTENTFUL_DRAFT_TOKEN }));
const getClient = (isDraft) => (isDraft ? draftClient : client);
export const getEntry = (id, isDraft = false) => getClient(isDraft).getEntry(id);
