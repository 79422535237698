import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Autocomplete } from 'src/components/mui';
const GroupUserSearch = ({ value = null, selector, onUpdate, name, label, }) => {
    const options = useSelector(selector);
    const onSubmit = (user) => {
        var _a, _b;
        onUpdate({
            id: (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : 0,
            email: (_b = user === null || user === void 0 ? void 0 : user.email) !== null && _b !== void 0 ? _b : '',
            uid: user === null || user === void 0 ? void 0 : user.uid,
        });
    };
    return (<Autocomplete value={value} style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option, value) => option.name === value.name} options={options} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.email) !== null && _a !== void 0 ? _a : ''; }} onChange={(e, value) => {
            onSubmit(value);
        }} renderInput={(params) => (<TextField {...params} label={label} type="text" name={name} variant="standard" InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default GroupUserSearch;
