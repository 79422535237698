import React from 'react';
import { Icon, IconButton, Menu, MenuItem } from 'src/components/mui';
const DropdownOptions = ({ component, onRemove, id, label, }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (<div>
      <IconButton aria-controls={`${component}-menu`} aria-haspopup="true" onClick={handleClick}>
        <Icon>more_horiz</Icon>
      </IconButton>
      <Menu id={`${component}-menu-${id}`} anchorEl={anchorEl} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }} transformOrigin={{
            vertical: -30,
            horizontal: 'center',
        }} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => {
            handleClose();
            onRemove(id);
        }}>
          {label}
        </MenuItem>
      </Menu>
    </div>);
};
export default DropdownOptions;
