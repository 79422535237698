var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/mui';
import SimpleChip from 'src/components/SimpleChip';
import ActiveStopTooltip from 'src/PopsApp/components/tooltips/ActiveStopTooltip';
import { selectSelectedJobs } from 'src/PopsApp/modules/parts/selectors';
import { getCatalogComponents } from 'src/PopsApp/modules/vendors/actions';
import { selectFluidsCatalog, selectPartsCatalog, } from 'src/PopsApp/modules/vendors/selectors';
import { calculateComponentsAvailability, createComponentAvailabilityRequests, } from 'src/PopsApp/pages/Vendors/utils/componentAvailabilityUtils';
import VendorPartsAvailability from 'src/PopsApp/pages/Vendors/VendorPartsAvailability';
import { getChipColor } from 'src/PopsApp/pages/Vendors/Vendors.styles';
import { colors } from 'src/styles/theme';
import { TIME_12H_FORMAT } from 'src/timeConstants';
const QuickLocationItem = ({ location, handleVendorSubmit, }) => {
    var _a;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [componentsAvailability, setComponentsAvailability] = useState();
    const selectedJobs = useSelector(selectSelectedJobs);
    const partsCatalog = useSelector(selectPartsCatalog);
    const fluidsCatalog = useSelector(selectFluidsCatalog);
    const loadAvailability = (partDistributorLocationId) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedJobs) {
            setLoading(true);
            const promises = [];
            selectedJobs.forEach((job) => {
                var _a;
                const availabilityRequestObjects = createComponentAvailabilityRequests((_a = job.services) === null || _a === void 0 ? void 0 : _a.knownRepairs);
                promises.push(dispatch(getCatalogComponents(job === null || job === void 0 ? void 0 : job.car, partDistributorLocationId, availabilityRequestObjects)));
            });
            yield Promise.all(promises);
            setLoading(false);
        }
    });
    useEffect(() => {
        if (selectedJobs) {
            setComponentsAvailability(calculateComponentsAvailability(location.partDistributorLocationId, selectedJobs, partsCatalog[location.partDistributorLocationId], fluidsCatalog[location.partDistributorLocationId]));
        }
    }, [location, selectedJobs, partsCatalog, fluidsCatalog]);
    useEffect(() => {
        if (location.integrated)
            loadAvailability(location.partDistributorLocationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const haveNotPickedUpOrders = (_a = location === null || location === void 0 ? void 0 : location.order) === null || _a === void 0 ? void 0 : _a.items.some((o) => o.states[0].status === 'STANDBY');
    const getDisplayedTitle = () => {
        if (location.start) {
            return `${moment(location.start).format(TIME_12H_FORMAT)} - ${location.partDistributorName}`;
        }
        return location.partDistributorName;
    };
    return (<li className="QuickLocation" key={`${location.partDistributorLocationId}`}>
      <Button disabled={location.integrated && loading} onClick={() => {
            handleVendorSubmit({
                partDistributorLocationId: location.partDistributorLocationId,
                partDistributorName: location.partDistributorName,
                partDistributorId: location.partDistributorId,
                address: location.partDistributorAddress,
                dealershipName: location.dealershipName,
                order: location === null || location === void 0 ? void 0 : location.order,
            }, !location.integrated, componentsAvailability);
        }} classes={{
            root: `QuickLocation__buttonRoot ${haveNotPickedUpOrders ? 'standby' : ''}`,
        }}>
        <div className="QuickLocation__title">
          <div className="QuickLocation__name">
            {getDisplayedTitle()}{' '}
            {haveNotPickedUpOrders && <ActiveStopTooltip />}
          </div>
          {!location.integrated && (<SimpleChip className="QuickLocation__badge" $bgColor={getChipColor({
                integrated: false,
            })} $textColor={colors.white} label="Not Integrated"/>)}
          {location.integrated && (<VendorPartsAvailability className="QuickLocation__availability" componentsAvailability={componentsAvailability} loading={loading} loaded={!loading}/>)}
        </div>
        <div className="QuickLocation__address">{`${location.partDistributorAddress} ${location.dealershipName ? `- ${location.dealershipName}` : ''}`}</div>
      </Button>
    </li>);
};
export default QuickLocationItem;
