/* eslint-disable import/no-import-module-exports */
import { createStore, applyMiddleware, compose, combineReducers, } from 'redux';
import thunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import { createTransform, persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { ActionCreators as AuthActions } from 'src/modules/auth/actions';
import tokenValidatorMiddleware from 'src/modules/middleware/tokenValidatorMiddleware';
import appReducers from 'src/modules/reducers';
import adminAppReducers from 'src/AdminApp/modules/reducers';
import popsAppReducers from 'src/PopsApp/modules/reducers';
import pricingAppReducers from 'src/PricingApp/modules/reducers';
import systemAppReducers from 'src/SystemApp/modules/reducers';
import techAppReducers from 'src/TechApp/modules/reducers';
import partnersAppReducers from 'src/PartnersApp/modules/reducers';
import fleetVisitsAppReducers from 'src/FleetVisitsApp/modules/reducers';
import partnersAPIAppReducers from 'src/PartnersAPIApp/modules/reducers';
import netSuiteReducers from 'src/ScanPocApp/modules/reducers';
import graphQLErrorMiddleware from 'src/modules/middleware/graphQLErrorMiddleware';
import apiErrorMiddleware from 'src/modules/middleware/apiErrorMiddleware';
const enhancers = [];
const middlewares = [
    tokenValidatorMiddleware,
    apiMiddleware,
    graphQLErrorMiddleware,
    apiErrorMiddleware,
    thunk,
];
const wnd = window;
const devToolsExtension = wnd && wnd.__REDUX_DEVTOOLS_EXTENSION__;
if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
}
// redux-persist transformer for "admin" store
const adminTransform = createTransform((inbound) => {
    var _a, _b, _c, _d;
    return {
        swimlanes: {
            territories: (_b = (_a = inbound.swimlanes) === null || _a === void 0 ? void 0 : _a.territories) !== null && _b !== void 0 ? _b : [],
            selectedRegions: (_d = (_c = inbound.swimlanes) === null || _c === void 0 ? void 0 : _c.selectedRegions) !== null && _d !== void 0 ? _d : [],
        },
    };
}, (outbound) => {
    return outbound;
}, { whitelist: ['admin'] });
const persistConfig = {
    key: 'root',
    whitelist: ['auth', 'netSuite', 'admin'],
    storage,
    transforms: [adminTransform],
    stateReconciler: autoMergeLevel2,
};
export const appReducer = combineReducers(Object.assign(Object.assign({}, appReducers), { netSuite: netSuiteReducers, admin: adminAppReducers, pops: popsAppReducers, pricing: pricingAppReducers, system: systemAppReducers, techApp: techAppReducers, partners: partnersAppReducers, fleetVisitsApp: fleetVisitsAppReducers, partnersAPIApp: partnersAPIAppReducers }));
const rootReducer = (state, action) => {
    if (action.type === AuthActions.AUTH.LOGOUT.type) {
        state = undefined;
    }
    return appReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export default function configureStore(preloadedState) {
    const store = createStore(persistedReducer, preloadedState, compose(applyMiddleware(...middlewares), ...enhancers));
    const persistor = persistStore(store);
    return { store, persistor };
}
