var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, FormControl, Grid, IconButton, Input, InputAdornment, } from 'src/components/mui';
import React, { useEffect, useState } from 'react';
import { colors, theme } from 'src/styles/theme';
import styled from 'styled-components';
import { Lock, Visibility, VisibilityOff, VpnKey, } from 'src/components/mui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getSelf, logout, resetForgottenPassword, setAccessToken, } from 'src/modules/auth/actions';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import { toast } from 'src/components/SimpleToast';
import Loader from 'src/FleetVisitsApp/pages/FleetVisits/components/Loader';
import { useHistory } from 'react-router';
import { selectCurrentUserEmail } from 'src/modules/auth/selectors';
import { parseJwt } from 'src/modules/auth/utils';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 400px;
`;
const HeaderLayout = styled.div `
  width: 100%;
  background-color: ${colors.YELLOW_E1};
`;
const Wrapper = styled.div `
  width: 60%;
  margin: 0 auto;

  ${theme.breakpoints.up('md')} {
    font-size: 10px;
  }

  ${theme.breakpoints.up('md')} {
    font-size: 1rem;
  }
`;
const EmailFormControl = styled(FormControl) `
  margin-bottom: 35px;
`;
const PasswordFormControl = styled(FormControl) `
  margin-bottom: 35px;
`;
const ResetForgottenPassword = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
    const userEmail = useSelector(selectCurrentUserEmail);
    const validatePassword = () => {
        const validPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/i;
        return !password || validPassword.test(password);
    };
    const handleConfirmPassword = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!password || !confirmPassword) {
            return;
        }
        const resetForgottenPasswordPayload = {
            password,
            confirmPassword,
        };
        const resetForgottenPasswordResponse = yield dispatch(resetForgottenPassword(resetForgottenPasswordPayload));
        if (!resetForgottenPasswordResponse.error) {
            analyticsTrackEvent('Reset Forgotten Password sent', {
                'Password': resetForgottenPasswordPayload.password,
                'Confirm Password': resetForgottenPasswordPayload.confirmPassword,
            });
            toast.success('Reset password updated successfully');
            yield dispatch(logout({ history }));
        }
        else {
            toast.error(resetForgottenPasswordResponse.payload.response.message);
        }
    });
    const handleChangePassword = (e) => {
        const inputPassword = e.target.value;
        setPassword(inputPassword);
    };
    const handleChangeConfirmPassword = (e) => {
        const inputConfirmPassword = e.target.value;
        setConfirmPassword(inputConfirmPassword);
    };
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const handleShowPasswordMouseDown = (e) => {
        e.preventDefault();
    };
    useEffect(() => {
        var _a, _b, _c;
        setLoading(true);
        if (document.cookie) {
            const servusAccessToken = (_c = (_b = (_a = document.cookie) === null || _a === void 0 ? void 0 : _a.split('; ')) === null || _b === void 0 ? void 0 : _b.find((row) => row === null || row === void 0 ? void 0 : row.startsWith('servus-accessToken='))) === null || _c === void 0 ? void 0 : _c.split('=')[1];
            dispatch(setAccessToken({ accessToken: servusAccessToken }));
            if (servusAccessToken) {
                const jwt = servusAccessToken && parseJwt(servusAccessToken);
                if (jwt) {
                    dispatch(getSelf());
                }
            }
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setIsValidPassword(!validatePassword());
        setIsValidConfirmPassword(password !== confirmPassword);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, confirmPassword]);
    return (<Container>
      <HeaderLayout>
        <Wrapper>
          <h1>Reset Password</h1>
        </Wrapper>
      </HeaderLayout>
      {loading ? (<Loader />) : (<Wrapper align="center">
          <form style={{ display: 'flex', flexDirection: 'column' }}>
            <EmailFormControl>
              <Input id="reset-forgotten-password-email-id" value={userEmail} disabled placeholder="Email" type="email" startAdornment={<InputAdornment position="start">@</InputAdornment>} endAdornment={<InputAdornment position="end">
                    <IconButton disabled edge="end">
                      <Lock />
                    </IconButton>
                  </InputAdornment>}/>
            </EmailFormControl>
            <PasswordFormControl>
              <Input id="reset-forgotten-password-id" value={password} type={showPassword ? 'text' : 'password'} onChange={handleChangePassword} error={isValidPassword} placeholder="New Password (+8 character length with at least 3 character types)" startAdornment={<InputAdornment position="start">
                    <IconButton disabled edge="start">
                      <VpnKey />
                    </IconButton>
                  </InputAdornment>} endAdornment={<InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleShowPassword} onMouseDown={handleShowPasswordMouseDown} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>}/>
            </PasswordFormControl>
            <PasswordFormControl error={isValidConfirmPassword}>
              <Input id="reset-forgotten-password-confirm-id" value={confirmPassword} type={showConfirmPassword ? 'text' : 'password'} onChange={handleChangeConfirmPassword} placeholder="Confirm New Password" startAdornment={<InputAdornment position="start">
                    <IconButton disabled edge="start">
                      <VpnKey />
                    </IconButton>
                  </InputAdornment>} endAdornment={<InputAdornment position="end">
                    <IconButton aria-label="toggle confirm password visibility" onClick={handleShowConfirmPassword} onMouseDown={handleShowPasswordMouseDown} edge="end">
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>}/>
            </PasswordFormControl>
            <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid item xs={4}>
                <Button color="primary" disabled={isValidPassword || isValidConfirmPassword} fullWidth={false} variant="contained" onClick={handleConfirmPassword}>
                  Confirm password
                </Button>
              </Grid>
              <Grid item xs={8}/>
            </Grid>
          </form>
        </Wrapper>)}
    </Container>);
};
export default ResetForgottenPassword;
