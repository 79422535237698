import React from 'react';
import styled from 'styled-components';
import { Grid } from 'src/components/mui';
const StyledPre = styled('pre') `
  white-space: pre-wrap;
  word-break: keep-all;
  max-height: 1000px;
  overflow-y: scroll;
`;
const ConnectorDetails = ({ status, info, }) => {
    return (<Grid container item xs={12}>
      <Grid item xs={12} md={6}>
        <strong>Info:</strong>
        <StyledPre>{JSON.stringify(info, undefined, 2)}</StyledPre>
      </Grid>
      <Grid item xs={12} md={6}>
        <strong>Status:</strong>
        <StyledPre>{JSON.stringify(status, undefined, 2)}</StyledPre>
      </Grid>
    </Grid>);
};
export default ConnectorDetails;
