import React from 'react';
import { Apartment, Whatshot } from 'src/components/mui/icons';
import { RadioButton } from 'src/AdminApp/components/form';
import { PickupMethod } from 'src/PopsApp/models/app';
import { colors } from 'src/styles/theme';
import { InputLabel, FormControl, MenuItem, Select, CircularProgress, } from 'src/components/mui';
import { DeliveryContainer, DeliveryContainerItem } from './Delivery.styles';
import useDelivery from './useDelivery';
const labelStyle = {
    margin: '2px 0 2px 8px',
    color: colors.default,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '1.25',
    letterSpacing: '-0.3px',
};
const Delivery = ({ ispId, distributor, selectedJobs, pickupMethod, setPickupMethod, }) => {
    var _a, _b;
    const { loading, error, deliveryAddressOptions, selectedAddress, onAddressSelect, } = useDelivery({ ispId, distributor, selectedJobs, setPickupMethod });
    return (<DeliveryContainer>
      <DeliveryContainerItem>
        <RadioButton field={{
            name: 'willCal',
            value: pickupMethod,
            onChange: () => setPickupMethod(PickupMethod.WILL_CALL),
            onBlur: null,
        }} id="will_call" label="Will Call" labelStyle={labelStyle}/>
        <RadioButton field={{
            name: 'delivery',
            value: pickupMethod,
            onChange: () => setPickupMethod(PickupMethod.DELIVERY),
            onBlur: null,
        }} id="delivery" label="Delivery" labelStyle={labelStyle} disabled={!(deliveryAddressOptions === null || deliveryAddressOptions === void 0 ? void 0 : deliveryAddressOptions.hubHomeStore) &&
            (!(deliveryAddressOptions === null || deliveryAddressOptions === void 0 ? void 0 : deliveryAddressOptions.hotshotAddresses) ||
                ((_a = deliveryAddressOptions === null || deliveryAddressOptions === void 0 ? void 0 : deliveryAddressOptions.hotshotAddresses) === null || _a === void 0 ? void 0 : _a.length) === 0)}/>
      </DeliveryContainerItem>
      <DeliveryContainerItem>
        {loading && (<CircularProgress size={25} style={{ margin: '0 auto', marginTop: '10px' }}/>)}
        {!loading && pickupMethod !== PickupMethod.WILL_CALL && !error && (<FormControl variant="outlined" fullWidth style={{
                marginTop: '34px',
            }}>
            <InputLabel id="address-select-label">Pickup Location</InputLabel>
            <Select labelId="address-select-label" id="address-select" value={selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.locationId} label="Pickup Location" onChange={({ target }) => {
                onAddressSelect(target.value);
            }} MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
            }} disabled={error}>
              {(deliveryAddressOptions === null || deliveryAddressOptions === void 0 ? void 0 : deliveryAddressOptions.hubHomeStore) && (<MenuItem value={deliveryAddressOptions === null || deliveryAddressOptions === void 0 ? void 0 : deliveryAddressOptions.hubHomeStore.partDistributorLocationId} style={{
                    height: '60px',
                }}>
                  <Apartment fontSize="small" style={{ marginRight: '8px' }}/>{' '}
                  {deliveryAddressOptions === null || deliveryAddressOptions === void 0 ? void 0 : deliveryAddressOptions.hubHomeStore.partDistributorAddress}
                </MenuItem>)}
              {((_b = deliveryAddressOptions === null || deliveryAddressOptions === void 0 ? void 0 : deliveryAddressOptions.hotshotAddresses) !== null && _b !== void 0 ? _b : []).map((address) => {
                return (<MenuItem key={address.id} value={address.id} style={{
                        height: '60px',
                    }}>
                      <Whatshot htmlColor="#ED722E" fontSize="small" style={{ marginRight: '8px' }}/>{' '}
                      {`${address.street}, ${address.city}, ${address.state} ${address.zip}`}
                    </MenuItem>);
            })}
            </Select>
          </FormControl>)}
      </DeliveryContainerItem>
    </DeliveryContainer>);
};
export default Delivery;
