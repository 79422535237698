var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useSelector } from 'react-redux';
import { sendMpiReport } from 'src/AdminApp/containers/vehicle/CarModule/helpers/sendMpi';
import { selectConsumerById } from 'src/AdminApp/modules/consumers/selectors';
import { selectAdminUserInfoById } from 'src/AdminApp/modules/users/selectors';
import { selectInspectionPerformedStatus, selectMpiConsumerIdByRepairRequestId, selectMpiInspectionByRepairRequestId, selectMpiPerformedByIdByRepairRequestId, } from 'src/AdminApp/modules/mpi/selectors';
import { SimpleModal } from 'src/components/layout';
const MpiNotifyCustomerDialog = ({ open, onClose, updateInspectionStatus, repairRequestId, vehicle, suppressNotifications = false, }) => {
    const mpi = useSelector(selectMpiInspectionByRepairRequestId(repairRequestId));
    const performedById = useSelector(selectMpiPerformedByIdByRepairRequestId(repairRequestId));
    const mpiConsumerId = useSelector(selectMpiConsumerIdByRepairRequestId(repairRequestId));
    const consumer = useSelector(selectConsumerById(mpiConsumerId));
    const mpiInspector = useSelector(selectAdminUserInfoById(performedById));
    const inspectionPerformedStatus = useSelector(selectInspectionPerformedStatus);
    return (<SimpleModal open={open} onClose={onClose} title="Would you like to send the MPI to the customer?" simpleTwoButtonRowProps={{
            onCancel: onClose,
            onSubmit: () => __awaiter(void 0, void 0, void 0, function* () {
                const lastSent = (inspectionPerformedStatus === null || inspectionPerformedStatus === void 0 ? void 0 : inspectionPerformedStatus.lastSent) || null;
                if (lastSent === null) {
                    onClose();
                }
                else {
                    yield sendMpiReport({
                        mpi,
                        consumer,
                        vehicle,
                        mpiInspector,
                        resend: !!lastSent,
                        suppressNotifications: suppressNotifications !== null && suppressNotifications !== void 0 ? suppressNotifications : false,
                    });
                }
                updateInspectionStatus();
            }),
            submitText: 'Send',
        }}/>);
};
export default MpiNotifyCustomerDialog;
