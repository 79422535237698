var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useState } from 'react';
export const initialState = {
    createdTo: undefined,
    createdFrom: undefined,
    zip: '',
    id: '',
    query: '',
};
const useConsumerDashboardFilters = ({ onSearch, onClear, }) => {
    const [state, setState] = useState(initialState);
    const { createdTo, createdFrom, zip, id } = state;
    const onDateChange = (field) => (date) => {
        const newState = Object.assign(Object.assign({}, state), { [field]: date.local() });
        setState(newState);
    };
    const resetFilter = () => {
        setState(initialState);
    };
    const clearFilter = () => {
        resetFilter();
        onClear();
    };
    const handleSubmit = (_a, _b) => {
        var { query } = _a, rest = __rest(_a, ["query"]);
        var setSubmitting = _b.setSubmitting;
        const searchValues = Object.assign(Object.assign(Object.assign(Object.assign({}, (!!createdTo && { createdTo })), (!!createdFrom && { createdFrom })), (!!zip && { zip })), (!!id && { id }));
        const values = Object.assign(Object.assign({ query: query.trim() }, rest), searchValues);
        onSearch(values);
        setSubmitting(false);
    };
    return {
        state,
        createdTo,
        createdFrom,
        setState,
        onDateChange,
        resetFilter,
        clearFilter,
        handleSubmit,
    };
};
export default useConsumerDashboardFilters;
