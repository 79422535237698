import React from 'react';
import { useSelector } from 'react-redux';
import { DetailLabel, DetailValue, SpecialOrderIcon, } from 'src/AdminApp/containers/scheduling/index.styles';
import { selectRegion } from 'src/AdminApp/modules/quickRo/selectors';
import styled from 'styled-components';
const SchedulingDetailsStyled = styled.div `
  padding-top: 24px;
`;
const SchedulingDetails = ({ pricing, leadTimeDays, skills, }) => {
    var _a;
    const region = useSelector(selectRegion);
    return (<SchedulingDetailsStyled>
      <tbody>
        <tr>
          <DetailLabel>Duration</DetailLabel>
          <DetailValue>
            {(pricing === null || pricing === void 0 ? void 0 : pricing.jobDuration) ? (_a = pricing === null || pricing === void 0 ? void 0 : pricing.jobDuration) === null || _a === void 0 ? void 0 : _a.toFixed(2) : '...'}{' '}
            hrs
          </DetailValue>
        </tr>
        {leadTimeDays > 0 && (<tr>
            <DetailLabel>Lead Time</DetailLabel>
            <DetailValue>
              {leadTimeDays} days
              <span className="specialOrder">
                <SpecialOrderIcon />
                SPECIAL
              </span>
            </DetailValue>
          </tr>)}
        <tr>
          <DetailLabel>Skills</DetailLabel>
          <DetailValue>
            {skills.length > 0
            ? skills.map((skill) => skill.name).join(', ')
            : 'None'}
          </DetailValue>
        </tr>
        <tr>
          <DetailLabel>Region</DetailLabel>
          <DetailValue>{region}</DetailValue>
        </tr>
      </tbody>
    </SchedulingDetailsStyled>);
};
export default SchedulingDetails;
