import { createSelector } from 'reselect';
export const selectParts = (state) => {
    var _a;
    return (_a = state.pops) === null || _a === void 0 ? void 0 : _a.parts;
};
export const selectPopsJobCounts = createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.jobCounts) !== null && _a !== void 0 ? _a : []; });
export const selectPopsRegions = createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.regions) !== null && _a !== void 0 ? _a : []; });
export const selectPopsMetros = createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.metros) !== null && _a !== void 0 ? _a : []; });
export const selectPopsTerritories = createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.territories) !== null && _a !== void 0 ? _a : []; });
export const selectPopsComponents = createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.components) !== null && _a !== void 0 ? _a : []; });
export const selectJobs = createSelector(selectParts, (parts) => {
    if (!parts || !parts.jobs)
        return null;
    return parts.jobs.data.map((job) => {
        const repairs = [
            ...job.services.knownRepairs.map((r) => r.name),
            ...job.services.diagnoses.map((r) => r.symptoms[r.symptoms.length - 1].name),
            ...job.services.customRequests.map((r) => r.message),
        ];
        // ALPHABETIZE
        repairs.sort();
        return Object.assign(Object.assign({}, job), { repairs });
    });
});
export const selectJobsPagination = createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.jobs) === null || _a === void 0 ? void 0 : _a.pagination; });
export const selectVan = createSelector(selectParts, (parts) => parts === null || parts === void 0 ? void 0 : parts.van);
export const selectJobsStats = createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.jobsStats) !== null && _a !== void 0 ? _a : {}; });
export const selectSelectedJobs = createSelector(selectParts, (parts) => {
    var _a;
    const jobs = (_a = parts === null || parts === void 0 ? void 0 : parts.jobsSelected) !== null && _a !== void 0 ? _a : {};
    const jobsArray = [];
    Object.keys(jobs).forEach((j) => {
        jobsArray.push(jobs[j]);
    });
    return jobsArray.length > 0 ? jobsArray : null;
});
export const selectSelectedJobsObject = createSelector(selectParts, (parts) => {
    var _a;
    return (_a = parts === null || parts === void 0 ? void 0 : parts.jobsSelected) !== null && _a !== void 0 ? _a : null;
});
export const selectDistributor = createSelector(selectParts, (parts) => {
    return parts === null || parts === void 0 ? void 0 : parts.componentPartDistributor;
});
export const selectPartDistributorLocationId = createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.componentPartDistributor) === null || _a === void 0 ? void 0 : _a.partDistributorLocationId; });
export const selectOrderParts = createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.orderParts) !== null && _a !== void 0 ? _a : []; });
export const selectVendorConfirmationNumber = createSelector(selectParts, (parts) => parts.vendorConfirmationNumber);
export const selectTopPriorityOrder = createSelector(selectParts, (parts) => parts.topPriorityOrder);
export const selectOrderRosterJobs = createSelector(selectParts, (parts) => parts.orderRosterJobs);
export const selectOrderRosterJobsPagination = createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.orderRosterJobs) === null || _a === void 0 ? void 0 : _a.pagination; });
export const selectDeliveryAddressOptions = createSelector(selectParts, (parts) => parts === null || parts === void 0 ? void 0 : parts.deliveryAddressOptions);
export const selectSelectedDeliveryAddress = createSelector(selectParts, (parts) => parts === null || parts === void 0 ? void 0 : parts.selectedDeliveryAddress);
export const selectOrderRosterJobByReferenceNum = (referenceNum) => createSelector(selectParts, (parts) => { var _a; return (_a = parts === null || parts === void 0 ? void 0 : parts.byReferenceNum[referenceNum]) !== null && _a !== void 0 ? _a : {}; });
export const selectWrongPartNumbers = createSelector(selectParts, (parts) => parts === null || parts === void 0 ? void 0 : parts.wrongPart);
