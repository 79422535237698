import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const TimeSlotWrapper = styled.li `
  padding: 22px 0 16px;
  border-top: 1px solid ${colors.border};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border-bottom: 1px solid ${colors.border};
  }
`;
export const TimeSlotText = styled.div `
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: ${colors.GRAY_3F};
`;
export const SlotResource = styled.div `
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_3F};
  margin-top: 8px;
`;
