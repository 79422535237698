var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createFirebaseUser, loadConsumer, } from 'src/AdminApp/modules/consumers/actions';
import { toast } from 'src/components/SimpleToast';
const useFixFirebaseUser = () => {
    const dispatch = useDispatch();
    const [submitting, setLoading] = useState(false);
    const handleSubmit = (email, id) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const result = yield dispatch(createFirebaseUser({
            consumerId: id,
            email,
        }));
        if (!result.error) {
            toast.success('Fix firebase user request sent.');
            dispatch(loadConsumer(id));
        }
        setLoading(false);
    });
    return {
        handleSubmit,
        submitting,
    };
};
export default useFixFirebaseUser;
