import styled from 'styled-components';
import { theme, colors } from 'src/styles/theme';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
export const Title = styled.h1 `
  margin: 0;
  padding: 0;
  font-size: 24px;

  ${theme.breakpoints.up('md')} {
    margin: 8px 8px;
    font-size: 30px;
  }
`;
export const Content = styled.section `
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0 0;

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 32px 0 0;
  }
`;
export const Divider = styled.div `
  display: none;

  ${theme.breakpoints.up('md')} {
    display: flex;
    height: 230px;
    width: 1px;
    background-color: ${colors.GRAY_DE};
  }
`;
