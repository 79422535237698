import { Button, Card, CardActions, CardContent } from 'src/components/mui';
import { actionButtonBase } from 'src/components/buttons/Buttons.styles';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const StyledCard = styled(Card) `
  margin-bottom: 23px;
`;
export const StyledCardContent = styled(CardContent) `
  padding: 0;
`;
export const StyledCardActions = styled(CardActions) `
  margin-bottom: 16px;
  flex-direction: column;
`;
export const POLabel = styled.div `
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  color: ${colors.black};
  margin-bottom: 16px;
`;
export const PartsInfo = styled.div `
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${colors.darkGrey};
`;
export const ReturnButton = styled(Button) `
  ${actionButtonBase};
  margin-bottom: 16px;
`;
export const CardTitle = styled.div `
  border-bottom: 1px solid ${colors.GRAY_DE};
  padding: 16px;
  margin-bottom: 16px;
`;
export const DetailsTable = styled.table `
  width: 100%;

  td {
    width: 50%;
    padding: 0 16px;
    vertical-align: top;
  }
`;
export const InfoLabel = styled.div `
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${colors.darkGrey};
  margin-bottom: 13px;
`;
export const InfoValue = styled.div `
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${colors.GRAY_3F};
  margin-bottom: 24px;
`;
