import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import { selectRepairs } from 'src/AdminApp/modules/quickRo/selectors';
import { ActionCreators } from 'src/AdminApp/modules/quickRo/actions';
import RepairItem from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/pages/services/components/RepairItem';
import SearchService from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/pages/services/components/SearchService';
const AddServices = ({ showItems }) => {
    const dispatch = useDispatch();
    const repairs = useSelector(selectRepairs);
    const handleSelect = (repair) => {
        dispatch(ActionCreators.TAB.ADD_REPAIR({ payload: repair }));
    };
    return (<div>
      <SearchService onSelect={handleSelect}/>

      {showItems && (<div>
          {map(repairs, (repair) => (<RepairItem key={repair.id} repair={repair}/>))}
        </div>)}
    </div>);
};
export default AddServices;
