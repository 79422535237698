import { Formik } from 'formik';
import React from 'react';
import { SlidePageContent, SlidePageWrapper, } from 'src/AdminApp/components/discounts/components/DiscountDrawer/styledComponents';
import TextField from 'src/AdminApp/components/form/TextField';
import { FooterActionButton, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const PromoCodePage = ({ onDrawerClose, onSubmit }) => {
    const validationSchema = Yup.object().shape({
        promoCode: Yup.string().required(),
    });
    return (<Formik initialValues={{
            promoCode: '',
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
            setSubmitting(false);
            onDrawerClose();
        }}>
      {({ isSubmitting, isValid }) => (<SlidePageWrapper>
          <SlidePageContent>
            <TextField fullWidth name="promoCode" type="test" label="Promo Code" variant="outlined"/>
          </SlidePageContent>
          <SimpleDrawerFooter>
            <FooterActionButton type="submit" disabled={isSubmitting || !isValid}>
              Add
            </FooterActionButton>
          </SimpleDrawerFooter>
        </SlidePageWrapper>)}
    </Formik>);
};
export default PromoCodePage;
