import { useSelector } from 'react-redux';
import { selectAccessToken as selectRsAccessToken } from 'src/modules/auth/selectors';
import { selectAccessToken as selectNsAccessToken } from './modules/selectors';
export const NETSUITE_API_URL = '/netsuite';
export const useNetSuiteApiClient = () => {
    const rsAccessToken = useSelector(selectRsAccessToken);
    const nsAccessToken = useSelector(selectNsAccessToken);
    const _createRsHeaders = (headers = {}) => {
        return Object.assign({ Authorization: `Bearer ${rsAccessToken}` }, headers);
    };
    const _createNsHeaders = (headers = {}) => {
        return _createRsHeaders(Object.assign({ 'Accept': 'application/json', 'Content-Type': 'application/json', 'rs-ns-access-token': nsAccessToken }, headers));
    };
    const doGet = (path) => {
        return fetch(NETSUITE_API_URL + path, {
            headers: _createNsHeaders(),
        }).then((response) => {
            if (!response.ok)
                throw new Error(`${response.status} - ${response.statusText}`);
            return response.json();
        });
    };
    const doPost = (path, body, headers = {}) => {
        return fetch(NETSUITE_API_URL + path, {
            method: 'POST',
            headers: _createNsHeaders(headers),
            body: JSON.stringify(body),
        }).then((response) => {
            if (!response.ok)
                throw new Error(`${response.status} - ${response.statusText}`);
            const contentType = response.headers.get('content-type');
            if (!contentType) {
                return null;
            }
            return response.json();
        });
    };
    const doPatch = (path, body, headers = {}) => {
        return fetch(NETSUITE_API_URL + path, {
            method: 'PATCH',
            headers: _createNsHeaders(headers),
            body: JSON.stringify(body),
        }).then((response) => {
            if (!response.ok)
                throw new Error(`${response.status} - ${response.statusText}`);
            const contentType = response.headers.get('content-type');
            if (!contentType) {
                return null;
            }
            return response.json();
        });
    };
    const authenticate = () => {
        return fetch(`${NETSUITE_API_URL}/auth`, {
            method: 'POST',
            headers: _createRsHeaders(),
        });
    };
    const fetchCustomerById = (id) => {
        return doGet(`/record/v1/customer/${id}`);
    };
    const fetchInventoryItemById = (id, expandSubResources = false) => {
        return doGet(`/record/v1/inventoryItem/${id}?expandSubResources=${expandSubResources}`);
    };
    const fetchCustomers = () => {
        return doGet('/record/v1/customer/');
    };
    const fetchPurchaseOrders = () => {
        return doGet('/record/v1/purchaseOrder/');
    };
    const fetchPurchaseOrdersV2 = (queryParams = '') => {
        let query = "SELECT * from transaction where type like 'PurchOrd'";
        if (queryParams)
            query += ` and tranid like '%${queryParams}%'`;
        return doPost('/query/v1/suiteql', {
            q: query,
        }, { prefer: 'transient' });
    };
    const fetchActiveVendors = () => {
        return doPost('/query/v1/suiteql', {
            q: "SELECT id, companyname as refName from vendor where isInactive = 'F' and companyname is not empty",
        }, { prefer: 'transient' });
    };
    const fetchActiveLocations = () => {
        return doPost('/query/v1/suiteql', {
            q: "SELECT id, fullname as refName from location where isInactive = 'F' and fullname is not empty",
        }, { prefer: 'transient' });
    };
    const fetchActiveClasses = () => {
        return doPost('/query/v1/suiteql', {
            q: "SELECT id, fullname as refName from classification where isInactive = 'F' and fullname is not empty",
        }, { prefer: 'transient' });
    };
    const fetchActiveInvItems = () => {
        return doPost('/query/v1/suiteql', {
            q: "SELECT * from item where itemtype = 'InvtPart' and isInactive = 'F'",
        }, { prefer: 'transient' });
    };
    const fetchPurchaseOrdersById = (id, expandSubResources = false) => {
        return doGet(`/record/v1/purchaseOrder/${id}?expandSubResources=${expandSubResources}`);
    };
    const createPO = (body) => {
        return doPost('/record/v1/purchaseOrder/', body);
    };
    const updatePo = (body, id) => {
        return doPatch(`/record/v1/purchaseOrder/${id}`, body);
    };
    const receivePO = (id, body) => {
        return doPost(`/record/v1/purchaseOrder/${id}/!transform/itemReceipt`, body);
    };
    return {
        authenticate,
        fetchCustomerById,
        fetchInventoryItemById,
        fetchCustomers,
        fetchPurchaseOrders,
        fetchPurchaseOrdersById,
        fetchPurchaseOrdersV2,
        fetchActiveVendors,
        fetchActiveLocations,
        fetchActiveClasses,
        fetchActiveInvItems,
        createPO,
        receivePO,
        updatePo,
    };
};
