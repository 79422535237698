import { createSelector } from 'reselect';
export const selectDashboard = (itemsSelector) => createSelector(itemsSelector, (items) => { var _a; return (_a = items.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardContent = (itemsSelector) => createSelector(selectDashboard(itemsSelector), (dashboard) => { var _a; return (_a = dashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectDashboardPagination = (itemsSelector) => createSelector(selectDashboard(itemsSelector), (dashboard) => {
    var _a;
    return (_a = dashboard.pagination) !== null && _a !== void 0 ? _a : {};
});
export const selectDashboardParams = (itemsSelector) => createSelector(selectDashboard(itemsSelector), (dashboard) => { var _a; return (_a = dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardFetching = (itemsSelector) => createSelector(selectDashboard(itemsSelector), (dashboard) => { var _a; return (_a = dashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
export const selectItemById = (id, itemsSelector) => createSelector(itemsSelector, (items) => {
    var _a;
    const repair = items.byId[id];
    if (!repair) {
        return;
    }
    repair.repairComponents = (_a = repair === null || repair === void 0 ? void 0 : repair.repairComponents) === null || _a === void 0 ? void 0 : _a.map((rc, idx) => {
        return Object.assign(Object.assign({}, rc), { metaId: idx });
    });
    return repair;
});
