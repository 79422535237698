var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOM_REPAIR_ID } from 'src/AdminApp/modules/api/constants';
import { selectRequestDataByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { setRepairs } from 'src/AdminApp/modules/quickRo/actions';
import { processIneligibleForService, processVehicleConditionTooPoor, completeProcessVehicleRequiresAddtlRepairs, returnIsLongerThanGracePeriod, addRecommendedRepairsByRefNumber, addRepair, addInternalRepairsByRefNumber, } from 'src/AdminApp/modules/requests/actions';
import CONFIG from 'src/config';
import { removeRepairsFromQuote, removeDeliveryFeeFromQuote, } from 'src/AdminApp/modules/subscription/actions';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { CANT_FINISH_STEPS } from '../CantFinishMenuController';
const useCantFinishDrawer = ({ open, onClose, referenceNum, requestUntilJobComplete, openScheduleAllRecommendRepairs, openCantFinishScreenForMeasurements, workOrderId, }) => {
    var _a, _b, _c, _d, _e, _f;
    const minimumDeliveryChargeId = CONFIG.MINIMUM_DELIVERY_CHARGE_ID;
    const dispatch = useDispatch();
    const [isLongerThanGracePeriod, setIsLongerThanGracePeriod] = useState(false);
    const recommendedRepairs = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const knownRepairs = Array.from(((_b = (_a = recommendedRepairs.diagnosis) === null || _a === void 0 ? void 0 : _a.diagnosisServices) === null || _b === void 0 ? void 0 : _b.knownRepairs) || []);
    const removedRepairs = Array.from(((_d = (_c = recommendedRepairs.diagnosis) === null || _c === void 0 ? void 0 : _c.diagnosisServices) === null || _d === void 0 ? void 0 : _d.removedRepairs) || []);
    const customRequests = Array.from(((_f = (_e = recommendedRepairs.diagnosis) === null || _e === void 0 ? void 0 : _e.diagnosisServices) === null || _f === void 0 ? void 0 : _f.customRequests) || []);
    const allRepairs = [knownRepairs, removedRepairs, customRequests];
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (open) {
                const result = yield prIsLongerThanNoChargeGracePeriod({
                    referenceNum,
                });
                setIsLongerThanGracePeriod((_a = result === null || result === void 0 ? void 0 : result.payload) === null || _a === void 0 ? void 0 : _a.isLongerThanNoChargeGracePeriod);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    const prIsLongerThanNoChargeGracePeriod = ({ referenceNum, }) => __awaiter(void 0, void 0, void 0, function* () {
        return dispatch(returnIsLongerThanGracePeriod({
            referenceNum,
        }));
    });
    const onCallIneligibleOrConditionPoorOrAddtlRepairNoOption = (referenceNum, cantFinishOptionChosen) => __awaiter(void 0, void 0, void 0, function* () {
        yield ineligibleForServiceAndVehicleConditionTooPoorCalls({
            referenceNum,
            cantFinishOptionChosen,
        });
    });
    const ineligibleForServiceAndVehicleConditionTooPoorCalls = ({ referenceNum, cantFinishOptionChosen, }) => __awaiter(void 0, void 0, void 0, function* () {
        if (cantFinishOptionChosen === 'INELIGIBLE_FOR_SERVICE') {
            yield prIneligibleForService({
                referenceNum,
            });
        }
        else if (cantFinishOptionChosen === 'VEHICLE_CONDITION_POOR') {
            yield prVehicleConditionTooPoor({ referenceNum });
        }
        else if (cantFinishOptionChosen === 'VEHICLE_REQUIRES_ADDTL_REPAIRS') {
            const quoteRepairs = [];
            const recommendedRepairs = [];
            const isLongerThanGracePeriod = false;
            const customerAgreed = false;
            yield prVehicleRequiresAddtlRepairs({
                referenceNum,
                isLongerThanGracePeriod,
                customerAgreed,
                quoteRepairs,
                recommendedRepairs,
            });
        }
        requestUntilJobComplete();
        dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    });
    const onCallVehicleRequiresAddtlRepairsCalls = (referenceNum, cantFinishOptionChosen, isLongerThanGracePeriod) => __awaiter(void 0, void 0, void 0, function* () {
        yield vehicleRequiresAddtlRepairsCalls({
            referenceNum,
            isLongerThanGracePeriod,
        });
        yield dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    });
    const vehicleRequiresAddtlRepairsCalls = ({ referenceNum, isLongerThanGracePeriod, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h, _j, _k, _l;
        const customerAgreed = true;
        const addedRepairToBeQuoted = [...repairsAddedOnAddServices];
        const editServicesToBeQuoted = [...repairsOnEditServices];
        const resp = yield prVehicleRequiresAddtlRepairs({
            referenceNum,
            isLongerThanGracePeriod,
            customerAgreed,
            quoteRepairs: addedRepairToBeQuoted,
            recommendedRepairs: editServicesToBeQuoted,
        });
        onClose();
        if (((_j = (_h = (_g = resp === null || resp === void 0 ? void 0 : resp.payload) === null || _g === void 0 ? void 0 : _g.recommendedServices) === null || _h === void 0 ? void 0 : _h.recommendedRepairs) === null || _j === void 0 ? void 0 : _j.length) !== 0) {
            if (((_l = (_k = resp === null || resp === void 0 ? void 0 : resp.payload) === null || _k === void 0 ? void 0 : _k.repairMeasurements) === null || _l === void 0 ? void 0 : _l.length) === 0 &&
                !hasNullAttributes(resp.payload.recommendedServices.recommendedRepairs)) {
                yield requestUntilJobComplete();
                openScheduleAllRecommendRepairs(resp.payload.recommendedServices.recommendedRepairs, resp.payload.repairRequest);
            }
            else {
                yield requestUntilJobComplete();
                setTimeout(() => {
                    openCantFinishScreenForMeasurements();
                }, 3000);
            }
        }
        else {
            yield requestUntilJobComplete();
        }
    });
    const hasNullAttributes = (recommendedRepairs) => {
        return recommendedRepairs.some((repair) => {
            return (repair.laborTime === null ||
                repair.laborRate === null ||
                repair.laborCost === null ||
                repair.totalCost === null);
        });
    };
    const prIneligibleForService = ({ referenceNum, }) => __awaiter(void 0, void 0, void 0, function* () {
        return dispatch(processIneligibleForService({
            referenceNum,
            internalApprovalProvidedById: undefined,
        }));
    });
    const prVehicleConditionTooPoor = ({ referenceNum, }) => __awaiter(void 0, void 0, void 0, function* () {
        return dispatch(processVehicleConditionTooPoor({
            referenceNum,
        }));
    });
    const prVehicleRequiresAddtlRepairs = ({ referenceNum, isLongerThanGracePeriod, customerAgreed, quoteRepairs, recommendedRepairs, }) => __awaiter(void 0, void 0, void 0, function* () {
        const cantFinishRepairs = {
            quoteRepairs: quoteRepairs.map((result) => ({
                repairId: result.repairId,
                repair: result.label,
                customRepairText: result.repairId === CUSTOM_REPAIR_ID
                    ? result.customRepairText
                    : undefined,
            })),
            recommendedRepairs: recommendedRepairs.map((result) => ({
                repairId: result.repairId === CUSTOM_REPAIR_ID ? undefined : result.repairId,
                repair: result.label,
                customRepairText: result.customRepairText,
                inspectionDetails: {
                    additionalInfo: '',
                },
            })),
        };
        yield dispatch(removeRepairsFromQuote(referenceNum));
        if (!customerAgreed) {
            yield dispatch(addRepair({
                referenceNum,
                repairId: minimumDeliveryChargeId,
                internalApprovalProvidedById: undefined,
                isValidateLaborCapacity: false,
            }));
        }
        else {
            if (isLongerThanGracePeriod) {
                yield dispatch(addInternalRepairsByRefNumber({
                    referenceNum,
                    body: cantFinishRepairs.quoteRepairs,
                }));
            }
            else {
                yield dispatch(removeDeliveryFeeFromQuote(referenceNum));
            }
            if (cantFinishRepairs.recommendedRepairs.length >= 1) {
                yield dispatch(addRecommendedRepairsByRefNumber({
                    body: {
                        origin: 'TOOLS',
                        recommendedRepairs: cantFinishRepairs.recommendedRepairs,
                    },
                    referenceNum,
                }));
            }
        }
        return dispatch(completeProcessVehicleRequiresAddtlRepairs({
            referenceNum,
        }));
    });
    const [repairsAddedOnAddServices, setRepairsAddedOnAddServices] = useState([]);
    const addRepairOnRepairsAddedOnAddServices = (repairsAdd) => {
        setRepairsAddedOnAddServices(repairsAdd);
    };
    const [repairsOnEditServices, setRepairsOnEditServices] = useState([]);
    const addRepairOnRepairsEditServices = (repairsAdd) => {
        setRepairsOnEditServices(repairsAdd);
    };
    const [currentStep, setCurrentStep] = useState(CANT_FINISH_STEPS[0]);
    const sendToIntroAndClose = () => {
        onClose();
        changeStep(0);
        dispatch(setRepairs([]));
    };
    const sendToTodayReturnVisit = () => {
        changeStep(7);
    };
    const sendToAddService = () => {
        changeStep(8);
    };
    const sendToEditReturn = () => {
        changeStep(9);
    };
    const changeStep = (stepName) => {
        setCurrentStep(CANT_FINISH_STEPS[stepName]);
    };
    const handleNextStep = () => {
        changeStep(currentStep.next);
    };
    return {
        currentStep,
        handleNextStep,
        sendToIntroAndClose,
        sendToTodayReturnVisit,
        sendToAddService,
        sendToEditReturn,
        onCallIneligibleOrConditionPoorOrAddtlRepairNoOption,
        onCallVehicleRequiresAddtlRepairsCalls,
        addRepairOnRepairsAddedOnAddServices,
        isLongerThanGracePeriod,
        repairsAddedOnAddServices,
        repairsOnEditServices,
        addRepairOnRepairsEditServices,
        allRepairs,
        changeStep,
        ineligibleForServiceAndVehicleConditionTooPoorCalls,
        prIneligibleForService,
        vehicleRequiresAddtlRepairsCalls,
        prVehicleRequiresAddtlRepairs,
    };
};
export default useCantFinishDrawer;
