import React, { useState } from 'react';
import { FormControlLabel, Switch } from 'src/components/mui';
import { PartnersTableContainer, PartnersTH } from './PartnersTable.styles';
import PartnersTableRow from './PartnersTableRow';
const PartnersTable = ({ partners, onFunnelEdit, onPartnerEdit, onActiveSwitch, }) => {
    const [showActive, setShowActive] = useState(true);
    const handleActiveSwitch = (e, active) => {
        onActiveSwitch(active !== null && active !== void 0 ? active : !showActive);
        setShowActive(active !== null && active !== void 0 ? active : !showActive);
    };
    return (<PartnersTableContainer>
      <thead>
        <tr>
          <PartnersTH>Partner Name</PartnersTH>
          <PartnersTH>Admin</PartnersTH>
          <PartnersTH>Last Updated</PartnersTH>
          <PartnersTH>&nbsp;</PartnersTH>
          <PartnersTH>Funnel Status</PartnersTH>
          <PartnersTH colSpan={3}>
            <FormControlLabel checked={showActive} onChange={handleActiveSwitch} control={<Switch color="primary" name="active"/>} label="Active"/>
          </PartnersTH>
        </tr>
      </thead>
      <tbody>
        {partners.map((partner) => {
            return (<PartnersTableRow key={partner.id} partner={partner} onFunnelEdit={onFunnelEdit} onPartnerEdit={onPartnerEdit} switchActive={handleActiveSwitch}/>);
        })}
      </tbody>
    </PartnersTableContainer>);
};
export default PartnersTable;
