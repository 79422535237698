var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import CONFIG from 'src/config';
import { selectServiceDrawer } from 'src/AdminApp/modules/requests/selectors';
import { selectCurrentUserIsTechnician } from 'src/AdminApp/modules/users/selectors';
import { formatAdditionalInfo } from 'src/AdminApp/components/repairs/statement/utils';
import { getPartDistributors } from 'src/PopsApp/modules/vendors/actions';
import { selectAuthorizedSystemAdmin } from 'src/modules/auth/selectors';
import { selectPartDistributors } from 'src/PopsApp/modules/vendors/selectors';
const validMetadata = (partMetadata, onlyCheckForEmpty = false) => {
    const allEmpty = Object.keys(partMetadata).every((key) => partMetadata[key] === '');
    if (allEmpty && !onlyCheckForEmpty)
        return true;
    const allNotEmpty = Object.keys(partMetadata).every((key) => partMetadata[key] !== '');
    if (allNotEmpty)
        return true;
    return false;
};
const useEditPartDrawer = ({ open, name, componentPartDistributor, onSubmit, adding, invoice, status, isShowAdditionalInfo = false, }) => {
    const dispatch = useDispatch();
    const [isConfirmQtyModalOpen, setIsConfirmQtyModalOpen] = useState(false);
    const [hasMetadataInfo, setHasMetadataInfo] = useState(false);
    const [tab, setTab] = useState('general');
    const [isAddPartToPickupDialogOpen, setIsAddPartToPickupDialogOpen] = useState(false);
    const [dynamicCreationConfirm, setDynamicCreationConfirm] = useState(false);
    const [idComponentDataCorrect, setIdComponentDataCorrect] = useState(false);
    const [confirmRemoveDynamicRule, setConfirmRemoveDynamicRule] = useState(false);
    const [isTireSelected, setIsTireSelected] = useState(false);
    const userIsInTechnicianGroup = useSelector(selectCurrentUserIsTechnician);
    const canEditComponentDetails = adding || !userIsInTechnicianGroup;
    const serviceDrawerState = useSelector(selectServiceDrawer);
    const forceComponentSelection = CONFIG.ENABLE_FORCE_COMPONENT_DB_SELECTION === 'true';
    const [formValues, setFormValues] = useState();
    const onAddPartToPickupConfirm = (addToPartPickup) => {
        setIsAddPartToPickupDialogOpen(false);
        handleSubmit(formatAdditionalInfo(formValues === null || formValues === void 0 ? void 0 : formValues.values), formValues === null || formValues === void 0 ? void 0 : formValues.resetForm, formValues === null || formValues === void 0 ? void 0 : formValues.setSubmitting, addToPartPickup);
    };
    const onConfirmDynamicCreation = () => {
        setDynamicCreationConfirm(true);
    };
    const onConfirmDataCorrectness = () => {
        setDynamicCreationConfirm(false);
        setIdComponentDataCorrect(true);
    };
    const handleCloseDialogs = () => {
        setDynamicCreationConfirm(false);
        setIdComponentDataCorrect(false);
        setConfirmRemoveDynamicRule(false);
    };
    const handleSubmitWithoutDynamicCreation = (values, resetForm, setSubmitting) => {
        handleCloseDialogs();
        const updatedValues = Object.assign(Object.assign({}, values), { addAsDynamicComponent: false });
        handleSubmitWithDrawer(updatedValues, resetForm, setSubmitting);
    };
    const listOfLockedDownComponents = CONFIG.LOCKED_DOWN_COMPONENTS;
    const isSystemAdmin = useSelector(selectAuthorizedSystemAdmin);
    const partDistributors = useSelector(selectPartDistributors);
    const isTireComponent = (name === null || name === void 0 ? void 0 : name.toLowerCase()) === 'tire';
    const PartSchema = Yup.object().shape({
        name: Yup.string().min(1).max(255).required(),
        quantity: Yup.number().positive().required(),
        price: serviceDrawerState.adding && invoice === undefined
            ? Yup.number().min(0).nullable()
            : Yup.number().min(0).required(),
        cost: Yup.number().min(0).required(),
        partNumber: Yup.string().min(1).max(255).nullable(),
        notes: Yup.string().min(1).max(255).nullable(),
        specialOrder: Yup.boolean().nullable(),
        leadTime: Yup.number().when('specialOrder', (specialOrder, PartSchema) => {
            return specialOrder
                ? PartSchema.min(0)
                : PartSchema.nullable().notRequired();
        }),
        partDistributorId: Yup.string().nullable(),
        partDistributorLocationId: Yup.string().nullable(),
        partDistributor: Yup.string().min(1).max(255).nullable(),
        partDistributorName: Yup.string()
            .min(1)
            .max(255)
            .when('partDistributor', (partDistributor, PartSchema) => {
            return partDistributor === 'Other'
                ? PartSchema.required('Other vendor name required')
                : PartSchema.nullable().notRequired();
        }),
        partMetadata: isTireComponent && hasMetadataInfo
            ? Yup.object().shape({
                manufacturer: Yup.string().required(),
                model: Yup.string().min(1).max(100).required(),
                aspectRatio: Yup.number().min(0).integer().required(),
                rimDiameter: Yup.number()
                    .test('maxDigitsAfterDecimal', 'rimDiameter field must have 1 digits after decimal or less', (number) => /^\d{0,3}(?:\.\d)?$/.test(number))
                    .required(),
                loadRange: Yup.string().max(3).required(),
                speedRating: Yup.string().max(3).required(),
                size: Yup.string().min(6).max(40).required(),
                position: Yup.string().required(),
                width: Yup.number().integer().required(),
            })
            : Yup.object().nullable(),
        address: Yup.string().min(1).max(255).nullable(),
        addAsDynamicComponent: Yup.boolean().nullable().notRequired(),
        coreChargeExpected: Yup.boolean().nullable(),
        coreChargeCost: Yup.number().when('coreChargeExpected', {
            is: true,
            then: Yup.number().min(1).required(),
            otherwise: Yup.number().nullable().notRequired(),
        }),
        partSpecialistLevel: Yup.number().min(0).nullable(),
        automaticallyOrder: Yup.boolean().nullable(),
        skynetMinimumMargin: Yup.number().min(0).nullable(),
        pcdbId: Yup.number().min(0).nullable(),
    });
    const getPartDistributor = useCallback(() => {
        if (componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.id) {
            return {
                id: componentPartDistributor.id,
                name: componentPartDistributor.partDistributorName,
                status: '',
            };
        }
        return null;
    }, [componentPartDistributor]);
    const onAddressSelect = (address, setFieldValue) => {
        if (!address) {
            setFieldValue('address', '');
            setFieldValue('notes', '');
            return;
        }
        setFieldValue('address', address.addressStr);
        if (address === null || address === void 0 ? void 0 : address.partDistributorLocation) {
            setFieldValue('partDistributorLocationId', address.id);
        }
        else {
            setFieldValue('partDistributorLocationId', undefined);
        }
        if (address === null || address === void 0 ? void 0 : address.dealershipName) {
            setFieldValue('partDistributorDealershipName', address.dealershipName);
        }
    };
    const selectPart = (setValues) => (part) => {
        setValues({
            name: part.name,
            quantity: part.quantity,
            price: part.unitPrice,
            cost: part.unitCost,
            partNumber: part.partNumber,
            componentId: part.componentId,
            pcdbId: part.pcdbId,
            coreChargeExpected: part.coreChargeExpected,
            partSpecialistLevel: part.partSpecialistLevel,
            automaticallyOrder: part.automaticallyOrder,
            skynetMinimumMargin: part.skynetMinimumMargin,
        });
    };
    const handleTabChange = (e, newValue) => setTab(newValue);
    const handleSubmit = (values, resetForm, setSubmitting, addToPartPickup = false) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        yield onSubmit(Object.assign(Object.assign({}, values), { partMetadata: isTireComponent && validMetadata((_a = values === null || values === void 0 ? void 0 : values.partMetadata) !== null && _a !== void 0 ? _a : {}, true)
                ? values.partMetadata
                : null, specialOrder: !!values.specialOrder, leadTime: values.specialOrder ? values.leadTime : null, addToPartPickup }));
        resetForm({ values: Object.assign({}, values) });
        setSubmitting(false);
    });
    const isAboutToBeOrderedFromNewLocation = (values, componentPartDistributor) => {
        const newLocationId = !values.partDistributorLocationId
            ? null
            : values.partDistributorLocationId;
        return ((newLocationId || values.address) &&
            values.partNumber &&
            (status !== 'ORDERED' ||
                newLocationId !== (componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.partDistributorLocationId) ||
                (!newLocationId &&
                    values.address !== (componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.address))));
    };
    const handleSubmitWithDrawer = (values, resetForm, setSubmitting) => {
        setDynamicCreationConfirm(false);
        setIdComponentDataCorrect(false);
        const formattedValues = formatAdditionalInfo(values);
        const isNotInvoice = invoice === undefined;
        const isAboutToBeOrdered = isAboutToBeOrderedFromNewLocation(values, componentPartDistributor);
        if (isAboutToBeOrdered && isNotInvoice) {
            setIsAddPartToPickupDialogOpen(true);
            setFormValues({ values, resetForm, setSubmitting });
        }
        else {
            setSubmitting(true);
            handleSubmit(formattedValues, resetForm, setSubmitting);
        }
    };
    useEffect(() => {
        if (open && !partDistributors) {
            dispatch(getPartDistributors());
        }
        return () => {
            setTab('general');
            setHasMetadataInfo(false);
        };
    }, [open]); // eslint-disable-line
    useEffect(() => {
        if (isShowAdditionalInfo) {
            setTab('additional');
        }
    }, [isShowAdditionalInfo]);
    return {
        isTireComponent,
        tab,
        handleTabChange,
        getPartDistributor,
        PartSchema,
        selectPart,
        onAddressSelect,
        setIsConfirmQtyModalOpen,
        isConfirmQtyModalOpen,
        setHasMetadataInfo,
        validMetadata,
        isSystemAdmin,
        listOfLockedDownComponents,
        handleSubmitWithDrawer,
        isAddPartToPickupDialogOpen,
        setIsAddPartToPickupDialogOpen,
        onAddPartToPickupConfirm,
        onConfirmDynamicCreation,
        dynamicCreationConfirm,
        onConfirmDataCorrectness,
        idComponentDataCorrect,
        handleSubmitWithoutDynamicCreation,
        handleCloseDialogs,
        confirmRemoveDynamicRule,
        setConfirmRemoveDynamicRule,
        canEditComponentDetails,
        forceComponentSelection,
        serviceDrawerState,
        setIsTireSelected,
        isTireSelected,
    };
};
export default useEditPartDrawer;
