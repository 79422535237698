var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { Grid, Switch, Typography } from 'src/components/mui';
import NotAuthorized from 'src/components/layout/NotAuthorized';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { PartSpecialistLevel } from 'src/PricingApp/models/enums/PartSpecialistLevel';
import FormInput from 'src/PricingApp/components/forms/FormInput';
import FormDivider from 'src/PricingApp/components/forms/FormDivider';
import FormSelect from 'src/PricingApp/components/forms/FormSelect';
import useAddComponentForm from 'src/PricingApp/components/components/AddComponentForm/useAddComponentForm';
import { addComponentContextValidation } from './validations';
const Toggle = styled(Switch) `
  transform: translateX(-12px);
`;
const AddComponentForm = ({ componentId }) => {
    const { getComponentTypes, onComponentTypeChange, getUnitsByComponentType, isPricingAdmin, component, onSaveOrUpdateComponent, isFluid, } = useAddComponentForm({ componentId });
    const handleReset = (resetForm, validateForm) => () => __awaiter(void 0, void 0, void 0, function* () {
        yield resetForm();
        validateForm();
    });
    return isPricingAdmin ? (<Formik enableReinitialize initialValues={component} validateOnMount validate={addComponentContextValidation} onSubmit={onSaveOrUpdateComponent}>
      {({ resetForm, values, isValid, isSubmitting, setFieldValue, validateForm, }) => {
            var _a;
            return (<Form>
          <Grid container style={{ marginTop: 5, maxWidth: 1300 }}>
            <Grid item container xs={12} lg={8} spacing={2}>
              <Grid item>
                <Typography variant="h6">
                  {values.id ? 'Edit' : 'Add'} Component
                </Typography>
              </Grid>
              <FormInput placeholder="autogenerated" label="Component ID" valueName="id" disabled variant="standard"/>
              <FormInput placeholder="example: Oil Filter" label="Component Name" valueName="name" variant="standard"/>
              <FormInput label="Component Type">
                <FormSelect options={getComponentTypes()} value={values.type} setValue={(newValue) => onComponentTypeChange(setFieldValue, newValue)}/>
              </FormInput>
              <FormInput label="Unit Type">
                <FormSelect options={getUnitsByComponentType(values)} value={values.unit} setValue={(newValue) => setFieldValue('unit', newValue)}/>
              </FormInput>
              <FormInput placeholder="example: 12138" label="PCDB id" valueName="pcdbId" type="number" variant="standard"/>
              <FormInput label="Order Automatically">
                <Toggle checked={values.automaticallyOrder} color="primary" name="automaticallyOrder" onChange={(e) => setFieldValue('automaticallyOrder', e.target.checked)}/>
              </FormInput>
              <FormInput label="Core Charge Expected">
                <Toggle checked={values.coreChargeExpected} color="primary" name="coreChargeExpected" onChange={(e) => setFieldValue('coreChargeExpected', e.target.checked)}/>
              </FormInput>
              <FormInput label="Part Specialist">
                <FormSelect options={Object.keys(PartSpecialistLevel).filter((v) => isNaN(Number(v)))} value={(_a = PartSpecialistLevel[values.partSpecialistLevel]) !== null && _a !== void 0 ? _a : 1} setValue={(newValue) => {
                    var _a;
                    return setFieldValue('partSpecialistLevel', (_a = PartSpecialistLevel[newValue]) !== null && _a !== void 0 ? _a : 1);
                }}/>
              </FormInput>
              <FormInput placeholder="example: 50" label="Skynet Minimum Margin (%)" valueName="skynetMinimumMargin" type="number" variant="standard"/>

              <FormDivider />

              {isFluid(values.type) && (<>
                  <FormInput placeholder="example: Synthetic" label="Fluid Type" valueName="metadata.fluidType" variant="standard"/>
                  <FormInput placeholder="example: 11033" label="Motor taxonomy id" valueName="catalogComponentDetails.externalId" type="number" variant="standard"/>
                  <FormDivider />
                </>)}

              <Grid item container style={{ justifyContent: 'flex-end' }}>
                <Grid item>
                  <SimpleTwoButtonRow submitText={`${values.id ? 'Update' : 'Add'} Component`} cancelText="Reset Form" disabledCancel={isSubmitting} disabledSubmit={isSubmitting || !isValid} onCancel={handleReset(resetForm, validateForm)}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>);
        }}
    </Formik>) : (<NotAuthorized targetRole="Pricing_Admin"/>);
};
export default AddComponentForm;
