var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsJobInfoLoading, setJobCancellationStatus, } from 'src/AdminApp/modules/swimlanes/actions';
import { selectJobCancellationStatus } from 'src/AdminApp/modules/swimlanes/selectors';
import { getVisit } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { selectFleetVisit } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
const useVisitJobInfo = ({ selectedJobs, referenceNum, jobIdx, }) => {
    const dispatch = useDispatch();
    const visit = useSelector(selectFleetVisit);
    const [loading, setLoading] = useState(true);
    const [openVisitDrawer, setOpenVisitDrawer] = useState(false);
    const jobCancellationStatus = useSelector(selectJobCancellationStatus);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const job = selectedJobs ? selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0] : undefined;
            if ((job && ((_a = job === null || job === void 0 ? void 0 : job.repairJob) === null || _a === void 0 ? void 0 : _a.type) === 'VISIT') || referenceNum) {
                const response = yield dispatch(getVisit({
                    refNum: referenceNum !== null && referenceNum !== void 0 ? referenceNum : selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.referenceNum,
                }));
                setLoading(false);
                if (response.error) {
                    throw new Error(`Visit Not Found: ${referenceNum !== null && referenceNum !== void 0 ? referenceNum : job === null || job === void 0 ? void 0 : job.repairJob.referenceNum}`);
                }
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedJobs, jobIdx, referenceNum]);
    useEffect(() => {
        if (jobCancellationStatus &&
            jobCancellationStatus.id === selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].id &&
            jobCancellationStatus.status === 'IN_PROGRESS') {
            setOpenVisitDrawer(true);
        }
        return () => setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobCancellationStatus]);
    useEffect(() => {
        if (!loading) {
            dispatch(setIsJobInfoLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    const handleClose = () => {
        dispatch(setJobCancellationStatus({
            id: selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].id,
            status: 'CLOSED',
        }));
        setOpenVisitDrawer(false);
    };
    return {
        referenceNum,
        loading,
        visit,
        openVisitDrawer,
        handleClose,
        jobCancellationStatus,
    };
};
export default useVisitJobInfo;
