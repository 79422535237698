import React, { useState } from 'react';
import { MobileEditFieldDrawer } from 'src/AdminApp/containers/vehicle/CarModule/MobileEditFieldDrawer';
import { Grid, InputAdornment, TextField } from 'src/components/mui';
const MilesFieldEdit = (props) => {
    const [mileage, setMileage] = useState(props.values.mileage);
    const [mileageOut, setMileageOut] = useState(props.values.mileageOut);
    return (<MobileEditFieldDrawer {...props} label="Mileage" onSave={() => props.onSave({ mileage, mileageOut })}>
      <Grid item xs={12}>
        <TextField variant="outlined" value={mileage} onChange={({ target }) => setMileage(parseInt(target.value, 10))} label="Miles In" type="text" name="mileage" fullWidth InputProps={{
            endAdornment: <InputAdornment position="end">miles</InputAdornment>,
        }}/>
      </Grid>

      <Grid item xs={12}>
        <TextField variant="outlined" value={mileageOut} onChange={({ target }) => setMileageOut(parseInt(target.value, 10))} label="Miles Out" type="text" name="mileageOut" fullWidth InputProps={{
            endAdornment: <InputAdornment position="end">miles</InputAdornment>,
        }}/>
      </Grid>
    </MobileEditFieldDrawer>);
};
export default MilesFieldEdit;
