import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import EditableSearchUserEmails from 'src/components/form/EditableSearchUserEmails';
import { selectManagers } from 'src/AdminApp/modules/users/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getManagers } from 'src/AdminApp/modules/users/actions';
import { SimpleModal } from 'src/components/layout';
const ApprovalProviderSchema = Yup.object().shape({
    internalApprovalProvidedById: Yup.number().positive().integer().required(),
});
const SelectInternalApprovalProviderFormModal = ({ open, onSubmit, onClose, multipleRepairs = false, }) => {
    const dispatch = useDispatch();
    const managers = useSelector(selectManagers);
    useEffect(() => {
        if (!managers || managers.length === 0) {
            dispatch(getManagers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<SimpleModal open={open} onClose={onClose} title="Select Approval Provider" maxWidth="md">
      <Formik validateOnMount initialValues={{
            internalApprovalProvidedById: '',
        }} validationSchema={ApprovalProviderSchema} onSubmit={(values) => onSubmit(parseInt(values.internalApprovalProvidedById, 10))}>
        {({ values, setFieldValue, isSubmitting, isValid }) => {
            return (<Form>
              <div>
                {multipleRepairs
                    ? 'One or more of the selected repairs require '
                    : 'This repair requires '}
                explicit approval from an Operations Manager, please indicate
                who has approved this:
              </div>
              <div>
                <EditableSearchUserEmails value={values.internalApprovalProvidedById} usersSelector={selectManagers} onUpdate={(value) => {
                    setFieldValue('internalApprovalProvidedById', value.id);
                }} requireId autoFocus/>
              </div>
              <SimpleTwoButtonRow onCancel={onClose} disabledCancel={isSubmitting} disabledSubmit={isSubmitting || !isValid}/>
            </Form>);
        }}
      </Formik>
    </SimpleModal>);
};
export default SelectInternalApprovalProviderFormModal;
