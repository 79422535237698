import { colors } from 'src/styles/theme';
import styled from 'styled-components';
const InfoText = styled.div `
  margin: 10px 0;
  color: #aaa;
  span {
    color: ${colors.red};
  }
`;
export default InfoText;
