import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
import { Icon } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { Info } from 'src/components/mui/icons';
export const NotesTable = styled.table `
  width: 100%;
  margin-top: 26px;
  border-collapse: collapse;

  .jobCheckbox,
  .checkAll {
    padding: 0;
  }

  th {
    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid ${colors.GRAY_DE};
    padding-bottom: 16px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
  }

  td {
    padding: 24px 3px;
    border-bottom: 1px solid ${colors.GRAY_DE};
    width: 1%;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_3F};
  }

  tr:last-child td {
    border-bottom: none;
  }

  label {
    font-size: 14px;
    font-weight: 500;
  }

  .repairsList {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .link {
    text-decoration: none;
    color: ${colors.primary};
  }
`;
export const chipColors = {
    EXTERNAL: colors.success,
    INTERNAL: colors.ORANGE_FF,
    PRIVATE: colors.iconGrey,
};
export const TypeIcon = styled(Icon) `
  color: ${(props) => { var _a; return (_a = props.color) !== null && _a !== void 0 ? _a : colors.darkGrey; }};
`;
export const TypeTooltip = styled(Tooltip) `
  display: flex;
  align-items: center;
  &.tooltip {
    background-color: ${colors.black} !important;
    color: red;
  }
`;
export const AppliesToIcon = styled(Info) `
  color: ${colors.black};
  font-size: 18px;
`;
export const TooltipContent = styled('div') `
  display: flex;
  align-items: center;
`;
export const TooltipRepairsWrapper = styled('div') `
  margin: 8px 0px;
`;
export const TooltipTitle = styled('span') `
  font-weight: bold;
  display: flex;
`;
export const TooltipTextContainer = styled('div') `
  white-space: pre-line;
  font-size: 11px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;
export const NotesList = styled.ul `
  padding-left: 0;
  list-style: none;

  ${theme.breakpoints.down('sm')} {
    margin-bottom: 80px;
  }
`;
export const NoteItemRoot = styled.li `
  border-radius: 4px;
  border: solid 1px ${colors.GRAY_DE};
  padding: 26px 16px 24px;
  margin-top: 16px;
`;
export const NoteContainer = styled.section `
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${theme.breakpoints.down('sm')} {
    align-items: center;
  }

  .jobCheckbox {
    padding: 0;
    margin-left: 8px;
  }
`;
export const NoteContainerInfo = styled.label.attrs((props) => ({
    htmlFor: props.htmlFor,
})) `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: ${colors.black};
`;
export const NoteDetails = styled.section `
  display: flex;
  justify-content: stretch;
  padding: 14px 0;
  margin: 14px 0;
  border-top: 1px solid ${colors.GRAY_DE};
  border-bottom: 1px solid ${colors.GRAY_DE};
`;
export const Note = styled.section `
  padding-top: 14px;
`;
export const NoteDetailsItem = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;
export const NoteLabel = styled.div `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
`;
export const NoteValue = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.3px;
  color: ${colors.black};
  margin-top: 6px;
  display: flex;
  flex-direction: column;
`;
export const NoteInfo = styled.div `
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.3px;
  color: ${colors.GRAY_76};
  margin-top: 6px;
  display: flex;
  align-items: center;
  white-space: break-spaces;
`;
export const LoaderWrapper = styled('div') `
  text-align: center;
`;
export const NoteSeeMoreContent = styled('div') `
  display: block;

  .line-break {
    white-space: pre-wrap;
  }
`;
