var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPartnerAssets, uploadPartnerAssets, } from 'src/PartnersApp/modules/assets/actions';
const useUploader = ({ partnerId }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleDrop = (assets) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield dispatch(uploadPartnerAssets({ partnerId, assets }));
        yield dispatch(getPartnerAssets({ partnerId }));
        setLoading(false);
    });
    return {
        loading,
        handleDrop,
    };
};
export default useUploader;
