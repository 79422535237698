import React from 'react';
import { SimpleModal } from 'src/components/layout';
const ArchiveConfirmationDialog = ({ open, onClose, onArchiveAction, }) => {
    return (<SimpleModal open={open} onClose={onClose} title="Are you sure you’d like to archive this Partner?" simpleTwoButtonRowProps={{
            onCancel: onClose,
            onSubmit: onArchiveAction,
            cancelText: 'Cancel',
            submitText: 'Archive',
            submitIsDestructive: true,
        }}/>);
};
export default ArchiveConfirmationDialog;
