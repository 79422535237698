import React from 'react';
import styled from 'styled-components';
import { MeshLoaderIcon } from 'src/components/icons';
import { STATIC_URL_BASE } from 'src/styles/theme';
const LoaderWrapper = styled.div `
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  #logo-loader {
    position: absolute;
    animation: spin 2s ease-in-out infinite;
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
const Loader = () => {
    return (<LoaderWrapper>
      <MeshLoaderIcon id="logo-loader"/>
      <img src={`${STATIC_URL_BASE}img/svg/ms-logo.svg`} alt="AutoNation Mobile Service logo"/>
    </LoaderWrapper>);
};
export default Loader;
