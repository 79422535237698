import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { Button, Accordion, Popover } from 'src/components/mui';
export const RoundedButton = styled(Button) `
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: ${colors.border};
  margin-left: 24px;
  border: solid 2px ${colors.GRAY_3F};

  span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: #3f3f3f;
  }
`;
export const StyledAccordion = styled(Accordion) `
  box-shadow: none;

  &::before {
    height: 0px;
  }

  .accordion-summary {
    min-height: auto;
    padding: 0px 0px 0px 16px;

    > :first-child {
      margin: 0;
    }
  }

  .accordion-detail {
    padding: 0;

    &.sub {
      display: flex;
      flex-direction: column;
    }

    &.regions {
      padding: 0px 16px;
    }
  }
`;
export const StyledPopover = styled(Popover) `
  padding: 16px;

  > :nth-child(3) {
    z-index: 9999;
    padding: 16px;
    max-height: 700px;
    overflow-y: auto;
  }
`;
