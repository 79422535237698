import React from 'react';
import { ActionButton, DestructiveButton } from 'src/components/buttons';
const LunchBreakActions = ({ lunchBreak, isCanceled, isLoading, endTime, isMobile, setIsModalOpen, updateLunchBreakStatus, goToDashBoardHandler, }) => {
    if ((lunchBreak && lunchBreak.actualStartTime && lunchBreak.actualEndTime) ||
        isCanceled) {
        return (<ActionButton onClick={goToDashBoardHandler} fullWidth={isMobile} disabled={isLoading}>
        Back
      </ActionButton>);
    }
    if (lunchBreak && lunchBreak.actualStartTime) {
        return (<ActionButton onClick={() => {
                updateLunchBreakStatus('COMPLETED');
            }} fullWidth={isMobile} disabled={isLoading || !endTime || isCanceled}>
        End Lunch
      </ActionButton>);
    }
    return (<>
      <ActionButton onClick={() => {
            updateLunchBreakStatus('PENDING');
        }} fullWidth={isMobile} disabled={isLoading || isCanceled}>
        Start Lunch
      </ActionButton>
      <DestructiveButton onClick={() => {
            setIsModalOpen(true);
        }} fullWidth={isMobile} disabled={isLoading || isCanceled}>
        Cancel Lunch
      </DestructiveButton>
    </>);
};
export default LunchBreakActions;
