import { createSelector } from 'reselect';
export const selectAdminPaymentDrawerState = (state) => state.admin.paymentDrawer;
export const selectPaymentDrawerInvoice = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.invoice);
export const selectPaymentDrawerScreen = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.screen);
export const selectPaymentDrawerTab = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.tab);
export const selectPaymentDrawerAmount = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.amount);
export const selectPaymentDrawerBalanceOwed = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.balanceOwed);
export const selectPaymentDrawerPaymentsReceivedTotal = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.paymentsReceivedTotal);
export const selectPaymentDrawerPaymentType = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.paymentType);
export const selectPaymentDrawerPaymentSubtype = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.paymentSubtype);
export const selectPaymentDrawerWorkOrderResponsibleParty = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.workOrderResponsibleParty);
export const selectPaymentDrawerWorkOrderId = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.workOrderId);
export const selectPaymentDrawerWorkOrderPayments = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.workOrderPayments);
export const selectPaymentDrawerTotalInvoicePrice = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.totalInvoicePrice);
export const selectPaymentDrawerResponsibleParties = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.responsibleParties);
export const selectPaymentDrawerHasResponsibleParties = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.hasResponsibleParties);
export const selectPaymentDrawerWorkOrderResponsibleParties = createSelector(selectAdminPaymentDrawerState, (drawer) => drawer === null || drawer === void 0 ? void 0 : drawer.workOrderResponsibleParties);
