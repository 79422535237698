import React from 'react';
import styled from 'styled-components';
import { Typography } from 'src/components/mui';
import { Speed } from 'src/components/mui/icons';
import { flexCenter } from 'src/styles/styled';
import { colors } from 'src/styles/theme';
import { getSquareMakeLogo } from 'src/AdminApp/containers/vehicle/utils';
const RowContainer = styled('div') `
  --item-margin-right: 8px;

  ${flexCenter};

  justify-content: flex-start;
  min-height: 50px;
`;
const RowItem = styled('div') `
  ${flexCenter};

  justify-content: inherit;
  width: auto;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`;
const Text = styled(Typography) `
  font-weight: 500;
`;
const Title = styled(Typography) `
  color: ${colors.iconGrey};
  margin-right: var(--item-margin-right);
`;
const Logo = styled('img') `
  --img-size: 24px;
  margin-right: var(--item-margin-right);
  max-height: var(--img-size);
  max-width: var(--img-size);
`;
const SpeedIcon = styled(Speed) `
  color: ${colors.iconGrey};
  margin-right: var(--item-margin-right);
`;
const FleetVehicleRow = ({ vehicle }) => {
    return (<RowContainer>
      <RowItem>
        <Logo alt={vehicle === null || vehicle === void 0 ? void 0 : vehicle.make} src={getSquareMakeLogo(vehicle === null || vehicle === void 0 ? void 0 : vehicle.make, '@3x')}/>
        <Text variant="body1">
          {`${vehicle === null || vehicle === void 0 ? void 0 : vehicle.year} ${vehicle === null || vehicle === void 0 ? void 0 : vehicle.make} ${vehicle === null || vehicle === void 0 ? void 0 : vehicle.model}`}
        </Text>
      </RowItem>
      {(vehicle === null || vehicle === void 0 ? void 0 : vehicle.plateNumber) && (<RowItem>
          <Title variant="subtitle1">LIC</Title>
          <Text variant="body1">{vehicle.plateNumber}</Text>
        </RowItem>)}
      {(vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin) && (<RowItem>
          <Title variant="subtitle1">VIN</Title>
          <Text variant="body1">{vehicle.vin}</Text>
        </RowItem>)}
      <RowItem>
        <SpeedIcon />
        <Text variant="body1">{vehicle === null || vehicle === void 0 ? void 0 : vehicle.mileage}</Text>
      </RowItem>
    </RowContainer>);
};
export default FleetVehicleRow;
