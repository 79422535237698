import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from 'src/modules/auth/actions';
const Logout = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(logout({ history }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <div>Logging out...</div>;
};
export default Logout;
