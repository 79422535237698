import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { capitalize } from 'lodash';
import { Typography } from 'src/components/mui';
import { selectConsumerMethods } from 'src/AdminApp/modules/payments/selectors';
import { Card } from 'src/AdminApp/containers/payments/PaymentDrawer/SavedCards';
const CardsContainer = styled.div `
  margin-top: 16px;
`;
const SavedPaymentMethodsContainer = styled.div `
  margin-top: 64px;
  margin-bottom: 64px;
`;
const SavedPaymentMethods = ({ onRemovePaymentMethod, }) => {
    const { referenceNum } = useParams();
    const params = { match: { params: { referenceNum } } };
    const methods = useSelector((state) => selectConsumerMethods(state, params));
    if (!methods.length) {
        return null;
    }
    const handleRemovePaymentMethod = (card) => {
        const label = `${capitalize(card === null || card === void 0 ? void 0 : card.cardType)}····${card === null || card === void 0 ? void 0 : card.lastFourDigits}`;
        onRemovePaymentMethod(card.id, label);
    };
    return (<SavedPaymentMethodsContainer>
      <Typography variant="subtitle2">Saved Payment Methods</Typography>
      <CardsContainer>
        {methods.map((method) => (<Card canRemove card={method} key={method.id} onRemove={handleRemovePaymentMethod}/>))}
      </CardsContainer>
    </SavedPaymentMethodsContainer>);
};
export default SavedPaymentMethods;
