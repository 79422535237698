var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Cancel as CancelIcon } from 'src/components/mui/icons';
import React from 'react';
import { StyledChip } from 'src/components/SimpleChip/SimpleChip.styles';
const SimpleChip = (_a) => {
    var { onDelete, size = 'small', $bgColor, $textColor } = _a, rest = __rest(_a, ["onDelete", "size", "$bgColor", "$textColor"]);
    return (<StyledChip {...rest} $bgColor={$bgColor} $textColor={$textColor} size={size} onDelete={onDelete !== null && onDelete !== void 0 ? onDelete : undefined} deleteIcon={onDelete ? <CancelIcon /> : undefined}/>);
};
export default SimpleChip;
