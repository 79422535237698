import styled from 'styled-components';
import { InfoRounded as InfoRoundedIcon } from 'src/components/mui/icons';
import { Tooltip } from 'src/components/tooltips';
import { colors } from 'src/styles/theme';
export const InfoSign = styled(InfoRoundedIcon) `
  width: 20px;
  height: 20px;

  fill: ${({ $infoColor }) => $infoColor !== null && $infoColor !== void 0 ? $infoColor : colors.GRAY_DE};
  color: ${({ $infoColor }) => $infoColor !== null && $infoColor !== void 0 ? $infoColor : colors.GRAY_DE};
  margin: ${({ $outerMargin }) => $outerMargin !== null && $outerMargin !== void 0 ? $outerMargin : '0 10px'};
  cursor: pointer;

  &.tooltipOpened {
    fill: ${({ $infoColor }) => $infoColor !== null && $infoColor !== void 0 ? $infoColor : colors.BLACK_ICON};
    color: ${({ $infoColor }) => $infoColor !== null && $infoColor !== void 0 ? $infoColor : colors.BLACK_ICON};
  }
`;
export const VisitTooltip = Tooltip;
export const TooltipContent = styled.div `
  background-color: ${colors.black};
  padding: 8px 8px;
  border-radius: 8px;

  color: white;
  font-size: 14px;
  line-height: 1.29;
  max-width: 312px;
  margin-bottom: 0;
  pointer-events: none;

  ::before {
    content: '';
    position: absolute;
    display: block;
    width: 0px;
    left: 50%;
    bottom: 24px;
    border: 15px solid transparent;
    border-bottom: 0;
    border-top: 8px solid ${colors.black};
    transform: translate(-50%, calc(100% + 5px));
  }

  .tooltipTitle {
    margin: 0 0 8px;
    font-weight: 700;
  }
`;
export const TooltipContentDesktop = styled.div `
  background-color: ${colors.black_ICON};
  padding: 8px 8px;
  border-radius: 8px;

  color: white;
  font-size: 14px;
  line-height: 1.29;
  max-width: ${(props) => { var _a; return (_a = props.contentWidth) !== null && _a !== void 0 ? _a : '312px'; }};
  margin-bottom: 0;
  pointer-events: none;

  .tooltipTitle {
    margin: 0 0 8px;
    font-weight: 700;
  }
`;
