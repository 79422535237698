import React from 'react';
import { Typography } from 'src/components/mui';
import { getJobSize, getPosition } from '../utils';
import { UnavailabilityContainer } from './ChartJob.styles';
const Unavailability = ({ template, timezone, selectedDate, }) => {
    const jobPositionX = getPosition(template.start, selectedDate, timezone);
    const jobSizePixels = getJobSize(template.start, template.finish, timezone);
    return (<UnavailabilityContainer position={jobPositionX} width={jobSizePixels}>
      {template.type && <Typography>{template.type}</Typography>}
    </UnavailabilityContainer>);
};
export default Unavailability;
