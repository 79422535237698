export var ResponsiblePartyTaxType;
(function (ResponsiblePartyTaxType) {
    ResponsiblePartyTaxType["PRICE"] = "Price";
    ResponsiblePartyTaxType["EXEMPT"] = "Exempt";
    ResponsiblePartyTaxType["COST"] = "Cost";
})(ResponsiblePartyTaxType || (ResponsiblePartyTaxType = {}));
export var ResponsiblePartyTypeVisibility;
(function (ResponsiblePartyTypeVisibility) {
    ResponsiblePartyTypeVisibility["CONSUMER"] = "CONSUMER";
    ResponsiblePartyTypeVisibility["FLEET"] = "FLEET";
})(ResponsiblePartyTypeVisibility || (ResponsiblePartyTypeVisibility = {}));
