import React from 'react';
import { theme } from 'src/styles/theme';
import { useMediaQuery } from 'src/components/mui';
import DatePicker from './DatePicker/DatePicker';
import MobileDatePicker from './DatePicker/MobileDatePicker';
import MobileDashboard from './MobileDashboard/MobileDashboard';
import Statuses from './Statuses/Statuses';
import Chart from './Chart/Chart';
import SwimlanesDashboardContext, { useSwimlanesDashboardContext, } from './SwimlanesDashboardContext';
const SwimlanesDashboard = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const context = useSwimlanesDashboardContext();
    return isMobile ? (<>
      <MobileDatePicker />
      <MobileDashboard />
    </>) : (<SwimlanesDashboardContext.Provider value={context}>
      <DatePicker />
      <Statuses />
      <Chart />
    </SwimlanesDashboardContext.Provider>);
};
export default SwimlanesDashboard;
