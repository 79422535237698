var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid } from 'src/components/mui';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { Formik } from 'formik';
import { isNull } from 'lodash';
import moment from 'moment';
import { DATE_TIME_FORMAT, LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ServiceAreaSelector } from 'src/AdminApp/components/deliveryVan/DeliveryVanManagementDrawer/ServiceAreaSelector';
import TextField from 'src/AdminApp/components/form/TextField';
import AppliesTo from 'src/AdminApp/models/enums/AppliesTo';
import { getSkillsByFilter } from 'src/AdminApp/modules/skills/actions';
import SkillSearchMultiSelect from 'src/components/form/SkillSearchMultiSelect';
import VehicleTypeSearch from 'src/components/form/VehicleTypeSearch';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
    namedAfter: Yup.string().required(),
    skeduloResourceId: Yup.string().when('createNewSkeduloResource', {
        is: false,
        then: Yup.string().required('Skedulo Resource Id is required.'),
        otherwise: Yup.string().nullable(),
    }),
    type: Yup.string().required(),
    vehicleNumber: Yup.string().required(),
    tags: Yup.array().min(1),
});
const DeliveryVanPage = ({ model, onSubmit, disableSave, }) => {
    const dispatch = useDispatch();
    const [availableSkills, setAvailableSkills] = useState([]);
    useEffect(() => {
        dispatch(getSkillsByFilter({ applies_to: AppliesTo.VAN })).then((response) => setAvailableSkills(response.payload));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getVehicleName = ({ namedAfter, vehicleNumber, type, }) => {
        return `${namedAfter || ''} - ${vehicleNumber || ''} ${type || ''}`;
    };
    return (<Formik initialValues={{
            id: model.id,
            ispId: model.ispId,
            regionId: model.regionId,
            name: model.name,
            namedAfter: model.namedAfter,
            skeduloResourceId: model.skeduloResourceId,
            createNewSkeduloResource: false,
            type: model.type,
            vehicleNumber: model.vehicleNumber,
            vin: model.vin,
            launchDate: model.launchDate,
            skills: model.skills || [],
            deliveryVanServiceAreas: model.deliveryVanServiceAreas || [],
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            values.name = getVehicleName(values);
            try {
                yield onSubmit(values);
            }
            finally {
                setSubmitting(false);
            }
        })}>
      {({ values, isSubmitting, isValid, setFieldValue, submitForm }) => (<>
          <SimpleDrawerContent>
            <Grid container spacing={2}>
              {values.id && (<Grid item xs={12}>
                  <h3>Delivery Van Status: </h3>
                </Grid>)}
              <Grid item xs={12}>
                <TextField fullWidth inputType="text" name="name" label="Delivery Van Name" value={getVehicleName(values)} type="text" variant="outlined" disabled/>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth inputType="text" name="namedAfter" label="Named After (Employee Name)" value={values.namedAfter} type="text" variant="outlined"/>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth inputType="text" name="skeduloResourceId" label="Skedulo Resource ID" value={values.skeduloResourceId} type="text" variant="outlined" disabled={values.createNewSkeduloResource ||
                (!!values.id && !!values.skeduloResourceId)}/>
                <LabelledCheckbox label="Create New Skedulo Resource" checkboxProps={{
                checked: values.createNewSkeduloResource,
                onChange: (_, value) => {
                    if (value)
                        setFieldValue('skeduloResourceId', '');
                    setFieldValue('createNewSkeduloResource', !!value);
                },
                name: 'createNewSkeduloResource',
            }}/>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <VehicleTypeSearch fullWidth value={values.type} type="text" name="region" label="Vehicle Type" variant="outlined" onUpdate={(value) => {
                setFieldValue('type', value);
            }}/>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth inputType="text" name="vehicleNumber" label="Vehicle Number" value={values.vehicleNumber} type="text" variant="outlined" disabled={!!values.id && !!values.vehicleNumber}/>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth inputType="text" name="vin" label="Vehicle VIN" value={values.vin} type="text" variant="outlined"/>
              </Grid>
              <Grid item xs={12}>
                <SimpleDatePicker fullWidth name="launchDate" label="Vehicle Launch Date" slotProps={{
                textField: { variant: 'outlined' },
                field: { clearable: true },
            }} format={LOCALIZED_DATE_FORMAT} value={values.launchDate ? moment(values.launchDate) : null} onChange={(value) => {
                setFieldValue('launchDate', !isNull(value)
                    ? moment(value).startOf('day').format(DATE_TIME_FORMAT)
                    : null);
            }}/>
              </Grid>
              <Grid item xs={12}>
                <SkillSearchMultiSelect options={availableSkills} selectedOptions={values.skills || []} name="tags" label="Skills" onUpdate={(value) => setFieldValue('skills', value)} unremovableOptions={[{ name: 'Van' }]}/>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h3>Service Areas: </h3>
            </Grid>
            <ServiceAreaSelector selectedServiceAreas={values.deliveryVanServiceAreas} minDate={values.launchDate} onUpdate={(serviceAreas) => setFieldValue('deliveryVanServiceAreas', serviceAreas)}/>
          </SimpleDrawerContent>
          <SimpleDrawerFooter>
            <FooterActionButton onClick={submitForm} disabled={isSubmitting || !isValid || disableSave}>
              {model && model.id ? 'Save Delivery Van' : 'Create Delivery Van'}
            </FooterActionButton>
          </SimpleDrawerFooter>
        </>)}
    </Formik>);
};
export default DeliveryVanPage;
