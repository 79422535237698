import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
import { AccordionDetails, Button } from 'src/components/mui';
export const EditContent = styled.section `
  padding: 22px 22px 100px 22px;
`;
export const EditFooter = styled.div `
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding: 16px 25px;
  text-align: center;
  box-shadow: inset 0 1px 0 0 ${colors.GRAY_DE};
  background-image: linear-gradient(
    to bottom,
    ${colors.white},
    ${colors.GRAY_F2} 75%
  );
  display: flex;
  justify-content: space-around;

  & button:last-child {
    width: 40%;
  }

  ${theme.breakpoints.up('md')} {
    width: 60vw;
  }
`;
export const AccordionUserDetails = styled(AccordionDetails) `
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 15px;
  }
`;
export const UserProfileEditWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
export const UserProfileEditSecWrapper = styled.div `
  display: flex;
  margin-bottom: 30px;
  padding-top: 20px;

  & > div {
    padding: 0 25px;

    & > img {
      width: 170px;
      height: 170px;
      border-radius: 50%;
      margin: auto;
      display: block;
    }
  }
`;
export const UserProfileEditRow = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  & > div {
    width: 48%;
    & svg {
      color: rgba(63, 63, 63, 0.26);
      width: 22px;
      cursor: pointer;
      height: 22px;
      margin: 0 5px 0 -6px;
      -webkit-tap-highlight-color: transparent;
    }
  }
`;
export const UserProfileEditFullRow = styled.div `
  display: flex;
  justify-content: space-between;
  & > div {
    width: 100%;
  }
`;
export const UserProfilePictureWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
`;
export const UserProfilePictureContainer = styled.div `
  & input {
    display: none;
  }
`;
export const WrapperBoxPhoto = styled.label `
  display: block;
  margin-bottom: 10px;
  vertical-align: top;
  position: relative;

  & img {
    width: 200px;
    height: 200px;
    clip-path: circle();

    &:hover {
      cursor: pointer;
    }
  }
`;
export const UploadPictureButton = styled(Button) `
  display: flex;
  justify-content: center;
  align-content: center;
  color: ${colors.BLUE};
  background-color: transparent;
  width: 170px;
  height: 170px;
  border: 1px solid ${colors.BLUE};
  border-radius: 50%;
  box-shadow: none;
  &:hover {
    background-color: ${colors.white};
  }
  & > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
  }
`;
export const CheckboxWrapper = styled.div `
  margin-top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  .checkboxSubtitle {
    font-size: 12px;
  }
`;
