var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable no-await-in-loop */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { QuotedPriceMode } from 'src/AdminApp/models/enums';
import { PartsOrderMethod } from 'src/AdminApp/models/enums/PartsOrderMethod';
import { formatAdditionalInfo } from 'src/AdminApp/components/repairs/statement/utils';
import { useMediaQuery } from 'src/components/mui';
import { toast } from 'src/components/SimpleToast';
import { formatWithCents } from 'src/modules/api/utils/format';
import { PickupMethod } from 'src/PopsApp/models/app';
import { addCustomPart, addPart, completeIntegratedPartsOrder, completeOrderParts, editCustomPart, editPart, getComponentsMarkUp, getJobsByVan, getPartOrderStatus, getPartPurchaseOrder, getSameDayJobs, getWrongPartNumbersByComponent, removeComponent, setOrderParts, setSelectedJobsForOrdering, setVendorConfirmationNumber, updateFitment, } from 'src/PopsApp/modules/parts/actions';
import { selectDistributor, selectOrderParts, selectPartDistributorLocationId, selectSelectedDeliveryAddress, selectSelectedJobs, selectSelectedJobsObject, selectTopPriorityOrder, selectVan, selectVendorConfirmationNumber, selectWrongPartNumbers, } from 'src/PopsApp/modules/parts/selectors';
import { theme } from 'src/styles/theme';
import { selectComponentsAvailability } from '../../modules/vendors/selectors';
export const partFields = [
    'id',
    'address',
    'cost',
    'name',
    'notes',
    'price',
    'quantity',
    'partNumber',
    'specialOrder',
    'leadTime',
    'catalogSource',
    'catalogPartNumber',
    'catalogPartName',
    'partManufacturer',
    'partMetadata',
    'componentId',
    'pricingData',
    'coreChargeCost',
    'coreChargeExpected',
    'additionalInfo',
    'createCore',
];
export const fluidFields = [
    'id',
    'address',
    'capacity',
    'capacityUnit',
    'cost',
    'name',
    'notes',
    'partNumber',
    'price',
    'type',
    'specialOrder',
    'leadTime',
    'componentId',
    'pricingData',
    'coreChargeExpected',
    'additionalInfo',
    'createCore',
];
export const filterOutFields = (part, fields) => {
    const filtered = {};
    Object.keys(part).forEach((k) => {
        if (fields.includes(k)) {
            filtered[k] = part[k];
            if (k === 'price') {
                filtered[k] = +part[k].toString().replace(/(,| )/g, '');
            }
        }
    });
    return filtered;
};
function asyncDelay(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}
const isAvailableForIntegration = (componentsAvailability, part) => {
    var _a, _b;
    return !!((_a = componentsAvailability === null || componentsAvailability === void 0 ? void 0 : componentsAvailability.availableRepairComponentIds) === null || _a === void 0 ? void 0 : _a.includes((_b = part.pricingData) === null || _b === void 0 ? void 0 : _b.repairComponentId));
};
export const getPartsList = (selectedJobs, orderParts, componentsAvailability) => {
    const newParts = [];
    selectedJobs.forEach((job) => {
        const { partsStatus, referenceNum, repairOrderId, repairRequestId, skeduloJobId, services, car, } = job;
        const strippedJob = {
            partsStatus,
            referenceNum,
            repairOrderId,
            repairRequestId,
            skeduloJobId,
            car,
        };
        services.knownRepairs.forEach((repair) => {
            const { id: repairId, name } = repair;
            const strippedRepair = {
                id: repairId,
                name,
            };
            repair.price.forEach((price) => {
                const strippedPrice = Object.assign({}, price);
                delete strippedPrice.fluids;
                delete strippedPrice.parts;
                price.parts.forEach((part) => {
                    newParts.push(Object.assign(Object.assign({}, part), { nodeId: repair.nodeId, meta: {
                            id: `${repairOrderId}-${repairId}-${part.id}`,
                            isFluid: false,
                            isCustom: false,
                            job: strippedJob,
                            repair: strippedRepair,
                            price: strippedPrice,
                            minPartsCost: price.minPartsCost,
                            maxPartsCost: price.maxPartsCost,
                        }, isIntegrated: isAvailableForIntegration(componentsAvailability, part), isSelected: false }));
                });
                price.fluids.forEach((fluid) => {
                    newParts.push(Object.assign(Object.assign({}, fluid), { nodeId: repair.nodeId, meta: {
                            id: `${repairOrderId}-${repairId}-${fluid.id}`,
                            isFluid: true,
                            isCustom: false,
                            job: strippedJob,
                            repair: strippedRepair,
                            price: strippedPrice,
                            minFluidsCost: price.minFluidsCost,
                            maxFluidsCost: price.maxFluidsCost,
                        }, isIntegrated: isAvailableForIntegration(componentsAvailability, fluid), isSelected: false }));
                });
            });
        });
        services.customRequests.forEach((repair) => {
            const { id: repairId, message } = repair;
            const strippedRepair = {
                id: repairId,
                name: message,
            };
            const isIntegrationEnabled = isAvailableForIntegration(componentsAvailability, repair);
            repair.price.forEach((price) => {
                const strippedPrice = Object.assign({}, price);
                delete strippedPrice.fluids;
                delete strippedPrice.parts;
                price.parts.forEach((part) => {
                    newParts.push(Object.assign(Object.assign({}, part), { meta: {
                            id: `${repairOrderId}-${repairId}-${part.id}`,
                            isFluid: false,
                            isCustom: true,
                            job: strippedJob,
                            repair: strippedRepair,
                            price: strippedPrice,
                            minPartsCost: price.minPartsCost,
                            maxPartsCost: price.maxPartsCost,
                        }, isIntegrated: isIntegrationEnabled, isSelected: false }));
                });
                price.fluids.forEach((fluid) => {
                    newParts.push(Object.assign(Object.assign({}, fluid), { meta: {
                            id: `${repairOrderId}-${repairId}-${fluid.id}`,
                            isFluid: true,
                            isCustom: true,
                            job: strippedJob,
                            repair: strippedRepair,
                            price: strippedPrice,
                            minFluidsCost: price.minFluidsCost,
                            maxFluidsCost: price.maxFluidsCost,
                        }, isIntegrated: isIntegrationEnabled, isSelected: false }));
                });
            });
        });
    });
    orderParts.forEach((order) => {
        var _a;
        if (order.isFitment) {
            const partId = order.isFluid
                ? order.part.fluidId
                : order.part.partId;
            const part = newParts.find((p) => { var _a; return ((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.job.referenceNum) === order.referenceNum && p.id === partId; });
            const fitment = (_a = part === null || part === void 0 ? void 0 : part.fitments) === null || _a === void 0 ? void 0 : _a.find((f) => f.id === order.part.id);
            if (!fitment)
                return;
            fitment.status = 'ADDED';
            return;
        }
        const part = newParts.find((p) => {
            var _a;
            return ((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.job.referenceNum) === order.referenceNum &&
                p.id === order.part.id;
        });
        if (part === null || part === void 0 ? void 0 : part.meta) {
            part.meta.isAdded = true;
        }
    });
    return newParts;
};
const useOrder = ({ isPrioritized = false, isQuickOrder = false, ispId, region, }) => {
    var _a;
    const [parts, setParts] = useState([]);
    const [dialogSettings, setDialogSettings] = useState(undefined);
    const [openCompleteDrawer, setOpenCompleteDrawer] = useState(false);
    const [openMergeDrawer, setOpenMergeDrawer] = useState(false);
    const [completingOrder, setCompletingOrder] = useState(false);
    const [addPartMode, setAddPartMode] = useState(false);
    const [newComponentType, setNewComponentType] = useState();
    const [newComponent, setNewComponent] = useState({});
    const [newComponentAdded, setNewComponentAdded] = useState(false);
    const [pickupMethod, setPickupMethod] = useState(PickupMethod.WILL_CALL);
    const [vendorOpen, setVendorOpen] = useState(false);
    const [partsOpen, setPartsOpen] = useState(true);
    const [openPartsDialog, setOpenPartsDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [markupsMap, setMarkups] = useState();
    const history = useHistory();
    const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
    const selectedJobs = useSelector(selectSelectedJobs);
    const orderParts = useSelector(selectOrderParts);
    const jobsObject = useSelector(selectSelectedJobsObject);
    const distributor = useSelector(selectDistributor);
    const vendorConfirmationNumber = useSelector(selectVendorConfirmationNumber);
    const partDistributorLocationId = useSelector(selectPartDistributorLocationId);
    const topPriorityOrder = useSelector(selectTopPriorityOrder);
    const componentsAvailability = (_a = useSelector(selectComponentsAvailability)) !== null && _a !== void 0 ? _a : {};
    const selectedDeliveryAddress = useSelector(selectSelectedDeliveryAddress);
    const van = useSelector(selectVan);
    const wrongPartNumbers = useSelector(selectWrongPartNumbers);
    const dispatch = useDispatch();
    const selectedPart = parts === null || parts === void 0 ? void 0 : parts.find((item) => item.isSelected);
    const orderMethodUsed = (isIntegrated) => {
        if (isQuickOrder) {
            if (isIntegrated) {
                return PartsOrderMethod.values.QUICK_ORDER_INTEGRATED;
            }
            return PartsOrderMethod.values.QUICK_ORDER;
        }
        if (isIntegrated) {
            return PartsOrderMethod.values[isPrioritized ? 'SMART_ORDER_INTEGRATED' : 'POPS_INTEGRATED'];
        }
        return PartsOrderMethod.values[isPrioritized ? 'SMART_ORDER' : 'POPS_REGULAR'];
    };
    const setAndFormatSelectedPart = (part) => {
        var _a;
        if (!part) {
            setSelectedPart(undefined);
            return;
        }
        setSelectedPart(Object.assign(Object.assign({}, part), { cost: formatWithCents((_a = part.cost) !== null && _a !== void 0 ? _a : 0), price: formatWithCents(part.price) }));
    };
    const addOrderPart = (part) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const newParts = [...orderParts];
        const _j = (_a = part !== null && part !== void 0 ? part : selectedPart) !== null && _a !== void 0 ? _a : {}, { meta } = _j, rest = __rest(_j, ["meta"]);
        const restPart = rest;
        const jobIndex = selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.findIndex((j) => {
            return j.referenceNum === (meta === null || meta === void 0 ? void 0 : meta.job.referenceNum);
        });
        let fitment;
        if (restPart.fitmentId) {
            fitment = (_b = restPart.fitments) === null || _b === void 0 ? void 0 : _b.find((f) => f.id === restPart.fitmentId);
        }
        const existingPart = newParts.find((p) => {
            return !!fitment && p.part.id === (fitment === null || fitment === void 0 ? void 0 : fitment.id);
        });
        if (existingPart) {
            existingPart.part = fitment;
            return;
        }
        newParts.push({
            part: (fitment || restPart),
            referenceNum: (_c = meta === null || meta === void 0 ? void 0 : meta.job.referenceNum) !== null && _c !== void 0 ? _c : '',
            repairOrderId: (_d = meta === null || meta === void 0 ? void 0 : meta.job.repairOrderId) !== null && _d !== void 0 ? _d : '',
            repairRequestId: (_e = meta === null || meta === void 0 ? void 0 : meta.job.repairRequestId) !== null && _e !== void 0 ? _e : '',
            skeduloJobId: (_f = meta === null || meta === void 0 ? void 0 : meta.job.skeduloJobId) !== null && _f !== void 0 ? _f : 'N/A',
            partId: (_g = meta === null || meta === void 0 ? void 0 : meta.id) !== null && _g !== void 0 ? _g : '',
            isFluid: (_h = meta === null || meta === void 0 ? void 0 : meta.isFluid) !== null && _h !== void 0 ? _h : false,
            jobNumber: (jobIndex !== null && jobIndex !== void 0 ? jobIndex : 0) + 1,
            externalPart: meta === null || meta === void 0 ? void 0 : meta.externalPart,
            isIntegrated: part || fitment ? false : restPart.isIntegrated,
            isFitment: !!fitment,
        });
        dispatch(setOrderParts(newParts));
    };
    const removeOrderPart = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const newParts = orderParts.filter((part) => { var _a, _b; return ((_b = (_a = part === null || part === void 0 ? void 0 : part.part) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.toString()) !== id; });
        yield dispatch(setOrderParts([...newParts]));
        return true;
    });
    const getEditPartBody = (part, meta) => {
        const formattedPart = formatAdditionalInfo(part);
        const filteredPart = filterOutFields(formattedPart, partFields);
        filteredPart[0] = Object.assign({}, formattedPart);
        delete filteredPart[0].meta;
        const body = Object.assign({}, meta.price);
        body.parts = [filteredPart];
        return body;
    };
    const getEditFluidBody = (part, meta) => {
        const formattedFluid = formatAdditionalInfo(part);
        const filteredFluid = filterOutFields(formattedFluid, fluidFields);
        const body = Object.assign({}, meta.price);
        body.fluids = [filteredFluid];
        return body;
    };
    const getAddPartBody = (part) => {
        const filteredPart = filterOutFields(part, partFields);
        const body = {};
        body.parts = [filteredPart];
        return body;
    };
    const getAddFluidBody = (part) => {
        const filteredFluid = filterOutFields(part, fluidFields);
        const body = {};
        body.fluids = [filteredFluid];
        return body;
    };
    const savePart = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d;
        const { meta } = selectedPart !== null && selectedPart !== void 0 ? selectedPart : {};
        if (!(meta === null || meta === void 0 ? void 0 : meta.isAdded)) {
            addOrderPart();
        }
        let body;
        if ((meta === null || meta === void 0 ? void 0 : meta.isFluid) && !addPartMode) {
            body = getEditFluidBody(selectedPart, meta);
        }
        if (!(meta === null || meta === void 0 ? void 0 : meta.isFluid) && !addPartMode) {
            body = getEditPartBody(selectedPart, meta);
        }
        if ((meta === null || meta === void 0 ? void 0 : meta.isFluid) && addPartMode) {
            body = getAddFluidBody(selectedPart);
        }
        if (!(meta === null || meta === void 0 ? void 0 : meta.isFluid) && addPartMode) {
            body = getAddPartBody(selectedPart);
        }
        if ((meta === null || meta === void 0 ? void 0 : meta.isCustom) && !addPartMode && !(selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId)) {
            yield dispatch(editCustomPart({ requestId: (_b = meta.repair.id.toString()) !== null && _b !== void 0 ? _b : '', body }));
        }
        if (!(meta === null || meta === void 0 ? void 0 : meta.isCustom) && !addPartMode && !(selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId)) {
            yield dispatch(editPart({ requestId: (_c = meta === null || meta === void 0 ? void 0 : meta.repair.id.toString()) !== null && _c !== void 0 ? _c : '', body }));
        }
        if ((meta === null || meta === void 0 ? void 0 : meta.isCustom) && addPartMode && !(selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId)) {
            yield dispatch(addCustomPart({ requestId: meta === null || meta === void 0 ? void 0 : meta.repair.id, body }));
        }
        if (!(meta === null || meta === void 0 ? void 0 : meta.isCustom) &&
            addPartMode &&
            (meta === null || meta === void 0 ? void 0 : meta.repair.id) &&
            !(selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId)) {
            yield dispatch(addPart({ requestId: meta === null || meta === void 0 ? void 0 : meta.repair.id, body, isCustom: false }));
        }
        if (selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId) {
            const fitment = (_d = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitments) === null || _d === void 0 ? void 0 : _d.find((f) => {
                return f.id === (selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId);
            });
            if (!fitment)
                return;
            const { partName, cost, partNumber, additionalInfo } = fitment;
            yield dispatch(updateFitment({
                partName,
                cost,
                partNumber,
                partDistributor: distributor,
                additionalInfo,
            }, selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId));
        }
        yield reloadsJobsByVan();
        setAddPartMode(false);
        setAndFormatSelectedPart(undefined);
        return true;
    });
    const setNextPart = () => {
        if (!selectedPart) {
            setAndFormatSelectedPart(undefined);
            return;
        }
        let foundInList = false;
        let foundNext = false;
        parts.forEach((p) => {
            var _a, _b, _c;
            if (foundNext) {
                return;
            }
            if (((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.id) === ((_b = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _b === void 0 ? void 0 : _b.id)) {
                foundInList = true;
                return;
            }
            if (foundInList &&
                !((_c = p === null || p === void 0 ? void 0 : p.meta) === null || _c === void 0 ? void 0 : _c.isAdded) &&
                p.status !== 'ORDERED' &&
                !newComponentType) {
                setAndFormatSelectedPart(Object.assign({}, p));
                foundNext = true;
            }
        });
        if (!foundNext) {
            setAndFormatSelectedPart(undefined);
        }
    };
    const getPartByOrder = (order) => {
        return parts.find((p) => { var _a; return ((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.id) === order.partId; });
    };
    const getIntegratedOrderParts = () => {
        const items = {};
        orderParts
            .filter((value) => value.isIntegrated && !value.isFitment)
            .forEach((o, idx) => {
            var _a;
            const ar = (_a = items[o.referenceNum]) !== null && _a !== void 0 ? _a : [];
            items[o.referenceNum] = [
                ...ar,
                {
                    index: idx + 1,
                    itemId: o.part.id,
                    itemType: o.isFluid ? 'FLUID_PRICES' : 'PARTS_PRICES',
                    manufacturerCode: o.externalPart.manufacturerCode,
                    partNumber: o.externalPart.partNumber,
                    quantity: o.part.quantity,
                    integratedOptionsAvailable: true,
                },
            ];
        });
        return items;
    };
    const getFailedOrders = (payload) => {
        var _a;
        let orderJobs = [];
        (_a = payload === null || payload === void 0 ? void 0 : payload.purchaseOrders) === null || _a === void 0 ? void 0 : _a.filter((o) => o.status !== 'SUCCEEDED').forEach((o) => {
            var _a;
            (_a = o.items) === null || _a === void 0 ? void 0 : _a.forEach((i) => {
                var _a;
                const orderPart = orderParts.find((op) => op.referenceNum === o.referenceNum && i.itemId === op.part.id);
                orderJobs.push({
                    referenceNum: o.referenceNum,
                    status: o.status,
                    jobNumber: orderPart === null || orderPart === void 0 ? void 0 : orderPart.jobNumber,
                    partName: (_a = orderPart === null || orderPart === void 0 ? void 0 : orderPart.part) === null || _a === void 0 ? void 0 : _a.name,
                });
            });
        });
        orderJobs = orderJobs === null || orderJobs === void 0 ? void 0 : orderJobs.reduce((acc, item) => {
            const i = acc.findIndex((a) => a.jobNumber === item.jobNumber);
            const newAcc = [...acc];
            if (i > -1) {
                newAcc[i] = {
                    jobNumber: item.jobNumber,
                    parts: [...acc[i].parts, item.partName],
                };
            }
            else {
                newAcc.push({
                    jobNumber: item.jobNumber,
                    parts: [item.partName],
                });
            }
            return newAcc;
        }, []);
        return orderJobs;
    };
    const getRequestParts = () => {
        const orderData = {};
        orderParts
            .filter((value) => !value.isIntegrated)
            .forEach((o) => {
            const id = o.repairRequestId;
            const addFieldName = o.isFluid ? 'addedFluidIds' : 'addedPartIds';
            const integrationAvailableFieldName = o.isFluid
                ? 'integratedOptionAvailableFluidIds'
                : 'integratedOptionAvailablePartIds';
            if (!orderData[id]) {
                orderData[id] = {
                    addedPartIds: [],
                    addedFluidIds: [],
                    addedPartFitments: [],
                    addedFluidFitments: [],
                    integratedOptionAvailablePartIds: [],
                    integratedOptionAvailableFluidIds: [],
                };
            }
            if (!o.isFitment) {
                orderData[id][addFieldName].push(o.part.id);
                if (isAvailableForIntegration(componentsAvailability, o.part)) {
                    orderData[id][integrationAvailableFieldName].push(o.part.id);
                }
            }
            else {
                const fitmentFieldName = o.isFluid
                    ? 'addedFluidFitments'
                    : 'addedPartFitments';
                const fitment = o.part;
                orderData[id][fitmentFieldName].push({
                    id: fitment.id,
                    partName: fitment.partName,
                    cost: fitment.cost,
                    partNumber: fitment.partNumber,
                    status: fitment.status === 'ADDED' ? 'SELECTED' : fitment.status,
                    partId: fitment.partId,
                    fluidId: fitment.fluidId,
                    removedBy: fitment.removedBy,
                    removedAt: fitment.removedAt,
                });
            }
        });
        return Object.keys(orderData).map((key) => {
            return Object.assign(Object.assign({}, orderData[key]), { repairRequestId: key });
        });
    };
    const checkSameDayJobs = () => __awaiter(void 0, void 0, void 0, function* () {
        var _e, _f, _g;
        const repairIdsSet = new Set();
        orderParts.forEach((o) => {
            repairIdsSet.add(o.repairRequestId);
        });
        const response = yield dispatch(getSameDayJobs({
            params: {
                vendorName: distributor.partDistributorName,
                vendorAddress: distributor.address,
                repairRequestIds: Array.from(repairIdsSet),
            },
        }));
        const hasSameDay = ((_g = (_f = (_e = response === null || response === void 0 ? void 0 : response.payload) === null || _e === void 0 ? void 0 : _e.Type) === null || _f === void 0 ? void 0 : _f.length) !== null && _g !== void 0 ? _g : 0) > 0;
        return { hasSameDay, data: response === null || response === void 0 ? void 0 : response.payload };
    });
    const updatePartsAndFluidsStatusFromPurchaseOrders = (purchaseOrders) => {
        const updatedJobs = Object.assign({}, jobsObject);
        purchaseOrders.forEach((purchaseOrder) => {
            const { knownRepairs } = updatedJobs[purchaseOrder.referenceNum].services;
            if (purchaseOrder.status === 'SUCCEEDED') {
                purchaseOrder.items.forEach((item) => {
                    knownRepairs.forEach((repair) => {
                        repair.price.forEach((pricePackage) => {
                            if (item.itemType === 'PARTS_PRICES') {
                                const part = pricePackage.parts.find((part) => part.id === item.itemId);
                                if (part) {
                                    part.status = 'ORDERED';
                                    updatedJobs[purchaseOrder.referenceNum].partsNeeded -= 1;
                                    updatedJobs[purchaseOrder.referenceNum].componentsNeeded -= 1;
                                }
                            }
                            else if (item.itemType === 'FLUID_PRICES') {
                                const fluid = pricePackage.fluids.find((part) => part.id === item.itemId);
                                if (fluid) {
                                    fluid.status = 'ORDERED';
                                    updatedJobs[purchaseOrder.referenceNum].fluidsNeeded -= 1;
                                    updatedJobs[purchaseOrder.referenceNum].componentsNeeded -= 1;
                                }
                            }
                        });
                    });
                });
            }
            if (updatedJobs[purchaseOrder.referenceNum].componentsNeeded <= 0) {
                updatedJobs[purchaseOrder.referenceNum].partsStatus = 'ORDERED';
            }
        });
        dispatch(setSelectedJobsForOrdering(updatedJobs));
    };
    const completeNonReduxIntegratedPartsOrder = () => __awaiter(void 0, void 0, void 0, function* () {
        var _h, _j, _k;
        const MAX_INTEGRATED_PURCHASING_TRIES = 10;
        const PURCHASING_REQUEST_DELAY = 3000;
        let allPartsOrdered = false;
        let failedOrderJobs = [];
        let response;
        setOpenCompleteDrawer(true);
        const items = getIntegratedOrderParts();
        if (Object.keys(items).length === 0) {
            return {
                allIntegratedPartsOrdered: true,
            };
        }
        const params = {
            distributorLocationId: partDistributorLocationId,
            ispId,
            purchaseOrderRequests: Object.keys(items).map((k) => ({
                referenceNum: k,
                items: items[k],
            })),
            orderMethod: orderMethodUsed(true),
            delivery: pickupMethod === PickupMethod.DELIVERY,
            hubDeliveryLocationId: (selectedDeliveryAddress === null || selectedDeliveryAddress === void 0 ? void 0 : selectedDeliveryAddress.type) === 'HUB' &&
                pickupMethod === PickupMethod.DELIVERY
                ? selectedDeliveryAddress === null || selectedDeliveryAddress === void 0 ? void 0 : selectedDeliveryAddress.locationId
                : null,
            hotshotDeliveryAddressId: (selectedDeliveryAddress === null || selectedDeliveryAddress === void 0 ? void 0 : selectedDeliveryAddress.type) !== 'HUB' &&
                pickupMethod === PickupMethod.DELIVERY
                ? selectedDeliveryAddress === null || selectedDeliveryAddress === void 0 ? void 0 : selectedDeliveryAddress.locationId
                : null,
        };
        try {
            response = yield dispatch(completeIntegratedPartsOrder({
                body: params,
            }));
        }
        catch (e) {
            response = { error: true };
        }
        if (!response.error) {
            const { payload } = response;
            if ((payload === null || payload === void 0 ? void 0 : payload.completedOrders) !== (payload === null || payload === void 0 ? void 0 : payload.totalOrders) &&
                (payload === null || payload === void 0 ? void 0 : payload.purchaseOrderBatchId)) {
                let tries = 0;
                while (tries < MAX_INTEGRATED_PURCHASING_TRIES) {
                    tries += 1;
                    // eslint-disable-next-line no-await-in-loop
                    yield asyncDelay(PURCHASING_REQUEST_DELAY);
                    // eslint-disable-next-line no-await-in-loop
                    response = yield dispatch(getPartOrderStatus({ id: payload === null || payload === void 0 ? void 0 : payload.purchaseOrderBatchId }));
                    if (((_h = response === null || response === void 0 ? void 0 : response.payload) === null || _h === void 0 ? void 0 : _h.status) !== 'PENDING' &&
                        ((_j = response === null || response === void 0 ? void 0 : response.payload) === null || _j === void 0 ? void 0 : _j.status) !== 'NOT_PICKED_UP') {
                        break;
                    }
                }
                response = yield dispatch(getPartPurchaseOrder({ id: payload === null || payload === void 0 ? void 0 : payload.purchaseOrderBatchId }));
                const finalPayload = response === null || response === void 0 ? void 0 : response.payload;
                allPartsOrdered = (_k = finalPayload.purchaseOrders) === null || _k === void 0 ? void 0 : _k.every((o) => o.status === 'SUCCEEDED');
                if (!allPartsOrdered) {
                    failedOrderJobs = getFailedOrders(finalPayload);
                }
            }
        }
        return {
            integratedPartsResponse: response,
            failedOrderJobs,
            allIntegratedPartsOrdered: allPartsOrdered,
        };
    });
    const completeOrder = ({ isUpdateOrder = false, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
        setCompletingOrder(true);
        const allJobsOrdered = parts.every((p) => {
            var _a, _b;
            return p.status === 'ORDERED' ||
                ((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.isAdded) ||
                ((_b = p.fitments) === null || _b === void 0 ? void 0 : _b.every((f) => ['ORDERED', 'ADDED', 'SELECTED'].includes(f.status)));
        });
        const requestParts = getRequestParts();
        const { integratedPartsResponse, failedOrderJobs, allIntegratedPartsOrdered, } = yield completeNonReduxIntegratedPartsOrder();
        let nonIntegratedPartsResponse;
        if (requestParts.length !== 0) {
            nonIntegratedPartsResponse = yield dispatch(completeOrderParts({
                body: {
                    ispId,
                    addedComponents: requestParts,
                    componentPartDistributor: distributor,
                    vendorConfirmationNumber,
                    updateOrder: isUpdateOrder,
                    orderMethod: orderMethodUsed(false),
                    delivery: pickupMethod === PickupMethod.DELIVERY,
                    hubDeliveryLocationId: (selectedDeliveryAddress === null || selectedDeliveryAddress === void 0 ? void 0 : selectedDeliveryAddress.type) === 'HUB' &&
                        pickupMethod === PickupMethod.DELIVERY
                        ? selectedDeliveryAddress === null || selectedDeliveryAddress === void 0 ? void 0 : selectedDeliveryAddress.locationId
                        : null,
                    hotshotDeliveryAddressId: (selectedDeliveryAddress === null || selectedDeliveryAddress === void 0 ? void 0 : selectedDeliveryAddress.type) !== 'HUB' &&
                        pickupMethod === PickupMethod.DELIVERY
                        ? selectedDeliveryAddress === null || selectedDeliveryAddress === void 0 ? void 0 : selectedDeliveryAddress.locationId
                        : null,
                },
            }));
        }
        if (!(integratedPartsResponse === null || integratedPartsResponse === void 0 ? void 0 : integratedPartsResponse.error) || !(nonIntegratedPartsResponse === null || nonIntegratedPartsResponse === void 0 ? void 0 : nonIntegratedPartsResponse.error)) {
            setDialogSettings({
                allJobsOrdered,
                integratedSkeduloId: (_m = (_l = integratedPartsResponse === null || integratedPartsResponse === void 0 ? void 0 : integratedPartsResponse.payload) === null || _l === void 0 ? void 0 : _l.skeduloPickupJobId) !== null && _m !== void 0 ? _m : (_o = integratedPartsResponse === null || integratedPartsResponse === void 0 ? void 0 : integratedPartsResponse.payload) === null || _o === void 0 ? void 0 : _o.partPickupSkeduloJobId,
                integratedSkeduloJobName: (_q = (_p = integratedPartsResponse === null || integratedPartsResponse === void 0 ? void 0 : integratedPartsResponse.payload) === null || _p === void 0 ? void 0 : _p.skeduloPickupJobName) !== null && _q !== void 0 ? _q : (_r = integratedPartsResponse === null || integratedPartsResponse === void 0 ? void 0 : integratedPartsResponse.payload) === null || _r === void 0 ? void 0 : _r.partPickupSkeduloJobName,
                skeduloId: (_t = (_s = nonIntegratedPartsResponse === null || nonIntegratedPartsResponse === void 0 ? void 0 : nonIntegratedPartsResponse.payload) === null || _s === void 0 ? void 0 : _s.skeduloPickupJobId) !== null && _t !== void 0 ? _t : (_u = nonIntegratedPartsResponse === null || nonIntegratedPartsResponse === void 0 ? void 0 : nonIntegratedPartsResponse.payload) === null || _u === void 0 ? void 0 : _u.partPickupSkeduloJobId,
                skeduloJobName: (_w = (_v = nonIntegratedPartsResponse === null || nonIntegratedPartsResponse === void 0 ? void 0 : nonIntegratedPartsResponse.payload) === null || _v === void 0 ? void 0 : _v.skeduloPickupJobName) !== null && _w !== void 0 ? _w : (_x = nonIntegratedPartsResponse === null || nonIntegratedPartsResponse === void 0 ? void 0 : nonIntegratedPartsResponse.payload) === null || _x === void 0 ? void 0 : _x.partPickupSkeduloJobName,
                success: true,
                integratedFailedOrderjobs: failedOrderJobs,
                partPickupOrderReferenceNum: (_z = (_y = integratedPartsResponse === null || integratedPartsResponse === void 0 ? void 0 : integratedPartsResponse.payload) === null || _y === void 0 ? void 0 : _y.skeduloPickupJobName) !== null && _z !== void 0 ? _z : (_0 = nonIntegratedPartsResponse === null || nonIntegratedPartsResponse === void 0 ? void 0 : nonIntegratedPartsResponse.payload) === null || _0 === void 0 ? void 0 : _0.partPickupOrderReferenceNum,
                allIntegratedPartsOrdered,
            });
            // Concat popsRepairRequests
            let popsRepairRequests = [];
            if ((_1 = integratedPartsResponse === null || integratedPartsResponse === void 0 ? void 0 : integratedPartsResponse.payload) === null || _1 === void 0 ? void 0 : _1.popsRepairRequests) {
                popsRepairRequests = [
                    ...popsRepairRequests,
                    ...integratedPartsResponse.payload.popsRepairRequests,
                ];
            }
            if ((_2 = nonIntegratedPartsResponse === null || nonIntegratedPartsResponse === void 0 ? void 0 : nonIntegratedPartsResponse.payload) === null || _2 === void 0 ? void 0 : _2.popsRepairRequests) {
                popsRepairRequests = [
                    ...popsRepairRequests,
                    ...nonIntegratedPartsResponse.payload.popsRepairRequests,
                ];
            }
            // Concat purchaseOrders
            let purchaseOrders = [];
            if ((_3 = integratedPartsResponse === null || integratedPartsResponse === void 0 ? void 0 : integratedPartsResponse.payload) === null || _3 === void 0 ? void 0 : _3.purchaseOrders) {
                purchaseOrders = [
                    ...purchaseOrders,
                    ...integratedPartsResponse.payload.purchaseOrders,
                ];
            }
            if ((_4 = nonIntegratedPartsResponse === null || nonIntegratedPartsResponse === void 0 ? void 0 : nonIntegratedPartsResponse.payload) === null || _4 === void 0 ? void 0 : _4.purchaseOrders) {
                purchaseOrders = [
                    ...purchaseOrders,
                    ...nonIntegratedPartsResponse.payload.purchaseOrders,
                ];
            }
            if (popsRepairRequests.length > 0) {
                const updatedJobs = Object.assign({}, jobsObject);
                popsRepairRequests.forEach((repairRequest) => {
                    if (updatedJobs[repairRequest.referenceNum]) {
                        updatedJobs[repairRequest.referenceNum].partsStatus =
                            repairRequest.partsStatus;
                        updatedJobs[repairRequest.referenceNum].componentsNeeded =
                            repairRequest.componentsNeeded;
                        updatedJobs[repairRequest.referenceNum].services =
                            repairRequest.services;
                    }
                });
                dispatch(setSelectedJobsForOrdering(updatedJobs));
            }
            if (purchaseOrders.length > 0) {
                updatePartsAndFluidsStatusFromPurchaseOrders(purchaseOrders);
            }
        }
        else if (nonIntegratedPartsResponse.error &&
            integratedPartsResponse.error) {
            setDialogSettings({
                success: false,
            });
            dispatch(setSelectedJobsForOrdering(Object.assign({}, jobsObject)));
        }
        dispatch(setOrderParts([]));
        setCompletingOrder(false);
        setOpenCompleteDrawer(true);
    });
    const saveNewComponent = (isCustom, values, innerNewComponentType) => __awaiter(void 0, void 0, void 0, function* () {
        var _5, _6;
        const repairId = (_5 = newComponentType === null || newComponentType === void 0 ? void 0 : newComponentType.repairId) !== null && _5 !== void 0 ? _5 : innerNewComponentType === null || innerNewComponentType === void 0 ? void 0 : innerNewComponentType.repairId;
        if (!repairId)
            return;
        const componentType = (_6 = innerNewComponentType === null || innerNewComponentType === void 0 ? void 0 : innerNewComponentType.type) !== null && _6 !== void 0 ? _6 : newComponentType === null || newComponentType === void 0 ? void 0 : newComponentType.type;
        const type = componentType === 'Fluid' ? 'fluids' : 'parts';
        const body = {
            [type]: [
                Object.assign(Object.assign({}, (values !== null && values !== void 0 ? values : newComponent)), { specialOrder: false }),
            ],
        };
        yield dispatch(addPart({ requestId: repairId, body, isCustom }));
        yield reloadsJobsByVan();
        setNewComponentAdded(true);
        return true;
    });
    useEffect(() => {
        if (newComponentAdded) {
            const addedPart = parts.find((p) => p.name === (newComponent === null || newComponent === void 0 ? void 0 : newComponent.name) &&
                p.partNumber === (newComponent === null || newComponent === void 0 ? void 0 : newComponent.partNumber));
            if (addedPart) {
                addOrderPart(addedPart);
            }
            setNewComponentType(undefined);
            setNewComponent({});
            setNewComponentAdded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newComponentAdded]);
    const deleteComponent = ({ repairId, componentId, componentType, isCustom, removeDynamicRule, }) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(removeComponent({
            requestId: repairId,
            componentId,
            componentType,
            isCustom,
            removeDynamicRule,
        }));
        if (!response || response.error) {
            return response;
        }
        yield removeOrderPart(componentId);
        yield reloadsJobsByVan();
        return response;
    });
    const reloadsJobsByVan = () => __awaiter(void 0, void 0, void 0, function* () {
        var _7;
        try {
            const payload = {
                ispId,
                page: 0,
                size: 25,
            };
            if (isPrioritized) {
                payload.shift = topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.shift;
                payload.date = topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.scheduleStartLocal;
                payload.timezone = topPriorityOrder === null || topPriorityOrder === void 0 ? void 0 : topPriorityOrder.timezone;
            }
            const jobsRequest = yield dispatch(getJobsByVan(payload));
            const jobs = (_7 = jobsRequest.payload) === null || _7 === void 0 ? void 0 : _7.content;
            const updatedJobs = Object.assign({}, jobsObject);
            jobs.forEach((j) => {
                if (updatedJobs[j.referenceNum]) {
                    updatedJobs[j.referenceNum] = j;
                }
            });
            dispatch(setSelectedJobsForOrdering(updatedJobs));
        }
        catch (e) {
            // TODO implement error mitigation
        }
    });
    const setIsIntegrated = (value) => {
        const partIndex = parts.findIndex((item) => item.id === (selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.id));
        const newParts = [...parts];
        newParts[partIndex] = Object.assign(Object.assign({}, newParts[partIndex]), { isIntegrated: value });
        setParts([...newParts]);
    };
    const setSelectedPart = (part, fitmentId, format = true) => {
        var _a;
        if (!part)
            return;
        const partIndex = parts.findIndex((item) => item.id === (part === null || part === void 0 ? void 0 : part.id));
        const previousSelectedPartIndex = parts.findIndex((item) => item.id === (selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.id));
        const newParts = [...parts];
        if (previousSelectedPartIndex !== -1 &&
            previousSelectedPartIndex !== partIndex) {
            newParts[previousSelectedPartIndex] = Object.assign(Object.assign({}, newParts[previousSelectedPartIndex]), { isSelected: false });
        }
        if (format) {
            part.cost = formatWithCents((_a = part.cost) !== null && _a !== void 0 ? _a : 0);
            part.price = formatWithCents(part.price);
        }
        part.fitmentId = fitmentId;
        newParts[partIndex] = Object.assign(Object.assign({}, part), { isSelected: true });
        setParts([...newParts]);
    };
    const handleAccordionClick = (state, action) => () => {
        action(!state);
    };
    const handleCompleteOrder = () => __awaiter(void 0, void 0, void 0, function* () {
        completeOrder({ isUpdateOrder: false });
    });
    const handleConfirmationNumberChange = (e) => {
        dispatch(setVendorConfirmationNumber(e.target.value));
    };
    const setPartPriceWithMarkUp = () => __awaiter(void 0, void 0, void 0, function* () {
        var _8, _9;
        if (!selectedPart)
            return;
        const selectedComponentId = selectedPart.pricingData.repairComponentId;
        if (markupsMap && markupsMap[selectedComponentId]) {
            const markup = markupsMap[selectedComponentId];
            const maxPrice = selectedPart.maxPrice * selectedPart.quantity;
            const markupFormula = ((_8 = selectedPart.cost) !== null && _8 !== void 0 ? _8 : 0) * markup * selectedPart.quantity;
            const isMarkupFormulaBigger = maxPrice <= markupFormula;
            if (isMarkupFormulaBigger) {
                selectedPart.price = maxPrice / selectedPart.quantity;
            }
            else {
                selectedPart.price = ((_9 = selectedPart.cost) !== null && _9 !== void 0 ? _9 : 0) * markup;
            }
        }
    });
    const handleSaveNextClick = (isCustom, values, innerNewComponentType) => __awaiter(void 0, void 0, void 0, function* () {
        setPartPriceWithMarkUp();
        if (!newComponentType && !innerNewComponentType) {
            yield savePart();
        }
        else {
            yield saveNewComponent(isCustom, values, innerNewComponentType);
            toast.success('Component added successfully');
        }
        setOpenEditDialog(false);
    });
    const handleSelectPart = (part, fitmentId) => __awaiter(void 0, void 0, void 0, function* () {
        if (!mdAndUp) {
            setOpenEditDialog(true);
        }
        setSelectedPart(part, fitmentId, true);
    });
    const handleChangeEvent = (fieldName, toFixed, allowDecimal = false) => (e) => {
        var _a, _b, _c, _d, _e;
        let value;
        const targetValue = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value;
        if (!targetValue) {
            value = null;
            // CAN'T CONVERT NUMBERS AS THE USER TYPES, BECAUSE NUMBER PARSES OUT THE DECIMAL WITH NOTHING BEHIND IT
        }
        else if (allowDecimal) {
            const lastCharIsDecimal = targetValue.substring(e.target.value.length - 1) === '.' ||
                targetValue.substring(e.target.value.length - 2) === '.0';
            value = lastCharIsDecimal ? targetValue : Number(targetValue);
        }
        else {
            value = toFixed ? Number(targetValue) : targetValue;
        }
        if (newComponentType) {
            setNewComponent(Object.assign(Object.assign({}, newComponent), { [fieldName]: value }));
            return;
        }
        if (!selectedPart)
            return;
        if (fieldName === 'createCore' && !(selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId)) {
            setSelectedPart(Object.assign(Object.assign({}, selectedPart), { createCore: e.target.checked, coreChargeCost: e.target.checked
                    ? (_b = selectedPart.coreChargeCost) !== null && _b !== void 0 ? _b : 0
                    : undefined }));
            return;
        }
        if (fieldName === 'partNumber' && !(selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId)) {
            setSelectedPart(Object.assign(Object.assign({}, selectedPart), { [fieldName]: value, catalogPartNumber: '' }));
            return;
        }
        if (fieldName === 'partNumber' && (selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId)) {
            const updatedPart = Object.assign({}, selectedPart);
            const fitment = (_c = updatedPart.fitments) === null || _c === void 0 ? void 0 : _c.find((f) => {
                return f.id === updatedPart.fitmentId;
            });
            if (!fitment)
                return;
            fitment.partNumber = value;
            setSelectedPart(updatedPart, updatedPart.fitmentId);
            return;
        }
        if (fieldName === 'name' && (selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId)) {
            const updatedPart = Object.assign({}, selectedPart);
            const fitment = (_d = updatedPart.fitments) === null || _d === void 0 ? void 0 : _d.find((f) => {
                return f.id === updatedPart.fitmentId;
            });
            if (!fitment)
                return;
            fitment.partName = value;
            setSelectedPart(updatedPart, updatedPart.fitmentId);
            return;
        }
        if (selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.fitmentId) {
            const updatedPart = Object.assign({}, selectedPart);
            const fitment = (_e = updatedPart.fitments) === null || _e === void 0 ? void 0 : _e.find((f) => {
                return f.id === updatedPart.fitmentId;
            });
            if (!fitment)
                return;
            fitment[fieldName] = value;
            setSelectedPart(updatedPart, updatedPart.fitmentId);
            return;
        }
        setSelectedPart(Object.assign(Object.assign({}, selectedPart), { [fieldName]: value }), selectedPart.fitmentId, false);
    };
    const handlePickPartSelect = (item) => {
        var _a, _b, _c;
        if (!item || !selectedPart)
            return;
        setSelectedPart(Object.assign(Object.assign({}, selectedPart), { partNumber: item.partNumber, cost: ((_a = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _a === void 0 ? void 0 : _a.isFluid)
                ? (_b = selectedPart.cost) !== null && _b !== void 0 ? _b : 0
                : item.unitCost, coreChargeCost: ((_c = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _c === void 0 ? void 0 : _c.isFluid) ? 0 : item.coreCost, meta: Object.assign(Object.assign({}, selectedPart.meta), { externalPart: item }) }));
    };
    const handlePickPart = () => {
        setOpenPartsDialog(true);
        setOpenEditDialog(false);
    };
    const handleBlurEvent = () => {
        if (!selectedPart)
            return;
        setSelectedPart(selectedPart, selectedPart.fitmentId, false);
    };
    const handleEdit = (order) => {
        const part = getPartByOrder(order);
        if (!part)
            return;
        setSelectedPart(part, order.isFitment ? order.part.id : undefined);
        setOpenEditDialog(true);
    };
    useEffect(() => {
        if (!(parts === null || parts === void 0 ? void 0 : parts.length) || selectedPart || newComponentType)
            return;
        const pendingPart = parts.find((part) => {
            var _a;
            return (part.status === 'PENDING' || part.status === 'NOT_PICKED_UP') &&
                !((_a = part.meta) === null || _a === void 0 ? void 0 : _a.isAdded) &&
                !selectedPart;
        });
        if (pendingPart) {
            setSelectedPart(pendingPart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parts, selectedPart]);
    useEffect(() => {
        const newParts = getPartsList(selectedJobs !== null && selectedJobs !== void 0 ? selectedJobs : [], orderParts, componentsAvailability);
        setParts([...newParts]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedJobs, orderParts]);
    useEffect(() => {
        if (!van && !selectedJobs) {
            history.push(`/pops/regions/${region}/vans/${ispId}/jobs`);
        }
    }, [history, ispId, region, selectedJobs, van]);
    useEffect(() => {
        if (markupsMap || !(parts === null || parts === void 0 ? void 0 : parts.length))
            return;
        const repairComponentIds = parts
            .filter((p) => { var _a, _b; return (_b = !((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.isFluid)) !== null && _b !== void 0 ? _b : false; })
            .filter((p) => { var _a; return QuotedPriceMode.RANGED === ((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.price.quotedPriceMode); })
            .map((p) => p.pricingData.repairComponentId);
        if ((repairComponentIds === null || repairComponentIds === void 0 ? void 0 : repairComponentIds.length) > 0) {
            dispatch(getComponentsMarkUp(repairComponentIds)).then((result) => {
                setMarkups(result.payload);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parts]);
    useEffect(() => {
        var _a, _b, _c;
        if ((selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.status) === 'WRONG_PART' &&
            (selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.id) &&
            !wrongPartNumbers[selectedPart.id]) {
            dispatch(getWrongPartNumbersByComponent((_b = (_a = selectedPart.meta) === null || _a === void 0 ? void 0 : _a.repair) === null || _b === void 0 ? void 0 : _b.id, selectedPart.id, ((_c = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _c === void 0 ? void 0 : _c.isFluid) ? 'FLUID' : 'PART'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPart]);
    return {
        parts,
        selectedPart,
        addPartMode,
        dialogSettings,
        openCompleteDrawer,
        savePart,
        setNextPart,
        addOrderPart,
        getPartByOrder,
        setSelectedPart,
        completeOrder,
        completingOrder,
        setDialogSettings,
        setOpenCompleteDrawer,
        setAddPartMode,
        checkSameDayJobs,
        openMergeDrawer,
        setOpenMergeDrawer,
        newComponentType,
        setNewComponentType,
        newComponent,
        setNewComponent,
        saveNewComponent,
        deleteComponent,
        setIsIntegrated,
        setPickupMethod,
        pickupMethod,
        reloadsJobsByVan,
        van,
        history,
        selectedJobs,
        distributor,
        orderParts,
        vendorOpen,
        setVendorOpen,
        partsOpen,
        setPartsOpen,
        openPartsDialog,
        setOpenPartsDialog,
        openEditDialog,
        setOpenEditDialog,
        markupsMap,
        setMarkups,
        componentsAvailability,
        wrongPartNumbers,
        handleAccordionClick,
        handleCompleteOrder,
        handleConfirmationNumberChange,
        handleSaveNextClick,
        handleSelectPart,
        mdAndUp,
        handleChangeEvent,
        handlePickPartSelect,
        handlePickPart,
        handleBlurEvent,
        handleEdit,
    };
};
export default useOrder;
