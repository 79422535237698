import React from 'react';
import ContactSupport from 'src/AdminApp/components/contactSupport';
import { TabPanel } from 'src/AdminApp/components/layout/TabPanel';
import RequestHistoryModal from 'src/AdminApp/components/requestHistory/RequestHistoryModal';
import SnapshotsBrowser from 'src/AdminApp/components/snapshots/SnapshotsBrowser';
import AppointmentList from 'src/AdminApp/containers/appointment/AppointmentModule';
import FleetVisitAppointmentCard from 'src/AdminApp/containers/appointment/FleetVisitAppointmentCard';
import Payment from 'src/AdminApp/containers/payments/PaymentModule';
import { CheckIcon, WarningIcon, } from 'src/AdminApp/containers/payments/PaymentModule/PaymentsTable/styledComponents';
import ContactInfo from 'src/AdminApp/containers/requests/ContactInfo';
import CustomerReview from 'src/AdminApp/containers/requests/CustomerReviewModule';
import Invoice from 'src/AdminApp/containers/requests/Invoice';
import Notes from 'src/AdminApp/containers/requests/Notes';
import OrderReceipt from 'src/AdminApp/containers/requests/OrderReceipt';
import Quote from 'src/AdminApp/containers/requests/Quote';
import RecommendedRepairs from 'src/AdminApp/containers/requests/RecommendedRepairs';
import RequestUpdatedInfo from 'src/AdminApp/containers/requests/RequestUpdatedInfo';
import SnapshotQuote from 'src/AdminApp/containers/requests/Snapshot/';
import StateTracker from 'src/AdminApp/containers/requests/StateTracker';
import RequestCTAsContainer from 'src/AdminApp/containers/requests/StateTracker/RequestCTAs/RequestCTAsContainer';
import TypeLabel from 'src/AdminApp/containers/requests/TypeLabel';
import CarModule from 'src/AdminApp/containers/vehicle/CarModule';
import { Box, Divider, Grid, Tab, Tabs } from 'src/components/mui';
import CONFIG from 'src/config';
import AddedToVisitDialog from 'src/FleetVisitsApp/pages/VisitView/Dialogs/AddedToVisitDialog';
import useTechnicians from 'src/TechApp/hooks/useTechnicians';
import styled from 'styled-components';
import WarrantyClaimSection from '../../components/warrantyClaim/WarrantyClaim';
import WarrantyDecisionsDrawer from '../../containers/repairs/WarrantyClaimDecisionsDrawer/WarrantyDecisionsDrawer';
import useStickyTabs from '../../hooks/useStickyTabs';
import useRequest from './useRequest';
import RenderContainer from './RenderContainer';
const TopBlock = styled(Grid) `
  margin-bottom: 64px;
`;
const Footer = styled(Grid) `
  padding-bottom: 20px;
`;
const PaymentsTabLabel = ({ balanceOwed }) => {
    return (<Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
      Payment
      {balanceOwed === 0 ? <CheckIcon /> : <WarningIcon />}
    </Grid>);
};
const Request = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { referenceNum, isMobile, showWarrantyQuestions, currentTab, setCurrentTab, tabsRef, repairRequest, repairRequestHistory, consumerContactInfo, vehicle, diagnosis, appointmentInfo, activeAppointment, fleet, logo, canView, canViewSnapshot, canViewRequestHistory, canEdit, orderId, workOrderId, requestId, mainInvoice, customerReview, repairsString, requestLock, openAddedToVisitDialog, setOpenVisitModal, isEditable, currentTask, typeLabel, isWarrantyCreationEligeble, isSendForApprovalButtonEnabled, isSendForApprovalButtonVisible, mpiFmcWorkAuthorizationStatus, skipAddToVisit, onSendForApproval, handleMPISendForApproval, fetchMPIFmcWorkAuthorizationStatus, isFleetVisitRequest, requestAddress, requestZip, hasInvoice, handleSubmitCustomReview, fleetContactInfo, origin, destination, } = useRequest();
    useTechnicians();
    useStickyTabs(tabsRef);
    if (!canView) {
        return (<div>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        ERROR - Not Authorized: You must have the "Repair_Viewer" role to view
        this page
      </div>);
    }
    return (<>
      <div>
        <TypeLabel type={typeLabel} refNum={(repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.visit) ? repairRequest.visit.refNum : ''}/>

        <RequestCTAsContainer canEdit={canEdit} referenceNum={referenceNum} state={repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.state}/>

        <TopBlock container spacing={4}>
          <Grid item md={isFleetVisitRequest ? 4 : 5} xs={12}>
            <RenderContainer Component={ContactInfo} componentProps={{
            id: consumerContactInfo === null || consumerContactInfo === void 0 ? void 0 : consumerContactInfo.consumerId,
            name: consumerContactInfo === null || consumerContactInfo === void 0 ? void 0 : consumerContactInfo.name,
            phone: consumerContactInfo === null || consumerContactInfo === void 0 ? void 0 : consumerContactInfo.phone,
            email: consumerContactInfo === null || consumerContactInfo === void 0 ? void 0 : consumerContactInfo.email,
            referenceNum,
            vehicle,
            consumerContactInfo,
            logo,
            marketingSource: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.marketingSource,
            repairRequestId: requestId,
            fleet,
            fleetContactInfo,
            canEdit: !requestLock.locked,
            tags: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.tags,
        }} isLoading={!consumerContactInfo || !vehicle}/>
          </Grid>
          <Grid item md={isFleetVisitRequest ? 8 : 7} xs={12}>
            <RenderContainer Component={StateTracker} componentProps={{
            repairRequest,
            visit: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.visit,
            isMobile,
            activeAppointment,
            currentTask,
            referenceNum,
        }} isLoading={!repairRequest || !consumerContactInfo}/>
          </Grid>
        </TopBlock>

        <Grid container spacing={2} style={{ justifyContent: 'flex-end' }}>
          <Grid item>
            <RenderContainer Component={WarrantyClaimSection} componentProps={{
            address: requestAddress,
            consumerContactInfo,
            vehicle,
            logo,
            referenceNum,
            marketingSource: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.marketingSource,
        }} isEmpty={!isWarrantyCreationEligeble} isLoading={!consumerContactInfo || !vehicle}/>
          </Grid>
          <Grid item>
            <RenderContainer Component={WarrantyDecisionsDrawer} componentProps={{
            open: showWarrantyQuestions,
            referenceNum,
            currentTaskWorkFlowState: currentTask === null || currentTask === void 0 ? void 0 : currentTask.state,
            warrantyClaims: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.repairRequestWarrantyClaims,
        }} isLoading={!diagnosis &&
            !currentTask &&
            !(repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.repairRequestWarrantyClaims)}/>
          </Grid>
          <Grid item>
            <ContactSupport />
          </Grid>
        </Grid>

        <Tabs ref={tabsRef} value={currentTab} onChange={(event, tab) => setCurrentTab(tab)} indicatorColor="secondary" textColor="inherit" variant="scrollable" aria-label="scrollable auto tabs example" scrollButtons>
          <Tab label="Appointment"/>
          <Tab label="Work Order"/>
          {canViewSnapshot && (<Tab label={hasInvoice ? 'Quote & Invoice' : 'Quote'}/>)}
          <Tab label={<PaymentsTabLabel balanceOwed={(_a = mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.balanceOwed) !== null && _a !== void 0 ? _a : 0}/>}/>
          {canViewSnapshot && <Tab label="Snapshots"/>}
          <Tab label="Notes"/>
        </Tabs>
        <Grid className="tabs-content" container>
          {currentTab !== 4 && (<>
              {!isMobile && (<Grid item sm={4} xs={12}>
                  <Box p={3}>
                    <RenderContainer isLoading={!vehicle} Component={CarModule} componentProps={{
                    vehicle,
                    canEdit: isEditable,
                    referenceNum,
                    repairRequestId: requestId,
                    state: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.state,
                    consumerId: (_b = consumerContactInfo === null || consumerContactInfo === void 0 ? void 0 : consumerContactInfo.consumerId) !== null && _b !== void 0 ? _b : 0,
                }}/>
                  </Box>
                </Grid>)}
              <Grid item sm={8} xs={12}>
                <TabPanel value={currentTab} index={0}>
                  <RenderContainer Component={AppointmentList} componentProps={Object.assign(Object.assign({ repairRequest, deliveryChannel: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.activeAppointment.deliveryChannel, address: requestAddress, origin,
                destination,
                vehicle,
                referenceNum,
                orderId,
                workOrderId,
                repairsString, consumerPreferredTimes: (_c = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.consumerPreferredTimes) !== null && _c !== void 0 ? _c : [] }, appointmentInfo), { hasInvoice })} isLoading={!appointmentInfo || !vehicle || !requestZip} isEmpty={isFleetVisitRequest}/>
                  <RenderContainer Component={CustomerReview} componentProps={{
                review: customerReview,
                onSubmit: handleSubmitCustomReview,
            }} isLoading={!customerReview} isEmpty={isFleetVisitRequest}/>

                  <RenderContainer Component={FleetVisitAppointmentCard} componentProps={{
                appointment: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.activeAppointment,
                visit: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.visit,
                partsStatus: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.partsStatus,
            }} isLoading={!(repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.visit)} isEmpty={!isFleetVisitRequest || !repairRequest}/>
                </TabPanel>

                <TabPanel value={currentTab} index={1}>
                  <Grid container direction="column" spacing={3}>
                    {!hasInvoice && (<Grid item>
                        <RenderContainer Component={Quote} componentProps={{
                    canEdit: isEditable,
                    hasInvoice,
                    onSendForApproval,
                }} isLoading={!diagnosis}/>
                      </Grid>)}
                    <Grid item>
                      <RenderContainer Component={Invoice} componentProps={{
                canEdit: isEditable,
                orderId,
                workOrderId,
                invoiceId: mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.id,
                locked: requestLock.locked,
            }} isEmpty={!hasInvoice}/>
                    </Grid>
                    {hasInvoice && (<Grid item>
                        <RenderContainer Component={Quote} componentProps={{
                    canEdit: isEditable,
                    hasInvoice,
                    onSendForApproval,
                }} isLoading={!diagnosis}/>
                      </Grid>)}

                    <Grid item>
                      <RenderContainer Component={RecommendedRepairs} componentProps={{
                repairRequestId: requestId,
                vehicle,
                workOrderId,
                canEdit,
                hasInvoice,
                isSendForApprovalButtonEnabled,
                onSendForApproval: handleMPISendForApproval,
                fmcWorkAuthorizationStatus: mpiFmcWorkAuthorizationStatus,
                isSendForApprovalButtonVisible,
                fetchMPIFmcWorkAuthorizationStatus,
                tags: repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.tags,
            }} isEmpty={!canView || !vehicle}/>
                    </Grid>
                  </Grid>
                </TabPanel>
                {canViewSnapshot && (<TabPanel value={currentTab} index={2}>
                    <section style={{ overflowX: 'scroll', width: '100%' }}>
                      <RenderContainer Component={SnapshotQuote} componentProps={{
                    isMobile,
                    workOrderId,
                }} isLoading={!diagnosis}/>
                    </section>
                  </TabPanel>)}
                <TabPanel value={currentTab} index={canViewSnapshot ? 3 : 2}>
                  <RenderContainer Component={Payment} componentProps={{
                canEdit,
                workOrderId,
                consumerContactInfo,
            }} isEmpty={CONFIG.REACT_APP_PAYMENTS_ENABLED !== 'true' ||
                !workOrderId}/>

                  <RenderContainer Component={OrderReceipt} componentProps={{
                canEdit: isEditable,
                orderId,
                workOrderId,
            }} isEmpty={!orderId}/>
                </TabPanel>
                <TabPanel value={currentTab} index={canViewSnapshot ? 5 : 3}>
                  <RenderContainer Component={Notes} componentProps={{
                repairRequest,
                fleet,
            }} isLoading={!repairRequest}/>
                </TabPanel>
              </Grid>
            </>)}
          {currentTab === 4 && (<Grid item xs={12}>
              <TabPanel value={currentTab} index={4}>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <RenderContainer Component={SnapshotsBrowser} componentProps={{
                referenceNum,
                loaderEnabled: false,
            }} isEmpty={!canViewSnapshot || !referenceNum}/>
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>)}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Footer item container style={{ justifyContent: 'space-between' }} xs={12}>
            <Grid item>
              <RenderContainer Component={RequestUpdatedInfo} componentProps={{
            created: (_d = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.created) !== null && _d !== void 0 ? _d : '',
            createdBy: (_e = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.createdBy) !== null && _e !== void 0 ? _e : '',
            updated: (_f = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.modified) !== null && _f !== void 0 ? _f : '',
            updatedBy: (_g = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.modifiedBy) !== null && _g !== void 0 ? _g : '',
        }} isEmpty={!repairRequest}/>
            </Grid>
            <Grid item>
              <RenderContainer Component={RequestHistoryModal} componentProps={{
            history: repairRequestHistory,
            repairRequest,
        }} isEmpty={!canViewRequestHistory}/>
            </Grid>
          </Footer>
        </Grid>
      </div>

      <AddedToVisitDialog open={openAddedToVisitDialog} onClose={() => setOpenVisitModal(false)} errorCode={skipAddToVisit}/>
    </>);
};
export default Request;
