import React from 'react';
import { Icon, Grid, MenuItem, TextField, TimePicker, } from 'src/components/mui';
import { getMomentValueOr8am } from 'src/utils/formatter';
import { SimpleDrawer, SimpleDrawerContent, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import { TIME_12H_FORMAT } from 'src/timeConstants';
import useEditShiftDrawer from './useEditShiftDrawer';
import { AddNewWindowButton, EditShiftContent, FieldWrapper, HorizontalBorder, SaveButton, SectionHeader, ShiftContent, } from './EditShiftDrawer.styles';
import WindowShiftTable from './WindowShiftTable/WindowShiftTable';
const EditShiftDrawer = ({ open, onClose, shiftId, }) => {
    const { handleClose, shift, allowEdit, handleShiftChange, isValid, isSubmitting, handleSubmit, handleWindowSave, handleWindowRemove, handleAddClick, editedShiftWindow, handleEditShiftWindowStartChange, handleEditShiftWindowEndChange, shiftWindowHasOverlap, } = useEditShiftDrawer({ shiftId, onClose, open });
    const drawerHeader = (shift === null || shift === void 0 ? void 0 : shift.id) ? 'Edit Shift' : 'Add Shift';
    return (<SimpleDrawer open={open} onClose={() => handleClose(false)} size="oneThird">
      <SimpleDrawerHeader onClose={onClose} title={drawerHeader}/>

      <SimpleDrawerContent>
        <EditShiftContent>
          <ShiftContent>
            <SectionHeader>Shift</SectionHeader>
            <Grid container spacing={1} style={{ alignItems: 'flex-end' }}>
              <Grid item xs={6}>
                <FieldWrapper>
                  <TextField variant="outlined" placeholder="Shift name" label="Name" value={shift.name} disabled={!allowEdit} onChange={(event) => {
            handleShiftChange('name', event.target.value);
        }} InputLabelProps={{
            shrink: true,
        }} fullWidth/>
                </FieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <FieldWrapper>
                  <TextField variant="outlined" label="Status" type="text" value={shift.status || ''} onChange={(event) => {
            handleShiftChange('status', event.target.value);
        }} InputLabelProps={{
            shrink: true,
        }} select fullWidth>
                    <MenuItem value="ACTIVE" key="ACTIVE">
                      ACTIVE
                    </MenuItem>
                    <MenuItem value="INACTIVE" key="INACTIVE">
                      INACTIVE
                    </MenuItem>
                  </TextField>
                </FieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <FieldWrapper>
                  <TextField variant="outlined" label="Consumer Default" type="text" value={`${shift.isDefault}` || ''} onChange={(event) => {
            handleShiftChange('isDefault', event.target.value);
        }} InputLabelProps={{
            shrink: true,
        }} select fullWidth>
                    <MenuItem value="true" key="true">
                      true
                    </MenuItem>
                    <MenuItem value="false" key="false">
                      false
                    </MenuItem>
                  </TextField>
                </FieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <SaveButton type="button" color="primary" variant="contained" disabled={!isValid || isSubmitting} onClick={handleSubmit}>
                  Save
                </SaveButton>
              </Grid>
            </Grid>
            <HorizontalBorder />
            <SectionHeader>Shift Window</SectionHeader>
            <Grid container spacing={1} style={{ alignItems: 'center' }}>
              <Grid item xs={5}>
                <FieldWrapper>
                  <TimePicker label="Window Start" value={getMomentValueOr8am(editedShiftWindow === null || editedShiftWindow === void 0 ? void 0 : editedShiftWindow.windowStart, TIME_12H_FORMAT)} onChange={handleEditShiftWindowStartChange} minutesStep={15} slotProps={{
            textField: {
                variant: 'standard',
                margin: 'normal',
                id: 'time-picker',
            },
            field: {
                clearable: true,
            },
        }}/>
                </FieldWrapper>
              </Grid>
              <Grid item xs={5}>
                <FieldWrapper>
                  <TimePicker label="Window End" value={getMomentValueOr8am(editedShiftWindow === null || editedShiftWindow === void 0 ? void 0 : editedShiftWindow.windowEnd, TIME_12H_FORMAT)} onChange={handleEditShiftWindowEndChange} minutesStep={15} slotProps={{
            textField: {
                variant: 'standard',
                margin: 'normal',
                id: 'time-picker',
            },
            field: {
                clearable: true,
            },
        }}/>
                </FieldWrapper>
              </Grid>
              <Grid item xs={2}>
                <FieldWrapper>
                  <AddNewWindowButton icon={<Icon>add_new_circle</Icon>} label="Add" color="primary" onClick={handleAddClick}/>
                </FieldWrapper>
              </Grid>
            </Grid>

            <WindowShiftTable data={shift.shiftWindowSet} onSave={handleWindowSave} onRemove={handleWindowRemove} disabled={!allowEdit} shiftWindowHasOverlap={shiftWindowHasOverlap}/>
          </ShiftContent>
        </EditShiftContent>
      </SimpleDrawerContent>
    </SimpleDrawer>);
};
export default EditShiftDrawer;
