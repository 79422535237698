import { orderBy } from 'lodash';
import moment from 'moment';
import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
import { ActionCreators, setSelectedTemplate, setSelectedUnavailability, } from './actions';
const DEFAULT_STATE = {
    selectedTemplate: null,
    templatesSlidePanelOpen: false,
    editTemplateSlidePanelOpen: false,
    templates: [],
    techNames: [],
    deliveryVanTemplateType: 'availability',
    selectedUnavailability: null,
    unavailabilitiesSlidePanelOpen: false,
    editUnavailabilitiesSlidePanelOpen: false,
    unavailabilities: [],
};
const deliveryVanTemplatesSuccessReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { templates: orderByStartDate(action.payload), techNames: [] });
};
const deliveryVanAddTemplateSuccessReducer = (state) => {
    return Object.assign(Object.assign({}, state), { templatesSlidePanelOpen: true, editTemplateSlidePanelOpen: false });
};
const openTemplatesSlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { templatesSlidePanelOpen: true, selectedTemplate: null });
};
const closeTemplatesSlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { templatesSlidePanelOpen: false });
};
const openEditTemplateSlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { templatesSlidePanelOpen: false, editTemplateSlidePanelOpen: true });
};
const closeEditTemplateSlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { templatesSlidePanelOpen: true, editTemplateSlidePanelOpen: false, selectedTemplate: null });
};
const clearSelectedTemplate = (state) => {
    return Object.assign(Object.assign({}, state), { setSelectedTemplate: null });
};
const templatesTechNameSuccessReducer = (state, action) => {
    if (action.payload.content.length === 0) {
        return Object.assign({}, state);
    }
    const tech = action.payload.content[0];
    const techIndex = state.techNames.findIndex((_tech) => _tech.id === tech.uid);
    if (techIndex === -1) {
        return Object.assign(Object.assign({}, state), { techNames: [
                ...state.techNames,
                { id: tech.uid, name: tech.fullName, enabled: tech.enabled },
            ] });
    }
    return Object.assign({}, state);
};
const deliveryVanUnavailabilitiesSuccessReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { unavailabilities: action.payload });
};
const deliveryVanAddUnavailabilitiesSuccessReducer = (state) => {
    return Object.assign(Object.assign({}, state), { templatesSlidePanelOpen: true, editUnavailabilitiesSlidePanelOpen: false });
};
const openEditUnavailabilitySlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { templatesSlidePanelOpen: false, editUnavailabilitiesSlidePanelOpen: true });
};
const closeEditUnavailabilitySlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { templatesSlidePanelOpen: true, editUnavailabilitiesSlidePanelOpen: false, selectedUnavailability: null });
};
const orderByStartDate = (values) => {
    return orderBy(values, [
        (item) => {
            return moment(item.start).format(DATE_YMD_FORMAT);
        },
    ], ['desc']);
};
const toggleAvailabityTemplateType = (state) => {
    return Object.assign(Object.assign({}, state), { deliveryVanTemplateType: 'availability' });
};
const toggleUnavailabityTemplateType = (state) => {
    return Object.assign(Object.assign({}, state), { deliveryVanTemplateType: 'unavailability' });
};
const deliveryVanTemplatesReducers = handleActions({
    [ActionCreators.DELIVERY_VAN_TEMPLATES.SUCCESS.type]: deliveryVanTemplatesSuccessReducer,
    [ActionCreators.DELIVERY_VAN_TEMPLATES_TECH_NAME.SUCCESS.type]: templatesTechNameSuccessReducer,
    [ActionCreators.DELIVERY_VAN_TEMPLATES_SELECT.CLEAR.type]: clearSelectedTemplate,
    [ActionCreators.SLIDE_PANEL_DELIVERY_VAN_TEMPLATES.OPEN.type]: openTemplatesSlidePanel,
    [ActionCreators.SLIDE_PANEL_DELIVERY_VAN_TEMPLATES.CLOSE.type]: closeTemplatesSlidePanel,
    [ActionCreators.SLIDE_PANEL_DELIVERY_VAN_EDIT_TEMPLATE.OPEN.type]: openEditTemplateSlidePanel,
    [ActionCreators.SLIDE_PANEL_DELIVERY_VAN_EDIT_TEMPLATE.CLOSE.type]: closeEditTemplateSlidePanel,
    [ActionCreators.ADD_DELIVERY_VAN_TEMPLATE.SUCCESS.type]: deliveryVanAddTemplateSuccessReducer,
    [ActionCreators.UPDATE_DELIVERY_VAN_TEMPLATE.SUCCESS.type]: deliveryVanAddTemplateSuccessReducer,
    [setSelectedTemplate.type]: createSimpleHandler('selectedTemplate'),
    [ActionCreators.DELIVERY_VAN_TEMPLATE_TYPE.AVAILABILITY.type]: toggleAvailabityTemplateType,
    [ActionCreators.DELIVERY_VAN_TEMPLATE_TYPE.UNAVAILABILITY.type]: toggleUnavailabityTemplateType,
    [ActionCreators.DELIVERY_VAN_UNAVAILABILITIES.SUCCESS.type]: deliveryVanUnavailabilitiesSuccessReducer,
    [ActionCreators.ADD_DELIVERY_VAN_UNAVAILABILITY.SUCCESS.type]: deliveryVanAddUnavailabilitiesSuccessReducer,
    [ActionCreators.UPDATE_DELIVERY_VAN_UNAVAILABILITY.SUCCESS.type]: deliveryVanAddUnavailabilitiesSuccessReducer,
    [ActionCreators.SLIDE_PANEL_DELIVERY_VAN_EDIT_UNAVAILABILITY.OPEN.type]: openEditUnavailabilitySlidePanel,
    [ActionCreators.SLIDE_PANEL_DELIVERY_VAN_EDIT_UNAVAILABILITY.CLOSE.type]: closeEditUnavailabilitySlidePanel,
    [setSelectedUnavailability.type]: createSimpleHandler('selectedUnavailability'),
}, DEFAULT_STATE);
export default deliveryVanTemplatesReducers;
