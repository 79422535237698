import { Button, SpeedDial } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
import styled from 'styled-components';
export const SupportSection = styled.section `
  text-align: right;
`;
export const ContactSupportBtn = styled(Button) `
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
  display: none;

  ${theme.breakpoints.up('md')} {
    display: inline-flex;
  }
`;
export const SupportSpeedDial = styled(SpeedDial) `
  position: fixed;
  bottom: 32px;
  right: 24px;

  .MuiSpeedDialAction-fab {
    color: ${colors.white};
    background: ${colors.TOOLTIP};
  }

  .MuiSpeedDialAction-staticTooltipLabel {
    color: ${colors.white};
    background: ${colors.TOOLTIP};
    width: 200px;
    padding: 12px 16px;
  }
`;
