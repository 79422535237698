var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotesEntityTypes, NotesUrgency, SUB_TYPE_DESCRIPTION, } from 'src/AdminApp/containers/requests/Notes/constants';
import { PartsHelpTabs } from 'src/AdminApp/containers/requests/Quote/PartsHelp/PartsHelpTabs';
import NotesOriginTypes from 'src/AdminApp/models/enums/NotesOriginTypes';
import { CUSTOM_REPAIR_ID } from 'src/AdminApp/modules/api/constants';
import { getFleet } from 'src/AdminApp/modules/fleets/actions';
import { addCustomRepair as addCustomRepairInvoice, addRepair as addRepairInvoice, updateBatchRepairComponents as updateBatchRepairComponentsInvoice, } from 'src/AdminApp/modules/invoices/actions';
import { addRequestNotes } from 'src/AdminApp/modules/requestNotes/actions';
import { addCustomRepair, addRecommendedRepairsByRefNumber, addRepair, fetchRequestPartPickups, getRecommendedRepairs, openServiceDrawer, saveFleetVehicleRP, searchRepairs, updateBatchRepairComponents, updateCustomRepairComponents, updateRepairComponents, } from 'src/AdminApp/modules/requests/actions';
import { ServiceDrawerName } from 'src/AdminApp/modules/requests/repairs.state';
import { selectFleet, selectRequest, selectRequestDataByReferenceNum, selectRequestDiagnosis, selectVehicleByRefNum, } from 'src/AdminApp/modules/requests/selectors';
import { getRepairRequestStopwatch } from 'src/AdminApp/modules/workflows/actions';
import { toast } from 'src/components/SimpleToast';
import { WARNINGS } from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal/WarningMessages';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { delayFor } from 'src/utils/delay';
const usePartsHelpDrawer = ({ isFmcRo, fetchQuoteFmcWorkAuthorizationStatus, onClose, isEditable, invoice, workOrderId, open, }) => {
    const dispatch = useDispatch();
    const [showCapacityWarning, setShowCapacityWarning] = useState(false);
    const [unsavedNewRepair, setUnsavedNewRepair] = useState();
    const [capacityWarningMessage, setCapacityWarningMessage] = useState();
    const { referenceNum, selectorProps } = usePageReferenceNum();
    const requestData = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const repairRequest = useSelector((state) => selectRequest(state, selectorProps));
    const fleet = useSelector(selectFleet(repairRequest !== null && repairRequest !== void 0 ? repairRequest : null));
    const vehicle = useSelector(selectVehicleByRefNum(referenceNum));
    const diagnosis = useSelector((state) => selectRequestDiagnosis(state, selectorProps));
    const [tab, setTab] = useState(0);
    const [repair, setRepair] = useState();
    const [repairId, setRepairId] = useState();
    const [tabHistory, setTabHistory] = useState([0]);
    const [disabledTabs, setDisabledTabs] = useState([]);
    const [selectedPart, setSelectedPart] = useState();
    const [internalApprovalProvidedById, setInternalApprovalProvidedById] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [customRepairText, setCustomRepairText] = useState('');
    const [fmcRepairId, setFmcRepairId] = useState('');
    const [currentAction, setCurrentAction] = useState();
    const [wrongPartOpen, setWrongPartOpen] = useState(false);
    const [isCustomerProvidedPartsValid, setIsCustomerProvidedPartsValid] = useState(false);
    const [selectedCustomerParts, setSelectedCustomerParts] = useState({});
    const [isCheckedAllCustomerParts, setIsCheckedAllCustomerParts] = useState(false);
    const [additionalRepairComponents, setAdditionalRepairComponents] = useState([]);
    const [responsiblePartyId, setResponsiblePartyId] = useState('');
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [fleetVehicleRP, setFleetVehicleRP] = useState();
    const toggleDisabledTab = (tab, test, dTabs) => {
        const disabled = [...dTabs];
        const index = disabled.indexOf(tab);
        if (test && index === -1) {
            disabled.push(tab);
        }
        else if (!test && index > -1) {
            disabled.splice(index, 1);
        }
        return disabled;
    };
    useEffect(() => {
        var _a, _b;
        if (!open)
            return;
        let diag = Object.assign({}, diagnosis);
        if (invoice) {
            diag = invoice;
        }
        if (!diag)
            return;
        let dTabs = [...disabledTabs];
        dTabs = toggleDisabledTab(PartsHelpTabs.ADDITIONAL_REPAIRS_REQUIRED, !isEditable, dTabs);
        // ARE THERE ANY REPAIRS WITHOUT TOTAL COST?
        let foundPending = false;
        (_a = diag.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs.forEach((repair) => {
            if (!foundPending) {
                foundPending = !!repair.price[0].parts.find((part) => part.status === 'PENDING');
            }
            if (!foundPending) {
                foundPending = !!repair.price[0].fluids.find((fluid) => fluid.status === 'PENDING');
            }
        });
        if (!foundPending) {
            (_b = diag.diagnosisServices) === null || _b === void 0 ? void 0 : _b.customRequests.forEach((repair) => {
                if (!foundPending) {
                    foundPending = !!repair.price[0].parts.find((part) => part.status === 'PENDING');
                }
                if (!foundPending) {
                    foundPending = !!repair.price[0].fluids.find((fluid) => fluid.status === 'PENDING');
                }
            });
        }
        dTabs = toggleDisabledTab(PartsHelpTabs.URGENT_PENDING_PARTS, !foundPending, dTabs);
        if (invoice) {
            dTabs = toggleDisabledTab(PartsHelpTabs.WRONG_PART_SELECT_REPAIR, true, dTabs);
            dTabs = toggleDisabledTab(PartsHelpTabs.FAULTY_PART_RECEIVED_SELECT_REPAIR, true, dTabs);
            dTabs = toggleDisabledTab(PartsHelpTabs.CUSTOMER_PROVIDED_PARTS, true, dTabs);
        }
        setDisabledTabs(dTabs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    const goToTab = (tab) => {
        setTab(tab);
        setTabHistory((prev) => [...prev, tab]);
    };
    const goBack = () => {
        const history = [...tabHistory];
        history.pop();
        setTabHistory(history);
        setTab(history[history.length - 1]);
    };
    const goToSelectComponentType = (repair) => {
        setRepair(repair);
        goToTab(PartsHelpTabs.ADDITIONAL_COMPONENTS_REQUIRED_SELECT_COMPONENT_TYPE);
    };
    const goToSelectRepairComponent = (repair, newTab) => {
        setRepair(repair);
        goToTab(newTab);
    };
    const submitRecommendedRepair = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!repairId || !repair)
            return;
        const response = yield dispatch(addRecommendedRepairsByRefNumber({
            body: {
                origin: 'TOOLS',
                recommendedRepairs: [
                    {
                        repairId: repairId === CUSTOM_REPAIR_ID ? undefined : repairId,
                        repair: repairId === CUSTOM_REPAIR_ID ? '' : repair.label,
                        customRepairText: customRepairText.length > 0 ? customRepairText : null,
                        inspectionDetails: {
                            additionalInfo: '',
                        },
                    },
                ],
            },
            referenceNum,
        }));
        if (!response.error) {
            dispatch(getRecommendedRepairs(referenceNum));
            toast.success('Recommended repair added successfully');
        }
        return response;
    });
    const handleRepairSelect = (selectedRepair, approvalId) => __awaiter(void 0, void 0, void 0, function* () {
        setRepairId(selectedRepair.repairId);
        if (approvalId) {
            setInternalApprovalProvidedById(approvalId);
        }
    });
    const handleResponsiblePartySelect = (responsibleParty) => {
        var _a, _b;
        if ((_a = responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.groupName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('account')) {
            setOpenConfirmation(true);
        }
        setResponsiblePartyId((responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.id) || '');
        if (!responsibleParty ||
            !((_b = responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.groupName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes('account'))) {
            setFleetVehicleRP(undefined);
        }
    };
    const handleRepairSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!repairId || isSubmitting)
            return;
        setIsSubmitting(true);
        if (currentAction === PartsHelpTabs.PRICING_QUOTE) {
            yield submitRecommendedRepair();
        }
        else if (currentAction === PartsHelpTabs.ADDITIONAL_REPAIRS_REQUIRED) {
            yield handleAddRepair({
                repairId,
                internalApprovalProvidedById,
                isValidateLaborCapacity: !!repairRequest.visit,
                responsiblePartyId,
            });
        }
        saveFleetVehicleResponsibleParty();
        setIsSubmitting(false);
        handleClose();
    });
    const handleAddPart = () => {
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.part,
            adding: true,
            repair,
            hasInvoice: !!invoice,
            isCustom: !!(repair === null || repair === void 0 ? void 0 : repair.message),
        }));
        handleClose();
    };
    const handleOpenAddFluid = () => {
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.fluid,
            adding: true,
            repair,
            hasInvoice: !!invoice,
            isCustom: !!(repair === null || repair === void 0 ? void 0 : repair.message),
        }));
        handleClose();
    };
    const handleOpenAddLabor = () => {
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.labor,
            adding: true,
            repair,
            hasInvoice: !!invoice,
            isCustom: !!(repair === null || repair === void 0 ? void 0 : repair.message),
        }));
        handleClose();
    };
    const handleClose = () => {
        setTab(0);
        setTabHistory([0]);
        onClose();
    };
    const onFmcRepairSelect = (selectedFmcRepair) => {
        setFmcRepairId(selectedFmcRepair.fmcId);
    };
    const handleCustomFmcRepairSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!fmcRepairId || isSubmitting)
            return;
        setIsSubmitting(true);
        yield handleAddCustomRepair(fmcRepairId);
        setIsSubmitting(false);
        handleClose();
    });
    const handleFaultyPart = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        if (!repair || isSubmitting)
            return;
        const faultyPart = repair.price[0].parts.find((part) => part.id === (selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.id));
        const faultyFluid = repair.price[0].fluids.find((fluid) => fluid.id === (selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.id));
        if (!faultyPart && !faultyFluid)
            return;
        const { partMetadata } = faultyPart !== null && faultyPart !== void 0 ? faultyPart : {};
        let cleanBody = {};
        setIsSubmitting(true);
        const repairPrice = Object.assign({}, repair.price[0]);
        if (faultyPart) {
            delete repairPrice.fluids;
        }
        if (faultyFluid) {
            delete repairPrice.parts;
        }
        const payload = Object.assign(Object.assign({}, repairPrice), { addToPartPickup: false });
        if (faultyPart) {
            const isDynamicComponent = ((_a = faultyPart.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'DYNAMIC';
            cleanBody = {
                name: faultyPart.name || '',
                id: faultyPart.id || '',
                quantity: faultyPart.quantity || '',
                cost: null,
                price: null,
                partNumber: faultyPart.partNumber || '',
                partDistributorId: '',
                partDistributor: faultyPart.componentPartDistributor
                    ? {
                        id: faultyPart.componentPartDistributor.id,
                        name: faultyPart.componentPartDistributor.partDistributorName,
                        status: '',
                    }
                    : null,
                partDistributorName: '',
                partDistributorLocationId: '',
                address: '',
                notes: faultyPart.notes || '',
                specialOrder: faultyPart.specialOrder,
                leadTime: faultyPart.leadTime,
                pcdbId: faultyPart.pcdbId,
                componentId: faultyPart.componentId,
                partMetadata: ((_b = faultyPart.name) === null || _b === void 0 ? void 0 : _b.toLowerCase()) !== 'tire'
                    ? null
                    : {
                        manufacturer: (_c = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.manufacturer) !== null && _c !== void 0 ? _c : '',
                        model: (_d = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.model) !== null && _d !== void 0 ? _d : '',
                        aspectRatio: (_e = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.aspectRatio) !== null && _e !== void 0 ? _e : '',
                        rimDiameter: (_f = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.rimDiameter) !== null && _f !== void 0 ? _f : '',
                        loadRange: (_g = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.loadRange) !== null && _g !== void 0 ? _g : '',
                        speedRating: (_h = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.speedRating) !== null && _h !== void 0 ? _h : '',
                        size: (_j = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.size) !== null && _j !== void 0 ? _j : '',
                        position: (_k = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.position) !== null && _k !== void 0 ? _k : '',
                        width: (_l = partMetadata === null || partMetadata === void 0 ? void 0 : partMetadata.width) !== null && _l !== void 0 ? _l : '',
                    },
                coreChargeExpected: faultyPart.coreChargeExpected,
                coreChargeCost: faultyPart.coreChargeCost,
                isFitment: false,
                addAsDynamicComponent: isDynamicComponent,
                status: 'PENDING',
            };
            payload.parts = [Object.assign(Object.assign({}, faultyPart), cleanBody)];
        }
        if (faultyFluid) {
            const isDynamicComponent = ((_m = faultyFluid.pricingData) === null || _m === void 0 ? void 0 : _m.componentType) === 'DYNAMIC';
            cleanBody = {
                id: faultyFluid.id || '',
                name: faultyFluid.name || '',
                capacityUnit: faultyFluid.capacityUnit || '',
                capacity: faultyFluid.capacity || '',
                type: faultyFluid.type || '',
                price: null,
                cost: null,
                partDistributorId: (_p = (_o = faultyFluid.componentPartDistributor) === null || _o === void 0 ? void 0 : _o.partDistributorId) !== null && _p !== void 0 ? _p : '',
                partDistributor: faultyFluid.componentPartDistributor
                    ? {
                        id: faultyFluid.componentPartDistributor.id,
                        name: faultyFluid.componentPartDistributor.partDistributorName,
                        status: '',
                    }
                    : null,
                partDistributorName: (_r = (_q = faultyFluid.componentPartDistributor) === null || _q === void 0 ? void 0 : _q.partDistributorName) !== null && _r !== void 0 ? _r : '',
                partDistributorLocationId: (_t = (_s = faultyFluid.componentPartDistributor) === null || _s === void 0 ? void 0 : _s.partDistributorLocationId) !== null && _t !== void 0 ? _t : '',
                address: (_v = (_u = faultyFluid.componentPartDistributor) === null || _u === void 0 ? void 0 : _u.address) !== null && _v !== void 0 ? _v : '',
                notes: faultyFluid.notes || '',
                partNumber: faultyFluid.partNumber || '',
                specialOrder: false,
                leadTime: faultyFluid.leadTime,
                componentId: faultyFluid.componentId,
                addAsDynamicComponent: isDynamicComponent,
                isFitment: false,
                status: 'PENDING',
            };
            payload.fluids = [Object.assign(Object.assign({}, faultyFluid), cleanBody)];
        }
        let response;
        if (repair === null || repair === void 0 ? void 0 : repair.message) {
            response = yield dispatch(updateCustomRepairComponents({
                referenceNum,
                customRequestId: repair === null || repair === void 0 ? void 0 : repair.id,
                body: payload,
            }));
        }
        else {
            response = yield dispatch(updateRepairComponents({
                referenceNum,
                repairId: repair === null || repair === void 0 ? void 0 : repair.id,
                body: payload,
            }));
        }
        if (!response.error) {
            const params = {
                entityId: repairRequest.id,
                entityType: NotesEntityTypes.REPAIR_REQUEST,
                origin: NotesOriginTypes.TOOLS,
                content: SUB_TYPE_DESCRIPTION.FAULTY_PART_RECEIVED,
                repairRequestId: repairRequest.id,
                visibility: 'INTERNAL',
                urgency: NotesUrgency.NORMAL,
                appliesTo: null,
                base64PhotoStrings: null,
                type: 'PART_UPDATE',
                subType: SUB_TYPE_DESCRIPTION.FAULTY_PART_RECEIVED,
            };
            const responseNotes = yield dispatch(addRequestNotes(params));
            if (!responseNotes.error) {
                dispatch(fetchRequestPartPickups(referenceNum));
                toast.success('Part marked as faulty');
                handleClose();
            }
        }
        setIsSubmitting(false);
    });
    const isPartSelected = (suggestedRepairId, partType, partId) => {
        var _a, _b;
        if (selectedCustomerParts && selectedCustomerParts[suggestedRepairId]) {
            return ((_b = (_a = selectedCustomerParts[suggestedRepairId][partType]) === null || _a === void 0 ? void 0 : _a.includes(partId)) !== null && _b !== void 0 ? _b : false);
        }
        return false;
    };
    const prepareListToUpdate = (repairs) => {
        return repairs.map((repair) => {
            var _a, _b;
            // Filter parts and fluids to include only those that are selected
            const selectedParts = (_a = repair.additionalRepairComponent.parts) === null || _a === void 0 ? void 0 : _a.filter((part) => isPartSelected(repair.suggestedRepairId, 'part', part.id) ||
                isCheckedAllCustomerParts);
            const selectedFluids = (_b = repair.additionalRepairComponent.fluids) === null || _b === void 0 ? void 0 : _b.filter((fluid) => isPartSelected(repair.suggestedRepairId, 'fluid', fluid.id) ||
                isCheckedAllCustomerParts);
            // Return a new object with the same structure, but with filtered parts and fluids
            return Object.assign(Object.assign({}, repair), { additionalRepairComponent: Object.assign(Object.assign({}, repair.additionalRepairComponent), { parts: selectedParts, fluids: selectedFluids }) });
        });
    };
    const handleSubmitBatch = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isSubmitting)
            return;
        setIsSubmitting(true);
        let response;
        if (invoice) {
            response = yield dispatch(updateBatchRepairComponentsInvoice({
                referenceNum,
                workOrderId: workOrderId,
                body: prepareListToUpdate(additionalRepairComponents),
            }));
        }
        else {
            response = yield dispatch(updateBatchRepairComponents({
                referenceNum,
                body: prepareListToUpdate(additionalRepairComponents),
            }));
        }
        if (!response.error) {
            dispatch(getRecommendedRepairs(referenceNum));
            setSelectedCustomerParts({});
            setIsCheckedAllCustomerParts(false);
            handleClose();
        }
        setIsSubmitting(false);
    });
    const handleAddCustomRepair = (fmcRepairId) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(addCustomRepair({
            referenceNum,
            repairDescription: customRepairText,
            fmcRepairId,
            isAutoIntegrateFmc: isFmcRo,
            responsiblePartyId,
        }));
        yield saveFleetVehicleResponsibleParty();
        yield delayFor(5000);
        fetchQuoteFmcWorkAuthorizationStatus();
        return dispatch(getRepairRequestStopwatch(referenceNum));
    });
    const handleAddCustomRepairInvoice = ({ repairDescription, }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!workOrderId || !invoice)
            return;
        const response = dispatch(addCustomRepairInvoice({
            invoiceId: invoice.id,
            workOrderId,
            repairDescription,
            responsiblePartyId,
        }));
        yield saveFleetVehicleResponsibleParty();
        return response;
    });
    const saveFleetVehicleResponsibleParty = () => __awaiter(void 0, void 0, void 0, function* () {
        if (fleetVehicleRP) {
            const responseSaveFleetVehicleRP = yield dispatch(saveFleetVehicleRP(fleetVehicleRP));
            if (responseSaveFleetVehicleRP && !(responseSaveFleetVehicleRP === null || responseSaveFleetVehicleRP === void 0 ? void 0 : responseSaveFleetVehicleRP.error)) {
                toast.success('Responsible Parties updated successfully');
                yield dispatch(getFleet(fleetVehicleRP.fleetId));
                setFleetVehicleRP(undefined);
            }
        }
    });
    const handleAddRepair = ({ repairId, internalApprovalProvidedById, isValidateLaborCapacity = false, responsiblePartyId, }) => __awaiter(void 0, void 0, void 0, function* () {
        if (invoice && workOrderId) {
            return dispatch(addRepairInvoice({
                invoiceId: invoice === null || invoice === void 0 ? void 0 : invoice.id,
                repairId: repairId.toString(),
                workOrderId,
                referenceNum,
                responsiblePartyId,
            }));
        }
        const response = yield dispatch(addRepair({
            referenceNum,
            repairId,
            internalApprovalProvidedById,
            isValidateLaborCapacity,
            responsiblePartyId,
        }));
        if (response.error &&
            response.payload.response.status === 400 &&
            response.payload.response.message.toLowerCase() === 'visit over capacity') {
            setUnsavedNewRepair({ repairId, internalApprovalProvidedById });
            setShowCapacityWarning(true);
            setCapacityWarningMessage(WARNINGS.ADD_A_SERVICE);
        }
        else if (response.error) {
            toast.error(response.payload.response.message);
        }
        else {
            yield delayFor(5000);
            fetchQuoteFmcWorkAuthorizationStatus();
            return dispatch(getRepairRequestStopwatch(referenceNum));
        }
    });
    const handleCapacityWarningClose = (confirm) => {
        if (confirm) {
            handleAddRepair({
                repairId: unsavedNewRepair.repairId,
                internalApprovalProvidedById: unsavedNewRepair.internalApprovalProvidedById,
                isValidateLaborCapacity: false,
            });
        }
        setShowCapacityWarning(false);
        setUnsavedNewRepair(undefined);
    };
    const handleSearchRepair = (data, fleetId) => dispatch(searchRepairs(data, fleetId));
    const submitCustomRepair = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isSubmitting || customRepairText.length === 0)
            return;
        if (isFmcRo) {
            goToTab(PartsHelpTabs.FMC_CUSTOM_REPAIR);
        }
        else {
            setIsSubmitting(true);
            if (currentAction === PartsHelpTabs.ADDITIONAL_REPAIRS_REQUIRED) {
                if (invoice) {
                    yield handleAddCustomRepairInvoice({
                        repairDescription: customRepairText,
                    });
                }
                else {
                    yield handleAddCustomRepair();
                }
            }
            else if (currentAction === PartsHelpTabs.PRICING_QUOTE) {
                yield submitRecommendedRepair();
            }
            setIsSubmitting(false);
            handleClose();
        }
    });
    const submitUrgentPartsNote = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isSubmitting)
            return;
        const params = {
            entityId: repairRequest.id,
            entityType: NotesEntityTypes.REPAIR_REQUEST,
            origin: NotesOriginTypes.TOOLS,
            content: SUB_TYPE_DESCRIPTION.URGENT_PENDING_PARTS,
            repairRequestId: repairRequest.id,
            visibility: 'INTERNAL',
            urgency: NotesUrgency.NORMAL,
            appliesTo: null,
            base64PhotoStrings: null,
            type: 'PART_UPDATE',
            subType: SUB_TYPE_DESCRIPTION.URGENT_PENDING_PARTS,
        };
        setIsSubmitting(true);
        const response = yield dispatch(addRequestNotes(params));
        setIsSubmitting(false);
        if (!response.error) {
            toast.success('Urgent Parts note added successfully');
            handleClose();
        }
    });
    const handleSubmitConfirmNewRP = () => {
        var _a, _b;
        setFleetVehicleRP({
            vin: (_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin) !== null && _a !== void 0 ? _a : '',
            responsiblePartyId: responsiblePartyId,
            fleetId: (_b = fleet === null || fleet === void 0 ? void 0 : fleet.id) !== null && _b !== void 0 ? _b : '',
            carId: vehicle === null || vehicle === void 0 ? void 0 : vehicle.consumerCarId,
        });
        setOpenConfirmation(false);
    };
    const handleSubmitCancelNewRP = () => {
        setFleetVehicleRP({
            vin: '',
            responsiblePartyId: '',
            fleetId: '',
            carId: undefined,
        });
        setOpenConfirmation(false);
    };
    return {
        handleSearchRepair,
        showCapacityWarning,
        handleCapacityWarningClose,
        capacityWarningMessage,
        requestData,
        goToTab,
        setCurrentAction,
        disabledTabs,
        goToSelectComponentType,
        repair,
        handleAddPart,
        handleOpenAddFluid,
        handleOpenAddLabor,
        setRepairId,
        handleRepairSubmit,
        repairId,
        isSubmitting,
        setRepair,
        setCustomRepairText,
        submitCustomRepair,
        onFmcRepairSelect,
        customRepairText,
        handleCustomFmcRepairSubmit,
        fmcRepairId,
        setIsCustomerProvidedPartsValid,
        selectedCustomerParts,
        isCheckedAllCustomerParts,
        setSelectedCustomerParts,
        setIsCheckedAllCustomerParts,
        isPartSelected,
        additionalRepairComponents,
        setAdditionalRepairComponents,
        isCustomerProvidedPartsValid,
        handleSubmitBatch,
        goToSelectRepairComponent,
        selectedPart,
        setSelectedPart,
        handleFaultyPart,
        handleClose,
        setWrongPartOpen,
        tab,
        goBack,
        wrongPartOpen,
        referenceNum,
        submitUrgentPartsNote,
        handleRepairSelect,
        fleet,
        vehicle,
        handleResponsiblePartySelect,
        openConfirmation,
        setOpenConfirmation,
        handleSubmitConfirmNewRP,
        fleetVehicleRP,
        responsiblePartyId,
        saveFleetVehicleRP,
        handleSubmitCancelNewRP,
    };
};
export default usePartsHelpDrawer;
