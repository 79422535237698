/* eslint-disable @typescript-eslint/no-duplicate-enum-values  */
export var StatusesMainColors;
(function (StatusesMainColors) {
    StatusesMainColors["READY"] = "#3656d5";
    StatusesMainColors["EN_ROUTE"] = "#e732f7";
    StatusesMainColors["ON_SITE"] = "#9482ef";
    StatusesMainColors["IN_PROGRESS"] = "#f4b63f";
    StatusesMainColors["COMPLETE"] = "#53b68b";
    StatusesMainColors["PENDING_ALLOCATION"] = "#3656d5";
    StatusesMainColors["QUEUED"] = "#3656d5";
})(StatusesMainColors || (StatusesMainColors = {}));
export const statuses = [
    {
        value: 'READY',
        label: 'Ready',
        color: StatusesMainColors.READY,
    },
    {
        value: 'EN_ROUTE',
        label: 'En Route',
        color: StatusesMainColors.EN_ROUTE,
    },
    {
        value: 'ARRIVED',
        label: 'Arrived',
        color: StatusesMainColors.ON_SITE,
    },
    {
        value: 'IN_PROGRESS',
        label: 'In Progress',
        color: StatusesMainColors.IN_PROGRESS,
    },
    {
        value: 'COMPLETE',
        label: 'Completed',
        color: StatusesMainColors.COMPLETE,
    },
];
export const DraggableStatuses = [
    'READY',
    'PENDING_ALLOCATION',
    'QUEUED',
    'PENDING_DISPATCH',
];
