import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const HelpList = styled.ul `
  list-style: none;
  padding-left: 0;

  li {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    color: ${colors.GRAY_3F};
    padding: 0;
    border-top: 1px solid ${colors.lightGrey};

    &:last-child {
      border-bottom: 1px solid ${colors.lightGrey};
    }

    button {
      width: 99%;
      padding: 24px 0;
      color: ${colors.GRAY_3F};
      cursor: pointer;
      justify-content: space-between;

      :hover {
        background: none;
      }
    }
  }
`;
