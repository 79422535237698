import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import RepairSearchSelect from 'src/components/form/RepairSearchSelect';
import { Container } from 'src/AdminApp/containers/fleets/tabs/styles';
import NewRuleDrawer from 'src/AdminApp/containers/fleets/tabs/pricing/NewRuleDrawer';
import PricingRule from '../PricingRule';
import { RuleTitle } from 'src/AdminApp/containers/fleets/tabs/pricing/NewRuleDrawer/NewRuleDrawer.styles';
import usePricingRules from './usePricingRules';
const Block = styled.div `
  flex: 1;
  padding-right: 16px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    flex: 2;
    padding-right: 0;
  }
`;
const AddRepairContainer = styled.div `
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid ${colors.lightGrey};
`;
const PricingRules = () => {
    var _a;
    const { selectedRepair, selectedRepairName, handleSearchRepair, onRepairSelect, getFleetRules, existingRules, isPricingRulesEditor, fleetId, isAddRepairOpen, handleDrawerClose, } = usePricingRules();
    useEffect(() => {
        getFleetRules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<Container>
      <Block>
        {isPricingRulesEditor && (<AddRepairContainer>
            <RuleTitle>Add a Repair</RuleTitle>
            <RepairSearchSelect onSelect={onRepairSelect} onSearch={handleSearchRepair} onCustomRepairSubmit={() => { }} autoFocus={false} fleetId={fleetId} InputProps={{
                variant: 'outlined',
                label: undefined,
                style: { width: '55%' },
            }} showCustomSuggestions={false}/>
          </AddRepairContainer>)}
        <RuleTitle>Existing Repairs</RuleTitle>
        {(_a = existingRules === null || existingRules === void 0 ? void 0 : existingRules.products) === null || _a === void 0 ? void 0 : _a.map((rule) => (<PricingRule rule={rule} onEdit={onRepairSelect}/>))}
      </Block>

      <NewRuleDrawer open={isAddRepairOpen} onClose={handleDrawerClose} onSubmit={getFleetRules} repairId={selectedRepair} repairName={selectedRepairName} fleetId={fleetId}/>
    </Container>);
};
export default PricingRules;
