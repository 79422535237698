/* eslint-disable default-param-last */
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_WITH_SECONDS, DATE_YMD_FORMAT, TIME_12H_FORMAT, TIME_FORMAT, TIME_ZONE_FORMAT, WEEK_DAY_FORMAT, } from 'src/timeConstants';
const TOTAL_HOURS = 24;
const PIXELS_PER_HOUR = 90;
export const getTravelTimeTailSize = (travelTime) => {
    const minutes = Math.floor(Math.round(travelTime / 60));
    return (minutes * 90) / 60;
};
export const getJobSize = (startTime, endTime, timezone) => {
    const convertedStartTime = convertToTimezone(startTime, timezone);
    const convertedEndTime = convertToTimezone(endTime, timezone);
    const start = moment(convertedStartTime, DATE_TIME_FORMAT_WITH_SECONDS);
    const end = moment(convertedEndTime, DATE_TIME_FORMAT_WITH_SECONDS);
    const timeDifference = moment.duration(end.diff(start));
    const minutesDifference = timeDifference.asMinutes();
    const elementWidth = (minutesDifference / 60) * PIXELS_PER_HOUR;
    return elementWidth;
};
export const getPosition = (startTime, selectedDate, timezone) => {
    const convertedTime = timezone
        ? convertToTimezone(startTime, timezone)
        : startTime;
    const time = moment(convertedTime, DATE_TIME_FORMAT);
    const totalMinutes = time.diff(moment(selectedDate).startOf('day'), 'minutes');
    const position = totalMinutes * (PIXELS_PER_HOUR / 60);
    return position;
};
export const getHourFromPosition = (position, format = TIME_12H_FORMAT) => {
    const completeHours = Math.floor(position / PIXELS_PER_HOUR);
    const minutesLeft = Math.floor(((position % PIXELS_PER_HOUR) * 60) / PIXELS_PER_HOUR);
    const date = moment()
        .startOf('day')
        .add(completeHours, 'hours')
        .add(minutesLeft, 'minutes');
    const formattedHour = date.format(format);
    return formattedHour;
};
export const getTimezoneCurrentTime = (timeZone, getOnlyTime = false) => {
    const currentDatetime = momentTimezone.tz(timeZone);
    const abbrTimezone = currentDatetime.format(TIME_ZONE_FORMAT);
    if (getOnlyTime) {
        return currentDatetime.format(TIME_12H_FORMAT);
    }
    return `${abbrTimezone} Timezone - ${currentDatetime.format(TIME_12H_FORMAT)}`;
};
export const getPositionFromTimezone = (timeZone, selectedDate) => {
    const timezoneDatetime = momentTimezone.tz(timeZone);
    const formattedDatetime = timezoneDatetime.format(TIME_FORMAT);
    const formattedSelected = moment(selectedDate).format(DATE_YMD_FORMAT);
    const date = moment(`${formattedSelected}T${formattedDatetime}`).format(DATE_TIME_FORMAT);
    const position = getPosition(date, selectedDate);
    return position;
};
const convertToNumerical = (time) => {
    const [hourStr, minuteStr, secondStr] = time.split(':');
    const hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);
    const second = parseInt(secondStr.split(' ')[0], 10);
    return hour + minute / 60 + second / 3600;
};
export const getColorPercentages = (startTime, endTime, format = false, timezone = '', additionalTime) => {
    const start = format
        ? convertToTimezone(startTime, timezone, TIME_FORMAT)
        : startTime;
    const newEndTime = !additionalTime
        ? endTime
        : moment(endTime).add(additionalTime, 'minutes').format(DATE_TIME_FORMAT);
    const end = format
        ? convertToTimezone(newEndTime, timezone, TIME_FORMAT)
        : endTime;
    const startPercentage = (convertToNumerical(start) / TOTAL_HOURS) * 100;
    const endPercentage = (convertToNumerical(end) / TOTAL_HOURS) * 100;
    return {
        startPercentage,
        endPercentage,
    };
};
export const getAvailabilityColorPercentages = (timeRanges, format = false, timezone = '') => {
    const percentages = timeRanges.map(({ startTime, endTime }) => {
        const start = format
            ? moment(convertToTimezone(startTime, timezone)).format(TIME_FORMAT)
            : startTime;
        const end = format
            ? moment(convertToTimezone(endTime, timezone)).format(TIME_FORMAT)
            : endTime;
        return getColorPercentages(start, end);
    });
    return percentages;
};
export const findNewVan = (clientY, potentialParents) => {
    var _a, _b;
    // eslint-disable-next-line no-restricted-syntax
    for (const parent of potentialParents) {
        const parentRect = parent.getBoundingClientRect();
        if (clientY >= parentRect.top && clientY <= parentRect.bottom) {
            if ((_a = parent.dataset) === null || _a === void 0 ? void 0 : _a.van) {
                return (_b = parent.dataset) === null || _b === void 0 ? void 0 : _b.van;
            }
        }
    }
    return null;
};
export const getDayAvailabilityTemplate = (templates, selectedDate) => {
    if (!templates || templates.length === 0) {
        return null;
    }
    const day = moment(selectedDate).format(WEEK_DAY_FORMAT).toLowerCase();
    const entries = templates.reduce((acc, el) => {
        const entry = el.entries.find((entry) => entry.weekday.toLowerCase() === day);
        if (entry) {
            acc.push({
                startTime: entry.startTime,
                endTime: entry.finishTime,
            });
        }
        return acc;
    }, []);
    if (!entries || entries.length === 0)
        return null;
    return entries.sort((a, b) => {
        const startTimeA = new Date(`${selectedDate}T${a.startTime}`);
        const startTimeB = new Date(`${selectedDate}T${b.startTime}`);
        return startTimeA.getTime() - startTimeB.getTime();
    });
};
export const isJobInsideTemplate = (entries, newStartTime, duration, unavailabilityCheck) => {
    if (!entries || entries.length === 0) {
        return false;
    }
    const newEnd = moment(moment(newStartTime, TIME_12H_FORMAT).format(TIME_FORMAT), TIME_FORMAT).add(duration, 'minutes');
    const entry = entries.find((entry) => {
        const { startTime, endTime } = entry;
        const tzStartBefore = moment(endTime, TIME_FORMAT);
        const tzStartAfter = moment(startTime, TIME_FORMAT);
        const newStart = moment(moment(newStartTime, TIME_12H_FORMAT).format(TIME_FORMAT), TIME_FORMAT);
        if (unavailabilityCheck) {
            return ((newStart.isAfter(tzStartAfter) &&
                newStart.isBefore(tzStartBefore)) ||
                (newEnd.isAfter(tzStartAfter) && newEnd.isBefore(tzStartBefore)) ||
                (tzStartAfter.isAfter(newStart) && tzStartBefore.isBefore(newEnd)));
        }
        return (newStart.isSameOrAfter(tzStartAfter) && newStart.isBefore(tzStartBefore));
    });
    if (entry) {
        return true;
    }
    return false;
};
export const convertToTimezone = (jobTime, timezone, format) => {
    const parsedDateTime = moment.tz(jobTime, 'UTC');
    const convertedDateTime = parsedDateTime.tz(timezone);
    if (format) {
        return convertedDateTime.format(format);
    }
    return convertedDateTime.format();
};
export const calculateArrivalWindow = (timeConstraint, hour, timezone) => {
    if (!timeConstraint)
        return false;
    const { startBefore, startAfter } = timeConstraint;
    const tzStartBefore = moment(convertToTimezone(startBefore, timezone, TIME_FORMAT), TIME_FORMAT);
    const tzStartAfter = moment(convertToTimezone(startAfter, timezone, TIME_FORMAT), TIME_FORMAT);
    const newHour = moment(moment(hour, TIME_12H_FORMAT).format(TIME_FORMAT), TIME_FORMAT);
    return newHour.isSameOrAfter(tzStartAfter) && newHour.isBefore(tzStartBefore);
};
export const isRegionTimezoneSameDay = (regionTime) => {
    const startTime = moment().startOf('day');
    const endTime = moment().endOf('day');
    const inputMoment = moment(regionTime, TIME_12H_FORMAT);
    return inputMoment.isBetween(startTime, endTime);
};
export const getOverlappingJobs = (job, data) => {
    const overlap = [];
    const earlierJobs = data
        .filter((el) => el.id !== job.id &&
        new Date(el.repairJob.endDate) <= new Date(job.repairJob.endDate))
        .sort((a, b) => {
        const endDateA = new Date(a.repairJob.endDate);
        const endDateB = new Date(b.repairJob.endDate);
        return endDateB.getTime() - endDateA.getTime();
    });
    const laterJobs = data
        .filter((el) => el.id !== job.id &&
        new Date(el.repairJob.endDate) > new Date(job.repairJob.endDate) &&
        new Date(job.repairJob.endDate) >= new Date(el.repairJob.startDate))
        .sort((a, b) => {
        const endDateA = new Date(a.repairJob.endDate);
        const endDateB = new Date(b.repairJob.endDate);
        return endDateB.getTime() - endDateA.getTime();
    });
    if (laterJobs.length > 0)
        return null;
    for (let i = 0; i < earlierJobs.length; i += 1) {
        const current = earlierJobs[i];
        const findAfter = (laterJobs !== null && laterJobs !== void 0 ? laterJobs : []).find((el) => new Date(current.repairJob.endDate) < new Date(el.repairJob.endDate) &&
            new Date(current.repairJob.endDate) > new Date(el.repairJob.startDate));
        if (!findAfter) {
            if (new Date(current.repairJob.endDate) > new Date(job.repairJob.startDate)) {
                overlap.push(current);
            }
            else if (overlap.length > 0 &&
                new Date(current.repairJob.endDate) >
                    new Date(overlap[overlap.length - 1].repairJob.startDate)) {
                overlap.push(current);
            }
        }
    }
    if (overlap.length === 0)
        return null;
    const filtered = overlap.filter((job) => job.repairJob.type !== 'LUNCH');
    if (filtered.length === 0 || job.repairJob.type === 'LUNCH')
        return null;
    return filtered;
};
