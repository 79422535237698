import { TextField, Autocomplete } from 'src/components/mui';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useDebounce from 'src/modules/api/utils/useDebounce';
import { searchLocationsHubs } from 'src/SystemApp/modules/locations/actions';
const HubSearchSingleValue = ({ setSelectedLocation, options, value, disabled = false, }) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const searchLocationsDebounce = useDebounce(search, 500);
    useEffect(() => {
        if ((searchLocationsDebounce === null || searchLocationsDebounce === void 0 ? void 0 : searchLocationsDebounce.length) > 1) {
            dispatch(searchLocationsHubs({ name: searchLocationsDebounce }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchLocationsDebounce]);
    const handleLocationsSelected = (e, newLocations) => {
        setSelectedLocation(newLocations);
    };
    return (<Autocomplete disabled={disabled} options={options} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} onChange={(e, newValue) => handleLocationsSelected(e, newValue)} onInputChange={(event, newInputValue, reason) => {
            if (reason === 'input') {
                setSearch(newInputValue);
            }
        }} value={value ? value : null} renderInput={(params) => (<TextField {...params} variant="outlined" placeholder="Search by name" label="Hub Search" name="locationsSelect"/>)}/>);
};
export default HubSearchSingleValue;
