import React, { useState } from 'react';
import moment from 'moment';
import { Grid } from 'src/components/mui';
import { ArrowDropDownRounded as ArrowDropDownRoundedIcon } from 'src/components/mui/icons';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { Platforms } from 'src/AdminApp/models/enums';
import { selectServiceAdvisors, selectTechnicians, } from 'src/AdminApp/modules/users/selectors';
import EditableSearchUsers from 'src/components/form/EditableSearchUsers';
import EditableInput from 'src/components/form/EditableInput';
import EditableSelect from 'src/components/form/EditableSelect';
import { formatDate } from 'src/utils/formatter';
import { addProtocol } from 'src/AdminApp/utils/url-utils';
import { Stars } from 'src/AdminApp/components/form';
import { GridLabelItem, Label, DateSelector, DateValue, } from 'src/AdminApp/components/layout/Module';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const Form = ({ rating, reviewDate, serviceWriter, technician, platform, url, reviewText, onUpdate, }) => {
    const [showEditDate, setShowEditDate] = useState(false);
    const [hoveredRating, setHoveredRating] = useState(rating);
    const onDateChange = (value, onChange) => {
        onChange(value.utc().toISOString());
        setShowEditDate(false);
    };
    return (<Grid container>
      <GridLabelItem item xs={12}>
        <Label> Rating </Label>
        <Stars numberOfStars={5} rating={rating} hovered={hoveredRating} onChange={(rating) => onUpdate({ rating })} onHover={(value) => setHoveredRating(value)} onHoverOut={() => setHoveredRating(rating)}/>
      </GridLabelItem>

      <GridLabelItem item xs={12}>
        <Label> Service Writer </Label>
        <EditableSearchUsers value={serviceWriter} defaultValue="None" usersSelector={selectServiceAdvisors} onUpdate={(serviceWriter) => onUpdate({ serviceWriter })}/>
      </GridLabelItem>

      <GridLabelItem item xs={12}>
        <Label> Technician </Label>
        <EditableSearchUsers value={technician !== null && technician !== void 0 ? technician : ''} defaultValue="None" usersSelector={selectTechnicians} onUpdate={(technician) => {
            onUpdate({ technician });
        }}/>
      </GridLabelItem>

      <GridLabelItem item xs={12}>
        <Label> Date </Label>
        <DateSelector onClick={() => setShowEditDate(true)}>
          <DateValue>{formatDate(reviewDate, LOCALIZED_DATE_FORMAT)}</DateValue>
          <ArrowDropDownRoundedIcon />
        </DateSelector>
        {showEditDate && (<SimpleDatePicker open={showEditDate} value={moment(reviewDate)} onChange={(date) => onDateChange(date, (reviewDate) => onUpdate({ reviewDate }))} onClose={() => setShowEditDate(false)}/>)}
      </GridLabelItem>

      <GridLabelItem item xs={12}>
        <Label> Platform</Label>
        <EditableSelect onUpdate={(platform) => onUpdate({ platform })} value={platform} defaultValue="Select Platform..." enumeration={Platforms} unsetOption={false}/>
      </GridLabelItem>

      <GridLabelItem item xs={12}>
        <Label> Review URL </Label>
        <EditableInput value={url} displayValue={url ? (<a target="_blank" href={addProtocol(url)} rel="noreferrer">
                {url}
              </a>) : ('Paste Url')} defaultValue="Paste Url..." onUpdate={(url) => onUpdate({ url })}/>
      </GridLabelItem>

      <GridLabelItem item xs={12}>
        <Label> Review </Label>
      </GridLabelItem>
      <Grid item xs={12}>
        <div style={{ width: '100%' }}>
          <EditableInput inputProps={{
            multiline: true,
            rows: 4,
            variant: 'outlined',
        }} value={reviewText} defaultValue="Paste Review..." onUpdate={(reviewText) => onUpdate({ reviewText })}/>
        </div>
      </Grid>
    </Grid>);
};
export default Form;
