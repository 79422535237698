var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { saveCoresReturnedItems } from 'src/PopsApp/modules/inventory/actions';
const useCreateCoreDrawer = ({ onClose, onFinish, referenceNum, vanId, requestCoreCharges, }) => {
    const dispatch = useDispatch();
    const [partNumbers, setPartNumbers] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleClose = () => {
        setPartNumbers(undefined);
        onClose();
    };
    const handleChangePartNumber = (value, coreChargeId) => {
        setPartNumbers(Object.assign(Object.assign({}, (partNumbers !== null && partNumbers !== void 0 ? partNumbers : {})), { [coreChargeId]: value }));
    };
    const handleCreateCore = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!vanId || isSubmitting)
            return;
        setIsSubmitting(true);
        // CONVERT PART TO INVENTORY ITEM
        const returnedItems = (_a = requestCoreCharges === null || requestCoreCharges === void 0 ? void 0 : requestCoreCharges.map((part) => {
            var _a, _b, _c;
            return ({
                partId: part.id,
                partNumber: Object.keys(partNumbers).length > 0 && partNumbers[part.id]
                    ? partNumbers[part.id]
                    : null,
                status: 'PENDING',
                quantity: 1,
                locationId: vanId,
                partName: part.name,
                unitType: 'ITEM',
                unitCost: part.coreChargeCost,
                poNumber: referenceNum,
                partDistributorId: (_a = part.componentPartDistributor) === null || _a === void 0 ? void 0 : _a.partDistributorId,
                partDistributorName: (_b = part.componentPartDistributor) === null || _b === void 0 ? void 0 : _b.partDistributorName,
                partDistributorLocationId: (_c = part.componentPartDistributor) === null || _c === void 0 ? void 0 : _c.partDistributorLocationId,
            });
        })) !== null && _a !== void 0 ? _a : [];
        const response = yield dispatch(saveCoresReturnedItems(returnedItems));
        if (!response.error) {
            toast.success('Core return created successfully');
            setPartNumbers(undefined);
            onFinish();
        }
        setIsSubmitting(false);
    });
    return {
        handleClose,
        handleCreateCore,
        isSubmitting,
        handleChangePartNumber,
    };
};
export default useCreateCoreDrawer;
