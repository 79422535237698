import { combineReducers } from 'redux';
import inventoryReducer from 'src/PopsApp/modules/inventory/reducers';
import hubsReducer from './hubs/reducers';
import locationsReducer from './locations/reducers';
import utilitiesReducer from './utilities/reducers';
export default combineReducers({
    utilities: utilitiesReducer,
    hubs: hubsReducer,
    locations: locationsReducer,
    inventory: inventoryReducer,
});
