import React from 'react';
import { Grid } from 'src/components/mui';
import { Heading } from './MobileDashboard.styles';
import Calendar from '../DatePicker/components/Calendar';
import Vans from './components/Vans';
const MobileDashboard = () => {
    return (<Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }} direction="column" spacing={2}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Heading>Appointments</Heading>
        </Grid>
        <Grid container item xs={6} style={{ justifyContent: 'flex-end' }}>
          <Calendar />
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ justifyContent: 'center' }} direction="column" spacing={2}>
        <Vans />
      </Grid>
    </Grid>);
};
export default MobileDashboard;
