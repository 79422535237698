import { toast } from 'src/components/SimpleToast';
const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        const successful = document.execCommand('copy');
        toast.success(`Copying text was ${successful ? 'successful' : 'unsuccessful'}`);
    }
    catch (err) {
        toast.error('Oops, unable to copy: ', err.toString());
    }
    document.body.removeChild(textArea);
};
const copyToClipboard = (text, customMessage) => {
    if (!window.navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    window.navigator.clipboard.writeText(text).then(() => {
        toast.success(customMessage || `Copied ${text} successfully to clipboard!`);
    }, (err) => {
        toast.error('Oops, unable to copy: ', err);
    });
};
export default copyToClipboard;
