import { colors, FONT_FAMILY } from 'src/styles/theme';
import styled from 'styled-components';
export const TooltipContainer = styled.section `
  padding: 18px 19px;
`;
export const RepairsTooltipTitle = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.white};
`;
export const RepairsTooltipList = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.1px;
  color: ${colors.white};
  margin-top: 4px;
`;
export const CancelationReasonTooltip = styled.span `
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${colors.white};
`;
export const LockTitle = styled.div `
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.9;
  letter-spacing: -0.08px;
  color: ${colors.white};
`;
export const LockText = styled.div `
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.1px;
  margin-top: 10px;
  color: ${colors.white};
`;
export const LockIconStyle = styled.div `
  svg {
    color: ${colors.pink};

    &:hover {
      color: ${colors.black};
    }
  }
`;
