var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Grid, DatePicker } from 'src/components/mui';
import { RequestStatusSelectForm, AppointmentStatusSelectForm, OrderStatusSelectForm, DeliveryChannelSelectForm, OrderResolutionSelectForm, PartsStatusSelectForm, } from 'src/AdminApp/components/form/Statuses';
import MarketingSourceSelect from 'src/AdminApp/containers/requests/MarketingSourceSelect';
import FilterButtons from 'src/components/dashboard/DashboardFilter/FilterButtons';
import FilterBlock from 'src/components/dashboard/DashboardFilter/FilterBlock';
import { DATE_YMD_FORMAT, LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const FullWidthDatePicker = styled(DatePicker) `
  width: 100%;
`;
const DashboardFilters = ({ filters, onClear, onReset, onSearch, }) => {
    const [submitting, setSubmitting] = useState(false);
    const clearFilter = () => {
        onReset();
        onClear();
    };
    const handleSubmit = (_a) => __awaiter(void 0, void 0, void 0, function* () {
        var { query } = _a, rest = __rest(_a, ["query"]);
        setSubmitting(true);
        const values = Object.assign({ query: query.trim() }, rest);
        try {
            yield onSearch(values);
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setSubmitting(false);
        }
    });
    return (<Formik enableReinitialize initialValues={{
            appointmentFrom: filters.appointmentFrom || null,
            appointmentTo: filters.appointmentFrom || null,
            createdFrom: filters.createdFrom || null,
            createdTo: filters.createdTo || null,
            query: filters.query || '',
            deliveryChannel: filters.deliveryChannel || '',
            orderStatus: filters.orderStatus || '',
            appointmentStatus: filters.appointmentStatus || '',
            requestStatus: filters.requestStatus || '',
            marketingSource: filters.marketingSource || '',
            partsStatus: filters.partsStatus || '',
            orderResolution: filters.orderResolution || '',
        }} onSubmit={handleSubmit}>
      {({ setFieldValue, values }) => (<Form>
          <FilterBlock>
            <Grid item xs={12} md={6} lg={3}>
              <FullWidthDatePicker 
        /* autoOk */
        disabled={submitting} format={LOCALIZED_DATE_FORMAT} label="Appointment Date From" name="appointmentFrom" value={moment(values.appointmentFrom)} onChange={(date) => {
                setFieldValue('appointmentFrom', date === null || date === void 0 ? void 0 : date.format(DATE_YMD_FORMAT));
            }}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FullWidthDatePicker disabled={submitting} format={LOCALIZED_DATE_FORMAT} label="Appointment Date To" name="appointmentTo" value={moment(values.appointmentTo)} onChange={(date) => {
                setFieldValue('appointmentTo', date === null || date === void 0 ? void 0 : date.format(DATE_YMD_FORMAT));
            }}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FullWidthDatePicker 
        /* autoOk */
        disabled={submitting} format={LOCALIZED_DATE_FORMAT} label="Created Date From" name="createdFrom" value={moment(values.createdFrom)} onChange={(date) => {
                setFieldValue('createdFrom', date === null || date === void 0 ? void 0 : date.format(DATE_YMD_FORMAT));
            }}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FullWidthDatePicker 
        /* autoOk */
        disabled={submitting} format={LOCALIZED_DATE_FORMAT} label="Created Date To" name="createdTo" value={moment(values.createdTo)} onChange={(date) => {
                setFieldValue('createdTo', date === null || date === void 0 ? void 0 : date.format(DATE_YMD_FORMAT));
            }}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <RequestStatusSelectForm disabled={submitting} name="requestStatus" label="Request Status"/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppointmentStatusSelectForm disabled={submitting} name="appointmentStatus" label="Appointment Status"/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderStatusSelectForm disabled={submitting} name="orderStatus" label="RO Status"/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderResolutionSelectForm disabled={submitting} name="orderResolution" label="RO Resolution"/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DeliveryChannelSelectForm disabled={submitting} name="deliveryChannel" label="Delivery Channel"/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MarketingSourceSelect fullWidth disabled={submitting} name="marketingSource" label="Marketing Source"/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <PartsStatusSelectForm fullWidth disabled={submitting} name="partsStatus" label="Parts Status"/>
            </Grid>
          </FilterBlock>
          <FilterButtons onClear={clearFilter} onReset={onReset} queryLabelText="Email, name, phone, reference #, VIN, plate #" isSubmitting={submitting}/>
        </Form>)}
    </Formik>);
};
export default DashboardFilters;
