var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { lookupRequestIdByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { toast } from 'src/components/SimpleToast';
import { SNAPSHOT_LOAD_TIMEOUT } from 'src/constants';
import createAPIAction from 'src/modules/api';
import { selectAccessToken, selectAuthorizedSnapshotViewer, } from 'src/modules/auth/selectors';
import { downloadInvoicePdf, downloadQuotePdf } from 'src/utils/downloadUtils';
import createDefaultActionCreators from 'src/utils/createActions';
import { getTechnicianOnSiteWorkflowByRef } from 'src/AdminApp/modules/workflows/actions';
import { getRepairMeasurements, getRequestUntilExpectedState, loadRequestSnapshot, } from 'src/AdminApp/modules/requests/actions';
import { getDiscounts } from 'src/AdminApp/modules/workOrders/actions';
import API_URLS from 'src/AdminApp/modules/api/urls';
// FSA ACTIONS
export const ActionCreators = {
    INVOICE: createDefaultActionCreators('INVOICE'),
    INVOICES: createDefaultActionCreators('INVOICES'),
    CREATE_INVOICE: createDefaultActionCreators('CREATE_INVOICE'),
    UPDATE_INVOICE: createDefaultActionCreators('UPDATE_INVOICE'),
    ADD_REPAIR: createDefaultActionCreators('ADD_REPAIR'),
    ADD_CUSTOM_REPAIR: createDefaultActionCreators('ADD_CUSTOM_REPAIR'),
    REMOVE_REPAIR: createDefaultActionCreators('REMOVE_REPAIR'),
    REMOVE_DIAGNOSE: createDefaultActionCreators('REMOVE_DIAGNOSE'),
    REMOVE_CUSTOM_REPAIR: createDefaultActionCreators('REMOVE_CUSTOM_REPAIR'),
    ADD_COMPONENT: createDefaultActionCreators('ADD_COMPONENT'),
    REMOVE_COMPONENT: createDefaultActionCreators('REMOVE_COMPONENT'),
    REMOVE_CUSTOM_COMPONENT: createDefaultActionCreators('REMOVE_CUSTOM_COMPONENT'),
    ADD_CUSTOM_COMPONENT: createDefaultActionCreators('ADD_CUSTOM_COMPONENT'),
    UPDATE_COMPONENT: createDefaultActionCreators('UPDATE_COMPONENT'),
    UPDATE_CUSTOM_COMPONENT: createDefaultActionCreators('UPDATE_CUSTOM_COMPONENT'),
    UPDATE_TOTAL_COST: createDefaultActionCreators('UPDATE_TOTAL_COST'),
    UPDATE_SUGGESTED_REPAIR: createDefaultActionCreators('UPDATE_SUGGESTED_REPAIR'),
    SEND_INVOICE: createDefaultActionCreators('SEND_INVOICE'),
    AUTHORIZE_INVOICE: createDefaultActionCreators('AUTHORIZE_INVOICE'),
    INVOICE_CALCULATE_TAX: createDefaultActionCreators('INVOICE_CALCULATE_TAX'),
    INVOICE_ADD_PAYMENT: createDefaultActionCreators('INVOICE_ADD_PAYMENT'),
    INVOICE_REMOVE_PAYMENT: createDefaultActionCreators('INVOICE_REMOVE_PAYMENT'),
    INVOICE_UPDATE_NOTES: createDefaultActionCreators('INVOICE_UPDATE_NOTES'),
    REMOVE_SUBLET: createDefaultActionCreators('REMOVE_SUBLET'),
    DOWNLOAD_INVOICE: createDefaultActionCreators('DOWNLOAD_INVOICE'),
    INVOICE_CREATE_SUBLET: createDefaultActionCreators('INVOICE_CREATE_SUBLET'),
    UPDATE_INVOICE_REPAIR_COMPONENTS_BATCH: createDefaultActionCreators('UPDATE_INVOICE_REPAIR_COMPONENTS_BATCH'),
};
export const getInvoiceByWorkOrderId = ({ workOrderId }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.INVOICE.FETCH(),
            ActionCreators.INVOICE.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.INVOICE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.ORDER_INVOICE({ workOrderId }),
        method: 'GET',
    }));
    if (response.error) {
        return response;
    }
    dispatch(getDiscounts({ workOrderId }));
    return response;
});
export const getInvoicesByWorkOrderId = ({ workOrderId }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.INVOICES.FETCH(),
            ActionCreators.INVOICES.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.INVOICES.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.ORDER_INVOICES({ workOrderId }),
        method: 'GET',
    }));
    if (response.error) {
        return response;
    }
    dispatch(getDiscounts({ workOrderId }));
    return response;
});
export const createInvoice = ({ requestId, referenceNum }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const canViewSnapshotRole = selectAuthorizedSnapshotViewer(getState());
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.CREATE_INVOICE.FETCH(),
            ActionCreators.CREATE_INVOICE.SUCCESS({
                meta: () => ({ requestId }),
            }),
            ActionCreators.CREATE_INVOICE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.INVOICE_CREATE({ requestId }),
        method: 'PUT',
    }));
    if (canViewSnapshotRole) {
        setTimeout(() => dispatch(loadRequestSnapshot({ referenceNum })), SNAPSHOT_LOAD_TIMEOUT);
    }
    return response;
});
export const createInvoiceAndCompleteJob = (referenceNum, requestId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createInvoice({ requestId, referenceNum }));
    if (!response.error) {
        yield dispatch(getRequestUntilExpectedState(referenceNum, requestId, 'CLOSED_REPAIR'));
        dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
    }
});
export const updateInvoiceByWorkOrderId = ({ hazardousMaterialsFee, deliveryFee, cancelFee, laborTaxRate, laborTaxAmount, laborTotalPrice, partsTaxRate, partsTaxAmount, partsTotalPrice, shopSuppliesFee, adjustments, totalPrice, totalSublet, totalFees, subtotal, totalTaxes, discounts, totalPriceOverride, }, workOrderId) => createAPIAction({
    types: [
        ActionCreators.UPDATE_INVOICE.FETCH(),
        ActionCreators.UPDATE_INVOICE.SUCCESS({
            meta: () => ({ workOrderId }),
        }),
        ActionCreators.UPDATE_INVOICE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ORDER_INVOICE({ workOrderId }),
    method: 'PUT',
    body: {
        hazardousMaterialsFee,
        deliveryFee,
        cancelFee,
        laborTaxRate: laborTaxRate ? Number(laborTaxRate) / 100 : null,
        laborTaxAmount,
        laborTotalPrice,
        partsTaxRate: partsTaxRate ? Number(partsTaxRate) / 100 : null,
        partsTotalPrice,
        partsTaxAmount,
        shopSuppliesFee,
        adjustments,
        totalPrice,
        totalSublet,
        totalFees,
        subtotal,
        totalTaxes,
        discounts,
        totalPriceOverride,
    },
});
export const addRepair = ({ invoiceId, repairId, workOrderId, referenceNum, responsiblePartyId, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.ADD_REPAIR.FETCH(),
            ActionCreators.ADD_REPAIR.SUCCESS({
                meta: () => ({ invoiceId, repairId }),
            }),
            ActionCreators.ADD_REPAIR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.ADD_REPAIR_INVOICE({
            invoiceId,
            repairId,
        }),
        body: { responsiblePartyId },
        method: 'POST',
    }));
    if (!response.error) {
        toast.success('Repair successfully added');
    }
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
    dispatch(getRepairMeasurements(referenceNum));
    return response;
});
export const addCustomRepair = ({ invoiceId, repairDescription, workOrderId, responsiblePartyId, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.ADD_CUSTOM_REPAIR.FETCH(),
            ActionCreators.ADD_CUSTOM_REPAIR.SUCCESS({
                meta: () => ({ invoiceId, repairDescription }),
            }),
            ActionCreators.ADD_CUSTOM_REPAIR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.ADD_CUSTOM_REPAIR_INVOICE({
            invoiceId,
            repairDescription,
        }),
        body: { responsiblePartyId },
        method: 'POST',
    }));
    if (!response.error) {
        toast.success('Custom repair successfully added');
    }
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const removeRepair = (knownRepairId, workOrderId, referenceNum) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REMOVE_REPAIR.FETCH(),
            ActionCreators.REMOVE_REPAIR.SUCCESS({
                meta: () => ({ document }),
            }),
            ActionCreators.REMOVE_REPAIR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REMOVE_REPAIR_FROM_INVOICE(knownRepairId),
        method: 'DELETE',
    }));
    if (!response.error) {
        toast.success('Repair successfully deleted');
    }
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    dispatch(getRepairMeasurements(referenceNum));
    return response;
});
export const removeDiagnosis = (carDiagnosisId, workOrderId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REMOVE_DIAGNOSE.FETCH(),
            ActionCreators.REMOVE_DIAGNOSE.SUCCESS({
                meta: () => ({ document }),
            }),
            ActionCreators.REMOVE_DIAGNOSE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REMOVE_DIAGNOSE_FROM_INVOICE(carDiagnosisId),
        method: 'DELETE',
    }));
    if (!response.error) {
        toast.success('Diagnose successfully deleted');
    }
    dispatch(getInvoiceByWorkOrderId({ workOrderId }));
    return response;
});
export const removeCustomRepair = (customRequestId, workOrderId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REMOVE_CUSTOM_REPAIR.FETCH(),
            ActionCreators.REMOVE_CUSTOM_REPAIR.SUCCESS({
                meta: () => ({ document }),
            }),
            ActionCreators.REMOVE_CUSTOM_REPAIR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REMOVE_CUSTOM_REPAIR_FROM_INVOICE(customRequestId),
        method: 'DELETE',
    }));
    if (!response.error) {
        toast.success('Custom Repair successfully deleted');
    }
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const addComponent = ({ repairId, workOrderId, body, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.ADD_COMPONENT.FETCH(),
            ActionCreators.ADD_COMPONENT.SUCCESS({
                meta: () => ({ repairId, workOrderId }),
            }),
            ActionCreators.ADD_COMPONENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.COMPONENT_INVOICE(repairId),
        method: 'POST',
        body,
    }));
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const removeComponent = ({ repairId, workOrderId, componentId, componentType, removeDynamicRule = false, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REMOVE_COMPONENT.FETCH(),
            ActionCreators.REMOVE_COMPONENT.SUCCESS({
                meta: () => ({
                    repairId,
                    workOrderId,
                    componentId,
                    componentType,
                    removeDynamicRule,
                }),
            }),
            ActionCreators.REMOVE_COMPONENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.INVOICE_DELETE_COMPONENT({
            repairId,
            componentId,
            componentType,
            removeDynamicRule,
        }),
        method: 'DELETE',
    }));
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const updateComponent = ({ repairId, workOrderId, body, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.UPDATE_COMPONENT.FETCH(),
            ActionCreators.UPDATE_COMPONENT.SUCCESS({
                meta: () => ({ repairId, workOrderId }),
            }),
            ActionCreators.UPDATE_COMPONENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.COMPONENT_INVOICE(repairId),
        method: 'PATCH',
        body,
    }));
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const updateBatchRepairComponents = ({ referenceNum, workOrderId, body, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateSuggestedRepairAction = createAPIAction({
        types: [
            ActionCreators.UPDATE_INVOICE_REPAIR_COMPONENTS_BATCH.FETCH(),
            ActionCreators.UPDATE_INVOICE_REPAIR_COMPONENTS_BATCH.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.UPDATE_INVOICE_REPAIR_COMPONENTS_BATCH.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.INVOICE_SUGGESTED_REPAIR_COMPONENTS_BATCH_ADDITIONAL_REPAIR_COMPONENTS(),
        body,
        method: 'PATCH',
    });
    const response = yield dispatch(updateSuggestedRepairAction);
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const addCustomComponent = ({ repairId, workOrderId, body, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.ADD_CUSTOM_COMPONENT.FETCH(),
            ActionCreators.ADD_CUSTOM_COMPONENT.SUCCESS({
                meta: () => ({ repairId, workOrderId }),
            }),
            ActionCreators.ADD_CUSTOM_COMPONENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.CUSTOM_COMPONENT_INVOICE(repairId),
        method: 'POST',
        body,
    }));
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const removeCustomComponent = ({ repairId, workOrderId, componentId, componentType, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REMOVE_CUSTOM_COMPONENT.FETCH(),
            ActionCreators.REMOVE_CUSTOM_COMPONENT.SUCCESS({
                meta: () => ({ repairId, workOrderId, componentId, componentType }),
            }),
            ActionCreators.REMOVE_CUSTOM_COMPONENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.INVOICE_DELETE_CUSTOM_COMPONENT({
            repairId,
            componentId,
            componentType,
        }),
        method: 'DELETE',
    }));
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const updateCustomComponent = ({ repairId, workOrderId, body, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.UPDATE_CUSTOM_COMPONENT.FETCH(),
            ActionCreators.UPDATE_CUSTOM_COMPONENT.SUCCESS({
                meta: () => ({ repairId, workOrderId }),
            }),
            ActionCreators.UPDATE_CUSTOM_COMPONENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.CUSTOM_COMPONENT_INVOICE(repairId),
        method: 'PATCH',
        body,
    }));
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const updateSuggestedRepair = ({ suggestedRepairId, workOrderId, body, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.UPDATE_SUGGESTED_REPAIR.FETCH(),
            ActionCreators.UPDATE_SUGGESTED_REPAIR.SUCCESS({
                meta: () => ({ suggestedRepairId, workOrderId }),
            }),
            ActionCreators.UPDATE_SUGGESTED_REPAIR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.INVOICE_UPDATE_SUGGESTED_REPAIR(suggestedRepairId),
        method: 'PATCH',
        body,
    }));
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const sendInvoice = ({ invoiceId, workOrderId, referenceNum, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const canViewSnapshotRole = selectAuthorizedSnapshotViewer(getState());
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.SEND_INVOICE.FETCH(),
            ActionCreators.SEND_INVOICE.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.SEND_INVOICE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.INVOICE_PROVIDED({ invoiceId }),
        method: 'PUT',
    }));
    if (canViewSnapshotRole) {
        setTimeout(() => dispatch(loadRequestSnapshot({ referenceNum })), SNAPSHOT_LOAD_TIMEOUT);
    }
    return response;
});
export const calculateTax = ({ invoiceId, workOrderId }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.INVOICE_CALCULATE_TAX.FETCH(),
            ActionCreators.INVOICE_CALCULATE_TAX.SUCCESS({
                meta: () => ({ invoiceId }),
            }),
            ActionCreators.INVOICE_CALCULATE_TAX.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.INVOICE_CALCULATE_TAX({ invoiceId }),
        method: 'PUT',
    }));
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const removeSublet = ({ workOrderId, repairId }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REMOVE_SUBLET.FETCH(),
            ActionCreators.REMOVE_SUBLET.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.REMOVE_SUBLET.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.INVOICE_SUBLET({ repairId }),
        method: 'DELETE',
    }));
    dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return response;
});
export const downloadInvoice = (referenceNum, workOrderResponsibleParty) => (dispatch, getState) => {
    const accessToken = selectAccessToken(getState());
    return downloadInvoicePdf(referenceNum, workOrderResponsibleParty, accessToken);
};
export const downloadQuote = (referenceNum) => (dispatch, getState) => {
    const accessToken = selectAccessToken(getState());
    return downloadQuotePdf(referenceNum, accessToken);
};
