import { TextField, TimePicker } from 'src/components/mui';
import React from 'react';
import { getDeliveryVansAutocomplete } from 'src/AdminApp/modules/deliveryVans/actions';
import { selectDeliveryVansAutocomplete } from 'src/AdminApp/modules/deliveryVans/selectors';
import { DestructiveButton } from 'src/components/buttons';
import DeliveryVanSearch from 'src/components/form/DeliveryVanSearch';
import { HubIcon } from 'src/components/icons/HubIcon';
import { ConfirmationModal } from 'src/components/layout';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { ContentWrapper, FormRow, MenuItemContent, MenuItemIcon, MenuItemInner, MenuItemWrapper, Subheader, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
import usePartPickupRescheduleCancel from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupRescheduleCancel/usePartPickupRescheduleCancel';
import { getMomentValueOr8am, getTimeString } from 'src/utils/formatter';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { DATE_YMD_FORMAT, LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT, } from 'src/timeConstants';
export const PartPickupRescheduleCancel = ({ startLocal, onClose, }) => {
    const { selectedDistributorLocationId, setSelectedDistributorLocationId, distributors, splitAtFirstComma, apptDate, setApptDate, apptTime, timeFormat, setApptTime, selectedVan, setSelectedVan, cancelPartPickupHandler, isSubmitting, submitRescheduleHandler, isValid, isConfirmModalOpen, goBackHandler, okayHandler, } = usePartPickupRescheduleCancel({ startLocal, onClose });
    return (<>
      <SimpleDrawerContent>
        <Subheader>Appointment Details</Subheader>
        <ContentWrapper>
          <FormRow>
            <TextField select label="Pickup Location" name="topReason" variant="outlined" value={selectedDistributorLocationId} onChange={(event) => {
            setSelectedDistributorLocationId(event.target.value);
        }}>
              {distributors &&
            distributors.map((distributor) => (<MenuItemWrapper key={distributor.distributorId} value={distributor.distributorId}>
                    <MenuItemInner>
                      <MenuItemIcon>
                        {distributor.isHub ? <HubIcon /> : null}
                      </MenuItemIcon>

                      <MenuItemContent>
                        <span>{splitAtFirstComma(distributor.address)[0]}</span>
                        <br />
                        {splitAtFirstComma(distributor.address)[1]}
                      </MenuItemContent>
                    </MenuItemInner>
                  </MenuItemWrapper>))}
            </TextField>
          </FormRow>
          <FormRow>
            <SimpleDatePicker fullWidth name="apptDate" label="Date" format={LOCALIZED_DATE_FORMAT} value={apptDate} onChange={(value) => {
            if (value) {
                setApptDate(value.local().format(DATE_YMD_FORMAT));
            }
        }} slotProps={{
            textField: {
                variant: 'outlined',
            },
            field: {
                clearable: true,
            },
        }}/>
          </FormRow>
          <FormRow>
            <TimePicker 
    /* fullWidth */
    label="Time" value={getMomentValueOr8am(apptTime, timeFormat)} onChange={(val) => {
            setApptTime(getTimeString(val, timeFormat));
        }} minutesStep={1} format={TIME_12H_FORMAT} slotProps={{
            textField: {
                margin: 'none',
                variant: 'outlined',
            },
        }}/>
          </FormRow>
          <FormRow>
            {selectedVan !== undefined && (<DeliveryVanSearch value={selectedVan} name="van" label="Van" onUpdate={(van) => {
                setSelectedVan(van);
            }} getDeliveryVans={getDeliveryVansAutocomplete} deliveryVansSelector={selectDeliveryVansAutocomplete} disabled={false}/>)}
          </FormRow>
        </ContentWrapper>
      </SimpleDrawerContent>
      <SimpleDrawerFooter className="CTA-render-tech-dashboard">
        <DestructiveButton onClick={cancelPartPickupHandler} disabled={isSubmitting} style={{ width: 'auto' }}>
          Cancel Part Pickup
        </DestructiveButton>
        <FooterActionButton onClick={submitRescheduleHandler} disabled={!isValid || isSubmitting}>
          Submit
        </FooterActionButton>
      </SimpleDrawerFooter>

      <ConfirmationModal open={isConfirmModalOpen} text="Cancel Part Pickup" onClose={goBackHandler} action={okayHandler}/>
    </>);
};
export default PartPickupRescheduleCancel;
