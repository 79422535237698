import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from '../api/urls';
export const ActionCreators = {
    GET_COMPONENTS: createDefaultActionCreators('GET_COMPONENTS'),
    SEARCH_COMPONENTS: createDefaultActionCreators('SEARCH_COMPONENTS'),
    GET_COMPONENT_BY_ID: createDefaultActionCreators('GET_COMPONENT_BY_ID'),
    UPDATE_COMPONENT: createDefaultActionCreators('UPDATE_COMPONENT'),
    SAVE_COMPONENT: createDefaultActionCreators('SAVE_COMPONENT'),
};
export const getComponent = (id) => createAPIAction({
    types: [
        ActionCreators.GET_COMPONENT_BY_ID.FETCH(),
        ActionCreators.GET_COMPONENT_BY_ID.SUCCESS(),
        ActionCreators.GET_COMPONENT_BY_ID.FAILURE(),
    ],
    endpoint: API_URLS.COMPONENTS.COMPONENT_BY_ID(id.toString()),
    method: 'GET',
});
export const getComponents = (params) => createAPIAction({
    types: [
        ActionCreators.GET_COMPONENTS.FETCH(),
        ActionCreators.GET_COMPONENTS.SUCCESS(),
        ActionCreators.GET_COMPONENTS.FAILURE(),
    ],
    endpoint: API_URLS.COMPONENTS.COMPONENTS(),
    params: Object.assign({}, params),
    method: 'GET',
});
export const searchComponents = (params) => createAPIAction({
    types: [
        ActionCreators.SEARCH_COMPONENTS.FETCH({
            meta: () => ({ params }),
        }),
        ActionCreators.SEARCH_COMPONENTS.SUCCESS({
            meta: () => ({ params }),
        }),
        ActionCreators.SEARCH_COMPONENTS.FAILURE(),
    ],
    endpoint: API_URLS.COMPONENTS.SEARCH_COMPONENTS(),
    params: Object.assign(Object.assign({}, params.pagination), params.filters),
    method: 'GET',
});
export const saveComponent = (component) => createAPIAction({
    types: [
        ActionCreators.SAVE_COMPONENT.FETCH(),
        ActionCreators.SAVE_COMPONENT.SUCCESS(),
        ActionCreators.SAVE_COMPONENT.FAILURE(),
    ],
    body: component,
    endpoint: API_URLS.COMPONENTS.COMPONENTS(),
    method: 'POST',
});
export const updateComponent = (id, component) => createAPIAction({
    types: [
        ActionCreators.UPDATE_COMPONENT.FETCH(),
        ActionCreators.UPDATE_COMPONENT.SUCCESS(),
        ActionCreators.UPDATE_COMPONENT.FAILURE(),
    ],
    body: component,
    endpoint: API_URLS.COMPONENTS.COMPONENT_BY_ID(id.toString()),
    method: 'PUT',
});
