import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
export const MakeModelContainer = styled.strong `
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
`;
export const VinPlateContainer = styled.section `
  margin-top: 4px;
`;
export const VinPlate = styled.div `
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};

  ${theme.breakpoints.up('md')} {
    font-size: 14px;
    line-height: 1.29;
  }
`;
export const NicknameLabel = styled.div `
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${theme.breakpoints.up('md')} {
    font-size: 14px;
    line-height: 1.29;
  }
`;
