import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Tab, Tabs } from 'src/components/mui';
import { selectAuthorizedGeoMgmtView } from 'src/modules/auth/selectors';
import { selectDashboardRegions, selectDashboardParams as selectDashboardRegionsParams, selectDashboardPagination as selectDashboardRegionsPagination, selectDashboardFetching as selectDashboardRegionsFetching, } from 'src/AdminApp/modules/regions/selectors';
import { selectDashboardPagination as selectDashboardTerritoriesPagination, selectDashboardTerritories, selectDashboardParams as selectDashboardTerritoriesParams, selectDashboardFetching as selectDashboardTerritoriesFetching, } from 'src/AdminApp/modules/territories/selectors';
import { selectDashboardFetching as selectDashboardMetrosFetching } from 'src/AdminApp/modules/metros/selectors';
import { getRegionsByFilters } from 'src/AdminApp/modules/regions/actions';
import { getTerritoriesByFilters } from 'src/AdminApp/modules/territories/actions';
import { TabPanel } from 'src/AdminApp/components/layout/TabPanel';
import Regions from 'src/AdminApp/containers/geoManagement/Regions';
import Territories from 'src/AdminApp/containers/geoManagement/Territories';
import GeoManagementActions from 'src/AdminApp/containers/geoManagement/GeoManagementActions';
import useIsMobile from 'src/hooks/useIsMobile';
import Loader from 'src/AdminApp/containers/requests/StateTracker/Loader';
import { selectDashboardFetching as selectDashboardServiceAreasFetching } from '../modules/serviceAreas/selectors';
const GeoManagement = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const isMobile = useIsMobile();
    const canView = useSelector(selectAuthorizedGeoMgmtView);
    const regions = useSelector(selectDashboardRegions);
    const regionsPagination = useSelector(selectDashboardRegionsPagination);
    const regionsParams = useSelector(selectDashboardRegionsParams);
    const territories = useSelector(selectDashboardTerritories);
    const pagination = useSelector(selectDashboardTerritoriesPagination);
    const params = useSelector(selectDashboardTerritoriesParams);
    const isRegionsFetching = useSelector(selectDashboardRegionsFetching);
    const isTerritoriesFetching = useSelector(selectDashboardTerritoriesFetching);
    const isMetrosFetching = useSelector(selectDashboardMetrosFetching);
    const isServiceAreasFetching = useSelector(selectDashboardServiceAreasFetching);
    const dispatch = useDispatch();
    const isLoading = isRegionsFetching ||
        isTerritoriesFetching ||
        isMetrosFetching ||
        isServiceAreasFetching;
    useEffect(() => {
        var _a, _b;
        if (currentTab !== 0)
            return;
        if (regions.length)
            return;
        dispatch(getRegionsByFilters(Object.assign(Object.assign({}, regionsParams), { pagination: {
                size: (_a = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                page: (_b = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.pageNumber) !== null && _b !== void 0 ? _b : 0,
                sort: regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.sort,
            } })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab, regions === null || regions === void 0 ? void 0 : regions.length]);
    useEffect(() => {
        var _a, _b;
        if (currentTab !== 1)
            return;
        if (territories.length)
            return;
        dispatch(getTerritoriesByFilters(Object.assign(Object.assign({}, params), { pagination: {
                size: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                page: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _b !== void 0 ? _b : 0,
                sort: pagination === null || pagination === void 0 ? void 0 : pagination.sort,
            } })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab, territories === null || territories === void 0 ? void 0 : territories.length]);
    if (isLoading ||
        (!regions.length && currentTab === 0) ||
        (!territories.length && currentTab === 1)) {
        return <Loader />;
    }
    return canView ? (<div>
      <GeoManagementActions />
      <Tabs value={currentTab} onChange={(event, tab) => setCurrentTab(tab)} indicatorColor="primary" textColor="primary" variant={isMobile ? 'fullWidth' : 'scrollable'} scrollButtons="auto" aria-label="scrollable auto tabs example">
        <Tab label="Regions & Service Areas"/>
        <Tab label="Territories & Metros"/>
      </Tabs>
      <Grid container>
        <Grid item md={12} xs={12}>
          <TabPanel value={currentTab} index={0} key={0}>
            <Regions />
          </TabPanel>
          <TabPanel value={currentTab} index={1} key={1}>
            <Territories />
          </TabPanel>
        </Grid>
      </Grid>
    </div>) : (<div>
      ERROR - Not Authorized: You must have the "Geo_Viewer" role to view this
      page
    </div>);
};
export default GeoManagement;
