var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'src/components/SimpleToast';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import useIsMobile from 'src/hooks/useIsMobile';
import { MarketingSourceVisibility } from 'src/AdminApp/models/enums/MarketingSourceVisibility';
import { getAPIKeyAccessLevel, getAPIKeyDescription, getAPIKeyIsDefault, getAPIKeyValidFrom, getAPIKeyValidTo, selectAddPartnerAPIKeyMarketingSource, selectAddPartnerAPIKeyPartner, } from 'src/PartnersAPIApp/modules/partnersApi/selectors';
import { getPartners } from 'src/PartnersApp/modules/partners/actions';
import { loadMarketingSources } from 'src/AdminApp/modules/lookups/actions';
import { addAPIKey, getPartnersAPIDashboard, resetAddAPIKey, setAPIKeyAccessLevel, setAPIKeyDescription, setAPIKeyValidFrom, setAPIKeyValidTo, setCheckedDefaultAPIKey, setSelectedMarketingSource, setSelectedPartner, updateAPIKey, } from 'src/PartnersAPIApp/modules/partnersApi/actions';
import { DATE_TIME_FORMAT_WITH_SECONDS } from 'src/timeConstants';
const useAddPartnerAPIDrawer = ({ open, apiKey, onClose, }) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingEnabled, setIsSubmittingEnabled] = useState(false);
    const [notExpire, setNotExpired] = useState(false);
    const selectedPartner = useSelector(selectAddPartnerAPIKeyPartner);
    const selectedMarketingSource = useSelector(selectAddPartnerAPIKeyMarketingSource);
    const partnerAPIDescription = useSelector(getAPIKeyDescription);
    const partnerAPIIsDefault = useSelector(getAPIKeyIsDefault);
    const partnerAPIValidFrom = useSelector(getAPIKeyValidFrom);
    const partnerAPIValidTo = useSelector(getAPIKeyValidTo);
    const partnerAPIAccessLevel = useSelector(getAPIKeyAccessLevel);
    useEffect(() => {
        dispatch(getPartners({ active: true, size: 500 }));
        dispatch(loadMarketingSources(MarketingSourceVisibility.ADMIN));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (open && apiKey) {
            dispatch(setSelectedPartner(apiKey.partnerId));
            dispatch(setSelectedMarketingSource(apiKey.marketingSourceId));
            dispatch(setCheckedDefaultAPIKey(apiKey.defaultKey));
            dispatch(setAPIKeyDescription(apiKey.description));
            dispatch(setAPIKeyValidFrom(moment(apiKey.validFrom)));
            dispatch(setAPIKeyValidTo(moment(apiKey.validTo)));
            dispatch(setAPIKeyAccessLevel(apiKey.accessLevel));
        }
        else if (!open && apiKey) {
            dispatch(resetAddAPIKey());
        }
        setNotExpired(!!apiKey && !apiKey.validTo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, apiKey]);
    useEffect(() => {
        const isEnabled = !!selectedPartner.id &&
            !!selectedMarketingSource.id &&
            !!partnerAPIValidFrom &&
            (!!notExpire || !!partnerAPIValidTo) &&
            !!partnerAPIAccessLevel;
        setIsSubmittingEnabled(isEnabled);
    }, [
        selectedPartner.id,
        selectedMarketingSource.id,
        partnerAPIValidFrom,
        partnerAPIValidTo,
        partnerAPIAccessLevel,
        notExpire,
    ]);
    const handleDescriptionChange = (e) => {
        dispatch(setAPIKeyDescription(e.target.value));
    };
    const handleAccessLevelChange = (e) => {
        dispatch(setAPIKeyAccessLevel(e));
    };
    const handleClose = () => {
        dispatch(resetAddAPIKey());
        setNotExpired(false);
        setIsSubmitting(false);
        onClose();
    };
    const handleIsDefaultAPIKey = (e) => {
        dispatch(setCheckedDefaultAPIKey(e.target.checked));
    };
    const handleNotExpire = (e) => {
        setNotExpired(e);
        setIsSubmitting(false);
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        if (!notExpire) {
            const validateValidTo = partnerAPIValidTo != null && partnerAPIValidTo.isValid();
            if (!validateValidTo) {
                toast.error("Invalid 'Valid to'. If it is not required check the 'This does not expire'.");
                setIsSubmitting(false);
                return;
            }
        }
        const validFrom = `${partnerAPIValidFrom.format(DATE_TIME_FORMAT_WITH_SECONDS)}Z`;
        const validTo = !notExpire
            ? `${partnerAPIValidTo.format(DATE_TIME_FORMAT_WITH_SECONDS)}Z`
            : null;
        if (moment(validTo).isSameOrBefore(validFrom)) {
            toast.error("Invalid Valid Dates. 'ValidFrom' has to be before 'ValidTo'.");
            setIsSubmitting(false);
            return;
        }
        const submitPayload = {
            body: {
                partnerId: selectedPartner.id,
                marketingSourceId: selectedMarketingSource.id,
                description: partnerAPIDescription,
                validFrom,
                validTo,
                defaultKey: partnerAPIIsDefault,
                accessLevel: partnerAPIAccessLevel,
            },
        };
        if (!apiKey) {
            const addResponse = yield dispatch(addAPIKey(submitPayload));
            if (!addResponse.error) {
                analyticsTrackEvent('New Partner API Key Created', {
                    'API Key ID': addResponse.payload.id,
                    'Partner ID': addResponse.payload.partnerId,
                    'Marketing Source ID': addResponse.payload.marketingSourceId,
                });
                toast.success('Partner API Key added successfully');
                handleClose();
            }
        }
        else {
            const updateResponse = yield dispatch(updateAPIKey({ id: apiKey.id, body: submitPayload.body }));
            if (!updateResponse.error) {
                toast.success('Partner API Key updated successfully');
                handleClose();
            }
        }
        dispatch(getPartnersAPIDashboard({}));
        setIsSubmitting(false);
    });
    return {
        dispatch,
        isMobile,
        isSubmitting,
        setIsSubmitting,
        isSubmittingEnabled,
        setIsSubmittingEnabled,
        selectedPartner,
        selectedMarketingSource,
        partnerAPIDescription,
        partnerAPIIsDefault,
        partnerAPIValidFrom,
        partnerAPIValidTo,
        partnerAPIAccessLevel,
        notExpire,
        setNotExpired,
        handleDescriptionChange,
        handleAccessLevelChange,
        handleClose,
        handleIsDefaultAPIKey,
        handleNotExpire,
        handleSubmit,
    };
};
export default useAddPartnerAPIDrawer;
