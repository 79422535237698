import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPopsJobCounts } from 'src/PopsApp/modules/parts/actions';
import { selectPopsJobCounts } from 'src/PopsApp/modules/parts/selectors';
import { Container, Title, Content, Divider } from './Parts.styles';
import Jobs from './components/Jobs';
import PartWidget from './components/PartWidget';
const Parts = () => {
    const dispatch = useDispatch();
    const jobCounts = useSelector(selectPopsJobCounts);
    useEffect(() => {
        dispatch(getPopsJobCounts());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<Container>
      <Title>Jobs</Title>

      <Content>
        {jobCounts && <Jobs counts={jobCounts}/>}
        <Divider />
        <PartWidget mapUrl={(shift) => `/pops/${shift}/jobs`} actionTitle="Smart Order"/>
      </Content>
    </Container>);
};
export default Parts;
