import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import { formatTimestamp } from 'src/utils/formatter';
import { LOCALIZED_DATE_LONG_FORMAT } from 'src/timeConstants';
const Title = styled(Typography) `
  margin: 24px 0 12px 0;
`;
const Date = styled.div `
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: ${colors.default};
`;
const AdminTitle = styled(Typography) `
  font-weight: normal;
  word-wrap: break-word;
`;
const RequestUpdatedInfo = ({ updated, updatedBy, created, createdBy, }) => {
    return (<Grid container>
      <Grid item xs={6} md={3}>
        <Title variant="subtitle1">Created</Title>
      </Grid>
      <Grid item xs={6} md={3}>
        <Title variant="subtitle1">Last Updated</Title>
      </Grid>
      <Grid item xs={12}/>
      <Grid item xs={6} md={3}>
        <Date>
          {created
            ? formatTimestamp(created, LOCALIZED_DATE_LONG_FORMAT)
            : 'N/A'}
        </Date>
      </Grid>
      <Grid item xs={6} md={3}>
        <Date>
          {updated
            ? formatTimestamp(updated, LOCALIZED_DATE_LONG_FORMAT)
            : 'N/A'}
        </Date>
      </Grid>
      <Grid item xs={12}/>
      <Grid item xs={6} md={3}>
        {createdBy && (<AdminTitle variant="subtitle1">
            by <AdminUser justName updatedUser={createdBy}/>
          </AdminTitle>)}
      </Grid>
      <Grid item xs={6} md={3}>
        {updatedBy && (<AdminTitle variant="subtitle1">
            by <AdminUser justName updatedUser={updatedBy}/>
          </AdminTitle>)}
      </Grid>
    </Grid>);
};
export default RequestUpdatedInfo;
