import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Container, Grid, Paper } from 'src/components/mui';
import { verifyMfa } from 'src/modules/auth/actions';
import { AMSLogo } from 'src/components/logos';
import MfaForm from 'src/containers/auth/MfaForm';
const StyledPaper = styled(Paper) `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 24px;
  margin-top: 64px;
`;
const MfaAuth = () => {
    const dispatch = useDispatch();
    const onSubmit = (mfaCode) => {
        dispatch(verifyMfa(mfaCode));
    };
    return (<Container>
      <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <AMSLogo />
            <MfaForm onSubmit={onSubmit}/>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>);
};
export default MfaAuth;
