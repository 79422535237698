var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { Grid, MenuItem, TextField as SelectField, Checkbox, } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import CONFIG from 'src/config';
import { SimpleModal } from 'src/components/layout';
import { addressObjectToString } from 'src/clients/mapboxClient';
import { TextField } from 'src/AdminApp/components/form';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { LabeledGridField } from 'src/AdminApp/components/repairs/statement/styledComponents';
import PlacesAutocomplete from 'src/components/form/PlacesAutocomplete';
import { ChipInput } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/pages/ServiceAreaPage';
const DistributorLocationModal = ({ isEdit = false, open, onClose, onSubmit, partDistributorId, location, partDistributorName, }) => {
    var _a;
    const DistributorSchema = Yup.object().shape({
        address: Yup.string().min(1).max(255).required(),
        dealershipName: Yup.string().min(1).max(255).nullable(),
        status: Yup.string().min(1).max(255).required(),
        epicorCoverageKey: Yup.string().min(1).max(19).nullable(),
        emailAddresses: Yup.array().of(Yup.string().email()),
        canReceiveCourierDeliveries: Yup.boolean().required(),
    });
    const [selectedAddress, setSelectedAddress] = useState(null);
    const statuses = ['ACTIVE', 'INACTIVE'];
    useEffect(() => {
        if (location) {
            setSelectedAddress(Object.assign(Object.assign({}, location), { street: location.street1, timezone: '' }));
        }
        else {
            setSelectedAddress(null);
        }
    }, [location, open]);
    const setAddress = (address, setFieldValue) => {
        setSelectedAddress(address);
        setFieldValue('address', address === null || address === void 0 ? void 0 : address.street);
    };
    const normalizeLocation = (values, address) => {
        return Object.assign(Object.assign({}, location), { createdBy: null, created: null, modifiedBy: null, modified: null, street1: address.street, street2: address.street2, city: address.city, state: address.state, zip: address.zip, country: address.country, latitude: address.latitude, longitude: address.longitude, status: values.status, partDistributorId, epicorCoverageKey: values.epicorCoverageKey || null, dealershipName: values.dealershipName, emailAddresses: values.emailAddresses || null, canReceiveCourierDeliveries: values.canReceiveCourierDeliveries || false });
    };
    return (<SimpleModal title={`${isEdit ? 'Edit' : 'Add'} Location`} open={open} onClose={onClose} fullWidth maxWidth="md">
      <Formik initialValues={{
            address: location
                ? addressObjectToString(selectedAddress)
                : undefined,
            dealershipName: (location === null || location === void 0 ? void 0 : location.dealershipName) || '',
            status: (location === null || location === void 0 ? void 0 : location.status) || 'ACTIVE',
            epicorCoverageKey: ((_a = location === null || location === void 0 ? void 0 : location.epicorCoverageKey) === null || _a === void 0 ? void 0 : _a.toString()) || undefined,
            emailAddresses: (location === null || location === void 0 ? void 0 : location.emailAddresses) || [],
            canReceiveCourierDeliveries: (location === null || location === void 0 ? void 0 : location.canReceiveCourierDeliveries) || false,
        }} validationSchema={DistributorSchema} onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            onSubmit(normalizeLocation(values, selectedAddress)).then(() => {
                resetForm({ values: Object.assign({}, values) });
                setSubmitting(false);
            });
        }}>
        {({ values, handleSubmit, isValid, setFieldValue, dirty }) => (<Grid>
            <LabeledGridField label="Address">
              <PlacesAutocomplete displayMap={false} address={selectedAddress} onSelect={(value) => {
                if (value) {
                    setAddress(value, setFieldValue);
                }
            }}/>
            </LabeledGridField>
            <LabeledGridField label="Status">
              <Field name="status">
                {({ field }) => (<SelectField select value={field.value || ''} onChange={(event) => __awaiter(void 0, void 0, void 0, function* () {
                    const item = event.target.value || '';
                    yield setFieldValue(field.name, item);
                })}>
                    {statuses.map((status) => (<MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>))}
                  </SelectField>)}
              </Field>
            </LabeledGridField>
            <LabeledGridField label="Epicor Coverage Key">
              <TextField type="text" name="epicorCoverageKey" fullWidth/>
            </LabeledGridField>
            <LabeledGridField label="Dealership Name">
              <TextField type="text" name="dealershipName" fullWidth/>
            </LabeledGridField>
            <LabeledGridField label="Email Addresses">
              <ChipInput fullWidth variant="standard" value={values.emailAddresses} validate={(value) => {
                return Yup.string().email().required().isValidSync(value);
            }} onAddChip={(value) => {
                setFieldValue('emailAddresses', [
                    ...values.emailAddresses,
                    value,
                ]);
            }} onDeleteChip={(value, index) => {
                const emailAddresses = [...values.emailAddresses];
                emailAddresses.splice(index, 1);
                setFieldValue('emailAddresses', emailAddresses);
            }}/>
            </LabeledGridField>
            {CONFIG.POPS_COURIER_DELIVERY_DISTRIBUTORS.split(',').includes(partDistributorName) && (<LabeledGridField label="Can Receive Courier Delivery">
                <Tooltip title="Can this location receive deliveries from courier services such as Fedex? Storage containers typically cannot.">
                  <Checkbox checked={values.canReceiveCourierDeliveries} onChange={(_, value) => {
                    setFieldValue('canReceiveCourierDeliveries', value);
                }} name="canReceiveCourierDeliveries"/>
                </Tooltip>
              </LabeledGridField>)}
            <Grid item xs={12} style={{ marginTop: '24px' }}>
              <SimpleTwoButtonRow disabledSubmit={!isValid || !dirty} submitText="Save" onCancel={onClose} onSubmit={handleSubmit}/>
            </Grid>
          </Grid>)}
      </Formik>
    </SimpleModal>);
};
export default DistributorLocationModal;
