var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Field, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, TextField as MTextField, MenuItem } from 'src/components/mui';
import { SimpleModal } from 'src/components/layout';
import { TextField } from 'src/AdminApp/components/form';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { LabeledGridField } from 'src/AdminApp/components/repairs/statement/styledComponents';
import { selectAppointmentResolutionOptions } from 'src/SystemApp/modules/utilities/selectors';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
const ResolutionModal = ({ open, adding = false, onClose, onSubmit, id, issuer, mapTo, reason, specificReason, visibility, }) => {
    const ResolutionSchema = Yup.object().shape({
        reason: Yup.string().min(1).max(255).required(),
        issuer: Yup.string().min(1).max(255).required(),
        mapTo: Yup.string().min(1).max(255).required(),
        visible: Yup.boolean().nullable(),
    });
    const options = useSelector(selectAppointmentResolutionOptions);
    return (<SimpleModal title={`${adding ? 'Add' : 'Edit'} Appointment Resolution`} open={open} onClose={onClose} maxWidth="md">
      <Formik initialValues={{
            id,
            issuer: issuer || '',
            mapTo: mapTo || '',
            reason: reason || '',
            specificReason: specificReason || '',
            visible: (visibility === null || visibility === void 0 ? void 0 : visibility.includes('CANCEL')) ||
                (visibility === null || visibility === void 0 ? void 0 : visibility.includes('RESCHEDULE')),
            prodEngVisible: visibility === null || visibility === void 0 ? void 0 : visibility.includes('PROD-ENG'),
        }} validationSchema={ResolutionSchema} onSubmit={(values, { setSubmitting, resetForm }) => {
            const newVisibility = [];
            if (values === null || values === void 0 ? void 0 : values.visible) {
                newVisibility.push('RESCHEDULE');
                newVisibility.push('CANCEL');
            }
            if (values === null || values === void 0 ? void 0 : values.prodEngVisible) {
                newVisibility.push('PROD-ENG');
            }
            onSubmit(Object.assign(Object.assign({}, values), { visibility: newVisibility })).then(() => {
                resetForm({ values: Object.assign({}, values) });
                setSubmitting(false);
            });
        }}>
        {({ errors, handleSubmit, isValid, setFieldValue, touched }) => (<Grid>
            <LabeledGridField label="Reason">
              <TextField type="string" name="reason" fullWidth variant="standard"/>
            </LabeledGridField>
            <LabeledGridField label="Sub Reason">
              <TextField type="string" name="specificReason" fullWidth variant="standard"/>
            </LabeledGridField>
            <LabeledGridField label="Map To">
              <Field name="mapTo">
                {({ field }) => {
                var _a;
                return (<MTextField select value={field.value || ''} onChange={(event) => __awaiter(void 0, void 0, void 0, function* () {
                        var _b, _c;
                        const item = (_c = (_b = event === null || event === void 0 ? void 0 : event.target) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : '';
                        yield setFieldValue(field.name, item);
                    })} helperText={touched.mapTo ? errors.mapTo : ''} error={touched.mapTo && Boolean(errors.mapTo)} variant="standard">
                    {(_a = options === null || options === void 0 ? void 0 : options.mapToReasons) === null || _a === void 0 ? void 0 : _a.map((v) => (<MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>))}
                  </MTextField>);
            }}
              </Field>
            </LabeledGridField>
            <LabeledGridField label="Issuer">
              <Field name="issuer">
                {({ field }) => {
                var _a;
                return (<MTextField select value={field.value || ''} onChange={(event) => __awaiter(void 0, void 0, void 0, function* () {
                        var _b, _c;
                        const item = (_c = (_b = event === null || event === void 0 ? void 0 : event.target) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : '';
                        yield setFieldValue(field.name, item);
                    })} helperText={touched.issuer ? errors.issuer : ''} error={touched.issuer && Boolean(errors.issuer)} variant="standard">
                    {(_a = options === null || options === void 0 ? void 0 : options.issuers) === null || _a === void 0 ? void 0 : _a.map((v) => (<MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>))}
                  </MTextField>);
            }}
              </Field>
            </LabeledGridField>
            <LabeledGridField label="Visible">
              <CheckboxField name="visible" color="primary"/>
            </LabeledGridField>
            <LabeledGridField label="Product/Eng visible">
              <CheckboxField name="prodEngVisible" color="primary"/>
            </LabeledGridField>
            <Grid item xs={12}>
              <SimpleTwoButtonRow disabledSubmit={!isValid} submitText="Save" onCancel={onClose} onSubmit={handleSubmit}/>
            </Grid>
          </Grid>)}
      </Formik>
    </SimpleModal>);
};
export default ResolutionModal;
