import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
export const ActionCreators = {
    ADD_WARRANTY: createDefaultActionCreators('ADD_WARRANTY'),
};
export const addWarrantyClaim = (referenceNumber, address) => createAPIAction({
    types: [
        ActionCreators.ADD_WARRANTY.FETCH(),
        ActionCreators.ADD_WARRANTY.SUCCESS(),
        ActionCreators.ADD_WARRANTY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ADD_WARRANTY(referenceNumber),
    method: 'POST',
    body: { address },
});
