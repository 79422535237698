import styled from 'styled-components';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
const getRotateAngle = ({ isOpen }) => (isOpen ? -180 : 0);
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  background-color: ${colors.white};

  border-radius: 4px;
  border: solid 1px ${colors.GRAY_DE};
  color: ${colors.white};
  overflow: hidden;
`;
export const Header = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 16px 16px 0;
  cursor: pointer;
  z-index: 1000;

  .itemCollapseIcon {
    width: 24px;
    height: 24px;
    background-image: url(${STATIC_URL_BASE}pops/icons/chevron-down.svg);
    transform: rotate(${getRotateAngle}deg);
    transition: transform 0.3s ease;
    margin-left: 8px;
    font-size: 13px;
  }
`;
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 16px;
  z-index: 100;

  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  height: ${(props) => (props.isOpen ? 'inherit' : 0)};
  transform: translateY(${(props) => (props.isOpen ? 0 : '-300px')});
  transition: ${(props) => props.isOpen
    ? 'opacity 0.3s 0.2s ease, transform 0.3s ease'
    : 'opacity  ease, transform 0.3s ease'};
  user-select: ${(props) => (props.isOpen ? 'auto' : 'none')};
`;
