import { useDispatch, useSelector } from 'react-redux';
import PartPickupItemStatus from 'src/AdminApp/models/enums/PartPickupItemStatus';
import { checkPickedUpItem, updatePickedUpQuantity, } from 'src/TechApp/modules/partpickup/actions';
import { selectPickedUpItems } from 'src/TechApp/modules/partpickup/selectors';
const usePartPickupChecklistItem = () => {
    const dispatch = useDispatch();
    const pickedUpItems = useSelector(selectPickedUpItems);
    const getItemIndex = (id) => {
        const itemIndex = pickedUpItems.findIndex((item) => item.id === id);
        return itemIndex >= 0 ? itemIndex : 0;
    };
    const countPendingOrNewPart = (item) => {
        let pendingOrNewPart = 0;
        item.states.forEach((state) => {
            if (state.status === PartPickupItemStatus.NEW_PART ||
                state.status === PartPickupItemStatus.PENDING) {
                pendingOrNewPart += 1;
            }
        });
        return pendingOrNewPart;
    };
    const handleChangedPickedUpItem = (item) => (_, value) => {
        dispatch(checkPickedUpItem({ id: item.id, checked: value }));
    };
    const handleQuantityChange = (item) => (newQuantity) => {
        dispatch(updatePickedUpQuantity({
            id: item.id,
            quantity: newQuantity,
        }));
    };
    return {
        countPendingOrNewPart,
        pickedUpItems,
        getItemIndex,
        handleChangedPickedUpItem,
        handleQuantityChange,
    };
};
export default usePartPickupChecklistItem;
