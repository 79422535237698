import React from 'react';
import { Autocomplete, TextField } from 'src/components/mui';
import useFleetsAutocomplete from './useFleetsAutocomplete';
const FleetsAutocomplete = ({ workOrders, disabled, fleet, }) => {
    const { fleetSearchResults, selected, dataSource, handleFleetSelected, handleSearchFleets, } = useFleetsAutocomplete({ fleet, workOrders });
    return (<Autocomplete multiple id="fleets-select" disabled={disabled} options={fleetSearchResults !== null && fleetSearchResults !== void 0 ? fleetSearchResults : []} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} filterSelectedOptions clearOnBlur noOptionsText={dataSource === 'STATION'
            ? 'No More Fleets from this station. Change your station to add different fleets.'
            : 'No Fleets Found'} onChange={handleFleetSelected} onInputChange={handleSearchFleets} value={selected} ChipProps={{
            classes: {
                root: 'chip',
                deleteIcon: 'chip-delete-icon',
            },
        }} isOptionEqualToValue={(option, value) => option.id === value.id} renderInput={(params) => (<TextField {...params} variant="outlined" placeholder="Search for a fleet" label="Fleets"/>)}/>);
};
export default FleetsAutocomplete;
