import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ActionCreators, setVehicle as quickROSetVehicle, } from 'src/AdminApp/modules/quickRo/actions';
import { ConfirmationModal } from 'src/components/layout';
import CarForm from 'src/AdminApp/containers/vehicle/CarModule/CarForm';
import { parseStringNumber } from 'src/utils/data-utils';
const TitleForm = styled.h3 `
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  margin-bottom: 24px;
  margin-top: 0;
`;
const CreateVehicle = ({ handleVinExists, isFleet = false, }) => {
    const dispatch = useDispatch();
    const [defaultState] = useState({});
    const [vehicle, setVehicle] = useState();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState('');
    const selectVehicleHandler = (vehicle) => {
        var _a;
        setVehicle(vehicle);
        const parsedMileage = parseStringNumber((_a = vehicle.mileage) !== null && _a !== void 0 ? _a : 0);
        if (!parsedMileage || parsedMileage === 0) {
            setConfirmationModalOpen(false);
        }
        else {
            if (parsedMileage <= 10) {
                setConfirmationModalOpen(true);
                setConfirmationModalText('You’ve entered in a low mileage, are you sure?');
                return;
            }
            if (parsedMileage >= 300000) {
                setConfirmationModalOpen(true);
                setConfirmationModalText('You’ve entered in a high mileage, are you sure?');
                return;
            }
        }
        selectVehicle(vehicle);
    };
    const selectVehicle = (vehicle) => {
        dispatch(quickROSetVehicle(vehicle));
    };
    const changeHandler = (vehicle) => {
        dispatch(ActionCreators.TAB.SAVE_CUSTOM_VEHICLE({ payload: vehicle }));
    };
    return (<>
      <ConfirmationModal open={confirmationModalOpen} text={confirmationModalText} action={() => selectVehicleHandler(vehicle)} onClose={() => setConfirmationModalOpen(false)}/>
      <TitleForm>Add New Vehicle Info</TitleForm>
      <CarForm vinPlateFieldsFirst handleVinExists={handleVinExists} isFleet={isFleet} vehicle={defaultState} onChange={changeHandler}/>
    </>);
};
export default CreateVehicle;
