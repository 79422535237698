var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ActionButton, DestructiveButton, SecondaryButton, } from 'src/components/buttons';
import { RowWrapper } from 'src/components/SimpleTwoButtonRow';
import OptionalTooltip from '../tooltips/OptionalTooltip';
const SimpleTwoButtonRow = (_a) => {
    var { onCancel, onSubmit, disabledCancel, disabledSubmit, disabledCancelTooltipTitle = '', disabledSubmitTooltipTitle = '', submitText = 'Save', cancelText = 'Cancel', submitIsDestructive = false, disableTopPadding = false } = _a, rest = __rest(_a, ["onCancel", "onSubmit", "disabledCancel", "disabledSubmit", "disabledCancelTooltipTitle", "disabledSubmitTooltipTitle", "submitText", "cancelText", "submitIsDestructive", "disableTopPadding"]);
    const CancelButton = submitIsDestructive
        ? SecondaryButton
        : DestructiveButton;
    const SubmitButton = submitIsDestructive ? DestructiveButton : ActionButton;
    return (<RowWrapper {...rest} disableTopPadding={disableTopPadding.toString()}>
      <OptionalTooltip disabled={!disabledCancel} title={disabledCancelTooltipTitle}>
        <CancelButton onClick={onCancel} disabled={disabledCancel}>
          {cancelText}
        </CancelButton>
      </OptionalTooltip>
      <OptionalTooltip disabled={!disabledSubmit} title={disabledSubmitTooltipTitle}>
        <SubmitButton onClick={onSubmit} type="submit" disabled={disabledSubmit}>
          {submitText}
        </SubmitButton>
      </OptionalTooltip>
    </RowWrapper>);
};
export default SimpleTwoButtonRow;
