import React from 'react';
import styled from 'styled-components';
import { Grid, Paper, Typography } from 'src/components/mui';
const StyledPaper = styled(Paper) `
  padding: 20px;
  margin: 5px;
  .header {
    h6 {
      display: inline-flex;
    }
    button {
      margin-bottom: 5px;
      margin-left: 10px;
    }
  }
`;
const ModuleWrapper = ({ filter, header, children }) => {
    return (<StyledPaper>
      <Grid container>
        {header && (<Grid item xs={12} className="header">
            <Typography color="textPrimary" variant="h6">
              {header}
            </Typography>
            {filter}
          </Grid>)}
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </StyledPaper>);
};
export default ModuleWrapper;
