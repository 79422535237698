var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { downloadInvoiceBatch } from 'src/utils/downloadUtils';
import { selectAccessToken } from 'src/modules/auth/selectors';
import { getFleetInvoices } from 'src/AdminApp/modules/fleets/actions';
import { selectFleetInvoicesDashboard, selectFleetInvoicesDashboardPagination, selectFleetInvoicesDashboardParams, selectFleetInvoicesDashboardFetching, } from 'src/AdminApp/modules/fleets/selectors';
export const MAX_DOWNLOAD_ITEMS = 50;
const useFleetInvoices = () => {
    const dispatch = useDispatch();
    const { fleetId } = useParams();
    const invoices = useSelector(selectFleetInvoicesDashboard);
    const pagination = useSelector(selectFleetInvoicesDashboardPagination);
    const params = useSelector(selectFleetInvoicesDashboardParams);
    const fetching = useSelector(selectFleetInvoicesDashboardFetching);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isBatchDownloading, setIsBatchDownloading] = useState(false);
    const [isInvoiceDownloading, setIsInvoiceDownloading] = useState(false);
    useEffect(() => {
        dispatch(getFleetInvoices({ fleetId }));
    }, [fleetId]);
    const accessToken = useSelector(selectAccessToken);
    const onLoadData = (_a) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e;
        var { page = (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _b !== void 0 ? _b : 0, size = (_c = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _c !== void 0 ? _c : 20, sort = (_d = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _d !== void 0 ? _d : ['appointmentDate', 'desc'], query = (_e = pagination === null || pagination === void 0 ? void 0 : pagination.query) !== null && _e !== void 0 ? _e : '', } = _a;
        dispatch(getFleetInvoices({
            fleetId,
            pageSize: size,
            pageIndex: page,
            sort,
            query,
        }));
    });
    const handleSelect = (item) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.find((s) => s.invoiceNum === item.invoiceNum)) {
                return prevSelectedItems.filter((s) => s.invoiceNum !== item.invoiceNum);
            }
            else {
                if (prevSelectedItems.length >= MAX_DOWNLOAD_ITEMS) {
                    return prevSelectedItems;
                }
                return [...prevSelectedItems, item];
            }
        });
    };
    const batchDownloadInvoices = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsBatchDownloading(true);
        const requests = selectedItems.map((item) => {
            const fileName = `${item.referenceNum}-${item.invoiceNum}.pdf`;
            return {
                repairRequestRefNum: item.referenceNum,
                responsiblePartyId: item.responsiblePartyId,
                outputFileName: fileName,
            };
        });
        yield downloadInvoiceBatch(requests, accessToken);
        setSelectedItems([]);
        setIsBatchDownloading(false);
    });
    const handleCheckAllSelected = () => {
        return ((invoices === null || invoices === void 0 ? void 0 : invoices.length) > 0 &&
            (invoices === null || invoices === void 0 ? void 0 : invoices.every((i) => selectedItems.find((s) => s.invoiceNum === i.invoiceNum))));
    };
    const handleSelectAll = () => {
        const isAllSelected = handleCheckAllSelected();
        if (isAllSelected) {
            const items = selectedItems.filter((s) => !invoices.find((i) => i.invoiceNum === s.invoiceNum));
            setSelectedItems(items);
        }
        else {
            const items = invoices.filter((i) => !selectedItems.find((s) => s.invoiceNum === i.invoiceNum));
            let allItems = [...selectedItems, ...items];
            if (allItems.length > MAX_DOWNLOAD_ITEMS) {
                allItems = allItems.slice(0, MAX_DOWNLOAD_ITEMS);
            }
            setSelectedItems(allItems);
        }
    };
    const handleClearSelected = () => {
        setSelectedItems([]);
    };
    return {
        invoices,
        pagination,
        params,
        fetching,
        selectedItems,
        isBatchDownloading,
        isInvoiceDownloading,
        setIsInvoiceDownloading,
        onLoadData,
        setSelectedItems,
        handleSelect,
        batchDownloadInvoices,
        handleCheckAllSelected,
        handleSelectAll,
        handleClearSelected,
    };
};
export default useFleetInvoices;
