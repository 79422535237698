import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useDoubleTap } from 'use-double-tap';
import { ArrowBack, Cancel as CancelIcon } from 'src/components/mui/icons';
import { Dialog, Divider, IconButton, InputBase, List, ListItem, ListItemText, Paper, Slide, TextField, Autocomplete, } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import useIsMobile from 'src/hooks/useIsMobile';
import { FieldWrapper } from './components';
const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props}/>;
});
const StyledPaper = styled(Paper) `
  display: flex;
  border-radius: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16),
    inset 0 -2px 0 0 ${colors.primary};
`;
const EditableSearchUserEmails = ({ value, defaultValue = 'None', usersSelector, onUpdate, requireId = false, style = null, autoFocus = false, }) => {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const users = useSelector(usersSelector);
    const loading = open && users.length === 0;
    const onDoubleClick = () => setOpen(true);
    const onDoubleTap = useDoubleTap(onDoubleClick);
    const options = [{ id: null, email: 'None' }, ...users];
    const displayedEmail = () => {
        const displayUser = users.find((u) => u.id === value);
        return displayUser ? displayUser.email : defaultValue;
    };
    const onSubmit = (user) => {
        if (requireId) {
            onUpdate({
                id: user === null || user === void 0 ? void 0 : user.id,
                email: user === null || user === void 0 ? void 0 : user.email,
            });
        }
        else {
            onUpdate(user === null || user === void 0 ? void 0 : user.email);
        }
        setInputValue('');
    };
    return (<>
      {(open || autoFocus) && !isMobile && (<Autocomplete style={{ width: '100%', minHeight: '25px', marginTop: '16px' }} open={open} onOpen={() => {
                setOpen(true);
            }} onClose={(e, reason) => {
                if (reason === 'toggleInput')
                    return;
                setOpen(false);
            }} options={options} getOptionLabel={(option) => option.email} loading={loading} onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }} 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onChange={(e, user) => {
                onSubmit(user);
            }} renderInput={(params) => (<TextField {...params} InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })} autoFocus/>)}/>)}
      {isMobile && (<Dialog fullScreen open={open || autoFocus} onClose={() => setOpen(false)} TransitionComponent={Transition}>
          <StyledPaper component="form">
            <IconButton onClick={() => setOpen(false)}>
              <ArrowBack />
            </IconButton>
            <InputBase fullWidth placeholder="Search" value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
            <IconButton>
              <CancelIcon onClick={() => inputValue === '' ? setOpen(false) : setInputValue('')}/>
            </IconButton>
          </StyledPaper>

          <List>
            {options.map((option) => {
                var _a, _b, _c;
                return option.email &&
                    option.email.indexOf(inputValue) > -1 && (<div key={`div-${((_a = option.id) !== null && _a !== void 0 ? _a : -1).toString()}`}>
                    <ListItem key={`li-${((_b = option.id) !== null && _b !== void 0 ? _b : -1).toString()}`} button onClick={() => {
                        onSubmit(option);
                        setOpen(false);
                    }}>
                      <ListItemText primary={option.email}/>
                    </ListItem>
                    <Divider key={`divider-${((_c = option.id) !== null && _c !== void 0 ? _c : -1).toString()}`}/>
                  </div>);
            })}
          </List>
        </Dialog>)}
      {!open && !autoFocus && (<FieldWrapper style={style} isEditable onDoubleClick={onDoubleClick} {...onDoubleTap}>
          {displayedEmail()}
        </FieldWrapper>)}
    </>);
};
export default EditableSearchUserEmails;
