import React from 'react';
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, } from 'src/components/mui';
import { Delete as DeleteIcon, CloudDownload as DownloadIcon, Airplay as AirplayIcon, } from 'src/components/mui/icons';
import { Tooltip } from 'src/components/tooltips';
import { formatFileSize } from 'src/utils/formatter';
const InvoicesList = ({ documents, onDelete, onOpen }) => {
    return (<List dense>
      {documents.map((document) => (<ListItem key={document.id}>
          <ListItemText primary={document.name} secondary={formatFileSize(document.size)}/>
          <ListItemSecondaryAction>
            <Tooltip title="Preview Invoice">
              <IconButton aria-label="Preview Invoice" onClick={() => {
                onOpen(document);
            }}>
                <AirplayIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Invoice">
              <IconButton href={document.url} aria-label="Download">
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Invoice">
              <IconButton onClick={() => onDelete(document)} aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>))}
    </List>);
};
export default InvoicesList;
