import React, { useEffect, useState } from 'react';
import { Button, Card } from 'src/components/mui';
import GoogleMapReact from 'google-map-react';
import { formatDate } from 'src/utils/formatter';
import CONFIG from 'src/config';
import { isIOS } from 'src/utils/crossPlatformUtils';
import { useHistory } from 'react-router-dom';
import { CopyToClipboardButton } from 'src/components/buttons';
import useIsMobile from 'src/hooks/useIsMobile';
import { DATE_YMD_FORMAT, LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT, TIME_ZONE_FORMAT, } from 'src/timeConstants';
import { DurationLabel, GoogleMapReactWrapper, InfoLabel, InfoValue, MapPin, StartDateLabel, StatusLabel, ViewVisitButton, VisitCardContent, VisitInfoTable, VisitSection, VisitSectionLocation, } from './FleetVisitAppointmentCard.styles';
const FleetVisitAppointmentCard = ({ visit, partsStatus, appointment, }) => {
    const history = useHistory();
    const [visitTimeDisplay, setVisitTimeDisplay] = useState();
    const [location, setLocation] = useState();
    const isMobile = useIsMobile();
    useEffect(() => {
        if (visit) {
            const startDate = formatDate(visit.startLocal, `${LOCALIZED_DATE_FORMAT} • ${TIME_12H_FORMAT} ${TIME_ZONE_FORMAT}`, visit.timezone);
            const duration = {
                hours: Math.trunc(Number(visit.actualDurationMinutes) / 60),
                minutes: Number(visit.actualDurationMinutes) % 60,
            };
            setVisitTimeDisplay(<>
          <StartDateLabel>{startDate}</StartDateLabel>
          <DurationLabel>
            Duration: {`${duration === null || duration === void 0 ? void 0 : duration.hours}h ${duration === null || duration === void 0 ? void 0 : duration.minutes}`}m
          </DurationLabel>
        </>);
            navigator.geolocation.getCurrentPosition((position) => {
                setLocation(position.coords);
            });
        }
    }, [visit]);
    const openMaps = () => {
        let url = '';
        const addressFormatted = `${visit.address.street} ${visit.address.city}, ${visit.address.state} ${visit.address.zip}`.replace(/null/g, '');
        if (isIOS)
            url = `http://maps.apple.com/?daddr=${addressFormatted}&dirflg=d&t=m`;
        else
            url = `https://www.google.com/maps/dir/?api=1${location ? `&origin=${location.latitude},${location.longitude}` : ''}&destination=${addressFormatted}`;
        window.open(url, 'location=yes');
    };
    const van = appointment.skeduloJobResources.find((v) => v.category === 'VAN' && v.status !== 'DELETED');
    const tech = appointment.skeduloJobResources.find((v) => v.category === 'MECHANIC' && v.status !== 'DELETED');
    const viewVisit = () => {
        history.push(`/admin/visits/${visit.refNum}`);
    };
    const renderClipBoardButton = () => {
        return (<span>
        {visit.address.street},{' '}
        {`${visit.address.city}, ${visit.address.state} ${visit.address.zip} `}
        <CopyToClipboardButton value={`${visit.address.street}, ${visit.address.city}, ${visit.address.state}, ${visit.address.zip}`} style={{ marginLeft: isMobile ? '-10px' : '-6px' }}/>
      </span>);
    };
    return (<Card variant="outlined">
      <VisitCardContent>
        <VisitSection>{visitTimeDisplay}</VisitSection>

        <VisitSection>
          <VisitInfoTable>
            <tbody>
              <tr>
                <td>
                  <InfoValue>
                    <a rel="noreferrer" href={`/admin/swimlanes/${formatDate(visit.startLocal, DATE_YMD_FORMAT)}${appointment.ispContactInfo
            ? `?van=${appointment.ispContactInfo.ispId}`
            : ''}`} target="_blank">
                      Swimlane
                    </a>
                  </InfoValue>
                </td>

                <td>
                  <InfoLabel>Parts Status</InfoLabel>
                  <StatusLabel>{partsStatus}</StatusLabel>
                </td>
              </tr>
              <tr>
                <td>
                  <InfoLabel>Van</InfoLabel>
                  <InfoValue>{van ? van.name : 'Not Assigned'}</InfoValue>
                </td>
                <td>
                  <InfoLabel>Technician</InfoLabel>
                  <InfoValue>{tech ? tech.name : 'Not Assigned'}</InfoValue>
                </td>
              </tr>
            </tbody>
          </VisitInfoTable>
        </VisitSection>

        <VisitSectionLocation>
          <InfoLabel>Location</InfoLabel>
          <InfoValue>
            <Button variant="text" onClick={openMaps}>
              {renderClipBoardButton()}
            </Button>
          </InfoValue>

          <GoogleMapReactWrapper>
            <GoogleMapReact bootstrapURLKeys={{ key: CONFIG.GOOGLE_PLACES_API_KEY }} defaultCenter={{
            lat: visit.address.latitude,
            lng: visit.address.longitude,
        }} defaultZoom={14}>
              <MapPin lat={visit.address.latitude} lng={visit.address.longitude}/>
            </GoogleMapReact>
          </GoogleMapReactWrapper>
        </VisitSectionLocation>
        <ViewVisitButton onClick={viewVisit}>View Visit</ViewVisitButton>
      </VisitCardContent>
    </Card>);
};
export default FleetVisitAppointmentCard;
