import { Grid, IconButton, InputAdornment } from 'src/components/mui';
import { Visibility, VisibilityOff } from 'src/components/mui/icons';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { TextField } from 'src/AdminApp/components/form';
import { PrimaryButton } from 'src/components/buttons';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import styled from 'styled-components';
const ResetPasswordButton = styled(SimpleRowButton) `
  width: 116px;
  margin-left: auto;
  white-space: nowrap;
`;
const LoginForm = ({ onSubmit, }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const onResetPasswordClick = () => {
        const resetPasswordUrl = `${location.origin}/reset-password`;
        location.replace(resetPasswordUrl);
    };
    return (<Formik initialValues={{
            email: '',
            password: '',
        }} onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
            setSubmitting(false);
        }}>
      {({ isSubmitting }) => (<Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField name="email" type="email" label="Email" autoComplete="email" InputLabelProps={{
                shrink: true,
            }} variant="standard"/>
            </Grid>

            <Grid item xs={12}>
              <TextField name="password" type={isPasswordVisible ? 'text' : 'password'} label="Password" autoComplete="current-password" variant="standard" InputProps={{
                endAdornment: (<InputAdornment position="end">
                      <IconButton onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>),
            }} InputLabelProps={{
                shrink: true,
            }}/>
            </Grid>

            <Grid item xs={12}>
              <ResetPasswordButton onClick={onResetPasswordClick} color="primary">
                Forgot Password
              </ResetPasswordButton>
            </Grid>

            <Grid item xs={12}>
              <PrimaryButton type="submit" fullWidth disabled={isSubmitting} data-testid="test">
                Sign in
              </PrimaryButton>
            </Grid>
          </Grid>
        </Form>)}
    </Formik>);
};
export default LoginForm;
