import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { selectIsAuthenticated } from 'src/modules/auth/selectors';
import { NotFound } from 'src/pages';
import Distributors from 'src/PopsApp/pages/Distributors';
import LocationsDashboard from 'src/PopsApp/pages/Distributors/LocationsDashboard';
import Job from 'src/PopsApp/pages/Job';
import Order from 'src/PopsApp/pages/Order';
import OrderPrioritized from 'src/PopsApp/pages/OrderPrioritized';
import OrderRoster from 'src/PopsApp/pages/OrderRoster';
import PartPickupDetails from 'src/PopsApp/pages/PartPickup/Details/PartPickupDetails';
import PartPickup from 'src/PopsApp/pages/PartPickup/PartPickup';
import Parts from 'src/PopsApp/pages/Parts';
import Region from 'src/PopsApp/pages/Region';
import Van from 'src/PopsApp/pages/Van';
import Vendors from 'src/PopsApp/pages/Vendors';
import Inventory from 'src/PopsApp/pages/Inventory';
import PartPickupChecklist from '../pages/PartPickup/Checklist/PartPickupChecklist';
import PartPickupMissingParts from 'src/PopsApp/pages/PartPickup/PartPickupMissingParts/PartPickupMissingParts';
import PartPickupReschedule from '../pages/PartPickup/Reschedule/PartPickupReschedule';
import Paths from './paths';
const routes = [
    {
        path: '/pops',
        exact: true,
        component: Parts,
    },
    {
        path: '/pops/:shift/jobs',
        exact: true,
        component: Vendors,
    },
    {
        path: '/pops/:shift/jobs/order',
        exact: true,
        component: OrderPrioritized,
    },
    {
        path: '/pops/regions',
        exact: true,
        component: Region,
    },
    {
        path: '/pops/regions/:region',
        exact: true,
        component: Region,
    },
    {
        path: '/pops/regions/:region/vans',
        exact: true,
        component: Van,
    },
    {
        path: '/pops/regions/:region/vans/:van',
        exact: true,
        component: Van,
    },
    {
        path: '/pops/regions/:region/vans/:ispId/jobs',
        exact: true,
        component: Job,
    },
    {
        path: '/pops/regions/:region/vans/:ispId/jobs/vendors',
        exact: true,
        component: Vendors,
    },
    {
        path: '/pops/regions/:region/vans/:ispId/jobs/vendors/:method',
        exact: true,
        component: Vendors,
    },
    {
        path: '/pops/regions/:region/vans/:ispId/jobs/order',
        exact: true,
        component: Order,
    },
    {
        path: '/pops/regions/:region/vans/:ispId/jobs/order/:method',
        exact: true,
        component: Order,
    },
    {
        path: '/pops/order-roster/:shift',
        exact: true,
        component: OrderRoster,
    },
    {
        path: '/pops/part-pickups',
        exact: true,
        component: PartPickup,
    },
    {
        path: '/pops/part-pickups/:referenceNum',
        exact: true,
        component: PartPickupDetails,
    },
    {
        path: '/pops/part-pickups/:referenceNum/select-parts',
        exact: true,
        component: PartPickupChecklist,
    },
    {
        path: '/pops/part-pickups/:referenceNum/missing-parts',
        exact: true,
        component: PartPickupMissingParts,
    },
    {
        path: '/pops/part-pickups/:referenceNum/reschedule',
        exact: true,
        component: PartPickupReschedule,
    },
    {
        path: '/pops/distributors',
        exact: true,
        component: Distributors,
    },
    {
        path: '/pops/distributors/:distributorId',
        exact: true,
        component: LocationsDashboard,
    },
    {
        path: '/pops/inventory',
        exact: true,
        component: Inventory,
    },
    {
        path: '*',
        component: NotFound,
    },
];
const Routes = () => {
    const isAuth = useSelector(selectIsAuthenticated);
    const location = useLocation();
    return isAuth ? (<Switch>
      {routes.map((r) => (<Route key={r.path} path={r.path} exact={r.exact} component={r.component}/>))}
    </Switch>) : (<Redirect to={{ pathname: Paths.Login, state: { from: location } }}/>);
};
export default Routes;
