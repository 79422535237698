import { useEffect, useRef } from 'react';
const defaultOptions = {
    root: null,
    rootMargin: '20px',
    threshold: [0.75, 1.0],
};
function useInfiniteScroll({ callback, options = {}, parentRef = null, pause = false, ref, }) {
    const disable = useRef(pause);
    const cb = useRef(callback);
    const observer = useRef();
    useEffect(() => {
        disable.current = pause;
    }, [pause]);
    useEffect(() => {
        cb.current = callback;
    }, [callback]);
    const handleObserver = (entities, obs) => {
        if (disable.current)
            return;
        const [target] = entities;
        if (target.isIntersecting)
            cb.current(entities, obs);
    };
    useEffect(() => {
        if ((ref === null || ref === void 0 ? void 0 : ref.current) && !observer.current) {
            const opts = Object.assign(Object.assign(Object.assign({}, defaultOptions), options), ((parentRef === null || parentRef === void 0 ? void 0 : parentRef.current) && { root: parentRef.current }));
            observer.current = new window.IntersectionObserver(handleObserver, opts);
            observer.current.observe(ref.current);
        }
    }, [options, parentRef, ref]);
}
export default useInfiniteScroll;
