import styled from 'styled-components';
import { Button, Menu } from 'src/components/mui';
import { Colors, MOBILE_BREAKPOINT } from 'src/PopsApp/modules/globalStyle';
export const DateClicker = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isMobile ? 'space-between' : 'center')};
  align-items: center;
  min-width: 215px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.1px;

  button {
    padding: 12px 4px;
  }

  svg {
    color: ${Colors.GRAY_CC};
  }
`;
export const DateClickerCaption = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
export const JumpButton = styled(Button) `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
  color: ${Colors.BLUE};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;

  svg {
    width: 18px;
    height: 18px;
    fill: ${Colors.BLUE};
    color: ${Colors.BLUE};
  }
`;
export const DatePickerRow = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 8px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 0 32px;
    border-bottom: solid 1px ${Colors.GRAY_DE};
  }
`;
export const DateActions = styled.div `
  display: flex;
  justify-content: flex-end;
`;
export const DatePickerMenu = styled(Menu) ``;
