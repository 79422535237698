import React from 'react';
import { StyledTab } from 'src/AdminApp/containers/payments/PaymentDrawer/styledComponents';
import { SubHeaderWrapper } from 'src/AdminApp/components/drawers/styledComponents';
import { Tabs } from 'src/components/mui';
const SubHeader = ({ tabs, toOmit = [], tab, onChange }) => {
    if (toOmit.indexOf(tab) !== -1 || tabs.length === 1) {
        return null;
    }
    return (<SubHeaderWrapper>
      <Tabs value={tab} onChange={onChange} variant="fullWidth">
        {tabs.map((t) => (<StyledTab label={t.label} key={t.value}/>))}
      </Tabs>
    </SubHeaderWrapper>);
};
export default SubHeader;
