var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import createDefaultActionCreators from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { reloadRequest } from 'src/AdminApp/modules/requests/actions';
// FSA ACTIONS
export const ActionCreators = {
    ORDER: createDefaultActionCreators('ORDER'),
    UPDATE_ORDER: createDefaultActionCreators('UPDATE_ORDER'),
    UPDATE_APPOINTMENT: createDefaultActionCreators('UPDATE_APPOINTMENT'),
    CONSUMER_CONFIRM_APPOINTMENT: createDefaultActionCreators('CONSUMER_CONFIRM_APPOINTMENT'),
    CANCEL_ORDER: createDefaultActionCreators('CANCEL_ORDER'),
    CONFIRM_ORDER: createDefaultActionCreators('CONFIRM_ORDER'),
    RESCHEDULE_ORDER: createDefaultActionCreators('RESCHEDULE_ORDER'),
    EDIT_DATE_ORDER: createDefaultActionCreators('EDIT_DATE_ORDER'),
    EDIT_DATE_APPOINTMENT: createDefaultActionCreators('EDIT_DATE_APPOINTMENT'),
    ORDER_SELECT_SHOP: createDefaultActionCreators('ORDER_SELECT_SHOP'),
    GET_CUSTOMER_REVIEW: createDefaultActionCreators('GET_CUSTOMER_REVIEW'),
    UPDATE_CUSTOMER_REVIEW: createDefaultActionCreators('UPDATE_CUSTOMER_REVIEW'),
};
export const rescheduleAppointment = ({ requestId }) => createAPIAction({
    types: [
        ActionCreators.RESCHEDULE_ORDER.FETCH(),
        ActionCreators.RESCHEDULE_ORDER.SUCCESS(),
        ActionCreators.RESCHEDULE_ORDER.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.REQUEST_ORDER_CREATE({ requestId }),
    method: 'POST',
});
export const editDateOrder = ({ orderId, date, timeSlot, }) => createAPIAction({
    types: [
        ActionCreators.EDIT_DATE_ORDER.FETCH(),
        ActionCreators.EDIT_DATE_ORDER.SUCCESS(),
        ActionCreators.EDIT_DATE_ORDER.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ORDER_EDIT_DATE({ orderId }),
    method: 'PATCH',
    body: {
        date,
        timeSlot,
    },
});
export const editDateAppointment = ({ referenceNum, date, timeSlot, }) => createAPIAction({
    types: [
        ActionCreators.EDIT_DATE_APPOINTMENT.FETCH(),
        ActionCreators.EDIT_DATE_APPOINTMENT.SUCCESS(),
        ActionCreators.EDIT_DATE_APPOINTMENT.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.APPOINTMENT_EDIT_DATE({ referenceNum }),
    method: 'POST',
    body: {
        date,
        timeSlot,
    },
});
export const updateAppointment = ({ zip, status, resolution, otherResolution, deliveryChannel, fetchInvoice = true, }, orderId, workOrderId, referenceNum) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const updateAppointmentApiAction = createAPIAction({
        types: [
            ActionCreators.UPDATE_APPOINTMENT.FETCH(),
            ActionCreators.UPDATE_APPOINTMENT.SUCCESS({
                meta: () => ({ orderId, referenceNum }),
            }),
            ActionCreators.UPDATE_APPOINTMENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.ORDER_APPOINTMENT({ orderId }),
        method: 'PATCH',
        body: {
            zip,
            status,
            resolution,
            otherResolution,
            deliveryChannel,
        },
    });
    if (fetchInvoice) {
        yield dispatch(updateAppointmentApiAction);
        return dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    }
    return dispatch(updateAppointmentApiAction);
});
export const updateOrder = ({ status = null, resolution = null, otherResolution = null, smsRoNumber = null, }, orderId, workOrderId, referenceNum, refreshRequest = false) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const updateOrderApiAction = createAPIAction({
        types: [
            ActionCreators.UPDATE_ORDER.FETCH(),
            ActionCreators.UPDATE_ORDER.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.UPDATE_ORDER.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.ORDER({ orderId }),
        method: 'PATCH',
        body: Object.assign(Object.assign(Object.assign(Object.assign({}, (status && { status })), (resolution && { resolution })), (otherResolution && { otherResolution })), (smsRoNumber !== null && { smsRoNumber })),
    });
    yield dispatch(updateOrderApiAction);
    const result = yield dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    if (refreshRequest) {
        return dispatch(reloadRequest(referenceNum));
    }
    return result;
});
export const selectOrderShop = ({ orderId, ispId, }) => createAPIAction({
    types: [
        ActionCreators.ORDER_SELECT_SHOP.FETCH(),
        ActionCreators.ORDER_SELECT_SHOP.SUCCESS(),
        ActionCreators.ORDER_SELECT_SHOP.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ORDER_SELECT_SHOP({ orderId, ispId }),
    method: 'PUT',
});
export const getCustomerReviewByOrderId = (orderId) => createAPIAction({
    types: [
        ActionCreators.GET_CUSTOMER_REVIEW.FETCH(),
        ActionCreators.GET_CUSTOMER_REVIEW.SUCCESS({
            meta: () => ({ orderId }),
        }),
        ActionCreators.GET_CUSTOMER_REVIEW.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CUSTOMER_REVIEW({ orderId }),
    method: 'GET',
});
export const updateCustomerReviewByOrderId = ({ orderId, values, }) => createAPIAction({
    types: [
        ActionCreators.UPDATE_CUSTOMER_REVIEW.FETCH(),
        ActionCreators.UPDATE_CUSTOMER_REVIEW.SUCCESS({
            meta: () => ({ orderId }),
        }),
        ActionCreators.UPDATE_CUSTOMER_REVIEW.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CUSTOMER_REVIEW({ orderId }),
    method: 'PUT',
    body: values,
});
