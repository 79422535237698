import { AddCircle, RemoveCircle } from '@mui/icons-material';
import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, CircularProgress, Grid, TextField, } from 'src/components/mui';
import { DrawerFormRow, FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import useAddAuthDrawer from './useAddAuthDrawer';
import { AuthorizationRepair, RepairAuthorizationSection, RepairAuthorizationTitle, } from '../AuthBtn.styles';
import { LoaderWrapper } from 'src/AdminApp/components/deliveryVan/DeliveryVanTemplatesDrawer/DeliveryVanTemplate.styles';
import { SimpleModal } from 'src/components/layout';
const AddAuthDrawer = ({ handleClose, open, referenceNum, workOrderId, invoice, responsiblePartyName, repairsToAuthorize, workOrderResponsiblePartyAuthorization, repairRequestId, }) => {
    var _a;
    const { onSubmit, addedRepairs, pendingRepairs, addRepair, removeRepair, authorizedRepairsSum, unauthorizedRepairsSum, isConfirmSaveOpen, setIsConfirmSaveOpen, loading, onUpdate, resetAddedRepairs, addedRepairsChanged, } = useAddAuthDrawer({
        referenceNum,
        workOrderId,
        invoice,
        handleClose,
        repairsToAuthorize,
        workOrderResponsiblePartyAuthorization,
        repairRequestId,
    });
    const validationSchema = Yup.object().shape({
        number: Yup.string().min(1).required(),
        claimAmount: Yup.string().min(1).required(),
    });
    return (<Formik initialValues={{
            claimAmount: ((_a = workOrderResponsiblePartyAuthorization === null || workOrderResponsiblePartyAuthorization === void 0 ? void 0 : workOrderResponsiblePartyAuthorization.claimAmount) === null || _a === void 0 ? void 0 : _a.toFixed(2)) || '',
            number: (workOrderResponsiblePartyAuthorization === null || workOrderResponsiblePartyAuthorization === void 0 ? void 0 : workOrderResponsiblePartyAuthorization.number) || '',
        }} validationSchema={validationSchema} onSubmit={(values) => onSubmit(values)} enableReinitialize validateOnMount>
      {({ isValid, setFieldValue, handleSubmit, dirty, values, resetForm }) => (<>
          <SimpleDrawer open={open} onClose={() => {
                resetForm();
                resetAddedRepairs();
                handleClose();
            }} title={`${responsiblePartyName} Authorization Number`} size="oneThird">
            {loading ? (<LoaderWrapper>
                <CircularProgress size={40}/>
              </LoaderWrapper>) : (<>
                <SimpleDrawerContent>
                  <Grid container xs={12}>
                    <DrawerFormRow item xs={12}>
                      <TextField value={values === null || values === void 0 ? void 0 : values.number} label="Authorization Number" placeholder="Authorization Number" name="number" variant="outlined" onChange={(e) => setFieldValue('number', e.target.value)} fullWidth/>
                    </DrawerFormRow>
                    <DrawerFormRow item xs={12}>
                      <TextField value={values === null || values === void 0 ? void 0 : values.claimAmount} label="Approved Claim Amount" placeholder="Approved Claim Amount" name="claimAmount" variant="outlined" type="number" onChange={(e) => setFieldValue('claimAmount', e.target.value)} fullWidth/>
                    </DrawerFormRow>
                  </Grid>
                  <RepairAuthorizationSection>
                    <RepairAuthorizationTitle>
                      <h2>Unauthorized Repairs</h2>
                      <h4>Total: ${unauthorizedRepairsSum}</h4>
                    </RepairAuthorizationTitle>

                    {pendingRepairs &&
                    (pendingRepairs === null || pendingRepairs === void 0 ? void 0 : pendingRepairs.map((repair) => {
                        var _a;
                        return (<AuthorizationRepair>
                          {repair.repairName}: ${(_a = repair.totalPrice) === null || _a === void 0 ? void 0 : _a.toFixed(2)}
                          <Button color="primary" onClick={() => {
                                addRepair(repair);
                            }} startIcon={<AddCircle />}>
                            Add
                          </Button>
                        </AuthorizationRepair>);
                    }))}
                  </RepairAuthorizationSection>
                  <RepairAuthorizationSection>
                    <RepairAuthorizationTitle>
                      <h2>Authorized Repairs</h2>
                      <h4>Total: ${authorizedRepairsSum}</h4>
                    </RepairAuthorizationTitle>
                    {addedRepairs &&
                    (addedRepairs === null || addedRepairs === void 0 ? void 0 : addedRepairs.map((repair) => {
                        var _a;
                        return (<AuthorizationRepair>
                          {repair.repairName}: ${(_a = repair === null || repair === void 0 ? void 0 : repair.totalPrice) === null || _a === void 0 ? void 0 : _a.toFixed(2)}
                          <Button color="primary" onClick={() => {
                                removeRepair(repair);
                            }} startIcon={<RemoveCircle />}>
                            Remove
                          </Button>
                        </AuthorizationRepair>);
                    }))}
                  </RepairAuthorizationSection>
                </SimpleDrawerContent>
                <SimpleDrawerFooter>
                  <FooterActionButton disabled={!isValid ||
                    (!dirty && !addedRepairsChanged) ||
                    (addedRepairs === null || addedRepairs === void 0 ? void 0 : addedRepairs.length) < 1} onClick={handleSubmit}>
                    Submit
                  </FooterActionButton>
                </SimpleDrawerFooter>
              </>)}
            <SimpleModal open={isConfirmSaveOpen} onClose={() => setIsConfirmSaveOpen(false)} title="Confirm Reauthorization" simpleTwoButtonRowProps={{
                onCancel: () => setIsConfirmSaveOpen(false),
                onSubmit: () => onUpdate(values),
                cancelText: 'No',
                submitText: 'Yes',
            }}>
              These services were previously authorized. Please confirm that
              these changes have been reauthorized.
            </SimpleModal>
          </SimpleDrawer>
        </>)}
    </Formik>);
};
export default AddAuthDrawer;
