import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { mapMetro } from 'src/AdminApp/modules/mappers';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byId: {},
    autocomplete: [],
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['id,asc'],
        },
    },
};
const metroSuccessReducer = (state, action) => {
    const model = mapMetro(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [model.id]: fetched(model) }), dashboard: Object.assign(Object.assign({}, state.dashboard), { _isFetching: false }) });
};
const metrosSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const metrosFetchReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const metroFetchReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { _isFetching: true }) });
};
const metrosClearReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const metrosAutocompleteSuccessReducer = (state, action) => {
    const results = action === null || action === void 0 ? void 0 : action.payload;
    return Object.assign(Object.assign({}, state), { autocomplete: results });
};
const metrosDeleteSuccessReducer = (state, action) => {
    var _a, _b;
    const { id } = action.meta;
    const content = (_b = (_a = state === null || state === void 0 ? void 0 : state.dashboard) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.filter((x) => x.id !== id);
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { dashboard: {
                content,
            }, pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { totalElements: state.dashboard.pagination.totalElements - 1 }) }) });
};
const reducers = handleActions({
    [ActionCreators.METROS.SUCCESS.type]: metrosSuccessReducer,
    [ActionCreators.METROS.FETCH.type]: metrosFetchReducer,
    [ActionCreators.METROS.CLEAR.type]: metrosClearReducer,
    [ActionCreators.METRO.SUCCESS.type]: metroSuccessReducer,
    [ActionCreators.METRO.FETCH.type]: metroFetchReducer,
    [ActionCreators.METROS_AUTOCOMPLETE.SUCCESS.type]: metrosAutocompleteSuccessReducer,
    [ActionCreators.ARCHIVE_METRO.SUCCESS.type]: metrosDeleteSuccessReducer,
}, DEFAULT_STATE);
export default reducers;
