import React from 'react';
import { STATIC_URL_BASE } from 'src/styles/theme';
import { SimpleModal } from 'src/components/layout';
import { DialogTitleContainer } from './ConfirmAddingModal.styles';
const ConfirmAddingModal = ({ open, onConfirm, onCancel, }) => {
    return (<SimpleModal open={open} title={<DialogTitleContainer>
          <div>
            <img alt="special" src={`${STATIC_URL_BASE}img/svg/special-order-label.svg`}/>
          </div>
          <div>Confirm Override</div>
        </DialogTitleContainer>} simpleTwoButtonRowProps={{
            onSubmit: onConfirm,
            submitText: 'Yes',
            cancelText: 'Cancel',
            onCancel,
        }}>
      This repair order requires additional lead time to acquire parts. Would
      you still like to add it to this visit?
    </SimpleModal>);
};
export default ConfirmAddingModal;
