export const getShopDashboardUrl = (id) => {
    const shopDomain = location.origin.replace('tools', 'shop');
    return `${shopDomain}/isp/${id}/dashboard`;
};
export const getZendeskUrl = (path = '') => {
    const zendeskDomain = location.hostname === 'tools.autonationmobileservice.com'
        ? 'repairsmithinc.zendesk.com'
        : 'repairsmith-stage.zendesk.com';
    return `https://${zendeskDomain}${path}`;
};
export const getSkeduloUrl = (path = '') => {
    const skeduloDomain = location.hostname === 'tools.autonationmobileservice.com'
        ? 'repairsmith.my.skedulo.com'
        : 'staging-repairsmith.my.skedulo.com';
    return `https://${skeduloDomain}${path}`;
};
export const getRepairDashboardUrl = (id) => `${location.origin.replace('admin', 'repairs')}/admin/repairs/${id}`;
export const getConsumerUrl = (path) => {
    if (location.origin === 'http://localhost:3002') {
        return `${location.origin.replace('3002', '3003')}/${path}`;
    }
    return `${location.origin.endsWith('.com')
        ? 'https://autonationmobileservice.com'
        : location.origin.replace('tools', 'consumer')}/${path}`;
};
export const getShopUrl = (path) => {
    const shopDomain = location.origin.replace('tools', 'shop');
    return `${shopDomain}/${path}`;
};
export const getFieldOpsUrl = (path) => {
    if (location.origin === 'http://localhost:3002') {
        return `${location.origin.replace('3002', '3000')}/${path}`;
    }
    return `${location.origin.replace('tools', 'fieldops')}/${path}`;
};
export function addProtocol(url) {
    // eslint-disable-next-line no-useless-escape
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = `//${url}`;
    }
    return url;
}
