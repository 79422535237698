import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
export const TooltipContainer = styled.section `
  padding: 18px 19px;
`;
export const CantFinishReasonTooltipTitle = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.WHITE};
`;
export const CantFinishReasonTooltipList = styled.div `
  font-family: ${FONT_FAMILY};
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.1px;
  color: ${colors.WHITE};
  margin-top: 4px;
`;
