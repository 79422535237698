import { createGlobalStyle } from 'styled-components';
export const STATIC_URL_BASE = 'https://assets.repairsmith.com/web/';
export const FONT_FAMILY = 'Interstate, sans-serif';
const GlobalStyle = createGlobalStyle `

  @font-face {
    font-family: 'Interstate';
    font-style: normal;
    font-display: swap;
    src: url('${STATIC_URL_BASE}anms/fonts/Interstate-Black.eot');
    src: url('${STATIC_URL_BASE}anms/fonts/Interstate-Black.woff2') format('woff2'),
      url('${STATIC_URL_BASE}anms/fonts/Interstate-Black.woff') format('woff'),
      url('${STATIC_URL_BASE}anms/fonts/Interstate-Black.ttf') format('truetype');
    font-weight: 900;
  }

  @font-face {
    font-family: 'Interstate';
    font-style: normal;
    font-display: swap;
    src: url('${STATIC_URL_BASE}anms/fonts/Interstate-Bold.eot');
    src: url('${STATIC_URL_BASE}anms/fonts/Interstate-Bold.woff2') format('woff2'),
      url('${STATIC_URL_BASE}anms/fonts/Interstate-Bold.woff') format('woff'),
      url('${STATIC_URL_BASE}anms/fonts/Interstate-Bold.ttf') format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Interstate';
    font-style: normal;
    font-display: swap;
    src: url('${STATIC_URL_BASE}anms/fonts/Interstate-Light.eot');
    src: url('${STATIC_URL_BASE}anms/fonts/Interstate-Light.woff2') format('woff2'),
      url('${STATIC_URL_BASE}anms/fonts/Interstate-Light.woff') format('woff'),
      url('${STATIC_URL_BASE}anms/fonts/Interstate-Light.ttf') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Interstate';
    font-style: normal;
    font-display: swap;
    src: url('${STATIC_URL_BASE}anms/fonts/Interstate-Regular.eot');
    src: url('${STATIC_URL_BASE}anms/fonts/Interstate-Regular.woff2') format('woff2'),
      url('${STATIC_URL_BASE}anms/fonts/Interstate-Regular.woff') format('woff'),
      url('${STATIC_URL_BASE}anms/fonts/Interstate-Regular.ttf') format('truetype');
    font-weight: 400;
  }

  .MuiButton-root {
    font-family: ${FONT_FAMILY} ;
  }

  .MuiTypography-body1 {
    font-family: ${FONT_FAMILY} ;
  }

  .MuiTypography-h5 {
    font-family: ${FONT_FAMILY} ;
  }

  .MuiTypography-subtitle1 {
    font-family: ${FONT_FAMILY} ;
  }

`;
export default GlobalStyle;
