import React from 'react';
import { ActionButton } from 'src/components/buttons';
import useFixFirebaseUser from './useFixFirebaseUser';
const FixFirebaseUser = ({ email, id }) => {
    const { submitting, handleSubmit } = useFixFirebaseUser();
    return (<ActionButton disabled={submitting} onClick={() => handleSubmit(email, id)}>
      Fix Firebase User
    </ActionButton>);
};
export default FixFirebaseUser;
