import React from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import { formatCurrency } from 'src/utils/formatter';
import DiscountStatementItem from 'src/AdminApp/components/discounts/DiscountStatementItem';
const RenderDiscounts = ({ promos = [], subscriptions = [], credits = [], otherDiscounts = [], remove, canEdit = false, }) => {
    return (<>
      {map(promos, (promo = {}) => {
            var _a;
            const { id, promoCode } = promo;
            const label = `Promo Code: ${promoCode.code}`;
            const formattedAmount = promoCode.discountType === 'AMOUNT'
                ? formatCurrency(promoCode.amountOff)
                : `${promoCode.percentOff}%`;
            return (<DiscountStatementItem key={id} amount={formattedAmount} discountType="Promo Code" id={id} isClosedAndUnredeemed={false} // this.props.closed && !promoCodeRedeemed;
             canEdit={canEdit} label={label} redeemed={(_a = promo.redeemed) !== null && _a !== void 0 ? _a : false} remove={remove}/>);
        })}
      {subscriptions.map((subscription) => {
            const { id, name, subscriptionPercentOff } = subscription;
            const formattedAmount = `${subscriptionPercentOff}%`;
            const label = `${name} - ${formattedAmount} Discount`;
            return (<DiscountStatementItem key={id} amount={formattedAmount} discountType="Subscription" id={id} isClosedAndUnredeemed={false} canEdit={false} label={label}/>);
        })}
      {map(credits, (credit = {}) => {
            const { id, originalReferenceNum, discountedAmount } = credit;
            const originalROLink = `/admin/repairs/${originalReferenceNum}`;
            const label = (<span>
            Credit: <Link to={originalROLink}>Cancel Fee Credit</Link>
          </span>);
            return (<DiscountStatementItem key={id} amount={formatCurrency(discountedAmount)} discountType="Cancel Fee Credit" id={id} canEdit={canEdit} label={label} remove={remove}/>);
        })}
      {map(otherDiscounts, (discount = {}) => {
            var _a;
            const { id, discountReasonType, reasonDescription, discountedAmount } = discount;
            const description = `${(_a = discountReasonType === null || discountReasonType === void 0 ? void 0 : discountReasonType.internalReason) !== null && _a !== void 0 ? _a : ''}${reasonDescription ? `: ${reasonDescription}` : ''}`;
            return (<DiscountStatementItem key={id} amount={formatCurrency(discountedAmount)} discountType="discount" id={id} canEdit={canEdit} label={description} remove={remove}/>);
        })}
    </>);
};
export default RenderDiscounts;
