import { TextField } from 'src/components/mui';
import React from 'react';
import { SubText } from 'src/components/SimpleDrawer';
const WrongPartNoteInput = ({ setNote, note, }) => {
    return (<>
      <h2>Notes</h2>
      <SubText>Add any additional notes.</SubText>
      <TextField fullWidth label="Notes" name="note" variant="outlined" placeholder="Any additional notes will be added here in regards to the faulty part received." multiline minRows={4} value={note} onChange={(e) => {
            setNote(e.target.value);
        }} style={{
            marginTop: '16px',
        }}/>
    </>);
};
export default WrongPartNoteInput;
