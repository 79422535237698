import { Button, Grid, useMediaQuery } from 'src/components/mui';
import { CheckCircleRounded } from 'src/components/mui/icons';
import React, { useState } from 'react';
import CONFIG from 'src/config';
import { RefLabel, Tag, } from 'src/TechApp/components/AppointmentList/AppointmentList.styles';
import { RefNumsContainer } from 'src/TechApp/components/AppointmentTask/AppointmentTask.styles';
import { renderServiceAddress, renderTimeWindow, } from 'src/TechApp/components/TaskParts';
import { theme } from 'src/styles/theme';
import PartPickupDrawer from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupDrawer';
import { CompletedLabel, PartPickup, PartPickupTaskAction, PickupDesc, PickupSection, } from './PartPickupTask.styles';
const PartPickupTask = ({ distributorName, address, startLocal, endLocal, location, partPickupOrderId, status, techName, isPorter, resources, }) => {
    var _a, _b;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPartPickupViewEnabled = CONFIG.ENABLE_PART_PICKUP_DISPLAY === 'true';
    const [isPartPickupDrawerOpen, setIsPartPickupDrawerOpen] = useState(false);
    const vanName = (_a = resources === null || resources === void 0 ? void 0 : resources.find((r) => r.category === 'VAN')) === null || _a === void 0 ? void 0 : _a.name;
    const porterTechName = (_b = resources === null || resources === void 0 ? void 0 : resources.find((r) => r.category === 'MECHANIC')) === null || _b === void 0 ? void 0 : _b.name;
    const handleOpenPartPickupDrawer = () => {
        setIsPartPickupDrawerOpen(true);
    };
    const onClosePartPickupDrawer = () => {
        setIsPartPickupDrawerOpen(false);
    };
    const getActionButton = () => {
        if (!isPartPickupViewEnabled || !partPickupOrderId) {
            return null;
        }
        if (status === 'COMPLETED') {
            return (<CompletedLabel>
          <span>Completed</span> <CheckCircleRounded />
        </CompletedLabel>);
        }
        return (<PartPickupTaskAction item>
        <Button variant="contained" color="primary" fullWidth={isMobile} onClick={handleOpenPartPickupDrawer}>
          View Details
        </Button>
      </PartPickupTaskAction>);
    };
    return (<>
      {isMobile ? (<PartPickup container direction="column" wrap="nowrap">
          <PickupSection container item>
            <Grid item xs={12}>
              {renderTimeWindow(startLocal, endLocal)}
              <Tag slugType="pickup">Part Pickup</Tag>
            </Grid>
            <Grid item>
              <RefNumsContainer>
                <RefLabel>Distributor: &nbsp;</RefLabel>
                <PickupDesc>{distributorName !== null && distributorName !== void 0 ? distributorName : ' Not available'}</PickupDesc>
              </RefNumsContainer>
            </Grid>
          </PickupSection>

          <PickupSection container item className="serviceAddress">
            <Grid item>
              {renderServiceAddress(address, location, 'Pickup From:')}
            </Grid>
          </PickupSection>
          {isPorter && (<PickupSection container item>
            <Grid item xs={12}>
              <RefNumsContainer>
                <RefLabel>Tech & Van: &nbsp;</RefLabel>
                <PickupDesc>{`${isPorter ? porterTechName : techName}${vanName ? ` • ${vanName}` : ''}`}</PickupDesc>
              </RefNumsContainer>
            </Grid>
          </PickupSection>)}

          {getActionButton()}
        </PartPickup>) : (<tr>
          <td>{renderTimeWindow(startLocal, endLocal)}</td>
          <td>
            <Tag slugType="pickup">Part Pickup</Tag>
            {renderServiceAddress(address, location, 'Pickup From:')}
          </td>
          <td colSpan={3}>
            <RefNumsContainer>
              <RefLabel>Distributor: &nbsp;</RefLabel>
              <PickupDesc>{distributorName !== null && distributorName !== void 0 ? distributorName : 'Not available'}</PickupDesc>
            </RefNumsContainer>
          </td>
          <td className="taskActions">{getActionButton()}</td>
        </tr>)}
      {partPickupOrderId && (<PartPickupDrawer partPickupOrderId={partPickupOrderId} address={address} open={isPartPickupDrawerOpen} onClose={onClosePartPickupDrawer} startLocal={startLocal} techName={techName}/>)}
    </>);
};
export default PartPickupTask;
