var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { TableRow, TableCell, TextField } from 'src/components/mui';
import { HighlightOff as HighlightOffIcon, Edit as EditIcon, Save as SaveIcon, Done as DoneIcon, CloudDownload as CloudDownloadIcon, } from 'src/components/mui/icons';
import { SalesMatchValidationStatus } from 'src/AdminApp/models/enums';
import { getShopDashboardUrl, getRepairDashboardUrl, } from 'src/AdminApp/utils/url-utils';
import { formatCurrency, formatPercentage, formatDate, } from 'src/utils/formatter';
import { updateSalesMatchRequestId, downloadSalesMatchInvoice, } from 'src/AdminApp/modules/salesMatch/actions';
import { selectAuthorizedSalesMatchEdit } from 'src/modules/auth/selectors';
import SmallIconButton from 'src/components/icons/SmallIconButton';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const rowColor = (validationStatus) => {
    let color;
    switch (validationStatus) {
        case SalesMatchValidationStatus.Invalid:
            color = '#ff00004f';
            break;
        case SalesMatchValidationStatus.Valid:
            color = '#0080005c';
            break;
        default:
            color = '';
    }
    return color;
};
const RedSmallIconButton = styled(SmallIconButton) `
  color: red;
`;
const GreenSmallIconButton = styled(SmallIconButton) `
  color: green;
`;
const EditButton = (props) => (<SmallIconButton aria-label="Edit repair request" title="Edit repair request" onClick={props.onClick}>
    <EditIcon />
  </SmallIconButton>);
const SaveButton = (props) => (<SmallIconButton aria-label="Save repair request" title="Save repair request" disabled={props.disabled} onClick={props.onClick}>
    <SaveIcon />
  </SmallIconButton>);
const DeleteInvalidMatchButton = (props) => (<RedSmallIconButton aria-label="Mark as invalid match" title="Mark as invalid match" onClick={props.onClick}>
    <HighlightOffIcon />
  </RedSmallIconButton>);
const VerifyInvoiceButton = (props) => (<GreenSmallIconButton aria-label="Verify Invoice" title="Verify Invoice" onClick={props.onClick}>
    <DoneIcon />
  </GreenSmallIconButton>);
const DownloadInvoiceButton = (props) => {
    return (<SmallIconButton onClick={props.onClick} aria-label="Download Invoice" title="Download Invoice">
      <CloudDownloadIcon />
    </SmallIconButton>);
};
const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  td {
    padding: 15px !important;
    white-space: nowrap;
  }
`;
const DashboardListItem = ({ id, shopIspId, shopName, invoiceNumber, dateRepairCompleted, repairRequestStatus, repairRequestState, nameOfCustomer, vehicleYearMakeModel, subtotal, totalAmount, salesTax, hazmatExpense, saleOfTires, repairRequestId, percentageMatch, validationStatus, deleteAction, verifyInvoiceAction, }) => {
    const [editRepairRequestEnabled, setEditRepairRequestEnabled] = useState(false);
    const [updatedRepairRequestIdError, setUpdatedRepairRequestIdError] = useState(false);
    const [repairRequestIdIsUpdating, setRepairRequestIdIsUpdating] = useState(false);
    const canEdit = useSelector(selectAuthorizedSalesMatchEdit);
    const dispatch = useDispatch();
    const enableRepairRequestEditing = () => {
        setEditRepairRequestEnabled(true);
    };
    const saveRepairRequest = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!updatedRepairRequestIdError) {
            setRepairRequestIdIsUpdating(true);
            try {
                yield dispatch(updateSalesMatchRequestId(id, repairRequestId));
            }
            catch (err) {
                toast.error("Sales match wasn't updated");
            }
            finally {
                setRepairRequestIdIsUpdating(false);
                setEditRepairRequestEnabled(false);
            }
        }
        else {
            toast.error(updatedRepairRequestIdError);
        }
    });
    const downloadInvoice = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield dispatch(downloadSalesMatchInvoice(id, invoiceNumber));
        }
        catch (err) {
            toast.error('Download Invoice Error');
        }
    });
    const setUpdatedRequestId = (newValue) => {
        if (newValue) {
            setUpdatedRepairRequestIdError(false);
        }
        else {
            setUpdatedRepairRequestIdError("RepairRequestId can't be empty");
        }
    };
    let actionButton;
    if (!canEdit) {
        actionButton = <div>&nbsp;</div>;
    }
    else if (editRepairRequestEnabled) {
        actionButton = (<SaveButton disabled={repairRequestIdIsUpdating} onClick={saveRepairRequest}/>);
    }
    else {
        actionButton = <EditButton onClick={enableRepairRequestEditing}/>;
    }
    return (<StyledTableRow style={{ backgroundColor: rowColor(validationStatus) }}>
      <TableCell>
        {shopIspId && (<a href={getShopDashboardUrl(shopIspId)} target="_blank" rel="noreferrer">
            {shopName}
          </a>)}
        {!shopIspId && <span>{shopName}</span>}
      </TableCell>
      <TableCell size="small">
        {formatPercentage(percentageMatch, 0)}
      </TableCell>
      <TableCell>{invoiceNumber}</TableCell>
      <TableCell>
        {dateRepairCompleted
            ? formatDate(dateRepairCompleted, LOCALIZED_DATE_FORMAT)
            : 'N/A'}
      </TableCell>
      <TableCell>{nameOfCustomer}</TableCell>
      <TableCell>{vehicleYearMakeModel}</TableCell>
      <TableCell>{formatCurrency(subtotal)}</TableCell>
      <TableCell>{formatCurrency(salesTax)}</TableCell>
      <TableCell>{hazmatExpense}</TableCell>
      <TableCell>{formatCurrency(saleOfTires)}</TableCell>
      <TableCell>{formatCurrency(totalAmount)}</TableCell>
      <TableCell>{SalesMatchValidationStatus[validationStatus]}</TableCell>
      <TableCell>{repairRequestStatus}</TableCell>
      <TableCell>{repairRequestState}</TableCell>
      <TableCell>
        {editRepairRequestEnabled ? (<form onSubmit={saveRepairRequest}>
            <TextField fullWidth error={!!updatedRepairRequestIdError} disabled={repairRequestIdIsUpdating} defaultValue={repairRequestId} onChange={(e) => setUpdatedRequestId(e.target.value)}/>
          </form>) : (repairRequestId && (<a href={getRepairDashboardUrl(repairRequestId)} target="_blank" rel="noreferrer">
              {repairRequestId}
            </a>))}
      </TableCell>
      <TableCell align="right">
        {actionButton}
        {validationStatus !== SalesMatchValidationStatus.Valid && (<VerifyInvoiceButton onClick={verifyInvoiceAction}/>)}
        <DownloadInvoiceButton onClick={downloadInvoice}/>
        <DeleteInvalidMatchButton onClick={deleteAction}/>
      </TableCell>
    </StyledTableRow>);
};
export default DashboardListItem;
