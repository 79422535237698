import { has, indexOf } from 'lodash';
import { forceReAuthentication, refreshAuth } from 'src/modules/auth/actions';
import { selectAccessToken, selectRefreshToken, } from 'src/modules/auth/selectors';
import { isTokenExpired } from 'src/modules/auth/utils';
const tokenValidatorMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    const ENDPOINTS_TO_IGNORE = ['/auth/login', '/auth/refresh-token'];
    if (has(action, '@@redux-api-middleware/RSAA')) {
        if (indexOf(ENDPOINTS_TO_IGNORE, action['@@redux-api-middleware/RSAA'].endpoint) !== -1) {
            return next(action);
        }
        const state = getState();
        const accessToken = selectAccessToken(state);
        const refreshToken = selectRefreshToken(state);
        if (accessToken && isTokenExpired(accessToken)) {
            if (refreshToken) {
                return dispatch(refreshAuth(refreshToken))
                    .then(() => next(action))
                    .catch(() => {
                    // NOTE: in this scenario, forceReAuthentication is handled by the apiErrorMiddleware
                });
            }
            return dispatch(forceReAuthentication());
        }
    }
    return next(action);
};
export default tokenValidatorMiddleware;
