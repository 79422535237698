import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'src/components/mui';
import { getRegionsByFilters } from 'src/AdminApp/modules/regions/actions';
import { selectDashboardRegions, selectDashboardParams, selectDashboardPagination, selectDashboardFetching, } from 'src/AdminApp/modules/regions/selectors';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import RegionListItem from './RegionListItem';
import SearchRegions from './SearchRegions';
const TopBlock = styled(Grid) `
  margin-bottom: 64px;
`;
const REGIONS_COLUMNS = [
    { title: 'Region', key: 'name', sortable: true },
];
const Regions = () => {
    var _a;
    const dispatch = useDispatch();
    const regions = useSelector(selectDashboardRegions);
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    const isFetching = useSelector(selectDashboardFetching);
    const onLoadData = ({ page, size, sort }) => dispatch(getRegionsByFilters(Object.assign(Object.assign({}, params), { pagination: Object.assign(Object.assign({}, pagination), { size,
            page,
            sort }) })));
    return (<>
      <h3>Regions</h3>
      <TopBlock container spacing={4}>
        <Grid item lg={12} md={12} xs={12}>
          <SearchRegions />
        </Grid>
      </TopBlock>
      <Grid container>
        <Grid item md={12} xs={12}>
          <SimpleTable data={regions} pagination={pagination} sort={(_a = pagination.sort) !== null && _a !== void 0 ? _a : []} columns={REGIONS_COLUMNS} isFetching={isFetching} renderItem={(item, index) => (<RegionListItem item={item} key={`${index}-${item.id}`}/>)} onLoadData={(params) => onLoadData(params)}/>
        </Grid>
        <Grid item md={7} xs={12}>
          {/* TODO: Need to create a separate Google Map based component */}
          {/* {renderMap()} */}
        </Grid>
      </Grid>
    </>);
};
export default Regions;
