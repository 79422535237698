import React from 'react';
export const LockIcon = () => {
    return (<svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path id="cg8pm1lkya" d="M0 0h26v26H0z"/>
      </defs>
      <g fill="currentColor" fillRule="evenodd">
        <mask id="a3228f9awb" fill="#fff">
          <use xlinkHref="#cg8pm1lkya"/>
        </mask>
        <g mask="url(#a3228f9awb)" fill="currentColor" fillRule="nonzero">
          <path d="M20 12.945v7c0 .644-.525 1.166-1.17 1.166H7.167A1.166 1.166 0 0 1 6 19.945v-7c0-.644.522-1.166 1.166-1.166h.39V9.446C7.556 6.456 10.011 4 13 4c2.99 0 5.445 2.455 5.445 5.446v2.333h.387c.644 0 1.169.522 1.169 1.166zM8.898 11.81h8.204V9.514c0-2.297-1.844-4.172-4.103-4.172-2.256 0-4.1 1.875-4.1 4.172v2.295z"/>
        </g>
      </g>
    </svg>);
};
