import React from 'react';
import { Grid } from 'src/components/mui';
import styled from 'styled-components';
import ProgressBar from './ProgressBar';
const TrackerContainer = styled(Grid) `
  border-radius: 24px;
  border: solid 1px rgba(222, 222, 222, 0.24);
  background-color: rgba(63, 63, 63, 0.01);
  align-items: center;
  padding: 16px 0 16px 32px;
`;
const StateTracker = ({ repairRequest, visit, isMobile, activeAppointment, currentTask, referenceNum, }) => (<TrackerContainer container>
    <Grid item md={12} xs={12}>
      <ProgressBar state={repairRequest.state} visit={visit} isMobile={isMobile} activeAppointment={activeAppointment} currentTask={currentTask} referenceNum={referenceNum}/>
    </Grid>
  </TrackerContainer>);
export default StateTracker;
