import styled from 'styled-components';
import { AccordionSummary, AccordionDetails } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const WorkOrderSummary = styled(AccordionSummary) `
  border-bottom: ${({ expanded }) => {
    return expanded === 'true' ? `solid 1px ${colors.GRAY_E1}` : 'none';
}};

  .summary-content {
    margin: 20px 0;
  }

  span,
  a {
    text-decoration: ${({ removed }) => (removed ? 'line-through' : 'none')};
    color: ${({ removed }) => (removed ? colors.GRAY_CC : 'inherit')};
  }
`;
export const RemovedLabel = styled.div `
  text-decoration: none;
  color: ${colors.GRAY_3F};
  margin-left: 3px;
`;
export const WorkOrderDetails = styled(AccordionDetails) `
  padding: 0;
  flex-direction: column;

  &:last-child {
    padding: 0;
  }
`;
export const Details = styled.table `
  width: 100%;
  padding: 0 16px 16px;

  tr td:first-child {
    width: 65%;
  }

  td {
    padding-top: 20px;
    vertical-align: top;

    &.removed {
      div {
        color: ${colors.GRAY_CC};

        &:not(:first-child) {
          text-decoration: line-through;
        }
      }

      span {
        color: ${colors.GRAY_CC};
        text-decoration: line-through;
      }
    }
  }

  .removedCar {
    color: ${colors.GRAY_CC};

    strong {
      text-decoration: line-through;
    }

    div {
      color: ${colors.GRAY_CC};
    }
  }
`;
export const DetailsLabel = styled.div `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
`;
export const DetailsValue = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_3F};
  margin-top: 5px;
`;
export const AddRemoveActions = styled.section `
  padding: 13px 18px;
  background: ${colors.GRAY_FA};
`;
export const CancelationReason = styled.div `
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_3F};
`;
