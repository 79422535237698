import { CUSTOM_REPAIR_ID } from 'src/AdminApp/modules/api/constants';
import { Divider, Grid, List } from 'src/components/mui';
import { AddCircle } from 'src/components/mui/icons';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import AddServices from 'src/AdminApp/containers/requests/CantFinish/CantFinishRequiresAddtlRepair/AddServices';
import { setRepairs } from 'src/AdminApp/modules/quickRo/actions';
import { selectRepairs } from 'src/AdminApp/modules/quickRo/selectors';
import { DeleteButton } from 'src/components/buttons';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import { SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { IntroContentTodayReturn, IntroTodayReturnCopy, IntroTodayReturnHeader, ListEditReturn, RepairItemStyled, } from '../CantFinishDrawer.styles';
const CantFinishEditReturnDrawer = ({ sendToTodayReturnVisit, addSelectedRepair, returnVisit, knowRepairsAndRemovedRepairsAndCustomRepair, }) => {
    const dispatch = useDispatch();
    const [servicesRepairList, setServicesRepairList] = useState(returnVisit);
    const backupRepairs = [...returnVisit];
    const repairsFromSelector = useSelector(selectRepairs);
    useEffect(() => {
        setServicesRepairList((prevList) => {
            return removeDuplicatedRepairsByLabelFromList([
                ...prevList,
                ...repairsFromSelector,
            ]);
        });
    }, [repairsFromSelector, setServicesRepairList]);
    const isIRepair = (obj) => {
        return Object.prototype.hasOwnProperty.call(obj, 'nodeId');
    };
    const removeOnQuickAddListInCaseContainsInServicesList = () => {
        const arrayServicesRepairId = servicesRepairList.map((servicesRepair) => servicesRepair.repairId);
        const list = [];
        knowRepairsAndRemovedRepairsAndCustomRepair.forEach((knownRemovedRepair) => {
            if (!Object.prototype.hasOwnProperty.call(knownRemovedRepair, 'nodeId')) {
                list.push({
                    repairId: CUSTOM_REPAIR_ID,
                    customRepairText: knownRemovedRepair.message,
                    repair: knownRemovedRepair.message,
                    label: knownRemovedRepair.message,
                });
            }
            else if (isIRepair(knownRemovedRepair) &&
                !arrayServicesRepairId.includes(knownRemovedRepair.nodeId)) {
                list.push({
                    repairId: knownRemovedRepair.nodeId,
                    repair: knownRemovedRepair.name,
                    label: knownRemovedRepair.name,
                });
            }
        });
        return list;
    };
    const [quickAddRepairList, setQuickAddRepairList] = useState(removeOnQuickAddListInCaseContainsInServicesList);
    const removeDuplicatedRepairsByLabelFromList = (list) => {
        return [...new Map(list.map((item) => [item.label, item])).values()];
    };
    const handleRemoveReturnVisitRepairsList = (repairName) => {
        const newList = servicesRepairList.filter((repair) => { var _a; return !((_a = repair.label) === null || _a === void 0 ? void 0 : _a.includes(repairName)); });
        setServicesRepairList(newList);
    };
    const handleRemoveQuickAddRepairList = (repairName) => {
        const newList = quickAddRepairList === null || quickAddRepairList === void 0 ? void 0 : quickAddRepairList.filter((repair) => { var _a; return !((_a = repair.label) === null || _a === void 0 ? void 0 : _a.includes(repairName)); });
        setQuickAddRepairList(newList);
    };
    return (<>
      <SimpleDrawerContent>
        <IntroContentTodayReturn>
          <div>
            <IntroTodayReturnHeader>Edit Services</IntroTodayReturnHeader>
            <IntroTodayReturnCopy>
              <ReactMarkdown>
                Add more repairs to the return visit by searching or using the
                quick add feature
              </ReactMarkdown>
            </IntroTodayReturnCopy>
            <AddServices showItems={false}/>
          </div>
          <div style={{
            paddingTop: 40,
            visibility: quickAddRepairList.length > 0 ? 'visible' : 'hidden',
        }}>
            <IntroTodayReturnHeader>Quick Add</IntroTodayReturnHeader>
            <List style={{ width: '100%' }}>
              <Divider />
              {map(quickAddRepairList, (repair) => (<>
                  <RepairItemStyled container style={{
                fontSize: '14px',
                paddingTop: '1px',
                paddingBottom: '1px',
                alignItems: 'center',
            }}>
                    <Grid item xs>
                      {repair === null || repair === void 0 ? void 0 : repair.label}
                    </Grid>
                    <Grid item>
                      <SimpleRowButton onClick={() => {
                handleRemoveQuickAddRepairList(repair.label);
                setServicesRepairList(removeDuplicatedRepairsByLabelFromList([
                    ...servicesRepairList,
                    repair,
                ]));
            }} color="primary">
                        <AddCircle />
                      </SimpleRowButton>
                    </Grid>
                  </RepairItemStyled>
                  <Divider />
                </>))}
            </List>
          </div>
          <div style={{
            paddingTop: 40,
            visibility: servicesRepairList.length > 0 ? 'visible' : 'hidden',
        }}>
            <IntroTodayReturnHeader>Services</IntroTodayReturnHeader>
            <IntroTodayReturnCopy>
              <ReactMarkdown>
                These are the services we'll perform on the next visit
              </ReactMarkdown>
            </IntroTodayReturnCopy>
            <ListEditReturn>
              {map(servicesRepairList, (repair, index) => (<>
                  {index !== 0 && <Divider />}

                  <RepairItemStyled container style={{
                fontSize: '14px',
                padding: '5px',
                alignItems: 'center',
            }}>
                    <Grid item xs>
                      {repair === null || repair === void 0 ? void 0 : repair.label}
                    </Grid>
                    <Grid item>
                      <DeleteButton onClick={() => {
                knowRepairsAndRemovedRepairsAndCustomRepair.forEach((knownRemovedRepair) => {
                    if (!Object.prototype.hasOwnProperty.call(knownRemovedRepair, 'nodeId')) {
                        if (knownRemovedRepair.message === repair.label) {
                            setQuickAddRepairList([
                                ...quickAddRepairList,
                                repair,
                            ]);
                        }
                    }
                    else if (knownRemovedRepair.name === repair.label) {
                        setQuickAddRepairList([
                            ...quickAddRepairList,
                            repair,
                        ]);
                    }
                });
                handleRemoveReturnVisitRepairsList(repair.label);
            }}/>
                    </Grid>
                  </RepairItemStyled>
                </>))}
            </ListEditReturn>
          </div>
        </IntroContentTodayReturn>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <SimpleTwoButtonRow submitText="Submit" onCancel={() => {
            sendToTodayReturnVisit();
            addSelectedRepair(backupRepairs);
            dispatch(setRepairs([]));
        }} onSubmit={() => {
            sendToTodayReturnVisit();
            addSelectedRepair(servicesRepairList);
            dispatch(setRepairs([]));
        }} disableTopPadding/>
      </SimpleDrawerFooter>
    </>);
};
export default CantFinishEditReturnDrawer;
