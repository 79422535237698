import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { FormControlLabel, FormGroup, RadioGroup, TextField, } from 'src/components/mui';
export const AvailableTechniciansWrapper = styled.section `
  margin-top: 40px;
`;
export const TechHeader = styled.h3 `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: -0.13px;
  color: ${colors.GRAY_76};
  margin: 0;
`;
export const TechChoices = styled(RadioGroup) ``;
export const TechChoice = styled.div `
  border-bottom: 1px solid ${colors.border};
  padding: 30px 0;
`;
export const TechControlLabel = styled(FormControlLabel) `
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: ${colors.GRAY_3F};
`;
export const NumberOfTechs = styled(TextField) `
  padding-bottom: 24px;
  margin-top: 26px;
`;
export const TechsList = styled(FormGroup) `
  padding-left: 31px;
`;
export const TechnicianLabel = styled(FormControlLabel) `
  padding: 28px 0;
  border-bottom: 1px solid ${colors.border};
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: ${colors.GRAY_3F};

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;
