import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import styled from 'styled-components';
import { Divider, Typography } from 'src/components/mui';
import { selectUser, selectVehicle, selectRepairs, } from 'src/AdminApp/modules/quickRo/selectors';
import { ConsumerTitle, SelectedVehicleRow, } from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/components';
import { ActionCreators } from 'src/AdminApp/modules/quickRo/actions';
import RepairItem from './components/RepairItem';
import SearchService from './components/SearchService';
export const Title = styled(Typography) `
  margin-bottom: 12px;
  margin-top: 32px;
`;
const AddServices = () => {
    const dispatch = useDispatch();
    const consumer = useSelector(selectUser);
    const vehicle = useSelector(selectVehicle);
    const repairs = useSelector(selectRepairs);
    const handleSelect = (repair) => {
        dispatch(ActionCreators.TAB.ADD_REPAIR({ payload: repair }));
    };
    return (<div>
      {consumer ? <ConsumerTitle consumer={consumer}/> : null}
      {vehicle && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.make) ? (<SelectedVehicleRow vehicle={vehicle}/>) : null}
      <Divider />
      <Title variant="subtitle1">Services</Title>
      <div>
        {map(repairs, (repair) => {
            var _a;
            return (<RepairItem key={repair.repairId} repair={Object.assign(Object.assign({}, repair), { label: (_a = repair.name) !== null && _a !== void 0 ? _a : repair.label })}/>);
        })}
      </div>
      <SearchService onSelect={handleSelect}/>
    </div>);
};
export default AddServices;
