import { Chip } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const StyledChip = styled(Chip) `
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $textColor }) => $textColor !== null && $textColor !== void 0 ? $textColor : colors.white};
  font-size: ${({ size }) => (size === 'small' ? '12px' : '13px')};
  line-height: ${({ size }) => (size === 'small' ? '12px' : '13px')};
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ $bgColor }) => $bgColor};
  }

  .MuiChip-icon,
  [class*='MuiChip-icon'] {
    color: ${({ $iconColor }) => $iconColor};
  }

  .MuiChip-deleteIcon,
  [class*='MuiChip-deleteIcon'] {
    color: ${({ $deleteIconColor }) => $deleteIconColor};
  }
`;
export const SimpleChips = styled.span `
  margin-left: 5px;
  > :not(:last-child) {
    margin-right: 5px;
  }
`;
export const ChipLogo = styled.img `
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  padding: 2px;
  background-color: ${colors.black};
`;
