import React, { useState } from 'react';
import { TextField } from 'src/components/mui';
import { ActionButton } from 'src/components/buttons';
import { SimpleModal } from 'src/components/layout';
const SkipCustom = ({ open, onClose, onOpen, onSubmit }) => {
    const [customTime, setCustomTime] = useState(0);
    const onChange = (e) => {
        e.preventDefault();
        const { valueAsNumber } = e.target;
        const fixedValue = parseFloat(valueAsNumber.toFixed(2));
        setCustomTime(fixedValue);
    };
    const handleClose = () => {
        setCustomTime(0);
        onClose();
    };
    return (<>
      <ActionButton className="skipOrderBtn" color="primary" variant="contained" onClick={onOpen}>
        Skip Custom
      </ActionButton>
      <SimpleModal open={open} onClose={handleClose} title="Select hours" simpleTwoButtonRowProps={{
            onCancel: handleClose,
            onSubmit: () => onSubmit(customTime),
            submitText: 'Skip',
            disabledSubmit: !customTime || isNaN(customTime),
            disabledCancel: !customTime || isNaN(customTime),
        }}>
        <TextField fullWidth margin="normal" type="number" autoFocus label="Hours" name="customTime" value={customTime} onChange={onChange} inputProps={{
            min: 0,
        }}/>
      </SimpleModal>
    </>);
};
export default SkipCustom;
