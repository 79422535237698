import React from 'react';
import { colors } from 'src/styles/theme';
import { Tooltip } from 'src/components/tooltips';
import { LocationOn as LocationOnIcon } from 'src/components/mui/icons';
const ActiveStopTooltip = () => (<Tooltip placement="bottom" title="The technician is currently at this location">
    <LocationOnIcon htmlColor={colors.primary} fontSize="small" style={{
        marginLeft: '4px',
    }}/>
  </Tooltip>);
export default ActiveStopTooltip;
