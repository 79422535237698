import { Button } from 'src/components/mui';
import moment from 'moment';
import React from 'react';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import useInvoiceRequestDetailsDrawer from 'src/AdminApp/pages/invoiceRequests/InvoiceRequestDetailsDrawer/useInvoiceRequestDetailsDrawer';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import SimpleModal from 'src/components/SimpleModal';
import { colors } from 'src/styles/theme';
import { DATE_TIME_12H_FORMAT } from 'src/timeConstants';
import styled from 'styled-components';
export const InfoLabel = styled.div `
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${colors.darkGrey};
  margin-bottom: 13px;
`;
export const InfoValue = styled.div `
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${colors.GRAY_3F};
  margin-bottom: 24px;
`;
const InvoiceRequestDetails = styled.div `
  &:not(:first-child) {
    border-top: 1px solid ${colors.GRAY_E6};
    padding-top: 24px;
  }
`;
const InvoiceRequestDetailsDrawer = ({ open, loading, onClose, invoiceRequests = [], handleAcknowledge, invoice, }) => {
    var _a, _b, _c;
    const { hasMultipleRequests, firstRequest, submitAcknowledge, showErrorStack, hideErrorStack, fullError, } = useInvoiceRequestDetailsDrawer({
        invoiceRequests,
        handleAcknowledge,
        onClose,
    });
    const displayField = (condition, label, value) => condition && (<>
        <InfoLabel>{label}</InfoLabel>
        <InfoValue>{value}</InfoValue>
      </>);
    return invoiceRequests.length === 0 && !loading ? null : (<SimpleDrawer open={open} onClose={() => {
            onClose();
        }} simpleMode title={!hasMultipleRequests
            ? 'Invoice Request Details'
            : 'Invoice Send History'} footerActionButtonProps={{
            onClick: submitAcknowledge,
        }} submitText={!hasMultipleRequests &&
            (firstRequest === null || firstRequest === void 0 ? void 0 : firstRequest.status) === 'COMPLETED_WITH_ERRORS' &&
            !(firstRequest === null || firstRequest === void 0 ? void 0 : firstRequest.acknowledgedAt) &&
            handleAcknowledge
            ? 'Acknowledge'
            : 'Close'} isLoading={loading}>
      {invoice && (<>
          <InfoLabel>Invoice ID</InfoLabel>
          <InfoValue>{invoice.id}</InfoValue>
          <InfoLabel>Responsible Party</InfoLabel>
          <InfoValue>
            {`${(_a = invoice.workOrderResponsibleParty.responsibleParty) === null || _a === void 0 ? void 0 : _a.name} (${(_c = (_b = invoice.workOrderResponsibleParty.responsibleParty) === null || _b === void 0 ? void 0 : _b.responsiblePartyType) === null || _c === void 0 ? void 0 : _c.name})`}
          </InfoValue>
          <InfoLabel>Created</InfoLabel>
          <InfoValue>
            {moment.utc(invoice.created).local().format(DATE_TIME_12H_FORMAT)}
          </InfoValue>
        </>)}
      {invoiceRequests.map((invoiceRequest) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return (<InvoiceRequestDetails key={invoiceRequest.id}>
            {displayField(!!(invoiceRequest === null || invoiceRequest === void 0 ? void 0 : invoiceRequest.id), 'ID', invoiceRequest.id)}
            {displayField(!!invoiceRequest.responsibleParty, 'Responsible Party', `${(_a = invoiceRequest.responsibleParty) === null || _a === void 0 ? void 0 : _a.name} (${(_d = (_c = (_b = invoiceRequest.responsibleParty) === null || _b === void 0 ? void 0 : _b.responsiblePartyType) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : (_e = invoiceRequest.responsibleParty) === null || _e === void 0 ? void 0 : _e.responsiblePartyTypeName})`)}
            {invoiceRequest.type &&
                    displayField(!hasMultipleRequests, 'Type', invoiceRequest.type.replaceAll('_', ' '))}

            {displayField(!hasMultipleRequests, 'Status', invoiceRequest.acknowledgedAt
                    ? 'COMPLETED WITH ERRORS, BUT ACKNOWLEDGED'
                    : invoiceRequest.status.replaceAll('_', ' '))}
  
            {displayField(!!invoiceRequest.created, 'Created', moment
                    .utc(invoiceRequest.created)
                    .local()
                    .format(DATE_TIME_12H_FORMAT))}
            {displayField(!!invoiceRequest.startedAt, 'Started', moment
                    .utc(invoiceRequest.startedAt)
                    .local()
                    .format(DATE_TIME_12H_FORMAT))}
            {displayField(!!invoiceRequest.mailSentDate, 'Sent Date', moment
                    .utc(invoiceRequest.mailSentDate)
                    .local()
                    .format(DATE_TIME_12H_FORMAT))}
            {displayField(!!invoiceRequest.mailSentBy, 'Sent By', <AdminUser justName updatedUser={(_f = invoiceRequest.mailSentBy) !== null && _f !== void 0 ? _f : ''}/>)}
            {displayField(!!invoiceRequest.completedAt, 'Completed', moment
                    .utc(invoiceRequest.completedAt)
                    .local()
                    .format(DATE_TIME_12H_FORMAT))}
            <InfoLabel>Recipients</InfoLabel>
            <InfoValue>
              <ul style={{
                    margin: 0,
                    padding: 0,
                    listStyleType: 'none',
                }}>
                {invoiceRequest.recipients.map((recipient) => (<li key={recipient} style={{
                        padding: '3px 0',
                    }}>
                    {recipient}
                  </li>))}
              </ul>
            </InfoValue>
            {((_g = invoiceRequest.items) !== null && _g !== void 0 ? _g : []).length > 0 && (<>
                <InfoLabel>
                  ROs ({invoiceRequest.numTotal} total,{' '}
                  {invoiceRequest.numCompleted} completed,{' '}
                  {invoiceRequest.numErrors} errors)
                </InfoLabel>
                <InfoValue>
                  <ul style={{
                        margin: 0,
                        padding: 0,
                        listStyleType: 'none',
                    }}>
                    {(_h = invoiceRequest.items) === null || _h === void 0 ? void 0 : _h.map((item) => {
                        const errorObj = item.error
                            ? JSON.parse(item.error)
                            : null;
                        return (<li key={item.id} style={{
                                padding: '3px 0',
                                marginBottom: 12,
                            }}>
                          <a href={`/admin/repairs/${item.referenceNum}`} rel="noopener noreferrer" target="_blank">
                            {item.referenceNum}
                          </a>{' '}
                          - {item.status.replaceAll('_', ' ')}
                          {errorObj && (<Button color="error" style={{
                                    marginTop: 8,
                                    textAlign: 'left',
                                    padding: 0,
                                }} onClick={() => {
                                    showErrorStack(errorObj);
                                }}>
                              {errorObj.errorMessage}
                            </Button>)}
                        </li>);
                    })}
                  </ul>
                </InfoValue>
              </>)}
          </InvoiceRequestDetails>);
        })}
      <SimpleModal open={!!fullError} onClose={hideErrorStack} maxWidth="lg" title={fullError === null || fullError === void 0 ? void 0 : fullError.errorMessage}>
        {fullError === null || fullError === void 0 ? void 0 : fullError.stackTrace}
      </SimpleModal>
    </SimpleDrawer>);
};
export default InvoiceRequestDetailsDrawer;
