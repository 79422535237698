import React from 'react';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import moment from 'moment';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
import { Appointments } from './FilterDialog.styles';
const AppointmentDates = ({ dateStart, dateEnd, onDateStartChange, onDateEndChange, }) => (<Appointments>
    <SimpleDatePicker className="searchDatePicker" format={LOCALIZED_DATE_FORMAT} label={LOCALIZED_DATE_FORMAT} value={moment(dateStart)} onChange={onDateStartChange} slotProps={{
        textField: {
            variant: 'outlined',
            margin: 'normal',
            id: 'date-picker-inline-start',
        },
    }}/>
    <div className="apptText">to</div>
    <SimpleDatePicker className="searchDatePicker" format={LOCALIZED_DATE_FORMAT} label={LOCALIZED_DATE_FORMAT} value={moment(dateEnd)} minDate={moment(dateStart !== null && dateStart !== void 0 ? dateStart : Date.now())} onChange={onDateEndChange} slotProps={{
        textField: {
            variant: 'outlined',
            margin: 'normal',
            id: 'date-picker-inline-end',
        },
    }}/>
  </Appointments>);
export default AppointmentDates;
