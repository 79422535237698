import React from 'react';
import * as snippet from '@segment/snippet';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CONFIG from 'src/config';
const Head = () => {
    const renderSnippet = () => {
        const opts = {
            apiKey: CONFIG.REACT_APP_ANALYTICS_WRITE_KEY,
            page: false, // Set this to `false` if you want to manually fire `analytics.page()` from within your pages.
        };
        return snippet.min(opts);
    };
    return (<HelmetProvider>
      <Helmet script={[
            {
                type: 'text/javascript',
                innerHTML: `${renderSnippet()}`,
            },
            {
                type: 'text/javascript',
                src: 'https://core.spreedly.com/iframe/iframe-v1.min.js',
            },
        ]}/>
    </HelmetProvider>);
};
export default Head;
