import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Divider, FormGroup } from 'src/components/mui';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox';
import { IntroContentTodayReturn, IntroTodayReturnCopy, ReactMarkdownStyled, SubtitleText, } from './CustomerProvidedParts.styles';
import useCustomerProvidedParts from './useCustomerProvidedParts';
const CustomerProvidedParts = ({ setIsCustomerProvidedPartsValid, selectedCustomerParts, isCheckedAllCustomerParts, setSelectedCustomerParts, setIsCheckedAllCustomerParts, isPartSelected, additionalRepairComponents, setAdditionalRepairComponents, invoice, }) => {
    const { handleCheckboxChange, handleSelectAllChange } = useCustomerProvidedParts({
        setIsCustomerProvidedPartsValid,
        selectedCustomerParts,
        isCheckedAllCustomerParts,
        setSelectedCustomerParts,
        setIsCheckedAllCustomerParts,
        additionalRepairComponents,
        setAdditionalRepairComponents,
        invoice,
    });
    return (<>
      <IntroContentTodayReturn>
        <SubtitleText>Parts List</SubtitleText>
        <IntroTodayReturnCopy>
          <ReactMarkdown>
            Select the parts that will be replaced by customer provided parts.
          </ReactMarkdown>
        </IntroTodayReturnCopy>
      </IntroContentTodayReturn>
      <FormGroup style={{ width: '100%' }}>
        <LabelledCheckbox style={{
            paddingTop: '5px',
            paddingBottom: '5px',
            color: 'gray',
            marginBottom: '5px',
        }} checked={isCheckedAllCustomerParts} onChange={(event) => handleSelectAllChange(event, additionalRepairComponents)} label="Select All"/>
        <Divider />
        {additionalRepairComponents.map((repairComponent) => {
            var _a, _b;
            return (<div key={repairComponent.suggestedRepairId} style={{ marginBottom: '15px' }}>
            <SubtitleText>
              {repairComponent.additionalRepairComponent.name}
            </SubtitleText>

            {/* Wrap each checkbox in a div to ensure it appears on its own line */}
            {((_a = repairComponent.additionalRepairComponent.parts) !== null && _a !== void 0 ? _a : []).map((part) => (<div key={part.id} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                  <LabelledCheckbox checked={isPartSelected(repairComponent.suggestedRepairId, 'part', part.id)} onChange={(event) => handleCheckboxChange(event, repairComponent, part.id, 'part')} value={part.id} label={`${part.name}${part.partNumber ? ` • Part#: ${part.partNumber}` : ''}`}/>
                </div>))}

            {((_b = repairComponent.additionalRepairComponent.fluids) !== null && _b !== void 0 ? _b : []).map((fluid) => (<div key={fluid.id} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                  <LabelledCheckbox checked={isPartSelected(repairComponent.suggestedRepairId, 'fluid', fluid.id)} onChange={(event) => handleCheckboxChange(event, repairComponent, fluid.id, 'fluid')} value={fluid.id} label={`${fluid.name}${fluid.partNumber ? ` • Part#: ${fluid.partNumber}` : ''}`}/>
                </div>))}
          </div>);
        })}
        <Divider />
      </FormGroup>

      <ReactMarkdownStyled>
        Setting a part to "Customer Provided" will remove it from the parts
        ordering queue and set the price to $0.00.
      </ReactMarkdownStyled>
    </>);
};
export default CustomerProvidedParts;
