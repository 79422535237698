import React from 'react';
import styled from 'styled-components';
import { times } from 'lodash';
import { StarRounded as StarRoundedIcon } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
const Stars = ({ numberOfStars, rating, onChange, hovered = 0, onHover = () => { }, onHoverOut = () => { }, }) => {
    const Star = styled(StarRoundedIcon) `
    color: ${({ $filled }) => $filled ? colors.darkGold : colors.grey};
    margin-left: 10px;
    font-size: 2.5rem;

    @media (max-width: 960px) {
      font-size: 1.5rem;
    }
  `;
    return (<div>
      {times(numberOfStars, (i) => (<span key={i}>
          <Star $filled={hovered !== 0 ? i < hovered : i < rating} onClick={() => onChange(i + 1)} onMouseEnter={() => onHover(i + 1)} onMouseLeave={() => onHoverOut(i + 1)}/>
        </span>))}
    </div>);
};
export default Stars;
