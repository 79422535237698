import { createSelector } from 'reselect';
import { filter, isEmpty, reduce, sortBy } from 'lodash';
export const selectWorkOrders = (state) => state.admin.workOrders;
export const selectWorkOrderById = (workOrderId) => createSelector(selectWorkOrders, (workOrders) => { var _a; return (_a = workOrders.byId[workOrderId]) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardDate = () => createSelector(selectWorkOrders, (workOrders) => { var _a; return (_a = workOrders === null || workOrders === void 0 ? void 0 : workOrders.dashboard) === null || _a === void 0 ? void 0 : _a.selectedDate; });
export const selectDashboardFilters = () => createSelector(selectWorkOrders, (workOrders) => { var _a, _b; return (_b = (_a = workOrders === null || workOrders === void 0 ? void 0 : workOrders.dashboard) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.filters; });
export const selectDashboardCount = () => createSelector(selectWorkOrders, (workOrders) => { var _a; return (_a = workOrders === null || workOrders === void 0 ? void 0 : workOrders.dashboard) === null || _a === void 0 ? void 0 : _a.totalElements; });
export const selectDashboardAssignedAdvisors = (date) => createSelector(selectWorkOrders, (workOrders) => { var _a; return (_a = workOrders === null || workOrders === void 0 ? void 0 : workOrders.dashboard) === null || _a === void 0 ? void 0 : _a.advisors[date]; });
export const selectSearchWorkOrders = () => createSelector(selectWorkOrders, (workOrders) => { var _a; return ((_a = workOrders === null || workOrders === void 0 ? void 0 : workOrders.dashboard) === null || _a === void 0 ? void 0 : _a.content) || []; });
export const selectNewWorkOrders = () => createSelector(selectWorkOrders, (workOrders) => { var _a; return ((_a = workOrders === null || workOrders === void 0 ? void 0 : workOrders.dashboard) === null || _a === void 0 ? void 0 : _a.needsAttention) || []; });
export const selectDashboardNumbers = () => createSelector(selectWorkOrders, (workOrders) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return {
        completedCount: ((_b = (_a = workOrders.dashboard) === null || _a === void 0 ? void 0 : _a.pagination) === null || _b === void 0 ? void 0 : _b.completedCount) || 0,
        totalElements: ((_e = (_d = (_c = workOrders.dashboard) === null || _c === void 0 ? void 0 : _c.pagination) === null || _d === void 0 ? void 0 : _d.totalElements) !== null && _e !== void 0 ? _e : 0) +
            ((_h = (_g = (_f = workOrders.dashboard) === null || _f === void 0 ? void 0 : _f.pagination) === null || _g === void 0 ? void 0 : _g.completedCount) !== null && _h !== void 0 ? _h : 0),
        remaining: ((_k = (_j = workOrders.dashboard) === null || _j === void 0 ? void 0 : _j.pagination) === null || _k === void 0 ? void 0 : _k.totalElements) || 0,
    };
});
export const selectWorkOrdersByRequestRefNum = (referenceNum) => createSelector(selectWorkOrders, (workOrders) => { var _a; return (_a = workOrders.byRequestRefNum[referenceNum]) !== null && _a !== void 0 ? _a : {}; });
export const selectFirstWorkOrderByRequestRefNum = (referenceNum) => createSelector(selectWorkOrdersByRequestRefNum(referenceNum), (workOrders) => workOrders[Object.keys(workOrders)[0]]);
export const selectFirstWorkOrderIdByRequestRefNum = (referenceNum) => createSelector(selectWorkOrdersByRequestRefNum(referenceNum), (workOrders) => Object.keys(workOrders)[0]);
export const selectWorkOrderSubletsByRefNum = (referenceNum) => createSelector(selectWorkOrdersByRequestRefNum(referenceNum), (workOrders) => reduce(Object.keys(workOrders), (subs, k) => workOrders[k].sublets.concat(subs), []));
export const selectWorkOrderSubletRepairIdsByRefNum = (referenceNum) => createSelector(selectWorkOrderSubletsByRefNum(referenceNum), (sublets) => reduce(sublets, (ids, s) => { var _a; return ((_a = s.suggestedRepairIds) !== null && _a !== void 0 ? _a : []).concat(ids); }, []));
export const selectQuoteDiscounts = (workOrderId) => createSelector(selectWorkOrderById(workOrderId), (workOrder) => {
    var _a;
    const discountDetails = (_a = workOrder.discountDetails) !== null && _a !== void 0 ? _a : [];
    const quoteDiscountDetails = filter(discountDetails, [
        'workOrderStage',
        'QUOTE',
    ]);
    if (quoteDiscountDetails && quoteDiscountDetails.length >= 1) {
        return quoteDiscountDetails;
    }
    return discountDetails;
});
export const selectDocumentsByWorkOrderId = (workOrderId) => createSelector(selectWorkOrderById(workOrderId), (workOrder) => workOrder.documents);
export const selectQuotePromos = (workOrderId) => createSelector(selectQuoteDiscounts(workOrderId), (discounts) => filter(discounts, ['discountType', 'PROMO_CODE']));
export const selectQuoteSubscriptions = (workOrderId) => createSelector(selectQuoteDiscounts(workOrderId), (discounts) => discounts.filter((x) => x.discountType === 'SUBSCRIPTION' && x.discountedAmount));
export const selectQuoteCredits = (workOrderId) => createSelector(selectQuoteDiscounts(workOrderId), (discounts) => filter(discounts, ['discountType', 'CANCEL_FEE_CREDIT']));
export const selectQuoteOtherDiscounts = (workOrderId) => createSelector(selectQuoteDiscounts(workOrderId), (discounts) => filter(discounts, ['discountType', 'CUSTOM_DISCOUNT']));
export const selectHasPromosByWorkOrderId = (workOrderId) => createSelector(selectQuotePromos(workOrderId), (discounts) => !isEmpty(discounts));
export const selectInvoiceDiscounts = (workOrderId) => createSelector(selectWorkOrderById(workOrderId), (workOrder) => {
    var _a;
    const discountDetails = (_a = workOrder.discountDetails) !== null && _a !== void 0 ? _a : [];
    return filter(discountDetails, ['workOrderStage', 'INVOICE']);
});
export const selectInvoicePromos = (workOrderId) => createSelector(selectInvoiceDiscounts(workOrderId), (discounts) => filter(discounts, ['discountType', 'PROMO_CODE']));
export const selectInvoiceSubscriptions = (workOrderId) => createSelector(selectInvoiceDiscounts(workOrderId), (discounts) => discounts.filter((x) => x.discountType === 'SUBSCRIPTION' && x.discountedAmount));
export const selectInvoiceCredits = (workOrderId) => createSelector(selectInvoiceDiscounts(workOrderId), (discounts) => filter(discounts, ['discountType', 'CANCEL_FEE_CREDIT']));
export const selectInvoiceOtherDiscounts = (workOrderId) => createSelector(selectInvoiceDiscounts(workOrderId), (discounts) => filter(discounts, ['discountType', 'CUSTOM_DISCOUNT']));
export const selectAllWorkOrderResponsiblePartiesByRequestRefNum = (referenceNum) => createSelector(selectFirstWorkOrderByRequestRefNum(referenceNum), (workOrder) => (workOrder === null || workOrder === void 0 ? void 0 : workOrder.workOrderResponsibleParties) || []);
export const selectQuoteWorkOrderResponsiblePartiesByRequestRefNum = (referenceNum) => createSelector(selectAllWorkOrderResponsiblePartiesByRequestRefNum(referenceNum), (workOrderResponsibleParty) => filter(workOrderResponsibleParty, ['stage', 'QUOTE']));
export const selectInvoiceWorkOrderResponsiblePartiesByRequestRefNum = (referenceNum) => createSelector(selectAllWorkOrderResponsiblePartiesByRequestRefNum(referenceNum), (workOrderResponsibleParty) => filter(workOrderResponsibleParty, ['stage', 'INVOICE']));
export const selectWorkOrderResponsiblePartyTypeTotals = (workOrderId) => createSelector(selectWorkOrderById(workOrderId), (workOrder) => {
    const workOrderResponsiblePartyTypeTotals = (workOrder === null || workOrder === void 0 ? void 0 : workOrder.workOrderResponsiblePartyTypeTotals) || [];
    workOrderResponsiblePartyTypeTotals.forEach((worpt) => {
        worpt.isPrimary = worpt.name === 'Customer Pay';
    });
    return sortBy(workOrderResponsiblePartyTypeTotals, [(x) => !x.isPrimary]);
});
