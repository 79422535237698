var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import { FormControlLabel, Radio } from 'src/components/mui';
import { colors } from 'src/styles/theme';
const FormControlLabelBorder = styled.div `
  border-bottom: 1px solid ${colors.lightGrey};
  padding-top: 4px;
  padding-bottom: 4px;
`;
const FormControlLabelStyled = styled(FormControlLabel) `
  display: block;
`;
const HelperWrapper = styled.div `
  padding-bottom: 18px;
  padding-top: 5px;
`;
// Restyled components
export const RadioButton = (_a) => {
    var { field, label, id, helperField, onChangeValue } = _a, props = __rest(_a, ["field", "label", "id", "helperField", "onChangeValue"]);
    const { name, value } = field, rest = __rest(field, ["name", "value"]); // eslint-disable-line @typescript-eslint/no-unused-vars
    return (<FormControlLabelBorder>
      <FormControlLabelStyled name={name} value={id} control={<Radio color="primary"/>} label={label} {...rest} onChange={(...args) => {
            field.onChange(...args);
            onChangeValue(...args);
        }} {...props}/>
      {helperField ? <HelperWrapper>{helperField}</HelperWrapper> : null}
    </FormControlLabelBorder>);
};
export const RadioButtonField = ({ name, id, label = null, helperField, onChange, }) => (<Field component={RadioButton} name={name} id={id} label={label || id} helperField={helperField} onChangeValue={onChange}/>);
