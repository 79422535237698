var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import { SlidePageContent, SlidePageFooter, SlidePageFormWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { getRegionsAutocomplete } from 'src/AdminApp/modules/regions/actions';
import { selectRegionsAutocomplete } from 'src/AdminApp/modules/regions/selectors';
import RegionSearch from 'src/components/form/RegionSearch';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const ArchiveRegionPage = ({ model, onArchive }) => {
    const validationSchema = Yup.object().shape({
        transferRegion: Yup.object().required(),
    });
    return model ? (<Formik initialValues={{
            id: model.id,
            transferRegion: null,
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if (onArchive && values.transferRegion) {
                yield onArchive({
                    id: values.id,
                    transferRegionId: (_b = (_a = values.transferRegion) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '',
                });
                setSubmitting(false);
            }
        })}>
      {({ values, isSubmitting, isValid, setFieldValue }) => {
            var _a;
            return (<SlidePageFormWrapper>
          <SlidePageContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>Move the following service area(s) to a new region</h2>
              </Grid>
              <Grid item xs={12}>
                <h4>
                  {(_a = model.serviceAreas) === null || _a === void 0 ? void 0 : _a.filter((x) => !x.archiveDate).map((x) => {
                    return <p>{x.name}</p>;
                })}
                </h4>
              </Grid>
              <Grid item xs={12}>
                <RegionSearch value={values.transferRegion} name="region" label="Region" onUpdate={(region) => {
                    if (region) {
                        setFieldValue('transferRegion', region);
                    }
                }} getRegions={getRegionsAutocomplete} regionsSelector={selectRegionsAutocomplete}/>
              </Grid>
            </Grid>
          </SlidePageContent>
          <SlidePageFooter>
            <FooterActionButton type="submit" disabled={isSubmitting || !isValid}>
              Archive Region
            </FooterActionButton>
          </SlidePageFooter>
        </SlidePageFormWrapper>);
        }}
    </Formik>) : null;
};
export default ArchiveRegionPage;
