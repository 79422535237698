import React from 'react';
import { ToggleButtonGroup } from 'src/components/mui';
import useIsMobile from 'src/hooks/useIsMobile';
import { StyledToggleButton } from './ShiftToggle.styles';
const ShiftToggle = ({ selectedShiftName, shifts, setShift, }) => {
    const isMobile = useIsMobile();
    return (<div style={{
            width: isMobile ? '100%' : 'inherit',
        }}>
      <ToggleButtonGroup size="small" orientation="horizontal" value={selectedShiftName} exclusive onChange={(_e, newShiftName) => setShift(newShiftName)} aria-label="Shift" style={{
            alignSelf: 'start',
            marginTop: isMobile ? 40 : 0,
            width: 'inherit',
        }}>
        {shifts.map((s) => (<StyledToggleButton key={s.id} value={s.name} disabled={selectedShiftName === s.name}>
            {s.name}
          </StyledToggleButton>))}
      </ToggleButtonGroup>
    </div>);
};
export default ShiftToggle;
