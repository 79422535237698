import styled from 'styled-components';
import { ToggleButton } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const StyledToggleButton = styled(ToggleButton) `
  color: ${colors.primary};
  border: 1px solid;
  padding: 3px 10px 3px 10px;
  width: 48%;

  &.Mui-selected {
    color: ${colors.white};
    border-color: ${colors.primary};
    background-color: ${colors.primary};
    &:hover {
      background-color: ${colors.primary};
    }
  }
`;
