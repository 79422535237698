var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedTemplate } from 'src/AdminApp/modules/deliveryVanTemplates/selectors';
import moment from 'moment';
import { toast } from 'src/components/SimpleToast';
import { debounce } from 'lodash';
import { getMomentValueOr8am } from 'src/utils/formatter';
import { TemplateWeekday } from 'src/AdminApp/models/enums';
import { clearTechniciansForAutocomplete, getTechniciansForAutocomplete, } from 'src/AdminApp/modules/users/actions';
import { addVanTemplate, updateVanTemplate, } from 'src/AdminApp/modules/deliveryVanTemplates/actions';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
const INITIAL_TIME_ENTRY = {
    checked: false,
    startTime: '08:00:00',
    endTime: '18:00:00',
};
const WEEKDAYS = Object.keys(TemplateWeekday);
const useEditAvailabilityTemplate = ({ vanModel, onCancel }) => {
    var _a;
    const dispatch = useDispatch();
    const templateModel = useSelector(selectSelectedTemplate);
    const [loading, setLoading] = useState(false);
    const [manualEntryMechanic, setManualEntryMechanic] = useState((_a = templateModel === null || templateModel === void 0 ? void 0 : templateModel.technicianName) !== null && _a !== void 0 ? _a : '');
    const getInitialValues = () => {
        var _a;
        const initialValues = {
            name: templateModel ? templateModel.name : null,
            start: templateModel
                ? templateModel.start
                : moment().format(DATE_YMD_FORMAT),
            finish: templateModel ? templateModel.finish : null,
            technicianId: templateModel ? templateModel.technicianId : null,
            MONDAY: INITIAL_TIME_ENTRY,
            TUESDAY: INITIAL_TIME_ENTRY,
            WEDNESDAY: INITIAL_TIME_ENTRY,
            THURSDAY: INITIAL_TIME_ENTRY,
            FRIDAY: INITIAL_TIME_ENTRY,
            SATURDAY: INITIAL_TIME_ENTRY,
            SUNDAY: INITIAL_TIME_ENTRY,
        };
        if (templateModel) {
            (_a = templateModel.entries) === null || _a === void 0 ? void 0 : _a.forEach((entry) => {
                initialValues[entry.weekday] = {
                    checked: true,
                    startTime: entry.startTime,
                    endTime: entry.finishTime,
                    id: entry.id,
                    skeduloTemplateEntryId: entry.skeduloTemplateEntryId,
                };
            });
        }
        return initialValues;
    };
    const getEntries = (values) => {
        const entries = [];
        WEEKDAYS.forEach((weekday) => {
            const entry = values[weekday];
            if (entry.checked) {
                entries.push({
                    active: true,
                    weekday: TemplateWeekday[weekday],
                    startTime: entry.startTime,
                    finishTime: entry.endTime,
                    id: entry.id,
                    skeduloTemplateEntryId: entry.skeduloTemplateEntryId,
                });
            }
            else if (!entry.checked && entry.id) {
                entries.push({
                    active: false,
                    weekday: TemplateWeekday[weekday],
                    startTime: entry.startTime,
                    finishTime: entry.endTime,
                    id: entry.id,
                    skeduloTemplateEntryId: entry.skeduloTemplateEntryId,
                });
            }
        });
        return entries;
    };
    const debouncedTechnicianSearch = debounce((value) => {
        dispatch(getTechniciansForAutocomplete(value));
    }, 500);
    const handleManualMechanicSearchUpdate = useCallback((value) => {
        if (value.length > 0) {
            debouncedTechnicianSearch(value);
        }
        else {
            dispatch(clearTechniciansForAutocomplete());
        }
    }, [debouncedTechnicianSearch, dispatch]);
    const handleFormSubmit = (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
        let valid = true;
        const entries = getEntries(values);
        if (values.finish) {
            if (moment(values.start).isAfter(moment(values.finish))) {
                toast.error('Start date must be before end date', 'Validation Error');
                setSubmitting(false);
                return;
            }
        }
        if (entries.length === 0 || entries.every((item) => !item.active)) {
            toast.error('At least one day must be selected', 'Validation Error');
            setSubmitting(false);
            return;
        }
        entries.forEach((entry) => {
            const startTime = getMomentValueOr8am(entry.startTime, 'HH:mm');
            const endTime = getMomentValueOr8am(entry.finishTime, 'HH:mm');
            const isAfter = startTime.isAfter(endTime);
            if (isAfter) {
                toast.error(`Validation Error: ${entry.weekday}: Start time must be before end time`);
                valid = false;
            }
        });
        if (!valid) {
            setSubmitting(false);
            return;
        }
        setLoading(true);
        if (templateModel) {
            const response = yield dispatch(updateVanTemplate({
                id: templateModel.id,
                vanId: vanModel === null || vanModel === void 0 ? void 0 : vanModel.id,
                skeduloTemplateId: templateModel.skeduloTemplateId,
                entries,
                name: values.name,
                start: values.start,
                finish: values.finish,
                technicianId: values.technicianId,
            }));
            if (response && !response.error) {
                toast.success('Template successfully updated.');
            }
            setLoading(false);
            onCancel();
        }
        else {
            const response = yield dispatch(addVanTemplate({
                vanId: vanModel === null || vanModel === void 0 ? void 0 : vanModel.id,
                entries,
                name: values.name,
                start: values.start,
                finish: values.finish,
                technicianId: values.technicianId,
            }));
            if (response && !response.error) {
                toast.success('Template successfully saved.');
            }
            setLoading(false);
        }
        setSubmitting(false);
    });
    return {
        dispatch,
        loading,
        setLoading,
        manualEntryMechanic,
        setManualEntryMechanic,
        templateModel,
        getInitialValues,
        getEntries,
        debouncedTechnicianSearch,
        handleManualMechanicSearchUpdate,
        handleFormSubmit,
    };
};
export default useEditAvailabilityTemplate;
