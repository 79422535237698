import styled from 'styled-components';
import { theme } from 'src/styles/theme';
const GridContainer = styled.div `
  margin: 0 0 54px;
  .gridContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -8px;

    & > * {
      flex: 1 1 288px;
      margin: 8px;
    }

    ${theme.breakpoints.up('md')} {
      margin: -12px;

      & > * {
        margin: 12px;
      }
    }
  }
`;
export default GridContainer;
