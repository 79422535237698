import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['name,asc', 'modified,desc', 'status,asc'],
        },
    },
    byId: {},
    distributorHoursOfOperation: [],
    distributorLocationHoursOfOperation: [],
};
const fetchDistributorDashboard = (state, action) => {
    var _a;
    const { params } = (_a = action.meta) !== null && _a !== void 0 ? _a : {};
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const searchDistributorDashboardSuccess = (state, action) => {
    var _a;
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize, query } = (_a = action.meta) !== null && _a !== void 0 ? _a : {};
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
                query,
            } }) });
};
const fetchDistributorLocations = (state, action) => {
    var _a, _b, _c;
    const { params, distributorId } = (_a = action.meta) !== null && _a !== void 0 ? _a : {};
    return Object.assign(Object.assign({}, state), { byId: {
            [distributorId]: {
                dashboard: Object.assign(Object.assign({}, (_c = (_b = state.byId) === null || _b === void 0 ? void 0 : _b[distributorId]) === null || _c === void 0 ? void 0 : _c.dashboard), { params, _isFetching: true }),
            },
        } });
};
const searchDistributorLocationsSuccess = (state, action) => {
    var _a, _b, _c;
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize, query, distributorId } = (_a = action.meta) !== null && _a !== void 0 ? _a : {};
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { byId: {
            [distributorId]: {
                dashboard: Object.assign(Object.assign(Object.assign({}, (_c = (_b = state.byId) === null || _b === void 0 ? void 0 : _b[distributorId]) === null || _c === void 0 ? void 0 : _c.dashboard), results), { pagination: {
                        pageSize,
                        totalElements,
                        totalPages,
                        pageNumber: pageIndex,
                        sort,
                        query,
                    } }),
            },
        } });
};
const searchDistributorHoursOfOperation = (state, action) => {
    return Object.assign(Object.assign({}, state), { distributorHoursOfOperation: action.payload });
};
const searchDistributorLocationHoursOfOperation = (state, action) => {
    return Object.assign(Object.assign({}, state), { distributorLocationHoursOfOperation: action.payload });
};
const patchDistributorHoursOfOperationSuccess = (state) => {
    return Object.assign(Object.assign({}, state), { distributorHoursOfOperation: [] });
};
const patchLocationHoursOfOperationSuccess = (state) => {
    return Object.assign(Object.assign({}, state), { distributorLocationHoursOfOperation: [] });
};
const reducers = handleActions({
    [ActionCreators.FETCH_DISTRIBUTORS.FETCH.type]: fetchDistributorDashboard,
    [ActionCreators.FETCH_DISTRIBUTORS.SUCCESS.type]: searchDistributorDashboardSuccess,
    [ActionCreators.FETCH_LOCATIONS.FETCH.type]: fetchDistributorLocations,
    [ActionCreators.FETCH_LOCATIONS.SUCCESS.type]: searchDistributorLocationsSuccess,
    [ActionCreators.FETCH_DISTRIBUTOR_HOURS_OF_OPERATION.SUCCESS.type]: searchDistributorHoursOfOperation,
    [ActionCreators.UPDATE_DISTRIBUTOR_HOURS_OF_OPERATION.SUCCESS.type]: patchDistributorHoursOfOperationSuccess,
    [ActionCreators.FETCH_DISTRIBUTOR_LOCATION_HOURS_OF_OPERATION.SUCCESS.type]: searchDistributorLocationHoursOfOperation,
    [ActionCreators.UPDATE_LOCATION_HOURS_OF_OPERATION.SUCCESS.type]: patchLocationHoursOfOperationSuccess,
}, DEFAULT_STATE);
export default reducers;
