import React from 'react';
import styled from 'styled-components';
import Module from 'src/components/layout/Module';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import VisitItem from './VisitItem';
import useFleetVisits from './useFleetVisits';
const FLEET_VISITS_COLUMNS = [
    { title: 'Reference #', key: 'refNum', sortable: true },
    { title: 'Status', key: 'status', sortable: true },
    { title: 'Scheduled Date/Time', key: 'startLocal', sortable: true },
    { title: 'Created', key: 'created', sortable: true },
    { title: 'Created By', key: 'createdBy', sortable: true },
    { title: 'Expected Revenue', key: 'expectedRevenue', sortable: true },
    { title: "Num of RO's", key: 'totalOrders', sortable: true },
];
const Wrapper = styled.div `
  padding: 20px;
`;
const FleetVisits = () => {
    const { tableSort, visits, pagination, onLoadData } = useFleetVisits();
    return (<Module title="Fleet Visits" actionButton={<div style={{ padding: '28px 0' }}/>}>
      {visits ? (<SimpleTable hideTopPagination data={visits} pagination={pagination} sort={tableSort} onLoadData={onLoadData} columns={FLEET_VISITS_COLUMNS} renderItem={(item, index) => (<VisitItem key={`${item.id}-${index}`} data={item}/>)}/>) : (<Wrapper>No Visits</Wrapper>)}
    </Module>);
};
export default FleetVisits;
