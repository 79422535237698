import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useInvoiceDownload from 'src/AdminApp/containers/requests/Invoice/useInvoiceDownload';
import { selectInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/selectors';
import InvoiceRequestDetailsDrawer from 'src/AdminApp/pages/invoiceRequests/InvoiceRequestDetailsDrawer';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import styled from 'styled-components';
import InvoicePdfItem from './InvoicePdfItem';
const PDF_LIST_COLUMNS = [
    { title: 'Invoice #', key: 'invoiceNumber' },
    { title: 'Responsible Party', key: 'responsibleParty' },
    { title: 'Created Date', key: 'createdDate' },
    { title: 'Sent Date', key: 'sentDate' },
    { title: '# Recipients', key: 'numberOfRecipients', textAlign: 'center' },
    { title: '', key: 'actions' },
];
const NoSentInvoices = styled.section `
  padding: 50px 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
`;
const InvoicePdfList = ({ workOrderId }) => {
    const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
    const [selectedInvoiceRequests, setSelectedInvoiceRequests] = useState();
    const [selectedInvoice, setSelectedInvoice] = useState();
    const invoices = useSelector((state) => selectInvoicesByWorkOrderId(state, { workOrderId })) || {};
    const { onDownloadInvoiceClick, isInvoiceDownloading } = useInvoiceDownload();
    const handleShowDetailsDrawer = (irs, invoice) => {
        setSelectedInvoiceRequests(irs);
        setSelectedInvoice(invoice);
        setShowDetailsDrawer(true);
    };
    const handleHideDetailsDrawer = () => {
        setSelectedInvoiceRequests(undefined);
        setSelectedInvoice(undefined);
        setShowDetailsDrawer(false);
    };
    return (<>
      <SimpleTable data={invoices} columns={PDF_LIST_COLUMNS} renderItem={(invoice, index) => (<InvoicePdfItem invoice={invoice} key={`${index}`} onViewDetailsClick={(irs) => {
                handleShowDetailsDrawer(irs, invoice);
            }} onDownloadInvoiceClick={onDownloadInvoiceClick} isInvoiceDownloading={isInvoiceDownloading}/>)} NullState={<NoSentInvoices>No Invoices</NoSentInvoices>} hidePagination hideTopPagination hideTopDivider/>
      <InvoiceRequestDetailsDrawer open={showDetailsDrawer} onClose={handleHideDetailsDrawer} invoiceRequests={selectedInvoiceRequests} invoice={selectedInvoice}/>
    </>);
};
export default InvoicePdfList;
