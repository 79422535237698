import React from 'react';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import CantFinishConditionsDrawerStart from 'src/AdminApp/containers/requests/CantFinish/CantFinishConditions/CantFinishConditionsDrawerStart';
import CantFinishTryThisDrawer from 'src/AdminApp/containers/requests/CantFinish/CantFinishConditions/CantFinishTryThisDrawer';
import CantFinishTodayReturnVisitDrawer from 'src/AdminApp/containers/requests/CantFinish/CantFinishRequiresAddtlRepair/CantFinishTodayReturnVisitDrawer';
import CantFinishAddServiceDrawer from 'src/AdminApp/containers/requests/CantFinish/CantFinishRequiresAddtlRepair/CantFinishAddServiceDrawer';
import CantFinishEditReturnDrawer from 'src/AdminApp/containers/requests/CantFinish/CantFinishRequiresAddtlRepair/CantFinishEditReturnDrawer';
import CantFinishDrawerContent from './CantFinishDrawerContent';
import { Steps, CANT_FINISH_STEPS } from '../CantFinishMenuController';
import useCantFinishDrawer from './useCantFinishDrawer';
const CantFinishDrawer = ({ open, onClose, referenceNum, requestUntilJobComplete, openScheduleAllRecommendRepairs, openCantFinishScreenForMeasurements, workOrderId, }) => {
    const { currentStep, handleNextStep, sendToIntroAndClose, sendToTodayReturnVisit, sendToAddService, sendToEditReturn, onCallIneligibleOrConditionPoorOrAddtlRepairNoOption, onCallVehicleRequiresAddtlRepairsCalls, addRepairOnRepairsAddedOnAddServices, isLongerThanGracePeriod, repairsAddedOnAddServices, repairsOnEditServices, addRepairOnRepairsEditServices, allRepairs, changeStep, } = useCantFinishDrawer({
        open,
        onClose,
        referenceNum,
        requestUntilJobComplete,
        openScheduleAllRecommendRepairs,
        openCantFinishScreenForMeasurements,
        workOrderId,
    });
    const getContentComponent = () => {
        let currentStepName = 0;
        Object.entries(CANT_FINISH_STEPS).forEach(([key, value]) => {
            if (value === currentStep) {
                currentStepName = parseInt(key, 10);
            }
        });
        switch (currentStepName) {
            case Steps.INTRO:
                return <CantFinishDrawerContent nextStep={changeStep}/>;
            case Steps.INELIGIBLE_FOR_SERVICE_INTRO:
                return (<CantFinishConditionsDrawerStart nextStep={handleNextStep} introCopyText={CANT_FINISH_STEPS[Steps.INELIGIBLE_FOR_SERVICE_INTRO].text} specialBehavior="no" onClose={sendToIntroAndClose} referenceNum={referenceNum} cantFinishOptionChosen={CANT_FINISH_STEPS[Steps.INELIGIBLE_FOR_SERVICE_INTRO]
                        .cantFinishOptionChosen} onCallIneligibleOrConditionPoorOrAddtlRepairNoOption={() => { }}/>);
            case Steps.INELIGIBLE_FOR_SERVICE_FINAL:
                return (<CantFinishTryThisDrawer onClose={sendToIntroAndClose} text={CANT_FINISH_STEPS[Steps.INELIGIBLE_FOR_SERVICE_FINAL].text} onCallIneligibleOrConditionPoorOrAddtlRepairNoOption={onCallIneligibleOrConditionPoorOrAddtlRepairNoOption} referenceNum={referenceNum} cantFinishOptionChosen={CANT_FINISH_STEPS[Steps.INELIGIBLE_FOR_SERVICE_FINAL]
                        .cantFinishOptionChosen}/>);
            case Steps.CONDITION_POOR_INTRO:
                return (<CantFinishConditionsDrawerStart nextStep={handleNextStep} introCopyText={CANT_FINISH_STEPS[Steps.CONDITION_POOR_INTRO].text} specialBehavior="no" onClose={sendToIntroAndClose} referenceNum={referenceNum} cantFinishOptionChosen={CANT_FINISH_STEPS[Steps.INELIGIBLE_FOR_SERVICE_INTRO]
                        .cantFinishOptionChosen} onCallIneligibleOrConditionPoorOrAddtlRepairNoOption={() => { }}/>);
            case Steps.CONDITION_POOR_FINAL:
                return (<CantFinishTryThisDrawer onClose={sendToIntroAndClose} text={CANT_FINISH_STEPS[Steps.CONDITION_POOR_FINAL].text} onCallIneligibleOrConditionPoorOrAddtlRepairNoOption={onCallIneligibleOrConditionPoorOrAddtlRepairNoOption} referenceNum={referenceNum} cantFinishOptionChosen={CANT_FINISH_STEPS[Steps.CONDITION_POOR_FINAL]
                        .cantFinishOptionChosen}/>);
            case Steps.REQUIRES_ADDITIONAL_REPAIRS_INTRO:
                return (<CantFinishConditionsDrawerStart nextStep={handleNextStep} introCopyText={CANT_FINISH_STEPS[Steps.REQUIRES_ADDITIONAL_REPAIRS_INTRO].text} specialBehavior={CANT_FINISH_STEPS[Steps.REQUIRES_ADDITIONAL_REPAIRS_INTRO]
                        .specialBehavior} onClose={sendToIntroAndClose} onCallIneligibleOrConditionPoorOrAddtlRepairNoOption={onCallIneligibleOrConditionPoorOrAddtlRepairNoOption} referenceNum={referenceNum} cantFinishOptionChosen={CANT_FINISH_STEPS[Steps.REQUIRES_ADDITIONAL_REPAIRS_INTRO]
                        .cantFinishOptionChosen}/>);
            case Steps.REQUIRES_ADDITIONAL_REPAIRS_HEY_TECH_START:
                return (<CantFinishConditionsDrawerStart nextStep={handleNextStep} introCopyText={isLongerThanGracePeriod
                        ? CANT_FINISH_STEPS[Steps.REQUIRES_ADDITIONAL_REPAIRS_HEY_TECH_START].textLongerChargeGrace
                        : CANT_FINISH_STEPS[Steps.REQUIRES_ADDITIONAL_REPAIRS_HEY_TECH_START].textNotLongerChargeGrace} specialBehavior="no" onClose={sendToIntroAndClose} referenceNum={referenceNum} cantFinishOptionChosen={CANT_FINISH_STEPS[Steps.REQUIRES_ADDITIONAL_REPAIRS_INTRO]
                        .cantFinishOptionChosen} onCallIneligibleOrConditionPoorOrAddtlRepairNoOption={() => { }}/>);
            case Steps.REQUIRES_ADDITIONAL_REPAIRS_TODAYS_RETURN_VISIT:
                return (<CantFinishTodayReturnVisitDrawer onClose={sendToIntroAndClose} sendToAddService={sendToAddService} sendToEditReturn={sendToEditReturn} addRepairOnRepairsAddedOnAddServices={addRepairOnRepairsAddedOnAddServices} onCallVehicleRequiresAddtlRepairsCalls={onCallVehicleRequiresAddtlRepairsCalls} referenceNum={referenceNum} cantFinishOptionChosen={CANT_FINISH_STEPS[Steps.REQUIRES_ADDITIONAL_REPAIRS_INTRO]
                        .cantFinishOptionChosen} todaysVisit={repairsAddedOnAddServices} returnsVisit={repairsOnEditServices} isLongerThanGracePeriod={isLongerThanGracePeriod}/>);
            case Steps.REQUIRES_ADDITIONAL_REPAIRS_ADD_SERVICE_REPAIR:
                return (<CantFinishAddServiceDrawer sendToTodayReturnVisit={sendToTodayReturnVisit} todayVisit={repairsAddedOnAddServices} addSelectedRepair={addRepairOnRepairsAddedOnAddServices}/>);
            case Steps.REQUIRES_ADDITIONAL_REPAIRS_EDIT_SERVICES:
                return (<CantFinishEditReturnDrawer sendToTodayReturnVisit={sendToTodayReturnVisit} returnVisit={repairsOnEditServices} addSelectedRepair={addRepairOnRepairsEditServices} knowRepairsAndRemovedRepairsAndCustomRepair={allRepairs.flat()}/>);
            default:
                return <div>Wrong step buddy {currentStepName}</div>;
        }
    };
    return !currentStep ? null : (<SimpleDrawer open={open} onClose={sendToIntroAndClose} title={currentStep.title}>
      {getContentComponent()}
    </SimpleDrawer>);
};
export default CantFinishDrawer;
