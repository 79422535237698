import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { createAPIPaginationHandler } from 'src/modules/api/utils/createAPIPaginationHandler';
import { ActionCreators, setSelectedJobsForOrdering, setOrderParts, setVendorConfirmationNumber, setSelectedDistributor, setSelectedDeliveryAddress, } from './actions';
const defaultState = {
    jobCounts: [],
    jobsSelected: null,
    orderParts: [],
    jobStats: undefined,
    jobs: undefined,
    van: undefined,
    vendor: undefined,
    vendorConfirmationNumber: undefined,
    topPriorityOrder: undefined,
    orderRosterJobs: undefined,
    deliveryAddressOptions: undefined,
    selectedDeliveryAddress: undefined,
    byReferenceNum: {},
    wrongPart: {},
};
const fetchJobByReferenceNumSuccess = (state, action) => {
    const job = action.payload;
    const { referenceNum } = action.meta;
    return Object.assign(Object.assign({}, state), { byReferenceNum: Object.assign(Object.assign({}, state.byReferenceNum), { [referenceNum]: job }) });
};
const fetchWrongPartNumbersSuccess = (state, action) => {
    const { componentId } = action.meta;
    const wrongPartNumbers = action.payload;
    return Object.assign(Object.assign({}, state), { wrongPart: Object.assign(Object.assign({}, state.wrongPart), { [componentId]: wrongPartNumbers }) });
};
const clearJobs = (state) => {
    return Object.assign(Object.assign({}, state), { jobs: undefined, jobsSelected: null, orderRosterJobs: undefined });
};
const reducers = handleActions({
    [ActionCreators.JOB_COUNTS.SUCCESS.type]: createSimpleHandler('jobCounts'),
    [ActionCreators.GET_REGIONS.SUCCESS.type]: (state, { payload }) => {
        var _a;
        const { pageSize, pageNumber, offset } = (_a = payload === null || payload === void 0 ? void 0 : payload.pageable) !== null && _a !== void 0 ? _a : {};
        const { first, last, numberOfElements, totalElements, totalPages } = payload !== null && payload !== void 0 ? payload : {};
        return Object.assign(Object.assign({}, state), { regions: (payload === null || payload === void 0 ? void 0 : payload.content) ? payload.content : payload, regionsFilterData: {
                pageSize,
                pageNumber,
                offset,
                first,
                last,
                numberOfElements,
                totalElements,
                totalPages,
            } });
    },
    [ActionCreators.GET_VANS_BY_REGION.SUCCESS.type]: createSimpleHandler('vans', 'vans'),
    [ActionCreators.GET_METROS.SUCCESS.type]: createSimpleHandler('metros'),
    [ActionCreators.GET_TERRITORIES.SUCCESS.type]: createSimpleHandler('territories'),
    [ActionCreators.GET_REPAIR_COMPONENTS.SUCCESS.type]: createSimpleHandler('components'),
    [ActionCreators.GET_JOBS_BY_VAN.SUCCESS.type]: createAPIPaginationHandler('jobs', 'content'),
    [ActionCreators.CLEAR_JOBS.CLEAR.type]: clearJobs,
    [ActionCreators.GET_SHOP_BY_ID.SUCCESS.type]: createSimpleHandler('van'),
    [setSelectedJobsForOrdering.type]: createSimpleHandler('jobsSelected'),
    [setOrderParts.type]: createSimpleHandler('orderParts'),
    [ActionCreators.GET_JOBS_STATS.SUCCESS.type]: createSimpleHandler('jobsStats'),
    [setVendorConfirmationNumber.type]: createSimpleHandler('vendorConfirmationNumber'),
    [ActionCreators.GET_TOP_PRIORITY_ORDER.SUCCESS.type]: createSimpleHandler('topPriorityOrder'),
    [ActionCreators.UPDATE_ORDER_ROSTER.SUCCESS.type]: createSimpleHandler('updateOrderRoster'),
    [ActionCreators.GET_COMPONENT_MARK_UP.SUCCESS.type]: createSimpleHandler('markups'),
    [ActionCreators.GET_ORDER_ROSTER_JOBS.SUCCESS.type]: createAPIPaginationHandler('orderRosterJobs', 'content'),
    [ActionCreators.GET_ACTIVELY_SKIPPED_ORDER_ROSTER_JOBS.SUCCESS.type]: createAPIPaginationHandler('orderRosterJobs', 'content'),
    [setSelectedDistributor.type]: createSimpleHandler('componentPartDistributor'),
    [ActionCreators.GET_DELIVERY_ADDRESS_OPTIONS.SUCCESS.type]: createSimpleHandler('deliveryAddressOptions'),
    [ActionCreators.GET_ORDER_ROSTER_JOB_REQUEST_BY_REFERENCE_NUM.SUCCESS.type]: fetchJobByReferenceNumSuccess,
    [ActionCreators.GET_WRONG_PART_NUMBERS.SUCCESS.type]: fetchWrongPartNumbersSuccess,
    [setSelectedDeliveryAddress.type]: createSimpleHandler('selectedDeliveryAddress'),
}, defaultState);
export default reducers;
