import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
export const SpecialLabelIcon = styled.img `
  height: 18px;
  width: 18px;
`;
export const SpecialLabelText = styled.span `
  font-family: ${FONT_FAMILY};
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  margin-left: 3px;
  color: ${colors.error};
`;
