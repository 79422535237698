import React from 'react';
import { forIn } from 'lodash';
import { MenuItem } from 'src/components/mui';
export const createMenuItems = (_enum, displayValue = true, isDisabled = () => false, firstEmpty = true) => {
    const menuItems = [];
    if (firstEmpty) {
        menuItems.push(<MenuItem value={undefined} key="none">
        &nbsp;
      </MenuItem>);
    }
    forIn(_enum.values, (value, key) => {
        menuItems.push(<MenuItem disabled={isDisabled === null || isDisabled === void 0 ? void 0 : isDisabled({ value, key })} value={key} key={key}>
        {displayValue ? value : key}
      </MenuItem>);
    });
    return menuItems;
};
