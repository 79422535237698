import { List, ListItem, ListItemSecondaryAction, ListItemText, Typography, } from 'src/components/mui';
import { KeyboardArrowRight } from 'src/components/mui/icons';
import React, { useContext } from 'react';
import { SimpleDrawerContent } from 'src/components/SimpleDrawer';
import { colors } from 'src/styles/theme';
import { formatTimestamp } from 'src/utils/formatter';
import styled from 'styled-components';
import { SliderContext } from '../sliderContext';
import { parseActions, parseEntities } from '../utils';
export const EXCLUDED_KEYS = ['modified', 'version', 'modified_by'];
const StyledDate = styled(Typography) `
  color: ${colors.grey_57};
`;
const History = ({ history, onHistoryItemClick }) => {
    const { setSlide } = useContext(SliderContext);
    const handleHistoryItemClick = (changelogs) => {
        onHistoryItemClick(changelogs);
        setSlide('historyItem');
    };
    const renderTimeGroups = () => (<List dense disablePadding>
      {Object.entries(history).map(([dateTime, changes]) => (<ListItem key={dateTime} divider button onClick={() => handleHistoryItemClick(changes)}>
          <ListItemText primary={<>
                <StyledDate variant="subtitle2">
                  {formatTimestamp(dateTime)}
                </StyledDate>
                {renderHistoryItemTitle(changes)}
              </>}/>
          <ListItemSecondaryAction>
            <KeyboardArrowRight />
          </ListItemSecondaryAction>
        </ListItem>))}
    </List>);
    const renderHistoryItemTitle = (changelogs) => {
        const entities = parseEntities(changelogs);
        const actions = parseActions(changelogs);
        if (entities.every((entity) => entity === 'Quote') &&
            changelogs.some((change) => change.table !== 'quote')) {
            return renderEntityActions(entities, ['changed']);
        }
        if (entities.every((entity) => entity === 'Repair request') &&
            changelogs.every((change) => change.changes.some((tableChange) => tableChange.field === 'customer_facing_notes'))) {
            return renderEntityActions(['Customer facing notes'], ['changed']);
        }
        if (entities.every((entity) => entity === 'Repair request') &&
            changelogs.every((change) => change.changes.some((tableChange) => tableChange.field === 'internal_notes'))) {
            return renderEntityActions(['Internal notes'], ['changed']);
        }
        return renderEntityActions(entities, actions);
    };
    const renderEntityActions = (entities, actions) => {
        return (<>
        {entities.join(', ')}: <i>{actions.join(', ')}</i>
      </>);
    };
    return (<SimpleDrawerContent>
      {history ? renderTimeGroups() : <div>LOADING...</div>}
    </SimpleDrawerContent>);
};
export default History;
