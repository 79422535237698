var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSkillsByFilter } from 'src/AdminApp/modules/skills/actions';
import { selectRepairs } from '../modules/quickRo/selectors';
import { getRepairSkillsByRepairIds } from '../modules/shops/actions';
const useCurrentRepairSkills = () => {
    const dispatch = useDispatch();
    const [repairSkills, setRepairSkills] = useState([]);
    const repairs = useSelector(selectRepairs);
    useEffect(() => {
        const repairIds = repairs.map((r) => r.repairId || r.id);
        const fetchRepairSkills = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const skillIdsResponse = yield dispatch(getRepairSkillsByRepairIds(repairIds));
                const skillIds = Array.isArray(skillIdsResponse.payload)
                    ? skillIdsResponse.payload
                    : [];
                const skillsResponse = yield dispatch(getSkillsByFilter({ ids: skillIds }));
                setRepairSkills(skillsResponse.payload);
            }
            catch (e) {
                console.error(e);
            }
        });
        fetchRepairSkills();
    }, [dispatch, repairs]);
    return repairSkills;
};
export default useCurrentRepairSkills;
