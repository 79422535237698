import React from 'react';
import { FormControl, TextField } from 'src/components/mui';
import { DrawerFormRow, SimpleDrawer } from 'src/components/SimpleDrawer';
import useCreateCoreDrawer from './useCreateCoreDrawer';
const CreateCoreDrawer = ({ open, onClose, onFinish, requestCoreCharges, referenceNum, vanId, }) => {
    const { handleClose, handleCreateCore, isSubmitting, handleChangePartNumber, } = useCreateCoreDrawer({
        onClose,
        onFinish,
        referenceNum,
        vanId,
        requestCoreCharges,
    });
    return (<SimpleDrawer simpleMode open={open} onClose={handleClose} title="Create Core" submitText="Submit" footerActionButtonProps={{
            onClick: handleCreateCore,
            isSubmitting,
        }}>
      <p>
        Some parts require you to create a corresponding core return item. What
        is the part number on the core item?
      </p>

      {requestCoreCharges === null || requestCoreCharges === void 0 ? void 0 : requestCoreCharges.map((coreCharge) => (<DrawerFormRow key={coreCharge.id}>
          <h3>{coreCharge.name}</h3>
          <FormControl fullWidth>
            <TextField variant="outlined" label="Part Number" onChange={(e) => {
                handleChangePartNumber(coreCharge.id, e.target.value);
            }}/>
          </FormControl>
        </DrawerFormRow>))}
    </SimpleDrawer>);
};
export default CreateCoreDrawer;
