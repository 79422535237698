import { Button } from 'src/components/mui';
import { Clear, Done } from 'src/components/mui/icons';
import React from 'react';
import styled from 'styled-components';
const StyledSmallButton = styled(Button) `
  margin: 10px 0 0;
  max-width: 40px;
  right: 0;
`;
const SmallDone = styled(Done) `
  height: 18px;
`;
const SmallClear = styled(Clear) `
  height: 18px;
`;
export const SmallSubmitButton = (props) => (<StyledSmallButton variant="outlined" {...props}>
    <SmallDone />
  </StyledSmallButton>);
export const SmallCancelButton = (props) => (<StyledSmallButton variant="outlined" {...props}>
    <SmallClear />
  </StyledSmallButton>);
