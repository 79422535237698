import React from 'react';
import { Typography, Grid } from 'src/components/mui';
import { Room as RoomIcon } from 'src/components/mui/icons';
import { CopyToClipboardButton } from 'src/components/buttons';
import { addressObjectToString } from 'src/clients/mapboxClient';
import EditableAddressInput from 'src/components/form/EditableAddressInput';
import useRequestedServiceLocation from 'src/AdminApp/containers/appointment/AppointmentModule/useRequestedServiceLocation';
import { IconMargin, SubTitle } from './styledComponents';
const RequestedServiceLocation = ({ address, workOrder, isEditable = true, }) => {
    var _a;
    const { handleAddressUpdate } = useRequestedServiceLocation(address, workOrder);
    return (<div>
      <Typography variant="subtitle1" component="span">
        <SubTitle>
          <RoomIcon fontSize="small"/>
          <span>Requested Service Location</span>
        </SubTitle>
      </Typography>

      <IconMargin>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="body1" component="span">
              <EditableAddressInput isEditable={isEditable} address={address} onAddressSelect={handleAddressUpdate} defaultValue="Not set"/>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <CopyToClipboardButton value={(_a = addressObjectToString(address)) !== null && _a !== void 0 ? _a : ''} style={{ marginLeft: '-6px' }}/>
          </Grid>
        </Grid>
      </IconMargin>
    </div>);
};
export default RequestedServiceLocation;
