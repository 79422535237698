import React from 'react';
import Slider from 'src/components/Slider/Slider';
import { FLEET_QUICK_RO_TABS, FLEET_TABS as TABS, } from 'src/AdminApp/modules/quickRo/enums';
import { ConfirmationModal } from 'src/components/layout';
import { FooterActionButton, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import useIsMobile from 'src/hooks/useIsMobile';
import AddRepairOrderDrawer from '../AddRepairOrderDrawer';
import SubHeader from '../SubHeader';
import useAddFleetRODrawer from './useAddFleetRODrawer';
const keepMounted = [TABS.GARAGE];
const isFirstScreen = (tab) => {
    return tab === TABS.ADD_FLEET_CUSTOMER;
};
const AddFleetRODrawer = ({ open, onClose, referenceNum, isFollowUp = false, isRescheduleCancelled = false, isRescheduleConfirmed = false, isSchedule = false, isPartOrdered = false, previousDate, previousVanId, headerText, cancellationValues, visit, }) => {
    const isMobile = useIsMobile();
    const { confirmationModalOpen, confirmationModalText, tab, closeDrawerHandler, addNewVehicle, navigateBackHandler, getIsContinueDisabled, routes, getOnClickNext, handleDrawerHeaderChange, updateConfirmationModalOpen, } = useAddFleetRODrawer({
        visit,
        referenceNum,
        onClose,
        cancellationValues,
        isSchedule,
        isRescheduleCancelled,
        isRescheduleConfirmed,
        isPartOrdered,
        previousDate,
        previousVanId,
        isFollowUp,
        isMobile,
        open,
    });
    const getHeaderText = ({ headerText, isFollowUp, tab, }) => {
        let header = '';
        if (headerText) {
            header = headerText;
        }
        else if ([TABS.SELECT_APPOINTMENT, TABS.SELECT_VISIT].includes(tab)) {
            if (isFollowUp)
                header = 'Schedule Follow-Up Fleet Appointment';
            else
                header = 'Schedule New Fleet Appointment';
        }
        else
            header = 'Add Fleet Repair Order';
        return header;
    };
    const getOnNextCta = () => tab === TABS.ADD_SERVICES ? 'Create Request' : 'Continue';
    const showArrowClose = isSchedule || isRescheduleCancelled || isRescheduleConfirmed || isFollowUp;
    const showFooter = tab !== TABS.RESET_PARTS && tab !== TABS.GARAGE;
    return (<>
      <ConfirmationModal open={confirmationModalOpen} text={confirmationModalText} action={addNewVehicle} onClose={updateConfirmationModalOpen(false)}/>
      <AddRepairOrderDrawer open={open} onClose={closeDrawerHandler} onNavigateBack={navigateBackHandler} isExpanded={tab
            ? tab === TABS.SELECT_APPOINTMENT || tab === TABS.SELECT_VISIT
            : false} header={getHeaderText({
            tab,
            isFollowUp,
            isMobile,
            showArrowClose,
            headerText,
        })} isSmall={tab ? tab === TABS.RESET_PARTS : false} subheader={isFirstScreen(tab) && (<SubHeader tabs={FLEET_QUICK_RO_TABS()} toOmit={[TABS.SEARCH_ADDRESS]} tab={tab} onChange={handleDrawerHeaderChange}/>)} footer={showFooter && (<SimpleDrawerFooter>
              <FooterActionButton disabled={getIsContinueDisabled()} onClick={getOnClickNext()}>
                {getOnNextCta()}
              </FooterActionButton>
            </SimpleDrawerFooter>)}>
        <Slider currentSlide={tab} keepMounted={keepMounted} slides={routes}/>
      </AddRepairOrderDrawer>
    </>);
};
export default AddFleetRODrawer;
