import { useLocation } from 'react-router-dom';
import { isFunction, isObject } from 'lodash';
export const PrefixHandler = (prefix) => {
    const ProxyHandler = {
        get(target, prop) {
            if (isFunction(target[prop]) && target[prop] !== null) {
                return (data) => prefix + target[prop](data);
            }
            if (isObject(target[prop]) && target[prop] !== null) {
                return new Proxy(target[prop], ProxyHandler);
            }
            return target[prop];
        },
        set() {
            return true;
        },
    };
    return ProxyHandler;
};
export const isFlagEnabled = (queryParam) => {
    return new URLSearchParams(useLocation().search).has(queryParam);
};
