import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { createAPIPaginationHandler } from 'src/modules/api/utils/createAPIPaginationHandler';
import { createClearHandler } from 'src/modules/api/utils/createClearHandler';
import { ActionCreators, resetVendors, setVehicleLocation, resetCatalogFluids, resetCatalogParts, resetRecentVendors, resetVendorsSearchResults, updateVendorSearch, resetPartDistributorLocationsByAddressSearchResults, setComponentsAvailability, } from './actions';
const defaultState = {
    recentVendors: undefined,
    homeStores: undefined,
    searchResults: undefined,
    vehicleLocation: undefined,
    componentsAvailability: undefined,
    catalogParts: {},
    catalogFluids: {},
    catalogPartsLookup: [],
    vanVendors: undefined,
    vanVendorAddresses: undefined,
    hubs: undefined,
    partDistributors: undefined,
    partDistributorSearchResults: undefined,
    addressSearchResults: undefined,
};
const componentSort = (first, second) => {
    var _a, _b;
    return (first.repairComponentId - second.repairComponentId ||
        first.unitPrice - second.unitPrice ||
        ((_a = first.partNumber) === null || _a === void 0 ? void 0 : _a.localeCompare((_b = second.partNumber) !== null && _b !== void 0 ? _b : '')) ||
        first.id - second.id);
};
const updateCatalog = (state, locationId, carId, productIds, newComponents, isFluid = false) => {
    const catalogName = isFluid ? 'catalogFluids' : 'catalogParts';
    let locationCatalog = state[catalogName][locationId];
    productIds.forEach((productId) => {
        if (locationCatalog && locationCatalog[carId]) {
            locationCatalog[carId][productId] = {
                components: newComponents,
            };
        }
        else if (locationCatalog) {
            locationCatalog[carId] = {
                [productId]: {
                    components: newComponents,
                },
            };
        }
        else {
            locationCatalog = {
                [carId]: {
                    [productId]: {
                        components: newComponents,
                    },
                },
            };
        }
    });
    return Object.assign(Object.assign({}, state), { [catalogName]: Object.assign(Object.assign({}, state[catalogName]), { [locationId]: locationCatalog }) });
};
const getCatalogPartsReducer = (state, action) => {
    const newParts = action.payload.sort(componentSort);
    newParts.forEach((part) => {
        part.meta = action.meta;
    });
    return updateCatalog(state, action.meta.locationId, action.meta.jobCar.id, action.meta.productIds, newParts);
};
const getCatalogPartsFailureReducer = (state, action) => {
    return updateCatalog(state, action.meta.locationId, action.meta.jobCar.id, action.meta.productIds, null);
};
const getCatalogPartsLookupReducer = (state, action) => {
    const newParts = action.payload.parts.slice(0, 7);
    newParts.forEach((part) => {
        part.meta = action.meta;
    });
    return Object.assign(Object.assign({}, state), { catalogPartsLookup: [...newParts] });
};
const getCatalogFluidsReducer = (state, action) => {
    const newFluids = action.payload.sort(componentSort);
    newFluids.forEach((fluid) => {
        fluid.meta = action.meta;
    });
    return updateCatalog(state, action.meta.locationId, action.meta.jobCar.id, action.meta.productIds, newFluids, true);
};
const getCatalogFluidsFailureReducer = (state, action) => {
    return updateCatalog(state, action.meta.locationId, action.meta.jobCar.id, action.meta.productIds, null, true);
};
const updateVendorSearchReducer = (state, action) => {
    var _a, _b;
    const updatedVendor = action.payload;
    return Object.assign(Object.assign({}, state), { searchResults: Object.assign(Object.assign({}, state.searchResults), { data: (_b = (_a = state.searchResults) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.map((vendor) => vendor.id === updatedVendor.id ? updatedVendor : vendor) }) });
};
const getPartDistributorsReducer = (state, action) => {
    const partDistributors = action.payload;
    return Object.assign(Object.assign({}, state), { partDistributors: partDistributors === null || partDistributors === void 0 ? void 0 : partDistributors.content });
};
const searchPartDistributorsReducer = (state, action) => {
    const partDistributors = action.payload;
    return Object.assign(Object.assign({}, state), { partDistributorSearchResults: partDistributors === null || partDistributors === void 0 ? void 0 : partDistributors.content });
};
const searchPartDistributorLocationsByAddressReducer = (state, action) => {
    var _a;
    const partDistributorLocations = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.content;
    return Object.assign(Object.assign({}, state), { addressSearchResults: partDistributorLocations });
};
const clearVanHomeStoresAndPickupsReducer = (state) => {
    return Object.assign(Object.assign({}, state), { homeStores: undefined, partPickups: undefined });
};
const reducers = handleActions({
    [ActionCreators.GET_VAN_VENDORS.SUCCESS.type]: createSimpleHandler('recentVendors'),
    [ActionCreators.GET_VAN_HOME_STORES.SUCCESS.type]: createSimpleHandler('homeStores'),
    [ActionCreators.GET_VAN_PART_PICKUPS.SUCCESS.type]: createSimpleHandler('partPickups'),
    [ActionCreators.SEARCH_PARTS_DISTRIBUTOR_LOCATIONS.SUCCESS.type]: createAPIPaginationHandler('searchResults', 'content'),
    [setVehicleLocation.type]: createSimpleHandler('vehicleLocation'),
    [resetVendors.type]: () => (Object.assign({}, defaultState)),
    [ActionCreators.POPS_GET_CATALOG_FLUIDS.SUCCESS.type]: getCatalogFluidsReducer,
    [ActionCreators.POPS_GET_CATALOG_FLUIDS.FAILURE.type]: getCatalogFluidsFailureReducer,
    [ActionCreators.POPS_GET_CATALOG_PARTS.SUCCESS.type]: getCatalogPartsReducer,
    [ActionCreators.POPS_GET_CATALOG_PARTS.FAILURE.type]: getCatalogPartsFailureReducer,
    [ActionCreators.HOME_STORES_AND_PICKUPS.CLEAR.type]: clearVanHomeStoresAndPickupsReducer,
    [ActionCreators.POPS_GET_CATALOG_PARTS_LOOKUP.SUCCESS.type]: getCatalogPartsLookupReducer,
    [updateVendorSearch.type]: updateVendorSearchReducer,
    [resetCatalogFluids.type]: createClearHandler('catalogFluids', []),
    [resetCatalogParts.type]: createClearHandler('catalogParts', []),
    [setComponentsAvailability.type]: createSimpleHandler('componentsAvailability'),
    [resetVendorsSearchResults.type]: createClearHandler('searchResults', []),
    [resetRecentVendors.type]: createClearHandler('vanVendors', defaultState.recentVendors),
    [ActionCreators.GET_RS_HUBS.SUCCESS.type]: createSimpleHandler('hubs'),
    [ActionCreators.GET_PART_DISTRIBUTORS.SUCCESS.type]: getPartDistributorsReducer,
    [ActionCreators.SEARCH_PART_DISTRIBUTOR_LOCATIONS_BY_ADDRESS.SUCCESS.type]: searchPartDistributorLocationsByAddressReducer,
    [resetPartDistributorLocationsByAddressSearchResults.type]: createClearHandler('addressSearchResults', []),
    [ActionCreators.SEARCH_PART_DISTRIBUTORS.SUCCESS.type]: searchPartDistributorsReducer,
}, defaultState);
export default reducers;
