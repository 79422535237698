import { Menu } from 'src/components/mui';
import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
import { Button, ButtonBase, Fab } from 'src/components/mui';
export const AddNotesBtn = styled(Button) `
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
  display: inline-flex;

  ${theme.breakpoints.down('sm')} {
    position: fixed;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    bottom: 100px;
    right: 16px;
  }
`;
export const AddNotesFab = styled(Fab) `
  position: fixed;
  bottom: 100px;
  right: 16px;
  height: 70px;
  width: 70px;
  z-index: 100;

  svg {
    height: 40px;
    width: 40px;
  }
`;
export const SortButtonContainer = styled('div') `
  display: flex;
  align-items: center;
`;
export const NotesDrawerBtn = styled(ButtonBase) `
  padding: 20px 0;
  box-shadow: inset 0 -1px 0 0 ${colors.lightGrey};
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;
export const InputInfo = styled('span') `
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.1px;
  color: ${colors.darkGrey};
  display: block;
  text-align: right;
  margin-top: 8px;
`;
export const InputLabel = styled('label') `
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  letter-spacing: -0.4px;
  color: ${colors.GRAY_3F};
  margin-bottom: 8px;
`;
export const MenuItemInner = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;

  svg {
    fill: ${colors.success};
  }
`;
export const MenuItemContent = styled.div `
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: -0.3px;

  li[class*='MuiButtonBase-root'] & {
    padding: 0.8rem 0;
  }

  span {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    color: ${colors.GRAY_3F};
  }
`;
export const FooterInfo = styled.span `
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.08px;
  color: ${colors.darkGrey};
  padding-bottom: 200px;
`;
export const AddNotesMenu = styled(Menu) `
  ul button {
    width: 100%;
  }
`;
