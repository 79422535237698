import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { ActionCreators, clearTechniciansForAutocomplete, setUserEmail, setUserFirstName, setUserLastName, setUserPhoneNumber, setUserResourceId, setUserSkills, setUserType, } from './actions';
const DEFAULT_STATE = {
    byId: {},
    userInfoById: {},
    byAuthGroup: {},
    byNotInAuthGroup: {},
    all: [],
    dashboard: {
        params: {
            filters: {},
        },
        users: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['id,asc'],
        },
    },
};
const userInfoSuccessReducer = (state, action) => {
    const { userId } = action.meta;
    return Object.assign(Object.assign({}, state), { userInfoById: Object.assign(Object.assign({}, state.userInfoById), { [userId]: Object.assign(Object.assign({}, action.payload), { _isFetching: false }) }) });
};
const fetchAdminUsersReducer = (state, action) => {
    const { params: { createdFrom, createdTo, id, query }, } = action.meta;
    const filters = Object.assign(Object.assign(Object.assign(Object.assign({}, (!!createdFrom && { createdFrom })), (!!createdTo && { createdTo })), (!!id && { id })), (!!query && { query }));
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params: {
                filters,
            }, _isFetching: true }) });
};
const adminUsersSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { sort } = action.meta;
    const { totalPages, totalElements, content, pageable: { pageNumber: pageIndex, pageSize }, } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { users: [...content], _isFetching: false, pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const userSelfSuccessReducer = (state, action) => {
    const { id } = action.payload;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [id]: Object.assign(Object.assign({}, action.payload), { _isFetching: false }) }) });
};
/**
 * Decorates a user reducer with logic to populate the users.byId map
 *
 * @param reducer
 * @returns
 */
const createUserReducer = (reducer) => (state, action) => {
    const userInfoById = action.payload.reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item.id]: {
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
        } })), {});
    return reducer(Object.assign(Object.assign({}, state), { userInfoById: Object.assign(Object.assign({}, state.userInfoById), userInfoById) }), action);
};
const serviceAdvisorsSuccessReducer = createUserReducer((state, action) => (Object.assign(Object.assign({}, state), { byAuthGroup: Object.assign(Object.assign({}, state.byAuthGroup), { serviceAdvisors: action.payload }) })));
const territoryManagersSuccessReducer = createUserReducer((state, action) => (Object.assign(Object.assign({}, state), { byAuthGroup: Object.assign(Object.assign({}, state.byAuthGroup), { territoryManagers: action.payload }) })));
const techniciansSuccessReducer = createUserReducer((state, action) => (Object.assign(Object.assign({}, state), { byAuthGroup: Object.assign(Object.assign({}, state.byAuthGroup), { technicians: action.payload }) })));
const engineersSuccessReducer = createUserReducer((state, action) => (Object.assign(Object.assign({}, state), { byAuthGroup: Object.assign(Object.assign({}, state.byAuthGroup), { engineers: action.payload }) })));
const portersSuccessReducer = createUserReducer((state, action) => (Object.assign(Object.assign({}, state), { byAuthGroup: Object.assign(Object.assign({}, state.byAuthGroup), { porters: action.payload }) })));
const notTechniciansSuccessReducer = createUserReducer((state, action) => (Object.assign(Object.assign({}, state), { byNotInAuthGroup: Object.assign(Object.assign({}, state.byNotInAuthGroup), { technicians: action.payload }) })));
const managersSuccessReducer = (state, action) => (Object.assign(Object.assign({}, state), { byAuthGroup: Object.assign(Object.assign({}, state.byAuthGroup), { managers: action.payload }) }));
const setUserFirstNameReducer = (state, action) => {
    const ispOwnerId = Object.keys(state.byId)[0];
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [ispOwnerId]: Object.assign(Object.assign({}, state.byId[ispOwnerId]), { firstName: action.payload }) }) });
};
const setUserLastNameReducer = (state, action) => {
    const ispOwnerId = Object.keys(state.byId)[0];
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [ispOwnerId]: Object.assign(Object.assign({}, state.byId[ispOwnerId]), { lastName: action.payload }) }) });
};
const setUserTypeReducer = (state, action) => {
    const ispOwnerId = Object.keys(state.byId)[0];
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [ispOwnerId]: Object.assign(Object.assign({}, state.byId[ispOwnerId]), { userType: action.payload }) }) });
};
const setUserSkillsReducer = (state, action) => {
    const ispOwnerId = Object.keys(state.byId)[0];
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [ispOwnerId]: Object.assign(Object.assign({}, state.byId[ispOwnerId]), { skills: action.payload }) }) });
};
const setUserPhoneNumberReducer = (state, action) => {
    const ispOwnerId = Object.keys(state.byId)[0];
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [ispOwnerId]: Object.assign(Object.assign({}, state.byId[ispOwnerId]), { phoneNumber: action.payload }) }) });
};
const setUserResourceIdReducer = (state, action) => {
    const ispOwnerId = Object.keys(state.byId)[0];
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [ispOwnerId]: Object.assign(Object.assign({}, state.byId[ispOwnerId]), { resourceId: action.payload }) }) });
};
const setUserEmailReducer = (state, action) => {
    const ispOwnerId = Object.keys(state.byId)[0];
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [ispOwnerId]: Object.assign(Object.assign({}, state.byId[ispOwnerId]), { email: action.payload }) }) });
};
const successUserProfilePictureUpdate = (state, action) => {
    const ispOwnerId = Object.keys(state.byId)[0];
    const { profilePicture } = action.payload;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [ispOwnerId]: Object.assign(Object.assign({}, state.byId[ispOwnerId]), { profilePicture }) }) });
};
const skillsSuccessReducer = (state, action) => (Object.assign(Object.assign({}, state), { skills: action.payload.content }));
const techniciansForAutocompleteReducer = (state, action) => {
    var _a;
    return (Object.assign(Object.assign({}, state), { techniciansForAutocomplete: (_a = action.payload) === null || _a === void 0 ? void 0 : _a.content }));
};
const clearTechniciansForAutocompleteReducer = (state) => (Object.assign(Object.assign({}, state), { techniciansForAutocomplete: [] }));
const reducers = handleActions({
    [ActionCreators.GET_SELF.SUCCESS.type]: userSelfSuccessReducer,
    [ActionCreators.GET_ADMIN_USER_INFO.SUCCESS.type]: userInfoSuccessReducer,
    [ActionCreators.GET_ADMIN_USERS.FETCH.type]: fetchAdminUsersReducer,
    [ActionCreators.GET_ADMIN_USERS.SUCCESS.type]: adminUsersSuccessReducer,
    [ActionCreators.GET_SERVICE_ADVISORS.SUCCESS.type]: serviceAdvisorsSuccessReducer,
    [ActionCreators.GET_TERRITORY_MANAGERS.SUCCESS.type]: territoryManagersSuccessReducer,
    [ActionCreators.GET_TECHNICIANS.SUCCESS.type]: techniciansSuccessReducer,
    [ActionCreators.GET_ENGINEERS.SUCCESS.type]: engineersSuccessReducer,
    [ActionCreators.GET_PORTERS.SUCCESS.type]: portersSuccessReducer,
    [ActionCreators.GET_NOT_TECHNICIANS.SUCCESS.type]: notTechniciansSuccessReducer,
    [ActionCreators.GET_MANAGERS.SUCCESS.type]: managersSuccessReducer,
    [ActionCreators.UPDATE_USER_PROFILE_PICTURE.SUCCESS.type]: successUserProfilePictureUpdate,
    [ActionCreators.GET_SKILLS.SUCCESS.type]: skillsSuccessReducer,
    [setUserFirstName.type]: setUserFirstNameReducer,
    [setUserLastName.type]: setUserLastNameReducer,
    [setUserType.type]: setUserTypeReducer,
    [setUserSkills.type]: setUserSkillsReducer,
    [setUserPhoneNumber.type]: setUserPhoneNumberReducer,
    [setUserResourceId.type]: setUserResourceIdReducer,
    [setUserEmail.type]: setUserEmailReducer,
    [ActionCreators.GET_TECHNICIANS_FOR_AUTOCOMPLETE.SUCCESS.type]: techniciansForAutocompleteReducer,
    [clearTechniciansForAutocomplete.type]: clearTechniciansForAutocompleteReducer,
}, DEFAULT_STATE);
export default reducers;
