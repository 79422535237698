import styled from 'styled-components';
import { colors, FONT_FAMILY, theme } from 'src/styles/theme';
import { Icon, Grid } from 'src/components/mui';
export const MarketLabel = styled.span `
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: ${colors.darkGrey};
  margin-right: 8px;
`;
export const HelperIcon = styled(Icon) `
  font-size: 18px;
  color: ${colors.lightGrey};
  margin-right: 8px;
`;
export const Row = styled.div `
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  color: ${colors.GRAY_3F};
`;
export const InfoWrapper = styled(Grid) `
  padding: 16px 0 8px;
`;
export const EmailWrapper = styled.div `
  ${theme.breakpoints.down('md')} {
    padding: 8px 0;
  }
`;
export const UserWrapper = styled.div `
  ${theme.breakpoints.down('md')} {
    padding: 8px 0;
  }
`;
export const HealthReportWrapper = styled.section `
  font-family: ${FONT_FAMILY};
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  padding-left: 40px;
`;
