import { Grid, Typography, DateTimePicker } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { InfoOutlined as InfoOutlinedIcon } from 'src/components/mui/icons';
import React, { useState } from 'react';
import AppointmentPassedModal from 'src/AdminApp/containers/appointment/AppointmentModule/AppointmentPassedModal';
import { displayResources, formIspTitle, formMechanicTitle, } from 'src/AdminApp/containers/appointment/AppointmentModule/utils';
import { AppointmentDeliveryChannel, AppointmentStatus, PartsStatus, } from 'src/AdminApp/models/enums';
import SkeduloJobResourceCategory from 'src/AdminApp/models/enums/SkeduloJobResourceCategory';
import { Editable, FieldWrapper } from 'src/components/form/components';
import EditableSelect from 'src/components/form/EditableSelect';
import useAdminUser from 'src/hooks/useAdminUser';
import useAppointmentInfo from 'src/hooks/useAppointmentInfo';
import { flexCenter } from 'src/styles/styled';
import { colors } from 'src/styles/theme';
import { formatDate } from 'src/utils/formatter';
import styled from 'styled-components';
import { DATE_YMD_FORMAT, LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT, } from 'src/timeConstants';
const CardWrapper = styled.div `
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;
  padding: 16px 0 0;
  margin-bottom: 16px;
  background: white;
`;
const StatusContainer = styled.div `
  ${flexCenter};

  height: auto;
  width: auto;
`;
const LabeledField = ({ title, children, }) => (<Grid direction="column" style={{ padding: '12px' }}>
    <Grid item xs={12} style={{ fontSize: '12px', fontWeight: 'bold', color: colors.darkGrey }}>
      {title}
    </Grid>
    <Grid item xs={12} style={{ fontSize: '14px', overflowWrap: 'break-word' }}>
      {children}
    </Grid>
  </Grid>);
const ShopField = ({ shop, canceledStyle }) => {
    var _a;
    return (<FieldWrapper style={canceledStyle}>{(_a = shop === null || shop === void 0 ? void 0 : shop.shopName) !== null && _a !== void 0 ? _a : 'None'}</FieldWrapper>);
};
const DateField = ({ isEditable, date, onUpdate, timezone, arrivalWindowStart, arrivalWindowEnd, }) => {
    const [selectedDate, setSelectedDate] = useState(date);
    return (<Editable isEditable={isEditable} displayValue={arrivalWindowStart
            ? formatDate(arrivalWindowStart, `${LOCALIZED_DATE_FORMAT} • ${TIME_12H_FORMAT} - `) + formatDate(arrivalWindowEnd, TIME_12H_FORMAT, timezone)
            : 'None'} renderForm={({ setIsEditing }) => (<DateTimePicker open 
        /* fullWidth */
        value={selectedDate} onChange={setSelectedDate} onAccept={(newDate) => {
                onUpdate({ date: newDate });
                setIsEditing(false);
            }} onClose={() => {
                setIsEditing(false);
            }} slotProps={{
                textField: {
                    fullWidth: true,
                },
            }}/>)}/>);
};
const AppointmentCard = ({ appointmentDate, appointmentResolution, appointmentTime, arrivalWindowEnd, arrivalWindowStart, actualStartLocal, createdBy, deliveryChannel, ispContactInfo, modified, modifiedBy, onAppointmentUpdate, onDateUpdate, otherResolution, partsStatus, skeduloJobId, skeduloJobResources, status, bookingTimestamp, }) => {
    var _a;
    const appointmentInfo = useAppointmentInfo({
        appointmentResolution,
        created: bookingTimestamp,
        modified: modified,
        createdBy: useAdminUser({ user: createdBy }),
        bookedBy: useAdminUser({ user: createdBy }),
        canceledBy: useAdminUser({ user: modifiedBy }),
        otherResolution,
        status,
    });
    const date = appointmentDate ? `${appointmentDate} ${appointmentTime}` : null;
    const [dateConfirmModalState, setDateConfirmModalState] = useState({
        open: false,
        date: null,
    });
    const closeConfirmModal = () => {
        setDateConfirmModalState({ open: false, date: null });
    };
    const updateDate = (_date) => {
        const newDate = _date.format(DATE_YMD_FORMAT);
        const timeSlot = _date.format(TIME_12H_FORMAT);
        onDateUpdate({ date: newDate, timeSlot });
    };
    const onPastDateConfirm = () => {
        updateDate(dateConfirmModalState.date);
        closeConfirmModal();
    };
    const tooltipAppointmentTimeInfo = !date
        ? ''
        : `Scheduled Start: ${formatDate(date, TIME_12H_FORMAT)}`.concat(actualStartLocal
            ? `\nActual Start: ${formatDate(actualStartLocal, TIME_12H_FORMAT)}`
            : '');
    const isCanceled = status === AppointmentStatus.CANCELED;
    const canceledStyle = {
        textDecoration: isCanceled ? 'line-through' : 'none',
    };
    const ispTitle = formIspTitle(deliveryChannel);
    const mechanicTitle = formMechanicTitle(deliveryChannel);
    return (<CardWrapper style={{ opacity: isCanceled ? '0.5' : '1' }}>
      <Grid container direction="column" style={{
            borderBottom: `1px solid ${colors.lightGrey}`,
            padding: '0 12px 6px',
        }}>
        <Grid container direction="row">
          <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>
                {tooltipAppointmentTimeInfo}
              </div>}>
            <Grid item xs={6} style={Object.assign({ fontWeight: 'bold' }, canceledStyle)}>
              <DateField isEditable={status === AppointmentStatus.PENDING} date={date} onUpdate={onDateUpdate} arrivalWindowStart={arrivalWindowStart} arrivalWindowEnd={arrivalWindowEnd}/>
            </Grid>
          </Tooltip>
          <Grid item xs={6}>
            <Grid item xs={12} direction="row" style={{ justifyContent: 'flex-end' }}>
              <EditableSelect isEditable={status === AppointmentStatus.PENDING} value={deliveryChannel} enumeration={AppointmentDeliveryChannel} displayValue={<StatusContainer>
                    <Tooltip placement="top" title={appointmentInfo}>
                      <InfoOutlinedIcon />
                    </Tooltip>
                    &nbsp;
                    <div>{status}</div>
                  </StatusContainer>} onUpdate={(deliveryChannel) => onAppointmentUpdate({ deliveryChannel })} style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '6px 0px',
            fontSize: '12px',
            lineHeight: '18px',
        }}/>
            </Grid>
            {!!skeduloJobId && (<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <a href={`/admin/swimlanes/${date === null || date === void 0 ? void 0 : date.substring(0, date.indexOf(' '))}`} target="_blank" rel="noreferrer">
                  Swimlane
                </a>
              </Grid>)}
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ borderBottom: `1px solid ${colors.lightGrey}` }}>
        <Grid item xs={6}>
          <LabeledField title={ispTitle}>
            <ShopField canceledStyle={canceledStyle} shop={ispContactInfo}/>
          </LabeledField>
        </Grid>
        <Grid item xs={6} style={canceledStyle}>
          <LabeledField title={mechanicTitle}>
            {displayResources(deliveryChannel, skeduloJobResources, ispContactInfo, SkeduloJobResourceCategory.MECHANIC)}
            <Typography variant="subtitle1" style={{ fontWeight: 'normal' }}>
              {(_a = ispContactInfo === null || ispContactInfo === void 0 ? void 0 : ispContactInfo.phone) !== null && _a !== void 0 ? _a : ''}
            </Typography>
          </LabeledField>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <LabeledField title="Parts Status">
          <span style={canceledStyle}>
            {partsStatus
            ? PartsStatus.display(partsStatus).toUpperCase()
            : 'N/A'}
          </span>
        </LabeledField>
      </Grid>

      <AppointmentPassedModal open={dateConfirmModalState.open} onClose={closeConfirmModal} onSubmit={onPastDateConfirm}/>
    </CardWrapper>);
};
export default AppointmentCard;
