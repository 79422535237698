var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { getVanHomeStores } from 'src/PopsApp/modules/vendors/actions';
import { selectHomeStores } from 'src/PopsApp/modules/vendors/selectors';
import { cancelPartPickupOrder, getPartPickupVan, reschedulePartPickup, } from 'src/TechApp/modules/partpickup/actions';
import { selectPartPickupOrder, selectPartPickupVans, } from 'src/TechApp/modules/partpickup/selectors';
import { TIME_FORMAT, DATE_YMD_FORMAT } from 'src/timeConstants';
const usePartPickupRescheduleCancel = ({ startLocal, onClose, }) => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedVan, setSelectedVan] = useState();
    const currentAssignedVan = useSelector(selectPartPickupVans);
    const partPickupOrder = useSelector(selectPartPickupOrder);
    const [selectedDistributorLocationId, setSelectedDistributorLocationId] = useState('');
    const homeStores = useSelector(selectHomeStores);
    const [distributors, setDistributors] = useState([]);
    const [apptDate, setApptDate] = useState();
    const [apptTime, setApptTime] = useState();
    const timeFormat = TIME_FORMAT;
    useEffect(() => {
        if (partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.id) {
            setApptDate(moment(startLocal).format(DATE_YMD_FORMAT));
            setApptTime(moment(startLocal).format(TIME_FORMAT));
            dispatch(getPartPickupVan(partPickupOrder.id));
        }
    }, [partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.id, startLocal, dispatch]);
    const submitRescheduleHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if ((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.id) && apptDate && apptTime) {
            setIsSubmitting(true);
            const response = yield dispatch(reschedulePartPickup(partPickupOrder.id, {
                distributorLocationId: selectedDistributorLocationId,
                date: apptDate,
                time: apptTime,
                vanId: (_a = selectedVan === null || selectedVan === void 0 ? void 0 : selectedVan.id) !== null && _a !== void 0 ? _a : '',
            }));
            setIsSubmitting(false);
            if (!response.error) {
                toast.success('Part Pickup Order Rescheduled Successfully');
                onClose();
                refreshPage();
            }
        }
    });
    const cancelPartPickupHandler = () => {
        setIsConfirmModalOpen(true);
    };
    const goBackHandler = () => {
        setIsConfirmModalOpen(false);
    };
    const okayHandler = () => {
        setIsConfirmModalOpen(false);
        submitCancel();
    };
    const refreshPage = () => {
        window.location.reload();
    };
    const submitCancel = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.id)) {
            return;
        }
        setIsSubmitting(true);
        const response = yield dispatch(cancelPartPickupOrder(partPickupOrder.id));
        setIsSubmitting(false);
        if (!response.error) {
            toast.success('Part Pickup Order Cancelled');
            onClose();
            refreshPage();
        }
    });
    const splitAtFirstComma = (input) => {
        const commaIndex = input.indexOf(',');
        return commaIndex === -1
            ? [input, '']
            : [input.substring(0, commaIndex), input.substring(commaIndex + 1)];
    };
    useEffect(() => {
        if (selectedVan) {
            dispatch(getVanHomeStores({ shopId: selectedVan.ispId }));
        }
    }, [selectedVan, dispatch]);
    useEffect(() => {
        if (currentAssignedVan !== undefined) {
            setSelectedVan(currentAssignedVan);
        }
    }, [currentAssignedVan]);
    useEffect(() => {
        if (selectedDistributorLocationId && selectedVan && apptDate && apptTime) {
            setIsValid(true);
        }
        else {
            setIsValid(false);
        }
    }, [apptDate, apptTime, selectedDistributorLocationId, selectedVan]);
    useEffect(() => {
        var _a, _b;
        if (partPickupOrder && partPickupOrder.partDistributorLocation) {
            const distributor = {
                distributorId: partPickupOrder.partDistributorLocation.id,
                address: partPickupOrder.partDistributorLocation.address,
                isHub: ((_a = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _a === void 0 ? void 0 : _a.partDistributorName) ===
                    'RepairSmith Hub',
            };
            updateDistributor(distributor);
            if (((_b = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.pickupAtDistributor) === null || _b === void 0 ? void 0 : _b.partDistributorName) !==
                'RepairSmith Hub') {
                setSelectedDistributorLocationId(distributor.distributorId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partPickupOrder]);
    const updateHub = (hub) => {
        const _distributors = distributors.filter((item) => !item.isHub);
        setDistributors([Object.assign({}, hub), ..._distributors]);
    };
    const updateDistributor = (distributor) => {
        const _distributors = distributors.filter((item) => item.isHub);
        if (!distributor.isHub) {
            setDistributors([Object.assign({}, distributor), ..._distributors]);
        }
    };
    useEffect(() => {
        if (homeStores) {
            homeStores.forEach((homeStore) => {
                var _a;
                if (homeStore.partDistributorName === 'RepairSmith Hub') {
                    const hub = {
                        distributorId: homeStore.partDistributorLocationId,
                        address: homeStore.partDistributorAddress,
                        isHub: true,
                    };
                    updateHub(hub);
                    if (((_a = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.pickupAtDistributor) === null || _a === void 0 ? void 0 : _a.partDistributorName) ===
                        'RepairSmith Hub') {
                        setSelectedDistributorLocationId(hub.distributorId);
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homeStores]);
    return {
        selectedDistributorLocationId,
        setSelectedDistributorLocationId,
        distributors,
        splitAtFirstComma,
        apptDate,
        setApptDate,
        apptTime,
        timeFormat,
        setApptTime,
        selectedVan,
        setSelectedVan,
        cancelPartPickupHandler,
        isSubmitting,
        submitRescheduleHandler,
        isValid,
        isConfirmModalOpen,
        setIsConfirmModalOpen,
        goBackHandler,
        okayHandler,
    };
};
export default usePartPickupRescheduleCancel;
