import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArchiveRegionPage from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/pages/RegionPage/ArchiveRegionPage';
import ArchiveServiceAreaPage from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/pages/ServiceAreaPage/ArchiveServiceAreaPage';
import { setSlidePanelPage } from 'src/AdminApp/modules/geoManagement/actions';
import { selectSlidePanelPage } from 'src/AdminApp/modules/geoManagement/selectors';
import { SimpleDrawer, SimpleDrawerHeader } from 'src/components/SimpleDrawer';
import { SimplePageSlider, SlidesContainer, } from 'src/components/SimpleDrawerPaged';
import ArchivedLaborRatesListPage from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/pages/ArchivedLaborRatesListPage';
import ArchivedLaborRatesPage from './pages/ArchivedLaborRatesPage';
import ArchiveMetroPage from './pages/ArchiveMetroPage';
import ArchiveTerritoryPage from './pages/ArchiveTerritoryPage';
import HomePage from './pages/HomePage';
import LaborRatesPage from './pages/LaborRatesPage';
import MetroPage from './pages/MetroPage';
import RegionPage from './pages/RegionPage';
import ServiceAreaPage from './pages/ServiceAreaPage';
import TerritoryPage from './pages/TerritoryPage';
import { SliderContext } from './utils';
const HOME_PAGE = 'home';
const keepMounted = ['metro', 'region'];
export const GeoManagementDrawer = ({ open, closeHandler, onServiceAreaRemove, onRegionSubmit, onRegionRemove, onMetroSubmit, onMetroRemove, onTerritorySubmit, onTerritoryRemove, onDeleteRegionLaborRates, onDeleteMetroLaborRates, disableOptions, serviceAreaModel, regionModel = null, metroModel = null, territoryModel = null, canEdit = false, }) => {
    const dispatch = useDispatch();
    const setSlide = (page) => dispatch(setSlidePanelPage(page));
    const slide = useSelector(selectSlidePanelPage);
    const closeGeoManagementDrawer = () => {
        closeHandler();
        setSlide(HOME_PAGE);
    };
    const setSlideLaborRates = () => setSlide('laborRates');
    const setSlideMetroLaborRates = () => setSlide('metroLaborRates');
    const setSlideArchivedLaborRatesList = () => setSlide('archivedLaborRatesList');
    const setSlideArchivedMetroLaborRatesList = () => setSlide('archivedMetroLaborRatesList');
    const routes = [
        {
            name: HOME_PAGE,
            title: 'Create New',
            render: () => <HomePage disableOptions={disableOptions}/>,
        },
        {
            name: 'serviceArea',
            title: serviceAreaModel ? 'Edit Service Area' : 'Create Service Area',
            hasModel: !!serviceAreaModel,
            render: () => (<ServiceAreaPage model={serviceAreaModel} onRemove={() => {
                    setSlide('archiveServiceArea');
                }} disableSave={!canEdit}/>),
        },
        {
            name: 'archiveServiceArea',
            title: 'Archive Service Area',
            render: () => (<ArchiveServiceAreaPage model={serviceAreaModel} onArchive={onServiceAreaRemove}/>),
        },
        {
            name: 'region',
            title: regionModel ? 'Edit Region' : 'Create Region',
            hasModel: !!regionModel,
            render: () => (<RegionPage disableSave={!canEdit} model={regionModel} onClickArchivedLaborRatesList={setSlideArchivedLaborRatesList} onClickLaborRates={setSlideLaborRates} onRemove={() => {
                    setSlide('archiveRegion');
                }} onSubmit={onRegionSubmit} closeGeoManagementDrawer={closeGeoManagementDrawer}/>),
        },
        {
            name: 'laborRates',
            title: regionModel ? 'Edit Region' : 'Create Region',
            render: () => <LaborRatesPage />,
        },
        {
            name: 'archivedLaborRatesList',
            title: 'Archived Labor Rates',
            render: () => <ArchivedLaborRatesListPage />,
        },
        {
            name: 'archivedLaborRates',
            title: 'Archived Labor Rates',
            render: () => <ArchivedLaborRatesPage />,
        },
        {
            name: 'archiveRegion',
            title: 'Archive Region',
            render: () => regionModel && (<ArchiveRegionPage model={regionModel} onArchive={onRegionRemove}/>),
        },
        {
            name: 'metro',
            title: metroModel ? 'Edit Metro' : 'Create Metro',
            hasModel: !!metroModel,
            render: () => (<MetroPage disableSave={!canEdit} model={metroModel} onClickArchivedLaborRatesList={setSlideArchivedMetroLaborRatesList} onClickLaborRates={setSlideMetroLaborRates} onRemove={() => {
                    setSlide('archiveMetro');
                }} onSubmit={onMetroSubmit !== null && onMetroSubmit !== void 0 ? onMetroSubmit : (() => { })}/>),
        },
        {
            name: 'metroLaborRates',
            title: metroModel ? 'Edit Metro' : 'Create Metro',
            render: () => <LaborRatesPage metro/>,
        },
        {
            name: 'archivedMetroLaborRatesList',
            title: 'Archived Labor Rates',
            render: () => <ArchivedLaborRatesListPage metro/>,
        },
        {
            name: 'archivedMetroLaborRates',
            title: 'Archived Labor Rates',
            render: () => <ArchivedLaborRatesPage />,
        },
        {
            name: 'archiveMetro',
            title: 'Archive Metro',
            render: () => metroModel && (<ArchiveMetroPage model={metroModel} onArchive={onMetroRemove}/>),
        },
        {
            name: 'territory',
            title: territoryModel ? 'Edit Territory' : 'Create Territory',
            hasModel: !!territoryModel,
            render: () => (<TerritoryPage model={territoryModel} onSubmit={onTerritorySubmit} onRemove={() => {
                    setSlide('archiveTerritory');
                }} disableSave={!canEdit}/>),
        },
        {
            name: 'archiveTerritory',
            title: 'Archive Territory',
            render: () => territoryModel && (<ArchiveTerritoryPage model={territoryModel} onArchive={onTerritoryRemove}/>),
        },
    ];
    const drawerTitle = (s) => {
        var _a, _b;
        return (_b = (_a = routes.find((r) => r.name === s)) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '';
    };
    const drawerHasModel = (s) => {
        var _a, _b;
        return (_b = (_a = routes.find((r) => r.name === s)) === null || _a === void 0 ? void 0 : _a.hasModel) !== null && _b !== void 0 ? _b : false;
    };
    const previousPage = () => {
        switch (slide) {
            case 'archiveServiceArea':
                return 'serviceArea';
            case 'archiveRegion':
            case 'laborRates':
            case 'archivedLaborRatesList':
                return 'region';
            case 'archiveMetro':
            case 'metroLaborRates':
            case 'archivedMetroLaborRatesList':
                return 'metro';
            case 'archiveTerritory':
                return 'territory';
            case 'archivedLaborRates':
                return 'archivedLaborRatesList';
            case 'archivedMetroLaborRates':
                return 'archivedMetroLaborRatesList';
            default:
                return HOME_PAGE;
        }
    };
    const providerContext = useMemo(() => ({
        keepMounted,
        onDeleteRegionLaborRates,
        onDeleteMetroLaborRates,
        previousPage,
        setSlide,
        slide,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keepMounted, slide, previousPage]);
    return (<SimpleDrawer open={open} onClose={closeGeoManagementDrawer}>
      <SimpleDrawerHeader title={drawerTitle(slide)} onClose={() => closeHandler()} onBack={() => setSlide(previousPage())} arrowBack={slide !== HOME_PAGE && !drawerHasModel(slide)}/>

      <SliderContext.Provider value={providerContext}>
        <div>
          <SlidesContainer>
            <SimplePageSlider currentSlide={slide} slides={routes}/>
          </SlidesContainer>
        </div>
      </SliderContext.Provider>
    </SimpleDrawer>);
};
export default GeoManagementDrawer;
