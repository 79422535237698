import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryButton } from 'src/components/buttons';
import { Box } from 'src/components/mui';
import RoleTooltip from 'src/components/tooltips/RoleTooltip';
import { selectHasFinanceAdmin } from 'src/modules/auth/selectors';
import PartiesEditDrawer from './PartiesEditDrawer';
import PartiesTable from './PartiesTable';
import useResponsibleParties from './useResponsibleParties';
const ResponsibleParties = () => {
    const { isDialogOpen, setDialogOpen, getData } = useResponsibleParties();
    const isFinanceAdmin = useSelector(selectHasFinanceAdmin);
    const [party, setParty] = useState();
    const [editMode, setEditMode] = useState(false);
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
      <Box m={1} display="flex" style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <RoleTooltip title="Only authorized users can add responsible parties" hasRole={isFinanceAdmin}>
          <PrimaryButton disabled={!isFinanceAdmin} onClick={() => {
            setDialogOpen(true);
        }}>
            Add New Responsible Party
          </PrimaryButton>
        </RoleTooltip>
      </Box>

      <PartiesTable onEdit={(party) => {
            if (!isFinanceAdmin)
                return;
            setParty(party);
            setEditMode(true);
            setDialogOpen(true);
        }}/>

      <PartiesEditDrawer isOpen={isDialogOpen} onClose={() => {
            setDialogOpen(false);
            setParty(undefined);
            setEditMode(false);
        }} editMode={editMode} party={party}/>
    </>);
};
export default ResponsibleParties;
