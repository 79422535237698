var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SaleMatchFilterType } from 'src/AdminApp/models/enums/SaleMatchFilterType';
import GeoMgmt from 'src/AdminApp/pages/GeoMgmt';
import InvoiceRequests from 'src/AdminApp/pages/invoiceRequests';
import Locks from 'src/AdminApp/pages/Locks';
import MarketingSources from 'src/AdminApp/pages/MarketingSources';
import Request from 'src/AdminApp/pages/Request';
import Shifts from 'src/AdminApp/pages/Shifts';
import ErrorBoundary from 'src/components/layout/ErrorBoundary';
import PageLayout from 'src/containers/layout/PageLayout';
import ProtectedRoute from 'src/containers/utils/ProtectedRoute';
import FleetVisits from 'src/FleetVisitsApp/pages/FleetVisits';
import VisitView from 'src/FleetVisitsApp/pages/VisitView';
import MyProfile from 'src/MyProfileApp/pages/MyProfile';
import InvoiceWorkbench from './pages/invoiceWorkbench';
import { NotFound } from 'src/pages';
import PartnersAPI from 'src/PartnersAPIApp/pages/PartnersAPI';
import { AdminSignup, Consumer, Consumers, Fleet, Fleets, Installations, Repairs, SalesMatch, Shop, ShopEdit, Shops, ShopsAdd, Swimlanes, ResponsibleParties, } from './pages';
const DefaultPage = ({ location }) => (<Redirect to={{ pathname: '/admin/repairs', state: { from: location } }}/>);
const MainLayout = (_a) => {
    var props = __rest(_a, []);
    window.process = { cwd: () => '' };
    return (<ErrorBoundary>
      <PageLayout {...props}>
        <Switch>
          <ProtectedRoute exact path="/admin/" component={DefaultPage}/>
          <ProtectedRoute exact path="/admin/consumers" component={Consumers}/>
          <ProtectedRoute exact path="/admin/consumers/:consumerId" component={Consumer}/>
          <ProtectedRoute exact path="/admin/repairs" component={Repairs}/>
          <ProtectedRoute exact path="/admin/repairs/:referenceNum" component={Request}/>
          <ProtectedRoute exact path="/admin/newrepairs/:referenceNum" component={Request}/>
          <ProtectedRoute exact path="/admin/repairs/:referenceNum/orders" component={({ match, }) => (<Redirect to={`/admin/repairs/${match.params.referenceNum}`}/>)}/>
          <ProtectedRoute exact path="/admin/shops" component={Shops}/>
          <ProtectedRoute exact path="/admin/shops/add" component={ShopsAdd}/>
          <ProtectedRoute exact path="/admin/shops/:shopId" component={Shop}/>
          <ProtectedRoute exact path="/admin/shops/:shopId/edit" component={ShopEdit}/>
          <ProtectedRoute exact path="/admin/salesMatch/" component={() => (<SalesMatch filterType={SaleMatchFilterType.Daily}/>)}/>
          <ProtectedRoute exact path="/admin/salesMatch/installations" component={Installations}/>
          <ProtectedRoute exact path="/admin/salesMatch/:filterType" component={({ match, }) => <SalesMatch filterType={match.params.filterType}/>}/>
          <ProtectedRoute exact path="/admin/fleets" component={Fleets}/>
          <ProtectedRoute exact path="/admin/fleets/:fleetId" component={Fleet}/>
          <ProtectedRoute exact path="/admin/user/signup" component={AdminSignup}/>
          <ProtectedRoute exact path="/admin/geoManagement" component={GeoMgmt}/>
          <ProtectedRoute exact path="/admin/locks" component={Locks}/>
          <ProtectedRoute exact path="/admin/visits/:refNum" component={VisitView}/>
          <ProtectedRoute exact path="/admin/visits" component={FleetVisits}/>
          <ProtectedRoute exact path="/admin/partner-api-keys" component={PartnersAPI}/>
          <ProtectedRoute exact path="/my-profile" component={MyProfile}/>
          <ProtectedRoute exact path="/admin/shifts" component={Shifts}/>
          <ProtectedRoute exact path="/admin/marketing-sources" component={MarketingSources}/>
          <ProtectedRoute exact path="/admin/swimlanes/:date?" component={Swimlanes}/>
          <ProtectedRoute exact path="/admin/responsible-parties" component={ResponsibleParties}/>
          <ProtectedRoute exact path="/admin/invoice-requests" component={InvoiceRequests}/>
          <ProtectedRoute exact path="/admin/invoice-workbench" component={InvoiceWorkbench}/>
          <Route component={NotFound}/>
        </Switch>
      </PageLayout>
    </ErrorBoundary>);
};
export default MainLayout;
