import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SearchAddressTitle, SelectAddressButton, SlideWrapper, } from 'src/AdminApp/components/drawers/styledComponents';
import useAddConsumerRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddConsumerRODrawer/useAddConsumerRODrawer';
import Scheduling from 'src/AdminApp/containers/scheduling';
import { scheduleContext } from 'src/AdminApp/containers/scheduling/util';
import { setContinueDisabled } from 'src/AdminApp/modules/quickRo/actions';
import { CONSUMER_TABS as TABS } from 'src/AdminApp/modules/quickRo/enums';
import { selectAllAvailabilityLoading, selectContinueDisabled, selectIsBackNavDisabled, selectRepairs, } from 'src/AdminApp/modules/quickRo/selectors';
import PlacesACWithServiceAreas from 'src/components/form/PlacesACWithServiceAreas';
import SearchUsersField from 'src/components/form/SearchUsersField';
import { ConfirmationModal, SimpleModal } from 'src/components/layout';
import { FooterActionButton, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleLoader from 'src/components/SimpleLoader';
import AddRepairOrderDrawer from '../AddRepairOrderDrawer';
import ConsumerInfo from '../components/ConsumerInfo';
import ResetParts from '../components/ResetPartsConfirmation';
import AddServices from '../pages/services/AddServices';
import CreateVehicle from '../pages/vehicle/CreateVehicle';
import Garage from '../pages/vehicle/Garage';
import Slider from '../Slider';
import SubHeader from '../SubHeader';
import AddNewConsumerTab from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddConsumerRODrawer/components/AddNewConsumerTab';
const { Provider } = scheduleContext;
const isFirstScreen = (tab) => {
    return (tab === TABS.ADD_NEW_CUSTOMER ||
        tab === TABS.EXISTING_CUSTOMER ||
        tab === TABS.SEARCH_ADDRESS);
};
const isTimeSelectionScreen = (tab) => {
    return tab === TABS.SELECT_APPOINTMENT;
};
const AddConsumerRODrawer = ({ open, onClose, referenceNum, context, isFollowup, isRecommendedRepair = false, isRescheduleConfirmed, isRescheduleCancelled, isSchedule, isPartOrdered, previousDate, previousVanId, headerText, preSelectedMechanics, cancellationValues, additionalRescheduleAction, }) => {
    var _a;
    const { isLoading, isDuplicateCarModalOpen, setIsDuplicateCarModalOpen, confirmationModalOpen, setConfirmationModalOpen, confirmationModalText, tab, user, customUser, customConsumerAddress, customVehicle, isUserSelected, closeDrawerHandler, onAddNewVehicle, addNewVehicleHandler, onCreateRequest, getOnClickNext, handleSelectCustomerAddress, handleSetTab, handleSetUser, loadMoreVehicles, } = useAddConsumerRODrawer(open, onClose, referenceNum, isFollowup, false, isRescheduleConfirmed, isRescheduleCancelled, isSchedule, isPartOrdered, previousDate, previousVanId, headerText, preSelectedMechanics, cancellationValues, additionalRescheduleAction);
    const continueDisabled = useSelector(selectContinueDisabled);
    const isBackNavDisabled = useSelector(selectIsBackNavDisabled);
    const [isSelectBtnDisabled, setIsSelectBtnDisabled] = useState(false);
    const [isZipCodeValid, setIsZipCodeValid] = useState(false);
    const repairs = useSelector(selectRepairs);
    const isAvailabilityLoading = useSelector(selectAllAvailabilityLoading);
    const contextValue = {
        isDisabled: continueDisabled,
        isSelectBtnDisabled,
        setIsSelectBtnDisabled,
    };
    const showArrowClose = isSchedule || isRescheduleConfirmed || isRescheduleCancelled || isFollowup;
    const getIsContinueDisabled = () => {
        if (continueDisabled) {
            return true;
        }
        if (tab === TABS.SEARCH_ADDRESS) {
            return !customConsumerAddress;
        }
        if (tab === TABS.EXISTING_CUSTOMER) {
            return !((user === null || user === void 0 ? void 0 : user.address) && (user === null || user === void 0 ? void 0 : user.id));
        }
        if (tab === TABS.ADD_NEW_CUSTOMER) {
            return !((customUser === null || customUser === void 0 ? void 0 : customUser.firstName) &&
                (customUser === null || customUser === void 0 ? void 0 : customUser.lastName) &&
                (customUser === null || customUser === void 0 ? void 0 : customUser.phone) &&
                (customUser === null || customUser === void 0 ? void 0 : customUser.email) &&
                (customUser === null || customUser === void 0 ? void 0 : customUser.address) &&
                (customUser === null || customUser === void 0 ? void 0 : customUser.marketingSourceId) &&
                isZipCodeValid);
        }
        if (tab === TABS.GARAGE) {
            return true;
        }
        if (tab === TABS.ADD_NEW_VEHICLE) {
            return !((customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.year) &&
                (customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.makeId) &&
                (customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.modelId) &&
                (customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.engineId) &&
                (customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.trimId) &&
                !!customVehicle.plateNumber === !!customVehicle.plateNumberState);
        }
        if (tab === TABS.ADD_SERVICES) {
            return repairs.length === 0;
        }
        if (tab === TABS.SELECT_APPOINTMENT) {
            return isAvailabilityLoading;
        }
        return false;
    };
    const getOnNextCta = () => tab === TABS.ADD_SERVICES ? 'Create Request' : 'Continue';
    const renderExistingCustomerTab = () => {
        return (<SlideWrapper>
        <SearchUsersField label="Customer" onSelect={(u) => {
                handleSetUser(u);
            }}/>

        {isUserSelected() && (<>
            <ConsumerInfo user={user}/>
            <SelectAddressButton onClick={() => {
                    handleSetTab(TABS.SEARCH_ADDRESS);
                }}>
              Select a different address
            </SelectAddressButton>
          </>)}
      </SlideWrapper>);
    };
    const renderSearchAddress = () => {
        return (<SlideWrapper>
        <SearchAddressTitle>Search Address</SearchAddressTitle>
        <PlacesACWithServiceAreas address={customConsumerAddress} onSelect={(value) => handleSelectCustomerAddress(value)} textFieldParams={{
                variant: 'outlined',
                label: 'Address',
            }}/>
      </SlideWrapper>);
    };
    const renderAddNewCustomer = () => {
        return <AddNewConsumerTab setIsZipCodeValid={setIsZipCodeValid}/>;
    };
    const renderAddNewVehicle = () => {
        return (<SlideWrapper>
        <CreateVehicle />
      </SlideWrapper>);
    };
    const renderGarage = () => {
        if (!user && !customUser)
            return <AddRepairOrderDrawer />;
        return (<SlideWrapper>
        <Garage consumer={user || customUser}/>
      </SlideWrapper>);
    };
    const renderAddServices = () => {
        return (<SlideWrapper>
        <AddServices />
      </SlideWrapper>);
    };
    const renderSelectAppointment = () => (<Provider value={contextValue}>
      {isLoading ? (<SimpleLoader />) : (<Scheduling userAddress={(user === null || user === void 0 ? void 0 : user.address) || {}} context={context} preSelectedMechanics={preSelectedMechanics} enableSpecificTime={!isRecommendedRepair}/>)}
    </Provider>);
    const renderResetParts = () => {
        return (<SlideWrapper>
        {isLoading ? (<SimpleLoader />) : (<ResetParts onConfirmResetParts={() => onCreateRequest(true)} onCancelResetParts={() => onCreateRequest(false)}/>)}
      </SlideWrapper>);
    };
    const routes = [
        {
            name: TABS.EXISTING_CUSTOMER,
            render: renderExistingCustomerTab,
        },
        { name: TABS.ADD_NEW_CUSTOMER, render: renderAddNewCustomer },
        { name: TABS.SEARCH_ADDRESS, render: renderSearchAddress },
        { name: TABS.GARAGE, render: renderGarage },
        { name: TABS.ADD_NEW_VEHICLE, render: renderAddNewVehicle },
        { name: TABS.ADD_SERVICES, render: renderAddServices },
        { name: TABS.SELECT_APPOINTMENT, render: renderSelectAppointment },
        { name: TABS.RESET_PARTS, render: renderResetParts },
    ];
    const navigateBackHandler = () => {
        var _a, _b;
        if (isFirstScreen(tab) ||
            isTimeSelectionScreen(tab) ||
            isBackNavDisabled ||
            context === 'Consumer / Vehicle') {
            closeDrawerHandler();
        }
        else {
            const tabIndex = routes.findIndex((route) => tab === route.name);
            if (isFirstScreen((_a = routes[tabIndex - 1]) === null || _a === void 0 ? void 0 : _a.name)) {
                handleSetTab(TABS.EXISTING_CUSTOMER);
            }
            else if (tab === TABS.ADD_SERVICES) {
                handleSetTab(TABS.GARAGE);
            }
            else {
                handleSetTab((_b = routes[tabIndex - 1]) === null || _b === void 0 ? void 0 : _b.name);
            }
        }
    };
    const getHeaderText = () => {
        let header;
        if (headerText)
            header = headerText;
        else if (tab === TABS.SELECT_APPOINTMENT) {
            if (isFollowup)
                header = 'Schedule Follow-Up Appointment';
            else
                header = 'Schedule New Appointment';
        }
        else
            header = 'Add Repair Order';
        return header;
    };
    const isResetParts = tab === TABS.RESET_PARTS;
    const handleConfirmDuplicateCar = () => {
        setIsDuplicateCarModalOpen(false);
        setContinueDisabled(false);
        addNewVehicleHandler();
    };
    return (<>
      <ConfirmationModal open={confirmationModalOpen} text={confirmationModalText} action={onAddNewVehicle} onClose={() => setConfirmationModalOpen(false)}/>
      <AddRepairOrderDrawer open={open} onClose={closeDrawerHandler} onNavigateBack={navigateBackHandler} header={getHeaderText()} isExpanded={tab ? tab === TABS.SELECT_APPOINTMENT : false} isSmall={tab ? tab === TABS.RESET_PARTS : false} arrowClose={showArrowClose} subheader={isFirstScreen(tab) && (<SubHeader tabs={[
                { label: 'Existing Customer', value: TABS.EXISTING_CUSTOMER },
                { label: 'Add New Customer', value: TABS.ADD_NEW_CUSTOMER },
            ]} toOmit={[TABS.SEARCH_ADDRESS]} tab={tab} onChange={(event, tab) => handleSetTab(tab)}/>)} footer={!isResetParts && (<SimpleDrawerFooter>
              <FooterActionButton disabled={getIsContinueDisabled()} onClick={(_a = getOnClickNext()) !== null && _a !== void 0 ? _a : (() => { })}>
                {getOnNextCta()}
              </FooterActionButton>
            </SimpleDrawerFooter>)}>
        <Slider currentSlide={tab} slides={routes} callback={loadMoreVehicles}/>
      </AddRepairOrderDrawer>
      <SimpleModal open={isDuplicateCarModalOpen} onClose={() => setIsDuplicateCarModalOpen(false)} title="Car already exists" simpleSingleButtonRowProps={{
            submitText: 'Okay',
            onSubmit: handleConfirmDuplicateCar,
        }}>
        You already have a {customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.year} {customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.model}{' '}
        {customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.make}. Are you certain you want to create a duplicate?
      </SimpleModal>
    </>);
};
export default AddConsumerRODrawer;
