var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addPagesToFunnel, removePagesFromFunnel, updateCustomFunnel, updatePageInFunnel, } from 'src/PartnersApp/modules/partners/actions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
const useFunnelSubmit = (funnel) => {
    const dispatch = useDispatch();
    const [submitComplete, setSubmitComplete] = useState(false);
    useEffect(() => {
        if (funnel) {
            setSubmitComplete(false);
        }
    }, [funnel]);
    const updateFunnel = (values, removedPages) => __awaiter(void 0, void 0, void 0, function* () {
        // FUNNEL UPDATING
        const funnelUpdatedResponse = yield dispatch(updateCustomFunnel({
            funnel: {
                id: funnel === null || funnel === void 0 ? void 0 : funnel.id,
                name: values.name,
                urlParameterIdentifier: values.urlParameterIdentifier,
                marketingSourceId: values.marketingSourceId,
                startAtPlate: values.startAtPlate,
            },
        }));
        if (funnelUpdatedResponse === null || funnelUpdatedResponse === void 0 ? void 0 : funnelUpdatedResponse.payload) {
            // ADD NEW PAGES
            const pagesToBeUpdated = [];
            const pagesToBeAdded = values.pages.reduce((prev, curr) => {
                if (!curr.funnelsPagesId) {
                    return [...prev, Object.assign({}, curr)];
                }
                pagesToBeUpdated.push(Object.assign({}, curr));
                return [...prev];
            }, []);
            if (pagesToBeAdded.length > 0 && funnel) {
                yield dispatch(addPagesToFunnel({
                    funnelId: funnel === null || funnel === void 0 ? void 0 : funnel.id,
                    pages: pagesToBeAdded,
                }));
            }
            // REMOVE PAGES WITH funnelsPagesId FROM THE FUNNEL
            const pagesToBeRemoved = Object.values(removedPages).reduce((prev, curr) => {
                if (curr.funnelsPagesId) {
                    return [...prev, Object.assign({}, curr)];
                }
                return [...prev];
            }, []);
            if (pagesToBeRemoved.length > 0 && funnel) {
                yield dispatch(removePagesFromFunnel({
                    funnelId: funnel === null || funnel === void 0 ? void 0 : funnel.id,
                    pages: pagesToBeRemoved,
                }));
            }
            if (pagesToBeUpdated.length > 0 && funnel) {
                yield Promise.all(pagesToBeUpdated.map((p) => dispatch(updatePageInFunnel({ funnelId: funnel === null || funnel === void 0 ? void 0 : funnel.id, page: p }))));
            }
            setSubmitComplete(true);
        }
    });
    return {
        updateFunnel,
        setSubmitComplete,
        submitComplete,
    };
};
export default useFunnelSubmit;
