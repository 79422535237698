var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import API_URLS from 'src/AdminApp/modules/api/urls';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createDefaultActionCreators from 'src/utils/createActions';
export const ActionCreators = {
    GET_ALL_MARKETING_SOURCES: createDefaultActionCreators('GET_ALL_MARKETING_SOURCES'),
    CREATE_MARKETING_SOURCE: createDefaultActionCreators('CREATE_MARKETING_SOURCE'),
    UPDATE_MARKETING_SOURCE: createDefaultActionCreators('UPDATE_MARKETING_SOURCE'),
    GET_MARKETING_SOURCE: createDefaultActionCreators('GET_MARKETING_SOURCE'),
    GET_FEES: createDefaultActionCreators('GET_FEES'),
};
export const getFees = () => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_FEES),
        endpoint: API_URLS.ADMIN.MARKETING_SOURCES_FEES(),
        method: 'GET',
    });
};
export const getMarketingSources = ({ pageSize, pageIndex, visibility, }) => {
    const params = Object.assign(Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!visibility && { visibility })), { sort: ['displayName,asc'] });
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_ALL_MARKETING_SOURCES),
        endpoint: API_URLS.ADMIN.MARKETING_SOURCES_DASHBOARD({}),
        method: 'GET',
        params,
    });
};
export const getMarketingSource = (id) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_MARKETING_SOURCE),
        endpoint: API_URLS.ADMIN.MARKETING_SOURCES_DASHBOARD({
            marketingSourceId: id,
        }),
        method: 'GET',
    });
};
export const createMarketingSource = (data) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.CREATE_MARKETING_SOURCE),
        endpoint: API_URLS.ADMIN.MARKETING_SOURCES_DASHBOARD({}),
        method: 'POST',
        body: data,
    });
};
export const updateMarketingSource = (data) => {
    const { id } = data, body = __rest(data, ["id"]);
    return createAPIAction({
        types: fillApiTypes(ActionCreators.UPDATE_MARKETING_SOURCE),
        endpoint: API_URLS.ADMIN.MARKETING_SOURCES_DASHBOARD({
            marketingSourceId: id,
        }),
        method: 'PUT',
        body,
    });
};
