import React from 'react';
import { Grid, Typography } from 'src/components/mui';
import { useDispatch } from 'react-redux';
import { openWarrantyQuestionSection } from 'src/AdminApp/modules/requests/actions';
import { PrimaryButton } from 'src/components/buttons';
import { WarrantyButtonWrapper } from 'src/AdminApp/containers/vehicle/CarModule/CarFields.styles';
const WarrantyInspection = () => {
    const dispatch = useDispatch();
    const openWarrantyDrawer = () => {
        dispatch(openWarrantyQuestionSection(true));
    };
    return (<WarrantyButtonWrapper>
      <Grid container style={{ alignContent: 'center' }}>
        <Grid item>
          <Typography variant="subtitle2" gutterBottom>
            Warranty Claim&nbsp;
          </Typography>
          <PrimaryButton onClick={openWarrantyDrawer}>
            Edit Warranty
          </PrimaryButton>
        </Grid>
      </Grid>
    </WarrantyButtonWrapper>);
};
export default WarrantyInspection;
