import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, Link } from 'react-router-dom';
import { Typography, Button } from 'src/components/mui';
import { FileCopy as FileCopyIcon } from 'src/components/mui/icons';
import SimpleChip from 'src/components/SimpleChip';
import { colors } from 'src/styles/theme';
import copyToClipboard from 'src/utils/copyToClipboard';
import styled from 'styled-components';
const RedirectLink = styled(Link) `
  margin-left: 8px;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: ${colors.primary};
  }
`;
const TypeLabel = ({ type, refNum }) => {
    const [el, setEl] = useState(null);
    const history = useHistory();
    const isFleetVisit = type === 'FLEET_VISIT';
    let labelText;
    if (isFleetVisit) {
        labelText = 'Fleet Visit';
    }
    else if (type === 'FLEET') {
        labelText = 'Fleet';
    }
    else {
        labelText = 'Consumer';
    }
    const chipBackground = isFleetVisit ? colors.primary : colors.secondary;
    const chipTextColor = colors.white;
    const handleCopyClick = () => copyToClipboard(`${location.origin}/admin/visits/${refNum}`);
    const handleChipClick = isFleetVisit
        ? () => {
            history.push(`/admin/visits/${refNum}`);
        }
        : undefined;
    useEffect(() => {
        setEl(document.querySelector('.type-render-portal'));
    }, []);
    if (el) {
        return ReactDOM.createPortal(<Typography component="div">
        <SimpleChip onClick={handleChipClick} $bgColor={chipBackground} $textColor={chipTextColor} label={labelText} size="medium"/>
        {isFleetVisit && (<>
            <RedirectLink to={`/admin/visits/${refNum}`}>{refNum}</RedirectLink>
            <Button startIcon={<FileCopyIcon />} onClick={handleCopyClick}/>
          </>)}
      </Typography>, el);
    }
    return null;
};
export default TypeLabel;
