var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSkillsByFilter } from 'src/AdminApp/modules/skills/actions';
import { removeUserProfileSkill, setUserFirstName, setUserLastName, setUserPhoneNumber, setUserResourceId, setUserSkills, updateUserProfileInfo, updateUserProfileSkills, uploadUserProfilePicture, } from 'src/AdminApp/modules/users/actions';
import AppliesTo from 'src/AdminApp/models/enums/AppliesTo';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import { toast } from 'src/components/SimpleToast';
const useEditMyProfileInfoDrawer = ({ user, onClose }) => {
    const dispatch = useDispatch();
    const [profilePicture, setProfilePicture] = useState(user.profilePicture);
    const [availableSkills, setAvailableSkills] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        setProfilePicture(user.profilePicture);
    }, [user.profilePicture]);
    useEffect(() => {
        dispatch(getSkillsByFilter({ applies_to: AppliesTo.TECHNICIAN })).then((response) => setAvailableSkills(response.payload));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handlerFirstNameChange = (e) => {
        dispatch(setUserFirstName(e.target.value));
    };
    const handlerLastNameChange = (e) => {
        dispatch(setUserLastName(e.target.value));
    };
    const handlerPhoneNumberChange = (e) => {
        dispatch(setUserPhoneNumber(e.target.value));
    };
    const handlerResourceIdChange = (e) => {
        dispatch(setUserResourceId(e.target.value));
    };
    const onProfilePictureSelected = (e) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        e.preventDefault();
        const file = ((_a = e.target.files) === null || _a === void 0 ? void 0 : _a.length) ? e.target.files[0] : undefined;
        if (!file)
            return;
        const profilePicturePayload = {
            file,
            userId: user.id,
        };
        const profilePictureUpdateResponse = yield dispatch(uploadUserProfilePicture(profilePicturePayload));
        if (!profilePictureUpdateResponse.error) {
            analyticsTrackEvent('User profile picture Updated', {
                'User ID': profilePicturePayload.userId,
            });
            setProfilePicture(URL.createObjectURL(file));
        }
    });
    const handleSelected = (e, value, reason) => __awaiter(void 0, void 0, void 0, function* () {
        if (reason === 'select-option') {
            const recentSkillAdded = value[value.length - 1];
            const userSkillUpdateResponse = yield dispatch(updateUserProfileSkills(user.id, recentSkillAdded));
            if (!userSkillUpdateResponse.error) {
                analyticsTrackEvent('User Skill Added', {
                    'User ID': user.id,
                });
                dispatch(setUserSkills(value));
            }
        }
        if (reason === 'remove-option') {
            const removedSkill = user.skills.filter((skill) => !value.some((val) => skill.id === val.id));
            const userSkillRemovedResponse = yield dispatch(removeUserProfileSkill(user.id, removedSkill[0].id));
            if (!userSkillRemovedResponse.error) {
                analyticsTrackEvent('User Skill Removed', {
                    'User ID': user.id,
                });
                dispatch(setUserSkills(value));
            }
        }
    });
    const handleClose = () => {
        onClose();
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isSubmitting)
            return;
        setIsSubmitting(true);
        const submitPayload = {
            body: user,
        };
        const updateResponse = yield dispatch(updateUserProfileInfo(submitPayload));
        if (!updateResponse.error) {
            analyticsTrackEvent('User Profile Info Updated', {
                'User ID': submitPayload.body.id,
            });
            toast.success('User Info updated successfully!');
            onClose();
        }
        setIsSubmitting(false);
    });
    return {
        profilePicture,
        availableSkills,
        isSubmitting,
        setProfilePicture,
        setAvailableSkills,
        setIsSubmitting,
        handlerFirstNameChange,
        handlerLastNameChange,
        handlerPhoneNumberChange,
        handlerResourceIdChange,
        onProfilePictureSelected,
        handleSelected,
        handleClose,
        handleSubmit,
    };
};
export default useEditMyProfileInfoDrawer;
