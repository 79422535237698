import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TableCell, TableRow } from 'src/components/mui';
import { formatTimestamp } from 'src/utils/formatter';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { Module, Subtext } from 'src/components/layout';
import { RepairRequestStatus } from 'src/AdminApp/models/enums';
import { getConsumerRequestHistory } from 'src/AdminApp/modules/consumers/actions';
import { selectConsumerRequestHistory } from 'src/AdminApp/modules/consumers/selectors';
import { getIspOwnerId } from 'src/AdminApp/components/users/utils';
import useMultipleAdminUsers from 'src/hooks/useMultipleAdminUsers';
const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
`;
const COLUMNS = [
    { title: 'Reference #', key: 'referenceNum' },
    { title: 'Status', key: 'status' },
    { title: 'Vehicle', key: 'vehicle.make' },
    { title: 'Services', key: 'services' },
    { title: 'Appointment', key: 'appointmentScheduleStartUTC' },
    { title: 'Created', key: 'created' },
];
const HistoryItem = ({ request, users }) => {
    var _a;
    const { car } = request;
    const repairs = (request === null || request === void 0 ? void 0 : request.suggestedInvoiceRepairs.length) > 0
        ? request.suggestedInvoiceRepairs
        : request === null || request === void 0 ? void 0 : request.suggestedQuoteRepairs;
    const user = users.find((user) => user.createdBy === request.createdBy);
    const hasName = !!((user === null || user === void 0 ? void 0 : user.firstName) || (user === null || user === void 0 ? void 0 : user.lastName));
    const name = hasName
        ? `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}`
        : (_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.createdBy;
    return (<StyledTableRow key={request.referenceNum}>
      <TableCell>
        <Link to={`/admin/repairs/${request.referenceNum}`}>
          {request.referenceNum}
        </Link>
      </TableCell>
      <TableCell>
        <div>{request.status}</div>
        <Subtext>{RepairRequestStatus.display(request.resolution)}</Subtext>
      </TableCell>
      <TableCell title={`ID: ${car.id}, Consumer Car ID: ${car.consumerCarId}`}>
        <div>
          {car.year} {car.make} {car.model} {car.trim} {car.engine}
        </div>
        <Subtext>Consumer Car ID: ({car.consumerCarId})</Subtext>
      </TableCell>
      <TableCell>
        <div>
          {repairs === null || repairs === void 0 ? void 0 : repairs.map((item) => (<div key={item}>{item}</div>))}
        </div>
      </TableCell>
      <TableCell>
        <div>
          {request.appointmentScheduleStartUTC
            ? formatTimestamp(request.appointmentScheduleStartUTC)
            : '-'}
        </div>
      </TableCell>
      <TableCell>
        <div>{formatTimestamp(request.created)}</div>
        {user && <Subtext>{name}</Subtext>}
      </TableCell>
    </StyledTableRow>);
};
const RepairHistory = ({ consumerId }) => {
    var _a, _b;
    const dispatch = useDispatch();
    const [usersIds, setUsersIds] = useState([]);
    const requestHistory = useSelector(selectConsumerRequestHistory({ consumerId }));
    const { params, pagination, data } = requestHistory !== null && requestHistory !== void 0 ? requestHistory : {};
    const { usersInfo } = useMultipleAdminUsers({ userIds: usersIds });
    useEffect(() => {
        if (data && usersIds.length === 0) {
            const users = data.reduce((acc, el) => {
                const user = {
                    ispOwnerId: getIspOwnerId(el.createdBy),
                    createdBy: el.createdBy,
                };
                const accItem = acc.find((el) => el.ispOwnerId === user.ispOwnerId);
                if (!accItem)
                    acc.push(Object.assign({}, user));
                return acc;
            }, []);
            setUsersIds(users);
        }
    }, [data]);
    const onLoadData = ({ page, size, sort, }) => {
        setUsersIds([]);
        dispatch(getConsumerRequestHistory(consumerId, {
            page,
            size,
            sort: sort,
        }));
    };
    return (<Module title="Request History">
      {data && (<SimpleTable data={data} pagination={pagination} sort={(_b = (_a = params === null || params === void 0 ? void 0 : params.pagination) === null || _a === void 0 ? void 0 : _a.sort) !== null && _b !== void 0 ? _b : []} columns={COLUMNS} renderItem={(item, index) => (<HistoryItem key={`${index}-${item.referenceNum}`} request={item} users={usersInfo}/>)} onLoadData={onLoadData}/>)}
    </Module>);
};
export default RepairHistory;
