import styled from 'styled-components';
import { Grid } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const GridLabelItem = styled(Grid) `
  display: flex;
  margin-top: 20px;
`;
export const Label = styled.span `
  color: ${() => colors.darkGrey};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-size: 12px;
  width: 90px;
`;
export const Value = styled.span `
  color: ${(props) => (props.grey ? colors.darkGrey : colors.default)};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-size: 14px;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const DateSelector = styled.div `
  cursor: pointer;
  display: flex;
  align-items: center;
`;
export const DateValue = styled.span `
  margin-left: 10px;
  font-weight: bold;
`;
