import { map } from 'lodash';
import React from 'react';
import { Card, CardContent, Divider, Typography, Grid, } from 'src/components/mui';
import { DeliveryChannel } from 'src/AdminApp/models/enums';
import styled from 'styled-components';
const StyledCard = styled(Card) `
  margin-top: 24px;
`;
const ShopCard = ({ shop }) => {
    var _a, _b, _c, _d, _e, _f;
    return (<StyledCard>
      <CardContent>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h6">{(_a = shop.shopName) !== null && _a !== void 0 ? _a : ''}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{(_b = shop.matchScore) !== null && _b !== void 0 ? _b : ''}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1">{(_c = shop.status) !== null && _c !== void 0 ? _c : ''}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Match Score</Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1">Address</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Phone Number</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <div>{(_d = shop.address) !== null && _d !== void 0 ? _d : ''}</div>
              <div>
                {(_e = shop.city) !== null && _e !== void 0 ? _e : ''}, {shop.state} {shop.zip}
              </div>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <div>{shop.phone}</div>
              <div>
                {shop.firstName} {shop.lastName}
              </div>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Shop Type</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {map((_f = shop.deliveryChannels) !== null && _f !== void 0 ? _f : [], (channel) => DeliveryChannel.display(channel)).join(', ')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>);
};
export default ShopCard;
