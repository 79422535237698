import React from 'react';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
const Wrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${colors.GRAY_F5};
  min-height: 245px;
`;
const Text = styled.p `
  font-size: 16px;
  color: ${colors.black};
  font-weight: 500;
  line-height: 16px;
`;
const NullState = ({ text }) => {
    return (<Wrapper>
      <Text>{text}</Text>
    </Wrapper>);
};
export default NullState;
