import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import SkeletonItem from './SkeletonItem';
const SkeletonContainer = styled.div `
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  ${theme.breakpoints.up('md')} {
    display: block;
    align-items: center;
  }
`;
const Skeleton = () => {
    return (<SkeletonContainer>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </SkeletonContainer>);
};
export default Skeleton;
