var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { uniqueId } from 'lodash';
import { selectMetroModel, selectRegionModel, selectSelectedLaborRates, } from 'src/AdminApp/modules/geoManagement/selectors';
import { selectAuthorizedGeoLaborRateEdit } from 'src/modules/auth/selectors';
import { ActionCreators, updateLaborRate, } from 'src/AdminApp/modules/geoManagement/actions';
import { getMetroById, updateMetro } from 'src/AdminApp/modules/metros/actions';
import { getRegionById, updateRegion, } from 'src/AdminApp/modules/regions/actions';
import { euRatesFields } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/utils';
import { DATE_YMD_FORMAT, LOCALIZED_DATE_LONGER_FORMAT, } from 'src/timeConstants';
const useLaborRatesPage = ({ metro = false, formRef, previousPage, setSlide, laborRatesSchema, }) => {
    const dispatch = useDispatch();
    const regionModel = useSelector(selectRegionModel);
    const metroModel = useSelector(selectMetroModel);
    const laborRate = useSelector(selectSelectedLaborRates);
    const laborRateEditor = useSelector(selectAuthorizedGeoLaborRateEdit);
    const model = !metro ? regionModel : metroModel;
    const getById = !metro ? getRegionById : getMetroById;
    const disabledEffectiveFrom = model && laborRate
        ? moment(laborRate.effectiveFrom).isBefore(Date.now(), 'day')
        : false;
    const disabledEffectiveTo = model && laborRate && laborRate.effectiveTo
        ? moment(laborRate.effectiveTo).isBefore(Date.now(), 'day')
        : false;
    useEffect(() => () => {
        dispatch(ActionCreators.LABOR_RATES.CLEAR_EDIT());
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    const handleEuChange = (_e, value) => {
        var _a, _b;
        if ((_a = formRef === null || formRef === void 0 ? void 0 : formRef.current) === null || _a === void 0 ? void 0 : _a.values.euActive) {
            euRatesFields.forEach((field) => {
                var _a;
                (_a = formRef === null || formRef === void 0 ? void 0 : formRef.current) === null || _a === void 0 ? void 0 : _a.setFieldValue(field, undefined);
            });
        }
        (_b = formRef === null || formRef === void 0 ? void 0 : formRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue('euActive', value);
    };
    const labelFn = (value) => (date, invalid) => {
        if (invalid)
            return invalid;
        if (value) {
            const label = date.format(LOCALIZED_DATE_LONGER_FORMAT);
            return label;
        }
        return '';
    };
    const handleDateChange = (name) => (momentDate) => {
        var _a;
        if (!momentDate || !(formRef === null || formRef === void 0 ? void 0 : formRef.current))
            return;
        const date = momentDate.format(DATE_YMD_FORMAT);
        (_a = formRef === null || formRef === void 0 ? void 0 : formRef.current) === null || _a === void 0 ? void 0 : _a.setFieldValue(name, date, true);
    };
    const onSubmit = (values, formikActions) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        formikActions.setSubmitting(true);
        let nextRates = null;
        if (model) {
            const { laborRates } = model;
            if (laborRates && laborRates.length > 0) {
                const nextIndex = laborRates.findIndex(({ id }) => (laborRate === null || laborRate === void 0 ? void 0 : laborRate.id) === id) + 1;
                nextRates = laborRates[nextIndex];
            }
            if (nextRates) {
                const isAfter = moment(values.effectiveFrom).isAfter(moment(nextRates.effectiveTo), 'day');
                if (!isAfter) {
                    formikActions.setFieldError('effectiveFrom', 'Effective From must be after previous Labor Rates Effective To date.');
                    formikActions.setSubmitting(false);
                    return;
                }
            }
            const castValues = __rest(laborRatesSchema.cast(values), []);
            if (!laborRate) {
                if (!metro) {
                    yield dispatch(updateRegion(Object.assign(Object.assign({}, regionModel), { laborRates: [
                            Object.assign(Object.assign({}, castValues), { regionId: model.id }),
                        ] }), true));
                }
                else {
                    yield dispatch(updateMetro(Object.assign(Object.assign({}, metroModel), { laborRates: [
                            Object.assign(Object.assign({}, castValues), { metroId: model.id }),
                        ] }), true));
                }
            }
            else {
                const updateRes = yield dispatch(updateLaborRate(laborRate.id, castValues));
                if (updateRes.error) {
                    formikActions.setSubmitting(false);
                    if ((_b = (_a = updateRes === null || updateRes === void 0 ? void 0 : updateRes.payload) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.message.includes('overlapping')) {
                        formikActions.setFieldError('effectiveTo', 'Date overlaps with another Labor Rates Effective To date.');
                    }
                    return;
                }
            }
            const result = yield dispatch(getById(model.id));
            dispatch(ActionCreators.SLIDE_PANEL[!metro ? 'UPDATE_REGION' : 'UPDATE_METRO']({
                payload: result.payload,
            }));
            setSlide(previousPage());
            return;
        }
        if (!laborRate) {
            dispatch(ActionCreators.LABOR_RATES.ADD({
                payload: Object.assign({ id: uniqueId('tempLaborRate_') }, laborRatesSchema.cast(values)),
            }));
            setSlide(previousPage());
            return;
        }
        dispatch(ActionCreators.LABOR_RATES.UPDATE({
            payload: laborRatesSchema.cast(values),
        }));
        setSlide(previousPage());
    });
    return {
        dispatch,
        regionModel,
        metroModel,
        laborRate,
        laborRateEditor,
        handleEuChange,
        labelFn,
        handleDateChange,
        onSubmit,
        model,
        getById,
        disabledEffectiveFrom,
        disabledEffectiveTo,
    };
};
export default useLaborRatesPage;
