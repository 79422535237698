import React, { useEffect, useState } from 'react';
import { ErrorIconButton } from 'src/styles/styled';
import { Grid, MenuItem, TextField } from 'src/components/mui';
import { AddCircle, DeleteForever } from 'src/components/mui/icons';
import { StyledDrawerRow } from 'src/AdminApp/components/repairs/statement/Repairs/Drawers/EditPartDrawer/EditPartDrawer.styles';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import PartAdditionalInfoOptions from 'src/AdminApp/components/repairs/statement/Repairs/Drawers/EditPartDrawer/PartAdditionalInfoOptions';
import FluidAdditionalInfoOptions from 'src/AdminApp/components/repairs/statement/Repairs/Drawers/EditFluidDrawer/FluidAdditionalInfoOptions';
import SimpleModal from 'src/components/SimpleModal';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
const AddComponentAdditionalInfo = ({ open, onClose, isFluid, values, setFieldValue, canEdit = true, }) => {
    const [additionalInfo, setAdditionalInfo] = useState(values);
    useEffect(() => {
        setAdditionalInfo(values);
    }, [values]);
    useEffect(() => {
        if ((additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.length) === 0 || !additionalInfo) {
            setAdditionalInfo([{ name: '', value: '' }]);
        }
    }, [additionalInfo]);
    const addField = () => {
        setAdditionalInfo([...additionalInfo, { name: '', value: '' }]);
    };
    const removeField = (name) => {
        if (name !== undefined && name !== '') {
            setAdditionalInfo(additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.filter((info) => info.name !== name));
        }
    };
    const updateName = (index, name) => {
        setAdditionalInfo(additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.map((field, idx) => {
            if (idx === index) {
                return Object.assign(Object.assign({}, field), { name });
            }
            return field;
        }));
    };
    const updateValue = (index, value) => {
        setAdditionalInfo(additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.map((field, idx) => {
            if (idx === index) {
                return Object.assign(Object.assign({}, field), { value });
            }
            return field;
        }));
    };
    const usedFields = additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.map((info) => info === null || info === void 0 ? void 0 : info.name);
    return (<SimpleModal title="Additional Info" open={open} onClose={onClose} fullWidth maxWidth="md">
      <Grid container spacing={1}>
        {additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.map(({ value, name }, index) => {
            var _a;
            return (<StyledDrawerRow>
            <Grid item xs={5}>
              <TextField variant="outlined" value={name} select name="name" label="Name" onChange={(event) => {
                    updateName(index, event.target.value);
                }} disabled={!canEdit} style={{ width: '100%' }}>
                <MenuItem value="">&nbsp;</MenuItem>
                {(_a = Object.keys(isFluid
                    ? FluidAdditionalInfoOptions.values
                    : PartAdditionalInfoOptions.values)) === null || _a === void 0 ? void 0 : _a.map((item, index) => (<MenuItem key={index} value={item} disabled={usedFields === null || usedFields === void 0 ? void 0 : usedFields.includes(item)}>
                    {isFluid
                        ? FluidAdditionalInfoOptions.display(item)
                        : PartAdditionalInfoOptions.display(item)}
                  </MenuItem>))}
              </TextField>
            </Grid>
            <Grid item xs={5}>
              <TextField variant="outlined" type="text" label="Value" placeholder="" value={value} onChange={(event) => {
                    updateValue(index, event.target.value);
                }} disabled={!canEdit} inputProps={{ maxLength: 32 }}/>
            </Grid>
            <ErrorIconButton onClick={() => removeField(name)}>
              <DeleteForever />
            </ErrorIconButton>
          </StyledDrawerRow>);
        })}
        {(additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.length) < 20 && (<SimpleRowButton color="primary" onClick={() => addField()}>
            <AddCircle />
            Add New Field
          </SimpleRowButton>)}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '24px' }}>
        <SimpleTwoButtonRow submitText="Save" onCancel={() => {
            setAdditionalInfo(values);
            onClose();
        }} onSubmit={() => {
            setFieldValue('additionalInfo', additionalInfo);
            onClose();
        }}/>
      </Grid>
    </SimpleModal>);
};
export default AddComponentAdditionalInfo;
