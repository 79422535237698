import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectPopsManualOrderViewer } from 'src/modules/auth/selectors';
import { selectDeliveryVanByIspId } from 'src/AdminApp/modules/deliveryVans/selectors';
import { selectJobs, selectJobsPagination, selectJobsStats, selectVan, } from 'src/PopsApp/modules/parts/selectors';
import { getJobsByVan, getJobsStats, getShopById, setOrderParts, setSelectedDistributor, } from 'src/PopsApp/modules/parts/actions';
import { getDeliveryVanByIspId } from 'src/AdminApp/modules/deliveryVans/actions';
import SkeduloResourceTag from 'src/AdminApp/models/enums/SkeduloResourceTag';
const useJob = () => {
    var _a, _b;
    const dispatch = useDispatch();
    const [allChecked, setAllChecked] = useState(false);
    const [selectedJobs, setSelectedJobs] = useState({});
    const [partsSelectedCount, setPartsSelectedCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const { ispId } = useParams();
    const history = useHistory();
    const canViewManualOrder = useSelector(selectPopsManualOrderViewer);
    const jobs = useSelector(selectJobs);
    const jobsPagination = useSelector(selectJobsPagination);
    const vanShop = useSelector(selectVan);
    const { totalComponents } = useSelector(selectJobsStats);
    const deliveryVan = useSelector(selectDeliveryVanByIspId((_a = vanShop === null || vanShop === void 0 ? void 0 : vanShop.id) !== null && _a !== void 0 ? _a : 0));
    useEffect(() => {
        if (!canViewManualOrder) {
            history.push('/pops/');
        }
        dispatch(getJobsByVan({ ispId, page, size: rowsPerPage }));
        dispatch(getShopById(ispId));
        dispatch(getJobsStats(ispId));
        dispatch(setOrderParts([]));
        dispatch(setSelectedDistributor(undefined));
        dispatch(getDeliveryVanByIspId(ispId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!jobs || jobs.length === 0) {
            return;
        }
        // IS EVERYTHING SELECTED ON THIS PAGE?
        let foundAll = true;
        jobs.forEach((job) => {
            if (!selectedJobs[job.referenceNum]) {
                foundAll = false;
            }
        });
        // MARK SELECTED ALL IF THEY ARE ALL INDEED SELECTED ALREADY
        setAllChecked(foundAll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, jobs]);
    const handleCheckAll = () => {
        if (!allChecked) {
            const allSelectedJobs = Object.assign({}, selectedJobs);
            let partsCount = partsSelectedCount;
            jobs.forEach((job) => {
                if ((job.partsStatus === 'PENDING' ||
                    job.partsStatus === 'WRONG_PART' ||
                    job.partsStatus === 'ORDER_IN_PROGRESS' ||
                    job.partsStatus === 'ORDER_FAILED' ||
                    job.partsStatus === 'PARTIALLY_ORDERED') &&
                    job.componentsNeeded > 0 &&
                    !selectedJobs[job.referenceNum]) {
                    allSelectedJobs[job.referenceNum] = job;
                    partsCount += job.componentsNeeded;
                }
            });
            setSelectedJobs(allSelectedJobs);
            setPartsSelectedCount(partsCount);
        }
        else {
            let newPartsSelectCount = partsSelectedCount;
            const allSelectedJobs = Object.assign({}, selectedJobs);
            jobs.forEach((job) => {
                if (selectedJobs[job.referenceNum]) {
                    delete allSelectedJobs[job.referenceNum];
                    newPartsSelectCount -= job.componentsNeeded;
                }
            });
            setSelectedJobs(allSelectedJobs);
            setPartsSelectedCount(newPartsSelectCount);
        }
        setAllChecked(!allChecked);
    };
    const handleItemSelected = (job) => {
        if (job.componentsNeeded === 0) {
            return false;
        }
        if (!selectedJobs[job.referenceNum]) {
            const newSelectedJobs = Object.assign({}, selectedJobs);
            newSelectedJobs[job.referenceNum] = job;
            setSelectedJobs(newSelectedJobs);
            setPartsSelectedCount(partsSelectedCount + job.componentsNeeded);
        }
        else {
            const newSelectedJobs = Object.assign({}, selectedJobs);
            delete newSelectedJobs[job.referenceNum];
            setSelectedJobs(newSelectedJobs);
            setPartsSelectedCount(partsSelectedCount - job.componentsNeeded);
            setAllChecked(false);
        }
    };
    const handleChangePage = (event, newPage) => {
        dispatch(getJobsByVan({ ispId, page: newPage, size: rowsPerPage }));
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        const newRows = parseInt(event.target.value, 10);
        dispatch(getJobsByVan({ ispId, page: 0, size: newRows }));
        setRowsPerPage(newRows);
        setPage(0);
    };
    const hasDeliveryToHubSkill = ((_b = deliveryVan === null || deliveryVan === void 0 ? void 0 : deliveryVan.skills) !== null && _b !== void 0 ? _b : []).some((item) => item.name === SkeduloResourceTag.DELIVERIES_TO_HUB);
    const repairSmithHubHomeStore = () => {
        var _a;
        const homeStoreItem = ((_a = deliveryVan === null || deliveryVan === void 0 ? void 0 : deliveryVan.deliveryVanHomeStores) !== null && _a !== void 0 ? _a : []).find((item) => item.partDistributorName === 'RepairSmith Hub');
        if (!homeStoreItem) {
            return null;
        }
        return homeStoreItem === null || homeStoreItem === void 0 ? void 0 : homeStoreItem.partDistributorAddress;
    };
    return {
        dispatch,
        allChecked,
        setAllChecked,
        selectedJobs,
        setSelectedJobs,
        partsSelectedCount,
        setPartsSelectedCount,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        jobs,
        jobsPagination,
        vanShop,
        totalComponents,
        deliveryVan,
        canViewManualOrder,
        ispId,
        handleCheckAll,
        handleItemSelected,
        handleChangePage,
        handleChangeRowsPerPage,
        hasDeliveryToHubSkill,
        repairSmithHubHomeStore,
    };
};
export default useJob;
