import React, { useState } from 'react';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { SlidesContainer } from 'src/components/SimpleDrawerPaged';
import { setPaymentDrawerScreen, setPaymentDrawerPaymentSubtype, } from 'src/AdminApp/modules/paymentDrawer/actions';
import { selectPaymentDrawerWorkOrderId } from 'src/AdminApp/modules/paymentDrawer/selectors';
import { selectConsumerMethodsById } from 'src/AdminApp/modules/payments/selectors';
import { selectRequestContactInfoByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import SavedCardsForm from '../SavedCardsForm';
import SpreedlyForm from '../SpreedlyForm';
import usePaymentDrawer from '../usePaymentDrawer';
export const FlowWindow = styled.div `
  padding: 16px;
`;
const ChargeScreen = ({ closeDrawer }) => {
    const { referenceNum } = useParams();
    const dispatch = useDispatch();
    const [useAnotherCard, setUseAnotherCard] = useState(false);
    const { onTokenize, onPaymentExistingCard } = usePaymentDrawer({});
    const debouncedTokenize = debounce(onTokenize, 500);
    const contactInfoSelector = useSelector(selectRequestContactInfoByReferenceNum);
    const contactInfo = contactInfoSelector(referenceNum);
    const consumerMethodsSelector = useSelector(selectConsumerMethodsById);
    const consumerMethods = consumerMethodsSelector(contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.consumerId);
    const workOrderId = useSelector(selectPaymentDrawerWorkOrderId);
    const handleAltFlow = () => {
        dispatch(setPaymentDrawerScreen('paymentType'));
        dispatch(setPaymentDrawerPaymentSubtype(null));
    };
    return (<SlidesContainer $hasTab={false}>
      <FlowWindow>
        {!useAnotherCard && consumerMethods.length ? (<SavedCardsForm onSubmit={(setSubmitting, resetForm, token, methodType, id) => {
                onPaymentExistingCard({
                    setSubmitting,
                    resetForm,
                    token,
                    methodType,
                    id,
                    closeDrawer,
                    workOrderId: workOrderId,
                });
            }} altFlow={handleAltFlow} useAnotherScreen={() => {
                setUseAnotherCard(true);
            }}/>) : (<SpreedlyForm consumer={contactInfo} onTokenize={debouncedTokenize} useAnotherScreen={consumerMethods.length
                ? () => {
                    setUseAnotherCard(false);
                }
                : null} altFlow={handleAltFlow}/>)}
      </FlowWindow>
    </SlidesContainer>);
};
export default ChargeScreen;
