import React from 'react';
import { MenuItem, Select } from 'src/components/mui';
import { createMenuItems } from 'src/AdminApp/components/form/utils';
import { Editable } from 'src/components/form/components';
const EditableSelect = ({ children, defaultValue, displayValue, enumeration, isEditable = true, isMenuItemDisabled, onUpdate, options, selectStyle, style, unsetOption = true, unsetOptionText = ' ', value, }) => {
    var _a;
    const foundLabel = (_a = options === null || options === void 0 ? void 0 : options.find((option) => option.value === value)) === null || _a === void 0 ? void 0 : _a.label;
    const getItems = () => {
        if (options === null || options === void 0 ? void 0 : options.length) {
            return options.map((option) => (<MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>));
        }
        if (enumeration) {
            return createMenuItems(enumeration, true, isMenuItemDisabled);
        }
        return null;
    };
    return (<Editable isEditable={isEditable} displayValue={foundLabel || children || displayValue || value || defaultValue} style={style} renderForm={({ setIsEditing }) => (<Select open style={selectStyle} value={value} onChange={({ target }) => {
                onUpdate(target.value);
                setIsEditing(false);
            }} onClose={() => {
                setIsEditing(false);
            }}>
          {unsetOption && (<MenuItem value="" key="unset">
              {unsetOptionText}
            </MenuItem>)}

          {getItems()}
        </Select>)}/>);
};
export default EditableSelect;
