import React from 'react';
import styled from 'styled-components';
import { Box } from 'src/components/mui/';
import { Alert } from 'src/components/mui';
const StyledAlert = styled(Alert) `
  margin: 5px;
`;
const StyledSpan = styled.span `
  cursor: pointer;
  color: #2c56dd;
  text-decoration: underline;
`;
const ExtraWebhookInfoModule = ({ templateClickHandler }) => {
    return (<StyledAlert severity="info" elevation={1}>
      <Box>
        Webhook Templates:{' '}
        <StyledSpan onClick={() => templateClickHandler('job')}>
          Job Updated
        </StyledSpan>{' '}
        |{' '}
        <StyledSpan onClick={() => templateClickHandler('job-allocation')}>
          Job Allocation Updated
        </StyledSpan>
      </Box>
    </StyledAlert>);
};
export default ExtraWebhookInfoModule;
