var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import * as gql from 'gql-query-builder';
import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { mapClientShopInvoiceMatchArray } from 'src/AdminApp/modules/mappers';
import { downloadBase64 } from 'src/AdminApp/utils/downloadUtils';
import { jsonToCsvBase64 } from 'src/AdminApp/utils/csvUtils';
import { selectAccessToken } from 'src/modules/auth/selectors';
import { downloadSalesMatchInvoicePdf } from 'src/utils/downloadUtils';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
// FSA ACTIONS
export const ActionCreators = {
    SALES_MATCH: createDefaultActionCreators('SALES_MATCH', ['CLEAR']),
    SALES_MATCH_DELETE: createDefaultActionCreators('SALES_MATCH_DELETE'),
    SALES_MATCH_UPDATE: createDefaultActionCreators('SALES_MATCH_UPDATE'),
    SALES_MATCH_VERIFY_INVOICE: createDefaultActionCreators('SALES_MATCH_VERIFY_INVOICE'),
    SALES_MATCH_SET_FILTERS_PANEL: createDefaultActionCreators('SALES_MATCH_CHANGE_FILTERS_PANEL'),
    SALES_MATCH_SUMMARY_INFO: createDefaultActionCreators('SALES_MATCH_SUMMARY_INFO'),
    SALES_MATCH_EXPORT_CSV: createDefaultActionCreators('SALES_MATCH_EXPORT_CSV'),
};
export const getSalesMatch = (params) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState().admin.salesMatch;
    const sort = params.sort || state.dashboard.pagination.sort;
    const filters = Object.assign(Object.assign({}, state.dashboard.params.filters), params.filters);
    const startDate = moment(filters.start)
        .startOf('day')
        .format(DATE_YMD_FORMAT);
    const endDate = moment(filters.end).endOf('day').format(DATE_YMD_FORMAT);
    const query = gql.query([
        {
            operation: `clientShopInvoiceMatches(
        start: "${startDate}",
        end: "${endDate}",
        dateFilterType: ${filters.dateFilterType},
        showFilterType: ${filters.showFilterType},
        ${params.pageIndex ? `page: ${params.pageIndex},` : ''}
        ${params.pageSize ? `pageSize: ${params.pageSize},` : ''}
        ${filters.repairRequestStatus
                ? `repairRequestStatus: "${filters.repairRequestStatus}",`
                : ''}
        ${filters.repairRequestState
                ? `repairRequestState: "${filters.repairRequestState}",`
                : ''}
        ${filters.shopId ? `shopId: "${filters.shopId}",` : ''}
        ${sort ? `sort: "${sort},"` : ''}
        )`,
            fields: [
                {
                    clientShopInvoice: [
                        'key',
                        {
                            clientShop: ['name', 'id', 'ispId'],
                        },
                        'sourceId',
                        'invoiceNumber',
                        'totalOrder',
                        'totalLabor',
                        'totalTax',
                        'totalPaid',
                        'totalTireFee',
                        'totalHazWaste',
                        'completedAtLocal',
                        'repairRequestStatus',
                        'repairRequestState',
                        'id',
                    ],
                },
                'validationStatus',
                'repairItemsCount',
                'customerName',
                'plateNumber',
                'vin',
                'make',
                'trim',
                'year',
                'repairRequestId',
                'confidence',
                'id',
            ],
        },
        {
            operation: `clientShopInvoiceMatchSummaryInfo(
        start: "${startDate}",
        end: "${endDate}",
        dateFilterType: ${filters.dateFilterType},
        showFilterType: ${filters.showFilterType},
        )`,
            fields: [
                'totalShops',
                'discovered',
                'revenue',
                'repairRequestStatuses',
                'repairRequestStates',
                {
                    shops: ['id', 'name'],
                },
            ],
        },
    ]);
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.SALES_MATCH.FETCH({
                meta: () => ({
                    pageSize: params.pageSize,
                    pageIndex: params.pageIndex,
                    sort,
                    filters,
                }),
            }),
            ActionCreators.SALES_MATCH.SUCCESS({
                meta: () => ({
                    pageSize: params.pageSize,
                    pageIndex: params.pageIndex,
                    sort,
                    filters,
                }),
            }),
            ActionCreators.SALES_MATCH.FAILURE({
                meta: () => ({
                    pageSize: params.pageSize,
                    pageIndex: params.pageIndex,
                    sort,
                    filters,
                }),
            }),
        ],
        endpoint: API_URLS.GRAPHQL(),
        method: 'POST',
        body: query,
    }));
    const { errors } = response.payload;
    if (errors && errors[0]) {
        throw new Error(errors[0].message);
    }
    return response;
});
export const exportSalesMatchCsv = (params, saveAsFileName) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        const state = getState().admin.salesMatch;
        const filters = Object.assign(Object.assign({}, state.dashboard.params.filters), params.filters);
        const startDate = moment(filters.start)
            .startOf('day')
            .format(DATE_YMD_FORMAT);
        const endDate = moment(filters.end).endOf('day').format(DATE_YMD_FORMAT);
        const query = gql.query({
            operation: `clientShopInvoiceMatches(
        start: "${startDate}",
        end: "${endDate}",
        dateFilterType: ${filters.dateFilterType},
        showFilterType: ${filters.showFilterType}
        )`,
            fields: [
                {
                    clientShopInvoice: [
                        'key',
                        {
                            clientShop: ['name', 'id', 'ispId'],
                        },
                        'sourceId',
                        'invoiceNumber',
                        'totalOrder',
                        'totalLabor',
                        'totalTax',
                        'totalPaid',
                        'totalTireFee',
                        'totalHazWaste',
                        'completedAtLocal',
                        'repairRequestStatus',
                        'repairRequestState',
                        'id',
                    ],
                },
                'validationStatus',
                'repairItemsCount',
                'customerName',
                'plateNumber',
                'vin',
                'make',
                'trim',
                'year',
                'repairRequestId',
                'confidence',
                'id',
            ],
        });
        const response = yield dispatch(createAPIAction({
            types: [
                ActionCreators.SALES_MATCH_EXPORT_CSV.FETCH(),
                ActionCreators.SALES_MATCH_EXPORT_CSV.SUCCESS(),
                ActionCreators.SALES_MATCH_EXPORT_CSV.FAILURE(),
            ],
            endpoint: API_URLS.GRAPHQL(),
            method: 'POST',
            body: query,
        }));
        const { errors } = response.payload;
        if (errors && errors[0]) {
            throw new Error(errors[0].message);
        }
        else if (!response.error &&
            response.payload.data.clientShopInvoiceMatches) {
            const base64string = jsonToCsvBase64(mapClientShopInvoiceMatchArray((_c = (_b = (_a = response.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.clientShopInvoiceMatches) !== null && _c !== void 0 ? _c : []));
            downloadBase64(base64string, `${saveAsFileName}.csv`);
        }
        return response;
    });
};
export const getSalesMatchSummaryInfo = (params) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const state = getState().admin.salesMatch;
        const filters = Object.assign(Object.assign({}, state.dashboard.params.filters), params.filters);
        const startDate = moment(filters.start)
            .startOf('day')
            .format(DATE_YMD_FORMAT);
        const endDate = moment(filters.end).endOf('day').format(DATE_YMD_FORMAT);
        const query = gql.query({
            operation: `clientShopInvoiceMatchSummaryInfo(
        start: "${startDate}",
        end: "${endDate}",
        dateFilterType: ${filters.dateFilterType},
        showFilterType: ${filters.showFilterType},
        )`,
            fields: [
                'totalShops',
                'discovered',
                'revenue',
                {
                    shops: ['id', 'name'],
                },
            ],
        });
        const response = yield dispatch(createAPIAction({
            types: [
                ActionCreators.SALES_MATCH_SUMMARY_INFO.FETCH(),
                ActionCreators.SALES_MATCH_SUMMARY_INFO.SUCCESS(),
                ActionCreators.SALES_MATCH_SUMMARY_INFO.FAILURE(),
            ],
            endpoint: API_URLS.GRAPHQL(),
            method: 'POST',
            body: query,
        }));
        const { errors } = response.payload;
        if (errors && errors[0]) {
            throw new Error(errors[0].message);
        }
        return response;
    });
};
export const deleteSalesMatch = (salesMatchId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const mutation = gql.mutation({
        operation: `deleteClientShopInvoiceMatch(id: "${salesMatchId}")`,
    });
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.SALES_MATCH_DELETE.FETCH(),
            ActionCreators.SALES_MATCH_DELETE.SUCCESS({
                meta: () => ({ salesMatchId }),
            }),
            ActionCreators.SALES_MATCH_DELETE.FAILURE(),
        ],
        endpoint: API_URLS.GRAPHQL(),
        method: 'POST',
        body: mutation,
    }));
    const { errors } = response.payload;
    if (errors && errors[0]) {
        throw new Error(errors[0].message);
    }
    return response;
});
export const updateSalesMatchRequestId = (salesMatchId, repairRequestId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const mutation = gql.mutation({
        operation: `updateClientShopInvoiceMatch(id: "${salesMatchId}", model: {repairRequestId: "${repairRequestId}"})`,
        fields: ['id', 'repairRequestId'],
    });
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.SALES_MATCH_UPDATE.FETCH(),
            ActionCreators.SALES_MATCH_UPDATE.SUCCESS({
                meta: () => ({ salesMatchId, repairRequestId }),
            }),
            ActionCreators.SALES_MATCH_UPDATE.FAILURE(),
        ],
        endpoint: API_URLS.GRAPHQL(),
        method: 'POST',
        body: mutation,
    }));
    const { errors } = response.payload;
    if (errors && errors[0]) {
        throw new Error(errors[0].message);
    }
    return response;
});
export const verifySalesMatchInvoice = (salesMatchId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const mutation = gql.mutation({
        operation: `verifyInvoice(id: "${salesMatchId}")`,
        fields: ['id', 'validationStatus'],
    });
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.SALES_MATCH_VERIFY_INVOICE.FETCH(),
            ActionCreators.SALES_MATCH_VERIFY_INVOICE.SUCCESS({
                meta: () => ({ salesMatchId }),
            }),
            ActionCreators.SALES_MATCH_VERIFY_INVOICE.FAILURE(),
        ],
        endpoint: API_URLS.GRAPHQL(),
        method: 'POST',
        body: mutation,
    }));
    const { errors } = response.payload;
    if (errors && errors[0]) {
        throw new Error(errors[0].message);
    }
    return response;
});
export const setSalesMatchFiltersPanel = (filtersPanel) => (dispatch) => {
    dispatch({
        type: ActionCreators.SALES_MATCH_SET_FILTERS_PANEL.SUCCESS().type,
        meta: {
            filtersPanel,
        },
    });
};
export const downloadSalesMatchInvoice = (salesMatchId, invoiceNumber) => (dispatch, getState) => {
    const accessToken = selectAccessToken(getState());
    return downloadSalesMatchInvoicePdf(salesMatchId, invoiceNumber, accessToken);
};
