import React from 'react';
import { ActionsBlock } from 'src/containers/layout/ActionsBlock';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import useMarketingSourcesDashboard from 'src/AdminApp/containers/marketingSources/MarketingSourcesDashboard/useMarketingSourcesDashboard';
import { Select, MenuItem, InputLabel, FormControl } from 'src/components/mui';
import ListItem from './ListItem';
import MarketingSourcesDrawer from '../MarketingSourcesDrawer';
const REQUEST_COLUMNS = [
    { title: 'Display Name', key: 'displayName', sortable: false },
    { title: 'Visibility', key: 'visibility', sortable: false },
    { title: 'Prevent Fee Codes', key: 'preventFeeCodes', sortable: false },
    { title: 'Created Date', key: 'created', sortable: false },
    { title: 'Created By', key: 'createdBy', sortable: false },
    { title: 'Modified Date', key: 'modified', sortable: false },
    { title: 'Modified By', key: 'modifiedBy', sortable: false },
    { title: 'Actions', key: 'actions', sortable: false },
];
const MarketingSourcesDashboard = () => {
    const { marketingSources, onLoadData, pagination, isFetching, setPageSize, handleListItemClick, handleEditorClose, handleSourceAdd, handleVisibilityChange, editDrawerOpen, currentId, visibility, } = useMarketingSourcesDashboard();
    const dashboardActions = [
        {
            function: handleSourceAdd,
            disabled: false,
            tooltip: undefined,
            copy: 'Add Marketing Source',
            isCancelAction: false,
            hasBorderTop: false,
        },
    ];
    return (<>
      <ActionsBlock actions={dashboardActions}/>
      <MarketingSourcesDrawer open={editDrawerOpen} onClose={handleEditorClose} marketingSourceId={currentId}/>
      <div>
        <FormControl fullWidth>
          {' '}
          <InputLabel id="visibility-select-label" style={{ padding: '0 8px', backgroundColor: 'white' }}>
            Visibility
          </InputLabel>
          <Select labelId="visibility-select-label" onChange={(e) => handleVisibilityChange(e.target.value)} style={{ maxWidth: '250px', marginBottom: '20px' }} value={visibility}>
            <MenuItem value="-1">All</MenuItem>
            <MenuItem value="1">Public</MenuItem>
            <MenuItem value="2">Admin</MenuItem>
          </Select>
        </FormControl>
      </div>
      <SimpleTable data={marketingSources !== null && marketingSources !== void 0 ? marketingSources : []} columns={REQUEST_COLUMNS} pagination={pagination} isFetching={isFetching} onLoadData={onLoadData} onRowsPerPageChange={setPageSize} renderItem={(item, index) => (<ListItem key={`${index}-ms`} item={item} onItemClick={(id) => {
                handleListItemClick(id);
            }}/>)} sort={[]}/>
    </>);
};
export default MarketingSourcesDashboard;
