import { colors, theme } from 'src/styles/theme';
import styled, { css } from 'styled-components';
const baseDrawerFooter = css `
  padding: 24px 20px 20px 20px;
  background: ${colors.white};
`;
export const SimpleDrawerFooter = styled.div `
  ${baseDrawerFooter};
  width: 100%;
  z-index: 10000;
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > :not(:last-child) {
    margin-right: 16px;
  }

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;

    > :not(:first-child) {
      margin-top: 16px;
    }

    > :not(:last-child) {
      margin-right: 0;
    }
  }
`;
