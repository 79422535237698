export const getCapacityUnitAbbreviation = (unit) => {
    const keys = {
        quart: 'qt',
        ml: 'ml',
        ounce: 'oz',
        gram: 'g',
        liter: 'l',
    };
    if (!unit)
        return 'N/A';
    return keys[unit.toLowerCase()] ? keys[unit.toLowerCase()] : 'N/A';
};
