import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { ActionCreators, setFilters, setPartiesSort } from './actions';
const DEFAULT_STATE = {
    parties: {
        params: {},
        pagination: {
            pageNumber: 0,
            pageSize: 20,
            totalElements: 0,
            totalPages: 0,
        },
    },
    partyTypes: [],
    partiesSort: ['name,asc'],
    partiesFilters: {},
};
const reducers = handleActions({
    [ActionCreators.GET_PARTIES.SUCCESS.type]: createSimpleHandler('parties'),
    [ActionCreators.GET_PARTY_TYPES.SUCCESS.type]: createSimpleHandler('partyTypes'),
    [setPartiesSort.type]: createSimpleHandler('partiesSort'),
    [setFilters.type]: createSimpleHandler('partiesFilters'),
}, DEFAULT_STATE);
export default reducers;
