import React from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { Button, Grid } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
const BigButton = styled(Button) `
  height: 100%;
`;
const RepairsDashboardSearch = ({ onSearch, filters }) => {
    return (<Formik initialValues={{
            name: filters.name || '',
        }} onSubmit={(data, { setSubmitting }) => {
            const values = Object.assign({}, data);
            onSearch(values);
            setSubmitting(false);
        }}>
      {({ isSubmitting }) => (<Form>
          <Grid container spacing={2}>
            <Grid item xs={10} md={10}>
              <TextField placeholder="Repair name" label="Search Query" name="name" variant="outlined" autoFocus fullWidth/>
            </Grid>
            <Grid item xs={2}>
              <BigButton size="small" variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting}>
                Search
              </BigButton>
            </Grid>
          </Grid>
        </Form>)}
    </Formik>);
};
export default RepairsDashboardSearch;
