import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { mapDeliveryVan } from 'src/AdminApp/modules/mappers';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    slidePanelOpen: false,
    homeStoresSlidePanelOpen: false,
    byId: {},
    byIspId: {},
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['id,asc'],
        },
    },
    autocomplete: [],
};
const deliveryVansSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { autocomplete: [], dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const deliveryVansFetchReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const deliveryVansClearReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const deliveryVanSuccessReducer = (state, action) => {
    const model = mapDeliveryVan(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [model.id]: fetched(model) }) });
};
const deliveryVanByIspSuccessReducer = (state, action) => {
    const model = mapDeliveryVan(action.payload);
    return Object.assign(Object.assign({}, state), { byIspId: Object.assign(Object.assign({}, state.byIspId), { [model.ispId]: fetched(model) }) });
};
const deliveryVansAutocompleteSuccessReducer = (state, action) => {
    const results = action === null || action === void 0 ? void 0 : action.payload;
    return Object.assign(Object.assign({}, state), { autocomplete: results });
};
const deliveryVansAddSuccessReducer = (state, action) => {
    const model = mapDeliveryVan(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [model.id]: Object.assign(Object.assign({}, state.byId[model.id]), model) }) });
};
const deliveryVansUpdateSuccessReducer = (state, action) => {
    const { id } = action.meta;
    const model = mapDeliveryVan(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [model.id]: Object.assign(Object.assign({}, state.byId[id]), model) }) });
};
const openSlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { slidePanelOpen: true });
};
const openHomeStoresSlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { homeStoresSlidePanelOpen: true });
};
const closeSlidePanel = (state) => (Object.assign(Object.assign({}, state), DEFAULT_STATE));
export const deliveryVansReducers = handleActions({
    [ActionCreators.DELIVERY_VANS.SUCCESS.type]: deliveryVansSuccessReducer,
    [ActionCreators.DELIVERY_VANS.FETCH.type]: deliveryVansFetchReducer,
    [ActionCreators.DELIVERY_VANS.CLEAR.type]: deliveryVansClearReducer,
    [ActionCreators.DELIVERY_VAN.SUCCESS.type]: deliveryVanSuccessReducer,
    [ActionCreators.DELIVERY_VAN_BY_ISP.SUCCESS.type]: deliveryVanByIspSuccessReducer,
    [ActionCreators.DELIVERY_VANS_AUTOCOMPLETE.SUCCESS.type]: deliveryVansAutocompleteSuccessReducer,
    [ActionCreators.ADD_DELIVERY_VAN.SUCCESS.type]: deliveryVansAddSuccessReducer,
    [ActionCreators.ADD_DELIVERY_VAN.SUCCESS.type]: closeSlidePanel,
    [ActionCreators.UPDATE_DELIVERY_VAN.SUCCESS.type]: deliveryVansUpdateSuccessReducer,
    [ActionCreators.UPDATE_DELIVERY_VAN.SUCCESS.type]: closeSlidePanel,
    [ActionCreators.SLIDE_PANEL_DELIVERY_VAN.OPEN.type]: openSlidePanel,
    [ActionCreators.SLIDE_PANEL_DELIVERY_VAN.CLOSE.type]: closeSlidePanel,
    [ActionCreators.SLIDE_PANEL_DELIVERY_VAN_HOME_STORES.OPEN.type]: openHomeStoresSlidePanel,
    [ActionCreators.SLIDE_PANEL_DELIVERY_VAN_HOME_STORES.CLOSE.type]: closeSlidePanel,
}, DEFAULT_STATE);
