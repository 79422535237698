import React from 'react';
import styled from 'styled-components';
import SimplePaginationControls from 'src/components/dashboard/SimpleTable/SimplePaginationControls';
import { IconButton } from 'src/components/mui';
import { FirstPage as FirstPageIcon, KeyboardArrowLeft, KeyboardArrowRight, LastPage as LastPageIcon, } from 'src/components/mui/icons';
const ActionsWrapper = styled.div `
  display: flex;
`;
const SimpleTablePaginationActions = ({ onPageChange, count, page, rowsPerPage, }) => {
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (<ActionsWrapper>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
        <KeyboardArrowLeft />
      </IconButton>
      <SimplePaginationControls onPageSelect={onPageChange} count={count} page={page} rowsPerPage={rowsPerPage}/>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Last Page">
        <LastPageIcon />
      </IconButton>
    </ActionsWrapper>);
};
export default SimpleTablePaginationActions;
