import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const AssetsContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: ${({ hasAssets }) => (hasAssets ? 'flex-start' : 'center')};
  align-items: ${({ hasAssets }) => (hasAssets ? 'flex-start' : 'center')};
  flex-wrap: wrap;
  padding: 21px 21px 90px;
  position: relative;
  max-height: 550px;
  overflow-y: scroll;
  background-color: ${colors.GRAY_FA};
`;
export const AssetsActions = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 14px 20px;
  border-top: solid 1px ${colors.GRAY_DE};

  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: white;

  & button {
    margin-left: 20px;
    padding: 12px 42px;
    font-size: 16px;
  }
`;
