import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useDebounce from 'src/modules/api/utils/useDebounce';
const useAutocompleteInput = (value, disabled, autocompleteFn) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState(value);
    const debouncedInputValue = useDebounce(inputValue, 500);
    useEffect(() => {
        if (disabled) {
            return;
        }
        if (inputValue === undefined && debouncedInputValue === undefined) {
            // component is rendered with no initial value (i.e. selecting a new item)
            // TODO: cache the default autocomplete values locally so that we don't need to call the API every time a new component is loaded
            dispatch(autocompleteFn());
            return;
        }
        if (debouncedInputValue !== undefined &&
            typeof debouncedInputValue === 'string' &&
            (value === null || value === void 0 ? void 0 : value.name) !== debouncedInputValue) {
            // user has manually entered characters into the autocomplete field to initiate a search
            // TODO: implement an option to use client-side autocomplete rather than fetching from API
            dispatch(autocompleteFn(debouncedInputValue));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedInputValue, value, autocompleteFn, dispatch]);
    const onInputChange = useCallback((_, value, reason) => {
        if (reason === 'input') {
            setInputValue(value);
        }
    }, [setInputValue]);
    return {
        onInputChange,
    };
};
export default useAutocompleteInput;
