import { InputAdornment, Tab, Tabs } from 'src/components/mui';
import { SearchRounded as SearchRoundedIcon } from 'src/components/mui/icons';
import pluralize from 'pluralize';
import React from 'react';
import { SimpleModal } from 'src/components/layout';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import VisitCapacityWarningModal from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal/VisitCapacityWarningModal';
import { AddedRepairsCount, LoadingSection, LoadingSkeleton, SearchInput, SearchSection, ToolsSection, } from 'src/FleetVisitsApp/pages/VisitView/AddExistingRepairOrdersDrawer/AddRepairOrdersDrawer.styles';
import RepairOrdersStack from 'src/FleetVisitsApp/pages/VisitView/AddExistingRepairOrdersDrawer/RepairOrdersStack';
import useAddExistingRepairOrdersDrawer from 'src/FleetVisitsApp/pages/VisitView/AddExistingRepairOrdersDrawer/useAddExistingRepairOrdersDrawer';
import LaborCapacityBar from 'src/FleetVisitsApp/pages/VisitView/LaborCapacityBar';
import RepairOrdersTable from './RepairOrdersTable';
import ConfirmAddingModal from './RepairOrdersTable/ConfirmAddingModal';
const AddExistingRepairOrdersDrawer = ({ open, onClose, visit, }) => {
    var _a, _b;
    const { selectedTab, searchedRepairOrders, repairOrders, selectedRepairOrders, handleClose, onTabChange, isLoading, handleSearchChange, searchTerm, isDesktop, addRepairOrder, selectedRepairOrdersSet, isRoReadyToAdd, handleAddROs, isSubmitting, isPartsResetModalOpen, setIsPartsResetModalOpen, handleSubmit, addToVisitDialog, confirmAddingModalProps, showCapacityWarning, handleCapacityWarningClose, warningMessage, totalAvailableHours, totalScheduledHours, totalRemainingHours, } = useAddExistingRepairOrdersDrawer({ open, onClose, visit });
    let repairsData = [];
    if (selectedTab === 0) {
        repairsData = (_a = searchedRepairOrders !== null && searchedRepairOrders !== void 0 ? searchedRepairOrders : repairOrders) !== null && _a !== void 0 ? _a : [];
    }
    else {
        repairsData = (_b = searchedRepairOrders !== null && searchedRepairOrders !== void 0 ? searchedRepairOrders : selectedRepairOrders) !== null && _b !== void 0 ? _b : [];
    }
    return (<>
      <SimpleDrawer open={open} onClose={handleClose} title="Select Existing ROs" size="threeFourths">
        <SimpleDrawerContent>
          <Tabs value={selectedTab} onChange={onTabChange} indicatorColor="primary">
            <Tab label="Browse Repair Orders"/>
            <Tab label={<div>
                  Added Repairs{' '}
                  {selectedRepairOrders.length > 0 && (<AddedRepairsCount>
                      {selectedRepairOrders.length}
                    </AddedRepairsCount>)}
                </div>}/>
          </Tabs>

          <ToolsSection>
            {visit && (<div className="capacityWrapper">
                <LaborCapacityBar totalAvailableHours={totalAvailableHours} totalRemainingHours={totalRemainingHours} totalScheduledHours={totalScheduledHours}/>
              </div>)}

            <SearchSection>
              <SearchInput fullWidth type="search" disabled={isLoading} variant="outlined" placeholder="Search by Fleet or VIN" onChange={handleSearchChange} InputProps={{
            startAdornment: (<InputAdornment position="start">
                      <SearchRoundedIcon />
                    </InputAdornment>),
        }} value={searchTerm}/>
            </SearchSection>
          </ToolsSection>

          {isDesktop && !isLoading && (<RepairOrdersTable repairOrders={repairsData} addRepairOrder={addRepairOrder} selectedSet={selectedRepairOrdersSet} isRoReadyToAdd={isRoReadyToAdd}/>)}
          {isLoading && (<LoadingSection>
              <LoadingSkeleton variant="rectangular"/>
              <LoadingSkeleton variant="rectangular"/>
              <LoadingSkeleton variant="rectangular"/>
              <LoadingSkeleton variant="rectangular"/>
              <LoadingSkeleton variant="rectangular"/>
              <LoadingSkeleton variant="rectangular"/>
            </LoadingSection>)}

          {!isDesktop && !isLoading && (<RepairOrdersStack repairOrders={repairsData} addRepairOrder={addRepairOrder} selectedSet={selectedRepairOrdersSet} isRoReadyToAdd={isRoReadyToAdd}/>)}
        </SimpleDrawerContent>
        <SimpleDrawerFooter>
          <FooterActionButton onClick={handleAddROs} disabled={selectedRepairOrders.length === 0 || isSubmitting}>
            Add ({selectedRepairOrders.length}){' '}
            {pluralize('RO', selectedRepairOrders.length)}
          </FooterActionButton>
        </SimpleDrawerFooter>
      </SimpleDrawer>
      <SimpleModal title="You have Ordered Parts!" open={isPartsResetModalOpen} onClose={() => setIsPartsResetModalOpen(false)} simpleTwoButtonRowProps={{
            cancelText: 'No',
            submitText: 'Yes',
            onCancel: () => handleSubmit(false),
            onSubmit: () => handleSubmit(true),
        }}>
        Will parts need to be ordered again? Clicking Yes will reset Part Status
        to Needed
      </SimpleModal>

      <ConfirmAddingModal open={addToVisitDialog} onConfirm={() => confirmAddingModalProps.confirmAdding()} onCancel={() => confirmAddingModalProps.cancelAdding()}/>

      <VisitCapacityWarningModal open={showCapacityWarning} onClose={handleCapacityWarningClose} warningMessage={warningMessage}/>
    </>);
};
export default AddExistingRepairOrdersDrawer;
