import { useDispatch, useSelector } from 'react-redux';
import { selectAvailabilityLoading, selectSchedulingAvailability, } from 'src/AdminApp/modules/quickRo/selectors';
import { selectAddVisitTimeSlot } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { saveAddVisitTimeSlot } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
const useAvailabilityList = () => {
    const dispatch = useDispatch();
    const availability = useSelector(selectSchedulingAvailability);
    const isFetching = useSelector(selectAvailabilityLoading);
    const selectedTimeSlot = useSelector(selectAddVisitTimeSlot);
    const handleTimeSlotClick = (date, time, timeSlot) => {
        let params;
        if ((selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.time) === time && (selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.date) === date) {
            params = undefined;
        }
        else {
            params = { date, time, timeSlot };
        }
        dispatch(saveAddVisitTimeSlot(params));
    };
    return {
        dispatch,
        availability,
        isFetching,
        selectedTimeSlot,
        handleTimeSlotClick,
    };
};
export default useAvailabilityList;
