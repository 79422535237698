import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import OptimizationRunStatus from 'src/AdminApp/models/enums/OptimizationRunStatus';
export const ActionCreators = {
    OPTIMIZATION_RUNS: createDefaultActionCreators('OPTIMIZATION_RUNS', [
        'CLEAR',
        'CLEAR_FILTERS',
    ]),
    OPTIMIZATION_RUN: createDefaultActionCreators('OPTIMIZATION_RUN'),
    UPDATE_OPTIMIZATION_RUN: createDefaultActionCreators('UPDATE_OPTIMIZATION_RUN'),
};
export const getOptimizationRuns = ({ pageIndex, pageSize, sort = ['created,desc'], status = OptimizationRunStatus.ERROR, }) => {
    const params = Object.assign(Object.assign(Object.assign({}, (!!pageIndex && { page: pageIndex })), (!!pageSize && { size: pageSize })), (!!sort && { sort }));
    return createAPIAction({
        types: [
            ActionCreators.OPTIMIZATION_RUNS.FETCH({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.OPTIMIZATION_RUNS.SUCCESS({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.OPTIMIZATION_RUNS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.OPTIMIZATION_RUNS(),
        params: Object.assign(Object.assign({}, params), { status }),
        method: 'GET',
    });
};
export const updateOptimizationRun = (lock) => {
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_OPTIMIZATION_RUN.FETCH(),
            ActionCreators.UPDATE_OPTIMIZATION_RUN.SUCCESS({
                meta: () => ({ lock }),
            }),
            ActionCreators.UPDATE_OPTIMIZATION_RUN.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.OPTIMIZATION_RUN_BY_ID(lock.id),
        method: 'PATCH',
        body: lock,
    });
};
export const resetOptimizationRunsFilters = ActionCreators.OPTIMIZATION_RUNS.CLEAR_FILTERS;
export const clearOptimizationRunsResults = ActionCreators.OPTIMIZATION_RUNS.CLEAR;
