import React from 'react';
import { Toggle } from 'src/AdminApp/containers/fleets/tabs/styles';
import { Paper, Tab, TableContainer, Tabs } from 'src/components/mui';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import FormDivider from 'src/PricingApp/components/forms/FormDivider';
import FormInput from 'src/PricingApp/components/forms/FormInput';
import TemplatesDrawerAvailabilityTable from '../TemplatesDrawerAvailabilityTable';
import TemplatesDrawerUnavailabilityTable from '../TemplatesDrawerUnavailabilityTable';
import useTemplatesDrawerPage from './useTemplatesDrawerPage';
const DeliveryVanTemplatesPage = ({ model }) => {
    const { showInactiveOut, setShowInactiveOut, templateType, onEditTemplate, onEditUnavailability, handleTabChange, handleCreateNewTemplate, } = useTemplatesDrawerPage();
    const renderShowInactiveToggle = (showInactive) => (<>
      <FormInput label="Show Inactive">
        <Toggle checked={showInactive} color="primary" name="showInactive" onChange={(e) => {
            showInactive = e.target.checked;
            setShowInactiveOut(showInactive);
        }}/>
      </FormInput>
      <FormDivider />
    </>);
    return (<>
      <Tabs indicatorColor="primary" scrollButtons="auto" textColor="primary" value={templateType} onChange={handleTabChange}>
        <Tab value="availability" label="Availability"/>
        <Tab value="unavailability" label="Unavailability"/>
      </Tabs>
      <SimpleDrawerContent>
        {renderShowInactiveToggle()}
        <TableContainer component={Paper}>
          {templateType === 'availability' ? (<TemplatesDrawerAvailabilityTable model={model} onEditTemplate={onEditTemplate} showInactive={showInactiveOut}/>) : (<TemplatesDrawerUnavailabilityTable model={model} onEditUnavailability={onEditUnavailability} showInactive={showInactiveOut}/>)}
        </TableContainer>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={handleCreateNewTemplate}>
          Create New Template
        </FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default DeliveryVanTemplatesPage;
