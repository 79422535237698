export const CUSTOM_REPAIR_ID = 99999;
export const FMC_CUSTOM_REPAIR_ID = 99998;
export const DEFAULT_PARTS_TAX_RATE = 9.5;
export const EMAIL_RECIPIENTS = 'amanda@repairsmith.com, jr@repairsmith.com, adam@repairsmith.com, christopher@repairsmith.com, adrian@repairsmith.com ';
export const PART_VENDORS = [
    'AutoZone',
    'Customer Provided',
    'Dealer',
    'IMC',
    'LKQ',
    "O'Reily's",
    'WorldPac',
    'Other - Used',
    'RepairSmith Hubs',
    'Other',
];
export const DISCOUNT_LIMITS = {
    PROMO_CODE: 1,
    RO_CREDIT: 2,
    OTHER_DISCOUNT: 2,
    CANCEL_FEE_CREDIT: 1,
};
export const SEVERITY_SCALES = [
    {
        timeFrame: 'now',
        value: 'now',
    },
    {
        timeFrame: '30 days',
        value: '30_days',
    },
    {
        timeFrame: '60 days',
        value: '60_days',
    },
    {
        timeFrame: '6 months',
        value: '6_months',
    },
];
export const CAR_MSG = {
    MILEAGE_OUT_LESS_THAN_MILEAGE_IN: 'Miles Out must be greater or equal to Miles In',
    VIN_ALREADY_IN_DB: 'This VIN already exists',
    PLATE_ALREADY_IN_DB: 'This Plate already exists',
    VIN_CHARACTER_LENGTH: '*Required - VIN must be 17 characters',
    PLATE_CHARACTER_LENGTH: 'Plate must be at least 5 characters',
    VIN_NOT_EXIST: 'VIN does not exist.',
    VIN_CANNOT_BE_DECODED: 'VIN cannot be decoded. Add the vehicle details manually and continue.',
    PLATE_NOT_EXIST: 'Plate does not exist.',
    VEHICLE_EXISTS: 'Vehicle already exists in this account.',
};
export const VIN_LENGTH = 17;
export const PLATE_NUMBER_LENGTH = 5;
export const PLATE_MAX_NUMBER_LENGTH = 8;
export const REPAIR_COMPLETED_WITH_INVOICE_STATE = 'REPAIR_COMPLETED_WITH_INVOICE';
export const CAR_YEAR_LIMIT = 1995;
