import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, StaticDatePicker, TimePicker } from 'src/components/mui';
import { setAPIKeyValidTo } from 'src/PartnersAPIApp/modules/partnersApi/actions';
import { getAPIKeyValidTo } from 'src/PartnersAPIApp/modules/partnersApi/selectors';
import { getMomentValueNoUTCOffsetOr8am } from 'src/utils/formatter';
import { TIMESTAMP_WITH_OFFSET_FORMAT } from 'src/timeConstants';
import { PartnersAPICalendar } from './AddPartnersAPIDateTimePickerTo.styles';
const AddPartnersAPIDateTimePicker = ({ editDate, onChange, }) => {
    const dispatch = useDispatch();
    const selectedStartDateTime = useSelector(getAPIKeyValidTo);
    useEffect(() => {
        if (editDate) {
            dispatch(setAPIKeyValidTo(moment(editDate, TIMESTAMP_WITH_OFFSET_FORMAT)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleDateTimeChange = (value) => {
        if (!(value === null || value === void 0 ? void 0 : value.isValid())) {
            return;
        }
        dispatch(setAPIKeyValidTo(value));
        onChange();
    };
    const noop = () => {
        // Nothing to do here
    };
    return (<Grid container style={{ justifyContent: 'space-between' }}>
      <Grid item xs={12} sm={7}>
        <PartnersAPICalendar>
          <StaticDatePicker value={selectedStartDateTime} disablePast disableFuture={!!editDate} onChange={editDate ? noop : handleDateTimeChange} slotProps={{
            toolbar: {
                hidden: true,
            },
            day: {
                hidden: true,
            },
            actionBar: {
                hidden: true,
            },
        }}/>
        </PartnersAPICalendar>
      </Grid>
      <Grid item xs={12} sm={5}>
        <TimePicker label="Enter Start Time" value={getMomentValueNoUTCOffsetOr8am(selectedStartDateTime)} disabled={!!editDate} onChange={handleDateTimeChange} slotProps={{
            textField: {
                variant: 'outlined',
                margin: 'normal',
                id: 'partnersAPI-time-picker',
            },
            field: {
                clearable: true,
            },
        }}/>
      </Grid>
    </Grid>);
};
export default AddPartnersAPIDateTimePicker;
