import { colors, FONT_FAMILY } from 'src/styles/theme';
import styled from 'styled-components';
export const SectionHeader = styled.h2 `
  font-family: ${FONT_FAMILY};
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.38px;
  color: ${colors.GRAY_3F};
  margin: 0;
`;
export const AddPartnersAPIAc = styled.div `
  margin-top: 24px;

  .chip {
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: white;
    background-color: ${(props) => props.color === 'gray' ? colors.GRAY_3F : colors.BLUE};
  }

  .chip-delete-icon {
    color: ${(props) => props.color === 'gray' ? colors.GRAY_97 : colors.BLUE_MUTED};
  }
`;
