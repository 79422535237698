import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { Collapse } from 'src/components/mui';
import { KeyboardArrowDown } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
const HeaderWrapper = styled.div `
  position: relative;
  cursor: pointer;
`;
export const Arrow = styled(KeyboardArrowDown) `
  position: absolute;
  right: 0;
  top: calc(50% - 12px);
  color: ${({ $color }) => $color || colors.GRAY_CC};
  transition: 0.3s transform ease;

  ${({ $opened }) => `
    transform: rotate(${$opened ? '0deg' : '180deg'});
  `}
`;
const CollapsibleBlock = ({ header, children, hashName, defaultOpen = false, }) => {
    const [opened, setOpened] = useState(defaultOpen);
    const location = useLocation();
    useEffect(() => {
        setOpened(location.hash === `#${hashName}` || defaultOpen);
    }, [defaultOpen, hashName, location]);
    return (<div>
      <HeaderWrapper onClick={() => setOpened((v) => !v)}>
        {header}
        <Arrow $opened={opened}/>
      </HeaderWrapper>
      <Collapse in={opened}>{children}</Collapse>
    </div>);
};
export default CollapsibleBlock;
