import { Dialog, DialogContent } from 'src/components/mui';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
export const SimpleDialogContent = styled(DialogContent) `
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 24px */
  letter-spacing: 0.28px;
  padding: 0;
  overflow: visible;
  position: relative;
`;
export const ContentChildren = styled.div `
  margin-top: 16px;
`;
export const ConfirmModalBtnRow = styled.div `
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-items: stretch;
  margin-top: 24px;
  width: 100%;

  > :not(:last-child) {
    margin-right: 16px;
  }
`;
export const SimpleDialog = styled(Dialog) `
  div[class*='MuiDialog-paper'] {
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
    padding: 24px;
  }

  div[class*='MuiDialog-paperWidthSm'] {
    width: 343px;
  }

  div[class*='MuiDialog-paperWidthMd'] {
    width: 504px;
  }
`;
export const SimpleDialogActions = styled.section `
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  ${theme.breakpoints.up('sm')} {
    position: static;
    bottom: inherit;
    left: inherit;
    right: inherit;
  }
`;
