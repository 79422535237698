var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createRef, useState } from 'react';
import ReactJson from '@microlink/react-json-view';
import { Button, Grid } from 'src/components/mui';
import { useNetSuiteApiClient } from '../useNetSuiteApiClient';
const NetSuiteApiTester = () => {
    const [json, setJson] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const endpointSelector = createRef();
    const idInput = createRef();
    const netSuiteApiClient = useNetSuiteApiClient();
    const endpoints = new Map(Object.entries({
        'Customer': {
            fn: netSuiteApiClient.fetchCustomerById,
            example: 7847,
            validateId: true,
        },
        'Inventory Item': {
            fn: (id) => netSuiteApiClient.fetchInventoryItemById(id, true),
            example: 531,
            validateId: true,
        },
        'Fetch Customers': {
            fn: () => netSuiteApiClient.fetchCustomers(),
            validateId: false,
        },
        'Fetch Purchase Orders': {
            fn: () => netSuiteApiClient.fetchPurchaseOrders(),
            validateId: false,
        },
    }));
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const endpoint = (_a = endpointSelector === null || endpointSelector === void 0 ? void 0 : endpointSelector.current) === null || _a === void 0 ? void 0 : _a.value;
        const id = (_b = idInput === null || idInput === void 0 ? void 0 : idInput.current) === null || _b === void 0 ? void 0 : _b.value;
        if (endpoint) {
            setLoading(true);
            setError(null);
            setJson(null);
            try {
                const config = endpoints.get(endpoint);
                if ((config === null || config === void 0 ? void 0 : config.validateId) && !id) {
                    throw Error('Id is required');
                }
                const data = (config === null || config === void 0 ? void 0 : config.fn) && (yield config.fn(id));
                setJson(data);
            }
            catch (ex) {
                setError(ex.message);
                setJson(null);
            }
            finally {
                setLoading(false);
            }
        }
    });
    const reset = () => {
        if (idInput && idInput.current) {
            idInput.current.value = '';
        }
        setLoading(false);
        setJson(null);
        setError(null);
    };
    return (<Grid container style={{ justifyContent: 'space-between' }}>
      <Grid container>
        <h4>Lookup By ID</h4>
        <Grid container>
          <select ref={endpointSelector} style={{ padding: '0 5px', width: '250px' }}>
            {Array.from(endpoints.keys()).map((key) => {
            var _a;
            return (<option value={key} key={key}>
                {key} (ex: {(_a = endpoints.get(key)) === null || _a === void 0 ? void 0 : _a.example})
              </option>);
        })}
          </select>
          <input ref={idInput} type="text" placeholder="ID" style={{ padding: '0 5px', marginLeft: '5px' }}/>
          <Button onClick={submit}>Submit</Button>
          <Button onClick={reset}>Reset</Button>
        </Grid>
        {(loading || error || json) && (<>
            <h4>Results</h4>
            <Grid container direction="row">
              {loading && <div>loading...</div>}
              {error && <div style={{ color: 'red' }}>{error}</div>}
              {json && (<ReactJson src={json} name={false} collapsed={false} indentWidth={4} displayDataTypes={false} theme="bright:inverted" enableClipboard onEdit={false} onAdd={false} onDelete={false}/>)}
            </Grid>
          </>)}
      </Grid>
    </Grid>);
};
export default NetSuiteApiTester;
