import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from 'src/components/mui';
import Grid from 'src/PopsApp/components/Grid/Grid';
import GridItem from 'src/PopsApp/components/Grid/GridItem';
import useFilter, { getQueryString, } from 'src/PopsApp/pages/Region/components/useFilter';
import FilterDialog from 'src/PopsApp/components/VansFilter/FilterDialog';
import DesktopFilter from 'src/PopsApp/components/VansFilter/DesktopFilter';
import { vansIdSort } from 'src/PopsApp/modules/hooks/useFilter';
import { useSelector } from 'react-redux';
import { selectPopsManualOrderViewer } from 'src/modules/auth/selectors';
import { RegionContainer } from './Region.styles';
import SortWidget, { SortPopover } from './components/SortWidget';
const Region = () => {
    var _a;
    const history = useHistory();
    const canViewManualOrder = useSelector(selectPopsManualOrderViewer);
    useEffect(() => {
        if (!canViewManualOrder) {
            history.push('/pops/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [showControls, setShowControls] = useState(false);
    const { regions, metros, territories, components, dataProcessing, updateFilter, batchFilter, query, sortDirection, handleSortClose, sortAnchorEl, isSortOpen, handleSortClick, isFilterOpen, setFilterOpen, } = useFilter();
    useEffect(() => {
        setShowControls(!dataProcessing);
    }, [dataProcessing]);
    const changeFilterOpened = (state) => () => {
        setFilterOpen(state);
    };
    const itemClick = (id) => () => {
        history.push(`/pops/regions/${id}/vans?${getQueryString(query)}`);
    };
    return (<>
      <SortPopover pid={vansIdSort} isOpen={isSortOpen} anchor={sortAnchorEl} onClose={handleSortClose} value={sortDirection} onItemClick={(v) => {
            updateFilter('sort', `region,${v}`);
            handleSortClose();
        }}/>
      <FilterDialog isOpen={isFilterOpen} title="Filter" onClose={changeFilterOpened(false)} metros={metros} renderLocationFilter territories={territories} query={query} onApplyChanges={(f) => {
            batchFilter(f);
        }}/>
      <RegionContainer>
        <h1 className="regionsTitle">Select Region</h1>

        {showControls ? (<>
            <button type="button" className="regionsSort" onClick={handleSortClick}>
              Sort
            </button>
            <button type="button" className="regionsSort" onClick={changeFilterOpened(true)}>
              Filter
            </button>

            <div>
              <DesktopFilter renderLocationField query={query} territories={territories} metros={metros} components={components} onResetClick={() => {
                batchFilter({});
            }} onUpdate={updateFilter}/>
            </div>
            <SortWidget value={sortDirection} onItemClick={(v) => {
                updateFilter('sort', `region,${v}`);
            }}/>
          </>) : (<div className="regionsProgressWrap">
            <CircularProgress />
          </div>)}

        <Grid noItemsText="There are no regions for the applied filter" hasItems={!regions.length && !!((_a = query === null || query === void 0 ? void 0 : query.regionName) === null || _a === void 0 ? void 0 : _a.length)}>
          {regions.length ? (regions.map((r) => (<GridItem key={r.regionId} name={r.region} count={r.totalVans} count2Subtitle="Job" count2={r.totalJobs} subtitle="Van" specialOrder={r.specialOrder} onClick={itemClick(r.regionId)}/>))) : (<div className="emptyRegions">No regions for selected filter</div>)}
        </Grid>
      </RegionContainer>
    </>);
};
export default Region;
