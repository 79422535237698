var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from 'src/components/mui';
import { uploadPricingRulesCsv, uploadDynamicComponentRulesCsv, } from 'src/PricingApp/modules/utils/actions';
import { selectAuthorizedPricingAdmin } from 'src/modules/auth/selectors';
import NotAuthorized from 'src/components/layout/NotAuthorized';
import CollapsibleBlock from 'src/components/layout/CollapsibleBlock';
import Uploader from './components/Uploader';
const onValidateDynamicRules = (data) => {
    const errors = [];
    const total = data.length;
    let defected = 0;
    data.forEach((row, index) => {
        let defectFlag = false;
        if (!row.componentName) {
            errors.push({
                message: `Row ${index + 1}: componentName is required`,
            });
            defectFlag = true;
        }
        if (!row.repairName) {
            errors.push({
                message: `Row ${index + 1}: repairName is required`,
            });
            defectFlag = true;
        }
        if (!row.status) {
            errors.push({
                message: `Row ${index + 1}: status is required`,
            });
            defectFlag = true;
        }
        if (!['ACTIVE', 'INACTIVE'].includes(row.status)) {
            errors.push({
                message: `Row ${index + 1}: status is invalid`,
            });
            defectFlag = true;
        }
        if (!row.ruleType) {
            errors.push({
                message: `Row ${index + 1}: ruleType is required`,
            });
            defectFlag = true;
        }
        if (!['INCLUSION', 'EXCLUSION'].includes(row.ruleType)) {
            errors.push({
                message: `Row ${index + 1}: ruleType is invalid`,
            });
            defectFlag = true;
        }
        if (defectFlag) {
            defected += 1;
        }
    });
    return {
        detected: total,
        accepted: total - defected,
        errors,
    };
};
const PricingRulesUploader = () => {
    const dispatch = useDispatch();
    const isPricingAdmin = useSelector(selectAuthorizedPricingAdmin);
    const renderUploader = () => (<Grid container spacing={2} direction="column">
      <Grid item container direction="row" spacing={2} style={{ alignItems: 'flex-start' }}>
        <Uploader title="YMMR Component Pricing Rules" schemaDefinition={`year_from, year_to, car_brand, make, model, engine, repair_name,
            component_name, position, name, rule_category (DEFAULT or OVERRIDE),
            min_quantity, max_quantity, min_price, max_price, min_cost,
            max_cost, fixed_price ('true' or 'false'), fleet_id`} onSubmit={(csvFile) => __awaiter(void 0, void 0, void 0, function* () {
            yield dispatch(uploadPricingRulesCsv(csvFile));
        })}/>
        <Uploader title="Dynamic Rules Uploader" schemaDefinition="yearFrom, yearTo, carBrand(US, EU, ASIA), make, model, engine,
            repairName, componentName, name, ruleType(INCLUSION or EXCLUSION), status(ACTIVE or INACTIVE)," transformData onPrepareData={(data, fileName) => {
            return {
                dynamicComponentRules: data,
                fileName,
            };
        }} onValidate={onValidateDynamicRules} onSubmit={(data) => {
            return dispatch(uploadDynamicComponentRulesCsv(data));
        }}/>
      </Grid>

      <Grid item xs>
        <CollapsibleBlock hashName="csvRuleUploader" header={<Typography variant="h5" gutterBottom>
              Logic Matrix
            </Typography>} defaultOpen={false}>
          <h4> Uploading Default Rules </h4>
          <ul>
            <li>
              If there is an existing default rule, then uploading a new default
              rule will deactivate the existing default rule and the new default
              rule is used
            </li>
            <ul>
              <li>
                No fields from the existing default rule will be re-used in the
                new default rule unless included
              </li>
            </ul>
            <li>
              If there is an existing override rule, then uploading a new
              default rule will insert new default rule fields
            </li>
            <ul>
              <li>
                Any override rule fields will take precedence over any
                overlapping default rule fields
              </li>
            </ul>
          </ul>
          <h4>Uploading Override Rules</h4>
          <ul>
            <li>
              {' '}
              If there is an existing default rule, then uploading a new
              override rule will insert new override rule fields
            </li>
            <ul>
              <li>
                Any existing default rule fields will be deactivated if they
                overlap with any new override rule fields
              </li>

              <li>
                Any existing default rule fields not deactivated will be
                re-created automatically as a default rule included with the new
                override rule
              </li>
              <ul>
                <li>
                  Only occurs when a default rule has both quantity and cost
                  while you are only uploading an override rule with only
                  quantity or cost
                </li>
              </ul>
            </ul>
            <li>
              If there is an existing override rule, then uploading a new
              override rule will deactivate the existing override rule and the
              new override rule is used
            </li>
            <ul>
              <li>
                No fields from the existing override rule will be re-used in the
                new override rule unless included
              </li>
            </ul>
          </ul>
          <h4>Uploading Default Fixed Pricing Rules</h4>
          FP = Fixed Pricing
          <ul>
            <li>
              If there is an existing default FP rule, then uploading a new
              default FP rule will deactivate the existing default FP rule and
              the new default FP rule is used
            </li>
            <ul>
              <li>
                No fields from the existing default FP rule will be re-used in
                the new default FP rule unless included
              </li>
            </ul>
            <li>
              If there is an existing override FP rule, then uploading a new
              default FP rule will insert new default FP rule fields
            </li>
            <ul>
              <li>
                Any override FP rule fields will take precedence over any
                overlapping default FP rule fields
              </li>
            </ul>
          </ul>
          <h4>Uploading Override Fixed Pricing Rules</h4>
          FP = Fixed Pricing
          <ul>
            <li>
              If there is an existing default FP rule, then uploading a new
              override FP rule will insert new override FP rule fields
            </li>
            <ul>
              <li>
                Any existing default FP rule fields will be deactivated if they
                overlap with any new override FP rule fields
              </li>
              <li>
                Any existing default FP rule fields not deactivated will be
                re-created automatically as a default FP rule included with the
                new override rule
              </li>

              <ul>
                <li>
                  Only occurs when a default FP rule has both quantity and price
                  while you are only uploading an override FP rule with only
                  price
                </li>
              </ul>
            </ul>

            <li>
              If there is an existing override FP rule, then uploading a new
              override FP rule will deactivate the existing override FP rule and
              the new override FP rule is used
            </li>
            <ul>
              <li>
                No fields from the existing override FP rule will be re-used in
                the new override FP rule unless included
              </li>
            </ul>
          </ul>
        </CollapsibleBlock>
      </Grid>
    </Grid>);
    if (!isPricingAdmin) {
        return <NotAuthorized targetRole="Pricing_Admin"/>;
    }
    return renderUploader();
};
export default PricingRulesUploader;
