import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { uniqBy } from 'lodash';
import { isFlagEnabled } from 'src/utils/url-utils';
import { formatCurrency } from 'src/utils/formatter';
import { priceModifiedSinceTaxCalculation } from 'src/AdminApp/containers/requests/Invoice/InvoiceForm/utils';
import { selectInvoiceWithWorkOrderId, selectInvoicesByWorkOrderId, } from 'src/AdminApp/modules/invoices/selectors';
import { selectPaymentDrawerOpen, selectWorkOrderPayments, selectResponsibleParties, } from 'src/AdminApp/modules/requests/selectors';
import { selectQuoteWorkOrderResponsiblePartiesByRequestRefNum, selectInvoiceWorkOrderResponsiblePartiesByRequestRefNum, } from 'src/AdminApp/modules/workOrders/selectors';
import { getWorkOrderPayments, openPaymentSection, } from 'src/AdminApp/modules/requests/actions';
import { refundPayment, removePayment, removePaymentMethod, } from 'src/AdminApp/modules/payments/actions';
import { getOwnedStatus } from '../utils';
const usePayment = ({ referenceNum, workOrderId, consumerContactInfo, }) => {
    var _a, _b, _c, _d, _e, _f;
    const dispatch = useDispatch();
    const primaryInvoice = useSelector((state) => selectInvoiceWithWorkOrderId(state, workOrderId)) || {};
    const invoices = useSelector((state) => selectInvoicesByWorkOrderId(state, { workOrderId })) || {};
    const responsibleParties = useSelector(selectResponsibleParties(referenceNum));
    const quoteWorkOrderResponsibleParties = useSelector(selectQuoteWorkOrderResponsiblePartiesByRequestRefNum(referenceNum));
    const invoiceWorkOrderResponsibleParties = useSelector(selectInvoiceWorkOrderResponsiblePartiesByRequestRefNum(referenceNum));
    const workOrderResponsibleParties = invoiceWorkOrderResponsibleParties.length > 0
        ? invoiceWorkOrderResponsibleParties
        : quoteWorkOrderResponsibleParties;
    let workOrderItemResponsiblePartiesOfInvoice = [];
    const addToParties = (repairs) => {
        let parties = [];
        repairs === null || repairs === void 0 ? void 0 : repairs.forEach((x) => {
            if (x.workOrderItemResponsibleParties &&
                x.workOrderItemResponsibleParties.length > 0) {
                parties = [
                    ...workOrderItemResponsiblePartiesOfInvoice,
                    ...x.workOrderItemResponsibleParties,
                ];
            }
        });
        return parties;
    };
    if ((_a = primaryInvoice === null || primaryInvoice === void 0 ? void 0 : primaryInvoice.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) {
        workOrderItemResponsiblePartiesOfInvoice = [
            ...workOrderItemResponsiblePartiesOfInvoice,
            ...addToParties((_b = primaryInvoice === null || primaryInvoice === void 0 ? void 0 : primaryInvoice.diagnosisServices) === null || _b === void 0 ? void 0 : _b.knownRepairs),
        ];
    }
    if ((_c = primaryInvoice === null || primaryInvoice === void 0 ? void 0 : primaryInvoice.diagnosisServices) === null || _c === void 0 ? void 0 : _c.diagnoses) {
        workOrderItemResponsiblePartiesOfInvoice = [
            ...workOrderItemResponsiblePartiesOfInvoice,
            ...addToParties((_d = primaryInvoice === null || primaryInvoice === void 0 ? void 0 : primaryInvoice.diagnosisServices) === null || _d === void 0 ? void 0 : _d.diagnoses),
        ];
    }
    if ((_e = primaryInvoice === null || primaryInvoice === void 0 ? void 0 : primaryInvoice.diagnosisServices) === null || _e === void 0 ? void 0 : _e.customRequests) {
        workOrderItemResponsiblePartiesOfInvoice = [
            ...workOrderItemResponsiblePartiesOfInvoice,
            ...addToParties((_f = primaryInvoice === null || primaryInvoice === void 0 ? void 0 : primaryInvoice.diagnosisServices) === null || _f === void 0 ? void 0 : _f.customRequests),
        ];
    }
    const responsiblePartiesOfInvoice = uniqBy(workOrderItemResponsiblePartiesOfInvoice.map((x) => x.responsibleParty), (x) => x.name);
    const payments = useSelector(selectWorkOrderPayments(workOrderId));
    const workOrderPayments = useSelector(selectWorkOrderPayments(workOrderId));
    let showPayment = useSelector(selectPaymentDrawerOpen);
    const showPaymentFlag = isFlagEnabled('payment');
    showPayment = showPayment || showPaymentFlag;
    const [addPaymentOpen, setAddPaymentOpen] = useState(showPayment);
    const [removePaymentOpen, setRemovePaymentOpen] = useState(false);
    const [removePaymentId, setRemovePaymentId] = useState();
    const [isRefund, setIsRefund] = useState(false);
    const [removePaymentAmount, setRemovePaymentAmount] = useState();
    const [removePaymentMethodOpen, setRemovePaymentMethodOpen] = useState(false);
    const [removePaymentMethodId, setRemovePaymentMethodId] = useState();
    const [removePaymentMethodName, setRemovePaymentMethodName] = useState();
    const [refundReason, setRefundReason] = useState();
    const paymentsReceivedTotal = invoices.reduce((prev, curr) => prev + curr.paymentsReceivedTotal, 0);
    const paymentsBalanceOwed = invoices.reduce((prev, curr) => prev + curr.balanceOwed, 0);
    const totalPrice = invoices.reduce((prev, curr) => prev + curr.totalPrice, 0);
    const { id } = primaryInvoice !== null && primaryInvoice !== void 0 ? primaryInvoice : {};
    useEffect(() => {
        if (workOrderId) {
            dispatch(getWorkOrderPayments(workOrderId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workOrderId]);
    useEffect(() => {
        setAddPaymentOpen(showPayment);
    }, [showPayment]);
    const resetAddPayment = () => {
        setAddPaymentOpen(false);
        dispatch(openPaymentSection(false));
    };
    function onRemovePayment() {
        if (isRefund && !refundReason) {
            return toast.error('Refund Reason cannot be blank.');
        }
        setRemovePaymentOpen(false);
        if (isRefund && payments) {
            const payment = payments.find((p) => p.id === removePaymentId);
            if (!payment)
                return;
            payment.refundReason = refundReason !== null && refundReason !== void 0 ? refundReason : '';
            setRefundReason('');
            return dispatch(refundPayment(workOrderId, payment, `${id}`));
        }
        return dispatch(removePayment(workOrderId, removePaymentId, `${id}`));
    }
    const hasResponsibleParties = responsibleParties.length || responsiblePartiesOfInvoice.length;
    const handleRemovePaymentMethod = (paymentMethodId, paymentMethodName) => {
        setRemovePaymentMethodId(paymentMethodId);
        setRemovePaymentMethodName(paymentMethodName);
        setRemovePaymentMethodOpen(true);
    };
    const handleRemovePayment = (paymentId, paymentAmount, refund) => {
        setRemovePaymentAmount(formatCurrency(paymentAmount));
        setRemovePaymentId(paymentId);
        setRemovePaymentOpen(true);
        setIsRefund(refund);
    };
    const onRemovePaymentMethod = () => {
        setRemovePaymentMethodOpen(false);
        return dispatch(removePaymentMethod(referenceNum, consumerContactInfo === null || consumerContactInfo === void 0 ? void 0 : consumerContactInfo.consumerId, removePaymentMethodId));
    };
    const getPaidStatus = (balanceOwed) => {
        if (balanceOwed === 0) {
            return 'success';
        }
        return 'default';
    };
    const recalculateTax = primaryInvoice
        ? priceModifiedSinceTaxCalculation(primaryInvoice)
        : false;
    const owedStatus = getOwnedStatus(paymentsBalanceOwed, totalPrice, recalculateTax);
    const handleAddPaymentOpen = (state) => () => setAddPaymentOpen(state);
    const handleRemovePaymentOpen = (state) => () => setRemovePaymentOpen(state);
    const handleRemovePaymentMethodOpen = (state) => () => setRemovePaymentMethodOpen(state);
    return {
        primaryInvoice,
        invoices,
        responsibleParties,
        quoteWorkOrderResponsibleParties,
        invoiceWorkOrderResponsibleParties,
        responsiblePartiesOfInvoice,
        workOrderResponsibleParties,
        payments,
        workOrderPayments,
        showPayment,
        addPaymentOpen,
        setAddPaymentOpen,
        removePaymentOpen,
        setRemovePaymentOpen,
        removePaymentId,
        setRemovePaymentId,
        isRefund,
        setIsRefund,
        removePaymentAmount,
        setRemovePaymentAmount,
        removePaymentMethodOpen,
        setRemovePaymentMethodOpen,
        removePaymentMethodId,
        setRemovePaymentMethodId,
        removePaymentMethodName,
        setRemovePaymentMethodName,
        refundReason,
        setRefundReason,
        paymentsReceivedTotal,
        paymentsBalanceOwed,
        totalPrice,
        id,
        resetAddPayment,
        onRemovePayment,
        hasResponsibleParties,
        handleRemovePaymentMethod,
        handleRemovePayment,
        onRemovePaymentMethod,
        getPaidStatus,
        owedStatus,
        recalculateTax,
        handleAddPaymentOpen,
        handleRemovePaymentOpen,
        handleRemovePaymentMethodOpen,
    };
};
export default usePayment;
