var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putWarrantyQuestions } from 'src/AdminApp/modules/consumers/actions';
import { setTab } from 'src/AdminApp/modules/quickRo/actions';
import { selectTab, selectUser } from 'src/AdminApp/modules/quickRo/selectors';
import { openWarrantyQuestionSection } from 'src/AdminApp/modules/requests/actions';
const useWarrantyDecisionsDrawer = (open, referenceNum, currentTaskWorkFlowState, warrantyClaims) => {
    var _a;
    const [drawerOpened, setDrawerOpened] = useState();
    const [isEligible, setIsEligible] = useState(false);
    const [isDefect, setIsDefect] = useState(false);
    const [reason, setReason] = useState('');
    const [warrantyClaim, setWarrantyClaim] = useState();
    const [approvedInspectionIds, setApprovedInspectionIds] = useState([]);
    const dispatch = useDispatch();
    const TABS = {
        QUESTION_SECTION: 0,
        MESSAGE_SECTION: 1,
        RECOMMENDED_REPAIR_SECTION: 2,
    };
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const tab = (_a = useSelector(selectTab)) !== null && _a !== void 0 ? _a : 0;
    const user = useSelector(selectUser);
    const isUserSelected = () => !!user;
    useEffect(() => {
        var _a, _b;
        const warrantyClaim = warrantyClaims === null || warrantyClaims === void 0 ? void 0 : warrantyClaims.find((e) => e.type === 'INSPECTION');
        if (warrantyClaim) {
            setWarrantyClaim(warrantyClaim);
            setIsEligible(warrantyClaim.eligible);
            setIsDefect(warrantyClaim.defect);
            setReason((_a = warrantyClaim.reason) !== null && _a !== void 0 ? _a : '');
            setApprovedInspectionIds((_b = warrantyClaim.approvedRepairIds) !== null && _b !== void 0 ? _b : []);
        }
    }, [warrantyClaims, drawerOpened]);
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const openWarrantyQuestionDrawer = searchParams.get('openWarrantyQuestionDrawer') === 'true';
    useEffect(() => {
        if (warrantyClaim &&
            (open ||
                (drawerOpened === undefined &&
                    (openWarrantyQuestionDrawer ||
                        currentTaskWorkFlowState ===
                            'RS_WARRANTY_CLAIM_INSPECTION_PERFORMED')))) {
            setDrawerOpened(true);
        }
    }, [
        currentTaskWorkFlowState,
        open,
        warrantyClaim,
        setDrawerOpened,
        drawerOpened,
        openWarrantyQuestionDrawer,
    ]);
    const closeDrawerHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        setDrawerOpened(false);
        dispatch(openWarrantyQuestionSection(false));
        dispatch(setTab(0));
    });
    const techScriptClick = () => {
        if (!isDefect) {
            onPersistAnswers();
        }
        else {
            dispatch(setTab(2));
        }
    };
    const showTechScript = () => {
        dispatch(setTab(1));
    };
    const onPersistAnswers = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        if (referenceNum && warrantyClaim) {
            setIsLoading(true);
            warrantyClaim.eligible = isEligible;
            warrantyClaim.defect = isDefect;
            warrantyClaim.reason = reason;
            warrantyClaim.approvedRepairIds = approvedInspectionIds;
            yield dispatch(putWarrantyQuestions({
                referenceNum,
                warrantyClaimId: (_b = warrantyClaim.warrantyClaimId) !== null && _b !== void 0 ? _b : '',
                request: warrantyClaim,
            }));
            setIsLoading(false);
            yield closeDrawerHandler();
            const currentPathname = window.location.pathname;
            setTimeout(() => window.location.replace(currentPathname), 1000);
        }
    });
    const getOnClickNext = () => {
        if (tab === 2) {
            return onPersistAnswers;
        }
        if (tab === 1) {
            return techScriptClick;
        }
        if (tab === 0) {
            return showTechScript;
        }
        return () => { };
    };
    const handleSetTab = (value) => {
        dispatch(setTab(value));
    };
    const getIsContinueDisabled = () => {
        if (tab === TABS.QUESTION_SECTION) {
            return !(isEligible === false ||
                (isEligible === true && isDefect === false) ||
                (isEligible === true && isDefect === true && reason !== ''));
        }
        if (tab === TABS.RECOMMENDED_REPAIR_SECTION) {
            return (approvedInspectionIds === null || approvedInspectionIds === void 0 ? void 0 : approvedInspectionIds.length) === 0;
        }
        return false;
    };
    const getOnNextCta = () => (tab === TABS.QUESTION_SECTION && isEligible === false) ||
        (tab === TABS.MESSAGE_SECTION && isDefect === false) ||
        tab === TABS.RECOMMENDED_REPAIR_SECTION
        ? 'Submit'
        : 'Continue';
    return {
        isLoading,
        confirmationModalOpen,
        setConfirmationModalOpen,
        tab,
        user,
        isUserSelected,
        closeDrawerHandler,
        getOnClickNext,
        handleSetTab,
        isEligible,
        isDefect,
        reason,
        approvedInspectionIds,
        setIsDefect,
        setIsEligible,
        setReason,
        setApprovedInspectionIds,
        drawerOpened,
        getIsContinueDisabled,
        TABS,
        getOnNextCta,
    };
};
export default useWarrantyDecisionsDrawer;
