var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { isEmpty } from 'lodash';
import { Select, MenuItem, FormControl, InputLabel } from 'src/components/mui';
import { Field } from 'formik';
import LabeledField from 'src/components/layout/LabeledField';
import { AppointmentStatus, DeliveryChannel, IntegratedStatus, RepairOrderStatus, RepairOrderResolution, RepairRequestStatus, RepairRequestResolution, SalesStatus, ShopStatus, TrueFalse, PartsStatus, DeliveryVanStatusValues, ResponsiblePartyStatus, ResponsiblePartyTaxType, } from 'src/AdminApp/models/enums';
import { TextField } from 'src/AdminApp/components/form';
import { createMenuItems } from './utils';
const isResolutionRequired = (statusEnum, status) => statusEnum.requiresResolution
    ? statusEnum.requiresResolution.includes(status)
    : false;
export var SelectType;
(function (SelectType) {
    SelectType[SelectType["LabeledField"] = 0] = "LabeledField";
    SelectType[SelectType["FormControl"] = 1] = "FormControl";
})(SelectType || (SelectType = {}));
const createLabeledField = (enumb, defaultLabel) => function makeField({ value, label = defaultLabel, defaultTitle = '', }) {
    return (<LabeledField label={label}>
        {enumb.display(value) || defaultTitle}
      </LabeledField>);
};
export const createStatusSelect = (statusEnum, type) => {
    return function makeField(_a) {
        var { label = 'Status', name = 'status', shrink = true, leftAlignLabel = false } = _a, rest = __rest(_a, ["label", "name", "shrink", "leftAlignLabel"]);
        const inputLabel = React.useRef(null);
        const selectOptions = createMenuItems(statusEnum);
        const renderSelectInput = ({ field, form, handleChange = undefined, }) => {
            return (<Select {...field} onChange={handleChange || form.handleChange} inputProps={{ name, id: name }} variant="standard" {...rest}>
          {selectOptions}
        </Select>);
        };
        switch (type) {
            case SelectType.LabeledField:
                return (<Field name={name}>
            {({ field, form }) => {
                        const handleChange = (e) => {
                            if (!isResolutionRequired(statusEnum, e.target.value)) {
                                form.setFieldValue('resolution', '');
                            }
                            form.handleChange(e);
                        };
                        return (<LabeledField label={label}>
                  {renderSelectInput({ field, form, handleChange })}
                </LabeledField>);
                    }}
          </Field>);
            case SelectType.FormControl:
                return (<Field name={name}>
            {({ field, form }) => {
                        return (<FormControl fullWidth>
                  <InputLabel sx={leftAlignLabel ? { left: '-16px;' } : {}} shrink={shrink} ref={inputLabel}>
                    {label}
                  </InputLabel>
                  {renderSelectInput({ field, form })}
                </FormControl>);
                    }}
          </Field>);
            default:
                return null;
        }
    };
};
const createResolutionSelect = (statusEnum, resolutionEnum) => {
    return function makeField({ label = 'Resolution', name = 'resolution', }) {
        const selectOptions = [
            <MenuItem value="" key="EMPTY"/>,
            ...createMenuItems(resolutionEnum, true, undefined, false),
        ];
        return (<Field name={name} render={({ field, form }) => {
                return (<>
              <LabeledField label={label}>
                <Select {...field} inputProps={{ name, id: name }} displayEmpty disabled={!isResolutionRequired(statusEnum, form.values.status)}>
                  {selectOptions}
                </Select>
              </LabeledField>
              {resolutionEnum.OTHER &&
                        form.values.resolution === resolutionEnum.OTHER && (<LabeledField label="Other Resolution">
                    <TextField placeholder="Type a custom resolution" type="text" name="otherResolution"/>
                  </LabeledField>)}
            </>);
            }}/>);
    };
};
export const validateOtherResolution = (values, resolution) => {
    return !(values.resolution === resolution && isEmpty(values.otherResolution));
};
export const RequestStatusLabeledField = createLabeledField(RepairRequestStatus, 'Status');
export const RequestResolutionLabeledField = createLabeledField(RepairRequestResolution, 'Resolution');
export const RequestStatusSelect = createStatusSelect(RepairRequestStatus, SelectType.LabeledField);
export const RequestStatusSelectForm = createStatusSelect(RepairRequestStatus, SelectType.FormControl);
export const RequestResolutionSelect = createResolutionSelect(RepairRequestStatus, RepairRequestResolution);
export const DeliveryChannelSelectForm = createStatusSelect(DeliveryChannel, SelectType.FormControl);
export const AppointmentStatusSelectForm = createStatusSelect(AppointmentStatus, SelectType.FormControl);
export const OrderStatusSelectForm = createStatusSelect(RepairOrderStatus, SelectType.FormControl);
export const OrderResolutionSelectForm = createStatusSelect(RepairOrderResolution, SelectType.FormControl);
export const ShopSalesStatusSelectForm = createStatusSelect(SalesStatus, SelectType.FormControl);
export const ShopStatusSelectForm = createStatusSelect(ShopStatus, SelectType.FormControl);
export const IntegratedStatusSelectForm = createStatusSelect(IntegratedStatus, SelectType.FormControl);
export const DeliveryVanStatusSelectForm = createStatusSelect(DeliveryVanStatusValues, SelectType.FormControl);
export const HasParentSelectForm = createStatusSelect(TrueFalse, SelectType.FormControl);
export const PartsStatusSelectForm = createStatusSelect(PartsStatus, SelectType.FormControl);
export const ResponsiblePartiesStatusSelectForm = createStatusSelect(ResponsiblePartyStatus, SelectType.FormControl);
export const ResponsiblePartyTaxTypeSelectForm = createStatusSelect(ResponsiblePartyTaxType, SelectType.FormControl);
export const ResponsiblePartiesHasAuthorizationNumber = createStatusSelect(TrueFalse, SelectType.FormControl);
export const ResponsiblePartiesHasPaymentRequired = createStatusSelect(TrueFalse, SelectType.FormControl);
export const ResponsiblePartiesHasGeneratesRevenue = createStatusSelect(TrueFalse, SelectType.FormControl);
