var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMediaQuery } from 'src/components/mui';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { defaultPaidByOptions } from 'src/AdminApp/containers/requests/StateTracker/utils';
import { DeliveryChannel, RepairOrderResolution, RepairRequestState, } from 'src/AdminApp/models/enums';
import TechnicianWorkflowState from 'src/AdminApp/models/enums/TechnicianWorkflowState';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import { CUSTOM_REPAIR_ID } from 'src/AdminApp/modules/api/constants';
import { closeExpiredArrivalTimerModal, setArrivalExtraMinutesTimestamp, } from 'src/AdminApp/modules/arrivalTimer/actions';
import { selectExpiredArrivalTimerModalOpen, selectIsArrivalTimerExpired, } from 'src/AdminApp/modules/arrivalTimer/selectors';
import { getAllFMCs } from 'src/AdminApp/modules/fleets/actions';
import { selectFleetById } from 'src/AdminApp/modules/fleets/selectors';
import { createInvoiceAndCompleteJob, getInvoicesByWorkOrderId, } from 'src/AdminApp/modules/invoices/actions';
import { selectInvoiceWithWorkOrderId } from 'src/AdminApp/modules/invoices/selectors';
import { getInspectionByRepairRequestId } from 'src/AdminApp/modules/mpi/actions';
import { selectMpiInspectionByRepairRequestId } from 'src/AdminApp/modules/mpi/selectors';
import { setContinueDisabled, setFleet, setIsBackNavDisabled, setPricing, setRepairs, setTab, saveUser, setVehicle, } from 'src/AdminApp/modules/quickRo/actions';
import { CONSUMER_TABS as TABS, FLEET_TABS, } from 'src/AdminApp/modules/quickRo/enums';
import { selectFleet, selectTimeSlot, selectUser, } from 'src/AdminApp/modules/quickRo/selectors';
import { updateRequestLock } from 'src/AdminApp/modules/requestLock/actions';
import { selectRequestLock } from 'src/AdminApp/modules/requestLock/selectors';
import { closeNotesDrawer, getMissing3CRequestNotes, openNotesDrawer, } from 'src/AdminApp/modules/requestNotes/actions';
import { select3CNotesDrawerIsOpen, selectRemaining3CNotes, } from 'src/AdminApp/modules/requestNotes/selectors';
import { authorizeQuote, cancelROVisitAppointment, closeRequestOrder, confirmRequestAppointment, getRequest, getRequestUntilExpectedState, getWorkOrderPayments, openFourCornerModal, openMeasurementsSection, openMobileCarDetails, openPaymentSection, openWarrantyQuestionSection, updateCar, } from 'src/AdminApp/modules/requests/actions';
import { selectFourCornerHasPhotos, selectRepairFitments, selectRequestActiveAppointment, selectRequestCoreCharges, selectRequestDataByReferenceNum, selectRequestDiagnosis, selectRequestRequestReferenceNum, selectVehicleByRefNum, } from 'src/AdminApp/modules/requests/selectors';
import { selectCurrentUser, selectTechnicians, } from 'src/AdminApp/modules/users/selectors';
import { getJobDurationHours } from 'src/AdminApp/modules/utils';
import { completeTechnicianWorkflowOnSiteSingleTask, getTechnicianOnSiteWorkflowByRef, sendAuthorizationStartedEvent, } from 'src/AdminApp/modules/workflows/actions';
import { selectWorkflowByReferenceNumber } from 'src/AdminApp/modules/workflows/selectors';
import { updateWorkOrder } from 'src/AdminApp/modules/workOrders/actions';
import { selectFirstWorkOrderByRequestRefNum, selectWorkOrderResponsiblePartyTypeTotals, } from 'src/AdminApp/modules/workOrders/selectors';
import { getFieldOpsUrl } from 'src/AdminApp/utils/url-utils';
import { toast } from 'src/components/SimpleToast';
import CONFIG from 'src/config';
import { selectAuthorizedSystemAdmin, selectAuthorizedTechnician, selectCancelApptsVisits, selectLockedRoEditor, selectPaymentPreauth, selectSkipFourCornerMPI, selectUserId, } from 'src/modules/auth/selectors';
import { theme } from 'src/styles/theme';
import { isIOS } from 'src/utils/crossPlatformUtils';
const useRequestCTAsContainer = ({ referenceNum, state, mobileOverride, skipPositionCheck, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const dispatch = useDispatch();
    const history = useHistory();
    const selectorProps = { match: { params: { referenceNum } } };
    // STATE
    const [openedModal, setOpenedModal] = useState();
    const [openModalNotifyUser, setOpenModalNotifyUser] = useState(false);
    const [openedQuickROType, setOpenedQuickROType] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isCarDetailsOpen, setIsCarDetailsOpen] = useState(false);
    const [currentLocation, setCurrentLocation] = useState();
    const [paidByOptions, setPaidByOptions] = useState([]);
    const [isSelectVisitTechnicianOpen, setIsSelectVisitTechnicianOpen] = useState(false);
    const [openROCancelConfirm, setOpenROCancelConfirm] = useState(false);
    const [currentTaskWorkFlow, setCurrentTaskWorkFlow] = useState();
    const [cancellationValues, setCancellationValues] = useState();
    const [allowRemove, setAllowRemove] = useState(true);
    const [isCantFinish, setIsCantFinish] = useState(false);
    const [requestPreAuthorization, setRequestPreAuthorization] = useState(true);
    const [openConfirmUnlockRoModal, setOpenConfirmUnlockRoModal] = useState(false);
    const [vanId, setVanId] = useState();
    const [openConfirmPartsFitmentDrawer, setOpenConfirmPartsFitmentDrawer] = useState(false);
    // SELECTORS
    const isTechnician = useSelector(selectAuthorizedTechnician);
    const isPaymentPreauth = useSelector(selectPaymentPreauth);
    const isLockedRoEditor = useSelector(selectLockedRoEditor);
    const isCancelAppts = useSelector(selectCancelApptsVisits);
    const canSkipTasks = useSelector(selectSkipFourCornerMPI);
    const vehicle = useSelector(selectVehicleByRefNum(referenceNum));
    const workflow = useSelector(selectWorkflowByReferenceNumber(referenceNum));
    const isMobile = (_a = (mobileOverride && mobileOverride === 'true')) !== null && _a !== void 0 ? _a : useMediaQuery(theme.breakpoints.down('sm'));
    const request = useSelector(selectRequestRequestReferenceNum(referenceNum));
    const hasVisit = !!(request === null || request === void 0 ? void 0 : request.visit);
    const isPartOrdered = ['ORDERED', 'PARTIALLY_ORDERED'].includes(request === null || request === void 0 ? void 0 : request.partsStatus);
    const partsArePickedUp = (request === null || request === void 0 ? void 0 : request.partsStatus) === 'PICKED_UP' ||
        (request === null || request === void 0 ? void 0 : request.partsStatus) === 'NOT_NEEDED';
    const lockStartTravelUntilPickedUp = CONFIG.ENABLE_LOCK_START_TRAVEL_UNTIL_PICKED_UP === 'true';
    const user = useSelector(selectUser);
    const fleet = useSelector(selectFleet);
    const fleetById = useSelector(selectFleetById((_b = request === null || request === void 0 ? void 0 : request.fleetId) !== null && _b !== void 0 ? _b : ''));
    const timeSlot = useSelector(selectTimeSlot);
    const activeAppointment = useSelector((state) => selectRequestActiveAppointment(state, selectorProps));
    const isFleet = !!(request === null || request === void 0 ? void 0 : request.fleetId);
    const isPartner = !!(request === null || request === void 0 ? void 0 : request.partnerId);
    const isFunnel = !!(request === null || request === void 0 ? void 0 : request.funnelId);
    const requestId = (_c = request === null || request === void 0 ? void 0 : request.id) !== null && _c !== void 0 ? _c : '';
    const requestData = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const diagnosis = useSelector((state) => selectRequestDiagnosis(state, selectorProps));
    const requestCoreCharges = useSelector((state) => selectRequestCoreCharges(state, selectorProps));
    const repairFitments = useSelector((state) => selectRepairFitments(state, selectorProps));
    const workOrder = useSelector(selectFirstWorkOrderByRequestRefNum(referenceNum));
    const quoteId = (_d = workOrder === null || workOrder === void 0 ? void 0 : workOrder.quote.id) !== null && _d !== void 0 ? _d : '';
    const workOrderId = (_e = workOrder === null || workOrder === void 0 ? void 0 : workOrder.id) !== null && _e !== void 0 ? _e : '';
    const mpi = useSelector(selectMpiInspectionByRepairRequestId((_f = request === null || request === void 0 ? void 0 : request.id) !== null && _f !== void 0 ? _f : ''));
    const workOrderResponsiblePartyTypeTotals = useSelector(selectWorkOrderResponsiblePartyTypeTotals((_g = workOrder === null || workOrder === void 0 ? void 0 : workOrder.id) !== null && _g !== void 0 ? _g : ''));
    const { inspectionType } = mpi !== null && mpi !== void 0 ? mpi : {};
    const currentUser = useSelector(selectCurrentUser);
    const requestLock = useSelector(selectRequestLock);
    const hasPhotos = useSelector(selectFourCornerHasPhotos(referenceNum));
    const primaryInvoice = useSelector((state) => selectInvoiceWithWorkOrderId(state, workOrderId)) || {};
    const isSystemAdmin = useSelector(selectAuthorizedSystemAdmin);
    const technicianId = useSelector(selectUserId);
    const is3CDrawerOpen = useSelector(select3CNotesDrawerIsOpen);
    const remaining3CNotes = useSelector(selectRemaining3CNotes);
    const authGroupTechnicians = useSelector(selectTechnicians);
    const hasTechnicianAuthGroup = 
    // eslint-disable-next-line eqeqeq
    ((_h = authGroupTechnicians === null || authGroupTechnicians === void 0 ? void 0 : authGroupTechnicians.filter((tech) => tech.id == technicianId)) === null || _h === void 0 ? void 0 : _h.length) > 0;
    const isArrivalTimerExpired = useSelector(selectIsArrivalTimerExpired);
    const expiredArrivalTimerModalOpen = useSelector(selectExpiredArrivalTimerModalOpen);
    // EFFECTS
    useEffect(() => {
        if (!isMobile || mpi || !requestId)
            return;
        const getData = () => __awaiter(void 0, void 0, void 0, function* () {
            yield dispatch(getInspectionByRepairRequestId(requestId));
        });
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId]);
    useEffect(() => {
        if (!requestId ||
            ((currentTaskWorkFlow === null || currentTaskWorkFlow === void 0 ? void 0 : currentTaskWorkFlow.state) !== 'CONCERN_CAUSE_CORRECTION' &&
                (currentTaskWorkFlow === null || currentTaskWorkFlow === void 0 ? void 0 : currentTaskWorkFlow.state) !== 'REPAIRS_AUTHORIZED'))
            return;
        const get3CNotesData = () => __awaiter(void 0, void 0, void 0, function* () {
            yield dispatch(getMissing3CRequestNotes(requestId));
        });
        get3CNotesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, currentTaskWorkFlow]);
    useEffect(() => {
        if (isFleet || isPartner || isFunnel || !isPaymentPreauth) {
            setRequestPreAuthorization(false);
        }
        if (workOrderResponsiblePartyTypeTotals &&
            workOrderResponsiblePartyTypeTotals.length > 0) {
            const customerPay = workOrderResponsiblePartyTypeTotals.filter((item) => item.name === 'Customer Pay');
            if (customerPay.length > 0 && customerPay[0].totalAmount <= 0) {
                setRequestPreAuthorization(false);
            }
        }
    }, [
        isFleet,
        isPartner,
        isFunnel,
        isPaymentPreauth,
        workOrderResponsiblePartyTypeTotals,
    ]);
    useEffect(() => {
        if (currentLocation || skipPositionCheck)
            return;
        navigator.geolocation.getCurrentPosition((position) => {
            setCurrentLocation(position.coords);
        });
    }, [currentLocation, skipPositionCheck]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (activeAppointment &&
                activeAppointment.status === 'CONFIRMED' &&
                (request === null || request === void 0 ? void 0 : request.state) !== 'CONFIRMED_APPOINTMENT' &&
                ((_a = request === null || request === void 0 ? void 0 : request.repairOrder) === null || _a === void 0 ? void 0 : _a.status) !== 'IN_PROGRESS') {
                yield dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
            }
            const van = activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.skeduloJobResources.find((resource) => resource.category === 'VAN' &&
                !['DELETED', 'DECLINED'].includes(resource.status));
            if (van === null || van === void 0 ? void 0 : van.resourceId) {
                setVanId(van === null || van === void 0 ? void 0 : van.resourceId);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeAppointment, request]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (referenceNum && !(workflow === null || workflow === void 0 ? void 0 : workflow.currentTask)) {
                const response = yield dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
                const { payload: { currentTask }, } = response;
                if (!currentTaskWorkFlow || currentTaskWorkFlow.id !== currentTask.id)
                    setCurrentTaskWorkFlow(currentTask);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const isMpiCompleted = ((workflow === null || workflow === void 0 ? void 0 : workflow.workflowTasks) || []).some((task) => (task === null || task === void 0 ? void 0 : task.state) === 'MPI_PERFORMED' && (task === null || task === void 0 ? void 0 : task.status) === 'COMPLETED');
            if (isMpiCompleted) {
                setAllowRemove(false);
            }
            if (!workflow)
                return;
            if (workflow.currentTask) {
                setCurrentTaskWorkFlow(workflow.currentTask);
            }
            if (((_a = workflow.currentTask) === null || _a === void 0 ? void 0 : _a.state) === 'CONFIRM_PARTS' &&
                ((_b = workflow.currentTask) === null || _b === void 0 ? void 0 : _b.status) === 'PENDING' &&
                (repairFitments === null || repairFitments === void 0 ? void 0 : repairFitments.length) === 0) {
                yield dispatch(completeTechnicianWorkflowOnSiteSingleTask(referenceNum, Object.assign(Object.assign({}, workflow.currentTask), { notes: 'Autocompleted - no multiple fitments' })));
                yield dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflow, repairFitments]);
    useEffect(() => {
        if (!isFleet || !workOrder)
            return;
        if (!isMobile) {
            const workOrderId = workOrder.id;
            (() => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b;
                const res = yield dispatch(getAllFMCs({ pageSize: 30 }));
                const result = [...((_b = (_a = res === null || res === void 0 ? void 0 : res.payload) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : [])];
                setPaidByOptions([
                    ...defaultPaidByOptions.map(({ label, value }, i) => ({
                        color: 'primary',
                        copy: `Set Paid By to: ${label}`,
                        function: () => {
                            dispatch(updateWorkOrder({
                                paidBy: value.toString(),
                                workOrderId,
                            })).then(() => dispatch(getRequest(referenceNum, requestId)));
                        },
                        hasBorderTop: i === 0,
                    })),
                    ...result.map(({ id, name }) => ({
                        color: 'primary',
                        copy: `Set Paid By to: ${name}`,
                        function: () => {
                            dispatch(updateWorkOrder({
                                paidBy: id,
                                workOrderId,
                            })).then(() => dispatch(getRequest(referenceNum, requestId)));
                        },
                    })),
                ]);
            }))();
        }
        else if (isMobile && paidByOptions.length > 0) {
            setPaidByOptions([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFleet, request === null || request === void 0 ? void 0 : request.fleetId, workOrder, isMobile]);
    useEffect(() => {
        var _a, _b;
        if (openedModal !== 'quickRo')
            return;
        const address = ((_a = user === null || user === void 0 ? void 0 : user.address) === null || _a === void 0 ? void 0 : _a.streetLine1) || ((_b = fleet === null || fleet === void 0 ? void 0 : fleet.address) === null || _b === void 0 ? void 0 : _b.streetLine1);
        if (openedQuickROType === 'reschedulePendingOrConfirmed') {
            if (!address || !timeSlot) {
                dispatch(setContinueDisabled(true));
            }
            else {
                dispatch(setContinueDisabled(false));
            }
        }
        else if (!address) {
            dispatch(setContinueDisabled(true));
        }
        else {
            dispatch(setContinueDisabled(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user === null || user === void 0 ? void 0 : user.address, fleet === null || fleet === void 0 ? void 0 : fleet.address, timeSlot, openedModal]);
    const preSelectedMechanics = useMemo(() => {
        var _a;
        if (openedModal === 'quickRo' &&
            openedQuickROType === 'reschedulePendingOrConfirmed' &&
            ((_a = activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.skeduloJobResources) !== null && _a !== void 0 ? _a : []).length >= 0) {
            return activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.skeduloJobResources.filter((resource) => resource.category === 'MECHANIC').map((r) => r.name).filter((r) => !!r);
        }
        return null;
    }, [openedModal, openedQuickROType, activeAppointment]);
    const preSelectedVan = useMemo(() => {
        var _a;
        if (((_a = activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.skeduloJobResources) !== null && _a !== void 0 ? _a : []).length >= 0) {
            return activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.skeduloJobResources.filter((resource) => resource.category === 'VAN').map((r) => r.skeduloResourceId).filter((r) => !!r);
        }
        return null;
    }, [activeAppointment]);
    const getDeliveryChannel = useMemo(() => request === null || request === void 0 ? void 0 : request.activeAppointment.deliveryChannel, [request]);
    const getHeaderText = useMemo(() => {
        if (openedQuickROType === 'reschedulePendingOrConfirmed' ||
            openedQuickROType === 'rescheduleCanceled') {
            return 'Reschedule Appointment';
        }
        return 'Schedule Appointment';
    }, [openedQuickROType]);
    const skeduloJobExists = useMemo(() => !!(request === null || request === void 0 ? void 0 : request.activeAppointment.skeduloJobId) || hasVisit, [hasVisit, request]);
    const shouldEnableConfirmAppointment = useMemo(() => {
        var _a, _b, _c, _d, _e;
        const activeAppointment = (_a = request === null || request === void 0 ? void 0 : request.activeAppointment) !== null && _a !== void 0 ? _a : null;
        const repairs = (_d = (_c = (_b = requestData === null || requestData === void 0 ? void 0 : requestData.diagnosis) === null || _b === void 0 ? void 0 : _b.diagnosisServices) === null || _c === void 0 ? void 0 : _c.knownRepairs) !== null && _d !== void 0 ? _d : [];
        const prices = repairs.reduce((agg, repair) => agg.concat(repair.price), []);
        const totalCosts = prices.map((price) => price.totalCost);
        const hasAllTotalCosts = !totalCosts.includes(null) && !totalCosts.includes(undefined);
        return ((activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.scheduleStartLocal) &&
            (activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.ispContactInfo) &&
            getDeliveryChannel !== DeliveryChannel.NONE &&
            !!((_e = workOrder === null || workOrder === void 0 ? void 0 : workOrder.quote.activeWorkAuthorization) === null || _e === void 0 ? void 0 : _e.providedAt) &&
            hasAllTotalCosts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request, requestData]);
    const shouldEnableScheduleAppointment = useMemo(() => {
        var _a;
        const quoteProvided = !!((_a = workOrder === null || workOrder === void 0 ? void 0 : workOrder.quote.activeWorkAuthorization) === null || _a === void 0 ? void 0 : _a.providedAt);
        const hasDuration = !!(request === null || request === void 0 ? void 0 : request.totalLaborDuration);
        return quoteProvided && hasDuration;
    }, [request, workOrder]);
    const scheduleRequestToolTip = useMemo(() => {
        var _a;
        const tooltip = 'Cannot schedule appointment unless ';
        const hasDuration = !!(request === null || request === void 0 ? void 0 : request.totalLaborDuration);
        if (!hasDuration) {
            return `${tooltip}labor duration is not null`;
        }
        const quoteProvided = !!((_a = workOrder === null || workOrder === void 0 ? void 0 : workOrder.quote.activeWorkAuthorization) === null || _a === void 0 ? void 0 : _a.providedAt);
        if (!quoteProvided) {
            return `${tooltip}a quote has been provided`;
        }
        const fleetHasPaidBy = !isFleet || (isFleet && !!(workOrder === null || workOrder === void 0 ? void 0 : workOrder.paidBy));
        if (!fleetHasPaidBy) {
            return `${tooltip}Paid By is chosen from the drop down`;
        }
    }, [isFleet, request, workOrder]);
    const isRescheduleCTADisabled = useMemo(() => {
        var _a;
        const hasDuration = !!(request === null || request === void 0 ? void 0 : request.totalLaborDuration);
        const quoteProvided = !!((_a = workOrder === null || workOrder === void 0 ? void 0 : workOrder.quote.activeWorkAuthorization) === null || _a === void 0 ? void 0 : _a.providedAt);
        return !(quoteProvided && hasDuration);
    }, [request, workOrder]);
    const isCompleteJobCTADisabled = useMemo(() => {
        if (fleetById === null || fleetById === void 0 ? void 0 : fleetById.amazon) {
            return false;
        }
        return ((request === null || request === void 0 ? void 0 : request.partsStatus) === 'PENDING' ||
            (request === null || request === void 0 ? void 0 : request.partsStatus) === 'PARTIALLY_ORDERED');
    }, [request, fleetById]);
    // METHODS
    const handleROCancelConfirmClose = () => {
        setOpenROCancelConfirm(false);
    };
    const handleLockRo = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!request)
            return;
        setIsLoading(true);
        const response = yield dispatch(updateRequestLock({ repairRequestId: request.id, locked: true }));
        if (response && !response.error) {
            setIsLoading(false);
        }
    });
    const close3CDrawer = () => {
        dispatch(closeNotesDrawer());
    };
    const open3CDrawer = () => {
        dispatch(openNotesDrawer());
    };
    const closeCantFinishDrawer = () => setOpenedModal(undefined);
    const handleUnlockRo = (unlockReasons) => __awaiter(void 0, void 0, void 0, function* () {
        if (!request)
            return;
        setIsLoading(true);
        const response = yield dispatch(updateRequestLock({
            repairRequestId: request.id,
            locked: false,
            unlockReasons,
        }));
        if (response && !response.error) {
            toast.success('RO unlocked successfully');
        }
        setIsLoading(false);
        setOpenedModal(undefined);
    });
    const handleOpenConfirmUnlockRoMOdal = () => {
        setOpenConfirmUnlockRoModal(true);
    };
    const openQuickRo = () => setOpenedModal('quickRo');
    const closeModal = () => {
        setOpenedModal(undefined);
        setOpenedQuickROType(undefined);
        setCancellationValues(undefined);
    };
    const closeCancellationReasons = () => setOpenedModal(undefined);
    const getRepairs = () => {
        var _a, _b, _c, _d;
        const customRepairs = ((_b = (_a = requestData === null || requestData === void 0 ? void 0 : requestData.diagnosis) === null || _a === void 0 ? void 0 : _a.diagnosisServices.customRequests) !== null && _b !== void 0 ? _b : []).map((r) => ({
            customRepairText: r.message,
            label: r.message,
            repairId: CUSTOM_REPAIR_ID,
        }));
        const repairs = ((_d = (_c = requestData === null || requestData === void 0 ? void 0 : requestData.diagnosis) === null || _c === void 0 ? void 0 : _c.diagnosisServices.knownRepairs) !== null && _d !== void 0 ? _d : []).map((r) => ({
            label: r.name,
            repairId: r.nodeId ? r.nodeId : r.id,
        }));
        return { customRepairs, repairs };
    };
    const mpiMainButtonText = () => {
        if (!mpi) {
            return 'Add MPI';
        }
        if (mpi.completedAt) {
            return `Edit ${inspectionType.name}${inspectionType.name.toLowerCase().indexOf('mpi') === -1 ? ' MPI' : ''}`;
        }
        return `Continue ${inspectionType.name}${inspectionType.name.toLowerCase().indexOf('mpi') === -1 ? ' MPI' : ''}`;
    };
    const completeJob = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const result = yield dispatch(createInvoiceAndCompleteJob(referenceNum, requestId));
        setIsLoading(false);
        if (!result.error) {
            history.push({
                hash: 'invoice',
            });
        }
    });
    const getLabelCurrentTaskWorkFlow = () => {
        if ((currentTaskWorkFlow === null || currentTaskWorkFlow === void 0 ? void 0 : currentTaskWorkFlow.state) === TechnicianWorkflowState.FOUR_CORNER) {
            if (hasPhotos)
                return TechnicianWorkflowState.values.FOUR_CORNER_CONTINUE;
            return TechnicianWorkflowState.values.FOUR_CORNER;
        }
        if ((currentTaskWorkFlow === null || currentTaskWorkFlow === void 0 ? void 0 : currentTaskWorkFlow.state) === TechnicianWorkflowState.MPI_PERFORMED) {
            return mpiMainButtonText();
        }
        return (currentTaskWorkFlow === null || currentTaskWorkFlow === void 0 ? void 0 : currentTaskWorkFlow.state)
            ? TechnicianWorkflowState.display(currentTaskWorkFlow.state)
            : 'Start Travel';
    };
    const setUpQuickRO = () => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const { customRepairs, repairs } = getRepairs();
        const user = requestData.contactInfo;
        const { car } = requestData;
        const requestAddress = request === null || request === void 0 ? void 0 : request.address;
        const address = {
            coordinates: {
                latitude: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.latitude,
                longitude: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.longitude,
            },
            city: (_a = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.city) !== null && _a !== void 0 ? _a : '',
            state: (_b = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.state) !== null && _b !== void 0 ? _b : '',
            streetLine1: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.street,
            street: (_c = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.street) !== null && _c !== void 0 ? _c : '',
            timezone: (_d = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.timezone) !== null && _d !== void 0 ? _d : 'America/Los_Angeles',
            zip: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.zip,
            zipCode: (_e = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.zip) !== null && _e !== void 0 ? _e : '',
            country: 'US',
            latitude: (_f = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.latitude) !== null && _f !== void 0 ? _f : 0,
            longitude: (_g = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.longitude) !== null && _g !== void 0 ? _g : 0,
        };
        if (isFleet && fleetById) {
            dispatch(setFleet(fleetById));
        }
        else {
            dispatch(saveUser(Object.assign(Object.assign({}, user), { address, id: user.consumerId })));
        }
        const { totalJobDurationMinutes } = request !== null && request !== void 0 ? request : {};
        const totalJobDurationHours = getJobDurationHours({
            totalJobDurationMinutes,
            totalLaborDuration: (_j = (_h = request === null || request === void 0 ? void 0 : request.workOrder) === null || _h === void 0 ? void 0 : _h.totalLaborDuration) !== null && _j !== void 0 ? _j : 0,
        });
        dispatch(setRepairs([...repairs, ...customRepairs]));
        dispatch(setPricing({
            totalLaborDuration: (_l = (_k = request === null || request === void 0 ? void 0 : request.workOrder) === null || _k === void 0 ? void 0 : _k.totalLaborDuration) !== null && _l !== void 0 ? _l : 0,
            jobDuration: totalJobDurationHours,
            reFetchDisabled: true,
        }));
        dispatch(setVehicle(Object.assign(Object.assign({}, car), { id: car === null || car === void 0 ? void 0 : car.consumerCarId })));
        dispatch(setIsBackNavDisabled(true));
        if (isFleet) {
            dispatch(setTab(FLEET_TABS.SELECT_VISIT));
        }
        else {
            dispatch(setTab(TABS.SELECT_APPOINTMENT));
        }
        openQuickRo();
    };
    const openRescheduleCanceledQuickRo = () => {
        setUpQuickRO();
        setOpenedQuickROType('rescheduleCanceled');
        openQuickRo();
        dispatch(setArrivalExtraMinutesTimestamp(undefined));
    };
    const setUpCantFinishQuickRO = (recommendedRepairs, repairRequest) => {
        var _a, _b, _c, _d, _e, _f, _g;
        const user = repairRequest.consumerContactInfo;
        const { car } = requestData;
        const requestAddress = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.address;
        const address = {
            coordinates: {
                latitude: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.latitude,
                longitude: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.longitude,
            },
            city: (_a = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.city) !== null && _a !== void 0 ? _a : '',
            state: (_b = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.state) !== null && _b !== void 0 ? _b : '',
            streetLine1: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.street,
            street: (_c = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.street) !== null && _c !== void 0 ? _c : '',
            timezone: (_d = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.timezone) !== null && _d !== void 0 ? _d : 'America/Los_Angeles',
            zip: requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.zip,
            zipCode: (_e = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.zip) !== null && _e !== void 0 ? _e : '',
            country: 'US',
            latitude: (_f = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.latitude) !== null && _f !== void 0 ? _f : 0,
            longitude: (_g = requestAddress === null || requestAddress === void 0 ? void 0 : requestAddress.longitude) !== null && _g !== void 0 ? _g : 0,
        };
        if (isFleet && fleetById) {
            dispatch(setFleet(fleetById));
        }
        else {
            dispatch(saveUser(Object.assign(Object.assign({}, user), { address, id: user.consumerId })));
        }
        dispatch(setRepairs(recommendedRepairs));
        dispatch(setVehicle(Object.assign(Object.assign({}, car), { id: car === null || car === void 0 ? void 0 : car.consumerCarId })));
        if (isFleet) {
            dispatch(setTab(FLEET_TABS.SELECT_VISIT));
        }
        else {
            dispatch(setTab(TABS.SELECT_APPOINTMENT));
        }
        setOpenedQuickROType(undefined);
        setOpenedModal('cantFinishRecommendedRepairs');
    };
    const openScheduleAllRecommendRepairs = (recommendedRepairs, repairRequest) => {
        setUpCantFinishQuickRO(recommendedRepairs, repairRequest);
    };
    const openCancellationReasonsPending = () => {
        setOpenedQuickROType('reschedulePendingOrConfirmed');
        setOpenedModal('cancellationReasons');
    };
    const openCantFinish = () => {
        vehicle.fleetId = request === null || request === void 0 ? void 0 : request.fleetId;
        dispatch(setFleet(fleet));
        dispatch(setVehicle(vehicle));
        setOpenedModal('cantFinishReasons');
    };
    const openCantFinishScreenForMeasurements = () => {
        setOpenedModal('cantFinishScreenForMeasurements');
    };
    const openScheduleQuickRo = () => {
        setUpQuickRO();
        dispatch(setContinueDisabled(true));
        setOpenedQuickROType('schedule');
        openQuickRo();
    };
    const openAuthorizationScript = () => {
        var _a;
        dispatch(sendAuthorizationStartedEvent(referenceNum, (_a = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) === null || _a === void 0 ? void 0 : _a.consumerId));
        setOpenedModal('authorizationScript');
    };
    const onCompleteJob = () => __awaiter(void 0, void 0, void 0, function* () {
        if (vehicle === null || vehicle === void 0 ? void 0 : vehicle.mileageNotAvailable) {
            completeJob();
        }
        else {
            setIsCarDetailsOpen(true);
        }
    });
    const complete3Ctask = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield completeTask();
        setIsLoading(false);
    });
    const getMilesOut = (car) => __awaiter(void 0, void 0, void 0, function* () {
        var _j, _k, _l, _m;
        dispatch(updateCar({
            referenceNum,
            vehicle: Object.assign(Object.assign({}, vehicle), { mileageOut: car.mileageOut }),
            consumerCarId: (_k = (_j = car === null || car === void 0 ? void 0 : car.consumerCarId) !== null && _j !== void 0 ? _j : vehicle.consumerCarId) !== null && _k !== void 0 ? _k : 0,
            consumerId: (_l = car === null || car === void 0 ? void 0 : car.consumerId) !== null && _l !== void 0 ? _l : (_m = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) === null || _m === void 0 ? void 0 : _m.consumerId,
            repairRequestId: requestId,
        }));
        setIsCarDetailsOpen(false);
        // CHECK CORES AND LAUNCH THE CORES DRAWER
        if (requestCoreCharges.length > 0) {
            setOpenedModal('createCore');
        }
        else {
            completeJob();
        }
    });
    const getVisitActions = (visitStatus, requestState, currentTask) => {
        var _a;
        const actions = [];
        const removeAction = {
            function: () => {
                if (visitStatus === 'PENDING' || visitStatus === 'IN_PROGRESS') {
                    setOpenROCancelConfirm(true);
                }
                else {
                    openCancellationReasonsPending();
                }
            },
            copy: 'Remove',
            disabled: false,
            mobileVariant: 'text',
        };
        if (['PENDING', 'CONFIRMED'].includes(visitStatus)) {
            actions.push({
                function: () => { },
                copy: 'Start Job',
                disabled: true,
                tooltip: 'Visit must be started first',
            });
            if (allowRemove) {
                actions.push(removeAction);
            }
        }
        else if (requestState === RepairRequestState.CONFIRMED_APPOINTMENT &&
            currentTask === 'JOB_STARTED' &&
            skeduloJobExists) {
            actions.push({
                function: () => {
                    setIsSelectVisitTechnicianOpen(true);
                },
                copy: 'Start Job',
                disabled: (diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.totalCost) === null,
                tooltip: (diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.totalCost) === null
                    ? 'Visit must be started and quote must be fully priced first'
                    : '',
            });
            if (allowRemove) {
                actions.push(removeAction);
            }
        }
        else if (requestState === RepairRequestState.REPAIR_IN_PROGRESS &&
            isTechnician &&
            skeduloJobExists) {
            switch (currentTask) {
                case 'REPAIRS_AUTHORIZED':
                    actions.push({
                        function: openAuthorizationScript,
                        copy: getLabelCurrentTaskWorkFlow(),
                        disabled: false,
                    });
                    if (allowRemove) {
                        actions.push(removeAction);
                    }
                    break;
                case 'FOUR_CORNER':
                    if (canSkipTasks) {
                        actions.push({
                            function: () => {
                                var _a, _b;
                                completeTask();
                                if (isFleet && !(vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin)) {
                                    dispatch(updateCar({
                                        referenceNum,
                                        vehicle: Object.assign(Object.assign({}, vehicle), { vin: '41111111111111111' }),
                                        consumerCarId: (_a = vehicle.consumerCarId) !== null && _a !== void 0 ? _a : 0,
                                        consumerId: (_b = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) === null || _b === void 0 ? void 0 : _b.consumerId,
                                        repairRequestId: requestId,
                                    }));
                                }
                                setIsLoading(false);
                            },
                            copy: 'Skip 4 Corner',
                            disabled: false,
                        });
                    }
                    actions.push({
                        function: () => {
                            setIsLoading(false);
                            if (isMobile) {
                                dispatch(openMobileCarDetails());
                                setTimeout(() => {
                                    dispatch(openFourCornerModal(true));
                                }, 50);
                            }
                            else {
                                dispatch(openFourCornerModal());
                            }
                        },
                        copy: getLabelCurrentTaskWorkFlow(),
                        disabled: false,
                    });
                    if (allowRemove) {
                        actions.push(removeAction);
                    }
                    break;
                case 'GET_MEASUREMENTS_SKIPPABLE':
                    actions.push({
                        function: () => {
                            dispatch(openMeasurementsSection(true));
                        },
                        copy: 'Measurements',
                        disabled: false,
                    });
                    break;
                case 'MPI_PERFORMED':
                    if (canSkipTasks) {
                        actions.push({
                            function: () => {
                                completeTask();
                                window.location.reload();
                                setIsLoading(false);
                            },
                            copy: 'Skip MPI',
                            disabled: false,
                        });
                    }
                    actions.push({
                        function: () => {
                            if (!mpi) {
                                window.location.href = getFieldOpsUrl(`mpi/create?repairRequestId=${referenceNum}`);
                            }
                            else {
                                if (mpi.completedAt) {
                                    analyticsTrackEvent('Edit MPI', {
                                        'User Name': `${currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName} ${currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName}`,
                                    });
                                }
                                else {
                                    analyticsTrackEvent('Continue MPI', {
                                        'User Name': `${currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName} ${currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName}`,
                                    });
                                }
                                window.location.href = getFieldOpsUrl(`mpi/${mpi.id}`);
                            }
                        },
                        copy: getLabelCurrentTaskWorkFlow(),
                        disabled: false,
                    });
                    break;
                case 'GET_MEASUREMENTS':
                    actions.push({
                        function: () => {
                            dispatch(openMeasurementsSection(true, undefined, true));
                        },
                        copy: 'Measurements',
                        disabled: false,
                    });
                    break;
                case 'INVOICE_CREATED':
                    actions.push({
                        function: () => {
                            onCompleteJob();
                        },
                        copy: getLabelCurrentTaskWorkFlow(),
                        disabled: isCompleteJobCTADisabled,
                        tooltipIcon: isCompleteJobCTADisabled
                            ? completeJobDisabledByPartsStatusTooltip
                            : '',
                    });
                    break;
                case 'CONFIRM_PARTS':
                    actions.push({
                        function: () => {
                            setOpenConfirmPartsFitmentDrawer(true);
                        },
                        copy: 'Confirm Parts',
                    });
                    break;
                case 'CONCERN_CAUSE_CORRECTION':
                    if (remaining3CNotes > 0) {
                        actions.push({
                            function: () => {
                                dispatch(openNotesDrawer());
                            },
                            copy: 'Add 3Cs',
                            disabled: false,
                        });
                    }
                    else {
                        actions.push({
                            function: () => {
                                complete3Ctask();
                            },
                            copy: 'Complete 3Cs Task',
                            disabled: isLoading,
                        });
                    }
                    break;
                default:
                    break;
            }
            if (requestState === 'REPAIR_IN_PROGRESS') {
                actions.push({
                    function: openCantFinish,
                    copy: "Can't Complete",
                    disabled: false,
                });
            }
        }
        // LOCK-UNLOCK RO CTAS
        if (isLockedRoEditor &&
            requestState === RepairRequestState.CLOSED_REPAIR &&
            ((_a = request === null || request === void 0 ? void 0 : request.repairOrder) === null || _a === void 0 ? void 0 : _a.resolution) ===
                RepairOrderResolution.REPAIR_COMPLETED_WITH_INVOICE) {
            if (requestLock.locked) {
                actions.push({
                    function: handleOpenConfirmUnlockRoMOdal,
                    copy: 'Edit RO',
                });
            }
            else if (requestLock.id) {
                actions.push({
                    function: handleLockRo,
                    copy: 'Re-Lock RO',
                });
            }
        }
        if (isCantFinish) {
            return [];
        }
        return actions;
    };
    const getNonVisitActions = (requestState, currentTask, activeAppointmentStatus) => {
        var _a;
        const actions = [];
        // WORKFLOW CTAS
        if (['CONFIRMED_APPOINTMENT', 'REPAIR_IN_PROGRESS', 'CLOSED_REPAIR'].includes(requestState) &&
            isTechnician &&
            skeduloJobExists) {
            switch (currentTask) {
                case 'TRAVEL_STARTED':
                    actions.push({
                        function: () => __awaiter(void 0, void 0, void 0, function* () {
                            setIsLoading(true);
                            const address = request === null || request === void 0 ? void 0 : request.address;
                            let driveTimeToCustomerMinutes = 0;
                            if (address && currentLocation) {
                                const directionsService = new window.google.maps.DirectionsService();
                                const requestMaps = {
                                    origin: `${currentLocation.latitude},${currentLocation.longitude}`,
                                    destination: address.latitude
                                        ? `${address.latitude}, ${address.longitude}`
                                        : `${address.street}, ${address.city}, ${address.state}, ${address.zip}`,
                                    travelMode: 'DRIVING',
                                };
                                directionsService.route(requestMaps, (result, status) => {
                                    if (status === 'OK') {
                                        const point = result.routes[0].legs[0];
                                        driveTimeToCustomerMinutes = Math.floor(point.duration.value / 60);
                                    }
                                });
                            }
                            yield completeTaskAndLaunchMaps({
                                technicianId,
                                notifyCustomer: true,
                                driveTimeToCustomerMinutes,
                            });
                            toast.success('We’ve alerted the customer you’re on the way, drive safe!');
                        }),
                        copy: getLabelCurrentTaskWorkFlow(),
                        disabled: lockStartTravelUntilPickedUp &&
                            hasTechnicianAuthGroup &&
                            !partsArePickedUp,
                        tooltipIcon: !lockStartTravelUntilPickedUp ||
                            !hasTechnicianAuthGroup ||
                            partsArePickedUp
                            ? ''
                            : 'All parts must be picked up. Please complete the part pickup tasks.',
                    });
                    break;
                case 'ARRIVED':
                    actions.push({
                        function: () => {
                            setIsLoading(true);
                            setOpenModalNotifyUser(true);
                        },
                        copy: getLabelCurrentTaskWorkFlow(),
                    });
                    break;
                case 'REPAIRS_AUTHORIZED':
                    actions.push({
                        function: openAuthorizationScript,
                        copy: getLabelCurrentTaskWorkFlow(),
                    });
                    break;
                case 'FOUR_CORNER':
                    if (canSkipTasks) {
                        actions.push({
                            function: () => {
                                var _a, _b;
                                completeTask();
                                if (isFleet && !(vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin)) {
                                    dispatch(updateCar({
                                        referenceNum,
                                        vehicle: Object.assign(Object.assign({}, vehicle), { vin: '41111111111111111' }),
                                        consumerCarId: (_a = vehicle.consumerCarId) !== null && _a !== void 0 ? _a : 0,
                                        consumerId: (_b = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) === null || _b === void 0 ? void 0 : _b.consumerId,
                                        repairRequestId: requestId,
                                    }));
                                }
                                setIsLoading(false);
                            },
                            copy: 'Skip 4 Corner',
                        });
                    }
                    actions.push({
                        function: () => {
                            if (isMobile) {
                                dispatch(openMobileCarDetails());
                                setTimeout(() => {
                                    dispatch(openFourCornerModal(true));
                                }, 50);
                            }
                            else {
                                dispatch(openFourCornerModal());
                            }
                        },
                        copy: getLabelCurrentTaskWorkFlow(),
                    });
                    break;
                case 'GET_MEASUREMENTS_SKIPPABLE':
                    actions.push({
                        function: () => {
                            dispatch(openMeasurementsSection(true));
                        },
                        copy: 'Measurements',
                        disabled: false,
                    });
                    break;
                case 'MPI_PERFORMED':
                    if (canSkipTasks) {
                        actions.push({
                            function: () => {
                                completeTask();
                                window.location.reload();
                                setIsLoading(false);
                            },
                            copy: 'Skip MPI',
                        });
                    }
                    actions.push({
                        function: () => {
                            setIsLoading(true);
                            if (!mpi) {
                                window.location.href = getFieldOpsUrl(`mpi/create?repairRequestId=${referenceNum}`);
                            }
                            else {
                                if (mpi.completedAt) {
                                    analyticsTrackEvent('Edit MPI', {
                                        'User Name': `${currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName} ${currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName}`,
                                    });
                                }
                                else {
                                    analyticsTrackEvent('Continue MPI', {
                                        'User Name': `${currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName} ${currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName}`,
                                    });
                                }
                                window.location.href = getFieldOpsUrl(`mpi/${mpi.id}`);
                            }
                        },
                        copy: getLabelCurrentTaskWorkFlow(),
                    });
                    break;
                case 'GET_MEASUREMENTS':
                    actions.push({
                        function: () => {
                            dispatch(openMeasurementsSection(true, undefined, true));
                        },
                        copy: 'Measurements',
                        disabled: false,
                    });
                    break;
                case 'INVOICE_CREATED':
                    actions.push({
                        function: () => {
                            onCompleteJob();
                        },
                        copy: getLabelCurrentTaskWorkFlow(),
                        disabled: isCompleteJobCTADisabled,
                        tooltipIcon: isCompleteJobCTADisabled
                            ? completeJobDisabledByPartsStatusTooltip
                            : undefined,
                    });
                    break;
                case 'CONCERN_CAUSE_CORRECTION':
                    if (remaining3CNotes > 0) {
                        actions.push({
                            function: () => {
                                dispatch(openNotesDrawer());
                            },
                            copy: 'Add 3Cs',
                            disabled: false,
                        });
                    }
                    else {
                        actions.push({
                            function: () => {
                                complete3Ctask();
                            },
                            copy: 'Complete 3Cs Task',
                            disabled: isLoading,
                        });
                    }
                    break;
                case 'CONFIRM_PARTS':
                    actions.push({
                        function: () => {
                            setOpenConfirmPartsFitmentDrawer(true);
                        },
                        copy: 'Confirm Parts',
                    });
                    break;
                case 'PAYMENT_COLLECTED':
                    actions.push({
                        function: () => __awaiter(void 0, void 0, void 0, function* () {
                            setIsLoading(true);
                            yield dispatch(openPaymentSection(true));
                            setIsLoading(false);
                        }),
                        copy: 'Collect Payment',
                    });
                    break;
                case 'RS_WARRANTY_CLAIM_INSPECTION_PERFORMED':
                    actions.push({
                        function: () => __awaiter(void 0, void 0, void 0, function* () {
                            setIsLoading(true);
                            yield dispatch(openWarrantyQuestionSection(true));
                            setIsLoading(false);
                        }),
                        copy: 'RepairSmith Warranty',
                    });
                    break;
                default:
                    // FAIL SAFE
                    // FOR SYSTEM ADMINS ONLY
                    if (isSystemAdmin && currentTask) {
                        actions.push({
                            function: () => {
                                completeTask();
                                setIsLoading(false);
                            },
                            copy: getLabelCurrentTaskWorkFlow(),
                        });
                    }
                    break;
            }
        }
        // LOCK-UNLOCK RO CTAS
        if (isLockedRoEditor &&
            requestState === RepairRequestState.CLOSED_REPAIR &&
            ((_a = request === null || request === void 0 ? void 0 : request.repairOrder) === null || _a === void 0 ? void 0 : _a.resolution) ===
                RepairOrderResolution.REPAIR_COMPLETED_WITH_INVOICE) {
            if (requestLock.locked) {
                actions.push({
                    function: handleOpenConfirmUnlockRoMOdal,
                    copy: 'Edit RO',
                });
            }
            else if (requestLock.id) {
                actions.push({
                    function: handleLockRo,
                    copy: 'Re-Lock RO',
                });
            }
        }
        // SCHEDULING CTAS
        if (requestState === 'OPEN_REQUEST' &&
            activeAppointmentStatus === 'CANCELED') {
            actions.push({
                function: openRescheduleCanceledQuickRo,
                copy: 'Reschedule Appointment',
                disabled: isRescheduleCTADisabled,
            });
        }
        else if (requestState === 'OPEN_REQUEST' &&
            activeAppointmentStatus === 'PENDING' &&
            skeduloJobExists) {
            actions.push({
                function: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    yield dispatch(confirmRequestAppointment(referenceNum));
                    yield dispatch(getRequest(referenceNum, requestId !== null && requestId !== void 0 ? requestId : ''));
                    dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
                    setIsLoading(false);
                }),
                copy: 'Confirm Appointment',
                disabled: !shouldEnableConfirmAppointment,
                tooltip: shouldEnableConfirmAppointment
                    ? ''
                    : 'Cannot confirm appointment unless appointment date/time, delivery channel, ISP, and all total costs for each repair are entered and quote is provided.',
            });
            if (isCancelAppts) {
                actions.push({
                    function: openCancellationReasonsPending,
                    copy: 'Cancel/Reschedule',
                    disabled: false,
                    mobileVariant: 'text',
                });
            }
        }
        else if (requestState === 'OPEN_REQUEST' &&
            activeAppointmentStatus === 'PENDING' &&
            (activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.scheduleStartLocal) &&
            !skeduloJobExists) {
            if (isCancelAppts) {
                actions.push({
                    function: openCancellationReasonsPending,
                    copy: 'Cancel/Reschedule',
                    disabled: false,
                    mobileVariant: 'text',
                });
            }
        }
        else if (requestState === 'OPEN_REQUEST') {
            actions.push({
                function: openScheduleQuickRo,
                copy: 'Schedule Appointment',
                disabled: !shouldEnableScheduleAppointment,
                tooltipIcon: shouldEnableScheduleAppointment
                    ? undefined
                    : scheduleRequestToolTip,
            });
        }
        else if (requestState === 'CONFIRMED_APPOINTMENT' && skeduloJobExists) {
            if (isCancelAppts) {
                actions.push({
                    function: openCancellationReasonsPending,
                    copy: 'Cancel/Reschedule',
                    disabled: false,
                    mobileVariant: 'text',
                });
            }
        }
        else if (requestState === 'REPAIR_IN_PROGRESS') {
            actions.push({
                function: openCantFinish,
                copy: "Can't Complete",
                disabled: false,
            });
        }
        if (isCantFinish && currentTask !== 'PAYMENT_COLLECTED') {
            return [];
        }
        return actions;
    };
    const completeTask = (fields = {}, awaitRequestState = null) => __awaiter(void 0, void 0, void 0, function* () {
        setOpenModalNotifyUser(false);
        if (currentTaskWorkFlow) {
            yield dispatch(completeTechnicianWorkflowOnSiteSingleTask(referenceNum, Object.assign(Object.assign(Object.assign({}, currentTaskWorkFlow), fields), { coordinates: {
                    latitude: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.latitude,
                    longitude: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.longitude,
                } })));
            (() => __awaiter(void 0, void 0, void 0, function* () {
                var _o, _p;
                if (awaitRequestState) {
                    yield dispatch(getRequestUntilExpectedState(referenceNum, requestId, awaitRequestState));
                }
                if (((_o = workflow.nextTask) === null || _o === void 0 ? void 0 : _o.state) === 'CONFIRM_PARTS' &&
                    ((_p = workflow.nextTask) === null || _p === void 0 ? void 0 : _p.status) === 'PENDING' &&
                    (repairFitments === null || repairFitments === void 0 ? void 0 : repairFitments.length) === 0) {
                    dispatch(completeTechnicianWorkflowOnSiteSingleTask(referenceNum, Object.assign(Object.assign({}, workflow.nextTask), { notes: 'Autocompleted - no multiple fitments' })));
                }
                yield dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
                setIsLoading(false);
            }))();
        }
    });
    const requestUntilJobComplete = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield dispatch(getRequestUntilExpectedState(referenceNum, requestId, 'CLOSED_REPAIR'));
        setIsCantFinish(true);
        yield dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
        yield getInvoicesByWorkOrderId({ workOrderId });
        setIsLoading(false);
    });
    const handleAuthorizeQuote = (method, authorizedByName) => __awaiter(void 0, void 0, void 0, function* () {
        if (!workOrderId || !requestId)
            return;
        setIsLoading(true);
        dispatch(getWorkOrderPayments(workOrderId));
        yield dispatch(authorizeQuote({
            quoteId,
            workOrderId,
            method,
            authorizedByName,
            referenceNum,
        }));
        yield dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
        yield dispatch(getRequestUntilExpectedState(referenceNum, requestId, 'REPAIR_IN_PROGRESS'));
        dispatch(setArrivalExtraMinutesTimestamp(undefined));
        setIsLoading(false);
    });
    const openMaps = () => {
        let url = '';
        if (request === null || request === void 0 ? void 0 : request.address) {
            const addressFormatted = `${request.address.street} ${request.address.city}, ${request.address.state} ${request.address.zip}`.replace(/null/g, '');
            if (isIOS) {
                url = `http://maps.apple.com/?daddr=${addressFormatted}&dirflg=d&t=m`;
            }
            else if (currentLocation) {
                url = `https://www.google.com/maps/dir/?api=1&origin=${currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.latitude},${currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.longitude}&destination=${addressFormatted}`;
            }
        }
        if (url.length) {
            window.open(url, 'location=yes');
        }
    };
    const completeTaskAndLaunchMaps = (fields = {}) => __awaiter(void 0, void 0, void 0, function* () {
        yield completeTask(fields);
        openMaps();
    });
    const handleRemoveROConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!request)
            return;
        if (request.visit.status === 'IN_PROGRESS') {
            handleROCancelConfirmClose();
            setOpenedModal('cancellationReasons');
        }
        else {
            yield dispatch(cancelROVisitAppointment(referenceNum, request.workOrder.id, request.visit.refNum, null));
            handleROCancelConfirmClose();
            return dispatch(getRequest(referenceNum, requestId));
        }
    });
    const handleCloseRecordMeasurement = () => {
        dispatch(openMeasurementsSection(false));
    };
    const hideOpenedModal = () => {
        setOpenedModal(undefined);
    };
    const openModal = (modalName) => {
        setOpenedModal(modalName);
    };
    const handleCloseVisitTechnicianDrawer = (technicianId) => {
        setIsSelectVisitTechnicianOpen(false);
        if (technicianId && Number.isInteger(technicianId)) {
            setIsLoading(true);
            completeTask({ technicianId }, 'REPAIR_IN_PROGRESS');
        }
    };
    const handleSubmitRepairOrderSolution = (...values) => {
        const params = Object.assign({}, values);
        const response = dispatch(closeRequestOrder(params));
        if (response && !response.error) {
            toast.success('Repair successfully closed.');
        }
    };
    const handleCloseRecordMeasurementDrawer = () => dispatch(openMeasurementsSection(false));
    const handleSubmitConfirmUnlock = () => {
        setOpenConfirmUnlockRoModal(false);
        setOpenedModal('requestUnlockReasons');
    };
    const handleCloseConfirmUnlock = () => {
        setOpenConfirmUnlockRoModal(false);
        setIsLoading(false);
    };
    const handleCloseNotifyUserModal = () => {
        setOpenModalNotifyUser(false);
        setIsLoading(false);
    };
    const handleCloseExpiredArrivalTimerModal = () => {
        dispatch(closeExpiredArrivalTimerModal());
    };
    const handleAddExtraMinutesToArrivalTimer = () => {
        dispatch(setArrivalExtraMinutesTimestamp(moment()));
    };
    const handleCloseConfirmPartsFitmentDrawer = () => {
        setOpenConfirmPartsFitmentDrawer(false);
    };
    const completeJobDisabledByPartsStatusTooltip = 'All parts and fluids on the work order must be ordered. ' +
        'Please fill in the details of all components (part number, vendor, vendor address, etc.)';
    const actions = useMemo(() => {
        var _a, _b, _c;
        if (hasVisit) {
            return getVisitActions((_a = request === null || request === void 0 ? void 0 : request.visit.status) !== null && _a !== void 0 ? _a : '', state, (_b = currentTaskWorkFlow === null || currentTaskWorkFlow === void 0 ? void 0 : currentTaskWorkFlow.state) !== null && _b !== void 0 ? _b : '');
        }
        const activeAppointmentStatus = request === null || request === void 0 ? void 0 : request.activeAppointment.status;
        return getNonVisitActions(state, (_c = currentTaskWorkFlow === null || currentTaskWorkFlow === void 0 ? void 0 : currentTaskWorkFlow.state) !== null && _c !== void 0 ? _c : '', activeAppointmentStatus !== null && activeAppointmentStatus !== void 0 ? activeAppointmentStatus : '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state,
        hasVisit,
        request,
        currentTaskWorkFlow,
        isTechnician,
        skeduloJobExists,
        isMobile,
        requestLock,
        isCantFinish,
    ]);
    return {
        openedQuickROType,
        openROCancelConfirm,
        isPartOrdered,
        isFleet,
        isTechnician,
        isPartner,
        isFunnel,
        isSystemAdmin,
        isPaymentPreauth,
        isLockedRoEditor,
        isCancelAppts,
        canSkipTasks,
        history,
        vehicle,
        isMobile,
        workflow,
        workOrder,
        handleSubmitRepairOrderSolution,
        timeSlot,
        request,
        diagnosis,
        isLoading,
        requestData,
        isSelectVisitTechnicianOpen,
        openedModal,
        user,
        fleet,
        fleetById,
        isCarDetailsOpen,
        setIsCarDetailsOpen,
        openModalNotifyUser,
        setOpenModalNotifyUser,
        preSelectedMechanics,
        preSelectedVan,
        getDeliveryChannel,
        getHeaderText,
        skeduloJobExists,
        getRepairs,
        getLabelCurrentTaskWorkFlow,
        setUpQuickRO,
        shouldEnableConfirmAppointment,
        shouldEnableScheduleAppointment,
        scheduleRequestToolTip,
        isRescheduleCTADisabled,
        isCompleteJobCTADisabled,
        actions,
        getNonVisitActions,
        requestPreAuthorization,
        workOrderId,
        completeTask,
        requestUntilJobComplete,
        handleROCancelConfirmClose,
        handleRemoveROConfirm,
        handleLockRo,
        handleUnlockRo,
        handleOpenConfirmUnlockRoMOdal,
        openQuickRo,
        closeModal,
        closeCancellationReasons,
        openRescheduleCanceledQuickRo,
        openScheduleAllRecommendRepairs,
        openCancellationReasonsPending,
        openCantFinish,
        openCantFinishScreenForMeasurements,
        openScheduleQuickRo,
        openAuthorizationScript,
        onCompleteJob,
        getMilesOut,
        getVisitActions,
        handleAuthorizeQuote,
        openMaps,
        handleCloseRecordMeasurement,
        openModal,
        hideOpenedModal,
        handleCloseVisitTechnicianDrawer,
        handleCloseRecordMeasurementDrawer,
        activeAppointment,
        cancellationValues,
        handleSubmitConfirmUnlock,
        requestLock,
        currentTaskWorkFlow,
        currentLocation,
        currentUser,
        hasPhotos,
        handleCloseConfirmUnlock,
        completeJob,
        mpiMainButtonText,
        technicianId,
        primaryInvoice,
        hasVisit,
        handleCloseNotifyUserModal,
        workOrderResponsiblePartyTypeTotals,
        openConfirmUnlockRoModal,
        requestId,
        quoteId,
        mpi,
        isArrivalTimerExpired,
        expiredArrivalTimerModalOpen,
        handleCloseExpiredArrivalTimerModal,
        handleAddExtraMinutesToArrivalTimer,
        open3CDrawer,
        close3CDrawer,
        is3CDrawerOpen,
        closeCantFinishDrawer,
        requestCoreCharges,
        vanId,
        openConfirmPartsFitmentDrawer,
        handleCloseConfirmPartsFitmentDrawer,
        repairFitments,
    };
};
export default useRequestCTAsContainer;
