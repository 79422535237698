import React, { useState } from 'react';
import Map, { calculateBounds } from 'src/components/maps/Map';
import { Typography } from 'src/components/mui';
import { DrivingTimeWrapper, DrivingStepsWrapper } from './styledComponents';
const MapAndDirections = ({ address, origin, destination, deliveryChannel, }) => {
    var _a, _b, _c, _d, _e, _f;
    const shouldShowDirections = deliveryChannel !== 'DELIVERY';
    const shouldShowMapAndDirections = ((_a = origin === null || origin === void 0 ? void 0 : origin.lat) !== null && _a !== void 0 ? _a : null) && ((_b = destination === null || destination === void 0 ? void 0 : destination.lng) !== null && _b !== void 0 ? _b : null);
    const markers = shouldShowDirections ? [origin, destination] : [origin];
    const [route, setRoute] = useState(null);
    const updateRoute = (_route) => { var _a; return setRoute((_a = _route === null || _route === void 0 ? void 0 : _route.legs[0]) !== null && _a !== void 0 ? _a : null); };
    const driveDistance = (_d = (_c = route === null || route === void 0 ? void 0 : route.distance) === null || _c === void 0 ? void 0 : _c.text) !== null && _d !== void 0 ? _d : '';
    const driveTime = (_f = (_e = route === null || route === void 0 ? void 0 : route.duration) === null || _e === void 0 ? void 0 : _e.text) !== null && _f !== void 0 ? _f : '';
    const renderDrivingSteps = () => {
        var _a;
        const driveSteps = (_a = route === null || route === void 0 ? void 0 : route.steps) !== null && _a !== void 0 ? _a : [];
        return (<DrivingStepsWrapper>
        {driveSteps.map((driveStep, idx) => {
                const { distance, duration, instructions, travel_mode } = driveStep;
                return (<li key={distance.text + idx}>
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: instructions }}/>{' '}
              <span style={{
                        display: idx === driveSteps.length ? 'none' : 'initial',
                    }}>
                ({travel_mode} for {distance.text}/{duration.text})
              </span>
            </li>);
            })}
      </DrivingStepsWrapper>);
    };
    const departureAddress = address
        ? `from: ${address.street} ${address.city ? `, ${address.city}, ` : ''} ${address.state} ${address.zip}`
        : '';
    const destinationAddress = destination
        ? `to: ${destination.name} (${destination.address}, ${destination.zip})`
        : '';
    return (shouldShowMapAndDirections && (<>
        <Map style={{ marginTop: '8px', width: '100%' }} origin={origin} destination={shouldShowDirections ? destination : null} markers={markers} bounds={calculateBounds(markers)} displayRoute={updateRoute} showRoute={shouldShowDirections}/>

        {shouldShowDirections && (<>
            <DrivingTimeWrapper>
              Driving Time: <b>{driveTime}</b> Distance: <b>{driveDistance}</b>
            </DrivingTimeWrapper>
            <Typography variant="subtitle1" component="span" style={{ fontWeight: 'normal' }}>
              <div>{departureAddress}</div>
              <div>{destinationAddress}</div>
            </Typography>
            <Typography variant="subtitle1">Directions</Typography>
            <Typography variant="subtitle1" component="span" style={{ fontWeight: 'normal' }}>
              {renderDrivingSteps()}
            </Typography>
          </>)}
      </>));
};
export default MapAndDirections;
