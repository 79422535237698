import React from 'react';
import { TooltipInfo } from 'src/components/tooltips';
import { TooltipContainer, RepairsTooltipTitle, RepairsTooltipList, } from './RepairsTooltip.styles';
const RepairsTooltip = ({ allRepairs }) => {
    return (<TooltipInfo>
      <TooltipContainer>
        <RepairsTooltipTitle>Repairs</RepairsTooltipTitle>
        <RepairsTooltipList>
          {allRepairs.map((r) => r.name).join(' • ')}
        </RepairsTooltipList>
      </TooltipContainer>
    </TooltipInfo>);
};
export default RepairsTooltip;
