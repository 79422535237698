import React from 'react';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import useResponsibleParties from './useResponsibleParties';
import RESPONSIBLE_PARTIES_COLUMNS from './PartiesColumns';
import PartiesItem from './PartiesItem';
import Filters from './Filters';
const PartiesTable = ({ onEdit, }) => {
    const { pagination, partiesData, tableSort, partiesFilters, clearFilters, searchData, onLoadData, } = useResponsibleParties();
    const onSearch = (filters) => {
        searchData({ filters });
    };
    const onReset = () => {
        clearFilters();
    };
    return (<div>
      <Filters filters={partiesFilters} onSearch={onSearch} onReset={onReset}/>
      <SimpleTable hideTopPagination data={partiesData} pagination={pagination} sort={tableSort} columns={RESPONSIBLE_PARTIES_COLUMNS} renderItem={(item) => (<PartiesItem key={item.id} party={item} onEdit={onEdit}/>)} onLoadData={onLoadData}/>
    </div>);
};
export default PartiesTable;
