import React from 'react';
import { useDispatch } from 'react-redux';
import pluralize from 'pluralize';
import { Button } from 'src/components/mui';
import { setSelectedJobsForOrdering } from 'src/PopsApp/modules/parts/actions';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { JobsFooterContainer, FooterItem } from './JobsFooter.styles';
const JobsFooter = ({ selectedJobs, partsSelectedCount }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { region, ispId } = useParams();
    const handleOrderParts = () => {
        dispatch(setSelectedJobsForOrdering(selectedJobs));
        history.push(`/pops/regions/${region}/vans/${ispId}/jobs/vendors`);
    };
    const selectedJobsCount = Object.keys(selectedJobs).length;
    return (<JobsFooterContainer>
      <div className="jobsStats">
        <FooterItem>
          {selectedJobsCount} {pluralize('Job', selectedJobsCount)} Selected
        </FooterItem>
        <FooterItem>
          {partsSelectedCount} {pluralize('Part', partsSelectedCount)} Selected
        </FooterItem>
      </div>
      <Button className="orderPartsBtn" color="primary" variant="contained" disabled={Object.keys(selectedJobs).length === 0} onClick={handleOrderParts}>
        Order {pluralize('Part', partsSelectedCount)}
      </Button>
    </JobsFooterContainer>);
};
export default JobsFooter;
