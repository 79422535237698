const statusesNeedingAvailabilityCheck = [
    'PENDING',
    'ORDER_FAILED',
    'NOT_PICKED_UP',
    'WRONG_PART',
];
const componentAvailable = (part, vendorCatalog, carId, productId) => {
    var _a;
    if (!vendorCatalog ||
        !vendorCatalog[carId] ||
        !vendorCatalog[carId][productId] ||
        !vendorCatalog[carId][productId].components) {
        return false;
    }
    return (_a = vendorCatalog[carId][productId].components) === null || _a === void 0 ? void 0 : _a.some((catalogComponent) => catalogComponent.repairComponentId === part.pricingData.repairComponentId);
};
const failedAvailabilityCall = (vendorCatalog, carId, productId) => {
    if (vendorCatalog &&
        vendorCatalog[carId] &&
        vendorCatalog[carId][productId] &&
        vendorCatalog[carId][productId].components === null) {
        return true;
    }
    return false;
};
export const componentNeeded = (component) => {
    if (component.status != null &&
        !statusesNeedingAvailabilityCheck.includes(component.status))
        return false;
    return true;
};
export const calculateComponentsAvailability = (partDistributorLocationId, selectedJobs, vendorPartsCatalog, vendorFluidsCatalog) => {
    let availableComponents = 0;
    let neededComponents = 0;
    let failedRequests = 0;
    const availableRepairComponentIds = [];
    selectedJobs.forEach((job) => {
        var _a;
        (_a = job.services) === null || _a === void 0 ? void 0 : _a.knownRepairs.forEach((repair) => {
            if (repair.price[0]) {
                const { parts, fluids } = repair.price[0];
                const neededParts = parts.filter(componentNeeded);
                const neededFluids = fluids.filter(componentNeeded);
                neededComponents += neededParts.length;
                neededComponents += neededFluids.length;
                failedRequests += failedAvailabilityCall(vendorPartsCatalog, job.car.id, repair.nodeId)
                    ? 1
                    : 0;
                availableComponents += neededParts.filter((part) => {
                    const isComponentAvailable = componentAvailable(part, vendorPartsCatalog, job.car.id, repair.nodeId);
                    if (isComponentAvailable &&
                        !availableRepairComponentIds.includes(part.pricingData.repairComponentId)) {
                        availableRepairComponentIds.push(part.pricingData.repairComponentId);
                    }
                    return isComponentAvailable;
                }).length;
                availableComponents += neededFluids.filter((fluid) => {
                    const isFluidAvailable = componentAvailable(fluid, vendorFluidsCatalog, job.car.id, repair.nodeId);
                    if (isFluidAvailable &&
                        !availableRepairComponentIds.includes(fluid.pricingData.repairComponentId)) {
                        availableRepairComponentIds.push(fluid.pricingData.repairComponentId);
                    }
                    return isFluidAvailable;
                }).length;
            }
        });
        job.services.customRequests.forEach((repair) => {
            if (repair.price[0]) {
                const { parts, fluids } = repair.price[0];
                neededComponents += parts.filter(componentNeeded).length;
                neededComponents += fluids.filter(componentNeeded).length;
            }
        });
    });
    return {
        allComponentsAvailable: availableComponents === neededComponents,
        availableComponents,
        neededComponents,
        failedRequests,
        availableRepairComponentIds,
    };
};
export const createComponentAvailabilityRequests = (knownRepairs) => {
    if (!knownRepairs)
        return [];
    return knownRepairs.map((repair) => {
        var _a, _b, _c, _d;
        return {
            productId: repair.nodeId,
            part: (_b = (_a = repair.price[0]) === null || _a === void 0 ? void 0 : _a.parts) === null || _b === void 0 ? void 0 : _b.some((part) => statusesNeedingAvailabilityCheck.includes(part.status)),
            fluid: (_d = (_c = repair.price[0]) === null || _c === void 0 ? void 0 : _c.fluids) === null || _d === void 0 ? void 0 : _d.some((fluid) => statusesNeedingAvailabilityCheck.includes(fluid.status)),
        };
    });
};
