import React from 'react';
import { QuantityButton, QuantityLabel, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
const QuantitySelector = ({ value, maxQuantity, onQuantityChange, }) => {
    const increaseQuantity = () => {
        if (value < maxQuantity) {
            const newQuantity = value + 1;
            onQuantityChange(newQuantity);
        }
    };
    const decreaseQuantity = () => {
        if (value > 0) {
            const newQuantity = value - 1;
            onQuantityChange(newQuantity);
        }
    };
    return (<div style={{ display: 'flex', alignItems: 'center' }}>
      <QuantityButton onClick={decreaseQuantity} disabled={value <= 0}>
        -
      </QuantityButton>
      <QuantityLabel>{value}</QuantityLabel>
      <QuantityButton onClick={increaseQuantity} disabled={value >= maxQuantity}>
        +
      </QuantityButton>
    </div>);
};
export default QuantitySelector;
