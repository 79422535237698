import React from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboardButton } from 'src/components/buttons';
const ReferenceNumLinkChip = ({ referenceNum, }) => {
    return (<div className="referenceNumLinkChip">
      <Link to={`/admin/repairs/${referenceNum}`}>{referenceNum}</Link>
      <CopyToClipboardButton value={`${location.origin}/admin/repairs/${referenceNum}`}/>
    </div>);
};
export default ReferenceNumLinkChip;
