import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { uploadDocuments, deleteDocument, } from 'src/AdminApp/modules/workOrders/actions';
import { selectDocumentsByWorkOrderId } from 'src/AdminApp/modules/workOrders/selectors';
const useOrderReceipt = ({ workOrderId }) => {
    const dispatch = useDispatch();
    const documents = useSelector(selectDocumentsByWorkOrderId(workOrderId));
    const [invoiceDocumentOpen, setInvoiceDocumentOpen] = useState(false);
    const [documentUrl, setDocumentUrl] = useState('');
    const onInvoiceDocumentOpen = (document) => {
        setInvoiceDocumentOpen(true);
        setDocumentUrl(document.url);
    };
    const onInvoiceDocumentClose = () => {
        setInvoiceDocumentOpen(false);
    };
    const onDrop = (documents) => {
        dispatch(uploadDocuments(workOrderId, documents));
    };
    const onDelete = (document) => {
        dispatch(deleteDocument(workOrderId, document));
    };
    return {
        dispatch,
        documents,
        invoiceDocumentOpen,
        documentUrl,
        setInvoiceDocumentOpen,
        setDocumentUrl,
        onInvoiceDocumentOpen,
        onInvoiceDocumentClose,
        onDrop,
        onDelete,
    };
};
export default useOrderReceipt;
