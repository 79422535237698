import React from 'react';
import { sendMpiReport } from 'src/AdminApp/containers/vehicle/CarModule/helpers/sendMpi';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'src/AdminApp/modules/users/selectors';
import { useParams } from 'react-router';
import { updateInspectionStatus } from 'src/AdminApp/containers/vehicle/CarModule/helpers/updateInspectionStatus';
import { selectInspectionPerformedStatus } from 'src/AdminApp/modules/mpi/selectors';
import { SimpleModal } from 'src/components/layout';
const SendHealthReportDialog = ({ mpi, consumer, vehicle, mpiInspector, open, onClose, suppressNotifications = false, }) => {
    var _a;
    const dispatch = useDispatch();
    const params = useParams();
    const { referenceNum } = params;
    const currentUser = useSelector(selectCurrentUser);
    const inspectionPerformedStatus = useSelector(selectInspectionPerformedStatus);
    const currentAuthGroups = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.authGroups) !== null && _a !== void 0 ? _a : [];
    const currentUserIsSA = currentAuthGroups.includes('Service Advisors');
    const handleSend = () => {
        sendMpiReport({
            mpi,
            consumer,
            vehicle,
            mpiInspector,
            resend: !!inspectionPerformedStatus.lastSent,
            suppressNotifications: suppressNotifications !== null && suppressNotifications !== void 0 ? suppressNotifications : false,
        });
        if (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) {
            updateInspectionStatus({
                dispatch,
                inspectionPerformedStatus,
                currentUserIsSA,
                currentUser,
                currentUserId: currentUser === null || currentUser === void 0 ? void 0 : currentUser.id,
                referenceNum,
                sentToCustomer: true,
            });
        }
        onClose();
    };
    return (<SimpleModal open={open} onClose={onClose} title="Would you like to Re-Send Report to Consumer?" simpleTwoButtonRowProps={{
            onCancel: onClose,
            onSubmit: handleSend,
            submitText: 'Send',
        }}/>);
};
export default SendHealthReportDialog;
