import React from 'react';
import styled from 'styled-components';
import { Box, IconButton, TableCell, TableRow } from 'src/components/mui';
import { Delete as DeleteIcon, KeyboardArrowRight as CollapsedIcon, KeyboardArrowDown as ExpandedIcon, Refresh as RefreshIcon, } from 'src/components/mui/icons';
import moment from 'moment';
import { DATE_TIME_12H_FORMAT_WITH_SECONDS } from 'src/timeConstants';
import StatusWidget from './StatusWidget';
import ConnectorDetails from './ConnectorDetails';
const PrimaryTableRow = styled(TableRow) ``;
const SecondaryTableRow = styled(TableRow) `
  background-color: #fcfcfc;
  .box {
    padding-left: 50px;
  }
`;
const ConnectorRow = (props) => {
    var _a;
    const { connector, expanded, info, status } = props;
    const { deleteConnector, loadStatus, pauseConnector, resumeConnector, restartConnector, restartTask, toggleRow, } = props;
    const deleteConnectorClicked = () => deleteConnector(connector);
    const loadStatusClicked = () => loadStatus(connector);
    const pauseConnectorClicked = () => pauseConnector(connector);
    const resumeConnectorClicked = () => resumeConnector(connector);
    const restartConnectorClicked = () => restartConnector(connector);
    const toggleRowClicked = () => toggleRow(connector);
    return (<>
      <PrimaryTableRow className={expanded ? 'expanded' : ''}>
        <TableCell>
          <IconButton onClick={toggleRowClicked}>
            {expanded ? <ExpandedIcon /> : <CollapsedIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" onClick={toggleRowClicked}>
          {connector}
        </TableCell>
        <TableCell align="center">
          {(status === null || status === void 0 ? void 0 : status.connector) && (<StatusWidget type="Connector" state={status.connector.state} style={{ verticalAlign: 'middle' }} pause={pauseConnectorClicked} resume={resumeConnectorClicked} restart={restartConnectorClicked}/>)}
        </TableCell>
        <TableCell align="center">
          {((_a = status === null || status === void 0 ? void 0 : status.tasks) === null || _a === void 0 ? void 0 : _a.length) > 0 && (<StatusWidget type="Task" state={status.tasks[0].state} style={{ verticalAlign: 'middle' }} pause={() => restartTask(connector, status.tasks[0].id)} resume={() => restartTask(connector, status.tasks[0].id)} restart={() => restartTask(connector, status.tasks[0].id)}/>)}
        </TableCell>
        <TableCell align="center">
          {status && status.fetched && (<div title={moment(status.fetched).format(DATE_TIME_12H_FORMAT_WITH_SECONDS)}>
              {moment(status.fetched).fromNow()}
            </div>)}
        </TableCell>
        <TableCell align="center">
          <IconButton title="Refresh Status" onClick={loadStatusClicked}>
            <RefreshIcon />
          </IconButton>
          <IconButton onClick={deleteConnectorClicked}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </PrimaryTableRow>
      {expanded && (<SecondaryTableRow>
          <TableCell colSpan={6}>
            <Box margin={1} padding={1.5} className="box">
              <ConnectorDetails info={info} status={status}/>
            </Box>
          </TableCell>
        </SecondaryTableRow>)}
    </>);
};
export default ConnectorRow;
