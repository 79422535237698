import { Formik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { ModuleFormContainer, TextField } from 'src/AdminApp/components/form';
import SendResetPassword from 'src/AdminApp/containers/consumers/SendResetPassword';
import MarketingSourceSelect from 'src/AdminApp/containers/requests/MarketingSourceSelect';
import ZendeskUserLink from 'src/AdminApp/zendesk/ZendeskUserLink';
import LabeledField from 'src/components/layout/LabeledField';
import Module from 'src/components/layout/Module';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { formatPhone } from 'src/utils/formatter';
import styled from 'styled-components';
import FixFirebaseUser from '../FixFirebaseUser';
import useConsumerInfo from './useConsumerInfo';
const ActionButtons = styled.section `
  padding-left: 25px;
  margin-top: 34px;
  text-align: center;

  button {
    width: 200px;
    font-size: 14px;
  }

  > *:not(:last-child) {
    margin-bottom: 14px;
  }
`;
const ConsumerInfo = ({ canEdit, canEditMarketingSource = false, id, firstName, lastName, phone, email, zendeskUserId = 0, termsAccepted = false, fleet = false, address, marketingSourceId, firebaseUserOutOfSync, }) => {
    const { editable, setEditable, marketingSourcesById, handleSubmit, isSystemAdmin, } = useConsumerInfo({ id });
    const getMarketingDisplayName = (marketingSourceId) => {
        var _a;
        const marketingSource = marketingSourceId &&
            marketingSourcesById &&
            marketingSourcesById[marketingSourceId];
        return (<LabeledField label="Marketing Source">
        {(_a = marketingSource === null || marketingSource === void 0 ? void 0 : marketingSource.displayName) !== null && _a !== void 0 ? _a : 'None'}
      </LabeledField>);
    };
    const initialValues = {
        firstName,
        lastName,
        phone,
        email,
        zendeskUserId,
        fleet,
        marketingSourceId,
    };
    return (<Module title="Consumer Info" showEditButton={canEdit} editable={editable} toggleEditable={() => setEditable(!editable)} form={<Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
          {({ isSubmitting, resetForm }) => (<ModuleFormContainer>
              <LabeledField label="Id">
                <Link to={`/admin/consumers/${id}`}>{id}</Link>
              </LabeledField>
              <LabeledField id="firstName" label="First Name">
                <TextField type="text" name="firstName"/>
              </LabeledField>
              <LabeledField id="lastName" label="Last Name">
                <TextField type="text" name="lastName"/>
              </LabeledField>
              <LabeledField id="phone" label="Phone">
                <TextField type="text" name="phone"/>
              </LabeledField>
              <LabeledField id="email" label="Email">
                <TextField type="text" name="email"/>
              </LabeledField>
              <LabeledField id="address" label="Address">
                {address}
              </LabeledField>
              {canEditMarketingSource ? (<LabeledField id="marketingSourceId" label="Marketing Source">
                  <MarketingSourceSelect name="marketingSourceId"/>
                </LabeledField>) : (getMarketingDisplayName(marketingSourceId))}
              <LabeledField id="zendeskUserId" label="Zendesk UserID">
                <TextField type="text" name="zendeskUserId"/>
              </LabeledField>
              <LabeledField id="termsAccepted" label="Terms Accepted">
                {termsAccepted.toString()}
              </LabeledField>
              <LabeledField id="fleet" label="Fleet">
                <CheckboxField name="fleet"/>
              </LabeledField>
              <SimpleTwoButtonRow disabledCancel={isSubmitting} disabledSubmit={isSubmitting} onCancel={() => {
                    resetForm();
                    setEditable(false);
                }}/>
            </ModuleFormContainer>)}
        </Formik>}>
      <LabeledField label="Id">
        <Link to={`/admin/consumers/${id}`}>{id}</Link>
      </LabeledField>
      <LabeledField label="First Name">{firstName}</LabeledField>
      <LabeledField label="Last Name">{lastName}</LabeledField>
      <LabeledField label="Phone">{formatPhone(phone)}</LabeledField>
      <LabeledField label="Email">{email}</LabeledField>
      <LabeledField label="Address">{address}</LabeledField>
      {getMarketingDisplayName(marketingSourceId)}
      <LabeledField label="Zendesk User ID">
        <ZendeskUserLink consumerId={id} zendeskUserId={zendeskUserId}/>
      </LabeledField>
      <LabeledField label="Terms Accepted">
        {termsAccepted.toString()}
      </LabeledField>
      <LabeledField label="Fleet">{fleet.toString()}</LabeledField>
      <ActionButtons>
        <SendResetPassword email={email}/>
        {firebaseUserOutOfSync && isSystemAdmin && id && (<FixFirebaseUser email={email} id={id}/>)}
      </ActionButtons>
    </Module>);
};
export default ConsumerInfo;
