var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CircularProgress, MenuItem } from 'src/components/mui';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CheckboxWrapper, DividerXl, DrawerContentWrapper, Loader, RadioButtonWrapper, RadioLabel, SelectWrapper, StyledRadioGroup, } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/AuthorizationScript.styles';
import { RadioButtonField, TextField } from 'src/AdminApp/components/form';
import { WorkAuthorizationMethod } from 'src/AdminApp/models/enums';
import { createMenuItems } from 'src/AdminApp/components/form/utils';
import Config from 'src/config';
import { getEntry } from 'src/clients/contentfulClient';
import * as Yup from 'yup';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { FooterActionButton, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
const AuthorizationForm = ({ onSuccess, onFail, onSkip, consumerName, preAuthorizationRequested, paymentMethodId, paymentMethodType, currentState, requestAuthorization, onServiceError, }) => {
    const [skipStatement, setSkipStatement] = useState();
    const validationSchema = Yup.object().shape({
        other: Yup.string().when('authorizedByName', {
            is: 'radio_other',
            then: Yup.string().required('Name is required.'),
            otherwise: Yup.string().nullable(),
        }),
        authorizedByName: Yup.string().required(),
        method: Yup.string().required('Authorization method is required'),
        processPayment: Yup.bool().nullable(),
    }, [['authorizedByName', 'other']]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield getEntry(Config.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_PROCESS_PAYMENT_SUBTEXT);
            setSkipStatement(response.fields.skipStatement);
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const validateValues = (values) => !!values.method && !!values.authorizedByName;
    const shouldRequestAuthorization = () => {
        return !(paymentMethodType === 'AFFIRM' ||
            !preAuthorizationRequested ||
            currentState === 'REPAIR_IN_PROGRESS');
    };
    return (<Formik validationSchema={validationSchema} initialValues={{
            method: '',
            authorizedByName: consumerName,
            other: '',
            processPayment: true,
        }} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            setSubmitting(true);
            const authorizedBy = values.authorizedByName === 'radio_other'
                ? values.other
                : values.authorizedByName;
            const userSkip = values.processPayment === false;
            if (!values.processPayment) {
                onSkip(values.method, authorizedBy, true);
                return;
            }
            const shouldAuthorizePayment = shouldRequestAuthorization();
            const isPreAuthorizationRequested = userSkip
                ? false
                : shouldAuthorizePayment;
            const response = yield requestAuthorization(isPreAuthorizationRequested, paymentMethodId, undefined, undefined, userSkip);
            if (paymentMethodType === 'AFFIRM' ||
                currentState === 'REPAIR_IN_PROGRESS' ||
                !preAuthorizationRequested) {
                onSkip(values.method, authorizedBy);
            }
            else if (response.error && paymentMethodId) {
                onServiceError(paymentMethodId);
            }
            else if ((_b = (_a = response.payload) === null || _a === void 0 ? void 0 : _a.currentTask) === null || _b === void 0 ? void 0 : _b.paymentMethodAuthorized) {
                onSuccess(values.method, authorizedBy);
            }
            else {
                onFail(values.method, authorizedBy, userSkip);
            }
        })}>
      {({ submitForm, isSubmitting, values }) => (<>
          <DrawerContentWrapper>
            {isSubmitting ? (<Loader>
                <CircularProgress size={50}/>
              </Loader>) : (<>
                <SelectWrapper>
                  <TextField select label="Authorization Method" name="method" variant="outlined">
                    <MenuItem disabled value="">
                      Authorization Method
                    </MenuItem>
                    {createMenuItems(WorkAuthorizationMethod, true, undefined, false)}
                  </TextField>
                </SelectWrapper>

                <DividerXl />

                <RadioButtonWrapper>
                  <RadioLabel>Authorized by:</RadioLabel>
                  <StyledRadioGroup id="authorizedByName" value={values.authorizedByName}>
                    <RadioButtonField name="authorizedByName" id={consumerName} label={consumerName}/>

                    <RadioButtonField name="authorizedByName" id="radio_other" label="Other"/>
                    {values.authorizedByName === 'radio_other' && (<TextField name="other" type="text" variant="standard" fullWidth label="Enter name" value={values.other} required={values.authorizedByName === 'radio_other'}/>)}
                  </StyledRadioGroup>
                </RadioButtonWrapper>
                {shouldRequestAuthorization() && (<CheckboxWrapper>
                    <CheckboxField color="primary" disabled={false} label="Process Payment Authorization" name="processPayment"/>
                    <div className="checkboxSubtitle">({skipStatement})</div>
                  </CheckboxWrapper>)}
              </>)}
          </DrawerContentWrapper>

          <SimpleDrawerFooter>
            <FooterActionButton disabled={!validateValues(values) || isSubmitting} onClick={submitForm}>
              Save
            </FooterActionButton>
          </SimpleDrawerFooter>
        </>)}
    </Formik>);
};
export default AuthorizationForm;
