import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography, Grid, } from 'src/components/mui';
import { AddCircle as AddCircleIcon, DeleteForever, } from 'src/components/mui/icons';
import { MeasurementUnit } from 'src/PricingApp/models/enums/MeasurementUnit';
import { MeasurementProcess } from 'src/PricingApp/models/enums/MeasurementProcess';
import { MeasurementPosition } from 'src/PricingApp/models/enums/MeasurementPosition';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { ActionButtonContainer, AddButtonContainer, MeasurementItemContainer, RemoveButton, } from './Measurements.styles';
export const MeasurementItem = ({ onSetValue, description, aiProcess, measurements, onAddSubset, onRemove, selectAllChecks, onUpdateProcess, }) => {
    return (<MeasurementItemContainer container>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <TextField variant="outlined" value={description} onChange={(e) => { var _a; return onSetValue((_a = measurements[0]) === null || _a === void 0 ? void 0 : _a.index, 'description', e.target.value); }} placeholder="What would you like to measure?" type="text" name="description" fullWidth/>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Process</InputLabel>
            <Select value={aiProcess} label="Process" onChange={(e) => onUpdateProcess(e.target.value)}>
              {Object.keys(MeasurementProcess)
            .filter((v) => isNaN(Number(v)))
            .map((process) => (<MenuItem value={process}>{process}</MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <LabelledCheckbox style={{ alignItems: 'flex-start' }} label={<Typography variant="caption" color="textSecondary">
                Optional
              </Typography>} labelPlacement="top" checkboxProps={{
            onChange: (e) => selectAllChecks(e.target.checked),
        }}/>
        </Grid>
        {measurements.map((position, index) => (<>
            {measurements.length > 1 && (<Grid item xs={4}>
                <TextField variant="outlined" value={position === null || position === void 0 ? void 0 : position.position} onChange={(e) => onSetValue(index, 'position', e.target.value)} placeholder="Which positions?" type="text" name="position" fullWidth/>
              </Grid>)}
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                <Select value={position === null || position === void 0 ? void 0 : position.unit} label="Unit" onChange={(e) => onSetValue(index, 'unit', e.target.value)}>
                  {Object.keys(MeasurementUnit.values)
                .filter((v) => isNaN(Number(v)))
                .map((key) => (<MenuItem value={key}>
                        {MeasurementUnit.display(key)}
                      </MenuItem>))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>Result</InputLabel>
                <Select value={position === null || position === void 0 ? void 0 : position.autointegratePosition} label="Result" onChange={(e) => onSetValue(index, 'autointegratePosition', e.target.value)}>
                  {Object.keys(MeasurementPosition)
                .filter((v) => isNaN(Number(v)))
                .map((unit) => (<MenuItem value={unit}>{unit}</MenuItem>))}
                </Select>
              </FormControl>
            </Grid>
            {measurements.length > 1 && (<Grid item xs={2}>
                <LabelledCheckbox label="" labelPlacement="top" checkboxProps={{
                    checked: position === null || position === void 0 ? void 0 : position.optional,
                    onChange: (e) => onSetValue(index, 'optional', e.target.checked),
                }}/>
              </Grid>)}
          </>))}
        <Grid item xs={12}>
          <ActionButtonContainer>
            <AddButtonContainer onClick={onAddSubset}>
              <AddCircleIcon color="primary"/>
              <span>{'  '}Add Position</span>
            </AddButtonContainer>
            <RemoveButton startIcon={<DeleteForever />} onClick={onRemove}/>
          </ActionButtonContainer>
        </Grid>
      </Grid>
    </MeasurementItemContainer>);
};
