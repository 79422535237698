import { handleActions } from 'redux-actions';
import { ActionCreators } from 'src/AdminApp/modules/payments/actions';
const DEFAULT_STATE = {
    spreedly: null,
    chargeByInvoice: null,
};
const successSpreedlyTokenizeReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { spreedly: action.payload });
};
const successChargePaymentReducer = (state, action) => {
    const { invoiceId } = action.meta;
    return Object.assign(Object.assign({}, state), { chargeByInvoice: Object.assign(Object.assign({}, state.chargeByInvoice), { [invoiceId]: action.payload }) });
};
const successLoadPaymentMethodsReducer = (state, action) => {
    const { consumerId } = action.meta;
    return Object.assign(Object.assign({}, state), { methodsByConsumer: Object.assign(Object.assign({}, state.methodsByConsumer), { [consumerId]: action.payload }) });
};
const reducers = handleActions({
    [ActionCreators.SPREEDLY_TOKENIZE.SUCCESS.type]: successSpreedlyTokenizeReducer,
    [ActionCreators.CHARGE_PAYMENT.SUCCESS.type]: successChargePaymentReducer,
    [ActionCreators.LOAD_PAYMENT_METHODS_BY_CONSUMER.SUCCESS.type]: successLoadPaymentMethodsReducer,
}, DEFAULT_STATE);
export default reducers;
