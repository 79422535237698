import { STATIC_URL_BASE } from 'src/styles/theme';
export const getSquareMakeLogo = (make, factor = '') => {
    return `${STATIC_URL_BASE}img/makes-logos/${make
        .toLowerCase()
        .replace(/\s/g, '-')}${factor}.png`;
};
export const getSquareMakeLogoLarge = (make, factor = '') => {
    return `${STATIC_URL_BASE}img/makes-logos-85px/${make
        .toLowerCase()
        .replace(/\s/g, '-')}${factor}.png`;
};
