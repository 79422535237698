import { Divider, Grid, List } from 'src/components/mui';
import { AddCircle } from 'src/components/mui/icons';
import { map } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { DeleteButton } from 'src/components/buttons';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import CONFIG from 'src/config';
import { ButtonLink, IntroContentTodayReturn, IntroTodayReturnCopy, IntroTodayReturnHeader, RepairItemStyled, } from '../CantFinishDrawer.styles';
const CantFinishTodayReturnVisitDrawer = ({ onClose, sendToAddService, sendToEditReturn, addRepairOnRepairsAddedOnAddServices, onCallVehicleRequiresAddtlRepairsCalls, referenceNum, cantFinishOptionChosen, todaysVisit, returnsVisit, isLongerThanGracePeriod, }) => {
    const generalDiagnosisId = CONFIG.GENERAL_DIAGNOSIS;
    const [todaysVisitRepairsList, setTodaysVisitRepairsList] = useState(todaysVisit);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const generalDiagnosis = useMemo(() => ({
        highlight: '<em>General</em> <em>Diagnosis</em>',
        label: 'General Diagnosis',
        repairId: parseInt(generalDiagnosisId, 10),
        requiresApproval: false,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    useEffect(() => {
        var _a;
        if (todaysVisit.length >= 1 || returnsVisit.length >= 1) {
            setDisableSubmit(false);
        }
        else {
            setDisableSubmit(true);
        }
        if (isLongerThanGracePeriod &&
            ((_a = todaysVisit === null || todaysVisit === void 0 ? void 0 : todaysVisit.at(0)) === null || _a === void 0 ? void 0 : _a.repairId) !== generalDiagnosis.repairId) {
            setTodaysVisitRepairsList((prevList) => {
                const newList = [generalDiagnosis, ...prevList];
                addRepairOnRepairsAddedOnAddServices(newList);
                return newList;
            });
        }
    }, [
        todaysVisit,
        returnsVisit.length,
        isLongerThanGracePeriod,
        generalDiagnosis,
        addRepairOnRepairsAddedOnAddServices,
        setDisableSubmit,
        setTodaysVisitRepairsList,
    ]);
    const handleRemove = (repairName) => {
        const newList = todaysVisitRepairsList.filter((repair) => { var _a; return !((_a = repair.label) === null || _a === void 0 ? void 0 : _a.includes(repairName)); });
        setTodaysVisitRepairsList(newList);
        addRepairOnRepairsAddedOnAddServices(newList);
    };
    return (<>
      <SimpleDrawerContent>
        <IntroContentTodayReturn>
          {isLongerThanGracePeriod && (<div>
              <IntroTodayReturnHeader>Today's visit</IntroTodayReturnHeader>
              <IntroTodayReturnCopy>
                <ReactMarkdown>
                  Here are the current repair ID's we will charge for today:
                </ReactMarkdown>
              </IntroTodayReturnCopy>
              <List style={{ width: '100%' }}>
                <Divider />
                {map(todaysVisitRepairsList, (repair) => (<>
                    <RepairItemStyled container style={{ alignItems: 'center' }}>
                      <Grid item xs style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        {repair.label}
                      </Grid>
                      {repair.repairId !== generalDiagnosis.repairId && (<Grid item>
                          <DeleteButton onClick={() => handleRemove(repair.label)}/>
                        </Grid>)}
                    </RepairItemStyled>
                    <Divider />
                  </>))}
              </List>
              <RepairItemStyled container style={{ padding: 0, alignItems: 'center' }}>
                <Grid item xs/>
                <Grid item/>
                <Grid item>
                  <SimpleRowButton onClick={() => {
                addRepairOnRepairsAddedOnAddServices([
                    ...todaysVisitRepairsList,
                ]);
                sendToAddService();
            }} color="primary">
                    <AddCircle /> Add Service
                  </SimpleRowButton>
                </Grid>
              </RepairItemStyled>
            </div>)}

          <div>
            <IntroTodayReturnHeader>Return visit</IntroTodayReturnHeader>
            <IntroTodayReturnCopy>
              <ReactMarkdown>
                Here are the current repairs we will perform on the next visit:
              </ReactMarkdown>
            </IntroTodayReturnCopy>
            <List style={{ width: '100%' }}>
              <Divider />
              {map(returnsVisit, (repair) => (<>
                  <RepairItemStyled container style={{ alignItems: 'center' }}>
                    <Grid item style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                      {repair === null || repair === void 0 ? void 0 : repair.label}
                    </Grid>
                  </RepairItemStyled>
                  <Divider />
                </>))}
            </List>
            <RepairItemStyled container style={{ padding: 0, alignItems: 'center' }}>
              <Grid item xs/>
              <Grid item/>
              <Grid item>
                <ButtonLink onClick={sendToEditReturn}>
                  Edit Services
                </ButtonLink>
              </Grid>
            </RepairItemStyled>
          </div>
        </IntroContentTodayReturn>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton disabled={disableSubmit} onClick={() => {
            onCallVehicleRequiresAddtlRepairsCalls(referenceNum, cantFinishOptionChosen, isLongerThanGracePeriod);
            onClose();
        }}>
          Submit
        </FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default CantFinishTodayReturnVisitDrawer;
