import React from 'react';
import { Tooltip } from 'src/components/tooltips';
import { filter } from 'lodash';
import { AppointmentDeliveryChannel } from 'src/AdminApp/models/enums';
import DeliveryChannel from 'src/AdminApp/models/enums/DeliveryChannel';
import SkeduloJobResourceCategory from 'src/AdminApp/models/enums/SkeduloJobResourceCategory';
import SkeduloJobResourceStatus from 'src/AdminApp/models/enums/SkeduloJobResourceStatus';
export const formIspTitle = (deliveryChannel) => {
    if (!deliveryChannel || deliveryChannel === DeliveryChannel.NONE)
        return 'Service Venue';
    return deliveryChannel === DeliveryChannel.REFERRAL ? 'Shop' : 'Van';
};
export const formMechanicTitle = (deliveryChannel) => {
    if (!deliveryChannel || deliveryChannel === DeliveryChannel.NONE)
        return 'Service Renderer';
    return deliveryChannel === DeliveryChannel.REFERRAL
        ? 'Shop Owner'
        : 'Mechanic';
};
export const skeduloJobResourcesFilter = (resources, type, isCanceled) => {
    return filter(resources, (r) => r.category === SkeduloJobResourceCategory[type] &&
        (r.status !== SkeduloJobResourceStatus.DELETED || isCanceled));
};
const getResourceNameFromIspContactInfo = (type, ispContactInfo) => {
    var _a, _b;
    return type === SkeduloJobResourceCategory.VAN
        ? (_a = ispContactInfo === null || ispContactInfo === void 0 ? void 0 : ispContactInfo.shopName) !== null && _a !== void 0 ? _a : 'None'
        : (_b = ispContactInfo === null || ispContactInfo === void 0 ? void 0 : ispContactInfo.contactPersonName) !== null && _b !== void 0 ? _b : 'None';
};
export const displayResources = (deliveryChannel, skeduloJobResources, ispContactInfo, type, isCanceled = false) => {
    if (deliveryChannel === AppointmentDeliveryChannel.DELIVERY) {
        if (skeduloJobResources.length > 0) {
            const items = skeduloJobResourcesFilter(skeduloJobResources, type, isCanceled).map((r) => r.name);
            if (isCanceled) {
                if (items.length > 1) {
                    return getResourceNameFromIspContactInfo(type, ispContactInfo);
                }
                return items.join(', ');
            }
            if (items.length > 2) {
                return (<>
            {items[0]}, {items[1]}
            <Tooltip title={items.slice(2).join(', ')}>
              <span style={{ cursor: 'pointer' }}>, ...</span>
            </Tooltip>
          </>);
            }
            return items.join(', ');
        }
    }
    return getResourceNameFromIspContactInfo(type, ispContactInfo);
};
