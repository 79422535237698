import React from 'react';
import { FormControl, Input, ListItemText, MenuItem, Select, } from 'src/components/mui';
import { Checkbox } from 'src/components/SimpleCheckbox';
const searchLevels = [
    { title: 'Public', level: 1 },
    { title: 'Admin', level: 2 },
    { title: 'Fleet', level: 3 },
];
const SearchLevelSelect = ({ activeLevels = [], setActiveLevels, }) => {
    const handleChange = (event) => {
        var _a;
        const newLevels = ((_a = event.target) === null || _a === void 0 ? void 0 : _a.value).sort();
        setActiveLevels(newLevels);
    };
    return (<FormControl fullWidth>
      <Select multiple value={activeLevels} onChange={handleChange} input={<Input />} renderValue={(selected) => selected.join(', ')}>
        {searchLevels.map(({ title, level }) => (<MenuItem key={level} value={level}>
            <Checkbox checked={activeLevels.indexOf(level) > -1}/>
            <ListItemText primary={`${title} (${level})`}/>
          </MenuItem>))}
      </Select>
    </FormControl>);
};
export default SearchLevelSelect;
