var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { selectIsAuthenticated } from 'src/modules/auth/selectors';
import { storeRedirectUrl } from 'src/modules/auth/utils';
const ProtectedRoute = (_a) => {
    var { component: Component } = _a, rest = __rest(_a, ["component"]);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const renderComponent = (props) => <Component {...props}/>;
    const renderRedirect = (props) => {
        var _a, _b;
        if ((_a = props.location) === null || _a === void 0 ? void 0 : _a.pathname) {
            storeRedirectUrl((_b = props.location) === null || _b === void 0 ? void 0 : _b.pathname);
        }
        return (<Redirect to={{ pathname: '/login', state: { from: props.location } }}/>);
    };
    return (<Route {...rest} render={isAuthenticated === true ? renderComponent : renderRedirect}/>);
};
export default ProtectedRoute;
