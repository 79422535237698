/* eslint-disable react/no-access-state-in-setstate */
import { Grid } from 'src/components/mui';
import { ErrorMessage, Form, Formik } from 'formik';
import { filter, forEach, isEmpty, map, memoize, isNil } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { FormPanel, FormSection, StateSelect, TextField, YearSelect, } from 'src/AdminApp/components/form';
import RegistrationForm from 'src/AdminApp/components/users/RegistrationForm';
import DeliveryVanActions from 'src/AdminApp/containers/shops/DeliveryVanActions';
import { getCarBrands, getCarTypes, getRepairFocuses, registerShop, updateShopById, } from 'src/AdminApp/modules/shops/actions';
import { selectCarBrands, selectCarTypes, selectRepairFocuses, selectSubletRepairFocuses, } from 'src/AdminApp/modules/shops/selectors';
import { ActionButton, DestructiveButton } from 'src/components/buttons';
import { toast } from 'src/components/SimpleToast';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { selectAuthorizedShopEdit } from 'src/modules/auth/selectors';
import { colors } from 'src/styles/theme';
import { REGEX_UUID } from 'src/utils/nav-utils';
import styled from 'styled-components';
import * as Yup from 'yup';
import PricingForm from './components/PricingForm';
import { deliveryChannels, renderFieldArray, renderGroupedCheckboxForm, renderHourSelects, } from './utils';
const getShopSchema = memoize((skeduloResourceIdValidationEnabled) => Yup.object().shape({
    phone: Yup.string()
        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number should contain only numbers and no dashes or special characters.')
        .required('Phone number is required'),
    zip: Yup.string()
        .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zip code is not valid.')
        .required('Zip code is required.'),
    state: Yup.string().required('State is required'),
    skeduloResourceId: skeduloResourceIdValidationEnabled
        ? Yup.string().matches(REGEX_UUID, 'Value must be a GUID')
        : undefined,
}));
const businessHours = {
    1: {
        label: 'Monday',
        dayOfWeek: 1,
        openingTime: '',
        closingTime: '',
        closed: false,
    },
    2: {
        label: 'Tuesday',
        dayOfWeek: 2,
        openingTime: '',
        closingTime: '',
        closed: false,
    },
    3: {
        label: 'Wednesday',
        dayOfWeek: 3,
        openingTime: '',
        closingTime: '',
        closed: false,
    },
    4: {
        label: 'Thursday',
        dayOfWeek: 4,
        openingTime: '',
        closingTime: '',
        closed: false,
    },
    5: {
        label: 'Friday',
        dayOfWeek: 5,
        openingTime: '',
        closingTime: '',
        closed: false,
    },
    6: {
        label: 'Saturday',
        dayOfWeek: 6,
        openingTime: '',
        closingTime: '',
        closed: false,
    },
    7: {
        label: 'Sunday',
        dayOfWeek: 7,
        openingTime: '',
        closingTime: '',
        closed: false,
    },
};
const SubmitContainer = styled(Grid) `
  position: sticky;
  bottom: 0;
  padding: 5px 15px 15px;
  background: ${colors.border};
`;
const CheckAllButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  width: 400px;

  button:not(:last-child) {
    margin-right: 16px;
  }
`;
class ShopAddForm extends Component {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            carBrands: {},
            carTypes: {},
            repairFocuses: {},
            subletRepairFocuses: {},
            deliveryChannels,
            businessHours,
            updatedOpeningTime: false,
            updatedClosingTime: false,
        };
        this.stateToArray = (type) => filter(this.state[type], (box) => box.checked).map((checked) => checked.id);
        this.mandatoryFieldsEmpty = () => {
            const stateRepairFocuses = this.stateToArray('repairFocuses');
            const stateCarBrands = this.stateToArray('carBrands');
            const stateCarTypes = this.stateToArray('carTypes');
            return (isEmpty(stateRepairFocuses) ||
                isEmpty(stateCarBrands) ||
                isEmpty(stateCarTypes));
        };
        this.renderBusinessInfoForm = ({ errors, touched, setFieldValue, values, }) => (<FormPanel ariaName="shop-business-info-form" title="Business Info">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField label="Shop Name" type="text" name="shopName"/>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="BAR Number" type="number" name="barNumber"/>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="Owner's First Name" type="text" name="firstName"/>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="Owner's Last Name" type="text" name="lastName"/>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Phone Number" type="phone" name="phone"/>
          {errors.name && touched.name && <div>{errors.name}</div>}
          <ErrorMessage name="phone"/>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address" type="text" name="address"/>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="City" type="text" name="city"/>
        </Grid>
        <Grid item xs={6} md={2}>
          <StateSelect label="State" name="state"/>
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField label="Zip Code" type="text" name="zip"/>
          {errors.name && touched.name && <div>{errors.name}</div>}
          <ErrorMessage name="zip"/>
        </Grid>

        <Grid item xs={12}>
          <FormSection title="Skedulo Resource ID">
            <Grid item xs={12} md={5}>
              <TextField disabled={!values.deliveryVan} label={!(values === null || values === void 0 ? void 0 : values.skeduloResourceId) ? 'Skedulo Resource ID' : ''} type="text" name="skeduloResourceId"/>
            </Grid>
          </FormSection>
        </Grid>

        <Grid item xs={12}>
          <FormSection title="Delivery Channels">
            {renderFieldArray('deliveryChannels', deliveryChannels, setFieldValue, values, this.state.deliveryChannels, this.check)}
            {values.id &&
                values.deliveryChannels.some((x) => x.toString() === deliveryChannels.DELIVERY.id) && (<Grid container style={{ justifyContent: 'flex-end' }}>
                  <DeliveryVanActions ispId={values.id} isDeliveryVan={values.deliveryVan} onSubmit={(deliveryVan) => {
                    var _a;
                    setFieldValue('skeduloResourceId', (_a = deliveryVan === null || deliveryVan === void 0 ? void 0 : deliveryVan.skeduloResourceId) !== null && _a !== void 0 ? _a : '');
                    setFieldValue('deliveryVan', !!deliveryVan.id);
                }}/>
                </Grid>)}
          </FormSection>
        </Grid>
      </Grid>
    </FormPanel>);
        this.setHours = ({ dayOfWeek, closed = false, openingTime = null, closingTime = null, bulk = false, businessHours = [], }) => {
            const updatedValues = Object.assign(Object.assign(Object.assign({}, (!isNil(closed) && { closed })), (!isEmpty(openingTime) && { openingTime })), (!isEmpty(closingTime) && { closingTime }));
            const updateAllOpeningTimes = !isEmpty(openingTime) && !this.state.updatedOpeningTime;
            const updateAllClosingTimes = !isEmpty(closingTime) && !this.state.updatedClosingTime;
            if (bulk) {
                this.setState({
                    businessHours: {
                        1: businessHours[0],
                        2: businessHours[1],
                        3: businessHours[2],
                        4: businessHours[3],
                        5: businessHours[4],
                        6: businessHours[5],
                        7: businessHours[6],
                    },
                });
            }
            else if (updateAllOpeningTimes || updateAllClosingTimes) {
                const updated = Object.assign(Object.assign({}, (updateAllOpeningTimes && { updatedOpeningTime: true })), (updateAllClosingTimes && { updatedClosingTime: true }));
                this.setState(Object.assign({ businessHours: Object.assign(Object.assign({}, this.state.businessHours), { 1: Object.assign(Object.assign({}, this.state.businessHours[1]), updatedValues), 2: Object.assign(Object.assign({}, this.state.businessHours[2]), updatedValues), 3: Object.assign(Object.assign({}, this.state.businessHours[3]), updatedValues), 4: Object.assign(Object.assign({}, this.state.businessHours[4]), updatedValues), 5: Object.assign(Object.assign({}, this.state.businessHours[5]), updatedValues), 6: Object.assign(Object.assign({}, this.state.businessHours[6]), updatedValues), 7: Object.assign(Object.assign({}, this.state.businessHours[7]), updatedValues) }) }, updated));
            }
            else {
                this.setState({
                    businessHours: Object.assign(Object.assign({}, this.state.businessHours), { [dayOfWeek]: Object.assign(Object.assign({}, this.state.businessHours[dayOfWeek]), updatedValues) }),
                });
            }
        };
        this.renderBusinessHoursForm = () => (<FormPanel ariaName="shop-hours-form" title="Business Hours">
      <Grid container spacing={2}>
        {renderHourSelects(this.setHours, this.state.businessHours)}
      </Grid>
    </FormPanel>);
        this.check = (type, id, checked) => {
            this.setState({
                [type]: Object.assign(Object.assign({}, this.state[type]), { [id]: Object.assign(Object.assign({}, this.state[type][id]), { checked }) }),
            });
        };
        this.checkAll = (type, checked) => {
            const newState = this.state[type];
            const keys = Object.keys(newState);
            for (let i = 0; i < keys.length; i += 1) {
                newState[keys[i]] = Object.assign(Object.assign({}, newState[keys[i]]), { checked });
            }
            this.setState({ [type]: newState });
        };
        // eslint-disable-next-line class-methods-use-this
        this.checkBulk = (_, array, state) => {
            const newState = Object.assign({}, state);
            for (let i = 0; i < array.length; i += 1) {
                newState[array[i]].checked = true;
            }
            return newState;
        };
        this.renderCheckAll = (type) => (<CheckAllButtonContainer>
      <ActionButton onClick={() => this.checkAll(type, true)}>
        Check All
      </ActionButton>
      <DestructiveButton onClick={() => this.checkAll(type, false)}>
        Clear All
      </DestructiveButton>
    </CheckAllButtonContainer>);
        this.renderServiceDetailsForm = ({ setFieldValue, values, }) => (<FormPanel ariaName="shop-service-details-form" title="Service Details">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormPanel ariaName="repair-focus-form" title="Repair Focus">
            {this.renderCheckAll('repairFocuses')}
            {renderGroupedCheckboxForm('repairFocuses', this.props.repairFocuses, setFieldValue, values, this.state.repairFocuses, this.check)}
          </FormPanel>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormPanel ariaName="sublet-repair-focus-form" title="Sublet Repair Focus">
            {this.renderCheckAll('subletRepairFocuses')}
            {renderGroupedCheckboxForm('subletRepairFocuses', this.props.subletRepairFocuses, setFieldValue, values, this.state.subletRepairFocuses, this.check)}
          </FormPanel>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormPanel ariaName="car-years-form" title="Car Years Served">
            <Grid container spacing={2}>
              <Grid item xs={5} md={5}>
                <YearSelect label="Year" name="year"/>
              </Grid>
              <Grid item xs={7} md={7} style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
            }}>
                to current.
              </Grid>
            </Grid>
          </FormPanel>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormPanel ariaName="car-types-form" title="Car Types">
            <Grid container spacing={2}>
              {this.renderCheckAll('carTypes')}
              {renderFieldArray('carTypes', this.props.carTypes, setFieldValue, values, this.state.carTypes, this.check)}
            </Grid>
          </FormPanel>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormPanel ariaName="car-brands-form" title="Car Brands">
            {this.renderCheckAll('carBrands')}
            {renderGroupedCheckboxForm('carBrands', this.props.carBrands, setFieldValue, values, this.state.carBrands, this.check)}
          </FormPanel>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormPanel ariaName="pricing-form" title="Pricing">
            <PricingForm values={values}/>
          </FormPanel>
        </Grid>
      </Grid>
    </FormPanel>);
    }
    componentDidMount() {
        var _a, _b, _c, _d, _e;
        const { isEditing } = this.props;
        this.props.getRepairFocuses().then((result) => {
            var _a, _b, _c, _d;
            let repairFocuses = {};
            let subletRepairFocuses = {};
            const payload = (_a = result === null || result === void 0 ? void 0 : result.payload) !== null && _a !== void 0 ? _a : [];
            forEach(payload, (focus) => {
                forEach(focus.repairFocuses, (repairFocus) => {
                    repairFocuses[repairFocus.id] = Object.assign(Object.assign({}, repairFocus), { checked: false });
                    subletRepairFocuses[repairFocus.id] = Object.assign(Object.assign({}, repairFocus), { checked: false });
                });
            });
            if (isEditing) {
                const initialRepairFocuses = (_c = (_b = this.props) === null || _b === void 0 ? void 0 : _b.initialValues) === null || _c === void 0 ? void 0 : _c.repairFocuses;
                const initialSubletRepairFocuses = (_d = this.props) === null || _d === void 0 ? void 0 : _d.initialValues.subletRepairFocuses;
                repairFocuses = this.checkBulk('repairFocuses', initialRepairFocuses, repairFocuses);
                subletRepairFocuses = this.checkBulk('subletRepairFocuses', initialSubletRepairFocuses, subletRepairFocuses);
            }
            this.setState({ repairFocuses, subletRepairFocuses });
        });
        this.props.getCarBrands().then((result) => {
            var _a, _b, _c;
            let carBrands = {};
            const payload = (_a = result === null || result === void 0 ? void 0 : result.payload) !== null && _a !== void 0 ? _a : [];
            forEach(payload, (brand) => {
                forEach(brand.carBrands, (carBrand) => {
                    carBrands[carBrand.id] = Object.assign(Object.assign({}, carBrand), { checked: false });
                });
            });
            if (isEditing) {
                const initialCarBrands = (_c = (_b = this.props) === null || _b === void 0 ? void 0 : _b.initialValues) === null || _c === void 0 ? void 0 : _c.carBrands;
                carBrands = this.checkBulk('carBrands', initialCarBrands, carBrands);
            }
            this.setState({ carBrands });
        });
        this.props.getCarTypes().then((result) => {
            var _a, _b;
            let carTypes = {};
            const payload = (_a = result === null || result === void 0 ? void 0 : result.payload) !== null && _a !== void 0 ? _a : [];
            forEach(payload, (carType) => {
                carTypes[carType.id] = Object.assign(Object.assign({}, carType), { checked: false });
            });
            if (isEditing) {
                const initialCarTypes = (_b = this.props) === null || _b === void 0 ? void 0 : _b.initialValues.carTypes;
                carTypes = this.checkBulk('carTypes', initialCarTypes, carTypes);
            }
            this.setState({ carTypes });
        });
        if (this.props.isEditing) {
            const initialDeliveryChannels = (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.initialValues) === null || _b === void 0 ? void 0 : _b.deliveryChannels) !== null && _c !== void 0 ? _c : [];
            const initDeliveryChannelsState = {
                REFERRAL: {
                    id: 'REFERRAL',
                    name: 'Referral',
                    checked: initialDeliveryChannels.includes('REFERRAL'),
                },
                DELIVERY: {
                    id: 'DELIVERY',
                    name: 'Delivery',
                    checked: initialDeliveryChannels.includes('DELIVERY'),
                },
                DIRECT: {
                    id: 'DIRECT',
                    name: 'Direct',
                    checked: initialDeliveryChannels.includes('DIRECT'),
                },
                HAZMAT: {
                    id: 'HAZMAT',
                    name: 'Hazmat',
                    checked: initialDeliveryChannels.includes('HAZMAT'),
                },
                SUBLET: {
                    id: 'SUBLET',
                    name: 'Sublet',
                    checked: initialDeliveryChannels.includes('SUBLET'),
                },
            };
            this.setState({ deliveryChannels: initDeliveryChannelsState });
            const businessHours = (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.initialValues) === null || _e === void 0 ? void 0 : _e.businessHours;
            this.setHours({ bulk: true, businessHours });
        }
    }
    render() {
        const { canEdit, initialValues, isEditing } = this.props;
        return canEdit ? (<Formik enableReinitialize initialValues={isEditing
                ? initialValues
                : {
                    email: '',
                    password: '',
                    termsAccepted: false,
                    shopName: '',
                    firstName: '',
                    lastName: '',
                    barNumber: '',
                    state: '',
                    deliveryChannels: [],
                    repairFocuses: [],
                    carTypes: [],
                    carBrands: [],
                    year: '',
                    pricingDetails: {
                        partsPricing: 'MATRIX',
                        taxRate: '',
                        laborRates: [
                            {
                                brandType: 'DEFAULT',
                                diagnosisPrice: '',
                                diagnosisReimbursable: false,
                                generalPrice: '',
                                maintenancePrice: '',
                                specialPrice: '',
                            },
                        ],
                    },
                }} validationSchema={getShopSchema(initialValues === null || initialValues === void 0 ? void 0 : initialValues.deliveryVan)} onSubmit={(values, { setSubmitting, resetForm }) => {
                const stateDeliveryChannels = this.stateToArray('deliveryChannels');
                const stateRepairFocuses = this.stateToArray('repairFocuses');
                const stateSubletRepairFocuses = this.stateToArray('subletRepairFocuses');
                const stateCarBrands = this.stateToArray('carBrands');
                const stateCarTypes = this.stateToArray('carTypes');
                const stateBusinessHours = map(this.state.businessHours, (hours) => hours);
                const newValues = Object.assign(Object.assign({}, values), { deliveryChannels: stateDeliveryChannels, repairFocuses: stateRepairFocuses, subletRepairFocuses: stateSubletRepairFocuses, carBrands: stateCarBrands, carTypes: stateCarTypes, businessHours: stateBusinessHours });
                if (isEditing) {
                    this.props.updateShopById(newValues).then((result) => {
                        setSubmitting(false);
                        if (!result.error) {
                            resetForm();
                            toast.success('Shop successfully updated.');
                        }
                    });
                }
                else {
                    this.props.registerShop(newValues).then((result) => {
                        var _a, _b;
                        const id = (_b = (_a = result === null || result === void 0 ? void 0 : result.payload) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
                        setSubmitting(false);
                        if (!result.error) {
                            resetForm();
                            toast.success('Shop successfully updated.');
                            if (id)
                                this.props.history.push(`/admin/shops/${id}/edit`);
                        }
                    });
                }
            }}>
        {({ errors, touched, isSubmitting, values, setFieldValue }) => {
                const { email, password } = values;
                const cannotSubmit = !isEditing &&
                    (isEmpty(email) ||
                        isEmpty(password) ||
                        this.mandatoryFieldsEmpty());
                return (<Form>
              {!isEditing && <RegistrationForm />}
              {this.renderBusinessInfoForm({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                    })}
              {this.renderBusinessHoursForm()}
              {this.renderServiceDetailsForm({ setFieldValue, values })}
              <SubmitContainer item xs={12}>
                <SimpleTwoButtonRow style={{ marginTop: '0px' }} disabledCancel={isSubmitting} disabledSubmit={isSubmitting || cannotSubmit || !isEmpty(errors)} onCancel={() => {
                        this.props.history.push('/admin/shops');
                    }}/>
              </SubmitContainer>
            </Form>);
            }}
      </Formik>) : (<div>
        ERROR - Not Authorized: You must have the "Shop_Editor" role to view
        this item
      </div>);
    }
}
const mapStateToProps = () => {
    return createStructuredSelector({
        repairFocuses: selectRepairFocuses,
        subletRepairFocuses: selectSubletRepairFocuses,
        carBrands: selectCarBrands,
        carTypes: selectCarTypes,
        canEdit: selectAuthorizedShopEdit,
    });
};
const mapDispatchToProps = {
    getRepairFocuses,
    getCarBrands,
    getCarTypes,
    registerShop,
    updateShopById,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShopAddForm));
