import styled, { css } from 'styled-components';
import { Button, IconButton } from 'src/components/mui';
import { theme } from './theme';
const flexCenter = css `
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
export const errorStyles = css `
  color: ${theme.palette.error.main};
  &:hover {
    background-color: ${theme.palette.error.main}0A;
  }
`;
export const ErrorButton = styled(Button) `
  ${errorStyles}
`;
export const ErrorIconButton = styled(IconButton) `
  ${errorStyles}
`;
const boldStyle = { fontWeight: 'bold' };
const boldInputProps = {
    style: boldStyle,
};
const boldSelectProps = {
    style: boldStyle,
};
const clearStyles = css `
  border: none;
  margin-block: 0;
  margin-inline: 0;
  margin: 0;
  padding-block: 0;
  padding-inline: 0;
  padding: 0;
`;
export { boldInputProps, boldSelectProps, clearStyles, flexCenter };
