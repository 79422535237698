import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const defaultState = {
    inspectionDetailByRepairId: null,
    inspectionByRepairId: null,
    inspectionTypes: [],
    inspectionPerformedStatus: null,
};
const reducers = handleActions({
    [ActionCreators.GET_INSPECTION_BY_REPAIR_REQUEST_ID.SUCCESS.type]: (state, action) => {
        if (!action.payload.data.findByRepairRequestId) {
            return Object.assign(Object.assign({}, state), { inspectionByRepairId: Object.assign(Object.assign({}, state.inspectionByRepairId), { [action.meta.repairRequestId]: null }) });
        }
        return Object.assign(Object.assign({}, state), { inspectionByRepairId: Object.assign(Object.assign({}, state.inspectionByRepairId), { [action.meta.repairRequestId]: Object.assign({}, action.payload.data.findByRepairRequestId) }) });
    },
    [ActionCreators.GET_INSPECTION_PERFORMED_STATUS.SUCCESS.type]: (state, action) => {
        return Object.assign(Object.assign({}, state), { inspectionPerformedStatus: action.payload });
    },
    [ActionCreators.GET_INSPECTION_PERFORMED_STATUS.FAILURE.type]: (state) => {
        return Object.assign(Object.assign({}, state), { inspectionPerformedStatus: null });
    },
    [ActionCreators.UPDATE_INSPECTION_PERFORMED_STATUS.SUCCESS.type]: (state, action) => {
        return Object.assign(Object.assign({}, state), { inspectionPerformedStatus: action.payload });
    },
}, defaultState);
export default reducers;
