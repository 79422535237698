var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from 'react-redux';
import { createPartner, updatePartner, } from 'src/PartnersApp/modules/partners/actions';
import { useEffect, useState } from 'react';
const usePartnerSubmit = (partner) => {
    const dispatch = useDispatch();
    const [submitComplete, setSubmitComplete] = useState(false);
    useEffect(() => {
        if (partner) {
            setSubmitComplete(false);
        }
    }, [partner]);
    const handleCreatePartner = (values) => __awaiter(void 0, void 0, void 0, function* () {
        if (!partner.id) {
            const newPartner = {
                name: values.name,
                funnels: [
                    {
                        name: values.name,
                        urlParameterIdentifier: values.urlParameterIdentifier,
                        marketingSourceId: values.marketingSourceId,
                        pages: values.pages,
                        startAtPlate: values.startAtPlate,
                    },
                ],
            };
            const partnerResponse = yield dispatch(createPartner({ partner: newPartner }));
            if (!(partnerResponse === null || partnerResponse === void 0 ? void 0 : partnerResponse.error)) {
                setSubmitComplete(true);
                return Promise.resolve(true);
            }
        }
        else {
            const newPartner = {
                id: partner.id,
                name: values.name,
            };
            const partnerResponse = yield dispatch(updatePartner({ partner: newPartner }));
            if (!(partnerResponse === null || partnerResponse === void 0 ? void 0 : partnerResponse.error)) {
                setSubmitComplete(true);
                return Promise.resolve(true);
            }
        }
        return Promise.reject();
    });
    return {
        handleCreatePartner,
        submitComplete,
    };
};
export default usePartnerSubmit;
