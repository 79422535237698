import React from 'react';
export const OverlappingApptIcon = () => (<svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="jz62u5jsra" d="M0 0h18v18H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="kykfxepwib" fill="#fff">
        <use xlinkHref="#jz62u5jsra"/>
      </mask>
      <path d="m2.344 6.844 6.18 3.57a.687.687 0 0 0 .686 0l6.18-3.57a.687.687 0 0 0 0-1.188L9.21 2.092a.687.687 0 0 0-.686 0l-6.18 3.564a.687.687 0 0 0 0 1.188zm12.36 1.586-5.837 3.317L3.03 8.403a.687.687 0 1 0-.686 1.188l6.18 3.57a.687.687 0 0 0 .686 0l6.18-3.57a.687.687 0 1 0-.686-1.188v.027zm0 2.747-5.837 3.316L3.03 11.15a.687.687 0 1 0-.686 1.188l6.18 3.571a.686.686 0 0 0 .686 0l6.18-3.57a.687.687 0 1 0-.686-1.189v.028z" fill="#FF9500" fillRule="nonzero" mask="url(#kykfxepwib)"/>
    </g>
  </svg>);
export const LunchIcon = () => (<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="kf5q6fx54a" d="M0 0h24v24H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="111stqsp0b" fill="#fff">
        <use xlinkHref="#kf5q6fx54a"/>
      </mask>
      <g mask="url(#111stqsp0b)" fill="#E61464" fillRule="nonzero">
        <path d="M14.907 21c.72 0 1.305-.585 1.26-1.305l-.135-6.433h.9c.674 0 1.214-.54 1.17-1.214l-.136-2.294c-.09-1.62-.45-3.194-1.08-4.679a3.458 3.458 0 0 0-2.519-2.07.499.499 0 0 0-.584.496l-.18 16.194c0 .72.584 1.305 1.304 1.305zM7.664 11.148l-.18 8.502c0 .72.585 1.305 1.26 1.305.72 0 1.305-.585 1.26-1.305l-.18-8.502c.944-.09 1.664-.9 1.664-1.844V3.636c0-.315-.27-.54-.54-.54-.314 0-.54.27-.54.54v4.678a.29.29 0 0 1-.27.27h-.584a.29.29 0 0 1-.27-.27V3.636c0-.315-.27-.54-.54-.54-.315 0-.54.27-.54.54v4.678a.29.29 0 0 1-.27.27H7.35a.29.29 0 0 1-.27-.27V3.636a.52.52 0 0 0-.54-.54c-.315 0-.54.27-.54.54v5.668c0 .944.72 1.754 1.664 1.844z"/>
      </g>
    </g>
  </svg>);
export const PartPickupIcon = () => (<svg width="26.483" height="26.483" viewBox="0 0 26.483 26.483" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="k9cxc4p11a" d="M0 0h26.483v26.483H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="imk4adfawb" fill="#fff">
        <use xlinkHref="#k9cxc4p11a"/>
      </mask>
      <g mask="url(#imk4adfawb)" fill="#E61464" fillRule="nonzero">
        <path d="M7.86 11.195v3.447l1.724 1.12v-3.576zM9.929 11.41l8.617-5.127-1.551-.905-8.617 5.127z"/>
        <path d="m10.446 17.4-3.447-2.328v-4.394L4.414 9.17v8.875l8.617 5.084v-8.875l-2.585-1.551zM13.462 13.478l8.617-5.084L19.407 6.8l-8.66 5.084zM4.845 8.394l2.67 1.594 8.66-5.084-2.713-1.594zM22.51 17.075V9.17l-8.618 5.084v8.918z"/>
      </g>
    </g>
  </svg>);
export const VisitIcon = () => (<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="25yrwd1qca" d="M0 0h24v24H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="9hmzzfh85b" fill="#fff">
        <use xlinkHref="#25yrwd1qca"/>
      </mask>
      <path d="M14.228 6.429c.764 0 1.442.462 1.716 1.159l.03.084.748 2.222a1.491 1.491 0 0 1 1.48 1.397l.003.08v2.538c0 .663-.446 1.224-1.057 1.41l-.077.022v1.383a.72.72 0 0 1-.662.712l-.062.003h-.767a.722.722 0 0 1-.722-.653l-.003-.062v-1.337H9.35v1.337a.72.72 0 0 1-.663.712l-.062.003h-.767a.722.722 0 0 1-.722-.653l-.003-.062V15.34a1.486 1.486 0 0 1-1.132-1.35L6 13.91v-2.538c0-.783.62-1.425 1.402-1.474l.081-.003.748-2.222c.24-.715.899-1.205 1.656-1.241l.09-.002h4.25zM7.402 7.714l-.223.665H5.617c-.418 0-.791.253-.95.635l-.027.074-.569 1.424h1.2a1.645 1.645 0 0 0-.128.639v2.864c0 .347.107.669.29.935H4.372v1.08l-.002.055a.577.577 0 0 1-.518.52l-.055.002h-.608l-.055-.002a.577.577 0 0 1-.517-.52l-.003-.055v-1.117l-.069-.02a1.195 1.195 0 0 1-.827-1.058l-.003-.079v-2.05l.002-.072a1.193 1.193 0 0 1 1.1-1.118l.074-.003.593-1.794.028-.077a1.458 1.458 0 0 1 1.272-.925l.086-.003h2.533zm11.98 0c.603 0 1.139.37 1.358.928l.028.077.593 1.794c.625.007 1.137.5 1.174 1.121l.002.072v2.05c0 .533-.35.984-.83 1.137l-.07.02.001 1.117c0 .3-.228.547-.52.575l-.055.002h-.608a.577.577 0 0 1-.572-.522l-.003-.055v-1.08h-1.314c.184-.266.291-.588.291-.935v-2.864c0-.227-.045-.442-.128-.639h1.451l-.569-1.424a1.029 1.029 0 0 0-.977-.709h-1.813l-.222-.665h2.784zm-11.14 3.844a1.09 1.09 0 0 0-1.097 1.082 1.09 1.09 0 0 0 1.097 1.081A1.09 1.09 0 0 0 9.34 12.64c-.001-.597-.493-1.082-1.098-1.082zm7.72 0a1.09 1.09 0 0 0-1.096 1.082 1.09 1.09 0 0 0 1.097 1.081 1.09 1.09 0 0 0 1.097-1.081 1.09 1.09 0 0 0-1.097-1.082zm-12.47.3a.873.873 0 0 0 0 1.747.873.873 0 0 0 0-1.748zm17.267 0a.873.873 0 0 0 0 1.747.873.873 0 0 0 0-1.748zm-6.365-4.44h-4.252a.857.857 0 0 0-.811.581l-.643 1.894h7.19l-.676-1.904a.857.857 0 0 0-.808-.571z" fill="#E61464" fillRule="nonzero" mask="url(#9hmzzfh85b)"/>
    </g>
  </svg>);
export const ClockIcon = () => (<svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M9.869 2.625c3.456 0 6.256 2.856 6.256 6.375s-2.8 6.375-6.256 6.375c-1.942 0-3.676-.905-4.821-2.324l-2.6-.001c-.455 0-.823-.336-.823-.75s.368-.75.822-.75h1.698a6.434 6.434 0 0 1-.463-1.688h-1.86c-.454 0-.822-.335-.822-.75 0-.414.368-.75.822-.75h1.834c.057-.59.193-1.156.397-1.687H1.197c-.454 0-.822-.336-.822-.75s.368-.75.822-.75h3.676c1.14-1.548 2.952-2.55 4.996-2.55zm-.131 1.904H9.7a.64.64 0 0 0-.637.646v4.106c0 .291.149.562.398.71l2.401 1.39a.628.628 0 0 0 .873-.22.644.644 0 0 0-.224-.886L10.375 9.06V5.175a.64.64 0 0 0-.637-.646z" id="x0fentrmra"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h18v18H0z"/>
      <use fill="#2C56DD" xlinkHref="#x0fentrmra"/>
    </g>
  </svg>);
export const CalendarIcon = () => (<svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M13.2 4.09h-.525v-.545c0-.3-.236-.545-.525-.545a.537.537 0 0 0-.525.545v.546h-5.25v-.546c0-.3-.236-.545-.525-.545a.537.537 0 0 0-.525.545v.546H4.8c-.577 0-1.05.49-1.05 1.09v8.728c0 .6.473 1.091 1.05 1.091h8.4c.578 0 1.05-.49 1.05-1.09V5.181c0-.6-.473-1.091-1.05-1.091zm-.919 9.274H5.72a.467.467 0 0 1-.469-.462V7.364h7.5v5.538c0 .254-.21.462-.469.462z" id="4mo20779oa"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h18v18H0z"/>
      <use fill="#2C56DD" xlinkHref="#4mo20779oa"/>
    </g>
  </svg>);
