import React from 'react';
import styled from 'styled-components';
import PaymentSummary from '../PaymentSummary';
import PaymentsTable from '../PaymentsTable';
const ResponsiblePartySection = styled.section `
  margin-bottom: 64px;
`;
const ResponsiblePartyPayment = ({ invoice, onRemovePayment, recalculateTax, }) => {
    return (<ResponsiblePartySection>
      <PaymentSummary invoice={invoice} recalculateTax={recalculateTax}/>
      <PaymentsTable payments={invoice.payments} onRemovePayment={onRemovePayment}/>
    </ResponsiblePartySection>);
};
export default ResponsiblePartyPayment;
