import styled from 'styled-components';
import { FONT_FAMILY, theme, STATIC_URL_BASE } from 'src/styles/theme';
export const RegionContainer = styled.div `
  padding: 0 0 64px;

  .regionsTitle {
    padding: 0;
    margin: 40px 0 24px;
    font-size: 30px;

    ${theme.breakpoints.up('md')} {
      margin: 0 0 24px;
    }
  }

  .regionsSort {
    border: none;
    background-color: transparent;
    background-image: url(${STATIC_URL_BASE}pops/icons/sort.svg);
    background-repeat: no-repeat;
    background-position: left center;
    padding: 4px 36px;
    margin: 0 0 16px;
    cursor: pointer;
    font-family: ${FONT_FAMILY};

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  }

  .regionsFilter {
    border: none;
    background-color: transparent;
    background-image: url(${STATIC_URL_BASE}pops/icons/filter.svg);
    background-repeat: no-repeat;
    background-position: left center;
    padding: 4px 36px;
    margin: 0 0 16px;
    cursor: pointer;
    font-family: ${FONT_FAMILY};

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  }

  .regionsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -8px;

    & > * {
      flex: 1 1 288px;
      margin: 8px;
    }

    ${theme.breakpoints.up('md')} {
      margin: -12px;

      & > * {
        margin: 12px;
      }
    }
  }

  .regionsListWrap {
    margin: 0 0 54px;
  }

  .regionsProgressWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding: 50px;
  }

  .emptyRegions {
    margin: 16px 8px;
  }
`;
