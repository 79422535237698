import styled, { css } from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
const mobileStyles = (styles) => function customMobileStyles({ fullScreen }) {
    return fullScreen ? styles : null;
};
export const VendorSelect = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-grow: 3;
  padding: 32px 0 32px 32px;

  ${mobileStyles(css `
    justify-content: space-between;
    height: 100%;
    padding: 16px;
  `)}

  .dialogRoot {
    padding: 0;

    ${mobileStyles(css `
      margin-top: 8px;
      flex: 1;
      height: 100%;
    `)}
  }

  .vendorDialogText {
    font-family: ${FONT_FAMILY};
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
    margin-bottom: 0;

    ${mobileStyles(css `
      color: ${colors.black};
      font-size: 14px;
      line-height: 18px;
    `)}
  }

  .selectVendor,
  .selectAddress {
    width: 100%;
    margin-top: 24px;

    & .MuiInputLabel-shrink {
      ${mobileStyles(css `
        font-weight: 700;
      `)}
    }
  }

  .dialogActions {
    margin-top: 24px;
    text-align: right;

    ${mobileStyles(css `
      padding-bottom: 36px;
    `)}
  }

  .vendorDialogButton {
    text-transform: none;
    padding: 16px 36px;
    font-family: ${FONT_FAMILY};
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
  }
`;
