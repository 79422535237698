var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFleetVisits, setContinueDisabled, setVisit, } from 'src/AdminApp/modules/quickRo/actions';
import { selectFleetVisits, selectRepairs, } from 'src/AdminApp/modules/quickRo/selectors';
import { selectRecommendedRepairs, selectRequestDataByReferenceNum, selectRequestDiagnosis, selectRequestRequestReferenceNum, } from 'src/AdminApp/modules/requests/selectors';
import useIsMobile from 'src/hooks/useIsMobile';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
const useFleetScheduling = () => {
    const [loading, setLoading] = useState(true);
    const [selectedVisitId, setSelectedVisitId] = useState();
    const [showCapacityWarning, setShowCapacityWarning] = useState(false);
    const [unsavedSelectedVisit, setUnsavedSelectedVisit] = useState();
    const isMobile = useIsMobile();
    const { referenceNum, selectorProps } = usePageReferenceNum();
    const dispatch = useDispatch();
    const request = useSelector(selectRequestRequestReferenceNum(referenceNum));
    const fleetVisits = useSelector(selectFleetVisits);
    const diagnosis = useSelector((state) => selectRequestDiagnosis(state, selectorProps));
    const selectedRecommendedRepairs = useSelector(selectRepairs);
    const recommendedRepairs = useSelector(selectRecommendedRepairs(referenceNum));
    const requestData = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const findSelectedRecommendedRepairs = (rp) => selectedRecommendedRepairs.some((srr) => rp.id === srr.id);
    const calculateMaxLeadTime = () => {
        var _a, _b;
        const existRecommendedRepair = ((_a = recommendedRepairs === null || recommendedRepairs === void 0 ? void 0 : recommendedRepairs.filter(findSelectedRecommendedRepairs).flatMap((num) => num)) === null || _a === void 0 ? void 0 : _a.length) > 0;
        if (existRecommendedRepair) {
            return getRecommendedRepairsMaxLeadTime();
        }
        return ((_b = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _b === void 0 ? void 0 : _b.maxLeadTime) || 0;
    };
    const getRecommendedRepairsMaxLeadTime = () => {
        const listMaxLeadTime = recommendedRepairs
            .filter(findSelectedRecommendedRepairs)
            .map((item) => item.maxLeadTime);
        if (listMaxLeadTime.length === 0) {
            return 0;
        }
        return listMaxLeadTime.reduce((prev, curr) => Math.max(prev, curr));
    };
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            dispatch(setContinueDisabled(true));
            if (!request)
                return;
            setLoading(true);
            yield dispatch(fetchFleetVisits(request.fleetId, calculateMaxLeadTime()));
            setLoading(false);
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]);
    const shouldShowCapacityWarning = (visit) => {
        var _a, _b, _c;
        const currentScheduledMinutes = (_a = visit.totalScheduledLaborMinutes) !== null && _a !== void 0 ? _a : 0;
        const totalRemainingLaborMinutes = (_b = visit.totalRemainingLaborMinutes) !== null && _b !== void 0 ? _b : 0;
        // VISIT ALREADY OUT OF TIME
        if (totalRemainingLaborMinutes <= 0) {
            setShowCapacityWarning(true);
            return true;
        }
        const newRoTotalLaborDurationHours = (_c = requestData.request.totalLaborDuration) !== null && _c !== void 0 ? _c : 0;
        const newRoTotalLaborDurationMinutes = newRoTotalLaborDurationHours * 60;
        const newScheduledMinutes = newRoTotalLaborDurationMinutes + currentScheduledMinutes;
        if (newScheduledMinutes > totalRemainingLaborMinutes) {
            setShowCapacityWarning(true);
            return true;
        }
        return false;
    };
    const handleCapacityWarningClose = (confirm) => {
        setShowCapacityWarning(false);
        if (confirm && unsavedSelectedVisit) {
            setSelectedVisitId(unsavedSelectedVisit.id);
            dispatch(setContinueDisabled(false));
            dispatch(setVisit({ visit: unsavedSelectedVisit }));
        }
        setUnsavedSelectedVisit(undefined);
    };
    const handleSelect = (visit) => () => {
        // UNSELECT
        if (visit.id === selectedVisitId) {
            setSelectedVisitId(undefined);
            dispatch(setContinueDisabled(true));
            return;
        }
        // SELECT NEW VISIT
        // CHECK IF VISIT HAS CAPACITY FOR THE NEW RO
        setUnsavedSelectedVisit(visit);
        if (!shouldShowCapacityWarning(visit)) {
            setSelectedVisitId(visit.id);
            dispatch(setContinueDisabled(false));
            dispatch(setVisit({ visit }));
        }
    };
    return {
        isMobile,
        loading,
        fleetVisits,
        selectedVisitId,
        handleSelect,
        showCapacityWarning,
        handleCapacityWarningClose,
        setSelectedVisitId,
        setUnsavedSelectedVisit,
    };
};
export default useFleetScheduling;
