var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import InvoiceRequestDetailsDrawer from 'src/AdminApp/pages/invoiceRequests/InvoiceRequestDetailsDrawer';
import InvoiceRequestItem from 'src/AdminApp/pages/invoiceRequests/InvoiceRequestItem';
import InvoiceRequestStatusSelect from 'src/AdminApp/pages/invoiceRequests/InvoiceRequestStatusSelect/InvoiceRequestStatusSelect';
import InvoiceRequestTypeSelect from 'src/AdminApp/pages/invoiceRequests/InvoiceRequestTypeSelect/InvoiceRequestTypeSelect';
import useInvoiceRequests from 'src/AdminApp/pages/invoiceRequests/useInvoiceRequests';
import NullState from 'src/AdminApp/pages/invoiceWorkbench/components/NullState';
import { FiltersContainer } from 'src/AdminApp/pages/invoiceWorkbench/InvoiceWorkbench.styles';
import { PrimaryButton } from 'src/components/buttons';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { ConfirmationModal } from 'src/components/layout';
import { Button, Container, FormControl, InputLabel, TextField, } from 'src/components/mui';
import SimpleAutosuggest from 'src/components/SimpleAutosuggest';
import useIsMobile from 'src/hooks/useIsMobile';
const InvoiceRequests = () => {
    var _a;
    const isMobile = useIsMobile();
    const { worpDriveAccess, pagination, onLoadData, type, status, isFetching, tableSort, handleChangeStatus, handleChangeType, invoiceRequests, showDetailsDrawer, handleShowDetailsDrawer, handleHideDetailsDrawer, selectedInvoiceRequest, handleAcknowledge, selectedResponsibleParty, handleSearch, handleRPSearch, handleSelectResponsibleParty, handleRefNumSearch, handleRefNumChange, refNumSearch, resetFilters, refNumSearchError, firstSearch, getNullText, clearInput, handleCancelInvoiceRequest, isInvoiceRequestCancelDialogOpen, handleInvoiceCancelDialogState, } = useInvoiceRequests(true);
    const WORKBENCH_COLUMNS = [
        { title: 'Type', key: 'type' },
        {
            title: 'Responsible Party',
            key: 'responsibleParty.name',
            sortable: true,
        },
        { title: 'Status', key: 'status', textAlign: 'center' },
        {
            title: 'Created',
            key: 'created',
            sortable: true,
        },
        {
            title: 'Started',
            key: 'startedAt',
            sortable: true,
        },
        { title: 'Completed', key: 'completedAt', sortable: true },
        { title: 'Recipients', key: 'numRecipients' },
        { title: 'Total', key: 'numTotal' },
        {
            title: 'Completed',
            key: 'numCompleted',
            textAlign: 'center',
        },
        { title: 'Errors', key: 'numErrors' },
        { title: '', key: 'actions' },
    ];
    return worpDriveAccess ? (<>
      <Container maxWidth="xl">
        <h3>Invoice Requests</h3>
        <FiltersContainer style={{ alignItems: isMobile ? 'center' : 'flex-start' }}>
          <FormControl>
            <TextField id="ref-number-lookup" label="Reference Number" variant="outlined" disabled={isFetching} value={refNumSearch} onChange={handleRefNumChange} error={refNumSearchError}/>
          </FormControl>
          <PrimaryButton style={{
            marginLeft: '8px',
            padding: '15px 30px',
            marginTop: isMobile ? '16px' : 0,
        }} disabled={isFetching || refNumSearchError} onClick={handleRefNumSearch}>
            Search
          </PrimaryButton>
        </FiltersContainer>
        <FiltersContainer>
          <FormControl>
            <InputLabel id="workbench-select-label" style={{
            backgroundColor: 'white',
            padding: '0 5px',
        }}>
              Type
            </InputLabel>
            <InvoiceRequestTypeSelect disabled={isFetching} type={type} onChange={handleChangeType}/>
          </FormControl>
          <FormControl style={{
            marginLeft: isMobile ? 0 : '8px',
        }}>
            <InputLabel id="workbench-select-label" style={{
            backgroundColor: 'white',
            padding: '0 5px',
        }}>
              Status
            </InputLabel>
            <InvoiceRequestStatusSelect disabled={isFetching} status={status} onChange={handleChangeStatus}/>
          </FormControl>
          <SimpleAutosuggest style={{
            minWidth: isMobile ? '100%' : 500,
            marginLeft: isMobile ? 0 : '8px',
            marginTop: isMobile ? '16px' : 0,
        }} label="Responsible Party" autoFocus={false} fieldName="nameWithType" fieldToInput="nameWithType" clearOnEmpty={selectedResponsibleParty} clearOnChange={clearInput} disabled={isFetching} initialValue={(_a = selectedResponsibleParty === null || selectedResponsibleParty === void 0 ? void 0 : selectedResponsibleParty.nameWithType) !== null && _a !== void 0 ? _a : ''} inputValue="nameWithType" onSearch={(value) => __awaiter(void 0, void 0, void 0, function* () { return yield handleRPSearch(value); })} onSelect={(suggestion) => {
            handleSelectResponsibleParty(suggestion);
        }} onClear={() => {
            handleSelectResponsibleParty(undefined);
        }} noOptions={firstSearch ? 'Type in a Search' : 'No Responsible Parties Found'}/>
          <PrimaryButton style={{
            marginLeft: '8px',
            padding: '15px 30px',
            marginTop: isMobile ? '16px' : 0,
        }} disabled={isFetching} onClick={() => {
            handleSearch();
        }}>
            Search
          </PrimaryButton>
          <Button variant="text" style={{ marginLeft: '24px', whiteSpace: 'nowrap' }} onClick={resetFilters} disabled={isFetching}>
            Reset Filters
          </Button>
        </FiltersContainer>
        <SimpleTable isFetching={isFetching} columns={WORKBENCH_COLUMNS} data={invoiceRequests} pagination={pagination} sort={tableSort} hideTopPagination renderItem={(item, index) => (<InvoiceRequestItem key={index} item={item} onClickViewDetails={handleShowDetailsDrawer} handleAcknowledge={handleAcknowledge} handleCancelInvoiceRequest={() => {
                handleInvoiceCancelDialogState(true, item.id);
            }}/>)} hidePagination={invoiceRequests.length < 1} onLoadData={onLoadData} showNullState NullState={<NullState text={getNullText()}/>}/>
        <InvoiceRequestDetailsDrawer open={showDetailsDrawer} onClose={() => handleHideDetailsDrawer()} invoiceRequests={selectedInvoiceRequest ? [selectedInvoiceRequest] : []} handleAcknowledge={handleAcknowledge}/>
        <ConfirmationModal open={isInvoiceRequestCancelDialogOpen !== null && isInvoiceRequestCancelDialogOpen !== void 0 ? isInvoiceRequestCancelDialogOpen : false} title="Cancel Invoice Request" text="Are you sure to cancel the invoice request?" onClose={() => {
            handleInvoiceCancelDialogState(false);
        }} action={handleCancelInvoiceRequest}/>
      </Container>
    </>) : (<div>
      ERROR - Not Authorized: You must have the "WORP Drive Access" role to view
      this item
    </div>);
};
export default InvoiceRequests;
