var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, CircularProgress, Grid, TextField } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { JobsTable } from 'src/PopsApp/pages/Job/Job.styles';
import { getOrderRosterJobByReferenceNum } from 'src/PopsApp/modules/parts/actions';
import { SPECIAL_ORDER_TOOLTIP } from 'src/PopsApp/modules/api/constants';
import SpecialOrderIndicator from 'src/PopsApp/components/SpecialOrderIndicator';
import { SpecialOrderWrapper } from 'src/PopsApp/pages/Job/JobTableRow/JobTableRow.styles';
import { Container } from 'src/PopsApp/pages/Order/Order.styles';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
import { LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
const BigButton = styled(Button) `
  height: 100%;
`;
const OrderRosterSearch = () => {
    const dispatch = useDispatch();
    const [job, setJob] = useState();
    const formatAppointmentDate = (date, timezone) => {
        const currentTimezone = moment.tz.guess();
        const localDate = moment.tz(date, timezone).local();
        return `${localDate.format(LOCALIZED_DATE_FORMAT)} • ${localDate.format(TIME_12H_FORMAT)} ${moment
            .tz(currentTimezone)
            .zoneAbbr()}`;
    };
    const formatUtcDate = (date) => {
        const currentTimezone = moment.tz.guess();
        const localDate = moment.tz(`${date}Z`, 'UTC').local();
        return `${localDate.format(LOCALIZED_DATE_FORMAT)} • ${localDate.format(TIME_12H_FORMAT)} ${moment
            .tz(currentTimezone)
            .zoneAbbr()}`;
    };
    const renderPendingComponents = (partsNeeded, fluidsNeeded) => {
        return (partsNeeded || 0) + (fluidsNeeded || 0);
    };
    const [submitting, setSubmitting] = useState(false);
    const [queryString, setQueryString] = useState('');
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        const response = yield dispatch(getOrderRosterJobByReferenceNum(queryString.trim()));
        setSubmitting(false);
        if (response.error) {
            setJob(undefined);
        }
        else {
            setJob(response.payload);
        }
    });
    const isJobActivelySkipped = () => {
        if (!(job === null || job === void 0 ? void 0 : job.skippedFrom) || !(job === null || job === void 0 ? void 0 : job.skippedTo)) {
            return false;
        }
        const utcSkippedFrom = moment.utc(job.skippedFrom);
        const utcSkippedTo = moment.utc(job.skippedTo);
        const utcMoment = moment.utc();
        return utcMoment.isBetween(utcSkippedFrom, utcSkippedTo);
    };
    const isPriorityAvailable = () => {
        const isShiftValid = (job === null || job === void 0 ? void 0 : job.shift) === 'DAY' || (job === null || job === void 0 ? void 0 : job.shift) === 'NIGHT';
        return ((isJobActivelySkipped() || (job === null || job === void 0 ? void 0 : job.partsStatus) === 'IN_PROGRESS') &&
            isShiftValid);
    };
    const getSearchForm = () => {
        return (<Formik enableReinitialize initialValues={{ query: '' }} onSubmit={handleSubmit}>
        {() => (<Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField autoFocus fullWidth disabled={submitting} label="Search Query" name="query" placeholder="Reference #" variant="outlined" value={queryString} onChange={(event) => setQueryString(event.target.value)}/>
              </Grid>
              <Grid item xs={4} md={2}>
                <BigButton fullWidth disabled={submitting} size="small" variant="outlined" onClick={() => setQueryString('')}>
                  Reset Form
                </BigButton>
              </Grid>
              <Grid item xs={4} md={2}>
                <BigButton fullWidth disabled={submitting} size="small" variant="outlined" onClick={() => setJob(undefined)}>
                  Clear Result
                </BigButton>
              </Grid>
              <Grid item xs={4} md={2}>
                <BigButton fullWidth color="primary" disabled={submitting || !queryString} size="small" type="submit" variant="contained">
                  {!submitting ? 'Search' : <CircularProgress size="1.75rem"/>}
                </BigButton>
              </Grid>
            </Grid>
          </Form>)}
      </Formik>);
    };
    return (<>
      {getSearchForm()}
      <Container className="orderContainer" style={{ width: '100%' }}>
        <JobsTable>
          <thead>
            <tr>
              <th>Priority</th>
              <th>Van Name</th>
              <th>Reference #</th>
              <th>Date</th>
              <th>Shift</th>
              <th>Pending Components</th>
              <th>Skipped From</th>
              <th>Skipped To</th>
              <th>Skipped by</th>
              <th>Order started at</th>
              <th>Order started by</th>
            </tr>
          </thead>
          <tbody>
            {job && (<SimpleTableRow data={[
                isPriorityAvailable() ? 'N/A' : '',
                <a href={`/admin/shops/${job.ispId}/edit`} rel="noopener noreferrer" target="_blank">
                    {job.vanName}
                  </a>,
                !job.containsSpecialOrder ? (<a href={`/admin/repairs/${job.referenceNum}`} rel="noopener noreferrer" target="_blank">
                      {job.referenceNum}
                    </a>) : (<Tooltip title={SPECIAL_ORDER_TOOLTIP}>
                      <SpecialOrderWrapper>
                        <span className="neededText">
                          <a href={`/admin/repairs/${job.referenceNum}`} rel="noopener noreferrer" target="_blank">
                            {job.referenceNum}
                          </a>
                        </span>
                        <SpecialOrderIndicator />
                      </SpecialOrderWrapper>
                    </Tooltip>),
                job.scheduleStart &&
                    formatAppointmentDate(job.scheduleStart, job.timezone),
                job.shift,
                renderPendingComponents(job.partsNeeded, job.fluidsNeeded),
                job.skippedFrom && formatUtcDate(job.skippedFrom),
                job.skippedTo && formatUtcDate(job.skippedTo),
                job.skippedBy,
                job.orderStartedAt && formatUtcDate(job.orderStartedAt),
                job.orderStartedBy,
            ]}/>)}
          </tbody>
        </JobsTable>
      </Container>
    </>);
};
export default OrderRosterSearch;
