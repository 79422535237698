import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import styled from 'styled-components';
import { Grid, IconButton, Icon } from 'src/components/mui';
import { SimpleModal } from 'src/components/layout';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const ImagePreview = styled.img `
  width: ${({ scale }) => 100 * scale}%;
  overflow: auto;
`;
const Title = styled.div `
  display: flex;
  align-items: center;
`;
const ZoomButtonsWrapper = styled.div `
  margin-left: auto;
`;
const InvoiceDocumentViewer = (props) => {
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1.0);
    const onDocumentLoadSuccess = ({ numPages }) => {
        if (numPages === null) {
            setNumPages(numPages !== null && numPages !== void 0 ? numPages : undefined);
        }
    };
    const isPDF = (url) => {
        // eslint-disable-next-line no-useless-escape
        const getExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        const match = url.match(getExtension);
        return (match === null || match === void 0 ? void 0 : match.length) === 0 || (match && match[1].toLowerCase() === 'pdf');
    };
    const goToPrevPage = () => {
        setPageNumber(pageNumber - 1);
    };
    const goToNextPage = () => {
        setPageNumber(pageNumber + 1);
    };
    const isPrevDisabled = () => pageNumber === 1;
    const isNextDisabled = () => pageNumber === numPages;
    const addScale = () => {
        setScale(scale + 0.2);
    };
    const removeScale = () => {
        setScale(scale - 0.2);
    };
    const renderTitle = () => (<Title>
      Invoice Preview
      <ZoomButtonsWrapper>
        <IconButton onClick={addScale}>
          <Icon>zoom_in</Icon>
        </IconButton>
        <IconButton disabled={scale === 1} onClick={removeScale}>
          <Icon>zoom_out</Icon>
        </IconButton>
      </ZoomButtonsWrapper>
    </Title>);
    const { url, open, onClose } = props;
    return (<SimpleModal open={open} onClose={onClose} title={renderTitle()} maxWidth="xl">
      {url && isPDF(url) ? (<>
          <Document file={{
                url: props.url,
            }} onLoadSuccess={onDocumentLoadSuccess}>
            <Page scale={scale} pageNumber={pageNumber}/>
          </Document>
          <Grid container style={{ alignItems: 'self-end' }}>
            <p>
              Page {pageNumber} of {numPages}
            </p>
            <IconButton disabled={isPrevDisabled()} onClick={goToPrevPage}>
              <Icon>keyboard_arrow_left</Icon>
            </IconButton>
            <IconButton disabled={isNextDisabled()} onClick={goToNextPage}>
              <Icon>keyboard_arrow_right</Icon>
            </IconButton>
          </Grid>
        </>) : (<ImagePreview scale={scale} src={url}/>)}
    </SimpleModal>);
};
export default InvoiceDocumentViewer;
