import styled from 'styled-components';
import { IconButton } from 'src/components/mui';
import { Colors } from 'src/PopsApp/modules/globalStyle';
import { StatusesMainColors } from '../../constants';
const StatusesRightSideColors = {
    COMPLETE: '#cdeae2',
    IN_PROGRESS: '#f9f1da',
    READY: '#e1e7fb',
    EN_ROUTE: '#f6e0f9',
    ARRIVED: '#e8e5f9',
    PENDING_ALLOCATION: '#e1e7fb',
    QUEUED: '#e1e7fb',
};
export const DraggableJob = styled.div `
  width: ${(props) => (props.width === 0 ? 90 : props.width)}px;
  height: 65px;
  border-radius: 8px;
  border: ${(props) => {
    var _a;
    return props.selected || props.isJobBeingUpdated
        ? `3px ${props.isJobBeingUpdated ? 'dashed' : 'solid'} ${(_a = StatusesMainColors[props.jobStatus]) !== null && _a !== void 0 ? _a : StatusesMainColors.READY}`
        : `1px solid ${Colors.GRAY_CC}`;
}};
  background-color: ${(props) => { var _a; return (_a = StatusesRightSideColors[props.jobStatus]) !== null && _a !== void 0 ? _a : StatusesRightSideColors.READY; }};
  position: relative;
  grid-row: ${(props) => props.gridRow};
  grid-column: 1;
  grid-area; 1 / 1 !important;
  z-index: ${(props) => props.zIndex};
  transition: all 200ms linear;
  cursor: ${(props) => (props.isJobBeingUpdated ? 'grab' : 'default')};

  &::before {
    content: '';
    left: 0;
    width: 15px;
    height: 100%;
    background: ${(props) => { var _a; return (_a = StatusesMainColors[props.jobStatus]) !== null && _a !== void 0 ? _a : StatusesMainColors.READY; }};
    position: absolute;
    border-top-left-radius: ${(props) => (props.selected ? 0 : 8)}px;
    border-bottom-left-radius: ${(props) => (props.selected ? 0 : 8)}px;
  }

  &::after {
    content: '';
    height: 1px;
    width: ${(props) => props.travelTimeTailWidth}px;
    background: ${Colors.GRAY_76};
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`;
export const ChartJobContainer = styled.div `
  display: flex;
  align-items: center;
  height: 100%;
`;
export const ChartJobInfoContainer = styled.div `
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-left: 16px;
  white-space: nowrap;
`;
export const ChartJobInfo = styled.div `
  display: flex;
  flex-direction: column;
  margin: 0 5px;
`;
export const ChartJobInfoReferenceNum = styled.span `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.23px;
`;
export const ChartJobInfoAddress = styled.span `
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.08px;
  color: ${Colors.GRAY_76};
`;
export const OverlappingApptButton = styled(IconButton) `
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0px 0px;

  svg {
    width: 22px;
    height: 22px;
  }
`;
export const JobPopperContent = styled.div `
  border-radius: 4px;
  border: solid 1px #dedede;
  background-color: white;
`;
export const UnavailabilityContainer = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  transform: ${(props) => `translateX(${props.position}px)`};
  width: ${(props) => props.width}px;
  height: 65px;
  background-color: #c0bfbf;
  padding: 7px;
  z-index: 1;
  overflow: hidden;
`;
export const JobPlaceholder = styled.div `
  position: absolute;
  width: ${(props) => (props.width === 0 ? 90 : props.width)}px;
  height: 65px;
  border-radius: 8px;
  transform: ${(props) => `translateX(${props.position}px)`};
  background: transparent;
  border: ${(props) => {
    var _a;
    return `3px dashed ${(_a = StatusesMainColors[props.jobStatus]) !== null && _a !== void 0 ? _a : StatusesMainColors.READY}`;
}};
`;
