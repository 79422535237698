var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from '../api/urls';
export const ActionCreators = {
    GET_CATALOGS: createDefaultActionCreators('GET_CATALOGS'),
    UPDATE_MANUFACTURER: createDefaultActionCreators('UPDATE_MANUFACTURER'),
    SAVE_MANUFACTURER: createDefaultActionCreators('SAVE_MANUFACTURER'),
    UPDATE_CATALOG_MANUFACTURER: createDefaultActionCreators('UPDATE_CATALOG_MANUFACTURER'),
    REMOVE_CATALOG_MANUFACTURER: createDefaultActionCreators('REMOVE_CATALOG_MANUFACTURER'),
    SAVE_CATALOG_MANUFACTURER: createDefaultActionCreators('SAVE_CATALOG_MANUFACTURER'),
    SEARCH_MANUFACTURERS: createDefaultActionCreators('SEARCH_MANUFACTURERS', [
        'CLEAR',
    ]),
};
export const saveManufacturer = (manufacturer) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.SAVE_MANUFACTURER.FETCH(),
            ActionCreators.SAVE_MANUFACTURER.SUCCESS(),
            ActionCreators.SAVE_MANUFACTURER.FAILURE(),
        ],
        body: manufacturer,
        endpoint: API_URLS.MANUFACTURERS.MANUFACTURERS(),
        method: 'POST',
    }));
    dispatch(searchManufacturers({}));
    return response;
});
export const updateManufacturer = (manufacturer) => createAPIAction({
    types: [
        ActionCreators.UPDATE_MANUFACTURER.FETCH(),
        ActionCreators.UPDATE_MANUFACTURER.SUCCESS(),
        ActionCreators.UPDATE_MANUFACTURER.FAILURE(),
    ],
    body: manufacturer,
    endpoint: API_URLS.MANUFACTURERS.MANUFACTURERS(),
    method: 'PUT',
});
export const saveCatalogManufacturer = (catalogManufacturer) => createAPIAction({
    types: [
        ActionCreators.SAVE_CATALOG_MANUFACTURER.FETCH(),
        ActionCreators.SAVE_CATALOG_MANUFACTURER.SUCCESS(),
        ActionCreators.SAVE_CATALOG_MANUFACTURER.FAILURE(),
    ],
    body: catalogManufacturer,
    endpoint: API_URLS.MANUFACTURERS.CATALOG_MANUFACTURER(),
    method: 'POST',
});
export const updateCatalogManufacturer = (catalogManufacturer) => createAPIAction({
    types: [
        ActionCreators.UPDATE_CATALOG_MANUFACTURER.FETCH(),
        ActionCreators.UPDATE_CATALOG_MANUFACTURER.SUCCESS(),
        ActionCreators.UPDATE_CATALOG_MANUFACTURER.FAILURE(),
    ],
    body: catalogManufacturer,
    endpoint: API_URLS.MANUFACTURERS.CATALOG_MANUFACTURER(),
    method: 'PUT',
});
export const removeCatalogManufacturer = (catalogManufacturer) => createAPIAction({
    types: [
        ActionCreators.REMOVE_CATALOG_MANUFACTURER.FETCH(),
        ActionCreators.REMOVE_CATALOG_MANUFACTURER.SUCCESS({
            meta: () => ({ catalogManufacturer }),
        }),
        ActionCreators.REMOVE_CATALOG_MANUFACTURER.FAILURE(),
    ],
    endpoint: API_URLS.MANUFACTURERS.CATALOG_MANUFACTURER_BY_ID((catalogManufacturer === null || catalogManufacturer === void 0 ? void 0 : catalogManufacturer.id) ? catalogManufacturer.id.toString() : ''),
    method: 'DELETE',
});
export const searchManufacturers = (params) => createAPIAction({
    types: [
        ActionCreators.SEARCH_MANUFACTURERS.FETCH({
            meta: () => ({ params }),
        }),
        ActionCreators.SEARCH_MANUFACTURERS.SUCCESS({
            meta: () => ({ params }),
        }),
        ActionCreators.SEARCH_MANUFACTURERS.FAILURE(),
    ],
    endpoint: API_URLS.MANUFACTURERS.SEARCH_MANUFACTURERS(),
    params: Object.assign(Object.assign({}, params.pagination), params.filters),
    method: 'GET',
});
