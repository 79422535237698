import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAllFleetCars } from 'src/AdminApp/modules/fleets/selectors';
const useFleetVehicleSearchSelect = ({ responsibleParty, }) => {
    const cars = useSelector(selectAllFleetCars);
    const [inputValue, setInputValue] = useState('');
    const [selectedCars, setSelectedCars] = useState([]);
    const options = cars.filter((i) => { var _a; return !((_a = responsibleParty.cars) !== null && _a !== void 0 ? _a : []).find((option) => option.id === i.id); });
    const allSelected = JSON.stringify(selectedCars) === JSON.stringify(options !== null && options !== void 0 ? options : []);
    const onUpdate = (data) => {
        setSelectedCars(data);
    };
    const toggleSelect = (option, selected) => {
        const index = selectedCars.findIndex((car) => car === option);
        if (index < 0 && selected) {
            setSelectedCars([...selectedCars, option]);
        }
        else if (!selected) {
            setSelectedCars(selectedCars.filter((car) => car !== option));
        }
    };
    return {
        inputValue,
        options,
        selectedCars,
        allSelected,
        setInputValue,
        setSelectedCars,
        onUpdate,
        toggleSelect,
    };
};
export default useFleetVehicleSearchSelect;
