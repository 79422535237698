var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/mui';
import { Create } from 'src/components/mui/icons';
import UserProfileInfo from 'src/MyProfileApp/pages/MyProfile/components/UserProfile/UserProfileInfo';
import Loader from 'src/MyProfileApp/pages/MyProfile/components/Loader';
import { selectCurrentUser } from 'src/AdminApp/modules/users/selectors';
import { loadCurrentUser } from 'src/AdminApp/modules/users/actions';
import { Wrapper, CTAWrapper, TopContainer, UserProfilePictureWrapper, UserTopInfoWrapper, } from './Content.styles';
import EditMyProfileInfoDrawer from '../../EditMyProfileInfoDrawer';
const Content = () => {
    var _a;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [editMyProfile, setEditMyProfile] = useState(false);
    const currentUser = useSelector(selectCurrentUser);
    const editProfileHandler = () => {
        setEditMyProfile(true);
    };
    const editProfileOnCloseHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        setEditMyProfile(false);
        setLoading(true);
        dispatch(loadCurrentUser());
        setLoading(false);
    });
    useEffect(() => {
        setLoading(true);
        dispatch(loadCurrentUser());
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
      <Wrapper align="center">
        <CTAWrapper>
          <Button onClick={editProfileHandler} color="primary" startIcon={<Create />}>
            Edit Profile
          </Button>
        </CTAWrapper>
        <TopContainer>
          <UserTopInfoWrapper>
            <UserProfilePictureWrapper>
              <img className="profile-pic" alt="Profile" src={(_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.profilePicture) !== null && _a !== void 0 ? _a : 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg' // TODO replace image placement to secure one
        }/>
            </UserProfilePictureWrapper>
            <UserProfileInfo user={currentUser}/>
          </UserTopInfoWrapper>
        </TopContainer>
        <EditMyProfileInfoDrawer open={editMyProfile} user={currentUser} onClose={editProfileOnCloseHandler}/>
      </Wrapper>
      {loading && <Loader />}
    </>);
};
export default Content;
