import React from 'react';
import { WorkOrdersTableEl } from 'src/FleetVisitsApp/pages/VisitView/WorkOrdersTable/WorkOrdersTable.styles';
import WorkOrdersTableRow from 'src/FleetVisitsApp/pages/VisitView/WorkOrdersTable/WorkOrdersTableRow';
const WorkOrdersTable = ({ workOrders, technicianTasks, onWorkOrderRemove, }) => {
    const handleWorkOrderRemove = (workOrder) => {
        onWorkOrderRemove(workOrder);
    };
    return (<WorkOrdersTableEl>
      <thead>
        <tr>
          <th>Reference</th>
          <th>Customer</th>
          <th>Vehicle Details/VIN</th>
          <th>Tech</th>
          <th>Services</th>
          <th>RO Status</th>
          <th>MPI</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {workOrders.map((workOrder) => (<WorkOrdersTableRow key={workOrder.id} workOrder={workOrder} technicianTasks={technicianTasks} onWorkOrderRemove={handleWorkOrderRemove}/>))}
      </tbody>
    </WorkOrdersTableEl>);
};
export default WorkOrdersTable;
