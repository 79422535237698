var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Grid, } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import { SlidePageContent, SlidePageFooter, SlidePageFormWrapper, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { getMetrosAutocomplete } from 'src/AdminApp/modules/metros/actions';
import { selectMetrosAutocomplete } from 'src/AdminApp/modules/metros/selectors';
import MetroSearch from 'src/components/form/MetroSearch';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const ArchiveMetroPage = ({ model, onArchive }) => {
    const validationSchema = Yup.object().shape({
        transferMetro: Yup.string().required(),
        assignTechnicianMetro: Yup.string().notRequired(),
        techniciansTransferMetro: Yup.string().when('assignTechnicianMetro', (assignTechnicianMetro, schema) => {
            return assignTechnicianMetro === 'true'
                ? schema.required()
                : schema.nullable();
        }),
    });
    return model ? (<Formik validateOnMount initialValues={{
            id: model === null || model === void 0 ? void 0 : model.id,
            transferMetro: null,
            assignTechnicianMetro: 'false',
            techniciansTransferMetro: null,
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if (onArchive) {
                yield onArchive({
                    id: values.id,
                    transferMetroId: (_a = values.transferMetro) === null || _a === void 0 ? void 0 : _a.id,
                    techniciansTransferMetroId: (_b = values.techniciansTransferMetro) === null || _b === void 0 ? void 0 : _b.id,
                });
                setSubmitting(false);
            }
        })}>
      {({ values, isSubmitting, isValid, setFieldValue }) => (<SlidePageFormWrapper>
          <SlidePageContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>{`Move the following "${model.name}" regions to a different metro`}</h2>
              </Grid>
              <Grid item xs={12}>
                <h4>
                  {model.regions
                .filter((x) => !x.archived)
                .map((x) => {
                return <p>{x.name}</p>;
            })}
                </h4>
              </Grid>
              <Grid item xs={12}>
                <MetroSearch value={values.transferMetro} name="metro" label="Metro" onUpdate={(metro) => setFieldValue('transferMetro', metro)} getMetros={getMetrosAutocomplete} metrosSelector={selectMetrosAutocomplete}/>
              </Grid>
              {model.users === 0 ? (<Grid item xs={12}>
                  <h2>There are no technicians assigned to this metro.</h2>
                </Grid>) : (<Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="yes-no-controlled-radio-buttons-group">
                      There are technicians that have this as their default
                      metro, do you want to assign them to another one?
                    </FormLabel>
                    <RadioGroup style={{ display: 'block' }} aria-labelledby="yes-no-controlled-radio-buttons-group" name="yes-no-controlled-radio-buttons-group" value={values.assignTechnicianMetro} onChange={({ target }) => {
                    setFieldValue('assignTechnicianMetro', target.value);
                    if (target.value === 'false') {
                        setFieldValue('techniciansTransferMetro', null);
                    }
                }}>
                      <FormControlLabel value="true" control={<Radio />} label="Yes"/>
                      <FormControlLabel value="false" control={<Radio />} label="No"/>
                    </RadioGroup>
                  </FormControl>
                  <Grid item xs={12}>
                    <MetroSearch disabled={values.assignTechnicianMetro === 'false'} value={values.techniciansTransferMetro} name="technicians-metro" label="Technicians Metro" onUpdate={(metro) => setFieldValue('techniciansTransferMetro', metro)} getMetros={getMetrosAutocomplete} metrosSelector={selectMetrosAutocomplete}/>
                  </Grid>
                </Grid>)}
            </Grid>
          </SlidePageContent>
          <SlidePageFooter>
            <FooterActionButton type="submit" disabled={isSubmitting || !isValid}>
              Archive Metro
            </FooterActionButton>
          </SlidePageFooter>
        </SlidePageFormWrapper>)}
    </Formik>) : null;
};
export default ArchiveMetroPage;
