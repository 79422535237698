import * as Yup from 'yup';
import { addContextValidation, nullableNumber, } from 'src/PricingApp/utils/validations';
const AFTERMARKET_MIN_DISCOUNT = 0.0;
const AFTERMARKET_MAX_DISCOUNT = 1.0;
const AFTERMARKET_DISCOUNT_RANGE_TEXT = `must be in range ${AFTERMARKET_MIN_DISCOUNT.toFixed(2)}-${AFTERMARKET_MAX_DISCOUNT.toFixed(2)}`;
const CONCIERGE_MIN_PRICE = 0;
const CONCIERGE_MAX_PRICE = 999999;
const CONCIERGE_PRICE_RANGE_TEXT = `must be in range ${CONCIERGE_MIN_PRICE.toLocaleString()}-${CONCIERGE_MAX_PRICE.toLocaleString()}`;
export const addRepairContextValidation = (values) => {
    return addContextValidation(values, AddRepairValidationSchema);
};
const AddRepairValidationSchema = Yup.object().shape({
    repair: Yup.object().shape({
        name: Yup.string().required('Repair Name is required'),
        repairType: Yup.string(),
        fmcRepairMappings: Yup.array().of(Yup.object()
            .nullable()
            .shape({
            fmcId: Yup.string().test('Autointegrate service code', 'Autointegrate service code must be a positive integer', (externalId) => {
                return (!externalId ||
                    (Number.isInteger(Number(externalId)) && Number(externalId) > 0));
            }),
        })),
    }),
    productDetails: Yup.object().shape({
        defaultReservedTime: Yup.number()
            .typeError('Default reserved time is required')
            .positive('duration must be a positive number'),
    }),
    aftermarketPartDiscount: Yup.object()
        .nullable()
        .shape({
        amDiscountPercent1: nullableNumber()
            .min(AFTERMARKET_MIN_DISCOUNT, AFTERMARKET_DISCOUNT_RANGE_TEXT)
            .max(AFTERMARKET_MAX_DISCOUNT, AFTERMARKET_DISCOUNT_RANGE_TEXT),
        amDiscountPercent2: nullableNumber()
            .min(AFTERMARKET_MIN_DISCOUNT, AFTERMARKET_DISCOUNT_RANGE_TEXT)
            .max(AFTERMARKET_MAX_DISCOUNT, AFTERMARKET_DISCOUNT_RANGE_TEXT),
    }),
    conciergeRules: Yup.object().shape({
        minPrice: Yup.number()
            .typeError('Price is required')
            .min(CONCIERGE_MIN_PRICE, CONCIERGE_PRICE_RANGE_TEXT)
            .max(CONCIERGE_MAX_PRICE, CONCIERGE_PRICE_RANGE_TEXT),
        maxPrice: Yup.number()
            .typeError('Price is required')
            .min(CONCIERGE_MIN_PRICE, CONCIERGE_PRICE_RANGE_TEXT)
            .max(CONCIERGE_MAX_PRICE, CONCIERGE_PRICE_RANGE_TEXT)
            .test('min<=max concierge prices', 'The maximum price must be higher or equal to the minimum price', 
        // eslint-disable-next-line func-names
        function () {
            return this.parent.minPrice <= this.parent.maxPrice;
        }),
    }),
    catalogDetails: Yup.array().of(Yup.object()
        .nullable()
        .shape({
        externalId: Yup.string().test('External id', 'External id must be a positive integer', (externalId) => {
            return (!externalId ||
                (Number.isInteger(Number(externalId)) && Number(externalId) > 0));
        }),
    })),
});
