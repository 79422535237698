import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
import { Box, Button } from 'src/components/mui';
export const DrawerHeader = styled.div `
  width: 100%;
  height: 56px;
  background: ${colors.secondary};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 0.75px;
  text-align: center;
  color: #2c56dd;
  padding: 19px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DrawerHeaderCta = styled.div `
  position: absolute;
  left: 1rem;
  cursor: pointer;
  z-index: 10;
  height: 24px;
`;
export const DrawerContent = styled(Box) `
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const DrawerFooter = styled(Box) `
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.lightGrey};
  padding: 24px;
  text-align: center;
`;
export const ButtonsWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  button {
    width: 100%;
  }

  ${theme.breakpoints.up('md')} {
    flex-direction: row;

    button {
      width: auto;
    }
  }
`;
export const StyledButton = styled(Button) `
  min-width: 144px;
  height: 48px;
  font-size: 1rem;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
`;
export const ContentWrapper = styled(Box) `
  padding: 25px 16px 0;
  margin: 0;

  h2 {
    margin: 0;
  }

  &.fullSpace {
    padding: 25px;
  }
`;
export const FormRow = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.563rem;
`;
export const LunchTitle = styled.h2 `
  font-size: 24px;
  font-weight: bold;
  line-height: 1.08;
  letter-spacing: -0.5px;
  color: ${colors.default};
`;
export const LunchSubheaderWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 20px 0 30px;
`;
export const LunchSubheader = styled.span `
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.3px;
  margin: 0;
  color: ${colors.default};
`;
export const LunchInfo = styled.span `
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.08px;
`;
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
export const Title = styled.h1 `
  margin: 0;
  padding: 0;
  font-size: 24px;

  ${theme.breakpoints.up('md')} {
    margin: 8px 8px;
    font-size: 30px;
  }
`;
export const Content = styled.section `
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0 0;

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 0;
  }
`;
export const Divider = styled.div `
  display: none;

  ${theme.breakpoints.up('md')} {
    display: flex;
    height: 230px;
    width: 1px;
    background-color: ${colors.GRAY_DE};
  }
`;
export const ContentInner = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  &.center {
    justify-content: center;
  }
`;
