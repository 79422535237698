import { toEnum } from 'src/models/enums';
export const FleetVisitStatus = toEnum({
    NEW: 'NEW',
    OPEN: 'OPEN',
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    IN_PROGRESS: 'IN_PROGRESS',
    CANCELED: 'CANCELED',
    COMPLETED: 'COMPLETED',
    CLOSED: 'CLOSED',
});
export const MpiStatus = toEnum({
    PENDING: 'PENDING',
    NOT_PERFORMED: 'NOT_PERFORMED',
    PERFORMED: 'PERFORMED',
    COMPLETED: 'COMPLETED',
});
