import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { uniq, isNull } from 'lodash';
import { ButtonBase, Checkbox, Collapse, Grid, Typography, useMediaQuery, } from 'src/components/mui';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from 'src/components/mui/icons';
import { Tooltip } from 'src/components/tooltips';
import ReferenceNumLinkChip from 'src/AdminApp/components/repairs/ReferenceNumLinkChip';
import PayerChipsList from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/PayerChipsList';
import { CalculatedTotalCost, QuotedPod, TotalPod, } from 'src/AdminApp/components/repairs/statement/styledComponents';
import { WarningIcon } from 'src/AdminApp/containers/payments/PaymentModule/PaymentsTable/styledComponents';
import TagChips from 'src/AdminApp/containers/requests/TagChips';
import { selectWorkOrderItemResponsiblePartiesOfCustomRepair, selectWorkOrderItemResponsiblePartiesOfDiagnosis, selectWorkOrderItemResponsiblePartiesOfRepair, selectWorkOrderItemResponsiblePartyAuthorizations, } from 'src/AdminApp/modules/requests/selectors';
import SimpleChip from 'src/components/SimpleChip';
import useAdminUser from 'src/hooks/useAdminUser';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { colors, theme } from 'src/styles/theme';
import { formatTimestamp } from 'src/utils/formatter';
import styled from 'styled-components';
import { ResponsiblePartyType } from '../statement/Repairs/ResponsiblePartyType/utils';
const RepairsTableHeaderStyled = styled(Grid) `
  padding-bottom: 12px;
  border-bottom: 1px solid ${colors.lightGrey};
  display: flex;
`;
const RepairDropdownStyled = styled.div `
  border-bottom: 1px solid ${colors.lightGrey};
`;
const MobileHeaderPod = styled(Grid) `
  text-align: right;
  padding-right: 32px;
`;
const RepairTitle = styled(Typography) `
  align-items: center;
  display: flex;
  flex-direction: row;
`;
const RepairLabel = styled.div `
  max-width: 80%;
`;
export const RepairDropdownButtons = styled(Grid) `
  margin-top: 24px;
`;
export const RecommendedRepairDropdownButtons = styled(Grid) `
  margin: 0;
`;
const RepairDropdownSummary = styled(ButtonBase) `
  width: 100%;
  text-align: left;
  display: block;
  padding: 24px 0;
  position: relative;

  ${QuotedPod} {
    border-right: 1px dashed ${colors.GRAY_AA};
    margin-top: -10px;
    margin-bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 23px;
  }
`;
const RepairDropdownContent = styled.div `
  padding: 4px 32px 48px;
  ${theme.breakpoints.down('sm')} {
    padding-left: 0;
  }
`;
const Initial = styled.span `
  color: ${colors.middleGrey};
`;
const ChipsSection = styled.section `
  align-content: start;
  margin-top: 4px;
  display: flex;

  > *:not(:last-child) {
    margin-right: 5px;
  }
`;
export const RepairsTableHeader = ({ isRecommendedHeader = false, }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (<RepairsTableHeaderStyled container style={{ justifyContent: isMobile ? 'space-between' : 'inherit' }}>
      <Grid item xs={6}>
        {isRecommendedHeader ? (<Typography variant="subtitle1">Repairs</Typography>) : (<Typography variant="subtitle1">Services</Typography>)}
      </Grid>
      {isMobile ? (<MobileHeaderPod item xs={3}>
          <Typography variant="subtitle1">
            <Initial>Initial</Initial> / Total
          </Typography>
        </MobileHeaderPod>) : (<>
          <QuotedPod item xs={3}>
            <Typography variant="subtitle1">System Quoted</Typography>
          </QuotedPod>
          <TotalPod item xs={3}>
            <Typography variant="subtitle1">Total</Typography>
          </TotalPod>
        </>)}
    </RepairsTableHeaderStyled>);
};
const DropdownIcon = styled(KeyboardArrowDownIcon) `
  position: absolute;
  right: 0;
  top: 24px;
  color: ${colors.lightGrey};
  transform: rotate(${(props) => (props.theme.opened ? '180deg' : '0deg')});
  transition: 0.3s ease transform;
`;
const isTheOnlyOneResponsiblePartyTypeIsConsumer = (workOrderItemResponsibleParties) => {
    if (workOrderItemResponsibleParties) {
        const uniqueResponsiblePartyTypesNames = uniq(workOrderItemResponsibleParties.map((x) => { var _a, _b; return (_b = (_a = x.responsibleParty) === null || _a === void 0 ? void 0 : _a.responsiblePartyType) === null || _b === void 0 ? void 0 : _b.name; }));
        return (uniqueResponsiblePartyTypesNames.length === 1 &&
            uniqueResponsiblePartyTypesNames[0] === ResponsiblePartyType.CUSTOMER);
    }
};
export const RepairDropdown = ({ hideChips = false, name = '', readOnlyChips = true, repairId, diagnosisId, suggestedRepairId, totalCost = 0, quoted, invoice, sublet, originReferenceNum, shouldDisplayAuditChip, isCreatedByCustomer, children, isOpen, workOrderItemResponsibleParties: preloadedWorkOrderItemResponsibleParties, style = {}, isRecommendedRepair = false, isRejectedRecommendedRepair = false, isScheduledRecommendedRepair = false, suggestedRepairStatus, isRecommendedRepairSelected = false, onSelectRecommendedRepair, recommendedRepairId, recommendedRepairScheduledRefNumber, fleetFixedPrice = false, created, createdBy = '', modified, modifiedBy = '', tags, isEditable, }) => {
    var _a, _b, _c, _d, _e, _f;
    const [opened, setOpened] = useState(isOpen);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { referenceNum } = usePageReferenceNum();
    const diagnosisWorkOrderItemResponsiblePartyAuthorizations = useSelector(selectWorkOrderItemResponsiblePartyAuthorizations(referenceNum));
    const workOrderItemResponsiblePartyAuthorization = diagnosisWorkOrderItemResponsiblePartyAuthorizations === null || diagnosisWorkOrderItemResponsiblePartyAuthorizations === void 0 ? void 0 : diagnosisWorkOrderItemResponsiblePartyAuthorizations.find((auth) => {
        var _a, _b;
        return (repairId && ((_a = auth.suggestedRepairIds) === null || _a === void 0 ? void 0 : _a.includes(repairId))) ||
            (suggestedRepairId &&
                ((_b = auth.suggestedRepairIds) === null || _b === void 0 ? void 0 : _b.includes(suggestedRepairId)));
    });
    let workOrderItemResponsibleParties = [];
    let customRepairId = 0;
    if (preloadedWorkOrderItemResponsibleParties) {
        workOrderItemResponsibleParties = preloadedWorkOrderItemResponsibleParties;
    }
    else if (!invoice) {
        if (suggestedRepairId) {
            customRepairId = repairId;
            workOrderItemResponsibleParties = useSelector(selectWorkOrderItemResponsiblePartiesOfCustomRepair(referenceNum, customRepairId));
        }
        else if (repairId) {
            workOrderItemResponsibleParties = useSelector(selectWorkOrderItemResponsiblePartiesOfRepair(referenceNum, repairId));
        }
        else if (diagnosisId) {
            workOrderItemResponsibleParties = useSelector(selectWorkOrderItemResponsiblePartiesOfDiagnosis(referenceNum, diagnosisId));
        }
    }
    else if (suggestedRepairId) {
        customRepairId = repairId;
        workOrderItemResponsibleParties =
            (_b = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _a === void 0 ? void 0 : _a.customRequests) === null || _b === void 0 ? void 0 : _b.filter((x) => x.id === customRepairId)[0].workOrderItemResponsibleParties;
    }
    else if (repairId) {
        workOrderItemResponsibleParties =
            (_d = (_c = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _c === void 0 ? void 0 : _c.knownRepairs) === null || _d === void 0 ? void 0 : _d.filter((x) => x.id === repairId)[0].workOrderItemResponsibleParties;
    }
    else if (diagnosisId) {
        workOrderItemResponsibleParties =
            (_f = (_e = invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices) === null || _e === void 0 ? void 0 : _e.diagnoses) === null || _f === void 0 ? void 0 : _f.filter((x) => x.id === diagnosisId)[0].workOrderItemResponsibleParties;
    }
    const hasOnlyOneResponsiblePartyType = isTheOnlyOneResponsiblePartyTypeIsConsumer(workOrderItemResponsibleParties !== null && workOrderItemResponsibleParties !== void 0 ? workOrderItemResponsibleParties : []);
    const getAuditInfo = `Added: ${formatTimestamp(created)} • ${useAdminUser({
        user: createdBy,
    })} \n Modified: ${formatTimestamp(modified)} • ${useAdminUser({
        user: modifiedBy,
    })}`;
    return (<RepairDropdownStyled style={style}>
      <RepairDropdownSummary onClick={() => setOpened((v) => !v)}>
        <Grid container>
          <Grid item xs>
            <RepairTitle variant="subtitle2">
              {isRecommendedRepair && (<Grid style={{ width: '32px' }}>
                  <Checkbox color="primary" checked={isRecommendedRepairSelected} onChange={() => onSelectRecommendedRepair(recommendedRepairId)} onClick={(e) => e.stopPropagation()} name={name} style={{ padding: '0px' }}/>
                </Grid>)}
              <RepairLabel>{name}&nbsp;</RepairLabel>
              {suggestedRepairStatus && (<Tooltip title={suggestedRepairStatus}>
                  <WarningIcon style={{
                color: theme.palette.warning.main,
                verticalAlign: 'sub',
                cursor: 'pointer',
            }}/>
                </Tooltip>)}
            </RepairTitle>
            <ChipsSection>
              {isRejectedRecommendedRepair && (<SimpleChip $bgColor={colors.red} $textColor={colors.white} label="REJECTED" className="rejectedChip"/>)}
              {shouldDisplayAuditChip && (<Tooltip title={getAuditInfo}>
                  <div style={{ paddingLeft: '5px' }}>
                    <SimpleChip $bgColor={colors.deepDarkGrey} $textColor={colors.white} label={isCreatedByCustomer ? 'Customer Added' : 'Admin Added'} className="repairAddedByChip"/>
                  </div>
                </Tooltip>)}
              {sublet && (<SimpleChip $bgColor={colors.warning} $textColor={colors.white} label="Sublet" className="subletChip"/>)}

              {tags && <TagChips tags={tags}/>}
              {fleetFixedPrice && (<SimpleChip $bgColor={colors.ORANGE_FF} label="Fixed Pricing"/>)}

              {originReferenceNum && (<ReferenceNumLinkChip referenceNum={originReferenceNum}/>)}
              {isScheduledRecommendedRepair && (<ReferenceNumLinkChip referenceNum={recommendedRepairScheduledRefNumber}/>)}
            </ChipsSection>
          </Grid>
          <DropdownIcon theme={{ opened }}/>
          {isMobile ? (<TotalPod item xs={3} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
              <div>
                <Initial>
                  <Typography component="span" variant="body2">
                    {quoted}
                  </Typography>
                </Initial>
              </div>
              <div>
                <Typography variant="body2">
                  {isNull(totalCost) ? (<span>Pending</span>) : (<CalculatedTotalCost values={totalCost}/>)}
                </Typography>
              </div>
            </TotalPod>) : (<>
              {quoted && (<QuotedPod item xs={3}>
                  <Typography variant="body2" component="span">
                    {quoted}
                  </Typography>
                </QuotedPod>)}
              <TotalPod item xs={3}>
                <Typography variant="body2" style={{ alignSelf: 'center' }}>
                  {isNull(totalCost) ? (<span>Pending</span>) : (<CalculatedTotalCost values={totalCost}/>)}
                </Typography>
              </TotalPod>
            </>)}
        </Grid>
        {!hideChips && !hasOnlyOneResponsiblePartyType && (<PayerChipsList inTitle responsibleParties={workOrderItemResponsibleParties !== null && workOrderItemResponsibleParties !== void 0 ? workOrderItemResponsibleParties : []} readOnly={readOnlyChips || !isEditable} repairId={suggestedRepairId || repairId} customRepairId={customRepairId} diagnosisId={diagnosisId} repairName={name} totalCost={totalCost} authorization={workOrderItemResponsiblePartyAuthorization}/>)}
      </RepairDropdownSummary>
      <Collapse in={opened}>
        {!hideChips && hasOnlyOneResponsiblePartyType && (<PayerChipsList responsibleParties={workOrderItemResponsibleParties !== null && workOrderItemResponsibleParties !== void 0 ? workOrderItemResponsibleParties : []} readOnly={readOnlyChips || !isEditable} repairId={suggestedRepairId || repairId} customRepairId={customRepairId} diagnosisId={diagnosisId} repairName={name} totalCost={totalCost} authorization={workOrderItemResponsiblePartyAuthorization}/>)}
        <RepairDropdownContent>{children}</RepairDropdownContent>
      </Collapse>
    </RepairDropdownStyled>);
};
export default RepairDropdown;
