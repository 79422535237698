import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMenuItems } from 'src/AdminApp/components/form/utils';
import CarCard from 'src/AdminApp/containers/vehicle/CarModule/CarCard';
import DecodePlateAlert from 'src/AdminApp/containers/vehicle/CarModule/DecodePlateAlert';
import { MobileEditFieldDrawer } from 'src/AdminApp/containers/vehicle/CarModule/MobileEditFieldDrawer';
import { States } from 'src/AdminApp/models/enums';
import { CAR_MSG, PLATE_NUMBER_LENGTH, } from 'src/AdminApp/modules/api/constants';
import { getVehicleDescriptionByPlate } from 'src/AdminApp/modules/autoselection/actions';
import { selectVehiclesByPlate } from 'src/AdminApp/modules/autoselection/selectors';
import { Button, Grid, InputAdornment, TextField, Typography, } from 'src/components/mui';
const LicensePlateFieldEdit = (props) => {
    const dispatch = useDispatch();
    const [plateError, setPlateError] = useState();
    const [showDecodePlateAlert, setShowDecodePlateAlert] = useState(false);
    const [isLicensePlateOnFocus, setIsLicensePlateOnFocus] = useState(false);
    const [plateNumber, setPlateNumber] = useState(props.values.plateNumber);
    const [plateNumberState, setPlateNumberState] = useState(props.values.plateNumberState);
    const [disableSave, setDisableSave] = useState(false);
    useEffect(() => {
        setDisableSave(!!plateNumberState !== !!plateNumber);
        if (!!plateNumberState && (plateNumber === null || plateNumber === void 0 ? void 0 : plateNumber.length) >= PLATE_NUMBER_LENGTH) {
            onDecodePlate();
        }
    }, [plateNumber, plateNumberState]); // eslint-disable-line
    const vehiclesByPlate = useSelector(selectVehiclesByPlate);
    const decodedVehicle = vehiclesByPlate[plateNumber];
    const onDecodePlate = () => {
        dispatch(getVehicleDescriptionByPlate({
            plate: plateNumber,
            state: plateNumberState,
        })).then((result) => {
            var _a;
            if ((_a = result.payload.status) !== null && _a !== void 0 ? _a : null === 404) {
                setPlateError(CAR_MSG.PLATE_NOT_EXIST);
            }
        });
    };
    return (<MobileEditFieldDrawer {...props} label="License Plate" onSave={() => props.onSave({ plateNumber, plateNumberState })} disableSave={disableSave}>
      <Grid item xs={8}>
        <TextField onFocus={() => setIsLicensePlateOnFocus(true)} onBlur={() => setIsLicensePlateOnFocus(false)} variant="outlined" value={plateNumber} onChange={({ target }) => {
            setPlateError(undefined);
            setPlateNumber(target.value);
        }} label="License Plate" type="text" name="plateNumber" fullWidth InputProps={{
            endAdornment: !!plateNumber && (<InputAdornment position="end">
                <Button onClick={(plateNumber === null || plateNumber === void 0 ? void 0 : plateNumber.length) >= PLATE_NUMBER_LENGTH &&
                    !!plateNumberState
                    ? onDecodePlate
                    : () => setShowDecodePlateAlert(true)} color={(plateNumber === null || plateNumber === void 0 ? void 0 : plateNumber.length) >= PLATE_NUMBER_LENGTH &&
                    !!plateNumberState
                    ? 'primary'
                    : 'secondary'}>
                  Decode
                </Button>
              </InputAdornment>),
        }} helperText={plateError ||
            ((plateNumber === null || plateNumber === void 0 ? void 0 : plateNumber.length) < PLATE_NUMBER_LENGTH &&
                CAR_MSG.PLATE_CHARACTER_LENGTH)} error={!!plateError}/>
      </Grid>

      <Grid item xs={4}>
        <TextField variant="outlined" value={plateNumberState} onChange={({ target }) => setPlateNumberState(target.value)} label="State" type="text" name="plateNumberState" select fullWidth error={!plateNumberState && !!plateNumber && !isLicensePlateOnFocus}>
          {createMenuItems(States, true)}
        </TextField>
      </Grid>
      {decodedVehicle && (<Grid item xs={12}>
          <Typography variant="subtitle1">
            Plate Decoded Vehicle Details
          </Typography>
          <CarCard car={decodedVehicle} actions={<Button onClick={() => {
                    props.onSave(Object.assign(Object.assign(Object.assign({}, props.values), decodedVehicle), { trim: decodedVehicle.style, plateNumber,
                        plateNumberState }));
                }} color="primary">
                Select Car
              </Button>}/>
        </Grid>)}
      <DecodePlateAlert open={showDecodePlateAlert} onClick={() => setShowDecodePlateAlert(false)} autoHideDuration={3000}/>
    </MobileEditFieldDrawer>);
};
export default LicensePlateFieldEdit;
