var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthorizedPricingUser } from 'src/modules/auth/selectors';
import ProtectedRoute from 'src/containers/utils/ProtectedRoute';
import PageLayout from 'src/containers/layout/PageLayout';
import NotAuthorized from 'src/components/layout/NotAuthorized';
import { NotFound } from 'src/pages';
import Components from 'src/PricingApp/pages/Components';
import Repairs from './pages/Repairs';
import AddComponent from './pages/AddComponent';
import AddRepair from './pages/AddRepair';
import EditRepair from './pages/EditRepair';
import EditComponent from './pages/EditComponent';
import Manufacturers from './pages/Manufacturers';
import Utils from './pages/Utils';
import CSVUploader from './pages/CSVUploader';
import Measurements from './pages/Measurements';
const DefaultPage = (props) => (<Redirect to={{ pathname: '/pricing/repairs', state: { from: props.location } }}/>);
const PricingComponentsRedirect = ({ match }) => (<Redirect to={`/pricing/components/${match.params.componentId}/edit`}/>);
const PricingRepairsRedirect = ({ match }) => (<Redirect to={`/pricing/repairs/${match.params.repairId}/edit`}/>);
const PricingApp = (_a) => {
    var props = __rest(_a, []);
    const isPricingUser = useSelector(selectAuthorizedPricingUser);
    return (<PageLayout {...props}>
      {isPricingUser ? (<Switch>
          <ProtectedRoute exact path="/pricing/" component={DefaultPage}/>
          <ProtectedRoute exact path="/pricing/repairs" component={Repairs}/>
          <ProtectedRoute exact path="/pricing/repairs/add" component={AddRepair}/>
          <ProtectedRoute exact path="/pricing/repairs/:repairId" component={PricingRepairsRedirect}/>
          <ProtectedRoute exact path="/pricing/repairs/:repairId/edit" component={EditRepair}/>
          <ProtectedRoute exact path="/pricing/components" component={Components}/>
          <ProtectedRoute exact path="/pricing/components/add" component={AddComponent}/>
          <ProtectedRoute exact path="/pricing/components/:componentId" component={PricingComponentsRedirect}/>
          <ProtectedRoute exact path="/pricing/components/:componentId/edit" component={EditComponent}/>

          <ProtectedRoute exact path="/pricing/packages" component={NotFound}/>
          <ProtectedRoute exact path="/pricing/utils" component={Utils}/>
          <ProtectedRoute exact path="/pricing/csv-uploader" component={CSVUploader}/>
          <ProtectedRoute exact path="/pricing/manufacturers" component={Manufacturers}/>
          <ProtectedRoute exact path="/pricing/measurements" component={Measurements}/>
          <Route component={NotFound}/>
        </Switch>) : (<NotAuthorized targetRole="Pricing_User or Pricing_Admin"/>)}
    </PageLayout>);
};
export default PricingApp;
