import CONFIG from 'src/config';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createDefaultActionCreators from 'src/utils/createActions';
export const ActionCreators = {
    GET_LOCATIONS: createDefaultActionCreators('GET_LOCATIONS'),
    GET_LOCATION_BY_ID: createDefaultActionCreators('GET_LOCATION_BY_ID'),
    GET_LOCATIONS_HUBS: createDefaultActionCreators('GET_LOCATIONS_HUBS', [
        'CLEAR',
    ]),
    UPDATE_LOCATION: createDefaultActionCreators('UPDATE_LOCATION'),
    SEARCH_LOCATION: createDefaultActionCreators('SEARCH_LOCATION'),
    ADD_LOCATION_PARENT: createDefaultActionCreators('ADD_LOCATION_PARENT'),
    REMOVE_LOCATION_PARENT: createDefaultActionCreators('REMOVE_LOCATION_PARENT'),
};
export const getLocations = ({ pageSize, pageIndex, sort = ['name,asc'], name, parentId, action = ActionCreators.GET_LOCATIONS, locationTypes, }) => {
    const params = Object.assign(Object.assign(Object.assign({}, (!!name && { name })), (!!parentId && { parentId })), (!!locationTypes && { locationTypes }));
    return createAPIAction({
        types: fillApiTypes(action, {
            success: {
                meta: () => ({ pageSize, pageIndex, sort }),
            },
        }),
        endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/locations`,
        method: 'GET',
        params,
    });
};
export const getLocationsHubs = ({ name, parentId, }) => {
    return getLocations({
        name,
        parentId,
        locationTypes: ['HUB'],
        action: ActionCreators.GET_LOCATIONS_HUBS,
    });
};
export const getLocationById = (id) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_LOCATION_BY_ID),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/locations/${id}`,
    method: 'GET',
});
export const clearLocationsHubs = ActionCreators.GET_LOCATIONS_HUBS.CLEAR;
export const searchLocationsHubs = ({ name, parentId, }) => {
    return getLocations({
        name,
        parentId,
        locationTypes: ['HUB'],
        action: ActionCreators.SEARCH_LOCATION,
    });
};
export const updateLocation = (location) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_LOCATION),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/locations/${location.id}`,
    method: 'PUT',
    body: location,
});
export const addLocationParent = (locationId, parentLocationId) => createAPIAction({
    types: fillApiTypes(ActionCreators.ADD_LOCATION_PARENT),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/locations/${locationId}/parents`,
    method: 'POST',
    body: {
        parentLocationId,
    },
});
export const removeLocationParent = (locationId, parentId) => createAPIAction({
    types: fillApiTypes(ActionCreators.REMOVE_LOCATION_PARENT),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/locations/${locationId}/parents/${parentId}`,
    method: 'DELETE',
});
