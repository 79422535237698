var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const rowStyles = css `
  border-bottom: 1px solid ${colors.lightGrey};
  font-size: 14px;
  font-weight: bold;
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:last-of-type {
    border-bottom: none;
  }

  svg {
    position: relative;
    top: -2px;
  }
`;
const ButtonStyles = styled(Button) `
  ${rowStyles};

  color: ${({ $color }) => colors[$color]};
`;
const SimpleRowButton = (_a) => {
    var { children, color = 'default' } = _a, rest = __rest(_a, ["children", "color"]);
    return (<ButtonStyles $color={color} {...rest}>
      {children}
    </ButtonStyles>);
};
export default SimpleRowButton;
