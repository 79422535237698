import React from 'react';
import { Container, Grid } from 'src/components/mui';
import ConsumersDashboard from 'src/AdminApp/containers/consumers/ConsumersDashboard';
const Consumers = () => {
    const optional = '';
    return (<Container maxWidth="xl">
      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item md={12}>
          {optional}
        </Grid>
      </Grid>
      <ConsumersDashboard />
    </Container>);
};
export default Consumers;
