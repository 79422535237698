import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from 'src/components/mui';
import { selectAuthorizedUserEdit } from 'src/modules/auth/selectors';
import { getAdminUsers, } from 'src/AdminApp/modules/users/actions';
import AdminSignupDashboard from 'src/AdminApp/containers/admin/AdminSignupDashboard';
import GenerateMfaForm from 'src/AdminApp/components/mfa/GenerateMfaForm';
import PageTitle from 'src/containers/layout/PageTitle';
import AddAndEditUser from 'src/AdminApp/containers/admin/AddAndEditUser';
import { selectAdminUsers, selectAdminUsersDashboardPagination, selectAdminUsersDashboardParams, } from 'src/AdminApp/modules/users/selectors';
const AdminSignup = () => {
    const dispatch = useDispatch();
    const adminUsers = useSelector(selectAdminUsers);
    const canEdit = useSelector(selectAuthorizedUserEdit);
    const pagination = useSelector(selectAdminUsersDashboardPagination);
    const params = useSelector(selectAdminUsersDashboardParams);
    const [openModal, setOpenModal] = useState(false);
    const [editUser, setEditUser] = useState();
    const handleEditUser = (id) => {
        const user = adminUsers.find((user) => user.id === id);
        setEditUser(user);
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setEditUser(undefined);
        setOpenModal(false);
    };
    const handleSearch = (filters) => {
        var _a, _b;
        const filtersRequest = filters !== null && filters !== void 0 ? filters : Object.assign({}, params.filters);
        dispatch(getAdminUsers(Object.assign({ pageIndex: 0, pageSize: (_a = pagination.pageSize) !== null && _a !== void 0 ? _a : 20, sort: (_b = pagination.sort) !== null && _b !== void 0 ? _b : ['id', 'desc'] }, filtersRequest)));
    };
    return canEdit ? (<div>
      <PageTitle slug="Admin Signup"/>
      <Grid container spacing={2}>
        <Grid container direction="row" style={{ justifyContent: 'flex-end' }}>
          <AddAndEditUser editUser={editUser} editMode={!!editUser} openModal={openModal} setOpenModal={setOpenModal} handleCloseModal={handleCloseModal} refreshList={handleSearch}/>
        </Grid>
        <Grid item xs={12}>
          <AdminSignupDashboard onSearch={handleSearch} onEdit={handleEditUser} adminUsers={adminUsers}/>
        </Grid>
        <Grid item xs={12}>
          <GenerateMfaForm />
        </Grid>
      </Grid>
    </div>) : (<div>
      ERROR - Not Authorized: You must have the "User_Editor" role to view this
      page
    </div>);
};
export default AdminSignup;
