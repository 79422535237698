import React from 'react';
import moment from 'moment';
import Loader from 'src/AdminApp/containers/requests/StateTracker/Loader';
import AvailabilityListItem from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/AvailabilityListItem';
import { MONTH_AND_DAY_FORMAT, WEEK_DAY_FORMAT } from 'src/timeConstants';
import { DayLabel, DaySection, ListWrapper, NoSlots, } from './AvailabilityList.styles';
import useAvailabilityList from './useAvailabilityList';
const AvailabilityList = () => {
    var _a;
    const { availability, isFetching, selectedTimeSlot, handleTimeSlotClick } = useAvailabilityList();
    if (isFetching) {
        return <Loader />;
    }
    if (Object.keys((_a = availability.slots) !== null && _a !== void 0 ? _a : {}).length > 0) {
        return (<ListWrapper>
        {Object.keys(availability.slots).map((date) => {
                var _a;
                const mDate = moment(date);
                const weekDay = mDate.calendar('', {
                    sameDay: '[Today]',
                    nextDay: WEEK_DAY_FORMAT,
                    nextWeek: WEEK_DAY_FORMAT,
                    sameElse: WEEK_DAY_FORMAT,
                });
                const slots = ((_a = availability.slots) !== null && _a !== void 0 ? _a : {})[date];
                return (<DaySection key={date}>
              <DayLabel>
                <span className="date">
                  {mDate.format(MONTH_AND_DAY_FORMAT)}
                </span>{' '}
                {weekDay}
              </DayLabel>
              <ul>
                {Object.keys(slots).map((time) => (<AvailabilityListItem key={time} date={date} time={time} timeSlot={slots[time]} onClick={handleTimeSlotClick} isThisTimeSlotSelected={(selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.date) === date &&
                            (selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.time) === time} hasAnyTimeSlotSelected={!!selectedTimeSlot}/>))}
              </ul>
            </DaySection>);
            })}
      </ListWrapper>);
    }
    return (<NoSlots>
      No time slots available. Fill in inputs to add a new visit.
    </NoSlots>);
};
export default AvailabilityList;
