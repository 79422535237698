import moment from 'moment';
import React from 'react';
import useFleetScheduling from 'src/AdminApp/containers/scheduling/FleetScheduling/useFleetScheduling';
import { CircularProgress } from 'src/components/mui';
import VisitCapacityWarningModal from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal';
import { WARNINGS } from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal/WarningMessages';
import LaborCapacityBar from 'src/FleetVisitsApp/pages/VisitView/LaborCapacityBar';
import { convertMinutesToHours } from 'src/FleetVisitsApp/pages/VisitView/VisitInfo/utils';
import { MONTH_AND_DAY_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
import { Container, Header, LaborCapacityWrapper, LoaderWrapper, SelectButton, Subheader, VisitItem, } from './FleetScheduling.styles';
const FleetScheduling = () => {
    const { isMobile, loading, fleetVisits, selectedVisitId, handleSelect, showCapacityWarning, handleCapacityWarningClose, } = useFleetScheduling();
    return (<Container>
      <Header variant={isMobile ? 'h6' : 'h5'}>Select a Visit</Header>
      <Subheader variant="body1">
        These visits were confirmed for this fleet
      </Subheader>

      <div>
        {loading && (<LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>)}
        {!loading && !(fleetVisits === null || fleetVisits === void 0 ? void 0 : fleetVisits.length) && <p>No visits found</p>}
        {fleetVisits.map((visit) => (<VisitItem key={visit.id}>
            <section>
              <span>
                <b>
                  {moment(visit.actualStartLocal).format(MONTH_AND_DAY_FORMAT)}{' '}
                  •{' '}
                </b>
                {moment(visit.actualStartLocal).format(TIME_12H_FORMAT)} -{' '}
                {moment(visit.actualEndLocal).format(TIME_12H_FORMAT)}
                {!moment(visit.actualStartLocal).isSame(visit.actualEndLocal, 'day') && ' (Next day)'}
              </span>
              <LaborCapacityWrapper>
                <LaborCapacityBar totalScheduledHours={convertMinutesToHours(visit.totalScheduledLaborMinutes)} totalAvailableHours={convertMinutesToHours(visit.totalAvailableLaborMinutes)} totalRemainingHours={convertMinutesToHours(visit.totalRemainingLaborMinutes)}/>
              </LaborCapacityWrapper>
            </section>

            <SelectButton variant="contained" color="primary" disabled={!!selectedVisitId && selectedVisitId !== visit.id} onClick={handleSelect(visit)}>
              {selectedVisitId === visit.id ? 'Change' : 'Select'}
            </SelectButton>
          </VisitItem>))}
      </div>

      <VisitCapacityWarningModal open={showCapacityWarning} onClose={handleCapacityWarningClose} warningMessage={WARNINGS.ADD_A_SERVICE}/>
    </Container>);
};
export default FleetScheduling;
