var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getVisit, updateVisit, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { DATE_TIME_FORMAT } from 'src/timeConstants';
const useVisitReschedule = () => {
    const dispatch = useDispatch();
    const prepareFleetResourcesForSubmit = (resources) => {
        let techsReduced = [];
        let vansReduced = [];
        const techs = resources.map((t) => ({
            deliveryVanId: t.deliveryVanId,
            type: 'MECHANIC',
            technicianId: t.technicianId,
        }));
        techsReduced = Array.from(new Set(techs.map((tech) => tech.technicianId))).map((technicianId) => {
            return techs.find((tech) => tech.technicianId === technicianId);
        });
        const vans = resources.map((t) => ({
            deliveryVanId: t.deliveryVanId,
            type: 'VAN',
            skeduloResourceId: t.deliveryVanSkeduloResourceId,
        }));
        vansReduced = Array.from(new Set(vans.map((van) => van.deliveryVanId))).map((deliveryVanId) => {
            return vans.find((van) => van.deliveryVanId === deliveryVanId);
        });
        return [...techsReduced, ...vansReduced];
    };
    const prepareDescriptionForSubmit = (stations, fleets, expectedRevenue) => {
        const visitFor = stations.length > 0
            ? stations.join(', ')
            : fleets.map((f) => f.fleetName)[0];
        const expectedRevenueDesc = expectedRevenue
            ? `\nExpected Revenue: $${expectedRevenue}`
            : '';
        return `Visit for ${visitFor}${expectedRevenueDesc}`;
    };
    const handleUpdateVisit = (job, newStartTime, handleResetSelectors) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        let updateResponse = null;
        const response = yield dispatch(getVisit({ refNum: job.repairJob.referenceNum }));
        if (!response || response.error) {
            handleResetSelectors(false, true);
            return;
        }
        const start = moment(response.payload.actualStartLocal, DATE_TIME_FORMAT);
        const end = moment(response.payload.actualEndLocal, DATE_TIME_FORMAT);
        const diffMin = end.diff(start, 'minutes');
        const newStart = moment(newStartTime, DATE_TIME_FORMAT);
        const newEnd = moment(moment(newStartTime).add(diffMin, 'minutes').format(DATE_TIME_FORMAT));
        const techniciansWithVans = response.payload.resources
            .filter((r) => r.type === 'MECHANIC')
            .map((r) => (Object.assign(Object.assign({}, r), { selectedVan: response.payload.resources.filter((re) => re.type === 'VAN' &&
                (re.deliveryVanId === r.deliveryVanId ||
                    re.skeduloResourceId === r.deliveryVanId))[0] })));
        const technicians = techniciansWithVans
            .filter((t) => t.selectedVan)
            .map((r) => ({
            name: `${r.technicianName} (${r.selectedVan.deliveryVanName})`,
            deliveryVanName: r.selectedVan.deliveryVanName,
            deliveryVanId: r.deliveryVanId,
            technicianId: r.technicianId,
            deliveryVanSkeduloResourceId: r.selectedVan.skeduloResourceId,
        }));
        const submitPayload = {
            body: {
                address: response.payload.address,
                stations: response.payload.stationName
                    ? [response.payload.stationName]
                    : [],
                fleets: response.payload.fleets.map((f) => ({
                    fleetId: f.fleetId,
                    fleetName: f.fleetName,
                    dailyOpsContactName: f.dailyOpsContactName,
                    dailyOpsContactPhone: f.dailyOpsContactPhone,
                    dailyOpsContactEmail: f.dailyOpsContactEmail,
                })),
                resources: prepareFleetResourcesForSubmit(technicians),
                actualStartLocal: newStart.format(DATE_TIME_FORMAT),
                actualEndLocal: newEnd.format(DATE_TIME_FORMAT),
                timezone: (_a = response.payload.address) === null || _a === void 0 ? void 0 : _a.timezone,
                expectedRevenue: response.payload.expectedRevenue,
                description: prepareDescriptionForSubmit(response.payload.stationName ? [response.payload.stationName] : [], response.payload.fleets, response.payload.expectedRevenue),
                resetParts: false,
            },
        };
        updateResponse = yield dispatch(updateVisit(Object.assign({ refNum: response.payload.refNum }, submitPayload)));
        if (!updateResponse || (updateResponse === null || updateResponse === void 0 ? void 0 : updateResponse.error)) {
            handleResetSelectors(false, true);
            return;
        }
        setTimeout(() => {
            handleResetSelectors(true, true);
        }, 1000);
    });
    return {
        handleUpdateVisit,
    };
};
export default useVisitReschedule;
