import styled from 'styled-components';
import { Grid } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
export const RepairsWrapper = styled.div `
  padding: 0 22px 34px 18px;
`;
export const Subtotal = styled.div `
  padding-top: 24px;
  padding-bottom: 50px;
  border-top: 1px dashed ${colors.lightGrey};
  display: flex;
  align-items: flex-end;
`;
export const ActionItemsWrapper = styled.div `
  background-color: ${colors.whiteSmokeGrey};
  padding: 18px 22px;
  border-top: 2px solid ${colors.GRAY_EB};
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  flex-wrap: wrap;
  & button {
    ${({ isMobile }) => isMobile ? ' margin-bottom: 10px;' : 'margin-left: 10px;'}
    margin-left: 12px;
  }

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
  }
`;
export const CopyToClipboardWrapper = styled.div `
  display: flex;
  flex: 1;
  align-items: center;

  ${theme.breakpoints.up('md')} {
    position: relative;
    left: -26px;
  }
`;
export const TextButton = styled.span `
  font-weight: bold;
  cursor: pointer;
  color: ${colors.lightBlue};
`;
export const GridMainLine = styled(Grid) `
  margin-top: 32px;
  margin-right: 32px;
  display: flex;
  align-items: center;
`;
export const InfoGrid = styled(Grid) `
  padding: 0 0 10px 32px;
  margin-bottom: 33px;
  color: ${colors.grey};

  & div {
    margin-bottom: 16px;
  }
`;
export const ComponentsGrid = styled(Grid) `
  padding-right: 20px;
  border-left: 2px solid ${colors.GRAY_EB};
  padding-bottom: 5px;
  margin-bottom: 15px;
`;
export const PriceWrapper = styled.div `
  color: ${colors.darkGrey};
  font-weight: bold;
  text-align: ${(props) => (props.right ? 'right' : 'left')};
  & button:hover {
    color: ${colors.red};
  }
`;
export const AlteredLabel = styled.div `
  font-size: 1rem;
  padding-left: 32px;

  & div {
    margin: 0 10px;
  }
`;
export const ImagePreview = styled.img `
  max-width: 100%;
`;
export const ChevronGrid = styled(Grid) `
  display: flex;
  align-items: center;

  & button {
    height: fit-content;
  }
`;
