import styled from 'styled-components';
import { FONT_FAMILY } from 'src/styles/theme';
export const FunnelSearchContainer = styled.section `
  width: 100%;
  display: flex;
  margin-bottom: 15px;

  .searchField {
    font-family: ${FONT_FAMILY};
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.3px;
    flex-grow: 3;
    margin-right: 38px;

    > div {
      border-radius: 24px;
    }
  }

  .addFunnelBtn {
    font-family: ${FONT_FAMILY};
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    padding: 0 30px;
  }
`;
export const FunnelPaginationContainer = styled.section `
  padding: 20px 0 50px;
`;
export const AlertContainer = styled.section `
  margin-bottom: 35px;
`;
