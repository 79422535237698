var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMeasurements, selectRepairMeasurements, } from 'src/PricingApp/modules/repairs/selectors';
import { addRepairMeasurement, getMeasurements, getMeasurementsByRepair, removeRepairMeasurement, } from 'src/PricingApp/modules/repairs/actions';
import { differenceBy } from 'lodash';
const useMeasurementsAutocomplete = ({ repairId }) => {
    const dispatch = useDispatch();
    const measurements = useSelector(selectMeasurements);
    const selectedMeasurements = useSelector(selectRepairMeasurements);
    useEffect(() => {
        dispatch(getMeasurements());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSelected = (e, value) => __awaiter(void 0, void 0, void 0, function* () {
        const measurement = value;
        const [newMeasurement] = differenceBy(measurement, selectedMeasurements, 'name');
        if (newMeasurement) {
            yield dispatch(addRepairMeasurement(newMeasurement.id, repairId));
            dispatch(getMeasurementsByRepair(repairId));
            return;
        }
        const [delMeasurement] = differenceBy(selectedMeasurements, measurement, 'name');
        if (delMeasurement) {
            yield dispatch(removeRepairMeasurement(delMeasurement.id, repairId));
            dispatch(getMeasurementsByRepair(repairId));
        }
    });
    return { measurements, selectedMeasurements, handleSelected };
};
export default useMeasurementsAutocomplete;
