import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    lunchBreakId: undefined,
    loading: false,
    lunchBreak: undefined,
};
const getLunchBreakSuccessReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { loading: false, lunchBreak: action.payload });
};
const getLunchBreakFetchReducer = (state) => {
    return Object.assign(Object.assign({}, state), { loading: true });
};
const patchLunchBreakFailureReducer = (state) => {
    return Object.assign(Object.assign({}, state), { loading: false });
};
const patchLunchBreakFetchReducer = (state) => {
    return Object.assign(Object.assign({}, state), { loading: true });
};
const getLunchBreakFailureReducer = (state) => {
    return Object.assign(Object.assign({}, state), { loading: false });
};
const lunchBreakReducers = handleActions({
    [ActionCreators.GET_LUNCH_BREAK.FETCH.type]: getLunchBreakFetchReducer,
    [ActionCreators.GET_LUNCH_BREAK.FAILURE.type]: getLunchBreakFailureReducer,
    [ActionCreators.GET_LUNCH_BREAK.SUCCESS.type]: getLunchBreakSuccessReducer,
    [ActionCreators.PATCH_LUNCH_BREAK.SUCCESS.type]: getLunchBreakSuccessReducer,
    [ActionCreators.PATCH_LUNCH_BREAK.FETCH.type]: patchLunchBreakFetchReducer,
    [ActionCreators.PATCH_LUNCH_BREAK.FAILURE.type]: patchLunchBreakFailureReducer,
}, DEFAULT_STATE);
export default lunchBreakReducers;
