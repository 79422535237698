import { isEmpty, startCase } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import AddConsumerRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddConsumerRODrawer/AddConsumerRODrawer';
import AddFleetRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddFleetRODrawer';
import ConfirmPartsFitmentDrawer from 'src/AdminApp/containers/repairs/ConfirmPartsFitmentDrawer/ConfirmPartsFitmentDrawer';
import RecordMeasurementsDrawer from 'src/AdminApp/containers/repairs/RecordMeasurementsDrawer';
import CantFinishDrawer from 'src/AdminApp/containers/requests/CantFinish/CantFinishStart/CantFinishDrawer';
import CantFinishDrawerScreenForMeasurements from 'src/AdminApp/containers/requests/CantFinish/CantFinishStart/CantFinishDrawerScreenForMeasurements';
import AdditionalCancelationReasons from 'src/AdminApp/containers/requests/StateTracker/drawers/AdditionalCancelationReasons';
import AuthorizationScript from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts';
import CancelRequestDrawer from 'src/AdminApp/containers/requests/StateTracker/drawers/CancelRequestDrawer';
import ConfirmUnlockRoModal from 'src/AdminApp/containers/requests/StateTracker/drawers/ConfirmUnlockRoModal';
import CreateCoreDrawer from 'src/AdminApp/containers/requests/StateTracker/drawers/CreateCoreDrawer';
import NotifyUserModal from 'src/AdminApp/containers/requests/StateTracker/drawers/NotifyUserModal';
import RepairOrderResolutionDrawer from 'src/AdminApp/containers/requests/StateTracker/drawers/RepairOrderResolutionDrawer';
import VisitSelectTechnicianDrawer from 'src/AdminApp/containers/requests/StateTracker/drawers/VisitSelectTechnicianDrawer';
import Loader from 'src/AdminApp/containers/requests/StateTracker/Loader';
import CarDrawer from 'src/AdminApp/containers/vehicle/CarModule/CarDrawer';
import TechnicianWorkflowState from 'src/AdminApp/models/enums/TechnicianWorkflowState';
import { SimpleModal } from 'src/components/layout';
import { ActionsBlock } from 'src/containers/layout/ActionsBlock';
import Notes3CDrawer from '../../../Notes/AddNotesDrawer/Notes3C/Notes3CDrawer';
import RequestUnlockReasons from '../../drawers/RequestUnlockReasons';
import ClosedStatesLabel from '../ClosedStatusLabel';
import { formatState, isRepairRequestClosed } from '../utils';
import useRequestCTAsContainer from './useRequestCTAsContainer';
/*
  TODO

  1. Use openedModal for all elements
  2. Split use hook to several ones
*/
const RequestCTAsContainer = ({ canEdit, referenceNum, state, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { request, requestData, handleCloseRecordMeasurement, hideOpenedModal, workOrderId, requestPreAuthorization, openCancellationReasonsPending, openedModal, isFleet, isLockedRoEditor, vehicle, isMobile, workflow, workOrder, completeTask, handleCloseVisitTechnicianDrawer, isSelectVisitTechnicianOpen, isCarDetailsOpen, setIsCarDetailsOpen, getMilesOut, openModalNotifyUser, handleSubmitRepairOrderSolution, openRescheduleCanceledQuickRo, closeCancellationReasons, handleROCancelConfirmClose, handleRemoveROConfirm, openROCancelConfirm, handleCloseRecordMeasurementDrawer, openedQuickROType, isPartOrdered, activeAppointment, preSelectedVan, preSelectedMechanics, getHeaderText, cancellationValues, handleUnlockRo, handleSubmitConfirmUnlock, requestLock, actions, currentTaskWorkFlow, handleAuthorizeQuote, handleCloseConfirmUnlock, primaryInvoice, closeModal, isLoading, handleCloseNotifyUserModal, diagnosis, openConfirmUnlockRoModal, openAuthorizationScript, isArrivalTimerExpired, expiredArrivalTimerModalOpen, handleCloseExpiredArrivalTimerModal, handleAddExtraMinutesToArrivalTimer, close3CDrawer, is3CDrawerOpen, requestUntilJobComplete, openScheduleAllRecommendRepairs, openCantFinishScreenForMeasurements, requestCoreCharges, completeJob, vanId, openConfirmPartsFitmentDrawer, handleCloseConfirmPartsFitmentDrawer, repairFitments, } = useRequestCTAsContainer({
        referenceNum,
        state,
    });
    const checkIfItsCantFinish = () => {
        return (openedModal === 'cantFinishRecommendedRepairs' ||
            openedModal === 'cantFinishScreenForMeasurements');
    };
    const renderSchedulingDrawers = () => {
        if (isFleet) {
            return (<AddFleetRODrawer isRescheduleCancelled={openedQuickROType === 'rescheduleCanceled'} isRescheduleConfirmed={openedQuickROType === 'reschedulePendingOrConfirmed'} isSchedule={openedQuickROType === 'schedule'} isPartOrdered={isPartOrdered} previousDate={activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.appointmentDate} previousVanId={preSelectedVan ? preSelectedVan[0] : undefined} headerText={openedModal === 'quickRo' ? getHeaderText : undefined} open={openedModal === 'quickRo' ||
                    openedModal === 'cantFinishRecommendedRepairs'} isFollowUp={openedModal === 'cantFinishRecommendedRepairs'} onClose={closeModal} referenceNum={referenceNum} cancellationValues={cancellationValues}/>);
        }
        return (<AddConsumerRODrawer isRescheduleCancelled={openedQuickROType === 'rescheduleCanceled'} isRescheduleConfirmed={openedQuickROType === 'reschedulePendingOrConfirmed'} isSchedule={openedQuickROType === 'schedule'} isPartOrdered={isPartOrdered} previousDate={activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.appointmentDate} previousVanId={preSelectedVan ? preSelectedVan[0] : undefined} headerText={openedModal === 'quickRo' ? getHeaderText : undefined} open={openedModal === 'quickRo' ||
                openedModal === 'cantFinishRecommendedRepairs'} onClose={closeModal} referenceNum={referenceNum} context={`Repair Request / ${startCase(openedQuickROType !== null && openedQuickROType !== void 0 ? openedQuickROType : '')} Appointment`} isFollowup preSelectedMechanics={preSelectedMechanics !== null && preSelectedMechanics !== void 0 ? preSelectedMechanics : []} cancellationValues={cancellationValues}/>);
    };
    if (!canEdit || isEmpty(request) || !diagnosis) {
        return null;
    }
    if (isRepairRequestClosed(state) && !isMobile && request) {
        if ((((_a = workflow === null || workflow === void 0 ? void 0 : workflow.currentTask) === null || _a === void 0 ? void 0 : _a.state) ===
            TechnicianWorkflowState.PAYMENT_COLLECTED &&
            (primaryInvoice === null || primaryInvoice === void 0 ? void 0 : primaryInvoice.balanceOwed) !== 0) ||
            ((_b = workflow === null || workflow === void 0 ? void 0 : workflow.currentTask) === null || _b === void 0 ? void 0 : _b.state) ===
                TechnicianWorkflowState.GET_MEASUREMENTS ||
            ((_c = workflow === null || workflow === void 0 ? void 0 : workflow.currentTask) === null || _c === void 0 ? void 0 : _c.state) ===
                TechnicianWorkflowState.CONCERN_CAUSE_CORRECTION ||
            checkIfItsCantFinish()) {
            return (<>
          {isLoading && <Loader />}

          <ActionsBlock actions={actions} childAtStart={<ClosedStatesLabel closedRequest={request}/>}/>
          {renderSchedulingDrawers()}
          <RecordMeasurementsDrawer onClose={handleCloseRecordMeasurement}/>

          <ConfirmUnlockRoModal open={openConfirmUnlockRoModal} onClose={handleCloseConfirmUnlock} onSubmit={handleSubmitConfirmUnlock}/>
          <RequestUnlockReasons open={openedModal === 'requestUnlockReasons'} onSubmit={handleUnlockRo} onCancel={hideOpenedModal}/>
          <Notes3CDrawer open={is3CDrawerOpen} repairRequestId={requestData === null || requestData === void 0 ? void 0 : requestData.request.id} onClose={close3CDrawer} loadNotes completeTask={completeTask} currentTaskWorkFlow={currentTaskWorkFlow}/>
          <CantFinishDrawerScreenForMeasurements open={openedModal === 'cantFinishScreenForMeasurements'} onClose={hideOpenedModal} referenceNum={referenceNum}/>
        </>);
        }
        if (isLockedRoEditor && (requestLock.locked || requestLock.id !== null)) {
            return (<>
          {isLoading && <Loader />}

          <ActionsBlock actions={actions} childAtStart={<ClosedStatesLabel closedRequest={request}/>}/>
          <ConfirmUnlockRoModal open={openConfirmUnlockRoModal} onClose={handleCloseConfirmUnlock} onSubmit={handleSubmitConfirmUnlock}/>
          <RequestUnlockReasons open={openedModal === 'requestUnlockReasons'} onSubmit={handleUnlockRo} onCancel={hideOpenedModal}/>
        </>);
        }
        const el = document.querySelector('.CTA-render-portal');
        if (el) {
            return ReactDOM.createPortal(<>
          <ClosedStatesLabel closedRequest={request}/>
          <RecordMeasurementsDrawer onClose={handleCloseRecordMeasurement}/>
        </>, el);
        }
        return null;
    }
    return (<>
      {isLoading && <Loader />}
      <ActionsBlock actions={actions}>
        {isRepairRequestClosed(state) && request ? (<ClosedStatesLabel closedRequest={request}/>) : (formatState(state))}
      </ActionsBlock>
      {renderSchedulingDrawers()}
      <RecordMeasurementsDrawer onClose={handleCloseRecordMeasurementDrawer}/>
      <CancelRequestDrawer open={openedModal === 'cancelRequest'} onClose={closeModal}/>
      <SimpleModal open={openROCancelConfirm} title="Are you sure you want to remove this RO? Its status will reset and progress will be lost." simpleTwoButtonRowProps={{
            submitText: 'Yes',
            onSubmit: handleRemoveROConfirm,
            onCancel: handleROCancelConfirmClose,
            cancelText: 'No',
            submitIsDestructive: true,
        }}/>
      <AdditionalCancelationReasons consumerId={(_d = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) === null || _d === void 0 ? void 0 : _d.consumerId} isFleet={isFleet} open={['cancelAppointment', 'cancellationReasons'].includes(openedModal)} referenceNum={referenceNum} repairRequestId={requestData === null || requestData === void 0 ? void 0 : requestData.request.id} visitReferenceNum={(_e = requestData === null || requestData === void 0 ? void 0 : requestData.request.visit) === null || _e === void 0 ? void 0 : _e.refNum} workOrderId={(_f = requestData === null || requestData === void 0 ? void 0 : requestData.request.workOrder) === null || _f === void 0 ? void 0 : _f.id} submit={openRescheduleCanceledQuickRo} onClose={closeCancellationReasons} expiredTimer={isArrivalTimerExpired}/>
      <RepairOrderResolutionDrawer open={openedModal === 'closeRequest'} onClose={closeModal} referenceNum={referenceNum} workOrderId={(_g = workOrder === null || workOrder === void 0 ? void 0 : workOrder.id) !== null && _g !== void 0 ? _g : ''} onSubmit={handleSubmitRepairOrderSolution}/>
      <NotifyUserModal open={openModalNotifyUser} onClose={handleCloseNotifyUserModal} currentTaskWorkFlow={currentTaskWorkFlow} completeTask={completeTask}/>
      <CarDrawer isMilesOutConfirm open={isCarDetailsOpen} vehicle={vehicle} onClose={() => setIsCarDetailsOpen(false)} onSubmit={getMilesOut}/>
      <VisitSelectTechnicianDrawer visitResources={(_j = (_h = request === null || request === void 0 ? void 0 : request.visit) === null || _h === void 0 ? void 0 : _h.resources) !== null && _j !== void 0 ? _j : []} open={isSelectVisitTechnicianOpen} onClose={handleCloseVisitTechnicianDrawer}/>
      <AuthorizationScript open={openedModal === 'authorizationScript'} onClose={hideOpenedModal} currentTask={(_k = currentTaskWorkFlow === null || currentTaskWorkFlow === void 0 ? void 0 : currentTaskWorkFlow.state) !== null && _k !== void 0 ? _k : ''} onCancelReschedule={openCancellationReasonsPending} consumerName={(_l = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) === null || _l === void 0 ? void 0 : _l.name} consumerId={(_m = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) === null || _m === void 0 ? void 0 : _m.consumerId} completeTask={handleAuthorizeQuote} preAuthorizationRequested={requestPreAuthorization} state={state} workOrderId={workOrderId}/>

      <SimpleModal open={expiredArrivalTimerModalOpen && !openedModal} title="Have you made contact with the customer?" simpleTwoButtonRowProps={{
            submitText: 'Yes',
            onSubmit: () => {
                handleCloseExpiredArrivalTimerModal();
                handleAddExtraMinutesToArrivalTimer();
                openAuthorizationScript();
            },
            onCancel: () => {
                handleCloseExpiredArrivalTimerModal();
                openCancellationReasonsPending();
            },
            cancelText: 'No',
        }}/>
      <Notes3CDrawer open={is3CDrawerOpen} repairRequestId={requestData === null || requestData === void 0 ? void 0 : requestData.request.id} onClose={close3CDrawer} loadNotes completeTask={completeTask} currentTaskWorkFlow={currentTaskWorkFlow}/>
      <CantFinishDrawer open={openedModal === 'cantFinishReasons'} onClose={hideOpenedModal} referenceNum={referenceNum} requestUntilJobComplete={requestUntilJobComplete} openScheduleAllRecommendRepairs={openScheduleAllRecommendRepairs} openCantFinishScreenForMeasurements={openCantFinishScreenForMeasurements} workOrderId={workOrderId}/>
      <CantFinishDrawerScreenForMeasurements open={openedModal === 'cantFinishScreenForMeasurements'} onClose={hideOpenedModal} referenceNum={referenceNum}/>

      <CreateCoreDrawer open={openedModal === 'createCore'} onClose={() => {
            hideOpenedModal();
        }} onFinish={() => {
            completeJob();
            hideOpenedModal();
        }} requestCoreCharges={requestCoreCharges} referenceNum={referenceNum} vanId={vanId}/>
      {repairFitments && (<ConfirmPartsFitmentDrawer open={openConfirmPartsFitmentDrawer} onClose={handleCloseConfirmPartsFitmentDrawer} repairFitments={repairFitments}/>)}
    </>);
};
export default RequestCTAsContainer;
