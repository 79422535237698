import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Grid } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
import { HasParentSelectForm } from 'src/AdminApp/components/form/Statuses';
import FleetSearchSelect from 'src/components/form/FleetSearchSelect';
import FilterButtons from 'src/components/dashboard/DashboardFilter/FilterButtons';
import FilterBlock from 'src/components/dashboard/DashboardFilter/FilterBlock';
const DashboardFilters = ({ onSearch, onReset, onClear, searchFleets, }) => {
    const [parentInput, setParentInput] = useState('');
    const resetFields = () => {
        setParentInput('');
    };
    const handleReset = () => {
        resetFields();
        onReset();
    };
    const handleClear = () => {
        resetFields();
        onClear();
    };
    return (<Formik initialValues={{
            contactName: '',
            email: '',
            hasParent: '',
            name: '',
            parentId: '',
            phone: '',
        }} onSubmit={(data, { setSubmitting }) => {
            const values = Object.assign(Object.assign({}, data), { parentInput });
            onSearch(values);
            setSubmitting(false);
        }}>
      {({ isSubmitting, resetForm, setFieldValue, values }) => (<Form>
          <FilterBlock>
            <Grid item xs={12} md={3}>
              <TextField label="Contact Name" name="contactName" fullWidth value={values.contactName}/>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Email" name="email" fullWidth value={values.email}/>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Phone" name="phone" fullWidth value={values.phone}/>
            </Grid>
            <Grid item xs={12} md={3}>
              <FleetSearchSelect onSelect={(data) => {
                setFieldValue('parentId', data.id);
                setParentInput(data.label);
            }} onChange={(value) => {
                setFieldValue('parentId', null);
                setParentInput(value);
            }} onSearch={searchFleets} inputValue={parentInput} label="Parent"/>
            </Grid>
            <Grid item xs={12} md={3}>
              <HasParentSelectForm shrink={!!values.hasParent} fullWidth name="hasParent" label="Has Parent"/>
            </Grid>
          </FilterBlock>
          <FilterButtons onClear={() => {
                handleClear();
                resetForm();
            }} onReset={() => {
                handleReset();
                resetForm();
            }} queryLabelText="Search Query" isSubmitting={isSubmitting}/>
        </Form>)}
    </Formik>);
};
export default DashboardFilters;
