var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-plusplus */
/* eslint-disable no-alert */
import { BarcodeReader } from 'dynamsoft-javascript-barcode';
import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid } from 'src/components/mui';
import styled from 'styled-components';
import Loader from 'src/FleetVisitsApp/pages/FleetVisits/components/Loader';
import VideoDecode from '../../components/VideoDecode';
import '../VideoProcessor.css';
import { useNetSuiteApiClient } from '../../useNetSuiteApiClient';
const s = styled;
const PocV3 = () => {
    var _a, _b;
    const { id } = useParams();
    const [active, setActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [counts, setCounts] = useState({ scanned: 0, matched: 0 });
    const [canSubmit, setCanSubmit] = useState(false);
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const [receiveItemObj, setReceiveItemObj] = useState();
    const netSuiteApiClient = useNetSuiteApiClient();
    const history = useHistory();
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            setIsLoading(true);
            try {
                const po = yield netSuiteApiClient.fetchPurchaseOrdersById(id, true);
                const invItems = [];
                (_b = (_a = po === null || po === void 0 ? void 0 : po.item) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.forEach((row) => {
                    const invItem = {
                        item: {
                            id: row.item.id,
                        },
                        orderDoc: {
                            id: po.id,
                        },
                        orderLine: row.line,
                        quantity: 0,
                        quantityRemaining: row.quantity,
                        itemName: row.item.refName,
                        description: row.description,
                    };
                    invItems.push(invItem);
                });
                setReceiveItemObj({
                    item: {
                        items: invItems,
                    },
                });
                setPurchaseOrder(po);
            }
            catch (ex) {
                console.error(ex.message);
            }
            setIsLoading(false);
        });
        // Simulate an API call (replace with your actual API endpoint)
        fetchData().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const initializeScanner = () => __awaiter(void 0, void 0, void 0, function* () {
        console.info(BarcodeReader.license);
        if (!BarcodeReader.license) {
            BarcodeReader.license =
                'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyMjM2NTI0LVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAyMjM2NTI0Iiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2Rscy5keW5hbXNvZnRvbmxpbmUuY29tIiwiY2hlY2tDb2RlIjotMTU3ODEyNjA3fQ==';
            BarcodeReader.engineResourcePath =
                'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.21/dist/';
        }
        try {
            yield BarcodeReader.loadWasm();
        }
        catch (ex) {
            if (ex.message.indexOf('network connection error')) {
                const customMsg = 'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
                console.error(customMsg);
            }
            throw ex;
        }
    });
    const processResult = useCallback((result) => {
        var _a, _b, _c, _d, _e, _f;
        setCounts((prevCounts) => (Object.assign(Object.assign({}, prevCounts), { scanned: prevCounts.scanned + 1 })));
        if (receiveItemObj && ((_b = (_a = receiveItemObj === null || receiveItemObj === void 0 ? void 0 : receiveItemObj.item) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.length)) {
            let found = false;
            for (let index = 0; (_e = index < ((_d = (_c = receiveItemObj === null || receiveItemObj === void 0 ? void 0 : receiveItemObj.item) === null || _c === void 0 ? void 0 : _c.items) === null || _d === void 0 ? void 0 : _d.length)) !== null && _e !== void 0 ? _e : 0; index++) {
                const invItem = (_f = receiveItemObj === null || receiveItemObj === void 0 ? void 0 : receiveItemObj.item) === null || _f === void 0 ? void 0 : _f.items[index];
                if (invItem.itemName === result.barcodeText) {
                    if (invItem.quantity === invItem.quantityRemaining) {
                        window.alert(`Qty reached for ${invItem.itemName}`);
                        break;
                    }
                    window.alert(`${invItem.itemName} scanned correctly`);
                    found = true;
                    setCanSubmit(true);
                    invItem.quantity++;
                    setCounts((prevCounts) => (Object.assign(Object.assign({}, prevCounts), { matched: prevCounts.matched + 1 })));
                }
            }
            if (found)
                setReceiveItemObj(Object.assign({}, receiveItemObj));
        }
    }, [setCounts, receiveItemObj, setReceiveItemObj, setCanSubmit]);
    useEffect(() => {
        initializeScanner();
    }, []);
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!canSubmit)
            window.alert('You must receive at least one item');
        setIsLoading(true);
        try {
            yield netSuiteApiClient.receivePO(id, receiveItemObj);
            window.alert('PO received');
            history.push('/scan-poc/v3');
        }
        catch (e) {
            window.alert(`Error: ${e.message}`);
        }
        setIsLoading(false);
    });
    return (<div className="container">
      <ScanContainer>
        {!active && (<img alt="special" src="img/png/camera.png" style={{ width: '350px', height: '350px' }}/>)}
        {active && <VideoDecode processResultCb={processResult}/>}
      </ScanContainer>
      {isLoading && <Loader />}
      <article>
        <h2>{purchaseOrder.referenceNum}</h2>
        <Grid container style={{ justifyContent: 'flex-end' }}>
          <div>Scanned Items: {counts.scanned}</div>
        </Grid>
        <Grid container style={{ justifyContent: 'flex-end' }}>
          <div>Matched {counts.matched}</div>
        </Grid>
        <Grid container style={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <GridItem xs={12} sm={12}>
            <Grid container spacing={2} direction="row" style={{ justifyContent: 'flex-end' }}>
              <GridItem item>
                <Button onClick={() => setActive(!active)} variant="contained" color="secondary">
                  {!active ? 'Scan Parts' : 'Close'}
                </Button>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        {purchaseOrder && (<div>
            <h2>{purchaseOrder === null || purchaseOrder === void 0 ? void 0 : purchaseOrder.tranId}</h2>
          </div>)}
        {(_b = (_a = receiveItemObj === null || receiveItemObj === void 0 ? void 0 : receiveItemObj.item) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.map((rp, index) => {
            var _a, _b;
            return (<div key={index}>
            <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <GridItem item xl>
                {(_a = rp.itemName) !== null && _a !== void 0 ? _a : ''} - {rp.description}
              </GridItem>
              <GridItem item xs={2}>
                <span style={{
                    fontWeight: 'bold',
                    color: rp.quantity > 0 && rp.quantity === rp.quantityRemaining
                        ? '#28B421'
                        : '#B12A27',
                }}>
                  {(_b = rp.quantity) !== null && _b !== void 0 ? _b : 0} of {rp.quantityRemaining}
                </span>
              </GridItem>
            </Grid>
          </div>);
        })}
      </article>
      <Grid container style={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <GridItem xs={12} sm={12}>
          <Grid container spacing={2} direction="row" style={{ justifyContent: 'flex-end' }}>
            <GridItem item>
              <Button onClick={() => submit()} variant="contained" color="primary">
                RECEIVE
              </Button>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <br />
      <div>POC V3</div>
    </div>);
};
export default PocV3;
const ScanContainer = styled('div') `
  width: 100%;
  height: 350px;
  text-align: center;
`;
const GridItem = s(Grid).attrs({ item: true }) `
  padding: 5px;
`;
