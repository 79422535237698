import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Button } from '@mui/material';
import React from 'react';
import { HelpList } from 'src/AdminApp/containers/requests/Quote/PartsHelp/PartsHelpDrawer.styles';
import { PartsHelpTabs } from 'src/AdminApp/containers/requests/Quote/PartsHelp/PartsHelpTabs';
const PartsHelpList = ({ goToTab, disabledTabs }) => {
    const listOptions = [
        {
            label: 'Additional Components Required',
            tab: PartsHelpTabs.ADDITIONAL_COMPONENTS_REQUIRED_SELECT_REPAIR,
        },
        {
            label: 'Additional Repairs Required',
            tab: PartsHelpTabs.ADDITIONAL_REPAIRS_REQUIRED,
        },
        {
            label: 'Customer Provided Parts',
            tab: PartsHelpTabs.CUSTOMER_PROVIDED_PARTS,
        },
        {
            label: 'Faulty Part Received',
            tab: PartsHelpTabs.FAULTY_PART_RECEIVED_SELECT_REPAIR,
        },
        {
            label: 'Pricing Quote',
            tab: PartsHelpTabs.PRICING_QUOTE,
        },
        {
            label: 'Urgent Pending Parts',
            tab: PartsHelpTabs.URGENT_PENDING_PARTS,
        },
        {
            label: 'Wrong Part',
            tab: PartsHelpTabs.WRONG_PART_SELECT_REPAIR,
        },
    ];
    return (<HelpList>
      {listOptions.map((option, index) => (<li key={index}>
          <Button onClick={() => goToTab(option.tab)} variant="text" endIcon={<ArrowForwardIosRoundedIcon />} disabled={disabledTabs.includes(option.tab)}>
            {option.label}
          </Button>
        </li>))}
    </HelpList>);
};
export default PartsHelpList;
