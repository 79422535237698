var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { getQuoteDiagnosis, removeWorkOrderResponsiblePartyAuthorization, } from 'src/AdminApp/modules/requests/actions';
import useConfirmModal from 'src/hooks/useConfirmModal';
const useAuthBtn = ({ workOrderResponsiblePartyAuthorization, workOrderId, referenceNum, invoice, repairRequestId, }) => {
    const dispatch = useDispatch();
    const [openAuthModal, setOpenAuthModal] = useState(false);
    const handleRemove = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        yield dispatch(removeWorkOrderResponsiblePartyAuthorization(referenceNum !== null && referenceNum !== void 0 ? referenceNum : '', (_a = workOrderResponsiblePartyAuthorization === null || workOrderResponsiblePartyAuthorization === void 0 ? void 0 : workOrderResponsiblePartyAuthorization.id) !== null && _a !== void 0 ? _a : ''));
        if (invoice) {
            dispatch(getInvoicesByWorkOrderId({ workOrderId }));
        }
        else {
            dispatch(getQuoteDiagnosis(referenceNum !== null && referenceNum !== void 0 ? referenceNum : '', repairRequestId));
        }
    });
    const { handleOpenModal, modal } = useConfirmModal({
        closeConfirmCb: handleRemove,
        confirmBtn: 'Remove',
        discardBtn: 'Keep',
        title: 'Removing this authorization will unauthorize all related repairs',
    });
    const handleOpenAuthModal = () => setOpenAuthModal(true);
    const handleCloseAuthModal = () => setOpenAuthModal(false);
    return {
        handleOpenModal,
        handleOpenAuthModal,
        handleCloseAuthModal,
        openAuthModal,
        modal,
    };
};
export default useAuthBtn;
