import React, { Fragment } from 'react';
import { Button } from 'src/components/mui';
import { formatPhone } from 'src/utils/formatter';
import { Edit as EditIcon, FileCopy as FileCopyIcon, } from 'src/components/mui/icons';
import { ResourcesSubTitle } from './VisitInfo.styles';
const Contacts = ({ visit, dailyOpsContacts, dailyOpsContactsExpanded, isMobile, handleSelectDOC, handleCopyPhoneClick, handleViewContacts, handleCollapseContacts, }) => {
    var _a, _b;
    const fleetCustomers = visit.fleets.map((f) => (<a key={f.fleetId} href={`/admin/fleets/${f.fleetId}`} target="_blank" rel="noreferrer">
      {f.fleetName}
    </a>));
    return (<>
      {visit.stationName && <strong>{visit.stationName}: </strong>}
      {fleetCustomers.reduce((acc, curr, index) => {
            return index === 0 ? [curr] : [...acc, ', ', curr];
        }, [])}

      <br />
      <br />

      {((_a = dailyOpsContacts === null || dailyOpsContacts === void 0 ? void 0 : dailyOpsContacts.length) !== null && _a !== void 0 ? _a : 0) >= 1 && (<ResourcesSubTitle>Daily Ops Contacts:</ResourcesSubTitle>)}

      {((_b = dailyOpsContacts === null || dailyOpsContacts === void 0 ? void 0 : dailyOpsContacts.length) !== null && _b !== void 0 ? _b : 0) >= 1 &&
            (dailyOpsContacts === null || dailyOpsContacts === void 0 ? void 0 : dailyOpsContacts.map((contact, index) => {
                var _a, _b, _c, _d;
                if (index > 0 && !dailyOpsContactsExpanded)
                    return null;
                return (<Fragment key={contact.fleetId}>
              <div>
                <strong>
                  {(_b = (_a = visit === null || visit === void 0 ? void 0 : visit.fleets) === null || _a === void 0 ? void 0 : _a.find((f) => f.fleetId === contact.fleetId)) === null || _b === void 0 ? void 0 : _b.fleetName}
                </strong>
                {isMobile && (<Button startIcon={<EditIcon />} onClick={() => handleSelectDOC(contact)} style={{
                            float: 'right',
                            verticalAlign: 'top',
                        }}/>)}
              </div>
              <div>Name: {contact.dailyOpsContactName}</div>
              <div>
                {isMobile ? (<>
                    <span>
                      Phone:{' '}
                      <a href={`tel:${contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactPhone}`}>
                        {formatPhone((_c = contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactPhone) !== null && _c !== void 0 ? _c : '')}
                      </a>
                    </span>
                    <Button startIcon={<FileCopyIcon />} onClick={() => { var _a; return handleCopyPhoneClick((_a = contact.dailyOpsContactPhone) !== null && _a !== void 0 ? _a : ''); }}/>
                  </>) : (<span>
                    Phone: {formatPhone((_d = contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactPhone) !== null && _d !== void 0 ? _d : '')}
                  </span>)}
              </div>
              <div>
                {isMobile ? (<div style={{
                            marginBottom: dailyOpsContactsExpanded ? '36px' : '0px',
                            overflow: 'scroll',
                        }}>
                    <span>Email: </span>
                    <a href={`mailto:${contact.dailyOpsContactEmail}`}>
                      {contact.dailyOpsContactEmail}
                    </a>
                  </div>) : (<span> Email: {contact.dailyOpsContactEmail}</span>)}
              </div>
            </Fragment>);
            }))}

      {dailyOpsContacts && !dailyOpsContactsExpanded && (<Button color="primary" variant="text" size="small" onClick={handleViewContacts}>
          View{' '}
          {(dailyOpsContacts === null || dailyOpsContacts === void 0 ? void 0 : dailyOpsContacts.length) > 1
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                    `+${(dailyOpsContacts === null || dailyOpsContacts === void 0 ? void 0 : dailyOpsContacts.length) - 1} More`
                : ''}
        </Button>)}
      {isMobile && dailyOpsContacts && dailyOpsContactsExpanded && (<Button color="primary" variant="text" size="small" onClick={handleCollapseContacts}>
          Show less
        </Button>)}
    </>);
};
export default Contacts;
