import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, Grid, } from 'src/components/mui';
import { FONT_FAMILY, colors, theme } from 'src/styles/theme';
export const NavLinksContainer = styled(Grid) ``;
export const NavLinkSection = styled(Grid) `
  padding-top: 25px;
  &:first-of-type {
    padding-top: 15px;
  }
  text-align: left;

  ${theme.breakpoints.down('sm')} {
    padding-top: 25px;
    margin-bottom: 35px;
    border-bottom: solid 1px ${colors.border};
    &:first-of-type {
      padding-top: 50px;
    }
    &:last-of-type {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  span {
    font-family: ${FONT_FAMILY};
    font-size: 12px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.08px;
    color: ${colors.GRAY_3F};
    white-space: normal;

    ${theme.breakpoints.down('sm')} {
      font-size: 14px;
      line-height: 1.29;
      letter-spacing: -0.3px;
    }
  }

  .parent-section {
    display: flex;
    align-items: center;

    span {
      text-align: center;
      margin-left: 6px;

      ${theme.breakpoints.down('sm')} {
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: -0.3px;
        margin-left: 12px;
      }
    }
  }

  .sub-section {
    padding: 10px 0 24px;
  }

  .item {
    border-radius: 4px;
    background-color: ${colors.white};
    padding: 6px 16px 6px 16px;
    margin: 10px 0 3px;
    transition: 200ms all;
    min-width: 120px;
    font-size: 14px;
    color: ${colors.GRAY_3F};

    ${theme.breakpoints.down('sm')} {
      padding: 4px 16px 8px 36px;
      margin: 2px 0 2px;

      &:hover {
        background-color: ${colors.white} !important;
      }

      &.active {
        background-color: ${colors.white} !important;
        span {
          color: ${colors.brandPink};
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${colors.brandPink};
            top: -3px;
            left: -16px;
            transform: translateY(50%);
          }
        }
      }
    }

    &.active,
    &:hover {
      background-color: ${colors.lightGrey};
    }

    &.accordion {
      padding: 3px 8px 3px 16px;

      ${theme.breakpoints.down('sm')} {
        padding: 3px 8px 16px 0px;
      }
    }

    &.accordion-item {
      ${theme.breakpoints.down('sm')} {
        margin-left: 16px;
      }
    }
  }
`;
export const NavLink = styled(RouterNavLink) `
  span {
    font-family: ${FONT_FAMILY};
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.08px;
    color: ${colors.GRAY_3F};
    white-space: nowrap;
    text-align: left;

    ${theme.breakpoints.down('sm')} {
      font-size: 14px;
    }
  }
  text-decoration: none;
`;
export const Accordion = styled(MuiAccordion) `
  box-shadow: none;
  background: transparent;

  &::before {
    display: none;
  }

  &.Mui-expanded {
    margin: 0;
  }
`;
export const AccordionSummary = styled(MuiAccordionSummary) `
  padding: 0;
  min-height: 0px;
  justify-content: space-between;
  width: 100%;

  ${theme.breakpoints.down('sm')} {
    padding: 4px 0;
  }

  & > * {
    margin: 0 !important;
    padding: 0;
    display: block;
  }

  .MuiAccordionSummary-expandIconWrapper {
    transform-origin: 9px 10px;
  }

  &.Mui-expanded {
    min-height: 0px;

    ${theme.breakpoints.down('sm')} {
      span:has(.expand-icon) {
        position: relative;
        top: 10px;
      }
    }
  }

  ${theme.breakpoints.down('sm')} {
    border-top: 1px solid #00000014;
    .MenuItem--nested,
    .MenuItem--first {
      border-top: none;
    }
    margin-left: 36px;
    margin-right: 24px;
    width: auto;
  }

  div:nth-child(1) {
    width: 100%;
    .MenuItem--nested {
      width: auto;
    }
  }

  div:nth-child(2) {
    position: absolute;
    right: 0;
    top: 14px;
  }

  ${theme.breakpoints.down('sm')} {
    span:has(.expand-icon) {
      position: relative;
      top: -8px;
    }
  }
`;
export const AccordionItemsContainer = styled(Grid) `
  &:last-child {
    margin-bottom: 20px;
  }
`;
