import { Skeleton } from 'src/components/mui';
import styled from 'styled-components';
import { theme, colors } from 'src/styles/theme';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
`;
export const Item = styled(Skeleton) `
  height: 122px;
  width: 100%;
  border-radius: 4px;

  margin: 8px 0;
  border: solid 1px ${colors.GRAY_AA};

  ${theme.breakpoints.up('md')} {
    min-width: 288px;
    max-width: 288px;
    margin: 6px 12px 6px 0;
  }
`;
export const ItemsGroup = styled.div `
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;

  display: flex;
  flex-wrap: wrap;

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
`;
export const FilterItem = styled(Skeleton) `
  height: 48px;
  width: calc(100% + 32px);
  position: relative;
  margin-bottom: 16px;
  display: flex;
  border: solid 1px ${colors.GRAY_AA};

  ${theme.breakpoints.up('md')} {
    border-radius: 24px;
    width: 100%;
    margin-bottom: 56px;
  }
`;
