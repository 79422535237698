var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEmpty } from 'lodash';
import moment from 'moment';
import createAPIAction from 'src/modules/api';
import createAction from 'src/modules/api/utils/createAction';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import { selectCurrentUserId } from 'src/modules/auth/selectors';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { selectCurrentUser, selectAdminUserInfoById } from './selectors';
// FSA ACTIONS
export const ActionCreators = {
    GET_SELF: createDefaultActionCreators('GET_SELF'),
    GET_ADMIN_USER_INFO: createDefaultActionCreators('GET_ADMIN_USER_INFO'),
    GET_ADMIN_USERS: createDefaultActionCreators('GET_ADMIN_USERS'),
    SIGNUP_ADMIN_USER: createDefaultActionCreators('SIGNUP_ADMIN_USER'),
    GET_SERVICE_ADVISORS: createDefaultActionCreators('GET_SERVICE_ADVISORS'),
    GET_TERRITORY_MANAGERS: createDefaultActionCreators('GET_TERRITORY_MANAGERS'),
    GET_TECHNICIANS: createDefaultActionCreators('GET_TECHNICIANS'),
    GET_ENGINEERS: createDefaultActionCreators('GET_ENGINEERS'),
    GET_PORTERS: createDefaultActionCreators('GET_PORTERS'),
    GET_NOT_TECHNICIANS: createDefaultActionCreators('GET_NOT_TECHNICIANS'),
    GET_MANAGERS: createDefaultActionCreators('GET_MANAGERS'),
    GET_TECHNICIAN_USER: createDefaultActionCreators('GET_TECHNICIAN_USER'),
    UPDATE_USER_PROFILE_INFO: createDefaultActionCreators('UPDATE_USER_PROFILE_INFO'),
    UPDATE_USER_PHONE_NUMBER: createDefaultActionCreators('UPDATE_USER_PHONE_NUMBER'),
    UPDATE_USER_EMAIL: createDefaultActionCreators('UPDATE_USER_EMAIL'),
    UPDATE_USER_PROFILE_PICTURE: createDefaultActionCreators('UPDATE_USER_PROFILE_PICTURE'),
    GET_SKILLS: createDefaultActionCreators('GET_SKILLS'),
    UPDATE_USER_SKILLS: createDefaultActionCreators('UPDATE_USER_SKILLS'),
    REMOVE_USER_SKILLS: createDefaultActionCreators('REMOVE_USER_SKILLS'),
    GET_TECHNICIANS_FOR_AUTOCOMPLETE: createDefaultActionCreators('GET_TECHNICIANS_FOR_AUTOCOMPLETE'),
};
export const setUserFirstName = createAction('SET_USER_FIRSTNAME');
export const setUserLastName = createAction('SET_USER_LASTNAME');
export const setUserType = createAction('SET_USER_TYPE');
export const setUserPhoneNumber = createAction('SET_USER_PHONE_NUMBER');
export const setUserSkills = createAction('SET_USER_SKILLS');
export const setUserResourceId = createAction('SET_USER_RESOURCE_ID');
export const setUserEmail = createAction('SET_USER_EMAIL');
export const clearTechniciansForAutocomplete = createAction('CLEAR_TECHNICIANS_FOR_AUTOCOMPLETE');
export const getSelf = () => createAPIAction({
    // GET_SELF
    types: [
        ActionCreators.GET_SELF.FETCH(),
        ActionCreators.GET_SELF.SUCCESS(),
        ActionCreators.GET_SELF.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ADMIN_USER_SELF(),
    method: 'GET',
});
export const getAdminUserInfo = ({ userId }) => (dispatch) => dispatch(createAPIAction({
    types: [
        ActionCreators.GET_ADMIN_USER_INFO.FETCH({
            meta: () => ({ userId }),
        }),
        ActionCreators.GET_ADMIN_USER_INFO.SUCCESS({
            meta: () => ({ userId }),
        }),
        ActionCreators.GET_ADMIN_USER_INFO.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ADMIN_USER_INFO(userId),
    method: 'GET',
}));
export const getAdminUsers = ({ id, createdFrom, createdTo, query, pageSize = 20, pageIndex = 0, sort = ['id,asc'], disabled, }) => {
    const params = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ size: pageSize, page: pageIndex, sort }, (!!id && { id })), (!!query && !isEmpty(query) && { query })), (!!createdFrom && {
        createdFrom: moment(createdFrom).startOf('day').utc().format(),
    })), (!!createdTo && {
        createdTo: moment(createdTo).startOf('day').add(1, 'days').utc().format(),
    })), { includeDisabled: disabled });
    return createAPIAction({
        types: [
            ActionCreators.GET_ADMIN_USERS.FETCH({
                meta: () => ({ sort, params }),
            }),
            ActionCreators.GET_ADMIN_USERS.SUCCESS({
                meta: () => ({ sort, params }),
            }),
            ActionCreators.GET_ADMIN_USERS.FAILURE(),
        ],
        params,
        endpoint: API_URLS.ADMIN.ADMIN_USERS(),
        method: 'GET',
    });
};
export const loadCurrentUser = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const currentUserId = selectCurrentUserId(getState());
    if (!currentUserId)
        return;
    const currentUserData = selectCurrentUser(getState());
    if (!currentUserData || currentUserData.id || currentUserData._isFetching)
        return;
    dispatch(getSelf());
});
export const loadAdminUserInfo = (userId) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    if (!userId)
        return;
    const userInfo = selectAdminUserInfoById(userId)(getState());
    if (!userInfo || userInfo.email)
        return;
    dispatch(getAdminUserInfo({ userId }));
});
export const signupAdminUser = (user, isTech = false) => (dispatch) => dispatch(createAPIAction({
    // SIGNUP_ADMIN_USER
    types: [
        ActionCreators.SIGNUP_ADMIN_USER.FETCH(),
        ActionCreators.SIGNUP_ADMIN_USER.SUCCESS({
            meta: () => (Object.assign({}, user)),
        }),
        ActionCreators.SIGNUP_ADMIN_USER.FAILURE(),
    ],
    endpoint: isTech
        ? API_URLS.ADMIN.USERS_TECHNICIANS()
        : API_URLS.ADMIN.ADMIN_USER_SIGNUP(),
    method: 'POST',
    body: user,
}));
export const updateAdminUser = (user, isTech = false) => (dispatch) => dispatch(createAPIAction({
    // UPDATE_ADMIN_USER
    types: [
        ActionCreators.SIGNUP_ADMIN_USER.FETCH(),
        ActionCreators.SIGNUP_ADMIN_USER.SUCCESS({
            meta: () => (Object.assign({}, user)),
        }),
        ActionCreators.SIGNUP_ADMIN_USER.FAILURE(),
    ],
    endpoint: isTech
        ? API_URLS.ADMIN.USERS_TECHNICIANS(user.id)
        : API_URLS.ADMIN.ADMIN_USER_UPDATE(user.id),
    method: 'PATCH',
    body: user,
}));
export const getTechnicians = (name, queryParams = {}) => {
    const params = Object.assign({ authGroup: 'Technicians', nameContains: name }, queryParams);
    return createAPIAction({
        types: [
            ActionCreators.GET_TECHNICIANS.FETCH(),
            ActionCreators.GET_TECHNICIANS.SUCCESS(),
            ActionCreators.GET_TECHNICIANS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.USERS(),
        method: 'GET',
        params,
    });
};
export const getEngineers = (name) => createAPIAction({
    types: [
        ActionCreators.GET_ENGINEERS.FETCH(),
        ActionCreators.GET_ENGINEERS.SUCCESS(),
        ActionCreators.GET_ENGINEERS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.USERS(),
    method: 'GET',
    params: {
        authGroup: 'Engineering',
        nameContains: name,
    },
});
export const getPorters = (name) => createAPIAction({
    types: [
        ActionCreators.GET_PORTERS.FETCH(),
        ActionCreators.GET_PORTERS.SUCCESS(),
        ActionCreators.GET_PORTERS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.USERS(),
    method: 'GET',
    params: {
        authGroup: 'Porter',
        nameContains: name,
    },
});
export const getServiceAdvisors = (name) => createAPIAction({
    types: [
        ActionCreators.GET_SERVICE_ADVISORS.FETCH(),
        ActionCreators.GET_SERVICE_ADVISORS.SUCCESS(),
        ActionCreators.GET_SERVICE_ADVISORS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.USERS(),
    method: 'GET',
    params: {
        authGroup: 'Service Advisors',
        nameContains: name,
    },
});
export const getTerritoryManagers = (name) => createAPIAction({
    types: [
        ActionCreators.GET_TERRITORY_MANAGERS.FETCH(),
        ActionCreators.GET_TERRITORY_MANAGERS.SUCCESS(),
        ActionCreators.GET_TERRITORY_MANAGERS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.USERS(),
    method: 'GET',
    params: {
        authGroup: 'Territory Managers',
        nameContains: name,
    },
});
export const getNotTechnicians = () => createAPIAction({
    types: [
        ActionCreators.GET_NOT_TECHNICIANS.FETCH(),
        ActionCreators.GET_NOT_TECHNICIANS.SUCCESS(),
        ActionCreators.GET_NOT_TECHNICIANS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.USERS(),
    method: 'GET',
    params: {
        authGroup: 'Technicians',
        notInGroup: true,
    },
});
export const getManagers = () => createAPIAction({
    types: [
        ActionCreators.GET_MANAGERS.FETCH(),
        ActionCreators.GET_MANAGERS.SUCCESS(),
        ActionCreators.GET_MANAGERS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.USERS(),
    method: 'GET',
    params: {
        authGroup: 'Managers',
    },
});
export const updateUserProfileInfo = ({ body, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_USER_PROFILE_INFO),
    endpoint: API_URLS.ADMIN.ADMIN_USERS(body.id),
    body,
    method: 'PUT',
});
export const updateUserPhoneNumber = ({ id, body, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_USER_PHONE_NUMBER),
    endpoint: API_URLS.ADMIN.ADMIN_USERS(id),
    body,
    method: 'PATCH',
});
export const updateUserEmail = ({ id, body }) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_USER_EMAIL),
    endpoint: API_URLS.ADMIN.ADMIN_USERS(id),
    body,
    method: 'PATCH',
});
export const uploadUserProfilePicture = (params) => {
    const formData = new window.FormData();
    formData.append('file', params.file);
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_USER_PROFILE_PICTURE.FETCH(),
            ActionCreators.UPDATE_USER_PROFILE_PICTURE.SUCCESS(),
            ActionCreators.UPDATE_USER_PROFILE_PICTURE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.USER_PROFILE_PHOTO(params.userId),
        formData,
        method: 'POST',
    });
};
export const updateUserProfileSkills = (userId, body) => createAPIAction({
    types: [
        ActionCreators.UPDATE_USER_SKILLS.FETCH(),
        ActionCreators.UPDATE_USER_SKILLS.SUCCESS(),
        ActionCreators.UPDATE_USER_SKILLS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ADD_USER_SKILLS(userId),
    body,
    method: 'POST',
});
export const removeUserProfileSkill = (userId, skillId) => {
    return createAPIAction({
        types: [
            ActionCreators.REMOVE_USER_SKILLS.FETCH(),
            ActionCreators.REMOVE_USER_SKILLS.SUCCESS(),
            ActionCreators.REMOVE_USER_SKILLS.FAILURE(),
        ],
        endpoint: `/api/isp-service/admin/users/${userId}/skills/${skillId}`,
        method: 'DELETE',
    });
};
export const getSkills = () => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_SKILLS),
    endpoint: API_URLS.ADMIN.SKILLS(),
    method: 'GET',
});
export const getTechniciansForAutocomplete = (query, pageSize = 20) => createAPIAction({
    types: [
        ActionCreators.GET_TECHNICIANS_FOR_AUTOCOMPLETE.FETCH(),
        ActionCreators.GET_TECHNICIANS_FOR_AUTOCOMPLETE.SUCCESS(),
        ActionCreators.GET_TECHNICIANS_FOR_AUTOCOMPLETE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.USERS_TECHNICIANS(),
    method: 'GET',
    params: { query, size: pageSize },
});
export const getTechnicianUser = (userId) => createAPIAction({
    types: [
        ActionCreators.GET_TECHNICIAN_USER.FETCH(),
        ActionCreators.GET_TECHNICIAN_USER.SUCCESS(),
        ActionCreators.GET_TECHNICIAN_USER.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.TECH_USERS(userId),
    method: 'GET',
});
