import createDefaultActionCreators, { createActionCreators, createDefaultApiActionCreators, } from 'src/utils/createActions';
import CONFIG from 'src/config';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createAction from 'src/modules/api/utils/createAction';
export const ActionCreators = {
    JOB_COUNTS: createDefaultApiActionCreators('JOB_COUNTS'),
    GET_REGIONS: createDefaultApiActionCreators('GET_REGIONS'),
    GET_METROS: createDefaultApiActionCreators('GET_METROS'),
    GET_TERRITORIES: createDefaultApiActionCreators('GET_TERRITORIES'),
    GET_VANS_BY_REGION: createDefaultApiActionCreators('GET_VANS_BY_REGION'),
    GET_JOBS_BY_VAN: createDefaultApiActionCreators('GET_JOBS_BY_VAN'),
    CLEAR_JOBS: createActionCreators(['CLEAR'], 'CLEAR_JOBS'),
    GET_SHOP_BY_ID: createDefaultApiActionCreators('GET_SHOP_BY_ID'),
    GET_JOBS_STATS: createDefaultApiActionCreators('GET_JOBS_STATS'),
    POPS_EDIT_PART: createDefaultApiActionCreators('POPS_EDIT_PART'),
    POPS_EDIT_CUSTOM_PART: createDefaultApiActionCreators('POPS_EDIT_CUSTOM_PART'),
    POPS_GET_SAME_DAY_JOBS: createDefaultApiActionCreators('POPS_GET_SAME_DAY_JOBS'),
    POPS_COMPLETE_ORDER: createDefaultActionCreators('POPS_COMPLETE_ORDER'),
    POPS_COMPLETE_INTEGRATED_ORDER: createDefaultApiActionCreators('POPS_COMPLETE_INTEGRATED_ORDER'),
    POPS_GET_INTEGRATED_ORDER_STATUS: createDefaultApiActionCreators('POPS_GET_INTEGRATED_ORDER_STATUS'),
    POPS_ADD_PART: createDefaultActionCreators('POPS_ADD_PART'),
    POPS_ADD_CUSTOM_PART: createDefaultActionCreators('POPS_ADD_CUSTOM_PART'),
    POPS_GET_PURCHASE_ORDER: createDefaultApiActionCreators('POPS_GET_PURCHASE_ORDER'),
    GET_REPAIR_NOTES: createDefaultActionCreators('GET_REPAIR_NOTES'),
    GET_TOP_PRIORITY_ORDER: createDefaultActionCreators('GET_TOP_PRIORITY_ORDER'),
    UPDATE_ORDER_ROSTER: createDefaultActionCreators('UPDATE_ORDER_ROSTER'),
    GET_COMPONENT_MARK_UP: createDefaultActionCreators('GET_COMPONENT_MARK_UP'),
    GET_ORDER_ROSTER_JOBS: createDefaultActionCreators('GET_ORDER_ROSTER_JOBS'),
    GET_ACTIVELY_SKIPPED_ORDER_ROSTER_JOBS: createDefaultActionCreators('GET_ACTIVELY_SKIPPED_ORDER_ROSTER_JOBS'),
    POPS_REMOVE_COMPONENT: createDefaultActionCreators('POPS_REMOVE_COMPONENT'),
    GET_ORDER_ROSTER_JOB_BY_REFERENCE_NUM: createDefaultActionCreators('GET_ORDER_ROSTER_JOB_BY_REFERENCE_NUM'),
    GET_ORDER_ROSTER_JOB_REQUEST_BY_REFERENCE_NUM: createDefaultActionCreators('GET_ORDER_ROSTER_JOB_REQUEST_BY_REFERENCE_NUM'),
    GET_DELIVERY_ADDRESS_OPTIONS: createDefaultActionCreators('GET_DELIVERY_ADDRESS_OPTIONS'),
    GET_WRONG_PART_NUMBERS: createDefaultActionCreators('GET_WRONG_PART_NUMBERS'),
    GET_REPAIR_COMPONENTS: createDefaultActionCreators('GET_REPAIR_COMPONENTS'),
    ADD_FITMENT: createDefaultActionCreators('ADD_FITMENT'),
    REMOVE_FITMENT: createDefaultActionCreators('REMOVE_FITMENT'),
    UPDATE_FITMENT: createDefaultActionCreators('UPDATE_FITMENT'),
    GET_FITMENTS: createDefaultActionCreators('GET_FITMENTS'),
    SAVE_SELECTED_FITMENT: createDefaultActionCreators('SAVE_SELECTED_FITMENT'),
};
export const getPopsJobCounts = () => createAPIAction({
    types: fillApiTypes(ActionCreators.JOB_COUNTS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/pops/job-counts`,
    method: 'GET',
});
export const getDeliveryRegions = (params) => {
    const p = Object.assign({}, params);
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_REGIONS),
        endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/regions/delivery-vans`,
        method: 'GET',
        params: p,
    });
};
export const getDeliveryMetros = (includeArchived = true) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_METROS),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/metros/names?include_archived=${includeArchived}`,
    method: 'GET',
});
export const getDeliveryTerritories = () => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_TERRITORIES),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/territories/names`,
    method: 'GET',
});
export const getJobsByVan = ({ ispId, shift, date, timezone, size, page, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_JOBS_BY_VAN),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/repair-requests/pops/by-vans/${ispId}`,
    params: { size, date, shift, timezone, page },
    method: 'GET',
});
export const getShopById = (ispId) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_SHOP_BY_ID),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/isps/${ispId}`,
    method: 'GET',
});
export const getJobsStats = (ispId) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_JOBS_STATS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/repair-requests/pops/by-vans/${ispId}/parts-state`,
    method: 'GET',
    params: {
        partsStatuses: ['PENDING', 'ORDER_FAILED', 'WRONG_PART', 'NOT_PICKED_UP'],
    },
});
export const setSelectedJobsForOrdering = createAction('SET_SELECTED_JOB_FOR_ORDERING');
export const setSelectedDistributor = createAction('SET_SELECTED_DISTRIBUTOR');
export const setOrderParts = createAction('SET_ORDER_PARTS');
export const setSelectedDeliveryAddress = createAction('SET_SELECTED_DELIVERY_ADDRESS');
export const editPart = ({ requestId, body, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.POPS_EDIT_PART),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/quotes/suggested-repair/${requestId}/component`,
    body,
    method: 'PATCH',
});
export const editCustomPart = ({ requestId, body, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.POPS_EDIT_CUSTOM_PART),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/quotes/custom-request/${requestId}/component`,
    body,
    method: 'PATCH',
});
export const getSameDayJobs = ({ params }) => createAPIAction({
    types: fillApiTypes(ActionCreators.POPS_GET_SAME_DAY_JOBS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/repair-requests/pops/orders`,
    method: 'GET',
    params,
});
export const completeOrderParts = ({ body }) => createAPIAction({
    types: fillApiTypes(ActionCreators.POPS_EDIT_CUSTOM_PART),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/repair-requests/pops/orders`,
    body,
    method: 'PUT',
});
export const completeIntegratedPartsOrder = ({ body }) => createAPIAction({
    types: fillApiTypes(ActionCreators.POPS_COMPLETE_INTEGRATED_ORDER),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/pops/purchase-order`,
    body,
    method: 'POST',
});
export const getPartOrderStatus = ({ id }) => createAPIAction({
    types: fillApiTypes(ActionCreators.POPS_GET_INTEGRATED_ORDER_STATUS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/pops/purchase-order/${id}/status`,
    method: 'GET',
});
export const getPartPurchaseOrder = ({ id }) => createAPIAction({
    types: fillApiTypes(ActionCreators.POPS_GET_PURCHASE_ORDER),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/pops/purchase-order/${id}`,
    method: 'GET',
});
export const addPart = ({ requestId, body, isCustom, }) => {
    const endpoint = isCustom ? 'custom-request' : 'suggested-repair';
    return createAPIAction({
        types: fillApiTypes(ActionCreators.POPS_ADD_PART),
        endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/quotes/${endpoint}/${requestId}/component`,
        body,
        method: 'POST',
    });
};
export const addCustomPart = ({ requestId, body, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.POPS_ADD_CUSTOM_PART),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/quotes/custom-request/${requestId}/component`,
    body,
    method: 'POST',
});
export const setVendorConfirmationNumber = createAction('SET_VENDOR_CONFIRMATION_NUMBER');
export const getTopPriorityOrder = (shift) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_TOP_PRIORITY_ORDER),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/pops/prioritize?shift=${shift.toUpperCase()}`,
    method: 'GET',
});
export const updateOrderRoster = ({ ispId, shift, date, timezone, body, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_TOP_PRIORITY_ORDER),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/pops/order-roster/${ispId}/${shift}/${date}?timezone=${timezone}`,
    body,
    method: 'PATCH',
});
export const getComponentsMarkUp = (repairComponentIds) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_COMPONENT_MARK_UP),
    endpoint: `${CONFIG.CLIENT_API_URL}/pricing-service/admin/components/markups`,
    params: {
        repairComponentIds,
    },
    method: 'GET',
});
export const getOrderRosterJobs = ({ shift, size, page, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_ORDER_ROSTER_JOBS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/order-roster/jobs/${shift}`,
    method: 'GET',
    params: { size, page },
});
export const getActivelySkippedOrderRosterJobs = ({ size, page, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_ACTIVELY_SKIPPED_ORDER_ROSTER_JOBS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/order-roster/jobs/skipped`,
    method: 'GET',
    params: { size, page },
});
export const removeComponent = ({ requestId, componentType, componentId, isCustom, removeDynamicRule, }) => {
    const endpoint = isCustom ? 'custom-request' : 'suggested-repair';
    return createAPIAction({
        types: fillApiTypes(ActionCreators.POPS_REMOVE_COMPONENT),
        endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/quotes/${endpoint}/${requestId}/component/${componentType}/${componentId}${removeDynamicRule ? '?removeDynamicRule=true' : ''}`,
        method: 'DELETE',
    });
};
export const getOrderRosterJobByReferenceNum = (referenceNum) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_ORDER_ROSTER_JOB_BY_REFERENCE_NUM),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/order-roster/jobs/search?referenceNum=${referenceNum}`,
    method: 'GET',
});
export const getOrderRosterJobRequestByReferenceNum = (referenceNum) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_ORDER_ROSTER_JOB_REQUEST_BY_REFERENCE_NUM, {
        success: {
            meta: () => ({ referenceNum }),
        },
    }),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/repair-requests/pops/by-reference-num/${referenceNum}`,
    method: 'GET',
});
export const getDeliveryAddressOptions = (ispId, partDistributorLocationId, referenceNumList) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_DELIVERY_ADDRESS_OPTIONS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/repair-requests/pops/delivery-options/${ispId}`,
    method: 'GET',
    params: { partDistributorLocationId, referenceNums: referenceNumList },
});
export const getWrongPartNumbersByComponent = (suggestedRepairId, componentId, type) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_WRONG_PART_NUMBERS, {
        success: {
            meta: () => ({ componentId }),
        },
    }),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service//admin/wrong-part/${suggestedRepairId}/${componentId}/${type}`,
    method: 'GET',
});
export const getRepairComponents = () => createAPIAction({
    types: [
        ActionCreators.GET_REPAIR_COMPONENTS.FETCH(),
        ActionCreators.GET_REPAIR_COMPONENTS.SUCCESS(),
        ActionCreators.GET_REPAIR_COMPONENTS.FAILURE(),
    ],
    endpoint: `${CONFIG.CLIENT_API_URL}/pricing-service/admin/components/repair-components`,
    method: 'GET',
});
export const saveSelectedFitment = ({ parentType, parentId, fitmentId, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.SAVE_SELECTED_FITMENT),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/component-fitments/${parentType.toUpperCase()}/${parentId}/fitment/${fitmentId}`,
    method: 'POST',
});
export const addFitment = (fitment) => createAPIAction({
    types: fillApiTypes(ActionCreators.ADD_FITMENT),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/component-fitments`,
    method: 'POST',
    body: fitment,
});
export const updateFitment = (fitment, id) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_FITMENT),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/component-fitments/${id}`,
    method: 'PATCH',
    body: fitment,
});
export const removeFitment = (id) => createAPIAction({
    types: fillApiTypes(ActionCreators.REMOVE_FITMENT),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/component-fitments/${id}`,
    method: 'DELETE',
});
