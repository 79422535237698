import createDefaultActionCreators, { createActionCreators, } from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/TechApp/modules/api/urls';
// FSA ACTIONS
export const ActionCreators = {
    GET_DASHBOARD: createDefaultActionCreators('GET_DASHBOARD'),
    GET_TECHNICIAN_DATA: createDefaultActionCreators('GET_TECHNICIAN_DATA'),
    MAP_TECHNICIAN_BY_ID: createActionCreators(['GET'], 'MAP_TECHNICIAN_BY_ID'),
    COMPLETE_TASK: createDefaultActionCreators('COMPLETE_TASK'),
    GET_PORTER_DATA: createDefaultActionCreators('GET_PORTER_DATA'),
    GET_PORTER_DASHBOARD: createDefaultActionCreators('GET_PORTER_DASHBOARD'),
};
export const getDashboardById = (technicianId, date, timezone) => createAPIAction({
    types: [
        ActionCreators.GET_DASHBOARD.FETCH(),
        ActionCreators.GET_DASHBOARD.SUCCESS(),
        ActionCreators.GET_DASHBOARD.FAILURE(),
    ],
    endpoint: API_URLS.DASHBOARD.GET_DASHBOARD(technicianId),
    method: 'GET',
    params: {
        date,
        timezone,
    },
});
export const getTechnicianById = (technicianId, date) => createAPIAction({
    types: [
        ActionCreators.GET_TECHNICIAN_DATA.FETCH(),
        ActionCreators.GET_TECHNICIAN_DATA.SUCCESS({
            meta: () => ({ technicianId, date }),
        }),
        ActionCreators.GET_TECHNICIAN_DATA.FAILURE(),
    ],
    endpoint: API_URLS.DASHBOARD.GET_TECHNICIAN_DATA(technicianId),
    method: 'GET',
    params: {
        date,
    },
});
export const getPorterById = (porterId, date) => createAPIAction({
    types: [
        ActionCreators.GET_PORTER_DATA.FETCH(),
        ActionCreators.GET_PORTER_DATA.SUCCESS({
            meta: () => ({ porterId, date }),
        }),
        ActionCreators.GET_TECHNICIAN_DATA.FAILURE(),
    ],
    endpoint: API_URLS.DASHBOARD.GET_PORTER_DATA(porterId),
    method: 'GET',
});
export const getPorterDashboardById = (porterId, params) => {
    return createAPIAction({
        types: [
            ActionCreators.GET_PORTER_DASHBOARD.FETCH(),
            ActionCreators.GET_PORTER_DASHBOARD.SUCCESS(),
            ActionCreators.GET_PORTER_DASHBOARD.FAILURE(),
        ],
        endpoint: API_URLS.DASHBOARD.GET_PORTER_DASHBOARD(porterId),
        method: 'GET',
        params,
    });
};
