import { useEffect, useState } from 'react';
import { difference } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/modules/api/utils/useDebounce';
import { selectFleetsSearch, selectProductFleetsByProductId, } from 'src/AdminApp/modules/fleets/selectors';
import { selectAddProductFleets } from 'src/PricingApp/modules/repairs/selectors';
import { setSelectedProductFleets } from 'src/PricingApp/modules/repairs/actions';
import { clearFleetsSearchResults, searchFleetsByName, } from 'src/AdminApp/modules/fleets/actions';
const useFleetsAutocomplete = ({ repairId, editable, handleAddMapping, handleRemoveMapping, }) => {
    const dispatch = useDispatch();
    const [searchFleet, setSearchFleet] = useState('');
    const searchFleetsDebounce = useDebounce(searchFleet, 500);
    const [selected, setSelected] = useState([]);
    const [isConflictModalOpen, setIsConflictModalOpen] = useState(false);
    const [fleetSearchResults, setFleetSearchResults] = useState([]);
    const fleetSearchResultsRaw = useSelector(selectFleetsSearch);
    const selectedFleets = useSelector(selectAddProductFleets);
    const fleetMappings = useSelector(selectProductFleetsByProductId(repairId));
    const filterRawSearchResults = () => {
        const filteredResults = fleetSearchResultsRaw === null || fleetSearchResultsRaw === void 0 ? void 0 : fleetSearchResultsRaw.filter((result) => {
            const findExisting = selectedFleets.find((fleet) => fleet.id === result.id);
            return !findExisting;
        });
        setFleetSearchResults(filteredResults);
    };
    useEffect(() => {
        dispatch(setSelectedProductFleets(Object.values(fleetMappings !== null && fleetMappings !== void 0 ? fleetMappings : [])));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleetMappings]);
    useEffect(() => {
        if (searchFleetsDebounce.length > 0) {
            dispatch(searchFleetsByName({ name: searchFleetsDebounce, allResults: true }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFleetsDebounce]);
    useEffect(() => {
        // FILTER SEARCH RESULTS IF THERE ARE ANY SELECTED ALREADY
        if (fleetSearchResultsRaw && (selectedFleets === null || selectedFleets === void 0 ? void 0 : selectedFleets.length) > 0) {
            filterRawSearchResults();
        }
        else if (fleetSearchResultsRaw) {
            setFleetSearchResults(fleetSearchResultsRaw);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleetSearchResultsRaw]);
    useEffect(() => {
        const selectedCountOld = selected.length;
        setSelected(selectedFleets);
        // ADD REMOVED FLEET IF NOT IN LOCAL SEARCH RESULTS
        if (selectedCountOld > selectedFleets.length) {
            filterRawSearchResults();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFleets]);
    useEffect(() => {
        if (!editable) {
            dispatch(setSelectedProductFleets([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editable]);
    const handleSearchFleets = (e, value) => {
        setSearchFleet(value);
    };
    const handleFleetSelected = (e, value) => {
        const addedValue = difference(value, selectedFleets);
        const removedValue = difference(selectedFleets, value);
        if (addedValue.length > 0) {
            const isChildOrParent = selectedFleets.filter((fleet) => fleet.id === addedValue[0].parentId ||
                fleet.parentId === addedValue[0].id);
            if ((isChildOrParent === null || isChildOrParent === void 0 ? void 0 : isChildOrParent.length) > 0) {
                setIsConflictModalOpen(true);
            }
            else {
                dispatch(setSelectedProductFleets(value));
                handleAddMapping(addedValue[0]);
            }
        }
        else if (removedValue.length > 0) {
            dispatch(setSelectedProductFleets(value));
            handleRemoveMapping(removedValue[0]);
        }
        if (value.length === 0) {
            dispatch(setSelectedProductFleets([]));
        }
        dispatch(clearFleetsSearchResults());
    };
    return {
        searchFleet,
        setSearchFleet,
        searchFleetsDebounce,
        selected,
        setSelected,
        isConflictModalOpen,
        setIsConflictModalOpen,
        fleetSearchResults,
        setFleetSearchResults,
        fleetSearchResultsRaw,
        selectedFleets,
        fleetMappings,
        handleSearchFleets,
        filterRawSearchResults,
        handleFleetSelected,
    };
};
export default useFleetsAutocomplete;
