import React, { Fragment, useContext } from 'react';
import { SliderContext } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/utils';
import { Slide } from 'src/components/mui';
import { usePrevious } from 'src/components/SimpleDrawerPaged/utils';
const SimplePageSlider = ({ currentSlide, slides, }) => {
    const sliderContext = useContext(SliderContext);
    const prevScreen = usePrevious(currentSlide);
    const prevIndex = slides.findIndex((route) => route.name === prevScreen);
    const currentIndex = slides.findIndex((route) => route.name === currentSlide);
    const direction = prevIndex < currentIndex ? 'left' : 'right';
    return (<Fragment key={`${currentSlide}-slides`}>
      {slides === null || slides === void 0 ? void 0 : slides.map((route) => {
            var _a;
            return (<Slide key={route.name} mountOnEnter direction={direction} in={route.name === currentSlide} unmountOnExit={!((_a = sliderContext === null || sliderContext === void 0 ? void 0 : sliderContext.keepMounted) === null || _a === void 0 ? void 0 : _a.includes(route.name))}>
          <div>
            {route.component ? route.component : route.render()}
          </div>
        </Slide>);
        })}
    </Fragment>);
};
export default SimplePageSlider;
