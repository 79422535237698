import { splitAndTrimOn } from 'src/utils/data-utils';
import * as Yup from 'yup';
const EMAIL_ERROR_MESSAGE = `Make sure that every email is formatted correctly`;
const EMAIL_MAX_LENGTH_MESSAGE = 'You can only send to 50 emails at a time';
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailListValidation = (emailsInput) => {
    const emailsArray = splitAndTrimOn(emailsInput, ',');
    if (emailsArray.length > 50) {
        return EMAIL_MAX_LENGTH_MESSAGE;
    }
    let badEmail = '';
    emailsArray.map((email) => {
        const isValid = Yup.string().matches(EMAIL_REGEX).isValidSync(email);
        if (!isValid && badEmail.length === 0) {
            badEmail = email;
        }
        return email;
    });
    if (badEmail) {
        return `${EMAIL_ERROR_MESSAGE} - ${badEmail}`;
    }
};
