import React from 'react';
import { Grid } from 'src/components/mui';
import AddComponentForm from 'src/PricingApp/components/components/AddComponentForm';
const EditComponent = (props) => {
    var _a, _b;
    return (<Grid container spacing={2}>
    <Grid item xs={12}>
      <AddComponentForm componentId={(_b = (_a = props === null || props === void 0 ? void 0 : props.match) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.componentId}/>
    </Grid>
  </Grid>);
};
export default EditComponent;
