import React from 'react';
import styled from 'styled-components';
import { Grid } from 'src/components/mui';
import { colors } from 'src/styles/theme';
const ErrorsWrapper = styled(Grid) `
  background-color: ${colors.error};
  margin-top: 10px;
  padding: 0 10px;
`;
const UploaderValidationDetails = ({ validationInfo }) => {
    var _a, _b;
    return (<>
    <Grid container>
      <Grid item xs={3}>
        <p>Detected</p>
        <p>{validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.detected}</p>
      </Grid>
      <Grid item xs={3}>
        <p>Accepted</p>
        <p>{validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.accepted}</p>
      </Grid>
      <Grid item xs={3}>
        <p>Errors</p>
        <p>{validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.errors.length}</p>
      </Grid>
    </Grid>
    {!!((_a = validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.errors) === null || _a === void 0 ? void 0 : _a.length) && (<ErrorsWrapper container>
        <Grid item xs={12}>
          <h3>Errors</h3>
        </Grid>
        <Grid item xs={12}>
          {(_b = validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.errors) === null || _b === void 0 ? void 0 : _b.map((x, index) => (<p key={index}>{x === null || x === void 0 ? void 0 : x.message}</p>))}
        </Grid>
      </ErrorsWrapper>)}
  </>);
};
export default UploaderValidationDetails;
