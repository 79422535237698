import React, { useState, useRef } from 'react';
import { Popper, ClickAwayListener, useMediaQuery } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import { InfoSign, TooltipContent, TooltipContentDesktop, VisitTooltip, } from './TooltipInfo.styles';
const TooltipInfo = ({ children, outermargin, infoColor, contentWidth, }) => {
    const [open, setOpen] = useState(false);
    const infoRef = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const handleClick = (e) => {
        e.stopPropagation();
        setTimeout(() => {
            setOpen(true);
        }, 100);
    };
    const handleClickAway = () => {
        if (open) {
            setOpen(false);
        }
    };
    return isMobile ? (<>
      <InfoSign className={open ? 'tooltipOpened' : ''} ref={infoRef} $outerMargin={outermargin} $infoColor={infoColor} onClick={handleClick}/>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper open={open} anchorEl={infoRef.current} placement="top" style={{
            position: 'fixed !important',
            zIndex: 1000,
        }}>
          <TooltipContent>{children}</TooltipContent>
        </Popper>
      </ClickAwayListener>
    </>) : (<VisitTooltip placement="top" title={<TooltipContentDesktop className="TooltipInfo__content" contentWidth={contentWidth}>
          {children}
        </TooltipContentDesktop>}>
      <InfoSign className={open ? 'tooltipOpened' : ''} ref={infoRef} $outerMargin={outermargin} $infoColor={infoColor} onMouseEnter={handleClick} onMouseLeave={handleClickAway}/>
    </VisitTooltip>);
};
export default TooltipInfo;
