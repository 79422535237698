import React from 'react';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import SimpleModal from 'src/components/SimpleModal';
const InputText = styled('textarea') `
  margin: 10px 0;
  height: 5rem;
  width: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  background: ${colors.white} !important;
  resize: none;
  padding: 10px;
`;
const RemovePaymentModal = ({ acceptBtnLabel, cancelBtnLabel, content, onClose, onSubmit, open, title, isRefund, refundReason, onRefundReason, }) => {
    return (<SimpleModal open={open} onClose={onClose} showClose={false} title={title} simpleTwoButtonRowProps={{
            onCancel: onClose,
            onSubmit,
            submitText: acceptBtnLabel,
            cancelText: cancelBtnLabel,
            submitIsDestructive: true,
        }}>
      {content}
      {isRefund && (<InputText id="refundReasonInput" value={refundReason} onChange={(evt) => onRefundReason(evt.target.value)} title="Refund Reason" placeholder="Refund Reason" minLength={1} maxLength={255}/>)}
    </SimpleModal>);
};
export default RemovePaymentModal;
