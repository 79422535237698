export var PartsHelpTabs;
(function (PartsHelpTabs) {
    PartsHelpTabs[PartsHelpTabs["HOME"] = 0] = "HOME";
    PartsHelpTabs[PartsHelpTabs["ADDITIONAL_COMPONENTS_REQUIRED_SELECT_REPAIR"] = 1] = "ADDITIONAL_COMPONENTS_REQUIRED_SELECT_REPAIR";
    PartsHelpTabs[PartsHelpTabs["ADDITIONAL_COMPONENTS_REQUIRED_SELECT_COMPONENT_TYPE"] = 2] = "ADDITIONAL_COMPONENTS_REQUIRED_SELECT_COMPONENT_TYPE";
    PartsHelpTabs[PartsHelpTabs["ADDITIONAL_REPAIRS_REQUIRED"] = 3] = "ADDITIONAL_REPAIRS_REQUIRED";
    PartsHelpTabs[PartsHelpTabs["INPUT_CUSTOM_REPAIR"] = 4] = "INPUT_CUSTOM_REPAIR";
    PartsHelpTabs[PartsHelpTabs["FMC_CUSTOM_REPAIR"] = 5] = "FMC_CUSTOM_REPAIR";
    PartsHelpTabs[PartsHelpTabs["CUSTOMER_PROVIDED_PARTS"] = 6] = "CUSTOMER_PROVIDED_PARTS";
    PartsHelpTabs[PartsHelpTabs["FAULTY_PART_RECEIVED_SELECT_REPAIR"] = 7] = "FAULTY_PART_RECEIVED_SELECT_REPAIR";
    PartsHelpTabs[PartsHelpTabs["FAULTY_PART_RECEIVED_SELECT_REPAIR_COMPONENT"] = 8] = "FAULTY_PART_RECEIVED_SELECT_REPAIR_COMPONENT";
    PartsHelpTabs[PartsHelpTabs["PRICING_QUOTE"] = 9] = "PRICING_QUOTE";
    PartsHelpTabs[PartsHelpTabs["URGENT_PENDING_PARTS"] = 10] = "URGENT_PENDING_PARTS";
    PartsHelpTabs[PartsHelpTabs["WRONG_PART_SELECT_REPAIR"] = 11] = "WRONG_PART_SELECT_REPAIR";
    PartsHelpTabs[PartsHelpTabs["WRONG_PART_SELECT_REPAIR_COMPONENT"] = 12] = "WRONG_PART_SELECT_REPAIR_COMPONENT";
})(PartsHelpTabs || (PartsHelpTabs = {}));
