var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import { selectTrackAdminConfirm, selectTrackRepairCompleted, } from 'src/AdminApp/modules/analytics/selectors';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { getCancelFeeCreditsByConsumerId, getConsumerById, updateConsumerCar, } from 'src/AdminApp/modules/consumers/actions';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { getInspectionPerformedStatus } from 'src/AdminApp/modules/mpi/actions';
import { selectCurrentUser } from 'src/AdminApp/modules/users/selectors';
import { getRepairRequestStopwatch, getTechnicianOnSiteWorkflowByRef, } from 'src/AdminApp/modules/workflows/actions';
import { ActionCreators as WorkOrderActionCreators, redeemPromoCode, } from 'src/AdminApp/modules/workOrders/actions';
import { selectHasPromosByWorkOrderId } from 'src/AdminApp/modules/workOrders/selectors';
import { toast } from 'src/components/SimpleToast';
import CONFIG from 'src/config';
import { SNAPSHOT_LOAD_TIMEOUT } from 'src/constants';
import createAPIAction from 'src/modules/api';
import createAction from 'src/modules/api/utils/createAction';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import { selectAuthorizedSnapshotViewer } from 'src/modules/auth/selectors';
import createDefaultActionCreators, { createActionCreators, } from 'src/utils/createActions';
import { isExpired } from 'src/utils/data-utils';
import { lookupRequestIdByReferenceNum, selectContactInfoByReferenceNum, selectFleetIdByReferenceNum, selectRepairRequestWithReferenceNum, selectRequestDataByReferenceNum, selectRequestRequestReferenceNum, } from './selectors';
import { updateFleetVehicleResponsibleParties } from '../fleets/actions';
export const ActionCreators = {
    ADD_CUSTOM_REPAIR: createDefaultActionCreators('ADD_CUSTOM_REPAIR'),
    ADD_FMC_CUSTOM_REPAIR: createDefaultActionCreators('ADD_FMC_CUSTOM_REPAIR'),
    ADD_RECOMMENDED_REPAIRS: createDefaultActionCreators('ADD_RECOMMENDED_REPAIRS'),
    ADD_RECOMMENDED_REPAIRS_BY_REF_NUMBER: createDefaultActionCreators('ADD_RECOMMENDED_REPAIRS_BY_REF_NUMBER'),
    ADD_REPAIR: createDefaultActionCreators('ADD_REPAIR'),
    INTERNAL_REPAIRS: createDefaultActionCreators('INTERNAL_REPAIRS'),
    GET_LOGO_FOR_REQUEST: createDefaultActionCreators('GET_LOGO_FOR_REQUEST'),
    REQUEST_DASHBOARD: createDefaultActionCreators('REQUEST_DASHBOARD', [
        'CLEAR',
        'CLEAR_FILTERS',
    ]),
    REQUEST_CREATE: createDefaultActionCreators('REQUEST_CREATE'),
    REQUEST_BY_ID: createDefaultActionCreators('REQUEST_BY_ID'),
    REQUEST_UPDATE: createDefaultActionCreators('REQUEST_UPDATE'),
    REQUEST_CAR: createDefaultActionCreators('REQUEST_CAR'),
    REQUEST_APPOINTMENTS: createDefaultActionCreators('REQUEST_APPOINTMENTS'),
    REQUEST_ORDERS: createDefaultActionCreators('REQUEST_ORDERS'),
    REQUEST_BY_REFERENCE_NUM: createDefaultActionCreators('REQUEST_BY_REFERENCE_NUM'),
    REQUEST_UPDATE_CONTACT: createDefaultActionCreators('REQUEST_UPDATE_CONTACT'),
    REQUEST_UPDATE_CAR: createDefaultActionCreators('REQUEST_UPDATE_CAR'),
    REQUEST_UPDATE_ADDRESS: createDefaultActionCreators('REQUEST_UPDATE_ADDRESS'),
    REQUEST_UPDATE_STATE: createDefaultActionCreators('REQUEST_UPDATE_STATE'),
    REQUEST_PROVIDE_QUOTE: createDefaultActionCreators('REQUEST_PROVIDE_QUOTE'),
    REQUEST_RELATED_PARTS: createDefaultActionCreators('REQUEST_RELATED_PARTS'),
    GET_CONSUMER_SERVICE_ADVISOR_NOTES: createDefaultActionCreators('GET_CONSUMER_SERVICE_ADVISOR_NOTES'),
    REPAIRS_SEARCH: createDefaultActionCreators('REPAIRS_SEARCH'),
    FMC_REPAIRS_SEARCH: createDefaultActionCreators('FMC_REPAIRS_SEARCH'),
    QUOTE_DIAGNOSIS: createDefaultActionCreators('QUOTE_DIAGNOSIS'),
    UPDATE_QUOTE_SUGGESTED_INSPECTION_TOTAL_COST: createDefaultActionCreators('UPDATE_QUOTE_SUGGESTED_INSPECTION_TOTAL_COST'),
    DELETE_QUOTE_SUGGESTED_INSPECTION: createDefaultActionCreators('DELETE_QUOTE_SUGGESTED_INSPECTION'),
    UPDATE_QUOTE_SUGGESTED_MAINTENANCE_TOTAL_COST: createDefaultActionCreators('UPDATE_QUOTE_SUGGESTED_MAINTENANCE_TOTAL_COST'),
    AUTHORIZE_QUOTE: createDefaultActionCreators('AUTHORIZE_QUOTE'),
    DELETE_QUOTE_SUGGESTED_MAINTENANCE: createDefaultActionCreators('DELETE_QUOTE_SUGGESTED_MAINTENANCE'),
    UPDATE_QUOTE_CAR_DIAGNOSIS_TOTAL_COST: createDefaultActionCreators('UPDATE_QUOTE_CAR_DIAGNOSIS_TOTAL_COST'),
    DELETE_QUOTE_CAR_DIAGNOSIS: createDefaultActionCreators('DELETE_QUOTE_CAR_DIAGNOSIS'),
    UPDATE_REPAIR_COMPONENTS: createDefaultActionCreators('UPDATE_REPAIR_COMPONENTS'),
    UPDATE_REPAIR_COMPONENTS_BATCH: createDefaultActionCreators('UPDATE_REPAIR_COMPONENTS_BATCH'),
    DELETE_QUOTE_SUGGESTED_REPAIR: createDefaultActionCreators('DELETE_QUOTE_SUGGESTED_REPAIR'),
    PROCESS_CANT_FINISH_INELIGIBLE_FOR_SERVICE: createDefaultActionCreators('PROCESS_CANT_FINISH_INELIGIBLE_FOR_SERVICE'),
    PROCESS_CANT_FINISH_VEHICLE_CONDITION_POOR: createDefaultActionCreators('PROCESS_CANT_FINISH_VEHICLE_CONDITION_POOR'),
    PROCESS_CANT_FINISH_VEHICLE_REQUIRES_ADDTL_REPAIRS: createDefaultActionCreators('PROCESS_CANT_FINISH_VEHICLE_REQUIRES_ADDTL_REPAIRS'),
    COMPLETE_PROCESS_CANT_FINISH_VEHICLE_REQUIRES_ADDTL_REPAIRS: createDefaultActionCreators('COMPLETE_PROCESS_CANT_FINISH_VEHICLE_REQUIRES_ADDTL_REPAIRS'),
    PROCESS_CANT_FINISH_VEHICLE_IS_LONGER_THAN_NO_CHARGE_GRACE_PERIOD: createDefaultActionCreators('PROCESS_CANT_FINISH_VEHICLE_IS_LONGER_THAN_NO_CHARGE_GRACE_PERIOD'),
    SKEDULO_CREATE_JOB: createDefaultActionCreators('SKEDULO_CREATE_JOB'),
    SKEDULO_CANCEL_JOB: createDefaultActionCreators('SKEDULO_CANCEL_JOB'),
    GET_RECOMMENDED_REPAIRS: createDefaultActionCreators('GET_RECOMMENDED_REPAIRS'),
    REJECT_RECOMMENDED_REPAIR: createDefaultActionCreators('REJECT_RECOMMENDED_REPAIR'),
    ACCEPT_RECOMMENDED_REPAIR: createDefaultActionCreators('ACCEPT_RECOMMENDED_REPAIR'),
    UNDO_REJECTED_RECOMMENDED_REPAIR: createDefaultActionCreators('UNDO_REJECTED_RECOMMENDED_REPAIR'),
    UPDATE_PARTS_STATUS: createDefaultActionCreators('UPDATE_PARTS_STATUS'),
    UPDATE_SUGGESTED_REPAIR: createDefaultActionCreators('UPDATE_SUGGESTED_REPAIR'),
    REQUESTS_BY_CAR_STATES: createDefaultActionCreators('REQUESTS_BY_CAR_STATES'),
    SCHEDULE_REPAIR: createDefaultActionCreators('SCHEDULE_REPAIR'),
    LOAD_REQUEST_HISTORY: createDefaultActionCreators('LOAD_REQUEST_HISTORY'),
    LOAD_REQUEST_SNAPSHOT: createDefaultActionCreators('LOAD_REQUEST_SNAPSHOT'),
    CAPTURE_REQUEST_SNAPSHOT: createDefaultActionCreators('CAPTURE_REQUEST_SNAPSHOT'),
    PAYER_TYPE_MODAL: createDefaultActionCreators('PAYER_TYPE_MODAL', [
        'CLEAR_REPAIR',
        'SET_REPAIR',
    ]),
    CLOSE_PAYER_TYPE_MODAL: createDefaultActionCreators('CLOSE_PAYER_TYPE_MODAL'),
    PAYER_CLAIMS: createActionCreators(['REMOVE_CLAIM', 'SET_CLAIM'], 'PAYER_CLAIMS'),
    CREATE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION: createDefaultActionCreators('CREATE_AUTHORIZATION_NUMBER'),
    UPDATE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION: createDefaultActionCreators('UPDATE_AUTHORIZATION_NUMBER'),
    DELETE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION: createDefaultActionCreators('DELETE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION'),
    RESPONSIBLE_PARTY_TYPES: createDefaultActionCreators('RESPONSIBLE_PARTY_TYPES'),
    GET_CONSUMER_RESPONSIBLE_PARTY: createDefaultActionCreators('GET_CONSUMER_RESPONSIBLE_PARTY'),
    GET_FLEET_RESPONSIBLE_PARTY: createDefaultActionCreators('GET_FLEET_RESPONSIBLE_PARTY'),
    GET_RESPONSIBLE_PARTIES_BY_TYPE: createDefaultActionCreators('GET_RESPONSIBLE_PARTIES_BY_TYPE'),
    SAVE_RESPONSIBLE_PARTIES: createDefaultActionCreators('SAVE_RESPONSIBLE_PARTIES'),
    CAR_PHOTO: createDefaultActionCreators('CAR_PHOTO'),
    FOUR_CORNER_MODAL: createActionCreators(['CLOSE', 'OPEN'], 'FOUR_CORNER_MODAL'),
    MOBILE_CAR_DETAILS: createActionCreators(['CLOSE', 'OPEN'], 'MOBILE_CAR_DETAILS'),
    APPOINTMENT_RESOLUTIONS: createDefaultActionCreators('APPOINTMENT_RESOLUTIONS'),
    CANCEL_FEE_MAX_HOURS: createDefaultActionCreators('CANCEL_FEE_MAX_HOURS'),
    WORK_ORDER_PAYMENTS: createDefaultActionCreators('WORK_ORDER_PAYMENTS'),
    CANCEL_VISIT_REQUEST_APPOINTMENT: createDefaultActionCreators('CANCEL_VISIT_REQUEST_APPOINTMENT'),
    REPAIR_MEASUREMENTS: createDefaultActionCreators('REPAIR_MEASUREMENTS'),
    ORDER_ROSTER_JOB_QUEUE_POSITION: createDefaultActionCreators('ORDER_ROSTER_JOB_QUEUE_POSITION'),
    CREATE_WRONG_PART: createDefaultActionCreators('CREATE_WRONG_PART'),
    REQUEST_PART_PICKUPS: createDefaultActionCreators('REPAIR_REQUEST_PART_PICKUPS'),
    SCHEDULE_FUTURE_APPOINTMENT: createDefaultActionCreators('SCHEDULE_FUTURE_APPOINTMENT'),
};
export const getLogoForRequest = ({ make = '', referenceNum, }) => createAPIAction({
    types: [
        ActionCreators.GET_LOGO_FOR_REQUEST.FETCH(),
        ActionCreators.GET_LOGO_FOR_REQUEST.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.GET_LOGO_FOR_REQUEST.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.LOGO_BY_BRAND(),
    method: 'GET',
    params: { brand: make },
});
export const updateRepairOrder = (referenceNum, repairOrder) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(ActionCreators.REQUEST_ORDERS.SUCCESS({
        payload: [repairOrder],
        meta: { referenceNum },
    }));
});
export const updateWorkOrder = (referenceNum, workOrder) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(WorkOrderActionCreators.FIND_WORK_ORDERS.SUCCESS({
        payload: { content: [workOrder] },
        meta: { referenceNum },
    }));
});
export const loadRequests = (params) => {
    const { filters } = params;
    const patchedDates = {
        createdFrom: (filters === null || filters === void 0 ? void 0 : filters.createdFrom) &&
            moment(filters.createdFrom)
                .startOf('day')
                .utc()
                .format(),
        createdTo: (filters === null || filters === void 0 ? void 0 : filters.createdTo) &&
            moment(filters.createdTo)
                .startOf('day')
                .add(1, 'days')
                .utc()
                .format(),
        appointmentTo: (filters === null || filters === void 0 ? void 0 : filters.appointmentTo) &&
            moment(filters.appointmentTo)
                .startOf('day')
                .add(1, 'days')
                .format(''),
        appointmentFrom: (filters === null || filters === void 0 ? void 0 : filters.appointmentFrom) &&
            moment(filters.appointmentFrom)
                .startOf('day')
                .format(''),
    };
    return createAPIAction({
        types: [
            ActionCreators.REQUEST_DASHBOARD.FETCH({
                meta: () => ({ params }),
            }),
            ActionCreators.REQUEST_DASHBOARD.SUCCESS(),
            ActionCreators.REQUEST_DASHBOARD.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUESTS_DASHBOARD(Object.assign(Object.assign({}, params), { filters: Object.assign(Object.assign({}, params.filters), patchedDates) })),
        method: 'GET',
    });
};
export const dispatchGetRequestAction = (referenceNum, requestId, action, endpointFactory) => createAPIAction({
    types: [
        action.FETCH(),
        action.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        action.FAILURE(),
    ],
    endpoint: endpointFactory({ requestId }),
    method: 'GET',
});
export const getRequest = (referenceNum, requestId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(dispatchGetRequestAction(referenceNum, requestId, ActionCreators.REQUEST_BY_ID, API_URLS.ADMIN.REQUEST_BY_ID));
    const { repairOrder } = response.payload;
    if (repairOrder) {
        dispatch(updateRepairOrder(referenceNum, repairOrder));
    }
    const { workOrder } = response.payload;
    if (workOrder) {
        dispatch(updateWorkOrder(referenceNum, workOrder));
    }
    return response;
});
export const getRequestUntilExpectedState = (referenceNum, requestId, expectedState, retries = 6, delay = 2000) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve) => {
        const attempt = () => __awaiter(void 0, void 0, void 0, function* () {
            if (retries === 0) {
                resolve(null);
            }
            else {
                const response = yield dispatch(getRequest(referenceNum, requestId));
                if (!response || response.payload.state !== expectedState) {
                    retries -= 1;
                    setTimeout(attempt, delay);
                }
                else {
                    resolve(response);
                }
            }
        });
        attempt();
    });
});
export const getRequestCar = (referenceNum, requestId) => dispatchGetRequestAction(referenceNum, requestId, ActionCreators.REQUEST_CAR, API_URLS.ADMIN.REQUEST_CAR);
export const getRequestAppointments = (referenceNum, requestId) => dispatchGetRequestAction(referenceNum, requestId, ActionCreators.REQUEST_APPOINTMENTS, API_URLS.ADMIN.REQUEST_APPOINTMENTS);
export const getQuoteDiagnosis = (referenceNum, requestId) => dispatchGetRequestAction(referenceNum, requestId, ActionCreators.QUOTE_DIAGNOSIS, API_URLS.ADMIN.QUOTE_DIAGNOSIS);
export const getRelatedParts = (referenceNum, requestId) => dispatchGetRequestAction(referenceNum, requestId, ActionCreators.REQUEST_RELATED_PARTS, API_URLS.ADMIN.REQUEST_RELATED_PARTS);
export const getRecommendedRepairs = (referenceNum) => createAPIAction({
    types: [
        ActionCreators.GET_RECOMMENDED_REPAIRS.FETCH(),
        ActionCreators.GET_RECOMMENDED_REPAIRS.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.GET_RECOMMENDED_REPAIRS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.RECOMMENDED_REPAIRS(referenceNum),
    method: 'GET',
});
export const getConsumerServiceAdvisorNotes = (referenceNum) => createAPIAction({
    types: [
        ActionCreators.GET_CONSUMER_SERVICE_ADVISOR_NOTES.FETCH(),
        ActionCreators.GET_CONSUMER_SERVICE_ADVISOR_NOTES.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.GET_CONSUMER_SERVICE_ADVISOR_NOTES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.NOTES_BY_ID_TYPE_ORIGIN({
        entityId: referenceNum,
        entityType: 'REPAIR_REQUEST',
        origin: 'CONSUMER',
    }),
    method: 'GET',
});
export const scheduleRO = (body, referenceNum, specificTime, resetParts, visitRefNum) => {
    return createAPIAction({
        types: [
            ActionCreators.SCHEDULE_REPAIR.FETCH(),
            ActionCreators.SCHEDULE_REPAIR.SUCCESS({
                meta: () => ({
                    appointmentTime: body.appointmentTime,
                    vanId: body.vanId,
                    visitRefNum,
                }),
            }),
            ActionCreators.SCHEDULE_REPAIR.FAILURE(),
        ],
        endpoint: visitRefNum
            ? API_URLS.ADMIN.SCHEDULE_APPOINTMENT_VISIT(visitRefNum)
            : API_URLS.ADMIN.SCHEDULE_APPOINTMENT(referenceNum),
        params: {
            resetParts,
            specificTime,
        },
        method: 'POST',
        body,
    });
};
export const rescheduleRO = (body, referenceNum, specificTime, resetParts) => {
    return createAPIAction({
        types: [
            ActionCreators.SCHEDULE_REPAIR.FETCH(),
            ActionCreators.SCHEDULE_REPAIR.SUCCESS({
                meta: () => ({
                    appointmentTime: body.appointmentTime,
                    vanId: body.vanId,
                }),
            }),
            ActionCreators.SCHEDULE_REPAIR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SCHEDULE_REPAIR(),
        params: {
            referenceNum,
            specificTime,
            resetParts,
        },
        method: 'POST',
        body,
    });
};
export const createRO = (body, specificTime, visitRefNum) => {
    return createAPIAction({
        types: [
            ActionCreators.REQUEST_CREATE.FETCH(),
            ActionCreators.REQUEST_CREATE.SUCCESS({
                meta: () => ({
                    appointmentTime: body.appointmentTime,
                    vanId: body.vanId,
                    consumerId: body.consumerId,
                    consumerCarId: body.consumerCarId,
                    mileage: body.mileage,
                    repairs: body.repairs,
                    visitRefNum,
                }),
            }),
            ActionCreators.REQUEST_CREATE.FAILURE(),
        ],
        endpoint: visitRefNum
            ? API_URLS.ADMIN.SCHEDULE_APPOINTMENT_VISIT(visitRefNum)
            : API_URLS.ADMIN.SCHEDULE_QUICK_RO(),
        params: { specificTime },
        method: 'POST',
        body,
    });
};
export const createROFromVisitByRecommendedRepairs = (body, specificTime, visitRefNum) => {
    return createAPIAction({
        types: [
            ActionCreators.REQUEST_CREATE.FETCH(),
            ActionCreators.REQUEST_CREATE.SUCCESS({
                meta: () => ({
                    appointmentTime: body.appointmentTime,
                    vanId: body.vanId,
                    consumerId: body.consumerId,
                    consumerCarId: body.consumerCarId,
                    mileage: body.mileage,
                    visitRefNum,
                }),
            }),
            ActionCreators.REQUEST_CREATE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SCHEDULE_APPOINTMENT_VISIT_FROM_RECOMMENDED_REPAIRS(visitRefNum),
        params: { specificTime },
        method: 'POST',
        body,
    });
};
/**
 * Retrieve a repair request data on page load. The data includes
   - repair request (with repair order and work order)
   - repair request's car
   - repair request's consumer
   - previous RO credits of consumer
   - quote diagnosis services
   - all discount details
   - all appointments
   - repair request's car logo
   - related parts
   - recommended repairs
   - consumer's service advisor notes
 *
 * @param referenceNum
 * @param force
 * @returns
 */
// TODO: look for where the loadRequest() can be replaced with just calling getRequest()
//       so that other components do not have to be reloaded
export const loadRequest = (referenceNum, force = false) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const currentRequestData = selectRequestDataByReferenceNum(referenceNum)(getState());
    if (!force && !isExpired(currentRequestData))
        return;
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REQUEST_BY_REFERENCE_NUM.FETCH(),
            ActionCreators.REQUEST_BY_REFERENCE_NUM.SUCCESS(),
            ActionCreators.REQUEST_BY_REFERENCE_NUM.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_BY_REFERENCE_NUM({ referenceNum }),
        method: 'GET',
    }));
    if (response.error) {
        return response;
    }
    const newRequest = response.payload;
    const requestId = newRequest === null || newRequest === void 0 ? void 0 : newRequest.id;
    const car = yield dispatch(getRequestCar(referenceNum, requestId));
    const { make = null } = (_a = car.payload) !== null && _a !== void 0 ? _a : {
        make: null,
    };
    const contactInfo = selectContactInfoByReferenceNum(referenceNum)(getState());
    if (!contactInfo)
        return;
    const consumerId = +contactInfo.consumerId;
    if (consumerId) {
        yield dispatch(getConsumerById(consumerId));
    }
    const { repairOrder } = newRequest;
    if (repairOrder) {
        dispatch(updateRepairOrder(referenceNum, repairOrder));
        const workOrderId = newRequest.workOrder.id;
        if (consumerId) {
            yield dispatch(getCancelFeeCreditsByConsumerId({ consumerId, workOrderId }));
        }
    }
    yield dispatch(getQuoteDiagnosis(referenceNum, requestId));
    dispatch(getRequestAppointments(referenceNum, requestId));
    dispatch(getLogoForRequest({ make, referenceNum }));
    dispatch(getRelatedParts(referenceNum, requestId));
    dispatch(getRecommendedRepairs(referenceNum));
    dispatch(getConsumerServiceAdvisorNotes(referenceNum));
    dispatch(getResponsiblePartyTypes());
    const fleetId = selectFleetIdByReferenceNum(referenceNum)(getState());
    if (fleetId) {
        dispatch(getResponsiblePartyByFleetId(referenceNum, fleetId));
    }
    else {
        dispatch(getResponsiblePartyByConsumerId(referenceNum, consumerId));
    }
    const { workOrder } = newRequest;
    if (workOrder) {
        dispatch(updateWorkOrder(referenceNum, workOrder));
        const workOrderId = newRequest.workOrder.id;
        yield dispatch(getCancelFeeCreditsByConsumerId({ consumerId, workOrderId }));
    }
    dispatch(getRepairMeasurements(referenceNum));
    return newRequest;
});
export const loadSwimlaneRequestInfo = (referenceNum, loadRequestOnly) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REQUEST_BY_REFERENCE_NUM.FETCH(),
            ActionCreators.REQUEST_BY_REFERENCE_NUM.SUCCESS(),
            ActionCreators.REQUEST_BY_REFERENCE_NUM.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_BY_REFERENCE_NUM({ referenceNum }),
        method: 'GET',
    }));
    if (response.error) {
        return response;
    }
    const newRequest = response.payload;
    const requestId = newRequest === null || newRequest === void 0 ? void 0 : newRequest.id;
    if (!loadRequestOnly) {
        yield dispatch(getRequestCar(referenceNum, requestId));
        yield dispatch(getQuoteDiagnosis(referenceNum, requestId));
    }
    return newRequest;
});
export const updateRequest = (body, referenceNum) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateRequestApiAction = createAPIAction({
        types: [
            ActionCreators.REQUEST_UPDATE.FETCH(),
            ActionCreators.REQUEST_UPDATE.SUCCESS(),
            ActionCreators.REQUEST_UPDATE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_BY_ID({ requestId }),
        method: 'PATCH',
        body,
    });
    return dispatch(updateRequestApiAction);
});
export const getWorkOrderPayments = (workOrderId) => (dispatch) => dispatch(createAPIAction({
    types: [
        ActionCreators.WORK_ORDER_PAYMENTS.FETCH(),
        ActionCreators.WORK_ORDER_PAYMENTS.SUCCESS({
            meta: () => ({ workOrderId }),
        }),
        ActionCreators.WORK_ORDER_PAYMENTS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.WORK_ORDER_PAYMENTS(workOrderId),
    method: 'GET',
}));
export const updateRequestState = (stateAction, referenceNum, params) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c, _d, _e;
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const request = selectRepairRequestWithReferenceNum(getState(), referenceNum);
    const result = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REQUEST_UPDATE_STATE.FETCH(),
            ActionCreators.REQUEST_UPDATE_STATE.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.REQUEST_UPDATE_STATE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN[`REQUEST_${stateAction}`]({
            requestId,
        }),
        method: 'POST',
        body: {},
        params,
    }));
    if (result.error) {
        return result;
    }
    if (request) {
        dispatch(getWorkOrderPayments(request.workOrder.id));
        if (params === null || params === void 0 ? void 0 : params.processCancelFee) {
            dispatch(getInvoicesByWorkOrderId({ workOrderId: request.workOrder.id }));
            if (((_c = (_b = result.payload) === null || _b === void 0 ? void 0 : _b.cancellationFeeStatus) === null || _c === void 0 ? void 0 : _c.state) === 'SUCCEEDED') {
                toast.success('Cancellation fee successfully processed.');
            }
            else if ((_e = (_d = result.payload) === null || _d === void 0 ? void 0 : _d.cancellationFeeStatus) === null || _e === void 0 ? void 0 : _e.errorMessage) {
                toast.error('Cancellation fee could not be processed.');
            }
        }
    }
    return dispatch(loadRequest(referenceNum, true));
});
export const cancelROVisitAppointment = (referenceNum, workOrderId, visitReferenceNum, params) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    yield dispatch(createAPIAction({
        types: [
            ActionCreators.CANCEL_VISIT_REQUEST_APPOINTMENT.FETCH(),
            ActionCreators.CANCEL_VISIT_REQUEST_APPOINTMENT.SUCCESS(),
            ActionCreators.CANCEL_VISIT_REQUEST_APPOINTMENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_CANCEL_VISIT_APPOINTMENT({
            visitReferenceNum,
            workOrderId,
        }),
        method: 'DELETE',
        params,
    }));
    dispatch(getWorkOrderPayments(workOrderId));
    return dispatch(loadRequest(referenceNum, true));
});
export const updateRequestAddress = (body, referenceNum) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateRequestAddressApiAction = createAPIAction({
        types: [
            ActionCreators.REQUEST_UPDATE_ADDRESS.FETCH(),
            ActionCreators.REQUEST_UPDATE_ADDRESS.SUCCESS(),
            ActionCreators.REQUEST_UPDATE_ADDRESS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_ADDRESS({ requestId }),
        method: 'PUT',
        body: Object.assign({}, body),
    });
    yield dispatch(updateRequestAddressApiAction);
    dispatch(loadRequest(referenceNum, true));
});
export const cancelRequest = ({ repairRequestResolution, otherResolution = null, invalidRequestReasons = null, referenceNum, }) => {
    const params = Object.assign(Object.assign({ repairRequestResolution }, (!!invalidRequestReasons && { invalidRequestReasons })), (!!otherResolution && { otherResolution }));
    return updateRequestState('CANCEL', referenceNum, params);
};
export const confirmRequestAppointment = (referenceNum) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const trackingCallPayload = selectTrackAdminConfirm(referenceNum)(getState());
    const response = yield dispatch(updateRequestState('CONFIRM_APPOINTMENT', referenceNum));
    dispatch(analyticsTrackEvent('Admin Confirmed Appointment', trackingCallPayload));
    return response;
});
export const cancelRequestAppointment = ({ appointmentResolution = null, otherResolution = null, processCancelFee = false, referenceNum, workOrderId, visitReferenceNum, }) => {
    if (visitReferenceNum) {
        return cancelROVisitAppointment(referenceNum, workOrderId, visitReferenceNum, {
            appointmentResolution,
            otherResolution,
        });
    }
    return updateRequestState('CANCEL_APPOINTMENT', referenceNum, {
        appointmentResolution,
        otherResolution,
        processCancelFee,
    });
};
export const closeRequestOrder = ({ orderResolution, otherResolution = null, referenceNum, workOrderId, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const hasPromos = selectHasPromosByWorkOrderId(workOrderId)(getState());
    const trackingCallPayload = selectTrackRepairCompleted(referenceNum)(getState());
    const repairCompleted = [
        'REPAIR_COMPLETED_WITH_INVOICE',
        'REPAIR_COMPLETED_NO_INVOICE',
    ].includes(orderResolution);
    const shouldRedeemPromoCode = repairCompleted && hasPromos;
    if (shouldRedeemPromoCode) {
        yield dispatch(redeemPromoCode({ workOrderId }));
    }
    yield dispatch(updateRequestState('CLOSE', referenceNum, Object.assign({ orderResolution }, (otherResolution && { otherResolution }))));
    yield dispatch(getInspectionPerformedStatus(referenceNum));
    return dispatch(analyticsTrackEvent('Repair Completed', trackingCallPayload));
});
export const reloadRequest = (referenceNum) => loadRequest(referenceNum, true);
export const reloadRequestAppointments = (referenceNum) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
        dispatch(getRequestAppointments(referenceNum, requestId));
    });
};
export const updateContact = ({ referenceNum, name, phone, email, }) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const body = Object.assign(Object.assign(Object.assign({}, (!!name && { name })), (!!phone && { phone })), (!!email && { email }));
        const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
        return dispatch(createAPIAction({
            types: [
                ActionCreators.REQUEST_UPDATE_CONTACT.FETCH(),
                ActionCreators.REQUEST_UPDATE_CONTACT.SUCCESS({
                    meta: () => ({ referenceNum }),
                }),
                ActionCreators.REQUEST_UPDATE_CONTACT.FAILURE(),
            ],
            endpoint: API_URLS.ADMIN.REQUEST_CONTACT({ requestId }),
            body,
            method: 'PUT',
        }));
    });
};
export const updateCar = ({ referenceNum, vehicle, consumerCarId, consumerId, updateVin = false, repairRequestId, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const { make, model, year: carYear, trim: carTrim, engineId, engineDescription, mileage, mileageOut, plateNumber, plateNumberState, residualValue, vin, mileageNotAvailable, } = vehicle;
    const body = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!make && { make })), (!!model && { model })), (!!carYear && { carYear })), (!!carTrim && { carTrim })), (!!engineId && { engineId })), (!!engineDescription && { engineDescription })), (!!mileage && { mileage })), (!!mileageOut && { mileageOut })), (!!plateNumber && { plateNumber })), (!!vin && { vin })), (!!plateNumberState && { plateNumberState })), (!!residualValue && { residualValue })), { mileageNotAvailable });
    const requestId = repairRequestId ||
        lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateCarAction = createAPIAction({
        types: [
            ActionCreators.REQUEST_UPDATE_CAR.FETCH(),
            ActionCreators.REQUEST_UPDATE_CAR.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.REQUEST_UPDATE_CAR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_CAR({ requestId }),
        body,
        method: 'PUT',
    });
    dispatch(updateConsumerCar({
        car: vehicle,
        consumerCarId,
        consumerId,
        updateVin,
    }));
    const response = yield dispatch(updateCarAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    return response;
});
export const searchRepairs = (searchTerms, fleetId) => createAPIAction({
    types: [
        ActionCreators.REPAIRS_SEARCH.FETCH(),
        ActionCreators.REPAIRS_SEARCH.SUCCESS(),
        ActionCreators.REPAIRS_SEARCH.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.REPAIRS_SEARCH(),
    method: 'POST',
    body: { searchTerms, fleetId, searchLevel: 2 },
});
export const searchFMCRepairs = (customRepairText, fmcType) => createAPIAction({
    types: [
        ActionCreators.FMC_REPAIRS_SEARCH.FETCH(),
        ActionCreators.FMC_REPAIRS_SEARCH.SUCCESS(),
        ActionCreators.FMC_REPAIRS_SEARCH.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.FMC_REPAIRS_SEARCH(),
    method: 'GET',
    params: { customRepairText, fmcType },
});
export const saveFleetVehicleRP = (fleetVehicleRP) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    if (fleetVehicleRP &&
        (fleetVehicleRP === null || fleetVehicleRP === void 0 ? void 0 : fleetVehicleRP.responsiblePartyId) &&
        (fleetVehicleRP === null || fleetVehicleRP === void 0 ? void 0 : fleetVehicleRP.vin) &&
        (fleetVehicleRP === null || fleetVehicleRP === void 0 ? void 0 : fleetVehicleRP.fleetId)) {
        return yield dispatch(updateFleetVehicleResponsibleParties(fleetVehicleRP === null || fleetVehicleRP === void 0 ? void 0 : fleetVehicleRP.fleetId, fleetVehicleRP));
    }
});
export const addRepair = ({ referenceNum, repairId, internalApprovalProvidedById, isValidateLaborCapacity, responsiblePartyId, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const addRepairAction = createAPIAction({
        types: [
            ActionCreators.ADD_REPAIR.FETCH(),
            ActionCreators.ADD_REPAIR.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.ADD_REPAIR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.ADD_REPAIR({ requestId, repairId }),
        method: 'POST',
        params: { internalApprovalProvidedById, isValidateLaborCapacity },
        body: {
            responsiblePartyId,
        },
    });
    const response = yield dispatch(addRepairAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    yield dispatch(getTechnicianOnSiteWorkflowByRef(referenceNum));
    return response;
});
export const addCustomRepair = ({ referenceNum, repairDescription, fmcRepairId, isAutoIntegrateFmc, responsiblePartyId, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const addRepairAction = createAPIAction({
        types: [
            ActionCreators.ADD_CUSTOM_REPAIR.FETCH(),
            ActionCreators.ADD_CUSTOM_REPAIR.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.ADD_CUSTOM_REPAIR.FAILURE(),
        ],
        endpoint: isAutoIntegrateFmc
            ? API_URLS.ADMIN.ADD_FMC_CUSTOM_REPAIR({
                requestId,
                repairDescription,
                fmcCustomRepairId: fmcRepairId,
            })
            : API_URLS.ADMIN.ADD_CUSTOM_REPAIR({
                requestId,
                repairDescription,
            }),
        method: 'POST',
        body: {
            responsiblePartyId,
        },
    });
    const response = yield dispatch(addRepairAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    return response;
});
export const updateQuoteProvided = ({ referenceNum, sendEmail }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _f;
    const state = getState();
    const body = { sendEmail };
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(state);
    const currentUser = selectCurrentUser(getState());
    const currentRequest = selectRequestRequestReferenceNum(referenceNum)(state);
    const noServiceAdvisorAssigned = currentRequest
        ? currentRequest.serviceAdvisorUserId
        : false;
    const serviceAdvisorName = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName)
        ? `${currentUser.firstName} ${currentUser.lastName}`
        : '';
    const currentAuthGroups = (_f = currentUser === null || currentUser === void 0 ? void 0 : currentUser.authGroups) !== null && _f !== void 0 ? _f : [];
    const currentUserIsServiceAdvisor = currentAuthGroups.includes('Service Advisors');
    const canViewSnapshotRole = selectAuthorizedSnapshotViewer(getState());
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REQUEST_PROVIDE_QUOTE.FETCH(),
            ActionCreators.REQUEST_PROVIDE_QUOTE.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.REQUEST_PROVIDE_QUOTE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_PROVIDE_QUOTE({ requestId }),
        body,
        method: 'PUT',
    }));
    if (noServiceAdvisorAssigned && currentUserIsServiceAdvisor) {
        dispatch(updateRequest({
            serviceAdvisorName,
            serviceAdvisorUserId: currentUser === null || currentUser === void 0 ? void 0 : currentUser.id,
        }, referenceNum));
    }
    dispatch(loadRequest(referenceNum, !!requestId));
    if (canViewSnapshotRole) {
        setTimeout(() => dispatch(loadRequestSnapshot({ referenceNum })), SNAPSHOT_LOAD_TIMEOUT);
    }
    return response;
});
export const addRepairComponents = ({ referenceNum, repairId, body, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateSuggestedRepairAction = createAPIAction({
        types: [
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FETCH(),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_SUGGESTED_REPAIR_COMPONENTS({
            suggestedRepairId: repairId,
        }),
        body,
        method: 'POST',
    });
    const response = yield dispatch(updateSuggestedRepairAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    return response;
});
export const updateRepairComponents = ({ referenceNum, repairId, body, isValidateLaborCapacity = false, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _g;
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateSuggestedRepairAction = createAPIAction({
        types: [
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FETCH(),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_SUGGESTED_REPAIR_COMPONENTS({
            suggestedRepairId: repairId,
            isValidateLaborCapacity,
        }),
        body,
        method: 'PATCH',
    });
    const response = yield dispatch(updateSuggestedRepairAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    dispatch(getRepairRequestStopwatch(referenceNum));
    if ((_g = response.payload) === null || _g === void 0 ? void 0 : _g.promoCodeRemoved) {
        toast.warn('Due to the new total, the promo code is no longer valid and has been removed.');
    }
    return response;
});
export const updateBatchRepairComponents = ({ referenceNum, body }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _h;
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateSuggestedRepairAction = createAPIAction({
        types: [
            ActionCreators.UPDATE_REPAIR_COMPONENTS_BATCH.FETCH(),
            ActionCreators.UPDATE_REPAIR_COMPONENTS_BATCH.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.UPDATE_REPAIR_COMPONENTS_BATCH.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.QUOTE_SUGGESTED_REPAIR_COMPONENTS_BATCH_ADDITIONAL_REPAIR_COMPONENTS(),
        body,
        method: 'PATCH',
    });
    const response = yield dispatch(updateSuggestedRepairAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    dispatch(getRepairRequestStopwatch(referenceNum));
    if ((_h = response.payload) === null || _h === void 0 ? void 0 : _h.promoCodeRemoved) {
        toast.warn('Due to the new total, the promo code is no longer valid and has been removed.');
    }
    return response;
});
export const removeRepairComponents = ({ referenceNum, repairId, componentType, componentId, removeDynamicRule, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _j;
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateSuggestedRepairAction = createAPIAction({
        types: [
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FETCH(),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REMOVE_SUGGESTED_REPAIR_COMPONENTS({
            suggestedRepairId: repairId,
            componentType,
            componentId,
            removeDynamicRule,
        }),
        method: 'DELETE',
    });
    const response = yield dispatch(updateSuggestedRepairAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    if ((_j = response.payload) === null || _j === void 0 ? void 0 : _j.promoCodeRemoved) {
        toast.warn('Due to the new total, the promo code is no longer valid and has been removed.');
    }
    return response;
});
export const processIneligibleForService = ({ referenceNum, internalApprovalProvidedById, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const cantFinishIneligibleForService = createAPIAction({
        types: [
            ActionCreators.PROCESS_CANT_FINISH_INELIGIBLE_FOR_SERVICE.FETCH(),
            ActionCreators.PROCESS_CANT_FINISH_INELIGIBLE_FOR_SERVICE.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.PROCESS_CANT_FINISH_INELIGIBLE_FOR_SERVICE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PROCESS_INELIGIBLE_FOR_SERVICE({ referenceNum }),
        params: { internalApprovalProvidedById },
        method: 'POST',
    });
    const response = yield dispatch(cantFinishIneligibleForService);
    dispatch(loadRequest(referenceNum, !!requestId));
    return response;
});
export const processVehicleConditionTooPoor = ({ referenceNum }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const cantFinishIneligibleForService = createAPIAction({
        types: [
            ActionCreators.PROCESS_CANT_FINISH_VEHICLE_CONDITION_POOR.FETCH(),
            ActionCreators.PROCESS_CANT_FINISH_VEHICLE_CONDITION_POOR.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.PROCESS_CANT_FINISH_VEHICLE_CONDITION_POOR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PROCESS_VEHICLE_CONDITION_TOO_POOR({
            referenceNum,
        }),
        method: 'POST',
    });
    const response = yield dispatch(cantFinishIneligibleForService);
    dispatch(loadRequest(referenceNum, !!requestId));
    return response;
});
export const completeProcessVehicleRequiresAddtlRepairs = ({ referenceNum }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const canViewSnapshotRole = selectAuthorizedSnapshotViewer(getState());
    const cantFinishVehicleRequiresAddtlRepairs = createAPIAction({
        types: [
            ActionCreators.COMPLETE_PROCESS_CANT_FINISH_VEHICLE_REQUIRES_ADDTL_REPAIRS.FETCH(),
            ActionCreators.COMPLETE_PROCESS_CANT_FINISH_VEHICLE_REQUIRES_ADDTL_REPAIRS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.COMPLETE_PROCESS_CANT_FINISH_VEHICLE_REQUIRES_ADDTL_REPAIRS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.COMPLETE_PROCESS_VEHICLE_REQUIRES_ADDTL_REPAIRS({
            referenceNum,
        }),
        method: 'POST',
    });
    const response = yield dispatch(cantFinishVehicleRequiresAddtlRepairs);
    dispatch(loadRequest(referenceNum, !!requestId));
    if (canViewSnapshotRole) {
        setTimeout(() => dispatch(loadRequestSnapshot({ referenceNum })), SNAPSHOT_LOAD_TIMEOUT);
    }
    return response;
});
export const returnIsLongerThanGracePeriod = ({ referenceNum }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const isLongerThanGracePeriod = createAPIAction({
        types: [
            ActionCreators.PROCESS_CANT_FINISH_VEHICLE_IS_LONGER_THAN_NO_CHARGE_GRACE_PERIOD.FETCH(),
            ActionCreators.PROCESS_CANT_FINISH_VEHICLE_IS_LONGER_THAN_NO_CHARGE_GRACE_PERIOD.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.PROCESS_CANT_FINISH_VEHICLE_IS_LONGER_THAN_NO_CHARGE_GRACE_PERIOD.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PROCESS_CANT_FINISH_VEHICLE_IS_LONGER_THAN_NO_CHARGE_GRACE_PERIOD({
            referenceNum,
        }),
        method: 'GET',
    });
    const response = yield dispatch(isLongerThanGracePeriod);
    return response;
});
export const updateSuggestedRepair = ({ referenceNum, suggestedRepairId, body, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _k;
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.UPDATE_SUGGESTED_REPAIR.FETCH(),
            ActionCreators.UPDATE_SUGGESTED_REPAIR.SUCCESS({
                meta: () => ({ suggestedRepairId, referenceNum }),
            }),
            ActionCreators.UPDATE_SUGGESTED_REPAIR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.QUOTE_SUGGESTED_REPAIR({ suggestedRepairId }),
        method: 'PATCH',
        body,
    }));
    dispatch(getQuoteDiagnosis(referenceNum, requestId));
    if ((_k = response.payload) === null || _k === void 0 ? void 0 : _k.promoCodeRemoved) {
        toast.warn('Due to the new total, the promo code is no longer valid and has been removed.');
    }
    return response;
});
export const removeSuggestedRepair = ({ referenceNum, suggestedRepairId, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _l;
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const removeSuggestedRepairAction = createAPIAction({
        types: [
            ActionCreators.DELETE_QUOTE_SUGGESTED_REPAIR.FETCH(),
            ActionCreators.DELETE_QUOTE_SUGGESTED_REPAIR.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.DELETE_QUOTE_SUGGESTED_REPAIR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.QUOTE_SUGGESTED_REPAIR({ suggestedRepairId }),
        method: 'DELETE',
    });
    const response = yield dispatch(removeSuggestedRepairAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    if ((_l = response.payload) === null || _l === void 0 ? void 0 : _l.promoCodeRemoved) {
        toast.warn('Due to the new total, the promo code is no longer valid and has been removed.');
    }
    return response;
});
export const addRecommendedRepairs = ({ inspectionResults, origin, referenceNum, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const body = {
        origin,
        recommendedRepairs: inspectionResults.map((result) => ({
            repairId: +result.inspectionRsRepair.repairId,
            inspectionDetails: {
                severity: result.status,
                symptoms: result.inspectionSymptoms.map((s) => s.name),
                additionalInfo: result.payload.additionalInformation,
                imageUrls: result.images.split(','),
            },
        })),
    };
    const updateSuggestedRepairAction = createAPIAction({
        types: [
            ActionCreators.ADD_RECOMMENDED_REPAIRS.FETCH({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.ADD_RECOMMENDED_REPAIRS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.ADD_RECOMMENDED_REPAIRS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.RECOMMENDED_REPAIRS(referenceNum),
        body,
        method: 'POST',
    });
    return dispatch(updateSuggestedRepairAction);
});
export const addRecommendedRepairsByRefNumber = ({ body, referenceNum }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const updateSuggestedRepairAction = createAPIAction({
        types: [
            ActionCreators.ADD_RECOMMENDED_REPAIRS_BY_REF_NUMBER.FETCH({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.ADD_RECOMMENDED_REPAIRS_BY_REF_NUMBER.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.ADD_RECOMMENDED_REPAIRS_BY_REF_NUMBER.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.RECOMMENDED_REPAIRS(referenceNum),
        body,
        method: 'POST',
    });
    return dispatch(updateSuggestedRepairAction);
});
export const addInternalRepairsByRefNumber = ({ body, referenceNum }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const updateSuggestedRepairAction = createAPIAction({
        types: [
            ActionCreators.INTERNAL_REPAIRS.FETCH({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.INTERNAL_REPAIRS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.INTERNAL_REPAIRS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.INTERNAL_REPAIRS(referenceNum),
        body,
        method: 'POST',
    });
    return dispatch(updateSuggestedRepairAction);
});
export const addCustomRepairComponents = ({ referenceNum, customRequestId, body, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateCustomRepairAction = createAPIAction({
        types: [
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FETCH(),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_CUSTOM_REPAIR_COMPONENTS({
            customRequestId,
        }),
        body,
        method: 'POST',
    });
    const response = yield dispatch(updateCustomRepairAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    return response;
});
export const updateCustomRepairComponents = ({ referenceNum, customRequestId, body, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateCustomRepairAction = createAPIAction({
        types: [
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FETCH(),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REQUEST_CUSTOM_REPAIR_COMPONENTS({
            customRequestId,
        }),
        body,
        method: 'PATCH',
    });
    const response = yield dispatch(updateCustomRepairAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    return response;
});
export const removeCustomRepairComponents = ({ referenceNum, customRequestId, componentType, componentId, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const updateCustomRepairAction = createAPIAction({
        types: [
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FETCH(),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.UPDATE_REPAIR_COMPONENTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REMOVE_CUSTOM_REPAIR_COMPONENTS({
            customRequestId,
            componentType,
            componentId,
        }),
        method: 'DELETE',
    });
    const response = yield dispatch(updateCustomRepairAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    return response;
});
export const removeCarDiagnosis = ({ referenceNum, diagnosisId, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const removeCarDiagnosisAction = createAPIAction({
        types: [
            ActionCreators.DELETE_QUOTE_CAR_DIAGNOSIS.FETCH(),
            ActionCreators.DELETE_QUOTE_CAR_DIAGNOSIS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.DELETE_QUOTE_CAR_DIAGNOSIS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.QUOTE_CAR_DIAGNOSIS({ diagnosisId }),
        method: 'DELETE',
    });
    const response = yield dispatch(removeCarDiagnosisAction);
    dispatch(loadRequest(referenceNum, !!requestId));
    return response;
});
export const rejectRecommendedRepair = ({ id, rejectedReason, otherReason, }) => createAPIAction({
    types: [
        ActionCreators.REJECT_RECOMMENDED_REPAIR.FETCH(),
        ActionCreators.REJECT_RECOMMENDED_REPAIR.SUCCESS({
            meta: () => ({ id }),
        }),
        ActionCreators.REJECT_RECOMMENDED_REPAIR.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.REJECT_RECOMMENDED_REPAIR(id),
    method: 'PUT',
    body: {
        rejectedReason,
        otherReason,
    },
});
export const acceptRecommendedRepair = ({ suggestedRepairIds, internalApprovalProvidedById, isValidateLaborCapacity, }) => createAPIAction({
    types: [
        ActionCreators.ACCEPT_RECOMMENDED_REPAIR.FETCH(),
        ActionCreators.ACCEPT_RECOMMENDED_REPAIR.SUCCESS({
            meta: () => ({ suggestedRepairIds }),
        }),
        ActionCreators.ACCEPT_RECOMMENDED_REPAIR.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ACCEPT_RECOMMENDED_REPAIR(),
    params: {
        suggestedRepairIds,
        internalApprovalProvidedById,
        isValidateLaborCapacity,
    },
    method: 'PUT',
});
export const undoRejectedRecommendedRepair = ({ id }) => createAPIAction({
    types: [
        ActionCreators.UNDO_REJECTED_RECOMMENDED_REPAIR.FETCH(),
        ActionCreators.UNDO_REJECTED_RECOMMENDED_REPAIR.SUCCESS({
            meta: () => ({ id }),
        }),
        ActionCreators.UNDO_REJECTED_RECOMMENDED_REPAIR.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.UNDO_REJECTED_RECOMMENDED_REPAIR(id),
    method: 'PUT',
});
export const updatePartsStatus = ({ referenceNum, status }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const requestId = lookupRequestIdByReferenceNum(referenceNum)(getState());
    const action = createAPIAction({
        types: [
            ActionCreators.UPDATE_PARTS_STATUS.FETCH(),
            ActionCreators.UPDATE_PARTS_STATUS.SUCCESS({
                meta: () => ({ referenceNum, status }),
            }),
            ActionCreators.UPDATE_PARTS_STATUS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.UPDATE_PARTS_STATUS({ requestId, status }),
        method: 'PUT',
    });
    const response = yield dispatch(action);
    dispatch(loadRequest(referenceNum, !!requestId));
    return response;
});
export const loadRequestHistory = (params) => createAPIAction({
    types: [
        ActionCreators.LOAD_REQUEST_HISTORY.FETCH(),
        ActionCreators.LOAD_REQUEST_HISTORY.SUCCESS({
            meta: () => ({ referenceNum: params.referenceNum }),
        }),
        ActionCreators.LOAD_REQUEST_HISTORY.FAILURE(),
    ],
    endpoint: API_URLS.SYSTEM.LOAD_REQUEST_HISTORY(params.id),
    method: 'GET',
});
export const loadRequestSnapshot = (params) => createAPIAction({
    types: [
        ActionCreators.LOAD_REQUEST_SNAPSHOT.FETCH(),
        ActionCreators.LOAD_REQUEST_SNAPSHOT.SUCCESS({
            meta: () => ({ referenceNum: params.referenceNum }),
        }),
        ActionCreators.LOAD_REQUEST_SNAPSHOT.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.LOAD_REQUEST_SNAPSHOT({
        referenceNum: params.referenceNum,
    }),
    method: 'GET',
});
export const createWorkOrderItemResponsiblePartyAuthorization = (referenceNum, values) => {
    return createAPIAction({
        types: [
            ActionCreators.CREATE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.FETCH(),
            ActionCreators.CREATE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.CREATE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATIONS({
            referenceNum,
        }),
        method: 'POST',
        body: values,
    });
};
export const updateWorkOrderItemResponsiblePartyAuthorization = (referenceNum, values) => {
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.FETCH(),
            ActionCreators.UPDATE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.UPDATE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATIONS({
            referenceNum,
        }),
        method: 'PUT',
        body: values,
    });
};
export const removeWorkOrderResponsiblePartyAuthorization = (referenceNum, workOrderResponsiblePartyAuthorizationId) => createAPIAction({
    types: [
        ActionCreators.DELETE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.FETCH(),
        ActionCreators.DELETE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.DELETE_WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.WORK_ORDER_RESPONSIBLE_PARTY_AUTHORIZATION_BY_ID(workOrderResponsiblePartyAuthorizationId),
    method: 'DELETE',
});
export const setIsMpiAlertShowing = createAction('SET_IS_MPI_ALERT_SHOWING');
export const setShowMpiSentToCustomerAlert = createAction('SET_SHOW_MPI_SENT_TO_CUSTOMER_ALERT');
export const clearResponsiblePartyTypeModalRepair = () => (dispatch) => {
    dispatch(ActionCreators.CLOSE_PAYER_TYPE_MODAL.SUCCESS());
};
export const setResponsiblePartyTypeModalRepair = (payload) => (dispatch) => {
    dispatch(ActionCreators.PAYER_TYPE_MODAL.SET_REPAIR({ payload }));
};
export const openAuthorizeModal = (isOpen) => {
    return {
        type: 'AUTHORIZE_MODAL_OPEN',
        payload: {
            isOpen,
        },
    };
};
export const openPaymentSection = (isOpen) => {
    return {
        type: 'PAYMENT_SECTION_OPEN',
        payload: {
            isOpen,
        },
    };
};
export const openWarrantyQuestionSection = (isOpen) => {
    return {
        type: 'WARRANTY_QUESTIONS_SECTION_OPEN',
        payload: {
            isOpen,
        },
    };
};
export const openMeasurementsSection = (isOpen, nodeId, required) => {
    return {
        type: 'MEASUREMENTS_SECTION_OPEN',
        payload: {
            isOpen,
            nodeId,
            required,
        },
    };
};
export const openCopyQuoteToInvoiceModal = (isOpen) => {
    return {
        type: 'COPY_QUOTE_TO_INVOICE_MODAL_OPEN',
        payload: {
            isOpen,
        },
    };
};
export const authorizeQuote = ({ quoteId, workOrderId, method, authorizedByName, referenceNum, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const canViewSnapshotRole = selectAuthorizedSnapshotViewer(getState());
    yield dispatch(createAPIAction({
        types: [
            ActionCreators.AUTHORIZE_QUOTE.FETCH(),
            ActionCreators.AUTHORIZE_QUOTE.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.AUTHORIZE_QUOTE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.QUOTE_AUTHORIZATION({
            quoteId,
            method,
            authorizedByName,
        }),
        method: 'PUT',
    }));
    dispatch(loadRequest(referenceNum));
    if (canViewSnapshotRole) {
        setTimeout(() => dispatch(loadRequestSnapshot({ referenceNum })), SNAPSHOT_LOAD_TIMEOUT);
    }
});
export const getResponsiblePartyByConsumerId = (referenceNum, consumerId) => createAPIAction({
    types: [
        ActionCreators.GET_CONSUMER_RESPONSIBLE_PARTY.FETCH(),
        ActionCreators.GET_CONSUMER_RESPONSIBLE_PARTY.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.GET_CONSUMER_RESPONSIBLE_PARTY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.RESPONSIBLE_PARTIES_BY_CONSUMER_ID(consumerId),
    method: 'GET',
});
export const getResponsiblePartyByFleetId = (referenceNum, fleetId) => createAPIAction({
    types: [
        ActionCreators.GET_FLEET_RESPONSIBLE_PARTY.FETCH(),
        ActionCreators.GET_FLEET_RESPONSIBLE_PARTY.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.GET_FLEET_RESPONSIBLE_PARTY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.RESPONSIBLE_PARTIES_BY_FLEET_ID(fleetId),
    method: 'GET',
});
export const getResponsiblePartyTypes = () => createAPIAction({
    types: fillApiTypes(ActionCreators.RESPONSIBLE_PARTY_TYPES),
    endpoint: API_URLS.ADMIN.RESPONSIBLE_PARTY_TYPES(),
    method: 'GET',
});
export const getResponsiblePartiesByType = ({ responsiblePartyTypeId, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_RESPONSIBLE_PARTIES_BY_TYPE),
    endpoint: API_URLS.ADMIN.RESPONSIBLE_PARTIES_BY_TYPE({
        responsiblePartyTypeId,
    }),
    method: 'GET',
});
export const saveResponsibleParties = (referenceNum, customRepairId, repairId, body) => createAPIAction({
    types: [
        ActionCreators.SAVE_RESPONSIBLE_PARTIES.FETCH(),
        ActionCreators.SAVE_RESPONSIBLE_PARTIES.SUCCESS({
            meta: () => ({ referenceNum, customRepairId, repairId, data: body }),
        }),
        ActionCreators.SAVE_RESPONSIBLE_PARTIES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.WORK_ORDER_ITEM_RESPONSIBLE_PARTIES(),
    body,
    method: 'POST',
});
export const uploadFourCornerPhotos = (params) => {
    const formData = new window.FormData();
    formData.append('photo', params.file);
    return createAPIAction({
        types: [
            ActionCreators.CAR_PHOTO.FETCH(),
            ActionCreators.CAR_PHOTO.SUCCESS({
                meta: () => ({ referenceNum: params.referenceNum }),
            }),
            ActionCreators.CAR_PHOTO.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.CAR_PHOTO({
            carId: params.carId,
            type: params.type,
        }),
        formData,
        method: 'POST',
    });
};
export const markFourCornerPhotoUploading = createAction('MARK_FOUR_CORNER_PHOTO_UPLOADING');
export const unmarkFourCornerPhotoUploading = createAction('UNMARK_FOUR_CORNER_PHOTO_UPLOADING');
export const deleteFourCornerPhoto = (params) => createAPIAction({
    types: [
        ActionCreators.CAR_PHOTO.FETCH(),
        ActionCreators.CAR_PHOTO.SUCCESS({
            meta: () => ({ referenceNum: params.referenceNum }),
        }),
        ActionCreators.CAR_PHOTO.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CAR_PHOTO({
        carId: params.carId,
        type: params.type,
    }),
    body: {
        carId: params.carId,
        type: params.type,
    },
    method: 'DELETE',
});
export const openFourCornerModal = (fromHeader = false) => (dispatch) => {
    dispatch(ActionCreators.FOUR_CORNER_MODAL.OPEN({ payload: fromHeader }));
};
export const closeFourCornerModal = () => (dispatch) => {
    dispatch(ActionCreators.FOUR_CORNER_MODAL.CLOSE());
};
export const openMobileCarDetails = () => (dispatch) => {
    dispatch(ActionCreators.MOBILE_CAR_DETAILS.OPEN());
};
export const closeMobileCarDetails = () => (dispatch) => {
    dispatch(ActionCreators.MOBILE_CAR_DETAILS.CLOSE());
};
export const fetchAppointmentResolutions = () => (dispatch) => dispatch(createAPIAction({
    types: [
        ActionCreators.APPOINTMENT_RESOLUTIONS.FETCH(),
        ActionCreators.APPOINTMENT_RESOLUTIONS.SUCCESS(),
        ActionCreators.APPOINTMENT_RESOLUTIONS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.APPOINTMENT_RESOLUTIONS(),
    method: 'GET',
}));
export const fetchCancelFeeMaxHours = () => (dispatch) => dispatch(createAPIAction({
    types: [
        ActionCreators.CANCEL_FEE_MAX_HOURS.FETCH(),
        ActionCreators.CANCEL_FEE_MAX_HOURS.SUCCESS(),
        ActionCreators.CANCEL_FEE_MAX_HOURS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CANCEL_FEE_MAX_HOURS(),
    method: 'GET',
}));
export const getRepairMeasurements = (referenceNum) => createAPIAction({
    types: [
        ActionCreators.REPAIR_MEASUREMENTS.FETCH({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.REPAIR_MEASUREMENTS.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.REPAIR_MEASUREMENTS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.REPAIR_MEASUREMENTS(referenceNum),
    method: 'GET',
});
export const updateRepairMeasurements = (referenceNum, body) => {
    const updates = body.map((measurement) => ({
        id: measurement.id,
        value: measurement.value,
    }));
    return createAPIAction({
        types: [
            ActionCreators.REPAIR_MEASUREMENTS.FETCH({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.REPAIR_MEASUREMENTS.SUCCESS({
                meta: () => ({ referenceNum }),
            }),
            ActionCreators.REPAIR_MEASUREMENTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.REPAIR_MEASUREMENTS(referenceNum),
        body: updates,
        method: 'PUT',
    });
};
export const fetchOrderRosterJobQueuePosition = (referenceNum) => createAPIAction({
    types: [
        ActionCreators.ORDER_ROSTER_JOB_QUEUE_POSITION.FETCH(),
        ActionCreators.ORDER_ROSTER_JOB_QUEUE_POSITION.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.ORDER_ROSTER_JOB_QUEUE_POSITION.FAILURE(),
    ],
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/admin/order-roster/jobs/queue-position/${referenceNum}`,
    method: 'GET',
});
export const createWrongPart = (wrongPart) => {
    return createAPIAction({
        types: [
            ActionCreators.CREATE_WRONG_PART.FETCH(),
            ActionCreators.CREATE_WRONG_PART.SUCCESS(),
            ActionCreators.CREATE_WRONG_PART.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.WRONG_PART(),
        body: wrongPart,
        method: 'POST',
    });
};
export const fetchRequestPartPickups = (referenceNum) => createAPIAction({
    types: [
        ActionCreators.REQUEST_PART_PICKUPS.FETCH(),
        ActionCreators.REQUEST_PART_PICKUPS.SUCCESS({
            meta: () => ({ referenceNum }),
        }),
        ActionCreators.REQUEST_PART_PICKUPS.FAILURE(),
    ],
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/repair-requests/pops/repair-order/${referenceNum}`,
    method: 'GET',
});
export const scheduleFutureAppointment = (referenceNum, address, appointmentDate) => createAPIAction({
    types: [
        ActionCreators.SCHEDULE_FUTURE_APPOINTMENT.FETCH(),
        ActionCreators.SCHEDULE_FUTURE_APPOINTMENT.SUCCESS(),
        ActionCreators.SCHEDULE_FUTURE_APPOINTMENT.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.SCHEDULE_FUTURE_APPOINTMENT(referenceNum),
    method: 'POST',
    body: { address, appointmentDate },
});
export const closeServiceDrawer = createAction('CLOSE_SERVICE_DRAWER');
export const openServiceDrawer = createAction('OPEN_SERVICE_DRAWER');
