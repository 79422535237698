import { toEnum } from 'src/models/enums';
export const AuthorizationGroups = toEnum({
    DISPATCHERS: 'Dispatchers',
    ENGINEERING: 'Engineering',
    MANAGERS: 'Managers',
    PRODUCT: 'Product',
    SERVICE_ADVISORS: 'Service Advisors',
    SUPPORT: 'Support',
    SUPPORT_II: 'Support II',
    TECHNICIANS: 'Technicians',
    TERRITORY_MANAGERS: 'Territory Managers',
    PART_SPECIALIST_I: 'Part Specialist I',
    PART_SPECIALIST_II: 'Part Specialist II',
    PORTER: 'Porter',
    PRICE_EDITOR_TECHNICIANS: 'Price Editor Technicians',
});
export default AuthorizationGroups;
