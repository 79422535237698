export var SkeduloResourceTag;
(function (SkeduloResourceTag) {
    SkeduloResourceTag["VAN"] = "Van";
    SkeduloResourceTag["CONSUMER"] = "Consumer";
    SkeduloResourceTag["FLEET"] = "Fleet";
    SkeduloResourceTag["AIR_CONDITIONING_REPAIRS"] = "Air Conditioning Repairs";
    SkeduloResourceTag["BODY_COLLISION_REPAIRS"] = "Body/Collision Repairs";
    SkeduloResourceTag["HYBRID_ELECTRICAL_VEHICLE_REPAIRS"] = "Hybrid/Electrical Vehicle Repairs";
    SkeduloResourceTag["PROGRAMMING_REQUIRED"] = "Programming Required";
    SkeduloResourceTag["SANITIZING_EQUIPMENT_REQUIRED"] = "Sanitizing Equipment Required";
    SkeduloResourceTag["WHEELS_TIRES"] = "Wheels & Tires";
    SkeduloResourceTag["WHEEL_ALIGNMENT"] = "Wheel Alignment";
    SkeduloResourceTag["MAZDA_TAKATA_RECALL_REPAIRS"] = "Mazda Takata Recall Repairs";
    SkeduloResourceTag["DELIVERIES_TO_HUB"] = "Deliveries to hub";
    SkeduloResourceTag["OIL_SKID"] = "Oil Skid";
    SkeduloResourceTag["MERCEDES_BRAKE_BOOSTER_RECALL"] = "Mercedes Brake Booster Recall";
})(SkeduloResourceTag || (SkeduloResourceTag = {}));
export default SkeduloResourceTag;
