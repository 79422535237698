const initialPagination = {
    totalElements: 1,
    last: false,
    first: true,
    size: 20,
    nextPage: 0,
};
const createAPIPaginationHandler = (basePath, pathFromPayload, loadAll) => (state, { payload }) => {
    var _a;
    const newData = pathFromPayload
        ? (_a = payload[pathFromPayload]) !== null && _a !== void 0 ? _a : []
        : payload || [];
    return Object.assign(Object.assign({}, state), { [basePath]: {
            data: newData,
            requesting: false,
            errors: [],
            pagination: loadAll
                ? initialPagination
                : {
                    totalElements: payload.totalElements,
                    first: payload.first,
                    last: payload.last,
                    size: payload.size,
                    page: payload.number,
                    nextPage: payload.number + 1,
                },
        } });
};
export { createAPIPaginationHandler, initialPagination };
