import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { FormControl, Grid, InputLabel, MenuItem, Select, } from 'src/components/mui';
import { SimpleModal } from 'src/components/layout';
import { TextField } from 'src/AdminApp/components/form';
import { LabeledGridField } from 'src/AdminApp/components/repairs/statement/styledComponents';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { MeasurementProcess } from 'src/PricingApp/models/enums/MeasurementProcess';
const MeasurementModal = ({ open, onClose, onSubmit, }) => {
    const MeasurementSchema = Yup.object().shape({
        name: Yup.string().required('Name is required').min(1).max(255),
        autointegrateType: Yup.string().required().min(1).max(255),
    });
    return (<SimpleModal title="Add New Measurement" open={open} onClose={onClose} maxWidth="md">
      <Formik initialValues={{
            name: '',
            autointegrateType: '',
        }} validationSchema={MeasurementSchema} onSubmit={(values, { setSubmitting, resetForm }) => {
            onSubmit(Object.assign({}, values)).then(() => {
                resetForm({ values: Object.assign({}, values) });
                setSubmitting(false);
            });
        }}>
        {({ handleSubmit, isValid, handleChange }) => (<Grid container>
            <LabeledGridField label="Name">
              <TextField type="string" name="name" fullWidth/>
            </LabeledGridField>
            <LabeledGridField label="Process">
              <FormControl fullWidth>
                <InputLabel>Process</InputLabel>
                <Select label="Process" name="autointegrateType" onChange={handleChange}>
                  {Object.keys(MeasurementProcess)
                .filter((v) => isNaN(Number(v)))
                .map((process) => (<MenuItem value={process}>{process}</MenuItem>))}
                </Select>
              </FormControl>
            </LabeledGridField>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
              <SimpleTwoButtonRow disabledSubmit={!isValid} submitText="Save" onCancel={onClose} onSubmit={handleSubmit}/>
            </Grid>
          </Grid>)}
      </Formik>
    </SimpleModal>);
};
export default MeasurementModal;
