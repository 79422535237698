import { handleActions } from 'redux-actions';
import { ActionCreators, } from './actions';
const DEFAULT_STATE = {
    arrivalGracePeriodMinutes: undefined,
};
const getArrivalGracePeriodConfigSuccess = (state, action) => {
    const { value } = action.payload;
    return Object.assign(Object.assign({}, state), { arrivalGracePeriodMinutes: value ? parseInt(value, 10) : undefined });
};
const reducers = handleActions({
    [ActionCreators.GET_ARRIVAL_GRACE_PERIOD_CONFIG.SUCCESS.type]: getArrivalGracePeriodConfigSuccess,
}, DEFAULT_STATE);
export default reducers;
