var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import createDefaultActionCreators, { createActionCreators, } from 'src/utils/createActions';
import CONFIG from 'src/config';
import CONSTANTS from 'src/constants';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createAction from 'src/modules/api/utils/createAction';
import moment from 'moment';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
export const ActionCreators = {
    GET_VAN_VENDORS: createDefaultActionCreators('POPS_GET_VAN_VENDORS'),
    SEARCH_PARTS_DISTRIBUTOR_LOCATIONS: createDefaultActionCreators('SEARCH_PARTS_DISTRIBUTOR_LOCATIONS'),
    POPS_GET_CATALOG_PARTS: createDefaultActionCreators('POPS_GET_CATALOG_PARTS'),
    POPS_GET_CATALOG_FLUIDS: createDefaultActionCreators('POPS_GET_CATALOG_FLUIDS'),
    POPS_GET_CATALOG_PARTS_LOOKUP: createDefaultActionCreators('POPS_GET_CATALOG_PARTS_LOOKUP'),
    GET_RS_HUBS: createDefaultActionCreators('GET_RS_HUBS'),
    GET_PART_DISTRIBUTORS: createDefaultActionCreators('GET_PART_DISTRIBUTORS'),
    SEARCH_PART_DISTRIBUTORS: createDefaultActionCreators('SEARCH_PART_DISTRIBUTORS'),
    SEARCH_PART_DISTRIBUTOR_LOCATIONS_BY_ADDRESS: createDefaultActionCreators('SEARCH_PART_DISTRIBUTOR_LOCATIONS_BY_ADDRESS'),
    GET_VAN_HOME_STORES: createDefaultActionCreators('GET_VAN_HOME_STORES'),
    GET_VAN_PART_PICKUPS: createDefaultActionCreators('GET_VAN_PART_PICKUPS'),
    HOME_STORES_AND_PICKUPS: createActionCreators(['CLEAR'], 'HOME_STORES_AND_PICKUPS'),
};
export const resetRecentVendors = createAction('RESET_RECENT_VENDORS');
export const searchPartsDistributorLocations = ({ latitude, longitude, catalogEnabled = false, partDistributorId, radius = 100, size = 20, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.SEARCH_PARTS_DISTRIBUTOR_LOCATIONS),
    endpoint: `${CONFIG.CLIENT_API_URL}/pricing-service/admin/part-distributor-locations/search`,
    method: 'GET',
    params: {
        latitude,
        longitude,
        catalogEnabled,
        partDistributorId,
        radius,
        size,
    },
});
export const getPartDistributors = () => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_PART_DISTRIBUTORS),
    endpoint: `${CONFIG.CLIENT_API_URL}/pricing-service/admin/part-distributors`,
    method: 'GET',
    params: {
        unpaged: true,
    },
});
export const searchPartDistributors = (name) => createAPIAction({
    types: fillApiTypes(ActionCreators.SEARCH_PART_DISTRIBUTORS),
    endpoint: `${CONFIG.CLIENT_API_URL}/pricing-service/admin/part-distributors/search`,
    method: 'GET',
    params: {
        name,
    },
});
export const searchPartDistributorLocationsByAddress = ({ partialAddress, dealershipName, partDistributorId, }) => {
    const params = dealershipName
        ? {
            address: partialAddress,
            dealershipName,
            partDistributorId,
        }
        : {
            address: partialAddress,
            partDistributorId,
        };
    return createAPIAction({
        types: fillApiTypes(ActionCreators.SEARCH_PART_DISTRIBUTOR_LOCATIONS_BY_ADDRESS),
        endpoint: `${CONFIG.CLIENT_API_URL}/pricing-service/admin/part-distributor-locations/search/address`,
        method: 'GET',
        params,
    });
};
export const resetPartDistributorLocationsByAddressSearchResults = createAction('RESET_PART_DISTRIBUTOR_LOCATION_BY_ADDRESS_SEARCH_RESULTS');
export const resetVendorsSearchResults = createAction('RESET_VENDORS_SEARCH_RESULTS');
export const setVehicleLocation = createAction('SET_VEHICLE_LOCATION');
export const resetVendors = createAction('RESET_VENDORS');
export const setComponentsAvailability = createAction('SET_COMPONENTS_AVAILABILITY');
export const updateVendorSearch = createAction('UPDATE_VENDOR_SEARCH');
export const resetCatalogParts = createAction('RESET_CATALOG_PARTS');
export const resetCatalogFluids = createAction('RESET_CATALOG_FLUIDS');
export const getCatalogParts = ({ car, locationId, productIds }, jobCar) => {
    const params = Object.assign(Object.assign({}, car), { partDistributorLocationId: locationId, productIds });
    return createAPIAction({
        types: [
            ActionCreators.POPS_GET_CATALOG_PARTS.FETCH(),
            ActionCreators.POPS_GET_CATALOG_PARTS.SUCCESS({
                meta: () => ({ locationId, jobCar, productIds }),
            }),
            ActionCreators.POPS_GET_CATALOG_PARTS.FAILURE({
                meta: () => ({ locationId, jobCar, productIds }),
            }),
        ],
        endpoint: `${CONFIG.CLIENT_API_URL}/pricing-service/available-parts`,
        method: 'GET',
        params,
    });
};
export const getCatalogPartsLookup = (car, partNumber, repairComponentId) => {
    const params = {
        year: car.carYear,
        make: car.make,
        model: car.model,
        trim: car.carTrim,
        engineId: car.engineId,
        partNumber,
        repairComponentId,
    };
    return createAPIAction({
        types: [
            ActionCreators.POPS_GET_CATALOG_PARTS_LOOKUP.FETCH(),
            ActionCreators.POPS_GET_CATALOG_PARTS_LOOKUP.SUCCESS({
                meta: () => (Object.assign(Object.assign({}, car), { repairComponentId })),
            }),
            ActionCreators.POPS_GET_CATALOG_PARTS_LOOKUP.FAILURE(),
        ],
        endpoint: `${CONFIG.CLIENT_API_URL}/pricing-service/catalog/parts-lookup`,
        method: 'GET',
        params,
    });
};
export const getCatalogFluids = ({ car, locationId, productIds }, jobCar) => {
    const params = Object.assign(Object.assign({}, car), { partDistributorLocationId: locationId, productIds });
    return createAPIAction({
        types: [
            ActionCreators.POPS_GET_CATALOG_FLUIDS.FETCH(),
            ActionCreators.POPS_GET_CATALOG_FLUIDS.SUCCESS({
                meta: () => ({ locationId, jobCar, productIds }),
            }),
            ActionCreators.POPS_GET_CATALOG_FLUIDS.FAILURE({
                meta: () => ({ locationId, jobCar, productIds }),
            }),
        ],
        endpoint: `${CONFIG.CLIENT_API_URL}/pricing-service/available-fluids`,
        method: 'GET',
        params,
    });
};
export const getCatalogComponents = (car, locationId, items) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const carDetails = {
        year: car.carYear,
        make: car.make,
        model: car.model,
        vin: car.vin,
        engineId: car.engineId,
        trim: car.trim,
    };
    if (car.carTrim && car.carTrim !== CONSTANTS.TRIM_DONT_KNOW) {
        carDetails.trim = car.carTrim;
    }
    if (car.engineId && car.engineId !== CONSTANTS.ENGINE_DONT_KNOW) {
        carDetails.engineId = car.engineId;
    }
    const promises = [];
    const partParams = {
        car: carDetails,
        locationId,
        productIds: items
            .filter((item) => item.part)
            .map((item) => item.productId),
    };
    if (partParams.productIds.length > 0) {
        promises.push(dispatch(getCatalogParts(partParams, car)));
    }
    const fluidParams = {
        car: carDetails,
        locationId,
        productIds: items
            .filter((item) => item.fluid)
            .map((item) => item.productId),
    };
    if (fluidParams.productIds.length > 0) {
        promises.push(dispatch(getCatalogFluids(fluidParams, car)));
    }
    yield Promise.all(promises);
});
export const getCatalogPartsFromLookup = (car, partNumber, repairComponentId) => {
    const carDetails = {
        year: car.carYear,
        make: car.make,
        model: car.model,
        vin: car.vin,
        engineId: car.engineId,
        trim: car.trim,
    };
    if (car.carTrim && car.carTrim !== CONSTANTS.TRIM_DONT_KNOW) {
        carDetails.trim = car.carTrim;
    }
    if (car.engineId && car.engineId !== CONSTANTS.ENGINE_DONT_KNOW) {
        carDetails.engineId = car.engineId;
    }
    return getCatalogPartsLookup(car, partNumber, repairComponentId);
};
export const getVanHomeStores = ({ shopId }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_VAN_HOME_STORES),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/repair-requests/pops/by-vans/${shopId}/home-stores`,
    method: 'GET',
});
export const getVanPartPickups = ({ shopId, date, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_VAN_PART_PICKUPS),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/repair-requests/pops/by-vans/${shopId}/part-pickups`,
    method: 'GET',
    params: { date: moment(date).format(DATE_YMD_FORMAT) },
});
