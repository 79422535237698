import { Dialog, DialogContent, IconButton } from 'src/components/mui';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const PartnerDetailsDialogContainer = styled(Dialog) `
  max-width: 845px;
  margin: auto;
`;
export const PartnerDetailsDialogContent = styled(DialogContent) `
  position: relative;
  padding: 0;

  &:first-child {
    padding-top: 0;
  }
`;
export const CloseButton = styled(IconButton) `
  position: absolute;
  top: 0;
  right: 0;
  color: ${colors.GRAY_71};
`;
