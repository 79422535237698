import { Button } from 'src/components/mui';
import React from 'react';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
import { buttonStyleBase } from '../Buttons.styles';
const StyledSecondaryButton = styled(Button) `
  ${buttonStyleBase};
  border-color: ${colors.linkBlue};
  color: ${colors.linkBlue};
  width: 100%;

  &:hover {
    border-color: ${colors.linkBlue};
  }
`;
/**
 * Used for non-destructive actions in SimpleModal
 */
const SecondaryButton = (props) => (<StyledSecondaryButton variant="outlined" {...props}/>);
export default SecondaryButton;
