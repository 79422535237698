var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sortBy } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiblePartyTypeVisibility, } from 'src/AdminApp/models/api';
import { getResponsiblePartyTypes, getResponsiblePartiesByType, } from 'src/AdminApp/modules/requests/actions';
import { selectResponsiblePartyTypes } from 'src/AdminApp/modules/requests/selectors';
var OptionGroup;
(function (OptionGroup) {
    OptionGroup["ASSIGNED_TO_VIN"] = "Assigned to VIN";
    OptionGroup["ASSIGNED_TO_ACCOUNT"] = "Assigned to Account";
    OptionGroup["INTERNAL"] = "Internal";
})(OptionGroup || (OptionGroup = {}));
const optionsPriority = {
    [OptionGroup.ASSIGNED_TO_VIN]: 0,
    [OptionGroup.ASSIGNED_TO_ACCOUNT]: 1,
    [OptionGroup.INTERNAL]: 2,
};
const useResponsiblePartySelect = ({ fleet, vehicle, grouped, showRpTypeSuffix, }) => {
    const [availableFleetResponsibleParties, setAvailableFleetResponsibleParties,] = useState();
    const responsiblePartyTypes = useSelector(selectResponsiblePartyTypes);
    const dispatch = useDispatch();
    const getGroupName = (responsiblePartyId) => {
        var _a;
        const fleetResponsibleParties = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties;
        const isRpFromVehicle = fleetResponsibleParties === null || fleetResponsibleParties === void 0 ? void 0 : fleetResponsibleParties.find((vrp) => vrp.cars.some((car) => car.id === (vehicle === null || vehicle === void 0 ? void 0 : vehicle.consumerCarId)) &&
            vrp.responsiblePartyId === responsiblePartyId);
        if (isRpFromVehicle) {
            return OptionGroup.ASSIGNED_TO_VIN;
        }
        const isRpFromFleet = !!((_a = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) === null || _a === void 0 ? void 0 : _a.find((frp) => frp.responsiblePartyId === responsiblePartyId));
        return isRpFromFleet
            ? OptionGroup.ASSIGNED_TO_ACCOUNT
            : OptionGroup.INTERNAL;
    };
    const getTransformedResponsibleParties = (response) => response === null || response === void 0 ? void 0 : response.map((el) => {
        const responsiblePartyType = responsiblePartyTypes === null || responsiblePartyTypes === void 0 ? void 0 : responsiblePartyTypes.find((rpt) => rpt.id === (el === null || el === void 0 ? void 0 : el.responsiblePartyTypeId));
        const applyGrouping = !!fleet || grouped;
        const groupName = applyGrouping ? getGroupName(el.id) : undefined;
        const response = Object.assign(Object.assign({}, el), { responsiblePartyType });
        if (showRpTypeSuffix) {
            response['suffix'] = responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.name;
        }
        if (groupName) {
            response['groupName'] = groupName;
            response['orderPriority'] = optionsPriority[groupName];
        }
        return response;
    });
    const fetchAllResponsiblePartiesByType = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!responsiblePartyTypes || (responsiblePartyTypes === null || responsiblePartyTypes === void 0 ? void 0 : responsiblePartyTypes.length) === 0) {
            yield dispatch(getResponsiblePartyTypes());
        }
        const promises = responsiblePartyTypes.map((rpt) => {
            return dispatch(getResponsiblePartiesByType({
                responsiblePartyTypeId: rpt.id,
            }));
        });
        const response = yield Promise.all(promises);
        if (response && (response === null || response === void 0 ? void 0 : response.length) > 0) {
            const transformedResponse = response.reduce((acc, el) => {
                if (el === null || el === void 0 ? void 0 : el.payload) {
                    acc.push(...el.payload);
                }
                return acc;
            }, []);
            if ((transformedResponse === null || transformedResponse === void 0 ? void 0 : transformedResponse.length) > 0) {
                const responsibleParties = getTransformedResponsibleParties(transformedResponse).filter((rp) => {
                    var _a, _b, _c;
                    if (((_a = rp === null || rp === void 0 ? void 0 : rp.groupName) === null || _a === void 0 ? void 0 : _a.includes(OptionGroup.INTERNAL)) || !(rp === null || rp === void 0 ? void 0 : rp.groupName)) {
                        return (_c = (_b = rp === null || rp === void 0 ? void 0 : rp.responsiblePartyType) === null || _b === void 0 ? void 0 : _b.visibility) === null || _c === void 0 ? void 0 : _c.includes(!!fleet
                            ? ResponsiblePartyTypeVisibility.FLEET
                            : ResponsiblePartyTypeVisibility.CONSUMER);
                    }
                    return true;
                });
                const sorted = sortBy(responsibleParties, [
                    'orderPriority',
                    'suffix',
                    'name',
                ]);
                setAvailableFleetResponsibleParties([...sorted]);
            }
        }
    });
    return {
        fetchAllResponsiblePartiesByType,
        availableFleetResponsibleParties,
    };
};
export default useResponsiblePartySelect;
