import React from 'react';
import styled from 'styled-components';
const Container = styled.div `
  h3 {
    font-weight: 700;
    font-size: 14px;
    padding: 0;
    margin: 0 0 4px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }
`;
const Message = ({ message, title }) => {
    return (<Container className="MessageContainer__textWrapper">
      {title && <h3>{title}</h3>}
      <p>{message}</p>
    </Container>);
};
export default Message;
