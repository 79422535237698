import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { mapClientShopInvoiceMatchArray } from 'src/AdminApp/modules/mappers';
import { SalesMatchDateFilterType, SalesMatchShowFilterType, } from 'src/AdminApp/modules/salesMatch/salesMatch.state';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    dashboard: {
        params: {
            filters: {
                start: undefined,
                end: undefined,
                shopId: undefined,
                repairRequestStatus: undefined,
                repairRequestState: undefined,
                dateFilterType: SalesMatchDateFilterType.Invoice,
                showFilterType: SalesMatchShowFilterType.Matches,
            },
        },
        data: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            offset: 0,
            sort: ['shopName,asc'],
        },
        _isFetching: false,
        _isDownloading: false,
    },
    filtersPanel: {
        expanded: false,
        dateFilterType: SalesMatchDateFilterType.Invoice,
        showFilterType: SalesMatchShowFilterType.Matches,
    },
    summaryInfo: {
        totalShops: 0,
        discovered: 0,
        revenue: 0,
        shops: [],
        repairRequestStatuses: [],
        repairRequestStates: [],
    },
};
const successSalesMatchDashboardReducer = (state, action) => {
    var _a, _b, _c, _d, _e;
    const data = mapClientShopInvoiceMatchArray((_c = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.clientShopInvoiceMatches) !== null && _c !== void 0 ? _c : []);
    const summaryInfo = (_e = (_d = action.payload) === null || _d === void 0 ? void 0 : _d.data.clientShopInvoiceMatchSummaryInfo) !== null && _e !== void 0 ? _e : null;
    const { filters, sort, pageIndex, pageSize } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: fetched(Object.assign(Object.assign({}, state.dashboard), { params: Object.assign(Object.assign({}, state.dashboard.params), { filters }), data, pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { pageSize, pageNumber: pageIndex, totalElements: summaryInfo.discovered, sort }), _isFetching: false })), summaryInfo });
};
const fetchSalesMatchDashboardReducer = (state, action) => {
    const { filters } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params: Object.assign(Object.assign({}, state.dashboard.params), { filters }), _isFetching: true }), filtersPanel: Object.assign(Object.assign({}, state.filtersPanel), {
            dateFilterType: filters.dateFilterType,
            showFilterType: filters.showFilterType,
        }) });
};
const failureSalesMatchDashboardReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { _isFetching: false }) });
};
const clearSalesMatchDashboardReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const successSalesMatchSummaryInfoReducer = (state, action) => {
    var _a, _b, _c;
    const summaryInfo = (_c = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.clientShopInvoiceMatchSummaryInfo) !== null && _c !== void 0 ? _c : null;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { totalElements: summaryInfo.discovered }) }), summaryInfo });
};
const successSalesMatchDeleteReducer = (state, action) => {
    var _a;
    const { salesMatchId } = action.meta;
    const data = action.payload.data
        ? (_a = state.dashboard.data) === null || _a === void 0 ? void 0 : _a.filter((x) => x.id !== salesMatchId)
        : state.dashboard.data;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { data, pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { totalElements: state.dashboard.pagination.totalElements - 1 }) }) });
};
const successSalesMatchVerifyReducer = (state, action) => {
    var _a, _b, _c;
    const { salesMatchId } = action.meta;
    const { data } = state.dashboard;
    if (action.payload.data) {
        const index = data.findIndex((x) => x.id === salesMatchId);
        if (index > -1) {
            data[index].validationStatus =
                (_c = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.verifyInvoice) === null || _c === void 0 ? void 0 : _c.validationStatus;
        }
    }
    return Object.assign(Object.assign({}, state), { dashboard: fetched(Object.assign(Object.assign({}, state.dashboard), { data, _isFetching: false })) });
};
const successSalesMatchUpdateReducer = (state, action) => {
    const { salesMatchId, repairRequestId } = action.meta;
    const { data } = state.dashboard;
    if (action.payload.data) {
        const index = data.findIndex((x) => x.id === salesMatchId);
        if (index !== -1) {
            data[index].repairRequestId = repairRequestId;
        }
    }
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { data, pagination: Object.assign({}, state.dashboard.pagination) }) });
};
const setFiltersPanel = (state, action) => {
    const { filtersPanel } = action.meta;
    return Object.assign(Object.assign({}, state), { filtersPanel: Object.assign(Object.assign({}, state.filtersPanel), filtersPanel) });
};
const stopSalesMatchExportReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { _isDownloading: false }) });
};
const fetchSalesMatchExportReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { _isDownloading: true }) });
};
const reducers = handleActions({
    [ActionCreators.SALES_MATCH.SUCCESS.type]: successSalesMatchDashboardReducer,
    [ActionCreators.SALES_MATCH.FETCH.type]: fetchSalesMatchDashboardReducer,
    [ActionCreators.SALES_MATCH.FAILURE.type]: failureSalesMatchDashboardReducer,
    [ActionCreators.SALES_MATCH.CLEAR.type]: clearSalesMatchDashboardReducer,
    [ActionCreators.SALES_MATCH_UPDATE.SUCCESS.type]: successSalesMatchUpdateReducer,
    [ActionCreators.SALES_MATCH_DELETE.SUCCESS.type]: successSalesMatchDeleteReducer,
    [ActionCreators.SALES_MATCH_SET_FILTERS_PANEL.SUCCESS.type]: setFiltersPanel,
    [ActionCreators.SALES_MATCH_VERIFY_INVOICE.SUCCESS.type]: successSalesMatchVerifyReducer,
    [ActionCreators.SALES_MATCH_SUMMARY_INFO.SUCCESS.type]: successSalesMatchSummaryInfoReducer,
    [ActionCreators.SALES_MATCH_EXPORT_CSV.SUCCESS.type]: stopSalesMatchExportReducer,
    [ActionCreators.SALES_MATCH_EXPORT_CSV.FETCH.type]: fetchSalesMatchExportReducer,
    [ActionCreators.SALES_MATCH_EXPORT_CSV.FAILURE.type]: stopSalesMatchExportReducer,
}, DEFAULT_STATE);
export default reducers;
