import React from 'react';
import RadioFormTemplate from 'src/AdminApp/containers/requests/StateTracker/RadioFormTemplate';
import { enumToArray } from 'src/models/enums';
import { RepairOrderResolution } from 'src/AdminApp/models/enums';
import { SimpleDrawer, SimpleDrawerHeader } from 'src/components/SimpleDrawer';
const RepairOrderResolutionDrawer = ({ open, onClose, onSubmit, referenceNum, workOrderId, }) => {
    return (<SimpleDrawer open={open} onClose={onClose}>
      <SimpleDrawerHeader title="Repair Resolution" onClose={onClose}/>

      <RadioFormTemplate paramName="orderResolution" params={{ referenceNum, workOrderId }} onClose={onClose} submitForm={onSubmit} options={enumToArray(RepairOrderResolution)}/>
    </SimpleDrawer>);
};
export default RepairOrderResolutionDrawer;
