import React from 'react';
import ReactMarkdown from 'react-markdown';
import { STATIC_URL_BASE } from 'src/styles/theme';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { CantFinishCopy, CantFinishWrapperHeader, Subheader, } from '../CantFinishDrawer.styles';
const CantFinishTryThisDrawer = ({ onClose, text, onCallIneligibleOrConditionPoorOrAddtlRepairNoOption, referenceNum, cantFinishOptionChosen, }) => {
    const pitchCopy = text;
    return (<>
      <SimpleDrawerContent>
        <CantFinishWrapperHeader>
          <span>
            <span>Try this!</span>
            <Subheader>(or make it your own)</Subheader>
          </span>
          <img src={`${STATIC_URL_BASE}tools/speech-bubble.svg`} alt="speech bubble"/>
        </CantFinishWrapperHeader>
        <CantFinishCopy>
          <ReactMarkdown>Hello \[Customer's Name\],</ReactMarkdown>
        </CantFinishCopy>
        <CantFinishCopy>
          <ReactMarkdown>{pitchCopy}</ReactMarkdown>
        </CantFinishCopy>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={() => {
            onCallIneligibleOrConditionPoorOrAddtlRepairNoOption(referenceNum, cantFinishOptionChosen);
            onClose();
        }}>
          Got it
        </FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default CantFinishTryThisDrawer;
