import { createSelector } from 'reselect';
import { getSorted } from 'src/AdminApp/utils/selectorsUtils';
export const selectInstallations = (state) => state.admin.installations;
export const selectDashboard = createSelector(selectInstallations, (installations) => { var _a; return (_a = installations.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardInstallations = createSelector(selectDashboard, (dashboard) => {
    var _a, _b, _c, _d;
    return getSorted((_a = dashboard.content) !== null && _a !== void 0 ? _a : [], (_d = ((_c = (_b = dashboard === null || dashboard === void 0 ? void 0 : dashboard.pagination) === null || _b === void 0 ? void 0 : _b.sort) !== null && _c !== void 0 ? _c : [])[0]) !== null && _d !== void 0 ? _d : '');
});
export const selectDashboardPagination = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardParams = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardIsFetching = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
