var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Field } from 'formik';
import { FormControl, Select, MenuItem, InputLabel } from 'src/components/mui';
const createYearsMenuItems = () => {
    const menuItems = [
        <MenuItem value="" key="unset">
      &nbsp;
    </MenuItem>,
    ];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1950; i -= 1) {
        menuItems.push(<MenuItem value={i} key={i}>
        {i}
      </MenuItem>);
    }
    return menuItems;
};
const YearSelect = (_a) => {
    var { label = '', name = '' } = _a, rest = __rest(_a, ["label", "name"]);
    const inputLabel = React.useRef(null);
    return (<Field name={name}>
      {({ field }) => {
            return (<FormControl fullWidth>
            <InputLabel ref={inputLabel}>{label}</InputLabel>
            <Select {...field} {...rest} inputProps={{ name, id: name }}>
              {createYearsMenuItems()}
            </Select>
          </FormControl>);
        }}
    </Field>);
};
export default YearSelect;
