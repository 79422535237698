import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthorizedTechnician, selectAuthorizedTechDashboardRedirect } from 'src/modules/auth/selectors';
const MyHome = () => {
    const isTechnician = useSelector(selectAuthorizedTechnician);
    const hasTechDashboardRedirect = useSelector(selectAuthorizedTechDashboardRedirect);
    if (isTechnician && hasTechDashboardRedirect) {
        return <Redirect to={{ pathname: '/tech/my-dashboard' }}/>;
    }
    return <Redirect to={{ pathname: '/' }}/>;
};
export default MyHome;
