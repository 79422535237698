import styled, { css } from 'styled-components';
import { colors } from 'src/styles/theme';
import { IconButton } from 'src/components/mui';
export const DrawerStyledHeaderTitle = styled.div `
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  letter-spacing: 0.42px;
  color: ${colors.midnightBlue};
  text-transform: capitalize;
  text-align: center;
  max-width: 91%;
`;
export const DrawerStyledHeader = styled.div `
  padding: 20px 20px 0 20px;
  position: relative;
  z-index: 10;
`;
const drawerHeaderButtonBase = css `
  position: absolute;
  top: 12px;
  width: 32px;
  height: 32px;
  color: ${colors.concrete};
  background-color: ${colors.lightSilver};
  border-radius: 50%;

  svg {
    font-size: 1.3em;
    padding: 5px;
  }
`;
export const DrawerCloseButton = styled(IconButton) `
  right: 12px;
  ${drawerHeaderButtonBase};
`;
export const DrawerBackButton = styled(IconButton) `
  left: 12px;
  ${drawerHeaderButtonBase};
`;
