const getLocationString = address => {
    if (!address)
        return '';
    const location = [
        address.city,
        address.state,
        address.zipCode,
    ];
    return location.filter(Boolean).join(', ');
};
const getAddressString = address => {
    if (!address)
        return '';
    const location = [
        address.street,
        getLocationString(address),
    ];
    return location.filter(Boolean).join(', ');
};
export { getAddressString, getLocationString, };
