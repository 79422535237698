import React from 'react';
import { Alert } from 'src/components/mui';
import styled from 'styled-components';
const StyledAlert = styled(Alert) `
  margin: 5px;
`;
const LocalWebhookInfo = () => {
    return (<StyledAlert severity="info">
      Setup ngrok in order to receive the webhooks on your local environment.
      See{' '}
      <span>
        <a href="https://gitlab.com/servus-venture/services/integration-clients/-/tree/master/skedulo-client/src/scripts/webhooks" target="_blank" rel="noreferrer">
          README
        </a>
      </span>{' '}
      for more details.
    </StyledAlert>);
};
export default LocalWebhookInfo;
