const createAction = (type) => {
    const actionCreator = (payload, meta) => ({
        type,
        payload,
        meta,
    });
    actionCreator.type = type;
    return actionCreator;
};
export default createAction;
