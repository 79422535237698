import React from 'react';
const VanSkills = ({ vanSkills, }) => {
    const skills = vanSkills.map((skill) => skill.skill.name);
    return (<div style={{
            whiteSpace: 'pre',
        }}>
      <b>Skills</b> <br />
      {skills.join('\n')}
    </div>);
};
export default VanSkills;
