import React from 'react';
import { useHistory } from 'react-router';
import SimpleChip from 'src/components/SimpleChip';
import { colors } from 'src/styles/theme';
const greenColorStatuses = ['ORDERED', 'IN_STOCK', 'NOT_NEEDED', 'PICKED_UP'];
export const PartPickupChip = ({ referenceNum }) => {
    const history = useHistory();
    return (<SimpleChip $bgColor={colors.lightBlue} $textColor={colors.white} label="Part Pickup" onClick={() => {
            history.push(`/pops/part-pickups/${referenceNum}`);
        }}/>);
};
const PartStatusChip = ({ status }) => status ? (<SimpleChip $bgColor={greenColorStatuses.includes(status) ? colors.success : colors.darkGrey} $textColor={colors.white} label={status.replace(/_/g, ' ')}/>) : null;
export default PartStatusChip;
