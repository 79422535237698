var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openSlidePanel } from 'src/AdminApp/modules/geoManagement/actions';
import { archiveRegion, getRegionById, getRegionsByFilters, updateRegion, } from 'src/AdminApp/modules/regions/actions';
import { selectDashboardPagination as selectRegionsDashboardPagination, selectDashboardParams as selectRegionsDashboardParams, } from 'src/AdminApp/modules/regions/selectors';
import { archiveServiceArea, getServiceAreaById, updateServiceArea, } from 'src/AdminApp/modules/serviceAreas/actions';
import { toast } from 'src/components/SimpleToast';
import { addLocationParent, removeLocationParent, } from 'src/SystemApp/modules/locations/actions';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const useRegionListItem = ({ item }) => {
    const { id } = item;
    const dispatch = useDispatch();
    const regionsPagination = useSelector(selectRegionsDashboardPagination);
    const regionsParams = useSelector(selectRegionsDashboardParams);
    const refreshRegions = () => {
        var _a, _b, _c;
        return dispatch(getRegionsByFilters(Object.assign(Object.assign({}, regionsParams), { pagination: {
                size: (_a = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                page: (_b = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.pageNumber) !== null && _b !== void 0 ? _b : 0,
                sort: (_c = regionsPagination === null || regionsPagination === void 0 ? void 0 : regionsPagination.sort) !== null && _c !== void 0 ? _c : ['name', 'asc'],
            } })));
    };
    const getRegion = (uid) => dispatch(getRegionById(uid));
    const getServiceArea = (uid) => dispatch(getServiceAreaById(uid));
    const onUpdateRegion = (model, originalHubs) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const response = yield dispatch(updateRegion(model));
        if (response.error) {
            return;
        }
        // ADD NEW HUBS
        const updatePromises = [];
        (_a = model.hubs) === null || _a === void 0 ? void 0 : _a.forEach((hub) => __awaiter(void 0, void 0, void 0, function* () {
            var _b;
            if (!((_b = hub.parentIds) === null || _b === void 0 ? void 0 : _b.includes(model.id))) {
                updatePromises.push(dispatch(addLocationParent(hub.id, model.id)));
            }
        }));
        // REMOVE OLD HUBS
        originalHubs === null || originalHubs === void 0 ? void 0 : originalHubs.forEach((hub) => __awaiter(void 0, void 0, void 0, function* () {
            var _c;
            if (!((_c = model.hubs) === null || _c === void 0 ? void 0 : _c.find((h) => h.id === hub.id))) {
                updatePromises.push(dispatch(removeLocationParent(hub.id, model.id)));
            }
        }));
        const responses = yield Promise.all(updatePromises);
        const hasError = responses.some((r) => r.error);
        if (!hasError) {
            toast.success('Region updated successfully');
        }
        refreshRegions();
    });
    const onRemoveRegion = ({ id, transferRegionId, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(archiveRegion({ id, transferRegionId }));
        refreshRegions();
    });
    const onUpdateServiceArea = (model) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(updateServiceArea(model));
        refreshRegions();
        return response;
    });
    const onRemoveServiceArea = ({ id, archiveDate, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(archiveServiceArea({
            id,
            archiveDate,
        }));
        refreshRegions();
    });
    const editRegion = () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield getRegion(id);
        const region = result.payload;
        return dispatch(openSlidePanel({
            onSaveRegion: onUpdateRegion,
            onRemoveRegion,
            regionModel: region,
            selectedPage: 'region',
        }));
    });
    const editServiceArea = (sa) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield getServiceArea(sa.id);
        const serviceArea = result.payload;
        return dispatch(openSlidePanel({
            onRemoveServiceArea,
            serviceAreaModel: serviceArea,
            selectedPage: 'serviceArea',
        }));
    });
    const renderName = (text, isArchived) => {
        if (isArchived) {
            return <span style={{ color: 'red' }}>{text} (Archived)</span>;
        }
        return <span>{text}</span>;
    };
    const renderServiceAreaName = (text, archiveDate) => {
        if (archiveDate) {
            const isArchived = archiveDate && new Date(archiveDate) < new Date();
            if (isArchived) {
                return <span style={{ color: 'red' }}>{text} (Archived)</span>;
            }
            const archiveDateString = moment(archiveDate).format(LOCALIZED_DATE_FORMAT);
            return (<span>
          {text} (Archiving: {archiveDateString})
        </span>);
        }
        return <span>{text}</span>;
    };
    return {
        refreshRegions,
        getRegion,
        getServiceArea,
        onUpdateRegion,
        onRemoveRegion,
        onUpdateServiceArea,
        onRemoveServiceArea,
        editRegion,
        editServiceArea,
        renderName,
        renderServiceAreaName,
    };
};
export default useRegionListItem;
