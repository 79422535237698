import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import useIsMobile from 'src/hooks/useIsMobile';
import { ActionsBlockMobile } from './ActionsBlock.mobile';
import { ActionsBlockDesktop } from './ActionsBlock.desktop';
export const ActionsBlock = ({ actions, children, childAtEnd, childAtStart, }) => {
    const isMobile = useIsMobile();
    const [el, setEl] = useState(null);
    useEffect(() => {
        window.setTimeout(() => {
            setEl(document.querySelector('.CTA-render-portal'));
        }, 0);
    }, [isMobile]);
    if (el) {
        return ReactDOM.createPortal(isMobile ? (<ActionsBlockMobile actions={actions}/>) : (<ActionsBlockDesktop actions={actions} childAtEnd={childAtEnd} childAtStart={childAtStart}>
          {children}
        </ActionsBlockDesktop>), el);
    }
    return null;
};
