import AuditInfo from 'src/AdminApp/components/users/AuditInfo';
import { WorkAuthorizationMethod } from 'src/AdminApp/models/enums';
import React from 'react';
import { InfoWrapper, Info, InfoText } from './styles';
const InfoStatus = ({ authorizationMethod, authorizationName, authorizedAt, authorizedBy, providedAt, providedBy, }) => {
    if (!authorizedAt) {
        return (<InfoWrapper>
        <Info>
          Last Authorized:
          <InfoText> Work Not Authorized</InfoText>
        </Info>
      </InfoWrapper>);
    }
    return (<InfoWrapper>
      {authorizedAt && authorizedBy && (<>
          <AuditInfo auditType="AUTHORIZED" auditedTimestamp={authorizedAt} auditedBy={authorizedBy}/>
          <Info>
            Authorized By:
            <InfoText>
              {` ${authorizationName} (Method: ${WorkAuthorizationMethod.display(authorizationMethod)})`}
            </InfoText>
          </Info>
        </>)}
      {providedAt && providedBy && (<AuditInfo auditType="INVOICE_SENT" auditedTimestamp={providedAt} auditedBy={providedBy}/>)}
    </InfoWrapper>);
};
export default InfoStatus;
