import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
export const Alerts = styled.div `
  .snackBarRoot {
    z-index: 1200;
    ${({ isMobile }) => (isMobile ? '' : 'width: 30%')}
  }

  .sentToCustomerContainer {
    width: 100%;
    cursor: pointer;
  }

  .userAlert {
    border-left: 4px solid ${colors.error};
    background: ${colors.GRAY_EE};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
    width: 100%;
  }

  .userSuccess {
    border-left: 4px solid ${colors.success};
    background: ${colors.GRAY_EE};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
    width: 100%;
  }

  .userAlertMessage {
    font-family: ${FONT_FAMILY};
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
    color: ${colors.GRAY_3F};
  }
`;
