import { createSelector } from 'reselect';
export const selectResponsiblePartiesState = (state) => state.admin.responsibleParties;
export const selectParties = createSelector(selectResponsiblePartiesState, (party) => party === null || party === void 0 ? void 0 : party.parties);
export const selectPartiesContent = createSelector(selectParties, (party) => {
    var _a, _b;
    return ((_b = (_a = party === null || party === void 0 ? void 0 : party.content) === null || _a === void 0 ? void 0 : _a.map((i) => {
        var _a;
        return (Object.assign(Object.assign({}, i), { authorizationNumber: i.authorizationNumber.toString(), paymentRequired: i.paymentRequired.toString(), generatesRevenue: (_a = i.generatesRevenue) === null || _a === void 0 ? void 0 : _a.toString() }));
    })) !== null && _b !== void 0 ? _b : []);
});
export const selectPartiesPagination = createSelector(selectParties, (p) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return ({
        page: (_b = (_a = p === null || p === void 0 ? void 0 : p.pageable) === null || _a === void 0 ? void 0 : _a.pageNumber) !== null && _b !== void 0 ? _b : 0,
        size: (_d = (_c = p === null || p === void 0 ? void 0 : p.pageable) === null || _c === void 0 ? void 0 : _c.pageSize) !== null && _d !== void 0 ? _d : 0,
        offset: (_e = p === null || p === void 0 ? void 0 : p.pageable) === null || _e === void 0 ? void 0 : _e.offset,
        pageNumber: (_f = p === null || p === void 0 ? void 0 : p.pageable) === null || _f === void 0 ? void 0 : _f.pageNumber,
        pageSize: (_g = p === null || p === void 0 ? void 0 : p.pageable) === null || _g === void 0 ? void 0 : _g.pageSize,
        totalElements: p === null || p === void 0 ? void 0 : p.totalElements,
        totalPages: p === null || p === void 0 ? void 0 : p.totalPages,
    });
});
export const selectPartiesFilters = createSelector(selectResponsiblePartiesState, (party) => party === null || party === void 0 ? void 0 : party.partiesFilters);
export const selectPartyTypes = createSelector(selectResponsiblePartiesState, (party) => party === null || party === void 0 ? void 0 : party.partyTypes);
export const selectPartiesSort = createSelector(selectResponsiblePartiesState, (party) => party === null || party === void 0 ? void 0 : party.partiesSort);
