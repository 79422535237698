import { FieldArray, Formik } from 'formik';
import React from 'react';
import EditableInput from 'src/components/form/EditableInput';
import EditableSearchPartDistributorLocations from 'src/components/form/EditableSearchPartDistributorLocations';
import SmallIconButton from 'src/components/icons/SmallIconButton';
import { SimpleModal } from 'src/components/layout';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, } from 'src/components/mui';
import { Clear } from 'src/components/mui/icons';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { Tooltip } from 'src/components/tooltips';
import { colors } from 'src/styles/theme';
import * as Yup from 'yup';
import { StyledTableRow } from './HomeStoresDrawerPage.styles';
import useHomeStoresDrawerPage from './useHomeStoresDrawerPage';
const validationSchema = Yup.object().shape({
    homeStores: Yup.array()
        .of(Yup.object().shape({
        partDistributorLocationId: Yup.string().nullable().notRequired(),
        priority: Yup.number().positive().integer().nullable().notRequired(),
    }, [['partDistributorLocationId', 'priority']]))
        .test('uniquePriority', 'Priority values must be unique.', (values) => {
        const filtered = values.map((v) => v.priority).filter((v) => !!v);
        return filtered.length === new Set(filtered).size;
    })
        .test('priorityRequired', 'Non hub locations must have a priority', (values) => !values
        .filter((v) => v.name !== 'RepairSmith Hub')
        .some((v) => v.priority === null && v.partDistributorLocationId !== null))
        .test('locationRequired', 'Cannot have a priority value without a location', (values) => !values.some((v) => v.priority !== null && v.partDistributorLocationId === null)),
});
const HomeStoresDrawerPage = ({ model, onClose, canEdit, }) => {
    const { isDialogOpen, setIsDialogOpen, rows, getValidationTooltip, getPriority, handleSubmit, handleUpdatePriority, handleUpdateLocation, handleClear, } = useHomeStoresDrawerPage({ model, onClose });
    return (<Formik initialValues={{
            homeStores: (rows === null || rows === void 0 ? void 0 : rows.length) > 0 ? rows : [],
        }} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
      {({ values, isSubmitting, isValid, errors, setFieldValue, submitForm, }) => {
            return (<>
            <>
              <SimpleDrawerContent>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ backgroundColor: colors.GRAY_EE }}>
                      <TableCell>Part Distributor</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Priority</TableCell>
                      <TableCell />
                    </TableHead>
                    <TableBody>
                      <FieldArray name="homeStores">
                        {() => {
                    var _a;
                    return (_a = values === null || values === void 0 ? void 0 : values.homeStores) === null || _a === void 0 ? void 0 : _a.map((row, index) => (<StyledTableRow>
                              <TableCell>
                                <div>{values.homeStores[index].name}</div>
                              </TableCell>
                              <TableCell>
                                <EditableSearchPartDistributorLocations partDistributorId={row.partDistributorId} value={values.homeStores[index].address} onUpdate={handleUpdateLocation(setFieldValue, index)}/>
                              </TableCell>
                              <TableCell>
                                {values.homeStores[index].name !==
                            'RepairSmith Hub' && (<EditableInput isEditable={canEdit} value={getPriority(values.homeStores, index)} defaultValue="None" onUpdate={handleUpdatePriority(setFieldValue, index)}/>)}
                              </TableCell>
                              <TableCell>
                                <SmallIconButton aria-label="Clear Home Store" title="Clear" onClick={handleClear(setFieldValue, index, values)}>
                                  <Clear />
                                </SmallIconButton>
                              </TableCell>
                            </StyledTableRow>));
                }}
                      </FieldArray>
                    </TableBody>
                  </Table>
                </TableContainer>
              </SimpleDrawerContent>
              <SimpleDrawerFooter>
                <Tooltip title={!isValid
                    ? getValidationTooltip(errors)
                    : ''}>
                  <FooterActionButton onClick={() => setIsDialogOpen(true)} disabled={!canEdit || isSubmitting || !isValid}>
                    Save Home Stores
                  </FooterActionButton>
                </Tooltip>
              </SimpleDrawerFooter>
            </>
            <SimpleModal title="This will apply the homestores you’ve set for the region for
                every van actively connected to it." onClose={() => setIsDialogOpen(false)} open={isDialogOpen} simpleTwoButtonRowProps={{
                    onCancel: () => setIsDialogOpen(false),
                    onSubmit: submitForm,
                    submitText: 'Proceed',
                }}/>
          </>);
        }}
    </Formik>);
};
export default HomeStoresDrawerPage;
