import React, { useState } from 'react';
import styled from 'styled-components';
import { useDoubleTap } from 'use-double-tap';
import { Check as CheckIcon, Close as CloseIcon, } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import EditFieldDrawer from 'src/components/form/EditFieldDrawer';
import useIsMobile from 'src/hooks/useIsMobile';
export const FieldWrapper = styled.div `
  padding: 6px;
  margin: -6px 0 0 -6px;
  touch-action: manipulation;
  user-select: none;

  ${(props) => props.isEditable &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${colors.transparentDarkGrey};
      border-radius: 4px;
    }
  `}

  ${(props) => props.breakLines &&
    `
    white-space: pre-line;
  `}
`;
export const InputButtonsWrapper = styled.div `
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 8px;
`;
export const InputButton = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-radius: 3.2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24);
  background-color: ${colors.white};
  margin-left: 8px;
  color: ${colors.primary};
  cursor: pointer;

  & svg {
    font-size: 1.25rem;
  }

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24);
  }
`;
export const InputWrapper = styled.div `
  margin-top: ${(props) => (props.inline ? '-10px' : '0px')};
  width: inherit;
`;
export const InputButtons = ({ onClose, onAccept, }) => (<InputButtonsWrapper>
    <InputButton onClick={onClose}>
      <CloseIcon />
    </InputButton>
    <InputButton onClick={onAccept}>
      <CheckIcon />
    </InputButton>
  </InputButtonsWrapper>);
export const Editable = ({ breakLines = false, children, displayValue, DrawerProps, isDrawerOnMobile = false, isEditable, onClick, renderForm, style, }) => {
    const isMobile = useIsMobile();
    const [isEditing, setIsEditing] = useState(false);
    const onDoubleClick = () => {
        if (isEditable) {
            if (onClick) {
                onClick();
                return;
            }
            setIsEditing(true);
        }
    };
    const onDoubleTap = useDoubleTap(onDoubleClick);
    if (isEditing && (!isMobile || !isDrawerOnMobile)) {
        return renderForm ? renderForm({ isEditing, setIsEditing }) : null;
    }
    return (<>
      <FieldWrapper isEditable={isEditable} breakLines={breakLines} onDoubleClick={onDoubleClick} {...onDoubleTap} style={style}>
        {children || displayValue}
      </FieldWrapper>
      {DrawerProps && (<EditFieldDrawer title={DrawerProps.title} renderInput={DrawerProps.renderInput} open={isEditing && isDrawerOnMobile} onSubmit={DrawerProps.onSubmit} onClose={() => {
                setIsEditing(false);
            }}/>)}
    </>);
};
