var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment/moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addVanUnavailability, updateVanUnavailability, } from 'src/AdminApp/modules/deliveryVanTemplates/actions';
import { selectSelectedUnavailability } from 'src/AdminApp/modules/deliveryVanTemplates/selectors';
import { toast } from 'src/components/SimpleToast';
import { DATE_YMD_FORMAT, DATE_YMD_LONG_TIME_FORMAT, TIME_12H_FORMAT_WITH_SECONDS, } from 'src/timeConstants';
import * as Yup from 'yup';
const useEditUnavailabilityTemplateDrawerPage = ({ vanModel, swimlanes, closeHandler, }) => {
    const dispatch = useDispatch();
    const templateModel = useSelector(selectSelectedUnavailability);
    const [loading, setLoading] = useState(false);
    const getInitialValues = () => {
        const initialValues = {
            start: templateModel
                ? templateModel.start
                : moment().format(DATE_YMD_FORMAT),
            startTime: templateModel
                ? moment(templateModel.start).format(TIME_12H_FORMAT_WITH_SECONDS)
                : '08:00:00',
            finishTime: templateModel
                ? moment(templateModel.finish).format(TIME_12H_FORMAT_WITH_SECONDS)
                : '20:00:00',
            notes: templateModel && templateModel.notes,
        };
        return initialValues;
    };
    const validationSchema = Yup.object().shape({
        start: Yup.string().required(),
    });
    const handleUpdateVanUnavailability = (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
        const startDate = moment
            .utc(`${values.start} ${values.startTime}`, DATE_YMD_LONG_TIME_FORMAT)
            .toISOString();
        const endDate = moment
            .utc(`${values.start} ${values.finishTime}`, DATE_YMD_LONG_TIME_FORMAT)
            .toISOString();
        if (moment(endDate).isSame(moment(startDate))) {
            toast.error('End time must be different from start time', 'Validation Error');
            return;
        }
        if (moment(endDate).isBefore(moment(startDate))) {
            toast.error('End time must be after start time', 'Validation Error');
            return;
        }
        setLoading(true);
        if (templateModel) {
            const response = yield dispatch(updateVanUnavailability({
                id: templateModel.id,
                vanId: vanModel === null || vanModel === void 0 ? void 0 : vanModel.id,
                skeduloAvailabilityId: templateModel.skeduloAvailabilityId,
                start: startDate,
                finish: endDate,
                active: true,
                notes: values.notes,
                type: 'NO STAFF-AVAILABLE',
            }));
            if (response && !response.error) {
                toast.success('Unavailability successfully updated.');
                if (closeHandler && swimlanes) {
                    closeHandler();
                }
            }
            setLoading(false);
        }
        else {
            const payload = {
                vanId: vanModel === null || vanModel === void 0 ? void 0 : vanModel.id,
                start: startDate,
                finish: endDate,
                notes: values.notes,
                active: true,
            };
            if (swimlanes) {
                payload.resourceId = vanModel === null || vanModel === void 0 ? void 0 : vanModel.id;
                delete payload.vanId;
            }
            const response = yield dispatch(addVanUnavailability(payload));
            if (response && !response.error) {
                toast.success('Unavailability successfully saved.');
                if (closeHandler && swimlanes) {
                    closeHandler();
                }
            }
            setLoading(false);
        }
        setSubmitting(false);
    });
    return {
        getInitialValues,
        validationSchema,
        handleUpdateVanUnavailability,
        loading,
    };
};
export default useEditUnavailabilityTemplateDrawerPage;
