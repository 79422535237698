var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import { Button, Grid } from 'src/components/mui';
import { getFleetRepairsRequests, searchFleetRepairRequests, } from 'src/AdminApp/modules/fleets/actions';
import { selectFleetRepairsDashboard, selectFleetRepairsDashboardPagination, selectFleetRepairsDashboardParams, selectFleetRepairsDashboardFetching, } from 'src/AdminApp/modules/fleets/selectors';
import Module from 'src/components/layout/Module';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { TextField } from 'src/AdminApp/components/form';
import FleetRepairItem from './Item';
const Wrapper = styled.div `
  padding: 20px;
`;
const GridTitle = styled(Grid) `
  width: 90%;
`;
const BigButton = styled(Button) `
  height: 100%;
  width: 45px;
  margin-left: 8px;
`;
const FLEET_REPAIRS_COLUMNS = [
    { title: 'Reference #', key: 'referenceNum', sortable: true },
    { title: 'Status', key: 'status', sortable: true },
    { title: 'Vehicle', key: 'vehicle', sortable: true },
    { title: 'Fleet', key: 'companyName', sortable: true },
    { title: 'Services', key: 'services', sortable: false },
    { title: 'Created', key: 'created', sortable: true },
    {
        title: 'Appointment',
        key: 'appointmentScheduleStartLocal',
        sortable: true,
    },
];
const FleetRepairs = () => {
    var _a;
    const dispatch = useDispatch();
    const { fleetId } = useParams();
    const repairs = useSelector(selectFleetRepairsDashboard);
    const pagination = useSelector(selectFleetRepairsDashboardPagination);
    const params = useSelector(selectFleetRepairsDashboardParams);
    const isDataFetching = useSelector(selectFleetRepairsDashboardFetching);
    useEffect(() => {
        var _a, _b, _c, _d;
        dispatch(getFleetRepairsRequests(Object.assign(Object.assign({ fleetId }, params), { pageSize: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20, pageIndex: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _b !== void 0 ? _b : 0, sort: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _c !== void 0 ? _c : ['created ', 'desc'], query: (_d = pagination === null || pagination === void 0 ? void 0 : pagination.query) !== null && _d !== void 0 ? _d : '' })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleetId]);
    const onLoadData = (_b) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e, _f;
        var { page = (_c = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _c !== void 0 ? _c : 20, size = (_d = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _d !== void 0 ? _d : 20, sort = (_e = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _e !== void 0 ? _e : ['created', 'desc'], query = (_f = pagination === null || pagination === void 0 ? void 0 : pagination.query) !== null && _f !== void 0 ? _f : '', } = _b;
        dispatch(getFleetRepairsRequests(Object.assign(Object.assign({ fleetId }, params), { pageSize: size, pageIndex: page, sort,
            query })));
    });
    const onSearchRepairs = (query) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(searchFleetRepairRequests({ query, fleetId }));
    });
    return (<Module title="Repair History" actionButton={<GridTitle container style={{ justifyContent: 'flex-end' }}>
          <Formik enableReinitialize initialValues={{
                query: pagination.query || '',
            }} onSubmit={({ query }, { setSubmitting }) => {
                onSearchRepairs(query.trim());
                setSubmitting(false);
            }}>
            {({ isSubmitting, values }) => (<Form>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    minWidth: '300px',
                }}>
                  <TextField placeholder="Nickname, Fleet Name, VIN, Plate" label="Search Query" name="query" variant="outlined" autoFocus fullWidth/>
                  <BigButton size="small" variant="contained" fullWidth disabled={isSubmitting} onClick={() => onLoadData({ query: '' })}>
                    Reset
                  </BigButton>
                  <BigButton size="small" variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting || isEmpty(values.query)}>
                    Search
                  </BigButton>
                </div>
              </Form>)}
          </Formik>
        </GridTitle>}>
      {(repairs === null || repairs === void 0 ? void 0 : repairs.length) || isDataFetching ? (<SimpleTable hideTopPagination pagination={pagination} sort={(_a = pagination.sort) !== null && _a !== void 0 ? _a : []} onLoadData={onLoadData} data={repairs} columns={FLEET_REPAIRS_COLUMNS} isFetching={isDataFetching} renderItem={(item, index) => (<FleetRepairItem repair={item} key={`${index}-${item.referenceNum}`}/>)}/>) : (<Wrapper>No Repairs History</Wrapper>)}
    </Module>);
};
export default FleetRepairs;
