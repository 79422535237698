import CONFIG from 'src/config';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createDefaultActionCreators from 'src/utils/createActions';
export const ActionCreators = {
    GET_HUBS: createDefaultActionCreators('GET_HUBS'),
    SAVE_HUB: createDefaultActionCreators('SAVE_HUB'),
    UPDATE_HUB: createDefaultActionCreators('UPDATE_HUB'),
    ARCHIVE_HUB: createDefaultActionCreators('ARCHIVE_HUB'),
};
export const getHubs = ({ pageSize, pageIndex, sort = ['name,asc'], }) => {
    const params = Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort }));
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_HUBS, {
            success: {
                meta: () => ({ pageSize, pageIndex, sort }),
            },
        }),
        endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/hubs`,
        method: 'GET',
        params,
    });
};
export const saveHub = (newHub) => createAPIAction({
    types: fillApiTypes(ActionCreators.SAVE_HUB),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/hubs`,
    method: 'POST',
    body: newHub,
});
export const updateHub = (hub) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_HUB),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/hubs/${hub.id}`,
    method: 'PATCH',
    body: hub,
});
export const archiveHub = (hubId) => createAPIAction({
    types: fillApiTypes(ActionCreators.ARCHIVE_HUB),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/admin/hubs/${hubId}`,
    method: 'DELETE',
});
