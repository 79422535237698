import { SimpleModal } from 'src/components/layout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserId } from 'src/modules/auth/selectors';
const NotifyUserModal = ({ open, onClose, currentTaskWorkFlow, completeTask, }) => {
    const technicianId = useSelector(selectUserId);
    const [title, setTitle] = useState('');
    useEffect(() => {
        if (open && (currentTaskWorkFlow === null || currentTaskWorkFlow === void 0 ? void 0 : currentTaskWorkFlow.state) === 'ARRIVED') {
            setTitle('Notify Customer you have arrived?');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    return (<SimpleModal open={open} onClose={onClose} title={title} simpleTwoButtonRowProps={{
            submitText: 'Yes',
            onSubmit: () => {
                completeTask({
                    technicianId,
                    notifyCustomer: true,
                    driveTimeToCustomerMinutes: 0,
                });
            },
            onCancel: () => {
                completeTask({
                    technicianId,
                    notifyCustomer: false,
                    driveTimeToCustomerMinutes: 0,
                });
            },
            cancelText: 'No',
        }}/>);
};
export default NotifyUserModal;
