var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CircularProgress } from 'src/components/mui';
import { usMoneyFormatWithCents } from 'src/modules/api/utils/format';
import { getCarTokens } from 'src/AdminApp/modules/consumers/actions';
import { selectVehicleTokens } from 'src/AdminApp/modules/consumers/selectors';
import { getAllRepairs } from 'src/AdminApp/modules/lookups/actions';
import { selectAllRepairs } from 'src/AdminApp/modules/lookups/selectors';
import SimpleChip from 'src/components/SimpleChip';
import { colors } from 'src/styles/theme';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
import { Loading, NoTokens, TokenCard, TokenCardTitle, TokenInfoLabel, TokenInfoTable, TokenInfoValue, TokensDisplayWrapper, } from './TokensDisplay.styles';
const STATUS_COLORS = {
    ACTIVE: colors.success,
    INACTIVE: colors.darkGrey,
    APPLIED: colors.ORANGE_FF,
    REDEEMED: colors.blue,
};
const TokensDisplay = ({ consumerId, vehicleId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const tokens = useSelector(selectVehicleTokens(consumerId));
    const allRepairs = useSelector(selectAllRepairs);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            if (allRepairs.length === 0) {
                yield dispatch(getAllRepairs());
            }
            yield dispatch(getCarTokens(consumerId, vehicleId));
            setLoading(false);
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (loading)
        return (<Loading>
        <CircularProgress />
      </Loading>);
    return (<TokensDisplayWrapper>
      {tokens === null || tokens === void 0 ? void 0 : tokens.map((token) => {
            var _a, _b;
            return (<TokenCard variant="outlined" key={token.id}>
          <TokenCardTitle>
            {token.productName}{' '}
            <div style={{ marginLeft: '5px' }}>
              <SimpleChip $bgColor={STATUS_COLORS[token.status]} label={token.status} $textColor={colors.white}/>
            </div>
          </TokenCardTitle>
          <TokenInfoTable>
            <tbody>
              <tr>
                <td>
                  <TokenInfoLabel>Issued Date</TokenInfoLabel>
                  <TokenInfoValue>
                    {moment(new Date(`${token.issuedDate}Z`)).format(LOCALIZED_DATE_FORMAT)}
                  </TokenInfoValue>
                </td>
                <td>
                  <TokenInfoLabel>Expiration Date</TokenInfoLabel>
                  <TokenInfoValue>
                    {moment(new Date(`${token.expirationDate}Z`)).format(LOCALIZED_DATE_FORMAT)}
                  </TokenInfoValue>
                </td>
              </tr>
              <tr>
                <td>
                  <TokenInfoLabel>Resp Party Type</TokenInfoLabel>
                  <TokenInfoValue>
                    {(_a = token.responsiblePartyType) !== null && _a !== void 0 ? _a : 'N/A'}
                  </TokenInfoValue>
                </td>
                <td>
                  <TokenInfoLabel>Resp Party</TokenInfoLabel>
                  <TokenInfoValue>
                    {(_b = token.responsibleParty) !== null && _b !== void 0 ? _b : 'N/A'}
                  </TokenInfoValue>
                </td>
              </tr>
              <tr>
                <td>
                  <TokenInfoLabel>Redemption Revenue</TokenInfoLabel>
                  <TokenInfoValue>
                    {usMoneyFormatWithCents(token.redemptionRevenue)}
                  </TokenInfoValue>
                </td>
                <td>
                  <TokenInfoLabel>Work Order</TokenInfoLabel>
                  <TokenInfoValue>
                    {token.referenceNum ? (<Link to={`/admin/repairs/${token.referenceNum}`}>
                        {token.referenceNum}
                      </Link>) : ('N/A')}
                  </TokenInfoValue>
                </td>
              </tr>
            </tbody>
          </TokenInfoTable>
        </TokenCard>);
        })}

      {tokens.length === 0 && <NoTokens>This vehicle has no tokens.</NoTokens>}
    </TokensDisplayWrapper>);
};
export default TokensDisplay;
