import React from 'react';
import { useSelector } from 'react-redux';
import { Value } from 'src/AdminApp/components/layout/Module';
import HealthReport from 'src/AdminApp/containers/vehicle/CarModule/HealthReport';
import LicensePlateFieldEdit from 'src/AdminApp/containers/vehicle/CarModule/LicensePlateFieldEdit';
import MilesFieldEdit from 'src/AdminApp/containers/vehicle/CarModule/MilesFieldEdit';
import ResidualFieldEdit from 'src/AdminApp/containers/vehicle/CarModule/ResidualFieldEdit';
import VinFieldEdit from 'src/AdminApp/containers/vehicle/CarModule/VinFieldEdit';
import { formVehicleDisplayText } from 'src/AdminApp/containers/vehicle/utils';
import { ResidualValue } from 'src/AdminApp/models/enums';
import { selectFourCornerWorkflow, selectFourCornerWorkflowTaskDone, selectWorkflowByReferenceNumber, } from 'src/AdminApp/modules/workflows/selectors';
import { Grid } from 'src/components/mui';
import { SimpleDrawer, SimpleDrawerContent } from 'src/components/SimpleDrawer';
import styled from 'styled-components';
import CarFieldEdit from './CarFieldEdit';
import FourCornersDrawer from './FourCornersDrawer';
import MobileFieldButton from './MobileFieldButton';
import WarrantyInspection from './Warranty/WarrantyInspection';
const Logo = styled.img `
  max-height: 32px;
  max-width: 32px;
  margin-right: 8px;
`;
const MobileCarDrawer = ({ open, onClose, onUpdate, vehicle, logo, repairRequestId, referenceNum, }) => {
    const isFourCornerCurrentTask = useSelector(selectFourCornerWorkflow(referenceNum));
    const isFourCornerDone = useSelector(selectFourCornerWorkflowTaskDone(referenceNum));
    const workflow = useSelector(selectWorkflowByReferenceNumber(referenceNum));
    const completedWarrantyTaskExists = workflow === null || workflow === void 0 ? void 0 : workflow.workflowTasks.filter((task) => task.state === 'RS_WARRANTY_CLAIM_INSPECTION_PERFORMED').some((task) => task.status === 'COMPLETED');
    const onCarSave = (values) => onUpdate(Object.assign(Object.assign({}, vehicle), values));
    return (<SimpleDrawer open={open} onClose={onClose} title="Car Details">
      <SimpleDrawerContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MobileFieldButton label="" displayValue={<Grid container style={{ alignItems: 'center' }}>
                  <Logo src={logo}/>
                  <div>
                    <b>{formVehicleDisplayText(vehicle)}</b>
                    <span>&nbsp;{vehicle.model}</span>
                    <div>
                      <Value>
                        Engine: {vehicle.engineDescription} • Trim:{' '}
                        {vehicle.trim}
                      </Value>
                    </div>
                  </div>
                </Grid>} values={vehicle} onSave={onCarSave} FieldEdit={CarFieldEdit}/>
          </Grid>

          <Grid item xs={12}>
            <MobileFieldButton label="License Plate" displayValue={<Value bold>
                  {vehicle.plateNumber}{' '}
                  {vehicle.plateNumberState && `(${vehicle.plateNumberState})`}
                </Value>} values={vehicle} onSave={onCarSave} FieldEdit={LicensePlateFieldEdit}/>
          </Grid>

          <Grid item xs={12}>
            <MobileFieldButton label="VIN" displayValue={<Value bold>{vehicle.vin}</Value>} values={vehicle} onSave={onCarSave} FieldEdit={VinFieldEdit}/>
          </Grid>

          <Grid item xs={12}>
            <MobileFieldButton label="Miles In / Out" displayValue={vehicle.mileage ? (<>
                    <Value bold>{vehicle.mileage}</Value>
                    {' / '}
                    {vehicle.mileageOut ? (<Value bold>{vehicle.mileageOut}</Value>) : (<Value bold grey>
                        {' '}
                        Add...
                      </Value>)}
                  </>) : (<Value bold grey>
                    {' '}
                    Add...
                  </Value>)} values={vehicle} onSave={onCarSave} FieldEdit={MilesFieldEdit}/>
          </Grid>

          <Grid item xs={12}>
            <MobileFieldButton label="Residual Value" displayValue={vehicle.residualValue ? (<Value bold>
                    {ResidualValue.display(vehicle.residualValue)}
                  </Value>) : (<Value bold grey>
                    {' '}
                    Add...
                  </Value>)} values={vehicle} onSave={onCarSave} FieldEdit={ResidualFieldEdit}/>
          </Grid>
        </Grid>
        {vehicle && (<FourCornersDrawer car={vehicle} handleUpdateCar={onCarSave} isCurrentTask={isFourCornerCurrentTask} isDone={isFourCornerDone} referenceNum={referenceNum}/>)}
        <HealthReport referenceNum={referenceNum} repairRequestId={repairRequestId} vehicle={vehicle}/>
        {completedWarrantyTaskExists && <WarrantyInspection />}
      </SimpleDrawerContent>
    </SimpleDrawer>);
};
export default MobileCarDrawer;
