import React from 'react';
import usePaymentForm from 'src/AdminApp/components/payments/PaymentForm/usePaymentForm';
import SpreedlyForm from '../SpreedlyForm/SpreedlyForm';
import { PaymentFormContainer } from './PaymentForm.styles';
const PaymentForm = ({ onFinishedAddPaymentMethod, path, triggerCCSubmit = false, onFormValid, onFormError, alwaysDefault = false, consumerId, }) => {
    const { handleAddPaymentMethod } = usePaymentForm({
        onFinishedAddPaymentMethod,
        path,
        triggerCCSubmit,
        onFormError,
        alwaysDefault,
        consumerId,
    });
    return (<PaymentFormContainer className="PaymentForm">
      <SpreedlyForm onTokenize={handleAddPaymentMethod} onFormValid={onFormValid}/>
    </PaymentFormContainer>);
};
export default PaymentForm;
