import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { mapClientShops } from 'src/AdminApp/modules/mappers';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            offset: 0,
            sort: ['installationDate,desc'],
        },
    },
};
const mapInstallations = (clientShopsList) => {
    return clientShopsList && clientShopsList.length
        ? clientShopsList.map(mapClientShops)
        : [];
};
const successInstallations = (state, action) => {
    var _a, _b, _c;
    const data = mapInstallations((_c = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.clientShops) !== null && _c !== void 0 ? _c : []);
    const { sort } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: fetched(Object.assign(Object.assign({}, state.dashboard), { content: data, pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { pageSize: data.length, totalElements: data.length, sort }), _isFetching: false })) });
};
const fetchInstallationsDashboardReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const failureInstallationsDashboardReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: false }) });
};
const clearInstallationsDashboardReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const setSortParamsInstallationsDashboardReducer = (state, action) => {
    const { sort } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { sort }) }) });
};
const reducers = handleActions({
    [ActionCreators.INSTALLATIONS.SUCCESS.type]: successInstallations,
    [ActionCreators.INSTALLATIONS.FETCH.type]: fetchInstallationsDashboardReducer,
    [ActionCreators.INSTALLATIONS.FAILURE.type]: failureInstallationsDashboardReducer,
    [ActionCreators.INSTALLATIONS.CLEAR.type]: clearInstallationsDashboardReducer,
    [ActionCreators.SET_SORT_PARAMS.SUCCESS.type]: setSortParamsInstallationsDashboardReducer,
}, DEFAULT_STATE);
export default reducers;
