import React from 'react';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
import { Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from 'src/components/mui';
import { DeleteForever as DeleteIcon, Edit as EditIcon, } from 'src/components/mui/icons';
import { toFixedIfNumber } from 'src/utils/formatter';
const HeadCell = styled(TableCell) `
  vertical-align: top;
`;
const RepairComponentTable = ({ values, setFieldValue, handleEditRepairComponent, componentType, }) => {
    const editComponent = (repairComponent) => () => {
        handleEditRepairComponent(Object.assign({}, repairComponent));
    };
    const removeComponent = (repairComponent) => () => {
        return setFieldValue('repairComponents', values.repairComponents.filter((component) => component.metaId !== repairComponent.metaId));
    };
    return (<TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <HeadCell>Component Name</HeadCell>
            <HeadCell>Type</HeadCell>
            <HeadCell>Unit</HeadCell>
            <HeadCell>PCDB id</HeadCell>
            <HeadCell>Default Min/Max Quantity</HeadCell>
            <HeadCell>Default Min/Max Unit Price</HeadCell>
            <HeadCell>Special Order</HeadCell>
            <HeadCell>Lead Time</HeadCell>
            <HeadCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {values.repairComponents &&
            values.repairComponents
                .filter((item) => componentType ? item.type === componentType : true)
                .map((repairComponent, index) => {
                var _a, _b;
                return repairComponent.component && (<TableRow key={`${repairComponent.component.name}-${((_a = repairComponent.component.position) === null || _a === void 0 ? void 0 : _a.name) || index}`}>
                      <TableCell style={{ width: 1, whiteSpace: 'nowrap' }}>
                        {repairComponent.component.name}
                      </TableCell>
                      <TableCell>{repairComponent.component.type}</TableCell>
                      <TableCell>{repairComponent.component.unit}</TableCell>
                      <TableCell>{repairComponent.component.pcdbId}</TableCell>
                      <TableCell>
                        {(repairComponent.defaultMinQuantity ||
                        repairComponent.defaultMaxQuantity) &&
                        `${repairComponent.defaultMinQuantity} - ${repairComponent.defaultMaxQuantity}`}
                      </TableCell>
                      <TableCell>
                        {(repairComponent.defaultMinUnitPrice ||
                        repairComponent.defaultMaxUnitPrice) &&
                        `${toFixedIfNumber(repairComponent.defaultMinUnitPrice) || null} - ${toFixedIfNumber(repairComponent.defaultMaxUnitPrice) || null}`}
                      </TableCell>
                      <TableCell>
                        {((_b = repairComponent === null || repairComponent === void 0 ? void 0 : repairComponent.component) === null || _b === void 0 ? void 0 : _b.type) !== 'LABOR' && (<Checkbox checked={!!repairComponent.overrideSpecialOrder} disabled/>)}
                      </TableCell>
                      <TableCell>{repairComponent.overrideLeadTime}</TableCell>
                      <TableCell style={{ width: 1, whiteSpace: 'nowrap' }}>
                        <IconButton edge="end" aria-label="edit" size="small" onClick={editComponent(repairComponent)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" size="small" style={{ color: colors.error }} onClick={removeComponent(repairComponent)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>);
            })}
        </TableBody>
      </Table>
    </TableContainer>);
};
export default RepairComponentTable;
