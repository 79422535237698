import React from 'react';
import styled from 'styled-components';
import { TableRow, TableCell } from 'src/components/mui';
import { formatCurrency, formatTimestamp } from 'src/utils/formatter';
import { LOCALIZED_DATE_LONG_FORMAT } from 'src/timeConstants';
import moment from 'moment';
const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  td {
    padding: 15px !important;
  }
`;
const VisitItem = ({ data }) => {
    return (<StyledTableRow>
      <TableCell>
        <a href={`/admin/visits/${data.refNum}`} target="_blank" rel="noreferrer noopener">
          {data.refNum}
        </a>
      </TableCell>
      <TableCell>{data.status.replace(/_/gi, ' ')}</TableCell>
      <TableCell>{`${moment(data.startLocal).format(LOCALIZED_DATE_LONG_FORMAT)}  `}</TableCell>
      <TableCell>{formatTimestamp(data.created)}</TableCell>
      <TableCell>{data.adminName}</TableCell>
      <TableCell>{formatCurrency(data.expectedRevenue)}</TableCell>
      <TableCell>{data.ordersCount.total}</TableCell>
    </StyledTableRow>);
};
export default VisitItem;
