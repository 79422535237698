import { toEnum } from 'src/models/enums';
export const PartPickupItemStatus = toEnum({
    PENDING: 'PENDING',
    PICKED_UP: 'PICKED_UP',
    STANDBY: 'STANDBY',
    NEW_PART: 'NEW_PART',
    NEW_LOCATION: 'NEW_LOCATION',
    REMOVED: 'REMOVED',
});
export default PartPickupItemStatus;
