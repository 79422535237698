var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { selectAuthorizedTechDashboardViewer, selectUserId, } from 'src/modules/auth/selectors';
import { getInventoryReturnItems } from 'src/PopsApp/modules/inventory/actions';
import { selectDashboardReturnItems } from 'src/PopsApp/modules/inventory/selectors';
import { getDashboardById, getTechnicianById, } from 'src/TechApp/modules/dashboard/actions';
import { selectTechDashboard, selectTechnicianById, } from 'src/TechApp/modules/dashboard/selectors';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
const useTechDashboard = ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const { technicianId } = match.params;
    const userId = useSelector(selectUserId);
    const canViewAdminTech = useSelector(selectAuthorizedTechDashboardViewer);
    const returnItems = useSelector(selectDashboardReturnItems);
    const queryParams = new URLSearchParams(useLocation().search);
    const dateParam = queryParams.has('date')
        ? moment(queryParams.get('date'))
        : moment();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentDateSlider, setCurrentDateSlider] = useState(dateParam);
    const [formattedDate, setFormattedDate] = useState(null);
    const [calendarDate, setCalendarDate] = useState(dateParam);
    const [coresCount, setCoresCount] = useState(0);
    const [openMoveDrawer, setOpenMoveDrawer] = useState(false);
    const technician = useSelector(selectTechnicianById(technicianId));
    const dashboard = useSelector(selectTechDashboard);
    const formatDate = (date) => date.format(DATE_YMD_FORMAT);
    const getCores = (locationId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const coresResponse = yield dispatch(getInventoryReturnItems({
            pageSize: 100,
            locationId,
            locationType: 'DELIVERY_VAN',
            sort: [],
            status: 'PENDING',
        }));
        if (!coresResponse.error) {
            setCoresCount(((_b = (_a = coresResponse.payload) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.length) || 0);
        }
        return coresResponse;
    });
    useEffect(() => {
        if (currentDateSlider != null) {
            const selectedDate = formatDate(currentDateSlider);
            setFormattedDate(selectedDate);
            if (!(technician && technician[selectedDate])) {
                (() => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    const technicianInfoResult = yield dispatch(getTechnicianById(technicianId, selectedDate));
                    if (!technicianInfoResult.error) {
                        yield dispatch(getDashboardById(technicianId, selectedDate, timeZone));
                        yield getCores(technicianInfoResult.payload.vanId);
                    }
                    setIsLoading(false);
                }))();
            }
            else {
                (() => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    yield dispatch(getDashboardById(technicianId, selectedDate, timeZone));
                    getCores(technician[selectedDate].vanId);
                    setIsLoading(false);
                }))();
            }
            history.push(`/tech/technicians/${technicianId}/dashboard?date=${selectedDate}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDateSlider, technicianId]);
    const handleMoveDrawerOpen = () => {
        setOpenMoveDrawer(true);
    };
    const handleMoveDrawerClose = (refresh = false) => {
        setOpenMoveDrawer(false);
        if (refresh) {
            const selectedDate = formatDate(currentDateSlider);
            getCores(technician[selectedDate].vanId);
        }
    };
    return {
        formattedDate,
        userId,
        technicianId,
        canViewAdminTech,
        technician,
        setOpen,
        setCalendarDate,
        calendarDate,
        setCurrentDateSlider,
        dashboard,
        isLoading,
        open,
        coresCount,
        returnItems,
        openMoveDrawer,
        handleMoveDrawerOpen,
        handleMoveDrawerClose,
    };
};
export default useTechDashboard;
