import React from 'react';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { formatTimestamp } from 'src/utils/formatter';
import { LOCALIZED_DATE_LONG_FORMAT } from 'src/timeConstants';
import EditableInput from 'src/components/form/EditableInput';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
const Title = styled.div `
  margin: 24px 0 12px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.1px;
  color: ${colors.darkGrey};
`;
const Content = styled.div `
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: -0.1px;
  margin-bottom: 2px;
`;
const Footer = styled.div `
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.darkGrey};
  margin-bottom: 24px;
`;
const Notes = ({ text, modified, modifiedBy, onUpdateNote }) => {
    const userstamp = (<span>
      &nbsp;•
      <AdminUser subText={false} updatedUser={modifiedBy}/>
    </span>);
    return text ? (<div>
      <Title>Service Notes</Title>
      <Content>
        <EditableInput inputProps={{
            multiline: true,
            rows: 3,
            variant: 'outlined',
            placeholder: 'Add a note for this service…',
        }} isDrawerOnMobile drawerTitle="Service Notes" value={text} defaultValue="Add a note..." onUpdate={(value) => {
            onUpdateNote(value);
        }}/>
      </Content>
      <Footer>
        {modified && `${formatTimestamp(modified, LOCALIZED_DATE_LONG_FORMAT)}`}
        {modifiedBy && userstamp}
      </Footer>
    </div>) : null;
};
export default Notes;
