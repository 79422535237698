import React, { useEffect, useState } from 'react';
import SimpleChip, { ChipLogo } from 'src/components/SimpleChip';
import { STATIC_URL_BASE, colors } from 'src/styles/theme';
const SubscriptionChip = ({ vehicle, setTokensDrawerOpen, }) => {
    const [subName, setSubName] = useState();
    const [subChipColor, setSubChipColor] = useState(colors.blue);
    useEffect(() => {
        var _a;
        if (!vehicle)
            return;
        const carSubscription = (_a = vehicle.subscriptions) === null || _a === void 0 ? void 0 : _a.find((sub) => sub.status === 'ACTIVE');
        if (!carSubscription)
            return;
        const planKind = carSubscription.chargebeePlanName.match(/standard|basic|pro/gi);
        if (!planKind)
            return;
        switch (planKind[0].toLowerCase()) {
            case 'basic':
                setSubChipColor(colors.newCarBlue);
                break;
            case 'standard':
                setSubChipColor(colors.celebrationPink);
                break;
            case 'pro':
                setSubChipColor(colors.carbonFiber);
                break;
            default:
            // nothing to see here
        }
        if (carSubscription.chargebeePlanName.indexOf('RepairSmith') > -1) {
            setSubName(`AutoPilot ${planKind[0]}`);
        }
        else {
            setSubName(carSubscription.chargebeePlanName.replace(/-|Tier/gi, ' '));
        }
    }, [vehicle]);
    return !subName ? null : (<div>
      {subName && (<SimpleChip clickable onClick={() => {
                setTokensDrawerOpen(true);
            }} $bgColor={subChipColor} $textColor={colors.white} avatar={<ChipLogo className="CarSubscriptionInfo__icon" src={`${STATIC_URL_BASE}img/svg/ms-logo.svg`} alt="AutoNation Mobile Service logo"/>} label={subName}/>)}
    </div>);
};
export default SubscriptionChip;
