var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVehicleLocation, resetVendorsSearchResults, getVanHomeStores, getVanPartPickups, setComponentsAvailability, ActionCreators, getPartDistributors, } from 'src/PopsApp/modules/vendors/actions';
import { setSelectedDistributor } from 'src/PopsApp/modules/parts/actions';
import { getDeliveryVanByIspId } from 'src/AdminApp/modules/deliveryVans/actions';
import { selectHomeStores, selectPartDistributors, selectPartPickups, } from 'src/PopsApp/modules/vendors/selectors';
import { selectSelectedJobs, selectVan, } from 'src/PopsApp/modules/parts/selectors';
import { selectDeliveryVanByIspId } from 'src/AdminApp/modules/deliveryVans/selectors';
import { toast } from 'src/components/SimpleToast';
import { useHistory, useParams } from 'react-router-dom';
const useVendors = ({ shopId, isManualOrder, handleSkipOrder, handleOrderParts, editVendor, isQuickOrder, }) => {
    var _a, _b;
    const dispatch = useDispatch();
    const history = useHistory();
    const [vendorSearchTab, setVendorSearchTab] = useState(0);
    const [isSkipOrderForTodayDialogOpen, setIsSkipOrderForTodayDialogOpen] = useState(false);
    const [openSkipCustomDialog, setOpenSkipCustomDialog] = useState(false);
    const [vanLoading, setVanLoading] = useState(true);
    const partDistributors = useSelector(selectPartDistributors);
    const vanShop = useSelector(selectVan);
    const homeStores = useSelector(selectHomeStores);
    const selectedJobs = useSelector(selectSelectedJobs);
    const partPickups = useSelector(selectPartPickups);
    const deliveryVan = useSelector(selectDeliveryVanByIspId(shopId));
    const [firstSelectedJob] = (selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.length) ? selectedJobs : [];
    const selectedJobsDate = (selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.length)
        ? (_a = firstSelectedJob === null || firstSelectedJob === void 0 ? void 0 : firstSelectedJob.scheduleStartLocal) === null || _a === void 0 ? void 0 : _a.split('T')[0]
        : null;
    const selectedJobsTimezone = (selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.length)
        ? firstSelectedJob === null || firstSelectedJob === void 0 ? void 0 : firstSelectedJob.timezone
        : null;
    const selectedJobsShift = (selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.length)
        ? (_b = firstSelectedJob === null || firstSelectedJob === void 0 ? void 0 : firstSelectedJob.shift) === null || _b === void 0 ? void 0 : _b.toUpperCase()
        : null;
    const { ispId, region, shift } = useParams();
    useEffect(() => {
        if (shopId) {
            dispatch(ActionCreators.HOME_STORES_AND_PICKUPS.CLEAR());
            dispatch(getVanHomeStores({ shopId }));
            const firstApptDate = getFirstAppointmentDate();
            if (firstApptDate) {
                dispatch(getVanPartPickups({ shopId, date: firstApptDate }));
            }
            if (!partDistributors) {
                dispatch(getPartDistributors());
            }
            dispatch(getDeliveryVanByIspId(shopId));
            setVanLoading(false);
        }
        return () => {
            dispatch(setVehicleLocation(undefined));
            dispatch(resetVendorsSearchResults(undefined));
            setVanLoading(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopId, selectedJobs, dispatch]);
    const handleTabsChange = (event, newValue) => {
        setVendorSearchTab(newValue);
    };
    const getFirstAppointmentDate = () => {
        if (selectedJobs) {
            return selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.sort((j1, j2) => j1.appointmentStartTime - j2.appointmentStartTime)[0].appointmentStartTime;
        }
    };
    const handleVendorSubmit = (selectedDistributor, _disableIntegration, componentsAvailability = {}) => {
        var _a, _b;
        dispatch(setComponentsAvailability(componentsAvailability));
        if (!selectedDistributor.partDistributorName) {
            selectedDistributor.partDistributorName = (_a = partDistributors.find((d) => d.id === selectedDistributor.partDistributorId)) === null || _a === void 0 ? void 0 : _a.name;
        }
        if (!selectedDistributor.partDistributorId) {
            selectedDistributor.partDistributorId = (_b = partDistributors.find((d) => d.name === selectedDistributor.partDistributorName)) === null || _b === void 0 ? void 0 : _b.id;
        }
        handleSubmit(selectedDistributor);
        setVendorSearchTab(0);
    };
    const handleSubmit = (distributor) => __awaiter(void 0, void 0, void 0, function* () {
        if (!isManualOrder && !editVendor) {
            const response = yield handleOrderParts(selectedJobs);
            if (!response || response.error) {
                return;
            }
        }
        if (isQuickOrder && selectedJobsDate) {
            const response = yield handleOrderParts(selectedJobs, ispId, shift !== null && shift !== void 0 ? shift : selectedJobsShift, selectedJobsDate, selectedJobsTimezone, true);
            if (!response || response.error) {
                return;
            }
        }
        yield dispatch(setSelectedDistributor(distributor));
        if (isManualOrder) {
            if (isQuickOrder) {
                history.push(`/pops/regions/${region}/vans/${ispId}/jobs/order/quick-order`);
            }
            else {
                history.push(`/pops/regions/${region}/vans/${ispId}/jobs/order`);
            }
        }
        else {
            history.push(`/pops/${shift}/jobs/order`);
        }
    });
    const onSkipOrder = (time) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield handleSkipOrder(time);
        if (!response || (response === null || response === void 0 ? void 0 : response.error)) {
            toast.error('Something went wrong when skipping orders, please try again later.');
        }
        setIsSkipOrderForTodayDialogOpen(false);
        setOpenSkipCustomDialog(false);
    });
    return {
        vendorSearchTab,
        isSkipOrderForTodayDialogOpen,
        setIsSkipOrderForTodayDialogOpen,
        openSkipCustomDialog,
        setOpenSkipCustomDialog,
        partDistributors,
        vanShop,
        homeStores,
        selectedJobs,
        partPickups,
        deliveryVan,
        handleTabsChange,
        handleVendorSubmit,
        onSkipOrder,
        vanLoading,
    };
};
export default useVendors;
