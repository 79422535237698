import React, { useContext } from 'react';
import styled from 'styled-components';
import { ButtonBase, Grid } from 'src/components/mui';
import { KeyboardArrowRight } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import { SliderContext, } from 'src/AdminApp/components/discounts/components/DiscountDrawer/utils';
import { SlidePageContent } from 'src/AdminApp/components/discounts/components/DiscountDrawer/styledComponents';
const ButtonWithArrow = styled(ButtonBase) `
  padding: 20px 0;
  box-shadow: inset 0 -1px 0 0 ${colors.lightGrey};
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;
const OptionType = ({ children, onClick, disabled }) => (<ButtonWithArrow disabled={disabled} onClick={onClick}>
    <Grid container style={{ alignItems: 'center' }}>
      <Grid item xs>
        {children}
      </Grid>
      <Grid>
        <KeyboardArrowRight />
      </Grid>
    </Grid>
  </ButtonWithArrow>);
const DiscountTypePage = ({ disableOptions, }) => {
    const { setSlide } = useContext(SliderContext);
    const handleClick = (page) => () => {
        setSlide(page);
    };
    return (<SlidePageContent>
      <OptionType disabled={!!disableOptions.promoCode} onClick={handleClick('promoCode')}>
        Promo Code
      </OptionType>
      <OptionType disabled={!!disableOptions.cancelFeeCredit} onClick={handleClick('cancelFeeCredit')}>
        Cancel Fee Credit
      </OptionType>
      <OptionType disabled={!!disableOptions.customDiscount} onClick={handleClick('customDiscount')}>
        Custom Discount
      </OptionType>
    </SlidePageContent>);
};
export default DiscountTypePage;
