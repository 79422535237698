import { handleActions } from 'redux-actions';
import { toast } from 'src/components/SimpleToast';
import { ActionCreators } from 'src/AdminApp/modules/zendesk/actions';
const DEFAULT_STATE = {
    zendeskTickets: [],
};
const zendeskLogReducer = (ticketType, status, notify = true) => (state, action) => {
    var _a, _b;
    const { referenceNum } = action.meta;
    if (notify) {
        switch (status) {
            case 'submitted':
                toast.info(`Request for ${ticketType} Zendesk ticket for ${referenceNum} has been submitted.`);
                break;
            case 'created':
                toast.success(`${ticketType} Zendesk ticket for ${referenceNum} has been created.`);
                break;
            case 'error':
                toast.error(`Request for ${ticketType} Zendesk ticket for ${referenceNum} has failed.`);
                break;
            default:
        }
    }
    const refLog = (_a = state[referenceNum]) !== null && _a !== void 0 ? _a : {};
    const typeLog = (_b = refLog[ticketType]) !== null && _b !== void 0 ? _b : [];
    const logEntry = {
        status,
        timestamp: Date.now(),
    };
    return Object.assign(Object.assign({}, state), { [referenceNum]: Object.assign(Object.assign({}, refLog), { [ticketType]: [...typeLog, logEntry] }) });
};
const reducers = handleActions({
    [ActionCreators.REQUEST_SALES_CALL.SUCCESS.type]: zendeskLogReducer('Sales Call Needed', 'submitted'),
    [ActionCreators.REQUEST_SALES_CALL.FAILURE.type]: zendeskLogReducer('Sales Call Needed', 'error', false),
    [ActionCreators.REQUEST_MPI_QUOTE_NEEDED.SUCCESS.type]: zendeskLogReducer('MPI Quote Needed', 'created', false),
    [ActionCreators.REQUEST_MPI_QUOTE_NEEDED.FAILURE.type]: zendeskLogReducer('MPI Quote Needed', 'error', false),
    [ActionCreators.GET_ZENDESK_TICKETS.SUCCESS.type]: (state, action) => (Object.assign(Object.assign({}, state), { zendeskTickets: action.payload })),
}, DEFAULT_STATE);
export default reducers;
