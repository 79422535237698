import React from 'react';
import { Grid } from 'src/components/mui';
import { SimpleModal } from 'src/components/layout';
import { ErrorIcon } from './VisitCapacityWarningModal.styles';
const VisitCapacityWarningModal = ({ open, onClose, warningMessage = '', }) => {
    return (<SimpleModal open={open} title={<Grid container style={{ alignItems: 'center' }}>
          <Grid item>Warning</Grid>
          <Grid item>
            <ErrorIcon />
          </Grid>
        </Grid>} simpleTwoButtonRowProps={{
            onSubmit: () => onClose(true),
            submitText: 'Confirm',
            cancelText: 'Cancel',
            onCancel: () => onClose(false),
        }}>
      {warningMessage}
    </SimpleModal>);
};
export default VisitCapacityWarningModal;
