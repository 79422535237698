import { createSelector } from 'reselect';
export const selectShops = (state) => state.admin.shops;
export const selectShopsByReferenceNum = (referenceNum) => createSelector(selectShops, (shops) => { var _a; return (_a = shops.byReferenceNum[referenceNum]) !== null && _a !== void 0 ? _a : []; });
export const selectShopById = (id) => createSelector(selectShops, (shops) => shops.byId[id]);
export const selectDashboard = createSelector(selectShops, (shops) => { var _a; return (_a = shops.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardShops = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectDashboardPagination = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardParams = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardFetching = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
export const selectRepairFocuses = createSelector(selectShops, (shops) => { var _a; return (_a = shops.registration.repairFocuses.data) !== null && _a !== void 0 ? _a : []; });
export const selectSubletRepairFocuses = createSelector(selectShops, (shops) => {
    var _a;
    return (_a = shops.registration.repairFocuses.data) === null || _a === void 0 ? void 0 : _a.map((i) => ({
        id: i.id,
        name: i.name,
        order: i.order,
        subletRepairFocuses: i.repairFocuses,
    }));
});
export const selectCarBrands = createSelector(selectShops, (shops) => { var _a; return (_a = shops.registration.carBrands.data) !== null && _a !== void 0 ? _a : []; });
export const selectCarTypes = createSelector(selectShops, (shops) => { var _a; return (_a = shops.registration.carTypes.data) !== null && _a !== void 0 ? _a : []; });
export const selectShopFormValues = createSelector(selectShops, (shops) => (shopId) => shops.byId[shopId]);
