var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Grid } from 'src/components/mui';
import { createStatusSelect, SelectType, } from 'src/AdminApp/components/form/Statuses';
import { OptimizationRunStatus } from 'src/AdminApp/models/enums/OptimizationRunStatus';
import FilterButtons from 'src/components/dashboard/DashboardFilter/FilterButtons';
const DashboardFilters = ({ onSearch, onClear, onReset, }) => {
    const [submitting, setSubmitting] = useState(false);
    const clearFilter = () => {
        onReset();
        onClear();
    };
    const OptimizationRunStatusSelectForm = createStatusSelect(OptimizationRunStatus, SelectType.FormControl);
    const handleSubmit = ({ status }) => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        yield onSearch(status);
        setSubmitting(false);
    });
    const handleResetForm = (resetForm) => () => {
        onReset();
        resetForm();
    };
    const handleClearResults = (resetForm) => () => {
        clearFilter();
        resetForm();
    };
    return (<Formik initialValues={{
            status: 'ERROR',
        }} onSubmit={handleSubmit}>
      {({ resetForm }) => (<Form>
          <FilterButtons hideQuery onClear={handleClearResults(resetForm)} onReset={handleResetForm(resetForm)} isSubmitting={submitting}>
            <Grid item xs={12} md={6} lg={3}>
              <OptimizationRunStatusSelectForm name="status" label="Lock Status"/>
            </Grid>
          </FilterButtons>
        </Form>)}
    </Formik>);
};
export default DashboardFilters;
