import React from 'react';
import styled from 'styled-components';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
const AdditionalInfo = styled.div `
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.75px;
  color: ${colors.darkGrey};
  display: flex;
  align-items: center;
`;
const AdditionalInfoImg = styled.img `
  margin-right: 4px;
  &:nth-of-type(2n) {
    margin-left: 20px;
  }
`;
const VinPlateVehicleInfo = ({ vehicle }) => (<AdditionalInfo>
    {vehicle.plateNumber && (<>
        <AdditionalInfoImg src={`${STATIC_URL_BASE}img/license-plate-text.svg`} alt=""/>{' '}
        {vehicle.plateNumber} ({vehicle.plateNumberState}){' '}
      </>)}

    {vehicle.vin && (<>
        <AdditionalInfoImg src={`${STATIC_URL_BASE}img/vin-text.svg`} alt=""/>{' '}
        {vehicle.vin}
      </>)}
  </AdditionalInfo>);
export default VinPlateVehicleInfo;
