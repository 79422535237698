import React, { useEffect, useState } from 'react';
import { Container, Fab } from 'src/components/mui';
import AddFleetVisitDrawer from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer';
import { AddRounded as AddRoundedIcon } from 'src/components/mui/icons';
import styled from 'styled-components';
import { ActionsBlock } from 'src/containers/layout/ActionsBlock';
import { clearFleetVisit } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { useDispatch } from 'react-redux';
import useIsMobile from 'src/hooks/useIsMobile';
import Content from './components/Content';
const AddVisitFab = styled(Fab) `
  position: fixed;
  bottom: 32px;
  right: 24px;
  height: 69px;
  width: 69px;

  svg {
    width: 40px;
    height: 40px;
  }
`;
const FleetVisits = () => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const [addVisitDrawerOpen, setAddVisitDrawerOpen] = useState(false);
    useEffect(() => {
        dispatch(clearFleetVisit());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleAddVisitFab = () => {
        setAddVisitDrawerOpen(true);
    };
    const handleAddVisitDrawerClose = () => {
        setAddVisitDrawerOpen(false);
    };
    const visitActions = [
        {
            function: () => {
                setAddVisitDrawerOpen(true);
            },
            disabled: false,
            tooltip: undefined,
            copy: 'Add Visit',
            isCancelAction: false,
            hasBorderTop: false,
        },
    ];
    return (<Container maxWidth="xl">
      <Content />
      <ActionsBlock actions={visitActions}/>
      <AddFleetVisitDrawer open={addVisitDrawerOpen} onClose={handleAddVisitDrawerClose}/>
      {isMobile && (<AddVisitFab color="primary" aria-label="Add Visit" variant="circular" onClick={handleAddVisitFab} title="Add Visit">
          <AddRoundedIcon />
        </AddVisitFab>)}
    </Container>);
};
export default FleetVisits;
