import { Formik } from 'formik';
import React from 'react';
import { TextField } from 'src/AdminApp/components/form';
import useNotes3CDrawerContent from 'src/AdminApp/containers/requests/Notes/AddNotesDrawer/Notes3C/useNotes3CDrawerContent';
import { Grid, MenuItem } from 'src/components/mui';
import { CheckCircleRounded as CheckCircleRoundedIcon } from 'src/components/mui/icons';
import { DrawerFormRow, FooterActionButton, Instructions, SimpleDrawerContent, SimpleDrawerFooter, SubHeader, } from 'src/components/SimpleDrawer';
import useNotes from '../../useNotes';
import { FooterInfo, InputInfo, InputLabel, MenuItemContent, MenuItemInner, } from '../AddNotesDrawer.styles';
const Notes3CDrawerContent = ({ type, repairRequestId, onClose, loadNotes = false, completeTask, currentTaskWorkFlow, }) => {
    const { fetchNotesAppliesTo, fetchNotesAppliesToRecommendedRepairs, notesAppliesTo, notesAppliesToRecommendedRepairs, saveNotes, notesAppliesToLoading, notesAppliesToRecommendedRepairsLoading, saveNotesLoading, onLoadData, pagination, notes, } = useNotes(repairRequestId);
    const data = [
        ...notesAppliesTo,
        ...notesAppliesToRecommendedRepairs,
    ];
    const { validationSchema, handleSubmitForm, INITIAL_VALUES, findNote, extractFromNote, suggestedRepairs, isMobile, truncateString, missing3cNotes, } = useNotes3CDrawerContent({
        repairRequestId,
        loadNotes,
        completeTask,
        currentTaskWorkFlow,
        pagination,
        notes,
        data,
        type,
        fetchNotesAppliesTo,
        fetchNotesAppliesToRecommendedRepairs,
        onLoadData,
        saveNotes,
        onClose,
    });
    const isLoading = notesAppliesToLoading ||
        notesAppliesToRecommendedRepairsLoading ||
        saveNotesLoading;
    return (<Formik initialValues={INITIAL_VALUES} validationSchema={validationSchema} onSubmit={handleSubmitForm} validateOnChange>
      {({ isSubmitting, isValid, isValidating, setFieldValue, setFieldTouched, handleSubmit, values, touched, dirty, }) => (<>
          <SimpleDrawerContent>
            <Grid container>
              <DrawerFormRow item xs={12}>
                <SubHeader>Concern, Cause, Correction</SubHeader>
                <Instructions>
                  Select the customer’s concerns and document the causes and
                  corrections.
                </Instructions>
              </DrawerFormRow>
              <DrawerFormRow item xs={12}>
                <TextField select fullWidth label="Concern" name="concern" variant="outlined" value={values.concern} error={touched.concern && values.concern.length === 0} onBlur={() => setFieldTouched('concern')} onChange={(e) => {
                setFieldValue('concern', e.target.value);
                const existingNote = findNote(e.target.value);
                if (existingNote) {
                    const noteObj = extractFromNote(existingNote.content);
                    setFieldValue('cause', noteObj === null || noteObj === void 0 ? void 0 : noteObj.cause);
                    setFieldValue('correction', noteObj === null || noteObj === void 0 ? void 0 : noteObj.correction);
                }
                else {
                    setFieldValue('cause', '');
                    setFieldValue('correction', '');
                }
            }}>
                  {suggestedRepairs.map(({ id, name }) => (<MenuItem key={id} value={`${id}:${name}`}>
                      <MenuItemInner>
                        <MenuItemContent>
                          <span>
                            {isMobile ? truncateString(name, 35) : name}
                          </span>
                        </MenuItemContent>
                        {findNote(id === null || id === void 0 ? void 0 : id.toString()) && (<CheckCircleRoundedIcon className="success"/>)}
                      </MenuItemInner>
                    </MenuItem>))}
                </TextField>
                {(missing3cNotes === null || missing3cNotes === void 0 ? void 0 : missing3cNotes.repairMissingNotes.length) ? (<InputInfo>
                    {`${missing3cNotes === null || missing3cNotes === void 0 ? void 0 : missing3cNotes.repairMissingNotes.length} Remaining`}
                  </InputInfo>) : null}
              </DrawerFormRow>
              {values.concern &&
                (findNote(values.concern) ? (<>
                    <DrawerFormRow item xs={12}>
                      <InputLabel>Document Cause</InputLabel>
                      <p>{values.cause}</p>
                    </DrawerFormRow>
                    <DrawerFormRow item xs={12}>
                      <InputLabel>Document Correction</InputLabel>
                      <p>{values.correction}</p>
                    </DrawerFormRow>
                  </>) : (<>
                    <DrawerFormRow item xs={12}>
                      <InputLabel>Document Cause</InputLabel>
                      <TextField variant="outlined" fullWidth multiline minRows={5} InputProps={{
                        style: { padding: '12px' },
                        readOnly: Boolean(findNote(values.concern)),
                    }} placeholder="Enter text here..." name="cause" type="text" value={values.cause} onChange={(e) => {
                        setFieldValue('cause', e.target.value);
                    }}/>
                      {!findNote(values.concern) && (<InputInfo>
                          Characters ({`${values.cause.length}`} / 500)
                        </InputInfo>)}
                    </DrawerFormRow>
                    <DrawerFormRow item xs={12}>
                      <InputLabel>Document Correction</InputLabel>
                      <TextField variant="outlined" fullWidth multiline minRows={5} InputProps={{
                        style: { padding: '12px' },
                        readOnly: Boolean(findNote(values.concern)),
                    }} placeholder="Enter text here..." name="correction" type="text" value={values.correction} onChange={(e) => {
                        setFieldValue('correction', e.target.value);
                    }}/>
                      {!findNote(values.concern) && (<InputInfo>
                          Characters ({`${values.correction.length}`} / 500)
                        </InputInfo>)}
                    </DrawerFormRow>
                  </>))}
            </Grid>
            <FooterInfo>This documentation will be customer facing.</FooterInfo>
          </SimpleDrawerContent>
          <SimpleDrawerFooter>
            {(missing3cNotes === null || missing3cNotes === void 0 ? void 0 : missing3cNotes.repairMissingNotes.length) ? (<FooterActionButton disabled={(!isValid ||
                    isValidating ||
                    isSubmitting ||
                    isLoading ||
                    !dirty ||
                    findNote(values.concern) !== undefined) &&
                    (missing3cNotes === null || missing3cNotes === void 0 ? void 0 : missing3cNotes.repairMissingNotes.length) === 0} onClick={handleSubmit}>
                Submit
              </FooterActionButton>) : (<FooterActionButton onClick={onClose}>Go Back</FooterActionButton>)}
          </SimpleDrawerFooter>
        </>)}
    </Formik>);
};
export default Notes3CDrawerContent;
