import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Divider, Grid } from 'src/components/mui';
import { DeleteButton } from 'src/components/buttons';
import { ActionCreators } from 'src/AdminApp/modules/quickRo/actions';
const RepairItemStyled = styled(Grid) `
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
`;
const RepairItem = ({ repair }) => {
    const dispatch = useDispatch();
    const handleRemove = () => {
        dispatch(ActionCreators.TAB.REMOVE_REPAIR({
            payload: { repairId: repair.repairId },
        }));
    };
    return (<>
      <Divider />
      <RepairItemStyled container style={{ alignItems: 'center' }}>
        <Grid item xs>
          {repair.label}
        </Grid>
        <Grid item>
          <DeleteButton onClick={handleRemove}/>
        </Grid>
      </RepairItemStyled>
    </>);
};
export default RepairItem;
