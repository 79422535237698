var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import NumberFormat from 'react-number-format';
const PriceInput = (_a) => {
    var { decimalScale = 2, inputRef, name, onChange, prefix = '$ ', thousandSeparator = true } = _a, other = __rest(_a, ["decimalScale", "inputRef", "name", "onChange", "prefix", "thousandSeparator"]);
    const handleChange = (values) => {
        onChange({
            target: {
                name,
                value: values.value,
            },
        });
    };
    return (<NumberFormat {...other} decimalScale={decimalScale} getInputRef={inputRef} name={name} prefix={prefix} thousandSeparator={thousandSeparator} onValueChange={handleChange}/>);
};
export default PriceInput;
