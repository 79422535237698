import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { Button, CardContent } from 'src/components/mui';
import { RoomRounded as RoomRoundedIcon } from 'src/components/mui/icons';
export const AppointmentTable = styled.table `
  width: 100%;
  border-collapse: collapse;
  margin-top: 24px;

  thead td {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
    padding-bottom: 8px;
    border-bottom: 1px solid ${colors.GRAY_DE};
  }

  tbody td {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    padding: 24px 0;
  }
`;
export const VisitCardContent = styled(CardContent) `
  padding: 0 !important;
`;
export const VisitSection = styled.div `
  padding: 20px 12px;
  border-bottom: solid 1px ${colors.GRAY_DE};
`;
export const VisitSectionLocation = styled.div `
  padding: 20px 12px;
`;
export const VisitInfoTable = styled.table `
  width: 100%;

  td {
    vertical-align: top;
  }

  tr:first-child td {
    padding-bottom: 18px;
  }
`;
export const StartDateLabel = styled.div `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
`;
export const DurationLabel = styled.div `
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
`;
export const StatusLabel = styled.span `
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  padding: 4px 5px;
  color: ${colors.white};
  background-color: ${colors.GRAY_9C};
  border-radius: 4px;
`;
export const InfoLabel = styled.div `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${colors.GRAY_76};
  margin-bottom: 5px;
`;
export const InfoValue = styled.div `
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.3px;

  a {
    text-decoration: none;
  }
`;
export const GoogleMapReactWrapper = styled.div `
  width: 100%;
  height: 350px;
  margin-top: 24px;
`;
export const MapPin = styled(RoomRoundedIcon) `
  color: ${colors.BLUE};
  width: 30px;
  height: 30px;
  position: relative;
  top: -15px;
  left: -15px;
`;
export const ViewVisitButton = styled(Button) `
  width: 100%;
  color: ${colors.BLUE};
  background: ${colors.GRAY_FA};
  padding: 16px 0;
`;
