import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CarCard from 'src/AdminApp/containers/vehicle/CarModule/CarCard';
import { MobileEditFieldDrawer } from 'src/AdminApp/containers/vehicle/CarModule/MobileEditFieldDrawer';
import { VIN_LENGTH } from 'src/AdminApp/modules/api/constants';
import { getVehicleDescriptionByVin } from 'src/AdminApp/modules/autoselection/actions';
import { selectVehiclesByVin } from 'src/AdminApp/modules/autoselection/selectors';
import { Button, Grid, InputAdornment, TextField, Typography, } from 'src/components/mui';
const VinFieldEdit = (props) => {
    const dispatch = useDispatch();
    const [vin, setVin] = useState(props.values.vin);
    const [vinError, setVinError] = useState();
    const vehiclesByVin = useSelector(selectVehiclesByVin);
    const decodedVehicle = vehiclesByVin[vin];
    const onDecodeVin = () => {
        dispatch(getVehicleDescriptionByVin({ vin })).then((result) => {
            var _a;
            if ((_a = result.payload.status) !== null && _a !== void 0 ? _a : null === 404) {
                setVinError('VIN does not exist.');
            }
        });
    };
    return (<MobileEditFieldDrawer {...props} label="VIN" onSave={() => props.onSave({})}>
      <Grid item xs={12}>
        <TextField variant="outlined" value={vin} onChange={({ target }) => {
            setVinError(undefined);
            setVin(target.value);
        }} label="VIN" type="text" name="vin" fullWidth error={!!vinError} InputProps={{
            endAdornment: (vin === null || vin === void 0 ? void 0 : vin.length) === VIN_LENGTH && (<InputAdornment position="end">
                <Button onClick={onDecodeVin} color="primary">
                  Decode
                </Button>
              </InputAdornment>),
        }}/>
      </Grid>
      {decodedVehicle && (<Grid item xs={12}>
          <Typography variant="subtitle1">
            VIN Decoded Vehicle Details
          </Typography>
          <CarCard car={decodedVehicle} actions={<Button onClick={() => {
                    props.onSave(Object.assign(Object.assign(Object.assign({}, props.values), decodedVehicle), { trim: decodedVehicle.style }));
                }} color="primary">
                Select Car
              </Button>}/>
        </Grid>)}
    </MobileEditFieldDrawer>);
};
export default VinFieldEdit;
