var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { updateCar, updateContact, updateRequest, } from 'src/AdminApp/modules/requests/actions';
import { loadMarketingSources } from 'src/AdminApp/modules/lookups/actions';
import { getInspectionPerformedStatus } from 'src/AdminApp/modules/mpi/actions';
import useIsMobile from 'src/hooks/useIsMobile';
const useContactInfo = (referenceNum, repairRequestId, name, phone, email, vehicle, consumerContactInfo, fleet, logo, marketingSource, id) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    useEffect(() => {
        dispatch(loadMarketingSources());
    }, []); // eslint-disable-line
    useEffect(() => {
        if (isMobile) {
            dispatch(getInspectionPerformedStatus(referenceNum));
        }
    }, [isMobile]); // eslint-disable-line
    const onUpdateContact = (key) => (value) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield dispatch(updateContact({
            referenceNum,
            name,
            phone,
            email,
            [key]: value,
        }));
        if (!(result === null || result === void 0 ? void 0 : result.error)) {
            toast.success('Consumer Information successfully updated.');
        }
    });
    const onUpdateCar = (car) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield dispatch(updateCar({
            referenceNum,
            vehicle: car,
            consumerCarId: vehicle.consumerCarId,
            consumerId: id,
            repairRequestId,
        }));
        if (!(result === null || result === void 0 ? void 0 : result.error)) {
            toast.success('Car Information successfully updated.');
        }
    });
    const updateMarketingSource = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield dispatch(updateRequest({
            marketingSource: { id },
        }, referenceNum));
        if (!(result === null || result === void 0 ? void 0 : result.error)) {
            toast.success('Request successfully updated.');
        }
    });
    return {
        isMobile,
        onUpdateContact,
        onUpdateCar,
        updateMarketingSource,
    };
};
export default useContactInfo;
