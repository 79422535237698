import styled from 'styled-components';
import { Button, Popper, MenuItem } from 'src/components/mui';
import { FONT_FAMILY } from 'src/styles/theme';
export const VisitActionButtonsWrapper = styled.section `
  margin-top: 32px;
  margin-bottom: 32px;
`;
export const AddRequestButton = styled(Button) `
  padding: 16px 29px;
  font-family: ${FONT_FAMILY};
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
`;
export const AddRoPopper = styled(Popper) `
  z-index: 2;
`;
export const AddRoMenuItem = styled(MenuItem) `
  width: 220px;
`;
