import { debounce } from 'lodash';
import moment from 'moment/moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { clearTechniciansForAutocomplete, getTechniciansForAutocomplete, } from 'src/AdminApp/modules/users/actions';
import { selectTechniciansForAutocomplete } from 'src/AdminApp/modules/users/selectors';
import EditableSearchUsers from 'src/components/form/EditableSearchUsers';
import { DatePicker, DateTimePicker, Switch } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
const ManualEntryContainerStyled = styled.div `
  display: flex;
  flex-direction: column;
  padding-top: 12px;
`;
const ManualEntryContainer = ({ manualEntryMechanic, setManualEntryMechanic, handleManualMechanicSelection, selectedDateTime, setSelectedDateTime, scheduleFutureAppointmentsCheck, handleSwitchChange, isDateTimePickerOpen, setIsDateTimePickerOpen, selectedFutureAppointmentDate, setSelectedFutureAppointmentDate, selectedMinFutureAppointmentDate, disableDateTimePicker, enableSwitch, }) => {
    const dispatch = useDispatch();
    const debouncedTechnicianSearch = debounce((value) => {
        dispatch(getTechniciansForAutocomplete(value));
    }, 500);
    const handleManualMechanicSearchUpdate = (value) => {
        if (value.length > 0) {
            debouncedTechnicianSearch(value);
        }
        else {
            dispatch(clearTechniciansForAutocomplete());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    return (<ManualEntryContainerStyled>
      <EditableSearchUsers value={manualEntryMechanic} label="Technician" defaultValue="Technician" usersSelector={selectTechniciansForAutocomplete} onChange={handleManualMechanicSearchUpdate} onUpdate={(value) => {
            handleManualMechanicSelection(value.serviceAdvisorUid);
            setManualEntryMechanic(value.serviceAdvisorName);
            setSelectedDateTime(moment());
        }} requireId doubleClickToOpen={false} disabled={scheduleFutureAppointmentsCheck} style={{
            border: `1px solid ${colors.GRAY_CC}`,
            margin: 0,
            borderRadius: 5,
            padding: 16,
            fontWeight: !manualEntryMechanic ? 'bold' : 'normal',
            color: !manualEntryMechanic ? colors.GRAY_97 : colors.default,
        }}/>
      {scheduleFutureAppointmentsCheck ? (<DatePicker disablePast label="Start Date" minDate={moment(selectedMinFutureAppointmentDate)} value={moment(selectedFutureAppointmentDate)} onChange={setSelectedFutureAppointmentDate} disabled={disableDateTimePicker} open={isDateTimePickerOpen} onClose={() => setIsDateTimePickerOpen(false)} slotProps={{
                textField: {
                    variant: 'outlined',
                    margin: 'normal',
                    id: 'scheduling-date-time-picker',
                    style: { marginTop: '24px' },
                    onClick: disableDateTimePicker
                        ? () => { }
                        : () => setIsDateTimePickerOpen(true),
                },
                field: {
                    clearable: true,
                },
            }}/>) : (<DateTimePicker label="Start Date/Time" value={selectedDateTime} onChange={setSelectedDateTime} disabled={disableDateTimePicker} open={isDateTimePickerOpen} onClose={() => setIsDateTimePickerOpen(false)} slotProps={{
                textField: {
                    variant: 'outlined',
                    margin: 'normal',
                    id: 'scheduling-date-time-picker',
                    style: { marginTop: '24px' },
                    onClick: disableDateTimePicker
                        ? () => { }
                        : () => setIsDateTimePickerOpen(true),
                },
                field: {
                    clearable: true,
                },
            }}/>)}
      <Tooltip title="Future appointments are only enabled in regions where max capacity > 0.">
        <div>
          Schedule Future Appointment
          <Switch color="primary" disabled={!enableSwitch} checked={scheduleFutureAppointmentsCheck} onChange={handleSwitchChange} inputProps={{ 'aria-label': 'controlled' }}/>
        </div>
      </Tooltip>
    </ManualEntryContainerStyled>);
};
export default ManualEntryContainer;
