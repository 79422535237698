import React from 'react';
import { Box } from 'src/components/mui';
import { SimpleModal } from 'src/components/layout';
import { PrimaryButton } from 'src/components/buttons';
import SkillsDashboard from './components/SkillsDashboard';
import SkillsDrawer from './components/SkillsDrawer';
import useSkills from './useSkills';
const Skills = () => {
    const { skills, currentSkill, isModalOpen, isDeleteConfirmOpen, setIsDeleteConfirmOpen, setIsModalOpen, onEditSkill, removeSkill, handleAddSkill, handleUpdateSkill, onDeleteSkill, } = useSkills();
    return (<>
      <Box marginBottom={1} display="flex" style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <PrimaryButton style={{ marginBottom: '10px' }} onClick={() => setIsModalOpen(true)}>
          Add New Skill
        </PrimaryButton>
      </Box>
      <SkillsDrawer open={isModalOpen} editSkill={currentSkill} editMode={!!currentSkill} onClose={() => setIsModalOpen(false)} onAddSkill={handleAddSkill} onUpdateSkill={handleUpdateSkill}/>
      <SkillsDashboard skills={skills} onEdit={onEditSkill} onDelete={(skillId) => onDeleteSkill(skillId)}/>
      <SimpleModal title="Are you sure you want to delete this skill?" open={isDeleteConfirmOpen} onClose={() => setIsDeleteConfirmOpen(false)} simpleTwoButtonRowProps={{
            onCancel: () => setIsDeleteConfirmOpen(false),
            onSubmit: () => {
                setIsDeleteConfirmOpen(false);
                removeSkill();
            },
            submitText: 'Confirm',
        }}>
        This skill will also be deleted from any vans/techs/services that have
        it.
      </SimpleModal>
    </>);
};
export default Skills;
