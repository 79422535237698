import React from 'react';
import { Typography } from 'src/components/mui';
import { TIME_12H_FORMAT } from 'src/timeConstants';
import { SlotRow } from '../styles';
import { RowContainer, TimeContainer, Subtitle, Details, Btn, } from './TimeSlot.styles';
import useTimeSlot from './useTimeSlot';
const TimeSlot = ({ date, isFirstAvailable, slot, time, mechanicFilterEnabled, }) => {
    var _a;
    const { enableTimeSlot, appointmentTime, vanId, startTime, slotEnd, btnText, mechanics, van, handleClick, } = useTimeSlot({
        date,
        slot,
        time,
        mechanicFilterEnabled,
    });
    return (<SlotRow>
      <RowContainer>
        <TimeContainer>
          <Typography variant="body2" title={`Appointment Time: ${appointmentTime.format(TIME_12H_FORMAT) || '...'}\n\nVan ID: ${vanId || '...'}`}>
            {startTime.format(TIME_12H_FORMAT)}
            {' - '}
            {slotEnd.format(TIME_12H_FORMAT)}
          </Typography>
          {((_a = mechanics === null || mechanics === void 0 ? void 0 : mechanics.length) !== null && _a !== void 0 ? _a : 0) > 0 && van && (<Details variant="subtitle1">
              <span title={`Mechanics: ${mechanics === null || mechanics === void 0 ? void 0 : mechanics.map((mechanic) => `${mechanic.name} / `)}\n\nSkedulo ID: ${(mechanics === null || mechanics === void 0 ? void 0 : mechanics.map((mechanic) => `${mechanic.skeduloResourceId}, `)) || 'TBD'}\n\nTags: ${(mechanics === null || mechanics === void 0 ? void 0 : mechanics.map((mechanic) => mechanic.tags && mechanic.tags.join(', '))) || 'None'}`}>
                {(mechanics === null || mechanics === void 0 ? void 0 : mechanics.map((mechanic) => `${mechanic.name} / `)) ||
                'Mechanic TBD'}
              </span>
              <span title={`Van: ${van.name}\n\nSkedulo ID: ${van.skeduloResourceId || 'TBD'}\n\nTags: ${(van.tags && van.tags.join(', ')) || 'None'}`}>
                {(van === null || van === void 0 ? void 0 : van.name) || 'Van TBD'}
              </span>
            </Details>)}
          {isFirstAvailable && (<Subtitle variant="subtitle1">First Available</Subtitle>)}
        </TimeContainer>
        <Btn color="primary" onClick={handleClick} variant="contained" disabled={!enableTimeSlot}>
          {btnText}
        </Btn>
      </RowContainer>
    </SlotRow>);
};
export default TimeSlot;
