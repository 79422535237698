var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { isEmpty } from 'lodash';
import NotesEntityType from 'src/AdminApp/models/enums/NotesEntityTypes';
import NotesOriginType from 'src/AdminApp/models/enums/NotesOriginTypes';
import vinValidator from 'vin-validator';
import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { calculateLatLong, getTimezoneByMapBox, } from 'src/clients/mapboxClient';
import { normalizeAddress } from 'src/AdminApp/modules/utils';
import { toast } from 'src/components/SimpleToast';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
// FSA ACTIONS
export const ActionCreators = {
    ADD_CONSUMER: createDefaultActionCreators('ADD_CONSUMER'),
    ADD_REQUEST: createDefaultActionCreators('ADD_REQUEST'),
    ADD_VEHICLE: createDefaultActionCreators('ADD_VEHICLE'),
    CONSUMER: createDefaultActionCreators('CONSUMER'),
    CONSUMERS: createDefaultActionCreators('CONSUMERS', ['CLEAR']),
    CONSUMER_UPDATE: createDefaultActionCreators('CONSUMER_UPDATE'),
    CONSUMER_UPDATE_ADDRESS: createDefaultActionCreators('CONSUMER_UPDATE_ADDRESS'),
    CONSUMER_GET_NOTES: createDefaultActionCreators('CONSUMER_GET_NOTES'),
    CONSUMER_UPDATE_NOTES: createDefaultActionCreators('CONSUMER_UPDATE_NOTES'),
    CONSUMER_CREATE_NOTES: createDefaultActionCreators('CONSUMER_CREATE_NOTES'),
    GET_CONSUMER_CAR: createDefaultActionCreators('GET_CONSUMER_CAR'),
    CONSUMER_CAR_UPDATE: createDefaultActionCreators('CONSUMER_CAR_UPDATE'),
    CONSUMER_PASSWORD_RESET: createDefaultActionCreators('CONSUMER_PASSWORD_RESET'),
    CONSUMER_VEHICLES: createDefaultActionCreators('CONSUMER_VEHICLES'),
    CONSUMER_REQUEST_HISTORY: createDefaultActionCreators('CONSUMER_REQUEST_HISTORY'),
    RO_CREDITS: createDefaultActionCreators('RO_CREDITS'),
    CANCEL_FEE_CREDITS: createDefaultActionCreators('CANCEL_FEE_CREDITS'),
    RESOLVE_ZENDESK_USER_ID: createDefaultActionCreators('RESOLVE_ZENDESK_USER_ID'),
    CONSUMER_CAR_GET_TOKENS: createDefaultActionCreators('CONSUMER_CAR_GET_TOKENS'),
    ADD_PAYMENT_METHOD: createDefaultActionCreators('ADD_PAYMENT_METHOD'),
    VALIDATE_VIN_PLATE: createDefaultActionCreators('VALIDATE_VIN_PLATE'),
    PUT_WARRANTY_QUESTIONS: createDefaultActionCreators('PUT_WARRANTY_QUESTIONS'),
    CONSUMER_CREATE_FIREBASE_USER: createDefaultActionCreators('CONSUMER_CREATE_FIREBASE_USER'),
};
export const addConsumer = ({ firstName, lastName, phone, email, marketingSourceId = null, }) => {
    return createAPIAction({
        types: [
            ActionCreators.ADD_CONSUMER.FETCH(),
            ActionCreators.ADD_CONSUMER.SUCCESS(),
            ActionCreators.CONSUMERS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.ADD_CONSUMER(),
        body: {
            firstName,
            lastName,
            phone,
            email,
            marketingSourceId,
        },
        method: 'POST',
    });
};
export const addRequest = ({ mileage, address, serviceDescription, consumerCarId, consumerId, repairId, marketingSource, }) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const normalizedAddress = normalizeAddress(address);
    const geocode = normalizedAddress
        ? yield calculateLatLong({
            zip: normalizedAddress.zip,
            address: normalizedAddress,
        })
        : { latitude: 0, longitude: 0 };
    const timezone = yield getTimezoneByMapBox((_a = geocode.latitude) !== null && _a !== void 0 ? _a : 0, (_b = geocode.longitude) !== null && _b !== void 0 ? _b : 0);
    const body = {
        mileage,
        serviceDescription,
        repairId,
        consumerCarId,
        consumerId,
        marketingSource: { id: marketingSource },
        address: Object.assign(Object.assign(Object.assign({}, normalizedAddress), geocode), { timezone }),
    };
    return dispatch(createAPIAction({
        types: [
            ActionCreators.ADD_REQUEST.FETCH(),
            ActionCreators.ADD_REQUEST.SUCCESS({
                meta: () => ({ consumerId, consumerCarId, mileage, repairId }),
            }),
            ActionCreators.ADD_REQUEST.FAILURE(),
        ],
        body,
        endpoint: API_URLS.ADMIN.REQUESTS(),
        method: 'POST',
    }));
});
export const getConsumers = ({ id, zip, createdFrom, createdTo, query, pageSize, pageIndex, sort = ['id,asc'], signal, }) => {
    const params = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort })), (!!id && !isEmpty(id) && { id })), (!!zip && !isEmpty(zip) && { zip })), (!!createdFrom && { createdFrom })), (!!createdTo && { createdTo })), (!!query && !isEmpty(query) && { query }));
    const patchedParams = Object.assign(Object.assign({}, (!!createdFrom && {
        createdFrom: moment(createdFrom).startOf('day').utc().format(),
    })), (!!createdTo && {
        createdTo: moment(createdTo).startOf('day').add(1, 'days').utc().format(),
    }));
    return createAPIAction({
        types: [
            ActionCreators.CONSUMERS.FETCH({
                meta: () => ({ id, params, pageSize, pageIndex, sort }),
            }),
            ActionCreators.CONSUMERS.SUCCESS({
                meta: () => ({ id, params, pageSize, pageIndex, sort }),
            }),
            ActionCreators.CONSUMERS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.CONSUMERS_SEARCH(),
        params: Object.assign(Object.assign({}, params), patchedParams),
        method: 'GET',
        signal,
    });
};
export const getConsumerById = (consumerId) => createAPIAction({
    types: [
        ActionCreators.CONSUMER.FETCH(),
        ActionCreators.CONSUMER.SUCCESS(),
        ActionCreators.CONSUMER.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CONSUMER({ consumerId }),
    method: 'GET',
});
export const updateConsumer = (values) => createAPIAction({
    types: [
        ActionCreators.CONSUMER_UPDATE.FETCH(),
        ActionCreators.CONSUMER_UPDATE.SUCCESS({
            meta: () => ({ consumerId: values.id }),
        }),
        ActionCreators.CONSUMER_UPDATE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CONSUMER(),
    method: 'PUT',
    body: Object.assign({}, values),
});
export const addVehicle = ({ consumerId, vehicle, }) => createAPIAction({
    types: [
        ActionCreators.ADD_VEHICLE.FETCH(),
        ActionCreators.ADD_VEHICLE.SUCCESS({
            meta: () => ({ consumerId }),
        }),
        ActionCreators.ADD_VEHICLE.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ADD_VEHICLE({ consumerId }),
    method: 'POST',
    body: vehicle,
});
export const resolveZendeskUserId = (consumerId) => createAPIAction({
    types: [
        ActionCreators.RESOLVE_ZENDESK_USER_ID.FETCH(),
        ActionCreators.RESOLVE_ZENDESK_USER_ID.SUCCESS({
            meta: () => ({ consumerId }),
        }),
        ActionCreators.RESOLVE_ZENDESK_USER_ID.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CONSUMER_ZENDESK_ID(consumerId),
    method: 'POST',
    body: {},
});
export const getVehiclesByConsumerId = (consumerId, page, size = 20, isDrawer = false) => {
    const pagination = { page, size, sort: ['id'] };
    return createAPIAction({
        types: [
            ActionCreators.CONSUMER_VEHICLES.FETCH({
                meta: () => ({ consumerId, pagination }),
            }),
            ActionCreators.CONSUMER_VEHICLES.SUCCESS({
                meta: () => ({ consumerId, pagination, isDrawer }),
            }),
            ActionCreators.CONSUMER_VEHICLES.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.CONSUMER_CARS({ consumerId, pagination }),
        method: 'GET',
    });
};
export const getConsumerRequestHistory = (consumerId, pagination = { page: 0, size: 20, sort: ['created,desc'] }) => createAPIAction({
    types: [
        ActionCreators.CONSUMER_REQUEST_HISTORY.FETCH({
            meta: () => ({ consumerId, pagination }),
        }),
        ActionCreators.CONSUMER_REQUEST_HISTORY.SUCCESS({
            meta: () => ({ consumerId, pagination }),
        }),
        ActionCreators.CONSUMER_REQUEST_HISTORY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CONSUMER_REQUEST_HISTORY({
        consumerId,
        pagination,
    }),
    method: 'GET',
});
export const loadConsumer = (consumerId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    yield dispatch(getConsumerById(consumerId));
    dispatch(getConsumerRequestHistory(consumerId));
});
export const getCancelFeeCreditsByConsumerId = ({ consumerId, workOrderId, }) => createAPIAction({
    types: [
        ActionCreators.CANCEL_FEE_CREDITS.FETCH(),
        ActionCreators.CANCEL_FEE_CREDITS.SUCCESS({
            meta: () => ({ consumerId, workOrderId }),
        }),
        ActionCreators.CANCEL_FEE_CREDITS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CANCEL_FEE_CREDITS({ consumerId }),
    params: {
        workOrderId,
    },
    method: 'GET',
});
export const updateConsumerCar = ({ consumerId, consumerCarId, car, updateVin = false, }) => {
    const { engineDescription, engineId, make, mileage, mileageOut, model, plateNumber, plateNumberState, residualValue, trim, vin, year, } = car;
    const body = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!make && { make })), (!!model && { model })), (!!year && { year })), (!!trim && { trim })), (!!engineId && { engineId })), (!!engineDescription && { engineDescription })), (!!mileage && { mileage })), (!!mileageOut && { mileageOut })), (!!plateNumber && { plateNumber })), (!!vin && { vin })), (!!plateNumberState && { plateNumberState })), (!!residualValue && { residualValue }));
    if (!updateVin && vin && !vinValidator.validate(vin)) {
        toast.error('Invalid VIN');
        return ActionCreators.CONSUMER_CAR_UPDATE.FAILURE();
    }
    return createAPIAction({
        types: [
            ActionCreators.CONSUMER_CAR_UPDATE.FETCH({
                meta: () => ({ consumerId, consumerCarId }),
            }),
            ActionCreators.CONSUMER_CAR_UPDATE.SUCCESS({
                meta: () => ({ consumerId, consumerCarId }),
            }),
            ActionCreators.CONSUMER_CAR_UPDATE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.CONSUMER_CAR({ consumerId, consumerCarId }),
        method: 'PATCH',
        body,
    });
};
export const updateConsumerAddress = ({ consumerId, address, }) => createAPIAction({
    types: [
        ActionCreators.CONSUMER_UPDATE_ADDRESS.FETCH(),
        ActionCreators.CONSUMER_UPDATE_ADDRESS.SUCCESS({
            meta: () => ({ consumerId, address }),
        }),
        ActionCreators.CONSUMER_UPDATE_ADDRESS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CONSUMER_UPDATE_ADDRESS({ consumerId }),
    body: address,
    method: 'POST',
});
export const putWarrantyQuestions = ({ referenceNum, warrantyClaimId, request, }) => createAPIAction({
    types: [
        ActionCreators.PUT_WARRANTY_QUESTIONS.FETCH(),
        ActionCreators.PUT_WARRANTY_QUESTIONS.SUCCESS({
            meta: () => ({ referenceNum, warrantyClaimId, request }),
        }),
        ActionCreators.CONSUMER_UPDATE_ADDRESS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.PUT_WARRANTY_QUESTIONS({
        referenceNum,
        warrantyClaimId,
    }),
    body: request,
    method: 'PUT',
});
export const passwordReset = (email) => createAPIAction({
    types: [
        ActionCreators.CONSUMER_PASSWORD_RESET.FETCH(),
        ActionCreators.CONSUMER_PASSWORD_RESET.SUCCESS({
            meta: () => ({ email }),
        }),
        ActionCreators.CONSUMER_PASSWORD_RESET.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CONSUMER_PASSWORD_RESET(),
    body: { email },
    method: 'POST',
});
export const getConsumerNotes = (consumerId) => createAPIAction({
    types: [
        ActionCreators.CONSUMER_GET_NOTES.FETCH(),
        ActionCreators.CONSUMER_GET_NOTES.SUCCESS({
            meta: () => ({ consumerId }),
        }),
        ActionCreators.CONSUMER_GET_NOTES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.NOTES_BY_ID_TYPE_ORIGIN({
        entityId: consumerId.toString(),
        entityType: NotesEntityType.CONSUMER,
        origin: NotesOriginType.TOOLS,
    }),
    method: 'GET',
});
export const validateVinOrPlate = ({ consumerId, vin, plate, state, }) => {
    const params = [];
    if (vin === null || vin === void 0 ? void 0 : vin.length)
        params.push(`vin=${vin}`);
    if (plate === null || plate === void 0 ? void 0 : plate.length)
        params.push(`plate=${plate}`);
    if (state === null || state === void 0 ? void 0 : state.length)
        params.push(`state=${state}`);
    return createAPIAction({
        types: [
            ActionCreators.VALIDATE_VIN_PLATE.FETCH(),
            ActionCreators.VALIDATE_VIN_PLATE.SUCCESS(),
            ActionCreators.VALIDATE_VIN_PLATE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.VALIDATE_VIN_PLATE({
            consumerId,
            params: params.join('&'),
        }),
        method: 'GET',
    });
};
export const createConsumerNotes = (consumerId, note) => createAPIAction({
    types: [
        ActionCreators.CONSUMER_CREATE_NOTES.FETCH(),
        ActionCreators.CONSUMER_CREATE_NOTES.SUCCESS({
            meta: () => ({ consumerId }),
        }),
        ActionCreators.CONSUMER_CREATE_NOTES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.NOTES(),
    method: 'POST',
    body: {
        content: note,
        origin: NotesOriginType.TOOLS,
        entityType: NotesEntityType.CONSUMER,
        entityId: consumerId,
    },
});
export const updateConsumerNotes = (noteId, note) => createAPIAction({
    types: [
        ActionCreators.CONSUMER_UPDATE_NOTES.FETCH(),
        ActionCreators.CONSUMER_UPDATE_NOTES.SUCCESS({
            meta: () => ({ noteId }),
        }),
        ActionCreators.CONSUMER_UPDATE_NOTES.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.NOTES_BY_ID(noteId),
    method: 'PATCH',
    body: note,
});
export const getCarTokens = (consumerId, consumerCarId) => createAPIAction({
    types: [
        ActionCreators.CONSUMER_CAR_GET_TOKENS.FETCH(),
        ActionCreators.CONSUMER_CAR_GET_TOKENS.SUCCESS({
            meta: () => ({ consumerId, consumerCarId }),
        }),
        ActionCreators.CONSUMER_CAR_GET_TOKENS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CONSUMER_CAR_TOKENS(consumerCarId),
    method: 'GET',
});
export const addPaymentMethod = (data) => createAPIAction({
    types: [
        ActionCreators.ADD_PAYMENT_METHOD.FETCH(),
        ActionCreators.ADD_PAYMENT_METHOD.SUCCESS({
            meta: () => ({ consumerId: data.consumerId }),
        }),
        ActionCreators.ADD_PAYMENT_METHOD.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ADD_PAYMENT_METHOD(),
    method: 'POST',
    body: data,
});
export const createFirebaseUser = (body) => createAPIAction({
    types: fillApiTypes(ActionCreators.CONSUMER_CREATE_FIREBASE_USER),
    endpoint: API_URLS.ADMIN.CONSUMER_CREATE_FIREBASE_USER(),
    method: 'POST',
    body,
});
