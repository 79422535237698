import styled from 'styled-components';
export const Wrapper = styled.div `
  padding: 5px 20px;
`;
export const TableWrapper = styled.div `
  margin-top: 20px;
  margin-bottom: 24px;
`;
export const AddButtonsWrapper = styled.div `
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;
  justify-content: flex-end;
`;
