export const getVendorInfo = (componentPartDistributor, notes) => {
    if (!componentPartDistributor)
        return '';
    return `\n ${componentPartDistributor
        ? `${componentPartDistributor.partDistributorName}; `
        : ''}${(componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.dealershipName)
        ? `${componentPartDistributor.dealershipName}; `
        : ''}
    ${(componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.address)
        ? `${componentPartDistributor.address}; `
        : ''}
    ${notes !== null && notes !== void 0 ? notes : ''}`;
};
export const statusesWithoutInfo = ['NOT_NEEDED'];
