var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useSubmitQuickRO from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/useSubmitQuickRO';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { selectCustomAddress, selectCustomUser, selectCustomVehicle, selectDateTime, selectFutureAppointmentDate, selectFutureAppointmentDateCheck, selectNextTab, selectTab, selectTimeSlot, selectUser, selectVanId, } from 'src/AdminApp/modules/quickRo/selectors';
import { selectVehiclesByConsumerId, selectVehiclesFetchingByConsumerId, selectVehiclesPaginationByConsumerId, } from 'src/AdminApp/modules/consumers/selectors';
import { addConsumer, addVehicle, getVehiclesByConsumerId, updateConsumerAddress, } from 'src/AdminApp/modules/consumers/actions';
import { ActionCreators, setContinueDisabled, setCustomConsumerAddress, setCustomUser, setNextTab, setRepairs, setTab, saveUser, setVehicle, } from 'src/AdminApp/modules/quickRo/actions';
import { CONSUMER_TABS as TABS } from 'src/AdminApp/modules/quickRo/enums';
import { find } from 'lodash';
import { scheduleFutureAppointment, } from 'src/AdminApp/modules/requests/actions';
import { toast } from 'src/components/SimpleToast';
import { delayFor } from 'src/utils/delay';
import { parseStringNumber } from 'src/utils/data-utils';
import moment from 'moment';
import { DATE_YMD_FORMAT, LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const useAddConsumerRODrawer = (open, onClose, referenceNum, isFollowup, isRecommendedRepair, isRescheduleConfirmed, isRescheduleCancelled, isSchedule, isPartOrdered, previousDate, previousVanId, headerText, preSelectedMechanics, cancellationValues, additionalRescheduleAction) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const dispatch = useDispatch();
    const submitQuickRO = useSubmitQuickRO({ referenceNum });
    const [isLoading, setIsLoading] = useState(false);
    const [isDuplicateCarModalOpen, setIsDuplicateCarModalOpen] = useState(false);
    const [isDuplicateCarModalShown, setIsDuplicateCarModalShown] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState('');
    const tab = (_a = useSelector(selectTab)) !== null && _a !== void 0 ? _a : 0;
    const nextTab = useSelector(selectNextTab);
    const user = useSelector(selectUser);
    const customUser = useSelector(selectCustomUser);
    const customConsumerAddress = useSelector(selectCustomAddress);
    const vehicles = useSelector(selectVehiclesByConsumerId((_c = (_b = user === null || user === void 0 ? void 0 : user.id) !== null && _b !== void 0 ? _b : customUser === null || customUser === void 0 ? void 0 : customUser.id) !== null && _c !== void 0 ? _c : 0));
    const vehiclePagination = useSelector(selectVehiclesPaginationByConsumerId((_e = (_d = user === null || user === void 0 ? void 0 : user.id) !== null && _d !== void 0 ? _d : customUser === null || customUser === void 0 ? void 0 : customUser.id) !== null && _e !== void 0 ? _e : 0));
    const isFetchingVehicles = useSelector(selectVehiclesFetchingByConsumerId((_g = (_f = user === null || user === void 0 ? void 0 : user.id) !== null && _f !== void 0 ? _f : customUser === null || customUser === void 0 ? void 0 : customUser.id) !== null && _g !== void 0 ? _g : 0));
    const customVehicle = useSelector(selectCustomVehicle);
    const selectedDateTime = useSelector(selectDateTime);
    const futureAppointmentDateCheck = useSelector(selectFutureAppointmentDateCheck);
    const selectedFutureAppointmentDate = useSelector(selectFutureAppointmentDate);
    const timeSlot = useSelector(selectTimeSlot);
    const newVanId = useSelector(selectVanId);
    const isReschedule = isRescheduleCancelled || isRescheduleConfirmed;
    const createConsumer = (consumer) => dispatch(addConsumer(consumer));
    const isUserSelected = () => !!user;
    const closeDrawerHandler = () => {
        dispatch(saveUser(null));
        dispatch(setCustomUser({
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            address: null,
            marketingSourceId: null,
        }));
        dispatch(setCustomConsumerAddress(null));
        dispatch(setRepairs([]));
        dispatch(setTab(TABS.EXISTING_CUSTOMER));
        dispatch(ActionCreators.TAB.RESET_ALL());
        onClose();
    };
    const updateUserAddress = (user, address) => __awaiter(void 0, void 0, void 0, function* () {
        const streetLine1 = address.street;
        const coordinates = {
            latitude: address.latitude,
            longitude: address.longitude,
        };
        const res = yield dispatch(updateConsumerAddress({
            consumerId: user.id,
            address: Object.assign(Object.assign({}, address), { active: true, coordinates,
                streetLine1 }),
        }));
        const newAddress = res.payload;
        if (newAddress) {
            dispatch(saveUser(Object.assign(Object.assign({}, user), { address: newAddress })));
        }
    });
    const onSelectCustomConsumerAddress = () => {
        updateUserAddress(user, customConsumerAddress);
        dispatch(setTab(TABS.EXISTING_CUSTOMER));
    };
    const onSelectCustomer = () => {
        dispatch(setTab(TABS.GARAGE));
    };
    const onAddNewConsumer = () => __awaiter(void 0, void 0, void 0, function* () {
        var _h;
        const res = yield createConsumer(customUser);
        const consumer = (_h = res === null || res === void 0 ? void 0 : res.payload) === null || _h === void 0 ? void 0 : _h.consumer;
        if (!consumer)
            return;
        dispatch(saveUser(consumer));
        dispatch(setTab(TABS.EXISTING_CUSTOMER));
        if (!(customUser === null || customUser === void 0 ? void 0 : customUser.address))
            return;
        yield updateUserAddress(consumer, customUser.address);
    });
    const onAddNewVehicle = () => {
        dispatch(setContinueDisabled(true));
        const isDuplicate = find(vehicles, {
            year: customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.year,
            make: customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.make,
            model: customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.model,
        });
        if (isDuplicate && !isDuplicateCarModalShown) {
            setIsDuplicateCarModalOpen(true);
            setIsDuplicateCarModalShown(true);
            return;
        }
        addNewVehicleHandler();
    };
    const addNewVehicleHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        var _j;
        const res = yield dispatch(addVehicle({
            consumerId: (_j = user === null || user === void 0 ? void 0 : user.id) !== null && _j !== void 0 ? _j : 0,
            vehicle: customVehicle,
        }));
        const vehicle = res.payload;
        dispatch(setVehicle(vehicle));
        dispatch(setTab(TABS.ADD_SERVICES));
        dispatch(setContinueDisabled(false));
    });
    const loadMoreVehicles = useCallback(() => {
        var _a, _b, _c, _d;
        const page = (_a = vehiclePagination === null || vehiclePagination === void 0 ? void 0 : vehiclePagination.pageNumber) !== null && _a !== void 0 ? _a : 0;
        const totalPages = (_b = vehiclePagination === null || vehiclePagination === void 0 ? void 0 : vehiclePagination.totalPages) !== null && _b !== void 0 ? _b : 0;
        if ((vehiclePagination === null || vehiclePagination === void 0 ? void 0 : vehiclePagination.pageNumber) !== undefined &&
            page < totalPages - 1 &&
            !isFetchingVehicles) {
            dispatch(getVehiclesByConsumerId((_d = (_c = user === null || user === void 0 ? void 0 : user.id) !== null && _c !== void 0 ? _c : customUser === null || customUser === void 0 ? void 0 : customUser.id) !== null && _d !== void 0 ? _d : 0, page + 1, 20, true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, vehiclePagination]);
    const createFutureAppointment = () => __awaiter(void 0, void 0, void 0, function* () {
        var _k, _l;
        if (selectedFutureAppointmentDate && referenceNum && user.address) {
            setIsLoading(true);
            const date = moment(selectedFutureAppointmentDate).format(DATE_YMD_FORMAT);
            const toastDate = moment(selectedFutureAppointmentDate).format(LOCALIZED_DATE_FORMAT);
            const res = yield dispatch(scheduleFutureAppointment(referenceNum, {
                id: user.address.id,
                street: user.address.streetLine1,
                street2: user.address.streetLine2,
                city: user.address.city,
                state: user.address.state,
                country: user.address.country,
                zip: user.address.zip,
                timezone: user.address.timezone,
                latitude: (_k = user.address.coordinates) === null || _k === void 0 ? void 0 : _k.latitude,
                longitude: (_l = user.address.coordinates) === null || _l === void 0 ? void 0 : _l.longitude,
                notes: user.address.notes,
                type: user.address.type,
                regionId: user.address.regionId,
            }, date));
            setIsLoading(false);
            if (!(res === null || res === void 0 ? void 0 : res.error)) {
                toast.success(`Successfully scheduled future appointment on ${toastDate}`);
                closeDrawerHandler();
                location.reload();
            }
        }
    });
    const determineResetParts = () => {
        if (futureAppointmentDateCheck) {
            createFutureAppointment();
            return;
        }
        let submitDate = null;
        if (timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot.date) {
            submitDate = timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot.date;
        }
        else if (selectedDateTime) {
            submitDate = moment(selectedDateTime).format(DATE_YMD_FORMAT);
        }
        const isSameVan = newVanId === previousVanId;
        const isSameDate = previousDate === submitDate;
        const resetParts = (!isSameVan && isPartOrdered && isReschedule) ||
            (isPartOrdered && !isSameDate && isReschedule);
        if (resetParts) {
            return dispatch(setTab(TABS.RESET_PARTS));
        }
        return onCreateRequest(false);
    };
    const onCreateRequest = (resetParts) => __awaiter(void 0, void 0, void 0, function* () {
        var _m, _o, _p, _q, _r;
        dispatch(setContinueDisabled(true));
        setIsLoading(true);
        const result = yield submitQuickRO({
            appointmentResolutionId: cancellationValues === null || cancellationValues === void 0 ? void 0 : cancellationValues.resolutionId,
            isFleet: false,
            isReschedule: isRescheduleCancelled || isRescheduleConfirmed,
            isSchedule,
            otherResolution: cancellationValues === null || cancellationValues === void 0 ? void 0 : cancellationValues.description,
            processCancelFee: cancellationValues === null || cancellationValues === void 0 ? void 0 : cancellationValues.processCancelFee,
            resetParts,
        });
        if (cancellationValues === null || cancellationValues === void 0 ? void 0 : cancellationValues.processCancelFee) {
            if (((_o = (_m = result === null || result === void 0 ? void 0 : result.payload) === null || _m === void 0 ? void 0 : _m.cancellationFeeStatus) === null || _o === void 0 ? void 0 : _o.state) === 'SUCCEEDED') {
                toast.success('Cancellation fee successfully processed.');
            }
            else if ((_q = (_p = result === null || result === void 0 ? void 0 : result.payload) === null || _p === void 0 ? void 0 : _p.cancellationFeeStatus) === null || _q === void 0 ? void 0 : _q.errorMessage) {
                toast.error('Cancellation could not be processed.');
            }
            yield delayFor(1000);
        }
        if (result === null || result === void 0 ? void 0 : result.error) {
            setIsLoading(false);
            dispatch(setContinueDisabled(false));
            return;
        }
        if (isRescheduleConfirmed || isRescheduleCancelled || isSchedule) {
            toast.success('We have sent a notification with the appointment details to the customer');
            setTimeout(() => {
                setIsLoading(false);
                closeDrawerHandler();
                if (additionalRescheduleAction) {
                    additionalRescheduleAction();
                }
                else {
                    location.reload();
                }
            }, 8500);
        }
        else {
            closeDrawerHandler();
            window.location.href = `/admin/repairs/${(_r = result === null || result === void 0 ? void 0 : result.payload) === null || _r === void 0 ? void 0 : _r.referenceNum}`;
        }
    });
    const validateMileage = () => {
        var _a;
        const parsedMileage = parseStringNumber((_a = customVehicle === null || customVehicle === void 0 ? void 0 : customVehicle.mileage) !== null && _a !== void 0 ? _a : 0);
        if (!parsedMileage || parsedMileage === 0) {
            setConfirmationModalOpen(false);
        }
        else {
            if (parsedMileage <= 10) {
                setConfirmationModalOpen(true);
                setConfirmationModalText('You’ve entered in a low mileage, are you sure?');
                return;
            }
            if (parsedMileage >= 300000) {
                setConfirmationModalOpen(true);
                setConfirmationModalText('You’ve entered in a high mileage, are you sure?');
                return;
            }
        }
        onAddNewVehicle();
    };
    const getOnClickNext = () => {
        if (nextTab) {
            return () => {
                if (tab === TABS.SEARCH_ADDRESS) {
                    updateUserAddress(user, customConsumerAddress);
                }
                dispatch(setNextTab(undefined));
                dispatch(setTab(nextTab));
            };
        }
        if (tab === TABS.SEARCH_ADDRESS) {
            return () => onSelectCustomConsumerAddress();
        }
        if (tab === TABS.EXISTING_CUSTOMER) {
            return onSelectCustomer;
        }
        if (tab === TABS.ADD_NEW_CUSTOMER) {
            return onAddNewConsumer;
        }
        if (tab === TABS.ADD_NEW_VEHICLE) {
            return validateMileage;
        }
        if (tab === TABS.ADD_SERVICES) {
            return () => onCreateRequest(false);
        }
        if (tab === TABS.SELECT_APPOINTMENT) {
            return determineResetParts;
        }
        return () => { };
    };
    const handleSelectCustomerAddress = (value) => {
        if (value === null && !(customConsumerAddress === null || customConsumerAddress === void 0 ? void 0 : customConsumerAddress.addressStr))
            return;
        dispatch(setCustomConsumerAddress(value));
    };
    const handleSetTab = (value) => {
        dispatch(setTab(value));
    };
    const handleSetUser = (value) => {
        dispatch(saveUser(value));
    };
    return {
        isLoading,
        isDuplicateCarModalOpen,
        setIsDuplicateCarModalOpen,
        confirmationModalOpen,
        setConfirmationModalOpen,
        confirmationModalText,
        tab,
        user,
        customUser,
        customConsumerAddress,
        customVehicle,
        isUserSelected,
        closeDrawerHandler,
        onAddNewVehicle,
        addNewVehicleHandler,
        onCreateRequest,
        getOnClickNext,
        handleSelectCustomerAddress,
        handleSetTab,
        handleSetUser,
        vehiclePagination,
        loadMoreVehicles,
    };
};
export default useAddConsumerRODrawer;
