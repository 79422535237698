import { AccordionDetails, Table } from 'src/components/mui';
import { colors, FONT_FAMILY, theme } from 'src/styles/theme';
import styled from 'styled-components';
export const AccordionContainer = styled.div `
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const HeaderContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    padding: 2px;
  }
`;
export const RuleTitle = styled.h4 `
  font-family: ${FONT_FAMILY};
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.48px;
  color: ${colors.GRAY_3F};
`;
export const RuleName = styled.span `
  font-family: ${FONT_FAMILY};
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.48px;
  color: ${colors.GRAY_3F};
  padding: 12px;
`;
export const RepairComponentsContent = styled.section `
  width: 100%;
  margin-top: 25px;
  padding-left: 24px;
  padding-right: 24px;
  height: 100% - 84px;
  ${theme.breakpoints.down('sm')} {
    padding-bottom: 180px;
  }
`;
export const SectionHeader = styled.h2 `
  font-family: ${FONT_FAMILY};
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.38px;
  color: ${colors.GRAY_3F};
  margin: 20px 0 16px;
`;
export const RepairHeader = styled.div `
  display: flex;
  flex-direction: column;
`;
export const RepairName = styled.div `
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
`;
export const HorizontalBorder = styled.div `
  width: 100%;
  height: 1px;
  opacity: 0.29;
  border: solid 0.5px ${colors.GRAY_97};
  margin-top: 20px;
`;
export const FieldWrapper = styled.div `
  margin-top: 18px;
`;
export const RuleAccordionDetails = styled(AccordionDetails) `
  display: flex;
  flex-flow: column;
  padding: 0px;
`;
export const RuleTable = styled(Table) `
  thead {
    background-color: ${colors.GRAY_FA};
  }
  th {
    padding: 10px 18px 10px 24px !important;
  }
  td {
    padding: 18px 18px 18px 24px !important;
  }
`;
export const TableFooter = styled.div `
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  padding: 18px;

  Button {
    padding-top: 12px;
    padding-bottom: 12px;
    color: ${colors.error};
    border-color: ${colors.error};
  }
`;
export const DeactivationModalContent = styled.h2 `
  font-family: ${FONT_FAMILY};
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: ${colors.GRAY_76};
`;
