var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useSelector } from 'react-redux';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
import { Checkbox } from 'src/components/SimpleCheckbox';
import moment from 'moment';
import { usMoneyFormatWithCents } from 'src/modules/api/utils/format';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
import SimpleChip from 'src/components/SimpleChip';
import { colors } from 'src/styles/theme';
import { openInvoicePdf } from 'src/utils/downloadUtils';
import { selectAccessToken } from 'src/modules/auth/selectors';
import { statusColors } from 'src/AdminApp/pages/invoiceRequests/InvoiceRequestItem';
const statusColor = {
    NEEDS_REVIEW: colors.orangeSelected,
    READY: colors.success,
    CLOSED: colors.blue,
};
const invoiceStatusColor = {
    ACTIVE: colors.success,
    INACTIVE: colors.darkGrey,
    PENDING_TOOLS_INVOICE: colors.orange,
    PENDING_NETSUITE_INVOICE: colors.lightOrange,
    PENDING_TAX_TRANSACTION: colors.ORANGE_FF,
    PENDING_SEND: colors.ORANGE_FF,
    PENDING_FMC_PORTAL_CONFIRMATION: colors.ORANGE_FF,
    PENDING_PDF: colors.ORANGE_FF,
    COMPLETE: colors.success,
    CLOSED: colors.blue,
};
const WorkbenchItem = ({ checked, checkDisabled, item, setLoading, onCheckboxClick, onIRStatusClick, mode, }) => {
    if (!item) {
        return null;
    }
    const { responsibleParty, referenceNum, appointmentDate, vin, roStatus, authNumber, invoiceNum, invoiceTotalPrice, submissionPortal, fleet, consumer, responsiblePartyId, invoiceRequestStatus, invoiceStatus, invoiceRequestType, } = item !== null && item !== void 0 ? item : {};
    const accessToken = useSelector(selectAccessToken);
    const handleDownloadInvoice = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (setLoading) {
            setLoading(true);
        }
        yield openInvoicePdf({
            referenceNum,
            responsiblePartyId,
            responsiblePartyName: responsibleParty,
            accessToken,
            invoiceNumber: invoiceNum,
        });
        if (setLoading) {
            setLoading(false);
        }
    });
    const title = mode === 0 ? fleet : consumer;
    return (<SimpleTableRow data={[
            <Checkbox checked={checked} disabled={checkDisabled} onChange={() => onCheckboxClick(item)}/>,
            <div style={{ whiteSpace: 'nowrap' }}>{title}</div>,
            <a href={`/admin/repairs/${referenceNum}`} target="_blank" rel="noreferrer noopener">
          {referenceNum}
        </a>,
            <div style={{ whiteSpace: 'nowrap' }}>
          {moment(appointmentDate).format(LOCALIZED_DATE_FORMAT)}
        </div>,
            roStatus && (<SimpleChip label={roStatus === null || roStatus === void 0 ? void 0 : roStatus.replaceAll('_', ' ')} $bgColor={statusColor[roStatus]} $textColor={colors.white}/>),
            <div style={{ minWidth: '70px' }}>
          <a href="#" onClick={handleDownloadInvoice}>
            {invoiceNum}
          </a>
        </div>,
            invoiceStatus && (<SimpleChip label={invoiceStatus === null || invoiceStatus === void 0 ? void 0 : invoiceStatus.replaceAll('_', ' ')} $bgColor={invoiceStatusColor[invoiceStatus]} $textColor={colors.white}/>),
            authNumber,
            <div style={{ minWidth: '200px' }}>{responsibleParty}</div>,
            submissionPortal,
            usMoneyFormatWithCents(invoiceTotalPrice),
            vin,
            invoiceRequestStatus && invoiceRequestType !== 'GENERATE_INVOICE' ? (<div style={{ cursor: 'pointer' }} onClick={() => onIRStatusClick(item)}>
            <SimpleChip label={invoiceRequestStatus === null || invoiceRequestStatus === void 0 ? void 0 : invoiceRequestStatus.replaceAll('_', ' ')} $bgColor={statusColors[invoiceRequestStatus === 'COMPLETED' ? 'COMPLETED_WITH_ERRORS' : invoiceRequestStatus]} $textColor={colors.white}/>
          </div>) : null,
        ]}/>);
};
export default WorkbenchItem;
