import { toEnum } from 'src/models/enums';
export const ShopSalesStatus = toEnum({
    NON_NETWORK: 'Non-Network',
    CONTRACTED: 'Contracted',
    TRIAL: 'Trial',
    SUSPENDED_VIOLATION: 'Suspended Violation',
    UNQUALIFIED: 'Unqualified',
    DECLINED_PARTNERSHIP: 'Declined Partnership'
});
export default ShopSalesStatus;
