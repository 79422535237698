import styled from 'styled-components';
import { Divider } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
export const JobInfoContainer = styled.div `
  min-width: 345px;
  max-width: 345px;
  display: flex;
  flex-direction: column;
  padding: 24px;

  ${theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
`;
export const JobInfoSection = styled.div `
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
`;
export const JobInfoSubsection = styled.div `
  display: flex;
  flex-direction: column;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.1px;
  margin-bottom: 17px;

  .label {
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: ${colors.GRAY_76};
  }

  .value {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: ${colors.GRAY_3F};
    margin-top: 4px;
    overflow-x: hidden;
    text-overflow: ellipsis;

    &.primary {
      font-weight: bold;
      color: ${colors.BLUE};
    }
  }
`;
export const JobInfoDivider = styled(Divider) `
  color: red;
`;
export const ApptMain = styled.div `
  display: flex;
  flex-direction: column;
`;
export const ApptMainInfo = styled.div `
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: ${colors.GRAY_3F};
  position: relative;
`;
export const ApptMainActions = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: ${colors.BLUE};

  .divider {
    margin: 0px 5px;
    width: 1px;
    height: 100%;
    background-color: ${colors.GRAY_CC};
  }

  .ref {
    font-size: 12px;
    font-weight: 500;
    color: ${colors.GRAY_76};
  }
`;
