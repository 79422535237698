import React from 'react';
import { Grid, StaticDatePicker, TimePicker } from 'src/components/mui';
import useAddPartnersAPIDateTimePicker from 'src/PartnersAPIApp/pages/AddPartnersAPIDrawer/AddPartnersAPIDateTimePickerFrom/useAddPartnersAPIDateTimePicker';
import { getMomentValueNoUTCOffsetOr8am } from 'src/utils/formatter';
import { PartnersAPICalendar } from './AddPartnersAPIDateTimePickerFrom.styles';
const AddPartnersAPIDateTimePicker = ({ editDate, }) => {
    const { selectedStartDateTime, handleDateTimeChange } = useAddPartnersAPIDateTimePicker({ editDate });
    const noop = () => {
        // Nothing to do here
    };
    return (<Grid container style={{ justifyContent: 'space-between' }}>
      <Grid item xs={12} sm={7}>
        <PartnersAPICalendar>
          <StaticDatePicker className="valid-from-calendar" value={selectedStartDateTime} disablePast disableFuture={!!editDate} onChange={editDate ? noop : handleDateTimeChange} slotProps={{
            toolbar: {
                hidden: true,
            },
            day: {
                hidden: true,
            },
            actionBar: {
                hidden: true,
            },
        }}/>
        </PartnersAPICalendar>
      </Grid>
      <Grid item xs={12} sm={5}>
        <TimePicker label="Enter Start Time" value={getMomentValueNoUTCOffsetOr8am(selectedStartDateTime)} onChange={handleDateTimeChange} disabled={!!editDate} slotProps={{
            textField: {
                variant: 'outlined',
                margin: 'normal',
                id: 'partnersAPI-time-picker',
            },
            field: {
                clearable: true,
            },
        }}/>
      </Grid>
    </Grid>);
};
export default AddPartnersAPIDateTimePicker;
