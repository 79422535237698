import { RadioGroup } from '@mui/material';
import React from 'react';
import { FormControlLabel, Radio } from 'src/components/mui';
import { SubText } from 'src/components/SimpleDrawer';
const RepairComponentsList = ({ repair, instructions, selectedPart, setSelectedPart, includeFluids = false, filter, }) => {
    var _a, _b, _c, _d, _e;
    let components = (_b = (_a = repair.price[0]) === null || _a === void 0 ? void 0 : _a.parts.map((part) => {
        return Object.assign(Object.assign({}, part), { isPart: true });
    })) !== null && _b !== void 0 ? _b : [];
    if (includeFluids) {
        components = components.concat((_d = (_c = repair.price[0]) === null || _c === void 0 ? void 0 : _c.fluids.map((fluid) => {
            return Object.assign(Object.assign({}, fluid), { isPart: false });
        })) !== null && _d !== void 0 ? _d : []);
    }
    if (filter) {
        components = components.filter(filter);
    }
    const handleSelectedPart = (e) => {
        // find part by id
        const part = repair.price[0].parts.find((part) => part.id === parseInt(e.target.value, 10));
        if (part) {
            setSelectedPart(Object.assign(Object.assign({}, part), { isPart: true }));
        }
        else {
            // find fluid by id
            const fluid = repair.price[0].fluids.find((fluid) => fluid.id === parseInt(e.target.value, 10));
            if (fluid) {
                setSelectedPart(Object.assign(Object.assign({}, fluid), { isPart: false }));
            }
        }
    };
    return (<>
      <h2>Select Component</h2>
      <SubText>{instructions}</SubText>

      <RadioGroup aria-label="repair" name="repair" value={(_e = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.id) !== null && _e !== void 0 ? _e : ''} onChange={handleSelectedPart}>
        {components.map((part) => (<FormControlLabel key={part.id} value={part.id} control={<Radio color="secondary"/>} label={`${part.name} • Parts #: ${part.partNumber}`}/>))}
      </RadioGroup>
    </>);
};
export default RepairComponentsList;
