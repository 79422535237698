import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const SimpleDrawerContent = styled.div `
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 8px;
  padding: 10px ${({ $noSidePadding }) => ($noSidePadding ? '0px' : '20px')};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 21px */
  letter-spacing: 0.245px;
  position: relative;
  display: ${({ $displayFlex }) => ($displayFlex ? 'flex' : 'block')};
  flex-direction: column;
`;
export const Instructions = styled.div `
  margin-bottom: 16px;
  color: ${colors.GRAY_76};
`;
export const SubText = styled.div `
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${colors.GRAY_3F};
`;
export const SubHeader = styled.h3 `
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.5px;
  color: ${colors.GRAY_3F};
`;
