import { toEnum } from 'src/models/enums';
import { toStatusWithResolutionEnum } from './StatusWithResolutionEnum';
export const RepairRequestStatus = toStatusWithResolutionEnum({
    NEW: 'New',
    OPEN: 'Open',
    CANCELED: 'Canceled',
    CLOSED: 'Closed',
}, ['CANCELED']);
export const RepairRequestResolution = toEnum({
    CANNOT_FULFILL_WITH_SHOP: 'Cannot Fulfill With Shop',
    CUSTOMER_CANCELED: 'Customer Canceled',
    DUPLICATE: 'Duplicate',
    EXPIRED: 'Expired',
    INVALID_REQUEST: 'Invalid Request',
    TEST: 'Test',
    UNKNOWN: 'Unknown',
    OTHER: 'Other',
});
export default RepairRequestStatus;
