import styled from 'styled-components';
import { theme, colors } from 'src/styles/theme';
export const Wrapper = styled.div `
  width: 100%;
  text-align: ${(props) => props.align || 'center'};
  font-size: 6px;
  padding-bottom: 80px;

  ${theme.breakpoints.up('md')} {
    font-size: 1rem;
  }
`;
export const CTAWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
`;
export const TopContainer = styled.div `
  display: flex;
  margin-bottom: 40px;
`;
export const UserTopInfoWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const UserInfoWrapper = styled.div `
  display: flex;
  width: 100%;
`;
export const UserProfilePictureWrapper = styled.div `
  width: 50%;

  & img {
    width: 300px;
    height: 300px;
    clip-path: circle();
  }
`;
export const UserSecWrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  & > div {
    margin-bottom: 10px;
    border: 1px solid ${colors.GRAY_CC};
    border-radius: 4px;
    box-shadow: none;
  }
`;
