var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { cancelRequest } from 'src/AdminApp/modules/requests/actions';
import { RepairRequestResolution } from 'src/AdminApp/models/enums';
import { reduce } from 'lodash';
import { toast } from 'src/components/SimpleToast';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
const paramName = 'repairRequestResolution';
const successMessage = 'Request successfully canceled.';
const useCancelRequestDrawer = () => {
    const { referenceNum } = usePageReferenceNum();
    const [showCancelRequestForm, setShowCancelRequestForm] = useState(true);
    const dispatch = useDispatch();
    const backButtonHandler = () => {
        setShowCancelRequestForm(true);
    };
    const nextButtonHandler = () => {
        setShowCancelRequestForm(false);
    };
    const handleSubmit = (values, setSubmitting, onClose) => __awaiter(void 0, void 0, void 0, function* () {
        const { cancelResolution, otherResolution } = values, invalidRequestRes = __rest(values, ["cancelResolution", "otherResolution"]);
        const isOtherReasonChecked = invalidRequestRes.other;
        const invalidRequestReasons = reduce(invalidRequestRes, (result, value, key) => {
            if (value && !['other', 'otherReason'].includes(key))
                return [...result, key];
            if (isOtherReasonChecked && key === 'otherReason')
                return result.concat(value);
            return result;
        }, []);
        const submit = Object.assign(Object.assign({ [paramName]: cancelResolution, referenceNum }, (values.cancelResolution === RepairRequestResolution.OTHER && {
            otherResolution,
        })), (values.cancelResolution === RepairRequestResolution.INVALID_REQUEST &&
            invalidRequestReasons.length >= 1 && {
            invalidRequestReasons,
        }));
        const response = yield dispatch(cancelRequest(Object.assign({}, submit)));
        if (response && !response.error) {
            toast.success(successMessage);
            setSubmitting(false);
            onClose();
        }
        return response;
    });
    return {
        showCancelRequestForm,
        handleSubmit,
        backButtonHandler,
        nextButtonHandler,
    };
};
export default useCancelRequestDrawer;
