import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { isEmpty, isNull, isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { Button, Collapse, Divider, Grid, Typography, } from 'src/components/mui';
import { Arrow } from 'src/components/layout/CollapsibleBlock';
import CurrencyLabel from 'src/components/CurrencyLabel';
import { colors } from 'src/styles/theme';
import { formatCurrency } from 'src/utils/formatter';
import { getCapacityUnitAbbreviation } from 'src/utils/getCapacityUnitAbbreviation';
import DiscountStatementItem from 'src/AdminApp/components/discounts/DiscountStatementItem';
import PricingSource from 'src/AdminApp/components/repairs/statement/Elements/PricingSource';
import PayerSummary from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/PayerSummary';
import { CalculatedTotalCost, CheckmarkIcon, Dots, PartSecondaryInfo, Title, } from 'src/AdminApp/components/repairs/statement/styledComponents';
import { PriceBreakdownLaborField, PriceBreakdownPriceField, PriceBreakdownTaxInputField, } from 'src/AdminApp/containers/requests/fieldComponents';
import { StyledGrid } from 'src/AdminApp/containers/requests/PriceDetailsItem';
import { FieldDivider, PriceField, Total, } from 'src/AdminApp/containers/requests/styledComponents';
import SimpleChip from 'src/components/SimpleChip';
export const Part = ({ itemPrice, unitCost, unitPrice, partNumber, partVendor, notes, quantity, name, address, sources, biData, }) => {
    const shouldShowCheckmark = !isNull(itemPrice) &&
        !isNull(unitCost) &&
        !isEmpty(partNumber) &&
        !isEmpty(partVendor) &&
        !isEmpty(address);
    const pricingSources = {
        sources,
        price: unitPrice,
        amPrice1: biData.amPrice1,
        amPrice2: biData.amPrice1,
    };
    return (<div>
      {shouldShowCheckmark && <CheckmarkIcon />}
      <Grid container>
        <Title>
          {name} {partNumber}
        </Title>
        <Dots />
        <CalculatedTotalCost values={itemPrice}/>
      </Grid>
      <PartSecondaryInfo>
        {quantity}x @ <CurrencyLabel>{unitPrice}</CurrencyLabel> each
      </PartSecondaryInfo>

      <PartSecondaryInfo style={{ fontWeight: 'bold' }}>
        <PricingSource part={pricingSources}/>
      </PartSecondaryInfo>

      {(partVendor || address) && (<div>
          {partVendor && `${partVendor.name}; `}
          {partVendor.address && `${partVendor.address}; `} {notes}
        </div>)}
    </div>);
};
export const Fluid = ({ name, unitPrice, itemPrice, partVendor, partNumber, address, quantity, unit, type, notes, }) => {
    const shouldShowCheckmark = !isNull(unitPrice) &&
        !isNull(itemPrice) &&
        !isEmpty(partNumber) &&
        !isEmpty(partVendor) &&
        !isEmpty(address);
    return (<div>
      {shouldShowCheckmark && <CheckmarkIcon />}
      <Grid container>
        <Title>
          {name} {partNumber}
        </Title>
        <Dots />
        {quantity === null ? (<span>Pending</span>) : (<CurrencyLabel>{unitPrice * quantity}</CurrencyLabel>)}
      </Grid>

      <PartSecondaryInfo>
        {type} • {quantity}
        {getCapacityUnitAbbreviation(unit)} @{' '}
        <CurrencyLabel>{unitPrice}</CurrencyLabel>
        {unit && `/${getCapacityUnitAbbreviation(unit)}`}
        <br />
      </PartSecondaryInfo>
      {(partVendor || address) && (<PartSecondaryInfo>
          {partVendor && `${partVendor.name}; `}
          {address && `${address}; `} {notes}
        </PartSecondaryInfo>)}
    </div>);
};
export const Labor = ({ biData, unitPrice, }) => {
    var _a, _b, _c, _d, _e, _f;
    const laborCost = (_b = (_a = biData === null || biData === void 0 ? void 0 : biData.totalPrice) === null || _a === void 0 ? void 0 : _a.actual) !== null && _b !== void 0 ? _b : null;
    const minLaborTime = (_d = (_c = biData === null || biData === void 0 ? void 0 : biData.quantity) === null || _c === void 0 ? void 0 : _c.min) !== null && _d !== void 0 ? _d : null;
    const laborTime = (_f = (_e = biData === null || biData === void 0 ? void 0 : biData.quantity) === null || _e === void 0 ? void 0 : _e.actual) !== null && _f !== void 0 ? _f : null;
    const laborRate = unitPrice;
    return (<Grid container>
      <Grid container>
        <Title>Labor Cost</Title>
        {!isNull(laborCost) && <Dots />}
        {!isNull(laborCost) && <CalculatedTotalCost values={laborCost}/>}
      </Grid>
      {!isNull(laborRate) && (<PartSecondaryInfo>
          {`${!isNil(laborTime) ? laborTime : minLaborTime} h`} @{' '}
          <CurrencyLabel>{laborRate}</CurrencyLabel>/h
        </PartSecondaryInfo>)}
    </Grid>);
};
export const InvoiceForm = ({ totalLaborDuration, laborTotalPrice, partsTotalPrice, shopSuppliesFee, hazardousMaterialsFee, deliveryFee, cancelFee, totalFees, totalSublet, subtotal, discounts, adjustments, partsTaxAmount, partsTaxRate, laborTaxRate, laborTaxAmount, totalTaxes, totalPrice, sublets, repairItems, showBreakDown = false, }) => {
    return (<Grid container>
      <Grid item xs={12}>
        <Total>
          <PriceBreakdownLaborField leftPart={totalLaborDuration ? `${totalLaborDuration}h` : null} label="Labors">
            {laborTotalPrice}
          </PriceBreakdownLaborField>
          <PriceBreakdownPriceField label="Parts">
            {partsTotalPrice}
          </PriceBreakdownPriceField>
          <PriceBreakdownPriceField label="Shop Supplies Fee">
            {shopSuppliesFee}
          </PriceBreakdownPriceField>
          <PriceBreakdownPriceField label="Environmental Fee">
            {hazardousMaterialsFee}
          </PriceBreakdownPriceField>
          <PriceBreakdownPriceField label="Mobile Service Charge">
            {deliveryFee}
          </PriceBreakdownPriceField>
          <PriceBreakdownPriceField label="Cancel Fee">
            {cancelFee}
          </PriceBreakdownPriceField>
          <PriceBreakdownPriceField label="Total Fees">
            {totalFees}
          </PriceBreakdownPriceField>
          <FieldDivider />
          <PriceBreakdownPriceField label="Subtotal">
            {subtotal}
          </PriceBreakdownPriceField>
          <PriceBreakdownPriceField label="Discounts">
            {-discounts}
          </PriceBreakdownPriceField>
          <PriceBreakdownPriceField label="Adjustments">
            {adjustments}
          </PriceBreakdownPriceField>
          <PriceBreakdownTaxInputField label="Parts Taxes" name="partsTax" values={{ partsTaxAmount, partsTaxRate: partsTaxRate * 100 }} initial={{ partsTaxAmount, partsTaxRate: partsTaxRate * 100 }}/>
          <PriceBreakdownTaxInputField label="Labor Taxes" name="laborTax" values={{ laborTaxRate: laborTaxRate * 100, laborTaxAmount }} initial={{ laborTaxRate: laborTaxRate * 100, laborTaxAmount }}/>
          <FieldDivider />

          <span>
            <PriceBreakdownPriceField label="Total Taxes">
              {totalTaxes}
            </PriceBreakdownPriceField>
            <TotalSublet totalSublet={totalSublet} sublets={sublets} repairItems={repairItems}/>
          </span>

          <FieldDivider />

          {!showBreakDown ? (<PriceBreakdownPriceField large label="Total Invoice Price">
              {totalPrice}
            </PriceBreakdownPriceField>) : (<PayerSummary totalPrice={totalPrice}/>)}
        </Total>
      </Grid>
    </Grid>);
};
const SubletsWrapper = styled.div `
  margin-top: 27px;
`;
const DividerWrapper = styled.div `
  margin: 12px 0px;
`;
const priceStyle = { textAlign: 'right' };
const fontNormal = { fontWeight: 'normal' };
export const TotalSublet = ({ repairItems = [], sublets = [], totalSublet, }) => {
    const [opened, setOpened] = useState(false);
    if (sublets.length === 0) {
        return null;
    }
    const getSubletDescription = (sublet) => {
        const { suggestedRepairIds } = sublet;
        if (suggestedRepairIds.length > 0) {
            return (<>
          <Grid item xs={3}>
            {suggestedRepairIds.map((id) => {
                    const repair = repairItems.find((r) => { var _a; return ((_a = r.metadata) === null || _a === void 0 ? void 0 : _a.oldId) === id; });
                    return (<Typography key={repair.id} variant="subtitle1">
                  {repair.name}
                </Typography>);
                })}
          </Grid>
          <Grid item xs={3}>
            {sublet.repairItems.map((ri) => (<Typography variant="subtitle1">
                <PriceField>{ri.subletCost}</PriceField>
              </Typography>))}
          </Grid>
        </>);
        }
        return <Typography variant="subtitle1">No Description</Typography>;
    };
    return (<>
      <StyledGrid container>
        <Grid item md={6}>
          <SimpleChip $bgColor={colors.orange} $textColor={colors.black} label="Total Sublet"/>
          {'  '}
          <Button size="small" color="primary" onClick={() => setOpened(!opened)}>
            View Sublet Info &nbsp; &nbsp; &nbsp; &nbsp;
            <Arrow $opened={opened} $color={colors.primary}/>
          </Button>
        </Grid>
        <Grid item md={6}>
          <PriceField>{totalSublet}</PriceField>
        </Grid>
      </StyledGrid>
      <Collapse in={opened}>
        <SubletsWrapper>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Shop Info</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1">Service</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" style={priceStyle}>
                Cost
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DividerWrapper>
                <Divider />
              </DividerWrapper>
            </Grid>
            {sublets.map((sublet) => {
            var _a, _b;
            return (<Fragment key={sublet.id}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{sublet.shopName}</Typography>
                  <Typography variant="subtitle1" style={fontNormal}>
                    {`${sublet.shopAddress}, ${sublet.shopCity}, ${sublet.shopState} ${sublet.shopZip}`}
                  </Typography>
                  <Typography variant="subtitle1" style={fontNormal}>
                    {`Contact: ${(_a = sublet === null || sublet === void 0 ? void 0 : sublet.shopContactName) !== null && _a !== void 0 ? _a : 'N/A'} • ${(_b = sublet === null || sublet === void 0 ? void 0 : sublet.shopPhone) !== null && _b !== void 0 ? _b : 'N/A'}`}
                  </Typography>
                </Grid>
                {getSubletDescription(sublet)}
              </Fragment>);
        })}
          </Grid>
        </SubletsWrapper>
      </Collapse>
    </>);
};
const renderDiscount = (d) => {
    var _a, _b, _c, _d, _e, _f;
    let discountedAmount = formatCurrency(d.discountedAmount);
    let label;
    if (d.discountType === 'CUSTOM_DISCOUNT') {
        label = `${(_b = (_a = d.discountReasonType) === null || _a === void 0 ? void 0 : _a.internalReason) !== null && _b !== void 0 ? _b : ''}${d.reasonDescription ? `: ${d.reasonDescription}` : ''}`;
    }
    else if (d.discountType === 'RO_CREDIT' ||
        d.discountType === 'CANCEL_FEE_CREDIT') {
        const originalROLink = `/admin/repairs/${d.originalReferenceNum}`;
        label = (<span>
        Credit: <Link to={originalROLink}>Cancel Fee Credit</Link>
      </span>);
    }
    else if (d.discountType === 'SUBSCRIPTION') {
        const { subscriptionPercentOff } = d;
        discountedAmount = `${subscriptionPercentOff}%`;
        label = `${d.name} - ${discountedAmount} Discount`;
    }
    else {
        label = `Promo Code: ${(_c = d.promoCode) === null || _c === void 0 ? void 0 : _c.code}`;
        discountedAmount =
            ((_d = d.promoCode) === null || _d === void 0 ? void 0 : _d.discountType) === 'AMOUNT'
                ? formatCurrency((_e = d.promoCode) === null || _e === void 0 ? void 0 : _e.amountOff)
                : `${(_f = d.promoCode) === null || _f === void 0 ? void 0 : _f.percentOff}%`;
    }
    return (<DiscountStatementItem label={label} amount={discountedAmount} key={d.id} id={d.id} canEdit={false}/>);
};
export const Discounts = ({ discounts = [] }) => {
    const promos = discounts.filter((d) => d.discountType === 'PROMO_CODE');
    const subscriptions = discounts.filter((d) => d.discountType === 'SUBSCRIPTION');
    const roCredits = discounts.filter((d) => d.discountType === 'RO_CREDIT');
    const customDiscounts = discounts.filter((d) => d.discountType === 'CUSTOM_DISCOUNT');
    const cancelFeeCredits = discounts.filter((d) => d.discountType === 'CANCEL_FEE_CREDIT');
    return (<>
      {promos.map((promo = {}) => renderDiscount(promo))}
      {subscriptions.map((subscription = {}) => renderDiscount(subscription))}
      {roCredits.map((credit = {}) => renderDiscount(credit))}
      {customDiscounts.map((discount = {}) => renderDiscount(discount))}
      {cancelFeeCredits.map((credit = {}) => renderDiscount(credit))}
    </>);
};
