import { useDispatch, useSelector } from 'react-redux';
import usePaymentDrawer from 'src/AdminApp/containers/payments/PaymentDrawer/usePaymentDrawer';
import { setPaymentDrawerAmount, setPaymentDrawerBalanceOwed, setPaymentDrawerInvoice, setPaymentDrawerPaymentsReceivedTotal, setPaymentDrawerScreen, setPaymentDrawerWorkOrderResponsibleParty, } from 'src/AdminApp/modules/paymentDrawer/actions';
import { selectPaymentDrawerWorkOrderId, selectPaymentDrawerWorkOrderResponsibleParties, } from 'src/AdminApp/modules/paymentDrawer/selectors';
const useSelectPayer = () => {
    const dispatch = useDispatch();
    const workOrderResponsibleParties = useSelector(selectPaymentDrawerWorkOrderResponsibleParties);
    const workOrderId = useSelector(selectPaymentDrawerWorkOrderId);
    const { getInvoiceByResponsibleParty } = usePaymentDrawer({ workOrderId });
    const handleSelectPayer = (newWorkOrderResponsibleParty) => () => {
        var _a, _b, _c;
        if (!newWorkOrderResponsibleParty.responsibleParty)
            return;
        const newInvoice = getInvoiceByResponsibleParty(newWorkOrderResponsibleParty.responsibleParty);
        dispatch(setPaymentDrawerInvoice(newInvoice));
        dispatch(setPaymentDrawerAmount((_a = newInvoice === null || newInvoice === void 0 ? void 0 : newInvoice.balanceOwed) !== null && _a !== void 0 ? _a : 0));
        dispatch(setPaymentDrawerBalanceOwed((_b = newInvoice === null || newInvoice === void 0 ? void 0 : newInvoice.balanceOwed) !== null && _b !== void 0 ? _b : 0));
        dispatch(setPaymentDrawerPaymentsReceivedTotal((_c = newInvoice === null || newInvoice === void 0 ? void 0 : newInvoice.paymentsReceivedTotal) !== null && _c !== void 0 ? _c : 0));
        dispatch(setPaymentDrawerScreen('home'));
        dispatch(setPaymentDrawerWorkOrderResponsibleParty(newWorkOrderResponsibleParty));
    };
    return {
        workOrderResponsibleParties,
        handleSelectPayer,
        getInvoiceByResponsibleParty,
    };
};
export default useSelectPayer;
