import { createSelector } from 'reselect';
import { selectRequestDataByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { selectUserId } from 'src/modules/auth/selectors';
export const selectTrackAdminConfirm = (referenceNum) => createSelector(selectRequestDataByReferenceNum(referenceNum), selectUserId, (request, admin_id) => ({
    shop_name: request.request.activeAppointment.ispContactInfo.shopName,
    shop_id: request.request.activeAppointment.ispContactInfo.ispId,
    delivery_channel: request.request.activeAppointment.deliveryChannel,
    appointment_date_time: request.request.activeAppointment.scheduleStartLocal,
    consumer_id: request.contactInfo.consumerId,
    repair_request_id: referenceNum,
    admin_id,
}));
export const selectTrackRepairCompleted = (referenceNum) => createSelector(selectRequestDataByReferenceNum(referenceNum), selectUserId, (request, admin_id) => {
    const delivery_channel = request.request.activeAppointment.deliveryChannel;
    const service_zip_code = delivery_channel === 'DELIVERY'
        ? request.request.address.zip
        : request.request.activeAppointment.ispContactInfo.zip;
    return {
        admin_id,
        delivery_channel,
        service_zip_code,
        consumer_id: request.contactInfo.consumerId,
        repair_request_id: referenceNum,
    };
});
