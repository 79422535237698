import styled from 'styled-components';
import { TableRow, TableCell } from 'src/components/mui';
export const NotesTableRowItem = styled(TableRow) `
  font-size: 12px;
`;
export const NotesTableCellItem = styled(TableCell) `
  font-size: 13px;

  &.content {
    min-width: 40ch;
  }
`;
