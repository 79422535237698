import React from 'react';
import { STATIC_URL_BASE } from 'src/styles/theme';
import { Container, Search, Filters, FilterIcon } from './FilterInput.styles';
const FilterInput = ({ searchStr, onSearch, placeholder, onFilter, showFilter = false, }) => {
    return (<Container>
      <Search onChange={onSearch} value={searchStr} placeholder={placeholder}/>
      {showFilter && (<Filters onClick={onFilter}>
          <FilterIcon src={`${STATIC_URL_BASE}pops/icons/filter.svg`} alt="filter icon"/>
          Filter
        </Filters>)}
    </Container>);
};
export default FilterInput;
