var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { downloadInvoice } from 'src/AdminApp/modules/invoices/actions';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
const useInvoiceDownload = () => {
    const { referenceNum } = usePageReferenceNum();
    const dispatch = useDispatch();
    const [isInvoiceDownloading, setIsInvoiceDownloading] = useState(false);
    const onDownloadInvoiceClick = (invoice) => __awaiter(void 0, void 0, void 0, function* () {
        setIsInvoiceDownloading(true);
        yield dispatch(downloadInvoice(referenceNum, invoice.workOrderResponsibleParty));
        setIsInvoiceDownloading(false);
    });
    return {
        isInvoiceDownloading,
        onDownloadInvoiceClick,
    };
};
export default useInvoiceDownload;
