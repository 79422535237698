import React from 'react';
import { map } from 'lodash';
import { Grid } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { AuthorizationGroups } from 'src/AdminApp/models/enums';
import { CheckboxGroup, FormPanel, TextField, } from 'src/AdminApp/components/form';
const formattedAuthGroups = map(AuthorizationGroups.values, (value, key) => ({
    id: key,
    value,
}));
const RegistrationForm = ({ isAdmin = false, authGroupsState = [], onAuthorizationGroupCheck, isTechnician, }) => {
    const tooltipText = "Please create this user's Skedulo Account and add their Resource ID here";
    const tooltip = (<Tooltip title={tooltipText} style={{ fontSize: '20px' }} placement="top">
      <div style={{
            paddingRight: '15px',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 500,
        }}>
        ?
      </div>
    </Tooltip>);
    return (<FormPanel ariaName="shop-registration-form" title="Registration">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField label="Email" type="text" name="email"/>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Password (8 characters minimum)" type="password" name="password"/>
        </Grid>
        {isAdmin && (<>
            <Grid item xs={12}>
              <TextField label="First Name" type="text" name="firstName"/>
            </Grid>
            <Grid item xs={12}>
              <TextField label="Last Name" type="text" name="lastName"/>
            </Grid>
            <Grid item xs={12}>
              <CheckboxGroup checkboxItems={formattedAuthGroups} checkboxState={authGroupsState} onCheckboxChange={onAuthorizationGroupCheck !== null && onAuthorizationGroupCheck !== void 0 ? onAuthorizationGroupCheck : (() => { })} title="Groups"/>
            </Grid>
            {!!isTechnician && isTechnician() && (<Grid item xs={12} style={{ marginBottom: '30px' }}>
                <TextField label="Skedulo Resource ID" type="text" name="skeduloResourceId" InputProps={{ endAdornment: tooltip }}/>
                <TextField label="Technician Phone Number" type="number" InputProps={{
                    inputProps: { min: 0 },
                }} name="phoneNumber" placeholder="9999999999"/>
              </Grid>)}
          </>)}
      </Grid>
    </FormPanel>);
};
export default RegistrationForm;
