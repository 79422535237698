import styled, { css } from 'styled-components';
import { flexCenter } from 'src/styles/styled';
import { colors, theme } from 'src/styles/theme';
import { Button, Typography, ToggleButton } from 'src/components/mui';
import { Timer } from 'src/components/mui/icons';
export const mobilePadding = css `
  padding-left: 24px;
  padding-right: 12px;
`;
export const ContentWrapper = styled('div') `
  ${flexCenter};

  flex-direction: column;
  height: auto;
  padding-bottom: 88px;

  ${theme.breakpoints.up('md')} {
    align-items: stretch;
    flex-direction: row;
    height: inherit;
    justify-content: space-between;
    padding-bottom: 0;
    padding-top: 0;
  }
`;
export const ContentContainer = styled.div `
  order: 1;
  width: 100%;

  &:first-of-type {
    ${mobilePadding};

    order: 2;
    ${theme.breakpoints.up('md')} {
      padding-right: 0;
    }
  }

  ${theme.breakpoints.up('md')} {
    height: inherit;
    order: unset;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 48px;
    width: 48%;

    &:first-of-type {
      order: unset;
    }
  }
`;
export const OpenScheduleWrapper = styled('div') `
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-top: 24px;
`;
export const OpenScheduleSeparator = styled('div') `
  flex: 1;
`;
export const StyledToggleButton = styled(ToggleButton) `
  color: ${colors.primary};
  border: 1px solid;
  padding: 3px 10px 3px 10px;
  width: 48%;
  &.Mui-selected {
    color: ${colors.white};
    border-color: ${colors.primary};
    background-color: ${colors.primary};
    &:hover {
      background-color: ${colors.primary};
    }
  }
`;
export const Content = styled('div') `
  padding: 0;

  ${theme.breakpoints.up('md')} {
    padding-bottom: 40px;
    padding-top: 40px;
  }
`;
export const SlotsTitle = styled(Typography) `
  display: block;
`;
export const MechanicsTitle = styled(Typography) `
  color: ${colors.default};
  font-size: initial;
`;
export const DetailLabel = styled.td `
  color: ${colors.darkGrey};
  font-weight: bold;
  padding: 3px 24px 3px 0;
`;
export const DetailValue = styled.td `
  font-weight: bold;
  .specialOrder {
    padding-left: 12px;
    color: ${colors.redF5};
  }
`;
export const SpecialOrderIcon = styled(Timer) `
  font-size: 14px;
  margin-right: 4px;
`;
export const MechanicsHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  @media (max-width: 767px) {
    padding-right: 32px;
  }
`;
export const Warning = styled.div `
  background-color: ${colors.GRAY_F5};
  border-left: thick solid ${colors.warning};
  border-radius: 4px;
  margin-top: 24px;
  object-fit: contain;
  padding: 16px;
  width: 100%;

  h6 {
    color: ${colors.darkGrey};
    font-size: 0.875rem;
  }
`;
export const Btn = styled(Button) `
  width: fit-content;
  font-size: 1rem;
  padding: 12px 22px;
  line-height: 1;
`;
