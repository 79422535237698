var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import WrongPartDrawer from 'src/AdminApp/components/repairs/statement/Repairs/Drawers/WrongPartDrawer';
import AddRepairOrderDrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer';
import Slider from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/Slider';
import CustomerProvidedPartsDrawer from 'src/AdminApp/containers/requests/Quote/CustomerProvidedParts';
import AdditionalComponentsRequired from 'src/AdminApp/containers/requests/Quote/PartsHelp/AdditionalComponentsRequired';
import AdditionalRepairsRequired from 'src/AdminApp/containers/requests/Quote/PartsHelp/AdditionalRepairsRequired';
import FmcRepairSearch from 'src/AdminApp/containers/requests/Quote/PartsHelp/FmcRepairSearch';
import InputCustomRepair from 'src/AdminApp/containers/requests/Quote/PartsHelp/InputCustomRepair';
import PartsHelpList from 'src/AdminApp/containers/requests/Quote/PartsHelp/PartsHelpList';
import { PartsHelpTabs } from 'src/AdminApp/containers/requests/Quote/PartsHelp/PartsHelpTabs';
import RepairComponentsList from 'src/AdminApp/containers/requests/Quote/PartsHelp/RepairComponentsList';
import SelectComponentType from 'src/AdminApp/containers/requests/Quote/PartsHelp/SelectComponentType';
import UrgentPendingParts from 'src/AdminApp/containers/requests/Quote/PartsHelp/UrgentPendingParts';
import { CUSTOM_REPAIR_ID } from 'src/AdminApp/modules/api/constants';
import { FooterActionButton, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import VisitCapacityWarningModal from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal';
import usePartsHelpDrawer from 'src/AdminApp/containers/requests/Quote/PartsHelp/usePartsHelpDrawer';
import FleetResponsiblePartySearchSelect from 'src/AdminApp/containers/fleets/FleetInfo/components/FleetResponsiblePartiesDrawer/components/FleetResponsiblePartySearchSelect/FleetResponsiblePartySearchSelect';
import AddVehicleResponsiblePartyConfirmationModal from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddFleetRODrawer/components/AddVehicleResponsiblePartyConfirmationModal';
const PartsHelpDrawer = ({ open, onClose, isEditable, isFmcRo, fetchQuoteFmcWorkAuthorizationStatus, invoice, workOrderId, }) => {
    var _a, _b, _c;
    const { handleSearchRepair, showCapacityWarning, handleCapacityWarningClose, capacityWarningMessage, goToTab, setCurrentAction, disabledTabs, goToSelectComponentType, repair, handleAddPart, handleOpenAddFluid, handleOpenAddLabor, setRepairId, handleRepairSubmit, repairId, isSubmitting, setRepair, setCustomRepairText, submitCustomRepair, onFmcRepairSelect, customRepairText, handleCustomFmcRepairSubmit, fmcRepairId, setIsCustomerProvidedPartsValid, selectedCustomerParts, isCheckedAllCustomerParts, setSelectedCustomerParts, setIsCheckedAllCustomerParts, isPartSelected, additionalRepairComponents, setAdditionalRepairComponents, isCustomerProvidedPartsValid, handleSubmitBatch, goToSelectRepairComponent, selectedPart, setSelectedPart, handleFaultyPart, handleClose, setWrongPartOpen, tab, goBack, wrongPartOpen, referenceNum, submitUrgentPartsNote, handleRepairSelect, fleet, vehicle, handleResponsiblePartySelect, openConfirmation, handleSubmitConfirmNewRP, handleSubmitCancelNewRP, } = usePartsHelpDrawer({
        isFmcRo,
        fetchQuoteFmcWorkAuthorizationStatus,
        onClose,
        isEditable,
        invoice,
        workOrderId,
        open,
    });
    const routes = [
        {
            name: PartsHelpTabs.HOME,
            label: 'Parts Help',
            render: () => (<PartsHelpList goToTab={(newTab) => {
                    goToTab(newTab);
                    setCurrentAction(newTab);
                }} disabledTabs={disabledTabs}/>),
        },
        {
            name: PartsHelpTabs.ADDITIONAL_COMPONENTS_REQUIRED_SELECT_REPAIR,
            label: "Addt'l Components Required",
            render: () => (<AdditionalComponentsRequired goNext={goToSelectComponentType} instructions="Which repair requires additional components?" invoice={invoice}/>),
        },
        {
            name: PartsHelpTabs.ADDITIONAL_COMPONENTS_REQUIRED_SELECT_COMPONENT_TYPE,
            label: "Addt'l Components Required",
            render: () => (<SelectComponentType repair={repair} onTypeSelect={(type) => {
                    switch (type) {
                        case 'Parts':
                            handleAddPart();
                            break;
                        case 'Fluids':
                            handleOpenAddFluid();
                            break;
                        case 'Labor':
                            handleOpenAddLabor();
                            break;
                        default:
                            handleAddPart();
                            break;
                    }
                }}/>),
        },
        {
            name: PartsHelpTabs.ADDITIONAL_REPAIRS_REQUIRED,
            label: "Addt'l Repairs Required",
            render: () => (<>
          {fleet && (<>
              <h2>Responsible Party </h2>
              <FleetResponsiblePartySearchSelect disabled={!fleet} fleet={fleet} vehicle={vehicle} onSelect={handleResponsiblePartySelect} showRpTypeSuffix grouped/>
            </>)}
          <AdditionalRepairsRequired title="Select Repair" onRepairSelect={handleRepairSelect} onCustomRepairSelected={() => {
                    setRepairId(CUSTOM_REPAIR_ID);
                    goToTab(PartsHelpTabs.INPUT_CUSTOM_REPAIR);
                }} onSearchRepairs={handleSearchRepair}/>
        </>),
            footer: (<SimpleDrawerFooter>
          <FooterActionButton onClick={handleRepairSubmit} disabled={!repairId} isSubmitting={isSubmitting}>
            Next
          </FooterActionButton>
        </SimpleDrawerFooter>),
        },
        {
            name: PartsHelpTabs.INPUT_CUSTOM_REPAIR,
            label: 'Add a custom repair',
            render: () => (<InputCustomRepair setCustomRepairText={(custom) => {
                    setRepair({
                        id: CUSTOM_REPAIR_ID,
                        repairId: CUSTOM_REPAIR_ID,
                        label: custom,
                    });
                    setCustomRepairText(custom);
                }}/>),
            footer: (<SimpleDrawerFooter>
          <FooterActionButton isSubmitting={isSubmitting} onClick={submitCustomRepair}>
            Save
          </FooterActionButton>
        </SimpleDrawerFooter>),
        },
        {
            name: PartsHelpTabs.FMC_CUSTOM_REPAIR,
            label: 'FMC Repair Search',
            render: () => (<FmcRepairSearch onSelect={onFmcRepairSelect} customRepairText={customRepairText}/>),
            footer: (<SimpleDrawerFooter>
          <FooterActionButton onClick={handleCustomFmcRepairSubmit} disabled={!fmcRepairId} isSubmitting={isSubmitting}>
            Submit
          </FooterActionButton>
        </SimpleDrawerFooter>),
        },
        {
            name: PartsHelpTabs.CUSTOMER_PROVIDED_PARTS,
            label: 'Customer Provided Parts',
            render: () => (<CustomerProvidedPartsDrawer setIsCustomerProvidedPartsValid={setIsCustomerProvidedPartsValid} selectedCustomerParts={selectedCustomerParts} isCheckedAllCustomerParts={isCheckedAllCustomerParts} setSelectedCustomerParts={setSelectedCustomerParts} setIsCheckedAllCustomerParts={setIsCheckedAllCustomerParts} isPartSelected={isPartSelected} additionalRepairComponents={additionalRepairComponents} setAdditionalRepairComponents={setAdditionalRepairComponents} invoice={invoice}/>),
            footer: (<SimpleDrawerFooter>
          <FooterActionButton disabled={!isCustomerProvidedPartsValid} isSubmitting={isSubmitting} onClick={handleSubmitBatch}>
            Confirm
          </FooterActionButton>
        </SimpleDrawerFooter>),
        },
        {
            name: PartsHelpTabs.FAULTY_PART_RECEIVED_SELECT_REPAIR,
            label: 'Faulty Part Received',
            render: () => (<AdditionalComponentsRequired goNext={(repair) => {
                    goToSelectRepairComponent(repair, PartsHelpTabs.FAULTY_PART_RECEIVED_SELECT_REPAIR_COMPONENT);
                }} instructions="Which repair has the faulty part?" invoice={invoice}/>),
        },
        {
            name: PartsHelpTabs.FAULTY_PART_RECEIVED_SELECT_REPAIR_COMPONENT,
            label: 'Faulty Part Received',
            render: () => repair ? (<RepairComponentsList repair={repair} includeFluids instructions="Which part is faulty?" selectedPart={selectedPart} setSelectedPart={setSelectedPart}/>) : null,
            footer: (<SimpleDrawerFooter>
          <FooterActionButton onClick={handleFaultyPart} disabled={!selectedPart} isSubmitting={isSubmitting}>
            Next
          </FooterActionButton>
        </SimpleDrawerFooter>),
        },
        {
            name: PartsHelpTabs.PRICING_QUOTE,
            label: 'Pricing Quote',
            render: () => (<AdditionalRepairsRequired title="Get a Quote" instructions="Search for a repair to find a quote." onRepairSelect={(selectedRepair) => __awaiter(void 0, void 0, void 0, function* () {
                    setRepairId(selectedRepair.repairId);
                    setRepair(selectedRepair);
                })} onCustomRepairSelected={() => {
                    setRepairId(CUSTOM_REPAIR_ID);
                    goToTab(PartsHelpTabs.INPUT_CUSTOM_REPAIR);
                }} onSearchRepairs={handleSearchRepair}/>),
            footer: (<SimpleDrawerFooter>
          <FooterActionButton onClick={handleRepairSubmit} disabled={!repairId} isSubmitting={isSubmitting}>
            Next
          </FooterActionButton>
        </SimpleDrawerFooter>),
        },
        {
            name: PartsHelpTabs.URGENT_PENDING_PARTS,
            label: 'Urgent Pending Parts',
            render: () => <UrgentPendingParts />,
            footer: (<SimpleDrawerFooter>
          <FooterActionButton onClick={submitUrgentPartsNote}>
            Okay
          </FooterActionButton>
        </SimpleDrawerFooter>),
        },
        {
            name: PartsHelpTabs.WRONG_PART_SELECT_REPAIR,
            label: 'Wrong Part',
            render: () => (<AdditionalComponentsRequired goNext={(repair) => {
                    goToSelectRepairComponent(repair, PartsHelpTabs.WRONG_PART_SELECT_REPAIR_COMPONENT);
                }} instructions="Which repair has the wrong part?" disabled={(repair) => {
                    const pickedUpParts = repair.price[0].parts.filter((part) => part.status === 'PICKED_UP');
                    const pickedUpFluids = repair.price[0].fluids.filter((fluid) => fluid.status === 'PICKED_UP');
                    return pickedUpParts.length === 0 && pickedUpFluids.length === 0;
                }}/>),
        },
        {
            name: PartsHelpTabs.WRONG_PART_SELECT_REPAIR_COMPONENT,
            label: 'Wrong Part',
            render: () => repair ? (<RepairComponentsList repair={repair} instructions="Which part is wrong?" selectedPart={selectedPart} setSelectedPart={setSelectedPart} filter={(part) => part.status === 'PICKED_UP'} includeFluids/>) : null,
            footer: (<SimpleDrawerFooter>
          <FooterActionButton onClick={() => {
                    handleClose();
                    setWrongPartOpen(true);
                }} disabled={!selectedPart} isSubmitting={isSubmitting}>
            Next
          </FooterActionButton>
        </SimpleDrawerFooter>),
        },
    ];
    return (<>
      <AddRepairOrderDrawer open={open} onClose={handleClose} header={(_b = (_a = routes[tab]) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : 'Parts Help'} onNavigateBack={goBack} arrowClose={tab === 0} isSmall={![
            PartsHelpTabs.ADDITIONAL_REPAIRS_REQUIRED,
            PartsHelpTabs.PRICING_QUOTE,
        ].includes(tab)} footer={(_c = routes[tab].footer) !== null && _c !== void 0 ? _c : null}>
        <Slider currentSlide={tab} slides={routes}/>
      </AddRepairOrderDrawer>

      <VisitCapacityWarningModal open={showCapacityWarning} onClose={handleCapacityWarningClose} warningMessage={capacityWarningMessage}/>

      <AddVehicleResponsiblePartyConfirmationModal open={openConfirmation} onClose={handleSubmitCancelNewRP} handleSubmitConfirmNewRP={handleSubmitConfirmNewRP}/>

      {repair && (<WrongPartDrawer repair={repair} open={wrongPartOpen} referenceNum={referenceNum} onClose={() => {
                setWrongPartOpen(false);
            }} selectedComponent={selectedPart}/>)}
    </>);
};
export default PartsHelpDrawer;
