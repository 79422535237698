import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const createSimpleReducer = (fieldName) => (state, action) => (Object.assign(Object.assign({}, state), { [fieldName]: action.payload }));
const DEFAULT_STATE = {
    tab: '0',
    invoice: undefined,
    screen: undefined,
    amount: 0,
    balanceOwed: undefined,
    paymentsReceivedTotal: undefined,
    paymentType: undefined,
    paymentSubtype: undefined,
    workOrderResponsibleParty: undefined,
    workOrderPayments: undefined,
    workOrderId: undefined,
    responsibleParties: undefined,
    totalInvoicePrice: undefined,
    hasResponsibleParties: false,
    workOrderResponsibleParties: [],
};
const reducers = handleActions({
    [ActionCreators.SET_PAYMENT_DRAWER_INVOICE.type]: createSimpleReducer('invoice'),
    [ActionCreators.SET_PAYMENT_DRAWER_SCREEN.type]: createSimpleReducer('screen'),
    [ActionCreators.SET_PAYMENT_DRAWER_TAB.type]: createSimpleReducer('tab'),
    [ActionCreators.SET_PAYMENT_DRAWER_AMOUNT.type]: createSimpleReducer('amount'),
    [ActionCreators.SET_PAYMENT_DRAWER_BALANCE_OWED.type]: createSimpleReducer('balanceOwed'),
    [ActionCreators.SET_PAYMENT_DRAWER_PAYMENTS_RECEIVED_TOTAL.type]: createSimpleReducer('paymentReceivedTotal'),
    [ActionCreators.SET_PAYMENT_DRAWER_PAYMENT_TYPE.type]: createSimpleReducer('paymentType'),
    [ActionCreators.SET_PAYMENT_DRAWER_PAYMENT_SUBTYPE.type]: createSimpleReducer('paymentSubtype'),
    [ActionCreators.SET_PAYMENT_DRAWER_WORK_ORDER_RESPONSIBLE_PARTY.type]: createSimpleReducer('workOrderResponsibleParty'),
    [ActionCreators.SET_PAYMENT_DRAWER_WORK_ORDER_PAYMENTS.type]: createSimpleReducer('workOrderPayments'),
    [ActionCreators.SET_PAYMENT_DRAWER_WORK_ORDER_ID.type]: createSimpleReducer('workOrderId'),
    [ActionCreators.SET_PAYMENT_DRAWER_TOTAL_INVOICE_PRICE.type]: createSimpleReducer('totalInvoicePrice'),
    [ActionCreators.SET_PAYMENT_DRAWER_RESPONSIBLE_PARTIES.type]: createSimpleReducer('responsibleParties'),
    [ActionCreators.SET_PAYMENT_DRAWER_HAS_RESPONSIBLE_PARTIES.type]: createSimpleReducer('hasResponsibleParties'),
    [ActionCreators.SET_PAYMENT_DRAWER_WORK_ORDER_RESPONSIBLE_PARTIES.type]: createSimpleReducer('workOrderResponsibleParties'),
}, DEFAULT_STATE);
export default reducers;
