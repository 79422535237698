import styled from 'styled-components';
import { TableCell, TableRow } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const PartnerAPIRowContainer = styled(TableRow) `
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    outline: solid 1px ${colors.BLUE};
  }
`;
export const PartnerAPICell = styled(TableCell) `
  padding: 26px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 15% !important;
  max-width: 15% !important;

  span.PartnerAPIMore {
    color: ${colors.GRAY_76};
    margin-left: 4px;
    white-space: nowrap;
  }
`;
export const SubtextContainer = styled.div `
  display: flex;
  flex-direction: column;

  .subtext {
    color: ${colors.GRAY_76};
  }
`;
