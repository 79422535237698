import { combineReducers } from 'redux';
import partsReducer from './parts/reducers';
import vendorsReducer from './vendors/reducers';
import distributorsReducer from './distributors/reducers';
import inventoryReducer from './inventory/reducers';
export default combineReducers({
    parts: partsReducer,
    vendors: vendorsReducer,
    distributors: distributorsReducer,
    inventory: inventoryReducer,
});
