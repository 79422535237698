/**
 * Returns object with all undefined or null properties removed
 */
const compact = (obj) => {
    if (!obj)
        return obj;
    const compacted = Object.assign({}, obj);
    Object.keys(compacted).forEach((key) => (compacted[key] === undefined || compacted[key] === null) &&
        delete compacted[key]);
    return compacted;
};
export default compact;
