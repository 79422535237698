import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    plans: [],
    address: undefined,
    subscription: undefined,
    applyTokensResponse: undefined,
};
const reducers = handleActions({
    [ActionCreators.APPLY_TOKENS.SUCCESS.type]: createSimpleHandler('applyTokensResponse'),
}, DEFAULT_STATE);
export default reducers;
