import React from 'react';
import { Typography } from 'src/components/mui';
import { Phone, AlternateEmail } from 'src/components/mui/icons';
import { formatPhone } from 'src/utils/formatter';
import { UserProfileInfoWrapper, UserContactInfo, UserPhoneInfo, UserEmailInfo, UserDetailsInfo, UserDetailsRow, } from './UserProfileInfo.styles';
const UserProfileInfo = ({ user }) => {
    var _a;
    return (<UserProfileInfoWrapper>
      <div>
        <Typography style={{ marginBottom: '10px' }} align="left" variant="h5">
          {user.firstName} {user.lastName}
        </Typography>
        <UserContactInfo>
          <UserPhoneInfo>
            <Phone />
            {(user.phoneNumber && formatPhone(user.phoneNumber)) || 'N/A'}
          </UserPhoneInfo>
          <UserEmailInfo>
            <AlternateEmail />
            {user.email}
          </UserEmailInfo>
        </UserContactInfo>
        <UserDetailsInfo>
          <UserDetailsRow>
            <Typography>Resource ID</Typography>
            <Typography>{user.resourceId || 'N/A'}</Typography>
          </UserDetailsRow>
          <UserDetailsRow>
            <Typography>User Type</Typography>
            <Typography>
              {user.userType &&
            user.userType
                .toLowerCase()
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </Typography>
          </UserDetailsRow>
          <UserDetailsRow>
            <Typography>ID</Typography>
            <Typography>{user.id}</Typography>
          </UserDetailsRow>
          <UserDetailsRow>
            <Typography>UID</Typography>
            <Typography>{user.uid}</Typography>
          </UserDetailsRow>
          <UserDetailsRow>
            <Typography>Skills</Typography>
            <Typography>
              {((_a = user.skills) === null || _a === void 0 ? void 0 : _a.length) > 0
            ? user.skills.map((skill, index) => `${skill.name}${user.skills.length - 1 !== index ? ', ' : ''}`)
            : 'N/A'}
            </Typography>
          </UserDetailsRow>
        </UserDetailsInfo>
      </div>
    </UserProfileInfoWrapper>);
};
export default UserProfileInfo;
