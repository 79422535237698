import React from 'react';
import styled from 'styled-components';
import { Divider, Typography } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import { flexCenter } from 'src/styles/styled';
const Container = styled('div') `
  ${flexCenter};

  color: ${colors.GRAY_3F};
  border-top: var(--border-top, 1px dashed ${colors.lightGrey});
  flex-direction: column;
  height: auto;
  padding: var(--padding, 24px 0);
`;
const Row = styled('div') `
  display: inherit;
  justify-content: space-between;
  align-items: center;
  width: inherit;

  &:not(:nth-of-type(2)) {
    margin-top: 4px;
  }
`;
const PayerSummary = ({ totalText, totalPrice }) => {
    var _a;
    return (<>
      <Container style={{
            borderTop: 'none',
            padding: '10px 0 10px',
        }}>
        <Row>
          <Typography variant="subtitle2">{totalText || 'Total:'}</Typography>
          <Typography variant="subtitle2">
            ${(_a = totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.toFixed) === null || _a === void 0 ? void 0 : _a.call(totalPrice, 2)}
          </Typography>
        </Row>
      </Container>
      <Divider />
    </>);
};
export default PayerSummary;
