import React from 'react';
import { Typography } from 'src/components/mui';
import EditableInput from 'src/components/form/EditableInput';
import { editInputStyle, TitleContainer, TitleText } from '../styles';
import useTitle from './useTitle';
const titleStyle = {
    minWidth: 285,
};
const Title = () => {
    const { fleet, vehicles, handleNameUpdate } = useTitle();
    if (!fleet)
        return null;
    return (<TitleContainer>
      <TitleText style={titleStyle} variant="h1">
        <EditableInput style={editInputStyle} value={fleet === null || fleet === void 0 ? void 0 : fleet.name} onUpdate={handleNameUpdate}>
          {fleet === null || fleet === void 0 ? void 0 : fleet.name}
        </EditableInput>
      </TitleText>
      <Typography variant="body2">{vehicles} Vehicles</Typography>
    </TitleContainer>);
};
export default Title;
