import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { StatusesMainColors } from '../constants';
export const Heading = styled.span `
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -;
  color: ${colors.BLACK};
`;
export const JobCardContainer = styled.div `
  padding: 0 0 20px;
  border-radius: 4px;
  box-shadow: inset 8px 0 0 0
    ${(props) => {
    var _a;
    return (_a = StatusesMainColors[props.status]) !== null && _a !== void 0 ? _a : StatusesMainColors.READY;
}};
  border: solid 1px #dedede;
  margin-bottom: 16px;
`;
