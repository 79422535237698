import { createSelector } from 'reselect';
export const selectFleets = (state) => state.admin.fleets;
export const selectFleetById = (id) => createSelector(selectFleets, (fleets) => fleets.byId[id]);
export const selectProductFleetsByProductId = (id) => createSelector(selectFleets, (fleets) => fleets.pricing[id]);
export const selectDashboard = createSelector(selectFleets, (fleets) => { var _a; return (_a = fleets.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectCarsDashboard = createSelector(selectFleets, (fleets) => { var _a; return (_a = fleets.carsDashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectRepairsDashboard = createSelector(selectFleets, (fleets) => { var _a; return (_a = fleets.fleetRepairsDashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectInvoicesDashboard = createSelector(selectFleets, (fleets) => { var _a; return (_a = fleets.fleetInvoicesDashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardFleets = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectDashboardPagination = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardParams = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectCarsDashboardFleets = createSelector(selectCarsDashboard, (carsDashboard) => { var _a; return (_a = carsDashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectCarsDashboardPagination = createSelector(selectCarsDashboard, (carsDashboard) => { var _a; return (_a = carsDashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectCarsDashboardParams = createSelector(selectCarsDashboard, (carsDashboard) => { var _a; return (_a = carsDashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectCarsDashboardFetching = createSelector(selectCarsDashboard, (carsDashboard) => { var _a; return (_a = carsDashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
export const selectFleetInvoicesDashboard = createSelector(selectInvoicesDashboard, (invoicesDashboard) => { var _a; return (_a = invoicesDashboard === null || invoicesDashboard === void 0 ? void 0 : invoicesDashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectFleetInvoicesDashboardPagination = createSelector(selectInvoicesDashboard, (invoicesDashboard) => { var _a; return (_a = invoicesDashboard.pagination) !== null && _a !== void 0 ? _a : { content: [] }; });
export const selectFleetInvoicesDashboardFetching = createSelector(selectInvoicesDashboard, (invoicesDashboard) => invoicesDashboard === null || invoicesDashboard === void 0 ? void 0 : invoicesDashboard._isFetching);
export const selectFleetInvoicesDashboardParams = createSelector(selectInvoicesDashboard, (invoicesDashboard) => { var _a; return (_a = invoicesDashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectFleetRepairsDashboard = createSelector(selectRepairsDashboard, (repairsDashboard) => { var _a; return (_a = repairsDashboard === null || repairsDashboard === void 0 ? void 0 : repairsDashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectFleetRepairsDashboardPagination = createSelector(selectRepairsDashboard, (repairsDashboard) => { var _a; return (_a = repairsDashboard.pagination) !== null && _a !== void 0 ? _a : { content: [] }; });
export const selectFleetRepairsDashboardFetching = createSelector(selectRepairsDashboard, (dashboard) => dashboard === null || dashboard === void 0 ? void 0 : dashboard._isFetching);
export const selectFleetRepairsDashboardParams = createSelector(selectRepairsDashboard, (repairsDashboard) => { var _a; return (_a = repairsDashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectFleetTotalElements = createSelector(selectCarsDashboard, (carsDashboard) => { var _a; return (_a = carsDashboard.totalElements) !== null && _a !== void 0 ? _a : 0; });
export const selectFleetsSearch = createSelector(selectFleets, (fleets) => { var _a, _b; return (_b = (_a = fleets === null || fleets === void 0 ? void 0 : fleets.searchResults) === null || _a === void 0 ? void 0 : _a.fleets) === null || _b === void 0 ? void 0 : _b.content; });
export const selectStationsSearch = createSelector(selectFleets, (fleets) => { var _a; return (_a = fleets === null || fleets === void 0 ? void 0 : fleets.searchResults) === null || _a === void 0 ? void 0 : _a.stations; });
export const partDistributorsSelector = createSelector(selectFleets, (fleets) => fleets === null || fleets === void 0 ? void 0 : fleets.partDistributionLocations);
export const selectFmcDashboard = createSelector(selectFleets, (fleets) => { var _a; return (_a = fleets.fmcDashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectFmcs = createSelector(selectFmcDashboard, (fmcDashboard) => { var _a; return (_a = fmcDashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectFmcPagination = createSelector(selectFmcDashboard, (fmcDashboard) => { var _a; return (_a = fmcDashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectFmcParams = createSelector(selectFmcDashboard, (fmcDashboard) => { var _a; return (_a = fmcDashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectAllFleetCars = createSelector(selectFleets, (fleet) => fleet.allCars);
