import React, { useState } from 'react';
import { Button } from 'src/components/mui';
import { SimpleDrawer, SimpleDrawerHeader } from 'src/components/SimpleDrawer';
import AdditionalDetailsDrawerForm from './AdditionalDetailsDrawerForm';
const AdditionalDetailsDrawer = ({ part, setSelectedPart, }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    return (<>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)} style={{
            margin: '0 16px 16px',
        }}>
        {(part === null || part === void 0 ? void 0 : part.partMetadata) ? 'Edit' : 'Add'} Additional Details
      </Button>
      <SimpleDrawer open={open} onClose={handleClose}>
        <SimpleDrawerHeader title="Additional Details" onClose={handleClose}/>

        <AdditionalDetailsDrawerForm part={part} handleClose={handleClose} setSelectedPart={setSelectedPart}/>
      </SimpleDrawer>
    </>);
};
export default AdditionalDetailsDrawer;
