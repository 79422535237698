import React from 'react';
import styled from 'styled-components';
import { IconButton } from 'src/components/mui';
import { DeleteForever } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
const IconButtonStyled = styled(IconButton) `
  color: ${colors.error};
`;
/**
 * Trash can delete button. [MUI IconButtonAPI](https://v4.mui.com/api/icon-button/#iconbutton-api)
 */
const DeleteButton = ({ onClick, style }) => (<IconButtonStyled style={style} onClick={onClick}>
    <DeleteForever />
  </IconButtonStyled>);
export default DeleteButton;
