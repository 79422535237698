var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { colors, theme } from 'src/styles/theme';
import styled from 'styled-components';
import { Button, FormControl, Grid, IconButton, Input, InputAdornment, } from 'src/components/mui';
import { Lock, Info } from 'src/components/mui/icons';
import { useDispatch } from 'react-redux';
import { forgotPassword } from 'src/modules/auth/actions';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import { toast } from 'src/components/SimpleToast';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 300px;
`;
const HeaderLayout = styled.div `
  width: 100%;
  background-color: ${colors.YELLOW_E1};
`;
const Wrapper = styled.div `
  width: 60%;
  margin: 0 auto;

  ${theme.breakpoints.up('md')} {
    font-size: 1rem;
  }
`;
const EmailFormControl = styled(FormControl) `
  margin-bottom: 25px;
`;
const RequirementMessage = styled.div `
  display: flex;
  align-items: center;
  color: ${colors.BLUE};
  font-size: 16px;

  span {
    margin-right: 10px;
  }

  .MuiIcon-root {
    color: ${colors.BLUE};
  }
`;
const ResetPassword = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [submitSucceeded, setSubmitSucceeded] = useState(false);
    const validateEmail = () => {
        const validEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return !email || validEmail.test(email);
    };
    const handleEmailInputChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
    };
    const handleSendPassword = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        const emailPayload = {
            email,
        };
        const emailResponse = yield dispatch(forgotPassword(emailPayload));
        if (!emailResponse.error) {
            analyticsTrackEvent('Forgotten Password Email Sent', {
                Email: emailPayload.email,
            });
            toast.success('Forgotten Password Email Sent successfully');
            setSubmitSucceeded(!submitSucceeded);
        }
    });
    useEffect(() => {
        setEmailError(!validateEmail());
    }, [email]); // eslint-disable-line react-hooks/exhaustive-deps
    return (<Container>
      <HeaderLayout>
        <Wrapper>
          <h1>Reset Password</h1>
        </Wrapper>
      </HeaderLayout>
      <Wrapper align="center">
        <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSendPassword}>
          <EmailFormControl variant="standard" error={emailError}>
            <Input id="reset-password-email-id" value={email} disabled={submitSucceeded} placeholder="Email" onChange={handleEmailInputChange} type="email" startAdornment={<InputAdornment position="start">@</InputAdornment>} endAdornment={submitSucceeded && (<InputAdornment position="end">
                    <IconButton disabled edge="end">
                      <Lock />
                    </IconButton>
                  </InputAdornment>)}/>
          </EmailFormControl>
          <Grid container style={{ justifyContent: 'space-between', alignItems: 'center' }} spacing={2}>
            <Grid item xs={8}>
              {submitSucceeded && (<RequirementMessage>
                  <span>
                    <Info />
                  </span>
                  <p>
                    Check your email for a link to reset your password. If it
                    doesn&apos;t appear within a few minutes, check your spam
                    folder.
                  </p>
                </RequirementMessage>)}
            </Grid>
            <Grid item xs={4}>
              {submitSucceeded ? (<Button color="primary" variant="contained" href="/login">
                  Return to sign in
                </Button>) : (<Button color="primary" disabled={!email || emailError} fullWidth={false} variant="contained" onClick={handleSendPassword}>
                  Send password reset email
                </Button>)}
            </Grid>
          </Grid>
        </form>
      </Wrapper>
    </Container>);
};
export default ResetPassword;
