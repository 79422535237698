import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { mapRegion, mapSkeduloRegion } from 'src/AdminApp/modules/mappers';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byId: {},
    autocomplete: [],
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['name,asc'],
        },
    },
    skeduloRegions: [],
};
const skeduloRegionsSuccessReducer = (state, action) => {
    const skeduloRegions = action.payload.map(mapSkeduloRegion);
    return Object.assign(Object.assign({}, state), { skeduloRegions });
};
const regionSuccessReducer = (state, action) => {
    const model = mapRegion(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [model.id]: fetched(model) }), dashboard: Object.assign(Object.assign({}, state.dashboard), { _isFetching: false }) });
};
const regionsSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { params } = action.meta;
    const { pagination } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { params, pagination: {
                pageSize: pagination.pageSize,
                totalElements,
                totalPages,
                pageNumber: pagination.pageIndex,
                sort: pagination.sort,
            } }) });
};
const regionsFetchReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const regionFetchReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { _isFetching: true }) });
};
const regionsClearReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const regionsClearFiltersReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params: Object.assign(Object.assign({}, state.dashboard.params), { filters: DEFAULT_STATE.dashboard.params.filters }) }) });
};
const regionsAutocompleteSuccessReducer = (state, action) => {
    const results = action.payload.map(mapRegion);
    return Object.assign(Object.assign({}, state), { autocomplete: results });
};
const regionUpdateSuccessReducer = (state, action) => {
    const { id } = action.meta;
    const model = mapRegion(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [id]: Object.assign(Object.assign({}, state.byId[id]), model) }) });
};
const regionAddSuccessReducer = (state, action) => {
    const model = mapRegion(action.payload);
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [model.id]: Object.assign({}, model) }) });
};
const regionDeleteSuccessReducer = (state, action) => {
    var _a, _b;
    const { id } = action.meta;
    const content = (_b = (_a = state === null || state === void 0 ? void 0 : state.dashboard) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.filter((x) => x.id !== id);
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { dashboard: {
                content,
            }, pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { totalElements: state.dashboard.pagination.totalElements - 1 }) }) });
};
const regionFetchZipCodeReducer = (state) => {
    return Object.assign({}, state);
};
const reducers = handleActions({
    [ActionCreators.SKEDULO_REGIONS.SUCCESS.type]: skeduloRegionsSuccessReducer,
    [ActionCreators.REGIONS.SUCCESS.type]: regionsSuccessReducer,
    [ActionCreators.REGIONS.FETCH.type]: regionsFetchReducer,
    [ActionCreators.REGIONS.CLEAR.type]: regionsClearReducer,
    [ActionCreators.REGIONS.CLEAR_FILTERS.type]: regionsClearFiltersReducer,
    [ActionCreators.REGIONS_AUTOCOMPLETE.SUCCESS.type]: regionsAutocompleteSuccessReducer,
    [ActionCreators.REGION.SUCCESS.type]: regionSuccessReducer,
    [ActionCreators.REGION.FETCH.type]: regionFetchReducer,
    [ActionCreators.ADD_REGION.SUCCESS.type]: regionAddSuccessReducer,
    [ActionCreators.UPDATE_REGION.SUCCESS.type]: regionUpdateSuccessReducer,
    [ActionCreators.DELETE_REGION.SUCCESS.type]: regionDeleteSuccessReducer,
    [ActionCreators.ARCHIVE_REGION.SUCCESS.type]: regionDeleteSuccessReducer,
    [ActionCreators.SERVICE_AREA_BY_ZIP_CODE.SUCCESS.type]: regionFetchZipCodeReducer,
}, DEFAULT_STATE);
export default reducers;
