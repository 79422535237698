var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, MenuItem, TextField as SelectField } from 'src/components/mui';
import { SimpleModal } from 'src/components/layout';
import { TextField } from 'src/AdminApp/components/form';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { LabeledGridField } from 'src/AdminApp/components/repairs/statement/styledComponents';
const DistributorModal = ({ open, onClose, onSubmit, }) => {
    const DistributorSchema = Yup.object().shape({
        name: Yup.string().min(1).max(255).required(),
        status: Yup.string().min(1).max(255).required(),
    });
    const statuses = ['ACTIVE', 'INACTIVE'];
    return (<SimpleModal title="Add Parts Distributor" maxWidth="md" open={open} onClose={onClose}>
      <Formik initialValues={{
            name: '',
            status: 'ACTIVE',
        }} validationSchema={DistributorSchema} onSubmit={(values, { setSubmitting, resetForm }) => {
            onSubmit(Object.assign({}, values)).then(() => {
                resetForm({ values: Object.assign({}, values) });
                setSubmitting(false);
            });
        }}>
        {({ handleSubmit, isValid, setFieldValue }) => (<Grid>
            <LabeledGridField label="Name">
              <TextField type="string" name="name" fullWidth/>
            </LabeledGridField>
            <LabeledGridField label="Status">
              <Field name="status">
                {({ field }) => (<SelectField select value={field.value || ''} onChange={(event) => __awaiter(void 0, void 0, void 0, function* () {
                    const item = event.target.value || '';
                    yield setFieldValue(field.name, item);
                })}>
                    {statuses.map((status) => (<MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>))}
                  </SelectField>)}
              </Field>
            </LabeledGridField>
            <Grid item xs={12}>
              <SimpleTwoButtonRow disabledSubmit={!isValid} submitText="Save" onCancel={onClose} onSubmit={handleSubmit}/>
            </Grid>
          </Grid>)}
      </Formik>
    </SimpleModal>);
};
export default DistributorModal;
