import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Typography } from 'src/components/mui';
import SimpleChip from 'src/components/SimpleChip';
import { flexCenter } from 'src/styles/styled';
import { colors } from 'src/styles/theme';
import { DATE_TIME_12H_FORMAT } from 'src/timeConstants';
const statusMap = {
    CLOSED: {
        text: 'Closed',
        bgColor: colors.darkGrey,
    },
    NEW: {
        text: 'New',
        bgColor: colors.success,
    },
    OPEN: {
        text: 'Open',
        bgColor: colors.blue2,
    },
};
const Card = styled('div') `
  ${flexCenter};

  border-radius: 4px;
  border: 1px solid ${colors.GRAY_DE};
  flex-direction: column;
  padding: 24px 16px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  > div:not(:last-of-type) {
    border-bottom: 1px solid ${colors.GRAY_DE};
    padding-bottom: 16px;
  }

  > div:not(:first-of-type) {
    margin-top: 16px;
  }
`;
const TitleArea = styled('div') `
  ${flexCenter};

  justify-content: space-between;
`;
const ROLink = styled(Link) `
  color: ${colors.primary};
`;
const Area = styled('div') `
  ${flexCenter};

  justify-content: flex-start;
`;
const Item = styled('div') `
  ${flexCenter};

  align-items: flex-start;
  flex-direction: column;
  flex: 5;

  &:first-of-type {
    flex: 4;
  }
`;
const ItemText = styled(Typography) `
  font-weight: 500;
`;
const MoreBtn = styled('div') `
  cursor: pointer;

  > h6 {
    text-decoration: underline;
  }
`;
const ROCard = ({ RO }) => {
    var _a;
    const [expand, setExpand] = useState(false);
    if (!(RO === null || RO === void 0 ? void 0 : RO.referenceNum))
        return null;
    const handleExpand = () => setExpand(true);
    const date = moment(RO.appointmentScheduleStartLocal);
    const appointment = date.isValid()
        ? date.format(DATE_TIME_12H_FORMAT)
        : 'None';
    const showExpand = RO.suggestedRepairs.length > 2 && !expand;
    const services = !showExpand
        ? RO.suggestedRepairs
        : RO.suggestedRepairs.slice(0, 2);
    const more = RO.suggestedRepairs.length - 2;
    return (<Card>
      <TitleArea>
        <Typography variant="subtitle2">
          Ref #:{' '}
          <ROLink target="_blank" to={`/admin/repairs/${RO.referenceNum}`}>
            {RO.referenceNum}
          </ROLink>
        </Typography>
        <SimpleChip $bgColor={statusMap[RO.status].bgColor} label={statusMap[RO.status].text} $textColor={colors.white}/>
      </TitleArea>
      <Area>
        <Item>
          <Typography variant="subtitle1">Appointment</Typography>
          <ItemText variant="body1">{appointment}</ItemText>
        </Item>
        <Item>
          <Typography variant="subtitle1">Total</Typography>
          <ItemText variant="body1">
            {!(RO === null || RO === void 0 ? void 0 : RO.total) ? 'N/A' : `$ ${(_a = RO.total) === null || _a === void 0 ? void 0 : _a.toFixed(2)}`}
          </ItemText>
        </Item>
      </Area>
      <Area>
        <Item>
          <Typography variant="subtitle1">Services</Typography>
          {services.map((name) => (<ItemText key={name} variant="body1">
              {name}
            </ItemText>))}
          {showExpand && (<MoreBtn role="button" onClick={handleExpand}>
              <Typography variant="subtitle1">+{more} more</Typography>
            </MoreBtn>)}
        </Item>
      </Area>
    </Card>);
};
export default ROCard;
