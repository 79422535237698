import React from 'react';
import Dropzone from 'react-dropzone';
import { CircularProgress } from 'src/components/mui';
import useUploader from 'src/PartnersApp/pages/PartnerManagement/PartnerDetailsDialog/Assets/components/Uploader/useUploader';
import { STATIC_URL_BASE } from 'src/styles/theme';
import { ProgressContainer, UploaderContainer, UploaderImage, UploaderLabel, } from './Uploader.styles';
const Uploader = ({ partner, hasAssets = false }) => {
    const { loading, handleDrop } = useUploader({ partnerId: partner.id });
    return (<UploaderContainer hasAssets={hasAssets}>
      {loading && (<ProgressContainer>
          <CircularProgress />
        </ProgressContainer>)}

      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (<>
            <input {...getInputProps()}/>

            <UploaderLabel {...getRootProps()} hasAssets={hasAssets} isLoading={loading}>
              {!loading && (<>
                  <UploaderImage src={`${STATIC_URL_BASE}tools/menu-icons/folder.svg`} hasAssets={hasAssets}/>
                  <span>
                    Drag &amp; Drop or <span>Browse</span>
                  </span>
                </>)}
            </UploaderLabel>
          </>)}
      </Dropzone>
    </UploaderContainer>);
};
export default Uploader;
