import styled from 'styled-components';
import { Grid } from 'src/components/mui';
import { FONT_FAMILY } from 'src/styles/theme';
import { Colors } from 'src/PopsApp/modules/globalStyle';
export const SectionContainer = styled(Grid) `
  margin-bottom: 20px;
  border-bottom: ${(props) => props.bordered ? `1px solid ${Colors.GRAY_CC}` : 'none'};

  & > * {
    font-family: ${FONT_FAMILY} !important;
  }
`;
SectionContainer.defaultProps = {
    bordered: true,
};
