import React from 'react';
import { useSelector } from 'react-redux';
import { selectFees } from 'src/AdminApp/modules/marketingSources/selectors';
import { TableRow, TableCell } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
import EditIcon from 'src/components/icons/EditIcon';
import useAdminUser from 'src/hooks/useAdminUser';
const StyledTableRow = styled(TableRow) `
  cursor: pointer;
  margin-top: 5px;

  td {
    font-weight: 300;
    font-size: 15px;
    color: ${colors.GRAY_3F};
  }

  td:first-child {
    width: 35%;
  }

  &:hover {
    outline: solid 2px ${colors.BLUE};
  }
`;
const ListItem = ({ item, onItemClick }) => {
    var _a, _b, _c, _d, _e;
    const fees = useSelector(selectFees);
    const modifiedBy = (_a = useAdminUser({ user: item.modifiedBy })) !== null && _a !== void 0 ? _a : (_b = item.modifiedBy) === null || _b === void 0 ? void 0 : _b.split('|').map((str) => (<>
        {str}
        <br />
      </>));
    const createdBy = (_c = useAdminUser({ user: item.createdBy })) !== null && _c !== void 0 ? _c : (_d = item.createdBy) === null || _d === void 0 ? void 0 : _d.split('|').map((str) => (<>
        {str}
        <br />
      </>));
    const visibilityStrings = [];
    item.visibility.forEach((visibility) => {
        if (visibility === 1) {
            visibilityStrings.push('Public');
        }
        if (visibility === 2) {
            visibilityStrings.push('Admin');
        }
    });
    if (!visibilityStrings.length) {
        visibilityStrings.push('None');
    }
    const preventFeeCodes = ((_e = item.preventFeeCodes) !== null && _e !== void 0 ? _e : [])
        .map((c) => { var _a; return (_a = fees.find((f) => f.code === c)) === null || _a === void 0 ? void 0 : _a.name; })
        .join(', ') || 'N/A';
    return (<StyledTableRow>
      <TableCell>{item.displayName}</TableCell>
      <TableCell>{visibilityStrings.join(', ')}</TableCell>
      <TableCell>{preventFeeCodes}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{item.created}</TableCell>
      <TableCell style={{ maxWidth: '250px', wordWrap: 'break-word' }}>
        {createdBy}
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{item.modified}</TableCell>
      <TableCell style={{ maxWidth: '250px', wordWrap: 'break-word' }}>
        {modifiedBy}
      </TableCell>
      <TableCell>
        <EditIcon onClick={() => onItemClick(item.id)}/>
      </TableCell>
    </StyledTableRow>);
};
export default ListItem;
