import createAPIAction from 'src/modules/api';
import API_URLS from 'src/AdminApp/modules/api/urls';
import createDefaultActionCreators, { createDefaultApiActionCreators, } from 'src/utils/createActions';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
export const ActionCreators = {
    GET_YEARS: createDefaultApiActionCreators('GET_YEARS'),
    GET_MAKE: createDefaultApiActionCreators('GET_MAKE'),
    GET_MODEL: createDefaultApiActionCreators('GET_MODEL'),
    GET_DESCRIPTION: createDefaultActionCreators('GET_DESCRIPTION'),
    GET_DESCRIPTION_BY_VIN: createDefaultApiActionCreators('GET_DESCRIPTION_BY_VIN'),
    GET_DESCRIPTION_BY_PLATE: createDefaultApiActionCreators('GET_DESCRIPTION_BY_PLATE'),
};
export const getAutoselectionYears = () => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_YEARS),
    endpoint: API_URLS.AUTOSELECTION.YEARS(),
    method: 'GET',
});
export const getAutoselectionMakes = ({ year }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_MAKE),
    endpoint: API_URLS.AUTOSELECTION.MAKES({ year }),
    method: 'GET',
});
export const getAutoselectionModels = ({ year, makeId, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_MODEL),
    endpoint: API_URLS.AUTOSELECTION.MODELS({ year, makeId }),
    method: 'GET',
});
export const getVehicleDescriptionByVin = ({ vin }) => createAPIAction({
    types: [
        ActionCreators.GET_DESCRIPTION_BY_VIN.FETCH(),
        ActionCreators.GET_DESCRIPTION_BY_VIN.SUCCESS({
            meta: () => ({ vin }),
        }),
        ActionCreators.GET_DESCRIPTION_BY_VIN.FAILURE(),
    ],
    endpoint: API_URLS.AUTOSELECTION.DESCRIPTION_BY_VIN({ vin }),
    method: 'GET',
});
export const getVehicleDescriptionByPlate = ({ plate, state, }) => createAPIAction({
    types: [
        ActionCreators.GET_DESCRIPTION_BY_PLATE.FETCH(),
        ActionCreators.GET_DESCRIPTION_BY_PLATE.SUCCESS({
            meta: () => ({ plate }),
        }),
        ActionCreators.GET_DESCRIPTION_BY_PLATE.FAILURE(),
    ],
    endpoint: API_URLS.AUTOSELECTION.DESCRIPTION_BY_PLATE({ plate, state }),
    method: 'GET',
});
