import React from 'react';
import styled from 'styled-components';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from 'src/components/mui';
import { CheckCircle, Error } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
const MatchTable = styled(Table) `
  min-width: 650px;
`;
const MatchSectionHeader = styled(TableRow) `
  background-color: ${colors.GRAY_DE};
`;
const MatchColumnHeader = styled(TableRow) `
  background-color: ${colors.GRAY_EE};
`;
const MatchMeta = styled.div `
  font-size: small;
  padding-left: 10px;
  color: ${colors.GRAY_9C};
`;
const MatchResults = ({ type, results }) => {
    const renderTable = (type, rows, matched) => {
        return (<>
        <TableHead>
          <MatchSectionHeader>
            <TableCell colSpan={2}>
              <span style={{ fontWeight: 'bolder' }}>
                {matched ? (<CheckCircle style={{ color: colors.green }}/>) : (<Error style={{ color: colors.darkRed }}/>)}
                {`${matched ? 'Matched' : 'Unmatched'} ${type} (${rows.length})`}
              </span>
            </TableCell>
          </MatchSectionHeader>
          <MatchColumnHeader>
            <TableCell>RepairSmith</TableCell>
            <TableCell>Skedulo</TableCell>
          </MatchColumnHeader>
        </TableHead>
        <TableBody>
          {rows.map((row) => (<TableRow key={row.repairSmithId}>
              <TableCell>
                <div>{row.repairSmithName}</div>
                <MatchMeta>
                  <span>ID: </span>
                  <span>{row.repairSmithId}</span>
                </MatchMeta>
              </TableCell>
              <TableCell>
                {!!row.skeduloId && (<>
                    <div>{row.skeduloName}</div>
                    <MatchMeta>
                      <span>ID: </span>
                      <span>{row.skeduloId}</span>
                    </MatchMeta>
                  </>)}
              </TableCell>
            </TableRow>))}
        </TableBody>
      </>);
    };
    return (<TableContainer component={Paper}>
      <MatchTable size="small" aria-label="a dense table">
        {renderTable(type, results.matched, true)}
        {renderTable(type, results.unmatched, false)}
      </MatchTable>
    </TableContainer>);
};
export default MatchResults;
