import { toEnum } from 'src/models/enums';
export const PaymentDescription = toEnum({
    PARTIAL_PAYMENT: 'Partial Payment',
    FULL_PAYMENT: 'Full Payment',
    PARTIAL_REFUND: 'Partial Refund',
    FULL_REFUND: 'Full Refund',
    CANCELLATION_FEE: 'Cancellation Fee',
    PRE_AUTHORIZATION: 'Pre-Authorization',
    DEPOSIT: 'Deposit',
});
export default PaymentDescription;
