import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
import { Button } from 'src/components/mui';
export const AppointmentsListContainer = styled.section `
  padding-bottom: 100px;
`;
export const AppointmentsTable = styled.table `
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 24px 0;
    border-bottom: 1px solid ${colors.lightGrey};
    vertical-align: top;

    &:nth-child(1n + 3) {
      padding-top: 59px;
    }

    &.servicesList {
      width: 30%;
    }

    &.duration {
      width: 8%;
      text-align: center;
    }

    &.subtotal {
      width: 8%;
      text-align: center;
    }

    &.taskActions {
      width: 15%;
      text-align: right;
    }
  }
`;
export const NoAppointments = styled.section `
  text-align: center;
  font-weight: normal;
  color: ${colors.darkGrey};
`;
export const TimeWindow = styled.div `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  display: inline-block;
`;
export const RefLabel = styled.span `
  color: ${colors.GRAY_76};
`;
export const CopyButton = styled(Button) `
  color: ${colors.primary};
  padding: 0;
  display: block;
  text-align: left;
  position: relative;

  svg {
    margin-left: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    position: relative;
    top: 2px;
  }
`;
const getSlugColor = ({ slugType }) => {
    switch (slugType) {
        case 'lunch':
            return colors.success;
        case 'appointment':
            return colors.primary;
        case 'pickup':
            return colors.ORANGE_FF;
        case 'visit':
            return colors.black;
        default:
            return colors.primary;
    }
};
export const Tag = styled.div `
  border-radius: 4px;
  font-size: 10px;
  font-weight: 800;
  color: ${colors.white};
  background-color: ${getSlugColor};
  display: inline-block;
  vertical-align: middle;
  padding: 2px 5px;
  text-transform: uppercase;
  margin-left: 8px;
  position: relative;
  top: -3px;

  ${theme.breakpoints.up('md')} {
    margin-left: 0;
    margin-bottom: 16px;
    top: 0;
  }
`;
