import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
export const ActionCreators = {
    GET_ARRIVAL_GRACE_PERIOD_CONFIG: createDefaultActionCreators('GET_ARRIVAL_GRACE_PERIOD_CONFIG'),
};
export const getArrivalGracePeriodConfig = () => createAPIAction({
    types: [
        ActionCreators.GET_ARRIVAL_GRACE_PERIOD_CONFIG.FETCH(),
        ActionCreators.GET_ARRIVAL_GRACE_PERIOD_CONFIG.SUCCESS(),
        ActionCreators.GET_ARRIVAL_GRACE_PERIOD_CONFIG.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.CUSTOM_CONFIG_BY_NAME(),
    method: 'GET',
    params: { name: 'arrival_grace_period_minutes' },
});
