var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { getTerritoriesByFilters, updateTerritory, getTerritoryById, archiveTerritory, } from 'src/AdminApp/modules/territories/actions';
import { archiveMetro, getMetroById, updateMetro, } from 'src/AdminApp/modules/metros/actions';
import { openSlidePanel } from 'src/AdminApp/modules/geoManagement/actions';
import { selectDashboardPagination as selectTerritoriesDashboardPagination, selectDashboardParams as selectTerritoriesDashboardParams, } from 'src/AdminApp/modules/territories/selectors';
const useTerritoriesListItem = (item) => {
    const { id } = item;
    const dispatch = useDispatch();
    const territoriesPagination = useSelector(selectTerritoriesDashboardPagination);
    const territoriesParams = useSelector(selectTerritoriesDashboardParams);
    const refreshTerritories = () => {
        var _a, _b;
        return dispatch(getTerritoriesByFilters(Object.assign(Object.assign({}, territoriesParams), { pagination: {
                size: (_a = territoriesPagination === null || territoriesPagination === void 0 ? void 0 : territoriesPagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                page: (_b = territoriesPagination === null || territoriesPagination === void 0 ? void 0 : territoriesPagination.pageNumber) !== null && _b !== void 0 ? _b : 0,
                sort: territoriesPagination === null || territoriesPagination === void 0 ? void 0 : territoriesPagination.sort,
            } })));
    };
    const getTerritory = (uid) => dispatch(getTerritoryById(uid));
    const getMetro = (uid) => dispatch(getMetroById(uid));
    const onUpdateTerritory = (model) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(updateTerritory(model));
        refreshTerritories();
    });
    const onRemoveTerritory = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(archiveTerritory(value));
        refreshTerritories();
    });
    const onUpdateMetro = (model) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(updateMetro(model));
        refreshTerritories();
    });
    const onRemoveMetro = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(archiveMetro(value));
        refreshTerritories();
    });
    const editTerritory = () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield getTerritory(id);
        const territory = result.payload;
        return dispatch(openSlidePanel({
            onSaveTerritory: onUpdateTerritory,
            onRemoveTerritory,
            territoryModel: territory,
            selectedPage: 'territory',
        }));
    });
    const editMetro = (metroId) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield getMetro(metroId);
        const metro = result.payload;
        return dispatch(openSlidePanel({
            onSaveMetro: onUpdateMetro,
            onRemoveMetro,
            metroModel: metro,
            selectedPage: 'metro',
        }));
    });
    return {
        refreshTerritories,
        getTerritory,
        getMetro,
        onUpdateMetro,
        onRemoveMetro,
        onUpdateTerritory,
        onRemoveTerritory,
        editTerritory,
        editMetro,
    };
};
export default useTerritoriesListItem;
