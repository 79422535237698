var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PaymentChooser from 'src/AdminApp/components/payments/PaymentChooser';
import { AffirmButton, SectionDetails, SectionHeader, ViewCCDetailsHeader, ViewCCDetailsWrapper, } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/AuthorizationScript.styles';
import { selectAddress } from 'src/AdminApp/modules/subscription/selectors';
import { Grid } from 'src/components/mui';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { toast } from 'src/components/SimpleToast';
import { STATIC_URL_BASE } from 'src/styles/theme';
const AuthorizationViewCC = ({ requestAuthorization, onSuccess, onFail, onGoToAffirmCheckout, onServiceError, }) => {
    const [triggerCCSubmit, setTriggerCCSubmit] = useState(false);
    const [validCCInput, setValidCCInput] = useState(false);
    const [creditCardChosen, setCreditCardChosen] = useState();
    const [methodChosen, setMethodChosen] = useState();
    const [errorMode, setErrorMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const billingAddress = useSelector(selectAddress);
    const handleMethodChosen = (method, creditCard) => {
        setMethodChosen(method);
        setErrorMode(false);
        if (method === 'EXISTING') {
            setCreditCardChosen(creditCard);
        }
        else {
            setValidCCInput(false);
            setCreditCardChosen(undefined);
        }
    };
    const handleFormValid = (valid) => {
        setValidCCInput(valid);
    };
    const handleFormError = (message) => {
        if (message) {
            toast.error(message);
        }
        setIsLoading(false);
        setTriggerCCSubmit(false);
    };
    const handleSubmit = () => {
        setIsLoading(true);
        if (methodChosen === 'NEW') {
            setTriggerCCSubmit(true);
        }
        else if (methodChosen === 'EXISTING' && creditCardChosen) {
            handleAuthorizeNowClick(creditCardChosen.id);
        }
    };
    const handleAuthorizeNowClick = (paymentMethodId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const response = yield requestAuthorization(true, paymentMethodId);
        setIsLoading(false);
        if (response.error) {
            onServiceError(paymentMethodId);
        }
        else if ((_b = (_a = response.payload) === null || _a === void 0 ? void 0 : _a.currentTask) === null || _b === void 0 ? void 0 : _b.paymentMethodAuthorized) {
            onSuccess();
        }
        else {
            onFail();
        }
    });
    const disableButton = (methodChosen === 'NEW' && !validCCInput && !creditCardChosen) ||
        isLoading ||
        triggerCCSubmit;
    return (<>
      <SimpleDrawerContent>
        <ViewCCDetailsWrapper>
          <ViewCCDetailsHeader>Credit Card Details</ViewCCDetailsHeader>

          <section>
            <SectionHeader>Billing Address</SectionHeader>

            <SectionDetails>
              <Grid container style={{
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
                <Grid item>
                  {billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.street}
                  <br />
                  {billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.city}, {billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.state}{' '}
                  {billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.zip}
                </Grid>
              </Grid>
            </SectionDetails>
          </section>

          <section>
            <SectionHeader>Credit Card Info</SectionHeader>

            <section>
              <PaymentChooser onMethodChosen={handleMethodChosen} triggerCCSubmit={triggerCCSubmit} onCCSubmit={(newPayment) => {
            setTriggerCCSubmit(false);
            handleAuthorizeNowClick(newPayment.id);
        }} onFormValid={handleFormValid} onFormError={handleFormError} errorMode={errorMode} path="Payment Authorization"/>
            </section>
          </section>

          <section>
            <AffirmButton color="primary" variant="text" onClick={onGoToAffirmCheckout}>
              <img alt="Affirm logo" src={`${STATIC_URL_BASE}payment/icon-payment-method-affirm.svg`}/>
              Affirm financing available
            </AffirmButton>
          </section>
        </ViewCCDetailsWrapper>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton disabled={disableButton} onClick={handleSubmit} isSubmitting={isLoading}>
          Authorize Now
        </FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default AuthorizationViewCC;
