var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { selectAppointmentResolutions } from 'src/SystemApp/modules/utilities/selectors';
import { useEffect, useState } from 'react';
import { createAppointmentResolution, getAppointmentResolutionOptions, getAppointmentResolutions, updateAppointmentResolution, } from 'src/SystemApp/modules/utilities/actions';
const useAppointmentResolutions = () => {
    const dispatch = useDispatch();
    const resolutions = useSelector(selectAppointmentResolutions);
    const [currentResolution, setCurrentResolution] = useState();
    const [resolutionModalOpen, setResolutionModalOpen] = useState(false);
    const [isNewResolution, setIsNewResolution] = useState(false);
    const onUpdateResolutionSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(updateAppointmentResolution(Object.assign({}, values)));
        setResolutionModalOpen(false);
    });
    const onCreateResolutionSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(createAppointmentResolution(Object.assign({}, values)));
        setResolutionModalOpen(false);
    });
    const onResolutionEdit = (values) => {
        setCurrentResolution(values);
        setIsNewResolution(false);
        setResolutionModalOpen(true);
    };
    const onResolutionCreate = () => {
        setCurrentResolution(undefined);
        setIsNewResolution(true);
        setResolutionModalOpen(true);
    };
    useEffect(() => {
        dispatch(getAppointmentResolutions());
        dispatch(getAppointmentResolutionOptions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        onResolutionCreate,
        resolutionModalOpen,
        isNewResolution,
        setResolutionModalOpen,
        onCreateResolutionSubmit,
        onUpdateResolutionSubmit,
        currentResolution,
        resolutions,
        onResolutionEdit,
    };
};
export default useAppointmentResolutions;
