import { Grid } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import styled from 'styled-components';
export const DrawerSizes = [
    'normal',
    'oneThird',
    'wide',
    'half',
    'halfPlus',
    'twoThirds',
    'threeFourths',
];
export const handleDrawerWidth = ({ size = 'normal', }) => {
    switch (size) {
        case 'oneThird':
            return '33vw';
        case 'wide':
            return '42vw';
        case 'half':
            return '50vw';
        case 'halfPlus':
            return '60vw';
        case 'twoThirds':
            return '66vw';
        case 'threeFourths':
            return '75vw';
        case 'normal':
            return '425px';
        default:
            return size;
    }
};
/*
OTHER STYLES USED IN DRAWERS
 */
export const DrawerFormRow = styled(Grid) `
  margin-bottom: 16px;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 8px;
  }
`;
export const DrawerFormBorderedSection = styled(Grid) `
  border-bottom: 1px solid lightgray;
  margin: 16px 0;
`;
