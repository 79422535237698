import styled from 'styled-components';
import { flexCenter } from 'src/styles/styled';
import { colors } from 'src/styles/theme';
import { Button, Typography } from 'src/components/mui';
export const RowContainer = styled('div') `
  ${flexCenter}

  justify-content: space-between;
`;
export const TimeContainer = styled('div') `
  ${flexCenter}

  align-items: flex-start;
  flex-direction: column;
  width: 75%;
`;
export const Subtitle = styled(Typography) `
  color: ${colors.success};
`;
export const Details = styled(Typography) `
  color: ${colors.middleGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
export const Btn = styled(Button) `
  padding: 12px 22px;

  & span[class*='label'] {
    font-size: 1rem;
  }
`;
