import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { ActionCreators, clearFleetsSearchResults, clearStationsSearchResults, } from './actions';
const DEFAULT_STATE = {
    byId: {},
    pricing: {},
    allIds: [],
    carsDashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['carMake,asc'],
        },
    },
    fleetRepairsDashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['created,desc'],
        },
    },
    fleetInvoicesDashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['created,desc'],
        },
    },
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['name,asc'],
            query: undefined,
        },
    },
    newFleet: null,
    searchResults: {
        fleets: undefined,
        stations: undefined,
    },
    fmcDashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: 10,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['name,asc'],
        },
    },
    allCars: [],
};
const searchFleetsSuccess = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize, query } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
                query,
            } }) });
};
const fetchFleetsDashboardSuccess = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const clearFleetsDashboardSuccess = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const getFleetSuccess = (state, action) => {
    const fleet = fetched(action.payload);
    const { fleetId } = action.meta;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [fleetId]: Object.assign({}, fleet) }) });
};
const getProductFleetSearchSuccess = (state, action) => {
    const fleet = fetched(action.payload);
    const { fleetId, productId } = action.meta;
    return Object.assign(Object.assign({}, state), { pricing: Object.assign(Object.assign({}, state.pricing), { [productId]: Object.assign(Object.assign({}, state.pricing[productId]), { [fleetId]: Object.assign({}, fleet) }) }) });
};
const clearProductFleetSearch = (state) => {
    return Object.assign(Object.assign({}, state), { pricing: {} });
};
const searchFleetCarsSuccess = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { carsDashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const fetchFleetCarsSuccess = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { carsDashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const searchFleetInvoicesSuccess = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { fleetInvoicesDashboard: Object.assign(Object.assign(Object.assign({}, state.fleetInvoicesDashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const searchFleetInvoicesFailure = (state, action) => {
    return Object.assign(Object.assign({}, state), { fleetInvoicesDashboard: Object.assign(Object.assign({}, state.fleetInvoicesDashboard), { _isFetching: false }) });
};
const updateFleetCarSuccess = (state, action) => {
    var _a, _b;
    const { fleetInvoicesDashboard } = state;
    const { car } = (_a = action === null || action === void 0 ? void 0 : action.meta) !== null && _a !== void 0 ? _a : {};
    return Object.assign(Object.assign({}, state), { fleetInvoicesDashboard: Object.assign(Object.assign({}, fleetInvoicesDashboard), { content: (_b = fleetInvoicesDashboard.content) === null || _b === void 0 ? void 0 : _b.map((i) => {
                var _a;
                return (car === null || car === void 0 ? void 0 : car.id) !== (i === null || i === void 0 ? void 0 : i.carId)
                    ? i
                    : Object.assign(Object.assign({}, i), { carNickname: (_a = car.nickname) !== null && _a !== void 0 ? _a : i.carNickname });
            }) }) });
};
const searchFleetRepairsSuccess = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize, query } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { fleetRepairsDashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
                query,
            } }) });
};
const fetchFleetRepairsSuccess = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { fleetRepairsDashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const fetchFleetInvoicesSuccess = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { fleetInvoicesDashboard: Object.assign(Object.assign({}, state.fleetInvoicesDashboard), { params, _isFetching: true }) });
};
const searchFleetsByNameSuccess = (state, action) => {
    return Object.assign(Object.assign({}, state), { searchResults: Object.assign(Object.assign({}, state.searchResults), { fleets: action.payload }) });
};
const clearStationsSearchResultsSuccess = (state) => {
    return Object.assign(Object.assign({}, state), { searchResults: Object.assign(Object.assign({}, state.searchResults), { stations: [] }) });
};
const clearFleetsSearchResultsSuccess = (state) => {
    return Object.assign(Object.assign({}, state), { searchResults: Object.assign(Object.assign({}, state.searchResults), { fleets: { content: [] } }) });
};
const searchStationsByNameSuccess = (state, action) => {
    return Object.assign(Object.assign({}, state), { searchResults: Object.assign(Object.assign({}, state.searchResults), { stations: action.payload }) });
};
const successGetResponsiblePartyByFleetId = (state, action) => {
    const { fleetId } = action.meta;
    const fleetResponsibleParty = fetched(action.payload);
    const fleet = state.byId[fleetId];
    if (fleet) {
        fleet.responsibleParty =
            fleetResponsibleParty;
    }
    const reqsById = state.byId;
    const byId = Object.assign(Object.assign({}, reqsById), { [fleetId]: Object.assign(Object.assign({}, reqsById[fleetId]), { fleet }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successUpdateResponsiblePartyByFleetId = (state, action) => {
    const { fleetId } = action.meta;
    const fleetResponsibleParty = fetched(action.payload);
    const fleet = state.byId[fleetId];
    fleet.responsibleParty = fleetResponsibleParty;
    const reqsById = state.byId;
    const byId = Object.assign(Object.assign({}, reqsById), { [fleetId]: Object.assign(Object.assign({}, reqsById[fleetId]), { fleet }) });
    return Object.assign(Object.assign({}, state), { byId });
};
const successPartDistributorWithLocations = (state, action) => {
    const partDistributionLocations = action.payload.content;
    return Object.assign(Object.assign({}, state), { partDistributionLocations });
};
const successGetAllFMCs = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize, query } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { fmcDashboard: Object.assign(Object.assign(Object.assign({}, state.fmcDashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
                query,
            } }) });
};
const searchAllFleetCarsSuccess = (state, action) => {
    return Object.assign(Object.assign({}, state), { allCars: action.payload });
};
const reducers = handleActions({
    [ActionCreators.SEARCH_FLEETS.SUCCESS.type]: searchFleetsSuccess,
    [ActionCreators.SEARCH_FLEETS.FETCH.type]: fetchFleetsDashboardSuccess,
    [ActionCreators.SEARCH_FLEETS.CLEAR.type]: clearFleetsDashboardSuccess,
    [ActionCreators.GET_FLEET.SUCCESS.type]: getFleetSuccess,
    [ActionCreators.GET_PRODUCT_FLEET_SEARCH.SUCCESS.type]: getProductFleetSearchSuccess,
    [ActionCreators.GET_PRODUCT_FLEET_SEARCH.CLEAR.type]: clearProductFleetSearch,
    [ActionCreators.UPDATE_FLEET.SUCCESS.type]: getFleetSuccess,
    [ActionCreators.GET_FLEET_CARS.SUCCESS.type]: searchFleetCarsSuccess,
    [ActionCreators.GET_FLEET_CARS.FETCH.type]: fetchFleetCarsSuccess,
    [ActionCreators.GET_FLEET_REPAIR_REQUESTS.SUCCESS.type]: searchFleetRepairsSuccess,
    [ActionCreators.GET_FLEET_INVOICES.SUCCESS.type]: searchFleetInvoicesSuccess,
    [ActionCreators.GET_FLEET_INVOICES.FAILURE.type]: searchFleetInvoicesFailure,
    [ActionCreators.GET_FLEET_INVOICES.FETCH.type]: fetchFleetInvoicesSuccess,
    [ActionCreators.SEARCH_FLEET_REPAIR_REQUESTS.SUCCESS.type]: searchFleetRepairsSuccess,
    [ActionCreators.GET_FLEET_REPAIR_REQUESTS.FETCH.type]: fetchFleetRepairsSuccess,
    [ActionCreators.SEARCH_CARS.SUCCESS.type]: searchFleetCarsSuccess,
    [ActionCreators.SEARCH_FLEETS_BY_NAME.SUCCESS.type]: searchFleetsByNameSuccess,
    [ActionCreators.SEARCH_FLEETS_BY_STATION.SUCCESS.type]: searchFleetsByNameSuccess,
    [ActionCreators.SEARCH_STATIONS_BY_NAME.SUCCESS.type]: searchStationsByNameSuccess,
    [clearStationsSearchResults.type]: clearStationsSearchResultsSuccess,
    [clearFleetsSearchResults.type]: clearFleetsSearchResultsSuccess,
    [ActionCreators.GET_RESPONSIBLE_PARTY_FOR_FLEET.SUCCESS.type]: successGetResponsiblePartyByFleetId,
    [ActionCreators.UPDATE_FLEET_RESPONSIBLE_PARTY.SUCCESS.type]: successUpdateResponsiblePartyByFleetId,
    [ActionCreators.GET_PART_DISTRIBUTOR_WITH_LOCATIONS.SUCCESS.type]: successPartDistributorWithLocations,
    [ActionCreators.GET_FMC.SUCCESS.type]: successGetAllFMCs,
    [ActionCreators.GET_ALL_FLEET_CARS.SUCCESS.type]: searchAllFleetCarsSuccess,
    [ActionCreators.UPDATE_FLEET_CAR.SUCCESS.type]: updateFleetCarSuccess,
}, DEFAULT_STATE);
export default reducers;
