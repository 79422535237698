import { handleActions } from 'redux-actions';
import { ActionCreators } from 'src/SystemApp/modules/hubs/actions';
import { fetched } from 'src/utils/data-utils';
const DEFAULT_STATE = {
    dashboard: {
        params: {
            filters: {},
        },
        content: undefined,
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['name,asc'],
        },
    },
};
const hubsSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { sort, pageIndex, pageSize } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const reducers = handleActions({
    [ActionCreators.GET_HUBS.SUCCESS.type]: hubsSuccessReducer,
}, DEFAULT_STATE);
export default reducers;
