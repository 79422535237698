var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadCurrentUser } from 'src/AdminApp/modules/users/actions';
import { toast } from 'src/components/SimpleToast';
import useDebounce from 'src/modules/api/utils/useDebounce';
import { deleteReturnItem, getInventoryReturnItems, updateReturnedItem, } from 'src/PopsApp/modules/inventory/actions';
import { selectDashboardPagination, selectDashboardReturnItems, } from 'src/PopsApp/modules/inventory/selectors';
import { getLocations } from 'src/SystemApp/modules/locations/actions';
import { selectDashboardLocations } from 'src/SystemApp/modules/locations/selectors';
import { DATE_TIME_FORMAT } from 'src/timeConstants';
const useReturnedInventory = () => {
    const dispatch = useDispatch();
    const returnItems = useSelector(selectDashboardReturnItems);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [openReturnDrawer, setOpenReturnDrawer] = useState(false);
    const [confirmationNumber, setConfirmationNumber] = useState('');
    const [openMoveDrawer, setOpenMoveDrawer] = useState(false);
    const [statusFilter, setStatusFilter] = useState('PENDING');
    const [searchPoNumberFilter, setSearchPoNumberFilter] = useState('');
    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState();
    const [search, setSearch] = useState('');
    const [selectedLocation, setSelectedLocation] = useState();
    const [createdFrom, setCreatedFrom] = useState(null);
    const [createdTo, setCreatedTo] = useState(null);
    const locationSearchResults = useSelector(selectDashboardLocations);
    const searchLocationsDebounce = useDebounce(search, 500);
    const pagination = useSelector(selectDashboardPagination);
    const defaultParams = {
        page: (pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) || 0,
        size: (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || 20,
        sort: (pagination === null || pagination === void 0 ? void 0 : pagination.sort) || ['created,asc'],
        status: 'PENDING',
    };
    useEffect(() => {
        dispatch(loadCurrentUser());
        onLoadData(defaultParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLoadData = ({ page, size, sort, status, poNumber, minPrice, maxPrice, locationId, locationType, createdFrom, createdTo, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        setIsLoading(true);
        yield dispatch(getInventoryReturnItems({
            pageSize: size,
            pageIndex: page,
            sort,
            status: status !== null && status !== void 0 ? status : statusFilter,
            poNumber,
            minPrice,
            maxPrice,
            locationId: (_a = selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.id) !== null && _a !== void 0 ? _a : locationId,
            locationType: (_b = selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.type) !== null && _b !== void 0 ? _b : locationType,
            createdFrom,
            createdTo,
        }));
        setIsLoading(false);
    });
    const onPaginationChange = (params) => {
        onLoadData(Object.assign(Object.assign(Object.assign({}, defaultParams), params), { poNumber: searchPoNumberFilter, status: statusFilter, minPrice,
            maxPrice, locationId: selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.id, locationType: selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.type, createdFrom: createdFrom === null || createdFrom === void 0 ? void 0 : createdFrom.format(DATE_TIME_FORMAT), createdTo: createdTo === null || createdTo === void 0 ? void 0 : createdTo.format(DATE_TIME_FORMAT) }));
    };
    const handleSearch = () => {
        let searchParams = Object.assign(Object.assign({}, defaultParams), { page: 0, poNumber: searchPoNumberFilter, status: statusFilter, minPrice,
            maxPrice, locationId: selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.id, locationType: selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.type, createdFrom: createdFrom === null || createdFrom === void 0 ? void 0 : createdFrom.format(DATE_TIME_FORMAT), createdTo: createdTo === null || createdTo === void 0 ? void 0 : createdTo.format(DATE_TIME_FORMAT) });
        if (searchPoNumberFilter.length) {
            searchParams = Object.assign(Object.assign({}, defaultParams), { page: 0, poNumber: searchPoNumberFilter, status: null });
            dispatch(getInventoryReturnItems(searchParams));
            return;
        }
        onLoadData(searchParams);
    };
    const searchLocations = (e) => {
        if (e) {
            setSearch(e.target.value);
        }
    };
    const handleLocationSelected = (e, newLocation, reason) => {
        setSelectedLocation(newLocation !== null && newLocation !== void 0 ? newLocation : undefined);
        if (reason === 'clear') {
            setSearch('');
        }
    };
    useEffect(() => {
        if (!open)
            return;
        if (searchLocationsDebounce && (searchLocationsDebounce === null || searchLocationsDebounce === void 0 ? void 0 : searchLocationsDebounce.length) > 1) {
            dispatch(getLocations({
                name: searchLocationsDebounce,
                locationTypes: ['HUB', 'DELIVERY_VAN', 'REGION'],
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchLocationsDebounce]);
    const onSearchChange = (e) => {
        setSearchPoNumberFilter(e.target.value);
    };
    const handleDeleteItem = (item) => {
        setOpenConfirmDelete(true);
        setSelectedItem(item);
    };
    const closeDeleteModal = () => {
        setOpenConfirmDelete(false);
        setSelectedItem(undefined);
    };
    const deleteItem = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.id) {
            const response = yield dispatch(deleteReturnItem(selectedItem.id));
            if (!response.error) {
                toast.success('Return item deleted successfully');
                handleSearch();
            }
        }
        closeDeleteModal();
    });
    const handleReturn = (item) => {
        var _a;
        setSelectedItem(item);
        setConfirmationNumber((_a = item.confirmationNumber) !== null && _a !== void 0 ? _a : '');
        setOpenReturnDrawer(true);
    };
    const handleReturnDrawerClose = () => {
        setSelectedItem(undefined);
        setConfirmationNumber('');
        setOpenReturnDrawer(false);
    };
    const resetSearchFilters = () => {
        setSearchPoNumberFilter('');
        setSearch('');
        setMinPrice('');
        setMaxPrice('');
        setSelectedLocation(null);
        setStatusFilter('PENDING');
        onLoadData(defaultParams);
    };
    const saveReturnedItem = (confirmationNumber) => __awaiter(void 0, void 0, void 0, function* () {
        if (!(selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.id))
            return;
        setIsSubmitting(true);
        const response = yield dispatch(updateReturnedItem({
            id: selectedItem.id,
            status: 'RETURNED',
            confirmationNumber: confirmationNumber !== null && confirmationNumber !== void 0 ? confirmationNumber : '',
        }));
        if (!response.error) {
            toast.success('Return item saved successfully');
            handleSearch();
        }
        setIsSubmitting(false);
        handleReturnDrawerClose();
    });
    const handleMove = (returnItem) => {
        setSelectedItem(returnItem);
        setOpenMoveDrawer(true);
    };
    const handleMoveDrawerClose = (refresh) => {
        setSelectedItem(undefined);
        setOpenMoveDrawer(false);
        if (refresh) {
            handleSearch();
        }
    };
    const setPrice = (price, isMin) => {
        if (isMin) {
            setMinPrice(price);
        }
        else {
            setMaxPrice(price);
        }
    };
    return {
        returnItems,
        isLoading,
        isSubmitting,
        pagination,
        onPaginationChange,
        handleDeleteItem,
        openConfirmDelete,
        closeDeleteModal,
        deleteItem,
        handleReturn,
        openReturnDrawer,
        handleReturnDrawerClose,
        saveReturnedItem,
        confirmationNumber,
        setConfirmationNumber,
        selectedItem,
        handleMove,
        openMoveDrawer,
        handleMoveDrawerClose,
        statusFilter,
        setStatusFilter,
        handleSearch,
        searchPoNumberFilter,
        onSearchChange,
        minPrice,
        maxPrice,
        setPrice,
        handleLocationSelected,
        locationSearchResults,
        searchLocations,
        resetSearchFilters,
        selectedLocation,
        setCreatedFrom,
        createdFrom,
        setCreatedTo,
        createdTo,
    };
};
export default useReturnedInventory;
