import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { List, ListItemText } from 'src/components/mui';
export const IconRow = styled.div `
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;
export const Icon = styled.img `
  width: 40px;
  height: 40px;
  margin: auto auto;
`;
export const IconLabel = styled.div `
  margin: auto auto;
  position: relative;
  top: -18px;
`;
export const IconTile = styled.div `
  width: 104px;
  height: 104px;
  padding-top: 18px;
  border-radius: 4px;
  border: solid 1px ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
export const RectangleIconTile = styled.div `
  width: 165px;
  height: 60px;
  padding: 18px auto;
  border-radius: 4px;
  border: solid 1px ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
export const TypeList = styled(List) `
  padding: 20px;
  min-width: 100%;
  width: 100%;
  max-width: 360px;
  background-color: ${colors.white};
`;
export const TypeLabel = styled(ListItemText) `
  margin-left: 15px;
`;
export const RightIcon = styled.img `
  position: absolute;
  right: 0px;
`;
