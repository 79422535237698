import { toEnum } from 'src/models/enums';
export const FleetTypes = toEnum({
    Amazon: 'Amazon',
    Commercial: 'Commercial',
    Rental: 'Rental',
    Dealer: 'Dealer',
});
export const FleetTaxTypes = toEnum({
    EXEMPT: 'Exempt',
    PRICE: 'Price',
    COST: 'Cost'
});
export default FleetTypes;
