var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSkill, deleteSkill, getSkillsByFilter, updateSkill, } from 'src/AdminApp/modules/skills/actions';
import { toast } from 'src/components/SimpleToast';
const useSkills = () => {
    const dispatch = useDispatch();
    const [skills, setSkills] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSkill, setCurrentSkill] = useState();
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [aboutToDeleteSkillId, setAboutToDeleteSkillId] = useState('');
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield dispatch(getSkillsByFilter({}));
            if (!(response === null || response === void 0 ? void 0 : response.error)) {
                setSkills(response.payload);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onEditSkill = (skill) => {
        setCurrentSkill(skill);
        setIsModalOpen(true);
    };
    const onDeleteSkill = (skillId) => {
        setIsDeleteConfirmOpen(true);
        setAboutToDeleteSkillId(skillId);
    };
    const removeSkill = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(deleteSkill(aboutToDeleteSkillId));
        if (response.error) {
            return;
        }
        const updatedSkills = skills.filter((value) => value.id !== aboutToDeleteSkillId);
        setSkills(updatedSkills);
        toast.success('Skill successfully removed.');
    });
    useEffect(() => {
        if (!isModalOpen) {
            setCurrentSkill(undefined);
        }
    }, [isModalOpen]);
    const handleAddSkill = (skill) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(addSkill(skill));
        if (response.error) {
            return;
        }
        setSkills((prevState) => {
            prevState.push(response.payload);
            return prevState;
        });
        setIsModalOpen(false);
        toast.success('Skill successfully added.');
    });
    const handleUpdateSkill = (skill) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(updateSkill(skill));
        if (response.error) {
            return;
        }
        const updatedSkill = response.payload;
        const updatedSkills = skills.map((value) => {
            if (value.id === updatedSkill.id) {
                return updatedSkill;
            }
            return value;
        });
        setSkills(updatedSkills);
        setIsModalOpen(false);
        toast.success('Skill successfully updated.');
    });
    return {
        skills,
        currentSkill,
        isModalOpen,
        isDeleteConfirmOpen,
        setIsDeleteConfirmOpen,
        setIsModalOpen,
        onEditSkill,
        removeSkill,
        handleAddSkill,
        handleUpdateSkill,
        onDeleteSkill,
    };
};
export default useSkills;
