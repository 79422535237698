import React from 'react';
import { TextField, Autocomplete } from 'src/components/mui';
import { SimpleModal } from 'src/components/layout';
import useFleetsAutocomplete from './useFleetsAutocomplete';
const FleetsAutocomplete = ({ repairId, editable, handleAddMapping, handleRemoveMapping, }) => {
    const { selected, isConflictModalOpen, setIsConflictModalOpen, fleetSearchResults, handleSearchFleets, handleFleetSelected, } = useFleetsAutocomplete({
        repairId,
        editable,
        handleAddMapping,
        handleRemoveMapping,
    });
    return (<>
      <Autocomplete multiple id="fleets-select" options={fleetSearchResults || []} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} filterSelectedOptions clearOnBlur noOptionsText="No Fleets Found" onChange={handleFleetSelected} onInputChange={handleSearchFleets} value={selected} disabled={!editable} ChipProps={{
            classes: {
                root: 'chip',
                deleteIcon: 'chip-delete-icon',
            },
        }} renderInput={(params) => (<TextField {...params} placeholder="Search for a fleet" variant="standard"/>)}/>
      <SimpleModal open={isConflictModalOpen} onClose={() => setIsConflictModalOpen(false)} title="Parent/Child Overlap">
        You can not add this fleet because its parent or child has already been
        selected
      </SimpleModal>
    </>);
};
export default FleetsAutocomplete;
