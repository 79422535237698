import Config from 'src/config';
export const isProduction = () => {
    return location.origin.endsWith('autonationmobileservice.com');
};
/**
 * Returns the APP_ENV value for non-local envs (e.g. prod, staging, dev, test, test1, etc) OR
 * the hostname for the runtime environment for local envs (e.g. LT-MyMachine)
 */
export const getAppEnv = () => {
    return Config.APP_ENV === 'local' ? Config.REACT_APP_HOSTNAME : Config.APP_ENV;
};
/**
 * Returns true if env is the same as the current application environment
 * @param env
 */
export const isAppEnv = (env) => {
    var _a;
    return ((_a = getAppEnv()) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) === (env === null || env === void 0 ? void 0 : env.toLocaleLowerCase());
};
