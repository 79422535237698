var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getJobsByRegions, updateJobVan, setUpdatingJobId, setSelectedJobs, setJobCancellationStatus, setOnDragEnd, setSelectedRegions, setLockDrag, } from 'src/AdminApp/modules/swimlanes/actions';
import { selectJobsByRegion, selectSelectedDate, selectWorkingVansOnly, selectSelectedRegions, selectUpdatingJobId, selectJobCancellationStatus, selectSelectedJobs, selectOnDragEnd, } from 'src/AdminApp/modules/swimlanes/selectors';
import { getDeliveryVanById, getDeliveryVanByIspId, } from 'src/AdminApp/modules/deliveryVans/actions';
import { getServiceAreaById } from 'src/AdminApp/modules/serviceAreas/actions';
import { toast } from 'src/components/SimpleToast';
import moment from 'moment';
import { DATE_TIME_FORMAT_WITH_SECONDS, DATE_YMD_LONG_TIME_FORMAT, TIME_12H_FORMAT, TIME_FORMAT, } from 'src/timeConstants';
import { findNewVan, getDayAvailabilityTemplate, getHourFromPosition, getPosition, calculateArrivalWindow, isJobInsideTemplate, convertToTimezone, } from '../Chart/utils';
import useVisitReschedule from './useVisitReschedule';
const useChart = (context) => {
    const dispatch = useDispatch();
    // state
    const [loading, setLoading] = useState(false);
    const [dragLoading, setDragLoading] = useState(false);
    const location = useLocation();
    // selectors
    const jobsByRegions = useSelector(selectJobsByRegion);
    const selectedDate = useSelector(selectSelectedDate);
    const workingVansOnly = useSelector(selectWorkingVansOnly);
    const selectedRegions = useSelector(selectSelectedRegions);
    const updatingJobId = useSelector(selectUpdatingJobId);
    const jobCancellationStatus = useSelector(selectJobCancellationStatus);
    const selectedJobs = useSelector(selectSelectedJobs);
    const onDragEnd = useSelector(selectOnDragEnd);
    const { handleUpdateVisit } = useVisitReschedule();
    const getJobs = useCallback((loadScreen = true) => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedDate)
            return;
        if (loadScreen) {
            setLoading(true);
        }
        const response = (selectedRegions === null || selectedRegions === void 0 ? void 0 : selectedRegions.length) > 0
            ? yield dispatch(getJobsByRegions(selectedDate, workingVansOnly, selectedRegions))
            : [];
        if (loadScreen) {
            setLoading(false);
        }
        context === null || context === void 0 ? void 0 : context.trackLastActivity();
        return response;
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate, workingVansOnly, selectedRegions, context === null || context === void 0 ? void 0 : context.trackLastActivity]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (selectedDate) {
                yield getJobs();
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, workingVansOnly, selectedRegions]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (onDragEnd) {
                const { data, jobId, van, region } = onDragEnd;
                yield handleDragEnd(data, jobId, van, region);
                dispatch(setOnDragEnd(undefined));
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onDragEnd]);
    useEffect(() => {
        if (jobCancellationStatus && jobCancellationStatus.status === 'CLOSED') {
            setDragLoading(false);
            handleResetSelectors(true, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobCancellationStatus]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            const params = new URLSearchParams(location.search);
            const vanValue = params.get('van');
            const byDeliveryVanId = vanValue === null || vanValue === void 0 ? void 0 : vanValue.includes('-');
            let deliveryVan;
            if (vanValue) {
                if (byDeliveryVanId) {
                    deliveryVan = yield dispatch(getDeliveryVanById(vanValue));
                }
                else {
                    deliveryVan = yield dispatch(getDeliveryVanByIspId(parseInt(vanValue, 10)));
                }
                if (!deliveryVan || deliveryVan.error) {
                    setLoading(false);
                    return;
                }
                const filteredAreas = deliveryVan.payload.deliveryVanServiceAreas.filter((area) => !(area.effectiveTo && new Date(area.effectiveTo) < new Date()));
                const promises = filteredAreas.map((area) => {
                    return dispatch(getServiceAreaById(area.serviceArea.id));
                });
                const response = yield Promise.all(promises);
                const regions = response
                    .filter((r) => r.payload.region.skeduloRegionId)
                    .map((data) => data.payload.region.skeduloRegionId);
                if (regions && regions.length > 0) {
                    dispatch(setSelectedRegions([...regions]));
                }
                else {
                    setLoading(false);
                }
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);
    const handleVanNotAvailable = (name) => {
        toast.error(`${name} is not available`);
        dispatch(setUpdatingJobId(undefined));
        dispatch(setSelectedJobs(undefined));
        setDragLoading(false);
        dispatch(setLockDrag(false));
    };
    const handleResetSelectors = (reloadJobs = false, resetSelectedJobs = false) => __awaiter(void 0, void 0, void 0, function* () {
        if (reloadJobs) {
            yield getJobs(false);
        }
        dispatch(setJobCancellationStatus(undefined));
        dispatch(setUpdatingJobId(undefined));
        dispatch(setLockDrag(false));
        if (resetSelectedJobs) {
            dispatch(setSelectedJobs(undefined));
        }
    });
    const handleUpdateJob = (body) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateVan(Object.assign({}, body));
        setTimeout(() => {
            handleResetSelectors(true, true);
        }, 4000);
    });
    const handleReassignRepairOrder = (body, jobId, newHour, currentTime, isBetweenArrivalWindow, updateScheduledTime, timezone) => __awaiter(void 0, void 0, void 0, function* () {
        const newBody = Object.assign(Object.assign({}, body), { appointmentTime: updateScheduledTime
                ? moment(`${selectedDate} ${newHour}`, DATE_YMD_LONG_TIME_FORMAT).format(DATE_TIME_FORMAT_WITH_SECONDS)
                : convertToTimezone(currentTime, timezone, DATE_TIME_FORMAT_WITH_SECONDS) });
        if (isBetweenArrivalWindow) {
            yield handleUpdateJob(newBody);
        }
        else {
            dispatch(setJobCancellationStatus({
                id: jobId,
                status: 'IN_PROGRESS',
                reassignVanFunction: (rescheduleReason) => __awaiter(void 0, void 0, void 0, function* () { return handleUpdateJob(Object.assign(Object.assign({}, newBody), { rescheduleReason })); }),
            }));
        }
    });
    const handleReassignVisit = (job, newHour, updateVan) => __awaiter(void 0, void 0, void 0, function* () {
        if (!updateVan) {
            yield handleUpdateVisit(job, moment(`${selectedDate} ${newHour}`, DATE_YMD_LONG_TIME_FORMAT).format(DATE_TIME_FORMAT_WITH_SECONDS), handleResetSelectors);
        }
        else {
            dispatch(setJobCancellationStatus({
                id: job.id,
                status: 'IN_PROGRESS',
                newStartTime: moment(`${selectedDate} ${newHour}`, DATE_YMD_LONG_TIME_FORMAT).format(DATE_TIME_FORMAT_WITH_SECONDS),
                reassignVanFunction: () => __awaiter(void 0, void 0, void 0, function* () {
                    return setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                        yield handleResetSelectors(true, true);
                    }), 1000);
                }),
            }));
        }
    });
    const getJobFromId = (id) => {
        const vans = jobsByRegions.reduce((acc, el) => {
            acc.push(...el.vanResources);
            return acc;
        }, []);
        const timelines = vans.reduce((acc, el) => {
            acc.push(...el.timelines);
            return acc;
        }, []);
        return timelines === null || timelines === void 0 ? void 0 : timelines.find((t) => t.id === id);
    };
    const handleDragEnd = (data, jobId, currentVan, region) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const boundingClientRect = data.currentNode.getBoundingClientRect();
        const clientY = boundingClientRect.top;
        const job = getJobFromId(jobId);
        if (!selectedJobs || (selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.length) === 0) {
            dispatch(setSelectedJobs([job]));
        }
        dispatch(setLockDrag(true));
        const chartContainer = document.querySelector('.chart-row-scrollable');
        if (chartContainer) {
            const jobsElements = chartContainer.querySelectorAll('.jobs');
            const jobsElementsArray = Array.from(jobsElements);
            const newVan = findNewVan(clientY, jobsElementsArray);
            const van = jobsByRegions.reduce((acc, el) => {
                const vanObject = el.vanResources.find((item) => item.id === (newVan !== currentVan.id ? newVan : currentVan.id));
                if (vanObject) {
                    acc = Object.assign({}, vanObject);
                }
                return acc;
            }, {});
            const isVisit = job.repairJob.type === 'VISIT';
            const vanAvailability = getDayAvailabilityTemplate((_a = van === null || van === void 0 ? void 0 : van.availabilityTemplates) !== null && _a !== void 0 ? _a : [], selectedDate);
            const vanUnavailability = van === null || van === void 0 ? void 0 : van.unavailabilities.map((template) => {
                return {
                    startTime: convertToTimezone(template.start, region.timezone, TIME_FORMAT),
                    endTime: convertToTimezone(template.finish, region.timezone, TIME_FORMAT),
                };
            });
            const isVanUpdated = newVan !== currentVan.id;
            const currentPosition = getPosition(job.repairJob.startDate, selectedDate, region.timezone);
            const currentHour = moment(getHourFromPosition(currentPosition), TIME_12H_FORMAT);
            const newHour = getHourFromPosition(data.offsetX);
            const momentNewHour = moment(getHourFromPosition(data.offsetX), TIME_12H_FORMAT);
            const isStartTimeUpdated = !currentHour.isSame(momentNewHour) && data.offsetX !== 0;
            if (!isVanUpdated && !isStartTimeUpdated) {
                setDragLoading(false);
                handleResetSelectors();
                return;
            }
            if (!vanAvailability ||
                !isJobInsideTemplate(vanAvailability !== null && vanAvailability !== void 0 ? vanAvailability : [], newHour, job.duration) ||
                isJobInsideTemplate(vanUnavailability !== null && vanUnavailability !== void 0 ? vanUnavailability : [], newHour, job.duration, true)) {
                handleVanNotAvailable(van.name);
                return;
            }
            const isBetweenArrivalWindow = calculateArrivalWindow(job.repairJob.timeConstraint, newHour, region.timezone);
            const body = {
                jobId: job.repairJob.id,
                timezone: region.timezone,
                newVan: isVanUpdated ? newVan : currentVan.id,
            };
            if (isVisit) {
                yield handleReassignVisit(job, newHour, isVanUpdated);
            }
            else {
                yield handleReassignRepairOrder(body, job.id, newHour, job.startTime, isBetweenArrivalWindow, isStartTimeUpdated, region.timezone);
            }
        }
    });
    const updateVan = ({ jobId, appointmentTime, timezone, newVan, rescheduleReason, }) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(updateJobVan(jobId, appointmentTime, timezone, newVan, rescheduleReason));
        return response;
    });
    return {
        jobsByRegions,
        loading,
        selectedDate,
        updateVan,
        getJobs,
        handleDragEnd,
        dragLoading,
        setDragLoading,
        updatingJobId,
        jobCancellationStatus,
        selectedJobs,
    };
};
export default useChart;
