import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import { colors, theme } from 'src/styles/theme';
import { ActionCreators } from 'src/AdminApp/modules/quickRo/actions';
import { getSquareMakeLogo } from 'src/AdminApp/containers/vehicle/utils';
import EditableInput from 'src/components/form/EditableInput';
import VinPlateVehicleInfo from './VinPlateVehicleInfo';
const Logo = styled.img `
  max-width: 18px;
  max-height: 18px;
  margin-right: 8px;
`;
const Row = styled.div `
  display: flex;
  font-size: 14px;
  align-items: center;
  ${theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;
const VehicleInfo = styled.div `
  margin-right: 8px;
  display: flex;
  flex-direction: row;
`;
const Label = styled.span `
  margin-left: 8px;
  margin-right: 8px;
`;
const VehicleRowWrapper = styled.div `
  padding: 16px 0;
  display: flex;
  align-items: center;
  width: 100%;
  ${({ borders }) => borders
    ? `
      ${theme.breakpoints.down('sm')} {
        border-top: 1px solid ${colors.lightGrey};
      }
    `
    : null}
`;
const SelectedVehicleRow = ({ vehicle }) => {
    var _a;
    const dispatch = useDispatch();
    const setMileage = (payload) => dispatch(ActionCreators.TAB.SET_VEHICLE_MILEAGE({
        payload: Number(payload),
    }));
    return (<>
      <Row>
        <VehicleRowWrapper>
          <Logo src={getSquareMakeLogo(vehicle === null || vehicle === void 0 ? void 0 : vehicle.make, '@3x')} alt=""/>
          <VehicleInfo>
            {vehicle === null || vehicle === void 0 ? void 0 : vehicle.year} {vehicle === null || vehicle === void 0 ? void 0 : vehicle.make} {vehicle === null || vehicle === void 0 ? void 0 : vehicle.model}
          </VehicleInfo>
        </VehicleRowWrapper>
        {(vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin) || (vehicle === null || vehicle === void 0 ? void 0 : vehicle.plateNumber) ? (<VehicleRowWrapper borders>
            <VinPlateVehicleInfo vehicle={vehicle}/>
          </VehicleRowWrapper>) : null}
      </Row>
      <Row>
        <VehicleRowWrapper>
          <VehicleInfo>
            <Label>Mileage: </Label>
            <EditableInput value={String((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.customMileage) !== null && _a !== void 0 ? _a : vehicle === null || vehicle === void 0 ? void 0 : vehicle.mileage)} displayValue={isNil(vehicle === null || vehicle === void 0 ? void 0 : vehicle.customMileage) && isNil(vehicle === null || vehicle === void 0 ? void 0 : vehicle.mileage)
            ? 'No Mileage Set'
            : vehicle.customMileage} onUpdate={setMileage} helperText="Vehicle Mileage" isInline/>
          </VehicleInfo>
        </VehicleRowWrapper>
      </Row>
    </>);
};
export default SelectedVehicleRow;
