var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import moment from 'moment';
import React, { useContext } from 'react';
import { borderStyle, bottomMargin, BtnRow, btnStyle, editStyle, RateItem, RateLine, RateWrapper, Text, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/LaborRates/LaborRates.styles';
import { BtnText } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import { effectiveDate, euRatesFields, ratesFieldNames, regularRatesFields, SliderContext, } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/utils';
import { Button, Grid, Typography } from 'src/components/mui';
import { AddCircle } from 'src/components/mui/icons';
import SimpleChip from 'src/components/SimpleChip';
import { ErrorButton } from 'src/styles/styled';
import { colors } from 'src/styles/theme';
import { formatCurrency } from 'src/utils/formatter';
import useLaborRates from './useLaborRates';
const LaborRates = ({ hasModel = false, metro = false, modelLaborRates = [], }) => {
    const { onDeleteRegionLaborRates, onDeleteMetroLaborRates, setSlide } = useContext(SliderContext);
    const { laborRateEditor, tempLaborRates, archivedLaborRates, hasPendingRegionLaborRates, hasPendingMetroLaborRates, handleClickNewRule, handleClickEditRule, } = useLaborRates({
        metro,
        onDeleteRegionLaborRates,
        onDeleteMetroLaborRates,
        setSlide,
    });
    const laborRates = !archivedLaborRates
        ? [...modelLaborRates, ...tempLaborRates]
        : [archivedLaborRates];
    const hasPending = !metro
        ? hasPendingRegionLaborRates
        : hasPendingMetroLaborRates;
    const showAddNew = hasModel && !hasPending && !archivedLaborRates && laborRateEditor;
    return (<>
      {laborRates.map((_a) => {
            var { effectiveFrom, effectiveTo, euActive, id } = _a, rates = __rest(_a, ["effectiveFrom", "effectiveTo", "euActive", "id"]);
            const fromIsAfter = moment
                .utc(effectiveFrom)
                .isAfter(Date.now(), 'day');
            const toIsAfter = moment.utc(effectiveTo).isAfter(Date.now(), 'day');
            const tempId = id === null || id === void 0 ? void 0 : id.startsWith('tempLaborRate_');
            const canDelete = tempId || fromIsAfter;
            const canEdit = tempId || !effectiveTo || toIsAfter || fromIsAfter;
            const rateStyle = canEdit && !euActive ? editStyle : {};
            const euRateStyle = canEdit ? editStyle : {};
            const wrapperStyle = !archivedLaborRates ? {} : borderStyle;
            const showEuRates = euActive || !!(rates === null || rates === void 0 ? void 0 : rates.euGeneralRepair);
            return (<RateWrapper item key={id} style={wrapperStyle} xs={12}>
              <Text style={bottomMargin} variant="subtitle1">
                {`Effective: ${effectiveDate(effectiveFrom)} - ${effectiveDate(effectiveTo)}`}
              </Text>
              <RateItem style={rateStyle}>
                {regularRatesFields.map((field, index) => (<RateLine key={`regularRate_${field}`}>
                    <Typography variant="subtitle2">
                      {ratesFieldNames[index]}
                    </Typography>
                    <Text variant="subtitle2">
                      {formatCurrency(rates ? rates[field] : '')}
                    </Text>
                  </RateLine>))}
              </RateItem>
              {showEuRates && (<RateItem style={euRateStyle}>
                  <div style={bottomMargin}>
                    <SimpleChip $bgColor={colors.success} $textColor={colors.white} label="EUROPEAN MAKES"/>
                  </div>
                  {euRatesFields.map((field, index) => (<RateLine key={`euRate_${field}`}>
                      <Typography variant="subtitle2">
                        {ratesFieldNames[index]}
                      </Typography>
                      <Text variant="subtitle2">
                        {formatCurrency(rates ? rates[field] : '')}
                      </Text>
                    </RateLine>))}
                </RateItem>)}
              <BtnRow>
                {!archivedLaborRates && laborRateEditor && (<>
                    {canEdit && (<Button color="primary" disabled={!laborRateEditor} onClick={!laborRateEditor ? () => { } : handleClickEditRule(id)}>
                        <BtnText>Edit Labor Rates</BtnText>
                      </Button>)}
                    {canDelete && (<ErrorButton disabled={!laborRateEditor} onClick={!laborRateEditor
                            ? () => { }
                            : handleClickEditRule(id, true, tempId)}>
                        <BtnText>Delete Labor Rates</BtnText>
                      </ErrorButton>)}
                  </>)}
              </BtnRow>
            </RateWrapper>);
        })}
      {showAddNew && (<Grid item xs={12}>
          <Button color="primary" startIcon={<AddCircle style={btnStyle}/>} onClick={handleClickNewRule}>
            <BtnText>New Labor Rate Rule</BtnText>
          </Button>
        </Grid>)}
    </>);
};
export default LaborRates;
