import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
import { Grid } from 'src/components/mui';
export const PartPickup = styled(Grid) `
  padding: 20px 24px;
  border: solid 1px ${colors.lightGrey};
  border-left: 6px solid ${colors.ORANGE_FF};
  margin-top: 15px;
  border-radius: 4px;
`;
export const LunchTaskStyled = styled(Grid) `
  padding: 20px 24px;
  border-radius: 4px;
  border: solid 1px ${colors.lightGrey};
  border-left: 6px solid ${colors.success};
  margin-top: 15px;
`;
export const LunchSection = styled(Grid) `
  border-bottom: solid 1px ${colors.lightGrey};
  padding: 0 0 16px 0;

  &:not(:first-child) {
    padding-top: 16px;
  }

  .visitAddress {
    margin-bottom: 0;
  }
`;
export const LunchTaskAction = styled(Grid) `
  padding-top: 16px;

  ${theme.breakpoints.up('md')} {
    padding-top: 0;
  }

  button {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;

    ${theme.breakpoints.up('md')} {
      width: 100%;
    }
  }
`;
