import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVisitsByFleet } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { selectVisitsByFleet } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
const useFleetVisits = () => {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const { fleetId } = useParams();
    const [tableSort, setTableSort] = useState(['startLocal', 'desc']);
    const visitsSelector = useSelector(selectVisitsByFleet);
    const visits = ((_a = visitsSelector === null || visitsSelector === void 0 ? void 0 : visitsSelector.content) === null || _a === void 0 ? void 0 : _a.length)
        ? visitsSelector.content
        : undefined;
    const pagination = {
        pageNumber: (_b = visitsSelector === null || visitsSelector === void 0 ? void 0 : visitsSelector.number) !== null && _b !== void 0 ? _b : 0,
        pageSize: (_c = visitsSelector === null || visitsSelector === void 0 ? void 0 : visitsSelector.size) !== null && _c !== void 0 ? _c : 20,
        totalElements: (_d = visitsSelector === null || visitsSelector === void 0 ? void 0 : visitsSelector.totalElements) !== null && _d !== void 0 ? _d : 0,
    };
    useEffect(() => {
        dispatch(getVisitsByFleet({
            page: pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber,
            size: pagination === null || pagination === void 0 ? void 0 : pagination.pageSize,
            sort: tableSort,
            fleetIds: [fleetId],
            showCanceled: true,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleetId]);
    const onLoadData = (_a) => {
        var _b, _c;
        var { page = (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _b !== void 0 ? _b : 0, size = (_c = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _c !== void 0 ? _c : 20, sort = ['startLocal', 'desc'], } = _a;
        setTableSort(sort);
        dispatch(getVisitsByFleet({
            page,
            size,
            sort: sort,
            fleetIds: [fleetId],
            showCanceled: true,
        }));
    };
    return { tableSort, setTableSort, visits, pagination, onLoadData };
};
export default useFleetVisits;
