import styled from 'styled-components';
import { TablePagination } from 'src/components/mui';
import { colors, FONT_FAMILY } from 'src/styles/theme';
export const Paginator = styled(TablePagination) `
  .tablePagination {
    font-family: ${FONT_FAMILY};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
    color: ${colors.GRAY_3F};

    & .MuiToolbar-gutters {
      padding-left: 5px;
    }
  }

  .tablePaginationCaption {
    font-family: ${FONT_FAMILY};
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.1px;
    text-align: center;
    color: ${colors.GRAY_76};
  }

  .paginationMenuItem {
    background: yellow;
  }

  .tablePaginationSelect {
    font-family: ${FONT_FAMILY};
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
    text-align: center;
    color: ${colors.GRAY_3F};
  }

  .tablePaginationCaptionActions svg {
    color: white;
    background: ${colors.GRAY_37};
    border-radius: 50%;
  }

  .tablePaginationSelectIcon {
    top: 3px;
  }
`;
