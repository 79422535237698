var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Radio } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import { TextField } from 'src/AdminApp/components/form';
import { AlternativesRadioLabel, RadioControlLabel, RadioGroupForm, SectionDetails, SectionHeader, ViewCCDetailsHeader, ViewCCDetailsWrapper, } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/AuthorizationScript.styles';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
import { RadioGroupFormControl } from '../AuthorizationScript.styles';
const AuthorizationAlternative = ({ requestAuthorization, completeTask, }) => {
    const validationSchema = Yup.object().shape({
        inputReason: Yup.string().required('Reason is required.'),
        alternativePayment: Yup.string().required(),
    }, [['alternativePayment', 'inputReason']]);
    return (<Formik validationSchema={validationSchema} initialValues={{
            alternativePayment: 'Other',
            inputReason: '',
        }} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            yield requestAuthorization(false, null, values.alternativePayment, values.inputReason);
            setSubmitting(false);
            completeTask();
        })}>
      {({ submitForm, isSubmitting, values, isValid, setFieldValue }) => (<>
          <SimpleDrawerContent>
            <ViewCCDetailsWrapper>
              <ViewCCDetailsHeader>Alternative Payment</ViewCCDetailsHeader>

              <section>
                <SectionHeader>Select Alternative Payment Option</SectionHeader>
                <SectionDetails>
                  <RadioGroupFormControl>
                    <RadioGroupForm aria-label="alternativePayment" name="alternativePayment" value={values.alternativePayment} onChange={(event) => setFieldValue('alternativePayment', event.target.value)}>
                      <RadioControlLabel value="Other" control={<Radio color="primary"/>} label={<AlternativesRadioLabel>Other</AlternativesRadioLabel>} labelPlacement="start"/>

                      <div>
                        <TextField name="inputReason" type="text" variant="outlined" fullWidth label="Explain Here" value={values.inputReason} required={false}/>
                      </div>
                    </RadioGroupForm>
                  </RadioGroupFormControl>
                </SectionDetails>
              </section>
            </ViewCCDetailsWrapper>
          </SimpleDrawerContent>
          <SimpleDrawerFooter>
            <FooterActionButton disabled={isSubmitting || !isValid} onClick={submitForm} isSubmitting={isSubmitting}>
              Continue
            </FooterActionButton>
          </SimpleDrawerFooter>
        </>)}
    </Formik>);
};
export default AuthorizationAlternative;
