import { Formik } from 'formik';
import React from 'react';
import { LoaderWrapper } from 'src/AdminApp/components/deliveryVan/DeliveryVanTemplatesDrawer/DeliveryVanTemplate.styles';
import { TextField } from 'src/AdminApp/components/form';
import { CircularProgress, Grid, TimePicker } from 'src/components/mui';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { DATE_YMD_FORMAT, LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT, TIME_12H_FORMAT_WITH_SECONDS, } from 'src/timeConstants';
import { getMomentValueOr8am, getTimeString } from 'src/utils/formatter';
import useEditUnavailabilityTemplateDrawerPage from './useEditUnavailabilityTemplateDrawerPage';
const EditUnavailabilityTemplateDrawerPage = ({ vanModel, swimlanes, closeHandler, }) => {
    const { getInitialValues, validationSchema, handleUpdateVanUnavailability, loading, } = useEditUnavailabilityTemplateDrawerPage({
        vanModel,
        swimlanes,
        closeHandler,
    });
    return (<Formik initialValues={getInitialValues()} validationSchema={validationSchema} onSubmit={handleUpdateVanUnavailability}>
      {({ isSubmitting, isValid, isValidating, setFieldValue, handleSubmit, values, }) => (<>
          <SimpleDrawerContent>
            {loading ? (<LoaderWrapper>
                <CircularProgress size={40}/>
              </LoaderWrapper>) : (<Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField variant="outlined" value={vanModel === null || vanModel === void 0 ? void 0 : vanModel.name} label="Van" type="text" name="vanName" fullWidth disabled/>
                </Grid>
                <Grid item xs={4}>
                  <SimpleDatePicker fullWidth name="start" label="Select date" format={LOCALIZED_DATE_FORMAT} value={values.start} onChange={(value) => {
                    setFieldValue('start', value ? value.local().format(DATE_YMD_FORMAT) : null);
                }} slotProps={{
                    textField: {
                        variant: 'outlined',
                    },
                    field: {
                        clearable: true,
                    },
                }}/>
                </Grid>

                <Grid item xs={4}>
                  <TimePicker value={getMomentValueOr8am(values.startTime, TIME_12H_FORMAT_WITH_SECONDS)} onChange={(val) => {
                    setFieldValue('startTime', getTimeString(val, TIME_12H_FORMAT_WITH_SECONDS));
                }} minutesStep={1} format={TIME_12H_FORMAT} label="Start time" slotProps={{
                    textField: {
                        margin: 'none',
                        variant: 'outlined',
                    },
                }}/>
                </Grid>
                <Grid item xs={4}>
                  <TimePicker value={getMomentValueOr8am(values.finishTime, TIME_12H_FORMAT_WITH_SECONDS)} onChange={(val) => {
                    setFieldValue('finishTime', getTimeString(val, TIME_12H_FORMAT_WITH_SECONDS));
                }} minutesStep={1} format={TIME_12H_FORMAT} label="End time" slotProps={{
                    textField: {
                        margin: 'none',
                        variant: 'outlined',
                    },
                }}/>
                </Grid>

                <Grid item xs={12}>
                  <TextField variant="outlined" value={values.notes} label="Notes (Optional)" type="text" name="notes" placeholder="Maximum 1000 characters" multiline rows={5} maxRows={10} fullWidth/>
                </Grid>
              </Grid>)}
          </SimpleDrawerContent>

          <SimpleDrawerFooter>
            <FooterActionButton disabled={!isValid || isValidating || isSubmitting || loading} onClick={handleSubmit}>
              Save
            </FooterActionButton>
          </SimpleDrawerFooter>
        </>)}
    </Formik>);
};
export default EditUnavailabilityTemplateDrawerPage;
