import { handleActions } from 'redux-actions';
import { fetched, fetching } from 'src/utils/data-utils';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    auth: {},
    timestamp: Date.now()
};
const connect = (state) => {
    return Object.assign(Object.assign({}, state), { auth: fetching(state.auth) });
};
const connected = (state, action) => {
    return Object.assign(Object.assign({}, state), { auth: fetched(action.payload) });
};
const disconnect = (state) => {
    return Object.assign(Object.assign({}, state), { auth: fetched({}) });
};
const reducers = handleActions({
    [ActionCreators.NETSUITE_AUTH.CONNECT.type]: connect,
    [ActionCreators.NETSUITE_AUTH.CONNECTED.type]: connected,
    [ActionCreators.NETSUITE_AUTH.DISCONNECT.type]: disconnect,
}, DEFAULT_STATE);
export default reducers;
