import React from 'react';
import { SimpleDrawer, SimpleDrawerContent, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import styled from 'styled-components';
import DrawerListItem from './DrawerListItem';
const DrawerContent = styled.div `
  padding: 30px;
`;
const DailyOpsContactsDrawer = ({ open, onClose, contacts, onContactSave, }) => {
    return (<SimpleDrawer open={open} onClose={() => onClose()}>
      <SimpleDrawerHeader title="Daily Ops Contact" onClose={onClose}/>

      <SimpleDrawerContent>
        <DrawerContent>
          {contacts.map((contact) => {
            return (<DrawerListItem contact={contact} onRowSave={onContactSave} key={contact.fleetId}/>);
        })}
        </DrawerContent>
      </SimpleDrawerContent>
    </SimpleDrawer>);
};
export default DailyOpsContactsDrawer;
