import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { selectPaymentDrawerAmount, selectPaymentDrawerInvoice, selectPaymentDrawerPaymentSubtype, selectPaymentDrawerPaymentType, selectPaymentDrawerScreen, selectPaymentDrawerWorkOrderId, } from 'src/AdminApp/modules/paymentDrawer/selectors';
import { SlidesContainer, SimplePageSlider, } from 'src/components/SimpleDrawerPaged';
import PaymentDrawerFooter from '../PaymentDrawerFooter';
import SelectPayer from '../SelectPayer';
import HomePage from '../HomePage';
import ChargeScreen from '../ChargeScreen';
import PaymentTypes from '../PaymentTypes';
import RealPaymentDetails from '../RealPaymentDetails';
import usePaymentDrawer from '../usePaymentDrawer';
export const Content = styled.div `
  z-index: 10;
  overflow: hidden;
  width: 100%;
`;
const ChargeFlow = ({ closeDrawer }) => {
    const screen = useSelector(selectPaymentDrawerScreen);
    const amount = useSelector(selectPaymentDrawerAmount);
    const invoice = useSelector(selectPaymentDrawerInvoice);
    const paymentType = useSelector(selectPaymentDrawerPaymentType);
    const paymentSubtype = useSelector(selectPaymentDrawerPaymentSubtype);
    const workOrderId = useSelector(selectPaymentDrawerWorkOrderId);
    const { onAddPayment, onSubmitPayment, calculatePaymentDescription } = usePaymentDrawer({ workOrderId });
    const handleSubmit = (values, { setSubmitting, resetForm, }) => {
        var _a;
        if (values.referenceNumber)
            values.referenceNumber = values.referenceNumber.trim();
        values.description = calculatePaymentDescription(amount);
        const paymentMethodTokenElem = document.getElementById('payment_method_token');
        let paymentMethodToken = null;
        if (paymentMethodTokenElem)
            paymentMethodToken = paymentMethodTokenElem.value;
        if (paymentMethodToken) {
            onSubmitPayment({
                setSubmitting,
                resetForm,
                closeDrawer,
                paymentType,
                paymentSubtype: (_a = values === null || values === void 0 ? void 0 : values.paymentSubtype) !== null && _a !== void 0 ? _a : paymentSubtype,
                workOrderId,
            });
        }
        else {
            onAddPayment({
                payment: Object.assign(Object.assign({}, values), { amount }),
                setSubmitting,
                resetForm,
                closeDrawer,
                paymentType,
                paymentSubtype,
                workOrderId,
            });
        }
    };
    if (!screen)
        return null;
    return (<Formik id="payment-form" enableReinitialize initialValues={{
            amount: (invoice === null || invoice === void 0 ? void 0 : invoice.balanceOwed) ? invoice === null || invoice === void 0 ? void 0 : invoice.balanceOwed.toFixed(2) : 0,
            paymentSubtype: paymentSubtype !== null && paymentSubtype !== void 0 ? paymentSubtype : '',
        }} onSubmit={handleSubmit}>
      {({ values, handleSubmit }) => {
            useEffect(() => {
                values.paymentSubtype = '';
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [screen]);
            return (<Form style={{ width: '100%' }}>
            <Content>
              <SlidesContainer $hasTab={screen === 'home'}>
                <SimplePageSlider currentSlide={screen} slides={[
                    {
                        name: 'selectPayer',
                        render: () => <SelectPayer />,
                    },
                    {
                        name: 'home',
                        render: () => <HomePage />,
                    },
                    {
                        name: 'charge',
                        render: () => <ChargeScreen closeDrawer={closeDrawer}/>,
                    },
                    {
                        name: 'paymentType',
                        render: () => <PaymentTypes />,
                    },
                    {
                        name: 'paymentDetail',
                        render: () => <RealPaymentDetails />,
                    },
                ]}/>
                <PaymentDrawerFooter values={values} handleSubmit={handleSubmit}/>
              </SlidesContainer>
            </Content>
          </Form>);
        }}
    </Formik>);
};
export default ChargeFlow;
