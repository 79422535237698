import { createSelector } from 'reselect';
export const selectEnumerations = (state) => state.admin.lookups;
export const selectMarketingSources = createSelector(selectEnumerations, (enums) => { var _a; return (_a = enums.marketingSources) !== null && _a !== void 0 ? _a : []; });
export const selectMarketingSourcesById = createSelector(selectEnumerations, (enums) => enums.marketingSourcesById);
export const selectPaymentTypes = createSelector(selectEnumerations, (enums) => { var _a; return (_a = enums.paymentTypes) !== null && _a !== void 0 ? _a : []; });
export const selectPaymentTypeIdByName = (paymentType) => createSelector(selectEnumerations, (enums) => enums.paymentTypesByName ? enums.paymentTypesByName[paymentType].id : null);
export const selectPaymentTypesByName = createSelector(selectEnumerations, (enums) => { var _a; return (_a = enums.paymentTypesByName) !== null && _a !== void 0 ? _a : {}; });
export const selectPaymentSubTypes = createSelector(selectEnumerations, (enums) => { var _a; return (_a = enums.paymentSubTypes) !== null && _a !== void 0 ? _a : []; });
export const selectPaymentSubTypesById = createSelector(selectEnumerations, (enums) => { var _a; return (_a = enums.paymentSubTypesById) !== null && _a !== void 0 ? _a : {}; });
export const selectDiscountReasonTypes = createSelector(selectEnumerations, (enums) => { var _a; return (_a = enums.discountReasonTypes) !== null && _a !== void 0 ? _a : []; });
export const selectDiscountReasonOtherType = createSelector(selectDiscountReasonTypes, (reasons) => reasons.find((r) => r.internalReason === 'Other'));
export const selectAllRepairs = createSelector(selectEnumerations, (enums) => (enums === null || enums === void 0 ? void 0 : enums.allRepairs) || []);
