import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byRefNum: {},
};
const getWorkflowSuccess = (state, action) => {
    return Object.assign(Object.assign({}, state), { byRefNum: Object.assign(Object.assign({}, state.byRefNum), { [action.meta.refNumber]: action.payload }) });
};
const getStopwatchSuccess = (state, action) => {
    return Object.assign(Object.assign({}, state), { stopwatch: action.payload });
};
const clearStopwatchReducer = (state) => {
    return Object.assign(Object.assign({}, state), { stopwatch: undefined });
};
const stopStopwatchReducer = (state) => {
    return Object.assign(Object.assign({}, state), { stopwatch: Object.assign(Object.assign({}, state.stopwatch), { isRunning: false }) });
};
const reducers = handleActions({
    [ActionCreators.GET_WORKFLOW.SUCCESS.type]: getWorkflowSuccess,
    [ActionCreators.GET_REPAIR_REQUEST_STOPWATCH.SUCCESS.type]: getStopwatchSuccess,
    [ActionCreators.CLEAR_STOPWATCH.type]: clearStopwatchReducer,
    [ActionCreators.STOP_STOPWATCH.type]: stopStopwatchReducer,
}, DEFAULT_STATE);
export default reducers;
