var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { toast } from 'src/components/SimpleToast';
import createDefaultActionCreators from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { getWorkOrderPayments } from 'src/AdminApp/modules/requests/actions';
export const ActionCreators = {
    SPREEDLY_TOKENIZE: createDefaultActionCreators('SPREEDLY_TOKENIZE'),
    CHARGE_PAYMENT: createDefaultActionCreators('CHARGE_PAYMENT'),
    CHARGE_AFFIRM_PAYMENT: createDefaultActionCreators('CHARGE_AFFIRM_PAYMENT'),
    ADD_PAYMENT: createDefaultActionCreators('ADD_PAYMENT'),
    REMOVE_PAYMENT: createDefaultActionCreators('REMOVE_PAYMENT'),
    LOAD_PAYMENT_METHODS_BY_CONSUMER: createDefaultActionCreators('LOAD_PAYMENT_METHODS_BY_CONSUMER'),
    DEACTIVATE_PAYMENT_METHOD: createDefaultActionCreators('DEACTIVATE_PAYMENT_METHOD'),
    REFUND_PAYMENT: createDefaultActionCreators('REFUND_PAYMENT'),
};
export const spreedlyTokenize = (invoiceId, requiredFields, saveForFuture = false) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const spreedlyReady = Boolean(window.Spreedly);
    if (spreedlyReady) {
        yield window.Spreedly.tokenizeCreditCard(requiredFields);
    }
    return dispatch({
        type: ActionCreators.SPREEDLY_TOKENIZE.SUCCESS.type,
        data: {
            invoiceId,
            requiredFields,
            saveForFuture,
        },
    });
});
const analyticsPayload = (payment) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        payment_method: `${payment.type.displayName}${((_a = payment.subType) !== null && _a !== void 0 ? _a : null) ? ` - ${(_b = payment.subType.displayName) !== null && _b !== void 0 ? _b : null}` : ''}`,
        payment_amount: (_c = payment.amount) !== null && _c !== void 0 ? _c : null,
        payment_reference_number: (_d = payment.referenceNumber) !== null && _d !== void 0 ? _d : null,
        payment_date: moment().toDate(),
        balance_owed: (_e = payment.balanceOwed) !== null && _e !== void 0 ? _e : null,
        transaction_user: (_f = payment.transactionUser) !== null && _f !== void 0 ? _f : null,
        payment_status: 'SUCCEEDED',
    });
};
const paymentAnalyticsPayload = (payment, payload = null) => {
    var _a, _b, _c;
    const response = (_a = payload === null || payload === void 0 ? void 0 : payload.response) !== null && _a !== void 0 ? _a : payload;
    return Object.assign(Object.assign({}, analyticsPayload(payment)), { payment_status: (_b = response === null || response === void 0 ? void 0 : response.state) !== null && _b !== void 0 ? _b : 'SUCCEEDED', declined_reason: response === null || response === void 0 ? void 0 : response.errorMessage, payments_received: (_c = payment === null || payment === void 0 ? void 0 : payment.paymentsReceived) !== null && _c !== void 0 ? _c : null });
};
export const chargePayment = (workOrderId, payment, invoiceId = null) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.CHARGE_PAYMENT.FETCH(),
            ActionCreators.CHARGE_PAYMENT.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.CHARGE_PAYMENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PAYMENT_CHARGE({ workOrderId }),
        method: 'POST',
        body: payment,
    }));
    const { error, payload } = response;
    if (error) {
        const { message = 'Sorry! Payment charge was unsuccessful.' } = payload;
        const errorMessage = (_b = (_a = payload.response) === null || _a === void 0 ? void 0 : _a.errorMessage) !== null && _b !== void 0 ? _b : message;
        toast.error(errorMessage);
    }
    else {
        toast.success('Payment was added successfully');
    }
    dispatch(getWorkOrderPayments(workOrderId));
    const paymentAnalytics = paymentAnalyticsPayload(payment, payload);
    if (invoiceId) {
        dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    }
    dispatch(analyticsTrackEvent('Charge Processed', paymentAnalytics));
    return (_c = payload.response) !== null && _c !== void 0 ? _c : null;
});
export const chargeAffirmPayment = (workOrderId, payment, invoiceId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _d, _e;
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.CHARGE_AFFIRM_PAYMENT.FETCH(),
            ActionCreators.CHARGE_AFFIRM_PAYMENT.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.CHARGE_AFFIRM_PAYMENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.AFFIRM_PAYMENT_CHARGE({ workOrderId }),
        method: 'POST',
        body: payment,
    }));
    const { error, payload } = response;
    if (error) {
        const { message = 'Sorry! Payment charge was unsuccessful.' } = payload;
        const errorMessage = (_e = (_d = payload.response) === null || _d === void 0 ? void 0 : _d.errorMessage) !== null && _e !== void 0 ? _e : message;
        toast.error(errorMessage);
    }
    else {
        toast.success('Payment was added successfully');
    }
    dispatch(getWorkOrderPayments(workOrderId));
    const paymentAnalytics = paymentAnalyticsPayload(payment, payload);
    if (invoiceId) {
        dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    }
    dispatch(getWorkOrderPayments(workOrderId));
    dispatch(analyticsTrackEvent('Charge Processed', paymentAnalytics));
    return payload;
});
export const addPayment = (workOrderId, payment, invoiceId = null) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.ADD_PAYMENT.FETCH(),
            ActionCreators.ADD_PAYMENT.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.ADD_PAYMENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PAYMENT_ADD({ workOrderId }),
        method: 'POST',
        body: payment,
    }));
    const { error } = response;
    if (!error) {
        toast.success('Payment was added successfully');
    }
    const paymentDescription = payment.description;
    const isRefund = paymentDescription.includes('REFUND');
    const eventName = isRefund ? 'Refund Processed' : 'Charge Processed';
    let trackingCallPayload;
    if (isRefund) {
        trackingCallPayload = analyticsPayload(payment);
    }
    else {
        trackingCallPayload = paymentAnalyticsPayload(payment, null);
    }
    dispatch(getWorkOrderPayments(workOrderId));
    dispatch(analyticsTrackEvent(eventName, trackingCallPayload));
    if (invoiceId) {
        return dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    }
});
export const removePayment = (workOrderId, paymentId, invoiceId = null) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _f, _g;
    const firstResponse = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REMOVE_PAYMENT.FETCH(),
            ActionCreators.REMOVE_PAYMENT.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.REMOVE_PAYMENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PAYMENT_REMOVE({ workOrderId, paymentId }),
        method: 'DELETE',
    }));
    const { error, payload } = firstResponse;
    if (error) {
        const { message = 'Sorry! Payment removal was unsuccessful.' } = payload;
        const errorMessage = (_g = (_f = payload.response) === null || _f === void 0 ? void 0 : _f.errorMessage) !== null && _g !== void 0 ? _g : message;
        toast.error(errorMessage);
    }
    else {
        toast.success('Payment removed successfully!');
    }
    dispatch(getWorkOrderPayments(workOrderId));
    if (invoiceId) {
        return dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    }
});
export const loadPaymentMethodsByConsumer = (consumerId, referenceNum) => createAPIAction({
    types: [
        ActionCreators.LOAD_PAYMENT_METHODS_BY_CONSUMER.FETCH(),
        ActionCreators.LOAD_PAYMENT_METHODS_BY_CONSUMER.SUCCESS({
            meta: () => ({ consumerId }),
        }),
        ActionCreators.LOAD_PAYMENT_METHODS_BY_CONSUMER.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.PAYMENT_METHODS_BY_CONSUMER({
        consumerId,
        referenceNum,
    }),
    method: 'GET',
});
export const removePaymentMethod = (referenceNum, consumerId, paymentMethodId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.DEACTIVATE_PAYMENT_METHOD.FETCH(),
            ActionCreators.DEACTIVATE_PAYMENT_METHOD.SUCCESS({
                meta: () => ({ consumerId }),
            }),
            ActionCreators.DEACTIVATE_PAYMENT_METHOD.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PAYMENT_METHOD_ACTIVATION({
            consumerId,
            paymentMethodId,
        }),
        params: { activate: false },
        method: 'PUT',
    }));
    if (!response.error) {
        toast.success('Payment method was successfully deleted');
    }
    dispatch(loadPaymentMethodsByConsumer(consumerId, referenceNum));
});
export const refundPayment = (workOrderId, payment, invoiceId = null) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _h, _j;
    const response = yield dispatch(createAPIAction({
        types: [
            ActionCreators.REFUND_PAYMENT.FETCH(),
            ActionCreators.REFUND_PAYMENT.SUCCESS({
                meta: () => ({ workOrderId }),
            }),
            ActionCreators.REFUND_PAYMENT.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PAYMENT_REFUND({ workOrderId }),
        method: 'POST',
        body: payment,
    }));
    const { error, payload } = response;
    if (error) {
        const { message = 'Sorry! Payment refund was unsuccessful.' } = payload;
        const errorMessage = (_j = (_h = payload.response) === null || _h === void 0 ? void 0 : _h.errorMessage) !== null && _j !== void 0 ? _j : message;
        toast.error(errorMessage);
    }
    else {
        toast.success('Payment was refunded successfully');
    }
    yield dispatch(getWorkOrderPayments(workOrderId));
    if (invoiceId) {
        return dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    }
});
