var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment/moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { AppointmentStatus } from 'src/AdminApp/models/enums';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { editDateAppointment, editDateOrder, rescheduleAppointment, updateAppointment, } from 'src/AdminApp/modules/orders/actions';
import { cancelRequestAppointment, getRequest, reloadRequest, reloadRequestAppointments, updatePartsStatus, } from 'src/AdminApp/modules/requests/actions';
import useIsMobile from 'src/hooks/useIsMobile';
import { DATE_YMD_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
const useAppointmentModule = (repairRequest, zip, address, origin, destination, vehicle, referenceNum, workOrderId, appointments, orderId, consumerPreferredTimes, repairsString, deliveryChannel, hasInvoice) => {
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const updateDate = ({ date, timeSlot, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(editDateOrder({ orderId, date, timeSlot }));
        dispatch(reloadRequestAppointments(referenceNum));
    });
    const [dateConfirmModalState, setDateConfirmModalState] = useState({
        open: false,
        date: null,
    });
    const openConfirmModal = (date) => setDateConfirmModalState({ open: true, date });
    const closeConfirmModal = () => setDateConfirmModalState({ open: false, date: undefined });
    const onUpdateDate = (dataParam) => __awaiter(void 0, void 0, void 0, function* () {
        const date = dataParam.format(DATE_YMD_FORMAT);
        const timeSlot = dataParam.format(TIME_12H_FORMAT);
        yield dispatch(editDateAppointment({ referenceNum, date, timeSlot }));
        dispatch(reloadRequestAppointments(referenceNum));
    });
    const onPastDateConfirm = () => {
        onUpdateDate(dateConfirmModalState.date);
        closeConfirmModal();
    };
    const onEditDateConfirmClick = (date) => {
        if (date.isBefore(moment())) {
            openConfirmModal(date);
        }
        else {
            onUpdateDate(date);
        }
    };
    const onUpdateAppointment = (values) => dispatch(updateAppointment(Object.assign(Object.assign({}, values), { fetchInvoice: hasInvoice }), orderId, workOrderId, referenceNum));
    const onUpdatePartsStatus = (status) => {
        return dispatch(updatePartsStatus({ referenceNum, status }));
    };
    const onUpdateAppointmentStatus = ({ status, resolution, otherResolution, }) => __awaiter(void 0, void 0, void 0, function* () {
        if (isMobile && status === AppointmentStatus.CANCELED) {
            const response = yield dispatch(cancelRequestAppointment({
                appointmentResolution: resolution,
                otherResolution: otherResolution || null,
                referenceNum,
            }));
            if (response && !response.error) {
                toast.success('Appointment successfully updated.');
            }
        }
        else {
            yield onUpdateAppointment({
                deliveryChannel,
                status,
                resolution,
                otherResolution,
            });
        }
        return getRequest(referenceNum, repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.id);
    });
    const onClickRescheduleAppointment = () => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(rescheduleAppointment({ requestId: repairRequest.id }));
        yield dispatch(reloadRequestAppointments(referenceNum));
        toast.success('New pending appointment successfully created.');
    });
    const onSubmitCancelAppointmentDrawer = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const response = yield dispatch(cancelRequestAppointment(values));
        if (((_a = response === null || response === void 0 ? void 0 : response.activeAppointment) === null || _a === void 0 ? void 0 : _a.status) === 'CLOSED') {
            yield dispatch(getInvoicesByWorkOrderId({ workOrderId: workOrderId }));
        }
        if (response && !response.error) {
            toast.success('Appointment successfully canceled.');
            yield dispatch(reloadRequest(referenceNum));
        }
    });
    return {
        isMobile,
        dateConfirmModalState,
        updateDate,
        closeConfirmModal,
        onPastDateConfirm,
        onEditDateConfirmClick,
        onUpdateAppointment,
        onUpdatePartsStatus,
        onUpdateAppointmentStatus,
        onClickRescheduleAppointment,
        onSubmitCancelAppointmentDrawer,
    };
};
export default useAppointmentModule;
