var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Card, Grid, Paper, Typography } from 'src/components/mui';
import csv from 'csvtojson';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { ActionButton, DestructiveButton } from 'src/components/buttons';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
import UploaderResponseDetails from './UploaderResponseDetails';
import UploaderValidationDetails from './UploaderValidationDetails';
const DropZoneCard = styled(Card) `
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.GRAY_F1} !important;
`;
const StyledPaper = styled(Paper) `
  padding: 10px;
`;
export const convertCsvToJson = (csvString) => __awaiter(void 0, void 0, void 0, function* () {
    return csv().fromString(csvString);
});
export const readFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result);
        };
        reader.onerror = () => {
            reject(new Error('Problem reading input file'));
        };
        reader.readAsBinaryString(file);
    });
});
export const getValidationInfo = (validationResult) => {
    return {
        detected: validationResult.length,
        accepted: validationResult.filter((x) => x.isValid).length,
        errors: validationResult
            .map((x) => {
            return x.errors;
        })
            .flat(),
    };
};
const Uploader = ({ title, schemaDefinition, transformData, onSubmit, onValidate, onPrepareData, }) => {
    const [csvFile, setCsvFile] = useState();
    const [jsonData, setJsonData] = useState();
    const [uploadResponse, setUploadResponse] = useState();
    const [loading, setLoading] = useState(false);
    const [validationInfo, setValidationInfo] = useState();
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (!csvFile)
            return;
        if (transformData && onPrepareData) {
            const preparedData = onPrepareData(jsonData, csvFile.name);
            const response = yield onSubmit(preparedData);
            setUploadResponse(((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.response) || (response === null || response === void 0 ? void 0 : response.payload));
            return;
        }
        setLoading(true);
        const response = yield onSubmit(csvFile);
        setLoading(false);
        setValidationInfo(((_b = response === null || response === void 0 ? void 0 : response.payload) === null || _b === void 0 ? void 0 : _b.response) || (response === null || response === void 0 ? void 0 : response.payload));
    });
    const handleCancel = () => {
        setCsvFile(undefined);
        setValidationInfo(undefined);
        setUploadResponse(undefined);
        setJsonData(undefined);
    };
    const onDrop = (files) => __awaiter(void 0, void 0, void 0, function* () {
        if (files === null || files === void 0 ? void 0 : files.length) {
            const csv = files[0];
            setCsvFile(csv);
            setUploadResponse(undefined);
            if (!transformData)
                return;
            const fileData = yield readFile(csv);
            const jsonFileData = yield convertCsvToJson(fileData);
            const updatedData = [];
            let dataFields = [];
            jsonFileData.forEach((row) => {
                const key = Object.keys(row)[0];
                const str = row[key];
                if (Object.keys(row).length > 1) {
                    updatedData.push(row);
                    return;
                }
                const isDataFieldInKey = key.split(';').length > 1;
                if (isDataFieldInKey) {
                    dataFields = key.split(';');
                }
                if (!dataFields.length) {
                    dataFields = str.split(';');
                }
                else {
                    const item = {};
                    dataFields.forEach((field, index) => {
                        item[field] = str.split(';')[index];
                    });
                    updatedData.push(item);
                }
            });
            setJsonData(updatedData);
            if (!onValidate)
                return;
            const validatedData = onValidate(updatedData);
            setValidationInfo(validatedData);
        }
    });
    return (<Grid container item direction="column" spacing={2} style={{ maxWidth: 600 }}>
      <Grid item xs>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item xs>
        <Dropzone onDrop={onDrop} multiple={false}>
          {({ getRootProps, getInputProps }) => (<DropZoneCard {...getRootProps()}>
              <input {...getInputProps()} accept=".csv"/>
              <Button>
                Drag 'n' drop .csv file here, or click to select file
              </Button>
            </DropZoneCard>)}
        </Dropzone>
      </Grid>
      <Grid item xs>
        <StyledPaper>
          <Typography variant="h6">Expected Schema:</Typography>
          <Typography>{schemaDefinition}</Typography>
        </StyledPaper>
      </Grid>
      <Grid item container xs={12} style={{ justifyContent: 'space-around' }}>
        <Grid item>
          <DestructiveButton onClick={handleCancel}>Clear</DestructiveButton>
        </Grid>
        <Grid item>
          <ActionButton onClick={handleSubmit} type="submit" color="primary" disabled={loading || !csvFile || (validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.errors.length) > 0}>
            Upload
          </ActionButton>
        </Grid>
      </Grid>
      {!!csvFile && (<Grid item xs>
          <StyledPaper>
            <Typography>Selected file: {csvFile === null || csvFile === void 0 ? void 0 : csvFile.name}</Typography>
          </StyledPaper>
        </Grid>)}
      {!!validationInfo && !uploadResponse && (<UploaderValidationDetails validationInfo={validationInfo}/>)}
      {!!uploadResponse && (<UploaderResponseDetails validationInfo={uploadResponse}/>)}
    </Grid>);
};
export default Uploader;
