import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useRef, useState, } from 'react';
import { debounce, uniqBy } from 'lodash';
import { useMediaQuery } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import { getFleetCars, searchCars } from 'src/AdminApp/modules/fleets/actions';
import useInfiniteScroll from 'src/hooks/useInfiniteScroll';
import { setTab, setVehicle } from 'src/AdminApp/modules/quickRo/actions';
import { FLEET_TABS as TABS } from 'src/AdminApp/modules/quickRo/enums';
import useIsMobile from 'src/hooks/useIsMobile';
export const defaultFleetCarsParams = {
    pageIndex: 0,
    pageSize: 20,
    sort: ['carMake', 'asc'],
};
const useFleetGarage = ({ fleet }) => {
    var _a;
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const [defaultVehicles, setDefaultVehicles] = useState([]);
    const [searchVehicles, setSearchVehicles] = useState([]);
    const [search, setSearch] = useState();
    const [loading, setLoading] = useState(false);
    const [noMoreDefault, setNoMoreDefault] = useState(false);
    const [noMoreSearch, setNoMoreSearch] = useState(false);
    const [page, setPage] = useState(0);
    const [searchPage, setSearchPage] = useState(0);
    const loadMoreRef = useRef();
    const hasSearchVehicles = ((_a = searchVehicles === null || searchVehicles === void 0 ? void 0 : searchVehicles.length) !== null && _a !== void 0 ? _a : 0) > 0;
    const vehiclesData = uniqBy(hasSearchVehicles ? searchVehicles : defaultVehicles, 'id');
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    useEffect(() => {
        if (fleet === null || fleet === void 0 ? void 0 : fleet.id) {
            dispatch(getFleetCars(Object.assign(Object.assign({}, defaultFleetCarsParams), { fleetId: fleet.id }))).then(({ payload }) => {
                setDefaultVehicles((payload === null || payload === void 0 ? void 0 : payload.content) || []);
            });
        }
    }, [fleet === null || fleet === void 0 ? void 0 : fleet.id]); // eslint-disable-line
    // eslint-disable-next-line
    const onSearch = useCallback(debounce((query) => {
        setNoMoreSearch(false);
        setSearchPage(0);
        if ((query === null || query === void 0 ? void 0 : query.length) > 0) {
            dispatch(searchCars(Object.assign(Object.assign({}, defaultFleetCarsParams), { fleetId: fleet.id, query }))).then(({ payload }) => {
                var _a, _b;
                setSearchVehicles(payload === null || payload === void 0 ? void 0 : payload.content);
                if (((_a = payload === null || payload === void 0 ? void 0 : payload.pageable) === null || _a === void 0 ? void 0 : _a.pageNumber) === ((_b = payload === null || payload === void 0 ? void 0 : payload.totalPages) !== null && _b !== void 0 ? _b : 0) - 1)
                    setNoMoreSearch(true);
            });
            return;
        }
        setSearchVehicles([]);
    }, 500), [fleet]);
    const handleLoadMore = () => {
        if (!(fleet === null || fleet === void 0 ? void 0 : fleet.id))
            return;
        if (hasSearchVehicles) {
            if (noMoreSearch)
                return;
            setLoading(true);
            dispatch(searchCars(Object.assign(Object.assign({}, defaultFleetCarsParams), { fleetId: fleet.id, pageIndex: searchPage + 1, query: search }))).then(({ payload }) => {
                var _a, _b;
                setSearchVehicles((prev) => { var _a; return [...prev, ...((_a = payload === null || payload === void 0 ? void 0 : payload.content) !== null && _a !== void 0 ? _a : [])]; });
                setSearchPage((prev) => prev + 1);
                if (((_a = payload === null || payload === void 0 ? void 0 : payload.pageable) === null || _a === void 0 ? void 0 : _a.pageNumber) === ((_b = payload === null || payload === void 0 ? void 0 : payload.totalPages) !== null && _b !== void 0 ? _b : 0) - 1)
                    setNoMoreSearch(true);
                setLoading(false);
            });
            return;
        }
        if (noMoreDefault)
            return;
        setLoading(true);
        dispatch(getFleetCars(Object.assign(Object.assign({}, defaultFleetCarsParams), { fleetId: fleet.id, pageIndex: page + 1 }))).then(({ payload }) => {
            var _a, _b;
            setDefaultVehicles((prev) => { var _a; return [...prev, ...((_a = payload === null || payload === void 0 ? void 0 : payload.content) !== null && _a !== void 0 ? _a : [])]; });
            setPage((prev) => prev + 1);
            if (((_a = payload === null || payload === void 0 ? void 0 : payload.pageable) === null || _a === void 0 ? void 0 : _a.pageNumber) === ((_b = payload === null || payload === void 0 ? void 0 : payload.totalPages) !== null && _b !== void 0 ? _b : 0) - 1)
                setNoMoreDefault(true);
            setLoading(false);
        });
    };
    const disableLoadMore = (hasSearchVehicles && noMoreSearch) ||
        (!hasSearchVehicles && noMoreDefault);
    const pause = disableLoadMore || loading;
    useInfiniteScroll({
        callback: handleLoadMore,
        pause,
        ref: loadMoreRef,
    });
    const selectVehicleHandler = (car) => {
        dispatch(setVehicle(car));
        dispatch(setTab(TABS.ADD_SERVICES));
    };
    const buttonHandler = () => {
        dispatch(setTab(TABS.ADD_NEW_VEHICLE));
    };
    const handleSearch = (e) => {
        const val = e.currentTarget.value;
        setSearch(val);
        onSearch(val);
    };
    const loadMoreText = disableLoadMore
        ? 'No More Vehicles'
        : 'Load More Vehicles';
    return {
        isMobile,
        buttonHandler,
        search,
        handleSearch,
        isDesktop,
        vehiclesData,
        selectVehicleHandler,
        loadMoreRef,
        loading,
        loadMoreText,
    };
};
export default useFleetGarage;
