import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Tabs, Tab } from 'src/components/mui';
import { TabPanel } from 'src/AdminApp/components/layout/TabPanel';
import { loadRequestSnapshot } from 'src/AdminApp/modules/requests/actions';
import { selectRequestSnapshotsByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import styled from 'styled-components';
import SnapshotLoader from './SnapshotLoader';
import SnapshotEventLog from './SnapshotEventLog';
import SnapshotDiffChangelog from './SnapshotDiffChangelog';
import SnapshotDiffViewer from './SnapshotDiffViewer';
import SnapshotJsonViewer from './SnapshotJsonViewer';
import SnapshotComponentViewer from './SnapshotComponentViewer';
import SnapshotBrowserContext from './context';
const FloatingGrid = styled(Grid) `
  z-index: 10;
`;
const SnapshotsBrowser = ({ referenceNum, loaderEnabled, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const dispatch = useDispatch();
    const [selectedSnapshot, setSelectedSnapshot] = useState();
    const [currentTab, setCurrentTab] = useState(0);
    const [isExpanded, setIsExpanded] = useState(true);
    const snapshots = useSelector(selectRequestSnapshotsByReferenceNum(referenceNum));
    const toggleExpanded = () => setIsExpanded(!isExpanded);
    const snapshotContext = useMemo(() => ({ isExpanded, toggleExpanded }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isExpanded]);
    const selectSnapshot = (snapshotId) => {
        const snapshot = snapshots.find((s) => s.id === snapshotId);
        setSelectedSnapshot(snapshot);
    };
    const getPreviousSnapshot = () => {
        if (!snapshots || !selectedSnapshot)
            return null;
        const selectedIndex = snapshots.indexOf(selectedSnapshot);
        const previousIndex = selectedIndex + 1;
        return selectedIndex < snapshots.length ? snapshots[previousIndex] : null;
    };
    const loadSnapshots = useCallback((refNum) => {
        if (!refNum)
            return;
        dispatch(loadRequestSnapshot({ referenceNum: refNum }));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [referenceNum]);
    useEffect(() => {
        setSelectedSnapshot(((snapshots === null || snapshots === void 0 ? void 0 : snapshots.length) > 0 && snapshots[0]) || undefined);
    }, [snapshots]);
    useEffect(() => {
        if (snapshots)
            return;
        loadSnapshots(referenceNum);
    }, [loadSnapshots, referenceNum, snapshots]);
    return (<SnapshotBrowserContext.Provider value={snapshotContext}>
      <Grid container item md={12} spacing={1}>
        {loaderEnabled && (<Grid item sm={12}>
            <SnapshotLoader referenceNum={referenceNum} reload={loadSnapshots}/>
          </Grid>)}
        <FloatingGrid item sm={12} md={isExpanded ? 4 : 1} lg={isExpanded ? 3 : 1}>
          <SnapshotEventLog referenceNum={referenceNum} snapshots={snapshots} selectedSnapshotId={selectedSnapshot === null || selectedSnapshot === void 0 ? void 0 : selectedSnapshot.id} selectSnapshot={selectSnapshot}/>
        </FloatingGrid>
        <Grid item sm={12} md={isExpanded ? 8 : 11} lg={isExpanded ? 9 : 11}>
          {referenceNum && snapshots && (<>
              <Tabs value={currentTab} onChange={(event, tab) => setCurrentTab(tab)}>
                <Tab label="View"/>
                <Tab label="Diff"/>
                <Tab label="Changelog"/>
                <Tab label="JSON"/>
              </Tabs>
              <TabPanel index={0} value={currentTab}>
                <SnapshotComponentViewer referenceNum={referenceNum} snapshot={selectedSnapshot}/>
              </TabPanel>
              <TabPanel index={1} value={currentTab}>
                <SnapshotDiffViewer previous={(_b = (_a = getPreviousSnapshot()) === null || _a === void 0 ? void 0 : _a.dataV2Dto) === null || _b === void 0 ? void 0 : _b.data} current={(_c = selectedSnapshot === null || selectedSnapshot === void 0 ? void 0 : selectedSnapshot.dataV2Dto) === null || _c === void 0 ? void 0 : _c.data}/>
              </TabPanel>
              <TabPanel index={2} value={currentTab}>
                <SnapshotDiffChangelog previous={(_e = (_d = getPreviousSnapshot()) === null || _d === void 0 ? void 0 : _d.dataV2Dto) === null || _e === void 0 ? void 0 : _e.data} current={(_f = selectedSnapshot === null || selectedSnapshot === void 0 ? void 0 : selectedSnapshot.dataV2Dto) === null || _f === void 0 ? void 0 : _f.data}/>
              </TabPanel>
              <TabPanel index={3} value={currentTab}>
                <SnapshotJsonViewer json={((_g = selectedSnapshot === null || selectedSnapshot === void 0 ? void 0 : selectedSnapshot.dataV2Dto) === null || _g === void 0 ? void 0 : _g.data) || null} root={(_h = selectedSnapshot === null || selectedSnapshot === void 0 ? void 0 : selectedSnapshot.dataV2Dto) === null || _h === void 0 ? void 0 : _h.version}/>
              </TabPanel>
            </>)}
        </Grid>
      </Grid>
    </SnapshotBrowserContext.Provider>);
};
export default SnapshotsBrowser;
