var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendInvoice } from 'src/AdminApp/modules/invoices/actions';
import { selectMainInvoiceByWorkOrderId } from 'src/AdminApp/modules/invoices/selectors';
import { getDiscounts } from 'src/AdminApp/modules/workOrders/actions';
import { requestSalesCall } from 'src/AdminApp/modules/zendesk/actions';
const useInvoiceFooter = (workOrderId) => {
    const { referenceNum } = useParams();
    const mainInvoice = useSelector((state) => selectMainInvoiceByWorkOrderId(state, { workOrderId }));
    const { id: invoiceId } = mainInvoice;
    const [isSendEmailConfirmDialogOpen, setIsSendEmailConfirmDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const openSendEmailConfirmDialog = () => {
        setIsSendEmailConfirmDialogOpen(true);
    };
    const closeSendEmailConfirmDialog = () => {
        setIsSendEmailConfirmDialogOpen(false);
    };
    const handleSendEmailConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        closeSendEmailConfirmDialog();
        yield dispatch(sendInvoice({ invoiceId, workOrderId, referenceNum }));
        dispatch(getDiscounts({ workOrderId }));
    });
    const handleRequestSalesCall = () => {
        dispatch(requestSalesCall(referenceNum));
    };
    return {
        referenceNum,
        mainInvoice,
        isSendEmailConfirmDialogOpen,
        openSendEmailConfirmDialog,
        closeSendEmailConfirmDialog,
        handleSendEmailConfirm,
        handleRequestSalesCall,
    };
};
export default useInvoiceFooter;
