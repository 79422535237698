var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDeliveryAddressOptions, setSelectedDeliveryAddress, } from 'src/PopsApp/modules/parts/actions';
import { selectDeliveryAddressOptions, selectSelectedDeliveryAddress, } from 'src/PopsApp/modules/parts/selectors';
import { PickupMethod } from 'src/PopsApp/models/app';
const useDelivery = ({ ispId, distributor, selectedJobs, setPickupMethod, }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const deliveryAddressOptions = useSelector(selectDeliveryAddressOptions);
    const selectedAddress = useSelector(selectSelectedDeliveryAddress);
    useEffect(() => {
        return () => {
            dispatch(setSelectedDeliveryAddress(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (distributor === null || distributor === void 0 ? void 0 : distributor.partDistributorLocationId) {
                const referenceNums = selectedJobs.map((j) => j.referenceNum);
                const response = yield dispatch(getDeliveryAddressOptions(ispId, distributor.partDistributorLocationId, referenceNums));
                if (!response || (response === null || response === void 0 ? void 0 : response.error)) {
                    setError(true);
                }
                setPickupMethod(PickupMethod.WILL_CALL);
            }
            else {
                setError(true);
            }
            setLoading(false);
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distributor]);
    useEffect(() => {
        var _a;
        if (!deliveryAddressOptions)
            return;
        dispatch(setSelectedDeliveryAddress({
            locationId: (_a = deliveryAddressOptions === null || deliveryAddressOptions === void 0 ? void 0 : deliveryAddressOptions.hubHomeStore) === null || _a === void 0 ? void 0 : _a.partDistributorLocationId,
            type: 'HUB',
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryAddressOptions]);
    const onAddressSelect = (locationId) => {
        var _a;
        dispatch(setSelectedDeliveryAddress({
            locationId,
            type: locationId === ((_a = deliveryAddressOptions === null || deliveryAddressOptions === void 0 ? void 0 : deliveryAddressOptions.hubHomeStore) === null || _a === void 0 ? void 0 : _a.id)
                ? 'HUB'
                : 'HOTSHOT',
        }));
    };
    return {
        loading,
        setLoading,
        error,
        setError,
        deliveryAddressOptions,
        selectedAddress,
        onAddressSelect,
    };
};
export default useDelivery;
