import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isNull, max } from 'lodash';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, } from 'src/components/mui';
import SimpleDatePicker from 'src/components/SimpleDatePicker';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
import { getDeliveryVansAutocomplete } from 'src/AdminApp/modules/deliveryVans/actions';
import { selectDeliveryVansAutocomplete } from 'src/AdminApp/modules/deliveryVans/selectors';
import { SlidePageFieldsContainer } from 'src/AdminApp/components/geoManagement/GeoManagementDrawer/styledComponents';
import DeliveryVanSearch from 'src/components/form/DeliveryVanSearch';
import { DATE_TIME_FORMAT, LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const DeliveryVan = ({ index = 0, value, onSetValue, onRemove, minDate, isLast, disabled = false, }) => {
    var _a, _b;
    return (<SlidePageFieldsContainer isLast={isLast} key={index}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DeliveryVanSearch key={`van_${index}`} value={value.van} name={`van_${index}`} label={`Van ${index + 1} (optional)`} disabled={!!value.id || disabled} onUpdate={(van) => {
            if (van) {
                onSetValue(index, Object.assign(Object.assign({}, value), { van }));
            }
            else {
                onRemove(index);
            }
        }} getDeliveryVans={getDeliveryVansAutocomplete} deliveryVansSelector={selectDeliveryVansAutocomplete}/>
      </Grid>
      {value.van && (<>
          <Grid item xs={6}>
            <SimpleDatePicker fullWidth name={`effectiveDateFrom_${index}`} label="Effective from" format={LOCALIZED_DATE_FORMAT} disabled={value.active} minDate={moment(max([minDate, (_a = value.van) === null || _a === void 0 ? void 0 : _a.launchDate]))} value={value.effectiveFrom ? moment(value.effectiveFrom) : null} onChange={(date) => {
                onSetValue(index, Object.assign(Object.assign({}, value), { effectiveFrom: moment(date)
                        .startOf('day')
                        .format(DATE_TIME_FORMAT) }));
            }} slotProps={{
                textField: {
                    variant: 'outlined',
                    helperText: 'Must be greater than or equal to Marketing Launch, Vehicle Launch date',
                },
            }}/>
          </Grid>
          <Grid item xs={6}>
            <SimpleDatePicker fullWidth name={`effectiveDateTo_${index}`} label="Effective to (opt.)" format={LOCALIZED_DATE_FORMAT} disabled={value.alreadyInactive} minDate={moment(value.effectiveFrom ||
                minDate ||
                ((_b = value.van) === null || _b === void 0 ? void 0 : _b.launchDate) ||
                undefined)} value={value.effectiveTo ? moment(value.effectiveTo) : null} onChange={(date) => {
                onSetValue(index, Object.assign(Object.assign({}, value), { effectiveTo: !isNull(date)
                        ? moment(date).startOf('day').format(DATE_TIME_FORMAT)
                        : '' }));
            }} slotProps={{
                textField: {
                    variant: 'outlined',
                    helperText: 'Must be greater than or equal to Effective To, Vehicle Launch, Marketing Launch date',
                },
                field: {
                    clearable: true,
                },
            }}/>
          </Grid>
        </>)}
    </Grid>
  </SlidePageFieldsContainer>);
};
export const DeliveryVansSelector = ({ selectedVans, onUpdate, minDate, }) => {
    const [activeVans, setActiveVans] = useState([]);
    const [inactiveVans, setInactiveVans] = useState([]);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setActiveVans(selectedVans.filter((x) => !x.alreadyInactive));
        setInactiveVans(selectedVans.filter((x) => x.alreadyInactive));
        setLoaded(true);
    }, [selectedVans]);
    const updateElement = (index, value) => {
        if (index === activeVans.length) {
            return onUpdate([...activeVans, value, ...inactiveVans]);
        }
        const updatedActiveVans = [...activeVans];
        updatedActiveVans[index] = value;
        onUpdate([...updatedActiveVans, ...inactiveVans]);
    };
    const removeElement = (index) => {
        const updatedActiveVans = [...activeVans];
        updatedActiveVans.splice(index, 1);
        onUpdate([...updatedActiveVans, ...inactiveVans]);
    };
    return (<>
      <SlidePageFieldsContainer>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Typography>Active Delivery Vans</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            {activeVans.map((x, index) => (<DeliveryVan key={`activeVan_${index}`} index={index} value={x} onSetValue={updateElement} onRemove={removeElement} minDate={minDate} isLast={index === activeVans.length - 1}/>))}
            <DeliveryVan index={activeVans.length} value={{}} onSetValue={updateElement} onRemove={removeElement} minDate={minDate} disabled={!loaded} isLast/>
          </AccordionDetails>
        </Accordion>
      </SlidePageFieldsContainer>
      {inactiveVans.length ? (<SlidePageFieldsContainer>
          <Accordion defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Typography>Inactive Delivery Vans</Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
              {inactiveVans.map((x, index) => (<DeliveryVan key={`inactiveVan_${index}`} index={index} value={x} onSetValue={updateElement} onRemove={removeElement} minDate={minDate} disabled isLast={index === inactiveVans.length - 1}/>))}
            </AccordionDetails>
          </Accordion>
        </SlidePageFieldsContainer>) : null}
    </>);
};
