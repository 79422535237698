var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
let instance = null;
export const GeocoderClient = () => {
    if (!instance) {
        instance = new global.google.maps.Geocoder();
    }
    return instance;
};
export const fetchAddressDetails = (address) => __awaiter(void 0, void 0, void 0, function* () {
    const { status, results } = yield geocodeAddress(address);
    if (status === 'OK') {
        const topResult = results[0];
        const addressDetails = mapAddressDetails(topResult);
        return { status, data: addressDetails };
    }
    return { status };
});
const geocodeAddress = (address, timeout = 0) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve) => {
        setTimeout(() => GeocoderClient().geocode({ address }, processGeocodeResponse(address, resolve)), timeout);
    });
});
const processGeocodeResponse = (address, resolve) => (results, status) => {
    if (status === 'OK') {
        resolve({ status, results });
    }
    else if (status === 'OVER_QUERY_LIMIT') {
        // retry API request in 30 seconds on OVER_QUERY_LIMIT Error
        // eslint-disable-next-line
        console.warn(`API ERROR: ${status}, retrying geocode request for address: ${address}`);
        geocodeAddress(address, 5000).then(resolve);
    }
    else {
        resolve({ status, results });
    }
};
const getAddressDetail = (addressData, type) => {
    var _a;
    return (_a = addressData.address_components.find((component) => component.types.includes(type))) === null || _a === void 0 ? void 0 : _a.long_name;
};
const mapAddressDetails = (addressData) => {
    const street1 = getAddressDetail(addressData, 'route');
    const street2 = getAddressDetail(addressData, 'street_number');
    const subpremise = getAddressDetail(addressData, 'subpremise');
    const city = getAddressDetail(addressData, 'locality');
    const state = getAddressDetail(addressData, 'administrative_area_level_1');
    const country = getAddressDetail(addressData, 'country');
    const zip = getAddressDetail(addressData, 'postal_code');
    const latitude = addressData.geometry.location.lat();
    const longitude = addressData.geometry.location.lng();
    return {
        street1,
        street2,
        subpremise,
        city,
        state,
        country,
        zip,
        latitude,
        longitude,
    };
};
