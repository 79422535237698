import { Grid } from 'src/components/mui';
import { KeyboardArrowRight } from 'src/components/mui/icons';
import React from 'react';
import Indicator from 'src/components/layout/Indicator';
import { SimpleDrawerContent } from 'src/components/SimpleDrawer';
import { colors } from 'src/styles/theme';
import { NotesTypes } from '../constants';
import { NotesDrawerBtn } from './AddNotesDrawer.styles';
const AddNotesOptionsDrawer = ({ onSelect, isAutoIntegrateFMC, remaining3Notes = 0, }) => {
    const exclude = isAutoIntegrateFMC ? 'CUSTOMER_FACING' : 'FMC_FACING';
    return (<SimpleDrawerContent>
      <Grid container>
        {Object.keys(NotesTypes.values)
            .filter((type) => type !== exclude)
            .map((item, index) => (<NotesDrawerBtn key={index} onClick={() => onSelect(NotesTypes[item])}>
              <Grid container>
                <Grid item xs>
                  <div style={{
                display: 'flex',
                flexWrap: 'wrap',
            }}>
                    {NotesTypes.values[item]}
                    {remaining3Notes > 0 &&
                item === NotesTypes.CONCERN_CAUSE_CORRECTION ? (<span style={{ paddingLeft: '5px' }}>
                        <Indicator value={`${remaining3Notes.toString()} Remaining`} color={colors.primary} text={colors.white}/>
                      </span>) : null}
                  </div>
                </Grid>
                <Grid>
                  <KeyboardArrowRight />
                </Grid>
              </Grid>
            </NotesDrawerBtn>))}
      </Grid>
    </SimpleDrawerContent>);
};
export default AddNotesOptionsDrawer;
