import React, { useState, useEffect, Fragment } from 'react';
import { Chip } from 'src/components/mui';
import { generateTargets } from './webhookUtils';
const TargetsFilter = ({ setTargets }) => {
    const allTargets = generateTargets();
    const [selectedTargets, setSelectedTargets] = useState([]);
    const toggleTarget = (target) => {
        const updatedTargets = [...selectedTargets];
        const index = updatedTargets.indexOf(target);
        if (index >= 0) {
            updatedTargets.splice(index, 1);
        }
        else {
            updatedTargets.push(target);
        }
        setSelectedTargets(updatedTargets);
    };
    useEffect(() => {
        setTargets([...selectedTargets]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTargets]);
    const pills = allTargets.map((target, index) => {
        return (<Chip key={target} label={target} variant={selectedTargets.includes(target) ? 'filled' : 'outlined'} onClick={() => toggleTarget(target)} style={{ marginLeft: index === 0 ? '30px' : '10px' }} color="default"/>);
    });
    return <Fragment key="target-filter-pills">{pills}</Fragment>;
};
export default TargetsFilter;
