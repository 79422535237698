import React from 'react';
import { TableRow, TableCell, Table, TableHead, TableContainer, TableBody, Paper, } from 'src/components/mui';
import { Edit as EditIcon } from 'src/components/mui/icons';
import SmallIconButton from 'src/components/icons/SmallIconButton';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
const ColumnHeader = styled(TableHead) `
  background-color: ${colors.GRAY_DE};
`;
const Resolutions = ({ resolutions, onEdit }) => {
    return (<TableContainer component={Paper}>
      <Table>
        <ColumnHeader>
          <TableRow>
            <TableCell>Issuer</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Specific Reason</TableCell>
            <TableCell>Map To</TableCell>
            <TableCell>Visibility</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </ColumnHeader>
        <TableBody>
          {resolutions.length > 0 &&
            (resolutions === null || resolutions === void 0 ? void 0 : resolutions.map((resolution) => {
                var _a;
                return (<SimpleTableRow key={resolution.id} data={[
                        resolution === null || resolution === void 0 ? void 0 : resolution.issuer,
                        resolution === null || resolution === void 0 ? void 0 : resolution.reason,
                        resolution === null || resolution === void 0 ? void 0 : resolution.specificReason,
                        resolution === null || resolution === void 0 ? void 0 : resolution.mapTo,
                        (_a = resolution === null || resolution === void 0 ? void 0 : resolution.visibility) === null || _a === void 0 ? void 0 : _a.join(', '),
                        <SmallIconButton aria-label="Edit Resolution" title="Edit" onClick={() => onEdit(resolution)}>
                    <EditIcon />
                  </SmallIconButton>,
                    ]}/>);
            }))}
        </TableBody>
      </Table>
    </TableContainer>);
};
export default Resolutions;
