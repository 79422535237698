import React from 'react';
import { Grid, Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from 'src/components/mui';
import { UnfoldMore as ExpandIcon, UnfoldLess as CollapseIcon, } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
import SnapshotEventLogItem from './SnapshotEventLogItem';
import SnapshotBrowserContext from './context';
const Table = styled(MuiTable) `
  & tr {
    vertical-align: middle;
  }
  & td {
    padding: 6px 10px;
  }
  & th {
    padding: 6px 10px;
    vertical-align: middle;
  }
`;
const ColumnHeader = styled(TableRow) `
  background-color: ${colors.GRAY_EE};
  cursor: pointer;
`;
const IconButton = styled.div `
  cursor: pointer;
  transform: rotate(90deg);
  padding-top: 10px;
`;
const SnapshotEventLog = ({ referenceNum, snapshots, selectedSnapshotId, selectSnapshot, }) => {
    return (<SnapshotBrowserContext.Consumer>
      {({ isExpanded, toggleExpanded }) => (<Grid container>
          <Grid item>
            {referenceNum && (<TableContainer component={Paper}>
                <Table className={` ${isExpanded && 'expanded'}`} size="small">
                  <TableHead>
                    <ColumnHeader onClick={toggleExpanded}>
                      <TableCell>v#</TableCell>
                      {isExpanded && (<TableCell>Snapshot Log for {referenceNum}</TableCell>)}
                      <TableCell>
                        <IconButton>
                          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                        </IconButton>
                      </TableCell>
                    </ColumnHeader>
                  </TableHead>
                  <TableBody>
                    {snapshots === null || snapshots === void 0 ? void 0 : snapshots.map((snapshot) => snapshot.eventLogs.map((event) => (<SnapshotEventLogItem key={event.id} snapshot={snapshot} event={event} isSelected={selectedSnapshotId === snapshot.id} selectSnapshot={selectSnapshot}/>)))}
                  </TableBody>
                </Table>
              </TableContainer>)}
          </Grid>
        </Grid>)}
    </SnapshotBrowserContext.Consumer>);
};
export default SnapshotEventLog;
