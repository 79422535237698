import styled from 'styled-components';
export const StatusesContainer = styled.div `
  display: flex;
  justify-content: ${(props) => { var _a; return (_a = props.justifyContent) !== null && _a !== void 0 ? _a : 'space-around'; }};
  margin-bottom: 10px;
`;
export const StatusItem = styled.span `
  font-size: 12px;
  font-weight: bold;
  margin-right: 20px;
  display: flex;
  align-items: center;
`;
export const StatusPill = styled.div `
  width: 15px;
  height: 8px;
  margin-right: 5px;
  border-radius: 500px;
  background-color: ${(props) => { var _a; return (_a = props.color) !== null && _a !== void 0 ? _a : '#000'; }};
`;
