import { Grid, IconButton, TextField } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { AddCircle } from 'src/components/mui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { selectSelectedMeasurement } from 'src/PricingApp/modules/repairs/selectors';
import { MeasurementItem } from './MeasurementItem';
import { ButtonRow } from './Measurements.styles';
export const MeasurementDrawer = ({ open, closeHandler, onMeasurementSubmit, }) => {
    const measurement = useSelector(selectSelectedMeasurement);
    const [groupedMeasurements, setGroupedMeasurements] = useState([]);
    const [displayName, setDisplayName] = useState(measurement.name);
    const [aiProcess, setAiProcess] = useState(measurement.autointegrateType);
    useEffect(() => {
        setDisplayName(measurement.name);
        setAiProcess(measurement.autointegrateType);
    }, [measurement]);
    useEffect(() => {
        var _a;
        const grouped = groupByToMap((_a = measurement.items) !== null && _a !== void 0 ? _a : [], (m) => m.description);
        setGroupedMeasurements(Array.from(grouped.entries())
            .sort((e1, e2) => e1[1][0].displayOrder - e2[1][0].displayOrder)
            .map((e) => ({
            description: e[0],
            measurements: e[1].sort((p1, p2) => p1.displayOrder - p2.displayOrder),
        })));
    }, [measurement]);
    const groupByToMap = (array, predicate) => array.reduce((map, value, index, array) => {
        var _a, _b;
        const key = predicate(value, index, array);
        // eslint-disable-next-line no-unused-expressions
        (_b = (_a = map.get(key)) === null || _a === void 0 ? void 0 : _a.push(Object.assign(Object.assign({}, value), { index }))) !== null && _b !== void 0 ? _b : map.set(key, [Object.assign(Object.assign({}, value), { index })]);
        return map;
    }, new Map());
    const addMeasurementSubset = (index = -1) => () => {
        if (index < 0) {
            setGroupedMeasurements([
                ...groupedMeasurements,
                {
                    description: '',
                    measurements: [
                        {
                            optional: false,
                            unit: 'INCHES',
                        },
                    ],
                },
            ]);
        }
        else {
            groupedMeasurements[index].measurements.push({
                optional: false,
                unit: 'INCHES',
            });
            setGroupedMeasurements([...groupedMeasurements]);
        }
    };
    const onRemoveMeasurement = (index) => () => {
        groupedMeasurements.splice(index, 1);
        setGroupedMeasurements([...groupedMeasurements]);
    };
    const onSelectAllCheckboxes = (descriptionIndex) => (checked) => {
        const newGrouped = groupedMeasurements;
        newGrouped[descriptionIndex].measurements.forEach((m) => {
            m.optional = checked;
        });
        setGroupedMeasurements([...newGrouped]);
    };
    const updateField = (descriptionIndex) => (index, field, value) => {
        if (field === 'description') {
            groupedMeasurements[descriptionIndex].description = value.toString();
        }
        else {
            groupedMeasurements[descriptionIndex].measurements[index][field] =
                value;
        }
        setGroupedMeasurements([...groupedMeasurements]);
    };
    const updateProcess = (process) => {
        setAiProcess(process);
    };
    const onSave = () => {
        if (!onMeasurementSubmit)
            return;
        let currentDisplayOrder = 0;
        onMeasurementSubmit(Object.assign(Object.assign({}, measurement), { autointegrateType: aiProcess, name: displayName, items: groupedMeasurements.flatMap((group) => group.measurements.map((m) => (Object.assign(Object.assign({}, m), { description: group.description, 
                // eslint-disable-next-line no-plusplus
                displayOrder: currentDisplayOrder++ })))), created: undefined, createdBy: undefined, modified: undefined, modifiedBy: undefined }));
    };
    return (<SimpleDrawer open={open} onClose={closeHandler} size="half" title="Measurement Requirements">
      <SimpleDrawerContent>
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          <Grid item xs={12}>
            <TextField variant="outlined" value={displayName} onChange={(e) => setDisplayName(e.target.value)} type="text" name="Name" label="Name" fullWidth/>
          </Grid>
        </Grid>
        <div>
          {groupedMeasurements &&
            groupedMeasurements.map((gm, index) => (<MeasurementItem onSetValue={updateField(index)} description={gm.description} measurements={gm.measurements} onAddSubset={addMeasurementSubset(index)} onRemove={onRemoveMeasurement(index)} selectAllChecks={onSelectAllCheckboxes(index)} aiProcess={aiProcess} onUpdateProcess={updateProcess}/>))}
          <ButtonRow>
            <IconButton color="primary" edge="end" onClick={addMeasurementSubset()}>
              <Tooltip title="Add new Measurement">
                <AddCircle style={{ fontSize: 70 }}/>
              </Tooltip>
            </IconButton>
          </ButtonRow>
        </div>
      </SimpleDrawerContent>

      <SimpleDrawerFooter>
        <FooterActionButton onClick={onSave}>Save</FooterActionButton>
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
