import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, TextField } from 'src/components/mui';
import PlacesAutocomplete from 'src/components/form/PlacesAutocomplete';
import { colors, theme } from 'src/styles/theme';
import SimpleModal from 'src/components/SimpleModal';
const AddressError = styled.div `
  color: ${colors.error};
  margin-top: 6px;
  font-size: 12px;

  ${theme.breakpoints.down('sm')} {
    position: relative;
    font-size: 14px;
  }
`;
const DEFAULT_ADDRESS = {
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    latitude: undefined,
    longitude: undefined,
    timezone: '',
};
const AddressFormModal = ({ open, onSubmit, address, disableEdit, disableEditReason, onClose, secondLine, title, }) => {
    const [innerAddress, setInnerAddress] = useState(address);
    const [addressError, setAddressError] = useState(false);
    const [secondLineEdit, setSecondLineEdit] = useState(secondLine !== null && secondLine !== void 0 ? secondLine : null);
    const handleAddressChange = () => {
        setAddressError(true);
        setInnerAddress(Object.assign({}, DEFAULT_ADDRESS));
    };
    const handleAddressSelect = (updatedAddress) => {
        if (!updatedAddress) {
            setInnerAddress(Object.assign({}, DEFAULT_ADDRESS));
            setAddressError(false);
            return;
        }
        const hasError = !(updatedAddress === null || updatedAddress === void 0 ? void 0 : updatedAddress.zip) || !(updatedAddress === null || updatedAddress === void 0 ? void 0 : updatedAddress.streetNumber);
        setAddressError(hasError);
        setInnerAddress(updatedAddress);
    };
    const onSecondLineChange = (e) => setSecondLineEdit(e.target.value);
    const onFormSubmit = () => {
        let updatedAddress;
        if (typeof innerAddress === 'string') {
            const [street, city, state, zip] = innerAddress
                .split(',')
                .map((a) => a.trim());
            updatedAddress = { street, city, state, zip };
        }
        else {
            updatedAddress = innerAddress;
        }
        onSubmit(Object.assign({ street2: secondLineEdit }, updatedAddress));
    };
    return (<SimpleModal open={open} onClose={onClose} title={title} fullWidth simpleTwoButtonRowProps={{
            onCancel: onClose,
            onSubmit: onFormSubmit,
            disabledSubmit: addressError,
        }}>
      <PlacesAutocomplete disabled={disableEdit} disabledReason={disableEditReason} address={innerAddress} onSelect={handleAddressSelect} onChange={handleAddressChange} displayMap/>
      {addressError && (<AddressError>
          Enter a valid street address including zip code and street number
        </AddressError>)}

      {secondLine != null && (<Grid container spacing={2} style={{ marginTop: 8 }}>
          <Grid item xs={12}>
            <TextField fullWidth label="Address Line 2" name="street2" type="text" value={secondLineEdit} onChange={onSecondLineChange} variant="standard"/>
          </Grid>
        </Grid>)}
    </SimpleModal>);
};
export default AddressFormModal;
