import React from 'react';
import styled from 'styled-components';
import { TableRow, TableCell, Typography } from 'src/components/mui';
import useTerritoriesListItem from './useTerritoriesListItem';
const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  td {
    padding: 15px !important;
  }
`;
const StyledDiv = styled.div `
  display: flex;
  flex-direction: column;
`;
const Editable = styled.span `
  cursor: pointer;
`;
export const DashboardListItem = ({ item }) => {
    const { name, metros, archived } = item;
    const { editTerritory, editMetro } = useTerritoriesListItem(item);
    const renderName = (text, isArchived) => {
        if (isArchived) {
            return <span style={{ color: 'red' }}>{text} (Archived)</span>;
        }
        return <span>{text}</span>;
    };
    return (<StyledTableRow>
      <TableCell>
        <Editable onClick={() => editTerritory()}>
          <Typography variant="h6">{renderName(name, archived)}</Typography>
        </Editable>
      </TableCell>
      <TableCell>
        <StyledDiv>
          {(metros !== null && metros !== void 0 ? metros : []).map((metro) => (<Editable key={metro.id} onClick={() => editMetro(metro.id)}>
              {renderName(metro.name, metro.archived)}
            </Editable>))}
        </StyledDiv>
      </TableCell>
    </StyledTableRow>);
};
