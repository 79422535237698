import styled from 'styled-components';
import { theme, colors } from 'src/styles/theme';
export const CapacitySection = styled.section `
  border-radius: 4px;
  border: solid 1px rgba(222, 222, 222, 0.24);
  background-color: rgba(63, 63, 63, 0.01);
  margin: 16px 15px 0 15px;
  padding: 14px 16px 6px 14px;

  ${theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
    padding: 16px;
  }

  .capacityHeader {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_3F};
    margin: 0 0 3px;

    ${theme.breakpoints.up('md')} {
      padding-right: 8px;
      margin-right: 8px;
      border-right: solid 1px rgba(222, 222, 222, 0.24);
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    display: inline-block;
    margin: 0;

    li {
      float: left;
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.08px;
      color: ${colors.GRAY_76};

      &:not(:last-child) {
        margin-right: 6px;
      }

      strong {
        font-weight: bold;
        color: ${colors.GRAY_3F};
      }
    }
  }
`;
