var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Cookies from 'js-cookie';
import createAPIAction, { createAPIAuthAction, } from 'src/modules/api';
import { selectCurrentUrl, selectMfaRequired, selectUserEmail, } from 'src/modules/auth/selectors';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import CONFIG from 'src/config';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createAction from 'src/modules/api/utils/createAction';
import { clearRedirectUrl, getCookieDomainAttrs, resolveRedirectUrl, INTERNAL_ACCESS_TOKEN, INTERNAL_REFRESH_TOKEN, storeRedirectUrl, } from './utils';
import { toast } from 'src/components/SimpleToast';
// FSA ACTIONS
export const ActionCreators = {
    AUTH: createDefaultActionCreators('AUTH', ['LOGOUT']),
    REFRESH_TOKEN: createDefaultActionCreators('REFRESH_TOKEN'),
    VERIFY_MFA: createDefaultActionCreators('VERIFY_MFA'),
    RESEND_MFA: createDefaultActionCreators('RESEND_MFA'),
    SET_ACCESS_TOKEN: createDefaultActionCreators('SET_ACCESS_TOKEN'),
    GET_SELF: createDefaultActionCreators('GET_SELF'),
    FORGOT_PASSWORD: createDefaultActionCreators('FORGOT_PASSWORD'),
    RESET_FORGOTTEN_PASSWORD: createDefaultActionCreators('RESET_FORGOTTEN_PASSWORD'),
};
const setAccessToken = createAction('SET_ACCESS_TOKEN');
const push = (href) => {
    window.location.href = href;
};
const login = ({ email, password }) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        const redirectUrl = resolveRedirectUrl();
        const actionResponse = yield dispatch(createAPIAuthAction({
            types: [
                ActionCreators.AUTH.FETCH(),
                ActionCreators.AUTH.SUCCESS({
                    meta: () => ({ email }),
                }),
                ActionCreators.AUTH.FAILURE(),
            ],
            endpoint: API_URLS.AUTH.LOGIN(),
            method: 'POST',
            body: { email, password },
        }));
        if (actionResponse.error) {
            // the last dispatched action has errored, break out of the promise chain.
            toast.error(((_b = (_a = actionResponse.payload) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.message) ||
                ((_c = actionResponse.payload) === null || _c === void 0 ? void 0 : _c.message));
            return;
        }
        const mfaRequired = selectMfaRequired(getState());
        if (mfaRequired) {
            push('/login/mfa');
        }
        else {
            clearRedirectUrl();
            push(redirectUrl);
        }
    });
};
const logout = ({ isReAuth = false, history }) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        const domain = getCookieDomainAttrs();
        Cookies.remove(INTERNAL_ACCESS_TOKEN, Object.assign({}, domain));
        Cookies.remove(INTERNAL_REFRESH_TOKEN, Object.assign({}, domain));
        const actionResponse = yield dispatch(ActionCreators.AUTH.LOGOUT());
        if (actionResponse.error) {
            // the last dispatched action has errored, break out of the promise chain.
            throw new Error(`Promise flow received action error: ${actionResponse}`);
        }
        const pathname = window.location.pathname;
        if (pathname === '/login' && isReAuth) {
            return;
        }
        if (history && pathname !== '/login') {
            history.push('/login');
            return;
        }
        push(isReAuth ? '/login' : '/');
    });
};
const getSelf = () => createAPIAction({
    // GET_SELF
    types: [
        ActionCreators.GET_SELF.FETCH(),
        ActionCreators.GET_SELF.SUCCESS(),
        ActionCreators.GET_SELF.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.ADMIN_USER_SELF(),
    method: 'GET',
});
const forgotPassword = ({ email }) => createAPIAction({
    types: fillApiTypes(ActionCreators.FORGOT_PASSWORD),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/reset-password`,
    body: { email },
    method: 'PUT',
});
const resetForgottenPassword = ({ password, confirmPassword, userId, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.RESET_FORGOTTEN_PASSWORD),
    endpoint: `${CONFIG.CLIENT_API_URL}/isp-service/reset-forgotten-password`,
    body: { password, confirmPassword, userId },
    method: 'PUT',
});
const forceReAuthentication = () => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const url = selectCurrentUrl(getState());
        if (url !== '/' && url !== '/login') {
            storeRedirectUrl(url);
        }
        return dispatch(logout({ isReAuth: true }));
    });
};
const refreshAuth = (refreshToken) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        const actionResponse = yield dispatch(createAPIAuthAction({
            types: [
                ActionCreators.REFRESH_TOKEN.FETCH(),
                ActionCreators.REFRESH_TOKEN.SUCCESS(),
                ActionCreators.REFRESH_TOKEN.FAILURE(),
            ],
            endpoint: API_URLS.AUTH.REFRESH_TOKEN(),
            method: 'PUT',
            body: { refreshToken },
        }));
        if (actionResponse.error) {
            const errorMessage = `Failed to refresh auth token:\n${(_a = actionResponse.payload) === null || _a === void 0 ? void 0 : _a.message}\n${(_c = (_b = actionResponse.payload) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.message}`;
            throw new Error(errorMessage);
        }
        return actionResponse;
    });
};
const resendMfa = () => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const userEmail = selectUserEmail(getState());
        const actionResponse = yield dispatch(createAPIAuthAction({
            types: [
                ActionCreators.RESEND_MFA.SUCCESS(),
                ActionCreators.RESEND_MFA.FETCH(),
                ActionCreators.RESEND_MFA.FAILURE(),
            ],
            endpoint: API_URLS.AUTH.RESEND_MFA(),
            method: 'POST',
            body: { email: userEmail },
        }));
        if (actionResponse.error) {
            // the last dispatched action has errored, break out of the promise chain.
            throw new Error(`Promise flow received action error: ${actionResponse}`);
        }
    });
};
const verifyMfa = (mfaCode) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const userEmail = selectUserEmail(getState());
        const actionResponse = yield dispatch(createAPIAuthAction({
            types: [
                ActionCreators.VERIFY_MFA.FETCH(),
                ActionCreators.VERIFY_MFA.SUCCESS(),
                ActionCreators.VERIFY_MFA.FAILURE(),
            ],
            endpoint: API_URLS.AUTH.VERIFY_MFA(),
            method: 'POST',
            body: { email: userEmail, code: mfaCode },
        }));
        if (actionResponse.error) {
            // the last dispatched action has errored, break out of the promise chain.
            throw new Error(`Promise flow received action error: ${actionResponse}`);
        }
        const redirectUrl = resolveRedirectUrl();
        clearRedirectUrl();
        push(redirectUrl);
    });
};
export { setAccessToken, login, logout, getSelf, forgotPassword, forceReAuthentication, resetForgottenPassword, refreshAuth, verifyMfa, resendMfa, };
