var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEmpty, isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'src/components/SimpleToast';
import { selectMakes, selectVehiclesByPlate, selectVehiclesByVin, } from 'src/AdminApp/modules/autoselection/selectors';
import { selectContinueDisabled, selectFleet, selectUser, } from 'src/AdminApp/modules/quickRo/selectors';
import { selectRequestDataByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { getVehicleDescriptionByPlate, getVehicleDescriptionByVin, } from 'src/AdminApp/modules/autoselection/actions';
import { searchCars } from 'src/AdminApp/modules/fleets/actions';
import { usePrevious } from 'src/components/SimpleDrawerPaged/utils';
import { normalizeVehicle, onDecodedVehicleSelect, } from 'src/AdminApp/containers/vehicle/CarModule/utils';
import { CAR_MSG, PLATE_MAX_NUMBER_LENGTH, PLATE_NUMBER_LENGTH, VIN_LENGTH, } from 'src/AdminApp/modules/api/constants';
import { validateVinOrPlate } from 'src/AdminApp/modules/consumers/actions';
import { setContinueDisabled } from 'src/AdminApp/modules/quickRo/actions';
const defaultState = {
    engineDesc: '',
    engineOptions: [],
    mileage: '',
    model: null,
    modelId: undefined,
    nickname: '',
    trimId: undefined,
    trimOptions: [],
    vin: '',
    notFoundVin: false,
};
const useCarForm = ({ vehicle, onChange, setIsSelectedCarVin, editMode, isFleet, handleVinExists, childrenFleetIds, }) => {
    var _a;
    const dispatch = useDispatch();
    let { consumerId } = useParams();
    const { referenceNum } = useParams();
    const makes = useSelector(selectMakes);
    const vehiclesByVin = useSelector(selectVehiclesByVin);
    const vehiclesByPlate = useSelector(selectVehiclesByPlate);
    const fleet = useSelector(selectFleet);
    const quickROConsumer = useSelector(selectUser);
    const quickROIsDisabled = useSelector(selectContinueDisabled);
    const requestData = useSelector(selectRequestDataByReferenceNum(referenceNum));
    if (!consumerId || consumerId === 'undefined') {
        consumerId = String(quickROConsumer === null || quickROConsumer === void 0 ? void 0 : quickROConsumer.id);
    }
    if (!consumerId || consumerId === 'undefined') {
        consumerId = (_a = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) === null || _a === void 0 ? void 0 : _a.consumerId;
    }
    const [stateVehicle, setStateVehicle] = useState(Object.assign(Object.assign({}, defaultState), Object.assign({}, vehicle)));
    const [isVinFull, setIsVinFull] = useState(false);
    const [isPlateFull, setIsPlateFull] = useState(false);
    const [showDecodePlateAlert, setShowDecodePlateAlert] = useState(false);
    const [isDecodedCarSelected, setIsDecodedCarSelected] = useState(false);
    const [vinError, setVinError] = useState();
    const [plateError, setPlateError] = useState();
    const [mileageOutError, setMileageOutError] = useState();
    const [shouldUpdateCarFieldsVehicle, setShouldUpdateCarFieldsVehicle] = useState(false);
    const [isLicensePlateOnFocus, setIsLicensePlateOnFocus] = useState(false);
    const [decodedVehicle, setDecodedVehicle] = useState();
    const [isVehicleChangingOldCarRequest, setIsVehicleChangingOldCarRequest] = useState(false);
    const [confirmationNewVehicleText, setConfirmationNewVehicleText] = useState([]);
    const [validating, setValidating] = useState(false);
    const prevState = usePrevious(stateVehicle);
    const updateStateVehicle = (values) => {
        setStateVehicle(Object.assign(Object.assign({}, stateVehicle), values));
    };
    useEffect(() => {
        var _a, _b;
        setIsVinFull(!isEmpty(vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin) && ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin) === null || _a === void 0 ? void 0 : _a.length) === VIN_LENGTH);
        setIsPlateFull(!isEmpty(vehicle === null || vehicle === void 0 ? void 0 : vehicle.plateNumber) &&
            ((_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.plateNumber) !== null && _b !== void 0 ? _b : '').length >= PLATE_NUMBER_LENGTH);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle, fleet]);
    useEffect(() => {
        const normalizedData = normalizeVehicle(stateVehicle, makes);
        if (!isEqual(stateVehicle, prevState)) {
            onChange(Object.assign(Object.assign({}, stateVehicle), normalizedData));
        }
    }, [stateVehicle, prevState, makes, onChange]);
    useEffect(() => {
        var _a;
        if (validating)
            return;
        if (((_a = stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.vin) === null || _a === void 0 ? void 0 : _a.length) !== VIN_LENGTH)
            return;
        if (isDecodedCarSelected && decodedVehicle)
            return;
        if (vinError)
            return;
        if (setIsSelectedCarVin)
            setIsSelectedCarVin(false);
        onDecodeVin();
    }, [stateVehicle.vin, editMode, validating]); // eslint-disable-line
    useEffect(() => {
        var _a, _b;
        if (!!(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumberState) &&
            ((_b = (_a = stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumber) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) >= PLATE_NUMBER_LENGTH &&
            !isDecodedCarSelected) {
            if (setIsSelectedCarVin)
                setIsSelectedCarVin(false);
            onDecodePlate();
        }
    }, [stateVehicle.plateNumber, stateVehicle.plateNumberState]); // eslint-disable-line
    useEffect(() => {
        if (isFleet)
            return;
        const vinIsInvalid = !!(vinError === null || vinError === void 0 ? void 0 : vinError.length) &&
            [CAR_MSG.VEHICLE_EXISTS, CAR_MSG.VIN_CHARACTER_LENGTH].includes(vinError);
        const plateIsInvalid = !!(plateError === null || plateError === void 0 ? void 0 : plateError.length) && [CAR_MSG.VEHICLE_EXISTS].includes(plateError);
        const isDisabled = validating || vinIsInvalid || plateIsInvalid;
        if (quickROIsDisabled !== isDisabled) {
            dispatch(setContinueDisabled(isDisabled));
        }
    }, [vinError, plateError, stateVehicle, validating]); // eslint-disable-line
    const selectCar = (selectedVehicle) => __awaiter(void 0, void 0, void 0, function* () {
        if (!(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.vin))
            return;
        if (!selectedVehicle) {
            selectedVehicle =
                decodedVehicle ||
                    ((stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumber)
                        ? vehiclesByPlate[stateVehicle.plateNumber]
                        : vehiclesByVin[stateVehicle.vin]);
        }
        const result = yield onDecodedVehicleSelect(selectedVehicle, dispatch);
        updateStateVehicle(Object.assign({}, result));
        setDecodedVehicle(selectedVehicle);
        if (setIsSelectedCarVin)
            setIsSelectedCarVin(true);
        setShouldUpdateCarFieldsVehicle(true);
        toast.success('Vehicle selected.');
    });
    const isSelectedVehicleValid = (selectedVehicle) => {
        return (selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.vin) !== null;
    };
    const validateSelectedVehicle = (selectedVehicle) => __awaiter(void 0, void 0, void 0, function* () {
        const errors = [];
        if ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.year) && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.year) !== Number(selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.year)) {
            errors.push(`New year is: ${selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.year}`);
        }
        if ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.make) && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.make) !== (selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.make)) {
            errors.push(`New make is: ${selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.make}`);
        }
        if ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.model) && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.model) !== (selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.model)) {
            errors.push(`New model is: ${selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.model}`);
        }
        if ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.engineDescription) &&
            (vehicle === null || vehicle === void 0 ? void 0 : vehicle.engineDescription) !== (selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.engineDescription)) {
            errors.push(`New engine is: ${selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.engineDescription}`);
        }
        if ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.trim) && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.trim) !== (selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.style)) {
            errors.push(`New trim is: ${selectedVehicle === null || selectedVehicle === void 0 ? void 0 : selectedVehicle.style}`);
        }
        if (errors.length > 0) {
            setConfirmationNewVehicleText(errors);
            setIsVehicleChangingOldCarRequest(true);
        }
        if (errors && errors.length === 0) {
            selectCar(selectedVehicle);
        }
    });
    const handleSelectDecodedVehicle = (selectedVehicle) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        if (!(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.vin))
            return;
        if (!selectedVehicle && (stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.vin)) {
            const result = yield dispatch(getVehicleDescriptionByVin({ vin: stateVehicle.vin }));
            if ((result === null || result === void 0 ? void 0 : result.error) || ((_b = result === null || result === void 0 ? void 0 : result.payload) === null || _b === void 0 ? void 0 : _b.status) === 404) {
                setVinError(CAR_MSG.VIN_NOT_EXIST);
                setDecodedVehicle(undefined);
                return;
            }
            setVinError(undefined);
            selectedVehicle = result.payload;
        }
        if (selectedVehicle) {
            setDecodedVehicle(selectedVehicle);
            validateSelectedVehicle(selectedVehicle);
        }
        setShouldUpdateCarFieldsVehicle(true);
    });
    const onDecodeVin = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e;
        if (!(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.vin))
            return;
        let selectedVehicle = vehiclesByVin[stateVehicle.vin];
        if (!isSelectedVehicleValid(selectedVehicle)) {
            setVinError(CAR_MSG.VIN_CANNOT_BE_DECODED);
            return;
        }
        const result = yield dispatch(getVehicleDescriptionByVin({ vin: stateVehicle.vin }));
        if ((result === null || result === void 0 ? void 0 : result.error) ||
            ((_c = result === null || result === void 0 ? void 0 : result.payload) === null || _c === void 0 ? void 0 : _c.vin) == null ||
            ((_e = (_d = result === null || result === void 0 ? void 0 : result.payload) === null || _d === void 0 ? void 0 : _d.status) !== null && _e !== void 0 ? _e : null) === 404) {
            updateStateVehicle({
                notFoundVin: true,
            });
            setVinError(CAR_MSG.VIN_CANNOT_BE_DECODED);
            return;
        }
        updateStateVehicle({
            notFoundVin: false,
        });
        setVinError(undefined);
        selectedVehicle = result.payload;
        setDecodedVehicle(selectedVehicle);
        setIsDecodedCarSelected(true);
        handleSelectDecodedVehicle(selectedVehicle);
    });
    const onDecodePlate = () => __awaiter(void 0, void 0, void 0, function* () {
        var _f, _g, _h, _j;
        if (!(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumber))
            return;
        let selectedVehicle = vehiclesByPlate[stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumber];
        if ((stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumber) && !selectedVehicle) {
            const result = yield dispatch(getVehicleDescriptionByPlate({
                plate: (_f = stateVehicle.plateNumber) !== null && _f !== void 0 ? _f : '',
                state: (_g = stateVehicle.plateNumberState) !== null && _g !== void 0 ? _g : '',
            }));
            if ((result === null || result === void 0 ? void 0 : result.error) || ((_j = (_h = result === null || result === void 0 ? void 0 : result.payload) === null || _h === void 0 ? void 0 : _h.status) !== null && _j !== void 0 ? _j : null) === 404) {
                setPlateError(CAR_MSG.PLATE_NOT_EXIST);
                return;
            }
            selectedVehicle = result.payload;
        }
        if (!selectedVehicle) {
            setPlateError(CAR_MSG.PLATE_NOT_EXIST);
            return;
        }
        setIsDecodedCarSelected(true);
        handleSelectDecodedVehicle(selectedVehicle);
        updateStateVehicle({
            vin: selectedVehicle.vin,
        });
        setPlateError(undefined);
        setVinError(undefined);
    });
    const checkValidation = ({ consumerId, vin, plate, state, }) => __awaiter(void 0, void 0, void 0, function* () {
        let params;
        setValidating(true);
        if (vin === null || vin === void 0 ? void 0 : vin.length) {
            params = {
                consumerId,
                vin,
            };
        }
        if (plate === null || plate === void 0 ? void 0 : plate.length) {
            params = {
                consumerId,
                plate,
                state,
            };
        }
        const validationResponse = yield dispatch(validateVinOrPlate(params));
        if (validationResponse.payload) {
            dispatch(setContinueDisabled(true));
        }
        if (validationResponse.payload && (vin === null || vin === void 0 ? void 0 : vin.length)) {
            setVinError(CAR_MSG.VEHICLE_EXISTS);
        }
        if (validationResponse.payload && (state === null || state === void 0 ? void 0 : state.length)) {
            setPlateError(CAR_MSG.VEHICLE_EXISTS);
        }
        setValidating(false);
    });
    const onFieldChange = (field) => ({ target }) => __awaiter(void 0, void 0, void 0, function* () {
        var _k, _l, _m, _o, _p;
        let { value } = target;
        if (field === 'vin') {
            setIsDecodedCarSelected(false);
            setVinError(undefined);
            value = value.replace(/o/gi, '0');
            if (value.length === VIN_LENGTH) {
                setIsVinFull(true);
                if (isFleet) {
                    dispatch(searchCars({
                        fleetId: fleet ? fleet.id : childrenFleetIds[0],
                        vin: value,
                    })).then((res) => {
                        const vinExists = res.payload.totalElements > 0;
                        if (vinExists)
                            setVinError(CAR_MSG.VIN_ALREADY_IN_DB);
                        if (handleVinExists)
                            handleVinExists(vinExists);
                    });
                }
                else {
                    checkValidation({ consumerId, vin: value });
                }
            }
            if ((value === null || value === void 0 ? void 0 : value.length) !== VIN_LENGTH) {
                setIsVinFull(false);
            }
        }
        if (field === 'plateNumber') {
            setPlateError(undefined);
            setIsPlateFull(value.length >= PLATE_NUMBER_LENGTH);
            if (!isFleet &&
                stateVehicle.plateNumberState &&
                value.length <= PLATE_MAX_NUMBER_LENGTH &&
                value.length >= PLATE_NUMBER_LENGTH) {
                checkValidation({
                    consumerId,
                    plate: value,
                    state: stateVehicle.plateNumberState,
                });
            }
        }
        if (field === 'plateNumberState') {
            if (!isFleet &&
                ((_l = (_k = stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.plateNumber) === null || _k === void 0 ? void 0 : _k.length) !== null && _l !== void 0 ? _l : 0) >= PLATE_NUMBER_LENGTH &&
                value.length) {
                checkValidation({
                    consumerId,
                    plate: stateVehicle.plateNumber,
                    state: value,
                });
            }
        }
        if (field === 'mileage' || field === 'mileageOut') {
            value = value.replace(/\D/g, '');
        }
        if (!isFleet && editMode && field === 'mileage') {
            setMileageOutError(undefined);
            if (((_m = stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.mileageOut) !== null && _m !== void 0 ? _m : 0) < +value) {
                setMileageOutError(CAR_MSG.MILEAGE_OUT_LESS_THAN_MILEAGE_IN);
            }
            if (isEmpty(value)) {
                value = null;
            }
        }
        if (!isFleet && editMode && field === 'mileageOut') {
            setMileageOutError(undefined);
            if (!isEmpty(value) && +value < ((_o = stateVehicle.mileage) !== null && _o !== void 0 ? _o : 0)) {
                setMileageOutError(CAR_MSG.MILEAGE_OUT_LESS_THAN_MILEAGE_IN);
            }
            if (isEmpty(value)) {
                value = null;
            }
        }
        if (field === 'fleetResponsibleParties') {
            const udpatedResponsibleParties = updateResponsibleParties((_p = stateVehicle.fleetResponsibleParties) !== null && _p !== void 0 ? _p : [], value);
            updateStateVehicle({
                [field]: udpatedResponsibleParties,
                fleetResponsiblePartyIds: value,
            });
            return;
        }
        updateStateVehicle({
            [field]: value,
        });
    });
    const updateResponsibleParties = (currentResponsibleParties, value) => {
        const array = value.map((ids) => { var _a; return (_a = ids.split(',')) === null || _a === void 0 ? void 0 : _a[0]; });
        const idsSet = new Set(array);
        currentResponsibleParties = currentResponsibleParties.filter((rp) => idsSet.has(rp.id));
        value.forEach((ids) => {
            var _a;
            const id = (_a = ids.split(',')) === null || _a === void 0 ? void 0 : _a[0];
            const exists = currentResponsibleParties.some((rp) => rp.id === id);
            if (!exists) {
                currentResponsibleParties.push({ id });
            }
        });
        return currentResponsibleParties;
    };
    const handleCancelOverrideCar = () => {
        setIsVehicleChangingOldCarRequest(false);
        setDecodedVehicle(undefined);
        updateStateVehicle({
            vin: '',
            plateNumber: '',
            plateNumberState: '',
        });
    };
    const handleOverrideCar = () => {
        setConfirmationNewVehicleText([]);
        setIsVehicleChangingOldCarRequest(false);
        selectCar(decodedVehicle);
    };
    return {
        makes,
        vehiclesByVin,
        vehiclesByPlate,
        fleet,
        stateVehicle,
        setStateVehicle,
        updateStateVehicle,
        isVinFull,
        setIsVinFull,
        isPlateFull,
        setIsPlateFull,
        showDecodePlateAlert,
        setShowDecodePlateAlert,
        isDecodedCarSelected,
        setIsDecodedCarSelected,
        vinError,
        setVinError,
        plateError,
        setPlateError,
        mileageOutError,
        setMileageOutError,
        shouldUpdateCarFieldsVehicle,
        setShouldUpdateCarFieldsVehicle,
        isLicensePlateOnFocus,
        setIsLicensePlateOnFocus,
        decodedVehicle,
        setDecodedVehicle,
        isVehicleChangingOldCarRequest,
        setIsVehicleChangingOldCarRequest,
        confirmationNewVehicleText,
        setConfirmationNewVehicleText,
        prevState,
        onDecodeVin,
        onDecodePlate,
        selectCar,
        isSelectedVehicleValid,
        validateSelectedVehicle,
        handleSelectDecodedVehicle,
        onFieldChange,
        handleCancelOverrideCar,
        handleOverrideCar,
    };
};
export default useCarForm;
