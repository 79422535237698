var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Typography, MenuItem, Select } from 'src/components/mui';
import { ConfirmationModal } from 'src/components/layout';
import { SimpleDrawerContent, SimpleDrawer, SimpleDrawerFooter, FooterActionButton, } from 'src/components/SimpleDrawer';
import { DrawerFormRow } from 'src/components/SimpleDrawer/SimpleDrawer.styles';
import { isEqual } from 'lodash';
import FleetResponsiblePartySearchSelect from './components/FleetResponsiblePartySearchSelect';
import FleetResponsiblePartyDropdown from './components/FleetResponsiblePartyDropdown';
import useFleetResponsiblePartiesDrawer from './useFleetResponsiblePartiesDrawer';
const FleetResponsiblePartiesDrawer = ({ open, onClose, fleetResponsibleParties = [], fleetId, onSubmit, allResponsibleParties, }) => {
    const { removeResponsiblePartyModalOpen, vehicleSearchOpen, responsiblePartyTypes, responsiblePartiesFromType, removeResponsiblePartyId, showCloseConfirmationModal, handleClose, onResponsiblePartyAdd, onUpdateVehicles, onAutoSubmitUpdate, setVehicleSearchOpen, setRemoveResponsiblePartyId, setRemoveResponsiblePartyModalOpen, fetchResponsiblePartiesByType, setShowCloseConfirmationModal, handleFormSubmit, } = useFleetResponsiblePartiesDrawer({
        open,
        fleetId,
        onClose,
    });
    const validationSchema = Yup.object().shape({
        selectedResponsiblePartyType: Yup.string(),
        responsibleParties: Yup.array(),
    });
    return (<Formik initialValues={{
            selectedResponsiblePartyType: undefined,
            selectedResponsibleParty: undefined,
            responsibleParties: fleetResponsibleParties,
        }} validationSchema={validationSchema} enableReinitialize onSubmit={(values, { setSubmitting, resetForm }) => __awaiter(void 0, void 0, void 0, function* () {
            setSubmitting(true);
            yield handleFormSubmit(values.responsibleParties, onSubmit, resetForm, onClose);
            setSubmitting(false);
        })}>
      {({ values, isSubmitting, isValid, setFieldValue, submitForm, resetForm, }) => {
            return (<SimpleDrawer size="half" open={open} onClose={() => handleClose(!isEqual(values.responsibleParties, fleetResponsibleParties))} title="Manage Responsible Party">
            <SimpleDrawerContent>
              <DrawerFormRow>
                <Typography variant="body1">
                  Select a Responsible Party type
                </Typography>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={values.selectedResponsiblePartyType} onChange={(e) => {
                    const { value } = e.target;
                    setFieldValue('selectedResponsiblePartyType', value);
                    fetchResponsiblePartiesByType(value !== null && value !== void 0 ? value : '');
                }} fullWidth>
                  <MenuItem disabled value="">
                    Responsible Party Type
                  </MenuItem>
                  {responsiblePartyTypes.map((rpt) => (<MenuItem key={rpt.id} value={rpt.id}>
                      {rpt.name}
                    </MenuItem>))}
                </Select>
              </DrawerFormRow>
              <DrawerFormRow>
                <Typography variant="body1">
                  Search and Add Responsible Parties
                </Typography>
                <FleetResponsiblePartySearchSelect responsibleParties={responsiblePartiesFromType.filter((el) => !values.responsibleParties.find((rp) => rp.responsiblePartyId === el.id || rp.id === el.id))} disabled={!values.selectedResponsiblePartyType} onSelect={(responsibleParty) => __awaiter(void 0, void 0, void 0, function* () {
                    var _a;
                    const newData = [...((_a = values.responsibleParties) !== null && _a !== void 0 ? _a : [])];
                    if (responsibleParty) {
                        newData.push(onResponsiblePartyAdd(responsibleParty));
                    }
                    setFieldValue('responsibleParties', newData);
                })}/>
              </DrawerFormRow>
              {values.responsibleParties &&
                    values.responsibleParties
                        .filter((rp) => {
                        var _a;
                        return rp.name ||
                            ((_a = allResponsibleParties === null || allResponsibleParties === void 0 ? void 0 : allResponsibleParties.find((el) => el.id === rp.responsiblePartyId)) === null || _a === void 0 ? void 0 : _a.name);
                    })
                        .map((rp) => {
                        var _a, _b;
                        return rp && (<FleetResponsiblePartyDropdown responsibleParty={Object.assign(Object.assign({}, rp), { name: (_a = rp === null || rp === void 0 ? void 0 : rp.name) !== null && _a !== void 0 ? _a : (_b = allResponsibleParties === null || allResponsibleParties === void 0 ? void 0 : allResponsibleParties.find((el) => el.id === rp.responsiblePartyId)) === null || _b === void 0 ? void 0 : _b.name })} onRemove={(id) => {
                                setRemoveResponsiblePartyId(id);
                                setRemoveResponsiblePartyModalOpen(true);
                            }} onVehiclesUpdate={(id, vehicles) => {
                                onUpdateVehicles(id, vehicles, values.responsibleParties, setFieldValue);
                            }} onAutoSubmitUpdate={(id, autoSubmit) => {
                                onAutoSubmitUpdate(id, autoSubmit, values.responsibleParties, setFieldValue);
                            }} onVehicleSearchOpen={setVehicleSearchOpen}/>);
                    })}
            </SimpleDrawerContent>

            <SimpleDrawerFooter>
              <FooterActionButton disabled={vehicleSearchOpen ||
                    isSubmitting ||
                    !isValid ||
                    isEqual(fleetResponsibleParties, values.responsibleParties)} onClick={submitForm}>
                Save
              </FooterActionButton>
            </SimpleDrawerFooter>
            <ConfirmationModal open={removeResponsiblePartyModalOpen} title="Remove Responsible Party" text="Are you sure you want to remove this Responsible Party?" onClose={() => setRemoveResponsiblePartyModalOpen(false)} action={() => {
                    var _a;
                    setFieldValue('responsibleParties', (_a = values.responsibleParties) === null || _a === void 0 ? void 0 : _a.filter((el) => el.id !== removeResponsiblePartyId));
                    setRemoveResponsiblePartyModalOpen(false);
                    setRemoveResponsiblePartyId(undefined);
                }}/>
            <ConfirmationModal open={showCloseConfirmationModal} text="Are you sure you want to close the drawer without saving your changes?" onClose={() => setShowCloseConfirmationModal(false)} action={() => {
                    resetForm();
                    handleClose(false);
                }}/>
          </SimpleDrawer>);
        }}
    </Formik>);
};
export default FleetResponsiblePartiesDrawer;
