import React from 'react';
import { toast as t } from 'react-toastify';
import toastProps from 'src/components/SimpleToast/defaultToastProps';
import Message from 'src/components/SimpleToast/Message';
const toast = (message, title, options, Component = Message) => {
    return t(<Component message={message} title={title}/>, Object.assign(Object.assign({}, toastProps), options));
};
toast.success = (message, title, options, Component = Message) => {
    return t.success(<Component message={message} title={title}/>, Object.assign(Object.assign({}, toastProps), options));
};
toast.error = (message, title, options, Component = Message) => {
    return t.error(<Component message={message} title={title}/>, Object.assign(Object.assign({}, toastProps), options));
};
toast.info = (message, title, options, Component = Message) => {
    return t.info(<Component message={message} title={title}/>, Object.assign(Object.assign({}, toastProps), options));
};
toast.warn = (message, title, options, Component = Message) => {
    return t.warn(<Component message={message} title={title}/>, Object.assign(Object.assign({}, toastProps), options));
};
export default toast;
