import React from 'react';
import { Modal, CircularProgress } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
const Wrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const Box = styled.div `
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 40px 56px;
  border-radius: 4px;
  box-shadow:
    0 24px 24px 0 rgba(0, 0, 0, 0.3),
    0 0 24px 0 rgba(0, 0, 0, 0.22);
  min-width: 336px;
`;
const Title = styled.h1 `
  padding: 0;
  margin: 0 0 24px;
  color: ${colors.black};
  font-size: 20px;
`;
const LoadingModal = ({ open, title }) => {
    return (<Modal open={open}>
      <Wrapper>
        <Box>
          <Title>{title}</Title>
          <CircularProgress size={60}/>
        </Box>
      </Wrapper>
    </Modal>);
};
export default LoadingModal;
