import { useDispatch, useSelector } from 'react-redux';
import { selectEngineers, selectServiceAdvisors, selectTechnicians, } from 'src/AdminApp/modules/users/selectors';
import { useEffect } from 'react';
import { getEngineers, getServiceAdvisors, getTechnicians, } from 'src/AdminApp/modules/users/actions';
import { isProduction } from 'src/utils/envUtils';
const useTechnicians = () => {
    const dispatch = useDispatch();
    const technicians = useSelector(selectTechnicians);
    const engineers = useSelector(selectEngineers);
    const serviceAdvisors = useSelector(selectServiceAdvisors);
    useEffect(() => {
        if (technicians.length === 0) {
            dispatch(getTechnicians(null, {}));
        }
        if (engineers.length === 0 && !isProduction()) {
            dispatch(getEngineers());
        }
        if (serviceAdvisors.length === 0) {
            dispatch(getServiceAdvisors());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const selectTechnician = (technicianId, by) => {
        const foundTech = technicians.find((t) => t[by] === technicianId);
        if (foundTech)
            return foundTech;
        const foundEngineer = engineers.find((t) => t[by] === technicianId);
        if (foundEngineer)
            return foundEngineer;
    };
    const selectTechnicianById = (technicianId) => {
        return selectTechnician(technicianId, 'id');
    };
    const selectTechnicianByUid = (technicianUid) => {
        return selectTechnician(technicianUid, 'uid');
    };
    return {
        technicians,
        selectTechnicianById,
        selectTechnicianByUid,
    };
};
export default useTechnicians;
