import moment from 'moment/moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAPIKeyValidFrom } from 'src/PartnersAPIApp/modules/partnersApi/actions';
import { getAPIKeyValidFrom } from 'src/PartnersAPIApp/modules/partnersApi/selectors';
import { TIMESTAMP_WITH_OFFSET_FORMAT } from 'src/timeConstants';
const useAddPartnersAPIDateTimePicker = ({ editDate, }) => {
    const dispatch = useDispatch();
    const selectedStartDateTime = useSelector(getAPIKeyValidFrom);
    useEffect(() => {
        if (editDate) {
            dispatch(setAPIKeyValidFrom(moment(editDate, TIMESTAMP_WITH_OFFSET_FORMAT)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleDateTimeChange = (value) => {
        if (!(value === null || value === void 0 ? void 0 : value.isValid())) {
            return;
        }
        dispatch(setAPIKeyValidFrom(value));
    };
    return {
        selectedStartDateTime,
        handleDateTimeChange,
    };
};
export default useAddPartnersAPIDateTimePicker;
