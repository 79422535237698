import { toEnum } from 'src/models/enums';
export const NotesEntityType = toEnum({
    REPAIR_REQUEST: 'REPAIR_REQUEST',
    SUGGESTED_REPAIR: 'SUGGESTED_REPAIR',
    PARTS_PRICES: 'PARTS_PRICES',
    FLUID_PRICES: 'FLUID_PRICES',
    CONSUMER: 'CONSUMER',
    WORK_ORDER: 'WORK_ORDER',
    FLEET_VISIT: 'FLEET_VISIT',
});
export default NotesEntityType;
