import { Grid, Table, TableCell, TableRow } from 'src/components/mui';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const ResourcesTable = styled(Table) `
  min-width: 650px;
`;
export const ResourcesColumnHeader = styled(TableRow) `
  background-color: ${colors.GRAY_EE};
`;
export const ResourcesCell = styled(TableCell) `
  min-width: ${({ $minWidth }) => $minWidth || '50px'};
`;
export const ResourcesMeta = styled.div `
  font-size: small;
  color: ${colors.GRAY_99};
`;
export const ResourcesClickableText = styled.div `
  color: ${colors.darkBlue};
  text-decoration: underline;
  cursor: pointer;
`;
export const ResourcesClickableCell = styled(TableCell) `
  color: ${colors.darkBlue};
  text-decoration: underline;
  cursor: pointer;
`;
export const ResourcesFilter = styled(Grid) `
  margin-right: 5px;
`;
export const ResourcesFilters = styled(Grid) `
  margin: 10px;
`;
