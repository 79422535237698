import React from 'react';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: undefined };
    }
    componentDidCatch(error) {
        this.setState({ error, hasError: true });
    }
    render() {
        var _a;
        if (this.state.hasError) {
            const { name, message } = (_a = this.state.error) !== null && _a !== void 0 ? _a : {};
            return (<>
          <h1>Something went wrong</h1>

          <p>
            <b>{name}</b>: {message}
          </p>
        </>);
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
