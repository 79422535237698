var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Tabs, Tab, Grid } from 'src/components/mui';
import useStickyTabs from 'src/AdminApp/hooks/useStickyTabs';
import Jobs from './Jobs';
import useChart from '../../hooks/useChart';
const TabPanel = (props) => {
    const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
    return (<div role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
      {value === index && children}
    </div>);
};
const Vans = () => {
    const { jobsByRegions } = useChart();
    const [filteredRegion, setFilteredRegion] = useState();
    const [selectedVan, setSelectedVan] = useState('');
    const tabsRef = useRef(null);
    useStickyTabs(tabsRef);
    useEffect(() => {
        if (selectedVan) {
            const jobs = jobsByRegions.filter((job) => job.vanResources.find((van) => van.id === selectedVan));
            setFilteredRegion([...jobs]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVan]);
    return (<>
      <AppBar position="static" color="transparent" style={{
            boxShadow: 'none',
            maxWidth: '345px',
        }} ref={tabsRef}>
        <Tabs value={selectedVan} onChange={(e, newValue) => setSelectedVan(newValue)} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
          {jobsByRegions.map((job) => {
            return job.vanResources.map((van) => (<Tab key={van.id} label={van.name} value={van.id}></Tab>));
        })}
        </Tabs>
      </AppBar>
      <Grid className="tabs-content" container>
        {filteredRegion === null || filteredRegion === void 0 ? void 0 : filteredRegion.map((region) => {
            return region.vanResources.map((van) => (<Grid item xs={12}>
              <TabPanel key={van.id} value={selectedVan} index={van.id}>
                <Jobs regionName={region.regionName} timelines={van.timelines}/>
              </TabPanel>
            </Grid>));
        })}
      </Grid>
    </>);
};
export default Vans;
