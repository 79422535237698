import React from 'react';
import { useHistory } from 'react-router-dom';
import { TooltipInfo } from 'src/components/tooltips';
import { getVisitData } from '../VisitRow/VisitRow';
import { VisitCardContainer, VisitCardRow, VisitItem, Divider, ViewButton, VisitItemTitle, } from './VisitMobile.styles';
import { TooltipDivider } from '../VisitRow/VisitRow.styles';
import VisitLockTooltip from '../VisitLockTooltip';
const VisitMobile = ({ item }) => {
    var _a;
    const history = useHistory();
    const itemData = getVisitData(item);
    return (<VisitCardContainer>
      <VisitCardRow>
        <VisitItem flex={5}>
          {itemData.stationName ? (<b>
              {itemData.stationName}
              {itemData.showFleetColon && <>: </>}
            </b>) : null}
          {itemData.showFleets ? <span> {itemData.fleetName}</span> : ''}
          {itemData.fleetsCount > 1 && (<span className="VisitMore"> +{itemData.fleetsCount - 1} more</span>)}
          <TooltipInfo outermargin="0 4px -4px">
            {itemData.stationName ? <b>{itemData.stationName}</b> : null}
            {itemData.stationName ? <TooltipDivider /> : null}
            {itemData.fleets.join(' • ')}
            <TooltipDivider />
            {itemData.address}
          </TooltipInfo>
        </VisitItem>
        <VisitItem flex={1} style={{ textAlign: 'right' }}>
          <VisitLockTooltip visitData={itemData}/>
        </VisitItem>
      </VisitCardRow>
      <VisitCardRow />
      <Divider />
      <VisitCardRow>
        <VisitItem flex={3}>
          <VisitItemTitle>Status</VisitItemTitle>
          {itemData.status}
        </VisitItem>
        <VisitItem />
        <VisitItem flex={3}>
          <VisitItemTitle>Appt Time</VisitItemTitle>
          {itemData.startDateFormatted} - {itemData.endDateFormatted}
          {!!itemData.isNextDate && ' (Next Day)'}
          <br />
          Scheduled Hrs: {itemData.totalScheduledHours}
        </VisitItem>
      </VisitCardRow>
      <VisitCardRow />
      <Divider hastopmargin/>
      <VisitCardRow>
        <VisitItem flex={3}>
          <VisitItemTitle>Techs</VisitItemTitle>
          {itemData.techsCount ? <span> {itemData.techName}</span> : 'N/A'}

          {itemData.techsCount > 1 && (<span className="VisitMore"> +{itemData.techsCount - 1} more</span>)}
          <TooltipInfo outermargin="0 4px -4px">
            <div className="tooltipTitle">
              <b>Technicians</b>
            </div>
            {(_a = itemData.techs) === null || _a === void 0 ? void 0 : _a.join(' • ')}
          </TooltipInfo>
        </VisitItem>
        <VisitItem />
        <VisitItem flex={3}>
          <VisitItemTitle>Capacity</VisitItemTitle>
          Repair Orders: {itemData.totalOrders}
          <br />
          Remaining Hrs: {itemData.totalRemainingHours}
        </VisitItem>
      </VisitCardRow>

      <ViewButton onClick={() => {
            history.push(`/admin/visits/${itemData.refNum}`);
        }}>
        View Details
      </ViewButton>
    </VisitCardContainer>);
};
export default VisitMobile;
