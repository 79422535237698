var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLimitExceeded } from 'src/AdminApp/components/discounts/utils';
import { DISCOUNT_LIMITS } from 'src/AdminApp/modules/api/constants';
import { addCustomRepair, addRepair, } from 'src/AdminApp/modules/invoices/actions';
import { selectInvoiceWithWorkOrderId } from 'src/AdminApp/modules/invoices/selectors';
import { getDiscountReasonTypes } from 'src/AdminApp/modules/lookups/actions';
import { selectDiscountReasonTypes } from 'src/AdminApp/modules/lookups/selectors';
import { getRequest, searchRepairs, } from 'src/AdminApp/modules/requests/actions';
import { lookupRequestIdByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { addRepairsToSublet, addSublet, removeRepairsFromSublet, } from 'src/AdminApp/modules/sublets/actions';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import { selectAuthorizedShowRODiscount, selectAuthorizedSubletValet, } from 'src/modules/auth/selectors';
const useActionsShell = ({ orderId, workOrderId, fleetId, promos, credits, otherDiscounts, onAddPromoCode, onAddCFCredit, onAddOtherDiscount, cancelFeeCredits, }) => {
    const dispatch = useDispatch();
    const { referenceNum } = usePageReferenceNum();
    const [isServiceModalOpen, setServiceModalOpen] = useState(false);
    const [isDiscountOpen, setIsDiscountOpen] = useState(false);
    const [isSubletOpen, setSubletOpen] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [repairId, setRepairId] = useState(null);
    const invoice = useSelector((state) => selectInvoiceWithWorkOrderId(state, workOrderId));
    const canEditSubletValet = useSelector(selectAuthorizedSubletValet);
    const requestId = useSelector(lookupRequestIdByReferenceNum(referenceNum));
    const discountReasonTypes = useSelector(selectDiscountReasonTypes);
    const canAccessDiscount = useSelector(selectAuthorizedShowRODiscount);
    useEffect(() => {
        if (!discountReasonTypes.length) {
            dispatch(getDiscountReasonTypes());
        }
    }, []); // eslint-disable-line
    const onAddRepair = ({ repairId }) => dispatch(addRepair({
        invoiceId: invoice === null || invoice === void 0 ? void 0 : invoice.id,
        repairId,
        workOrderId,
        referenceNum,
    }));
    const onAddCustomRepair = ({ repairDescription, }) => dispatch(addCustomRepair({
        invoiceId: invoice === null || invoice === void 0 ? void 0 : invoice.id,
        workOrderId,
        repairDescription,
    }));
    const onSearchRepairs = (data) => dispatch(searchRepairs(data, fleetId));
    const onRepairSelect = (selectedRepair) => setRepairId(selectedRepair.repairId);
    const handleRepairSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!repairId)
            return;
        setSubmitting(true);
        yield onAddRepair({ repairId });
        yield dispatch(getRequest(referenceNum, requestId));
        setSubmitting(false);
        setServiceModalOpen(false);
    });
    const handleCustomRepairSubmit = (repairDescription) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield onAddCustomRepair({ repairDescription });
        if (!response.error) {
            setServiceModalOpen(false);
        }
        return dispatch(getRequest(referenceNum, requestId));
    });
    const onAddPromo = ({ promoCode }) => __awaiter(void 0, void 0, void 0, function* () {
        yield onAddPromoCode({ promoCode });
        setIsDiscountOpen(false);
    });
    const onAddOther = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield onAddOtherDiscount(values);
        setIsDiscountOpen(false);
    });
    const onAddCredit = ({ invoiceId, creditAmount, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield onAddCFCredit({
            originalInvoiceId: invoiceId,
            creditAmount,
        });
        setIsDiscountOpen(false);
    });
    const canAddPromo = !isLimitExceeded(promos, DISCOUNT_LIMITS.PROMO_CODE);
    const canAddCredit = !isEmpty(cancelFeeCredits) &&
        !isLimitExceeded(credits, DISCOUNT_LIMITS.CANCEL_FEE_CREDIT);
    const canAddOtherDiscount = !isLimitExceeded(otherDiscounts, DISCOUNT_LIMITS.OTHER_DISCOUNT);
    const disableOptions = {
        promoCode: !canAddPromo,
        previousRepairCredit: !canAddCredit,
        customDiscount: !canAddOtherDiscount,
        cancelFeeCredit: !canAddCredit,
    };
    const onAddSublet = (_a) => __awaiter(void 0, void 0, void 0, function* () {
        var { repairIds: repairIdsValues } = _a, rest = __rest(_a, ["repairIds"]);
        const repairIds = Object.keys(repairIdsValues).filter((key) => repairIdsValues[key]);
        yield dispatch(addSublet(Object.assign({ referenceNum,
            orderId,
            repairIds }, rest)));
        setSubletOpen(false);
    });
    const onAddRepairs = (_b) => __awaiter(void 0, void 0, void 0, function* () {
        var { repairIds: repairIdsValues } = _b, rest = __rest(_b, ["repairIds"]);
        const repairIds = Object.keys(repairIdsValues).filter((key) => repairIdsValues[key]);
        yield dispatch(addRepairsToSublet(Object.assign({ orderId,
            referenceNum,
            repairIds }, rest)));
        setSubletOpen(false);
    });
    const onRemoveRepairs = (data) => dispatch(removeRepairsFromSublet(Object.assign(Object.assign({}, data), { orderId,
        referenceNum })));
    return {
        canAccessDiscount,
        canEditSubletValet,
        disableOptions,
        isDiscountOpen,
        isServiceModalOpen,
        isSubmitting,
        isSubletOpen,
        onAddCredit,
        onAddOther,
        onAddPromo,
        onAddRepairs,
        onAddSublet,
        onRemoveRepairs,
        onRepairSelect,
        onSearchRepairs,
        setIsDiscountOpen,
        setServiceModalOpen,
        setSubletOpen,
        handleCustomRepairSubmit,
        handleRepairSubmit,
        repairId,
        invoice,
        referenceNum,
    };
};
export default useActionsShell;
