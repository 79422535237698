import React from 'react';
import PlacesACWithServiceAreas from 'src/components/form/PlacesACWithServiceAreas';
import { SearchAddressTitle, SlideWrapper, } from 'src/AdminApp/components/drawers/styledComponents';
const SearchAddress = ({ customAddress, handleAddressSelect }) => (<SlideWrapper>
    <SearchAddressTitle>Search Address</SearchAddressTitle>
    <PlacesACWithServiceAreas address={customAddress} onSelect={handleAddressSelect} textFieldParams={{
        variant: 'outlined',
        label: 'Address',
    }}/>
  </SlideWrapper>);
export default SearchAddress;
