var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Checkbox, Grid, Icon, IconButton, MenuItem, Select, Tooltip, } from 'src/components/mui';
import { selectAccessToken } from 'src/modules/auth/selectors';
import { downloadNewFleetTemplateCsv } from 'src/utils/downloadUtils';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import csv from 'csvtojson';
import { SimpleModal } from 'src/components/layout';
import { FleetBulkVehicleImportCSVFormat } from 'src/AdminApp/models/enums/FleetBulkVehicleImportCSVFormat';
import { colors } from 'src/styles/theme';
const ErrorsWrapper = styled(Grid) `
  background-color: ${colors.error};
  margin-top: 10px;
  padding: 0 10px;
`;
const DropZoneCard = styled(Card) `
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.GRAY_F1} !important;
`;
const Upload = styled.div `
  display: flex;
  flex-direction: column;
`;
const ImportCsvModalModal = ({ open, onClose, onSubmit, }) => {
    const dispatch = useDispatch();
    const accessToken = useSelector(selectAccessToken);
    const [importCSVFormat, setImportCSVFormat] = useState(FleetBulkVehicleImportCSVFormat.RepairSmithStandard);
    const [cancelDeltaRepairRequests, setCancelDeltaRepairRequests] = useState(false);
    const [validationInfo, setValidationInfo] = useState();
    const [vehicles, setVehicles] = useState();
    const [csvFile, setCsvFile] = useState();
    const [isSubmitting, setSubmitting] = useState(false);
    const validateVehicles = (vehicles) => {
        if (importCSVFormat === FleetBulkVehicleImportCSVFormat.RepairSmithStandard) {
            return vehicles.map((x, index) => {
                return validateRepairSmithStandardVehicle(x, index + 1);
            });
        }
        if (importCSVFormat === FleetBulkVehicleImportCSVFormat.ElementPMDetail) {
            return vehicles.map((x, index) => {
                return validateElementPMVehicle(x, index + 1);
            });
        }
        if (importCSVFormat === FleetBulkVehicleImportCSVFormat.LeasePlanPMDetail) {
            return vehicles.map((x, index) => {
                return validateLeasePlanPMVehicle(x, index + 1);
            });
        }
    };
    const validateRepairSmithStandardVehicle = (vehicle, index) => {
        const errors = [];
        let isValid = true;
        if (!vehicle) {
            isValid = false;
            errors.push(`Row ${index}: "row" is undefined`);
        }
        if (!vehicle.year) {
            isValid = false;
            errors.push(`Row ${index}: "year" not found`);
        }
        if (!vehicle.make) {
            isValid = false;
            errors.push(`Row ${index}: "make" not found`);
        }
        if (!vehicle.model) {
            isValid = false;
            errors.push(`Row ${index}: "model" not found`);
        }
        if (!vehicle.engineDescription) {
            isValid = false;
            errors.push(`Row ${index}: "engineDescription" not found`);
        }
        return { vehicle, isValid, errors };
    };
    const validateElementPMVehicle = (vehicle, index) => {
        const errors = [];
        let isValid = true;
        if (!vehicle) {
            isValid = false;
            errors.push(`Row ${index}: row is undefined`);
        }
        if (!vehicle['Unit #']) {
            isValid = false;
            errors.push(`Row ${index}: "Unit #" not found`);
        }
        if (!vehicle.VIN) {
            isValid = false;
            errors.push(`Row ${index}: "VIN" not found`);
        }
        if (!vehicle['PM Description']) {
            isValid = false;
            errors.push(`Row ${index}: "PM Description" not found`);
        }
        if (!vehicle['Current Estimated Odometer']) {
            isValid = false;
            errors.push(`Row ${index}: "Current Estimated Odometer" not found`);
        }
        if (!vehicle['Next PM Due Date']) {
            isValid = false;
            errors.push(`Row ${index}: "Next PM Due Date" not found`);
        }
        return { vehicle, isValid, errors };
    };
    const validateLeasePlanPMVehicle = (vehicle, index) => {
        const errors = [];
        let isValid = true;
        if (!vehicle) {
            isValid = false;
            errors.push(`Row ${index}: row is undefined`);
        }
        if (!vehicle.Unit_No) {
            isValid = false;
            errors.push(`Row ${index}: "Unit_No" not found`);
        }
        if (!vehicle.VIN) {
            isValid = false;
            errors.push(`Row ${index}: "VIN" not found`);
        }
        if (!vehicle.PM_Description) {
            isValid = false;
            errors.push(`Row ${index}: "PM_Description" not found`);
        }
        return { vehicle, isValid, errors };
    };
    const getValidationInfo = (validationResult) => {
        return {
            detected: validationResult.length,
            accepted: validationResult.filter((x) => x.isValid).length,
            errors: validationResult
                .map((x) => {
                return x.errors;
            })
                .flat(),
        };
    };
    const convertCsvToJson = (csvString) => __awaiter(void 0, void 0, void 0, function* () {
        return csv().fromString(csvString);
    });
    const readFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = () => {
                reject(new Error('Problem reading input file'));
            };
            reader.readAsBinaryString(file);
        });
    });
    const onDrop = (files) => __awaiter(void 0, void 0, void 0, function* () {
        if (files && files.length) {
            const csv = files[0];
            const fileData = yield readFile(csv);
            const vehiclesJson = yield convertCsvToJson(fileData);
            const validatedVehicles = validateVehicles(vehiclesJson);
            const validationInfo = getValidationInfo(validatedVehicles);
            setCsvFile(csv);
            setVehicles(validatedVehicles === null || validatedVehicles === void 0 ? void 0 : validatedVehicles.filter((x) => x.isValid).map((y) => y.vehicle));
            setValidationInfo(validationInfo);
        }
    });
    const onDownloadTemplate = () => __awaiter(void 0, void 0, void 0, function* () {
        downloadNewFleetTemplateCsv(importCSVFormat, accessToken);
    });
    const handleImportCSVFormatChange = (event) => {
        setValidationInfo(null);
        setVehicles(undefined);
        setImportCSVFormat(event.target.value);
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        yield onSubmit(vehicles, csvFile, importCSVFormat, cancelDeltaRepairRequests);
        setVehicles(undefined);
        setValidationInfo(undefined);
        setImportCSVFormat(FleetBulkVehicleImportCSVFormat.RepairSmithStandard);
        setCancelDeltaRepairRequests(false);
        setSubmitting(false);
    });
    const handleClose = () => __awaiter(void 0, void 0, void 0, function* () {
        setValidationInfo(undefined);
        setVehicles(undefined);
        setSubmitting(false);
        setImportCSVFormat(FleetBulkVehicleImportCSVFormat.RepairSmithStandard);
        setCancelDeltaRepairRequests(false);
        onClose();
    });
    const getSubmitText = () => {
        if (importCSVFormat === FleetBulkVehicleImportCSVFormat.RepairSmithStandard) {
            return vehicles && vehicles.length
                ? `Create ${vehicles.length} vehicles`
                : 'Create vehicles';
        }
        return vehicles && vehicles.length
            ? `Process ${vehicles.length} rows`
            : 'Process rows';
    };
    const disabledSubmit = !validationInfo ||
        !vehicles ||
        vehicles.length <= validationInfo.errors.length ||
        isSubmitting;
    return (<SimpleModal title="Import CSV" open={open} onClose={handleClose} simpleTwoButtonRowProps={{
            disabledSubmit,
            onSubmit: handleSubmit,
            onCancel: handleClose,
            submitText: getSubmitText(),
        }}>
      <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item xs={10}>
          <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={importCSVFormat} onChange={handleImportCSVFormatChange} label="Age">
            <MenuItem value={FleetBulkVehicleImportCSVFormat.RepairSmithStandard}>
              RepairSmith Standard
            </MenuItem>
            <MenuItem value={FleetBulkVehicleImportCSVFormat.ElementPMDetail}>
              Element PM Detail
            </MenuItem>
            <MenuItem value={FleetBulkVehicleImportCSVFormat.LeasePlanPMDetail}>
              LeasePlan PM Detail
            </MenuItem>
          </Select>
        </Grid>
        {!validationInfo && (<>
            <Grid item xs={1}>
              <Tooltip title="Download Template">
                <IconButton style={{ padding: 0 }} onClick={onDownloadTemplate}>
                  <Icon>cloud_download</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Cancel Delta Repair Requests">
                <Checkbox style={{ padding: 0 }} checked={cancelDeltaRepairRequests} onChange={(_, value) => {
                setCancelDeltaRepairRequests(value);
            }} name="cancelDeltaRepairRequests" color="primary"/>
              </Tooltip>
            </Grid>
          </>)}
      </Grid>
      {!validationInfo && (<Grid container style={{ marginTop: '24px' }}>
          <Grid item xs={12}>
            <Upload>
              <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (<DropZoneCard {...getRootProps()}>
                    <input {...getInputProps()} accept=".csv"/>
                    <Button>
                      Drag 'n' drop .csv file here, or click to select file
                    </Button>
                  </DropZoneCard>)}
              </Dropzone>
            </Upload>
          </Grid>
        </Grid>)}
      {validationInfo && (<>
          <Grid container>
            <Grid item xs={4}>
              <p>Detected</p>
              <p>{validationInfo.detected}</p>
            </Grid>
            <Grid item xs={4}>
              <p>Accepted</p>
              <p>{validationInfo.accepted}</p>
            </Grid>
            <Grid item xs={4}>
              <p>Errors</p>
              <p>{validationInfo.errors.length}</p>
            </Grid>
          </Grid>
          {!!validationInfo.errors.length && (<ErrorsWrapper container>
              <Grid item xs={12}>
                <h3>Errors</h3>
              </Grid>
              <Grid item xs={12}>
                {validationInfo.errors.map((x, index) => (<p key={index}>{x}</p>))}
              </Grid>
            </ErrorsWrapper>)}
        </>)}
    </SimpleModal>);
};
export default ImportCsvModalModal;
