import React from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, } from 'src/components/mui';
import useFleetInfo from 'src/AdminApp/containers/fleets/tabs/Info/FleetInfo/useFleetInfo';
const CarSelectResponsibleParty = ({ stateVehicle, onFieldChange, fleet, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    if (!fleet) {
        return null;
    }
    const { findResponsiblePartyById } = useFleetInfo(fleet);
    const rps = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) !== null && _a !== void 0 ? _a : [];
    const availableResponsibleParties = (rps === null || rps === void 0 ? void 0 : rps.length)
        ? (_b = rps
            .map((rp) => {
            var _a;
            return (Object.assign(Object.assign({}, rp), { name: (_a = findResponsiblePartyById(rp.responsiblePartyId)) === null || _a === void 0 ? void 0 : _a.name }));
        })) === null || _b === void 0 ? void 0 : _b.filter((rp) => rp.name)
        : [];
    const selectedIds = ((_c = stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.fleetResponsiblePartyIds) === null || _c === void 0 ? void 0 : _c.length)
        ? (_e = (_d = stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.fleetResponsiblePartyIds) === null || _d === void 0 ? void 0 : _d.map((rp) => rp)) !== null && _e !== void 0 ? _e : []
        : (_g = (_f = stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.fleetResponsibleParties) === null || _f === void 0 ? void 0 : _f.map((rp) => `${rp.id},${rp.responsiblePartyId}`)) !== null && _g !== void 0 ? _g : [];
    return (<FormControl fullWidth>
      <InputLabel id="responsible-party-label">Responsible Party</InputLabel>
      <Select id="responsible-party" labelId="responsible-party-label" name="responsible-party" variant="outlined" fullWidth multiple input={<OutlinedInput label="Responsible Party"/>} onChange={onFieldChange('fleetResponsibleParties')} value={selectedIds} renderValue={(selected) => {
            const value = selected
                .map((val) => {
                var _a, _b;
                return ((_b = (_a = availableResponsibleParties.find((rp) => `${rp === null || rp === void 0 ? void 0 : rp.id},${rp.responsiblePartyId}` === val)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : []);
            })
                .join(', ');
            return value;
        }}>
        {availableResponsibleParties.length === 0 ? (<MenuItem disabled>No responsible parties associated</MenuItem>) : (availableResponsibleParties.map((rp) => (<MenuItem value={`${rp.id},${rp.responsiblePartyId}`} key={rp.id}>
              <Checkbox checked={selectedIds.includes(`${rp.id},${rp.responsiblePartyId}`)}/>
              <ListItemText primary={rp.name}/>
            </MenuItem>)))}
      </Select>
    </FormControl>);
};
export default CarSelectResponsibleParty;
