import styled from 'styled-components';
import { Form } from 'formik';
import { colors } from 'src/styles/theme';
export const SlidePageWrapper = styled(Form) `
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
`;
export const SlidePageContent = styled.div `
  padding: 24px;
  flex: 1;
`;
export const SlidePageFooter = styled.div `
  background: ${colors.lightGrey};
  padding: 24px;
  text-align: center;

  button {
    width: 192px;
  }
`;
