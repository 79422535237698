import React from 'react';
import { Table as MuiTable, TableRow, TableBody, useMediaQuery, } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import SimpleTablePaginationActions from 'src/components/dashboard/SimpleTable/SimpleTablePaginationActions';
import { StyledTablePagination } from 'src/components/dashboard/SimpleTable/SimpleTable.style';
const ROWS_PER_PAGE = [10, 20, 50, 100];
const SimpleTablePagination = ({ pagination, hidePagination = false, rowsCount, onPageChange, onRowsPerPageChange, }) => {
    var _a, _b;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return hidePagination ? null : (<MuiTable>
      <TableBody>
        <TableRow>
          <StyledTablePagination ActionsComponent={SimpleTablePaginationActions} count={(_a = pagination === null || pagination === void 0 ? void 0 : pagination.totalElements) !== null && _a !== void 0 ? _a : 0} page={(_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _b !== void 0 ? _b : 0} rowsPerPage={(pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || ROWS_PER_PAGE[1]} rowsPerPageOptions={ROWS_PER_PAGE} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} labelRowsPerPage={isMobile ? <span /> : 'Rows per page'}/>
        </TableRow>
      </TableBody>
    </MuiTable>);
};
export default SimpleTablePagination;
