import { createSelector } from 'reselect';
import { selectRequestContactInfo } from 'src/AdminApp/modules/requests/selectors';
export const selectPayments = (state) => state.admin.payments;
export const selectMethodsByConsumer = createSelector(selectPayments, (payments) => { var _a; return (_a = payments.methodsByConsumer) !== null && _a !== void 0 ? _a : {}; });
export const selectConsumerMethods = createSelector(selectRequestContactInfo, selectMethodsByConsumer, (contactInfo, methodsByConsumer) => {
    var _a;
    return (contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.consumerId)
        ? (_a = methodsByConsumer[contactInfo.consumerId]) !== null && _a !== void 0 ? _a : []
        : [];
});
export const selectConsumerMethodsByConsumerId = (consumerId) => createSelector(selectMethodsByConsumer, (methodsByConsumer) => {
    var _a;
    return (_a = methodsByConsumer[consumerId]) !== null && _a !== void 0 ? _a : [];
});
export const selectHasConsumerMethods = createSelector(selectConsumerMethods, (methods) => (methods === null || methods === void 0 ? void 0 : methods.filter(({ active, methodType }) => methodType === 'CREDIT_CARD' && active).length) > 0);
export const selectConsumerMethodsById = createSelector(selectMethodsByConsumer, (methodsByConsumer) => (consumerId) => { var _a; return (_a = methodsByConsumer === null || methodsByConsumer === void 0 ? void 0 : methodsByConsumer[consumerId]) !== null && _a !== void 0 ? _a : []; });
