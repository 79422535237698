import React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { toast } from 'src/components/SimpleToast';
import { Grid } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { colors } from 'src/styles/theme';
const CustomRepairSchema = Yup.object().shape({
    repairDescription: Yup.string().max(255, 'Too Long!'),
});
const StyledError = styled.div `
  color: ${colors.redF5};
`;
const AddCustomRepairForm = ({ onSubmit, onClose, showActionButtons = true, setCustomRepairText, }) => (<Formik initialValues={{ repairDescription: '' }} validationSchema={CustomRepairSchema} onSubmit={(values, { resetForm }) => {
        if (!onSubmit)
            return;
        Promise.resolve(onSubmit(values)).then((response) => {
            if (response === null || response === void 0 ? void 0 : response.error)
                toast.error(`Sorry! Failed to add custom repair ${values}`);
            else {
                resetForm();
                if (onClose)
                    onClose();
            }
        });
    }}>
    {({ isSubmitting, touched, errors, setFieldValue }) => (<Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Repair description" type="text" name="repairDescription" autoFocus onChange={(e) => {
            if (setCustomRepairText)
                setCustomRepairText(e.target.value);
            setFieldValue('repairDescription', e.target.value);
        }}/>
            {errors.repairDescription && touched.repairDescription ? (<StyledError>{errors.repairDescription}</StyledError>) : null}
          </Grid>
          <Grid item xs={12}>
            {showActionButtons && (<SimpleTwoButtonRow onCancel={onClose} disabledCancel={isSubmitting} disabledSubmit={isSubmitting}/>)}
          </Grid>
        </Grid>
      </Form>)}
  </Formik>);
export default AddCustomRepairForm;
