import React from 'react';
import { map, isEmpty } from 'lodash';
import { WatchLater as WatchLaterIcon } from 'src/components/mui/icons';
import { formatDate } from 'src/utils/formatter';
import { LOCALIZED_DATE_LONGER_FORMAT, TIME_12H_FORMAT, WEEK_DAY_SHORT_FORMAT, } from 'src/timeConstants';
import { Section } from './styledComponents';
const RequestedTimes = ({ times }) => {
    const renderTimeLine = ({ appointmentDate, appointmentTime, }) => {
        const date = formatDate(`${appointmentDate} ${appointmentTime}`, `${WEEK_DAY_SHORT_FORMAT}, ${LOCALIZED_DATE_LONGER_FORMAT} • ${TIME_12H_FORMAT}`);
        return <div key={date}>{date}</div>;
    };
    return (<Section icon={<WatchLaterIcon fontSize="small"/>} title="Requested Times">
      {map(times, renderTimeLine)}
      {isEmpty(times) && 'No date selected'}
    </Section>);
};
export default RequestedTimes;
