import { createSelector } from 'reselect';
const getPartnersAPIs = (state) => state.partnersAPIApp.partnersAPI;
export const selectPartnersAPIData = createSelector(getPartnersAPIs, (partnersAPI) => partnersAPI === null || partnersAPI === void 0 ? void 0 : partnersAPI.data);
export const selectPartnersAPIPagination = createSelector(getPartnersAPIs, (partnersAPIs) => partnersAPIs === null || partnersAPIs === void 0 ? void 0 : partnersAPIs.pagination);
export const selectPartnersAPISort = createSelector(getPartnersAPIs, (partnersAPIs) => partnersAPIs === null || partnersAPIs === void 0 ? void 0 : partnersAPIs.apiKeySort);
export const selectAddPartnerAPIKeyPartner = createSelector(getPartnersAPIs, (partnersAPIs) => { var _a; return (_a = partnersAPIs.addPartnerAPIKey.partners) !== null && _a !== void 0 ? _a : []; });
export const selectAddPartnerAPIKeyMarketingSource = createSelector(getPartnersAPIs, (partnersAPIs) => { var _a; return (_a = partnersAPIs.addPartnerAPIKey.marketingSource) !== null && _a !== void 0 ? _a : []; });
export const getAPIKeyIsDefault = createSelector(getPartnersAPIs, (partnersAPIs) => { var _a; return (_a = partnersAPIs.addPartnerAPIKey.isDefault) !== null && _a !== void 0 ? _a : false; });
export const getAPIKeyDescription = createSelector(getPartnersAPIs, (partnersAPI) => { var _a; return (_a = partnersAPI.addPartnerAPIKey.description) !== null && _a !== void 0 ? _a : ''; });
export const getAPIKeyValidFrom = createSelector(getPartnersAPIs, (partnersAPI) => partnersAPI.addPartnerAPIKey.validFrom);
export const getAPIKeyValidTo = createSelector(getPartnersAPIs, (partnersAPI) => partnersAPI.addPartnerAPIKey.validTo);
export const getAPIKeyAccessLevel = createSelector(getPartnersAPIs, (partnersAPI) => partnersAPI.addPartnerAPIKey.accessLevel);
