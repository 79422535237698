import React, { useEffect, useState } from 'react';
import { JobsTable } from 'src/PopsApp/pages/Job/Job.styles';
import StyledTablePagination from 'src/components/StyledTablePagination';
import moment from 'moment-timezone';
import { Tooltip } from 'src/components/tooltips';
import { SPECIAL_ORDER_TOOLTIP } from 'src/PopsApp/modules/api/constants';
import { SpecialOrderWrapper } from 'src/PopsApp/pages/Job/JobTableRow/JobTableRow.styles';
import SpecialOrderIndicator from 'src/PopsApp/components/SpecialOrderIndicator';
import { OrderRosterMode } from 'src/AdminApp/models/enums/OrderRosterMode';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
import { LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
const OrderRosterJobs = ({ page, rowsPerPage, jobs, changeRowsPerPage, pagination, changePage, mode, }) => {
    const [priorityCounter, setPriorityCounter] = useState(1);
    useEffect(() => {
        setPriorityCounter(rowsPerPage * page + 1);
    }, [page, rowsPerPage]);
    const formatAppointmentDate = (date, timezone) => {
        const currentTimezone = moment.tz.guess();
        const localDate = moment.tz(date, timezone).local();
        return `${localDate.format(LOCALIZED_DATE_FORMAT)} • ${localDate.format(TIME_12H_FORMAT)} ${moment
            .tz(currentTimezone)
            .zoneAbbr()}`;
    };
    const formatUtcDate = (date) => {
        const currentTimezone = moment.tz.guess();
        const localDate = moment.tz(`${date}Z`, 'UTC').local();
        return `${localDate.format(LOCALIZED_DATE_FORMAT)} • ${localDate.format(TIME_12H_FORMAT)} ${moment
            .tz(currentTimezone)
            .zoneAbbr()}`;
    };
    const renderPendingComponents = (partsNeeded, fluidsNeeded) => {
        return (partsNeeded || 0) + (fluidsNeeded || 0);
    };
    const getDataColumns = (job, index) => {
        const baseColumns = [
            index + priorityCounter,
            <a href={`/admin/shops/${job.ispId}/edit`} rel="noopener noreferrer" target="_blank">
        {job.vanName}
      </a>,
            <>
        {!job.containsSpecialOrder && (<a href={`/admin/repairs/${job.referenceNum}`} rel="noopener noreferrer" target="_blank">
            {job.referenceNum}
          </a>)}
        {job.containsSpecialOrder && (<Tooltip title={SPECIAL_ORDER_TOOLTIP}>
            <SpecialOrderWrapper>
              <span className="neededText">
                <a href={`/admin/repairs/${job.referenceNum}`} rel="noopener noreferrer" target="_blank">
                  {job.referenceNum}
                </a>
              </span>
              <SpecialOrderIndicator />
            </SpecialOrderWrapper>
          </Tooltip>)}
      </>,
            job.scheduleStart &&
                formatAppointmentDate(job.scheduleStart, job.timezone),
            job.shift,
            job.skippedFrom && formatUtcDate(job.skippedFrom),
            job.skippedTo && formatUtcDate(job.skippedTo),
            renderPendingComponents(job.partsNeeded, job.fluidsNeeded),
        ];
        if (mode === OrderRosterMode.SKIPPED) {
            baseColumns.push(job.skippedBy);
        }
        return baseColumns;
    };
    return (<>
      <JobsTable>
        <thead>
          <tr>
            <th>Priority</th>
            <th>Van Name</th>
            <th>Reference #</th>
            <th>Date</th>
            <th>Shift</th>
            <th>Skipped From</th>
            <th>Skipped To</th>
            <th>Pending Components</th>
            {mode === OrderRosterMode.SKIPPED && <th>Skipped by</th>}
          </tr>
        </thead>
        <tbody>
          {jobs &&
            jobs.data.map((job, index) => (<SimpleTableRow data={getDataColumns(job, index)}/>))}
        </tbody>
      </JobsTable>
      {pagination != null && (<StyledTablePagination paginationData={pagination} page={page} onPageChange={changePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={changeRowsPerPage}/>)}
    </>);
};
export default OrderRosterJobs;
