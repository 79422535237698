import React, { useState } from 'react';
import { Typography } from 'src/components/mui';
import CollapsibleBlock from 'src/components/layout/CollapsibleBlock';
import InvoiceForm from 'src/AdminApp/containers/requests/Invoice/InvoiceForm';
import InvoiceQuote from './Details';
import InvoiceFooter from './InvoiceFooter';
const Invoice = ({ canEdit, invoiceId, orderId, workOrderId, locked = false, }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isEditing, setIsEditing] = useState(true);
    return (<CollapsibleBlock hashName="invoice" header={<Typography variant="h5" gutterBottom>
          Invoice{invoiceId ? ` #${invoiceId}` : ''}
        </Typography>} defaultOpen>
      <InvoiceQuote canEdit={canEdit} orderId={orderId} workOrderId={workOrderId}/>
      <InvoiceForm orderId={orderId} workOrderId={workOrderId} canEdit={canEdit} setIsEditing={setIsEditing} locked={locked}/>
      <InvoiceFooter canEdit={canEdit} workOrderId={workOrderId}/>
    </CollapsibleBlock>);
};
export default Invoice;
