import React from 'react';
import { SimpleModal } from 'src/components/layout';
const AppointmentPassedModal = ({ open, onClose, onSubmit, }) => {
    return (<SimpleModal title="This appointment date has already passed" open={open} onClose={onClose} simpleTwoButtonRowProps={{
            onCancel: onClose,
            onSubmit,
            submitText: 'Save',
        }}>
      Are you sure you want to select this time?
    </SimpleModal>);
};
export default AppointmentPassedModal;
