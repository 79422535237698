import React from 'react';
import { InputLabel, Select } from 'src/components/mui';
const RegionFilter = ({ options, value, onChange, }) => (<>
    <InputLabel margin="dense" shrink>
      Region
    </InputLabel>
    <Select native name="regionFilter" onChange={onChange} value={value} style={{ width: 400 }} variant="standard">
      <option value="">&lt;All&gt;</option>
      {options.map((region) => (<option key={region.UID} value={region.UID}>
          {region.Name}
        </option>))}
    </Select>
  </>);
export default RegionFilter;
