import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectVehicle } from 'src/AdminApp/modules/quickRo/selectors';
import CarMakeSquareImage from 'src/PopsApp/components/CarMakeSquareImage';
import { useParams } from 'react-router';
import { Tab } from 'src/components/mui';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import { VehicleDetails, VehicleInfo, YearMakeModelInfo, Bullet, TokensTabs, TokensTabPanel, } from './TokensSubscriptionsDrawer.styles';
import TokensDisplay from './TokensDisplay';
const TokensSubscriptionsDrawer = ({ open, onClose, extVehicle, }) => {
    var _a;
    const params = useParams();
    const [tabValue, setTabValue] = useState(0);
    let vehicle = useSelector(selectVehicle);
    if (extVehicle) {
        vehicle = extVehicle;
    }
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return !vehicle ? null : (<SimpleDrawer open={open} onClose={onClose} title="Subscriptions & Tokens">
      <VehicleInfo>
        <YearMakeModelInfo>
          <CarMakeSquareImage make={vehicle.make}/>
          {`${vehicle.make} ${vehicle.model} ${vehicle.year}`}
        </YearMakeModelInfo>

        <VehicleDetails>
          {vehicle.engineDescription}{' '}
          {vehicle.vin && vehicle.engineDescription && <Bullet> • </Bullet>}
          {vehicle.vin && <span>VIN: {vehicle.vin}</span>}
        </VehicleDetails>
      </VehicleInfo>

      <TokensTabs value={tabValue} onChange={handleTabChange} variant="fullWidth" indicatorColor="primary" textColor="primary">
        <Tab label="Tokens"/>
        <Tab label="Subscriptions"/>
      </TokensTabs>

      <TokensTabPanel value={tabValue} index={0}>
        <TokensDisplay consumerId={+params.consumerId} vehicleId={(_a = vehicle.consumerCarId) !== null && _a !== void 0 ? _a : vehicle.id}/>
      </TokensTabPanel>

      <TokensTabPanel value={tabValue} index={1}>
        &nbsp;
      </TokensTabPanel>
    </SimpleDrawer>);
};
export default TokensSubscriptionsDrawer;
