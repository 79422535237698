import React from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Button, Grid, Icon } from 'src/components/mui';
import Module from 'src/components/layout/Module';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import { SimpleModal } from 'src/components/layout';
import AddFleetRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddFleetRODrawer';
import { TextField } from 'src/AdminApp/components/form';
import ImportCsvModal from 'src/AdminApp/components/fleets/Modals';
import useFleetVehicles from 'src/AdminApp/containers/fleets/FleetVehicles/useFleetVehicles';
import CarListItem from './Item';
import FleetCarDrawer from './FleetCarDrawer';
const Wrapper = styled.div `
  padding: 20px;
`;
const GridTitle = styled(Grid) `
  width: 90%;
`;
const BigButton = styled(Button) `
  height: 100%;
  width: 45px;
  margin-left: 8px;
`;
const FLEET_VEHICLES_COLUMNS = [
    { title: 'Year', key: 'carYear', sortable: true },
    { title: 'Make', key: 'carMake', sortable: true },
    { title: 'Model', key: 'carModel', sortable: true },
    { title: 'Engine', key: 'engineDescription', sortable: true },
    { title: 'VIN', key: 'vin', sortable: true },
    { title: 'LicensePlate', key: 'plateNumber', sortable: true },
    { title: 'Mileage', key: 'carMileage', sortable: true },
    { title: 'Nickname', key: 'nickname', sortable: true },
    { title: 'Last Serviced', key: 'lastServiceDate', sortable: true },
    {
        title: 'Responsible Party',
        key: 'fleetResponsiblePartyIds',
        sortable: false,
    },
    { title: 'Actions', key: '', sortable: false },
];
const FleetVehicles = () => {
    var _a, _b, _c;
    const { setImportCsvIsOpen, onAddCar, onSearchCars, onLoadData, pagination, cars, isFetching, onAddRequestOpen, setEditCarIsOpen, getCarById, setRemoveCarIsOpen, addCarQuickROIsOpen, setAddCarQuickROIsOpen, removeCarIsOpen, onRemoveCarSubmit, importCsvIsOpen, onImportSubmit, editCarIsOpen, onSaveVehicle, fleet, allResponsibleParties, } = useFleetVehicles();
    return (<>
      <Module title="Fleet Vehicles" actionButton={<GridTitle container style={{ justifyContent: 'flex-end' }}>
            <Button startIcon={<Icon>add_circle</Icon>} onClick={() => setImportCsvIsOpen(true)}>
              Import CSV
            </Button>
            <Button startIcon={<Icon>add_circle</Icon>} onClick={onAddCar}>
              Add Vehicle
            </Button>
            <Formik enableReinitialize initialValues={{
                query: '',
            }} onSubmit={({ query }, { setSubmitting }) => {
                const values = {
                    query: query.trim(),
                };
                onSearchCars(values);
                setSubmitting(false);
            }}>
              {({ isSubmitting, values }) => (<Form>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    minWidth: '300px',
                }}>
                    <TextField placeholder="VIN, plate number, nickname" label="Search Query" name="query" variant="outlined" autoFocus fullWidth/>
                    <BigButton size="small" variant="contained" fullWidth disabled={isSubmitting || isFetching} onClick={() => {
                    var _a, _b, _c;
                    return onLoadData({
                        page: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _a !== void 0 ? _a : 0,
                        size: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _b !== void 0 ? _b : 10,
                        sort: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _c !== void 0 ? _c : ['carMake', 'asc'],
                    });
                }}>
                      Reset
                    </BigButton>
                    <BigButton size="small" variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting || isEmpty(values.query) || isFetching}>
                      Search
                    </BigButton>
                  </div>
                </Form>)}
            </Formik>
          </GridTitle>}>
        {((_a = cars === null || cars === void 0 ? void 0 : cars.length) !== null && _a !== void 0 ? _a : 0) >= 0 ? (<SimpleTable hideTopPagination data={cars} pagination={pagination} isFetching={isFetching} sort={(_b = pagination.sort) !== null && _b !== void 0 ? _b : []} onLoadData={onLoadData} columns={FLEET_VEHICLES_COLUMNS} renderItem={(item, index) => (<CarListItem {...item} key={`${index}`} fleet={fleet} onAddRequest={onAddRequestOpen} onEditVehicle={(id) => setEditCarIsOpen(getCarById(id))} onRemoveVehicle={(id) => { var _a; return setRemoveCarIsOpen((_a = getCarById(id)) !== null && _a !== void 0 ? _a : false); }} allResponsibleParties={allResponsibleParties}/>)}/>) : (<Wrapper>No Vehicles</Wrapper>)}

        <AddFleetRODrawer open={!!addCarQuickROIsOpen} onClose={() => setAddCarQuickROIsOpen(false)}/>

        <SimpleModal title="Confirm Delete" open={!!removeCarIsOpen} onClose={() => setRemoveCarIsOpen(false)} simpleTwoButtonRowProps={{
            onCancel: () => setRemoveCarIsOpen(false),
            onSubmit: onRemoveCarSubmit,
            submitText: 'Delete',
            submitIsDestructive: true,
        }}>
          Are you sure you want to remove this vehicle?
        </SimpleModal>
        <ImportCsvModal open={importCsvIsOpen} onClose={() => setImportCsvIsOpen(false)} onSubmit={onImportSubmit}/>
      </Module>
      <FleetCarDrawer open={!!editCarIsOpen} onClose={() => setEditCarIsOpen(false)} onSubmit={onSaveVehicle} vehicle={editCarIsOpen} childrenFleetIds={[...((_c = fleet === null || fleet === void 0 ? void 0 : fleet.childrenIds) !== null && _c !== void 0 ? _c : []), fleet === null || fleet === void 0 ? void 0 : fleet.id]}/>
    </>);
};
export default FleetVehicles;
