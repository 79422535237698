var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/state-in-constructor,react/no-access-state-in-setstate */
import React from 'react';
import styled from 'styled-components';
import { Paper, Typography } from 'src/components/mui';
import EditIcon from 'src/components/icons/EditIcon';
import { colors } from 'src/styles/theme';
const PaperWrapper = styled(Paper) `
  padding: 0 0 20px;
  width: 100%;
  margin-bottom: 32px;
`;
const Title = styled(Typography) `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${colors.darkGrey};
  background-color: ${colors.whiteSmokeGrey};
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 8px 10px;
  margin-bottom: 10px !important;
  font-size: 0.875rem;
  font-weight: bold;
`;
const Module = (_a) => {
    var { title, showEditButton = false, editable = false, toggleEditable = () => { }, actionButton = null, form, children } = _a, rest = __rest(_a, ["title", "showEditButton", "editable", "toggleEditable", "actionButton", "form", "children"]);
    return (<PaperWrapper {...rest}>
      <Title variant="h5">
        {title} {actionButton}{' '}
        {showEditButton && (<EditIcon onClick={() => {
                if (toggleEditable)
                    toggleEditable();
            }}/>)}
      </Title>
      {editable ? form : children}
    </PaperWrapper>);
};
export default Module;
