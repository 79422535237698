import * as Yup from 'yup';
const phoneValidation = Yup.string().matches(/^$|[2-9]{1}\d{2}[2-9]{1}\d{6}/, 'Phone Number is not valid');
const FMCEmailValidation = Yup.string().email('FMC Email must be a valid email');
const FMCValidation = Yup.object().shape({
    name: Yup.string().min(1).max(255).required('Name is required'),
    email: Yup.string()
        .email()
        .when('phone', {
        is: '',
        then: FMCEmailValidation.required('Email is required.'),
        otherwise: FMCEmailValidation,
    }),
    phone: phoneValidation.when('email', {
        is: '',
        then: phoneValidation.required('Phone is required.'),
        otherwise: phoneValidation,
    }),
    contactName: Yup.string().min(1).max(255).required(),
    submissionMethod: Yup.string()
        .nullable()
        .oneOf([null, 'AUTO_INTEGRATE', 'LEASE_PLAN', 'ARI', 'PHONE']),
}, [['email', 'phone']]);
export const FleetValidationSchema = Yup.object().shape({
    name: Yup.string().min(1).max(255).required(),
    dspName: Yup.string()
        .min(4, 'Must be exactly 4 characters')
        .max(4, 'Must be exactly 4 characters')
        .when('type', {
        is: 'Amazon',
        then: Yup.string().required('DSP Name is required.'),
        otherwise: Yup.string().nullable(),
    }),
    station: Yup.string()
        .min(4, 'Must be exactly 4 characters')
        .max(4, 'Must be exactly 4 characters')
        .when('type', {
        is: 'Amazon',
        then: Yup.string().required('Station is required.'),
        otherwise: Yup.string().nullable(),
    }),
    contactName: Yup.string().min(1).max(255).required(),
    phone: phoneValidation.required(),
    email: Yup.string().email().required(),
    dailyOpsContactName: Yup.string().min(1).max(255).nullable(),
    dailyOpsContactPhone: phoneValidation.nullable(),
    dailyOpsContactEmail: Yup.string().email().nullable(),
    submissionMethod: Yup.string()
        .nullable()
        .oneOf([null, 'AUTO_INTEGRATE', 'LEASE_PLAN', 'ARI', 'PHONE']),
    hasFMC: Yup.bool(),
    fleetManagementCompany: Yup.object().when('hasFMC', {
        is: true,
        then: FMCValidation,
    }),
    pmCsvImportKey: Yup.string().max(255),
    newBusinessHunterSystemUserId: Yup.number().nullable(),
    accountVpSystemUserId: Yup.number().nullable(),
    accountManagerSystemUserId: Yup.number().nullable(),
    removed: Yup.boolean().required(),
    marketingSourceId: Yup.string()
        .min(1)
        .max(255)
        .required('Marketing Source is required'),
});
export const tagsSchema = Yup.object().shape({
    tags: Yup.array().of(Yup.object().shape({
        key: Yup.string().min(1).max(255).required('This field is required.'),
        value: Yup.string()
            .min(1)
            .max(255)
            .when('key', {
            is: (val) => (val === null || val === void 0 ? void 0 : val.length) > 0,
            then: Yup.string().required('This field is required.'),
            otherwise: Yup.string().nullable(),
        }),
    })),
});
export const tempTagStore = {
    tags: [],
};
