import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byId: {},
    customerReviewByOrderId: {},
};
const successReducer = (state, action) => {
    const order = action.payload;
    const byId = Object.assign(Object.assign({}, state.byId), { [order.id]: order });
    return Object.assign(Object.assign({}, state), { byId });
};
const shopSelectSuccessReducer = (state) => {
    return Object.assign({}, state);
};
const customerReviewSuccessReducer = (state, action) => {
    const customerReview = action.payload;
    const customerReviewByOrderId = Object.assign(Object.assign({}, state.customerReviewByOrderId), { [action.meta.orderId]: customerReview });
    return Object.assign(Object.assign({}, state), { customerReviewByOrderId });
};
const reducers = handleActions({
    [ActionCreators.ORDER.SUCCESS.type]: successReducer,
    [ActionCreators.ORDER_SELECT_SHOP.SUCCESS.type]: shopSelectSuccessReducer,
    [ActionCreators.UPDATE_CUSTOMER_REVIEW.SUCCESS.type]: customerReviewSuccessReducer,
    [ActionCreators.GET_CUSTOMER_REVIEW.SUCCESS.type]: customerReviewSuccessReducer,
}, DEFAULT_STATE);
export default reducers;
