var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useState, useEffect } from 'react';
import { InputAdornment, TextField, Autocomplete } from 'src/components/mui';
import { Search as SearchIcon } from 'src/components/mui/icons';
import { debounce, get } from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { colors } from 'src/styles/theme';
const SimpleAutosuggest = ({ label = '', placeholder, fieldName, name, autoFocus = true, initialValue, inputId, groupBy, noOptions, disabled, onSearch, onSelect, onChange, onClear, suggestions, clearOnEmpty, clearOnChange, clearOnSelect, InputProps = {}, filterOptions, watchFields = [], fieldToInput = 'name', style, }) => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState(suggestions !== null && suggestions !== void 0 ? suggestions : []);
    const [loading, setLoading] = useState(false);
    const [watchForClear, setWatchForClear] = useState(!!clearOnEmpty);
    const clear = () => {
        setInputValue('');
        setOptions(suggestions !== null && suggestions !== void 0 ? suggestions : []);
        setValue(null);
    };
    const handleChange = (event, newValue, reason) => __awaiter(void 0, void 0, void 0, function* () {
        if (reason === 'clear') {
            clear();
            if (onClear) {
                onClear();
            }
            return;
        }
        setValue(newValue);
        if (!newValue)
            return;
        onSelect(newValue);
        setInputValue(get(newValue, fieldToInput));
        if (clearOnSelect) {
            clear();
        }
    });
    const handleInputChange = (event, newInputValue) => __awaiter(void 0, void 0, void 0, function* () {
        if ((event === null || event === void 0 ? void 0 : event.type) !== 'change')
            return;
        setInputValue(newInputValue);
        if (!(suggestions === null || suggestions === void 0 ? void 0 : suggestions.length)) {
            debouncedLoadSuggestions(newInputValue);
        }
        if (onChange) {
            onChange(newInputValue);
        }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedLoadSuggestions = useCallback(debounce((value) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const items = yield onSearch(value);
        setLoading(false);
        setOptions(items !== null && items !== void 0 ? items : []);
    }), 500), watchFields);
    // watch for external object and clear component when it is empty
    useEffect(() => {
        if (watchForClear && !clearOnEmpty) {
            clear();
        }
        if (!watchForClear && clearOnEmpty) {
            setWatchForClear(true);
        }
    }, [clearOnEmpty, watchForClear]);
    useEffect(() => {
        clear();
    }, [clearOnChange]);
    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
            setInputValue(get(initialValue, fieldToInput) || initialValue || '');
        }
    }, [initialValue]);
    useEffect(() => {
        if (suggestions) {
            setOptions(suggestions);
        }
    }, [suggestions]);
    return (<Autocomplete style={style} value={value} inputValue={inputValue !== null && inputValue !== void 0 ? inputValue : ''} options={options} onChange={handleChange} onInputChange={handleInputChange} autoHighlight disabled={disabled} loading={loading} groupBy={groupBy} noOptionsText={noOptions !== null && noOptions !== void 0 ? noOptions : 'No results found'} disablePortal getOptionLabel={fieldName ? (option) => option[fieldName] : (option) => option} renderOption={(props, option, { inputValue }) => {
            const title = fieldName ? option[fieldName] : option;
            const matches = match(title, inputValue, {
                insideWords: true,
            });
            const parts = parse(title, matches);
            const { className } = props, restProps = __rest(props, ["className"]);
            return (<li className={`AutoSuggest__option ${className}`} {...restProps}>
            <div>
              {parts.map((part, index) => (<span key={index} style={{
                        fontWeight: part.highlight ? 700 : 400,
                    }}>
                  {part.text}
                </span>))}
            </div>
          </li>);
        }} renderGroup={groupBy
            ? (params) => (<li key={params.key}>
                <div style={{
                    padding: '8px 16px',
                    backgroundColor: colors.GRAY_F2,
                    fontSize: '14px',
                    fontWeight: '200',
                }}>
                  {params.group}
                </div>
                <ul style={{ padding: 0, margin: 0 }}>{params.children}</ul>
              </li>)
            : undefined} renderInput={(params) => (<TextField {...params} label={label} InputProps={Object.assign(Object.assign(Object.assign({}, params.InputProps), InputProps), { placeholder,
                autoFocus,
                name, startAdornment: (<InputAdornment position="start">
                <SearchIcon color="disabled"/>
              </InputAdornment>), id: inputId !== null && inputId !== void 0 ? inputId : 'autoSuggestInput' })}/>)} filterOptions={filterOptions || undefined}/>);
};
export default SimpleAutosuggest;
