var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment/moment';
import { addVisit, getVisit, resetAddVisit, saveAddVisitTimeSlot, setAddVisitDuration, setAddVisitDurationTime, setAddVisitEndDateTime, setAddVisitExtLocation, setAddVisitLocation, setAddVisitStartDateTime, setAddVisitTechnicians, setAddVisitVans, setFleetsDatasource, setSelectedFleets, setSelectedStations, updateVisit, saveAddVisitTechniciansChoice, saveAddVisitTechnicianIds, saveAddVisitNumberOfTechnicians, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
import { toast } from 'src/components/SimpleToast';
import { TabNames } from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/AddFleetVisitDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { searchShifts } from 'src/AdminApp/modules/shifts/actions';
import { searchFleetsByStation, searchStationsByName, } from 'src/AdminApp/modules/fleets/actions';
import randomColor from 'src/FleetVisitsApp/pages/FleetVisits/components/RandomColor';
import { loadCurrentUser } from 'src/AdminApp/modules/users/actions';
import numeral from 'numeral';
import { clearSchedulingAvailability, fetchSchedulingAvailability, } from 'src/AdminApp/modules/quickRo/actions';
import { selectAddVisitFleets, selectAddVisitLocation, selectAddVisitStations, selectAddVisitStartDateTime, selectAddVisitTechnicians, selectAddVisitEndDateTime, selectAddVisitTechniciansChoice, selectAddVisitVanMechanicParings, selectAddVisitTimeSlot, selectAddVisitNumberOfTechnicians, selectFleetVisit, } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { selectCurrentUserId } from 'src/modules/auth/selectors';
import { selectCurrentAdminUserName } from 'src/AdminApp/modules/users/selectors';
import { selectShifts } from 'src/AdminApp/modules/shifts/selectors';
import { selectSchedulingAvailability } from 'src/AdminApp/modules/quickRo/selectors';
import useDebounce from 'src/modules/api/utils/useDebounce';
import { WARNINGS } from 'src/FleetVisitsApp/pages/FleetVisits/components/VisitCapacityWarningModal/WarningMessages';
import { DATE_TIME_FORMAT, DATE_YMD_FORMAT } from 'src/timeConstants';
const useAddFleetVisitDrawer = ({ open, onClose, refNum, openedFromFleet = false, newStartTime, reassignVanFunction, }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const visit = useSelector(selectFleetVisit);
    const [initialTechnicians, setInitialTechnicians] = useState([]);
    const [expectedRevenue, setExpectedRevenue] = useState();
    const [localDuration, setLocalDuration] = useState(8);
    const [selectedDurationString, setSelectedDurationString] = useState('8h');
    const [isMoreThanADay, setIsMoreThanADay] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [shift, setShift] = useState();
    const [selectedStartDateChanged, setSelectedStartDateChanged] = useState(false);
    const [showCapacityWarning, setShowCapacityWarning] = useState(false);
    const [unsavedDuration, setUnsavedDuration] = useState(undefined);
    const [unsavedNumberOfTechs, setUnsavedNumberOfTechs] = useState(undefined);
    const [unsavedTechniciansChoice, setUnsavedTechniciansChoice] = useState();
    const [unsavedVanMechanicPairings, setUnsavedVanMechanicPairings] = useState();
    const [warningMessage, setWarningMessage] = useState('');
    const selectedStations = useSelector(selectAddVisitStations);
    const selectedFleets = useSelector(selectAddVisitFleets);
    const selectedLocation = useSelector(selectAddVisitLocation);
    const selectedStartDateTime = useSelector(selectAddVisitStartDateTime);
    const selectedEndDateTime = useSelector(selectAddVisitEndDateTime);
    const selectedTechnicians = useSelector(selectAddVisitTechnicians);
    const currentUserId = useSelector(selectCurrentUserId);
    const currentUserName = useSelector(selectCurrentAdminUserName);
    const shifts = useSelector(selectShifts);
    const availability = useSelector(selectSchedulingAvailability);
    const techniciansChoice = useSelector(selectAddVisitTechniciansChoice);
    const vanMechanicPairings = useSelector(selectAddVisitVanMechanicParings);
    const numberOfTechnicians = useSelector(selectAddVisitNumberOfTechnicians);
    const selectedTimeSlot = useSelector(selectAddVisitTimeSlot);
    const localDurationDebounced = useDebounce(localDuration, 1000);
    useEffect(() => {
        if (tabValue === TabNames.Assisted && !visit) {
            dispatch(setAddVisitTechnicians([]));
            dispatch(setAddVisitVans([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabValue, visit]);
    useEffect(() => {
        let unmountedComponent = false;
        const startDateChanged = selectedStartDateTime &&
            selectedTimeSlot &&
            moment(selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.date, DATE_YMD_FORMAT).dayOfYear() !==
                moment(selectedStartDateTime.toDate(), DATE_YMD_FORMAT).dayOfYear();
        if (startDateChanged && !unmountedComponent) {
            setSelectedStartDateChanged(true);
        }
        return () => {
            unmountedComponent = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStartDateTime, selectedTimeSlot]);
    // set shift to the default when shifts are loaded
    useEffect(() => {
        var _a;
        if (!shift && !!shifts) {
            setShift((_a = shifts.find((shift) => shift.isDefault)) === null || _a === void 0 ? void 0 : _a.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shifts]);
    // EDITING VISIT CODE TO REPOPULATE AND CLEAR FORM
    useEffect(() => {
        if (!open) {
            setInitialTechnicians([]);
        }
        if (open) {
            dispatch(searchShifts({
                pageSize: 5,
                pageIndex: 0,
                status: 'ACTIVE',
            }));
        }
        if (open && !visit) {
            dispatch(setAddVisitStartDateTime(moment().hour(8).minute(0).second(0)));
            dispatch(setAddVisitEndDateTime(moment().hour(16).minute(0).second(0)));
            dispatch(setAddVisitDuration('8.0'));
        }
        if (open && visit) {
            setTabValue(!newStartTime ? TabNames.Assisted : TabNames.Manual);
            dispatch(saveAddVisitTechniciansChoice('specific'));
            const technicians = visit.resources
                .filter((r) => r.type === 'MECHANIC')
                .map((r) => { var _a; return (_a = r.technicianId) !== null && _a !== void 0 ? _a : ''; });
            setInitialTechnicians(!newStartTime ? technicians : []);
            setExpectedRevenue(visit.expectedRevenue);
            (() => __awaiter(void 0, void 0, void 0, function* () {
                if (visit.stationName) {
                    // SEARCH FOR STATION AND FLEET TO SET UP AUTOCOMPLETES
                    dispatch(searchStationsByName({ query: visit.stationName }));
                    dispatch(searchFleetsByStation({ station: visit.stationName, size: 100 }));
                    dispatch(setFleetsDatasource('STATION'));
                    // SET THE STATION THEN THE FLEETS
                    dispatch(setSelectedStations([visit.stationName]));
                }
                dispatch(setSelectedFleets(visit.fleets.map((f) => ({
                    id: f.fleetId,
                    name: f.fleetName,
                    station: visit.stationName,
                    consumer: { address: null },
                    dailyOpsContactName: f.dailyOpsContactName,
                    dailyOpsContactPhone: f.dailyOpsContactPhone,
                    dailyOpsContactEmail: f.dailyOpsContactEmail,
                }))));
                // SET LOCATION
                const { address } = visit;
                dispatch(setAddVisitLocation(address));
                dispatch(setAddVisitExtLocation(address));
                // SET START/END DATE TIME
                const start = moment(visit.actualStartLocal, DATE_TIME_FORMAT);
                const end = moment(visit.actualEndLocal, DATE_TIME_FORMAT);
                window.setTimeout(() => {
                    const diffMin = end.diff(start, 'minutes');
                    setLocalDuration(diffMin / 60);
                    const newStart = newStartTime
                        ? moment(newStartTime, DATE_TIME_FORMAT)
                        : undefined;
                    const newEnd = newStartTime
                        ? moment(moment(newStartTime)
                            .add(diffMin, 'minutes')
                            .format(DATE_TIME_FORMAT))
                        : undefined;
                    dispatch(setAddVisitStartDateTime(newStart !== null && newStart !== void 0 ? newStart : start));
                    dispatch(setAddVisitEndDateTime(newEnd !== null && newEnd !== void 0 ? newEnd : end));
                    const vans = visit.resources
                        .filter((r) => r.type === 'VAN')
                        .map((r) => r);
                    for (let i = 0; i < vans.length; i += 1) {
                        const van = vans[i];
                        van.resourceColor = randomColor(i);
                    }
                    const techniciansWithVans = visit.resources
                        .filter((r) => r.type === 'MECHANIC')
                        .map((r) => (Object.assign(Object.assign({}, r), { selectedVan: visit.resources.filter((re) => re.type === 'VAN' &&
                            (re.deliveryVanId === r.deliveryVanId ||
                                re.skeduloResourceId === r.deliveryVanId))[0] })));
                    const technicians = techniciansWithVans.map((r) => ({
                        name: `${r.technicianName} (${r.selectedVan.deliveryVanName})`,
                        deliveryVanName: r.selectedVan.deliveryVanName,
                        deliveryVanId: r.deliveryVanId,
                        technicianId: r.technicianId,
                        deliveryVanSkeduloResourceId: r.selectedVan.skeduloResourceId,
                    }));
                    // SET TECHNICIANS
                    dispatch(setAddVisitTechnicians(!newStartTime ? technicians : []));
                    const techVanPairings = techniciansWithVans.map((r) => {
                        var _a, _b;
                        return ({
                            deliveryVanId: r.deliveryVanId,
                            deliveryVanName: r.selectedVan.deliveryVanName,
                            deliveryVanSkeduloResourceId: r.selectedVan.skeduloResourceId,
                            mechanicFirstName: (_a = r.technicianName) === null || _a === void 0 ? void 0 : _a.split(' ')[0],
                            mechanicLastName: (_b = r.technicianName) === null || _b === void 0 ? void 0 : _b.split(' ')[1],
                            technicianId: r.technicianId,
                        });
                    });
                    dispatch(saveAddVisitTechnicianIds(new Set(techVanPairings)));
                    // SET VANS
                    dispatch(setAddVisitVans(vans));
                }, 500);
            }))();
        }
        else if (!open && visit) {
            dispatch(resetAddVisit());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, visit, newStartTime]);
    useEffect(() => {
        // CALCULATE IS TWO DAYS WHENEVER THE END TIME IS CHANGED
        setIsMoreThanADay(selectedStartDateTime.dayOfYear() !== selectedEndDateTime.dayOfYear());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEndDateTime]);
    useEffect(() => {
        if (!currentUserName && currentUserId && open) {
            dispatch(loadCurrentUser());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserName, currentUserId, open]);
    useEffect(() => {
        dispatch(setAddVisitDuration(localDuration));
        const formattedInput = numeral(localDuration).format('0.00');
        const timeSplit = formattedInput.toString().split('.');
        let minutes = 0;
        if (timeSplit.length === 2) {
            minutes = (+timeSplit[1] * 60) / 100;
        }
        const hours = timeSplit[0];
        const hoursString = `${hours}h`;
        const minutesString = minutes > 0 ? `${minutes}m` : '';
        dispatch(setAddVisitDurationTime(`${hours}:${minutes}`));
        setSelectedDurationString(`${hoursString} ${minutesString}`);
        dispatch(setAddVisitEndDateTime(selectedStartDateTime
            .clone()
            .add(hours, 'hours')
            .add(minutes, 'minutes')));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localDuration, selectedStartDateTime]);
    const handleDurationChange = (value) => {
        setUnsavedDuration(value);
        if (!shouldShowCapacityWarning({
            newDuration: value,
            message: WARNINGS.VISIT_DURATION_ADJUSTMENT,
        })) {
            setLocalDuration(value);
        }
    };
    useEffect(() => {
        if (localDuration) {
            setLocalDuration(localDuration);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (tabValue === TabNames.Manual) {
            dispatch(saveAddVisitTimeSlot(undefined));
            return;
        }
        if (selectedLocation && shift) {
            if (((techniciansChoice === 'specific' || !techniciansChoice) &&
                vanMechanicPairings.size === 0) ||
                (techniciansChoice === 'byNumber' &&
                    (isNaN(numberOfTechnicians) || numberOfTechnicians === 0))) {
                dispatch(clearSchedulingAvailability());
                return;
            }
            const techs = Array.from(vanMechanicPairings).map((pair) => pair.technicianId);
            const vans = Array.from(vanMechanicPairings).map((pair) => pair.deliveryVanSkeduloResourceId);
            dispatch(saveAddVisitTimeSlot(undefined));
            dispatch(fetchSchedulingAvailability({
                maxLeadTime: 0,
                shift,
                tags: [],
                deliveryAddressZip: selectedLocation.zip,
                latitude: selectedLocation.latitude,
                longitude: selectedLocation.longitude,
                duration: localDuration,
                numberOfMechanics: techniciansChoice === 'byNumber' ? numberOfTechnicians : undefined,
                mechanicsIds: techniciansChoice === 'specific' ? techs : undefined,
                skeduloVanIds: techniciansChoice === 'specific' ? vans : undefined,
                repairOrderPartStatus: 'PENDING',
                automaticOrderEnabled: false,
                isFleetVisit: true,
            }));
        }
        else if (Object.keys(availability).length > 0) {
            dispatch(clearSchedulingAvailability());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedLocation,
        localDurationDebounced,
        shift,
        techniciansChoice,
        numberOfTechnicians,
        vanMechanicPairings,
        tabValue,
    ]);
    const shouldShowCapacityWarning = ({ newDuration, newNumberOfTechs, message, }) => {
        var _a, _b;
        if (!visit)
            return false;
        let totalAvailableMinutes = (_a = visit.totalAvailableLaborMinutes) !== null && _a !== void 0 ? _a : 0;
        const scheduledMinutes = (_b = visit.totalScheduledLaborMinutes) !== null && _b !== void 0 ? _b : 0;
        // CHANGING OF NUMBER OF TECHS
        if (newNumberOfTechs) {
            totalAvailableMinutes = localDuration * 60 * newNumberOfTechs;
            // CHANGING DURATION
        }
        else if (newDuration) {
            totalAvailableMinutes = newDuration * 60 * numberOfTechnicians;
        }
        // CANNOT SHRINK THE VISIT IF WE ALREADY HAVE SCHEDULED WORK
        if (totalAvailableMinutes < scheduledMinutes) {
            setWarningMessage(message);
            setShowCapacityWarning(true);
            return true;
        }
        return false;
    };
    const onCapacityWarningClose = (confirm) => {
        setShowCapacityWarning(false);
        if (confirm) {
            if (unsavedDuration) {
                setLocalDuration(unsavedDuration);
            }
            if (unsavedNumberOfTechs) {
                dispatch(saveAddVisitNumberOfTechnicians(unsavedNumberOfTechs > 20 ? 1 : unsavedNumberOfTechs));
            }
            if (unsavedVanMechanicPairings) {
                dispatch(saveAddVisitTechnicianIds(unsavedVanMechanicPairings));
            }
            if (unsavedTechniciansChoice) {
                dispatch(saveAddVisitTechniciansChoice(unsavedTechniciansChoice));
            }
        }
        setUnsavedDuration(undefined);
        setUnsavedNumberOfTechs(undefined);
        setUnsavedVanMechanicPairings(undefined);
        setUnsavedTechniciansChoice(undefined);
    };
    const handleClose = () => {
        dispatch(resetAddVisit());
        onClose();
    };
    const adjustTimeForSubmit = ({ tab, duration, startDate, endDate, timeSlotDate, timeSlotTime, }) => {
        let actualStartLocal;
        let actualEndLocal;
        if (tab === TabNames.Manual && startDate && endDate) {
            actualStartLocal = startDate.format(DATE_TIME_FORMAT);
            actualEndLocal = endDate.format(DATE_TIME_FORMAT);
        }
        else if (tabValue === TabNames.Assisted && timeSlotDate && timeSlotTime) {
            // JS DATE CANNOT PARSE THE AM/PM OF THE TIME SLOT, SO WE HAVE TO ADJUST THE TIME TO 24 HOURS.
            // TIME SLOT EXAMPLE: 03:15 PM = 15:15
            const timeIsPm = timeSlotTime.includes('PM');
            const minMatch = timeSlotTime.match(/:\d\d/gi);
            let min = '00';
            if (minMatch) {
                min = minMatch[0].replace(':', '');
            }
            const timeHour = timeSlotTime.replace(/:\d\d\s(AM|PM)/gi, '');
            let timeAdjusted = +timeHour;
            if (timeIsPm && +timeHour !== 12) {
                timeAdjusted = +timeHour + 12;
            }
            else if (!timeIsPm && +timeHour === 12) {
                timeAdjusted = 0;
            }
            const startLocal = moment(`${timeSlotDate} ${timeAdjusted}:${min}`);
            actualStartLocal = startLocal.format(DATE_TIME_FORMAT);
            actualEndLocal = startLocal
                .clone()
                .add(duration, 'hours')
                .format(DATE_TIME_FORMAT);
        }
        return {
            actualStartLocal,
            actualEndLocal,
        };
    };
    const prepareFleetResourcesForSubmit = () => {
        var _a, _b;
        let techsReduced = [];
        let vansReduced = [];
        if (tabValue === TabNames.Manual) {
            const techs = selectedTechnicians.map((t) => ({
                deliveryVanId: t.deliveryVanId,
                type: 'MECHANIC',
                technicianId: t.technicianId,
            }));
            techsReduced = Array.from(new Set(techs.map((tech) => tech.technicianId))).map((technicianId) => {
                return techs.find((tech) => tech.technicianId === technicianId);
            });
            const vans = selectedTechnicians.map((t) => ({
                deliveryVanId: t.deliveryVanId,
                type: 'VAN',
                skeduloResourceId: t.deliveryVanSkeduloResourceId,
            }));
            vansReduced = Array.from(new Set(vans.map((van) => van.deliveryVanId))).map((deliveryVanId) => {
                return vans.find((van) => van.deliveryVanId === deliveryVanId);
            });
        }
        else {
            (_a = selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.timeSlot.vans) === null || _a === void 0 ? void 0 : _a.forEach((van) => {
                vansReduced.push({
                    deliveryVanId: van.deliveryVanId,
                    skeduloResourceId: van.skeduloResourceId,
                    type: 'VAN',
                });
            });
            (_b = selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.timeSlot.mechanics) === null || _b === void 0 ? void 0 : _b.forEach((tech, idx) => {
                if (vansReduced && vansReduced[idx]) {
                    techsReduced.push({
                        technicianId: tech.technicianId,
                        type: 'MECHANIC',
                        deliveryVanId: vansReduced[idx].deliveryVanId,
                    });
                }
            });
        }
        return [...techsReduced, ...vansReduced];
    };
    const prepareDescriptionForSubmit = () => {
        const visitFor = selectedStations.length > 0
            ? selectedStations.join(', ')
            : selectedFleets.map((f) => f.name)[0];
        const expectedRevenueDesc = expectedRevenue
            ? `\nExpected Revenue: $${expectedRevenue}`
            : '';
        return `Visit for ${visitFor}${expectedRevenueDesc}`;
    };
    const handleSubmit = (resetParts) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        const { actualStartLocal, actualEndLocal } = adjustTimeForSubmit({
            tab: tabValue,
            duration: localDuration,
            startDate: selectedStartDateTime,
            endDate: selectedEndDateTime,
            timeSlotDate: selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.date,
            timeSlotTime: selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.time,
        });
        const submitPayload = {
            body: {
                address: selectedLocation,
                stations: selectedStations,
                fleets: selectedFleets.map((f) => ({
                    fleetId: f.id,
                    fleetName: f.name,
                    dailyOpsContactName: f.dailyOpsContactName,
                    dailyOpsContactPhone: f.dailyOpsContactPhone,
                    dailyOpsContactEmail: f.dailyOpsContactEmail,
                })),
                resources: prepareFleetResourcesForSubmit(),
                actualStartLocal,
                actualEndLocal,
                timezone: selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.timezone,
                expectedRevenue,
                description: prepareDescriptionForSubmit(),
                resetParts,
            },
        };
        if (!visit) {
            const addResponse = yield dispatch(addVisit(submitPayload));
            if (!(addResponse === null || addResponse === void 0 ? void 0 : addResponse.error)) {
                analyticsTrackEvent('New Visit Created', {
                    'Visit ID': addResponse.payload.refNum,
                    'Admin Name': currentUserName,
                    'Fleets': selectedFleets.map((f) => f.name).join(', '),
                    'Stations': selectedStations.join(', '),
                });
                if (!openedFromFleet) {
                    history.push(`/admin/visits/${addResponse.payload.refNum}`);
                }
                handleClose();
            }
        }
        else {
            // UPDATE VISIT
            const updateResponse = yield dispatch(updateVisit(Object.assign({ refNum: visit.refNum }, submitPayload)));
            if (!(updateResponse === null || updateResponse === void 0 ? void 0 : updateResponse.error)) {
                toast.success('Visit updated successfully');
                if (refNum) {
                    dispatch(getVisit({ refNum }));
                }
                if (reassignVanFunction) {
                    reassignVanFunction();
                }
                handleClose();
            }
        }
        setIsSubmitting(false);
    });
    return {
        handleSubmit,
        handleClose,
        isSubmitting,
        selectedFleets,
        localDuration,
        setExpectedRevenue,
        setTabValue,
        tabValue,
        selectedTechnicians,
        selectedTimeSlot,
        selectedLocation,
        expectedRevenue,
        setShift,
        shift,
        shifts,
        selectedEndDateTime,
        selectedDurationString,
        isMoreThanADay,
        handleDurationChange,
        initialTechnicians,
        setLocalDuration,
        adjustTimeForSubmit,
        selectedStartDateChanged,
        showCapacityWarning,
        onCapacityWarningClose,
        shouldShowCapacityWarning,
        setUnsavedNumberOfTechs,
        setUnsavedTechniciansChoice,
        setUnsavedVanMechanicPairings,
        warningMessage,
    };
};
export default useAddFleetVisitDrawer;
