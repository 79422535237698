import React from 'react';
import { useMediaQuery } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import { ActionsBlockDesktop } from 'src/containers/layout/ActionsBlock/ActionsBlock.desktop';
import { ActionsBlockMobile } from 'src/containers/layout/ActionsBlock/ActionsBlock.mobile';
export const TechActionsBlock = ({ actions, children, childAtEnd, childAtStart, }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return isMobile ? (<ActionsBlockMobile actions={actions}/>) : (<ActionsBlockDesktop actions={actions} childAtEnd={childAtEnd} childAtStart={childAtStart}>
      {children}
    </ActionsBlockDesktop>);
};
