import { toEnum } from 'src/models/enums';
export const FluidAdditionalInfoOptions = toEnum({
    brandOrManufacturer: 'Brand/Manufacturer',
    containerSize: 'Container Size',
    dotRating: 'DOT Rating',
    oilBlend: 'Oil Blend',
    refrigerantType: 'Refrigerant Type',
    viscosity: 'Viscosity',
    weight: 'Weight',
    other: 'Other',
});
export default FluidAdditionalInfoOptions;
