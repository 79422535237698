import { toEnum } from 'src/models/enums';
import { toStatusWithResolutionEnum } from './StatusWithResolutionEnum';
export const AppointmentStatus = toStatusWithResolutionEnum({
    PENDING: 'Pending',
    CONFIRMED: 'Confirmed',
    CANCELED: 'Canceled',
    CLOSED: 'Closed',
}, ['CANCELED']);
export const AppointmentResolution = toEnum({
    CUSTOMER_CANCELED: 'Customer Canceled',
    CUSTOMER_NO_SHOW: 'Customer No Show',
    TECHNICIAN_ISSUE: 'Technician Issue',
    VEHICLE_ACCESS: 'Vehicle Access',
    ENVIRONMENTAL: 'Environmental',
    SHOP_CANCELED: 'Shop Canceled',
    SHOP_REJECTED_REQUEST: 'Shop Rejected Request',
    RESCHEDULED: 'Rescheduled',
    UNKNOWN_ARRIVAL: 'Unknown Arrival',
    UNKNOWN: 'Unknown',
    OTHER: 'Other',
});
export default AppointmentStatus;
