import React from 'react';
import { Form, Formik } from 'formik';
import { Grid } from 'src/components/mui';
import FilterButtons from 'src/components/dashboard/DashboardFilter/FilterButtons';
import { TextField } from 'src/AdminApp/components/form';
import useShiftsFilters from './useShiftsFilters';
const ShiftsFilters = ({ onClear, onSearch }) => {
    const { submitting, queryString, clearFilter, handleReset, handleSubmit, handleQueryChange, } = useShiftsFilters({ onClear, onSearch });
    return (<Formik enableReinitialize initialValues={{ query: '' }} onSubmit={handleSubmit}>
      {() => (<Form>
          <FilterButtons hideQuery onClear={clearFilter} onReset={handleReset} queryLabelText="Search Query" isSubmitting={submitting}>
            <Grid item xs={12} md={6}>
              <TextField autoFocus fullWidth disabled={submitting} label="Search Query" name="query" placeholder="Name" variant="outlined" value={queryString} onChange={handleQueryChange}/>
            </Grid>
          </FilterButtons>
        </Form>)}
    </Formik>);
};
export default ShiftsFilters;
