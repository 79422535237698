var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import queryString from 'query-string';
import { RSAA, } from 'redux-api-middleware';
import { selectAccessToken } from 'src/modules/auth/selectors';
const getEndpoint = (endpoint, params) => {
    if (!params) {
        return endpoint;
    }
    const query = queryString.stringify(params);
    return query ? `${endpoint}?${query}` : endpoint;
};
const getBody = (method, body, formData) => {
    return method === 'GET' || method === 'HEAD'
        ? undefined
        : formData || JSON.stringify(body);
};
const getHeadersFn = (headers, formData, includeAuthHeader = true) => (state) => {
    const headersObject = Object.assign({}, headers);
    if (includeAuthHeader) {
        const accessToken = selectAccessToken(state);
        if (accessToken) {
            headersObject.Authorization = `Bearer ${accessToken}`;
        }
    }
    if (!formData) {
        headersObject['Content-Type'] = 'application/json';
    }
    return headersObject;
};
const getFetchFn = (signal) => (...fetchArgs) => __awaiter(void 0, void 0, void 0, function* () {
    const [url, options] = fetchArgs;
    if (signal) {
        options.signal = signal;
    }
    const result = yield window.fetch(url, options);
    return result;
});
const getBailoutFn = (url) => (state) => {
    // ABORT: an XHR request to a protected end point is executed,
    // but the user does not have an accessToken in the auth store.
    if (url.indexOf('/admin') !== -1 && !selectAccessToken(state)) {
        console.warn('ABORT: fetch attempted with no accessToken');
        return true;
    }
    // ABORT: an XHR request queued up from a protected page
    // is eventually executed after the user has been redirected
    // to the login page due to an auth error from the API.
    if (window.location.pathname === '/login' && url !== '/auth/login') {
        console.warn('ABORT: fetch attempted from login page');
        return true;
    }
    return false;
};
const enhanceFailureMeta = (rsaaTypes, url) => {
    const failureTypes = rsaaTypes.filter((rsaaType) => { var _a; return (_a = rsaaType === null || rsaaType === void 0 ? void 0 : rsaaType.type) === null || _a === void 0 ? void 0 : _a.endsWith('/FAILURE'); });
    if (failureTypes.length === 0)
        return;
    const failureType = failureTypes[0];
    const originalMeta = failureType.meta;
    switch (typeof originalMeta) {
        case 'function':
            failureType.meta = () => (Object.assign({ url }, originalMeta()));
            break;
        case 'object':
            failureType.meta = () => (Object.assign({ url }, originalMeta));
            break;
        case 'undefined':
            failureType.meta = () => ({ url });
            break;
        default:
        // no-op. we'll just preserve the existing meta value since we would
        // not be able to safely convert a value type (e.g. String, Number)
        // to an object without potentially breaking downstream code.
    }
};
const _createAPIAction = (_a, includeAuthHeader) => {
    var { types, endpoint, method = 'GET', headers = {}, body = {}, params, formData, signal } = _a, rest = __rest(_a, ["types", "endpoint", "method", "headers", "body", "params", "formData", "signal"]);
    enhanceFailureMeta(types, endpoint);
    return {
        [RSAA]: Object.assign({ types, endpoint: getEndpoint(endpoint, params), method, headers: getHeadersFn(headers, formData, includeAuthHeader), body: getBody(method, body, formData), fetch: getFetchFn(signal), bailout: getBailoutFn(endpoint) }, rest),
    };
};
export const createAPIAuthAction = (params) => {
    return _createAPIAction(params, false);
};
const createAPIAction = (params) => {
    return _createAPIAction(params, true);
};
export default createAPIAction;
