import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'src/components/mui';
import { ChevronRight, LocationOn } from 'src/components/mui/icons';
import { colors, theme } from 'src/styles/theme';
import { flexCenter } from 'src/styles/styled';
import FloatControlButtons, { ControlButtonsBlock, } from 'src/components/form/FloatControlButtons';
import PlacesACWithServiceAreas from 'src/components/form/PlacesACWithServiceAreas';
import useIsMobile from 'src/hooks/useIsMobile';
const LocationWrapper = styled('div') `
  padding: 24px 0 10px 0;
  border-bottom: 1px solid ${colors.GRAY_CC};
  margin-bottom: 20px;
  ${theme.breakpoints.up('md')} {
    padding-top: 54px;
    border-bottom: 0;
    margin-bottom: 0;
  }
`;
const LocationContainer = styled('div') `
  ${theme.breakpoints.up('md')} {
    ${flexCenter};

    background-color: ${colors.GRAY_F8};
    border-radius: 24px;
    flex-direction: column;
    min-height: 115px;
    padding: 16px;
    width: 100%;
  }
`;
const TitleWrapper = styled('div') `
  ${flexCenter};

  justify-content: stretch;
`;
const TitleIcon = styled(LocationOn) `
  color: ${colors.lightGrey};
  font-size: 1.125rem;
  margin-right: 8px;
`;
const Title = styled(Typography) `
  font-weight: normal;
`;
const FieldWrapper = styled('div') `
  ${flexCenter};

  align-items: var(--align, center);
  flex: 2;
  justify-content: space-between;
  padding-left: 26px;
  position: relative;

  ${ControlButtonsBlock} {
    top: 45%;
  }
`;
const AddressContainer = styled('div') ``;
const AddressLine = styled(Typography) `
  color: ${colors.darkGrey};
  font-weight: 700;
`;
const InvalidContainer = styled('div') `
  align-self: flex-end;
  margin-bottom: 8px;
`;
const EditBtn = styled(Button) `
  align-self: flex-end;
`;
const EditChevron = styled(ChevronRight) `
  align-self: center;
`;
const labelProps = {
    style: { display: 'none' },
};
const inputProps = {
    style: {
        fontSize: '0.875rem',
        margin: 0,
        paddingRight: 0,
    },
};
const style = { '--align': 'flex-start' };
const Location = ({ address, onChange }) => {
    const isMobile = useIsMobile();
    const [edit, setEdit] = useState(false);
    const [tempAddress, setTempAddress] = useState();
    const handleEdit = () => setEdit(true);
    const handleSelectAddress = (args) => {
        if (!args)
            return;
        setTempAddress({
            city: args.city,
            latitude: args.latitude,
            longitude: args.longitude,
            state: args.state,
            street: args.street,
            timezone: args.timezone,
            zip: args.zip,
            country: args.country,
        });
    };
    const handleCancel = () => {
        setEdit(false);
        setTempAddress(undefined);
    };
    const handleAccept = () => {
        if (tempAddress) {
            onChange(tempAddress);
        }
        handleCancel();
    };
    const locationAddress = {
        city: address === null || address === void 0 ? void 0 : address.city,
        latitude: address === null || address === void 0 ? void 0 : address.latitude,
        longitude: address === null || address === void 0 ? void 0 : address.longitude,
        state: address === null || address === void 0 ? void 0 : address.state,
        street: (address === null || address === void 0 ? void 0 : address.streetLine1) || (address === null || address === void 0 ? void 0 : address.street),
        timezone: address === null || address === void 0 ? void 0 : address.timezone,
        zip: address === null || address === void 0 ? void 0 : address.zip,
    };
    const validZip = (address === null || address === void 0 ? void 0 : address.zip) != null;
    return (<LocationWrapper>
      <LocationContainer>
        <TitleWrapper>
          <TitleIcon />
          <Title variant="subtitle1">Service Location</Title>
        </TitleWrapper>
        {!edit ? (<FieldWrapper onClick={() => isMobile && handleEdit()}>
            {!validZip ? (<InvalidContainer>
                <AddressLine variant="body2">Invalid Address</AddressLine>
              </InvalidContainer>) : (<AddressContainer>
                <AddressLine variant="body2">
                  {(address === null || address === void 0 ? void 0 : address.streetLine1) || (address === null || address === void 0 ? void 0 : address.street)}
                </AddressLine>
                <AddressLine variant="body2">
                  {`${address === null || address === void 0 ? void 0 : address.city}, ${address === null || address === void 0 ? void 0 : address.state} ${address === null || address === void 0 ? void 0 : address.zip}`}
                </AddressLine>
              </AddressContainer>)}
            {isMobile ? (<EditChevron />) : (<EditBtn color="primary" onClick={handleEdit}>
                Edit
              </EditBtn>)}
          </FieldWrapper>) : (<FieldWrapper style={style}>
            <PlacesACWithServiceAreas address={tempAddress || locationAddress} displayMap={false} inputLabelProps={labelProps} inputProps={inputProps} onSelect={handleSelectAddress}/>
            <FloatControlButtons onApprove={handleAccept} onClose={handleCancel}/>
          </FieldWrapper>)}
      </LocationContainer>
    </LocationWrapper>);
};
export default Location;
