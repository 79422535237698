import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, FormControl, FormControlLabel, Grid, InputLabel, Select, Switch, TextField, } from 'src/components/mui';
import { AddCircle } from 'src/components/mui/icons';
import { createMenuItems } from 'src/AdminApp/components/form/utils';
import FluidCapacityUnit from 'src/AdminApp/models/enums/FluidCapacityUnit';
import FluidType from 'src/AdminApp/models/enums/FluidType';
import { SimpleModal } from 'src/components/layout';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import { selectAuthorizedPartsIntegratedOrdering } from 'src/modules/auth/selectors';
import { selectOrderParts } from 'src/PopsApp/modules/parts/selectors';
import { colors } from 'src/styles/theme';
import AdditionalDetailsDrawer from '../AdditionalDetailsDrawer/AdditionalDetailsDrawer';
import AddComponentAdditionalInfo from '../AddComponentDrawer/AddComponentAdditionalInfo';
const isAvailableForIntegration = (componentsAvailability, part) => {
    var _a, _b;
    return !!((_a = componentsAvailability === null || componentsAvailability === void 0 ? void 0 : componentsAvailability.availableRepairComponentIds) === null || _a === void 0 ? void 0 : _a.includes((_b = part.pricingData) === null || _b === void 0 ? void 0 : _b.repairComponentId));
};
const EditPart = ({ part, onSaveNext, isFluid, onChangeInput, onBlurInput, onPickPart, componentsAvailability, isDialog = false, setIsIntegrated, setSelectedPart, distributor, wrongPartNumbers, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    const itemName = isFluid ? 'Fluid' : 'Part';
    const isTireComponent = ((_a = part.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'tire';
    const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false);
    const [additionalInfoMap, setAdditionalInfoMap] = useState(null);
    const integratedOrderingEnabled = useSelector(selectAuthorizedPartsIntegratedOrdering);
    const orderParts = useSelector(selectOrderParts);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const confirmationMessage = isFluid
        ? ' You have entered in a fluid capacity greater than 25, are you sure? If so, show me the money!'
        : 'My precious you have entered in a quantity greater than 25, are you sure?';
    const [prevQtyValue, setPrevQtyValue] = useState(isTireComponent ? 1 : (_b = part.quantity) !== null && _b !== void 0 ? _b : 0);
    const [prevFluidValue, setPrevFluidValue] = useState((_c = part.capacity) !== null && _c !== void 0 ? _c : 0);
    useEffect(() => {
        if (isDialog) {
            const currentPart = orderParts.find((value) => value.part.id === part.id);
            if (currentPart) {
                setIsIntegrated(currentPart === null || currentPart === void 0 ? void 0 : currentPart.isIntegrated);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDialog, orderParts]);
    const partNameClasses = classNames('editField', {
        'editField--partName': integratedOrderingEnabled && part.isIntegrated,
    });
    const resetQuantity = () => {
        const inputType = !isFluid || part.isIntegrated ? 'quantity' : 'capacity';
        onChangeInput(inputType, true)({
            target: {
                value: inputType === 'quantity' ? prevQtyValue : prevFluidValue,
            },
        });
        setIsModalOpen(false);
    };
    const setAdditionalInfo = (fieldName, info) => {
        var _a;
        setAdditionalInfoMap(info);
        onChangeInput(fieldName, false)({
            target: {
                value: (_a = info === null || info === void 0 ? void 0 : info.filter((info) => info.name !== '' && info.value !== '')) === null || _a === void 0 ? void 0 : _a.reduce((obj, item) => (Object.assign(Object.assign({}, obj), { [item.name]: item.value })), {}),
            },
        });
    };
    const onQuantityBlur = () => {
        var _a, _b;
        if (prevQtyValue === part.quantity && prevFluidValue === part.capacity)
            return;
        if (part.quantity > 25 || part.capacity > 25.0) {
            setIsModalOpen(true);
        }
        else {
            setPrevQtyValue((_a = part.quantity) !== null && _a !== void 0 ? _a : 0);
            setPrevFluidValue((_b = part.capacity) !== null && _b !== void 0 ? _b : 0);
        }
    };
    const isPartReadyToBeAdded = () => {
        var _a, _b, _c, _d;
        if ((_a = part.meta) === null || _a === void 0 ? void 0 : _a.isFluid) {
            const fluidFields = part.name &&
                part.capacityUnit &&
                part.capacity &&
                part.type &&
                part.cost &&
                part.price >= 0 &&
                part.partNumber &&
                (part.coreChargeExpected ? ((_b = part.coreChargeCost) !== null && _b !== void 0 ? _b : 0) > 0 : true);
            return part.isIntegrated
                ? fluidFields && part.quantity && part.meta.externalPart
                : fluidFields;
        }
        return (part.name &&
            part.quantity &&
            part.cost &&
            part.price >= 0 &&
            part.partNumber &&
            (part.isIntegrated ? (_c = part === null || part === void 0 ? void 0 : part.meta) === null || _c === void 0 ? void 0 : _c.externalPart : true) &&
            (isTireComponent ? part === null || part === void 0 ? void 0 : part.partMetadata : true) &&
            (part.coreChargeExpected
                ? ((_d = part.coreChargeCost) !== null && _d !== void 0 ? _d : 0) > 0 || !part.createCore
                : true));
    };
    const isPartNotPickedUp = (_d = distributor === null || distributor === void 0 ? void 0 : distributor.order) === null || _d === void 0 ? void 0 : _d.items.some((p) => { var _a; return p.partNumber === part.partNumber && ((_a = p === null || p === void 0 ? void 0 : p.states[0]) === null || _a === void 0 ? void 0 : _a.status) === 'STANDBY'; });
    const isWrongPartNumber = wrongPartNumbers &&
        ((_e = wrongPartNumbers[part.id]) === null || _e === void 0 ? void 0 : _e.filter((partNumber) => { var _a; return ((_a = part.partNumber) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === (partNumber === null || partNumber === void 0 ? void 0 : partNumber.toUpperCase()); }).length) > 0;
    const partNotPickedUpError = isPartNotPickedUp
        ? 'Not available at this location.'
        : null;
    const wrongPartNumberError = isWrongPartNumber
        ? 'Wrong Part Number. Please choose another.'
        : '';
    const isFitment = !!part.fitmentId;
    const fitment = (_f = part.fitments) === null || _f === void 0 ? void 0 : _f.find((f) => f.id === part.fitmentId);
    const hasFitments = !isFitment && ((_h = (_g = part === null || part === void 0 ? void 0 : part.fitments) === null || _g === void 0 ? void 0 : _g.length) !== null && _h !== void 0 ? _h : 0) > 0;
    const p = isFitment && fitment
        ? Object.assign(Object.assign({}, part), { name: fitment === null || fitment === void 0 ? void 0 : fitment.partName, cost: fitment === null || fitment === void 0 ? void 0 : fitment.cost, partNumber: fitment === null || fitment === void 0 ? void 0 : fitment.partNumber, additionalInfo: fitment === null || fitment === void 0 ? void 0 : fitment.additionalInfo }) : part;
    if (typeof p.createCore === 'undefined')
        p.createCore = true;
    let addToCartDisabled;
    if (isFitment) {
        addToCartDisabled = false;
    }
    else {
        addToCartDisabled =
            !isPartReadyToBeAdded() ||
                isPartNotPickedUp ||
                isWrongPartNumber ||
                hasFitments;
    }
    useEffect(() => {
        setAdditionalInfoMap((p === null || p === void 0 ? void 0 : p.additionalInfo)
            ? Object.keys(p === null || p === void 0 ? void 0 : p.additionalInfo).map((key) => {
                return {
                    name: key,
                    value: (p === null || p === void 0 ? void 0 : p.additionalInfo) &&
                        (p === null || p === void 0 ? void 0 : p.additionalInfo[key]),
                };
            })
            : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p === null || p === void 0 ? void 0 : p.additionalInfo]);
    return (<Grid container direction="column">
      <span className="caption">{itemName} Details</span>
      {isAvailableForIntegration(componentsAvailability, part) && (<FormControlLabel style={{
                justifyContent: 'flex-end',
                margin: isDialog ? '0 16px 10px' : '0 0px 10px',
                color: colors.GRAY_76,
            }} control={<Switch checked={(p === null || p === void 0 ? void 0 : p.isIntegrated) && !hasFitments && !isFitment} disabled={hasFitments || isFitment} onChange={() => setIsIntegrated(!(p === null || p === void 0 ? void 0 : p.isIntegrated))} color="primary"/>} label="Integration" labelPlacement="start"/>)}
      <div className="partNameWrapper">
        <TextField className={partNameClasses} variant="outlined" label={`${itemName} Name`} value={(_j = p === null || p === void 0 ? void 0 : p.name) !== null && _j !== void 0 ? _j : ''} placeholder={`${itemName} Name`} onChange={onChangeInput('name', false)} disabled={(p === null || p === void 0 ? void 0 : p.isIntegrated) || hasFitments || isFitment}/>
        {(p === null || p === void 0 ? void 0 : p.isIntegrated) && !isFitment && !hasFitments && (<Button variant="text" className="pickPartButton" onClick={onPickPart}>
            Pick Part
          </Button>)}
      </div>

      {!isFluid && (<TextField className="editField" variant="outlined" label={`${itemName} Quantity`} value={(_l = (_k = p === null || p === void 0 ? void 0 : p.quantity) === null || _k === void 0 ? void 0 : _k.toString()) !== null && _l !== void 0 ? _l : ''} type="number" placeholder={`${itemName} Quantity`} onChange={onChangeInput('quantity', true)} onBlur={onQuantityBlur} disabled={isTireComponent || hasFitments || isFitment}/>)}
      {isFluid && part.isIntegrated && (<TextField className="editField" variant="outlined" label="Fluid Order Quantity" type="number" value={(_o = (_m = p === null || p === void 0 ? void 0 : p.quantity) === null || _m === void 0 ? void 0 : _m.toString()) !== null && _o !== void 0 ? _o : ''} placeholder="Fluid Order Quantity" onChange={onChangeInput('quantity', true)} onBlur={onQuantityBlur} disabled={isFitment}/>)}
      {isFluid && (<FormControl>
          <InputLabel id="fluid-capacity-unit" className="editFieldCaption">
            Fluid Capacity Unit
          </InputLabel>
          <Select labelId="fluid-capacity-unit" className="editField" variant="outlined" label="Fluid Capacity Unit" value={(_p = p === null || p === void 0 ? void 0 : p.capacityUnit) !== null && _p !== void 0 ? _p : ''} onChange={onChangeInput('capacityUnit', false)} disabled={hasFitments || isFitment}>
            {createMenuItems(FluidCapacityUnit)}
          </Select>
        </FormControl>)}
      {isFluid && (<TextField className="editField" variant="outlined" label="Fluid Capacity" type="number" value={(_q = p === null || p === void 0 ? void 0 : p.capacity) !== null && _q !== void 0 ? _q : ''} placeholder="Fluid Capacity" onChange={onChangeInput('capacity', true, true)} onBlur={onQuantityBlur} disabled={hasFitments || isFitment}/>)}
      {isFluid && (<FormControl>
          <InputLabel id="fluid-capacity-type" className="editFieldCaption">
            Fluid Capacity Type
          </InputLabel>
          <Select labelId="fluid-capacity-type" className="editField" variant="outlined" label="Fluid Capacity Type" value={(_r = p === null || p === void 0 ? void 0 : p.type) !== null && _r !== void 0 ? _r : ''} onChange={onChangeInput('type', false)} disabled={hasFitments || isFitment}>
            {createMenuItems(FluidType)}
          </Select>
        </FormControl>)}
      <Grid container direction="row" style={{ alignItems: 'center', justifyContent: 'space-between' }} wrap="nowrap" className="partCostWrap">
        <TextField className="editField editField--left" variant="outlined" label={`${itemName} Cost`} type="number" value={(_t = (_s = p === null || p === void 0 ? void 0 : p.cost) === null || _s === void 0 ? void 0 : _s.toString()) !== null && _t !== void 0 ? _t : ''} placeholder={`${itemName} Cost`} onChange={onChangeInput('cost', true, true)} disabled={((p === null || p === void 0 ? void 0 : p.isIntegrated) || hasFitments) && !isFitment} onBlur={onBlurInput}/>
        <TextField className="editField" variant="outlined" label={`${itemName} Price`} type="number" value={(_v = (_u = p === null || p === void 0 ? void 0 : p.price) === null || _u === void 0 ? void 0 : _u.toString()) !== null && _v !== void 0 ? _v : ''} placeholder={`${itemName} Price`} onChange={onChangeInput('price', true, true)} onBlur={onBlurInput} disabled={isFitment}/>
      </Grid>

      {!isFluid && (p === null || p === void 0 ? void 0 : p.coreChargeExpected) && (<Grid container direction="row" style={{ alignItems: 'center', justifyContent: 'space-between' }} wrap="nowrap" className="partCostWrap">
          <TextField className="editField" variant="outlined" label={`${itemName} Core Cost`} type="number" value={(_w = p === null || p === void 0 ? void 0 : p.coreChargeCost) !== null && _w !== void 0 ? _w : ''} placeholder={`${itemName} Core Cost`} onChange={onChangeInput('coreChargeCost', true, true)} onBlur={onBlurInput} disabled={(p === null || p === void 0 ? void 0 : p.isIntegrated) || hasFitments || isFitment || !(p === null || p === void 0 ? void 0 : p.createCore)}/>
          <FormControlLabel style={{
                justifyContent: 'flex-end',
                margin: isDialog ? '0 16px 10px' : '0 0px 10px',
                color: colors.GRAY_76,
                minWidth: '120px',
            }} control={<Switch checked={p === null || p === void 0 ? void 0 : p.createCore} disabled={(p === null || p === void 0 ? void 0 : p.isIntegrated) || hasFitments || isFitment} onChange={onChangeInput('createCore', false)} color="primary"/>} label="Core Expected" labelPlacement="top"/>
        </Grid>)}

      <TextField className="editField" variant="outlined" label="Part Number" value={(_x = p === null || p === void 0 ? void 0 : p.partNumber) !== null && _x !== void 0 ? _x : ''} placeholder="Part Number" onChange={onChangeInput('partNumber', false)} disabled={((p === null || p === void 0 ? void 0 : p.isIntegrated) || hasFitments) && !isFitment} error={isPartNotPickedUp || isWrongPartNumber} helperText={partNotPickedUpError || wrongPartNumberError}/>
      {wrongPartNumbers && wrongPartNumbers[p.id] && (<TextField className="editField" variant="outlined" label="Wrong Part Numbers" value={wrongPartNumbers[part.id].join(', ')} placeholder="Wrong Part Numbers" disabled/>)}
      {isTireComponent && !isDialog && (<AdditionalDetailsDrawer part={p} setSelectedPart={setSelectedPart}/>)}
      {!isTireComponent && !isDialog && (<SimpleRowButton color="primary" onClick={() => {
                setAdditionalInfoOpen(true);
            }}>
          <AddCircle />
          Additional Info
        </SimpleRowButton>)}

      <Divider />
      <Grid container direction="row" style={{ alignItems: 'center', justifyContent: 'space-between' }} wrap="nowrap" className="editPartActions">
        <Button className="editButton" variant="contained" color="primary" disabled={addToCartDisabled} onClick={() => onSaveNext(false)}>
          Add to Cart
        </Button>
      </Grid>
      <SimpleModal open={isModalOpen} onClose={resetQuantity} title={confirmationMessage} simpleTwoButtonRowProps={{
            cancelText: 'No',
            submitText: 'Yes',
            onCancel: resetQuantity,
            onSubmit: () => {
                var _a, _b;
                setPrevQtyValue((_a = p === null || p === void 0 ? void 0 : p.quantity) !== null && _a !== void 0 ? _a : 0);
                setPrevFluidValue((_b = p.capacity) !== null && _b !== void 0 ? _b : 0);
                setIsModalOpen(false);
            },
        }}/>
      <AddComponentAdditionalInfo open={additionalInfoOpen} onClose={() => setAdditionalInfoOpen(false)} isFluid={isFluid} setFieldValue={setAdditionalInfo} values={additionalInfoMap !== null && additionalInfoMap !== void 0 ? additionalInfoMap : []}/>
    </Grid>);
};
export default EditPart;
