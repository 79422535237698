import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { Grid, Button } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
import { selectUserEmail } from 'src/modules/auth/selectors';
import { resendMfa } from 'src/modules/auth/actions';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
const ResendVerificationButton = styled(SimpleRowButton) `
  width: 200px;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`;
const MfaForm = ({ onSubmit }) => {
    const [resendVerificationEnabled, setResendVerificationEnabled] = useState(true);
    const userEmail = useSelector(selectUserEmail);
    const dispatch = useDispatch();
    const handleResendClick = () => {
        setResendVerificationEnabled(false);
        dispatch(resendMfa());
        setTimeout(() => {
            setResendVerificationEnabled(true);
        }, 60000);
    };
    return (<Formik initialValues={{
            mfaCode: '',
        }} onSubmit={(values, { setSubmitting }) => {
            onSubmit(values.mfaCode);
            setSubmitting(false);
        }}>
      {({ isSubmitting }) => (<Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p>
                A verification code was sent to your email address{' '}
                {`'${userEmail}'`}. Please enter the verification code below.
              </p>
            </Grid>
            <Grid item xs={12} style={{ margin: '0px 0px 30px 0px' }}>
              <TextField name="mfaCode" type="mfaCode" label="MFA Verification Code"/>
            </Grid>
            <Grid item xs={12} container style={{ justifyContent: 'flex-end' }}>
              <ResendVerificationButton onClick={handleResendClick} color={resendVerificationEnabled ? 'primary' : 'lightGrey'} disabled={!resendVerificationEnabled}>
                Resend Verification Code
              </ResendVerificationButton>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                Verify
              </Button>
            </Grid>
          </Grid>
        </Form>)}
    </Formik>);
};
export default MfaForm;
