import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Icon, MenuItem, Select, TableCell, TableRow, TextField, } from 'src/components/mui';
import { FormatListBulleted, Schedule } from 'src/components/mui/icons';
import DeliveryTypesDrawer from 'src/PopsApp/pages/Distributors/DeliveryTypes/DeliveryTypesDrawer';
import { colors } from 'src/styles/theme';
import { formatTimestamp } from 'src/utils/formatter';
import styled from 'styled-components';
const StyledTableRow = styled(TableRow) `
  cursor: pointer;
  margin-top: 5px;

  td {
    font-weight: 300;
    font-size: 15px;
    color: ${colors.GRAY_3F};
  }

  td:first-child {
    width: 35%;
  }

  &:hover {
    outline: solid 2px ${colors.BLUE};
  }
`;
const DashboardListItem = ({ distributor, onRowSave, onHoursEdit, }) => {
    const rowStyle = {
        cursor: 'pointer',
    };
    const history = useHistory();
    const statuses = ['ACTIVE', 'INACTIVE'];
    const [isEditingRow, setIsEditingRow] = useState(false);
    const [editRow, setEditRow] = useState();
    useEffect(() => {
        setEditRow(distributor);
    }, [distributor]);
    const handleEditClick = () => {
        setIsEditingRow(true);
    };
    const handleSaveClick = () => {
        setIsEditingRow(false);
        onRowSave(editRow);
    };
    const saveDeliveryTypes = (values) => {
        onRowSave(values);
    };
    const renderCells = () => {
        if (!isEditingRow) {
            return (<>
          <TableCell>{distributor === null || distributor === void 0 ? void 0 : distributor.name}</TableCell>
          <TableCell>{distributor === null || distributor === void 0 ? void 0 : distributor.status}</TableCell>
        </>);
        }
        return (<>
        <TableCell>
          <TextField label="Name" type="text" name="Name" value={editRow === null || editRow === void 0 ? void 0 : editRow.name} onChange={(event) => {
                if (!editRow)
                    return;
                setEditRow(Object.assign(Object.assign({}, editRow), { name: event.target.value }));
            }}/>
        </TableCell>
        <TableCell>
          <Select onChange={(event) => {
                if (!editRow)
                    return;
                setEditRow(Object.assign(Object.assign({}, editRow), { status: event.target.value }));
            }} value={editRow === null || editRow === void 0 ? void 0 : editRow.status}>
            {statuses.map((status) => (<MenuItem key={status} value={status}>
                {status}
              </MenuItem>))}
          </Select>
        </TableCell>
      </>);
    };
    const renderEditCell = () => {
        if (!isEditingRow) {
            return (<TableCell align="center" style={rowStyle} onClick={handleEditClick}>
          <Icon>edit</Icon>
        </TableCell>);
        }
        return (<TableCell align="center" style={rowStyle} onClick={handleSaveClick}>
        <Icon>check</Icon>
      </TableCell>);
    };
    return (<StyledTableRow>
      {renderCells()}
      <TableCell>{formatTimestamp(distributor === null || distributor === void 0 ? void 0 : distributor.modified)}</TableCell>
      {renderEditCell()}
      <TableCell align="center" style={rowStyle} onClick={() => {
            history.push(`/pops/distributors/${distributor.id}`);
        }}>
        <FormatListBulleted />
      </TableCell>
      <TableCell align="center" style={rowStyle}>
        <Schedule onClick={() => {
            onHoursEdit(distributor);
        }}/>
      </TableCell>
      <TableCell align="center" style={rowStyle}>
        <DeliveryTypesDrawer model={distributor} onSave={saveDeliveryTypes}/>
      </TableCell>
    </StyledTableRow>);
};
export default DashboardListItem;
