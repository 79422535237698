import React from 'react';
import { useSelector } from 'react-redux';
import { isQuoteCostsHasNull } from 'src/AdminApp/components/repairs/statement/utils';
import InfoStatus from 'src/AdminApp/containers/requests/Invoice/InfoStatus';
import { ButtonRightWrapper, ConsumerLink, Wrapper, } from 'src/AdminApp/containers/requests/Invoice/styles';
import useInvoiceFooter from 'src/AdminApp/containers/requests/Invoice/useInvoiceFooter';
import { selectRequestDataByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { getConsumerUrl } from 'src/AdminApp/utils/url-utils';
import { CopyToClipboardButton } from 'src/components/buttons';
import { SimpleModal } from 'src/components/layout';
import { Button, Grid } from 'src/components/mui';
import { Phone } from 'src/components/mui/icons';
import { Tooltip } from 'src/components/tooltips';
import CONFIG from 'src/config';
const InvoiceFooter = ({ canEdit = false, workOrderId, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { referenceNum, mainInvoice, isSendEmailConfirmDialogOpen, openSendEmailConfirmDialog, closeSendEmailConfirmDialog, handleSendEmailConfirm, handleRequestSalesCall, } = useInvoiceFooter(workOrderId);
    const requestData = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const consumerName = ((_a = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) === null || _a === void 0 ? void 0 : _a.name) || '';
    const link = getConsumerUrl(`my-repairs/${referenceNum}`);
    const sendInvoiceEnabled = CONFIG.REACT_APP_SEND_INVOICE_ENABLED === 'true';
    const mainInvoiceActiveWorkAuthorization = mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.activeWorkAuthorization;
    const authorizedAt = (mainInvoiceActiveWorkAuthorization === null || mainInvoiceActiveWorkAuthorization === void 0 ? void 0 : mainInvoiceActiveWorkAuthorization.authorizedAt) || '';
    const authorizedBy = (mainInvoiceActiveWorkAuthorization === null || mainInvoiceActiveWorkAuthorization === void 0 ? void 0 : mainInvoiceActiveWorkAuthorization.authorizedBy) || consumerName;
    const providedAt = (mainInvoiceActiveWorkAuthorization === null || mainInvoiceActiveWorkAuthorization === void 0 ? void 0 : mainInvoiceActiveWorkAuthorization.providedAt) || '';
    const providedBy = (mainInvoiceActiveWorkAuthorization === null || mainInvoiceActiveWorkAuthorization === void 0 ? void 0 : mainInvoiceActiveWorkAuthorization.providedBy) || '';
    const authorizationMethod = (_b = mainInvoiceActiveWorkAuthorization === null || mainInvoiceActiveWorkAuthorization === void 0 ? void 0 : mainInvoiceActiveWorkAuthorization.workAuthorizationMethod) !== null && _b !== void 0 ? _b : '';
    const authorizationName = (_c = mainInvoiceActiveWorkAuthorization === null || mainInvoiceActiveWorkAuthorization === void 0 ? void 0 : mainInvoiceActiveWorkAuthorization.authorizedByName) !== null && _c !== void 0 ? _c : '';
    const isAuthorized = !!(mainInvoiceActiveWorkAuthorization === null || mainInvoiceActiveWorkAuthorization === void 0 ? void 0 : mainInvoiceActiveWorkAuthorization.authorizedAt);
    const isSendInvoiceButtonEnabled = isAuthorized &&
        !isQuoteCostsHasNull((_e = (_d = mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.diagnosisServices) === null || _d === void 0 ? void 0 : _d.knownRepairs) !== null && _e !== void 0 ? _e : [], (_g = (_f = mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.diagnosisServices) === null || _f === void 0 ? void 0 : _f.customRequests) !== null && _g !== void 0 ? _g : []);
    return (<>
      <ConsumerLink container style={{ alignItems: 'center' }}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid container item xl={8} lg={8} md={12} sm={12} xs={8} style={{ alignItems: 'center' }}>
            <Grid item>
              <CopyToClipboardButton value={link}>
                Copy Customer Invoice Link
              </CopyToClipboardButton>
            </Grid>
            {CONFIG.REACT_APP_SHOW_REQUEST_SALES_CALL_CTA === 'true' && (<Grid item>
                <Button onClick={() => handleRequestSalesCall()} color="primary" startIcon={<Phone />} style={{ marginLeft: '0px' }}>
                  Request Sales Call
                </Button>
              </Grid>)}
          </Grid>
          {canEdit && (<Wrapper>
              {sendInvoiceEnabled && (<ButtonRightWrapper item>
                  <Tooltip disabled={isSendInvoiceButtonEnabled} title="Input costs for all parts/fluids and Authorize invoice first.">
                    <span>
                      <Button onClick={openSendEmailConfirmDialog} variant="contained" color="primary" disabled={!isSendInvoiceButtonEnabled}>
                        Send Invoice
                      </Button>
                    </span>
                  </Tooltip>
                </ButtonRightWrapper>)}
            </Wrapper>)}
        </Grid>
        <SimpleModal open={isSendEmailConfirmDialogOpen} onClose={closeSendEmailConfirmDialog} title="Would you like to email Invoice to Consumer?" simpleTwoButtonRowProps={{
            onSubmit: handleSendEmailConfirm,
            onCancel: closeSendEmailConfirmDialog,
            cancelText: 'No',
            submitText: 'Yes',
        }}/>
      </ConsumerLink>
      <InfoStatus authorizationMethod={authorizationMethod} authorizationName={authorizationName} authorizedAt={authorizedAt} authorizedBy={authorizedBy} providedAt={providedAt} providedBy={providedBy}/>
    </>);
};
export default InvoiceFooter;
