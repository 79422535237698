var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getConsumerUrl } from 'src/AdminApp/utils/url-utils';
import { difference, groupBy } from 'lodash';
import { SEVERITY_SCALES } from 'src/AdminApp/modules/api/constants';
import { analyticsTrackEvent } from 'src/AdminApp/modules/analytics/actions';
export const sendMpiReport = ({ mpi, consumer, vehicle, mpiInspector, resend = false, suppressNotifications = false, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { createdAt, id: inspectionId, inspectionResults, repairRefNum, inspectionType, } = mpi;
    const link = getConsumerUrl(`my-repairs/car-health-reports/${inspectionId}`);
    // GROUP AND COUNT REPAIRS BASED ON SEVERITY
    let severityTracking = {};
    const repairsBySeverity = groupBy(mpi.inspectionResults, 'status');
    Object.keys(repairsBySeverity).forEach((severityKey) => {
        var _a;
        const severity = (_a = repairsBySeverity[severityKey]) !== null && _a !== void 0 ? _a : null;
        const severityScaleItem = SEVERITY_SCALES.find((sev) => sev.value === severityKey);
        const severityRepairs = severity.map((repair) => {
            var _a, _b;
            return (_a = repair.inspectionRsRepair) !== null && _a !== void 0 ? _a : {
                id: null,
                inspectionCategoryId: null,
                name: (_b = repair === null || repair === void 0 ? void 0 : repair.payload) === null || _b === void 0 ? void 0 : _b.customServiceRequest,
                repairId: null,
            };
        });
        const severityItemName = `Severity ${severityScaleItem ? severityScaleItem.timeFrame : null}`;
        severityTracking = Object.assign(Object.assign({}, severityTracking), { [severityItemName]: severityRepairs, [`${severityItemName} Count`]: severityRepairs.length });
    });
    // ADD SEVERITIES WITHOUT REPAIRS WITH ZERO COUNTS
    const severityKeysIncluded = Object.keys(repairsBySeverity);
    const severityScaleAllTypes = SEVERITY_SCALES.map((sev) => sev.value);
    const noSeverityRepairs = difference(severityScaleAllTypes, severityKeysIncluded);
    noSeverityRepairs.forEach((noRepair) => {
        const sevObj = SEVERITY_SCALES.find((sev) => sev.value === noRepair);
        severityTracking = Object.assign(Object.assign({}, severityTracking), { [`Severity ${sevObj && sevObj.timeFrame} Count`]: 0 });
    });
    const eventName = resend
        ? 'Updated MPI Sent from Tools'
        : 'MPI Sent from Tools';
    analyticsTrackEvent(eventName, Object.assign(Object.assign({ 'repairID': repairRefNum, 'Inspection recipient': consumer.fleet ? 'fleet' : 'consumer', 'Vehicle': vehicle, 'CarDetails': `${vehicle === null || vehicle === void 0 ? void 0 : vehicle.year} ${vehicle === null || vehicle === void 0 ? void 0 : vehicle.make} ${vehicle === null || vehicle === void 0 ? void 0 : vehicle.model}`, 'Mileage': vehicle === null || vehicle === void 0 ? void 0 : vehicle.mileage, 'Inspection type': inspectionType.name, 'Total number of recommended repairs': inspectionResults.length }, severityTracking), { 'Inspected by': mpiInspector
            ? `${mpiInspector.firstName} ${(_a = mpiInspector.lastName) === null || _a === void 0 ? void 0 : _a.substring(0, 1)}`
            : null, 'Date Inspected': new Date(), 'Created': createdAt, 'Inspection URL': link, 'Suppress Notifications': suppressNotifications }));
});
