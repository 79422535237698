import React from 'react';
import { TabPanel } from 'src/AdminApp/components/layout/TabPanel';
import LocationAndMechanicSelection from 'src/AdminApp/containers/scheduling/LocationAndMechanicSelection';
import ManualEntryContainer from 'src/AdminApp/containers/scheduling/ManualEntryContainer';
import SchedulingDetails from 'src/AdminApp/containers/scheduling/SchedulingDetails';
import SchedulingTabs from 'src/AdminApp/containers/scheduling/SchedulingTabs';
import ShiftToggle from 'src/AdminApp/containers/scheduling/ShiftToggle';
import useScheduling from 'src/AdminApp/containers/scheduling/useScheduling';
import useIsMobile from 'src/hooks/useIsMobile';
import { Content, ContentContainer, ContentWrapper, OpenScheduleSeparator, OpenScheduleWrapper, SlotsTitle, } from './index.styles';
import Location from './Location';
import TimeSlots from './TimeSlots';
const Scheduling = ({ userAddress, context, preSelectedMechanics, enableSpecificTime = true, }) => {
    const isMobile = useIsMobile();
    const { currentTab, setCurrentTab, setManualEntryMechanic, setSelectedDateTime, setSelectedFutureAppointmentDate, setSelectedMinFutureAppointmentDate, setScheduleFutureAppointmentsCheck, isLoading, goToVisits, shift, setShift, shifts, pricing, leadTimeDays, skills, schedulingAvailability, mechanicIds, address, manualEntryMechanic, handleManualMechanicSelection, selectedDateTime, scheduleFutureAppointmentsCheck, handleSwitchChange, isDateTimePickerOpen, setIsDateTimePickerOpen, selectedFutureAppointmentDate, selectedMinFutureAppointmentDate, disableDateTimePicker, enableSwitch, errMsg, activeMechanics, selectedVanId, handleLocationChange, } = useScheduling({
        userAddress,
        context,
        preSelectedMechanics,
    });
    /* RENDER START */
    const renderTabs = () => (<SchedulingTabs currentTab={currentTab} setCurrentTab={setCurrentTab} enableSpecificTime={enableSpecificTime} setManualEntryMechanic={setManualEntryMechanic} setSelectedDateTime={setSelectedDateTime} setSelectedFutureAppointmentDate={setSelectedFutureAppointmentDate} setSelectedMinFutureAppointmentDate={setSelectedMinFutureAppointmentDate} setScheduleFutureAppointmentsCheck={setScheduleFutureAppointmentsCheck}/>);
    // DESKTOP RENDER
    if (!isMobile) {
        return (<>
        <ContentWrapper>
          <ContentContainer>
            <Content>
              {renderTabs()}
              <TabPanel value={currentTab} index={0}>
                <OpenScheduleWrapper>
                  <SlotsTitle variant={isMobile ? 'h6' : 'h5'}>
                    Available Time Slots
                  </SlotsTitle>
                  <OpenScheduleSeparator />
                  {shift && (<ShiftToggle setShift={setShift} selectedShiftName={shift} shifts={shifts}/>)}
                </OpenScheduleWrapper>

                <SchedulingDetails pricing={pricing} leadTimeDays={leadTimeDays} skills={skills}/>

                <TimeSlots loading={isLoading} slots={schedulingAvailability === null || schedulingAvailability === void 0 ? void 0 : schedulingAvailability.slots} mechanicFilterEnabled={(mechanicIds === null || mechanicIds === void 0 ? void 0 : mechanicIds.length) > 0} tz={address.timezone}/>
              </TabPanel>
              {enableSpecificTime && (<TabPanel value={currentTab} index={1}>
                  {shift && (<ShiftToggle setShift={setShift} selectedShiftName={shift} shifts={shifts}/>)}

                  <ManualEntryContainer manualEntryMechanic={manualEntryMechanic} setManualEntryMechanic={setManualEntryMechanic} handleManualMechanicSelection={handleManualMechanicSelection} selectedDateTime={selectedDateTime} setSelectedDateTime={setSelectedDateTime} scheduleFutureAppointmentsCheck={scheduleFutureAppointmentsCheck} handleSwitchChange={handleSwitchChange} isDateTimePickerOpen={isDateTimePickerOpen} setIsDateTimePickerOpen={setIsDateTimePickerOpen} selectedFutureAppointmentDate={selectedFutureAppointmentDate} setSelectedFutureAppointmentDate={setSelectedFutureAppointmentDate} selectedMinFutureAppointmentDate={selectedMinFutureAppointmentDate} disableDateTimePicker={disableDateTimePicker} enableSwitch={enableSwitch}/>
                </TabPanel>)}
            </Content>
          </ContentContainer>
          <ContentContainer>
            <Content>
              <LocationAndMechanicSelection address={address} errMsg={errMsg} currentTab={currentTab} activeMechanics={activeMechanics} mechanicIds={mechanicIds} selectedVanId={selectedVanId} skills={skills} handleLocationChange={handleLocationChange}/>
            </Content>
          </ContentContainer>
        </ContentWrapper>
      </>);
    }
    // MOBILE RENDER
    if (isMobile) {
        return (<>
        {renderTabs()}
        <ContentWrapper>
          <ContentContainer>
            <Content>
              <TabPanel value={currentTab} index={0}>
                <SlotsTitle variant="h6">Available Time Slots</SlotsTitle>

                <LocationAndMechanicSelection address={address} errMsg={errMsg} currentTab={currentTab} activeMechanics={activeMechanics} mechanicIds={mechanicIds} selectedVanId={selectedVanId} skills={skills} handleLocationChange={handleLocationChange}/>
                {shift && (<ShiftToggle setShift={setShift} selectedShiftName={shift} shifts={shifts}/>)}
                <TimeSlots loading={isLoading} slots={schedulingAvailability === null || schedulingAvailability === void 0 ? void 0 : schedulingAvailability.slots} mechanicFilterEnabled={(mechanicIds === null || mechanicIds === void 0 ? void 0 : mechanicIds.length) > 0} tz={address.timezone}/>
              </TabPanel>
              {enableSpecificTime && (<TabPanel value={currentTab} index={1}>
                  {shift && (<ShiftToggle setShift={setShift} selectedShiftName={shift} shifts={shifts}/>)}

                  <Location address={address} onChange={handleLocationChange}/>
                  <ManualEntryContainer manualEntryMechanic={manualEntryMechanic} setManualEntryMechanic={setManualEntryMechanic} handleManualMechanicSelection={handleManualMechanicSelection} selectedDateTime={selectedDateTime} setSelectedDateTime={setSelectedDateTime} scheduleFutureAppointmentsCheck={scheduleFutureAppointmentsCheck} handleSwitchChange={handleSwitchChange} isDateTimePickerOpen={isDateTimePickerOpen} setIsDateTimePickerOpen={setIsDateTimePickerOpen} selectedFutureAppointmentDate={selectedFutureAppointmentDate} setSelectedFutureAppointmentDate={setSelectedFutureAppointmentDate} selectedMinFutureAppointmentDate={selectedMinFutureAppointmentDate} disableDateTimePicker={disableDateTimePicker} enableSwitch={enableSwitch}/>
                </TabPanel>)}
            </Content>
          </ContentContainer>
        </ContentWrapper>
      </>);
    }
    return null;
};
export default Scheduling;
