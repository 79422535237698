import { useDispatch, useSelector } from 'react-redux';
import { selectDashboardPagination, selectDashboardParams, } from 'src/AdminApp/modules/shops/selectors';
import { useEffect, useState } from 'react';
import { ActionCreators, getShops, } from 'src/AdminApp/modules/shops/actions';
const useShopsDashboard = () => {
    const dispatch = useDispatch();
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    const [selectedFilters, setSelectedFilters] = useState({
        deliveryChannel: 'DELIVERY',
    });
    useEffect(() => {
        var _a, _b, _c;
        dispatch(getShops(Object.assign(Object.assign(Object.assign({}, params), selectedFilters), { pageSize: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20, pageIndex: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _b !== void 0 ? _b : 0, sort: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _c !== void 0 ? _c : ['shopName', 'asc'] })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLoadData = ({ page, size, sort }) => {
        dispatch(getShops(Object.assign({ pageSize: size, pageIndex: page, sort }, selectedFilters)));
    };
    const onSearch = (filters) => {
        setSelectedFilters(filters);
        dispatch(getShops(Object.assign({ pageSize: 20, pageIndex: 0 }, filters)));
    };
    const onClear = () => {
        dispatch(ActionCreators.SHOPS.CLEAR());
        setSelectedFilters({});
    };
    return {
        onLoadData,
        onSearch,
        onClear,
        params,
        pagination,
    };
};
export default useShopsDashboard;
