import React from 'react';
import { theme } from 'src/styles/theme';
import styled from 'styled-components';
const Line = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 25px;
  font-size: 13px;
`;
const Label = styled.div `
  display: flex;
  align-items: ${(props) => (props.centerLabel ? 'center' : 'start')};
  min-width: 125px;
  padding-right: 20px;
  font-weight: 900;

  ${theme.breakpoints.up('md')} {
    min-width: 170px;
  }
`;
const Value = styled.div `
  display: flex;
  flex-direction: column;
  min-width: 80px;
  width: 100%;
  word-wrap: break-word;
`;
const LabeledField = ({ label, children, id, centerLabel = true, }) => (<Line id={id}>
    <Label centerLabel={centerLabel}>{label}</Label>
    <Value>{children}</Value>
  </Line>);
export default LabeledField;
