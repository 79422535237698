var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { isNull } from 'lodash';
const SimpleDatePicker = (props) => {
    const { value } = props, rest = __rest(props, ["value"]);
    let val = null;
    if (isNull(value)) {
        val = null;
    }
    else if (moment.isMoment(value)) {
        val = value;
    }
    else {
        val = moment(value);
    }
    return <DatePicker value={val} {...rest}/>;
};
export default SimpleDatePicker;
