var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Icon, TextField, Grid, Button } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import { formatPhone } from 'src/utils/formatter';
import { ResourcesTitle } from 'src/FleetVisitsApp/pages/VisitView/VisitInfo/VisitInfo.styles';
import { GridItem } from 'src/FleetVisitsApp/pages/VisitView/Dialogs/AddedToVisitDialog.styles';
const validationSchema = Yup.object().shape({
    dailyOpsContactName: Yup.string().required('Contact name is required'),
    dailyOpsContactPhone: Yup.string()
        .required('Contact phone number is required')
        .matches(/^[0-9]{10}$/, 'Must be a valid phone number'),
    dailyOpsContactEmail: Yup.string()
        .required('Contact Email is required')
        .email('Must be a valid email'),
});
const StyledTableRow = styled(Grid) `
  cursor: pointer;
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colors.GRAY_CC};
  border-bottom: 1px solid ${colors.GRAY_CC};
  padding: 10px 0 10px 0;
  margin-bottom: 40px;
`;
const DrawerListItem = ({ contact, onRowSave }) => {
    const rowStyle = {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
    };
    const [isEditingRow, setIsEditingRow] = useState(false);
    const handleEditClick = () => {
        setIsEditingRow(true);
    };
    const handleCloseClick = () => {
        setIsEditingRow(false);
    };
    const handleSaveClick = (contactInfo) => {
        setIsEditingRow(false);
        onRowSave(contactInfo);
    };
    const renderCells = () => {
        var _a;
        if (!isEditingRow) {
            return (<>
          <GridItem style={{ fontSize: '14px' }} sm={3}>
            Name: {contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactName}
          </GridItem>
          <GridItem style={{ fontSize: '14px' }} sm={3}>
            Phone: {formatPhone((_a = contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactPhone) !== null && _a !== void 0 ? _a : '')}
          </GridItem>
          <GridItem sm={5} style={{ overflow: 'clip', fontSize: '14px' }}>
            Email: {contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactEmail}
          </GridItem>
          <GridItem sm={1} onClick={handleEditClick}>
            <Icon>edit</Icon>
          </GridItem>
        </>);
        }
        return (<Formik initialValues={{
                dailyOpsContactName: contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactName,
                dailyOpsContactPhone: contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactPhone,
                dailyOpsContactEmail: contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactEmail,
            }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
                setSubmitting(true);
                yield handleSaveClick(Object.assign(Object.assign({}, contact), values));
                setSubmitting(false);
                handleCloseClick();
            })} validateOnChange>
        {({ isSubmitting, isValid, isValidating, handleSubmit, dirty, setFieldValue, values, errors, }) => (<>
            <GridItem sm={3}>
              <TextField label="Name" type="text" name="name" variant="outlined" value={values === null || values === void 0 ? void 0 : values.dailyOpsContactName} onChange={(e) => {
                    setFieldValue('dailyOpsContactName', e.target.value);
                }} error={!!errors.dailyOpsContactName} helperText={errors.dailyOpsContactName}/>
            </GridItem>
            <GridItem sm={3}>
              <TextField label="Phone" type="text" name="phone" variant="outlined" value={values === null || values === void 0 ? void 0 : values.dailyOpsContactPhone} onChange={(e) => {
                    setFieldValue('dailyOpsContactPhone', e.target.value);
                }} error={!!errors.dailyOpsContactPhone} helperText={errors.dailyOpsContactPhone}/>
            </GridItem>
            <GridItem sm={4}>
              <TextField label="Email" type="text" name="email" variant="outlined" value={values === null || values === void 0 ? void 0 : values.dailyOpsContactEmail} onChange={(e) => {
                    setFieldValue('dailyOpsContactEmail', e.target.value);
                }} error={!!errors.dailyOpsContactEmail} helperText={errors.dailyOpsContactEmail}/>
            </GridItem>
            <GridItem sm={1} style={rowStyle} onClick={handleCloseClick}>
              <Icon>close</Icon>
            </GridItem>
            <GridItem sm={1} style={rowStyle}>
              <Button onClick={() => handleSubmit()} disabled={!isValid || isValidating || isSubmitting || !dirty}>
                <Icon>check</Icon>
              </Button>
            </GridItem>
          </>)}
      </Formik>);
    };
    return (<>
      <ResourcesTitle>{contact.fleetName}</ResourcesTitle>
      <StyledTableRow>{renderCells()}</StyledTableRow>
    </>);
};
export default DrawerListItem;
