var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { TextField, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from 'src/components/mui';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import Loader from 'src/FleetVisitsApp/pages/FleetVisits/components/Loader';
import { useNetSuiteApiClient } from '../../useNetSuiteApiClient';
var Status;
(function (Status) {
    Status["B"] = "PENDING RECEIPT";
    Status["E"] = "PARTIALLY RECEIVED";
    Status["F"] = "PENDING BILL";
    Status["G"] = "FULLY BILLED";
    Status["H"] = "CLOSED";
    Status["D"] = "Deleted";
})(Status || (Status = {}));
const GridItem = styled(Grid) `
  padding: 5px;
`;
const Cell = styled(TableCell) `
  font-size: 14px;
`;
const PoList = () => {
    const history = useHistory();
    const netSuiteApiClient = useNetSuiteApiClient();
    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const initialValues = {
        location: '',
    };
    const handleSearch = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        // Make your API call here with the searchTerm
        // Update searchResults based on the API response
        setIsLoading(true);
        setSearchResults([]);
        try {
            const poList = yield netSuiteApiClient.fetchPurchaseOrdersV2((_a = values === null || values === void 0 ? void 0 : values.po) !== null && _a !== void 0 ? _a : '');
            setSearchResults((_b = poList === null || poList === void 0 ? void 0 : poList.items) !== null && _b !== void 0 ? _b : []);
        }
        catch (ex) {
            // Handle error
        }
        setIsLoading(false);
    });
    useEffect(() => {
        handleSearch({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getStatusText = (code) => {
        return Status[code] || 'Unknown';
    };
    const handleCreatePo = () => {
        // Redirect to another page (e.g., '/another-page')
        history.push('/scan-poc/v3/PO/edit/new');
    };
    return (<div>
      <Formik initialValues={initialValues} onSubmit={handleSearch}>
        <Form>
          <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <GridItem xs={12} sm={8} item>
              <Field fullWidth id="po" name="po" label="PO Number" as={TextField} variant="standard"/>
            </GridItem>
            <GridItem xs={12} sm={4} item>
              <Grid container spacing={2} direction="row" style={{ justifyContent: 'flex-end' }}>
                <GridItem item>
                  <Button type="submit" variant="contained" color="primary">
                    Search POs
                  </Button>
                </GridItem>
                <GridItem item>
                  <Button onClick={handleCreatePo} variant="contained" color="primary">
                    Create PO
                  </Button>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </Form>
      </Formik>
      {isLoading && <Loader />}
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>PO Action</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Created date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResults.map((result) => (<TableRow key={result === null || result === void 0 ? void 0 : result.id}>
                <Cell>
                  <Link to={`/scan-poc/v3/PO/edit/${result === null || result === void 0 ? void 0 : result.id}`}>View</Link> |
                  {['B', 'E'].indexOf(result === null || result === void 0 ? void 0 : result.status) >= 0 ? (<Link to={`/scan-poc/v3/PO/receive/${result === null || result === void 0 ? void 0 : result.id}`}>
                      {' '}
                      Receive{' '}
                    </Link>) : (<span> Receive </span>)}
                  | {result === null || result === void 0 ? void 0 : result.id}
                </Cell>
                <Cell>{result === null || result === void 0 ? void 0 : result.typebaseddocumentnumber}</Cell>
                <Cell>{result === null || result === void 0 ? void 0 : result.trandisplayname}</Cell>
                <Cell>{result === null || result === void 0 ? void 0 : result.createddate}</Cell>
                <Cell>{getStatusText(result === null || result === void 0 ? void 0 : result.status)}</Cell>
              </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>);
};
export default PoList;
