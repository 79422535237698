import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
import createAction from 'src/modules/api/utils/createAction';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
export const ActionCreators = {
    GET_WORKBENCH_DASHBOARD: createDefaultActionCreators('GET_WORKBENCH_DASHBOARD'),
    SUBMIT_SEND_INVOICE_REQUESTS: createDefaultActionCreators('SUBMIT_SEND_INVOICE_REQUESTS'),
    CHECK_DASHBOARD: createDefaultActionCreators('CHECK_DASHBOARD'),
    GET_INVOICE_REQUEST: createDefaultActionCreators('GET_INVOICE_REQUEST'),
};
const makeWorkbenchParamsAndBody = ({ pageNumber = 0, pageSize = 50, fleetId, responsiblePartyId, roStatus, customerType, sort, returnFacets, }) => {
    const params = {};
    if (pageNumber) {
        params.page = pageNumber;
    }
    if (pageSize) {
        params.size = pageSize;
    }
    if (sort) {
        params.sort = sort[0];
    }
    const body = { customerType, returnFacets };
    if (responsiblePartyId) {
        body.responsiblePartyId = responsiblePartyId;
    }
    if (fleetId) {
        body.fleetId = fleetId;
    }
    if (!(roStatus === null || roStatus === void 0 ? void 0 : roStatus.length)) {
        body.roStatus = [
            // WE WILL NEED THESE LATER
            // 'NEEDS_REVIEW',
            // 'REPAIR_COMPLETED_NO_INVOICE',
            'REPAIR_COMPLETED_WITH_INVOICE',
        ];
    }
    else {
        body.roStatus = [];
        if (roStatus.includes('NEEDS_REVIEW')) {
            body.roStatus.push('NEEDS_REVIEW');
        }
        if (roStatus.includes('READY')) {
            body.roStatus.push('REPAIR_COMPLETED_NO_INVOICE');
        }
        if (roStatus.includes('CLOSED')) {
            body.roStatus.push('REPAIR_COMPLETED_WITH_INVOICE');
        }
    }
    return { params, body };
};
export const getWorkbenchDashboard = ({ pageNumber = 0, pageSize = 50, fleetId, responsiblePartyId, roStatus, customerType, sort, returnFacets, }) => {
    const { params, body } = makeWorkbenchParamsAndBody({
        pageNumber,
        pageSize,
        fleetId,
        responsiblePartyId,
        roStatus,
        customerType,
        sort,
        returnFacets,
    });
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_WORKBENCH_DASHBOARD),
        endpoint: API_URLS.ADMIN.GET_WORKBENCH_INVOICES(params),
        method: 'POST',
        body,
    });
};
// The same endpoint as getWorkbenchDashboard but without updating store
export const checkDashboardUpdates = ({ pageNumber = 0, pageSize = 50, fleetId, responsiblePartyId, roStatus, customerType, sort, returnFacets, }) => {
    const { params, body } = makeWorkbenchParamsAndBody({
        pageNumber,
        pageSize,
        fleetId,
        responsiblePartyId,
        roStatus,
        customerType,
        sort,
        returnFacets,
    });
    return createAPIAction({
        types: fillApiTypes(ActionCreators.CHECK_DASHBOARD),
        endpoint: API_URLS.ADMIN.GET_WORKBENCH_INVOICES(params),
        method: 'POST',
        body,
    });
};
export const updateWorkbenchSelectedItems = createAction('UPDATE_WORKBENCH_SELECTED_ITEMS');
export const resetWorkbenchItemsSelected = createAction('RESET_WORKBENCH_SELECTED_ITEMS');
export const changeFilters = createAction('CHANGE_FILTERS');
export const submitSendInvoiceRequests = (data) => createAPIAction({
    types: fillApiTypes(ActionCreators.SUBMIT_SEND_INVOICE_REQUESTS),
    endpoint: API_URLS.ADMIN.SUBMIT_INVOICE_REQUESTS(),
    method: 'POST',
    body: Object.assign(Object.assign({}, data), { type: 'SEND_INVOICE' }),
});
export const getInvoiceRequest = (id) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_INVOICE_REQUEST),
    endpoint: API_URLS.ADMIN.GET_INVOICE_REQUEST(id),
    method: 'GET',
});
