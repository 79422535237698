import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/PopsApp/modules/api/urls';
export const ActionCreators = {
    FETCH_DISTRIBUTORS: createDefaultActionCreators('FETCH_DISTRIBUTORS'),
    ADD_DISTRIBUTORS: createDefaultActionCreators('ADD_DISTRIBUTORS'),
    UPDATE_DISTRIBUTOR: createDefaultActionCreators('UPDATE_DISTRIBUTOR'),
    FETCH_LOCATIONS: createDefaultActionCreators('FETCH_LOCATIONS'),
    ADD_LOCATIONS: createDefaultActionCreators('ADD_LOCATIONS'),
    UPDATE_LOCATIONS: createDefaultActionCreators('UPDATE_LOCATIONS'),
    FETCH_DISTRIBUTOR_HOURS_OF_OPERATION: createDefaultActionCreators('FETCH_DISTRIBUTOR_HOURS_OF_OPERATION'),
    UPDATE_DISTRIBUTOR_HOURS_OF_OPERATION: createDefaultActionCreators('UPDATE_DISTRIBUTOR_HOURS_OF_OPERATION'),
    FETCH_DISTRIBUTOR_LOCATION_HOURS_OF_OPERATION: createDefaultActionCreators('FETCH_DISTRIBUTOR_LOCATION_HOURS_OF_OPERATION'),
    UPDATE_LOCATION_HOURS_OF_OPERATION: createDefaultActionCreators('UPDATE_LOCATION_HOURS_OF_OPERATION'),
};
export const fetchDistributors = ({ pageSize, pageIndex, sort = ['name,asc'], }) => (dispatch) => {
    const params = Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort }));
    return dispatch(createAPIAction({
        types: [
            ActionCreators.FETCH_DISTRIBUTORS.FETCH({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.FETCH_DISTRIBUTORS.SUCCESS({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.FETCH_DISTRIBUTORS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISTRIBUTORS(),
        params: Object.assign({}, params),
        method: 'GET',
    }));
};
export const updateDistributor = (distributor) => {
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_DISTRIBUTOR.FETCH(),
            ActionCreators.UPDATE_DISTRIBUTOR.SUCCESS({
                meta: () => (Object.assign({}, distributor)),
            }),
            ActionCreators.UPDATE_DISTRIBUTOR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISTRIBUTOR_BY_ID(distributor.id),
        method: 'PUT',
        body: Object.assign(Object.assign({}, distributor), { modified: null, modifiedBy: null, created: null, createdBy: null }),
    });
};
export const saveDistributor = (distributor) => {
    return createAPIAction({
        types: [
            ActionCreators.ADD_DISTRIBUTORS.FETCH(),
            ActionCreators.ADD_DISTRIBUTORS.SUCCESS({
                meta: () => (Object.assign({}, distributor)),
            }),
            ActionCreators.ADD_DISTRIBUTORS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISTRIBUTORS(),
        method: 'POST',
        body: Object.assign({}, distributor),
    });
};
export const fetchDistributorLocations = ({ pageSize, pageIndex, street, dealershipName, sort = ['status,asc', 'street1,asc'], distributorId, }) => (dispatch) => {
    const params = Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort }));
    return dispatch(createAPIAction({
        types: [
            ActionCreators.FETCH_LOCATIONS.FETCH({
                meta: () => ({ pageSize, pageIndex, sort, params, distributorId }),
            }),
            ActionCreators.FETCH_LOCATIONS.SUCCESS({
                meta: () => ({ pageSize, pageIndex, sort, params, distributorId }),
            }),
            ActionCreators.FETCH_LOCATIONS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.LOCATIONS_BY_DISTRIBUTOR(distributorId),
        params: Object.assign(Object.assign({}, params), { street,
            dealershipName }),
        method: 'GET',
    }));
};
export const saveDistributorLocation = (distributorLocation) => {
    return createAPIAction({
        types: [
            ActionCreators.ADD_LOCATIONS.FETCH(),
            ActionCreators.ADD_LOCATIONS.SUCCESS({
                meta: () => (Object.assign({}, distributorLocation)),
            }),
            ActionCreators.ADD_LOCATIONS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISTRIBUTOR_LOCATIONS(),
        method: 'POST',
        body: Object.assign({}, distributorLocation),
    });
};
export const updateDistributorLocation = (distributorLocation) => {
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_LOCATIONS.FETCH(),
            ActionCreators.UPDATE_LOCATIONS.SUCCESS({
                meta: () => (Object.assign({}, distributorLocation)),
            }),
            ActionCreators.UPDATE_LOCATIONS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISTRIBUTOR_LOCATIONS_BY_ID(distributorLocation.id),
        method: 'PUT',
        body: Object.assign({}, distributorLocation),
    });
};
export const fetchDistributorHoursOfOperation = (distributorId) => (dispatch) => {
    return dispatch(createAPIAction({
        types: [
            ActionCreators.FETCH_DISTRIBUTOR_HOURS_OF_OPERATION.FETCH(),
            ActionCreators.FETCH_DISTRIBUTOR_HOURS_OF_OPERATION.SUCCESS(),
            ActionCreators.FETCH_DISTRIBUTOR_HOURS_OF_OPERATION.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISTRIBUTOR_HOURS_OF_OPERATION(distributorId),
        method: 'GET',
    }));
};
export const patchUpdateDistributorHoursOfOperation = (distributorId, values) => {
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_DISTRIBUTOR_HOURS_OF_OPERATION.FETCH(),
            ActionCreators.UPDATE_DISTRIBUTOR_HOURS_OF_OPERATION.SUCCESS({
                meta: () => (Object.assign({}, values)),
            }),
            ActionCreators.UPDATE_DISTRIBUTOR_HOURS_OF_OPERATION.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISTRIBUTOR_HOURS_OF_OPERATION(distributorId),
        method: 'PATCH',
        body: { entries: values },
    });
};
export const fetchDistributorLocationHoursOfOperation = (locationId) => (dispatch) => {
    return dispatch(createAPIAction({
        types: [
            ActionCreators.FETCH_DISTRIBUTOR_LOCATION_HOURS_OF_OPERATION.FETCH(),
            ActionCreators.FETCH_DISTRIBUTOR_LOCATION_HOURS_OF_OPERATION.SUCCESS(),
            ActionCreators.FETCH_DISTRIBUTOR_LOCATION_HOURS_OF_OPERATION.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISTRIBUTOR_LOCATION_HOURS_OF_OPERATION(locationId),
        method: 'GET',
    }));
};
export const patchUpdateLocationHoursOfOperation = (locationId, values) => {
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_LOCATION_HOURS_OF_OPERATION.FETCH(),
            ActionCreators.UPDATE_LOCATION_HOURS_OF_OPERATION.SUCCESS({
                meta: () => (Object.assign({}, values)),
            }),
            ActionCreators.UPDATE_LOCATION_HOURS_OF_OPERATION.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.DISTRIBUTOR_LOCATION_HOURS_OF_OPERATION(locationId),
        method: 'PATCH',
        body: { entries: values },
    });
};
