import createDefaultActionCreators, { createActionCreators, } from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/TechApp/modules/api/urls';
export const ActionCreators = {
    GET_LUNCH_BREAK: createDefaultActionCreators('GET_LUNCH_BREAK'),
    PATCH_LUNCH_BREAK: createDefaultActionCreators('PATCH_LUNCH_BREAK'),
    LUNCH_BREAK_DRAWER: createActionCreators(['OPEN', 'CLOSE'], 'LUNCH_BREAK_DRAWER'),
};
export const getLunchBreak = (lunchBreakId) => createAPIAction({
    types: [
        ActionCreators.GET_LUNCH_BREAK.FETCH(),
        ActionCreators.GET_LUNCH_BREAK.SUCCESS(),
        ActionCreators.GET_LUNCH_BREAK.FAILURE(),
    ],
    endpoint: API_URLS.LUNCH_BREAK(lunchBreakId),
    method: 'GET',
});
export const patchLunchBreak = (lunchBreakId, requestBody) => createAPIAction({
    types: [
        ActionCreators.PATCH_LUNCH_BREAK.FETCH(),
        ActionCreators.PATCH_LUNCH_BREAK.SUCCESS(),
        ActionCreators.PATCH_LUNCH_BREAK.FAILURE(),
    ],
    endpoint: API_URLS.LUNCH_BREAK(lunchBreakId),
    method: 'PATCH',
    body: requestBody,
});
