import React from 'react';
import { useSelector } from 'react-redux';
import { selectInvoiceByWorkOrderId } from 'src/AdminApp/modules/invoices/selectors';
import { selectRequestSnapshots, selectSuggestedRepairsArrayByRefNum, selectTheOnlyOneResponsiblePartyIsPrimary, selectWorkOrderResponsiblePartyTypes, } from 'src/AdminApp/modules/requests/selectors';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import SnapshotV2 from './SnapshotV2';
const SnapshotQuote = ({ isMobile, workOrderId }) => {
    const { referenceNum, selectorProps } = usePageReferenceNum();
    const requestSnapshots = useSelector((state) => selectRequestSnapshots(state, selectorProps));
    const invoiceSnapshot = (requestSnapshots === null || requestSnapshots === void 0 ? void 0 : requestSnapshots.find((snapshot) => snapshot.type === 'INVOICE')) || null;
    const quoteSnapshot = (requestSnapshots === null || requestSnapshots === void 0 ? void 0 : requestSnapshots.find((snapshot) => snapshot.type === 'QUOTE')) || null;
    const hasOnlyOneResponsiblePartyType = useSelector(selectTheOnlyOneResponsiblePartyIsPrimary(referenceNum));
    const workOrderResponsiblePartyTypes = useSelector(selectWorkOrderResponsiblePartyTypes(referenceNum));
    const suggestedRepairsArrayByRefNum = useSelector(selectSuggestedRepairsArrayByRefNum(referenceNum));
    const originalInvoice = useSelector((state) => selectInvoiceByWorkOrderId(state, { workOrderId }));
    return (<SnapshotV2 referenceNum={referenceNum} quoteSnapshot={quoteSnapshot} invoiceSnapshot={invoiceSnapshot} originalInvoice={originalInvoice} hasOnlyOneResponsiblePartyType={hasOnlyOneResponsiblePartyType} workOrderResponsiblePartyTypes={workOrderResponsiblePartyTypes} suggestedRepairsArrayByRefNum={suggestedRepairsArrayByRefNum} isMobile={isMobile} workOrderId={workOrderId}/>);
};
export default SnapshotQuote;
