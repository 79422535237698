export var TemplateWeekday;
(function (TemplateWeekday) {
    TemplateWeekday["MONDAY"] = "MONDAY";
    TemplateWeekday["TUESDAY"] = "TUESDAY";
    TemplateWeekday["WEDNESDAY"] = "WEDNESDAY";
    TemplateWeekday["THURSDAY"] = "THURSDAY";
    TemplateWeekday["FRIDAY"] = "FRIDAY";
    TemplateWeekday["SATURDAY"] = "SATURDAY";
    TemplateWeekday["SUNDAY"] = "SUNDAY";
})(TemplateWeekday || (TemplateWeekday = {}));
export default TemplateWeekday;
