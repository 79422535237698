import React from 'react';
import RepairsList from 'src/AdminApp/containers/requests/Quote/PartsHelp/RepairsList';
import { SubText } from 'src/components/SimpleDrawer';
const AdditionalComponentsRequired = ({ goNext, instructions, disabled, invoice, }) => {
    const onRepairSelect = (repair) => {
        goNext(repair);
    };
    return (<>
      <h2>Select Repair</h2>

      <SubText>{instructions}</SubText>

      <RepairsList onRepairSelect={onRepairSelect} disabled={disabled} invoice={invoice}/>
    </>);
};
export default AdditionalComponentsRequired;
