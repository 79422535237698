import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'src/components/mui';
import { selectFleetLatestROs } from 'src/AdminApp/modules/quickRo/selectors';
import { ActionCreators, fetchFleetLatestROs, } from 'src/AdminApp/modules/quickRo/actions';
import ROCard from './ROCard';
const Container = styled('div') `
  margin-top: 80px;
`;
const Title = styled(Typography) `
  margin-bottom: 24px;
`;
const Subtitle = styled(Typography) `
  font-size: 0.875rem;
  font-weight: 500;
`;
const LatestROs = ({ carId }) => {
    const dispatch = useDispatch();
    const latestROs = useSelector(selectFleetLatestROs);
    useEffect(() => {
        if (carId) {
            dispatch(fetchFleetLatestROs(carId));
        }
        return () => {
            dispatch(ActionCreators.FETCH_FLEET_LATESTS_ROS.CLEAR());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carId]);
    const hasROs = (latestROs === null || latestROs === void 0 ? void 0 : latestROs.ROs.length) > 0;
    return (<Container>
      <Title variant="h5">Latest Repair Orders</Title>
      {!hasROs ? (<Subtitle variant="subtitle1">No Repair Orders Found</Subtitle>) : (latestROs.ROs.map((ro) => <ROCard key={ro.id} RO={ro}/>))}
    </Container>);
};
export default LatestROs;
