var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { Weekdays } from 'src/models/enums';
import { fetchDistributorHoursOfOperation, patchUpdateDistributorHoursOfOperation, } from 'src/PopsApp/modules/distributors/actions';
import { selectDistributorHoursOfOperation } from 'src/PopsApp/modules/distributors/selectors';
import { TIME_FORMAT } from 'src/timeConstants';
const INITIAL_TIME_ENTRY = {
    open: false,
    openTime: '08:00:00',
    closeTime: '08:00:00',
};
export const WEEKDAYS = Object.keys(Weekdays);
const useDistributorHoursOfOperationDrawer = ({ distributor, onClose, }) => {
    const dispatch = useDispatch();
    const distributorHoursOfOperation = useSelector(selectDistributorHoursOfOperation);
    const [isLoading, setIsLoading] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (distributor) {
                setIsLoading(true);
                yield dispatch(fetchDistributorHoursOfOperation(distributor.id));
                setIsLoading(false);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distributor]);
    const getInitialValues = () => {
        const initialValues = {
            MONDAY: INITIAL_TIME_ENTRY,
            TUESDAY: INITIAL_TIME_ENTRY,
            WEDNESDAY: INITIAL_TIME_ENTRY,
            THURSDAY: INITIAL_TIME_ENTRY,
            FRIDAY: INITIAL_TIME_ENTRY,
            SATURDAY: INITIAL_TIME_ENTRY,
            SUNDAY: INITIAL_TIME_ENTRY,
        };
        if (distributorHoursOfOperation) {
            distributorHoursOfOperation.forEach((entry) => {
                initialValues[entry.weekday] = {
                    open: entry.open,
                    openTime: entry.openTime,
                    closeTime: entry.closeTime,
                    id: entry.id,
                };
            });
        }
        return initialValues;
    };
    const invalidHoursErrorMessage = (values) => {
        const sameHours = values.filter((item) => moment
            .utc(item.closeTime, TIME_FORMAT)
            .isSame(moment.utc(item.openTime, TIME_FORMAT))).length > 0;
        if (sameHours) {
            return 'Validation Error: Close time must be different from open time';
        }
        const closeBeforeOpen = values.filter((item) => moment
            .utc(item.closeTime, TIME_FORMAT)
            .isBefore(moment.utc(item.openTime, TIME_FORMAT))).length > 0;
        if (closeBeforeOpen) {
            return 'Validation Error: Close time must be after open time';
        }
        return null;
    };
    const onHandleSubmit = (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!openConfirmationDialog) {
            setSubmitting(true);
            const initialValues = getInitialValues();
            const payload = Object.keys(initialValues).map((el) => {
                return Object.assign({ weekday: el }, values[el]);
            });
            if (payload.length > 0 && (distributor === null || distributor === void 0 ? void 0 : distributor.id)) {
                const validation = invalidHoursErrorMessage(payload);
                if (validation) {
                    toast.error(validation);
                    setSubmitting(false);
                    return;
                }
                const response = yield dispatch(patchUpdateDistributorHoursOfOperation(distributor === null || distributor === void 0 ? void 0 : distributor.id, payload));
                if (response && !response.error) {
                    toast.success('Distributor successfully updated.');
                }
                onClose();
            }
            setSubmitting(false);
        }
    });
    return {
        getInitialValues,
        openConfirmationDialog,
        isLoading,
        invalidHoursErrorMessage,
        onHandleSubmit,
        setOpenConfirmationDialog,
    };
};
export default useDistributorHoursOfOperationDrawer;
