var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { TableBody, Table, TableRow, TableSortLabel, TableCell, useMediaQuery, useTheme, } from 'src/components/mui';
import { selectVisitsData, selectVisitsPagination, selectVisitDate, selectVisitSort, selectVisitFleetIds, selectVisitStations, } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { getVisitsDashboard, setVisitSort, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { PAGE_SIZE } from 'src/FleetVisitsApp/modules/api/constants';
import { DATE_YMD_FORMAT } from 'src/timeConstants';
import Loader from './Loader';
import { VisitsTableHead, VisitsContainer, VisitsPagination, } from './Content.styles';
import VisitRow from './VisitRow/VisitRow';
import VisitMobile from './VisitMobile/VisitMobile';
import DatePicker from './DatePicker/DatePicker';
import VisitFilter from './Filter/VisitFilter';
const columnNames = [
    { id: 'customers', label: 'Customers' },
    { id: 'techs', label: 'Techs' },
    {
        id: 'apptTime',
        label: 'Appointment Time',
        sortable: true,
        field: 'startLocal',
    },
    {
        id: 'capacity',
        label: 'Capacity',
        sortable: false,
        field: 'capacity',
    },
    { id: 'subtotal', label: 'Subtotal', sortable: true, field: 'subtotal' },
    { id: 'status', label: 'Status', sortable: true, field: 'status' },
];
const Content = () => {
    var _a, _b, _c;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const visits = useSelector(selectVisitsData);
    const pagination = useSelector(selectVisitsPagination);
    const visitDate = useSelector(selectVisitDate);
    const sort = useSelector(selectVisitSort);
    const fleetIds = (_a = useSelector(selectVisitFleetIds)) !== null && _a !== void 0 ? _a : '';
    const fleetStations = (_b = useSelector(selectVisitStations)) !== null && _b !== void 0 ? _b : '';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [sortName, sortDirection] = (_c = sort.split(',')) !== null && _c !== void 0 ? _c : ['startLocal', 'asc'];
    const getRequestParams = ({ newPage, newSize, newSort, newDate, }) => {
        var _a, _b;
        const params = {
            page: (_a = newPage !== null && newPage !== void 0 ? newPage : pagination === null || pagination === void 0 ? void 0 : pagination.page) !== null && _a !== void 0 ? _a : 0,
            size: (_b = newSize !== null && newSize !== void 0 ? newSize : pagination === null || pagination === void 0 ? void 0 : pagination.size) !== null && _b !== void 0 ? _b : PAGE_SIZE,
            date: newDate !== null && newDate !== void 0 ? newDate : moment(visitDate, DATE_YMD_FORMAT).format(DATE_YMD_FORMAT),
            sort: newSort !== null && newSort !== void 0 ? newSort : sort,
        };
        const storedFilters = localStorage.getItem('fleetVisitsFilter');
        const storedFiltersPageSize = localStorage.getItem('fleetVisitsPageSize');
        const storedFleetIds = [];
        const storedStationNames = [];
        if (storedFilters) {
            JSON.parse(storedFilters).forEach((f) => {
                if (f.type === 'fleet') {
                    storedFleetIds.push(f.id);
                }
                else if (f.type === 'station') {
                    storedStationNames.push(f.stationName);
                }
            });
        }
        if (!fleetIds && storedFleetIds.length > 0) {
            params.fleetIds = storedFleetIds;
        }
        else if (fleetIds) {
            params.fleetIds = fleetIds;
        }
        if (!fleetStations && storedStationNames.length > 0) {
            params.stationNames = storedStationNames;
        }
        else if (fleetStations) {
            params.stationNames = fleetStations;
        }
        if (storedFiltersPageSize) {
            params.size = storedFiltersPageSize;
        }
        return params;
    };
    const requestVisits = (params) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield dispatch(getVisitsDashboard(getRequestParams(params)));
        setLoading(false);
    });
    useEffect(() => {
        requestVisits({});
    }, [fleetIds, fleetStations]); // eslint-disable-line react-hooks/exhaustive-deps
    const handlePageSizeChange = (e) => {
        localStorage.setItem('fleetVisitsPageSize', e.target.value);
        requestVisits({ newSize: e.target.value, newPage: 0 });
    };
    const handlePageChange = (e, page) => {
        requestVisits({ newPage: page });
    };
    const handleSortChange = (item) => () => {
        let sortItem;
        if (sortName === item.field && sortDirection === 'asc') {
            sortItem = [item.field, 'desc'].join(',');
        }
        else {
            sortItem = [item.field, 'asc'].join(',');
        }
        dispatch(setVisitSort(sortItem));
        requestVisits({ newSort: sortItem });
    };
    const handleDatePick = (date) => {
        requestVisits({ newDate: date });
    };
    if (!visits)
        return null;
    return (<VisitsContainer>
      <VisitFilter />
      <DatePicker onChange={handleDatePick}/>

      {isMobile &&
            visits.map((item) => (<VisitMobile key={item.id} item={item}/>))}
      {!isMobile && (<Table>
          <VisitsTableHead>
            <TableRow>
              {columnNames.map((item) => (<TableCell key={item.id} align="left" sortDirection={sortName === item.field ? sortDirection : false}>
                  {item.sortable ? (<TableSortLabel active={sortName === item.field} direction={sortName === item.field ? sortDirection : 'asc'} onClick={handleSortChange(item)}>
                      {item.label}
                    </TableSortLabel>) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>{item.label}</>)}
                </TableCell>))}
            </TableRow>
          </VisitsTableHead>
          <TableBody>
            {!loading &&
                visits.map((item) => (<VisitRow key={item.id} item={item}/>))}
          </TableBody>
        </Table>)}
      {!(visits === null || visits === void 0 ? void 0 : visits.length) && !loading && (<div className="VisitsNoData">
          There are no visits for selected date
        </div>)}
      {!!(visits === null || visits === void 0 ? void 0 : visits.length) && !loading && (<VisitsPagination classes={{
                root: 'page-root',
                toolbar: 'VisitsPaginationToolbar',
            }} count={pagination.totalElements} page={pagination.page} onPageChange={handlePageChange} rowsPerPage={pagination.size} onRowsPerPageChange={handlePageSizeChange}/>)}
      {loading && <Loader />}
    </VisitsContainer>);
};
export default Content;
