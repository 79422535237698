import { handleActions } from 'redux-actions';
import { fetched, fetching } from 'src/utils/data-utils';
import { CUSTOM_REPAIR_ID } from 'src/AdminApp/modules/api/constants';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { ActionCreators, setResponsiblePartyId } from './actions';
import { DEFAULT_TAB } from './enums';
export const DEFAULT_STATE = {
    tab: DEFAULT_TAB,
    nextTab: undefined,
    vehicle: undefined,
    repairs: [],
    pricing: {},
    region: undefined,
    mechanicIds: [],
    dateTime: undefined,
    timeSlot: undefined,
    activeMechanics: {},
    schedulingAvailability: {},
    isBackNavDisabled: false,
    continueDisabled: false,
    fleetLatestROs: {
        ROs: [],
    },
    fleetVisits: [],
    visit: undefined,
    setResponsiblePartyId: undefined,
    newFleetVehicleResponsibleParty: {},
};
const setTab = (state, action) => {
    return Object.assign(Object.assign({}, state), { tab: action.payload });
};
const setNextTab = (state, action) => {
    return Object.assign(Object.assign({}, state), { nextTab: action.payload });
};
const setUser = (state, action) => {
    return Object.assign(Object.assign({}, state), { user: action.payload });
};
const setCustomUser = (state, action) => {
    return Object.assign(Object.assign({}, state), { customUser: Object.assign(Object.assign({}, state.customUser), action.payload) });
};
const setFleet = (state, action) => {
    return Object.assign(Object.assign({}, state), { fleet: action.payload });
};
const setCustomFleet = (state, action) => {
    return Object.assign(Object.assign({}, state), { customFleet: Object.assign(Object.assign({}, state.customFleet), action.payload) });
};
const setCustomConsumerAddress = (state, action) => {
    return Object.assign(Object.assign({}, state), { customAddress: Object.assign({}, action.payload) });
};
const setCustomVehicle = (state, action) => {
    return Object.assign(Object.assign({}, state), { customVehicle: Object.assign(Object.assign({}, state.customVehicle), action.payload) });
};
const setVehicle = (state, action) => {
    return Object.assign(Object.assign({}, state), { vehicle: action.payload });
};
const clearVehicle = (state) => (Object.assign(Object.assign({}, state), { vehicle: DEFAULT_STATE.vehicle }));
const setVehicleMileage = (state, action) => {
    return Object.assign(Object.assign({}, state), { vehicle: Object.assign(Object.assign({}, state.vehicle), { customMileage: action.payload }) });
};
const resetCustomVehicle = (state) => {
    return Object.assign(Object.assign({}, state), { customVehicle: {} });
};
const resetVehicle = (state) => {
    return Object.assign(Object.assign({}, state), { vehicle: {} });
};
const addRepair = (state, action) => {
    return Object.assign(Object.assign({}, state), { repairs: [...state.repairs, action.payload] });
};
const setCreateNewFleetVehicleRP = (state, action) => {
    return Object.assign(Object.assign({}, state), { newFleetVehicleResponsibleParty: action.payload });
};
const clearCreateNewFleetVehicleRP = (state) => {
    return Object.assign(Object.assign({}, state), { newFleetVehicleResponsibleParty: {
            vin: '',
            responsiblePartyId: '',
            carId: undefined,
        } });
};
const removeRepair = (state, action) => {
    return Object.assign(Object.assign({}, state), { repairs: state.repairs.filter((repair) => repair.repairId !== action.payload.repairId) });
};
const setRepairs = (state, action) => {
    return Object.assign(Object.assign({}, state), { repairs: action.payload });
};
const resetRepairs = (state) => {
    return Object.assign(Object.assign({}, state), { repairs: DEFAULT_STATE.repairs });
};
const setMechanicIds = (state, action) => {
    const prev = state.mechanicIds;
    const id = action.payload;
    if (prev === null || prev === void 0 ? void 0 : prev.includes(id)) {
        const mechanicIds = prev.filter((val) => val !== id);
        return Object.assign(Object.assign({}, state), { mechanicIds });
    }
    const mechanicIds = [...prev, id];
    return Object.assign(Object.assign({}, state), { mechanicIds });
};
const clearMechanicIds = (state) => {
    return Object.assign(Object.assign({}, state), { mechanicIds: [] });
};
const setVanId = (state, action) => {
    const id = action.payload;
    const mechanics = state.mechanicIds;
    const vanId = id;
    if ((mechanics === null || mechanics === void 0 ? void 0 : mechanics.length) === 0) {
        return Object.assign(Object.assign({}, state), { vanId: null });
    }
    return Object.assign(Object.assign({}, state), { vanId });
};
const clearVanId = (state) => {
    return Object.assign(Object.assign({}, state), { vanId: null });
};
const setDateTime = (state, action) => {
    return Object.assign(Object.assign({}, state), { dateTime: action.payload });
};
const clearDateTime = (state) => (Object.assign(Object.assign({}, state), { dateTime: null }));
const setFutureAppointmentDate = (state, action) => {
    return Object.assign(Object.assign({}, state), { futureAppointmentDate: action.payload });
};
const setFutureAppointmentDateCheck = (state, action) => {
    return Object.assign(Object.assign({}, state), { futureAppointmentDateCheck: action.payload });
};
const setTimeSlot = (state, action) => {
    return Object.assign(Object.assign({}, state), { timeSlot: action.payload });
};
const setShift = (state, action) => {
    return Object.assign(Object.assign({}, state), { shift: action.payload });
};
const clearTimeSlot = (state) => (Object.assign(Object.assign({}, state), { timeSlot: null }));
const resetScheduling = (state) => (Object.assign(Object.assign({}, state), { activeMechanics: DEFAULT_STATE.activeMechanics, schedulingAvailability: DEFAULT_STATE.schedulingAvailability, mechanicIds: DEFAULT_STATE.mechanicIds, pricing: DEFAULT_STATE.pricing, region: DEFAULT_STATE.region, timeSlot: DEFAULT_STATE.timeSlot }));
const resetAll = () => (Object.assign({}, DEFAULT_STATE));
const fetchPricingFetching = (state, action) => {
    const pricing = fetching(Object.assign({}, action.payload));
    return Object.assign(Object.assign({}, state), { pricing });
};
const setPricing = (state, action) => {
    const { pricing } = state;
    const { totalLaborDuration, jobDuration, reFetchDisabled } = action.payload;
    return Object.assign(Object.assign({}, state), { pricing: Object.assign(Object.assign({}, pricing), { totalLaborDuration,
            jobDuration,
            reFetchDisabled }) });
};
const fetchPricingSucceeded = (state, action) => {
    const pricing = fetched(Object.assign({}, action.payload));
    const { repairs } = state;
    // HACK: TS-1802 - manually add the custom repairs for labor duration computation
    // TODO: this should be computed in the pricing-service
    const customRepairs = (repairs === null || repairs === void 0 ? void 0 : repairs.filter((repair) => repair.repairId === CUSTOM_REPAIR_ID)) || [];
    customRepairs.forEach((repair) => {
        pricing.packages.push({
            repairId: repair.repairId,
            name: repair.customRepairText,
            laborDuration: repair.laborTime,
        });
    });
    pricing.totalLaborDuration = pricing.packages
        .map((p) => p.laborDuration)
        .reduce((a, b) => a + b, 0);
    pricing.jobDuration = pricing.totalLaborDuration;
    return Object.assign(Object.assign({}, state), { pricing });
};
const fetchPricingFailed = (state) => (Object.assign(Object.assign({}, state), { pricing: fetched(Object.assign({}, DEFAULT_STATE.pricing)) }));
const activeMechanicsFetching = (state, action) => {
    const activeMechanics = fetching({ mechanics: action.payload });
    return Object.assign(Object.assign({}, state), { activeMechanics });
};
const activeMechanicsSucceeded = (state, action) => {
    var _a;
    const activeMechanics = fetched({ mechanics: action.payload });
    const newMechanicIds = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const mechanicId of (_a = state.mechanicIds) !== null && _a !== void 0 ? _a : []) {
        if (activeMechanics.mechanics.includes(mechanicId)) {
            newMechanicIds.push(mechanicId);
        }
    }
    return Object.assign(Object.assign({}, state), { activeMechanics, mechanicIds: newMechanicIds });
};
const clearActiveMechanics = (state) => (Object.assign(Object.assign({}, state), { region: DEFAULT_STATE.region, activeMechanics: DEFAULT_STATE.activeMechanics }));
const schedulingAvailabilityFetching = (state, action) => {
    const schedulingAvailability = fetching(action.payload);
    return Object.assign(Object.assign({}, state), { schedulingAvailability });
};
const schedulingAvailabilitySucceeded = (state, action) => {
    const schedulingAvailability = fetched(action.payload);
    return Object.assign(Object.assign({}, state), { region: schedulingAvailability.region, schedulingAvailability });
};
const clearSchedulingAvailability = (state) => (Object.assign(Object.assign({}, state), { schedulingAvailability: DEFAULT_STATE.schedulingAvailability }));
const setDisableBackNav = (state, action) => {
    return Object.assign(Object.assign({}, state), { isBackNavDisabled: action.payload.isBackNavDisabled });
};
const setContinueDisabled = (state, action) => {
    return Object.assign(Object.assign({}, state), { continueDisabled: action.payload });
};
const fleetLatestROsFetching = (state, action) => {
    const fleetLatestROs = fetching({
        ROs: action.payload || [],
    });
    return Object.assign(Object.assign({}, state), { fleetLatestROs });
};
const fleetLatestROsSucceeded = (state, action) => {
    const fleetLatestROs = fetched({
        ROs: action.payload || [],
    });
    return Object.assign(Object.assign({}, state), { fleetLatestROs });
};
const fleetLatestROsClear = (state) => (Object.assign(Object.assign({}, state), { fleetLatestROs: DEFAULT_STATE.fleetLatestROs }));
const setVisit = (state, action) => (Object.assign(Object.assign({}, state), { visit: action.payload.visit }));
const reducers = handleActions({
    [ActionCreators.TAB.SAVE_TAB.type]: setTab,
    [ActionCreators.TAB.SAVE_NEXT_TAB.type]: setNextTab,
    [ActionCreators.TAB.SAVE_USER.type]: setUser,
    [ActionCreators.TAB.SAVE_CUSTOM_USER.type]: setCustomUser,
    [ActionCreators.TAB.SAVE_FLEET.type]: setFleet,
    [ActionCreators.TAB.SAVE_CUSTOM_FLEET.type]: setCustomFleet,
    [ActionCreators.TAB.SAVE_CUSTOM_ADDRESS.type]: setCustomConsumerAddress,
    [ActionCreators.TAB.SAVE_CUSTOM_VEHICLE.type]: setCustomVehicle,
    [ActionCreators.TAB.SAVE_VEHICLE.type]: setVehicle,
    [ActionCreators.TAB.CLEAR_VEHICLE.type]: clearVehicle,
    [ActionCreators.TAB.SAVE_VEHICLE_MILEAGE.type]: setVehicleMileage,
    [ActionCreators.TAB.RESET_VEHICLE.type]: resetVehicle,
    [ActionCreators.TAB.RESET_CUSTOM_VEHICLE.type]: resetCustomVehicle,
    [ActionCreators.TAB.SET_CREATE_NEW_FLEET_VEHICLE_RP.type]: setCreateNewFleetVehicleRP,
    [ActionCreators.TAB.CLEAR_CREATE_NEW_FLEET_VEHICLE_RP.type]: clearCreateNewFleetVehicleRP,
    [ActionCreators.TAB.ADD_REPAIR.type]: addRepair,
    [ActionCreators.TAB.REMOVE_REPAIR.type]: removeRepair,
    [ActionCreators.TAB.SAVE_REPAIRS.type]: setRepairs,
    [ActionCreators.TAB.RESET_REPAIRS.type]: resetRepairs,
    [ActionCreators.TAB.SAVE_MECHANIC_IDS.type]: setMechanicIds,
    [ActionCreators.TAB.CLEAR_MECHANIC_IDS.type]: clearMechanicIds,
    [ActionCreators.TAB.SAVE_VAN_ID.type]: setVanId,
    [ActionCreators.TAB.CLEAR_VAN_ID.type]: clearVanId,
    [ActionCreators.TAB.SAVE_DATE_TIME.type]: setDateTime,
    [ActionCreators.TAB.CLEAR_DATE_TIME.type]: clearDateTime,
    [ActionCreators.TAB.SAVE_FUTURE_APPOINTMENT_DATE.type]: setFutureAppointmentDate,
    [ActionCreators.TAB.SAVE_FUTURE_APPOINTMENT_DATE_CHECK.type]: setFutureAppointmentDateCheck,
    [ActionCreators.TAB.SAVE_TIME_SLOT.type]: setTimeSlot,
    [ActionCreators.TAB.SAVE_SHIFT.type]: setShift,
    [ActionCreators.TAB.CLEAR_TIME_SLOT.type]: clearTimeSlot,
    [ActionCreators.TAB.RESET_SCHEDULING.type]: resetScheduling,
    [ActionCreators.TAB.RESET_ALL.type]: resetAll,
    [ActionCreators.TAB.SAVE_DISABLE_BACK_NAV.type]: setDisableBackNav,
    [ActionCreators.TAB.SAVE_CONTINUE_DISABLED.type]: setContinueDisabled,
    [ActionCreators.FETCH_PRICING.FETCH.type]: fetchPricingFetching,
    [ActionCreators.FETCH_PRICING.SUCCESS.type]: fetchPricingSucceeded,
    [ActionCreators.FETCH_PRICING.FAILURE.type]: fetchPricingFailed,
    [ActionCreators.TAB.SAVE_PRICING.type]: setPricing,
    [ActionCreators.ACTIVE_MECHANICS.FETCH.type]: activeMechanicsFetching,
    [ActionCreators.ACTIVE_MECHANICS.SUCCESS.type]: activeMechanicsSucceeded,
    [ActionCreators.ACTIVE_MECHANICS.FAILURE.type]: clearActiveMechanics,
    [ActionCreators.ACTIVE_MECHANICS.CLEAR.type]: clearActiveMechanics,
    [ActionCreators.SCHEDULING_AVAILABILITY.FETCH.type]: schedulingAvailabilityFetching,
    [ActionCreators.SCHEDULING_AVAILABILITY.SUCCESS.type]: schedulingAvailabilitySucceeded,
    [ActionCreators.SCHEDULING_AVAILABILITY.FAILURE.type]: clearSchedulingAvailability,
    [ActionCreators.SCHEDULING_AVAILABILITY.CLEAR.type]: clearSchedulingAvailability,
    [ActionCreators.FETCH_FLEET_LATESTS_ROS.FETCH.type]: fleetLatestROsFetching,
    [ActionCreators.FETCH_FLEET_LATESTS_ROS.SUCCESS.type]: fleetLatestROsSucceeded,
    [ActionCreators.FETCH_FLEET_LATESTS_ROS.FAILURE.type]: fleetLatestROsClear,
    [ActionCreators.FETCH_FLEET_LATESTS_ROS.CLEAR.type]: fleetLatestROsClear,
    [ActionCreators.FETCH_FLEET_VISITS.SUCCESS.type]: createSimpleHandler('fleetVisits'),
    [ActionCreators.TAB.SAVE_VISIT.type]: setVisit,
    [setResponsiblePartyId.type]: createSimpleHandler('setResponsiblePartyId'),
}, DEFAULT_STATE);
export default reducers;
