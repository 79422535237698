import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import AddServices from 'src/AdminApp/containers/requests/CantFinish/CantFinishRequiresAddtlRepair/AddServices';
import { setRepairs } from 'src/AdminApp/modules/quickRo/actions';
import { selectRepairs } from 'src/AdminApp/modules/quickRo/selectors';
import { SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import SimpleTwoButtonRow from 'src/components/SimpleTwoButtonRow';
import { IntroContentTodayReturn, IntroTodayReturnCopy, IntroTodayReturnHeader, } from '../CantFinishDrawer.styles';
const CantFinishAddServiceDrawer = ({ sendToTodayReturnVisit, addSelectedRepair, todayVisit, }) => {
    const repairs = useSelector(selectRepairs);
    const dispatch = useDispatch();
    const removeDuplicatedRepairsByLabelFromList = (list) => {
        return [...new Map(list.map((item) => [item.label, item])).values()];
    };
    return (<>
      <SimpleDrawerContent>
        <IntroContentTodayReturn>
          <IntroTodayReturnHeader>Search Repairs</IntroTodayReturnHeader>
          <IntroTodayReturnCopy>
            <ReactMarkdown>
              Add more repairs by using the search feature below:
            </ReactMarkdown>
          </IntroTodayReturnCopy>
          <AddServices showItems/>
        </IntroContentTodayReturn>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <SimpleTwoButtonRow submitText="Save" onCancel={() => {
            sendToTodayReturnVisit();
            dispatch(setRepairs([]));
        }} onSubmit={() => {
            sendToTodayReturnVisit();
            const repairsToBeAdded = removeDuplicatedRepairsByLabelFromList([
                ...todayVisit,
                ...repairs,
            ]);
            addSelectedRepair(repairsToBeAdded);
            dispatch(setRepairs([]));
        }}/>
      </SimpleDrawerFooter>
    </>);
};
export default CantFinishAddServiceDrawer;
