var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField as MTextField, } from 'src/components/mui';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
import { Field, Form } from 'formik';
import React from 'react';
import styled from 'styled-components';
import CheckboxGroup from './CheckboxGroup';
import HoursSelect from './HoursSelect';
import Stars from './Stars';
import StateSelect from './StateSelect';
import { createMenuItems } from './utils';
import YearSelect from './YearSelect';
export const FieldTitle = styled.div `
  font-weight: 600;
  margin-top: 16px;
`;
export const FormSection = ({ title, xs = 12, md = 12, children, }) => (<Grid container item xs={xs} md={md}>
    <Grid item xs={xs} md={md}>
      <FieldTitle>{title}</FieldTitle>
    </Grid>
    {children}
  </Grid>);
export const FormPanel = ({ children, ariaName, title }) => (<Accordion defaultExpanded>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${ariaName}-content`} id={`${ariaName}-header`}>
      <b>{title}</b>
    </AccordionSummary>
    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
      {children}
    </AccordionDetails>
  </Accordion>);
export const RowFormContainer = styled(Form) `
  display: flex;
  flex-direction: row;
  button:not(:first-of-type) {
    margin-left: 10px;
  }
`;
export const ModuleFormContainer = styled(Form) `
  display: flex;
  flex-direction: column;
  font-size: 13px;
  button:not(:first-of-type) {
    margin-left: 10px;
  }
`;
export const TextField = (_a) => {
    var { children = null, inputProps, multiline = false, name, type = 'text', validate = undefined } = _a, rest = __rest(_a, ["children", "inputProps", "multiline", "name", "type", "validate"]);
    return (<Field name={name} type={type} validate={validate}>
    {({ field, form }) => {
            return (<MTextField id={name} fullWidth type={type} error={!!(form.touched[field.name] && form.errors[field.name])} label={form.touched[field.name] &&
                    form.errors[field.name]} {...field} {...rest} multiline={multiline} inputProps={inputProps}>
          {children}
        </MTextField>);
        }}
  </Field>);
};
export const RadioButton = (_a) => {
    var { field: { name, value, onChange, onBlur }, id, label, labelStyle, disabled = false } = _a, props = __rest(_a, ["field", "id", "label", "labelStyle", "disabled"]);
    return (<div>
      <input name={name} id={id} type="radio" value={id !== null && id !== void 0 ? id : ''} checked={id === value} onChange={onChange} onBlur={onBlur} disabled={disabled} {...props}/>
      <label style={Object.assign({ marginLeft: '8px' }, labelStyle)} htmlFor={id}>
        {label}
      </label>
    </div>);
};
export const RadioButtonField = ({ name, id, label, }) => (<Field component={RadioButton} name={name} id={id} label={label || id}/>);
export const EnumDropdown = ({ thisEnum, displayValue = true, label = '', name = '', disabled = false, fullWidth = false, variant = 'outlined', }) => {
    const inputLabel = React.useRef(null);
    return (<Field name={name}>
      {({ field }) => {
            return (<FormControl fullWidth>
            <InputLabel shrink ref={inputLabel}>
              {label}
            </InputLabel>
            <Select {...field} inputProps={{ name, id: name, disabled }} fullWidth={fullWidth} variant={variant}>
              <MenuItem value={undefined} key="unset">
                &nbsp;
              </MenuItem>
              {createMenuItems(thisEnum, displayValue, undefined, false)}
            </Select>
          </FormControl>);
        }}
    </Field>);
};
export const SwitchField = ({ name, onChange }) => {
    return (<Field name={name} render={({ field }) => {
            return (<Switch {...field} onChange={(...args) => {
                    if (onChange)
                        onChange(...args);
                    field.onChange(...args);
                }} checked={field.value} color="primary" name="checkedB" inputProps={{
                    name,
                    'id': name,
                    'aria-label': 'primary checkbox',
                }}/>);
        }}/>);
};
export { CheckboxGroup, StateSelect, HoursSelect, YearSelect, Stars };
