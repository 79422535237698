var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { List } from 'immutable';
import { getRecommendedRepairs } from 'src/AdminApp/modules/requests/actions';
const useWarrantyDecisionsRecommendedRepairs = (setApprovedInspectionIds, approvedInspectionIds, referenceNumber) => {
    const dispatch = useDispatch();
    const [selectedRepairs, setSelectedRepairs] = useState(List([]));
    const [isLoading, setIsLoading] = useState(false);
    const fetchApprovedInspections = (referenceNumber) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e;
        if (referenceNumber) {
            setIsLoading(true);
            const response = yield dispatch(getRecommendedRepairs(referenceNumber));
            const recommendedRepairs = (_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.recommendedRepairs;
            const createdRepairs = (_b = response === null || response === void 0 ? void 0 : response.payload) === null || _b === void 0 ? void 0 : _b.createdRepairs;
            const rejectedRepairs = (_c = response === null || response === void 0 ? void 0 : response.payload) === null || _c === void 0 ? void 0 : _c.rejectedRepairs;
            const scheduledRepairs = (_d = response === null || response === void 0 ? void 0 : response.payload) === null || _d === void 0 ? void 0 : _d.scheduledRepairs;
            const addedRepairs = (_e = response === null || response === void 0 ? void 0 : response.payload) === null || _e === void 0 ? void 0 : _e.addedRepairs;
            let finalList = [];
            [
                recommendedRepairs,
                createdRepairs,
                rejectedRepairs,
                scheduledRepairs,
                addedRepairs,
            ].forEach((recommendedList) => {
                if ((recommendedList === null || recommendedList === void 0 ? void 0 : recommendedList.length) > 0) {
                    const list = recommendedList.filter((rr) => rr.origin === 'FIELDOPS' && rr.status !== 'REMOVED');
                    list.forEach((it) => {
                        if ((approvedInspectionIds === null || approvedInspectionIds === void 0 ? void 0 : approvedInspectionIds.indexOf(it.id)) >= 0) {
                            it.checked = true;
                        }
                    });
                    finalList = finalList.concat(list);
                }
            });
            if (finalList.length > 0) {
                setSelectedRepairs(List(finalList));
            }
            setIsLoading(false);
        }
    });
    useEffect(() => {
        fetchApprovedInspections(referenceNumber);
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const selectedRecommendedRepairs = [];
        selectedRepairs.forEach((repair) => {
            if (repair.checked) {
                selectedRecommendedRepairs.push(repair.id);
            }
        });
        setApprovedInspectionIds(selectedRecommendedRepairs);
    }, [selectedRepairs, setApprovedInspectionIds]);
    const handleCheckboxChange = (index) => {
        return () => checkBoxChange(index);
    };
    const checkBoxChange = (index) => {
        // Update the 'checked' property using Immutable.js methods
        setSelectedRepairs((prevData) => prevData.update(index, (item) => (Object.assign(Object.assign({}, item), { checked: !(item === null || item === void 0 ? void 0 : item.checked) }))));
    };
    return {
        selectedRepairs,
        handleCheckboxChange,
        isLoading,
    };
};
export default useWarrantyDecisionsRecommendedRepairs;
