import styled from 'styled-components';
import { errorStyles, flexCenter } from 'src/styles/styled';
import { Button, Divider as MuiDivider, MenuItem } from 'src/components/mui';
export const blockMarginStyle = { marginTop: 24 };
export const removeStyles = {
    '--hide-remove': 'none',
    '--height': '24px',
};
export const Content = styled.div `
  ${flexCenter};

  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  overflow: hidden auto;
  padding: 48px 0 24px;
`;
export const Block = styled.div `
  display: inherit;
  flex-direction: inherit;
  padding-inline: 24px;
  width: inherit;
`;
export const Divider = styled(MuiDivider) `
  margin-block: 8px;
  width: inherit;
`;
export const FieldWrapper = styled.div `
  width: inherit;

  &:not(:first-of-type) {
    margin-top: 24px;
  }
`;
export const RemoveContainer = styled.div `
  margin-top: 8px;
  height: var(--height, auto);

  > button {
    display: var(--hide-remove, initial);
  }
`;
export const RemoveBtn = styled(Button) `
  height: 48px;
  padding-inline: 0;
  width: 56px;

  ${errorStyles}
`;
export const RemainingContainer = styled.div `
  display: inherit;
  justify-content: space-between;
  margin-top: 16px;
  padding-inline: 24px;
  width: inherit;
`;
export const AddPayerContainer = styled.div `
  display: inherit;
  padding-inline: 24px;
  width: inherit;
`;
export const AddPayerBtn = styled(Button) `
  height: 48px;
  padding-inline: 0;
`;
export const Item = styled(MenuItem) `
  font-weight: bold;
`;
