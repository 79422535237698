import React from 'react';
import { SimpleStyledDrawer, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import { ContentWrapper, SlidesContainer, } from 'src/components/SimpleDrawerPaged/SimpleDrawerPaged.styles';
const SimpleDrawerPaged = ({ children, closeDrawer, error = null, header, navigateBack, nextCta = null, open, screen = 'home', subheader = null, initScreen = null, size, }) => {
    const startScreen = !initScreen ? screen === 'home' : screen === initScreen;
    return (<SimpleStyledDrawer anchor="right" size={size} open={open} onClose={closeDrawer}>
      <ContentWrapper>
        <SimpleDrawerHeader extraCta={<>
              <span>{header}</span>
              <span>{nextCta}</span>
            </>} arrowBack={!startScreen} onClose={startScreen ? closeDrawer : navigateBack}/>

        {!!subheader && subheader}
        <SlidesContainer $hasTab={!!subheader}>{children}</SlidesContainer>
        {!!error && error}
      </ContentWrapper>
    </SimpleStyledDrawer>);
};
export default SimpleDrawerPaged;
