import React from 'react';
import { Collapse, Switch, Typography } from 'src/components/mui';
import { AddCircle, DeleteForever, Person as PersonIcon, } from 'src/components/mui/icons';
import { AddVehicleButton, DeleteVehicleButton, DropdownIcon, FmcContactWrapper, FmcDropdown, FmcDropdownContent, FmcDropdownSummary, FmcItemHeader, FmcItemSubHeader, FmcVehicleWrapper, RemoveButton, ShowVehiclesButton, } from 'src/AdminApp/containers/fleets/FMC/Fmc.styles';
import FleetVehicleSearchSelect from 'src/AdminApp/containers/fleets/FleetInfo/components/FleetVehicleSearchSelect';
import { ResponsiblePartyDropdownItemText } from './FleetResponsiblePartyDropdown.styles';
import useFleetResponsiblePartyDropdown from './useFleetResponsiblePartyDropdown';
const FleetResponsiblePartyDropdown = ({ responsibleParty, onRemove, onVehiclesUpdate, onAutoSubmitUpdate, onVehicleSearchOpen, }) => {
    var _a, _b;
    const { opened, vehiclesOpened, showAll, vehiclesSearchOpened, setOpened, setVehiclesOpened, setShowAll, setVehiclesSearchOpened, onAddVehicles, onRemoveVehicle, } = useFleetResponsiblePartyDropdown({
        responsibleParty,
        onVehiclesUpdate,
        onVehicleSearchOpen,
    });
    if (!responsibleParty)
        return null;
    return (<FmcDropdown>
      <FmcDropdownSummary onClick={() => setOpened((v) => !v)}>
        <FmcItemHeader>
          <span>{responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.name}</span>
          <FmcItemHeader>
            <RemoveButton onClick={() => {
            setVehiclesSearchOpened(false);
            onRemove(responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.id);
        }}>
              Remove Responsible Party
            </RemoveButton>
            <DropdownIcon $opened={opened}/>
          </FmcItemHeader>
        </FmcItemHeader>
      </FmcDropdownSummary>
      <Collapse in={opened}>
        <FmcDropdownContent>
          <FmcContactWrapper>
            <PersonIcon color="disabled" style={{ paddingRight: '4px' }}/>
            {responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.name}
          </FmcContactWrapper>
          <Typography variant="caption">Auto Submit</Typography>
          <Switch checked={responsibleParty.autoSubmit} onChange={(event) => onAutoSubmitUpdate(responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.id, event.target.checked)} color="primary"/>
          <FmcDropdownSummary onClick={() => setVehiclesOpened((v) => !v)}>
            <FmcItemSubHeader>
              <span>Vehicles</span>
              <DropdownIcon $opened={vehiclesOpened}/>
            </FmcItemSubHeader>
          </FmcDropdownSummary>
          <Collapse in={vehiclesOpened}>
            <FmcDropdownContent>
              {(_a = responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.cars) === null || _a === void 0 ? void 0 : _a.map((car, index) => {
            if (index < 4 || showAll) {
                return (<FmcVehicleWrapper key={car.vin}>
                      <ResponsiblePartyDropdownItemText>
                        {index + 1}. VIN: {car.vin}, LIC: {car.plateNumber},{' '}
                        {car.year} {car.make} {car.model} {car.trim}
                      </ResponsiblePartyDropdownItemText>
                      <DeleteVehicleButton onClick={() => onRemoveVehicle(car)}>
                        <DeleteForever />
                      </DeleteVehicleButton>
                    </FmcVehicleWrapper>);
            }
            return null;
        })}
              {((_b = responsibleParty === null || responsibleParty === void 0 ? void 0 : responsibleParty.cars) === null || _b === void 0 ? void 0 : _b.length) > 4 && (<>
                  <ShowVehiclesButton onClick={() => setShowAll(!showAll)}>
                    Show {showAll ? 'Less' : 'More'}
                  </ShowVehiclesButton>
                  <br />
                </>)}
              {vehiclesSearchOpened ? (<FleetVehicleSearchSelect responsibleParty={responsibleParty} onSubmit={(data) => {
                onAddVehicles(data);
                setVehiclesSearchOpened(false);
            }} onClose={() => {
                setVehiclesSearchOpened(false);
            }}/>) : (<AddVehicleButton onClick={() => setVehiclesSearchOpened(true)}>
                  <AddCircle />
                  Add Vehicle
                </AddVehicleButton>)}
            </FmcDropdownContent>
          </Collapse>
        </FmcDropdownContent>
      </Collapse>
    </FmcDropdown>);
};
export default FleetResponsiblePartyDropdown;
