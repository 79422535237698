import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { Box, Button } from 'src/components/mui';
export const DrawerHeader = styled.div `
  width: 100%;
  height: 56px;
  background: ${colors.secondary};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 0.75px;
  text-align: center;
  color: #2c56dd;
  padding: 19px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DrawerHeaderCta = styled.div `
  position: absolute;
  left: 1rem;
  cursor: pointer;
  z-index: 10;
  height: 24px;
`;
export const DrawerContent = styled(Box) `
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const DrawerFooter = styled(Box) `
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.lightGrey};
  padding: 24px;
  text-align: center;
`;
export const ButtonWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
  button {
    min-width: 11rem;
  }
`;
export const StyledButton = styled(Button) `
  min-width: 144px;
  height: 48px;
  font-size: 1rem;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
`;
export const ContentWrapper = styled(Box) `
  padding: 25px 16px 0;
  margin: 0;

  h2 {
    margin: 0;
  }

  &.fullSpace {
    padding: 25px;
  }
`;
export const FormRow = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.563rem;
`;
export const LunchTitle = styled.h2 `
  font-size: 24px;
  font-weight: bold;
  line-height: 1.08;
  letter-spacing: -0.5px;
  color: ${colors.default};
`;
export const LunchSubheaderWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 20px 0 32px;
`;
export const LunchSubheader = styled.h4 `
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.3px;
  margin: 0;
  color: ${colors.default};
`;
export const LunchInfo = styled.span `
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.08px;
`;
export const LinkButton = styled.button `
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
  color: #2c56dd;
  background: transparent;
  border: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    background: transparent;
  }
`;
export const SimpleModalActions = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`;
