import styled from 'styled-components';
import { Button, Grid } from 'src/components/mui';
export const Wrapper = styled.div `
  padding: 20px;
`;
export const GridTitle = styled(Grid) `
  width: 90%;
  justify-content: flex-end;
`;
export const BigButton = styled(Button) `
  height: 100%;
  width: 45px;
  margin-left: 8px;
`;
