/* eslint-disable no-plusplus */
import { flattenDeep, includes, map, sortBy } from 'lodash';
export const formatRepairs = (knownRepairs, diagnoses, customRequests, removedComponents = [], removedCustomComponents = []) => {
    const sortFields = ['index', 'created', 'name', 'message'];
    return mapRepairs(sortBy(knownRepairs, sortFields), sortBy(diagnoses, sortFields), sortBy(customRequests, sortFields), sortBy(removedComponents, sortFields), sortBy(removedCustomComponents, sortFields));
};
export const sortRepairs = (repairs) => {
    const sortFields = ['index', 'created', 'name', 'message'];
    return sortBy(repairs, sortFields);
};
function mapRepairs(knownRepairs, diagnoses, customRequests, removedComponents = [], removedCustomComponents = []) {
    let count = 1;
    const mappedRepairs = knownRepairs.map((repair) => (Object.assign(Object.assign({}, repair), { name: `${count++}. ${repair.name}` })));
    const mappedDiagnoses = diagnoses.map((diagnose) => (Object.assign(Object.assign({}, diagnose), { name: `${count++}. Inspection: ${diagnose.symptoms
            .map((s) => s.name)
            .join(', ')}` })));
    const mappedCustomRepairs = customRequests.map((customRepair) => (Object.assign(Object.assign({}, customRepair), { name: `${count++}. Custom Repair: ${customRepair.message}` })));
    const mappedRemovedComponents = removedComponents.map((repair) => (Object.assign(Object.assign({}, repair), { name: `${count++}. ${repair.name}` })));
    const mappedRemovedCustomComponents = removedCustomComponents.map((customRepair) => (Object.assign(Object.assign({}, customRepair), { name: `${count++}. Custom Repair: ${customRepair.message}` })));
    return {
        knownRepairs: mappedRepairs,
        diagnoses: mappedDiagnoses,
        customRequests: mappedCustomRepairs,
        removedComponents: mappedRemovedComponents,
        removedCustomComponents: mappedRemovedCustomComponents,
    };
}
export const isQuoteCostsHasNull = (repairs, customRepairs) => {
    const findNullCost = (k) => {
        const partsCostsContainsNull = flattenDeep(map(k.price, (p) => map(p.parts, (part) => part.cost))).includes(null);
        const fluidCostsContainsNull = flattenDeep(map(k.price, (p) => map(p.fluids, (fluid) => fluid.cost))).includes(null);
        return partsCostsContainsNull || fluidCostsContainsNull;
    };
    const knownRepairsHasNullCost = includes(map(repairs, findNullCost), true);
    const customRepairsHasNullCost = includes(map(customRepairs, findNullCost), true);
    return knownRepairsHasNullCost || customRepairsHasNullCost;
};
export const isComponentsHaveNullQuantityCostAndPrice = (repairs, customRepairs) => {
    const findNullCost = (k) => {
        const partsCostsContainsNull = flattenDeep(map(k.price, (p) => map(p.parts, (part) => [part.cost, part.price, part.quantity]))).includes(null);
        const fluidCostsContainsNull = flattenDeep(map(k.price, (p) => map(p.fluids, (fluid) => [fluid.cost, fluid.price, fluid.capacity]))).includes(null);
        return partsCostsContainsNull || fluidCostsContainsNull;
    };
    const knownRepairsHasNullCost = includes(map(repairs, findNullCost), true);
    const customRepairsHasNullCost = includes(map(customRepairs, findNullCost), true);
    return knownRepairsHasNullCost || customRepairsHasNullCost;
};
export const formatAdditionalInfo = (values) => {
    var _a, _b;
    if ((values === null || values === void 0 ? void 0 : values.additionalInfo) && Array.isArray(values === null || values === void 0 ? void 0 : values.additionalInfo)) {
        return Object.assign(Object.assign({}, values), { additionalInfo: (_b = (_a = values === null || values === void 0 ? void 0 : values.additionalInfo) === null || _a === void 0 ? void 0 : _a.filter((info) => info.name !== '' && info.value !== '')) === null || _b === void 0 ? void 0 : _b.reduce((obj, item) => (Object.assign(Object.assign({}, obj), { [item.name]: item.value })), {}) });
    }
    return values;
};
