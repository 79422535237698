import React from 'react';
import { SlotResource, TimeSlotText, TimeSlotWrapper, } from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/AvailabilityListItem/AvailabilityListItem.styles';
import { Button } from 'src/components/mui';
const AvailabilityListItem = ({ date, time, timeSlot, onClick, isThisTimeSlotSelected, hasAnyTimeSlotSelected, }) => {
    var _a, _b;
    const handleOnClick = () => {
        onClick(date, time, timeSlot);
    };
    const mechanicsNames = (_a = timeSlot.mechanics) === null || _a === void 0 ? void 0 : _a.map((m) => m.name).join(', ');
    const vansNames = (_b = timeSlot.vans) === null || _b === void 0 ? void 0 : _b.map((v) => v.name).join(', ');
    return (<TimeSlotWrapper>
      <div>
        <TimeSlotText>{time}</TimeSlotText>
        <SlotResource>
          Techs: <strong>{mechanicsNames}</strong>
        </SlotResource>
        <SlotResource>
          Vans: <strong>{vansNames}</strong>
        </SlotResource>
      </div>
      <Button variant="contained" color="primary" onClick={handleOnClick} disabled={hasAnyTimeSlotSelected && !isThisTimeSlotSelected}>
        {isThisTimeSlotSelected ? 'Change' : 'Select'}
      </Button>
    </TimeSlotWrapper>);
};
export default AvailabilityListItem;
