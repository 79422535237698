import styled from 'styled-components';
import { TableHead, TablePagination } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const PartnersAPITableHead = styled(TableHead) `
  width: 100%;
  background-color: ${colors.GRAY_FA};
  border-top: solid 1px ${colors.GRAY_DE};
  border-bottom: solid 1px ${colors.GRAY_DE};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.14);

  tr th:nth-child(1) {
    width: 20%;
  }

  tr th:nth-child(2) {
    width: 20%;
  }

  tr th:last-child {
    width: 8%;
  }
`;
export const PartnersAPIContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  .PartnersAPINoData {
    margin: 8px 0 0;
  }
`;
export const PartnersAPIPagination = styled(TablePagination) `
  font-size: 12px;

  .PartnersAPIPaginationInput {
    font-size: 12px;
    color: ${colors.black};
  }

  .PartnersAPIPaginationToolbar {
    font-size: 12px;
    color: ${colors.GRAY_76};
  }
`;
