import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Divider, AccordionActions, } from 'src/components/mui';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
import { setSalesMatchFiltersPanel } from 'src/AdminApp/modules/salesMatch/actions';
import { selectFiltersPanelState } from 'src/AdminApp/modules/salesMatch/selectors';
import { SalesMatchDateFilterType, SalesMatchShowFilterType, } from 'src/AdminApp/modules/salesMatch/salesMatch.state';
const StyledSubmitButton = styled(Button) `
  width: 85px;
`;
const DashboardFiltersPanel = ({ onSearch, }) => {
    var _a;
    const dispatch = useDispatch();
    const setFiltersPanel = setSalesMatchFiltersPanel;
    const filtersPanelState = useSelector(selectFiltersPanelState);
    const setDateOptionInvoice = () => {
        if (!setFiltersPanel)
            return;
        dispatch(setFiltersPanel({
            dateFilterType: SalesMatchDateFilterType.Invoice,
        }));
    };
    const setShowOptionMatches = () => {
        if (!setFiltersPanel)
            return;
        dispatch(setFiltersPanel({
            showFilterType: SalesMatchShowFilterType.Matches,
        }));
    };
    const setDateOptionMatched = () => {
        if (!setFiltersPanel)
            return;
        dispatch(setFiltersPanel({
            dateFilterType: SalesMatchDateFilterType.Matched,
        }));
    };
    const setShowOptionAll = () => {
        if (!setFiltersPanel)
            return;
        setFiltersPanel({
            showFilterType: SalesMatchShowFilterType.All,
        });
    };
    const switchExpanded = () => {
        if (!setFiltersPanel)
            return;
        setFiltersPanel({
            expanded: !(filtersPanelState === null || filtersPanelState === void 0 ? void 0 : filtersPanelState.expanded),
        });
    };
    const apply = () => {
        if (!onSearch)
            return;
        onSearch(filtersPanelState);
    };
    const reset = () => {
        setDateOptionInvoice();
        setShowOptionMatches();
    };
    const filtersPanel = filtersPanelState;
    const dateFilterType = Number(filtersPanel === null || filtersPanel === void 0 ? void 0 : filtersPanel.dateFilterType);
    const showFilterType = Number(filtersPanel === null || filtersPanel === void 0 ? void 0 : filtersPanel.showFilterType);
    return (<Accordion expanded={(_a = filtersPanel === null || filtersPanel === void 0 ? void 0 : filtersPanel.expanded) !== null && _a !== void 0 ? _a : false}>
      <AccordionSummary onClick={switchExpanded} expandIcon={<ExpandMoreIcon />}>
        <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Typography>Options</Typography>
        </Grid>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid container item direction="row">
            <Grid container style={{ alignItems: 'center' }} item xs={4}>
              <Typography>Date:</Typography>
            </Grid>
            <Grid item xs={8}>
              <ButtonGroup>
                <StyledSubmitButton variant={dateFilterType === SalesMatchDateFilterType.Invoice
            ? 'contained'
            : 'outlined'} onClick={setDateOptionInvoice}>
                  Invoice
                </StyledSubmitButton>
                <StyledSubmitButton variant={dateFilterType === SalesMatchDateFilterType.Matched
            ? 'contained'
            : 'outlined'} onClick={setDateOptionMatched}>
                  Matched
                </StyledSubmitButton>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container item direction="row">
            <Grid container item xs={4} style={{ alignItems: 'center' }}>
              <Typography>Show:</Typography>
            </Grid>
            <Grid item xs={8}>
              <ButtonGroup>
                <StyledSubmitButton variant={showFilterType === SalesMatchShowFilterType.Matches
            ? 'contained'
            : 'outlined'} onClick={setShowOptionMatches}>
                  Matches
                </StyledSubmitButton>
                <StyledSubmitButton variant={showFilterType === SalesMatchShowFilterType.All
            ? 'contained'
            : 'outlined'} onClick={setShowOptionAll}>
                  All
                </StyledSubmitButton>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Grid container item direction="row" style={{ justifyContent: 'space-around' }}>
          <StyledSubmitButton variant="outlined" onClick={reset}>
            Reset
          </StyledSubmitButton>
          <StyledSubmitButton variant="outlined" onClick={apply}>
            Apply
          </StyledSubmitButton>
        </Grid>
      </AccordionActions>
    </Accordion>);
};
export default DashboardFiltersPanel;
