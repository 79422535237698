import React from 'react';
import { Button } from 'src/components/mui';
import { AddCircle } from 'src/components/mui/icons';
import ExtraTimeReasonModal from 'src/AdminApp/containers/requests/StopwatchBar/ExtraTimeReasonModal';
import ExtraMinutesModal from 'src/AdminApp/containers/requests/StopwatchBar/ExtraMinutesModal';
import useStopwatchUpdates from 'src/hooks/useStopwatchUpdates';
import useNonVisitStopwatch from './useNonVisitStopwatch';
import { LevelIcon } from '../../ProgressBar/ProgressBar.styles';
import { StopwatchContainerDesktop, StopwatchBarDesktopTimeLeft, } from '../stopwatch.styles';
const NonVisitStopwatch = ({ activeAppointment, currentTask, referenceNum, }) => {
    const { stopwatch, secondsLeft, timeLeft, setTimeLeft, isAddTimeDisabled, isExtraTimeReasonModalOpened, setIsExtraTimeReasonModalOpened, isExtraMinutesModalOpened, setIsExtraMinutesModalOpened, extraTimeReason, setExtraTimeReason, extraMinutes, setExtraMinutes, extraTimeReasonOptions, onExtraTimeRequestSubmit, stopwatchInterval, } = useNonVisitStopwatch({ activeAppointment, currentTask, referenceNum });
    useStopwatchUpdates(secondsLeft, stopwatchInterval, setTimeLeft);
    if (!(stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning)) {
        return <LevelIcon>build</LevelIcon>;
    }
    return (<>
      <StopwatchContainerDesktop>
        <StopwatchBarDesktopTimeLeft>{timeLeft}</StopwatchBarDesktopTimeLeft>
        <Button style={{
            minWidth: 'auto',
            padding: 0,
        }} onClick={() => {
            if (isAddTimeDisabled ||
                stopwatch === undefined ||
                !(stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning)) {
                return;
            }
            setExtraTimeReason(undefined);
            setIsExtraTimeReasonModalOpened(true);
        }}>
          <AddCircle fontSize="large" style={{
            color: '#2c56dd',
            fontSize: '25px',
            marginRight: '5px',
        }}/>
        </Button>
      </StopwatchContainerDesktop>
      <ExtraTimeReasonModal isOpen={isExtraTimeReasonModalOpened} setIsOpen={setIsExtraTimeReasonModalOpened} extraTimeReasons={extraTimeReasonOptions} onValueChange={setExtraTimeReason} disableSubmit={extraTimeReason === undefined} onSubmit={() => setIsExtraMinutesModalOpened(true)}/>
      <ExtraMinutesModal isOpen={isExtraMinutesModalOpened} setIsOpen={setIsExtraMinutesModalOpened} onValueChange={setExtraMinutes} disableSubmit={extraMinutes <= 0} onSubmit={onExtraTimeRequestSubmit} addingTime/>
    </>);
};
export default NonVisitStopwatch;
