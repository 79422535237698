var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import useIsMobile from 'src/hooks/useIsMobile';
const Container = styled(Box) `
  ${theme.breakpoints.down('sm')} {
    padding-top: 16px;
  }
`;
export const TabPanel = (props) => {
    const isMobile = useIsMobile();
    const { children, value, index, removePadding = false } = props, other = __rest(props, ["children", "value", "index", "removePadding"]);
    return (<div role="tabpanel" hidden={value !== index} id={`nav-tabpanel-${index}`} aria-labelledby={`nav-tab-${index}`} {...other}>
      {value === index && (<Container p={isMobile || removePadding ? 0 : 3}>
          <Typography component="span">{children}</Typography>
        </Container>)}
    </div>);
};
