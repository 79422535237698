var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTerritoriesByFilters, ActionCreators, } from 'src/AdminApp/modules/territories/actions';
import { selectDashboardPagination } from 'src/AdminApp/modules/territories/selectors';
import { selectAuthorizedGeoMgmtSearch } from 'src/modules/auth/selectors';
import SearchTerritoriesFilters from './SearchTerritoriesFilters';
const SearchTerritories = () => {
    const dispatch = useDispatch();
    const pagination = useSelector(selectDashboardPagination);
    const canSearch = useSelector(selectAuthorizedGeoMgmtSearch);
    const loadData = (params) => dispatch(getTerritoriesByFilters(params));
    const clearData = () => dispatch(ActionCreators.TERRITORIES.CLEAR());
    const clearFilters = () => dispatch(ActionCreators.TERRITORIES.CLEAR_FILTERS());
    const onSearch = (filters) => {
        var _a, _b;
        loadData({
            pagination: {
                page: 0,
                size: (_a = pagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                sort: (_b = pagination.sort) !== null && _b !== void 0 ? _b : ['name,asc'],
            },
            filters,
        });
    };
    const onClear = () => __awaiter(void 0, void 0, void 0, function* () {
        yield clearData();
    });
    const renderSearch = () => {
        return canSearch ? (<SearchTerritoriesFilters onSearch={onSearch} onClear={onClear} onReset={clearFilters}/>) : (<div>
        ERROR - Not Authorized: You must have the "GEO_SEARCH" role to view this
        item
      </div>);
    };
    return <>{renderSearch()}</>;
};
export default SearchTerritories;
