var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Drawer } from 'src/components/mui';
import React from 'react';
import { SimpleDrawerContent, SimpleDrawerFooter, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import FooterActionButton from 'src/components/SimpleDrawer/FooterActionButton/FooterActionButton';
import { handleDrawerWidth, } from 'src/components/SimpleDrawer/SimpleDrawer.styles';
import SimpleLoader from 'src/components/SimpleLoader';
import { theme } from 'src/styles/theme';
import styled from 'styled-components';
export const SimpleStyledDrawer = styled(Drawer) `
  & [class*='MuiDrawer-paperAnchorRight'] {
    width: ${({ size }) => handleDrawerWidth({ size })};

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
`;
const SimpleDrawer = (_a) => {
    var { title, showClose = true, onClose, size, arrowBack, onBack, children, footerActionButtonProps, simpleMode = false, submitText, isLoading } = _a, rest = __rest(_a, ["title", "showClose", "onClose", "size", "arrowBack", "onBack", "children", "footerActionButtonProps", "simpleMode", "submitText", "isLoading"]);
    return (<SimpleStyledDrawer anchor="right" size={size} onClose={onClose} {...rest}>
      {title && onClose && showClose && (<SimpleDrawerHeader title={title} arrowBack={arrowBack} onBack={onBack} onClose={showClose
                ? (e) => {
                    onClose(e);
                }
                : undefined}/>)}
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (<SimpleLoader />) : simpleMode ? (<SimpleDrawerContent>{children}</SimpleDrawerContent>) : (children)}
      {footerActionButtonProps && submitText && (<SimpleDrawerFooter>
          <FooterActionButton {...footerActionButtonProps}>
            {submitText}
          </FooterActionButton>
        </SimpleDrawerFooter>)}
    </SimpleStyledDrawer>);
};
export default SimpleDrawer;
