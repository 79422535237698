import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { ActionCreators } from './actions';
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_SORT = ['name', 'asc'];
const DEFAULT_STATE = {
    byId: {},
    dashboard: {
        params: {
            filters: {},
        },
        content: [],
        pagination: {
            pageSize: DEFAULT_PAGE_SIZE,
            pageNumber: DEFAULT_PAGE_NUMBER,
            totalElements: 0,
            totalPages: 1,
            sort: DEFAULT_SORT,
        },
    },
};
const successSearchManufacturersReducer = (state, action) => {
    var _a, _b, _c;
    const { content, totalPages, totalElements } = fetched(action.payload);
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { content, _isFetching: false, params: {
                filters: params.filters,
            }, pagination: {
                pageSize: (_a = params.pagination) === null || _a === void 0 ? void 0 : _a.size,
                totalElements,
                totalPages,
                pageNumber: (_b = params.pagination) === null || _b === void 0 ? void 0 : _b.page,
                sort: (_c = params.pagination) === null || _c === void 0 ? void 0 : _c.sort,
            } }) });
};
const successUpdateManufacturerReducer = (state, action) => {
    const updatedManufacturer = fetched(action.payload);
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { content: state.dashboard.content.map((manufacturer) => manufacturer.id === updatedManufacturer.id
                ? Object.assign(Object.assign({}, manufacturer), updatedManufacturer) : manufacturer) }) });
};
const successSaveCatalogManufacturerReducer = (state, action) => {
    const savedCatalogManufacturer = fetched(action.payload);
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { content: state.dashboard.content.map((manufacturer) => {
                var _a;
                return manufacturer.id === savedCatalogManufacturer.manufacturerId
                    ? Object.assign(Object.assign({}, manufacturer), { catalogs: [
                            ...((_a = manufacturer.catalogs) !== null && _a !== void 0 ? _a : []),
                            savedCatalogManufacturer,
                        ] }) : manufacturer;
            }) }) });
};
const successUpdateCatalogManufacturerReducer = (state, action) => {
    const updatedCatalogManufacturer = fetched(action.payload);
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { content: state.dashboard.content.map((manufacturer) => {
                var _a;
                return manufacturer.id === updatedCatalogManufacturer.manufacturerId
                    ? Object.assign(Object.assign({}, manufacturer), { catalogs: (_a = manufacturer.catalogs) === null || _a === void 0 ? void 0 : _a.map((catalogManufacturer) => {
                            if (catalogManufacturer.id === updatedCatalogManufacturer.id) {
                                return Object.assign(Object.assign({}, catalogManufacturer), updatedCatalogManufacturer);
                            }
                            return catalogManufacturer;
                        }) }) : manufacturer;
            }) }) });
};
const successRemoveCatalogManufacturerReducer = (state, action) => {
    const removedCatalogManufacturer = action.meta.catalogManufacturer;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { content: state.dashboard.content.map((manufacturer) => {
                var _a;
                if (manufacturer.id === removedCatalogManufacturer.manufacturerId) {
                    return Object.assign(Object.assign({}, manufacturer), { catalogs: (_a = manufacturer.catalogs) === null || _a === void 0 ? void 0 : _a.filter((catalogManufacturer) => catalogManufacturer.id !== removedCatalogManufacturer.id) });
                }
                return manufacturer;
            }) }) });
};
const fetchSearchManufacturersReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const reducers = handleActions({
    [ActionCreators.UPDATE_MANUFACTURER.SUCCESS.type]: successUpdateManufacturerReducer,
    [ActionCreators.SAVE_CATALOG_MANUFACTURER.SUCCESS.type]: successSaveCatalogManufacturerReducer,
    [ActionCreators.UPDATE_CATALOG_MANUFACTURER.SUCCESS.type]: successUpdateCatalogManufacturerReducer,
    [ActionCreators.REMOVE_CATALOG_MANUFACTURER.SUCCESS.type]: successRemoveCatalogManufacturerReducer,
    [ActionCreators.SEARCH_MANUFACTURERS.SUCCESS.type]: successSearchManufacturersReducer,
    [ActionCreators.SEARCH_MANUFACTURERS.FETCH.type]: fetchSearchManufacturersReducer,
}, DEFAULT_STATE);
export default reducers;
