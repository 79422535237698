import React from 'react';
import { Alert, Snackbar } from 'src/components/mui';
import { CheckCircleRounded as CheckCircleRoundedIcon } from 'src/components/mui/icons';
const SaMpiQuoteRequestedAlert = ({ open, onClick, }) => {
    return (<Snackbar classes={{ root: 'snackBarRoot' }} open={open} onClose={onClick} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <div role="button" tabIndex={0} onKeyDown={onClick} className="sentToCustomerContainer" onClick={onClick}>
        <Alert classes={{ root: 'userSuccess', message: 'userAlertMessage' }} severity="success" icon={<CheckCircleRoundedIcon fontSize="inherit"/>}>
          SA MPI Quote Requested
        </Alert>
      </div>
    </Snackbar>);
};
export default SaMpiQuoteRequestedAlert;
