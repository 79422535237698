import { ListItem, ListItemText, Typography } from 'src/components/mui';
import React from 'react';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import { SimpleDrawerContent } from 'src/components/SimpleDrawer';
import { isDate } from 'src/utils/data-utils';
import { formatTimestamp, formatUTCDate } from 'src/utils/formatter';
import styled from 'styled-components';
import { EXCLUDED_KEYS } from './History';
const StyledHeader = styled.div `
  color: grey;
  padding-top: 10px;
`;
const HeaderText = ({ children }) => (<Typography align="center" variant="subtitle2">
    {children}
  </Typography>);
const Value = ({ value }) => {
    if (isDate(value))
        return <span>{`${value && formatUTCDate(value / 1000)}`}</span>;
    return <span>{`${value}`}</span>;
};
const HistoryItemDetails = ({ changelog }) => {
    return (<SimpleDrawerContent>
      <StyledHeader>
        <HeaderText>
          {formatTimestamp(changelog.modified.toString())}
        </HeaderText>
        <HeaderText>
          <AdminUser justName updatedUser={changelog.modifiedBy}/>
        </HeaderText>
        <HeaderText>{changelog.table}</HeaderText>
      </StyledHeader>
      {changelog === null || changelog === void 0 ? void 0 : changelog.changes.filter((change) => !EXCLUDED_KEYS.find((key) => change.field.endsWith(key))).filter((change) => change.oldValue !== change.newValue).sort((a, b) => a.field.localeCompare(b.field)).map((change) => {
            const text = (<Typography>
              <b>
                {change.field}
                {': '}
              </b>
              <Value value={`${change.oldValue}`}/>
              <span> ⇒ </span>
              <Value value={`${change.newValue}`}/>
            </Typography>);
            return (<ListItem key={change.field} divider>
              <ListItemText primary={text}/>
            </ListItem>);
        })}
    </SimpleDrawerContent>);
};
export default HistoryItemDetails;
