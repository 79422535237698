var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { AuthScriptCopy, AuthScriptHeader, AuthScriptSubheader, } from 'src/AdminApp/containers/requests/StateTracker/drawers/AuthorizationScripts/AuthorizationScript.styles';
import { getEntry } from 'src/clients/contentfulClient';
import { ActionButton, DestructiveButton } from 'src/components/buttons';
import { STATIC_URL_BASE } from 'src/styles/theme';
import { SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import Config from 'src/config';
const AuthorizationAuthScript = ({ nextStep, onCancelReschedule, }) => {
    const [headerCopy, setHeaderCopy] = useState();
    const [scriptCopy, setScriptCopy] = useState();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield getEntry(Config.REACT_APP_CONTENTFUL_ID_AUTHORIZATION_SCRIPT_TRY_THIS);
            setHeaderCopy(response.fields.header);
            setScriptCopy(response.fields.script);
        }))();
    }, []);
    return (<>
      <SimpleDrawerContent>
        <AuthScriptHeader>
          <span>
            <span>{headerCopy}</span>
            <AuthScriptSubheader>(or make it your own)</AuthScriptSubheader>
          </span>
          <img src={`${STATIC_URL_BASE}tools/speech-bubble.svg`} alt="speech bubble"/>
        </AuthScriptHeader>

        <AuthScriptCopy>
          <ReactMarkdown>{scriptCopy}</ReactMarkdown>
        </AuthScriptCopy>
      </SimpleDrawerContent>

      <SimpleDrawerFooter>
        <DestructiveButton onClick={onCancelReschedule}>
          Cancel/Reschedule
        </DestructiveButton>
        <ActionButton onClick={nextStep}>Continue</ActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default AuthorizationAuthScript;
