import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useDoubleTap } from 'use-double-tap';
import { ArrowBack, Cancel as CancelIcon } from 'src/components/mui/icons';
import { Dialog, Divider, IconButton, InputBase, List, ListItem, ListItemText, Paper, Slide, TextField, Autocomplete, } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import useIsMobile from 'src/hooks/useIsMobile';
import { FieldWrapper } from './components';
const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props}/>;
});
const StyledPaper = styled(Paper) `
  display: flex;
  border-radius: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), inset 0 -2px 0 0 #2c56dd;
`;
const EditableSearchUsers = ({ value, defaultValue = '', usersSelector, onUpdate, onChange = () => { }, requireId = false, label = '', doubleClickToOpen = true, style = null, disabled = false, }) => {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const users = useSelector(usersSelector);
    const onDoubleClick = () => setOpen(true);
    const onDoubleTap = useDoubleTap(onDoubleClick);
    const options = users.length === 0 ? [] : [{ fullName: 'None', blank: true }, ...users];
    const onSubmit = (value) => {
        if (value === null || value === void 0 ? void 0 : value.blank) {
            setInputValue('');
            onUpdate({ fullName: '' });
            return;
        }
        if (requireId) {
            onUpdate({
                serviceAdvisorUserId: value === null || value === void 0 ? void 0 : value.id,
                serviceAdvisorName: value === null || value === void 0 ? void 0 : value.fullName,
                skeduloResourceId: value === null || value === void 0 ? void 0 : value.skeduloResourceId,
                serviceAdvisorUid: value === null || value === void 0 ? void 0 : value.uid,
            });
        }
        else {
            onUpdate(value.fullName);
        }
        setInputValue('');
    };
    return (<>
      {open && !isMobile && (<Autocomplete style={{ width: '100%', minHeight: '25px' }} open={disabled ? false : open} disabled={disabled} onOpen={() => {
                setOpen(true);
            }} onClose={(_, reason) => {
                if (reason === 'toggleInput')
                    return;
                setOpen(false);
            }} isOptionEqualToValue={(option, value) => option.fullName === value.fullName} options={options} noOptionsText="Begin typing to search..." getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.fullName) !== null && _a !== void 0 ? _a : ''; }} onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }} onChange={(_, value) => {
                onSubmit(value);
            }} renderInput={(params) => (<TextField label={label} autoFocus onChange={(event) => {
                    if (onChange !== null)
                        onChange(event.target.value);
                }} {...params} disabled={disabled} InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>)}
      {open && isMobile && (<Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
          <StyledPaper component="form">
            <IconButton onClick={() => setOpen(false)}>
              <ArrowBack />
            </IconButton>
            <InputBase fullWidth placeholder="Search" value={inputValue} onChange={(e) => {
                setInputValue(e.target.value);
                if (onChange !== null)
                    onChange(e.target.value);
            }}/>
            <IconButton>
              <CancelIcon onClick={() => setInputValue('')}/>
            </IconButton>
          </StyledPaper>

          <List>
            {options.map((option) => option.fullName && (<Fragment key={option.fullName}>
                    <ListItem button onClick={() => {
                    onSubmit(option);
                    setOpen(false);
                }}>
                      <ListItemText primary={option.fullName}/>
                    </ListItem>
                    <Divider />
                  </Fragment>))}
          </List>
        </Dialog>)}
      {!open && (<FieldWrapper isEditable onClick={(e) => {
                if (!doubleClickToOpen)
                    onDoubleClick();
                else
                    onDoubleTap.onClick(e);
            }} onDoubleClick={onDoubleClick} style={Object.assign(Object.assign({}, style), { color: !value ? colors.GRAY_67 : colors.GRAY_3F })}>
          {value || defaultValue || 'None'}
        </FieldWrapper>)}
    </>);
};
export default EditableSearchUsers;
