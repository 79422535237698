import React, { useState } from 'react';
import { ButtonGroup } from 'src/components/mui';
import AddWarrantyClaimDrawer from 'src/AdminApp/containers/repairs/AddWarrantyClaimDrawer/AddWarrantyClaim';
import { ContactSupportBtn, SupportSection, } from '../contactSupport/ContactSupport.styles';
const WarrantyClaimSection = ({ address, consumerContactInfo, vehicle, logo, referenceNum, marketingSource, }) => {
    const [isAddRepairDrawerOpen, setIsAddRepairDrawerOpen] = useState(false);
    return (<SupportSection>
      <ButtonGroup variant="contained" aria-label="contact support">
        <ContactSupportBtn color="primary" variant="contained" style={{ height: '100%' }} onClick={() => setIsAddRepairDrawerOpen(true)}>
          AutoNation Mobile Service Warranty Claim
        </ContactSupportBtn>
      </ButtonGroup>

      <AddWarrantyClaimDrawer selectedUser={Object.assign(Object.assign({}, consumerContactInfo), { address: Object.assign(Object.assign({}, address), { streetLine1: address === null || address === void 0 ? void 0 : address.street }) })} vehicle={vehicle} logo={logo} open={isAddRepairDrawerOpen} onClose={() => setIsAddRepairDrawerOpen(false)} context="Request Dashboard / Add Repair Order" referenceNum={referenceNum} marketingSource={marketingSource}/>
    </SupportSection>);
};
export default WarrantyClaimSection;
