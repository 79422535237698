import TechDashboard from './TechDashboard';
import Technicians from './Technicians';
import LunchBreaks from './LunchBreaksRoot/SearchPage';
import LunchBreakDetails from './LunchBreaksRoot/LunchBreakDetails';
import PorterDashboard from './PorterDashboard/PorterDashboard';
export default {
    TechDashboard,
    Technicians,
    LunchBreaks,
    LunchBreakDetails,
    PorterDashboard
};
