import React from 'react';
import { DrawerFormRow, FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import { ChecklistSubheader, ListHeadings, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
import usePartPickupChecklist from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupChecklist/usePartPickupChecklist';
import PartPickupChecklistItem from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupChecklist/PartPickupChecklistItem';
export const PartPickupChecklist = ({ goToMissingParts, onFinish, }) => {
    const { groupByRepairRequest, partPickupOrder, countPendingOrNewPartGroup, handleSubmit, isSubmitting, } = usePartPickupChecklist({
        goToMissingParts,
        onFinish,
    });
    return (<>
      <SimpleDrawerContent>
        Select Parts that were picked up.
        <DrawerFormRow>
          <ListHeadings>
            <ChecklistSubheader>PO#</ChecklistSubheader>
            <ChecklistSubheader>Multiple Qty</ChecklistSubheader>
          </ListHeadings>
        </DrawerFormRow>
        <DrawerFormRow>
          {groupByRepairRequest((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.items) || [])
            .filter((group) => countPendingOrNewPartGroup(group.items) > 0)
            .map((group, index) => (<PartPickupChecklistItem key={index} repairRequestReferenceNum={group.repairRequestReferenceNum} items={group.items}/>))}
        </DrawerFormRow>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={handleSubmit} disabled={isSubmitting} isSubmitting={isSubmitting}>
          Submit
        </FooterActionButton>
      </SimpleDrawerFooter>
    </>);
};
export default PartPickupChecklist;
