import { IconButton, Menu, MenuItem, TableCell, TableRow, } from 'src/components/mui';
import MoreIcon from 'src/components/icons/MoreIcon';
import React, { useState } from 'react';
import SubscriptionChip from 'src/AdminApp/containers/requests/SubscriptionChip';
const VehicleItem = ({ vehicle, addRequest, onAddRequestOpen, onViewTokensOpen, }) => {
    const [vehicleMenuAnchor, setVehicleMenuAnchor] = useState();
    const [vehicleMenuId, setVehicleMenuId] = useState();
    const showVehicleMenu = (event, id) => {
        setVehicleMenuAnchor(event.currentTarget);
        setVehicleMenuId(Number(id));
    };
    const closeVehicleMenu = () => {
        setVehicleMenuAnchor(undefined);
        setVehicleMenuId(undefined);
    };
    const isVehicleMenuOpen = (id) => !!(vehicleMenuAnchor && vehicleMenuId === id);
    const vehicleId = vehicle.id;
    return (<TableRow key={vehicleId} title={`ID: ${vehicleId}`}>
      <TableCell>{vehicleId}</TableCell>
      <TableCell>
        {vehicle.year} {vehicle.make} {vehicle.model}{' '}
        {vehicle.engineDescription} {vehicle.trim}
      </TableCell>
      <TableCell>
        <SubscriptionChip vehicle={vehicle} setTokensDrawerOpen={() => {
            onViewTokensOpen(vehicle);
        }}/>
      </TableCell>
      <TableCell>{vehicle.vin}</TableCell>
      <TableCell>{vehicle.mileage}</TableCell>
      <TableCell>
        {!!addRequest && (<>
            <IconButton onClick={(event) => showVehicleMenu(event, (vehicleId !== null && vehicleId !== void 0 ? vehicleId : 0).toString())}>
              <MoreIcon />
            </IconButton>
            <Menu id={`menu-${vehicleId}`} anchorEl={vehicleMenuAnchor} open={isVehicleMenuOpen(vehicleId)} onClose={closeVehicleMenu}>
              <MenuItem onClick={() => {
                closeVehicleMenu();
                return onAddRequestOpen(vehicle);
            }}>
                Add Request
              </MenuItem>
              <MenuItem onClick={() => {
                closeVehicleMenu();
                return onViewTokensOpen(vehicle);
            }}>
                View Tokens
              </MenuItem>
            </Menu>
          </>)}
      </TableCell>
    </TableRow>);
};
export default VehicleItem;
