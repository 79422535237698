var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectStopwatch, selectWorkflowByReferenceNumber, } from 'src/AdminApp/modules/workflows/selectors';
import { selectArrivalGracePeriodMinutes } from 'src/AdminApp/modules/customConfigs/selectors';
import { selectArrivalExtraMinutesTimestamp } from 'src/AdminApp/modules/arrivalTimer/selectors';
import { selectRequestActiveAppointment, selectRequest, } from 'src/AdminApp/modules/requests/selectors';
import { selectWorkflowCurrentTask } from 'src/AdminApp/modules/workflows/selectors';
import { addExtraTimeRequest, clearStopwatch, getRepairRequestStopwatch, stopStopwatch, } from 'src/AdminApp/modules/workflows/actions';
import { toast } from 'src/components/SimpleToast';
import { reloadRequestAppointments } from 'src/AdminApp/modules/requests/actions';
import { getArrivalGracePeriodConfig } from 'src/AdminApp/modules/customConfigs/actions';
import { setIsArrivalTimerExpired, openExpiredArrivalTimerModal, closeExpiredArrivalTimerModal, } from 'src/AdminApp/modules/arrivalTimer/actions';
import useStopwatchUpdates from 'src/hooks/useStopwatchUpdates';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { getExtraTimeRequestReasons } from 'src/AdminApp/modules/extraTimeRequestReasons/actions';
import { AppointmentStatus } from 'src/AdminApp/models/enums';
import moment from 'moment';
import { getMomentValueOr8am } from 'src/utils/formatter';
import { TIME_FORMAT } from 'src/timeConstants';
import { usePageReferenceNumOutsideRouter } from 'src/hooks/usePageReferenceNum';
const useStopwatchBar = () => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const { referenceNum, selectorProps } = usePageReferenceNumOutsideRouter();
    const stopwatch = useSelector(selectStopwatch());
    const technicianWorkflow = useSelector(selectWorkflowByReferenceNumber(referenceNum));
    const arrivalGracePeriodMinutes = useSelector(selectArrivalGracePeriodMinutes);
    const arrivalExtraMinutesTimestamp = useSelector(selectArrivalExtraMinutesTimestamp);
    const activeAppointment = useSelector((state) => selectRequestActiveAppointment(state, selectorProps));
    const repairRequest = useSelector((state) => selectRequest(state, selectorProps));
    const currentTask = useSelector(selectWorkflowCurrentTask(referenceNum));
    const [secondsLeft, setSecondsLeft] = useState(0);
    const [timeLeft, setTimeLeft] = useState('00:00');
    const [stopwatchInterval, setStopwatchInterval] = useState();
    const [isAddTimeDisabled, setIsAddTimeDisabled] = useState(false);
    const [isExtraTimeReasonModalOpened, setIsExtraTimeReasonModalOpened] = useState(false);
    const [isExtraMinutesModalOpened, setIsExtraMinutesModalOpened] = useState(false);
    const [extraTimeReason, setExtraTimeReason] = useState();
    const [extraMinutes, setExtraMinutes] = useState(0);
    const [extraTimeReasonOptions, setExtraTimeReasonOptions] = useState([]);
    const [isAppointmentInvalid, setIsAppointmentInvalid] = useState(false);
    const [arrivalTimerMinutesRemaining, setArrivalTimerMinutesRemaining] = useState();
    const [arrivedCompletedTime, setArrivedCompletedTime] = useState(undefined);
    const [authorizationCompleted, setAuthorizationCompleted] = useState(false);
    const [displayArrivalGracePeriod, setDisplayArrivalGracePeriod] = useState(false);
    const stopwatchIntervalRef = useRef(stopwatchInterval);
    stopwatchIntervalRef.current = stopwatchInterval;
    useStopwatchUpdates(secondsLeft, stopwatchInterval, setTimeLeft);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!isMobile) {
                return;
            }
            const { payload } = yield dispatch(getExtraTimeRequestReasons());
            setExtraTimeReasonOptions(payload);
        }))();
        return () => {
            clearInterval(stopwatchIntervalRef.current);
            dispatch(clearStopwatch());
        };
    }, [dispatch, isMobile]);
    const requestStopwatch = useCallback(() => {
        const sleep = (ms) => {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        };
        // Retry mechanism is necessary since the endpoint may respond with
        // an invalid value due to a race condition
        const fetchAndRetry = () => __awaiter(void 0, void 0, void 0, function* () {
            let availableTime;
            for (let i = 0; i <= 2; i += 1) {
                // eslint-disable-next-line no-await-in-loop
                const { payload } = yield dispatch(getRepairRequestStopwatch(referenceNum));
                availableTime = payload.availableTime;
                if (availableTime !== 0) {
                    return;
                }
                // eslint-disable-next-line no-await-in-loop
                yield sleep(3000);
            }
        });
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield fetchAndRetry();
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, referenceNum]);
    useEffect(() => {
        if ((stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.availableTime) === undefined) {
            return;
        }
        clearInterval(stopwatchInterval);
        setSecondsLeft(stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.availableTime);
        const interval = setInterval(() => {
            setSecondsLeft((prevState) => prevState - 1);
        }, 1000);
        setStopwatchInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.availableTime]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            // Skips initial tasks for normal ROs and visits
            const isCurrentTaskValid = currentTask &&
                currentTask.state !== 'TRAVEL_STARTED' &&
                currentTask.state !== 'ARRIVED' &&
                currentTask.status === 'PENDING' &&
                currentTask.state !== 'JOB_STARTED' &&
                currentTask.status === 'PENDING';
            const isStopwatchValid = (stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning) === undefined;
            if (isCurrentTaskValid && isStopwatchValid && isMobile) {
                requestStopwatch();
            }
        }))();
    }, [
        dispatch,
        requestStopwatch,
        stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning,
        currentTask,
        currentTask === null || currentTask === void 0 ? void 0 : currentTask.state,
        currentTask === null || currentTask === void 0 ? void 0 : currentTask.status,
        isMobile,
    ]);
    useEffect(() => {
        const isAppointmentInvalid = activeAppointment &&
            (activeAppointment.status === AppointmentStatus.CANCELED ||
                activeAppointment.status === AppointmentStatus.CLOSED);
        const isJobCompleted = currentTask &&
            currentTask.state === 'PAYMENT_COLLECTED' &&
            currentTask.status === 'PENDING';
        if (isAppointmentInvalid || isJobCompleted) {
            setIsAddTimeDisabled(true);
            clearInterval(stopwatchInterval);
            dispatch(stopStopwatch());
            setIsAppointmentInvalid(true);
        }
        else {
            setIsAppointmentInvalid(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeAppointment, currentTask, dispatch]);
    useEffect(() => {
        if (technicianWorkflow) {
            const hasPendingArrivedTasks = technicianWorkflow.workflowTasks.some((task) => task.state === 'ARRIVED' && task.status === 'PENDING');
            const arrivedTasksCompletedDates = technicianWorkflow.workflowTasks
                .filter((task) => task.state === 'ARRIVED')
                .filter((task) => task.status === 'COMPLETED')
                .filter((completedDate) => completedDate !== null)
                .map((task) => moment.tz(task.completedDate, 'UTC'));
            const arrivedTaskCompletedDate = arrivedTasksCompletedDates.length === 0 ||
                (hasPendingArrivedTasks && !arrivalExtraMinutesTimestamp)
                ? undefined
                : arrivedTasksCompletedDates.reduce((prev, current) => !!prev && prev > current ? prev : current);
            setArrivedCompletedTime(arrivedTaskCompletedDate);
            const completedAuthorizedTaskExists = technicianWorkflow.workflowTasks
                .filter((task) => task.state === 'REPAIRS_AUTHORIZED')
                .some((task) => task.status === 'COMPLETED');
            setAuthorizationCompleted(completedAuthorizedTaskExists);
        }
    }, [technicianWorkflow, arrivalExtraMinutesTimestamp]);
    useEffect(() => {
        setDisplayArrivalGracePeriod(isMobile &&
            !!arrivedCompletedTime &&
            !authorizationCompleted &&
            !!arrivalGracePeriodMinutes &&
            !isAppointmentInvalid);
    }, [
        isMobile,
        arrivedCompletedTime,
        authorizationCompleted,
        arrivalGracePeriodMinutes,
        arrivalExtraMinutesTimestamp,
        isAppointmentInvalid,
    ]);
    useEffect(() => {
        if (!displayArrivalGracePeriod) {
            dispatch(closeExpiredArrivalTimerModal());
            return;
        }
        const interval = setInterval(() => {
            setArrivalTimerMinutesRemaining(getRemainingMinutes());
        }, 3000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayArrivalGracePeriod, arrivalExtraMinutesTimestamp]);
    useEffect(() => {
        if (arrivalGracePeriodMinutes === undefined) {
            dispatch(getArrivalGracePeriodConfig());
        }
    }, [arrivalGracePeriodMinutes, dispatch]);
    const getRemainingMinutes = () => {
        const newArrivedMoment = arrivalExtraMinutesTimestamp
            ? getMomentValueOr8am(arrivalExtraMinutesTimestamp, TIME_FORMAT)
            : arrivedCompletedTime;
        if (!arrivalGracePeriodMinutes || !newArrivedMoment)
            return 0;
        const extraArrivalGracePeriod = Math.max(Math.floor(arrivalGracePeriodMinutes * 0.3), 2);
        const arrivalGracePeriod = arrivalExtraMinutesTimestamp
            ? extraArrivalGracePeriod
            : arrivalGracePeriodMinutes;
        const minutesPassed = moment().diff(newArrivedMoment, 'minute');
        const minutesRemaining = (arrivalGracePeriod - minutesPassed) % 60;
        if (minutesRemaining <= 0) {
            if (!authorizationCompleted && !isAppointmentInvalid) {
                dispatch(setIsArrivalTimerExpired(true));
                dispatch(openExpiredArrivalTimerModal());
            }
            return 0;
        }
        return arrivalGracePeriod - minutesPassed;
    };
    const formatMinutesForDisplay = (minutesRemaining) => {
        if (minutesRemaining === undefined)
            return '';
        const hours = Math.floor(minutesRemaining / 60);
        const minutes = minutesRemaining % 60;
        const formattedHours = `${hours >= 10 ? hours : `0${hours}`}`;
        const formattedMinutes = `${minutes >= 10 ? minutes : `0${minutes}`}`;
        return `${formattedHours}:${formattedMinutes}`;
    };
    const onExtraTimeRequestSubmit = useCallback(() => {
        setIsExtraMinutesModalOpened(false);
        setIsExtraTimeReasonModalOpened(false);
        const extraTimeRequest = {
            secondsRequested: Number(extraMinutes) * 60,
            reason: extraTimeReason,
        };
        dispatch(addExtraTimeRequest(activeAppointment.id, extraTimeRequest)).then(({ error }) => {
            if (!error) {
                dispatch(reloadRequestAppointments(referenceNum));
                toast.success('Extra time successfully requested.');
                requestStopwatch();
            }
        });
    }, [
        extraMinutes,
        dispatch,
        extraTimeReason,
        activeAppointment === null || activeAppointment === void 0 ? void 0 : activeAppointment.id,
        referenceNum,
        requestStopwatch,
    ]);
    return {
        stopwatch,
        setIsExtraMinutesModalOpened,
        setIsExtraTimeReasonModalOpened,
        extraMinutes,
        extraTimeReason,
        timeLeft,
        isAddTimeDisabled,
        setExtraTimeReason,
        isExtraTimeReasonModalOpened,
        extraTimeReasonOptions,
        isExtraMinutesModalOpened,
        setExtraMinutes,
        onExtraTimeRequestSubmit,
        isAppointmentInvalid,
        setIsAppointmentInvalid,
        arrivalTimerMinutesRemaining,
        setArrivalTimerMinutesRemaining,
        requestStopwatch,
        displayArrivalGracePeriod,
        formatMinutesForDisplay,
        isMobile,
        referenceNum,
        activeAppointment,
        repairRequest,
        currentTask,
    };
};
export default useStopwatchBar;
