import CONFIG from 'src/config';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createDefaultActionCreators from 'src/utils/createActions';
export const ActionCreators = {
    APPLY_TOKENS: createDefaultActionCreators('APPLY_TOKENS'),
    APPLY_DISCOUNTS: createDefaultActionCreators('APPLY_DISCOUNTS'),
    REMOVE_REPAIRS_FROM_QUOTE: createDefaultActionCreators('REMOVE_REPAIRS_FROM_QUOTE'),
    REMOVE_DELIVERY_FEE_FROM_QUOTE: createDefaultActionCreators('REMOVE_DELIVERY_FEE_FROM_QUOTE'),
    SEND_AFFIRM_LINK_BY_MAIL: createDefaultActionCreators('SEND_AFFIRM_LINK_BY_MAIL'),
    GET_AFFIRM_CHECKOUT_MAGIC_LINK: createDefaultActionCreators('GET_AFFIRM_CHECKOUT_MAGIC_LINK'),
    SEND_AFFIRM_CHECKOUT_LINK_BY_MAIL: createDefaultActionCreators('SEND_AFFIRM_CHECKOUT_LINK_BY_MAIL'),
};
export const removeRepairsFromQuote = (refNum) => createAPIAction({
    types: fillApiTypes(ActionCreators.REMOVE_REPAIRS_FROM_QUOTE),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/quotes/${refNum}/remove-repairs-from-quote`,
    method: 'PATCH',
});
export const removeDeliveryFeeFromQuote = (refNum) => createAPIAction({
    types: fillApiTypes(ActionCreators.REMOVE_DELIVERY_FEE_FROM_QUOTE),
    endpoint: `${CONFIG.CLIENT_API_URL}/repair-service/quotes/${refNum}/remove-delivery-fee`,
    method: 'PATCH',
});
export const getAffirmCheckoutMagicLink = ({ referenceNum, consumerId, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_AFFIRM_CHECKOUT_MAGIC_LINK),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/consumer/login-link/affirm-checkout/${consumerId}`,
    method: 'GET',
    params: {
        referenceNum,
    },
});
export const sendAffirmCheckoutLinkByEmail = ({ consumerId, affirmUrl, referenceNum, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.SEND_AFFIRM_CHECKOUT_LINK_BY_MAIL),
    endpoint: `${CONFIG.CLIENT_API_URL}/consumer-service/admin/segment/event/affirm-checkout-send`,
    method: 'POST',
    body: {
        consumerId,
        affirmUrl,
        referenceNum,
    },
});
