/* eslint-disable class-methods-use-this */
import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { DatePicker, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, } from 'src/components/mui';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
import FilterButtons from 'src/components/dashboard/DashboardFilter/FilterButtons';
import { TextField } from 'src/AdminApp/components/form';
import useConsumerDashboardFilters, { initialState, } from 'src/AdminApp/containers/consumers/ConsumerDashboardFilters/useConsumerDashboardFilters';
import { LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
const FullWidthDatePicker = styled(DatePicker) `
  width: 100%;
`;
const ConsumerDashboardFilters = ({ onSearch, onClear }) => {
    const { createdTo, createdFrom, onDateChange, resetFilter, clearFilter, handleSubmit, } = useConsumerDashboardFilters({ onSearch, onClear });
    return (<Formik initialValues={initialState} onSubmit={handleSubmit}>
      {({ isSubmitting, resetForm }) => (<Form>
          <Grid container style={{ alignContent: 'flex-end', marginBottom: 12 }} spacing={4}>
            <Grid item xs={12} style={{ paddingTop: 0, marginTop: 32 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <FullWidthDatePicker label="Created Date From" value={createdFrom} onChange={onDateChange('createdFrom')} format={LOCALIZED_DATE_FORMAT} slotProps={{
                textField: {
                    fullWidth: true,
                    variant: 'standard',
                },
            }}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FullWidthDatePicker label="Created Date To" value={createdTo} onChange={onDateChange('createdTo')} format={LOCALIZED_DATE_FORMAT} slotProps={{
                textField: {
                    fullWidth: true,
                    variant: 'standard',
                },
            }}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField label="Zipcode" name="zip" autoFocus fullWidth variant="standard"/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField label="Consumer ID" name="id" fullWidth variant="standard"/>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <FilterButtons onClear={() => {
                clearFilter();
                resetForm();
            }} onReset={() => {
                resetFilter();
                resetForm();
            }} queryLabelText="Search Query (by name, email, phone number)" isSubmitting={isSubmitting}/>
        </Form>)}
    </Formik>);
};
export default ConsumerDashboardFilters;
