import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'src/AdminApp/modules/users/selectors';
import { ButtonGroup, Menu, MenuItem, SpeedDialAction, } from 'src/components/mui';
import { ArrowDropDown as ArrowDropDownIcon, AutoStoriesRounded as AutoStoriesRoundedIcon, ForumRounded as ForumRoundedIcon, HeadsetMicOutlined as HeadsetMicOutlinedIcon, } from 'src/components/mui/icons';
import useIsMobile from 'src/hooks/useIsMobile';
import { selectAuthorizedShowZendeskChat } from 'src/modules/auth/selectors';
import { colors } from 'src/styles/theme';
import { ContactSupportBtn, SupportSection, SupportSpeedDial, } from './ContactSupport.styles';
const ContactSupport = () => {
    const [isChatting, setIsChatting] = useState(false);
    const [speedDialOpen, setSpeedDialOpen] = useState(false);
    const [options, setOptions] = useState();
    const open = Boolean(options);
    const currentUser = useSelector(selectCurrentUser);
    const zendeskChatEnabled = useSelector(selectAuthorizedShowZendeskChat);
    const isMobile = useIsMobile();
    useEffect(() => {
        if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.email) && window.$zopim) {
            window.$zopim(() => {
                window.$zopim.livechat.setName(`${currentUser.firstName} ${currentUser.lastName}`);
                window.$zopim.livechat.setEmail(currentUser.email);
                window.$zopim.livechat.addTags('Tools');
            });
            setIsChatting(window.zE ? window.zE('webWidget:get', 'chat:isChatting') : false);
        }
    }, [currentUser]);
    const openChat = () => {
        var _a;
        (_a = window.zE) === null || _a === void 0 ? void 0 : _a.activate();
    };
    if (typeof window.zE === 'function') {
        window.zE('webWidget:on', 'chat:start', () => {
            setIsChatting(true);
        });
        window.zE('webWidget:on', 'chat:end', () => {
            setIsChatting(false);
        });
    }
    const handleSpeedDialOpen = () => {
        setSpeedDialOpen(true);
    };
    const handleSpeedDialClose = () => {
        setSpeedDialOpen(false);
    };
    return zendeskChatEnabled ? (<SupportSection>
      <ButtonGroup variant="contained" aria-label="contact support">
        <ContactSupportBtn color="primary" startIcon={<HeadsetMicOutlinedIcon />} variant="contained" onClick={openChat}>
          Contact Support
        </ContactSupportBtn>
        <ContactSupportBtn size="small" color="primary" aria-controls={open ? 'support-options' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={(e) => setOptions(e.currentTarget)}>
          <ArrowDropDownIcon />
        </ContactSupportBtn>
      </ButtonGroup>
      <Menu id="support-options" anchorEl={options} open={open} onClose={() => setOptions(undefined)}>
        <MenuItem component="a" target="_blank" href="https://repairsmithtools.zendesk.com/hc/en-us" onClick={() => setOptions(undefined)}>
          Knowledge base
        </MenuItem>
      </Menu>
      {!isChatting && isMobile && (<SupportSpeedDial ariaLabel="Add Repair Order" icon={<HeadsetMicOutlinedIcon />} FabProps={{
                style: {
                    backgroundColor: colors.BLUE,
                    color: colors.white,
                },
            }} title="Contact Support" onClose={handleSpeedDialClose} onOpen={handleSpeedDialOpen} open={speedDialOpen}>
          <SpeedDialAction key={0} tooltipTitle="Knowledge Base" tooltipOpen onClick={() => {
                window.open('https://repairsmithtools.zendesk.com/hc/en-us', '_blank');
                handleSpeedDialClose();
            }} icon={<AutoStoriesRoundedIcon />}/>
          <SpeedDialAction key={1} tooltipTitle="Live Chat Support" tooltipOpen onClick={() => {
                handleSpeedDialClose();
                openChat();
            }} icon={<ForumRoundedIcon />}/>
        </SupportSpeedDial>)}
    </SupportSection>) : null;
};
export default ContactSupport;
