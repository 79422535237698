import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import { Help } from 'src/components/mui/icons';
import { Tooltip } from 'src/components/tooltips';
import TextField from 'src/AdminApp/components/form/TextField';
const Wrapper = styled(Grid) `
  border-bottom: var(--no-border, 1px solid ${colors.lightGrey});
  margin-bottom: 24px;

  :nth-last-of-type(2) {
    margin-bottom: 14px;
  }
`;
const Text = styled(Typography) `
  font-weight: 500;
  margin-bottom: 8px;
`;
const Item = styled(Grid) `
  margin-bottom: 24px;

  :nth-last-of-type(2) {
    margin-bottom: var(--edit-margin, 12px);
  }
`;
const FutureAppointmentPage = ({ setFieldValue, }) => {
    const setOnlyPositiveValues = (e, field, maxValue) => {
        e.preventDefault();
        const { value } = e.target;
        const qtyInputVal = parseInt(value, 10);
        if (qtyInputVal >= 0 && qtyInputVal <= maxValue) {
            setFieldValue(field, qtyInputVal);
        }
    };
    return (<Wrapper container>
      <Grid item xs={12}>
        <Item container spacing={2}>
          <Grid item xs={12}>
            <Text variant="h5">
              Future Appointment
              <Tooltip title="A future appointment is a placeholder for a specific date, but with TBD time, van, and mechanic.
              The appointment time and resource allocations will be automatically allocated and confirmed a few weeks prior to the appointment date.">
                <Help />
              </Tooltip>
            </Text>
          </Grid>
        </Item>
        <Item container spacing={2}>
          <Grid item xs={11}>
            <TextField fullWidth label="Max Capacity" name="futureAppointmentMaxCapacity" inputType="number" variant="standard" onChange={(e) => setOnlyPositiveValues(e, 'futureAppointmentMaxCapacity', 1000)}/>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Maximum number of future appointments allowed on a given date in this region.
              Enter a value between 0 and 1,000.">
              <Help />
            </Tooltip>
          </Grid>
        </Item>
        <Item container spacing={2}>
          <Grid item xs={11}>
            <TextField fullWidth label="Min Days" name="futureAppointmentMinDays" inputType="number" variant="standard" onChange={(e) => setOnlyPositiveValues(e, 'futureAppointmentMinDays', 420)}/>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={`Minimum number of days before a future appointment can be scheduled within this region.
                Enter value between 0 and 420.`}>
              <Help />
            </Tooltip>
          </Grid>
        </Item>
      </Grid>
    </Wrapper>);
};
export default FutureAppointmentPage;
