import styled from 'styled-components';
import { colors, theme } from 'src/styles/theme';
import { Button, TextField, Skeleton } from 'src/components/mui';
export const AddedRepairsCount = styled.span `
  background: ${colors.BLUE};
  color: ${colors.white};
  border-radius: 50%;
  height: 17px;
  width: 17px;
  padding-top: 1px;
  display: inline-block;
  font-size: 13px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.33px;
`;
export const SelectButton = styled(Button) `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  padding: 16px 0;
  width: 100px;
  color: ${({ roaction }) => roaction === 'REMOVE' ? colors.GRAY_76 : colors.BLUE};
  border-color: ${({ roaction }) => roaction === 'REMOVE' ? colors.GRAY_76 : colors.BLUE};

  &:hover {
    border-color: ${({ roaction }) => roaction === 'REMOVE' ? colors.GRAY_76 : colors.BLUE};
  }
`;
export const ToolsSection = styled.section `
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up('md')} {
    flex-direction: row-reverse;
  }
`;
export const CapacityWrapper = styled.div `
  ${theme.breakpoints.up('md')} {
    flex-grow: 1;
  }
`;
export const SearchSection = styled.section `
  margin: 20px 25px;

  ${theme.breakpoints.up('md')} {
    flex-grow: 10;
  }
`;
export const SearchInput = styled(TextField) `
  .MuiTextField-root {
    & input {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      line-height: 16px;
      letter-spacing: -0.3px;
    }

    & > div {
      border-radius: 24px;
    }

    & svg {
      color: ${colors.GRAY_D1};
    }
  }
`;
export const LoadingSection = styled.section `
  margin-top: 0;

  ${theme.breakpoints.up('md')} {
    margin-top: 22px;
  }
`;
export const LoadingSkeleton = styled(Skeleton) `
  margin: 0 16px 16px;
  padding-top: 200px;

  ${theme.breakpoints.up('md')} {
    padding-top: 70px;
    margin-bottom: 36px;
    margin-left: 24px;
    margin-right: 24px;
  }
`;
export const FooterActions = styled.div `
  text-align: right;
`;
