import { useEffect } from 'react';
const useStopwatchUpdates = (secondsLeft, stopwatchInterval, setTimeLeft) => {
    useEffect(() => {
        if (secondsLeft <= 0) {
            clearInterval(stopwatchInterval);
            setTimeLeft('00:00');
            return;
        }
        let hours = Math.floor(secondsLeft / 3600);
        let minutes = Math.floor((secondsLeft - hours * 3600) / 60);
        if (hours < 10) {
            hours = `0${hours}`;
        }
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }
        setTimeLeft(`${hours}:${minutes}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondsLeft]);
};
export default useStopwatchUpdates;
