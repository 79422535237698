import { handleActions } from 'redux-actions';
import { fetched } from 'src/utils/data-utils';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byId: {},
    autocomplete: [],
    dashboard: {
        params: {
            filters: {},
        },
        data: [],
        pagination: {
            pageSize: 20,
            pageNumber: 0,
            totalElements: 0,
            totalPages: 1,
            sort: ['name,asc'],
        },
        _isDownloading: false,
        _isFetching: false,
    },
    newTerritory: null,
};
const territoriesSuccessReducer = (state, action) => {
    const results = fetched(action.payload);
    const { params, pageSize, pageIndex, sort } = action.meta;
    const { totalPages, totalElements } = results;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign(Object.assign({}, state.dashboard), results), { params, pagination: {
                pageSize,
                totalElements,
                totalPages,
                pageNumber: pageIndex,
                sort,
            } }) });
};
const territoriesFetchReducer = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params, _isFetching: true }) });
};
const territoriesClearReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign({}, DEFAULT_STATE.dashboard) });
};
const territoriesClearFiltersReducer = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { params: Object.assign(Object.assign({}, state.dashboard.params), { filters: DEFAULT_STATE.dashboard.params.filters }) }) });
};
const getTerritorySuccess = (state, action) => {
    const territory = fetched(action.payload);
    const { territoryId } = action.meta;
    return Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [territoryId]: Object.assign(Object.assign({}, state.byId[territoryId]), territory) }), dashboard: Object.assign(Object.assign({}, state.dashboard), { _isFetching: false }) });
};
const getTerritoryFetch = (state) => {
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { _isFetching: true }) });
};
const territoriesAutocompleteSuccessReducer = (state, action) => {
    const results = action === null || action === void 0 ? void 0 : action.payload;
    return Object.assign(Object.assign({}, state), { autocomplete: results });
};
const territoriesDeleteSuccessReducer = (state, action) => {
    var _a, _b;
    const { id } = action.meta;
    const content = (_b = (_a = state === null || state === void 0 ? void 0 : state.dashboard) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.filter((x) => x.id !== id);
    return Object.assign(Object.assign({}, state), { dashboard: Object.assign(Object.assign({}, state.dashboard), { dashboard: {
                content,
            }, pagination: Object.assign(Object.assign({}, state.dashboard.pagination), { totalElements: state.dashboard.pagination.totalElements - 1 }) }) });
};
const reducers = handleActions({
    [ActionCreators.TERRITORIES.SUCCESS.type]: territoriesSuccessReducer,
    [ActionCreators.TERRITORIES.FETCH.type]: territoriesFetchReducer,
    [ActionCreators.TERRITORIES.CLEAR.type]: territoriesClearReducer,
    [ActionCreators.TERRITORIES.CLEAR_FILTERS.type]: territoriesClearFiltersReducer,
    [ActionCreators.GET_TERRITORY.SUCCESS.type]: getTerritorySuccess,
    [ActionCreators.GET_TERRITORY.FETCH.type]: getTerritoryFetch,
    [ActionCreators.TERRITORIES_AUTOCOMPLETE.SUCCESS.type]: territoriesAutocompleteSuccessReducer,
    [ActionCreators.UPDATE_TERRITORY.SUCCESS.type]: getTerritorySuccess,
    [ActionCreators.ARCHIVE_TERRITORY.SUCCESS.type]: territoriesDeleteSuccessReducer,
}, DEFAULT_STATE);
export default reducers;
