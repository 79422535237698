import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { ActionCreators, setIsArrivalTimerExpired, setArrivalExtraMinutesTimestamp, } from './actions';
const DEFAULT_STATE = {
    isArrivalTimerExpired: false,
    expiredArrivalTimerModalOpen: false,
    arrivalExtraMinutesTimestamp: undefined,
};
const openSlidePanel = (state) => {
    return Object.assign(Object.assign({}, state), { expiredArrivalTimerModalOpen: true });
};
const closeSlidePanel = (state) => (Object.assign(Object.assign({}, state), { expiredArrivalTimerModalOpen: false }));
const reducers = handleActions({
    [ActionCreators.EXPIRED_ARRIVAL_TIMER_MODAL.OPEN.type]: openSlidePanel,
    [ActionCreators.EXPIRED_ARRIVAL_TIMER_MODAL.CLOSE.type]: closeSlidePanel,
    [setIsArrivalTimerExpired.type]: createSimpleHandler('isArrivalTimerExpired'),
    [setArrivalExtraMinutesTimestamp.type]: createSimpleHandler('arrivalExtraMinutesTimestamp'),
}, DEFAULT_STATE);
export default reducers;
