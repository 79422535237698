import React from 'react';
export const Indicator = ({ value, color, text }) => {
    const wideMode = value.length > 1;
    return (<span style={{
            display: 'inline-block',
            backgroundColor: color,
            color: text,
            width: wideMode ? 'auto' : '20px',
            height: '20px',
            borderRadius: wideMode ? '12px' : '50%',
            fontSize: '14px',
            textAlign: 'center',
            padding: wideMode ? '3px 10px' : '0',
        }}>
      {value}
    </span>);
};
export default Indicator;
