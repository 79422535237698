import React from 'react';
import { FormControl, FormHelperText, MenuItem, Select, } from 'src/components/mui';
const positions = new Map([
    ['UNKNOWN', 1],
    ['Left', 2],
    ['Left Lower', 9],
    ['Right', 12],
    ['Right Lower', 19],
    ['Outer', 6],
    ['Front', 22],
    ['Front Left', 26],
    ['Front Lower', 27],
    ['Rear', 30],
    ['Rear Left', 34],
    ['Rear Left Upper', 35],
    ['Inner', 38],
    ['Left Outer', 42],
    ['Upper', 46],
    ['Lower', 50],
    ['Front Left Inner', 79],
    ['Front Left Outer', 80],
    ['Front Right Inner', 81],
    ['Front Right Outer', 82],
    ['Rear Left Inner', 83],
    ['Rear Right Inner', 85],
    ['Rear Right Outer', 86],
    ['Front Left Upper', 87],
    ['Front Left Lower', 88],
    ['Front Right Upper', 89],
    ['Front Right Lower', 90],
    ['Rear Left Lower', 92],
    ['Rear Right Upper', 94],
    ['Front Right', 104],
    ['Rear Right', 106],
    ['Rear Lower', 91],
    ['Front Upper', 103],
    ['Rear Upper', 105],
]);
const PositionSelect = ({ positionName = '', error, setPosition, }) => {
    const handleChange = (event) => {
        const newPositionName = event.target.value;
        const newPositionId = positions.get(newPositionName);
        if (newPositionId && newPositionName) {
            setPosition({ id: newPositionId, name: newPositionName });
        }
        else {
            setPosition(null);
        }
    };
    return (<FormControl fullWidth error={!!error}>
      <FormHelperText style={{ fontWeight: 'bold' }}>{error}</FormHelperText>
      <Select variant="standard" value={positionName} onChange={handleChange}>
        <MenuItem key="" value="">
          {' '}
          &nbsp;{' '}
        </MenuItem>
        {Array.from(positions.entries()).map(([name, id]) => (<MenuItem key={name} value={name}>{`${id} (${name})`}</MenuItem>))}
      </Select>
    </FormControl>);
};
export default PositionSelect;
