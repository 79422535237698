var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { selectMeasurements, selectMeasurementsSlidePanelOpen, } from 'src/PricingApp/modules/repairs/selectors';
import { useEffect, useState } from 'react';
import { closeMeasurementRequirementsSlidePanel, createMeasurement, getMeasurement, getMeasurements, openMeasurementRequirementsSlidePanel, updateMeasurement, } from 'src/PricingApp/modules/repairs/actions';
import { toast } from 'src/components/SimpleToast';
const useMeasurementDashboard = () => {
    const dispatch = useDispatch();
    const measurements = useSelector(selectMeasurements);
    const measurementsOpen = useSelector(selectMeasurementsSlidePanelOpen);
    const [measurementModalOpen, setMeasurementModalOpen] = useState(false);
    useEffect(() => {
        dispatch(getMeasurements());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleMeasurementsPanel = (isOpen) => __awaiter(void 0, void 0, void 0, function* () {
        if (isOpen) {
            yield dispatch(openMeasurementRequirementsSlidePanel());
        }
        else {
            yield dispatch(closeMeasurementRequirementsSlidePanel());
        }
    });
    const onUpdateMeasurement = (measurement) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(updateMeasurement(measurement));
        if (response && !response.error) {
            toast.success('Measurements successfully updated.');
            handleMeasurementsPanel(false);
            dispatch(getMeasurements());
        }
    });
    const onMeasurementCreate = () => {
        setMeasurementModalOpen(true);
    };
    const onMeasurementSelected = (measurement) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(getMeasurement(measurement.id));
        handleMeasurementsPanel(true);
    });
    const onCreateMeasurementSubmit = (measurement) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(createMeasurement(measurement));
        if (response && !response.error) {
            toast.success('Measurement successfully created.');
            setMeasurementModalOpen(false);
            dispatch(getMeasurements());
        }
    });
    return {
        onMeasurementCreate,
        measurementModalOpen,
        setMeasurementModalOpen,
        onCreateMeasurementSubmit,
        measurementsOpen,
        handleMeasurementsPanel,
        onUpdateMeasurement,
        measurements,
        onMeasurementSelected,
    };
};
export default useMeasurementDashboard;
