import React from 'react';
import { colors, STATIC_URL_BASE } from 'src/styles/theme';
const NoteLogo = ({ width, marginRight }) => (<div style={{
        background: colors.black,
        padding: 10,
        textAlign: 'center',
        display: 'inline-block',
    }}>
    <img src={`${STATIC_URL_BASE}img/svg/ms-logo.svg`} alt="AutoNation Mobile Service logo" width={width} style={{
        marginRight,
    }}/>
  </div>);
export default NoteLogo;
