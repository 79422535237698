import React from 'react';
import { Table, TableBody, TableCell, TableRow, TableSortLabel, } from 'src/components/mui';
import useIsMobile from 'src/hooks/useIsMobile';
import AddPartnerAPIDrawer from '../../AddPartnersAPIDrawer';
import { PartnersAPIContainer, PartnersAPIPagination, PartnersAPITableHead, } from './Content.styles';
import Loader from './Loader';
import PartnerAPIRow from './PartnerAPIRow/PartnerAPIRow';
import useContent from './useContent';
const columnNames = [
    {
        id: 'partnerId',
        label: 'Partner',
        sortable: true,
        field: 'partnerId',
        isMobile: true,
    },
    { id: 'marketingSourceId', label: 'Marketing Source', isMobile: true },
    { id: 'description', label: 'Description', isMobile: true },
    { id: 'accessLevel', label: 'Access Level', isMobile: true },
    {
        id: 'publicKey',
        label: 'Public Key',
        isMobile: false,
    },
    {
        id: 'validFrom',
        label: 'Valid From',
        sortable: true,
        field: 'validFrom',
        isMobile: false,
    },
    {
        id: 'validTo',
        label: 'Valid To',
        sortable: true,
        field: 'validTo',
        isMobile: false,
    },
    { id: 'actions', label: 'Actions', sortable: false, isMobile: true },
];
const Content = () => {
    const { editPartner, setEditPartner, loading, apiKeys, marketingSources, partners, pagination, sortName, sortDirection, handlePageSizeChange, handlePageChange, handleSortChange, handleOnClosePartnerAPIDrawer, } = useContent();
    const isMobile = useIsMobile();
    return !apiKeys ? (<PartnersAPIContainer>
      <div className="PartnersAPINoData">There are no APIs.</div>
    </PartnersAPIContainer>) : (<PartnersAPIContainer>
      <Table style={{ tableLayout: 'fixed' }}>
        <PartnersAPITableHead>
          <TableRow>
            {columnNames.map((column) => (!isMobile || column.isMobile) && (<TableCell key={column.id} align="left" sortDirection={sortName === column.field
                ? sortDirection
                : undefined}>
                    {column.sortable ? (<TableSortLabel active={sortName === column.field} direction={sortName === column.field
                    ? sortDirection
                    : 'asc'} onClick={() => handleSortChange(column)}>
                        {column.label}
                      </TableSortLabel>) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{column.label}</>)}
                  </TableCell>))}
          </TableRow>
        </PartnersAPITableHead>
        <TableBody>
          {!loading &&
            apiKeys.map((apiKey) => (<PartnerAPIRow key={apiKey.id} item={apiKey} setEditPartner={setEditPartner} partners={partners} marketingSources={marketingSources}/>))}
        </TableBody>
      </Table>
      <AddPartnerAPIDrawer open={editPartner.open} onClose={handleOnClosePartnerAPIDrawer} apiKey={editPartner.apiKey}/>
      {!(apiKeys === null || apiKeys === void 0 ? void 0 : apiKeys.length) && !loading && (<div className="PartnersAPINoData">There are no APIs.</div>)}
      {!!(apiKeys === null || apiKeys === void 0 ? void 0 : apiKeys.length) && !loading && (<PartnersAPIPagination classes={{
                root: 'page-root',
                toolbar: 'PartnerAPIPaginationToolbar',
            }} count={pagination.totalElements} page={pagination.page} onPageChange={handlePageChange} rowsPerPage={pagination.size} onRowsPerPageChange={handlePageSizeChange}/>)}
      {loading && <Loader />}
    </PartnersAPIContainer>);
};
export default Content;
