import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { map } from 'lodash';
import { Grid } from 'src/components/mui';
import { TextField } from 'src/AdminApp/components/form';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { DeliveryVanStatusSelectForm, IntegratedStatusSelectForm, ShopSalesStatusSelectForm, ShopStatusSelectForm, } from 'src/AdminApp/components/form/Statuses';
import FilterButtons from 'src/components/dashboard/DashboardFilter/FilterButtons';
import DashboardDatePicker from 'src/components/dashboard/DashboardFilter/DatePicker';
import FilterBlock from 'src/components/dashboard/DashboardFilter/FilterBlock';
import { deliveryChannels } from './ShopAddForm/utils';
const initialState = {
    isExpanded: true,
    createdTo: null,
    createdFrom: null,
    radius: '',
    integrated: '',
    deliveryVanStatus: '',
    salesStatus: '',
    status: '',
    zip: '',
    shopName: '',
    city: '',
};
const DashboardFilters = ({ onSearch, onClear }) => {
    const [state, setState] = useState(initialState);
    const onDateChange = (field) => (date) => {
        setState(Object.assign(Object.assign({}, state), { [field]: date.local() }));
    };
    const resetFilter = () => {
        setState(Object.assign({}, initialState));
    };
    const clearFilter = () => {
        resetFilter();
        onClear();
    };
    const handleSubmit = (data, { setSubmitting }) => {
        const { createdTo, createdFrom } = state;
        const values = Object.assign(Object.assign(Object.assign({}, state), data), { createdTo,
            createdFrom });
        onSearch(values);
        setSubmitting(false);
    };
    return (<Formik initialValues={Object.assign(Object.assign({}, initialState), { deliveryChannel: 'DELIVERY' })} onSubmit={handleSubmit}>
      {({ isSubmitting, resetForm, setFieldValue, values }) => (<Form>
          <FilterBlock>
            <Grid item xs={12} md={3}>
              <DashboardDatePicker label="Created Date From" value={state.createdFrom} onChange={onDateChange('createdFrom')}/>
            </Grid>
            <Grid item xs={12} md={3}>
              <DashboardDatePicker label="Created Date To" value={state.createdTo} onChange={onDateChange('createdTo')}/>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Zipcode" name="zip" autoFocus fullWidth variant="standard"/>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Radius (miles)" name="radius" fullWidth variant="standard"/>
            </Grid>
            <Grid item xs={12} md={3}>
              <Field name="city">
                {({ field }) => (<TextField autoFocus label="City" fullWidth variant="standard" {...field}/>)}
              </Field>
            </Grid>
            <Grid item xs={12} md={3}>
              <ShopStatusSelectForm name="status" label="Shop Status"/>
            </Grid>
            <Grid item xs={12} md={3}>
              <ShopSalesStatusSelectForm name="salesStatus" label="Sales Status"/>
            </Grid>
            <Grid item xs={12} md={3}>
              <IntegratedStatusSelectForm name="integrated" label="Integrated"/>
            </Grid>
            <Grid item xs={12} md={3}>
              <Field name="shopName">
                {({ field }) => (<TextField autoFocus label="Shop Name" fullWidth variant="standard" {...field}/>)}
              </Field>
            </Grid>
            <Grid item xs={12} md={3}>
              <DeliveryVanStatusSelectForm name="deliveryVanStatus" label="Delivery Van Status"/>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                {map(deliveryChannels, ({ name, id }) => {
                const checked = values.deliveryChannel === id;
                return (<Grid item xs={6} md={2} key={id}>
                      <CheckboxField name={id} label={name} checked={checked} onChange={(e) => {
                        setFieldValue('deliveryChannel', e.target.checked ? id : null);
                    }}/>
                    </Grid>);
            })}
              </Grid>
            </Grid>
          </FilterBlock>

          <FilterButtons hideQuery onReset={() => {
                resetFilter();
                resetForm();
                setFieldValue('deliveryChannel', null);
            }} onClear={() => {
                clearFilter();
                resetForm();
                setFieldValue('deliveryChannel', null);
            }} isSubmitting={isSubmitting}/>
        </Form>)}
    </Formik>);
};
export default DashboardFilters;
