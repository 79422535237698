import React from 'react';
import { FormControl, Input, ListItemText, MenuItem, Select, } from 'src/components/mui';
import { Checkbox } from 'src/components/SimpleCheckbox';
const catalogIds = [
    { id: 1, catalog: 'Motor' },
    { id: 2, catalog: 'Epicor' },
    { id: 3, catalog: 'RepairSmith' },
];
const ExcludeCatalogsSelect = ({ currentIds = [], setIds, }) => {
    const handleChange = (event) => {
        var _a;
        const newIds = ((_a = event.target) === null || _a === void 0 ? void 0 : _a.value).sort();
        setIds(newIds);
    };
    return (<FormControl fullWidth>
      <Select multiple value={currentIds} onChange={handleChange} input={<Input />} renderValue={(selected) => selected.join(', ')}>
        {catalogIds.map(({ catalog, id }) => (<MenuItem key={id} value={id}>
            <Checkbox checked={currentIds.indexOf(id) > -1}/>
            <ListItemText primary={`${id} (${catalog})`}/>
          </MenuItem>))}
      </Select>
    </FormControl>);
};
export default ExcludeCatalogsSelect;
