import React from 'react';
import { AccordionSummary, AccordionDetails } from 'src/components/mui';
import { ExpandMore } from 'src/components/mui/icons';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { StyledAccordion } from './RegionSelector.styles';
const sortMetros = (metros) => metros.sort((a, b) => { var _a; return (_a = a.name) === null || _a === void 0 ? void 0 : _a.localeCompare(b.name); });
const sortRegions = (regions) => regions.sort((a, b) => { var _a; return (_a = a.name) === null || _a === void 0 ? void 0 : _a.localeCompare(b.name); });
const TerritoryItem = ({ territory: t, getRegions, selectedRegions, checkAllRegionsFromMetroSelected, checkAllMetrosFromTerritorySelected, }) => {
    return (<StyledAccordion key={t.id}>
      <AccordionSummary className="accordion-summary" expandIcon={<ExpandMore />}>
        <LabelledCheckbox aria-label="Territory" title={`id=${t.id}`} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()} label={t.name} checkboxProps={{
            checked: checkAllMetrosFromTerritorySelected(t),
            onChange: (e) => getRegions(e.target.checked, t.id),
        }}/>
      </AccordionSummary>
      <AccordionDetails className="accordion-detail sub">
        {sortMetros(t.metros).map((m) => (<StyledAccordion key={m.id}>
            <AccordionSummary className="accordion-summary" expandIcon={<ExpandMore />}>
              <LabelledCheckbox aria-label="Metro" title={`id=${m.id}`} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()} label={m.name} style={{
                marginLeft: '16px',
            }} checkboxProps={{
                checked: checkAllRegionsFromMetroSelected(m),
                onChange: (e) => getRegions(e.target.checked, t.id, m.id),
            }}/>
            </AccordionSummary>
            <AccordionDetails className="accordion-detail sub regions">
              {sortRegions(m.regions).map((r) => (<LabelledCheckbox key={r.id} aria-label="Region" title={`id=${r.id}`} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()} label={r.name} checkboxProps={{
                    checked: selectedRegions.includes(r.id),
                    onChange: (e) => getRegions(e.target.checked, t.id, undefined, r.id),
                    style: {
                        marginLeft: '55px',
                    },
                }}/>))}
            </AccordionDetails>
          </StyledAccordion>))}
      </AccordionDetails>
    </StyledAccordion>);
};
export default TerritoryItem;
