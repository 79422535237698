var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getDeliveryRegions } from 'src/PopsApp/modules/parts/actions';
import { selectPopsRegions } from 'src/PopsApp/modules/parts/selectors';
import Grid from 'src/PopsApp/components/Grid/Grid';
import GridItem from 'src/PopsApp/components/Grid/GridItem';
import FilterInput from 'src/PopsApp/components/FilterInput';
import { resetRecentVendors } from 'src/PopsApp/modules/vendors/actions';
import useFilter from 'src/PopsApp/pages/Van/components/useFilter';
import DesktopFilter from 'src/PopsApp/components/VansFilter/DesktopFilter';
import { SortPopover } from 'src/PopsApp/pages/Region/components/SortWidget';
import FilterDialog from 'src/PopsApp/components/VansFilter/FilterDialog';
import { vansIdSort } from 'src/PopsApp/modules/hooks/useFilter';
import { selectPopsManualOrderViewer } from 'src/modules/auth/selectors';
import { RegionContainer } from '../Region/Region.styles';
import LoadPlaceholder from './components/LoadPlaceholder';
const Van = () => {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const history = useHistory();
    const canViewManualOrder = useSelector(selectPopsManualOrderViewer);
    useEffect(() => {
        if (!canViewManualOrder) {
            history.push('/pops/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { region } = useParams();
    const regions = useSelector(selectPopsRegions);
    const regionVans = ((_b = (_a = regions === null || regions === void 0 ? void 0 : regions.find((r) => r.regionId === region)) === null || _a === void 0 ? void 0 : _a.vans) === null || _b === void 0 ? void 0 : _b.length) || 4;
    const vans = (_d = (_c = regions === null || regions === void 0 ? void 0 : regions.find((r) => r.regionId === region)) === null || _c === void 0 ? void 0 : _c.vans) !== null && _d !== void 0 ? _d : [];
    const [loading, setLoading] = useState(false);
    const { searchStr, setSearchStr, filtered, updateFilter, batchFilter, metros, territories, components, query, filterData, sortDirection, handleSortClose, isSortOpen, sortAnchorEl, handleSortClick, isFilterOpen, setFilterOpen, } = useFilter({
        values: vans,
        searchField: 'name',
        defaultSort: undefined,
    });
    const getVans = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        if (!regions.length) {
            yield dispatch(getDeliveryRegions(query));
        }
        setLoading(false);
    });
    useEffect(() => {
        getVans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData, region]);
    const onSearch = (e) => {
        setSearchStr(e.target.value);
    };
    const itemClick = (id) => () => {
        dispatch(resetRecentVendors());
        history.push(`/pops/regions/${region}/vans/${id}/jobs`);
    };
    const changeFilterOpened = (state) => () => {
        setFilterOpen(state);
    };
    return (<RegionContainer>
      <SortPopover pid={vansIdSort} isOpen={isSortOpen} anchor={sortAnchorEl} onClose={handleSortClose} value={sortDirection} onItemClick={(v) => {
            updateFilter('sort', `region,${v}`);
            handleSortClose();
        }}/>
      <FilterDialog isOpen={isFilterOpen} title="Filter" onClose={changeFilterOpened(false)} metros={metros} territories={territories} query={query} renderLocationFilter={false} onApplyChanges={(f) => {
            batchFilter(f);
        }}/>
      <h1 className="regionsTitle">Select Van</h1>

      <button type="button" className="regionsSort" onClick={handleSortClick}>
        Sort
      </button>
      <button type="button" className="regionsSort" onClick={changeFilterOpened(true)}>
        Filter
      </button>

      <DesktopFilter renderLocationField={false} query={query} territories={territories} metros={metros} components={components} onResetClick={() => {
            batchFilter({});
        }} onUpdate={updateFilter}/>
      {!loading ? (<FilterInput searchStr={searchStr} onSearch={onSearch} placeholder="Search van"/>) : null}

      {loading ? (<LoadPlaceholder itemsCount={regionVans}/>) : (<Grid noItemsText="There are no vans for the applied filter" hasItems={!filtered.length}>
          {filtered.map((van) => {
                var _a, _b;
                return (<GridItem key={van.id} name={`Van ${van.name}`} count={(_a = van.totalJobs) !== null && _a !== void 0 ? _a : 0} subtitle="Job" onClick={itemClick(van.ispId)} specialOrder={(_b = van.specialOrder) !== null && _b !== void 0 ? _b : false}/>);
            })}
        </Grid>)}
    </RegionContainer>);
};
export default Van;
