import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMarketingSources } from 'src/AdminApp/modules/lookups/selectors';
import { setSelectedMarketingSource } from 'src/PartnersAPIApp/modules/partnersApi/actions';
import { selectAddPartnerAPIKeyMarketingSource } from 'src/PartnersAPIApp/modules/partnersApi/selectors';
const useMarketingSourceAutocomplete = (marketingSourceId) => {
    const dispatch = useDispatch();
    const marketingSourcesList = useSelector(selectMarketingSources);
    const selectedMarketingSource = useSelector(selectAddPartnerAPIKeyMarketingSource);
    useEffect(() => {
        if (marketingSourceId) {
            const marketingSourceIdSelected = marketingSourcesList.filter((source) => source.id === marketingSourceId);
            dispatch(setSelectedMarketingSource(marketingSourceIdSelected[0]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSelected = (e, value) => {
        dispatch(setSelectedMarketingSource(value));
    };
    return {
        marketingSourcesList,
        selectedMarketingSource,
        handleSelected,
    };
};
export default useMarketingSourceAutocomplete;
