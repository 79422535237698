import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { CopyToClipboardButton } from 'src/components/buttons';
import { selectSelectedJobs } from 'src/PopsApp/modules/parts/selectors';
import { getPartDistributors } from 'src/PopsApp/modules/vendors/actions';
import { selectPartDistributors } from 'src/PopsApp/modules/vendors/selectors';
import { MONTH_AND_DAY_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
import { componentNeeded } from '../utils/componentAvailabilityUtils';
import { ListSectionWrapper, ListTitle } from '../Vendors.styles';
import { AppointmentList } from './SelectedAppointment.styles';
const SelectedAppointments = () => {
    const dispatch = useDispatch();
    const selectedJobs = useSelector(selectSelectedJobs);
    const partDistributors = useSelector(selectPartDistributors);
    const otherPartDistributor = partDistributors === null || partDistributors === void 0 ? void 0 : partDistributors.find((dist) => dist.name.toLowerCase() === 'other');
    useEffect(() => {
        if (!partDistributors)
            dispatch(getPartDistributors());
    }, [dispatch, partDistributors]);
    return (<ListSectionWrapper>
      <ListTitle>Selected Appointments</ListTitle>

      <AppointmentList>
        {selectedJobs === null || selectedJobs === void 0 ? void 0 : selectedJobs.map((job, index) => (<li className="Appointment" key={`job.repairRequestId ${index}`}>
            <div className="Appointment__main">
              <div className="Appointment__main__time">
                {moment(job.appointmentStartTime).format(`${MONTH_AND_DAY_FORMAT} - ${TIME_12H_FORMAT}`)}
              </div>
              <div style={{ flex: 1 }}/>
            </div>
            <div className="Appointment__address">
              {job.appointmentAddress}{' '}
              <CopyToClipboardButton value={job.appointmentAddress}/>
            </div>
            <div className="Appointment__repairInfo">
              <span className="Appointment__repairInfo__car">
                {(job === null || job === void 0 ? void 0 : job.car) &&
                `${job.car.carYear} ${job.car.make} ${job.car.model} ${job.car.engineDescription}`}
              </span>
              {(job === null || job === void 0 ? void 0 : job.services) &&
                [
                    ...job.services.knownRepairs,
                    ...job.services.customRequests,
                ].map((r) => {
                    const items = [...r.price[0].parts, ...r.price[0].fluids];
                    return items
                        .filter((i) => componentNeeded(i))
                        .map((item) => (<span className="Appointment__repairInfo__repairs">
                        · {item.name}{' '}
                        {item.componentPartDistributor && (<span>
                            (
                            {`${(otherPartDistributor === null || otherPartDistributor === void 0 ? void 0 : otherPartDistributor.id) ===
                                item.componentPartDistributor.partDistributorId
                                ? 'Other, '
                                : ''}${item.componentPartDistributor.partDistributorName}`}
                            )
                          </span>)}
                      </span>));
                })}
            </div>
          </li>))}
      </AppointmentList>
    </ListSectionWrapper>);
};
export default SelectedAppointments;
