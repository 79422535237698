var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFitment, removeFitment, setOrderParts, } from 'src/PopsApp/modules/parts/actions';
import { usMoneyFormatWithCents } from 'src/modules/api/utils/format';
import { toast } from 'src/components/SimpleToast';
import { selectOrderParts, selectDistributor, } from 'src/PopsApp/modules/parts/selectors';
const usePart = ({ part, onDelete, onReload, onClick }) => {
    var _a, _b, _c, _d, _e;
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(true);
    const [fitmentIdToDelete, setFitmentIdToDelete] = useState();
    const fitments = (_a = part === null || part === void 0 ? void 0 : part.fitments) !== null && _a !== void 0 ? _a : [];
    const isFitment = !!fitments.length;
    const status = ((_b = part.meta) === null || _b === void 0 ? void 0 : _b.isAdded) ? 'ADDED' : (_c = part.status) !== null && _c !== void 0 ? _c : 'PENDING';
    const partNumber = (_d = part.partNumber) !== null && _d !== void 0 ? _d : 'N/A';
    const quantity = (_e = part.quantity) !== null && _e !== void 0 ? _e : 0;
    const price = usMoneyFormatWithCents(part.price);
    const orderParts = useSelector(selectOrderParts);
    const distributor = useSelector(selectDistributor);
    const disabled = [
        'ORDERED',
        'ORDER_IN_PROGRESS',
        'PARTIALLY_ORDERED',
    ].includes(part.status);
    const handleRemoveComponent = (e) => __awaiter(void 0, void 0, void 0, function* () {
        var _f, _g;
        e.stopPropagation();
        if (disabled)
            return false;
        if (part.meta && part.id) {
            yield onDelete(part.meta.repair.id, part.id.toString(), ((_f = part.meta) === null || _f === void 0 ? void 0 : _f.isFluid) ? 'fluid' : 'part', (_g = part.meta) === null || _g === void 0 ? void 0 : _g.isCustom, !!part.componentId);
        }
    });
    const handleRemoveFitment = (id) => __awaiter(void 0, void 0, void 0, function* () {
        if (disabled)
            return false;
        yield dispatch(removeFitment(id));
        const orders = orderParts.filter((order) => {
            return order.part.id !== id;
        });
        dispatch(setOrderParts(orders));
        onReload();
    });
    const handleCaptionClick = () => {
        if (disabled) {
            return;
        }
        onClick();
    };
    const handleFitmentEditClick = (id) => {
        if (disabled) {
            return;
        }
        onClick(id);
    };
    const handleFitmentClick = (e) => __awaiter(void 0, void 0, void 0, function* () {
        var _h;
        e.stopPropagation();
        const response = yield dispatch(addFitment({
            cost: (_h = part.cost) !== null && _h !== void 0 ? _h : 0,
            partId: part.id,
            partDistributor: distributor,
        }));
        if (!response.error) {
            toast.success('Fitment successfully added');
        }
        setExpanded(true);
        onReload();
    });
    return {
        expanded,
        setExpanded,
        fitmentIdToDelete,
        setFitmentIdToDelete,
        handleRemoveComponent,
        handleRemoveFitment,
        handleCaptionClick,
        handleFitmentClick,
        handleFitmentEditClick,
        fitments,
        isFitment,
        disabled,
        status,
        partNumber,
        quantity,
        price,
    };
};
export default usePart;
