import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CarFields from 'src/AdminApp/containers/vehicle/CarModule/CarFields';
import { MobileEditFieldDrawer } from 'src/AdminApp/containers/vehicle/CarModule/MobileEditFieldDrawer';
import { normalizeVehicle } from 'src/AdminApp/containers/vehicle/CarModule/utils';
import { selectMakes } from 'src/AdminApp/modules/autoselection/selectors';
const CarFieldEdit = (props) => {
    const [newVehicleData, setNewVehicleData] = useState();
    const makes = useSelector(selectMakes);
    return (<MobileEditFieldDrawer {...props} label="Car" onSave={() => props.onSave(normalizeVehicle(newVehicleData, makes))}>
      <CarFields vehicle={props.values} onUpdate={setNewVehicleData}/>
    </MobileEditFieldDrawer>);
};
export default CarFieldEdit;
