import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AddConsumerRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddConsumerRODrawer/AddConsumerRODrawer';
import ListItem from 'src/AdminApp/containers/repairs/RepairsDashboard/RepairListItem';
import { ActionCreators, loadRequests, } from 'src/AdminApp/modules/requests/actions';
import { selectRequestArray, selectRequestFetching, selectRequestPagination, selectRequestParams, } from 'src/AdminApp/modules/requests/selectors';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import { Button, useMediaQuery } from 'src/components/mui';
import { AddCircle } from 'src/components/mui/icons';
import { toast } from 'src/components/SimpleToast';
import { selectAuthorizedRepairSearch, selectAuthorizedRepairView, } from 'src/modules/auth/selectors';
import { theme } from 'src/styles/theme';
import { isReferenceNum } from 'src/utils/nav-utils';
import styled from 'styled-components';
import Filters from './Filters';
const AddRowWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
const REQUEST_COLUMNS = [
    { title: 'Reference #', key: 'repairRequest.referenceNum' },
    { title: 'Vehicle', key: 'repairRequest.car.make' },
    { title: 'Created Date/Time', key: 'repairOrder.created', sortable: true },
    { title: 'Parts Status', key: 'repairRequest.partsStatus' },
    {
        title: 'Appt Date/Time',
        key: 'appointment.scheduleStartLocal',
        sortable: true,
    },
    { title: 'Appt Status', key: 'appointment.status' },
    { title: 'Order Status', key: 'repairOrder.status' },
    { title: 'Payment Status', key: 'invoice.balanceOwed' },
    { title: 'Consumer Info', key: 'repairRequest.consumerContactInfo.lastName' },
    { title: 'Delivery Channel', key: 'appointment.deliveryChannel' },
    { title: 'Shop Info', key: 'ispContactInfo.shopName' },
];
const RepairsDashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isAddRepairDrawerOpen, setIsAddRepairDrawerOpen] = useState(false);
    const data = useSelector(selectRequestArray);
    const params = useSelector(selectRequestParams);
    const pagination = useSelector(selectRequestPagination);
    const canView = useSelector(selectAuthorizedRepairView);
    const canSearch = useSelector(selectAuthorizedRepairSearch);
    const isFetching = useSelector(selectRequestFetching);
    useEffect(() => {
        dispatch(loadRequests(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLoadData = ({ page, size, sort, }) => {
        dispatch(loadRequests({
            pagination: { page, size, sort },
            filters: params.filters,
        }));
    };
    const onSearch = (filters) => {
        var _a, _b;
        // REDIRECT TO REPAIR DETAIL PAGE IF REFERENCE NUMBER IS ENTERED
        if ((filters === null || filters === void 0 ? void 0 : filters.query) && isReferenceNum(filters.query)) {
            history.push(`/admin/repairs/${filters.query}`);
            return;
        }
        if (!filters.createdFrom) {
            return toast.error('Created Date From is required.');
        }
        if (!validateDateRange(filters)) {
            return toast.error('Maximum date range is 365 days.');
        }
        return dispatch(loadRequests({
            pagination: {
                page: 0,
                size: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20,
                sort: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _b !== void 0 ? _b : ['repairOrder.created,desc'],
            },
            filters,
        }));
    };
    const onClear = () => {
        dispatch(ActionCreators.REQUEST_DASHBOARD.CLEAR());
    };
    // eslint-disable-next-line class-methods-use-this
    const validateDateRange = ({ createdFrom, createdTo }) => {
        return (moment(createdFrom).diff(moment(createdTo || new Date()), 'days') >= -365);
    };
    const renderSearch = () => {
        return canSearch ? (<Filters filters={params.filters} onSearch={onSearch} onClear={onClear} onReset={() => {
                dispatch(ActionCreators.REQUEST_DASHBOARD.CLEAR_FILTERS());
            }}/>) : (<div>
        ERROR - Not Authorized: You must have the "Repair_Search" role to view
        this item
      </div>);
    };
    const renderResults = () => {
        var _a;
        return canView ? (<SimpleTable data={data !== null && data !== void 0 ? data : []} pagination={pagination} sort={(_a = params === null || params === void 0 ? void 0 : params.pagination) === null || _a === void 0 ? void 0 : _a.sort} columns={REQUEST_COLUMNS} isFetching={isFetching} renderItem={(item, index) => (<ListItem {...item} key={`${index}-${item.referenceNum}`}/>)} onLoadData={onLoadData}/>) : (<div>
        ERROR - Not Authorized: You must have the "Repair_Viewer" role to view
        this item
      </div>);
    };
    const renderQuickRo = () => (<>
      <AddRowWrapper>
        {useMediaQuery(theme.breakpoints.down('sm')) ? (<SimpleRowButton onClick={() => setIsAddRepairDrawerOpen(true)} color="primary" style={{ width: 'fit-content' }}>
            <AddCircle /> Add Repair Order
          </SimpleRowButton>) : (<Button onClick={() => setIsAddRepairDrawerOpen(true)} color="primary" variant="contained" style={{ marginBottom: '12px' }}>
            Add Repair Order
          </Button>)}
      </AddRowWrapper>

      <AddConsumerRODrawer open={isAddRepairDrawerOpen} onClose={() => setIsAddRepairDrawerOpen(false)} context="Request Dashboard / Add Repair Order"/>
    </>);
    return (<>
      {renderQuickRo()}
      {renderSearch()}
      {renderResults()}
    </>);
};
export default RepairsDashboard;
