import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { Grid } from 'src/components/mui';
export const TotalSection = styled.section `
  margin-bottom: 24px;
  padding-bottom: 24px;
  ${({ invoicesCount }) => invoicesCount < 2 ? null : `border-bottom: 1px solid ${colors.border}`};
`;
export const PaymentsRow = styled(Grid) `
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
`;
export const ColoredMoneyLabel = styled.div `
  color: ${({ status }) => colors[status]};
  display: flex;
  align-items: center;
`;
