var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button } from 'src/components/mui';
import { CloseRounded as CloseRoundedIcon } from 'src/components/mui/icons';
import { useDispatch } from 'react-redux';
import usePartnerSubmit from 'src/PartnersApp/pages/PartnerManagement/FunnelDetailsDialog/usePartnerSubmit';
import { publishFunnel } from 'src/PartnersApp/modules/partners/actions';
import moment from 'moment';
import { loadMarketingSources } from 'src/AdminApp/modules/lookups/actions';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { DATE_TIME_12H_FORMAT } from 'src/timeConstants';
import SaveChangesDialog from './SaveChangesDialog';
import FunnelPageCard from './FunnelPageCard';
import PartnerDetails from './PartnerDetails';
import useFunnelSubmit from './useFunnelSubmit';
import { FunnelDetailsDialogContent, PartnerDetailsContainer, FunnelDetails, FunnelDialog, FunnelSectionTitle, FunnelAction, FunnelActions, FunnelPagesTitle, FunnelPagesContainer, PagesList, FunnelPages, PublishAlert, } from './FunnelDetailsDialog.styles';
const FunnelDetailsDialog = ({ open, onClose, partner, allPages, }) => {
    var _a;
    const dispatch = useDispatch();
    const [removedPages, setRemovedPages] = useState({});
    const [selectedPageIds, setSelectedPageIds] = useState();
    const [yupPages, setYupPages] = useState();
    const [hasDirtyPagesList, setHasDirtyPagesList] = useState(false);
    const [showFormDirtyMessage, setShowFormDirtyMessage] = useState(false);
    const [funnel, setFunnel] = useState();
    const { submitComplete, updateFunnel, setSubmitComplete: setFunnelSubmitComplete, } = useFunnelSubmit(funnel);
    const { handleCreatePartner, submitComplete: partnerSubmitComplete } = usePartnerSubmit(partner);
    useEffect(() => {
        dispatch(loadMarketingSources());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!partner.funnels || !partner.funnels.length) {
            setFunnel(undefined);
        }
        else {
            setFunnel(partner === null || partner === void 0 ? void 0 : partner.funnels[0]);
        }
    }, [partner]);
    useEffect(() => {
        if (submitComplete && partnerSubmitComplete) {
            onClose(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitComplete, partnerSubmitComplete]);
    const pageSort = (a, b) => a.ordinal < b.ordinal ? -1 : a.ordinal > b.ordinal ? 1 : 0; // eslint-disable-line
    const handlePublish = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(funnel === null || funnel === void 0 ? void 0 : funnel.id))
            return;
        yield dispatch(publishFunnel({ funnelId: funnel.id }));
        onClose(true);
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: yup.object({
            name: yup.string().required('Partner is required'),
            urlParameterIdentifier: yup
                .string()
                .required('Custom Parameter Name is required'),
            pages: yupPages,
            startAtPlate: yup.boolean().notRequired(),
        }),
        onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
            handleCreatePartner(values);
            if (funnel === null || funnel === void 0 ? void 0 : funnel.id) {
                updateFunnel(values, removedPages);
            }
            else {
                setFunnelSubmitComplete(true);
            }
        }),
    });
    useEffect(() => {
        if (funnel && allPages) {
            const notFoundPages = {};
            const selectedPages = allPages.reduce((prev, curr) => {
                var _a;
                const foundPage = (_a = funnel === null || funnel === void 0 ? void 0 : funnel.pages) === null || _a === void 0 ? void 0 : _a.find((p) => p.id === curr.id);
                if (foundPage) {
                    return [...prev, Object.assign({}, foundPage)];
                }
                notFoundPages[curr.id] = Object.assign({}, curr);
                return [...prev];
            }, []);
            setRemovedPages(Object.assign(Object.assign({}, removedPages), notFoundPages));
            formik.setValues({
                name: partner.name,
                urlParameterIdentifier: funnel.urlParameterIdentifier,
                marketingSourceId: funnel.marketingSourceId,
                pages: selectedPages.sort(pageSort),
                startAtPlate: funnel.startAtPlate,
            });
            const funnelPageYup = yup.lazy((value) => {
                if (value.hasCta) {
                    return yup.object().shape({
                        header: yup.string().required('Header required'),
                        callToAction: yup.string().required('CTA required'),
                    });
                }
                return yup.object().shape({
                    header: yup.string().required('Header required'),
                });
            });
            // SETUP VALIDATION
            setYupPages(yup.array().of(funnelPageYup));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [funnel, allPages]);
    useEffect(() => {
        // TRACK THE SELECTED PAGES FOR THE PAGES CHECKLIST
        if (formik.values.pages) {
            setSelectedPageIds(new Set(formik.values.pages.map((p) => p.id)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.pages]);
    useEffect(() => {
        // RESET ON CLOSE
        if (!open) {
            setHasDirtyPagesList(false);
            formik.resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    const handlePageCheckClick = (e, page) => {
        let newSelected;
        if (e.target.checked) {
            // ADD CURRENT PAGE
            // CHECK IF IT'S BEEN REMOVED
            const foundRemoved = removedPages[page.id];
            newSelected = [
                ...formik.values.pages.filter((p) => p.id !== page.id),
                foundRemoved ? Object.assign({}, foundRemoved) : Object.assign({}, page),
            ];
        }
        else {
            // REMOVE CURRENT PAGE
            setRemovedPages(Object.assign(Object.assign({}, removedPages), { [page.id]: formik.values.pages.find((p) => p.id === page.id) }));
            newSelected = [
                ...formik.values.pages.filter((p) => p.id !== page.id),
            ];
        }
        // THIS IS REFRESHING ALL SELECTED PAGES, INCLUDING ONES TOUCHED ALREADY
        if (newSelected) {
            setHasDirtyPagesList(true);
            newSelected.sort(pageSort);
            formik.setValues(Object.assign(Object.assign({}, formik.values), { pages: newSelected }));
        }
    };
    const handleClose = () => {
        if ((Object.keys(formik.touched).length > 0 || hasDirtyPagesList) &&
            (funnel === null || funnel === void 0 ? void 0 : funnel.status) !== 'ARCHIVED') {
            setShowFormDirtyMessage(true);
            return;
        }
        onClose();
    };
    return !formik.values.pages || !funnel ? null : (<>
      <FunnelDialog open={open} onClose={handleClose} maxWidth="lg" scroll="body" fullWidth>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <FunnelDetailsDialogContent>
            <PartnerDetailsContainer>
              <FunnelSectionTitle>Partner Details</FunnelSectionTitle>

              <PartnerDetails formik={formik} initialUrlIdentifier={(_a = funnel === null || funnel === void 0 ? void 0 : funnel.urlParameterIdentifier) !== null && _a !== void 0 ? _a : ''} disabled={formik.isSubmitting || funnel.status === 'ARCHIVED'}/>

              <FunnelPagesContainer>
                <FunnelPagesTitle>Funnel Pages</FunnelPagesTitle>

                {formik.values.pages && (<PagesList>
                    {allPages.map((page) => {
                var _a;
                return (<li key={page.id}>
                          <LabelledCheckbox label={page.name} checkboxProps={{
                        name: page.id,
                        disabled: page.required || funnel.status === 'ARCHIVED',
                        checked: (_a = selectedPageIds === null || selectedPageIds === void 0 ? void 0 : selectedPageIds.has(page.id)) !== null && _a !== void 0 ? _a : false,
                        onChange: (e) => {
                            handlePageCheckClick(e, page);
                        },
                    }}/>
                        </li>);
            })}
                  </PagesList>)}
              </FunnelPagesContainer>
            </PartnerDetailsContainer>

            <FunnelDetails>
              <FunnelSectionTitle>
                <span>Custom Funnel</span>
                <Button variant="text" onClick={handleClose} className="closeFunnelEditBtn">
                  <CloseRoundedIcon />
                </Button>
              </FunnelSectionTitle>

              {funnel.approved && (<PublishAlert severity="success">
                  Last Published on{' '}
                  {moment(funnel.approved).format(DATE_TIME_12H_FORMAT)}
                </PublishAlert>)}

              {formik.values.pages && (<FunnelPages>
                  {formik.values.pages.map((page, idx) => {
                return (<FunnelPageCard page={page} idx={idx} formik={formik} key={page.id} disabled={formik.isSubmitting || funnel.status === 'ARCHIVED'}/>);
            })}
                </FunnelPages>)}
            </FunnelDetails>
          </FunnelDetailsDialogContent>

          <FunnelActions>
            {(partner === null || partner === void 0 ? void 0 : partner.allowPublish) && (<FunnelAction variant="contained" color="primary" onClick={handlePublish} disabled={formik.isSubmitting}>
                Publish
              </FunnelAction>)}
            <FunnelAction type="submit" variant="outlined" color="primary" disabled={formik.isSubmitting || funnel.status === 'ARCHIVED'}>
              Save
            </FunnelAction>
          </FunnelActions>
        </form>
      </FunnelDialog>

      <SaveChangesDialog open={showFormDirtyMessage} onSave={() => __awaiter(void 0, void 0, void 0, function* () {
            yield formik.submitForm();
            setShowFormDirtyMessage(false);
            onClose();
        })} onClose={() => {
            setShowFormDirtyMessage(false);
            onClose();
        }} isSubmitting={formik.isSubmitting}/>
    </>);
};
export default FunnelDetailsDialog;
