import React from 'react';
import { useSelector } from 'react-redux';
import { selectConsumerById } from 'src/AdminApp/modules/consumers/selectors';
import ZendeskUserLink from './ZendeskUserLink';
const ZendeskInfo = ({ consumerId }) => {
    var _a, _b;
    const consumer = useSelector(selectConsumerById(consumerId));
    return consumer ? (<ZendeskUserLink consumerId={(_a = consumer.id) !== null && _a !== void 0 ? _a : 0} zendeskUserId={(_b = consumer.zendeskUserId) !== null && _b !== void 0 ? _b : 0}/>) : null;
};
export default ZendeskInfo;
