import styled from 'styled-components';
import { IconButton, Button } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const EllipsisButton = styled(IconButton) `
  padding: 0;
`;
export const FloatingMenu = styled.div `
  position: absolute;
  background: white;
  top: -50%;
  left: 95%;
  display: ${(props) => (props.open ? 'block' : 'none')};
  border-radius: 2px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
  z-index: 9999;
`;
export const StyledActionButton = styled(Button) `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: ${colors.GRAY_3F};
  padding: 10px 76.5px 9px 17.5px;
`;
