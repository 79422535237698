import React from 'react';
import { InputLabel, Select } from 'src/components/mui';
const TimezoneFilter = ({ options, value, onChange, }) => (<>
    <InputLabel margin="dense" shrink>
      Timezone
    </InputLabel>
    <Select native name="timezoneFilter" onChange={onChange} value={value} style={{ width: 250 }} variant="standard">
      <option value="">&lt;All&gt;</option>
      {options.map((timezone) => (<option key={timezone}>{timezone}</option>))}
    </Select>
  </>);
export default TimezoneFilter;
