import React from 'react';
import { ModeSwitcherContainer, ModeOption } from './ModeSwitcher.styles';
const ModeSwitcher = ({ mode, onChange, disabled = false, }) => {
    return (<ModeSwitcherContainer mode={mode}>
      <ModeOption disabled={disabled} active={mode === 0} onClick={() => {
            if (disabled)
                return;
            onChange(0);
        }}>
        Fleet
      </ModeOption>
      <ModeOption disabled={disabled} active={mode === 1} onClick={() => {
            if (disabled)
                return;
            onChange(1);
        }}>
        Consumer
      </ModeOption>
    </ModeSwitcherContainer>);
};
export default ModeSwitcher;
