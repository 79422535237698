import { handleActions } from 'redux-actions';
import { union } from 'lodash';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    byRequestRefNum: {},
    allSubletIds: [],
};
const successReducer = (state, action) => {
    const sublet = action.payload;
    const { referenceNum } = action.meta;
    const allSubletIds = union(state.allSubletIds, sublet.id);
    const byRequestRefNum = Object.assign(Object.assign({}, state.byRequestRefNum), { [referenceNum]: sublet });
    return Object.assign(Object.assign({}, state), { byRequestRefNum,
        allSubletIds });
};
const reducers = handleActions({
    [ActionCreators.ADD_SUBLET.SUCCESS.type]: successReducer,
    [ActionCreators.GET_SUBLET.SUCCESS.type]: successReducer,
    [ActionCreators.UPDATE_SUBLET.SUCCESS.type]: successReducer,
    [ActionCreators.ADD_REPAIRS_TO_SUBLET.SUCCESS.type]: successReducer,
    [ActionCreators.REMOVE_REPAIRS_FROM_SUBLET.SUCCESS.type]: successReducer,
}, DEFAULT_STATE);
export default reducers;
