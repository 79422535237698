import API_URLS from 'src/AdminApp/modules/api/urls';
import createAPIAction from 'src/modules/api';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createDefaultActionCreators from 'src/utils/createActions';
export const ActionCreators = {
    GET_INVOICE_REQUESTS: createDefaultActionCreators('GET_INVOICE_REQUESTS', [
        'CLEAR',
    ]),
    ACKNOWLEDGE_INVOICE_REQUEST: createDefaultActionCreators('ACKNOWLEDGE_INVOICE_REQUEST'),
    CANCEL_INVOICE_REQUEST: createDefaultActionCreators('CANCEL_INVOICE_REQUEST'),
    SEARCH_B2B_RESPONSIBLE_PARTIES: createDefaultActionCreators('SEARCH_B2B_RESPONSIBLE_PARTIES'),
};
const makeRequestParamsAndBody = ({ pageNumber = 0, pageSize = 50, referenceNumber, responsiblePartyId, status, sort, type }) => {
    const params = {
        responsiblePartyId,
    };
    if (pageNumber) {
        params.page = pageNumber;
    }
    if (pageSize) {
        params.size = pageSize;
    }
    if (sort) {
        params.sort = sort[0];
    }
    if (referenceNumber) {
        params.referenceNumber = referenceNumber;
    }
    if (status === null || status === void 0 ? void 0 : status.length) {
        params.status = status.join(',');
    }
    if (type === null || type === void 0 ? void 0 : type.length) {
        params.type = type.join(',');
    }
    return { params };
};
export const getInvoiceRequests = ({ pageNumber = 0, pageSize = 50, referenceNumber, responsiblePartyId, status, sort, type }) => {
    const { params } = makeRequestParamsAndBody({
        pageNumber,
        pageSize,
        referenceNumber,
        responsiblePartyId,
        status,
        sort,
        type
    });
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_INVOICE_REQUESTS),
        endpoint: API_URLS.ADMIN.INVOICE_REQUESTS(params),
        method: 'GET',
    });
};
export const acknowledgeInvoiceRequest = (invoiceRequestId) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.ACKNOWLEDGE_INVOICE_REQUEST),
        endpoint: API_URLS.ADMIN.ACKNOWLEDGE_INVOICE_REQUEST(invoiceRequestId),
        method: 'PATCH',
    });
};
export const cancelInvoiceRequest = (invoiceRequestId) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.CANCEL_INVOICE_REQUEST),
        endpoint: API_URLS.ADMIN.CANCEL_INVOICE_REQUEST(invoiceRequestId),
        method: 'PATCH',
    });
};
export const searchB2BResponsibleParties = (search) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.SEARCH_B2B_RESPONSIBLE_PARTIES),
        endpoint: API_URLS.ADMIN.SEARCH_B2B_RESPONSIBLE_PARTIES(search),
        method: 'GET',
    });
};
export const clearInvoiceRequests = ActionCreators.GET_INVOICE_REQUESTS.CLEAR;
