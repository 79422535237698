var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid } from 'src/components/mui';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InitialPrice from 'src/AdminApp/components/repairs/statement/InitialPrice';
import { getConsumerById } from 'src/AdminApp/modules/consumers/actions';
import { selectConsumerById } from 'src/AdminApp/modules/consumers/selectors';
import { getSkeduloUrl } from 'src/AdminApp/utils/url-utils';
import ZendeskUserLink from 'src/AdminApp/zendesk/ZendeskUserLink';
import { SimpleModal } from 'src/components/layout';
import { CopyToClipboardButton } from 'src/components/buttons';
import useIsMobile from 'src/hooks/useIsMobile';
import { usMoneyFormatWithCents } from 'src/modules/api/utils/format';
import CarMakeSquareImage from 'src/PopsApp/components/CarMakeSquareImage';
import { AccordionContent, AccordionHeader, PartsAccordion, } from 'src/PopsApp/components/PartsAccordion/PartsAccordion';
import PartStatus from 'src/PopsApp/components/PartStatus';
import { toast } from 'src/components/SimpleToast';
import { colors } from 'src/styles/theme';
import AddComponentDrawer from '../AddComponentDrawer/AddComponentDrawer';
import OrderNotesDrawer from '../OrderNotesDrawer/OrderNotesDrawer';
import PartDesktop from '../Part/PartDesktop';
import PartMobile from '../Part/PartMobile';
import FluidMobile from '../Fluid/FluidMobile';
import FluidDesktop from '../Fluid/FluidDesktop';
import { CarAdditional, CarTitleRoot, Container, JobFooter, NewComponentIndicator, QuotedPod, Repair, TotalPod, } from './CarInfo.styles';
const getCarRepairs = (job) => {
    const repairs = [];
    job.services.knownRepairs.forEach((r) => {
        repairs.push({
            id: r.id,
            name: r.name,
            parts: r.price[0].parts,
            fluids: r.price[0].fluids,
            price: r.price[0],
            isCustom: false,
            quotedPriceMode: r.price[0].quotedPriceMode,
        });
    });
    job.services.customRequests.forEach((r) => {
        repairs.push({
            id: r.id,
            name: r.message,
            parts: r.price[0].parts,
            fluids: r.price[0].fluids,
            price: r.price[0],
            isCustom: true,
            quotedPriceMode: r.price[0].quotedPriceMode,
        });
    });
    return repairs;
};
const CarInfo = ({ parts, selectedPart, job, jobNumber, jobsCount, onSelectPart, setNewComponentType, deleteComponent, newComponentType, onChangeInput, onSaveNext, onReload, }) => {
    var _a, _b, _c, _d, _e, _f;
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(true);
    const [partsStatus, setPartsStatus] = useState('PENDING');
    const [removingComponent, setRemovingComponent] = useState();
    const [dynamicCreationConfirm, setDynamicCreationConfirm] = useState(false);
    const [isComponentDataCorrect, setIsComponentDataCorrect] = useState(false);
    const [savingData, setSavingData] = useState();
    const { car, referenceNum } = job;
    const repairs = getCarRepairs(job);
    const dispatch = useDispatch();
    const consumer = useSelector(selectConsumerById((_a = job === null || job === void 0 ? void 0 : job.consumerId) !== null && _a !== void 0 ? _a : 0));
    const Part = isMobile ? PartMobile : PartDesktop;
    const Fluid = isMobile ? FluidMobile : FluidDesktop;
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            yield dispatch(getConsumerById((_a = job.consumerId) !== null && _a !== void 0 ? _a : 0));
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [job]);
    useEffect(() => {
        if (job.partsStatus !== 'PENDING') {
            setPartsStatus(job.partsStatus);
            if (job.partsStatus === 'ORDERED') {
                setOpen(false);
            }
            return;
        }
        const allAdded = parts.length &&
            parts
                .filter((p) => p.meta.job.repairRequestId === job.repairRequestId)
                .every((p) => p.meta.isAdded);
        if (allAdded) {
            setPartsStatus('ADDED');
            setOpen(false);
            return;
        }
        setPartsStatus(job.partsStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parts]);
    const handleClick = (state) => () => setOpen(state);
    const handleSelectPart = (p) => (fitmentId) => {
        onSelectPart(p, fitmentId);
    };
    const handleRemoveComponentPrompt = ({ repairId, componentId, componentType, isCustom, removeDynamicRule, }) => {
        setRemovingComponent({
            repairId,
            componentId,
            componentType,
            isCustom,
            removeDynamicRule,
        });
    };
    const onRemoveComponent = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!removingComponent)
            return;
        const { repairId, componentId, componentType, isCustom, removeDynamicRule, } = removingComponent;
        const response = yield deleteComponent(repairId, componentId, componentType, isCustom, removeDynamicRule);
        if (response && !response.error) {
            toast.success('Component removed successfully');
        }
        setRemovingComponent(undefined);
    });
    const handleSaveNext = (isCustom, values, newComponentType) => {
        if (values.addAsDynamicComponent) {
            setDynamicCreationConfirm(true);
            setSavingData({
                isCustom,
                values,
                newComponentType,
            });
            return;
        }
        onSaveNext(isCustom, values);
    };
    const getComponentName = () => {
        var _a, _b;
        return (_b = (_a = parts.find((p) => p.id.toString() === (removingComponent === null || removingComponent === void 0 ? void 0 : removingComponent.componentId))) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '';
    };
    const entityName = ((_b = savingData === null || savingData === void 0 ? void 0 : savingData.capacityUnit) === null || _b === void 0 ? void 0 : _b.length) ? 'fluid' : 'part';
    const hasEngine = (car === null || car === void 0 ? void 0 : car.engineId) && (car === null || car === void 0 ? void 0 : car.engineId) !== 'i_dont_know';
    const fixedComponents = parts
        .filter((p) => { var _a; return ((_a = p === null || p === void 0 ? void 0 : p.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'FIXED'; })
        .map((p) => p.componentId);
    return (<Container>
      <PartsAccordion className="accordion">
        <AccordionHeader isOpen={open} onClick={handleClick(!open)}>
          <CarTitleRoot>
            <CarMakeSquareImage make={car.make} className="carIcon"/>
            <div className="infoWrap">
              <h5 className="carTitle">
                {car.carYear} {car.make} {car.model}
                <span className="carTitleDesktop">
                  {' '}
                  • {car.engineDescription} {car === null || car === void 0 ? void 0 : car.carTrim} • Job {jobNumber} of{' '}
                  {jobsCount}
                </span>
              </h5>
              <p className="carSubtitle">
                {car.engineDescription} {car === null || car === void 0 ? void 0 : car.carTrim} • Job {jobNumber} of{' '}
                {jobsCount}
              </p>
              <PartStatus status={partsStatus} className="partStatus"/>
            </div>
          </CarTitleRoot>
        </AccordionHeader>

        <AccordionContent isOpen={open}>
          <CarAdditional>
            <div className="infoItem">
              <p className="infoTitle">LIC</p>
              <p className="infoText">{(_c = car.plateNumber) !== null && _c !== void 0 ? _c : 'N/A'}</p>
            </div>
            <div className="infoItem">
              <p className="infoTitle">VIN</p>
              <p className="infoText">{(_d = car.vin) !== null && _d !== void 0 ? _d : 'N/A'}</p>
            </div>
            <div className="additionalDesktop">
              <div className="infoItem">
                <p className="infoTitle">Ref #</p>
                <a className="infoText skedulo" href={`/admin/repairs/${job.referenceNum}`} rel="noopener noreferrer" target="_blank">
                  {job.referenceNum}
                </a>
                <CopyToClipboardButton value={job.referenceNum} style={{ minWidth: '0px' }}/>
              </div>
              {job.zendeskId && consumer && (<div className="infoItem">
                  <p className="infoTitle">Zendesk ID</p>
                  <ZendeskUserLink consumerId={(_e = consumer.id) !== null && _e !== void 0 ? _e : 0} zendeskUserId={(_f = consumer.zendeskUserId) !== null && _f !== void 0 ? _f : 0} linkStyles={{
                color: colors.BLUE,
                fontWeight: 700,
                textDecoration: 'none',
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: '12px',
                marginRight: '8px',
            }}/>
                </div>)}
              <div className="infoItem">
                <p className="infoTitle">Skedulo </p>
                {(job === null || job === void 0 ? void 0 : job.skeduloJobId) ? (<a className="infoText skedulo" href={getSkeduloUrl(`/job/${job.skeduloJobId}/details`)} rel="noopener noreferrer" target="_blank">
                    JOB
                  </a>) : (<p className="infoText">N/A</p>)}
              </div>
            </div>
          </CarAdditional>

          {isMobile ? (<h4 className="carInfoTitle">Repairs</h4>) : (<Grid container className="carInfoHeader">
              <Grid item className="carInfoTitle--part">
                <h4 className="carInfoTitle ">Repairs</h4>
              </Grid>
              <Grid item className="carInfoTitle--quoted">
                <h4 className="carInfoTitle">Quoted</h4>
              </Grid>
              <TotalPod item className="carInfoTitle--price">
                <h4 className="carInfoTitle">Price</h4>
              </TotalPod>
            </Grid>)}
          <Repair>
            {repairs.map((r) => (<Fragment key={r.id}>
                {isMobile ? (<div className="repairTitle">
                    <h4>
                      {r.name}
                      {(newComponentType === null || newComponentType === void 0 ? void 0 : newComponentType.repairId) === r.id && (<NewComponentIndicator>
                          {' '}
                          (Adding new component)
                        </NewComponentIndicator>)}
                    </h4>
                    <div className="partDetails">
                      Total: {usMoneyFormatWithCents(r.price.totalCost)} |
                      Quoted: <InitialPrice price={r.price}/>
                    </div>
                  </div>) : (<Grid container style={{
                    alignItems: 'baseline',
                }}>
                    <QuotedPod item className="carInfoRepairName">
                      <h4 className="repairTitle">
                        {r.name}
                        {(newComponentType === null || newComponentType === void 0 ? void 0 : newComponentType.repairId) === r.id && (<NewComponentIndicator>
                            {' '}
                            (Adding new component)
                          </NewComponentIndicator>)}
                      </h4>
                      <div className="partQuoted">
                        <InitialPrice price={r.price}/>
                      </div>
                    </QuotedPod>
                    <TotalPod item className="partCostRow">
                      <div className="partCost">
                        {usMoneyFormatWithCents(r.price.totalCost)}
                      </div>
                    </TotalPod>
                  </Grid>)}
                <div className="carInfoRepairParts">
                  {parts
                .filter((p) => {
                var _a, _b, _c;
                return (((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.job.repairOrderId) === job.repairOrderId &&
                    ((_b = p === null || p === void 0 ? void 0 : p.meta) === null || _b === void 0 ? void 0 : _b.repair.id) === r.id &&
                    !((_c = p === null || p === void 0 ? void 0 : p.meta) === null || _c === void 0 ? void 0 : _c.isFluid));
            })
                .map((p) => {
                var _a, _b;
                return (<Part part={p} key={p.id} onClick={handleSelectPart(p)} selected={((_a = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _a === void 0 ? void 0 : _a.id) === ((_b = p === null || p === void 0 ? void 0 : p.meta) === null || _b === void 0 ? void 0 : _b.id)} onDelete={(repairId, componentId, componentType, isCustom) => {
                        var _a;
                        return handleRemoveComponentPrompt({
                            repairId,
                            componentId,
                            componentType,
                            isCustom,
                            removeDynamicRule: ((_a = p.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'DYNAMIC',
                        });
                    }} onReload={onReload}/>);
            })}

                  {parts
                .filter((p) => {
                var _a, _b, _c;
                return (((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.job.repairOrderId) === job.repairOrderId &&
                    ((_b = p === null || p === void 0 ? void 0 : p.meta) === null || _b === void 0 ? void 0 : _b.repair.id) === r.id &&
                    ((_c = p === null || p === void 0 ? void 0 : p.meta) === null || _c === void 0 ? void 0 : _c.isFluid));
            })
                .map((f) => {
                var _a, _b;
                return (<Fluid fluid={f} key={f.id} onClick={handleSelectPart(f)} selected={((_a = selectedPart === null || selectedPart === void 0 ? void 0 : selectedPart.meta) === null || _a === void 0 ? void 0 : _a.id) === ((_b = f === null || f === void 0 ? void 0 : f.meta) === null || _b === void 0 ? void 0 : _b.id)} onReload={onReload} onDelete={(repairId, componentId, componentType, isCustom) => {
                        var _a;
                        return handleRemoveComponentPrompt({
                            repairId,
                            componentId,
                            componentType,
                            isCustom,
                            removeDynamicRule: ((_a = f === null || f === void 0 ? void 0 : f.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'DYNAMIC',
                        });
                    }}/>);
            })}
                </div>
                <div className="carInfoRepairActions">
                  <AddComponentDrawer type="Part" setNewComponentType={setNewComponentType} repairId={r.id} newComponentType={newComponentType} onChangeInput={onChangeInput} onSaveNext={handleSaveNext} referenceNum={referenceNum} isCustomRepair={r.isCustom} price={r.price} hasEngine={hasEngine} fixedComponents={fixedComponents}/>
                  <AddComponentDrawer type="Fluid" setNewComponentType={setNewComponentType} repairId={r.id} newComponentType={newComponentType} onChangeInput={onChangeInput} onSaveNext={handleSaveNext} referenceNum={referenceNum} isCustomRepair={r.isCustom} price={r.price} hasEngine={hasEngine} fixedComponents={fixedComponents}/>
                  <OrderNotesDrawer repairRequestId={job.repairRequestId} referenceNum={referenceNum} highlightNotes={parts.filter((p) => {
                var _a, _b;
                return (p === null || p === void 0 ? void 0 : p.status) === 'WRONG_PART' &&
                    ((_a = p === null || p === void 0 ? void 0 : p.meta) === null || _a === void 0 ? void 0 : _a.job.repairOrderId) === job.repairOrderId &&
                    ((_b = p === null || p === void 0 ? void 0 : p.meta) === null || _b === void 0 ? void 0 : _b.repair.id) === r.id;
            }).length > 0}/>
                </div>
              </Fragment>))}
          </Repair>
        </AccordionContent>

        <JobFooter>
          <div className="refContainer">
            <p className="containerCaption">Ref #</p>
            <p className="containerText">{job.referenceNum}</p>
          </div>
          <div className="skeduloContainer">
            <p className="containerCaption">Skedulo ID</p>

            {(job === null || job === void 0 ? void 0 : job.skeduloJobId) ? (<a className="containerSkeduloText" href={getSkeduloUrl(`/job/${job.skeduloJobId}/details`)} rel="noopener noreferrer" target="_blank">
                {(job === null || job === void 0 ? void 0 : job.skeduloJobName) ? 'JOB' : 'N/A'}
              </a>) : (<p className="containerText">N/A</p>)}
          </div>
        </JobFooter>
      </PartsAccordion>
      <SimpleModal open={!!removingComponent && !(removingComponent === null || removingComponent === void 0 ? void 0 : removingComponent.removeDynamicRule)} title={`Are you sure you want to remove ${getComponentName()}?`} simpleTwoButtonRowProps={{
            cancelText: 'No',
            submitText: 'Yes',
            onCancel: () => setRemovingComponent(undefined),
            onSubmit: onRemoveComponent,
            submitIsDestructive: true,
        }}/>
      <SimpleModal open={!!(removingComponent === null || removingComponent === void 0 ? void 0 : removingComponent.removeDynamicRule)} onClose={() => setRemovingComponent(undefined)} title="Removal Warning" simpleTwoButtonRowProps={{
            onCancel: () => {
                setRemovingComponent(undefined);
            },
            onSubmit: () => {
                setRemovingComponent(undefined);
                onRemoveComponent();
            },
            cancelText: 'Cancel',
            submitText: 'Confirm',
        }}>
        Please confirm that this {removingComponent === null || removingComponent === void 0 ? void 0 : removingComponent.componentType} is not
        needed to complete this repair. Doing so will make sure this{' '}
        {removingComponent === null || removingComponent === void 0 ? void 0 : removingComponent.componentType} no longer appears when we perform
        this repair on this specific vehicle.
      </SimpleModal>
      <SimpleModal open={dynamicCreationConfirm} onClose={() => setDynamicCreationConfirm(false)} title="Warning" simpleTwoButtonRowProps={{
            onCancel: () => setDynamicCreationConfirm(false),
            onSubmit: () => {
                setDynamicCreationConfirm(false);
                setIsComponentDataCorrect(true);
            },
            cancelText: 'Cancel',
            submitText: 'Confirm',
        }}>
        Please confirm this
        {entityName} is always needed when doing this repair on this specific
        vehicle. Doing so will make sure this {entityName} always appears when
        we perform this repair on this specific vehicle.
      </SimpleModal>
      <SimpleModal open={isComponentDataCorrect} onClose={() => setIsComponentDataCorrect(false)} title="Are you sure?" simpleTwoButtonRowProps={{
            onCancel: () => setIsComponentDataCorrect(false),
            onSubmit: () => {
                const { isCustom, values, newComponentType } = savingData;
                setIsComponentDataCorrect(false);
                onSaveNext(isCustom, values, newComponentType);
                setSavingData({});
            },
            cancelText: 'Cancel',
            submitText: 'Confirm',
        }}>
        Please verify the {entityName} information is correct as entered.
      </SimpleModal>
    </Container>);
};
export default CarInfo;
