import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { MONTH_AND_DAY_FORMAT, WEEK_DAY_SHORT_FORMAT } from 'src/timeConstants';
import SliderStyled from './ScrollDatePicker.styles';
const Slider = ({ currentDate, setCurrentDate }) => {
    const [days, setDays] = useState([]);
    const settings = {
        dots: false,
        infinite: false,
        initialSlide: 10,
        speed: 300,
        slidesToShow: 1,
        focusOnSelect: true,
        variableWidth: true,
        slidesToScroll: 5,
        afterChange: (index) => {
            setCurrentDate(days[index]);
        },
    };
    const slider = useRef(null);
    useEffect(() => {
        if (slider && slider.current)
            slider.current.slickGoTo(10);
        const days = [currentDate];
        for (let number = 1; number < 11; number += 1)
            days.push(moment(currentDate).subtract(number, 'days'), moment(currentDate).add(number, 'days'));
        setDays(days.sort((a, b) => a - b));
    }, [currentDate]);
    return days.length > 0 ? (<SliderStyled ref={slider} {...settings}>
      {days.map((day, index) => (<div key={index}>
          <p>
            <strong>{moment(day).format(MONTH_AND_DAY_FORMAT)}</strong>{' '}
            {moment(day).format(WEEK_DAY_SHORT_FORMAT)}
          </p>
        </div>))}
    </SliderStyled>) : null;
};
export default Slider;
