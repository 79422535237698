import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Divider, Grid, Paper, Typography } from 'src/components/mui';
const StyledPaper = styled(Paper) `
  padding: 10px;
`;
const UploaderResponseDetails = ({ validationInfo }) => {
    var _a;
    return (<Grid item xs>
    <StyledPaper>
      <Typography>Total rows: {validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.totalRows}</Typography>
      <Typography>Successful rows: {validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.successfulRows}</Typography>
      <Typography>Failed rows: {validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.failedRows}</Typography>
      {!isEmpty(validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.errors) && (<>
          <Divider />
          <Typography>Errors:</Typography>
          {(_a = validationInfo === null || validationInfo === void 0 ? void 0 : validationInfo.errors) === null || _a === void 0 ? void 0 : _a.map((error) => {
                var _a;
                return (<>
              {error.row && (<Typography variant="body1" style={{ paddingLeft: 10 }}>
                  Row number: {error.row}
                </Typography>)}
              {(_a = error === null || error === void 0 ? void 0 : error.reasons) === null || _a === void 0 ? void 0 : _a.map((reason) => (<Typography variant="body2" style={{ paddingLeft: 20, wordWrap: 'break-word' }}>
                  - {reason}
                </Typography>))}
            </>);
            })}
        </>)}
    </StyledPaper>
  </Grid>);
};
export default UploaderResponseDetails;
