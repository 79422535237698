var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { fetchDistributors, saveDistributor, updateDistributor, } from 'src/PopsApp/modules/distributors/actions';
import { selectDashboardPagination, selectDashboardParams, selectDashboardFetching, selectDistributorDashboard, } from 'src/PopsApp/modules/distributors/selectors';
const useDistributorsDashboard = () => {
    const dispatch = useDispatch();
    const distributors = useSelector(selectDistributorDashboard);
    const pagination = useSelector(selectDashboardPagination);
    const params = useSelector(selectDashboardParams);
    const isFetching = useSelector(selectDashboardFetching);
    const [addDistributorDrawerOpen, setAddDistributorDrawerOpen] = useState(false);
    const [hoursDrawerOpen, setHoursDrawerOpen] = useState(false);
    const [selectedDistributor, setSelectedDistributor] = useState();
    useEffect(() => {
        onLoadData({
            page: (pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) || 0,
            size: (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || 20,
            sort: (pagination === null || pagination === void 0 ? void 0 : pagination.sort) || ['name,asc', 'modified,desc', 'status,asc'],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLoadData = ({ page, size, sort, }) => {
        dispatch(fetchDistributors(Object.assign(Object.assign({}, params), { pageSize: size, pageIndex: page, sort })));
    };
    const onEditDistributor = (distributor) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield dispatch(updateDistributor(distributor));
        if (!result.error) {
            toast.success('Part Distributor successfully updated');
            onLoadData({
                page: (pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) || 0,
                size: (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || 20,
                sort: (pagination === null || pagination === void 0 ? void 0 : pagination.sort) || ['name,asc', 'modified,desc', 'status,asc'],
            });
        }
    });
    const onSaveDistributor = (distributor) => {
        dispatch(saveDistributor(distributor)).then((result) => {
            setAddDistributorDrawerOpen(false);
            if (!result.error) {
                toast.success('Part Distributor successfully saved');
                onLoadData({
                    page: (pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) || 0,
                    size: (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || 20,
                    sort: (pagination === null || pagination === void 0 ? void 0 : pagination.sort) || ['name,asc', 'modified,desc', 'status,asc'],
                });
            }
        });
    };
    const onHoursEdit = (distributor) => {
        setHoursDrawerOpen(true);
        setSelectedDistributor(distributor);
    };
    const closeHoursOfOperationDrawer = () => {
        setSelectedDistributor(undefined);
        setHoursDrawerOpen(false);
    };
    return {
        distributors,
        pagination,
        params,
        isFetching,
        addDistributorDrawerOpen,
        setAddDistributorDrawerOpen,
        onLoadData,
        onEditDistributor,
        onSaveDistributor,
        onHoursEdit,
        selectedDistributor,
        hoursDrawerOpen,
        closeHoursOfOperationDrawer,
    };
};
export default useDistributorsDashboard;
