import { TextField, Autocomplete } from 'src/components/mui';
import React from 'react';
import { useSelector } from 'react-redux';
import useAutocompleteInput from 'src/hooks/useAutocompleteInput';
const MetroSearch = ({ value, getMetros, metrosSelector, onUpdate, name, label, disabled = false, }) => {
    const options = useSelector(metrosSelector);
    const { onInputChange } = useAutocompleteInput(value, disabled, getMetros);
    const onSubmit = (value) => {
        onUpdate(value);
    };
    return (<Autocomplete fullWidth disabled={disabled} value={value} style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option, value) => option.id === value.id} options={options} getOptionLabel={(option) => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : ''; }} onInputChange={onInputChange} onChange={(e, value) => {
            onSubmit(value);
        }} renderInput={(params) => (<TextField {...params} name={name} label={label} fullWidth variant="standard" type="text" InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default MetroSearch;
