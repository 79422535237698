import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { OrderRosterMode } from 'src/AdminApp/models/enums/OrderRosterMode';
import { Accordion, AccordionDetails, AccordionSummary, } from 'src/components/mui';
import { ExpandMore as ExpandMoreIcon } from 'src/components/mui/icons';
import SimpleChip from 'src/components/SimpleChip';
import { ChipButton } from 'src/PopsApp/components/VansFilter/FilterDialog.styles';
import { getActivelySkippedOrderRosterJobs, getOrderRosterJobs, } from 'src/PopsApp/modules/parts/actions';
import { selectOrderRosterJobs, selectOrderRosterJobsPagination, } from 'src/PopsApp/modules/parts/selectors';
import OrderRosterJobs from 'src/PopsApp/pages/OrderRoster/components/OrderRosterJobs';
import OrderRosterSearch from 'src/PopsApp/pages/OrderRoster/components/OrderRosterSearch';
import { colors } from 'src/styles/theme';
import { Container } from '../Order/Order.styles';
const OrderRoster = () => {
    const history = useHistory();
    const { shift } = useParams();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const jobs = useSelector(selectOrderRosterJobs);
    const pagination = useSelector(selectOrderRosterJobsPagination);
    useEffect(() => {
        setPage(0);
    }, [shift]);
    useEffect(() => {
        const currentMode = shift;
        if (currentMode === OrderRosterMode.DAY ||
            currentMode === OrderRosterMode.NIGHT) {
            dispatch(getOrderRosterJobs({
                shift: shift.toUpperCase(),
                size: rowsPerPage,
                page,
            }));
        }
        else {
            dispatch(getActivelySkippedOrderRosterJobs({ size: rowsPerPage, page }));
        }
    }, [dispatch, shift, page, rowsPerPage]);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = ({ target }) => {
        setRowsPerPage(parseInt(target.value, 10));
        setPage(0);
    };
    return (<>
      <OrderRosterSearch />
      <Accordion style={{ marginTop: '20px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <h2 className="regionsTitle">Order Roster</h2>
        </AccordionSummary>
        <AccordionDetails>
          <Container className="orderContainer" style={{ width: '100%' }}>
            <div className="ordersMain fixed">
              <div className="shift-container">
                <ChipButton>
                  <SimpleChip label="Day" $bgColor={shift === OrderRosterMode.DAY
            ? colors.BLUE
            : colors.lightGrey} $textColor={shift === OrderRosterMode.DAY
            ? colors.white
            : colors.darkGrey} onClick={() => history.push('/pops/order-roster/day')}/>
                </ChipButton>
                <ChipButton>
                  <SimpleChip label="Night" $bgColor={shift === OrderRosterMode.NIGHT
            ? colors.BLUE
            : colors.lightGrey} $textColor={shift === OrderRosterMode.NIGHT
            ? colors.white
            : colors.darkGrey} onClick={() => history.push('/pops/order-roster/night')}/>
                </ChipButton>
                <ChipButton>
                  <SimpleChip label="Skipped" $bgColor={shift === OrderRosterMode.SKIPPED
            ? colors.BLUE
            : colors.lightGrey} $textColor={shift === OrderRosterMode.SKIPPED
            ? colors.white
            : colors.darkGrey} onClick={() => history.push('/pops/order-roster/skipped')}/>
                </ChipButton>
              </div>
              <OrderRosterJobs jobs={jobs} pagination={pagination} page={page} changeRowsPerPage={handleChangeRowsPerPage} changePage={handleChangePage} rowsPerPage={rowsPerPage} mode={shift}/>
            </div>
          </Container>
        </AccordionDetails>
      </Accordion>
    </>);
};
export default OrderRoster;
