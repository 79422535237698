var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { acknowledgeInvoiceRequest, getInvoiceRequests, searchB2BResponsibleParties, cancelInvoiceRequest } from 'src/AdminApp/modules/invoiceRequests/actions';
import { selectInvoiceRequestsData, selectIsFetching, selectPagination, } from 'src/AdminApp/modules/invoiceRequests/selectors';
import { toast } from 'src/components/SimpleToast';
import { selectWorpDriveAccess } from 'src/modules/auth/selectors';
import { isReferenceNum } from 'src/utils/nav-utils';
const useInvoiceRequests = (autoLoad = false) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(50);
    const [tableSort, setTableSort] = useState();
    const [type, setType] = useState([]);
    const [status, setStatus] = useState([]);
    const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
    const [selectedInvoiceRequest, setSelectedInvoiceRequest] = useState();
    const [isInvoiceRequestCancelDialogOpen, setInvoiceRequestDialogOpen] = useState();
    const [cancelInvoiceRequestId, setCancelInvoiceRequestId] = useState();
    const [selectedResponsibleParty, setSelectedResponsibleParty] = useState();
    const [firstSearch, setFirstSearch] = useState(true);
    const [refNumSearch, setRefNumSearch] = useState();
    const [refNumSearchError, setRefNumSearchError] = useState();
    const [clearInput, setClearInput] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const worpDriveAccess = useSelector(selectWorpDriveAccess);
    const pagination = useSelector(selectPagination);
    const isFetching = useSelector(selectIsFetching);
    const invoiceRequests = useSelector(selectInvoiceRequestsData);
    const defaultSearchParams = () => {
        return {
            pageNumber: 0,
            pageSize,
            sort: tableSort !== null && tableSort !== void 0 ? tableSort : ['created,desc']
        };
    };
    const getSearchParams = () => {
        const params = defaultSearchParams();
        if (pagination) {
            params.pageNumber = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber;
        }
        if (type) {
            params.type = type;
        }
        if (type) {
            params.type = type;
        }
        if (status) {
            params.status = status;
        }
        if (selectedResponsibleParty) {
            params.responsiblePartyId = selectedResponsibleParty.id;
        }
        if (tableSort) {
            params.sort = tableSort;
        }
        return params;
    };
    const onLoadData = ({ page, size, sort }) => {
        const params = getSearchParams();
        if (typeof page !== 'undefined') {
            params.pageNumber = page;
        }
        if (typeof size !== 'undefined') {
            params.pageSize = size;
            setPageSize(size);
        }
        if (typeof sort !== 'undefined') {
            setTableSort(sort);
            params.sort = sort;
        }
        if (refNumSearch) {
            params.referenceNumber = refNumSearch;
        }
        dispatch(getInvoiceRequests(params));
    };
    const handleSearch = (newStatus) => {
        const params = getSearchParams();
        if (newStatus) {
            params.status = newStatus;
            setStatus(newStatus);
        }
        if (selectedResponsibleParty) {
            delete params.referenceNumber;
            setRefNumSearch('');
        }
        dispatch(getInvoiceRequests(params));
        setHasSearched(true);
    };
    const onRowsPerPageChange = (newPageSize) => {
        dispatch(getInvoiceRequests(Object.assign(Object.assign({}, getSearchParams()), { pageNumber: 0, pageSize: newPageSize })));
    };
    const handleChangeStatus = (status) => {
        setStatus(status);
    };
    const handleChangeType = (type) => {
        setType(type);
    };
    const handleRPSearch = (search) => __awaiter(void 0, void 0, void 0, function* () {
        const searchTrimmed = encodeURIComponent(search.trim());
        if (searchTrimmed.length < 2)
            return;
        const response = yield dispatch(searchB2BResponsibleParties(searchTrimmed));
        if (response.error) {
            return [];
        }
        setFirstSearch(false);
        return response.payload;
    });
    const handleSelectResponsibleParty = (rp) => {
        setSelectedResponsibleParty(rp);
    };
    const handleRefNumSearch = () => {
        if (!refNumSearch || isFetching)
            return;
        const params = Object.assign(Object.assign({}, getSearchParams()), { pageNumber: 0, referenceNumber: refNumSearch });
        delete params.responsiblePartyId;
        delete params.status;
        setStatus([]);
        setSelectedResponsibleParty(undefined);
        dispatch(getInvoiceRequests(params));
        setHasSearched(true);
    };
    const handleRefNumChange = (e) => {
        setRefNumSearch(e.target.value);
        setRefNumSearchError(!isReferenceNum(e.target.value));
    };
    const handleShowDetailsDrawer = (ir) => {
        setSelectedInvoiceRequest(ir);
        setShowDetailsDrawer(true);
    };
    const handleHideDetailsDrawer = (opts) => {
        setSelectedInvoiceRequest(undefined);
        setShowDetailsDrawer(false);
        if (opts === null || opts === void 0 ? void 0 : opts.acknowledged) {
            dispatch(getInvoiceRequests(Object.assign(Object.assign({}, getSearchParams()), { referenceNumber: opts === null || opts === void 0 ? void 0 : opts.refNum })));
        }
    };
    const handleAcknowledge = (invoiceRequestId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(acknowledgeInvoiceRequest(invoiceRequestId));
        if (!response.error) {
            toast.success('Invoice request acknowledged');
            handleHideDetailsDrawer({ acknowledged: true, refNum: refNumSearch });
        }
    });
    const handleCancelInvoiceRequest = () => __awaiter(void 0, void 0, void 0, function* () {
        if (cancelInvoiceRequestId) {
            const response = yield dispatch(cancelInvoiceRequest(cancelInvoiceRequestId));
            if (!response.error) {
                toast.success('Invoice request canceled');
                handleHideDetailsDrawer({ acknowledged: true, refNum: refNumSearch });
            }
        }
    });
    const resetFilters = () => {
        setType([]);
        setStatus([]);
        setSelectedResponsibleParty(undefined);
        setRefNumSearch('');
        setClearInput(!clearInput);
        setHasSearched(true);
        dispatch(getInvoiceRequests(defaultSearchParams()));
    };
    const getNullText = () => {
        let nullText = '';
        if (isFetching) {
            nullText = 'Loading...';
        }
        else if (hasSearched && (invoiceRequests === null || invoiceRequests === void 0 ? void 0 : invoiceRequests.length) == 0) {
            nullText = 'No results found';
        }
        return nullText;
    };
    const handleInvoiceCancelDialogState = (isOpen, requestId) => {
        setInvoiceRequestDialogOpen(isOpen);
        if (isOpen) {
            setCancelInvoiceRequestId(requestId);
        }
        else {
            setCancelInvoiceRequestId(undefined);
        }
    };
    useEffect(() => {
        if (autoLoad) {
            dispatch(getInvoiceRequests(defaultSearchParams()));
        }
    }, [autoLoad]);
    return {
        worpDriveAccess,
        pagination,
        isFetching,
        onLoadData,
        onRowsPerPageChange,
        loading,
        setLoading,
        handleChangeStatus,
        handleChangeType,
        type,
        status,
        tableSort,
        invoiceRequests,
        showDetailsDrawer,
        handleShowDetailsDrawer,
        handleHideDetailsDrawer,
        selectedInvoiceRequest,
        handleAcknowledge,
        handleSearch,
        handleRPSearch,
        selectedResponsibleParty,
        handleSelectResponsibleParty,
        handleRefNumSearch,
        handleRefNumChange,
        refNumSearch,
        resetFilters,
        refNumSearchError,
        firstSearch,
        getNullText,
        clearInput,
        handleCancelInvoiceRequest,
        isInvoiceRequestCancelDialogOpen,
        handleInvoiceCancelDialogState
    };
};
export default useInvoiceRequests;
