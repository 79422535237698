var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import ReactJson from '@microlink/react-json-view';
import { Alert, Box, MenuItem, TextField } from 'src/components/mui';
import { FooterActionButton, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
import CONFIG from 'src/config';
import * as Yup from 'yup';
import LocalWebhookInfo from './LocalWebhookInfo';
import { buildEnvironment, generateEnvironments, getDefaultBaseUrl, } from './webhookUtils';
const rsEnvironments = generateEnvironments(CONFIG.APP_ENV);
const SetupEnvironmentSchema = Yup.object().shape({
    baseUrl: Yup.string()
        .url('Incorrect URL (try starting with https://)')
        .required('Required'),
    environment: Yup.string().required('Required'),
    localMachineName: Yup.string().when('environment', {
        is: (value) => value === 'local',
        then: Yup.string()
            .required('Required')
            .min(2, 'Too Short')
            .max(50, 'Too Long'),
        otherwise: Yup.string().min(2, 'Too Short').max(50, 'Too Long'),
    }),
});
const INITIAL_VALUES = {
    baseUrl: getDefaultBaseUrl(CONFIG.APP_ENV),
    environment: CONFIG.APP_ENV || '',
    localMachineName: CONFIG.APP_ENV === 'local' ? CONFIG.REACT_APP_HOSTNAME : '',
};
const DraweredSetup = ({ createWebhook, deleteWebhook, webhooks, toggleDrawer, }) => {
    const [webhooksToDelete, setWebhooksToDelete] = useState([]);
    const [webhooksToCreate, setWebhooksToCreate] = useState([]);
    const [webhookPreview, setWebhookPreview] = useState();
    const previewHandler = (webhook) => {
        setWebhookPreview(webhook);
    };
    const setupWebhookCreation = (values, initialMount) => __awaiter(void 0, void 0, void 0, function* () {
        const webhooksToBeCreated = yield Promise.all(yield buildEnvironment(values)).then((result) => {
            return result;
        });
        setWebhooksToCreate(webhooksToBeCreated);
        if (!initialMount) {
            previewHandler(webhooksToBeCreated[1]);
        }
    });
    const checkEnvironment = (allWebhooks, environment, localMachineName) => {
        const environmentKey = environment === 'local' ? localMachineName : environment;
        const environmentWebhooks = allWebhooks.filter((webhook) => { var _a; return ((_a = webhook.metadata) === null || _a === void 0 ? void 0 : _a.environment) === environmentKey; });
        setWebhooksToDelete(environmentWebhooks);
    };
    useEffect(() => {
        setupWebhookCreation(INITIAL_VALUES, true);
        checkEnvironment(webhooks, INITIAL_VALUES.environment, INITIAL_VALUES.localMachineName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const wizardCreateHandler = (webhooksCreate, webhooksDelete) => __awaiter(void 0, void 0, void 0, function* () {
        const requests = yield webhooksDelete.map((webhook) => deleteWebhook(webhook.id, webhook.name, true));
        Promise.all(requests).then(() => {
            webhooksCreate.forEach((webhook) => {
                createWebhook(webhook);
            });
        });
    });
    return (<Formik initialValues={Object.assign({}, INITIAL_VALUES)} validationSchema={SetupEnvironmentSchema} validateOnMount onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
                setSubmitting(false);
                wizardCreateHandler(webhooksToCreate, webhooksToDelete);
                resetForm({});
            }, 1000);
            toggleDrawer();
        }}>
      {({ submitForm, isSubmitting, isValid, values, setFieldValue }) => (<Form>
          <SimpleDrawerContent>
            <Box margin={1}>
              <TextField id="environment" name="environment" label="Environment" select fullWidth onChange={(e) => {
                setFieldValue('environment', e.target.value);
                setFieldValue('baseUrl', getDefaultBaseUrl(e.target.value));
                checkEnvironment(webhooks, e.target.value, values.localMachineName);
                setupWebhookCreation(Object.assign(Object.assign({}, values), { environment: e.target.value, baseUrl: getDefaultBaseUrl(e.target.value) }), false);
            }} value={values.environment}>
                {rsEnvironments.map((environment) => (<MenuItem value={environment} key={environment}>
                    {environment}
                  </MenuItem>))}
              </TextField>
            </Box>
            {values.environment === 'local' ? (<Box margin={1}>
                <TextField id="localMachineName" name="localMachineName" label="Local Machine Name" fullWidth onChange={(e) => {
                    setFieldValue('localMachineName', e.target.value);
                    checkEnvironment(webhooks, values.environment, e.target.value);
                    setupWebhookCreation(Object.assign(Object.assign({}, values), { localMachineName: e.target.value }), false);
                }} value={values.localMachineName}/>
              </Box>) : null}
            <Box margin={1}>
              <TextField id="baseUrl" name="baseUrl" label="Base URL" placeholder="https://" fullWidth onChange={(e) => {
                setFieldValue('baseUrl', e.target.value);
                checkEnvironment(webhooks, values.environment, values.localMachineName);
                setupWebhookCreation(Object.assign(Object.assign({}, values), { baseUrl: e.target.value }), false);
            }} value={values.baseUrl}/>
            </Box>
            {values.environment === 'local' ? <LocalWebhookInfo /> : null}
            {values.environment && webhooksToDelete.length > 0 ? (<Box margin={1} marginTop="calc(1rem + 30px)">
                <Alert severity="warning">
                  The following webhooks for environment{' '}
                  <strong>
                    {values.environment === 'local'
                    ? values.localMachineName
                    : values.environment}
                  </strong>{' '}
                  will be <strong>deleted</strong>:
                  <ul>
                    {webhooksToDelete.map((webhook) => {
                    return <li key={webhook.id}>{webhook.name}</li>;
                })}
                  </ul>
                </Alert>
              </Box>) : (<Box margin={1} marginTop="calc(1rem + 30px)">
                <Alert severity="warning">
                  No existing webhooks detected for environment{' '}
                  <strong>
                    {values.environment === 'local'
                    ? values.localMachineName
                    : values.environment}
                  </strong>
                </Alert>
              </Box>)}
            {values.environment ? (<Box margin={1}>
                <Alert severity="info">
                  The following webhooks for environment{' '}
                  <strong>
                    {values.environment === 'local'
                    ? values.localMachineName
                    : values.environment}
                  </strong>{' '}
                  will be <strong>created</strong>:
                  <ul>
                    {webhooksToCreate.map((webhook) => {
                    return (<li 
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="button" tabIndex={0} key={webhook.name} style={{
                            cursor: 'pointer',
                            color: 'blue',
                            textDecoration: 'underline',
                        }} onKeyDown={() => { }} onClick={() => previewHandler(webhook)}>
                          {webhook.name}
                        </li>);
                })}
                  </ul>
                </Alert>
              </Box>) : null}
            {webhookPreview ? (<Box>
                <h3>Webhook Preview</h3>
                <ReactJson src={webhookPreview} name={null} indentWidth={2} displayDataTypes={false} theme="mocha" enableClipboard={false} onEdit={false} onAdd={false} onDelete={false} collapseStringsAfterLength={100} style={{ fontSize: '0.875rem', overflow: 'auto' }}/>
              </Box>) : null}
          </SimpleDrawerContent>
          <SimpleDrawerFooter>
            <FooterActionButton disabled={!isValid || isSubmitting} onClick={submitForm}>
              Submit
            </FooterActionButton>
          </SimpleDrawerFooter>
        </Form>)}
    </Formik>);
};
export default DraweredSetup;
