import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/mui';
import { selectDashboardFleets } from 'src/AdminApp/modules/fleets/selectors';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import AddFleetDrawer from 'src/AdminApp/containers/fleets/AddFleetDrawer';
import AddFleetRODrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/AddFleetRODrawer';
import { AddCircle } from 'src/components/mui/icons';
import SearchIndexType from 'src/SystemApp/pages/elasticsearch/ElasticsearchIndexer/SearchIndexType';
import ReindexSearchButton from 'src/SystemApp/pages/elasticsearch/ElasticsearchIndexer/ReindexSearchButton';
import useFleetsDashboard from 'src/AdminApp/containers/fleets/useFleetsDashboard';
import DashboardListItem from './DashboardListItem';
import DashboardFilters from './DashboardFilters';
const REQUEST_COLUMNS = [
    { title: 'Fleet Name', key: 'name', sortable: false },
    { title: 'Station', key: 'station', sortable: false },
    { title: 'Main Contact', key: 'contactName', sortable: false },
    { title: 'Fleet Size', key: 'fleetSize', sortable: false },
    { title: 'Parent Name', key: 'parentName', sortable: true },
    { title: '', key: 'other' },
];
const FleetsDashboard = () => {
    var _a;
    const [isAddROOpen, setIsAddROOpen] = useState(false);
    const fleets = useSelector(selectDashboardFleets);
    const { setAddFleetIsOpen, onReset, onClear, onSearch, handleSearchFleets, pagination, onLoadData, setPageSize, addFleetIsOpen, onAddFleet, } = useFleetsDashboard();
    return (<>
      <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '30px',
        }}>
        <Button style={{ marginRight: '10px' }} onClick={() => setIsAddROOpen(true)} variant="contained" color="primary">
          Add Repair Order
        </Button>
        <AddFleetRODrawer open={isAddROOpen} onClose={() => setIsAddROOpen(false)}/>

        <ReindexSearchButton indexType={SearchIndexType.FLEET}/>

        <div style={{ display: 'flex' }}>
          <Button onClick={() => setAddFleetIsOpen(true)} startIcon={<AddCircle />} variant="text" style={{ fontSize: 20, padding: '0 0 0 15px' }}>
            Add Fleet
          </Button>
        </div>
      </div>
      <DashboardFilters onSearch={onSearch} onClear={onClear} onReset={onReset} searchFleets={handleSearchFleets}/>
      <SimpleTable data={fleets} pagination={pagination} sort={(_a = pagination.sort) !== null && _a !== void 0 ? _a : []} columns={REQUEST_COLUMNS} renderItem={(item, index) => (<DashboardListItem {...item} key={`${index}-${item.referenceNum}`}/>)} onLoadData={onLoadData} onRowsPerPageChange={setPageSize}/>
      <AddFleetDrawer open={addFleetIsOpen} onClose={() => setAddFleetIsOpen(false)} searchFleets={handleSearchFleets} onSubmit={onAddFleet}/>
    </>);
};
export default FleetsDashboard;
