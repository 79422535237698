import React from 'react';
import { Create as CreateIcon, FileCopy } from 'src/components/mui/icons';
import { IconButton, Button, useMediaQuery } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import copyToClipboard from 'src/utils/copyToClipboard';
import { theme } from 'src/styles/theme';
import { capitalize } from 'lodash';
import { PartnerAPICell, PartnerAPIRowContainer, SubtextContainer, } from './PartnerAPIRow.styles';
const PartnerAPIRow = ({ item, setEditPartner, partners, marketingSources, }) => {
    var _a, _b, _c, _d;
    const onCopyQuoteLink = () => {
        copyToClipboard(item.publicKey);
    };
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (<PartnerAPIRowContainer>
      <PartnerAPICell>
        {partners ? (<SubtextContainer>
            <span>
              {(_b = (_a = partners.find((partner) => partner.id === item.partnerId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''}
            </span>
            <span className="subtext">{item.partnerId}</span>
          </SubtextContainer>) : (item.id)}
      </PartnerAPICell>
      <PartnerAPICell>
        {marketingSources ? (<SubtextContainer>
            <span>
              {(_d = (_c = marketingSources.find((source) => source.id === item.marketingSourceId)) === null || _c === void 0 ? void 0 : _c.displayName) !== null && _d !== void 0 ? _d : ''}
            </span>
            <span className="subtext">{item.marketingSourceId}</span>
          </SubtextContainer>) : (item.id)}
      </PartnerAPICell>
      <PartnerAPICell>{item.description}</PartnerAPICell>

      <PartnerAPICell>{capitalize(item.accessLevel)}</PartnerAPICell>

      {!isMobile && (<>
          <PartnerAPICell>{item.publicKey}</PartnerAPICell>
          <PartnerAPICell>{item.validFrom}</PartnerAPICell>
          <PartnerAPICell>{item.validTo}</PartnerAPICell>
        </>)}
      <PartnerAPICell>
        <IconButton onClick={() => setEditPartner({ open: true, apiKey: item })}>
          <CreateIcon />
        </IconButton>

        <Tooltip title="Copy public key">
          <Button startIcon={<FileCopy />} onClick={() => onCopyQuoteLink()}/>
        </Tooltip>
      </PartnerAPICell>
    </PartnerAPIRowContainer>);
};
export default PartnerAPIRow;
