import styled from 'styled-components';
import { Grid, Typography } from 'src/components/mui';
import { theme, colors } from 'src/styles/theme';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 0 16px;
  position: relative;

  .carInfoTitle {
    padding: 0;
    margin: 24px 0 8px;
    font-size: 12px;
    font-weight: 700;
    color: ${colors.GRAY_76};

    &--part {
      flex: 1;
    }

    &--quoted {
      flex: 0 0 120px;
      text-align: right;
      padding-right: 24px;
    }

    &--price {
      flex: 0 0 160px;
      margin: 0;
      padding-right: 90px;
    }
  }

  .partDetails {
    font-weight: 500;
    font-size: 12px;
    color: ${colors.GRAY_76};

    @media (min-width: 600px) {
      position: absolute;
      left: 0;
      top: 100%;
    }
  }

  .carInfoRepairName {
    text-align: left;
    margin-top: 0;
    margin-bottom: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .partQuoted {
      font-weight: 500;
      color: ${colors.GRAY_AA};
      font-size: 14px;
    }
  }

  .carInfoHeader {
    border-bottom: solid 1px ${colors.GRAY_DE};
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  .carInfoRepairActions {
    white-space: nowrap;
    margin: 10px 0px;

    & > * {
      font-size: 12px;
    }

    ${theme.breakpoints.up('md')} {
      display: flex;
      align-items: center;
      margin: 25px 0px;
      white-space: normal;

      & > * {
        font-size: inherit;
      }
    }

    & > * {
      margin-right: 10px;
    }
  }

  .partStatus {
    position: absolute;
    top: 18px;
    right: 48px;
  }

  .repairNotesButton {
    padding: 20px 0;
    color: blue;
    display: flex;
    align-items: center;
    width: fit-content;
  }

  .repairNotesButtonIcon {
    display: block;
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }

  ${theme.breakpoints.up('md')} {
    border-top: solid 1px ${colors.GRAY_DE};

    :first-of-type {
      border-top: none;
    }

    .accordion {
      border: none;

      .partCostRow {
        justify-content: flex-start;
      }

      .partCost {
        font-weight: 500;
        color: ${colors.black};
        font-size: 14px;
        margin-right: 10px;
        display: flex;
        flex: 0 0 72px;
        justify-content: flex-end;
      }
    }
  }
`;
export const Repair = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  :last-child {
    margin: 0 0 48px;
  }

  .repairTitle {
    font-weight: 700;
    font-size: 14px;
    color: ${colors.black};
    padding: 0;
    margin: 0 0 16px;

    h4 {
      margin: 0;
    }
  }

  .repairAddParts {
    padding: 16px 0;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.BLUE};
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 0 0 32px;
  }
  .repairAddPartsIcon {
    margin-right: 4px;
  }
`;
export const JobFooter = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;

  ${theme.breakpoints.up('md')} {
    display: none;
  }

  .refContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
      to top,
      rgba(2, 2, 2, 0.01) 12%,
      rgba(0, 0, 0, 0.04)
    );
    padding: 8px;
  }

  .skeduloContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    padding: 8px;
  }

  .containerCaption {
    color: ${colors.GRAY_76};
    font-weight: 700;
    margin: 0 0 4px;
    padding: 0;
  }

  .containerText {
    padding: 0;
    margin: 0;
    font-weight: 500;
    color: ${colors.black};
  }

  .containerSkeduloText {
    padding: 0;
    margin: 0;
    font-weight: 700;
    color: ${colors.BLUE};
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
  }
`;
export const CarTitleRoot = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .carIcon {
    width: 24px;
    height: 24px;

    ${theme.breakpoints.up('md')} {
      width: 32px;
      height: 32px;
    }
  }

  .carTitle {
    font-size: 14px;
    font-weight: 700;
    color: ${colors.black};
    padding: 0 64px 0 0;
    margin: 4px 0 2px;

    ${theme.breakpoints.up('md')} {
      font-size: 16px;
    }
  }

  .carTitleDesktop {
    display: none;
    ${theme.breakpoints.up('md')} {
      display: inline;
    }
  }

  .carSubtitle {
    font-size: 12px;
    font-weight: 500;
    color: ${colors.GRAY_76};
    padding: 0;
    margin: 0;

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  }

  .infoWrap {
    margin-left: 8px;

    padding-bottom: 16px;
  }
`;
export const CarAdditional = styled.div `
  padding: 20px 0;
  border-top: solid 1px ${colors.GRAY_DE};
  border-bottom: solid 1px ${colors.GRAY_DE};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${theme.breakpoints.up('md')} {
    border: none;
    padding: 8px 0 20px;
  }

  .infoItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .infoTitle {
    font-weight: 800;
    font-size: 12px;
    color: ${colors.GRAY_DE};

    padding: 0;
    margin: 0;
    margin-right: 4px;
  }

  .infoText {
    font-weight: 500;
    font-size: 12px;
    color: ${colors.GRAY_76};
    margin: 0;
    padding: 0;
    margin-right: 8px;
  }

  .additionalDesktop {
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .infoText {
      color: ${colors.black};

      &.skedulo {
        color: ${colors.BLUE};
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
      }
    }

    .infoTitle {
      font-weight: 500;
      color: ${colors.GRAY_76};
    }

    ${theme.breakpoints.up('md')} {
      display: flex;
    }
  }
`;
export const QuotedPod = styled(Grid) `
  text-align: right;
  border-right: 1px dashed ${colors.GRAY_AA};
  padding-right: 23px;
  margin-top: -15px;
  margin-bottom: -8px;
  flex: 1;
`;
export const TotalPod = styled(Grid) `
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: -8px;
  display: flex;
  flex: 0 0 160px;
`;
export const ActionsPod = styled(Grid) `
  text-align: right;
  padding-right: 56px;
  margin-top: -15px;
  margin-bottom: -8px;
  flex: 0 0 80px;
`;
export const NewComponentIndicator = styled(Typography) `
  color: ${colors.BLUE};
`;
