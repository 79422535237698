import { createSelector } from 'reselect';
import { AUTH_ROLES } from 'src/models/enums';
import { isTokenExpired, parseJwt } from './utils';
export const selectAuth = (state) => { var _a; return (_a = state.auth) !== null && _a !== void 0 ? _a : {}; };
export const selectCurrentUrl = (state) => { var _a, _b, _c; return (_c = (_b = (_a = state.router) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.pathname) !== null && _c !== void 0 ? _c : '/'; };
export const selectAccessToken = createSelector(selectAuth, (auth) => { var _a; return (_a = auth.accessToken) !== null && _a !== void 0 ? _a : ''; });
export const selectRefreshToken = createSelector(selectAuth, (auth) => { var _a; return (_a = auth.refreshToken) !== null && _a !== void 0 ? _a : null; });
export const selectMfaRequired = createSelector(selectAuth, (auth) => { var _a; return (_a = auth.mfaRequired) !== null && _a !== void 0 ? _a : null; });
export const selectUserEmail = createSelector(selectAuth, (auth) => { var _a; return (_a = auth.userEmail) !== null && _a !== void 0 ? _a : null; });
export const selectIsAuthenticated = createSelector(selectAccessToken, (accessToken) => accessToken && !isTokenExpired(accessToken));
export const selectRoles = createSelector(selectAuth, (auth) => { var _a; return (_a = auth.roles) !== null && _a !== void 0 ? _a : []; });
export const selectParsedToken = createSelector(selectAccessToken, (accessToken) => {
    return parseJwt(accessToken);
});
export const selectUserId = createSelector(selectParsedToken, (parsedToken) => parsedToken['https://servus.com/ispOwnerId'] ||
    parsedToken['https://servus.com/systemUserId']);
export const selectCurrentUserId = createSelector(selectAuth, (auth) => auth.userId);
export const selectCurrentUserEmail = createSelector(selectAuth, (auth) => { var _a; return (_a = auth.userEmail) !== null && _a !== void 0 ? _a : ''; });
export const selectCurrentUserGroups = createSelector(selectAuth, (auth) => { var _a; return (_a = auth.groups) !== null && _a !== void 0 ? _a : []; });
const hasRole = (role) => createSelector(selectRoles, (roles) => roles.includes(AUTH_ROLES.display(`${role}`)));
export const selectAuthorizedEditServiceAdvisor = createSelector(hasRole(AUTH_ROLES.REQUEST_SERVICE_ADVISOR_EDITOR), (authorized) => authorized);
export const selectAuthorizedRepairView = createSelector(hasRole(AUTH_ROLES.REPAIR_VIEWER), (authorized) => authorized);
export const selectAuthorizedRepairSearch = createSelector(hasRole(AUTH_ROLES.REPAIR_SEARCH), (authorized) => authorized);
export const selectAuthorizedRequestHistoryView = createSelector(hasRole(AUTH_ROLES.REQUEST_HISTORY_VIEWER), (authorized) => authorized);
export const selectAuthorizedRepairEdit = createSelector(hasRole(AUTH_ROLES.REPAIR_EDITOR), (authorized) => authorized);
export const selectAuthorizedConsumerMarketingResourceEditor = createSelector(hasRole(AUTH_ROLES.MARKETING_SOURCE_EDITOR), (authorized) => authorized);
export const selectAuthorizedCustomerView = createSelector(hasRole(AUTH_ROLES.CUSTOMER_VIEWER), (authorized) => authorized);
export const selectAuthorizedCustomerSearch = createSelector(hasRole(AUTH_ROLES.CUSTOMER_SEARCH), (authorized) => authorized);
export const selectAuthorizedCustomerEdit = createSelector(hasRole(AUTH_ROLES.CUSTOMER_EDITOR), (authorized) => authorized);
export const selectAuthorizedShopView = createSelector(hasRole(AUTH_ROLES.SHOP_VIEWER), (authorized) => authorized);
export const selectAuthorizedShopSearch = createSelector(hasRole(AUTH_ROLES.SHOP_SEARCH), (authorized) => authorized);
export const selectAuthorizedShopEdit = createSelector(hasRole(AUTH_ROLES.SHOP_EDITOR), (authorized) => authorized);
export const selectAuthorizedSalesMatchView = createSelector(hasRole(AUTH_ROLES.SALES_MATCH_VIEWER), (authorized) => authorized);
export const selectAuthorizedSalesMatchEdit = createSelector(hasRole(AUTH_ROLES.SALES_MATCH_EDITOR), (authorized) => authorized);
export const selectAuthorizedUserEdit = createSelector(hasRole(AUTH_ROLES.USER_EDITOR), (authorized) => authorized);
export const selectAuthorizedPaymentCharge = createSelector(hasRole(AUTH_ROLES.PAYMENT_CHARGE), (authorized) => authorized);
export const selectAuthorizedPaymentRefund = createSelector(hasRole(AUTH_ROLES.PAYMENT_REFUND), (authorized) => authorized);
export const selectAuthorizedPaymentChargeCard = createSelector(hasRole(AUTH_ROLES.PAYMENT_CHARGE_CARD), (authorized) => authorized);
export const selectAuthorizedPartsEdit = createSelector(hasRole(AUTH_ROLES.PARTS_EDITOR), (authorized) => authorized);
export const selectAuthorizedPartsView = createSelector(hasRole(AUTH_ROLES.PARTS_VIEWER), (authorized) => authorized);
export const selectAuthorizedPricingAdmin = createSelector(hasRole(AUTH_ROLES.PRICING_ADMIN), (authorized) => authorized);
export const selectAuthorizedPricingUser = createSelector(selectAuthorizedPricingAdmin, hasRole(AUTH_ROLES.PRICING_USER), (isPricingAdmin, isPricingUser) => isPricingAdmin || isPricingUser);
export const selectAuthorizedSystemAdmin = createSelector(hasRole(AUTH_ROLES.SYSTEM_ADMIN), (authorized) => authorized);
export const selectAuthorizedSystemUser = createSelector(selectAuthorizedSystemAdmin, hasRole(AUTH_ROLES.SYSTEM_USER), (isSystemAdmin) => isSystemAdmin);
export const selectAuthorizedGeoMgmtView = createSelector(hasRole(AUTH_ROLES.GEO_VIEWER), (authorized) => authorized);
export const selectAuthorizedGeoMgmtSearch = createSelector(hasRole(AUTH_ROLES.GEO_SEARCH), (authorized) => authorized);
export const selectAuthorizedGeoMgmtEdit = createSelector(hasRole(AUTH_ROLES.GEO_EDITOR), (authorized) => authorized);
export const selectAuthorizedGeoLaborRateEdit = createSelector(hasRole(AUTH_ROLES.GEO_LABOR_RATE_EDITOR), (authorized) => authorized);
export const selectAuthorizedHyperionEdit = createSelector(hasRole(AUTH_ROLES.HYPERION_EDIT), (authorized) => authorized);
export const selectAuthorizedSubletValet = createSelector(hasRole(AUTH_ROLES.SUBLET_VALET_BETA), (authorized) => authorized);
export const selectAuthorizedDeliveryVanView = createSelector(hasRole(AUTH_ROLES.DELIVERY_VAN_VIEWER), (authorized) => authorized);
export const selectAuthorizedDeliveryVanEdit = createSelector(hasRole(AUTH_ROLES.DELIVERY_VAN_EDITOR), (authorized) => authorized);
export const selectAuthorizedFmcAdmin = createSelector(hasRole(AUTH_ROLES.FMC_ADMIN), (authorized) => authorized);
export const selectAuthorizedRepairRequestStatusEdit = createSelector(hasRole(AUTH_ROLES.REPAIR_REQUEST_STATUS_EDITOR), (authorized) => authorized);
export const selectAuthorizedSnapshotViewer = createSelector(hasRole(AUTH_ROLES.SNAPSHOT_VIEWER), (authorized) => authorized);
export const selectAuthorizedPartsIntegratedOrdering = createSelector(hasRole(AUTH_ROLES.PARTS_INTEGRATED_ORDERING), (authorized) => authorized);
export const selectAuthorizedCustomFunnelsEditor = createSelector(hasRole(AUTH_ROLES.PARTNERS_EDITOR), (authorized) => authorized);
export const selectAuthorizedTechnician = createSelector(hasRole(AUTH_ROLES.TECHNICIAN), (authorized) => authorized);
export const selectAuthorizedTechDashboardRedirect = createSelector(hasRole(AUTH_ROLES.TECH_DASHBOARD_REDIRECT), (authorized) => authorized);
export const selectAuthorizedTechDashboardViewer = createSelector(hasRole(AUTH_ROLES.TECH_DASHBOARD_VIEWER), (authorized) => authorized);
export const selectAuthorizedInternalPaymentCharge = createSelector(hasRole(AUTH_ROLES.INTERNAL_PAYMENT_CHARGE), (authorized) => authorized);
export const selectAuthorizedResponsiblePartyEditor = createSelector(hasRole(AUTH_ROLES.RESPONSIBLE_PARTY_EDITOR), (authorized) => authorized);
export const selectAuthorizedShowRODiscount = createSelector(hasRole(AUTH_ROLES.HIDE_ADD_DISCOUNT), (authorized) => !authorized);
export const selectAuthorizedShowZendeskChat = createSelector(hasRole(AUTH_ROLES.ZENDESK_CHAT), (authorized) => authorized);
export const selectAuthorizedAppointmentDateEditor = createSelector(hasRole(AUTH_ROLES.APPOINTMENT_DATE_EDITOR), (authorized) => authorized);
export const selectAuthorizedComponentEditor = createSelector(hasRole(AUTH_ROLES.COMPONENT_EDITOR), (authorized) => authorized);
export const selectOfflineFourCorners = createSelector(hasRole(AUTH_ROLES.OFFLINE_FOUR_CORNERS), (authorized) => authorized);
export const selectShowActionButtonsNonMain = createSelector(hasRole(AUTH_ROLES.SHOW_ACTION_BUTTONS_NOT_MAIN), (authorized) => authorized);
export const selectCancellationReasonEditor = createSelector(hasRole(AUTH_ROLES.CANCELLATION_REASON_EDITOR), (authorized) => authorized);
export const selectPartnerAPIKeyEditor = createSelector(hasRole(AUTH_ROLES.PARTNER_API_KEY_EDITOR), (authorized) => authorized);
export const selectPaymentRefundSubmitter = createSelector(hasRole(AUTH_ROLES.PAYMENT_REFUND_SUBMITTER), (authorized) => authorized);
export const selectComponentMarkupEditor = createSelector(hasRole(AUTH_ROLES.B2B_FIXED_MARKUP_EDITOR), (authorized) => authorized);
export const selectShiftEditor = createSelector(hasRole(AUTH_ROLES.SHIFT_ADMIN), (authorized) => authorized);
export const selectPopsManualOrderViewer = createSelector(hasRole(AUTH_ROLES.POPS_MANUAL_ORDER_VIEWER), (authorized) => authorized);
export const selectSkipFourCornerMPI = createSelector(hasRole(AUTH_ROLES.SKIP_FOUR_CORNER_MPI), (authorized) => authorized);
export const selectLockEditor = createSelector(hasRole(AUTH_ROLES.LOCK_ADMIN), (authorized) => authorized);
export const selectPartDistributorEditor = createSelector(hasRole(AUTH_ROLES.PART_DISTRIBUTOR_EDITOR), () => true);
export const selectProductEngCancelViewer = createSelector(hasRole(AUTH_ROLES.PRODUCT_ENG_CANCEL_VIEWER), (authorized) => authorized);
export const selectPaymentPreauth = createSelector(hasRole(AUTH_ROLES.PAYMENT_PREAUTH), (authorized) => authorized);
export const selectTemplateHStoreManager = createSelector(hasRole(AUTH_ROLES.TEMPLATE_HSTORE_MGR), (authorized) => authorized);
export const selectEscalationPayments = createSelector(hasRole(AUTH_ROLES.ESCALATION_PAYMENTS), (authorized) => authorized);
export const selectLockedRoEditor = createSelector(hasRole(AUTH_ROLES.LOCKED_RO_EDITOR), (authorized) => authorized);
export const selectQuickOrder = createSelector(hasRole(AUTH_ROLES.QUICK_ORDER), (authorized) => authorized);
export const selectWarrantyAdmin = createSelector(hasRole(AUTH_ROLES.WARRANTY_ADMIN), (authorized) => authorized);
export const selectFleetPricingRulesEditor = createSelector(hasRole(AUTH_ROLES.FPR_EDITOR), (authorized) => authorized);
export const selectCancelApptsVisits = createSelector(hasRole(AUTH_ROLES.CANCEL_APPTS_VISITS), (authorized) => authorized);
export const selectFleetEditor = createSelector(hasRole(AUTH_ROLES.FLEET_EDITOR), (authorized) => authorized);
export const selectRoPriceEdit = createSelector(hasRole(AUTH_ROLES.RO_PRICE_EDIT), (authorized) => authorized);
export const selectRoPriceEditRecRepairs = createSelector(hasRole(AUTH_ROLES.RO_PRICE_EDIT_REC_REPAIRS), (authorized) => authorized);
export const selectRoPriceEditSilverRock = createSelector(hasRole(AUTH_ROLES.RO_PRICE_EDIT_SILVER_ROCK), (authorized) => authorized);
export const selectAccountManager = createSelector(hasRole(AUTH_ROLES.ACCOUNT_MANAGER), (authorized) => authorized);
export const selectHasFinanceAdmin = createSelector(hasRole(AUTH_ROLES.FINANCE_ADMIN), (authorized) => authorized);
export const selectWorpDriveAccess = createSelector(hasRole(AUTH_ROLES.WORP_DRIVE_ACCESS), (authorized) => authorized);
