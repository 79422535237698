import { formatTimestamp } from 'src/utils/formatter';
import { AppointmentStatus } from 'src/AdminApp/models/enums';
import React from 'react';
function useAppointmentInfo({ appointmentResolution, created, modified, canceledBy, createdBy, otherResolution, status, bookedBy }) {
    let bookedByMessage;
    if (bookedBy) {
        bookedByMessage = `Booked by: ${bookedBy} ${created ? ` at ${formatTimestamp(created)}` : ''}`;
    }
    const createdByMessage = `Created by: ${createdBy} ${created ? ` at ${formatTimestamp(created)}` : ''}`;
    if (status !== AppointmentStatus.CANCELED) {
        return <div>{bookedByMessage || createdByMessage}</div>;
    }
    let canceledByMessage;
    if (!appointmentResolution) {
        canceledByMessage = `Canceled by: ${canceledBy} at ${formatTimestamp(modified)}`;
    }
    else {
        const { issuer, reason, specificReason } = appointmentResolution;
        const specific = specificReason ? ` - ${specificReason}` : '';
        const other = otherResolution ? `: ${otherResolution}` : '';
        const reasons = `Reason: ${issuer} - ${reason}${specific}${other}`;
        canceledByMessage = `Canceled by: ${canceledBy} at ${formatTimestamp(modified)} ${reasons}`;
    }
    return (<>
      {bookedByMessage || createdByMessage}
      <br />
      <br />
      {canceledByMessage}
    </>);
}
export default useAppointmentInfo;
