import { handleActions } from 'redux-actions';
import { ActionCreators } from './actions';
const DEFAULT_STATE = {
    marketingSources: [],
    vendorAddresses: undefined,
    allRepairs: undefined,
};
const moveOtherToEnd = (marketingSources) => marketingSources
    .filter((x) => x.marketingName !== 'other')
    .concat(marketingSources.filter((x) => x.marketingName === 'other'));
const successLoadMarketingSourcesReducer = (state, action) => {
    const marketingSources = action.payload || [];
    const marketingSourcesById = {};
    marketingSources.forEach((source) => {
        marketingSourcesById[source.id] = source;
    });
    return Object.assign(Object.assign({}, state), { marketingSources: moveOtherToEnd(marketingSources), marketingSourcesById });
};
const successLoadPaymentTypesReducer = (state, action) => {
    const paymentTypes = action.payload || [];
    const paymentTypesByName = {};
    paymentTypes.forEach((type) => {
        paymentTypesByName[type.displayName] = type;
    });
    return Object.assign(Object.assign({}, state), { paymentTypes: moveOtherToEnd(paymentTypes), paymentTypesByName });
};
const successLoadPaymentSubTypesReducer = (state, action) => {
    const paymentSubTypes = action.payload || [];
    const paymentSubTypesById = {};
    paymentSubTypes.forEach((subType) => {
        paymentSubTypesById[subType.id] = subType;
    });
    return Object.assign(Object.assign({}, state), { paymentSubTypes: moveOtherToEnd(paymentSubTypes), paymentSubTypesById });
};
const successDiscountReasonTypes = (state, action) => {
    return Object.assign(Object.assign({}, state), { discountReasonTypes: action.payload });
};
const successVendorAddressReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { vendorAddresses: action.payload });
};
const successAllRepairsReducer = (state, action) => {
    return Object.assign(Object.assign({}, state), { allRepairs: action.payload });
};
const reducers = handleActions({
    [ActionCreators.LOAD_MARKETING_SOURCES.SUCCESS.type]: successLoadMarketingSourcesReducer,
    [ActionCreators.LOAD_PAYMENT_TYPES.SUCCESS.type]: successLoadPaymentTypesReducer,
    [ActionCreators.LOAD_PAYMENT_SUB_TYPES.SUCCESS.type]: successLoadPaymentSubTypesReducer,
    [ActionCreators.GET_DISCOUNT_REASON_TYPES.SUCCESS.type]: successDiscountReasonTypes,
    [ActionCreators.GET_VENDOR_ADDRESS_AUTOCOMPLETE.SUCCESS.type]: successVendorAddressReducer,
    [ActionCreators.GET_ALL_REPAIRS.SUCCESS.type]: successAllRepairsReducer,
}, DEFAULT_STATE);
export default reducers;
