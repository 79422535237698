import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const UploaderInput = styled.input `
  display: none;
`;
export const UploaderContainer = styled.div `
  display: flex;
  position: relative;
  margin-right: ${({ hasAssets }) => (hasAssets ? '20px' : '0')};
`;
export const ProgressContainer = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const UploaderImage = styled.img `
  width: ${({ hasAssets }) => (!hasAssets ? '100px' : '81px')};
  background-repeat: no-repeat;
  border-image-width: 0;
  margin-bottom: 20px;
`;
export const UploaderLabel = styled.label `
  font-size: 20px;
  font-weight: 800;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  border: ${({ hasAssets }) => hasAssets ? 'dashed 2px rgba(44, 86, 221, 0.3)' : 'none'};

  width: 340px;
  height: 340px;

  span > span {
    color: ${colors.BLUE};
  }
`;
