import { startCase, toLower } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Select, Popover } from 'src/components/mui';
import { Checkbox } from 'src/components/SimpleCheckbox';
import { Item, Actions, ActionButton } from './StatusSelect.styles';
const StatusSelect = ({ status = [], onChange, disabled, }) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [innerStatus, setInnerStatus] = useState([]);
    useEffect(() => {
        setInnerStatus(status);
    }, [status]);
    const handleChange = (value) => {
        if (innerStatus.includes(value)) {
            setInnerStatus(innerStatus.filter((s) => s !== value));
        }
        else {
            setInnerStatus([...innerStatus, value]);
        }
    };
    const handleClear = () => {
        setInnerStatus([]);
        setOpen(false);
        setAnchorEl(null);
        onChange([]);
    };
    return (<>
      <Select labelId="status-select-label" id="status-select" value={10} style={{ minWidth: 160 }} label="Age" disabled={disabled} MenuProps={{
            style: {
                opacity: 0,
            },
        }} open={open} onClick={(event) => {
            if (disabled)
                return;
            setOpen(!open);
            setAnchorEl(event === null || event === void 0 ? void 0 : event.currentTarget);
        }} renderValue={() => {
            const s = innerStatus !== null && innerStatus !== void 0 ? innerStatus : [];
            return (innerStatus !== null && innerStatus !== void 0 ? innerStatus : [])
                .map((s) => startCase(toLower(s).replaceAll('_', ' ')))
                .join(', ');
        }}></Select>
      <Popover open={open} anchorEl={anchorEl} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }} transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }} onClose={() => {
            setOpen(false);
            setAnchorEl(null);
            setInnerStatus(status);
        }} style={{ minWidth: 260 }}>
        {/* <Item onClick={() => handleChange('NEEDS_REVIEW')}>
          <Checkbox checked={innerStatus.includes('NEEDS_REVIEW')} /> Needs
          Review
        </Item>
        <Item onClick={() => handleChange('READY')}>
          <Checkbox checked={innerStatus.includes('READY')} /> Ready
        </Item>*/}
        <Item>
          <Checkbox checked/> Closed
        </Item>
        <Actions>
          {/*<ActionButton onClick={handleClear}>Reset</ActionButton>*/}
          <ActionButton onClick={() => {
            setOpen(false);
            setAnchorEl(null);
            onChange(innerStatus);
        }} isMain>
            Apply
          </ActionButton>
        </Actions>
      </Popover>
    </>);
};
export default StatusSelect;
