import React from 'react';
import { createMenuItems } from 'src/AdminApp/components/form/utils';
import MilesIn from 'src/AdminApp/containers/vehicle/MilesIn';
import { ResidualValue } from 'src/AdminApp/models/enums';
import { Grid, TextField } from 'src/components/mui';
import { DrawerFormRow } from 'src/components/SimpleDrawer';
import CarSelectResponsibleParty from './CarSelectResponsibleParty';
const ExtraCarFields = ({ stateVehicle, onFieldChange, isFleet, editMode, fleet, }) => {
    return (<Grid container>
      {!isFleet && editMode ? (<DrawerFormRow item xs={12}>
          <TextField variant="outlined" value={(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.residualValue) || ''} onChange={onFieldChange('residualValue')} label="Residual Value" type="text" name="residualValue" select fullWidth>
            {createMenuItems(ResidualValue, true)}
          </TextField>
        </DrawerFormRow>) : (<MilesIn stateVehicle={stateVehicle} onFieldChange={onFieldChange} isFleet={isFleet}/>)}
      {isFleet && (<>
          <DrawerFormRow item xs={12}>
            <TextField fullWidth label="Nickname" name="nickname" type="text" value={(stateVehicle === null || stateVehicle === void 0 ? void 0 : stateVehicle.nickname) || ''} variant="outlined" onChange={onFieldChange('nickname')}/>
          </DrawerFormRow>
          <DrawerFormRow item xs={12}>
            <CarSelectResponsibleParty fleet={fleet} stateVehicle={stateVehicle} onFieldChange={onFieldChange}/>
          </DrawerFormRow>
        </>)}
    </Grid>);
};
export default ExtraCarFields;
