import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
import { AddCircle, RemoveCircle } from 'src/components/mui/icons';
import React from 'react';
import ExtraTimeReasonModal from 'src/AdminApp/containers/requests/StopwatchBar/ExtraTimeReasonModal';
import ExtraMinutesModal from 'src/AdminApp/containers/requests/StopwatchBar/ExtraMinutesModal';
import CONFIG from 'src/config';
import useVisitsStopwatchBar from './useVisitsStopwatchBar';
const StopwatchContainer = styled.div `
  background-color: ${colors.GRAY_DE};
  color: ${colors.black};
  height: 34px;
  width: 100%;
  position: fixed;
  top: 56px;
  z-index: 20;
  left: 0;
  display: flex;
  font-family: ${FONT_FAMILY};
  font-weight: bold;
  line-height: 1px;
`;
const StopwatchBarSection = styled.div `
  flex-grow: 0.3333;
  display: flex;
  align-items: center;
  width: 0;
`;
/*
  This component will only render under /admin/visits/${referenceNum} url
 */
const VisitsStopwatchBar = () => {
    const { stopwatch, addingTime, timeLeft, extraTimeReasonOptions, isExtraTimeReasonModalOpened, isExtraMinutesModalOpened, isAddTimeDisabled, isDeduceTimeDisabled, extraTimeReason, extraMinutes, setExtraTimeReason, setExtraMinutes, onExtraTimeRequestSubmit, setAddingTime, setIsExtraMinutesModalOpened, setIsExtraTimeReasonModalOpened, useComputedAvailableTime, repairRequest, isMobile, isFleetView, } = useVisitsStopwatchBar();
    const isFleetVisitRequest = !!(repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.visit) || isFleetView;
    if (!isFleetVisitRequest || CONFIG.ENABLE_VISITS_STOPWATCH_DISPLAY != 'true')
        return null;
    if (isMobile &&
        ((stopwatch !== undefined && (stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning)) ||
            useComputedAvailableTime)) {
        return (<>
        <StopwatchContainer>
          <StopwatchBarSection onClick={() => {
                if (isDeduceTimeDisabled ||
                    stopwatch === undefined ||
                    !(stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning)) {
                    return;
                }
                setAddingTime(false);
                setIsExtraMinutesModalOpened(true);
            }}>
            <RemoveCircle style={{ marginLeft: '16px' }} fontSize="small"/>
            <span style={{ fontSize: '13px' }}>Deduct Time</span>
          </StopwatchBarSection>
          <StopwatchBarSection style={{
                flexDirection: 'column',
            }}>
            <span style={{
                height: '50%',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
            }}>
              Visit Timer
            </span>
            <span style={{ height: '50%', display: 'flex', alignItems: 'center' }}>
              {timeLeft}{' '}
            </span>
          </StopwatchBarSection>
          <StopwatchBarSection style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '16px',
            }} onClick={() => {
                if (isAddTimeDisabled ||
                    stopwatch === undefined ||
                    !(stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning)) {
                    return;
                }
                setAddingTime(true);
                setExtraTimeReason(undefined);
                setIsExtraTimeReasonModalOpened(true);
            }}>
            <AddCircle fontSize="small"/>
            Add Time
          </StopwatchBarSection>
        </StopwatchContainer>
        <ExtraTimeReasonModal isOpen={isExtraTimeReasonModalOpened} setIsOpen={setIsExtraTimeReasonModalOpened} extraTimeReasons={extraTimeReasonOptions} onValueChange={setExtraTimeReason} disableSubmit={extraTimeReason === undefined} onSubmit={() => setIsExtraMinutesModalOpened(true)}/>
        <ExtraMinutesModal isOpen={isExtraMinutesModalOpened} setIsOpen={setIsExtraMinutesModalOpened} addingTime={addingTime} onValueChange={setExtraMinutes} disableSubmit={extraMinutes <= 0} onSubmit={onExtraTimeRequestSubmit}/>
      </>);
    }
    else {
        return null;
    }
};
export default VisitsStopwatchBar;
