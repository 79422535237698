export const cardSvgByType = (cardType) => {
    switch (cardType) {
        case 'american_express':
            return '/img/svg/payment/cards/Amex.svg';
        case 'diners_club':
            return '/img/svg/payment/cards/DinersClub.svg';
        case 'discover':
            return '/img/svg/payment/cards/Discover.svg';
        case 'jcb':
            return '/img/svg/payment/cards/JCB.svg';
        case 'master':
            return '/img/svg/payment/cards/Mastercard.svg';
        case 'visa':
            return '/img/svg/payment/cards/Visa.svg';
        case 'affirm':
            return '/img/svg/payment/affirm.svg';
        default:
            return '/img/svg/payment/cards/CreditCard.svg';
    }
};
