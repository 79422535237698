import { NotesEntityTypes, NotesTypes } from './constants';
export const getTypeValues = (data, type) => data.filter((item) => {
    if (type === 'REPAIR') {
        return item.entityType === NotesEntityTypes.SUGGESTED_REPAIR;
    }
    return (item.entityType === NotesEntityTypes.PARTS_PRICES ||
        item.entityType === NotesEntityTypes.FLUID_PRICES ||
        item.entityType === NotesEntityTypes.LABOR);
});
export const renderNoteContent = (note) => {
    if (note.type !== NotesTypes.CONCERN_CAUSE_CORRECTION) {
        return note.content;
    }
    const causeMatch = note.content.match(/--CAUSE--\s*(.*?)\s*--/s);
    const correctionMatch = note.content.match(/--CORRECTION--\s*(.*)/s);
    const cause = causeMatch ? causeMatch[1].trim() : '';
    const correction = correctionMatch ? correctionMatch[1].trim() : '';
    return `Cause: \n${cause}\n\nCorrection:\n${correction}`;
};
