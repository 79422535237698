import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { CircularProgress, Typography } from 'src/components/mui';
import { DATE_YMD_LONG_TIME_FORMAT, MONTH_AND_DAY_FORMAT, WEEK_DAY_SHORT_FORMAT, } from 'src/timeConstants';
import { SlotRow } from './styles';
import TimeSlot from './TimeSlot';
const Wrapper = styled('div') `
  margin-top: 24px;
`;
const DateContainer = styled('div') `
  &:not(:last-of-type) {
    margin-bottom: 56px;
  }
`;
const DateTitle = styled(Typography) `
  font-weight: 700;
`;
const Day = styled('span') `
  font-weight: normal;
`;
const TimeSlots = ({ loading, slots, mechanicFilterEnabled, tz, }) => {
    if (loading)
        return (<Wrapper>
        <CircularProgress />
      </Wrapper>);
    const [now, setNow] = useState(moment().utc().tz(tz));
    useEffect(() => {
        setNow(moment().utc().tz(tz));
    }, [tz]);
    const verifyTimeSlot = (date, time) => {
        const timeSlot = (slots !== null && slots !== void 0 ? slots : {})[date][time];
        if ('vanId' in timeSlot || 'mechanics' in timeSlot) {
            const slotDate = moment(`${date} ${time}`, DATE_YMD_LONG_TIME_FORMAT).tz(tz, true);
            return now.isBefore(slotDate, 'hour');
        }
        return false;
    };
    const dates = Object.keys(slots || []).filter((date) => Object.keys((slots !== null && slots !== void 0 ? slots : {})[date])
        .reverse()
        .some((time) => verifyTimeSlot(date, time)));
    if (isEmpty(slots) || isEmpty(dates))
        return (<Wrapper>
        <Typography variant="h5">No Time Slots Available</Typography>
      </Wrapper>);
    return (<Wrapper>
      {dates.map((key, index) => {
            const date = moment(key).tz(tz, true);
            const timeSlots = (slots !== null && slots !== void 0 ? slots : {})[key];
            const isToday = date.isSame(moment().utc().tz(tz), 'day');
            const validTimeSlots = Object.keys(timeSlots).filter((time) => verifyTimeSlot(key, time));
            return (<DateContainer key={key}>
            <SlotRow>
              <DateTitle variant="body2">
                {`${date.format(MONTH_AND_DAY_FORMAT)} `}
                <Day>
                  {!isToday ? date.format(WEEK_DAY_SHORT_FORMAT) : 'Today'}
                </Day>
              </DateTitle>
            </SlotRow>
            {validTimeSlots.map((time, i) => (<TimeSlot key={time} date={key} isFirstAvailable={i === index && index === 0} slot={slots ? slots[key][time] : undefined} time={time} mechanicFilterEnabled={mechanicFilterEnabled}/>))}
          </DateContainer>);
        })}
    </Wrapper>);
};
export default TimeSlots;
