import createAPIAction from 'src/modules/api';
import createDefaultActionCreators from 'src/utils/createActions';
import API_URLS from 'src/AdminApp/modules/api/urls';
export const ActionCreators = {
    GET_EXTRA_TIME_REQUEST_REASONS: createDefaultActionCreators('GET_EXTRA_TIME_REQUEST_REASONS'),
};
export const getExtraTimeRequestReasons = (isVisit = false) => createAPIAction({
    types: [
        ActionCreators.GET_EXTRA_TIME_REQUEST_REASONS.FETCH(),
        ActionCreators.GET_EXTRA_TIME_REQUEST_REASONS.SUCCESS(),
        ActionCreators.GET_EXTRA_TIME_REQUEST_REASONS.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.EXTRA_TIME_REQUEST_REASONS(isVisit),
    method: 'GET',
});
