import { omit } from 'lodash';
// Map methods transform raw payload from the API
// into the desired format used by our React app.
export const mapRepairRequest = (request, options = {}) => {
    const mappedRequest = omit(request, [
        'consumerContactInfo',
    ]);
    return Object.assign({ request: Object.assign(Object.assign({}, mappedRequest), { status: mappedRequest.status }), contactInfo: Object.assign({}, request.consumerContactInfo) }, options);
};
export const mapAppointment = (appointment) => {
    return Object.assign({}, appointment);
};
export const mapRepairOrder = (order) => {
    return Object.assign({}, order);
};
export const mapReferenceNumsToIds = (referenceNumsToIds, requests) => {
    return requests.reduce((m, r) => {
        m[r.referenceNum] = r.id;
        return m;
    }, Object.assign({}, referenceNumsToIds));
};
export const mapRepairRequestReferenceNumsToIds = (referenceNumsToIds, dashboardItems) => {
    return dashboardItems.reduce((m, r) => {
        m[r.referenceNum] = r.repairRequestId;
        return m;
    }, Object.assign({}, referenceNumsToIds));
};
export const mapConsumer = (user) => ({
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    phone: user.phone && user.phone.toString(),
    email: user.email && user.email.toString(),
    firstName: user.firstName,
    lastName: user.lastName,
    termsAccepted: user.termsAccepted || false,
    zendeskUserId: user.zendeskUserId,
    fleet: user.fleet || false,
    fleetId: user.fleetId,
    address: user.address
        ? `${user.address.streetLine1}, ${user.address.city}, ${user.address.state}, ${user.address.zip}`
        : '',
    marketingName: user.marketingName || '',
    marketingSourceId: user.marketingSourceId || null,
    fullAddress: user.address,
    firebaseUserOutOfSync: user.firebaseUserOutOfSync,
});
export const mapUpdatedConsumerContactInfo = (user) => ({
    name: user.name,
    phone: user.phone,
    email: user.email,
});
export const mapRequestCar = (car) => {
    return (car && {
        id: car.id,
        consumerCarId: car.consumerCarId,
        year: car.carYear || null,
        make: car.make || '',
        model: car.model || '',
        trim: car.carTrim || '',
        engineId: car.engineId || '',
        engineDescription: car.engineDescription || '',
        mileage: car.mileage,
        mileageOut: car.mileageOut,
        plateNumber: car.plateNumber || '',
        plateNumberState: car.plateNumberState || null,
        vin: car.vin || '',
        residualValue: car.residualValue,
        photos: car.photos || [],
        mileageNotAvailable: car.mileageNotAvailable,
        subscriptions: car.subscriptions,
    });
};
export const mapConsumerCar = (car) => {
    return (car && {
        id: car.id,
        year: car.year || null,
        make: car.make || '',
        model: car.model || '',
        trim: car.trim || '',
        engineId: car.engineId || '',
        engineDescription: car.engineDescription || '',
        mileage: car.mileage || null,
        plateNumber: car.plateNumber || '',
        plateNumberState: car.plateNumberState || '',
        vin: car.vin || '',
        subscriptions: car.subscriptions,
    });
};
export const mapPagination = (response) => {
    return (response.pageable && {
        totalElements: response.totalElements,
        totalPages: response.totalPages,
        pageSize: response.pageable.pageSize,
        pageNumber: response.pageable.pageNumber,
        offset: response.pageable.offset,
    });
};
export const mapClientShopInvoiceMatch = (item) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
    return item && {
        id: item.id,
        shopId: (_c = (_b = (_a = item.clientShopInvoice) === null || _a === void 0 ? void 0 : _a.clientShop) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null,
        shopIspId: (_f = (_e = (_d = item.clientShopInvoice) === null || _d === void 0 ? void 0 : _d.clientShop) === null || _e === void 0 ? void 0 : _e.ispId) !== null && _f !== void 0 ? _f : null,
        shopName: (_j = (_h = (_g = item.clientShopInvoice) === null || _g === void 0 ? void 0 : _g.clientShop) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : null,
        invoiceNumber: (_l = (_k = item.clientShopInvoice) === null || _k === void 0 ? void 0 : _k.invoiceNumber) !== null && _l !== void 0 ? _l : null,
        dateRepairCompleted: (_o = (_m = item.clientShopInvoice) === null || _m === void 0 ? void 0 : _m.completedAtLocal) !== null && _o !== void 0 ? _o : null,
        repairRequestStatus: (_q = (_p = item.clientShopInvoice) === null || _p === void 0 ? void 0 : _p.repairRequestStatus) !== null && _q !== void 0 ? _q : null,
        repairRequestState: (_s = (_r = item.clientShopInvoice) === null || _r === void 0 ? void 0 : _r.repairRequestState) !== null && _s !== void 0 ? _s : null,
        nameOfCustomer: item.customerName,
        licensePlate: item.plateNumber,
        vin: item.vin,
        vehicleYearMakeModel: `${item.year} ${item.make} ${item.trim}`,
        repairItemsCount: item.repairItemsCount,
        totalAmount: (_u = (_t = item.clientShopInvoice) === null || _t === void 0 ? void 0 : _t.totalOrder) !== null && _u !== void 0 ? _u : null,
        salesTax: (_w = (_v = item.clientShopInvoice) === null || _v === void 0 ? void 0 : _v.totalTax) !== null && _w !== void 0 ? _w : null,
        hazmatExpense: (_y = (_x = item.clientShopInvoice) === null || _x === void 0 ? void 0 : _x.totalHazWaste) !== null && _y !== void 0 ? _y : null,
        saleOfTires: (_0 = (_z = item.clientShopInvoice) === null || _z === void 0 ? void 0 : _z.totalTireFee) !== null && _0 !== void 0 ? _0 : null,
        repairRequestId: item.repairRequestId,
        percentageMatch: ((_1 = item.confidence) !== null && _1 !== void 0 ? _1 : 0) * 100,
        subtotal: ((_3 = (_2 = item.clientShopInvoice) === null || _2 === void 0 ? void 0 : _2.totalOrder) !== null && _3 !== void 0 ? _3 : 0) -
            ((_5 = (_4 = item.clientShopInvoice) === null || _4 === void 0 ? void 0 : _4.totalTax) !== null && _5 !== void 0 ? _5 : 0) || null,
        validationStatus: item.validationStatus,
    };
};
export const mapClientShopInvoiceMatchArray = (clientShopInvoiceMatchList) => {
    return clientShopInvoiceMatchList && clientShopInvoiceMatchList.length
        ? clientShopInvoiceMatchList.map(mapClientShopInvoiceMatch)
        : [];
};
export const mapClientShops = (item) => {
    var _a, _b, _c, _d;
    return item && {
        customId: item.customId,
        shopName: item.name,
        shopZip: item.zipCode,
        shopPhone: item.phone,
        systemsIntegratedId: (_b = (_a = item.type) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
        systemsIntegrated: (_d = (_c = item.type) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : null,
        installationDate: item.installedOn,
        lastInvoiceDateTime: item.lastInvoiceProcessedAt,
        status: item.status,
        ispId: item.ispId,
    };
};
export const mapServiceArea = (item) => item && {
    id: item.id,
    name: item.name,
    zipCodes: item.zipCodes,
    regionId: item.regionId,
    region: item.region,
    deliveryVanServiceAreas: item.deliveryVanServiceAreas,
    marketingLaunchDate: item.marketingLaunchDate,
    bookingEffectiveDate: item.bookingEffectiveDate,
    archiveDate: item.archiveDate,
};
export const mapMetro = (item) => item &&
    {
        id: item.id,
        territory: item.territory,
        name: item.name,
        archived: item.archived,
        regions: item.regions,
    };
export const mapRegion = (item) => item &&
    {
        id: item.id,
        skeduloRegionId: item.skeduloRegionId,
        metro: item.metro,
        name: item.name,
        serviceAreas: item.serviceAreas,
        archived: item.archived,
    };
export const mapSkeduloRegion = (item) => item && {
    uid: item.UID,
    name: item.Name,
    timezone: item.Timezone,
};
export const mapDeliveryVan = (item) => item &&
    {
        id: item.id,
        regionId: item.regionId,
        name: item.name,
        type: item.type,
        ispId: item.ispId,
        skeduloResourceId: item.skeduloResourceId,
        confirmedRepairRequests: item.confirmedRepairRequests,
        namedAfter: item.namedAfter,
        vehicleNumber: item.vehicleNumber,
        vin: item.vin,
        launchDate: item.launchDate,
        skills: item.skills,
        deliveryVanServiceAreas: item.deliveryVanServiceAreas,
        deliveryVanHomeStores: item.deliveryVanHomeStores,
    };
