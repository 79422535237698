import { flatMap, isNil, keyBy, map, mapValues, reduce, sortBy, uniqBy, } from 'lodash';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { selectMainInvoiceByWorkOrderId } from '../invoices/selectors';
export const selectRequests = (state) => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.admin) === null || _a === void 0 ? void 0 : _a.requests; };
export const selectFleets = (state) => { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.admin) === null || _a === void 0 ? void 0 : _a.fleets) !== null && _b !== void 0 ? _b : {}; };
export const selectReferenceNum = (_, props) => { var _a, _b; return (_b = (_a = props === null || props === void 0 ? void 0 : props.match) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.referenceNum; };
export const selectRepairs = createSelector(selectRequests, (requests) => (requests === null || requests === void 0 ? void 0 : requests.repairs) || []);
export const selectReferenceNumToIds = createSelector(selectRequests, (requests) => requests.referenceNumToIds || {});
export const lookupRequestIdByReferenceNum = (referenceNum) => createSelector(selectReferenceNumToIds, (referenceNumToIds) => referenceNumToIds[referenceNum]);
export const selectAllRepairRequests = createSelector(selectRequests, (requests) => requests === null || requests === void 0 ? void 0 : requests.byId);
export const selectRequestData = createSelector(selectAllRepairRequests, selectReferenceNum, (requests, referenceNum) => {
    return requests === null || requests === void 0 ? void 0 : requests[referenceNum];
});
export const selectRequestSnapshotsByReferenceNum = (referenceNum) => createSelector(selectRequests, (requests) => { var _a; return (_a = requests === null || requests === void 0 ? void 0 : requests.snapshots) === null || _a === void 0 ? void 0 : _a[referenceNum]; });
export const selectRequestSnapshots = createSelector(selectRequests, selectReferenceNum, (requests, referenceNum) => { var _a; return (_a = requests === null || requests === void 0 ? void 0 : requests.snapshots) === null || _a === void 0 ? void 0 : _a[referenceNum]; });
export const selectRequestDataByReferenceNum = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => requests === null || requests === void 0 ? void 0 : requests[referenceNum]);
export const selectRequestRequestReferenceNum = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a, _b; return referenceNum ? (_b = (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.request) !== null && _b !== void 0 ? _b : null : null; });
export const selectRequestRequestByReferenceNum = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => requests[referenceNum].request);
export const selectRequestContactInfoReferenceNum = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a, _b; return referenceNum ? (_b = (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.contactInfo) !== null && _b !== void 0 ? _b : {} : null; });
export const selectRepairRequestWithReferenceNum = createSelector(selectAllRepairRequests, (_, referenceNum) => referenceNum, (requests, referenceNum) => { var _a; return referenceNum ? (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.request : null; });
export const selectOrdersByReferenceNum = (referenceNum) => createSelector(selectRequestDataByReferenceNum(referenceNum), (request) => { var _a; return (_a = request === null || request === void 0 ? void 0 : request.orders) !== null && _a !== void 0 ? _a : []; });
export const selectRequest = createSelector(selectRequestData, (requestData) => { var _a; return (_a = requestData === null || requestData === void 0 ? void 0 : requestData.request) !== null && _a !== void 0 ? _a : null; });
export const selectRequestHistory = createSelector(selectRequestData, (requestData) => { var _a; return (_a = requestData === null || requestData === void 0 ? void 0 : requestData.history) !== null && _a !== void 0 ? _a : null; });
export const selectContactInfo = createSelector(selectRequestData, (requestData) => { var _a; return (_a = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) !== null && _a !== void 0 ? _a : null; });
export const selectAdvisorCallRequestedDate = createSelector(selectRequest, (requestData) => { var _a; return (_a = requestData === null || requestData === void 0 ? void 0 : requestData.advisorCallRequestedDate) !== null && _a !== void 0 ? _a : null; });
export const selectContactInfoByReferenceNum = (referenceNum) => createSelector(selectRequestDataByReferenceNum(referenceNum), (request) => { var _a; return (_a = request === null || request === void 0 ? void 0 : request.contactInfo) !== null && _a !== void 0 ? _a : null; });
export const selectFleetIdByReferenceNum = (referenceNum) => createSelector(selectRequestDataByReferenceNum(referenceNum), (request) => { var _a, _b; return (_b = (_a = request === null || request === void 0 ? void 0 : request.request) === null || _a === void 0 ? void 0 : _a.fleetId) !== null && _b !== void 0 ? _b : null; });
export const selectRequestZip = createSelector(selectRequest, (request) => { var _a; return (_a = request === null || request === void 0 ? void 0 : request.zip) !== null && _a !== void 0 ? _a : null; });
export const selectRequestContactInfo = createSelector(selectRequestData, (requestData) => {
    var _a;
    return (_a = requestData === null || requestData === void 0 ? void 0 : requestData.contactInfo) !== null && _a !== void 0 ? _a : null;
});
export const selectRequestContactInfoByReferenceNum = createSelector(selectAllRepairRequests, (requests) => function contactInfoFinder(referenceNumber) {
    var _a, _b;
    return (_b = (_a = requests === null || requests === void 0 ? void 0 : requests[referenceNumber]) === null || _a === void 0 ? void 0 : _a.contactInfo) !== null && _b !== void 0 ? _b : null;
});
export const selectRequestCar = createSelector(selectRequestData, (requestData) => requestData === null || requestData === void 0 ? void 0 : requestData.car);
export const selectRequestDiagnosis = createSelector(selectRequestData, (requestData) => requestData === null || requestData === void 0 ? void 0 : requestData.diagnosis);
export const selectRequestCoreCharges = createSelector(selectRequestDiagnosis, (diagnosis) => {
    var _a;
    const coreCharges = [];
    (_a = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs.forEach((repair) => {
        repair.price[0].parts.forEach((part) => {
            if (part.coreChargeExpected && part.coreChargeCost) {
                coreCharges.push(part);
            }
        });
    });
    return coreCharges;
});
export const selectRepairFitments = createSelector(selectRequestDiagnosis, (diagnosis) => {
    var _a, _b, _c;
    if (isNil((_a = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs)) {
        return undefined;
    }
    const partFluidFitments = [];
    (_c = (_b = diagnosis === null || diagnosis === void 0 ? void 0 : diagnosis.diagnosisServices) === null || _b === void 0 ? void 0 : _b.knownRepairs) === null || _c === void 0 ? void 0 : _c.forEach((repair) => {
        var _a, _b;
        (_a = repair.price[0].parts) === null || _a === void 0 ? void 0 : _a.forEach((part) => {
            var _a, _b;
            if (((_a = part.fitments) !== null && _a !== void 0 ? _a : []).length > 0) {
                partFluidFitments.push({
                    id: part.id,
                    name: repair.name,
                    partFluidName: part.name,
                    price: part.price,
                    partOrFluid: 'PART',
                    fitments: (_b = part.fitments) !== null && _b !== void 0 ? _b : [],
                });
            }
        });
        (_b = repair.price[0].fluids) === null || _b === void 0 ? void 0 : _b.forEach((fluid) => {
            var _a, _b;
            if (((_a = fluid.fitments) !== null && _a !== void 0 ? _a : []).length > 0 && fluid.id) {
                partFluidFitments.push({
                    id: fluid.id,
                    name: repair.name,
                    partFluidName: fluid.name,
                    price: fluid.price,
                    partOrFluid: 'FLUID',
                    fitments: (_b = fluid.fitments) !== null && _b !== void 0 ? _b : [],
                });
            }
        });
    });
    return partFluidFitments;
});
export const selectPrimaryResponsibleParty = createSelector(selectRequestData, (requestData) => requestData === null || requestData === void 0 ? void 0 : requestData.primaryResponsibleParty);
export const selectRequestAppointments = createSelector(selectRequestData, (requestData) => { var _a; return (_a = requestData === null || requestData === void 0 ? void 0 : requestData.appointments) !== null && _a !== void 0 ? _a : []; });
export const selectRequestOrders = createSelector(selectRequestData, (requestData) => { var _a; return (_a = requestData === null || requestData === void 0 ? void 0 : requestData.orders) !== null && _a !== void 0 ? _a : []; });
export const selectRequestLogos = createSelector(selectRequestData, (requestData) => { var _a; return (_a = requestData === null || requestData === void 0 ? void 0 : requestData.logo) !== null && _a !== void 0 ? _a : {}; });
export const selectRequestLogoUrl = createSelector(selectRequestLogos, (logos) => { var _a, _b; return ((_a = logos === null || logos === void 0 ? void 0 : logos.baseUrl) !== null && _a !== void 0 ? _a : '') + ((_b = logos === null || logos === void 0 ? void 0 : logos.logoLargeUrl) !== null && _b !== void 0 ? _b : ''); });
export const selectRepairMeasurements = createSelector(selectRequestData, (requestData) => { var _a, _b; return (_b = (_a = requestData.measurementData) === null || _a === void 0 ? void 0 : _a.measurements) !== null && _b !== void 0 ? _b : []; });
export const selectRepairMeasurementsData = createSelector(selectRequestData, (requestData) => { var _a; return (_a = requestData.measurementData) !== null && _a !== void 0 ? _a : {}; });
export const selectQueuePosition = createSelector(selectRequestData, (requestData) => { var _a; return (_a = requestData.queuePosition) !== null && _a !== void 0 ? _a : -1; });
export const selectRequestAppointmentInfo = createSelector(selectRequestZip, selectRequestAppointments, (reqZip, appointments) => ({
    zip: reqZip,
    appointments,
}));
export const selectRequestActiveAppointment = createSelector(selectRequest, (request) => request && request.activeAppointment);
export const selectRequestDashboard = createSelector(selectRequests, (requests) => requests === null || requests === void 0 ? void 0 : requests.dashboard);
export const selectRequestArray = createSelector(selectRequestDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.data) !== null && _a !== void 0 ? _a : []; });
export const selectRequestParams = createSelector(selectRequestDashboard, (dashboard) => dashboard === null || dashboard === void 0 ? void 0 : dashboard.params);
export const selectRequestPagination = createSelector(selectRequestDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectRequestFetching = createSelector(selectRequestDashboard, (dashboard) => { var _a; return (_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard._isFetching) !== null && _a !== void 0 ? _a : false; });
export const selectVehicleByRefNum = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a, _b; return (_b = (requests && ((_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.car))) !== null && _b !== void 0 ? _b : {}; });
export const selectVehicleSubscriptionByRefNum = (referenceNum) => createSelector(selectVehicleByRefNum(referenceNum), (car) => {
    var _a;
    return (_a = car.subscriptions) === null || _a === void 0 ? void 0 : _a.find((s) => {
        return moment().isBetween(moment(s.currentTermStart).utc(true), moment(s.currentTermEnd).utc(true));
    });
});
export const selectSuggestedRepairsArrayByRefNum = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => {
    var _a, _b, _c, _d;
    return map((_d = (_c = (_b = (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.diagnosis) === null || _b === void 0 ? void 0 : _b.diagnosisServices) === null || _c === void 0 ? void 0 : _c.knownRepairs) !== null && _d !== void 0 ? _d : []);
});
export const selectSuggestedRepairsArrayByRefNumWithCustomRepair = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => {
    var _a, _b, _c, _d;
    return map((_d = (_c = (_b = (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.diagnosis) === null || _b === void 0 ? void 0 : _b.diagnosisServices) === null || _c === void 0 ? void 0 : _c.customRequests) !== null && _d !== void 0 ? _d : []);
});
export const selectSuggestedRepairsIdsArrayByRefNum = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => {
    var _a, _b, _c, _d;
    return map((_d = (_c = (_b = (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.diagnosis) === null || _b === void 0 ? void 0 : _b.diagnosisServices) === null || _c === void 0 ? void 0 : _c.knownRepairs) !== null && _d !== void 0 ? _d : [], (repair) => repair.id);
});
export const selectSuggestedRepairsNodeIdsArrayByRefNum = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => {
    var _a, _b, _c, _d;
    return map((_d = (_c = (_b = (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.diagnosis) === null || _b === void 0 ? void 0 : _b.diagnosisServices) === null || _c === void 0 ? void 0 : _c.knownRepairs) !== null && _d !== void 0 ? _d : [], (repair) => repair.nodeId);
});
export const selectRepairsStringByRefNum = (referenceNum) => createSelector(selectSuggestedRepairsIdsArrayByRefNum(referenceNum), (repairsArray) => reduce(repairsArray, (string, repair) => {
    let str = string;
    if (string !== '') {
        str += ',';
    }
    return str + repair.toString();
}, ''));
export const selectRecommendedRepairs = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a; return (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.recommendedRepairs; });
export const selectScheduledRepairs = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a; return (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.scheduledRepairs; });
export const selectCreatedRepairs = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a; return (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.createdRepairs; });
export const selectRejectedRepairs = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a; return (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.rejectedRepairs; });
export const selectRecommendedSubTotal = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a; return (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.recommendedRepairsSubTotal; });
export const selectRecommendedRepairsIsSubmitting = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a, _b; return (_b = (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a._isSubmitting) !== null && _b !== void 0 ? _b : false; });
export const selectRelatedParts = createSelector(selectRequestData, (requestData) => { var _a; return (_a = requestData === null || requestData === void 0 ? void 0 : requestData.relatedPartsBySuggestedRepair) !== null && _a !== void 0 ? _a : null; });
export const selectFleet = ({ fleetId }) => createSelector(selectFleets, (fleets) => {
    if (fleetId) {
        return fleets.byId[fleetId];
    }
    return null;
});
export const selectIsMpiAlertShowing = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a; return (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.isMpiAlertShowing; });
export const selectShowMpiSentToCustomerAlert = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => { var _a; return (_a = requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.showMpiSentToCustomerAlert; });
export const selectDashboardDefaultFilters = () => ({
    createdFrom: moment().subtract(7, 'days').utc().toISOString(),
});
export const selectResponsiblePartyTypeModal = createSelector(selectRequests, (requests) => requests === null || requests === void 0 ? void 0 : requests.responsiblePartyTypeModal);
export const selectWorkOrderItemResponsiblePartiesGroupedByRepairId = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => {
    var _a, _b, _c;
    return mapValues(keyBy(((_c = (_b = (_a = requests === null || requests === void 0 ? void 0 : requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.diagnosis) === null || _b === void 0 ? void 0 : _b.diagnosisServices) === null || _c === void 0 ? void 0 : _c.knownRepairs) ||
        [], (x) => x.id), (x) => x.workOrderItemResponsibleParties);
});
export const selectWorkOrderItemResponsiblePartiesGroupedByDiagnosisId = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => {
    var _a, _b, _c;
    return mapValues(keyBy(((_c = (_b = (_a = requests === null || requests === void 0 ? void 0 : requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.diagnosis) === null || _b === void 0 ? void 0 : _b.diagnosisServices) === null || _c === void 0 ? void 0 : _c.diagnoses) || [], (x) => x.id), (x) => x.workOrderItemResponsibleParties);
});
export const selectWorkOrderItemResponsiblePartiesGroupedByCustomRepairId = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => {
    var _a, _b, _c;
    return mapValues(keyBy(((_c = (_b = (_a = requests === null || requests === void 0 ? void 0 : requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.diagnosis) === null || _b === void 0 ? void 0 : _b.diagnosisServices) === null || _c === void 0 ? void 0 : _c.customRequests) || [], (x) => x.id), (x) => x.workOrderItemResponsibleParties);
});
export const selectWorkOrderItemResponsiblePartiesGroupedByIds = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return mapValues(keyBy([
        ...(((_c = (_b = (_a = requests === null || requests === void 0 ? void 0 : requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.diagnosis) === null || _b === void 0 ? void 0 : _b.diagnosisServices) === null || _c === void 0 ? void 0 : _c.knownRepairs) || []),
        ...(((_f = (_e = (_d = requests === null || requests === void 0 ? void 0 : requests[referenceNum]) === null || _d === void 0 ? void 0 : _d.diagnosis) === null || _e === void 0 ? void 0 : _e.diagnosisServices) === null || _f === void 0 ? void 0 : _f.diagnoses) || []),
        ...(((_j = (_h = (_g = requests === null || requests === void 0 ? void 0 : requests[referenceNum]) === null || _g === void 0 ? void 0 : _g.diagnosis) === null || _h === void 0 ? void 0 : _h.diagnosisServices) === null || _j === void 0 ? void 0 : _j.customRequests) || []),
    ], (x) => x.id), (x) => x.workOrderItemResponsibleParties);
});
export const selectWorkOrderItemResponsibleParties = (referenceNum) => createSelector(selectAllRepairRequests, (requests) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return [
        ...(flatMap((_d = (_c = (_b = (_a = requests === null || requests === void 0 ? void 0 : requests[referenceNum]) === null || _a === void 0 ? void 0 : _a.diagnosis) === null || _b === void 0 ? void 0 : _b.diagnosisServices) === null || _c === void 0 ? void 0 : _c.knownRepairs) === null || _d === void 0 ? void 0 : _d.map((x) => x.workOrderItemResponsibleParties)) || []),
        ...(flatMap((_h = (_g = (_f = (_e = requests === null || requests === void 0 ? void 0 : requests[referenceNum]) === null || _e === void 0 ? void 0 : _e.diagnosis) === null || _f === void 0 ? void 0 : _f.diagnosisServices) === null || _g === void 0 ? void 0 : _g.diagnoses) === null || _h === void 0 ? void 0 : _h.map((x) => x.workOrderItemResponsibleParties)) || []),
        ...(flatMap((_m = (_l = (_k = (_j = requests === null || requests === void 0 ? void 0 : requests[referenceNum]) === null || _j === void 0 ? void 0 : _j.diagnosis) === null || _k === void 0 ? void 0 : _k.diagnosisServices) === null || _l === void 0 ? void 0 : _l.customRequests) === null || _m === void 0 ? void 0 : _m.map((x) => x.workOrderItemResponsibleParties)) || []),
    ];
});
export const selectWorkOrderItemResponsiblePartiesOfRepair = (referenceNum, repairId) => createSelector(selectWorkOrderItemResponsiblePartiesGroupedByRepairId(referenceNum), (payerState) => payerState === null || payerState === void 0 ? void 0 : payerState[repairId]);
export const selectWorkOrderItemResponsiblePartiesOfDiagnosis = (referenceNum, diagnosisId) => createSelector(selectWorkOrderItemResponsiblePartiesGroupedByDiagnosisId(referenceNum), (payerState) => payerState === null || payerState === void 0 ? void 0 : payerState[diagnosisId]);
export const selectWorkOrderItemResponsiblePartiesOfCustomRepair = (referenceNum, customRepairId) => createSelector(selectWorkOrderItemResponsiblePartiesGroupedByCustomRepairId(referenceNum), (payerState) => payerState === null || payerState === void 0 ? void 0 : payerState[customRepairId]);
export const selectTheOnlyOneResponsiblePartyIsPrimary = (referenceNum) => createSelector(selectWorkOrderItemResponsibleParties(referenceNum), (workOrderItemResponsibleParties) => {
    var _a;
    if (workOrderItemResponsibleParties) {
        const uniqueResponsiblePartyTypes = uniqBy(workOrderItemResponsibleParties.map((x) => { var _a; return (_a = x === null || x === void 0 ? void 0 : x.responsibleParty) === null || _a === void 0 ? void 0 : _a.responsiblePartyType; }), (x) => x === null || x === void 0 ? void 0 : x.name);
        return (uniqueResponsiblePartyTypes.length === 1 &&
            ((_a = uniqueResponsiblePartyTypes[0]) === null || _a === void 0 ? void 0 : _a.defaultPrimary));
    }
});
export const selectIsPayer3rdPartyType = (referenceNum) => createSelector(selectWorkOrderItemResponsiblePartiesGroupedByRepairId(referenceNum), (groupedWorkOrderItemResponsibleParties) => groupedWorkOrderItemResponsibleParties &&
    Object.values(groupedWorkOrderItemResponsibleParties).some((workOrderItemResponsibleParties) => workOrderItemResponsibleParties === null || workOrderItemResponsibleParties === void 0 ? void 0 : workOrderItemResponsibleParties.some((workOrderItemResponsibleParty) => {
        var _a, _b;
        return ((_b = (_a = workOrderItemResponsibleParty === null || workOrderItemResponsibleParty === void 0 ? void 0 : workOrderItemResponsibleParty.responsibleParty) === null || _a === void 0 ? void 0 : _a.responsiblePartyType) === null || _b === void 0 ? void 0 : _b.name) === '3rd Party Pay';
    })));
export const selectInvoiceHas3rdPartyType = createSelector(selectMainInvoiceByWorkOrderId, (_, props) => props, (mainInvoice) => {
    var _a, _b;
    return (_b = (_a = mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.diagnosisServices) === null || _a === void 0 ? void 0 : _a.knownRepairs) === null || _b === void 0 ? void 0 : _b.some((repair) => repair.workOrderItemResponsibleParties.some((worParty) => {
        var _a;
        return ((_a = worParty.responsibleParty.responsiblePartyType) === null || _a === void 0 ? void 0 : _a.name) ===
            '3rd Party Pay';
    }));
});
export const selectWorkOrderResponsiblePartyTypes = (referenceNum) => createSelector(selectResponsibleParties(referenceNum), (payer) => payer === null || payer === void 0 ? void 0 : payer.map((x) => x.responsiblePartyType));
export const selectResponsibleParties = (referenceNum) => createSelector(selectWorkOrderItemResponsiblePartiesGroupedByIds(referenceNum), (payer) => payer
    ? Object.values(payer).reduce((prev, cur) => {
        return cur === null || cur === void 0 ? void 0 : cur.reduce((previous, current) => {
            if (!previous
                .map((x) => x.responsiblePartyTypeId)
                .includes(current === null || current === void 0 ? void 0 : current.responsibleParty.responsiblePartyTypeId)) {
                previous.push(current === null || current === void 0 ? void 0 : current.responsibleParty);
            }
            return previous;
        }, prev);
    }, [])
    : []);
export const selectWorkOrderItemResponsiblePartyAuthorizations = (referenceNum) => createSelector(selectRequestDataByReferenceNum(referenceNum), (request) => { var _a, _b; return (_b = (_a = request === null || request === void 0 ? void 0 : request.diagnosis) === null || _a === void 0 ? void 0 : _a.workOrderResponsiblePartyAuthorizations) !== null && _b !== void 0 ? _b : []; });
export const selectAuthorizeModalOpen = createSelector(selectRequests, (requests) => { var _a; return (_a = requests === null || requests === void 0 ? void 0 : requests.authorizeModalOpen) !== null && _a !== void 0 ? _a : false; });
export const selectPaymentDrawerOpen = createSelector(selectRequests, (requests) => { var _a; return (_a = requests === null || requests === void 0 ? void 0 : requests.paymentDrawerOpen) !== null && _a !== void 0 ? _a : false; });
export const selectWarrantyQuestionsDrawerOpen = createSelector(selectRequests, (requests) => { var _a; return (_a = requests === null || requests === void 0 ? void 0 : requests.warrantyQuestionsDrawerOpen) !== null && _a !== void 0 ? _a : false; });
export const selectMeasurementsDrawerState = createSelector(selectRequests, (requests) => {
    var _a;
    return (_a = requests.measurementsDrawer) !== null && _a !== void 0 ? _a : {
        open: false,
        nodeId: null,
        required: false,
    };
});
export const selectResponsiblePartyTypes = createSelector(selectRequests, (requests) => (requests === null || requests === void 0 ? void 0 : requests.responsiblePartyTypes) || []);
export const selectResponsiblePartyTypesNotDefaultPrimary = createSelector(selectRequests, (requests) => { var _a; return ((_a = requests === null || requests === void 0 ? void 0 : requests.responsiblePartyTypes) === null || _a === void 0 ? void 0 : _a.filter((rpt) => !rpt.defaultPrimary)) || []; });
export const selectResponsiblePartiesFromType = createSelector(selectRequests, (requests) => sortBy(requests === null || requests === void 0 ? void 0 : requests.responsiblePartiesFromType, 'name'));
export const selectFourCornerModal = createSelector(selectRequests, (requests) => requests.fourCornerModal);
export const selectMobileCarDetails = createSelector(selectRequests, (requests) => requests.mobileCarDetails);
export const selectFourCornerHasPhotos = (referenceNum) => createSelector(selectRequestDataByReferenceNum(referenceNum), (request) => {
    var _a;
    const photos = (_a = request === null || request === void 0 ? void 0 : request.car) === null || _a === void 0 ? void 0 : _a.photos;
    return (photos === null || photos === void 0 ? void 0 : photos.length) > 0;
});
export const selectFourCornerFromHeader = createSelector(selectRequests, (requests) => requests.fourCornerFromHeader);
const orderDeclinedJobReason = (options) => {
    var _a, _b;
    const reasons = options === null || options === void 0 ? void 0 : options.get('REPAIRSMITH');
    if (!reasons)
        return;
    const systemReasons = reasons.get('System');
    const jobDeclinedReasons = reasons.get('RepairSmith Declines Job');
    if (!(systemReasons === null || systemReasons === void 0 ? void 0 : systemReasons.length))
        return;
    reasons.delete(systemReasons[0].reason);
    reasons.set(systemReasons[0].reason, systemReasons);
    if (!jobDeclinedReasons || !jobDeclinedReasons.length)
        return;
    reasons.delete((_a = jobDeclinedReasons[0]) === null || _a === void 0 ? void 0 : _a.reason);
    reasons.set((_b = jobDeclinedReasons[0]) === null || _b === void 0 ? void 0 : _b.reason, jobDeclinedReasons);
};
export const filterReasonsWithoutSubReasonsVisible = (options) => {
    const filteredMap = new Map(options);
    options.forEach((key, value) => {
        let keyInvisibleCount = 0;
        Array.from(key.values()).every((subItem) => {
            const subItemVisibleCount = subItem.filter((s) => s.visibility.length > 0).length;
            if (subItemVisibleCount > 0) {
                return false;
            }
            keyInvisibleCount += 1;
            return true;
        });
        if (key.size === keyInvisibleCount) {
            filteredMap.delete(value);
        }
    });
    return filteredMap;
};
export const selectResolutionsOptions = createSelector(selectRequests, (requests) => {
    const { resolutions } = requests;
    const optionsMap = new Map();
    const others = [];
    if (!resolutions)
        return null;
    const options = resolutions.reduce((prev, resolution) => {
        if (resolution.mapTo === 'OTHER') {
            others.push(resolution);
            return prev;
        }
        if (!prev.has(resolution.issuer)) {
            const reasonsMap = new Map();
            prev.set(resolution.issuer, reasonsMap.set(resolution.reason, [resolution]));
            return prev;
        }
        const reason = prev.get(resolution.issuer);
        if (!reason)
            return null;
        if (reason.has(resolution.reason)) {
            prev.set(resolution.issuer, reason.set(resolution.reason, [
                ...reason.get(resolution.reason),
                resolution,
            ]));
            return prev;
        }
        prev.set(resolution.issuer, reason.set(resolution.reason, [resolution]));
        return prev;
    }, optionsMap);
    orderDeclinedJobReason(options);
    others.forEach((other) => {
        const reason = options.get(other.issuer);
        options.set(other.issuer, reason.set(other.reason, [other]));
    });
    return filterReasonsWithoutSubReasonsVisible(options);
});
export const selectMaxCancelHours = createSelector(selectRequests, (requests) => requests === null || requests === void 0 ? void 0 : requests.cancelFeeMaxHours);
export const selectAppointmentIsWithinMaxCancelHours = createSelector(selectRequests, selectReferenceNum, selectMaxCancelHours, (requests, refNum, maxHours) => {
    var _a;
    const appointment = (_a = requests === null || requests === void 0 ? void 0 : requests.byId[refNum]) === null || _a === void 0 ? void 0 : _a.request.activeAppointment;
    const { appointmentDate, appointmentTime, timezone } = appointment;
    const appTime = `${appointmentDate} ${appointmentTime}`;
    const app = moment.tz(appTime, timezone);
    const now = moment.tz(timezone);
    return maxHours ? (app === null || app === void 0 ? void 0 : app.diff(now, 'hours')) < maxHours : true;
});
export const selectIsActiveAppointmentConfirmed = createSelector(selectRequests, selectReferenceNum, (requests, refNum) => { var _a, _b; return ((_b = (_a = requests === null || requests === void 0 ? void 0 : requests.byId[refNum]) === null || _a === void 0 ? void 0 : _a.request.activeAppointment) === null || _b === void 0 ? void 0 : _b.status) === 'CONFIRMED'; });
export const selectWorkOrderPayments = (workOrderId) => createSelector(selectRequests, (requests) => { var _a; return (_a = requests === null || requests === void 0 ? void 0 : requests.payments) === null || _a === void 0 ? void 0 : _a[workOrderId]; });
export const selectWorkOrderPaymentsReceived = (workOrderId) => createSelector(selectWorkOrderPayments(workOrderId), (payments) => payments === null || payments === void 0 ? void 0 : payments.reduce((prev, current) => current.description !== 'PRE_AUTHORIZATION' &&
    (current === null || current === void 0 ? void 0 : current.status) === 'SUCCEEDED' &&
    (current === null || current === void 0 ? void 0 : current.amount) &&
    !(current === null || current === void 0 ? void 0 : current.removed)
    ? prev + current.amount
    : prev, 0));
export const selectRequestPartPickups = createSelector(selectRequests, (requests) => { var _a; return (_a = requests === null || requests === void 0 ? void 0 : requests.partPickups) !== null && _a !== void 0 ? _a : []; });
export const selectServiceDrawer = createSelector(selectRequests, (requests) => requests === null || requests === void 0 ? void 0 : requests.serviceDrawer);
export const selectWorkOrderResponsiblePartyAuthorizations = (referenceNum) => createSelector(selectRequests, (requests) => {
    var _a, _b, _c;
    return ((_c = (_b = (_a = requests === null || requests === void 0 ? void 0 : requests.byId[referenceNum]) === null || _a === void 0 ? void 0 : _a.request) === null || _b === void 0 ? void 0 : _b.workOrder) === null || _c === void 0 ? void 0 : _c.workOrderResponsiblePartyAuthorizations) || [];
});
