import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Container, Grid, Paper } from 'src/components/mui';
import { login } from 'src/modules/auth/actions';
import { AMSLogo } from 'src/components/logos';
import LoginForm from './LoginForm';
const LoginContainerDiv = styled(Container) `
  padding-left: 32px;
  padding-right: 32px;
`;
const StyledPaper = styled(Paper) `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px 48px 32px;
  margin-top: 64px;
`;
const LogoWrapper = styled.div `
  margin-top: 40px;
  margin-bottom: 35px;
`;
export const LoginContainer = () => {
    const dispatch = useDispatch();
    return (<LoginContainerDiv disableGutters>
      <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Grid item xs={12} sm={5}>
          <StyledPaper>
            <LogoWrapper>
              <AMSLogo />
            </LogoWrapper>
            <LoginForm onSubmit={(credentials) => dispatch(login(credentials))}/>
          </StyledPaper>
        </Grid>
      </Grid>
    </LoginContainerDiv>);
};
export default LoginContainer;
