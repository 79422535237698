var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import { difference, map } from 'lodash';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRepairPrice } from 'src/AdminApp/components/repairs/statement/Repairs/utils';
import { closeServiceDrawer, getQuoteDiagnosis, openMeasurementsSection, openServiceDrawer, } from 'src/AdminApp/modules/requests/actions';
import { ServiceDrawerName } from 'src/AdminApp/modules/requests/repairs.state';
import { selectRequestDataByReferenceNum, selectServiceDrawer, } from 'src/AdminApp/modules/requests/selectors';
import { formatAdditionalInfo } from 'src/AdminApp/components/repairs/statement/utils';
import { toast } from 'src/components/SimpleToast';
import { updateFitment } from 'src/PopsApp/modules/parts/actions';
const useRepairsDisplay = ({ referenceNum, workOrderId, updateSuggestedRepair, updateSuggestedRepairInvoice, removeComponent, updateComponent, addComponent, removeRepair, showCustom, repairs, relatedParts, invoice, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const dispatch = useDispatch();
    const requestData = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const serviceDrawerState = useSelector(selectServiceDrawer);
    const isSilverRockRO = requestData &&
        ((_b = (_a = requestData === null || requestData === void 0 ? void 0 : requestData.request) === null || _a === void 0 ? void 0 : _a.marketingSource) === null || _b === void 0 ? void 0 : _b.marketingName) === 'silverrock';
    const updateNote = ({ suggestedRepairId, customerFacingNotes, }) => {
        if (invoice && updateSuggestedRepairInvoice) {
            dispatch(updateSuggestedRepairInvoice({
                suggestedRepairId,
                workOrderId,
                body: {
                    customerFacingNotes,
                },
            }));
        }
        else if (updateSuggestedRepair) {
            dispatch(updateSuggestedRepair({
                referenceNum,
                suggestedRepairId,
                body: {
                    customerFacingNotes,
                },
            }));
        }
    };
    const handleOpenMeasurements = (nodeId) => {
        dispatch(openMeasurementsSection(true, nodeId, false));
    };
    const onRemoveComponent = (repairId, componentId, componentType, removeDynamicRule) => {
        return removeComponent({
            repairId,
            componentId,
            componentType,
            removeDynamicRule,
        });
    };
    const handleUpdateFitment = (fitment) => __awaiter(void 0, void 0, void 0, function* () {
        const formattedFitment = formatAdditionalInfo(fitment);
        const response = yield dispatch(updateFitment(formattedFitment, fitment.id));
        if (!response.error) {
            toast.success('Fitment was successfully updated');
            dispatch(getQuoteDiagnosis(referenceNum, requestData.request.id));
            dispatch(closeServiceDrawer());
        }
    });
    const onPartSubmit = (values) => {
        const { addToPartPickup } = values, rest = __rest(values, ["addToPartPickup"]);
        if (values.isFitment && !serviceDrawerState.adding) {
            handleUpdateFitment(values);
            return;
        }
        const repairPrice = Object.assign({}, serviceDrawerState.repair.price[0]);
        delete repairPrice.fluids;
        const repairId = serviceDrawerState.repair.id;
        const parts = repairPrice.parts.map((part) => {
            const { sources, pricingData, partDistributor, componentPartDistributor } = part, restPart = __rest(part, ["sources", "pricingData", "partDistributor", "componentPartDistributor"]);
            return restPart;
        });
        const { partDistributor } = rest, cleanBody = __rest(rest, ["partDistributor"]);
        return serviceDrawerState.adding
            ? addComponent({
                repairId,
                body: {
                    parts: [Object.assign({}, rest)],
                },
            })
            : updateComponent({
                repairId,
                body: Object.assign(Object.assign({}, repairPrice), { parts: [Object.assign(Object.assign({}, parts), cleanBody)], addToPartPickup }),
            });
    };
    const onFluidSubmit = (values) => {
        if (values.isFitment) {
            handleUpdateFitment(values);
            return;
        }
        const repairPrice = Object.assign({}, serviceDrawerState.repair.price[0]);
        delete repairPrice.parts;
        const repairId = serviceDrawerState.repair.id;
        const { addToPartPickup } = values;
        dispatch({ type: 'closeEditFluid' });
        dispatch({ type: 'closeAddComponent' });
        return serviceDrawerState.fluid
            ? updateComponent({
                repairId,
                body: Object.assign(Object.assign({}, repairPrice), { fluids: [Object.assign(Object.assign({}, serviceDrawerState.fluid), values)], addToPartPickup }),
            })
            : addComponent({
                repairId,
                body: {
                    fluids: [Object.assign({}, values)],
                },
            });
    };
    const onLaborSubmit = (values) => {
        const repairId = serviceDrawerState.repair.id;
        return updateComponent({
            repairId,
            body: values,
            isValidateLaborCapacity: !!requestData.request.visit,
        });
    };
    const onLaborRemove = () => {
        const repairId = serviceDrawerState.repair.id;
        dispatch({ type: 'closeEditLabor' });
        dispatch({ type: 'closeAddComponent' });
        return updateComponent({
            repairId,
            body: {
                laborRate: null,
                laborTime: null,
            },
        });
    };
    const onRemoveRepair = () => {
        removeRepair({ repairId: serviceDrawerState.repairId });
    };
    const handleAddPart = () => {
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.part,
            repair: serviceDrawerState.repair,
            part: null,
            adding: true,
            isCustom: showCustom,
            hasInvoice: !!invoice,
        }));
    };
    const handleEditLabor = (repair, id) => {
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.labor,
            repair,
            repairId: id,
            adding: false,
            isCustom: showCustom,
            hasInvoice: !!invoice,
        }));
    };
    const handleEditPart = (repair, part, isFitment = false) => {
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.part,
            repair,
            part,
            isFitment,
            adding: false,
            isCustom: showCustom,
            hasInvoice: !!invoice,
            isShowAdditionalInfo: false,
        }));
    };
    const handleOpenEditFluid = (repair, fluid, isFitment = false) => {
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.fluid,
            repair,
            fluid,
            isFitment,
            adding: false,
            isCustom: showCustom,
            hasInvoice: !!invoice,
            isShowAdditionalInfo: false,
        }));
    };
    const handleOpenAdditionalInfoPart = (repair, part, isFitment = false) => {
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.part,
            repair,
            part,
            isFitment,
            adding: false,
            isCustom: showCustom,
            hasInvoice: !!invoice,
            isShowAdditionalInfo: true,
        }));
    };
    const handleOpenAdditionalInfoFluid = (repair, fluid, isFitment = false) => {
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.fluid,
            repair,
            fluid,
            isFitment,
            adding: false,
            isCustom: showCustom,
            hasInvoice: !!invoice,
            isShowAdditionalInfo: true,
        }));
    };
    const handleOpenAddComponent = (repair) => dispatch(openServiceDrawer({
        drawerName: ServiceDrawerName.addComponent,
        repair,
        isCustom: showCustom,
        hasInvoice: !!invoice,
    }));
    const handleOpenWrongPart = (repair, suggestedRepairId) => dispatch({
        type: 'openWrongPart',
        repair,
        suggestedRepairId,
        isCustom: showCustom,
    });
    const handleOpenRemoveRepair = (id) => dispatch(openServiceDrawer({
        drawerName: ServiceDrawerName.removeRepair,
        repairId: id,
        isCustom: showCustom,
        hasInvoice: !!invoice,
    }));
    const handleOpenAddLabor = () => dispatch(openServiceDrawer({
        drawerName: ServiceDrawerName.labor,
        repair: serviceDrawerState.repair,
        isCustom: showCustom,
        hasInvoice: !!invoice,
    }));
    const handleOpenAddFluid = () => {
        dispatch(openServiceDrawer({
            drawerName: ServiceDrawerName.fluid,
            repair: serviceDrawerState.repair,
            fluid: null,
            isCustom: showCustom,
            hasInvoice: !!invoice,
        }));
    };
    const laborModalProps = showCustom
        ? (_e = ((_d = (_c = serviceDrawerState.repair) === null || _c === void 0 ? void 0 : _c.price) !== null && _d !== void 0 ? _d : [])[0]) !== null && _e !== void 0 ? _e : {}
        : getRepairPrice((_f = serviceDrawerState.repairId) !== null && _f !== void 0 ? _f : -1, repairs);
    const currentSuggestedRepairId = (_g = serviceDrawerState.repair) === null || _g === void 0 ? void 0 : _g.id;
    const currentRelatedParts = relatedParts && Object.keys(relatedParts).length > 0
        ? relatedParts[currentSuggestedRepairId]
        : [];
    const currentRelatedPartsNumbers = map(currentRelatedParts, (part) => part.partNumber);
    const currentSuggestedRepairPartNumbers = map((_l = (_k = ((_j = (_h = serviceDrawerState.repair) === null || _h === void 0 ? void 0 : _h.price) !== null && _j !== void 0 ? _j : [])[0]) === null || _k === void 0 ? void 0 : _k.parts) !== null && _l !== void 0 ? _l : [], (part) => part.partNumber);
    const relatedPartsLength = difference(currentRelatedPartsNumbers, currentSuggestedRepairPartNumbers).length;
    const fixedPartComponents = useMemo(() => {
        var _a, _b;
        let fixedComponentIds = [];
        (_b = (_a = serviceDrawerState.repair) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.forEach((price) => {
            const fixedFluids = price.fluids
                .filter((f) => { var _a; return ((_a = f === null || f === void 0 ? void 0 : f.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'FIXED'; })
                .map((f) => f.componentId);
            const fixedParts = price.parts
                .filter((p) => { var _a; return ((_a = p === null || p === void 0 ? void 0 : p.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'FIXED'; })
                .map((p) => p.componentId);
            fixedComponentIds = [...fixedComponentIds, ...fixedFluids, ...fixedParts];
        });
        return fixedComponentIds;
    }, [serviceDrawerState]);
    const fixedFluidComponents = useMemo(() => {
        var _a, _b;
        let fixedComponentIds = [];
        (_b = (_a = serviceDrawerState.repair) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.forEach((price) => {
            const fixedFluids = price.fluids
                .filter((f) => { var _a; return ((_a = f === null || f === void 0 ? void 0 : f.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'FIXED'; })
                .map((f) => f.componentId);
            const fixedParts = price.parts
                .filter((p) => { var _a; return ((_a = p === null || p === void 0 ? void 0 : p.pricingData) === null || _a === void 0 ? void 0 : _a.componentType) === 'FIXED'; })
                .map((p) => p.componentId);
            fixedComponentIds = [...fixedComponentIds, ...fixedFluids, ...fixedParts];
        });
        return fixedComponentIds;
    }, [serviceDrawerState]);
    const handleCloseServiceDrawer = () => {
        dispatch(closeServiceDrawer());
    };
    return {
        updateNote,
        handleOpenMeasurements,
        onRemoveComponent,
        onPartSubmit,
        onFluidSubmit,
        onLaborSubmit,
        onLaborRemove,
        onRemoveRepair,
        handleAddPart,
        serviceDrawerState,
        handleEditLabor,
        handleEditPart,
        handleOpenEditFluid,
        handleOpenAddComponent,
        handleOpenWrongPart,
        handleOpenRemoveRepair,
        handleCloseServiceDrawer,
        handleOpenAddLabor,
        handleOpenAddFluid,
        handleOpenAdditionalInfoPart,
        handleOpenAdditionalInfoFluid,
        laborModalProps,
        relatedPartsLength,
        currentRelatedParts,
        currentSuggestedRepairPartNumbers,
        currentSuggestedRepairId,
        hasEngine: ((_m = requestData.car) === null || _m === void 0 ? void 0 : _m.engineId) && ((_o = requestData.car) === null || _o === void 0 ? void 0 : _o.engineId) !== 'i_dont_know',
        fixedPartComponents,
        fixedFluidComponents,
        isSilverRockRO,
    };
};
export default useRepairsDisplay;
