import { FormControlLabel, InputAdornment, Radio, RadioGroup, TextField, } from 'src/components/mui';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormGridItemWrapper } from 'src/AdminApp/components/drawers/styledComponents';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import Grid from 'src/PopsApp/components/Grid/Grid';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
const RadioButton = styled(FormControlLabel) `
  padding: 4px 0;
  border-bottom: ${({ $removeBorderBottom }) => $removeBorderBottom ? '0' : `1px solid ${colors.GRAY_DE}`};
`;
const extraTimeOptions = ['15 min', '30 min', '60 min', 'Custom Time'];
const ExtraMinutesModal = ({ isOpen, setIsOpen, onValueChange, addingTime = false, disableSubmit, onSubmit, }) => {
    const [extraMinutesOption, setExtraMinutesOption] = useState('');
    useEffect(() => {
        if (isOpen) {
            onValueChange(0);
            setExtraMinutesOption('');
        }
    }, [isOpen, onValueChange]);
    return (<SimpleDrawer open={isOpen} onClose={() => { }}>
      <SimpleDrawerHeader title="Select Time" onClose={() => {
            setIsOpen(false);
        }} arrowBack/>

      <SimpleDrawerContent>
        <Formik initialValues={{}} onSubmit={() => { }}>
          <>
            <RadioGroup onChange={({ target }) => {
            const { value } = target;
            setExtraMinutesOption(value);
            if (value !== 'Custom Time') {
                const minutesStr = value.substr(0, 2);
                onValueChange(Number(minutesStr));
            }
            else {
                onValueChange(0);
            }
        }}>
              {extraTimeOptions.map((extraTimeOption) => (<RadioButton key={extraTimeOption} $removeBorderBottom={extraTimeOption === 'Custom Time'} value={extraTimeOption} control={<Radio color="primary"/>} label={extraTimeOption}/>))}
            </RadioGroup>
            {extraMinutesOption === 'Custom Time' && (<Grid hasItems={false}>
                <FormGridItemWrapper item xs={12}>
                  <TextField onChange={({ target }) => onValueChange(Number(target.value))} variant="outlined" type="number" label="Enter time" InputProps={{
                endAdornment: (<InputAdornment position="end">min</InputAdornment>),
            }} name="Name" fullWidth/>
                </FormGridItemWrapper>
              </Grid>)}
          </>
        </Formik>
      </SimpleDrawerContent>
      <SimpleDrawerFooter>
        <FooterActionButton onClick={onSubmit} disabled={disableSubmit}>
          {addingTime ? 'Add Time' : 'Deduce Time'}
        </FooterActionButton>
      </SimpleDrawerFooter>
    </SimpleDrawer>);
};
export default ExtraMinutesModal;
