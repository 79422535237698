import React from 'react';
import { useSelector } from 'react-redux';
import { selectPaymentDrawerAmount, selectPaymentDrawerPaymentType, selectPaymentDrawerScreen, } from 'src/AdminApp/modules/paymentDrawer/selectors';
import { FooterActionButton } from 'src/components/SimpleDrawer';
import { SliderFooter } from 'src/components/SimpleDrawerPaged';
import usePaymentDrawer from '../usePaymentDrawer';
const PaymentDrawerFooter = ({ handleSubmit, values, }) => {
    const amount = useSelector(selectPaymentDrawerAmount);
    const paymentType = useSelector(selectPaymentDrawerPaymentType);
    const screen = useSelector(selectPaymentDrawerScreen);
    const { navigate } = usePaymentDrawer({});
    let submit = null;
    if (screen === 'home') {
        submit = (<SliderFooter>
        <FooterActionButton onClick={() => navigate(1)} disabled={!amount}>
          Select Payment Method
        </FooterActionButton>
      </SliderFooter>);
    }
    else if (screen === 'paymentDetail') {
        if (values.referenceNumber) {
            values.referenceNumber = values.referenceNumber.replace(/\s+/g, ' ');
            values.referenceNumber = values.referenceNumber.trimStart();
        }
        const paymentSubtypeRequired = ['Financing', 'Internal'].includes(paymentType !== null && paymentType !== void 0 ? paymentType : '');
        const disabled = (paymentType === 'ACH Payment' && !values.referenceNumber) ||
            (paymentSubtypeRequired && !values.paymentSubtype);
        submit = (<SliderFooter>
        <FooterActionButton disabled={disabled} onClick={handleSubmit}>
          Submit
        </FooterActionButton>
      </SliderFooter>);
    }
    else if (screen === 'charge') {
        submit = (<FooterActionButton id="payment-submit" style={{ display: 'none' }} onClick={handleSubmit}>
        Submit
      </FooterActionButton>);
    }
    return submit;
};
export default PaymentDrawerFooter;
