import React from 'react';
import DiscountDrawer from 'src/AdminApp/components/discounts/components/DiscountDrawer';
import SubletDrawer from 'src/AdminApp/containers/sublets/SubletDrawer';
import RepairSearchSelect from 'src/components/form/RepairSearchSelect';
import { SimpleModal } from 'src/components/layout';
import { Button } from 'src/components/mui';
import { AddCircle } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import useActionsShell from './useActionsShell';
const ActionsShell = ({ orderId, workOrderId, fleetId, promos, credits, otherDiscounts, onAddPromoCode, onAddCFCredit, onAddOtherDiscount, disabled, cancelFeeCredits, }) => {
    const { canAccessDiscount, canEditSubletValet, disableOptions, isDiscountOpen, isServiceModalOpen, isSubmitting, isSubletOpen, onAddCredit, onAddOther, onAddPromo, onAddRepairs, onAddSublet, onRemoveRepairs, onRepairSelect, onSearchRepairs, setIsDiscountOpen, setServiceModalOpen, setSubletOpen, handleCustomRepairSubmit, handleRepairSubmit, repairId, invoice, referenceNum, } = useActionsShell({
        orderId,
        workOrderId,
        fleetId,
        promos,
        credits,
        otherDiscounts,
        onAddPromoCode,
        onAddCFCredit,
        onAddOtherDiscount,
        cancelFeeCredits,
    });
    return (<div style={{ margin: '10px 0px' }}>
      <SimpleModal fullWidth open={isServiceModalOpen} onClose={() => setServiceModalOpen(false)} title="Add a Repair" simpleTwoButtonRowProps={{
            onSubmit: handleRepairSubmit,
            onCancel: () => setServiceModalOpen(false),
            disabledSubmit: !repairId || isSubmitting,
        }}>
        <RepairSearchSelect onSelect={onRepairSelect} onSearch={onSearchRepairs} onCustomRepairSubmit={handleCustomRepairSubmit} autoFocus clearCustom={false}/>
      </SimpleModal>
      {canAccessDiscount && (<>
          <Button style={disabled ? {} : { color: colors.success }} startIcon={<AddCircle />} onClick={() => setIsDiscountOpen(true)} disabled={disabled}>
            Discount
          </Button>
          <DiscountDrawer open={isDiscountOpen} cancelFeeCredits={cancelFeeCredits} closeHandler={() => setIsDiscountOpen(false)} onPromoCodeSubmit={onAddPromo} onCreditSubmit={onAddCredit} onOtherSubmit={onAddOther} disableOptions={disableOptions}/>
        </>)}
      {canEditSubletValet && (<>
          <Button style={disabled ? {} : { color: colors.orange }} startIcon={<AddCircle />} onClick={() => setSubletOpen(true)} disabled={disabled}>
            Sublet
          </Button>
          {invoice && (<SubletDrawer open={isSubletOpen} onClose={() => setSubletOpen(false)} onAddSublet={onAddSublet} onAddRepairs={onAddRepairs} onRemoveRepairs={onRemoveRepairs} invoice={invoice} referenceNum={referenceNum}/>)}
        </>)}
    </div>);
};
export default ActionsShell;
