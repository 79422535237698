import React from 'react';
import styled from 'styled-components';
import ReactJson from '@microlink/react-json-view';
const Wrapper = styled.div `
  margin: 20px;
  min-width: 300px;
  max-width: 600px;
  width: 600px;
`;
const DraweredTemplate = ({ template, }) => {
    return (<Wrapper>
      <h1>{`${template.name} Template`}</h1>
      <ReactJson src={template} name="skeduloWebhookTemplate" indentWidth={2} displayDataTypes={false} theme="mocha" enableClipboard={false} onEdit={false} onAdd={false} onDelete={false} collapseStringsAfterLength={100} style={{ minHeight: '600px' }}/>
    </Wrapper>);
};
export default DraweredTemplate;
