import { Grid, Typography } from 'src/components/mui';
import { AddAPhoto as AddAPhotoIcon, CalendarToday as CalendarTodayIcon, DeleteForever as DeletePhotoIcon, LocationOn as LocationOnIcon, Phone as PhoneIcon, WatchLater as WatchLaterIcon, ExpandMore as ExpandMoreIcon, } from 'src/components/mui/icons';
import { capitalize } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getSkeduloUrl } from 'src/AdminApp/utils/url-utils';
import { CarouselContent, CarouselWrapper, Slide, } from 'src/components/carousel.styles';
import ConfirmationModal from 'src/components/layout/ConfirmationModal';
import SimpleModal from 'src/components/SimpleModal';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import { AppointmentInfoSection, PartPickupAccordion, PartPickupField, PartPickupSection, PartPickupSectionAccordionDetails, PartPickupSectionContent, PhoneLink, PurchaseOrdersTitle, StyledAccordionSummary, } from 'src/TechApp/components/AppointmentList/PartPickupOrderDetails/PartPickupOrderDetails.styles';
import PartPickupOrderItem from 'src/TechApp/components/AppointmentList/PartPickupOrderItem';
import PartPickupCTAContainer from 'src/TechApp/components/PartPickupTask/PartPickupCTAContainer';
import PartPickupTransfer from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/PartPickupTransfer';
import { groupByRepairRequest } from 'src/TechApp/utils';
import { colors } from 'src/styles/theme';
import { formatMilitaryToStandardTime, getFormattedDate, getFormattedTime, } from 'src/utils/formatter';
import { AttachPhotoFab, Container, Content, ContentInner, PartPickupAttachedPhotosPlaceholder, PartPickupAttachmentContainer, Title, } from './PartPickupDetails.styles';
import usePartPickupDetails from './usePartPickupDetails';
const PartPickupDetails = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
    const { referenceNum } = useParams();
    const { partPickupOrder, isPickupAtDistributor, isPickupAtLocation, isLoading, selectedPhoto, setSelectedPhoto, deletePhotoId, setDeletePhotoId, currentLocation, hasHubs, completeCurrentTask, handleAddPhoto, handleOpenRemovePhotoModal, handleRemovePhoto, isPartPickupTransferDrawerOpen, setIsPartPickupTransferDrawerOpen, } = usePartPickupDetails({ referenceNum });
    return partPickupOrder ? (<>
      <Container>
        <Title>Part Pickup</Title>
        <Content>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <h2 style={((_a = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _a === void 0 ? void 0 : _a.dealershipName)
            ? { padding: '0 16px', marginBottom: 0 }
            : { padding: '0 16px' }}>
                {isPickupAtDistributor
            ? (_b = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.pickupAtDistributor) === null || _b === void 0 ? void 0 : _b.partDistributorName
            : isPickupAtLocation
                ? `${(_c = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.pickupAtLocation) === null || _c === void 0 ? void 0 : _c.name} Hub`
                : (_d = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _d === void 0 ? void 0 : _d.partDistributorName}
              </h2>
              <h4 style={{ padding: '0 16px', marginTop: 0 }}>
                {isPickupAtDistributor
            ? (_e = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _e === void 0 ? void 0 : _e.partDistributorName
            : (_f = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _f === void 0 ? void 0 : _f.dealershipName}
              </h4>
            </Grid>
            <Grid item xs={12} md={12}>
              <PartPickupSection>
                <LocationOnIcon htmlColor={colors.lightGrey}/>
                <PartPickupSectionContent>
                  {isPickupAtDistributor
            ? partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.pickupAtDistributor.address
            : isPickupAtLocation
                ? (_h = (_g = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.pickupAtLocation) === null || _g === void 0 ? void 0 : _g.address) === null || _h === void 0 ? void 0 : _h.fullAddress
                : (_j = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _j === void 0 ? void 0 : _j.address}
                </PartPickupSectionContent>
              </PartPickupSection>
            </Grid>
            <Grid item xs={12} md={12}>
              <PartPickupSection>
                <PhoneLink href={((_k = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _k === void 0 ? void 0 : _k.phone)
            ? `tel:${(_l = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _l === void 0 ? void 0 : _l.phone}`
            : undefined}>
                  <PhoneIcon htmlColor={colors.lightGrey}/>
                  <PartPickupSectionContent>
                    {(_m = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _m === void 0 ? void 0 : _m.phone}
                  </PartPickupSectionContent>
                </PhoneLink>
              </PartPickupSection>
            </Grid>
            <Grid item xs={12} md={12}>
              <PartPickupAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <WatchLaterIcon htmlColor={colors.lightGrey}/>
                  <Typography style={{ marginLeft: '8px' }}>
                    Hours of Operation
                  </Typography>
                </StyledAccordionSummary>
                <PartPickupSectionAccordionDetails>
                  {(_p = (_o = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _o === void 0 ? void 0 : _o.hoursOfOperation) === null || _p === void 0 ? void 0 : _p.map((hours, index) => (<AppointmentInfoSection key={index}>
                        <PartPickupField>
                          {capitalize(hours.weekday)}
                        </PartPickupField>
                        <span>
                          {hours.open
                ? `${formatMilitaryToStandardTime(hours.openTime)} - ${formatMilitaryToStandardTime(hours.closeTime)}`
                : 'Closed'}
                        </span>
                      </AppointmentInfoSection>))}
                </PartPickupSectionAccordionDetails>
              </PartPickupAccordion>
            </Grid>
            <Grid item xs={12} md={12}>
              <PartPickupAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <CalendarTodayIcon htmlColor={colors.lightGrey}/>
                  <Typography style={{ marginLeft: '8px' }}>
                    Appointment Info
                  </Typography>
                </StyledAccordionSummary>
                <PartPickupSectionAccordionDetails>
                  <AppointmentInfoSection>
                    <PartPickupField>Date</PartPickupField>
                    <span>
                      {getFormattedDate((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.scheduledFor) || '')}
                    </span>
                  </AppointmentInfoSection>
                  <AppointmentInfoSection>
                    <PartPickupField>Time</PartPickupField>
                    <span>
                      {getFormattedTime((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.scheduledFor) || '')}
                    </span>
                    &nbsp; - &nbsp;
                    <span>
                      {getFormattedTime((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.scheduledEndTime) || '')}
                    </span>
                  </AppointmentInfoSection>
                  <AppointmentInfoSection>
                    <PartPickupField>Techs</PartPickupField>
                    <span>
                      {(_r = (_q = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.resources) === null || _q === void 0 ? void 0 : _q.filter((resource) => resource.category === 'MECHANIC')) === null || _r === void 0 ? void 0 : _r.map((resource) => resource.name).join(', ')}
                    </span>
                  </AppointmentInfoSection>
                  <AppointmentInfoSection>
                    <PartPickupField>Vans</PartPickupField>
                    <span>
                      {(_t = (_s = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.resources) === null || _s === void 0 ? void 0 : _s.filter((resource) => resource.category === 'VAN')) === null || _t === void 0 ? void 0 : _t.map((resource) => resource.name).join(', ')}
                    </span>
                  </AppointmentInfoSection>
                  <AppointmentInfoSection>
                    <PartPickupField>Skedulo</PartPickupField>
                    <span>
                      <a rel="noreferrer" href={getSkeduloUrl(`/job/${partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.skeduloJobId}`)} target="_blank">
                        {partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.skeduloJobId}
                      </a>
                    </span>
                  </AppointmentInfoSection>
                </PartPickupSectionAccordionDetails>
              </PartPickupAccordion>
            </Grid>
            <Grid item xs={12} md={12}>
              <PartPickupAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AddAPhotoIcon htmlColor={colors.lightGrey}/>
                  <Typography style={{ marginLeft: '8px' }}>
                    Attached Photos ({(_v = (_u = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.attachments) === null || _u === void 0 ? void 0 : _u.length) !== null && _v !== void 0 ? _v : 0}
                    )
                  </Typography>
                </StyledAccordionSummary>
                <PartPickupSectionAccordionDetails>
                  {((_w = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.attachments) === null || _w === void 0 ? void 0 : _w.length) > 0 ? (<Grid container direction="column" spacing={2}>
                      {partPickupOrder.attachments.map((photo) => (<Grid item xs={12}>
                            <PartPickupAttachmentContainer onClick={() => setSelectedPhoto(photo)}>
                              <span>{photo.fileName}</span>
                              <DeletePhotoIcon htmlColor={colors.GRAY_76} onClick={(e) => handleOpenRemovePhotoModal(e, photo.id)}/>
                            </PartPickupAttachmentContainer>
                          </Grid>))}
                    </Grid>) : (<PartPickupAttachedPhotosPlaceholder>
                      No Attached Photos
                    </PartPickupAttachedPhotosPlaceholder>)}
                </PartPickupSectionAccordionDetails>
              </PartPickupAccordion>
            </Grid>
            <Grid item xs={12} md={12}>
              <PartPickupAccordion data-last="true">
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <PurchaseOrdersTitle>Purchase Orders</PurchaseOrdersTitle>
                </StyledAccordionSummary>
                <PartPickupSectionAccordionDetails style={{ margin: 0, padding: 0 }}>
                  {groupByRepairRequest((partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.items) || []).map((group) => (<PartPickupOrderItem key={group.repairRequestReferenceNum} repairRequestReferenceNum={group.repairRequestReferenceNum} items={group.items}/>))}
                </PartPickupSectionAccordionDetails>
              </PartPickupAccordion>
            </Grid>
            <Grid item xs={12} md={12}>
              <ContentInner style={{ marginBottom: '124px' }}>
                <PartPickupCTAContainer address={(_y = (_x = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _x === void 0 ? void 0 : _x.address) !== null && _y !== void 0 ? _y : ''} currentLocation={currentLocation} completeCurrentTask={completeCurrentTask} isRootPage hasHubs={hasHubs} goToPartPickupTransfer={() => setIsPartPickupTransferDrawerOpen(true)}/>
              </ContentInner>
            </Grid>
          </Grid>
        </Content>
      </Container>
      {((_z = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.attachments) === null || _z === void 0 ? void 0 : _z.length) < 25 && (<AttachPhotoFab color="primary" aria-label="Attach Photo" title="Attach Photo" variant="circular" size="large">
          <input type="file" id="attach-photo" accept="image/*" multiple={false} onChange={handleAddPhoto} style={{ display: 'none', cursor: 'pointer' }}/>
          <label htmlFor="attach-photo" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                cursor: 'pointer',
            }}>
            <AddAPhotoIcon style={{ cursor: 'pointer' }}/>
          </label>
        </AttachPhotoFab>)}
      <SimpleModal open={!!selectedPhoto} onClose={() => setSelectedPhoto(null)} maxWidth="lg" title={(_0 = selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.fileName) !== null && _0 !== void 0 ? _0 : ''}>
        <CarouselWrapper>
          <CarouselContent>
            <Slide>
              <img alt={(_1 = selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.fileName) !== null && _1 !== void 0 ? _1 : ''} src={(_2 = selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.fileUrl) !== null && _2 !== void 0 ? _2 : ''}/>
            </Slide>
          </CarouselContent>
        </CarouselWrapper>
      </SimpleModal>
      <ConfirmationModal open={!!deletePhotoId} title="Delete Photo" text="Are you sure you'd like to remove this photo?" action={handleRemovePhoto} onClose={() => setDeletePhotoId(null)}/>
      <SimpleDrawer open={isPartPickupTransferDrawerOpen} onClose={() => setIsPartPickupTransferDrawerOpen(false)} title="Transfer Pickup" arrowBack={false} size="half">
        <PartPickupTransfer onClose={() => setIsPartPickupTransferDrawerOpen(false)}/>
      </SimpleDrawer>
    </>) : (<Container>
      <Title>Part Pickup</Title>
      <Content>
        <h4>
          {!isLoading
            ? `The Pickup Up for ${referenceNum} wasn't found`
            : 'Loading...'}
        </h4>
      </Content>
    </Container>);
};
export default PartPickupDetails;
