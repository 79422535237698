var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isNaN } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useGeoManagementActions from 'src/AdminApp/containers/geoManagement/GeoManagementActions/useGeoManagementActions';
import { updateDeliveryVanServiceAreaHomestores } from 'src/AdminApp/modules/deliveryVans/actions';
import { addServiceArea, clearZipCodes, removeZipCode, transferZipCode, updateServiceArea, validateZipCodes, } from 'src/AdminApp/modules/serviceAreas/actions';
import { selectValidateZipCodes } from 'src/AdminApp/modules/serviceAreas/selectors';
import * as Yup from 'yup';
const useServiceAreaPage = ({ model }) => {
    const dispatch = useDispatch();
    const validateServiceAreaZipCodes = (zipCodes, serviceAreaId) => dispatch(validateZipCodes(zipCodes, serviceAreaId));
    const removeServiceAreaZipCode = (zipCode) => dispatch(removeZipCode(zipCode));
    const transferServiceAreaZipCode = (zipCode, newServiceAreaId) => dispatch(transferZipCode(zipCode, newServiceAreaId));
    const zipCodesInUse = useSelector(selectValidateZipCodes);
    const [serviceAreasDialog, setServiceAreasDialog] = useState();
    const { closeHandler, refreshRegions } = useGeoManagementActions();
    useEffect(() => {
        if (zipCodesInUse.length) {
            dispatch(clearZipCodes());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        region: Yup.string().required(),
    });
    if (model === null || model === void 0 ? void 0 : model.deliveryVanServiceAreas) {
        model.deliveryVanServiceAreas.forEach((x) => {
            x.alreadyInactive = !!(x.effectiveTo && new Date(x.effectiveTo) < new Date());
            x.active = !!(x.effectiveFrom && new Date(x.effectiveFrom) < new Date());
        });
    }
    const getZipCodeAdd = (value) => {
        return value.split(',').map((x) => x.trim());
    };
    const handleZipCodeBeforeAdd = (value) => {
        if (value.toString() === '')
            return false;
        const zipCodes = getZipCodeAdd(value.toString());
        let result = true;
        zipCodes.forEach((zipCode) => {
            result = !isNaN(Number(zipCode)) && zipCode.toString().length === 5;
            if (!result) {
                toast.error(`Validation Error: '${zipCode}' is not a zip code`);
            }
        });
        return result;
    };
    const mergeZipCodes = (zipCodes, b2bZipCodes) => {
        const _zipCodes = zipCodes.map((name) => ({
            name,
            b2bExclusive: false,
            id: '',
            serviceAreas: [],
            activeServiceAreas: [],
        }));
        const _b2bZipCodes = b2bZipCodes.map((name) => ({
            name,
            b2bExclusive: true,
            id: '',
            serviceAreas: [],
            activeServiceAreas: [],
        }));
        return _zipCodes.concat(_b2bZipCodes);
    };
    const onUpdateDeliveryVanServiceAreas = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.model) {
            const vans = (_a = serviceAreasDialog === null || serviceAreasDialog === void 0 ? void 0 : serviceAreasDialog.model) === null || _a === void 0 ? void 0 : _a.deliveryVanServiceAreas.filter((item) => !item.id && !item.alreadyInactive).map((area) => area.van.id);
            const response = yield dispatch(updateDeliveryVanServiceAreaHomestores(serviceAreasDialog.model.id, vans));
            if (response && !response.error) {
                toast.success('Homestores updated successfully.');
                refreshRegions();
            }
        }
        closeHandler();
        setServiceAreasDialog(undefined);
    });
    const removeZipCodeFromArray = (zipCodes, zipCodeToRemove) => {
        if (zipCodeToRemove.length === 0 || zipCodes.length === 0)
            return zipCodes;
        return zipCodes.filter((item) => item !== zipCodeToRemove[0]);
    };
    const handleSubmit = (values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const mergedZipCodeNames = [...values.zipCodes, ...values.b2bZipCodes];
        yield validateServiceAreaZipCodes(mergedZipCodeNames, values.id);
        const mergedZipCodes = mergeZipCodes(values.zipCodes, values.b2bZipCodes);
        let response;
        const payload = Object.assign(Object.assign({}, values), { zipCodes: mergedZipCodes });
        if (model === null || model === void 0 ? void 0 : model.id) {
            response = yield dispatch(updateServiceArea(payload));
        }
        else {
            response = yield dispatch(addServiceArea(payload));
        }
        if (response && !response.error) {
            const haveNewServiceAreasAdded = values.deliveryVanServiceAreas.some((area) => !area.id);
            const model = Object.assign(Object.assign({}, values), { id: (_b = values === null || values === void 0 ? void 0 : values.id) !== null && _b !== void 0 ? _b : response.payload.id });
            if (haveNewServiceAreasAdded) {
                setServiceAreasDialog({
                    open: true,
                    model,
                });
            }
            else {
                refreshRegions();
                closeHandler();
            }
        }
        else {
            setSubmitting(false);
            closeHandler();
        }
        setSubmitting(false);
    });
    return {
        validationSchema,
        handleSubmit,
        handleZipCodeBeforeAdd,
        getZipCodeAdd,
        removeZipCodeFromArray,
        zipCodesInUse,
        transferServiceAreaZipCode,
        removeServiceAreaZipCode,
        serviceAreasDialog,
        setServiceAreasDialog,
        closeHandler,
        onUpdateDeliveryVanServiceAreas,
        refreshRegions
    };
};
export default useServiceAreaPage;
