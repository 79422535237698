import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from 'src/components/mui';
import { CloseSharp } from 'src/components/mui/icons';
import { selectOrderParts, selectSelectedJobs, } from 'src/PopsApp/modules/parts/selectors';
import { PartsAccordion, AccordionHeader, AccordionContent, } from 'src/PopsApp/components/PartsAccordion/PartsAccordion';
import EditPart from '../EditPart';
import OrderParts from '../OrderParts';
import { Container } from './EditDialog.styles';
const EditDialog = ({ isOpen, onClose, part, onChange, onBlurInput, onSaveNext, handleEdit, handlePickPart, componentsAvailability, setIsIntegrated, setSelectedPart, distributor, }) => {
    var _a, _b;
    const [partsOpen, setPartsOpen] = useState(false);
    const orderParts = useSelector(selectOrderParts);
    const selectedJobs = useSelector(selectSelectedJobs);
    const handleAccordionClick = (state, action) => () => {
        action(!state);
    };
    const handlePickPartInternal = () => {
        handlePickPart();
    };
    if (!part)
        return null;
    return (<Container open={isOpen} onClose={onClose} fullScreen>
      <div className="dialogHeader">
        <IconButton className="dialogCloseIcon">
          <CloseSharp onClick={onClose}/>
        </IconButton>
        <h2>{((_a = part === null || part === void 0 ? void 0 : part.meta) === null || _a === void 0 ? void 0 : _a.isFluid) ? 'Edit Fluid' : 'Edit Part'}</h2>
      </div>
      <div className="dialogContent">
        <PartsAccordion className="accordion">
          <AccordionHeader className="accHeader" isOpen={partsOpen} onClick={handleAccordionClick(partsOpen, setPartsOpen)}>
            Parts Added ({orderParts.length})
          </AccordionHeader>
          <AccordionContent className="accContent" isOpen={partsOpen}>
            <OrderParts selectedPart={part} selectedJobs={selectedJobs !== null && selectedJobs !== void 0 ? selectedJobs : []} onEdit={(order) => {
            handleEdit(order);
        }}/>
          </AccordionContent>
        </PartsAccordion>

        <EditPart part={part} onChangeInput={onChange} onBlurInput={onBlurInput} onSaveNext={onSaveNext} onPickPart={handlePickPartInternal} isFluid={(_b = part === null || part === void 0 ? void 0 : part.meta) === null || _b === void 0 ? void 0 : _b.isFluid} componentsAvailability={componentsAvailability} isDialog setIsIntegrated={setIsIntegrated} setSelectedPart={setSelectedPart} distributor={distributor}/>
      </div>
    </Container>);
};
export default EditDialog;
