import React from 'react';
import { DeleteForeverRounded as DeleteForeverRoundedIcon } from 'src/components/mui/icons';
import { RemoveRoButton } from './ActionButtons.styles';
const ActionButtons = ({ onWorkOrderRemove, disabled }) => {
    return (<RemoveRoButton variant="text" endIcon={<DeleteForeverRoundedIcon />} onClick={onWorkOrderRemove} disabled={disabled}>
      {' '}
      Remove RO
    </RemoveRoButton>);
};
export default ActionButtons;
