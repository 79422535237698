import React from 'react';
import { IconButton } from 'src/components/mui';
import { CheckCircle as RunningIcon, Error as FailedIcon, PauseCircleFilled as PausedIcon, PauseCircleOutline as PauseIcon, PlayCircleOutline as ResumeIcon, Replay as RestartIcon, } from 'src/components/mui/icons';
const StatusWidget = (props) => {
    const { type, state, style, pause, resume, restart } = props;
    const value = state === null || state === void 0 ? void 0 : state.toLowerCase();
    switch (value) {
        case 'running':
            return (<>
          <span title="Running">
            <RunningIcon style={Object.assign(Object.assign({}, style), { color: 'green' })}/>
          </span>
          <IconButton edge="end" title={`Pause ${type}`} onClick={pause}>
            <PauseIcon />
          </IconButton>
        </>);
        case 'paused':
            return (<>
          <span title="Paused">
            <PausedIcon style={Object.assign(Object.assign({}, style), { color: 'orange' })}/>
          </span>
          <IconButton edge="end" title={`Resume ${type}`} onClick={resume}>
            <ResumeIcon />
          </IconButton>
        </>);
        case 'failed':
            return (<>
          <span title="Failed">
            <FailedIcon style={Object.assign(Object.assign({}, style), { color: 'red' })}/>
          </span>
          <IconButton edge="end" title={`Restart ${type}`} onClick={restart}>
            <RestartIcon />
          </IconButton>
        </>);
        default:
            return null;
    }
};
export default StatusWidget;
