import { handleActions } from 'redux-actions';
import { createClearHandler } from 'src/modules/api/utils/createClearHandler';
import { createSimpleHandler } from 'src/modules/api/utils/createSimpleHandler';
import { ActionCreators } from 'src/SystemApp/modules/locations/actions';
const DEFAULT_STATE = {
    locations: undefined,
    hubs: undefined,
    searchResults: undefined,
};
const reducers = handleActions({
    [ActionCreators.GET_LOCATIONS_HUBS.SUCCESS.type]: createSimpleHandler('hubs', 'content'),
    [ActionCreators.GET_LOCATIONS_HUBS.CLEAR.type]: createClearHandler('hubs'),
    [ActionCreators.SEARCH_LOCATION.SUCCESS.type]: createSimpleHandler('searchResults', 'content'),
    [ActionCreators.GET_LOCATIONS.SUCCESS.type]: createSimpleHandler('locations', 'content'),
}, DEFAULT_STATE);
export default reducers;
