import React from 'react';
import { ActionButton, DestructiveButton } from 'src/components/buttons';
import { SimpleModal } from 'src/components/layout';
import styled from 'styled-components';
import SkipCustom from './SkipCustom';
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 24px;

  > :not(:last-child) {
    margin-right: 16px;
  }
`;
const SkipOrderModal = ({ open, openCustom, onClose, onOpenCustom, onSubmit, }) => {
    return (<SimpleModal open={open} onClose={onClose} title="This will make the order disappear from queue entirely for the
            selected time. Are you sure you want to skip it?" actionButtonsRow={<Wrapper>
          <DestructiveButton onClick={onClose}>Cancel</DestructiveButton>

          <ActionButton className="skipOrderBtn" color="primary" variant="contained" onClick={() => onSubmit(24)}>
            Skip 24h
          </ActionButton>

          <SkipCustom open={openCustom} onClose={onClose} onOpen={onOpenCustom} onSubmit={(value) => onSubmit(value)}/>
        </Wrapper>}>
      This should only be used in cases where it is not possible to obtain the
      part (i.e. It’s a Sunday and the dealership is closed)
    </SimpleModal>);
};
export default SkipOrderModal;
