import { Grid, Typography } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const bottomMargin = {
    marginBottom: 8,
};
export const btnStyle = {
    fontSize: '1.5rem',
};
export const editStyle = {
    '--edit-margin': '8px',
};
export const borderStyle = {
    '--no-border': 'none',
};
export const RateWrapper = styled(Grid) `
  border-bottom: var(--no-border, 1px solid ${colors.lightGrey});
  margin-bottom: 24px;

  :nth-last-of-type(2) {
    margin-bottom: 16px;
  }
`;
export const RateItem = styled('div') `
  margin-bottom: 24px;

  :nth-last-of-type(2) {
    margin-bottom: var(--edit-margin, 24px);
  }
`;
export const Text = styled(Typography) `
  font-weight: 500;
`;
export const RateLine = styled('div') `
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
export const BtnRow = styled('div') `
  align-items: center;
  display: flex;

  & > :first-of-type {
    margin-right: 8px;
  }
`;
