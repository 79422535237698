var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiblePartyTypeVisibility, } from 'src/AdminApp/models/api';
import { selectCustomVehicle } from 'src/AdminApp/modules/quickRo/selectors';
import { getResponsiblePartiesByType, getResponsiblePartyTypes, } from 'src/AdminApp/modules/requests/actions';
import { selectResponsiblePartyTypes } from 'src/AdminApp/modules/requests/selectors';
var OptionGroup;
(function (OptionGroup) {
    OptionGroup["ASSIGNED_TO_VIN"] = "Assigned to VIN";
    OptionGroup["ASSIGNED_TO_ACCOUNT"] = "Assigned to Account";
    OptionGroup["INTERNAL"] = "Internal";
})(OptionGroup || (OptionGroup = {}));
const optionsPriority = {
    [OptionGroup.ASSIGNED_TO_VIN]: 0,
    [OptionGroup.ASSIGNED_TO_ACCOUNT]: 1,
    [OptionGroup.INTERNAL]: 2,
};
const useFleetResponsiblePartySearchSelect = ({ fleet, vehicle, grouped, showRpTypeSuffix, responsibleParties, onSelect, initialValue, }) => {
    const [availableFleetResponsibleParties, setAvailableFleetResponsibleParties,] = useState();
    const responsiblePartyTypes = useSelector(selectResponsiblePartyTypes);
    const customVehicle = useSelector(selectCustomVehicle);
    const [carRPs, setCarRPs] = useState([]);
    const [getInitialValue, setGetInitialValue] = useState();
    const dispatch = useDispatch();
    const roVehicle = customVehicle !== null && customVehicle !== void 0 ? customVehicle : vehicle;
    useEffect(() => {
        const fetchRPT = () => __awaiter(void 0, void 0, void 0, function* () {
            yield dispatch(getResponsiblePartyTypes());
        });
        if (!responsiblePartyTypes || (responsiblePartyTypes === null || responsiblePartyTypes === void 0 ? void 0 : responsiblePartyTypes.length) === 0) {
            fetchRPT();
        }
    }, []);
    useEffect(() => {
        if (!availableFleetResponsibleParties ||
            (availableFleetResponsibleParties === null || availableFleetResponsibleParties === void 0 ? void 0 : availableFleetResponsibleParties.length) === 0) {
            fetchAllResponsiblePartiesByType();
        }
    }, [fleet, vehicle, responsiblePartyTypes]);
    const getGroupName = (el, isAutonationPay) => {
        var _a;
        const responsiblePartyId = el.id;
        const fleetResponsibleParties = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties;
        const isRpFromVehicle = fleetResponsibleParties === null || fleetResponsibleParties === void 0 ? void 0 : fleetResponsibleParties.find((vrp) => {
            var _a, _b;
            return ((((_a = vrp.cars) === null || _a === void 0 ? void 0 : _a.some((car) => car.id === (vehicle === null || vehicle === void 0 ? void 0 : vehicle.consumerCarId))) &&
                vrp.responsiblePartyId === responsiblePartyId) ||
                !!((_b = roVehicle === null || roVehicle === void 0 ? void 0 : roVehicle.fleetResponsiblePartyIds) === null || _b === void 0 ? void 0 : _b.find((id) => id.includes(responsiblePartyId))));
        });
        if (isRpFromVehicle) {
            return OptionGroup.ASSIGNED_TO_VIN;
        }
        const isRpFromFleet = !!((_a = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) === null || _a === void 0 ? void 0 : _a.find((frp) => frp.responsiblePartyId === responsiblePartyId));
        if (isRpFromFleet) {
            return OptionGroup.ASSIGNED_TO_ACCOUNT;
        }
        if (isAutonationPay) {
            return OptionGroup.INTERNAL;
        }
        return null;
    };
    const getTransformedResponsibleParties = (response) => response === null || response === void 0 ? void 0 : response.map((el) => {
        var _a;
        const responsiblePartyType = responsiblePartyTypes === null || responsiblePartyTypes === void 0 ? void 0 : responsiblePartyTypes.find((rpt) => rpt.id === (el === null || el === void 0 ? void 0 : el.responsiblePartyTypeId));
        const applyGrouping = !!fleet || grouped;
        const groupName = applyGrouping
            ? getGroupName(el, ((_a = responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'autonation pay')
            : undefined;
        const response = Object.assign(Object.assign({}, el), { responsiblePartyType });
        if (showRpTypeSuffix) {
            response['suffix'] = responsiblePartyType === null || responsiblePartyType === void 0 ? void 0 : responsiblePartyType.name;
        }
        if (groupName) {
            response['groupName'] = groupName;
            response['orderPriority'] = optionsPriority[groupName];
        }
        if (groupName === null || groupName === void 0 ? void 0 : groupName.includes('VIN')) {
            setCarRPs((prevCarRPs) => {
                if (!prevCarRPs.some((carRP) => carRP.id === response.id)) {
                    return [...prevCarRPs, response];
                }
                return prevCarRPs;
            });
        }
        return response;
    });
    const fetchAllResponsiblePartiesByType = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!responsiblePartyTypes || (responsiblePartyTypes === null || responsiblePartyTypes === void 0 ? void 0 : responsiblePartyTypes.length) === 0) {
            yield dispatch(getResponsiblePartyTypes());
        }
        const promises = responsiblePartyTypes.map((rpt) => {
            return dispatch(getResponsiblePartiesByType({
                responsiblePartyTypeId: rpt.id,
            }));
        });
        const response = yield Promise.all(promises);
        if (response && (response === null || response === void 0 ? void 0 : response.length) > 0) {
            const transformedResponse = response.reduce((acc, el) => {
                if (el === null || el === void 0 ? void 0 : el.payload) {
                    acc.push(...el.payload);
                }
                return acc;
            }, []);
            if ((transformedResponse === null || transformedResponse === void 0 ? void 0 : transformedResponse.length) > 0) {
                const responsibleParties = getTransformedResponsibleParties(transformedResponse).filter((rp) => {
                    var _a, _b, _c;
                    if (((_a = rp === null || rp === void 0 ? void 0 : rp.groupName) === null || _a === void 0 ? void 0 : _a.includes(OptionGroup.INTERNAL)) || !(rp === null || rp === void 0 ? void 0 : rp.groupName)) {
                        return (_c = (_b = rp === null || rp === void 0 ? void 0 : rp.responsiblePartyType) === null || _b === void 0 ? void 0 : _b.visibility) === null || _c === void 0 ? void 0 : _c.includes(!!fleet
                            ? ResponsiblePartyTypeVisibility.FLEET
                            : ResponsiblePartyTypeVisibility.CONSUMER);
                    }
                    return true;
                });
                const sorted = sortBy(responsibleParties, ['orderPriority', 'name']);
                setAvailableFleetResponsibleParties([...sorted]);
            }
        }
    });
    useEffect(() => {
        if (!responsibleParties)
            return;
        setAvailableFleetResponsibleParties(responsibleParties);
    }, [fleet, vehicle, responsibleParties]);
    useEffect(() => {
        var _a, _b;
        if (!initialValue) {
            if ((carRPs === null || carRPs === void 0 ? void 0 : carRPs.length) === 1 &&
                ((_b = ((_a = roVehicle === null || roVehicle === void 0 ? void 0 : roVehicle.fleetResponsiblePartyIds) !== null && _a !== void 0 ? _a : roVehicle === null || roVehicle === void 0 ? void 0 : roVehicle.fleetResponsibleParties)) === null || _b === void 0 ? void 0 : _b.length) === 1 &&
                !getInitialValue) {
                setGetInitialValue(carRPs[0]);
                onSelect ? onSelect(carRPs[0]) : null;
            }
            else if (((carRPs === null || carRPs === void 0 ? void 0 : carRPs.length) === 0 || (carRPs === null || carRPs === void 0 ? void 0 : carRPs.length) > 1) &&
                getInitialValue) {
                setGetInitialValue(undefined);
            }
        }
        else {
            setGetInitialValue(initialValue);
            onSelect ? onSelect(initialValue) : null;
        }
    }, [availableFleetResponsibleParties]);
    return {
        fetchAllResponsiblePartiesByType,
        availableFleetResponsibleParties,
        getInitialValue,
    };
};
export default useFleetResponsiblePartySearchSelect;
