var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { differenceWith } from 'lodash';
import { useCallback, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { responsiblePartyType, responsiblePartyTypeReducer, } from 'src/AdminApp/components/repairs/statement/Repairs/ResponsiblePartyType/utils';
import { selectCancelFeeCreditsByConsumerId, selectConsumerById, } from 'src/AdminApp/modules/consumers/selectors';
import { addComponent, addCustomComponent, removeComponent, removeCustomComponent, removeCustomRepair, removeDiagnosis, removeRepair, removeSublet, updateComponent, updateCustomComponent, } from 'src/AdminApp/modules/invoices/actions';
import { selectInvoiceWithWorkOrderId, selectNonPrimaryInvoiceWithWorkOrderId, } from 'src/AdminApp/modules/invoices/selectors';
import { selectRequestIsLocked } from 'src/AdminApp/modules/requestLock/selectors';
import { closeServiceDrawer, getRecommendedRepairs, getRequest, } from 'src/AdminApp/modules/requests/actions';
import { lookupRequestIdByReferenceNum, selectContactInfo, } from 'src/AdminApp/modules/requests/selectors';
import { removeRepairsFromSublet } from 'src/AdminApp/modules/sublets/actions';
import { applyInvoiceCancelFeeCredit, applyInvoiceOtherDiscount, applyInvoicePromoCode, removeInvoiceDiscount, } from 'src/AdminApp/modules/workOrders/actions';
import { selectInvoiceCredits, selectInvoiceOtherDiscounts, selectInvoicePromos, selectInvoiceSubscriptions, selectWorkOrderSubletsByRefNum, } from 'src/AdminApp/modules/workOrders/selectors';
import { selectShowActionButtonsNonMain } from 'src/modules/auth/selectors';
const useInvoiceDetails = ({ referenceNum, workOrderId, selectorProps, }) => {
    var _a, _b, _c;
    const reduxDispatch = useDispatch();
    const [payerState, payerDispatch] = useReducer(responsiblePartyTypeReducer, responsiblePartyType);
    const [removingDiscountId, setRemovingDiscountId] = useState('');
    const [isRemoveDiscountOpen, setIsRemoveDiscountOpen] = useState(false);
    const [discountType, setDiscountType] = useState('');
    const requestLocked = useSelector(selectRequestIsLocked);
    const sublets = useSelector(selectWorkOrderSubletsByRefNum(referenceNum));
    const invoice = useSelector((state) => selectInvoiceWithWorkOrderId(state, workOrderId));
    const nonPrimaryInvoicesResponsiblePartyAuthorizations = useSelector((state) => selectNonPrimaryInvoiceWithWorkOrderId(state, workOrderId));
    const promos = useSelector(selectInvoicePromos(workOrderId));
    const subscriptionDiscounts = useSelector(selectInvoiceSubscriptions(workOrderId));
    const credits = useSelector(selectInvoiceCredits(workOrderId));
    const otherDiscounts = useSelector(selectInvoiceOtherDiscounts(workOrderId));
    const contactInfo = useSelector((state) => selectContactInfo(state, selectorProps));
    const consumerId = contactInfo ? +contactInfo.consumerId : 0;
    const consumer = useSelector(selectConsumerById(consumerId));
    const allCancelFeeCredits = useSelector((state) => selectCancelFeeCreditsByConsumerId(state, consumerId));
    const requestId = useSelector(lookupRequestIdByReferenceNum(referenceNum));
    const isShowActionButtonsNonMain = useSelector(selectShowActionButtonsNonMain);
    const authorizableRepairs = (invoice === null || invoice === void 0 ? void 0 : invoice.diagnosisServices)
        ? [
            ...invoice.diagnosisServices.knownRepairs,
            ...invoice.diagnosisServices.customRequests,
        ]
        : [];
    const authorizableResponsibleParties = (_c = (_b = (_a = authorizableRepairs === null || authorizableRepairs === void 0 ? void 0 : authorizableRepairs.flatMap((rep) => rep === null || rep === void 0 ? void 0 : rep.workOrderItemResponsibleParties)) === null || _a === void 0 ? void 0 : _a.filter((rep) => rep !== undefined)) === null || _b === void 0 ? void 0 : _b.filter((woirp) => { var _a, _b; return (_b = (_a = woirp === null || woirp === void 0 ? void 0 : woirp.responsibleParty) === null || _a === void 0 ? void 0 : _a.responsiblePartyType) === null || _b === void 0 ? void 0 : _b.authorizationNumber; })) !== null && _c !== void 0 ? _c : [];
    const getRepairToAuthorize = (woirp, rep) => {
        var _a;
        return {
            repairId: (rep === null || rep === void 0 ? void 0 : rep.suggestedRepairId) ? rep === null || rep === void 0 ? void 0 : rep.suggestedRepairId : rep === null || rep === void 0 ? void 0 : rep.id,
            repairName: rep === null || rep === void 0 ? void 0 : rep.name,
            woirpId: woirp === null || woirp === void 0 ? void 0 : woirp.id,
            totalPrice: (_a = rep === null || rep === void 0 ? void 0 : rep.price[0]) === null || _a === void 0 ? void 0 : _a.totalCost,
            responsiblePartyId: woirp === null || woirp === void 0 ? void 0 : woirp.responsiblePartyId,
        };
    };
    const workOrderItemResponsiblePartyAuthInfo = authorizableRepairs === null || authorizableRepairs === void 0 ? void 0 : authorizableRepairs.reduce((acc, rep) => {
        authorizableResponsibleParties === null || authorizableResponsibleParties === void 0 ? void 0 : authorizableResponsibleParties.map((woirp) => {
            var _a, _b;
            if (rep.id === +woirp.suggestedRepairId ||
                (rep === null || rep === void 0 ? void 0 : rep.suggestedRepairId) === +woirp.suggestedRepairId) {
                const responsiblePartyName = (_a = woirp === null || woirp === void 0 ? void 0 : woirp.responsibleParty) === null || _a === void 0 ? void 0 : _a.name;
                if (acc[responsiblePartyName]) {
                    const index = (_b = Object.keys(acc[responsiblePartyName])) === null || _b === void 0 ? void 0 : _b.length;
                    acc[responsiblePartyName] = Object.assign(Object.assign({}, acc[responsiblePartyName]), { [index]: Object.assign({}, getRepairToAuthorize(woirp, rep)) });
                }
                else {
                    acc[responsiblePartyName] = {
                        [0]: Object.assign({}, getRepairToAuthorize(woirp, rep)),
                    };
                }
            }
        });
        return acc;
    }, {});
    const cancelFeeCredits = differenceWith(allCancelFeeCredits, credits, (allCredit, otherCredit) => allCredit.invoiceId === otherCredit.originalInvoiceId);
    const handleOpenPayerModal = useCallback(() => payerDispatch({
        payload: true,
        type: 'OPEN_PAYER_MODAL',
    }), []);
    const handleClosePayerModal = useCallback(() => payerDispatch({
        payload: false,
        type: 'CLOSE_PAYER_MODAL',
    }), []);
    const handleOpenAuthModal = useCallback(() => payerDispatch({
        payload: true,
        type: 'OPEN_AUTH_MODAL',
    }), []);
    const handleCloseAuthModal = useCallback(() => payerDispatch({
        payload: false,
        type: 'CLOSE_AUTH_MODAL',
    }), []);
    const onAddComponent = ({ repairId, body, }) => {
        reduxDispatch(addComponent({ workOrderId, repairId, body }));
        reduxDispatch(closeServiceDrawer());
    };
    const onUpdateComponent = ({ repairId, body, }) => __awaiter(void 0, void 0, void 0, function* () {
        reduxDispatch(updateComponent({ workOrderId, repairId, body }));
        reduxDispatch(closeServiceDrawer());
        return reduxDispatch(getRequest(referenceNum, requestId));
    });
    const onRemoveComponent = ({ repairId, componentId, componentType, removeDynamicRule = false, }) => {
        reduxDispatch(removeComponent({
            workOrderId,
            repairId,
            componentId,
            componentType,
            removeDynamicRule,
        }));
        reduxDispatch(closeServiceDrawer());
    };
    const onRemoveRepair = ({ repairId }) => __awaiter(void 0, void 0, void 0, function* () {
        yield reduxDispatch(removeRepair(repairId, workOrderId, referenceNum));
        reduxDispatch(closeServiceDrawer());
        yield reduxDispatch(getRecommendedRepairs(referenceNum));
        return reduxDispatch(getRequest(referenceNum, requestId));
    });
    const onAddCustomComponent = ({ repairId, body, }) => reduxDispatch(addCustomComponent({ workOrderId, repairId, body }));
    const onUpdateCustomComponent = ({ repairId, body, }) => __awaiter(void 0, void 0, void 0, function* () {
        yield reduxDispatch(updateCustomComponent({ workOrderId, repairId, body }));
        reduxDispatch(closeServiceDrawer());
        return reduxDispatch(getRequest(referenceNum, requestId));
    });
    const onRemoveCustomComponent = ({ repairId, componentId, componentType, }) => {
        reduxDispatch(removeCustomComponent({
            workOrderId,
            repairId,
            componentId,
            componentType,
        }));
        reduxDispatch(closeServiceDrawer());
    };
    const onRemoveCustomRepair = ({ repairId }) => __awaiter(void 0, void 0, void 0, function* () {
        yield reduxDispatch(removeCustomRepair(repairId, workOrderId));
        reduxDispatch(closeServiceDrawer());
        return reduxDispatch(getRequest(referenceNum, requestId));
    });
    const onRemoveDiagnosis = ({ repairId }) => reduxDispatch(removeDiagnosis(repairId, workOrderId));
    const onAddPromoCode = ({ promoCode }) => reduxDispatch(applyInvoicePromoCode({ workOrderId, promoCode }));
    const onAddCredit = ({ originalInvoiceId, creditAmount, }) => reduxDispatch(applyInvoiceCancelFeeCredit({
        workOrderId,
        originalInvoiceId: originalInvoiceId,
        creditAmount,
    }));
    const onAddOtherDiscount = ({ discountedAmount, discountReasonType, reasonDescription, }) => reduxDispatch(applyInvoiceOtherDiscount({
        workOrderId,
        discountReasonType,
        discountedAmount,
        reasonDescription,
    }));
    const onRemoveDiscount = () => __awaiter(void 0, void 0, void 0, function* () {
        yield reduxDispatch(removeInvoiceDiscount({
            workOrderId,
            discountId: removingDiscountId,
        }));
        closeRemoveDiscountDialog();
    });
    const openRemoveDiscountDialog = ({ discountId, discountType, }) => {
        setIsRemoveDiscountOpen(true);
        setDiscountType(discountType);
        setRemovingDiscountId(discountId);
    };
    const closeRemoveDiscountDialog = () => {
        setIsRemoveDiscountOpen(false);
        setDiscountType('');
        setRemovingDiscountId('');
    };
    const onRemoveSublet = (data) => {
        if (data.orderId) {
            return reduxDispatch(removeSublet(data));
        }
        reduxDispatch(removeRepairsFromSublet(Object.assign(Object.assign({}, data), { referenceNum })));
    };
    const responsiblePartyTypeValue = Object.assign(Object.assign({}, payerState), { handleCloseAuthModal,
        handleClosePayerModal,
        handleOpenAuthModal,
        handleOpenPayerModal });
    return {
        requestLocked,
        isRemoveDiscountOpen,
        setIsRemoveDiscountOpen,
        removingDiscountId,
        setRemovingDiscountId,
        discountType,
        setDiscountType,
        payerState,
        payerDispatch,
        responsiblePartyTypeValue,
        sublets,
        invoice,
        nonPrimaryInvoicesResponsiblePartyAuthorizations,
        promos,
        subscriptionDiscounts,
        credits,
        otherDiscounts,
        contactInfo,
        consumer,
        allCancelFeeCredits,
        requestId,
        cancelFeeCredits,
        handleOpenPayerModal,
        handleClosePayerModal,
        handleOpenAuthModal,
        handleCloseAuthModal,
        onAddComponent,
        onUpdateComponent,
        onRemoveComponent,
        onRemoveRepair,
        onAddCustomComponent,
        onUpdateCustomComponent,
        onRemoveCustomComponent,
        onRemoveCustomRepair,
        onRemoveDiagnosis,
        onAddPromoCode,
        onAddCredit,
        onAddOtherDiscount,
        onRemoveDiscount,
        openRemoveDiscountDialog,
        closeRemoveDiscountDialog,
        onRemoveSublet,
        consumerId,
        isShowActionButtonsNonMain,
        workOrderItemResponsiblePartyAuthInfo,
    };
};
export default useInvoiceDetails;
