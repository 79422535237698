import styled from 'styled-components';
import { theme } from 'src/styles/theme';
export const DialogTitleContainer = styled.div `
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up('md')} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;
