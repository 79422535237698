import { TableRow } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import styled from 'styled-components';
export const LoaderWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px;
`;
export const searchInputStyle = (notManualEntry) => ({
    border: `1px solid ${colors.GRAY_CC}`,
    margin: 0,
    borderRadius: 5,
    padding: 16,
    fontWeight: notManualEntry ? 'bold' : 'normal',
    color: notManualEntry ? colors.GRAY_97 : colors.default,
});
export const StyledTableRow = styled(TableRow) `
  vertical-align: top !important;
  &:hover {
    background: rgb(238, 238, 238);
    cursor: pointer;
  }
  td {
    padding: 15px !important;
  }
`;
