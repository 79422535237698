import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { Button, CircularProgress, Grid, Typography } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { formatDate, formatTimestamp } from 'src/utils/formatter';
import { ErrorRounded as ErrorRoundedIcon, CheckCircleRounded as CheckCircleRoundedIcon, } from 'src/components/mui/icons';
import { selectConsumerById } from 'src/AdminApp/modules/consumers/selectors';
import { selectAdminUserInfoById, selectServiceAdvisors, } from 'src/AdminApp/modules/users/selectors';
import { selectIsMpiAlertShowing } from 'src/AdminApp/modules/requests/selectors';
import { AdvisorNameWrapper, HealthReportButtonWrapper, HealthReportStatsWrapper, HealthReportWrapper, IssuesFound, NoIssuesFound, PerformedStatusMetadata, PerformedStatusWrapper, } from 'src/AdminApp/containers/vehicle/CarModule/CarFields.styles';
import pluralize from 'pluralize';
import { selectShowActionButtonsNonMain } from 'src/modules/auth/selectors';
import SendHealthReportDialog from 'src/AdminApp/containers/vehicle/CarModule/SendHealthReportDialog';
import EditableSearchUsers from 'src/components/form/EditableSearchUsers';
import useHealthReport from 'src/AdminApp/containers/vehicle/CarModule/useHealthReport';
import { PrimaryButton } from 'src/components/buttons';
import { LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
const HealthReport = ({ repairRequestId, referenceNum, vehicle, state, }) => {
    var _a;
    const { getData, updateServiceAdvisor, loading, inspectionPerformedStatus, currentUserIsSAEditor, mpi, handleMPIMainAction, mpiMainButtonText, downloadMpiPdf, issuesCount, mpiEditable, suppressNotifications, } = useHealthReport({
        repairRequestId,
        referenceNum,
        state,
    });
    const [isSendReportConfirmDialogOpen, setIsSendReportConfirmDialogOpen] = useState(false);
    const consumer = useSelector(selectConsumerById(mpi === null || mpi === void 0 ? void 0 : mpi.consumerId));
    const mpiInspector = useSelector(selectAdminUserInfoById(mpi === null || mpi === void 0 ? void 0 : mpi.performedById));
    const isMpiAlertShowing = useSelector(selectIsMpiAlertShowing(referenceNum));
    const isShowActionButtonsNonMain = useSelector(selectShowActionButtonsNonMain);
    useEffect(() => {
        getData();
    }, []); // eslint-disable-line
    const toggleSendReportConfirmDialog = (open = true) => {
        setIsSendReportConfirmDialogOpen(open);
    };
    const isVisible = !!mpi || isShowActionButtonsNonMain;
    if (loading)
        return <CircularProgress />;
    return (<HealthReportWrapper>
      <Typography variant="subtitle2" gutterBottom>
        Health Report
        {mpi ? (<>
            :{' '}
            <Button className="downloadMpiButton" onClick={downloadMpiPdf} color="primary" disabled={!mpi.completedAt}>
              Download PDF
            </Button>
          </>) : ('')}
      </Typography>

      {mpi && (<>
          {issuesCount > 0 && (<IssuesFound>
              <ErrorRoundedIcon />
              <span>
                {issuesCount} {pluralize('Issue', issuesCount)}
              </span>
            </IssuesFound>)}
          {issuesCount === 0 && (<NoIssuesFound>
              <CheckCircleRoundedIcon />
              <span>No Issues</span>
            </NoIssuesFound>)}
        </>)}

      {(inspectionPerformedStatus === null || inspectionPerformedStatus === void 0 ? void 0 : inspectionPerformedStatus.status) !== 'NOT_PERFORMED' && (<HealthReportButtonWrapper>
          <Grid container style={{ alignContent: 'center' }}>
            <Grid item>
              {(inspectionPerformedStatus === null || inspectionPerformedStatus === void 0 ? void 0 : inspectionPerformedStatus.status) === 'COMPLETED' &&
                !mpiEditable && (<Tooltip title="MPI may no longer be edited">
                    <span>
                      <PrimaryButton color="primary" variant="contained" className="mainMPIButton" disabled>
                        Edit MPI
                      </PrimaryButton>
                    </span>
                  </Tooltip>)}
              {isVisible && (mpiEditable || !inspectionPerformedStatus) && (<PrimaryButton color="primary" variant="contained" className="mainMPIButton" onClick={handleMPIMainAction}>
                  {mpiMainButtonText()}
                </PrimaryButton>)}
              {(inspectionPerformedStatus === null || inspectionPerformedStatus === void 0 ? void 0 : inspectionPerformedStatus.status) === 'COMPLETED' && mpi && (<PrimaryButton className="editMPIButton" disabled={!inspectionPerformedStatus.lastSent || isMpiAlertShowing} onClick={() => {
                    toggleSendReportConfirmDialog();
                }}>
                  Re-send MPI{' '}
                </PrimaryButton>)}
            </Grid>
          </Grid>
        </HealthReportButtonWrapper>)}

      {(inspectionPerformedStatus === null || inspectionPerformedStatus === void 0 ? void 0 : inspectionPerformedStatus.status) === 'NOT_PERFORMED' && (<PerformedStatusWrapper>
          <div>
            <strong>Not Performed:</strong>{' '}
            {startCase(inspectionPerformedStatus.rejectionReason.toLowerCase())}{' '}
            {inspectionPerformedStatus.rejectionReason === 'OTHER' && (<span>- {inspectionPerformedStatus.otherRejectionReason}</span>)}
          </div>
          <PerformedStatusMetadata>
            Submitted{' '}
            {formatDate(inspectionPerformedStatus.modified, LOCALIZED_DATE_FORMAT)}{' '}
            {inspectionPerformedStatus.technician && (<span>by {inspectionPerformedStatus.technician}</span>)}
          </PerformedStatusMetadata>
        </PerformedStatusWrapper>)}

      <HealthReportStatsWrapper>
        <Grid container>
          {(mpi === null || mpi === void 0 ? void 0 : mpi.completedAt) && inspectionPerformedStatus && (<Grid item xs={12}>
              <Typography variant="subtitle1">
                Performed:{' '}
                {formatDate(parseInt(mpi.completedAt, 10), LOCALIZED_DATE_FORMAT)}
                {inspectionPerformedStatus.technician && (<span> by {inspectionPerformedStatus.technician}</span>)}
              </Typography>
            </Grid>)}

          {inspectionPerformedStatus && (<Grid item xs={12}>
              <Typography variant="subtitle1" component="div">
                <AdvisorNameWrapper>
                  <span>Advisor:</span>
                  {currentUserIsSAEditor ? (<EditableSearchUsers value={inspectionPerformedStatus.serviceAdvisorName} defaultValue={inspectionPerformedStatus.serviceAdvisorName} usersSelector={selectServiceAdvisors} onUpdate={(values) => updateServiceAdvisor(values)} requireId/>) : ((_a = inspectionPerformedStatus.serviceAdvisorName) !== null && _a !== void 0 ? _a : 'None')}
                </AdvisorNameWrapper>
              </Typography>
            </Grid>)}

          {(inspectionPerformedStatus === null || inspectionPerformedStatus === void 0 ? void 0 : inspectionPerformedStatus.serviceAdvisorLastSaved) && (<Grid item xs={12} style={{ display: 'none' }}>
              <Typography variant="subtitle1">
                Advisor Last Saved:{' '}
                {formatTimestamp(inspectionPerformedStatus.serviceAdvisorLastSaved, `${LOCALIZED_DATE_FORMAT} ${TIME_12H_FORMAT}`)}
              </Typography>
            </Grid>)}

          {(inspectionPerformedStatus === null || inspectionPerformedStatus === void 0 ? void 0 : inspectionPerformedStatus.lastSentByName) && (<Grid item xs={12}>
              <Typography variant="subtitle1">
                Last Sent:{' '}
                {formatTimestamp(inspectionPerformedStatus.lastSent, `${LOCALIZED_DATE_FORMAT} ${TIME_12H_FORMAT}`)}
                {inspectionPerformedStatus.lastSentByName && (<span> by {inspectionPerformedStatus.lastSentByName}</span>)}
              </Typography>
            </Grid>)}

          {(mpi === null || mpi === void 0 ? void 0 : mpi.updatedAt) && (<Grid item xs={12}>
              <Typography variant="subtitle1">
                Last Edited:{' '}
                {formatTimestamp(mpi.updatedAt, `${LOCALIZED_DATE_FORMAT} ${TIME_12H_FORMAT}`)}
                {mpi.modifiedBy && <span> by {mpi.modifiedBy}</span>}
              </Typography>
            </Grid>)}
        </Grid>
      </HealthReportStatsWrapper>

      <SendHealthReportDialog open={isSendReportConfirmDialogOpen} onClose={() => {
            toggleSendReportConfirmDialog(false);
        }} mpi={mpi} consumer={consumer} vehicle={vehicle} mpiInspector={mpiInspector} suppressNotifications={suppressNotifications}/>
    </HealthReportWrapper>);
};
export default HealthReport;
