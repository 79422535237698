import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPartner } from 'src/PartnersAPIApp/modules/partnersApi/actions';
import { selectAddPartnerAPIKeyPartner } from 'src/PartnersAPIApp/modules/partnersApi/selectors';
import { selectPartnersData } from 'src/PartnersApp/modules/partners/selectors';
const usePartnersAPIAutocomplete = ({ partnerId, }) => {
    const dispatch = useDispatch();
    const partners = useSelector(selectPartnersData);
    const selectedPartner = useSelector(selectAddPartnerAPIKeyPartner);
    useEffect(() => {
        if (!partnerId)
            return;
        const selectedPartnerId = partners === null || partners === void 0 ? void 0 : partners.filter((partner) => partner.id === partnerId);
        if (!(selectedPartnerId === null || selectedPartnerId === void 0 ? void 0 : selectedPartnerId.length))
            return;
        if (!selectedPartner)
            return;
        dispatch(setSelectedPartner(selectedPartnerId[0]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnerId, partners, selectedPartner]);
    const handleSelected = (e, value) => {
        dispatch(setSelectedPartner(value));
    };
    return {
        handleSelected,
        partners,
        selectedPartner,
    };
};
export default usePartnersAPIAutocomplete;
