import React, { useState } from 'react';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import CantFinishConditionsDrawerStart from 'src/AdminApp/containers/requests/CantFinish/CantFinishConditions/CantFinishConditionsDrawerStart';
import { Steps, CANT_FINISH_STEPS } from '../CantFinishMenuController';
const CantFinishDrawerScreenForMeasurements = ({ open, onClose, referenceNum, }) => {
    const [currentStep, setCurrentStep] = useState(CANT_FINISH_STEPS[10]);
    const sendToIntroAndClose = () => {
        onClose();
        changeStep(10);
    };
    const changeStep = (stepName) => {
        setCurrentStep(CANT_FINISH_STEPS[stepName]);
    };
    const handleNextStep = () => {
        changeStep(currentStep.next);
    };
    return !currentStep ? null : (<SimpleDrawer anchor="right" title={currentStep.title} open={open} onClose={sendToIntroAndClose}>
      <CantFinishConditionsDrawerStart nextStep={handleNextStep} introCopyText={CANT_FINISH_STEPS[Steps.SCREEN_FOR_MEASUREMENTS_REQUIRED].text} specialBehavior={CANT_FINISH_STEPS[Steps.SCREEN_FOR_MEASUREMENTS_REQUIRED]
            .specialBehavior} onClose={sendToIntroAndClose} referenceNum={referenceNum} cantFinishOptionChosen={CANT_FINISH_STEPS[Steps.SCREEN_FOR_MEASUREMENTS_REQUIRED]
            .cantFinishOptionChosen} onCallIneligibleOrConditionPoorOrAddtlRepairNoOption={() => { }}/>
    </SimpleDrawer>);
};
export default CantFinishDrawerScreenForMeasurements;
