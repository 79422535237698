var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import createDefaultActionCreators from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { getInvoicesByWorkOrderId } from 'src/AdminApp/modules/invoices/actions';
import { createWorkOrder, findWorkOrder, getWorkOrderSublets, } from 'src/AdminApp/modules/workOrders/actions';
import { selectFirstWorkOrderIdByRequestRefNum } from 'src/AdminApp/modules/workOrders/selectors';
export const ActionCreators = {
    ADD_SUBLET: createDefaultActionCreators('ADD_SUBLET'),
    GET_SUBLET: createDefaultActionCreators('GET_SUBLET'),
    DELETE_SUBLET: createDefaultActionCreators('DELETE_SUBLET'),
    UPDATE_SUBLET: createDefaultActionCreators('UPDATE_SUBLET'),
    ADD_REPAIRS_TO_SUBLET: createDefaultActionCreators('ADD_REPAIRS_TO_SUBLET'),
    REMOVE_REPAIRS_FROM_SUBLET: createDefaultActionCreators('REMOVE_REPAIRS_FROM_SUBLET'),
    REMOVE_SUBLET: createDefaultActionCreators('REMOVE_SUBLET'),
};
export const addSublet = (_a) => {
    var { referenceNum, workOrderId = null, orderId, repairIds, custom = false, repairIdsCost } = _a, rest = __rest(_a, ["referenceNum", "workOrderId", "orderId", "repairIds", "custom", "repairIdsCost"]);
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        let workOrder = null;
        if (!workOrderId) {
            workOrder = yield dispatch(createWorkOrder({ referenceNum, orderId }));
        }
        // TODO: Update reducer so sublet is added to work order sublets array
        const repairItems = repairIds.map((id) => ({
            subletCost: repairIdsCost[`cost_${id}`],
            suggestedRepairId: id,
        }));
        yield dispatch(createAPIAction({
            types: [
                ActionCreators.ADD_SUBLET.FETCH(),
                ActionCreators.ADD_SUBLET.SUCCESS({
                    meta: () => ({ referenceNum }),
                }),
                ActionCreators.ADD_SUBLET.FAILURE(),
            ],
            endpoint: API_URLS.ADMIN.SUBLET(),
            method: 'POST',
            body: Object.assign({ suggestedRepairIds: repairIds, custom, repairOrderId: orderId, workOrderId: workOrderId || (workOrder === null || workOrder === void 0 ? void 0 : workOrder.id), repairItems }, rest),
        }));
        let id = workOrderId ? [workOrderId] : null;
        if (!id && workOrder) {
            id = [workOrder.id];
        }
        if (id) {
            yield dispatch(findWorkOrder({
                workOrderIds: id,
                referenceNum,
            }));
        }
        if (workOrderId) {
            return dispatch(getInvoicesByWorkOrderId({ workOrderId }));
        }
    });
};
export const addRepairsToSublet = ({ referenceNum, repairIds, subletId, repairIdsCost, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const workOrderId = selectFirstWorkOrderIdByRequestRefNum(referenceNum)(getState());
    const repairItems = repairIds.map((id) => ({
        subletCost: repairIdsCost[`cost_${id}`],
        suggestedRepairId: id,
    }));
    yield dispatch(createAPIAction({
        types: [
            ActionCreators.ADD_REPAIRS_TO_SUBLET.FETCH(),
            ActionCreators.ADD_REPAIRS_TO_SUBLET.SUCCESS({
                meta: () => ({ workOrderId, referenceNum }),
            }),
            ActionCreators.ADD_REPAIRS_TO_SUBLET.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SUBLET_REPAIR_ITEMS({ subletId }),
        method: 'PUT',
        body: repairItems,
    }));
    yield dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return dispatch(getWorkOrderSublets({ workOrderId, referenceNum }));
});
export const removeRepairsFromSublet = ({ repairItems, subletId, referenceNum, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const workOrderId = selectFirstWorkOrderIdByRequestRefNum(referenceNum)(getState());
    yield dispatch(createAPIAction({
        types: [
            ActionCreators.REMOVE_REPAIRS_FROM_SUBLET.FETCH(),
            ActionCreators.REMOVE_REPAIRS_FROM_SUBLET.SUCCESS({
                meta: () => ({ workOrderId, referenceNum, subletId }),
            }),
            ActionCreators.REMOVE_REPAIRS_FROM_SUBLET.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SUBLET_REPAIR_ITEMS({ subletId }),
        method: 'DELETE',
        body: repairItems,
    }));
    yield dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return dispatch(getWorkOrderSublets({ workOrderId, referenceNum }));
});
export const removeSublet = ({ orderId, subletId, referenceNum, }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const workOrderId = selectFirstWorkOrderIdByRequestRefNum(referenceNum)(getState());
    yield dispatch(createAPIAction({
        types: [
            ActionCreators.REMOVE_SUBLET.FETCH(),
            ActionCreators.REMOVE_SUBLET.SUCCESS({
                meta: () => ({ orderId }),
            }),
            ActionCreators.REMOVE_SUBLET.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.SUBLET_BY_ID({ subletId }),
        method: 'DELETE',
    }));
    yield dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    return dispatch(findWorkOrder({ workOrderIds: [workOrderId], referenceNum }));
});
