import React from 'react';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from 'src/components/layout';
import { Button, IconButton } from 'src/components/mui';
import { Edit as EditIcon } from 'src/components/mui/icons';
import moment from 'moment';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
import AdminUser from 'src/AdminApp/components/users/AdminUser';
import { selectAccountManager } from 'src/modules/auth/selectors';
import { colors } from 'src/styles/theme';
import { DATE_TIME_12H_FORMAT, LOCALIZED_DATE_FORMAT } from 'src/timeConstants';
import useAccountOwner from './useAccountOwner';
import AccountOwnerDrawer from './AccountOwnerDrawer';
const AccountOwner = () => {
    var _a, _b;
    const { openDrawer, setOpenDrawer, dateOverlappedModalOpen, setDateOverlappedModalOpen, formDefaults, showHistory, setShowHistory, users, fleet, accountOwners, allAccountOwners, handleSubmit, handleClose, handleOwnerEdit, handleSubmitModal, } = useAccountOwner();
    const canEditAccount = useSelector(selectAccountManager);
    return (<>
      <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            marginBottom: 10,
        }}>
        {allAccountOwners.length > 2 && (<Button variant="outlined" onClick={() => setShowHistory(!showHistory)} style={{ marginRight: 10 }}>
            {showHistory ? 'Hide History' : 'Show History'}
          </Button>)}
        <Button variant="contained" onClick={() => setOpenDrawer(true)} disabled={!canEditAccount}>
          Add Account Owner
        </Button>
      </div>

      {accountOwners.length === 0 ? (<div style={{
                textAlign: 'center',
                border: `solid 1px ${colors.lightGrey}`,
                padding: 16,
                fontSize: '14px',
                color: colors.darkGrey,
            }}>
          No Account Owner Assigned
        </div>) : (<SimpleTable data={accountOwners} pagination={{
                totalPages: 1,
                totalElements: (_b = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.fleetAccountOwners) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0,
            }} sort={[]} columns={[
                { title: 'Account Owner', key: 'name', sortable: false },
                { title: 'Start Date', key: 'name', sortable: false },
                { title: 'End Date', key: 'name', sortable: false },
                { title: 'Modified By', key: 'name', sortable: false },
                { title: 'Modified At', key: 'name', sortable: false },
                { title: ' ', key: '', sortable: false },
            ]} renderItem={(item) => {
                var _a, _b;
                return (<SimpleTableRow data={[
                        (_b = (_a = users.find((user) => user.uid === item.systemUserId)) === null || _a === void 0 ? void 0 : _a.fullName) !== null && _b !== void 0 ? _b : '',
                        item.startDate
                            ? moment.utc(item.startDate).format(LOCALIZED_DATE_FORMAT)
                            : 'Present',
                        item.endDate
                            ? moment.utc(item.endDate).format(LOCALIZED_DATE_FORMAT)
                            : 'Present',
                        <AdminUser subText={false} updatedUser={item.modifiedBy}/>,
                        moment.utc(item.modified).local().format(DATE_TIME_12H_FORMAT),
                        <IconButton onClick={handleOwnerEdit(item)} disabled={!canEditAccount} style={{ margin: -8 }}>
                  <EditIcon />
                </IconButton>,
                    ]}/>);
            }} onLoadData={() => { }} onRowsPerPageChange={() => { }} hidePagination hideTopPagination/>)}

      <AccountOwnerDrawer openDrawer={openDrawer} formDefaults={formDefaults} users={users} handleClose={handleClose} handleSubmit={handleSubmit}/>
      <ConfirmationModal open={dateOverlappedModalOpen} title="Do you want to continue?" text="The date range you entered overlaps with an existing account owner." onClose={() => setDateOverlappedModalOpen(false)} action={handleSubmitModal}/>
    </>);
};
export default AccountOwner;
