import { Button, InputLabel } from 'src/components/mui';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const ContentInner = styled.div `
  p {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
  }
`;
export const TechInputLabel = styled(InputLabel) `
  margin: -6px 0 0 12px;
  font-weight: 700;
  background-color: ${colors.white};
  z-index: 20;
  padding: 0 5px;
`;
export const VanLabel = styled.div `
  margin-top: 16px;
  padding-left: 10px;
`;
export const DrawerFooter = styled.section `
  background: ${colors.GRAY_F8};
  padding: 16px;
  text-align: center;
  box-shadow: inset 0 1px 0 0 ${colors.GRAY_DE};
`;
export const AssignSubmitButton = styled(Button) `
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  padding: 16px 60px;
`;
