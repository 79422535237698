import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonGroup } from 'src/components/mui';
import ReactJson from '@microlink/react-json-view';
const Wrapper = styled.div `
  margin: 20px;
  min-width: 300px;
  width: 600px;
  display: flex;
  flex-direction: column;
`;
const DraweredWebhook = ({ webhook, }) => {
    const [toggle, setToggle] = useState(false);
    const toggleRawDisplay = (enabled) => {
        setToggle(enabled);
    };
    const filteredQuery = webhook.query.replace(/(\r\n|\n|\r)/gm, '\n');
    const displayedQuery = filteredQuery.trim();
    const displayedCustomFields = JSON.stringify(webhook.customFields, null, 2);
    const displayedHeaders = JSON.stringify(webhook.headers, null, 2);
    return (<Wrapper>
      <ButtonGroup style={{ justifyContent: 'center' }}>
        <Button color={!toggle ? 'primary' : 'secondary'} onClick={() => toggleRawDisplay(false)}>
          Pretty
        </Button>
        <Button color={toggle ? 'primary' : 'secondary'} onClick={() => toggleRawDisplay(true)}>
          Raw
        </Button>
      </ButtonGroup>
      {toggle ? (<div>
          <h3>Raw Details:</h3>
          <ReactJson src={webhook} name="skeduloWebhook" indentWidth={2} displayDataTypes={false} theme="mocha" enableClipboard={false} onEdit={false} onAdd={false} onDelete={false} collapseStringsAfterLength={100} style={{ minHeight: '600px' }}/>
        </div>) : (<div>
          <h3>ID:</h3>
          <p>{webhook.id}</p>
          <h3>Name:</h3>
          <p>{webhook.name}</p>
          <h3>URL:</h3>
          <p>{webhook.url}</p>
          <h3>Headers:</h3>
          <pre>{displayedHeaders}</pre>
          <h3>Query:</h3>
          <pre>{displayedQuery}</pre>
          <h3>Custom Fields:</h3>
          <pre>{displayedCustomFields}</pre>
        </div>)}
    </Wrapper>);
};
export default DraweredWebhook;
