import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const VendorContainer = styled.div `
    padding: 16px;
    border-radius: 24px;
    border: solid 1px ${colors.GRAY_F2};
    background-color: ${colors.LIGHT_GRAY};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 0 32px;

    &.active-stop {
      border: solid 1px ${colors.BLUE};
    }
  }

  .vendorTitle {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 4px;

    &__main {
      display: flex;
      align-items: center;
    }

    button {
      font-size: 14px;
      font-weight: 700;
      color: ${colors.BLUE};
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
  }

  .vendorSubtitle {
    font-size: 12px;
    font-weight: 500;
    color: ${colors.GRAY_76};
    padding: 0;
    margin: 0;

`;
