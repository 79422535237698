import React, { useState, useEffect } from 'react';
import { TableCell, Icon, TimePicker } from 'src/components/mui';
import { getMomentValueOr8am, getTimeString } from 'src/utils/formatter';
import { toast } from 'src/components/SimpleToast';
import { TIME_12H_FORMAT } from 'src/timeConstants';
const WindowShiftTableItem = ({ item, disabled, onRowRemove, onRowSave, shiftWindowHasOverlap, }) => {
    const rowStyle = {
        cursor: 'pointer',
    };
    const [isEditingRow, setIsEditingRow] = useState(false);
    const [editRow, setEditRow] = useState({});
    const handleEditClick = () => {
        setIsEditingRow(true);
    };
    useEffect(() => {
        setEditRow(item);
    }, [item]);
    const handleShiftWindowChange = (newShiftWindow) => {
        if (shiftWindowHasOverlap(newShiftWindow, true)) {
            toast.error('Cannot create an overlap');
            return;
        }
        setEditRow(newShiftWindow);
    };
    const renderWindowCells = () => {
        if (!isEditingRow) {
            return (<>
          <TableCell>{editRow.windowStart}</TableCell>
          <TableCell>{editRow.windowEnd}</TableCell>
        </>);
        }
        return (<>
        <TableCell>
          <TimePicker value={getMomentValueOr8am(editRow.windowStart, TIME_12H_FORMAT)} onChange={(value) => {
                handleShiftWindowChange(Object.assign(Object.assign({}, editRow), { windowStart: getTimeString(value, TIME_12H_FORMAT) }));
            }} 
        /*  InputLabelProps={{
          shrink: true,
        }} */
        /* KeyboardButtonProps={{
          'aria-label': 'change time',
        }} */
        minutesStep={15} slotProps={{
                textField: {
                    variant: 'standard',
                    margin: 'normal',
                    id: 'time-picker',
                },
                field: {
                    clearable: true,
                },
            }}/>
        </TableCell>
        <TableCell>
          <TimePicker value={getMomentValueOr8am(editRow.windowEnd, TIME_12H_FORMAT)} onChange={(value) => {
                handleShiftWindowChange(Object.assign(Object.assign({}, editRow), { windowEnd: getTimeString(value, TIME_12H_FORMAT) }));
            }} minutesStep={15} slotProps={{
                textField: {
                    variant: 'standard',
                    margin: 'normal',
                    id: 'time-picker',
                },
                field: {
                    clearable: true,
                },
            }}/>
        </TableCell>
      </>);
    };
    const handleSaveClick = () => {
        setIsEditingRow(false);
        onRowSave(editRow);
    };
    const renderEditCell = () => {
        if (!isEditingRow) {
            return (<TableCell align="center" style={rowStyle} onClick={handleEditClick}>
          <Icon>edit</Icon>
        </TableCell>);
        }
        return (<TableCell align="center" style={rowStyle} onClick={handleSaveClick}>
        <Icon>check</Icon>
      </TableCell>);
    };
    const renderEditAndRemoveIcons = () => {
        if (disabled) {
            return null;
        }
        return (<>
        {renderEditCell()}

        <TableCell align="center" style={rowStyle} onClick={() => onRowRemove(editRow)}>
          <Icon>delete</Icon>
        </TableCell>
      </>);
    };
    return (<>
      {renderWindowCells()}
      {renderEditAndRemoveIcons()}
    </>);
};
export default WindowShiftTableItem;
