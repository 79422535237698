import { startCase } from 'lodash';
const REGEX_ID = /^[0-9]+$/;
export const REGEX_UUID = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;
const REGEX_REFERENCE_NUM = /^[a-zA-Z0-9]{2}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/;
const REGEX_VISIT_REFERENCE_NUM = /^[a-zA-Z0-9]{7}-[a-zA-Z0-9]{4}$/;
const REGEX_REPAIR_ORDER = /^[a-zA-Z]{2}[0-9]+$/;
const REGEX_VIN = /^[(A-H|J-N|P|R-Z|0-9)]{17}$/;
const REGEX_DATE = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
const URL_TO_DISPLAY_TEXT = {
    requests: 'repairs',
    services: 'backend services',
};
export const isRepairOrderIdentifier = (text) => REGEX_REPAIR_ORDER.test(text);
export const isReferenceNum = (text) => REGEX_REFERENCE_NUM.test(text) || REGEX_REPAIR_ORDER.test(text);
// USED TO DETERMINE IF A STRING IS A VISIT REFERENCE NUMBER
// (e.g. 1234567-1234)
export const isVisitReferenceNum = (text) => REGEX_VISIT_REFERENCE_NUM.test(text);
export const isVin = (text) => REGEX_VIN.test(text);
export const isIdentifier = (text) => REGEX_REFERENCE_NUM.test(text) ||
    REGEX_VISIT_REFERENCE_NUM.test(text) ||
    REGEX_UUID.test(text) ||
    REGEX_ID.test(text) ||
    REGEX_DATE.test(text) ||
    isRepairOrderIdentifier(text);
export const getDisplayText = (text) => isIdentifier(text) ? text : startCase(URL_TO_DISPLAY_TEXT[text] || text);
export const getNavContext = ({ pathname }) => {
    const tokens = pathname.split('/').splice(1); // dump the first item in array (empty string)
    return {
        area: tokens.shift(),
        section: tokens.shift(),
        tokens,
    };
};
export const getBreadcrumbs = (location) => {
    const { tokens, area, section } = getNavContext(location);
    const breadcrumbs = tokens.reduce((acc, token) => {
        acc.push({ token, url: `${acc[acc.length - 1].url}/${token}` });
        return acc;
    }, [
        {
            token: section !== null && section !== void 0 ? section : area,
            url: section ? `/${area}/${section}` : `/${area}`,
        },
    ]);
    return breadcrumbs;
};
export const isSubSectionLocation = (location) => {
    const breadcrumbs = getBreadcrumbs(location);
    return !!breadcrumbs[breadcrumbs.length - 2];
};
export const getConsumerUrl = (path) => `${location.origin.endsWith('.com')
    ? 'https://www.autonationmobileservice.com'
    : location.origin.replace('tools', 'consumer').replace('3002', '3003')}/${path}`;
