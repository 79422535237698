import React, { useState } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { Button, Grid, TextField, NativeSelect, InputLabel, FormControl, FormGroup, } from 'src/components/mui';
import { InfoText } from 'src/components/layout';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { DATE_TIME_12H_FORMAT_WITH_SECONDS } from 'src/timeConstants';
import ServiceModule from './ServiceModule';
const SERVICES = [
    'autoselection-service',
    'consumer-service',
    'isp-service',
    'repair-service',
    'search-service',
    'symptom-service',
    'notification-service',
    'pricing-service',
    'discovery-service',
    'gateway-service',
];
const ENDPOINTS = ['info', 'env', 'health', 'liquibase', 'metrics', 'trace'];
const DEFAULT_HOST = `${location.origin.replace('tools', 'api')}`;
const DEFAULT_SERVICES = SERVICES.reduce((acc, service) => (Object.assign(Object.assign({}, acc), { [service]: true })), {});
const ServicesDashboard = () => {
    const [host, setHost] = useState(DEFAULT_HOST);
    const [hostInput, setHostInput] = useState(DEFAULT_HOST);
    const [services, setServices] = useState(DEFAULT_SERVICES);
    const [endpoint, setEndpoint] = useState('info');
    const [timestamp, setTimestamp] = useState(Date.now());
    const onChangeHostInput = (e) => {
        setHostInput(e.target.value);
        updateHost(e.target.value);
    };
    const updateHost = debounce((value) => {
        setHost(value);
    }, 1000);
    const onChangeEndpoint = (e) => {
        setEndpoint(e.target.value);
        updateHost(e.target.value);
    };
    const onServiceClicked = (e) => {
        setServices(Object.assign(Object.assign({}, services), { [e.target.value]: e.target.checked }));
    };
    const refresh = () => {
        setTimestamp(Date.now());
    };
    return (<>
      <Grid container spacing={2} style={{
            marginBottom: '10px',
            borderBottom: '1px solid darkgrey',
            alignItems: 'flex-start',
        }}>
        <Grid item xs={12} md={3}>
          <TextField label="API Host" value={hostInput} fullWidth margin="none" onChange={onChangeHostInput} variant="standard" InputLabelProps={{
            shrink: true,
        }}/>
        </Grid>
        <Grid item xs={12} md={1}>
          <FormControl margin="none">
            <InputLabel>Endpoint</InputLabel>
            <NativeSelect onChange={onChangeEndpoint}>
              {ENDPOINTS.map((endpoint) => (<option key={endpoint}>{endpoint}</option>))}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={7}>
          <FormControl component="fieldset">
            <FormGroup row>
              {SERVICES.map((service) => (<LabelledCheckbox label={service} key={service} checkboxProps={{
                checked: services[service],
                onChange: onServiceClicked,
                value: service,
            }}/>))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1}>
          <Button variant="contained" onClick={refresh} color="primary">
            Refresh
          </Button>
          <InfoText>{`Last Refreshed: ${moment(timestamp).format(DATE_TIME_12H_FORMAT_WITH_SECONDS)}`}</InfoText>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {Object.keys(services).map((service) => services[service] && (<Grid item xs={12} md={4} key={service}>
                <ServiceModule host={host} service={service} endpoint={endpoint} timestamp={timestamp}/>
              </Grid>))}
      </Grid>
    </>);
};
export default ServicesDashboard;
