import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
import { Menu, MenuItem, IconButton } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { MoreVert as MoreVertIcon, HelpOutline as HelpOutlinedIcon, } from 'src/components/mui/icons';
import { ActionButton, PrimaryButton, DestructiveButton } from 'src/components/buttons';
import { camelCase } from 'lodash';
const MenuButton = styled(IconButton) ``;
const LabelInfo = styled.div `
  font-size: 10px;
  white-space: pre-wrap;
  padding: 0 16px;
  color: ${colors.darkGrey};
  max-width: 150px;
`;
const CtaRow = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin: 0 8px;
    min-width: min-content;
    white-space: nowrap;
    width: auto;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  ${MenuButton} {
    margin: 0;
    padding: 4px;
  }
`;
export const ActionsBlockDesktop = ({ actions, childAtEnd, childAtStart, children, }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = !!anchorEl;
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return actions.length ? (<CtaRow>
      {childAtStart}
      {actions.map((action) => {
            var _a, _b, _c, _d, _e;
            let TheButton;
            switch (action.button) {
                case 'action':
                    TheButton = ActionButton;
                    break;
                case 'destructive':
                    TheButton = DestructiveButton;
                    break;
                default:
                    TheButton = PrimaryButton;
                    break;
            }
            let button;
            if (action.button === 'menu') {
                button = (<Fragment key={action.key}>
              <MenuButton aria-label="more" aria-controls="actions-menu" aria-haspopup="true" onClick={handleOpen}>
                <MoreVertIcon />
              </MenuButton>
              <Menu id="actions-menu" anchorEl={anchorEl} keepMounted open={menuOpen} onClose={handleClose}>
                {action.showInfoLabel && (<div>
                    <LabelInfo>{action.infoLabelText}</LabelInfo>
                  </div>)}
                {(_a = action.actions) === null || _a === void 0 ? void 0 : _a.map((a) => (<MenuItem key={a.key} disabled={a.disabled} onClick={() => {
                            a.action();
                            handleClose();
                        }}>
                    {a.copy}
                  </MenuItem>))}
              </Menu>
            </Fragment>);
            }
            else {
                button = (<Fragment key={(_b = action.copy) !== null && _b !== void 0 ? _b : 'action'}>
              {action.tooltipIcon && (<div>
                  <Tooltip title={action.tooltipIcon}>
                    <HelpOutlinedIcon style={{ color: 'red' }}/>
                  </Tooltip>
                </div>)}
              <TheButton variant={(_c = action.variant) !== null && _c !== void 0 ? _c : 'contained'} disabled={action.disabled} onClick={action.function} key={(_d = action.copy) !== null && _d !== void 0 ? _d : 'action'} fullWidth={action.fullWidth} disableElevation={action.disableElevation} className={`${camelCase(((_e = action.copy) !== null && _e !== void 0 ? _e : 'action').replace(/\s|\//g, ''))}-cta`}>
                {action.copy}
              </TheButton>
            </Fragment>);
            }
            return action.tooltip ? (<Tooltip title={action.tooltip} key={action.key}>
            <div>{button}</div>
          </Tooltip>) : (button);
        })}
      {childAtEnd}
    </CtaRow>) : (<span>{children}</span>);
};
