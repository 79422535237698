var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { toast } from 'src/components/SimpleToast';
import styled from 'styled-components';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, } from 'src/components/mui';
import { Refresh as RefreshIcon } from 'src/components/mui/icons';
import { debounce } from 'lodash';
import ModuleWrapper from './ModuleWrapper';
import ConnectorRow from './ConnectorRow';
import { useConnectApiClient } from './connectApiClient';
const ConnectorTable = styled(Table) `
  min-width: 650px;
`;
const Connectors = () => {
    const [connectorNames, setConnectorNames] = useState([]);
    const [connectorData, setConnectorData] = useState({});
    const [filter, setFilter] = useState(null);
    const [filteredConnectors, setFilteredConnectors] = useState([]);
    const connectApiClient = useConnectApiClient();
    const fetchConnectors = () => {
        connectApiClient
            .fetchConnectors()
            .then((connectors) => {
            const data = connectors.reduce((acc, connector) => {
                acc[connector] = {
                    name: connector,
                    expanded: false,
                };
                return acc;
            }, {});
            setConnectorData(data);
            setConnectorNames(connectors.sort());
            setFilteredConnectors(connectors.sort());
        })
            .catch((ex) => {
            toast.error(`Could not fetch Connectors\n${ex.message}`);
        });
    };
    const fetchStatuses = () => __awaiter(void 0, void 0, void 0, function* () {
        /* eslint-disable no-await-in-loop, no-restricted-syntax */
        for (const connector of filteredConnectors) {
            yield fetchStatus(connector);
        }
        /* eslint-enable */
    });
    const fetchStatus = (connector) => __awaiter(void 0, void 0, void 0, function* () {
        return connectApiClient
            .fetchConnectorStatus(connector)
            .then((status) => {
            updateConnectorData(connector, (data) => {
                data.status = status;
                data.status.fetched = Date.now();
            });
        })
            .catch((ex) => {
            toast.error(`Could not fetch Connector Status\n${ex.message}`);
        });
    });
    const fetchInfo = (connector) => {
        connectApiClient
            .fetchConnectorInfo(connector)
            .then((info) => {
            updateConnectorData(connector, (data) => {
                data.info = info;
                data.info.fetched = Date.now();
            });
        })
            .catch((ex) => {
            toast.error(`Could not fetch Connector Info\n${ex.message}`);
        });
    };
    const deleteConnector = (connector) => {
        // eslint-disable-next-line no-alert
        if (confirm(`Delete ${connector}?`)) {
            // eslint-disable-next-line no-alert
            alert('This functionality is currently disabled');
            // connectApiClient.deleteConnector(connector)
            //   .then(fetchConnectors);
        }
    };
    const applyFilter = () => {
        if (!filter)
            return;
        const regexp = new RegExp(filter);
        setFilteredConnectors(connectorNames.filter((connector) => !regexp || regexp.test(connector)));
    };
    const applyFilterDebounced = debounce((filter) => {
        if (!filter)
            return;
        setFilter(filter);
    }, 250, { maxWait: 1000 });
    const handleFilterChanged = (e) => {
        applyFilterDebounced(e.target.value);
    };
    const pauseConnector = (connector) => {
        connectApiClient
            .pauseConnector(connector)
            .then(() => fetchStatus(connector));
    };
    const resumeConnector = (connector) => {
        connectApiClient
            .resumeConnector(connector)
            .then(() => fetchStatus(connector));
    };
    const restartConnector = (connector) => {
        connectApiClient
            .restartConnector(connector)
            .then(() => fetchStatus(connector));
    };
    const restartTask = (connector, taskId) => {
        connectApiClient
            .restartConnectorTask(connector, taskId)
            .then(() => fetchStatus(connector));
    };
    const toggleRow = (connector) => {
        updateConnectorData(connector, (data) => {
            data.expanded = !data.expanded;
            if (data.expanded) {
                if (!data.info)
                    fetchInfo(connector);
                if (!data.status)
                    fetchStatus(connector);
            }
        });
    };
    const updateConnectorData = (connector, updateFn) => {
        const data = connectorData[connector];
        updateFn(data);
        setConnectorData(Object.assign(Object.assign({}, connectorData), { [connector]: data }));
    };
    useEffect(() => {
        fetchConnectors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        applyFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectorNames]);
    useEffect(() => {
        applyFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);
    return (<ModuleWrapper header="Kafka Connectors" reloadHandler={fetchConnectors}>
      <Grid item xs={12} md={6}>
        <TextField label="Filter" fullWidth margin="normal" onChange={handleFilterChanged} InputLabelProps={{
            shrink: true,
        }} variant="standard"/>
      </Grid>
      <Grid item container xs={12} style={{ padding: '0 10px' }}>
        <Grid item xs={6} style={{ padding: '15px 0' }}>
          Showing: {filteredConnectors === null || filteredConnectors === void 0 ? void 0 : filteredConnectors.length} of {connectorNames === null || connectorNames === void 0 ? void 0 : connectorNames.length}
        </Grid>
        <Grid item container xs={6} style={{ justifyContent: 'flex-end' }}/>
      </Grid>
      <TableContainer component={Paper}>
        <ConnectorTable stickyHeader size="small" aria-label="Kafka Connectors">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Connector</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Task Status</TableCell>
              <TableCell align="center">Status Last Fetched</TableCell>
              <TableCell align="center">
                <IconButton title="Refresh Statuses" onClick={fetchStatuses}>
                  <RefreshIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredConnectors
            .map((connector) => connectorData[connector] || { name: connector })
            .map((data) => (<ConnectorRow key={data.name} connector={data.name} expanded={data.expanded} info={data.info} status={data.status} deleteConnector={deleteConnector} loadStatus={fetchStatus} pauseConnector={pauseConnector} resumeConnector={resumeConnector} restartConnector={restartConnector} restartTask={restartTask} toggleRow={toggleRow}/>))}
          </TableBody>
        </ConnectorTable>
      </TableContainer>
    </ModuleWrapper>);
};
export default Connectors;
