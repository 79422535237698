import React from 'react';
import { LocalShipping, Business } from 'src/components/mui/icons';
import { colors } from 'src/styles/theme';
import { Tooltip } from 'src/components/tooltips';
const DeliveryVanAdditionalInfo = ({ hasDeliveryToHubSkill, hasRepairSmithHubHomeStore, address, }) => {
    return (<>
      {hasDeliveryToHubSkill && (<Tooltip placement="bottom" title="This van starts at a hub, you can have its parts delivered">
          <LocalShipping htmlColor={colors.primary} style={{
                margin: '0px 6px',
            }}/>
        </Tooltip>)}
      {hasRepairSmithHubHomeStore && (<Tooltip placement="bottom" title={address}>
          <Business htmlColor={colors.primary} style={{
                margin: '0px 6px',
            }}/>
        </Tooltip>)}
    </>);
};
export default DeliveryVanAdditionalInfo;
