var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, CircularProgress, useMediaQuery, } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import { selectVehicleByRefNum } from 'src/AdminApp/modules/requests/selectors';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import NotesTable from './NotesTable/NotesTable';
import NotesItem from './NotesItem';
import AddNotesDrawer from './AddNotesDrawer/AddNotesDrawer';
import SortNotesDrawer from './SortNotesDrawer';
import { NotesList, LoaderWrapper } from './Notes.styles';
import useNotes from './useNotes';
import useRequestCTAsContainer from '../StateTracker/RequestCTAs/RequestCTAsContainer/useRequestCTAsContainer';
const Notes = ({ repairRequest, fleet, canEdit = true }) => {
    var _a, _b, _c;
    const { repairRequestId } = repairRequest.repairOrder;
    const { state } = repairRequest;
    const { referenceNum } = usePageReferenceNum();
    const { fetchNotesAppliesTo, fetchNotesAppliesToRecommendedRepairs, notes, notesLoading, onLoadData, pagination, notesAppliesTo, notesAppliesToRecommendedRepairs, } = useNotes(repairRequestId);
    const { completeTask, currentTaskWorkFlow } = useRequestCTAsContainer({
        referenceNum,
        state,
    });
    const notesAppliesToCombined = [
        ...notesAppliesTo,
        ...notesAppliesToRecommendedRepairs,
    ];
    const car = useSelector(selectVehicleByRefNum(referenceNum));
    const fmc = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.fmcs) === null || _a === void 0 ? void 0 : _a.filter((fmc) => { var _a; return (_a = fmc === null || fmc === void 0 ? void 0 : fmc.cars) === null || _a === void 0 ? void 0 : _a.find((fmcCar) => fmcCar.id === car.consumerCarId); });
    const isAutoIntegrateFMC = (fmc === null || fmc === void 0 ? void 0 : fmc.length) === 1 && ((_c = (_b = fmc[0]) === null || _b === void 0 ? void 0 : _b.fmcDto) === null || _c === void 0 ? void 0 : _c.submissionMethod) === 'AUTO_INTEGRATE';
    const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            yield Promise.all([
                fetchNotesAppliesTo(),
                fetchNotesAppliesToRecommendedRepairs(),
                onLoadData({
                    page: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _a !== void 0 ? _a : 0,
                    size: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _b !== void 0 ? _b : 10,
                    sort: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _c !== void 0 ? _c : ['created,desc'],
                }),
            ]);
        }))();
    }, []); // eslint-disable-line
    return (<>
      <Grid container direction="row" style={{
            marginBottom: '40px',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
        {mdAndUp && (<Grid item>
            <Typography variant="h5" gutterBottom>
              Notes
            </Typography>
          </Grid>)}
        {canEdit && (<Grid item>
            <AddNotesDrawer repairRequestId={repairRequestId} isAutoIntegrateFMC={isAutoIntegrateFMC} completeTask={completeTask} currentTaskWorkFlow={currentTaskWorkFlow}/>
          </Grid>)}
        {!mdAndUp && <SortNotesDrawer repairRequestId={repairRequestId}/>}
      </Grid>

      {notesLoading && (<LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>)}
      {!notesLoading && !mdAndUp && (<NotesList>
          {notes.map((note) => (<NotesItem key={note.id} note={note} notesAppliesTo={notesAppliesToCombined}/>))}
        </NotesList>)}
      {!notesLoading && mdAndUp && (<NotesTable repairRequestId={repairRequestId}/>)}
    </>);
};
export default Notes;
