var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { InputAdornment } from 'src/components/mui';
import { LocationBadIcon, LocationGoodIcon, } from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer/AddFleetVisitDrawer.styles';
import PlacesAutocomplete from 'src/components/form/PlacesAutocomplete';
import { selectAddVisitExtLocation, selectAddVisitLocation, selectAddVisitStartDateTime, } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getTechnicians, saveAddVisitTechnicianIds, setAddVisitLocation, setAddVisitTechnicians, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { toast } from 'src/components/SimpleToast';
import { ActionCreators, getRegionsByFilters, } from 'src/AdminApp/modules/regions/actions';
import styled from 'styled-components';
import moment from 'moment';
import { fetchActiveMechanicsInRegion } from 'src/AdminApp/modules/quickRo/actions';
import { DATE_TIME_FORMAT, DATE_YMD_FORMAT } from 'src/timeConstants';
import { TabNames } from '../AddFleetVisitDrawer';
const ServiceAreaSection = styled.section `
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  margin-top: 8px;
`;
const LocationAutocomplete = ({ disabled, tabValue, }) => {
    const dispatch = useDispatch();
    const [addressValidation, setAddressValidation] = useState();
    const [serviceArea, setServiceArea] = useState();
    const extAddress = useSelector(selectAddVisitExtLocation);
    const selectedStartDateTime = useSelector(selectAddVisitStartDateTime);
    const selectedLocation = useSelector(selectAddVisitLocation);
    const clearData = () => dispatch(ActionCreators.REGIONS.CLEAR());
    const clearFilters = () => dispatch(ActionCreators.REGIONS.CLEAR_FILTERS());
    const updateSelectedLocation = (selectedLocation) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        if (!selectedLocation) {
            if (serviceArea) {
                setServiceArea(undefined);
            }
            return;
        }
        let techsResponse;
        if (tabValue === TabNames.Manual) {
            techsResponse = yield dispatch(getTechnicians({
                startDate: selectedStartDateTime.format(DATE_YMD_FORMAT),
                zip: selectedLocation.zip,
                startWithTime: moment()
                    .tz(selectedLocation.timezone)
                    .format(DATE_TIME_FORMAT),
            }));
        }
        else {
            techsResponse = yield dispatch(fetchActiveMechanicsInRegion({
                start: selectedStartDateTime.format(DATE_YMD_FORMAT),
                zipCode: selectedLocation.zip,
                timezone: selectedLocation.timezone,
                fleet: true,
            }));
        }
        if (!techsResponse.error && techsResponse.payload.length === 0) {
            toast.error('There are no available techs in this zip');
            dispatch(setAddVisitTechnicians([]));
        }
        setAddressValidation(undefined);
        const result = yield dispatch(getRegionsByFilters({
            filters: {
                query: selectedLocation.zip,
            },
        }));
        const serviceAreas = (_b = (_a = result === null || result === void 0 ? void 0 : result.payload) === null || _a === void 0 ? void 0 : _a.content[0]) === null || _b === void 0 ? void 0 : _b.serviceAreas;
        setServiceArea((_d = (_c = result === null || result === void 0 ? void 0 : result.payload) === null || _c === void 0 ? void 0 : _c.content[0]) === null || _d === void 0 ? void 0 : _d.name);
        setAddressValidation((serviceAreas === null || serviceAreas === void 0 ? void 0 : serviceAreas.length) > 0);
        clearData();
        clearFilters();
    });
    useEffect(() => {
        updateSelectedLocation(selectedLocation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocation, tabValue]);
    const handleLocationChange = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(saveAddVisitTechnicianIds(new Set()));
        setAddressValidation(undefined);
        dispatch(setAddVisitLocation(value));
    });
    return (<>
      <PlacesAutocomplete displayMap={false} variant="outlined" disabled={disabled} alignItems="flex-start" address={selectedLocation} extAddress={extAddress} searchOptions={{
            componentRestrictions: { country: 'us' },
            types: ['address'],
        }} label="Location" onSelect={handleLocationChange} onError={() => {
            // NOTHING TO SEE HERE
        }} inputProps={{
            endAdornment: addressValidation === undefined ? null : (<InputAdornment position="end">
                {addressValidation ? <LocationGoodIcon /> : <LocationBadIcon />}
              </InputAdornment>),
        }}/>
      <ServiceAreaSection>Region: {serviceArea}</ServiceAreaSection>
    </>);
};
export default LocationAutocomplete;
