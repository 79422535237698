import React from 'react';
import styled from 'styled-components';
import { Icon, IconButton as MIconButton } from 'src/components/mui';
import { colors } from 'src/styles/theme';
const ControlButton = styled(MIconButton) `
  background-color: ${colors.white};
  border-radius: 3.2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24);
  height: 32px;
  margin-left: 6px;
  width: 32px;

  &:hover {
    background-color: ${colors.lightGrey};
  }
`;
export const ControlButtonsBlock = styled.div `
  padding-top: 8px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 2;
`;
const FloatControlButtons = ({ containerStyle, onApprove, onClose, }) => (<ControlButtonsBlock style={containerStyle}>
    <ControlButton color="primary" onClick={onClose}>
      <Icon>close</Icon>
    </ControlButton>
    <ControlButton type="submit" color="primary" onClick={onApprove}>
      <Icon>check</Icon>
    </ControlButton>
  </ControlButtonsBlock>);
export default FloatControlButtons;
