import React from 'react';
import { Formik } from 'formik';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SimpleDrawerHeader, DrawerFormRow, } from 'src/components/SimpleDrawer';
import { Grid, DatePicker, Autocomplete, TextField } from 'src/components/mui';
const AccountOwnerDrawer = ({ formDefaults, users, openDrawer, handleClose, handleSubmit, }) => {
    return (<SimpleDrawer open={openDrawer} onClose={handleClose}>
      <SimpleDrawerHeader title={formDefaults.id ? 'Edit Account Owner' : 'Add Account Owner'} onClose={handleClose}/>
      <Formik initialValues={{
            owner: formDefaults.owner,
            start: formDefaults.start,
            end: formDefaults.end,
        }} onSubmit={handleSubmit}>
        {({ values, setFieldValue, dirty, handleSubmit }) => {
            var _a;
            return (<>
            <SimpleDrawerContent>
              <Grid container>
                <DrawerFormRow xs={12}>
                  <Autocomplete options={users} getOptionLabel={(user) => user === null || user === void 0 ? void 0 : user.email} renderInput={(params) => (<TextField {...params} fullWidth label="Owner"/>)} onChange={(event, value) => {
                    setFieldValue('owner', value);
                }} value={values.owner}/>
                </DrawerFormRow>
                <DrawerFormRow xs={12}>
                  <DatePicker label="Start Date" value={values.start} onChange={(date) => {
                    setFieldValue('start', date);
                    setFieldValue('end', null);
                }}/>
                </DrawerFormRow>
                <DrawerFormRow xs={12}>
                  <DatePicker label="End Date" value={values.end} minDate={(_a = values.start) === null || _a === void 0 ? void 0 : _a.clone().add(1, 'day')} onChange={(date) => setFieldValue('end', date)}/>
                </DrawerFormRow>
              </Grid>
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <FooterActionButton onClick={handleSubmit} disabled={!dirty || !values.owner || !values.start}>
                Save
              </FooterActionButton>
            </SimpleDrawerFooter>
          </>);
        }}
      </Formik>
    </SimpleDrawer>);
};
export default AccountOwnerDrawer;
