import React from 'react';
import { TableRow, TableCell } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import { formatTimestamp } from 'src/utils/formatter';
import styled from 'styled-components';
const StyledTableRow = styled(TableRow) `
  cursor: pointer;
  margin-top: 5px;

  td {
    font-weight: 300;
    font-size: 15px;
    color: ${colors.GRAY_3F};
  }

  td:first-child {
    width: 35%;
  }

  &:hover {
    outline: solid 2px ${colors.BLUE};
  }
`;
const DashboardListItem = ({ id, name, status, isDefault, modified, onItemClick, }) => {
    return (<StyledTableRow onClick={() => {
            onItemClick(id);
        }}>
      <TableCell>{name}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{`${isDefault}`}</TableCell>
      <TableCell>{formatTimestamp(modified)}</TableCell>
      <TableCell>{id}</TableCell>
    </StyledTableRow>);
};
export default DashboardListItem;
