import { toEnum } from 'src/models/enums';
export const SkeduloJobResourceStatus = toEnum({
    PENDING_DISPATCH: 'Pending Dispatch',
    DISPATCHED: 'Dispatched',
    CONFIRMED: 'Confirmed',
    EN_ROUTE: 'En Route',
    CHECKED_IN: 'Checked In',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Complete',
    DELETED: 'Deleted',
    DECLINED: 'Declined',
});
export default SkeduloJobResourceStatus;
