import moment from 'moment';
import { Button } from 'src/components/mui';
import { DeleteForever } from 'src/components/mui/icons';
import { capitalize } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUserIsEngineering, selectCurrentUserIsProduct, } from 'src/AdminApp/modules/users/selectors';
import { SecondaryButton } from 'src/components/buttons';
import { usMoneyFormatWithCents } from 'src/modules/api/utils/format';
import { CardTitle, DetailsTable, InfoLabel, InfoValue, PartsInfo, POLabel, ReturnButton, StyledCard, StyledCardActions, StyledCardContent, } from 'src/PopsApp/pages/Inventory/ReturnedItemCard.styles';
import { colors } from 'src/styles/theme';
const ReturnedItemCard = ({ item, onDeleteItem, onReturn, onMove, }) => {
    var _a, _b;
    const { locationAddress } = item;
    const userIsEngineer = useSelector(selectCurrentUserIsEngineering);
    const userIsProduct = useSelector(selectCurrentUserIsProduct);
    const allowDelete = userIsEngineer || userIsProduct;
    return (<StyledCard variant="outlined">
      <StyledCardContent>
        <CardTitle>
          <POLabel>
            PO#{' '}
            <a href={`/admin/repairs/${item.poNumber}`} target="_blank" rel="noopener noreferrer">
              {item.poNumber}
            </a>
          </POLabel>
          <PartsInfo>
            Part# {item.partNumber} • Part {item.partName} • Qty {item.quantity}
          </PartsInfo>
        </CardTitle>
        <DetailsTable>
          <tbody>
            <tr>
              <td>
                <InfoLabel>Name</InfoLabel>
                <InfoValue>{item.locationName}</InfoValue>
              </td>
              <td>
                <InfoLabel>Location Type</InfoLabel>
                <InfoValue>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {capitalize((_a = item.locationType) === null || _a === void 0 ? void 0 : _a.replace('_', ' '))}
                  </div>
                </InfoValue>
              </td>
            </tr>

            <tr>
              <td>
                <InfoLabel>Distributor</InfoLabel>
                <InfoValue>{item.partDistributorName}</InfoValue>
              </td>
              <td>
                <InfoLabel>Address</InfoLabel>
                <InfoValue>
                  {locationAddress && item.locationType !== 'DELIVERY_VAN'
            ? `${locationAddress.street1} ${locationAddress.city}, ${locationAddress.state} ${locationAddress.zip}`
            : null}
                </InfoValue>
              </td>
            </tr>
            <tr>
              <td>
                <InfoLabel>Created Date</InfoLabel>
                <InfoValue>{moment(item.created).format('lll')}</InfoValue>
              </td>
              <td>
                <InfoLabel>Core Cost</InfoLabel>
                <InfoValue>
                  {usMoneyFormatWithCents((_b = item.unitCost) !== null && _b !== void 0 ? _b : '')}
                </InfoValue>
              </td>
            </tr>
          </tbody>
        </DetailsTable>
      </StyledCardContent>
      <StyledCardActions disableSpacing>
        <ReturnButton fullWidth onClick={() => {
            onReturn(item);
        }}>
          Return
        </ReturnButton>
        <SecondaryButton fullWidth onClick={() => {
            onMove(item);
        }}>
          Move
        </SecondaryButton>
      </StyledCardActions>
      {allowDelete && (<section style={{ backgroundColor: colors.GRAY_F8, padding: '10px 15px' }}>
          <Button endIcon={<DeleteForever />} onClick={() => onDeleteItem(item)} style={{ color: colors.error, padding: 0, fontWeight: 'bold' }}>
            Remove
          </Button>
        </section>)}
    </StyledCard>);
};
export default ReturnedItemCard;
