var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'src/components/SimpleToast';
import { fetchLocationHierarchy, moveReturnedItems, } from 'src/PopsApp/modules/inventory/actions';
import { selectMoveLocations } from 'src/PopsApp/modules/inventory/selectors';
const useMoveReturnedItemDrawer = ({ onClose, returnedItems = [], open, }) => {
    const dispatch = useDispatch();
    const [selectedLocation, setSelectedLocation] = useState();
    const locationSearchResults = useSelector(selectMoveLocations);
    const [selectedItems, setSelectedItems] = useState([]);
    useEffect(() => {
        if (open && returnedItems.length > 0 && returnedItems[0].id) {
            dispatch(fetchLocationHierarchy(returnedItems[0].id));
            setSelectedItems([...returnedItems]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, returnedItems]);
    const handleClose = (refresh = false) => {
        onClose(refresh);
    };
    const handleLocationSelected = (e, newLocation) => {
        if (newLocation) {
            // do something
            setSelectedLocation(newLocation);
        }
    };
    const moveReturnItem = () => __awaiter(void 0, void 0, void 0, function* () {
        const updatedItems = selectedItems.map((item) => {
            return Object.assign(Object.assign({}, item), { locationId: selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.id });
        });
        const response = yield dispatch(moveReturnedItems(updatedItems));
        if (!response.error) {
            toast.success('Items moved successfully');
            handleClose(true);
        }
    });
    const handleSelectedItem = (item) => {
        const index = selectedItems.findIndex((selectedItem) => selectedItem.id === item.id);
        if (index === -1) {
            setSelectedItems([...selectedItems, item]);
        }
        else {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
        }
    };
    return {
        handleClose,
        moveReturnItem,
        selectedLocation,
        locationSearchResults,
        handleLocationSelected,
        selectedItems,
        handleSelectedItem,
    };
};
export default useMoveReturnedItemDrawer;
