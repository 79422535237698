import { bool, object, string } from 'yup';
const cancellationReasonsSchema = object().shape({
    cancellationFee: bool().default(false).notRequired(),
    description: string().when('hasDescription', {
        is: true,
        otherwise: string().nullable().notRequired(),
        then: string().min(1).max(240).required(),
    }),
    hasDescription: bool().notRequired(),
    hasSubReasons: bool().notRequired(),
    id: string().required(),
    reason: string().required(),
    subReason: string().when('hasSubReasons', {
        is: true,
        otherwise: string().nullable().notRequired(),
        then: string().required(),
    }),
    topReason: string().required(),
});
const strValues = {
    CUSTOMER: 'Customer Issue',
    AMAZON: 'Amazon',
    REPAIRSMITH: 'RepairSmith Issue',
};
const initValues = {
    cancellationFee: false,
    description: '',
    hasDescription: false,
    hasSubReasons: false,
    id: '',
    reason: '',
    subReason: '',
    topReason: '',
};
const OTHER = 'other';
export { cancellationReasonsSchema, initValues, OTHER, strValues };
