/* global google */
import React from 'react';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import CONFIG from 'src/config';
export const calculateBounds = (markers = []) => {
    const lats = [];
    const lngs = [];
    let bounds = null;
    if (markers.length > 1) {
        markers.forEach((marker) => {
            const lat = marker === null || marker === void 0 ? void 0 : marker.lat;
            const lng = marker === null || marker === void 0 ? void 0 : marker.lng;
            lats.push(lat);
            lngs.push(lng);
        });
        bounds = {
            nw: {
                lat: Math.max(...lats),
                lng: Math.min(...lngs),
            },
            se: {
                lat: Math.min(...lats),
                lng: Math.max(...lngs),
            },
        };
    }
    return bounds;
};
const Map = ({ children = [], center = { lat: 34.024031, lng: -118.393819 }, zoom = 11, bounds = null, origin = null, markers = [], destination = null, uniqKey = -1, style = {}, showRoute = true, displayRoute = () => { }, onMarkerHover = () => { }, }) => {
    const onChildMouseEnter = (key, childProps) => {
        if (onMarkerHover)
            onMarkerHover(childProps.id);
    };
    const onChildMouseLeave = () => {
        if (onMarkerHover)
            onMarkerHover(-1);
    };
    const mapDirections = (map, maps) => {
        const directionsService = new maps.DirectionsService();
        const directionsDisplay = new maps.DirectionsRenderer();
        directionsService.route({
            origin: { lat: origin === null || origin === void 0 ? void 0 : origin.lat, lng: origin === null || origin === void 0 ? void 0 : origin.lng },
            destination: {
                lat: destination === null || destination === void 0 ? void 0 : destination.lat,
                lng: destination === null || destination === void 0 ? void 0 : destination.lng,
            },
            travelMode: 'DRIVING',
        }, (response, status) => {
            displayRoute(response.routes[0]);
            if (status === 'OK') {
                directionsDisplay.setDirections(response);
                const routePolyline = new google.maps.Polyline({
                    path: response.routes[0].overview_path,
                });
                routePolyline.setMap(map);
            }
            else {
                console.error(`Directions request failed due to ${status}`);
            }
        });
    };
    const mapMarkers = (map, maps) => {
        let marker;
        const mapMarkers = [];
        const infowindow = new maps.InfoWindow();
        for (let i = 0; i < markers.length; i += 1) {
            const { lat, lng, icon, title, key, id, hoverIcon, selected } = markers[i];
            marker = new maps.Marker({
                position: {
                    lat,
                    lng,
                },
                map,
                title,
                icon,
                key,
            });
            if (selected)
                marker.setIcon(hoverIcon);
            maps.event.addListener(marker, 'mouseover', (function setIcon(marker) {
                return function setIconMarker() {
                    if (onMarkerHover)
                        onMarkerHover(id);
                    marker.setIcon(hoverIcon);
                    infowindow.setContent(title);
                    infowindow.open(map, marker);
                };
            })(marker));
            maps.event.addListener(marker, 'mouseout', (function setIcon(marker) {
                return function setIconMarker() {
                    if (infowindow)
                        infowindow.close();
                    marker.setIcon(icon);
                };
            })(marker));
            mapMarkers.push(marker);
        }
    };
    const mapMoreComponents = (map, maps) => {
        if (!!origin && !!destination)
            mapDirections(map, maps);
        else if ((!origin && markers.length > 0) ||
            (origin && markers.length > 0 && !showRoute))
            mapMarkers(map, maps);
    };
    let thisCenter;
    let thisFit = null;
    const size = { height: '375px', width: '466px' };
    if (!showRoute) {
        thisCenter = origin;
    }
    else {
        thisFit = bounds ? fitBounds(bounds, size) : null;
        thisCenter = thisFit ? thisFit.center : center;
    }
    const thisZoom = thisFit && thisFit.zoom ? thisFit.zoom : zoom;
    return (<div style={Object.assign({ height: '350px', width: '100%' }, style)}>
      <GoogleMapReact key={`${thisZoom}-${thisCenter.lat},${thisCenter.lng}-${uniqKey}`} bootstrapURLKeys={{ key: CONFIG.GOOGLE_PLACES_API_KEY }} defaultCenter={thisCenter} defaultZoom={thisZoom} onChildMouseEnter={onChildMouseEnter} onChildMouseLeave={onChildMouseLeave} onGoogleApiLoaded={({ map, maps }) => mapMoreComponents(map, maps)} yesIWantToUseGoogleMapApiInternals>
        {children}
      </GoogleMapReact>
    </div>);
};
export default Map;
