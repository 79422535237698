import React, { useEffect, useState } from 'react';
import { Grid } from 'src/components/mui';
import Title from 'src/AdminApp/containers/fleets/tabs/Title';
import Info from 'src/AdminApp/containers/fleets/tabs/Info';
import VehiclesRepairs from 'src/AdminApp/containers/fleets/tabs/VehiclesRepairs';
import AddFleetVisitDrawer from 'src/FleetVisitsApp/pages/FleetVisits/AddFleetVisitDrawer';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFleetById } from 'src/AdminApp/modules/fleets/selectors';
import { ActionsBlock } from 'src/containers/layout/ActionsBlock';
import { clearFleetVisit } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import SendResetPasswordModal from 'src/AdminApp/containers/consumers/SendResetPassword/SendResetPasswordModal';
import useIsMobile from 'src/hooks/useIsMobile';
import useSendResetPassword from '../containers/consumers/SendResetPassword/useSendResetPassword';
const Fleet = () => {
    const isMobile = useIsMobile();
    const { fleetId } = useParams();
    const [openAddVisitDrawer, setOpenAddVisitDrawer] = useState(false);
    const fleet = useSelector(selectFleetById(fleetId));
    useEffect(() => {
        clearFleetVisit();
    }, []);
    const { toggleModal, openModal, onResetPasswordClick } = useSendResetPassword({ isFleet: true, email: fleet === null || fleet === void 0 ? void 0 : fleet.email });
    const visitActions = [
        {
            function: () => {
                setOpenAddVisitDrawer(true);
            },
            disabled: false,
            tooltip: undefined,
            copy: 'Add Visit',
            isCancelAction: false,
            hasBorderTop: false,
        },
    ];
    if (isMobile) {
        visitActions.push({
            function: () => {
                toggleModal();
            },
            disabled: false,
            tooltip: undefined,
            copy: 'Send Password Reset',
            isCancelAction: false,
            hasBorderTop: false,
        });
    }
    return (<>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ActionsBlock actions={visitActions}/>
          <AddFleetVisitDrawer open={openAddVisitDrawer} onClose={() => setOpenAddVisitDrawer(false)} fleet={fleet}/>
        </Grid>
        <Grid item xs={12}>
          <Title />
        </Grid>
        <Grid item xs={12}>
          <Info />
        </Grid>
        <Grid item xs={12}>
          <VehiclesRepairs />
        </Grid>
      </Grid>
      <SendResetPasswordModal openModal={openModal} toggleModal={toggleModal} isFleet onResetPasswordClick={onResetPasswordClick}/>
    </>);
};
export default Fleet;
