import React from 'react';
import useWrongPartDrawer, { WrongPartDrawerTabs, } from 'src/AdminApp/components/repairs/statement/Repairs/Drawers/WrongPartDrawer/useWrongPartDrawer';
import WrongPartNoteInput from 'src/AdminApp/components/repairs/statement/Repairs/Drawers/WrongPartDrawer/WrongPartNoteInput';
import WrongPartPhotoUpload from 'src/AdminApp/components/repairs/statement/Repairs/Drawers/WrongPartDrawer/WrongPartPhotoUpload';
import AddRepairOrderDrawer from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer';
import Slider from 'src/AdminApp/containers/repairs/AddRepairOrderDrawer/Slider';
import { FooterActionButton, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
const WrongPartDrawer = ({ repair, open, referenceNum, onClose, selectedComponent, }) => {
    const { setTab, tab, onWrongPartSubmit, note, setNote, photos, addPhoto, removePhoto, handleClose, isSubmitting, } = useWrongPartDrawer({
        repair,
        referenceNum,
        onClose,
        selectedComponent,
    });
    const routes = [
        {
            name: WrongPartDrawerTabs.NoteInput,
            render: () => <WrongPartNoteInput setNote={setNote} note={note}/>,
            footer: (<SimpleDrawerFooter>
          <FooterActionButton onClick={() => {
                    setTab(WrongPartDrawerTabs.PhotoUpload);
                }} disabled={note.length === 0}>
            Next
          </FooterActionButton>
        </SimpleDrawerFooter>),
        },
        {
            name: WrongPartDrawerTabs.PhotoUpload,
            render: () => (<WrongPartPhotoUpload photos={photos} addPhoto={addPhoto} removePhoto={removePhoto}/>),
            footer: (<SimpleDrawerFooter>
          <FooterActionButton onClick={onWrongPartSubmit} isSubmitting={isSubmitting}>
            Submit
          </FooterActionButton>
        </SimpleDrawerFooter>),
        },
    ];
    return (<AddRepairOrderDrawer open={open} onClose={handleClose} isSmall header="Wrong Part" arrowClose={tab === 0} footer={routes[tab].footer} onNavigateBack={() => {
            if (tab > 0)
                setTab(tab - 1);
        }}>
      <Slider currentSlide={tab} slides={routes}/>
    </AddRepairOrderDrawer>);
};
export default WrongPartDrawer;
