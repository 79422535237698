import { DateTimePicker, Grid, TableCell, TableRow, Typography, useMediaQuery, } from 'src/components/mui';
import { Tooltip } from 'src/components/tooltips';
import { InfoOutlined as InfoOutlinedIcon } from 'src/components/mui/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { displayResources } from 'src/AdminApp/containers/appointment/AppointmentModule/utils';
import { AppointmentDeliveryChannel, AppointmentStatus, DeliveryChannel, PartsStatus, } from 'src/AdminApp/models/enums';
import SkeduloJobResourceCategory from 'src/AdminApp/models/enums/SkeduloJobResourceCategory';
import { CopyToClipboardButton } from 'src/components/buttons';
import { Editable, FieldWrapper } from 'src/components/form/components';
import EditableSelect from 'src/components/form/EditableSelect';
import useAdminUser from 'src/hooks/useAdminUser';
import useAppointmentInfo from 'src/hooks/useAppointmentInfo';
import { selectAuthorizedAppointmentDateEditor } from 'src/modules/auth/selectors';
import { colors, theme } from 'src/styles/theme';
import { formatDate, formatPhone } from 'src/utils/formatter';
import styled from 'styled-components';
import { LOCALIZED_DATE_FORMAT, TIME_12H_FORMAT } from 'src/timeConstants';
import { StatusWrapper } from './styledComponents';
const getArrivalWindowString = ({ windowStart, windowEnd, timezone, }) => `${formatDate(windowStart, LOCALIZED_DATE_FORMAT)} • Arrival Window: ${formatDate(windowStart, TIME_12H_FORMAT)} - ${formatDate(windowEnd, TIME_12H_FORMAT, timezone)}`;
const formatAppointmentDateTime = (datetime) => formatDate(datetime, `${LOCALIZED_DATE_FORMAT} ${TIME_12H_FORMAT}`);
const DateField = ({ isEditable, windowStart, windowEnd, scheduledStart, actualStart, timezone, onDateUpdate, isMdOrSmallerDisplay, appointmentInfo, }) => {
    const [selectedDate, setSelectedDate] = useState(windowStart);
    const scheduledAndActualStartString = `Scheduled Start: ${formatAppointmentDateTime(scheduledStart)}`.concat(actualStart
        ? `\nActual Start: ${formatAppointmentDateTime(actualStart)}`
        : '');
    // eslint-disable-next-line react/no-unstable-nested-components
    const DisplayValue = () => (<>
      {getArrivalWindowString({
            windowStart,
            windowEnd,
            timezone,
        })}{' '}
      <Tooltip title={appointmentInfo}>
        <InfoOutlinedIcon style={{
            marginBottom: '-3px',
        }} fontSize="small"/>
      </Tooltip>
    </>);
    return (<Grid container>
      <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>
            {scheduledAndActualStartString}
          </div>}>
        <Grid item xs={11} md={11} lg={8} style={{
            display: 'flex',
        }}>
          <Editable isEditable={isEditable} displayValue={windowStart ? <DisplayValue /> : 'None'} renderForm={({ setIsEditing }) => (<DateTimePicker open value={selectedDate} onChange={(newDate) => {
                var _a;
                setSelectedDate((_a = newDate === null || newDate === void 0 ? void 0 : newDate.toString()) !== null && _a !== void 0 ? _a : null);
            }} onClose={() => setIsEditing(false)} onAccept={(_date) => {
                onDateUpdate(_date);
                setIsEditing(false);
            }} slotProps={{
                textField: {
                    fullWidth: true,
                },
            }}/>)}/>

          <CopyToClipboardButton value={getArrivalWindowString({ windowStart, windowEnd, timezone })} style={{ marginLeft: '-6px' }}/>
        </Grid>
      </Tooltip>

      {!isMdOrSmallerDisplay && (<ActualWrapper variant="subtitle1">
          {scheduledAndActualStartString}
        </ActualWrapper>)}
    </Grid>);
};
const ShopField = ({ channel, isEditable, shop, skeduloResources, isCanceled, }) => {
    var _a;
    const text = channel !== DeliveryChannel.DELIVERY
        ? (_a = shop.shopName) !== null && _a !== void 0 ? _a : 'None'
        : displayResources(channel, skeduloResources, shop, SkeduloJobResourceCategory.VAN, isCanceled);
    return (<FieldWrapper isEditable={isEditable} onDoubleClick={() => isEditable}>
      {text}
    </FieldWrapper>);
};
const ActualWrapper = styled(Typography) `
  font-weight: normal;
  width: 100%;
  white-space: pre-line;
`;
const PhoneWrapper = styled(Typography) `
  font-weight: normal;
`;
const StyledTableCell = styled(TableCell) `
  color: ${(props) => props.$canceled && colors.default};
  text-decoration: ${(props) => props.$canceled && 'line-through'};
  opacity: ${(props) => props.$canceled && '0.5'};
  font-weight: ${(props) => props.$canceled && '500'};

  ${ActualWrapper} {
    font-weight: ${(props) => props.$canceled && '500'};
    color: ${(props) => props.$canceled && colors.darkGrey};
  }

  ${PhoneWrapper} {
    font-weight: ${(props) => props.$canceled && '500'};
    color: ${(props) => props.$canceled && colors.darkGrey};
  }
`;
const AppointmentRow = ({ appointmentDate, appointmentResolution, appointmentTime, arrivalWindowEnd, arrivalWindowStart, actualStartLocal, canEditStatus = false, createdBy, deliveryChannel, ispContactInfo, modified, modifiedBy, onAppointmentCancel, onAppointmentStatusUpdate, onEditDateConfirmClick, onPartsStatusUpdate, otherResolution, partsStatus, skeduloJobId, skeduloJobResources, status, timezone, bookingTimestamp, bookedBy, }) => {
    const appointmentDateEditor = useSelector(selectAuthorizedAppointmentDateEditor);
    const appointmentInfo = useAppointmentInfo({
        appointmentResolution,
        created: bookingTimestamp,
        modified: modified,
        createdBy: useAdminUser({ user: createdBy }),
        canceledBy: useAdminUser({ user: modifiedBy }),
        otherResolution,
        status,
        bookedBy: bookedBy ? useAdminUser({ user: bookedBy }) : null,
    });
    const isMdOrSmallerDisplay = useMediaQuery(theme.breakpoints.down('md'));
    const date = appointmentDate ? `${appointmentDate} ${appointmentTime}` : null;
    const appointmentIsCanceled = AppointmentStatus.CANCELED === status;
    const appointmentIsClosed = AppointmentStatus.CLOSED === status;
    const isPendingDeliveryNewRequest = () => status === AppointmentStatus.PENDING &&
        deliveryChannel === AppointmentDeliveryChannel.DELIVERY &&
        !skeduloJobId;
    const isDateEditable = () => appointmentDateEditor && appointmentIsClosed;
    const isVanEditable = () => isPendingDeliveryNewRequest()
        ? false
        : status === AppointmentStatus.PENDING && !skeduloJobId;
    return (<TableRow>
      <StyledTableCell $canceled={appointmentIsCanceled}>
        <DateField windowStart={arrivalWindowStart} windowEnd={arrivalWindowEnd} scheduledStart={date} actualStart={actualStartLocal} onDateUpdate={onEditDateConfirmClick} isEditable={isDateEditable()} timezone={timezone} isMdOrSmallerDisplay={isMdOrSmallerDisplay} appointmentInfo={appointmentInfo}/>
      </StyledTableCell>
      <TableCell size="small">
        <StatusWrapper>
          {!!skeduloJobId && (ispContactInfo === null || ispContactInfo === void 0 ? void 0 : ispContactInfo.ispId) && (<a href={`/admin/swimlanes/${date === null || date === void 0 ? void 0 : date.substring(0, date.indexOf(' '))}?van=${ispContactInfo.ispId}`} rel="noreferrer" target="_blank" style={appointmentIsCanceled ? { textDecoration: 'line-through' } : {}}>
              Swimlane
            </a>)}
        </StatusWrapper>
      </TableCell>
      <StyledTableCell $canceled={appointmentIsCanceled}>
        <EditableSelect isEditable={canEditStatus && status !== AppointmentStatus.CANCELED} value={status} enumeration={AppointmentStatus} unsetOption={false} displayValue={<div style={appointmentIsCanceled ? { opacity: '0.5' } : {}}>
              {status}
            </div>} onUpdate={(status) => {
            if (AppointmentStatus.CANCELED === status) {
                onAppointmentCancel();
            }
            else {
                onAppointmentStatusUpdate({ status });
            }
        }}/>
      </StyledTableCell>
      <StyledTableCell $canceled={appointmentIsCanceled}>
        <EditableSelect isEditable={deliveryChannel === AppointmentDeliveryChannel.DELIVERY} value={partsStatus} displayValue={partsStatus ? PartsStatus.display(partsStatus).toUpperCase() : 'N/A'} onUpdate={(status) => onPartsStatusUpdate(status)} isMenuItemDisabled={({ key }) => key !== PartsStatus.IN_STOCK} enumeration={PartsStatus}/>
      </StyledTableCell>
      <StyledTableCell $canceled={appointmentIsCanceled}>
        <ShopField channel={deliveryChannel} isEditable={isVanEditable()} skeduloResources={skeduloJobResources} shop={ispContactInfo} isCanceled={appointmentIsCanceled}/>
      </StyledTableCell>
      <StyledTableCell $canceled={appointmentIsCanceled}>
        {displayResources(deliveryChannel, skeduloJobResources, ispContactInfo, SkeduloJobResourceCategory.MECHANIC, appointmentIsCanceled)}
        {(ispContactInfo === null || ispContactInfo === void 0 ? void 0 : ispContactInfo.phone) && (<PhoneWrapper variant="subtitle1">
            {formatPhone(ispContactInfo.phone)}
          </PhoneWrapper>)}
      </StyledTableCell>
    </TableRow>);
};
export default AppointmentRow;
