import { capitalize } from 'lodash';
/** this function parses a list of changelogs and returns a readable list of unique Entity names
 For example: when changelogs list contains these table names:
 'quote', 'suggested_repair', 'car_diagnosis', 'parts_prices_for_suggested_repair', 'fluid_prices', 'symptom'
 this function will return just one string: 'Quote'
 because there is no reason to display all table names as they all belong to the same business entity 'Quote'
 */
export function parseEntities(changelogs) {
    const entities = new Set();
    changelogs
        .map((change) => change.table)
        .filter((table) => table !== 'repair_order')
        .forEach((table) => {
        if ([
            'quote',
            'suggested_repair',
            'car_diagnosis',
            'parts_prices_for_suggested_repair',
            'fluid_prices',
            'symptom',
        ].includes(table)) {
            entities.add('Quote');
        }
        else {
            entities.add(parseTableName(table));
        }
    });
    return entities.size !== 0
        ? Array.from(entities)
        : changelogs.map((change) => change.table);
}
export function parseTableName(table) {
    if (table === undefined)
        return null;
    return capitalize(table.replaceAll('_', ' '));
}
/** this function returns a list of unique action names from a changelogs list */
export function parseActions(changelogs) {
    const actions = new Set(changelogs.map(({ action }) => {
        switch (action) {
            case 'INSERT':
                return 'added';
            case 'UPDATE':
                return 'changed';
            case 'DELETE':
                return 'removed';
            default:
                return action.toLowerCase();
        }
    }));
    return Array.from(actions);
}
