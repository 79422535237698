import React from 'react';
import moment from 'moment';
import { CalendarToday as CalendarTodayIcon } from 'src/components/mui/icons';
import { MobileDatePicker, Grid } from 'src/components/mui';
import { colors } from 'src/styles/theme';
import ScrollDatePicker from 'src/TechApp/components/ScrollDatePicker/Slider';
import AppointmentList from 'src/TechApp/components/AppointmentList';
import SimpleLoader from 'src/components/SimpleLoader';
import usePorterDashboard from './usePorterDashboard';
import { DateButton, HubInfoContainer } from '../TechApp.styles';
import { uniqBy, sortBy } from 'lodash';
const PorterDashboard = ({ match }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { porterId } = match.params;
    const { isLoading, open, setOpen, formattedDate, calendarDate, setCalendarDate, userId, porter, dashboard, setCurrentDateSlider, userIsPorter, } = usePorterDashboard({ porterId });
    if (!formattedDate)
        return null;
    if (!isLoading &&
        (!((_a = porter === null || porter === void 0 ? void 0 : porter[formattedDate]) === null || _a === void 0 ? void 0 : _a.regionIds) ||
            ((_c = (((_b = porter === null || porter === void 0 ? void 0 : porter[formattedDate]) === null || _b === void 0 ? void 0 : _b.regionIds) || [])) === null || _c === void 0 ? void 0 : _c.length) === 0)) {
        return (<div>
        ERROR - No regions found: Porter must be assigned to a region to access
        this item.
      </div>);
    }
    const hubs = ((_e = (_d = porter[formattedDate]) === null || _d === void 0 ? void 0 : _d.hubs) === null || _e === void 0 ? void 0 : _e.length) > 0
        ? uniqBy((_f = porter === null || porter === void 0 ? void 0 : porter[formattedDate]) === null || _f === void 0 ? void 0 : _f.hubs, 'id')
        : [];
    const items = sortBy(dashboard !== null && dashboard !== void 0 ? dashboard : [], [
        'partDistributorLocation.partDistributorName',
        'scheduledFor',
    ]);
    return (<div id="porters-app" style={{
            overflowX: 'hidden',
        }}>
      {porter && userId === porterId && userIsPorter ? (<>
          {(porter === null || porter === void 0 ? void 0 : porter[formattedDate]) && !isLoading && (<>
              <Grid container justifyContent="space-between" direction="row" alignItems="center">
                <Grid item xs={12} md={10}>
                  <h1>Hello, {(_g = porter[formattedDate]) === null || _g === void 0 ? void 0 : _g.firstName}!</h1>
                </Grid>
                {hubs.length > 0 && (<Grid item xs={12} md={2}>
                    <HubInfoContainer>
                      <span className="hub-title">Your Hub(s)</span>
                      {hubs.map((hub, index) => (<>
                          <span key={hub.id} className="hub-address">
                            {hub.locationAddress.fullAddress}
                          </span>
                          {index !== (hubs === null || hubs === void 0 ? void 0 : hubs.length) - 1 && <br />}
                        </>))}
                    </HubInfoContainer>
                  </Grid>)}
              </Grid>
              <Grid container justifyContent="space-between" direction="row" alignItems="center">
                <h2>Your Pickups</h2>

                <DateButton onClick={() => {
                    setOpen(true);
                }}>
                  <CalendarTodayIcon style={{ fontSize: '18px', color: colors.darkGrey }}/>
                  Jump to Date
                </DateButton>
                <MobileDatePicker open={open} onChange={(date) => {
                    setCalendarDate(moment(date));
                    setOpen(false);
                }} onAccept={() => setOpen(false)} value={calendarDate} slotProps={{
                    textField: {
                        component: () => null,
                    },
                    actionBar: {
                        hidden: true,
                    },
                    toolbar: {
                        hidden: true,
                    },
                }}/>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <ScrollDatePicker currentDate={calendarDate} setCurrentDate={setCurrentDateSlider}/>
                </Grid>
              </Grid>

              <AppointmentList dashboardAppointments={items} techName={(_h = porter[formattedDate]) === null || _h === void 0 ? void 0 : _h.firstName} isPorter/>
            </>)}
          {isLoading && <SimpleLoader />}
        </>) : (<div>
          ERROR - Not Authorized: You must be assigned to the Porters group to
          access this item.
        </div>)}
    </div>);
};
export default PorterDashboard;
