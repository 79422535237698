var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEmpty } from 'lodash';
import moment from 'moment';
import { calculateLatLong, getTimezoneByMapBox, } from 'src/clients/mapboxClient';
import createAPIAction from 'src/modules/api';
import createDefaultActionCreators, { createActionCreators, } from 'src/utils/createActions';
import { isExpired } from 'src/utils/data-utils';
import API_URLS from 'src/AdminApp/modules/api/urls';
import { selectShopById } from 'src/AdminApp/modules/shops/selectors';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
// FSA ACTIONS
export const ActionCreators = {
    SHOP: createDefaultActionCreators('SHOP'),
    SHOP_REGISTER: createDefaultActionCreators('SHOP_UPDATE'),
    SHOP_UPDATE: createDefaultActionCreators('SHOP_UPDATE'),
    SHOP_UPDATE_BY_ID: createDefaultActionCreators('SHOP_UPDATE_BY_ID'),
    SHOPS: createDefaultActionCreators('SHOPS', ['CLEAR']),
    SHOPS_SEARCH: createDefaultActionCreators('SHOPS_SEARCH'),
    CLEAR_SEARCHED_SHOPS: createActionCreators(['SUCCESS'], 'CLEAR_SEARCHED_SHOPS'),
    REPAIR_FOCUSES: createDefaultActionCreators('REPAIR_FOCUSES'),
    CAR_BRANDS: createDefaultActionCreators('CAR_BRANDS'),
    CAR_TYPES: createDefaultActionCreators('CAR_TYPES'),
    GET_REPAIR_TAGS: createDefaultActionCreators('GET_REPAIR_TAGS'),
};
export const searchShops = ({ shopName, referenceNum, filterByVehicle, integrated, sublet, filterByRepairs, salesStatus, latitude, longitude, carMake, carYear, suggestedRepairs, delivery, consumerLatitude, consumerLongitude, zip, }) => {
    const params = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!shopName && { shopName })), (!!salesStatus && { ispSalesStatus: salesStatus })), (integrated && { integrated: true })), (sublet && { sublet: true })), (filterByRepairs && !sublet && { repairFocus: suggestedRepairs })), (filterByRepairs && sublet && { subletRepairFocus: suggestedRepairs })), (!!latitude && {
        radius: 10,
        latitude,
        longitude,
    })), (filterByVehicle && {
        carMake,
        carYear,
    })), (!!delivery && { delivery })), (!!consumerLatitude && {
        consumerLatitude,
        consumerLongitude,
    })), (!!zip && { zip }));
    return createAPIAction({
        types: fillApiTypes(ActionCreators.SHOPS_SEARCH, {
            success: {
                meta: () => ({ referenceNum }),
            },
        }),
        endpoint: API_URLS.ADMIN.SHOPS_SEARCH(),
        params,
        method: 'GET',
    });
};
const getShopById = (id) => createAPIAction({
    types: fillApiTypes(ActionCreators.SHOP, {
        success: {
            meta: () => ({ id }),
        },
    }),
    endpoint: API_URLS.ADMIN.SHOPS_BY_ID(id),
    method: 'GET',
});
export const loadShop = (id) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const currentShopData = selectShopById(id)(getState());
    if (!isExpired(currentShopData))
        return;
    dispatch(getShopById(id));
});
export const updateShop = (values) => createAPIAction({
    types: fillApiTypes(ActionCreators.SHOP_UPDATE, {
        success: {
            meta: () => (Object.assign({}, values)),
        },
    }),
    endpoint: API_URLS.ADMIN.SHOP_UPDATE(values.id),
    method: 'PATCH',
    body: Object.assign({}, values),
});
export const updateShopById = (values) => createAPIAction({
    types: fillApiTypes(ActionCreators.SHOP_UPDATE_BY_ID, {
        success: {
            meta: () => (Object.assign({}, values)),
        },
    }),
    endpoint: API_URLS.ADMIN.SHOP_UPDATE(values.id),
    method: 'PUT',
    body: Object.assign({}, values),
});
export const getShops = ({ pageSize, pageIndex, sort = ['shopName,asc'], deliveryChannel, createdFrom, createdTo, shopName, radius, zip, integrated, deliveryVanStatus, city, salesStatus, status, }) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        let latLong;
        if (zip) {
            latLong = yield calculateLatLong({ zip });
        }
        const params = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort })), (!!salesStatus && { salesStatus })), (!!status && { status })), (integrated && { integrated })), (!!deliveryVanStatus && { deliveryVanStatus })), (!!shopName && !isEmpty(shopName) && { shopName })), (!!city && !isEmpty(city) && { city })), (!!deliveryChannel &&
            !isEmpty(deliveryChannel) && { delivery: deliveryChannel })), (!!latLong && {
            latitude: latLong.latitude,
            longitude: latLong.longitude,
            radius: radius || 10,
        }));
        const patchedParams = Object.assign(Object.assign({}, (!!createdFrom && {
            createdFrom: moment(createdFrom).startOf('day').utc().format(),
        })), (!!createdTo && {
            createdTo: moment(createdTo)
                .startOf('day')
                .add(1, 'days')
                .utc()
                .format(),
        }));
        return dispatch(createAPIAction({
            types: fillApiTypes(ActionCreators.SHOPS, {
                fetch: {
                    meta: () => ({ pageSize, pageIndex, sort, params }),
                },
                success: {
                    meta: () => ({ pageSize, pageIndex, sort, params }),
                },
            }),
            endpoint: API_URLS.ADMIN.SHOPS_DASHBOARD(),
            params: Object.assign(Object.assign({}, params), patchedParams),
            method: 'GET',
        }));
    });
};
export const clearSearchedShops = (referenceNum) => ({
    type: ActionCreators.CLEAR_SEARCHED_SHOPS.SUCCESS.type,
    referenceNum,
});
export const getRepairFocuses = () => createAPIAction({
    types: fillApiTypes(ActionCreators.REPAIR_FOCUSES),
    endpoint: API_URLS.ADMIN.SHOP_REPAIR_FOCUSES(),
    method: 'GET',
});
export const getCarBrands = () => createAPIAction({
    types: fillApiTypes(ActionCreators.CAR_BRANDS),
    endpoint: API_URLS.ADMIN.SHOP_CAR_BRANDS(),
    method: 'GET',
});
export const getCarTypes = () => createAPIAction({
    types: fillApiTypes(ActionCreators.CAR_TYPES),
    endpoint: API_URLS.ADMIN.SHOP_CAR_TYPES(),
    method: 'GET',
});
export const registerShop = (values) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const { zip = null, address = null, city = null, state = null } = values;
    const latLongParams = Object.assign(Object.assign(Object.assign(Object.assign({}, (!!address && { street: address })), (!!city && { city })), (!!state && { state })), (!!zip && { zip }));
    const latLong = zip && latLongParams
        ? yield calculateLatLong({ address: latLongParams })
        : {};
    const timezone = yield getTimezoneByMapBox(latLong.latitude, latLong.longitude);
    const body = Object.assign(Object.assign(Object.assign({}, values), latLong), { timezone });
    return dispatch(createAPIAction({
        types: fillApiTypes(ActionCreators.SHOP_REGISTER, {
            success: {
                meta: () => (Object.assign({}, values)),
            },
        }),
        endpoint: API_URLS.ADMIN.SHOP_REGISTER(),
        body,
        method: 'POST',
    }));
});
export const getRepairSkillsByRepairIds = (repairIds) => {
    return createAPIAction({
        types: fillApiTypes(ActionCreators.GET_REPAIR_TAGS),
        endpoint: API_URLS.PRICING.REPAIR_TAGS(repairIds),
        method: 'GET',
    });
};
