import React, { useState } from 'react';
import { Tabs, Tab } from 'src/components/mui';
import { CloseRounded as CloseRoundedIcon } from 'src/components/mui/icons';
import InternalNotes from 'src/PartnersApp/pages/PartnerManagement/PartnerDetailsDialog/InternalNotes';
import Assets from 'src/PartnersApp/pages/PartnerManagement/PartnerDetailsDialog/Assets';
import { CloseButton, PartnerDetailsDialogContainer, PartnerDetailsDialogContent, } from './PartnerDetailsDialog.styles';
const PartnerDetailsDialog = ({ partner, open, onClose, }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };
    return (<PartnerDetailsDialogContainer open={open} onClose={onClose} maxWidth="md" fullWidth>
      <PartnerDetailsDialogContent>
        <Tabs value={selectedTab} onChange={handleChangeTab} indicatorColor="primary">
          <Tab label="Internal Notes"/>
          <Tab label="Assets"/>
        </Tabs>

        <CloseButton color="primary" aria-label="Close Partner Details" className="PartnerDetails__closeBtn" onClick={onClose}>
          <CloseRoundedIcon />
        </CloseButton>

        {selectedTab === 0 && (<InternalNotes partner={partner} onClose={onClose}/>)}
        {selectedTab === 1 && <Assets partner={partner} onClose={onClose}/>}
      </PartnerDetailsDialogContent>
    </PartnerDetailsDialogContainer>);
};
export default PartnerDetailsDialog;
