import { IconButton, Grid, Drawer } from 'src/components/mui';
import { NavLink } from 'src/containers/utils';
import styled from 'styled-components';
import { colors } from 'src/styles/theme';
export const Gap = styled.div `
  flex-grow: 1;
`;
export const CloseButton = styled(IconButton) `
  padding: 0;
`;
export const SignOutLink = styled(NavLink) `
  font-family: Interstate;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.3px;
  color: ${colors.default};
`;
export const BottomBlock = styled(Grid) `
  padding: 25px 24px 72px 24px;
`;
export const TopBlock = styled.div `
  overflow: auto;
  flex: 1;
  padding-left: 16px;
`;
export const DrawerHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.black};
  color: ${colors.white};
  padding: 0 16px;

  span {
    font-family: Interstate;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
    text-align: center;
    color: ${colors.white};
  }
`;
export const ActionIcon = styled(IconButton) `
  padding: 0;
`;
export const MobileDrawer = styled(Drawer) `
  .MuiDrawer-paper {
    width: 100%;
  }
`;
