import React from 'react';
import { isEmpty } from 'lodash';
import { AddCircle } from 'src/components/mui/icons';
import { DISCOUNT_LIMITS } from 'src/AdminApp/modules/api/constants';
import { isLimitExceeded } from 'src/AdminApp/components/discounts/utils';
import SimpleRowButton from 'src/components/layout/SimpleRowButton';
import DiscountDrawer from 'src/AdminApp/components/discounts/components/DiscountDrawer';
import useAddDiscountDrawer from './useAddDiscountDrawer';
const AddDiscountDrawer = ({ onAddPromoCode, onAddCFCredit, onAddOtherDiscount, promos = null, credits = null, others = null, cancelFeeCredits = [], }) => {
    const { open, openHandler, closeHandler, onAddPromo, onAddCredit, onAddOther, } = useAddDiscountDrawer({
        onAddPromoCode,
        onAddCFCredit,
        onAddOtherDiscount,
    });
    const canAddPromo = !isLimitExceeded(promos, DISCOUNT_LIMITS.PROMO_CODE);
    const canAddCancelFeeCredit = !isEmpty(cancelFeeCredits) &&
        !isLimitExceeded(credits, DISCOUNT_LIMITS.CANCEL_FEE_CREDIT);
    const canAddOtherDiscount = !isLimitExceeded(others, DISCOUNT_LIMITS.OTHER_DISCOUNT);
    const disableOptions = {
        promoCode: !canAddPromo,
        customDiscount: !canAddOtherDiscount,
        cancelFeeCredit: !canAddCancelFeeCredit,
    };
    const isDisabled = !canAddPromo && !canAddCancelFeeCredit && !canAddOtherDiscount;
    return (<>
      <SimpleRowButton color="success" disabled={isDisabled} onClick={openHandler} startIcon={<AddCircle />}>
        Discount
      </SimpleRowButton>

      <DiscountDrawer open={open} cancelFeeCredits={cancelFeeCredits} closeHandler={closeHandler} onPromoCodeSubmit={onAddPromo} onCreditSubmit={onAddCredit} onOtherSubmit={onAddOther} disableOptions={disableOptions}/>
    </>);
};
export default AddDiscountDrawer;
