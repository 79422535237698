var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'src/components/SimpleToast';
import useGetHubsFromVan from 'src/TechApp/components/AppointmentList/PartPickupWorkflow/useGetHubsFromVan';
import { completePartPickupTask, fetchPartPickupOrder, fetchPartPickupOrderByReferenceNum, getPartPickupTaskWorkflow, } from 'src/TechApp/modules/partpickup/actions';
import { selectPartPickupOrder, selectPartPickupWorkflow, selectShouldAutocompleteSelectPart, } from 'src/TechApp/modules/partpickup/selectors';
const usePartPickupOrderDetails = ({ partPickupOrderId, }) => {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const { referenceNum } = useParams();
    const [currentLocation, setCurrentLocation] = useState();
    const partPickupOrder = useSelector(selectPartPickupOrder);
    const partPickupWorkflow = useSelector(selectPartPickupWorkflow);
    const shouldAutocompleteSelectPart = useSelector(selectShouldAutocompleteSelectPart);
    const isPickupAtHub = ((_a = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.pickupAtDistributor) === null || _a === void 0 ? void 0 : _a.partDistributorName) ===
        'RepairSmith Hub';
    useEffect(() => {
        let unmountedComponent = false;
        if (referenceNum) {
            dispatch(fetchPartPickupOrderByReferenceNum(referenceNum));
            navigator.geolocation.getCurrentPosition((position) => {
                if (unmountedComponent)
                    return;
                setCurrentLocation(position.coords);
            });
        }
        return () => {
            unmountedComponent = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referenceNum]);
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(fetchPartPickupOrder(partPickupOrderId));
        if (!response.error && response.payload) {
            const partPickupOrderObj = response.payload;
            yield dispatch(getPartPickupTaskWorkflow(partPickupOrderObj.referenceNum));
        }
    });
    useEffect(() => {
        let intervalId = null;
        fetchData();
        intervalId = setInterval(fetchData, 10000);
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const van = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.resources.find((resource) => resource.category === 'VAN');
    const { hasHubs, isLoadingHubs } = useGetHubsFromVan(van === null || van === void 0 ? void 0 : van.resourceId);
    const completeCurrentTask = () => __awaiter(void 0, void 0, void 0, function* () {
        var _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        if (!(partPickupWorkflow === null || partPickupWorkflow === void 0 ? void 0 : partPickupWorkflow.currentTask)) {
            return;
        }
        const response = yield dispatch(completePartPickupTask(partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum, partPickupWorkflow.currentTask.id, Object.assign(Object.assign({}, partPickupWorkflow.currentTask), { coordinates: {
                latitude: (_e = currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.latitude) !== null && _e !== void 0 ? _e : 0.0,
                longitude: (_f = currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.longitude) !== null && _f !== void 0 ? _f : 0.0,
            } })));
        if (((_h = (_g = response.payload) === null || _g === void 0 ? void 0 : _g.currentTask) === null || _h === void 0 ? void 0 : _h.status) === 'COMPLETED' &&
            ((_k = (_j = response.payload) === null || _j === void 0 ? void 0 : _j.currentTask) === null || _k === void 0 ? void 0 : _k.state) !== 'SELECT_PARTS' &&
            ((_m = (_l = response.payload) === null || _l === void 0 ? void 0 : _l.currentTask) === null || _m === void 0 ? void 0 : _m.state) !== 'COMPLETE') {
            toast.success(`The ${(_p = (_o = response.payload) === null || _o === void 0 ? void 0 : _o.currentTask) === null || _p === void 0 ? void 0 : _p.state} task was successfully completed`);
        }
        dispatch(getPartPickupTaskWorkflow(partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.referenceNum));
    });
    useEffect(() => {
        if (shouldAutocompleteSelectPart) {
            completeCurrentTask();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldAutocompleteSelectPart]);
    const vendorName = isPickupAtHub
        ? (_b = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _b === void 0 ? void 0 : _b.partDistributorName
        : (_c = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _c === void 0 ? void 0 : _c.dealershipName;
    const phone = (_d = partPickupOrder === null || partPickupOrder === void 0 ? void 0 : partPickupOrder.partDistributorLocation) === null || _d === void 0 ? void 0 : _d.phone;
    return {
        partPickupOrder,
        isLoadingHubs,
        isPickupAtHub,
        vendorName,
        phone,
        currentLocation,
        completeCurrentTask,
        hasHubs,
    };
};
export default usePartPickupOrderDetails;
