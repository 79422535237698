var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CONFIG from 'src/config';
import { formatAdditionalInfo } from 'src/AdminApp/components/repairs/statement/utils';
import { selectCurrentUserIsTechnician } from 'src/AdminApp/modules/users/selectors';
import { getPartDistributors } from 'src/PopsApp/modules/vendors/actions';
import { selectPartDistributors } from 'src/PopsApp/modules/vendors/selectors';
const useEditFluidDrawer = ({ componentPartDistributor, open, onSubmit, invoice, status, adding, isShowAdditionalInfo = false, }) => {
    const dispatch = useDispatch();
    const partDistributors = useSelector(selectPartDistributors);
    const [isConfirmCapModalOpen, setIsConfirmCapModalOpen] = useState(false);
    const [isAddPartToPickupDialogOpen, setIsAddPartToPickupDialogOpen] = useState(false);
    const [dynamicCreationConfirm, setDynamicCreationConfirm] = useState(false);
    const [idComponentDataCorrect, setIdComponentDataCorrect] = useState(false);
    const [confirmRemoveDynamicRule, setConfirmRemoveDynamicRule] = useState(false);
    const [tab, setTab] = useState('general');
    const userIsInTechnicianGroup = useSelector(selectCurrentUserIsTechnician);
    const canEditComponentDetails = adding || !userIsInTechnicianGroup;
    const forceComponentSelection = CONFIG.ENABLE_FORCE_COMPONENT_DB_SELECTION === 'true';
    const [formValues, setFormValues] = useState();
    useEffect(() => {
        if (open && !partDistributors) {
            dispatch(getPartDistributors());
        }
        return () => {
            setTab('general');
        };
    }, [open]); // eslint-disable-line
    useEffect(() => {
        if (isShowAdditionalInfo) {
            setTab('additional');
        }
    }, [isShowAdditionalInfo]);
    const onAddPartToPickupConfirm = (addToPartPickup) => {
        setIsAddPartToPickupDialogOpen(false);
        handleSubmit(formatAdditionalInfo(formValues === null || formValues === void 0 ? void 0 : formValues.values), formValues === null || formValues === void 0 ? void 0 : formValues.resetForm, formValues === null || formValues === void 0 ? void 0 : formValues.setSubmitting, addToPartPickup);
    };
    const onConfirmDynamicCreation = () => {
        setDynamicCreationConfirm(true);
    };
    const onConfirmDataCorrectness = () => {
        setDynamicCreationConfirm(false);
        setIdComponentDataCorrect(true);
    };
    const handleCloseDialogs = () => {
        setDynamicCreationConfirm(false);
        setIdComponentDataCorrect(false);
        setConfirmRemoveDynamicRule(false);
    };
    const handleSubmitWithoutDynamicCreation = (values, resetForm, setSubmitting, addToPartPickup) => {
        handleCloseDialogs();
        const updatedValues = Object.assign(Object.assign({}, values), { addAsDynamicComponent: false });
        handleSubmit(updatedValues, resetForm, setSubmitting, addToPartPickup);
    };
    const handleSubmit = (values, resetForm, setSubmitting, addToPartPickup = false) => __awaiter(void 0, void 0, void 0, function* () {
        handleCloseDialogs();
        yield onSubmit(Object.assign(Object.assign({}, values), { specialOrder: !!values.specialOrder, leadTime: values.specialOrder ? values.leadTime : null, addToPartPickup }));
        resetForm({ values: Object.assign({}, values) });
        setSubmitting(false);
    });
    const isAboutToBeOrderedFromNewLocation = (values, componentPartDistributor) => {
        const newLocationId = !values.partDistributorLocationId
            ? null
            : values.partDistributorLocationId;
        return ((newLocationId || values.address) &&
            values.partNumber &&
            (status !== 'ORDERED' ||
                newLocationId !== (componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.partDistributorLocationId) ||
                (!newLocationId &&
                    values.address !== (componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.address))));
    };
    const handleSubmitWithDrawer = (values, resetForm, setSubmitting) => {
        setDynamicCreationConfirm(false);
        setIdComponentDataCorrect(false);
        const formattedValues = formatAdditionalInfo(values);
        const isNotInvoice = invoice === undefined;
        const isAboutToBeOrdered = isAboutToBeOrderedFromNewLocation(values, componentPartDistributor);
        if (isAboutToBeOrdered && isNotInvoice) {
            setIsAddPartToPickupDialogOpen(true);
            setFormValues({ values, resetForm, setSubmitting });
        }
        else {
            setSubmitting(true);
            handleSubmit(formattedValues, resetForm, setSubmitting);
        }
    };
    const getPartDistributor = useCallback(() => {
        if (componentPartDistributor === null || componentPartDistributor === void 0 ? void 0 : componentPartDistributor.id) {
            return {
                id: componentPartDistributor.id,
                name: componentPartDistributor.partDistributorName,
                status: '',
            };
        }
        return null;
    }, [componentPartDistributor]);
    const onAddressSelect = (address, setFieldValue) => {
        if (!address) {
            setFieldValue('address', '');
            setFieldValue('notes', '');
            return;
        }
        setFieldValue('address', address.addressStr);
        if (address === null || address === void 0 ? void 0 : address.partDistributorLocation) {
            setFieldValue('partDistributorLocationId', address.id);
        }
        else {
            setFieldValue('partDistributorLocationId', undefined);
        }
        if (address === null || address === void 0 ? void 0 : address.dealershipName) {
            setFieldValue('partDistributorDealershipName', address.dealershipName);
        }
    };
    const handleTabChange = (e, newValue) => setTab(newValue);
    return {
        tab,
        handleTabChange,
        partDistributors,
        isConfirmCapModalOpen,
        setIsConfirmCapModalOpen,
        isAddPartToPickupDialogOpen,
        setIsAddPartToPickupDialogOpen,
        formValues,
        setFormValues,
        getPartDistributor,
        onAddressSelect,
        onAddPartToPickupConfirm,
        handleSubmit,
        handleSubmitWithDrawer,
        onConfirmDynamicCreation,
        onConfirmDataCorrectness,
        dynamicCreationConfirm,
        idComponentDataCorrect,
        handleSubmitWithoutDynamicCreation,
        handleCloseDialogs,
        confirmRemoveDynamicRule,
        setConfirmRemoveDynamicRule,
        canEditComponentDetails,
        forceComponentSelection,
    };
};
export default useEditFluidDrawer;
