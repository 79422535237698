import { useState } from 'react';
const useWorkOrderItem = ({ workOrder }) => {
    var _a, _b, _c, _d, _e;
    const [itemExpanded, setItemExpanded] = useState(true);
    const mechanicDisplayName = (_a = workOrder === null || workOrder === void 0 ? void 0 : workOrder.mechanicName) !== null && _a !== void 0 ? _a : 'Not Assigned';
    let cancelationReason = (_e = (_c = (_b = workOrder.resolution) === null || _b === void 0 ? void 0 : _b.specificReason) !== null && _c !== void 0 ? _c : (_d = workOrder.resolution) === null || _d === void 0 ? void 0 : _d.reason) !== null && _e !== void 0 ? _e : 'N/A';
    if (cancelationReason.length === 0) {
        cancelationReason = 'N/A';
    }
    const handleAccordionOnChange = (event, expanded) => {
        setItemExpanded(expanded);
    };
    const allowRemove = (workOrder.repairOrderStatus === 'PENDING' ||
        workOrder.repairOrderStatus === 'IN_PROGRESS') &&
        workOrder.status !== 'REMOVED';
    return {
        itemExpanded,
        handleAccordionOnChange,
        mechanicDisplayName,
        allowRemove,
        cancelationReason,
    };
};
export default useWorkOrderItem;
