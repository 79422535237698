import React from 'react';
import { Link } from 'react-router-dom';
import { getZendeskUrl } from 'src/AdminApp/utils/url-utils';
import { formatPhone } from 'src/utils/formatter';
import SimpleTableRow from 'src/components/dashboard/SimpleTable/SimpleTableRow';
const DashboardListItem = ({ id, firstName, lastName, email, phone, zendeskUserId = '', }) => (<SimpleTableRow data={[
        <Link to={`/admin/consumers/${id}`}>{id}</Link>,
        <a href={getZendeskUrl(`/agent/users/${zendeskUserId}`)} target="_blank" rel="noreferrer">
        {zendeskUserId}
      </a>,
        <Link to={`/admin/consumers/${id}`}>
        {firstName} {lastName}
      </Link>,
        <>
        <div>{email}</div>
        <div>{formatPhone(phone)}</div>
      </>,
    ]}/>);
export default DashboardListItem;
