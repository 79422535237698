var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import API_URLS from 'src/AdminApp/modules/api/urls';
import { toast } from 'src/components/SimpleToast';
import createAPIAction from 'src/modules/api';
import createAction from 'src/modules/api/utils/createAction';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createDefaultActionCreators from 'src/utils/createActions';
// FSA ACTIONS
export const ActionCreators = {
    SEARCH_FLEETS: createDefaultActionCreators('SEARCH_FLEETS', ['CLEAR']),
    SEARCH_FLEETS_BY_NAME: createDefaultActionCreators('SEARCH_FLEETS_BY_NAME'),
    SEARCH_FLEETS_BY_STATION: createDefaultActionCreators('SEARCH_FLEETS_BY_STATION'),
    SEARCH_STATIONS_BY_NAME: createDefaultActionCreators('SEARCH_STATIONS_BY_NAME'),
    CREATE_FLEET: createDefaultActionCreators('CREATE_FLEET'),
    GET_FLEET: createDefaultActionCreators('GET_FLEET'),
    GET_PRODUCT_FLEET_SEARCH: createDefaultActionCreators('GET_PRODUCT_FLEET_SEARCH', ['CLEAR']),
    UPDATE_FLEET: createDefaultActionCreators('UPDATE_FLEET'),
    GET_FLEET_CARS: createDefaultActionCreators('GET_FLEET_CARS'),
    GET_ALL_FLEET_CARS: createDefaultActionCreators('GET_ALL_FLEET_CARS'),
    ADD_FLEET_CAR: createDefaultActionCreators('ADD_FLEET_CAR'),
    PROCESS_ELEMENT_PM_CSV: createDefaultActionCreators('PROCESS_ELEMENT_PM_CSV'),
    PROCESS_LEASE_PLAN_PM_CSV: createDefaultActionCreators('PROCESS_LEASE_PLAN_PM_CSV'),
    UPDATE_FLEET_CAR: createDefaultActionCreators('UPDATE_FLEET_CAR'),
    UPDATE_FLEET_CAR_STATUS: createDefaultActionCreators('UPDATE_FLEET_CAR_STATUS'),
    UPLOAD_FILES: createDefaultActionCreators('UPLOAD_FILES'),
    GET_FLEET_REPAIR_REQUESTS: createDefaultActionCreators('GET_FLEET_REPAIR_REQUESTS'),
    GET_FLEET_INVOICES: createDefaultActionCreators('GET_FLEET_INVOICES'),
    SEARCH_FLEET_REPAIR_REQUESTS: createDefaultActionCreators('SEARCH_FLEET_REPAIR_REQUESTS'),
    GET_FMC: createDefaultActionCreators('GET_FMC'),
    CREATE_FMC: createDefaultActionCreators('CREATE_FMC'),
    UPDATE_FMC: createDefaultActionCreators('UPDATE_FMC'),
    DELETE_FMC: createDefaultActionCreators('DELETE_FMC'),
    SEARCH_FMC_BY_NAME: createDefaultActionCreators('SEARCH_FMC_BY_NAME'),
    SEARCH_CARS: createDefaultActionCreators('SEARCH_CARS'),
    GET_RESPONSIBLE_PARTY_FOR_FLEET: createDefaultActionCreators('GET_RESPONSIBLE_PARTY_FOR_FLEET'),
    UPDATE_FLEET_RESPONSIBLE_PARTY: createDefaultActionCreators('UPDATE_FLEET_RESPONSIBLE_PARTY'),
    GET_PART_DISTRIBUTOR_WITH_LOCATIONS: createDefaultActionCreators('GET_PART_DISTRIBUTOR_WITH_LOCATIONS'),
    UPDATE_FLEET_RESPONSIBLE_PARTIES: createDefaultActionCreators('UPDATE_FLEET_RESPONSIBLE_PARTIES'),
    GET_FLEET_ACCOUNT_OWNERS: createDefaultActionCreators('GET_ACCOUNT_OWNERS'),
    CREATE_FLEET_ACCOUNT_OWNERS: createDefaultActionCreators('CREATE_ACCOUNT_OWNERS'),
    UPDATE_FLEET_ACCOUNT_OWNERS: createDefaultActionCreators('UPDATE_ACCOUNT_OWNERS'),
    GET_FLEET_RESPONSIBLE_PARTIES_BY_CAR: createDefaultActionCreators('GET_FLEET_RESPONSIBLE_PARTIES_BY_CAR'),
    UPDATE_FLEET_VEHICLE_RESPONSIBLE_PARTIES: createDefaultActionCreators('UPDATE_FLEET_VEHICLE_RESPONSIBLE_PARTIES'),
    UPDATE_FLEET_RESPONSIBLE_PARTY_BILLING_CONTACTS: createDefaultActionCreators('UPDATE_FLEET_RESPONSIBLE_PARTY_BILLING_CONTACTS'),
};
export const searchFleets = ({ pageSize, pageIndex, sort = ['name,asc'], contactName, email, phone, parentId, hasParent, query, }) => {
    const params = Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort }));
    return createAPIAction({
        types: [
            ActionCreators.SEARCH_FLEETS.FETCH({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.SEARCH_FLEETS.SUCCESS({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.SEARCH_FLEETS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEETS(),
        params: Object.assign(Object.assign({}, params), { contactName,
            email,
            phone,
            parentId, name: query, hasParent }),
        method: 'GET',
    });
};
export const searchFleetsByName = ({ name, allResults = false, }) => {
    const params = {
        size: allResults ? 9999 : 10,
        pageIndex: 1,
        sort: ['name,asc'],
    };
    return createAPIAction({
        types: [
            ActionCreators.SEARCH_FLEETS_BY_NAME.FETCH(),
            ActionCreators.SEARCH_FLEETS_BY_NAME.SUCCESS(),
            ActionCreators.SEARCH_FLEETS_BY_NAME.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEETS(),
        params: Object.assign(Object.assign({}, params), { name }),
        method: 'GET',
    });
};
export const searchFleetsByStation = ({ station, size = 10, }) => {
    const params = {
        size,
        pageIndex: 0,
        sort: ['name,asc'],
    };
    return createAPIAction({
        types: [
            ActionCreators.SEARCH_FLEETS_BY_STATION.FETCH(),
            ActionCreators.SEARCH_FLEETS_BY_STATION.SUCCESS(),
            ActionCreators.SEARCH_FLEETS_BY_STATION.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEETS(),
        params: Object.assign(Object.assign({}, params), { station }),
        method: 'GET',
    });
};
export const clearStationsSearchResults = createAction('CLEAR_STATIONS_SEARCH_RESULTS');
export const clearFleetsSearchResults = createAction('CLEAR_FLEETS_SEARCH_RESULTS');
export const searchStationsByName = ({ query }) => {
    const params = {
        pageSize: 10,
        pageIndex: 1,
        sort: ['name,asc'],
    };
    return createAPIAction({
        types: [
            ActionCreators.SEARCH_STATIONS_BY_NAME.FETCH(),
            ActionCreators.SEARCH_STATIONS_BY_NAME.SUCCESS(),
            ActionCreators.SEARCH_STATIONS_BY_NAME.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.STATIONS(),
        params: Object.assign(Object.assign({}, params), { query }),
        method: 'GET',
    });
};
export const searchFMCByName = ({ name }) => {
    const params = {
        pageSize: 10,
        pageIndex: 1,
        sort: ['name,asc'],
    };
    return createAPIAction({
        types: [
            ActionCreators.SEARCH_FMC_BY_NAME.FETCH(),
            ActionCreators.SEARCH_FMC_BY_NAME.SUCCESS(),
            ActionCreators.SEARCH_FMC_BY_NAME.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FMCS(),
        params: Object.assign(Object.assign({}, params), { name }),
        method: 'GET',
    });
};
export const getFleet = (fleetId) => {
    return createAPIAction({
        types: [
            ActionCreators.GET_FLEET.FETCH(),
            ActionCreators.GET_FLEET.SUCCESS({ meta: () => ({ fleetId }) }),
            ActionCreators.GET_FLEET.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_BY_ID(fleetId),
        method: 'GET',
    });
};
export const getProductFleetSearch = (fleetId, productId) => {
    return createAPIAction({
        types: [
            ActionCreators.GET_PRODUCT_FLEET_SEARCH.FETCH(),
            ActionCreators.GET_PRODUCT_FLEET_SEARCH.SUCCESS({
                meta: () => ({ fleetId, productId }),
            }),
            ActionCreators.GET_PRODUCT_FLEET_SEARCH.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_BY_ID(fleetId),
        method: 'GET',
    });
};
export const createFleet = ({ name, dspName, station, contactName, phone, email, dailyOpsContactName, dailyOpsContactPhone, dailyOpsContactEmail, parentId, consumerId, consumer, fleetManagementCompany, taxExempt, type, marketingSourceId, marketingName, }) => createAPIAction({
    types: [
        ActionCreators.CREATE_FLEET.FETCH(),
        ActionCreators.CREATE_FLEET.SUCCESS(),
        ActionCreators.CREATE_FLEET.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.FLEETS(),
    body: {
        name,
        dspName,
        station,
        contactName,
        phone,
        email,
        dailyOpsContactName,
        dailyOpsContactPhone,
        dailyOpsContactEmail,
        parentId,
        consumerId,
        consumer: consumer && consumer.address ? consumer : undefined,
        fleetManagementCompany,
        taxExempt,
        type,
        marketingSourceId,
        marketingName,
    },
    method: 'POST',
});
export const updateFleet = (values) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield createAPIAction({
        types: [
            ActionCreators.UPDATE_FLEET.FETCH(),
            ActionCreators.UPDATE_FLEET.SUCCESS({
                meta: () => ({ fleetId: values.id }),
            }),
            ActionCreators.UPDATE_FLEET.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_BY_ID(values.id),
        method: 'PUT',
        body: Object.assign(Object.assign({}, values), { laborRate: values.laborRate === '' ? null : values.laborRate }),
    });
    if (!(response === null || response === void 0 ? void 0 : response.error) &&
        (values.initParent || values.oldParentId) &&
        values.oldParentId !== values.parentId) {
        toast.success('Parent fleet has been changed. Please re-index the search to complete the update.');
    }
    return response;
});
export const patchUpdateFleet = (fleetId, values) => createAPIAction({
    types: [
        ActionCreators.UPDATE_FLEET.FETCH(),
        ActionCreators.UPDATE_FLEET.SUCCESS({
            meta: () => ({ fleetId }),
        }),
        ActionCreators.UPDATE_FLEET.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.FLEET_BY_ID(fleetId),
    method: 'PATCH',
    body: Object.assign({}, values),
});
export const getFleetCars = ({ fleetId, pageSize, pageIndex, sort = ['carMake,asc'], }) => {
    const params = Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort }));
    return createAPIAction({
        types: [
            ActionCreators.GET_FLEET_CARS.FETCH({
                meta: () => ({ fleetId, pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.GET_FLEET_CARS.SUCCESS({
                meta: () => ({ fleetId, pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.GET_FLEET_CARS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_CARS_BY_ID(fleetId),
        params: Object.assign({}, params),
        method: 'GET',
    });
};
export const getAllFleetCars = (fleetId) => {
    return createAPIAction({
        types: [
            ActionCreators.GET_ALL_FLEET_CARS.FETCH(),
            ActionCreators.GET_ALL_FLEET_CARS.SUCCESS(),
            ActionCreators.GET_ALL_FLEET_CARS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.ALL_FLEET_CARS_BY_ID(fleetId),
        method: 'GET',
    });
};
export const searchCars = ({ query = null, vin = null, plateNumber = null, nickname = null, id = null, fleetId = null, pageSize = 20, pageIndex = 0, sort = ['carMake,asc'], }) => {
    const params = Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort }));
    return createAPIAction({
        types: [
            ActionCreators.SEARCH_CARS.FETCH({
                meta: () => ({ query, fleetId, pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.SEARCH_CARS.SUCCESS({
                meta: () => ({ query, fleetId, pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.SEARCH_CARS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.CARS_SEARCH(),
        method: 'GET',
        params: Object.assign({ vin,
            plateNumber,
            nickname,
            id,
            fleetId,
            query }, params),
    });
};
export const searchFleetRepairRequests = ({ query, fleetId, pageSize = 20, pageIndex = 0, sort = ['created,asc'], }) => {
    const params = {
        searchFilter: query,
        size: pageSize,
        page: pageIndex,
        sort,
    };
    return createAPIAction({
        types: [
            ActionCreators.SEARCH_FLEET_REPAIR_REQUESTS.FETCH({
                meta: () => ({ query, fleetId, pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.SEARCH_FLEET_REPAIR_REQUESTS.SUCCESS({
                meta: () => ({ query, fleetId, pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.SEARCH_FLEET_REPAIR_REQUESTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_REPAIR_REQUESTS(fleetId),
        params: Object.assign({}, params),
        method: 'GET',
    });
};
export const getFleetInvoices = ({ fleetId, pageSize = 20, pageIndex = 0, sort = ['appointmentDate,desc'], query, }) => {
    const params = {
        size: pageSize,
        page: pageIndex,
        sort,
        invoiceId: query,
    };
    return createAPIAction({
        types: [
            ActionCreators.GET_FLEET_INVOICES.FETCH({
                meta: () => ({ fleetId, pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.GET_FLEET_INVOICES.SUCCESS({
                meta: () => ({ fleetId, pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.GET_FLEET_INVOICES.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_INVOICES(fleetId),
        params: Object.assign({}, params),
        method: 'GET',
    });
};
export const getFleetRepairsRequests = ({ fleetId, pageSize, pageIndex, sort = ['created,asc'], query, }) => {
    const params = {
        searchFilter: query || undefined,
        size: pageSize,
        page: pageIndex,
        sort,
    };
    return createAPIAction({
        types: [
            ActionCreators.GET_FLEET_REPAIR_REQUESTS.FETCH({
                meta: () => ({ fleetId, pageSize, pageIndex, sort, query, params }),
            }),
            ActionCreators.GET_FLEET_REPAIR_REQUESTS.SUCCESS({
                meta: () => ({ fleetId, pageSize, pageIndex, sort, query, params }),
            }),
            ActionCreators.GET_FLEET_REPAIR_REQUESTS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_REPAIR_REQUESTS(fleetId),
        params: Object.assign({}, params),
        method: 'GET',
    });
};
export const addCarToFleet = ({ fleetId, car, }) => {
    return createAPIAction({
        types: [
            ActionCreators.ADD_FLEET_CAR.FETCH(),
            ActionCreators.ADD_FLEET_CAR.SUCCESS({ meta: () => ({ fleetId }) }),
            ActionCreators.ADD_FLEET_CAR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_CAR(fleetId),
        method: 'POST',
        body: Object.assign({}, car),
    });
};
export const processElementPmCsv = ({ fleetId, url, cancelDeltaRepairRequests, }) => {
    return createAPIAction({
        types: [
            ActionCreators.PROCESS_ELEMENT_PM_CSV.FETCH(),
            ActionCreators.PROCESS_ELEMENT_PM_CSV.SUCCESS({
                meta: () => ({ fleetId }),
            }),
            ActionCreators.PROCESS_ELEMENT_PM_CSV.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_PROCESS_ELEMENT_PM_CSV(fleetId),
        method: 'POST',
        body: {
            url,
            cancelDeltaRepairRequests,
        },
    });
};
export const processLeasePlanPmCsv = ({ fleetId, url, cancelDeltaRepairRequests, }) => {
    return createAPIAction({
        types: [
            ActionCreators.PROCESS_LEASE_PLAN_PM_CSV.FETCH(),
            ActionCreators.PROCESS_LEASE_PLAN_PM_CSV.SUCCESS({
                meta: () => ({ fleetId }),
            }),
            ActionCreators.PROCESS_LEASE_PLAN_PM_CSV.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_PROCESS_LEASE_PLAN_PM_CSV(fleetId),
        method: 'POST',
        body: {
            url,
            cancelDeltaRepairRequests,
        },
    });
};
export const updateFleetCar = ({ fleetId, car, }) => {
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_FLEET_CAR.FETCH(),
            ActionCreators.UPDATE_FLEET_CAR.SUCCESS({
                meta: () => ({ fleetId, car }),
            }),
            ActionCreators.UPDATE_FLEET_CAR.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_CAR_BY_ID({ fleetId, carId: car.id }),
        method: 'PATCH',
        body: Object.assign({}, car),
    });
};
export const updateCarStatusToFleet = ({ status, fleetId, carId, }) => {
    return createAPIAction({
        types: [
            ActionCreators.UPDATE_FLEET_CAR_STATUS.FETCH(),
            ActionCreators.UPDATE_FLEET_CAR_STATUS.SUCCESS({
                meta: () => ({ fleetId }),
            }),
            ActionCreators.UPDATE_FLEET_CAR_STATUS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FLEET_CAR_STATUS_BY_ID({
            fleetId,
            carId,
            status,
        }),
        method: 'PUT',
    });
};
export const uploadFleetCsv = (file, userId) => {
    const formData = new FormData();
    formData.append('folderPath', 'tools-documents/fleets/csv');
    formData.append('documents', file);
    formData.append('userId', userId);
    formData.append('thumbnail', 'false');
    return createAPIAction({
        types: [
            ActionCreators.UPLOAD_FILES.FETCH(),
            ActionCreators.UPLOAD_FILES.SUCCESS(),
            ActionCreators.UPLOAD_FILES.FAILURE(),
        ],
        endpoint: '/lambda/documentUpload',
        method: 'POST',
        formData,
    });
};
export const updateFMC = (FMC) => createAPIAction({
    types: [
        ActionCreators.UPDATE_FMC.FETCH(),
        ActionCreators.UPDATE_FMC.SUCCESS({ meta: () => ({ id: FMC.id }) }),
        ActionCreators.UPDATE_FMC.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.FMC_BY_ID(FMC.id),
    method: 'PATCH',
    body: FMC,
});
export const createFMC = (FMC) => createAPIAction({
    types: [
        ActionCreators.CREATE_FMC.FETCH(),
        ActionCreators.CREATE_FMC.SUCCESS(),
        ActionCreators.CREATE_FMC.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.FMCS(),
    method: 'POST',
    body: FMC,
});
export const createFleetAccountOwners = (body) => createAPIAction({
    types: fillApiTypes(ActionCreators.CREATE_FLEET_ACCOUNT_OWNERS),
    endpoint: API_URLS.ADMIN.FLEET_ACCOUNT_OWNERS(),
    method: 'POST',
    body,
});
export const updateFleetAccountOwners = (body) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_FLEET_ACCOUNT_OWNERS),
    endpoint: API_URLS.ADMIN.FLEET_ACCOUNT_OWNERS(),
    method: 'PATCH',
    body,
});
export const getAllFMCs = ({ query, pageSize = 10, pageIndex = 0, sort = ['name,asc'], includeRemoved = false, }) => {
    const params = {
        name: query,
        size: pageSize,
        page: pageIndex,
        sort,
        includeRemoved,
    };
    return createAPIAction({
        types: [
            ActionCreators.GET_FMC.FETCH(),
            ActionCreators.GET_FMC.SUCCESS({
                meta: () => ({ pageSize, pageIndex, sort, query, params }),
            }),
            ActionCreators.GET_FMC.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.FMCS(),
        params: Object.assign({}, params),
        method: 'GET',
    });
};
export const getResponsiblePartyByFleetId = (fleetId) => createAPIAction({
    types: [
        ActionCreators.GET_RESPONSIBLE_PARTY_FOR_FLEET.FETCH(),
        ActionCreators.GET_RESPONSIBLE_PARTY_FOR_FLEET.SUCCESS({
            meta: () => ({ fleetId }),
        }),
        ActionCreators.GET_RESPONSIBLE_PARTY_FOR_FLEET.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.RESPONSIBLE_PARTIES_BY_FLEET_ID(fleetId),
    method: 'GET',
});
export const updateFleetResponsibleParty = (body, fleetId) => createAPIAction({
    types: [
        ActionCreators.UPDATE_FLEET_RESPONSIBLE_PARTY.FETCH(),
        ActionCreators.UPDATE_FLEET_RESPONSIBLE_PARTY.SUCCESS({
            meta: () => ({ fleetId }),
        }),
        ActionCreators.UPDATE_FLEET_RESPONSIBLE_PARTY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.RESPONSIBLE_PARTIES_BY_FLEET_ID(fleetId),
    method: 'PATCH',
    body: Object.assign({}, body),
});
export const getPartDistributorWithLocations = () => {
    return createAPIAction({
        types: [
            ActionCreators.GET_PART_DISTRIBUTOR_WITH_LOCATIONS.FETCH(),
            ActionCreators.GET_PART_DISTRIBUTOR_WITH_LOCATIONS.SUCCESS(),
            ActionCreators.GET_PART_DISTRIBUTOR_WITH_LOCATIONS.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.PART_DISTRIBUTOR_WITH_LOCATIONS(),
        method: 'GET',
    });
};
export const updateFleetResponsibleParties = (fleetId, responsibleParties) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_FMC),
    endpoint: API_URLS.ADMIN.UPDATE_FLEET_RESPONSIBLE_PARTIES(fleetId),
    method: 'POST',
    body: responsibleParties,
});
export const getCarResponsibleParties = (fleetId, carId) => createAPIAction({
    types: [
        ActionCreators.GET_FLEET_RESPONSIBLE_PARTIES_BY_CAR.FETCH(),
        ActionCreators.GET_FLEET_RESPONSIBLE_PARTIES_BY_CAR.SUCCESS(),
        ActionCreators.GET_FLEET_RESPONSIBLE_PARTIES_BY_CAR.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.GET_FLEET_RESPONSIBLE_PARTIES_BY_CAR({
        fleetId,
        carId,
    }),
    method: 'GET',
});
export const updateFleetVehicleResponsibleParties = (fleetId, vehicleResponsibleParties) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_FLEET_VEHICLE_RESPONSIBLE_PARTIES),
    endpoint: API_URLS.ADMIN.UPDATE_FLEET_VEHICLE_RESPONSIBLE_PARTIES(fleetId),
    method: 'POST',
    body: vehicleResponsibleParties,
});
export const updateFleetResponsiblePartyBillingContacts = (responsiblePartyId, billingContacts) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_FLEET_RESPONSIBLE_PARTY_BILLING_CONTACTS),
    endpoint: API_URLS.ADMIN.UPDATE_FLEET_RESPONSIBLE_PARTY_BILLING_CONTACTS(responsiblePartyId),
    method: 'PUT',
    body: billingContacts,
});
