import { CircularProgress } from '@mui/material';
import React from 'react';
const RenderContainer = ({ isLoading, isEmpty, Component, componentProps, }) => {
    if (isEmpty)
        return null;
    if (isLoading)
        return <CircularProgress />;
    return <Component {...componentProps}/>;
};
export default RenderContainer;
