var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useMediaQuery } from 'src/components/mui';
import { theme } from 'src/styles/theme';
import MpiPendingAlert from 'src/AdminApp/containers/requests/RecommendedRepairs/WorkflowComponents/Alerts/MpiPendingAlert';
import MpiSentToCustomerAlert from 'src/AdminApp/containers/requests/RecommendedRepairs/WorkflowComponents/Alerts/MpiSentToCustomerAlert';
import SaMpiQuotePendingAlert from 'src/AdminApp/containers/requests/RecommendedRepairs/WorkflowComponents/Alerts/SAMpiQuotePendingAlert';
import MpiChangesPendingAlert from 'src/AdminApp/containers/requests/RecommendedRepairs/WorkflowComponents/Alerts/MpiChangesPendingAlert';
import SaMpiQuoteRequestedAlert from 'src/AdminApp/containers/requests/RecommendedRepairs/WorkflowComponents/Alerts/SAMpiQuoteRequestedAlert';
import useAlertsAndDialogs from 'src/AdminApp/containers/requests/RecommendedRepairs/WorkflowComponents/useAlertsAndDialogs';
import MpiNotifyCustomerDialog from './Dialogs/MpiNotifyCustomerDialog';
import { Alerts } from './AlertsAndDialogs.styles';
import MpiNotifySADialog from './Dialogs/MpiNotifySADialog';
const AlertsAndDialogs = ({ repairRequestId, onComplete, recommendedRepairsDirty, vehicle, hasRecommendedRepairs, }) => {
    const { referenceNum, state, showMpiSentToCustomerAlert, handleNotifyCustomer, onManualClose, handleShowMpiCustomerAlertClose, handleMpiNotifySADialogClose, handleShowNotifySADialog, handleMpiNotifyCustomerDialogClose, handleShowNotifyCustomerDialog, suppressNotifications, } = useAlertsAndDialogs({
        repairRequestId,
        onComplete,
        recommendedRepairsDirty,
        vehicle,
        hasRecommendedRepairs,
    });
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (<>
      <Alerts isMobile={isMobile}>
        <MpiPendingAlert open={state.alerts.showMpiPendingAlert} onNotifyCustomer={handleNotifyCustomer} onManualClose={onManualClose}/>

        <SaMpiQuoteRequestedAlert open={state.alerts.showQuoteRequestedAlert} onClick={() => onManualClose('setShowQuoteRequestedAlert')}/>

        <MpiSentToCustomerAlert open={showMpiSentToCustomerAlert} onClick={() => handleShowMpiCustomerAlertClose()} autoHideDuration={state.customerAlertAutoHide}/>

        <SaMpiQuotePendingAlert open={state.alerts.showQuotePendingAlert} recommendedRepairsDirty={recommendedRepairsDirty} onNotifyCustomer={handleNotifyCustomer} onManualClose={onManualClose}/>

        <MpiChangesPendingAlert open={state.alerts.showChangesPendingAlert} onUpdateCustomer={handleNotifyCustomer} onManualClose={onManualClose}/>
      </Alerts>

      <MpiNotifySADialog referenceNum={referenceNum} open={state.dialogs.showNotifySADialog} onClose={() => handleMpiNotifySADialogClose()} updateInspectionStatus={() => handleShowNotifySADialog()}/>

      <MpiNotifyCustomerDialog repairRequestId={repairRequestId} open={state.dialogs.showMpiNotifyCustomerDialog} onClose={() => handleMpiNotifyCustomerDialogClose()} updateInspectionStatus={() => __awaiter(void 0, void 0, void 0, function* () { return handleShowNotifyCustomerDialog(); })} vehicle={vehicle} suppressNotifications={suppressNotifications}/>
    </>);
};
export default AlertsAndDialogs;
