import React from 'react';
import { SimpleModal } from 'src/components/layout';
const SaveChangesDialog = ({ open, onSave, onClose, isSubmitting, }) => {
    return (<SimpleModal open={open} onClose={() => {
            // do nothing, you must go through the buttons
        }} title="Would you like to save your changes?" simpleTwoButtonRowProps={{
            onCancel: onClose,
            onSubmit: onSave,
            cancelText: 'Close',
            submitText: 'Save',
            disabledSubmit: isSubmitting,
            disabledCancel: isSubmitting,
        }}/>);
};
export default SaveChangesDialog;
