var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { archiveHub, getHubs, saveHub, updateHub, } from 'src/SystemApp/modules/hubs/actions';
import { selectDashboardHubs, selectDashboardPagination, } from 'src/SystemApp/modules/hubs/selectors';
const useHubs = () => {
    const dispatch = useDispatch();
    const [isHubDrawerOpen, setIsHubDrawerOpen] = useState(false);
    const [selectedHub, setSelectedHub] = useState();
    const [hubArchiveConfirmOpen, setHubArchiveConfirmOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const pagination = useSelector(selectDashboardPagination);
    const hubs = useSelector(selectDashboardHubs);
    const defaultParams = {
        page: (pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) || 0,
        size: (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || 20,
        sort: (pagination === null || pagination === void 0 ? void 0 : pagination.sort) || ['name,asc'],
    };
    useEffect(() => {
        onLoadData(defaultParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLoadData = ({ page, size, sort, }) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield dispatch(getHubs({
            pageSize: size,
            pageIndex: page,
            sort,
        }));
        setIsLoading(false);
    });
    const openHubDrawer = () => {
        setIsHubDrawerOpen(true);
    };
    const closeHubDrawer = () => {
        setSelectedHub(undefined);
        setIsHubDrawerOpen(false);
    };
    const saveSelectedHub = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const payload = Object.assign(Object.assign(Object.assign({}, selectedHub), values), { locationAddress: Object.assign(Object.assign(Object.assign({}, selectedHub === null || selectedHub === void 0 ? void 0 : selectedHub.locationAddress), values.locationAddress), { street1: values.locationAddress.street }) });
        let response;
        if (!selectedHub) {
            response = yield dispatch(saveHub(payload));
            if (!response.error) {
                toast.success('Hub successfully created');
            }
        }
        else {
            response = yield dispatch(updateHub(payload));
            if (!response.error) {
                toast.success('Hub successfully updated');
            }
        }
        if (!response.error) {
            closeHubDrawer();
            onLoadData(defaultParams);
        }
        return response;
    });
    const handleEditHub = (hub) => {
        var _a;
        setSelectedHub(Object.assign(Object.assign({}, hub), { locationAddress: Object.assign(Object.assign({}, hub.locationAddress), { street: (_a = hub.locationAddress.street1) !== null && _a !== void 0 ? _a : '' }) }));
        openHubDrawer();
    };
    const handleArchiveHub = (hub) => __awaiter(void 0, void 0, void 0, function* () {
        setHubArchiveConfirmOpen(true);
        setSelectedHub(hub);
    });
    const closeArchiveHubConfirm = () => {
        setHubArchiveConfirmOpen(false);
        setSelectedHub(undefined);
    };
    const handleArchiveHubConfirmed = (hub) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield dispatch(archiveHub(hub.id));
        if (!response.error) {
            toast.success('Hub successfully archived');
            onLoadData(defaultParams);
            closeArchiveHubConfirm();
        }
    });
    return {
        hubs,
        pagination,
        onLoadData,
        isHubDrawerOpen,
        openHubDrawer,
        closeHubDrawer,
        saveSelectedHub,
        handleEditHub,
        selectedHub,
        handleArchiveHub,
        hubArchiveConfirmOpen,
        handleArchiveHubConfirmed,
        closeArchiveHubConfirm,
        isLoading,
    };
};
export default useHubs;
