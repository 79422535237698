import { Grid } from 'src/components/mui';
import React from 'react';
import { FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, } from 'src/components/SimpleDrawer';
export const MobileEditFieldDrawer = ({ label, onClose, onSave, open, children, disableSave = false, }) => (<SimpleDrawer open={open} onClose={onClose} title={`Edit ${label}`}>
    <SimpleDrawerContent>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </SimpleDrawerContent>
    <SimpleDrawerFooter>
      <FooterActionButton onClick={onSave} disabled={disableSave}>
        Save
      </FooterActionButton>
    </SimpleDrawerFooter>
  </SimpleDrawer>);
