import React from 'react';
import pluralize from 'pluralize';
import { Tooltip } from 'src/components/tooltips';
import { SPECIAL_ORDER_TOOLTIP } from 'src/PopsApp/modules/api/constants';
import SpecialOrderIndicator from 'src/PopsApp/components/SpecialOrderIndicator';
import { SpecialOrderWrapper, Widget } from './GridItem.styles';
const GridItem = ({ name, count, subtitle, onClick, count2, count2Subtitle, specialOrder, }) => {
    return (<Widget type="button" onClick={onClick}>
      <p className="itemName">{name}</p>
      <p className="itemCount">
        {count} {pluralize(subtitle, count)}
      </p>
      {count2 && count2Subtitle && (<p className="itemCount">
          {count2} {pluralize(count2Subtitle, count2)}
        </p>)}
      {specialOrder && (<Tooltip title={SPECIAL_ORDER_TOOLTIP}>
          <SpecialOrderWrapper>
            <SpecialOrderIndicator />
          </SpecialOrderWrapper>
        </Tooltip>)}
    </Widget>);
};
export default GridItem;
