import createAPIAction from 'src/modules/api';
import createAction from 'src/modules/api/utils/createAction';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import API_URLS from 'src/PricingApp/modules/api/urls';
import createDefaultActionCreators, { createActionCreators, createDefaultApiActionCreators, } from 'src/utils/createActions';
export const ActionCreators = {
    GET_REPAIR_BY_ID: createDefaultActionCreators('GET_REPAIR_BY_ID'),
    GET_CATALOGS: createDefaultActionCreators('GET_CATALOGS'),
    UPDATE_REPAIR: createDefaultApiActionCreators('UPDATE_REPAIR'),
    SAVE_PRODUCT_DEFAULT_RESPONSIBLE_PARTY: createDefaultActionCreators('SAVE_PRODUCT_DEFAULT_RESPONSIBLE_PARTY'),
    UPDATE_PRODUCT_DEFAULT_RESPONSIBLE_PARTY: createDefaultActionCreators('UPDATE_PRODUCT_DEFAULT_RESPONSIBLE_PARTY'),
    DELETE_PRODUCT_DEFAULT_RESPONSIBLE_PARTY: createDefaultActionCreators('DELETE_PRODUCT_DEFAULT_RESPONSIBLE_PARTY'),
    SEARCH_REPAIRS: createDefaultActionCreators('SEARCH_REPAIRS', ['CLEAR']),
    SEARCH_BASE_PRODUCTS: createDefaultActionCreators('SEARCH_BASE_PRODUCTS'),
    SLIDE_PANEL_MEASUREMENT_REQUIREMENTS: createActionCreators(['OPEN', 'CLOSE'], 'SLIDE_PANEL_MEASUREMENT_REQUIREMENTS'),
    GET_MEASUREMENTS: createDefaultActionCreators('GET_MEASUREMENTS'),
    GET_MEASUREMENTS_BY_REPAIR: createDefaultActionCreators('GET_MEASUREMENTS_BY_REPAIR'),
    GET_MEASUREMENT: createDefaultActionCreators('GET_MEASUREMENT'),
    UPDATE_MEASUREMENT_ITEMS: createDefaultActionCreators('UPDATE_MEASUREMENT_ITEMS'),
    CREATE_MEASUREMENT: createDefaultActionCreators('CREATE_MEASUREMENT'),
    ADD_REPAIR_MEASUREMENT: createDefaultActionCreators('ADD_REPAIR_MEASUREMENT'),
    REMOVE_REPAIR_MEASUREMENT: createDefaultActionCreators('REMOVE_REPAIR_MEASUREMENT'),
    CLEAR_MEASUREMENTS: createActionCreators(['CLEAR'], 'CLEAR_MEASUREMENTS'),
    GET_DEFAULT_RESPONSIBLE_PARTY_INFO: createDefaultActionCreators('GET_DEFAULT_RESPONSIBLE_PARTY_INFO'),
    GET_COMPONENTS_BY_REPAIR_ID: createDefaultActionCreators('GET_COMPONENTS_BY_REPAIR_ID'),
    GET_EXISTING_FLEET_RULES: createDefaultActionCreators('GET_EXISTING_FLEET_RULES'),
    CREATE_FLEET_PRICING_RULES: createDefaultActionCreators('CREATE_FLEET_PRICING_RULES'),
    DEACTIVATE_FLEET_PRICING_RULES: createDefaultActionCreators('DEACTIVATE_FLEET_PRICING_RULES'),
    GET_REPAIR_CONCIERGE_RULES: createDefaultActionCreators('GET_REPAIR_CONCIERGE_RULES'),
};
export const getRepair = (id) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_REPAIR_BY_ID),
    endpoint: API_URLS.REPAIRS.REPAIR_BY_ID(id.toString()),
    method: 'GET',
});
export const saveOrUpdateRepair = (repair) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_REPAIR),
    body: repair,
    endpoint: API_URLS.REPAIRS.REPAIRS(),
    method: 'POST',
});
export const saveDefaultResponsiblePartyForProduct = (data) => createAPIAction({
    types: fillApiTypes(ActionCreators.SAVE_PRODUCT_DEFAULT_RESPONSIBLE_PARTY),
    body: Object.assign(Object.assign({}, data), { status: 'ACTIVE', customerType: 'CONSUMER' }),
    endpoint: API_URLS.REPAIRS.UPDATE_DEFAULT_RESPONSIBLE_PARTY(),
    method: 'POST',
});
export const updateDefaultResponsiblePartyForProduct = (data) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_PRODUCT_DEFAULT_RESPONSIBLE_PARTY),
    body: Object.assign(Object.assign({}, data), { status: 'ACTIVE', customerType: 'CONSUMER' }),
    endpoint: API_URLS.REPAIRS.DEFAULT_RESPONSIBLE_PARTY({ id: data.id }),
    method: 'PATCH',
});
export const deleteDefaultResponsiblePartyForProduct = ({ id, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.DELETE_PRODUCT_DEFAULT_RESPONSIBLE_PARTY),
    endpoint: API_URLS.REPAIRS.DEFAULT_RESPONSIBLE_PARTY({ id }),
    method: 'DELETE',
});
export const searchRepairs = (params) => createAPIAction({
    types: [
        ActionCreators.SEARCH_REPAIRS.FETCH({
            meta: () => ({ params }),
        }),
        ActionCreators.SEARCH_REPAIRS.SUCCESS({
            meta: () => ({ params }),
        }),
        ActionCreators.SEARCH_REPAIRS.FAILURE(),
    ],
    endpoint: API_URLS.REPAIRS.SEARCH_REPAIRS(),
    params: Object.assign(Object.assign({}, params.pagination), params.filters),
    method: 'GET',
});
export const searchBaseProducts = (name) => createAPIAction({
    types: fillApiTypes(ActionCreators.SEARCH_BASE_PRODUCTS),
    endpoint: API_URLS.REPAIRS.SEARCH_BASE_PRODUCTS(),
    params: { name },
    method: 'GET',
});
export const setSelectedProductFleets = createAction('SET_SELECTED_PRODUCT_FLEETS');
export const getMeasurements = () => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_MEASUREMENTS),
    endpoint: API_URLS.MEASUREMENTS.MEASUREMENTS(),
    method: 'GET',
});
export const getMeasurementsByRepair = (repairId) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_MEASUREMENTS_BY_REPAIR),
    endpoint: API_URLS.MEASUREMENTS.MEASUREMENTS_BY_REPAIR_ID(repairId.toString()),
    method: 'GET',
});
export const getMeasurement = (measurementId) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_MEASUREMENT),
    endpoint: API_URLS.MEASUREMENTS.ITEMS_BY_MEASUREMENT_ID(measurementId),
    method: 'GET',
});
export const updateMeasurement = (measurement) => createAPIAction({
    types: fillApiTypes(ActionCreators.UPDATE_MEASUREMENT_ITEMS),
    endpoint: API_URLS.MEASUREMENTS.MEASUREMENTS_BY_REPAIR_ID(measurement.id),
    body: measurement,
    method: 'PUT',
});
export const createMeasurement = (measurement) => createAPIAction({
    types: fillApiTypes(ActionCreators.CREATE_MEASUREMENT),
    endpoint: API_URLS.MEASUREMENTS.MEASUREMENTS(),
    body: measurement,
    method: 'POST',
});
export const addRepairMeasurement = (measurementId, repairId) => createAPIAction({
    types: fillApiTypes(ActionCreators.ADD_REPAIR_MEASUREMENT),
    endpoint: API_URLS.REPAIRS.REPAIR_MEASUREMENTS(),
    params: {
        repairId,
        measurementId,
    },
    method: 'POST',
});
export const removeRepairMeasurement = (measurementId, repairId) => createAPIAction({
    types: fillApiTypes(ActionCreators.REMOVE_REPAIR_MEASUREMENT),
    endpoint: API_URLS.REPAIRS.REPAIR_MEASUREMENTS(),
    params: {
        repairId,
        measurementId,
    },
    method: 'DELETE',
});
export const openMeasurementRequirementsSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_MEASUREMENT_REQUIREMENTS.OPEN.type,
});
export const closeMeasurementRequirementsSlidePanel = () => ({
    type: ActionCreators.SLIDE_PANEL_MEASUREMENT_REQUIREMENTS.CLOSE.type,
});
export const clearMeasurements = () => ({
    type: ActionCreators.CLEAR_MEASUREMENTS.CLEAR.type,
});
export const getDefaultResponsiblePartyInfo = ({ productId, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_DEFAULT_RESPONSIBLE_PARTY_INFO),
    endpoint: API_URLS.REPAIRS.GET_DEFAULT_RESPONSIBLE_PARTY_INFO({
        productId,
    }),
    method: 'GET',
});
export const getComponentsByRepairId = (repairId) => createAPIAction({
    types: [
        ActionCreators.GET_COMPONENTS_BY_REPAIR_ID.FETCH(),
        ActionCreators.GET_COMPONENTS_BY_REPAIR_ID.SUCCESS({
            meta: () => ({ repairId }),
        }),
        ActionCreators.GET_COMPONENTS_BY_REPAIR_ID.FAILURE(),
    ],
    endpoint: API_URLS.COMPONENTS.COMPONENTS_BY_REPAIR_ID(repairId),
    method: 'GET',
});
export const getExistingFleetRules = (fleetId) => createAPIAction({
    types: [
        ActionCreators.GET_EXISTING_FLEET_RULES.FETCH(),
        ActionCreators.GET_EXISTING_FLEET_RULES.SUCCESS({
            meta: () => ({ fleetId }),
        }),
        ActionCreators.GET_EXISTING_FLEET_RULES.FAILURE(),
    ],
    endpoint: API_URLS.RULES.RULES_BY_FLEET_ID(fleetId),
    method: 'GET',
});
export const createFleetRules = (rule) => createAPIAction({
    types: fillApiTypes(ActionCreators.CREATE_FLEET_PRICING_RULES),
    endpoint: API_URLS.RULES.FLEET_PRICING_RULES(),
    method: 'POST',
    body: rule,
});
export const deactivateFleetRules = (fleetRuleIds, reason) => createAPIAction({
    types: fillApiTypes(ActionCreators.DEACTIVATE_FLEET_PRICING_RULES),
    endpoint: API_URLS.RULES.FLEET_PRICING_RULES(),
    method: 'DELETE',
    params: { fleetRuleIds, reason },
});
export const getRepairConciergeRules = (repairId) => createAPIAction({
    types: [
        ActionCreators.GET_REPAIR_CONCIERGE_RULES.FETCH(),
        ActionCreators.GET_REPAIR_CONCIERGE_RULES.SUCCESS({
            meta: () => ({ repairId }),
        }),
        ActionCreators.GET_REPAIR_CONCIERGE_RULES.FAILURE(),
    ],
    endpoint: API_URLS.RULES.REPAIR_CONCIERGE_RULES_BY_REPAIR_ID(repairId),
    method: 'GET',
});
