import React from 'react';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import SimpleTable from 'src/components/dashboard/SimpleTable';
import ConfirmationModal from 'src/components/layout/ConfirmationModal';
import HubAddEditDrawer from 'src/SystemApp/pages/hubs/HubAddEditDrawer';
import HubItem from 'src/SystemApp/pages/hubs/HubItem';
import { HubsButtonBar } from 'src/SystemApp/pages/hubs/Hubs.styles';
import useHubs from 'src/SystemApp/pages/hubs/useHubs';
const REQUEST_COLUMNS = [
    { title: 'Name', key: 'name' },
    { title: 'Address', key: 'fullAddress' },
    { title: 'Status', key: 'archived' },
    {
        title: 'Deliveries from couriers?',
        key: 'acceptCourierDeliveries',
        textAlign: 'center',
    },
    {
        title: 'Deliveries from distributors?',
        key: 'acceptDistributorDeliveries',
        textAlign: 'center',
    },
    { title: '', key: 'edit', textAlign: 'center' },
    { title: '', key: 'archive', textAlign: 'center' },
];
const Hubs = () => {
    const { hubs, pagination, onLoadData, isHubDrawerOpen, openHubDrawer, closeHubDrawer, saveSelectedHub, handleEditHub, selectedHub, handleArchiveHub, hubArchiveConfirmOpen, handleArchiveHubConfirmed, closeArchiveHubConfirm, isLoading, } = useHubs();
    return (<section className="Hubs">
      <HubsButtonBar className="Hubs__buttonBar">
        <PrimaryButton onClick={openHubDrawer}>Add Hub</PrimaryButton>
      </HubsButtonBar>
      <SimpleTable data={hubs} style={{ minHeight: 200 }} pagination={pagination} sort={pagination.sort} columns={REQUEST_COLUMNS} isFetching={isLoading} hideTopPagination renderItem={(hub, index) => (<HubItem hub={hub} key={`${index}`} onEditHub={handleEditHub} onArchiveHub={() => {
                handleArchiveHub(hub);
            }}/>)} onLoadData={onLoadData}/>

      <HubAddEditDrawer open={isHubDrawerOpen} onClose={closeHubDrawer} saveHub={saveSelectedHub} selectedHub={selectedHub}/>

      <ConfirmationModal open={hubArchiveConfirmOpen} onClose={closeArchiveHubConfirm} action={handleArchiveHubConfirmed} title="Archive hub?" text="Are you sure you want to archive this hub?" model={selectedHub}/>
    </section>);
};
export default Hubs;
