var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { selectCustomAddress, selectCustomUser, selectCustomVehicle, selectTab, selectUser, } from 'src/AdminApp/modules/quickRo/selectors';
import { updateConsumerAddress } from 'src/AdminApp/modules/consumers/actions';
import { ActionCreators, setCustomConsumerAddress, setCustomUser, setRepairs, setTab, saveUser, } from 'src/AdminApp/modules/quickRo/actions';
import { CONSUMER_TABS as TABS } from 'src/AdminApp/modules/quickRo/enums';
import { addWarrantyClaim } from 'src/AdminApp/modules/warranty/actions';
const useAddWarrantyClaim = (open, onClose, referenceNum) => {
    var _a;
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isDuplicateCarModalOpen, setIsDuplicateCarModalOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const tab = (_a = useSelector(selectTab)) !== null && _a !== void 0 ? _a : 0;
    const user = useSelector(selectUser);
    const customUser = useSelector(selectCustomUser);
    const customConsumerAddress = useSelector(selectCustomAddress);
    const customVehicle = useSelector(selectCustomVehicle);
    const isUserSelected = () => !!user;
    const closeDrawerHandler = () => {
        dispatch(saveUser(null));
        dispatch(setCustomUser({
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            address: null,
            marketingSourceId: null,
        }));
        dispatch(setCustomConsumerAddress(null));
        dispatch(setRepairs([]));
        dispatch(setTab(TABS.EXISTING_CUSTOMER));
        dispatch(ActionCreators.TAB.RESET_ALL());
        onClose();
    };
    const updateUserAddress = (user, address) => __awaiter(void 0, void 0, void 0, function* () {
        const streetLine1 = address.street;
        const coordinates = {
            latitude: address.latitude,
            longitude: address.longitude,
        };
        const res = yield dispatch(updateConsumerAddress({
            consumerId: user.id,
            address: Object.assign(Object.assign({}, address), { active: true, coordinates,
                streetLine1 }),
        }));
        const newAddress = res.payload;
        if (newAddress) {
            dispatch(saveUser(Object.assign(Object.assign({}, user), { address: newAddress })));
        }
    });
    const onSelectCustomConsumerAddress = () => {
        updateUserAddress(user, customConsumerAddress);
        dispatch(setTab(TABS.EXISTING_CUSTOMER));
    };
    const onAddNewWarranty = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e;
        if (referenceNum) {
            setIsLoading(true);
            const res = yield dispatch(addWarrantyClaim(referenceNum, {
                id: user.address.id,
                street: user.address.streetLine1,
                street2: user.address.streetLine2,
                city: user.address.city,
                state: user.address.state,
                country: user.address.country,
                zip: user.address.zip,
                timezone: user.address.timezone,
                latitude: (_b = user.address.coordinates) === null || _b === void 0 ? void 0 : _b.latitude,
                longitude: (_c = user.address.coordinates) === null || _c === void 0 ? void 0 : _c.longitude,
                notes: user.address.notes,
                type: user.address.type,
            }));
            if ((_d = res === null || res === void 0 ? void 0 : res.payload) === null || _d === void 0 ? void 0 : _d.referenceNum) {
                history.push(`/admin/repairs/${(_e = res === null || res === void 0 ? void 0 : res.payload) === null || _e === void 0 ? void 0 : _e.referenceNum}`);
            }
            setIsLoading(false);
        }
    });
    const getOnClickNext = () => {
        if (tab === TABS.EXISTING_CUSTOMER) {
            return onAddNewWarranty;
        }
        if (tab === TABS.SEARCH_ADDRESS) {
            return () => onSelectCustomConsumerAddress();
        }
        return () => { };
    };
    const handleSelectCustomerAddress = (value) => {
        if (value === null && !(customConsumerAddress === null || customConsumerAddress === void 0 ? void 0 : customConsumerAddress.addressStr))
            return;
        dispatch(setCustomConsumerAddress(value));
    };
    const handleSetTab = (value) => {
        dispatch(setTab(value));
    };
    const handleSetUser = (value) => {
        dispatch(saveUser(value));
    };
    return {
        isLoading,
        isDuplicateCarModalOpen,
        setIsDuplicateCarModalOpen,
        confirmationModalOpen,
        setConfirmationModalOpen,
        tab,
        user,
        customUser,
        customConsumerAddress,
        customVehicle,
        isUserSelected,
        closeDrawerHandler,
        getOnClickNext,
        handleSelectCustomerAddress,
        handleSetTab,
        handleSetUser,
        onAddNewWarranty,
    };
};
export default useAddWarrantyClaim;
