import React from 'react';
import { TextField, Autocomplete } from 'src/components/mui';
const TimezonesSearch = ({ value = '', onUpdate, name, label, }) => {
    const options = [
        'America/New_York',
        'America/Chicago',
        'America/Los_Angeles',
        'America/Anchorage',
        'Pacific/Honolulu',
    ];
    const onSubmit = (value) => {
        onUpdate(value);
    };
    return (<Autocomplete style={{ width: '100%', minHeight: '25px' }} isOptionEqualToValue={(option) => option === value} options={options} getOptionLabel={(option) => option !== null && option !== void 0 ? option : ''} onChange={(e, value) => {
            onSubmit(value !== null && value !== void 0 ? value : '');
        }} renderInput={(params) => (<TextField {...params} fullWidth type="text" variant="outlined" name={name} label={label} InputProps={Object.assign(Object.assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment })}/>)}/>);
};
export default TimezonesSearch;
