import React from 'react';
import { CircularProgress } from 'src/components/mui';
import { CheckBox as CheckBoxIcon, Schedule as ScheduleIcon, RemoveCircle as RemoveCircleIcon, } from 'src/components/mui/icons';
import { Tooltip } from 'src/components/tooltips';
const VendorPartsAvailability = ({ className = undefined, componentsAvailability, loading, loaded, }) => {
    const renderAvailability = () => {
        if (!componentsAvailability) {
            return (<Tooltip placement="bottom" title="Availability check failed">
          <RemoveCircleIcon htmlColor="lightgray"/>
        </Tooltip>);
        }
        const availabilityString = `${componentsAvailability.availableComponents}/${componentsAvailability.neededComponents}`;
        if (componentsAvailability.failedRequests > 0) {
            return (<Tooltip placement="bottom" title="Availability check failed">
          <div>
            <RemoveCircleIcon htmlColor="lightgray" style={{ paddingLeft: '4px', fontSize: '24px' }}/>
          </div>
        </Tooltip>);
        }
        if (componentsAvailability.allComponentsAvailable) {
            return (<Tooltip placement="bottom" title="Distributor has all parts available">
          <div>
            <span>{availabilityString}</span>
            <CheckBoxIcon htmlColor="#00b988" style={{ paddingLeft: '4px', fontSize: '24px' }}/>
          </div>
        </Tooltip>);
        }
        return (<Tooltip placement="bottom" title="Distributor does not have all parts available">
        <div>
          <span>{availabilityString}</span>
          <RemoveCircleIcon htmlColor="lightgray" style={{ paddingLeft: '4px', fontSize: '24px' }}/>
        </div>
      </Tooltip>);
    };
    return (<div className={className}>
      {loading && (<Tooltip title="App is actively fetching availability">
          <CircularProgress size="1rem" style={{ marginLeft: 4 }}/>
        </Tooltip>)}
      {!loading && loaded && renderAvailability()}
      {!loading && !loaded && (<Tooltip title="Distributor is in the auto-check queue">
          <ScheduleIcon />
        </Tooltip>)}
    </div>);
};
export default VendorPartsAvailability;
