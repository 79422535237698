import { isNil } from 'lodash';
import createDefaultActionCreators from 'src/utils/createActions';
import createAPIAction from 'src/modules/api';
import API_URLS from 'src/AdminApp/modules/api/urls';
// FSA ACTIONS
export const ActionCreators = {
    TERRITORIES: createDefaultActionCreators('TERRITORIES', [
        'CLEAR',
        'CLEAR_FILTERS',
    ]),
    ADD_TERRITORY: createDefaultActionCreators('ADD_TERRITORY'),
    GET_TERRITORY: createDefaultActionCreators('GET_TERRITORY'),
    TERRITORIES_AUTOCOMPLETE: createDefaultActionCreators('TERRITORIES_AUTOCOMPLETE'),
    UPDATE_TERRITORY: createDefaultActionCreators('UPDATE_TERRITORY'),
    DELETE_TERRITORY: createDefaultActionCreators('DELETE_TERRITORY'),
    ARCHIVE_TERRITORY: createDefaultActionCreators('ARCHIVE_TERRITORY'),
};
export const getTerritories = ({ pageSize, pageIndex, sort = ['name,asc'], }) => {
    const params = Object.assign(Object.assign(Object.assign({}, (!!pageSize && { size: pageSize })), (!!pageIndex && { page: pageIndex })), (!!sort && { sort }));
    return createAPIAction({
        types: [
            ActionCreators.TERRITORIES.FETCH({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.TERRITORIES.SUCCESS({
                meta: () => ({ pageSize, pageIndex, sort, params }),
            }),
            ActionCreators.TERRITORIES.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.TERRITORIES(),
        params: Object.assign({}, params),
        method: 'GET',
    });
};
export const getTerritoriesByFilters = (params) => {
    var _a, _b, _c;
    const pagination = {
        pageSize: (_a = params.pagination) === null || _a === void 0 ? void 0 : _a.size,
        pageIndex: (_b = params.pagination) === null || _b === void 0 ? void 0 : _b.page,
        sort: (_c = params.pagination) === null || _c === void 0 ? void 0 : _c.sort,
    };
    return createAPIAction({
        types: [
            ActionCreators.TERRITORIES.FETCH({
                meta: () => ({ params, pagination }),
            }),
            ActionCreators.TERRITORIES.SUCCESS({
                meta: () => ({ params, pagination }),
            }),
            ActionCreators.TERRITORIES.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.TERRITORIES_DASHBOARD(Object.assign(Object.assign({}, params), { filters: Object.assign({}, params.filters) })),
        method: 'GET',
    });
};
export const getTerritoriesAutocomplete = (name) => {
    const filters = {
        name: !isNil(name) ? name : '',
    };
    return createAPIAction({
        types: [
            ActionCreators.TERRITORIES_AUTOCOMPLETE.FETCH(),
            ActionCreators.TERRITORIES_AUTOCOMPLETE.SUCCESS(),
            ActionCreators.TERRITORIES_AUTOCOMPLETE.FAILURE(),
        ],
        endpoint: API_URLS.ADMIN.TERRITORIES_AUTOCOMPLETE(filters),
        method: 'GET',
    });
};
export const getTerritoryById = (territoryId) => createAPIAction({
    types: [
        ActionCreators.GET_TERRITORY.FETCH(),
        ActionCreators.GET_TERRITORY.SUCCESS({ meta: () => ({ territoryId }) }),
        ActionCreators.GET_TERRITORY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.TERRITORY_BY_ID(territoryId),
    method: 'GET',
});
export const addTerritory = (territory) => createAPIAction({
    types: [
        ActionCreators.ADD_TERRITORY.FETCH(),
        ActionCreators.ADD_TERRITORY.SUCCESS(),
        ActionCreators.ADD_TERRITORY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.TERRITORIES(),
    method: 'POST',
    body: {
        name: territory.name,
        manager: territory.manager,
        metroIds: territory.metroIds,
    },
});
export const updateTerritory = (territory) => createAPIAction({
    types: [
        ActionCreators.UPDATE_TERRITORY.FETCH(),
        ActionCreators.UPDATE_TERRITORY.SUCCESS({
            meta: () => ({ territoryId: territory.id }),
        }),
        ActionCreators.UPDATE_TERRITORY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.TERRITORY_BY_ID(territory.id),
    method: 'PUT',
    body: {
        name: territory.name,
        manager: territory.manager,
        metroIds: territory.metroIds,
    },
});
export const archiveTerritory = ({ id, transferTerritoryId, }) => createAPIAction({
    types: [
        ActionCreators.ARCHIVE_TERRITORY.FETCH(),
        ActionCreators.ARCHIVE_TERRITORY.SUCCESS({ meta: () => ({ id }) }),
        ActionCreators.ARCHIVE_TERRITORY.FAILURE(),
    ],
    endpoint: API_URLS.ADMIN.TERRITORY_ARCHIVE(id),
    method: 'PUT',
    body: { transferTerritoryId },
});
