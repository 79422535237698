import React from 'react';
import { SimpleModal } from 'src/components/layout';
const RemoveRepairModal = ({ open, onClose, onSubmit, isCustom, }) => (<SimpleModal title={`Remove ${isCustom ? 'Custom ' : ''}Repair`} open={open} onClose={onClose} simpleTwoButtonRowProps={{
        onCancel: onClose,
        onSubmit,
        submitText: 'Confirm',
    }}>
    Are you sure you want to remove {isCustom ? 'custom ' : ''}repair?
  </SimpleModal>);
export default RemoveRepairModal;
