import { handleActions } from 'redux-actions';
import { ActionCreators as MetroActionCreators } from 'src/AdminApp/modules/metros/actions';
import { ActionCreators as RegionActionCreators } from 'src/AdminApp/modules/regions/actions';
import { ActionCreators as ServiceAreaActionCreators } from 'src/AdminApp/modules/serviceAreas/actions';
import { ActionCreators as TerritoryActionCreators } from 'src/AdminApp/modules/territories/actions';
import { ActionCreators, archiveLaborRates, deleteLaborRates, setLaborRatesToEdit, } from './actions';
const DEFAULT_STATE = {
    laborRates: [],
    params: {
        disableOptions: {
            metro: false,
            region: false,
            serviceArea: false,
            territory: false,
        },
        metroModel: undefined,
        regionModel: undefined,
        selectedPage: 'home',
    },
    selectedArchivedLaborRate: undefined,
    selectedLaborRate: undefined,
    slidePanelOpen: false,
};
const openSlidePanel = (state, action) => {
    const { params } = action.meta;
    return Object.assign(Object.assign({}, state), { slidePanelOpen: true, params: Object.assign(Object.assign({}, state.params), params) });
};
const closeSlidePanel = (state, action) => {
    var _a;
    if ((_a = action === null || action === void 0 ? void 0 : action.meta) === null || _a === void 0 ? void 0 : _a.inPlace) {
        return state;
    }
    return Object.assign(Object.assign({}, state), DEFAULT_STATE);
};
const slidePanelSetPage = (state, action) => {
    const { page } = action.meta;
    return Object.assign(Object.assign({}, state), { params: Object.assign(Object.assign({}, state.params), { selectedPage: page }) });
};
const addLaborRates = (state, action) => {
    const { payload } = action;
    return Object.assign(Object.assign({}, state), { laborRates: [...state.laborRates, payload] });
};
const clearLaborRates = (state) => (Object.assign(Object.assign({}, state), { laborRates: DEFAULT_STATE.laborRates }));
const setLaborRateToEdit = (state, action) => {
    var _a, _b, _c;
    const { id, metro = false } = action.payload;
    const model = !metro ? (_a = state.params) === null || _a === void 0 ? void 0 : _a.regionModel : (_b = state.params) === null || _b === void 0 ? void 0 : _b.metroModel;
    const laborRates = (_c = (model ? model === null || model === void 0 ? void 0 : model.laborRates : state === null || state === void 0 ? void 0 : state.laborRates)) !== null && _c !== void 0 ? _c : [];
    return Object.assign(Object.assign({}, state), { selectedLaborRate: laborRates.find(({ id: modelId }) => id === modelId) });
};
const clearLaborRateToEdit = (state) => (Object.assign(Object.assign({}, state), { selectedLaborRate: DEFAULT_STATE.selectedLaborRate }));
const updateLaborRates = (state, action) => {
    const laborRate = action.payload;
    const removed = state.laborRates.findIndex(({ id }) => laborRate.id === id);
    const laborRates = [...state.laborRates];
    laborRates.splice(removed, 1, laborRate);
    return Object.assign(Object.assign({}, state), { laborRates });
};
const deleteFromLaborRates = (state, action) => {
    const removed = state.laborRates.findIndex(({ id }) => action.payload === id);
    const laborRates = [...state.laborRates];
    laborRates.splice(removed, 1);
    return Object.assign(Object.assign({}, state), { laborRates });
};
const updateRegionModel = (state, action) => (Object.assign(Object.assign({}, state), { params: Object.assign(Object.assign({}, state.params), { regionModel: action.payload }) }));
const setArchivedLaborRate = (state, action) => {
    var _a, _b, _c;
    const { id, metro = false } = action.payload;
    const model = !metro ? (_a = state.params) === null || _a === void 0 ? void 0 : _a.regionModel : (_b = state.params) === null || _b === void 0 ? void 0 : _b.metroModel;
    const laborRates = (_c = (model ? model === null || model === void 0 ? void 0 : model.laborRates : state === null || state === void 0 ? void 0 : state.laborRates)) !== null && _c !== void 0 ? _c : [];
    return Object.assign(Object.assign({}, state), { selectedArchivedLaborRate: laborRates.find(({ id: modelId }) => id === modelId) });
};
const clearArchivedLaborRate = (state) => (Object.assign(Object.assign({}, state), { selectedArchivedLaborRate: DEFAULT_STATE.selectedArchivedLaborRate }));
const updateMetroModel = (state, action) => (Object.assign(Object.assign({}, state), { params: Object.assign(Object.assign({}, state.params), { metroModel: action.payload }) }));
const reducers = handleActions({
    [ActionCreators.SLIDE_PANEL.OPEN.type]: openSlidePanel,
    [ActionCreators.SLIDE_PANEL.CLOSE.type]: closeSlidePanel,
    [ActionCreators.SLIDE_PANEL.SET_PAGE.type]: slidePanelSetPage,
    [TerritoryActionCreators.ADD_TERRITORY.SUCCESS.type]: closeSlidePanel,
    [TerritoryActionCreators.UPDATE_TERRITORY.SUCCESS.type]: closeSlidePanel,
    [TerritoryActionCreators.ARCHIVE_TERRITORY.SUCCESS.type]: closeSlidePanel,
    [MetroActionCreators.ADD_METRO.SUCCESS.type]: closeSlidePanel,
    [MetroActionCreators.UPDATE_METRO.SUCCESS.type]: closeSlidePanel,
    [MetroActionCreators.ARCHIVE_METRO.SUCCESS.type]: closeSlidePanel,
    [RegionActionCreators.ADD_REGION.SUCCESS.type]: closeSlidePanel,
    [RegionActionCreators.UPDATE_REGION.SUCCESS.type]: closeSlidePanel,
    [RegionActionCreators.ARCHIVE_REGION.SUCCESS.type]: closeSlidePanel,
    [ServiceAreaActionCreators.ARCHIVE_SERVICE_AREA.SUCCESS.type]: closeSlidePanel,
    [ActionCreators.LABOR_RATES.ADD.type]: addLaborRates,
    [ActionCreators.LABOR_RATES.CLEAR.type]: clearLaborRates,
    [setLaborRatesToEdit.type]: setLaborRateToEdit,
    [ActionCreators.LABOR_RATES.CLEAR_EDIT.type]: clearLaborRateToEdit,
    [ActionCreators.LABOR_RATES.UPDATE.type]: updateLaborRates,
    [deleteLaborRates.type]: deleteFromLaborRates,
    [ActionCreators.SLIDE_PANEL.UPDATE_REGION.type]: updateRegionModel,
    [archiveLaborRates.type]: setArchivedLaborRate,
    [ActionCreators.LABOR_RATES.CLEAR_ARCHIVED.type]: clearArchivedLaborRate,
    [ActionCreators.SLIDE_PANEL.UPDATE_METRO.type]: updateMetroModel,
}, DEFAULT_STATE);
export default reducers;
