var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { createStructuredSelector } from 'reselect';
import { map, isEmpty } from 'lodash';
import { Button, Grid } from 'src/components/mui';
import { addressObjectToString, calculateLatLong, } from 'src/clients/mapboxClient';
import { selectShopsByReferenceNum } from 'src/AdminApp/modules/shops/selectors';
import { searchShops, clearSearchedShops, } from 'src/AdminApp/modules/shops/actions';
import Map, { calculateBounds } from 'src/components/maps/Map';
import { TextField } from 'src/AdminApp/components/form';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import { InfoText, SimpleModal } from 'src/components/layout';
import List from './List';
const CoverageZipCodeWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  width: 100%;
`;
class ShopFinder extends Component {
    constructor(props) {
        super(props);
        this.selectShop = (isp) => {
            this.props.onSelectShop(isp);
        };
        this.closeCoverageZipsDialog = () => this.setState({ coverageZipCodes: '', isCoverageZipsOpen: false });
        this.onRowHover = (event, shop) => {
            this.setState({ hoveredMarker: shop.id });
        };
        this.onMarkerHover = (hoveredListId) => {
            this.setState({ hoveredListId });
        };
        this.getConsumerAddressMarker = () => {
            var _a, _b, _c, _d;
            const consumerLatitude = (_b = (_a = this.props.consumerAddress) === null || _a === void 0 ? void 0 : _a.latitude) !== null && _b !== void 0 ? _b : null;
            const consumerLongitude = (_d = (_c = this.props.consumerAddress) === null || _c === void 0 ? void 0 : _c.longitude) !== null && _d !== void 0 ? _d : null;
            return !consumerLatitude || !consumerLongitude
                ? null
                : {
                    key: 'consumerAddress',
                    lat: consumerLatitude,
                    lng: consumerLongitude,
                    title: 'Customer Address',
                    icon: '/img/svg/map/purple_pin.svg',
                    hoverIcon: '/img/svg/map/purple_pin.svg',
                    id: 'consumerAddress',
                };
        };
        this.getMarkers = () => {
            const { hoveredMarker } = this.state;
            return map(this.props.shops, (shop) => {
                const id = shop === null || shop === void 0 ? void 0 : shop.id;
                const selected = hoveredMarker === id;
                return {
                    key: `${id}`,
                    lat: shop === null || shop === void 0 ? void 0 : shop.latitude,
                    lng: shop === null || shop === void 0 ? void 0 : shop.longitude,
                    title: shop === null || shop === void 0 ? void 0 : shop.shopName,
                    icon: '/img/svg/map/blue_pin.svg',
                    hoverIcon: '/img/svg/map/red_pin.svg',
                    id,
                    selected,
                };
            });
        };
        this.getMapCenter = () => {
            var _a, _b, _c, _d;
            const defaultCoordinates = {
                lat: 34.024031,
                lng: -118.393819,
            };
            const zip = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.zip) !== null && _b !== void 0 ? _b : null;
            const consumerCoordinates = {
                lat: (_c = this.props.consumerAddress) === null || _c === void 0 ? void 0 : _c.latitude,
                lng: (_d = this.props.consumerAddress) === null || _d === void 0 ? void 0 : _d.longitude,
            };
            if (consumerCoordinates.lat && consumerCoordinates.lng) {
                return consumerCoordinates;
            }
            if (zip) {
                calculateLatLong({ zip }).then((res) => {
                    this.setState({ zipCoordinates: res });
                });
            }
            const { zipCoordinates } = this.state;
            if ((zipCoordinates === null || zipCoordinates === void 0 ? void 0 : zipCoordinates.latitude) && (zipCoordinates === null || zipCoordinates === void 0 ? void 0 : zipCoordinates.longitude)) {
                return {
                    lat: zipCoordinates.latitude,
                    lng: zipCoordinates.longitude,
                };
            }
            return defaultCoordinates;
        };
        this.renderMap = () => {
            const { hoveredMarker } = this.state;
            const markers = this.getMarkers();
            const consumerAddressMarker = this.getConsumerAddressMarker();
            if (consumerAddressMarker) {
                markers.push(consumerAddressMarker);
            }
            const center = this.getMapCenter();
            return (<Map key={`${hoveredMarker}_${markers.length}`} onMarkerHover={this.onMarkerHover} bounds={calculateBounds(markers)} center={center} markers={markers}/>);
        };
        this.getConsumerCoordinates = () => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            let consumerLatitude = (_b = (_a = this.props.consumerAddress) === null || _a === void 0 ? void 0 : _a.latitude) !== null && _b !== void 0 ? _b : null;
            let consumerLongitude = (_d = (_c = this.props.consumerAddress) === null || _c === void 0 ? void 0 : _c.longitude) !== null && _d !== void 0 ? _d : null;
            const requestZip = (_f = (_e = this.props) === null || _e === void 0 ? void 0 : _e.zip) !== null && _f !== void 0 ? _f : null;
            if ((!consumerLatitude || !consumerLongitude) && !!requestZip) {
                const latLong = yield calculateLatLong({ zip: requestZip });
                consumerLatitude = (_g = latLong === null || latLong === void 0 ? void 0 : latLong.latitude) !== null && _g !== void 0 ? _g : null;
                consumerLongitude = (_h = latLong === null || latLong === void 0 ? void 0 : latLong.longitude) !== null && _h !== void 0 ? _h : null;
            }
            return {
                consumerLatitude,
                consumerLongitude,
            };
        });
        this.getShopCoordinates = (zip) => __awaiter(this, void 0, void 0, function* () {
            let coordinates;
            if (zip) {
                coordinates = yield calculateLatLong({ zip });
            }
            return coordinates;
        });
        this.renderAddressString = () => {
            const { consumerAddress, zip: requestZip } = this.props;
            const addressString = addressObjectToString(consumerAddress);
            if (addressString) {
                return <span>{addressString}</span>;
            }
            if (requestZip) {
                return requestZip;
            }
            return 'N/A';
        };
        this.renderForm = () => {
            const { referenceNum, suggestedRepairs, vehicle, sublet } = this.props;
            const { shopName, zip } = this.state;
            return (<Formik initialValues={{
                    shopName,
                    zip,
                    delivery: sublet && 'SUBLET',
                    salesStatus: '',
                    filterByVehicle: true,
                    filterByRepairs: true,
                    guaranteed: true,
                    nonSublet: false,
                }} enableReinitialize onSubmit={(values, { setSubmitting }) => __awaiter(this, void 0, void 0, function* () {
                    const latLong = yield this.getShopCoordinates(values === null || values === void 0 ? void 0 : values.zip);
                    const { latitude, longitude } = latLong;
                    const consumerLatLong = yield this.getConsumerCoordinates();
                    const { consumerLatitude, consumerLongitude } = consumerLatLong !== null && consumerLatLong !== void 0 ? consumerLatLong : {};
                    if (values.nonSublet) {
                        values.delivery = undefined;
                    }
                    yield this.props.searchShops(Object.assign({ consumerLatitude,
                        consumerLongitude,
                        referenceNum,
                        latitude,
                        longitude, carMake: vehicle.make, carYear: vehicle.year, suggestedRepairs }, values));
                    setSubmitting(false);
                })} render={({ status, isSubmitting }) => (<Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                {this.renderMap()}
              </Grid>
              <Grid item xs={12} md={4} style={{ position: 'relative', top: '-20px' }}>
                <TextField style={{ marginBottom: '15px', marginTop: '20px' }} variant="outlined" label="Shop Name" name="shopName" autoFocus/>
                <TextField style={{ marginBottom: '15px' }} variant="outlined" label="Zip" name="zip"/>
                <CheckboxField style={{ marginBottom: '15px' }} label="Search non-sublet shops" name="nonSublet"/>
                {status && status.msg && <div>{status.msg}</div>}
                <Button variant="contained" size="large" color="primary" type="submit" fullWidth disabled={isSubmitting}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Form>)}/>);
        };
        this.state = {
            zipCoordinates: {},
            shopName: '',
            zip: props.zip || '',
            hoveredMarker: -1,
            hoveredListId: -1,
            coverageZipCodes: '',
            isCoverageZipsOpen: false,
        };
    }
    componentWillUnmount() {
        this.props.clearSearchedShops(this.props.referenceNum);
    }
    render() {
        const { vehicle } = this.props;
        const { isCoverageZipsOpen, coverageZipCodes } = this.state;
        return (<>
        {!isEmpty(vehicle) && (<InfoText style={{ margin: '0 0 8px' }}>
            Repair requested for a{' '}
            <span>{`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`}</span>{' '}
            near {this.renderAddressString()}.
          </InfoText>)}
        {this.renderForm()}
        <List shops={this.props.shops} selectShop={this.selectShop} hoveredListId={this.state.hoveredListId} onShopHover={this.onRowHover} onShopLeave={() => { }}/>
        <SimpleModal open={isCoverageZipsOpen !== null && isCoverageZipsOpen !== void 0 ? isCoverageZipsOpen : false} onClose={this.closeCoverageZipsDialog} title="Zip Code Coverage">
          <CoverageZipCodeWrapper>{coverageZipCodes}</CoverageZipCodeWrapper>
        </SimpleModal>
      </>);
    }
}
const mapStateToProps = (_, props) => createStructuredSelector({
    shops: selectShopsByReferenceNum(props.referenceNum),
});
const mapDispatchToProps = (dispatch) => ({
    searchShops: (values) => dispatch(searchShops(values)),
    clearSearchedShops: (referenceNum) => dispatch(clearSearchedShops(referenceNum)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ShopFinder);
