import React from 'react';
import styled from 'styled-components';
import { Container, editInputStyle, FieldLabel, FieldRow, FieldValue, variantMap, } from 'src/AdminApp/containers/fleets/tabs/styles';
import { selectNonTechnicians } from 'src/AdminApp/modules/users/selectors';
import EditableSearchUserEmails from 'src/components/form/EditableSearchUserEmails';
import { Switch } from 'src/components/mui';
import AccountOwner from '../FleetInfo/AccountOwner/AccountOwner';
import useAccountManagement from './useAccountManagement';
const Block = styled('div') `
  flex: 1;
  padding-right: 16px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    flex: 2;
    padding-right: 0;
  }
`;
const AccountManagement = () => {
    const { fleet, canEditStatus, handleUpdateField } = useAccountManagement();
    if (!fleet)
        return null;
    return (<>
      <Container>
        <Block>
          {canEditStatus && (<FieldRow>
              <FieldLabel variant="subtitle1">
                Active
                <Switch checked={!fleet.removed} onChange={(event) => {
                handleUpdateField('removed')(!event.target.checked);
            }} color="primary" name="Active"/>
              </FieldLabel>
            </FieldRow>)}
          <FieldRow>
            <FieldLabel variant="subtitle1">New Business Hunter</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableSearchUserEmails value={fleet.newBusinessHunterSystemUserId} usersSelector={selectNonTechnicians} onUpdate={(value) => {
            handleUpdateField('newBusinessHunterSystemUserId')(value.id);
        }} style={editInputStyle} requireId/>
            </FieldValue>
          </FieldRow>
          <FieldRow>
            <FieldLabel variant="subtitle1">Account VP</FieldLabel>
            <FieldValue variant="body1" variantMapping={variantMap}>
              <EditableSearchUserEmails value={fleet.accountVpSystemUserId} usersSelector={selectNonTechnicians} onUpdate={(value) => {
            handleUpdateField('accountVpSystemUserId')(value.id);
        }} style={editInputStyle} requireId/>
            </FieldValue>
          </FieldRow>
        </Block>
      </Container>

      <AccountOwner />
    </>);
};
export default AccountManagement;
