import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SkeduloResourceTag from 'src/AdminApp/models/enums/SkeduloResourceTag';
import { Button, CircularProgress, Divider, Tab, Tabs, } from 'src/components/mui';
import useIsMobile from 'src/hooks/useIsMobile';
import DeliveryVanAdditionalInfo from 'src/PopsApp/components/Breadcrumbs/DeliveryVanAdditionalInfo/DeliveryVanAdditionalInfo';
import { selectAllActivePartDistributors } from 'src/PopsApp/modules/vendors/selectors';
import { JobsRoot, VanContainer } from 'src/PopsApp/pages/Job/Job.styles';
import { VendorDrawerRoot, VendorHeader, } from 'src/PopsApp/pages/Vendors/Vendors.styles'; // eslint-disable-line import/extensions
import useSmartOrder from './hooks/useSmartOrder';
import useVendors from './hooks/useVendors';
import QuickLocations from './QuickLocations';
import SelectedAppointments from './SelectedAppointments';
import SkipOrderModal from './SkipOrderModal/SkipOrderModal';
import VendorManualInput from './VendorManualInput';
import VendorSearch from './VendorSearch';
const Vendors = () => {
    var _a, _b, _c;
    const isMobile = useIsMobile();
    const { ispId, region, method } = useParams();
    const navLocation = useLocation();
    const history = useHistory();
    const queryParams = queryString.parse(navLocation.search);
    const isQuickOrder = method === 'quick-order';
    const isManualOrder = !!(ispId && region) && !(queryParams === null || queryParams === void 0 ? void 0 : queryParams.editVendor);
    const activePartDistributors = useSelector(selectAllActivePartDistributors);
    // If no region nor ispId are in the URL it means it's coming from smart order.
    const { loading, smartIspId, handleSkipOrder, orderLoaded, handleOrderParts, } = useSmartOrder({
        ispId,
        region,
        editVendor: !!(queryParams === null || queryParams === void 0 ? void 0 : queryParams.editVendor),
    });
    const shopId = isManualOrder ? ispId : smartIspId;
    const { vendorSearchTab, isSkipOrderForTodayDialogOpen, setIsSkipOrderForTodayDialogOpen, openSkipCustomDialog, setOpenSkipCustomDialog, vanShop, homeStores, selectedJobs, partPickups, deliveryVan, handleTabsChange, handleVendorSubmit, onSkipOrder, vanLoading, } = useVendors({
        shopId,
        isManualOrder,
        handleSkipOrder,
        handleOrderParts,
        editVendor: !!(queryParams === null || queryParams === void 0 ? void 0 : queryParams.editVendor),
        isQuickOrder,
    });
    useEffect(() => {
        if (!vanShop && !selectedJobs && isManualOrder) {
            history.push(`/pops/regions/${region}/vans/${ispId}/jobs`);
        }
    }, [history, ispId, region, selectedJobs, vanShop, isManualOrder]);
    const selectedJobsCount = Object.keys(selectedJobs !== null && selectedJobs !== void 0 ? selectedJobs : {}).length;
    // The 'tags' property is defined as SkeduloResourceTag[] but it actually have the keys and not the values from the backend.
    // This needs a refactor/fix, its not a straight forward change since this Interface it's used in other places
    const showDeliveryTag = (_a = deliveryVan === null || deliveryVan === void 0 ? void 0 : deliveryVan.tags) === null || _a === void 0 ? void 0 : _a.includes('DELIVERIES_TO_HUB');
    const hasDeliveryToHubSkill = ((_b = deliveryVan === null || deliveryVan === void 0 ? void 0 : deliveryVan.skills) !== null && _b !== void 0 ? _b : []).some((item) => item.name === SkeduloResourceTag.DELIVERIES_TO_HUB);
    const repairSmithHubHomeStore = () => {
        var _a;
        const homeStoreItem = ((_a = deliveryVan === null || deliveryVan === void 0 ? void 0 : deliveryVan.deliveryVanHomeStores) !== null && _a !== void 0 ? _a : []).find((item) => item.partDistributorName === 'RepairSmith Hub');
        if (!homeStoreItem) {
            return null;
        }
        return homeStoreItem === null || homeStoreItem === void 0 ? void 0 : homeStoreItem.partDistributorAddress;
    };
    const activeHomeStores = activePartDistributors &&
        homeStores &&
        (homeStores === null || homeStores === void 0 ? void 0 : homeStores.filter((hs) => {
            return activePartDistributors === null || activePartDistributors === void 0 ? void 0 : activePartDistributors.some((pd) => pd.id === hs.partDistributorId);
        }));
    return (<>
      <JobsRoot>
        {vanShop && (<VendorHeader>
            <div>
              <VanContainer>
                <h2>{vanShop.shopName}</h2>
                <span>{vanShop.metroName}</span>
                <DeliveryVanAdditionalInfo hasDeliveryToHubSkill={hasDeliveryToHubSkill} hasRepairSmithHubHomeStore={!!repairSmithHubHomeStore()} address={(_c = repairSmithHubHomeStore()) !== null && _c !== void 0 ? _c : ''}/>
              </VanContainer>
              <Helmet>
                <title>{vanShop.shopName}</title>
              </Helmet>
            </div>
            {!isManualOrder && (<Button className="skipOrderBtn" color="primary" variant="contained" disabled={selectedJobsCount === 0} onClick={() => setIsSkipOrderForTodayDialogOpen(true)}>
                Skip Order
              </Button>)}
          </VendorHeader>)}
        <Tabs value={vendorSearchTab} onChange={handleTabsChange} indicatorColor="primary" textColor="primary" variant={isMobile ? 'fullWidth' : 'standard'}>
          <Tab label="Search Vendor"/>
          <Tab label="Manual Input"/>
        </Tabs>
        <VendorDrawerRoot fullScreen={isMobile}>
          {(!isManualOrder && loading) ||
            vanLoading ||
            (!isManualOrder && !orderLoaded && !(queryParams === null || queryParams === void 0 ? void 0 : queryParams.editVendor)) ? (<CircularProgress size={40}/>) : (<>
              {vendorSearchTab === 0 && (<VendorSearch onVendorSubmit={handleVendorSubmit}/>)}

              {vendorSearchTab === 1 && (<VendorManualInput onVendorSubmit={handleVendorSubmit}/>)}
              <Divider />

              <section>
                {partPickups && partPickups.length > 0 && (<QuickLocations title="Select from Existing Stops" partPickups={partPickups} handleVendorSubmit={handleVendorSubmit} key="existing-stops"/>)}
                {activeHomeStores && activeHomeStores.length > 0 && (<QuickLocations title="Select from Home Stores" homeStores={activeHomeStores} handleVendorSubmit={handleVendorSubmit} showDeliveryTag={showDeliveryTag} key="home-stores"/>)}
                <SelectedAppointments />
              </section>
            </>)}
        </VendorDrawerRoot>
      </JobsRoot>
      <SkipOrderModal open={isSkipOrderForTodayDialogOpen} openCustom={openSkipCustomDialog} onClose={() => setIsSkipOrderForTodayDialogOpen(false)} onOpenCustom={() => setOpenSkipCustomDialog(true)} onSubmit={(time) => onSkipOrder(time)}/>
    </>);
};
export default Vendors;
