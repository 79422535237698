import React, { useMemo, useState } from 'react';
import { SimpleDrawer } from 'src/components/SimpleDrawer';
import { SimplePageSlider, SlidesContainer, } from 'src/components/SimpleDrawerPaged';
import CreditPage from './pages/CreditPage';
import CustomDiscountPage from './pages/CustomDiscountPage';
import DiscountTypePage from './pages/DiscountTypePage';
import PromoCodePage from './pages/PromoCodePage';
import { SliderContext, } from './utils';
const DiscountDrawer = ({ open, closeHandler, cancelFeeCredits, onPromoCodeSubmit, onCreditSubmit, onOtherSubmit, disableOptions, }) => {
    const [slide, setSlide] = useState('addDiscount');
    const slidesContext = useMemo(() => ({
        slide,
        setSlide,
    }), [slide]);
    const closeDrawerHandler = () => {
        closeHandler();
        setSlide('addDiscount');
    };
    const routes = [
        {
            name: 'addDiscount',
            title: 'Add Discount',
            render: () => <DiscountTypePage disableOptions={disableOptions}/>,
        },
        {
            name: 'promoCode',
            title: 'Add Promo Code',
            render: () => (<PromoCodePage onDrawerClose={closeDrawerHandler} onSubmit={onPromoCodeSubmit}/>),
        },
        {
            name: 'cancelFeeCredit',
            title: 'Add Cancel Fee Credit',
            render: () => (<CreditPage cancelFeeCredits={cancelFeeCredits} onDrawerClose={closeDrawerHandler} onSubmit={onCreditSubmit}/>),
        },
        {
            name: 'customDiscount',
            title: 'Add Custom Discount',
            render: () => (<CustomDiscountPage onDrawerClose={closeDrawerHandler} onSubmit={onOtherSubmit}/>),
        },
    ];
    const drawerTitle = (s) => {
        var _a, _b;
        return (_b = (_a = routes.find((r) => r.name === s)) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '';
    };
    return (<SimpleDrawer open={open} onClose={slide === 'addDiscount'
            ? closeDrawerHandler
            : () => setSlide('addDiscount')} title={drawerTitle(slide)} arrowBack={slide !== 'addDiscount'}>
      <SliderContext.Provider value={slidesContext}>
        <SlidesContainer>
          <SimplePageSlider currentSlide={slide} slides={routes}/>
        </SlidesContainer>
      </SliderContext.Provider>
    </SimpleDrawer>);
};
export default DiscountDrawer;
