import API_URLS from 'src/AdminApp/modules/api/urls';
import createAPIAction from 'src/modules/api';
import createAction from 'src/modules/api/utils/createAction';
import fillApiTypes from 'src/modules/api/utils/fillApiTypes';
import createDefaultActionCreators from 'src/utils/createActions';
export const ActionCreators = {
    GET_PARTIES: createDefaultActionCreators('GET_PARTIES'),
    ADD_PARTY: createDefaultActionCreators('ADD_PARTY'),
    EDIT_PARTY: createDefaultActionCreators('EDIT_PARTY'),
    GET_PARTY_TYPES: createDefaultActionCreators('GET_PARTY_TYPES'),
    PATCH_PARTY: createDefaultActionCreators('PATCH_PARTY'),
    GET_PARTY: createDefaultActionCreators('GET_PARTY'),
};
export var ResponsiblePartyStatus;
(function (ResponsiblePartyStatus) {
    ResponsiblePartyStatus["INACTIVE"] = "Inactive";
    ResponsiblePartyStatus["ACTIVE"] = "Active";
})(ResponsiblePartyStatus || (ResponsiblePartyStatus = {}));
export const getParties = (params) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_PARTIES),
    endpoint: API_URLS.SYSTEM.RESPONSIBLE_PARTIES(),
    method: 'GET',
    params,
});
export const createParty = (data) => createAPIAction({
    types: fillApiTypes(ActionCreators.ADD_PARTY),
    endpoint: API_URLS.SYSTEM.RESPONSIBLE_PARTIES_CREATE(),
    method: 'POST',
    body: data,
});
export const editParty = (data) => createAPIAction({
    types: fillApiTypes(ActionCreators.EDIT_PARTY),
    endpoint: API_URLS.SYSTEM.RESPONSIBLE_PARTIES_EDIT(data.id),
    method: 'PUT',
    body: data,
});
export const patchParty = (data) => createAPIAction({
    types: fillApiTypes(ActionCreators.PATCH_PARTY),
    endpoint: API_URLS.SYSTEM.RESPONSIBLE_PARTIES_EDIT(data.id),
    method: 'PATCH',
    body: data,
});
export const getParty = (id) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_PARTY),
    endpoint: API_URLS.SYSTEM.RESPONSIBLE_PARTIES_EDIT(id),
    method: 'GET',
});
export const getPartyTypes = ({ params, }) => createAPIAction({
    types: fillApiTypes(ActionCreators.GET_PARTY_TYPES),
    endpoint: API_URLS.SYSTEM.RESPONSIBLE_PARTY_TYPES(),
    method: 'GET',
    params,
});
export const setPartiesSort = createAction('SET_PARTIES_SORT');
export const setFilters = createAction('SET_FILTERS');
