var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatInvoice } from 'src/AdminApp/containers/requests/Invoice/InvoiceForm/utils';
import useInvoiceDownload from 'src/AdminApp/containers/requests/Invoice/useInvoiceDownload';
import { RepairOrderResolution } from 'src/AdminApp/models/enums';
import DeliveryChannel from 'src/AdminApp/models/enums/DeliveryChannel';
import { getInvoicesByWorkOrderId, removeSublet, updateInvoiceByWorkOrderId, } from 'src/AdminApp/modules/invoices/actions';
import { selectMainInvoiceByWorkOrderId, selectNotMainInvoicesByWorkOrderId, } from 'src/AdminApp/modules/invoices/selectors';
import { selectOrderById } from 'src/AdminApp/modules/orders/selectors';
import { selectRepairRequestWithReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { removeSublet as removeWorkOrderSublet } from 'src/AdminApp/modules/sublets/actions';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
const useInvoiceForm = ({ workOrderId, orderId, setIsEditing, }) => {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const { referenceNum } = usePageReferenceNum();
    const [currentEditField, setCurrentEditField] = useState();
    const mainInvoiceRaw = useSelector((state) => selectMainInvoiceByWorkOrderId(state, { workOrderId }));
    const notMainInvoices = useSelector((state) => selectNotMainInvoicesByWorkOrderId(state, { workOrderId }));
    const orderProps = { match: { params: { orderId, referenceNum } } };
    const order = useSelector((state) => selectOrderById(state, orderProps));
    const repairRequest = useSelector((state) => selectRepairRequestWithReferenceNum(state, referenceNum));
    const totalLaborDuration = (_b = (_a = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.workOrder) === null || _a === void 0 ? void 0 : _a.totalLaborDuration) !== null && _b !== void 0 ? _b : 0;
    const totalJobDurationMinutes = (_d = (_c = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.workOrder) === null || _c === void 0 ? void 0 : _c.totalJobDurationMinutes) !== null && _d !== void 0 ? _d : 0;
    const mainInvoice = formatInvoice(mainInvoiceRaw);
    const totalPriceOverrideEnabled = RepairOrderResolution.REPAIR_COMPLETED_WITH_INVOICE === (order === null || order === void 0 ? void 0 : order.resolution) &&
        (order === null || order === void 0 ? void 0 : order.appointment.deliveryChannel) !== DeliveryChannel.DELIVERY;
    const { onDownloadInvoiceClick, isInvoiceDownloading } = useInvoiceDownload();
    const handleRemoveSublet = ({ repairId, subletId, }) => {
        if (repairId) {
            return dispatch(removeSublet({ workOrderId, repairId }));
        }
        if (subletId) {
            return dispatch(removeWorkOrderSublet({ orderId, subletId, referenceNum }));
        }
    };
    const handleInvoiceSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setIsEditing(false);
        dispatch(updateInvoiceByWorkOrderId(values, workOrderId));
        return dispatch(getInvoicesByWorkOrderId({ workOrderId }));
    });
    return {
        mainInvoice,
        handleInvoiceSubmit,
        setCurrentEditField,
        totalJobDurationMinutes,
        totalLaborDuration,
        currentEditField,
        handleRemoveSublet,
        onDownloadInvoiceClick,
        isInvoiceDownloading,
        notMainInvoices,
        totalPriceOverrideEnabled,
    };
};
export default useInvoiceForm;
