import React from 'react';
import { Checkbox } from 'src/components/mui';
const CheckboxOrLabel = ({ job, selectedJobs, onItemSelected, }) => {
    return (<div>
      {(job.partsStatus === 'PENDING' ||
            job.partsStatus === 'WRONG_PART' ||
            job.partsStatus === 'NOT_PICKED_UP' ||
            job.partsStatus === 'PARTIALLY_ORDERED' ||
            job.partsStatus === 'ORDER_FAILED' ||
            job.partsStatus === 'ORDER_IN_PROGRESS' ||
            job.partsStatus === 'ORDERED') && (<Checkbox id={`job-${job.referenceNum}`} className="jobCheckbox" checked={!!selectedJobs[job.referenceNum]} color="primary" onChange={() => onItemSelected(job)}/>)}
    </div>);
};
export default CheckboxOrLabel;
