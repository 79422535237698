import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPartnerFunnels } from 'src/PartnersApp/modules/partners/selectors';
const useCustomFunnels = () => {
    const [allPages, setAllPages] = useState();
    const funnels = useSelector(selectPartnerFunnels);
    useEffect(() => {
        var _a;
        if (!funnels)
            return;
        const ogFunnel = funnels.find((f) => f.original);
        if (!ogFunnel)
            return;
        setAllPages((_a = ogFunnel === null || ogFunnel === void 0 ? void 0 : ogFunnel.pages) === null || _a === void 0 ? void 0 : _a.map((p) => ({
            id: p.id,
            name: p.name,
            header: p.header,
            subheader: p.subheader,
            callToAction: p.callToAction,
            hasCta: p.hasCta,
            ordinal: p.ordinal,
            path: p.path,
            required: p.required,
            selected: true,
        })));
    }, [funnels]);
    return {
        allPages,
    };
};
export default useCustomFunnels;
