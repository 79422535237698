var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { selectCarsDashboardFetching, selectCarsDashboardFleets, selectCarsDashboardPagination, selectCarsDashboardParams, selectFleetById, } from 'src/AdminApp/modules/fleets/selectors';
import { selectCurrentUserId } from 'src/modules/auth/selectors';
import { addCarToFleet, getFleet, getFleetCars, getResponsiblePartyByFleetId, processElementPmCsv, processLeasePlanPmCsv, searchCars, updateCarStatusToFleet, updateFleetCar, uploadFleetCsv, } from 'src/AdminApp/modules/fleets/actions';
import { getResponsiblePartyTypes, getResponsiblePartiesByType, } from 'src/AdminApp/modules/requests/actions';
import { getVehicleDescriptionByVin } from 'src/AdminApp/modules/autoselection/actions';
import { setFleet, setNextTab, setTab, setVehicle, } from 'src/AdminApp/modules/quickRo/actions';
import { FLEET_TABS as TABS } from 'src/AdminApp/modules/quickRo/enums';
import { toast } from 'src/components/SimpleToast';
import { FleetBulkVehicleImportCSVFormat, PmCsvImportStatus, } from 'src/AdminApp/models/enums';
const useFleetVehicles = () => {
    const dispatch = useDispatch();
    const { fleetId } = useParams();
    const [addCarQuickROIsOpen, setAddCarQuickROIsOpen] = useState(false);
    const [editCarIsOpen, setEditCarIsOpen] = useState(false);
    const [removeCarIsOpen, setRemoveCarIsOpen] = useState(false);
    const [importCsvIsOpen, setImportCsvIsOpen] = useState(false);
    const [allResponsibleParties, setAllResponsibleParties] = useState();
    const cars = useSelector(selectCarsDashboardFleets);
    const pagination = useSelector(selectCarsDashboardPagination);
    const params = useSelector(selectCarsDashboardParams);
    const fleet = useSelector(selectFleetById(fleetId));
    const isFetching = useSelector(selectCarsDashboardFetching);
    const userId = useSelector(selectCurrentUserId);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if ((fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) &&
                ((_a = fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                !allResponsibleParties) {
                const rpTypes = yield dispatch(getResponsiblePartyTypes());
                const rpTypesData = rpTypes === null || rpTypes === void 0 ? void 0 : rpTypes.payload;
                const promises = rpTypesData.map((type) => {
                    return dispatch(getResponsiblePartiesByType({
                        responsiblePartyTypeId: type.id,
                    }));
                });
                const response = yield Promise.all(promises);
                const responsibleParties = response.reduce((acc, el) => {
                    acc.push(...el.payload);
                    return acc;
                }, []);
                setAllResponsibleParties(responsibleParties);
            }
        }))();
    }, [fleet === null || fleet === void 0 ? void 0 : fleet.fleetResponsibleParties]);
    useEffect(() => {
        var _a, _b, _c;
        dispatch(getFleetCars(Object.assign(Object.assign({ fleetId }, params), { pageSize: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _a !== void 0 ? _a : 20, pageIndex: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _b !== void 0 ? _b : 0, sort: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _c !== void 0 ? _c : ['carMake', 'asc'] })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleetId]);
    useEffect(() => {
        if (!(fleet === null || fleet === void 0 ? void 0 : fleet.id))
            return;
        dispatch(getResponsiblePartyByFleetId(fleetId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleet === null || fleet === void 0 ? void 0 : fleet.id]);
    useEffect(() => {
        if (editCarIsOpen) {
            dispatch(setFleet(fleet));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editCarIsOpen]);
    const onLoadData = ({ page, size, sort, }) => {
        var _a, _b, _c;
        const newPage = (_a = page !== null && page !== void 0 ? page : pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _a !== void 0 ? _a : 0;
        const newSize = (_b = size !== null && size !== void 0 ? size : pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _b !== void 0 ? _b : 20;
        const newSort = (_c = sort !== null && sort !== void 0 ? sort : pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _c !== void 0 ? _c : ['carMake', 'asc'];
        return dispatch(getFleetCars(Object.assign(Object.assign({ fleetId }, params), { pageSize: newSize, pageIndex: newPage, sort: newSort })));
    };
    const getCarById = (id) => cars.find((car) => car.id === id);
    const onSaveVehicle = (values) => __awaiter(void 0, void 0, void 0, function* () {
        if (yield isVinFound(values.vin)) {
            onAddCardOldModal(values);
        }
    });
    const isVinFound = (vin) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const response = yield dispatch(getVehicleDescriptionByVin({ vin })).catch(() => { });
        return ((_b = (_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : null) !== 404;
    });
    const onAddCar = () => {
        return onAddQuickROCar();
    };
    const onAddCardOldModal = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const isEditing = !!editCarIsOpen;
        const carId = editCarIsOpen === null || editCarIsOpen === void 0 ? void 0 : editCarIsOpen.id;
        const carFleetId = editCarIsOpen === null || editCarIsOpen === void 0 ? void 0 : editCarIsOpen.fleetId;
        const toastSuccessMessage = `Vehicle ${isEditing ? 'updated' : 'added'} successfully`;
        setEditCarIsOpen(false);
        if (isEditing) {
            const response = yield dispatch(updateFleetCar({
                fleetId: carFleetId,
                car: Object.assign(Object.assign({}, values), { id: carId, fleetFmcId: values.fleetFmcId === 'None' ? null : values.fleetFmcId, fleetResponsibleParties: values.fleetResponsibleParties }),
            }));
            if (response && !(response === null || response === void 0 ? void 0 : response.error)) {
                toast.success(toastSuccessMessage);
            }
        }
        else {
            const response = yield dispatch(addCarToFleet({
                fleetId: values.fleetId,
                car: Object.assign(Object.assign({}, values), { fleetFmcId: values.fleetFmcId === 'None' ? null : values.fleetFmcId, fleetResponsibleParties: values.fleetResponsibleParties }),
            }));
            if (response && !(response === null || response === void 0 ? void 0 : response.error)) {
                toast.success(toastSuccessMessage);
            }
        }
        onLoadData({ page: 0, size: pagination.pageSize });
    });
    const onAddQuickROCar = () => {
        var _a, _b, _c;
        dispatch(setFleet(fleet));
        if (!((_c = (_b = (_a = fleet === null || fleet === void 0 ? void 0 : fleet.consumer) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.street) === null || _c === void 0 ? void 0 : _c.length)) {
            dispatch(setNextTab(TABS.ADD_NEW_VEHICLE));
        }
        else {
            dispatch(setTab(TABS.ADD_NEW_VEHICLE));
        }
        setAddCarQuickROIsOpen(true);
    };
    const loadFleet = (car) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e;
        const response = yield dispatch(getFleet(car.fleetId));
        const fleet = response.payload;
        dispatch(setFleet(fleet));
        dispatch(setVehicle(car));
        if (!((_e = (_d = (_c = fleet === null || fleet === void 0 ? void 0 : fleet.consumer) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.street) === null || _e === void 0 ? void 0 : _e.length)) {
            dispatch(setNextTab(TABS.ADD_SERVICES));
            dispatch(setTab(TABS.ADD_FLEET_CUSTOMER));
        }
        else {
            dispatch(setTab(TABS.ADD_SERVICES));
        }
        setAddCarQuickROIsOpen(true);
    });
    const onAddRequestOpen = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const car = yield getCarById(id);
        if (!car)
            return;
        dispatch(setVehicle(car));
        loadFleet(car);
    });
    const uploadFileToS3 = (csvFile) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield dispatch(uploadFleetCsv(csvFile, (userId !== null && userId !== void 0 ? userId : -1).toString()));
            return response.payload;
        }
        catch (e) {
            toast.error('Unable to upload file');
        }
    });
    const onImportSubmit = (cars, csvFile, importCSVFormat, cancelDeltaRepairRequests) => __awaiter(void 0, void 0, void 0, function* () {
        var _f, _g, _h, _j, _k, _l;
        // TODO: this logic have to be replaced for using bulk add instead of multiple single add
        if (cars && cars.length) {
            const s3Response = yield uploadFileToS3(csvFile);
            if (!s3Response)
                return;
            const showToast = (r) => {
                if (r.payload.status === PmCsvImportStatus.PROCESSED_SUCCESSFUL) {
                    toast.success(`${PmCsvImportStatus.PROCESSED_SUCCESSFUL}
totalRows: ${r.payload.totalRows}
totalVehicles: ${r.payload.totalVehicles}`);
                }
                else {
                    toast.error(`${PmCsvImportStatus.PROCESSED_WITH_ERRORS}
totalRows: ${r.payload.totalRows}
invalidRows: ${r.payload.invalidRows}
totalVehicles: ${r.payload.totalVehicles}
invalidFleets: ${r.payload.invalidFleets}
invalidRepairs: ${r.payload.invalidRepairs}
invalidVehicles: ${r.payload.invalidVehicles}`);
                }
            };
            switch (importCSVFormat) {
                case FleetBulkVehicleImportCSVFormat.LeasePlanPMDetail: {
                    const response1 = yield dispatch(processLeasePlanPmCsv({
                        fleetId,
                        url: s3Response.url.url,
                        cancelDeltaRepairRequests,
                    }));
                    onLoadData({
                        page: (_f = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _f !== void 0 ? _f : 0,
                        size: (_g = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _g !== void 0 ? _g : 10,
                        sort: (_h = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _h !== void 0 ? _h : ['carMake', 'asc'],
                    }).then(() => {
                        showToast(response1);
                    });
                    break;
                }
                case FleetBulkVehicleImportCSVFormat.ElementPMDetail: {
                    const response2 = yield dispatch(processElementPmCsv({
                        fleetId,
                        url: s3Response.url.url,
                        cancelDeltaRepairRequests,
                    }));
                    onLoadData({
                        page: (_j = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _j !== void 0 ? _j : 0,
                        size: (_k = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _k !== void 0 ? _k : 10,
                        sort: (_l = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _l !== void 0 ? _l : ['carMake', 'asc'],
                    }).then(() => {
                        showToast(response2);
                    });
                    break;
                }
                case FleetBulkVehicleImportCSVFormat.RepairSmithStandard:
                    yield Promise.all(cars.map((x) => __awaiter(void 0, void 0, void 0, function* () {
                        yield dispatch(addCarToFleet({ fleetId, car: x }));
                        onLoadData({
                            page: 0,
                            size: 20,
                        });
                    })));
                    break;
                default:
                    break;
            }
            setImportCsvIsOpen(false);
        }
    });
    const onSearchCars = ({ query }) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(searchCars({ query, fleetId }));
    });
    const onRemoveCarSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _m;
        const car = removeCarIsOpen;
        setRemoveCarIsOpen(false);
        yield dispatch(updateCarStatusToFleet({
            fleetId: car.fleetId,
            carId: (_m = car === null || car === void 0 ? void 0 : car.id) !== null && _m !== void 0 ? _m : -1,
            status: 'true',
        }));
        onLoadData({ page: 0, size: 20 });
    });
    return {
        setImportCsvIsOpen,
        onAddCar,
        onSearchCars,
        onLoadData,
        pagination,
        cars,
        isFetching,
        onAddRequestOpen,
        setEditCarIsOpen,
        getCarById,
        setRemoveCarIsOpen,
        addCarQuickROIsOpen,
        setAddCarQuickROIsOpen,
        removeCarIsOpen,
        onRemoveCarSubmit,
        importCsvIsOpen,
        onImportSubmit,
        editCarIsOpen,
        onSaveVehicle,
        fleet,
        allResponsibleParties,
    };
};
export default useFleetVehicles;
