import { Grid } from 'src/components/mui';
import React from 'react';
import { ActionButton, DestructiveButton } from 'src/components/buttons';
import { CheckboxField } from 'src/components/SimpleCheckbox';
import styled from 'styled-components';
import { FieldTitle } from './Form';
const CheckAllButtonContainer = styled.div `
  margin-bottom: 15px;

  button {
    display: inline-block;
    width: 150px;
  }

  > :not(:last-child) {
    margin-right: 16px;
  }
`;
const CheckboxGroup = ({ checkboxItems, checkboxState, disabledGroups, onCheckboxChange, checkAll, clearAll, title, }) => {
    if (!checkboxItems || !checkboxState) {
        return null;
    }
    return (<Grid container direction="column" style={{ width: '100%', alignItems: 'flex-start' }}>
      <FieldTitle>{title}</FieldTitle>
      <CheckAllButtonContainer>
        <ActionButton onClick={checkAll !== null && checkAll !== void 0 ? checkAll : (() => { })}>Check All</ActionButton>
        <DestructiveButton onClick={clearAll !== null && clearAll !== void 0 ? clearAll : (() => { })}>
          Clear All
        </DestructiveButton>
      </CheckAllButtonContainer>

      <Grid container spacing={2}>
        {checkboxItems.map(({ value, id }) => {
            const checked = checkboxState.includes(id);
            return (<Grid item xs={6} md={3} key={id}>
              <CheckboxField checked={checked} label={value} name={id} onChange={(e) => {
                    onCheckboxChange(id, e.target.checked);
                }} disabled={disabledGroups === null || disabledGroups === void 0 ? void 0 : disabledGroups.includes(id)}/>
            </Grid>);
        })}
      </Grid>
    </Grid>);
};
export default CheckboxGroup;
