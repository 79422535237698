var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Formik } from 'formik';
import { omit } from 'lodash';
import React, { useState } from 'react';
import { TextField } from 'src/AdminApp/components/form';
import { Box, BoxPhoto, ImgWrapper, Thumb, } from 'src/AdminApp/containers/vehicle/CarModule/FourCornersDrawer/FourCornersDrawer.styles';
import NotesOriginTypes from 'src/AdminApp/models/enums/NotesOriginTypes';
import { get_base_64 } from 'src/AdminApp/utils/imageUtils';
import { Grid, MenuItem } from 'src/components/mui';
import { CameraAlt as CameraAltIcon, Delete as DeleteIcon, } from 'src/components/mui/icons';
import { DrawerFormRow, FooterActionButton, Instructions, SimpleDrawerContent, SimpleDrawerFooter, SubHeader, } from 'src/components/SimpleDrawer';
import { toast } from 'src/components/SimpleToast';
import { ErrorButton } from 'src/styles/styled';
import styled from 'styled-components';
import * as Yup from 'yup';
import { NotesEntityTypes, NotesOrigin, NotesTypes, NotesUrgency, typesOptions, } from '../constants';
import useNotes from '../useNotes';
import { getTypeValues } from '../utils';
export const WrapperBoxPhoto = styled.label `
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: top;
  width: 50%;
`;
const AddNotesDrawerForm = ({ type, repairRequestId, onClose, isAutoIntegrateFMC, }) => {
    const { notesAppliesTo, notesAppliesToRecommendedRepairs, saveNotes, notesAppliesToLoading, notesAppliesToRecommendedRepairsLoading, saveNotesLoading, onLoadData, pagination, } = useNotes(repairRequestId);
    const isLoading = notesAppliesToLoading ||
        notesAppliesToRecommendedRepairsLoading ||
        saveNotesLoading;
    const data = [
        ...notesAppliesTo,
        ...notesAppliesToRecommendedRepairs,
    ];
    const [photos, setPhotos] = useState(null);
    const addPhoto = (file) => {
        get_base_64(file).then((result) => {
            setPhotos(Object.assign(Object.assign({}, photos), { [file.name]: result }));
        });
    };
    const removePhoto = (name) => {
        const updatedPhotos = omit(photos, name);
        setPhotos(updatedPhotos);
    };
    const handleAddNote = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const appliesTo = [];
        const value = data.find((note) => {
            if (values.type === 'ORDER') {
                return note.entityType === NotesEntityTypes.REPAIR_REQUEST;
            }
            return note.entityId === values.component;
        });
        if (value) {
            appliesTo.push(value);
        }
        const params = {
            entityId: repairRequestId,
            entityType: NotesEntityTypes.REPAIR_REQUEST,
            origin: NotesOriginTypes.TOOLS,
            content: values.note,
            repairRequestId,
            visibility: type === NotesTypes.INTERNAL ? 'INTERNAL' : 'EXTERNAL',
            urgency: NotesUrgency.NORMAL,
            appliesTo,
            base64PhotoStrings: photos,
        };
        const response = yield saveNotes(params);
        if (response && !response.error) {
            yield onLoadData({
                page: (pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) || 0,
                size: (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || 10,
                sort: (pagination === null || pagination === void 0 ? void 0 : pagination.sort) || ['created,desc'],
            });
            toast.success('Note added successfully.');
            onClose();
        }
    });
    const validationSchema = Yup.object().shape({
        component: Yup.string().when('type', (type) => {
            if (type !== 'ORDER') {
                return Yup.string().required();
            }
        }),
        note: Yup.string().required(),
        type: Yup.string().required(),
    });
    return (<Formik initialValues={{
            component: '',
            note: '',
            type: '',
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            setSubmitting(true);
            yield handleAddNote(values);
            setSubmitting(false);
        })} validateOnChange>
      {({ isSubmitting, isValid, isValidating, setFieldValue, setFieldTouched, handleSubmit, values, touched, dirty, }) => {
            var _a;
            return (<>
          <SimpleDrawerContent>
            <Grid container>
              <DrawerFormRow item>
                <SubHeader>Add Note</SubHeader>
                <Instructions>
                  {`Select the parts${type !== NotesTypes.FMC_FACING ? ', or repair, ' : ''} or entire RO to apply a note to. `}
                </Instructions>
              </DrawerFormRow>

              <DrawerFormRow item xs={12}>
                <TextField select fullWidth label="Select a type" name="type" variant="outlined" value={values.type} error={touched.type && values.type.length === 0} onBlur={() => setFieldTouched('type')} onChange={(e) => {
                    setFieldValue('type', e.target.value);
                }}>
                  {typesOptions
                    .filter((item) => isAutoIntegrateFMC ? item.value !== 'REPAIR' : true)
                    .map(({ value, label }) => (<MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>))}
                </TextField>
              </DrawerFormRow>
              {values.type && values.type !== 'ORDER' && (<DrawerFormRow item xs={12}>
                  <TextField select fullWidth label={`Select a ${values.type.toLowerCase()}`} name="components" variant="outlined" value={values.component} onChange={(e) => {
                        setFieldValue('component', e.target.value);
                    }}>
                    {getTypeValues(data, values.type).map(({ entityId, entityName, entityOriginId, originatedFrom, }) => {
                        var _a;
                        const entityOriginName = (_a = data.find((item) => item.entityId === entityOriginId)) === null || _a === void 0 ? void 0 : _a.entityName;
                        return (<MenuItem key={entityId} value={entityId}>
                            {`${NotesOrigin.values[originatedFrom]} - ${entityOriginName !== null && entityOriginName !== void 0 ? entityOriginName : ' '}${entityOriginName ? ' - ' : ' '} `}
                            <span style={{
                                fontWeight: 'bold',
                                marginLeft: '3px',
                            }}>
                              {entityName}
                            </span>
                          </MenuItem>);
                    })}
                  </TextField>
                </DrawerFormRow>)}
              <DrawerFormRow item xs={12}>
                <TextField variant="outlined" fullWidth multiline minRows={5} InputProps={{
                    style: { fontSize: '13px', padding: '12px' },
                }} label="Note" name="note" type="text" value={values.note} onChange={(e) => {
                    setFieldValue('note', e.target.value);
                }}/>
              </DrawerFormRow>
              <DrawerFormRow>
                {photos &&
                    ((_a = Object.keys(photos)) === null || _a === void 0 ? void 0 : _a.map((key) => {
                        return (<Thumb key={key}>
                        <ImgWrapper>
                          <img alt={key} src={photos[key]}/>
                        </ImgWrapper>
                        <ErrorButton disabled={false} onClick={() => removePhoto(key)}>
                          <DeleteIcon />
                          Delete photo
                        </ErrorButton>
                      </Thumb>);
                    }))}
                <input type="file" style={{ display: 'none' }} id="upload-additional" accept="image/*" onChange={(e) => __awaiter(void 0, void 0, void 0, function* () {
                    const file = e.target.files && e.target.files[0];
                    if (!file)
                        return;
                    addPhoto(file);
                })}/>
                <WrapperBoxPhoto htmlFor="upload-additional">
                  <BoxPhoto>
                    <Box>
                      <CameraAltIcon />
                    </Box>
                  </BoxPhoto>
                </WrapperBoxPhoto>
              </DrawerFormRow>
            </Grid>
          </SimpleDrawerContent>
          <SimpleDrawerFooter>
            <FooterActionButton disabled={!isValid ||
                    isValidating ||
                    isSubmitting ||
                    isLoading ||
                    !dirty ||
                    (values.type !== 'ORDER' && values.component.length === 0)} onClick={handleSubmit}>
              Submit
            </FooterActionButton>
          </SimpleDrawerFooter>
        </>);
        }}
    </Formik>);
};
export default AddNotesDrawerForm;
