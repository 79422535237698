var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Divider, Drawer, FormGroup, Grid, MenuItem, Typography, } from 'src/components/mui';
import { Field, Formik } from 'formik';
import { find } from 'lodash';
import React, { Fragment, useRef, useState } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useSelector } from 'react-redux';
import { StyledTab, SubHeader, } from 'src/AdminApp/components/drawers/styledComponents';
import { TextField } from 'src/AdminApp/components/form';
import SubletAddressSelect from 'src/AdminApp/components/repairs/statement/Repairs/Modals/SubletAddressSelect';
import { sortRepairs } from 'src/AdminApp/components/repairs/statement/utils';
import ShopFinder from 'src/AdminApp/containers/shops/NewShopFinder';
import { selectRequestRequestReferenceNum, selectVehicleByRefNum, } from 'src/AdminApp/modules/requests/selectors';
import { selectFirstWorkOrderIdByRequestRefNum, selectWorkOrderSubletsByRefNum, } from 'src/AdminApp/modules/workOrders/selectors';
import { getTimezoneByMapBox } from 'src/clients/mapboxClient';
import { DeleteButton } from 'src/components/buttons';
import { LabelledCheckbox } from 'src/components/SimpleCheckbox/Checkbox';
import { DrawerFormRow, FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import { theme } from 'src/styles/theme';
import { constructAddressObj } from 'src/utils/address-utils';
import { formatCurrency } from 'src/utils/formatter';
import styled from 'styled-components';
const SubletFinderDrawer = styled(Drawer) `
  & .paper {
    width: 100%;
  }
`;
// This is a temporary fix for the shop finder
// TODO: Fix this
// ShopFinder should be rewritten to functional component. Currently it is a
// class component and is not compatible with latest typescript
const ShopFinderUntyped = ShopFinder;
const SubletFinderContentWrapper = styled.div `
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.5px;
  width: ${window.innerWidth}px;

  ${theme.breakpoints.up('md')} {
    width: ${window.innerWidth - 152}px;
  }
`;
const RepairRow = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 16px;
`;
const SubletCostField = styled(TextField) `
  margin-top: 8px;
`;
const btnStyle = { marginRight: -16 };
const Repair = ({ index = null, repairName, quotedPrice, totalPrice, }) => (<div style={{ width: '330px', padding: index === null ? '0 16px' : '0' }}>
    <div style={{ maxWidth: '300px' }}>
      <Typography variant="h6">
        {index !== null ? `${index + 1}. ` : ''}
        {repairName}
      </Typography>
    </div>
    <div>
      <Typography variant="body1">
        {`Quoted: ${quotedPrice} • Total: ${totalPrice}`}
      </Typography>
    </div>
  </div>);
export const SubletFinderContent = styled.div `
  z-index: 10;
  overflow: hidden;
  width: 100%;
  padding: 24px 32px;
`;
const initValues = {
    repairIds: {},
    repairIdsCost: {},
};
const SubletItems = ({ sublets }) => (<Fragment key="sublet-items">
    {sublets.map((sub) => (<MenuItem style={{ maxWidth: '400px' }} value={sub.id} key={sub.id}>
        {`${sub.shopName} - ${sub.id}`}
      </MenuItem>))}
  </Fragment>);
const SubletDrawer = ({ open, onClose, onAddSublet, onAddRepairs, onRemoveRepairs, invoice, referenceNum, }) => {
    var _a, _b, _c;
    const firstTabRef = useRef(null);
    const formRef = useRef();
    const customRepairs = !((_a = invoice.diagnosisServices) === null || _a === void 0 ? void 0 : _a.customRequests)
        ? []
        : sortRepairs(invoice.diagnosisServices.customRequests).map((r) => {
            return Object.assign(Object.assign({}, r), { id: r.suggestedRepairId, name: r.message });
        });
    const repairs = [
        ...sortRepairs((_c = (_b = invoice.diagnosisServices) === null || _b === void 0 ? void 0 : _b.knownRepairs) !== null && _c !== void 0 ? _c : []),
        ...customRepairs,
    ];
    const request = useSelector(selectRequestRequestReferenceNum(referenceNum));
    const vehicle = useSelector(selectVehicleByRefNum(referenceNum));
    const sublets = useSelector(selectWorkOrderSubletsByRefNum(referenceNum));
    const workOrderId = useSelector(selectFirstWorkOrderIdByRequestRefNum(referenceNum));
    const [shopFinderOpen, setShopFinderOpen] = useState(false);
    const [shop, setShop] = useState({});
    const [sublet, setSublet] = useState();
    const [isCustomShop, setIsCustomShop] = useState(false);
    const [tab, setTab] = useState(0);
    const isNewSublet = tab === 0;
    const formShopValues = () => {
        var _a, _b;
        return shop
            ? {
                shopId: shop.id,
                shopName: shop.shopName,
                shopAddress: shop.address,
                shopCity: shop.city,
                shopState: shop.state,
                shopZip: shop.zip,
                shopPhone: shop.phone,
                shopContactName: `${(_a = shop.firstName) !== null && _a !== void 0 ? _a : ''} ${(_b = shop.lastName) !== null && _b !== void 0 ? _b : ''}`,
            }
            : null;
    };
    const onSelectShop = (isp) => {
        setShopFinderOpen(false);
        setShop(isp);
    };
    const handleCloseModal = () => {
        var _a, _b, _c;
        if ((_a = formRef === null || formRef === void 0 ? void 0 : formRef.current) === null || _a === void 0 ? void 0 : _a.resetForm) {
            (_b = formRef.current) === null || _b === void 0 ? void 0 : _b.resetForm();
        }
        setShop(null);
        (_c = firstTabRef.current) === null || _c === void 0 ? void 0 : _c.click();
        onClose();
    };
    const getFormattedShopAddress = () => {
        return (shop === null || shop === void 0 ? void 0 : shop.address)
            ? `${shop.address}, ${shop.city}, ${shop.state} ${shop.zip}`
            : '';
    };
    const tabs = [
        { label: 'Create New Sublet', value: 0 },
        sublets.length > 0 && { label: 'Update Sublet', value: 1 },
    ];
    const getTotalCost = (repairIdsCost) => Object.values(repairIdsCost)
        .reduce((prev, current) => prev + current, 0)
        .toFixed(2);
    const subletRepairs = sublets.reduce((prev, { repairItems }) => {
        repairItems === null || repairItems === void 0 ? void 0 : repairItems.forEach(({ suggestedRepairId }) => prev.push(suggestedRepairId));
        return prev;
    }, []);
    const getNewRepairs = (values) => {
        const updatedValues = Object.assign({}, values);
        subletRepairs.forEach((id) => {
            delete updatedValues.repairIds[id];
            delete updatedValues.repairIdsCost[`cost_${id}`];
        });
        return updatedValues;
    };
    return (<>
      <SimpleDrawer open={open} onClose={handleCloseModal}>
        <Formik innerRef={formRef} initialValues={initValues} enableReinitialize onSubmit={(values, { setSubmitting, resetForm }) => __awaiter(void 0, void 0, void 0, function* () {
            let shopValues = formShopValues();
            if (values.shopAddress && isCustomShop) {
                const parsed = yield geocodeByAddress(values.shopAddress);
                const addressObj = constructAddressObj(values.shopAddress, parsed);
                addressObj.timezone = yield getTimezoneByMapBox(addressObj.latitude, addressObj.longitude);
                shopValues = {
                    shopAddress: addressObj.street,
                    shopState: addressObj.state,
                    shopZip: addressObj.zip,
                    shopCity: addressObj.city,
                };
            }
            const shopInfo = isNewSublet ? shopValues : {};
            return isNewSublet
                ? onAddSublet(Object.assign(Object.assign(Object.assign({}, values), shopInfo), { workOrderId })).then(() => {
                    resetForm();
                    setShop(null);
                    setSublet(undefined);
                    setSubmitting(false);
                    setIsCustomShop(false);
                })
                : onAddRepairs(Object.assign(Object.assign({}, values), { workOrderId, subletId: sublet === null || sublet === void 0 ? void 0 : sublet.id })).then(() => {
                    var _a;
                    resetForm({ values: Object.assign({}, values) });
                    setSubmitting(false);
                    setSublet(undefined);
                    (_a = firstTabRef.current) === null || _a === void 0 ? void 0 : _a.click();
                });
        })}>
          {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
            const [selectedRepairs, selectedCosts] = [
                Object.keys(values.repairIds),
                Object.keys(values.repairIdsCost),
            ];
            const [idsLen, costsLen] = [
                selectedRepairs.length,
                selectedCosts.length,
            ];
            const invalidCost = idsLen !== costsLen ||
                selectedCosts.some((key) => { var _a; return ((_a = values.repairIdsCost) === null || _a === void 0 ? void 0 : _a[key]) === null; });
            const isBtnDisabled = isSubmitting || idsLen === 0 || costsLen === 0 || invalidCost;
            return (<>
                <SimpleDrawerContent>
                  <SimpleDrawerHeader title="Add Sublet" onClose={handleCloseModal}/>
                  <Grid container spacing={2}>
                    {repairs
                    .filter((repair) => {
                    if (sublet)
                        return true;
                    const inSomeSublet = sublets.some(({ repairItems }) => repairItems === null || repairItems === void 0 ? void 0 : repairItems.some(({ suggestedRepairId }) => repair.id === suggestedRepairId));
                    return !inSomeSublet;
                })
                    .map((repair, index) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                    const isSelected = values.repairIds[(_a = repair.id) !== null && _a !== void 0 ? _a : -1];
                    const inSublet = (_b = sublet === null || sublet === void 0 ? void 0 : sublet.repairItems) === null || _b === void 0 ? void 0 : _b.some(({ suggestedRepairId }) => suggestedRepairId === repair.id);
                    if (inSublet) {
                        const item = ((_c = sublet === null || sublet === void 0 ? void 0 : sublet.repairItems) !== null && _c !== void 0 ? _c : []).find(({ suggestedRepairId }) => repair.id === suggestedRepairId);
                        if (!values.repairIds[(_d = item === null || item === void 0 ? void 0 : item.suggestedRepairId) !== null && _d !== void 0 ? _d : -1]) {
                            setFieldValue('repairIds', Object.assign(Object.assign({}, values.repairIds), { [(_e = item === null || item === void 0 ? void 0 : item.suggestedRepairId) !== null && _e !== void 0 ? _e : -1]: true }));
                            setFieldValue('repairIdsCost', Object.assign(Object.assign({}, values.repairIdsCost), { [`cost_${(_f = item === null || item === void 0 ? void 0 : item.suggestedRepairId) !== null && _f !== void 0 ? _f : -1}`]: item === null || item === void 0 ? void 0 : item.subletCost }));
                        }
                    }
                    return (<DrawerFormRow item xs={12} key={repair.id}>
                            {inSublet ? (<RepairRow>
                                {repair && (<Repair index={index} repairName={(_g = repair.name) !== null && _g !== void 0 ? _g : ''} quotedPrice={formatCurrency((_h = repair.price[0].quotedTotalCost) !== null && _h !== void 0 ? _h : 0)} totalPrice={formatCurrency((_j = repair.price[0].totalCost) !== null && _j !== void 0 ? _j : 0)}/>)}
                                <DeleteButton style={btnStyle} onClick={() => {
                                var _a, _b;
                                onRemoveRepairs({
                                    repairItems: [repair.id],
                                    subletId: sublet === null || sublet === void 0 ? void 0 : sublet.id,
                                });
                                setSublet(Object.assign(Object.assign({}, sublet), { repairItems: ((_a = sublet === null || sublet === void 0 ? void 0 : sublet.repairItems) !== null && _a !== void 0 ? _a : []).filter(({ suggestedRepairId }) => repair.id !== suggestedRepairId) }));
                                const updatedValues = Object.assign({}, values);
                                delete updatedValues.repairIds[(_b = repair.id) !== null && _b !== void 0 ? _b : -1];
                                delete updatedValues.repairIdsCost[`cost_${repair.id}`];
                                setFieldValue('repairIds', updatedValues.repairIds);
                                setFieldValue('repairIdsCost', updatedValues.repairIdsCost);
                            }}/>
                              </RepairRow>) : (<FormGroup aria-label="position" row>
                                <LabelledCheckbox checkboxProps={{
                                onChange: (event) => {
                                    var _a, _b;
                                    const isChecked = event.target.checked;
                                    setFieldValue('repairIds', Object.assign(Object.assign({}, values.repairIds), { [(_a = repair.id) !== null && _a !== void 0 ? _a : -1]: isChecked }));
                                    if (!isChecked) {
                                        const updatedValues = Object.assign({}, values);
                                        delete updatedValues.repairIds[(_b = repair.id) !== null && _b !== void 0 ? _b : -1];
                                        delete updatedValues.repairIdsCost[`cost_${repair.id}`];
                                        setFieldValue('repairIds', updatedValues.repairIds);
                                        setFieldValue('repairIdsCost', updatedValues.repairIdsCost);
                                    }
                                },
                            }} value={repair.id} label={<Repair index={index} repairName={(_k = repair.name) !== null && _k !== void 0 ? _k : ''} quotedPrice={formatCurrency((_l = repair.price[0].quotedTotalCost) !== null && _l !== void 0 ? _l : 0)} totalPrice={formatCurrency((_m = repair.price[0].totalCost) !== null && _m !== void 0 ? _m : 0)}/>} labelPlacement="start"/>
                              </FormGroup>)}
                            {(inSublet || isSelected) && (<SubletCostField fullWidth label="Sublet Cost" name={`cost_${repair.id}`} type="number" variant="outlined" value={values.repairIdsCost[`cost_${repair.id}`]} onChange={(e) => {
                                const value = e.currentTarget.value
                                    ? parseFloat(e.currentTarget.value)
                                    : null;
                                setFieldValue('repairIdsCost', Object.assign(Object.assign({}, values.repairIdsCost), { [`cost_${repair.id}`]: value }));
                            }}/>)}
                            <Divider style={{
                            marginTop: 20,
                            width: '100%',
                        }}/>
                          </DrawerFormRow>);
                })}
                    <DrawerFormRow item xs={12}>
                      <TextField fullWidth label="Total Sublet Cost" name="totalCost" variant="outlined" value={getTotalCost(values.repairIdsCost)}/>
                    </DrawerFormRow>
                  </Grid>
                  <Grid>
                    <SubHeader indicatorColor="primary" color="white" value={tab} onChange={(_event, t) => {
                    setTab(t);
                    if (t === 0) {
                        setSublet(undefined);
                        setFieldValue('id', null);
                        const updatedValues = getNewRepairs(values);
                        setFieldValue('repairIds', updatedValues.repairIds);
                        setFieldValue('repairIdsCost', updatedValues.repairIdsCost);
                    }
                }} style={{ margin: '18px 0' }}>
                      {tabs.map((t, i) => (<StyledTab key={t.value} $freeWidth label={t.label} $isActive={tab === t.value} {...(i === 0 && { innerRef: firstTabRef })}/>))}
                    </SubHeader>
                  </Grid>
                  {tab === 0 ? (<Grid>
                      <SubletAddressSelect textFieldProps={{
                        variant: 'outlined',
                        label: 'Sublet Shop Address',
                    }} initialAddress={values.shopAddress || getFormattedShopAddress()} onChange={() => setShop(null)} onSelectNetwork={() => {
                        setIsCustomShop(false);
                        setShopFinderOpen(true);
                        setFieldValue('shopName', null);
                        setFieldValue('shopPhone', null);
                        setFieldValue('shopContactName', null);
                    }} onSelectAddress={(address) => {
                        setIsCustomShop(true);
                        setShop(null);
                        setFieldValue('shopAddress', address);
                    }}/>
                      {isCustomShop && (<>
                          <DrawerFormRow item xs={12} style={{ marginTop: '14px' }}>
                            <TextField variant="outlined" type="text" label="Sublet Shop Name" name="shopName" fullWidth/>
                          </DrawerFormRow>

                          <DrawerFormRow item xs={12}>
                            <TextField variant="outlined" type="text" label="Sublet Phone" name="shopPhone" fullWidth/>
                          </DrawerFormRow>

                          <DrawerFormRow item xs={12}>
                            <TextField variant="outlined" type="text" label="Sublet Contact Name" name="shopContactName" fullWidth/>
                          </DrawerFormRow>
                        </>)}
                    </Grid>) : (<Grid>
                      <Field name="id" render={({ field, form }) => {
                        const handleOnChange = (e) => {
                            const selectedSublet = find(sublets, {
                                id: e.target.value,
                            });
                            setSublet(selectedSublet);
                            const updatedValues = getNewRepairs(values);
                            form.setFieldValue('repairIds', updatedValues.repairIds);
                            form.setFieldValue('repairIdsCost', updatedValues.repairIdsCost);
                            form.setFieldValue(field.name, selectedSublet === null || selectedSublet === void 0 ? void 0 : selectedSublet.id);
                        };
                        return (<TextField {...field} select variant="outlined" label="Existing Sublet" fullWidth onChange={handleOnChange}>
                              <SubletItems sublets={sublets}/>
                            </TextField>);
                    }}/>
                    </Grid>)}
                </SimpleDrawerContent>

                <SimpleDrawerFooter>
                  <FooterActionButton disabled={isBtnDisabled} type="submit" onClick={() => handleSubmit()}>
                    {isNewSublet ? 'Request New Sublet' : 'Update Sublet'}
                  </FooterActionButton>
                </SimpleDrawerFooter>
              </>);
        }}
        </Formik>
      </SimpleDrawer>
      <SubletFinderDrawer anchor="right" open={shopFinderOpen} onClose={() => setShopFinderOpen(false)}>
        <SubletFinderContentWrapper>
          <SimpleDrawerHeader onClose={() => setShopFinderOpen(false)} title="Sublet Finder"/>
          <SubletFinderContent>
            <ShopFinderUntyped referenceNum={referenceNum} zip={request === null || request === void 0 ? void 0 : request.zip} vehicle={vehicle} consumerAddress={request === null || request === void 0 ? void 0 : request.address} onSelectShop={onSelectShop} suggestedRepairs={[]} sublet/>
          </SubletFinderContent>
        </SubletFinderContentWrapper>
      </SubletFinderDrawer>
    </>);
};
export default SubletDrawer;
