var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid } from 'src/components/mui';
import { Formik } from 'formik';
import React from 'react';
import TextField from 'src/AdminApp/components/form/TextField';
import { DrawerFormRow, FooterActionButton, SimpleDrawer, SimpleDrawerContent, SimpleDrawerFooter, SimpleDrawerHeader, } from 'src/components/SimpleDrawer';
import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
    dailyOpsContactName: Yup.string().required('Contact name is required'),
    dailyOpsContactPhone: Yup.string()
        .required('Contact phone number is required')
        .matches(/^[0-9]{10}$/, 'Must be a valid phone number'),
    dailyOpsContactEmail: Yup.string()
        .required('Contact Email is required')
        .email('Must be a valid email'),
});
const EditDrawerMobile = ({ open, onClose, contact, onContactSave, }) => {
    return (<SimpleDrawer open={open} onClose={onClose}>
      <SimpleDrawerHeader title="Edit" onClose={onClose}/>

      <Formik initialValues={{
            dailyOpsContactName: contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactName,
            dailyOpsContactPhone: contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactPhone,
            dailyOpsContactEmail: contact === null || contact === void 0 ? void 0 : contact.dailyOpsContactEmail,
        }} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => __awaiter(void 0, void 0, void 0, function* () {
            setSubmitting(true);
            yield onContactSave(Object.assign(Object.assign({}, contact), values));
            setSubmitting(false);
            onClose();
        })} validateOnChange>
        {({ isSubmitting, isValid, isValidating, handleSubmit, dirty, setFieldValue, values, errors, }) => (<>
            <SimpleDrawerContent>
              <Grid container direction="row" spacing={2}>
                <Grid item spacing={3}>
                  <h3>{contact === null || contact === void 0 ? void 0 : contact.fleetName}</h3>
                </Grid>
              </Grid>
              <DrawerFormRow item xs={12}>
                <TextField fullWidth name="name" label="Name" value={values.dailyOpsContactName} type="text" variant="outlined" onChange={(e) => {
                setFieldValue('dailyOpsContactName', e.target.value);
            }} error={!!errors.dailyOpsContactName} helperText={errors.dailyOpsContactName}/>
              </DrawerFormRow>
              <br />
              <DrawerFormRow item xs={12}>
                <TextField fullWidth name="phone" label="Phone" value={values.dailyOpsContactPhone} type="text" variant="outlined" onChange={(e) => {
                setFieldValue('dailyOpsContactPhone', e.target.value);
            }} error={!!errors.dailyOpsContactPhone} helperText={errors.dailyOpsContactPhone}/>
              </DrawerFormRow>
              <br />
              <DrawerFormRow>
                <TextField fullWidth name="email" label="Email" value={values.dailyOpsContactEmail} type="text" variant="outlined" onChange={(e) => {
                setFieldValue('dailyOpsContactEmail', e.target.value);
            }} error={!!errors.dailyOpsContactEmail} helperText={errors.dailyOpsContactEmail}/>
              </DrawerFormRow>
            </SimpleDrawerContent>
            <SimpleDrawerFooter>
              <FooterActionButton onClick={() => handleSubmit()} disabled={!isValid || isValidating || isSubmitting || !dirty}>
                Done
              </FooterActionButton>
            </SimpleDrawerFooter>
          </>)}
      </Formik>
    </SimpleDrawer>);
};
export default EditDrawerMobile;
