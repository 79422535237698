import { createContext } from 'react';
import moment from 'moment';
import { LOCALIZED_DATE_LONGER_FORMAT } from 'src/timeConstants';
export const SliderContext = createContext(null);
export const ratesFieldNames = [
    'General Repairs',
    'Maintenance Repairs',
    'Specialty Repairs',
    'Diagnosis',
];
export const regularRatesFields = [
    'generalRepair',
    'maintenanceRepair',
    'specialtyRepair',
    'diagnosis',
];
export const euRatesFields = [
    'euGeneralRepair',
    'euMaintenanceRepair',
    'euSpecialtyRepair',
    'euDiagnosis',
];
export const effectiveDate = (date) => date ? moment(date).format(LOCALIZED_DATE_LONGER_FORMAT) : '';
