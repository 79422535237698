import React, { useEffect, useState } from 'react';
import useScrollPosition from 'src/AdminApp/hooks/useScrollPosition';
import { Snackbar, SnackbarMessage, ActionButton, ActionText, RefreshIcon, } from './RefreshSnackbar.styles';
const MESSAGE = 'Some of the results you’re viewing have changed.  Reload page for new results.';
const RefreshSnackbar = ({ open, onClick, }) => {
    const [scrollPosition] = useScrollPosition();
    const [isScrollOnTop, setIsScrollOnTop] = useState(true);
    useEffect(() => {
        if (scrollPosition === 0)
            setIsScrollOnTop(true);
        else
            setIsScrollOnTop(false);
    }, [scrollPosition]);
    return (<Snackbar open={open} message={<SnackbarMessage>{MESSAGE}</SnackbarMessage>} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} isScrollOnTop={isScrollOnTop} action={<>
          <ActionButton onClick={onClick}>
            <ActionText>Refresh</ActionText>
            <RefreshIcon />
          </ActionButton>
        </>}></Snackbar>);
};
export default RefreshSnackbar;
