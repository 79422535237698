import styled from 'styled-components';
import { Grid, Typography, Icon } from 'src/components/mui';
import { colors } from 'src/styles/theme';
export const LevelIcon = styled(Icon) `
  font-size: 15px;
`;
export const ProgressBarPadding = styled.div `
  margin-right: 32px;
  flex-grow: 5;
  padding: 32px 36px;
`;
export const DotOverlay = styled.div `
  width: 100%;
  display: flex;
  height: 8px;
  justify-content: space-between;
  position: absolute;
  align-items: center;
`;
export const ProgressBarWrapper = styled.div `
  background-color: ${colors.lightGrey};
  border-radius: 4px;
  position: relative;
`;
export const ProgressBarFill = styled.span `
  display: block;
  height: 8px;
  border-radius: 4px;
  transition: width 500ms ease-in-out;
  width: ${({ width }) => width}%;
  background: ${({ $isCanceled }) => $isCanceled ? colors.lightRed : colors.primary};
`;
export const ProgressTypography = styled(Typography) `
  position: absolute;
  left: 50%;
  top: 16px;
  transform: translateX(-50%);
  font-size: 10px;
  white-space: nowrap;
  line-height: 10px;
`;
export const ProgressLabelWrapper = styled(Grid) `
  position: relative;
`;
export const LabelWrapper = styled(Grid) `
  padding: 0 12px;
`;
export const IconWrapper = styled.div `
  width: 24px;
  height: 24px;
  border: solid 1px ${colors.lightGrey};
  color: ${colors.iconGrey};
  padding: 3px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ type }) => {
    if (type === 'current') {
        return `
        background: ${colors.primary};
        color: ${colors.default};
        border-color: ${colors.default};
      `;
    }
    if (type === 'past' || type === 'last') {
        return `
        background: white;
        color: ${colors.default};
        border-color: ${colors.default};
      `;
    }
    return '';
}}
`;
