import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
export const VendorSearchResultsTable = styled.table `
  width: 100%;
  margin-top: 48px;
  border-collapse: collapse;

  .searchResultsHeader {
    text-align: left;
    border-bottom: 1px solid ${colors.GRAY_76};
    padding-bottom: 12px;
    font-family: ${FONT_FAMILY};
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};

    &.integrationHeader {
      width: 130px;
      white-space: nowrap;
    }
  }

  .searchResultsItem {
    padding: 24px 0;
    text-align: left;
    border-bottom: 1px solid ${colors.GRAY_76};
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_3F};
  }

  .vendorItem {
    padding-left: 6px;
  }
`;
const getPointerEvents = ({ disablePointerEvents, }) => (disablePointerEvents ? 'none' : 'auto');
export const SearchResultsRow = styled.tr `
  cursor: pointer;
  pointer-events: ${getPointerEvents};

  &:hover .searchResultsItem {
    background-color: ${colors.GRAY_F2};
  }

  &:active .searchResultsItem {
    background-color: rgba(44, 86, 221, 0.05);
  }
`;
export const VendorSearchResultsList = styled.div `
  margin-top: 40px;

  .nearbyVendors {
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_3F};
  }
`;
export const VendorList = styled.section `
  margin-top: 16px;

  .vendorCard:not(:last-child) {
    margin-bottom: 16px;
  }

  .vendorCardContent {
    padding: 0;
  }

  .vendorBtnRoot {
    padding: 0;
    width: 100%;
    text-align: left;
  }

  .vendorBtnLabel {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .itemTopSection {
    padding: 24px 16px 10px 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .vendorName {
    font-family: ${FONT_FAMILY};
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
    color: ${colors.GRAY_3F};
  }

  .vendorLabel {
    font-family: ${FONT_FAMILY};
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
  }

  .resultDivider {
    width: 100%;
  }

  .itemBottomSection {
    padding: 24px 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .addressLabel {
    font-family: ${FONT_FAMILY};
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
  }

  .vendorAddress {
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.3px;
    color: ${colors.GRAY_3F};
    margin-top: 4px;
  }

  .distanceLabel {
    font-family: ${FONT_FAMILY};
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_76};
  }

  .vendorDistance {
    font-family: ${FONT_FAMILY};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.1px;
    color: ${colors.GRAY_3F};
    margin-top: 4px;
  }
`;
export const NoNearbyVendors = styled.div `
  padding: 20px 0 50px;
  text-align: center;
  font-family: ${FONT_FAMILY};
  font-size: 16px;
`;
