import React from 'react';
import { Alert, Snackbar } from 'src/components/mui';
import { CheckCircleRounded as CheckCircleRoundedIcon } from 'src/components/mui/icons';
const MpiSentToCustomerAlert = ({ open, onClick, autoHideDuration, }) => {
    return (<Snackbar classes={{ root: 'snackBarRoot' }} open={open} onClose={onClick} autoHideDuration={autoHideDuration} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}>
      <div role="button" tabIndex={0} className="sentToCustomerContainer" onClick={onClick} onKeyDown={() => { }}>
        <Alert classes={{ root: 'userSuccess', message: 'userAlertMessage' }} severity="success" icon={<CheckCircleRoundedIcon fontSize="inherit"/>}>
          MPI Sent to Customer
        </Alert>
      </div>
    </Snackbar>);
};
export default MpiSentToCustomerAlert;
