export const get_base_64 = (file) => {
    return new Promise((acc, err) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            acc(reader.result);
        };
        reader.onerror = (error) => {
            err(error);
        };
    });
};
