import React from 'react';
import { ChevronLeft, ChevronRight } from 'src/components/mui/icons';
import { IconButton } from 'src/components/mui';
import moment from 'moment';
import { MONTH_AND_DAY_FORMAT, WEEK_DAY_SHORT_FORMAT } from 'src/timeConstants';
import { DateClicker, DateClickerCaption } from '../DatePicker.styles';
import useDatePicker from '../hooks/useDatePicker';
const DateSelector = ({ isMobile }) => {
    const { handleSetPrevDate, handleSetNextDate, selectedDate } = useDatePicker();
    return (<DateClicker isMobile={isMobile}>
      <IconButton onClick={handleSetPrevDate}>
        <ChevronLeft />
      </IconButton>
      <DateClickerCaption>
        <span>
          {moment(selectedDate).format(`${WEEK_DAY_SHORT_FORMAT}, ${MONTH_AND_DAY_FORMAT} YYYY`)}
        </span>
      </DateClickerCaption>

      <IconButton onClick={handleSetNextDate}>
        <ChevronRight />
      </IconButton>
    </DateClicker>);
};
export default DateSelector;
