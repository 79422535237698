import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { selectInspectionPerformedStatus, selectMpiInspectionByRepairRequestId, } from 'src/AdminApp/modules/mpi/selectors';
import { selectOrdersByReferenceNum, selectRequestDataByReferenceNum, selectShowMpiSentToCustomerAlert, } from 'src/AdminApp/modules/requests/selectors';
import { selectZendeskTickets } from 'src/AdminApp/modules/zendesk/selectors';
import { getZendeskTickets } from 'src/AdminApp/modules/zendesk/actions';
import { ZendeskTicketType } from 'src/AdminApp/models/enums/ZendeskTicketType';
import { setIsMpiAlertShowing, setShowMpiSentToCustomerAlert, } from 'src/AdminApp/modules/requests/actions';
import moment from 'moment/moment';
import { REPAIR_COMPLETED_WITH_INVOICE_STATE } from 'src/AdminApp/modules/api/constants';
import { updateInspectionStatus } from 'src/AdminApp/containers/vehicle/CarModule/helpers/updateInspectionStatus';
import { selectCurrentUser } from 'src/AdminApp/modules/users/selectors';
import { selectPartnerById } from 'src/PartnersApp/modules/partners/selectors';
import { getPartner } from 'src/PartnersApp/modules/partners/actions';
const initialState = {
    dialogs: {
        showNotifySADialog: false,
        showMpiNotifyCustomerDialog: false,
    },
    alerts: {
        showMpiPendingAlert: false,
        showQuoteRequestedAlert: false,
        showQuotePendingAlert: false,
        showChangesPendingAlert: false,
    },
    customerAlertAutoHide: 3000,
    updateSA: false,
    isAlertShowing: false,
};
const calculateIsShowing = (newState) => !!Object.values(newState.alerts).find((a) => a);
function reducer(state, action) {
    switch (action.type) {
        case 'setShowNotifySADialog':
            return Object.assign(Object.assign({}, state), { dialogs: Object.assign(Object.assign({}, state.dialogs), { showNotifySADialog: action.payload }) });
        case 'setShowMpiNotifyCustomerDialog':
            return Object.assign(Object.assign({}, state), { updateSA: action.payload, dialogs: Object.assign(Object.assign({}, state.dialogs), { showMpiNotifyCustomerDialog: action.payload }) });
        case 'setCustomerAlertAutoHide':
            return Object.assign(Object.assign({}, state), { customerAlertAutoHide: action.payload });
        case 'setShowQuoteRequestedAlert': {
            const newState = Object.assign(Object.assign({}, state), { alerts: Object.assign(Object.assign({}, state.alerts), { showQuoteRequestedAlert: action.payload }) });
            if (action.payload) {
                newState.dialogs = Object.assign(Object.assign({}, state.dialogs), { showNotifySADialog: false });
            }
            newState.isAlertShowing = calculateIsShowing(newState);
            return newState;
        }
        case 'setShowQuotePendingAlert': {
            const newState = Object.assign(Object.assign({}, state), { alerts: Object.assign(Object.assign({}, state.alerts), { showQuotePendingAlert: action.payload }) });
            newState.isAlertShowing = calculateIsShowing(newState);
            return newState;
        }
        case 'setShowMpiPendingAlert': {
            const newState = Object.assign(Object.assign({}, state), { alerts: Object.assign(Object.assign({}, state.alerts), { showMpiPendingAlert: action.payload }), isAlertShowing: action.payload });
            newState.isAlertShowing = calculateIsShowing(newState);
            return newState;
        }
        case 'setShowChangesPendingAlert': {
            const newState = Object.assign(Object.assign({}, state), { alerts: Object.assign(Object.assign({}, state.alerts), { showChangesPendingAlert: action.payload }) });
            newState.isAlertShowing = calculateIsShowing(newState);
            return newState;
        }
        case 'setUpdateSA': {
            return Object.assign(Object.assign({}, state), { updateSA: action.payload });
        }
        case 'reset':
            return initialState;
        default:
            throw new Error();
    }
}
const useAlertsAndDialogs = (params) => {
    var _a, _b;
    const { repairRequestId, onComplete, recommendedRepairsDirty, hasRecommendedRepairs, } = params;
    const dispatch = useDispatch();
    const [state, localDispatch] = useReducer(reducer, initialState);
    const { referenceNum } = useParams();
    const history = useHistory();
    const urlParams = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
    const mpi = useSelector(selectMpiInspectionByRepairRequestId(repairRequestId));
    const inspectionPerformedStatus = useSelector(selectInspectionPerformedStatus);
    const orders = useSelector(selectOrdersByReferenceNum(referenceNum));
    const currentUser = useSelector(selectCurrentUser);
    const zendeskTickets = useSelector(selectZendeskTickets);
    const showMpiSentToCustomerAlert = useSelector(selectShowMpiSentToCustomerAlert(referenceNum));
    const repairRequest = useSelector(selectRequestDataByReferenceNum(referenceNum));
    const partnerInfo = useSelector(selectPartnerById((_a = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _a === void 0 ? void 0 : _a.partnerId));
    const suppressNotifications = partnerInfo === null || partnerInfo === void 0 ? void 0 : partnerInfo.disableNonAppointmentReminderCommunications;
    const currentAuthGroups = (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.authGroups) !== null && _b !== void 0 ? _b : [];
    const currentUserIsSA = currentAuthGroups.includes('Service Advisors');
    useEffect(() => {
        dispatch(getZendeskTickets(referenceNum, ZendeskTicketType[ZendeskTicketType.MPI_QUOTE_NEEDED]));
        if (urlParams.get('mpiSentToCustomer')) {
            dispatch(setShowMpiSentToCustomerAlert(true, { referenceNum }));
            localDispatch({ type: 'setCustomerAlertAutoHide', payload: 7000 });
        }
    }, [dispatch, referenceNum, urlParams]);
    useEffect(() => {
        if (zendeskTickets && inspectionPerformedStatus) {
            const newTickets = zendeskTickets.filter((t) => t.status === 'NEW');
            if ((newTickets === null || newTickets === void 0 ? void 0 : newTickets.length) > 0) {
                newTickets.sort((a, b) => {
                    return new Date(b.created).getTime() - new Date(a.created).getTime();
                });
                const showQuotePending = (!inspectionPerformedStatus.lastSent ||
                    moment
                        .utc(newTickets[0].created)
                        .isAfter(moment.utc(inspectionPerformedStatus.lastSent))) &&
                    hasRecommendedRepairs;
                localDispatch({
                    type: 'setShowQuotePendingAlert',
                    payload: showQuotePending,
                });
            }
        }
    }, [zendeskTickets, inspectionPerformedStatus, hasRecommendedRepairs]);
    useEffect(() => {
        // SHOW MPI PENDING ALERT
        if (mpi && inspectionPerformedStatus && (orders === null || orders === void 0 ? void 0 : orders.length) > 0) {
            const isAutomaticallySent = orders[0].resolution === REPAIR_COMPLETED_WITH_INVOICE_STATE &&
                (inspectionPerformedStatus === null || inspectionPerformedStatus === void 0 ? void 0 : inspectionPerformedStatus.lastSent);
            // FIRST MPI SAVE WITH NO SERVICE ADVISOR
            // 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
            const showPending = !isAutomaticallySent &&
                mpi.completedAt &&
                mpi.updatedAt &&
                mpi.inspectionResults.length > 0 &&
                (!inspectionPerformedStatus.serviceAdvisorLastSaved ||
                    moment(mpi.updatedAt).isAfter(moment.utc(inspectionPerformedStatus.serviceAdvisorLastSaved)));
            localDispatch({ type: 'setShowMpiPendingAlert', payload: showPending });
        }
    }, [mpi, inspectionPerformedStatus, orders]);
    useEffect(() => {
        if (recommendedRepairsDirty &&
            !state.alerts.showQuotePendingAlert &&
            !state.alerts.showMpiPendingAlert) {
            localDispatch({ type: 'setShowChangesPendingAlert', payload: true });
        }
    }, [
        recommendedRepairsDirty,
        state.alerts.showMpiPendingAlert,
        state.alerts.showQuotePendingAlert,
    ]);
    // LISTEN TO ALL ALERTS
    useEffect(() => {
        dispatch(setIsMpiAlertShowing(state.isAlertShowing || showMpiSentToCustomerAlert, {
            referenceNum,
        }));
    }, [
        state.isAlertShowing,
        showMpiSentToCustomerAlert,
        dispatch,
        referenceNum,
    ]);
    useEffect(() => {
        var _a, _b;
        if ((_a = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _a === void 0 ? void 0 : _a.partnerId) {
            dispatch(getPartner((_b = repairRequest === null || repairRequest === void 0 ? void 0 : repairRequest.request) === null || _b === void 0 ? void 0 : _b.partnerId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repairRequest]);
    const handleNotifyCustomer = () => {
        localDispatch({
            type: 'setShowMpiNotifyCustomerDialog',
            payload: true,
        });
    };
    const onManualClose = (type) => {
        localDispatch({ type, payload: false });
    };
    const handleShowMpiCustomerAlertClose = () => {
        dispatch(setShowMpiSentToCustomerAlert(false, { referenceNum }));
        onManualClose('setCustomerAlertAutoHide');
    };
    const handleMpiNotifySADialogClose = () => {
        localDispatch({ type: 'setShowNotifySADialog', payload: false });
        if (onComplete) {
            onComplete();
        }
    };
    const handleShowNotifySADialog = () => {
        localDispatch({ type: 'setShowNotifySADialog', payload: false });
        updateInspectionStatus({
            dispatch,
            inspectionPerformedStatus,
            currentUserIsSA,
            currentUser,
            currentUserId: currentUser === null || currentUser === void 0 ? void 0 : currentUser.id,
            referenceNum,
            updateSAEdit: true,
        });
        localDispatch({ type: 'setShowQuoteRequestedAlert', payload: true });
        if (onComplete) {
            onComplete();
        }
    };
    const handleMpiNotifyCustomerDialogClose = () => {
        localDispatch({
            type: 'setShowMpiNotifyCustomerDialog',
            payload: false,
        });
    };
    const handleShowNotifyCustomerDialog = () => {
        localDispatch({
            type: 'setShowMpiNotifyCustomerDialog',
            payload: false,
        });
        localDispatch({ type: 'setShowChangesPendingAlert', payload: false });
        updateInspectionStatus({
            dispatch,
            inspectionPerformedStatus,
            currentUserIsSA,
            currentUser,
            currentUserId: currentUser === null || currentUser === void 0 ? void 0 : currentUser.id,
            referenceNum,
            updateSA: state.updateSA,
            updateSAEdit: state.updateSA,
            sentToCustomer: true,
        });
        if (onComplete) {
            localDispatch({ type: 'setUpdateSA', payload: false });
            onComplete();
        }
    };
    return {
        referenceNum,
        state,
        showMpiSentToCustomerAlert,
        handleNotifyCustomer,
        onManualClose,
        handleShowMpiCustomerAlertClose,
        handleMpiNotifySADialogClose,
        handleShowNotifySADialog,
        handleMpiNotifyCustomerDialogClose,
        handleShowNotifyCustomerDialog,
        suppressNotifications,
    };
};
export default useAlertsAndDialogs;
