import React from 'react';
import { Grid } from 'src/components/mui';
import Connectors from './Connectors';
import ConnectApiInfo from './ConnectApiInfo';
const KafkaConnect = () => {
    return (<Grid container>
      <Grid item xs={12} md={6}>
        <ConnectApiInfo />
      </Grid>
      <Grid item xs={12}>
        <Connectors />
      </Grid>
    </Grid>);
};
export default KafkaConnect;
