import { useDispatch, useSelector } from 'react-redux';
import { setCustomUser } from 'src/AdminApp/modules/quickRo/actions';
import { selectCustomUser } from 'src/AdminApp/modules/quickRo/selectors';
const useAddNewConsumerTab = () => {
    const dispatch = useDispatch();
    const customUser = useSelector(selectCustomUser);
    const setField = (field) => (event) => dispatch(setCustomUser({ [field]: event.target.value }));
    const handleAddressChange = (address) => dispatch(setCustomUser({ address }));
    const handleMarketingSourceChange = (v) => dispatch(setCustomUser({ marketingSourceId: v.target.value }));
    return {
        customUser,
        setField,
        handleAddressChange,
        handleMarketingSourceChange,
    };
};
export default useAddNewConsumerTab;
