import React from 'react';
import { StaticDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { CalendarIcon } from '../../icons';
import { JumpButton, DatePickerMenu } from '../DatePicker.styles';
import useDatePicker from '../hooks/useDatePicker';
const Calendar = () => {
    const { anchorEl, open, handleClick, handleClose, selectedDate, handleDateChange, } = useDatePicker();
    return (<>
      <JumpButton startIcon={<CalendarIcon />} aria-controls="basic-menu" aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        Calendar
      </JumpButton>
      <DatePickerMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <StaticDatePicker value={moment(selectedDate)} onChange={handleDateChange}/>
      </DatePickerMenu>
    </>);
};
export default Calendar;
