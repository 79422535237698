import { createSelector } from 'reselect';
export const selectRegions = (state) => state.admin.regions;
export const regionById = (id) => createSelector(selectRegions, (value) => (value.byId[id] || {}));
export const selectDashboard = createSelector(selectRegions, (regions) => { var _a; return (_a = regions.dashboard) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardRegions = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.content) !== null && _a !== void 0 ? _a : []; });
export const selectDashboardPagination = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.pagination) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardParams = createSelector(selectDashboard, (dashboard) => { var _a; return (_a = dashboard.params) !== null && _a !== void 0 ? _a : {}; });
export const selectDashboardFetching = createSelector(selectDashboard, (dashboard) => {
    var _a;
    return (_a = dashboard._isFetching) !== null && _a !== void 0 ? _a : false;
});
export const selectSkeduloRegions = createSelector(selectRegions, (regions) => { var _a; return (_a = regions.skeduloRegions) !== null && _a !== void 0 ? _a : []; });
export const selectRegionsAutocomplete = createSelector(selectRegions, (regions) => { var _a; return (_a = regions.autocomplete) !== null && _a !== void 0 ? _a : []; });
