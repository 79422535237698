var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { truncate } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotesEntityTypes, NotesTypes, NotesUrgency, } from 'src/AdminApp/containers/requests/Notes/constants';
import NotesOriginTypes from 'src/AdminApp/models/enums/NotesOriginTypes';
import { getMissing3CRequestNotes } from 'src/AdminApp/modules/requestNotes/actions';
import { selectMissing3CNotes } from 'src/AdminApp/modules/requestNotes/selectors';
import { selectSuggestedRepairsArrayByRefNum, selectSuggestedRepairsArrayByRefNumWithCustomRepair, } from 'src/AdminApp/modules/requests/selectors';
import { toast } from 'src/components/SimpleToast';
import useIsMobile from 'src/hooks/useIsMobile';
import usePageReferenceNum from 'src/hooks/usePageReferenceNum';
import * as Yup from 'yup';
const useNotes3CDrawerContent = ({ repairRequestId, loadNotes, completeTask, currentTaskWorkFlow, pagination, notes, data, type, fetchNotesAppliesTo, fetchNotesAppliesToRecommendedRepairs, onLoadData, saveNotes, onClose, }) => {
    const { referenceNum } = usePageReferenceNum();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const missing3cNotes = useSelector(selectMissing3CNotes);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const response = yield dispatch(getMissing3CRequestNotes(repairRequestId));
            const missing3cNotesResponse = response.payload;
            if ((currentTaskWorkFlow === null || currentTaskWorkFlow === void 0 ? void 0 : currentTaskWorkFlow.state) === 'CONCERN_CAUSE_CORRECTION' &&
                ((_a = missing3cNotesResponse === null || missing3cNotesResponse === void 0 ? void 0 : missing3cNotesResponse.repairMissingNotes) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                completeTask();
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        var _a, _b, _c;
        if (loadNotes) {
            fetchNotesAppliesTo();
            fetchNotesAppliesToRecommendedRepairs();
            onLoadData({
                page: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) !== null && _a !== void 0 ? _a : 0,
                size: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) !== null && _b !== void 0 ? _b : 10,
                sort: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.sort) !== null && _c !== void 0 ? _c : ['created,desc'],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadNotes]);
    const buildNote = (concern, cause, correction) => {
        return `
--CONCERN--
${concern}
--CAUSE--
${cause}
--CORRECTION--
${correction}
`;
    };
    const handleAddNote = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const [suggestedRepairId, suggestedRepairName] = values.concern.split(':');
        const appliesTo = [];
        const value = data.find((note) => note.entityId === suggestedRepairId);
        const newNote = buildNote(suggestedRepairName, values.cause, values.correction);
        if (value) {
            appliesTo.push(value);
        }
        const params = {
            entityId: suggestedRepairId,
            entityType: NotesEntityTypes.SUGGESTED_REPAIR,
            origin: NotesOriginTypes.TOOLS,
            content: newNote,
            repairRequestId,
            visibility: type === NotesTypes.INTERNAL ? 'INTERNAL' : 'EXTERNAL',
            urgency: NotesUrgency.NORMAL,
            appliesTo,
            type: NotesTypes.CONCERN_CAUSE_CORRECTION,
        };
        const response = yield saveNotes(params);
        if (response && !response.error) {
            yield onLoadData({
                page: (pagination === null || pagination === void 0 ? void 0 : pagination.pageNumber) || 0,
                size: (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || 10,
                sort: (pagination === null || pagination === void 0 ? void 0 : pagination.sort) || ['created,desc'],
            });
            toast.success('Note added successfully.');
            dispatch(getMissing3CRequestNotes(repairRequestId));
        }
    });
    const validationSchema = Yup.object().shape({
        concern: Yup.string().required(),
        cause: Yup.string().max(500, 'Too Long!').required(),
        correction: Yup.string().max(500, 'Too Long!').required(),
    });
    const suggestedRepairsKnownRepairs = useSelector(selectSuggestedRepairsArrayByRefNum(referenceNum));
    const suggestedRepairsWithCustomRepair = useSelector(selectSuggestedRepairsArrayByRefNumWithCustomRepair(referenceNum));
    const newArrayOfSuggestedRepair = suggestedRepairsWithCustomRepair.map((_a) => {
        var { message: name } = _a, rest = __rest(_a, ["message"]);
        return (Object.assign({ name }, rest));
    });
    const suggestedRepairs = [
        ...suggestedRepairsKnownRepairs,
        ...newArrayOfSuggestedRepair,
    ];
    const findNote = useCallback((id) => {
        const [suggestedRepairId] = id.split(':');
        return notes.find((note) => note.entityId === suggestedRepairId);
    }, [notes]);
    const extractFromNote = (note) => {
        const causeMatch = note.match(/--CAUSE--\s*(.*?)\s*--/s);
        const correctionMatch = note.match(/--CORRECTION--\s*(.*)/s);
        const cause = causeMatch ? causeMatch[1].trim() : '';
        const correction = correctionMatch ? correctionMatch[1].trim() : '';
        return { cause, correction };
    };
    const truncateString = (str, length) => {
        if (str.length <= length) {
            return str;
        }
        return truncate(str, {
            omission: '...',
            length,
        }).trimEnd();
    };
    const INITIAL_VALUES = {
        concern: '',
        cause: '',
        correction: '',
    };
    const handleSubmitForm = (values, { setSubmitting, resetForm }) => __awaiter(void 0, void 0, void 0, function* () {
        if (missing3cNotes === null || missing3cNotes === void 0 ? void 0 : missing3cNotes.repairMissingNotes.length) {
            setSubmitting(true);
            yield handleAddNote(values);
            setSubmitting(false);
            if ((missing3cNotes === null || missing3cNotes === void 0 ? void 0 : missing3cNotes.repairMissingNotes.length) > 1) {
                resetForm({ values: INITIAL_VALUES });
            }
        }
        else {
            onClose();
        }
    });
    return {
        validationSchema,
        handleSubmitForm,
        INITIAL_VALUES,
        findNote,
        extractFromNote,
        suggestedRepairs,
        isMobile,
        truncateString,
        missing3cNotes,
    };
};
export default useNotes3CDrawerContent;
