import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { Search, Today, Refresh } from 'src/components/mui/icons';
import { Button, Grid } from 'src/components/mui';
import { colors, theme } from 'src/styles/theme';
import { isSubSectionLocation } from 'src/utils/nav-utils';
import Head from 'src/containers/layout/Head';
import { selectOfflineFourCorners } from 'src/modules/auth/selectors';
import { useSelector } from 'react-redux';
import OnlineIndicator from 'src/components/Offline/OnlineIndicator';
import { selectStopwatch } from 'src/AdminApp/modules/workflows/selectors';
import { selectFleetVisit, selectVisitsStopwatch, } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import useIsMobile from 'src/hooks/useIsMobile';
import MobileAppBar from './MobileAppBar/MobileAppBar';
import { DRAWER_WIDTH } from './AppBar/AppBar';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import StopwatchBar from 'src/AdminApp/containers/requests/StopwatchBar';
import VisitsStopwatchBar from 'src/AdminApp/containers/requests/VisitsStopwatchBar';
const Wrapper = styled.div `
  padding-left: ${DRAWER_WIDTH}px;

  ${theme.breakpoints.down('sm')} {
    padding-top: 56px;
    padding-left: 0;
  }
`;
const BreadcrumbsCTAWrapper = styled(Grid) `
  align-items: center;
  padding: 12px 0;
  flex-wrap: nowrap;

  ${({ $isBarType }) => {
    return $isBarType
        ? `
      background: ${colors.black};
      color: ${colors.white};
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      padding: 10px 4px;
      z-index: 20;

      svg {
        fill: ${colors.white};
      }
    `
        : '';
}}

  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
    height: 96px;
  }

  ${theme.breakpoints.down('sm')} {
    height: 56px;

    .type-render-portal {
      align-self: center;
    }
  }

  .CTA-render-portal {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  & > div {
    flex: 1;
    width: auto;
  }
`;
const getContentPaddingTop = ({ isStopwatchValid, }) => (isStopwatchValid ? '44px' : '10px');
const Content = styled.div `
  flex-grow: 1;
  padding: 0 32px;
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  max-width: calc(100vw - ${DRAWER_WIDTH}px);

  ${theme.breakpoints.down('sm')} {
    padding: ${getContentPaddingTop} 16px 10px;
    max-width: 100vw;
  }
`;
const GlobalStyle = createGlobalStyle `
  body {
    background: white;
  }
`;
const SearchButtonIcon = styled(Search) `
  color: ${colors.black};
  transform: rotateY(180deg);
`;
const DashboardButtonIcon = styled(Today) `
  color: ${colors.black};
`;
const RefreshButtonIcon = styled(Refresh) `
  color: ${colors.black};
`;
const SearchButton = ({ linkTo }) => {
    return (<Button color="primary" component={Link} to={linkTo}>
      <SearchButtonIcon />
    </Button>);
};
const DashboardButton = ({ linkTo }) => {
    return (<Button color="primary" component={Link} to={linkTo}>
      <DashboardButtonIcon />
    </Button>);
};
const RefreshButton = ({ onClick, }) => (<Button color="primary" onClick={onClick}>
    <RefreshButtonIcon />
  </Button>);
const PageLayout = ({ children, location, disableBreadcrumbs = false, showRefreshPageButton = false, }) => {
    const isMobile = useIsMobile();
    const isSubSection = isSubSectionLocation(location);
    const offlineFourCornersEnabled = useSelector(selectOfflineFourCorners);
    const stopwatch = useSelector(selectStopwatch());
    const history = useHistory();
    const visitsStopwatch = useSelector(selectVisitsStopwatch());
    const visit = useSelector(selectFleetVisit);
    return (<>
      <Wrapper>
        <Head />
        <GlobalStyle />

        {isMobile && !isSubSection && (<MobileAppBar location={location}/>)}

        <Content isStopwatchValid={(stopwatch === null || stopwatch === void 0 ? void 0 : stopwatch.isRunning) || (visitsStopwatch === null || visitsStopwatch === void 0 ? void 0 : visitsStopwatch.isRunning)}>
          {(isMobile && !isSubSection) || disableBreadcrumbs ? null : (<>
              <BreadcrumbsCTAWrapper className="mobile-header" $isBarType={Boolean(isSubSection && isMobile)} container style={{ justifyContent: 'space-between' }}>
                <Grid container item xs={false} style={{ flexDirection: 'row', gap: '10px' }}>
                  <Breadcrumbs visit={visit}/>
                  {!isMobile ? <div className="type-render-portal"/> : null}
                </Grid>
                {!isMobile && <div className="CTA-render-portal"/>}
                {isMobile && (<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {showRefreshPageButton && (<RefreshButton onClick={() => history.go(0)}/>)}
                    <SearchButton linkTo="/admin/repairs"/>
                    <DashboardButton linkTo="/tech/my-dashboard"/>
                  </div>)}
              </BreadcrumbsCTAWrapper>
              <div className="stopwatch-bar">
                <StopwatchBar />
                <VisitsStopwatchBar />
              </div>
              {isMobile ? (<div className="CTA-render-portal" style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    borderBottom: '1px solid #D8D8D8',
                    paddingBottom: '10px',
                    marginBottom: '20px',
                }}/>) : null}
            </>)}
          {children}
        </Content>
      </Wrapper>
      {offlineFourCornersEnabled && <OnlineIndicator />}
    </>);
};
export default PageLayout;
