var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from 'src/components/mui';
import useDebounce from 'src/modules/api/utils/useDebounce';
import { clearFleetsSearchResults, searchFleetsByStation, searchStationsByName, clearStationsSearchResults, } from 'src/AdminApp/modules/fleets/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedStations, setSelectedFleets, setFleetsDatasource, } from 'src/FleetVisitsApp/modules/fleetVisits/actions';
import { selectStationsSearch } from 'src/AdminApp/modules/fleets/selectors';
import { selectAddVisitFleets, selectAddVisitStations, } from 'src/FleetVisitsApp/modules/fleetVisits/selectors';
import { toast } from 'src/components/SimpleToast';
const StationsAutocomplete = ({ workOrders, disabled, }) => {
    const dispatch = useDispatch();
    const [searchFleet, setSearchFleet] = useState('');
    const searchStationsDebounce = useDebounce(searchFleet, 500);
    const [searchResults, setSearchResults] = useState([]);
    const [selected, setSelected] = useState([]);
    const stationsResultsRaw = useSelector(selectStationsSearch);
    const selectedStations = useSelector(selectAddVisitStations);
    const selectedFleets = useSelector(selectAddVisitFleets);
    useEffect(() => {
        if (searchStationsDebounce.length > 0) {
            dispatch(searchStationsByName({ query: searchStationsDebounce }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchStationsDebounce]);
    useEffect(() => {
        // FILTER ONLY IF WE HAVE RESULTS TO FILTER AND STUFF SELECTED
        if (stationsResultsRaw && (selected === null || selected === void 0 ? void 0 : selected.length) > 0) {
            const filteredResults = stationsResultsRaw.filter((result) => !selected.includes(result));
            setSearchResults(filteredResults);
        }
        else if (stationsResultsRaw) {
            setSearchResults(stationsResultsRaw);
        }
    }, [stationsResultsRaw, selected]);
    useEffect(() => {
        setSelected(selectedStations);
    }, [selectedStations]);
    const handleSearch = (e, value) => {
        setSearchFleet(value);
    };
    const handleSelected = (e, value) => __awaiter(void 0, void 0, void 0, function* () {
        const deletedStation = selectedStations[0];
        // STOP THE USER FROM REMOVING A STATION THAT IS BEING USED IN A WORK ORDER
        const foundWoStation = workOrders === null || workOrders === void 0 ? void 0 : workOrders.find((wo) => { var _a; return ((_a = wo.fleet) === null || _a === void 0 ? void 0 : _a.station) === deletedStation; });
        if (foundWoStation) {
            toast.error('This station cannot be removed as long as its ROs are in the visit');
            return;
        }
        // ENFORCED 1 OPTION LIMIT EVEN THOUGH THE COMPONENT IS SETUP FOR MULTIPLES. WE LIKE THE CHIPS THAT COME WITH MULTIPLE
        if (value.length > 1) {
            value.shift();
        }
        dispatch(setSelectedStations(value));
        // DELETED THE STATION
        if (value.length === 0) {
            dispatch(setSelectedFleets(selectedFleets.filter((f) => f.station !== deletedStation)));
            dispatch(setFleetsDatasource('SEARCH'));
            dispatch(clearStationsSearchResults());
            dispatch(clearFleetsSearchResults());
        }
        else {
            // WHEN STATIONS CHANGE, WE GET THE FLEETS FROM IT
            dispatch(setFleetsDatasource('STATION'));
            const stationFleetsResponse = yield dispatch(searchFleetsByStation({ station: value[0], size: 100 }));
            if (!stationFleetsResponse.error) {
                dispatch(setSelectedFleets(stationFleetsResponse.payload.content));
            }
        }
    });
    return (<Autocomplete multiple id="stations-select" options={searchResults} disabled={disabled} filterSelectedOptions clearOnBlur noOptionsText="No results" onChange={handleSelected} onInputChange={handleSearch} ChipProps={{
            classes: {
                root: 'chip',
                deleteIcon: 'chip-delete-icon',
            },
        }} value={selected} renderInput={(params) => (<TextField {...params} variant="outlined" placeholder="Search for a station" label="Stations"/>)}/>);
};
export default StationsAutocomplete;
