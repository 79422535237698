import styled from 'styled-components';
import { colors, FONT_FAMILY } from 'src/styles/theme';
export const StopwatchContainerMobile = styled.div `
  background-color: ${colors.GRAY_DE};
  color: ${colors.black};
  height: 34px;
  width: 100%;
  position: fixed;
  top: 56px;
  z-index: 20;
  left: 0;
  display: flex;
  justify-content: flex-end;
  font-family: ${FONT_FAMILY};
  font-weight: bold;
  line-height: 1px;
`;
export const StopwatchContainerDesktop = styled.div `
  background-color: white;
  color: ${colors.black};
  height: 44px;
  max-width: 130px;
  z-index: 20;
  margin-bottom: 15px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${FONT_FAMILY};
  font-weight: bold;
  line-height: 1px;
  border-radius: 5.2px;
  border: solid 0.8px rgba(118, 118, 118, 0.24);
`;
export const StopwatchBarSection = styled.div `
  flex-grow: 0.3333;
  display: flex;
  align-items: center;
  width: 0;
`;
export const StopwatchBarDesktopTimeLeft = styled.span `
  font-size: 15px;
  padding: 0 5px;
`;
