var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadSwimlaneRequestInfo } from 'src/AdminApp/modules/requests/actions';
import { selectRequestDataByReferenceNum } from 'src/AdminApp/modules/requests/selectors';
import { getSwimlanesRequestInfo, setIsJobInfoLoading, setJobCancellationStatus, } from 'src/AdminApp/modules/swimlanes/actions';
import { selectIsJobInfoLoading, selectJobCancellationStatus, } from 'src/AdminApp/modules/swimlanes/selectors';
const useRepairRequestJobInfo = ({ selectedJobs, referenceNumber, jobIdx, }) => {
    const dispatch = useDispatch();
    const referenceNum = selectedJobs && selectedJobs.length > 0 && selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0]
        ? selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.referenceNum
        : undefined;
    const requestData = useSelector(selectRequestDataByReferenceNum(referenceNumber !== null && referenceNumber !== void 0 ? referenceNumber : referenceNum));
    const isJobInfoLoading = useSelector(selectIsJobInfoLoading);
    const [loading, setLoading] = useState(true);
    const [openCancelationDrawer, setOpenCancelationDrawer] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [requestInfo, setRequestInfo] = useState();
    const { request, contactInfo } = requestData !== null && requestData !== void 0 ? requestData : {};
    const jobCancelationStatus = useSelector(selectJobCancellationStatus);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const job = selectedJobs ? selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0] : undefined;
            if (job || referenceNumber) {
                try {
                    const response = yield dispatch(getSwimlanesRequestInfo(referenceNumber !== null && referenceNumber !== void 0 ? referenceNumber : referenceNum));
                    if (response.error) {
                        setRequestInfo(undefined);
                        throw new Error(`Repair Request Not Found: ${referenceNumber !== null && referenceNumber !== void 0 ? referenceNumber : (_a = job === null || job === void 0 ? void 0 : job.repairJob) === null || _a === void 0 ? void 0 : _a.referenceNum}`);
                    }
                    else {
                        setRequestInfo(response.payload);
                    }
                }
                catch (e) {
                    // eslint-disable-next-line no-alert
                }
                setLoading(false);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedJobs, jobIdx, referenceNumber]);
    useEffect(() => {
        if (!loading && isJobInfoLoading) {
            dispatch(setIsJobInfoLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, isJobInfoLoading]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (((jobCancelationStatus === null || jobCancelationStatus === void 0 ? void 0 : jobCancelationStatus.id) === ((_a = selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0]) === null || _a === void 0 ? void 0 : _a.id) &&
                (jobCancelationStatus === null || jobCancelationStatus === void 0 ? void 0 : jobCancelationStatus.status) === 'IN_PROGRESS') ||
                openMenu) {
                const response = yield dispatch(loadSwimlaneRequestInfo(referenceNumber !== null && referenceNumber !== void 0 ? referenceNumber : selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].repairJob.referenceNum, openMenu));
                if (response && !(response === null || response === void 0 ? void 0 : response.error) && !openMenu) {
                    setOpenCancelationDrawer(true);
                }
            }
        }))();
        return () => setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobCancelationStatus, openMenu]);
    const jobCanBeCanceled = ((request === null || request === void 0 ? void 0 : request.state) === 'OPEN_REQUEST' &&
        (request === null || request === void 0 ? void 0 : request.activeAppointment.status) === 'PENDING') ||
        (request === null || request === void 0 ? void 0 : request.state) === 'CONFIRMED_APPOINTMENT';
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const handleJobCancelation = () => {
        dispatch(setJobCancellationStatus({
            id: selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].id,
            status: 'IN_PROGRESS',
        }));
        setOpenCancelationDrawer(true);
    };
    const handleCloseCancelation = () => {
        dispatch(setJobCancellationStatus({
            id: selectedJobs[jobIdx !== null && jobIdx !== void 0 ? jobIdx : 0].id,
            status: 'CLOSED',
        }));
        setOpenCancelationDrawer(false);
    };
    return {
        loading,
        requestInfo,
        handleClick,
        openMenu,
        setOpenMenu,
        jobCanBeCanceled,
        handleJobCancelation,
        request,
        contactInfo,
        openCancelationDrawer,
        handleCloseCancelation,
        jobCancelationStatus,
    };
};
export default useRepairRequestJobInfo;
