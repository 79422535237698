var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAdminUserInfo } from 'src/AdminApp/modules/users/actions';
import { isAdmin } from 'src/AdminApp/components/users/utils';
const useMultipleAdminUsers = ({ userIds }) => {
    const dispatch = useDispatch();
    const [usersInfo, setUsersInfo] = useState([]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (userIds && userIds.length > 0) {
                const adminUsers = userIds.filter((el) => isAdmin(el.createdBy));
                if (!adminUsers || (adminUsers === null || adminUsers === void 0 ? void 0 : adminUsers.length) === 0) {
                    setUsersInfo(userIds);
                    return;
                }
                const notAdminUsers = userIds.filter((el) => !isAdmin(el.createdBy));
                const promises = adminUsers.map((user) => dispatch(getAdminUserInfo({ userId: parseInt(user.ispOwnerId) })));
                const response = yield Promise.all(promises);
                const users = response.reduce((acc, el) => {
                    const id = el.meta.userId;
                    const user = userIds.find((el) => parseInt(el.ispOwnerId) === id);
                    acc.push(Object.assign(Object.assign({}, el.payload), user));
                    return acc;
                }, []);
                setUsersInfo([...users, ...notAdminUsers]);
            }
        }))();
    }, [userIds]);
    return {
        usersInfo,
    };
};
export default useMultipleAdminUsers;
